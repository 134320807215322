
/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, FormGroup, Button, Input, Media, Label } from 'reactstrap';
import CollapseBasic from '../../../component/atoms/collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import moment from 'moment';
import EditBtn from "../../atoms/editBtn";
import DeleteBtn from "../../atoms/deleteBtn";
import { post_special_offer_comment, delete_special_offer, offer_helpful_or_not } from '../../../actions/business';
import {Link} from 'react-router-dom';

class OtherOffers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            onStoreOptions: '',
            enableToggleOfferReply: {},
            enableReplyText: {},
            enableOfferReplyText: {},
            otherOffersData: null,
            checkInOffersData: null,
            inStoreOffersData: null,
            otherOfferState: null,
            offerCheckState: null,
            mediaSet: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps?.other_offers) {
            this.setState({ otherOffersData: nextProps?.other_offers })
        }

        if (nextProps?.check_in_offers) {
            this.setState({ checkInOffersData: nextProps?.check_in_offers })
        }

        if (nextProps?.in_store_offers) {
            this.setState({ inStoreOffersData: nextProps?.in_store_offers })
        }

        if (nextProps?.otherOffers) {
            this.setState({ otherOfferState: nextProps?.otherOffers });
        }

        if (nextProps?.offerCheck) {
            this.setState({ offerCheckState: nextProps?.offerCheck });
        }

        if (nextProps?.mediaSet) {
            this.setState({ mediaSet: [...nextProps?.mediaSet] });
        }
    }

    addNewComment = ({ text, id, title, parent_id, reply }) => {
        const { my_profile_info, businessId } = this.props;
        let data = {};

        if (reply) {
            data = {
                comment: text,
                created_by: my_profile_info?.id,
                parent_id: parent_id,
                reviews_specialoffers: id,
                reviews_userentries: businessId,
            }
            this.props.post_special_offer_comment({ data, listing_id: businessId, offer_type: title });
            setTimeout(() => { this.setState({ enableReplyText: {}, enableToggleOfferReply: {} }) }, 1000);
        } else {
            data = {
                [id]: text,
                comment: text,
                created_by: my_profile_info?.id,
                reviews_specialoffers: id,
                reviews_userentries: businessId,
            }
            this.props.post_special_offer_comment({ data, listing_id: businessId, offer_type: title });
            setTimeout(() => { this.setState({ enableOfferReplyText: {} }) }, 1000);
        }
    }

    setToggleReply = (id) => {
        let { enableToggleOfferReply } = this.state;
        if (enableToggleOfferReply[id]) {
            enableToggleOfferReply[id] = !enableToggleOfferReply[id];
        } else {
            enableToggleOfferReply[id] = true;
        }
        this.setState({ enableToggleOfferReply });
    }

    setEditItems = ({ item, toggleItem }) => {
        this.setAndGetOtherOffers(toggleItem);
        this.props.setEditOffer(item);
        this.setOfferItems();

    }

    deleteOffer = ({ id, title }) => {
        let { delete_special_offer, businessId } = this.props;
        delete_special_offer({
            id,
            listing_id: businessId,
            offer_type: title
        });
    }

    offerHelpfulOrNot = (data, title) => {
        let { businessId } = this.props;
        this.props.offer_helpful_or_not(businessId, title, data);
    }

    renderItems = ({ title, media, data, toggleItem }) => {
        let { enableToggleOfferReply, enableReplyText, enableOfferReplyText } = this.state;
        let setItem = data?.results;

        return (
            <CollapseBasic
                title={title}
                noHr={true}
                containerClass="bg-transparent type-secondary-dark title-type-sm" bodyClass="mt-3 text-secondary-dark"
                isOpen={true}
                size="sm"
                noPadding={true}>
                <ul className="list-unstyled">
                    {setItem && Array.isArray(setItem) && setItem?.length ?

                        setItem.map((item, index) => (
                            <li className="mb-2">
                                <div>
                                    <div className="d-flex mx-n1 mb-2">
                                        <div className="px-1">
                                            <span className="text-white wb-break-word">
                                                <span className="text-white font-weight-bold ff-alt" role="button">{`${index + 1} ${item?.special_offer ? item?.special_offer : ''}`}</span>
                                                <br />
                                                {media && (
                                                    item?.offer_media && Array.isArray(item?.offer_media) && item?.offer_media?.length ?
                                                        item?.offer_media.map(offer_media_item => {
                                                            return (
                                                                <img
                                                                    className="mr-2"
                                                                    width="100"
                                                                    src={offer_media_item?.file}
                                                                    alt=""
                                                                />
                                                            )
                                                        })
                                                        : null
                                                )}
                                                {item?.create_by_name ? <span className="text-secondary-dark">{`[added by ${item?.create_by_name}]`}</span> : ""}
                                            </span>
                                        </div>
                                        {item?.conversation && Array.isArray(item?.conversation) && item?.conversation?.length ? null :
                                            (<div className="px-1 col-auto ml-auto" hidden={!this.checkIfLoggedIn()}>
                                                <EditBtn color="gold" onClick={() => { this.setEditItems({ item, toggleItem }) }} />
                                                <DeleteBtn
                                                    color="gold"
                                                    onClick={() => {
                                                        this.props.setToggleForChildComponents({
                                                            toggle: true,
                                                            text: 'Are you sure you want to delete?',
                                                            id: item?.id,
                                                            type: title
                                                        })
                                                    }} />
                                            </div>
                                            )}
                                    </div>

                                    <div>
                                        {'Website to claim deal: '}

                                        <span><a href="#" className="text-reset">{item?.claim_deal_link ? item.claim_deal_link : "Not Applicable"}</a></span>

                                    </div>
                                    <div>
                                        Offer starts on: <span className="font-weight-bold text-white">
                                            {item?.from_date ? moment(item?.from_date).format('MMM DD, YYYY') : null}
                                        </span>
                                    </div>
                                    <div>
                                        Offer ends on: <span className="font-weight-bold text-white">
                                            {item?.to_date ? moment(item?.to_date).format('MMM DD, YYYY') : null}
                                        </span>
                                    </div>
                                    <div>
                                        Terms, Details and Exclusions: <br /> <span>{item?.terms_details_exclusions ? item.terms_details_exclusions : "Not Announced"}</span>
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <i>Was this offer helpful? </i>
                                    <span className="text-secondary-darker font-weight-bold d-inline-block hover-underline" role="button" onClick={() => this.offerHelpfulOrNot({
                                        type: "helpful", specialoffer: item.id
                                    }, title)}>Yes</span>
                                    <span className="text-secondary-darker font-weight-bold">{' - '}</span>
                                    <span className="text-secondary-darker font-weight-bold d-inline-block hover-underline" role="button" onClick={() => this.offerHelpfulOrNot({
                                        type: "not_helpful", specialoffer: item.id
                                    }, title)}>No</span>
                                </div>
                                <div className="mb-2">
                                    <strong className="text-secondary-darker">{item?.offer_group_status?.helpful_count ? item?.offer_group_status?.helpful_count : 0}</strong>{' people found this offer helpful'}
                                </div>

                                <div>
                                    {/* Repeat this */}
                                    <div className="fs-13 mb-2">
                                        <div className="d-flex mx-n2 mb-2">
                                            <div className="px-2">
                                                {/* All Replies */}
                                                <div>
                                                    {/* Repeat this */}
                                                    {item?.conversation && Array.isArray(item?.conversation) && item?.conversation?.length ?
                                                        item?.conversation.map(item_ct => (
                                                            <div className="fs-13 mb-1">
                                                                <div className="d-flex mx-n2">
                                                                    <div className="px-2">
                                                                        <img
                                                                            className="img-circle _30x30"
                                                                            src={item_ct?.sender?.current_profile_pic ? item_ct?.sender?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png"} 
                                                                            alt="" />
                                                                    </div>
                                                                    <div className="px-2 flex-grow-1">
                                                                        <div className="mb-1">
                                                                            <div>
                                                                                {item_ct?.sender?.username ? 
                                                                                <Link to={`/people/${item_ct?.sender?.username}`} className="text-secondary-dark font-weight-bold ff-base">
                                                                                    {`${item_ct?.sender?.first_name} ${item_ct?.sender?.last_name}`}
                                                                                </Link> : 
                                                                                <span className="text-secondary-dark font-weight-bold ff-base">
                                                                                    {`${item_ct?.sender?.first_name} ${item_ct?.sender?.last_name}`}
                                                                                </span>}
                                                                                {": "}
                                                                                {item_ct?.body}
                                                                            </div>
                                                                            <span
                                                                                onClick={() => { this.setToggleReply(item_ct?.id) }}
                                                                                role="button"
                                                                                className="text-secondary-dark ff-base font-weight-bold">
                                                                                {'Reply'}
                                                                            </span>
                                                                            <span>&nbsp;-&nbsp;</span>
                                                                            <span>{item_ct?.sent_at}</span>

                                                                            {item_ct?.comment_reply && Array.isArray(item_ct?.comment_reply) && item_ct?.comment_reply?.length ? (
                                                                                item_ct?.comment_reply.map(item_reply_comment => (
                                                                                    <div className="fs-13 mb-1">
                                                                                        <div className="d-flex mx-n2 ml-2 mt-2">
                                                                                            <div className="px-2">
                                                                                                <img
                                                                                                    className="img-circle _30x30"
                                                                                                    src={item_reply_comment?.sender?.current_profile_pic ? item_reply_comment?.sender?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png"}
                                                                                                    alt="" />
                                                                                            </div>
                                                                                            <div className="px-2 flex-grow-1">
                                                                                                <div className="mb-1">
                                                                                                    <div>
                                                                                                        {item_reply_comment?.sender?.username ? 
                                                                                                        <Link to={`/people/${item_reply_comment?.sender?.username}`} className="text-secondary-dark font-weight-bold ff-base">
                                                                                                            {`${item_reply_comment?.sender?.first_name} ${item_reply_comment?.sender?.last_name}`}
                                                                                                        </Link> : 
                                                                                                        <span className="text-secondary-dark font-weight-bold ff-base">
                                                                                                            {`${item_reply_comment?.sender?.first_name} ${item_reply_comment?.sender?.last_name}`}
                                                                                                        </span>}
                                                                                                        {": "}
                                                                                                        {item_reply_comment?.body}
                                                                                                    </div>
                                                                                                    <span
                                                                                                        onClick={() => { this.setToggleReply(item_reply_comment?.id) }}
                                                                                                        role="button"
                                                                                                        className="text-secondary-dark ff-base font-weight-bold">
                                                                                                        {'Reply'}
                                                                                                    </span>
                                                                                                    <span>&nbsp;-&nbsp;</span>
                                                                                                    <span>{item_reply_comment?.sent_at}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div>
                                                                                            <div hidden={enableToggleOfferReply && enableToggleOfferReply[item_reply_comment?.id] ? false : true}>
                                                                                                <Media className="media-post mt-3">
                                                                                                    <Media>
                                                                                                        <Media
                                                                                                            className="img-circle _30x30"
                                                                                                            src={'https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png'}
                                                                                                            alt="User Image"
                                                                                                        />
                                                                                                    </Media>
                                                                                                    <Media body>
                                                                                                        <FormGroup className="mb-0">
                                                                                                            <Input
                                                                                                                onChange={(e) => {
                                                                                                                    enableReplyText[item_reply_comment?.id] = e.target.value;
                                                                                                                    this.setState({ enableReplyText })
                                                                                                                }}
                                                                                                                bsSize="sm"
                                                                                                                value={enableReplyText[item_reply_comment?.id]}
                                                                                                                className="text-primary mb-2"
                                                                                                                type="textarea"
                                                                                                                name="reply"
                                                                                                                placeholder="Write a reply"
                                                                                                            />
                                                                                                            <div className="text-right">
                                                                                                                <div className="text-right">
                                                                                                                    <Button
                                                                                                                        onClick={() => {
                                                                                                                            this.setToggleReply(item_reply_comment?.id);
                                                                                                                            enableReplyText[item_reply_comment?.id] = '';
                                                                                                                            this.setState({ enableReplyText });
                                                                                                                        }}
                                                                                                                        size="sm"
                                                                                                                        color="primary"
                                                                                                                        className="ml-0">
                                                                                                                        {'Cancel'}
                                                                                                                    </Button>
                                                                                                                    <Button
                                                                                                                        disabled={enableReplyText[item_reply_comment?.id] && enableReplyText[item_reply_comment?.id].length ? false : true}
                                                                                                                        onClick={() => {
                                                                                                                            this.addNewComment({
                                                                                                                                text: enableReplyText[item_reply_comment?.id],
                                                                                                                                id: item.id,
                                                                                                                                parent_id: item_ct.id,
                                                                                                                                title,
                                                                                                                                reply: true
                                                                                                                            });
                                                                                                                        }}
                                                                                                                        size="sm"
                                                                                                                        color="primary"
                                                                                                                        className=" mr-2">
                                                                                                                        {'Submit'}
                                                                                                                    </Button>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </FormGroup>
                                                                                                    </Media>
                                                                                                </Media>
                                                                                                <hr />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div hidden={enableToggleOfferReply && enableToggleOfferReply[item_ct?.id] ? false : true}>
                                                                        <Media className="media-post mt-3">
                                                                            <Media>
                                                                                <Media
                                                                                    className="img-circle _30x30"
                                                                                    src={'https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png'}
                                                                                    alt="User Image"
                                                                                />
                                                                            </Media>
                                                                            <Media body>
                                                                                <FormGroup className="mb-0">
                                                                                    <Input
                                                                                        onChange={(e) => { enableReplyText[item_ct?.id] = e.target.value; this.setState({ enableReplyText }) }}
                                                                                        bsSize="sm"
                                                                                        value={enableReplyText[item_ct?.id]}
                                                                                        className="mb-2"
                                                                                        type="textarea"
                                                                                        name="reply"
                                                                                        placeholder="Write a reply"
                                                                                    />
                                                                                    <div className="text-right">
                                                                                        <div className="text-right">
                                                                                            <Button
                                                                                                onClick={() => {
                                                                                                    this.setToggleReply(item_ct?.id);
                                                                                                    enableReplyText[item_ct?.id] = '';
                                                                                                    this.setState({ enableReplyText });
                                                                                                }}
                                                                                                size="sm"
                                                                                                color="primary"
                                                                                                className="ml-0">
                                                                                                {'Cancel'}
                                                                                            </Button>
                                                                                            <Button
                                                                                                disabled={enableReplyText[item_ct?.id] && enableReplyText[item_ct?.id].length ? false : true}
                                                                                                onClick={() => {
                                                                                                    this.addNewComment({
                                                                                                        text: enableReplyText[item_ct?.id],
                                                                                                        id: item.id,
                                                                                                        parent_id: item_ct.id,
                                                                                                        title,
                                                                                                        reply: true
                                                                                                    });
                                                                                                }}
                                                                                                size="sm"
                                                                                                color="primary"
                                                                                                className=" mr-2">
                                                                                                {'Submit'}
                                                                                            </Button>
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </FormGroup>
                                                                            </Media>
                                                                        </Media>
                                                                        <hr />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        : null}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-white text-dark p-2">
                                            <div className="d-flex mx-n2 mb-2">
                                                <div className="px-2">
                                                    <img
                                                        className="img-circle _50x50"
                                                        src="https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png"
                                                        alt="" />
                                                </div>
                                                <div className="px-2 col">
                                                    <Input
                                                        onChange={(e) => { enableOfferReplyText[item?.id] = e.target.value; this.setState({ enableOfferReplyText }) }}
                                                        value={enableOfferReplyText && enableOfferReplyText[item?.id] ? enableOfferReplyText[item?.id] : ''}
                                                        bsSize="sm"
                                                        type="textarea"
                                                        rows="2"
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <Button
                                                    disabled={enableOfferReplyText && enableOfferReplyText[item?.id] ? false : true}
                                                    onClick={() => { this.addNewComment({ text: enableOfferReplyText[item?.id], id: item?.id, title }) }}
                                                    color="primary" size="sm">Submit</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))
                        : null}
                </ul>
            </CollapseBasic>
        )
    }

    handleSetAndGetOfferCheck = ({ name, value }) => {
        this.props.handleChangeOffer({ name, value });
        this.setOfferItems();
    }

    setOfferItems = () => {
        let checkOfferValue = this.props.getCheckOffer();
        if (checkOfferValue) {
            let { offerCheck, mediaSet } = checkOfferValue;
            this.setState({
                offerCheckState: offerCheck,
                mediaSet
            });
        }
    }


    renderAddItem = ({ media, toggleItems }) => {
        const {
            handleSubmitOtherOffers,
            stateSet,
            handleCloseAndFetch,
            checkIfVideo,
            removeFromMedia,
            toggleVideoOption
        } = this.props;

        let { onStoreOptions, offerCheckState, mediaSet } = this.state;

        return (
            <div className="bg-white shadow-sm p-3">
                <div>
                    <div className="ff-alt text-secondary-dark font-weight-bold mb-2">Offer Details:</div>
                    <FormGroup>
                        {media ? (
                            <Input
                                value={offerCheckState?.special_offer?.length ? 'actual offer' : mediaSet?.length ? 'Upload offer image-pdf' : this.state.onStoreOptions}
                                onChange={(e) => { this.setState({ onStoreOptions: e.target.value }) }}
                                type="select"
                                bsSize="sm">
                                <option selected value="">Please Choose</option>
                                <option value="actual offer">I will type in the actual offer</option>
                                <option value="Upload offer image-pdf">I will upload offer image/pdf</option>
                            </Input>
                        ) : (
                                <Input
                                    value={offerCheckState?.special_offer}
                                    onChange={(e) => { this.handleSetAndGetOfferCheck({ name: 'special_offer', value: e.target.value }) }}
                                    className="mt-2 item-set" type="text" placeholder="Please type in actual offer" />
                            )}

                        {onStoreOptions == "Upload offer image-pdf" && (
                            <React.Fragment>
                                <div className="mt-2">
                                    <Button
                                        onClick={() => {
                                            toggleVideoOption();
                                            handleCloseAndFetch();
                                        }}
                                        className="mt-2 text-secondary-dark p-0"
                                        color="link">
                                        <span className="icon-add-new"></span>
                                        {'Upload Media'}
                                    </Button>
                                    <Row className="mt-2" xs={2} sm={3} md={2} xl={3} form>
                                        {mediaSet && Array.isArray(mediaSet) && mediaSet.length ?
                                            mediaSet.map(item => {
                                                return (
                                                    <Col className="mb-3">
                                                        <div className="d-flex pr-3 pt-3" role="button" onClick={() => { stateSet({ viewMyPhotosModal: true }) }}>
                                                            <div>
                                                                <img className="img-fluid img-thumbnail" src={checkIfVideo(item)} alt="Uploaded media" />
                                                            </div>
                                                            <div className="mx-n3 mt-n3">
                                                                <Button color="dark" size="sm" title="Remove Media" onClick={(e) => { removeFromMedia(e, item) }}>
                                                                    <FontAwesomeIcon icon="minus" />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                            : null}
                                    </Row>
                                </div>
                            </React.Fragment>
                        )}

                        {onStoreOptions == "actual offer" && (
                            <Input
                                value={offerCheckState?.special_offer}
                                onChange={(e) => { this.handleSetAndGetOfferCheck({ name: 'special_offer', value: e.target.value }) }}
                                className="mt-2 item-set" type="text" placeholder="Please type in actual offer" />
                        )}
                    </FormGroup>
                </div>
                <div>
                    <div className="ff-alt text-secondary-dark font-weight-bold mb-2">Deal Valid:</div>
                    <FormGroup>
                        <Label className="ff-alt text-secondary-dark font-weight-normal">Choose deal start date:</Label>
                        {/* <Input
                            value={offerCheckState?.startDate}
                            onChange={(e) => { this.handleSetAndGetOfferCheck({ name: 'startDate', value: e.target.value }) }}
                            type="select" bsSize="sm">
                            <option value="">None</option>
                            <option value="Enter Start date">Enter Start Date</option>
                        </Input> */}
                        {/* Previous Working Condition */}
                        {/* {offerCheckState?.startDate !== '' && ()} */}
                            <div className="custom-date-picker">
                                <DatePicker
                                    className="form-control-sm form-control"
                                    selected={offerCheckState?.from_date}
                                    placeholderText="Enter State Date"
                                    minDate={moment().toDate()}
                                    onChange={(date) => { this.handleSetAndGetOfferCheck({ name: 'from_date', value: date }) }}
                                />
                            </div>
                    </FormGroup>
                    <FormGroup>
                        <Label className="ff-alt text-secondary-dark font-weight-normal">Choose deal expiration date:</Label>
                        {/* <Input
                            value={offerCheckState?.endDate}
                            onChange={(e) => { this.handleSetAndGetOfferCheck({ name: 'endDate', value: e.target.value }) }}
                            type="select" bsSize="sm">
                            <option value="">None</option>
                            <option value="Enter Expiration date">Enter Expiration Date</option>
                        </Input> */}
                        {/* Previous Working Condition */}
                        {/* {offerCheckState?.endDate !== '' && ()} */}
                            <div className="custom-date-picker">
                                <DatePicker
                                    className="form-control-sm form-control"
                                    selected={offerCheckState?.to_date}
                                    placeholderText="Enter Expiration Date"
                                    minDate={moment().toDate()}
                                    onChange={(date) => { this.handleSetAndGetOfferCheck({ name: 'to_date', value: date }) }}
                                />
                            </div>
                    </FormGroup>
                    <hr className="bg-light" />
                    <FormGroup>
                        <Label className="ff-alt text-secondary-dark font-weight-normal">Website to claim deal:</Label>
                        <Input
                            value={offerCheckState?.claimDealOption}
                            onChange={(e) => { this.handleSetAndGetOfferCheck({ name: 'claimDealOption', value: e.target.value }) }}
                            type="select" bsSize="sm">
                            <option value="">Please Choose</option>
                            <option value="Not Applicable">Not Applicable</option>
                            <option value="Enter Link">Enter Link</option>
                        </Input>
                        {offerCheckState?.claimDealOption == "Enter Link" && (
                            <Input
                                value={offerCheckState?.claim_deal_link}
                                onChange={(e) => { this.handleSetAndGetOfferCheck({ name: 'claim_deal_link', value: e.target.value }) }}
                                className="mt-2"
                                type="text"
                                bsSize="sm"
                            />
                        )}
                    </FormGroup>
                    <hr className="bg-light" />
                    <FormGroup>
                        <Label className="ff-alt text-secondary-dark font-weight-normal">Terms, Details and Exclusions:</Label>
                        <Input
                            value={offerCheckState?.terms_details_exclusions}
                            onChange={(e) => { this.handleSetAndGetOfferCheck({ name: 'terms_details_exclusions', value: e.target.value }) }}
                            className="mt-2" bsSize="sm" type="textarea" rows="2" />
                    </FormGroup>
                </div>

                <div className="text-right mt-3">
                    <Button
                        onClick={() => { this.setAndGetOtherOffers(toggleItems, false) }}
                        className="mw"
                        color="primary"
                        size="sm">{'Cancel'}</Button>
                    <Button
                        disabled={(offerCheckState?.terms_details_exclusions == '' || offerCheckState?.special_offer == '') ? true : false}
                        // disabled={offerCheckState?.validateForSubmit && offerCheckState?.terms_details_exclusions == '' && offerCheckState?.special_offer == '' ? true : false}
                        onClick={() => { handleSubmitOtherOffers() }}
                        className="mw"
                        color="primary"
                        size="sm">{'Submit'}</Button>
                </div>
            </div>
        )
    }

    setAndGetOtherOffers = (item, edit) => {
        this.props.handleOtherOffersToggle(item, edit);
        let offerItems = this.props.getItems();
        this.setState({ otherOfferState: offerItems, mediaSet: null });
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_info} = this.props;
        if(my_profile_info && my_profile_info.id && authId && (authId == my_profile_info.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const {
            stateSet,
        } = this.props;

        let {
            otherOfferState,
            otherOffersData,
            checkInOffersData,
            inStoreOffersData,
        } = this.state;

        return (
            <div className="text-secondary-dark p-3 mb-3" style={{ backgroundColor: '#dac994' }}>
                <h2 className="mr-2">Other Offers</h2>
                <hr className="bg-dark" />

                {/* Check in offers */}
                {this.renderItems({ title: "Check In Offer", data: checkInOffersData, stateSet, toggleItem: 'isCheckInOfferToggle' })}
                <div>
                    <div className="mb-3">
                        <div>
                            <div className="text-right mb-2">
                                <Button
                                    className="p-0 text-secondary-dark font-weight-bold"
                                    onClick={() => { 
                                        if(!this.checkIfLoggedIn()){
                                            this.props.forceToggleLoginModal();
                                            return false;
                                        }
                                        this.setState({ onStoreOptions: '' });
                                        this.setAndGetOtherOffers('isCheckInOfferToggle', false) }}
                                    color="link" >
                                    {'Add'}
                                </Button>
                                <hr className="bg-secondary-dark" />
                            </div>
                            {otherOfferState &&
                                otherOfferState?.isCheckInOfferToggle ?
                                (this.renderAddItem({ media: false, toggleItems: 'isCheckInOfferToggle' })) : null}
                        </div>
                    </div>

                    {/* In store coupon */}
                    <div className="mb-3">
                        {this.renderItems({
                            title: 'In Store Coupon',
                            data: inStoreOffersData,
                            media: true,
                            stateSet,
                            toggleItem: 'inStoreCouponToggle'
                        })}
                        <div>
                            <div className="text-right mb-2">
                                <Button
                                    onClick={() => { 
                                        if(!this.checkIfLoggedIn()){
                                            this.props.forceToggleLoginModal();
                                            return false;
                                        }
                                        this.setState({ onStoreOptions: '' });
                                        this.setAndGetOtherOffers('inStoreCouponToggle', false) }}
                                    className="p-0 text-secondary-dark font-weight-bold"
                                    color="link" >
                                    {'Add'}
                                </Button>
                                <hr className="bg-secondary-dark" />
                            </div>
                            {otherOfferState &&
                                otherOfferState?.inStoreCouponToggle ?
                                (this.renderAddItem({ media: true, toggleItems: 'inStoreCouponToggle' })) : null}
                        </div>
                    </div>


                    {/* Other offer */}
                    {this.renderItems({
                        title: 'Other Offer',
                        data: otherOffersData,
                        stateSet,
                        toggleItem: 'inOtherOffer'
                    })}

                    <div>
                        <div className="text-right mb-2">
                            <Button
                                onClick={() => { 
                                    if(!this.checkIfLoggedIn()){
                                        this.props.forceToggleLoginModal();
                                        return false;
                                    }
                                    this.setState({ onStoreOptions: '' });
                                    this.setAndGetOtherOffers('inOtherOffer', false) }}
                                className="p-0 text-secondary-dark font-weight-bold"
                                color="link" >
                                {'Add'}
                            </Button>
                            <hr className="bg-secondary-dark" />
                        </div>

                        {otherOfferState &&
                            otherOfferState?.inOtherOffer ?
                            (this.renderAddItem({ media: false, toggleItems: 'inOtherOffer' })) : null}
                    </div>
                </div>
            </div >
        )
    }
}

const mapState = (state) => ({
    other_offers: state.business.other_offers,
    check_in_offers: state.business.check_in_offers,
    in_store_offers: state.business.in_store_offers,
    my_profile_info: state.user.my_profile,
});


const mapProps = (dispatch) => ({
    post_special_offer_comment: ({ data, listing_id, offer_type }) => dispatch(post_special_offer_comment({ data, listing_id, offer_type })),
    delete_special_offer: ({ id, listing_id, offer_type }) => dispatch(delete_special_offer({ id, listing_id, offer_type })),
    offer_helpful_or_not: (id, type, data) => dispatch(offer_helpful_or_not(id, type, data)),
});

export default connect(mapState, mapProps)(OtherOffers);