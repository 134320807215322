/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import { Container, Row, Col } from 'reactstrap';
export default class Awards extends Component {
   render() {
      return (
         <div>
            <AppHeader isEnableCover={false} />
            <div className="wk-static-wrapper">
               <Container>
                  <div className="wk-static-sub-head">
                     <h1>WikiReviews Awards</h1>
                  </div>
                  <div className="wk-static-content-body">
                     <p className="fs-14">Awards are given to users when they reach certain milestones on WikiReviews. The awards users receive will be placed in their profiles for other WikiReviews users to see! Continue making contributions and creating great reviews to receive more awards!
                  </p>
                     <Row>
                        <Col md={4}>
                           <div className="wk-static-sidebar-left">
                              <ul className="p-0">
                                 <li><a href="#AvatarAwards">Avatar Awards</a> </li>
                                 <li><a href="#BrightestStarAwards">Brightest Star Awards </a></li>
                                 <li><a href="#ContributionAvatarAwards">Contribution Avatar Awards </a></li>
                                 <li><a href="#FirstToAwards">First To Awards </a></li>
                                 <li><a href="#GemAwards">Gem Awards </a></li>
                                 <li><a href="#WikiLeaderboardAwards">Wiki Leaderboard Awards </a></li>
                              </ul>
                           </div>
                        </Col>
                        <Col md={8}>
                           <div className="wk-static-content-right">
                              <h2 id="AvatarAwards">AVATAR AWARDS</h2>
                              <ul>
                                 <li>Analyzer Avatar: This badge is given to active contributors who report any irregularities or issues with the site and analyze new features to ensure proper testing is done before going live.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-analyzer.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Category Supervisor: This badge is given to administrators who ensure that categories are properly maintained with quality information and ensure that the site is in tip top shape.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-category-supervisor.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Citation Avatar: This badge is given to administrators who help to ensure citations are properly done in the Buyer's Guide and also helps to find sources when required.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-citation-quote.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Coaching Buddy: This badge is given to administrators who provide coaching to new WikiReviewers.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-coaching-buddy.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>CRP Admin: This badge is given to administrators who watch over the Community Review Project section to ensure that categories are selected properly for projects and who also communicate with users who may have questions or concerns regarding community review projects
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-crp-admin.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Expert Reviewer: This badge is given to contributors who are considered experts by the community in a certain field. Users who are considered experts give very detailed reviews and are very well regarded in the community.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-expert-reviewer.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Hyperlocal Community: This badge is given to contributors who like to perform community outreach and become very involved with their local wiki community by coordinating local special events, offer guidance to local businesses and contributors, and help wherever they are needed.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-hyperlocal-community.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>PowerHelper: This badge is given to administrators who improve information in a multitude of ways, rate pictures and video and the general quality of content, and add content that is missing from WikiReviews.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-power-helper.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Problems and Solutions: This badge is given to contributors who provide solutions to issues users may have on WikiReviews.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-p-s.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Professional Admin: This badge is given to admins who help ensure that all information is correct on professional business pages.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-professional-admins.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Question and Answer Supervisor: This badge is given to contributors who are responsible for ensuring that questions and answers on WikiReviews are properly listed and are archived when necessary. They also incorporate questions and answers into the listing information when needed.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-q-a.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Recent Changed Patrol: This badge is given to contributors who patrol and monitor recently changed content on WikiReviews.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-recent-changed.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Subcategory Supervisor: This badge is given to administrators who ensure that specific subcategories are properly maintained with quality information and ensure that the site is in tip top shape.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-subcategory-supervisor.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>WikiReviews Guardian: This badge is given to contributors who primarily clean up and rid the site of vandalism and spam to improve the overall experience of the wiki community. These contributors also spend time patrolling users who have been found guilty of an infraction or who are on probation.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-wr-guardian.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>WikiReviews Herald: This badge is given to administrators who write and contribute to articles for the WikiReviews Herald.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-wr-herald.png" className="wk-awards-image" alt="awards" />
                                 </li>
                              </ul>
                              <h2 id="BrightestStarAwards">Brightest Star Awards</h2>
                              <p className="fs-14">Each month, 10 WikiReviewers with the highest quantity of reviews are awarded the Brightest Star award! The following are the Brightest Star awards starting with the highest quantity of reviews.
                               </p>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>
                                    {` Sun `}
                                    <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Sun.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                 <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Vega <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Vega.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Aldebran  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Aldebaran.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  Alpha  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Alpha Centauri.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  Cappela  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Capella.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  Polaris   <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Polaris.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Sirius    <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Sirius.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Arcturus    <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Arcturus.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Canopus     <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Canopus.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box mb-4">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Antares     <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Antares.png" alt="award" /></div>
                                 </div>
                              </div>
                              <h2 id="ContributionAvatarAwards">Contribution Avatar Awards</h2>
                              <p className="fs-14">User will receive Contribution points for each contribution they make. When they hit certain milestones, they will receive a Contribution award! The following are the awards and the number of contributions needed to obtain that award:
                               </p>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Rhodium – 10,000 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-rhodium.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Platinum – 7500 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-platinum.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Gold – 5,000 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-gold.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Ruthenium – 3500 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-ruthenium.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Iridium – 2000 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-iridum.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Osmium – 1000 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-osmium.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Palladium – 500 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-palladium.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Rhenium – 250 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-rhenium.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Silver – 100 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-silver.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Silver – 100 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-silver.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box mb-4">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Indium – 50 lifetime contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/email/avatar-indium.png" alt="award" /></div>
                                 </div>
                              </div>
                              <h2 id="FirstToAwards">First To Awards (Mobile and Desktop)</h2>
                              <p className="fs-14">WikiReviews users who are the first to contribute to listing pages will of course get credit for it! Not only will you receive an award for being the first user to contribute, but you will also have your profile linked to the listing when you’re the first to review it. You will receive your award based on the platform you contributed on (either your mobile device or desktop).
                               </p>
                              <h4 className="wk-awards-sub-title">Desktop First To Awards</h4>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> First to Add a Picture to a Listing Page <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to add pictures in listing.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> First to Add a Community Review Project to a Category or Subcategory <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First CRP project.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> First to Add a Listing to a Category or Subcategory  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to add listing.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  First to Add a Video to a Listing Page  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to add video in listing.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  First to Flag a Listing Page  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to flag.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  First to Improve a Listing Page   <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to improve.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  First to Review a Community Review Project  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to review CRP project.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>   First to Review a Listing  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First review.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>   First to Vote  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to vote.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>   First to Add a Tip to a Listing Page  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to write tip on listing.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box mb-4">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>   First to Add a Warning to a Listing Page  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to write warning on listing.png" alt="award" /></div>
                                 </div>
                              </div>
                              <h4 className="wk-awards-sub-title">Mobile First To Awards</h4>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>   First to Add a Picture to a Listing Page  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to add picture on listing from mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>   First to Add a Community Review Project to a Category or Subcategory  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to write warning on listing mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>   First to Add a Listing to a Category or Subcategory   <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to add listing mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> First to Add a Video to a Listing Page   <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First video review from mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> First to Flag a Listing Page   <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First-to-flag-mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  First to Improve a Listing Page   <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First-to-improve-mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  First to Review a Community Review Project    <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First CRP project mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  First to Review a Listing    <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First review on mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>  First to Use UPC Bar Code Scanning <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First-to-vote-mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small>   First to Use Speech to Text <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First speech to text review.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> First to Vote <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First-to-vote-mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> First to Add a Tip to a Listing Page  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to write tip on listing mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box mb-4">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> First to Add a Warning to a Listing Page   <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/First to write warning on listing mobile.png" alt="award" /></div>
                                 </div>
                              </div>
                              <h2 id="GemAwards">Gem Awards</h2>
                              <p className="fs-14">User will receive Contribution points for each contribution they make. When they hit certain milestones, they will receive a Gem award! Although you will receive an avatar award based on your contributions, you will also receive a Gem award! The following are the Gem awards and the number of contributions needed to obtain that award:
                               </p>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Pink Star Diamond: 1,000 Contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Pink Star Diamond.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Painite: 900 Contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Painite.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Musgravite: 700 Contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Musgravite.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Jadeite: 500 Contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Jadeite.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Alexandrite: 300 Contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Alexandrite.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Red Beryl: 200 Contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Red Beryl.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Red Beryl: 200 Contributions <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Benitoite.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Black Opal: 50 Contributions  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Black Opal.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Taaffeite: 25 Contributions  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Taaffeite.png" alt="award" /></div>
                                 </div>
                              </div>
                              <div className="wk-awards-box mb-4">
                                 <div className="wk-awards-content fs-14">
                                    <small>
                                       <span role="img" aria-label="award-label">⚫</span>
                                    </small> Tanzanite: 10 Contributions  <div className="wk-awards-img"><img src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Tanzanite.png" alt="award" /></div>
                                 </div>
                              </div>
                              <h2 id="WikiLeaderboardAwards">Wiki Leaderboard Awards</h2>
                              <p className="fs-14">Each month, users who received the most Nailed It, LOL’s, and Priceless marks on their reviews will receive a spot on our Wiki Leaderboard. <br />
                                                    Aside from being able to land a spot on our Leaderboard, users will also be able to receive additional awards based on their actions on the site. These awards are given on a monthly and lifetime basis.
                               </p>
                              <ul>
                                 <li>WikiScholar: Users will receive this award when they make outstanding contributions to the Buyer’s Guide section.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/wikischolar.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>WikiGeoPowerHelper: Users will receive this award when they contribute the most location based flags, improvements, and listing page adds.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/wiki-geopowerhelper.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>WikiPowerHelper: Users will receive this award when they contribute the most flags, improvements, and listing page adds.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/wiki-powerhelper.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>Users will receive this award when they create the most location based reviews.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/wiki-georeviewer.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>WikiGuru: Users will receive this award when they create the most overall contributions to the WikiReviews platform.
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/top-wikireviewer.png" className="wk-awards-image" alt="awards" />
                                 </li>
                                 <li>WikiInfluencer: Users will receive this award when they have the highest number of friends and followers..
                                      <img src="https://userdatawikireviews.s3.amazonaws.com/images/email/wikiinfluencer.png" className="wk-awards-image" alt="awards" />
                                 </li>
                              </ul>
                           </div>
                        </Col>
                     </Row>
                  </div>
               </Container>
            </div>
         </div>
      )
   }
}
