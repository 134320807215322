import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { capitalizeFirstLetter, detectlink } from '../../../utils/utilities';
import ReactHtmlParser from 'react-html-parser';
import { callApi, responseCodeValidator } from '../../../utils/apiCaller';
import querystring from "query-string";
import DataTable from './dataTable';

const UserReviews = ({ isModalOpen, user, toggleModal }) => {
    const initialState = {
        reviews:[],
        pageCount:0,
        loading:true,
        currentPage:0,
    }
    const [state,setState] =useState(initialState);
    const {
        reviews,
        pageCount,
        loading,
        currentPage,
    } = state;

    const pageMaxCount = 10;
    const columnFields = ["name","review", "created_date", "action"];
    
    const optimizedColumns = columnFields.map((col) => {
        if (col === "name") {
            return {
                Header: `Listing Name`,
                accessor: col,
                Cell: ({ row }) => <span><Link to={`/${row.original.listing_type}/${row.original.associated_to.id}`}>{row.original.associated_to.name}</Link></span>,
            };
        }

        if (col === "review") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span className='review-content'>{ReactHtmlParser(detectlink(row.original?.review))}</span>,
            };
        }

        if (col === "created_date") {
            return {
                Header: "Created On",
                accessor: col,
                Cell: ({ row }) => <span>{moment(row.original.created_date_time).format("MMM DD, YYYY")}</span>,
            };
        }



        if (col === "action") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span className={`btn btn-sm ${row.original.is_active ? `disable-button` : `enable-button`}`} onClick={() => { toggleReviewStatus({review: row.original }) }} >{row.original.is_active ? `Disable` : `Enable`}</span>,
            };
        }
    });

    useEffect(() => {
        fetchUserReviews({page:1});
    }, [user]);

    const fetchUserReviews=({page})=>{
        
        const params = {
            entry:'',
            user:user.id,
            asset:'',
            page
        }

        const queryParams = querystring.stringify(params);
        const url = `/api/adminreviews/?${queryParams}`;

        setState((prevState) => ({ ...prevState, loading: true }));

        callApi(url, 'GET').then((res) => {
            if (res && responseCodeValidator(String(res.code))) {
                setState((prevState) => ({
                    ...prevState,
                    currentPage: page - 1,
                    reviews: res.results,
                    pageCount: Math.ceil(res?.count / pageMaxCount),
                    loading: false
                }));
            }
        });
    }

    const toggleReviewStatus = ({ review }) => {

        const isReviewActive = review?.is_active;

        const body = {
            id: review.id,
            is_active: isReviewActive ? `Disable` : `Enable`,
        }
        setState((prevState) => ({ ...prevState, loading: true }));
        callApi(`/api/adminreviews/${review.id}/`, 'PUT', body).then((res) => {
            if (res && responseCodeValidator(String(res.code))) {
                fetchUserReviews({ page: currentPage + 1 });
            }
        });
    }

    const columns = useMemo(() => optimizedColumns, [optimizedColumns]);
    const Reviews = useMemo(() => reviews, [reviews]);

    
    return (
        <>
            <Modal
                className="custom-pop-change"
                centered
                isOpen={isModalOpen}
                toggle={toggleModal}>
                <ModalHeader className="px-0" toggle={toggleModal}>
                    {`${user.user.first_name} ${user.user.last_name}`}
                </ModalHeader>
                <ModalBody className="wk-compose-new-messagebody p-3 modal-dataTable">
                    <DataTable
                        columns={columns}
                        data={Reviews}
                        pageCount={pageCount}
                        loading={loading}
                        fetchData={fetchUserReviews}
                        currentPage={currentPage}
                    />
                </ModalBody>
                <ModalFooter className="justify-content-start px-0">

                </ModalFooter>
            </Modal >
        </>

    )
}

export default UserReviews;