import { callApi } from "../../utils/apiCaller";
import querystring from "query-string";

export const ALL_CATEGORIES = "ALL_CATEGORIES";
export const ALL_MOVIE_CATEGORIES = "ALL_MOVIE_CATEGORIES";
export const ALL_PRODUCT_CATEGORIES = "ALL_PRODUCT_CATEGORIES";
export const ADD_MOVIE_LISTING = "ADD_MOVIE_LISTING";
export const ADD_PRODUCT_LISTING = "ADD_PRODUCT_LISTING";
export const SEARCH_DATA = "SEARCH_DATA";
export const ALL_CAPTION_CATEGORIES = "ALL_CAPTION_CATEGORIES";
// export const UPLOAD_FILE_TYPE = "UPLOAD_FILE_TYPE";
export const UPLOAD_FILE_TYPE = "UPLOAD_MEDIA_DATA";
export const GET_PRODUCT_SUBCATEGORY_LISTING = "GET_PRODUCT_SUBCATEGORY_LISTING";
export const GET_PRODUCT_ANOTHSUBCATEGORY_LISTING = "GET_PRODUCT_ANOTHSUBCATEGORY_LISTING";
export const GET_PRODUCT_OTHERSUBCATEGORY_LISTING = "GET_PRODUCT_OTHERSUBCATEGORY_LISTING";
export const GET_PROFESSIONAL_SUBCATEGORY_LISTING = "GET_PROFESSIONAL_SUBCATEGORY_LISTING";
export const GET_PROFESSIONAL_ANOTHSUBCATEGORY_LISTING = "GET_PROFESSIONAL_ANOTHSUBCATEGORY_LISTING";
export const GET_PROFESSIONAL_OTHERSUBCATEGORY_LISTING = "GET_PROFESSIONAL_OTHERSUBCATEGORY_LISTING";
export const ALL_PROFESSIONAL_CATEGORIES = "ALL_PROFESSIONAL_CATEGORIES";

export const GET_DRIECTOR_LISTING = "GET_DRIECTOR_LISTING";
export const GET_WRITER_LISTING = "GET_WRITER_LISTING";
export const GET_PRODUCER_LISTING = "GET_PRODUCER_LISTING";
export const GET_CAST_LISTING = "GET_CAST_LISTING";

export const ALL_BUSINESS_CATEGORIES = "ALL_BUSINESS_CATEGORIES";
export const ADD_BUSINESS_LISTING = "ADD_BUSINESS_LISTING";
export const ADD_PROFESSIONALS_LISTING = "ADD_PROFESSIONALS_LISTING";
export const GET_BUSINESS_SUBCATEGORY_LISTING = "GET_BUSINESS_SUBCATEGORY_LISTING";
export const GET_MOVIE_SUBCATEGORY_LISTING = "GET_MOVIE_SUBCATEGORY_LISTING";
export const GET_CATEGORY_MENU_LISTING = "GET_CATEGORY_MENU_LISTING";
export const GET_SUBCATEGORY_MENU_LISTING = "GET_SUBCATEGORY_MENU_LISTING";

export const ALL_CRP_CATEGORIES= "ALL_CRP_CATEGORIES";
export const GET_CRP_SUBCATEGORY_LISTING= "GET_CRP_SUBCATEGORY_LISTING";
export const GET_CRP_ANOTHSUBCATEGORY_LISTING= "GET_CRP_ANOTHSUBCATEGORY_LISTING"
export const GET_PATROL_CRP= "GET_PATROL_CRP"
export const GET_PATROL_CORE= "GET_PATROL_CORE"
export const GET_PATROL_MEDIA= "GET_PATROL_MEDIA"
export const GET_PATROL_REVIEW_LISTING= "GET_PATROL_REVIEW_LISTING"
export const GET_PATROL_REVIEW= "GET_PATROL_REVIEW"
export const GET_IMPROVE_CORE= "GET_IMPROVE_CORE"
export const GET_IMPROVE_MEDIA= "GET_IMPROVE_MEDIA"
export const GET_IMPROVE_TECHINCAL= "GET_IMPROVE_TECHINCAL"
export const GET_VOTE_LISTING= "GET_VOTE_LISTING"
export const GET_FLAG_LISTING= "GET_FLAG_LISTING"
export const SUBMIT_PATROL_REVIEW="SUBMIT_PATROL_REVIEW"
export const GET_USER_STATUS="GET_USER_STATUS"



export const allCategories = (data) => ({
  type: ALL_CATEGORIES,
  payload: data,
});

export const allMovieCategories = (data) => ({
  type: ALL_MOVIE_CATEGORIES,
  payload: data,
});

export const allMenuCategories = (data) => ({
  type: GET_CATEGORY_MENU_LISTING,
  payload: data,
});
export const allSubMenuCategories = (data) => ({
  type: GET_SUBCATEGORY_MENU_LISTING,
  payload: data,
});

export const allProductCategories = (data) => ({
  type: ALL_PRODUCT_CATEGORIES,
  payload: data,
});

export const allProfessionalsCategories = (data) => ({
  type: ALL_PROFESSIONAL_CATEGORIES,
  payload: data,
});

export const allCaptionCategories = (data) => ({
  type: ALL_CAPTION_CATEGORIES,
  payload: data,
});

export const addMovieListingAction = (data) => ({
  type: ADD_MOVIE_LISTING,
  payload: data,
});

export const addProductListingAction = (data) => ({
  type: ADD_PRODUCT_LISTING,
  payload: data,
});

export const addBusinessListingAction = (data) => ({
  type: ADD_BUSINESS_LISTING,
  payload: data,
});
export const addProfessionalsListingAction = (data) => ({
  type: ADD_PROFESSIONALS_LISTING,
  payload: data,
});



export const searchData = (data) => ({
  type: SEARCH_DATA,
  payload: data,
});

export const uploadFileAction = (data) => ({
  type: UPLOAD_FILE_TYPE,
  payload: data,
});

export const allBusinessCategories = (data) => ({
  type: ALL_BUSINESS_CATEGORIES,
  payload: data,
});

export const allCrpCategories = (data) => ({
  type: ALL_CRP_CATEGORIES,
  payload: data,
});

export const initFetchCategories = (params, actionCreator) => {
  return async (dispatch) => {
    const query = querystring.stringify(params);
	const response = await callApi(
      `/api/taxonomy/${query ? "?" + query : ""}`,
      "GET"
    );

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(actionCreator(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const addMovieListing = (params) => {
  return async (dispatch) => {
    const response = await callApi(`api/movies/`, "POST", params);

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(addMovieListingAction(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const addProductListing = (params) => {
  return async (dispatch) => {
    const response = await callApi(`api/products/`, "POST", params);

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(addProductListingAction(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const addBusinessListing = (params) => {
  return async (dispatch) => {
    const response = await callApi(`api/business/`, "POST", params);

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(addBusinessListingAction(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};
export const addprofessionalsListing = (params) => {
  return async (dispatch) => {
    const response = await callApi(`api/professionalsbase/`, "POST", params);

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(addProfessionalsListingAction(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const fetchData = (params) => {
  return async (dispatch) => {
    const query = querystring.stringify(params);

    const response = await callApi(
      `/api/search/professional/${query ? "?" + query : ""}`,
      "GET"
    );

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(searchData(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const upload_media = (type, data, index) => {
  return (dispatch) => {
    callApi(
      `/upload/multiuploader/?album=&instance=moviesdrawer&image=undefined&doc=undefined`,
      "POST",
      data,
      true
    ).then((response) => {
      response["index"] = index;
      if (response && response.code !== 400 && response.code !== 500) {
        response["isUpload"] = true;
        return dispatch(uploadFileAction(response));
      } else {
        response["isUpload"] = false;
      }
    });
  };
};


export const initFetchMenuCategories = (params, actionCreator) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${params.category}&depth=true&delsel=&ancestor=&sub_cat=&drawerChangeSequence=true`,
      "GET"
    );

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(actionCreator(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const initFetchMenuSubCategories = (params, actionCreator) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${params.category}&depth=false&delsel=&ancestor=&sub_cat=`,
      "GET"
    );
    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(actionCreator(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const initFetchRateCrpCategories = (params, actionCreator) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${params.category}&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    );

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(actionCreator(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};
export const get_crpSub_listing_ = (data) => ({ type: GET_CRP_SUBCATEGORY_LISTING, payload: data });
export const get_crpanotherSub_listing_ = (data) => ({ type: GET_CRP_ANOTHSUBCATEGORY_LISTING, payload: data });

export const initFetchRateCrpSubCategories = (categoryId, type) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${categoryId}&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
      if (type == "category_id") {
        return dispatch(get_crpSub_listing_(response));
      } else if (type == "subcategory_id") {
        return dispatch(get_crpanotherSub_listing_(response));
      } 
    }
  };
};


export const initFetchProductCategories = (params, actionCreator) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${params.category}&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    );

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(actionCreator(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const initFetchBusinessCategories = (params, actionCreator) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${params.category}&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    );

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(actionCreator(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const get_productSub_listing_ = (data) => ({ type: GET_PRODUCT_SUBCATEGORY_LISTING, payload: data });
export const get_productanotherSub_listing_ = (data) => ({ type: GET_PRODUCT_ANOTHSUBCATEGORY_LISTING, payload: data });
export const get_productotherSub_listing_ = (data) => ({ type: GET_PRODUCT_OTHERSUBCATEGORY_LISTING, payload: data });
export const initFetchProductSubCategories = (categoryId, type) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${categoryId}&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
      if (type == "category_id") {
        return dispatch(get_productSub_listing_(response));
      } else if (type == "subcategory_id") {
        return dispatch(get_productanotherSub_listing_(response));
      } else if (type == "anothersubcategory_id") {
        return dispatch(get_productotherSub_listing_(response));
      }
    }
  };
};


export const get_movieSub_listing_ = (data) => ({ type: GET_MOVIE_SUBCATEGORY_LISTING, payload: data });

export const initFetchMovieSubCategories = (categoryId, type) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${categoryId}&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
      if (type == "category_id") {
        return dispatch(get_movieSub_listing_(response));
      } 
    }
  };
};

export const clear_cat_sub_product = () => {
  return (dispatch) => {
    dispatch(get_productSub_listing_([]));
    dispatch(get_productanotherSub_listing_([]));
    dispatch(get_productotherSub_listing_([]));
  };
};
export const initFetchProfessionalsCategories = (params, actionCreator) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${params.category}&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    );

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(actionCreator(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const get_businessSub_listing_ = (data) => ({ type: GET_BUSINESS_SUBCATEGORY_LISTING, payload: data });

export const initFetchBusinessSubCategories = (categoryId, type) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${categoryId}&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
      if (type == "category_id") {
        return dispatch(get_businessSub_listing_(response));
      }
    }
  };
};

export const get_professionalSub_listing_ = (data) => ({ type: GET_PROFESSIONAL_SUBCATEGORY_LISTING, payload: data });
export const get_professionalanotherSub_listing_ = (data) => ({ type: GET_PROFESSIONAL_ANOTHSUBCATEGORY_LISTING, payload: data });
export const get_professionalotherSub_listing_ = (data) => ({ type: GET_PROFESSIONAL_OTHERSUBCATEGORY_LISTING, payload: data });
export const initFetchProfessionalSubCategories = (categoryId, type) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/taxonomy/?category=${categoryId}&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
      if (type == "category_id") {
        return dispatch(get_professionalSub_listing_(response));
      } else if (type == "subcategory_id") {
        return dispatch(get_professionalanotherSub_listing_(response));
      } else if (type == "anothersubcategory_id") {
        return dispatch(get_professionalotherSub_listing_(response));
      }
      return dispatch(get_businessSub_listing_(response));
    }
  }

};

export const clear_cat_sub_professional = () => {
  return (dispatch) => {
    dispatch(get_professionalSub_listing_([]));
    dispatch(get_professionalanotherSub_listing_([]));
    dispatch(get_professionalotherSub_listing_([]));
  }
}
export const clear_cat_sub_business = () => {
  return (dispatch) => {
    dispatch(get_businessSub_listing_([]));
  };
};

export const get_director_listing_ = (data) => ({ type: GET_DRIECTOR_LISTING, payload: data });
export const get_writer_listing_ = (data) => ({ type: GET_WRITER_LISTING, payload: data });
export const get_producer_listing_ = (data) => ({ type: GET_PRODUCER_LISTING, payload: data });
export const get_cast_listing_ = (data) => ({ type: GET_CAST_LISTING, payload: data });

export const search_moviesMembers = (value, type) => {
  return async (dispatch) => {
	const response = await callApi(
      `/api/search/professional/?q=${value}&sensor=false`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
      if (type == "director") {
        return dispatch(get_director_listing_(response));
      } else if (type == "writer") {
        return dispatch(get_writer_listing_(response));
      } else if (type == "producer") {
        return dispatch(get_producer_listing_(response));
      }else if (type == "cast") {
        return dispatch(get_cast_listing_(response));
      }
    }
  }

};


export const get_patrol_crp_ = (data) => ({ type: GET_PATROL_CRP, payload: data });
export const get_patrol_movie_core_ = (data) => ({ type: GET_PATROL_CORE, payload: data });
export const get_patrol_movie_media_ = (data) => ({ type: GET_PATROL_MEDIA, payload: data });
export const get_patrol_movie_review__ = (data) => ({ type: GET_PATROL_REVIEW_LISTING, payload: data });




export const get_patrol_crp = (categoryId, type,listType) => {
  return async (dispatch) => {
	const response = await callApi(
      `/api/patrol/?contest=&type=${type}&list=${listType}&next=&taxonomy_id=${categoryId}`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
	  if (type == "corelisting") {
        return dispatch(get_patrol_movie_core_(response));
      } else if (type == "review") {
        return dispatch(get_patrol_movie_review__(response));
      } else if (type == "media") {
        return dispatch(get_patrol_movie_media_(response));
      }else if (type == "communityreviews") {
        return dispatch(get_patrol_crp_(response));
      }
    }
  };
};

export const get_patrol_review_ = (data) => ({ type: GET_PATROL_REVIEW, payload: data });

export const get_patrol_review = (reviewId) => {
  return async (dispatch) => {
	const response = await callApi(
      `/api/reviews/?entry=&user=&asset=&id=${reviewId}`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
     return dispatch(get_patrol_review_(response));
    }
  };
};

export const get_improve_movie_core_ = (data) => ({ type: GET_IMPROVE_CORE, payload: data });
export const get_improve_movie_media_ = (data) => ({ type: GET_IMPROVE_MEDIA, payload: data });
export const get_improve_movie_technical_ = (data) => ({ type: GET_IMPROVE_TECHINCAL, payload: data });

export const get_improve_listing = (categoryId, type,listType) => {
  return async (dispatch) => {
	const response = await callApi(
      `/api/improve/?next=&all=&id=&type=${type}&list=${listType}&taxonomy_id=${categoryId}&page=1`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
	  if (type == "listing") {
        return dispatch(get_improve_movie_core_(response));
      }else if (type == "media") {
        return dispatch(get_improve_movie_media_(response));
      }else if (type == "technicaldetails") {
        return dispatch(get_improve_movie_technical_(response));
      }
	  
    }
  };
};


export const get_vote_listing_ = (data) => ({ type: GET_VOTE_LISTING, payload: data });

export const get_vote_listing = (categoryId, type,listType) => {
  return async (dispatch) => {
	const response = await callApi(
      `/api/flags/?type=${type}&category=${listType}&flag_id=&object_id=`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
	  return dispatch(get_vote_listing_(response));
    }
  };
};

export const get_flag_listing_ = (data) => ({ type: GET_FLAG_LISTING, payload: data });

export const get_flag_listing = (categoryId, type,listType) => {
  return async (dispatch) => {
	const response = await callApi(
      `/api/flags/?type=${type}&category=${listType}&flag_id=&object_id=`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
	  return dispatch(get_flag_listing_(response));
    }
  };
};

export const submit_patrol_review_ = (data) => ({
  type: SUBMIT_PATROL_REVIEW,
  payload: data,
});
export const submit_patrol_review = (params) => {
  return async (dispatch) => {
    const response = await callApi(`/api/patrol/?contest=&type=&list=&next=&taxonomy_id=`, "POST", params);

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        return dispatch(submit_patrol_review_(response));
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

/*export const submit_improve_ = (data) => ({
  type: SUBMIT_IMPROVE,
  payload: data,
});*/
export const submit_improve = (id,data) => {
    return (dispatch) => {
        callApi(`/api/improve/${id}/?next=&all=&type=listing&list=&taxonomy_id=&page=`, "PATCH", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                /*toast(`${message}!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_business_detail(id));*/
            }
        });
    };
};

export const submit_improve_tech_media = (id,type,data) => {
    return (dispatch) => {
        callApi(`/api/improve/?next=&all=&id=&type=${type}&list=&taxonomy_id=&page=`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                /*toast(`${message}!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_business_detail(id));*/
            }
        });
    };
};


export const clearvoteList = () => {
  return (dispatch) => {
  dispatch(get_vote_listing_(""));
  };
};


export const getUserStatus_ = (data) => ({ type: GET_USER_STATUS, payload: data });

export const getUserStatus = () => {
  return async (dispatch) => {
	const response = await callApi(`/api/blockordeleteuser/`,"GET");
    if (response && response?.code !== 400 && response?.code !== 500) {
   
	  return dispatch(getUserStatus_(response));
    }
  };
};


export const unblock_my_account = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
    callApi("/api/blockordeleteuser/", "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {

        return dispatch(getUserStatus());
        resolve()      
      }
    });
  });
  };
};
