/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import { Container, Row, Col } from 'reactstrap';
export default class Press extends Component {
    render() {
        return (
            <div>
            <AppHeader isEnableCover={false} />
            <div className="wk-static-wrapper">
              <Container>
                  <div className="wk-static-sub-head">
                     <h1>Media And Press</h1>
                  </div>
                  <div className="wk-static-content-body">
                     <Row>
                        <Col md={4}>
                           <div className="wk-static-sidebar-left">
                              <h2 className="wk-press-head">PRESS</h2>
                              <h5 className="press-sub-head"><b>For Press Inquiries Contact WikiReviews</b>
                               </h5>
                               <p className="fs-14">Email : press@wikireviews.com
                               </p>
                               <p className="fs-14">Phone : 1 (310)993-6486
                               </p>
                               <h5 className="press-sub-head"><b>Overview</b>
                               </h5>
                               <ul className="wk-press-list">
                                    <li><a href="#">WikiReviews Factsheet</a></li>
                                    <li><a href="#">Company anouncement</a></li>
                                    <li><a href="#">Brand Guidelines</a></li>
                                    <li><a href="#">WikiReviews Blog</a></li>
                                    <li><a href="#">WikiReviews B-Roll</a></li>						
                              </ul>
                              <h5 className="press-sub-head"><b>Toolkit</b>
                               </h5>
                               <ul className="wk-press-list">
                                    <li><a href="#">Print Page</a></li>
                                    <li><a href="#">Email Page</a></li>
                                    <li><a href="#">Contact PR</a></li>					
                              </ul>
                              <h5 className="press-sub-head"><b>Mobile app</b>
                               </h5>
                           </div>                            
                        </Col>
                        <Col md={8}>
                            <div className="wk-static-content-right">
                               <h2 id="General-Questions">OVERVIEW</h2>
                               <p className="fs-14"><u><b>What is WikiReviews?</b></u>
                               </p>
                               <p className="fs-14">WikiReviews is an online, community-driven social review website that helps people find, write and read reviews of local and nationwide (USA & Canada) businesses, products services and films. WikiReviews is also a social site where users are encouraged to recommend things they like so that this can easily be shared with their friends and followers on the site. WikiReviews also allows the community to post their own personal projects that they want the community to review. WikiReviews goal is to be the largest online social site for reviews about just about anything and everything in the world. We can only accomplish this with the help of WikiCommunity volunteers all over the world.
                               </p>
                               <p className="fs-14"><u><b>Benefites of WikiReviews</b></u>
                               </p>
                               <p className="fs-14">A Wiki is a website where users can add, modify or delete content via their web browser.
                               </p>
                               <p className="fs-14">First, we would love for you to contribute to the reviews of products, services, businesses and movies that we have on our site. If you don’t see a particular item on our site, please add it to our database so that others can add their reviews. More info is always better, so be sure to include everything you know, whether that’s a business address, phone number, movie title or product name. If you really enjoy helping, you can also add images and videos or rate other users’ reviews. This can all be done from the Participate Section at the bottom menu bar of our site.
                               </p>
                               <p className="fs-14">No! WikiReviews is completely free to use for all consumer users and business owners.
                               </p>
                               <p className="fs-14">We make money through users clicking on the advertisements shown on the various pages of our site. Also, unlike other review sites, we do not solicit businesses listed on our site for advertising money nor can businesses pay us money to show up higher on our search results page. And most importantly, businesses cannot pay us to modify their star ratings. Star ratings are controlled by users and the community. This money will help us cover our web hosting costs as well as other administrative costs required to keep the site operating. The bulk of our workforce is made up of volunteers working hard to make our site the most comprehensive review site on the web.
                               </p>
                               <p className="fs-14">You will see that we have a very diverse user base of folks who use our site. You'll find a wide range of people on WikiReviews, including locals who are "in the know" about what's cool and happening in their city, visitors who want to get an insider's local perspective, and anyone trying to find a great local business. In our Community Review Projects section, you will find all different types of users from business owners who want feedback on potential logos to musicians wanting to know about their music to even artists.
                               </p>
                               <p className="fs-14">Studies show that people enjoy reviewing things they like and things they do not like. People like to give kudos to those things they really enjoyed and similarly, people like to caution others about their experiences. WikiReviews is not just a site full of complaints but rather a balanced site with reviews of all types.
                               </p>
                               <p className="fs-14"><u><b>WikiReviews Mobile App</b></u>
                               </p>
                               <p className="fs-14">WikiReviews will soon to be the largest community run business, movie, product, professional and actor review site. At WikiReviews the community is in charge from disputing reviews to voting on whether reviews should be deleted or filtered. Be a part of the largest social reviews network.
                               </p>
                               <p className="fs-14"><u><b>WikiReviews Features:</b></u>
                               </p>
                               <p className="fs-14">Users can create reviews for just about anything by writing, dictating or recording reviews. Users can also create their own projects to be reviewed by the community.
                               </p>
                               <ul>
                                    <li>Discover trending businesses, products &amp; movies in the USA &amp; Canada</li>
                                    <li>Easily create reviews by writing, dictating or recording reviews</li>
                                    <li>Upload your own personal projects to get reviewed by the community.</li>
                                    <li>Discover great local businesses in any category</li>
                                    <li>Watch movie trailers. Lookup movie showtimes and buy tickets.</li>
                                    <li>Connect with your friends and be kept up to date on their reviews and 
                                        recommendations
                                    </li>
                                    <li>Compare products to find out what reviewers like best.</li>
                                    <li>Watch Video Reviews that give you a much clearer picture before you 
                                        purchase  anything.
                                    </li>
                                    <li>Scan in a barcode of products you like and quickly read the reviews.</li>
                                    <li>Find the best rated menu items in a restaurant to order.</li>
                                    <li> Peel behind general five star ratings by getting detailed star ratings for 
                                         every listing.
                                    </li>
                                    <li>Set your favorite Local Theater as your Home Theater and easily get 
                                    showtimes, tickets and trailers for every movie playing there.</li>
                                    <li>Ask questions and problems and post answers and solutions for 
                                    common issues with products, movies and businesses.</li>
                                    <li>Look up a business address, phone number, email, website, hours of 
                                    operation and more.</li>
					            </ul>
                                <p className="fs-14">Download the App Today and See What Everyone's Talking About!
                               </p>
                            </div>
                        </Col>
                     </Row>
                  </div>
              </Container>
            </div>
        </div>
        )
    }
}
