import React, { Component } from 'react'
import { FormGroup, Button, Input, Label, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CollapseBasic from '../../atoms/collapse';
import { callApi } from '../../../utils/apiCaller'
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
// import Moment from 'moment';
class TechnicalDetails extends Component {
  constructor() {
    super()

    this.setItemData = false;
    this.state = {
      selectedItem: null,
      textInput: '',
      isToggleAddAnother: false,
      dataItems: {},
      storyline: null,
      duration: null,
      currentYear: null,
      isLessTrue: false,
      readMoreTextObj:{}
    }

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.additional_info && Object.keys(nextProps?.additional_info).length) {
      this.setState({ dataItems: nextProps?.additional_info });
    }

  }

  componentDidMount() {
    this.setItemData = true;
  }

  componentDidUpdate() {
    let nextProps = this.props;
    let { dataItems } = this.state;
    if (nextProps?.additional_info && Object.keys(dataItems).length !== Object.keys(dataItems).length) {
      this.setItemData = true;
      this.setState({ dataItems: nextProps?.additional_info[0] });
    }
  }

  handlleSubmitData = async () => {
    const { selectedItem, textInput } = this.state;
    const { id } = this.props;
    let data = { "name": selectedItem, "info": textInput };

    try {
      let response = await callApi(`/api/movies/${id}/`, 'PUT', data);
      if (response && (response?.code == 201 || response?.code == 200)) {
        this.props.fetchByItemId({ movieId: id });
        this.setState({isToggleAddAnother:false})
      }
    } catch (err) {
    }
  }
  handlleDeleteData = async (dt, item) => {
   const  infoData = dt.value
    const { id } = this.props;

    let data = { "delete_add_info": true, "name": item, "info":infoData };
    try {

      let response = await callApi(`/api/movies/${id}/`, 'PUT', data);
      if (response && (response?.code == 201 || response?.code == 200)) {
        this.props.fetchByItemId({ movieId: id });

      }
    } catch (err) {
    }
  }
  toggleLessMore = (item) => {
    const {readMoreTextObj} = this.state;

    let readMoreTextObjCopy = {...readMoreTextObj}   
   
    if(!!!readMoreTextObjCopy?.[item]){
      readMoreTextObjCopy[item] = true
    }else{
      readMoreTextObjCopy[item] = !readMoreTextObjCopy?.[item]
    }
    this.setState({readMoreTextObj:readMoreTextObjCopy})
  }

  checkIfLoggedIn = () => {
    const authId = localStorage.getItem('profileId');
    const {my_profile} = this.props;
    if(my_profile && my_profile.id && authId && (authId == my_profile.id)){
        return true;
    }else{
        return false;
    }
  }

  render() {
    const { my_profile } = this.props;
    let { isToggleAddAnother, dataItems, selectedItem, textInput, isLessTrue,readMoreTextObj } = this.state;
    
    let canadaRatings = {};

    if (Object.keys(this?.state?.dataItems).length) {
      canadaRatings = this?.state?.dataItems['Canada Ratings'];
    }

    if (!Object.keys(dataItems).length) {
      return null;
    } else {
      return (
        <>
          <CollapseBasic title="Technical Details" containerClass="bg-transparent type-white" bodyClass="mt-2 text-white" isOpen={false}>
            <CollapseBasic title="Canada Ratings" containerClass="bg-transparent type-white" bodyClass="mt-2 text-white" isOpen={false} size="sm" noPadding={true}>
              <ul className="list-unstyled">
                {canadaRatings && canadaRatings && Object.keys(canadaRatings).length > 0 ?
                  Object.keys(canadaRatings).sort().map((key, i) => (
                    <li key={i}>
                      <div className="d-flex mx-n2">
                        <div className="px-2 flex-100" style={{whitSpace: "nowrap", width: "100px",  overflow: "hidden",textOverflow: "ellipsis"}}>
                          <span className="font-weight-bold">
                            {key}{':'}
                          </span>
                        </div>
                        <div className="px-2 col wb-break-word">
                          {canadaRatings[key] && Array.isArray(canadaRatings[key]) && canadaRatings[key]?.length ?
                            canadaRatings[key].map((item, index) => (
                              <div className="d-flex align-items-center" key={index}>
                                <div className="px-2 col">
                                  {item.value}
                                </div>
                              </div>
                            ))
                            : ""}
                        </div>
                      </div>
                    </li>
                  ))
                  : (<div>
                    <span className="small">
                      <i>
                        {'No Rating to Display'}
                      </i>
                    </span>
                  </div>)
                }
              </ul>
            </CollapseBasic>

            <div className="mt-2">

              <ul className="list-unstyled fs-13">
                {dataItems && Object.keys(dataItems).length ?

                  Object.keys(dataItems).map(item => {
                    return item !== "Canada Ratings" && (
                      <li>
                        <div className="d-flex mx-n2">
                          <div className="px-2 flex-100" style={{whitSpace: "nowrap", width: "100px",  overflow: "hidden",textOverflow: "ellipsis"}}>
                            <span className="font-weight-bold" title={item.charAt(0).toUpperCase() + item.slice(1).replace(/_/g, " ")}>
                              {item == "duration" ? "Duration" :
                                item == "mb-rating" ? "Mb-rating" :
                                  item == "release_date" ? "Release Date" :
                                    item == "storyline" ? "Story Line" :
                                      item == "USA Rating" ? "USA Rating" :
                                        item == "camera" ? "Camera" : `${item.charAt(0).toUpperCase()}${item.slice(1).replace(/_/g, " ")}`}
                            </span>
                          </div>
                          <div className="px-2 col ff-base wb-break-word">
                            {(dataItems?.[item]?.[0]?.value?.length > 241 ? dataItems[item][0].value : null) ?
                              <React.Fragment>
                                {readMoreTextObj?.[item]  ? `${dataItems[item][0]?.value}` : (`${(dataItems[item][0]?.value).substring(0, 241)} ... `)}
                                <span className='text-primary cursor-pointer' onClick={() => this.toggleLessMore(item)}>{readMoreTextObj?.[item]  ? ' read less' : 'read more'}</span>
                                {!!dataItems[item]?.[0]?.value && <span role="button" onClick={() => { this.handlleDeleteData(dataItems[item]?.value?.[0], item) }} className="text-danger mx-1 ml-" title="Remove">x</span>}
                              </React.Fragment>
                              : dataItems[item] && Array.isArray(dataItems[item]) && dataItems[item]?.length ?
                                dataItems[item].map(dt => {
                                  return (
                                    <React.Fragment>
                                      <span>{dt?.value}{`${dataItems[item].length > 1? "," : ""}`}</span>
                                      {!!dataItems[item]?.[0]?.value && <span role="button" onClick={() => { this.handlleDeleteData(dt, item) }} className="text-danger mx-1 ml-" title="Remove">x</span>}
                                    </React.Fragment>
                                  )
                                })
                                : dataItems[item]?.[0]?.value}
                            
                          </div>
                        </div>
                      </li>
                    )
                  }) : null}
              </ul>
            </div>

            <div className="text-right mb-2">
              <Button
                onClick={() => {
                  if(!this.checkIfLoggedIn()){
                    this.props.forceToggleLoginModal();
                    return false;
                  } 
                  this.setState({ isToggleAddAnother: !this.state.isToggleAddAnother }) }}
                color="link"
                className="p-0 text-white">
                <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                {' Add another'}
              </Button>
            </div>

            {isToggleAddAnother ? (
              <div className="bg-white p-3">
                <h5 className="text-secondary-dark mb-3 fs-30">Add another</h5>
                <FormGroup>
                  <Input
                    onChange={(e) => {

                      selectedItem = e.target.value;
                      this.setState({ selectedItem });
                    }}
                    className="primary"
                    bsSize="sm"
                    type="select">
                    <option value="" selected="selected">Please Select</option>
                    <option value="33">{this.state.color}</option>
                    <option value="34">Aspect Ration</option>
                    <option value="35">Camera</option>
                    <option value="36">Laboratory</option>
                    <option value="37">Negative Format</option>
                    <option value="38">Cinematographic Process</option>
                    <option value="39">Printed Film Format</option>
                    <option value="64">Release Date</option>
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label className="text-dark font-weight-normal">Info <span className="text-danger">*</span></Label>
                  {selectedItem == "64" ?

                    <div className="custom-date-picker">
                      <DatePicker
                        className="mt-2 form-control-sm form-control"
                        selected={textInput}
                        onChange={(date) => { this.setState({ textInput: date }) }}
                      />
                    </div> :
                    <Input
                      onChange={(e) => {

                        textInput = e.target.value;
                        this.setState({ textInput });
                      }}
                      className="primary"
                      bsSize="sm"
                      type="text"
                      placeholder="Enter additional information"
                    />
                  }

                </FormGroup>

                <div className="text-right">
                  <Button
                    // disabled={(addItems?.textInput?.length && addItems?.selectedItem !== null) ? false : true}
                    onClick={this.handlleSubmitData}
                    className="mw"
                    color="primary"
                    size="sm">
                    {'Submit'}
                  </Button>
                  <Button
                    onClick={() => { this.setState({ isToggleAddAnother: !this.state.isToggleAddAnother }) }}
                    className="mw" color="primary" size="sm">Cancel</Button>
                </div>
              </div>
            ) : null}

          </CollapseBasic>
        </>
      )
    }
  }
}
export default TechnicalDetails;