import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Form, Input } from 'reactstrap';

const SearchBar = (props) => {
    const [searchText, setSearchText] = useState(props.searchText);

    useEffect(() => {

    }, []);

    const setSearchValue = (value) => {
        setSearchText(value);
        debouncedChangeHandler(value);
    }

    const handleSubmit = (value) => {
        props.handleSearchSubmit && props.handleSearchSubmit(value);
    }

    const changeHandler = (value) => {
        if (value?.length < 3 && value?.length !== 0) {
            toast("Please search with minimum 3 letters.", {
                autoClose: 2500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
            });
            return;
        }
        handleSubmit(value);
    }

    const debouncedChangeHandler = useMemo(
        (value) => debounce((value => {
            changeHandler(value)
        }), 2000)
        , []);

        const searchHandleChange = (e)=>{
            setSearchValue(e.target.value);          
        }

    return (
        <>
            <Form className="d-flex" onSubmit={(e) => {
                e?.preventDefault();
                changeHandler(searchText)
            }}>
                <Input className="bg-search search-block-input" id="filter-search" onChange={searchHandleChange} type="text" bsSize="sm" value={searchText} placeholder="Search" name="searchText" />
                <button type="submit" className="d-none"></button>

                {/* {
                    searchText ? <>

                        <button title="Search results" type="button" className="btn btn-sm btn-primary ml-1" onClick={handleSubmit} >Search</button>

                    </> : <></>
                }

                {
                    searchText || props.searchResultsEnabled ? <>
                        <button title="Reset results" type="button" className="btn btn-sm ml-1 text-red" onClick={(e) => {
                            setSearchValue("")
                            if (props.searchResultsEnabled) {
                                props.handleSearchSubmit && props.handleSearchSubmit("");
                            }
                        }} >Reset</button>
                    </> : <></>
                } */}
            </Form>
        </>
    );


};


export default SearchBar;
