import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";

export const GET_EDUCATION = "GET_EDUCATION";
export const GET_WORK_HISTORY = "GET_WORK_HISTORY";
export const ADD_WORK_HISTORY = "ADD_WORK_HISTORY";
export const ADD_EDUCATION = "ADD_EDUCATION";
export const SEARCH_ORGANIZATION = "SEARCH_ORGANIZATION";
export const SEARCH_UNIVERSITY = "SEARCH_UNIVERSITY";

export const get_education_ = (data) => {
  return {
    type: GET_EDUCATION,
    payload: data,
  };
};


export const get_education = () => {
  return (dispatch) => {
    callApi(`/api/education/`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_education_(response.results));
      }
    });
  };
};

export const get_work_history_ = (data) => {
  return {
    type: GET_WORK_HISTORY,
    payload: data,
  };
};


export const get_work_history = (profileId) => {
  let url = `/api/work_history/`
    if(profileId)
    url = `/api/work_history/?profile=${profileId}`
  return (dispatch) => {
    callApi(url, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_work_history_(response.results));
      }
    });
  };
};


export const add_work_history_ = (data) => {
  return {
    type: ADD_WORK_HISTORY,
    payload: data,
  };
};


export const add_work_history = (data) => {
  return (dispatch) => {
    callApi(`/api/work_history/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
		toast(`${response.message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        }); 
		return dispatch(get_work_history());
      }
    });
  };
};


export const add_education_ = (data) => {
  return {
    type: ADD_EDUCATION,
    payload: data,
  };
};


export const add_education = (data) => {
  return (dispatch) => {
    callApi(`/api/education/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${response.message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_education());
      }
    });
  };
};

export const search_organization_ = (data) => {
  return {
    type: SEARCH_ORGANIZATION,
    payload: data,
  };
};

export const search_organization = (query) => {
  return (dispatch) => {
    if (query) {
      callApi(`/api/search/?add=true&content_type=business&location=false&page=1&q=${query}&sensor=false&size=5`, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(search_organization_(response.results));
        }
      });
    } else {
      return dispatch(search_organization_([]));
    }
  };
};

export const search_university_ = (data) => {
  return {
    type: SEARCH_UNIVERSITY,
    payload: data,
  };
};

export const search_university = (query) => {
  return (dispatch) => {
    if (query) {
      callApi(`/api/search/?subcat=459&add=true&content_type=business&location=false&page=1&q=${query}&sensor=false&size=50`, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(search_university_(response.results));
        }
      });
    } else {
      return dispatch(search_university_([]));
    }
  };
};

export const edit_work_history = (data) => {
  return (dispatch) => {
    callApi(`/api/work_history/${data.id}/`, "PATCH", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${response.message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_work_history());
      }
    });
  };
};

export const delete_work_history = (id) => {
  return (dispatch) => {
    callApi(`/api/work_history/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${response.message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_work_history());
      }
    });
  };
};

export const edit_education = (data) => {
  return (dispatch) => {
    callApi(`/api/education/${data.id}/`, "PATCH", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${response.message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_education());
      }
    });
  };
};

export const delete_education = (id) => {
  return (dispatch) => {
    callApi(`/api/education/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${response.message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_education());
      }
    });
  };
};



