import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap'

const ShowUploadedMedia = ({ embedMedia, galleryMedia, removeEmbeddedFromUploaded, removeFromUploaded, isEdit, feed }) => {
   const truncate = (filenameString) => {
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };

    return (
        <>
            {(embedMedia && Array.isArray(embedMedia) && embedMedia.length) || (galleryMedia && Array.isArray(galleryMedia) && galleryMedia.length) ? (
                <div className="border mb-3">
                    <Row xs={2} sm={feed ? 2 : 3} md={feed ? 2 : 4} lg={5} noGutters>
                        {embedMedia && Array.isArray(embedMedia) && embedMedia.length ?
                            embedMedia.map((item, uploaded_index) => (
                                <Col className={isEdit ? 'mr-4' : feed ? "mr-4" : ""} key={uploaded_index}>
                                    <div className="d-flex pr-3 m-2">
                                        <div>
                                            {/* {item.type === "embedded_link" &&  */}
                                            <div className="gallery-media d-flex justify-content-center">
                                                <a href={item.url} target="_blank" rel="noopener noreferrer" download="Image">
                                                    <img
                                                        className="img-fluid img-thumbnail h-100"
                                                        src={item.thumbnail || require("../../../../assets/images/embedded_link_img.png")}
                                                        // id={`embedded_link-${uploaded_index}`}
                                                        alt={item.url}
                                                    />
                                                    {/* <UncontrolledTooltip
                                                        autohide="false"
                                                        placement="top"
                                                        target={`embedded_link-${uploaded_index}`}
                                                    >
                                                        {item.url}
                                                    </UncontrolledTooltip> */}
                                                </a>
                                            </div>
                                            {/* } */}
                                        </div>
                                        <div className="mx-n3 mt-2">
                                            <Button onClick={() => { removeEmbeddedFromUploaded(uploaded_index, item) }} color="delete-media" size="sm" title="Remove Media">
                                                <FontAwesomeIcon icon="trash-alt" />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            )) : null}

                        {galleryMedia && Array.isArray(galleryMedia) && galleryMedia.length ?
                            galleryMedia.map((item, uploaded_index) => (
                                <Col className={isEdit ? 'mr-4' : feed ? "mr-4" : ""}>
                                    <div className="d-flex pr-3 m-2">
                                        <div>
                                            {(item.type === "image" ||
                                                item.media_type === "image" ||
                                                item.type === "video" ||
                                                item.media_type === "video" || item.type === "audio" ||
                                                item.media_type === "audio") && (
                                                    <div className="gallery-media d-flex justify-content-center">
                                                        {item.media_type === 'image' ?
                                                            <img
                                                                className="img-fluid img-thumbnail h-100"
                                                                src={item.url ? item.url : item.file}
                                                                alt={
                                                                    item.filename
                                                                        ? item.filename.length < 20
                                                                            ? item.filename
                                                                            : truncate(item.filename)
                                                                        : item.name
                                                                            ? item.name.length < 20
                                                                                ? item.name
                                                                                : truncate(item.name)
                                                                            : ""
                                                                } /> :
                                                            <img
                                                                className="img-fluid img-thumbnail"
                                                                src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
                                                                alt={
                                                                    item.filename
                                                                        ? item.filename.length < 20
                                                                            ? item.filename
                                                                            : truncate(item.filename)
                                                                        : item.name
                                                                            ? item.name.length < 20
                                                                                ? item.name
                                                                                : truncate(item.name)
                                                                            : ""
                                                                }
                                                                onError={(error) => (error.target.src = require("../../../../assets/images/videopholder.jpg"))}
                                                            />}
                                                    </div>
                                                )}
                                        </div>
                                        <div className="mx-n3 mt-2">
                                            <Button onClick={() => { removeFromUploaded(uploaded_index, item) }} color="delete-media" size="sm" title="Remove Media">
                                                <FontAwesomeIcon icon="trash-alt" />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>)) : null}

                    </Row>
                </div>
            ) : null
            }</>
    )
}

export default ShowUploadedMedia