import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  FormGroup,
  Button,
  Modal,
  ModalBody,
  Input,
  Label,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import Loaderimg from "../../../assets/images/w-brand-black.jpg";
import { Link } from "react-router-dom";
import { Ratings } from "../../molecules/homepage/ShowRatings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { callApi } from "../../../utils/apiCaller";
import { Carousel as ReactRespCarousel } from "react-responsive-carousel";
import {
  add_dispute_review,
  dispute_modal_status,
  get_dispute_draft,
  delete_dispute_draft
} from "../../../actions/dispute";
import Carousel from "react-multi-carousel";
import ReactHtmlParser from "react-html-parser";

class DisputingReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disputeThisReviewModalToggle: false,
      disputeReview: {
        review_id: "",
        reason: "This review is fake",
        comment: "",
        dispute_file: [],
        admin_reason: [],
        embedLinks: "",
        is_draft: false,
        is_administrative_review: false,
        review_owner: "",
        is_community_flagged: false
      },
      reviewAdded: "",
      reviewId: "",
      all_disputes_list: [],
      disputed_tab: false,
      user_names_list: [],
      get_dispute_data: [],
      showExplanation: false,
      disputeReviewId: "",
      is_media_dispute: false,

      dispute_files: [],
      dispute_imgepreview: [],
      get_reveiewhelpCount: [],

      pinStatuses: {},

      dispute_reply_text: "",
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      homeDropdownOpen: {},
      homeShareDropdownOpen: {},

      review: null,
      can_vote: false,
      editFeedError: "",
      feedDropdownOpen: false,
      timer_started: false,
      is_administrative_review: false,
      confirmationToggle: false,
      confirmationReason: "",
      pk_id: "",
      postStatus: "",
      isLoading: false,
      disputeValidationError: "",
      showEmbedLinks: false,
      showUploadedDocuments: false,
      showUploadedMedia: false,
      review_mediaset: [],
      commentValidation: false,
      embedLinks: [""],
      draftToggle: false,
      withAction: false,
      savedMedia: [],
      viewMyPhotosModal: false,
      imageAttacment: "",
      was_draft: false,
      deleting_draft: false,
      showDraftButton: true,
      administrativeRequested: false,
      review_owner: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    const { disputeReview } = this.state;
    if (
      this.state.disputeThisReviewModalToggle &&
      nextProps?.add_dispute_status
    ) {
      if (nextProps?.add_dispute_status?.code === 200) {
        this.setState({ confirmationToggle: true });
      }
    }
    if (nextProps?.get_dispute_draft_data) {
      if (
        nextProps?.get_dispute_draft_data?.code === 200 &&
        nextProps?.get_dispute_draft_data?.results
      ) {
        this.setState({ draftToggle: false });
        this.handleDisputethisreview(this.props.review);
        let res = nextProps?.get_dispute_draft_data?.results;
        let reason = "";
        if (
          this.state.administrativeRequested ||
          this.state.is_administrative_review
        ) {
          reason = "Request Administrative Review";
          if (res[0]?.flag_name) {
            let flag_name = res[0]?.flag_name;
            reason = flag_name?.charAt(0).toUpperCase() + flag_name?.substr(1);
            this.setState({ is_administrative_review: false });
          }

          this.state.disputeReview[
            "admin_reason"
          ] = res[0]?.admin_reason?.split(",");
          this.setState({ disputeReview });
        } else {
          reason = res && res[0]?.flag_name;
          reason = reason.charAt(0).toUpperCase() + reason.slice(1);
        }
        disputeReview["reason"] = reason;
        disputeReview["comment"] = res && res[0]?.content_flagged;
        let link = [];
        try {
          link = res && res[0]?.embed_links?.split(",");
        } catch (e) {
          link.push(res[0]?.embed_links);
        }
        if (link.length > 0) {
          this.setState({ showEmbedLinks: true });
        }
        this.setState({ disputeReview, embedLinks: link });

        // Getting Media
        let images = [];
        res[0]?.media?.map(media => {
          images.push(
            `https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`
          );
        });
        this.setState({ savedMedia: images, was_draft: true });
      }
    }

    if (nextProps?.delete_dispute_draft_data && this.state.deleting_draft) {
      toast(nextProps?.delete_dispute_draft_data?.Message, {
        autoClose: 4500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc"
      });
      this.setState({ deleting_draft: false });
      this.handleDisputethisreview(this.props.review);
    }
  }

  componentDidMount = () => {
    this.handleStatusCheck(this.props.review);
  };

  handleStatusCheck = async (review, draft = "") => {
    if (review === null) {
      this.setState({ disputeThisReviewModalToggle: false });
      this.props.dispute_modal_status({ modal: false });
      return null;
    }

    if (
      review?.created_by?.user?.username ===
      this.props?.my_profile?.user?.username
    ) {
      toast(`Reviewers cannot dispute their own reviews!`, {
        autoClose: 4500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc"
      });
      this.setState({ disputeThisReviewModalToggle: false });
      this.props.dispute_modal_status({ modal: false });
      return;
    }
    try {
      let formData = new FormData();
      formData.append("check_status", true);
      if (this.props?.source === "NonActioned") {
        formData.append("review_id", review.id);
        this.setState({ withAction: true });
      } else {
        formData.append("review_id", review?.action?.id);
      }
      const response = await callApi(`/api/disputes/`, "POST", formData);
      if (
        response?.code == 200 &&
        response?.Status ===
          "Oops! Looks like this review is already under same process!"
      ) {
        toast(response?.Status, {
          autoClose: 4500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc"
        });
        this.setState({ disputeThisReviewModalToggle: false });
        this.props.dispute_modal_status({ modal: false });
        return;
      } else if (
        response?.code === 200 &&
        response?.Message === "You have already saved review dispute!"
      ) {
        this.setState({
          draftToggle: true,
          showDraftButton: true,
          administrativeRequested: false
        });
        return;
      } else if (
        response?.code === 200 &&
        response?.saved_status === "administrative_review"
      ) {
        toast(response?.Message, {
          autoClose: 4500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc"
        });
        this.setState({
          draftToggle: true,
          showDraftButton: false,
          administrativeRequested: true,
          is_administrative_review: true
        });
        //   this.setState({ disputeThisReviewModalToggle : false })
        //   this.props.dispute_modal_status({modal:false})
        return;
      } else {
        this.handleDisputethisreview(review);
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleDisputethisreview = async review => {
    const { disputeReview } = this.state;
    this.setState({ draftToggle: false });
    if (this.props.reason) {
      disputeReview["reason"] = this.props.reason;
      this.setState({ disputeReview });
    }
    if (this.props?.source === "NonActioned") {
      this.setState({
        review_id: review?.id,
        review_user_name:
          review?.created_by?.user?.first_name +
          review?.created_by?.user?.last_name,
        review_profile_image:
          review?.created_user_media?.user?.user?.current_profile_pic,
        review_target_name: review?.associated_to?.name,
        review_rating: review?.ratings_sets,
        review_time_stamp: review?.review_date_time,
        review_description: review?.review_text,
        review_media: review?.reviewmedia_set,
        review_user_link: review?.created_by?.vanity_url,
        review_pathname: review?.associated_to?.content_type?.category?.toLowerCase(),
        review_slug: review?.associated_to?.slug,
        review_state: review?.associated_to?.id,
        review_owner: review?.created_by?.id
      });
      this.setState({
        disputeThisReviewModalToggle: true,
        reviewId: review?.id,
        review: review,
        reviewmedia_set: review?.action?.reviewmedia_set
      });
      this.props.dispute_modal_status({ modal: true });
    } else {
      this.setState({
        review_id: review?.action?.id,
        review_user_name:
          review?.action?.created_by?.user?.first_name +
          review?.action?.created_by?.user?.last_name,
        review_profile_image: review?.actor?.current_profile_pic?.url,
        review_target_name: review?.target?.name,
        review_rating: review?.action?.ratings_sets,
        review_time_stamp: review?.time_stamp,
        review_description: review?.action?.review,
        review_media: review?.action?.reviewmedia_set,
        review_user_link: review?.actor?.username,
        review_pathname: review?.target?.category?.toLowerCase(),
        review_slug: review?.target?.slug,
        review_state: review?.target?.id,
        review_owner: review?.action?.created_by?.id
      });
      this.setState({
        disputeThisReviewModalToggle: true,
        reviewId: review?.action?.id,
        review: review,
        reviewmedia_set: review?.action?.reviewmedia_set
      });
      this.props.dispute_modal_status({ modal: true });
    }
  };

  handleChange = e => {
    this.setState({ disputeValidationError: "" });
    const { disputeReview } = this.state;
    disputeReview[e.target.name] = e.target.value;
    this.setState({ disputeReview });
    if (e.target.name === "reason") {
      if (e.target.value === "Request Administrative Review") {
        this.setState({ is_administrative_review: true });
      } else {
        this.setState({ is_administrative_review: false });
      }
    }
    if (e.target.name === "comment") {
      this.setState({ commentValidation: false });
    }
  };

  handleImageChange = async e => {
    e.preventDefault();
    this.setState({ disputeValidationError: "" });
    // const {dispute_imgepreview,dispute_files} = this.state
    if (e.target.files.length > 1) {
      for (let i = 0; i < e.target.files.length; i++) {
        let reader = new FileReader();
        let file = e.target.files[i];
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreview_Url: reader.result
          });
          this.setState({
            dispute_files: [...this.state.dispute_files, file],
            dispute_imgepreview: [
              ...this.state.dispute_imgepreview,
              reader.result
            ]
          });
        };
        reader.readAsDataURL(file);
        // console.log("=======imagePreview_Url", this.state.imagePreview_Url)
      }
    } else {
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreview_Url: reader.result
        });

        this.setState({
          dispute_files: [...this.state.dispute_files, file],
          dispute_imgepreview: [
            ...this.state.dispute_imgepreview,
            reader.result
          ]
        });
      };
      reader.readAsDataURL(file);
    }
  };

  deleteImage = index => {
    var image_array = [...this.state.dispute_files];
    image_array.splice(index, 1);
    this.setState({ dispute_files: image_array });

    var preview_array = [...this.state.dispute_imgepreview];
    preview_array.splice(index, 1);
    this.setState({ dispute_imgepreview: preview_array });
  };

  isValidated = () => {
    const { disputeReview, dispute_files } = this.state;
    this.setState({
      is_administrative_review: false,
      disputeValidationError: "",
      commentValidation: false
    });
    if (disputeReview["comment"] === "") {
      this.setState({
        disputeValidationError:
          "You must upload an explanation for this review dispute.",
        commentValidation: true
      });
      return false;
    }
    if (
      dispute_files.length === 0 &&
      disputeReview["reason"] === "This review is fake"
    ) {
      this.setState({
        disputeValidationError:
          "You must upload proofs(Images/Videos/Documents) to support this review dispute."
      });
      return false;
    }
    if (
      disputeReview["reason"] === "Request Administrative Review" &&
      disputeReview["admin_reason"].length === 0
    ) {
      this.setState({
        disputeValidationError:
          "You must select checkbox(es) as a reason for Administrative Request"
      });
    }
    return true;
  };

  handleDisputeSubmit = (draft = "") => {
    const {
      disputeReview,
      dispute_files,
      embedLinks,
      was_draft,
      is_administrative_review
    } = this.state;
    if (was_draft) {
      if (this.isValidated()) {
        disputeReview["dispute_file"] = dispute_files;
        disputeReview["review_id"] = this.state.reviewId;
        disputeReview["embedLinks"] = embedLinks;
        disputeReview["was_draft"] = true;
        disputeReview["saved_media"] = this.state.savedMedia;
        disputeReview["review_owner"] = this.state.review_owner;
        if (is_administrative_review) {
          disputeReview["is_administrative_review"] = true;
          disputeReview["was_draft"] = false;
        }
        if (this.props.my_profile.business_owner) {
          this.setState({
            disputeReview,
            postStatus: "dispute_added",
            isLoading: true
          });
          this.props.add_dispute_review(disputeReview, this.state.filterType);
        } else {
          disputeReview["is_community_flagged"] = true;
          this.setState({
            disputeReview,
            postStatus: "dispute_added",
            isLoading: true
          });
          this.props.add_dispute_review(disputeReview, this.state.filterType);
        }
        this.setState({
          disputeReview,
          postStatus: "dispute_added",
          isLoading: true
        });
        this.props.add_dispute_review(disputeReview, this.state.filterType);
      }
    } else {
      if (this.isValidated()) {
        disputeReview["dispute_file"] = dispute_files;
        disputeReview["review_id"] = this.state.reviewId;
        disputeReview["embedLinks"] = embedLinks;
        disputeReview["was_draft"] = false;
        if (draft === "draft") {
          disputeReview["is_draft"] = true;
        }
        if (draft === "draft") {
          disputeReview["is_draft"] = true;
        }
        if (is_administrative_review) {
          disputeReview["is_administrative_review"] = true;
        }
        if (this.props.my_profile.business_owner) {
          this.setState({
            disputeReview,
            postStatus: "dispute_added",
            isLoading: true
          });
          // this.props.add_dispute_review(disputeReview,  this.state.filterType);
        } else {
          disputeReview["is_community_flagged"] = true;
          this.setState({
            disputeReview,
            postStatus: "dispute_added",
            isLoading: true
          });
          // this.props.add_dispute_review(disputeReview,  this.state.filterType);
        }
        this.setState({
          disputeReview,
          postStatus: "dispute_added",
          isLoading: true
        });
        this.props.add_dispute_review(disputeReview, this.state.filterType);
      }
    }
  };

  handleAdminReasonChange = e => {
    const { disputeReview } = this.state;
    let adminReason = disputeReview["admin_reason"];
    if (e.target.checked) {
      adminReason.push(e.target.value);
    }
    if (!e.target.checked) {
      adminReason.pop(e.target.value);
    }
    disputeReview["admin_reason"] = adminReason;
    this.setState({ disputeReview });
  };

  handleEmbedLink = option => {
    if (option === "+") {
      let val = this.state.embedLinks;
      val.push("");
      this.setState({ embedLinks: val });
    } else {
      let val = this.state.embedLinks;
      val.pop();
      this.setState({ embedLinks: val });
    }
  };

  handleChangeLinks = (e, ind) => {
    const { embedLinks } = this.state;
    embedLinks[ind] = e.target.value;
    this.setState({ embedLinks });
  };

  handleStatus = () => {
    this.setState({ isLoading: false, disputeThisReviewModalToggle: false });
    this.props.dispute_modal_status({ modal: false, refresh: true });
  };

  handleSavedDispute = () => {
    if (this.state.withAction) {
      this.props.get_dispute_draft(this.props?.review?.id);
    } else {
      this.props.get_dispute_draft(this.props?.review?.action?.id);
    }
  };

  handleDeleteDraft = () => {
    const { disputeReview } = this.state;
    disputeReview["reason"] = "This review is fake";
    disputeReview["comment"] = "";
    disputeReview["is_administrative_review"] = false;
    this.setState({
      disputeReview,
      embedLinks: [],
      dispute_files: [],
      deleting_draft: true,
      savedMedia: [],
      is_administrative_review: false
    });

    if (this.props?.source === "NonActioned") {
      this.props.delete_dispute_draft(this.props?.review?.id);
    } else {
      this.props.delete_dispute_draft(this.props?.review?.action?.id);
    }
  };

  deleteSavedImage = index => {
    const { savedMedia } = this.state;
    let saved_media = savedMedia;
    saved_media.splice(index, 1);
    this.setState({ savedMedia: saved_media });
  };

  render() {
    const {} = this.props;
    let {
      disputeReview: { reason, comment, is_draft, admin_reason },
      draftToggle,
      is_administrative_review,
      confirmationToggle,
      isLoading,
      review_user_name,
      review_target_name,
      review_rating,
      review_time_stamp,
      review_description,
      showUploadedDocuments,
      showUploadedMedia,
      showEmbedLinks,
      disputeValidationError,
      reviewmedia_set,
      review_profile_image,
      review_user_link,
      review_pathname,
      review_slug,
      review_state,
      commentValidation,
      embedLinks,
      savedMedia,
      imageAttacment,
      administrativeRequested,
      showDraftButton
    } = this.state;

    const responsive = {
      videoModal: {
        mobile: {
          breakpoint: { max: 9999999, min: 0 },
          items: 1
        }
      },
      viewGalleryImages: {
        desktop: {
          breakpoint: { max: 9999999, min: 1200 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1199, min: 576 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1
        }
      }
    };

    return (
      <div>
        {/* Dispute Review Modal */}
        <Modal
          size="xl"
          isOpen={this.state.disputeThisReviewModalToggle}
          toggle={() =>
            this.setState({
              disputeThisReviewModalToggle: !this.state
                .disputeThisReviewModalToggle
            })
          }
        >
          <>
            {isLoading ? (
              <div className="loader_div">
                <div className="inner-loader">
                  <img src={Loaderimg} alt="" />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="modal-header flex-wrap p-0">
              {/* Dispute review intro design */}
              <div className="row mb-5 form flex-grow-1">
                <div className="col-lg-8 ml-auto">
                  <div className="bg-warning text-dark p-2 fs-14">
                    <div className="d-flex flex-wrap mx-n2">
                      <div className="text-white px-2">
                        <span className="font-weight-normal">
                          {" "}
                          This is the{" "}
                          <span className="font-weight-bold"> review </span> you
                          are disputing.{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white p-3 fs-14 text-dark">
                    <div className="d-flex mx-n2 mb-2">
                      <div className="px-2">
                        <a href="#" className="text-decoration-none">
                          <img
                            className="img-circle _50x50"
                            src={
                              review_profile_image
                                ? review_profile_image
                                : require("../../../assets/images/icons/user-circle.png")
                            }
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="px-2 flex-grow-1">
                        <div className="px-2 flex-grow-1">
                          <div className="ff-alt">
                            <a
                              href={`/people/${review_user_link}`}
                              className="text-reset ff-base font-weight-bold"
                            >
                              {review_user_name}
                            </a>
                            <span> wrote a review for </span>
                            <Link
                              to={{
                                pathname: `/${review_pathname}/${
                                  review_slug ? review_slug : "null"
                                }`,
                                state: { id: review_state }
                              }}
                              className="text-reset font-weight-bold"
                            >
                              {review_target_name}
                            </Link>{" "}
                            and gave it
                            <br />
                            <Ratings ratings={review_rating} />
                            <span> {review_time_stamp}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ff-alt my-4">
                      <i className="icon-comma-left"></i>
                      <span className="comment-body-renderer">
                        {" "}
                        {ReactHtmlParser(review_description)}
                      </span>
                      <i className="icon-comma-right"></i>
                    </div>

                    <div className="mb-3">
                      <ReactRespCarousel
                        className="rr-carousel"
                        infiniteLoop={true}
                        showArrows={true}
                        showStatus={false}
                        showIndicators={false}
                      >
                        {reviewmedia_set && reviewmedia_set.length > 0
                          ? reviewmedia_set.map((data, index1) => {
                              return (
                                <div key={index1}>
                                  {data.type === "image" ? (
                                    <img width="100" src={data.url} alt="" />
                                  ) : data.type === "video" ? (
                                    <video width="100%" controls>
                                      <source src={data.url} type="video/mp4" />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : null}
                                </div>
                              );
                            })
                          : ""}
                      </ReactRespCarousel>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.props.dispute_modal_status({ modal: false })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
              </div>
            </div>
            <ModalBody className="bg-transparent p-0">
              <Row className="mb-3" noGutters>
                <Col xs={12} lg="auto">
                  <div className="d-flex flex-column justify-content-center bg-tertiary text-white text-center px-5 py-4 h-100">
                    <div>
                      <img
                        src={require("../../../assets/images/icons/star/blue/fill.png")}
                        alt=""
                      />
                      <h2 className="my-2 fs-50 text-shadow">REVIEW DISPUTE</h2>
                      <img
                        src={require("../../../assets/images/icons/star/blue/fill.png")}
                        alt=""
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} className="col-lg">
                  <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                    <Row>
                      <Col className="text-center">
                        <div className="fs-24 ff-headings text-uppercase">
                          Please watch the video to see how Review Disputes work
                        </div>
                        <div className="mt-3">
                          <Row className="justify-content-between align-items-center">
                            <Col
                              xs="12"
                              className="d-flex justify-content-center align-items-center"
                            >
                              <div className="text-center d-flex align-items-center justify-content-center">
                                <div>
                                  <img
                                    className="img-fluid"
                                    src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div
                                className="video-holder play-icon d-inline-block mx-2"
                                role="button"
                                onClick={() =>
                                  this.setState({
                                    watchHowDisputeReviewWorksModalToggle: true
                                  })
                                }
                              >
                                <img
                                  height="80"
                                  src={require("../../../assets/images/dispute_jury.webp")}
                                  alt="dispute"
                                />
                              </div>
                              <div className="text-center d-flex align-items-center justify-content-center">
                                <div>
                                  <img
                                    className="img-fluid"
                                    src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div className="bg-white p-3 mb-3">
                <div>
                  {/* For Community Users */}
                  <FormGroup className="mb-0" row>
                    <Col lg={6}>
                      <Label className="col-form-label text-primary font-weight-bold fs-16">
                        Step 1. Choose why you are disputing this review.
                      </Label>
                    </Col>
                    <Col lg={6}>
                      <Input
                        type="select"
                        className="primary"
                        bsSize="sm"
                        name="reason"
                        onChange={this.handleChange}
                        value={reason}
                      >
                        <option value="This review is fake">
                          This review is fake
                        </option>
                        <option value="This review is not relevant">
                          This review is not relevant
                        </option>
                        <option value="This review is inaccurate">
                          This review is inaccurate
                        </option>
                        <option value="" disabled>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; OR
                        </option>
                        <option value="Request Administrative Review">
                          Request Administrative Review
                        </option>
                      </Input>

                      {/* For Admins */}
                      {is_administrative_review ? (
                        <div className="mt-3">
                          {/* <div className="fs-16 mb-2">Administrative Review</div> */}
                          {/* <Col className="mb-2" md={4}> */}
                          <FormGroup check inline>
                            <Label
                              className="font-weight-normal fs-14 text-dark"
                              check
                            >
                              <Input
                                type="checkbox"
                                {...(admin_reason?.includes(
                                  "Conflict of Interest"
                                )
                                  ? { checked: "true" }
                                  : "")}
                                value="Conflict of Interest"
                                onClick={this.handleAdminReasonChange}
                              />
                              Conflict of Interest
                            </Label>
                          </FormGroup>
                          {/* </Col>
                                <Col className="mb-2" md={4}> */}
                          &nbsp; &nbsp; &nbsp;
                          <FormGroup check inline>
                            <Label
                              className="font-weight-normal fs-14 text-dark"
                              check
                            >
                              <Input
                                type="checkbox"
                                value="Contains threats of violence"
                                {...(admin_reason?.includes(
                                  "Contains threats of violence"
                                )
                                  ? { checked: "true" }
                                  : "")}
                                onClick={this.handleAdminReasonChange}
                              />{" "}
                              Contains threats of violence
                            </Label>
                          </FormGroup>
                          {/* </Col>
                                <Col className="mb-2" md={4}> */}
                          &nbsp; &nbsp; &nbsp;
                          <FormGroup check inline>
                            <Label
                              className="font-weight-normal fs-14 text-dark"
                              check
                            >
                              <Input
                                type="checkbox"
                                value="Spam"
                                {...(admin_reason?.includes("Spam")
                                  ? { checked: "true" }
                                  : "")}
                                onClick={this.handleAdminReasonChange}
                              />{" "}
                              Spam
                            </Label>
                          </FormGroup>
                          {/* </Col> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </FormGroup>
                </div>
                {/* </CollapseBasic> */}
              </div>
              <div className="bg-white p-3 mb-3">
                <div>
                  <div>
                    <Label
                      for="disputed_review"
                      className="text-primary font-weight-bold fs-16"
                    >
                      {reason === "This review is fake" ? (
                        <span>
                          Step 2. Provide evidence and explanation for disputing
                          the review in question
                        </span>
                      ) : (
                        ""
                      )}

                      {reason === "This review is not relevant" ? (
                        <span>
                          Step 2. Provide evidence for disputing the review in
                          question. Uploading an explanation is (optional)
                        </span>
                      ) : (
                        ""
                      )}

                      {reason === "This review is inaccurate" ? (
                        <span>
                          Step 2. Provide an explanation for disputing the
                          review in question. Uploading evidence is (optional)
                        </span>
                      ) : (
                        ""
                      )}

                      {reason === "Request Administrative Review" ? (
                        <span>
                          Step 2. Provide evidence and explanation for why you
                          marked this review for [Conflict of Interest]
                          [Contains Threats of Violence] or [Spam]
                        </span>
                      ) : (
                        ""
                      )}
                    </Label>

                    {/* Dispute review uplaod files*/}
                    <div className="attachable-container">
                      <Input
                        type="textarea"
                        // className="text-primary-dark fs-14 font-weight-bold"
                        // className="primary text-dark fs-14 font-weight-bold ph-normal"
                        className={
                          commentValidation
                            ? "primary text-dark fs-14 font-weight-bold ph-normal border-danger"
                            : "primary text-dark fs-14 font-weight-bold ph-normal"
                        }
                        bsSize="sm"
                        rows="4"
                        name="comment"
                        id="disputed_review"
                        onChange={this.handleChange}
                        value={comment}
                        placeholder="Please provide a written explanation in as much detail as possible and upload any and all images/video documents to fully support your case."
                        required
                      />

                      <div className="attachable-area">
                        <hr className="bg-primary mt-0 mb-2" />

                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <Label
                              title="Embed Link"
                              className="text-primary"
                              role="button"
                              onClick={() => {
                                this.setState({ showEmbedLinks: true });
                              }}
                              // onChange={ (e) => {
                              //     this.setState({ showEmbedLinks: true });
                              //     this.handleImageChange(e);
                              // }}
                            >
                              <FontAwesomeIcon icon="link" fixedWidth />
                            </Label>
                          </div>
                          <div className="px-1">
                            <Input
                              type="file"
                              id="attach_files_modal"
                              multiple
                              onChange={e => {
                                this.setState({ showUploadedDocuments: true });
                                this.handleImageChange(e);
                              }}
                              style={{ display: "none" }}
                              accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                            />
                            <Label
                              for="attach_files_modal"
                              title="Upload Documents"
                              className="text-primary"
                              role="button"
                            >
                              <FontAwesomeIcon icon="paperclip" fixedWidth />
                            </Label>
                          </div>
                          <div className="px-1">
                            <input
                              type="file"
                              name="imageUpload"
                              id="imageUpload"
                              style={{ display: "None" }}
                              onChange={e => {
                                this.setState({ showUploadedMedia: true });
                                this.handleImageChange(e);
                              }}
                              multiple
                              accept="image/*,video/*"
                            />
                            <label
                              for="imageUpload"
                              title="Upload Media"
                              className="text-primary"
                              role="button"
                            >
                              <div
                                className="d-flex justify-content-center"
                                style={{ width: "20px" }}
                              >
                                <img
                                  src={require("../../../assets/images/icons/feed-cam.png")}
                                  alt=""
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2">
                      *Note: Dispute submitted with factual data and
                      images/videos to backup assertions have a greater
                      likelihood of being filtered or removed.{" "}
                    </div>
                    {/* Inset Embed links here */}
                    {showEmbedLinks ? (
                      <div>
                        {embedLinks.map((item, index) => (
                          <div className="mb-3">
                            <div className="fs-14 text-dark font-weight-bold">
                              Embed links
                            </div>
                            <div>
                              {/* Repeat this */}
                              <div className="d-flex flex-nowrap mx-n1 mb-2 col-lg-6 px-0">
                                <div className="px-1 col">
                                  {item ? (
                                    <Input
                                      className="primary"
                                      type="text"
                                      bsSize="sm"
                                      name={`embedLinks${index}`}
                                      id="embed_link"
                                      value={item}
                                      onChange={e =>
                                        this.handleChangeLinks(e, index)
                                      }
                                    />
                                  ) : (
                                    <Input
                                      className="primary"
                                      type="text"
                                      bsSize="sm"
                                      name={`embedLinks${index}`}
                                      id="embed_link"
                                      placeholder={`Insert embed link`}
                                      onChange={e =>
                                        this.handleChangeLinks(e, index)
                                      }
                                    />
                                  )}
                                </div>
                                <div className="px-1">
                                  {/* <Button color="primary" size="sm" title="Add" className="mr-0">
                                                <FontAwesomeIcon icon="plus" onClick={() => this.handleEmbedLink('+')} />
                                            </Button> */}
                                  <Button
                                    color="tertiary"
                                    className="mw"
                                    onClick={() => this.handleEmbedLink("+")}
                                  >
                                    Add Another Link
                                  </Button>
                                  {/* Hide below if one remains */}
                                  {embedLinks.length > 1 ? (
                                    // <Button color="danger" size="sm" title="Remove" className="ml-2">
                                    //     <FontAwesomeIcon icon="minus" onClick={() => this.handleEmbedLink('-')} />
                                    // </Button>
                                    <Button
                                      color="danger"
                                      className="mw"
                                      onClick={() => this.handleEmbedLink("-")}
                                    >
                                      Delete
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Uploaded Documents shown here */}
                    {showUploadedDocuments ? (
                      <div className="mt-3">
                        <div className="fs-14 text-dark font-weight-bold">
                          Uploaded Documents
                        </div>
                        <div className="border mb-3">
                          <Row xs={2} sm={3} lg={4} noGutters>
                            {this.state.dispute_imgepreview.map(
                              (image, index) =>
                                image.toString().includes("data:image") ||
                                image.toString().includes("data:video") ? (
                                  ""
                                ) : (
                                  <Col>
                                    <div
                                      className="d-flex pr-3 m-2"
                                      role="button"
                                      onClick={() =>
                                        this.setState({
                                          viewMyPhotosModal: true
                                        })
                                      }
                                    >
                                      <div className="flex-grow-1 mw-100">
                                        <div
                                          className="bg-light p-2 text-truncate border fs-13"
                                          title={
                                            this.state.dispute_files &&
                                            this.state.dispute_files[index] &&
                                            this.state.dispute_files[index].name
                                          }
                                        >
                                          <img
                                            width={24}
                                            className="mr-2"
                                            src={
                                              "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/file_icon.png"
                                            }
                                            alt="Icon"
                                          />{" "}
                                          {this.state.dispute_files &&
                                            this.state.dispute_files[index] &&
                                            this.state.dispute_files[index]
                                              .name}
                                        </div>
                                      </div>
                                      <div className="mx-n3 mt-2">
                                        <Button
                                          color="delete-media _alt"
                                          size="sm"
                                          title="Remove Media"
                                          onClick={() =>
                                            this.deleteImage(index)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="trash-alt"
                                            size="sm"
                                          />
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                )
                            )}
                          </Row>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* Uploaded media shown here */}
                    {showUploadedMedia ? (
                      <div className="mt-3">
                        <div className="fs-14 text-dark font-weight-bold">
                          Uploaded media
                        </div>
                        <div className="border">
                          <Row noGutters>
                            
                            {this.state.dispute_imgepreview.map(
                              (image, index) =>
                                image.toString().includes("data:image") ||
                                image.toString().includes("data:video") ? (
                                  <Col xs="auto">
                                    <div
                                      className="d-flex pr-3 m-2"
                                      role="button"
                                      onClick={() =>
                                        this.setState({
                                          viewMyPhotosModal: true
                                        })
                                      }
                                    >
                                      <div className="flex-grow-1 mw-100">
                                        {image
                                          .toString()
                                          .includes("data:image") ? (
                                          <div className="project-thumbnail">                                            
                                            <img
                                              className="img-fluid img-thumbnail"
                                              src={image}
                                              alt="dispute"
                                              title="Image Name"
                                            />
                                          </div>
                                        ) : image
                                            .toString()
                                            .includes("data:video") ? (
                                          // <div
                                          //   className="bg-light p-2 text-truncate border fs-13"
                                          //   title={
                                          //     this.state.dispute_files &&
                                          //     this.state.dispute_files[index] &&
                                          //     this.state.dispute_files[index]
                                          //       .name
                                          //   }
                                          // >
                                          //   <img
                                          //     width={24}
                                          //     className="mr-2"
                                          //     src={
                                          //       "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/videoicon.jpg"
                                          //     }
                                          //     alt="Icon"
                                          //   />{" "}
                                          //   {this.state.dispute_files &&
                                          //     this.state.dispute_files[index] &&
                                          //     this.state.dispute_files[index]
                                          //       .name}
                                            
                                          // </div>
                                          <div
                                            className="bg-light p-2 text-truncate border fs-13"
                                          >
                                              {this.state.dispute_files &&
                                              this.state.dispute_files[index] &&
                                              this.state.dispute_files[index].name
                                              }                                              
                                            <img
                                              className="img-fluid img-thumbnail"
                                              width={40}
                                              className="mr-2"
                                              src="https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/videoicon.jpg"
                                              />
                                              {/* {console.log("=======>>>video",this.state.imagePreview_Url)} */}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="mx-n3 mt-2">
                                        <Button
                                          color="delete-media _alt"
                                          size="sm"
                                          title="Remove Media"
                                          onClick={() =>
                                            this.deleteImage(index)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="trash-alt"
                                            size="sm"
                                          />
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )
                            )}
                          </Row>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Already Saved Media */}
                    {savedMedia.length > 0 ? (
                      <div className="mt-3">
                        <div className="fs-14 text-dark font-weight-bold">
                          Saved Media
                        </div>
                        <div className="border">
                          <Row xs={2} sm={3} lg={4} noGutters>
                            {savedMedia.map((media, index) => (
                              <Col>
                                <div
                                  className="d-flex pr-3 m-2"
                                  role="button"
                                  // onClick={() =>
                                  // this.setState({ viewMyPhotosModal: true })
                                  // }
                                >
                                  {media.includes(".png") ||
                                  media.includes(".jpg") ||
                                  media.includes(".jpeg") ||
                                  media.includes(".mp4") ? (
                                    <>
                                      <img
                                        className="img-fluid img-thumbnail"
                                        src={media}
                                        alt="dispute"
                                        title="Image Name"
                                      />
                                    </>
                                  ) : media.includes(".mp4") ? (
                                    <>
                                      <img
                                        width={24}
                                        className="mr-2"
                                        src={
                                          "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/videoicon.jpg"
                                        }
                                        alt="Icon"
                                      />{" "}
                                      {media.split("/")[2]}
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        width={24}
                                        className="mr-2"
                                        src={
                                          "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/file_icon.png"
                                        }
                                        alt="Icon"
                                      />{" "}
                                      {media.split("/")[2]}
                                    </>
                                  )}
                                  <div className="mx-n3 mt-2">
                                    <Button
                                      color="delete-media _alt"
                                      size="sm"
                                      title="Remove Media"
                                      onClick={() =>
                                        this.deleteSavedImage(index)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="trash-alt"
                                        size="sm"
                                      />
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* </CollapseBasic> */}
              </div>

              <div className="bg-white p-3 mb-3">
                {/* <CollapseBasic
                        title="Step 3. User Consent"
                        noHr={true}
                        containerClass="type-primary bg-transparent"
                        bodyClass="mt-3"
                        isOpen={true}
                        size="sm"
                        > */}
                <div className="fs-16 font-weight-bold text-primary">
                  Step 3. User Consent
                </div>
                <hr className="bg-light" />
                <p className="text-dark fs-14">
                  All parties involved in the review dispute will have 7 days to
                  respond to the claims of the dispute. All parties will have a
                  chance to also upload their reasoning and any evidence they
                  may have. After 1 week, a randomly selected jury comprised of
                  WikiReviews community members will vote on the outcome of the
                  review based on all evidence and explanations given.
                </p>{" "}
                <p className="text-dark fs-14">
                  The WikiReviews jury will decide to keep the review where it
                  is on the site, mark the review as not recommended and moved
                  to the filtered section, or remove the review from the site.
                  Any review that is marked as not recommended or removed from
                  the site will have no weight on the listing’s overall star
                  rating.
                </p>
                <FormGroup>
                  {/* <Label className="fs-14 text-dark font-weight-bold" check> */}
                  {/* <Input
                                onChange={this.handleChange}
                                name="consent"
                                value={consent}
                                type="checkbox"
                            />{" "} */}
                  <span className="fs-14 text-dark font-weight-bold">
                    If you understand the review dispute process and still wish
                    to dispute the review, please click on the submit button
                    below.
                  </span>
                  {/* </Label> */}
                  <p className="text-danger text-right fs-14">
                    {disputeValidationError}
                  </p>
                </FormGroup>
                <div className="mt-2">
                  <div className="d-flex justify-content-end mx-n2">
                    <div className="px-2 mb-2">
                      <Button
                        color="grey"
                        className="mw"
                        onClick={() =>
                          this.props.dispute_modal_status({ modal: false })
                        }
                      >
                        Cancel Dispute
                      </Button>
                    </div>
                    {showDraftButton ? (
                      <div className="px-2 mb-2">
                        <Button
                          color="tertiary"
                          className="mw"
                          onClick={() => this.handleDisputeSubmit("draft")}
                        >
                          Save Review Dispute Draft
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="px-2 mb-2">
                      <Button
                        color="primary"
                        className="mw"
                        onClick={() => this.handleDisputeSubmit()}
                      >
                        Submit Review Dispute
                      </Button>
                    </div>
                  </div>
                </div>
                {/* </CollapseBasic> */}
              </div>
            </ModalBody>
          </>
        </Modal>

        {/* SEE HOW REVIEW DISPUTES WORK */}
        <Modal
          isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
          toggle={() =>
            this.setState({
              watchHowDisputeReviewWorksModalToggle: !this.state
                .watchHowDisputeReviewWorksModalToggle
            })
          }
        >
          <ModalHeader
            className="text-uppercase px-0"
            toggle={() =>
              this.setState({
                watchHowDisputeReviewWorksModalToggle: !this.state
                  .watchHowDisputeReviewWorksModalToggle
              })
            }
          >
            SEE HOW REVIEW DISPUTES WORK
          </ModalHeader>
          <ModalBody className="p-0 bg-transparent">
            <div className="text-center">
              <video controls autoPlay width="100%">
                <source
                  src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </ModalBody>
        </Modal>

        {/* Dispute Status Modal */}
        <Modal
          size="sm"
          isOpen={confirmationToggle}
          className="text-center"
          modalClassName="backdrop-opaque top-shifted transition-none"
        >
          <div className="bg-white modal-header">
            <h5 className="modal-title mx-auto text-dark">
              Your Review Dispute has been {is_draft ? "Saved" : "Submitted"}!
            </h5>
            {/* <button
                        className="btn btn-sm"
                        aria-label="Close"
                        
                        >
                        <span aria-hidden="true" onClick={()=> this.setState({confirmationToggle: false})} >×</span>
                        </button> */}
          </div>
          {is_draft ? (
            <ModalBody className="text-dark">
              <p className="text-dark fs-14">
                Your dispute has been saved as draft. You may edit, delete and
                post your saved dispute anytime. You will see all your drafted
                review in "My Profile" section under "Reviews" tab.
              </p>
            </ModalBody>
          ) : (
            <ModalBody className="text-dark">
              <p className="text-dark fs-14">
                All parties involved in the review dispute will have 7 days to
                respond to the claims of the dispute. All parties will have a
                chance to also upload their reasoning and any evidence they may
                have. After 1 week, a randomly selected jury comprised of
                WikiReviews community members will vote on the outcome of the
                review based on all evidence and explanations given.
              </p>
              <p className="text-dark fs-14">
                The WikiReviews jury will decide to keep the review where it is
                on the site, mark the review as not recommended and moved to the
                filtered section, or remove the review from the site. Any review
                that is marked as not recommended or removed from the site will
                have no weight on the listing’s overall star rating.
              </p>
            </ModalBody>
          )}
          <ModalFooter className="bg-white">
            <div className="text-center w-100">
              {/* <Button
                        color="primary"
                        size="sm"
                        className="btn-confirm"
                        onClick={()=> this.setState({confirmationToggle: false})}
                    >
                        Cancel
                    </Button> */}
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={() => this.handleStatus()}
              >
                Ok
              </Button>
            </div>
          </ModalFooter>
        </Modal>

        {/* Confirmation Modal */}
        <Modal
          isOpen={draftToggle}
          className="text-center"
          modalClassName="backdrop-opaque top-shifted transition-none"
        >
          <div className="bg-white modal-header">
            {administrativeRequested ? (
              <h5 className="modal-title mx-auto text-dark">
                Administrative Requested Review
              </h5>
            ) : (
              <h5 className="modal-title mx-auto text-dark">
                Saved Review Dispute
              </h5>
            )}
            <button className="btn btn-sm" aria-label="Close">
              <span
                aria-hidden="true"
                onClick={() => {
                  this.setState({ draftToggle: false });
                  this.props.dispute_modal_status({ modal: false });
                }}
              >
                ×
              </span>
            </button>
          </div>
          {administrativeRequested ? (
            <ModalBody className="text-dark">
              Do you want to Continue with the Requested Administrative Review
              or start over again with the dispute process.
            </ModalBody>
          ) : (
            <ModalBody className="text-dark">
              Do you want to Continue with the saved dispute or start over again
              with the dispute process.
            </ModalBody>
          )}
          <ModalFooter className="bg-white justify-content-center">
            <div className="d-flex justify-content-center mx-n2">
              <Button
                color="primary"
                size="sm"
                className="btn-confirm px-2"
                // onClick={()=> this.setState({draftToggle: false})}
                onClick={() => this.handleDeleteDraft()}
                style={{ width: 200 }}
              >
                {administrativeRequested
                  ? "Cancel Adminstrative Request"
                  : "Cancel Saved Dispute"}
              </Button>
              <Button
                color="primary"
                size="sm"
                className="btn-confirm px-2"
                onClick={() => this.handleSavedDispute()}
                style={{ width: 200 }}
              >
                {administrativeRequested
                  ? "Continue with Adminstrative Request"
                  : "Continue with Saved Review Dispute Draft"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>

        {/* View Media Modal */}
        <Modal
          centered
          isOpen={this.state.viewMyPhotosModal}
          toggle={() =>
            this.setState({ viewMyPhotosModal: !this.state.viewMyPhotosModal })
          }
        >
          {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
          <ModalBody className="bg-transparent p-0">
            <Carousel
              responsive={responsive.videoModal}
              infinite={true}
              autoPlay={false}
              swipeable={true}
              showDots={false}
              arrows={true}
              sliderClass={`${imageAttacment?.length === 0}` ? "mx-auto" : ""}
            >
              {imageAttacment.length > 1 ? (
                imageAttacment.map((med, i) => {
                  return (
                    <div
                      className="item fs-14 h-100 d-flex flex-column justify-content-center"
                      key={i}
                    >
                      <div>
                        <div className="d-flex text-white justify-content-between mt-3">
                          <div className="mr-2 ff-alt">{med?.caption}</div>
                          <div className="ff-base">{med?.label}</div>
                        </div>
                      </div>
                      <div
                        className="text-center mt-3 d-flex flex-column justify-content-center align-items-center"
                        style={{ minHeight: "300px" }}
                      >
                        {(med?.type === "image" || med?.type === "") && (
                          <img
                            className="img-fluid"
                            src={med?.url}
                            alt=""
                            style={{
                              maxHeight: "300px",
                              border: "2px solid #8791b5"
                            }}
                          />
                        )}
                        {med?.type === "video" && (
                          <video
                            className="img-fluid"
                            controls
                            style={{ maxHeight: "300px" }}
                          >
                            <source src={med?.url} type="video/mp4" />
                            {"Your browser does not support the video tag."}
                          </video>
                        )}
                      </div>
                      <div className="px-2 mt-3">
                        <div className="d-flex flex-nowrap mx-n2">
                          <div className="px-2 col d-flex flex-nowrap">
                            <div className="mr-2">
                              <img
                                className="img-circle _30x30"
                                src={
                                  med?.user?.current_profile_pic
                                    ? med?.user?.current_profile_pic
                                    : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"
                                }
                                alt=""
                                onError={error =>
                                  (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                }
                              />
                            </div>
                            <div>
                              <div className="text-primary ff-base">
                                Uploaded by&nbsp;
                                <a className="text-reset" href="#">
                                  <span className="font-weight-bold">
                                    {med?.user?.user?.first_name}
                                  </span>{" "}
                                </a>
                              </div>
                              <div className="text-tertiary ff-base">
                                {med?.upload_date}
                              </div>
                            </div>
                          </div>
                          <div className="px-2 col-auto">
                            <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                              {i + 1} of {imageAttacment.length}
                            </h2>
                          </div>
                          <div className="px-2 col">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="item px-4">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                      <span>No result found for this category</span>
                    </div>
                  </div>
                </div>
              )}
            </Carousel>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapState = state => ({
  my_profile: state.user.my_profile,
  add_dispute_status: state.dispute.add_dispute_status,
  get_dispute_draft_data: state.dispute.get_dispute_draft_data,
  delete_dispute_draft_data: state.dispute.delete_dispute_draft_data
});

const mapProps = dispatch => ({
  add_dispute_review: params => dispatch(add_dispute_review(params)),
  dispute_modal_status: params => dispatch(dispute_modal_status(params)),
  get_dispute_draft: params => dispatch(get_dispute_draft(params)),
  delete_dispute_draft: params => dispatch(delete_dispute_draft(params))
  // get_reviewgroup: (assetType, postId, type) => dispatch(get_reviewgroup(assetType, postId, type)),
});

export default connect(mapState, mapProps)(DisputingReview);
