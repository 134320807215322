/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";

class PublicRoute extends Component {

    isLoggedIn = () => {
        return this.props.isLoggedIn === true ? true : false
    }

    render() {
        const { component: Component, restricted, ...rest } = this.props;
        let isLoggedIn = this.isLoggedIn()
        return (
            <Route {...rest} render={props =>
                (isLoggedIn && restricted ?
                    <Redirect to='/home' />
                    : <Component {...props} />)} />
        )
    }
}

const mapState = (state) => {
    return {
        authStates: state.auth,
        isLoggedIn: state.auth.isLoggedIn
    }
}

export default connect(mapState)(PublicRoute);
