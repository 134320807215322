import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ModalComponent = ({ size, isOpen, toggleHandler,isScrollable, header, headerClass, body,bodyClass, footer, footerClass }) => {
    return (
        <Modal
            size={size}
            isOpen={isOpen}
            toggle={toggleHandler}
            scrollable={isScrollable}
            backdrop="static"
        >
            <ModalHeader
                className={headerClass}
                toggle={toggleHandler}
            >
                {header}
            </ModalHeader>
            <ModalBody className={bodyClass}>
                {body}
            </ModalBody>
            <ModalFooter className={footerClass}>
                {footer}
            </ModalFooter>
        </Modal>
    )
}

export default ModalComponent;


