import React, { useState } from 'react'
import { Button, FormGroup, Input, Media } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import EditBtn from '../../../atoms/editBtn';
import DeleteBtn from '../../../atoms/deleteBtn';

const Reply = ({ reply, index, checkIfLoggedIn, answer, question, productId, myProfile, deleteAnswer, confirmDeleteModalToggle, submitReply }) => {
    const [replyEditText, setReplyEditText] = useState({});
    const [toggleEditReply, setToggleEditReply] = useState({});
    
    const handleEditReplyChange = (e, index) => {
        const text = e.target.value;
        if (text !== '') {
            replyEditText[index] = text;
            setReplyEditText({ ...replyEditText })
        }
    }
   
    const editReply = (id, text, index) => {
        

        if (toggleEditReply[index] == undefined) {
            toggleEditReply[index] = true;
        } else {
            toggleEditReply[index] = !toggleEditReply[index];
        }
        if (id && text != '') {
            replyEditText[index] = text;
            setReplyEditText({...replyEditText});
            setToggleEditReply({...toggleEditReply});
        }
    }


    const submitEditReply = (text, reply) => {
        
        if (text) {
            submitReply({ id:reply?.id, text }, 'edit_reply');
            setReplyEditText({});
            setToggleEditReply({});
        }
    }
    return (
        <div key={index}>
            <Media className="media-post mt-3">
                <Media>
                    <Link
                        to={{ pathname: `/people/${reply?.sender?.username}` }}
                    >
                        {reply?.sender?.current_profile_pic
                            ?
                            <img className="img-circle _50x50" src={reply.sender.current_profile_pic} alt={reply?.sender?.full_name} onError={(error) =>
                                (error.target.src = require("../../../../assets/images/icons/user-circle.png"))
                            } />
                            :
                            <img className="img-circle _50x50"  src={require("../../../../assets/images/icons/user-circle.png")} alt={reply?.sender?.full_name}/>
                        }

                    </Link>
                </Media>
                <Media body>
                    <Media className="mb-0" heading>
                        <div className="d-flex">
                            <div>
                                <span className="text-dark fs-14">
                                    <Link to={{ pathname: `/people/${reply?.sender?.username}` }} className="fs-13 text-reset ff-base font-weight-bold text-decoration-none">
                                        {`${reply?.sender?.full_name}: `}
                                    </Link>
                                </span>
                                {!toggleEditReply[index] ?
                                    <span className="comment-body-renderer text-darker font-weight-normal fs-13">
                                        {reply.body}
                                    </span>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </Media>

                    {/* Hide when editing Post */}
                    <div>
                        <div className="d-flex mt-n1">
                            <div className="mr-2">
                                {/* Reply Button */}
                                <span className="ff-alt text-tertiary align-middle">
                                    {/* <span>{" - "}</span> */}
                                    {reply?.sent_at}
                                </span>
                                
                            </div>
                            <div className="ml-auto" hidden={!checkIfLoggedIn()}>
                                {myProfile && myProfile?.user?.username && reply?.sender?.username == myProfile.user.username
                                    ?
                                    <EditBtn onClick={() => editReply(reply.id, reply.body, index)} />
                                    :
                                    ""
                                }
                                {myProfile && myProfile?.user?.username && reply?.sender?.username == myProfile.user.username
                                    ?
                                    <DeleteBtn onClick={() => {deleteAnswer(reply.id, 'reply'); confirmDeleteModalToggle();}} />
                                    :
                                    ""
                                }
                            </div>
                            
                        </div>
                    </div>

                    {/* Edit Reply */}
                    {toggleEditReply[index] ?
                        <div>
                            <FormGroup>
                                <Input
                                    bsSize="sm"
                                    className="text-primary bordermb-2"
                                    type="textarea"
                                    placeholder="Edit Your Reply"
                                    value={replyEditText[index]}
                                    onChange={(e) => handleEditReplyChange(e, index)}
                                />
                                <div className="text-right">
                                    <div className="text-right mt-1">
                                        <Button
                                            size="sm"
                                            color="primary"
                                            className="ml-0"
                                            onClick={
                                                () => {
                                                    toggleEditReply[index] = false;
                                                    setToggleEditReply({ ...toggleEditReply });
                                                }
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size="sm"
                                            color="primary"
                                            className=" mr-2"
                                            disabled={replyEditText[index] == '' || replyEditText[index] == undefined || replyEditText[index] == null}
                                            onClick={() => { submitEditReply(replyEditText[index], reply) }}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                        :
                        ""
                    }
                </Media>
            </Media></div>
    )
}

export default Reply;