import React, { Component } from 'react';
import {
    Button, Col, FormGroup,
    Input, Label, Media, Nav, NavItem, NavLink, Row, TabContent, TabPane,
    Modal, ModalHeader, ModalBody, ModalFooter, Progress, Alert, Form
} from 'reactstrap';
import EditBtn from "../../atoms/editBtn";
import DeleteBtn from "../../atoms/deleteBtn";
import feedCam from './../../../assets/images/icons/feed-cam.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { get_problem_list, submit_problem, submit_problem_answer, user_feed_prob, submit_edit_answer, submit_answer_reply } from './../../../actions/products';
import Carousel from 'react-multi-carousel';
import StarsRating from 'stars-rating';

const responsive = {
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1,
        },
    }
};

class ProbAndSol extends Component {
    constructor(props) {
        super(props);
        this.callOnce = true;
        this.state = {
            problemText: '',
            isEnable: false,
            business_tos: "",
            problemData: null,
            solutionEnable: false,
            solutionText: {},
            editAnswer: false,
            toggleElem: {},
            toggleReply: {},
            editAnswerText: {},
            itemRecord: null,
            editReply: false,
            replyText: {},
            toggleEditReply: {},
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            fetchGalleryAlbum: "",
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            uploadMediaPostModal: false,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            albumType: '',
            pageNo: 1,
            selectedLabel: 'Sort By',
            productId: null,
            uploadedFiles: [],
            uploadUrl: `/upload/multiuploader/?album=feeds&instance=newpost&image=undefined&doc=undefined`,
            viewMyPhotosModalnew: false,
            secondCarouselMedia: [],
            isToggleModalTrue: false,
            toggleProb: {},
            editProblemText: {},
            replyEditText: {}
        }
    }
    componentWillReceiveProps(nextProps) {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state;
        if (nextProps.problem_list && nextProps.problem_list.results) {

            this.setState({ problemData: nextProps.problem_list.results })
        }
        if (nextProps.uploadedMediaSet.length !== this.state.uploadedFiles.length) {
            this.setState({ uploadedFiles: [...nextProps.uploadedMediaSet] });
        }

        if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypesList: nextProps.album_types_list,
                },
                //uploadMediaModal: true,
                selectedUpMediaType: "upload",
                showGalleryType: "images",
            });
        }

        if (
            nextProps.album_type_data &&
            Object.keys(nextProps.album_type_data).length > 0
        ) {
            let item = nextProps.album_type_data;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypeData: nextProps.album_type_data,
                },
                fetchGalleryScroll, fetchGalleryCount,
            });
        }
        if (nextProps.item_record) {
            this.setState({ itemRecord: nextProps.item_record })
        }
    }

    componentDidMount() {
        let id = this.props?.productId;
        if (id) {
            this.props.get_problem_list(id);
        }
    }

    componentDidUpdate() {
        if (this.props?.productId && this.callOnce) {
            this.callOnce = false;
            this.props.get_problem_list(this.props.productId);
        }
    }
    removeFromUploaded = (index) => {
        let { uploadedFiles } = this.state;
        if (index > -1) {
            uploadedFiles.splice(index, 1);
        }
        this.setState({ uploadedFiles });
    }

    handleChange = (e) => {
        let text = e.target.value;
		if (text !== '') {
            this.setState({ problemText: text, isEnable: true })
        }
        else {

            this.setState({ problemText: text, isEnable: false })
        }
    }

    handleRadioButtonChange = async (evt) => {
        await this.setState({
            [evt.target.name]: evt.target.id,
        });
    }

    handleProblemSubmit = async () => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        const { productId } = this.props;
        let messagemedia_set = [...this.state.uploadedFiles].map(item => item.id);
        const data = {
            body: this.state.problemText ? this.state.problemText : "",
            tos: this.state.business_tos ? this.state.business_tos : "",
            tag_list: [],
            listing_tag_list: [],
            question_rating_category: [],
            messagemedia_set: messagemedia_set,
            user_entry: productId
        }
        if(productId){
            await this.props.submit_problem(productId, data);
            await this.props.resetUploadedMediaSet();

            await this.setState({
                business_tos: '',
                problemText: '',
                uploadMedia: {
                    mediaType: "image",
                    albumType: "",
                    pageNo: 1,
                    albumTypesList: [],
                    albumTypeData: {},
                    selectedMedia: [],
                    selectedMediaIds: [],
                    embedLinks: {},
                    uploadFiles: [],
                    uploadedFiles: [],
                    progress: 0,
                },
                uploadedFiles: []
            })
        }
    }
    delete_problem = (id,type) => {
		let deleteMessage = "Are you sure you want to delete this problem?";
		let message = "Your problem has been deleted.";
		if(type != "undefined" && type == "solution"){
			deleteMessage = "Are you sure you want to delete this solution?";
			message = "Your solution has been deleted.";
		}
        if (type == "reply") {
            deleteMessage = "Are you sure you want to delete this reply?";
            message = "Your reply has been deleted.";
        }
        if (id) {
			this.props.toggleConfirmModal({ text:deleteMessage,isTextDelete: true, uniqueKey: 'delete-prob-sol', id,message : message });
        }

    }

    handleAnswerChange = (e,index) => {
        let {solutionText} = this.state;
        let text = e.target.value;
        solutionText[index] = text;
        if (text !== '') {
            this.setState({ solutionText, solutionEnable: true })
        }
        else {

            this.setState({ solutionText, solutionEnable: false })
        }
    }

    submitAnswer = (proId,text,index) => {
        let {solutionText} = this.state;
        let updatedText = text;
        let id = parseInt(proId);
        let messagemedia_set = [];
        let problem = true;
        let reply = updatedText;
        let tag_list = [];
        let user_entry = this.props?.productId;
        let data = { [id]: updatedText, messagemedia_set, problem, reply, tag_list, user_entry };

        if (updatedText && id && problem && reply !== '' && user_entry) {
            solutionText[index] = '';
            this.props.submit_problem_answer(id, data);
            this.setState({ solutionText, solutionEnable: false })
        }

    }

    editAnswer = (id, text, index) => {
        let { toggleElem, editAnswerText } = this.state;
        if (toggleElem[index] == undefined) {
            toggleElem[index] = true;
        } else {
            toggleElem[index] = !toggleElem[index];
        }
        // editAnswerText[index] = text;
        if (id && text != '') {
            editAnswerText[index] = text;
            this.props.user_feed_prob(id);
            this.setState({
                editAnswerText,
                editAnswer: true,
                toggleElem
            })
        }
    }

    editProblem = (id, text, index) => {
        let { toggleProb, editProblemText } = this.state;
        if (toggleProb[index] == undefined) {
            toggleProb[index] = true;
        } else {
            toggleProb[index] = !toggleProb[index];
        }
        // editAnswerText[index] = text;
        if (id && text != '') {
            editProblemText[index] = text;
            this.props.user_feed_prob(id);
            this.setState({
                editProblemText,
                toggleProb
            })
        }
    }

    handleAnswerEdit = (e, index) => {
        let { editAnswerText } = this.state;
        let text = e.target.value;
        editAnswerText[index] = text;
        if (text !== '') {
            this.setState({ editAnswerText })
        }
        else {

            this.setState({ editAnswerText })
        }
    }

    submitEditAnswer = (msgId, text) => {
        let type = '';
        let body = '';
        let record = this.state.itemRecord;
        // let messagemedia_set = [];
        // if (record) {
        //     messagemedia_set = record.messagemedia_set;
        //     type = record.type;
        // }
        if (record) {
            type = record.type;
        }
        let user_entry = this.props.productId;
        let msg_id = msgId;
        let editpost = true;
        if (text && text != '' && text != undefined && text != null) {
            body = text;
        }
        if (body != '' && type == 'biz_msg' && user_entry && msgId && editpost) {
            let data = { body, type,  user_entry, msg_id, editpost }
            this.props.submit_edit_answer(msgId, data,type);
            this.setState({
                editAnswer: false,
                toggleElem: {}
            })
        }
        if (body != '' && type == 'problem' && user_entry && msgId && editpost) {
            let data = { body, type, user_entry, msg_id, editpost }
            this.props.submit_edit_answer(msgId, data,type);
            this.setState({
                editAnswer: false,
                toggleProb: {}
            })
        }

    }

    editReply = (id, text, index) => {
        let { replyEditText, toggleEditReply } = this.state;

        if (toggleEditReply[index] == undefined) {
            toggleEditReply[index] = true;
        } else {
            toggleEditReply[index] = !toggleEditReply[index];
        }
        if (id && text != '') {
            replyEditText[index] = text;
            this.props.user_feed_prob(id);
            this.setState({
                replyEditText,
                editReply: true,
                toggleEditReply
            })
        }
    }
    handleReply = (e, index) => {
        let { replyText } = this.state;
        let text = e.target.value;
        replyText[index] = text;
        if (text !== '') {
            this.setState({ replyText })
        }
        else {

            this.setState({ replyText })
        }
    }

    handleEditReply = (e, index) => {
        let { replyEditText } = this.state;
        let text = e.target.value;
        replyEditText[index] = text;
        if (text !== '') {
            this.setState({ replyEditText })
        }
        else {

            this.setState({ replyEditText })
        }
    }

    handleProblemEdit = (e, index) => {
        let { editProblemText } = this.state;
        let text = e.target.value;
        editProblemText[index] = text;
        if (text !== '') {
            this.setState({ editProblemText })
        }
        else {

            this.setState({ editProblemText })
        }
    }

    

    submitReply = (msgId, text,type) => {

        let body = '';
        let user_entry = this.props.productId;
        let answer_id = msgId;
        let editpost = null;
        if(type == 'add_reply'){
            editpost = false;
        }
        if(type == 'edit_reply'){
            editpost = true;
        }
        if (text && text != '' && text != undefined && text != null) {
            body = text;
        }
        
        if (body != '' && user_entry && answer_id && type == 'add_reply' && !editpost) {
            let data = { answer_id, body, user_entry }
            this.props.submit_edit_answer(msgId, data,type);
            this.setState({
                editReply: false,
                toggleReply: {},
                replyText: {}
            })
        }

        if (body != '' && user_entry && answer_id && type == 'edit_reply' && editpost) {
            let data = { answer_id, body, user_entry,editpost }
            this.props.submit_edit_answer(msgId, data,type);
            this.setState({
                editReply: false,
                toggleEditReply: {},
                replyText: {}
            })
        }

    }

    openSecondCarousal = (files, index) => {
        const carouselMedia = files;
        const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
        const array2 = (index !== carouselMedia.length - 1) ? carouselMedia.filter((item, i) => i > index) : [];
        const newArray = [carouselMedia[index], ...array2, ...array1];

        this.setState({ secondCarouselMedia: newArray, viewMyPhotosModalnew: true });
    }

    toggleRep = (id, index) => {
        let { toggleReply } = this.state;
        if (toggleReply[index] == undefined) {
            toggleReply[index] = true;
        } else {
            toggleReply[index] = !toggleReply[index];
        }
        this.setState({ toggleReply })
    }

    toggleModal = () => {
        this.setState({ isToggleModalTrue: !this.state.isToggleModalTrue })
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_info} = this.props;
        if(my_profile_info && my_profile_info.id && authId && (authId == my_profile_info.id)){
            return true;
        }else{
            return false;
        }
    }

    toggleAnswers = (item) =>{
        const problemData = JSON.parse(JSON.stringify(this.state.problemData));
        const selectedProblemData = problemData.find(a=> a.id === item.id);
        selectedProblemData.hideAnswers = !selectedProblemData.hideAnswers
        this.setState({
            ...this.state,
            problemData
        });
    }

    render() {
        let { solutionText,replyEditText,editProblemText, isToggleModalTrue, toggleProb, problemText, isEnable, uploadUrl, problemData, solutionEnable, toggleEditReply, editAnswer, editAnswerText, editReply, replyText, toggleElem, toggleReply } = this.state;
        let userDetail = this.props?.my_profile_info;

        return (
            <div>
                <div className="mb-4 text-dark">
                    <h2>
                        Ask For Help With A Problem
                                            </h2>
                    <p className="fs-14">
                        Having trouble with this product? Get expert help by telling the WikiReviews community about your problem.
                                            </p>
                </div>

                <div>
                    <div>
                        <FormGroup className="main-post-formgroup">
                            <div className="input-labl">
                                <Input
                                    className="primary text-primary font-weight-bold"
                                    type="textarea"
                                    rows="4"
                                    placeholder="Please post the problem you are having with this product"
                                    value={problemText}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </FormGroup>
                    </div>

                    <div className="mb-3">
                        <FormGroup tag="fieldset">
                            <Row>
                                <Col xs="auto">
                                    <FormGroup check>
                                        <Label className="ff-base font-weight-normal text-dark" check>
                                            <Input type="radio" name="business_tos"
                                                onChange={this.handleRadioButtonChange}
                                                value={this.state.business_tos}
                                                checked={this.state.business_tos === "1" ? true : false}
                                                id={1} />{' '}
                                            {'to community'}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col xs="auto">
                                    <FormGroup check>
                                        <Label className="ff-base font-weight-normal text-dark" check>
                                            <Input type="radio" name="business_tos"
                                                onChange={this.handleRadioButtonChange}
                                                value={this.state.business_tos}
                                                checked={this.state.business_tos === "3" ? true : false}
                                                id={3} />{' '}
                                            {'to friends'}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col xs="auto">
                                    <FormGroup check>
                                        <Label className="ff-base font-weight-normal text-dark" check>
                                            <Input type="radio" name="business_tos"
                                                onChange={this.handleRadioButtonChange}
                                                value={this.state.business_tos}
                                                checked={this.state.business_tos === "2" ? true : false}
                                                id={2} />{' '}
                                            {'to both'}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </div>
                </div>

                {/* Uploaded media shown here */}
                {this.state.uploadedFiles.length > 0
                    ?
                    <div className="border mb-3">
                        <Row xs={2} sm={3} md={4} lg={5} noGutters>
                            {this.state.uploadedFiles.length > 0 &&
                                this.state.uploadedFiles.map((item, index) => {
                                    return (
                                        <Col className="wk-ps-upload-image">
                                            <div className="d-flex pr-3 m-2" key={item.id}>
                                                <div>
                                                    {(item.type === "image" ||
                                                        item.media_type === "image") && (
                                                            <div

                                                                style={{ border: "none" }}
                                                            >
                                                                <img className="img-fluid img-thumbnail" src={item.file ? item.file : item.url} alt="Uploaded media" />
                                                            </div>
                                                        )}
                                                    {(item.type === "video" ||
                                                        item.media_type === "video") && (
                                                            <div

                                                                style={{ border: "none" }}
                                                            >
                                                                <video width="100" height="100">
                                                                    <source src={item.file ? item.file : item.url} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                        )}
                                                </div>

                                                <div className="mx-n3 mt-2">
                                                    <Button
                                                        color="delete-media"
                                                        size="sm"
                                                        title="Remove Media"
                                                        onClick={() =>
                                                            this.removeFromUploaded(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    : ""
                }

                <div className="mb-3">
                    <div className="d-flex mx-n2">
                        <div className="px-2">
                            <Button color="transparent" title="Upload Media" onClick={() => {
                                if(!this.checkIfLoggedIn()){
                                    this.props.forceToggleLoginModal();
                                    return false;
                                }
                                this.props.getuploadUrl({ uploadUrl });
                                this.props.toggleEnableFileUpload(true, false,false);
                            }}>< img src={feedCam} alt="" /></Button>
                        </div>
                        <div className="px-2 ml-auto">
                            <a href="javascript:void(0)" onClick={
                                () => {
                                    this.props.resetUploadedMediaSet();
                                    this.setState({
                                        problemText: "",
                                        business_tos: "",
                                        uploadedFiles: []
                                    })
                                }}>Clear</a>{" "}
                            <Button color="primary" disabled={!isEnable || this.state.business_tos == ''} onClick={this.handleProblemSubmit}>Ask Something</Button>
                        </div>
                    </div>
                </div>


                <div>
                    {/* Repeat this */}

                    {problemData && problemData.length > 0 && Array.isArray(problemData)
                        ?
                        problemData.map((item, index) => {
                            return (
                                <div className="bg-white p-3 fs-14 text-dark mb-3" key={index}>
                                    <div className="d-flex mx-n2 mb-4">
                                        <div className="px-2">
                                            <Link
                                                to={{ pathname: `/people/${item.sender_details}` }}
                                            >
                                                {item?.current_profile_pic
                                                    ?
                                                    <img className="img-circle _50x50" src={item.current_profile_pic} alt="" onError={(error) =>
                                                        (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                    } />
                                                    :
                                                    <img className="img-circle _50x50" src={require("./../../../assets/images/icons/user-circle.png")} alt="demo aa" />
                                                }

                                            </Link>
                                        </div>
                                        <div className="px-2 flex-grow-1">
                                            <div className="d-flex">
                                                <div className="d-flex">
                                                    <div className="fs-14 col pl-0 pt-2 text-tertiary">
                                                        <Link
                                                            to={{ pathname: `/people/${item.sender_details}` }}
                                                            className="text-dark ff-base font-weight-bold">{item.sender}</Link>
                                                                {" "}
                                                                <span>posted a problem for</span>
                                                                {" "}
                                                                <Link
                                                            to={{ pathname: `${item?.user_entry_to?.slug}/#` }}
                                                            className="text-dark ff-base font-weight-bold">{item.recipients} </Link>
                                                                {" "}
                                                            <span>{item.sent_at}</span>
                                                            
                                                    </div>
                                                    <StarsRating
                                                        count={5}
                                                        value={1}
                                                        size={24}
                                                        color1={'#d7d8e2'}
                                                        color2={'#122c40'}
                                                        onChange={(rating) => {
                                                            console.log(rating)
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/* Question details */}
                                            <div className="d-flex mx-n2">
                                                <div className="px-2 col">
                                                    <div className="ff-alt wb-break-word">
                                                        {!toggleProb[index] ?

                                                            item.body
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                </div>


                                                <div className="px-2 col-auto" hidden={!this.checkIfLoggedIn()}>
                                                    {userDetail && userDetail?.user?.username && item.sender_details == userDetail.user.username
                                                        && item?.conversation.length == 0 ?
                                                        <EditBtn onClick={() => { this.editProblem(item.msg_id, item.body, index) }} />
                                                        :
                                                        ""
                                                    }
                                                    {userDetail && userDetail?.user?.id && item.sender_id == userDetail.user.id && item.conversation.length > 0
                                                        ?
                                                        <EditBtn onClick={this.toggleModal} />
                                                        :
                                                        ""
                                                    }
                                                    {userDetail && userDetail?.user?.username && item.sender_details == userDetail.user.username ?
                                                        <DeleteBtn onClick={() => this.delete_problem(item.id)} />
                                                        :
                                                        ""
                                                    }
                                                </div>

                                            </div>

                                            <div className='d-flex justify-content-end  align-items-center'>
                                                <span className='text-dark font-weight-bold'>Rate this Problem:</span>
                                                <StarsRating
                                                    count={5}
                                                    value={1}
                                                    size={24}
                                                    color1={'#d7d8e2'}
                                                    color2={'#15c3d4'}
                                                    onChange={(rating) => {
                                                        console.log(rating)
                                                    }}
                                                />
                                            </div>

                                            {item?.conversation?.length ?
                                                <div className='d-flex justify-content-end  align-items-center'>
                                                    <a className='text-dark font-weight-bold cursor-pointer' onClick={() => this.toggleAnswers(item)}>
                                                        {item?.hideAnswers ? 'Show' : 'Hide'} {item?.conversation?.length} {item?.conversation?.length > 1 ? 'Solutions' : 'Solution'}
                                                    </a>
                                                </div> : ''
                                            }

                                    {/* Edit Question */}
                                    {toggleProb[index] ?

                                        <div>
                                            <FormGroup className="mb-0">
                                                <Input
                                                    bsSize="sm"
                                                    className="primary text-primary border-primary mb-2"
                                                    type="textarea"
                                                    rows="4"
                                                    placeholder="Editing your comment"
                                                    value={editProblemText[index]}
                                                    onChange={(e) => this.handleProblemEdit(e, index)}
                                                />
                                                <div className="text-right">
                                                    <div className="text-right">
                                                        <Button
                                                            size="sm"
                                                            color="link"
                                                            className="text-royal-blue font-weight-normal ff-alt px-0"
                                                            onClick={
                                                                () => {
                                                                    editProblemText[index] = '';
                                                                    this.setState({
                                                                        editProblemText
                                                                    })
                                                                }
                                                            }
                                                        >
                                                            Clear
                                                        </Button>

                                                        <Button
                                                            size="sm"
                                                            color="link"
                                                            className="text-royal-blue font-weight-normal ff-alt px-0"
                                                            onClick={
                                                                () => {
                                                                    toggleProb[index] = false;
                                                                    this.setState({
                                                                        toggleProb
                                                                    })
                                                                }
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>

                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            disabled={editProblemText[index] == '' || editProblemText[index] == undefined || editProblemText[index] == null}
                                                            onClick={() => { this.submitEditAnswer(item.msg_id, editProblemText[index]) }}

                                                        >
                                                            Post
                                                        </Button>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </div>
                                        :
                                        ""
                                    }

                                    {/* Gallery Images */}
                                    { item.attachments?.length > 0 || (item.attachments && item.attachments.length > 1) ?
                                    
                                    <div className="my-5">
                                        <div>
                                            {/* Featured Photo/Video */}
                                            { item.attachments.slice(0, 1).map((media, index) => {
                                                return (
                                                    <div className="mx-auto text-center mb-3" style={{ maxWidth: "450px" }} key={media.id} onClick={() => this.openSecondCarousal(item.attachments, index)}>
                                                        {media.media_type === 'image' && (
                                                            <div>
                                                                <img
                                                                    src={media.url}
                                                                    alt="Featured Photo"
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                        )}
                                                        {media.media_type === 'video' && (
                                                            <video className="img-fluid" controls>
                                                                <source
                                                                    src={media.url}
                                                                    type="video/mp4"
                                                                />
                                                                {'Your browser does not support the video tag.'}
                                                            </video>
                                                        )}
                                                    </div>
                                                )
                                            }
                                            )}
                                            <div className="mb-3">
                                                {/* List of Photos uploaded */}
                                                <ul className="list-unstyled d-flex justify-content-center flex-wrap mx-n1 mb-2" style={{ maxHeight: '280px', overflowY: 'auto' }}>
                                                    {/* Repeat this */}
                                                    {
                                                        item.attachments.filter((el, i) => i != 0).map((media, index) => {
                                                            return (
                                                                <li onClick={() => this.openSecondCarousal(item.attachments, index + 1)}
                                                                    className="p-1"
                                                                    role="button" key={media.id}>
                                                                    {media.media_type === 'image' ?
                                                                        <img
                                                                            className="img-thumbnail uploaded-img-thumbnail"
                                                                            src={media.url}
                                                                            alt="Featured Photo"
                                                                        />
                                                                        :
                                                                        <div className="video-holder">
                                                                            <img
                                                                                className="img-thumbnail uploaded-img-thumbnail"
                                                                                src={media.thumbnail}
                                                                                alt="Featured Video"
                                                                            />
                                                                        </div>
                                                                    }
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                                    }
                                    {/* Gallery Images ends */}


                                    </div>
                                    </div>
                                    {!item?.hideAnswers && item?.conversation && Array.isArray(item.conversation) && item?.conversation?.length > 0 ?
                                        item.conversation.map((answer, index) => {
                                            return (
                                                <div key={index}>
                                                    <Media className="media-post mt-2 p-2" style={{ backgroundColor: '#fcfbf8' }}>
                                                        <Media>
                                                            <Link
                                                                to={{ pathname: `/people/${answer.sender_details}` }}
                                                            >
                                                                {answer?.current_profile_pic
                                                                    ?
                                                                    <img className="img-circle _50x50" src={answer.current_profile_pic} alt="" onError={(error) =>
                                                                        (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                                    } />
                                                                    :
                                                                    <img className="img-circle _50x50" src={require("./../../../assets/images/icons/user-circle.png")} alt="demo aa" />
                                                                }

                                                            </Link>
                                                        </Media>
                                                        <Media body>
                                                            <Media className="mb-0" heading>
                                                                <div className="fs-14 text-tertiary">
                                                                    <div className='d-flex col pl-0'>
                                                                        <div className='fs-14 mr-1 pt-2 text-tertiary'>
                                                                            <span className="text-dark fs-14">
                                                                                <Link to={{ pathname: `/people/${answer.sender_details}` }} className="fs-14 text-reset ff-base font-weight-bold text-decoration-none">
                                                                                    {answer.sender}
                                                                                </Link>
                                                                            </span>
                                                                            <span className='mx-1'>replied to</span>
                                                                            <Link
                                                                                to={{ pathname: `/people/${item.sender_details}` }}
                                                                                className="text-dark ff-base font-weight-bold">{item.sender} </Link>
                                                                            <span>
                                                                                {answer.sent_at}
                                                                            </span>
                                                                        </div>
                                                                            <StarsRating
                                                                                count={5}
                                                                                value={1}
                                                                                size={24}
                                                                                color1={'#d7d8e2'}
                                                                                color2={'#122c40'}
                                                                                onChange={(rating) => {
                                                                                    console.log(rating)
                                                                                }}
                                                                            />
                                                                    </div>
                                                                    <div className='my-2'>
                                                                            {!toggleElem[index] ?
                                                                                <span className="comment-body-renderer text-tertiary font-weight-normal">
                                                                                    {answer.body}
                                                                                </span>
                                                                                :
                                                                                ""
                                                                            }
                                                                        </div>
                                                                </div>
                                                            </Media>

                                                            {/* Hide when editing Post */}
                                                            <div>
                                                                <div className="d-flex mt-n1">
                                                                   
                                                                    <div className="ml-auto" hidden={!this.checkIfLoggedIn()}>
                                                                        {userDetail && userDetail?.user?.username && answer.sender_details == userDetail.user.username
                                                                            ?
                                                                            <EditBtn onClick={() => {
                                                                                this.editAnswer(answer.msg_id, answer.body, index)
                                                                            }} />
                                                                            :
                                                                            ""
                                                                        }
                                                                        {userDetail && userDetail?.user?.username && answer.sender_details == userDetail.user.username
                                                                            ?
                                                                            <DeleteBtn onClick={() => this.delete_problem(answer.msg_id,'solution')} />
                                                                            :
                                                                            ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex justify-content-end  align-items-center'>
                                                                    <span className='text-dark font-weight-bold'>Rate this Solution:</span>
                                                                    <StarsRating
                                                                        count={5}
                                                                        value={1}
                                                                        size={24}
                                                                        color1={'#d7d8e2'}
                                                                        color2={'#15c3d4'}
                                                                        onChange={(rating) => {
                                                                            console.log(rating)
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className='d-flex justify-content-end  align-items-center'>
                                                                    {/* Reply Button */}
                                                                    <button
                                                                        className="btn btn-link btn-sm px-0 font-weight-bold text-dark"
                                                                        onClick={() =>
                                                                            this.toggleRep(answer.msg_id, index)
                                                                        }
                                                                    >
                                                                        Reply to the Solution
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            {/* Show when editing your comment */}
                                                            {toggleElem[index] ?

                                                                <div>
                                                                    <FormGroup className="mb-0">
                                                                        <Input
                                                                            bsSize="sm"
                                                                            className="text-primary border mb-2"
                                                                            type="textarea"
                                                                            placeholder="Editing your comment"
                                                                            value={editAnswerText[index]}
                                                                            onChange={(e) => this.handleAnswerEdit(e, index)}
                                                                        />
                                                                        <div className="text-right mt-2">
                                                                            <div className="text-right">
                                                                                <Button
                                                                                    size="sm"
                                                                                    color="link"
                                                                                    className="text-royal-blue font-weight-normal ff-alt px-0"

                                                                                    onClick={
                                                                                        () => {
                                                                                            editAnswerText[index] = '';
                                                                                            this.setState({
                                                                                                editAnswerText
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                >
                                                                                    Clear
                                                                                </Button>

                                                                                <Button
                                                                                    size="sm"
                                                                                    color="link"
                                                                                    className="text-royal-blue font-weight-normal ff-alt px-0"
                                                                                    onClick={
                                                                                        () => {
                                                                                            toggleElem[index] = false;
                                                                                            this.setState({
                                                                                                editAnswer: false,
                                                                                                toggleElem
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                >
                                                                                    Cancel
                                                                                </Button>

                                                                                <Button
                                                                                    size="sm"
                                                                                    color="primary"
                                                                                    disabled={editAnswerText[index] == '' || editAnswerText[index] == undefined || editAnswerText[index] == null}
                                                                                    onClick={() => { this.submitEditAnswer(answer.msg_id, editAnswerText[index]) }}

                                                                                >
                                                                                    Update
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                                :
                                                                ""
                                                            }


                                                            {/* Add Reply */}
                                                            {toggleReply[index] ?

                                                                <div className='d-flex'>
                                                                    <Link
                                                                        to={{ pathname: `/people/${item.sender_details}` }}
                                                                    >
                                                                        <img className="img-circle _50x50 mr-1" src={this.props.my_profile_info?.current_profile_pic} alt="" onError={(error) =>
                                                                            (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                                        } />

                                                                    </Link>
                                                                    <FormGroup className="mb-0 col">
                                                                        <Input
                                                                            bsSize="sm"
                                                                            className="text-primary border mb-2"
                                                                            type="textarea"
                                                                            placeholder="Add Reply"
                                                                            value={replyText[index]}
                                                                            onChange={(e) => this.handleReply(e, index)}
                                                                        />
                                                                        <div className="text-right">
                                                                            <div className="text-right">
                                                                                <Button
                                                                                    size="sm"
                                                                                    color="primary"
                                                                                    className=" mr-2"
                                                                                    onClick={() => { this.submitReply(answer.msg_id, replyText[index],'add_reply') }}
                                                                                >
                                                                                    Submit
                                                                                </Button>
                                                                                <Button
                                                                                    size="sm"
                                                                                    color="primary"
                                                                                    className="ml-0"
                                                                                    onClick={
                                                                                        () => {
                                                                                            toggleReply[index] = false;
                                                                                            this.setState({
                                                                                                toggleReply
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                >
                                                                                    Cancel
                                                                                </Button>

                                                                            </div>
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                                :
                                                                ""
                                                            }


                                                    {
                                                        answer?.replies && Array.isArray(answer.replies) && answer?.replies.length > 0 ?
                                                            answer.replies.map((reply, index) => {
                                                                return (

                                                                    <Media className="media-post mt-3" key={index}>
																		
                                                                        <Media>
                                                                            <Link
                                                                                to={{ pathname: `/people/${reply?.sender?.username}` }}
                                                                            >
                                                                                {reply?.sender?.current_profile_pic
                                                                                    ?
                                                                                    <img className="img-circle _50x50" src={reply.sender.current_profile_pic} alt="" onError={(error) =>
                                                                                        (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                                                    } />
                                                                                    :
                                                                                    <img className="img-circle _50x50" src={require("./../../../assets/images/icons/user-circle.png")} alt="demo aa" />
                                                                                }

                                                                            </Link>
                                                                        </Media>
                                                                        <Media body>
                                                                            <Media className="mb-0" heading>
                                                                                <div className="d-flex">
                                                                                    <div>
                                                                                        <span className="text-dark fs-14">
                                                                                            <Link to={{ pathname: `/people/${reply?.sender?.username}` }} className="fs-13 text-reset ff-base font-weight-bold text-decoration-none">
                                                                                                {reply?.sender?.full_name}
                                                                                            </Link>
                                                                                            {": "}
                                                                                        </span>
                                                                                        {!toggleEditReply[index] ?
                                                                                            <span className="comment-body-renderer text-darker font-weight-normal fs-13">
                                                                                                {reply.body}
                                                                                            </span>
                                                                                            :
                                                                                            ""
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Media>

                                                                            {/* Hide when editing Post */}
                                                                            <div>
                                                                                <div className="d-flex mt-n1">
                                                                                    <div className="mr-2">
                                                                                        {/* Reply Button */}
                                                                                        {/* <button
                                                                                            className="btn btn-link btn-sm px-0 font-weight-bold text-darker"
                                                                                        >
                                                                                            Reply
                                                                                        </button> */}
                                                                                        <span className="ff-alt text-tertiary align-middle">
                                                                                            {/* <span>{" - "}</span> */}
                                                                                            {reply?.sent_at}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="ml-auto" hidden={!this.checkIfLoggedIn()}>
                                                                                        {userDetail && userDetail?.user?.username && reply?.sender?.username == userDetail.user.username
                                                                                            ?
                                                                                            <EditBtn onClick={() => this.editReply(reply.id, reply.body, index)} />
                                                                                            :
                                                                                            ""
                                                                                        }
                                                                                        {userDetail && userDetail?.user?.username && reply?.sender?.username == userDetail.user.username
                                                                                            ?
                                                                                            <DeleteBtn onClick={() => this.delete_problem(reply.id, 'reply')} />
                                                                                            :
                                                                                            ""
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* Edit Reply */}
                                                                            {toggleEditReply[index] ?

                                                                                <div>
                                                                                    <FormGroup>
                                                                                        <Input
                                                                                            bsSize="sm"
                                                                                            className="text-primary bordermb-2"
                                                                                            type="textarea"
                                                                                            placeholder="Edit Your Reply"
                                                                                            value={replyEditText[index]}
                                                                                            onChange={(e) => this.handleEditReply(e, index)}
                                                                                        />
                                                                                        <div className="text-right">
                                                                                            <div className="text-right mt-2">
                                                                                                <Button
                                                                                                    size="sm"
                                                                                                    color="primary"
                                                                                                    className=" mr-2"
                                                                                                    disabled={replyEditText[index] == '' || replyEditText[index] == undefined || replyEditText[index] == null}
                                                                                                    onClick={() => { this.submitReply(reply.id, replyEditText[index],'edit_reply') }}
                                                                                                >
                                                                                                    Update
                                                                                                </Button>
                                                                                                <Button
                                                                                                    size="sm"
                                                                                                    color="primary"
                                                                                                    className="ml-0"
                                                                                                    onClick={
                                                                                                        () => {
                                                                                                            toggleEditReply[index] = false;
                                                                                                            this.setState({
                                                                                                                toggleEditReply
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                >
                                                                                                    Cancel
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </FormGroup>
                                                                                </div>
                                                                                :
                                                                                ""
                                                                            }
                                                                        </Media>
                                                                    </Media>
                                                                )
                                                            }
                                                            )
                                                            :
                                                            ""
                                                    }


                                                    </Media>
                                                </Media>

                                                </div>
                                            )
                                        }
                                        )
                                        :
                                        ""
                                    }



                                    {/* Comment box */}
                                    <div className="p-2 mt-3" style={{ backgroundColor: '#fcfbf8' }}>
                                        <Media className="media-post">
                                            <Media>
                                                <Link
                                                    to={{ pathname: `/people/${this.props.my_profile_info?.user?.username}` }}
                                                >
                                                    <img className="img-circle _50x50" src={this.props.my_profile_info?.current_profile_pic} alt="" onError={(error) =>
                                                        (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                    } />
                                                </Link>
                                            </Media>
                                            <Media body>
                                                <FormGroup className="mb-0">
                                                    <Input
                                                        bsSize="sm"
                                                        className="font-weight-bold text-primary mb-2"
                                                        type="textarea"
                                                        placeholder="Do you have a solution?"
                                                        value={solutionText[index]}
                                                        onChange={(e)=>this.handleAnswerChange(e,index)}
                                                    />
                                                    <div className="text-right">
                                                        <div className="text-right">
                                                            <Button
                                                                size="sm"
                                                                color="primary"
                                                                disabled={solutionText[index] == '' || solutionText[index] == undefined || solutionText[index] == null}
                                                                onClick={() => {
                                                                    if(!this.checkIfLoggedIn()){
                                                                        this.props.forceToggleLoginModal();
                                                                        return false;
                                                                    }
                                                                    this.submitAnswer(item.id,solutionText[index],index)}}
                                                            >
                                                                Submit
                                                        </Button>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Media>
                                        </Media>
                                    </div>
                                </div>
                            )
                        }

                        )
                        :
                        "No Record"
                    }

                </div>
                {/* View Media Modal */}
                <Modal
                    centered
                    isOpen={this.state.viewMyPhotosModalnew}
                    toggle={() => {
                        this.setState({ viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew })
                    }}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            sliderClass={true ? "mx-auto" : ""}>
                            {/* Repeat this */}
                            {this.state.secondCarouselMedia?.length > 0 && this.state.secondCarouselMedia.map((med, i) => {
                                return (
                                    <div className="item fs-14 h-100 d-flex flex-column justify-content-center"
                                        key={med.id}
                                    >
                                        <div>
                                            <div className="d-flex text-white justify-content-between mt-3">
                                                <div className="mr-2 ff-alt">{med.caption}</div>
                                                <div className="ff-base">{med.label}</div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                            {(med.media_type === "image" || med.type === "") && (
                                                <img
                                                    className="img-fluid shown-media"
                                                    src={med.url}
                                                    alt=""
                                                />
                                            )}
                                            {med.media_type === "video" && (
                                                <video className="img-fluid shown-media" controls>
                                                    <source src={med.url} type="video/mp4" />
                                                    {'Your browser does not support the video tag.'}
                                                </video>
                                            )}
                                        </div>
                                        <div className="px-2 mt-3">
                                            <div className="d-flex flex-nowrap mx-n2">
                                                <div className="px-2 col d-flex flex-nowrap">
                                                    <div className="mr-2">
                                                        <img
                                                            className="img-circle _30x30"
                                                            src={med?.user?.user?.current_profile_pic
                                                                ? med?.user?.user?.current_profile_pic
                                                                : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                                            alt=""
                                                        />

                                                    </div>
                                                    <div>
                                                        <div className="text-primary ff-base">
                                                            {` Uploaded by `}
                                                            <a className="text-reset" href="#">
                                                                <span className="font-weight-bold">
                                                                    {med.user?.user.first_name} {med.user?.user.last_name}
                                                                </span>{" "}
                                                            </a>
                                                        </div>
                                                        <div className="text-tertiary ff-base">
                                                            {med.upload_date}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-2 col-auto">
                                                    <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                                                        {i + 1} of {this.state.secondCarouselMedia.length}
                                                    </h2>
                                                </div>
                                                <div className="px-2 col">{" "}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </ModalBody>
                </Modal>

                <Modal size="sm" isOpen={isToggleModalTrue} toggle={this.toggleModal} modalClassName="backdrop-opaque top-shifted transition-none">
                    <ModalHeader toggle={this.toggleModal}></ModalHeader>
                    <ModalBody className="text-center text-dark">
                        <h2 className="mb-3">{'Alert'}</h2>
                        <p className="small">You can't edit this problem as other users have suggested a solution for it.</p>
                        <div className="pt-4">
                            <div>
                                <Button onClick={this.toggleModal} size="md" color="primary btn-confirm">Ok</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapState = (state) => ({
    problem_list: state.products.fetch_problem_list,
    my_profile_info: state.user.my_profile,
    item_record: state.products.fetch_problem_record
});

const mapDispatch = (dispatch) => ({
    get_problem_list: (id) => dispatch(get_problem_list(id)),
    submit_problem: (productId, data) => dispatch(submit_problem(productId, data)),
    submit_problem_answer: (id, data) => dispatch(submit_problem_answer(id, data)),
    user_feed_prob: (id) => dispatch(user_feed_prob(id)),
    submit_edit_answer: (id, data,type) => dispatch(submit_edit_answer(id, data,type)),
    submit_answer_reply: (data) => dispatch(submit_answer_reply(data))
})

export default connect(mapState, mapDispatch)(ProbAndSol);