import { callApi } from "../utils/apiCaller";

export const get_friends_profile_ = (data, profileType) => ({ type: "GET_FRIENDS_PROFILE", payload: data, profileType: profileType });
export const get_friends_profile = (profileType, businessID, pageNo) => {
    // ${15385975}
    return (dispatch) => {        
        callApi(`/api/friendsprofile/?type=${profileType}&entry=${businessID}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {                
                return dispatch(get_friends_profile_(response, profileType)); 
            }
        });
    };
};

export const rating_created_dispatch = (data) => ({ type: "RATING_CREATED", payload: data, });

export const setEditExistingMedia = (data) => ({ type: "EDIT_EXISTING_MEDIA", payload: data, });
export const setEditExistingEmbedMedia = (data) => ({ type: "EDIT_EXISTING_EMBED_MEDIA", payload: data, });

export const setEditReviewToggler = (data) => ({ type: "IS_EDIT_ENABLE", payload: data });
export const isEditedReviewId = (data) => ({ type: "IS_EDITED_REVIEW_ID", payload: data });
export const setFollowReviewToggler = (data) => ({ type: "FOLLOW_REVIEW", payload: data });
export const setUploadPercentage = (data) => ({ type: "UPLOAD_PERCENTAGE", payload: data });
export const setLoginPrompt = (data) => ({ type: "SET_LOGIN_PROMPT", payload: data });
export const setLoginPromptSignUp = (data) => ({ type: "SET_LOGIN_PROMPT_SIGNUP", payload: data });
export const setLoginPromptLogin = (data) => ({ type: "SET_LOGIN_PROMPT_LOGIN", payload: data });