import { callApi, responseCodeValidator } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { get_reviews_listing } from './movies'

// const defined here
export const GET_TYPE_DETAILS_BY_ID = 'GET_TYPE_DETAILS_BY_ID';
export const GET_BACK_TO_CATEGORIE = 'GET_BACK_TO_CATEGORIE';
export const GET_CATEGORY_TAXONOMY_LIST = 'GET_CATEGORY_TAXONOMY_LIST';
export const GET_ITEM_FLAGS = 'GET_ITEM_FLAGS';
export const FETCH_REVIEW_LISTING_ITEMS = 'FETCH_REVIEW_LISTING_ITEMS';
export const GET_PROFESSIONAL_PINS = 'GET_PROFESSIONAL_PINS';
export const GET_UPLOADED_MEDIA = 'GET_UPLOADED_MEDIA';
export const GET_UPLOADED_MEDIA_FOLLOW = 'GET_UPLOADED_MEDIA_FOLLOW'


export const clear_type_details = () => {
    return (dispatch) => {
        return dispatch(type_detail_dispatch(null));
    }
}

export const type_detail_dispatch = (data) => ({ type: GET_TYPE_DETAILS_BY_ID, payload: data, });
export const type_detail_failiure_dispatch = (data) => ({ type: GET_BACK_TO_CATEGORIE, payload: data, });

export const remove_back_to_list_values = () => {
    return (dispatch) => { 
        dispatch(type_detail_failiure_dispatch(null))
    }
}

export const get_type_detail = ({ type, id }) => {
    return (dispatch) => {
        callApi(`/api/${type}/${id}/`, "GET").then((response) => {
            if (response?.code == 404) {
                return dispatch(type_detail_failiure_dispatch(response))
            }

            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(type_detail_dispatch(response));
            }
        });
    };
};

export const get_id_from_slug = ({ type, slug }) => {
    return (dispatch) => {
        callApi(`/api/get_id_from_slug/?type=${type}&slug=${slug}`, "GET").then((response) => {
            if (response) {
                dispatch(type_detail_failiure_dispatch(response))
            }
        });
    }
}

export const get_list_items = (data) => ({ type: GET_CATEGORY_TAXONOMY_LIST, payload: data, });
export const get_category_taxonomy_listing = (categoryType, types, subCategory) => {
    return (dispatch) => {
        if (subCategory) {
            callApi(`/api/taxonomy/?category=${categoryType}&parent=${types}&sub_category=${subCategory}`, "GET").then((response) => {
                if (response) {
                    dispatch(get_list_items(response))
                }
            });
        } else {
            callApi(`/api/taxonomy/?category=${categoryType}&parent=${types}`, "GET").then((response) => {
                if (response) {
                    dispatch(get_list_items(response))
                }
            });
        }
        
    }
}

export const update_caption_and_categories = ({ id, data }) => {
    return (dispatch) => {
        callApi(`/upload/${id}/`, "PUT", data).then((response) => {
            if (response) {

            }
        });
    }
}

export const get_item_flags_ = (data) => ({ type: GET_ITEM_FLAGS, payload: data, });
export const get_item_flags = (type) => {
    type = type ? type : "business";
    return (dispatch) => {
        callApi(`/api/flags/?type=flaglist&category=${type}&flag_id=&object_id=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_item_flags_(response));
            }
        });
    };
};

export const update_detail = (type, id, data, message) => {
    return (dispatch) => {
        callApi(`/api/${type}/${id}/`, "PATCH", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`${message}!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_type_detail({ type: type, id: id }));
            }
        });
    };
};

export const update_PDF_cut_sheet = (type, id, data, message) => {
    return (dispatch) => {
        callApi(`/upload/multiuploader/?album=&instance=pdf&image=undefined&doc=undefined`, 'POST', data, true)
            .then((response) => {
                if (response && responseCodeValidator(String(response.code))) {
                    toast(`${message}!`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc"
                    });

                    const payload = {
                        "type": "media",
                        "entries": id,
                        "userentries_id": id,
                        "taxonomy_id": "",
                        "multiuploaderfile": [
                            response.id
                        ],
                        "galleryType": "products"
                    }
                    return dispatch(add_cut_sheet_pdf(payload, id, type));
                }
            });
    };
};

export const add_cut_sheet_pdf = (data, id, type) => {
    return (dispatch) => {
        callApi(`/api/improve/?type=&type=${data.type || 'media'}`, "POST", data).then((response) => {
            if (response && responseCodeValidator(String(response.code))) {
                return dispatch(get_type_detail({ type: type, id: id }));
            }
        });
    };
};

export const delete_pdf = (pdfIds, isUpdatingPDF, type, id, data, message) => {
    console.log({ type, id, data, message })
    return (dispatch) => {
        callApi(`/upload/multiuploader_delete_multiple/?delete_${type}=True`, 'POST', pdfIds)
            .then((response) => {
                if (response && responseCodeValidator(String(response.code))) {
                    if (isUpdatingPDF) {
                        return dispatch(update_PDF_cut_sheet(type, id, data, message));
                    } else {
                        return dispatch(update_detail(type, id, data, message));
                    }
                }
            })
    }
}

export const add_social_website = (id, type, data) => {
    return (dispatch) => {
        callApi(`/api/addsocialwebsite/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Social media link added successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_type_detail({ type: type, id: id }));
            }
        });
    };
};

export const delete_listing_Info = (id, type, listingid) => {
    return (dispatch) => {
        callApi(`/api/deletelistingInfo/${listingid}/`, "DELETE",).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Social Media Link Deleted Successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_type_detail({ type: type, id: id }));
            }
        });
    };
};

export const dispatch_fetch_review = (data) => ({ type: FETCH_REVIEW_LISTING_ITEMS, payload: data })
export const fetch_review_listing = ({ id, type, detail_type }) => {
    let url = null;
    if (detail_type === "movies") url = `/api/reviews/?entry=&associated_to=${id}&searchTerm=${type}`;
    return (dispatch) => {
        callApi(url, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (detail_type == "movies") {
                    dispatch(get_reviews_listing(id))
                }
                return dispatch(dispatch_fetch_review(response));
            }
        });
    };
};


export const edit_item_details = (id, type, data, message) => {
    return (dispatch) => {
        callApi(`/api/${type}/${id}/`, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`${message}!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_type_detail({ type: type, id: id }));
            }
        });
    }
}
export const get_professional_pins_ = (data) => ({ type: GET_PROFESSIONAL_PINS, payload: data, });
export const get_professional_pins = (id) => {
    return (dispatch) => {
        callApi(`/api/professionalpins/?&get_userwise_pins=true&professionaluser_entry_id=${id}
        `, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_professional_pins_(response));
            }
        });
    };
};

export const add_media_to_item_gallery = (data, type, id) => {
    return (dispatch) => {
        callApi(`/api/improve/?type=&type=${data.type}`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Media uploaded successfully.`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_type_detail({ type: type, id: id }));
            }
        });
    };
};

export const add_hours_of_operations = (id, data, type) => {
    return (dispatch) => {
        callApi(`/api/professionalupdate/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (response && response.code === 201) {
                    toast(`${'Hours of operations added to profile'}`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc"
                    });
                }
                return dispatch(get_type_detail({ type: type, id: id }));
            }
        });
    };
};


export const get_uploaded_media_ = (data) => ({ type: GET_UPLOADED_MEDIA, payload: data, });
export const get_uploaded_media = (data) => {
    return (dispatch) => {
       return dispatch(get_uploaded_media_(data)); 
    };
};

export const get_uploaded_media_follow_ = (data) => ({ type: GET_UPLOADED_MEDIA_FOLLOW, payload: data, });
export const get_uploaded_media_follow = (data) => {
    return (dispatch) => {
       return dispatch(get_uploaded_media_follow_(data)); 
    };
};

export const setItemDetailLoading = (data) => ({type:"ITEM_DETAIL_LOADING", payload:data})

