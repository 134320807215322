const initialState = {
    fetch_photos: null,
    fetch_videos: null,
    fetch_taxonomy: null,
    upload_scroll: null,
    fetch_deleted_data: null,
    upload_media_data: null,
    upload_profile_data: null,
    people_you_know_data: null,
    fetch_taxonomy_listData: null
};

const myProfile = (state = initialState, action) => {
    switch (action.type) {
        
        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "FETCH_MY_PHOTOS":
            state.fetch_photos = action.payload;
            return { ...state };

        case "FETCH_MY_VIDEOS":
            state.fetch_videos = action.payload;
            return { ...state };

        case "FETCH_TAXONOMY_CATEGORY":
            state.fetch_taxonomy = action.payload;
            return { ...state };

        case "MEDIA_UPLOAD_SCROLL":
            state.upload_scroll = action.payload;
            return { ...state };

        case "FETCH_DELETE_DATA":
            state.fetch_deleted_data = action.payload;
            return { ...state };

        case "UPLOAD_MEDIA_DATA":
            state.upload_media_data = action.payload;
            return { ...state };

        case "UPLOAD_PROFILE_DATA":
            state.upload_profile_data = action.payload;
            return { ...state };

        case "CLEAR_MEDIA_DATA":
            state.upload_media_data = action.payload;
            return { ...state };

        case "PEOPLE_YOU_KNOW":
            state.people_you_know_data = action.payload;
            return { ...state };

        case 'FETCH_TAXONOMY_LISTING':
            state.fetch_taxonomy_listData = action.payload;
            return { ...state };

        default:
            return state;
    }
};

export default myProfile;
