import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Progress,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import moment from "moment";

import {
  upload_media,
  initFetchMovieSubCategories,
  search_moviesMembers,
  get_improve_listing,
  submit_improve_tech_media
} from "../../../actions/footer";

import {
  media_upload_scroll,
  fetch_media_data,
  clear_media_dispatch,
  upload_add_captions,
  fetch_taxonomy_list,
  fetch_taxonomy_category
} from "../../../actions/myProfiles";

import {
  add_movie
} from "../../../actions/movies";
import {
  get_album_types_list,
  get_album_type_data,
  delete_selected_gallery_media
} from "../../../actions/user";
import { get_type_detail } from '../../../actions/itemDetail';

import { callApi } from "../../../utils/apiCaller";

class ImproveMovieMediaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      loaded: true,
      drawer: true,
      improveMedia_list: [],
      name: '',
      category_id: '',
      subcategory_id: '',
      typeMethod: "",
      rateType: "",
      type: "media",
      listingType: "movies",
      about: null,
      mediaSet: [],
      media: [],
      uploadMediaModal: false,
      fileReviewStatus: false,
      selectedFilesPreview: [],
      latestUploadedData: {},
      showVideoMedia: true,
      fetchTaxonomy: [],
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      uploadCaptions: {},
      files: [],
      albumType: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      uploadMediaPostModal: false,
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
      errorName: ""
    };
  }
  redirectto = (movieId, slug) => {
    let path = "movies";
    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: movieId }
    })
  }
  componentWillReceiveProps(nextProps) {
    let { loaded } = this.state;
    if (nextProps.fetch_save_movie_response) {
      if (nextProps.fetch_save_movie_response && nextProps.fetch_save_movie_response.id && nextProps.fetch_save_movie_response.slug) {
        let slug = null;
        let movieId = "";
        if (nextProps.fetch_save_movie_response.id != "") {
          movieId = nextProps.fetch_save_movie_response.id;
        }
        if (nextProps.fetch_save_movie_response.slug != "") {
          slug = nextProps.fetch_save_movie_response.slug;
        }
        this.redirectto(movieId, slug);

      }
    }

    if (nextProps.ratingType && nextProps.ratingType != "") {
      this.setState({ rateType: nextProps.ratingType })
    }
    if (nextProps.getImproveMedia && nextProps.getImproveMedia.results && Array.isArray(nextProps.getImproveMedia.results) && nextProps.getImproveMedia.results.length > 0) {
      this.setState({ improveMedia_list: nextProps.getImproveMedia.results }, () => {
        if (nextProps.getImproveMedia.results && nextProps.getImproveMedia.results[0] && loaded == true) {
          this.getMovieDetail(nextProps.getImproveMedia.results[0])
        }

      });
    }
    if (nextProps?.movie_details) {
      this.setState({
        about: nextProps?.movie_details,
      });
    }

    let {
      latestUploadedData,
      fetchGalleryScroll,
      fetchGalleryCount,
    } = this.state;

    if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypesList: nextProps.album_types_list,
        },
        selectedUpMediaType: "upload",
        showGalleryType: "images",
      });
    }
    if (nextProps.fetch_taxonomy_listData) {
      let taxCatListing = nextProps.fetch_taxonomy_listData;
      if (
        taxCatListing &&
        Array.isArray(taxCatListing) &&
        taxCatListing.length > 0
      ) {
        if (taxCatListing[0].isTypes == "images") {
          this.setState({
            fetchTaxonomyListingImages: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        } else {
          this.setState({
            fetchTaxonomyListingVideos: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        }
      }
    }
    if (
      nextProps.album_type_data &&
      Object.keys(nextProps.album_type_data).length > 0
    ) {
      let item = nextProps.album_type_data;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypeData: nextProps.album_type_data,
        },
        fetchGalleryScroll,
        fetchGalleryCount,
      });
    }

    if (nextProps.fetch_taxonomy) {
      let taxCat = nextProps.fetch_taxonomy;
      if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
        taxCat = taxCat.filter((dt) => dt.label !== null);
      }
      this.setState({ fetchTaxonomy: taxCat[0]?.children });
    }
    if (nextProps.upload_scroll) {
      let item = nextProps.upload_scroll;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        fetchGalleryScroll,
        fetchGalleryCount,
        activeMyPhotoIndex: 0,
      });
    }
  }

  componentDidMount = () => {
    let { type, listingType, improveMedia_list } = this.state;
    let id = ""
    //this.props.get_patrol_crp(id,type,listingType);
  }

  getMovieDetail = async (data) => {
    this.setState({ loaded: false, about: "" }, () => {
      this.props.get_type_detail({ type: 'movies', id: data.id });
      this.props.fetch_taxonomy_category('movies');
    })
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let { rateType } = this.state;
    this.setState({
      [name]: value
    });
    if (name == "category_id") {
      let type = "category_id";
      this.setState({
        categoryError: "",
        category_id: e.target.value,
        improveMedia_list: [],
        about: "",
        loaded: true
      }, () => {
        let id = this.state.category_id;
        let typeCore = "listing";
        let listingType = "movies";
        this.props.get_improve_listing(id, typeCore, listingType);
      });
      this.props.initFetchMovieSubCategories(e.target.value, type);
    }
  }

  handleSkip = () => {
    let { improveMedia_list } = this.state;
    if (improveMedia_list && Array.isArray(improveMedia_list) && improveMedia_list.length > 0) {
      improveMedia_list.shift();
      this.setState({ improveMedia_list, about: "" }, () => {
        this.getMovieDetail(improveMedia_list[0])
      });

    }

  }

  handleMovieMediaSubmit = () => {
    let { improveMedia_list, uploadMedia } = this.state;

    if (improveMedia_list && Array.isArray(improveMedia_list) && improveMedia_list.length > 0) {
      let id = ""
      let type = "media"
      let params = {};
      params.type = "media";
      params.entries = improveMedia_list && Array.isArray(improveMedia_list) && improveMedia_list[0] ? improveMedia_list[0].id : "";
      params.userentries_id = improveMedia_list && Array.isArray(improveMedia_list) && improveMedia_list[0] ? improveMedia_list[0].id : "";
      params.reviews_userentries = improveMedia_list && Array.isArray(improveMedia_list) && improveMedia_list[0] ? improveMedia_list[0].id : "";
      params.taxonomy_id = "";
      params.categories = [];
      params.multiuploaderfile = uploadMedia?.selectedMediaIds;
      this.props.submit_improve_tech_media(id, type, params);
      this.setState({
        mediaSet: [],
        media: [],
        uploadMediaModal: false,
        fileReviewStatus: false,
        selectedFilesPreview: [],
        latestUploadedData: {},
        showVideoMedia: true,
        fetchTaxonomy: [],
        selectedUpMediaType: "upload",
        showGalleryType: "images",
        fetchGalleryScroll: {},
        fetchGalleryCount: 0,
        uploadCaptions: {},
        files: [],
        albumType: "",
        fetchTaxonomyListingImages: [],
        fetchTaxonomyListingVideos: [],
        uploadMediaPostModal: false,
        uploadMedia: {
          mediaType: "image",
          albumType: "",
          pageNo: 1,
          albumTypesList: [],
          albumTypeData: {},
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: {},
          uploadFiles: [],
          uploadedFiles: [],
          progress: 0,
        },
        errorName: ""


      }, () => {
        this.handleSkip();
      }
      )

    } else {
      this.setState({
        errorName: "Something Went Wrong!"

      });

    }
  }

  handleChangeTaxonomyData = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    data.category = value;
    let id = upload_data.id;
    this.setState({ taxonomycategoryId: value }, () =>
      this.props.upload_add_captions(id, data)
    );
  };

  handleOnClickRemoveSelectedMedia = (id) => () => {
    let removeMediaIds = [];
    removeMediaIds.push(id);
    this.props.delete_selected_gallery_media(removeMediaIds);
    let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
      (file) => file.id !== id
    );
    let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
      (file) => file.id !== id
    );
    let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
      (item) => item !== id
    );
    let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
      (file) => file.id !== id
    );
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: selectedMedia,
        selectedMediaIds: selectedMediaIds,
        uploadedFiles: uploadedFiles,
        uploadFiles: uploadFiles,
      },
    });
  };
  handleOnClickRemoveSelectedGalleryMedia = (media, index) => {
    // remove media from upload media state
    let { uploadMedia, editPost } = this.state;
    let id = media.id;
    let filterSelectedMediaIds = [];
    let filterSelectedMedia = [];

    if (id) {
      filterSelectedMediaIds = uploadMedia?.selectedMediaIds.filter(data => data !== id);
      filterSelectedMedia = uploadMedia?.selectedMedia.filter(data => data.id !== id);
    }
    uploadMedia['selectedMediaIds'] = filterSelectedMediaIds;
    uploadMedia['selectedMedia'] = filterSelectedMedia;

    if (editPost) {
      editPost["messagemedia_set"] = filterSelectedMediaIds;
    }

    this.setState({ uploadMedia, editPost }, () => { this.props.delete_selected_gallery_media([id]) });
  };
  handleOnClickSelectGalleryMedia = (media) => {
    if (media) {
      let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
      let newSelectedMediaIds;
      let newSelectedMedia;
      if (index !== -1) {
        newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
          (item) => item !== media.id
        );
        newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
          (item) => item.id !== media.id
        );
      } else {
        newSelectedMediaIds = [
          ...this.state.uploadMedia.selectedMediaIds,
          media.id,
        ];
        newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
      }
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: newSelectedMedia,
          selectedMediaIds: newSelectedMediaIds,
        },
      });
    }
  };

  handleOnFilePostUploadChange = (event) => {
    let uploadFiles = event.target.files;
    let showFiles = [];
    for (const key of Object.keys(uploadFiles)) {
      let itemType = uploadFiles[key].type.split("/");
      let extName = itemType[0];
      showFiles.push({ id: "", url: extName === "image" ? URL.createObjectURL(uploadFiles[key]) : require("../../../assets/images/blank.png") });
    }
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        uploadFiles: showFiles,
        progress: 0,
      },
    });
    let progressPart = 100 / showFiles.length;
    let progress = 0;
    for (const key of Object.keys(uploadFiles)) {
      let data = new FormData();
      data.append("file", uploadFiles[key]);
      callApi(
        `/upload/multiuploader/?album=&instance=mediacategory&image=undefined&doc=undefined`,
        "POST",
        data,
        true
      ).then((response) => {
        this.handleOnClickSelectGalleryMedia(response);
        if (showFiles.length === 1 || key === showFiles.length - 1) {
          progress = 100;
        } else {
          progress = progress + progressPart;
        }
        if (response && response.code !== 400 && response.code !== 500) {
          showFiles[key].id = response.id;
          showFiles[key].media_type = response.media_type;
          showFiles[key].thumbnail = response.thumbnail;
          if (response.media_type == "image") {
            this.props.fetch_taxonomy_list('movies', 'images');
          } else {
            this.props.fetch_taxonomy_list('movies', 'videos');
          }

          this.setState({
            ...this.state,
            uploadMedia: {
              ...this.state.uploadMedia,
              progress: progress,
              uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
              uploadedFiles: showFiles,
            },
          });
        }
      });
    }
  };

  clearMediaDispatch = () => {
    this.setState(
      {
        latestUploadedData: {},
        uploadCaptions: {},
        selectedFilesPrevietoggleBottomMenuw: [],
        files: [],
      },
      () => this.props.clear_media_dispatch()
    );
  };

  toggleVideoOption = () => {
    this.setState({ showVideoMedia: !this.state.showVideoMedia });
  };

  AddNewEmbedLink = (e) => {
    e.preventDefault();
    let { embeddedLink } = this.state;
    // this.props.embedNewLink({
    //   data: {
    //     video: `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
    //     exclude_list: [],
    //     body_preview: `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
    //     question_rating_category: [],
    //     messagemedia_set: [],
    //   },
    // });
    this.setState({ embeddedLink: "" });
  };

  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`;

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach((item) => {
        if (
          fetchGalleryScroll[item] &&
          fetchGalleryScroll[item].results !== null
        ) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map((item) => {
          let itemName = (item?.thumbnail).split(".");
          let itemFile = (item?.file).split(".");
          let extName = itemName[itemName.length - 1];
          let extFile = itemFile[itemFile.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;

          return (
            <Col xs="auto" className="mb-3">
              <FormGroup
                className="checkable-media"
                check
                style={{ width: "120px" }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={() => this.handleOnClickSelectGalleryMedia(item)}
                  />
                  <div className="gallery-media">
                    <img
                      className="img-fluid"
                      src={isVideo ? srcFile : item?.file}
                      alt="logo Pics"
                    />
                  </div>
                </Label>
                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
                  {item.caption ? item.caption : "No Caption"}
                </div>
                <div className="font-weight-normal fs-14 d-block">
                  {item.category ? item.category : "No Category"}
                </div>
              </FormGroup>
            </Col>
          );
        });
      }
    } else {
      return getDataItems;
    }
  };

  handleMediaUpload = () => {
    const queryCaptionParams = {
      category: "Album",
      depth: true,
      delsel: "",
      ancestor: "",
      sub_cat: "",
      parent: "",
    };
    let { mediaSetReview } = this.state;
    this.props.getAllCaptionCategories(queryCaptionParams);

    this.setState(
      {
        uploadMediaModal: !this.state.uploadMediaModal,
        fileReviewStatus: true,
        mediaSet: mediaSetReview,
      },
      () => {
        this.clearMediaDispatch();
        this.props.fetch_media_data("image", "", 1, true, true);
        this.props.fetch_media_data("video", "", 1, true, true);
      }
    );
  };

  checkIsAdded = (item) => {
    let { mediaSet } = this.state;
    let id = item.id;
    let itemFiltered = mediaSet.find((item) => item.id === id);
    if (itemFiltered) {
      return true;
    } else {
      return false;
    }
  };

  setMediaSetForItems = (e, item) => {
    // e.preventDefault();
    let { mediaSet, offerCheck } = this.state;
    if (item && item.id) {
      let id = item.id;
      let itemFiltered = mediaSet.find((item) => item.id === id);

      if (itemFiltered) {
        const index = mediaSet.indexOf(itemFiltered);
        if (index > -1) {
          mediaSet.splice(index, 1);
        }
      } else {
        mediaSet.push(item);
      }

      this.setState({ mediaSet, mediaSetReview: mediaSet, offerCheck });
    }
  };

  removeFromMedia = (e, item) => {
    e.preventDefault();
    let { mediaSet } = this.state;
    let id = item.id;
    let itemFiltered = mediaSet.find((item) => item.id === id);
    if (itemFiltered) {
      const index = mediaSet.indexOf(itemFiltered);
      if (index > -1) {
        mediaSet.splice(index, 1);
      }
      this.setState({ mediaSet, mediaSetReview: mediaSet });
    }
  };
  handleCaptionByIndex = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    uploadCaptions[index] = value;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    let id = upload_data.id;
    this.setState({ uploadCaptions }, () =>
      this.props.upload_add_captions(id, data)

    );

  };
  handleOnClickGalleryType = (type) => {
    let mediaType = "";
    if (type === "images") {
      mediaType = "image";
    } else if (type === "videos") {
      mediaType = "video";
    }
    this.props.get_album_type_data(mediaType, "", 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: type,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: mediaType,
        albumType: "",
        pageNo: 1,
      },
    });
  };
  handleOnClickAlbumTypeChange = (e) => {
    this.props.get_album_type_data("image", e.target.value, 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: "images",
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: e.target.value,
        pageNo: 1,
      },
    });
  };
  handleOnClickSelectedUploadMediaType = (type) => {
    if (type === "gallery") {
      this.props.get_album_type_data("image", "", 1);
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        showGalleryType: "images",
        uploadMedia: {
          ...this.state.uploadMedia,
          mediaType: "image",
          albumType: "",
          pageNo: 1,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "upload") {
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "embed") {
      let embedLinks = {
        0: "",
        1: "",
      };
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: embedLinks,
        },
      });
    }
  };
  embedLinkOnChange = (id) => (e) => {
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        embedLinks: {
          ...this.state.uploadMedia.embedLinks,
          [id]: e.target.value,
        },
      },
    });
  };
  mapEmbedLinks = () => {
    let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
    return embedKeys.map((emKey) => {
      return (
        <div className="d-flex mx-n2 mb-2" key={emKey}>
          <div className="flex-fill px-2">
            <Input
              type="url"
              bsSize="sm"
              className="bg-white"
              value={this.state.uploadMedia.embedLinks[emKey]}
              onChange={this.embedLinkOnChange(emKey)}
              placeholder="Embeded link"
            />
          </div>
          <div className="px-2">
            <Button
              title="Remove"
              color="danger"
              size="sm"
              hidden={emKey <= 1 ? true : false}
              onClick={() => {
                this.deleteEmbedLinkRow(emKey);
              }}
            >
              <FontAwesomeIcon icon="minus" />
            </Button>
          </div>
        </div>
      );
    });
  };
  deleteEmbedLinkRow = (emKey) => {
    let embedLinks = this.state.uploadMedia.embedLinks;
    if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
      delete embedLinks[emKey];
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: embedLinks,
        },
      });
    }
  };
  addEmbedlinkRow = () => {
    let newEmbedLinks = {};
    for (let i = 2; i < 5; i += 1) {
      if (
        Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
        -1
      ) {
        newEmbedLinks[i] = "";
        break;
      }
    }
    if (Object.keys(newEmbedLinks).length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {
            ...this.state.uploadMedia.embedLinks,
            ...newEmbedLinks,
          },
        },
      });
    }
  };
  confirmDeleteModalToggle = () => {
    if (this.state.deletePostId) {
      this.setState({ deletePostId: 0, deletePostType: "" });
    }
    this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
  };
  handleOnClickUploadMedia = () => {
    this.props.get_album_types_list();
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        progress: 0,
        uploadFiles: [],
        uploadedFiles: [],
      },
      uploadMediaPostModal: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
    });
  };
  truncate = (filenameString) => {
    let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
    let extension = filenameString.substr(
      filenameString.lastIndexOf("."),
      filenameString.length - 1
    );
    let partial = filename.substring(filename.length - 3, filename.length);
    filename = filename.substring(0, 15);
    return filename + "..." + partial + extension;
  };
  limitedPostsModalToggle = () => {
    this.setState({ limitedPostsModal: !this.state.limitedPostsModal });
  };
  uploadMediaModalToggle = () => {
    this.setState({
      ...this.state,
      uploadMediaPostModal: !this.state.uploadMediaPostModal,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: [],
        selectedMediaIds: [],
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        uploadedFiles: [],
        uploadFiles: [],
      },
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      taxonomycategoryId: null,
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
    });
  };
  handleOpenModal(data, url) {
    this.setState({
      viewMyPhotosModal: !this.state.viewMyPhotosModa,
      imageAttacment: data,
    });
  }

  handleScrollGalleryVideo = (e) => {
    let { refGalleryVideoScroll } = this;
    // let count = 0;
    let clientHeight = refGalleryVideoScroll.current.clientHeight;
    let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "video",
        album: "",
        force_update: false,
      });
    }
  };

  fetchGalleryOnScroll = ({ type, album, force_update }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state;
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;
      if (nextCount <= roundValue) {
        let page = nextCount;
        this.props.get_album_type_data(type, "", page);
      }
    }
  };

  handleScrollGalleryImage = (e) => {
    let { refGalleryImageScroll } = this;
    let { fetchGalleryAlbum } = this.state;

    let clientHeight = refGalleryImageScroll.current.clientHeight;
    let scrollHeight = refGalleryImageScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "image",
        album: fetchGalleryAlbum,
        force_update: false,
      });
    }
  };

  handleChangeTaxonomy = (e) => {
    let { value } = e.target;
    let albumType = value !== '' ? value : '';

  }

  render() {
    const {
      openModal,
      toggleImproveMovieMediaModal,
      movies,
    } = this.props;
    const { category_id, improveMedia_list, name, movieDetail, about, mediaSet, latestUploadedData, selectedFilesPreview,
      showVideoMedia, fetchTaxonomy, embeddedLink, fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state;
    let avg_rating = about && about.total_rating && about.total_rating.avg_rating[0] ? about.total_rating.avg_rating[0] : 'norating.png';
    let totalLength = selectedFilesPreview.length;
    let objectKeys = [];
    let objectLength = 0;
    let calculatedPercentage = 0;
    if (Object.keys(latestUploadedData).length > 0) {
      objectKeys = Object.keys(latestUploadedData);
      objectLength = objectKeys.length;
      calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
    }
    return (
      <>
        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={openModal}
          toggle={toggleImproveMovieMediaModal}
        >
          <form
            className="d-flex flex-column h-100"
          >
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button color="primary" onClick={toggleImproveMovieMediaModal}>
                    <FontAwesomeIcon icon="angle-left" />
                        back to listing
                      </Button>
                </div>
                <div>
                  <div className="bg-darker p-3 text-tertiary">
                    <div className="mb-2 text-primary font-weight-bold">
                      You’re Improving Core Listing Info Media
                        </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input
                              type="select"
                              className="transparent"
                              size="sm"
                            >
                              <option>{movies?.category}</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </li>
                      <li className="px-2">
                        <div className="d-flex">
                          <div className="mr-1">
                            <span className="text-danger">*</span>
                          </div>
                          <FormGroup>
                            <Input
                              id="category_id"
                              name="category_id"
                              onChange={this.handleChange}
                              type="select"
                              className="transparent"
                              size="sm"
                              selected={category_id}
                            >
                              <option value="">Select Category</option>
                              {movies?.children?.map((movie) => {
                                return (
                                  <option value={movie.id} key={movie.id}>
                                    {movie.category}
                                  </option>
                                );
                              })}
                            </Input>
                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.categoryError}</span>
                          </FormGroup>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ModalBody className="bg-white text-dark">
              <div className="wk-crpmodal">
                <Row>
                  <Col md="6">
                    <div className=" font-weight-bold mb-2 text-primary mb-3">Step 1. Look it Up</div>
                    <p className="text-dark font-weight-bold">The Brief.</p>
                    <div className="wk-core-petrol-listingleft d-flex flex-wrap">
                      <div className="wk-core-petrol-listingimage">
                        {about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].url ?
                          <img
                            src={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].url}`}
                            className="img-fluid"
                            onError={(error) =>
                              (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                            }
                            alt={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about.listing_profileimage[0].caption ? about.listing_profileimage[0].caption : 'no-image'}`}
                          />
                          :
                          <img alt="" src="https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png" className="img-fluid" />

                        }

                      </div>
                      <div className="wk-core-petrol-listingcontent">
                        <span style={{ color: 'red', fontSize: "13px" }}>{this.state.errorName}</span>
                        <h4 className="fs-18">{about?.name}
							 &nbsp;<span className="fs-14">{about && Array.isArray(about.taxonomy_list) && about.taxonomy_list.length > 0 ?
                            about.taxonomy_list.map((taxonomy) => {
                              return <>{taxonomy} </>
                            })
                            : ""
                          }</span></h4>
                        {/*<img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png" alt="star"/>
								 <p className="mt-2 mb-0">0 reviews</p>*/}
                        <p className="fs-14">
                          <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                            className="img-fluid"
                            onError={(error) =>
                              (error.target.src = `../../../assets/images/norating.png`)
                            }
                            alt={`${about && about.total_rating && about.total_rating.avg_rating[1] ? about.total_rating.avg_rating[1] : 'no-rating'}`}

                          />{" "}{(about && about.total_rating && about.total_rating.total_review) ? about.total_rating.total_review : 0} reviews</p>
                        <p className="mt-2 mb-0">Category: <span className="font-weight-bold text-dark">
                          {about && Array.isArray(about.taxonomy_list) && about.taxonomy_list.length > 0 ?
                            about.taxonomy_list.map((taxonomy) => {
                              return <>{taxonomy} </>
                            })
                            : ""
                          }</span></p>
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="font-weight-bold mb-2 text-primary">Step 2.</div>
                    <Row>

                      {about && about.media_dict && Array.isArray(about.media_dict) && about.media_dict.length > 1 ?
                        about.media_dict.map((itemImage, index_image) => {
                          return (<Col md="4" key={index_image}>
                            <img src={itemImage?.url} className="12345 wk-improve-media-uploadimage mb-2 img-fluid"
                              onError={(error) => (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/circle.png`)}
                              alt={itemImage.caption ? itemImage.caption : 'no-image'}
                            />

                            <FormGroup>
                              <label className="fs-14">Existing category: {itemImage?.category}</label>
                              <Input onChange={this.handleChangeTaxonomy} bsSize="sm" type="select" name="album_type_select" id="category_id2">
                                <option value="">Select Category</option>
                                {fetchTaxonomy && Array.isArray(fetchTaxonomy) && fetchTaxonomy.length > 0 ?
                                  fetchTaxonomy.map((item, index_tax) => (
                                    <option key={index_tax} value={item.category}>{item.label}</option>
                                  ))
                                  : null}
                              </Input>

                            </FormGroup>
                          </Col>)
                        })
                        : null
                      }




                    </Row>
                    <div className="wk-upload-media-improve pb-2">
                      {/* Uploaded media shown here */}
                      {
                        this.state.uploadMedia.selectedMedia.length ?
                          <div className="border mb-3">
                            <Row xs={2} sm={3} md={4} lg={5} noGutters>
                              {this.state.uploadMedia.selectedMedia.map((item, indexMedia) => (
                                <Col>
                                  <div className="d-flex pr-3 m-2">
                                    <div>
                                      {(item.type === "image" ||
                                        item.media_type === "image" ||
                                        item.type === "video" ||
                                        item.media_type === "video" || item.type === "audio" ||
                                        item.media_type === "audio") && (
                                          <div className="gallery-media">
                                            { item.media_type === 'image' ?
                                              <img
                                                className="img-fluid img-thumbnail"
                                                src={item.url ? item.url : item.file}
                                                alt={
                                                  item.filename
                                                    ? item.filename.length < 20
                                                      ? item.filename
                                                      : this.truncate(item.filename)
                                                    : item.name
                                                      ? item.name.length < 20
                                                        ? item.name
                                                        : this.truncate(item.name)
                                                      : ""
                                                } /> :
                                              <img
                                                className="img-fluid img-thumbnail"
                                                src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
                                                alt={
                                                  item.filename
                                                    ? item.filename.length < 20
                                                      ? item.filename
                                                      : this.truncate(item.filename)
                                                    : item.name
                                                      ? item.name.length < 20
                                                        ? item.name
                                                        : this.truncate(item.name)
                                                      : ""
                                                }
                                              />}
                                          </div>
                                        )}
                                    </div>
                                    <div className="mx-n3 mt-2">
                                      <Button
                                        color="delete-media"
                                        size="sm"
                                        title="Remove Media"
                                        onClick={() =>
                                          this.handleOnClickRemoveSelectedGalleryMedia(
                                            item, indexMedia
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                      </Button>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </div>
                          :
                          ''
                      }
                      <Button className="ml-0" color="primary" size="sm" onClick={() =>
                        this.handleOnClickUploadMedia()
                      }>
                        Upload Media
                      </Button>
                    </div>
                    <div className="text-right mt-3">
                      <Button className="ml-0" color="primary" size="sm"
                        onClick={() => this.handleMovieMediaSubmit()}>
                        Submit
                      </Button>
                      <a href="javascript:void(0)" className="ml-4" onClick={() => {
                        this.handleSkip();
                      }}>Skip ></a>
                    </div>
                  </Col>
                </Row>
              </div>

            </ModalBody>
          </form>
        </Modal>
        {/* Media Modal */}

        <Modal
          isOpen={this.state.uploadMediaPostModal}
          toggle={this.uploadMediaModalToggle}
        >
          <ModalHeader toggle={this.uploadMediaModalToggle}>
            UPLOAD MEDIA
          </ModalHeader>
          <ModalBody className="p-3">
            <Row>
              <Col xs={"auto"}>
                <div className="bg-light h-100 p-3">
                  <Nav
                    tabs
                    className="flex-column active-transparent border-0 m-0"
                  >
                    <NavItem className="text-left">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "upload"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("upload");
                        }}
                      >
                        Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "gallery"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("gallery");
                        }}
                      >
                        Gallery
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "embed"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("embed");
                        }}
                      >
                        Embed
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col>
                <TabContent activeTab={this.state.selectedUpMediaType}>
                  <TabPane tabId="upload">
                    <div
                      className="mb-3 type-file-block bg-light"
                      hidden={
                        this.state.uploadMedia.uploadFiles &&
                          this.state.uploadMedia.uploadFiles.length > 0
                          ? true
                          : false
                      }
                    >
                      <Input
                        type="file"
                        name="upload_media_file"
                        id="uploadFilesPostFromSystem"
                        accept="image/*, video/*, audio/*"
                        onChange={this.handleOnFilePostUploadChange}
                        multiple
                      />
                      <Label for="uploadFilesPostFromSystem">
                        <div className="type-bg"></div>
                      </Label>
                    </div>
                    {this.state.uploadMedia.uploadFiles.length > 0 && (
                      <Row className="mb-3" form>
                        <Col xs={12}>
                          <div
                            style={{
                              maxWidth: "120px",
                              margin: "0 1rem 1rem auto",
                            }}
                          >
                            <div className="text-center mb-1 small">
                              {this.state.uploadMedia.progress === 100 ? (
                                <div className="text-success">
                                  <FontAwesomeIcon
                                    icon="check-circle"
                                    className="mr-1"
                                  />{" "}
                                  Uploaded
                                </div>
                              ) : (
                                  <div>
                                    Uploading{" "}
                                    <span className="text-success font-weight-bold ff-base">
                                      {this.state.uploadMedia.progress.toFixed(0)}
                                    %
                                  </span>
                                  </div>
                                )}
                            </div>
                            <Progress
                              value={this.state.uploadMedia.progress}
                              style={{ height: "8px" }}
                            ></Progress>
                          </div>
                        </Col>
                        {this.state.uploadMedia.uploadFiles.map(
                          (file, index) => {
                            return (
                              <Col xs="auto">
                                <div className="bg-light p-2">
                                  <div className="d-flex pr-3">
                                    <div>
                                      <div
                                        className="selectable-media 123"
                                        style={{ cursor: "default" }}
                                      >
                                        <div className="gallery-media">
                                          {file.media_type === 'image' ?
                                            <img src={file.url} alt="" /> :
                                            <img src={file.thumbnail && file.thumbnail !== null ? file.thumbnail : file.url} alt="" />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mx-n3 mt-2">
                                      <Button
                                        color="delete-media _alt"
                                        size="sm"
                                        title="Remove Media"
                                        hidden={file.id === "" ? true : false}
                                        onClick={this.handleOnClickRemoveSelectedMedia(
                                          file.id
                                        )}
                                      >
                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <Input
                                      bsSize="sm"
                                      type="text"
                                      className="mw-100 caption-input"
                                      name="caption"
                                      placeholder="Caption"
                                      onBlur={(e) =>
                                        this.handleCaptionByIndex(e, index)
                                      }
                                      style={{ maxWidth: '160px' }}
                                    />
                                    <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_type_select" id="selectAlbumTypeData" className="mw-100 mt-1">
                                      <option value="">Choose Category</option>
                                      {file.media_type === "image" && fetchTaxonomyListingImages && Array.isArray(fetchTaxonomyListingImages) && fetchTaxonomyListingImages.length > 0 ?
                                        fetchTaxonomyListingImages.map(item => (
                                          <option value={item.id}>{item.label}</option>
                                        ))
                                        : null}
                                      {file.media_type === "video" && fetchTaxonomyListingVideos && Array.isArray(fetchTaxonomyListingVideos) && fetchTaxonomyListingVideos.length > 0 ?
                                        fetchTaxonomyListingVideos.map(item => (
                                          <option value={item.id}>{item.label}</option>
                                        ))
                                        : null}
                                    </Input>
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        )}
                        <Col xs="auto">
                          <div className="d-flex pt-3">
                            <div className="selectable-media" hidden>
                              <Label
                                for="uploadFilesFromSystemMini"
                                className="gallery-media"
                                style={{
                                  borderStyle: "dashed",
                                  cursor: "pointer",
                                }}
                              >
                                <div className="d-flex h-100 align-items-center justify-content-center">
                                  <span className="fs-14">Upload More</span>
                                </div>
                              </Label>
                              <Input
                                type="file"
                                name="upload_media_file"
                                id="uploadFilesFromSystemMini"
                                accept="image/*, video/*"
                                onChange={this.handleOnFilePostUploadChange}
                                multiple
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              uploadMediaPostModal: false,
                            });
                          }}
                        >
                          {"OK"}
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="gallery">
                    <div className="mb-2">
                      <Nav tabs className="d-inline-flex mb-0">
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "images"}
                            onClick={() => {
                              this.handleOnClickGalleryType("images");
                            }}
                          >
                            Image Gallery
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "videos"}
                            onClick={() => {
                              this.handleOnClickGalleryType("videos");
                            }}
                          >
                            Video Gallery
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.showGalleryType}>
                      <TabPane tabId="images">
                        <div>
                          <FormGroup className="form-row justify-content-end">
                            <Col xs="auto">
                              <Label className="small" for="selectAlbumType">
                                Album Type:
                              </Label>
                            </Col>
                            <Col xs="auto">
                              <Input
                                bsSize="sm"
                                type="select"
                                name="album_type_select"
                                id="selectAlbumType"
                                defaultValue={this.state.uploadMedia.albumType}
                                onChange={this.handleOnClickAlbumTypeChange}
                              >
                                <option value="">All</option>
                                {this.state.uploadMedia.albumTypesList.length >
                                  0 &&
                                  this.state.uploadMedia.albumTypesList.map(
                                    (type) => {

                                      if (type.label) {
                                        return (
                                          <option value={type.category}>
                                            {type.category}
                                          </option>

                                        )
                                      }
                                    }
                                  )}
                              </Input>
                            </Col>
                          </FormGroup>
                        </div>
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryImageScroll}
                          onScroll={this.handleScrollGalleryImage}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>{this.fetchScrollPhotoMedia()}</Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No images</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="videos">
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryVideoScroll}
                          onScroll={this.handleScrollGalleryVideo}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>{this.fetchScrollPhotoMedia(true)}</Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No videos</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="embed">
                    <div className="text-muted mb-2">
                      Submit the link for the image or video you want to embed{" "}
                    </div>
                    <div className="mb-2">
                      {/* Repeat this while adding */}
                      {this.mapEmbedLinks()}
                    </div>
                    <div className="d-flex mx-n2 mb-2">
                      <div className="px-2 ml-auto">
                        <Button
                          title="Add"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            this.addEmbedlinkRow();
                          }}
                        >
                          <FontAwesomeIcon icon="plus" />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.insertEmbedLinks();
                          }}
                        >
                          Insert
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>


    );



  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  fetch_save_movie_response: state.movies.fetch_save_movie_response,
  getImproveMedia: state.footer.getImproveMedia,
  movie_details: state.item_detail.item_details,
  upload_scroll: state.myProfile.upload_scroll,
  upload_media_data: state.myProfile.upload_media_data,
  album_types_list: state.user.album_types_list,
  album_type_data: state.user.album_type_data,
  fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
  fetch_taxonomy: state.myProfile.fetch_taxonomy,
});

const mapProps = (dispatch) => ({
  media_upload_scroll: ({ type, album, page, force_update }) =>
    dispatch(media_upload_scroll({ type, album, page, force_update })),
  fetch_media_data: (type, album, page, user_profile) =>
    dispatch(fetch_media_data(type, album, page, user_profile)),
  add_movie: (data) => dispatch(add_movie(data)),
  clear_media_dispatch: () => dispatch(clear_media_dispatch()),
  upload_media_files: (type, data, index) =>
    dispatch(upload_media(type, data, index)),
  upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
  get_album_types_list: () => dispatch(get_album_types_list()),
  get_album_type_data: (type, album, page) =>
    dispatch(get_album_type_data(type, album, page)),
  delete_selected_gallery_media: (dataID) =>
    dispatch(delete_selected_gallery_media(dataID)),
  fetch_taxonomy_list: (category, type) =>
    dispatch(fetch_taxonomy_list(category, type)),
  initFetchMovieSubCategories: (categoryId, type) => dispatch(initFetchMovieSubCategories(categoryId, type)),
  search_moviesMembers: (value, type) => dispatch(search_moviesMembers(value, type)),
  get_improve_listing: (categoryId, type, listType) => dispatch(get_improve_listing(categoryId, type, listType)),
  get_type_detail: ({ type, id }) => dispatch(get_type_detail({ type, id })),
  submit_improve_tech_media: (id, type, data) => dispatch(submit_improve_tech_media(id, type, data)),
  fetch_taxonomy_category: (type) => dispatch(fetch_taxonomy_category(type)),
});
export default withRouter(connect(mapState, mapProps)(ImproveMovieMediaModal));