import React, { Component } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import SearchBar from "../searchBar";


export default class FilterLinks extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dropdownSortBy: false,
        }
    }

    toggleCategory(item) {
        this.setState(prevState => ({
            [item]: !prevState[item]
        }));
    }

    onMouseEnterCategory(item) {
        this.setState({ [item]: true });
    }

    onMouseLeaveCategory(item) {
        this.setState({ [item]: false });
    }

    render() {
        let { filterByItems, filterBySearch, searchResultsEnabled, renderDropdownItem, currentSortByOption } = this.props;
        return (
            <div className="d-flex mx-n2 mb-3">
                <div className="px-2">
                    <span
                        onClick={() => { filterByItems('new') }}
                        className="text-tertiary font-weight-bold text-decoration-none cursor-pointer">
                        {'All'}
                    </span>
                </div>
                <div className="px-2">
                    <UncontrolledDropdown onMouseOver={() => this.onMouseEnterCategory('dropdownSortBy')}
                        onMouseLeave={() => this.onMouseLeaveCategory('dropdownSortBy')}
                        isOpen={this.state.dropdownSortBy}
                        toggle={() => this.toggleCategory('dropdownSortBy')}>
                        <DropdownToggle
                            className="text-tertiary bg-transparent font-weight-bold py-0"
                            color="transparent"
                            size="sm"
                            caret>
                            <span className="pr-2">
                                {!!currentSortByOption ? currentSortByOption : 'Sort By'}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu className="type-tertiary">
                            {renderDropdownItem({ title: 'Most Relevant', item: 'relevant' })}
                            {renderDropdownItem({ title: 'Featured Reviews', item: 'featured' })}
                            {renderDropdownItem({ title: 'Top Contributions', item: 'top' })}
                            {renderDropdownItem({ title: 'Photo Reviews', item: 'image' })}
                            {renderDropdownItem({ title: 'Video Reviews', item: 'video' })}
                            {renderDropdownItem({ title: 'Newest', item: 'newest' })}
                            {renderDropdownItem({ title: 'Oldest', item: 'oldest' })}
                            {renderDropdownItem({ title: 'Highest Rating', item: 'highest' })}
                            {renderDropdownItem({ title: 'Lowest Rating', item: 'lowest' })}
                            {renderDropdownItem({ title: 'Most Helpful', item: 'helpful' })}
                            {renderDropdownItem({ title: 'Longest', item: 'longest' })}
                            {renderDropdownItem({ title: 'Shortest', item: 'shortest' })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                {!this.props.listing_type_professional && (
                    <div className="px-2">
                        <span
                            onClick={() => { filterByItems('notrecommended') }}
                            className="text-tertiary font-weight-bold text-decoration-none cursor-pointer">
                            {'Not Recommended'}
                        </span>
                    </div>
                )}

                {/* TODO: THIS IS COMMON FOR ALL CATEGORIES EXCEPT BUSINESS */}
                <div className="ml-auto">
                    <SearchBar
                        handleSearchSubmit={filterBySearch}
                        searchResultsEnabled={searchResultsEnabled}                       
                    />
                </div>
            </div>
        )
    }
}
