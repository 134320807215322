import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Moment from "react-moment";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { withRouter } from 'react-router';
import {
  Button, Col, Container,
  CustomInput, DropdownItem, DropdownMenu, DropdownToggle, FormGroup,
  Input, Label, Modal, ModalBody,
  ModalFooter, ModalHeader, Nav, NavItem, NavLink,
  Progress, Row, TabContent,
  Table, TabPane,
  UncontrolledDropdown
} from 'reactstrap';
// import SimilarMovies from "./../oragnisms/movieDetails/similarMovies";
import { get_id_from_slug, get_type_detail, clear_type_details, get_uploaded_media, setItemDetailLoading, get_uploaded_media_follow } from '../../actions/itemDetail';
import {
  add_dispute_reply, add_dispute_review, add_movie_review_vote,
  delete_dispute,
  delete_dispute_reply,
  delete_movies_review, get_dispute_discussion, get_reviews_listing, reset_reviews_listings,
  get_theater_data, get_advisory_listing
} from '../../actions/movies';
import { callApi } from "../../utils/apiCaller";
import { responsive } from '../../utils/constants';
import CollapseBasic from '../atoms/collapse';
import ConfirmModal from '../atoms/confirmModal';
import DeleteBtn from "../atoms/deleteBtn";
import EditBtn from "../atoms/editBtn";
import UploadMedia from '../atoms/fileUploadMedia';
import AppHeader from '../oragnisms/dashboard/appHeader';
import About from '../oragnisms/movieDetails/about';
import CreateReview from '../atoms/createReview';
import DetailReviews from '../oragnisms/movieDetails/detailReviews';
import Hometheater from '../oragnisms/movieDetails/hometheater';
import LeafletMap from '../oragnisms/movieDetails/LeafletMap';
import SocialPinFlag from '../oragnisms/movieDetails/socialPinFlag';
import SocialButtons from '../molecules/shared/SocialButtons/socialButtons';
import TechnicalDetails from '../oragnisms/movieDetails/TechnicalDetails';
import TheaterLocation from '../oragnisms/movieDetails/theaterLocation';
import SimilarDetail from './../atoms/similarDetail';
import CoolFacts from '../oragnisms/movieDetails/coolFacts';
import CastAndCrew from '../oragnisms/movieDetails/castAndCrew';
import TipsAndWarning from '../oragnisms/movieDetails/tipsAndWarning';
import Advisory from '../oragnisms/movieDetails/advisory';
import PlotSummary from '../oragnisms/movieDetails/plotSummary';
import DiscussThisPage from '../oragnisms/movieDetails/discussThisPage';
import { delete_movie_advisory } from './../../actions/movies';
import { toast } from "react-toastify";
import { my_profile } from "../../actions/user";
import PublicPageHeader from '../oragnisms/homepage/publicPageHeader';
import Gallery from '../molecules/shared/Gallery/gallery';
import { categoryType, getReviewSuffix, highlightSearchTerm } from '../../utils/utilities';
import DetailedStarRating from '../molecules/shared/DetailedStarRatings/detailedStarRating';
import { setEditExistingMedia } from '../../actions/shared';
import { finalizedMediaInReviewEmbedLinkSetForFollowReview, finalizedMediaInReviewEmbedLinkSetForNewReview, finalizedMediaInReviewMediaSetForNewReview, uploadMediaInReviewEmbedLinkSetForNewReview, uploadMediaInReviewMediaSetForEditReview, uploadMediaInReviewMediaSetForFollowReview, uploadMediaInReviewMediaSetForNewReview } from '../../actions/reviewMedia';

class MovieDetails extends Component {
  constructor(props) {
    super(props)

    this.isWillMountCallAble = false;
    this.firstTimeSimillarCallable = false;
    this.state = {
      updateCoolFactsOnDelete: false,
      createNewDisModalToggle: false,
      shareByEmailModalToggle: false,
      viewEditReviewStep: 'writeReview',
      viewMovieAs: 'about',
      movieId: null, //35481458
      reviews: [],
      mediaSet: [],
      mediaSetFollowUp: [],
      movieDetail: null,
      imgUrl: null,
      Duration: null,
      Rating: null,
      avgRating: null,
      theaterDetail: "",
      selectedUpMediaType: 'upload',
      showGalleryType: 'images',
      albumType: '',
      latestUploadedData: {},
      uploadCaptions: {},
      dataWantsToDelete: [],
      review_group_animation: {},
      fetchReviewGroup: [],
      confirmDeleteModal: false,
      limitedPostsModal: false,
      uploadMediaPostModal: false,
      viewReactedType: 'all',
      viewPeopleWhoReactedModalToggle: false,
      sharePostModal: false,
      dropdownOpen: false,
      hoverwoohoo: false,
      hovernailed: false,
      hoverlol: false,
      hoverinsightful: false,
      hoverbummer: false,
      fetchGalleryAlbum: "",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      hoverName: "",
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
      reactedReviewId: 0,
      overReviewId: 0,
      editreviewData: {},
      reviewValue: "",
      totalCharacter: 0,
      imageAttacment: "",
      review_group_count: {},
      reviewAll: 0,
      deletereviewId: 0,
      modalData: null,
      reviewId: 0,
      pageCount: 0,
      overallRatingVote: null,
      disputeReview: {
        review_id: "",
        reason: "",
        comment: "",
        dispute_file: [],
      },
      all_disputes_list: [],
      disputed_tab: false,
      user_names_list: [],
      get_dispute_data: [],
      showExplanation: false,
      disputeReviewId: "",
      showReply: false,
      dispute_files: [],
      dispute_imgepreview: [],
      dispute_reply_text: "",
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      movieName: null,
      taxonomyList: [],
      isEnableFileUpload: false,
      similarMovieList: null,
      keyMAP: Math.random(),
      uploadMediaUrl: "",
      uploadedMediaSet: {
        embedLinks: [],
        uploadedFiles: [],
      },
      uploadedMediaSetFollowUp: {
        embedLinks: [],
        uploadedFiles: [],
    },
      isEnableConfirmModal: false,
      confirmModalText: '',
      confirmModalKey: '',
      confirmModalItemId: null,
      detailList: [],
      enableStickyHeader: false,
      typeupload: "Movies",
      discusstype: "",
      forceShowLoginModal: false,
      disableCreateReview:true,
      publishReview:false
    };
    this.handleImageChange = this.handleImageChange.bind(this);
  }


  resetStates = () => {
    this.setState({
      updateCoolFactsOnDelete: false,
      createNewDisModalToggle: false,
      shareByEmailModalToggle: false,
      viewEditReviewStep: 'writeReview',
      viewMovieAs: 'about',
      movieId: null, //35481458
      reviews: [],
      mediaSet: [],
      movieDetail: null,
      imgUrl: null,
      Duration: null,
      Rating: null,
      avgRating: null,
      theaterDetail: "",
      selectedUpMediaType: 'upload',
      showGalleryType: 'images',
      albumType: '',
      latestUploadedData: {},
      uploadCaptions: {},
      dataWantsToDelete: [],
      review_group_animation: {},
      fetchReviewGroup: [],
      confirmDeleteModal: false,
      limitedPostsModal: false,
      uploadMediaPostModal: false,
      viewReactedType: 'all',
      viewPeopleWhoReactedModalToggle: false,
      sharePostModal: false,
      dropdownOpen: false,
      hoverwoohoo: false,
      hovernailed: false,
      hoverlol: false,
      hoverinsightful: false,
      hoverbummer: false,
      fetchGalleryAlbum: "",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      hoverName: "",
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: [],
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },      
      reactedReviewId: 0,
      overReviewId: 0,
      editreviewData: {},
      reviewValue: "",
      totalCharacter: 0,
      imageAttacment: "",
      review_group_count: {},
      reviewAll: 0,
      deletereviewId: 0,
      modalData: null,
      reviewId: 0,
      pageCount: 0,
      overallRatingVote: null,
      disputeReview: {
        review_id: "",
        reason: "",
        comment: "",
        dispute_file: [],
      },
      all_disputes_list: [],
      disputed_tab: false,
      user_names_list: [],
      get_dispute_data: [],
      showExplanation: false,
      disputeReviewId: "",
      showReply: false,
      dispute_files: [],
      dispute_imgepreview: [],
      dispute_reply_text: "",
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      movieName: null,
      taxonomyList: [],
      isEnableFileUpload: false,
      similarMovieList: null,
      keyMAP: Math.random(),
      uploadMediaUrl: "",
      uploadedMediaSet: {
        embedLinks: [],
        uploadedFiles: [],
      },
      uploadedMediaSetFollowUp: {
        embedLinks: [],
        uploadedFiles: [],
    },
      isEnableConfirmModal: false,
      confirmModalText: '',
      confirmModalKey: '',
      confirmModalItemId: null,
      detailList: [],
      enableStickyHeader: false,
      forceShowLoginModal: false,
      disableCreateReview:true,
      publishReview:false
    })
  }

  toggleConfirmModal = ({ text, isTextDelete, uniqueKey, id }) => {
    let text_str = '';
    if (isTextDelete && !text) {
      text_str = 'Do you want to delete this ?';
    }

    if (text && !isTextDelete) {
      text_str = text
    }

    this.setState({
      isEnableConfirmModal: !this.state.isEnableConfirmModal,
      confirmModalText: text_str,
      confirmModalKey: uniqueKey || '',
      confirmModalItemId: id || null
    });
  }

  getUploadedMedia = ({ mediaSet, links }) => {
    let { uploadedMediaSet,uploadedMediaSetFollowUp } = this.state;
    let uploadedMediaSetCopy = { ...uploadedMediaSet }
    let uploadedMediaSetFollowUpCopy = {...uploadedMediaSetFollowUp}
    const key = 'id'
    const keyEmbed = 'thumbnail'

    if (this.state.reviewOneToggler) {
      if (this.props.isFollowReviewEnable) {
        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
          const mediaContainer = [...this.props.followReviewMediaSet, ...mediaSet]
          const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
            [item['id'], item])).values()];
          this.props.uploadMediaInReviewMediaSetForFollowReview(mediaSetUnique);
        }
        if (links && Array.isArray(links) && links.length > 0) {
          const embedContainer = [...this.props.followReviewEmbedLinkSetFinalized, ...links]
          const embedSetUnique = [...new Map(embedContainer?.map(item =>
            [item['thumbnail'], item])).values()];
          this.props.finalizedMediaInReviewEmbedLinkSetForFollowReview(embedSetUnique);
        }
      } else {

        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
          const mediaContainer = [...this.props.newReviewMediaSet, ...mediaSet]
          const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
            [item['id'], item])).values()];
          this.props.uploadMediaInReviewMediaSetForNewReview(mediaSetUnique);
        }
        if (links && Array.isArray(links) && links.length > 0) {
          const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
          const embedSetUnique = [...new Map(embedContainer?.map(item =>
            [item['thumbnail'], item])).values()];
          this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
        }
      }
    } else {
      if (this.props.isEditReviewEnabled) {
        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
          const mediaContainer = [...this.props.editReviewMediaSetFinalized, ...mediaSet]
          const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
            [item['id'], item])).values()];
          this.props.uploadMediaInReviewMediaSetForEditReview(mediaSetUnique);
        }
        if (links && Array.isArray(links) && links.length > 0) {
          const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
          const embedSetUnique = [...new Map(embedContainer?.map(item =>
            [item['thumbnail'], item])).values()];
          this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
        }
      } else {
        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) { uploadedMediaSetCopy['uploadedFiles'] = uploadedMediaSetCopy['uploadedFiles'].concat(mediaSet); }
        if (links) { uploadedMediaSetCopy['embedLinks'] = uploadedMediaSetCopy['embedLinks'].concat(links); }
        this.setState({ uploadedMediaSet: uploadedMediaSetCopy }, () => { this.props.get_uploaded_media(uploadedMediaSetCopy); });
      }
    }

}

  getuploadUrl = (url) => {
    let { uploadMediaUrl } = this.state;
    uploadMediaUrl = url.uploadUrl;
    this.setState({ uploadMediaUrl });
  }


  componentWillMount() {
    this.resetStates();
    this.props.reset_reviews_listing();
    this.hitOnce = false;
    this.checksAndRenderById();
  }

  checksAndRenderById = () => {
    let props = this.props;
    let pathname = props.history.location?.pathname;
    const urlId = props?.match?.params?.id
    let id = props?.history?.location?.state?.id || urlId;
    let serviceParams = props?.match?.params;

    if (id === undefined && serviceParams?.service !== '' && serviceParams?.state !== '' && serviceParams?.city !== '' && serviceParams?.name !== '' && !this.hitOnce) {
      this.hitOnce = true;
      let items = pathname.split('/');
      let type = items[1] ? items[1] : '';
      let serviceItems = Object.values(serviceParams).join('/');
      // this.props.get_id_from_slug({ type, slug: serviceItems })
    }

    if (id) {
      this.setState({ movieId: id, MovieDetails: null, simillarBusiness: null, setWallDataItems: {} }, () => {
        window.scrollTo(0, 0);
        this.fetchByItemId({ movieId: this.state.movieId });
      });
    }

    if (id === undefined && serviceParams?.id && this.hitOnce) {
      this.hitOnce = true;
      let items = pathname.split('/');
      let type = items[1] ? items[1] : '';
      let slug = items[2] ? items[2] : '';
      // this.props.get_id_from_slug({ type, slug })
    }

    this.isWillMountCallAble = true;
  }

  componentWillReceiveProps(nextProps) {
    let { fetchGalleryScroll, fetchGalleryCount, movieId, movieDetail, similarMovieList } = this.state;

    if (nextProps.auth_data && (nextProps.auth_data !== this.props.auth_data)) {
      //this.props.my_profile_info();
      window.location.reload();
    }

    if (nextProps?.movie_details) {
      this.setState({
        movieDetail: nextProps?.movie_details,
        imgUrl: nextProps?.movie_details,
        Duration: nextProps?.movie_details,
        Rating: nextProps?.movie_details,
        avgRating: nextProps?.movie_details,
        movieName: nextProps?.movie_details?.name,
        taxonomyList: nextProps?.movie_details?.taxonomy,

      }, () => {

        if (!similarMovieList && !this.firstTimeSimillarCallable) {
          this.firstTimeSimillarCallable = true;
          this.getSimilarMovieList();
        }
      });

    }

    if (movieDetail !== null && nextProps?.movie_details) {

      this.setState({
        movieDetail: nextProps?.movie_details,
      })
    }


    if (nextProps?.fetch_theater_data) {
      this.setState({ theaterDetail: nextProps?.fetch_theater_data });
    }

    if (nextProps?.fetch_theater_data) {
      this.setState({ theaterDetail: nextProps?.fetch_theater_data });
    }

    if (nextProps.get_review_listing &&
      Array.isArray(nextProps.get_review_listing.results)) {
      this.setState({
        reviews: nextProps?.get_review_listing?.results,
      },()=>{
        // highlightSearchTerm();
    });
      if (!this.state.initialReviewListing) this.setState({ initialReviewListing: nextProps.get_review_listing.results })

      if (!this.state.initialReviewListing?.length && nextProps.get_review_listing.results?.length)
        this.setState({ initialReviewListing: nextProps.get_review_listing.results })

      if (nextProps.get_review_listing.results?.some(item => item?.created_by?.id === this.props?.loggedInUser?.id && !!nextProps.get_review_listing.results.length)) {
        this.setState({ disableCreateReview: true })
      } else if (nextProps?.get_review_listing?.results?.length === 0) {
        if(!this.state.initialReviewListing)  this.setState({ disableCreateReview: false })
      }
    } else if (nextProps.get_review_listing && Array.isArray(nextProps.get_review_listing.results) && nextProps.get_review_listing.results.length === 0) {
      this.setState({reviews: nextProps.get_review_listing.results},()=>{
        // highlightSearchTerm();
    })
      if (this.state.publishReview) {
        this.setState({ disableCreateReview: true})
      } else {
        if(!this.state.initialReviewListing)  this.setState({ disableCreateReview: false})
      }
    }

    if (nextProps?.back_to_listing) {
      if (nextProps?.back_to_listing?.code === 200 && !movieId) {
        this.setState({ movieId: nextProps?.back_to_listing?.entry_id }, () => {
          this.fetchByItemId({ movieId: this.state.movieId });
        });
      }

      if ([404, 400, 500].includes(nextProps?.back_to_listing?.code)) {
        this.props.history.push('/movies');
      }
    }

    if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypesList: nextProps.album_types_list,
        },
        //uploadMediaModal: true,
        selectedUpMediaType: "upload",
        showGalleryType: "images",
      });
    }

    if (
      nextProps.album_type_data &&
      Object.keys(nextProps.album_type_data).length > 0
    ) {
      let item = nextProps.album_type_data;
      if (item.album) { fetchGalleryScroll = [] }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        ...this.state,
        //selectedUpMediaType: "gallery",
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypeData: nextProps.album_type_data,
        },
        fetchGalleryScroll, fetchGalleryCount,
      });
    }
    if (nextProps?.advisory_list_data && nextProps?.advisory_list_data?.results) {

      this.setState({ detailList: nextProps.advisory_list_data.results })
    }
    if("isEditReviewEnabled" in nextProps){
      if(this.state.isEditReviewEnabled !==nextProps.isEditReviewEnabled){
          // User has closed thepopup withj publishing
          // revert all the images we just updated.
          // this.revertBackTheImages();
      }
      this.setState({isEditReviewEnabled: nextProps.isEditReviewEnabled})
  }
  }
//   revertBackTheImages = () =>{
//     const tempReviews = [...this.state.reviews];
//     const foundIndex = tempReviews.findIndex(r=> r.id ===this.props.isEditedReviewId)
    
//     if(foundIndex >-1){
//         let reviewmedia_set_copy =  tempReviews[foundIndex].reviewmedia_set
//         this.state.newlyUpdatedMedias.forEach((media)=>{
//             if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.id===media?.id)){
//                 reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.id!==media.id)
//             }                
//         })
       
//         this.state.newlyUpdatedEmbedMedias.forEach((media)=>{
//             if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.url===media?.url)){
//                 reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.url!==media.url)
//             }                
//         })
//         this.state.deletedMedias?.forEach((media)=>{                
//             reviewmedia_set_copy.push(media)                              
//         })

//         tempReviews[foundIndex].reviewmedia_set = reviewmedia_set_copy;

//         this.setState({reviews: tempReviews,deletedMedias:[]},()=>{
//         })
//     }
// }

  componentDidMount() {
    this.props.my_profile_info();
    let { movieId } = this.state;
    if (movieId && !this.isWillMountCallAble) {
      this.fetchByItemId({ movieId })
    }
    window.addEventListener("scroll", this.handleScroll, { passive: false });
  }

  fetchByItemId = ({ movieId }) => {
    if (movieId) {
      this.props.setItemDetailLoading(true);
      this.props.get_type_detail({ type: 'movies', id: movieId });
      this.props.get_reviews_listing(movieId)
      this.props.get_theater_data(movieId);
      this.props.get_advisory_listing(movieId);
    }
  }


  componentDidUpdate(prevProps) {
    let nextProps = this.props;
    let { movieId, movieDetail, mediaSet, uploadedMediaSet,mediaSetFollowUp, uploadedMediaSetFollowUp } = this.state;
    // if (nextProps?.history?.location?.state?.id !== movieId) {
    //   this.hitOnce = false;
    //   this.checksAndRenderById();
    // }

    if (this.props.current_location !== prevProps.current_location) {
      if (movieId) {
        this.fetchByItemId({ movieId })
      }
    }

    let uploadedFiles = uploadedMediaSet?.uploadedFiles;

    if (Array.isArray(uploadedFiles) && Array.isArray(mediaSet) && uploadedFiles?.length !== mediaSet?.length) {
      this.setState({ mediaSet: uploadedFiles });
    }
    let uploadedFilesFollowUp = uploadedMediaSetFollowUp?.uploadedFiles;

    if (Array.isArray(uploadedFilesFollowUp) && Array.isArray(mediaSetFollowUp) && uploadedFilesFollowUp?.length !== mediaSetFollowUp?.length) {

        this.setState({ mediaSetFollowUp: uploadedFilesFollowUp });
    }

  }

  // countdown counter
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.resetStates();
    this.props.clear_type_details();
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    let enableSticky = document.getElementById('enable-sticky');
    let distanceToTop = enableSticky.getBoundingClientRect().top;
    if (distanceToTop < 0) {
      if (this.state.enableStickyHeader !== true) {
        this.setState({ enableStickyHeader: true });
      }
    } else {
      if (this.state.enableStickyHeader !== false) {
        this.setState({ enableStickyHeader: false });
      }
    }
  }

  // Delete Review
  confirmDeleteModalToggle = () => {

    if (this.state.deletereviewId) {
      this.setState({ deletereviewId: 0 });
    }
    this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
  };

  handleOnDeleteConfirmation = () => {
    if (this.state.deletereviewId) {
      this.props.delete_my_review(
        this.state.deletereviewId,
      );
    }
    this.setState({
      confirmDeleteModal: !this.state.confirmDeleteModal,
      deletereviewId: 0,
    });
  };

  // upload media code start
  handleOnClickUploadMedia = () => {
    this.props.get_album_types_list();
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        progress: 0,
        uploadFiles: [],
        uploadedFiles: [],
      },
      uploadMediaPostModal: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
    });
  };

  uploadMediaModalToggle = () => {
    this.setState({
      ...this.state,
      uploadMediaPostModal: !this.state.uploadMediaPostModal,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: [],
        selectedMediaIds: [],
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        //albumTypesList: [],
        embedLinks: {},
        uploadedFiles: [],
        uploadFiles: [],
      },
      selectedUpMediaType: "upload",
      showGalleryType: "images",
    });
  };

  handleOnClickSelectedUploadMediaType = (type) => {
    if (type === "gallery") {
      this.props.get_album_type_data("image", "", 1);
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        showGalleryType: "images",
        uploadMedia: {
          ...this.state.uploadMedia,
          mediaType: "image",
          albumType: "",
          pageNo: 1,
          //selectedMedia: [],
          //selectedMediaIds: [],
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "upload") {
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          //selectedMedia: [],
          //selectedMediaIds: [],
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "embed") {
      let embedLinks = {
        0: "",
        1: "",
      };
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: embedLinks,
        },
      });
    }
  };

  handleOnFilePostUploadChange = (event) => {
    let uploadFiles = event.target.files;
    let showFiles = [];
    for (const key of Object.keys(uploadFiles)) {
      showFiles.push({ id: "", url: URL.createObjectURL(uploadFiles[key]) });
    }
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        uploadFiles: showFiles,
        progress: 0,
      },
    });
    let progressPart = 100 / showFiles.length;

    for (const key of Object.keys(uploadFiles)) {
      let data = new FormData();
      data.append("file", uploadFiles[key]);

      callApi(
        `/upload/multiuploader/?album=feeds&instance=post&image=undefined&doc=undefined`,
        "POST",
        data,
        true
      ).then((response) => {
        let progressBar = 0;
        this.handleOnClickSelectGalleryMedia(response);
        if (showFiles.length === 1 || key === showFiles.length - 1) {
          progressBar = 100;
        } else {
          progressBar = progressBar + progressPart;
        }
        showFiles[key].id = response.id;
        this.setState({
          ...this.state,
          uploadMedia: {
            ...this.state.uploadMedia,
            progress: progressBar,
            uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
            // uploadedFiles: showFiles,
          },
        });
      });
    }
  };

  handleOnClickSelectGalleryMedia = (media) => {
    let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
    let newSelectedMediaIds;
    let newSelectedMedia;
    if (index !== -1) {
      newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
        (item) => item !== media.id
      );
      newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
        (item) => item.id !== media.id
      );
    } else {
      newSelectedMediaIds = [
        ...this.state.uploadMedia.selectedMediaIds,
        media.id,
      ];
      newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
    }
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: newSelectedMedia,
        selectedMediaIds: newSelectedMediaIds,
      },
    });
  };

  handleOnClickRemoveSelectedMedia = (id) => () => {
    let removeMediaIds = [];
    removeMediaIds.push(id);
    this.props.delete_selected_gallery_media(removeMediaIds);
    let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
      (file) => file.id !== id
    );
    let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
      (file) => file.id !== id
    );
    let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
      (item) => item !== id
    );
    let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
      (file) => file.id !== id
    );
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: selectedMedia,
        selectedMediaIds: selectedMediaIds,
        uploadedFiles: uploadedFiles,
        uploadFiles: uploadFiles,
      },
    });
  };

  handleOnClickGalleryType = (type) => {
    let mediaType = "";
    if (type === "images") {
      mediaType = "image";
    } else if (type === "videos") {
      mediaType = "video";
    }
    this.props.get_album_type_data(mediaType, "", 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: type,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: mediaType,
        albumType: "",
        pageNo: 1,
        //selectedMedia: [],
        //selectedMediaIds: [],
      },
    });
  };

  handleOnClickAlbumTypeChange = (e) => {
    this.props.get_album_type_data("image", e.target.value, 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: "images",
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: e.target.value,
        pageNo: 1,
        //selectedMedia: [],
        //selectedMediaIds: [],
      },
    });
  };

  handleScrollGalleryImage = () => {
    let { refGalleryImageScroll } = this;
    let { fetchGalleryAlbum } = this.state;

    let clientHeight = refGalleryImageScroll.current.clientHeight;
    let scrollHeight = refGalleryImageScroll.current.scrollHeight;
    const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
    }
  }

  fetchGalleryOnScroll = ({ type }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;
      if (nextCount <= roundValue) {
        let page = nextCount;
        this.props.get_album_type_data(type, "", page);
        //this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
      }
    }
  }

  handleScrollGalleryVideo = () => {
    let { refGalleryVideoScroll } = this;
    // let count = 0;
    let clientHeight = refGalleryVideoScroll.current.clientHeight;
    let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
    const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
    }
  }
  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach(item => {
        if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map(item => {

          let itemName = (item?.thumbnail).split('.');
          let itemFile = (item?.file).split('.');
          let extName = itemName[itemName.length - 1];
          let extFile = itemFile[itemFile.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview

          return (
            <Col xs="auto" className="mb-3">
              <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                <Label check>
                  <Input type="checkbox" onClick={() =>
                    this.handleOnClickSelectGalleryMedia(
                      item
                    )
                  } />
                  <div className="gallery-media" >
                    <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                  </div>
                </Label>
                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
              </FormGroup>
            </Col>
          )
        })
      }
    } else {
      return getDataItems;
    }
  }

  embedLinkOnChange = (id) => (e) => {
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        embedLinks: {
          ...this.state.uploadMedia.embedLinks,
          [id]: e.target.value,
        },
      },
    });
  };
  mapEmbedLinks = () => {
    let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
    return embedKeys.map((emKey) => {
      return (
        <div className="d-flex mx-n2 mb-2" key={emKey}>
          <div className="flex-fill px-2">
            <Input
              type="url"
              bsSize="sm"
              className="bg-white"
              value={this.state.uploadMedia.embedLinks[emKey]}
              onChange={this.embedLinkOnChange(emKey)}
              placeholder="Embeded link"
            />
          </div>
          <div className="px-2">
            <Button
              title="Remove"
              color="danger"
              size="sm"
              hidden={emKey <= 1 ? true : false}
              onClick={() => {
                this.deleteEmbedLinkRow(emKey);
              }}
            >
              <FontAwesomeIcon icon="minus" />
            </Button>
          </div>
        </div>
      );
    });
  };
  deleteEmbedLinkRow = (emKey) => {
    let embedLinks = this.state.uploadMedia.embedLinks;
    if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
      delete embedLinks[emKey];
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: embedLinks,
        },
      });
    }
  };
  addEmbedlinkRow = () => {
    let newEmbedLinks = {};
    for (let i = 2; i < 5; i += 1) {
      if (
        Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
        -1
      ) {
        newEmbedLinks[i] = "";
        break;
      }
    }
    if (Object.keys(newEmbedLinks).length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {
            ...this.state.uploadMedia.embedLinks,
            ...newEmbedLinks,
          },
        },
      });
    }
  };
  insertEmbedLinks = () => {
    //let post = this.state.addPost;

    let embedValues = Object.values(this.state.uploadMedia.embedLinks).filter(
      (item) => item !== ""
    );

    //if (post.body && post.body.replace(/(<([^>]+)>)/gi, "") !== "") {
    // if (embedValues.length > 0) {
    //   embedValues = embedValues.map((item) => "<p>" + item + "</p>");
    //   post.body = post.body + embedValues.join("");
    //   post.body_preview = post.body;
    // }
    // this.setState({
    //   ...this.state,
    //   addPost: post
    // });
    //} else {
    if (embedValues.length > 0) {
      embedValues = embedValues.map((item) => "<a href=" + item + "><p>" + item + "</p></a>");
      let newPost = {
        video: embedValues.join("\n"),
        exclude_list: [],
        question_rating_category: [],
        messagemedia_set: [],
      };
      //if (this.state.myPostsList.count < this.state.limitedPostsCount) {
      this.props.add_my_post(
        newPost,
        this.state.filterType,
        this.state.pageNo
      );
      //}
      this.setState({
        ...this.state,
        limitedPostsModal:
          this.state.myPostsList.count < this.state.limitedPostsCount
            ? false
            : true,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: {},
        },
        uploadMediaPostModal: false,
      });
    }
    //}
  };
  handleOnClickRemoveSelectedGalleryMedia = (media) => {

    let removeMediaIds = [];
    removeMediaIds.push(media.id);
    let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
    let newSelectedMediaIds;
    let newSelectedMedia;
    if (index !== -1) {
      newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
        (item) => item !== media.id
      );
      newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
        (item) => item.id !== media.id
      );
      this.props.delete_selected_gallery_media(removeMediaIds);
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: newSelectedMedia,
          selectedMediaIds: newSelectedMediaIds,
        },
      });
    }
  };
  truncate = (filenameString) => {
    // let split = filenameString.split(".");
    let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
    let extension = filenameString.substr(
      filenameString.lastIndexOf("."),
      filenameString.length - 1
    );
    let partial = filename.substring(filename.length - 3, filename.length);
    filename = filename.substring(0, 15);
    return filename + "..." + partial + extension;
  };

  // upload media code end

  handleMouseIn(event) {
    var name = "";
    if (event.target.getAttribute("data-name") != null) {
      name = event.target.getAttribute("data-name");
    }
    var id = 0;
    if (event.target.getAttribute("data-id") != null) {
      id = parseInt(event.target.getAttribute("data-id"));
    }
    if (name == "bummer") {
      this.setState({ hoverbummer: true, hoverReviewId: id })
    } else if (name == "lol") {
      this.setState({ hoverlol: true, hoverReviewId: id })
    } else if (name == "nailed") {
      this.setState({ hovernailed: true, hoverReviewId: id })
    } else if (name == "insightful") {
      this.setState({ hoverinsightful: true, hoverReviewId: id })
    } else if (name == "woohoo") {
      this.setState({ hoverwoohoo: true, hoverReviewId: id })
    }
    if (name != "" && id != 0) {
      let assetType = "review";
      this.props.get_reviewgroup(assetType, id, name)
    }

  }

  handleMouseOut(event) {
    var name = "";
    if (event.target.getAttribute("data-name") != null) {
      name = event.target.getAttribute("data-name");
    }
    var id = 0;
    if (event.target.getAttribute("data-id") != null) {
      id = parseInt(event.target.getAttribute("data-id"));
    }
    if (name == "bummer") {
      // let hoverbummer = false
      this.setState({ hoverbummer: false, hoverReviewId: id })
    } else if (name == "lol") {
      this.setState({ hoverlol: false, hoverReviewId: id })
    } else if (name == "nailed") {
      this.setState({ hovernailed: false, hoverReviewId: id })
    } else if (name == "insightful") {
      this.setState({ hoverinsightful: false, hoverReviewId: id })
    } else if (name == "woohoo") {
      this.setState({ hoverwoohoo: false, hoverReviewId: id })
    }
    if (name != "" && id != 0) {
      let assetType = "review";
      this.props.get_reviewgroup(assetType, id, name)
    }
  }

  handleOnClickReacted = (type) => {
    const { reactedReviewId } = this.state;
    if (type != "" && reactedReviewId != 0) {
      let assetType = "review";
      this.props.get_reviewgroup(assetType, reactedReviewId, type)
      this.setState({ viewReactedType: type });
    }
  }

  handleOpenModal(data) {
    this.setState({ viewMyPhotosModal: !this.state.viewMyPhotosModa, imageAttacment: data })
  }

  handleOnClickReviewType = (review, reviewId, type) => {
    // const { fetchReviewGroup } = this.state;
    let reviewAl = 0;
    reviewAl = review.review_group_count.lol + review.review_group_count.woohoo + review.review_group_count.insightful + review.review_group_count.bummer + review.review_group_count.nailed;
    this.setState({ viewPeopleWhoReactedModalToggle: !this.state.viewPeopleWhoReactedModalToggle, review_group_count: review, reviewAll: reviewAl, viewReactedType: type, reactedReviewId: reviewId });
  };

  fetchReviewGroupList = (type) => {
    let fetchReviewGroup = this.state.fetchReviewGroup;
    if (fetchReviewGroup && fetchReviewGroup.length > 0) {
      return fetchReviewGroup.map((item, index) => {
        //if(item.group_icon == type){
        return (
          <li className="mb-3" key={index}>
            <div className="d-flex">
              <div className="mr-2 media media-post">
                <img
                  className="media-object"
                  width="50" height="50" src={item.profile_pic ? item.profile_pic : require("../../assets/images/icons/user-circle.png")} alt="User" />
              </div>
              <div>
                <a href={item.redirect_to} className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"> {item.name}</a>
                {type == "all" ? (<img src={require("../../assets/images/icons/" + item.group_icon + "-active.png")} alt="" />) : ""}
              </div>
            </div>
          </li>

        )
        //}
      })
    } else {
      return (
        <div className="text-secondary-dark font-weight-bold">No People Reacted.</div>
      )
    }
  }

  handleChange = (key1, evt) => {
    const {
      modalData,
    } = this.state
    var input = evt.target.value;
    modalData && Object.keys(modalData).forEach(element => {
      if (element === key1) {
        this.setState({
          modalData: {
            ...this.state.modalData,
            [key1]: evt.target.value
          },
          reviewValue: "<p>" + evt.target.value + "</p>",
          totalCharacter: input.length,
        })
      }
    })
  }

  // Dispute Review
  handleChangedispute = (e) => {
    const { disputeReview } = this.state;
    disputeReview[e.target.name] = e.target.value;
    this.setState({ disputeReview });
  };

  handleDisputeSubmit = () => {
    const { disputeReview, dispute_files } = this.state;
    disputeReview["dispute_file"] = dispute_files;
    disputeReview["review_id"] = this.state.reviewId;
    this.setState({ disputeReview, disputeThisReviewModalToggle: false });
    this.props.add_dispute_review(disputeReview, this.state.filterType);
  };

  handleHeatedDiscussion = (id) => {
    // this.setState({ historyLogModalToggle: true, disputeReviewId: id });
    this.setState({ voteReviewModalToggleFour: true, disputeReviewId: id });
    this.props.get_dispute_discussion(id);
  };

  handleDeleteDispute = () => {
    this.setState({ historyLogModalToggle: false });
    this.props.delete_dispute(this.state.disputeReviewId);
  };

  handleImageChange = async (e) => {
    e.preventDefault();
    // const {dispute_imgepreview,dispute_files} = this.state

    if (e.target.files.length > 1) {
      for (let i = 0; i < e.target.files.length; i++) {
        let reader = new FileReader();
        let file = e.target.files[i];
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreview_Url: reader.result,
          });
          this.setState({
            dispute_files: [...this.state.dispute_files, file],
            dispute_imgepreview: [
              ...this.state.dispute_imgepreview,
              reader.result,
            ],
          });
        };
        reader.readAsDataURL(file);
      }
    } else {
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreview_Url: reader.result,
        });

        this.setState({
          dispute_files: [...this.state.dispute_files, file],
          dispute_imgepreview: [
            ...this.state.dispute_imgepreview,
            reader.result,
          ],
        });
      };
      reader.readAsDataURL(file);
    }
  };

  deleteImage = (index) => {
    var image_array = [...this.state.dispute_files];
    image_array.splice(index, 1);
    this.setState({ dispute_files: image_array });

    var preview_array = [...this.state.dispute_imgepreview];
    preview_array.splice(index, 1);
    this.setState({ dispute_imgepreview: preview_array });
  };

  handleReplyChange = (e) => {
    const { value } = e.target;
    this.setState({ dispute_reply_text: value });
  };

  handleDisputeReply = (e) => {
    const { dispute_files, dispute_reply_text, disputeReviewId } = this.state;
    let params = {};
    params["flag_id"] = e[0].flag_id;
    params["flag_content_id"] = e[0].pk;
    params["dispute_reply_file"] = dispute_files;
    params["review_id"] = disputeReviewId;
    params["comment"] = dispute_reply_text;
    this.props.add_dispute_reply(params);
  };

  handleJuryVoting = () => {
    this.setState({ voteReviewModalToggleOne: true });
  };

  handleDeleteReply = (pk) => {
    this.props.delete_dispute_reply(pk, this.state.disputeReviewId)
  }

  handleSubmitRating = () => {

  }

  getnocategory = (discusst) => {
    let { discussType } = this.state;
    if (discusst && discusst != "") {
      this.setState({ discussType: discusst });
    }
  }

  getSimilarMovieList = async () => {

    const { movieId, movieName, taxonomyList } = this.state;
    let id = movieId;
    let name = window.unescape(movieName);
    let category = taxonomyList;

    let catName = '';
    if (category && category.length > 0) {
      for (var i = 0; i < category.length; i++) {
        catName = catName + category[i] + ',';
      }
    }
    catName = catName.replace(/,\s*$/, "");
    catName = window.unescape(catName);

    if (id !== null && name !== '' && catName !== '') {

      let response = await callApi(
        `/api/search/?content_type=movies&cat=${catName}&to_api=true&page=1&simmilar=true&listing=${id}&l_name=${name}`,
        "GET"
      );

      if (response && response.code !== 400 && response.code !== 500) {
        this.setState({
          similarMovieList: response,
        });
      }

    }
  }

  onPublishReview = () => {
    let { movieId } = this.state;
    this.setState({publishReview:true},()=>{            
      this.fetchByItemId({ movieId });
  })
    // will call here the fetch reviews list function
  }

  handleOnConfirmModal = () => {
    let { confirmModalKey, movieDetail, confirmModalItemId, movieId, deleteReviewComment } = this.state;

    if (confirmModalKey === "delete-movies-review") {
      let name = movieDetail?.name;
      this.props.delete_movies_review(confirmModalItemId, movieId, name);
    }

    if (confirmModalKey === "delete-movies-coolFacts") {
      this.handleDeleteItem(confirmModalItemId);
    }

    if (confirmModalKey === "delete-movies-advisory" && confirmModalItemId) {
      let type = 'advisory';
      this.props.delete_movie_advisory(confirmModalItemId, movieId, type);
    }

    if (confirmModalKey === "delete-movies-tips" && confirmModalItemId) {
      let type = 'tips';
      this.props.delete_movie_advisory(confirmModalItemId, movieId, type);
    }

    if (confirmModalKey === "delete-movies-warning" && confirmModalItemId) {
      let type = 'warning';
      this.props.delete_movie_advisory(confirmModalItemId, movieId, type);
    }

    if (confirmModalKey === "delete-movie-review-comment" && confirmModalItemId) {
      this.deleteReviewComments(confirmModalItemId);
    }

    this.props.get_type_detail({ type: 'movies', id: movieId })
  }

  deleteReviewComments = async (id) => {
    let { movieId } = this.state;
    try {
      let url = `/api/deleterecord/?entityid=${id}&entity=comment`
      let response = await callApi(url, 'DELETE');
      if (response && response.code !== 500) {
        if (movieId) {
          this.fetchByItemId({ movieId })
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleOnClickUploadModalOk = () => {
    if (this.state.galleryTypeUpload) {
      this.setState({ callGalleryFunction: true })
    }
  }

  toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false, reviewOneToggler,followUpStatus) => {
    this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
    this.setState({
        reviewOneToggler: reviewOneToggler? true: false,
        followUpStatus:followUpStatus
    })
}

  handleDeleteItem = async (id) => {
    try {
      let response = await callApi(`/api/coolfacts/?id=${id}&type=undefined`, 'DELETE');
      if (response && response?.code !== 500) {
        this.toggleUpateCoolFacts(true);
        toast(`Your content deleted from cool facts section.`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  toggleUpateCoolFacts = (value) => { this.setState({ updateCoolFactsOnDelete: value }) }

  resetUploadedMediaSet = () => {
    const uploadedMediaSet = {
        embedLinks: [],
        uploadedFiles: [],
        mediaSet: [],
        mediaSetFollowUp:[]
    }
    this.setState({ uploadedMediaSet });
}

  handleCategoryChange = (id) => {
    let elementToScrollTo = document.getElementById(id);
    const childOffset = 1;
    for (let i = 0; i < childOffset; i++) {
      if (!elementToScrollTo.previousElementSibling) {
        break;
      }
      elementToScrollTo = elementToScrollTo.previousElementSibling;
    }
    elementToScrollTo.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }

  forceToggleLoginModal = () => {
    this.setState(prevState => ({
      ...this.prevState,
      forceShowLoginModal: !prevState.forceShowLoginModal
    }));
  }

  removeUploadedMediaSet = (media)=>{       
    this.setState(prevState=>{
        return {
            ...prevState,
            uploadedMediaSet:{
                ...prevState.uploadedMediaSet,
                uploadedFiles:this.state.uploadedMediaSet?.uploadedFiles?.filter(item=>item.id!==media?.id)
            }
        }
    })

    // this.setState({deletedMedias:[...this.state.deletedMedias,...[media]]})
}

removeUploadedMediaSetFollow = (media)=>{       
    this.setState(prevState=>{
        return {
            ...prevState,
            uploadedMediaSetFollowUp:{
                ...prevState.uploadedMediaSetFollowUp,
                uploadedFiles:this.state.uploadedMediaSetFollowUp?.uploadedFiles?.filter(item=>item.id!==media?.id)
            }
        }
    })        
}

  render() {
    let { movieDetail, movieName, imgUrl, Duration, avgRating, Rating, enableStickyHeader, isEnableConfirmModal, confirmModalText, reviews, review_group_count, reviewAll, overallRatingVote, viewEditReviewStep, isEnableFileUpload, similarMovieList, confirmModalKey, movieId, uploadedMediaSet,uploadedMediaSetFollowUp, mediaSet } = this.state;
    const {
      disputeReview: { reason, comment },
      dispute_reply_text,
      get_dispute_data
    } = this.state;
    const { my_profile, auth_data } = this.props;
    const avg_rating = avgRating && avgRating.total_rating && avgRating.total_rating.avg_rating[0] ? avgRating.total_rating.avg_rating[0] : 'norating.png';
    const authId = localStorage.getItem('profileId');
    return (
      <div className="wr-movie-top-banner">
        {isEnableConfirmModal ?
          <ConfirmModal
            modalText={confirmModalText}
            modalKey={confirmModalKey}
            toggleConfirmModal={this.toggleConfirmModal}
            handleOnConfirm={this.handleOnConfirmModal}
          />
          : null}

        {isEnableFileUpload ?
          <UploadMedia
            getUploadedMedia={this.getUploadedMedia}
            uploadedUrl={this.state.uploadMediaUrl}
            hideEmbed={this.state.hideEmbed}
            callbackFunction={this.handleOnClickUploadModalOk}
            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
              this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, this.state.reviewOneToggler? true: false);             
          }}
            typeUpload={this.state.typeupload}
            discusstype={this.state.discussType}
          />
          : null}

        {/* Show/hide on scroll */}
        <div hidden={!enableStickyHeader}>
          <div className="bg-white-shaded text-darker fs-13 p-2 fixed-top" style={{ top: '45px' }}>
            <Container>
              <Row>
                <Col lg={6}>
                  <div className="d-flex mx-n2">
                    <div className="px-2">
                      <div className="topbar-main-img-holder">
                        {/* <img className="object-fit-contain" width="80" src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/139472r1.jpg" alt="" /> */}
                        <img
                          src={`${imgUrl && imgUrl?.listing_profileimage && imgUrl?.listing_profileimage.length > 0 && imgUrl?.listing_profileimage[0] && imgUrl?.listing_profileimage[0].url}`}
                          className="img-fluid"
                          onError={(error) =>
                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                          }
                          alt={`${imgUrl && imgUrl?.listing_profileimage && imgUrl?.listing_profileimage.length > 0 && imgUrl?.listing_profileimage[0] && imgUrl.listing_profileimage[0].caption ? imgUrl.listing_profileimage[0].caption : 'no-image'}`}
                        />
                      </div>
                    </div>
                    <div className="px-2 mt-2 d-flex">
                      <div className="d-flex mr-1">
                        <div className="d-flex">
                          <div>
                            <h2 className="fs-20 text-uppercase">{movieName || ''}</h2>
                          </div>
                          <div className="ml-3 align-self-end" hidden>
                            <ul className="list-unstyled d-flex flex-wrap mx-n2">
                              <li className="text-uppercase font-weight-bold px-1">
                                <span>{'Action, Adventure'}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="ml-3 align-self-end">
                          <ul className="list-unstyled d-flex flex-wrap mx-n2">
                            <li className="text-uppercase font-weight-bold px-1">
                              <span>&nbsp;</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex align-items-center">
                          <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                            className="img-fluid"
                            onError={(error) =>
                              (error.target.src = `../../../assets/images/norating.png`)
                            }
                            alt={`${avgRating && avgRating.total_rating && avgRating.total_rating.avg_rating[1] ? avgRating.total_rating.avg_rating[1] : 'no-rating'}`}
                          />
                        </div>

                        <div className="mt-1">
                          <span className="ff-alt"> {(Rating && Rating.total_rating && Rating.total_rating.total_review) ? Rating.total_rating.total_review : 0} {getReviewSuffix(Rating?.total_rating?.total_review)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="ml-4">
                    <div className="d-flex flex-400 mx-n2 mt-2">
                      {
                        Duration ? (

                          <div className="px-2 flex-180">
                            <div>
                              <span className="font-weight-bold mr-1">
                                Duration:
                              </span>
                              <span className="ff-alt wb-break-word">
                                {Duration && Duration.additional_info && Duration.additional_info.duration && Duration.additional_info.duration.length > 0 ? Duration.additional_info.duration[0].value : ""}
                              </span>
                            </div>
                          </div>
                        ) : ("")
                      }

                      <div className="px-2 flex-grow-1">
                        <div>
                          <span className="font-weight-bold mr-1">
                            {'Now showing in:'}
                          </span>
                          <span className="ff-alt">
                            {'AMC Newport Centre 11'}
                          </span>
                        </div>
                        <div className="mt-2">
                          <span className="font-weight-bold mr-1">
                            {'Show Time:'}
                          </span>
                          <span className="ff-alt">
                            {'1PM, 5PM, 11PM'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {(my_profile && my_profile.id && authId && (authId == my_profile.id)) ? (
          <AppHeader isEnableCover={false} logoutToHome={true} />
        ) : (<PublicPageHeader isEnableCover={false} forceShowLoginModal={this.state.forceShowLoginModal} forceToggleLoginModal={this.forceToggleLoginModal} />)}
        <header
          className="cover-banner bg-dark"
          style={{ backgroundImage: `url(${(this.state.movieDetail && this.state.movieDetail.listing_profileimage && this.state.movieDetail.listing_profileimage[0] && this.state.movieDetail.listing_profileimage[0].url) ? this.state.movieDetail.listing_profileimage[0].url : 'https://userdatawikireviews.s3.amazonaws.com/media/content/253705r1.jpg'})` }}>
          <TabContent
            className={this.state.viewMovieAs === 'gallery' ? 'bg-dark-shaded-sm' : ''}
            activeTab={this.state.viewMovieAs}
            style={{ minHeight: '500px' }}>
            <TabPane tabId="about">
              <About
                aboutDetails={this.state.movieDetail !== null ? this.state.movieDetail : ""}
                movieId={this.state.movieId}
                toggleEnableFileUpload={this.toggleEnableFileUpload}
                uploadedMediaSet={this.state.uploadedMediaSet}
                getuploadUrl={this.getuploadUrl}
                callGalleryFunction={this.state.callGalleryFunction}
                resetGalleryFunctionToggle={() => this.setState({ callGalleryFunction: false })}
                handleCategoryChange={this.handleCategoryChange}
              />
            </TabPane>
            <TabPane tabId="gallery">
              <Gallery
                details={this.props.movie_details}
                categoryType={categoryType.Movies}
                forceToggleLoginModal={this.forceToggleLoginModal}
                mainTaxonomy={"movies"}
              />
              {/* <Gallery movieDetails={this.props.movie_details}
                toggleEnableFileUpload={this.toggleEnableFileUpload}
                uploadedMediaSet={this.state.uploadedMediaSet}
                callGalleryFunction={this.state.callGalleryFunction}
                resetGalleryFunctionToggle={() => this.setState({ callGalleryFunction: false })}
                getuploadUrl={this.getuploadUrl} resetUploadedMediaSet={this.resetUploadedMediaSet} forceToggleLoginModal={this.forceToggleLoginModal} /> */}
            </TabPane>
            <TabPane tabId="nowplaying">
              {this.state.viewMovieAs === 'nowplaying' && (
                <div className="featured-map">
                  <LeafletMap
                    movieDetails={this.state.movieDetail !== null ? this.state.movieDetail : ""}
                    theaterDetails={this.state.theaterDetail !== null ? this.state.theaterDetail : ""}
                    movieId={this.state.movieId}
                    currentLocationData={this.props.current_location}
                  />
                </div>
              )}

              {this.props.movie_details && (
                <TheaterLocation
                  movieDetails={this.props.movie_details}
                  theaterDetails={this.props.fetch_theater_data}
                  movieId={this.state.movieId}
                />
              )}
            </TabPane>
            <TabPane tabId="homeTheater">
              <Hometheater
                Hometheater={this.state.movieDetail !== null ? this.state.movieDetail : ""}
              />
            </TabPane>
          </TabContent>
          <Container>
            <Nav className="featured position-relative bg-white" justified pills style={{ marginTop: '-54px', zIndex: 3 }}>
              <NavItem className="mw-100 margin-right-2">
                <NavLink className="text-truncate" href="#"
                  active={this.state.viewMovieAs === "about"}
                  onClick={() => {
                    this.setState({ viewMovieAs: "about" })
                  }}>
                  <span className="font-weight-bold text-uppercase">
                    {'About'}
                  </span>
                </NavLink>
              </NavItem>

              <NavItem className="mw-100 margin-right-2">
                <NavLink className="text-truncate" href="#"
                  active={this.state.viewMovieAs === "gallery"}
                  onClick={() => {
                    window.dispatchEvent(new Event("resize"));
                    this.setState({ viewMovieAs: "gallery" })
                  }}>
                  <span className="font-weight-bold text-uppercase">
                    {'Gallery'}
                  </span>
                </NavLink>
              </NavItem>
              {this.state.theaterDetail && this.state.theaterDetail.results && this.state.theaterDetail.results.theater_details.length > 0 ?
                <NavItem className="mw-100">
                  <NavLink className="text-truncate" href="#"
                    active={this.state.viewMovieAs === "nowplaying"}
                    onClick={() => {
                      this.setState({ viewMovieAs: "nowplaying" })
                    }}>
                    <span className="font-weight-bold text-uppercase">
                      {'Now Playing'}
                    </span>
                  </NavLink>
                </NavItem>
                : ""}
              {/* <NavItem className="mw-100">
                <NavLink className="text-truncate" href="#"
                  active={this.state.viewMovieAs === "homeTheater"}
                  onClick={() => {
                    this.setState({ viewMovieAs: "homeTheater" })
                  }}>
                  <span className="font-weight-bold text-uppercase">
                    {'Home Theater'}
                  </span>
                </NavLink>
              </NavItem> */}
              <NavItem className="mw-100">
                {/* <SocialPinFlag movieDetails={this.props.movie_details} forceToggleLoginModal={this.forceToggleLoginModal} /> */}
                <SocialButtons categoryId={this.state.movieId} showHistoryTab categoryType="movies" categoryDetails={this.props.movie_details} forceToggleLoginModal={this.forceToggleLoginModal} bgLight={false}/>
              </NavItem>
            </Nav>
          </Container>
        </header >

        {/* Main Content */}
        <section id="enable-sticky" className="py-4 text-white fs-14" style={{ backgroundColor: '#00162b' }}>
          <Container>
            <Row>
              <Col md={4}>
                <div id="seeDetails">                  
                  <DetailedStarRating
                    total_rating={movieDetail?.total_rating || null}
                    id={movieId}
                    bgLight={false}
                    charts={{ bar: true, line: true, genderTable: true }} /> 
                </div>
                <div id="castCrew">
                  {movieDetail?.professional && (
                    <CastAndCrew
                      id={movieId}
                      professional={movieDetail}
                      fetchByItemId={this.fetchByItemId}
                    />
                  )}
                </div>
                <div id="coolFacts">
                  <CoolFacts
                    id={movieId}
                    toggleConfirmModal={this.toggleConfirmModal}
                    toggleUpateCoolFacts={this.toggleUpateCoolFacts}
                    my_profile={this.props.my_profile}
                    updateCoolFactsOnDelete={this.state.updateCoolFactsOnDelete}
                    forceToggleLoginModal={this.forceToggleLoginModal}
                  />
                </div>
                <div className="bg-movie-dark text-white mb-3" id="seetechincaldetail">
                  <TechnicalDetails
                    id={movieId}
                    listing_type={'movies'}
                    my_profile={this.props.my_profile}
                    additional_info={this.props?.movie_details?.additional_info}
                    fetchByItemId={this.fetchByItemId}
                    forceToggleLoginModal={this.forceToggleLoginModal}
                  />
                </div>
                <div id="seeTipsWarning">
                  <TipsAndWarning id={this.state.movieId} data={this.state.detailList} toggleConfirmModal={this.toggleConfirmModal} name={this.state.movieName} forceToggleLoginModal={this.forceToggleLoginModal} />
                </div>
                <SimilarDetail name={'Similar Movies'} data={similarMovieList} type={'movies'} />

                {/* Advisory */}
                <div id="advisory">
                  <Advisory id={this.state.movieId} data={this.state.detailList} toggleConfirmModal={this.toggleConfirmModal} name={this.state.movieName} forceToggleLoginModal={this.forceToggleLoginModal} />
                </div>
                {/* Plot Summary */}
                <div id="viewPlotSummary">
                  <PlotSummary
                    id={this.state.movieId}
                    fetchByItemId={this.fetchByItemId}
                    additional_info={this.state?.movieDetail?.additional_info}
                    forceToggleLoginModal={this.forceToggleLoginModal}
                  />
                </div>
                <DiscussThisPage
                  id={movieId}
                  getUploadedMedia={this.getUploadedMedia}
                  uploadedMediaSet={this.state.uploadedMediaSet}
                  mediaSet={this.state.mediaSet}

                  toggleEnableFileUpload={this.toggleEnableFileUpload}

                  getnocategory={this.getnocategory}
                  getuploadUrl={this.getuploadUrl}
                  forceToggleLoginModal={this.forceToggleLoginModal}
                />
                <div className="bg-white text-center">
                  <a href="http://wikireviews.com/blog" rel="noopener noreferrer" target="_blank">
                    <img className="img-fluid blog-img" src="https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_02.png" alt="Go to WikiReviews Herald" />
                  </a>
                </div>
              </Col>

              <Col md={8}>
              {!(movieDetail?.is_logged_user_review ?? true) && 
                <CreateReview
                  itemDetail={movieDetail}
                  overallRatingVote={overallRatingVote}
                  viewEditReviewStep={viewEditReviewStep}
                  mainViewType={this.state.mainViewType}
                  listing_type={'movies'}
                  reviews={reviews}
                  toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                    this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true, false);                                       
                }}
                  categoryId={movieId}
                  uploadedMediaSet={this.state.uploadedMediaSet}
                  removeUploadedMediaSet={this.removeUploadedMediaSet}
                  onPublishReview={this.onPublishReview}
                  my_review_count={my_profile?.review_statistics?.count}
                  forceToggleLoginModal={this.forceToggleLoginModal}
                  my_profile_data={this.props.my_profile}
                  resetUploadedMediaSet={this.resetUploadedMediaSet}
                />
                }

                <DetailReviews
                  uniqueDeleteKey={'delete-movies-review'}
                  uniqueDeleteCommentKey={'delete-movie-review-comment'}
                  overallRatingVote={overallRatingVote}
                  viewEditReviewStep={viewEditReviewStep}
                  onPublishReview={this.onPublishReview}
                  listing_type={'movies'}                 
                  reviews={reviews}
                  item_detail={this.props.movie_details}
                  uploadedMediaSet={uploadedMediaSet}
                  uploadedMediaSetFollow={uploadedMediaSetFollowUp}
                  removeUploadedMediaSet={this.removeUploadedMediaSet}
                  removeUploadedMediaSetFollow={this.removeUploadedMediaSetFollow}
                  toggleConfirmModal={this.toggleConfirmModal}
                  toggleEnableFileUpload={(hideEmbed, galleryTypeUpload,newReviewStatus, followUpStatus)=>{
                    this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus,followUpStatus);                   
                }}
                  getuploadUrl={this.getuploadUrl}
                  resetUploadedMediaSet={this.resetUploadedMediaSet}
                  getnocategory={this.getnocategory}
                  forceToggleLoginModal={this.forceToggleLoginModal}
                />
              </Col>

            </Row>
          </Container>
        </section >

        {/* Share By Email Modal Popup */}
        < Modal size="sm" isOpen={this.state.shareByEmailModalToggle} toggle={() => this.setState({
          shareByEmailModalToggle: !this.state.shareByEmailModalToggle
        })}>
          <ModalHeader className="text-uppercase px-0" toggle={() => this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle
          })}>
            Share by Email
                    </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input className="primary" bsSize="sm" type="text" name="sharer_name" placeholder="Your name" />
            </FormGroup>
            <FormGroup>
              <Input className="primary" bsSize="sm" type="email" name="email" placeholder="Recipient's email" />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="px-0">
            <div className="text-right">
              <Button color="primary">Share</Button>
            </div>
          </ModalFooter>
        </Modal >
        {/* See who reacted Modal */}
        < Modal size="lg" isOpen={this.state.viewPeopleWhoReactedModalToggle} toggle={() => this.setState({ viewPeopleWhoReactedModalToggle: !this.state.viewPeopleWhoReactedModalToggle })}>
          <ModalHeader toggle={() => this.setState({ viewPeopleWhoReactedModalToggle: !this.state.viewPeopleWhoReactedModalToggle })}></ModalHeader>
          <ModalBody>
            <Nav className="nav-fill active-transparent" tabs>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "all"}
                  onClick={() => {
                    this.handleOnClickReacted("all");
                  }}
                >
                  <div className="ff-base">
                    All&nbsp;<span>{reviewAll}</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "woohoo"}
                  onClick={() => {
                    this.handleOnClickReacted("woohoo");
                  }}
                >
                  <div className="ff-base">
                    Woohoo&nbsp;<span>{review_group_count &&
                      Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.woohoo ? review_group_count.review_group_count.woohoo : 0}</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "nailedIt"}
                  onClick={() => {
                    this.handleOnClickReacted("nailedIt");
                  }}
                >
                  <div className="ff-base">
                    Nailed It&nbsp;<span>{review_group_count &&
                      Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.nailed ? review_group_count.review_group_count.nailed : 0}</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "insightful"}
                  onClick={() => {
                    this.handleOnClickReacted("insightful");
                  }}
                >
                  <div className="ff-base">
                    Insightful&nbsp;<span>{review_group_count &&
                      Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.insightful ? review_group_count.review_group_count.insightful : 0}</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "lol"}
                  onClick={() => {
                    this.handleOnClickReacted("lol");
                  }}
                >
                  <div className="ff-base">
                    LOL&nbsp;<span>{review_group_count &&
                      Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.lol ? review_group_count.review_group_count.lol : 0}</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "bummer"}
                  onClick={() => {
                    this.handleOnClickReacted("bummer");
                  }}
                >
                  <div className="ff-base">
                    Bummer&nbsp;<span>{review_group_count &&
                      Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.bummer ? review_group_count.review_group_count.bummer : 0}</span>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.viewReactedType}>
              <TabPane tabId="all">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("all")}
                </ul>
              </TabPane>
              <TabPane tabId="woohoo">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("woohoo")}
                </ul>
              </TabPane>
              <TabPane tabId="nailedIt">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("nailed")}
                </ul>
              </TabPane>
              <TabPane tabId="insightful">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("insightful")}
                </ul>
              </TabPane>
              <TabPane tabId="lol">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("lol")}
                </ul>
              </TabPane>
              <TabPane tabId="bummer">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("bummer")}
                </ul>
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal >

        {/* Delete Confirmation Modal */}
        < Modal
          size="sm"
          isOpen={this.state.confirmDeleteModal}
          toggle={this.confirmDeleteModalToggle}
          className="text-center" modalClassName="backdrop-opaque top-shifted transition-none"
        >
          <div className="bg-white modal-header">
            <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
            <button
              className="btn btn-sm"
              aria-label="Close"
              onClick={this.confirmDeleteModalToggle}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <ModalBody className="text-dark">
            Are you sure you want to delete?
          </ModalBody>
          <ModalFooter className="bg-white">
            <div className="text-center w-100">
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={this.confirmDeleteModalToggle}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={this.handleOnDeleteConfirmation}
              >
                Yes
              </Button>
            </div>
          </ModalFooter>
        </Modal >

        {/* Upload Media Post Modal */}
        < Modal
          isOpen={this.state.uploadMediaPostModal}
          toggle={this.uploadMediaModalToggle}
        >
          <ModalHeader toggle={this.uploadMediaModalToggle}>
            UPLOAD MEDIA
          </ModalHeader>
          <ModalBody className="p-3">
            <Row>
              <Col xs={"auto"}>
                <div className="bg-light h-100 p-3">
                  <Nav tabs className="flex-column active-transparent border-0 m-0">
                    <NavItem className="text-left">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "upload"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("upload");
                        }}
                      >
                        Upload
                </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "gallery"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("gallery");
                        }}
                      >
                        Gallery
                </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "embed"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("embed");
                        }}
                      >
                        Embed
                </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col>
                <TabContent activeTab={this.state.selectedUpMediaType}>
                  <TabPane tabId="upload">
                    <div
                      className="mb-3 type-file-block bg-light"
                      hidden={
                        this.state.uploadMedia.uploadFiles &&
                          this.state.uploadMedia.uploadFiles.length > 0
                          ? true
                          : false
                      }
                    >
                      <Input
                        type="file"
                        name="upload_media_file"
                        id="uploadFilesPostFromSystem"
                        accept="image/*, video/*"
                        onChange={this.handleOnFilePostUploadChange}
                        multiple
                      />
                      <Label for="uploadFilesPostFromSystem">
                        <div className="type-bg"></div>
                      </Label>
                    </div>
                    {this.state.uploadMedia.uploadFiles.length > 0 && (
                      <Row className="mb-3" form>
                        <Col xs={12}>
                          <div
                            style={{
                              maxWidth: "120px",
                              margin: "0 1rem 1rem auto",
                            }}
                          >
                            <div className="text-center mb-1 small">
                              {this.state.uploadMedia.progress === 100 ? (
                                <div className="text-success">
                                  <FontAwesomeIcon
                                    icon="check-circle"
                                    className="mr-1"
                                  />{" "}
                                  Uploaded
                                </div>
                              ) : (
                                  <div>
                                    Uploading{" "}
                                    <span className="text-success font-weight-bold ff-base">
                                      {this.state.uploadMedia.progress.toFixed(0)}
                                    %
                                  </span>
                                  </div>
                                )}
                            </div>
                            <Progress
                              value={this.state.uploadMedia.progress}
                              style={{ height: "8px" }}
                            ></Progress>
                          </div>
                        </Col>
                        {this.state.uploadMedia.uploadFiles.map((file) => {
                          return (
                            <Col xs="auto">
                              <div className="d-flex pr-3">
                                <div>
                                  <div
                                    className="selectable-media"
                                    style={{ cursor: "default" }}
                                  >
                                    <div className="gallery-media">
                                      <img src={file.url} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="mx-n3 mt-2">
                                  <Button color="delete-media" size="sm" title="Remove Media"
                                    hidden={file.id === "" ? true : false}
                                    onClick={this.handleOnClickRemoveSelectedMedia(
                                      file.id
                                    )}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                        <Col xs="auto">
                          <div className="d-flex pt-3">
                            <div className="selectable-media" hidden>
                              <Label
                                for="uploadFilesFromSystemMini"
                                className="gallery-media"
                                style={{
                                  borderStyle: "dashed",
                                  cursor: "pointer",
                                }}
                              >
                                <div className="d-flex h-100 align-items-center justify-content-center">
                                  <span className="fs-14">Upload More</span>
                                </div>
                              </Label>
                              <Input
                                type="file"
                                name="upload_media_file"
                                id="uploadFilesFromSystemMini"
                                accept="image/*, video/*"
                                onChange={this.handleOnFilePostUploadChange}
                                multiple
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              uploadMediaPostModal: false,
                            });
                          }}
                        >
                          {'OK'}
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="gallery">
                    <div className="mb-2">
                      <Nav tabs className="d-inline-flex mb-0">
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "images"}
                            onClick={() => {
                              this.handleOnClickGalleryType("images");
                            }}
                          >
                            Image Gallery
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "videos"}
                            onClick={() => {
                              this.handleOnClickGalleryType("videos");
                            }}
                          >
                            Video Gallery
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.showGalleryType}>
                      <TabPane tabId="images">
                        <div>
                          <FormGroup className="form-row justify-content-end">
                            <Col xs="auto">
                              <Label className="small" for="selectAlbumType">
                                Album Type:
                              </Label>
                            </Col>
                            <Col xs="auto">
                              <Input
                                bsSize="sm"
                                type="select"
                                name="album_type_select"
                                id="selectAlbumType"
                                defaultValue={this.state.uploadMedia.albumType}
                                onChange={this.handleOnClickAlbumTypeChange}
                              >
                                <option value="">All</option>
                                {this.state.uploadMedia.albumTypesList.length >
                                  0 &&
                                  this.state.uploadMedia.albumTypesList.map(
                                    (type) => (
                                      <option value={type.category}>
                                        {type.category}
                                      </option>
                                    )
                                  )}
                              </Input>
                            </Col>
                          </FormGroup>
                        </div>
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryImageScroll}
                          onScroll={this.handleScrollGalleryImage}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>

                                {this.fetchScrollPhotoMedia()}
                              </Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No images</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {'OK'}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="videos">
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryVideoScroll}
                          onScroll={this.handleScrollGalleryVideo}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>
                                {this.fetchScrollPhotoMedia(true)}
                              </Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No videos</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {'OK'}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="embed">
                    <div className="text-muted mb-2">
                      Submit the link for the image or video you want to embed{" "}
                    </div>
                    <div className="mb-2">
                      {/* Repeat this while adding */}
                      {this.mapEmbedLinks()}
                    </div>
                    <div className="d-flex mx-n2 mb-2">
                      <div className="px-2 ml-auto">
                        <Button
                          title="Add"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            this.addEmbedlinkRow();
                          }}
                        >
                          <FontAwesomeIcon icon="plus" />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.insertEmbedLinks();
                          }}
                        >
                          Insert
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
        </Modal >



        {/* Dispute Review Modal */}
        {/* {this.state.disputeThisReviewModalToggle} */}
        <Modal
          size="lg"
          scrollable
          isOpen={this.state.disputeThisReviewModalToggle}
          toggle={() =>
            this.setState({
              disputeThisReviewModalToggle: !this.state
                .disputeThisReviewModalToggle,
            })
          }
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                disputeThisReviewModalToggle: !this.state
                  .disputeThisReviewModalToggle,
              })
            }
          >
            DISPUTE REVIEW
          </ModalHeader>
          <ModalBody className="bg-transparent">
            <div className="bg-white p-2 mb-3">
              <CollapseBasic
                title="Step 1. Reason for Dispute"
                noHr={true}
                containerClass="type-primary bg-transparent"
                bodyClass="mt-3"
                isOpen={true}
                size="sm"
              >
                <hr className="bg-light" />
                <div>
                  {/* For Community Users */}
                  <FormGroup row>
                    <Col sm={6}>
                      <Label className="text-dark font-weight-normal fs-14">
                        Choose why you are disputing this review
                      </Label>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="select"
                        className="primary"
                        bsSize="sm"
                        name="reason"
                        onChange={this.handleChange}
                        value={reason}
                      >
                        <option value="This review is fake">
                          This review is fake
                        </option>
                        <option value="This review is not relevant">
                          This review is not relevant
                        </option>
                        <option value="This review is inaccurate">
                          This review is inaccurate
                        </option>
                      </Input>
                    </Col>
                  </FormGroup>
                  {/* For Admins */}
                  <div>
                    <div className="fs-16 mb-2">Administrative Review</div>
                    <Row>
                      <Col className="mb-2" md={6}>
                        <FormGroup check>
                          <Label
                            className="font-weight-normal fs-14 text-dark"
                            check
                          >
                            <Input type="checkbox" /> Conflict of Interest
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col className="mb-2" md={6}>
                        <FormGroup check>
                          <Label
                            className="font-weight-normal fs-14 text-dark"
                            check
                          >
                            <Input type="checkbox" /> Contains threats of
                            violence
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col className="mb-2" md={6}>
                        <FormGroup check>
                          <Label
                            className="font-weight-normal fs-14 text-dark"
                            check
                          >
                            <Input type="checkbox" /> Spam
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </div>
              </CollapseBasic>
            </div>
            <div className="bg-white p-2 mb-3">
              <CollapseBasic
                title="Step 2. Evidence and Explanation"
                noHr={true}
                containerClass="type-primary bg-transparent"
                bodyClass="mt-3"
                isOpen={true}
                size="sm"
              >
                <hr className="bg-light" />
                <div>
                  <div className="mb-3">
                    <Label
                      for="disputed_review"
                      className="text-primary-dark fs-14"
                    >
                      {reason === 'This review is fake' ?

                        <span>
                          Provide evidence and explanation for disputing the review in
                          question
                      </span>
                        :
                        ''
                      }

                      {reason === 'This review is not relevant' ?
                        <span>
                          Provide evidence for disputing the review in question.
                          <br />
                          Uploading an explanation (optional)
                        </span>
                        :
                        ''
                      }

                      {reason === 'This review is inaccurate' ?
                        <span>
                          Provide an explanation for disputing the review in question.
                        <br />
                        Uploading evidence (optional)
                      </span>
                        :
                        ''
                      }
                    </Label>
                    <div>
                      <Input
                        type="textarea"
                        // className="text-primary-dark fs-14 font-weight-bold"
                        className="primary text-primary-dark fs-14 font-weight-bold"
                        bsSize="sm"
                        rows="4"
                        name="comment"
                        id="disputed_review"
                        onChange={this.handleChange}
                        value={comment}
                        placeholder="Provide explanation..."
                        required
                      />
                      {/* <div className="attachable-area">
                        <hr className="bg-primary mt-0" />
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <Input type="file" id="attach_files_modal" multiple style={{ display: 'none' }} />
                            <Label for="attach_files_modal" className="btn btn-sm btn-transparent" title="Attach Files">
                              <FontAwesomeIcon icon="paperclip" fixedWidth />
                            </Label>
                          </div>
                          <div className="px-1">
                            <Button color="transparent" size="sm" title="Upload Media">
                              <FontAwesomeIcon icon="camera" fixedWidth />
                            </Button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  {/* Attach file types documents */}
                  {/* <div className="mt-3">
                    <div className="fs-14 font-weight-bold mb-2">
                      Attached Files
                    </div>
                    <Row form>
                      <Col xs="auto" className="mb-3">
                        <div className="d-flex pr-3 pt-3">
                          <div>
                            <div className="bg-light p-2 text-truncate border border-dark" style={{ maxWidth: '200px' }}>
                              Filefleafejfke_123.pdf
                          </div>
                          </div>
                          <div className="mx-n3 mt-n3">
                            <Button color="dark" size="sm" title="Remove File">
                              <FontAwesomeIcon icon="minus" size="sm" />{" "}
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs="auto" className="mb-3">
                        <div className="d-flex pr-3 pt-3">
                          <div>
                            <div className="bg-light p-2 text-truncate border border-dark" style={{ maxWidth: '200px' }}>
                              Filefleafejfke_123.pdf
                          </div>
                          </div>
                          <div className="mx-n3 mt-n3">
                            <Button color="dark" size="sm" title="Remove File">
                              <FontAwesomeIcon icon="minus" size="sm" />{" "}
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs="auto" className="mb-3">
                        <div className="d-flex pr-3 pt-3">
                          <div>
                            <div className="bg-light p-2 text-truncate border border-dark" style={{ maxWidth: '200px' }}>
                              Filefleafejfke_123.pdf
                          </div>
                          </div>
                          <div className="mx-n3 mt-n3">
                            <Button color="dark" size="sm" title="Remove File">
                              <FontAwesomeIcon icon="minus" size="sm" />{" "}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div> */}

                  {/* Uploaded file type images/videos */}
                  {/* <div className="mt-3">
                    <div className="fs-14 font-weight-bold mb-2">
                      Uploaded Files
                    </div>
                    <Row form>
                      <Col xs="auto" className="mb-3">
                        <div className="d-flex pr-3 pt-3">
                          <div>
                            <div className="gallery-media">
                              <img
                                src={require("../../../assets/images/cvs-logo.jpg")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="mx-n3 mt-n3">
                            <Button color="dark" size="sm" title="Remove File">
                              <FontAwesomeIcon icon="minus" size="sm" />{" "}
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs="auto" className="mb-3">
                        <div className="d-flex pr-3 pt-3">
                          <div>
                            <div className="gallery-media">
                              <img
                                src={require("../../../assets/images/cvs-logo.jpg")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="mx-n3 mt-n3">
                            <Button color="dark" size="sm" title="Remove File">
                              <FontAwesomeIcon icon="minus" size="sm" />{" "}
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xs="auto" className="mb-3">
                        <div className="d-flex pr-3 pt-3">
                          <div>
                            <div className="gallery-media">
                              <img
                                src={require("../../../assets/images/cvs-logo.jpg")}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="mx-n3 mt-n3">
                            <Button color="dark" size="sm" title="Remove File">
                              <FontAwesomeIcon icon="minus" size="sm" />{" "}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                 */}
                </div>
              </CollapseBasic>
            </div>

            <div className="bg-white p-2 mb-3">
              <CollapseBasic
                title="Step 3. Upload Images and/or Video (optional)"
                noHr={true}
                containerClass="type-primary bg-transparent"
                bodyClass="mt-3"
                isOpen={true}
                size="sm"
              >
                <hr className="bg-light" />
                {/* <div className="mb-3">
                  <p className="text-dark font-weight-normal fs-14">
                    Upload an image or video to accompany your review. Additional images/videos can be added later.
                  </p>
                  <Button color="tertiary" className="bg-tertiary border-tertiary">Upload Media</Button>
                </div> */}

                {/* Dispute review uplaod files*/}
                <div className="mb-3 mt-3">
                  {/* <input type="file" onChange={this.handleImageChange} multiple color="dark"/> */}

                  <input
                    type="file"
                    name="imageUpload"
                    id="imageUpload"
                    style={{ display: "None" }}
                    onChange={this.handleImageChange}
                    multiple
                  />
                  <label
                    for="imageUpload"
                    className="btn btn-tertiary bg-tertiary border-tertiary"
                  >
                    Upload Media
                  </label>
                </div>

                {/* Uploaded media shown here */}
                <div className="border mb-3">
                  <Row xs={2} sm={3} lg={4} noGutters>
                    {this.state.dispute_imgepreview.map((image, index) => (
                      <Col>
                        <div
                          className="d-flex pr-3 m-2"
                          role="button"
                          onClick={() =>
                            this.setState({ viewMyPhotosModal: true })
                          }
                        >
                          <div className="flex-grow-1 mw-100">
                            {image.toString().includes("data:image") ? (
                              <img
                                className="img-fluid img-thumbnail"
                                src={image}
                                alt=""
                                title="Image Name"
                              />
                            ) : (
                                <div
                                  className="bg-light p-2 text-truncate border fs-13"
                                  title={
                                    this.state.dispute_files &&
                                    this.state.dispute_files[index] &&
                                    this.state.dispute_files[index].name
                                  }
                                >
                                  <img
                                    width={24}
                                    className="mr-2"
                                    src={
                                      "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/file_icon.png"
                                    }
                                    alt="Icon"
                                  />{" "}
                                  {this.state.dispute_files &&
                                    this.state.dispute_files[index] &&
                                    this.state.dispute_files[index].name}
                                </div>
                              )}
                          </div>
                          <div className="mx-n3 mt-2">
                            <Button
                              color="delete-media"
                              size="sm"
                              title="Remove"
                              onClick={() => this.deleteImage(index)}
                            >
                              <FontAwesomeIcon icon="trash-alt" size="sm" />
                            </Button>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>

                <div className="mt-2">
                  <div className="d-flex justify-content-end mx-n2">
                    <div className="px-2 mb-2">
                      <Button
                        color="grey"
                        className="mw"
                        onClick={() =>
                          this.setState({ disputeThisReviewModalToggle: false })
                        }
                      >
                        Cancel Dispute
                      </Button>
                    </div>
                    <div className="px-2 mb-2">
                      <Button color="tertiary" className="mw">
                        Save Review Dispute Draft
                      </Button>
                    </div>
                    <div className="px-2 mb-2">
                      <Button
                        color="primary"
                        className="mw"
                        onClick={this.handleDisputeSubmit}
                      >
                        Submit Review Dispute
                      </Button>
                    </div>
                  </div>
                </div>
              </CollapseBasic>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          // isOpen={this.state.voteReviewModalToggleOne}
          // isOpen={true}
          toggle={() =>
            this.setState({
              voteReviewModalToggleOne: !this.state.voteReviewModalToggleOne,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        voteReviewModalToggleOne: !this.state
                          .voteReviewModalToggleOne,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs={12} lg="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-tertiary text-white text-center px-5 py-4 h-100"
                    >
                      <div>
                        <img
                          // src={require("../../../assets/images/icons/star/blue/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">
                          REVIEW DISPUTE
                        </h2>
                        <img
                          // src={require("../../../assets/images/icons/star/blue/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className="col-lg">
                    <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            <h3 className="text-light">
                              Business Owner initiated.
                            </h3>
                            <div className="fs-14">
                              <div className="text-primary fs-16 font-weight-bold">
                                Step 1. Lorem Ipsum.
                              </div>
                              <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md="auto" className="align-self-end">
                          <div className="text-light font-weight-bold">
                            Submission time ends in
                          </div>
                          <div className="d-flex mx-n2 flex-nowrap">
                            <div className="px-2">
                              <div className="text-white">
                                <div className="fs-32 ff-headings">7</div>
                                <div className="font-weight-bold">days</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  15
                                </div>
                                <div className="font-weight-normal">hours</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  32
                                </div>
                                <div className="font-weight-normal">
                                  minutes
                                </div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  56
                                </div>
                                <div className="font-weight-normal">
                                  seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="body-bg px-3 py-3">
                <Row>
                  <Col>
                    In case you don't know the correct address, try looking for
                    it with our search bar.
                  </Col>
                  <Col xs="auto">
                    <div className="d-flex">
                      <Input
                        className="primary"
                        bsSize="sm"
                        type="search"
                        placeholder="Custom Search"
                        style={{ minWidth: "300px" }}
                      ></Input>
                      <Button color="primary" size="sm" className="ml-3">
                        <FontAwesomeIcon icon="search" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16">
                      Dispute History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">
                              <Moment format="DD-MM-YYYY">
                                {get_dispute_data &&
                                  get_dispute_data[0] &&
                                  get_dispute_data[0].created_on}
                              </Moment>
                              &nbsp; at &nbsp;
                              <Moment format="HH:MM:SS">
                                {get_dispute_data &&
                                  get_dispute_data[0] &&
                                  get_dispute_data[0].created_on}
                              </Moment>
                            </div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Sau6402
                            </a>
                            &nbsp; added a Punctuation in Tips.
                          </div>
                        </div>
                      </li>
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Danielbauwens
                            </a>
                            &nbsp; reverted edits by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Fart biscuit
                            </a>
                            &nbsp; changed back to last version by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Anna
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="main-post-formgroup">
                          <div className="text-primary fs-16 font-weight-bold">
                            Step 2. Write the points of your dispute
                          </div>
                          <div className="input-label-block">
                            <Input
                              className="primary"
                              type="textarea"
                              rows="4"
                              placeholder="&nbsp;"
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <div className="mt-4">
                          <FormGroup>
                            <div className="text-tertiary font-weight-bold mb-2">
                              Submit proofs
                            </div>
                            <CustomInput bsSize="sm" type="file" />
                          </FormGroup>
                          <div className="text-right">
                            <div className="text-tertiary">
                              or{" "}
                              <span className="font-weight-bold" role="button">
                                embed link
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <hr />
                    <div className="text-right">
                      <Button
                        color="link"
                        className="text-primary p-0 font-weight-bold"
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon="plus"
                          size="sm"
                        />
                        add another
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <Button color="primary">Submit</Button>
              <Button
                color="link"
                className="text-tertiary font-weight-bold"
                onClick={() =>
                  this.setState({ voteReviewModalToggleOne: false })
                }
              >
                Skip
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={this.state.voteReviewModalToggleTwo}
          toggle={() =>
            this.setState({
              voteReviewModalToggleTwo: !this.state.voteReviewModalToggleTwo,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        voteReviewModalToggleTwo: !this.state
                          .voteReviewModalToggleTwo,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
                <div className="bg-dark text-white fs-14 px-3">
                  <div className="mb-2 pt-2">
                    <div className="d-flex mx-n2">
                      <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            className="text-white bg-transparent shadow-none font-weight-bold"
                            color="transparent"
                            size="sm"
                            caret
                          >
                            <span className="pr-2">Disputed Reviews</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="fs-14">
                              Disputed Reviews
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="px-2 col-auto">
                        near{" "}
                        <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled d-flex flex-wrap mx-n2">
                    <li className="px-2">
                      <div className="d-flex">
                        <FormGroup>
                          <Input
                            type="select"
                            className="transparent"
                            size="sm"
                          >
                            <option>Businesses</option>
                          </Input>
                        </FormGroup>
                        <div className="ml-2">
                          <span className="text-danger">*</span>
                        </div>
                      </div>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>IT Services</option>
                          <option>Apartment Rentals</option>
                          <option>Automotive</option>
                          <option>Education</option>
                          <option>Beauty and Spas</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Asian</option>
                          <option>European</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Indian</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Level 6</option>
                        </Input>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                      style={{ minWidth: "300px" }}
                    >
                      <div>
                        <img
                          // src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                        <img
                          // src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            <h3 className="text-light">
                              Community Initiated dispute.
                            </h3>
                            <div className="fs-14">
                              <div className="text-primary fs-16 font-weight-bold">
                                Step 1. Your vote is needed.
                              </div>
                              <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md="auto" className="align-self-end">
                          <div className="text-light font-weight-bold">
                            Submission time ends in
                          </div>
                          <div className="d-flex mx-n2 flex-nowrap">
                            <div className="px-2">
                              <div className="text-white">
                                <div className="fs-32 ff-headings">7</div>
                                <div className="font-weight-bold">days</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  15
                                </div>
                                <div className="font-weight-normal">hours</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  32
                                </div>
                                <div className="font-weight-normal">
                                  minutes
                                </div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  56
                                </div>
                                <div className="font-weight-normal">
                                  seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="body-bg px-3 py-3">
                <Row>
                  <Col>
                    In case you don't know the correct address, try looking for
                    it with our search bar.
                  </Col>
                  <Col xs="auto">
                    <div className="d-flex">
                      <Input
                        className="primary"
                        bsSize="sm"
                        type="search"
                        placeholder="Custom Search"
                        style={{ minWidth: "300px" }}
                      ></Input>
                      <Button color="primary" size="sm" className="ml-3">
                        <FontAwesomeIcon icon="search" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16">
                      Dispute History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Sau6402
                            </a>
                            &nbsp; added a Punctuation in Tips.
                          </div>
                        </div>
                      </li>
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Danielbauwens
                            </a>
                            &nbsp; reverted edits by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Fart biscuit
                            </a>
                            &nbsp; changed back to last version by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Anna
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col md={8}>
                    <div className="mb-3">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 2. Some Copy Line here
                      </div>
                      <div>
                        <div className="bg-light p-2 text-dark mb-2">
                          <div className="text-tertiary">
                            Flagged for <strong>Reason Something</strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>User's-Name</strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-light p-2 text-dark mb-2">
                          <div className="text-tertiary">
                            Flagged for{" "}
                            <strong>Another Reason Something</strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>NameNameName</strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago in Chicago, IL
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-light p-2 text-dark mb-2">
                          <div className="text-tertiary">
                            Flagged for{" "}
                            <strong>Yet Another Reason Something</strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>Messi10</strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via Android
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-tertiary fs-16 font-weight-bold mt-4">
                        Discussion between parties
                      </div>
                      <hr />
                      <div className="text-center mb-2">
                        <div className="text-dark">Dec 16, 2013</div>
                      </div>
                      <div>
                        <div className="p-2 text-dark">
                          <div className="mb-2">
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _30x30"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-3">
                                  <div className="d-flex mx-n2 mb-1">
                                    <div className="px-2 col">
                                      <a href="#" className="text-reset">
                                        <strong>User Name</strong>
                                      </a>
                                      &nbsp;
                                      <span className="fs-12 text-muted">
                                        Business Owner
                                      </span>
                                    </div>
                                    <div className="col-auto">
                                      <div className="text-tertiary">
                                        Dec 16, 2013, 15:41
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Voluptate, incidunt iste,
                                    obcaecati nostrum reprehenderit veniam et
                                    saepe reiciendis voluptatibus tenetur a.
                                    Porro debitis consectetur iste saepe ipsam
                                    libero reprehenderit sed!
                                  </div>
                                </div>
                                <div className="bg-light p-3">
                                  <div className="d-flex mx-n2">
                                    <div className="px-2">
                                      <img
                                        src="https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png"
                                        alt=""
                                      />
                                    </div>
                                    <div className="px-2">
                                      <div className="mb-2">
                                        <div className="mb-2">
                                          <a
                                            href="#"
                                            className="text-dark font-weight-bold"
                                          >
                                            www.website.com
                                          </a>
                                        </div>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-2">
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _30x30"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-3">
                                  <div className="d-flex mx-n2 mb-1">
                                    <div className="px-2 col">
                                      <a href="#" className="text-reset">
                                        <strong>User Name</strong>
                                      </a>
                                      &nbsp;
                                      <span className="fs-12 text-muted">
                                        Expert Reviewer
                                      </span>
                                    </div>
                                    <div className="col-auto">
                                      <div className="text-tertiary">
                                        Dec 16, 2013, 15:50
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Voluptate, incidunt iste,
                                    obcaecati nostrum reprehenderit veniam et
                                    saepe reiciendis voluptatibus tenetur a.
                                    Porro debitis consectetur iste saepe ipsam
                                    libero reprehenderit sed!
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-light px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="bg-dark text-white text-center p-3 bg-vote-alt">
                      <div className="my-2 ff-headings fs-50">CAST</div>
                      <img
                        className="my-2"
                        // src={require("../../../assets/images/icons/star/white/fill.png")}
                        alt=""
                      />
                      <div className="my-2 ff-headings fs-50">YOUR</div>
                      <img
                        className="my-2"
                        // src={require("../../../assets/images/icons/star/white/fill.png")}
                        alt=""
                      />
                      <div className="my-2 ff-headings fs-50">VOTE</div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div>
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 3. Some copy line
                      </div>
                      <div>
                        <div className="bg-white p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">
                                    Fake, remove the review.
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">25%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      5 votes out of 12
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Expand"
                              >
                                <FontAwesomeIcon icon="plus" />{" "}
                              </Button>
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Collapse"
                              >
                                <FontAwesomeIcon icon="minus" />{" "}
                              </Button>
                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                        <div className="bg-white p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">
                                    Review is valid, leave it.
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">25%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      5 votes out of 12
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Expand"
                              >
                                <FontAwesomeIcon icon="plus" />{" "}
                              </Button>
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Collapse"
                              >
                                <FontAwesomeIcon icon="minus" />{" "}
                              </Button>
                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                        <div className="bg-primary text-white p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">
                                    Move to Non-Recommended.
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">25%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      5 votes out of 12
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Expand"
                              >
                                <FontAwesomeIcon icon="plus" />{" "}
                              </Button>
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Collapse"
                              >
                                <FontAwesomeIcon icon="minus" />{" "}
                              </Button>
                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 4. Please, tell us why.
                      </div>
                      <div className="text-dark">
                        It would be really helpful for fellow voters to know the
                        reasoning of your decision. It has to be 140 characters
                        long. Some text like that.
                      </div>
                      <div className="text-right text-primary fs-14">65</div>
                      <Input
                        type="textarea"
                        className="primary"
                        rows="3"
                        defaultValue="The Info is accurate, I've been there today."
                        placeholder="Please elaborate..."
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <Button color="primary">Submit</Button>
              <Button color="link" className="text-tertiary font-weight-bold">
                Skip
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={this.state.voteReviewModalToggleThree}
          toggle={() =>
            this.setState({
              voteReviewModalToggleThree: !this.state
                .voteReviewModalToggleThree,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        voteReviewModalToggleThree: !this.state
                          .voteReviewModalToggleThree,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
                <div className="bg-dark text-white fs-14 px-3">
                  <div className="mb-2 pt-2">
                    <div className="d-flex mx-n2">
                      <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            className="text-white bg-transparent shadow-none font-weight-bold"
                            color="transparent"
                            size="sm"
                            caret
                          >
                            <span className="pr-2">Disputed Reviews</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="fs-14">
                              Disputed Reviews
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="px-2 col-auto">
                        near{" "}
                        <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled d-flex flex-wrap mx-n2">
                    <li className="px-2">
                      <div className="d-flex">
                        <FormGroup>
                          <Input
                            type="select"
                            className="transparent"
                            size="sm"
                          >
                            <option>Businesses</option>
                          </Input>
                        </FormGroup>
                        <div className="ml-2">
                          <span className="text-danger">*</span>
                        </div>
                      </div>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>IT Services</option>
                          <option>Apartment Rentals</option>
                          <option>Automotive</option>
                          <option>Education</option>
                          <option>Beauty and Spas</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Asian</option>
                          <option>European</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Indian</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Level 6</option>
                        </Input>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                      style={{ minWidth: "300px" }}
                    >
                      <div>
                        <img
                          // src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                        <img
                          // src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            <h3 className="text-light">
                              Business Owner dispute.
                            </h3>
                            <div className="fs-14">
                              <div className="text-primary fs-16 font-weight-bold">
                                Step 1. Your vote is needed.
                              </div>
                              <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md="auto" className="align-self-end">
                          <div className="text-light font-weight-bold">
                            Submission time ends in
                          </div>
                          <div className="d-flex mx-n2 flex-nowrap">
                            <div className="px-2">
                              <div className="text-white">
                                <div className="fs-32 ff-headings">7</div>
                                <div className="font-weight-bold">days</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  15
                                </div>
                                <div className="font-weight-normal">hours</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  32
                                </div>
                                <div className="font-weight-normal">
                                  minutes
                                </div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  56
                                </div>
                                <div className="font-weight-normal">
                                  seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="body-bg px-3 py-3">
                <Row>
                  <Col>
                    In case you don't know the correct address, try looking for
                    it with our search bar.
                  </Col>
                  <Col xs="auto">
                    <div className="d-flex">
                      <Input
                        className="primary"
                        bsSize="sm"
                        type="search"
                        placeholder="Custom Search"
                        style={{ minWidth: "300px" }}
                      ></Input>
                      <Button color="primary" size="sm" className="ml-3">
                        <FontAwesomeIcon icon="search" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16 text-primary">
                      Step 2. Voting History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Sau6402
                            </a>
                            &nbsp; added a Punctuation in Tips.
                          </div>
                        </div>
                      </li>
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Danielbauwens
                            </a>
                            &nbsp; reverted edits by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Fart biscuit
                            </a>
                            &nbsp; changed back to last version by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Anna
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col md={8}>
                    <div className="mb-3">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 3. Review Lorem Ipsum
                      </div>
                      <div>
                        <Row>
                          <Col lg={6}>
                            <div className="bg-white text-center text-tertiary p-3">
                              <div className="mb-2">
                                <img
                                  className="img-circle _50x50"
                                  // src={require("../../../assets/images/icons/user-circle.png")}
                                  alt=""
                                />
                              </div>
                              <div className="font-weight-bold fs-18">
                                User John
                              </div>
                              <div className="text-tertiary">
                                a.k.a. The Reviewer
                              </div>
                              <div className="mt-3">
                                {/* Repeat this */}
                                <div className="mb-4">
                                  <div className="ff-alt">
                                    <FontAwesomeIcon
                                      icon="quote-left"
                                      size="lg"
                                    />
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Aut facilis nesciunt odio
                                    illum debitis vitae corporis suscipit
                                    molestias porro aperiam.
                                    <FontAwesomeIcon
                                      icon="quote-right"
                                      size="lg"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <a
                                      href="#"
                                      className="text-reset font-weight-bold"
                                    >
                                      letterpfintent.doc
                                    </a>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <div className="ff-alt">
                                    <FontAwesomeIcon
                                      icon="quote-left"
                                      size="lg"
                                    />
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Aut facilis nesciunt odio
                                    illum debitis vitae corporis suscipit
                                    molestias porro aperiam.
                                    <FontAwesomeIcon
                                      icon="quote-right"
                                      size="lg"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <a
                                      href="#"
                                      className="text-reset font-weight-bold"
                                    >
                                      supporting.doc
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="bg-light text-center text-tertiary p-3">
                              <div className="mb-2">
                                <img
                                  className="img-circle _50x50"
                                  // src={require("../../../assets/images/icons/user-circle.png")}
                                  alt=""
                                />
                              </div>
                              <div className="font-weight-bold fs-18">
                                Business Owner
                              </div>
                              <div className="text-tertiary">
                                a.k.a. The Business Owner
                              </div>
                              <div className="mt-3">
                                {/* Repeat this */}
                                <div className="mb-4">
                                  <div className="ff-alt">
                                    <FontAwesomeIcon
                                      icon="quote-left"
                                      size="lg"
                                    />
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Aut facilis nesciunt odio
                                    illum debitis vitae corporis suscipit
                                    molestias porro aperiam.
                                    <FontAwesomeIcon
                                      icon="quote-right"
                                      size="lg"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <span>no proofs submitten</span>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <div className="ff-alt">
                                    <FontAwesomeIcon
                                      icon="quote-left"
                                      size="lg"
                                    />
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Aut facilis nesciunt odio
                                    illum debitis vitae corporis suscipit
                                    molestias porro aperiam.
                                    <FontAwesomeIcon
                                      icon="quote-right"
                                      size="lg"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <a
                                      href="#"
                                      className="text-reset font-weight-bold"
                                    >
                                      proof-of-some-type.pdf
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>&nbsp;</Col>
                  <Col md={8}>
                    <div>
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 4. Does the Business Owner have sufficient evidence
                        to support any of the following:
                        <div className="fs-13 text-dark font-weight-normal">
                          Please put as much info (images, video, documents
                          etc.) as possible to support your statements.
                        </div>
                      </div>
                      <div>
                        <div className="bg-light p-3 mb-1">
                          <div className="d-flex mx-n2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">
                                    The review is inaccurate
                                  </div>
                                  <div className="ff-alt">
                                    Must have evidence.
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className="bg-light p-3 mb-1">
                          <div className="d-flex mx-n2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">Review is fake.</div>
                                  <div className="ff-alt">
                                    Must explain why or have some type of
                                    evidence.
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className="bg-light p-3 mb-1">
                          <div className="d-flex mx-n2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">
                                    Review is Not Relevant.
                                  </div>
                                  <div className="ff-alt">
                                    Must explain why.
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                        <div className="bg-light p-3 mb-1">
                          <div className="d-flex mx-n2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">
                                    Do not dispute review.
                                  </div>
                                  <div className="ff-alt">
                                    The dispute does not sufficiently meet any
                                    of the first three criteria
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 5. Please, tell us why.
                      </div>
                      <div className="text-dark">
                        It would be really helpful for fellow voters to know the
                        reasoning of your decision. It has to be 140 characters
                        long. Some text like that.
                      </div>
                      <div className="text-right text-primary fs-14">65</div>
                      <Input
                        type="textarea"
                        className="primary"
                        rows="3"
                        defaultValue="The Info is accurate, I've been there today."
                        placeholder="Please elaborate..."
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <Button color="primary">Submit</Button>
              <Button color="link" className="text-tertiary font-weight-bold">
                Skip
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        {/* Discussion and reply against dispute by community user */}
        <Modal
          size="xl"
          // scrollable
          className="drawer-modal"
          isOpen={this.state.voteReviewModalToggleFour}
          // isOpen = {true}
          toggle={() =>
            this.setState({
              voteReviewModalToggleFour: !this.state.voteReviewModalToggleFour,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        voteReviewModalToggleFour: !this.state
                          .voteReviewModalToggleFour,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs={12} lg="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-tertiary text-white text-center px-5 py-4 h-100"
                    >
                      <div>
                        <img
                          src={require("../../assets/images/icons/star/blue/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">
                          REVIEW DISPUTE
                        </h2>
                        <img
                          src={require("../../assets/images/icons/star/blue/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className="col-lg">
                    <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            {get_dispute_data && get_dispute_data[0] &&
                              get_dispute_data[0].is_business_owner ?
                              <h3 className="text-light">
                                Business Owner Initiated.
                                </h3>
                              :
                              <h3 className="text-light">
                                Community Initiated.
                                </h3>
                            }
                            <div className="fs-14">
                              <div className="text-primary fs-16 font-weight-bold">
                                Step 1. Lorem Ipsum.
                              </div>
                              <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md="auto" className="align-self-end">
                          <div className="text-light font-weight-bold">
                            Submission time ends in
                          </div>
                          <div className="d-flex mx-n2 flex-nowrap">
                            <div className="px-2">
                              <div className="text-white">
                                <div className="fs-32 ff-headings"> {this.state.days}</div>
                                <div className="font-weight-bold">days</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  {this.state.hours}
                                </div>
                                <div className="font-weight-normal">hours</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  {this.state.minutes}
                                </div>
                                <div className="font-weight-normal">
                                  minutes
                                </div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  {this.state.seconds}
                                </div>
                                <div className="font-weight-normal">
                                  seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="body-bg px-3 py-3">
                <Row>
                  <Col>
                    In case you don't know the correct address, try looking for
                    it with our search bar.
                  </Col>
                  <Col xs="auto">
                    <div className="d-flex">
                      <Input
                        className="primary"
                        bsSize="sm"
                        type="search"
                        placeholder="Custom Search"
                        style={{ minWidth: "300px" }}
                      ></Input>
                      <Button color="primary" size="sm" className="ml-3">
                        <FontAwesomeIcon icon="search" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16">
                      Dispute History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">
                              <Moment format="DD/MM/YYYY">
                                {get_dispute_data &&
                                  get_dispute_data[0] &&
                                  get_dispute_data[0].created_on}
                              </Moment>
                            </div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              {get_dispute_data[0] &&
                                get_dispute_data[0].user_name}
                            </a>
                            &nbsp; disputed as " {get_dispute_data[0] &&
                              get_dispute_data[0].flag_name} "
                          </div>
                        </div>
                      </li>

                      {/* repeat this for dispute discussion */}
                      {get_dispute_data && get_dispute_data.map((discussion) => (

                        <li className="py-2 border-bottom">
                          { discussion.child_key_name === 1 ||
                            discussion.child_key_name === '1'
                            ?
                            <div className="d-flex mx-n1">
                              <div className="px-1">
                                <div className="ff-alt">
                                  <Moment format="DD/MM/YYYY">
                                    {discussion &&
                                      discussion.created_on}
                                  </Moment>
                                </div>
                              </div>
                              <div className="px-1">
                                <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                                >
                                  {discussion &&
                                    discussion.user_name}
                                </a>
                            &nbsp; reverted edits by{" "}
                                {/* <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Fart biscuit
                            </a>
                            &nbsp; changed back to last version by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Anna
                            </a> */}"
                            {discussion &&
                                  discussion.content_flagged}
                                  "
                          </div>
                            </div>
                            :
                            ''
                          }

                        </li>
                      ))}


                    </ul>
                  </Col>
                  <Col md={8}>
                    <div className="mb-3">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 2. Some Copy Line here
                      </div>
                      <div>
                        <div className="bg-light p-2 text-dark mb-2">
                          <div className="text-tertiary">
                            Flagged for
                            <strong> " {get_dispute_data &&
                              get_dispute_data[0] &&
                              get_dispute_data[0].flag_name} " </strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>{get_dispute_data &&
                                      get_dispute_data[0] &&
                                      get_dispute_data[0].user_name}</strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    Created on&nbsp;
                                    <span className="text-reset">
                                      {/* {discussion.created_on} */}
                                      <Moment format="MMM DD,YYYY">
                                        {get_dispute_data && get_dispute_data[0] &&
                                          get_dispute_data[0].created_on}
                                      </Moment>
                                      &nbsp; , &nbsp;
                                      <Moment format="HH:MM">
                                        {get_dispute_data && get_dispute_data[0] && get_dispute_data[0].created_on}
                                      </Moment>
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  " {get_dispute_data &&
                                    get_dispute_data[0] &&
                                    get_dispute_data[0].content_flagged} "
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="bg-light p-2 text-dark mb-2">
                          <div className="text-tertiary">
                            Flagged for{" "}
                            <strong>Another Reason Something</strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>NameNameName</strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago in Chicago, IL
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-light p-2 text-dark mb-2"> 
                          <div className="text-tertiary">
                            Flagged for{" "}
                            <strong>Yet Another Reason Something</strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>Messi10</strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via Android
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>*/}
                      </div>
                      <div className="text-tertiary fs-16 font-weight-bold mt-4">
                        Discussion between parties
                      </div>
                      <hr className="mt-2" />
                      {/* <div className="text-center mb-2">
                        <div className="text-dark">Dec 16, 2013</div>
                      </div> */}
                      <div>
                        <div className="p-2 text-dark">
                          {get_dispute_data && get_dispute_data.map((discussion) => (
                            <div className="mb-3">
                              { discussion.child_key_name === 1 ||
                                discussion.child_key_name === '1'
                                ?
                                <div className="d-flex mx-n2">
                                  <div className="px-2">
                                    <img
                                      className="img-circle _30x30"
                                      src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="px-2 col">
                                    <div className="mb-3">
                                      <div className="d-flex mx-n2 mb-1">
                                        <div className="px-2 col">
                                          <a href="#" className="text-reset">
                                            <strong>{discussion.user_name}</strong>
                                          </a>
                                      &nbsp;
                                      {/* <span className="fs-12 text-muted">
                                        Business Owner
                                      </span> */}
                                        </div>
                                        <div className="col-auto">
                                          <div className="text-tertiary">
                                            <span className="text-reset">
                                              <Moment format="MMM DD,YYYY">
                                                {discussion.created_on}
                                              </Moment>
                                          &nbsp; , &nbsp;
                                          <Moment format="HH:MM">
                                                {discussion.created_on}
                                              </Moment>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        {discussion.content_flagged}
                                      </div>
                                    </div>
                                    <div className="bg-light p-3">
                                      <div className="mb-2">
                                        {/* <div className="mb-2">
                                          <a
                                            href="#"
                                            className="text-dark font-weight-bold"
                                          >
                                            www.website.com
                                          </a>
                                        </div>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. */}
                                        {discussion && discussion.media.map((media) =>
                                          <div>
                                            {media.includes('.jpeg') || media.includes('.jpg') || media.includes('.png')
                                              ?
                                              <div className="mb-2">
                                                <img className="img-fluid" src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} alt="" />
                                              </div>
                                              :
                                              <div className="mb-2 d-inline-block">
                                                <div className="d-flex mx-n2">
                                                  <div className="px-2">
                                                    <FontAwesomeIcon icon="paperclip" fixedWidth />
                                                  </div>
                                                  <div className="px-2 col">
                                                    <span className="font-weight-bold text-truncate">
                                                      <a className="text-reset font-weight-bold" rel="noopener noreferrer" href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} target="_blank">{media}</a>
                                                    </span>
                                                  </div>
                                                  <div className="px-2 ml-auto">
                                                    <span className="mr-2">|</span>
                                                    <a href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} rel="noopener noreferrer" target="_blank" className="text-reset">
                                                      <FontAwesomeIcon icon="external-link-alt" />
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            }
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                ''
                              }
                            </div>
                          ))}
                          {/* <div className="mb-2">
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _30x30"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-3">
                                  <div className="d-flex mx-n2 mb-1">
                                    <div className="px-2 col">
                                      <a href="#" className="text-reset">
                                        <strong>User Name</strong>
                                      </a>
                                      &nbsp;
                                      <span className="fs-12 text-muted">
                                        Expert Reviewer
                                      </span>
                                    </div>
                                    <div className="col-auto">
                                      <div className="text-tertiary">
                                        Dec 16, 2013, 15:50
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Voluptate, incidunt iste,
                                    obcaecati nostrum reprehenderit veniam et
                                    saepe reiciendis voluptatibus tenetur a.
                                    Porro debitis consectetur iste saepe ipsam
                                    libero reprehenderit sed!
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-2">
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _30x30"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-3">
                                  <div className="d-flex mx-n2 mb-1">
                                    <div className="px-2 col">
                                      <a href="#" className="text-reset">
                                        <strong>User Name</strong>
                                      </a>
                                      &nbsp;
                                      <span className="fs-12 text-muted">
                                        Business Owner
                                      </span>
                                    </div>
                                    <div className="col-auto">
                                      <div className="text-tertiary">
                                        Dec 16, 2013, 16:01
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Dolorum, esse perferendis?
                                    Ipsam tempore minus fugiat.
                                  </div>
                                  <div className="mt-3">
                                    <img
                                      className="img-fluid"
                                      src="https://source.unsplash.com/random/400x300"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        */}
                        </div>
                        <hr />
                        <div>
                          <div className="attachable-container">
                            <Input
                              type="textarea"
                              className="primary"
                              bsSize="sm"
                              rows="3"
                              onChange={this.handleReplyChange}
                              value={dispute_reply_text}
                              placeholder="Provide explanation..."
                            />
                            <div className="attachable-area">
                              <hr className="bg-primary mt-0 mb-2" />
                              <div className="d-flex mx-n1">
                                <div className="px-1">
                                  {/* <Input
                                    type="file"
                                    id="attach_files_modal"
                                    multiple
                                    style={{ display: "none" }}
                                  />
                                  <Label
                                    for="attach_files_modal"
                                    className="btn btn-sm btn-transparent"
                                    title="Attach Files"
                                  >
                                    <FontAwesomeIcon
                                      icon="paperclip"
                                      fixedWidth
                                    />
                                  </Label> */}
                                  <input type="file" name="imageUpload"
                                    id="imageUpload"
                                    style={{ 'display': 'None' }}
                                    onChange={this.handleImageChange}
                                    multiple

                                  />
                                  <label for="imageUpload"
                                    title="Upload"
                                    className="text-primary"
                                    role="button"
                                  >
                                    <FontAwesomeIcon icon="camera" />
                                  </label>
                                </div>
                                {/* <div className="px-1">
                                  <Button
                                    color="transparent"
                                    size="sm"
                                    title="Upload Media"
                                  >
                                    <FontAwesomeIcon icon="camera" fixedWidth />
                                  </Button>
                                </div> */}
                              </div>
                            </div>
                          </div>

                          {/* Attach file types documents */}
                          <div className="mt-3">
                            {/* <div className="fs-14 font-weight-bold mb-2">
                              Attached Files
                            </div>
                            <Row form>
                              <Col xs="auto" className="mb-3">
                                <div className="d-flex pr-3 pt-3">
                                  <div>
                                    <div
                                      className="bg-light p-2 text-truncate border border-dark"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      Filefleafejfke_123.pdf
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-n3">
                                    <Button
                                      color="dark"
                                      size="sm"
                                      title="Remove File"
                                    >
                                      <FontAwesomeIcon icon="minus" />{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                              <Col xs="auto" className="mb-3">
                                <div className="d-flex pr-3 pt-3">
                                  <div>
                                    <div
                                      className="bg-light p-2 text-truncate border border-dark"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      Filefleafejfke_123.pdf
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-n3">
                                    <Button
                                      color="dark"
                                      size="sm"
                                      title="Remove File"
                                    >
                                      <FontAwesomeIcon icon="minus" />{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                              <Col xs="auto" className="mb-3">
                                <div className="d-flex pr-3 pt-3">
                                  <div>
                                    <div
                                      className="bg-light p-2 text-truncate border border-dark"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      Filefleafejfke_123.pdf
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-n3">
                                    <Button
                                      color="dark"
                                      size="sm"
                                      title="Remove File"
                                    >
                                      <FontAwesomeIcon icon="minus" />{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          */}
                            <Row xs={2} sm={3} md={4} form>
                              {this.state.dispute_imgepreview.map((image, index) => (
                                <Col className="mb-3">
                                  <div className="d-flex pr-3 pt-3" role="button" onClick={() => this.setState({ viewMyPhotosModal: true })}>
                                    <div className="flex-grow-1 mw-100">
                                      {image.toString().includes('data:image')
                                        ?

                                        <img
                                          className="img-fluid img-thumbnail"
                                          src={image}
                                          alt=""
                                          title="Image Name"
                                        />
                                        :
                                        <div className="bg-light p-2 text-truncate border fs-13" title={this.state.dispute_files &&
                                          this.state.dispute_files[0] &&
                                          this.state.dispute_files[0].name}>
                                          <img
                                            width={24}
                                            className="mr-2"
                                            src={
                                              "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/file_icon.png"
                                            }
                                            alt="Icon"
                                          /> {this.state.dispute_files &&
                                            this.state.dispute_files[0] &&
                                            this.state.dispute_files[0].name}
                                        </div>

                                      }
                                    </div>
                                    <div className="mx-n3 mt-n3">
                                      <Button color="dark"
                                        size="sm"
                                        title="Remove Media"
                                        onClick={() => this.deleteImage(index)}
                                      >
                                        <FontAwesomeIcon icon="minus" size="sm" />
                                      </Button>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>

                          </div>

                          {/* Uploaded file type images/videos */}
                          {/* <div className="mt-3">
                            <div className="fs-14 font-weight-bold mb-2">
                              Uploaded Files
                            </div>
                            <Row form>
                              <Col xs="auto" className="mb-3">
                                <div className="d-flex pr-3 pt-3">
                                  <div>
                                    <div className="gallery-media">
                                      <img
                                        src={require("../../../assets/images/cvs-logo.jpg")}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-n3">
                                    <Button
                                      color="dark"
                                      size="sm"
                                      title="Remove File"
                                    >
                                      <FontAwesomeIcon icon="minus" />{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                              <Col xs="auto" className="mb-3">
                                <div className="d-flex pr-3 pt-3">
                                  <div>
                                    <div className="gallery-media">
                                      <img
                                        src={require("../../../assets/images/cvs-logo.jpg")}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-n3">
                                    <Button
                                      color="dark"
                                      size="sm"
                                      title="Remove File"
                                    >
                                      <FontAwesomeIcon icon="minus" />{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                              <Col xs="auto" className="mb-3">
                                <div className="d-flex pr-3 pt-3">
                                  <div>
                                    <div className="gallery-media">
                                      <img
                                        src={require("../../../assets/images/cvs-logo.jpg")}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-n3">
                                    <Button
                                      color="dark"
                                      size="sm"
                                      title="Remove File"
                                    >
                                      <FontAwesomeIcon icon="minus" />{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                         */}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <Button color="primary" onClick={() => this.handleDisputeReply(get_dispute_data)}>Post</Button>
            </ModalFooter>
          </form>
        </Modal>


        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={this.state.voteReviewModalToggleFive}
          toggle={() =>
            this.setState({
              voteReviewModalToggleFive: !this.state.voteReviewModalToggleFive,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        voteReviewModalToggleFive: !this.state
                          .voteReviewModalToggleFive,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs={12} lg="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-tertiary text-white text-center px-5 py-4 h-100"
                    >
                      <div>
                        <img
                          // src={require("../../../assets/images/icons/star/blue/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">
                          REVIEW DISPUTE
                        </h2>
                        <img
                          // src={require("../../../assets/images/icons/star/blue/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className="col-lg">
                    <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            <h3 className="text-light">
                              Business Owner initiated.
                            </h3>
                            <div className="fs-14">
                              <div className="text-primary fs-16 font-weight-bold">
                                Step 1. Lorem Ipsum.
                              </div>
                              <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md="auto" className="align-self-end">
                          <div className="text-light font-weight-bold">
                            Submission time ends in
                          </div>
                          <div className="d-flex mx-n2 flex-nowrap">
                            <div className="px-2">
                              <div className="text-white">
                                <div className="fs-32 ff-headings">7</div>
                                <div className="font-weight-bold">days</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  15
                                </div>
                                <div className="font-weight-normal">hours</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  32
                                </div>
                                <div className="font-weight-normal">
                                  minutes
                                </div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  56
                                </div>
                                <div className="font-weight-normal">
                                  seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="body-bg px-3 py-3">
                <Row>
                  <Col>
                    In case you don't know the correct address, try looking for
                    it with our search bar.
                  </Col>
                  <Col xs="auto">
                    <div className="d-flex">
                      <Input
                        className="primary"
                        bsSize="sm"
                        type="search"
                        placeholder="Custom Search"
                        style={{ minWidth: "300px" }}
                      ></Input>
                      <Button color="primary" size="sm" className="ml-3">
                        <FontAwesomeIcon icon="search" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16 text-dark">
                      Dispute History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Sau6402
                            </a>
                            &nbsp; added a Punctuation in Tips.
                          </div>
                        </div>
                      </li>
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Danielbauwens
                            </a>
                            &nbsp; reverted edits by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Fart biscuit
                            </a>
                            &nbsp; changed back to last version by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Anna
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col md={8}>
                    <Row xs={1} lg={2}>
                      <Col>
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          Step 2. Review Owner claims
                        </div>
                      </Col>
                      <Col>
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          Step 2. Respond to claims
                        </div>
                      </Col>
                      <Col className="bg-light text-center text-tertiary">
                        <div className="p-3">
                          <div className="mb-2 user-profile-img-holder">
                            <img
                              className="img-circle _50x50"
                              // src={require("../../../assets/images/icons/user-circle.png")}
                              alt=""
                            />
                            {/* <span className="user-profile-badge">
                              <img width="24" src="https://cdn.icon-icons.com/icons2/1527/PNG/512/shield_106660.png" alt="" />
                            </span> */}
                          </div>
                          <div className="font-weight-bold fs-18">
                            Business Owner
                          </div>
                          <div className="text-tertiary">
                            a.k.a. The Business Owner
                          </div>
                        </div>
                      </Col>
                      <Col className="bg-white text-center text-dark">
                        <div className="p-3">
                          <div className="mb-2 user-profile-img-holder">
                            <img
                              className="img-circle _50x50"
                              // src={require("../../../assets/images/icons/user-circle.png")}
                              alt=""
                            />
                            <span className="user-profile-badge">
                              <img
                                width="24"
                                src="https://cdn.icon-icons.com/icons2/1527/PNG/512/shield_106660.png"
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="font-weight-bold fs-18">
                            Name LastName
                          </div>
                          <div className="text-tertiary">You</div>
                        </div>
                      </Col>
                      <Col className="bg-light text-center text-tertiary d-flex flex-column">
                        <div className="my-4">
                          <div className="ff-alt">
                            <FontAwesomeIcon icon="quote-left" size="lg" />
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Aut facilis nesciunt odio illum debitis vitae
                            corporis suscipit molestias porro aperiam.
                            <FontAwesomeIcon icon="quote-right" size="lg" />
                          </div>
                          <div className="mt-4">
                            <a href="#" className="text-reset font-weight-bold">
                              proof-number-one.pdf
                            </a>
                          </div>
                        </div>
                        <div className="mt-auto">
                          <hr className="mt-0" />
                        </div>
                      </Col>
                      <Col className="bg-white text-dark d-flex flex-column">
                        <div className="mb-4">
                          <FormGroup className="main-post-formgroup">
                            <div className="input-label-block">
                              <Input
                                className="primary"
                                type="textarea"
                                rows="2"
                                placeholder="&nbsp;"
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="text-tertiary font-weight-bold mb-2">
                              Submit proofs
                            </div>
                            <CustomInput bsSize="sm" type="file" />
                          </FormGroup>
                          <div className="text-right">
                            <div className="text-tertiary">
                              or{" "}
                              <span className="font-weight-bold" role="button">
                                embed link
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="mt-auto">
                          <hr className="mt-0" />
                        </div>
                      </Col>
                      <Col className="bg-light text-center text-tertiary d-flex flex-column">
                        <div className="my-4">
                          <div className="ff-alt">
                            <FontAwesomeIcon icon="quote-left" size="lg" />
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Aut facilis nesciunt odio illum debitis vitae
                            corporis suscipit molestias porro aperiam.
                            <FontAwesomeIcon icon="quote-right" size="lg" />
                          </div>
                          <div className="mt-4">
                            <a href="#" className="text-reset font-weight-bold">
                              proof-number-two.pdf
                            </a>
                          </div>
                        </div>
                        <div className="mt-auto">
                          <hr className="mt-0" />
                        </div>
                      </Col>
                      <Col className="bg-white text-dark d-flex flex-column">
                        <div className="mb-4">
                          <FormGroup className="main-post-formgroup">
                            <div className="input-label-block">
                              <Input
                                className="primary"
                                type="textarea"
                                rows="2"
                                placeholder="&nbsp;"
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <div className="text-tertiary font-weight-bold mb-2">
                              Submit proofs
                            </div>
                            <CustomInput bsSize="sm" type="file" />
                          </FormGroup>
                          <div className="text-right">
                            <div className="text-tertiary">
                              or{" "}
                              <span className="font-weight-bold" role="button">
                                embed link
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="mt-auto">
                          <hr className="mt-0" />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <Button color="primary">Submit</Button>
              <Button color="link" className="text-tertiary font-weight-bold">
                Skip
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={this.state.voteReviewModalToggleSix}
          toggle={() =>
            this.setState({
              voteReviewModalToggleSix: !this.state.voteReviewModalToggleSix,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        voteReviewModalToggleSix: !this.state
                          .voteReviewModalToggleSix,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
                <div className="bg-dark text-white fs-14 px-3">
                  <div className="mb-2 pt-2">
                    <div className="d-flex mx-n2">
                      <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            className="text-white bg-transparent shadow-none font-weight-bold"
                            color="transparent"
                            size="sm"
                            caret
                          >
                            <span className="pr-2">Disputed Reviews</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="fs-14">
                              Disputed Reviews
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="px-2 col-auto">
                        near{" "}
                        <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled d-flex flex-wrap mx-n2">
                    <li className="px-2">
                      <div className="d-flex">
                        <FormGroup>
                          <Input
                            type="select"
                            className="transparent"
                            size="sm"
                          >
                            <option>Businesses</option>
                          </Input>
                        </FormGroup>
                        <div className="ml-2">
                          <span className="text-danger">*</span>
                        </div>
                      </div>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>IT Services</option>
                          <option>Apartment Rentals</option>
                          <option>Automotive</option>
                          <option>Education</option>
                          <option>Beauty and Spas</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Asian</option>
                          <option>European</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Indian</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Level 6</option>
                        </Input>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                      style={{ minWidth: "300px" }}
                    >
                      <div>
                        <img
                          // src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                        <img
                          // src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            <h3 className="text-light">
                              Business Owner dispute.
                            </h3>
                            <div className="fs-14">
                              <div className="text-primary fs-16 font-weight-bold">
                                Step 1. Your vote is needed.
                              </div>
                              <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md="auto" className="align-self-end">
                          <div className="text-light font-weight-bold">
                            Submission time ends in
                          </div>
                          <div className="d-flex mx-n2 flex-nowrap">
                            <div className="px-2">
                              <div className="text-white">
                                <div className="fs-32 ff-headings">7</div>
                                <div className="font-weight-bold">days</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  15
                                </div>
                                <div className="font-weight-normal">hours</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  32
                                </div>
                                <div className="font-weight-normal">
                                  minutes
                                </div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  56
                                </div>
                                <div className="font-weight-normal">
                                  seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="body-bg px-3 py-3">
                <Row>
                  <Col>
                    In case you don't know the correct address, try looking for
                    it with our search bar.
                  </Col>
                  <Col xs="auto">
                    <div className="d-flex">
                      <Input
                        className="primary"
                        bsSize="sm"
                        type="search"
                        placeholder="Custom Search"
                        style={{ minWidth: "300px" }}
                      ></Input>
                      <Button color="primary" size="sm" className="ml-3">
                        <FontAwesomeIcon icon="search" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16 text-primary">
                      Step 2. Voting History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Sau6402
                            </a>
                            &nbsp; added a Punctuation in Tips.
                          </div>
                        </div>
                      </li>
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Danielbauwens
                            </a>
                            &nbsp; reverted edits by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Fart biscuit
                            </a>
                            &nbsp; changed back to last version by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Anna
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col md={8}>
                    <div className="mb-3">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 3. Review Lorem Ipsum
                      </div>
                      <div>
                        <Row>
                          <Col lg={6}>
                            <div className="bg-white text-center text-tertiary p-3">
                              <div className="mb-2">
                                <img
                                  className="img-circle _50x50"
                                  // src={require("../../../assets/images/icons/user-circle.png")}
                                  alt=""
                                />
                              </div>
                              <div className="font-weight-bold fs-18">
                                User John
                              </div>
                              <div className="text-tertiary">
                                a.k.a. The Reviewer
                              </div>
                              <div className="mt-3">
                                {/* Repeat this */}
                                <div className="mb-4">
                                  <div className="ff-alt">
                                    <FontAwesomeIcon
                                      icon="quote-left"
                                      size="lg"
                                    />
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Aut facilis nesciunt odio
                                    illum debitis vitae corporis suscipit
                                    molestias porro aperiam.
                                    <FontAwesomeIcon
                                      icon="quote-right"
                                      size="lg"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <a
                                      href="#"
                                      className="text-reset font-weight-bold"
                                    >
                                      letterpfintent.doc
                                    </a>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <div className="ff-alt">
                                    <FontAwesomeIcon
                                      icon="quote-left"
                                      size="lg"
                                    />
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Aut facilis nesciunt odio
                                    illum debitis vitae corporis suscipit
                                    molestias porro aperiam.
                                    <FontAwesomeIcon
                                      icon="quote-right"
                                      size="lg"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <a
                                      href="#"
                                      className="text-reset font-weight-bold"
                                    >
                                      supporting.doc
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="bg-light text-center text-tertiary p-3">
                              <div className="mb-2">
                                <img
                                  className="img-circle _50x50"
                                  // src={require("../../../assets/images/icons/user-circle.png")}
                                  alt=""
                                />
                              </div>
                              <div className="font-weight-bold fs-18">
                                Business Owner
                              </div>
                              <div className="text-tertiary">
                                a.k.a. The Business Owner
                              </div>
                              <div className="mt-3">
                                {/* Repeat this */}
                                <div className="mb-4">
                                  <div className="ff-alt">
                                    <FontAwesomeIcon
                                      icon="quote-left"
                                      size="lg"
                                    />
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Aut facilis nesciunt odio
                                    illum debitis vitae corporis suscipit
                                    molestias porro aperiam.
                                    <FontAwesomeIcon
                                      icon="quote-right"
                                      size="lg"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <span>no proofs submitten</span>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <div className="ff-alt">
                                    <FontAwesomeIcon
                                      icon="quote-left"
                                      size="lg"
                                    />
                                    Lorem ipsum dolor, sit amet consectetur
                                    adipisicing elit. Aut facilis nesciunt odio
                                    illum debitis vitae corporis suscipit
                                    molestias porro aperiam.
                                    <FontAwesomeIcon
                                      icon="quote-right"
                                      size="lg"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <a
                                      href="#"
                                      className="text-reset font-weight-bold"
                                    >
                                      proof-of-some-type.pdf
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>&nbsp;</Col>
                  <Col md={8}>
                    {/* Step 4 */}
                    <div>
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 4. Does the Business Owner have sufficient evidence
                        to support any of the following:
                        <div className="fs-13 text-dark font-weight-normal">
                          Please put as much info (images, video, documents
                          etc.) as possible to support your statements.
                        </div>
                      </div>
                      <div>
                        <div className="bg-light p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">
                                    This review is inaccurate
                                  </div>
                                  <div className="fs-12 ff-alt">
                                    Must have evidence
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">25%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      5 votes out of 12
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Expand"
                              >
                                <FontAwesomeIcon icon="plus" />{" "}
                              </Button>
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Collapse"
                              >
                                <FontAwesomeIcon icon="minus" />{" "}
                              </Button>
                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                        <div className="bg-light p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">Review is fake.</div>
                                  <div className="fs-12 ff-alt">
                                    Must explain why or have some type of
                                    evidence.
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">25%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      5 votes out of 12
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Expand"
                              >
                                <FontAwesomeIcon icon="plus" />{" "}
                              </Button>
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Collapse"
                              >
                                <FontAwesomeIcon icon="minus" />{" "}
                              </Button>
                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                        <div className="bg-light p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">
                                    Review is Not Relevant.
                                  </div>
                                  <div className="fs-12 ff-alt">
                                    Must explain why.
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">25%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      5 votes out of 12
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Expand"
                              >
                                <FontAwesomeIcon icon="plus" />{" "}
                              </Button>
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Collapse"
                              >
                                <FontAwesomeIcon icon="minus" />{" "}
                              </Button>
                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                        <div className="bg-light p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" />{" "}
                                  <div className="mb-1">
                                    Do not dispute review.
                                  </div>
                                  <div className="fs-12 ff-alt">
                                    The dispute does not sufficiently meet any
                                    of the first three criteria.
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">25%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      5 votes out of 12
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Expand"
                              >
                                <FontAwesomeIcon icon="plus" />{" "}
                              </Button>
                              <Button
                                color="outline-tertiary rounded-circle"
                                size="sm"
                                title="Collapse"
                              >
                                <FontAwesomeIcon icon="minus" />{" "}
                              </Button>
                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                      </div>

                      {/* Step 4.b */}
                      <div className="mt-3">
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          Step 4.b. Should this review be...
                        </div>
                        <div>
                          <div className="bg-light p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                              <div className="px-2 col">
                                <FormGroup className="mb-2" check>
                                  <Label
                                    className="text-reset font-weight-normal fs-14 d-block"
                                    check
                                  >
                                    <Input
                                      type="radio"
                                      name="keepOrRemoveReview"
                                    />{" "}
                                    <div className="mb-1">
                                      ...kept on WikiReviews?
                                    </div>
                                    <div className="d-flex flex-wrap mx-n2 fs-14">
                                      <div className="px-2 mb-2">25%</div>
                                      <div className="px-2 mb-2 align-self-center">
                                        <Progress
                                          color="tertiary"
                                          value="25"
                                          style={{
                                            height: "6px",
                                            width: "240px",
                                          }}
                                        ></Progress>
                                      </div>
                                      <div className="px-2 mb-2">
                                        5 votes out of 12
                                      </div>
                                    </div>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="px-2 col-auto">
                                <Button
                                  color="outline-tertiary rounded-circle"
                                  size="sm"
                                  title="Expand"
                                >
                                  <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                  color="outline-tertiary rounded-circle"
                                  size="sm"
                                  title="Collapse"
                                >
                                  <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                              </div>
                            </div>

                            {/* Collapse content */}
                            <div className="pl-3">Collapse content</div>
                          </div>
                          <div className="bg-light p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                              <div className="px-2 col">
                                <FormGroup className="mb-2" check>
                                  <Label
                                    className="text-reset font-weight-normal fs-14 d-block"
                                    check
                                  >
                                    <Input
                                      type="radio"
                                      name="keepOrRemoveReview"
                                    />{" "}
                                    <div className="mb-1">
                                      ...removed from WikiReviews?
                                    </div>
                                    <div className="d-flex flex-wrap mx-n2 fs-14">
                                      <div className="px-2 mb-2">25%</div>
                                      <div className="px-2 mb-2 align-self-center">
                                        <Progress
                                          color="tertiary"
                                          value="25"
                                          style={{
                                            height: "6px",
                                            width: "240px",
                                          }}
                                        ></Progress>
                                      </div>
                                      <div className="px-2 mb-2">
                                        5 votes out of 12
                                      </div>
                                    </div>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="px-2 col-auto">
                                <Button
                                  color="outline-tertiary rounded-circle"
                                  size="sm"
                                  title="Expand"
                                >
                                  <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                  color="outline-tertiary rounded-circle"
                                  size="sm"
                                  title="Collapse"
                                >
                                  <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                              </div>
                            </div>

                            {/* Collapse content */}
                            <div className="pl-3">Collapse content</div>
                          </div>
                        </div>
                      </div>

                      {/* Step 4.c */}
                      <div className="mt-3">
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          Step 4.c. Should this kept review be...
                        </div>
                        <div>
                          <div className="bg-light p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                              <div className="px-2 col">
                                <FormGroup className="mb-2" check>
                                  <Label
                                    className="text-reset font-weight-normal fs-14 d-block"
                                    check
                                  >
                                    <Input
                                      type="radio"
                                      name="reasonToKeepReview"
                                    />{" "}
                                    <div className="mb-1">
                                      ...put in the filtered section?
                                    </div>
                                    <div className="d-flex flex-wrap mx-n2 fs-14">
                                      <div className="px-2 mb-2">25%</div>
                                      <div className="px-2 mb-2 align-self-center">
                                        <Progress
                                          color="tertiary"
                                          value="25"
                                          style={{
                                            height: "6px",
                                            width: "240px",
                                          }}
                                        ></Progress>
                                      </div>
                                      <div className="px-2 mb-2">
                                        5 votes out of 12
                                      </div>
                                    </div>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="px-2 col-auto">
                                <Button
                                  color="outline-tertiary rounded-circle"
                                  size="sm"
                                  title="Expand"
                                >
                                  <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                  color="outline-tertiary rounded-circle"
                                  size="sm"
                                  title="Collapse"
                                >
                                  <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                              </div>
                            </div>

                            {/* Collapse content */}
                            <div className="pl-3">Collapse content</div>
                          </div>
                          <div className="bg-light p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                              <div className="px-2 col">
                                <FormGroup className="mb-2" check>
                                  <Label
                                    className="text-reset font-weight-normal fs-14 d-block"
                                    check
                                  >
                                    <Input
                                      type="radio"
                                      name="reasonToKeepReview"
                                    />{" "}
                                    <div className="mb-1">
                                      ...kept where it is? (in the unfiltered
                                      section)
                                    </div>
                                    <div className="d-flex flex-wrap mx-n2 fs-14">
                                      <div className="px-2 mb-2">25%</div>
                                      <div className="px-2 mb-2 align-self-center">
                                        <Progress
                                          color="tertiary"
                                          value="25"
                                          style={{
                                            height: "6px",
                                            width: "240px",
                                          }}
                                        ></Progress>
                                      </div>
                                      <div className="px-2 mb-2">
                                        5 votes out of 12
                                      </div>
                                    </div>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="px-2 col-auto">
                                <Button
                                  color="outline-tertiary rounded-circle"
                                  size="sm"
                                  title="Expand"
                                >
                                  <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                  color="outline-tertiary rounded-circle"
                                  size="sm"
                                  title="Collapse"
                                >
                                  <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                              </div>
                            </div>

                            {/* Collapse content */}
                            <div className="pl-3">Collapse content</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Step 5 */}
                    <div className="mt-4">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 5. Please, tell us why.
                      </div>
                      <div className="text-dark">
                        It would be really helpful for fellow voters to know the
                        reasoning of your decision. It has to be 140 characters
                        long. Some text like that.
                      </div>
                      <div className="text-right text-primary fs-14">65</div>
                      <Input
                        type="textarea"
                        className="primary"
                        rows="3"
                        defaultValue="The Info is accurate, I've been there today."
                        placeholder="Please elaborate..."
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              <Button color="primary">Submit</Button>
              <Button color="link" className="text-tertiary font-weight-bold">
                Skip
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        {/* Jury Final Decision Modal */}
        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={this.state.voteReviewModalToggleFinal}
          toggle={() =>
            this.setState({
              voteReviewModalToggleFinal: !this.state
                .voteReviewModalToggleFinal,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        voteReviewModalToggleFinal: !this.state
                          .voteReviewModalToggleFinal,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
                <div className="bg-dark text-white fs-14 px-3">
                  <div className="mb-2 pt-2">
                    <div className="d-flex mx-n2">
                      <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            className="text-white bg-transparent shadow-none font-weight-bold"
                            color="transparent"
                            size="sm"
                            caret
                          >
                            <span className="pr-2">Disputed Reviews</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="fs-14">
                              Disputed Reviews
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="px-2 col-auto">
                        near{" "}
                        <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled d-flex flex-wrap mx-n2">
                    <li className="px-2">
                      <div className="d-flex">
                        <FormGroup>
                          <Input
                            type="select"
                            className="transparent"
                            size="sm"
                          >
                            <option>Businesses</option>
                          </Input>
                        </FormGroup>
                        <div className="ml-2">
                          <span className="text-danger">*</span>
                        </div>
                      </div>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>IT Services</option>
                          <option>Apartment Rentals</option>
                          <option>Automotive</option>
                          <option>Education</option>
                          <option>Beauty and Spas</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Asian</option>
                          <option>European</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Indian</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Level 6</option>
                        </Input>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                      style={{ minWidth: "300px" }}
                    >
                      <div>
                        <img
                          // src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                        <img
                          // src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-column justify-content-center bg-primary text-dark h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            <h3 className="text-white">
                              The Community has voted.
                            </h3>
                            <div className="fs-14">
                              <div className="fs-16 font-weight-bold">
                                Their decision is to delete the review.
                              </div>
                              <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col md="auto" className="align-self-end">
                          <div className="text-light font-weight-bold text-muted">
                            Voting ended
                          </div>
                          <div className="d-flex mx-n2 flex-nowrap">
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings">0</div>
                                <div className="font-weight-bold">days</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  00
                                </div>
                                <div className="font-weight-normal">hours</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  00
                                </div>
                                <div className="font-weight-normal">
                                  minutes
                                </div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  00
                                </div>
                                <div className="font-weight-normal">
                                  seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16 text-primary mb-2">
                      Voting History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Sau6402
                            </a>
                            &nbsp; added a Punctuation in Tips.
                          </div>
                        </div>
                      </li>
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Danielbauwens
                            </a>
                            &nbsp; reverted edits by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Fart biscuit
                            </a>
                            &nbsp; changed back to last version by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Anna
                            </a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col md={8}>
                    <div>
                      <div className="font-weight-bold fs-16 text-primary mb-3">
                        The Decision
                      </div>

                      <div className="bg-light p-2 mb-3 border">
                        <div className="font-weight-bold">
                          Their Decision is to delete the review.
                        </div>
                        <div className="text-tertiary">
                          The review is inaccurate and will be removed from
                          WikiReviews
                        </div>
                      </div>

                      <div className="bg-light p-3 mt-5">
                        <div
                          className="overflow-y-auto"
                          style={{ maxHeight: "300px" }}
                        >
                          <div>
                            <div className="d-flex">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-1">
                                  <a href="#" className="text-tertiary">
                                    <strong>UserName</strong>
                                  </a>
                                  &nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div>
                            <div className="d-flex">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-1">
                                  <a href="#" className="text-tertiary">
                                    <strong>UserName</strong>
                                  </a>
                                  &nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div>
                            <div className="d-flex">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-1">
                                  <a href="#" className="text-tertiary">
                                    <strong>UserName</strong>
                                  </a>
                                  &nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div>
                            <div className="d-flex">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-1">
                                  <a href="#" className="text-tertiary">
                                    <strong>UserName</strong>
                                  </a>
                                  &nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-weight-bold fs-16 text-primary mb-4">
                        Demographic &amp; Geographic Breakdown of Votes
                        <div className="text-tertiary font-weight-normal fs-14">
                          This is a text explaining what the information below
                          is all about.
                        </div>
                      </div>

                      <Table
                        bordered
                        size="sm"
                        className="text-center table-chart _cols-4"
                      >
                        <thead className="text-tertiary">
                          <tr>
                            <th>&nbsp;</th>
                            <th>Voted to Delete</th>
                            <th>Voted to Delete</th>
                            <th>Voted to Keep, as Questionable</th>
                          </tr>
                        </thead>
                        <tbody className="ff-alt text-muted">
                          <tr>
                            <th className="bg-light" scope="row">
                              Results
                            </th>
                            <td className="bg-light">18.75%</td>
                            <td className="bg-light">43.75%</td>
                            <td className="bg-light">37.5%</td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="mt-5">
                        <div className="text-dark fs-16 font-weight-bold mb-2">
                          Age Group
                        </div>
                        <Table
                          bordered
                          size="sm"
                          className="text-center table-chart _cols-4"
                        >
                          <thead className="text-tertiary">
                            <tr>
                              <th>Age Group</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Keep, as Questionable</th>
                            </tr>
                          </thead>
                          <tbody className="ff-alt text-muted">
                            <tr>
                              <th className="bg-light" scope="row">
                                18-24
                              </th>
                              <td className="bg-light">2</td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                25-34
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">3</td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                35-44
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">4</td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                45-54
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                              <td className="bg-light">6</td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                55-64
                              </th>
                              <td className="bg-light">1</td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                65+
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      <div className="mt-5">
                        <div className="text-dark fs-16 font-weight-bold mb-2">
                          Gender
                        </div>
                        <Table
                          bordered
                          size="sm"
                          className="text-center table-chart _cols-4"
                        >
                          <thead className="text-tertiary">
                            <tr>
                              <th>Gender</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Keep, as Questionable</th>
                            </tr>
                          </thead>
                          <tbody className="ff-alt text-muted">
                            <tr>
                              <th className="bg-light" scope="row">
                                Male
                              </th>
                              <td className="bg-light">2</td>
                              <td className="bg-light">2</td>
                              <td className="bg-light">2</td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Female
                              </th>
                              <td className="bg-light">3</td>
                              <td className="bg-light">3</td>
                              <td className="bg-light">3</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      <div className="mt-5">
                        <div className="text-dark fs-16 font-weight-bold mb-2">
                          Geography
                        </div>
                        <Table
                          bordered
                          size="sm"
                          className="text-center table-chart _cols-4"
                        >
                          <thead className="text-tertiary">
                            <tr>
                              <th>Geography</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Keep, as Questionable</th>
                            </tr>
                          </thead>
                          <tbody className="ff-alt text-muted">
                            <tr>
                              <th className="bg-light" scope="row">
                                Irvine, CA
                              </th>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Brooklyn, NY
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Perris, CA
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Mequon, WI
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Chicago, IL
                              </th>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </form>
        </Modal>

        {/* Limited Disputes Modal */}
        <Modal
          centered
          isOpen={this.state.limitedDisputesModalToggle}
          toggle={() =>
            this.setState({
              limitedDisputesModalToggle: !this.state
                .limitedDisputesModalToggle,
            })
          }
        >
          <ModalBody className="p-4">
            <Row className="mb-3">
              <Col xs={3}>
                <img
                  className="img-fluid"
                  // src={require("../../../assets/images/icons/paywall.png")}
                  alt="Bell"
                />
              </Col>
              <Col xs={9}>
                <h4 className="text-primary mb-2 ff-base fs-16">
                  You have disputed 3 reviews for free.
                </h4>
                <p className="text-primary-dark ff-base fs-14">
                  Upgrade to <strong>Premium Business</strong> account to get
                  unlimited ability to dispute and other more benefits.
                </p>
              </Col>
            </Row>
            <Button color="primary" size="lg" block>
              {" "}
              Upgrade to PREMIUM and get more benefits
            </Button>
            <div className="text-center mt-2">
              <Button
                color="link"
                className="text-dark"
                onClick={() =>
                  this.setState({ limitedDisputesModalToggle: false })
                }
              >
                Stay with FREE account
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {/* View History Log Modal popup */}
        <Modal
          size="lg"
          scrollable
          isOpen={this.state.historyLogModalToggle}
          toggle={() =>
            this.setState({
              historyLogModalToggle: !this.state.historyLogModalToggle,
            })
          }
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                historyLogModalToggle: !this.state.historyLogModalToggle,
              })
            }
          >
            History Log
          </ModalHeader>
          <ModalBody className="fs-14 text-dark">
            <div className="d-flex flex-wrap mx-n1 mb-3"></div>

            <div>
              <div className="py-2 border-bottom mb-1">
                <Row>
                  <Col xs="auto">
                    <FormGroup check style={{ minWidth: "160px" }}>
                      <Label
                        className="text-reset font-weight-normal fs-14 d-block"
                        check
                      >
                        <Input type="radio" name="selectLogType" />{" "}
                        <div className="pl-4 font-weight-bold ff-alt">
                          <span className="text-primary">
                            {get_dispute_data &&
                              get_dispute_data[0] &&
                              get_dispute_data[0].user_name}
                          </span>
                        </div>
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <div>
                          <span>
                            {" "}
                            <a href="#" className="text-reset font-weight-bold">
                              {get_dispute_data &&
                                get_dispute_data[0] &&
                                get_dispute_data[0].flag_name}
                            </a>
                          </span>
                          &nbsp;
                          <span className="fs-12 text-muted">
                            Created on
                            <span className="text-reset font-weight-bold">
                              {/* {discussion.created_on} */}
                              <Moment format="DD-MM-YYYY">
                                {get_dispute_data &&
                                  get_dispute_data[0] &&
                                  get_dispute_data[0].created_on}
                              </Moment>
                              &nbsp; at &nbsp;
                              <Moment format="HH:MM:SS">
                                {get_dispute_data &&
                                  get_dispute_data[0] &&
                                  get_dispute_data[0].created_on}
                              </Moment>
                            </span>
                          </span>
                        </div>
                      </Col>
                      <Col xs="auto">
                        {this.state.showExplanation ? (
                          <Button
                            color="outline-tertiary rounded-circle"
                            size="sm"
                            title="Collapse"
                            onClick={() =>
                              this.setState({ showExplanation: false })
                            }
                          >
                            <FontAwesomeIcon icon="minus" />{" "}
                          </Button>
                        ) : (
                            <Button
                              color="outline-tertiary rounded-circle"
                              size="sm"
                              title="Expand"
                              onClick={() =>
                                this.setState({ showExplanation: true })
                              }
                            >
                              <FontAwesomeIcon icon="plus" />{" "}
                            </Button>
                          )}
                        <DeleteBtn onClick={this.handleDeleteDispute} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              {this.state.showExplanation ? (
                <div>
                  <div className="mt-3">
                    <span className="text-muted ff-alt fs-14">
                      "
                      {get_dispute_data &&
                        get_dispute_data[0] &&
                        get_dispute_data[0].content_flagged}
                      "
                    </span>
                  </div>
                  {/* <hr /> */}
                  <div className="mt-3 bg-white p-2 mb-3">
                    {get_dispute_data &&
                      get_dispute_data[0] &&
                      get_dispute_data[0].media.map((media) => (
                        <div>
                          {media.includes(".jpeg") ||
                            media.includes(".jpg") ||
                            media.includes(".png") ? (
                              <div className="bg-light d-inline-block p-2 mb-2">
                                <div className="d-flex mx-n2">
                                  <div className="px-2">
                                    <FontAwesomeIcon icon="camera" fixedWidth />
                                  </div>
                                  <div className="px-2 col">
                                    <CollapseBasic
                                      title={"filename.jpg"}
                                      noHr={true}
                                      containerClass="type-dark bg-transparent"
                                      bodyClass="mt-2 text-dark"
                                      isOpen={false}
                                      noPadding={true}
                                      size="sm"
                                    >
                                      <img
                                        className="img-fluid"
                                        src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                        alt=""
                                      />
                                    </CollapseBasic>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="bg-light d-inline-block p-2 mb-2">
                                <div className="d-flex mx-n2">
                                  <div className="px-2">
                                    <FontAwesomeIcon
                                      icon="paperclip"
                                      fixedWidth
                                    />
                                  </div>
                                  <div className="px-2 col">
                                    <span className="font-weight-bold text-truncate">
                                      <a
                                        className="text-reset font-weight-bold"
                                        href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Filename.pdf
                                    </a>
                                    </span>
                                  </div>
                                  <div className="px-2 ml-auto">
                                    <span className="mr-2">|</span>
                                    <a
                                      href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                      target="_blank"
                                      className="text-reset"
                                      rel="noopener noreferrer"
                                    >
                                      <FontAwesomeIcon icon="external-link-alt" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      ))}
                  </div>
                  <hr />

                  <div className="pl-5">
                    {get_dispute_data &&
                      get_dispute_data.map((discussion) => (
                        <div>
                          {discussion.child_key_name === 1 ||
                            discussion.child_key_name === "1" ? (
                              <div>
                                <div className="d-flex mx-n2 mb-3">
                                  <div className="px-2">
                                    {/* <img className="img-circle _30x30" src={require("../../../assets/images/user-circle.png")} alt="" /> */}
                                  </div>
                                  <div className="px-2">
                                    <div className="text-dark">
                                      <span className="font-weight-bold">
                                        {discussion.user_name}
                                      </span>{" "}
                                    wrote this reply&nbsp;
                                    <DeleteBtn onClick={() => this.handleDeleteReply(discussion.pk)} />
                                    </div>
                                    <span className="text-muted ff-alt fs-14">
                                      "{discussion.content_flagged}"
                                  </span>
                                  </div>
                                </div>
                                <div className="mt-3 bg-white p-2">
                                  {discussion &&
                                    discussion.media.map((media) => (
                                      <div>
                                        {media.includes(".jpeg") ||
                                          media.includes(".jpg") ||
                                          media.includes(".png") ? (
                                            <div className="bg-light d-inline-block p-2 mb-2">
                                              <div className="d-flex mx-n2">
                                                <div className="px-2">
                                                  <FontAwesomeIcon
                                                    icon="camera"
                                                    fixedWidth
                                                  />
                                                </div>
                                                <div className="px-2 col">
                                                  <CollapseBasic
                                                    title={"filename.jpg"}
                                                    noHr={true}
                                                    containerClass="type-dark bg-transparent"
                                                    bodyClass="mt-2 text-dark"
                                                    isOpen={false}
                                                    noPadding={true}
                                                    size="sm"
                                                  >
                                                    <img
                                                      className="img-fluid"
                                                      src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                      alt=""
                                                    />
                                                  </CollapseBasic>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="bg-light d-inline-block p-2 mb-2">
                                              <div className="d-flex mx-n2">
                                                <div className="px-2">
                                                  <FontAwesomeIcon
                                                    icon="paperclip"
                                                    fixedWidth
                                                  />
                                                </div>
                                                <div className="px-2 col">
                                                  <span className="font-weight-bold text-truncate">
                                                    <a
                                                      className="text-reset font-weight-bold"
                                                      href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      Filename.pdf
                                                </a>
                                                  </span>
                                                </div>
                                                <div className="px-2 ml-auto">
                                                  <span className="mr-2">|</span>
                                                  <a
                                                    href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                    target="_blank"
                                                    className="text-reset"
                                                    rel="noopener noreferrer"
                                                  >
                                                    <FontAwesomeIcon icon="external-link-alt" />
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    ))}
                                  <hr />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                        </div>
                      ))}
                    {this.state.showReply ? (
                      <div
                        className="bg-white p-2 mt-3"
                        style={{
                          border: "2px groove gray",
                          padding: "2%",
                          "border-radius": "3px",
                        }}
                      >
                        <div>
                          <Input
                            type="textarea"
                            // className="text-primary-dark fs-14 font-weight-bold"
                            className="primary text-primary-dark fs-14 font-weight-bold"
                            bsSize="sm"
                            rows="4"
                            name="dispute_reply_text"
                            id="dispute_reply_text"
                            onChange={this.handleReplyChange}
                            value={dispute_reply_text}
                            placeholder="Provide your explanation for dispute..."
                            required
                          />

                          <div className="mb-3 mt-3">
                            {/* <input type="file" onChange={this.handleImageChange} multiple color="dark"/> */}

                            <input
                              type="file"
                              name="imageUpload"
                              id="imageUpload"
                              style={{ display: "None" }}
                              onChange={this.handleImageChange}
                              multiple
                            />
                            <label
                              for="imageUpload"
                              className="btn btn-tertiary bg-tertiary border-tertiary"
                            >
                              Upload Media
                            </label>
                          </div>
                          <div className="mb-3">
                            <Row xs={2} sm={3} md={4} form>
                              {this.state.dispute_imgepreview.map(
                                (image, index) => (
                                  <Col className="mb-3">
                                    <div
                                      className="d-flex pr-3 pt-3"
                                      role="button"
                                      onClick={() =>
                                        this.setState({
                                          viewMyPhotosModal: true,
                                        })
                                      }
                                    >
                                      <div className="flex-grow-1 mw-100">
                                        {image
                                          .toString()
                                          .includes("data:image") ? (
                                            <img
                                              className="img-fluid img-thumbnail"
                                              src={image}
                                              alt=""
                                              title="Image Name"
                                            />
                                          ) : (
                                            <div
                                              className="bg-light p-2 text-truncate border fs-13"
                                              title={
                                                this.state.dispute_files &&
                                                this.state.dispute_files[0] &&
                                                this.state.dispute_files[0].name
                                              }
                                            >
                                              <img
                                                width={24}
                                                className="mr-2"
                                                src={
                                                  "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/file_icon.png"
                                                }
                                                alt="Icon"
                                              />{" "}
                                              {this.state.dispute_files &&
                                                this.state.dispute_files[0] &&
                                                this.state.dispute_files[0].name}
                                            </div>
                                          )}
                                      </div>
                                      <div className="mx-n3 mt-n3">
                                        <Button
                                          color="dark"
                                          size="sm"
                                          title="Remove Media"
                                          onClick={() =>
                                            this.deleteImage(index)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="minus"
                                            size="sm"
                                          />
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                )
                              )}
                            </Row>
                          </div>

                          <div className="text-right">
                            <Button
                              color="primary"
                              className="mw"
                              onClick={() =>
                                this.setState({ showReply: false })
                              }
                            >
                              Cancel
                            </Button>
                            <Button
                              color="primary"
                              className="mw"
                              onClick={() =>
                                this.handleDisputeReply(get_dispute_data)
                              }
                            >
                              Submit Reply
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                        <div className="text-right mt-2">
                          {get_dispute_data &&
                            get_dispute_data[0] &&
                            get_dispute_data[0].voting_enable_flag ? (
                              // <div className="bg-warning p-3 text-dark text-center mt-1 fs-14">
                              //   <strong
                              //     role="button"
                              //     className="text-black"
                              //     onClick={this.handleJuryVoting}
                              //   >
                              //     Cast Your Valuable Vote
                              //   </strong>
                              // </div>
                              <div className="text-right mt-2">
                                <Button
                                  size="sm"
                                  color="primary"
                                  onClick={() => this.setState({ showReply: true })}
                                >
                                  Reply to this dispute
                            </Button>
                              </div>
                            ) : (
                              <div className="text-right mt-2">
                                <Button
                                  size="sm"
                                  color="primary"
                                  onClick={() => this.setState({ showReply: true })}
                                >
                                  Reply to this dispute
                            </Button>
                              </div>
                            )}
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                  ""
                )}
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <Button color="primary">Compare</Button>
          </ModalFooter> */}
        </Modal>

        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={this.state.patrolReviewModalToggleOne}
          toggle={() =>
            this.setState({
              patrolReviewModalToggleOne: !this.state
                .patrolReviewModalToggleOne,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        patrolReviewModalToggleOne: !this.state
                          .patrolReviewModalToggleOne,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
                <div className="bg-dark text-white fs-14 px-3">
                  <div className="mb-2 pt-2">
                    <div className="d-flex mx-n2">
                      <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Patroling{" "}
                        </span>
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            className="text-white bg-transparent shadow-none font-weight-bold"
                            color="transparent"
                            size="sm"
                            caret
                          >
                            <span className="pr-2">Reviews</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="fs-14">
                              Reviews
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="px-2 col-auto">
                        near{" "}
                        <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled d-flex flex-wrap mx-n2">
                    <li className="px-2">
                      <div className="d-flex">
                        <FormGroup>
                          <Input
                            type="select"
                            className="transparent"
                            size="sm"
                          >
                            <option>Businesses</option>
                          </Input>
                        </FormGroup>
                        <div className="ml-2">
                          <span className="text-danger">*</span>
                        </div>
                      </div>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>IT Services</option>
                          <option>Apartment Rentals</option>
                          <option>Automotive</option>
                          <option>Education</option>
                          <option>Beauty and Spas</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Asian</option>
                          <option>European</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Indian</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Level 6</option>
                        </Input>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ModalBody className="fs-14 text-dark">
              <Row>
                <Col lg={8}>
                  <div className="text-primary fs-16 font-weight-bold mb-2">
                    Step 1. Brief
                  </div>
                  <div>
                    <strong>The Brief.</strong>
                  </div>
                  <p>
                    The Original Listing, currently marked as{" "}
                    <span className="font-weight-bold">Non Recommended</span>,
                    was{" "}
                    <span className="font-weight-bold">
                      Nominated for deletion
                    </span>{" "}
                    due to{" "}
                    <span className="font-weight-bold">Facts are Wrong</span> 3
                    months ago, by{" "}
                    <span className="font-weight-bold">Business Owner</span>{" "}
                    (a.k.a the business owner). It was disputed 1 week ago by
                    User2 (a.k.a the reviewer).
                  </p>
                  <div className="bg-light p-3">
                    <div className="text-black mb-2">
                      New Review on ListingName
                    </div>
                    <div className="font-weight-bold">
                      <div className="mb-1">
                        John Username's Review{" "}
                        <img
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="text-tertiary font-weight-bold">
                      <div className="mb-1">
                        Attention{" "}
                        <img
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png"
                          alt=""
                        />
                      </div>
                      <div className="mb-1">
                        Ambient{" "}
                        <img
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="my-4">
                      <FontAwesomeIcon icon="quote-left" />
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Placeat eius adipisci mollitia eos incidunt. Consequatur
                      deleniti neque, eos suscipit saepe quos velit?
                      Necessitatibus dolorum nulla reprehenderit totam, dolore
                      doloribus ut.
                      <FontAwesomeIcon icon="quote-right" />
                    </div>

                    <div className="text-center mt-3">
                      <img
                        className="img-fluid"
                        src="https://source.unsplash.com/random/600x300"
                        alt=""
                      />
                    </div>

                    <div className="mt-3">
                      <Carousel
                        responsive={responsive.viewGalleryImages}
                        infinite={true}
                        autoPlay={false}
                        swipeable={true}
                        showDots={false}
                        arrows={true}
                        containerClass="mx-n4"
                      >
                        <div className="item px-4">
                          <div className="img-holder text-center">
                            <img
                              className="img-thumbnail fixed-height-img full-width"
                              src="https://source.unsplash.com/random/100x100"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="item px-4">
                          <div className="img-holder text-center">
                            <img
                              className="img-thumbnail fixed-height-img full-width"
                              src="https://source.unsplash.com/random/100x100"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="item px-4">
                          <div className="img-holder text-center">
                            <img
                              className="img-thumbnail fixed-height-img full-width"
                              src="https://source.unsplash.com/random/100x100"
                              alt=""
                            />
                          </div>
                        </div>
                      </Carousel>
                    </div>
                    <hr />
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <img
                          className="img-circle _50x50"
                          src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                          alt=""
                        />
                      </div>
                      <div className="px-2">
                        <div className="mb-2">
                          <span>
                            Submitted by{" "}
                            <a href="#" className="text-reset">
                              <strong>Techdarma</strong>
                            </a>
                          </span>
                          <div>
                            <span className="fs-12 text-muted">
                              one hour ago via iOS7
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-4">
                    <div className="text-primary fs-16 font-weight-bold mb-2">
                      Step 2. Check if the review doesn't adheres to our tips
                    </div>
                    <div>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Do not post photos or videos of other copyrighted
                            material that you don't have permission to use. Do
                            not post photos of people without their permission.
                            If they are children, ask the parents before you
                            post.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Opinions are almost always protected as free speech
                            except in the case of defamation (a false accusation
                            of an offense or a malicious misrepresentation of
                            someone's words or actions). true threats,
                            solicitations to commit crimes, incitement to
                            imminent lawless action, blackmail, fighting words
                            and obscenity.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Please ommit excessive exaggeration and do not lie.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Deliberately sabotaging the viability of a business
                            is strongly discouraged.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Please be accurate, avoid embellishment and broad
                            generalizations, and to relate only to your first
                            hand experiences.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          className="primary"
                          type="textarea"
                          bsSize="sm"
                          placeholder="(optional) Write a message to the user re: the tips"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="mb-">
                    <div className="text-primary fs-16 font-weight-bold mb-2">
                      Step 3. Check for Flags
                    </div>
                    <div>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            General Issue
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Offensive
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Profanity
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">Spam</span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Hate/Racist Based
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Illegal Drug Focused
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Notability
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Universally Illegal
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Vanity Page
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Incomplete/Not Useful
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Vandalism
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          className="primary"
                          type="textarea"
                          bsSize="sm"
                          placeholder="Please elaborate..."
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="bg-white">
              <Button color="primary">Submit</Button>
              <Button color="link" className="text-tertiary font-weight-bold">
                Skip
              </Button>
            </ModalFooter>
          </form>
        </Modal>

      </div>
    )
  }
}

const mapState = (state) => ({
  loggedInUser: state.user.my_profile,
  get_review_listing: state.movies.get_review_listing,
  get_movie_detail: state.movies.get_movie_detail,
  get_dispute_data: state.movies.get_dispute_data,
  fetch_theater_data: state.movies.fetch_theater_data,
  movie_details: state.item_detail.item_details, // from item_details
  back_to_listing: state.item_detail.back_to_listing, // from item_details
  current_location: state.home.current_location,
  my_profile: state.user.my_profile,
  advisory_list_data: state.movies.advisory_list,
  auth_data: state.auth.auth_login,
  editExistingMedia : state.shared.editExistingMedia,
  isEditEnable: state.shared.isEditEnable,
  editExistingEmbedMedia : state.shared.editExistingEmbedMedia,
  isEditedReviewId: state.shared.isEditedReviewId,
  isEditReviewEnabled: state.shared.isEditReviewEnabled,
  newReviewMediaSet: state.reviewMedia.newReviewMediaSet,
  newReviewMediaSetFinalized: state.reviewMedia.newReviewMediaSetFinalized,
  newReviewEmbedLinkSet: state.reviewMedia.newReviewEmbedLinkSet,
  newReviewEmbedLinkSetFinalized: state.reviewMedia.newReviewEmbedLinkSetFinalized,
  editReviewMediaSetFinalized:state.reviewMedia.editReviewMediaSetFinalized,
  followReviewMediaSet: state.reviewMedia.followReviewMediaSet,     
  followReviewEmbedLinkSetFinalized: state.reviewMedia.followReviewEmbedLinkSetFinalized,
  isFollowReviewEnable:state.shared.isFollowReviewEnable
});

const mapProps = (dispatch) => ({
  get_id_from_slug: ({ type, slug }) => dispatch(get_id_from_slug({ type, slug })), // from item_details
  get_type_detail: ({ type, id }) => dispatch(get_type_detail({ type, id })), // from item_details
  add_movie_review_vote: (data) => dispatch(add_movie_review_vote(data)),
  add_dispute_review: (params) => dispatch(add_dispute_review(params)),
  get_dispute_discussion: (id) => dispatch(get_dispute_discussion(id)),
  get_theater_data: (id) => dispatch(get_theater_data(id)),
  delete_dispute: (id) => dispatch(delete_dispute(id)),
  add_dispute_reply: (params) => dispatch(add_dispute_reply(params)),
  delete_dispute_reply: (id, reviewId) => dispatch(delete_dispute_reply(id, reviewId)),
  get_reviews_listing: (id, type) => dispatch(get_reviews_listing(id, type)),
  reset_reviews_listing: (id, type) => dispatch(reset_reviews_listings(id, type)),
  delete_movies_review: (id, movie_id, name) => dispatch(delete_movies_review(id, movie_id,name)),
  delete_movie_advisory: (id, movieId, type) => dispatch(delete_movie_advisory(id, movieId, type)),
  get_advisory_listing: (type) => dispatch(get_advisory_listing(type)),
  clear_type_details: () => dispatch(clear_type_details()),
  my_profile_info: () => dispatch(my_profile()),
  get_uploaded_media: (data) => dispatch(get_uploaded_media(data)),
  get_uploaded_media_follow: (data) => dispatch(get_uploaded_media_follow(data)),
  setItemDetailLoading: (data) => dispatch(setItemDetailLoading(data)),
  setEditExistingMedia:(data)=>dispatch(setEditExistingMedia(data)),
  uploadMediaInReviewMediaSetForNewReview: (data) => dispatch(uploadMediaInReviewMediaSetForNewReview(data)),
  uploadMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(uploadMediaInReviewEmbedLinkSetForNewReview(data)),
  finalizedMediaInReviewMediaSetForNewReview: (data) => dispatch(finalizedMediaInReviewMediaSetForNewReview(data)),
  finalizedMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForNewReview(data)),
  uploadMediaInReviewMediaSetForEditReview: (data) => dispatch(uploadMediaInReviewMediaSetForEditReview(data)),
  uploadMediaInReviewMediaSetForFollowReview: (data) => dispatch(uploadMediaInReviewMediaSetForFollowReview(data)),
  finalizedMediaInReviewEmbedLinkSetForFollowReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForFollowReview(data)),
});


export default withRouter(connect(mapState, mapProps)(MovieDetails));