import React, { Component } from "react";
import { Map, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import Routing from "./RoutingMachine";
import { get_map_data } from '../../../actions/business';
import { connect } from "react-redux";
import osm from "../../atoms/osm-providers"

class LeafletMap extends Component {
  state = {
    lat: this.props.businessDetails ? this.props.businessDetails.address[0].latitude : 0,
    lng: this.props.businessDetails ? this.props.businessDetails.address[0].longitude : 0,
    zoom: 14,
    isMapInit: false,
    tolat: 0,
    tolon: 0,
    keyMAP: "",
    travelMode: "DRIVING",
    routes: [],
	toaddress : '',
	address : this.props.businessDetails ? (this.props?.businessDetails?.address[0]?.address1 + ',' + this.props.businessDetails?.address[0]?.city + ',' + this.props.businessDetails?.address[0]?.state +' '+ this.props.businessDetails?.address[0]?.zipcode + ',' + this.props.businessDetails?.address[0]?.country) : ''
  };
  saveMap = map => {
    this.map = map;
    this.setState({
      isMapInit: true
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.destinationDetails !== this.props.destinationDetails) {
		
      this.setState({
        tolat: nextProps.destinationDetails.latitude ? nextProps.destinationDetails.latitude : 0,
        tolon: nextProps.destinationDetails.longitude ? nextProps.destinationDetails.longitude : 0,
        isMapInit: true,
        keyMAP: Math.random(),
        travelMode: nextProps.destinationDetails.travelMode,
		toaddress : nextProps.destinationDetails.addressTo ? nextProps.destinationDetails.addressTo : "",
		
      });

    }
  }

  initItinerary = (itinerary) => {
    
    //this.setState({ routes: itinerary});
    this.props.get_map_data(itinerary)
    //this.setState({itinerary});
  }


  render() {
    let { address,toaddress } = this.state;
	const positionCenter = [(this.state.lat + 0.0029792), (this.state.lng - 0.0171707)];
    const position = [this.state.lat, this.state.lng];
    const topostion = [this.state.tolat, this.state.tolon];
	return (
      <Map key={this.state.keyMAP} zoomControl={false} mode={this.state.travelMode} center={positionCenter} zoom={this.state.zoom} ref={this.saveMap} style={{ position: 'absolute', zIndex: '0' }}>
        <ZoomControl position="bottomright" />
         <TileLayer
			url={osm.maptiler.url}
			attribution={osm.maptiler.attribution}
        />
		<Marker position={position}>
            <Popup>
              <span>{address.replace("null,", "")}</span>
            </Popup>
          </Marker>
		<Marker position={topostion}>
            <Popup>
              <span>{toaddress}</span>
            </Popup>
          </Marker>  
        {this.state.isMapInit && <Routing itineraryReady={this.initItinerary} from={topostion} to={position} map={this.map} travelType={this.state.travelMode} />}
      </Map>
    );
  }
}
const mapProps = (dispatch) => ({
  get_map_data: (data) => dispatch(get_map_data(data)),
});
export default connect(null, mapProps)(LeafletMap)