const initialState = {
  auth_login: localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null,
  isLoggedIn: localStorage.getItem("token") !== null ? true : false,
  auth_error: null,
  captcha: null,
  captcha_error: "",
  category_list: [],
  email_signup_response: null,
  user_information: null,
  email_signup_error: null,
  reset_password_error: null,
  resend_activation_email_error: null,
  locations: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_INITIAL_AUTH_NEW':
      return initialState;

    case "AUTH_LOGIN":
      state.auth_login = action.payload;
      return { ...state };

    case "AUTH_ERROR":
      state.auth_login = null;
      state.auth_error = action.payload;
      state.isLoggedIn = false;
      return { ...state };

    case "IS_LOGIN_TRUE":
      state.isLoggedIn = true;
      return { ...state };

    case "GET_CAPTCHA":
      state.captcha = action.payload;
      return { ...state };

    case "CAPTCHA_ERROR":
      state.captcha_error = action.payload;
      return { ...state };

    case "GET_CATEGORY_LIST":
      state.category_list = action.payload;
      return { ...state };

    case "EMAIL_SIGNUP_RESPONSE":
      state.email_signup_response = action.payload;
      return { ...state };

    case "GET_USER_INFORMATION":
      state.user_information = action.payload;
      return { ...state };

    case "EMAIL_SIGNUP_ERROR":
      state.email_signup_error = action.payload;
      return { ...state };

    case "RESET_PASSWORD_ERROR":
      state.reset_password_error = action.payload;
      return { ...state };

    case "RESEND_ACTIVATION_EMAIL_ERROR":
      state.resend_activation_email_error = action.payload;
      return { ...state };

    case "GET_LOCATIONS":
      state.locations = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default auth;
