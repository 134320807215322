/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, Input, DropdownItem } from 'reactstrap';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import allCatIcon from "../../../assets/images/icons/all-category-top-search.png";
import businessIcon from "../../../assets/images/icons/business-top-search.png";
import productsIcon from "../../../assets/images/icons/products-top-search.png";
import moviesIcon from "../../../assets/images/icons/movies-top-search.png";
import professionalIcon from "../../../assets/images/icons/professionals-top-search.png";
import actorIcon from "../../../assets/images/icons/actors-top-search.png";
import crpIcon from "../../../assets/images/icons/crp-hover.png";
import peopleIcon from "../../../assets/images/icons/people-top-search.png";
import { get_searchReviews, clear_search_reviews } from '../../../actions/review';
import OutsideClickHandler from "react-outside-click-handler";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { add_tempLocation } from '../../../actions/home';


class CreateAReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewTrendingType: 'national',
      mainViewType: 'feed',
      createReviewModalToggle: false,
      viewReactedType: 'all',
      viewPeopleWhoReactedModalToggle: false,
      confirmDeleteModalToggle: false,
      viewEditReviewStep: 'writeReview',
      drawerModalPopupToggleAddMovie: false,
      search_name: "",
      content_type: "All",
      searchReviewData: [],
      unableToSet: true,
      contentImage: allCatIcon,
      latitude: this.props.currentLocaiton && this.props.currentLocaiton.lat,
      longitude: this.props.currentLocaiton && this.props.currentLocaiton.lng,
      latlongs: '',
      address: this.props.currentLocaiton ? this.props.currentLocaiton.city + ", " + this.props.currentLocaiton.state + ", " + this.props.currentLocaiton.country : "",
      newplaceholder: this.props.currentLocaiton ? this.props.currentLocaiton.city + ", " + this.props.currentLocaiton.state + ", " + this.props.currentLocaiton.country : "",
    };
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    let { unableToSet } = this.state
    if (nextProps.searchReviews &&
      Array.isArray(nextProps.searchReviews.results) &&
      nextProps.searchReviews.results.length > 0 && unableToSet) {
      this.setState({
        searchReviewData: nextProps.searchReviews.results
      });
    } else {
      this.setState({
        searchReviewData: []
      });
    }

    if (nextProps?.currentLocaiton) {
      this.setState({
        newplaceholder: nextProps?.currentLocaiton ? nextProps?.currentLocaiton.city + ", " + nextProps?.currentLocaiton.state + ", " + nextProps?.currentLocaiton.country : "",
      })
    }
  }

  componentDidMount() {

    if (this.props.createReviewModalToggle) {
      this.setState(prevState => ({
        createReviewModalToggle: !prevState.createReviewModalToggle,
      }))
    }

    this.props.clear_search_reviews()

  }

  // Change State of input field	
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    let location = {};
    const { content_type, latitude, longitude } = this.state;
    location.lat = latitude;
    location.lng = longitude;
    if (name === 'search_name') {
      if (e.target.value.length > 0 && value !== "") {
        this.setState(
          {
            [name]: value,
            unableToSet: true,
          }, () => this.props.get_searchReviews(JSON.stringify(location), value, content_type));

      } else {
        this.setState(
          {
            searchReviewData: [],
            search_name: '',
            unableToSet: false,

          }, () => {
            this.props.clear_search_reviews();
          });

      }
    }
  }

  handleChangeAddress = address => {
    this.setState({ address });
  };

  handleOnClickCategory = (type, ImageType) => {
    const { latitude, longitude, search_name } = this.state
    let location = {};
    location.lat = latitude;
    location.lng = longitude;
    if (type !== "") {

      this.setState(
        {
          content_type: type,
          contentImage: ImageType
        }, () => this.props.get_searchReviews(JSON.stringify(location), search_name, type));
    }
  }

  handleSelect = (e, id, name, slug, content_type) => {
    e.preventDefault();
    let subdomain = content_type?.toLowerCase();
    this.props.history.push(`/${subdomain}/${id}`)
  }

  handleSelectAddress = async (address) => {
    const { search_name, content_type, latitude, longitude } = this.state;
    let city = '';
    let county = '';
    let state = '';
    let country = '';
    let location = {};

    const result = await geocodeByAddress(address);
    result && result[0] && result[0].address_components.map((addr) => {
      if (addr.types[0] === "locality") {
        //city name
        city = addr.long_name
      } else if (addr.types[0] === "administrative_area_level_2") {
        //county name
        county = addr.long_name
      } else if (addr.types[0] === "administrative_area_level_1") {
        //state name
        state = addr.short_name
      } else if (addr.types[0] === "country") {
        //state name
        country = addr.short_name
      }
    })
    const latlng = await getLatLng(result[0]);

    this.setState({
      latitude: latlng.lat,
      longitude: latlng.lng,
      address: address,
    });

    const data = {
      "city": city,
      "county": county,
      "state": state,
      "country": country,
      "locality2": "",
      "address_text": address,
      "lat": this.state.lat,
      "lng": this.state.lng
    }
    await this.props.add_tempLocation(data);


    location.lat = latitude;
    location.lng = longitude;
    if (search_name) {
      this.props.get_searchReviews(JSON.stringify(location), search_name, content_type)
    }
  };

  createReviewModalToggle = (data) => {
    this.setState({
      createReviewModalToggle: !this.state.createReviewModalToggle,
      address: "",
      latitude: this.props.currentLocaiton && this.props.currentLocaiton.lat,
      longitude: this.props.currentLocaiton && this.props.currentLocaiton.lng,
      search_name: "",
      searchReviewData: []
    });
    if (data === 'exit') {
      this.props.clear_search_reviews();
    }
  };

  handleCloseClick = () => {
    this.setState({ address: "" })
  }

  render() {
    const { search_name, contentImage, searchReviewData, newplaceholder } = this.state;

    const searchOptions = {
      componentRestrictions: { country: ['us', 'ca'] },
      types: ['(regions)']
    };

    return (
      <div>
        {/* Create Review Modal Popup */}
        <Modal isOpen={this.state.createReviewModalToggle} toggle={this.createReviewModalToggle} style={{ maxWidth: '530px' }}>
          <ModalHeader toggle={() => this.createReviewModalToggle('exit')}>CREATE A REVIEW</ModalHeader>
          <ModalBody>
            <h2 className="text-dark fs-38">Search for Anything </h2>
            <p className="text-tertiary ff-base font-weight-bold mb-3">Search local or national using the form.</p>
            <div className="d-flex">
              <div className="mr-2 flex-grow-1">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    this.setState({ isSearching: false })
                  }}>
                  <div className="d-flex mb-3">
                    <UncontrolledDropdown size="sm">
                      <DropdownToggle color={`${contentImage === allCatIcon ? 'primary' : 'tertiary'}`} className="text-white d-flex align-items-center">
                        {contentImage === allCatIcon ? "All" : <img src={contentImage} width="20" alt="icon" />}
                        <span className="text-darker ml-2">
                          <FontAwesomeIcon icon="caret-down" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="menu-white no-caret rounded-0 results-dropdown">
                        <DropdownItem type="all" key={"all"} onClick={() => {
                          this.handleOnClickCategory("All", allCatIcon);
                        }}>
                          {/* <img className="mr-3" src={allCatIcon} width="20" alt="icon" /> */}
                        results in <strong>all categories</strong>
                        </DropdownItem>
                        {/* <DropdownItem className="my-1" divider /> */}
                        <DropdownItem type="business" key={"business"} onClick={() => {
                          this.handleOnClickCategory("business", businessIcon);
                        }}>
                          {/* <img className="mr-3" src={businessIcon} width="20" alt="icon" /> */}
                          results in <strong>Business</strong>
                        </DropdownItem>
                        {/* <DropdownItem className="my-1" divider /> */}
                        <DropdownItem type="products" key={"products"} onClick={() => {
                          this.handleOnClickCategory("products", productsIcon);
                        }}>
                          {/* <img className="mr-3" src={productsIcon} width="20" alt="icon" /> */}
                          results in <strong>Products</strong>
                        </DropdownItem>
                        {/* <DropdownItem className="my-1" divider /> */}
                        <DropdownItem type="movies" key={"movies"} onClick={() => {
                          this.handleOnClickCategory("movies", moviesIcon);
                        }}>
                          {/* <img className="mr-3" src={moviesIcon} width="20" alt="icon" /> */}
                          results in <strong>Movies</strong>
                        </DropdownItem>
                        {/* <DropdownItem className="my-1" divider /> */}
                        <DropdownItem type="professionals" key={"professionals"} onClick={() => {
                          this.handleOnClickCategory("professionals", professionalIcon);
                        }}>
                          {/* <img className="mr-3" src={professionalIcon} width="20" alt="icon" /> */}
                          results in <strong>Professionals</strong>
                        </DropdownItem>
                        {/* <DropdownItem className="my-1" divider /> */}
                        <DropdownItem type="actors" key={"actors"} onClick={() => {
                          this.handleOnClickCategory("actors", actorIcon);
                        }}>
                          {/* <img className="mr-3" src={actorIcon} width="20" alt="icon" /> */}
                          results in <strong>Actors</strong>
                        </DropdownItem>
                        {/* <DropdownItem className="my-1" divider /> */}
                        <DropdownItem type="crp" key={"projects"} onClick={() => {
                          this.handleOnClickCategory("projects", crpIcon);
                        }}>
                          {/* <img className="mr-3" src={crpIcon} width="20" alt="icon" /> */}
                          results in <strong>CRP's</strong>
                        </DropdownItem>
                        {/* <DropdownItem className="my-1" divider /> */}
                        <DropdownItem type="people" key={"people"} onClick={() => {
                          this.handleOnClickCategory("people", peopleIcon);
                        }}>
                          {/* <img className="mr-3" src={peopleIcon} width="20" alt="icon" /> */}
                          search only <strong>People</strong>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Input bsSize="sm" type="text" name="search_name" autoComplete="off" onChange={this.handleChange} value={search_name} className="primary create-review-search-input" placeholder={this.state.isSearching ? '' : 'Search for your favorite business, product, or movie!'} onFocus={() => this.setState({ isSearching: true })} />
                    {/* Instant Search Results */}
                    {this.state.isSearching ?
                      <ul className="list-unstyled text-dark instant-results bg-white w-60 shadow" style={{ maxHeight: '400px', overflowY: 'auto', marginTop: '33px', width: '417px' }}>
                        {Array.isArray(searchReviewData) && searchReviewData && searchReviewData.length > 0 ? (searchReviewData.map((item, index) => (
                          <Link
                            className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
                            to={{
                              //  pathname: `/${item._source?.content_type?.toLowerCase()}/${item._source?.slug}`,
                              pathname: `/${(this.state.content_type == "All" && item._source?.content_type) ? (item._source?.content_type).toLowerCase() : (item?._index==="wr_professional" ? item?._source?.professional_type
                              : this.state.content_type)}/${item._source?.slug ? item._source?.slug : item?._id}`,
                              state: { id: item?._id }
                            }}>
                            <li className="result-item" key={index} role="button" 
                            // onClick={(e) => this.handleSelect(e, item._id, item._source.name, item._source.slug, item._source.content_type)}
                            >
                              <div className="result-link fs-14">
                                <div className="d-flex">
                                  <div className="mr-2">
                                    <img src={item._source && item._source.listing_profileimage && item._source.listing_profileimage.length > 0 ? item._source.listing_profileimage[0].thumbnail : item.listing_profileimage && item.listing_profileimage.length > 0 ? item.listing_profileimage[0].thumbnail : require('../../../assets/images/icons/placeholder-img.png')
                                    } width={40}
                                      onError={(error) =>
                                        (error.target.src = `require('../../../assets/images/icons/placeholder-img.png')`)
                                      }
                                      alt="" />

                                  </div>
                                  <div>
                                    <span className="font-weight-bold">{item._source ? item._source.name : item.name ? item.name : ""}</span>
                                    <div>
                                      <img src={require('../../../assets/images/icons/norating-alt.png')} alt="" />
                                    </div>
                                    <div>
                                      <span>{item._source && item._source.category != null ? item._source.category : item.category ? item.category : ""}</span>
                                    </div>
                                    <div className="text-muted">
                                      <span>{item._source && item._source?.address?.length > 0 ? item._source?.address[0]?.city + ", " + item._source?.address[0]?.state + ", " + item._source?.address[0]?.zipcode : ""}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>

                          </Link>
                        ))
                        ) : null}
                      </ul>

                      : ""}

                  </div>
                </OutsideClickHandler>
                <div className="d-flex">

                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChangeAddress}
                    onSelect={this.handleSelectAddress}
                    icon="marker"
                    iconPosition="left"
                    searchOptions={searchOptions}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                      return (
                        <div className="Demo__search-bar-container position-relative with-map-icon">
                          <div className="Demo__search-input-container ">
                            <Input
                              {...getInputProps({
                                placeholder: newplaceholder,
                                className: 'primary create-review-place-input',
                                bsSize: "sm",
                                name: 'address',
                                autoFocus: true,
                                onFocus: () => {
                                  this.setState({
                                    address: ''
                                  })
                                }
                              })}
                            />
                            {/* {this.state.address && this.state.address.length > 0 && (
                              <button
                                className="Demo__clear-button"
                                onClick={this.handleCloseClick}
                              >
                                x
                              </button>
                            )} */}
                          </div>
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container position-absolute">
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';

                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, { className })}
                                  >
                                    <FontAwesomeIcon className="mr-2" fixedWidth icon="map-marker-alt" />
                                    <span>{!isNaN(parseInt(suggestion?.formattedSuggestion?.mainText)) ?
                                      `${suggestion?.formattedSuggestion?.mainText}, ${suggestion?.formattedSuggestion?.secondaryText}`
                                      : `${suggestion?.description}`}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    }}
                  </PlacesAutocomplete>
                </div>
              </div>

              <Link className="btn btn-primary d-flex flex-column align-items-center justify-content-center"
                to={{
                  pathname: `/search`,
                  search: `?q=${this.state.search_name}`,
                  state: { contenttype: this.state.content_type.toLowerCase() }
                }}><FontAwesomeIcon icon="search" /> </Link>

            </div>

          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapState = (state) => ({
  searchReviews: state.reviews.searchReviews,
  currentLocaiton: state.home.current_location
});

const mapProps = (dispatch) => ({
  get_searchReviews: (location, query, contentType) => dispatch(get_searchReviews(location, query, contentType)),
  clear_search_reviews: () => dispatch(clear_search_reviews()),
  add_tempLocation: (data) => dispatch(add_tempLocation(data))

});


export default withRouter(connect(mapState, mapProps)(CreateAReview));
