
const initialState = {
    newReviewMediaSet: [], // Create a review, Upload PopUp
    newReviewMediaSetFinalized: [], // Create a review, Move Upload Box Selectives to Finalized State, so that it can be displayed on review box
    newReviewEmbedLinkSet: [], // Create a review, Upload PopUp
    newReviewEmbedLinkSetFinalized: [], // Create a review, Move Upload Box Selectives to Finalized State, so that it can be displayed on review box


    editReviewMediaSet: [], // Create a review, Upload PopUp
    editReviewMediaSetFinalized: [], // Create a review, Move Upload Box Selectives to Finalized State, so that it can be displayed on review box

    editReviewEmbedLinkSet: [], // Create a review, Upload PopUp
    editReviewEmbedLinkSetFinalized:[],  // Create a review, Move Upload Box Selectives to Finalized State, so that it can be displayed on review box

    followReviewMediaSet: [], 
    followReviewMediaSetFinalized: [], 

    followReviewEmbedLinkSet: [], 
    followReviewEmbedLinkSetFinalized: [] 

};

export const UPLOAD_MEDIA_SET_TO_NEW_REVIEW = "UPLOAD_MEDIA_SET_TO_NEW_REVIEW";
export const FINALIZED_MEDIA_SET_TO_NEW_REVIEW = "FINALIZED_MEDIA_SET_TO_NEW_REVIEW";

export const UPLOAD_EMBED_LINK_SET_TO_NEW_REVIEW = "UPLOAD_EMBED_LINK_SET_TO_NEW_REVIEW";
export const FINALIZED_EMBED_LINK_SET_TO_NEW_REVIEW = "FINALIZED_EMBED_LINK_SET_TO_NEW_REVIEW";


export const UPLOAD_MEDIA_SET_TO_EDIT_REVIEW = "UPLOAD_MEDIA_SET_TO_EDIT_REVIEW";
export const FINALIZED_MEDIA_SET_TO_EDIT_REVIEW = "FINALIZED_MEDIA_SET_TO_EDIT_REVIEW";

export const UPLOAD_EMBED_LINK_SET_TO_EDIT_REVIEW = "UPLOAD_EMBED_LINK_SET_TO_EDIT_REVIEW";
export const FINALIZED_EMBED_LINK_SET_TO_EDIT_REVIEW = "FINALIZED_EMBED_LINK_SET_TO_EDIT_REVIEW";

export const UPLOAD_MEDIA_SET_TO_FOLLOW_REVIEW = "UPLOAD_MEDIA_SET_TO_FOLLOW_REVIEW";
export const FINALIZED_MEDIA_SET_TO_FOLLOW_REVIEW = "FINALIZED_MEDIA_SET_TO_FOLLOW_REVIEW";

export const UPLOAD_EMBED_LINK_SET_TO_FOLLOW_REVIEW = "UPLOAD_EMBED_LINK_SET_TO_FOLLOW_REVIEW";
export const FINALIZED_EMBED_LINK_SET_TO_FOLLOW_REVIEW = "FINALIZED_EMBED_LINK_SET_TO_FOLLOW_REVIEW";



const ReviewMedia = (state = initialState, action) => {
    switch (action.type) {
        // New review
        case UPLOAD_MEDIA_SET_TO_NEW_REVIEW:
            state.newReviewMediaSet = action.payload
            return { ...state };

        case UPLOAD_EMBED_LINK_SET_TO_NEW_REVIEW:
            state.newReviewEmbedLinkSet = action.payload
            return { ...state };

        case FINALIZED_MEDIA_SET_TO_NEW_REVIEW:
            state.newReviewMediaSetFinalized = action.payload
            return { ...state };

        case FINALIZED_EMBED_LINK_SET_TO_NEW_REVIEW:
            state.newReviewEmbedLinkSetFinalized = action.payload
            return { ...state };


        // Edit review
        case UPLOAD_MEDIA_SET_TO_EDIT_REVIEW:
            state.editReviewMediaSet = action.payload
            return { ...state };

        case UPLOAD_EMBED_LINK_SET_TO_EDIT_REVIEW:
            state.editReviewEmbedLinkSet = action.payload
            return { ...state };

        case FINALIZED_MEDIA_SET_TO_EDIT_REVIEW:
            state.editReviewMediaSetFinalized = action.payload
            return { ...state };

        case FINALIZED_EMBED_LINK_SET_TO_EDIT_REVIEW:
            state.editReviewEmbedLinkSetFinalized = action.payload
            return { ...state };
        // Follow up/ create new review 

        case UPLOAD_MEDIA_SET_TO_FOLLOW_REVIEW:
            state.followReviewMediaSet = action.payload
            return { ...state };

        case UPLOAD_EMBED_LINK_SET_TO_FOLLOW_REVIEW:
            state.followReviewEmbedLinkSet = action.payload
            return { ...state };

        case FINALIZED_MEDIA_SET_TO_FOLLOW_REVIEW:
            state.followReviewMediaSetFinalized = action.payload
            return { ...state };

        case FINALIZED_EMBED_LINK_SET_TO_FOLLOW_REVIEW:
            state.followReviewEmbedLinkSetFinalized = action.payload
            return { ...state };
        default:
            return state;
    }
}

export default ReviewMedia;
