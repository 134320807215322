import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import osm from "../../atoms/osm-providers"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Progress,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import {
  AvForm,
  AvField,
  
} from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import moment from "moment";
import { callApi } from "../../../utils/apiCaller";
import { Icon } from "leaflet";
import { Map, Marker, Popup, TileLayer, ZoomControl, Circle } from "react-leaflet";
import Control from 'react-leaflet-control';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import {
  media_upload_scroll,
  fetch_media_data,
  clear_media_dispatch,
  upload_add_captions,
  fetch_taxonomy_list,
} from "../../../actions/myProfiles";

import {
  upload_media,
  initFetchBusinessSubCategories,
  clear_cat_sub_business
} from "../../../actions/footer";

import {
  add_my_post,
  get_album_types_list,
  get_album_type_data,
  delete_selected_gallery_media
} from "../../../actions/user";

import {
  add_business
} from "../../../actions/business";
import { toast } from "react-toastify";

const validate = Yup.object().shape({
  location_set: Yup.array()
    .of(
      Yup.object().shape({
        area_served: Yup.string().min(4, "too short").required("Required"),
      })
    )
    .required("Must have websites")
    .min(3, "Minimum of 3 websites"),
  paymentoptions_set: Yup.array()
    .of(
      Yup.object().shape({
        paymentoptions: Yup.number().required("Please select Payment Option"),
      })
    )
    .required("Payment Option is required")
    .min(3, "Minimum of 3 Phone"),
  hours_operation_set: Yup.array()
    .of(
      Yup.object().shape({
        info: Yup.string().min(4, "too short").required("Required"),
        start_time: Yup.number().required("Please select Start Time"),
        end_time: Yup.number().required("Please select End Time"),
        day_of_week: Yup.number().required("Please select Day of Week"),
      })
    )
    .required("Hours of Operation is required")
    .min(3, "Minimum of 3 Hours of Operation"),

});

class AddBusinessModal extends React.Component {
  constructor(props) {
    super(props);
    this.myScrollPhotoRef = React.createRef();
    this.myScrollVideoRef = React.createRef();

    this.refGalleryVideoScroll = React.createRef();
    this.refGalleryImageScroll = React.createRef();

    this.refScrollDeleteImages = React.createRef();
    this.refScrollDeleteVideos = React.createRef();
    this.wrapperRef = React.createRef();
    this.state = {
      addBusinessForm: {
        name: "",
        country: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        hour_start_time: "",
        hour_end_time: "",
        hour_day_of_week: "",
        hour_info: "",
        radial_distance: "",
        drawer: true,
        is_draft: false,
        myPostsList: {},
        termsCondtions: false,
        additional_info: {
          is_tos_accepted: false,
        },
        taxonomy: [""],
        media: [""],
        email_set: {
          email: "",
          email_type: ""
        },
        website_set: [{
          website: ""
        }],
        location_radial_set: [
          {
            radial_distance: "",
          }
        ],
        location_set: [
          {
            area_served: ""
          }
        ],
        paymentoptions_set: [
          {
            paymentoptions: ""
          }
        ],
        phone_set: [{
          ph1: "",
          ph2: "",
          ph3: "",
          phone: ""
        }],
        address: [{
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipcode: ""
        }],
        hours_operation_set: [{
          start_time: "",
          end_time: "",
          day_of_week: "",
          info: ""
        }]
      },
      country: "usa",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      category_id: '',
      subcategory_id: '',
      name: "",
      email: "",
      email_type: "",
      website: "",
      radial_distance: "",
      email_set: {
        email: "",
        email_type: ""
      },
      location_set: [
        {
          area_served: ""
        }
      ],
      paymentoptions_set: [
        {
          paymentoptions: ""
        }],
      hours_operation_set: [{
        start_time: "",
        end_time: "",
        day_of_week: "",
        info: ""
      }],
      address: [{
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: ""
      }],
      location_latLong: [
        {
          latitude: 0,
          longitude: 0,
          address: ""

        }
      ],
      mediaSet: [],
      uploadMediaModal: false,
      media: [],
      fileReviewStatus: false,
      selectedFilesPreview: [],
      latestUploadedData: {},
      showVideoMedia: true,
      fetchTaxonomy: [],
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      files: [],
      nameError: "",
      cityError: "",
      categoryError: "",
      stateError: "",
      zipcodeError: "",
      termsError: "",
      phoneError: "",
      businessSubcategory: [],
      uploadCaptions: {},
      taxonomycategoryId: "",
      fetchTaxomomyCategory: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      albumType: "",
      uploadMediaPostModal: false,
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
      number_part_1: "",
      number_part_2: "",
      number_part_3: "",
      hour_start_time: "",
      hour_end_time: "",
      hour_day_of_week: "",
      hour_info: "",
      termsCondtions: false,
      currentLat: 0,
      currentLong: 0,
      zoom: 14,
      keyMAP: Math.random(),
      routes: [],
      address: "",
      lat: "",
      lng: "",
      is_draft: false
    };
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let {
      latestUploadedData,
      fetchGalleryScroll,
      fetchGalleryCount,
    } = this.state;
    if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypesList: nextProps.album_types_list,
        },
        selectedUpMediaType: "upload",
        showGalleryType: "images",
      });
    }

    if (
      nextProps.album_type_data &&
      Object.keys(nextProps.album_type_data).length > 0
    ) {
      let item = nextProps.album_type_data;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypeData: nextProps.album_type_data,
        },
        fetchGalleryScroll,
        fetchGalleryCount,
      });
    }

    if (nextProps.businessSabcategories && Array.isArray(nextProps.businessSabcategories) && nextProps.businessSabcategories.length > 0) {
      this.setState({ businessSubcategory: nextProps.businessSabcategories });
    }

    if (nextProps.fetch_taxonomy_listData) {
      let taxCatListing = nextProps.fetch_taxonomy_listData;
      if (
        taxCatListing &&
        Array.isArray(taxCatListing) &&
        taxCatListing.length > 0
      ) {
        if (taxCatListing[0].isTypes == "images") {
          this.setState({
            fetchTaxonomyListingImages: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        } else {
          this.setState({
            fetchTaxonomyListingVideos: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        }
      }
    }

    if (
      nextProps.current_location &&
      Object.keys(nextProps.current_location).length > 0
    ) {
      if (nextProps.current_location?.lat != this.props.current_location?.lat && nextProps.current_location?.lng != this.props.current_location?.lng && this.props.current_location != null) {
        this.setState(
          {
            currentLat: nextProps.current_location?.lat,
            currentLong: nextProps.current_location?.lng,
          },
          () => {
            this.mapCordinatesSet();
          }
        );
      } else {

        this.setState(
          {
            currentLat: nextProps.current_location?.lat,
            currentLong: nextProps.current_location?.lng,
          });
      }

    }

    if (nextProps.fetch_save_response) {
      if (nextProps.fetch_save_response && nextProps.fetch_save_response.id && nextProps.fetch_save_response.slug) {
        let slug = null;
        let businessId = "";
        if (nextProps.fetch_save_response.id != "") {
          businessId = nextProps.fetch_save_response.id;
        }
        if (nextProps.fetch_save_response.slug != "") {
          slug = nextProps.fetch_save_response.slug;
        }
        this.redirecttobusiness(businessId, slug);

      }

    }

  }


  componentWillUnmount() {
    this.props.clear_cat_sub_business();
  }

  firstMethod = (e) => {
  
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("t");
   
    let fieldIntIndex = parseInt(fieldIndex, 10);
    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {

      // It should not be last input field
      if (fieldIntIndex < 3) {

        // Get the next input field using it's name
        const nextfield = document.querySelector(
            `input[name=number_part_${fieldIntIndex + 1}]`
        );
        
        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (name == "name") {
      this.setState({
        nameError: ""
      });
    } else if (name == "city") {
      this.setState({
        cityError: ""
      });
    } else if (name == "category_id") {
      this.setState({
        categoryError: ""
      });
    } else if (name == "state") {
      this.setState({
        stateError: ""
      });
    } else if (name == "zipcode") {
      this.setState({
        zipcodeError: ""
      });
    } else if (name == "termsCondtions") {
      this.setState({
        termsError: ""
      });
    } else if (name == "number_part1") {
      this.setState({
        phoneError: ""
      });
    }

    if(name == "number_part1"){
      this.setState({
        number_part_1: value
      });
    }
    if(name == "number_part2"){
      this.setState({
        number_part_2: value
      });
    }
    if(name == "number_part3"){
      this.setState({
        number_part_3: value
      });
    }


    if (name == "category_id") {
      let type = "category_id"
      this.props.initFetchBusinessSubCategories(e.target.value, type);
    }
    if (name == "subcategory_id") {
      let type = "subcategory_id"
      this.props.initFetchBusinessSubCategories(e.target.value, type);
    }

  }

  handleChangeBusiness = (e, index) => {
    const { paymentoptions_set, location_set, hours_operation_set } = this.state
    const { name, value } = e.target
    if (name.includes('payment') && paymentoptions_set.length <= index) {
      paymentoptions_set.push({ paymentoptions: "" })
      this.setState({ paymentoptions_set })
    }
    if (name.includes('area') && location_set.length <= index) {
      if (this.state.radial_distance != "") {
        location_set.push({ area_served: "", radial_distance: "" })
      } else {
        location_set.push({ area_served: "" })
      }

      this.setState({ location_set })
    }
    if (name.includes('hours') && hours_operation_set.length <= index) {
      hours_operation_set.push({ start_time: "", end_time: "", day_of_week: "", info: "" })
      this.setState({ hours_operation_set })
    }


    if (name === 'payment_options') {
      paymentoptions_set[index].paymentoptions = value
      this.setState({ paymentoptions_set })
    }
    else if (name === 'area_served') {
      location_set[index].area_served = value;
      if (this.state.radial_distance != "") {
        location_set[index].radial_distance = this.state.radial_distance
      }
      this.setState({ location_set })
    } else if (name === 'hour_start_time') {
      hours_operation_set[index].start_time = value
      this.setState({ hours_operation_set })
    } else if (name === 'hour_end_time') {
      hours_operation_set[index].end_time = value
      this.setState({ hours_operation_set })
    } else if (name === 'hour_day_of_week') {
      hours_operation_set[index].day_of_week = value
      this.setState({ hours_operation_set })
    } else if (name === 'hour_info') {
      hours_operation_set[index].info = value
      this.setState({ hours_operation_set })
    }

  }

  handleremoveBusiness = (option) => {
    const { paymentoptions_set, location_set, hours_operation_set } = this.state
    if (option === 'paymentoptions_set') {
      paymentoptions_set.pop()
      this.setState({ paymentoptions_set })
    }
    else if (option === 'location_set') {
      location_set.pop()
      this.setState({ location_set })
    }
    else if (option === 'hours_operation_set') {
      hours_operation_set.pop()
      this.setState({ hours_operation_set })
    }

  }

  handleCancel = () => {
    this.setState({
      businessSubcategory: []
    });
    this.props.clear_cat_sub_business();
    this.props.toggleBusinessModal();
  }

  handleBusinessSubmit = () => {
    let { name, city, state, zipcode, address1, address2
      , email_set, location_set, paymentoptions_set, hours_operation_set, address, email, email_type, website, radial_distance, start_time,
      end_time, day_of_week, info, category_id, termsCondtions, country, number_part_1, number_part_2, number_part_3 } = this.state;

    if (name == "" && city == "" && category_id == "" && state == "" && zipcode == "" && termsCondtions == false) {
      this.setState({
        nameError:
          "This field is required",
        cityError:
          "City is required",
        categoryError:
          "This field is required",
        stateError:
          "State name is required",
        zipcodeError:
          "Zipcode/Postal code is required",
        termsError:
          "This field is required",
      });
    }
	if (city == "") {
      this.setState({
        cityError:
          "City is required",
      });
    } 
	if (name == "") {
      this.setState({
        nameError:
          "This field is required",
      });
    }
	if (category_id == "") {
      this.setState({
        categoryError:
          "This field is required",
      });
    }
    if (state == "") {
      this.setState({
        stateError:
          "State name is required",
      });
    }
    if (zipcode == "") {
      this.setState({
        zipcodeError:
          "Zipcode/Postal code is required",
      });
    }
    if (termsCondtions == false) {
      this.setState({
        termsError:
          "This field is required",
      });
    } 
	if (country == "can") {
	 if (zipcode.length < 6) {
        this.setState({
          zipcodeError:
            "Ensure zipcode/postal code has 6 characters",
        });
      }
	 return false
    }
	if (country == "usa") {
	  if (zipcode.length < 5) {
        this.setState({
          zipcodeError:
            "Ensure zipcode/postal code has 5 characters",
        });
		return false
	  }
    }
	if (number_part_1 != "" || number_part_2 != "" || number_part_3 != "") {
		
	  if ((this.state.number_part_1.length + this.state.number_part_2.length + this.state.number_part_3.length) < 10) {
        this.setState({ phoneError: "Ensure this value has at least 10 characters (it has 7)." })
        return false
	  } 
	}  
	if(name != "" && city != "" && category_id != "" && state != "" && zipcode != "" && termsCondtions == true){
        
        let params = {}
        let media = []

        params = {
          name: this.state.name,
          is_draft: this.state.is_draft,
          country: country,
          additional_info: {
            is_tos_accepted: this.state.termsCondtions,
          },
          email_set: [{
            email: this.state.email,
            email_type: this.state.email_type
          }],
          website_set: [{
            website: this.state.website
          }],
          location_radial_set: [{
            radial_distance: this.state.radial_distance
          }],
          phone_set: [{
            ph1: this.state.number_part_1,
            ph2: this.state.number_part_2,
            ph3: this.state.number_part_3,
            phone: this.state.number_part_1 + this.state.number_part_2 + this.state.number_part_3
          }],
          address: [{
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zipcode: this.state.zipcode
          }],
          hoursofoperation_set: hours_operation_set && hours_operation_set[0] && hours_operation_set[0].start_time == "" ? {} : hours_operation_set,
          paymentoptions_set: paymentoptions_set && paymentoptions_set[0] && paymentoptions_set[0].paymentoptions == "" ? {} : paymentoptions_set,
          location_set: location_set && location_set[0] && location_set[0].area_served == "" ? {} : location_set,
          taxonomy: [this.state.category_id],
        }
        if (location_set.length > 0) {
          for (var i = 0; i < location_set.length; i++) {
            if (this.state.radial_distance != "") {
              location_set[i].radial_distance = this.state.radial_distance
            }
          }
        }
        if (this.state.uploadMedia.selectedMediaIds.length > 0) {
          params.media = this.state.uploadMedia.selectedMediaIds
        } else {
          toast(`Please add media with the listing.`, {
            autoClose: 3500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
          return 0;
        }
        this.props.add_business(params)
      }

  }

  // upload business images

  handleOnFilePostUploadChange = (event) => {
    
    let uploadFiles = event.target.files;
    let showFiles = [];
    for (const key of Object.keys(uploadFiles)) {
      let itemType = uploadFiles[key].type.split("/");
      let extName = itemType[0];
      showFiles.push({ id: "", url: extName === "image" ? URL.createObjectURL(uploadFiles[key]) : require("../../../assets/images/blank.png") });
    }
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        uploadFiles: showFiles,
        progress: 0,
      },
    });
    let progressPart = 100 / showFiles.length;
    let progress = 0;
    for (const key of Object.keys(uploadFiles)) {
      let data = new FormData();
      data.append("file", uploadFiles[key]);

      callApi(
        `/upload/multiuploader/?album=&instance=businessdrawer&image=undefined&doc=undefined`,
        "POST",
        data,
        true
      ).then((response) => {
        this.handleOnClickSelectGalleryMedia(response);
        if (showFiles.length === 1 || key === showFiles.length - 1) {
          progress = 100;
        } else {
          progress = progress + progressPart;
        }
        if (response && response.code !== 400 && response.code !== 500) {
          showFiles[key].id = response.id;
          showFiles[key].media_type = response.media_type;
          showFiles[key].thumbnail = response.thumbnail;
          if (response.media_type == "image") {
            this.props.fetch_taxonomy_list('Business', 'images');
          } else {
            this.props.fetch_taxonomy_list('Business', 'videos');
          }

          this.setState({
            ...this.state,
            uploadMedia: {
              ...this.state.uploadMedia,
              progress: progress,
              uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
              uploadedFiles: showFiles,
            },
          });
        }
      });
    }
  };
  handleCaptionByIndex = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    uploadCaptions[index] = value;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    let id = upload_data.id;
    this.setState({ uploadCaptions }, () =>
      this.props.upload_add_captions(id, data)
    );
  };

  redirecttobusiness = (businessId, slug) => {
    let path = "business";
    
    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: businessId }
    })
  }

  handleChangeTaxonomyData = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    data.category = value;
    let id = upload_data.id;
    this.setState({ taxonomycategoryId: value }, () =>
      this.props.upload_add_captions(id, data)
    );
  };

  handleOnClickRemoveSelectedMedia = (id) => () => {
    let removeMediaIds = [];
    removeMediaIds.push(id);
    this.props.delete_selected_gallery_media(removeMediaIds);
    let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
      (file) => file.id !== id
    );
    let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
      (file) => file.id !== id
    );
    let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
      (item) => item !== id
    );
    let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
      (file) => file.id !== id
    );
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: selectedMedia,
        selectedMediaIds: selectedMediaIds,
        uploadedFiles: uploadedFiles,
        uploadFiles: uploadFiles,
      },
    });
  };

  handleOnClickRemoveSelectedGalleryMedia = (media, index) => {
    // remove media from upload media state
    let { uploadMedia, editPost } = this.state;
    let id = media.id;
    let filterSelectedMediaIds = [];
    let filterSelectedMedia = [];

    if (id) {
      filterSelectedMediaIds = uploadMedia?.selectedMediaIds.filter(data => data !== id);
      filterSelectedMedia = uploadMedia?.selectedMedia.filter(data => data.id !== id);
    }
    uploadMedia['selectedMediaIds'] = filterSelectedMediaIds;
    uploadMedia['selectedMedia'] = filterSelectedMedia;

    if (editPost) {
      editPost["messagemedia_set"] = filterSelectedMediaIds;
    }

    this.setState({ uploadMedia, editPost }, () => { this.props.delete_selected_gallery_media([id]) });
  };

  handleOnClickSelectGalleryMedia = (media) => {
    if (media) {
      let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
      let newSelectedMediaIds;
      let newSelectedMedia;
      if (index !== -1) {
        newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
          (item) => item !== media.id
        );
        newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
          (item) => item.id !== media.id
        );
      } else {
        newSelectedMediaIds = [
          ...this.state.uploadMedia.selectedMediaIds,
          media.id,
        ];
        newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
      }
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: newSelectedMedia,
          selectedMediaIds: newSelectedMediaIds,
        },
      });
    }
  };

  handleOnClickGalleryType = (type) => {
    let mediaType = "";
    if (type === "images") {
      mediaType = "image";
    } else if (type === "videos") {
      mediaType = "video";
    }
    this.props.get_album_type_data(mediaType, "", 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: type,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: mediaType,
        albumType: "",
        pageNo: 1,
      },
    });
  };

  handleOnClickAlbumTypeChange = (e) => {
    this.props.get_album_type_data("image", e.target.value, 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: "images",
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: e.target.value,
        pageNo: 1,
      },
    });
  };

  handleOnClickSelectedUploadMediaType = (type) => {
    if (type === "gallery") {
      this.props.get_album_type_data("image", "", 1);
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        showGalleryType: "images",
        uploadMedia: {
          ...this.state.uploadMedia,
          mediaType: "image",
          albumType: "",
          pageNo: 1,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "upload") {
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "embed") {
      let embedLinks = {
        0: "",
        1: "",
      };
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: embedLinks,
        },
      });
    }
  };

  embedLinkOnChange = (id) => (e) => {
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        embedLinks: {
          ...this.state.uploadMedia.embedLinks,
          [id]: e.target.value,
        },
      },
    });
  };

  mapEmbedLinks = () => {
    let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
    return embedKeys.map((emKey) => {
      return (
        <div className="d-flex mx-n2 mb-2" key={emKey}>
          <div className="flex-fill px-2">
            <Input
              type="url"
              bsSize="sm"
              className="bg-white"
              value={this.state.uploadMedia.embedLinks[emKey]}
              onChange={this.embedLinkOnChange(emKey)}
              placeholder="Embeded link"
            />
          </div>
          <div className="px-2">
            <Button
              title="Remove"
              color="danger"
              size="sm"
              hidden={emKey <= 1 ? true : false}
              onClick={() => {
                this.deleteEmbedLinkRow(emKey);
              }}
            >
              <FontAwesomeIcon icon="minus" />
            </Button>
          </div>
        </div>
      );
    });
  };

  deleteEmbedLinkRow = (emKey) => {
    let embedLinks = this.state.uploadMedia.embedLinks;
    if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
      delete embedLinks[emKey];
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: embedLinks,
        },
      });
    }
  };
  addEmbedlinkRow = () => {
    let newEmbedLinks = {};
    for (let i = 2; i < 5; i += 1) {
      if (
        Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
        -1
      ) {
        newEmbedLinks[i] = "";
        break;
      }
    }
    if (Object.keys(newEmbedLinks).length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {
            ...this.state.uploadMedia.embedLinks,
            ...newEmbedLinks,
          },
        },
      });
    }
  };

  handleOnClickUploadMedia = () => {
    this.props.get_album_types_list();
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        progress: 0,
        uploadFiles: [],
        uploadedFiles: [],
      },
      uploadMediaPostModal: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
    });
  };
  truncate = (filenameString) => {
    let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
    let extension = filenameString.substr(
      filenameString.lastIndexOf("."),
      filenameString.length - 1
    );
    let partial = filename.substring(filename.length - 3, filename.length);
    filename = filename.substring(0, 15);
    return filename + "..." + partial + extension;
  };

  confirmDeleteModalToggle = () => {
    if (this.state.deletePostId) {
      this.setState({ deletePostId: 0, deletePostType: "" });
    }
    this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
  };

  limitedPostsModalToggle = () => {
    this.setState({ limitedPostsModal: !this.state.limitedPostsModal });
  };

  uploadMediaModalToggle = () => {
    this.setState({
      ...this.state,
      uploadMediaPostModal: !this.state.uploadMediaPostModal,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: [],
        selectedMediaIds: [],
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        uploadedFiles: [],
        uploadFiles: [],
      },
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      taxonomycategoryId: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
    });
  };

  insertEmbedLinks = () => {

    let embedValues = Object.values(this.state.uploadMedia.embedLinks).filter(
      (item) => item !== ""
    );

    if (embedValues.length > 0) {
      embedValues = embedValues.map(
        (item) => "<a href=" + item + "><p>" + item + "</p></a>"
      );
      let newPost = {
        video: embedValues.join("\n"),
        exclude_list: [],
        question_rating_category: [],
        messagemedia_set: [],
      };

      this.props.add_my_post(newPost, this.state.filterType, this.state.pageNo);

      this.setState({
        ...this.state,
        /*limitedPostsModal:
          this.state.myPostsList.count < this.state.limitedPostsCount
            ? false
            : true,*/
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: {},
        },
        uploadMediaPostModal: false,
      });
    }
    //}
  };

  handleScrollGalleryImage = (e) => {
    let { refGalleryImageScroll } = this;
    let { fetchGalleryAlbum } = this.state;

    let clientHeight = refGalleryImageScroll.current.clientHeight;
    let scrollHeight = refGalleryImageScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "image",
        album: fetchGalleryAlbum,
        force_update: false,
      });
    }
  };

  fetchGalleryOnScroll = ({ type, album, force_update }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state;
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;
      if (nextCount <= roundValue) {
        let page = nextCount;
        this.props.get_album_type_data(type, "", page);
      }
    }
  };

  handleScrollGalleryVideo = (e) => {
    let { refGalleryVideoScroll } = this;
    // let count = 0;
    let clientHeight = refGalleryVideoScroll.current.clientHeight;
    let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "video",
        album: "",
        force_update: false,
      });
    }
  };

  handleOpenModal(data, url) {
    this.setState({
      viewMyPhotosModal: !this.state.viewMyPhotosModa,
      imageAttacment: data,
    });
  }

  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`;

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach((item) => {
        if (
          fetchGalleryScroll[item] &&
          fetchGalleryScroll[item].results !== null
        ) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map((item) => {
          let itemName = (item?.thumbnail).split(".");
          let itemFile = (item?.file).split(".");
          let extName = itemName[itemName.length - 1];
          let extFile = itemFile[itemFile.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;

          return (
            <Col xs="auto" className="mb-3">
              <FormGroup
                className="checkable-media"
                check
                style={{ width: "120px" }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={() => this.handleOnClickSelectGalleryMedia(item)}
                  />
                  <div className="gallery-media">
                    <img
                      className="img-fluid"
                      src={isVideo ? srcFile : item?.file}
                      alt="logo Pics"
                    />
                  </div>
                </Label>
                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
                  {item.caption ? item.caption : "No Caption"}
                </div>
                <div className="font-weight-normal fs-14 d-block">
                  {item.category ? item.category : "No Category"}
                </div>
              </FormGroup>
            </Col>
          );
        });
      }
    } else {
      return getDataItems;
    }
  };

  handleOnRestoreMap = () => {
    this.setState({ keyMAP: Math.random() })
  }

  handleChangeAddress = (address, stateName, index) => {

    let { location_set, location_latLong } = this.state;
    //
    if (location_set.length <= index) {
      if (this.state.radial_distance != "") {
        location_set.push({ area_served: "", radial_distance: "" })
      } else {
        location_set.push({ area_served: "" })
      }

      if (location_latLong.length <= index) {
        location_latLong.push({ latitude: 0, longitude: 0, address: "" })
      }


      this.setState({ location_set })
    }
    if (this.state.radial_distance != "") {
      location_set[index].radial_distance = this.state.radial_distance
    }
    location_set[index].area_served = address;
    this.setState({ location_set, [stateName]: address });

  }


  mapCordinatesSet = () => {
    let location_latLong = this.state.location_latLong;
    const skater = new Icon({
      iconUrl: "https://stagingdatawikireviews.s3.amazonaws.com/images/map-W.png",
      iconSize: [30, 40]
    });
    return (
      <Map key={this.state.keyMAP} center={{
        lat: (this.state.currentLat),
        lng: (this.state.currentLong)
      }}
        zoom={this.state.zoom}
        onClick={this.onMapClicked}
        style={{ position: 'absolute', zIndex: '0' }}
        boundsOptions={{
          paddingBottomRight: [250, 0],
        }}
        zoomControl={false}
      >
        <ZoomControl position="bottomright" />

        <TileLayer
          url={osm.maptiler.url}
          attribution={osm.maptiler.attribution}
          maxZoom={18}
        />
        {location_latLong && Array.isArray(location_latLong) && location_latLong.length ?
          location_latLong.map((item, index) => {
            return (<Marker
              position={[
                item.latitude && item.latitude != 0 ? item.latitude : 0,
                item.longitude && item.longitude != 0 ? item.longitude : 0,
              ]}
              icon={skater}
              //onClick={this.onMarkerClick}
              key={index}
            >
              <Popup>{item.address ? item.address : ""}</Popup>
              <Circle
                center={{ lat: item.latitude && item.latitude != 0 ? item.latitude : 0, lng: item.longitude && item.longitude != 0 ? item.longitude : 0 }}
                fillColor="blue"
                radius={this.state.radial_distance} />
            </Marker>
            )
          })
          : (null)}

        <Control position="topright">
          <button className="leafletbutton"
            onClick={() => { this.handleOnRestoreMap() }}
          >
            Restore Map
				</button>
        </Control>
      </Map>
    )
  }

  handleSelect = async (address, stateName, index) => {
    const result = await geocodeByAddress(address);
    const latlng = await getLatLng(result[0]);

    let { location_set, location_latLong } = this.state;
    //
    if (location_set.length <= index) {
      if (this.state.radial_distance != "") {
        location_set.push({ area_served: "", radial_distance: "" })
      } else {
        location_set.push({ area_served: "" })
      }

      if (location_latLong.length <= index) {
        location_latLong.push({ latitude: 0, longitude: 0, address: "" })
      }

      this.setState({ location_set })
    }
    if (this.state.radial_distance != "") {
      location_set[index].radial_distance = this.state.radial_distance
    }
    location_set[index].area_served = address;
    location_latLong[index].latitude = latlng.lat;
    location_latLong[index].longitude = latlng.lng;
    location_latLong[index].address = address;
    await this.setState({
      location_set, [stateName]: address, location_latLong
    }, () => {
      this.mapCordinatesSet()
    });
  };
  
  firstMethod = (e) => {
	const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("t_");
	
    let fieldIntIndex = parseInt(fieldIndex, 10);
	
    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
  
      // It should not be last input field
      if (fieldIntIndex < 3) {
  
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=number_part_${fieldIntIndex + 1}]`
        );
		// If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }	
  }


  render() {
    const {
      openModal,
      toggleBusinessModal,
      businesses,
      videos_children,
    } = this.props;
    const { myPostsList, name, addBusinessForm, category_id, subcategory_id, country, address1, address2,
      city, state, zipcode, email_set, website, radial_distance, location_set, paymentoptions_set, mediaSet, latestUploadedData, selectedFilesPreview,
      showVideoMedia, fetchTaxonomy, embeddedLink, businessSubcategory, fetchTaxomomyCategory,
      fetchTaxonomyListingImages, fetchTaxonomyListingVideos, number_part_1, number_part_2, number_part_3, hours_operation_set, email, email_type } = this.state;

    let totalLength = selectedFilesPreview.length;
    let objectKeys = [];
    let objectLength = 0;
    let calculatedPercentage = 0;
    if (Object.keys(latestUploadedData).length > 0) {
      objectKeys = Object.keys(latestUploadedData);
      objectLength = objectKeys.length;
      calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
    }

    const searchOptions = {
      componentRestrictions: { country: ['us', 'ca'] },
      // types: ['city']
    };

    return (
      <>
        <Formik
          touched
          errors
          initialValues={addBusinessForm}
          validationSchema={validate}
          onSubmit={(values) => {
            this.props.onSubmit({
              ...values,
              taxonomy: [values.taxonomy],
            });
          }}
          render={(props) => {
            const {
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            } = props;
            return (
              /* Add Business drawer modal popup */
              <Modal
                size="xl" scrollable className="drawer-modal"
                isOpen={openModal}
                toggle={toggleBusinessModal}
              >
                <div className="modal-header p-0">
                  <div className="w-100">
                    <div>
                      <Button color="primary" onClick={toggleBusinessModal}><FontAwesomeIcon icon="angle-left" /> back to listing</Button>
                    </div>
                    <div>
                      <div className="bg-darker p-3 text-tertiary">
                        <div className="mb-2 text-primary font-weight-bold">
                          You’re Adding a New Business Listing
                                   </div>
                        <ul className="list-unstyled d-flex flex-wrap mx-n2">
                          <li className="px-2">
                            <div className="d-flex">
                              <FormGroup>
                                <Input type="select" className="transparent" size="sm">
                                  <option>Business</option>
                                </Input>
                              </FormGroup>
                            </div>
                          </li>
                          <li className="px-2">
                            <div className="d-flex">
                              <div className="mr-1">
                                <span className="text-danger">*</span>
                              </div>
                              <FormGroup>
                                <Input
                                  id="category_id"
                                  name="category_id"
                                  onChange={this.handleChange}
                                  type="select"
                                  className="transparent"
                                  size="sm"
                                  selected={category_id}
                                >
                                  <option value="">Select Category</option>
                                  {businesses && businesses.length > 0 ? businesses.map((business) => {
                                    return (
                                      <option value={business.id} key={business.id}>
                                        {business.category}
                                      </option>

                                    );
                                  }) : ("")}
                                </Input>
                                <span className="text-danger fs-14 ff-alt">{this.state.categoryError}</span>
                              </FormGroup>
                            </div>
                          </li>
                          <li className="px-2" hidden={businessSubcategory && businessSubcategory.length > 0 ? false : true}>
                            <FormGroup>
                              <Input
                                id="subcategory_id"
                                name="subcategory_id"
                                onChange={this.handleChange}
                                type="select"
                                className="transparent"
                                size="sm"
                                selected={subcategory_id}
                              >
                                <option value="">Select SubCategory</option>
                                {businessSubcategory && businessSubcategory.length > 0 ? businessSubcategory.map((businesssub) => {
                                  return (

                                    <option value={businesssub.id} key={businesssub.id}>
                                      {businesssub.category}
                                    </option>
                                  );
                                }) : ("")}
                              </Input>
                            </FormGroup>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <ModalBody className="bg-white text-dark">
                  {/* Step 1 */}
                  <div>
                    <div className="text-primary font-weight-bold mb-2">Step 1. Enter Basic Information</div>
                    <Row>
                      <Col md={6} lg={4}>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0" size="sm">Country</Label>
                          <Input className="primary select-type-dark" bsSize="sm" type="select"
                            id="country"
                            name="country"
                            selected={country}
                            onChange={this.handleChange}
                          >
                            <option value="usa">United States</option>
                            <option value="can">Canada</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0" size="sm">Listing Name <span className="text-danger">*</span></Label>
                          <Input className="primary" bsSize="sm" type="text" placeholder="Your Business Name"
                            value={name}
                            id="name"
                            name="name"
                            onChange={this.handleChange}
                          />
                          <span className="text-danger fs-14 ff-alt">{this.state.nameError}</span>
                        </FormGroup>
                      </Col>
                      <Col md={6} lg={4}>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0" size="sm">Address <span className="text-danger">*</span></Label>
                          <Input className="primary" bsSize="sm" type="text" id="address1" name="address1" value={address1} placeholder="Address 1" onChange={this.handleChange} />
                          <Input className="primary mt-2" bsSize="sm" type="text" id="address2" name="address2" value={address2} placeholder="Address 2" onChange={this.handleChange} />
                          <div className="d-flex mx-n2 mt-2">
                            <div className="px-2">
                              <Input className="primary" bsSize="sm" type="text" id="city" name="city" value={city} placeholder="City" onChange={this.handleChange} />
                              <span className="text-danger fs-14 ff-alt">{this.state.cityError}</span>
                            </div>
                            <div className="px-2">
                              <Input className="primary" bsSize="sm" type="text" id="state" name="state" value={state} placeholder="State" onChange={this.handleChange} />
                              <span className="text-danger fs-14 ff-alt">{this.state.stateError}</span>
                            </div>
                            <div className="px-2">
                              <Input className="primary" bsSize="sm" type="text" id="zipcode" name="zipcode" value={zipcode} placeholder="Zipcode/Postal code" onChange={this.handleChange} />
                              <span className="text-danger fs-14 ff-alt">{this.state.zipcodeError}</span>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md={6} lg={4}>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0" size="sm">Phone </Label>
                          <div className="d-flex mx-n2">
                            <div className="col px-2">
                              <div className="d-flex mx-n2">
                                <div className="col px-2">
                                  <Input className="primary" min="0" max={999} bsSize="sm" type="number" maxLength="3" onKeyUp={(e) => this.firstMethod(e)} name="number_part_1" placeholder="555" value={number_part_1} onChange={this.handleChange} />
                                </div>
                                <div className="col px-2">
                                  <Input className="primary" min="0" max={999} bsSize="sm" type="number" maxLength="3" onKeyUp={(e) => this.firstMethod(e)} name="number_part_2" placeholder="555" value={number_part_2} onChange={this.handleChange} />
                                </div>
                              </div>
                            </div>
                            <div className="col px-2">
                              <Input className="primary" min="0" max={9999} bsSize="sm" type="number" maxLength="4" onKeyUp={(e) => this.firstMethod(e)} name="number_part_3" placeholder="5555" value={number_part_3} onChange={this.handleChange} />
                            </div>
                          </div>
                          <span className="text-danger fs-14 ff-alt">{this.state.phoneError}</span>
                        </FormGroup>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0" size="sm">Email</Label>
                          <Input className="primary" bsSize="sm" type="email" name="email" value={email} onChange={this.handleChange} />
                          <Input className="primary mt-2 select-type-dark" bsSize="sm" name="email_type" value={email_type} onChange={this.handleChange} type="select">
                            <option>Choose email type</option>
                            <option>Sales</option>
                            <option>Reserve</option>
                            <option>General</option>
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0" size="sm">Website</Label>
                          <Input className="primary" bsSize="sm" name="website" value={website} onChange={this.handleChange} type="url" placeholder="http://www.example.com/" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr />
                  {/* Step 2 */}
                  <div>
                    <div className="text-primary font-weight-bold mb-2">
                      Step 2. Add images and/ or videos
                      <span className="ml-1" style={{ color: 'red', fontSize: "16px" }}>*</span>
                    </div>
                    <Row>
                      <Col lg={5}>
                        <p className="text-tertiary fs-14">
                          Upload an image to represent your project. This could be album artwork, a book cover, artist profile, video-freeze frame, headshot... you get the idea (.jpg, .jpeg, .png).
                                   </p>
                      </Col>
                      <Col lg={7}>
                        {/* Uploaded media shown here */}
                        {
                          this.state.uploadMedia.selectedMedia.length ?
                            <div className="border mb-3">
                              <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                {this.state.uploadMedia.selectedMedia.map((item, indexMedia) => (
                                  <Col>
                                    <div className="d-flex pr-3 m-2">
                                      <div>
                                        {(item.type === "image" ||
                                          item.media_type === "image" ||
                                          item.type === "video" ||
                                          item.media_type === "video" || item.type === "audio" ||
                                          item.media_type === "audio") && (
                                            <div className="gallery-media">
                                              { item.media_type === 'image' ?
                                                <img
                                                  className="img-fluid img-thumbnail"
                                                  src={item.url ? item.url : item.file}
                                                  alt={
                                                    item.filename
                                                      ? item.filename.length < 20
                                                        ? item.filename
                                                        : this.truncate(item.filename)
                                                      : item.name
                                                        ? item.name.length < 20
                                                          ? item.name
                                                          : this.truncate(item.name)
                                                        : ""
                                                  } /> :
                                                <img
                                                  className="img-fluid img-thumbnail"
                                                  src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
                                                  alt={
                                                    item.filename
                                                      ? item.filename.length < 20
                                                        ? item.filename
                                                        : this.truncate(item.filename)
                                                      : item.name
                                                        ? item.name.length < 20
                                                          ? item.name
                                                          : this.truncate(item.name)
                                                        : ""
                                                  }
                                                />}
                                            </div>
                                          )}
                                      </div>
                                      <div className="mx-n3 mt-2">
                                        <Button
                                          color="delete-media"
                                          size="sm"
                                          title="Remove Media"
                                          onClick={() =>
                                            this.handleOnClickRemoveSelectedGalleryMedia(
                                              item, indexMedia
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon icon="trash-alt" />{" "}
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                            :
                            ''
                        }
                        <Button
                          color="royal-blue"
                          className="font-weight-normal"
                          bsSize="sm"
                          onClick={() =>
                            this.handleOnClickUploadMedia()
                          }
                        >
                          Upload Media
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <hr />

                  {/* Step 3 */}
                  <div>
                    <div className="text-primary font-weight-bold mb-2">Step 3. Detailed Information</div>
                    <Row>
                      <Col md={6} lg={4}>

                        <div>
                          {/* Repeat this */}
                          <div>
                            <FieldArray
                              name="hours_operation_set"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.hours_operation_set.map(
                                    (hours, index) => (
                                      <div key={index}>
                                        <FormGroup>
                                          <Label className="text-dark font-weight-bold mb-0" size="sm">Hours of operation</Label>
                                          <Input className="primary select-type-dark"
                                            bsSize="sm"
                                            type="select"
                                            name="hour_day_of_week"
                                            onChange={(e) => this.handleChangeBusiness(e, index)}
                                          >
                                            <option>Select Day</option>
                                            <option value="1">Monday</option>
                                            <option value="2" >Tuesday</option>
                                            <option value="3" >Wednesday</option>
                                            <option value="4">Thursday</option>
                                            <option value="5">Friday</option>
                                            <option value="6">Saturday</option>
                                            <option value="7">Sunday</option>
                                          </Input>
                                          <div className="d-flex mx-n2 mt-2">
                                            <div className="px-2">
                                              <Input className="primary select-type-dark"
                                                bsSize="sm" type="select"
                                                name="hour_start_time"
                                                onChange={(e) => this.handleChangeBusiness(e, index)}
                                              >
                                                <option>Select Opening Hours</option>
                                                {/* <option value="Closed">Closed</option> */}
                                                <option value="00:00">12:00 am (midnight)</option>
                                                <option value="00:30">12:30 am</option>
                                                <option value="01:00">1:00 am</option>
                                                <option value="01:30">1:30 am</option>
                                                <option value="02:00">2:00 am</option>
                                                <option value="02:30">2:30 am</option>
                                                <option value="03:00">3:00 am</option>
                                                <option value="03:30">3:30 am</option>
                                                <option value="04:00">4:00 am</option>
                                                <option value="04:30">4:30 am</option>
                                                <option value="05:00">5:00 am</option>
                                                <option value="05:30">5:30 am</option>
                                                <option value="06:00">6:00 am</option>
                                                <option value="06:30">6:30 am</option>
                                                <option value="07:00">7:00 am</option>
                                                <option value="07:30">7:30 am</option>
                                                <option value="08:00">8:00 am</option>
                                                <option value="08:30">8:30 am</option>
                                                <option value="09:00">9:00 am</option>
                                                <option value="09:30">9:30 am</option>
                                                <option value="10:00">10:00 am</option>
                                                <option value="10:30">10:30 am</option>
                                                <option value="11:00">11:00 am</option>
                                                <option value="11:30">11:30 am</option>
                                                <option value="12:00">12:00 pm</option>
                                                <option value="12:30">12:30 pm</option>
                                                <option value="13:00">1:00 pm</option>
                                                <option value="13:30">1:30 pm</option>
                                                <option value="14:00">2:00 pm</option>
                                                <option value="14:30">2:30 pm</option>
                                                <option value="15:00">3:00 pm</option>
                                                <option value="15:30">3:30 pm</option>
                                                <option value="16:00">4:00 pm</option>
                                                <option value="16:30">4:30 pm</option>
                                                <option value="17:00">5:00 pm</option>
                                                <option value="17:30">5:30 pm</option>
                                                <option value="18:00">6:00 pm</option>
                                                <option value="18:30">6:30 pm</option>
                                                <option value="19:00">7:00 pm</option>
                                                <option value="19:30">7:30 pm</option>
                                                <option value="20:00">8:00 pm</option>
                                                <option value="20:30">8:30 pm</option>
                                                <option value="21:00">9:00 pm</option>
                                                <option value="21:30">9:30 pm</option>
                                                <option value="22:00">10:00 pm</option>
                                                <option value="22:30">10:30 pm</option>
                                                <option value="23:00">11:00 pm</option>
                                                <option value="23:30">11:30 pm</option>
                                              </Input>
                                            </div>
                                            <div className="px-2">
                                              <Input className="primary select-type-dark" bsSize="sm"
                                                type="select"
                                                name="hour_end_time"
                                                onChange={(e) => this.handleChangeBusiness(e, index)}
                                              >
                                                <option>Select Closing Hours</option>
                                                <option value="00:30">12:30 am</option>
                                                <option value="01:00">1:00 am</option>
                                                <option value="01:30">1:30 am</option>
                                                <option value="02:00">2:00 am</option>
                                                <option value="02:30">2:30 am</option>
                                                <option value="03:00">3:00 am</option>
                                                <option value="03:30">3:30 am</option>
                                                <option value="04:00">4:00 am</option>
                                                <option value="04:30">4:30 am</option>
                                                <option value="05:00">5:00 am</option>
                                                <option value="05:30">5:30 am</option>
                                                <option value="06:00">6:00 am</option>
                                                <option value="06:30">6:30 am</option>
                                                <option value="07:00">7:00 am</option>
                                                <option value="07:30">7:30 am</option>
                                                <option value="08:00">8:00 am</option>
                                                <option value="08:30">8:30 am</option>
                                                <option value="09:00">9:00 am</option>
                                                <option value="09:30">9:30 am</option>
                                                <option value="10:00">10:00 am</option>
                                                <option value="10:30">10:30 am</option>
                                                <option value="11:00">11:00 am</option>
                                                <option value="11:30">11:30 am</option>
                                                <option value="12:00">12:00 pm (noon)</option>
                                                <option value="12:30">12:30 pm</option>
                                                <option value="13:00">1:00 pm</option>
                                                <option value="13:30">1:30 pm</option>
                                                <option value="14:00">2:00 pm</option>
                                                <option value="14:30">2:30 pm</option>
                                                <option value="15:00">3:00 pm</option>
                                                <option value="15:30">3:30 pm</option>
                                                <option value="16:00">4:00 pm</option>
                                                <option value="16:30">4:30 pm</option>
                                                <option value="17:00">5:00 pm</option>
                                                <option value="17:30">5:30 pm</option>
                                                <option value="18:00">6:00 pm</option>
                                                <option value="18:30">6:30 pm</option>
                                                <option value="19:00">7:00 pm</option>
                                                <option value="19:30">7:30 pm</option>
                                                <option value="20:00">8:00 pm</option>
                                                <option value="20:30">8:30 pm</option>
                                                <option value="21:00">9:00 pm</option>
                                                <option value="21:30">9:30 pm</option>
                                                <option value="22:00">10:00 pm</option>
                                                <option value="22:30">10:30 pm</option>
                                                <option value="23:00">11:00 pm</option>
                                                <option value="23:30">11:30 pm</option>
                                                <option value="00:00 1">12:00 am (midnight next day)</option>
                                                <option value="00:30 1">12:30 am (next day)</option>
                                                <option value="01:00 1">1:00 am (next day)</option>
                                                <option value="01:30 1">1:30 am (next day)</option>
                                                <option value="02:00 1">2:00 am (next day)</option>
                                                <option value="02:30 1">2:30 am (next day)</option>
                                                <option value="03:00 1">3:00 am (next day)</option>
                                                <option value="03:30 1">3:30 am (next day)</option>
                                                <option value="04:00 1">4:00 am (next day)</option>
                                                <option value="04:30 1">4:30 am (next day)</option>
                                                <option value="05:00 1">5:00 am (next day)</option>
                                                <option value="05:30 1">5:30 am (next day)</option>
                                                <option value="06:00 1">6:00 am (next day)</option>
                                              </Input>
                                            </div>
                                          </div>
                                          <Input className="primary mt-2" bsSize="sm" type="text"
                                            placeholder="i.e. brunch"
                                            name="hour_info"
                                            onChange={(e) => this.handleChangeBusiness(e, index)}
                                          />
                                        </FormGroup>
                                        {/* Hide for first */}
                                        <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            this.handleremoveBusiness('hours_operation_set')
                                          }
                                          }>
                                          Remove
                                           </Button>
                                      </div>
                                    )
                                  )}
                                  <div className="text-right">
                                    <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          start_time: "",
                                          end_time: "",
                                          day_of_week: "",
                                          info: "",
                                        })
                                      }>
                                      <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div>
                          {/* Repeat this */}
                          <div>
                            <FieldArray
                              name="paymentoptions_set"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.paymentoptions_set.map(
                                    (payment, index) => (
                                      <div key={index}>
                                        <FormGroup>
                                          <Label
                                            className="text-dark font-weight-bold mb-0"
                                            size="sm"
                                          >
                                            Payment Options
                                          </Label>
                                          <Input
                                            className="primary select-type-dark"
                                            bsSize="sm"
                                            type="select"
                                            // name={`producers_set[${index}.profession_id]`}
                                            name="payment_options"
                                            onChange={(e) => this.handleChangeBusiness(e, index)}
                                          >
                                            <option value="">Choose payment options</option>
                                            <option value="3">Check</option>
                                            <option value="2">Credit Card</option>
                                            <option value="1">Cash</option>
                                          </Input>
                                        </FormGroup>
                                        {/* Hide for first */}
                                        <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            this.handleremoveBusiness('paymentoptions_set')
                                          }
                                          }>
                                          Remove
                                           </Button>
                                      </div>
                                    )
                                  )}
                                  <div className="text-right">
                                    <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          paymentoptions: "",
                                        })
                                      }>
                                      <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6} lg={4}>
                        <div>
                          {/* Repeat this */}
                          <div>
                            <FieldArray
                              name="location_set"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.location_set.map(
                                    (areaServed, index) => (
                                      <div key={index}>
                                        <FormGroup>
                                          <Label
                                            className="text-dark font-weight-bold mb-0"
                                            size="sm"
                                          >
                                            Area Served
                                          </Label>
                                          {/*<Input className="primary" bsSize="sm" type="text"
										    placeholder="Area Served"
										    name="area_served"
                                            onChange={(e) => this.handleChangeBusiness(e,index)}	
										  />*/}
                                          <div ref={this.wrapperRef} key={index}>
                                            <PlacesAutocomplete
                                              value={this.state[`address_${index}`]}
                                              onChange={(address) => this.handleChangeAddress(address, `address_${index}`, index)}
                                              onSelect={(address) => this.handleSelect(address, `address_${index}`, index)}
                                              searchOptions={searchOptions}
                                            >
                                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div className="position-relative">
                                                  <input
                                                    {...getInputProps({
                                                      placeholder: 'Area Served',
                                                      className: 'location-search-input comment-form-control form-control form-control-sm',
                                                      name: 'area_served'

                                                    })}
                                                  />
                                                  <div className="autocomplete-dropdown-container position-absolute">
                                                    {loading && <div className="bg-white fs-14 p-2">Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                      const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                      // inline style for demonstration purpose
                                                      const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                      return (
                                                        <div
                                                          key={index}
                                                          {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                          })}
                                                        >
                                                          <FontAwesomeIcon className="mr-2" fixedWidth icon="map-marker-alt" />
                                                          {suggestion.formattedSuggestion.mainText}
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                </div>
                                              )}
                                            </PlacesAutocomplete>
                                          </div>
                                        </FormGroup>
                                        {/* Hide for first */}
                                        <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            this.handleremoveBusiness('location_set')
                                          }
                                          }>
                                          Remove
                                           </Button>
                                      </div>
                                    )
                                  )}
                                  <div className="text-right">
                                    <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          area_served: "",
                                        })
                                      }>
                                      <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0" size="sm">Radial Distance</Label>
                          <Input className="primary" bsSize="sm" type="text" placeholder="Radial Distance" name="radial_distance" value={radial_distance} onChange={this.handleChange} />
                        </FormGroup>
                      </Col>
                      <Col md={6} lg={4}>
                        <div className="display-4 text-center">
                          {this.state.location_latLong[0].latitude != 0 ?
                            this.mapCordinatesSet()
                            : ""}

                        </div>
                      </Col>
                    </Row>
                  </div>

                  <hr />

                  {/* Step 4 */}
                  <div>
                    <div className="text-primary font-weight-bold mb-2">Step 4. Terms and Conditions</div>
                    <div className="pl-4">
                      <FormGroup check>
                        <Label className="fs-14" check>
                          <Input type="checkbox" name="termsCondtions" onChange={this.handleChange} />{' '}
                          <span className="text-danger">*</span>
                          <a rel="noopener noreferrer" href="https://wikireviews.com/tos" target="_blank" className="text-royal-blue">I agree to the Business Terms of Services and Privacy Policy</a>
                        </Label>
                      </FormGroup>
                      <span className="text-danger fs-14 ff-alt">{this.state.termsError}</span>
                    </div>
                  </div>

                  {/* Step 5 */}
                  <div className="mt-4">
                    <div className="text-primary font-weight-bold mb-2">Step 5. Write a Review</div>
                    <div className="pl-4">
                      <FormGroup check>
                        <Label className="fs-13 text-darker font-weight-bold" check>
                          <Input type="checkbox" name="is_draft" onChange={this.handleChange} />{' '}
                    Thanks for adding a new listing! As initial uploader, you get the honor of First Review! Write a Review for this product now and immortalize your name in the chronicles of wiki glory!
                </Label>
                      </FormGroup>
                    </div>

                    <div className="text-right mt-3">
                      <Button color="primary" size="sm" onClick={async () => { await this.handleCancel(); }}>Cancel</Button>
                      <Button color="primary" size="sm" onClick={this.handleBusinessSubmit}>Add Business</Button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            );
          }}
        />
        {/* Upload Media Post Modal */}
        <Modal
          isOpen={this.state.uploadMediaPostModal}
          toggle={this.uploadMediaModalToggle}
        >
          <ModalHeader toggle={this.uploadMediaModalToggle}>
            UPLOAD MEDIA
          </ModalHeader>
          <ModalBody className="p-3">
            <Row>
              <Col xs={"auto"}>
                <div className="bg-light h-100 p-3">
                  <Nav
                    tabs
                    className="flex-column active-transparent border-0 m-0"
                  >
                    <NavItem className="text-left">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "upload"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("upload");
                        }}
                      >
                        Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "gallery"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("gallery");
                        }}
                      >
                        Gallery
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "embed"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("embed");
                        }}
                      >
                        Embed
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col>
                <TabContent activeTab={this.state.selectedUpMediaType}>
                  <TabPane tabId="upload">
                    <div
                      className="mb-3 type-file-block bg-light"
                      hidden={
                        this.state.uploadMedia.uploadFiles &&
                          this.state.uploadMedia.uploadFiles.length > 0
                          ? true
                          : false
                      }
                    >
                      <Input
                        type="file"
                        name="upload_media_file"
                        id="uploadFilesPostFromSystem"
                        accept="image/*, video/*, audio/*"
                        onChange={this.handleOnFilePostUploadChange}
                        multiple
                      />
                      <Label for="uploadFilesPostFromSystem">
                        <div className="type-bg"></div>
                      </Label>
                    </div>
                    {this.state.uploadMedia.uploadFiles.length > 0 && (
                      <Row className="mb-3" form>
                        <Col xs={12}>
                          <div
                            style={{
                              maxWidth: "120px",
                              margin: "0 1rem 1rem auto",
                            }}
                          >
                            <div className="text-center mb-1 small">
                              {this.state.uploadMedia.progress === 100 ? (
                                <div className="text-success">
                                  <FontAwesomeIcon
                                    icon="check-circle"
                                    className="mr-1"
                                  />{" "}
                                  Uploaded
                                </div>
                              ) : (
                                  <div>
                                    Uploading{" "}
                                    <span className="text-success font-weight-bold ff-base">
                                      {this.state.uploadMedia.progress.toFixed(0)}
                                    %
                                  </span>
                                  </div>
                                )}
                            </div>
                            <Progress
                              value={this.state.uploadMedia.progress}
                              style={{ height: "8px" }}
                            ></Progress>
                          </div>
                        </Col>
                        {this.state.uploadMedia.uploadFiles.map(
                          (file, index) => {
                            return (
                              <Col xs="auto">
                                <div className="bg-light p-2">
                                  <div className="d-flex pr-3">
                                    <div>
                                      <div
                                        className="selectable-media 123"
                                        style={{ cursor: "default" }}
                                      >
                                        <div className="gallery-media">
                                          {file.media_type === 'image' ?
                                            <img src={file.url} alt="" /> :
                                            <img src={file.thumbnail && file.thumbnail !== null ? file.thumbnail : file.url} alt="" />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mx-n3 mt-2">
                                      <Button
                                        color="delete-media _alt"
                                        size="sm"
                                        title="Remove Media"
                                        hidden={file.id === "" ? true : false}
                                        onClick={this.handleOnClickRemoveSelectedMedia(
                                          file.id
                                        )}
                                      >
                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <Input
                                      bsSize="sm"
                                      type="text"
                                      className="mw-100 caption-input"
                                      name="caption"
                                      placeholder="Caption"
                                      onBlur={(e) =>
                                        this.handleCaptionByIndex(e, index)
                                      }
                                      style={{ maxWidth: '160px' }}
                                    />
                                    <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_type_select" id="selectAlbumTypeData" className="mw-100 mt-1">
                                      <option value="">Choose Category</option>
                                      {file.media_type === "image" && fetchTaxonomyListingImages && Array.isArray(fetchTaxonomyListingImages) && fetchTaxonomyListingImages.length > 0 ?
                                        fetchTaxonomyListingImages.map(item => (
                                          <option value={item.id}>{item.label}</option>
                                        ))
                                        : null}
                                      {file.media_type === "video" && fetchTaxonomyListingVideos && Array.isArray(fetchTaxonomyListingVideos) && fetchTaxonomyListingVideos.length > 0 ?
                                        fetchTaxonomyListingVideos.map(item => (
                                          <option value={item.id}>{item.label}</option>
                                        ))
                                        : null}
                                    </Input>
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        )}
                        <Col xs="auto">
                          <div className="d-flex pt-3">
                            <div className="selectable-media" hidden>
                              <Label
                                for="uploadFilesFromSystemMini"
                                className="gallery-media"
                                style={{
                                  borderStyle: "dashed",
                                  cursor: "pointer",
                                }}
                              >
                                <div className="d-flex h-100 align-items-center justify-content-center">
                                  <span className="fs-14">Upload More</span>
                                </div>
                              </Label>
                              <Input
                                type="file"
                                name="upload_media_file"
                                id="uploadFilesFromSystemMini"
                                accept="image/*, video/*"
                                onChange={this.handleOnFilePostUploadChange}
                                multiple
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              uploadMediaPostModal: false,
                            });
                          }}
                        >
                          {"OK"}
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="gallery">
                    <div className="mb-2">
                      <Nav tabs className="d-inline-flex mb-0">
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "images"}
                            onClick={() => {
                              this.handleOnClickGalleryType("images");
                            }}
                          >
                            Image Gallery
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "videos"}
                            onClick={() => {
                              this.handleOnClickGalleryType("videos");
                            }}
                          >
                            Video Gallery
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.showGalleryType}>
                      <TabPane tabId="images">
                        <div>
                          <FormGroup className="form-row justify-content-end">
                            <Col xs="auto">
                              <Label className="small" for="selectAlbumType">
                                Album Type:
                              </Label>
                            </Col>
                            <Col xs="auto">
                              <Input
                                bsSize="sm"
                                type="select"
                                name="album_type_select"
                                id="selectAlbumType"
                                defaultValue={this.state.uploadMedia.albumType}
                                onChange={this.handleOnClickAlbumTypeChange}
                              >
                                <option value="">All</option>
                                {this.state.uploadMedia.albumTypesList.length >
                                  0 &&
                                  this.state.uploadMedia.albumTypesList.map(
                                    (type) => {
                                      if (type.label) {
                                        return (
                                          <option value={type.category}>
                                            {type.category}
                                          </option>

                                        )
                                      }
                                    }
                                  )}
                              </Input>
                            </Col>
                          </FormGroup>
                        </div>
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryImageScroll}
                          onScroll={this.handleScrollGalleryImage}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>{this.fetchScrollPhotoMedia()}</Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No images</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="videos">
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryVideoScroll}
                          onScroll={this.handleScrollGalleryVideo}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>{this.fetchScrollPhotoMedia(true)}</Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No videos</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="embed">
                    <div className="text-muted mb-2">
                      Submit the link for the image or video you want to embed{" "}
                    </div>
                    <div className="mb-2">
                      {/* Repeat this while adding */}
                      {this.mapEmbedLinks()}
                    </div>
                    <div className="d-flex mx-n2 mb-2">
                      <div className="px-2 ml-auto">
                        <Button
                          title="Add"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            this.addEmbedlinkRow();
                          }}
                        >
                          <FontAwesomeIcon icon="plus" />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.insertEmbedLinks();
                          }}
                        >
                          Insert
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  upload_scroll: state.myProfile.upload_scroll,
  upload_media_data: state.myProfile.upload_media_data,
  businessSabcategories: state.footer.businessSabcategories,
  productAnothSabcategories: state.footer.productAnothSabcategories,
  productOtherSabcategories: state.footer.productOtherSabcategories,
  fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
  album_types_list: state.user.album_types_list,
  album_type_data: state.user.album_type_data,
  current_location: state.home.current_location,
  fetch_save_response: state.business.fetch_save_response,
});

const mapProps = (dispatch) => ({
  media_upload_scroll: ({ type, album, page, force_update }) =>
    dispatch(media_upload_scroll({ type, album, page, force_update })),
  fetch_media_data: (type, album, page, user_profile) =>
    dispatch(fetch_media_data(type, album, page, user_profile)),
  clear_media_dispatch: () => dispatch(clear_media_dispatch()),
  initFetchBusinessSubCategories: (categoryId, type) => dispatch(initFetchBusinessSubCategories(categoryId, type)),
  clear_cat_sub_business: () => dispatch(clear_cat_sub_business()),
  add_business: (data) => dispatch(add_business(data)),
  upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
  fetch_taxonomy_list: (category, type) =>
    dispatch(fetch_taxonomy_list(category, type)),
  get_album_types_list: () => dispatch(get_album_types_list()),
  get_album_type_data: (type, album, page) =>
    dispatch(get_album_type_data(type, album, page)),
  delete_selected_gallery_media: (dataID) =>
    dispatch(delete_selected_gallery_media(dataID)),
  add_my_post: (data, type, page) => dispatch(add_my_post(data, type, page)),

});

export default withRouter(connect(mapState, mapProps)(AddBusinessModal));
