/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import CollapseBasic from '../../../component/atoms/collapse';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Progress, } from 'reactstrap';
import moment from 'moment';
import { connect } from "react-redux";
import {
  recommend_people
} from "../../../actions/business";
import { getUniqueArrayOfObject } from '../../../utils/utilities';

class PeopleWorking extends Component {
   mapRef = React.createRef();
    constructor(props) {
        super(props)

        this.state = {
			show_view_all: false,
			isPresent : false,

        }
    }
	
	componentDidUpdate(prevProps) {
		if (this.props.peopleWorkingState !== prevProps.peopleWorkingState) {
			// this.myFormRef.reset();
        }
	}

  recommendPeople = (id,typeStatus)=>{
	  const {businessId} = this.props;
	 let data = {}
     data.type = typeStatus;
	 if(businessId && businessId != ""){
     this.props.recommend_people(data,id,businessId)
	 }	 
	  
  }

    render() {
        const { firstNameError, lastNameError, isToggleWorkingPeople, stateSet, peopleWorkingState, handleSubmitPeopleWorking, working_employee,isToggleRecommendPeople,viewPeopleWorkingHereModal } = this.props;
        let working_length = working_employee?.results?.length;
		const {isPresent} = this.state;
        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic title="People Working Here" containerClass="bg-transparent type-secondary" bodyClass="text-secondary" isOpen={true}>
                    <ul className="fs-14 list-unstyled mb-2">
                        {working_employee && Array.isArray(working_employee.results) && working_employee?.results?.length ?

                            working_employee.results.slice(0, 3) .map(item => (
                                <li className="d-flex flex-nowrap gap-2 py-3" style={{ borderBottom: '1px solid #cbc7bb' }}>
                                    <div>
                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/friend-photo.png" alt="" />
                                    </div>
                                    <div className="flex-grow-1">
                                        <Link to="#" className="text-secondary-dark fs-14 font-weight-bold">
                                            {item?.reviews_professional?.firstname}
                                        </Link>
                                        <div className="text-secondary-dark fs-13">    {item?.reviews_professional?.profession?.title}</div>

                                        <div className="my-2">
                                            <Progress value={item?.ratings_by_employer?.recommend_count?.percentage} className="progress-secondary" style={{height: '14' }}>{item?.ratings_by_employer?.recommend_count?.percentage}%</Progress>
                                        </div>
                                        <span className="text-secondary-dark fs-13">
                                            <span>{item?.ratings_by_employer?.recommend_count?.percentage}% approval </span>
                                        </span>

                                        {/* Recommend this person */}
                                        {isToggleRecommendPeople && (  <div className="mt-2">
                                            <div className="fs-15 text-secondary-dark">
                                                <span>
                                                    Would you recommend this person ?
                                                </span>
                                            </div>
                                            <div>
                                                <span className="text-dark font-weight-bold fs-14" role="button"
												onClick={() => {
												 this.recommendPeople(item?.id,"yes")
												}}
												>
                                                    Yes
                                                </span>
                                                <span className="mx-2">|</span>
                                                <span className="text-dark font-weight-bold fs-14" role="button"
												onClick={() => {
													this.recommendPeople(item?.id,"no")
												}}
												>
                                                    No
                                                </span>
                                            </div>
                                        </div> )}

                                        <div className="text-right">
                                            <span onClick={() => {
												stateSet({ tipsText: '', isToggleRecommendPeople: true })
                                            }} className="text-secondary fs-14 font-weight-bold" role="button">Recommend  {item?.reviews_professional?.firstname}</span>
                                        </div>
                                    </div>
                                </li>
                            ))
                            :
                            null
                        }

                    </ul>

                    <div className="d-flex gap-2 align-items-start justify-content-between">
                        <span
                            onClick={() => {

                                stateSet({ tipsText: '', isToggleWorkingPeople: true, editId: null })
                            }}

                            role="button" className="text-secondary-dark fs-14 font-weight-bold">add another person</span>
                       {working_length  && working_length > 3?     
                       <span role="button" className="text-secondary fs-14 ff-alt"
                            onClick={() => {
                               // this.setState({ viewPeopleWorkingHereModal: true })
                                // this.props.toggleopenmodal();
                                stateSet({ tipsText: '', viewPeopleWorkingHereModal: true, editId: null })
                            }}>
                                
                            View all
                        </span>
                        : null }
                    </div>
                    {/* Add Another Person */}
                    {isToggleWorkingPeople && (<div className="shadow bg-white p-3 mt-2">
                        <div>
                            <form ref={(el) => this.myFormRef = el}>    

                            <h5 className="text-secondary-dark fs-30">
                                Add Another Person
                            </h5>
							
                            <div className="text-dark">
                                <div className="mb-2">
                                    <Input
                                        bsSize="sm"
                                        type="text"
                                        name="firstName"
                                        placeholder="Enter First Name"
                                        value={peopleWorkingState?.first_name}
                                        onChange={(e) => { this.props.handleChangePeopleWorking({ name: 'first_name', value: e.target.value }) }}
                                    />
									 <span className="text-danger fs-14 ff-alt">{firstNameError}</span>
                                </div>
                                <div className="mb-2">
                                    <Input
                                        bsSize="sm"
                                        type="text"
                                        name="lastName"
                                        placeholder="Enter Last Name"
                                        value={peopleWorkingState?.last_name}
                                        onChange={(e) => { this.props.handleChangePeopleWorking({ name: 'last_name', value: e.target.value }) }}
                                    />
									 <span className="text-danger fs-14 ff-alt">{lastNameError}</span>
                                </div>
                                <div className="mb-2">
                                    <Input value={peopleWorkingState?.profession}
                                        onChange={(e) => { this.props.handleChangePeopleWorking({ name: 'profession', value: e.target.value }) }} bsSize="sm" type="select">
                                        <option value="">Choose Profession</option>
                                        {getUniqueArrayOfObject(working_employee?.profession_list.flat(),"title")?.map((item)=>{
                                            return  <option value={item.id}>{item.title}</option>

                                        })}
                                    </Input>
                                </div>
                                <div className="mb-2">
                                    <Input
                                        bsSize="sm"
                                        type="text"
                                        name="professionTitle"
                                        placeholder="Enter profession title"
                                        value={peopleWorkingState?.character_role}
                                        onChange={(e) => { this.props.handleChangePeopleWorking({ name: 'character_role', value: e.target.value }) }}
                                    />
                                </div>
                                <div className="mb-2">
                                    {/* <Label size="sm" className="font-weight-bold mb-0 ff-base">Start Date</Label> */}
                                    <Input
                                        bsSize="sm"
                                        type="month"
                                        max={moment().format("YYYY-MM")}
                                        name="professionStartDate"
                                        placeholder="Enter start date"
                                        value={peopleWorkingState?.from_date}
                                        onChange={(e) => { this.props.handleChangePeopleWorking({ name: 'from_date', value: e.target.value }) }}
                                    />
                                </div>
                                <div className="mb-2" hidden={isPresent ? true : false}>
                                    <Input
                                        bsSize="sm"
                                        type="month"
                                        // min={moment(peopleWorkingState?.from_date).format("YYYY-MM")}
                                        max={moment().format("YYYY-MM")}
                                        name="professionEndDate"
                                        placeholder="Enter end date"
                                        value={peopleWorkingState?.to_date}
                                        onChange={(e) => { this.props.handleChangePeopleWorking({ name: 'to_date', value: e.target.value }) }}


                                    />
                                </div>
                                <div className="mb-2">
                                    <FormGroup check>
                                        <Label className="font-weight-bold ff-base" check>
                                            <Input value={peopleWorkingState?.is_present}
                                                onChange={(e) => { 
												this.props.handleChangePeopleWorking({ name: 'is_present', value: e.target.checked }) 
												this.setState({isPresent: e.target.checked})
												}} type="checkbox" /> Present </Label>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="text-right">
                                    <Button onClick={() => { handleSubmitPeopleWorking() }} size="sm" color="primary mr-2">
                                        Submit
                                    </Button>
                                    <Button
                                        onClick={() => {

                                            stateSet({ tipsText: '', isToggleWorkingPeople: false, editId: null })
                                        }}
                                        size="sm" color="primary ml-0">
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                            </form>
						</div>
						
                        <hr />
                    </div>
                    )}
                </CollapseBasic>
            </div>
        )
    }
}

const mapState = (state) => ({
 
});

const mapProps = (dispatch) => ({
  recommend_people: (data, id,businessId) =>
    dispatch(recommend_people(data, id,businessId)),
 
});

export default connect(mapState, mapProps)(PeopleWorking);
