import React from "react";
import ReactionDetailsModal from "./DetailsModal";
import { callApi } from "../../../../utils/apiCaller";
export default class Reactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statuses: null,
      counts: 0,
      isDetailsModalOpen: false,
      activeTab: "",
      feedData:null,
      animations: {
        insightful: false,
        woohoo: false,
        bummer: false,
        lol: false,
        nailed: false,
        priceless: false,
        helpful: false,
        not_helpful: false,
        thanku: false,
      },
      reactionHoverData: {}
    };
  }

  componentDidUpdate() {
    if (!this.state.statuses && this.props.statuses) {
      this.setState({
        statuses: this.props.statuses,
        counts: this.props.counts,
      });
    }
  }

  componentDidMount() {
    if (!this.state.statuses && this.props.statuses) {
      this.setState({
        statuses: this.props.statuses,
        counts: this.props.counts,
      });
    }
  }

  handleClickReaction = async (reaction,id,reaction_count, feedData) => {
    const status = this.state.statuses ? !this.state.statuses[reaction] : false;
    let count = this.state.counts && this.state.counts[reaction];
  
    // return ;
    if (!count) {
      count = 0;
    }

    if (count > 0 && count !== undefined && !status) {
      count -= 1;
    }

    if (count !== undefined && status) {
      count += 1;
    }

    await this.setState({
      statuses: {
        ...this.state.statuses,
        [reaction]: status,
      },
      counts: {
        ...this.state.counts,
        [reaction]: count,
      },
      animations: { [reaction]: true },
    });

    setTimeout(() => {
    
      this.setState({ animations: { [reaction]: false } });
    }, 2000);

    if (reaction === "thanku") {
      reaction = "Thank you";
    }
    this.props.onClick(reaction, status);
  };

  setDetailsModalOpen = (isOpen) => {
    this.setState({ isDetailsModalOpen: isOpen });
  };

  onClickViewReactions = (name,feed) => {

    const {feedData} = this.state;
    const { id } = this.props;
    let feedId = id;
    let assetType = ""
   
    if (feed.verb && feed.verb.includes('review')) {
      feedId = feed?.action?.id
      assetType = 'review'
    } else {
      feedId = feed.id
      assetType = 'action'
    }

    if(name == "all"){
      // assetType = "action"
      //  assetType = "review"
    }else if(name === "thanku"){
      assetType = "contribution"
    }else if(name == "bummer"){
      // assetType = "action"
      // assetType = "review"
    }else {
      //assetType = "review"
    }
    //let assetType = name === "thanku" ? "contribution" : "review";
    if (this.props?.get_reviewgroup) {
      this.props.get_reviewgroup(assetType, feedId, name);
    }
    if (name === "thanku") {
      name = "all";
    }
    this.setState({ isDetailsModalOpen: true, activeTab: name,feedData:feed },()=>{

    });
  };

  getCountDetails = (feed, reaction, count) => {

    let url = '';
    if (count && count != 0 && feed && Object.keys(feed).length > 0) {
      if (reaction === 'thanku') {
        url = `/api/contributiongroup/?action=${feed.id}&review_group=thanku`
      } else {
        if (feed.verb && feed.verb.includes('review')) {
          url = `/api/reviewgroup/?review=${feed.action && feed.action.id ? feed.action.id : feed.id}&review_group=${reaction}`
        } else {
          url = `/api/reviewgroup/?action=${feed.id}&review_group=${reaction}`
        }
      }

      callApi(url, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          this.setState({
            reactionHoverData: {
              type: reaction,
              data: response,
              id: feed.id
            }
          });
        }
      });
    }
  }

  render() {
    const { id, get_reveiewData, feed } = this.props;
    const {
      animations,
      statuses,
      counts,
      isDetailsModalOpen,
      activeTab,
      reactionHoverData,
      feedData
    } = this.state;

    return (
        <>
          <ReactionDetailsModal
              isOpen={isDetailsModalOpen}
              setIsOpen={this.setDetailsModalOpen}
              counts={counts}
              statuses={statuses}
              onClickViewReactions={(name,feed) => this.onClickViewReactions(name,feed)}
              currentTab={activeTab}
              get_reveiewData={get_reveiewData}
              feedData={feedData}
          />

          <div className="quick-reactions d-flex flex-wrap align-items-end mb-2">

            <div
                className="reaction-holder"
                hidden={statuses && statuses != null && !Object.keys(statuses).includes("thanku")}
            >
              {/* Show on reacting */}
              {
                (animations?.thanku && statuses?.thanku) && <div
                  className="reaction-anim"
                >
                  <img
                    src={require("../../../../assets/images/icons/cheers_forever.gif")}
                    alt=""
                  />
                </div>
              }
              
              <div className="reaction-btn-group">
                <button
                    className={
                      statuses?.thanku
                          ? "reaction reacted thanku"
                          : "reaction thanku"
                    }
                    title={"Thank you"}
                    onClick={() => this.handleClickReaction("thanku", id)}
                >
                <span className="icon">
                  <img
                      src={require("../../../../assets/images/icons/cheers.png")}
                      // src={require("../../../../assets/images/icons/user-circle.png")}
                      alt="thanku"


                  />
                </span>
                  <span className="name">Thank you</span>
                </button>
                <button
                    className="reaction hoverable"
                    onClick={() => this.onClickViewReactions("thanku",feed)}
                    onMouseOver={() => this.getCountDetails(feed, 'thanku', counts?.thanku)}
                >
                <span className="count">
                  {counts?.thanku ? counts?.thanku : 0}
                </span>
                  {reactionHoverData.data && reactionHoverData.data.length > 0 && (
                      <div className="on-hover-appear tooltiptext reaction-tooltip">
                        <p>{reactionHoverData.data[0].name}</p>
                        {reactionHoverData.data.length > 1 && (
                            <span>and {reactionHoverData.data.length - 1} more</span>
                        )}
                      </div>
                  )}

                </button>
              </div>
            </div>
            <div
                className="reaction-holder"
                hidden={statuses && statuses != null && Object.keys(statuses).includes("thanku")}
            >
              {/* Show on reacting */}
              <div
                  className="reaction-anim"
                  hidden={!animations?.woohoo || !statuses?.woohoo}
              >
                <img
                    src={require("../../../../assets/images/icons/woohoo_forever.gif")}
                    alt=""
                />
              </div>

              <div className="reaction-btn-group">
                <button
                    className={
                      statuses?.woohoo
                          ? "reaction reacted woohoo"
                          : "reaction woohoo"
                    }
                    title={"Woohoo"}
                    onClick={() => this.handleClickReaction("woohoo", id,counts, feedData)}
                >
                <span className="icon">
                  <img
                      src={require("../../../../assets/images/icons/woohoo.png")}
                      alt="woohoo"
                  />
                </span>
                  <span className="name">Woohoo</span>
                </button>
                <button
                    className="reaction hoverable"
                    onClick={() => this.onClickViewReactions("woohoo",feed)}
                    onMouseOver={() => this.getCountDetails(feed, 'woohoo', counts?.woohoo)}
                >
                <span className="count">
                  {counts?.woohoo ? counts?.woohoo : 0}
                </span>
                  {reactionHoverData.data && reactionHoverData.data.length > 0 && (
                      <div className="on-hover-appear tooltiptext reaction-tooltip">
                        <p>{reactionHoverData.data[0].name}</p>
                        {reactionHoverData.data.length > 1 && (
                            <span>and {reactionHoverData.data.length - 1} more</span>
                        )}
                      </div>
                  )}
                </button>
              </div>
            </div>
            <div
                className="reaction-holder"
                hidden={statuses && statuses != null && Object.keys(statuses).includes("thanku")}
            >
              <div
                  className="reaction-anim"
                  hidden={!animations?.nailed || !statuses?.nailed}
              >
                <img
                    src={require("../../../../assets/images/icons/nailedit_forever.gif")}
                    alt=""
                />
              </div>
              <div className="reaction-btn-group">
                <button
                    className={
                      statuses?.nailed
                          ? "reaction reacted nailed"
                          : "reaction nailed"
                    }
                    onClick={() => this.handleClickReaction("nailed", id)}
                    title={"Nailed It"}
                >
                <span className="icon">
                  <img
                      src={require("../../../../assets/images/icons/nailed-it.png")}
                      alt="Nailed It"
                  />
                </span>
                  <span className="name">Nailed It</span>
                </button>
                <button
                    className="reaction hoverable"
                    onClick={() => this.onClickViewReactions("nailed",feed)}
                    onMouseOver={() => this.getCountDetails(feed, 'nailed', counts?.nailed)}
                >
                <span className="count">
                  {counts?.nailed ? counts?.nailed : 0}
                </span>
                  {reactionHoverData.data && reactionHoverData.data.length > 0 && (
                      <div className="on-hover-appear tooltiptext reaction-tooltip">
                        <p>{reactionHoverData.data[0].name}</p>
                        {reactionHoverData.data.length > 1 && (
                            <span>and {reactionHoverData.data.length - 1} more</span>
                        )}
                      </div>
                  )}
                </button>
              </div>
            </div>
            <div
                className="reaction-holder"
                hidden={statuses && statuses != null && Object.keys(statuses).includes("thanku")}
            >
              {/* Show on reacting */}
              <div
                  className="reaction-anim"
                  hidden={!animations?.insightful || !statuses?.insightful}
              >
                <img
                    src={require("../../../../assets/images/icons/insightful_forever.gif")}
                    alt=""
                />
              </div>

              <div className="reaction-btn-group">
                <button
                    className={
                      statuses?.insightful
                          ? "reaction reacted insightful"
                          : "reaction insightful"
                    }
                    onClick={() => this.handleClickReaction("insightful", id)}
                    title="Insightful"
                >
                <span className="icon">
                  <img
                      src={require("../../../../assets/images/icons/insightful.png")}
                      alt="insightful"
                  />
                </span>
                  <span className="name">Insightful</span>
                </button>
                <button
                    className="reaction hoverable"
                    onClick={() => this.onClickViewReactions("insightful",feed)}
                    //   toggle={() =>
                    //     this.setState({
                    //       viewPeopleWhoReactedModalToggle: !this.state
                    //         .viewPeopleWhoReactedModalToggle,
                    //     })
                    //   }
                    onMouseOver={() => this.getCountDetails(feed, 'insightful', counts?.insightful)}
                >
                <span className="count">
                  {counts?.insightful ? counts?.insightful : 0}
                </span>
                  {reactionHoverData.data && reactionHoverData.data.length > 0 && (
                      <div className="on-hover-appear tooltiptext reaction-tooltip">
                        <p>{reactionHoverData.data[0].name}</p>
                        {reactionHoverData.data.length > 1 && (
                            <span>and {reactionHoverData.data.length - 1} more</span>
                        )}
                      </div>
                  )}
                </button>
              </div>
            </div>
            <div
                className="reaction-holder"
                hidden={statuses && statuses != null && Object.keys(statuses).includes("thanku")}
            >
              {/* Show on reacting */}
              <div
                  className="reaction-anim"
                  hidden={!animations?.lol || !statuses?.lol}
              >
                <img
                    src={require("../../../../assets/images/icons/lol_forever.gif")}
                    alt=""
                />
              </div>

              <div className="reaction-btn-group">
                <button
                    className={
                      statuses?.lol ? "reaction reacted lol" : "reaction lol"
                    }
                    onClick={() => this.handleClickReaction("lol", id)}
                    title="LOL"
                >
                <span className="icon">
                  <img
                      src={require("../../../../assets/images/icons/lol.png")}
                      alt="lol"
                  />
                </span>
                  <span className="name">LOL</span>
                </button>
                <button
                    className="reaction hoverable"
                    onClick={() => this.onClickViewReactions("lol",feed)}
                    onMouseOver={() => this.getCountDetails(feed, 'lol', counts?.lol)}
                    //   toggle={() =>
                    //     this.setState({
                    //       viewPeopleWhoReactedModalToggle: !this.state
                    //         .viewPeopleWhoReactedModalToggle,
                    //     })
                    //   }
                >
                <span className="count">
                  {counts?.lol ? counts?.lol : 0}
                </span>
                  {reactionHoverData.data && reactionHoverData.data.length > 0 && (
                      <div className="on-hover-appear tooltiptext reaction-tooltip">
                        <p>{reactionHoverData.data[0].name}</p>
                        {reactionHoverData.data.length > 1 && (
                            <span>and {reactionHoverData.data.length - 1} more</span>
                        )}
                      </div>
                  )}
                </button>
              </div>
            </div>
            <div
                className="reaction-holder"
                hidden={statuses && statuses != null && Object.keys(statuses).includes("thanku")}
            >
              {/* Show on reacting */}
              <div
                  className="reaction-anim"
                  hidden={!animations?.bummer || !statuses?.bummer}
              >
                <img
                    src={require("../../../../assets/images/icons/bummer_forever.gif")}
                    alt=""
                />
              </div>

              <div className="reaction-btn-group">
                <button
                    className={
                      statuses?.bummer
                          ? "reaction reacted bummer"
                          : "reaction bummer"
                    }
                    title="Bummer"
                    onClick={() => this.handleClickReaction("bummer", id)}
                >
                <span className="icon">
                  <img
                      src={require("../../../../assets/images/icons/bummer.png")}
                      alt="bummer"
                  />
                </span>
                  <span className="name">Bummer</span>
                </button>
                <button
                    className="reaction hoverable"
                    onClick={() => this.onClickViewReactions("bummer",feed)}
                    onMouseOver={() => this.getCountDetails(feed, 'bummer', counts?.bummer)}
                    //   toggle={() =>
                    //     this.setState({
                    //       viewPeopleWhoReactedModalToggle: !this.state
                    //         .viewPeopleWhoReactedModalToggle,
                    //     })
                    //   }
                >
                <span className="count">
                  {counts?.bummer ? counts?.bummer : 0}
                </span>
                  {reactionHoverData.data && reactionHoverData.data.length > 0 && (
                      <div className="on-hover-appear tooltiptext reaction-tooltip">
                        <p>{reactionHoverData.data[0].name}</p>
                        {reactionHoverData.data.length > 1 && (
                            <span>and {reactionHoverData.data.length - 1} more</span>
                        )}
                      </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
    );
  }
}
