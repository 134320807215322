import React, { Component } from 'react';
import {
    Button, Col, FormGroup,
    Input, Label, Media, 
    Modal, ModalHeader, ModalBody, ModalFooter, Alert,Row
} from 'reactstrap';
import EditBtn from "../../atoms/editBtn";
import DeleteBtn from "../../atoms/deleteBtn";
import feedCam from './../../../assets/images/icons/feed-cam.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { callApi } from '../../../utils/apiCaller';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { get_discussions, create_discussion, delete_discussion, edit_discussion, add_comment } from '../../../actions/discussionsTab';
import { get_album_types_list, get_album_type_data, delete_selected_gallery_media } from "../../../actions/user";
import { get_discuss_this_page } from '../../../actions/business';
import Carousel from 'react-multi-carousel';

const responsive = {
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1,
        },
    }
};

class DiscussionsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createNewDisModalToggle: false,
            discussions: null,
            newDiscussion: {
                title: '',
                body: '',
                errors: {}
            },
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            fetchGalleryAlbum: "",
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            uploadMediaPostModal: false,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            albumType: '',
            pageNo: 1,
            confirmDeleteModal: false,
            deleteItem: {
                id: 0
            },
            editDiscussion: null,
            newComment: {
                id: 0,
                reply: ''
            },
            editComment: null,
            newReply: {
                id: 0,
                reply: ''
            },
            editReply: null,
            prevY: 0,
            loading: false,
            page: 1,
            actorId: 0,
            uploadUrl: '/upload/multiuploader/?album=&instance=Null&image=undefined&doc=undefined',
            uploadedFiles: []
        }
    }

    handleOnClickCreateDiscussionSubmit = async () => {
        let errors = { ...this.state.newDiscussion.errors };

        if (this.state.newDiscussion.title === '') {
            errors.title = 'This field is required!';
        }

        if (this.state.newDiscussion.body === '') {
            errors.body = 'This field is required!';
        }

        if (errors && Object.keys(errors).length > 0) {
            this.setState({
                newDiscussion: {
                    ...this.state.newDiscussion,
                    errors: errors
                }
            });
        } else {
            let url = `/api/profdiscussion/?entity=${this.props.actorDetails.id}&page=1`;
            let multiuploaderfile = [...this.state.uploadedFiles].map(item => item.id);

            let data = {
                title: this.state.newDiscussion.title,
                body: this.state.newDiscussion.body,
                user_entry: this.props.actorDetails.id,
                professionalmessagemedia_set: multiuploaderfile,
                apiUrl: `/api/profdiscussion/`
            };
            if (data.title && data.body) {
                await this.props.create_discussion(data, url);
                await this.props.get_discuss_this_page({ id: data.user_entry, pageId: 1, type: 'profdiscussion' });
                await this.props.resetUploadedMediaSet();
                await this.setState({
                    uploadMediaPostModal: false,
                    uploadMedia: {
                        ...this.state.uploadMedia,
                        selectedMedia: [],
                        selectedMediaIds: [],
                        uploadFiles: [],
                        uploadedFiles: []
                    },
                    newDiscussion: {
                        title: '',
                        body: '',
                        errors: {}
                    },
                    createNewDisModalToggle: false,
                    uploadedFiles: []
                });
            }
        }
    }

    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
        const { actorDetails , uploadedMediaSet} = nextProps;
        let { fetchGalleryScroll, fetchGalleryCount, uploadMedia, uploadedFiles } = this.state;

        if(uploadedMediaSet.length !== uploadedFiles.length){
            this.setState({ uploadedFiles: [...uploadedMediaSet]});
        }

        if (actorDetails?.id) {
            callApi(`/api/profdiscussion/?entity=${actorDetails.id}&page=1`, 'GET')
                .then(res => {
                    this.setState({ discussions: res, actorId: actorDetails?.id });
                });
        }

        if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypesList: nextProps.album_types_list,
                },
                //uploadMediaModal: true,
                selectedUpMediaType: "upload",
                showGalleryType: "images",
            });
        }

        if (
            nextProps.album_type_data &&
            Object.keys(nextProps.album_type_data).length > 0
        ) {
            let item = nextProps.album_type_data;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypeData: nextProps.album_type_data,
                },
                fetchGalleryScroll, fetchGalleryCount,
            });
        }
    }

    componentDidMount() {
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        };

        this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            options
        );
        this.observer.observe(this.loadingRef);
    }

    getDiscussions(next) {
        this.setState({ loading: true });
        callApi(`${next}`, 'GET')
            .then(res => {
                if (res && Object.keys(res).length > 0) {
                    let data = {
                        ...res,
                        results: [...this.state.discussions.results, ...res.results]
                    };
                    this.setState({ discussions: data, loading: false });
                }
            });
    }

    handleObserver(entities, observer) {
        const y = entities[0].boundingClientRect.y;
        let fetch = false;
        if (this.state.prevY === 0 && Math.abs(this.state.prevY - y) > 200) {
            fetch = true;
        } else if (this.state.prevY >= y) {
            fetch = true;
        }

        if (fetch) {
            if (this.state.discussions && this.state.discussions.next) {
                this.getDiscussions(this.state.discussions.next.split('com')[1]);
            }
        }
        this.setState({ prevY: y });
    }

    handleOnClickToggleDiscussionModal = () => {
        this.setState({
            createNewDisModalToggle: !this.state.createNewDisModalToggle,
            newDiscussion: {
                title: '',
                body: '',
                errors: {}
            },
            uploadedFiles: []
        });
    }

    handleOnChangeNewDiscussion = (e) => {
        let errors = { ...this.state.newDiscussion.errors };
        if (e.target.value) {
            delete errors[e.target.name];
        }
        this.setState({
            newDiscussion: {
                ...this.state.newDiscussion,
                [e.target.name]: e.target.value,
                errors: errors
            }
        });
    }

    handleOnChangeEditComment = (e) => {
        this.setState({
            editComment: {
                ...this.state.editComment,
                body: e.target.value
            }
        });
    }

    handleOnSubmitEditComment = () => {
        if (this.state.editComment?.body !== '') {
            let data = {
                body: this.state.editComment.body,
                id: this.state.editComment.id,
                profdiscussion_edit: true,
                apiUrl: `/api/profdiscussion/${this.state.editComment.id}/`
            };

            this.props.edit_discussion(data);
            this.setState({
                editComment: null
            }, () => {
                let url = `/api/profdiscussion/?entity=${this.props.actorDetails.id}&page=1`;
                this.props.get_discussions(url);
            });
        }
    }

    handleOnChangeEditDiscussion = (e) => {
        let errors = { ...this.state.editDiscussion.errors };
        if (e.target.value) {
            delete errors[e.target.name];
        }
        this.setState({
            editDiscussion: {
                ...this.state.editDiscussion,
                [e.target.name]: e.target.value,
                errors: errors
            }
        });
    }

    handleOnSubmitEditDiscussion = async () => {
        let errors = { ...this.state.editDiscussion.errors };

        if (this.state.editDiscussion.title === '') {
            errors.title = 'This field is required!';
        }

        if (this.state.editDiscussion.body === '') {
            errors.body = 'This field is required!';
        }

        if (errors && Object.keys(errors).length > 0) {
            this.setState({
                editDiscussion: {
                    ...this.state.editDiscussion,
                    errors: errors
                }
            });
        } else {
            let data = {
                title: this.state.editDiscussion.title,
                body: this.state.editDiscussion.body,
                id: this.state.editDiscussion.id,
                profdiscussion_edit: true,
                apiUrl: `/api/profdiscussion/${this.state.editDiscussion.id}/`
            };
            if (data.title && data.body) {
                let res = this.state.discussions.results.map(item => {
                    if (item.id === data.id) {
                        item.title = data.title;
                        item.body = data.body;
                    }
                    return item;
                });

                await this.props.edit_discussion(data);
                await this.setState({
                    editDiscussion: null,
                    discussions: {
                        ...this.state.discussions,
                        results: res
                    }
                });
            }
        }
    }

    handleOnDeleteItem = () => {
        const { deleteItem, discussions } = this.state;
        let message = '';

        if (deleteItem.id) {
            if (deleteItem.level === 1) {
                message = 'Your discussion has been deleted!';
                this.props.delete_discussion(`/api/profdiscussion/${deleteItem.id}/`, message);
                let res = discussions.results.filter(item => item.id !== deleteItem.id);
                this.setState({
                    discussions: {
                        ...this.state.discussions,
                        results: res
                    },
                    deleteItem: {
                        id: 0
                    },
                    confirmDeleteModal: false
                });
            } else if (deleteItem.level === 2) {
                message = 'Your answer to discussion has been deleted!';
                this.props.delete_discussion(`/api/profdiscussion/${deleteItem.id}/`, message);

                this.setState({
                    deleteItem: {
                        id: 0
                    },
                    confirmDeleteModal: false
                }, () => {
                    let url = `/api/profdiscussion/?entity=${this.props.actorDetails.id}&page=1`;
                    this.props.get_discussions(url);
                });
            } else if (deleteItem.level === 3) {
                message = 'Your reply has been deleted!';
                this.props.delete_discussion(`/api/profdiscussion/${deleteItem.id}/`, message);

                this.setState({
                    deleteItem: {
                        id: 0
                    },
                    confirmDeleteModal: false
                }, () => {
                    let url = `/api/profdiscussion/?entity=${this.props.actorDetails.id}&page=1`;
                    this.props.get_discussions(url);
                });
            }
        }
    }

    handleOnSubmitNewComment = async () => {
        const { newComment } = this.state;

        if (newComment.reply !== '') {
            let data = {
                reply: newComment.reply,
                [newComment.id]: newComment.reply,
                professionalmessagemedia_set: [],
                title: newComment.reply,
                user_entry: this.props.actorDetails.id,
                apiUrl: `/api/profdiscussion/${newComment.id}/`
            }

            await this.props.add_comment(newComment.id, data);

            let url = `/api/profdiscussion/?entity=${this.props.actorDetails.id}&page=1`;
            await this.props.get_discussions(url);

            await this.setState({
                newComment: {
                    id: 0,
                    reply: ''
                }
            });
        }
    }

    handleOnSubmitNewReply = async () => {
        const { newReply } = this.state;

        if (newReply.reply !== '') {
            let data = {
                reply: newReply.reply,
                professionalmessagemedia_set: [],
                title: newReply.reply,
                user_entry: this.props.actorDetails.id,
                apiUrl: `/api/profdiscussion/${newReply.id}/`
            }

            await this.props.add_comment(newReply.id, data);

            let url = `/api/profdiscussion/?entity=${this.props.actorDetails.id}&page=1`;
            await this.props.get_discussions(url);

            await this.setState({
                newReply: {
                    id: 0,
                    reply: ''
                }
            });
        }
    }

    handleOnChangeEditReply = (e) => {
        this.setState({
            editReply: {
                ...this.state.editReply,
                body: e.target.value
            }
        });
    }

    handleOnSubmitEditReply = () => {
        if (this.state.editReply?.body !== '') {
            let data = {
                body: this.state.editReply.body,
                id: this.state.editReply.id,
                profdiscussion_edit: true,
                apiUrl: `/api/profdiscussion/${this.state.editReply.id}/`
            };

            this.props.edit_discussion(data);
            this.setState({
                editReply: null
            }, () => {
                let url = `/api/profdiscussion/?entity=${this.props.actorDetails.id}&page=1`;
                this.props.get_discussions(url);
            });
        }
    }

    removeFromUploaded = (index) => {
        let { uploadedFiles } = this.state;
        if (index > -1) {
            uploadedFiles.splice(index, 1);
        }
        this.setState({ uploadedFiles });
    }

    truncate = (filenameString) => {
        // let split = filenameString.split(".");
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };

    openSecondCarousal = (files, index) => {
        const carouselMedia = files;
        const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
        const array2 = (index !== carouselMedia.length - 1) ? carouselMedia.filter((item, i) => i > index) : [];
        const newArray = [carouselMedia[index], ...array2, ...array1];

        this.setState({ secondCarouselMedia: newArray, viewMyPhotosModalnew: true });
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const loadingCSS = {
            height: "100px",
            margin: "30px"
        };
        const { discussions, newDiscussion, editDiscussion, newComment, editComment, newReply, editReply, uploadUrl, uploadedFiles } = this.state;
        const { my_profile_data, uploadedMediaSet } = this.props;

        return (
            <div>
                <div className="bg-white p-3 mb-3">
                    <div className="text-right">
                        <Button color="link" className="p-0 text-dark font-weight-bold" onClick={() => {
                            if(!this.checkIfLoggedIn()){
                                this.props.forceToggleLoginModal();
                                return false;
                            }
                            this.setState({ createNewDisModalToggle: true })}}>
                            Create a New Discussion
                                                </Button>
                    </div>
                </div>

                <div>
                {discussions?.results?.length > 0 ? discussions?.results?.map((disc, index) => {
                        return (
                            <div key={index} className="bg-white p-3 fs-14 text-dark mb-3">
                                <div className="d-flex mx-n2 mb-4">
                                    <div className="px-2">
                                        <a href="#" className="text-decoration-none">
                                            <img
                                                className="img-circle _50x50"
                                                src={disc.current_profile_pic ?
                                                    disc.current_profile_pic :
                                                    "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                                onError={(error) =>
                                                    (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                                                }
                                                alt={disc.sender} />
                                        </a>
                                    </div>
                                    <div className="px-2 flex-grow-1">
                                        <div className="d-flex mx-n2">
                                            <div className="px-2 flex-grow-1">
                                                <div className="ff-base wb-break-word text-tertiary">
                                                    <Link to={`/people/${disc.sender_details}`} className="text-dark font-weight-bold">{disc.sender}</Link>
                                                    {' '}
                                                    <span>created a new discussion</span>
                                                    {' '}
                                                    <a href="#" className="text-dark font-weight-bold">{disc.title}</a>
                                                    {' '}
                                                    <span>{disc.sent_at}</span>
                                                </div>
                                            </div>
                                            <div className="px-2 col-auto" hidden={!this.checkIfLoggedIn()}>
                                                <EditBtn onClick={() => this.setState({
                                                    editDiscussion: {
                                                        ...disc,
                                                        errors: {}
                                                    }
                                                })} />
                                                <DeleteBtn onClick={() => this.setState({
                                                    deleteItem: {
                                                        id: disc.id,
                                                        level: 1,
                                                        deleteType: 'discussion'
                                                    },
                                                    confirmDeleteModal: true
                                                })} />
                                            </div>
                                        </div>

                                        {/* Discussion details */}
                                        <div className="ff-alt wb-break-word">
                                            {ReactHtmlParser(disc.body)}
                                        </div>

                                        {/* Edit Discussion */}
                                        <div className="mt-3" hidden={editDiscussion?.id !== disc.id}>
                                            <FormGroup>
                                                <Input className="border text-primary" bsSize="sm" name="title" type="text" placeholder="Enter title for discussion" value={editDiscussion?.title} onChange={this.handleOnChangeEditDiscussion} />
                                                {editDiscussion?.errors?.title && (
                                                    <div className="text-danger small mt-1">
                                                        {editDiscussion?.errors?.title}
                                                    </div>
                                                )}
                                            </FormGroup>
                                            <FormGroup>
                                                <Input className="border text-dark" bsSize="sm" name="body" type="textarea" rows="10" value={editDiscussion?.body ? ReactHtmlParser(editDiscussion.body) : ''} onChange={this.handleOnChangeEditDiscussion} />
                                                {editDiscussion?.errors?.body && (
                                                    <div className="text-danger small mt-1">
                                                        {editDiscussion?.errors?.body}
                                                    </div>
                                                )}
                                            </FormGroup>
                                            <div className="text-right mt-3">
                                                <Button color="primary" size="sm" onClick={this.handleOnSubmitEditDiscussion}>Update</Button>
                                                <Button color="primary" size="sm" onClick={() => this.setState({
                                                    editDiscussion: null
                                                })}>Cancel</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Gallery Images */}
                                {disc.attachments?.length > 0 || disc.attachments && disc.attachments.length > 1 ?
                                    <div className="my-5">
                                        <div>
                                            {/* Featured Photo/Video */}
                                            {disc.attachments?.length > 0 && disc.attachments.slice(0, 1).map(media => {
                                                return (
                                                    <div className="mx-auto text-center mb-3" style={{ maxWidth: "450px" }} key={media.id} onClick={() => this.openSecondCarousal(disc.attachments, 0)}>
                                                        {media.media_type === 'image' && (
                                                            <div>
                                                                <img
                                                                    src={media.url}
                                                                    alt="Featured Photo"
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                        )}
                                                        {media.media_type === 'video' && (
                                                            <video className="img-fluid" controls>
                                                                <source
                                                                    src={media.url}
                                                                    type="video/mp4"
                                                                />
                                                                {'Your browser does not support the video tag.'}
                                                            </video>
                                                        )}
                                                    </div>
                                                )
                                            })}
                                            <div className="mb-3">
                                                {/* List of Photos uploaded */}
                                                <ul className="list-unstyled d-flex justify-content-center flex-wrap mx-n1 mb-2" style={{ maxHeight: '280px', overflowY: 'auto' }}>
                                                    {/* Repeat this */}
                                                    {disc.attachments && disc.attachments.length > 1 ? (
                                                        disc.attachments.filter((el, i) => i != 0).map((media, index) => {
                                                            return (
                                                                <li onClick={() => this.openSecondCarousal(disc.attachments, index + 1)}
                                                                    className="p-1"
                                                                    role="button" key={media.id}>
                                                                    {media.media_type === 'image' ?
                                                                        <img
                                                                            className="img-thumbnail uploaded-img-thumbnail"
                                                                            src={media.url}
                                                                            alt="Featured Photo"
                                                                        />
                                                                        :
                                                                        <div className="video-holder">
                                                                            <img
                                                                                className="img-thumbnail uploaded-img-thumbnail"
                                                                                src={media.thumbnail}
                                                                                alt="Featured Video"
                                                                            />
                                                                        </div>
                                                                    }
                                                                </li>
                                                            );
                                                        })) : ("")}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {/* Gallery Images ends */}

                                {/* All Comments */}
                                <div>
                                    {/* Comment by other user */}
                                    {disc.conversation?.length > 0 && disc.conversation?.map(conv => {
                                        return (
                                            <Media className="media-post mt-3" key={conv.msg_id}>
                                                <Media>
                                                    <img
                                                        className="media-object"
                                                        src={conv.current_profile_pic ? conv.current_profile_pic : require("../../../assets/images/icons/user-circle.png")}
                                                        onError={(error) =>
                                                            (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                                                        }
                                                        alt="User"
                                                    />
                                                </Media>
                                                <Media body>
                                                    <Media className="mb-0" heading>
                                                        <div className="d-flex">
                                                            <div>
                                                                <span className="text-dark fs-14">
                                                                    <Link to={{ pathname: `/people/${conv.sender}` }} className="fs-14 text-reset ff-base font-weight-bold text-decoration-none">
                                                                        {conv.sender}
                                                                    </Link>
                                                                    {": "}
                                                                </span>
                                                                {editComment?.id !== conv.msg_id ?
                                                                    <span className="comment-body-renderer text-tertiary font-weight-normal">
                                                                        {ReactHtmlParser(conv.body)}
                                                                    </span>
                                                                    :
                                                                    ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </Media>

                                                    {/* Hide when editing Post */}
                                                    <div>
                                                        <div className="d-flex mt-n1">
                                                            <div className="mr-2">
                                                                {/* Reply Button */}
                                                                <button
                                                                    className="btn btn-link btn-sm px-0 font-weight-bold text-darker"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            newReply: {
                                                                                id: conv.msg_id,
                                                                                reply: '',
                                                                                replyOn: 'comment'
                                                                            }
                                                                        })
                                                                    }}
                                                                >
                                                                    Reply
                                                                        </button>
                                                                <span className="ff-alt text-tertiary align-middle">
                                                                    <span>{" - "}</span>
                                                                    {conv.sent_at}
                                                                </span>
                                                            </div>
                                                            {my_profile_data?.user?.username === conv.sender_details && (
                                                                <div className="ml-auto" hidden={!this.checkIfLoggedIn()}>
                                                                    <EditBtn onClick={() => this.setState({
                                                                        editComment: {
                                                                            id: conv.msg_id,
                                                                            body: conv.body
                                                                        }
                                                                    })} />
                                                                    <DeleteBtn onClick={() => this.setState({
                                                                        deleteItem: {
                                                                            id: conv.msg_id,
                                                                            level: 2,
                                                                            deleteType: 'answer'
                                                                        },
                                                                        confirmDeleteModal: true
                                                                    })} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    {/* Show when editing your comment */}
                                                    <div hidden={editComment?.id !== conv.msg_id}>
                                                        <FormGroup className="mb-0">
                                                            <Input
                                                                bsSize="sm"
                                                                className="border text-primary mb-2"
                                                                type="textarea"
                                                                placeholder="Editing your comment"
                                                                name="body" value={editComment?.body ? ReactHtmlParser(editComment?.body) : ''} onChange={this.handleOnChangeEditComment}
                                                            />
                                                            <div className="text-right">
                                                                <div className="text-right">
                                                                    <Button
                                                                        size="sm"
                                                                        color="primary"
                                                                        className=" mr-2"
                                                                        onClick={this.handleOnSubmitEditComment}
                                                                    >
                                                                        {'Update'}
                                                                    </Button>
                                                                    <Button
                                                                        size="sm"
                                                                        color="primary"
                                                                        className="ml-0"
                                                                        onClick={() => this.setState({
                                                                            editComment: null
                                                                        })}
                                                                    >
                                                                        {'Cancel'}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    </div>

                                                    <div>
                                                        {/* Show When Replying */}
                                                        <div hidden={newReply?.id !== conv.msg_id || newReply?.replyOn === 'reply'}>
                                                            <Media className="media-post mt-3">
                                                                <Media>
                                                                    <Media
                                                                        object
                                                                        src={my_profile_data?.current_profile_file ? my_profile_data?.current_profile_file : require("../../../assets/images/icons/user-circle.png")}
                                                                        alt="User Image"
                                                                    />
                                                                </Media>
                                                                <Media body>
                                                                    <FormGroup className="mb-0">
                                                                        <Input
                                                                            bsSize="sm"
                                                                            className="border text-primary mb-2"
                                                                            type="textarea"
                                                                            name="reply"
                                                                            placeholder="Write a reply"
                                                                            value={newReply?.reply ? ReactHtmlParser(newReply?.reply) : ''}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    newReply: {
                                                                                        ...this.state.newReply,
                                                                                        reply: e.target.value
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                        <div className="text-right">
                                                                            <div className="text-right">
                                                                                <Button
                                                                                    size="sm"
                                                                                    color="primary"
                                                                                    className=" mr-2"
                                                                                    onClick={this.handleOnSubmitNewReply}
                                                                                >
                                                                                    {'Submit'}
                                                                                </Button>
                                                                                <Button
                                                                                    size="sm"
                                                                                    color="primary"
                                                                                    className="ml-0"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            newReply: {
                                                                                                id: 0,
                                                                                                reply: ''
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    {'Cancel'}
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Media>
                                                            </Media>
                                                            <hr />
                                                        </div>
                                                    </div>

                                                    {/* Replies to a comment */}
                                                    <div>
                                                        {conv.replies?.length > 0 && conv.replies?.map(rep => {
                                                            return (
                                                                <Media className="media-post mt-3" key={rep.id}>
                                                                    <Media>
                                                                        <img
                                                                            className="media-object"
                                                                            src={rep.sender.current_profile_pic ? rep.sender.current_profile_pic : require("../../../assets/images/icons/user-circle.png")}
                                                                            onError={(error) =>
                                                                                (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                                                                            }
                                                                            alt="User"
                                                                        />
                                                                    </Media>
                                                                    <Media body>
                                                                        <Media className="mb-0" heading>
                                                                            <div className="d-flex">
                                                                                <div>
                                                                                    <span className="text-dark fs-14">
                                                                                        <Link to={{ pathname: `/people/${rep.sender.full_name}` }} className="fs-14 text-reset ff-base font-weight-bold text-decoration-none">
                                                                                            {rep.sender.full_name}
                                                                                        </Link>
                                                                                        {": "}
                                                                                    </span>
                                                                                    {editReply?.id !== rep.id ?
                                                                                        <span className="comment-body-renderer text-tertiary font-weight-normal">
                                                                                            {ReactHtmlParser(rep.body)}
                                                                                        </span>
                                                                                        :
                                                                                        ""
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Media>

                                                                        {/* Hide when editing Post */}
                                                                        <div>
                                                                            <div className="d-flex mt-n1">
                                                                                <div className="mr-2">
                                                                                    {/* Reply Button */}
                                                                                    <button
                                                                                        className="btn btn-link btn-sm px-0 font-weight-bold text-darker"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                newReply: {
                                                                                                    id: conv.msg_id,
                                                                                                    reply: '',
                                                                                                    replyOn: 'reply',
                                                                                                    replyId: rep.id
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        Reply
                                                                                    </button>
                                                                                    {/* <span className="ff-alt text-tertiary align-middle">
                                                                                        <span>{" - "}</span>
                                                                                        2 days ago
                                                                                    </span> */}
                                                                                </div>
                                                                                {my_profile_data?.user?.username === rep.sender?.username && (
                                                                                    <div className="ml-auto" hidden={!this.checkIfLoggedIn()}>
                                                                                        <EditBtn onClick={() => this.setState({
                                                                                            editReply: {
                                                                                                id: rep.id,
                                                                                                body: rep.body
                                                                                            }
                                                                                        })} />
                                                                                        <DeleteBtn onClick={() => this.setState({
                                                                                            deleteItem: {
                                                                                                id: rep.id,
                                                                                                level: 3,
                                                                                                deleteType: 'reply'
                                                                                            },
                                                                                            confirmDeleteModal: true
                                                                                        })} />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        {/* Show when editing your reply */}
                                                                        <div hidden={editReply?.id !== rep.id}>
                                                                            <FormGroup className="mb-0">
                                                                                <Input
                                                                                    bsSize="sm"
                                                                                    className="border text-primary mb-2"
                                                                                    type="textarea"
                                                                                    placeholder="Editing your comment"
                                                                                    name="body" value={editReply?.body ? ReactHtmlParser(editReply?.body) : ''} onChange={this.handleOnChangeEditReply}
                                                                                />
                                                                                <div className="text-right">
                                                                                    <div className="text-right">
                                                                                        <Button
                                                                                            size="sm"
                                                                                            color="primary"
                                                                                            className=" mr-2"
                                                                                            onClick={this.handleOnSubmitEditReply}
                                                                                        >
                                                                                            {'Update'}
                                                                                        </Button>
                                                                                        <Button
                                                                                            size="sm"
                                                                                            color="primary"
                                                                                            className="ml-0"
                                                                                            onClick={() => this.setState({
                                                                                                editReply: null
                                                                                            })}
                                                                                        >
                                                                                            {'Cancel'}
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </FormGroup>
                                                                        </div>

                                                                        <div>
                                                                            {/* Show When Replying */}
                                                                            <div hidden={newReply.id !== conv.msg_id || (newReply?.replyOn === 'comment' || newReply?.replyId !== rep.id)}>
                                                                                <Media className="media-post mt-3">
                                                                                    <Media>
                                                                                        <Media
                                                                                            object
                                                                                            src={my_profile_data?.current_profile_file ? my_profile_data?.current_profile_file : require("../../../assets/images/icons/user-circle.png")}
                                                                                            alt="User Image"
                                                                                        />
                                                                                    </Media>
                                                                                    <Media body>
                                                                                        <FormGroup className="mb-0">
                                                                                            <Input
                                                                                                bsSize="sm"
                                                                                                className="border text-primary mb-2"
                                                                                                type="textarea"
                                                                                                name="reply"
                                                                                                placeholder="Write a reply"
                                                                                                value={newReply?.reply ? ReactHtmlParser(newReply?.reply) : ''}
                                                                                                onChange={(e) => {
                                                                                                    this.setState({
                                                                                                        newReply: {
                                                                                                            ...this.state.newReply,
                                                                                                            reply: e.target.value
                                                                                                        }
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            <div className="text-right">
                                                                                                <div className="text-right">
                                                                                                    <Button
                                                                                                        size="sm"
                                                                                                        color="primary"
                                                                                                        className=" mr-2"
                                                                                                        onClick={this.handleOnSubmitNewReply}
                                                                                                    >
                                                                                                        {'Submit'}
                                                                                                    </Button>
                                                                                                    <Button
                                                                                                        size="sm"
                                                                                                        color="primary"
                                                                                                        className="ml-0"
                                                                                                        onClick={() => {
                                                                                                            this.setState({
                                                                                                                newReply: {
                                                                                                                    id: 0,
                                                                                                                    reply: ''
                                                                                                                }
                                                                                                            })
                                                                                                        }}
                                                                                                    >
                                                                                                        {'Cancel'}
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                    </Media>
                                                                                </Media>
                                                                                <hr />
                                                                            </div>
                                                                        </div>
                                                                    </Media>
                                                                </Media>
                                                            )
                                                        })}
                                                    </div>
                                                </Media>
                                            </Media>
                                        )
                                    })}
                                </div>

                                {/* Comment box */}
                                <div className="bg-light p-2 mt-3">
                                    <Media className="media-post">
                                        <Media>
                                            <Media
                                                object
                                                src={my_profile_data?.current_profile_file ? my_profile_data?.current_profile_file : require("../../../assets/images/icons/user-circle.png")}
                                                alt="User Image"
                                            />
                                        </Media>
                                        <Media body>
                                            <FormGroup className="mb-0">
                                                <Input
                                                    bsSize="sm"
                                                    className="text-primary font-weight-bold mb-2"
                                                    type="textarea"
                                                    value={(disc.id === newComment.id) ? ReactHtmlParser(newComment.reply) : ''}
                                                    onChange={(e) => this.setState({
                                                        newComment: {
                                                            id: disc.id,
                                                            reply: e.target.value
                                                        }
                                                    })}
                                                />
                                                <div className="text-right">
                                                    <div className="text-right">
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            onClick={() => {
                                                                if(!this.checkIfLoggedIn()){
                                                                    this.props.forceToggleLoginModal();
                                                                    return false;
                                                                }
                                                                this.handleOnSubmitNewComment()}}
                                                        >
                                                            {'Submit'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Media>
                                    </Media>
                                </div>
                            </div>
                        )
                    }) :
                        (
                            <div className="text-dark fs-14 font-weight-bold">No discussion available to display.</div>
                        )
                    }
                    <div
                        ref={loadingRef => (this.loadingRef = loadingRef)}
                        style={loadingCSS}
                    >
                        <Alert color="primary" hidden={!this.state.loading}>
                            Loading...
                        </Alert>
                    </div>
                </div>

                {/* Create a new discussion Modal */}
                <Modal isOpen={this.state.createNewDisModalToggle} toggle={() => this.handleOnClickToggleDiscussionModal()}>
                    <ModalHeader className="px-0" toggle={() => this.handleOnClickToggleDiscussionModal()}>
                        CREATE NEW DISCUSSION
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Input className="border text-dark ph-grey" bsSize="sm" type="text" placeholder="Enter title for discussion" name="title" value={newDiscussion.title} onChange={this.handleOnChangeNewDiscussion} />
                            {newDiscussion?.errors?.title && (
                                <div className="text-danger small mt-1">
                                    {newDiscussion?.errors?.title}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="body">Body</Label>
                            <Input className="border text-dark ph-grey font-weight-bold" bsSize="sm" type="textarea" rows="10" value={newDiscussion.body} name="body" onChange={this.handleOnChangeNewDiscussion} />
                            {newDiscussion?.errors?.body && (
                                <div className="text-danger small mt-1">
                                    {newDiscussion?.errors?.body}
                                </div>
                            )}
                        </FormGroup>
                        <div>
                            <Button color="transparent" size="sm" title="Upload Media" onClick={() => {//this.handleOnClickUploadMedia()
                                this.props.getuploadUrl({uploadUrl}); 
                                this.props.toggleEnableFileUpload(true, false,false);
								this.props.getnocategory("dicussionTab");								
                            }
                            }><img src={feedCam} alt="" /></Button>
                        </div>

                        {/* Uploaded media shown here */}
                        {this.state.uploadedFiles && Array.isArray(this.state.uploadedFiles) && this.state.uploadedFiles.length > 0 &&
                        <div className="border mb-3">
                            <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                {
                                        this.state.uploadedFiles.map((item, index) => {
                                        return (
                                            <Col key={item.id}>
                                                <div className="d-flex pr-3 m-2">
                                                    <div>
                                                        {(item.type === "image" ||
                                                            item.media_type === "image") && (
                                                                <div>
                                                                    <img
                                                                        className="img-fluid img-thumbnail"
                                                                        src={item.file ? item.file : item.url}
                                                                        alt={
                                                                            item.filename
                                                                                ? item.filename.length < 20
                                                                                    ? item.filename
                                                                                    : this.truncate(item.filename)
                                                                                : item.name
                                                                                    ? item.name.length < 20
                                                                                        ? item.name
                                                                                        : this.truncate(item.name)
                                                                                    : ""
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        {(item.type === "video" ||
                                                            item.media_type === "video") && (
                                                                <div>
                                                                    <video width="100" height="100">
                                                                        <source src={item.file ? item.file : item.url} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                    </video>
                                                                </div>
                                                            )}
                                                    </div>
                                                    {/* <div>
                                                        <img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
                                                    </div> */}
                                                    <div className="mx-n3 mt-2">
                                                        <Button color="delete-media _alt" size="sm" title="Remove Media" onClick={() =>
                                                            this.removeFromUploaded(index)
                                                        }><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </div>
                        }
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <Button color="primary" onClick={() => this.handleOnClickCreateDiscussionSubmit()}>Submit</Button>
                    </ModalFooter>
                </Modal>

                {/* Delete Review Modal */}
                <Modal size="sm" isOpen={this.state.confirmDeleteModal} toggle={() => this.setState({
                    confirmDeleteModal: !this.state.confirmDeleteModal, deleteItem: {
                        id: 0
                    }
                })} modalClassName="backdrop-opaque top-shifted transition-none">
                    <ModalHeader toggle={() => this.setState({
                        confirmDeleteModal: !this.state.confirmDeleteModal, deleteItem: {
                            id: 0
                        }
                    })}></ModalHeader>
                    <ModalBody className="text-center text-dark">
                        <h2 className="mb-3">Confirmation</h2>
                        <p className="small">Are you sure you want to delete this {this.state.deleteItem.deleteType ?  this.state.deleteItem.deleteType : ''}?</p>

                        <div className="pt-4">
                            <div>
                                <Button onClick={() => this.setState({
                                    confirmDeleteModal: false, deleteItem: {
                                        id: 0
                                    }
                                })} size="md" color="primary btn-confirm">Cancel</Button>
                                <Button size="md" color="primary btn-confirm" onClick={() => this.handleOnDeleteItem()}>Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                {/* View Media Modal */}
                <Modal
                    centered
                    isOpen={this.state.viewMyPhotosModalnew}
                    toggle={() => {
                        this.setState({ viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew })
                    }}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            sliderClass={true ? "mx-auto" : ""}>
                            {/* Repeat this */}
                            {this.state.secondCarouselMedia?.length > 0 && this.state.secondCarouselMedia.map((med, i) => {
                                return (
                                <div className="item fs-14 h-100 d-flex flex-column justify-content-center"
                                    key={med.id}
                                >
                                    <div>
                                        <div className="d-flex text-white justify-content-between mt-3">
                                            <div className="mr-2 ff-alt">{med.caption}</div>
                                            <div className="ff-base">{med.label}</div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                        {(med.media_type === "image" || med.type === "") && ( 
                                            <img
                                                className="img-fluid shown-media"
                                                src={med.url}
                                                alt=""
                                            />
                                        )}
                                        {med.media_type === "video" && (
                                            <video className="img-fluid shown-media" controls>
                                                <source src={med.url} type="video/mp4" />
                                                {'Your browser does not support the video tag.'}
                                            </video>
                                        )}
                                    </div>
                                    <div className="px-2 mt-3">
                                        <div className="d-flex flex-nowrap mx-n2">
                                            <div className="px-2 col d-flex flex-nowrap">
                                                <div className="mr-2">
                                                    <img
                                                        className="img-circle _30x30"
                                                        src={med?.user?.user?.current_profile_pic
                                                            ? med?.user?.user?.current_profile_pic
                                                            : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                                        alt=""
                                                    />
                                                    
                                                </div>
                                                <div>
                                                    <div className="text-primary ff-base">
                                                        {` Uploaded by `}
                                                        <a className="text-reset" href="#">
                                                            <span className="font-weight-bold">
                                                                {med.user?.user.first_name} {med.user?.user.last_name}
                                                                        </span>{" "}
                                                        </a>
                                                    </div>
                                                    <div className="text-tertiary ff-base">
                                                        {med.upload_date}
                                                                </div>
                                                </div>
                                            </div>
                                            <div className="px-2 col-auto">
                                                <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                                                    {i+1} of {this.state.secondCarouselMedia.length}
                                                </h2>
                                            </div>
                                            <div className="px-2 col">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            )})}
                        </Carousel>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapState = (state) => ({
    discussions_data: state.discussions.discussions_data,
    album_types_list: state.user.album_types_list,
    album_type_data: state.user.album_type_data,
    my_profile_data: state.user.my_profile
});

const mapDispatch = (dispatch) => ({
    get_discussions: (url) => dispatch(get_discussions(url)),
    create_discussion: (data, url) => dispatch(create_discussion(data, url)),
    delete_discussion: (url, message) => dispatch(delete_discussion(url, message)),
    get_album_types_list: () => dispatch(get_album_types_list()),
    get_album_type_data: (type, album, page) => dispatch(get_album_type_data(type, album, page)),
    delete_selected_gallery_media: (dataID) => dispatch(delete_selected_gallery_media(dataID)),
    edit_discussion: (data) => dispatch(edit_discussion(data)),
    add_comment: (id, data) => dispatch(add_comment(id, data)),
    get_discuss_this_page: (data) => dispatch(get_discuss_this_page(data))
})

export default connect(mapState, mapDispatch)(DiscussionsTab);