import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, FormGroup, Input } from 'reactstrap';

const ShareByEmail = (props) => {
    const [form, setForm] = useState({
        shareName: '',
        shareEmail: '',
        shareNote: ''
    });

    const [errors, setErrors] = useState({});
    const myProfile = useSelector(state => state.user.my_profile)
    const user = myProfile?.user || null
    const userName = `${user?.first_name || ""} ${user?.last_name || ""}`

    useEffect(() => {
        const { shareName, shareEmail, shareNote } = props;
        setForm({
            shareName: userName?.trim() || "",
            shareEmail,
            shareNote,
        })
    }, [props.shareName, props.shareEmail, props.shareNote, props]);


    const handleOnChangeShareByEmail = (e) => {
        let tempErrors = { ...errors };
        if (e.target.value) {
            delete tempErrors[e.target.name];
        }

        setErrors(tempErrors);
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let tempErrors = { ...errors };
        const { shareName, shareEmail, shareNote } = form;

        if (!shareName) {
            tempErrors.shareName = 'This field is required!';
        }

        if (!shareEmail) {
            tempErrors.shareEmail = 'This field is required!';
        } else {
            const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!reg.test(String(shareEmail).toLowerCase())) {
                tempErrors.shareEmail = 'Please enter a valid email!';
            }
        }

        if (tempErrors && Object.keys(tempErrors).length > 0) {
            setErrors(tempErrors);
        } else {
            props.handleOnSubmitShareByEmail({ shareName, shareEmail, shareNote });
            if (props.modalToggler) props.modalToggler();
        }
    }

    return <>
        <Form onSubmit={handleSubmit}>
            <FormGroup >
                <Input
                    className="primary"
                    bsSize="sm"
                    type="text"
                    name="shareName"
                    placeholder="Your name"
                    value={form.shareName}
                    onChange={handleOnChangeShareByEmail}
                />
                {errors?.shareName && (
                    <div className="text-danger small mt-1">
                        {errors?.shareName}
                    </div>
                )}
            </FormGroup>
            <FormGroup>
                <Input
                    className="primary"
                    bsSize="sm"
                    type="email"
                    name="shareEmail"
                    placeholder="Recipient's email"
                    value={form.shareEmail}
                    onChange={handleOnChangeShareByEmail}
                />
                {errors?.shareEmail && (
                    <div className="text-danger small mt-1">
                        {errors?.shareEmail}
                    </div>
                )}
            </FormGroup>
            <FormGroup>
                <Input
                    className="primary"
                    bsSize="sm"
                    id="shareNote"
                    name="shareNote"
                    type="textarea"
                    placeholder="Add a note (optional)"
                    value={form.shareNote}
                    onChange={handleOnChangeShareByEmail}
                />
            </FormGroup>
            <Button color="primary" type='submit'>
                Share
            </Button>
        </Form>
    </>
}

export default ShareByEmail;