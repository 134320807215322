const initialState = {
    all_messages: null,
    messages_by_id: null
};

const messages = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case 'SET_LOADER':
            state.isLoading = action.payload;
            return { ...state };

        case "GET_MESSAGES":
            state.all_messages = action.payload;
            return { ...state };

        case "GET_MESSAGES_BY_ID":
            state.messages_by_id = action.payload;
            return { ...state };

        case 'REMOVE_FORCE_MESSAGE':
            state.all_messages['force'] = false;
            return { ...state };

        default:
            return state;
    }
}

export default messages;