import React from "react";
import { Media } from "reactstrap";
import { MentionsInput, Mention } from "react-mentions";
import "./style.css";
import { callApi } from "../../../../utils/apiCaller";
import Axios from "axios";

const Suggestion = (entry, focused) => {
  return (
    <div
      style={{
        width: 300,
        // backgroundColor: focused ? "#fafafa" : "#fff",
        // padding: "10px 4px",
      }}
      role="button"
      key={entry.id}
    >
      <div>
        <Media>

          <Media
            object
            className="img-circle _30x30 rounded-0 mr-2"
            src={
              entry.profile_pic
                ? entry.profile_pic
                : require("../../../../assets/images/icons/user-circle.png")
            }
            onError={(error) =>
              (error.target.src = require("../../../../assets/images/icons/user-circle.png"))
            }
            alt="User Image"
          />
          {/* <Media
            object
            className="img-circle _30x30 rounded-0 mr-2"
            src={entry.profile_pic}
            alt="User Image"
          /> */}
          <Media className="p-0" body>
            {entry.display}
          </Media>
        </Media>
      </div>
    </div>
  );
};

const MentionTextArea = ({ value, onChange, peopleList = [], placeholder, id, my_profile }) => {


  const fetchUsers=(query, callback)=> {
    if (!query) return
    callApi(`/api/tags/?q=${query}&size=20`, "GET") 
      .then(res => {
        const data = res.results.filter((i) => my_profile?.user?.username != i?.username);
        return data.map(user => ({ display: `${user?.first_name} ${user?.last_name || ""}`, id: user?.type === "people" ? `/${user?.type}/${user?.username}` : `/${user?.type}/${user?.id}`, type: user?.type, profile_pic: user?.profile_pic }))
      })
      .then(callback)
  }


  return (
    <MentionsInput
      className="input-label-block"
      value={value}
      onChange={onChange}
      allowSpaceInQuery={true}
      name="post"
      rows="4"
      id={id ? id : "post"}
      placeholder={placeholder}>
      <Mention
        trigger="@"        
        data={fetchUsers}
        renderSuggestion={Suggestion}
        markup="[__display__](__id__)"
        // markup="{{__type__}}"
      />
    </MentionsInput>
  );
};

export default MentionTextArea;
