import React, { Component } from 'react'
import CollapseBasic from '../../atoms/collapse'
import { Button, FormGroup, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { update_detail } from '../../../actions/itemDetail';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
class Specifications extends Component {
    constructor() {
        super()
        this.state = {
            setproductDescription: null,
            addproductDescription: false,
            editproductDescription: false,
            AddNewproductDescription: false,
            productDescription: null,
            AddNewproductDescriptionIsValid: false,
            productDescriptionIsValid: null,
            setManufacturerSheet: null,
            editManufacturerSheet: null,
            manufacturerSheet: null,
            AddNewManufacturerSheet: false,
            AddNewManufacturerSheetIsValid: false,
            addManufacturerSheet: false,
            manufacturerSheetIsValid: false,
            isToggleAddAnother: false,
        }
    }
    async componentWillReceiveProps(nextProps) {
        let { Specification, productId } = nextProps;
        if (Specification) {
            await this.setState({
                setproductDescription: Specification?.additional_info?.['Product Description'] ? Specification?.additional_info?.['Product Description'][0].value : "",
                setManufacturerSheet: Specification?.additional_info?.manufacturer_cut_sheet ? Specification?.additional_info?.manufacturer_cut_sheet?.[0].value : "",

            });
        }
        if (productId) {
            this.setState({
                prodId: productId,
            });

        }
    }
    handleFieldsChange = (type, data) => {

        const { prodId, setManufacturerSheet, setproductDescription } = this.state
        if (prodId && prodId != 0) {
            if (type === 'addproductDescription') {
                const productdescription = {
                    "Product Description": data
                }
                let dataPoductDescription = {}
                dataPoductDescription.additional_info = {}
                dataPoductDescription.additional_info.key = 'Product Description';
                dataPoductDescription.additional_info.oldVal = setproductDescription && setproductDescription != "" ? setproductDescription : "";
                dataPoductDescription.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataPoductDescription, 'Product Description added successfully')
            } else if (type === 'editproductDescription') {
                const productdescription = {
                    "Product Description": data
                }

                let dataPoductDescription = {}
                dataPoductDescription.additional_info = {}
                dataPoductDescription.additional_info.key = 'Product Description';
                dataPoductDescription.additional_info.oldVal = setproductDescription && setproductDescription != "" ? setproductDescription : "";
                dataPoductDescription.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataPoductDescription, 'Product Description updated successfully')
            }
            else if (type === 'addManufacturerSheet') {
                const manufacturerCutSheet = {
                    "manufacturer_cut_sheet": data
                }
                let dataManuCut = {}
                dataManuCut.additional_info = {}
                dataManuCut.additional_info.key = 'manufacturer_cut_sheet';
                dataManuCut.additional_info.oldVal = setManufacturerSheet && setManufacturerSheet.length > 0 ? setManufacturerSheet[0].value : "";
                dataManuCut.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataManuCut, 'Manufacture Cut Sheet updated successfully')
            }
            else if (type === 'editManufacturerSheet') {
                const manufacturerCutSheet = {
                    "manufacturer_cut_sheet": data
                }
                let types = "products";
                let dataManuCut = {}
                dataManuCut.additional_info = {}
                dataManuCut.additional_info.key = 'manufacturer_cut_sheet';
                dataManuCut.additional_info.oldVal = setManufacturerSheet && setManufacturerSheet != "" ? setManufacturerSheet : "";
                dataManuCut.additional_info.newVal = data;
                this.props.update_detail(types, prodId, dataManuCut, 'Manufacture Cut Sheet updated successfully')
            }
        }

    }

    handleChange = async (evt, data) => {
        if (data) {
            await this.setState({
                // ...this.state,
                [evt.target.name]: evt.target.value,
                [data]: evt.target.validity.valid
            });
        }
        else {
            await this.setState({
                [evt.target.name]: evt.target.value,
            });
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile} = this.props;
        if(my_profile && my_profile.id && authId && (authId == my_profile.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const { setproductDescription, isToggleAddAnother, setManufacturerSheet } = this.state
        return (
            <>
                <div className="bg-white-shaded-md text-secondary mb-3">
                    <CollapseBasic title="Specifications" containerClass="bg-transparent type-secondary" bodyClass="text-secondary-dark" isOpen={true}>
                        {/* Show If no data */}
                        <div hidden>
                            <span className="small"><i>{'No Specifications to Display.'}</i></span>
                        </div>

                        {/* Show If data */}
                        <div>
                            <ul className="list-unstyled mb-2 fs-14">
                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className="fs-14 font-weight-bold">Manufacturer cut sheet:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.setManufacturerSheet == "" || this.state.setManufacturerSheet == null) ?
                                            this.state.addManufacturerSheet === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addManufacturerSheet: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="text"
                                                        bsSize="sm"
                                                        placeholder="Enter Manufacturer cut sheet"
                                                        name="AddNewManufacturerSheet"
                                                        value={this.state.AddNewManufacturerSheet}
                                                        onChange={(evt) => this.handleChange(evt, 'AddNewManufacturerSheetIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Manufacturer cut sheet' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.AddNewManufacturerSheetIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addManufacturerSheet: false
                                                                });
                                                                this.handleFieldsChange('addManufacturerSheet', this.state.AddNewManufacturerSheet)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm"
                                                            onClick={() => {
                                                                this.setState({
                                                                    addManufacturerSheet: false
                                                                })
                                                            }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editManufacturerSheet ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="text" placeholder="Enter product Description" value={this.state.manufacturerSheet} name='manufacturerSheet'
                                                                onChange={(evt) => this.handleChange(evt, 'manufacturerSheetIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter Manufacturer cut sheet' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.manufacturerSheetIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editManufacturerSheet: false
                                                                        })
                                                                        this.handleFieldsChange('editManufacturerSheet', this.state.manufacturerSheet)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editManufacturerSheet: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editManufacturerSheet: true,
                                                                    manufacturerSheet: setManufacturerSheet && setManufacturerSheet != "" ? setManufacturerSheet : ""
                                                                })
                                                            }}
                                                        >{setManufacturerSheet && setManufacturerSheet != "" ? setManufacturerSheet : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className="fs-14 font-weight-bold">Product Description:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.setproductDescription == "" || this.state.setproductDescription == null) ?
                                            this.state.addproductDescription === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addproductDescription: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="textarea"
                                                        bsSize="sm"
                                                        placeholder="Enter Product Description"
                                                        name="AddNewproductDescription"
                                                        value={this.state.AddNewproductDescription}
                                                        onChange={(evt) => this.handleChange(evt, 'AddNewproductDescriptionIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Product Description' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.AddNewproductDescriptionIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addproductDescription: false
                                                                });
                                                                this.handleFieldsChange('addproductDescription', this.state.AddNewproductDescription)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm"
                                                            onClick={() => {
                                                                this.setState({
                                                                    addproductDescription: false
                                                                })
                                                            }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editproductDescription ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="textarea" placeholder="Enter product Description" value={this.state.productDescription} name='productDescription'
                                                                onChange={(evt) => this.handleChange(evt, 'productDescriptionIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter product Description' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.productDescriptionIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editproductDescription: false
                                                                        })
                                                                        this.handleFieldsChange('editproductDescription', this.state.productDescription)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editproductDescription: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 wb-break-word editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editproductDescription: true,
                                                                    productDescription: setproductDescription && setproductDescription != "" ? setproductDescription : ""
                                                                })
                                                            }}
                                                        >{setproductDescription && setproductDescription != "" ? setproductDescription : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>

                            </ul>
                        </div>
                        <div className="text-right mb-2">
                            <Button
                                onClick={() => { 
                                    if(!this.checkIfLoggedIn()){
                                        this.props.forceToggleLoginModal();
                                        return false;
                                    }
                                    this.setState({ isToggleAddAnother: !this.state.isToggleAddAnother }) }}
                                color="link"
                                className="p-0 text-secondary-dark">

                                <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                                {' Add another'}
                            </Button>
                        </div>

                        {isToggleAddAnother ? (
                            <div className="bg-white p-3">
                                <h5 className="text-secondary-dark mb-3 fs-30">Add another</h5>
                                <FormGroup>
                                    <Input

                                        className="primary"
                                        bsSize="sm"
                                        type="select">
                                        <option value="" selected="selected">Please Select</option>

                                    </Input>
                                </FormGroup>

                                <div className="text-right">
                                    <Button
                                        className="mw"
                                        color="primary"
                                        size="sm">
                                        {'Submit'}
                                    </Button>
                                    <Button
                                        onClick={() => { this.setState({ isToggleAddAnother: !this.state.isToggleAddAnother }) }}
                                        className="mw" color="primary" size="sm">Cancel</Button>
                                </div>
                            </div>
                        ) : null}
                    </CollapseBasic>
                </div>
            </>
        )
    }
}

const mapState = (state) => ({
    my_profile: state.user.my_profile,
});
const mapProps = (dispatch) => ({
    update_detail: (type, id, data, message) => dispatch(update_detail(type, id, data, message)),
    // edit_item_details: ({ id, type, data, message }) => dispatch(edit_item_details({ id, type, data, message })), // from item_details
});


export default connect(mapState, mapProps)(Specifications);
