/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Switch, withRouter } from 'react-router-dom';
import Homepage from "./component/pages/homepage";
import Dashboard from './component/pages/dashboard';
import Projects from './component/pages/projects';
import ProjectDetail from './component/pages/projectDetail';
import Business from './component/pages/business';
import PrivateRoute from "./component/PrivateRoute";
import PublicRoute from "./component/PublicRoute";
import { isEnableMobile } from './actions/utils';
import { connect } from "react-redux";
import Home from "./component/pages/home";
import BusinessDetails from './component/pages/businessDetails';
import MovieDetails from './component/pages/movieDetails';
import ProductsDetails from './component/pages/productsDetails';
import Movies from './component/pages/movies';
import MovieCategory from './component/pages/movieCategory';
import BusinessCategory from './component/pages/businessCategory';
import SearchResults from './component/pages/searchResults';
import NoticeBoard from './component/pages/noticeBoard';
import About from './component/pages/static/about';
import FAQ from './component/pages/static/faq';
import Guidelines from './component/pages/static/guidelines';
import Manifesto from './component/pages/static/manifesto';
import PrivacyPolicy from './component/pages/static/privacyPolicy';
import Terms from './component/pages/static/terms';
import Press from './component/pages/static/press';
import Awards from './component/pages/static/awards';
import ViewGallery from './component/pages/viewGallery';
import Products from './component/pages/products';
import ProductsCategory from './component/pages/productsCategory';
import Professionals from './component/pages/professionals';
import ProfessionalsCategory from './component/pages/professionalsCategory';
import DoctorDetails from './component/pages/doctorDetails';
import DentistDetails from './component/pages/dentistDetails';
import AccountantDetails from './component/pages/accountantDetails';
import LawyerDetails from './component/pages/lawyerDetails';
import ActorDetails from './component/pages/actorDetails';
// import Actors from './component/pages/actors';
import AllNotifications from './component/pages/all-notifications';
import Locations from './component/pages/locations';
import HomepageCity from './component/pages/homepage-city';
import ErrorBoundary from './component/ErrorBoundry';
import BuyersGuide from './component/pages/buyersGuide';
import DmcaTakedown from './component/pages/dmca/dmca-takedown';
import DmcaHistory from './component/pages/dmca/dmca-history';
import BrandDetail from './component/pages/brandDetails';
import PrivacySettings from './component/oragnisms/home/PrivacySettings';
import Blog from './component/pages/static/blog';
import People from './component/pages/people';
import DeleteAccount from './component/pages/deleteaccount';
import DeactivateAccount from './component/pages/deactivateaccount';
import SiteTour from './component/molecules/shared/SiteTour/siteTour';
import AdminHome from './component/admin/adminHome';
import PageNotFound from './component/pages/static/pageNotFound';
import LinkTransition from './component/pages/LinkTransition';

const loaderExludedRoutes = ['/'];

class RouteLinks extends Component {
   
    // componentDidMount() {
    //     let origin = window.location.origin;
    //     let env_mobile_origin = process.env.REACT_APP_MOBILE_URL
    //     let env_web_origin = process.env.REACT_APP_WEB_URL

    //     if (origin == env_mobile_origin) {
    //         console.log('originorigin1')
    //         this.props.isEnableMobile(true);
    //     }

    //     if (origin == env_web_origin) {
    //         console.log('originorigin2')
    //         this.props.isEnableMobile(false);
    //     }
    // }

    callAbleRoutes = (type) => {
        return [`/${type}/:id`,
        `/${type}/:service/:city/:state/:name`,
        `/${type}/:service/:city/:state/:name/:item`,
        `/${type}/:service/:city/:state/:name/:item/:item2`,
        `/${type}/:service/:city/:state/:name/:item/:item2/:item3`]
    }
    
    render() {
        let props = this.props; 
        return (
            <Router>
                <SiteTour/>
                <ErrorBoundary key={props?.location?.pathname}>
                    <Switch>
                        <PublicRoute restricted={true} component={Homepage} path="/" exact />
                         {/** Admin Portal */}
                         <PrivateRoute component={AdminHome}  path={['/admin-panel']} exact/>  
                         
                        <PublicRoute
                            component={BusinessDetails}
                            path={["/business/:id","/business/:id/:slug", "/business/:service/:city/:state/:name", "/business/:service/:city/:state/:name/:item"]}
                            exact />
                        <PrivateRoute component={AllNotifications} path="/notifications" exact />
                        <PrivateRoute component={SearchResults} path="/search" exact />
                        <PublicRoute  component={SearchResults} path="/searchOut" exact />
                        <PrivateRoute component={Dashboard} path={["/myprofile"]} exact />
                        <PrivateRoute component={Projects} path="/projectslist" exact />
                        <PrivateRoute component={ProjectDetail} path="/projects/:id" exact />
                        <PrivateRoute component={Home} path="/home" exact />
                        <PrivateRoute component={Movies} path="/movies" exact />
                        <PublicRoute component={MovieDetails} path={["/movies/:id","/movies/:id/:slug"]} exact />
                        {/* <PrivateRoute component={Business} path="/business" exact /> */}
                        <PrivateRoute component={BusinessCategory} path={["/business-category/:category","/business-category/:category/:category2" ]} exact />
                        <PublicRoute restricted={true} component={Homepage} path="/form/passwordresetconfirm/:token" exact />
                        <PublicRoute restricted={true} component={Homepage} path="/api/activate/:token" exact />
                        <PublicRoute restricted={true} component={Homepage} path="/api/confirm-email-id/*" exact />
                        <PrivateRoute component={Business}
                            path={["/business", "/business-reviews/", "/business-reviews/:category", "/business-reviews/:category/:subCategory"]}
                            exact />
                        <PrivateRoute component={Movies} path={["/movies", "/movie-reviews/", "/movie-reviews/:category", "/movie-reviews/:category/:subCategory"]} exact />
                        <PrivateRoute component={MovieCategory} path="/movie/:category" exact />
                        <PrivateRoute component={BuyersGuide} path={["/movie/:pathCategory/buyers-guide","/product/:pathCategory/buyers-guide","/business/:pathCategory/buyers-guide"]} exact />
                        <PrivateRoute component={Products} path={["/products", "/product-reviews"]} exact />
                        <PrivateRoute component={ProductsCategory}
                            path={["/product/:category", "/product/:category/:category2"]} exact />
                        <PublicRoute component={ProductsDetails}
                            path={["/products/:id", "/products/:id/:slug", "/products/:service/:city/:state/:name", "/products/:service/:city/:state/:name/:item",
                                "/products/:service/:city/:state/:name/:item/:item2", "/products/:service/:city/:state/:name/:item/:item2/:item3"]}
                            exact />
                        <PrivateRoute component={Professionals} path="/professionals" exact />
                        <PrivateRoute component={ProfessionalsCategory} path="/professionals/:category" exact />
                        <PublicRoute component={DoctorDetails} path="/doctors/:id" exact />
                        <PublicRoute component={DentistDetails} path="/dentists/:id" exact />
                        <PublicRoute component={AccountantDetails} path="/accountants/:id" exact />
                        <PublicRoute component={LawyerDetails} path="/lawyers/:id" exact />
                        {/* <PrivateRoute component={Actors} path={["/actors", "/actor-reviews"]} exact />*/}
                        <PublicRoute component={ActorDetails} path="/actors/:id" exact />
                        <PrivateRoute component={NoticeBoard} path="/notice-board" exact />
                        <PublicRoute component={About} path="/about" exact />
                        <PublicRoute component={FAQ} path="/faq" exact />
                        <PrivateRoute component={Manifesto} path="/manifesto" exact />
                        <PublicRoute component={Guidelines} path="/guidelines" exact />
                        <PublicRoute component={PrivacyPolicy} path="/policies" exact />
                        <PublicRoute component={Terms} path="/terms" exact />
                        <PublicRoute component={Blog} path="/blog" exact />
                        <PrivateRoute component={Press} path="/press" exact />
                        <PrivateRoute component={Awards} path="/awards" exact />
                        {/* Deactivate Account */}
                        <PrivateRoute component={DeactivateAccount} path={["/deactivateaccount"]} exact /> 
                        {/* Delete Account */}
                        <PrivateRoute component={DeleteAccount} path={["/deleteaccount"]} exact />
                        <PrivateRoute component={ViewGallery} path="/view_all/:id" exact />
                        <PublicRoute component={Locations} path="/locations" exact />
                        <PrivateRoute component={BuyersGuide} path="/buyersguide" exact />
                        <PrivateRoute component={DmcaTakedown} path="/dmca-takedown" exact />
                        <PrivateRoute component={DmcaHistory} path="/dmca-history" exact />
                        <PrivateRoute component={BrandDetail} path="/brands/:slug" exact />
                        <PrivateRoute component={PrivacySettings} path="/privacysettings" exact />
                        <PublicRoute component={People} path={["/people/:id"]} exact />

                        {/* Transition Page on Link click */}
                        <PublicRoute component={LinkTransition} path={["/abouttransition/"]} exact />
                        
                       
                        <PublicRoute component={PageNotFound} path="*" exact />
                        {/* <PublicRoute restricted={true} component={HomepageCity} path="/:city" exact /> */}
                        
                    </Switch>
                </ErrorBoundary>
            </Router>
        );
    }
}
export { loaderExludedRoutes }



const mapProps = (dispatch) => ({
    isEnableMobile: (data) => dispatch(isEnableMobile(data)),
})

export default withRouter(connect(null, mapProps)(RouteLinks));
