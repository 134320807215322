import React from "react";
import { Helmet } from "react-helmet";
import { WEB_URL } from "../../../../utils/constants";
export default function HelmetMetaData(props) {
    let quote = props.quote || "";
    let title = props.title || "Trusted Reviews and Recommendations from Your Community";
    let image = props.image || "https://userdatawikireviews.s3.amazonaws.com/images/w-share.png";
    let description = props.description || "Trusted Reviews and Recommendations from Your Community";
    let hashtag = props.hashtag || "#wikireviews";

    return (
        <Helmet>
            <title>{title}</title>
            <meta charset="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta property="type" content="website" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="theme-color" content="#ffffff" />
            <meta property="title" content={title} />
            <meta property="quote" content={quote} />
            <meta name="description" content={description} />
            <meta property="image" content={image} />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="350" />
            <meta property="og:site_name" content={WEB_URL} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:quote" content={quote} />
            <meta property="og:hashtag" content={hashtag} />
            <meta property="og:image" content={image} />
            <meta property="og:description" content={description} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:card" content={WEB_URL} />
            <meta name="twitter:site" content={WEB_URL} />
            <meta name="twitter:creator" content="@WikiReviews" />
        </Helmet>
    );
}