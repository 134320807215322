import React, { Component } from 'react';
import { Badge, Button, ButtonGroup, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Media, Nav, NavItem, NavLink, Row, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AppHeader from '../oragnisms/dashboard/appHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-multi-carousel';
import CollapseBasic from '../atoms/collapse';
import EditBtn from '../atoms/editBtn';
import DeleteBtn from '../atoms/deleteBtn';
import TipsAndWarning from '../oragnisms/buyersGuide/tipsAndWarning'
import Vocablory from '../oragnisms/buyersGuide/vocablory'
import DiscussThisPage from "../oragnisms/buyersGuide/discussThisPage"
import ArticleTab from "../oragnisms/buyersGuide/articleTab"
import DiscussionTab from "../oragnisms/buyersGuide/discussionsTab"
import HistoryTab from "../oragnisms/buyersGuide/historyTab"
import UploadMedia from '../atoms/uploadMedia';
import ConfirmModal from '../atoms/confirmModal';
import { delete_tips_warning, get_tips_warning, get_buyers_talks, get_buyers_guides, get_vocablory, get_buyers_trending, get_buyers_guide_history } from '../../actions/buyersGuide';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";


class BuyersGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMediaModalToggle: false,
            createNewDisModalToggle: false,
            selectedGuideTabType: "article",
            mediaSet: [],
            reviews: [],
            productId: '',
            taxonomy_id: "",
            productDetail: "",
            productName: "",
            manufacturer: "",
            imgUrl: null,
            modelNumber: null,
            avgRating: null,
            priceMax: "",
            priceMim: "",
            similarProducts: "",
            overallRatingVote: null,
            isEnableConfirmModal: false,
            isEnableFileUpload: false,
            confirmModalText: '',
            confirmModalKey: '',
            confirmModalItemId: null,
            chartStarRatings: null,
            enableStickyHeader: false,
            deleteOfferDetails: false,
            discussionTab: "",
            confirmMessateText: "",
            loaded: false,
            uploadMediaUrl: "",
            keyCompare: "",
            discusstype: "",
            uploadedMediaSet: {
                embedLinks: {},
                uploadedFiles: [],
            },
            category: "",
            trendingData: [],
            carouselMedia: [],
            secondCarouselMedia: [],
            openEditAllArticlesPage: false,
            getHistoryData: {},
            headerImage: null
        }
    }

    componentWillReceiveProps(nextProps) {
      
        if (nextProps.get_buyersTrending && Array.isArray(nextProps.get_buyersTrending)) {
            let newtrending = []
            nextProps.get_buyersTrending.forEach((trendingImages,index) => {
                if(trendingImages.trending_bg && Array.isArray(trendingImages.trending_bg) && trendingImages.trending_bg.length > 0){
                    newtrending.push(trendingImages.trending_bg[0]);
                }
            })
            if (nextProps.get_buyersHistory){
                this.setState({ getHistoryData : nextProps.get_buyersHistory })
            }

            /*nextProps.get_buyersTrending.forEach((trendingImages,index) => {
                if(trendingImages.media_list && Array.isArray(trendingImages.media_list) && trendingImages.media_list.length > 0){
                newtrending.push(trendingImages.media_list[0]);
                trendingImages.media_list.forEach((mediaImages,index) => {
                   
                  newtrending.push(mediaImages);
                })
                }
            })*/
            if (nextProps.get_buyersTrending[0] && nextProps.get_buyersTrending[0].media_list && Array.isArray(nextProps.get_buyersTrending[0].media_list)) {
                this.setState({ trendingData: nextProps.get_buyersTrending, carouselMedia: nextProps.get_buyersTrending[0].media_list.length > 0 ? nextProps.get_buyersTrending[0].media_list : newtrending, headerImage: nextProps?.get_buyersTrending?.[0]?.treding_bg?.[0]?.url}, () => {
                    if (!this.state.headerImage) {
                        let headerImage;
                        if (this.state.carouselMedia?.length > 1) {
                            headerImage = this.state.carouselMedia[1]?.url
                        } else if (this.state.carouselMedia?.length == 1) {
                            headerImage = this.state.carouselMedia[0]?.url
                        }
                        this.setState({ headerImage })
                    } 
                })
            }
        }
    }


    toggleConfirmModal = ({ text, isTextDelete, uniqueKey, id, message }) => {
        let text_str = '';
        let messagetstr = '';
        if (isTextDelete && !text) {
            text_str = 'Do you want to delete this ?';
        }

        if (text && !isTextDelete) {
            text_str = text;
            messagetstr = message;
        }

        if (text && isTextDelete) {
            text_str = text;
            messagetstr = message;
        }

        this.setState({
            isEnableConfirmModal: !this.state.isEnableConfirmModal,
            confirmModalText: text_str,
            confirmMessateText: messagetstr,
            confirmModalKey: uniqueKey || '',
            confirmModalItemId: id || null
        });
    }
    handleOnConfirmModal = () => {
        let { taxonomy_id } = this.state;
        let { confirmModalKey, confirmModalItemId } = this.state;
        if (confirmModalKey === "delete-product-tips" && confirmModalItemId) {
            let type = 'tip';
            this.props.delete_tips_warning(confirmModalItemId, '', type, taxonomy_id);
        }

        if (confirmModalKey === "delete-product-warning" && confirmModalItemId) {
            let type = 'warning';
            this.props.delete_tips_warning(confirmModalItemId, '', type, taxonomy_id);
        }

    }
    componentDidMount() {
        let { productId, taxonomy_id } = this.state;
        let props = this.props;
        let { match } = this.props;
        let taxonomyId = props?.history?.location?.state?.taxonomyId;

        let categoryName = match?.params?.pathCategory;
        if (taxonomyId) {
            this.setState({ taxonomy_id: taxonomyId, category: categoryName }, () => {
                this.fetchByItemId(taxonomyId)
            })
        }
        window.addEventListener("scroll", this.handleScroll, { passive: false });
    }
    toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
    }

    callBuyersTrendingApi = () => {
        this.props.get_buyers_trending(this.state.taxonomy_id);
    }

    fetchByItemId = (taxonomy) => {
       
        // taxonomy = 1464;
        this.props.get_tips_warning(taxonomy);
        this.props.get_buyers_guides(taxonomy);
        this.props.get_vocablory(taxonomy);
        this.props.get_buyers_trending(taxonomy);
        this.props.get_buyers_guide_history(taxonomy, '');

        if (taxonomy && taxonomy != "") {
            //let url = `/api/buyers-discussion/?entity=${taxonomy}&page=1`;
            let url = `/api/buyerstalk/?category=${taxonomy}&page=1`;
            // https://wikireviews.com/api/buyerstalk/?category=6962&page=1
            this.props.get_buyers_talks(url);
        } else {
            let url = `/api/buyerstalk/?category=&page=1`;
            this.props.get_buyers_talks(url);
        }

    }

    handleActiveDiscussionTab = () => {
        this.setState({ selectedGuideTabType: 'discussion' })
    };

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet } = this.state;

        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
            uploadedMediaSet['uploadedFiles'] = mediaSet;
        }

        if (links) {
            uploadedMediaSet['embedLinks'] = links;
        }
        this.setState({ uploadedMediaSet });
    }

    handleOnClickUploadModalOk = () => {
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }

    getuploadUrl = (url) => {
        let { uploadMediaUrl } = this.state;
        uploadMediaUrl = url.uploadUrl;
        this.setState({ uploadMediaUrl });
    }
    backtoCategory = () => {
        const { history } = this.props;
        history.goBack()
    }
    editPage = () => {
        this.setState({
            openEditAllArticlesPage: true,
        })
    };

    closeEditPage = () => {
        this.setState({
            openEditAllArticlesPage: false,
        })
    };



    resetUploadedMediaSet = () => {
        const uploadedMediaSet = {
            embedLinks: {},
            uploadedFiles: [],
            mediaSet:[]
        }
        this.setState({ uploadedMediaSet });
    }
    openSecondCarousal = (index) => {
        const carouselMedia = this.state.carouselMedia;

        const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
        const array2 = (index !== carouselMedia.length - 1) ? carouselMedia.filter((item, i) => i > index) : [];
        const newArray = [carouselMedia[index], ...array2, ...array1];
        this.setState({ secondCarouselMedia: newArray, viewMediaModalToggle: true });
    }

    forceToggleLoginModal = () => {
        this.setState(prevState => ({
            ...this.prevState,
            forceShowLoginModal: !prevState.forceShowLoginModal
        }));
    }

    getnocategory = (discusst) => {
        let { discussType } = this.state;

        if (discusst && discusst != "") {
            this.setState({ discussType: discusst });
        }
    }


    //  componentDidUpdate() {
    //     let nextProps = this.props;
    //     let next_uploaded_files = nextProps.uploadedMediaSet?.uploadedFiles.length;
    //     const { uploadedFiles } = this.state;

    //     if (next_uploaded_files !== uploadedFiles.length) {
    //         const { uploadedFiles } = this.props.uploadedMediaSet;
    //         this.setState({ uploadedFiles: [...uploadedFiles] });
    //     }

    // 	/*if(nextProps.notifiTab != this.props.notifiTab){
    // 		this.setState({ mainViewType: this.props.notifiTab });
    // 	}*/
    // }

    componentDidUpdate() {
        let { mediaSet, discussionList, uploadedMediaSet } = this.state;
        let nextProps = this.props;
        let get_buyersTalks = nextProps?.get_buyersTalks;
        if (get_buyersTalks?.results?.length !== discussionList?.length) {
            this.setState({ discussionList: nextProps?.get_buyersTalks?.results });
        }
        let uploadedFiles = uploadedMediaSet?.uploadedFiles;
        if (Array.isArray(uploadedFiles) && Array.isArray(mediaSet) && uploadedFiles?.length !== mediaSet?.length) {
            this.setState({ mediaSet: uploadedFiles });
        }

        //let next_uploaded_files = nextProps.uploadedMediaSet?.uploadedFiles.length;


        // if (next_uploaded_files !== uploadedFiles.length) {
        //     const { uploadedFiles } = this.props.uploadedMediaSet;
        //     this.setState({ uploadedFiles: [...uploadedFiles] });
        // }
    }

    discussTab = (disscusion) => {
        let { discussionTab } = this.state;
        discussionTab = disscusion;
        this.setState({ discussionTab });
    }

    getnocategory = (discusst) => {
        let { discussType } = this.state;
        if (discusst && discusst != "") {
            this.setState({ discussType: discusst });
        }
    }

    setStateOfDiscussion = (tab) => {
        //this.setState({title: newTitle});
        this.setState({ selectedGuideTabType: tab })
    }


    render() {


        let { productId, productName, isEnableConfirmModal, confirmModalText, confirmModalKey, isEnableFileUpload,
            uploadedMediaSet, trendingData, carouselMedia, secondCarouselMedia, viewMediaModalToggle, getHistoryData } = this.state;

        const responsive = {
            videoModal: {
                mobile: {
                    breakpoint: { max: 9999999, min: 0 },
                    items: 1
                }
            },
            viewProductsInfoImages: {
                desktop: {
                    breakpoint: { max: 9999999, min: 1200 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1199, min: 768 },
                    items: 3
                },
                mobile: {
                    breakpoint: { max: 767, min: 0 },
                    items: 1
                }
            }
        }
        return (

            <div>
                {isEnableConfirmModal ?
                    <ConfirmModal
                        modalText={confirmModalText}
                        modalKey={confirmModalKey}
                        toggleConfirmModal={this.toggleConfirmModal}
                        handleOnConfirm={this.handleOnConfirmModal}
                    />
                    : null}
                {isEnableFileUpload ?
                    <UploadMedia
                        getUploadedMedia={this.getUploadedMedia}
                        uploadedUrl={this.state.uploadMediaUrl}
                        hideEmbed={this.state.hideEmbed}
                        callbackFunction={this.handleOnClickUploadModalOk}
                        noCategory={this.state.discussionTab}
                        typeUpload={this.state.typeupload}
                        toggleEnableFileUpload={this.toggleEnableFileUpload}
                        discusstype={this.state.discussType}
                    />
                    : null}
                <AppHeader isEnableCover={false} />

                <header className="bg-dark text-white position-relative">
                    {this.state.headerImage ?
                        <div className="featured-blurred-bg extra-blurr full-height" style={{ backgroundImage: `url(${this.state.headerImage})` }}></div>
                        :
                        ''
                        // <div className="featured-blurred-bg extra-blurr full-height" style={{ backgroundImage: "url('https://www.chevrolet.com/content/dam/chevrolet/na/us/english/index/vehicles/2021/performance/camaro/mov/01-images/2021-camaro-masthead-01.jpg')" }}></div>
                    }
                    <Container fluid={true} className="container-lg position-relative px-3" style={{ padding: '70px 0 30px', minHeight: '500px' }}>
                        <Row>
                            <Col xs={{ order: 1 }} lg={{ order: 1 }} className="col-auto flex-lg-180">
                                <Button color="tertiary reset" onClick={this.backtoCategory}>Back to Category</Button>
                            </Col>
                            <Col xs={{ order: 2 }} lg={{ order: 3 }} className="col-auto ml-auto flex-lg-180 text-right">
                                {/*<Button color="dark" className="guide-edit-mode--highlighted" onClick={this.editPage}>edit page <FontAwesomeIcon icon="pencil-alt" className="ml-2" /> </Button>*/}
                            </Col>
                            <Col xs={{ order: 3, size: 12 }} lg={{ order: 4 }} className="d-lg-none">{" "}</Col>
                            <Col xs={{ order: 4 }} lg={{ order: 2 }} className="col text-center">
                                <h1 className="buyers-guide-heading guide-edit-mode--highlighted">Buyer's Guide: <span className="buyersCategory">{this.state.category}</span></h1>
                            </Col>
                        </Row>
                        <Row className="text-center text-lg-left mt-2">
                            <Col lg={4} className="align-self-lg-end">
                                <Dropdown size="sm" className="hoverable">
                                    <DropdownToggle className="type-transparent" caret>
                                        <span className="mr-1">Type</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="type-tertiary-alt">
                                        <DropdownItem>Type 1</DropdownItem>
                                        <DropdownItem>Type 2</DropdownItem>
                                        <DropdownItem>Type 3</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown size="sm" className="hoverable">
                                    <DropdownToggle className="type-transparent" caret>
                                        <span className="mr-1">Size</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="type-tertiary-alt">
                                        <DropdownItem>Size 1</DropdownItem>
                                        <DropdownItem>Size 2</DropdownItem>
                                        <DropdownItem>Size 3</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown size="sm" className="hoverable">
                                    <DropdownToggle className="type-transparent" caret>
                                        <span className="mr-1">Price Range</span>
                                    </DropdownToggle>
                                    <DropdownMenu className="type-tertiary-alt">
                                        <DropdownItem>Price Range 5</DropdownItem>
                                        <DropdownItem>Price Range 2</DropdownItem>
                                        <DropdownItem>Price Range 3</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>

                            <Col lg={4}>
                                <h3 className="text-center mt-2 text-shadow-soft">Top-Rated <span className="buyersCategory">{this.state.category}</span></h3>
                            </Col>
                        </Row>

                        <div className="mx-n3 pt-3">
                         
                            <Carousel
                                responsive={responsive.viewProductsInfoImages}
                                afterChange={(previousSlide, { currentSlide, onMove }) => {
                                    let headerImage;
                                    if (currentSlide - 2 >= carouselMedia.length) {
                                        headerImage = carouselMedia?.[0]?.url
                                    } else {
                                        headerImage = carouselMedia?.[currentSlide-2]?.url
                                    }
                                    this.setState({ headerImage })
                                }}
                                infinite={true}
                                autoPlay={false}
                                swipeable={true}
                                showDots={false}
                                arrows={true}
                                containerClass="gallery-carousel"
                                sliderClass={`${carouselMedia?.length === 0}` ? "mx-auto" : ""}>

                                {(carouselMedia && Array.isArray(carouselMedia) && carouselMedia.length > 0) ? (
                                    carouselMedia.map((mediaData, i) => {
                                        return (
                                            <div className="item px-3" key={i}>
                                                <div className={`img-holder text-center`} role="button">
                                                    <div className={`${mediaData?.media_type === 'video' ? 'video-holder' : 'img-holder'} text-center`} role="button" onClick={() => this.openSecondCarousal(i)}>
                                                        <img
                                                            className="img-thumbnail fixed-height-img is-200 full-width"
                                                            src={`${mediaData && mediaData.url ? mediaData.url : 'https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'}`}
                                                            style={{ border: '2px solid #8791b5' }}
                                                            onError={(error) =>
                                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                            }
                                                            alt={`${mediaData && mediaData.url && mediaData.caption ? mediaData.caption : 'no-image'}`}
                                                        />
                                                    </div>
                                                    <div className="text-left position-relative">
                                                        <Link
                                                            // to={`/people/${med?.media?.user?.user?.username}`}
                                                            to={{
                                                                pathname: `${`/people/${mediaData?.user?.user?.username}`}`,
                                                                state: { id: mediaData?.user?.user?.id }
                                                            }}
                                                        >
                                                            <img className="img-circle _30x30 mt-n4 ml-3" src={mediaData?.user?.current_profile_pic ? mediaData?.user?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt="" onError={(error) =>
                                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                            } />
                                                        </Link>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="item px-4">
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                                <span>No result found</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Carousel>
                        </div>
                    </Container>
                </header>

                {/* Main content starts */}
                <section className="py-3">
                    <Container>
                        <Row>
                            <Col xs={12} className="col-lg-auto flex-lg-340">
                                <div className="text-secondary-dark fs-14">
                                    {/* Vocabulary */}
                                    <Vocablory toggleConfirmModal={this.toggleConfirmModal} taxonoId={this.state.taxonomy_id} />
                                    {/* Tips & Warnings */}
                                    <TipsAndWarning taxonomy_id={this.state.taxonomy_id} name={this.state.productName} toggleConfirmModal={this.toggleConfirmModal} />


                                    {/* Discuss This Page */}
                                    
                                    <DiscussThisPage
                                        // id={productId}
                                        id={this.state.taxonomy_id}
                                        listing_type={'products'}
                                        isLight={true}
                                        getUploadedMedia={this.getUploadedMedia}
                                        toggleEnableFileUpload={this.toggleEnableFileUpload}
                                        uploadedMediaSet={this.state.uploadedMediaSet}
                                        mediaSet={this.state.mediaSet}
                                        discussTab={this.discussTab}
                                        forceToggleLoginModal={this.forceToggleLoginModal}
                                        getnocategory={this.getnocategory}
                                        resetUploadedMediaSet={this.resetUploadedMediaSet}
                                        setStateOfDiscussion = {this.setStateOfDiscussion}

                                    />
                                </div>
                            </Col>
                            <Col xs={12} className="flex-lg-340-rest">
                                <div className="p-3 guide-edit-mode--highlighted" style={{ backgroundColor: '#fdfcf8' }}>
                                    <CollapseBasic title="Rules for contribution to the Buyer's Guide" containerClass="bg-transparent type-secondary heading-lg heading-secondary-dark expanded-dark" bodyClass="fs-13 ff-alt text-secondary-dark" isOpen={true} noPadding={true}>
                                        <p>
                                            Contributors to the Buyer’s Guide sections of WikiReviews will abide by the following five simple rule
                                        </p>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <strong className="ff-base">1) {" "}</strong>
                                                Do not use promotional language; try to keep your emotions out of it.
                                            </li>
                                            <li className="mb-2">
                                                <strong className="ff-base">2) {" "}</strong>
                                                Try not to amend the words of others unless it is entirely necessary. Arguing over minor wording changes is generally not defined as “necessary”.
                                            </li>
                                            <li className="mb-2">
                                                <strong className="ff-base">3) {" "}</strong>
                                                Any time you add something, make sure you support it with a source. A url is fine, just make sure you have something to back up what you have to say; and something you can show is reputable. No blogs or comment threads please. Press releases or company websites are fine, so long as you abide by Rule #1.
                                            </li>
                                            <li className="mb-2">
                                                <strong className="ff-base">4) {" "}</strong>
                                                If you have a conflict of interest, just say so. We don’t mind if you work for the company you are writing about, but let us know so that we all have the full context of your contributions.
                                            </li>
                                            <li className="mb-2">
                                                <strong className="ff-base">5) {" "}</strong>
                                                If you have an issue with the behavior or contributions of another user, the correct place for that discussion is by opening a discussion thread on the administrator’s noticeboard; not through disrupting the page itself.
                                            </li>
                                        </ul>
                                    </CollapseBasic>
                                </div>
                                <div className="text-dark fs-14 mt-3">
                                    <Nav className="main" tabs>
                                        <NavItem>
                                            <NavLink
                                                active={this.state.selectedGuideTabType === "article"}
                                                onClick={() => this.setState({ selectedGuideTabType: 'article' })}
                                            >


                                                <div className="ff-base">
                                                    Article
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                active={this.state.selectedGuideTabType === "discussion"}
                                                onClick={() => this.setState({ selectedGuideTabType: 'discussion' })}
                                            >
                                                <div className="ff-base">
                                                    Discussion
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink
                                                active={this.state.selectedGuideTabType === "talk"}
                                                onClick={() => this.setState({ selectedGuideTabType: 'talk' })}
                                            >
                                                <div className="ff-base">
                                                    Talk
                                                </div>
                                            </NavLink>
                                        </NavItem> */}
                                        <NavItem>
                                            <NavLink
                                                active={this.state.selectedGuideTabType === "history"}
                                                onClick={() => this.setState({ selectedGuideTabType: 'history' })}
                                            >
                                                <div className="ff-base">
                                                    History
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={this.state.selectedGuideTabType}>
                                        <TabPane tabId="article">
                                            <ArticleTab
                                                taxonomy_id={this.state.taxonomy_id}
                                                openEditAllArticlesPage={this.state.openEditAllArticlesPage}
                                                callBuyersTrendingApi={this.callBuyersTrendingApi}
                                                closeEditPage={this.closeEditPage} />
                                        </TabPane>
                                        <TabPane tabId="discussion">
                                            <DiscussionTab
                                                id={this.state.taxonomy_id}
                                                getUploadedMedia={this.getUploadedMedia}
                                                toggleEnableFileUpload={this.toggleEnableFileUpload}
                                                // uploadedMediaSet={this.state.uploadedMediaSet}
                                                uploadedMediaSet={this.state.mediaSet}
                                                discussTab={this.discussTab}
                                                getnocategory={this.getnocategory}
                                                resetUploadedMediaSet={this.resetUploadedMediaSet}
                                            />
                                        </TabPane>
                                        <TabPane tabId="history">
                                           <HistoryTab
                                               taxonomyId={this.state.taxonomy_id}
                                               getHistoryData={getHistoryData}
                                           />
                                        </TabPane>

                                    </TabContent>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* View Media Modal */}
                    <Modal centered isOpen={this.state.viewMediaModalToggle} toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}>
                        <ModalBody className="bg-transparent p-0">
                            <Carousel
                                responsive={responsive.videoModal}
                                infinite={true}
                                autoPlay={false}
                                swipeable={true}
                                showDots={false}
                                arrows={true}
                                sliderClass={`${carouselMedia?.length === 0}` ? "mx-auto" : ""}>

                                {secondCarouselMedia.length > 0 ? secondCarouselMedia.map((med, i) => {
                                    return (
                                        <div className="item fs-14 h-100 d-flex flex-column justify-content-center" key={med?.id}>
                                            <div>
                                                <div className="d-flex text-white justify-content-between mt-3">
                                                    <div className="mr-2 ff-alt">
                                                        {med?.caption}
                                                    </div>
                                                    <div className="ff-base">
                                                        {med?.label}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                                {(med?.type === 'image' || med?.type === '') && (
                                                    <img className="img-fluid shown-media" src={med?.url} alt="" />
                                                )}
                                                {med?.type === 'video' && (
                                                    <video className="img-fluid shown-media" controls autoPlay>
                                                        <source src={med?.url} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                )}
                                                {/* <div>
                                                {i+1} of {secondCarouselMedia.length}
                                            </div> */}
                                            </div>
                                            <div className="px-2 mt-3">
                                                <div className="d-flex flex-nowrap mx-n2">
                                                    <div className="px-2 col d-flex flex-nowrap">
                                                        <div className="mr-2">
                                                            <img className="img-circle _30x30" src={med?.user?.current_profile_file ? med?.user?.current_profile_file : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt="" onError={(error) =>
                                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                            } />
                                                        </div>
                                                        <div>
                                                            <div className="text-primary ff-base">
                                                                Uploaded by&nbsp;<a className="text-reset" href="#"><span className="font-weight-bold">{med?.user?.user?.first_name}</span> </a>
                                                            </div>
                                                            <div className="text-tertiary ff-base">
                                                                {med?.upload_date}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-2 col-auto">
                                                        <h2 className="fs-38" style={{ color: '#d4dae6' }}>{i + 1} of {secondCarouselMedia.length}</h2>
                                                    </div>
                                                    <div className="px-2 col">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : (
                                    <div className="item px-4">
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                                <span>No result found for this category</span>
                                                {/* <div className="mt-2">
                                                    <Button color="link" className="text-tertiary font-weight-bold">Back</Button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Carousel>
                        </ModalBody>
                    </Modal>
                </section>
            </div>
        )
    }
}

const mapState = (state) => ({
    get_buyersTrending: state.buyersGuide.get_buyersTrending,
    get_buyersHistory: state.buyersGuide.get_buyersHistory
    // get_buyersTrending: state.buyersGuide.get_buyersTrending

});

const mapProps = (dispatch) => ({
    delete_tips_warning: (id, productId, type, taxonomy_id) => dispatch(delete_tips_warning(id, productId, type, taxonomy_id)),
    get_tips_warning: (taxonomyId) => dispatch(get_tips_warning(taxonomyId)),
    get_buyers_talks: (url) => dispatch(get_buyers_talks(url)),
    get_buyers_guides: (taxonomyId) => dispatch(get_buyers_guides(taxonomyId)),
    get_vocablory: (taxonomyId) => dispatch(get_vocablory(taxonomyId)),
    get_buyers_trending: (taxonomyId) => dispatch(get_buyers_trending(taxonomyId)),
    get_buyers_guide_history: (taxonomyId, url) => dispatch(get_buyers_guide_history(taxonomyId, url)),

});


export default withRouter(connect(mapState, mapProps)(BuyersGuide));
