import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CustomInput, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { update_detail } from '../../../actions/itemDetail';
// import { edit_item_details } from '../../../actions/itemDetail';
class TechnicalDetails extends Component {
    constructor() {
        super()
        this.state = {
            setbrandName: null,
            editBrandName: null,
            brand: null,
            AddNewBrandName: false,
            addNewBrandNameIsValid: false,
            addBrandName: false,
			brandIsValid: false,
			setmanufacturerName:null,
			editManufacturerName: null,
            manufacturer: null,
            AddNewManufacturerName: false,
            addNewManufacturerNameIsValid: false,
            addManufacturerName: false,
            manufacturerIsValid: false,
            modelSet: null,
            addModel: false,
            editModel: false,
            newModel: null,
            newModelIsValid: false,
            model: null,
            modelIsValid: false,
            listPrice: null,
            setproductDescription: null,
            addproductDescription: false,
            editproductDescription: false,
            AddNewproductDescription: false,
            productDescription: null,
            AddNewproductDescriptionIsValid: false,
            productDescriptionIsValid: null,
            setproductDetails: null,
            addproductDetails: false,
            editproductDetails: false,
            AddNewproductDetails: false,
            productDetails: null,
            AddNewproductDetailsIsValid: false,
            productDetailsIsValid: null,
            assembledDepth: null,
            assembledHeight: null,
            assembledWeight: null,
            assembledWidth: null,
            setManufacturerSheet: null,
            editManufacturerSheet: null,
            manufacturerSheet: null,
            AddNewManufacturerSheet: false,
            AddNewManufacturerSheetIsValid: false,
            addManufacturerSheet: false,
            manufacturerSheetIsValid: false,
            manufacturerWarranty: null,
            packedDepth: null,
            packedHeight: null,
            packedWeight: null,
            packedWidth: null,
            priceMax: null,
            setPriceMax: null,
            addPriceMax: false,
            editPriceMax: false,
            newPriceMax: null,
            newPriceMaxIsValid: false,
            priceMaxIsValid: false,
            setPriceMim: null,
            addPriceMim: false,
            editPriceMim: false,
            addnewPriceMim: null,
            addnewPriceMimIsValid: false,
            priceMim: null,
            priceMimIsValid: false,
            brandIsValid: false,
            prodId: 0,
            isToggleAddAnother: false,
            productsList:
                [{ name: "Samsung", isChecked: false }, { name: "Sony", isChecked: false }, { name: "Phillips", isChecked: false }, { name: "Others", isChecked: false }],
            addedProducts: []
        }
    }
    async componentWillReceiveProps(nextProps) {
        let { TechnicalDetail, productId } = nextProps;
        
       
        if (TechnicalDetail) {
            await this.setState({

                setbrandName: TechnicalDetail?.manufacturer && TechnicalDetail?.manufacturer != null ? TechnicalDetail?.manufacturer : "",
                setmanufacturerName: TechnicalDetail?.manufacturer && TechnicalDetail?.manufacturer != null ? TechnicalDetail?.manufacturer : "",
                modelSet: TechnicalDetail?.model_number && TechnicalDetail?.model_number != null ? TechnicalDetail?.model_number : "",
                listPrice: TechnicalDetail?.additional_info?.['List Price'] ? TechnicalDetail?.additional_info?.['List Price'][0].value : "",
                setproductDetails: TechnicalDetail?.additional_info?.['Product Details'] ? TechnicalDetail?.additional_info?.['Product Details'][0].value : "",
                assembledDepth: TechnicalDetail?.additional_info?.assembled_depth ? TechnicalDetail?.additional_info?.assembled_depth[0].value : "",
                assembledHeight: TechnicalDetail?.additional_info?.assembled_height ? TechnicalDetail?.additional_info?.assembled_height[0].value : "",
                assembledWeight: TechnicalDetail?.additional_info?.assembled_weight ? TechnicalDetail?.additional_info?.assembled_weight[0].value : "",
                assembledWidth: TechnicalDetail?.additional_info?.assembled_width ? TechnicalDetail?.additional_info?.assembled_width[0].value : "",
                setManufacturerSheet: TechnicalDetail?.additional_info?.manufacturer_cut_sheet ? TechnicalDetail?.additional_info?.manufacturer_cut_sheet[0].value : "",
                manufacturerWarranty: TechnicalDetail?.additional_info?.manufacturer_warranty ? TechnicalDetail?.additional_info?.manufacturer_warranty[0].value : "",
                packedDepth: TechnicalDetail?.additional_info?.packed_depth ? TechnicalDetail?.additional_info?.packed_depth[0].value : "",
                packedHeight: TechnicalDetail?.additional_info?.packed_height ? TechnicalDetail?.additional_info?.packed_height[0].value : "",
                packedWeight: TechnicalDetail?.additional_info?.packed_weight ? TechnicalDetail?.additional_info?.packed_weight[0].value : "",
                packedWidth: TechnicalDetail?.additional_info?.packed_width ? TechnicalDetail?.additional_info?.packed_width[0].value : "",
                setPriceMax: TechnicalDetail?.additional_info?.price_max ? TechnicalDetail?.additional_info?.price_max[0].value : "",
                setPriceMim: TechnicalDetail?.additional_info?.price_min ? TechnicalDetail?.additional_info?.price_min[0].value : "",
                setproductDescription: TechnicalDetail?.additional_info?.['Product Description'] ? TechnicalDetail?.additional_info?.['Product Description'][0].value : "",
            });
        }
        if (productId) {
            this.setState({
                prodId: productId,
            });
           
        }
    }
    onAddingItem = async (item) => {
        const isChecked = item.target.checked;
        const value = item.target.value;
		
        this.setState(prevState => ({ productsList: prevState.productsList.map(product => product.name === value ? { ...product, isChecked } : product) }));

        if (isChecked){
            this.setState(prevState => ({ addedProducts: [...prevState.addedProducts, value] }));
        }else {
            const newAddedProducts = this.state.addedProducts.filter(product => product !== value)
            this.setState({ addedProducts: newAddedProducts });
        }
        let type = "products";
        let message = "Technical Detail update Successfully!"

        // this.props.edit_item_details(productId,type,data,message)
    }
    handleFieldsChange = (type, data) => {

        const { prodId, setproductDescription, setproductDetails, setPriceMax, setPriceMim, setManufacturerSheet } = this.state
        if (prodId && prodId != 0) {
            if (type === 'addModel') {
                const modelnumber = {
                    "model_number": data
                }
                let types = "products";
                this.props.update_detail(types, prodId, modelnumber, 'Model Number updated successfully')
            } else if (type === 'editModel') {
                const modelnumber = {
                    "model_number": data
                }
                let types = "products";
                this.props.update_detail(types, prodId, modelnumber, 'Model Number updated successfully')
            }

            else if (type === 'addBrandName') {
                const brandname = {
                    "manufacturer": data
                }
                let types = "products";
                this.props.update_detail(types, prodId, brandname, 'Brand name updated successfully')
            } else if (type === 'editBrandName') {
                const brandname = {
                    "manufacturer": data
                }
                let types = "products";
                this.props.update_detail(types, prodId, brandname, 'Brand name updated successfully')
            }else if (type === 'addManufacturerName') {
                const brandname = {
                    "manufacturer": data
                }
                let types = "products";
                this.props.update_detail(types, prodId, brandname, 'Manufacturer updated successfully')
            } else if (type === 'editManufacturerName') {
                const brandname = {
                    "manufacturer": data
                }
                let types = "products";
                this.props.update_detail(types, prodId, brandname, 'Manufacturer updated successfully')
            }
            else if (type === 'addManufacturerSheet') {
                const manufacturerCutSheet = {
                    "manufacturer_cut_sheet": data
                }
                let dataManuCut = {}
                dataManuCut.additional_info = {}
                dataManuCut.additional_info.key = 'manufacturer_cut_sheet';
                dataManuCut.additional_info.oldVal = setManufacturerSheet && setManufacturerSheet.length > 0 ? setManufacturerSheet[0].value : "";
                dataManuCut.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataManuCut, 'Manufacture Cut Sheet updated successfully')
            }
            else if (type === 'editManufacturerSheet') {
                const manufacturerCutSheet = {
                    "manufacturer_cut_sheet": data
                }
                let types = "products";
                let dataManuCut = {}
                dataManuCut.additional_info = {}
                dataManuCut.additional_info.key = 'manufacturer_cut_sheet';
                dataManuCut.additional_info.oldVal = setManufacturerSheet && setManufacturerSheet != "" ? setManufacturerSheet : "";
                dataManuCut.additional_info.newVal = data;
                this.props.update_detail(types, prodId, dataManuCut, 'Manufacture Cut Sheet updated successfully')
            }
            else if (type === 'addproductDescription') {
                const ProductDescription = {
                    "Product Description": data
                }
                let dataPoductDescription = {}
                dataPoductDescription.additional_info = {}
                dataPoductDescription.additional_info.key = 'Product Description';
                dataPoductDescription.additional_info.oldVal = setproductDescription && setproductDescription != "" ? setproductDescription : "";
                dataPoductDescription.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataPoductDescription, 'Product Description added successfully')
            } else if (type === 'editproductDescription') {
                const ProductDescription = {
                    "product_description": data
                }
                let dataPoductDescription = {}
                dataPoductDescription.additional_info = {}
                dataPoductDescription.additional_info.key = 'Product Description';
                dataPoductDescription.additional_info.oldVal = setproductDescription && setproductDescription != "" ? setproductDescription : "";
                dataPoductDescription.additional_info.newVal = data;

                let types = "products";
                this.props.update_detail(types, prodId, dataPoductDescription, 'Product Description updated successfully')
            }
            else if (type === 'addproductDetails') {
                const ProductDetails = {
                    "Product Details": data
                }
                let dataProductDetails = {}
                dataProductDetails.additional_info = {}
                dataProductDetails.additional_info.key = 'Product Details';
                dataProductDetails.additional_info.oldVal = setproductDetails && setproductDetails != "" ? setproductDetails : "";
                dataProductDetails.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataProductDetails, 'Product Details updated successfully')
            } else if (type === 'editproductDetails') {
                const ProductDetails = {
                    "Product_Details": data
                }
                let dataProductDetails = {}
                dataProductDetails.additional_info = {}
                dataProductDetails.additional_info.key = 'Product Details';
                dataProductDetails.additional_info.oldVal = setproductDetails && setproductDetails != "" ? setproductDetails : "";
                dataProductDetails.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataProductDetails, 'Product Details updated successfully')
            }
            else if (type === 'addPriceMax') {
                const PriceMax = {
                    "price_max": data
                }
                let dataPriceMax = {}
                dataPriceMax.additional_info = {}
                dataPriceMax.additional_info.key = 'price_max';
                dataPriceMax.additional_info.oldVal = setPriceMax && setPriceMax != "" ? setPriceMax : "";
                dataPriceMax.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataPriceMax, 'Max Price updated successfully')
            }
            else if (type === 'editPriceMax') {
                const ProductDetails = {
                    "price_max": data
                }
                let dataPriceMax = {}
                dataPriceMax.additional_info = {}
                dataPriceMax.additional_info.key = 'price_max';
                dataPriceMax.additional_info.oldVal = setPriceMax && setPriceMax != "" ? setPriceMax : "";
                dataPriceMax.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataPriceMax, 'Max Price updated successfully')
            }
            else if (type === 'addPriceMim') {
                const PriceMin = {
                    "price_min": data
                }
                let dataPriceMim = {}
                dataPriceMim.additional_info = {}
                dataPriceMim.additional_info.key = 'price_min';
                dataPriceMim.additional_info.oldVal = setPriceMim && setPriceMim != "" ? setPriceMim : "";
                dataPriceMim.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataPriceMim, 'Min Price updated successfully')
            }
            else if (type === 'editPriceMim') {
                const ProductDetails = {
                    "price_min": data
                }
                let dataPriceMim = {}
                dataPriceMim.additional_info = {}
                dataPriceMim.additional_info.key = 'price_min';
                dataPriceMim.additional_info.oldVal = setPriceMim && setPriceMim != "" ? setPriceMim : "";
                dataPriceMim.additional_info.newVal = data;
                let types = "products";
                this.props.update_detail(types, prodId, dataPriceMim, 'Min Price updated successfully')
            }

        }

    }

    handleChange = async (evt, data) => {
		
        if (data) {
            await this.setState({
                // ...this.state,
                [evt.target.name]: evt.target.value,
                [data]: evt.target.validity.valid
            });
        }
        else {
            await this.setState({
                [evt.target.name]: evt.target.value,
            });
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile} = this.props;
        if(my_profile && my_profile.id && authId && (authId == my_profile.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const { setbrandName, modelSet, assembledWidth,
            setManufacturerSheet, manufacturerWarranty,
            packedDepth, assembledWeight, listPrice,
            setproductDescription, setproductDetails,
            assembledDepth, assembledHeight, packedHeight,
            packedWeight, packedWidth, setPriceMax, setPriceMim, isToggleAddAnother,setmanufacturerName } = this.state
        const { productsList } = this.state;

        return (
            <>
                <div className="bg-white-shaded-md text-secondary mb-3">
                    <CollapseBasic title="Technical Details" containerClass="bg-transparent type-secondary" bodyClass="text-secondary-dark" isOpen={true}>
                        {/* Show If no data */}
                        <div hidden>
                            <span className="small"><i>{'No Info added so far.'}</i></span>
                        </div>

                        {/* Show If data */}
                        <div>
                            <p className="text-body ff-base lh-1-3"> Include all the specs, figures, and technical details here. Options will vary depending on the listing. You know what to do!</p>
                            <ul className="list-unstyled mb-2 fs-14">
                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className="fs-14 font-weight-bold">Brand Name:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.setbrandName == "" || this.state.setbrandName == null) ?
                                            this.state.addBrandName === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addBrandName: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="text"
                                                        bsSize="sm"
                                                        placeholder="Enter Brand Name"
                                                        name="AddNewBrandName"
                                                        value={this.state.AddNewBrandName}
                                                        onChange={(evt) => this.handleChange(evt, 'addNewBrandNameIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Brand Name' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.addNewBrandNameIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addBrandName: false
                                                                });
                                                                this.handleFieldsChange('addBrandName', this.state.AddNewBrandName)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm" onClick={() => {
                                                            this.setState({
                                                                addBrandName: false
                                                            })
                                                        }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editBrandName ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="text" placeholder="Enter Brand Name" value={this.state.brand} name='brand'
                                                                onChange={(evt) => this.handleChange(evt, 'brandIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter Brand Name' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.brandIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editBrandName: false
                                                                        })
                                                                        this.handleFieldsChange('editBrandName', this.state.brand)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editBrandName: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 font-weight-bold editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editBrandName: true,
                                                                    brand: setbrandName && setbrandName != "" ? setbrandName : ""
                                                                })
                                                            }}
                                                        >{setbrandName && setbrandName != "" ? setbrandName : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>


                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className=" font-weight-bold fs-14">Model Number:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.modelSet == "" || this.state.modelSet == null) ?
                                            this.state.addModel === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addModel: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="text"
                                                        bsSize="sm"
                                                        placeholder="Enter model number"
                                                        name="newModel"
                                                        value={this.state.newModel}
                                                        onChange={(evt) => this.handleChange(evt, 'newModelIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter model number' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.newModelIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addModel: false
                                                                });
                                                                this.handleFieldsChange('addModel', this.state.newModel)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm"
                                                            onClick={() => {
                                                                this.setState({
                                                                    addModel: false
                                                                })
                                                            }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editModel ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="text" placeholder="Enter model number" value={this.state.model} name='model'
                                                                onChange={(evt) => this.handleChange(evt, 'modelIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter model number' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.modelIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editModel: false
                                                                        })
                                                                        this.handleFieldsChange('editModel', this.state.model)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editModel: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 font-weight-bold editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editModel: true,
                                                                    model: modelSet && modelSet != "" ? modelSet : ""
                                                                })
                                                            }}
                                                        >{modelSet && modelSet != "" ? modelSet : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>
								<li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className="fs-14 font-weight-bold">Manufacturer:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.setmanufacturerName == "" || this.state.setmanufacturerName == null) ?
                                            this.state.addManufacturerName === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addManufacturerName: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="text"
                                                        bsSize="sm"
                                                        placeholder="Enter Manufacturer"
                                                        name="AddNewManufacturerName"
                                                        value={this.state.AddNewManufacturerName}
                                                        onChange={(evt) => this.handleChange(evt, 'addNewManufacturerNameIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Manufacturer' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.addNewManufacturerNameIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addManufacturerName: false
                                                                });
                                                                this.handleFieldsChange('addManufacturerName', this.state.AddNewManufacturerName)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm" onClick={() => {
                                                            this.setState({
                                                                addManufacturerName: false
                                                            })
                                                        }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editManufacturerName ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="text" placeholder="Enter Manufacturer" value={this.state.manufacturer} name='manufacturer'
                                                                onChange={(evt) => this.handleChange(evt, 'manufacturerIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter Manufacturer' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.manufacturerIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editManufacturerName: false
                                                                        })
                                                                        this.handleFieldsChange('editManufacturerName', this.state.manufacturer)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editManufacturerName: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 font-weight-bold editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editManufacturerName: true,
                                                                    manufacturer: setmanufacturerName && setmanufacturerName != "" ? setmanufacturerName : ""
                                                                })
                                                            }}
                                                        >{setmanufacturerName && setmanufacturerName != "" ? setmanufacturerName : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className="fs-14 font-weight-bold">Manufacturer cut sheet:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.setManufacturerSheet == "" || this.state.setManufacturerSheet == null) ?
                                            this.state.addManufacturerSheet === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addManufacturerSheet: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="text"
                                                        bsSize="sm"
                                                        placeholder="Enter Manufacturer cut sheet"
                                                        name="AddNewManufacturerSheet"
                                                        value={this.state.AddNewManufacturerSheet}
                                                        onChange={(evt) => this.handleChange(evt, 'AddNewManufacturerSheetIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Manufacturer cut sheet' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.AddNewManufacturerSheetIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addManufacturerSheet: false
                                                                });
                                                                this.handleFieldsChange('addManufacturerSheet', this.state.AddNewManufacturerSheet)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm"
                                                            onClick={() => {
                                                                this.setState({
                                                                    addManufacturerSheet: false
                                                                })
                                                            }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editManufacturerSheet ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="text" placeholder="Enter product Description" value={this.state.manufacturerSheet} name='manufacturerSheet'
                                                                onChange={(evt) => this.handleChange(evt, 'manufacturerSheetIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter Manufacturer cut sheet' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.manufacturerSheetIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editManufacturerSheet: false
                                                                        })
                                                                        this.handleFieldsChange('editManufacturerSheet', this.state.manufacturerSheet)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editManufacturerSheet: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editManufacturerSheet: true,
                                                                    manufacturerSheet: setManufacturerSheet && setManufacturerSheet != "" ? setManufacturerSheet : ""
                                                                })
                                                            }}
                                                        >{setManufacturerSheet && setManufacturerSheet != "" ? setManufacturerSheet : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>

                                {
                                    manufacturerWarranty ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>Manufacturer warranty:</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{manufacturerWarranty}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''

                                }
                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className="fs-14 font-weight-bold">Price Max:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.setPriceMax == "" || this.state.setPriceMax == null) ?
                                            this.state.addPriceMax === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addPriceMax: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="text"
                                                        bsSize="sm"
                                                        placeholder="Enter Price Max"
                                                        name="newPriceMax"
                                                        value={this.state.newPriceMax}
                                                        onChange={(evt) => this.handleChange(evt, 'newPriceMaxIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Price Max' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.newPriceMaxIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addPriceMax: false
                                                                });
                                                                this.handleFieldsChange('addPriceMax', this.state.newPriceMax)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm"
                                                            onClick={() => {
                                                                this.setState({
                                                                    addPriceMax: false
                                                                })
                                                            }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editPriceMax ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="text" placeholder="Enter price" value={this.state.priceMax} name='priceMax'
                                                                onChange={(evt) => this.handleChange(evt, 'priceMaxIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter Price Max' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.priceMaxIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editPriceMax: false
                                                                        })
                                                                        this.handleFieldsChange('editPriceMax', this.state.priceMax)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editPriceMax: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 font-weight-bold editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editPriceMax: true,
                                                                    priceMax: setPriceMax && setPriceMax != "" ? setPriceMax : ""
                                                                })
                                                            }}
                                                        >{setPriceMax && setPriceMax != "" ? setPriceMax : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>

                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className="fs-14 font-weight-bold">Price Min:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.setPriceMim == "" || this.state.setPriceMim == null) ?
                                            this.state.addPriceMim === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addPriceMim: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="text"
                                                        bsSize="sm"
                                                        placeholder="Enter Price Min"
                                                        name="addnewPriceMim"
                                                        value={this.state.addnewPriceMim}
                                                        onChange={(evt) => this.handleChange(evt, 'addnewPriceMimIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Price Min' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.addnewPriceMimIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addPriceMim: false
                                                                });
                                                                this.handleFieldsChange('addPriceMim', this.state.addnewPriceMim)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm"
                                                            onClick={() => {
                                                                this.setState({
                                                                    addPriceMim: false
                                                                })
                                                            }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editPriceMim ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="text" placeholder="Enter price" value={this.state.priceMim} name='priceMim'
                                                                onChange={(evt) => this.handleChange(evt, 'priceMimIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter Price Min' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.priceMimIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editPriceMim: false
                                                                        })
                                                                        this.handleFieldsChange('editPriceMim', this.state.priceMim)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editPriceMim: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 font-weight-bold editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editPriceMim: true,
                                                                    priceMim: setPriceMim && setPriceMim != "" ? setPriceMim : ""
                                                                })
                                                            }}
                                                        >{setPriceMim && setPriceMim != "" ? setPriceMim : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className="fs-14 font-weight-bold">Product Description:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.setproductDescription == "" || this.state.setproductDescription == null) ?
                                            this.state.addproductDescription === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addproductDescription: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="textarea"
                                                        bsSize="sm"
                                                        placeholder="Enter Product Description"
                                                        name="AddNewproductDescription"
                                                        value={this.state.AddNewproductDescription}
                                                        onChange={(evt) => this.handleChange(evt, 'AddNewproductDescriptionIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Product Description' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.AddNewproductDescriptionIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addproductDescription: false
                                                                });
                                                                this.handleFieldsChange('addproductDescription', this.state.AddNewproductDescription)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm"
                                                            onClick={() => {
                                                                this.setState({
                                                                    addproductDescription: false
                                                                })
                                                            }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editproductDescription ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="textarea" placeholder="Enter product Description" value={this.state.productDescription} name='productDescription'
                                                                onChange={(evt) => this.handleChange(evt, 'productDescriptionIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter product Description' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.productDescriptionIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editproductDescription: false
                                                                        })
                                                                        this.handleFieldsChange('editproductDescription', this.state.productDescription)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editproductDescription: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 wb-break-word editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editproductDescription: true,
                                                                    productDescription: setproductDescription && setproductDescription != "" ? setproductDescription : ""
                                                                })
                                                            }}
                                                        >{setproductDescription && setproductDescription != "" ? setproductDescription : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                    <div className="px-2 flex-120">
                                        <span className="fs-14 font-weight-bold">Product Details:</span>
                                    </div>
                                    <div className="col px-2">

                                        {(this.state.setproductDetails == "" || this.state.setproductDetails == null) ?
                                            this.state.addproductDetails === false ?
                                                <span className="fs-14 font-weight-bold editable-column"
                                                    onClick={() => {
                                                        if(!this.checkIfLoggedIn()){
                                                            this.props.forceToggleLoginModal();
                                                            return false;
                                                        }
                                                        this.setState({
                                                            addproductDetails: true
                                                        })
                                                    }}
                                                >Click to add</span>
                                                :
                                                <AvForm>
                                                    <AvField
                                                        type="textarea"
                                                        bsSize="sm"
                                                        placeholder="Enter product Details"
                                                        name="AddNewproductDetails"
                                                        value={this.state.AddNewproductDetails}
                                                        onChange={(evt) => this.handleChange(evt, 'AddNewproductDetailsIsValid')}
                                                        validate={{
                                                            required: { value: true, errorMessage: 'Please enter Product Details' }
                                                        }}
                                                    />
                                                    <div className="text-right">
                                                        <Button color="primary" size="sm"
                                                            disabled={this.state.AddNewproductDetailsIsValid ? false : true}
                                                            onClick={() => {
                                                                this.setState({
                                                                    addproductDetails: false
                                                                });
                                                                this.handleFieldsChange('addproductDetails', this.state.AddNewproductDetails)
                                                            }}>Update</Button>
                                                        <Button color="primary" size="sm"
                                                            onClick={() => {
                                                                this.setState({
                                                                    addproductDetails: false
                                                                })
                                                            }}>Cancel</Button>
                                                    </div>
                                                </AvForm>
                                            :
                                            <div >
                                                {
                                                    this.state.editproductDetails ?
                                                        <AvForm>
                                                            <AvField bsSize="sm" type="textarea" placeholder="Enter product Details" value={this.state.productDetails} name='productDetails'
                                                                onChange={(evt) => this.handleChange(evt, 'productDetailsIsValid')}
                                                                validate={{
                                                                    required: { value: true, errorMessage: 'Please enter product Details' }
                                                                }}
                                                            />
                                                            <div className="text-right">
                                                                <Button color="primary" size="sm"
                                                                    disabled={this.state.productDetailsIsValid ? false : true}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editproductDetails: false
                                                                        })
                                                                        this.handleFieldsChange('editproductDetails', this.state.productDetails)
                                                                    }}>Update</Button>
                                                                <Button color="primary" size="sm"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editproductDetails: false
                                                                        })
                                                                    }}>Cancel</Button>
                                                            </div>
                                                        </AvForm>
                                                        :
                                                        <span className="fs-14 editable-column"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editproductDetails: true,
                                                                    productDetails: setproductDetails && setproductDetails != "" ? setproductDetails : ""
                                                                })
                                                            }}
                                                        >{setproductDetails && setproductDetails != "" ? setproductDetails : ""}
                                                        </span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </li>

                                {
                                    listPrice ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>List Price</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{listPrice}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''
                                }
                                {
                                    assembledDepth ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>Assembled Depth</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{assembledDepth}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''
                                }
                                {
                                    assembledHeight ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>Assembled Height</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{assembledHeight}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''
                                }
                                {
                                    assembledWeight ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>Assembled Weight</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{assembledWeight}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''
                                }
                                {
                                    assembledWidth ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>Assembled width</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{assembledWidth}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''
                                }
                                {
                                    packedDepth ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>Packed Depth</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{packedDepth}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''
                                }
                                {
                                    packedHeight ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>Packed Height</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{packedHeight}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''
                                }
                                {
                                    packedWeight ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>Packed Weight</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{packedWeight}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''
                                }
                                {
                                    packedWidth ?
                                        <li>
                                            <div className="d-flex flex-nowrap">
                                                <span className="font-weight-bold mb-1 mr-1" style={{ width: '140px' }}>Packed width</span>

                                                <span className="col px-0">
                                                    <div>
                                                        <div className="mb-2">
                                                            {/* If added */}
                                                            <div className="mr-1 fs-14">
                                                                <span className="mr-1 font-weight-normal text-secondary-dark">{packedWidth}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </li> : ''
                                }
                            </ul>
                        </div>

                        <div className="text-right mb-2">
                            <Button color="link"
                                onClick={() => { 
                                    if(!this.checkIfLoggedIn()){
                                        this.props.forceToggleLoginModal();
                                        return false;
                                    }
                                    this.setState({ isToggleAddAnother: !this.state.isToggleAddAnother }) }}
                                className="p-0 text-secondary-dark font-weight-bold">
                                <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                                {' Add another'}
                            </Button>
                        </div>

                        {/* Show when Adding */}
                        {isToggleAddAnother ? (
                            <div className="mb-4 py-2 shadow-sm bg-white px-2">
                                <h5 className="text-secondary-dark mb-3 fs-30">Add another</h5>
                                <div className="mb-2">
                                    <Input bsSize="sm" type="select" name="selectedCategory">
                                        <option value="">Please Select</option>
                                        <option value="1">Cost</option>
                                        <option value="1">AV</option>
                                        <option value="1">Recorder and Players</option>
                                        <option value="1">DTV Converter</option>
                                    </Input>

                                    <div className="my-2 font-weight-bold" role="button">
                                        is multiple choice attribute
                                                    </div>
                                    <Input className="mt-2" bsSize="sm" type="text" name="" />

                                    {/* <div>
                                                    <Label size="sm" className="p-0 mt-2  mb-1">Choose Multiple</Label>
                                                    <div className="mb-2">
                                                        <CustomInput type="checkbox" id="samsung" className="dark fs-14" label="Samsung" />
                                                    </div>
                                                    <div className="mb-2">
                                                        <CustomInput type="checkbox" id="sony" className="dark fs-14" label="Sony" />
                                                    </div>
                                                    <div className="mb-2">
                                                        <CustomInput type="checkbox" id="phillips" className="dark fs-14" label="Phillips" />
                                                    </div>
                                                    <div className="mb-2">
                                                        <CustomInput type="checkbox" id="other" className="dark fs-14" label="Other" />
                                                    </div>
                                                </div> */}
                                    <div>
                                        <Label size="sm" className="p-0 mt-2  mb-1">Choose Multiple</Label>
                                        {
                                            productsList.map((index, i) =>
                                                <div key={i} className="mb-2">
                                                    <CustomInput type="checkbox" value={index.name} checked={index?.isChecked ? true : false} onChange={this.onAddingItem} id={"other" + index.name} className="dark fs-14" label={index.name} />
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>
                                <div className="text-right">
                                    <Button size="sm" color="primary mr-2">
                                        Submit
                                                </Button>
                                    <Button size="sm" color="primary ml-0"
                                        onClick={() => { this.setState({ isToggleAddAnother: !this.state.isToggleAddAnother }) }}
                                    >

                                        Cancel
                                                </Button>
                                </div>
                            </div>
                        ) : null
                        }

                    </CollapseBasic>
                </div>

            </>
        )
    }
}

const mapState = (state) => ({
    my_profile: state.user.my_profile,
});

const mapProps = (dispatch) => ({
    update_detail: (type, id, data, message) => dispatch(update_detail(type, id, data, message)),
});


export default connect(mapState, mapProps)(TechnicalDetails);

// export default  TechnicalDetails;
