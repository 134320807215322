import { callApi, responseCodeValidator } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { setUploadPercentage } from "./shared";

export const FETCH_MY_PHOTOS = "FETCH_MY_PHOTOS";
export const FETCH_MY_VIDEOS = "FETCH_MY_VIDEOS";
export const FETCH_TAXONOMY_CATEGORY = "FETCH_TAXONOMY_CATEGORY";
export const MEDIA_UPLOAD_SCROLL = "MEDIA_UPLOAD_SCROLL";
export const FETCH_DELETE_DATA = "FETCH_DELETE_DATA";
export const UPLOAD_MEDIA_DATA = "UPLOAD_MEDIA_DATA";
export const CLEAR_MEDIA_DATA = "CLEAR_MEDIA_DATA";
export const DELETE_MULTIPLE_MEDIA = "DELETE_MULTIPLE_MEDIA";
export const PEOPLE_YOU_KNOW = "PEOPLE_YOU_KNOW";
export const FETCH_TAXONOMY_LISTING = "FETCH_TAXONOMY_LISTING";
export const UPLOAD_PROFILE_DATA = "UPLOAD_PROFILE_DATA";



export const my_photos_dispatcher = (data) => ({ type: FETCH_MY_PHOTOS, payload: data });
export const my_videos_dispatcher = (data) => ({ type: FETCH_MY_VIDEOS, payload: data });
export const fetch_media_data = (type, album, page, user_profile, force_true, user_name) => {
    let url = `/upload/list/${type}/?album=${album}&page=${page}&user_profile=${user_profile}`
     if(user_name)
        url = `/upload/list/${type}/?album=${album}&page=${page}&user_profile=${user_profile}&user_name=${user_name}`
    return (dispatch) => {
        callApi(url, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    response['album'] = album ? true : false;
                    response['page'] = page;
                    if (force_true) {
                        response['album'] = true
                    }

                    if (type == 'image') {
                        return dispatch(my_photos_dispatcher(response));
                    } else {
                        return dispatch(my_videos_dispatcher(response));
                    }
                }
            })
    }
}

export const fetch_taxonomy_dispatcher = (data) => ({ type: FETCH_TAXONOMY_CATEGORY, payload: data })
export const fetch_taxonomy_category = (type) => {
    let url = null;

    if(!type){
        url = `/api/taxonomy/?category=Album&depth=true&delsel=&ancestor=&sub_cat=&parent=`;
    }

    if(type == "movies"){
        url = `/api/taxonomy/?category=movies&parent=images`;
    }

    return (dispatch) => {
        callApi(url, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(fetch_taxonomy_dispatcher(response));
                }
            })
    }
}

export const media_upload_dispatch = (data) => ({ type: MEDIA_UPLOAD_SCROLL, payload: data })
export const media_upload_scroll = ({ type, album, page, force_update }) => {
    return (dispatch) => {
        callApi(`/upload/list/${type}/?album=${album}&page=${page}`, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    response['type'] = type;
                    response['page'] = page;
                    response['ablum'] = album ? true : false;
                    if (force_update) { album = true };
                    return dispatch(media_upload_dispatch(response));
                }
            })
    }
}


export const delete_media_dispatch = (data) => ({ type: FETCH_DELETE_DATA, payload: data })
export const fetch_delete_media = ({ type, page, isDelete }) => {
    let url;
    if (isDelete) {
        url = `/upload/list/${type}/?album=&page=${page}`
    } else {
        url = `/upload/list/${type}/?album=&delete_userprofile_${type}s=True&page=${page}`
    }
    return (dispatch) => {
        callApi(url, 'GET')
            .then((response) => {
                response['page'] = page;
                response['type'] = type == 'image' ? true : false;
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(delete_media_dispatch(response));
                }
            })
    }
}

export const clear_delete_media_dispatch = () => ((dispatch) => dispatch({ type: FETCH_DELETE_DATA, payload: {} }))

export const clear_media_dispatch = () => ((dispatch) => dispatch({ type: CLEAR_MEDIA_DATA, payload: {} }))

export const upload_media_dispatch = (data) => ({ type: UPLOAD_MEDIA_DATA, payload: data })
export const upload_media_files = (type, data, index) => {
    return (dispatch) => {
        callApi(`/upload/multiuploader/?album=&instance=self${type}&image=undefined&doc=undefined`, 'POST', data, true)
            .then((response) => {
                if (response && responseCodeValidator(String(response.code))) {
					response['index'] = index;
                    response['isUpload'] = true;
                    return dispatch(upload_media_dispatch(response));
                } else {
                    const invalidResponse = {};
					invalidResponse['index'] = index;
					invalidResponse['isUpload'] = false;
                    return dispatch(upload_media_dispatch(invalidResponse));
                }
            })
    }
}

export const upload_media_filesData = (url, type, data, index) => {
    return (dispatch) => {
        dispatch(setUploadPercentage(0))
        callApi(`${url}`, 'POST', data, true,"","",dispatch)
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
					response['index'] = index;
                    response['isUpload'] = true;
                    
                    return dispatch(upload_media_dispatch(response));
                } else {
                    response['isUpload'] = false;
                }
            })
    }
}

export const upload_add_captions = (id, data) => {
    return (dispatch) => {
        callApi(`/upload/${id}/`, 'PUT', data)
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    // toast(`caption added successfully!`, {
                    //     autoClose: 2500,
                    //     className: "black-background",
                    //     bodyClassName: "red-hunt",
                    //     progressClassName: "cc",
                    // });
                }
            })
    }
}

export const single_delete_from_upload = (id, type) => {
    return (dispatch) => {
        callApi(`/upload/multiuploader_delete/${id}/`, 'POST')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    toast(`${type || "image"} deleted successfully!`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                }
            })
    }
}

export const delete_mutiple_media_dispatch = (data) => ({ type: DELETE_MULTIPLE_MEDIA, payload: data })
export const delete_mutiple_media = ({ type, data }) => {
    return (dispatch) => {
        callApi(`/upload/multiuploader_delete_multiple/?delete_${type}s=True`, 'POST', data)
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    toast(`${type === 'image' ? 'Photos' : 'Videos'} deleted successfully!`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                    dispatch(fetch_media_data(type, '', 1, true, true));
                }
            })
    }
}

export const get_people_you_know_ = (data) => {
    return {
        type: PEOPLE_YOU_KNOW,
        payload: data,
    };
};


export const get_people_you_know = (page = 1) => {
    return (dispatch) => {
        callApi(`/api/peopleyoumayknow/?page=${page}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_people_you_know_(response));
            }
        });
    };
};

export const send_friend_request = (data) => {
    return (dispatch) => {
        callApi(`/api/connectionrequest/`, 'POST', data)
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    toast(`Your friend request has been sent`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                        toastId: 'Sent Friend Request'
                    });       
                }
            })
    }
}

export const fetch_taxonomy_list_ = (data) => ({ type: FETCH_TAXONOMY_LISTING, payload: data })
export const fetch_taxonomy_list = (category, types) => {
    return (dispatch) => {
        callApi(`/api/taxonomy/?category=${category}&parent=${types}`, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    if (response[0]) {
                        response[0]['isTypes'] = types;
                    }
                    return dispatch(fetch_taxonomy_list_(response));
                }
            })
    }
}
 
export const upload_profile_dispatch = (data) => ({ type: UPLOAD_PROFILE_DATA, payload: data })
export const upload_profile_files = (type, data, index) => {
    return (dispatch) => {
        callApi(`/upload/multiuploader/?album=profile&instance=myProfile_image&image=undefined&doc=undefined`, 'POST', data, true, "", "", dispatch)
            .then((response) => {
                response['index'] = index;
                if (response && response.code !== 400 && response.code !== 500) {
                    response['isUpload'] = true;
                    return dispatch(upload_profile_dispatch(response));
                } else {
                    response['isUpload'] = false;
                }
            })
    }
}

export const add_media_to_gallery_profile = (data) => {
    return (dispatch) => {
        callApi(`/api/improve/?instance=selfvideo`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
				let message = "Media uploaded successfully."
				if(response.message){
					message = response.message
				}
                toast(`${message}`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc"
               });
               return dispatch(fetch_media_data('video', '', 1, true, true));
             }
        });
    };
};

