const initialState = {
  my_profile: null,
  search_theater: [],
  move_set: null,
  my_posts_list: null,
  my_privacy_setting: null,
  album_types_list: null,
  album_type_data: null,
  search_people_data: [],
  search_people_data_edit: [],
  search_people_data_loading: false,
  get_reveiewData: null,
  people_list: [],
  get_tw_data: null,
  search_username: [],
  my_profile_error: null,
  people_profile: null,
  logged_user_profile: null,
  my_privacy_setting_status: null,
  tags_list: [],
  settingEnable:false,
  auth_phone_number: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_INITIAL_AUTH_NEW':
      return initialState;

    case 'VERIFY_PHONE_NUMBER':
      state.auth_phone_number = action.payload;
      return { ...state }

    case 'RESET_VERIFY_PHONE_NUMBER':
      state.auth_phone_number = action.payload;
      return { ...state }
      
    case "MY_PROFILE":
      state.my_profile = action.payload;
      return { ...state };

    case "SEARCH_THEATER":
      state.search_theater = action.payload;
      return { ...state };

    case "GET_MOVE_SET":
      state.move_set = action.payload;
      return { ...state };

    case "GET_MY_POSTS_LIST":
      state.my_posts_list = action.payload;
      return { ...state };

    case "RESET_MY_POSTS_LIST":
      state.my_posts_list = null;
      return { ...state };

    case "GET_ALBUM_TYPES_LIST":
      state.album_types_list = action.payload;
      return { ...state };

    case "GET_ALBUM_TYPE_DATA":
      state.album_type_data = action.payload;
      return { ...state };

    case "GET_SEARCH_PEOPLE_LOADING":
      state.search_people_data_loading = true;
      return { ...state };

    case "GET_SEARCH_PEOPLE":
      state.search_people_data = action.payload;
      return { ...state };

    case "GET_SEARCH_PEOPLE_EDIT":
      state.search_people_data_edit = action.payload;
      return { ...state };

    case "GET_REVIEWGROUP":
      state.get_reveiewData = action.payload;
      return { ...state };

    case "GET_SEARCH_PEOPLE_ERROR":
      state.search_people_data_loading = false;
      return { ...state };

    case "GET_PEOPLE_LIST":
      return { ...state, people_list: action.payload };

    case "GET_TIPS_WARNING_DATA":
      state.get_tw_data = action.payload;
      return { ...state };

    case "SEARCH_USERNAME":
      state.search_username = action.payload;
      return { ...state };

    case "UPDATE_MY_PROFILE_ERROR":
      if (state.my_profile_error === null) {
        state.my_profile_error = action.payload
      } else {
        state.my_profile_error = { ...state.my_profile_error, ...action.payload };
      }
      return { ...state };

    case "PEOPLE_PROFILE":
      state.people_profile = action.payload;
      return { ...state };
    case "LOGGED_USER_PROFILE":
      state.logged_user_profile = action.payload;
      return { ...state };

    case "GET_MY_PRIVACY_SETTING":
      state.my_privacy_setting = action.payload;
      state.my_privacy_setting['random'] = Math.random();
      return { ...state };

    case "GET_MY_PRIVACY_SETTING_STATUS":
      state.my_privacy_setting_status = action.payload;
      return { ...state };
    case "GET_TAG_LIST":
      state.tags_list = action.payload;
      return { ...state };
    case "SET_SETTINGS_ENABLE":
      state.settingEnable = action.payload;
      return { ...state };
      
    default:
      return state;
  }
};

export default user;
