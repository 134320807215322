import React, { Component } from 'react'
import {
    Collapse, Nav, NavItem, NavLink, TabContent, TabPane,
    FormGroup, Button, Input, Label, Form,
} from 'reactstrap';
import CollapseBasic from './collapse';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditBtn from './editBtn';
import DeleteBtn from './deleteBtn'
import { callApi } from "../../utils/apiCaller";
import moment from 'moment';
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
export default class Background extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpenEducation: false,
            isOpenCertification: false,
            isOpenAwards: false,
            isSearching: false,
            backgroundType: 'education',
            businessListing: {},
            searchListing: {},
            educationItems: {
                selectedUniversity: '',
                degreeText: '',
                fromDate: '',
                toDate: '',
                isPresent: false,
                isEdit: false
            },
            certificationItems: {
                selectedUniversity: '',
                degreeText: '',
                fromDate: '',
                toDate: '',
                isPresent: false,
                isEdit: false
            },
            awardItems: {
                selectedUniversity: '',
                titleText: '',
                fromDate: '',
                isEdit: false
            },
            userEntryId: '',
			categorType:"",
			universityError:"",
			degreeError:"",
			startError:"",
			endError:""
        }
    }

    componentDidMount() {
        this.fetchItems();
    }

    fetchItems = async () => {
        let { backgroundType, businessListing } = this.state;
        const { id,categoryType } = this.props;
        try {
            let url = `/api/professionalworkhistory/?&professionaluser_entry__id=${id}&taxonomy__category=${backgroundType}`
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {
                this.resetStates();
                businessListing[backgroundType] = response?.results.slice(0, 3);
                this.setState({ businessListing,categorType:categoryType });
            }
        } catch (err) {
            console.log({ err });
        }
    }

    textOnSearch = async () => {
        let { searchText } = this.state;
        if (searchText?.length) {
            try {
                let url = `/api/search/?content_type=business&location=false&page=1&q=${searchText}&sensor=false&size=5`;
                let response = await callApi(url, 'GET');
                if (response && response?.code !== 500) {
                    this.setState({ searchListing: response });
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    handleSubmitCert = async (isUpdate) => {
        let { certificationItems: { selectedUniversity, degreeText, fromDate, toDate, isPresent },startError , endError } = this.state;
        const { id } = this.props;
        let splitFrom = fromDate.split('-');
        let splitTo = toDate.split('-');

        let data = {
            "is_present": isPresent,
            "user_entry": selectedUniversity?._source?.name,
            "title": degreeText,
            "from_date": fromDate,
            "to_date": toDate,
            "search_id": selectedUniversity?._id,
            "from_month": splitFrom[1], // month
            "from_year": splitFrom[0], // year
            "professionaluser_entry_id": id,
            "company": selectedUniversity?._source?.name,
            "category": "certification",
            "to_month": splitTo[1], // month
            "to_year": splitTo[0]  // year
        };

        if (degreeText == "") {
            this.setState({
                degreeError:
                    "This field is required",
            });
        }

        if (fromDate == "") {
            startError = "This field is required"
        }
        if (!isPresent && toDate == "") {
            endError = "This field is required"
        }

        if (Object.keys(selectedUniversity).length == 0) {
            this.setState({
                universityError:
                    "This field is required",
            });
        }
        if (isPresent) {
            data['to_date'] = '';
            delete data['to_month'];
            delete data['to_year'];
        }

        if (!this.state.certificationItems.isPresent && this.state.certificationItems.toDate === '') {
            endError = 'This field is required';
        } else if (this.state.certificationItems.fromDate !== '' && ( this.state.certificationItems.toDate !== '' || !this.state.certificationItems.toDate )) {
            const date = new Date()
            const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit' })
            const [{ value: month }, , { value: year }] = dateTimeFormat.formatToParts(date);
            const newDate = `${year}-${month}`;

            if (this.state.certificationItems.fromDate > newDate) {
                startError = 'Start date must be less than your current date';
            } else if (this.state.certificationItems.toDate > newDate && !this.state.certificationItems.isPresent) {
                endError = 'End date must be less than your current date';
            } else if (this.state.certificationItems.fromDate >= this.state.certificationItems.toDate && !this.state.educationItems.isPresent)
                endError = 'End date should be greater than your start date.';
            else {
                endError = "";
                startError = ""
            }
        } else {
            endError = "";
        }

        if(this.state.certificationItems.isPresent)
           endError = ""
        this.setState({startError: startError, endError: endError})


        if (id && degreeText != "" && fromDate != "" && Object.keys(selectedUniversity).length > 0 && startError == "" && endError == "")
            try {
                let response = await callApi(`/api/professionalworkhistory/`, 'POST', data);
                if (response && response?.code !== 500) {

                    let message = response?.message;
                    if (message != "undefined" || message != undefined) {
                        toast(`${message}`, {
                            autoClose: 3500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        });
                    }
                    this.resetStates(true);
                }
            } catch (err) {

            }
    }

    handleSubmitEdu = async (isUpdate) => {
        let { educationItems: { selectedUniversity, degreeText, fromDate, toDate, isPresent },endError, startError } = this.state;
        const { id } = this.props;
        let splitFrom = fromDate.split('-');
        let splitTo = toDate.split('-');

        let data = {
            "is_present": isPresent,
            "user_entry": selectedUniversity?._source?.name,
            "title": degreeText,
            "from_date": fromDate,
            "to_date": toDate,
            "search_id": selectedUniversity?._id,
            "from_month": splitFrom[1], // month
            "from_year": splitFrom[0], // year
            "professionaluser_entry_id": id,
            "company": selectedUniversity?._source?.name,
            "category": "education",
            "to_month": splitTo[1], // month
            "to_year": splitTo[0]  // year
        };

        if (degreeText == "") {
            this.setState({
                degreeError:
                    "This field is required",
            });
        }

        if (fromDate == "") {
                startError = "This field is required"
        }
        if (!isPresent && toDate == "") {
                endError= "This field is required"
        }


        if (Object.keys(selectedUniversity).length == 0) {
            this.setState({
                universityError:
                    "This field is required",
            });
        }

        if (isPresent) {
            data['to_date'] = '';
            delete data['to_month'];
            delete data['to_year'];
        }
        
        if (!this.state.educationItems.isPresent && this.state.educationItems.toDate === '') {
            endError = 'This field is required';
        
        } else if ( this.state.educationItems.fromDate !== '' && ( this.state.educationItems.toDate !== '' || !this.state.educationItems.toDate)) {
        
            const date = new Date()
            const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit' })
            const [{ value: month }, , { value: year }] = dateTimeFormat.formatToParts(date);
            const newDate = `${year}-${month}`;

            if (this.state.educationItems.fromDate > newDate) {
                startError = 'Start date must be less than your current date';
            } else if (this.state.educationItems.toDate > newDate && !this.state.educationItems.isPresent) {
                endError = 'End date must be less than your current date';
            } else if (this.state.educationItems.fromDate >= this.state.educationItems.toDate && !this.state.educationItems.isPresent)
                endError = 'End date should be greater than your start date.';
            else {
                endError = "";
                startError = ""
            }
        } else {
            endError = "";
        }

        if(this.state.educationItems.isPresent)
           endError = ""
        this.setState({startError: startError, endError: endError})

        if (id && degreeText != "" && fromDate != "" && Object.keys(selectedUniversity).length > 0 && startError == "" && endError == "") {
            try {
                let response = await callApi(`/api/professionalworkhistory/`, 'POST', data);
                if (response && response?.code !== 500) {

                    let message = response?.message;
                    if (message != "undefined" || message != undefined) {
                        toast(`${message}`, {
                            autoClose: 3500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        });
                    }
                    this.resetStates(true);
                }
            } catch (err) {

            }
        }
    }

    handleSubmitAwards = async () => {
        let { awardItems: { selectedUniversity, titleText, fromDate } } = this.state;
        const { id } = this.props;
        let splitFrom = fromDate.split('-');

        let data = {
            // "user_entry_id": "",
            "from_date": fromDate,
            "user_entry": selectedUniversity?._source?.name,
            "title": titleText,
            "search_id": selectedUniversity?._id,
            "from_month": splitFrom[1],
            "from_year": splitFrom[0],
            "to_month": splitFrom[1],
            "to_year": splitFrom[0],
            "professionaluser_entry_id": id,
            "company": selectedUniversity?._source?.name,
            "category": "award"
        }

        let url = `/api/professionalworkhistory/`;
        try {
            let response = await callApi(url, 'POST', data);
            if (response && response?.code !== 500) {
                let message = response?.message;
                if (message != "undefined" || message != undefined) {
                    toast(`${message}`, {
                        autoClose: 3500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                }
                
                this.resetStates(true)
            }
        } catch (err) {

        }
    }


    updateAward = async () => {
        let { awardItems: { selectedUniversity, titleText, fromDate, isEdit }, userEntryId } = this.state;
        const { id } = this.props;
        let splitFrom = fromDate.split('-');

        let data = {
            "user_entry_id": "",
            "from_date": fromDate,
            "user_entry": selectedUniversity?._source?.name,
            "title": titleText,
            "search_id": selectedUniversity?._id == isEdit?.id ? userEntryId : selectedUniversity?._id,
            "from_month": splitFrom[1],
            "from_year": splitFrom[0],
            "to_month": splitFrom[1],
            "to_year": splitFrom[0],
            "professionaluser_entry_id": id,
            "company": selectedUniversity?._source?.name,
            "category": "award"
        }

        let url = `/api/professionalworkhistory/${isEdit?.id}/`;
        try {
            let response = await callApi(url, 'PUT', data);
            if (response && response?.code !== 500) {
                
                let message = response?.message;
                if (message != "undefined" || message != undefined) {
                    toast(`${message}`, {
                        autoClose: 3500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                }
                this.resetStates(true)
            }
        } catch (err) {

        }
    }


    fetchEditEduForm = async ({ isEdu, id }) => {
        let lable_type = 'professional_education';
        if (!isEdu) {
            lable_type = 'professional_award'
        }
        try {
            let url = `/form/${lable_type}/${id}`
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {
                const doc = new DOMParser().parseFromString(response?.data, "text/html");
                if (doc) {
                    let elements = doc.querySelectorAll('input[type="hidden"]');
                    if (elements?.length) {
                        elements.forEach(el => {
                            if (el?.type == "hidden") {
                                let elements_items = el.attributes['1'].nodeValue;
                                if (elements_items.includes('user_entry_id')) {
                                    let filteredElements = elements_items.split('=');
                                    if (filteredElements && Array.isArray(filteredElements) && filteredElements?.length) {
                                        this.setState({
                                            userEntryId: parseInt(filteredElements[1].replace(/['"]+/g, ''))
                                        })
                                    }
                                }
                            }
                        })
                    }
                }

            }
        } catch (err) {
            console.log({ err })
        }
    }

    updateCert = async () => {
        let { certificationItems: { selectedUniversity, degreeText, fromDate, toDate, isPresent, isEdit } } = this.state;
        const { id } = this.props;
        let splitFrom = fromDate.split('-');
        let splitTo = toDate.split('-');

        let data = {
            "category": "certification",
            "company": selectedUniversity?._source?.name,
            "from_date": fromDate,
            "from_month": splitFrom[1], // month
            "from_year": splitFrom[0], // year
            "is_present": isPresent,
            "professionaluser_entry_id": id,
            "search_id": selectedUniversity?._id,
            // "search_id": selectedUniversity?._id == isEdit?.id ? userEntryId : selectedUniversity?._id,
            "title": degreeText,
            "to_date": toDate,
            "to_month": splitTo[1], // month
            "to_year": splitTo[0],  // year
            "user_entry": selectedUniversity?._source?.name,
            // "user_entry_id": (userEntryId).toString()
            // "user_entry_id": parseInt(userEntryId)
        }

        if (isPresent) {
            data['to_date'] = '';
            delete data['to_month'];
            delete data['to_year'];
        }

        if (!isPresent) {
            delete data['is_present']
        }

        let url = `/api/professionalworkhistory/${isEdit?.id}/`;
        try {
            let response = await callApi(url, 'PUT', data);
            if (response && response?.code !== 500) {
                
                let message = response?.message;
                if (message != "undefined" || message != undefined) {
                    toast(`${message}`, {
                        autoClose: 3500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                }
                this.resetStates(true)
            }
        } catch (err) {

        }
    }

    updateEdu = async () => {
        let { educationItems: { selectedUniversity, degreeText, fromDate, toDate, isPresent, isEdit } } = this.state;
        const { id } = this.props;
        let splitFrom = fromDate.split('-');
        let splitTo = toDate.split('-');

        let data = {
            "category": "education",
            "company": selectedUniversity?._source?.name,
            "from_date": fromDate,
            "from_month": splitFrom[1], // month
            "from_year": splitFrom[0], // year
            "is_present": isPresent,
            "professionaluser_entry_id": id,
            "search_id": selectedUniversity?._id,
            // "search_id": selectedUniversity?._id == isEdit?.id ? userEntryId : selectedUniversity?._id,
            "title": degreeText,
            "to_date": toDate,
            "to_month": splitTo[1], // month
            "to_year": splitTo[0],  // year
            "user_entry": selectedUniversity?._source?.name,
            // "user_entry_id": (userEntryId).toString()
            // "user_entry_id": parseInt(userEntryId)
        }

        if (isPresent) {
            data['to_date'] = '';
            delete data['to_month'];
            delete data['to_year'];
        }

        if (!isPresent) {
            delete data['is_present']
        }

        let url = `/api/professionalworkhistory/${isEdit?.id}/`;
        try {
            let response = await callApi(url, 'PUT', data);
            if (response && response?.code !== 500) {
                
                let message = response?.message;
                if (message != "undefined" || message != undefined) {
                    toast(`${message}`, {
                        autoClose: 3500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                }
                this.resetStates(true)
            }
        } catch (err) {

        }
    }

    resetStates = (isFetch) => {
        this.setState({
            isOpenEducation: false,
            isOpenAwards: false,
            isOpenCertification:false,
            searchText: "",
            // backgroundType: 'education',
            // businessListing: {},
            searchListing: {},
            educationItems: {
                selectedUniversity: '',
                degreeText: '',
                fromDate: '',
                toDate: '',
                isPresent: false,
                isEdit: false
            },
            certificationItems: {
                selectedUniversity: '',
                degreeText: '',
                fromDate: '',
                toDate: '',
                isPresent: false,
                isEdit: false
            },
            awardItems: {
                selectedUniversity: '',
                titleText: '',
                fromDate: '',
                isEdit: false
            }
        }, () => {
            if (isFetch) {
                this.fetchItems();
            }
        });
    }

    deleteBackgroundData = async (id) => {
        try {
            let url = `/api/professionalworkhistory/${id}/`;
            let response = await callApi(url, 'DELETE');
            if (response?.code !== 500 || response?.code !== 400) {
                let message = response?.message;
                if (message != "undefined" || message != undefined) {
                    toast(`${message}`, {
                        autoClose: 3500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                }
                this.fetchItems();
            }
        } catch (err) {

        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }


    render() {
        let { businessListing, searchListing, educationItems, certificationItems, awardItems, isOpenEducation, isOpenCertification, isOpenAwards,categorType } = this.state;
        const { boardCertification } = this.props;
		
        return (
            <div>
                <div className="bg-white-shaded-md text-secondary mb-3">
                    <CollapseBasic
                        title="Background"
                        containerClass="bg-transparent type-secondary"
                        bodyClass="text-secondary"
                        isOpen={false}>
                        <Nav tabs className="mb-3 active-transparent secondary-dark">
                            <NavItem>
                                <NavLink href="#"
                                    className="ff-base"
                                    active={this.state.backgroundType == "education"}
                                    onClick={() => {
                                        this.setState({ backgroundType: "education" }, () => {
                                            this.resetStates(true);
                                        })
                                    }}>{'Education'}
                                </NavLink>
                            </NavItem>

                            {boardCertification ?
                                (<NavItem>
                                    <NavLink href="#"
                                        className="ff-base"
                                        active={this.state.backgroundType == "certification"}
                                        onClick={() => {
                                            this.setState({ backgroundType: "certification" }, () => {
                                                this.resetStates(true);
                                            })
                                        }}>{'Board Certification'}
                                    </NavLink>
                                </NavItem>)
                                : null}

                            {!boardCertification && (categorType != "" && categorType != "accountant" && categorType != "lawyers")  ?
                                (<NavItem>
                                    <NavLink href="#"
                                        className="ff-base"
                                        active={this.state.backgroundType == "award"}
                                        onClick={() => {
                                            this.setState({ backgroundType: "award" }, () => {
                                                this.resetStates(true);
                                            })
                                        }}>{'Award'}
                                    </NavLink>
                                </NavItem>) : null}
                        </Nav>

                        <TabContent activeTab={this.state.backgroundType}>
                            {this.state.backgroundType == "education" &&
                                (<TabPane tabId="education">
                                    <ul className="list-unstyled">
                                        {businessListing['education'] &&
                                            Array.isArray(businessListing['education']) &&
                                            businessListing['education']?.length ?
                                            businessListing['education'].map((education_list, education_index) => (
                                                <li key={education_index} className="mb-3 py-2 border-bottom border-secondary">
                                                    <div className="d-flex flex-nowrap mx-n2">
                                                        <div className="px-2 mt-1 mb-2">
                                                            <img
                                                                src={require('../../assets/images/icons/placeholder-img.png')}
                                                                alt=""
                                                                width={50}
                                                                height={50}
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    objectPosition: 'center center'
                                                                }} />
                                                        </div>
                                                        <div className="d-flex flex-fill px-2 mx-n1">
                                                            <div className="text-secondary-dark px-1">
															
                                                                <div className="font-weight-bold fs-14 ff-base lh-1-2 mb-1">
                                                                    <span>{education_list?.title}</span>
                                                                    <span>&nbsp;at&nbsp;</span>
                                                                    <span>{education_list?.company}</span>
                                                                </div>
                                                                <div className="fs-13">
                                                                    {education_list?.from_date_label && education_list?.from_date_label != null ?
                                                                        `${education_list?.from_date_label} - ${education_list?.is_present ? 'Present' : education_list.to_date_label && education_list.to_date_label != null ? education_list.to_date_label : ""}`
                                                                        :
                                                                        ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 col-auto ml-auto">
                                                            <div className="interactive">
                                                                <div className="interactive-appear">
                                                                    <div className="text-nowrap" hidden={!this.checkIfLoggedIn()}>

                                                                        <EditBtn color="gold"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    isOpenEducation: true,
                                                                                    educationItems: {
                                                                                        selectedUniversity: {
                                                                                            _id: education_list?.id,
                                                                                            _source: {
                                                                                                name: education_list?.company
                                                                                            }
                                                                                        },
                                                                                        degreeText: education_list?.title,
                                                                                        fromDate: moment(`${education_list?.from_year}-${education_list?.from_month}`, 'YYYY-MM').format('YYYY-MM-DD'),
                                                                                        toDate: moment(`${education_list?.to_year}-${education_list?.to_month}`, 'YYYY-MM').format('YYYY-MM-DD'),
                                                                                        isPresent: education_list?.is_present,
                                                                                        isEdit: education_list
                                                                                    }
                                                                                }, () => {
                                                                                    this.fetchEditEduForm({ isEdu: true, id: education_list?.id });
                                                                                });
                                                                            }}
                                                                        />

                                                                        <DeleteBtn
                                                                            color="gold"
                                                                            onClick={() => {
                                                                                this.deleteBackgroundData(education_list?.id)
                                                                                // this.props.toggleConfirmModal({
                                                                                //     isTextDelete: true,
                                                                                //     uniqueKey: 'delete-background',
                                                                                //     id: education_list?.id
                                                                                // })
                                                                            }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </li>
                                            ))

                                            : null
                                        }
                                    </ul>

                                    <div>
                                        <Button
                                            color="link"
                                            className="text-secondary-dark ff-alt fs-13 font-weight-normal p-0 hover-blue _not-icon"
                                            onClick={() => {
                                                if(!this.checkIfLoggedIn()){
                                                    this.props.forceToggleLoginModal();
                                                    return false;
                                                }
                                                this.setState({
                                                    isOpenEducation: !this.state.isOpenEducation
                                                })
                                            }}>
                                            <span className="icon-add-new"></span>
                                            {'add new education'}
                                        </Button>

                                        <Collapse isOpen={isOpenEducation}>
                                            {/* Add education History */}
                                            <div className="shadow bg-white p-3 mt-2">
                                                <Form>
                                                    <h5 className="text-secondary-dark fs-30">
                                                        {`${educationItems?.isEdit ? 'Edit' : 'Add'} Background`}
                                                    </h5>
                                                    <div className="text-dark">
													<OutsideClickHandler
														  onOutsideClick={() => {
															this.setState({ isSearching: false })
														  }}>
                                                        <div className="mb-2 position-relative">
                                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">
                                                                {'University/College'}
                                                            </Label>
                                                            <Input
                                                                bsSize="sm"
                                                                type="text"
                                                                value={educationItems?.selectedUniversity?._source?.name ? educationItems?.selectedUniversity?._source?.name : this.state.searchText}
																onFocus={() => this.setState({ isSearching: true })}
																onChange={(e) => {
                                                                    educationItems['selectedUniversity'] = {};
                                                                    this.setState({ searchText: e.target.value, educationItems, universityError: ""}, () => {
                                                                        this.textOnSearch();
                                                                    });
                                                                }}
                                                                name="university_name"
                                                                autoComplete="false"
                                                                placeholder="Type/Search University/Institute"
                                                            />
															<span style={{color:'red'}}>{this.state.universityError}</span>
															
                                                            {/* Instant Search Results */}
															{this.state.isSearching ?
                                                            <ul
                                                                className="list-unstyled text-dark instant-results bg-white w-100 shadow"
                                                                hidden={educationItems?.selectedUniversity?._source?.name ? true : false}
                                                                style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                {searchListing && Array.isArray(searchListing?.results) && searchListing?.results?.length ?
                                                                    searchListing?.results.map((data, search_list_index) => (
                                                                        <li
                                                                            key={search_list_index}
                                                                            className="result-item"
                                                                            role="button"
                                                                            onClick={() => {
                                                                                educationItems['selectedUniversity'] = data;
                                                                                this.setState({ educationItems });
                                                                            }}>
                                                                            <div className="result-link fs-14">
                                                                                <div className="d-flex">
                                                                                    <div className="mr-2">
                                                                                        <img src={require('../../assets/images/icons/placeholder-img-alt.jpg')} width={40} alt="" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <span>
                                                                                            {data?._source?.name}
                                                                                        </span>
                                                                                        <div>
                                                                                            <img src={require('../../assets/images/icons/norating-alt.png')} alt="" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>{data?._source?.category}</span>
                                                                                        </div>
                                                                                        <div className="text-muted">
                                                                                            {Object.values(data?._source?.address).map((add, add_index) => (
                                                                                                <p key={add_index}>
                                                                                                    {`${add?.address1 ? `${add?.address1},` : ''} ${add?.address2 ? `${add?.address2},` : ''} ${add?.city ? `${add?.city},` : ''} ${add?.state ? `${add?.state},` : ''} ${add?.country ? `${add?.country},` : ''} ${add?.zipcode ? `${add?.zipcode},` : ''}`}
                                                                                                </p>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                    :
                                                                    null
                                                                }
                                                            </ul>
															: ""}
                                                        </div>
														</OutsideClickHandler>
                                                        <div className="mb-2">
                                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">Degree/Course</Label>
                                                            <Input
                                                                bsSize="sm"
                                                                type="text"
                                                                name="course"
                                                                value={educationItems['degreeText']}
                                                                onChange={(e) => {
                                                                    educationItems['degreeText'] = e.target.value;
                                                                    this.setState({ educationItems,degreeError:"" })
                                                                }}
                                                                placeholder="Enter the degree/course"
                                                            />
															<span style={{color:'red'}}>{this.state.degreeError}</span>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">Start Date</Label>
                                                            <Input
                                                                bsSize="sm"
                                                                type="month"
                                                                name="courseStartDate"
                                                                max={moment(educationItems['toDate'], 'YYYY-MM-DD').format('YYYY-MM')}
                                                                value={educationItems['fromDate'] !== '' ? moment(educationItems['fromDate'], 'YYYY-MM-DD').format('YYYY-MM') : ''}
                                                                onChange={(e) => {
                                                                    educationItems['fromDate'] = moment(e.target.value, 'YYYY-MM').format('YYYY-MM');
                                                                    this.setState({ educationItems,startError:"" })
                                                                }}
                                                                placeholder="Enter start date"
                                                            />
															<span style={{color:'red'}}>{this.state.startError}</span>
                                                        </div>
                                                        {!educationItems['isPresent'] && (
                                                            <div className="mb-2">
                                                                <Label size="sm" className="font-weight-bold mb-0 ff-base">End Date</Label>
                                                                <Input
                                                                    bsSize="sm"
                                                                    type="month"
                                                                    name="courseEndDate"
                                                                    min={moment(educationItems['fromDate'], 'YYYY-MM-DD').format('YYYY-MM')}
                                                                    max={moment().format('YYYY-MM')}
                                                                    value={educationItems['toDate'] !== '' ? moment(educationItems['toDate'], 'YYYY-MM-DD').format('YYYY-MM') : ''}
                                                                    onChange={(e) => {
                                                                        educationItems['toDate'] = moment(e.target.value, 'YYYY-MM').format('YYYY-MM');
                                                                        this.setState({ educationItems,endError:"" })
                                                                    }}
                                                                    placeholder="Enter end date"
                                                                />
																<span style={{color:'red'}}>{this.state.endError}</span>
                                                            </div>
                                                        )}
                                                        <div className="mb-2">
                                                            <FormGroup check>
                                                                <Label className="font-weight-bold ff-base" check>
                                                                    <Input
                                                                        type="checkbox"
																		checked={educationItems['isPresent'] == true ? true : false}
                                                                        onClick={() => {
                                                                            educationItems['isPresent'] = !educationItems['isPresent'];
                                                                            this.setState({ educationItems });
                                                                        }}
                                                                    />
                                                                    {'Currently here'}
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="mb-2">
                                                        <div className="text-right">
                                                            <Button size="sm" color="primary mr-2"
                                                                onClick={() => {
                                                                    if (educationItems?.isEdit) {
                                                                        this.updateEdu();
                                                                    } else {
                                                                        this.handleSubmitEdu();
                                                                    }
                                                                }}>
                                                                {'Submit'}
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                color="primary ml-0"
                                                                id="addEditEducationToggler"
                                                                onClick={this.resetStates}>
                                                                {'Cancel'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Form>
                                                <hr />
                                            </div>
                                        </Collapse>
                                    </div>
                                </TabPane>
                                )}

                            {boardCertification && this.state.backgroundType == "certification" ?
                                (<TabPane tabId="certification">
                                    <ul className="list-unstyled">
                                        {businessListing['certification'] &&
                                            Array.isArray(businessListing['certification']) &&
                                            businessListing['certification']?.length ?
                                            businessListing['certification'].map((certification_list, certification_index) => (
                                                <li key={certification_index} className="mb-3 py-2 border-bottom border-secondary">
                                                    <div className="d-flex flex-nowrap mx-n2">
                                                        <div className="px-2 mt-1 mb-2">
                                                            <img
                                                                src={require('../../assets/images/icons/placeholder-img.png')}
                                                                alt=""
                                                                width={50}
                                                                height={50}
                                                                style={{
                                                                    objectFit: 'cover',
                                                                    objectPosition: 'center center'
                                                                }} />
                                                        </div>
                                                        <div className="d-flex flex-fill px-2 mx-n1">
                                                            <div className="text-secondary-dark px-1">
                                                                <div className="font-weight-bold fs-14 ff-base lh-1-2 mb-1">
                                                                    <span>{certification_list?.title}</span>
                                                                    <span>&nbsp;at&nbsp;</span>
                                                                    <span>{certification_list?.company}</span>
                                                                </div>
                                                                <div className="fs-13">
                                                                    {certification_list?.from_date_label ?
                                                                        `${certification_list?.from_date_label} - ${certification_list?.to_date_label}`
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 col-auto ml-auto">
                                                            <div className="interactive">
                                                                <div className="interactive-appear">
                                                                    <div className="text-nowrap" hidden={!this.checkIfLoggedIn()}>

                                                                        <EditBtn color="gold"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    isOpenCertification: true,
                                                                                    certificationItems: {
                                                                                        selectedUniversity: {
                                                                                            _id: certification_list?.id,
                                                                                            _source: {
                                                                                                name: certification_list?.company
                                                                                            }
                                                                                        },
                                                                                        degreeText: certification_list?.title,
                                                                                        fromDate: moment(`${certification_list?.from_year}-${certification_list?.from_month}`, 'YYYY-MM').format('YYYY-MM-DD'),
                                                                                        toDate: moment(`${certification_list?.to_year}-${certification_list?.to_month}`, 'YYYY-MM').format('YYYY-MM-DD'),
                                                                                        isPresent: certification_list?.is_present,
                                                                                        isEdit: certification_list
                                                                                    }
                                                                                }, () => {
                                                                                    this.fetchEditEduForm({ isEdu: true, id: certification_list?.id });
                                                                                });
                                                                            }}
                                                                        />

                                                                        <DeleteBtn
                                                                            color="gold"
                                                                            onClick={() => {
                                                                                this.deleteBackgroundData(certification_list?.id)
                                                                                // this.props.toggleConfirmModal({
                                                                                //     isTextDelete: true,
                                                                                //     uniqueKey: 'delete-background',
                                                                                //     id: certification_list?.id
                                                                                // })
                                                                            }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </li>
                                            ))

                                            : null
                                        }
                                    </ul>

                                    <div>
                                        <Button
                                            color="link"
                                            className="text-secondary-dark ff-alt fs-13 font-weight-normal p-0 hover-blue _not-icon"
                                            onClick={() => {
                                                if(!this.checkIfLoggedIn()){
                                                    this.props.forceToggleLoginModal();
                                                    return false;
                                                }
                                                this.setState({
                                                    isOpenCertification: !this.state.isOpenCertification
                                                })
                                            }}>
                                            <span className="icon-add-new"></span>
                                            {'add new certification'}
                                        </Button>

                                        <Collapse isOpen={isOpenCertification}>
                                            {/* Add education History */}
                                            <div className="shadow bg-white p-3 mt-2">
                                                <Form>
                                                    <h5 className="text-secondary-dark fs-30">
                                                        {`${certificationItems?.isEdit ? 'Edit' : 'Add'} Certification`}
                                                    </h5>
                                                    <div className="text-dark">
                                                        <div className="mb-2 position-relative">
                                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">
                                                                {'University/College'}
                                                            </Label>
                                                            <Input
                                                                bsSize="sm"
                                                                type="text"
                                                                value={certificationItems?.selectedUniversity?._source?.name ? certificationItems?.selectedUniversity?._source?.name : this.state.searchText}
                                                                onChange={(e) => {
                                                                    certificationItems['selectedUniversity'] = {};
                                                                    this.setState({ searchText: e.target.value, certificationItems, universityError:"" }, () => {
                                                                        this.textOnSearch();
                                                                    });
                                                                }}
                                                                name="university_name"
                                                                autoComplete="false"
                                                                placeholder="Type/Search University/Institute"
                                                            />
                                                            <span style={{color:'red'}}>{this.state.universityError}</span>
                                                            {/* Instant Search Results */}
                                                            <ul
                                                                className="list-unstyled text-dark instant-results bg-white w-100 shadow"
                                                                hidden={certificationItems?.selectedUniversity?._source?.name ? true : false}
                                                                style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                {searchListing &&
                                                                    Array.isArray(searchListing?.results) &&
                                                                    searchListing?.results?.length ?
                                                                    searchListing?.results.map((data, search_list_index) => (
                                                                        <li
                                                                            key={search_list_index}
                                                                            className="result-item"
                                                                            role="button"
                                                                            onClick={() => {
                                                                                certificationItems['selectedUniversity'] = data;
                                                                                this.setState({ certificationItems })
                                                                            }}>
                                                                            <div className="result-link fs-14">
                                                                                <div className="d-flex">
                                                                                    <div className="mr-2">
                                                                                        <img src={require('../../assets/images/icons/placeholder-img-alt.jpg')} width={40} alt="" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <span>
                                                                                            {data?._source?.name}
                                                                                        </span>
                                                                                        <div>
                                                                                            <img src={require('../../assets/images/icons/norating-alt.png')} alt="" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>{data?._source?.category}</span>
                                                                                        </div>
                                                                                        <div className="text-muted">
                                                                                            {Object.values(data?._source?.address).map((add, add_index) => (
                                                                                                <p key={add_index}>
                                                                                                    {`${add?.address1 ? `${add?.address1},` : ''} ${add?.address2 ? `${add?.address2},` : ''} ${add?.city ? `${add?.city},` : ''} ${add?.state ? `${add?.state},` : ''} ${add?.country ? `${add?.country},` : ''} ${add?.zipcode ? `${add?.zipcode},` : ''}`}
                                                                                                </p>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                    :
                                                                    null
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">Degree/Course</Label>
                                                            <Input
                                                                bsSize="sm"
                                                                type="text"
                                                                name="course"
                                                                value={certificationItems['degreeText']}
                                                                onChange={(e) => {
                                                                    certificationItems['degreeText'] = e.target.value;
                                                                    this.setState({ certificationItems, degreeError:"" })
                                                                }}
                                                                placeholder="Enter the degree/course"
                                                            />
                                                            <span style={{color:'red'}}>{this.state.degreeError}</span>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">Start Date</Label>
                                                            <Input
                                                                bsSize="sm"
                                                                type="month"
                                                                name="courseStartDate"
                                                                max={moment(certificationItems['toDate'], 'YYYY-MM-DD').format('YYYY-MM')}
                                                                value={certificationItems['fromDate'] !== '' ? moment(certificationItems['fromDate'], 'YYYY-MM-DD').format('YYYY-MM') : ''}
                                                                onChange={(e) => {
                                                                    certificationItems['fromDate'] = moment(e.target.value, 'YYYY-MM').format('YYYY-MM');
                                                                    this.setState({ certificationItems, startError: "" })
                                                                }}
                                                                placeholder="Enter start date"
                                                            />
															<span style={{color:'red'}}>{this.state.startError}</span>
                                                        </div>
                                                        {!certificationItems['isPresent'] && (
                                                            <div className="mb-2">
                                                                <Label size="sm" className="font-weight-bold mb-0 ff-base">End Date</Label>
                                                                <Input
                                                                    bsSize="sm"
                                                                    type="month"
                                                                    name="courseEndDate"
                                                                    min={moment(certificationItems['fromDate'], 'YYYY-MM-DD').format('YYYY-MM')}
                                                                    max={moment().format('YYYY-MM')}
                                                                    value={certificationItems['toDate'] !== '' ? moment(certificationItems['toDate'], 'YYYY-MM-DD').format('YYYY-MM') : ''}
                                                                    onChange={(e) => {
                                                                        certificationItems['toDate'] = moment(e.target.value, 'YYYY-MM').format('YYYY-MM');
                                                                        this.setState({ certificationItems, endError:"" })
                                                                    }}
                                                                    placeholder="Enter end date"
                                                                />
                                                                <span style={{color:'red'}}>{this.state.endError}</span>
                                                            </div>
                                                        )}
                                                        <div className="mb-2">
                                                            <FormGroup check>
                                                                <Label className="font-weight-bold ff-base" check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        onClick={() => {
                                                                            certificationItems['isPresent'] = !certificationItems['isPresent'];
                                                                            this.setState({ certificationItems });
                                                                        }}
                                                                    />
                                                                    {'Currently here'}
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="mb-2">
                                                        <div className="text-right">
                                                            <Button size="sm" color="primary mr-2"
                                                                onClick={() => {
                                                                    if (certificationItems?.isEdit) {
                                                                        this.updateCert();
                                                                    } else {
                                                                        this.handleSubmitCert();
                                                                    }
                                                                }}>
                                                                {'Submit'}
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                color="primary ml-0"
                                                                id="addEditEducationToggler"
                                                                onClick={this.resetStates}>
                                                                {'Cancel'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Form>
                                                <hr />
                                            </div>
                                        </Collapse>
                                    </div>
                                </TabPane>
                                ) : null}

                            {!boardCertification && this.state.backgroundType == "award" ? (
                                <TabPane tabId="award">
                                    <ul className="list-unstyled">
                                        {businessListing['award'] && Array.isArray(businessListing['award']) && businessListing['award']?.length ?
                                            businessListing['award'].map((award_list, award_index) => (
                                                <li key={award_index} className="mb-3 py-2 border-bottom border-secondary">
                                                    <div className="d-flex flex-nowrap mx-n2">
                                                        <div className="px-2 mt-1 mb-2">
                                                            <img
                                                                src={require('../../assets/images/icons/placeholder-img.png')}
                                                                alt=""
                                                                width={50}
                                                                height={50}
                                                                style={{ objectFit: 'cover', objectPosition: 'center center' }} />
                                                        </div>
                                                        <div className="d-flex flex-fill px-2 mx-n1">
                                                            <div className="text-secondary-dark px-1">
                                                                <div className="font-weight-bold fs-14 ff-base lh-1-2 mb-1">
                                                                    <span>{award_list?.title}</span>
                                                                    <span>&nbsp;for&nbsp;</span>
                                                                    <span>{award_list?.company}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 col-auto ml-auto">
                                                            <div className="interactive">
                                                                <div className="interactive-appear">
                                                                    <div className="text-nowrap" hidden={!this.checkIfLoggedIn()}>
                                                                        <Button color="link"
                                                                            className="text-secondary-dark px-0"
                                                                            id="addEditAwardToggler">
                                                                            <EditBtn
                                                                                color="gold"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        isOpenAwards: true,
                                                                                        awardItems: {
                                                                                            selectedUniversity: {
                                                                                                _id: award_list?.id,
                                                                                                _source: {
                                                                                                    name: award_list?.company
                                                                                                }
                                                                                            },
                                                                                            titleText: award_list?.title,
                                                                                            fromDate: `${award_list?.from_year}-${award_list?.from_month}`,
                                                                                            isEdit: award_list
                                                                                        }
                                                                                    }, () => {
                                                                                        this.fetchEditEduForm({ isEdu: false, id: award_list?.id });
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                        <DeleteBtn color="gold"
                                                                            onClick={() => {
                                                                                this.deleteBackgroundData(award_list?.id);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )) : null}
                                    </ul>

                                    <div>
                                        <Button color="link"
                                            className="text-secondary-dark ff-alt fs-13 font-weight-normal p-0 hover-blue _not-icon"
                                            onClick={() => {
                                                if(!this.checkIfLoggedIn()){
                                                    this.props.forceToggleLoginModal();
                                                    return false;
                                                }
                                                this.setState({ isOpenAwards: !this.state.isOpenAwards });
                                            }}>
                                            <span className="icon-add-new"></span>
                                            {'add new award'}
                                        </Button>

                                        <Collapse isOpen={isOpenAwards}>
                                            {/* Add Award */}
                                            <div className="shadow bg-white p-3 mt-2">
                                                <Form>
                                                    <h5 className="text-secondary-dark fs-30">
                                                        {`${awardItems?.isEdit ? 'Edit' : 'Add'} Award`}
                                                    </h5>
                                                    <div className="text-dark">
                                                        <div className="mb-2 position-relative">
                                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">{'Award'}</Label>
                                                            <Input
                                                                bsSize="sm"
                                                                type="text"
                                                                name="movie_name"
                                                                value={awardItems?.selectedUniversity?._source?.name ? awardItems?.selectedUniversity?._source?.name : this.state.searchText}
                                                                onChange={(e) => {
                                                                    awardItems['selectedUniversity'] = {};
                                                                    this.setState({ searchText: e.target.value, awardItems }, () => {
                                                                        this.textOnSearch();
                                                                    });
                                                                }}
                                                                placeholder="Type/Search Movie" />

                                                            {/* Instant Search Results */}
                                                            <ul
                                                                className="list-unstyled text-dark instant-results bg-white w-100 shadow"
                                                                hidden={awardItems?.selectedUniversity?._source?.name ? true : false}
                                                                style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                                {searchListing && Array.isArray(searchListing?.results) && searchListing?.results?.length ?
                                                                    searchListing?.results.map((data, search_index_new) => (
                                                                        <li
                                                                            key={search_index_new}
                                                                            className="result-item"
                                                                            role="button"
                                                                            onClick={() => {
                                                                                awardItems['selectedUniversity'] = data;
                                                                                this.setState({ educationItems });
                                                                            }}>
                                                                            <div className="result-link fs-14">
                                                                                <div className="d-flex">
                                                                                    <div className="mr-2">
                                                                                        <img src={require('../../assets/images/icons/placeholder-img-alt.jpg')} width={40} alt="" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <span>
                                                                                            {data?._source?.name}
                                                                                        </span>
                                                                                        <div>
                                                                                            <img src={require('../../assets/images/icons/norating-alt.png')} alt="" />
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>{data?._source?.category}</span>
                                                                                        </div>
                                                                                        <div className="text-muted">
                                                                                            {Object.values(data?._source?.address).map((add, add_item_index) => (
                                                                                                <p key={add_item_index}>
                                                                                                    {`${add?.address1 ? `${add?.address1},` : ''} ${add?.address2 ? `${add?.address2},` : ''} ${add?.city ? `${add?.city},` : ''} ${add?.state ? `${add?.state},` : ''} ${add?.country ? `${add?.country},` : ''} ${add?.zipcode ? `${add?.zipcode},` : ''}`}
                                                                                                </p>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ))
                                                                    :
                                                                    null
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">Award for</Label>
                                                            <Input
                                                                bsSize="sm"
                                                                type="text"
                                                                name="award"
                                                                value={awardItems['titleText']}
                                                                onChange={(e) => {
                                                                    awardItems['titleText'] = e.target.value;
                                                                    this.setState({ awardItems });
                                                                }}
                                                                placeholder="Enter Award"
                                                            />
                                                        </div>
                                                        <div className="mb-2">
                                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">Award Date</Label>
                                                            <Input
                                                                bsSize="sm"
                                                                type="month"
                                                                name="awardDate"
                                                                max={moment().format('YYYY-MM')}
                                                                value={awardItems['fromDate'] !== '' ? moment(awardItems['fromDate'], 'YYYY-MM-DD').format('YYYY-MM') : ''}
                                                                onChange={(e) => {
                                                                    awardItems['fromDate'] = moment(e.target.value, 'YYYY-MM').format('YYYY-MM-DD');
                                                                    this.setState({ awardItems })
                                                                }}
                                                                placeholder="Enter date"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-2">
                                                        <div className="text-right">
                                                            <Button
                                                                onClick={() => {
                                                                    if (awardItems?.isEdit) {
                                                                        this.updateAward();
                                                                    } else {
                                                                        this.handleSubmitAwards();
                                                                    }
                                                                }}
                                                                size="sm" color="primary mr-2">
                                                                {'Submit'}
                                                            </Button>
                                                            <Button
                                                                onClick={this.resetStates}
                                                                size="sm" color="primary ml-0" id="addEditAwardToggler">
                                                                {'Cancel'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Form>
                                                <hr />
                                            </div>
                                        </Collapse>
                                    </div>
                                </TabPane>
                            ) : null}
                        </TabContent>
                    </CollapseBasic>
                </div>
            </div>
        )
    }
}
