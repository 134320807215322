import React from 'react';
import './App.scss';
import RouteLinks from "./router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import { callApi } from "./utils/apiCaller";

import packageJson from "../package.json";
import { getBuildDate } from "./utils/constants";
import withClearCache from "./ClearCache";
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLoginPrompt } from './actions/shared';

library.add(fab, far, fas);


const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  // arthurov2562@yopmail.com
  let url = '';
  let webUrl = window.location.href;
  const dispatch = useDispatch();

  if (webUrl.includes('/api/activate')) {
    let tokenArray = webUrl.split('activate');
    url = `/api/activate${tokenArray[1]}`;
  }else if(webUrl.includes('/api/confirm-email-id')){
    url = `/api/confirm-email-id${webUrl.split('confirm-email-id')[1]}`;
  }

  if(url !== ''){
    callApi(url, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        setTimeout(() => {
          toast(response.message, {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }, 2000);
      }
    });
  }
  
  let buildDate = packageJson?.buildDate;
  if (buildDate) {
    console.log({ 'Build date': JSON.stringify(getBuildDate(buildDate)) });
  }

  Axios.interceptors.request.use(
    (res) => {
      var api = res.url.replace('https://staging.api.wikireviews.com/api/','');
      const keyURL = api.split("/")?.[0]
      if(keyURL==="detailsstarrating"){
        if(!!!localStorage.getItem("token")) dispatch(setLoginPrompt(true))        
      }
      return res
    },
      
    err => {
    //you can have custom user frindly errors
    if (err.response.status === 404) {
        throw new Error(`${err.config.url} not found`);
      }
      throw err;
    }
  );

  return (
    <div className="App">
      <ToastContainer />
      <RouteLinks />
    </div>
  );
}

export default App;
