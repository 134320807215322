import React from 'react'
import { Button } from 'reactstrap';
import gmailIcon from "../../../../assets/images/icons/gmail.png";
import hotmailIcon from "../../../../assets/images/icons/hotmail.png";
import yahooIcon from "../../../../assets/images/icons/yahoo.png";
import FoundConnections from './foundConnections';
import { useGoogleLogin } from "@react-oauth/google";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { development_connection_keys } from "../../../../utils/constants";
import { toast } from "react-toastify";

const FindConnection = ({ socialState, importSocialContacts, handleAuthorization, authToken }) => {
    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => {
          handleAuthorization(tokenResponse);
        },
        onError: (response) => {
            toast("Oops! Something went wrong.", {
                autoClose: 2500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
              });
        },
        scope: development_connection_keys.google.scope,
      });

    return (
        <>
            <div className="circle-step">
                <ul>
                    <li>
                        <strong>1</strong>
                        <span>Profile Information</span>
                    </li>
                    <li className="active">
                        <strong>2</strong>
                        <span>Find Your Connections</span>
                    </li>
                    <li>
                        <strong>3</strong>
                        <span>Profile Picture</span>
                    </li>
                </ul>
            </div>
            <div className="text-left mb-3">
                <strong className="ff-base text-dark fs-14">
                    Are your connections already on WikiReviews?
                </strong>
            </div>
            <p className="fs-14 text-dark">
                How many of your friends and business connections are already
                using WikiReviews? Find out and connect by filling in your email
                address(s) below.
            </p>
            <div>
                <ul className="list-styled">
                    {socialPlatforms.map((item, index) => {
                        return <li className="ff-base border-bottom mb-3" key={index}>
                            {!socialState[item.value] ?
                                <div className="d-flex flex-wrap mb-2">
                                    <div className="mr-2">
                                        <img
                                            width={20}
                                            src={item.logo}
                                            alt={item.label}
                                            className="mr-2"
                                        />
                                        <span className="font-weight-bold fs-14 text-dark">
                                            {socialState?.[`${item.value}Loading`] ? "Fetching Contacts..." : `${item.label}`}
                                        </span>
                                    </div>
                                    <div className="ml-auto">
                                        <Button
                                            color="dark"
                                            className="mw text-tertiary"
                                            onClick={()=> {
                                                if (item.value === "gmail") {
                                                    login()
                                                } else {
                                                    importSocialContacts(item.value)
                                                }
                                            }}
                                        >
                                            Find Connections
                                        </Button>
                                    </div>
                                </div> : <div className="mb-2">
                                    <div className="mr-2 mb-3">
                                        <img
                                            width={20}
                                            src={item.logo}
                                            alt={item.label}
                                            className="mr-2"
                                        />
                                        <span className="fs-16 text-dark">
                                            {!!socialState.gmailImports ? `${socialState.gmailImports} ${socialState?.gmailImports > 1 ? "contacts" : "contact" } imported from ${item.label}` : "No Contacts Found"}
                                                                                   
                                        </span>
                                    </div>
                                    <FoundConnections connections={socialState?.[`${item.value}Connections`]} authToken={authToken}/>
                                </div>}
                        </li>
                    })}

                </ul>
            </div>
        </>
    )
}

export default FindConnection;


const socialPlatforms = [
    {
        label: "Gmail",
        value: "gmail",
        logo: gmailIcon
    }
]