import { Route, Redirect } from 'react-router-dom';
import React, { Component } from 'react'
import { connect } from 'react-redux';
class PrivateRoute extends Component {

    isLoggedIn = () => {
        return this.props.isLoggedIn ? true : false
    }
    
    render() {
        const { component: Component, ...rest } = this.props;
        let isLoggedIn = this.isLoggedIn();
        return (
            <Route {...rest} render={props => (
                isLoggedIn ?
                    <Component {...props} />
                    : <Redirect to="/" />
            )} />
        )
    }
}

const mapState = (state) => {
    return {
        isLoggedIn:state.auth.isLoggedIn
    }
}

export default connect(mapState)(PrivateRoute);