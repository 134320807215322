import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";


export const ConfirmationModal = ({ isOpen, setIsOpen, onConfirm, title }) => {
  return (
    <Modal size="sm" isOpen={isOpen} toggle={setIsOpen} className="text-center" modalClassName="backdrop-opaque top-shifted transition-none">
      <div className="bg-white modal-header">
        <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
        <button
          className="btn btn-sm"
          aria-label="Close"
          onClick={() => setIsOpen(false)}
        >
          <span aria-hidden="true" style={{fontSize:'25px'}}>×</span>
        </button>
      </div>
      <ModalBody className="text-dark">
        {title || 'Are you sure you want to delete comment?'}
      </ModalBody>
      <ModalFooter className="bg-white">
        <div className="text-center w-100">
          <Button
            color="primary"
            size="sm"
            className="btn-confirm"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="sm"
            className="btn-confirm"
            onClick={() => onConfirm()}
          >
            Ok
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
