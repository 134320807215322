/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-input-switch';
import { Row, Col, Badge, } from 'reactstrap';
import CollapseBasic from '../../../component/atoms/collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditBtn from "../../../component/atoms/editBtn";
import {
    update_additional_info,
    delete_additional_info,
    get_amenities_options,
    delete_additional_info_new
} from '../../../actions/business'

class AdditionalInfo extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
        this.state = {
            additionInfo: [],
            all_additional_fields: [],
            isEditVisible: {},
            CategoryName: '',
            CategoryId: '',
            parentId: '',
            parentName: '',
            corporateId: '',
            existing_info_count: 0,
            amenities_list: '',
            amenityIsSelectBox: false,
            additional_info_all_categories: [],
            isOpenAdditional: {},
            isOpenCategory: {},
            newID: 0,
            paymentSwitch: {},
            additionalInfoExisting:[],
            selectedAdditionalInfo: null,
            my_profile: null,
            selectedItem: null
        }
    }

    componentWillReceiveProps(nextProps) {
        const { business_details } = nextProps;
        if (business_details?.all_additional_fields) {
            let allAdditionalFields = business_details.all_additional_fields;
            // let result = this.sortObject(item);
            // let result = item;
            // let newMergeresult = result.concat(business_details?.all_additional_fields);

            // let newMergeresult = nextProps?.corporate_details?.all_additional_fields
            allAdditionalFields &&
                Array.isArray(allAdditionalFields) &&
                allAdditionalFields.length > 0 &&
                allAdditionalFields.forEach((nitem, index) => {

                    if (nitem) {
                        allAdditionalFields[index]["uniquId"] = index;
                    }
                    if (nitem.value) {
                        // this.sortObject(nitem.value)
                        //allAdditionalFields[index]["children"] = this.sortObject(nitem.value)
                        allAdditionalFields[index]["type"] = 'Edit'
                    } else {
                        allAdditionalFields[index]["type"] = 'Add'

                    }
                });
            this.setState({
                additionInfo: allAdditionalFields,
                existing_info_count: allAdditionalFields.length,
                corporateId: business_details.id,
                all_additional_fields: business_details?.all_additional_fields,
            })
        }

        if(business_details?.additional_info && business_details?.additional_info!== this.props.business_details?.additional_info){
            const receivedData = Object.keys(business_details.additional_info).map(item=>{
                return {
                    itemName: item,
                    itemValue: business_details.additional_info[item]?.[0]?.value,
                    created_by: business_details.additional_info[item]?.[0]?.created_by,
                    id: business_details.additional_info[item]?.[0]?.id
                }
            })
            this.setState({additionalInfoExisting: receivedData})
        }

        if(nextProps && nextProps.amenities_data) this.handleUpcomingAmenities(nextProps.amenities_data);

        if (nextProps && nextProps.my_profile) {
            this.setState({
                my_profile: nextProps.my_profile
            })
        }
    }

    handleUpcomingAmenities = (amenities_data)=>{
        if (amenities_data) {
            this.setState({
                amenities_list: amenities_data,
                CategoryId: amenities_data &&
                    amenities_data[0] &&
                    amenities_data[0].reviews_additionalattributes
            }, ()=>{
                let param;
                if (this.state.selectedItem) {
                    (this.state.selectedItem?.itemValue || []).forEach(value => {
                        const paramForAdditionalInfo = this.onSwitchClick(
                            1,
                            this.getSelectedAdditionalInfoParams(this.state.item),
                            value);
                        if (!param) {
                            param = paramForAdditionalInfo;
                        } else {
                            param.value = (param.value || []).concat((paramForAdditionalInfo.value || []));
                        }
                    })

                     let tempPaymentSwitch = {}
                    this.state.amenities_list.forEach(async amentity => {
                        const findItemValue = (this.state.selectedItem?.itemValue || []).find(value => value === amentity.option_value);
                        if (findItemValue) {
                            const rawPaymentSwitch = this.handleSwitch(findItemValue, amentity)
                            tempPaymentSwitch = Object.assign({}, rawPaymentSwitch, tempPaymentSwitch);
                            this.setState({ paymentSwitch: tempPaymentSwitch })

                        }
                     })
                   
                   if(param) this.setState({ selectedAdditionalInfo: param });
                }
            })

        }
    }

    sortObject = (items) => {
        let { isEditVisible } = this.state;
        let filteredItems = [];
        var myObj = items,
            keys = Object.keys(myObj),
            i, k, len = keys.length;

        let filteredKeys = keys.filter(item => item.search('_') < 0);
        filteredKeys = filteredKeys && Array.isArray(filteredKeys) && filteredKeys.sort((a, b) => {
            let aItems = a.toLowerCase();
            let bItems = b.toLowerCase();
            if (aItems < bItems) { return -1; }
            if (aItems > bItems) { return 1; }
            return 0;
        });
        for (i = 0; i < len; i++) {
            k = filteredKeys[i];
            if (k !== undefined) {
                isEditVisible[i] = false;
                filteredItems.push({ "name": k, "value": myObj[k] })
            }
        }
        this.setState({ isEditVisible })
        return filteredItems;
    }

    toggleDeleteItems = (item, title) => {
        let param = {}
        param["delete_add_info"] = true
        param["name"] = title
        let info;

        if(Array.isArray(item.value)){
            if(item.value.length === 1){
               info =  item.value;
            } else{
                info = item.value;
            }
        } else{
            info = [item.value];
        }
        param["info"] = info;

        this.props.delete_additional_info(param, this.state.corporateId)
        this.setState({ isOpenAdditional: {}, selectedAdditionalInfo: null, paymentSwitch: {} });
    }

    handleSwitch = (itemData, amenity) => {
        let valueType = amenity.option_value
        let { paymentSwitch } = this.state;
        let tempPaymentSwitch = JSON.parse(JSON.stringify(paymentSwitch || {}));
        let param = {}
        if(!amenity.is_multiple_choice_attribute){
            tempPaymentSwitch = {};
        }
        if (itemData?.multiple == true) {
			
            if (valueType == "YES") {
                tempPaymentSwitch[valueType] = !tempPaymentSwitch[valueType];
                if (tempPaymentSwitch["NO"]) {
                    param["delete_add_info"] = true
                    param["name"] = itemData?.name
                    param["info"] = "NO"
                    // await this.props.delete_additional_info_new(param, this.state.corporateId)
                    delete tempPaymentSwitch.NO;
                }
            } else if (valueType == "NO") {
                tempPaymentSwitch[valueType] = !tempPaymentSwitch[valueType];
                if (tempPaymentSwitch["YES"]) {
                    param["delete_add_info"] = true
                    param["name"] = itemData?.name
                    param["info"] = "YES"
                    // await this.props.delete_additional_info_new(param, this.state.corporateId)
                    delete tempPaymentSwitch.YES;
                } else {
                    tempPaymentSwitch[valueType] = !tempPaymentSwitch[valueType];
                }
            }else{
				tempPaymentSwitch[valueType] = !tempPaymentSwitch[valueType];
			}
        } else if (itemData?.multiple == false) {
            tempPaymentSwitch[valueType] = !tempPaymentSwitch[valueType];
            if (Object.keys(tempPaymentSwitch).length > 1) {
                let last = Object.keys(tempPaymentSwitch)[Object.keys(tempPaymentSwitch).length - 2];
                param["delete_add_info"] = true
                param["name"] = itemData?.name
                param["info"] = last
                // await this.props.delete_additional_info_new(param, this.state.corporateId)
                delete tempPaymentSwitch[last];
            }else{
				tempPaymentSwitch[valueType] = !tempPaymentSwitch[valueType];
			}

        } else {
            tempPaymentSwitch[valueType] = !tempPaymentSwitch[valueType];
        }
              
        this.setState({ paymentSwitch: tempPaymentSwitch });
        return tempPaymentSwitch;
    }

    handleSwitchEdit = async (e, index, itemData, valueType, parentId, parentName) => {
        let { paymentSwitch } = this.state;
        let param = {}
      
        if (itemData?.multiple == true) {
            if (valueType == "YES") {
                paymentSwitch[valueType] = !paymentSwitch[valueType];
                if (paymentSwitch["NO"]) {
                    param["delete_add_info"] = true
                    param["name"] = itemData?.name
                    param["info"] = "NO"
                    await this.props.delete_additional_info_new(param, this.state.corporateId)
                    await delete paymentSwitch.NO;
                }
            } else if (valueType == "NO") {
                paymentSwitch[valueType] = !paymentSwitch[valueType];
                if (paymentSwitch["YES"]) {
                    param["delete_add_info"] = true
                    param["name"] = itemData?.name
                    param["info"] = "YES"
                    await this.props.delete_additional_info_new(param, this.state.corporateId)
                    await delete paymentSwitch.YES;
                } else {
                    paymentSwitch[valueType] = !paymentSwitch[valueType];
                }
            }else {
                    paymentSwitch[valueType] = !paymentSwitch[valueType];
            }
        } else if (itemData?.multiple == false) {
            paymentSwitch[valueType] = !paymentSwitch[valueType];
            if (Object.keys(paymentSwitch).length > 1) {
                let last = Object.keys(paymentSwitch)[Object.keys(paymentSwitch).length - 2];
                param["delete_add_info"] = true
                param["name"] = itemData?.name
                param["info"] = last
                await this.props.delete_additional_info_new(param, this.state.corporateId)
                await delete paymentSwitch[last];
            }else {
                 paymentSwitch[valueType] = !paymentSwitch[valueType];
            }

        } else {
            paymentSwitch[valueType] = !paymentSwitch[valueType];
        }
       
        this.setState({ paymentSwitch });
    }

    renderValues = (value, title) => {
        let itemsRender = []
        if (value && Array.isArray(value) && value.length > 0) {
            value.map((item, index) => {
                let currentIndex = index + 1;
                if (item.value && typeof (item.value) == "string") {
                    itemsRender.push(
                        <React.Fragment key={index}>
                            <Badge color="default" className="mr-1 fs-12">
                                <div className="d-flex text-normal align-items-start">
                                    <span className="mr-1 font-weight-normal text-right">{item.value}</span>
                                    <button onClick={() => this.toggleDeleteItems(item, title)} type="button" className="close btn-sm" aria-label="Close">
                                        <FontAwesomeIcon icon="times-circle" />
                                    </button>
                                </div>
                            </Badge>
                        </React.Fragment>
                    )
                }
                return null;
            })
        }
        return (
            itemsRender
        )
    }

    renderItems = ({ title, value, index, item, parentId, parentName }, new_info = false) => {
        let { existing_info_count, isOpenAdditional } = this.state;
        if (new_info) {
            index = existing_info_count + index
        }
        if (title !== 'specialties' && title != 'undefined' && title != undefined && title != "") {
            return (
            <div className="border-bottom"  key={item?.id}>
                <li className='d-flex flex-nowrap align-items-start py-2 px-1'>
                    <div className="d-flex flex-wrap w-100">
                        <span className="font-weight-bold">{title}</span>

                        {/* Hide when editing */}
                        <span className="ml-auto">
                            <div className="d-flex">
                                <div className="text-right">
                                    {value != "undefined" || value != undefined ? this.renderValues(value, title) : ""}
                                </div>
                                <div className="ml-2 interactive col-auto px-0">
                                    <div className="interactive-appear">
                                        <div className="text-nowrap">
                                            {value == "undefined" || value == undefined ?
                                                <button type="button" className="btn btn-sm text-secondary-dark"
                                                    onClick={ () => {
                                                        //  this.setState({ isOpenAdditional: {}, paymentSwitch: {} });
                                                        if(isOpenAdditional && isOpenAdditional[item?.id]){
                                                            this.setState({ paymentSwitch: {}, isOpenAdditional: {}});
                                                        } else{
                                                            this.setState({ paymentSwitch: {}, selectedAdditionalInfo: null});
                                                            this.toggleEditState({ idx: item?.id, item });
                                                        }
                                                        
                                                    }
                                                    }
                                                >
                                                    {isOpenAdditional && isOpenAdditional[item?.id] ? <>Close</>: <><FontAwesomeIcon icon="plus" /> {"Add"}</>}
                                                </button>
                                                :
                                                <EditBtn
                                                    onClick={ () => {
                                                        //  this.setState({ isOpenAdditional: {}, paymentSwitch: {} });
                                                        this.setState({ paymentSwitch: {}, selectedAdditionalInfo: null});
                                                         this.toggleEditState({ idx: item?.id, item });
                                                    }
                                                    }
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                    {/* Show when editing */}
                </li>
                {this.renderAdditionalInfoBox({ value, index, item, parentId, parentName }, new_info)}
                                </div>
            )
        }
    }

    renderAdditionalInfoBox = ({ value, index, item, parentId, parentName }, new_info)=>{
        let { existing_info_count, isOpenAdditional, paymentSwitch, selectedAdditionalInfo, amenityIsSelectBox } = this.state;
        if (new_info) {
            index = existing_info_count + index
        }

        return <>
        <div className="mb-4 py-2 shadow-sm bg-white px-2" hidden={isOpenAdditional && isOpenAdditional[item?.id] ? false : true}>
        <div className="mb-2">

                    {
                        amenityIsSelectBox ?
                            <select className='form-row' style={{ width: "100%" }}>
                                {
                                    this.state.amenities_list &&
                                    Array.isArray(this.state.amenities_list) &&
                                    this.state.amenities_list.length > 0 &&
                                    this.state.amenities_list.map((amenity, amenityIndex) => (
                                        <option key={amenityIndex}
                                            onClick={(event) => {
                                                this.handleSwitch(item, amenity)
                                                if (value == "undefined" || value == undefined) this.onSwitchClick(1, item, amenity.option_value);
                                                else this.handleSwitchEdit(1, index, item, amenity.option_value, parentId, parentName)
                                            }}
                                            value={
                                                (value == "undefined" || value == undefined) ?
                                                    (paymentSwitch && Object.keys(paymentSwitch).length > 0 && paymentSwitch[amenity.option_value] ? 1 : 0)
                                                    :
                                                    this.userExists(value, amenity.option_value) ? 1 : paymentSwitch && Object.keys(paymentSwitch).length > 0 && paymentSwitch[amenity.option_value] ? 1 : 0
                                            }

                                            selected={
                                                (value == "undefined" || value == undefined) ?
                                                    (paymentSwitch && Object.keys(paymentSwitch).length > 0 && paymentSwitch[amenity.option_value] ? true : false)
                                                    :
                                                    this.userExists(value, amenity.option_value) ? true : paymentSwitch && Object.keys(paymentSwitch).length > false && paymentSwitch[amenity.option_value] ? true : false
                                            }
                                        >{amenity.option_value}</option>
                                    ))
                                }
                            </select>
                            : 
            <Row form>
                {this.state.newID != 0 && this.state.newID == (item && item.id ? item.id : item && item.value && item.value[0] && item.value[0].id) ?
                    <>
                        {this.state.amenities_list &&
                            Array.isArray(this.state.amenities_list) &&
                            this.state.amenities_list.length > 0 &&
                            this.state.amenities_list.map((amenity, amenityIndex) => (
                                <Col xs="auto" key={amenityIndex}>
                                    {(value == "undefined" || value == undefined) ?
                                        <Switch
                                            value={paymentSwitch && Object.keys(paymentSwitch).length > 0 && paymentSwitch[amenity.option_value] ? 1 : 0}
                                            // name={"amenityIndex_" + amenity.option_value}
                                            // id={"amenityIndex_" + amenity.option_value}
                                            name={"amenityIndex_" + amenity.id}
                                            id={"amenityIndex_" + amenity.id}
                                            onChange={ (e) => {
                                                 this.handleSwitch(item, amenity)
                                                 this.onSwitchClick(e, item, amenity.option_value);
                                            }}
                                        /> :
                                        <Switch
                                            //value={this.userExists(value, amenity.option_value) ? 1 : 0}
                                            value={this.userExists(value, amenity.option_value) ? 1 : paymentSwitch && Object.keys(paymentSwitch).length > 0 && paymentSwitch[amenity.option_value] ? 1 : 0}
                                            // name={"amenityIndex_" + amenity.option_value}
                                            // id={"amenityIndex_" + amenity.option_value}
                                            name={"amenityIndex_" + amenity.id}
                                            id={"amenityIndex_" + amenity.id}
                                            onChange={ (e) => {
                                                //await this.handleSwitch(e, amenity.option_value)
                                                 this.handleSwitchEdit(e, index, item, amenity.option_value, parentId, parentName)
                                                 this.onSwitchClick(e, item, amenity.option_value);

                                            }}
                                        />
                                    }
                                    {/*<Switch
                                        value={this.userExists(value, amenity.option_value) ? 1 : 0}
                                        name={amenity.option_value}
                                        id={"amenityIndex_" + amenity.option_value}
                                        onChange={(e) => this.handleEdit(e, index, item, amenity.option_value, parentId, parentName)}
                                    />*/}  {amenity.option_value}

                                </Col>
                            ))
                        }

                        {/* <Switch value={this.state.dummySwitch} name="dummy-switch" disabled={this.state.dummyProcessing} onChange={() => {
                            this.setState({ dummyProcessing: true });
                            setTimeout(() => {
                                this.setState({ dummySwitch: this.state.dummySwitch ? 0 : 1 });
                                this.setState({ dummyProcessing: false });
                            }, 2000)
                        }} /> */}
                    </>
                    : ""
                }
            </Row>
        }

                    {this.state.amenities_list?.length ? <div className='d-flex col-auto'>
                        <button
                             disabled={!selectedAdditionalInfo || !selectedAdditionalInfo?.value?.length}
                            className="btn btn-link btn-sm px-0 font-weight-bold text-dark" onClick={() => {
                                this.saveAdditionalInfo();
                            }}>Save</button>

                        {
                            this.state.additionalInfoExisting.find(a => a.itemName === selectedAdditionalInfo?.info) ?
                                <button
                                    //  disabled={!selectedAdditionalInfo}
                                    className="btn btn-link btn-sm px-0 font-weight-bold text-dark" onClick={() => {
                                        let data = JSON.parse(JSON.stringify(selectedAdditionalInfo));
                                        this.toggleDeleteItems(data, (data?.info));
                                        return;
                                    }}>Delete</button> :
                                <></>
                        }

                        <button className="btn btn-link btn-sm text-muted" onClick={() => {
                            this.setState({ isOpenAdditional: {}, selectedAdditionalInfo: null, paymentSwitch: {} });

                        }}>Close</button>

                    </div> : <></>}
        </div>

    </div>
        </>
    }



    userExists = (arr, val) => {
        let data = false
        let { paymentSwitch } = this.state;
        let { newID } = this.state
        if (Array.isArray(arr) && arr.length > 0) {
            arr.map((el, index) => {
                if (el.value == val) {
                    this.state.paymentSwitch[el.value] = true;
                    //this.setState({paymentSwitch});
                    data = true
                }
            })
            return data
        }
    }

    toggleEditState = ({ idx, del, item }) => {
        let { isEditVisible } = this.state;
        const tempIsOpenAdditional = {};
        let newID;
        if (del) {
            isEditVisible[idx] = false;
        } else if (tempIsOpenAdditional[item?.id]) {
            delete tempIsOpenAdditional[item?.id];
        } else {
            newID = item?.id ? item.id : item?.value?.[0].id;
            isEditVisible[idx] = true;
            tempIsOpenAdditional[item?.id] = true
            this.setState({
                amenities_list: '',
                newID: newID,
                amenityIsSelectBox: item.name === "Year Business Started" || item.info === "Year Business Started" || item.itemName === "Year Business Started"
            }, () => {
                if (item.name === "Year Business Started" || item.info === "Year Business Started" || item.itemName === "Year Business Started") {
                    const today = new Date();
                    let toyear = today.getFullYear();
                    let fromYear = 1800;
                    const amenities_data = [];

                    for (let i = fromYear; i <= toyear; i++) {
                        amenities_data.push(
                            {
                                id: i,
                                reviews_additionalattributes_id: idx,
                                option_value: i,
                                is_active: true,
                                is_multiple_choice_attribute: 0
                            }
                        )

                    }

                    this.handleUpcomingAmenities(amenities_data);
                } else {
                    this.props.get_amenities_options(newID)
                }

            });
        }

        this.setState({ 
            isEditVisible, 
            selectedItem: item,
            CategoryName: item?.itemName || item?.name, 
            parentId: item?.parentId,
            parentName: item?.parentName,
            CategoryId: newID, 
            isOpenAdditional: tempIsOpenAdditional
         })
    }

    handleOnExpand = (expand, id) => {
        let { isOpenCategory } = this.state;
        isOpenCategory[id] = expand;
        this.setState({ isOpenCategory });
    }

    manageValueToSelectedAdditionalInfo = (e, data, valueType) => {
        const amentity = this.state.amenities_list?.find(a => a.option_value === valueType);

        if (amentity?.is_multiple_choice_attribute) {
            if (e == 0) {
                // toggle Off
                const tempValues = (data.value || []).filter(t => t != valueType);
                data.value = tempValues;
            } else if (e === 1) {
                // toggle on
                data.value.push(valueType);
            }
        } else {
            data.value = [valueType];
        }
        return data;
    }

    onSwitchClick = (e, itemData, valueType) => {
        const { selectedAdditionalInfo, CategoryId } = this.state;
        let param;
        if (!selectedAdditionalInfo) {
            param = this.manageValueToSelectedAdditionalInfo(
                e,
                this.getSelectedAdditionalInfoParams(itemData),
                valueType
            )
            this.setState({ selectedAdditionalInfo: param });
        } else if (selectedAdditionalInfo && selectedAdditionalInfo["name"] === CategoryId) {
            const tempselectedAdditionalInfo = JSON.parse(JSON.stringify(selectedAdditionalInfo));
            if (!tempselectedAdditionalInfo.id) tempselectedAdditionalInfo.id = itemData.id;
            param = this.manageValueToSelectedAdditionalInfo(
                e,
                tempselectedAdditionalInfo,
                valueType
            )
            this.setState({ selectedAdditionalInfo: param });

        } else {
            this.setState({ selectedAdditionalInfo: null }, () => {
                param = this.manageValueToSelectedAdditionalInfo(
                    e,
                    this.getSelectedAdditionalInfoParams(itemData),
                    valueType
                )
                this.setState({ selectedAdditionalInfo: param });
            })
        }

        return param;
    }

    getSelectedAdditionalInfoParams = (itemData) => {
        let param = {}
        param["name"] = this.state.CategoryId;
        param["info"] = this.state.CategoryName;
        param["type"] = this.state.CategoryName;
        param["value"] = [];
        param["multiple"] = true;
        param["parentId"] = this.state.parentId ? this.state.parentId : "";
        param["parentName"] = this.state.parentName ? this.state.parentName : "";
        param["update_additional_info"] = true;
        param["id"] = itemData?.id;
        return param;
    }

    saveAdditionalInfo = () => {
        const { selectedAdditionalInfo } = this.state;
        let data = JSON.parse(JSON.stringify(selectedAdditionalInfo));
        this.props.update_additional_info(data, this.state.corporateId)
        setTimeout(() => {
            this.setState({ isOpenAdditional: {} , selectedAdditionalInfo: null, paymentSwitch: {}});
        }, 1000);
    }

    render() {
        const { additionInfo, additionalInfoExisting, my_profile } = this.state;
        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic title="Additional Information" containerClass="bg-transparent type-secondary" bodyClass="text-secondary-dark" isOpen={true}>

                    {/* Show If data */}
                    <div>
                        <ul>
                            {additionalInfoExisting?.map((item, index)=>{
                                return  <li key={index}>
                                <div className="border-bottom">
                                    <div className={`d-flex flex-nowrap align-items-start py-2 px-1`}>
                                        <div className="font-weight-bold flex-grow-1 mr-2" >{item?.itemName}</div>

                                            <div className="d-flex">
                                                <span className="d-inline-block">
                                                    {/* {typeof item?.itemValue === 'object' ||
                                                        Array.isArray(item?.itemValue) ? "" : item?.itemValue} */}
                                                    {Array.isArray(item?.itemValue) ? (item?.itemValue || []).join(", ") :
                                                        (typeof item?.itemValue === 'object' ? "" : item?.itemValue)
                                                    }

                                                </span>
                                                {(item.created_by === my_profile?.user?.id && item?.id)
                                                    ? <span className='ml-2'><FontAwesomeIcon icon="edit" onClick={() => {
                                                        this.setState({ paymentSwitch: {}, selectedAdditionalInfo: null });
                                                        this.toggleEditState({ idx: item?.id, item });
                                                    }} /></span> : <></>}
                                            </div>
                                    </div>
                                    {this.renderAdditionalInfoBox({ value: item?.value, index, item, parentId: item?.parentId, parentName: item?.parentName })}
                                </div>
                            </li>
                            })}

                           

                        </ul>
                        <ul className="list-unstyled mb-2 fs-14">
                            {additionInfo && Array.isArray(additionInfo) && additionInfo.length > 0 ?
                                additionInfo.map((item, index) => {
                                    return this.renderItems({
                                        title: item?.name,
                                        value: item?.value,
                                        index,
                                        item,
                                    },
                                        false)
                                }) : null}
                        </ul>
                        {/* <ul className="list-unstyled mb-2 fs-14">
                            {additionInfo && Array.isArray(additionInfo) && additionInfo.length > 0 ?
                                additionInfo.map((item, index) => {
                                    if (item.parentId) {
                                        return (
                                            <li key={item.uniquId}>
                                                <div className="border-bottom">
                                                    <div className={`d-flex flex-nowrap align-items-start py-2 px-1 add_info_type_${item.type.toLowerCase()}`}>
                                                        <div className="font-weight-bold flex-grow-1 mr-2" key={item.uniquId}>{item.parentName}</div>
                                                        {isOpenCategory && isOpenCategory[item?.uniquId] ?
                                                            <div className="flex-shrink-0">
                                                                <span className="d-inline-block" role="button" tabIndex={0} onClick={async () => {
                                                                    this.handleOnExpand(false, item.uniquId);
                                                                }}>
                                                                    <FontAwesomeIcon icon="minus" />
                                                                </span>
                                                            </div>
                                                            : <div className="flex-shrink-0">
                                                                <span className="d-inline-block" role="button" tabIndex={0} onClick={async () => {
                                                                    this.handleOnExpand(true, item.uniquId);
                                                                }}>
                                                                    <span className="mr-2">{item.type}</span><FontAwesomeIcon icon="plus" />
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    {isOpenCategory && isOpenCategory[item.uniquId] ?
                                                        <ul className="list-unstyled mb-2 fs-14 pl-3">
                                                            {item.children && Array.isArray(item.children) && item.children.length > 0 ?
                                                                item.children.map((itemSubcategory, indexSub) => {
                                                                    return (
                                                                        this.renderItems({
                                                                            title: itemSubcategory.name,
                                                                            value: itemSubcategory.value,
                                                                            index: indexSub,
                                                                            item: itemSubcategory,
                                                                            parentId: item.parentId,
                                                                            parentName: item.parentName,
                                                                        },
                                                                            false)
                                                                    )
                                                                }) : <div>  <span className="small"><i>{'No subcategory found'}</i></span>  </div>}
                                                        </ul>
                                                        : ""}
                                                </div>
                                            </li>
                                        )
                                    }
                                }) : null}
                        </ul> */}
                    </div>
                </CollapseBasic>
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        amenities_data: state.business.amenities_data,
        business_details: state.business.business_details,
        my_profile: state.user.my_profile
    }
}

const mapProps = (dispatch) => ({
    update_additional_info: (data, id) => dispatch(update_additional_info(data, id)),
    delete_additional_info: (data, id) => dispatch(delete_additional_info(data, id)),
    delete_additional_info_new: (data, id) => dispatch(delete_additional_info_new(data, id)),
    get_amenities_options: (data) => dispatch(get_amenities_options(data)),
});

export default connect(mapState, mapProps)(AdditionalInfo);
