import { callApi } from "../utils/apiCaller";
// import {FETCH_LISTING_PINS} from "./business";
//import { toast } from "react-toastify";

export const PROJECT_LIST = "PROJECT_LIST";
export const PROJECT_LIST_PAGES = "PROJECT_LIST_PAGES";
export const PROJECT_CATEGORIES = "PROJECT_CATEGORIES";
export const PROJECT_SUB_CATEGORIES = "PROJECT_SUB_CATEGORIES";
export const PROJECT_SUB_CATEGORIES_FILTER = "PROJECT_SUB_CATEGORIES_FILTER";
export const FILTERED_PROJECT_LIST_PAGES = "FILTERED_PROJECT_LIST_PAGES";
export const CONNECTIVITY_TABS_CONTENT = "CONNECTIVITY_TABS_CONTENT";
export const FETCH_LISTING_PINS = 'FETCH_LISTING_PINS';

// Import list
export const project_list_ = (data) => {
    return {
        type: PROJECT_LIST,
        payload: data,
    };
};

export const project_list = () => {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            callApi(`/api/projects/?&image__isnull=False&is_active=True&is_draft=False&trending_flag=True`, "GET").then((response) => {

                if (response && response.code !== 400 && response.code !== 500) {
                    dispatch(project_list_(response));
                    return resolve();
                }

                return resolve();
            });
        })

    };
};

export const project_trending_list = (id,categoryId) => {

    return (dispatch) => {
        callApi(`/api/trendingcrpcategory/?id=${id}&category=${categoryId}`, "GET").then((response) => {

            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(project_list_(response));
            }
        });
    };

};


// Project list on scroll
export const project_list_pages_ = (data) => {
    return {
        type: PROJECT_LIST_PAGES,
        payload: data,
    };
};

export const project_list_pages = (pageNo) => {
    return (dispatch) => {
        callApi(`/api/projects/?&image__isnull=False&is_active=True&is_draft=False&trending_flag=True&page=${pageNo}`, "GET").then((response) => {

            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(project_list_pages_(response));
            }
        });
    };

};


// Left Categories
export const project_categories_ = (data) => {
    return {
        type: PROJECT_CATEGORIES,
        payload: data,
    };
};

export const project_categories = () => {

    return (dispatch) => {
        return new Promise((resolve, reject) => {
            callApi(`/api/taxonomy/?category=Projects_Taxonomy&depth=false&delsel=&ancestor=&sub_cat=&level=1`, "GET").then((response) => {

                if (response && response.code !== 400 && response.code !== 500) {
                    dispatch(project_categories_(response));
                    return resolve();
                }
                return resolve();
            });
        });
    };

};

// Left Categories Sub Items
export const project_subItems_ = (data) => {
    return {
        type: PROJECT_SUB_CATEGORIES,
        payload: data,
    };
};

export const project_subItems = (id) => {

    return (dispatch) => {
        callApi(`/api/taxonomy/?category=${id}&depth=false&delsel=&ancestor=&sub_cat=&level=1`, "GET").then((response) => {

            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(project_subItems_(response));
            }
        });
    };

};

// Left Categories Sub Items Filter
export const project_subItems_filter_ = (data) => {
    return {
        type: PROJECT_SUB_CATEGORIES_FILTER,
        payload: data,
    };
};

export const project_subItems_filter = (id) => {

    return (dispatch) => {
        callApi(`/api/projects/?is_draft=False&taxonomy=${id}&page=1`, "GET").then((response) => {

            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(project_subItems_filter_(response));
            }
        });
    };

};


// Filtered Project list on scroll
export const filtered_list_pages_ = (data) => {
    return {
        type: FILTERED_PROJECT_LIST_PAGES,
        payload: data,
    };
};

export const filtered_list_pages = (id, pageNo) => {
    return (dispatch) => {
        callApi(`/api/projects/?is_draft=False&taxonomy=${id}&page=${pageNo}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(filtered_list_pages_(response));
            }
        });
    };

};


// My Connection Activity - 
export const connectivity_tabs_content_ = (data) => {
    return {
        type: CONNECTIVITY_TABS_CONTENT,
        payload: data,
    };
};

export const connectivity_tabs_content = (name) => {
    return (dispatch) => {
        callApi(`/api/userfeeds/?category=crp&searchTerm=${name}&page=1`, "GET").then((response) => {

            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(connectivity_tabs_content_(response));
            }
        });
    };

};


export const pins_dispatch_data = (data) => ({ type: FETCH_LISTING_PINS, payload: data });
export const fetch_items_pin = (id) => {
    return (dispatch) => {
        callApi(`/api/pins/?type=&user=&listing_id=${id}&pin_review_type=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                response['id'] = id;
                dispatch(pins_dispatch_data(response))
            }
        });
    }
}







