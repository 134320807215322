
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {enable_fiend_friends_tab, get_providers_on_wikireviews } from "../../../actions/connections";
import { connect } from "react-redux";

class Connections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      providersConnections: {}
    };
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.providers_on_wikireviews) {
      this.setState({
        providersConnections: nextProps.providers_on_wikireviews,
      });
    }
  }

  render() {
    let { connections } = this.props;
    const {providersConnections} = this.state;
    return (
      <div className="bg-white-shaded-md p-3 mb-3 step-5">
        <h2 className="text-secondary">
          <span className="text-secondary-dark">{connections?.count}</span>
          {" Connections"}
        </h2>
        <hr className="bg-secondary mt-2" />

        <ul className="list-unstyled d-flex flex-column">
          <li className="mb-2">
            <div className="d-flex flex-nowrap mx-n2">
              <span className="px-2 ff-base text-secondary-dark">Gmail</span>
              <span className="px-2 ml-auto">
                {/* Show when connected */}
                {providersConnections.google ?
                  <span className="text-secondary-dark fs-14">
                    Connected
                  <i className="tick-mark ml-1"></i>
                  </span>
                  :
                  <Link
                  to="myprofile?active_tab=friends"
                  className="text-secondary font-weight-bold fs-14"
                >
                  Connect Now
                </Link>
                }
              </span>
            </div>
          </li>
          {/* <li className="mb-2">
            <div className="d-flex flex-nowrap mx-n2">
              <span className="px-2 ff-base text-secondary-dark">Facebook</span>
              <span className="px-2 ml-auto">
                {providersConnections.facebook ?
                  <span className="text-secondary-dark fs-14">
                    Connected
                  <i className="tick-mark ml-1"></i>
                  </span>
                  :
                  <Link
                  to="myprofile?active_tab=friends"
                  className="text-secondary font-weight-bold fs-14"
                >
                  Connect Now
                </Link>
                }
              </span>
            </div>
          </li>
          <li className="mb-2">
            <div className="d-flex flex-nowrap mx-n2">
              <span className="px-2 ff-base text-secondary-dark">Twitter</span>
              <span className="px-2 ml-auto">
                {providersConnections.twitter ?
                  <span className="text-secondary-dark fs-14">
                    Connected
                  <i className="tick-mark ml-1"></i>
                  </span>
                  :
                  <Link
                  to="myprofile?active_tab=friends"
                  className="text-secondary font-weight-bold fs-14"
                >
                  Connect Now
                </Link>
                }
              </span>
            </div>
          </li> */}
        </ul>
        <div className="text-left">
          <Link
            to="myprofile?active_tab=friends"
            target="_blank"
            className="text-secondary-dark font-weight-bold fs-14"
          >
            view connections
          </Link>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  providers_on_wikireviews: state.connections.providers_on_wikireviews,
});

const mapProps = (dispatch) => ({
  enable_fiend_friends_tab: ({ status, param }) =>    dispatch(enable_fiend_friends_tab({ status, param })),
  get_providers_on_wikireviews: () => dispatch(get_providers_on_wikireviews())
});

export default withRouter(connect(mapState, mapProps)(Connections));
