import React, { Component } from 'react'
import { my_profile } from "../../../actions/user";
import { get_community_review_projects } from "../../../actions/communityReviewProjects";
import { get_reviews } from "../../../actions/review";
import { get_contributions } from "../../../actions/contributions";
import { callApi } from "../../../utils/apiCaller";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

class MyStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myProfileInfo: null,
            projectsCollapse: false,
            reviewsCollapse: false,
            contributionsCollapse: false
        }
    }

    componentDidMount() {
        callApi(`/api/myprofile/?q=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                this.props.get_community_review_projects(`/api/projects/?created_by__id=${response.id}&page=1`);
                this.props.get_reviews(`/api/reviews/?entry=&user=${response.id}&asset=&home_page=true`);
                this.props.get_contributions('me', 1);
                this.setState({
                    myProfileInfo: response
                });
            }
        });
    }
    componentWillReceiveProps(nextProps) {
        const { community_review_projects_data, reviews_data, contributions_data } = nextProps;

        const community_reviews = community_review_projects_data?.results?.length > 0 ? community_review_projects_data?.results.filter((project, i) => i <= 3 && project.is_active) : [];
        const reviews = reviews_data?.results?.length > 0 ? reviews_data?.results?.filter((item, i) => i <= 3) : [];
        const contributions = contributions_data?.results?.length > 0 ? contributions_data.results.filter((item, i) => i <= 3) : [];

        this.setState({
            projectsCollapse: community_reviews.length ? true : false,
            reviewsCollapse: reviews.length ? true : false,
            contributionsCollapse: contributions.length ? true : false,
        })
    }
    
    toggleCollapse = (type) => {
        const value = !this.state[type];
        this.setState({
            [type]: value
        });
    }

    renderVerb = (item) => {
        let html;
        if (item.verb.includes('Commented on status')) {
            html = <div>{item.verb}
                <Link target="_blank" to={`/people/${item.actor.username}`} className="text-secondary-dark font-weight-bold"> {item.target && item.target.name ? item.target.name : `${item.actor.first_name} ${item.actor.last_name}'s`}</Link> Post
                    </div>
        } else if (item.verb.includes('Updated his profile')) {
            html = <div>Updated his profile picture</div>
        } else if (item.target && item.target.name) {
            html = <div>{item.verb}<Link to={{
                pathname: `/${item.target.category.toLowerCase()}/${item.target.slug ? item.target.slug : 'null'}`,
                state: { id: item.target.id }
            }} className="text-secondary-dark font-weight-bold"> {item.target.name}</Link></div>
        } else {
            html = <div>{item.verb}</div>
        }
        return html;
    }

    redirectToSlugUrl = (item) => {
        if (item?.redirect_link) {
            let id = (item?.listing_id).toString();
            let splitItems = item.redirect_link.split('/');
            let filteredItems = [];
            splitItems.forEach(item => {
                if (item !== '' && item !== id) {
                    filteredItems.push(item);
                }
            })

            if (filteredItems?.length) {
                filteredItems = filteredItems.filter(item => item !== undefined).join('/');
            }

            return ({
                pathname: filteredItems,
                state: { id }
            })
        }
    }

    render() {
        const { myProfileInfo, projectsCollapse, reviewsCollapse, contributionsCollapse } = this.state;
        const { reviews_data, community_review_projects_data, contributions_data } = this.props;
        
        return (
            <div className="bg-white-shaded-md p-3 mb-3">
                <h2 className="text-secondary">{'My Statistics'}</h2>
                <hr className="bg-secondary mt-2" />

                {/* Projects Collapse */}
                <div>
                    {/* Collapse heading */}
                    <div className="mb-3">
                        <div className="d-flex mx-n2">
                            <div className="px-2">
                                <span className="text-secondary ff-headings fs-38 d-block" style={{ width: '50px', lineHeight: '1.2' }}>
                                    {myProfileInfo?.statistics?.crp}
                                </span>
                            </div>
                            <div className="px-2 flex-grow-1">
                                <div className="d-flex flex-nowrap mx-n1">
                                    <div className="px-1 flex-grow-1">
                                        <span className="text-secondary">
                                            Projects uploaded in <span className="font-weight-bold">
                                                Community Review Projects
                                            </span>
                                        </span>
                                    </div>
                                    <div className="px-1">
                                        <span className="icon-plus2" title="Expand" hidden={projectsCollapse} onClick={() => this.toggleCollapse('projectsCollapse')}></span>
                                        <span className="icon-minus2" title="Collapse" hidden={!projectsCollapse} onClick={() => this.toggleCollapse('projectsCollapse')}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Collapse body */}
                    <div hidden={!projectsCollapse}>
                        <ul className="list-unstyled text-secondary">
                            {community_review_projects_data?.results?.length > 0 && community_review_projects_data?.results.filter((project, i) => i <= 3 && project.is_active).map((project) => {
                                return (
                                    <li className="mb-3" key={project.id}>
                                        <div className="d-flex flex-wrap mx-n2">
                                            <div className="px-2">
                                                <a href="#" className="text-decoration-none">
                                                    {project?.main_images[0]?.url ?
                                                        <img src={project.main_images[0]?.url} alt="" width={50} height={50} onError={(error) =>
                                                            (error.target.src = require("../../../assets/images/icons/placeholder-img.png"))} />
                                                        :
                                                        <img src={require("../../../assets/images/icons/placeholder-img.png")} alt="" width={50} height={50} style={{ objectFit: 'cover', objectPosition: 'center center' }} />
                                                    }

                                                </a>
                                            </div>

                                            <div className="px-2">
                                                <div className="fs-14 ff-base">
                                                    {project.title}
                                                    {project.is_draft && (
                                                        <span> [DRAFT]</span>
                                                    )}
                                                </div>
                                                <div className="fs-14 ff-base">
                                                    {project.sent_at}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mb-0 mt-2" />
                                    </li>
                                )
                            })}
                        </ul>

                        <div className="text-right">
                            <Link to={
                                {
                                    key: '1',
                                    pathname: '/myprofile',
                                    search: '?active_tab=crp',
                                    state: {
                                    }
                                }
                            }>
                                <a className="text-secondary font-weight-bold">view all</a>
                            </Link>
                        </div>
                    </div>

                    <hr className="bg-secondary" />
                </div>

                {/* Reviews Collapse */}
                <div>
                    {/* Collapse heading */}
                    <div className="mb-3">
                        <div className="d-flex mx-n2">
                            <div className="px-2">
                                <span className="text-secondary ff-headings fs-38 d-block" style={{ width: '50px', lineHeight: '1.2' }}>
                                    {myProfileInfo?.statistics?.reviews}
                                </span>
                            </div>
                            <div className="px-2 flex-grow-1">
                                <div className="d-flex flex-nowrap mx-n1">
                                    <div className="px-1 flex-grow-1">
                                        <span className="text-secondary font-weight-bold">
                                            Reviews Written
                                        </span>
                                    </div>
                                    <div className="px-1">
                                        {/* hidden={projectsCollapse} */}
                                        <span className="icon-plus2" title="Expand" hidden={reviewsCollapse} onClick={() => this.toggleCollapse('reviewsCollapse')}></span>
                                        {/* hidden={!projectsCollapse} */}
                                        <span className="icon-minus2" title="Collapse" hidden={!reviewsCollapse} onClick={() => this.toggleCollapse('reviewsCollapse')}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Collapse body */}
                    <div hidden={!reviewsCollapse}>
                        <ul className="list-unstyled text-secondary">
                            {reviews_data?.results?.length > 0 && reviews_data?.results?.filter((item, i) => i <= 3).map(item => {
                                return (
                                    <li className="mb-3" key={item.id}>
                                        <div className="d-flex flex-nowrap mx-n1">
                                            <div className="px-1 mt-1 mb-2">
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Link to={this.redirectToSlugUrl(item)} className="text-decoration-none">
                                                            {(item.listing_profileimage && item.listing_profileimage[0]?.url) ?
                                                                <img src={item.listing_profileimage[0].url} alt="" width={50} height={50} onError={(error) =>
                                                                    (error.target.src = require("../../../assets/images/icons/placeholder-img.png"))} />
                                                                :
                                                                <img src={require("../../../assets/images/icons/placeholder-img.png")} alt="" width={50} height={50} style={{ objectFit: 'cover', objectPosition: 'center center' }} />
                                                            }

                                                        </Link>
                                                    </div>

                                                    <div className="px-2">
                                                        <div className="font-weight-bold fs-14 ff-base">
                                                            <Link to={this.redirectToSlugUrl(item)} className="text-reset">{item.listing_name}</Link>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap mx-n1">
                                                            <div className="px-1">
                                                                {/* <img
                                                                    title={item.ratings_sets.avg_rating[1]}
                                                                    src={item.ratings_sets.avg_rating[1] !== 'No Rating' ?
                                                                        require(`../../../assets/images/icons/star/gold/${Math.floor(item.ratings_sets.avg_rating[1])}rating.png`) :
                                                                        require('../../../assets/images/icons/star/gold/norating.png')}
                                                                    alt=""
                                                                /> */}
                                                            </div>
                                                            <div className="px-1 fs-14 ff-alt">
                                                                {item.review_date_time}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mb-0 mt-1" />
                                    </li>
                                )
                            })}
                        </ul>

                        <div className="text-right">
                            <Link to={
                                {
                                    key: '1',
                                    pathname: '/myprofile',
                                    search: '?active_tab=reviews',
                                    state: {
                                    }
                                }
                            }>
                                <a className="text-secondary font-weight-bold">view all</a>
                            </Link>
                        </div>
                    </div>

                    <hr className="bg-secondary" />
                </div>

                {/* Contributions Collapse */}
                <div>
                    {/* Collapse heading */}
                    <div className="mb-3">
                        <div className="d-flex mx-n2">
                            <div className="px-2">
                                <span className="text-secondary ff-headings fs-38 d-block" style={{ width: '50px', lineHeight: '1.2' }}>
                                    {myProfileInfo?.statistics?.contri}
                                </span>
                            </div>
                            <div className="px-2 flex-grow-1">
                                <div className="d-flex flex-nowrap mx-n1">
                                    <div className="px-1 flex-grow-1">
                                        <span className="text-secondary font-weight-bold">
                                            Contributions Made
                                        </span>
                                    </div>
                                    <div className="px-1">
                                        {/* <Button color="outline-secondary btn-circle" size="md" title="Expand" ><FontAwesomeIcon icon="plus" /> </Button>
                                        <Button color="outline-secondary btn-circle" size="md" title="Collapse"><FontAwesomeIcon icon="minus" /> </Button> */}
                                        <span className="icon-plus2" title="Expand" hidden={contributionsCollapse} onClick={() => this.toggleCollapse('contributionsCollapse')}></span>
                                        <span className="icon-minus2" title="Collapse" hidden={!contributionsCollapse} onClick={() => this.toggleCollapse('contributionsCollapse')}></span>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="d-flex mx-n2">
                                        <span className="ff-headings fs-30 px-2 text-secondary d-block">
                                            {myProfileInfo?.award_statistics?.next_avatar_count}
                                        </span>
                                        <div className="px-2 mt-1">
                                            <a href="#" className="text-secondary">
                                                <div className="d-flex flex-nowrap">
                                                    <div className="ff-alt fs-12 text-secondary mr-3">
                                                        <i>more for <span className="font-weight-bold d-block">{myProfileInfo?.award_statistics?.next_avatar_name} avatar</span> </i>
                                                    </div>
                                                    <div className="mt-2">
                                                        <img src={require("../../../assets/images/icons/achievement.png")} alt="" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Collapse Body */}
                    <div hidden={!contributionsCollapse}>
                        <ul className="list-unstyled">
                            {contributions_data?.results?.length > 0 && contributions_data.results.filter((item, i) => i <= 3).map(item => {
                                return (
                                    <li className="mb-3" key={item.id}>
                                        <div className="d-flex flex-nowrap mx-n1">
                                            <div className="px-1 mt-1 mb-2">
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <a href="#" className="text-decoration-none">
                                                            {item.target?.profile_image?.length > 0 && item.target?.profile_image[0]?.url ?

                                                                <img src={item.target?.profile_image[0]?.url} alt="" width={50} height={50} style={{ objectFit: 'cover', objectPosition: 'center center' }} onError={(error) =>
                                                                    (error.target.src = require("../../../assets/images/icons/placeholder-img.png"))} />

                                                                :
                                                                <img src={require("../../../assets/images/icons/placeholder-img.png")} alt="" width={50} height={50} style={{ objectFit: 'cover', objectPosition: 'center center' }} />
                                                            }
                                                        </a>
                                                    </div>

                                                    <div className="px-2">
                                                        <div className="ff-base fs-14 text-secondary">
                                                            {this.renderVerb(item)}
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap mx-n1">
                                                            {item.target?.avg_rating && (
                                                                <div className="px-1">
                                                                    <img title={item.target.avg_rating[1]} src={item.target.avg_rating[1] !== 'No Rating' ? require(`../../../assets/images/icons/star/gold/${Math.floor(item.target.avg_rating[1])}rating.png`) : require('../../../assets/images/icons/star/gold/norating.png')} alt="" />
                                                                </div>
                                                            )}

                                                            <div className="px-1 fs-14 ff-alt text-secondary-dark">
                                                                {item.time_stamp}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="px-2">
                                                        <div className="fs-14 ff-base">
                                                            <span>
                                                                Followed me
                                                                &nbsp;
                                                            <a href="#" className="text-secondary-dark font-weight-bold">Beatrice  Straight</a>
                                                            &nbsp;
                                                            as a favorite actor
                                                        </span>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap mx-n1">
                                                            <div className="px-1">
                                                                <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/gold/norating.png" alt="" />
                                                            </div>
                                                            <div className="px-1 fs-14 ff-alt text-secondary-dark">
                                                                3 days ago
                                                        </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mb-0 mt-1" />
                                    </li>
                                )
                            })}
                        </ul>

                        <div className="text-right">
                            <Link to={
                                {
                                    key: '1',
                                    pathname: '/myprofile',
                                    search: '?active_tab=contributions',
                                    state: {
                                    }
                                }
                            }>
                                <a className="text-secondary font-weight-bold">view all</a>
                            </Link>
                        </div>
                    </div>

                    <hr className="bg-secondary" />
                </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    my_profile_info: state.user.my_profile,
    community_review_projects_data: state.communityReviewProjects.community_review_projects_data,
    reviews_data: state.reviews.reviews_data,
    contributions_data: state.contributions.contributions_data,
});

const mapDispatch = (dispatch) => ({
    my_profile: () => dispatch(my_profile()),
    get_community_review_projects: (url) => dispatch(get_community_review_projects(url)),
    get_reviews: (url) => dispatch(get_reviews(url)),
    get_contributions: (actor, page) => dispatch(get_contributions(actor, page)),
});

export default connect(mapState, mapDispatch)(MyStatistics);
