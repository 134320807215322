/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Row, Col, Button, Form, Input, Collapse, } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Actors from "../../molecules/dashboard/actors";
import MyEducation from "../../molecules/dashboard/myeducation";
import WorkHistory from "../../molecules/dashboard/workhistory";
import { API_URL, badgesReference } from "../../../utils/constants";
import { Helmet } from "react-helmet";
import MyFavorites from '../../molecules/dashboard/myfavorites';
import Chart from "react-google-charts";
import { callApi, responseCodeValidator } from "../../../utils/apiCaller";
import { toast } from "react-toastify";
import EditBtn from '../../atoms/editBtn';
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import { updatePhoneNumber } from '../../../actions/user';

class ProfileTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            number_part_1: "",
            number_part_2: "",
            number_part_3: "",
            errors: {},
            reviewStatisticsData: [],
            demographicReviewStatistics: [],
            contributionStatistics: []
        };

        this.homeTheaterRef = React.createRef();
        this.usernameRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutsideUsername);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('mousedown', this.handleClickOutsideUsername);
    }

    handleChange = (e) => {
        let { name, value } = e.target;

        var a = document.getElementById("a"),
            b = document.getElementById("b"),
            c = document.getElementById("c");

        // a.onkeyup = function () {
        //     if (this.value.length === parseInt(this.attributes["maxlength"].value)) {
        //         b.focus();
        //     }
        // }
        // b.onkeyup = function () {
        //     if (this.value.length === parseInt(this.attributes["maxlength"].value)) {
        //         c.focus();
        //     }
        // }

        if (name == "number_part_1") {
            if (e.target.value.length <= 3) {
                this.setState({ [name]: value })

            }
            if (e.target.value.length === 3 || e.target.value.length > 3) {

                ReactDOM.findDOMNode(this.nextComponent).focus();
            }
        }

        if (name == "number_part_2") {
            if (e.target.value.length <= 3) {
                this.setState({ [name]: value })

            }

            if (e.target.value.length === 3 || e.target.value.length > 3) {
                ReactDOM.findDOMNode(this.nextComponent3).focus();
            }
        }

        if (name == "number_part_3") {
            if (e.target.value.length <= 4) {
                this.setState({ [name]: value })

            }
            if (e.target.value.length === 4) {
                ReactDOM.findDOMNode(this.nextComponent3).blur();
            }
        }







    }

    handleClickOutside = (event) => {
        if (this.props.isFieldShown && this.props.isFieldShown.theater && this.props.handleClickOutSideTheater && this.homeTheaterRef.current && !this.homeTheaterRef.current.contains(event.target)) {
            this.props.handleClickOutSideTheater();
        }
    }

    handleClickOutsideUsername = (event) => {
        if (this.props.isFieldShown && this.props.isFieldShown.username && this.props.handleClickOutSideUsername && this.usernameRef.current && !this.usernameRef.current.contains(event.target)) {
            this.props.handleClickOutSideUsername();
        }
    }

    // add Favorite
    handleAddPhone = () => {
        let errors = { ...this.state.errors };
        if (this.state.number_part_1 == '' && this.state.number_part_2 == '' && this.state.number_part_3 == '') {
            errors.phone_number = 'This field is required.';
        } else {
            delete errors.phone_number;
        }
        if (Object.keys(errors).length > 0) {
            this.setState(prevState => ({
                ...prevState,
                errors: errors
            }));
        } else {
            let data = {
                ph1: this.state.number_part_1,
                ph2: this.state.number_part_2,
                ph3: this.state.number_part_3,
            };
            this.props.updatePhoneNumber(data).then((response) => {
                if (response) {
                    if (response.status == false) {
                        errors.phone_number = response.msg;
                        this.setState(prevState => ({
                            ...prevState,
                            errors: errors
                        }));
                    } else {
                        errors.phone_number = '';
                        this.setState(prevState => ({
                            ...prevState,
                            errors: errors
                        }));
                    }
                    //return dispatch(get_my_posts_list(pageNo));
                }
            });
            this.setState({ number_part_1: '', number_part_2: '', number_part_3: '' });
        }
    }

    getPublicProfileURL = () => {
        const { myProfile } = this.props;
        return `${window.location.protocol}//${window.location.host}/people/${myProfile && myProfile.user && myProfile.user.username ? myProfile.user.username : ''}`;
    }

    getAddress = (address) =>{
        if(!address) return ''

        const {address1, address2, city, state, zipcode, country} = address;
        const fullAddress = [];
        address1 && fullAddress.push(address1);
        address2 && fullAddress.push(address2);    
        city && fullAddress.push(city);    
        state && fullAddress.push(state);    
        if(country && country === "usa") fullAddress.push("United States");
        if(country && country === "can") fullAddress.push("Canada");
        
        zipcode && fullAddress.push(zipcode);   

        return !fullAddress.length ? "No address found" : fullAddress.join(', ');
    }

    compareContributionStatistics = (newData, currentData) => {
        currentData = currentData.slice(1);
        let filteredNewData = newData.map((item) => item[1])
        let filteredCurrentData = currentData.map((item) => item[1])
        return JSON.stringify(filteredNewData) === JSON.stringify(filteredCurrentData)
    }

    compareReviewStatistics = (newData, oldData) => {
        let tempReviewStatisticsData = JSON.parse(JSON.stringify(newData));
        if (tempReviewStatisticsData !== undefined && tempReviewStatisticsData.length === 10) {
            for (let i = 0; i < tempReviewStatisticsData.length; i++) {
                tempReviewStatisticsData[i][3] = tempReviewStatisticsData[i][1]
                tempReviewStatisticsData[i][0] = parseFloat(tempReviewStatisticsData[i][0]).toString() + '\u2605';
                tempReviewStatisticsData[i][4] = `${parseFloat(tempReviewStatisticsData[i][0]).toString() + '\u2605'} \n Rates Scale : ${tempReviewStatisticsData[i][3]}`;

            }
        }
        oldData = oldData.slice(1)
        return JSON.stringify(tempReviewStatisticsData) === JSON.stringify(oldData)
    }

    compareDemographicStatistics = (newData, currentData) => {
        let filteredNewData = newData.map((item) => item[1])
        let filteredCurrentData = currentData.map((item) => item[1])
        return JSON.stringify(filteredNewData) === JSON.stringify(filteredCurrentData)
    }

    render() {
        const {
            myProfile,
            fetchMyProfile,
            isFieldShown,
            searchTheater,
            showEditCollapse,
            hideEditCollapse,
            search_theater_info,
            handleOnChangeMyProfile,
            handleOnSaveMyProfile,
            handleOnCancelUpdateProfile,
            handleOnChangeSearchTheater,
            handleCheckAlbumType,
            myScrollPhotoRef,
            handlePhotoScroll,
            renderPhotos,
            myScrollVideoRef,
            handleVideoScroll,
            renderVideos,
            toggleUploadMediaModal,
            toggleDeletModal,
            addNewPhotoClick,
            deletePhotoClick,
            changePassword,
            myInfoError,
            searchUsername,
            search_username_info,
            handleOnChangeSearchUsername,
            myProfileError,
            subMainViewType
        } = this.props;

        const {
            number_part_1,
            number_part_2,
            errors,
            number_part_3,
            reviewStatisticsData,
            demographicReviewStatistics,
            contributionStatistics
        } = this.state
      
        if (myProfile && myProfile != 'null') {
            // get ReviewStats made 1st Chart Data
            if (!this.compareReviewStatistics(myProfile?.review_statistics?.stats, reviewStatisticsData) && myProfile.review_statistics && myProfile.review_statistics.stats) {
                let tempReviewStatisticsData = JSON.parse(JSON.stringify(myProfile.review_statistics.stats));
                if (tempReviewStatisticsData !== undefined && tempReviewStatisticsData.length === 10) {
                    for (let i = 0; i < tempReviewStatisticsData.length; i++) {
                        tempReviewStatisticsData[i][3] = tempReviewStatisticsData[i][1]
                        tempReviewStatisticsData[i][0] = parseFloat(tempReviewStatisticsData[i][0]).toString() + '\u2605';
                        tempReviewStatisticsData[i][4] = `${parseFloat(tempReviewStatisticsData[i][0]).toString() + '\u2605'} \n Rates Scale : ${tempReviewStatisticsData[i][3]}`;

                    }
                    tempReviewStatisticsData.splice(0, 0, ["", "Rates Scale", { role: 'style' }, { role: 'annotation' }, { type: 'string', role: 'tooltip' }]);
                    this.setState({ reviewStatisticsData: tempReviewStatisticsData })
                }
                // removed console from here.
            }

            // get demographics made 2nd Chart Data
            if (myProfile.review_statistics && myProfile.review_statistics.demographics) {
                let newdemographicsGraph = JSON.parse(JSON.stringify(myProfile.review_statistics.demographics));
                let professionalCategories = ['Doctors', 'Accountants', 'Lawyers', 'Dentists'];
                const professionalsCount = newdemographicsGraph.filter((categoryData) => professionalCategories.includes(categoryData[0])).reduce((total, singleCat) => {
                    return total + singleCat[1]
                }, 0)
                newdemographicsGraph = newdemographicsGraph.filter((categoryData) => !professionalCategories.includes(categoryData[0]))
                newdemographicsGraph.push(["Professionals", professionalsCount, professionalsCount])
                if (newdemographicsGraph !== undefined && newdemographicsGraph.length === 5) {
                    for (let i = 0; i < newdemographicsGraph.length; i++) {
                        newdemographicsGraph[i][2] = newdemographicsGraph[i][1]

                    }
                    newdemographicsGraph.splice(0, 0, ["Category", "", { role: 'annotation' }]);
                }

                if (!this.compareDemographicStatistics(newdemographicsGraph, demographicReviewStatistics)) {
                    this.setState({
                        demographicReviewStatistics: newdemographicsGraph
                    })
                }
            }
            
            // get Contributions made 4th Chart Data
            if (!this.compareContributionStatistics(myProfile?.statistics?.contribution_stats, contributionStatistics) && myProfile.statistics && myProfile.statistics.contribution_stats) {
                let newContributionGraph = JSON.parse(JSON.stringify(myProfile.statistics.contribution_stats));
                if (newContributionGraph !== undefined && newContributionGraph.length === 5) {
                    for (let i = 0; i < newContributionGraph.length; i++) {
                        newContributionGraph[i][2] = newContributionGraph[i][1]

                    }
                    newContributionGraph.splice(0, 0, ["Contribution", "", { role: 'annotation' }]);
                
                    this.setState({contributionStatistics: newContributionGraph})
                }
            }
        }

        return (
            <div>
                <Helmet>
                    <title>{myProfile && myProfile.user && myProfile.user.first_name && `${myProfile.user.first_name.charAt(0).toUpperCase() + myProfile.user.first_name.slice(1)} ${myProfile.user.last_name && myProfile.user.last_name.charAt(0).toUpperCase() + myProfile.user.last_name.slice(1)} Private Profile on WikiReviews`}</title>
                </Helmet>
                <Row>
                    <Col xs={12} className="flex-lg-350 wk-myprofile-left-tab">
                        {
                            !myProfile?.mobile_no && <div className="bg-primary text-white p-3 mb-3">
                                <h2>{'Add your phone number to authenticate your account.'}</h2>
                                <hr className="bg-white mt-2" />

                                <Row className="container" form>
                                    <Col xs={3}>
                                        <Input className="primary" id="a" min="0" max="999" bsSize="sm" type="number" maxLength="3" name="number_part_1" placeholder="(555)" value={number_part_1} ref={c => this.nextComponent1 = c} onChange={this.handleChange} />
                                    </Col>
                                    <Col xs={3}>
                                        <Input className="primary" bsSize="sm" min="0" max="999" id="b" type="number" maxLength="3" name="number_part_2" placeholder="555" value={number_part_2} ref={c => this.nextComponent = c} onChange={this.handleChange} />
                                    </Col>
                                    <Col xs={6}>
                                        <Input className="primary" bsSize="sm" min="0" max="9999" id="c" type="number" maxLength="4" name="number_part_3" placeholder="5555" value={number_part_3} ref={d => this.nextComponent3 = d} onChange={this.handleChange} />
                                    </Col>
                                    {errors && errors.phone_number && (
                                        <div className="text-danger small mt-1">
                                            {errors.phone_number}
                                        </div>
                                    )}
                                </Row>

                                <div className="text-right mt-3">
                                    <Button color="text-dark rounded" size="sm" disabled={
                                        this.state.number_part_1.length == 3 &&
                                            this.state.number_part_2.length == 3 &&
                                            this.state.number_part_3.length == 4
                                            ? false
                                            : true
                                    } onClick={this.handleAddPhone}>Authenticate</Button>
                                </div>
                            </div>
                        }
                        
                        <div className="bg-white-shaded-md px-3 py-4 mb-3 step-4_myProfile">
                            <h2 className="text-secondary">{'My info'}</h2>
                            <hr className="bg-secondary" />

                            <div className="text-secondary-dark fs-14"> {/* My Info starts */}
                                <div> {/* Field Container */}
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">{'First Name:'}</span>
                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['fName']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                    {myProfile && myProfile.user && myProfile.user.first_name ? myProfile.user.first_name : ''}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">
                                                            <EditBtn onClick={() => showEditCollapse('fName')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['fName']}>
                                        <Form>
                                            <div className="mb-2">
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="first_name"
                                                    placeholder="e.g. John"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.user && myProfile.user.first_name ? myProfile.user.first_name : ''}
                                                    onChange={handleOnChangeMyProfile('First Name')}
                                                />
                                                {myInfoError.first_name && (
                                                    <div className="text-danger small mt-1">
                                                        {myInfoError.first_name}
                                                    </div>
                                                )}
                                                <div className="text-right">
                                                    <Button
                                                        disabled={myInfoError?.first_name ? true : false}
                                                        size="sm" color="primary mr-2" onClick={() => handleOnSaveMyProfile('First Name')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('First Name')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>


                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">Last Name:</span>

                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['lName']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                    {myProfile && myProfile.user && myProfile.user.last_name ? myProfile.user.last_name : ''}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">

                                                            <EditBtn onClick={() => showEditCollapse('lName')} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['lName']}>
                                        <Form>
                                            <div className="mb-2">
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="last_name"
                                                    placeholder="e.g. Smith"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.user && myProfile.user.last_name ? myProfile.user.last_name : ''}
                                                    onChange={handleOnChangeMyProfile('Last Name')}
                                                />
                                                {myInfoError.last_name && (
                                                    <div className="text-danger small mt-1">
                                                        {myInfoError.last_name}
                                                    </div>
                                                )}
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2"
                                                        disabled={myInfoError?.last_name ? true : false}
                                                        onClick={() => handleOnSaveMyProfile('Last Name')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('Last Name')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>

                                {/* Field Container */}
                                <div>
                                    {/* <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">Home Theater:</span>

                                        <span className="flex-grow-1 ff-alt step-13_myProfile" hidden={isFieldShown['theater']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                    {myProfile && myProfile.home_theater_list && myProfile.home_theater_list.theater_name ? myProfile.home_theater_list.theater_name : 'SET HOME THEATRE'}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">
                                                            <Button
                                                                size="sm" color="dark" title="Edit" onClick={() => showEditCollapse('theater')}>
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button>
                                                            <EditBtn onClick={() => showEditCollapse('theater')} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div> */}

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['theater']}>
                                        <Form>
                                            <div className="mb-2 position-relative" ref={this.homeTheaterRef}>
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="theater"
                                                    placeholder="Enter Zip Code or Name"
                                                    value={searchTheater}
                                                    onChange={handleOnChangeSearchTheater}
                                                    autoComplete='off'
                                                />
                                                <ul
                                                    className="list-unstyled text-dark instant-results bg-white w-100 mt-n2 shadow"
                                                    hidden={searchTheater && search_theater_info && search_theater_info.length > 0 ? false : true}
                                                    style={{ maxHeight: '400px', overflowY: 'auto' }}>

                                                    {searchTheater && search_theater_info && search_theater_info.length > 0 &&
                                                        search_theater_info.map(item => {
                                                            return (
                                                                <li
                                                                    className="result-item"
                                                                    role="button"
                                                                    key={item.id}
                                                                    onClick={() => handleOnSaveMyProfile('Home Theater', item.id)}>
                                                                    <div className="result-link fs-12">
                                                                        <strong>{item.name}</strong>
                                                                        <br />
                                                                        {item.theater_location}
                                                                        <br />
                                                                        {item.theater_zip}, {item.theater_city}, {item.theater_state}
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                </ul>
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={() => handleOnSaveMyProfile('Home Theater')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('Home Theater')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">User Name:</span>

                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['username']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1 wb-break-word">
                                                    {myProfile && myProfile.user && myProfile.user.username ? myProfile.user.username : ''}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">

                                                            {/* <Button
                                                                size="sm" color="dark" title="Edit" onClick={() => showEditCollapse('username')}>
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button> */}
                                                            <EditBtn onClick={() => showEditCollapse('username')} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['username']}>
                                        <Form>
                                            <div className="mb-2" ref={this.usernameRef}>
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="username"
                                                    value={searchUsername || searchUsername == '' ? searchUsername : (myProfile && myProfile.user && myProfile.user.username ? myProfile.user.username : '')}
                                                    //value={searchUsername ? searchUsername : ''}
                                                    onChange={handleOnChangeSearchUsername}
                                                    placeholder="Search Username"
                                                    autoComplete='off'

                                                />
                                                {myProfileError && myProfileError.username && (
                                                    <div className="text-danger small mt-1">
                                                        {myProfileError.username}
                                                    </div>
                                                )}
                                                <ul
                                                    className="list-unstyled text-dark instant-results bg-white w-100 mt-n2 shadow"
                                                    hidden={searchUsername && search_username_info && search_username_info.length > 0 ? false : true}
                                                    style={{ maxHeight: '400px', overflowY: 'auto' }}>

                                                    {searchUsername && search_username_info && search_username_info.length > 0 &&
                                                        search_username_info.map(item => {
                                                            return (
                                                                <li
                                                                    className="result-item"
                                                                    role="button"
                                                                    key={item.id}
                                                                    onClick={() => handleOnSaveMyProfile('Username', item.id)}>
                                                                    <div className="result-link fs-12">
                                                                        <strong>{item.name}</strong>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                </ul>
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" disabled={searchUsername == '' ? true : false} onClick={() => handleOnSaveMyProfile('Username')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('Username')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">Public Profile:</span>

                                        <span className="flex-grow-1 ff-alt">
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                    <Link to={{ pathname: this.getPublicProfileURL() }} style={{ wordBreak: 'break-word' }} target="_blank">
                                                        {this.getPublicProfileURL()}
                                                    </Link>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">Joined:</span>

                                        <span className="flex-grow-1">
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                    <span className="font-weight-bold">
                                                        {myProfile && myProfile.joining_date_str && myProfile.joining_date_str ? myProfile.joining_date_str : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">Lives in:</span>

                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['address']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                    {this.getAddress(myProfile?.present_address)}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">
                                                            {/* <Button
                                                                size="sm" color="dark" title="Edit" >
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button> */}
                                                            <EditBtn onClick={() => showEditCollapse('address')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['address']}>
                                        <Form>
                                            <div className="mb-2">
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="address1"
                                                    value={myProfile && myProfile.present_address && myProfile.present_address.address1 ? myProfile.present_address.address1 : ''}
                                                    placeholder="Address 1"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    onChange={handleOnChangeMyProfile('Address')}
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="address2"
                                                    placeholder="Address 2"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.present_address && myProfile.present_address.address2 ? myProfile.present_address.address2 : ''}
                                                    onChange={handleOnChangeMyProfile('Address')}
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="city"
                                                    placeholder="City"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.present_address && myProfile.present_address.city ? myProfile.present_address.city : ''}
                                                    onChange={handleOnChangeMyProfile('Address')}
                                                />
                                                {myInfoError.city && (
                                                    <div className="text-danger small mt-1">
                                                        {myInfoError.city}
                                                    </div>
                                                )}
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="state"
                                                    placeholder="State"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.present_address && myProfile.present_address.state ? myProfile.present_address.state : ''}
                                                    onChange={handleOnChangeMyProfile('Address')}
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="select"
                                                    name="country"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    onChange={handleOnChangeMyProfile('Address')}
                                                >
                                                    <option value="usa" selected={myProfile && myProfile.present_address && myProfile.present_address.country === 'usa'}>United States</option>
                                                    <option value="can" selected={myProfile && myProfile.present_address && myProfile.present_address.country === 'can'}>Canada</option>
                                                </Input>
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="zipcode"
                                                    maxLength={7}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.present_address && myProfile.present_address.zipcode ? myProfile.present_address.zipcode : ''}
                                                    placeholder="Zipcode"
                                                    onChange={handleOnChangeMyProfile('Address')}
                                                />
                                                {myInfoError.zipCode && (
                                                    <div className="text-danger small mt-1">
                                                        {myInfoError.zipCode}
                                                    </div>
                                                )}
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={() => handleOnSaveMyProfile('Address')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('Address')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">Gender:</span>

                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['gender']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                    {myProfile && myProfile.gender && myProfile.gender === 'm' ? 'Male' : ''}
                                                    {myProfile && myProfile.gender && myProfile.gender === 'f' ? 'Female' : ''}
                                                    {myProfile && myProfile.gender && myProfile.gender === 'n' ? 'Prefer not to say' : ''}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">
                                                            {/* <Button
                                                                size="sm" color="dark" title="Edit" onClick={() => showEditCollapse('gender')}>
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button> */}
                                                            <EditBtn onClick={() => showEditCollapse('gender')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['gender']}>
                                        <Form>
                                            <div className="mb-2">
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="select"
                                                    name="gender"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    onChange={handleOnChangeMyProfile('Gender')}
                                                >
                                                    <option value="m" selected={myProfile && myProfile.gender && myProfile.gender === 'm'}>Male</option>
                                                    <option value="f" selected={myProfile && myProfile.gender && myProfile.gender === 'f'}>Female</option>
                                                    <option value="n" selected={myProfile && myProfile.gender && myProfile.gender === 'n'}>Prefer Not To Say</option>
                                                </Input>
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={() => handleOnSaveMyProfile('Gender')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('Gender')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">Age:</span>

                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['age']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                    {myProfile && myProfile.age && myProfile.age ? myProfile.age : ''}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">
                                                            {/* <Button
                                                                size="sm" color="dark" title="Edit" onClick={() => showEditCollapse('age')}>
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button> */}
                                                            <EditBtn onClick={() => showEditCollapse('age')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['age']}>
                                        <Form>
                                            <div className="mb-2">
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="date"
                                                    name="dob"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.dob && myProfile.dob ? myProfile.dob : ''}
                                                    onChange={handleOnChangeMyProfile('Date Of Birth')}
                                                />
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={() => handleOnSaveMyProfile('Date Of Birth')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('Date Of Birth')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">From:</span>

                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['fromAddress']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                            {this.getAddress(myProfile?.permanent_address)}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">
                                                            {/* <Button
                                                                size="sm" color="dark" title="Edit" onClick={() => showEditCollapse('fromAddress')}>
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button> */}
                                                            <EditBtn onClick={() => showEditCollapse('fromAddress')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['fromAddress']}>
                                        <Form>
                                            <div className="mb-2">
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="address1"
                                                    placeholder="Address 1"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.permanent_address && myProfile.permanent_address.address1 ? myProfile.permanent_address.address1 : ''}
                                                    onChange={handleOnChangeMyProfile('From Address')}
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="address2"
                                                    placeholder="Address 2"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.permanent_address && myProfile.permanent_address.address2 ? myProfile.permanent_address.address2 : ''}
                                                    onChange={handleOnChangeMyProfile('From Address')}
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="city"
                                                    placeholder="City"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.permanent_address && myProfile.permanent_address.city ? myProfile.permanent_address.city : ''}
                                                    onChange={handleOnChangeMyProfile('From Address')}
                                                />
                                                {myInfoError.City && (
                                                    <div className="text-danger small mt-1">
                                                        {myInfoError.City}
                                                    </div>
                                                )}
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="state"
                                                    placeholder="State"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.permanent_address && myProfile.permanent_address.state ? myProfile.permanent_address.state : ''}
                                                    onChange={handleOnChangeMyProfile('From Address')}
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="select"
                                                    name="country"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    onChange={handleOnChangeMyProfile('From Address')}
                                                >
                                                    <option value="usa" selected={myProfile && myProfile.permanent_address && myProfile.permanent_address.country === 'usa'}>United States</option>
                                                    <option value="can" selected={myProfile && myProfile.permanent_address && myProfile.permanent_address.country === 'can'}>Canada</option>
                                                </Input>
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="text"
                                                    name="zipcode"
                                                    placeholder="Zipcode"
                                                    maxLength={7}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.permanent_address && myProfile.permanent_address.zipcode ? myProfile.permanent_address.zipcode : ''}
                                                    onChange={handleOnChangeMyProfile('From Address')}
                                                />
                                                {myInfoError.zipCode && (
                                                    <div className="text-danger small mt-1">
                                                        {myInfoError.zipCode}
                                                    </div>
                                                )}
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={() => handleOnSaveMyProfile('From Address')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('From Address')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">Email:</span>

                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['email']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1" style={{ wordBreak: 'break-word' }}>
                                                    {myProfile && myProfile.user && myProfile.user.email ? myProfile.user.email : ''}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">
                                                            {/* <Button
                                                                size="sm" color="dark" title="Edit" onClick={() => showEditCollapse('email')}>
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button> */}
                                                            <EditBtn onClick={() => showEditCollapse('email')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['email']}>
                                        <Form>
                                            <div className="mb-2">
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="email"
                                                    name="email"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={myProfile && myProfile.user && myProfile.user.email ? myProfile.user.email : ''}
                                                    onChange={handleOnChangeMyProfile('Email')}
                                                    placeholder="Email ID"
                                                />
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={() => handleOnSaveMyProfile('Email')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('Email')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <span className="font-weight-bold mb-1 mr-2 flex-110">Password:</span>

                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['password']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1">
                                                    {'*******'}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">

                                                            <EditBtn onClick={() => showEditCollapse('password')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['password']}>
                                        <Form>
                                            <div className="mb-2">
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="password"
                                                    name="newPassword"
                                                    placeholder="New Password"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={changePassword && changePassword.newPassword}
                                                    onChange={handleOnChangeMyProfile('Password')}
                                                />
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="password"
                                                    name="confirmNewPassword"
                                                    placeholder="Confirm New Password"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    value={changePassword && changePassword.confirmNewPassword}
                                                    onChange={handleOnChangeMyProfile('Password')}
                                                />
                                                {changePassword && changePassword.error && (
                                                    <div className="text-danger small mt-1">
                                                        {changePassword.error}
                                                    </div>
                                                )}
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={() => handleOnSaveMyProfile('Password')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('Password')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>

                                {/* Field Container */}
                                <div>
                                    <div className="d-flex flex-column">
                                        <span className="font-weight-bold text-secondary fs-16 mb-1 mr-2">About Me</span>

                                        {/* Hide when editing */}
                                        <span className="flex-grow-1 ff-alt" hidden={isFieldShown['aboutMe']}>
                                            <div className="d-flex">
                                                <div className="mb-2 flex-grow-1 wb-break-word">
                                                    {myProfile && myProfile.about_me ? myProfile.about_me : <em>Please tell us about yourself</em>}
                                                </div>
                                                <div className="ml-2 interactive col-auto px-0">
                                                    <div className="interactive-appear">
                                                        <div className="text-nowrap">
                                                            {/* <Button
                                                                size="sm" color="dark" title="Edit" onClick={() => showEditCollapse('aboutMe')}>
                                                                <FontAwesomeIcon icon="edit" />
                                                            </Button> */}
                                                            <EditBtn onClick={() => showEditCollapse('aboutMe')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    {/* Show when editing */}
                                    <Collapse isOpen={isFieldShown['aboutMe']}>
                                        <Form>
                                            <div className="mb-2">
                                                <Input
                                                    bsSize="sm"
                                                    className="glowy-form-control mb-2"
                                                    type="textarea"
                                                    name="about_me"
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    onChange={handleOnChangeMyProfile('About Me')}
                                                    value={myProfile && myProfile.about_me ? myProfile.about_me : ''}
                                                    placeholder="I am..."
                                                    style={{ color: '#555' }}
                                                />
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={() => handleOnSaveMyProfile('About Me')}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => handleOnCancelUpdateProfile('About Me')}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </Collapse>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white-shaded-md px-3 py-3 mb-3">
                            <Actors />
                        </div>

                        <div className="bg-white-shaded-md px-3 py-3 mb-3">
                            <WorkHistory subMainViewType={subMainViewType} />
                        </div>

                        <div className="bg-white-shaded-md px-3 py-3 mb-3">
                            <MyFavorites subMainViewType={subMainViewType} />
                        </div>

                        <div className="bg-white-shaded-md px-3 py-3 mb-3">
                            <MyEducation />
                        </div>

                    </Col>
                    <Col xs={12} className="flex-lg-350-rest">
                        <div className="bg-white p-3 mb-3 step-12_myProfile">
                            <h2 className="text-secondary fs-38 mb-3">My Statistics</h2>
                            <hr className="mt-1 mb-0" />

                            <div>
                                <div className="d-flex">
                                    <div className="mr-4">
                                        <span className="fs-38 ff-headings text-secondary">{myProfile && myProfile != 'null' && myProfile.statistics && myProfile.statistics.crp ? myProfile.statistics.crp : '0'}</span>
                                    </div>
                                    <div className="align-self-end">
                                        <span className="font-weight-bold text-secondary mb-2 d-block">Community Review Projects</span>
                                    </div>
                                </div>
                                <hr className="mt-1 bg-secondary" />
                            </div>

                            <div>
                                <div className="d-flex mb-3">
                                    <div className="mr-4">
                                        <span className="fs-30 ff-headings text-secondary-dark">{myProfile && myProfile != 'null' && myProfile.statistics && myProfile.statistics.reviews ? myProfile.statistics.reviews : '0'}</span>
                                    </div>
                                    <div className="align-self-end">
                                        <span className="font-weight-bold text-secondary-dark mb-2 d-block">Reviews created</span>
                                    </div>
                                </div>

                                <div className="row g-3" form>
                                    <div className="col-md-6 mb-3 px-3 border-right border-secondary">
                                        <div className="fs-14 text-center">
                                            <div className="text-center fs-14 text-secondary-dark">Distribution of <br /> ratings you gave</div>
                                            <Chart
                                                width={'100%'}
                                                height={'300px'}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart...</div>}
                                                /*data={myProfile && myProfile != 'null' && myProfile.review_statistics && myProfile.review_statistics.stats ? myProfile.review_statistics.stats.map((i, index) => {
                                                    if (index === 0) {
                                                        i[0] = 'Star Rating'
                                                        i[1] = 'Number of Reviews';
                                                    }
                                                    return i;
                                                }) : []}*/
                                                data={reviewStatisticsData}
                                                options={{
                                                    title: null,
                                                    legend: 'none',
                                                    // legend: { position: "bottom" },
                                                    titleTextStyle: {
                                                        fontName: 'museo_sans500',
                                                        fontSize: 14,
                                                        color: '#716646',
                                                        bold: false
                                                    },
                                                    vAxis: { title: 'Rates', titleTextStyle: { italic: false, color: '#053061', fontName: "Arial", fontSize: "14", bold: true } },
                                                    hAxis: { title: 'Distribution', titleTextStyle: { italic: false, color: '#053061', fontName: "Arial", fontSize: "14", bold: true } },
                                                    seriesType: 'bars',
                                                    annotations: {
                                                        textStyle: {
                                                            fontSize: 12
                                                        }
                                                    }
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                            />
                                        </div>
                                        {/* <div className="border-right border-secondary h-100 d-none d-xl-block ml-2"></div> */}
                                    </div>
                                    <div className="col-md-6 mb-3 px-3">
                                        <div className="fs-14 text-center">
                                            <div className="text-center fs-14 text-secondary-dark">Category demographics</div>
                                            <Chart
                                                chartType="PieChart"
                                                data={demographicReviewStatistics}
                                                width={'100%'}
                                                height={'300px'}
                                                loader={<div>Loading Chart...</div>}
                                                options={{
                                                    title: "",
                                                    titleTextStyle: {
                                                        fontName: 'museo_sans500',
                                                        fontSize: 14,
                                                        color: '#716646',
                                                        bold: false
                                                    },
                                                    chartArea: { width: '100%' },
                                                    legend: { position: "bottom", alignment: "start", maxLines: 3 },
                                                    isStacked: true,
                                                    annotation: {
                                                        fontsize: 1
                                                    },
                                                    slices: {
                                                        0: { color: '#716646' },
                                                        1: { color: '#315271' },
                                                        2: { color: '#e8ddc1' },
                                                        3: { color: '#06182b' },
                                                        4: { color: '#6982a0' },
                                                    }
                                                }}
                                                rootProps={{ "data-testid": "1" }}
                                            /></div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3 bg-secondary" />
                            <div>
                                <div className="d-flex mb-3">
                                    <div className="mr-4">
                                        <span className="fs-30 ff-headings text-secondary-dark">{myProfile && myProfile != 'null' && myProfile.statistics && myProfile.statistics.contri ? myProfile.statistics.contri : '0'}</span>
                                    </div>
                                    <div className="align-self-end">
                                        <span className="font-weight-bold text-secondary-dark mb-2 d-block">Contributions made</span>
                                    </div>
                                </div>

                                <div className='row g-3'>
                                    <div className="col-md-6 mb-3 px-3 border-right border-secondary">
                                        <div className="fs-14 text-center">
                                            <div className="text-center fs-14 text-secondary-dark">Distribution of <br /> Approvals/Reverts</div>
                                            <Chart
                                                width={'100%'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart...</div>}

                                                data={contributionStatistics}
                                                options={{
                                                    legend: 'none',
                                                    title: null,
                                                    titleTextStyle: {
                                                        fontName: 'museo_sans500',
                                                        fontSize: 14,
                                                        color: '#716646',
                                                        bold: false
                                                    },
                                                    colors: ['#15c3d4'],
                                                }}
                                                rootProps={{ "data-testid": "1" }}
                                            />
                                        </div>
                                        {/* <div className="border-right border-secondary h-100 d-none d-xl-block ml-2"></div> */}
                                    </div>
                                    <div className="col-md-6 mb-3 px-3">
                                        <div className="fs-14 text-center">
                                            <div className="text-center fs-14 text-secondary-dark">Category demographics</div>
                                            <Chart
                                                width={'100%'}
                                                // height={'300px'}
                                                // width={'216px'}
                                                height={'300px'}

                                                chartType="PieChart"
                                                className="wk-profile-contribution-chart"
                                                loader={<div>Loading Chart...</div>}
                                                data={contributionStatistics}
                                                options={{
                                                    title: null,
                                                    titleTextStyle: {
                                                        fontFamily: 'museo_sans500',
                                                        fontSize: 14,
                                                        color: '#716646',
                                                        marginBottom: '24px',
                                                        display: 'inline-block',
                                                        minHeight: '25px',
                                                        bold: false
                                                    },
                                                    //legend: { position: "top", maxLines: 2 },

                                                    legend: { position: "top", alignment: "start", maxLines: 2 },
                                                    chartArea: { top: 50, bottom: 30, right: 20, left: 43, 'width': '100%' },
                                                    slices: {
                                                        0: { color: '#315271' },
                                                        1: { color: '#716646' },
                                                        2: { color: '#e8ddc1' },
                                                        3: { color: '#06182b' },
                                                        4: { color: '#6982a0' },

                                                    }
                                                }}
                                                rootProps={{ "data-testid": "1" }}
                                            />

                                            {/* <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                    ['Task', 'Hours per Day'],
                                                    ['Work', 11],
                                                    ['Eat', 2],
                                                    ['Commute', 2],
                                                    ['Watch TV', 2],
                                                    ['Sleep', 7],
                                                ]}
                                                options={{
                                                    title: 'My Daily Activities',
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                <hr className="mt-1 bg-secondary" />
                            </div>
                        </div>
                        <div className="bg-white p-3 mb-3">
                            <h2 className="text-secondary fs-38 mb-3">My Badges</h2>
                            <hr className="mt-1" />
                            <Row xs={2} md={3} lg={2} xl={2} style={{ maxHeight: '300px', overflowY: 'auto' }}>

                                {myProfile && myProfile.award_statistics && myProfile.award_statistics.other && myProfile.award_statistics.other.badges && myProfile.award_statistics.other.badges.length > 0 && myProfile.award_statistics.other.badges.map((badge, index) => {
                                    // let img = 'taaffeite';
                                    // if (badge[0] === 'Taaffeite') {
                                    //     img = 'taaffeite';
                                    // } else if (badge[0] === 'Aldebaran') {
                                    //     img = 'aldebaran';
                                    // } else if (badge[0] === 'Tanzanite') {
                                    //     img = 'tanzanite';
                                    // } else if (badge[0] === 'Antares') {
                                    //     img = 'antares';
                                    // } else if (badge[0] === 'Black Opal') {
                                    //     img = 'black_opal';
                                    // } else if (badge[0] === 'Alpha Centauri') {
                                    //     img = 'alpha_centauri';
                                    // } else if (badge[0] === 'Arcturus') {
                                    //     img = 'arcturus';
                                    // } else if (badge[0] === 'Polaris') {
                                    //     img = 'polaris';
                                    // } 
                                    
                                    return (
                                        <Col className="mb-2" key={index}>
                                            <div className="d-flex mx-n1">
                                                <div className="px-1 col-auto">
                                                    <a className="user-badge-link" title={`${badgesReference?.[badge[0].replace(/ /g, "_")]}`}>
                                                        <span className="user-badge">
                                                            <span className="badge-date">{badge[1]}</span>
                                                            <span className="badge-img">
                                                                <img src={`https://userdatawikireviews.s3.us-west-2.amazonaws.com/images/awards/${badge[0]}.png`} alt={badge[0]} />
                                                            </span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="px-1">
                                                    <div className="text-secondary-dark fs-14">
                                                        <strong className="ff-base">{badge[0]}</strong>
                                                        <br />
                                                        <span>{badge[1]}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                                {myProfile && myProfile.award_statistics && myProfile.award_statistics.all_badges && Object.keys(myProfile.award_statistics.all_badges).length > 0 && Object.keys(myProfile.award_statistics.all_badges).map((badge, index) => {
                                    // let img = 'first_review';
                                    // if (badge === 'First review') {
                                    //     img = 'first_review';
                                    // } else if (badge === 'First to add listing') {
                                    //     img = 'first_to_add_listing';
                                    // } else if (badge === 'First to add pictures in listing') {
                                    //     img = 'first_to_add_pictures_in_listing';
                                    // } else if (badge === 'First to add video in listing') {
                                    //     img = 'first_to_add_video_in_listing';
                                    // } else if (badge === 'First speech to text review') {
                                    //     img = 'first_speech_to_text_review';
                                    // } else if (badge === 'First to add picture on listing from mobile') {
                                    //     img = 'first_to_add_picture_on_listing_from_mobile';
                                    // } else if (badge === 'First CRP project') {
                                    //     img = 'first_crp_project';
                                    // } else if (badge === 'First to write tip on listing') {
                                    //     img = 'first_to_write_tip_on_listing';
                                    // }
                                    return (
                                        <Col className="mb-2" key={index}>
                                            <div className="d-flex mx-n1">
                                                <div className="px-1 col-auto">
                                                    <a href="#" className="user-badge-link" title={`${badge} - ${myProfile?.award_statistics?.all_badges?.[badge]?.[0]} time`}>
                                                        <span className="user-badge">
                                                            <span className="badge-date">{myProfile.award_statistics.all_badges[badge][0]}</span>
                                                            <span className="badge-img full">
                                                                <img src={`https://userdatawikireviews.s3.amazonaws.com/images/awards/${badge}.png`} alt={badge} />
                                                            </span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="px-1">
                                                    <div className="text-secondary-dark fs-14">
                                                        <strong className="ff-base">{badge}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                                {myProfile && myProfile.award_statistics && !(myProfile.award_statistics.all_badges) && myProfile.award_statistics.other && myProfile.award_statistics.other.badges && myProfile.award_statistics.other.badges.length == 0 && (
                                    <div className="text-secondary font-weight-bold p-0">
                                        No badges found
                                    </div>
                                )}
                            </Row>
                        </div>

                        <div className="bg-white p-3 mb-3">
                            <h2 className="text-secondary fs-38 mb-3">{'My photos'}</h2>
                            <div className="d-flex flex-wrap fs-14">
                                <div className="mr-2">
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'image', albumType: 'reviews' })}
                                        color="link" className="text-secondary font-weight-bold p-0">Pictures from reviews</Button>
                                </div>
                                <div className="ml-auto">
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'image', albumType: '' })}
                                        color="link" className="text-secondary font-weight-bold p-0">All</Button>
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'image', albumType: 'profile' })}
                                        color="link" className="text-secondary font-weight-bold p-0 ml-2">Profile Pictures</Button>
                                </div>
                            </div>
                            <hr className="mt-1" />
                            <div>
                                <ul
                                    ref={myScrollPhotoRef}
                                    onScroll={handlePhotoScroll}
                                    className="list-unstyled d-flex flex-nowrap flex-lg-wrap  mx-n1 mb-2 wk-upload-profile-photos"
                                    style={{ maxHeight: '205px', overflowY: 'auto' }}>
                                    {renderPhotos()}
                                </ul>

                                <div className="d-flex">
                                    <div className="mr-2">
                                        <Button
                                            color="link"
                                            className="text-secondary-dark font-weight-bold p-0 hover-blue"
                                            onClick={() => { addNewPhotoClick("image/*", true) }}>
                                            <span className="icon-camera-gold mr-2"></span>
                                            {'add new photo'}
                                        </Button>
                                    </div>
                                    <div className="ml-auto">
                                        <Button
                                            color="link"
                                            className="text-secondary font-weight-bold p-0"
                                            onClick={() => { deletePhotoClick() }}
                                        >
                                            {'delete photos'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-3 mb-3">
                            <h2 className="text-secondary fs-38 mb-3">My Videos</h2>
                            <div className="d-flex flex-wrap fs-14">
                                <div className="mr-2">
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'video', albumType: 'reviews' })}
                                        color="link" className="text-secondary font-weight-bold p-0">Videos from reviews</Button>
                                </div>
                                <div className="ml-auto">
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'video', albumType: '' })}
                                        color="link" className="text-secondary font-weight-bold p-0">All</Button>
                                </div>
                            </div>
                            <hr className="mt-1" />

                            <div>
                                <ul
                                    ref={myScrollVideoRef}
                                    onScroll={handleVideoScroll}
                                    className="list-unstyled d-flex flex-nowrap flex-lg-wrap  mx-n1 mb-2 wk-profile-video-upload"
                                    style={{ maxHeight: '280px', overflowY: 'auto' }}>
                                    {renderVideos()}
                                </ul>

                                <div className="d-flex">
                                    <div className="mr-2">
                                        <Button
                                            color="link"
                                            className="text-secondary-dark font-weight-bold p-0 hover-blue"
                                            onClick={() => { toggleUploadMediaModal('video/*',true) }}>
                                            <span className="icon-camera-gold mr-2"></span> add new video</Button>
                                    </div>
                                    <div className="ml-auto">
                                        <Button
                                            color="link"
                                            className="text-secondary font-weight-bold p-0"
                                            onClick={() => { toggleDeletModal() }}
                                        >
                                            {'delete videos'}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authNumber: state.user.auth_phone_number,
})

const mapDispatchToProps = (dispatch) => ({
    updatePhoneNumber: (data) => dispatch(updatePhoneNumber(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTab)
