
import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { Button, Col, Container, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { get_id_from_slug, get_type_detail, clear_type_details, get_uploaded_media, setItemDetailLoading, get_uploaded_media_follow } from '../../actions/itemDetail';
import { fetch_silimar_products, get_reviews_listing, delete_products_review, delete_tips_warning, fetch_what_reviewers_say, get_product_details_wall_data } from '../../actions/products';
import { get_chart_star_ratings, share_by_email } from '../../actions/business';
import { get_friends_profile, setEditExistingMedia } from '../../actions/shared'
import CollapseBasic from '../atoms/collapse';
import ConfirmModal from '../atoms/confirmModal';
import CreateReview from '../atoms/createReview';
import { callApi } from "../../utils/apiCaller";
import UploadMedia from '../atoms/fileUploadMedia';
import AppHeader from '../oragnisms/dashboard/appHeader';
import About from '../oragnisms/productDetails/about';
import CompareProducts from '../oragnisms/productDetails/compareProducts';
import LeftCompareProducts from '../oragnisms/productDetails/leftcompareProducts';
import DetailReviews from '../oragnisms/productDetails/detailReviews';
import Gallery from '../molecules/shared/Gallery/gallery';
import Specifications from '../oragnisms/productDetails/specifications';
import TechnicalDetails from '../oragnisms/productDetails/technicalDetails';
import dataReview from './reviews';
import OfferDetails from '../oragnisms/productDetails/offerDetails';
import DiscussThisPage from '../oragnisms/movieDetails/discussThisPage';
import TipsAndWarning from '../oragnisms/productDetails/tipsAndWarning';
import { delete_problem } from '../../actions/products';
import { my_profile } from "../../actions/user";
import PublicPageHeader from '../oragnisms/homepage/publicPageHeader';
import WhatReviewersSay from '../oragnisms/productDetails/whatReviewersSay';
import DetailedStarRating from '../molecules/shared/DetailedStarRatings/detailedStarRating';
import FriendsProfile from '../molecules/shared/FriendsProfile/friendsProfile';
import { categoryType, getReviewSuffix, highlightSearchTerm } from '../../utils/utilities';
import SocialButtons from '../molecules/shared/SocialButtons/socialButtons';
import { finalizedMediaInReviewEmbedLinkSetForFollowReview, finalizedMediaInReviewEmbedLinkSetForNewReview, finalizedMediaInReviewMediaSetForNewReview, uploadMediaInReviewEmbedLinkSetForNewReview, uploadMediaInReviewMediaSetForEditReview, uploadMediaInReviewMediaSetForFollowReview, uploadMediaInReviewMediaSetForNewReview } from '../../actions/reviewMedia';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';
import { getCategoryDetailDescription, getCategoryDetailTitle } from '../../utils/seo-utilities';

class ProductDetails extends Component {
    constructor(props) {
        super(props)
        this.compareProductChild = React.createRef();
        this.state = {
            viewProductAs: 'about',
            viewMediaModalToggle: false,
            createNewDisModalToggle: false,
            mainViewType: 'reviews',
            viewEditReviewStep: 'writeReview',
            reviews: [],
            mediaSet: [],
            mediaSetFollowUp: [],
            productId: 0,
            productDetail: "",
            productName: "",
            manufacturer: "",
            imgUrl: null,
            modelNumber: null,
            avgRating: null,
            priceMax: "",
            priceMim: "",
            similarProducts: "",
            overallRatingVote: null,
            uploadedMediaSet: {
                embedLinks: [],
                uploadedFiles: [],
            },
            uploadedMediaSetFollowUp: {
                embedLinks: [],
                uploadedFiles: [],
            },
            isEnableConfirmModal: false,
            isEnableFileUpload: false,
            confirmModalText: '',
            confirmModalKey: '',
            confirmModalItemId: null,
            chartStarRatings: null,
            enableStickyHeader: false,
            deleteOfferDetails: false,
            discussionTab: "",
            confirmMessateText: "",
            loaded: false,
            uploadMediaUrl: "",
            typeupload: "Products",
            keyCompare: "",
            discusstype: "",
            forceShowLoginModal: false,
            what_reviewers_say: null,
            friendsProfileRecommend: null,
            friendsProfileFavourite: null,
            friendsProfileWant: null,
            isEditReviewEnabled:false,
            newlyUpdatedMedias: [],
            newlyUpdatedEmbedMedias:[],
            deletedMedias:[],
            followUpStatus:false,
            disableCreateReview:true,
            publishReview:false,
            initialReviewListing: null,
            searchedValue:''
        }
    }

    resetState = () => {
        this.setState({
            viewProductAs: 'about',
            viewMediaModalToggle: false,
            createNewDisModalToggle: false,
            mainViewType: 'reviews',
            viewEditReviewStep: 'writeReview',
            reviews: [],
            productId: 0,
            productDetail: "",
            productName: "",
            manufacturer: "",
            imgUrl: null,
            modelNumber: null,
            avgRating: null,
            priceMax: "",
            priceMim: "",
            similarProducts: "",
            overallRatingVote: null,
            uploadedMediaSet: {
                embedLinks: {},
                uploadedFiles: [],
            },
            uploadedMediaSetFollowUp: {
                embedLinks: [],
                uploadedFiles: [],
            },
            isEnableConfirmModal: false,
            isEnableFileUpload: false,
            confirmModalText: '',
            confirmModalKey: '',
            confirmModalItemId: null,
            chartStarRatings: null,
            enableStickyHeader: false,
            deleteOfferDetails: false,
            discussionTab: "",
            uploadMediaUrl: "",
            notifiTab: "",
            forceShowLoginModal: false,
            newlyUpdatedMedias: [],
            newlyUpdatedEmbedMedias:[],
            deletedMedias:[],
            followUpStatus:false,
            disableCreateReview:true,
            publishReview:false
        })
    }

    setToggleDeleteOffer = (id) => { this.setState({ deleteOfferDetails: id ? id : false }) }

    handleCompareChild = () => {

        //this.compareProductChild.current.testChildFunciton()
    }

    toggleConfirmModal = ({ text, isTextDelete, uniqueKey, id, message }) => {
        let text_str = '';
        let messagetstr = '';
        if (isTextDelete && !text) {
            text_str = 'Do you want to delete this ?';
        }

        if (text && !isTextDelete) {
            text_str = text;
            messagetstr = message;
        }

        if (text && isTextDelete) {
            text_str = text;
            messagetstr = message;
        }

        this.setState({
            isEnableConfirmModal: !this.state.isEnableConfirmModal,
            confirmModalText: text_str,
            confirmMessateText: messagetstr,
            confirmModalKey: uniqueKey || '',
            confirmModalItemId: id || null
        });
    }

    componentWillMount() {
        this.hitOnce = false;
        this.checksAndRenderById();
    }

    checksAndRenderById = () => {
        let props = this.props;
        let pathname = props.history.location?.pathname;
        const urlId = props?.match?.params?.id
        let id = props?.history?.location?.state?.id || urlId;
        let serviceParams = props?.match?.params;
        let mainViewTyp = props?.history?.location?.state?.notif_tab;


        const { slug } = serviceParams;

        if (slug) {
            const id = serviceParams.id;
            this.getDataByID(id);
        } else {
            if (id === undefined && serviceParams?.service !== '' && serviceParams?.state !== '' && serviceParams?.city !== '' && serviceParams?.name !== '' && !this.hitOnce) {
                this.hitOnce = true;
                let items = pathname.split('/');
                let type = items[1] ? items[1] : '';
                let serviceItems = Object.values(serviceParams).join('/');
                // this.props.get_id_from_slug({ type, slug: serviceItems })
            }

            if (id) {
                this.getDataByID(id);
            }

            if (id === undefined && serviceParams?.id && !this.hitOnce) {
                this.hitOnce = true;
                let items = pathname.split('/');
                let type = items[1] ? items[1] : '';
                let slug = items[2] ? items[2] : '';
                // this.props.get_id_from_slug({ type, slug })

            }
        }

        if (mainViewTyp && mainViewTyp != "") {
            this.setState({ notifiTab: mainViewTyp });
        }
    }

    getDataByID(id) {
        this.setState({ productId: id, ProductDetails: null, simillarBusiness: null, setWallDataItems: {} }, () => {
            window.scrollTo(0, 0)
            this.props.setItemDetailLoading(true);
            this.fetchByItemId({ productId: this.state.productId });
        });
    }

    componentWillReceiveProps(nextProps) {
        let { productId } = this.state;
        if (nextProps?.product_details) {
            this.setState({
                productDetail: nextProps?.product_details,
                productName: nextProps?.product_details,
                imgUrl: nextProps?.product_details,
                avgRating: nextProps?.product_details,
                manufacturer: nextProps?.product_details,
                priceMax: nextProps?.product_details,
                priceMim: nextProps?.product_details,
                modelNumber: nextProps?.product_details.model_number,
            });

        }
        if (nextProps?.what_reviewers_say) {
            this.setState({
                what_reviewers_say: nextProps.what_reviewers_say
            });
        }
        if (nextProps?.back_to_listing) {
            if (nextProps?.back_to_listing?.code === 200 && !productId) {
                this.setState({ productId: nextProps?.back_to_listing?.entry_id }, () => {
                    this.props.setItemDetailLoading(true);
                    this.fetchByItemId({ productId: this.state.productId });
                });
            }

            if ([404, 400, 500].includes(nextProps?.back_to_listing?.code)) {
                this.props.history.push('/products');
            }
        }

        if (nextProps.fetch_similarProducts && Array.isArray(nextProps.fetch_similarProducts.results) && nextProps.fetch_similarProducts.results.length > 0) {
            this.setState({ similarProducts: nextProps?.fetch_similarProducts });
        }

        if (nextProps.get_review_listing && Array.isArray(nextProps.get_review_listing.results) && nextProps.get_review_listing.results.length > 0) {           
            this.setState({ reviews: nextProps.get_review_listing.results },()=>{
                highlightSearchTerm();
            });
            
            if(!this.state.initialReviewListing)  this.setState({initialReviewListing: nextProps.get_review_listing.results})

            if (!nextProps.get_review_listing.results?.some(item => item?.created_by?.id === this.props?.loggedInUser?.id)) {  
                if(this.state.publishReview) {
                    this.setState({ disableCreateReview: true })
                }else{
                    if(!this.state.initialReviewListing) this.setState({ disableCreateReview: false })
                }
            }
        } else if (nextProps.get_review_listing && Array.isArray(nextProps.get_review_listing.results) && nextProps.get_review_listing.results.length === 0) {            
            this.setState({reviews: nextProps.get_review_listing.results},()=>{
                highlightSearchTerm();
            })
            if(this.state.publishReview) {
                this.setState({ disableCreateReview: true })
            }else{
                if(!this.state.initialReviewListing) this.setState({ disableCreateReview: false })
            }
        } else {           
            if (dataReview.results && Array.isArray(dataReview.results) && dataReview.results.length > 0) {
                this.setState({
                    reviews: dataReview,
                    pageCount: dataReview.count,
                },()=>{
                    highlightSearchTerm();
                });
                
            }
        }


        if (nextProps?.chart_star_ratings) {
            this.setState({ chartStarRatings: nextProps?.chart_star_ratings });
        }

        if (nextProps.auth_data && (nextProps.auth_data !== this.props.auth_data)) {
            //this.props.my_profile_info();
            window.location.reload();
        }

        if (nextProps.friends_profile_recommend) {
            this.setState({
                friendsProfileRecommend: nextProps.friends_profile_recommend
            })
        }
        if (nextProps.friends_profile_favourite) {
            this.setState({
                friendsProfileFavourite: nextProps.friends_profile_favourite
            })
        }
        if (nextProps.friends_profile_want) {
            this.setState({
                friendsProfileWant: nextProps.friends_profile_want
            })
        }
        if("isEditReviewEnabled" in nextProps){
            if(this.state.isEditReviewEnabled !==nextProps.isEditReviewEnabled){
                // User has closed thepopup withj publishing
                // revert all the images we just updated.
                this.revertBackTheImages();
            }
            this.setState({isEditReviewEnabled: nextProps.isEditReviewEnabled})
        }

        if(nextProps.uploaded_media){
            this.setState({
                uploadedMediaSet:nextProps.uploaded_media
            })
        }

    }

  

    revertBackTheImages = () =>{
        const tempReviews = [...this.state.reviews];
        const foundIndex = tempReviews.findIndex(r=> r.id ===this.props.isEditedReviewId)
        
        if(foundIndex >-1){
            let reviewmedia_set_copy =  tempReviews[foundIndex].reviewmedia_set
            this.state.newlyUpdatedMedias.forEach((media)=>{
                if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.id===media?.id)){
                    reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.id!==media.id)
                }                
            })
           
            this.state.newlyUpdatedEmbedMedias.forEach((media)=>{
                if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.url===media?.url)){
                    reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.url!==media.url)
                }                
            })
            this.state.deletedMedias?.forEach((media)=>{                
                reviewmedia_set_copy.push(media)                              
            })

            tempReviews[foundIndex].reviewmedia_set = reviewmedia_set_copy;

            this.setState({reviews: tempReviews,deletedMedias:[]},()=>{
            })
        }
    }


    componentDidUpdate(prevProps) {
        let nextProps = this.props;
        let { productId, loaded, mediaSet,mediaSetFollowUp, uploadedMediaSet,uploadedMediaSetFollowUp } = this.state;

        // Typical usage (don't forget to compare props):
        // if (nextProps?.history?.location?.state?.id !== productId) {
        //     this.hitOnce = false;
        //     this.checksAndRenderById();
        // }

        if (nextProps?.history?.location?.state?.id !== productId && loaded === false) {

            this.hitOnce = false;
            this.setState(prevState => ({
                loaded: true
            }), () => { this.checksAndRenderById(); })

        }

        if (this.props.current_location && prevProps.current_location) {
            if ((this.props.current_location.lat !== prevProps.current_location.lat) && (this.props.current_location.lng !== prevProps.current_location.lng)) {
                let { productId } = this.state;
                if (productId) {
                    this.props.setItemDetailLoading(true);
                    this.fetchByItemId({ productId })
                }
            }
        }

        let uploadedFiles = uploadedMediaSet?.uploadedFiles;

        if (Array.isArray(uploadedFiles) && Array.isArray(mediaSet) && uploadedFiles?.length !== mediaSet?.length) {

            this.setState({ mediaSet: uploadedFiles });
        }

        let uploadedFilesFollowUp = uploadedMediaSetFollowUp?.uploadedFiles;

        if (Array.isArray(uploadedFilesFollowUp) && Array.isArray(mediaSetFollowUp) && uploadedFilesFollowUp?.length !== mediaSetFollowUp?.length) {

            this.setState({ mediaSetFollowUp: uploadedFilesFollowUp });
        }

    }

    componentDidMount() {
        this.props.my_profile_info();
        let { productId } = this.state;
        if (productId) {
            this.props.setItemDetailLoading(true);
            this.fetchByItemId({ productId });
        }
        window.addEventListener("scroll", this.handleScroll, { passive: false });
    }

    componentWillUnmount() {
        this.resetState();
        this.props.clear_type_details();
        window.removeEventListener("scroll", this.handleScroll);
        this.props.resetItemsState();
        this.props.resetProductsState();
    }

    handleScroll = () => {
        let enableSticky = document.getElementById('enable-sticky');
        let distanceToTop = enableSticky.getBoundingClientRect().top;
        if (distanceToTop < 0) {
            if (this.state.enableStickyHeader !== true) {
                this.setState({ enableStickyHeader: true });
            }
        } else {
            if (this.state.enableStickyHeader !== false) {
                this.setState({ enableStickyHeader: false });
            }
        }
    }

    fetchByItemId = ({ productId }) => {
        if (productId) {
            this.props.get_type_detail({ type: 'products', id: productId });
            this.props.fetch_silimar_products(productId);
            this.props.fetch_what_reviewers_say(productId);
            this.props.get_reviews_listing(productId);
            this.props.get_chart_star_ratings(productId);
            this.setState({ viewProductAs: "about" });
            this.props.get_friends_profile('recommend', productId);
            this.props.get_friends_profile('want', productId);
            this.props.get_friends_profile('favourite', productId);
        }
    }

    toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false, reviewOneToggler,followUpStatus) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
        this.setState({
            reviewOneToggler: reviewOneToggler? true: false,
            followUpStatus:followUpStatus
        })
    }

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet,uploadedMediaSetFollowUp } = this.state;
        let uploadedMediaSetCopy = { ...uploadedMediaSet }
        let uploadedMediaSetFollowUpCopy = {...uploadedMediaSetFollowUp}
        const key = 'id'
        const keyEmbed = 'thumbnail'
        if (this.state.reviewOneToggler) {

            if (this.props.isFollowReviewEnable) {

                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.followReviewMediaSet, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForFollowReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.followReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForFollowReview(embedSetUnique);
                }
            } else {

                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.newReviewMediaSet, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForNewReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
                }
            }
        } else {
            if (this.props.isEditReviewEnabled) {
                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.editReviewMediaSetFinalized, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForEditReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
                }
            }else{
                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) { 
                    uploadedMediaSetCopy['uploadedFiles'] = uploadedMediaSetCopy['uploadedFiles'].concat(mediaSet); 
                    uploadedMediaSetCopy['uploadedFiles'] = [...new Map(uploadedMediaSetCopy['uploadedFiles']?.map(item =>
                        [item['id'], item])).values()];
                }
                if (links) { 
                    uploadedMediaSetCopy['embedLinks'] = uploadedMediaSetCopy['embedLinks'].concat(links); 
                    uploadedMediaSetCopy['embedLinks'] = [...new Map(uploadedMediaSetCopy['embedLinks']?.map(item =>
                        [item['thumbnail'], item])).values()]; 
                
                }
                this.setState({ uploadedMediaSet: uploadedMediaSetCopy }, () => { this.props.get_uploaded_media(uploadedMediaSetCopy); });
            }
            
        }

        //redux
    
    }

    handleOnClickUploadModalOk = () => {
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }

    getuploadUrl = (url) => {
        let { uploadMediaUrl } = this.state;
        uploadMediaUrl = url.uploadUrl;
        this.setState({ uploadMediaUrl });
    }

    discussTab = (disscusion) => {
        let { discussionTab } = this.state;
        discussionTab = disscusion;
        this.setState({ discussionTab });
    }

    resetUploadedMediaSet = () => {
        const uploadedMediaSet = {
            embedLinks: [],
            uploadedFiles: [],
            mediaSet: [],
            mediaSetFollowUp:[]
        }
        this.setState({ uploadedMediaSet });
    }

    onPublishReview = () => {
        let { productId } = this.state;        
        this.setState({publishReview:true},()=>{
            this.props.setItemDetailLoading(true);
            this.fetchByItemId({ productId });
        })

    }

    handleOnConfirmModal = () => {
        let { confirmModalKey, productDetail, confirmModalItemId, productId, deleteReviewComment } = this.state;

        if (confirmModalKey === "delete-products-review") {
            let name = productDetail?.name;
            this.props.delete_products_review(confirmModalItemId, productId, name);
        }

        if (confirmModalKey === "delete-product-review-comment" && confirmModalItemId) {
            this.deleteReviewComments(confirmModalItemId);
        }

        if (confirmModalKey === "delete-product-tips" && confirmModalItemId) {
            let type = 'tips';
            this.props.delete_tips_warning(confirmModalItemId, productId, type);
        }

        if (confirmModalKey === "delete-product-warning" && confirmModalItemId) {
            let type = 'warning';
            this.props.delete_tips_warning(confirmModalItemId, productId, type);
        }

        if (confirmModalKey == "delete-offer-detail" && confirmModalItemId) {
            this.setToggleDeleteOffer(confirmModalItemId);
        }
        if (confirmModalKey == "delete-prob-sol" && confirmModalItemId) {
            let proID = this.state.productId;
            if (proID && proID != 0) {
                this.props.delete_problem(confirmModalItemId, proID, this.state.confirmMessateText);
            }
        }
       
    }

    deleteReviewComments = async (id) => {
        let { productId } = this.state;
        try {
            let url = `/api/deleterecord/?entityid=${id}&entity=comment`
            let response = await callApi(url, 'DELETE');
            if (response && response.code !== 500) {
                toast(`Comment Deleted!`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                if (productId) {
                    this.props.setItemDetailLoading(true);
                    this.fetchByItemId({ productId })
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    getWallData = (data) => {
        this.props.get_wall_data();
    }

    handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareId , shareNote}) => {
        let url = `/api/shareByemail/`;
        const data = {
            listing_id: shareId,
            mix_uid: `${shareEmail.split('@')[0]}_${shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            listing_type: "products",
            optional_note: shareNote
        };

        this.props.share_by_email(url, data);
    }

    getnocategory = (discusst) => {
        let { discussType } = this.state;
        if (discusst && discusst != "") {
            this.setState({ discussType: discusst });
        }
    }

    handleCategoryChange = (id) => {
        let elementToScrollTo = document.getElementById(id);
        const childOffset = 1;
        for (let i = 0; i < childOffset; i++) {
            if (!elementToScrollTo.previousElementSibling) {
                break;
            }
            elementToScrollTo = elementToScrollTo.previousElementSibling;
        }
        elementToScrollTo.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    }

    getloaded = (load) => {
        let { loaded } = this.state;
        loaded = load.loaded;

        this.setState({ loaded });
    }

    forceToggleLoginModal = () => {
        this.setState(prevState => ({
            ...this.prevState,
            forceShowLoginModal: !prevState.forceShowLoginModal
        }));
    }

    removeUploadedMediaSet = (media)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSet:{
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:this.state.uploadedMediaSet?.uploadedFiles?.filter(item=>item.id!==media?.id)
                }
            }
        })

        this.setState({deletedMedias:[...this.state.deletedMedias,...[media]]})
    }

    removeUploadedMediaSetFollow = (media)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSetFollowUp:{
                    ...prevState.uploadedMediaSetFollowUp,
                    uploadedFiles:this.state.uploadedMediaSetFollowUp?.uploadedFiles?.filter(item=>item.id!==media?.id)
                }
            }
        })        
    }

    

    render() {
        let { productId,
            productDetail,
            overallRatingVote,
            viewEditReviewStep,
            reviews,
            isEnableConfirmModal,
            confirmModalText,
            confirmModalKey,
            isEnableFileUpload,
            enableStickyHeader,
            productName,
            imgUrl,
            manufacturer,
            modelNumber,
            avgRating,
            priceMax,
            priceMim,
            what_reviewers_say,
            uploadedMediaSet,
            uploadedMediaSetFollowUp,
            followUpStatus
        } = this.state;
        const { loggedInUser, my_profile } = this.props;
        const responsive = { videoModal: { mobile: { breakpoint: { max: 9999999, min: 0 }, items: 1 } }, viewGalleryImages: { desktop: { breakpoint: { max: 9999999, min: 1200 }, items: 3 }, tablet: { breakpoint: { max: 1199, min: 576 }, items: 2 }, mobile: { breakpoint: { max: 575, min: 0 }, items: 1 } } };
        const avg_rating = avgRating && avgRating?.total_rating && avgRating?.total_rating?.avg_rating?.[0] ? avgRating.total_rating.avg_rating[0] : 'norating.png';
        const authId = localStorage.getItem('profileId');        
        return (
            <div className="wr-movie-top-banner">
                <HelmetMetaData
                    title={getCategoryDetailTitle("products", this.state.avgRating?.name || this.state.manufacturer?.name)}
                    description={getCategoryDetailDescription(this.state.avgRating?.name || this.state.manufacturer?.name)}
                />
                {isEnableConfirmModal ?
                    <ConfirmModal
                        modalText={confirmModalText}
                        modalKey={confirmModalKey}
                        toggleConfirmModal={this.toggleConfirmModal}
                        handleOnConfirm={this.handleOnConfirmModal}
                    />
                    : null}
                {isEnableFileUpload ?
                    <UploadMedia
                        getUploadedMedia={this.getUploadedMedia}
                        uploadedUrl={this.state.uploadMediaUrl}
                        hideEmbed={this.state.hideEmbed}
                        callbackFunction={this.handleOnClickUploadModalOk}
                        noCategory={this.state.discussionTab}
                        typeUpload={this.state.typeupload}
                        toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                            this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, this.state.reviewOneToggler? true: false);
                           
                        }}
                        mainTaxonomy={productDetail?.taxonomy_list?.[0]?.toLowerCase().replace(/&/g, "and")}
                        discusstype={this.state.discussType}
                    />
                    : null}
                {/* Show/hide on scroll */}
                <div hidden={!enableStickyHeader}>
                    <div className="bg-darker-shaded text-white fs-13 p-2 fixed-top" style={{ top: '45px' }}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="d-flex flex-nowrap mx-n2">
                                        <div className="px-2">
                                            <div className="topbar-main-img-holder">
                                                <img
                                                    src={`${imgUrl && imgUrl?.listing_profileimage && imgUrl?.listing_profileimage.length > 0 && imgUrl?.listing_profileimage[0] && imgUrl?.listing_profileimage[0].url}`}
                                                    className="img-fluid"
                                                    onError={(error) =>
                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                    }
                                                    alt={`${imgUrl && imgUrl?.listing_profileimage && imgUrl?.listing_profileimage.length > 0 && imgUrl?.listing_profileimage[0] && imgUrl.listing_profileimage[0].caption ? imgUrl.listing_profileimage[0].caption : 'no-image'}`}
                                                />
                                            </div>
                                        </div>
                                        <div className="px-2 mt-2">
                                            <div className="d-flex flex-wrap align-items-start">
                                                <div className="mr-2">
                                                    <h2 className="text-white text-uppercase fs-20">{productName?.name || ''}</h2>
                                                </div>
                                                <div className="d-flex flex-nowrap align-items-center">
                                                    <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${avg_rating}`}
                                                        className="img-fluid"
                                                        onError={(error) =>
                                                            (error.target.src = `../../../assets/images/norating.png`)
                                                        }
                                                        alt={`${avgRating && avgRating?.total_rating && avgRating?.total_rating.avg_rating?.[1] ? avgRating.total_rating.avg_rating[1] : 'no-rating'}`}

                                                    />
                                                    <span className="fs-14 ff-alt">&nbsp; {(productDetail && productDetail.total_rating?.total_review) ? productDetail.total_rating?.total_review : 0} {getReviewSuffix(productDetail.total_rating?.total_review)}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="ml-4">
                                        <div className="fs-13 d-flex mx-n2 mt-2">
                                            <div className="px-2">

                                                {
                                                    modelNumber ?
                                                        (
                                                            <div>
                                                                <span className="font-weight-bold mr-1">
                                                                    Model Number:
                                                                </span>
                                                                <span className="ff-alt">

                                                                    {modelNumber}
                                                                </span>
                                                            </div>
                                                        ) : ("")

                                                }

                                            </div>
                                            <div className="px-2">
                                                {
                                                    manufacturer ? (
                                                        <div>
                                                            <span className="font-weight-bold mr-1">
                                                                Manufacturer:
                                                            </span>
                                                            <span className="ff-alt">
                                                                {manufacturer && manufacturer.manufacturer}
                                                            </span>
                                                        </div>
                                                    ) : ""
                                                }

                                                <div className="mt-2">

                                                    <span className="font-weight-bold mr-1">
                                                        Price Range:
                                                    </span>
                                                    <span className="ff-alt">

                                                        ${priceMim && priceMim.additional_info && priceMim.additional_info.price_min && priceMim.additional_info.price_min.length > 0 ? priceMim.additional_info.price_min[0].value : ""} - $
                                                        {priceMax && priceMax.additional_info && priceMax.additional_info.price_max && priceMax.additional_info.price_max.length > 0 ? priceMax.additional_info.price_max[0].value : ""}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                {(my_profile && my_profile.id && authId && (authId == my_profile.id)) ? (
                    <AppHeader isEnableCover={false} logoutToHome={true} />
                ) : (<PublicPageHeader isEnableCover={false} forceShowLoginModal={this.state.forceShowLoginModal} forceToggleLoginModal={this.forceToggleLoginModal} />)}
                <header className="position-relative">
                    <div className="featured-blurred-bg" style={{ backgroundImage: `url(${productDetail && productDetail.media_dict && productDetail.media_dict.length > 0 && Array.isArray(productDetail.media_dict) && productDetail.media_dict[0] ? productDetail.media_dict[0].url : 'https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'})` }}></div>
                    <TabContent className={(this.state.viewProductAs === 'gallery' || this.state.viewProductAs === 'compare') ? 'bg-dark-shaded-sm' : ''} activeTab={this.state.viewProductAs} style={{ minHeight: '500px' }}>
                        <TabPane tabId="about">
                            <About aboutDetails={this.state.productDetail !== null ? this.state.productDetail : ""}
                                productId={this.state.productId}
                                getuploadUrl={this.getuploadUrl}
                            />
                        </TabPane>
                        <TabPane tabId="gallery">
                            <Gallery details={this.props.product_details}
                                categoryType={categoryType.Products}
                                forceToggleLoginModal={this.forceToggleLoginModal}
                                mainTaxonomy={productDetail?.taxonomy_list?.[0]?.toLowerCase().replace(/&/g, "and")}
                            />
                        </TabPane>
                        <TabPane tabId="compare">
                            <CompareProducts
                                aboutDetails={this.state.productDetail !== null ? this.state.productDetail : ""}
                                allProducts={this.state.similarProducts !== null ? this.state.similarProducts : ""}
                                productId={this.state.productId}
                                chartStar_Ratings={this.state.chartStarRatings !== null ? this.state.chartStarRatings : ""}
                                getloaded={this.getloaded}
                                keyCompare={this.state.keyCompare}
                            />
                        </TabPane>
                    </TabContent>
                    <Container>
                        <Nav className="featured position-relative bg-white" justified pills style={{ marginTop: '-54px', zIndex: 3 }}>
                            <NavItem className="mw-100 margin-right-2">
                                <NavLink className="text-truncate" href="#"
                                    active={this.state.viewProductAs === "about"}
                                    onClick={() => {
                                        this.setState({ viewProductAs: "about" })
                                    }}>
                                    <span className="font-weight-bold text-uppercase">
                                        {'About'}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="mw-100 margin-right-2">
                                <NavLink className="text-truncate" href="#"
                                    active={this.state.viewProductAs === "gallery"}
                                    onClick={() => {
                                        window.dispatchEvent(new Event("resize"));
                                        this.setState({ viewProductAs: "gallery" });
                                    }}>
                                    <span className="font-weight-bold text-uppercase">
                                        {'Gallery'}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="mw-100 margin-right-2">
                                <NavLink className="text-truncate" href="#"
                                    active={this.state.viewProductAs === "compare"}
                                    onClick={() => {
                                        this.setState({ viewProductAs: "compare", keyCompare: Math.random() })

                                    }}>
                                    <span className="font-weight-bold text-uppercase">
                                        {'Compare'}
                                    </span>
                                </NavLink>
                            </NavItem>                           
                            <NavItem className="mw-100">
                                {/* <SocialPinFlag productDetails={this.props.product_details} forceToggleLoginModal={this.forceToggleLoginModal} /> */}
                                <SocialButtons categoryId={productId} showHistoryTab categoryType="products" categoryDetails={this.props.product_details} forceToggleLoginModal={this.forceToggleLoginModal} bgLight={false}/>
                            </NavItem>
                        </Nav>
                    </Container>
                </header>

                {/* Main Content */}
                <section id="enable-sticky" className="py-4 fs-14">
                    <Container>
                        <Row>
                            <Col md={4}>
                                {/* What Reviewers have say */}
                                {what_reviewers_say && what_reviewers_say?.count >= 1
                                    && <WhatReviewersSay
                                        id={productId}
                                        what_reviewers_say={what_reviewers_say}
                                    />
                                }

                                {/* Detailed Star Rating */}
                                <DetailedStarRating
                                    total_rating={productDetail?.total_rating || null}
                                    id={productId}
                                    bgLight={true}
                                    charts={{ bar: true, line: true, genderTable: true }} />

                                {/* Friends Recommendation */}
                                <FriendsProfile
                                        profileData={this.state.friendsProfileRecommend}
                                        title="Recommends" />

                                {/* Friends want this */}
                                <FriendsProfile
                                        profileData={this.state.friendsProfileWant}
                                        title="Want" />

                                {/* Friends favorited this */}
                                <FriendsProfile
                                        profileData={this.state.friendsProfileFavourite}
                                        title="Favorited" />

                               
                                {/* Specifications */}
                                <Specifications Specification={this.state.productDetail !== null ? this.state.productDetail : ""}
                                    productId={this.state.productId} forceToggleLoginModal={this.forceToggleLoginModal} />
                                
                                {/* Technical Details */}
                                <TechnicalDetails TechnicalDetail={this.state.productDetail !== null ? this.state.productDetail : ""}
                                    productId={this.state.productId} forceToggleLoginModal={this.forceToggleLoginModal} />

                                {/* Compare Similar Products */}
                                <div className="bg-white-shaded-md text-secondary mb-3">
                                    <CollapseBasic title="Compare Similar Products" containerClass="bg-transparent type-secondary" bodyClass="text-secondary" isOpen={true} detailId={this.state.productId} >
                                        <LeftCompareProducts
                                            aboutDetails={this.state.productDetail !== null ? this.state.productDetail : ""}
                                            allProducts={this.state.similarProducts !== null ? this.state.similarProducts : ""}
                                            productId={this.state.productId}
                                            chartStar_Ratings={this.state.chartStarRatings !== null ? this.state.chartStarRatings : ""}
                                            getloaded={this.getloaded}
                                        />
                                    </CollapseBasic>
                                </div>

                                {/* Tips & Warnings */}
                                <TipsAndWarning id={this.state.productId} name={this.state.productName} toggleConfirmModal={this.toggleConfirmModal} forceToggleLoginModal={this.forceToggleLoginModal} />

                                {/* First to review */}
                                {productDetail?.first_review_info && Array.isArray(productDetail?.first_review_info) && productDetail?.first_review_info?.length ? (
                                    <div className="bg-white-shaded-md p-3 mb-3">
                                        <div className="d-flex mx-n2 fs-14">
                                            <div className="px-2">
                                                <Link className="fs-14 text-dark d-block" to="#" target="_blank">
                                                    <img
                                                        src={productDetail?.first_review_info[0]?.user_pic}
                                                        onError={(e) => {
                                                            e.target.src = 'https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png'
                                                        }}
                                                        className="object-fit-cover rounded-circle"
                                                        width={32}
                                                        height={32}
                                                        title={'title'}
                                                        alt="" />
                                                </Link>
                                            </div>
                                            <div className="px-2 col">
                                                <Link className="d-inline-block text-dark font-weight-bold ff-base" to="#" target="_blank">
                                                    {productDetail?.first_review_info[0]?.user_name}
                                                </Link>
                                                <div>
                                                    <span className="text-primary font-weight-bold ff-base">1<sup>st</sup> to Review</span>
                                                </div>
                                            </div>
                                            <div className="px-2">
                                                <img width="24" src="https://userdatawikireviews.s3.amazonaws.com/images/first-to-review.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}

                                {/* Other Offers */}
                                <OfferDetails
                                    id={productId}
                                    listing_type={'products'}
                                    itemDetail={productDetail}
                                    // toggleEnableFileUpload={this.toggleEnableFileUpload}
                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, false, false);                                       
                                    }}
                                    uploadedMediaSet={this.state.uploadedMediaSet}
                                    getUploadedMedia={this.getUploadedMedia}
                                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                                    toggleConfirmModal={this.toggleConfirmModal}
                                    setToggleDeleteOffer={this.setToggleDeleteOffer}
                                    deleteOfferDetails={this.state.deleteOfferDetails}
                                    loggedInUser={loggedInUser}
                                    getnocategory={this.getnocategory}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile={this.props.my_profile}
                                    resetUploadedMediaSet={this.resetUploadedMediaSet}
                                />
                                {/* Discuss This Page */}
                                <DiscussThisPage
                                    id={productId}
                                    listing_type={'products'}
                                    isLight={true}
                                    getUploadedMedia={this.getUploadedMedia}
                                    toggleEnableFileUpload={this.toggleEnableFileUpload}
                                    // uploadedMediaSet={this.state.uploaded_media}
                                    uploadedMediaSet={this.state.uploadedMediaSet}
                                    // mediaSet={this.state.mediaSet}
                                    mediaSet={this.state.mediaSet}
                                    discussTab={this.discussTab}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    getnocategory={this.getnocategory}
                                    resetUploadedMediaSet={this.resetUploadedMediaSet}
                                />

                                <div className="bg-white text-center">
                                    <a href="http://wikireviews.com/blog" rel="noopener noreferrer" target="_blank">
                                        <img className="img-fluid blog-img" src="https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_02.png" alt="Go to WikiReviews Herald" />
                                    </a>
                                </div>
                            </Col>

                            <Col md={8}>
                                {!(productDetail?.is_logged_user_review ?? true) && 
                                <CreateReview
                                    itemDetail={productDetail}
                                    overallRatingVote={overallRatingVote}
                                    viewEditReviewStep={viewEditReviewStep}
                                    mainViewType={this.state.mainViewType}
                                    listing_type={'products'}
                                    reviews={reviews}
                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true, false);                                       
                                    }}
                                    uploadedMediaSet={this.state.uploadedMediaSet}
                                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                                    onPublishReview={this.onPublishReview}
                                    my_review_count={loggedInUser?.review_statistics?.count}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                    categoryId={productId}
                                    resetUploadedMediaSet={this.resetUploadedMediaSet}
                                />
                                }   

                                <DetailReviews
                                    uniqueDeleteKey={'delete-products-review'}
                                    uniqueDeleteCommentKey={'delete-product-review-comment'}
                                    overallRatingVote={overallRatingVote}
                                    viewEditReviewStep={viewEditReviewStep}
                                    mainViewType={this.state.mainViewType}
                                    onPublishReview={this.onPublishReview}
                                    listing_type={'products'}
                                    reviews={reviews}
                                    item_detail={this.props.product_details}
                                    uploadedMediaSet={uploadedMediaSet}
                                    uploadedMediaSetFollow={uploadedMediaSetFollowUp}
                                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                                    removeUploadedMediaSetFollow={this.removeUploadedMediaSetFollow}
                                    toggleConfirmModal={this.toggleConfirmModal}
                                    // toggleEnableFileUpload={this.toggleEnableFileUpload}
                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload,newReviewStatus, followUpStatus)=>{
                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus,followUpStatus);
                                       
                                    }}
                                    getuploadUrl={this.getuploadUrl}
                                    discussTab={this.discussTab}
                                    notifiTab={this.state.notifiTab}
                                    getnocategory={this.getnocategory}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    getWallData={this.getWallData}
                                    handleOnSubmitShareByEmail={this.handleOnSubmitShareByEmail}
                                    my_profile={this.props.my_profile}
                                    resetUploadedMediaSet={this.resetUploadedMediaSet}
                                    getUploadedMedia={this.getUploadedMedia}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* View Media Modal */}
                <Modal isOpen={this.state.viewMediaModalToggle} toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}>
                    <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader>
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}>
                            <div className="item h-100 d-flex flex-column justify-content-center">
                                <div className="text-center">
                                    <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/content/61jPxT1VThL._SL1000_.7aa854c3c91c4f76680718ca686a8ba40cd955eb.jpg" alt="" />
                                </div>
                            </div>
                            <div className="item h-100 d-flex flex-column justify-content-center">
                                <div className="text-center">
                                    <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/content/image.86e21d0666615fe54c81bf87a167ca9609dd62ec.jpg" alt="" />
                                </div>
                            </div>
                            <div className="item h-100 d-flex flex-column justify-content-center">
                                <div className="text-center">
                                    <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/content/image.4376fbaea82bd6d002686a1900c479b3414b45e7.jpg" alt="" />
                                </div>
                            </div>
                        </Carousel>
                    </ModalBody>
                </Modal>

                {/* Share By Email Modal Popup */}
                <Modal size="sm" isOpen={this.state.shareByEmailModalToggle} toggle={() => this.setState({
                    shareByEmailModalToggle: !this.state.shareByEmailModalToggle
                })}>
                    <ModalHeader className="text-uppercase px-0" toggle={() => this.setState({
                        shareByEmailModalToggle: !this.state.shareByEmailModalToggle
                    })}>
                        Share by Email
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input className="primary" bsSize="sm" type="text" name="sharer_name" placeholder="Your name" />
                        </FormGroup>
                        <FormGroup>
                            <Input className="primary" bsSize="sm" type="email" name="email" placeholder="Recipient's email" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <div className="text-right">
                            <Button color="primary">Share</Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapState = (state) => ({
    loggedInUser: state.user.my_profile,
    product_details: state.item_detail.item_details, // from item_details
    back_to_listing: state.item_detail.back_to_listing, // from item_details
    current_location: state.home.current_location,
    fetch_similarProducts: state.products.fetch_similarProducts,
    get_review_listing: state.products.get_review_listing,
    chart_star_ratings: state.business.chart_star_ratings,
    auth_data: state.auth.auth_login,
    my_profile: state.user.my_profile,
    uploaded_media: state.item_detail.uploaded_media,
    what_reviewers_say: state.products.fetch_what_reviewers_say,
    friends_profile_recommend: state.shared.friends_profile_recommend,
    friends_profile_want: state.shared.friends_profile_want,
    friends_profile_favourite: state.shared.friends_profile_favourite,
    wallData: state.shared.wallData,
    editExistingMedia : state.shared.editExistingMedia,
    editExistingEmbedMedia : state.shared.editExistingEmbedMedia,
    isEditedReviewId: state.shared.isEditedReviewId,
    isEditReviewEnabled: state.shared.isEditReviewEnabled,
    newReviewMediaSet: state.reviewMedia.newReviewMediaSet,     
    newReviewEmbedLinkSetFinalized: state.reviewMedia.newReviewEmbedLinkSetFinalized,
    followReviewMediaSet: state.reviewMedia.followReviewMediaSet,     
    followReviewEmbedLinkSetFinalized: state.reviewMedia.followReviewEmbedLinkSetFinalized,
    editReviewMediaSetFinalized:state.reviewMedia.editReviewMediaSetFinalized,
    isFollowReviewEnable:state.shared.isFollowReviewEnable
});

const mapProps = (dispatch) => ({
    get_id_from_slug: ({ type, slug }) => dispatch(get_id_from_slug({ type, slug })), // from item_details
    get_type_detail: ({ type, id }) => dispatch(get_type_detail({ type, id })), // from item_details
    setItemDetailLoading:(data)=>dispatch(setItemDetailLoading(data)),
    fetch_silimar_products: (id) => dispatch(fetch_silimar_products(id)), // from item_details
    get_reviews_listing: (id, type) => dispatch(get_reviews_listing(id, type)),
    get_chart_star_ratings: (id) => dispatch(get_chart_star_ratings(id)),
    delete_products_review: (id, movie_id, name) => dispatch(delete_products_review(id, movie_id, name)),
    delete_tips_warning: (id, productId, type) => dispatch(delete_tips_warning(id, productId, type)),
    delete_problem: (id, productId, message) => dispatch(delete_problem(id, productId, message)),
    clear_type_details: () => dispatch(clear_type_details()),
    my_profile_info: () => dispatch(my_profile()),
    get_uploaded_media: (data) => dispatch(get_uploaded_media(data)),
    get_uploaded_media_follow: (data) => dispatch(get_uploaded_media_follow(data)),
    resetItemsState: () => dispatch({ type: 'RESET_ITEM_DETAIL' }),
    resetProductsState: () => dispatch({ type: 'RESET_PRODUCTS' }),
    get_friends_profile: (type, productId, pageNo) => dispatch(get_friends_profile(type, productId, pageNo)),
    fetch_what_reviewers_say: (productId) => dispatch(fetch_what_reviewers_say(productId)),
    get_wall_data: () => dispatch(get_product_details_wall_data()),
    setEditExistingMedia:(data)=>dispatch(setEditExistingMedia(data)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),    
    uploadMediaInReviewMediaSetForNewReview: (data) => dispatch(uploadMediaInReviewMediaSetForNewReview(data)),
    uploadMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(uploadMediaInReviewEmbedLinkSetForNewReview(data)),
    finalizedMediaInReviewMediaSetForNewReview: (data) => dispatch(finalizedMediaInReviewMediaSetForNewReview(data)),
    finalizedMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForNewReview(data)),
    uploadMediaInReviewMediaSetForEditReview: (data) => dispatch(uploadMediaInReviewMediaSetForEditReview(data)),
    uploadMediaInReviewMediaSetForFollowReview: (data) => dispatch(uploadMediaInReviewMediaSetForFollowReview(data)),
    finalizedMediaInReviewEmbedLinkSetForFollowReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForFollowReview(data)),


});


export default withRouter(connect(mapState, mapProps)(ProductDetails));
