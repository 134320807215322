import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Progress,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { callApi } from "../../../utils/apiCaller";
import { Link } from 'react-router-dom';

import {
  upload_media,
  initFetchProductSubCategories,
  clear_cat_sub_product
} from "../../../actions/footer";

import {
  add_product,
  clear_product
} from "../../../actions/products";

class RateProductModal extends React.Component {
  constructor(props) {
    super(props);
    this.myScrollPhotoRef = React.createRef();
    this.myScrollVideoRef = React.createRef();

    this.refGalleryVideoScroll = React.createRef();
    this.refGalleryImageScroll = React.createRef();

    this.refScrollDeleteImages = React.createRef();
    this.refScrollDeleteVideos = React.createRef();
    this.state = {
      addProductForm: {
        name: '',
        manufacturer: "",
        modelNumber: null,
        is_draft: false,
        additional_info: {
          price_min: "",
          price_max: "",
          packedWeight: "",
          packed_height: "",
          packed_depth: "",
          packed_width: "",
          packed_weight: "",
          assembled_weight: "",
          assembled_width: "",
          assembled_depth: "",
          assembled_height: "",
          assembled_weight: "",
          manufacturer_warranty: "",
          manufacturer_cut_sheet: "",
        },
        taxonomy: [""],
        media: [""],
        websites_set: [
          {
            website: "",
            website_type: ""
          },
        ],
        phones_set: [
          {
            phone: "",
            label: "",
          },
        ],
        features_set: [
          {
            features: "",
            label: "",
          },
        ],
      },
      name: '',
      manufacturer: "",
      modelNumber: null,
      maxPrice: "",
      minPrice: "",
      manufactureWarranty: "",
      manufactureCutSheet: "",
      assembledWeight: "",
      assembledHeight: "",
      assembledDepth: "",
      assembledWidth: "",
      packedWeight: "",
      packedHeight: "",
      packedDepth: "",
      packedWidth: "",
      category_id: '',
      subcategory_id: '',
      myPostsList: {},
      anothersubcategory_id: '',
      othersubcategory_id: '',
      websites_set: [
        {
          website: "",
          website_type: ""
        },
      ],
      phones_set: [
        {
          phone: "",
          label: "",
        },
      ],
      features_set: [
        {
          features: "",
          label: "",
        },
      ],
      mediaSet: [],
      uploadMediaModal: false,
      media: [],
      fileReviewStatus: false,
      selectedFilesPreview: [],
      latestUploadedData: {},
      showVideoMedia: true,
      fetchTaxonomy: [],
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      files: [],
      nameError: "",
      manufactureError: "",
      categoryError: "",
      productSubcategory: [],
      productAnothSabcategory: [],
      productOtherSabcategory: [],
      uploadCaptions: {},
      taxonomycategoryId: "",
      fetchTaxomomyCategory: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      albumType: "",
      priceError: "",
      uploadMediaPostModal: false,
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
    };
  }

  componentWillReceiveProps(nextProps) {



    if (nextProps.productSabcategories && Array.isArray(nextProps.productSabcategories) && nextProps.productSabcategories.length > 0) {
      this.setState({ productSubcategory: nextProps.productSabcategories });
    }
    if (nextProps.productAnothSabcategories && Array.isArray(nextProps.productAnothSabcategories) && nextProps.productAnothSabcategories.length > 0) {
      this.setState({ productAnothSabcategory: nextProps.productAnothSabcategories });
    }
    if (nextProps.productOtherSabcategories && Array.isArray(nextProps.productOtherSabcategories) && nextProps.productOtherSabcategories.length > 0) {
      this.setState({ productOtherSabcategory: nextProps.productOtherSabcategories });
    }

  }

  componentWillUnmount() {
    this.props.clear_cat_sub_product();
    this.props.clear_product();
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    if (name == "name") {
      this.setState({
        nameError: ""
      });
    } else if (name == "manufacturer") {
      this.setState({
        manufactureError: ""
      });
    } else if (name == "category_id") {
      this.setState({
        categoryError: ""
      });
    } else if (name == "minPrice") {
      this.setState({
        priceError: ""
      });
    } else if (name == "maxPrice") {
      this.setState({
        priceError: ""
      });
    }

    if (name == "category_id") {
      let type = "category_id"
      this.setState({
        category_id: e.target.value
      });
      this.props.initFetchProductSubCategories(e.target.value, type);
    }
    if (name == "subcategory_id") {
      let type = "subcategory_id"
      this.setState({
        category_id: e.target.value
      });
      this.props.initFetchProductSubCategories(e.target.value, type);
    }

    if (name == "anothersubcategory_id") {
      let type = "anothersubcategory_id"
      this.setState({
        category_id: e.target.value
      });
      this.props.initFetchProductSubCategories(e.target.value, type);
    }

  }

  handleCancel = () => {
    this.setState({
      productSubcategory: [],
      productAnothSabcategory: [],
      productOtherSabcategory: []
    });
    this.props.clear_cat_sub_product();
    this.props.toggleRateProductModal();
  }

  handleProductSubmit = () => {
    let { name, manufacturer, websites_set, phones_set, features_set, category_id, minPrice, maxPrice } = this.state;
    if (name == "" && manufacturer == "" && category_id == "") {
      this.setState({
        nameError:
            "Required",
        manufactureError:
            "Required",
        categoryError:
            "Required",
      });
    } else if (manufacturer == "") {
      this.setState({
        manufactureError:
            "Required",
      });
    } else if (name == "") {
      this.setState({
        nameError:
            "Required",
      });
    } else if (category_id == "") {
      this.setState({
        categoryError:
            "This field is required",
      });
    } else if (maxPrice < minPrice) {
      this.setState({
        priceError:
            "Max Price should not be less than Min Price",
      });
    } else {
      let params = {}
      let media = []
      /*this.state.uploadMedia.selectedMediaIds?.map(med => {
        media.push(med.id)
      })*/
      let intItem = parseInt(this.state.category_id);
      let minPrice = parseInt(this.state.minPrice);
      let maxPrice = parseInt(this.state.maxPrice);
      let packedHeight = parseInt(this.state.packedHeight);
      let packedWidth = parseInt(this.state.packedWidth);
      let packedWeight = parseInt(this.state.packedWeight);
      let packedDepth = parseInt(this.state.packedDepth);
      let assembledWeight = parseInt(this.state.assembledWeight);
      let assembledDepth = parseInt(this.state.assembledDepth);
      let assembledHeight = parseInt(this.state.assembledHeight);
      let assembledWidth = parseInt(this.state.assembledWidth);
      params = {
        name: this.state.name,
        is_draft: this.state.is_draft,
        additional_info: {
          price_min: minPrice,
          price_max: maxPrice,
          packed_height: packedHeight,
          packed_depth: packedDepth,
          packed_width: packedWidth,
          packed_weight: packedWeight,
          assembled_weight: assembledWeight,
          assembled_width: assembledWidth,
          assembled_depth: assembledDepth,
          assembled_height: assembledHeight,
          manufacturer_warranty: this.state.manufactureWarranty,
          manufacturer_cut_sheet: this.state.manufactureCutSheet,
        },
        manufacturer: this.state.manufacturer,
        website_set: websites_set && websites_set[0] && websites_set[0].website == "" ? {} : websites_set,
        phone_set: phones_set && phones_set[0] && phones_set[0].phone == "" ? {} : phones_set,
        feature_set: features_set && features_set[0] && features_set[0].features == "" ? {} : features_set,
        taxonomy: [intItem],
      }
      if (this.state.modelNumber != "" || this.state.modelNumber != null) {
        params.model_number = this.state.modelNumber
      }
      if (this.state.uploadMedia.selectedMediaIds.length > 0) {
        params.media = this.state.uploadMedia.selectedMediaIds
      }
      this.props.add_product(params)
    }
  }

  render() {
    const {
      openModal,
      toggleRateProductModal,
      products,
      videos_children,
    } = this.props;
    const { addProductForm, category_id, subcategory_id, anothersubcategory_id, name, manufacturer, maxPrice, minPrice, modelNumber, websites_set,
      phones_set, features_set, manufactureCutSheet, manufactureWarranty, mediaSet, latestUploadedData, selectedFilesPreview,
      showVideoMedia, fetchTaxonomy, embeddedLink, assembledWeight, assembledHeight, assembledDepth, assembledWidth,
      packedDepth, packedHeight, packedWeight, packedWidth, productSubcategory, productAnothSabcategory, productOtherSabcategory, othersubcategory_id, fetchTaxomomyCategory,
      fetchTaxonomyListingImages, fetchTaxonomyListingVideos, myPostsList} = this.state;

    return (
        <>
          /* Add Product drawer modal popup */
          <Modal
              size="xl" scrollable className="drawer-modal"
              isOpen={openModal}
              toggle={toggleRateProductModal}
          >
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button color="primary" onClick={toggleRateProductModal}>
                    <FontAwesomeIcon icon="angle-left" /> back to listing</Button>
                </div>
                <div>
                  <div className="bg-darker p-3 text-tertiary">
                    <div className="mb-2 text-primary font-weight-bold">
                      You’re Adding a New Product Listing
                    </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input type="select" className="transparent" size="sm">
                              <option>Products</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </li>
                      <li className="px-2">
                        <div className="d-flex">
                          <div className="mr-1">
                            <span className="text-danger">*</span>
                          </div>
                          <FormGroup>
                            <Input
                                id="category_id"
                                name="category_id"
                                onChange={this.handleChange}
                                type="select"
                                className="transparent"
                                bsSize="sm"
                                selected={category_id}
                            >
                              <option value="">Select Category</option>
                              {products && products.length > 0 ? products.map((product) => {
                                return (
                                    <option value={product.id} key={product.id}>
                                      {product.category}
                                    </option>

                                );
                              }) : ("")}
                            </Input>
                            <span style={{ color: 'red' }}>{this.state.categoryError}</span>
                          </FormGroup>
                        </div>
                      </li>
                      <li className="px-2" hidden={productSubcategory && productSubcategory.length > 0 ? false : true}>
                        <FormGroup>
                          <Input
                              id="subcategory_id"
                              name="subcategory_id"
                              onChange={this.handleChange}
                              type="select"
                              className="transparent"
                              bsSize="sm"
                              selected={subcategory_id}
                          >
                            <option value="">Select SubCategory</option>
                            {productSubcategory && productSubcategory.length > 0 ? productSubcategory.map((productsub) => {
                              return (

                                  <option value={productsub.id} key={productsub.id}>
                                    {productsub.category}
                                  </option>
                              );
                            }) : ("")}
                          </Input>
                        </FormGroup>
                      </li>

                      <li className="px-2" hidden={productAnothSabcategory && productAnothSabcategory.length > 0 ? false : true}>
                        <FormGroup>
                          <Input
                              id="anothersubcategory_id"
                              name="anothersubcategory_id"
                              onChange={this.handleChange}
                              type="select"
                              className="transparent"
                              size="sm"
                              selected={anothersubcategory_id}
                          >
                            <option value="">Select SubCategory</option>
                            {productAnothSabcategory && productAnothSabcategory.length > 0 ? productAnothSabcategory.map((productanothsub) => {
                              return (
                                  <option value={productanothsub.id} key={productanothsub.id}>
                                    {productanothsub.category}
                                  </option>

                              );
                            }) : ("")}
                          </Input>
                        </FormGroup>
                      </li>

                      <li className="px-2" hidden={productOtherSabcategory && productOtherSabcategory.length > 0 ? false : true}>
                        <FormGroup>
                          <Input
                              id="othersubcategory_id"
                              name="othersubcategory_id"
                              onChange={this.handleChange}
                              type="select"
                              className="transparent"
                              size="sm"
                              selected={othersubcategory_id}
                          >
                            <option value="">Select SubCategory</option>
                            {productOtherSabcategory && productOtherSabcategory.length > 0 ? productOtherSabcategory.map((productothsub) => {
                              return (
                                  <option value={productothsub.id} key={productothsub.id}>
                                    {productothsub.category}
                                  </option>
                              );
                            }) : ("")}
                          </Input>
                        </FormGroup>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ModalBody className="bg-white text-dark">
              <div className="wk-crpmodal">
                <Row>
                  <Col md="6">
                    <div class=" font-weight-bold mb-2 text-primary mb-3">Step 1. Rate Image</div>
                    <p>How would you rate this image?</p>
                    <img alt="" src="https://userdatawikireviews.s3.amazonaws.com/media/content/296878r5.jpg" className="wk-rate-image-left mb-3" />
                    <p className="mb-0">Submitted by <a href="#">Anonymous User</a></p>
                    <p><a href="#" className="font-normal">2 months ago</a></p>
                  </Col>
                  <Col md="6">
                    <p>Rate This Project</p>
                    <div className="mb-2">
                      <input type="radio" className="mr-2"/>
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                    </div>
                    <p>How much do you like this picture? Choose a category for this image: Storefront pic</p>
                    <textarea placeholder="" class="form-control wk-crpmodal-quick-note">Quick Note.(optional)</textarea>
                    <div className="text-right mt-3">
                      <Button color="primary" size="sm" onClick={this.handleMovieSubmit}>
                        Submit
                      </Button>
                      <a href="#" className="ml-4">Skip ></a>
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </Modal>
        </>
    )
  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:state.business.videos_children && state.business.videos_children[0],
  productSabcategories: state.footer.productSabcategories,
  productAnothSabcategories: state.footer.productAnothSabcategories,
  productOtherSabcategories: state.footer.productOtherSabcategories,
  fetch_save_response: state.products.fetch_save_response,
});

const mapProps = (dispatch) => ({
  clear_product: () => dispatch(clear_product()),
  initFetchProductSubCategories: (categoryId, type) => dispatch(initFetchProductSubCategories(categoryId, type)),
  clear_cat_sub_product: () => dispatch(clear_cat_sub_product()),
  add_product: (data) => dispatch(add_product(data)),

});

export default withRouter(connect(mapState, mapProps)(RateProductModal));
