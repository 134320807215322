const initialState = {
    fetched_pin_points: null,
    latest_two_reviews: null,
    pin_status: null
};

const picks = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "FETCH_PIN_DETAILS":
            state.fetched_pin_points = action.payload;
            state.pin_status = null;
            return { ...state };

        case "FETCH_LATEST_TWO_REVIEWS":
            state.latest_two_reviews = action.payload;
            state.pin_status = null;
            return { ...state };

        case "PIN_STATUS":
            state.pin_status = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default picks;