const initialState = {
    fetch_similarProducts: null,
    fetch_compareProducts: null,
    fetch_tips_war_list: null,
    fetch_problem_list: null,
    fetch_problem_record: null,
    get_review_listing: null,
    fetch_save_response: null,
    fetch_what_reviewers_say: null,
};

const products = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "GET_REVIEW_LISTING_PRODUCT":
            state.get_review_listing = action.payload;
            return { ...state };

        case "FETCH_SIMILAR_PRODUCTS":
            state.fetch_similarProducts = action.payload;
            state.fetch_similarProducts['random'] = Math.random();
            return { ...state };
        case "FETCH_COMPARE_PRODUCTS":
            state.fetch_compareProducts = action.payload;
            state.fetch_compareProducts['random'] = Math.random();
            return { ...state };
        case "GET_TIPS_WARNING":
            state.fetch_tips_war_list = action.payload;
            return { ...state };
        case "GET_PROBLEM_SOLUTION":
            state.fetch_problem_list = action.payload;
            return { ...state };
        case "GET_PROBLEM_RECORD":
            state.fetch_problem_record = action.payload;
            return { ...state };

        case "GET_SAVE_RESPONSE":
            state.fetch_save_response = action.payload;
            return { ...state };

        case 'FETCH_WHAT_REVIEWERS_SAY':
            state.fetch_what_reviewers_say = action.payload;
            return { ...state }

        case 'RESET_PRODUCTS':
            return initialState;

        default:
            return state;
    }
};

export default products;
