/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router'
import { Button, FormGroup, Label, Form, Input } from 'reactstrap';
import { get_work_history, add_work_history, search_organization, edit_work_history, delete_work_history } from '../../../actions/workeducation';
import Moment from 'react-moment';
import EditBtn from "../../atoms/editBtn";
import DeleteBtn from "../../atoms/deleteBtn";
import { get, debounce } from 'lodash';
import DatePicker from "react-datepicker";
import moment from 'moment';

class WorkHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOrganization: null,
            addHistoryToggle: false,
            organization_name: '',
            designation: '',
            is_present: false,
            workStartDate: '',
            workEndDate: '',
            errors: {},
            editFormVisible: {},
            editItem: null
        }
        this.workHistoryRef = React.createRef();
    }

    debounceSearchOrganization = debounce((value) => {
        this.props.search_organization(value);
    }, 500);

    handleChange = (e) => {
        const { errors } = this.state

        let value = e.target.value;
        let name = e.target.name;
        if (name === 'is_present') {
            value = !this.state.is_present;
        }

        delete errors[name]     //deleting error on change
        if (name === 'is_present')
            delete errors['workEndDate']

        this.setState({
            ...this.state,
            [name]: value,
            errors: errors
        });
        if (name === 'organization_name') {
            this.debounceSearchOrganization(value);
        }
    }

    handleEditChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        if (name === 'is_present') {
            value = !this.state.editItem.is_present;
        }
        this.setState({
            editItem: {
                ...this.state.editItem,
                [name]: value,
            }
        });
        if (name === 'organization_name') {
            this.props.search_organization(value);
        }
    }

    componentDidMount = () => {
        const { id } = this.props
        this.props.get_work_history(id)
    }

    componentDidUpdate(prevProps){
        if(prevProps.id !== this.props.id)
           this.props.get_work_history(this.props.id)

        if ((this.props.subMainViewType !== prevProps.subMainViewType) && this.props.subMainViewType === 'work history') {
            this.workHistoryRef.current.scrollIntoView({ behavior: 'smooth' });
        } 
    }

    handleOnClickEditWorkHistory = (item) => {
        const editItem = {
            organization_name: item.entry_name,
            designation: item.title,
            is_present: item.is_present,
            workStartDate: new Date(item.from_date),
            workEndDate: item.is_present ? '' : new Date(item.to_date),
            errors: {},
            search_id: item.id,
            id: item.id
        }
        this.setState({
            editFormVisible: {
                [item.id]: true
            },
            editItem: editItem,
            addHistoryToggle: false
        });
    }

    handleOnClickDeleteWorkHistory = (id) => {
        this.props.delete_work_history(id);
    }

    handleAddToggle = () => {
        this.setState({
            selectedOrganization: null,
            addHistoryToggle: true,
            organization_name: '',
            designation: '',
            is_present: false,
            workStartDate: '',
            workEndDate: '',
            errors: {},
            editFormVisible: {}
        });
    }

    hideAddCollapse = () => {
        this.setState({
            selectedOrganization: null,
            addHistoryToggle: false,
            organization_name: '',
            designation: '',
            is_present: false,
            workStartDate: '',
            workEndDate: '',
            errors: {},
            editFormVisible: {}
        });
    }

    hideEditCollapse = () => {
        this.setState({
            selectedOrganization: null,
            addHistoryToggle: false,
            editItem: null,
            editFormVisible: {}
        });
    }

    handleAddWork = () => {
        let errors = { ...this.state.errors };
        if (this.state.designation === '') {
            errors.designation = 'This field is required';
        } else {
            delete errors.designation;
        }
 

        if (!this.state.selectedOrganization)
            errors.organization_name = 'Please select the Organization from dropdown list';
        else if (this.state.organization_name === '' || Object.keys(this.state.selectedOrganization).length < 1) {
            errors.organization_name = 'Please select the Organization from dropdown list';
        } else {
            delete errors.organization_name;
        }

        if (this.state.workStartDate === '') {
            errors.workStartDate = 'This field is required';
        } else {
            delete errors.workStartDate;
        }

     
        if (!this.state.is_present && this.state.workEndDate === '') {
            errors.workEndDate = 'This field is required';
        } else if ( this.state.workStartDate !== '' && (this.state.workEndDate !== '' || !this.state.workEndDate )) {
            const newDate = new Date();
            if (this.state.workStartDate > newDate) {
                errors.workStartDate = 'Start date must be less than your current date';
            } else if (this.state.workEndDate > newDate && !this.state.is_present) {
                errors.workEndDate = 'End date must be less than your current date';
            } else if (this.state.workStartDate >= this.state.workEndDate && !this.state.is_present)
                errors.workEndDate = 'End date should be greater than your start date.';
            else {
                delete errors.workEndDate;
                delete errors.workStartDate
            }
        } else {
            delete errors.workEndDate;
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => ({
                ...prevState,
                errors: errors
            }));
        } else {
            const formatted_workStartDate = moment(this.state.workStartDate).format("MM-yyyy");
            const formatted_workEndDate = this.state.is_present ? '' : moment(this.state.workEndDate).format("MM-yyyy");
            let data = {
                from_date: formatted_workStartDate,
                to_date: this.state.is_present ? '' : formatted_workEndDate,
                user_entry: this.state.organization_name,
                title: this.state.designation,
                search_id: '',
                from_date_month: formatted_workStartDate.split('-')[0],
                from_date_year: formatted_workStartDate.split('-')[1],
                to_date_month: this.state.is_present ? '' : formatted_workEndDate.split('-')[0],
                to_date_year: this.state.is_present ? '' : formatted_workEndDate.split('-')[1],
                is_present: this.state.is_present
            };

            if (this.state.selectedOrganization && Object.keys(this.state.selectedOrganization).length > 0) {
                data.search_id = this.state.selectedOrganization._id;
                this.props.add_work_history(data);
                this.setState({
                    selectedOrganization: null,
                    addHistoryToggle: false,
                    organization_name: '',
                    designation: '',
                    is_present: false,
                    workStartDate: '',
                    workEndDate: '',
                    errors: {},
                    editFormVisible: {}
                });
            }
        }
    }

    handleEditWork = () => {
        let errors = { ...this.state.editItem.errors };
        if (this.state.editItem.designation === '') {
            errors.designation = 'This field is required';
        } else {
            delete errors.designation;
        }

        if (!this.state.editItem.organization_name){
            errors.organization_name = 'Please select the Organization from dropdown list';
        }else{
            delete errors.organization_name;
        }

        if (this.state.editItem.workStartDate === '') {
            errors.workStartDate = 'This field is required';
        } else {
            delete errors.workStartDate;
        }

        if (!this.state.editItem.is_present && this.state.editItem.workEndDate === '') {
            errors.workEndDate = 'This field is required';
        } else if (this.state.editItem.workEndDate !== '' && this.state.editItem.workStartDate !== '') {
            if (this.state.editItem.workStartDate > this.state.editItem.workEndDate) {
                errors.workEndDate = 'End date should be bigger than start date';
            } else {
                const newDate = new Date();

                if (this.state.editItem.workEndDate > newDate) {
                    errors.workEndDate = 'End date should not be bigger than current date';
                } else {
                    delete errors.workEndDate;
                }
            }
        } else {
            delete errors.workEndDate;
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => ({
                ...prevState,
                editItem: {
                    ...prevState.editItem,
                    errors: errors
                }
            }));
        } else {
            const formatted_workStartDate = moment(this.state.editItem.workStartDate).format("MM-yyyy");
            const formatted_workEndDate = this.state.editItem.is_present ? '' : moment(this.state.editItem.workEndDate).format("MM-yyyy");
            let data = {
                from_date: formatted_workStartDate,
                to_date: this.state.editItem.is_present ? '' : formatted_workEndDate,
                user_entry: '', //this.state.editItem.organization_name,
                title: this.state.editItem.designation,
                search_id: '',
                from_date_month: formatted_workStartDate.split('-')[0],
                from_date_year: formatted_workStartDate.split('-')[1],
                to_date_month: this.state.editItem.is_present ? '' : formatted_workEndDate.split('-')[0],
                to_date_year: this.state.editItem.is_present ? '' : formatted_workEndDate.split('-')[1],
                is_present: this.state.editItem.is_present,
                id: this.state.editItem.id
            };

            if (this.state.selectedOrganization && Object.keys(this.state.selectedOrganization).length > 0) {
                data.search_id = this.state.selectedOrganization._id;
                data.user_entry = this.state.selectedOrganization._id;
            }
            this.props.edit_work_history(data);
            this.setState({
                selectedOrganization: null,
                addHistoryToggle: false,
                editItem: null,
                editFormVisible: {}
            });
        }
    }

    handleOnClickAddSelected = (item, type = 'add') => {
        const { errors } = this.state
        delete errors.organization_name
        this.props.search_organization('');
        if (type === 'add') {
            this.setState({
                selectedOrganization: item,
                organization_name: item._source.name,
                errors
            });
        } else {
            this.setState({
                selectedOrganization: item,
                editItem: {
                    ...this.state.editItem,
                    organization_name: item._source.name,
                    errors
                },
                errors
            });
        }
    }
    handleChangeDate = ({ name, value, isEdit = false }) => {
        const { errors } = { ...this.state };
        if (value && errors[name]) {
            delete errors[name]
        }
        if (!isEdit) {
            this.setState({
                ...this.state,
                [name]: value,
                errors: errors
            });
        } else {
            this.setState({
                editItem: {
                    ...this.state.editItem,
                    [name]: value,
                }
            });
        }
        

    
    }


    render() {
        const { addHistoryToggle,
            organization_name,
            designation,
            is_present,
            workStartDate,
            workEndDate,
            errors,
            editFormVisible,
            editItem
        } = this.state

        const { work_data, search_organization_info,heading, showAddNewWork=false } = this.props;

        return (
            <div id="workHistory" className="type-target" ref={this.workHistoryRef}>
                <h2 className="text-secondary">{heading ? heading + ' work history': 'My work history'}</h2>
                <hr className="bg-secondary" />


                <ul className="list-unstyled" style={{ maxHeight: '325px', overflowY: 'auto', overflowX: 'hidden' }}>
                    {work_data && work_data.length > 0 ? work_data.map(work => {
                            let profile_image = require('../../../assets/images/icons/placeholder-img.png');
                        if (work?.profile_image && Array.isArray(work?.profile_image) && work?.profile_image[0] && work?.profile_image[0].thumbnail) {
                            profile_image = work?.profile_image[0].thumbnail
                        }
                        return (
                            <li className="mb-3" key={work.id}>
                                <div className="d-flex flex-nowrap mx-n1">
                                    <div className="px-1 mt-1 mb-2 order-1 order-sm-1">
                                        <img src={profile_image} alt="" width={50} className="listing-profile-image" />
                                    </div>

                                    <div className="px-1 col-auto ml-auto order-2 order-sm-3">
                                        <div className="interactive">
                                            <div className="interactive-appear">
                                                <div className="text-nowrap">
                                                    <EditBtn color="gold" onClick={() => this.handleOnClickEditWorkHistory(work)} />
                                                    <DeleteBtn color="gold" onClick={() => this.handleOnClickDeleteWorkHistory(work.id)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="d-sm-none d-lg-block order-3 w-100"></div> */}
                                    <div className="d-flex flex-fill px-1 mx-n1 order-4 order-sm-2">
                                        <div className="text-secondary-dark px-1">
                                            <div className="font-weight-bold fs-14 ff-base lh-1-2 mb-1 text-break">
                                                <span>{work.title}</span>
                                                <span>&nbsp;at&nbsp;</span>
                                                <span>{work.user_entry}</span>
                                            </div>
                                            <div className="fs-13">
                                                <Moment format="MMMM YYYY">
                                                    {work.from_date}
                                                </Moment> - {!work.to_date ? work.is_present_label : (
                                                    <Moment format="MMMM YYYY">
                                                        {work.to_date}
                                                    </Moment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Edit Work History */}
                                {editItem && editFormVisible[work.id] && (
                                    <div className="shadow p-3 mt-2">
                                        <Form>
                                            <h5 className="text-secondary-dark fs-30">
                                                Edit Work History
                                        </h5>
                                            <div className="text-dark">
                                                <div className="mb-2 position-relative">
                                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">Organization</Label>
                                                    <Input
                                                        bsSize="sm"
                                                        type="text"
                                                        name="organization_name"
                                                        placeholder="Type/Search Organization"
                                                        value={editItem.organization_name}
                                                        onChange={this.handleEditChange}
                                                    />
                                                    {editItem.errors && editItem.errors.organization_name && (
                                                        <div className="text-danger small mt-1">
                                                            {editItem.errors.organization_name}
                                                        </div>
                                                    )}
                                                    {/* Instant Search Results */}
                                                    {search_organization_info && search_organization_info.length > 0 && (
                                                        <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                            {search_organization_info.map(item => {
                                                                return (
                                                                    <li className="result-item" role="button" key={item._id}>
                                                                        <div className="result-link fs-14" onClick={() => this.handleOnClickAddSelected(item, 'edit')}>
                                                                            <div className="d-flex">
                                                                                <div className="mr-2">
                                                                                    <img src={get(item,'_source.listing_profileimage[0].url', require('../../../assets/images/icons/placeholder-img-alt.jpg'))} width={40} alt="" />
                                                                                </div>
                                                                                <div>
                                                                                    <span>{item._source.name}</span>
                                                                                    <div>
                                                                                        <img title={item._source.total_rating?.avg_rating[1]} src={(!item._source.total_rating || item._source.total_rating?.avg_rating[1] === 'No Rating') ?  require('../../../assets/images/icons/norating.png') : require(`../../../assets/images/icons/${Math.floor(item._source.total_rating?.avg_rating[1])}rating.png`)} alt="" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <span>{item._source.category}</span>
                                                                                    </div>
                                                                                    <div className="text-muted">
                                                                                        <span>{item._source.address && item._source.address[0] && (
                                                                                            <span>
                                                                                                {item._source.address[0].address1},{item._source.address[0].city},{item._source.address[0].state},{item._source.address[0].country},
                                                                                                {item._source.address[0].zipcode}
                                                                                            </span>
                                                                                        )}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    )}
                                                </div>
                                                <div className="mb-2">
                                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">Designation</Label>
                                                    <Input
                                                        bsSize="sm"
                                                        type="text"
                                                        name="designation"
                                                        placeholder="Enter the designation"
                                                        value={editItem.designation}
                                                        onChange={this.handleEditChange}
                                                    />
                                                    {editItem.errors && editItem.errors.designation && (
                                                        <div className="text-danger small mt-1">
                                                            {editItem.errors.designation}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-2">
                                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">Start Date</Label>
                                                    <div className="custom-date-picker">
                                                        <DatePicker
                                                            placeholderText="Enter start date"
                                                            dateFormat="MMMM yyyy"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="scroll"
                                                            maxDate={new Date()}
                                                            className="form-control-sm form-control"
                                                            selected={editItem.workStartDate}
                                                            onChange={(date) => { this.handleChangeDate({ name: 'workStartDate', value: date, isEdit: true }) }}
                                                        />
                                                    </div>
                                                    {/* <Input
                                                        bsSize="sm"
                                                        type="month"
                                                        name="workStartDate"
                                                        placeholder="Enter start date"
                                                        value={editItem.workStartDate}
                                                        onChange={this.handleEditChange}
                                                    /> */}
                                                    {editItem.errors && editItem.errors.workStartDate && (
                                                        <div className="text-danger small mt-1">
                                                            {editItem.errors.workStartDate}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-2" hidden={editItem.is_present}>
                                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">End Date</Label>
                                                    <div className="custom-date-picker">
                                                        <DatePicker
                                                            placeholderText="Enter end date"
                                                            dateFormat="MMMM yyyy"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="scroll"
                                                            maxDate={new Date()}
                                                            className="form-control-sm form-control"
                                                            selected={editItem.workEndDate}
                                                            onChange={(date) => { this.handleChangeDate({ name: 'workEndDate', value: date, isEdit: true }) }}
                                                        />
                                                    </div>
                                                    {/* <Input
                                                        bsSize="sm"
                                                        type="month"
                                                        name="workEndDate"
                                                        placeholder="Enter end date"
                                                        value={editItem.workEndDate}
                                                        onChange={this.handleEditChange}
                                                    /> */}
                                                    {editItem.errors && editItem.errors.workEndDate && (
                                                        <div className="text-danger small mt-1">
                                                            {editItem.errors.workEndDate}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-2">
                                                    <FormGroup check>
                                                        <Label className="font-weight-bold ff-base" check>
                                                            <Input type="checkbox" name="is_present" value={editItem.is_present}
                                                                onChange={this.handleEditChange} checked={editItem.is_present} /> Currently here
                                                    </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={this.handleEditWork}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => this.hideEditCollapse()}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </div>
                                )}

                            </li>
                        )
                    }

                    ) : ''}
                </ul>



                <div>
                    {!showAddNewWork &&
                        <Button color="link" className="text-secondary-dark font-weight-bold p-0 hover-blue _not-icon" onClick={this.handleAddToggle}><span className="icon-add-new"></span> add new work</Button>
                    }
                    {addHistoryToggle ?
                        <div className="shadow p-3 mt-2" >
                            <Form>
                                <h5 className="text-secondary-dark fs-30">
                                    Add Work History
                                </h5>
                                <div className="text-dark">
                                    <div className="mb-2 position-relative">
                                        <Label size="sm" className="font-weight-bold mb-0 ff-base">Organization</Label>
                                        <Input
                                            bsSize="sm"
                                            type="text"
                                            name="organization_name"
                                            value={organization_name}
                                            onChange={this.handleChange}
                                            placeholder="Type/Search Organization"
                                        />
                                        {errors && errors.organization_name && (
                                            <div className="text-danger small mt-1">
                                                {errors.organization_name}
                                            </div>
                                        )}
                                        {/* Instant Search Results */}
                                        {search_organization_info && search_organization_info.length > 0 && (
                                            <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                {search_organization_info.map(item => {
                                                    return (
                                                        <li className="result-item" role="button" key={item._id}>
                                                            <div className="result-link fs-14" onClick={() => this.handleOnClickAddSelected(item)}>
                                                                <div className="d-flex">
                                                                    <div className="mr-2">
                                                                        <img src={get(item,'_source.listing_profileimage[0].url', require('../../../assets/images/icons/placeholder-img-alt.jpg'))} width={40} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <span>{item._source.name}</span>
                                                                        <div>
                                                                            <img title={item._source.total_rating?.avg_rating[1]} src={(!item._source.total_rating || item._source.total_rating?.avg_rating[1] === 'No Rating') ?  require('../../../assets/images/icons/norating.png') : require(`../../../assets/images/icons/${Math.floor(item._source.total_rating?.avg_rating[1])}rating.png`)} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <span>{item._source.category}</span>
                                                                        </div>
                                                                        <div className="text-muted">
                                                                            <span>{item._source.address && item._source.address[0] && (
                                                                                <span>
                                                                                    {item._source.address[0].address1},{item._source.address[0].city},{item._source.address[0].state},{item._source.address[0].country},
                                                                                    {item._source.address[0].zipcode}
                                                                                </span>
                                                                            )}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="mb-2">
                                        <Label size="sm" className="font-weight-bold mb-0 ff-base">Designation</Label>
                                        <Input
                                            bsSize="sm"
                                            type="text"
                                            name="designation"
                                            value={designation}
                                            onChange={this.handleChange}
                                            placeholder="Enter the designation"
                                        />
                                        {errors && errors.designation && (
                                            <div className="text-danger small mt-1">
                                                {errors.designation}
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-2">
                                        <Label size="sm" className="font-weight-bold mb-0 ff-base">Start Date</Label>
                                        <div className="custom-date-picker">
                                            <DatePicker
                                                placeholderText="Enter start date"
                                                dateFormat="MMMM yyyy"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="scroll"
                                                maxDate={new Date()}
                                                className="form-control-sm form-control"
                                                selected={workStartDate}
                                                onChange={(date) => { this.handleChangeDate({ name: 'workStartDate', value: date }) }}
                                            />
                                        </div>
                                        
                                        {/* <Input
                                            bsSize="sm"
                                            type="month"
                                            name="workStartDate"
                                            value={workStartDate}
                                            onChange={this.handleChange}
                                            placeholder="Enter start date"
                                        /> */}
                                        {errors && errors.workStartDate && (
                                            <div className="text-danger small mt-1">
                                                {errors.workStartDate}
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-2" hidden={is_present}>
                                        <Label size="sm" className="font-weight-bold mb-0 ff-base row pl-3">End Date </Label>
                                        <div className="custom-date-picker">
                                            <DatePicker
                                                placeholderText="Enter end date"
                                                dateFormat="MMMM yyyy"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="scroll"
                                                maxDate={new Date()}
                                                className="form-control-sm form-control"
                                                selected={workEndDate}
                                                onChange={(date) => { this.handleChangeDate({ name: 'workEndDate', value: date }) }}
                                            />
                                        </div>
                                            {/* <Input
                                            bsSize="sm"
                                            type="month"
                                            name="workEndDate"
                                            value={workEndDate}
                                            onChange={this.handleChange}
                                            placeholder="Enter end date"
                                        /> */}
                                            {errors && errors.workEndDate && (
                                                <div className="text-danger small mt-1">
                                                    {errors.workEndDate}
                                                </div>
                                            )}
                                    </div>
                                    <div className="mb-2">
                                        <FormGroup check>
                                            <Label className="font-weight-bold ff-base" check>
                                                <Input type="checkbox" onChange={this.handleChange}
                                                    name="is_present"
                                                    value={is_present} checked={is_present} /> Currently here
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="text-right">
                                        <Button size="sm" color="primary mr-2" onClick={this.handleAddWork}>
                                            Submit
                                        </Button>
                                        <Button size="sm" color="primary ml-0" onClick={() => this.hideAddCollapse()}>
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                            <hr />
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
        );
    }
}

const mapState = (state) => ({
    search_organization_info: state.workeducation.search_organization_info,
    work_data: state.workeducation.work_data,
});
const mapDispatch = (dispatch) => ({
    get_work_history: (id) => dispatch(get_work_history(id)),
    add_work_history: (data) => dispatch(add_work_history(data)),
    search_organization: (query) => dispatch(search_organization(query)),
    edit_work_history: (data) => dispatch(edit_work_history(data)),
    delete_work_history: (id) => dispatch(delete_work_history(id)),
});


export default withRouter(connect(mapState, mapDispatch)(WorkHistory));
