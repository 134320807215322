import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import { callApi } from "../../../utils/apiCaller";


export default class DmcaHistory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            selectedLeftTab: 'VIDEOS',
            dmcaHistory: [
                {
                    userName: 'rohit',
                    address: `655 Phive St.F.I.5 Apt5 Five Corners, FV, 55555`,
                    phoneNumber: '+5 (555) 555-5555',
                    email: 'rkkbkkdi893898@gmail.com',
                    originalWork: 'https://www.thehouseofbriskett.info',
                    allegedInfringWork: 'http://wwe.weaeaiif.com',
                    supportingDocuments: [{ name: 'rohit letter', size: '28kb', url: 'https://wiki.com' }]

                }
            ],
            restoreRemovedMaterialModalToggle: false,
            form: {
                name: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
                phone: "",
                email: "",
                removedMaterial: "",
                additionalInfo: "",
                signature: ""
            },
            supportingDoc: undefined,
            error: {},
            embedLink: [{ 'embedLink_0': "" }],

            collapsedIds: {}
        };
    }

    componentDidMount() {

    }
    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ isLoading: false });
        // You can also log the error to an error reporting service
    }

    fetchDmcaHistory = async ({ parent_category, sortBy = "date", statue = "Deleted" }) => {

        let url = `/api/taxonomy/?category=${  'A'}&depth=true&delsel=&ancestor=&sub_cat=${ 'A' }&level=`

        this.setState({ isLoading: true })
        let response = await callApi(url, 'GET');
        this.setState({ isLoading: false })
        if (response && response?.code !== 500 && response?.code !== 400) {
            this.setState({ dmcaHistory: response?.results });
        }
    }

    fetchSearchedDmcaHistory = async ({ e, sortBy = "date", statue = "Deleted", searchString }) => {

        if (e.target?.value && e.target?.value.length % 3 === 0) {
            let url = `/api/taxonomy/?category=${'id'}&depth=true&delsel=&ancestor=&sub_cat=${'sub_cat'}&level=`

            this.setState({ isLoading: true })
            let response = await callApi(url, 'GET');
            this.setState({ isLoading: false })
            if (response && response?.code !== 500 && response?.code !== 400) {
                this.setState({ dmcaHistory: response?.results });
            }
        }

    }

    onChangeInput = (e) => {
        const { error } = this.state
        const field = { [e.target.name]: e.target.value }
        delete error.name
        this.setState((state) => { return { form: { ...state.form, ...field }, error } })
    }

    onChangeEmbedLink = (e, index) => {
        const { embedLink } = this.state
        const field = { [e.target.name]: e.target.value }
        embedLink[index] = field
        this.setState((state) => { return { embedLink: embedLink } })
    }

    onChangeFileInput = (e) => {
        
        if (e.target?.files && e.target?.files.length > 0)
            this.setState({ supportingDoc: e.target.files[0] })
        else
            this.setState(({ supportingDoc: undefined }))
        
    }

    onChangeCheckBoxInput = (e) => {
       
        const field = { [e.target.name]: e.target.checked }
        this.setState((state) => { return { form: { ...state.form, ...field } } })
    }

    requiredFieldValidation = (form) => {
        const error = {}
        for (let [, [key, value]] of Object.entries(Object.entries(form))) {
            if (value === "" || !value)
                error[key] = "Required"
        }

        this.setState((state) => { return { error: { ...state.error, ...error } } })
        return error
    }

    onSubmit = (e) => {
        e.preventDefault()
        const { form, supportingDoc } = this.state
        const formData = new FormData();
        let errors = this.requiredFieldValidation(form)
        // if (Object.keys(errors).length > 0)
        //     return false

        for (let [, [key, value]] of Object.entries(Object.entries(form))) {
            formData.append(key, value);
        }

        if (supportingDoc) {
            formData.append('supportingDoc', supportingDoc, supportingDoc.name)
        }
        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1], pair);
        }
        this.postTakedown(formData, e)
    }

    postTakedown = (data, e) => {
        const target = e.target

        // callApi(`/api/projects/`, "POST", data).then((response) => {
        //     if (response && response.code !== 400 && response.code !== 500) {
        //         target.reset()
        //     }
        // });
    }

    onClickAddDeleteEmbedLink(type, index) {
        try {
            const { embedLink } = this.state
            
            if (type === 'PLUS') {
                embedLink.push({ [`embedLink_${index}`]: "" })
                this.setState({ embedLink: embedLink })
            } else if (type === 'MINUS') {
                embedLink.splice(index, 1)
                let updateData = [...embedLink]
               
                this.setState({ embedLink: updateData }, () => this.forceUpdate())
            }
        } catch (err) { console.log('err', err) }

    }

    render() {
        const { embedLink, collapsedIds, selectedLeftTab } = this.state

        return (
            <div>
                <AppHeader isEnableCover={false} />
                <Container className="jumbo-page-header" fluid={true}>
                    <h1>History</h1>
                </Container>
                <section className="pt-5">
                    <Container>
                        <Row>
                            <Col lg={3}>
                                <CollapseBasic title="All participate decisions" size="sm" noPadding={true} containerClass="bg-transparent mb-3 type-tertiary title-type-sm expanded-dark-title" bodyClass="text-dark fs-14 ff-alt" isOpen={false}>
                                    <ul className="list-unstyled text-tertiary font-weight-bold mt-n2">
                                        <li onClick={() => this.setState({ selectedLeftTab: 'PARTICIPATE-DECISIONS' })} className={`py-3 border-bottom border-tertiary ${selectedLeftTab === 'PARTICIPATE-DECISIONS' && 'text-dark'}`} role="button">
                                            <span>All participate decisions lists </span>
                                        </li>
                                    </ul>
                                </CollapseBasic>
                                <CollapseBasic title="All ANB Decisions" size="sm" noPadding={true} containerClass="bg-transparent mb-3 type-tertiary title-type-sm expanded-dark-title" bodyClass="text-dark fs-14 ff-alt" isOpen={false}>
                                    <ul className="list-unstyled text-tertiary font-weight-bold mt-n2">
                                        <li onClick={() => this.setState({ selectedLeftTab: 'ANB-DECISIONS' })} className={`py-3 border-bottom border-tertiary ${selectedLeftTab === 'ANB-DECISIONS' && 'text-dark'}`} role="button">
                                            <span >   All ANB Decisions lists  </span>
                                        </li>
                                    </ul>
                                </CollapseBasic>
                                <CollapseBasic title="DMCA Takedown" size="sm" noPadding={true} containerClass="bg-transparent mb-3 type-tertiary title-type-sm expanded-dark-title" bodyClass="text-dark fs-14 ff-alt" isOpen={true}>
                                    <ul className="list-unstyled text-tertiary font-weight-bold mt-n2">
                                        <li onClick={() => this.setState({ selectedLeftTab: 'VIDEOS' })} className={`py-3 border-bottom border-tertiary ${selectedLeftTab === 'VIDEOS' && 'text-dark'}`} role="button">
                                            <span>Videos</span>
                                        </li>
                                        <li onClick={() => this.setState({ selectedLeftTab: 'IMAGES' })} className={`py-3 border-bottom border-tertiary ${selectedLeftTab === 'IMAGES' && 'text-dark'}`} role="button">
                                            <span>Images</span>
                                        </li>
                                        <li onClick={() => this.setState({ selectedLeftTab: 'TEXT' })} className={`py-3 border-bottom border-tertiary ${selectedLeftTab === 'TEXT' && 'text-dark'}`} role="button">
                                            <span>Text?</span>
                                        </li>
                                    </ul>
                                </CollapseBasic>
                                <div>
                                    <span onClick={() => this.setState({ selectedLeftTab: 'COPYRIGHT' })} className={`fs-16 ff-alt font-weight-bold text-tertiary ${selectedLeftTab === 'COPYRIGHT' && 'text-dark'}`} role="button">Copyright</span>
                                </div>
                            </Col>
                            <Col lg={9}>
                                <div className="fs-14 ff-alt text-dark">
                                    <div className="d-flex mx-n2">
                                        <div className="px-2 flex-grow-1">
                                            <span className="text-tertiary">Sort by</span>
                                            <UncontrolledDropdown className="d-inline-block mx-3">
                                                <DropdownToggle size="sm" className="bg-transparent text-dark font-weight-bold p-0 border-0 shadow-none" caret>
                                                    Date
                                                </DropdownToggle>
                                                <DropdownMenu className="type-tertiary-alt">
                                                    <DropdownItem >Date</DropdownItem>
                                                    <DropdownItem>Activity</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <UncontrolledDropdown className="d-inline-block mx-3">
                                                <DropdownToggle size="sm" className="bg-transparent text-dark font-weight-bold p-0 border-0 shadow-none" caret>
                                                    Status
                                            </DropdownToggle>
                                                <DropdownMenu className="type-tertiary-alt">
                                                    <DropdownItem>Restored</DropdownItem>
                                                    <DropdownItem>Deleted</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="px-2">
                                            <Input onChange={(e) => this.fetchSearchedDmcaHistory(e)} className="bg-search primary" type="search" bsSize="sm" placeholder="Search" />
                                        </div>
                                    </div>

                                    <div className="mt-2 bg-white p-3">
                                        <p className="text-primary font-weight-bold">
                                            Please select one thread from the list
                                        </p>

                                        <div>
                                            {Array.isArray(embedLink) && embedLink.length > 0 ? embedLink.map((item, index) => (<>

                                                <div className="review-bg p-3 mb-2">
                                                    {
                                                        collapsedIds.hasOwnProperty(item.id) ?
                                                            <>
                                                                {/* Collapsed View */}
                                                                <div>
                                                                    <div className="d-flex mx-n1">
                                                                        <div className="px-1 flex-grow-1">
                                                                            <div>
                                                                                <span className="fs-14">DMCA Takedown Request Submitted by <a href="#" className="text-reset font-weight-bold">Username</a></span>
                                                                            </div>
                                                                            <div>
                                                                                <span className="text-tertiary fs-12">2 days ago</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="px-1">
                                                                            <span
                                                                                onClick={() => {
                                                                                    const { collapsedIds } = this.state
                                                                                    delete collapsedIds[item.id]
                                                                                    this.setState({ collapsedIds: collapsedIds })
                                                                                }}
                                                                                className="icon-plus" title="Expand" role="button"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                {/* Expanded View */}
                                                                <div>
                                                                    <div className="d-flex mx-n1">
                                                                        <div className="px-1 flex-grow-1">
                                                                            <div>
                                                                                <span className="fs-15">DMCA Takedown Request Submitted by <a href="#" className="text-reset font-weight-bold">Username</a></span>
                                                                            </div>
                                                                            <div>
                                                                                <span className="text-tertiary fs-13">2 days ago</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="px-1">
                                                                            <span onClick={() => this.setState((state) => { return { collapsedIds: { ...state.collapsedIds, [item.id]: true } } })} className="icon-minus" title="Collapse" role="button"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-2 border-top pt-3">
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold">
                                                                                    Copyright Holder
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-bold">
                                                                                    Monty Python Jr.
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold">
                                                                                    Address
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-bold">
                                                                                    655 Phive St.
                                                                                <br />
                                                                                F.I.5 Apt5
                                                                                <br />
                                                                                Five Corners, FV, 55555
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold">
                                                                                    Phone Number
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-bold">
                                                                                    +5 (555) 555-5555
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold">
                                                                                    Email
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-bold">
                                                                                    <a href="mailto:monty@thehouseofbrisket.info" className="text-reset"> monty@thehouseofbrisket.info </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold">
                                                                                    Original work(s)
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-bold">
                                                                                    <a href="#" className="text-reset">http://www.thehouseofbriskett.info/s/galleries/c8980819239.png</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold">
                                                                                    Alleged Infringing work(s)
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-bold">
                                                                                    <a href="#" className="text-reset">http://www.wikireviews.com/houseofbriskett/gal/snorting.jpg</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold">
                                                                                    Supporting Documents
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-bold">
                                                                                    <span className="text-nowrap">
                                                                                        <a href="#" className="text-reset">letter_of_intent.doc</a>
                                                                                        {" "}
                                                                                        <i className="text-tertiary">28 Kb</i>
                                                                                    </span>
                                                                                    {",  "}
                                                                                    <span className="text-nowrap">
                                                                                        <a href="#" className="text-reset">patent-892384.pdf</a>
                                                                                        {" "}
                                                                                        <i className="text-tertiary">3 Mb</i>
                                                                                    </span>
                                                                                    {",  "}
                                                                                    <span className="text-nowrap">
                                                                                        <a href="#" className="text-reset">whatabouts-duh.jpg</a>
                                                                                        {" "}
                                                                                        <i className="text-tertiary">550 Kb</i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold">
                                                                                    Disclaimer(s)
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <p className="text-tertiary font-weight-normal">
                                                                                    "I have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law."
                                                                            </p>
                                                                                <p className="text-tertiary font-weight-normal">
                                                                                    "The Information in the notification is accurate and under penality of perjury that the complaining party is authorised to act on behalf of the owner of an exclusive right that is allegedly infringed."
                                                                            </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold">
                                                                                    Signed by
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-bold">
                                                                                    <a href="#" className="text-reset">Jolly Goodfellow</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* DMCA History */}
                                                                    <div className="mt-2 border-top pt-3">
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-bold fs-16">
                                                                                    DMCA History
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-normal">
                                                                                    02/03/2014
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-normal">
                                                                                    request submitted by <a href="#" className="text-reset font-weight-bold">John Username</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-normal">
                                                                                    02/03/2014
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-normal">
                                                                                    request submitted by <a href="#" className="text-reset font-weight-bold">John Username</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mx-n2 mb-2">
                                                                            <div className="px-2 flex-150">
                                                                                <div className="text-tertiary text-right font-weight-normal">
                                                                                    02/03/2014
                                                                            </div>
                                                                            </div>
                                                                            <div className="px-2 flex-grow-1">
                                                                                <div className="text-dark font-weight-normal">
                                                                                    request submitted by <a href="#" className="text-reset font-weight-bold">John Username</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-2 border-top pt-3">
                                                                        <div className="d-flex justify-content-end">
                                                                            <Button color="primary" size="sm">Request restoration of removed material</Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </div>

                                            </>)) :
                                                <h4>No Record found</h4>}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Restore Removed Material Modal */}
                <Modal isOpen={this.state.restoreRemovedMaterialModalToggle} toggle={() => this.setState({ restoreRemovedMaterialModalToggle: !this.state.restoreRemovedMaterialModalToggle })}>
                    <ModalHeader toggle={() => this.setState({ restoreRemovedMaterialModalToggle: !this.state.restoreRemovedMaterialModalToggle })}>
                        <div className="ff-alt fs-24 font-weight-bold">Restore Removed Material</div>
                    </ModalHeader>
                    <ModalBody className="fs-14">
                        <Form>
                            <div className="text-tertiary ff-alt mb-4">
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cumque, rerum quis quo dignissimos consectetur ex omnis? Omnis atque cum fuga labore corporis aperiam est odio, quos aspernatur ex impedit quis sunt voluptatum explicabo sequi, harum tempore, fugiat rem dolorum temporibus aliquam magnam exercitationem assumenda! Blanditiis nesciunt magnam nam velit ducimus.
                            </div>
                            <FormGroup className="form-group-primary form-row">
                                <Label size="sm" for="fullName" sm={3}>Name</Label>
                                <Col sm={9} md={6}>
                                    <Input required onChange={this.onChangeInput} type="text" bsSize="sm" name="name" id="fullName" placeholder="Type in your Name" />
                                </Col>
                            </FormGroup>
                            <FormGroup className="form-group-primary form-row">
                                <Label size="sm" for="address1" sm={3}>Address</Label>
                                <Col sm={9} md={6}>
                                    <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="address1" id="address1" placeholder="Type in your Address, Line 1" />
                                    <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="address2" id="address2" placeholder="Type in your Address, Line 2" />
                                </Col>
                                <Col sm={{ size: 9, offset: 3 }}>
                                    <Row form>
                                        <Col sm={7}>
                                            <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="city" id="city" placeholder="City" />
                                        </Col>
                                        <Col sm={2}>
                                            <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="state" id="state" placeholder="State" />
                                        </Col>
                                        <Col sm={3}>
                                            <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="zipCode" id="zip_code" placeholder="Zip Code" />
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <FormGroup className="form-group-primary form-row">
                                <Label size="sm" for="phone" sm={3}>Phone Number</Label>
                                <Col sm={9} md={6}>
                                    <Input required onChange={this.onChangeInput} type="tel" bsSize="sm" name="phone" id="phone" placeholder="Type in your phone Number" />
                                </Col>
                            </FormGroup>
                            <FormGroup className="form-group-primary form-row">
                                <Label size="sm" for="email" sm={3}>Email</Label>
                                <Col sm={9} md={6}>
                                    <Input required onChange={this.onChangeInput} type="email" bsSize="sm" name="email" id="email" placeholder="Type in your email Address" />
                                </Col>
                            </FormGroup>
                            <hr />
                            <FormGroup className="form-group-primary form-row">
                                <Label size="sm" for="removedMaterial" sm={3}>Removed Material</Label>
                                <Col sm={9} md={6}>
                                    <Input required onChange={this.onChangeInput} type="text" bsSize="sm" name="removedMaterial" id="removed_material" placeholder="Name of material" />
                                </Col>
                            </FormGroup>
                            <FormGroup className="form-group-primary form-row">
                                <Col sm={{ size: 9, offset: 3 }}>
                                    <Input onChange={this.onChangeInput} type="textarea" rows="4" bsSize="sm" name="additionalInfo" id="additional_info" placeholder="Describe the material that has been changed. (Optional)" />
                                </Col>
                            </FormGroup>
                            <FormGroup className="form-group-primary form-row">
                                <Label size="sm" for="documents" sm={3}>Supporting Documents</Label>
                                <Col sm={9} md={6}>
                                    <Input onChange={this.onChangeFileInput} type="file" bsSize="sm" className="mb-3" name="documents" id="documents" placeholder="Upload supporting files" />
                                </Col>
                                <Col sm={{ size: 9, offset: 3 }} md={{ size: 3, offset: 0 }}>
                                    <span className="text-tertiary ff-alt fs-14">or <span role="button" className="font-weight-bold">embed link</span></span>
                                </Col>
                                <Col sm={{ size: 9, offset: 3 }}>
                                    {/* Repeat this */}
                                    {embedLink.map((value, index) => (

                                        <div key={index} className="d-flex flex-nowrap mx-n1 mb-2">
                                            <div className="px-1 col">
                                                <Input onChange={(e) => this.onChangeEmbedLink(e, index)} type="text" bsSize="sm" name={`embedLink_${index}`} value={value[`${Object.keys(value)[0]}`]} id={`embed_link_${index}`} placeholder="Inset embed links" />
                                            </div>
                                            <div className="px-1">
                                                <Button onClick={() => this.onClickAddDeleteEmbedLink('PLUS', index + 1)} color="primary" size="sm" title="Add" className="mr-0">
                                                    <FontAwesomeIcon icon="plus" />
                                                </Button>
                                                {/* Hide below if one remains */}
                                                <Button disabled={Object.keys(embedLink).length === 1} onClick={() => this.onClickAddDeleteEmbedLink('MINUS', index)} color="danger" size="sm" title="Remove" className="ml-2">
                                                    <FontAwesomeIcon icon="minus" />
                                                </Button>
                                            </div>
                                        </div>
                                    ))}
                                </Col>
                            </FormGroup>
                            <hr />
                            <FormGroup className="form-group-primary mb-3 form-row" check inline>
                                <Label size="sm" className="text-tertiary" sm={3}>Disclaimer(s)</Label>
                                <Col sm={9}>
                                    <p className="text-tertiary fs-14">
                                        "Under penalty of perjury, I have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the meterial to be removed or disabled."
                                            </p>

                                    <p className="text-tertiary fs-14">
                                        "I hereby consent to the jurisdiction of the Federal District Court applicable for my address and agree to accept service of process from the person who was complained of copyright infringement."
                                            </p>
                                    <Label className="text-tertiary ff-alt" check>
                                        <Input required onChange={this.onChangeCheckBoxInput} type="checkbox" name="agree" /> I Agree
                                            </Label>
                                </Col>
                            </FormGroup>
                            <FormGroup className="form-group-primary form-row">
                                <Label size="sm" for="signature" sm={3}>Signature</Label>
                                <Col sm={9} md={5}>
                                    <Input required onChange={this.onChangeInput} type="text" bsSize="sm" name="signature" id="signature" placeholder="Electronic Signature" />
                                </Col>
                            </FormGroup>
                            <div className="p-3 text-right">
                                <Button type="submit" color="primary">Submit</Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div >
        )
    }
}
