import React, { Component } from 'react';

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <h1>Wikireviews Herald</h1>
        );
    }
}
 
export default Blog;