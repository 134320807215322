/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Row, Col, Button, Form, Input, Collapse, } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Actors from "../../molecules/dashboard/actors";
import MyEducation from "../../molecules/dashboard/myeducation";
import WorkHistory from "../../molecules/dashboard/workhistory";
import { API_URL } from "../../../utils/constants";
import { Helmet } from "react-helmet";
import MyFavorites from '../../molecules/dashboard/myfavorites';
import Chart from "react-google-charts";
import { callApi } from "../../../utils/apiCaller";
import { toast } from "react-toastify";
import EditBtn from '../../atoms/editBtn';



export default class ProfileTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            number_part_1: "",
            number_part_2: "",
            number_part_3: "",
            errors: {},
        };

        this.homeTheaterRef = React.createRef();
        this.usernameRef = React.createRef();
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutsideUsername);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('mousedown', this.handleClickOutsideUsername);
    }

    handleClickOutside = (event) => {
        if (this.props.isFieldShown && this.props.isFieldShown.theater && this.props.handleClickOutSideTheater && this.homeTheaterRef.current && !this.homeTheaterRef.current.contains(event.target)) {
            this.props.handleClickOutSideTheater();
        }
    }

    handleClickOutsideUsername = (event) => {
        if (this.props.isFieldShown && this.props.isFieldShown.username && this.props.handleClickOutSideUsername && this.usernameRef.current && !this.usernameRef.current.contains(event.target)) {
            this.props.handleClickOutSideUsername();
        }
    }

    render() {
        const {
            myProfile,
            isFieldShown,
            searchTheater,
            hideEditCollapse,
            search_theater_info,
            handleOnChangeSearchTheater,
            handleCheckAlbumType,
            myScrollPhotoRef,
            handlePhotoScroll,
            renderPhotos,
            myScrollVideoRef,
            handleVideoScroll,
            renderVideos,
            changePassword,
            myInfoError,
            searchUsername,
            search_username_info,
            handleOnChangeSearchUsername,
            myProfileError,
            subMainViewType
        } = this.props;

        const {
            number_part_1,
            number_part_2,
            errors,
            number_part_3
        } = this.state
        // get Contributions made 4th Chart Data
        if (myProfile && myProfile != 'null') {
            if (myProfile.statistics && myProfile.statistics.contribution_stats) {
                let newContributionGraph = myProfile.statistics.contribution_stats;
                if (newContributionGraph !== undefined && newContributionGraph.length === 5) {
                    for (let i = 0; i < newContributionGraph.length; i++) {
                        newContributionGraph[i][2] = newContributionGraph[i][1]

                    }
                    newContributionGraph.splice(0, 0, ["Contribution", "", { role: 'annotation' }]);
                }
            }
        }
        // get ReviewStats made 1st Chart Data
        if (myProfile && myProfile != 'null') {
            if (myProfile.review_statistics && myProfile.review_statistics.stats) {
                let newReviewStatsGraph = myProfile.review_statistics.stats;
                if (newReviewStatsGraph !== undefined && newReviewStatsGraph.length === 10) {
                    for (let i = 0; i < newReviewStatsGraph.length; i++) {
                        newReviewStatsGraph[i][3] = newReviewStatsGraph[i][1]
                        newReviewStatsGraph[i][0] = parseFloat(newReviewStatsGraph[i][0]).toString() + '\u2605';
                        newReviewStatsGraph[i][4] = `${parseFloat(newReviewStatsGraph[i][0]).toString() + '\u2605'} \n Rates Scale : ${newReviewStatsGraph[i][3]}`;

                    }
                    newReviewStatsGraph.splice(0, 0, ["", "Rates Scale", { role: 'style' }, { role: 'annotation' }, { type: 'string', role: 'tooltip' }]);
                }
                // removed console from here.
            }
        }

        // get demographics made 2nd Chart Data
        if (myProfile && myProfile != 'null') {
            if (myProfile.review_statistics && myProfile.review_statistics.demographics) {
                let newdemographicsGraph = myProfile.review_statistics.demographics;
                if (newdemographicsGraph !== undefined && newdemographicsGraph.length === 3) {
                    newdemographicsGraph.splice(0, 0, ["Type", "Value"]);
                }
            }
        }

	  return (
            <div>
                <Helmet>
                    <title>{myProfile && myProfile.user && myProfile.user.first_name && `${myProfile.user.first_name.charAt(0).toUpperCase() + myProfile.user.first_name.slice(1)} ${myProfile.user.last_name && myProfile.user.last_name.charAt(0).toUpperCase() + myProfile.user.last_name.slice(1)} Private Profile on WikiReviews`}</title>
                </Helmet>
                <Row>
                    <Col xs={12} className="flex-lg-350 wk-myprofile-left-tab">
                    
                        <div className="bg-white-shaded-md px-3 py-4 mb-3">
                            <h2 className="text-secondary">{`${myProfile?.user?.first_name} info`}</h2>
                            <hr className="bg-secondary" />


                            <div className="text-secondary-dark fs-14">
                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <div className="mb-2 flex-grow-1 font-weight-bold">
                                            {myProfile && myProfile.user && myProfile.user.first_name ? myProfile.user.first_name : ''}
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <div className="mb-2 flex-grow-1">
                                            <span className="">
                                              joined {myProfile && myProfile.joining_date_str && myProfile.joining_date_str ? myProfile.joining_date_str : ''}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <div className="mb-2 flex-grow-1">
                                            <span >
                                                {myProfile && myProfile.gender && myProfile.gender === 'm' ? 'Male, ' : ''}
                                                {myProfile && myProfile.gender && myProfile.gender === 'f' ? 'Female, ' : ''}

                                                {myProfile && myProfile.age && myProfile.age ? myProfile.age : ''}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="d-flex flex-nowrap">
                                        <div className="mb-2 flex-grow-1">
                                            <span>from </span>
                                            <span className="font-weight-bold">
                                                {myProfile && myProfile.permanent_address && `${myProfile.permanent_address.address1 ? myProfile.permanent_address.address1 : ''} ${myProfile.permanent_address.address2 ? myProfile.permanent_address.address2 : ''} ${myProfile.permanent_address.city ? myProfile.permanent_address.city + ',' : ''} ${myProfile.permanent_address.state ? myProfile.permanent_address.state : ''} ${myProfile.permanent_address.zipcode} ${myProfile.permanent_address.country_fullname}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="bg-white-shaded-md px-3 py-3 mb-3">
                            {myProfile?.id &&
                                <WorkHistory id={myProfile?.id} heading={myProfile?.user?.first_name} subMainViewType={subMainViewType} showAddNewWork={false} />
                            }
                        </div>

                        <div className="bg-white-shaded-md px-3 py-3 mb-3">
                           {myProfile?.user?.id &&
                            <MyFavorites id={myProfile?.user?.id} heading={myProfile?.user?.first_name} subMainViewType={subMainViewType} showAddNewfavourite={false} />
                           }
                        </div>

                    </Col>
                    <Col xs={12} className="flex-lg-350-rest">
                   
                        <div className="bg-white p-3 mb-3">
                            <h2 className="text-secondary fs-38 mb-3">{`${myProfile?.user?.first_name} photos`}</h2>
                            <div className="d-flex flex-wrap fs-14">
                                <div className="mr-2">
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'image', albumType: 'reviews' })}
                                        color="link" className="text-secondary font-weight-bold p-0">Pictures from reviews</Button>
                                </div>
                                <div className="ml-auto">
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'image', albumType: '' })}
                                        color="link" className="text-secondary font-weight-bold p-0">All</Button>
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'image', albumType: 'profile' })}
                                        color="link" className="text-secondary font-weight-bold p-0 ml-2">Profile Pictures</Button>
                                </div>
                            </div>
                            <hr className="mt-1" />
                            <div>
                                <ul
                                    ref={myScrollPhotoRef}
                                    onScroll={handlePhotoScroll}
                                    className="list-unstyled d-flex flex-nowrap flex-lg-wrap  mx-n1 mb-2 wk-upload-profile-photos"
                                    style={{ maxHeight: '205px', overflowY: 'auto' }}>
                                    {renderPhotos()}
                                </ul>

                            </div>
                        </div>

                        <div className="bg-white p-3 mb-3">
                            <h2 className="text-secondary fs-38 mb-3">{`${myProfile?.user?.first_name} videos`}</h2>
                            <div className="d-flex flex-wrap fs-14">
                                <div className="mr-2">
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'video', albumType: 'reviews' })}
                                        color="link" className="text-secondary font-weight-bold p-0">Videos from reviews</Button>
                                </div>
                                <div className="ml-auto">
                                    <Button
                                        onClick={() => handleCheckAlbumType({ filterType: 'video', albumType: '' })}
                                        color="link" className="text-secondary font-weight-bold p-0">All</Button>
                                </div>
                            </div>
                            <hr className="mt-1" />

                            <div>
                                <ul
                                    ref={myScrollVideoRef}
                                    onScroll={handleVideoScroll}
                                    className="list-unstyled d-flex flex-nowrap flex-lg-wrap  mx-n1 mb-2 wk-profile-video-upload"
                                    style={{ maxHeight: '280px', overflowY: 'auto' }}>
                                    {renderVideos()}
                                </ul>

                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
