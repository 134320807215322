import * as actions from "../actions/feeds";

const initialState = {
  feeds_data: null,
  get_dispute_data: null,
  get_jury_data: null,
  get_juror_data: null,
  get_reveiew_helpCount: null,
  get_feed_pinsCount: null,
  get_review_data: null,
  get_review_status: null,
  final_jury_data: null,
  add_dispute_status: null,
};


const feeds = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_INITIAL_AUTH_NEW':
      return initialState;
      
    case actions.SET_FILTER:
      return initialState;

    case actions.GET_FEEDS: {
      const existingResults = state?.feeds_data?.results || [];
      const updatedResults = existingResults.map(oldResult => {
        let updated = {};

        if (action?.payload?.results && Object.keys(action?.payload?.results).length) {
          updated = action?.payload?.results?.find(r => r.id === oldResult.id);
          if (updated) {
            return updated;
          }

        }
        return oldResult;
      });


      let newResults = [];
      if(action?.payload?.results && Array.isArray(action?.payload?.results) && action?.payload?.results?.length){
        newResults = action?.payload?.results.filter(newRes => {
          return !updatedResults.find(r => r.id === newRes.id);
        });
      }

      const nextState = {
        ...state,
        feeds_data: {
          ...(state.feeds_data || {}),
          ...action.payload,
          results: [...updatedResults, ...newResults]
        }
      };

      return nextState;
    }

    case actions.RESET_FEEDS: 
      state.feeds_data = null;
      return { ...state }

    case "GET_DISPUTE_DATA":
      state.get_dispute_data = action.payload;
      return { ...state };

    case "GET_JURY_DATA":
      state.get_jury_data = action.payload;
      return { ...state };

    case "CHECK_JUROR_DATA":
      state.get_juror_data = action.payload;
      return { ...state };

    case "GET_HELPCOUNT":
      state.get_reveiew_helpCount = action.payload;
      return { ...state };

    case "GET_PINSCOUNT":
      state.get_feed_pinsCount = action.payload;
      return { ...state };

    case "REVIEW_DATA":
      state.get_review_data = action.payload;
      return { ...state };

    case "REVIEW_STATUS":
      state.get_review_status = action.payload;
      return { ...state };

    case "FINAL_JURY_DATA":
      state.final_jury_data = action.payload;
      return { ...state };

    case "ADD_DISPUTE_STATUS":
      state.add_dispute_status = action.payload;
      return { ...state };

    case "FEEDS_LOADING":
      state.isLoadingFeeds = action.payload;
      return { ...state };

    default:
      return state;
  }
};

export default feeds;
