/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from "react-redux";
import { Alert, Button, ButtonGroup, Col, Collapse, Modal, ModalBody, ModalFooter, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { fetch_latest_two_reviews, fetch_pin_details, set_pin_details } from "../../../../actions/yourPicks";
import { findIndex } from 'lodash'
import { callApi } from '../../../../utils/apiCaller'
import Loaderimg from "../../../../assets/images/w-brand-black.jpg";
import { detectlink, pushUniqueObjectsInArray } from '../../../../utils/utilities';
import { Link } from 'react-router-dom';


class YourPicks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            prevY: 0,
            dataItems: {},
            viewYourPicksType: 'all',
            viewPicksTypeToggle: 'listing',
            toggleItems: {},
            latestReviews: {},
            confirmDeleteModal: false,
            selectedDeleteItems: null,
            activeHover: false,
            homeDropdownOpen: {},
            expandedReviewEntryIds: [],
            startFromPageOne: false
        }
        this.onMouseEnterCategory = this.onMouseEnterCategory.bind(this);
        this.onMouseLeaveCategory = this.onMouseLeaveCategory.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let { latestReviews } = this.state;

        if (nextProps?.fetched_pin_points && (!this.state?.dataItems?.results?.length || this.state.startFromPageOne)) {
            const data = {
                ...nextProps?.fetched_pin_points,
                results: pushUniqueObjectsInArray([], nextProps?.fetched_pin_points?.results, "user_entry"),
              };
            this.setState({ dataItems: data, loading: false });
            this.setState({startFromPageOne: false})
        }

        if (nextProps?.pin_status && (nextProps?.pin_status?.updated === "bottom"|| nextProps?.pin_status?.updated === "top")) {
            this.setState({startFromPageOne: true})
        }

        if (nextProps?.latest_two_reviews?.results?.length) {
            let item = nextProps?.latest_two_reviews?.results;
            let id = null;
            let category = item[0]?.associated_to?.content_type?.category?.toLowerCase()
            if(!category) {
                category = item[0]?.listing_type
            }
            if(category === "accountants" || category === "doctors" || category === "dentists" || category === "lawyers" || category === "actors") {
                id = item[0]?.associated_to?.id
            } else {
                id = item[0]?.listing_id;
            }  
            if (id) {
                latestReviews[id] = item;
                this.setState({ latestReviews });
            }
            this.setState({loading: false})
        }
    }

    clickToPin = (e, item) => {
        const { viewYourPicksType } = this.state;
        let selectedTab = viewYourPicksType;
        e.stopPropagation();
        let type = "top";
        let message = "Pin to top successfully!"

        if (item.pin_to_top_flag !== 0) {
            message = "Un pin successfully!"
            type = "bottom";
        }

        let entry_type = item.type
        if (entry_type === "Listing Review") {
            entry_type = "Listing"
            selectedTab = "reviewTab"
        } else if (entry_type === "Professional Review") {
            entry_type = "Professional Listing"
            selectedTab = "reviewTab"
        }

        this.props.set_pin_details({ type, pinid: item.pin_id, entry_type, selectedTab, message: message });
        this.setState({toggleItems: {}})
    }

    componentDidMount() {
        this.props.fetch_pin_details({ page: 1, loading: true });
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0,
          };
      
          this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            options
          );
          this.observer.observe(this.loadingRef);
    }

    getPinDetails(next) {
        this.setState({ loading: true });
        callApi(`${next}`, "GET").then((res) => {
          if (res && Object.keys(res).length > 0) {
            const data = {
              ...res,
              results: pushUniqueObjectsInArray(this.state.dataItems.results, res.results, "user_entry"),
            };
            this.setState({ dataItems: data, loading: false, toggleItems: {} });
          }
        });
      }
    //   [...this.state.dataItems.results, ...res.results]
    
    handleObserver(entities, observer) {
        const y = entities[0].boundingClientRect.y;
        let fetch = false;
        if (this.state.prevY === 0 && Math.abs(this.state.prevY - y) > 200) {
          fetch = true;
        } else if (this.state.prevY >= y) {
          fetch = true;
        }
    
        if (fetch) {
          if (this.state.dataItems && this.state.dataItems.next) {
            this.getPinDetails(this.state.dataItems.next.split(".com")[1]);
          }        
        }
        this.setState({ prevY: y });
      }

    sortItems = (a, b) => {
        if (a.pin_to_top_flag < b.pin_to_top_flag) {
            return 1;
        } else if (a.pin_to_top_flag > b.pin_to_top_flag) {
            return -1;
        } else {
            return 0;
        }
    }

    toggleCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = !expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseEnterCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseLeaveCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    setFilterItems = (item) => {
        this.setState({ viewYourPicksType: item, loading: true }, () => {
            this.setState({startFromPageOne: true, toggleItems: {}});
            if (item !== "all") {
                this.props.fetch_pin_details({ pin_type: `${item}%20list` })
            } 
            else {
                this.props.fetch_pin_details({ page: 1 })
            };
        });
    }

    handleViewPickToggle = (item, yourspickType) => {
        this.setState({ viewPicksTypeToggle: item, dataItems: [], loading: true, viewYourPicksType: yourspickType, toggleItems: {} }, () => {
            if (item === "review") {
                //this.props.fetch_pin_details({ type: 'review', loading: true, });
                this.props.fetch_pin_details({ type: 'review' });
            } else {
                this.props.fetch_pin_details({ page: 1, loading: true });
            }
        })
    }

    renderReview = (data, itemData) => {
        const reviewData = JSON.parse(JSON.stringify(data));
        return (
            <div>
                <div className="mb-2">
                    <div className="d-flex">
                        <div className="px-2 col">
                            <span className="font-weight-bold">new review</span>
                            {` `}
                            <span className="text-tertiary">by&nbsp;<span className="font-weight-bold">
                                {`${reviewData?.created_by?.user?.first_name} ${reviewData?.created_by?.user?.last_name}`}
                            </span> </span>
                        </div>

                        <div className="px-2 col-auto">
                            {
                                !this.state.expandedReviewEntryIds.includes(reviewData.id) ?

                                    <span className="icon-minus" title="Collapse"
                                        onClick={() => {
                                            const { expandedReviewEntryIds } = this.state;
                                            const tempExpandedReviewEntryIds = [...expandedReviewEntryIds]

                                            tempExpandedReviewEntryIds.push(reviewData.id);
                                            this.setState({
                                                expandedReviewEntryIds: [...tempExpandedReviewEntryIds]
                                            })
                                        }}></span>
                                    :
                                    <span className="icon-plus" title="Expand"
                                        onClick={() => {
                                            const { expandedReviewEntryIds } = this.state;
                                            const tempExpandedReviewEntryIds = [...expandedReviewEntryIds]
                                            const objWithIdIndex = tempExpandedReviewEntryIds.findIndex((reviewEntryId) => reviewEntryId === reviewData.id);

                                            if (objWithIdIndex > -1) {
                                                tempExpandedReviewEntryIds.splice(objWithIdIndex, 1);
                                            }
                                            this.setState({
                                                expandedReviewEntryIds: [...tempExpandedReviewEntryIds]
                                            })
                                        }}
                                    ></span>
                            }
                        </div>
                    </div>
                </div>

                <div>
                    {/* Overall Star Rating */}
                    {Object.keys(reviewData.ratings_sets).map(item => {
                        if (item === 'avg_rating') {
                            return (
                                <div className="d-flex flex-wrap mx-n2 mb-2">
                                    <div className="px-2 mb-2">
                                        <img className="mr-2" src={require(`../../../../assets/images/icons/star/blue/${itemData?.rating_img[0]}`)} alt="" />
                                        <span className="text-tertiary fs-14">
                                            Overall Rating
                                        </span>
                                    </div>
                                    {item !== 'avg_rating' ? null : (
                                        <div className="px-2">
                                            <span className="text-tertiary fs-14">
                                                {reviewData.review_date_time}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    }
                    )}

                    {/* More info starts */}
                    {!this.state.expandedReviewEntryIds.includes(reviewData.id) ?
                        <div>
                            <div className="mb-3">
                                <Row xs={1} sm={2}>
                                    {Object.keys(reviewData.ratings_sets).map((item, index) => {
                                        if (item !== 'avg_rating') {
                                            return (
                                                <Col key={index}>
                                                    <div className="d-flex flex-wrap mx-n2 mb-2">
                                                        <div className="px-2 mb-2">

                                                            {
                                                                reviewData.ratings_sets &&
                                                                    reviewData.ratings_sets[item] &&
                                                                    reviewData.ratings_sets[item][0] ?
                                                                    <img
                                                                        className="mr-2"
                                                                        src={require(`../../../../assets/images/icons/star/blue/${reviewData.ratings_sets[item][0]}`)}
                                                                        alt=""
                                                                    />
                                                                    :
                                                                    <img
                                                                        className="mr-2"
                                                                        src={require(`../../../../assets/images/icons/star/blue/0rating.png`)}
                                                                        alt=""
                                                                    />
                                                            }
                                                            <span className="text-tertiary fs-14">
                                                                {item}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        } else {
                                            return null;
                                        }
                                    }
                                    )}
                                </Row>
                            </div>

                            <div>
                                <div className="text-tertiary">
                                    <i className="icon-comma-left"></i>
                                    <span className="comment-body-renderer">
                                        {ReactHtmlParser(itemData?.type === "Professional" ? detectlink(reviewData.professionalreview) : detectlink(reviewData.review))}
                                    </span>
                                    <i className="icon-comma-right"></i>
                                </div>
                            </div>
                        </div>
                        : ""}
                    {/* More view ends */}
                </div>
                <hr className="bg-tertiary mt-2" />
            </div>

        )
    }

    renderViewCategory = ({ reviewsInfo, item, index }) => {
        const listingType = item.Listing_type;

        return (
            <Col md={12} lg={9} className="mb-3">
                <Collapse isOpen={true} >
                    <div className="bg-white cursor-pointer position-relative"
                    // onClick={(e) => { this.setToggleCategories({ e, index, item }) }}
                    >
                        {
                            item.pin_to_top_flag ?
                                <img src={require(`../../../../assets/images/icons/PinCardHeadExtended.png`)} className="img img-fluid w-100 position-absolute top-0 start-0" style={{ height: "20px" }} alt="" /> : ''
                        }
                        {/* More info starts here */}
                        <div className={`fs-14 p-3 text-dark`}>
                            <div className={`d-sm-flex mx-n2 mb-3  ${item.pin_to_top_flag ? 'mt-3' : ''}`} role="button"
                                onClick={(e) => { this.setToggleCategories({ e, index, item }) }}
                            >
                                <div className={`mb-2 px-2`} style={{ maxWidth: '200px' }}>



                                    {item?.listing_profile_image[0]?.url ?
                                        (
                                            <img className="img-fluid" src={item.listing_profile_image[0].url} alt=""
                                                onError={(error) =>
                                                    (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)
                                                }
                                            />)
                                        : (<img className="img-fluid" src={'https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png'} alt="" />)}
                                </div>
                                <div className="px-2 w-100">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="font-weight-bold ff-alt pr-2">
                                            {item?.name}
                                        </div>
                                        <div>
                                            <span className="text-dark font-weight-bold">
                                                {(reviewsInfo && Array.isArray(reviewsInfo)) ? `Category: ${reviewsInfo[0]?.associated_to?.taxonomy[0]?.category ? reviewsInfo[0]?.associated_to?.taxonomy[0]?.category : reviewsInfo[0]?.associated_to?.content_type?.category}` : ""}
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="mt-n1 mb-1">
                                        <span className="fs-13 text-tertiary">
                                            {listingType?.charAt(0).toUpperCase() + listingType?.slice(1)}
                                        </span>
                                    </div>
                                    <div className="mb-2">
                                        <img className="mr-2" src={require(`../../../../assets/images/icons/star/blue/${item?.rating_img[0]}`)} alt="" />
                                        <span className="text-tertiary fs-14">
                                            {'Overall Rating'}
                                        </span>
                                    </div>
                                    <div className="text-dark font-weight-bold mt-3">
                                        {
                                            !(reviewsInfo && Array.isArray(reviewsInfo)) 
                                            ?
                                            this.state.loading 
                                                ? "Fetching Data..." 
                                                : 
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        No Reviews to Display
                                                    </div>
                                                    <div>
                                                            <Link
                                                                to={{
                                                                    pathname: `/${listingType?.toLowerCase()}/${item.user_entry}`,
                                                                    state: {
                                                                        id: item.user_entry
                                                                    }
                                                                }}
                                                            >
                                                                <u>Add a review now!</u>
                                                            </Link>
                                                    </div>
                                                </div> 
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                            (reviewsInfo && Array.isArray(reviewsInfo)) 
                            ? reviewsInfo.map(review => this.renderReview(review, item)) 
                            : 
                                ''
                            }
                        </div>
                    </div>
                </Collapse>
            </Col>
        )
    }

    renderViewCategoryReview = ({ item, index }) => {
        return (
            <Col md={12} lg={9} className="mb-3">
                <Collapse isOpen={true} >
                    <div className="bg-white cursor-pointer position-relative"
                    // onClick={(e) => { this.setToggleCategories({ e, index, item }) }}
                    >
                        {
                            item.pin_to_top_flag ?
                                <img src={require(`../../../../assets/images/icons/PinCardHeadExtended.png`)} className="img img-fluid w-100 position-absolute top-0 start-0" style={{ height: "20px" }} alt="" /> : ''
                        }
                        {/* More info starts here */}
                        <div className="fs-14 p-3 text-dark">
                            <div className={`d-sm-flex mx-n2 mb-3  ${item.pin_to_top_flag ? 'mt-3' : ''}`} role="button"
                                onClick={(e) => { this.setToggleCategories({ e, index, item }) }}
                            >
                                <div className="mb-2 px-2" style={{ maxWidth: '200px' }}>
                                    {
                                        item?.type === "Professional Review" ?
                                            (item?.review_details?.professional_profileimage?.[0]?.url ?
                                                (
                                                    <img className="img-fluid" src={item.review_details.professional_profileimage[0].url} alt=""
                                                        onError={(error) =>
                                                            (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)
                                                        }
                                                    />)
                                                : (<img className="img-fluid" src={'https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png'} alt="" />))
                                            :
                                            (item?.review_details?.listing_profileimage?.[0]?.url ?
                                                (
                                                    <img className="img-fluid" src={item.review_details.listing_profileimage[0].url} alt=""
                                                        onError={(error) =>
                                                            (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)
                                                        }
                                                    />)
                                                : (<img className="img-fluid" src={'https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png'} alt="" />))
                                    }
                                </div>
                                <div className="px-2 w-100">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="font-weight-bold ff-alt pr-2">
                                            {item?.type === "Professional Review" ? (item?.review_details?.associated_to?.firstname + " " + item?.review_details?.associated_to?.lastname) : item?.review_details?.associated_to?.name}
                                        </div>
                                        <div>
                                            <span className="text-dark font-weight-bold">
                                                {(item?.review_details?.associated_to?.taxonomy?.[0]?.category) ? `Category: ${item.review_details.associated_to.taxonomy[0].category}` : (item?.review_details?.associated_to?.content_type?.category ? item.review_details.associated_to.content_type.category : '')}
                                            </span>
                                        </div>
                                    </div>
                                    
                                    <div className="mt-n1 mb-1">
                                        <span className="fs-13 text-tertiary">
                                            {item?.review_details?.associated_to?.content_type?.category}
                                        </span>
                                    </div>
                                    {
                                        item?.review_details?.total_rating?.avg_rating?.[0] ?
                                            <div className="mb-2">
                                                <img className="mr-2" src={require(`../../../../assets/images/icons/star/blue/${item?.review_details?.total_rating?.avg_rating?.[0]}`)} alt="" />
                                                <span className="text-tertiary fs-14">
                                                    {'Overall Rating'}
                                                </span>
                                            </div>
                                            : ''
                                    }
                                    
                                </div>
                            </div>
                            <div>
                <div className="mb-2">
                    <div className="d-flex">
                        <div className="pr-2 pl-0 col">
                            <span className="font-weight-bold">Review</span>
                            {` `}
                            <span className="text-tertiary">by&nbsp;<span className="font-weight-bold">
                                {`${item?.review_details?.created_by?.user?.first_name} ${item?.review_details?.created_by?.user?.last_name}`}
                            </span> </span>
                        </div>

                        <div className="px-2 col-auto">
                            {
                                !this.state.expandedReviewEntryIds.includes(item.user_entry) ?

                                    <span className="icon-minus" title="Collapse"
                                        onClick={() => {
                                            const { expandedReviewEntryIds } = this.state;
                                            const tempExpandedReviewEntryIds = [...expandedReviewEntryIds]

                                            tempExpandedReviewEntryIds.push(item.user_entry);
                                            this.setState({
                                                expandedReviewEntryIds: [...tempExpandedReviewEntryIds]
                                            })
                                        }}></span>
                                    :
                                    <span className="icon-plus" title="Expand"
                                        onClick={() => {
                                            const { expandedReviewEntryIds } = this.state;
                                            const tempExpandedReviewEntryIds = [...expandedReviewEntryIds]
                                            const objWithIdIndex = tempExpandedReviewEntryIds.findIndex((reviewEntryId) => reviewEntryId === item.user_entry);

                                            if (objWithIdIndex > -1) {
                                                tempExpandedReviewEntryIds.splice(objWithIdIndex, 1);
                                            }
                                            this.setState({
                                                expandedReviewEntryIds: [...tempExpandedReviewEntryIds]
                                            })
                                        }}
                                    ></span>
                            }
                        </div>
                    </div>
                </div>

                <div>
                    {/* Overall Star Rating */}
                    {item?.review_details?.ratings_sets && Object.keys(item?.review_details?.ratings_sets).map(setItem => {
                        if (setItem === 'avg_rating') {
                            return (
                                <div className="d-flex flex-wrap mx-n2 mb-2">
                                    <div className="px-2 mb-2">
                                        <img className="mr-2" src={require(`../../../../assets/images/icons/star/blue/${item?.review_details?.ratings_sets?.[setItem]?.[0]}`)} alt="" />
                                        <span className="text-tertiary fs-14">
                                            Overall Rating
                                        </span>
                                    </div>
                                    {setItem !== 'avg_rating' ? null : (
                                        <div className="px-2">
                                            <span className="text-tertiary fs-14">
                                                {item?.review_details?.review_date_time}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    }
                    )}

                    {/* More info starts */}
                    {!this.state.expandedReviewEntryIds.includes(item?.user_entry) ?
                        <div>
                            <div className="mb-3">
                                <div className='row'>
                                    {item?.review_details?.ratings_sets && Object.keys(item?.review_details?.ratings_sets) && !Array.isArray(item?.review_details?.ratings_sets) &&  Object.keys(item?.review_details?.ratings_sets).map((setItem, index) => {
                                            return (setItem !== "avg_rating" ?
                                                 <div className='col-sm-6' key={index}>  
                                                    <div className="d-flex flex-wrap mx-n2 mb-2">
                                                        <div className="px-2 mb-2">
                                                            {
                                                                item?.review_details?.ratings_sets?.[setItem] ?  
                                                                    <img
                                                                        className="mr-2"
                                                                        src={require(`../../../../assets/images/icons/star/blue/${item?.review_details?.ratings_sets?.[setItem]?.[0]}`)}
                                                                        alt=""
                                                                    />
                                                                    :
                                                                    <img
                                                                        className="mr-2"
                                                                        src={require(`../../../../assets/images/icons/star/blue/0rating.png`)}
                                                                        alt=""
                                                                    />
                                                                    
                                                            }
                                                            <span className="text-tertiary fs-14">
                                                                {setItem}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                '')
                                    })}
                                </div>
                            </div>

                            <div>
                                <div className="text-tertiary">
                                    <i className="icon-comma-left"></i>
                                    <span className="comment-body-renderer">
                                        {ReactHtmlParser(item?.type === "Professional Review" ? item?.review_details?.professionalreview : item?.review_details?.review)}
                                    </span>
                                    <i className="icon-comma-right"></i>
                                </div>
                            </div>
                        </div>
                        : ""
                    }
                    {/* More view ends */}
                </div>
                <hr className="bg-tertiary mt-2" />
            </div>
                        </div>
                    </div>
                </Collapse>
            </Col>
        )
    }

    setToggleCategories = ({ e, index, item }) => {
        e.preventDefault();

        let { toggleItems } = this.state;
        if (toggleItems[index] === undefined) {
            toggleItems[index] = true;
        } else {
            toggleItems[index] = !toggleItems[index];
        }

        if (toggleItems[index]) {
            if ((item.type === "Listing" || item.type === "Professional") && item?.count_of_reviews > 0) {
                this.props.fetch_latest_two_reviews({ userentryid: item.user_entry, entry_type: item.type });
                this.setState({ loading: true })
            } 
        }

        this.setState({ toggleItems });
    }


    renderDefaultView = ({ item, index }) => {
        return (
            <Col className="mb-3 pr-2">
                <div className="bg-white-shaded-md h-100 position-relative">
                {
                    item.pin_to_top_flag ? 
                    <img src={require(`../../../../assets/images/icons/PinCardHead.png`)} className="img img-fluid w-100 position-absolute top-0 start-0" alt="" /> : ''
                }
                <div className='d-flex flex-column p-3 h-100'>
                    <div className={`mb-4 cursor-pointer`} onClick={(e) => { this.setToggleCategories({ e, index, item }) }}>
                        {(item?.count_of_reviews || item?.count_of_reviews === 0)
                            // && item?.pin_type === 'watch list'
                            ?
                            <div className={`text-right mt-n4 mr-n4 position-relative`} style={{ zIndex: "100" }}>
                                <span className="badge badge-counter">{item.count_of_reviews}</span>
                            </div>
                            : 
                            ""
                        }
                        <div className={`poster-img mb-2 ${item.pin_to_top_flag ? 'mt-3' : ''}`}>
                            {(item?.listing_profile_image && item?.listing_profile_image[0]?.url) ? (<img className="test" src={item?.listing_profile_image[0]?.url} alt=""
                                onError={(error) => (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)} />)
                                : (<img src={'https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png'} alt="" />)}
                        </div>
                        <div>
                            <div>
                                <div className="text-dark font-weight-bold fs-14">
                                    {/* {item.Listing_type && item.user_entry ?
                                        <a className="text-reset clamp-line clamp-line-3 cursor-pointer"
                                            onClick={() => {
                                                this.props.history.push({
                                                    pathname: `/products/${item.user_entry}`,
                                                    state: {
                                                        id: item.user_entry
                                                    }
                                                })
                                            }}>
                                            {item.name}
                                        </a>
                                        :
                                        <span className="clamp-line clamp-line-3">{item.name}</span>
                                    } */}
                                    <span className="clamp-line clamp-line-3">{item.name}</span>
                                </div>
                            </div>
                            <div>
                                <img src={require(`../../../../assets/images/icons/star/blue/${item.rating_img && item.rating_img[0] != null ? item?.rating_img[0] : '0rating.png'}`)} alt="" />
                            </div>
                            <div>
                                <span className="text-tertiary ff-alt fs-14">
                                        <span>{item?.count_of_reviews}</span> {item?.count_of_reviews > 1 ? 'reviews' : 'review'}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="mt-auto">
                        <div className="d-flex flex-nowrap">
                            <div className="mr-2">
                                <Button
                                    onClick={(e) => { this.clickToPin(e, item) }}
                                    color="link"
                                    className="text-warning font-weight-bold text-decoration-none p-0">
                                    {item.pin_to_top_flag !== 0 ? "Un-pin" : "pin to top"}
                                </Button>
                            </div>
                            <div className="ml-auto">
                                <Button
                                    onClick={(e) => { this.confirmDeleteModalToggle(e, item) }}
                                    color="link"
                                    className="text-dark text-decoration-none p-0"
                                    title="Remove">&times;</Button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </Col>
        )
    }

    renderDefaultViewReview = ({ item, index }) => {
        return (
            <Col className="mb-3">
                <div className="bg-white-shaded-md h-100 position-relative">
                {
                    item.pin_to_top_flag ? 
                    <img src={require(`../../../../assets/images/icons/PinCardHead.png`)} className="img img-fluid w-100 position-absolute top-0 start-0" alt="" /> : ''
                } 
                <div className='d-flex flex-column p-3 h-100'>
                    <div className={`mb-4 cursor-pointer`} onClick={(e) => { this.setToggleCategories({ e, index, item }) }}>
                        <div className={`poster-img mb-2 ${item.pin_to_top_flag ? 'mt-3' : ''}`}>
                                {
                                    item?.type === "Professional Review" ?
                                        ((item?.review_details?.professional_profileimage && item?.review_details?.professional_profileimage[0]?.url) ? (<img className="test" src={item?.review_details?.professional_profileimage[0]?.url} alt=""
                                            onError={(error) => (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)} />)
                                            : (<img src={'https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png'} alt="" />))
                                        :
                                        ((item?.review_details?.listing_profileimage && item?.review_details?.listing_profileimage[0]?.url) ? (<img className="test" src={item?.review_details?.listing_profileimage[0]?.url} alt=""
                                            onError={(error) => (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)} />)
                                            : (<img src={'https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png'} alt="" />))
                                }
                        </div>
                        <div>
                            <div>
                                <div className="text-dark font-weight-bold fs-14" style={{ minHeight: "65px" }}>
                                    {/* {item.Listing_type && item.user_entry ?
                                        <a className="text-reset clamp-line clamp-line-3 cursor-pointer"
                                            onClick={() => {
                                                this.props.history.push({
                                                    pathname: `/products/${item.user_entry}`,
                                                    state: {
                                                        id: item.user_entry
                                                    }
                                                })
                                            }}>
                                            {item.name}
                                        </a>
                                        :
                                        <span className="clamp-line clamp-line-3">{item.name}</span>
                                    } */}
                                    <span className="clamp-line clamp-line-3">{item?.type === "Professional Review" ? (item?.review_details?.associated_to?.firstname + " " + item?.review_details?.associated_to?.lastname) : item?.review_details?.associated_to?.name}</span>
                                </div>
                            </div>
                            <div>
                                {item?.review_details?.created_by?.user ?
                                    <div className='text-dark mt-2' style={{fontSize: "14px"}}>
                                        <b>Review by:</b>{" "} <br />
                                        <Link to={{
                                            pathname: `/people/${item?.review_details?.created_by?.user?.username}`,
                                            state: {
                                                id: item?.review_details?.created_by?.user?.id
                                            }
                                        }} className="text-dark">
                                        {`${item?.review_details?.created_by?.user?.first_name} ${item?.review_details?.created_by?.user?.last_name}`}
                                        </Link>    
                                    </div> : ''
                                }
                                <img title={`${item?.review_details?.ratings_sets?.avg_rating?.[1]}`} src={require(`../../../../assets/images/icons/star/blue/${item?.review_details?.ratings_sets?.avg_rating?.[0] ? item.review_details.ratings_sets.avg_rating[0] : '0rating.png'}`)} alt={`${item?.review_details?.ratings_sets?.avg_rating?.[1] ? item.review_details.ratings_sets.avg_rating[0] : "NA" }`} />
                            </div>
                            <div>
                                <span className='clamp-line clamp-line-3 text-dark mt-2' style={{ fontSize: "12px" }}>{ReactHtmlParser(item?.type === "Professional Review" ? item?.review_details?.professionalreview : item?.review_details?.review)}</span>
                            </div>
                        </div>
                    </div>

                     <div className="mt-auto">
                        <div className="d-flex flex-nowrap">
                            <div className="mr-2">
                                <Button
                                    onClick={(e) => { this.clickToPin(e, item) }}
                                    color="link"
                                    className="text-warning font-weight-bold text-decoration-none p-0">
                                    {item.pin_to_top_flag !== 0 ? "Un-pin" : "pin to top"}
                                </Button>
                            </div>
                            <div className="ml-auto">
                                <Button
                                    onClick={(e) => { this.confirmDeleteModalToggle(e, item) }}
                                    color="link"
                                    className="text-dark text-decoration-none p-0"
                                    title="Remove">&times;</Button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </Col>
        )
    }

    confirmDeleteModalToggle = (e, item) => {
        e.stopPropagation();
        this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal, selectedDeleteItems: item ? item : null });
    }

    handleOnDeleteConfirmation = () => {
        let { selectedDeleteItems, dataItems, viewYourPicksType } = this.state;
        let url, method;

        if (selectedDeleteItems.type === "Listing Review") {
            url = `/api/pins/${selectedDeleteItems.user_entry}/?type=${"watch list"}&review=${true}`
            method = "DELETE"
        } else {
            url = `/api/pinselection/?type=delete&pinid=${selectedDeleteItems.pin_id}&entry_type=${selectedDeleteItems.type}`
            method = "GET"
        }

        callApi(url, method).then((response) => {
            if (response && response.code !== 400 && response.code !== 500 && response.code !== 404) {
                let deletingIndex = findIndex(dataItems.results, { pin_id: selectedDeleteItems.pin_id })
                if (deletingIndex > -1)
                    dataItems.results.splice(deletingIndex, 1)

                this.setState({ dataItems: dataItems, confirmDeleteModal: !this.state.confirmDeleteModal, selectedDeleteItems: null });
            }
        });
    }

    redirectToSlugUrl = (item) => {

        let listingType = item.Listing_type.toLowerCase();
        let id = (item?.user_entry).toString();
        if (item?.slug) {
            return ({
                pathname: `/${listingType}/${item?.slug}`,
                state: { id }
            })
        } else {
            return ({
                pathname: `/${item?.listingType}`,
                state: { id }
            })
        }
    }

    render() {
        let { dataItems, loading, toggleItems, latestReviews } = this.state;
        const loadingCSS = {
            height: "100px",
            margin: "30px",
          };
        return (
            <div>
                <div className="d-flex mx-n1 mb-3">

                    <Modal
                        size="sm"
                        isOpen={this.state.confirmDeleteModal}
                        toggle={this.confirmDeleteModalToggle}
                        className="text-center" modalClassName="backdrop-opaque top-shifted transition-none">
                        <div className="bg-white modal-header">
                            <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
                            <button
                                className="btn btn-sm"
                                aria-label="Close"
                                onClick={this.confirmDeleteModalToggle}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <ModalBody className="text-dark">
                            {'Are you sure you want to delete?'}
                        </ModalBody>
                        <ModalFooter className="bg-white">
                            <div className="text-center w-100">
                                <Button
                                    color="primary"
                                    size="sm"
                                    className="btn-confirm"
                                    onClick={this.confirmDeleteModalToggle}
                                >
                                    {'Cancel'}
                                </Button>
                                <Button
                                    color="primary"
                                    size="sm"
                                    className="btn-confirm"
                                    onClick={this.handleOnDeleteConfirmation}
                                >
                                    {'Yes'}
                                </Button>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <div className="px-1">
                        <ButtonGroup className="flex-wrap type-filter museo-sans-900" size="sm">
                            {this.state.viewPicksTypeToggle == "listing" ?
                                <>
                                    <div className="item d-flex align-items-center">
                                        <Button
                                            color="transparent"
                                            className="flex-nowrap"
                                            onClick={() => this.setFilterItems("all")}
                                            active={this.state.viewYourPicksType === "all"}>
                                            {'All'}
                                        </Button>
                                    </div>
                                    <div className="item d-flex align-items-center">
                                        <Button
                                            color="transparent"
                                            className="flex-nowrap"
                                            onClick={() => this.setFilterItems("favourite")}
                                            active={this.state.viewYourPicksType === "favourite"}>
                                            {'Favorites'}
                                        </Button>
                                    </div>
                                    <div className="item d-flex align-items-center">
                                        <Button
                                            color="transparent"
                                            className="flex-nowrap"
                                            onClick={() => this.setFilterItems("watch")}
                                            active={this.state.viewYourPicksType === "watch"}>
                                            {'Watch'}
                                        </Button>
                                    </div>
                                    <div className="item d-flex align-items-center">
                                        <Button
                                            color="transparent"
                                            className="flex-nowrap"
                                            onClick={() => this.setFilterItems("want")}
                                            active={this.state.viewYourPicksType === "want"} >
                                            {'Want'}
                                        </Button>
                                    </div>
                                    <div className="item d-flex align-items-center">
                                        <Button
                                            color="transparent"
                                            className="flex-nowrap"
                                            onClick={() => this.setFilterItems("recommend")}
                                            active={this.state.viewYourPicksType === "recommend"}>
                                            {'Recommend'}
                                        </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="item d-flex align-items-center">
                                        <Button
                                            color="transparent"
                                            className="flex-nowrap"
                                            //onClick={() => this.setFilterItems("watch")}
                                            active={this.state.viewYourPicksType === "watch"}>
                                            {'Watch'}
                                        </Button>
                                    </div>
                                </>

                            }
                        </ButtonGroup>
                    </div>
                    <div className="px-1 ml-auto">
                        <Nav tabs className="non-decorated-alt ">
                            <NavItem>
                                <NavLink href="#"
                                    className='museo-sans-900'
                                    active={this.state.viewPicksTypeToggle === "review"}
                                    onClick={() => { this.handleViewPickToggle("review", "watch") }}>
                                    {'Review'}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                    className='museo-sans-900'
                                    active={this.state.viewPicksTypeToggle === "listing"}
                                    onClick={() => { this.handleViewPickToggle("listing", "all") }}>
                                    {'Listing'}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
                <TabContent activeTab={this.state.viewPicksTypeToggle}>
                    <TabPane tabId="review">                        
                            <Row xs={1} sm={2} md={3} lg={5} form>
                                {dataItems?.results && Array.isArray(dataItems?.results) && dataItems?.results?.length ?
                                    dataItems?.results.filter(item => item.review_details && Object.keys(item.review_details).length > 0).sort(this.sortItems).map((item, index) => (
                                        // <div className="bg-white fs-14 p-3 mb-3" key={index}>
                                        //     <div className="d-flex mx-n2">
                                        //         <div className="px-2">
                                        //             <div className="text-center mb-3">
                                        //                 <img width="100" className="object-fit-cover object-position-center"
                                        //                     src={`https://stagingdatawikireviews.s3.amazonaws.com/media/${item?.review_details?.created_by?.current_profile_pic?.file}`}
                                        //                     alt={item?.review_details?.created_by?.current_profile_pic?.file ? item?.review_details?.created_by?.current_profile_pic?.filename : ""}
                                        //                     onError={(error) =>
                                        //                         (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                        //                     } style={{ maxHeight: '300px' }} />
                                        //             </div>
                                        //         </div>
                                        //         <div className="px-2 flex-grow-1">
                                        //             <div>
                                        //                 <div className="ff-base fs-14">
                                        //                     <a className="cursor-pointer text-darker" onClick={() => {
                                        //                         this.props.history.push({
                                        //                             pathname: `/people/${item?.review_details?.created_by?.user?.username}`                                                                    
                                        //                         })
                                        //                     }}><b>{`${item?.review_details?.created_by?.user?.first_name} ${item?.review_details?.created_by?.user?.last_name}`}</b></a>
                                        //                     {' review for '}
                                        //                     <b><a className="cursor-pointer text-darker" onClick={() => {
                                        //                         this.props.history.push({
                                        //                             pathname: `/products/${item.user_entry}`,
                                        //                             state: {
                                        //                                 id: item.user_entry
                                        //                             }
                                        //                         })
                                        //                     }}>{item?.review_details?.associated_to?.name}</a></b>
                                        //                     {' and gave it '}
                                        //                 </div>
                                        //                 <div className="mb-3 mt-1 cursor-pointer">
                                        //                     <img
                                        //                         onMouseOver={() => this.onMouseEnterCategory(true, item?.user_entry)}
                                        //                         onMouseLeave={() => this.onMouseLeaveCategory(false, item?.user_entry)}
                                        //                         className="img-fluid"
                                        //                         src={item?.review_details?.ratings_sets && item?.review_details?.ratings_sets['avg_rating'] &&
                                        //                             item?.review_details?.ratings_sets['avg_rating'][0] ?
                                        //                             require(`../../../../assets/images/icons/star/blue/${item?.review_details?.ratings_sets['avg_rating'][0]}`)
                                        //                             : '0rating.png'}
                                        //                         alt={item?.review_details?.ratings_sets && item?.review_details?.ratings_sets['avg_rating'] &&
                                        //                             item?.review_details?.ratings_sets['avg_rating'][1] ?
                                        //                             item?.review_details?.ratings_sets['avg_rating'][1] : '0.0'}
                                        //                         title={item?.review_details?.ratings_sets && item?.review_details?.ratings_sets['avg_rating'] &&
                                        //                             item?.review_details?.ratings_sets['avg_rating'][1] ?
                                        //                             item?.review_details?.ratings_sets['avg_rating'][1] : '0.0'} />
                                        //                     {''}
                                        //                 </div>

                                        //                 <div className={this.state.homeDropdownOpen[item?.user_entry] ? "displayHover" : 'd-none'}>
                                        //                     {item?.review_details?.ratings_sets && Object.keys(item?.review_details?.ratings_sets).length ?
                                        //                         Object.keys(item?.review_details?.ratings_sets).map(rt_item => rt_item !== "avg_rating" && (
                                        //                             <div className="cursor-pointer">
                                        //                                 <img className="img-fluid"
                                        //                                     src={item?.review_details?.ratings_sets && item?.review_details?.ratings_sets[rt_item] &&
                                        //                                         item?.review_details?.ratings_sets[rt_item][0] ?
                                        //                                         require(`../../../../assets/images/icons/star/blue/${item?.review_details?.ratings_sets[rt_item][0]}`)
                                        //                                         : require(`../../../../assets/images/icons/star/blue/0rating.png`)}
                                        //                                     alt={item?.review_details?.ratings_sets && item?.review_details?.ratings_sets[rt_item] &&
                                        //                                         item?.review_details?.ratings_sets[rt_item][1] ?
                                        //                                         item?.review_details?.ratings_sets[rt_item][1] : '0.0'}
                                        //                                     title={item?.review_details?.ratings_sets && item?.review_details?.ratings_sets[rt_item] &&
                                        //                                         item?.review_details?.ratings_sets[rt_item][1] ?
                                        //                                         item?.review_details?.ratings_sets[rt_item][1] : '0.0'}
                                        //                                     onError={(error) => (error.target.src = require(`../../../../assets/images/icons/star/blue/0rating.png`))}
                                        //                                 />
                                        //                                 {rt_item}
                                        //                             </div>
                                        //                         ))
                                        //                         : null}
                                        //                 </div>

                                        //                 <div className="my-1 mt-3">
                                        //                     <i className="icon-comma-left"></i>
                                        //                     <span className="comment-body-renderer fs-14 ff-alt text-tertiary" dangerouslySetInnerHTML={{ __html: item?.review_details?.review }} />
                                        //                     <i className="icon-comma-right"></i>
                                        //                 </div>
                                        //             </div>
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <React.Fragment key={index}>
                                        {
                                            (toggleItems && toggleItems[index] 
                                                ? this.renderViewCategoryReview({ item, index })
                                                : (this.renderDefaultViewReview({ item, index })))  
                                        }
                                    </React.Fragment>
                                        
                                    ))
                                    : !this.state.loading && 'No Record Found'}
                            </Row>
                       
                    </TabPane>
                    <TabPane tabId="listing">
                        {/* <Row xs={1} sm={2} md={3} lg={5}> */}
                        <Row xs={1} sm={2} md={3} lg={5} form>
                            {dataItems && dataItems?.results && dataItems?.results?.length ?
                                dataItems?.results.sort(this.sortItems).map((item, index) => (
                                    <React.Fragment key={index}>
                                        {
                                        (item?.name && item?.count_of_reviews >= 0) 
                                            ? 
                                            (toggleItems && toggleItems[index] 
                                                ? this.renderViewCategory({ reviewsInfo: latestReviews[item.user_entry], item, index })
                                                : (this.renderDefaultView({ item, index })))  
                                            : ''
                                        }
                                    </React.Fragment>
                                ))
                                : (<Col>{loading ?

                                    <div className="loader_div">
                                        <div className="inner-loader">
                                            <img src={Loaderimg} alt="" />
                                        </div>
                                    </div>
                                    : `No data to display`}</Col>)}
                        </Row>
                    </TabPane>
                </TabContent>
                <div
                    ref={(loadingRef) => (this.loadingRef = loadingRef)}
                    style={loadingCSS}
                >
                    <Alert color="primary" hidden={!this.state.loading}>
                        Loading...
                    </Alert>
                </div>
            </div >
        )
    }
}

const mapState = (state) => ({
    fetched_pin_points: state.picks.fetched_pin_points,
    latest_two_reviews: state.picks.latest_two_reviews,
    pin_status: state.picks.pin_status
});

const mapProps = (dispatch) => ({
    fetch_pin_details: (data) => dispatch(fetch_pin_details(data)),
    set_pin_details: ({ type, pinid, entry_type, selectedTab, message }) => dispatch(set_pin_details({ type, pinid, entry_type, selectedTab, message })),
    fetch_latest_two_reviews: (data) => dispatch(fetch_latest_two_reviews(data)),
})

export default connect(mapState, mapProps)(YourPicks);