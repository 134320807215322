/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import AppHeader from '../oragnisms/dashboard/appHeader';
import { Container, Row, Col, Badge, Button,  FormGroup, Input, Nav, NavItem, NavLink, TabPane, TabContent, CustomInput } from 'reactstrap';
import CollapseBasic from '../atoms/collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  get_dispute_discussion,
} from "../../actions/feeds";
import {
  get_administrative_requested_disputes,
  handle_admin_request
} from "../../actions/dispute";
import Moment from "react-moment";

import AdminRequestedDispute from "../atoms/disputeReview/adminRequestedDispute";


class NoticeBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainViewType: "main",
      get_dispute_data: [],
      requested_disputes: [],
      showAdminRequestedDispute: false,
      review:'',
    }
  }

  componentDidMount = () => {
    this.props.get_administrative_requested_disputes()
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.get_dispute_data &&
      Object.keys(nextProps.get_dispute_data).length > 0
    ) {
      this.setState({
        get_dispute_data: nextProps.get_dispute_data.results,
      });
    }

    if (
      nextProps.admin_requested_disputes &&
      Object.keys(nextProps.admin_requested_disputes).length > 0
    ) {
      this.setState({
        requested_disputes: nextProps?.admin_requested_disputes?.results,
      });
    }

    if ( nextProps?.get_dispute_modal_status?.modal === false && this.state.showAdminRequestedDispute ) {
      this.setState({ showAdminRequestedDispute: false})
      if( nextProps?.get_dispute_modal_status?.refresh ) {
        this.props.get_administrative_requested_disputes()
      }

    }
  }

  handleDisputeRequest = (data,option) => {
    
  }

  handleDisputedReview = (dispute) => {
    this.setState({ review:dispute?.review_id, showAdminRequestedDispute: true})
  }

  render() {
    const { requested_disputes, showAdminRequestedDispute, review} = this.state
    return (
      <>
        {showAdminRequestedDispute ? 
        <AdminRequestedDispute review={review}/>
        :
        ''
        }
        <div>
          <AppHeader isEnableCover={false} />
          <section className="bg-dark text-white text-center p-4 position-relative mb-5" style={{ top: '45px' }}>
            <Container>
              <div>
                <div className="text-muted mb-3">
                  Admin Notice Board: <span className="font-weight-bold">Resolution Center</span>
                </div>
                <h1 className="fs-50">Review Dispute</h1>
              </div>
            </Container>
          </section>

          <section className="py-3">
            <Container>
              <Row>
                <Col md={4}>
                  <CollapseBasic title="Resolution Center" containerClass="type-dark bg-transparent" bodyClass="text-tertiary pb-3" noPadding={true} isOpen={true}>
                    <ul className="list-unstyled text-tertiary font-weight-bold">
                      <li className="py-2 border-bottom">
                        <Button color="link" className="text-dark p-0 font-weight-bold ff-base">Heated Discussions <Badge color="primary" className="rounded-circle">1</Badge> </Button>
                      </li>
                      <li className="py-2 border-bottom">
                        <Button color="link" className="text-tertiary p-0 font-weight-bold ff-base">User Admin Shopping <Badge color="danger" className="rounded-circle">6</Badge> </Button>
                      </li>
                      <li className="py-2 border-bottom">
                        <Button color="link" className="text-tertiary p-0 font-weight-bold ff-base">Business Ownership Disputes <Badge color="warning" className="rounded-circle">13</Badge> </Button>
                      </li>
                      <li className="py-2 border-bottom">
                        <Button color="link" className="text-tertiary p-0 font-weight-bold ff-base">Individual Professional Disputes </Button>
                      </li>
                      <li className="py-2 border-bottom">
                        <Button color="link" className="text-tertiary p-0 font-weight-bold ff-base">Reqeust for mediation <Badge color="primary" className="rounded-circle">2</Badge> </Button>
                      </li>
                      <li className="py-2 border-bottom">
                        <Button color="link" className="text-tertiary p-0 font-weight-bold ff-base">Block Users or IP address </Button>
                      </li>
                      <li className="py-2 border-bottom">
                        <Button color="link" className="text-tertiary p-0 font-weight-bold ff-base">New User Ban Requests </Button>
                      </li>
                      <li className="py-2 border-bottom">
                        <Button color="link" className="text-tertiary p-0 font-weight-bold ff-base">Mediation Final </Button>
                      </li>
                      <li className="py-2 border-bottom">
                        <Button color="link" className="text-tertiary p-0 font-weight-bold ff-base">Arbitration Final </Button>
                      </li>
                    </ul>
                  </CollapseBasic>

                  <CollapseBasic title="Urgent Actions Committee" containerClass="type-dark bg-transparent" bodyClass="text-tertiary pb-3" noPadding={true} isOpen={false}>
                    <div className="fs-20">Urgent Actions Committee</div>
                    <hr />
                  </CollapseBasic>
                  <CollapseBasic title="Admin Improve" containerClass="type-dark bg-transparent" bodyClass="text-tertiary pb-3" noPadding={true} isOpen={false}>
                    <div className="fs-20">Admin Improve</div>
                    <hr />
                  </CollapseBasic>
                  <CollapseBasic title="Monitor Users" containerClass="type-dark bg-transparent" bodyClass="text-tertiary pb-3" noPadding={true} isOpen={false}>
                    <div className="fs-20">Monitor Users</div>
                    <hr />
                  </CollapseBasic>
                  <CollapseBasic title="Page Issues" containerClass="type-dark bg-transparent" bodyClass="text-tertiary pb-3" noPadding={true} isOpen={false}>
                    <div className="fs-20">Page Issues</div>
                    <hr />
                  </CollapseBasic>
                  <CollapseBasic title="Flagged Reviews" containerClass="type-dark bg-transparent" bodyClass="text-tertiary pb-3" noPadding={true} isOpen={false}>
                    <div className="fs-20">Flagged Reviews</div>
                    <hr />
                  </CollapseBasic>
                  <CollapseBasic title="Blacklist" containerClass="type-dark bg-transparent" bodyClass="text-tertiary pb-3" noPadding={true} isOpen={false}>
                    <div className="fs-20">Blacklist</div>
                    <hr />
                  </CollapseBasic>
                  <CollapseBasic title="Community Forum" containerClass="type-dark bg-transparent" bodyClass="text-tertiary pb-3" noPadding={true} isOpen={false}>
                    <div className="fs-20">Community Forum</div>
                    <hr />
                  </CollapseBasic>
                </Col>
                <Col md={8}>
                  <div>
                    <div>
                      <div className="mb-2 pt-2">
                        <div className="d-flex mx-n2">
                          <div className="px-2 col">
                            <span className="text-dark font-weight-bold mr-2">Heated Discussion </span>
                            <FormGroup className="d-inline-block">
                              <Input type="select" className="transparent" size="sm">
                                <option>Select Category</option>
                                <option selected>In Progress</option>
                              </Input>
                            </FormGroup>
                          </div>
                          <div className="px-2 col-auto">
                            near <span className="text-dark font-weight-bold">Brooklyn, NY</span>
                          </div>
                        </div>
                      </div>
                      <ul className="list-unstyled d-flex flex-wrap mx-n2">
                        <li className="px-2">
                          <div className="d-flex">
                            <FormGroup>
                              <Input type="select" className="transparent" size="sm">
                                <option>Businesses</option>
                              </Input>
                            </FormGroup>
                            <div className="ml-2">
                              <span className="text-danger">*</span>
                            </div>
                          </div>
                        </li>
                        <li className="px-2">
                          <FormGroup>
                            <Input type="select" className="transparent" size="sm">
                              <option>Select Category</option>
                              <option>IT Services</option>
                              <option>Apartment Rentals</option>
                              <option>Automotive</option>
                              <option>Education</option>
                              <option>Beauty and Spas</option>
                            </Input>
                          </FormGroup>
                        </li>
                        <li className="px-2">
                          <FormGroup>
                            <Input type="select" className="transparent" size="sm">
                              <option>Select Category</option>
                              <option>Asian</option>
                              <option>European</option>
                            </Input>
                          </FormGroup>
                        </li>
                        <li className="px-2">
                          <FormGroup>
                            <Input type="select" className="transparent" size="sm">
                              <option>Select Category</option>
                              <option>Indian</option>
                            </Input>
                          </FormGroup>
                        </li>
                        <li className="px-2">
                          <FormGroup>
                            <Input type="select" className="transparent" size="sm">
                              <option>Select Category</option>
                              <option>Level 6</option>
                            </Input>
                          </FormGroup>
                        </li>
                      </ul>
                    </div>

                    <div>
                      <Nav className="mb-3" tabs>
                        <NavItem className="text-center">
                          <NavLink href="#"
                            className="fs-16 ff-base"
                            active={this.state.mainViewType === 'main'}
                            onClick={() => { this.setState({ mainViewType: 'main' }) }}>
                            Main
                          </NavLink>
                        </NavItem>
                        <NavItem className="text-center">
                          <NavLink href="#"
                            className="fs-16 ff-base"
                            active={this.state.mainViewType === 'userHistory'}
                            onClick={() => { this.setState({ mainViewType: 'userHistory' }) }}>
                            User History
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.mainViewType}>
                        <TabPane tabId="main">
                          <div className="bg-white text-dark p-3">
                            <div className="d-flex mx-n2 mb-2">
                              <div className="px-2 col">
                                <div className="text-primary font-weight-bold fs-16">
                                  Step 1. Get all the facts
                                </div>
                              </div>
                              <div className="px-2 col-auto">
                                <div className="d-flex mx-n1 flex-nowrap">
                                  <div className="px-1 mr-2">
                                    <div className="text-muted">
                                      <div className="fs-26 text-primary ff-headings text-center">
                                        7
                                      </div>
                                      <div className="fs-14 font-weight-normal">
                                        days
                                      </div>
                                    </div>
                                  </div>
                                  <div className="px-1">
                                    <div className="text-muted">
                                      <div className="fs-26 text-dark ff-headings">
                                        15
                                      </div>
                                      <div className="fs-14 font-weight-normal">
                                        hours
                                      </div>
                                    </div>
                                  </div>
                                  <div className="px-1">
                                    <div className="text-muted">
                                      <div className="fs-26 text-dark ff-headings">
                                        32
                                      </div>
                                      <div className="fs-14 font-weight-normal">
                                        minutes
                                      </div>
                                    </div>
                                  </div>
                                  <div className="px-1">
                                    <div className="text-muted">
                                      <div className="fs-26 text-dark ff-headings">
                                        56
                                      </div>
                                      <div className="fs-14 font-weight-normal">
                                        seconds
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <div className="text-dark font-weight-bold mb-2">
                                The Brief
                              </div>
                              <p className="fs-14">
                                The Original Review, currently marked as <span className="font-weight-bold">Non Recommended</span>, was <span className="font-weight-bold">Nominated for deletion</span> due to <span className="font-weight-bold">Facts are Wrong</span> 3 months ago, by <span className="font-weight-bold">Business Owner</span> (a.k.a. the business owner). It was disputed 1 week ago by User2 (a.k.a the reviewer).
                              </p>
                              <hr />
                            </div>

                            <div>
                              <div className="text-dark font-weight-bold mb-2">
                                The Dispute
                              </div>
                              <div>
                              {requested_disputes.map((dispute) => {
                                return ( <div>
                                          <div className="p-3 fs-14 mb-1">
                                            <div className="d-flex mx-n2">
                                        <div className="px-2 col-auto">
                                          <img className="img-circle _50x50" src={require("../../assets/images/icons/user-circle.png")} alt="" />
                                        </div>
                                        <div className="px-2 col">
                                          <div className="d-flex mx-n2 mb-2">
                                            <div className="px-2 col">
                                              <a href="#" className="text-reset font-weight-bold">{dispute?.user_name}</a>
                                              <span>&nbsp;</span>
                                              <span className="text-muted">
                                                Falgged the review as 
                                                " 
                                                <b>{dispute?.flag_name ? dispute?.flag_name : null }</b>  
                                                <b>{dispute?.admin_reason ? dispute?.admin_reason : null } </b>
                                                "
                                                &nbsp; <br/>on <i>
                                              <Moment format="DD-MM-YYYY">
                                                {dispute?.created_on}
                                              </Moment>
                                              &nbsp; at &nbsp;
                                              <Moment format="HH:MM:SS">
                                                  {dispute?.created_on}
                                                </Moment>
                                            </i></span>
                                            </div>
                                            {/* <div className="px-2 col-auto">
                                              <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png" alt="" />
                                            </div> */}
                                          </div>
                                          <div className="fs-15">
                                            <p>{dispute?.content_flagged}</p>
                                          </div>
                                          <div className="mt-2 text-dark font-weight-bold text-right" onClick={() => this.handleDisputedReview(dispute)}>
                                            view more
                                            {/* <div className="fs-13 text-muted">One hour ago via iOS near Lugano</div> */}
                                          </div>
                                        </div>
                                      </div>
                                          </div>
                                    
                                          <div>
                                            <div className="bg-light fs-14 text-center p-3">
                                              {/* <a href="#" className="text-reset font-weight-bold"> */}
                                                {/* {get_dispute_data && get_dispute_data[0] && get_dispute_data[0].user_name}</a> 
                                                filed for <strong>Dispute Resolution</strong> */}

                                                <Button color="primary" className="mw" onClick={() => this.handleDisputeRequest(dispute,'accept')}> Accept </Button>
                                                <Button color="primary" className="mw" onClick={() => this.handleDisputeRequest(dispute,'reject')}> Reject </Button>

                                            </div>
                                          </div>
                                        </div>
                                        )
                                }
                              )} 

                                
                                {/* <div className="p-3 fs-14 mb-1">
                                  <div className="d-flex mx-n2">
                                    <div className="px-2 col-auto">
                                      <img className="img-circle _50x50" src={require("../../assets/images/icons/user-circle.png")} alt="" />
                                    </div>
                                    <div className="px-2 col">
                                      <div className="d-flex mx-n2 mb-2">
                                        <div className="px-2 col">
                                          <a href="#" className="text-reset font-weight-bold">User Anna</a>
                                          <span>&nbsp;</span>
                                          <span className="text-muted">asked about <i>delivery hours</i></span>
                                        </div>
                                        <div className="px-2 col-auto">
                                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png" alt="" />
                                        </div>
                                      </div>
                                      <div className="fs-15">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, sit?</p>
                                      </div>
                                      <div className="mt-2">
                                        <div className="fs-13 text-muted">One hour ago via iOS near Lugano</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bg-light p-3 fs-14 mb-1">
                                  <div className="d-flex mx-n2">
                                    <div className="px-2 col-auto">
                                      <img className="img-circle _50x50" src={require("../../assets/images/icons/user-circle.png")} alt="" />
                                    </div>
                                    <div className="px-2 col">
                                      <div className="d-flex mx-n2 mb-2">
                                        <div className="px-2 col">
                                          <a href="#" className="text-reset font-weight-bold">Business Owner</a>
                                          <span>&nbsp;</span>
                                          <span className="text-muted"><i>Owner</i></span>
                                        </div>
                                        <div className="px-2 col-auto">
                                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png" alt="" />
                                        </div>
                                      </div>
                                      <div className="fs-15">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, sit?</p>
                                      </div>
                                      <div className="mt-2">
                                        <div className="fs-13 text-muted">One hour ago via iOS near Zurich</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bg-light p-3 fs-14 mb-1">
                                  <div className="d-flex mx-n2">
                                    <div className="px-2 col-auto">
                                      <img className="img-circle _50x50" src={require("../../assets/images/icons/user-circle.png")} alt="" />
                                    </div>
                                    <div className="px-2 col">
                                      <div className="d-flex mx-n2 mb-2">
                                        <div className="px-2 col">
                                          <a href="#" className="text-reset font-weight-bold">User John</a>
                                        </div>
                                        <div className="px-2 col-auto">
                                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png" alt="" />
                                        </div>
                                      </div>
                                      <div className="fs-15">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, sit?</p>
                                      </div>
                                      <div className="mt-2">
                                        <div className="fs-13 text-muted">One hour ago via iOS near Zurich</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bg-light p-3 fs-14 mb-1">
                                  <div className="d-flex mx-n2">
                                    <div className="px-2 col-auto">
                                      <img className="img-circle _50x50" src={require("../../assets/images/icons/user-circle.png")} alt="" />
                                    </div>
                                    <div className="px-2 col">
                                      <div className="d-flex mx-n2 mb-2">
                                        <div className="px-2 col">
                                          <a href="#" className="text-reset font-weight-bold">Business Owner</a>
                                          <span>&nbsp;</span>
                                          <span className="text-muted"><i>Owner</i></span>
                                        </div>
                                        <div className="px-2 col-auto">
                                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png" alt="" />
                                        </div>
                                      </div>
                                      <div className="fs-15">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, sit?</p>
                                      </div>
                                      <div className="mt-2">
                                        <div className="fs-13 text-muted">One hour ago via iOS near Zurich</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bg-light p-3 fs-14 mb-1"> */}
                                  {/* <div className="d-flex mx-n2">
                                    <div className="px-2 col-auto">
                                      <img className="img-circle _50x50" src={require("../../assets/images/icons/user-circle.png")} alt="" />
                                    </div>
                                    <div className="px-2 col">
                                      <div className="d-flex mx-n2 mb-2">
                                        <div className="px-2 col">
                                          <a href="#" className="text-reset font-weight-bold">User John</a>
                                        </div>
                                        <div className="px-2 col-auto">
                                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png" alt="" />
                                        </div>
                                      </div>
                                      <div className="fs-15">
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, sit?</p>
                                      </div>
                                      <div className="mt-2">
                                        <div className="fs-13 text-muted">One hour ago via iOS near Zurich</div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                              
                              
                              <hr />
                            </div>

                            <div>
                              <div className="text-dark font-weight-bold mb-2">
                                The Alligations
                              </div>
                              <Row>
                                <Col lg={6}>
                                  <div className="bg-white text-center fs-14 text-dark p-3">
                                    <div className="mb-2">
                                      <img className="img-circle _50x50" src={require("../../assets/images/icons/user-circle.png")} alt="" />
                                    </div>
                                    <div className="font-weight-bold fs-15">
                                      User John
                                    </div>
                                    <div className="text-tertiary">
                                      a.k.a. The Reviewer
                                    </div>
                                    <div>
                                      {/* Repeat this */}
                                      <div className="mt-4">
                                        <div className="ff-alt">
                                          <FontAwesomeIcon icon="quote-left" className="mr-2" size="lg" />
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut facilis nesciunt odio illum debitis vitae corporis suscipit molestias porro aperiam...
                                            <FontAwesomeIcon icon="quote-right" className="ml-2" size="lg" />
                                        </div>
                                        <div>
                                          <Button color="link" className="text-dark font-weight-bold">
                                            <FontAwesomeIcon icon="plus" className="mr-2" size="sm" />
                                            read more
                                          </Button>
                                        </div>
                                        <div className="mt-4">
                                          <div className="text-tertiary font-weight-bold">
                                            5 proofs submitten
                                          </div>

                                          <div className="mt-2">
                                            <ul className="d-flex flex-column list-unstyled">
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">File:</span>
                                                  <span>&nbsp;</span>
                                                  <span>Scanned....Bill-20394.pdf</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">Link:</span>
                                                  <span>&nbsp;</span>
                                                  <span>"Review by user Jane Doe..."</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">Link:</span>
                                                  <span>&nbsp;</span>
                                                  <span>"Review by Alex Doe..."</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">Link:</span>
                                                  <span>&nbsp;</span>
                                                  <span>Screenshot-09-09-2009</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">Link:</span>
                                                  <span>&nbsp;</span>
                                                  <span>"video"</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="mt-3">
                                          <FormGroup className="mb-0 text-left">
                                            <CustomInput bsSize="sm" type="file" />
                                          </FormGroup>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="bg-light text-center fs-14 text-dark p-3">
                                    <div className="mb-2">
                                      <img className="img-circle _50x50" src={require("../../assets/images/icons/user-circle.png")} alt="" />
                                    </div>
                                    <div className="font-weight-bold fs-15">
                                      Business Owner
                                    </div>
                                    <div className="text-tertiary">
                                      a.k.a. The Business Owner
                                    </div>
                                    <div>
                                      {/* Repeat this */}
                                      <div className="mt-4">
                                        <div className="ff-alt">
                                          <FontAwesomeIcon icon="quote-left" className="mr-2" size="lg" />
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut facilis nesciunt odio illum debitis vitae corporis suscipit molestias porro aperiam.
                                            <FontAwesomeIcon icon="quote-right" className="ml-2" size="lg" />
                                        </div>
                                        <div>
                                          <Button color="link" className="text-dark font-weight-bold">
                                            <FontAwesomeIcon icon="plus" className="mr-2" size="sm" />
                                            read more
                                          </Button>
                                        </div>
                                        <div className="mt-4">
                                          <div className="text-tertiary font-weight-bold">
                                            5 proofs submitten
                                          </div>

                                          <div className="mt-2">
                                            <ul className="d-flex flex-column list-unstyled">
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">File:</span>
                                                  <span>&nbsp;</span>
                                                  <span>Scanned....Bill-20394.pdf</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">Link:</span>
                                                  <span>&nbsp;</span>
                                                  <span>"Review by user Jane Doe..."</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">Link:</span>
                                                  <span>&nbsp;</span>
                                                  <span>"Review by Alex Doe..."</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">Link:</span>
                                                  <span>&nbsp;</span>
                                                  <span>Screenshot-09-09-2009</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                              <li className="mb-2">
                                                <Badge color="tertiary" className="fs-13">
                                                  <span className="font-weight-bold">Link:</span>
                                                  <span>&nbsp;</span>
                                                  <span>"video"</span>
                                                  <span className="mx-2">|</span>
                                                  <span role="button" title="Delete">
                                                    <FontAwesomeIcon icon="times" />
                                                  </span>
                                                </Badge>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="mt-3">
                                          <FormGroup className="mb-0 text-left">
                                            <CustomInput bsSize="sm" type="file" />
                                          </FormGroup>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          
                          

                          <div className="mt-3 d-flex mx-n2">
                            <div className="px-2 col-auto ml-auto">
                              <Button color="primary" className="mw">Submit</Button>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="userHistory">
                          User History view
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </>
    )
  }
}

const mapState = (state) => ({
  get_dispute_data: state.feeds.get_dispute_data,
  admin_requested_disputes: state.dispute.admin_requested_disputes,
  get_dispute_modal_status: state.dispute.get_dispute_modal_status,
});

const mapDispatch = (dispatch) => ({
  get_dispute_discussion: (id) => dispatch(get_dispute_discussion(id)),
  get_administrative_requested_disputes: () => dispatch(get_administrative_requested_disputes()),
  handle_admin_request:(params) => dispatch(handle_admin_request(params)),
});

export default connect(mapState, mapDispatch)(NoticeBoard);
