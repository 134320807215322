import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'reactstrap';
import { callApi } from '../../../utils/apiCaller';
import dummyData from '../../../dummyData.json';
import { checkSettingFieldType, getFieldname, getFieldSubText } from '../../../utils/utilities';
import { useDispatch } from 'react-redux';
import { add_privacy_setting, set_setting_enable } from '../../../actions/user';
// import moment from 'moment';

const SettingsAndPrivacy = () => {
    const [settingFields, setSettingFields] = useState([])
    const dispatch = useDispatch();
    useEffect(() => {
        callApi(`/api/settingsjson/`, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {     
                    let formatFields = []               
                    response?.results?.forEach(item=>{
                        if(checkSettingFieldType(item.name)==="radio"){
                            formatFields.push({            
                                value: item.value,
                                label: getFieldname(item.name),
                                name: item.name,
                                type:"radio",           
                                json_data: item.json_data
                            })
                        }else if(checkSettingFieldType(item.name)==="checkbox"){
                            formatFields.push({           
                                value: item.value,
                                label: getFieldname(item.name),
                                name: item.name,
                                type: "checkbox",           
                                json_data: Object.keys(item.json_data)?.map((field)=>{
                                    return {
                                        label: item.json_data?.[field],
                                        value: field,
                                        checked: !!item.value ? Object.keys(item.value).includes(field) : false
                                    }
                                }) 
                            })
                        }
                    })
                    setSettingFields(formatFields || [])
                }
            })
        
        
    }, [])


    const handleChange = (e, checkedName) => {
        const { target: { type, name, value, checked } } = e;        
        if (type === "radio") {
            const mutateIndex = settingFields.findIndex(item => item.name === name)
            setSettingFields(prevState => prevState.map((item, index) =>
                index === mutateIndex
                    ? { ...item, value: value }
                    : item
            ))
        }if(type === "checkbox"){
            const mutateIndex = settingFields.findIndex(item => item.name === checkedName)
            const mutateInnerIndex = settingFields[mutateIndex].json_data.findIndex(item=>item.value===name)
            setSettingFields(prevState => prevState.map((item, index) =>
                index === mutateIndex
                    ? {
                        ...item,
                        json_data: item.json_data.map((innerItem, innerIndex) =>
                            innerIndex === mutateInnerIndex
                                ? { ...innerItem, checked: checked }
                                : innerItem
                        )
                    }
                    : item
            ))
        }
    }  

    const handleSumbit = (e) => {
        e.preventDefault();
        const data = {}
        settingFields.forEach(item=>{
            if(item.type==="radio"){
                data[item.name] = item.value
            }else if(item.type==="checkbox"){
                const checkedData = item.json_data.map((field)=> {  
                    if(field.checked){
                        return {
                            [field.value]: field.label
                        }
                    }else{
                        return {}
                    }                  
                    
                })
                data[item.name] = Object.assign({}, ...checkedData)
            }            
        })
        dispatch(add_privacy_setting(data));
    }
    // const format1 = "MMM-DD HH:mm:ss"
    // var timezones = moment().utc().utcOffset(+330);
    // var unix = moment(timezones).format('lll');
    console.log('settingFieldssettingFields',settingFields)
    return <>

        <div className="px-2 mb-3">
            {/* write your own review, Part 2 */}
            <div className="wk-static-wrapper-settings sticky-main-header">
                <div className="wk-static-sub-head ">
                    <h1>MY PRIVACY & SETTINGS</h1>
                </div>
            </div>
            
            <div>
                <div className="bg-white p-2 sticky-header" style={{borderBottom: "5px solid #f4f3ea"}}>
                    <h2 className="text-secondary  fs-36">
                        My Privacy
                    </h2>
                </div>

                {/* Step 1 My privacy */}
                <div className="bg-white mb-3">
                    <Container>
                        
                        <Row>
                            <Col lg="12">
                                <div className="wk-static-content-body">
                                    <Form>
                                        <div className="mb-3 mt-3">
                                            {settingFields?.map((mainfield, index) => {
                                                return index < 3 && <Row className='mb-2'>
                                                    <Col md="3">
                                                        <label className="fs-16 text-secondary font-weight-bold text-capitalize">{mainfield.label}</label>
                                                    </Col>
                                                    <Col md="9">
                                                        {mainfield?.type === 'radio' && <>
                                                            {Object.keys(mainfield?.json_data)?.map((innerField, index) => {
                                                                return <label className="fs-14 w-100 text-secondary-dark" key={index}>
                                                                    <input type="radio" id={mainfield?.name} className="mr-2" value={innerField} checked={mainfield.value===innerField} name={mainfield?.name} onChange={(e)=>handleChange(e)}  />
                                                                    {mainfield?.json_data?.[innerField]}
                                                                </label>
                                                            })}
                                                        </>}                                                        
                                                        {mainfield?.type === 'checkbox' && <>
                                                            {mainfield?.json_data?.map((innerField, index) => {
                                                                return <label className="fs-14 w-100 text-secondary-dark containerCheckbox" key={index}>{innerField?.label}
                                                                <input type="checkbox" className="mr-2 regular-checkbox" checked={innerField?.checked} name={innerField.value} onChange={(e)=>handleChange(e,mainfield.name)}/>
                                                                <span className="checkmark"></span>
                                                              </label>
                                                            })}
                                                        </>}    
                                                        {!!getFieldSubText(mainfield.name) && <span className="pl-3 ml-1 fs-13 font-weight-light text-secondary-dark">{getFieldSubText(mainfield.name)}</span>  }                                                  
                                                    </Col>
                                                </Row>
                                            })}
                                            <div className="text-right">
                                                <Button color="primary" onClick={handleSumbit}>Save Changes</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="bg-white p-2 mb-1 sticky-header-2" style={{borderBottom: "5px solid #f4f3ea"}}>
                    <h2 className="text-secondary  fs-36">
                        My Settings
                    </h2>
                </div>

                {/* Step 2 Collapse */}
                <div className="bg-white mb-3" >
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="wk-static-content-body">
                                    <Form>
                                        <div className="mb-3 mt-3">                                            
                                            {settingFields?.map((mainfield, index) => {
                                                return (index > 2 && index < 8) && <Row className='mb-2'>
                                                    <Col md="3">
                                                        <label className="fs-16 text-secondary font-weight-bold text-capitalize">{mainfield.label}</label>
                                                    </Col>
                                                    <Col md="9">
                                                        {mainfield?.type === 'radio' && <>
                                                            {Object.keys(mainfield?.json_data)?.map((innerField, index) => {
                                                                return <label className="fs-14 w-100 text-secondary-dark" key={index}>
                                                                    <input type="radio" id={mainfield?.name} className="mr-2" value={innerField} checked={mainfield.value===innerField} name={mainfield?.name} onChange={(e)=>handleChange(e)}  />
                                                                    {mainfield?.json_data?.[innerField]}
                                                                </label>
                                                            })}
                                                        </>}                                                        
                                                        {mainfield?.type === 'checkbox' && <>
                                                            {mainfield?.json_data?.map((innerField, index) => {
                                                                return <><label className="fs-14 w-100 text-secondary-dark containerCheckbox" key={index}>{innerField?.label}
                                                                <input type="checkbox" className="mr-2 regular-checkbox" checked={innerField?.checked} name={innerField.value} onChange={(e)=>handleChange(e,mainfield.name)}/>
                                                                <span className="checkmark"></span>
                                                              </label>
                                                              {!!getFieldSubText(innerField.value) && <div className="pl-3 ml-1 mb-2"><span className="fs-13 font-weight-light text-secondary-dark">{getFieldSubText(innerField.value)}</span></div>  }                                                     
                                                              </>
                                                            })}
                                                        </>}  
                                                        
                                                    </Col>
                                                </Row>
                                            })}
                                            <div className="text-right">
                                                <Button color="primary" onClick={handleSumbit}>Save Changes</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="bg-white p-2 mb-1 sticky-header-3" style={{borderBottom: "5px solid #f4f3ea"}}>
                    <h2 className="text-secondary  fs-36">
                        Email Settings
                    </h2>
                </div>

                <div className="bg-white mb-3" >
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="wk-static-content-body">
                                    <Form>
                                        <div className="mb-3 mt-3">                                            
                                            {settingFields?.map((mainfield, index) => {
                                                return (index > 7) && <Row className='mb-2'>
                                                    <Col md="3">
                                                        <label className="fs-16 text-secondary font-weight-bold text-capitalize">{mainfield.label}</label>
                                                    </Col>
                                                    <Col md="9">
                                                        {mainfield?.type === 'radio' && <>
                                                            {Object.keys(mainfield?.json_data)?.map((innerField, index) => {
                                                                return <label className="fs-14 w-100 text-secondary-dark" key={index}>
                                                                    <input type="radio" id={mainfield?.name} className="mr-2" value={innerField} checked={mainfield.value===innerField} name={mainfield?.name} onChange={(e)=>handleChange(e)}  />
                                                                    {mainfield?.json_data?.[innerField]}
                                                                </label>
                                                            })}
                                                        </>}                                                        
                                                        {mainfield?.type === 'checkbox' && <>
                                                            {mainfield?.json_data?.map((innerField, index) => {
                                                                return <label className="fs-14 w-100 text-secondary-dark containerCheckbox" key={index}>{innerField?.label}
                                                                <input type="checkbox" className="mr-2 regular-checkbox" checked={innerField?.checked} name={innerField.value} onChange={(e)=>handleChange(e,mainfield.name)}/>
                                                                <span className="checkmark"></span>
                                                              </label>
                                                            })}
                                                        </>}   
                                                        {!!getFieldSubText(mainfield.name) && <div className="pl-3 ml-1"><span className="fs-13 font-weight-light text-secondary-dark">{getFieldSubText(mainfield.name)}</span></div>  }                                                     
                                                    </Col>
                                                </Row>
                                            })}
                                            <div className="text-right">
                                                <Button color="primary" onClick={handleSumbit}>Save Changes</Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="bg-white p-2 mb-1 sticky-header-4" style={{borderBottom: "5px solid #f4f3ea"}} >
                    <h2 className="text-secondary  fs-36">
                        Account Status
                    </h2>
                </div>

                <div className="bg-white mb-3" >
                    <Container>
                        <Row className="pb-3">
                            <Col lg="12">
                                <div className="wk-privacy-setting-content mb-3 mt-3 pb-3">
                                    <label class="text-secondary mb-2 fs-16">Deactivate my WikiReviews account</label>
                                    <p className="fs-14">Deactivating your account will remove your personal profile from WikiReviews temporarily. You can reactivate your account anytime by logging back into WikiReviews.</p>
                                    <Link to="/deactivateaccount">

                                        <Button color="primary">Deactivate My account</Button>
                                    </Link>
                                </div>
                                <div className="wk-privacy-setting-content mb-3 pb-3">
                                    <label class="text-secondary mb-2 fs-16">Delete my WikiReviews account</label>
                                    <p className="fs-14">Deleting your account will remove your personal profile from wikiReviews permanently. Once you delete your account, it can not be restored. Are you sure about you don't want to deactivate your account temporarily?</p>
                                    <Link to="/deleteaccount">
                                        <Button color="primary">Delete My account</Button>
                                    </Link>
                                </div>
                                <div className="text-right">
                                    <Button color="primary" onClick={()=>dispatch(set_setting_enable(false))}>Cancel</Button>                                   
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </div>
    </>
}


export default SettingsAndPrivacy;