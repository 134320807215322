
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';
import Carousel from 'react-multi-carousel';
import { fetch_compare_products} from '../../../actions/products'
import { callApi } from "../../../utils/apiCaller";
import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import { isUndefined } from 'lodash';
import { getReviewSuffix } from '../../../utils/utilities';
const chartEvents = [
    {
        eventName: "select",
        callback({ chartWrapper }) {
            // removed console from here.
        }
    }
];

const options = {
    hAxis: {
        title: 'Users',
    },
    vAxis: {
        title: 'Rating',
    },
};
class leftcompareProducts extends Component {
    constructor(props) {
        super(props);
		this.state = {
            compareProductsModalToggle: false,
			prodId:0,
			similarProducts:"",
			compareModal:false,
			selectedId:[],
			comparProducts:[],
			lastObject:"",
			about: null,
			contentType:"products",
			removeIndex: {},
			message:"",
			setGraphValue: 'Overall Rating',
			chartDataitems:{},
			loaded : false
            
        }
        
    }
	
  componentWillReceiveProps(nextProps) {
        const { allProducts,productId,aboutDetails} = nextProps;
		if (productId !==this.state.prodId) {	
			this.setState({
                prodId: productId,
				selectedId:[],
				comparProducts:[],
            });	
			
		}
	/*if (nextProps?.fetch_compare_products && nextProps?.fetch_compare_products?.results && Array.isArray(nextProps?.fetch_compare_products?.results) && nextProps?.fetch_compare_products?.results?.length) {
		this.setState({
			comparProducts: nextProps.fetch_compare_products.results
		  });
		}*/
	}
	cancelProduct = () => {
		this.setState({
		 compareModal: !this.state.compareModal,
		 removeIndex:{}
		});
	}
	getDataItems=(id)=>{
		callApi(`/api/detailsstarrating/?id=${id}`, "GET").then((response) => {
			 if (response && response.code !== 400 && response.code !== 500) {
				this.setState({
					chartDataitems:{...this.state.chartDataitems,[id]:response[this.state.setGraphValue]}
				})
			 }
			 else return []
		 });
	 }	
	openCompareModal = () => {
		let {selectedId} = this.state;
		let {productId}=this.props;
		if(selectedId.length <= 4 && selectedId.length >=1){
			this.setState({chartDataitems:{}})
			let compareIds = [...selectedId];
			compareIds.unshift(productId);
			
		callApi(
				`/api/products/?category=${compareIds.join()}&compare=true`,
				'GET'
			  ).then((response) => {
				if (response && response.code !== 400 && response.code !== 500) {  
				   if(response?.results && Array.isArray(response?.results) && response?.results?.length > 0){
					
					compareIds.map((i)=>this.getDataItems(i));
					this.setState({
					  compareProductsModalToggle: true,
					  comparProducts:response.results,
					  removeIndex:{}
					});
				   }
				}
		  });
		}else if(selectedId.length > 4){
			this.setState({
			 compareModal: true,
			 message : "You can compare maximum 4 products."
			});
		}else{
			this.setState({
			 compareModal: true,
			 message : "Please select minimum 1 products for comparsion."
			});
			
		}
			
	  };
	  handleSelectedProducts = (event) => {
		const {loaded} = this.state;
		if (event.target.name == "select_similar_products" &&
			event.target.type === "checkbox") {
			if (event.target.checked) {
				// Pushing the object into array
				this.state.selectedId.push(event.target.value);
			} else {
				let el = this.state.selectedId.find(
					(itm) => itm === event.target.value
				);
				if (el)
					this.state.selectedId.splice(
						this.state.selectedId.indexOf(el),
						1
					);
			}
			
			this.setState({
				selectedId: this.state.selectedId,
			});
			this.props.getloaded({ loaded });
		}
	}
	
	handleRemoveProduct = (event) => {
		let { removeIndex } = this.state;
		if ( event.target.type === "checkbox") {
			if (event.target.checked) {
				removeIndex[event.target.value] = true;
			}
			this.setState({
				removeIndex
			 });
		}
	}
	
	 compareProductList = (type) => {
		//const {chartStar_Ratings} = this.props
		let { selectedId, removeIndex, loaded } = this.state;
		let comparProducts = this.state.comparProducts;
		
		// let dataItems = [];
        // if (chartStar_Ratings && chartStar_Ratings[setGraphValue]) {
        //     dataItems = chartStar_Ratings[setGraphValue]
        // }
		if (comparProducts && comparProducts.length > 0) {
			let currntProdIndex=comparProducts.findIndex((i)=> !isUndefined(i) && i.id==this.props.productId);
			let currntProd=comparProducts[currntProdIndex];
			comparProducts.splice(currntProdIndex,1);
			comparProducts.unshift(currntProd);

            return comparProducts.map((item, index) => {
				let avg_rating = item && item.total_rating && item.total_rating.avg_rating?.[0] ? item.total_rating.avg_rating?.[0] : 'norating.png';
                if (type === "productRemove") {
					return (
					
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>
						{item.id!==this.state.prodId ? <FormGroup check>
							<Label check>
								<Input type="checkbox" value={item.id} onChange={this.handleRemoveProduct} />{' '}
									remove
								</Label>
						</FormGroup>: `Current Product`}
					</td>
                    )
                }
				if (type === "productName") {
                    return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false} onClick={() => { this.setState({ compareProductsModalToggle: false, selectedId: item.id != this.state.prodId ? [] : selectedId, comparProducts: "" }, () => this.props.getloaded({ loaded })); item.id != this.state.prodId && this.testChildFunciton() }}> <Link className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
							to={{
								pathname: `/${this.state.contentType}/${item.slug}`,
								state: { id: item.id }
							}}>
							{item.name ? item.name : "--"}</Link>
						</td>
                    )
                } 
				if (type === "productImage") {
                    return (
						<td key={index} onClick={() => { this.setState({ compareProductsModalToggle: false, selectedId: item.id != this.state.prodId ? [] : selectedId }, () => this.props.getloaded({ loaded })); item.id != this.state.prodId && this.testChildFunciton() }} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>
						<div className="product-img">
								<Link className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
															to={{
																pathname: `/${this.state.contentType}/${item.slug}`,
																state: { id: item.id }
															}}>
								<img src={`${item && item.listing_profileimage && item.listing_profileimage.length > 0 && item.listing_profileimage[0] && item.listing_profileimage[0].url}`}
                                                        onError={(error) =>
                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                        }
                                                        alt={`${item && item.listing_profileimage && item.listing_profileimage.length > 0 && item.listing_profileimage[0] && item.listing_profileimage[0].caption ? item.listing_profileimage[0].caption : 'no-image'}`}
                                                    />
								</Link>
							</div>
						</td>
                    )
                }
				if (type === "productRating") {
					return (
                        <td hidden={removeIndex && removeIndex[item.id] == true ? true : false} key={index}><div>
							<img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
										className="img-fluid"
										onError={(error) =>
											(error.target.src = `../../../assets/images/norating.png`)
										}
										alt={`${item && item.total_rating && item.total_rating.avg_rating?.[1] ? item.total_rating.avg_rating?.[1] : 'no-rating'}`}
							/>
							</div>
							<span> {(item && item.total_rating && item.total_rating.total_review) ? item.total_rating.total_review : 0} {getReviewSuffix(item.total_rating.total_review)}</span>
						</td>
                    )
                } 
				if (type === "price_min") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["price_min"] ? item.additional_info["price_min"][0].value : "-"}</td>
						
                    )
                }
				if (type === "price_max") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["price_max"] ? item.additional_info["price_max"][0].value : "-"}</td>
						
                    )
                }
				if (type === "manufacturer_cut_sheet") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["manufacturer_cut_sheet"] ? item.additional_info["manufacturer_cut_sheet"][0].value : "-"}</td>
						
                    )
                }
				if (type === "packed_width") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["packed_width"] ? item.additional_info["packed_width"][0].value : "-"}</td>
						
                    )
                }
				if (type === "packed_height") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["packed_height"] ? item.additional_info["packed_height"][0].value : "-"}</td>
						
                    )
                }
				if (type === "packed_depth") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["packed_depth"] ? item.additional_info["packed_depth"][0].value : "-"}</td>
						
                    )
                }
				if (type === "assembled_width") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["assembled_width"] ? item.additional_info["assembled_width"][0].value : "-"}</td>
						
                    )
                }
				if (type === "assembled_height") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["assembled_height"] ? item.additional_info["assembled_height"][0].value : "-"}</td>
						
                    )
                }
				if (type === "assembled_depth") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["assembled_depth"] ? item.additional_info["assembled_depth"][0].value : "-"}</td>
						
                    )
                }
				if (type === "packed_weight") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["packed_weight"] ? item.additional_info["packed_weight"][0].value : "-"}</td>
						
                    )
                }
				if (type === "assembled_weight") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["assembled_weight"] ? item.additional_info["assembled_weight"][0].value : "-"}</td>
						
                    )
                }
				if (type === "manufacturer_warranty") {
					return (
                        <td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["manufacturer_warranty"] ? item.additional_info["manufacturer_warranty"][0].value : "-"}</td>
						
                    )
                }
				
				if(type === "readMore"){
					return (
						<td key={index} onClick={() => { this.setState({ compareProductsModalToggle: false, comparProducts: "", selectedId: item.id != this.state.prodId ? [] : selectedId }, () => this.props.getloaded({ loaded })); item.id != this.state.prodId && this.testChildFunciton() }} hidden={removeIndex && removeIndex[item.id] == true ? true : false}> <Link className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
							to={{
								pathname: `/${this.state.contentType}/${item.slug}`,
								state: { id: item.id }
							}}>Read all {item?.total_rating?.total_review} Reviews</Link>
						</td>
					 )
				}
				
				if(type === "OverallRating"){
					return (
					<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}> <span> Overall Rating distributions</span>
                                <div>
								{/* Chart Area */}
								<Chart
									chartType="LineChart"
									data={this.state.chartDataitems? this.state.chartDataitems[item.id] :{}}
									options={options}
									graphID={`LineChart_${item.id}`}
									width="100%"
									height="400px"
									chartEvents={chartEvents}
								/>
							</div>
                  </td>
				  )
				}
				if(type === "priceSpace"){
					return (
					<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["List Price"] ? item.additional_info["List Price"][0].value : "-"}</td>
					)
				}
				
				
				 
			 })
        }
    }
	
	testChildFunciton = () =>{
		var elements = document.getElementsByTagName("INPUT");
			for (var inp of elements) {
				if (inp.type === "checkbox")
					inp.checked = false;
			}
			
	}

    render() {
		const {allProducts,aboutDetails,productId,chartStar_Ratings} = this.props
		let { similarProducts, selectedId, about, loaded } = this.state;
		
		const responsive = {
            videoModal: {
                mobile: {
                    breakpoint: { max: 9999999, min: 0 },
                    items: 1
                }
            },
            viewGalleryImages: {
                desktop: {
                    breakpoint: { max: 9999999, min: 1200 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1199, min: 576 },
                    items: 2
                },
                mobile: {
                    breakpoint: { max: 575, min: 0 },
                    items: 1
                }
            }
        }
       return (
            <div>
                <p className="text-body ff-base lh-1-3">
                                            Comparison is a buyer's best friend. Help the
                                            community by adding products related to this
                                            listing. If you think another product could
                                            serve as a viable alternative to the one
                                            featured in this listing, include it here.
										    </p>
                                        <ul className="fs-14 list-unstyled">
										{(allProducts && allProducts?.results && allProducts?.results.length > 0) ? (
					   						allProducts?.results.filter(i => i.id != productId).map((productData, i) => {		
											return (		
                                            <li className="mb-3" key={i}>
                                                <div className="d-flex flex-nowrap mx-n1">
                                                    <div className="px-1 mb-2 col">
                                                        <div className="d-flex mx-n2">
                                                            <div className="px-2 col-auto mt-1">
                                                                <a
                                                                    href="#"
                                                                    className="text-decoration-none"
                                                                >
																{(productData?.listing_profileimage[0]?.media_type === "image" || productData?.listing_profileimage[0]?.media_type === "") ? (
																  <img
																	className="object-fit-cover object-position-center"
																	width={50}
																	height={50}
																	src={`${productData && productData.listing_profileimage && productData.listing_profileimage.length > 0 && productData.listing_profileimage[0] ? productData.listing_profileimage[0].url : 'https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'}`}
														
																	onError={(error) =>
																							(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
																						}
																						alt={`${productData && productData.listing_profileimage && productData.listing_profileimage.length > 0 && productData.listing_profileimage[0] && productData.listing_profileimage[0].caption ? productData.listing_profileimage[0].caption : 'no-image'}`}
																	/>
																)
																: (
																
																<img
																	className="object-fit-cover object-position-center"
																	src='https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'
																	width={50}
																	height={50}
																	alt=""
																	onError={(error) =>
																							(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
																						}
																						
																	/>
																
																)
																
																}
																{productData?.type === "video" && (
																  <video
																	className="object-fit-cover object-position-center"
																	width={50}
																	height={50}
																	controls
																	
																  >
																	<source src={productData?.url} type="video/mp4" />
																	{"Your browser does not support the video tag."}
																  </video>
																)}
                                                                </a>
                                                            </div>

                                                            <div className="px-2 col">
                                                                <div className="d-flex flex-column mx-n2">
                                                                    <div className="px-2 col">
																			<div className="font-weight-bold text-secondary-dark ff-base" onClick={() => { this.setState({ selectedId: productData.id != this.state.prodId ? [] : selectedId }, () => this.props.getloaded({ loaded })); productData.id != this.state.prodId && this.testChildFunciton() }}>
                                                                           <Link className="text-reset text-decoration-none"
                                                                                to={{
                                                                                    pathname: `/${this.state.contentType}/${productData.slug}`,
                                                                                    state: { id: productData.id }
                                                                                }}>
                                                                                {productData.name.length > 30 ? productData.name.substr(0, 30) : productData.name}
                                                                            </Link>
                                                                        </div>
                                                                        <div className="d-flex align-items-center flex-wrap mx-n1">
                                                                            <div className="px-1">
                                                                                <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${productData && productData?.total_rating && productData?.total_rating.avg_rating?.[0] ? productData.total_rating.avg_rating[0] : 'norating.png'}`}
																				className="img-fluid"
																				onError={(error) =>
																					(error.target.src = `../../../assets/images/norating.png`)
																				}
																				alt={`${productData && productData?.total_rating && productData?.total_rating.avg_rating?.[1] ? productData.total_rating.avg_rating?.[1] : 'no-rating'}`}
													/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-2 col-auto">
                                                                        <FormGroup check>
                                                                            <Label check>
																					<Input type="checkbox" name="select_similar_products" id={"selectProduct" + i} defaultChecked={selectedId.includes(productData.id)} value={productData.id} onChange={this.handleSelectedProducts} />{' '}
                                                                                <span className="align-middle">
                                                                                    compare
                                                                                    </span>
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="bg-secondary mb-0 mt-1" />
                                            </li>
                                            );
											})
										  ) : (
										    <div className="item px-4">
												<div className="d-flex flex-column align-items-center justify-content-center">
												  <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
													<span>No result found</span>
												  </div>
												</div>
											  </div>
											  
											)}
                                        </ul>
										<div className="text-right mb-2">
                                            <Button color="link" className="p-0 text-secondary-dark font-weight-bold"
                                               onClick={() => this.openCompareModal()}
												>
                                                Compare
                                                </Button>
                                        </div>
				{/* Compare Products Modal Popup */}
                <Modal size="xl" isOpen={this.state.compareProductsModalToggle} toggle={() => this.setState({ compareProductsModalToggle: !this.state.compareProductsModalToggle })}>
                    <ModalHeader className="px-0" toggle={() => this.setState({ compareProductsModalToggle: !this.state.compareProductsModalToggle })}>
                        COMPARE
                    </ModalHeader>
                    <ModalBody className="p-0 bg-transparent">
                        <div className="bg-white px-3 py-2 text-dark">
                            <h2 className="fs-32 mb-0">Compare Products</h2>
                        </div>

                        <p className="text-white fs-13 my-2">The following chart is a summary of what consumers have said in their product reviews for the products listed below. For each attribute, the consensus is shown as a percentage of agreement.</p>

                        <div className="compare-product-list">
                            <Table className="products-list text-center fs-14">
                                <thead>
                                    <tr className="products-title">
                                       <th scope="row">Products</th>
									   {this.compareProductList('productRemove')}   
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                      <th>Product Name</th>
                                        {this.compareProductList('productName')}   
                                    </tr>
                                    <tr>
                                        <th scope="row"></th>
                                        {this.compareProductList('productImage')}
                                        
                                    </tr>
                                    <tr>
                                        <th scope="row">Star Rating</th>
                                        {this.compareProductList('productRating')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Detailed Star Rating</th>
										{this.compareProductList('OverallRating')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Price</th>
										{this.compareProductList('priceSpace')}
                                        
                                    </tr>
                                    <tr>
                                        <th scope="row">Minimum Price</th>
										{this.compareProductList('price_min')}  
                                        
                                    </tr>
                                    <tr>
                                        <th scope="row">Maximum Price</th>
										{this.compareProductList('price_max')}  
                                    </tr>
                                    <tr>
                                        <th scope="row">Manufacturer Cut Sheet</th>
                                        {this.compareProductList('manufacturer_cut_sheet')} 
                                    </tr>
                                    <tr>
                                        <th scope="row">Packed_width</th>
                                        {this.compareProductList('packed_width')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Packed height</th>
                                       {this.compareProductList('packed_height')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Packed depth</th>
                                        {this.compareProductList('packed_depth')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Assembled width</th>
                                        {this.compareProductList('assembled_width')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Assembled height</th>
                                        {this.compareProductList('assembled_height')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Assembled depth</th>
                                        {this.compareProductList('assembled_depth')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Packed weight</th>
                                        {this.compareProductList('packed_weight')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Assembled weight</th>
                                        {this.compareProductList('assembled_weight')}
                                    </tr>
                                    <tr>
                                        <th scope="row">Manufacturer warranty</th>
                                        {this.compareProductList('manufacturer_warranty')}
                                    </tr>
                                    <tr className="products-title">
                                        <th scope="row">Read More</th>
                                        {this.compareProductList('readMore')}
                                        
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </ModalBody>
                </Modal>
				
				<Modal isOpen={this.state.compareModal} toggle={() => this.setState({ compareModal: !this.state.compareModal })}>
					<ModalHeader toggle={() => this.setState({ compareModal: !this.state.compareModal })}>wikireviews.com says</ModalHeader>
					<ModalBody>
					{this.state.message}
					</ModalBody>
					<ModalFooter>
					</ModalFooter>
				  </Modal>
            </div>
        )
    }
}

const mapState = (state) => ({
	fetch_compareProducts: state.products.fetch_compareProducts,

})

const mapProps = (dispatch) => ({
	fetch_compare_products: (compareId) => dispatch(fetch_compare_products(compareId)),
})

export default connect(mapState, mapProps)(leftcompareProducts);
