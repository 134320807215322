import React, { Component } from 'react';
import { Button, Col, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap';
import AppHeader from '../oragnisms/dashboard/appHeader';
import { connect } from "react-redux";
import { withRouter ,Link } from 'react-router-dom';
import { deactivate_my_account } from '../../actions/user';
import { toast } from "react-toastify";

class DeactivateAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reason: '',
            description: '',
            email_opt: '',
            block: true,
            show_description: false,
            submitDisabled: true
        };
    }

    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let checked = e.target.checked;

        

        if (name == "reason" && value == "other") {
            this.setState({
                ["show_description"]: true,
                ["submitDisabled"]: false,
            });
        } else if (name == "reason" && value != "other") {
            this.setState({
                ["show_description"]: false,
                ["submitDisabled"]: false,
            });
        }
        if (name == "chk_opt") {
            this.setState({
                [name]: checked
            });
        } else {
            this.setState({
                [name]: value
            });
        }

    }


    handleOnSubmitDeactivateAccount = (e) => {
        e.preventDefault();
        let { reason,
            block,
            chk_opt,
            description
        } = this.state;

        let data1 = {};
        let message = "Your account have been deactivated!";

        data1.reason_val = reason
        data1.descrp = description
        data1.block = block
        data1.email_opt = chk_opt
        this.props.deactivate_my_account(data1).then(() => {
            toast(message, {
                autoClose: 2500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
            });
            this.props.history.push('/')
        });
    };


    render() {
        const content = this.state.show_description
            ? <Col lg={6}>
                <FormGroup>
                    <p className="ff-base text-dark font-weight-bold text-dark mb-3">Please explain further</p>
                    <Input bsSize="sm" rows={10} cols={10} type="textarea" className="border" name="description" id="description" onChange={this.handleChange} placeholder="Provide explanation..." />
                </FormGroup>
            </Col> : '';
        
        return (
            <div>
                <AppHeader isEnableCover={false} />
                <div className="wk-static-wrapper">
                    <div className="wk-static-sub-head bg-black">
                        <h1>ACCOUNT DEACTIVATION</h1>
                    </div>
                    <div className="wk-static-content-body bg-transparent mx-auto lh-1-3" style={{ maxWidth: 625 }}>
                        <div className="text-tertiary text-center">
                            <h5 className="fs-36 text-dark mb-4">Are you sure you want to deactivate your account?</h5>
                            <p className="fs-16 ff-base">Deactivating your account will disable your profile and remove your name and picture from most things you've shared on Facebook. Some information may still be visible to others, such as your name in their friends list and messages you sent.</p>
                            <div className="bg-light p-3 text-tertiary mb-4">
                                <div className="fs-36 ff-headings lh-1-2">
                                    You will be missing the latest news of your favorite Businesses, Movies &amp; Products!
                                </div>
                            </div>
                            <p className="fs-14 ff-base">Your 0 connections will no longer be able to keep in touch with you.</p>
                        </div>
                        <hr className="border-tertiary my-4" />

                        <div>
                            <Row>
                                <Col lg={6}>
                                    <p className="ff-base text-dark font-weight-bold text-dark mb-3">Reason for leaving (Required)</p>
                                    <FormGroup className="mb-1" check>
                                        <Label
                                            className="text-reset font-weight-normal fs-14 d-block"
                                            check
                                        >
                                            <Input className="checked-bold" type="radio" id="reason1" name="reason" value="temporary" onChange={this.handleChange} />{" "}
                                            <span className="mb-1">
                                                This is temporary. I will be back.
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label
                                            className="text-reset font-weight-normal fs-14 d-block"
                                            check
                                        >
                                            <Input className="checked-bold" type="radio" id="reason2" name="reason" value="another_account" onChange={this.handleChange} />{" "}
                                            <span className="mb-1">
                                                I have another WikiReviews Account
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label
                                            className="text-reset font-weight-normal fs-14 d-block"
                                            check
                                        >
                                            <Input className="checked-bold" type="radio" id="reason3" name="reason" value="account_hacked" onChange={this.handleChange} />{" "}
                                            <span className="mb-1">
                                                My account was hacked
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label
                                            className="text-reset font-weight-normal fs-14 d-block"
                                            check
                                        >
                                            <Input className="checked-bold" type="radio" id="reason4" name="reason" value="not_useful" onChange={this.handleChange} />{" "}
                                            <span className="mb-1">
                                                I don't find WikiReviews to be useful
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label
                                            className="text-reset font-weight-normal fs-14 d-block"
                                            check
                                        >
                                            <Input className="checked-bold" type="radio" id="reason5" name="reason" value="not_safe" onChange={this.handleChange} />{" "}
                                            <span className="mb-1">
                                                I don't feel safe on WikiReviews
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label
                                            className="text-reset font-weight-normal fs-14 d-block"
                                            check
                                        >
                                            <Input className="checked-bold" type="radio" id="reason6" name="reason" value="privacy_concern" onChange={this.handleChange} />{" "}
                                            <span className="mb-1">
                                                I have a privacy concern
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label
                                            className="text-reset font-weight-normal fs-14 d-block"
                                            check
                                        >
                                            <Input className="checked-bold" type="radio" id="reason7" name="reason" value="dont_understand_to_use" onChange={this.handleChange} />{" "}
                                            <span className="mb-1">
                                                I don't understand how to use WikiReviews
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label
                                            className="text-reset font-weight-normal fs-14 d-block"
                                            check
                                        >
                                            <Input className="checked-bold" type="radio" id="reason8" name="reason" value="many_notifications" onChange={this.handleChange} />{" "}
                                            <span className="mb-1">
                                                I get too many emails, invitations and requests from WikiReviews
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label
                                            className="text-reset font-weight-normal fs-14 d-block"
                                            check
                                        >
                                            <Input className="checked-bold" type="radio" id="reason9" name="reason" value="other" onChange={this.handleChange} />{" "}
                                            <span className="mb-1">
                                                Other, please explain further
                                            </span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                {content}
                            </Row>
                            <hr className="border-tertiary my-3" />
                            <FormGroup>
                                <p className="ff-base text-dark font-weight-bold text-dark mb-3">Email opt out</p>
                                <div className="mb-2 text-dark fs-14 font-weght-normal">
                                    <CustomInput type="checkbox" onChange={this.handleChange} id="email_opt" name="chk_opt" className="dark fs-14" label={<span className="font-weight-normal" >Opt out of receiving future emails from <strong>WikiReviews.</strong></span>} />
                                </div>
                                <p class="ff-base text-tertiary fs-13">Note: Even after you deactivate, your friends can still invite you to events, tag you in photos, or ask you to join groups. If you opt out, you will NOT receive these email invitations and notifications from your friends.</p>
                            </FormGroup>
                        </div>
                        <div className="d-flex flex-wrap justify-content-end mt-4">
                            <div>
                                <Button disabled={this.state.submitDisabled} color="tertiary" onClick={(e) => this.handleOnSubmitDeactivateAccount(e)} size="lg">Confirm</Button>
                                <Link to="/home"><Button color="primary" size="lg">Cancel</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    my_privacy_setting: state.user.my_privacy_setting
});

const mapProps = (dispatch) => ({
    deactivate_my_account: (data) => dispatch(deactivate_my_account(data)),
    // get_my_privacy_list: () => dispatch(get_my_privacy_list()),


});

export default withRouter(connect(mapState, mapProps)(DeactivateAccount));