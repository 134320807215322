import { callApi, callApiAdvance } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { getQueryString, } from '../utils/validation';
import {FETCH_HIGHLY_SUB_CATEGORIE_ITEMS} from "./business";

export const FETCH_COLLAGE_NATIONAL = 'FETCH_COLLAGE_NATIONAL';
export const FETCH_COLLAGE_CITY = 'FETCH_COLLAGE_CITY';
export const FETCH_COLLAGE_PROJECTS = 'FETCH_COLLAGE_PROJECTS';
export const FETCH_CATEGORY_DISPATCH = 'FETCH_CATEGORY_DISPATCH';
export const FETCH_TOP_CATEGORY_LIST = 'FETCH_TOP_CATEGORY_LIST';
export const FETCH_CURRENT_LOCATION = 'FETCH_CURRENT_LOCATION';
export const GET_SEARCH_HOME = 'GET_SEARCH_HOME';
export const ADD_TMPLOCATION = 'ADD_TMPLOCATION';
export const FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS';
export const ADD_SEARCH_LIST = 'ADD_SEARCH_LIST';
export const FETCH_FILTER_RESULTS = 'FETCH_FILTER_RESULTS';
export const FETCH_SEARCH_CITIES = 'FETCH_SEARCH_CITIES';
export const FETCH_SUBCATEGORIES = 'FETCH_SUBCATEGORIES';
export const FETCH_ADDITIONAL_ATTRIBUTES = 'FETCH_ADDITIONAL_ATTRIBUTES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_FOOTER_RESULTS = 'FETCH_FOOTER_RESULTS';
export const GET_NOTIFICATION_DATA = 'GET_NOTIFICATION_DATA';
export const FETCH_NOTIFICATION_DATA = 'FETCH_NOTIFICATION_DATA';
export const GET_SEARCH_LOCATION_DATA = 'GET_SEARCH_LOCATION_DATA';
export const BROWSE_BY_CATEGORY = 'BROWSE_BY_CATEGORY';
export const SEARCH_RESULTS_LOADING = 'SEARCH_RESULTS_LOADING';

const common_fetch = ({ url, method, data, dispatch_function }) => {
  if (data === undefined) {
    data = {};
  }
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      callApi(url, method, data).then((response) => {
          if (response && response.code !== 400 && response.code !== 500) {
              dispatch(dispatch_function(response));
              return resolve();
          }
          else {
              return resolve();
          }
      });
  });
  };
};

export const browse_by_category_dispatch = (data) => ({ type: BROWSE_BY_CATEGORY, payload: data });

export const browse_by_category = (data) => {
  return common_fetch({
    // url: `/api/search/?content_type=business&cat=${data}&to_api=true&showReview=true&location=true&page=1&recommends=true`,
    url: `/api/search/?content_type=movies&cat=&to_api=true&showReview=true&cPagebest=true&location=true&page=1`,
    method: "GET",
    dispatch_function: browse_by_category_dispatch
  });
};

export const fetch_collage_items_national_dispatch = (data) => ({ type: FETCH_COLLAGE_NATIONAL, payload: data, });
export const fetch_collage_items_national = (...data) => {
  return common_fetch({
    url: getQueryString({ url: `/api/newtrends/`, data }),
    method: "GET",
    dispatch_function: fetch_collage_items_national_dispatch
  });
};


export const fetch_actors_collage_items = (...data) => {
  return common_fetch({
    url: getQueryString({ url: `/api/professionaltrends/`, data }),
    method: "GET",
    dispatch_function: fetch_collage_items_national_dispatch
  });
};



export const ffetch_collage_items_city_dispatch = (data) => ({ type: FETCH_COLLAGE_CITY, payload: data, });
export const fetch_collage_items_city = (...data) => {
  return common_fetch({
    url: getQueryString({ url: `/api/citytrending/`, data }),
    method: "GET",
    dispatch_function: ffetch_collage_items_city_dispatch
  });
};

export const fetch_collage_items_projects_dispatch = (data) => ({ type: FETCH_COLLAGE_PROJECTS, payload: data, });
export const fetch_collage_items_projects = (...data) => {
  return common_fetch({
    url: getQueryString({ url: `/api/projects/`, data }),
    method: "GET",
    dispatch_function: fetch_collage_items_projects_dispatch
  });
};


export const fetch_category__dispatch = (data) => ({ type: FETCH_CATEGORY_DISPATCH, payload: data, });
export const fetch_category_results = (...data) => {
  return common_fetch({
    url: getQueryString({ url: `/api/search/`, data }),
    method: "GET",
    dispatch_function: fetch_category__dispatch
  });
};

export const fetch_trending_crp_dispatch = (data) => ({ type: FETCH_CATEGORY_DISPATCH, payload: data, });
export const fetch_trending_crp = (...data) => {
  return common_fetch({
    url: getQueryString({ url: `/api/trendingcrpcategory/`, data }),
    method: "GET",
    dispatch_function: fetch_trending_crp_dispatch
  });
};

export const clear_dispatch_taxonomy = () => ((dispatch) => { dispatch(fetch_trending_crp_dispatch(null)) });

export const fetch_category_list_dispatch = (data) => ({ type: FETCH_TOP_CATEGORY_LIST, payload: data, });
export const fetch_category_list = (...data) => {
  return common_fetch({
    url: getQueryString({ url: `/api/taxonomy/`, data }),
    method: "GET",
    dispatch_function: fetch_category_list_dispatch
  });
};


export const fetch_current_location_dispatch = (data) => ({ type: FETCH_CURRENT_LOCATION, payload: data, });
export const fetch_current_location = () => {
  return common_fetch({
    url: `/api/settemplocation/`,
    method: "GET",
    dispatch_function: fetch_current_location_dispatch
  });
};


export const get_searchHome_ = (data) => ({ type: GET_SEARCH_HOME, payload: data });


export const get_searchHome = (query, contentType) => {
  return (dispatch) => {
	
    if (contentType === "All") {
      callApi(`/api/search/?q=${query}&page=1&size=3&suggetions=true&sensor=false`, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          dispatch(get_searchHome_(response));
        }
      });
    } else if(contentType == "people" || contentType == "People"){
	callApi(`/api/search/?user=${query}&page=1&sensor=false`, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          dispatch(get_searchHome_(response));
        }
      });

	}else{
      callApi(`/api/search/?q=${query}&content_type=${contentType}&page=1&radius=&size=5&suggetions=true&sensor=false`, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          dispatch(get_searchHome_(response));
        }
      });
    }
  };
};


export const add_tempLocation_ = (data) => {
  return {
    type: ADD_TMPLOCATION,
    payload: data,
  };
};


export const add_tempLocation = (data) => {
  const lastSetupLocation = (JSON.stringify(data));
  localStorage.setItem("lastSetupLocation", lastSetupLocation);
  return (dispatch) => {
    callApi(`/api/settemplocation/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(add_tempLocation_(response));
      }
    });
  };
};

export const clear_search_home = () => {
  return (dispatch) => {
    dispatch(get_searchHome_([]));
  };
};


export const serach_home_result_ = (data) => ({ type: FETCH_SEARCH_RESULTS, payload: data });
export const serach_home_result = (url) => {
  return (dispatch) => {
    dispatch(set_search_results_loading(true));
    callApi(`${url}`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          try {
            return dispatch(serach_home_result_(response));
          } catch (e) {
            return dispatch(serach_home_result_([]));
          }
        }
      })
  }
}

export const send_again_request = (data,query) => {
  let text = 'Your friend request has been sent';
  	
  return (dispatch) => {
    callApi(`/api/connectionrequest/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
		let url = `/api/search/?user=${query}&page=1`;
        dispatch(serach_home_result(url));
        // toast.success("request sent!");
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        }); 
      }
    });
  };
};

export const follow_request = (data,query) => {
  let text = 'Follow Successfully!';
  return (dispatch) => {
    callApi(`/api/following/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
		let url = `/api/search/?user=${query}&page=1`;
        dispatch(serach_home_result(url));
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        }); 
      }
    });
  };
};

export const unfollow_request = (id,query) => {
  let text = 'Unfollow Successfully!';
  return (dispatch) => {
    callApi(`/api/removefollower/?id=${id}&self=true`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
		let url = `/api/search/?user=${query}&page=1`;
        dispatch(serach_home_result(url));
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        }); 
      }
    });
  };
};

export const fetch_filter_result_ = (data) => ({ type: FETCH_FILTER_RESULTS, payload: data });
export const fetch_filter_result = (url) => {
  if (url != "") {
    return (dispatch) => {
      callApi(`${url}`, 'GET')
        .then((response) => {
          if (response && response.code !== 400 && response.code !== 500) {
            try {
              return dispatch(fetch_filter_result_(response));
            } catch (e) {
              return dispatch(fetch_filter_result_([]));
            }
          }
        })
    }
  }
}


export const add_search_list_ = (data) => {
  return {
    type: ADD_SEARCH_LIST,
    payload: data,
  };
};


export const add_search_list = (url, data, type, name, message) => {
  let id = data.user_entry;
  return (dispatch) => {
    callApi(`${url}`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${name} has been ${message} your ${type}.`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        response['id'] = id;
        return dispatch(add_search_list_(response));
      }
    });
  };
};

export const delete_pin_list = (
  id,
  type,
) => {
  return (dispatch) => {
    callApi(
      `/api/pins/${id}/?type=${type}`,
      "DELETE"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
      }
    });
  };
};

export const fetch_search_cities_ = (data) => ({ type: FETCH_SEARCH_CITIES, payload: data });
export const fetch_search_cities = (url) => {
  return (dispatch) => {
    callApi(`${url}`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          try {
            return dispatch(fetch_search_cities_(response));
          } catch (e) {
            return dispatch(fetch_search_cities_([]));
          }
        }
      })
  }
}

export const fetch_categories_ = (data) => ({ type: FETCH_CATEGORIES, payload: data });
export const fetch_categories = (searchType) => {
  return (dispatch) => {
    callApi(`/api/taxonomy/?category=${searchType}&depth=false&delsel=&ancestor=&level=0`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          try {
            return dispatch(fetch_categories_(response));
          } catch (e) {
            return dispatch(fetch_categories_([]));
          }
        }
      })
  }
}

export const fetch_subcategories_ = (data) => ({ type: FETCH_SUBCATEGORIES, payload: data });
export const fetch_subcategories = (categoryId) => {
  return (dispatch) => {
    callApi(`/api/taxonomy/?category=${categoryId}&depth=true&delsel=&ancestor=&level=`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          try {
            return dispatch(fetch_subcategories_(response));
          } catch (e) {
            return dispatch(fetch_subcategories_([]));
          }
        }
      })
  }
}

export const clear_subcategories = () => {
  return (dispatch) => {
    dispatch(fetch_subcategories_([]));
  };
};

export const fetch_additonal_attributes_ = (data) => ({ type: FETCH_ADDITIONAL_ATTRIBUTES, payload: data });
export const fetch_additonal_attributes = (categoryId) => {
  return (dispatch) => {
    callApi(`/api/additionalattributes/?content_type_id=${categoryId}`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          try {
            return dispatch(fetch_additonal_attributes_(response));
          } catch (e) {
            return dispatch(fetch_additonal_attributes_([]));
          }
        }
      })
  }
}

export const fetch_price_sort = (url) => {
  return (dispatch) => {
    callApi(`${url}`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          try {
            //return dispatch(fetch_additonal_attributes_(response));
          } catch (e) {
            //return dispatch(fetch_additonal_attributes_([]));
          }
        }
      })
  }
}

export const clear_features = () => {
  return (dispatch) => {
    dispatch(fetch_additonal_attributes_([]));
  };
};

export const clear_search_result = () => {
  return (dispatch) => {
    dispatch(serach_home_result_([]));
  };
};

export const fetch_footer_result_ = (data) => ({ type: FETCH_FOOTER_RESULTS, payload: data });
export const fetch_footer_result = (categoryId) => {
  return (dispatch) => {
    callApi(`/api/taxonomy/?category=${categoryId}&depth=false&delsel=&ancestor=&sub_cat=`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          try {
            return dispatch(fetch_footer_result_(response));
          } catch (e) {
            return dispatch(fetch_footer_result_([]));
          }
        }
      })
  }
}

export const my_notification_ = (data) => {
  return {
    type: GET_NOTIFICATION_DATA,
    payload: data,
  };
};

export const my_notification = (type) => {
  return (dispatch) => {
    callApiAdvance({
      endpoint: `/api/notification/`,
      loader:false,
      method: "GET"
    }).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(my_notification_(response));
      }
    });
  };
};

export const fetch_notification_ = (data) => {
  return {
    type: FETCH_NOTIFICATION_DATA,
    payload: data,
  };
};

export const fetch_notification_data = (page) => {
  return (dispatch) => {
    callApi(
      `/api/notification/?notify=true&page=${page}`,
      "GET"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(fetch_notification_(response));
      }
    });
  };
};

export const set_nofiticationCount = (data) => {
  return (dispatch) => {
    callApi(`/api/notification/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(my_notification());
      }
    });
  };
};

export const get_search_location_ = (data) => {
  return {
    type: GET_SEARCH_LOCATION_DATA,
    payload: data,
  };
};

export const get_search_location = (data) => {
  const postData = {
    start: data.start,
    length: data.length
  }
  return (dispatch) => {
    callApi(
      `/api/businessfilter/?city=${data.city}&state=&country=${data.country}`,
      "POST", postData
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_search_location_(response));
      }
    });
  };
};

export const set_search_results_loading = (data) => {
  return {
    type: SEARCH_RESULTS_LOADING,
    payload: data,
  };
};


/**
https://wikireviews.com/api/search/?content_type=business&catTrending=True&subcat=1187&trendingType=national&size=6
Request URL: https://wikireviews.com/api/newtrends/?trending=national&content_type=&listingtaxonomy=
Request URL: https://wikireviews.com/api/citytrending/?type=City
Request URL: https://wikireviews.com/api/projects/?&image__isnull=False&is_active=True&is_draft=False

http://wikireviews.com/api/taxonomy/?category=1&depth=false&level=1&show_children=true

http://wikireviews.com/api/settemplocation/
 */
