import React, { Component } from 'react';
import { callApi } from '../../utils/apiCaller';
import CollapseBasic from './collapse';
import { Link } from 'react-router-dom';

export default class SimillarProfessionals extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listingItems: {}
        }
    }


    componentDidMount() {
        this.fetchItems();
    }

    fetchItems = async () => {
        let { id, listing_type } = this.props;
        try {
            let url = `/api/${listing_type}/?id=${id}&similar=${listing_type}`;
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {
                
                this.setState({
                    listingItems: response?.results
                })
            }
        } catch (err) {
            console.log({ err });
        }
    }

    render() {
        let { listingItems } = this.state;
        
        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic title="Similar Professionals" containerClass="bg-transparent type-secondary" bodyClass="text-secondary" isOpen={false}>
                    <ul className="list-unstyled">
                        {listingItems && Array.isArray(listingItems) && listingItems?.length ?

                            listingItems.map(item => (
                                <li className="py-2 border-bottom border-secondary mb-1">
                                    <div className="d-flex flex-nowrap mx-n2">
                                        <div className="px-2 col-auto">
                                            <Link
                                                to={{
                                                    pathname: `/${item?.content_type_name}/${item?.id}`,
                                                    state: { id: item?.id }
                                                }}>
                                                {item?.professional_profileimage?.length > 0 && item?.professional_profileimage[0] != 'Not Available' ? (
                                                    <img
                                                        className="img-circle _50x50"
                                                        src={item.professional_profileimage[0].location}
                                                        alt={item?.name}
                                                    />
                                                )
                                                    :
                                                    <img
                                                        className="img-circle _50x50"
                                                        src={require("../../assets/images/icons/user-circle.png")}
                                                        alt={item?.name}
                                                    />
                                            }
                                            </Link>
                                        </div>
                                        <div className="px-2 col">
                                            <Link
                                                to={{
                                                    pathname: `/${item?.content_type_name}/${item?.id}`,
                                                    state: { id: item?.id }
                                                }}
                                                className="text-secondary-dark font-weight-bold ff-base">{item?.name}</Link>
                                        </div>
                                    </div>
                                </li>
                            ))
                            :
                            null
                        }
                    </ul>
                </CollapseBasic>
            </div>
        )
    }
}
