import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { toast } from "react-toastify";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import { callApi } from '../../../utils/apiCaller';
import OutsideClickHandler from 'react-outside-click-handler';

export default class CastAndCrew extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // professional: require('./cast_crew.json'),//props?.professional || [],
            professional: props?.professional?.professional || [],
            roleText: {},
            inputText: {},
            loaded: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps?.professional) {
            this.setState({
                professional: nextProps?.professional?.professional || [],
            });
        }
    }

    updateRoleId = async (item) => {
        let { inputText } = this.state;
        const { id } = this.props;
        try {
            let data = { "character_role": { "character_role": inputText[item?.sr_no], "id": item?.sr_no, "oldValue": item?.character_role } }
            let response = await callApi(`/api/movies/${id}/`, 'PATCH', data);
            if (response && (response?.code == 201 || response?.code == 200)) {
                toast(`Cast and Crew updated Successfully.`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                this.props.fetchByItemId({ movieId: id })
                this.setState({ loaded: false });
            }
        } catch (err) {
            console.log({ err });
        }
    }

    setValueIndex = (index) => {
        let { roleText, inputText } = this.state;
        if (roleText[index] !== undefined) {
            roleText[index] = !roleText[index];
            inputText[index] = '';
            this.setState({ roleText });
        } else {
            roleText[index] = true;
            inputText[index] = '';
            this.setState({ roleText, inputText });
        }
    }

    render() {
        let { professional, roleText, inputText } = this.state;
        let filteredItems = [];
        let item_values = [];
        if (professional && Array.isArray(professional) && professional?.length) {
            professional.forEach(item => {
                if (!filteredItems.length) {
                    item_values.push({ title: `${item?.first_name} ${item?.last_name || ''}`, data: item });
                    filteredItems.push({ key: item?.category, value: item_values });
                } else {
                    let isMatch = filteredItems.findIndex(dt => dt.key == item?.category);
                    if (isMatch > -1) {
                        let sameItem = filteredItems[isMatch];
                        if (sameItem?.value && Array.isArray(sameItem?.value) && sameItem?.value?.length) {
                            sameItem.value.push({ title: `${item?.first_name} ${item?.last_name || ''}`, data: item })
                        }
                    } else {
                        filteredItems.push({ key: item?.category, value: [{ title: `${item?.first_name} ${item?.last_name || ''}`, data: item }] });
                    }
                }
            });
        }

        return (
            <div className="bg-dark text-white mb-3">
                <CollapseBasic
                    title="Cast & Crew"
                    containerClass="bg-transparent type-white"
                    bodyClass="mt-2 text-white"
                    isOpen={true}>
                    <ul className="list-unstyled">
                        {filteredItems && Array.isArray(filteredItems) && filteredItems?.length ?
                            filteredItems.map(item => (
                                <li>
                                    <div className="fs-16 text-white ff-alt font-weight-bold mb-3">
                                        {item.key == "Directors" && 'Directed by'}
                                        {item.key == "Producers" && 'Produced by'}
                                        {item.key == "Writers" && 'Writers'}
                                        {item.key == "Distributor" && 'Distributed By'}
                                    </div>
                                    <ul className="list-unstyled">
                                        {item.value.map((value_item, value_index) => (
                                            <li className="mb-1">
                                                <div className="d-flex mx-n1 align-items-center">
                                                    <div className="px-1">
                                                        <a href="#" className="text-white">
                                                            <span className="font-weight-bold">
                                                                {value_item?.title}
                                                            </span>
                                                        </a>
                                                    </div>
                                                    <div className="px-1">
                                                        {/* If not editing */}
                                                        <div hidden={!roleText[value_item?.data?.sr_no] ? false : true}>
                                                            <span className="editable-column text-light d-inline-block fs-12" role="button">
                                                                {/* If not added */}
                                                                <div hidden={!roleText[value_item?.data?.sr_no] ? false : true}>
                                                                    <div hidden={item.key == "Distributor" || value_item?.data?.character_role ? true : false}>
                                                                        <i
                                                                            onClick={() => { this.setValueIndex(value_item?.data?.sr_no) }}
                                                                            className="d-inline-block">
                                                                            {'(Click to Add)'}
                                                                        </i>
                                                                    </div>
                                                                </div>
                                                                {/* If added */}
                                                                <div hidden={!value_item?.data?.character_role ? true : false}>
                                                                    <span
                                                                        onClick={() => { this.setValueIndex(value_item?.data?.sr_no) }}
                                                                        className="d-inline-block">
                                                                        {value_item?.data?.character_role}
                                                                    </span>
                                                                </div>
                                                            </span>
                                                        </div>

                                                        {/* If editing */}
                                                        <div hidden={!roleText[value_item?.data?.sr_no] ? true : false}>
                                                            <OutsideClickHandler
                                                                onOutsideClick={() => {
                                                                    if (roleText[value_item?.data?.sr_no]) {
                                                                        this.setValueIndex(value_item?.data?.sr_no)
                                                                    }
                                                                }}>
                                                                <InputGroup size="sm" >
                                                                    <Input

                                                                        disabled={(inputText[value_item?.data?.sr_no] !== undefined) ?
                                                                            false :
                                                                            true}

                                                                        type="text"
                                                                        placeholder="Enter Character Roles"
                                                                        value={inputText[value_item?.data?.sr_no]}
                                                                        onChange={(e) => {
                                                                            inputText[value_item?.data?.sr_no] = e.target.value;
                                                                            this.setState({ inputText });
                                                                        }} />
                                                                    <InputGroupAddon addonType="append">
                                                                        <Button
                                                                            onClick={() => { this.updateRoleId(value_item?.data) }}
                                                                            color="light"
                                                                            disabled={(inputText[value_item?.data?.sr_no] !== undefined &&
                                                                                inputText[value_item?.data?.sr_no]?.length) ?
                                                                                false :
                                                                                true}>
                                                                            <FontAwesomeIcon icon="arrow-right" />
                                                                        </Button>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                            </OutsideClickHandler>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}

                                    </ul>
                                    <hr className="bg-light my-3" />
                                </li>
                            )) : 'No Record Found'}
                    </ul>
                </CollapseBasic>
            </div>
        )
    }
}
