/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';

class TheaterLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movId: 0,
      about: null,
      theaterData: null,
      storyline: null,
      duration: null,
      rating: null,
      currentYear: null
    }
  }
	
  componentWillReceiveProps(nextProps) {
	   const { movieDetails,movieId,theaterDetails} = nextProps;
			if(movieId){
				this.setState({
					movId: movieId,
				});
			}
	}
	
   theatreList = () => {
        let theaterData = this.state.theaterData;
		const {theaterDetails} = this.props
		if (theaterDetails && theaterDetails?.results?.theater_details && Array.isArray(theaterDetails?.results?.theater_details) && theaterDetails.results.theater_details.length > 0) {
            return theaterDetails?.results?.theater_details.map((item, index) => {
		//if (theaterData && theaterData.length > 0) {
            //return theaterData.map((item, index) => {
				return (
                        <div className="wr-movie-details-playing-bottom mt-2" key={index}>
                          <div className="wr-category-heading">
							<p className="fs-14 font-weight-bold">{index + 1}. {item.name}</p>
                            <div className="wr-movie-product-info-bottom">
                              <ul className="m-0 p-0">
                                <li className="d-flex">
                                  <span className="fs-14 wr-movie-product-info-bottom-left">
                                    Address:
                                                             </span>
                                  <span className="fs-14 wr-movie-product-info-bottom-right">
                                    {item.address} {item.city} , {item.state} {item.zip}
                                                             </span>
                                </li>
                                <li className="d-flex">
                                  <span className="fs-14 wr-movie-product-info-bottom-left">
                                    Showtime:
                                                             </span>
                    <span className="fs-14 wr-movie-product-info-bottom-right">
                      {item.address} {item.city} , {item.state} {item.zip}
                    </span>
                  </li>
                  <li className="d-flex">
                    <span className="fs-14 wr-movie-product-info-bottom-left">
                      Showtime:
                                                             </span>
                    <span className="fs-14 wr-movie-product-info-bottom-right">
                      {this.showTime(item.showtime_list)}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )
      })
    } else {

      return (
        <div className="bg-white p-3">
          <h2 className="text-secondary-dark">No Theaters to Display</h2>
        </div>

      )
    }
  }

  tConvert = (time) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  showTime = (data) => {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        if (data.length > 0) {
          return (
            <span>{this.tConvert(item)} | </span>
          )
        } else {
          return null;
        }
      })
    }
  }

  render() {
    const {
      about,
      theaterData,
      duration,
      rating,
      currentYear
    } = this.state;
	const {movieDetails} = this.props;
    let avg_rating = movieDetails && movieDetails.total_rating && movieDetails.total_rating.avg_rating[0] ? movieDetails.total_rating.avg_rating[0] : 'norating.png';

    return (
      <>
        <Container>
		  <Row>
            <Col md={4}>
              <div className="wr-movie-top-about">
                <div className="wr-movie-top-about-body">
                  <div className="wr-movie-product-info-top d-flex flex-wrap">
                    <div className="wr-movie-product-info-top-left">
                      <img src={`${movieDetails && movieDetails.listing_profileimage && movieDetails.listing_profileimage.length > 0 && movieDetails.listing_profileimage[0] && movieDetails.listing_profileimage[0].url}`}
                        className="img-fluid"
                        onError={(error) =>
                          (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                        }
                        alt={`${movieDetails && movieDetails.listing_profileimage && movieDetails.listing_profileimage.length > 0 && movieDetails.listing_profileimage[0] && movieDetails.listing_profileimage[0].caption ? movieDetails.listing_profileimage[0].caption : 'no-image'}`}
                      />
                    </div>
                    <div className="wr-movie-product-info-top-right">
                      <div className="wr-category-heading">
                        <h4>{movieDetails && movieDetails.name}</h4>
                        <div hidden>
                          <InputGroup size="sm">
                            <Input type="text" placeholder="Enter Name" />
                            <InputGroupAddon addonType="append">
                              <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </div>
                        <p className="fs-14 font-weight-bold"><span>{movieDetails?.additional_info?.duration && Array.isArray(movieDetails?.additional_info?.duration) && movieDetails?.additional_info?.duration.length > 0 && movieDetails.additional_info.duration[0].value != null ? movieDetails.additional_info.duration[0].value : ""}</span>  <div className="wr-movie-detal-date-edit" hidden>
                          <InputGroup size="sm">
                            <Input type="text" />
                            <InputGroupAddon addonType="append">
                              <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </div> <span>|</span>{movieDetails?.additional_info.rating ? movieDetails.additional_info.rating[0].value : ""}<span></span><div className="wr-movie-detal-date-edit" hidden>
                            <InputGroup size="sm">
                              <Input type="text" />
                              <InputGroupAddon addonType="append">
                                <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </div> <span>|</span> <span>{movieDetails?.additional_info.year ? movieDetails.additional_info.year[0].value : ""}</span> <div className="wr-movie-detal-date-edit" hidden>
                            <InputGroup size="sm">
                              <Input type="text" />
                              <InputGroupAddon addonType="append">
                                <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </div></p>
                         <p className="fs-14">
                            <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                              className="img-fluid"
                              onError={(error) =>
                                (error.target.src = `../../../assets/images/norating.png`)
                              }
                              alt={`${movieDetails && movieDetails?.total_rating && movieDetails?.total_rating?.avg_rating[1] ? movieDetails?.total_rating?.avg_rating[1] : 'no-rating'}`}
                            />
                            {(movieDetails && movieDetails?.total_rating && movieDetails?.total_rating.total_review) ? movieDetails?.total_rating?.total_review : 0} reviews
                          </p>
                       
                      </div>
                    </div>
                  </div>
                  {this.theatreList()}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>

    )
  }
}


export default TheaterLocation;