import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";

export const GET_TIPS_WARNING = "GET_TIPS_WARNING";
export const GET_BUYERS_GUIDES = "GET_BUYERS_GUIDES";
export const GET_BUYERS_TALKS = "GET_BUYERS_TALKS";
export const GET_BUYERS_VOCABLORY = "GET_BUYERS_VOCABLORY";
export const GET_BUYERS_TRENDING = "GET_BUYERS_TRENDING";
export const GET_BUYERS_SUBCATEGORY = "GET_BUYERS_SUBCATEGORY";
export const GET_BUYERS_HISTORY = "GET_BUYERS_HISTORY";

// Tips Warning
export const get_tips_warning_ = (data) => {
  return {
    type: GET_TIPS_WARNING,
    payload: data,
  };
};

export const get_tips_warning = (taxonomyId) => {
  return (dispatch) => {
    let url = `/api/buyerstipswarning/`;
    if (taxonomyId && taxonomyId != "") {
      url = `/api/buyerstipswarning/?taxonomy=${taxonomyId}`;
    }
    callApi(url, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_tips_warning_(response));
      }
    });
  };
};

export const add_tips_warning = (data, name, taxonomy_id) => {

  return (dispatch) => {
    callApi(`/api/buyerstipswarning/?taxonomy=`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if (data.type == 'warning') {
          text = `You added a warning.`;
        }
        if (data.type == 'tip') {
          text = `You added a tip.`;
        }

        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }

      if (taxonomy_id){
        return dispatch(get_tips_warning(taxonomy_id));
      }
      return dispatch(get_tips_warning(data));
    });
  };
};

export const edit_tips_warning = (data, id, name, taxonomy_id) => {
  return (dispatch) => {
    callApi(`/api/buyerstipswarning/${id}/`, "PUT", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if (data.type == 'warning') {
          text = `You have updated a warning.`;
        }
        if (data.type == 'tip') {
          text = `You have updated a tip.`;
        }
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      if (taxonomy_id){
        return dispatch(get_tips_warning(taxonomy_id));
      }
      return dispatch(get_tips_warning());
    });
  };
};

// BuyersGuide Articles
export const get_buyers_guides_ = (data) => {
  return {
    type: GET_BUYERS_GUIDES,
    payload: data,
  };
};

export const get_buyers_guides = (taxonomyId) => {
  return (dispatch) => {
    let url = `/api/buyersguide/?category=&`;
    if (taxonomyId && taxonomyId != "") {
      url = `/api/buyersguide/?category=${taxonomyId}&`;
    }
    callApi(url, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_buyers_guides_(response));
      }
    });
  };
};

// BuyersGuide Talks
export const get_buyers_talks_ = (data) => {
  return {
    type: GET_BUYERS_TALKS,
    payload: data,
  };
};

export const get_buyers_talks = (url) => {
  return (dispatch) => {

    callApi(url, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_buyers_talks_(response));
      }
    });
  };
};


export const delete_tips_warning = (id, productId, type, taxonomy_id) => {
  return (dispatch) => {
    callApi(`/api/buyerstipswarning/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if (type == 'warning') {
          text = `Your warning is deleted.`;
        }
        if (type == 'tip') {
          text = `Your tip is deleted.`;
        }
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      if (taxonomy_id){
        return dispatch(get_tips_warning(taxonomy_id));
      }
      return dispatch(get_tips_warning());
    });
  };
};



export const delete_buyers_article = (id, taxonomy_id) => {
  return (dispatch) => {
    callApi(`/api/buyersguide/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Your have deleted an article in the Buyer's Guide..`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(get_buyers_guides(taxonomy_id));

    });
  };
};
export const add_buyers_guide = (id, type, taxonomy_id, data, message) => {
  return (dispatch) => {
    var url;
    var dataType;
    url = `/api/buyersguide/?category=${taxonomy_id}&`;
    dataType = "POST";
    if (type == "Edit") {
      url = `/api/buyersguide/${id}/`;
      dataType = "PUT";
      // taxonomy_id = id;
    }
    callApi(url, dataType, data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        // if (response.message) {
          toast(`${message}`, {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        // }
      }
      return dispatch(get_buyers_guides(taxonomy_id));
    });
  };
};

//submit-discuss-article
export const submit_discuss_article = (data, message, taxonomy_id) => {
  // console.log(taxonomy_id , '<<<<<<<<<<<<<<<<<taxonomy_id');
  return (dispatch) => {
    callApi(`/api/buyerstalk/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast( `${message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      let url = `/api/buyerstalk/?category=${taxonomy_id}&page=1`;
      return dispatch(get_buyers_talks(url));
    });
  };
};
// BuyersGuide Vocablory
export const get_vocablory_ = (data) => {
  return {
    type: GET_BUYERS_VOCABLORY,
    payload: data,
  };
};

export const get_vocablory = (taxonomyId) => {
  return (dispatch) => {
    let url = `/api/vocabulary/?taxonomy=&search_term=`;
    if (taxonomyId && taxonomyId != "") {
      url = `/api/vocabulary/?taxonomy=${taxonomyId}&search_term=`;
    }
    callApi(url, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_vocablory_(response));
      }
    });
  };
};

export const search_vocablory = (taxonomyId, search) => {
  return (dispatch) => {
    let url = `/api/vocabulary/?taxonomy=${taxonomyId}&search_term=`;
    if (search && search != "") {
      url = `/api/vocabulary/?taxonomy=${taxonomyId}&search_term=${search}`;
    }
    callApi(url, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_vocablory_(response));
      }
    });
  };
};


export const add_buyers_vocablory = (taxonomyId, data) => {
  return (dispatch) => {
    let url = `/api/vocabulary/?taxonomy=${taxonomyId}&search_term=`;
    callApi(url, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast("You have added a term to the Vocabulary section.", {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(get_vocablory(taxonomyId));
    });
  };
};

// BuyersGuide Trending
export const get_buyers_trending_ = (data) => {
  return {
    type: GET_BUYERS_TRENDING,
    payload: data,
  };
};

export const get_buyers_trending = (taxonomyId) => {
  return (dispatch) => {

    let url = `/api/buyersguide/?category=&&trending=true`;
    if (taxonomyId && taxonomyId != "") {
      url = `/api/buyersguide/?category=${taxonomyId}&trending=true`;
    }
    callApi(url, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_buyers_trending_(response));
      }
    });
  };
};

export const get_subcategory_ = (data) => {
  return {
    type: GET_BUYERS_SUBCATEGORY,
    payload: data,
  };
};
export const search_article_subcategory = (taxonomyId, search) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/search/taxonomy/?catnode=${taxonomyId}&q=${search}&sensor=false`,
      "GET"
    );
    if (response && response?.code !== 400 && response?.code !== 500) {
      return dispatch(get_subcategory_(response));
    }
  }
};


// Get BuyersGuide History
export const get_buyers_guide_history_ = (data) => {
  return {
    type: GET_BUYERS_HISTORY,
    payload: data,
  };
};

export const get_buyers_guide_history = (taxonomyId, url) => {
  return (dispatch) => {
    let api_url = `/api/buyersguide-history-listing/?category=${taxonomyId}&`;
    if (url !== '') {
      api_url = url;
    }
    callApi(api_url, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_buyers_guide_history_(response));
      }
    });
  };
};

// Order article by up & down
export const submit_article_order = (taxonomy_id, data) => {
  return (dispatch) => {
    var url = `/api/buyersguide-article-ordering/?category=${taxonomy_id}&`;

    callApi(url, 'POST', data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        // if (response.message) {
        let message = 'Article\'s order updated successfully.';
        toast(`${message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        // }
      }
      return dispatch(get_buyers_guides(taxonomy_id));
    });
  };
};




