import React from "react";
import { Button } from "reactstrap";

export default class EndOfGallery extends React.Component {
    render() {
        return (
            <div className="item text-white fs-14 h-100 d-flex flex-column justify-content-center mx-auto" style={{ maxWidth: 525 }}>
                <div className="ff-base">
                    <div className="d-flex text-white justify-content-between mt-3">
                        <div className="mr-2 font-weight-bold">
                            The End of the Gallery
                        </div>
                        <div>
                            <a className="text-reset" href="#" target="_blank">Help Us!</a>
                        </div>
                    </div>
                </div>
                <div className="bg-black-shaded-md ff-base text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                    <img className="img-fluid" src={require("../../assets/images/icons/business-gallery.png")} alt="" />
                    <div className="mb-3 mx-auto" style={{ maxWidth: 300 }}>
                        That's all the photos and videos we have of <span className="font-weight-bold">{this.props.businessName}.</span> try adding one of your own.
                    </div>
                    <Button color="link" className="text-primary" onClick={this.props?.handleOnClickUploadMedia ? this.props?.handleOnClickUploadMedia : ''}>Add Photo or Video</Button>
                </div>
                <div className="mt-3">
                    <div className="text-center ff-headings fs-24">Add</div>
                </div>
            </div>
        )
    }
}
