/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { callApi } from '../../../../utils/apiCaller';
import CollapseBasic from '../../../atoms/collapse';
import GenderTable from './genderTable';
import RatingBarChart from './ratingBarChart';
import RatingLineChart from './ratingLineChart';
import SortByDropdown from './sortByDropdown';



const DetailedStarRating = (props) => {

    const [genderAgeRating, setGenderAgeRating] = useState([])
    const [genderLoading, setGenderLoading] = useState(true);
    const [sortByData, setSortByData] = useState([]);
    const [graphType, setGraphType] = useState('Overall Rating');
    const [lineChartResponse, setLineChartResponse] = useState({});
    const [lineChartLoading, setLineChartLoading] = useState(true);
    const [barChartResponse, setBarChartResponse] = useState({});
    const [barChartLoading,setBarChartLoading] = useState(true);
    const [barChartStatus, setBarChartStatus] = useState(false);
    const totalRating = props?.total_rating;
    const dataChanged = useSelector(state=>state.shared.ratingCreated)
    let selected_key = '';
    if (graphType == 'Overall Rating') {
        selected_key = 'avg_rating'
    } else {
        selected_key = graphType;
    }
    const barColor = props.bgLight ? '#d1bb78' : '#879ab5'
    useEffect(() => {
        if(dataChanged != null){
            getTrendingStarRating();
            props.charts.genderTable && getGenderAgeRating();
            props.charts.line && getTrendingGraph();
        }

    }, [dataChanged]);

    useEffect(()=>{
        getTrendingStarRating();
        props.charts.genderTable && getGenderAgeRating();
        props.charts.line && getTrendingGraph();
    }, [props.id,dataChanged]);

    const getGenderAgeRating = async () => {
        let { id } = props;
        let professionalQuery = ''
        if(props.professionals){
            professionalQuery = '&professional=true'
        }
        try {
            let response = await callApi(`/get_gender_age_rating/?listing_id=${id}${professionalQuery}`, 'GET');
            if (response && response.code !== 500) {
                formatGenderData(response);                
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getTrendingStarRating = async () => {
        let { id } = props;
        let categoryType = 'detailsstarrating'
        if(props.professionals){
            categoryType = 'professionaldetailsstarrating'
        }
        try {
            let response = await callApi(`/api/${categoryType}/?id=${id}`, 'GET');
            if (response && response.code !== 500) {
                setBarChartResponse(response)
                formatBarChartData(response["Overall Rating"]) 
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getTrendingGraph = async () => {
        let { id } = props;
        let professionalQuery = ''
        if(props.professionals){
            professionalQuery = '&professional=true'
        }
        try {
            let response = await callApi(`/get_trending_graph/?key=${encodeURIComponent(graphType)}&listing_id=${id}${professionalQuery}`, 'GET');
            if (response && response.code !== 500) { 
                setLineChartResponse(response)
                setLineChartLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const formatBarChartData = (categoryData) => {
        let data = [
            "Rating",
            "Reviews",
            { role: "style" },
            {
                sourceColumn: 0,
                role: "annotation",
                type: "string",
                calc: "stringify",
            },
        ]
        const filteredData = categoryData.map((item, index) => {
            if (index === 0) return data
            return [item[0], item[1], `color: ${barColor}; opacity: ${1 - 0.05 * index}`, null]
        })
        setSortByData(filteredData)
        setBarChartStatus(filteredData.some((item, i) => {
            if (i === 0) return
            return item[1] !== 0
        }))
        setBarChartLoading(false);
    }

  
    const formatGenderData = (genderData)=>{
        
        let genderDataArray 
        if(!!Object.keys(genderData).length){
            genderDataArray = Object.values(genderData).slice(0, -1)
            const tempArray = genderDataArray.map(item=>{
                return {
                    name: item[0], // index 0 --> age_group
                    male: item[1] === "null" ? "No rating" : +item[1], // index 1 --> total_male_avg , index 2 --> no_of_male_reviews
                    female: item[3] === "null" ? "No rating" : +item[3], // index 3 --> total_female_avg , index 4 --> no_of_female_reviews
                    prefer: item[5] === "null" ? "No rating" : +item[5] // index 5 --> no_of_female_reviews , index 6 --> no_of_prefer_not_to_say_reviews
                }
            })
            setGenderAgeRating(tempArray);
            setGenderLoading(false);
        }
    }

    const starColor = props.bgLight ? "gold" : "white";

    return (
        <div
            className={props.bgLight ?
                "bg-white-shaded-md text-secondary mb-3" :
                "bg-movie-dark text-white mb-3"}>
            <CollapseBasic
                title="Detailed Star Rating"
                containerClass={props.bgLight ? "bg-transparent type-secondary" : "bg-transparent type-white"}
                bodyClass={props.bgLight ? "text-secondary" : "mt-2 text-white"}
                isOpen={true}>
                <SortByDropdown
                    setGraphType={(title) => {
                        setGraphType(title);
                        formatBarChartData(barChartResponse[title]);                        
                    }}
                    graphType={graphType}
                    totalRating={totalRating}
                    {...props}
                />
                <div>
                    <div className="font-weight-bold mb-2">
                        <span className={props.bgLight ? "text-secondary-dark": "text-tertiary-darker"}>{graphType == 'Overall Rating ' ? ' ' : graphType}</span>
                        {' Average Rating'}
                    </div>
                    {totalRating !== null && totalRating[selected_key] ? (
                        <img
                            src={`https://userdatawikireviews.s3.amazonaws.com/images/star/${starColor}/${totalRating[selected_key][0]}`}
                            alt={totalRating[selected_key][1]}
                            onError={error =>
                                (error.target.src = ``)
                              }
                        />                        
                    ) : null}
                    {props.charts.bar && <RatingBarChart graphType={graphType} data={sortByData} barChartStatus={barChartStatus} loading={barChartLoading} {...props}/>}
                    {props.charts.line && <RatingLineChart graphType={graphType} data={lineChartResponse} loading={lineChartLoading} {...props}/>}
                    {props.charts.genderTable && <GenderTable genderData={genderAgeRating} loading={genderLoading} {...props}/>}
                </div>
            </CollapseBasic>
        </div>
    )
}

export default DetailedStarRating;