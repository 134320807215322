/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Container, Nav, NavItem, NavLink, } from 'reactstrap';
import SocialButtons from '../../molecules/shared/SocialButtons/socialButtons';

export default class TopBarTabs extends Component {
    render() {
        const { viewBusinessAs, setViewBsuinessAs, business_details, businessId } = this.props;
        return (
            <Container>
                <div className="blurred-content-bg"
                    style={{
                        marginTop: '-54px'
                    }}>
                    <Nav className="featured position-relative bg-white" justified pills style={{ zIndex: 3 }}>
                        <NavItem className="mw-100 margin-right-2">
                            <NavLink className="text-truncate" href="#"
                                active={viewBusinessAs === "about"}
                                onClick={() => { setViewBsuinessAs("about") }}>
                                <span className="font-weight-bold text-uppercase">
                                    {'About'}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="mw-100 margin-right-2">
                            <NavLink className="text-truncate step-3_Listing" href="#"
                                active={viewBusinessAs === "gallery"}
                                onClick={() => {
                                    window.dispatchEvent(new Event("resize"));
                                    setViewBsuinessAs("gallery")
                                }}>
                                <span className="font-weight-bold text-uppercase">
                                    {'Gallery'}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="mw-100 margin-right-2">
                            <NavLink className="text-truncate step-4_Listing" href="#"
                                active={viewBusinessAs === "location"}
                                onClick={() => { setViewBsuinessAs("location") }}>
                                <span className="font-weight-bold text-uppercase">
                                    {'Location'}
                                </span>
                            </NavLink>
                        </NavItem>                        
                        <NavItem className="mw-100">
                            <SocialButtons categoryId={businessId} categoryType="business" categoryDetails={business_details} forceToggleLoginModal={this.props.forceToggleLoginModal} showHistoryTab={true} />
                        </NavItem>
                    </Nav>
                </div>
            </Container>
        )
    }
}
