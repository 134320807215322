
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import { fetch_compare_products } from '../../../actions/products'
import { callApi } from "../../../utils/apiCaller";
import { Link, NavLink } from "react-router-dom";
import { Chart } from "react-google-charts";
import { getReviewSuffix } from '../../../utils/utilities';
const chartEvents = [
	{
		eventName: "select",
		callback({ chartWrapper }) {
			// removed console from here.
		}
	}
];

const options = {
	hAxis: {
		title: 'Users',
	},
	vAxis: {
		title: 'Rating',
	},
};
class compareProducts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			compareProductsModalToggle: false,
			prodId: 0,
			similarProducts: "",
			compareModal: false,
			selectedId: [],
			comparProducts: "",
			lastObject: "",
			about: null,
			contentType: "products",
			removeIndex: {},
			message: "",
			setGraphValue: 'Overall Rating',
			loaded : false,
			keySimilar:"",
			chartDataitems:{},
		}

	}

	componentWillReceiveProps(nextProps) {
		const { allProducts, productId, aboutDetails, keyCompare } = nextProps;
		if (productId) {
			this.setState({
				prodId: productId,
				selectedId: []
			});
		}
		if(keyCompare){
			this.setState({
				keySimilar: keyCompare
			});
		}
		

		/*if (nextProps?.fetch_compare_products && nextProps?.fetch_compare_products?.results && Array.isArray(nextProps?.fetch_compare_products?.results) && nextProps?.fetch_compare_products?.results?.length) {
			this.setState({
				comparProducts: nextProps.fetch_compare_products.results
			  });
			}*/
	}

	cancelProduct = () => {
		this.setState({
			compareModal: !this.state.compareModal,
			removeIndex: {},
			selectedId : []
		});
	}

    getDataItems = (id) => {
        callApi(`/api/detailsstarrating/?id=${id}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                this.setState({
                    chartDataitems: { ...this.state.chartDataitems, [id]: response[this.state.setGraphValue] }
                })
            }
        });
    }

	openCompareModal = () => {
		let { selectedId} = this.state;
		let {productId}=this.props;
		if (selectedId.length <= 4 && selectedId.length >= 1) {
			this.setState({chartDataitems:{}})
			let compareIds = [...selectedId];
			compareIds.unshift(productId);
			
			callApi(
				`/api/products/?category=${compareIds.join()}&compare=true`,
				'GET'
			).then((response) => {
				if (response && response.code !== 400 && response.code !== 500) {
					if (response?.results && Array.isArray(response?.results) && response?.results?.length > 0) {
                        compareIds.forEach((i) => this.getDataItems(i));
						this.setState({
							compareProductsModalToggle: true,
							comparProducts: response.results,
							removeIndex: {}
						});
					}
				}
			});
		} else if (selectedId.length > 4 ) {
			this.setState({
				compareModal: true,
				message: "You can compare maximum 4 products."
			});
		} else {
			this.setState({
				compareModal: true,
				message: "Please select minimum 1 products for comparsion."
			});

		}

	};
	handleSelectedProducts = (event) => {
		const {loaded} = this.state;
		if (event.target.name == "select_similar_products" &&
			event.target.type === "checkbox") {
			if (event.target.checked) {
				// Pushing the object into array
				this.state.selectedId.push(event.target.value);
			} else {
				let el = this.state.selectedId.find(
					(itm) => itm === event.target.value
				);
				if (el)
					this.state.selectedId.splice(
						this.state.selectedId.indexOf(el),
						1
					);
			}
			
			this.setState({
				selectedId: this.state.selectedId,
			});
			this.props.getloaded({ loaded });
		}
	}

	handleRemoveProduct = (event) => {
		let { removeIndex } = this.state;
		if (event.target.type === "checkbox") {
			if (event.target.checked) {
				removeIndex[event.target.value] = true;
			}
			this.setState({
				removeIndex
			});
		}
	}
	 
	compareProductList =(type) => {
		//const { chartStar_Ratings } = this.props
		let { selectedId, removeIndex, loaded } = this.state;
		let comparProducts = this.state.comparProducts;
		
		// let dataItems=[];
		//  if (chartStar_Ratings && chartStar_Ratings[setGraphValue]) {
		//  	dataItems = chartStar_Ratings[setGraphValue]
		//  }
		 
		if (comparProducts && comparProducts.length > 0) {
			
            //Placing the Current product as the first index
			let currntProdIndex=comparProducts.findIndex((i)=> i.id==this.props.productId);
			let currntProd=comparProducts[currntProdIndex];
			comparProducts.splice(currntProdIndex,1);
			comparProducts.unshift(currntProd);

			return comparProducts.map((item, index) => {
				let avg_rating = item && item.total_rating && item.total_rating.avg_rating[0] ? item.total_rating.avg_rating[0] : 'norating.png';
				
				
				if (type === "productRemove") {
					return (

						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>
                            {item.id !== this.state.prodId ? <FormGroup check>
								<Label check>
									<Input type="checkbox" value={item.id} onChange={this.handleRemoveProduct} />{' '}
										remove
									</Label>
							</FormGroup>: `Current Product`}
							
						</td>
					)
				}
				if (type === "productName") {
					return (
						<td key={index} onClick={() => { this.setState({ compareProductsModalToggle: false, comparProducts: "", selectedId: item.id != this.state.prodId ? [] : selectedId }, () => this.props.getloaded({ loaded })) }} hidden={removeIndex && removeIndex[item.id] == true ? true : false}> <Link className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
							to={{
								pathname: `/${this.state.contentType}/${item.slug}`,
								state: { id: item.id }
							}}>
							{item.name ? item.name : "--"}</Link>
						</td>
					)
				}
				if (type === "productImage") {
					return (
						
						<td key={index} onClick={() => { this.setState({ compareProductsModalToggle: false, comparProducts: "", selectedId:item.id != this.state.prodId ? [] : selectedId }, () => this.props.getloaded({ loaded })) }} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>
							<div className="product-img">
								<Link className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
									to={{
										pathname: `/${this.state.contentType}/${item.slug}`,
										state: { id: item.id }
									}}>
									<img src={`${item && item.listing_profileimage && item.listing_profileimage.length > 0 && item.listing_profileimage[0] && item.listing_profileimage[0].url}`}
										onError={(error) =>
											(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
										}
										alt={`${item && item.listing_profileimage && item.listing_profileimage.length > 0 && item.listing_profileimage[0] && item.listing_profileimage[0].caption ? item.listing_profileimage[0].caption : 'no-image'}`}
									/>
								</Link>
							</div>
						</td>
					)
				}
				if (type === "productRating") {
					return (
						<td hidden={removeIndex && removeIndex[item.id] == true ? true : false} key={index}><div>
							<img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/gold/${avg_rating}`}
								className="img-fluid"
								onError={(error) =>
									(error.target.src = `../../../assets/images/norating.png`)
								}
								alt={`${item && item.total_rating && item.total_rating.avg_rating[1] ? item.total_rating.avg_rating[1] : 'no-rating'}`}
							/>
						</div>
							<span> {(item && item.total_rating && item.total_rating.total_review) ? item.total_rating.total_review : 0}  {getReviewSuffix(item.total_rating.total_review)}</span>
						</td>
					)
				}
				if (type === "price_min") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["price_min"] ? item.additional_info["price_min"][0].value : "-"}</td>

					)
				}
				if (type === "price_max") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["price_max"] ? item.additional_info["price_max"][0].value : "-"}</td>

					)
				}
				if (type === "manufacturer_cut_sheet") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["manufacturer_cut_sheet"] ? item.additional_info["manufacturer_cut_sheet"][0].value : "-"}</td>

					)
				}
				if (type === "packed_width") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["packed_width"] ? item.additional_info["packed_width"][0].value : "-"}</td>

					)
				}
				if (type === "packed_height") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["packed_height"] ? item.additional_info["packed_height"][0].value : "-"}</td>

					)
				}
				if (type === "packed_depth") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["packed_depth"] ? item.additional_info["packed_depth"][0].value : "-"}</td>

					)
				}
				if (type === "assembled_width") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["assembled_width"] ? item.additional_info["assembled_width"][0].value : "-"}</td>

					)
				}
				if (type === "assembled_height") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["assembled_height"] ? item.additional_info["assembled_height"][0].value : "-"}</td>

					)
				}
				if (type === "assembled_depth") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["assembled_depth"] ? item.additional_info["assembled_depth"][0].value : "-"}</td>

					)
				}
				if (type === "packed_weight") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["packed_weight"] ? item.additional_info["packed_weight"][0].value : "-"}</td>

					)
				}
				if (type === "assembled_weight") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["assembled_weight"] ? item.additional_info["assembled_weight"][0].value : "-"}</td>

					)
				}
				if (type === "manufacturer_warranty") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["manufacturer_warranty"] ? item.additional_info["manufacturer_warranty"][0].value : "-"}</td>

					)
				}

				if (type === "readMore") {
					return (
						<td key={index} onClick={() => { this.setState({ compareProductsModalToggle: false, comparProducts: "", selectedId: item.id != this.state.prodId ? [] : selectedId }, () => this.props.getloaded({ loaded })) }} hidden={removeIndex && removeIndex[item.id] == true ? true : false}> <Link className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
							to={{
								pathname: `/${this.state.contentType}/${item.slug}`,
								state: { id: item.id }
							}}>Read all {item?.total_rating?.total_review} Reviews</Link>
						</td>
					)
				}

				if (type === "OverallRating") {
				
					 
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}> <span> Overall Rating distributions</span>
							<div>
								{/* Chart Area */}
								<Chart
									chartType="LineChart"
									data={this.state.chartDataitems? this.state.chartDataitems[item.id] :{}}
									options={options}
									graphID={`LineChart_${item.id}`}
									width="100%"
									height="400px"
									chartEvents={chartEvents}
								/>
							</div>
						</td>
					)
				}
				if (type === "priceSpace") {
					return (
						<td key={index} hidden={removeIndex && removeIndex[item.id] == true ? true : false}>{item.additional_info && item.additional_info["List Price"] ? item.additional_info["List Price"][0].value : "-"}</td>
					)
				}



			})
		}
		
	}

	testChildFunciton = () => {

		
	}

	render() {
		const { allProducts, aboutDetails, productId, chartStar_Ratings } = this.props
		let { similarProducts, selectedId, about, loaded } = this.state;

		const responsive = {
			videoModal: {
				mobile: {
					breakpoint: { max: 9999999, min: 0 },
					items: 1
				}
			},
			viewGalleryImages: {
				desktop: {
					breakpoint: { max: 9999999, min: 1200 },
					items: 3
				},
				tablet: {
					breakpoint: { max: 1199, min: 576 },
					items: 2
				},
				mobile: {
					breakpoint: { max: 575, min: 0 },
					items: 1
				}
			}
		}	
		return (
			<div className="h-100 d-flex flex-column">
				<div className="bg-white-shaded text-dark px-2 pt-2 pb-2 bg-business-bar" style={{ marginBottom: '35px' }}>
					<Container>
						<div className="d-flex align-items-end mx-n2">
							<div className="px-2">
								<div className="topbar-main-img-holder shifted">
									<img className="img-fluid" src={`${aboutDetails && aboutDetails.listing_profileimage && aboutDetails.listing_profileimage.length > 0 && aboutDetails.listing_profileimage[0] && aboutDetails.listing_profileimage[0].url}`}
										onError={(error) =>
											(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
										}
										alt={`${aboutDetails && aboutDetails.listing_profileimage && aboutDetails.listing_profileimage.length > 0 && aboutDetails.listing_profileimage[0] && aboutDetails.listing_profileimage[0].caption ? aboutDetails.listing_profileimage[0].caption : 'no-image'}`}
									/>
								</div>
							</div>
							<div className="px-2">
								<div className="d-flex align-items-start">
									<div className="mr-2">
										<h1 className="text-dark text-uppercase fs-20">
											{aboutDetails && aboutDetails?.name}
										</h1>
									</div>
									<div className="d-flex align-items-center">
										<img className="mr-2" src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png" alt="" />
										<span className="fs-14 ff-alt">{(about && about.total_rating && about.total_rating.total_review) ? about.total_rating.total_review : 0} Review</span>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</div>
				<Container className="my-auto">
					<div className="text-center">
						<Carousel
							responsive={responsive.viewGalleryImages}
							infinite={true}
							autoPlay={false}
							swipeable={true}
							showDots={false}
							arrows={true}
							containerClass="mx-n4"
							sliderClass={`${allProducts?.results?.length === 0}` ? "mx-auto" : ""}
						>
							{(allProducts && allProducts?.results && allProducts?.results.length > 0) ? (
								allProducts?.results.filter(i => i.id != productId).map((products, i) => {
									return (
										<div className="item px-4" key={i}>
											<div className="img-holder text-center">
												<a href="#" onClick={() => this.props.getloaded({ loaded })}>
													{(products?.listing_profileimage[0]?.media_type === "image" || products?.listing_profileimage[0]?.media_type === "") ? (
														<NavLink className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
															to={{
																pathname: `/products/${products?.slug}`,
																state: { id: products?.id }
															}}><img
																className="img-thumbnail fixed-height-img is-200 full-width"
																src={`${products && products.listing_profileimage && products.listing_profileimage.length > 0 && products.listing_profileimage[0] ? products.listing_profileimage[0].url : 'https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'}`}
																
																onError={(error) =>
																	(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
																}
																alt={`${products && products.listing_profileimage && products.listing_profileimage.length > 0 && products.listing_profileimage[0] && products.listing_profileimage[0].caption ? products.listing_profileimage[0].caption : 'no-image'}`}
															/></NavLink>
													)
														: (

															<img
																className="img-thumbnail fixed-height-img is-200 full-width"
																src='https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'
																
																onError={(error) =>
																	(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
																}
																alt=""
															/>

														)

													}
													{products?.type === "video" && (
														<video
															className="img-thumbnail fixed-height-img is-200 full-width"
															controls

														>
															<source src={products?.url} type="video/mp4" />
															{"Your browser does not support the video tag."}
														</video>
													)}
												</a>
												<div className="mt-1 text-left text-white fs-14">
													<span className="ff-alt">{products.name.length > 30 ? products.name.substr(0, 30) : products.name}</span>
													<div className="mt-2">
														<FormGroup check>
															<Label className="font-weight-bold text-primary" check>
																<Input type="checkbox" name="select_similar_products" defaultChecked={selectedId.includes(products.id)} id={"selectProduct" + i} value={products.id} onChange={this.handleSelectedProducts} />{' '}
																Select
															</Label>
														</FormGroup>
													</div>
												</div>
											</div>
										</div>
									);
								})
							) : (
									<div className="item px-4">
										<div className="d-flex flex-column align-items-center justify-content-center">
											<div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
												<span>No result found</span>
											</div>
										</div>
									</div>
								)}
						</Carousel>

						<div className="text-right">
							<Button color="primary" disabled={!selectedId.length} size="lg" onClick={() => this.openCompareModal()}>Compare {!!selectedId.length && "Selected"}</Button>
						</div>
					</div>
				</Container>
				{/* Compare Products Modal Popup */}
				<Modal size="xl" isOpen={this.state.compareProductsModalToggle} toggle={() => this.setState({ compareProductsModalToggle: !this.state.compareProductsModalToggle })}>
					<ModalHeader className="px-0" toggle={() => this.setState({ compareProductsModalToggle: !this.state.compareProductsModalToggle, comparProducts:""})}>
						COMPARE
                    </ModalHeader>
					<ModalBody className="p-0 bg-transparent">
						<div className="bg-white px-3 py-2 text-dark">
							<h2 className="fs-32 mb-0">Compare Products</h2>
						</div>

						<p className="text-white fs-13 my-2">The following chart is a summary of what consumers have said in their product reviews for the products listed below. For each attribute, the consensus is shown as a percentage of agreement.</p>

						<div className="compare-product-list">
							<Table className="products-list text-center fs-14">
								<thead>
									<tr className="products-title">
										<th scope="row">Products</th>
										{this.compareProductList('productRemove')}

									</tr>
								</thead>
								<tbody>
									<tr>
										<th>Product Name</th>
										{this.compareProductList('productName')}
									</tr>
									<tr>
										<th scope="row">Product Image</th>
										{this.compareProductList('productImage')}

									</tr>
									<tr>
										<th scope="row">Star Rating</th>
										{this.compareProductList('productRating')}
									</tr>
									<tr>
										<th scope="row">Detailed Star Rating</th>
										{this.compareProductList('OverallRating')}
									</tr>
									<tr>
										<th scope="row">Price</th>
										{this.compareProductList('priceSpace')}

									</tr>
									<tr>
										<th scope="row">Minimum Price</th>
										{this.compareProductList('price_min')}

									</tr>
									<tr>
										<th scope="row">Maximum Price</th>
										{this.compareProductList('price_max')}
									</tr>
									<tr>
										<th scope="row">Manufacturer Cut Sheet</th>
										{this.compareProductList('manufacturer_cut_sheet')}
									</tr>
									<tr>
										<th scope="row">Packed width</th>
										{this.compareProductList('packed_width')}
									</tr>
									<tr>
										<th scope="row">Packed height</th>
										{this.compareProductList('packed_height')}
									</tr>
									<tr>
										<th scope="row">Packed depth</th>
										{this.compareProductList('packed_depth')}
									</tr>
									<tr>
										<th scope="row">Assembled width</th>
										{this.compareProductList('assembled_width')}
									</tr>
									<tr>
										<th scope="row">Assembled height</th>
										{this.compareProductList('assembled_height')}
									</tr>
									<tr>
										<th scope="row">Assembled depth</th>
										{this.compareProductList('assembled_depth')}
									</tr>
									<tr>
										<th scope="row">Packed weight</th>
										{this.compareProductList('packed_weight')}
									</tr>
									<tr>
										<th scope="row">Assembled weight</th>
										{this.compareProductList('assembled_weight')}
									</tr>
									<tr>
										<th scope="row">Manufacturer warranty</th>
										{this.compareProductList('manufacturer_warranty')}
									</tr>
									<tr className="products-title">
										<th scope="row">Read More</th>
										{this.compareProductList('readMore')}
									</tr>
								</tbody>
							</Table>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={this.state.compareModal} toggle={() => this.setState({ compareModal: !this.state.compareModal })}>
					<ModalHeader toggle={() => this.setState({ compareModal: !this.state.compareModal })}>wikireviews.com says</ModalHeader>
					<ModalBody>
						{this.state.message}
					</ModalBody>
					<ModalFooter>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

const mapState = (state) => ({
	fetch_compareProducts: state.products.fetch_compareProducts,

})

const mapProps = (dispatch) => ({
	fetch_compare_products: (compareId) => dispatch(fetch_compare_products(compareId)),
})

export default connect(mapState, mapProps)(compareProducts);
