/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    FacebookShareButton,
    TwitterShareButton
} from "react-share";
import { Button } from 'reactstrap';
import { add_remove_to_list, add_to_list, share_by_email, update_business_flag } from '../../../../actions/business';
import { get_item_flags } from '../../../../actions/itemDetail';
import { getCategoryName } from '../../../../utils/utilities';
import ModalComponent from '../../../atoms/modalComponent';
import ShareByEmail from '../../../atoms/shared/shareByEmail';
import HistoryLog from '../../../molecules/shared/HistoryLog/historyLog';

class SocialButtonsProfessionals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryDetails: {},
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: '',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            twitterRelated: ['WikiReviews'],
            shareByEmailModalToggle: false,
            shareId: 0,
            flags_data: [],
            showHistoryTab: false           
        }
    }

    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
        const { categoryDetails, pin_list_status, flags_data } = nextProps;

        if (categoryDetails && Object.keys(categoryDetails).length > 0) {
            this.setState({ categoryDetails });
        }

        if (flags_data && flags_data?.results) {
            this.setState({ flags_data: flags_data.results })
        }

        if (this.props.showHistoryTab) {
            this.setState({ showHistoryTab: true })
        }

        if (pin_list_status && pin_list_status.msg) {
            if (pin_list_status.msg === "Deleted Successfully") {
                return
            }
            let categoryInformation = this.state.categoryDetails;
            if (categoryInformation?.user_recommending?.pin_list_value?.length > 0) {
                categoryInformation.user_recommending.pin_list_value = []
                this.setState({categoryDetails: categoryInformation})
            }
            if (categoryInformation?.user_recommending?.recommend_list) {
                categoryInformation.user_recommending.recommend_list = 0
                this.setState({categoryDetails: categoryInformation})
            }
            
        }
    }

    componentDidMount() {
        if(this.props.categoryType==="dentist"){
            this.props.get_item_flags('dentist');
        } else if (this.props.categoryType==="doctor") {
            this.props.get_item_flags('doctor');
        } else if (this.props.categoryType==="accountant") {
            this.props.get_item_flags('accountant');
        } else if (this.props.categoryType==="lawyer") {
            this.props.get_item_flags('lawyer');
        } else if (this.props.categoryType==="actor") {
            this.props.get_item_flags('actor');
        }
    }

    handleOnClickAddToList = (type, status) => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        let list_type = type?.replace(/ /g, "_");
        if (this.state.categoryDetails?.pins_status) {
            let message = '',
                url = '',
                nextUrl = '',
                data = { type };

            let categoryDetails = this.state.categoryDetails;
            let pinList = categoryDetails?.pins_status ? categoryDetails.pins_status : {};

            if (status) {
                message = `${this.state.categoryDetails.firstname} ${this.state.categoryDetails.lastname} has been deleted from your ${type}`;
                url = `/api/professionalpins/${this.state.categoryDetails.id}/?type=${type}`;
                this.props.remove_from_list(url, message, nextUrl);
                pinList[list_type] = false;
            } else {
                data.professionaluser_entry = this.state.categoryDetails.id;
                message = `${this.state.categoryDetails.firstname} ${this.state.categoryDetails.lastname} has been added to your ${type}`;
                url = `/api/professionalpins/`;
                this.props.add_to_list(url, data, message, nextUrl);
                pinList[list_type] = true;
            }
            categoryDetails.pins_status = {...pinList};

            this.setState({
                categoryDetails
            });
        }
    }

    handleOnClickRecommend = (status) => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        if (this.state.categoryDetails?.pins_status) {
            let message = '',
                url = '',
                nextUrl = '',
                data = { type: 'recommend list' };

            let categoryDetails = this.state.categoryDetails;

            if (status) {
                message = `${this.state.categoryDetails.firstname} ${this.state.categoryDetails.lastname} has been deleted from your recommended list`;
                url = `/api/professionalpins/${this.state.categoryDetails.id}/?type=recommend list`;
                this.props.remove_from_list(url, message, nextUrl);
                categoryDetails.pins_status.recommend_list = false
            } else {
                data.professionaluser_entry = this.state.categoryDetails.id;
                message = `You recommend ${this.state.categoryDetails.firstname} ${this.state.categoryDetails.lastname}!`;
                url = `/api/professionalpins/`;
                this.props.add_to_list(url, data, message, nextUrl);
                categoryDetails.pins_status.recommend_list = true
            }

            this.setState({
                categoryDetails: {...categoryDetails}
            });
        }
    }

   

    handleOnSubmitShareByEmail = ({shareEmail, shareName, shareNote}) => {        
        let key = 'listing_id';
        const categoryType = this.props.categoryType;

        let url = `/api/professionalShareByemail/`;

        const data = {
            [key]: this.state.shareId,
            mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            listing_type: categoryType,
            optional_note: shareNote,
            professional: true
        };

        this.props.share_by_email(url, data);
    }

    modalToggler = ()=>{
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
        })
    }

    handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,            
            shareId: id
        });
    }

    handleOnClickFlag = (id, message) => {
        if (!this.checkIfLoggedIn()) {
            this.props.forceToggleLoginModal();
            return false;
        }
        const data = {
            flag_details: {
                flag: id,
                is_flagged: true,
                object_id: this.props.movieDetails.id
            },
            message: message
        };
        this.props.update_business_flag(data);
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const { my_profile } = this.props;
        if (my_profile && my_profile.id && authId && (authId == my_profile.id)) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { categoryDetails,shareByEmailModalToggle,fbHashTag,twitterHashTags,twitterRelated,historyLogModalToggle,historyCompareModalToggle, flags_data } = this.state;
        const catId = !!this.props.categoryId ? `/${this.props.categoryId}` : ""
        let url = `${this.state.protocol}//${this.state.host}/${getCategoryName(this.props.categoryType)}${catId}/${this.props.categoryDetails?.slug ? this.props.categoryDetails?.slug : ''}`;
        // let title_quote = `Reviews of ${this.props?.categoryDetails?.name ? this.props?.categoryDetails?.name : 'business'} in ${this.props?.categoryDetails?.address[0]?.city}, ${this.props?.categoryDetails?.address[0]?.state}`;
        let title = `Check out ${this.props?.categoryDetails?.firstname ? this.props?.categoryDetails?.firstname : 'this listing'} ${this.props?.categoryDetails?.firstname && this.props?.categoryDetails?.lastname ? this.props?.categoryDetails?.lastname : ''} on`

        const inRecommendList = categoryDetails?.pins_status?.recommend_list;
        const inWantList = categoryDetails?.pins_status?.want_list;
        const inWatchList = categoryDetails?.pins_status?.watch_list;
        const inFavouriteList = categoryDetails?.pins_status?.favourite_list;
        const pinActive = inWantList || inWatchList || inFavouriteList;
       
        return (
            <div className="wk-mv-share">
                {/* <Helmet>
                    <meta property="og:title" content="European Travel Destinations"/>
                    <meta property="og:description" content="Offering tour packages for individuals or groups."/>
                    <meta property="og:image" content="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/1594323905763.b61278a7aaab346f8337a4cbbeded879146441a5.jpg"/>
                    <meta property="og:url" content={window.location.href}/>
                </Helmet> */}
                {/* <MetaTags>
                    <title>Page 1</title>
                    <meta name="description" content="Some description." />
                    <meta property="og:title" content="MyApp" />
                    <meta property="og:image" content="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/1594323905763.b61278a7aaab346f8337a4cbbeded879146441a5.jpg" />
                </MetaTags> */}
                <ul className="justify-content-center">  
                    {this.state.showHistoryTab && <li>
                        <a className="text-white" href="javascript:void(0)" onClick={() => this.setState({ historyLogModalToggle: true })}>
                            <FontAwesomeIcon icon="clipboard-list" />
                        </a>
                    </li>}                  
                    
                    <li className="share">
                        <a href="javascript:void(0)">{''}</a>
                        <ul className="dropdownbar">
                            <li>
                                <a href="javascript:void(0)">
                                    <FacebookShareButton
                                        url={url}
                                        quote={title}
                                        hashtag={fbHashTag}>
                                        {'Facebook'}
                                    </FacebookShareButton>
                                </a>
                            </li>
                            {/* <li>
                                <a href="#">Google+</a>
                            </li> */}
                            <li>
                                <a href="javascript:void(0)">
                                <TwitterShareButton
                                        url={url}
                                        title={title}
                                        via='WikiReviews'
                                        related={twitterRelated}
                                        // hashtags={twitterHashTags}
                                        >
                                        {'Twitter'}
                                    </TwitterShareButton>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={() => this.handleOnClickShareByEmail(this.props.categoryDetails?.id)}>By email</a>
                            </li>
                        </ul>
                    </li>
                    <li className={`like`} onClick={() => this.handleOnClickRecommend(inRecommendList)}>
                        <a href="javascript:void(0)" className={`${inRecommendList ? 'active' : ''}`}></a>
                    </li>
                    <li className={`pin`}>
                        <a href="javascript:void(0)" className={`${(pinActive) ? 'active' : ''}`}></a>
                        <ul className="dropdownbar">
                            {this.props.hideWant ? '' : 
                            <li onClick={() => this.handleOnClickAddToList('want list', inWantList)}>
                                <a href="javascript:void(0)"><img alt="" src={require('../../../../assets/images/icons/want-list.png')} /> {inWantList ? 'Added' : 'Add'} to want list</a>
                            </li>
                            }
                            <li onClick={() => this.handleOnClickAddToList('favourite list', inFavouriteList)}>
                                <a href="javascript:void(0)"><img alt="" src={require('../../../../assets/images/icons/favoritelist.png')} /> {inFavouriteList ? 'Added' : 'Add'} to favorites</a>
                            </li>
                            <li onClick={() => this.handleOnClickAddToList('watch list', inWatchList)}>
                                <a href="javascript:void(0)"><img alt="" src={require('../../../../assets/images/icons/watchlist.png')} /> {inWatchList ? 'Added' : 'Add'} to watch list</a>
                            </li>
                        </ul>
                    </li>
                    <li className="flag">
                        <a href="javascript:void(0)">{''}</a>
                        <ul className="dropdownbar dropdownbar-right">
                            {flags_data?.length > 0 && flags_data.map(flag => {
                                return (
                                    <li key={flag.id} onClick={() => this.handleOnClickFlag(flag.id, flag.label)}>
                                        <a className='cursor-pointer'>{flag.label}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </li>
                </ul>

                {/* Share By Email Modal Popup */}
               
                <ModalComponent
                    size="sm"
                    isScrollable={true}
                    isOpen={shareByEmailModalToggle}
                    toggleHandler={() =>
                        this.setState({
                            shareByEmailModalToggle: !shareByEmailModalToggle,
                        })}
                    header={"Share by Email"}
                    headerClass="text-uppercase px-0"                    
                    body={<ShareByEmail handleOnSubmitShareByEmail={(value)=>this.handleOnSubmitShareByEmail(value)} modalToggler={this.modalToggler}/>}
                />               

                <ModalComponent
                    size={"lg"}
                    isScrollable={true}
                    isOpen={historyLogModalToggle}
                    toggleHandler={() => this.setState({ historyLogModalToggle: !historyLogModalToggle })}
                    header={"History Log"}
                    bodyClass={"history-box"}
                    body={<HistoryLog historyCompareModalToggle={historyCompareModalToggle} compareToggler={() => this.setState({ historyCompareModalToggle: false})}/>}
                    footer={!historyCompareModalToggle && <Button color="primary" onClick={() => this.setState({ historyCompareModalToggle: true })}>Compare</Button>}
                />
            </div>
        );
    }
}

const mapState = (state) => ({
        flags_data: state.item_detail.flags_data,
        my_profile: state.user.my_profile,
        pin_list_status: state.business.pin_list_status,
    })



const mapProps = (dispatch) => ({
    get_item_flags: (type) => dispatch(get_item_flags(type)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(add_remove_to_list(url, message, nextUrl)),
    update_business_flag: (data) => dispatch(update_business_flag(data)),
});

export default connect(mapState, mapProps)(SocialButtonsProfessionals);
