import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";

import {
  upload_media,
  initFetchMovieSubCategories,
  search_moviesMembers,
  get_patrol_crp,
  submit_patrol_review

} from "../../../actions/footer";

class PatrolMovieMediaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      loaded: true,
      drawer: true,
      patrolMedia_list: [],
      reasonError: "",
      category_id: '',
      subcategory_id: '',
      typeMethod: "",
      decision: "",
      reason: "",
      rateType: "",
      type: "media",
      listingType: "movies",
      about: null
    };
  }
  redirectto = (movieId, slug) => {
    let path = "movies";
    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: movieId }
    })
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.fetch_save_movie_response) {
      if (nextProps.fetch_save_movie_response && nextProps.fetch_save_movie_response.id && nextProps.fetch_save_movie_response.slug) {
        let slug = null;
        let movieId = "";
        if (nextProps.fetch_save_movie_response.id != "") {
          movieId = nextProps.fetch_save_movie_response.id;
        }
        if (nextProps.fetch_save_movie_response.slug != "") {
          slug = nextProps.fetch_save_movie_response.slug;
        }
        this.redirectto(movieId, slug);

      }
    }

    if (nextProps.ratingType && nextProps.ratingType != "") {
      this.setState({ rateType: nextProps.ratingType })
    }

    if (nextProps.patrolMedia && nextProps.patrolMedia.results && Array.isArray(nextProps.patrolMedia.results) && nextProps.patrolMedia.results.length > 0) {
      this.setState({ patrolMedia_list: nextProps.patrolMedia.results }, () => {
      });
    }

  }

  componentDidMount = () => {
    let date = new Date()
    let year = date.getFullYear()

  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let { rateType } = this.state;
    this.setState({
      [name]: value
    });
    if (name == "category_id") {
      let type = "category_id";
      this.setState({
        categoryError: "",
        category_id: e.target.value,
        patrolMedia_list: [],
        about: "",
        loaded: true
      }, () => {
        let id = this.state.category_id;
        let typeCore = "media";
        let listingType = "movies";
        this.props.get_patrol_crp(id, typeCore, listingType);
      });
      this.props.initFetchMovieSubCategories(e.target.value, type);
    }
  }

  handleSkip = () => {
    let { patrolMedia_list } = this.state;
    if (patrolMedia_list && Array.isArray(patrolMedia_list) && patrolMedia_list.length > 0) {
      patrolMedia_list.shift();
      this.setState({ patrolMedia_list, about: "" }, () => {
      });
    }
  }
  handleChangeMedia = (e) => {
    if (e.target.name === 'reason') {
      this.setState({ reason: e.target.value, reasonError: "" })
    }
    if (e.target.name === 'Approved') {
      this.setState({ decision: e.target.value })
    }
    if (e.target.name === 'Rejected') {
      this.setState({ decision: e.target.value })
    }
  };


  handleMovieMediaSubmit = () => {
    let { reason, decision, patrolMedia_list } = this.state
    if (reason == "") {
      this.setState({
        reasonError:
          "This field is required"
      });
    } else {
      if (patrolMedia_list && Array.isArray(patrolMedia_list) && patrolMedia_list.length > 0) {
        let params = {}
        params.flag_details = {}
        params.flag_details.type = "multiuploaderfile";
        params.flag_details.decision = decision;
        params.flag_details.reason = reason;
        params.flag_details.object_id = patrolMedia_list && patrolMedia_list[0] ? patrolMedia_list[0].id : "";
        params.flag_details.is_patroled = true;
        this.props.submit_patrol_review(params);
        this.handleSkip()
      }

    }
  }

  render() {
    const {
      openModal,
      togglePatrolMovieMediaModal,
      movies,
    } = this.props;
    const { category_id, patrolMedia_list, reason } = this.state;
    return (
      <>
        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={openModal}
          toggle={togglePatrolMovieMediaModal}
        >
          <form
            className="d-flex flex-column h-100"
          >
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button color="primary" onClick={togglePatrolMovieMediaModal}>
                    <FontAwesomeIcon icon="angle-left" />
                        back to listing
                      </Button>
                </div>
                <div>
                  <div className="bg-darker p-3 text-tertiary">
                    <div className="mb-2 text-primary font-weight-bold">
                      You’re Patrolling for Media
                        </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input
                              type="select"
                              className="transparent"
                              size="sm"
                            >
                              <option>{movies?.category}</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </li>
                      <li className="px-2">
                        <div className="d-flex">
                          <div className="mr-1">
                            <span className="text-danger">*</span>
                          </div>
                          <FormGroup>
                            <Input
                              id="category_id"
                              name="category_id"
                              onChange={this.handleChange}
                              type="select"
                              className="transparent"
                              size="sm"
                              selected={category_id}
                            >
                              <option value="">Select Category</option>
                              {movies?.children?.map((movie) => {
                                return (
                                  <option value={movie.id} key={movie.id}>
                                    {movie.category}
                                  </option>
                                );
                              })}
                            </Input>
                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.categoryError}</span>
                          </FormGroup>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ModalBody className="bg-white text-dark">
              <div className="wk-crpmodal">
                <Row>
                  <Col md="6">
                    <div className=" font-weight-bold mb-2 text-primary mb-0">Step 1. New Media</div>
                    <p className="text-dark font-weight-bold">Review new media below.</p>
                    <p>Media greatly enhance a Listing, Project or Review. High quality media let viewers see for themselves, allowing for a better understanding of the person, place, event, or object pictured. Entries should abide by our community guidelines. No pornography or copyrighted material is permitted. Patrolling each new user contribution is done to ensure that new info is accurate and spam-free.</p>
                    <div className="bg-grey p-4 mb-4">
                      <p>Suggested New Image to Listing</p>
                      {patrolMedia_list && Array.isArray(patrolMedia_list) && patrolMedia_list.length > 0 && patrolMedia_list[0] ?
                        <>
                          {patrolMedia_list[0].media && patrolMedia_list[0].media.media_type && patrolMedia_list[0].media.media_type == "image" && (
                            <img src={`${patrolMedia_list[0].media.file}`} className="img-fluid w-100" alt="image" />
                          )}
                          {patrolMedia_list[0].media && patrolMedia_list[0].media.media_type && patrolMedia_list[0].media.media_type == "video" && (
                            <video width="100%" controls>
                              <source src={`${patrolMedia_list[0].media.file}`} type="video/mp4" />
							Your browser does not support the video tag.
                            </video>
                          )}
                        </>
                        : ""}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className=" font-weight-bold mb-2 text-primary">Step 2. Approve or Select Flag</div>
                    <div className="wk-approve-petrol-radio">
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" name="Approved" value="Approved" checked={this.state.decision === "Approved"} onChange={this.handleChangeMedia} />Approved</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" name="Rejected" value="Rejected" checked={this.state.decision === "Rejected"} onChange={this.handleChangeMedia} />Rejected</label>
                    </div>
                    <textarea className="form-control wk-crpmodal-quick-note wk-crpmodal-quick-note2  w-100" placeholder="Tell us why?" onChange={this.handleChangeMedia}
                      value={reason} name="reason" placeholder="Tell us why?" ></textarea>
                    <span style={{ color: 'red', fontSize: "13px" }}>{this.state.reasonError}</span>
                    <div className="text-right mt-3">
                      <Button className="ml-0" color="primary" size="sm" onClick={this.handleMovieMediaSubmit}>
                        Submit
                      </Button>
                      <a href="javascript:void(0)" className="ml-4"
                        onClick={() => {
                          this.handleSkip();
                        }}>Skip ></a>
                    </div>
                  </Col>
                </Row>
              </div>

            </ModalBody>
          </form>
        </Modal>



      </>


    );



  }
}

const mapState = (state) => ({
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  fetch_save_movie_response: state.movies.fetch_save_movie_response,
  patrolMedia: state.footer.patrolMedia,
});

const mapProps = (dispatch) => ({
  submit_patrol_review: (data) => dispatch(submit_patrol_review(data)),
  initFetchMovieSubCategories: (categoryId, type) => dispatch(initFetchMovieSubCategories(categoryId, type)),
  search_moviesMembers: (value, type) => dispatch(search_moviesMembers(value, type)),
  get_patrol_crp: (categoryId, type, listType) => dispatch(get_patrol_crp(categoryId, type, listType)),
});
export default withRouter(connect(mapState, mapProps)(PatrolMovieMediaModal));