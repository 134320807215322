
const initialState = {
    friends_profile_recommend: null,
    friends_profile_want: null,
    friends_profile_favourite: null,
    ratingCreated: null,
    editExistingMedia: [],
    editExistingEmbedMedia: [],
    isEditReviewEnabled: false,
    isFollowReviewEnable:false,
    isProductWallDataLoading:false,
    uploadPercentage:0,
    loginPrompt:false,
    loginPromptSignUp:false,
    loginPromptLogin:false
};

export const GET_WALL_DATA = "GET_WALL_DATA";

const shared = (state = initialState, action) => {

    switch (action.type) {
        case "GET_FRIENDS_PROFILE":
            if (action.profileType === 'recommend') {
                state.friends_profile_recommend = action.payload;
            } else if (action.profileType === 'want') {
                state.friends_profile_want = action.payload;
            } else if (action.profileType === 'favourite') {
                state.friends_profile_favourite = action.payload;
            }
            return { ...state };

        case GET_WALL_DATA:
            state.wallData = action.payload;
            return { ...state };
        case "RATING_CREATED":
            state.ratingCreated = action.payload
            return { ...state };
        case "EDIT_EXISTING_MEDIA":
            state.editExistingMedia = action.payload
            return { ...state };
        case "EDIT_EXISTING_EMBED_MEDIA":
            state.editExistingEmbedMedia = action.payload
            return { ...state };
        case "IS_EDIT_ENABLE":
            state.isEditReviewEnabled = action.payload
            return { ...state };
        case "IS_EDITED_REVIEW_ID":
            state.isEditedReviewId = action.payload
            return { ...state };
        case "FOLLOW_REVIEW":
            state.isFollowReviewEnable = action.payload
            return { ...state };
        case "WALL_DATA_LOADING":
            state.isProductWallDataLoading = action.payload
            return { ...state };
        case "UPLOAD_PERCENTAGE":
            state.uploadPercentage = action.payload
            return { ...state };
        case "SET_LOGIN_PROMPT":
            state.loginPrompt = action.payload
            return { ...state };
        case "SET_LOGIN_PROMPT_SIGNUP":
            state.loginPromptSignUp = action.payload
            return { ...state };
        case "SET_LOGIN_PROMPT_LOGIN":
            state.loginPromptLogin = action.payload
            return { ...state };
            
            
        default:
            return state;
    }
}

export default shared;
