import React, { Component } from "react";
import {
    Modal,
    ModalBody,
    UncontrolledTooltip
} from "reactstrap";
import Carousel from 'react-multi-carousel';
import Embed from "react-embed";
import Markdown from "react-markdown";

const responsive = {
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1,
        },
    },
    viewGalleryImages: {
        desktop: {
            breakpoint: { max: 9999999, min: 1200 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1199, min: 576 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1,
        },
    },
};

export default class ReviewMediaSet extends Component {
    constructor(props) {
        super(props)
        this.Carousel = null;
        this.state = {
            viewMyPhotosModalnew: false,
            imageAttacmentnew: ""
        }
    }

    handleOpenModal(data, index) {
        this.setState({
            viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew,
            imageAttacmentnew: data,
            currentActiveIndex: index
        });
    }

    renderLinksWrapper = ({ body, index }) => {
        let expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
        let itemsGet = body?.url.match(expression);    
        if(itemsGet!==null){
            if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
                itemsGet = [...new Set(itemsGet)];          
                if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
                  return itemsGet.map((item, index) => (
                    <div className="outer-link" key={index}>                       
                        {item.includes("youtube") ?  <Embed url={item} /> : <img
                              className="img-fluid img-thumbnail h-100"
                              src={item.thumbnail || require("../../../assets/images/embedded_link_img.png")}
                              // id={`embedded_link-${uploaded_index}`}
                              alt={item.url}
                          />}              
                          
                      {/* <ComponentNew embedData={item} /> */}
                    </div>
                  ));
                }
              }
        }else{
            return <img
            className="img-fluid img-thumbnail h-100"
            src={body.thumbnail || require("../../../assets/images/embedded_link_img.png")}
            id={`embedded_link-${index}`}
            alt={body.url}
            style={{width:"100%"}}
        />
        }        
      };

    render() {
        const { item, truncate, } = this.props;
        let { imageAttacmentnew } = this.state;

        return (
            <React.Fragment>
                {/* View Media Modal */}
                <Modal
                    centered
                    isOpen={this.state.viewMyPhotosModalnew}
                    toggle={() => {
                        this.setState({ viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew }, () => {
                            if (!this.state.viewMyPhotosModalnew) {
                                clearTimeout(this.setTimeOutSlider);
                            }
                        })
                    }}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            ref={(el) => (this.Carousel = el)}
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            sliderClass={`${imageAttacmentnew?.length === 0}` ? "mx-auto" : ""}>
                            {imageAttacmentnew.length > 0 ? (
                                imageAttacmentnew.map((med, i) => {
                                    return (
                                        <div className="item fs-14 h-100 d-flex flex-column justify-content-center"
                                            key={i}>
                                            <div>
                                                <div className="d-flex text-white justify-content-between mt-3">
                                                    <div className="mr-2 ff-alt">{med?.caption}</div>
                                                    <div className="ff-base">{med?.label}</div>
                                                </div>
                                            </div>
                                            <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                                {(med?.type === "image" || med?.type === "") && (
                                                    <img
                                                        className="img-fluid shown-media"
                                                        src={med?.url}
                                                        alt=""
                                                    />
                                                )}
                                                {med?.type === "video" && (
                                                    <video className="img-fluid shown-media" controls>
                                                        <source src={med?.url} type="video/mp4" />
                                                        {'Your browser does not support the video tag.'}
                                                    </video>
                                                )}
                                            </div>
                                            <div className="px-2 mt-3">
                                                <div className="d-flex flex-nowrap mx-n2">
                                                    <div className="px-2 col d-flex flex-nowrap">
                                                        <div className="mr-2">
                                                            <img
                                                                className="img-circle _30x30"
                                                                src={med?.user?.current_profile_file
                                                                    ? med?.user?.current_profile_file
                                                                    : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className="text-primary ff-base">
                                                                {` Uploaded by `}
                                                                <a className="text-reset" href="#">
                                                                    <span className="font-weight-bold">
                                                                        {med?.user?.user?.first_name}
                                                                    </span>{" "}
                                                                </a>
                                                            </div>
                                                            <div className="text-tertiary ff-base">
                                                                {med?.upload_date}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-2 col-auto">
                                                        <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                                                            {i + 1} of {imageAttacmentnew.length}
                                                        </h2>
                                                    </div>
                                                    <div className="px-2 col">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (<div className="item px-4">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                        <span>No result found for this category</span>
                                    </div>
                                </div>
                            </div>
                                )}
                        </Carousel>
                        {this.state.viewMyPhotosModalnew && (
                            this.setTimeOutSlider = setTimeout(() => {
                                if (this?.Carousel?.goToSlide) {
                                    let index = this.state.currentActiveIndex;
                                    this.Carousel.goToSlide(index + 2, true);
                                }
                            }, 100)
                        )}
                    </ModalBody>
                </Modal>

                <div className="my-5">
                    {item.reviewmedia_set?.length > 0 ? (
                        <div >
                            {item.reviewmedia_set.slice(0, 1).map((reviewmedia, index) => (
                                <div key={index}>
                                    {reviewmedia.type === "image" && (
                                        <div className="mb-3" style={{ maxWidth: "450px" }}>
                                            <img
                                                src={reviewmedia.url}
                                                alt={reviewmedia.filename?.length < 20 ? reviewmedia.filename : truncate(reviewmedia.filename || '')}
                                                className="img-fluid"
                                            />
                                        </div>
                                    )}
                                    {reviewmedia.type === "video" && (
                                        <video width="320" height="240" controls>
                                            <source
                                                src={reviewmedia.url}
                                                type="video/mp4"
                                            />
                                            {'Your browser does not support the video tag.'}
                                        </video>
                                    )}
                                </div>
                            ))}
                            <div className="mb-3">
                                {/* List of Photos uploaded */}
                                <ul className="list-unstyled d-flex flex-wrap mx-n1 mb-2" style={{ maxHeight: '280px', overflowY: 'auto' }}>
                                    {item?.reviewmedia_set && item?.reviewmedia_set?.length > 1 ? (
                                        item.reviewmedia_set?.slice(1)?.map((reviewmediass, index1) => {
                                            return (
                                                <li key={index1}
                                                    onClick={() => this.setState({
                                                        viewMyPhotosModal: !this.state.viewMyPhotosModal
                                                    })}
                                                    className="p-1"
                                                    role="button">
                                                    {reviewmediass?.type == "image" ?
                                                        <img
                                                            className="img-thumbnail uploaded-img-thumbnail"
                                                            src={reviewmediass?.location}
                                                            alt={reviewmediass?.filename?.length < 20
                                                                ? reviewmediass?.filename
                                                                : truncate(reviewmediass?.filename)}
                                                            onClick={() => {
                                                                this.handleOpenModal(item?.reviewmedia_set, index1)
                                                            }}
                                                        />
                                                        :
                                                        <div>
                                                        {/* <div className="video-holder">
                                                             <img
                                                                className="img-thumbnail uploaded-img-thumbnail"
                                                                src={reviewmediass?.url}
                                                                alt={reviewmediass?.filename?.length < 20
                                                                    ? reviewmediass?.filename
                                                                    : truncate(reviewmediass?.filename || '')}
                                                            /> */}
                                                            <video width="125" height="105" controls>
                                                                <source
                                                                    src={reviewmediass.url}
                                                                    type="video/mp4"
                                                                />
                                                                {'Your browser does not support the video tag.'}
                                                            </video>
                                                        </div>
                                                    }
                                                </li>
                                            );
                                        })) : ("")}
                                </ul>
                            </div>
                        </div>
                    ) : ("")}                 
                    
                    {Array.isArray(item?.embeded_video?.body) &&  !!item?.embeded_video?.body?.length && <div>
                        <div className="mb-2"><span >Embedded Media</span></div>
                        <div style={{display:"flex",width:'100%',flexWrap:'wrap'}}>
                        {(JSON.parse(JSON.stringify(item?.embeded_video?.body)))?.map((link, index) => {
                            return <div className="gallery-media2 mr-2 mb-2" style={{width:"48%"}}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer" download="Image">                                    
                                    {this.renderLinksWrapper({ body: link, index })}                                    
                                </a>
                            </div>
                        })}
                        </div>
                    </div>
                    }
                    {Array.isArray(item?.prof_embeded_video?.body) && !!item?.prof_embeded_video?.body?.length && <div>
                        <div className="mb-2"><span >Embedded Media</span></div>
                        <div style={{display:"flex",width:'100%',flexWrap:'wrap'}}>
                        {(JSON.parse(JSON.stringify(item?.prof_embeded_video?.body)))?.map((link, index) => {
                           return <div className="gallery-media2 mr-2 mb-2" style={{width:"48%"}}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer" download="Image">                                     
                                    {this.renderLinksWrapper({ body: link, index })}
                                </a>
                            </div>
                        })}
                        </div>
                    </div>
                    }
                    {Array.isArray(item?.child_embed_data?.body) && !!item?.child_embed_data?.body?.length && <div>
                        <div className="mb-2"><span >Embedded Media</span></div>
                        <div style={{display:"flex",width:'100%',flexWrap:'wrap'}}>
                        {(JSON.parse(JSON.stringify(item?.child_embed_data?.body)))?.map((link, index) => {
                            return <div className="gallery-media2 mr-2 mb-2" style={{width:"48%"}}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer" download="Image">                                  
                                   
                                    {this.renderLinksWrapper({ body: link, index })}
                                </a>
                            </div>
                        })}
                        </div>
                    </div>
                    }
                </div>

            </React.Fragment>
        )
    }
}
