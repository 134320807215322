/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from "../oragnisms/dashboard/appHeader";
import { connect } from "react-redux";
import { Container, Row, Col, Nav, NavItem, NavLink, FormGroup, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert, } from 'reactstrap';
import CollageItems from '../oragnisms/home/CollageItems';
import UserFeedsPanel from '../atoms/userFeedsRightPanel';
import {
    fetch_professionals_list,
    fetch_products_newtrends,
    fetch_filtered_professionals
} from '../../actions/productsListing';
import { add_to_list, remove_from_list, share_by_email, } from '../../actions/business';
import { set_previous_tab } from '../../actions/professionals';
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import CreateReview from '../../component/atoms/createReview';
import UploadMedia from '../atoms/fileUploadMedia';
import { get_uploaded_media } from '../../actions/itemDetail';
import { capitalizeFirstLetter, detectlink, getShareReviewURL } from '../../utils/utilities';
import ModalComponent from '../atoms/modalComponent';
import ShareByEmail from '../atoms/shared/shareByEmail';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';
import { getSubCategoryDescription, getSubCategoryTitle } from '../../utils/seo-utilities';
class Professionals extends Component {
    constructor(props) {
        super(props)
        this.enableScrollTillEnd = true;
        this.state = {
            currentPageIndex: 1,
            checkNextPagination: false,
            myConnectionActivity: 'all',
            viewEditReviewStep: 'writeReview',
            professionals_list: [],
            tabType: 'doctors',
            homeDropdownOpen: {},
            homeShareDropdownOpen: {},
            category: '',
            data_list: {},
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: '',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
            professionalImage: "https://userdatawikireviews.s3.amazonaws.com/images/doctor-neutral-472x446.jpg",
            toggleItemCreateReview: {},
            toggleItemReadMore: {},
            uploadedMediaSet: {
                embedLinks: {},
                uploadedFiles: [],
            },
            isEnableFileUpload: false,
            this_user: null,
            loadingLists: false,
            starRatingFilterEnabled: false,
            starRatingFilterValue: null,
        }
        this.onMouseEnterCategory = this.onMouseEnterCategory.bind(this);
        this.onMouseLeaveCategory = this.onMouseLeaveCategory.bind(this);
        this.onMouseShareEnterCategory = this.onMouseShareEnterCategory.bind(this);
        this.onMouseShareLeaveCategory = this.onMouseShareLeaveCategory.bind(this);
    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.this_user) {
            this.setState({this_user: nextProps?.this_user?.user?.id})
        }

        if (nextProps?.professionals_list?.results && this.state.professionals_list?.length) {
            let professionals_list = this.state.professionals_list;
            professionals_list = professionals_list.concat(nextProps.professionals_list?.results);
            this.enableScrollTillEnd = true;
            this.setState({
                professionals_list,
                checkNextPagination: nextProps.professionals_list?.next
            });
        } else {
            if(!nextProps.isLoading){
                this.setState({
                    professionals_list: nextProps?.professionals_list?.results,
                    checkNextPagination: nextProps.professionals_list?.next
                });
            }
           
        }
		if(nextProps.get_prious_tab){
			this.setState({tabType:nextProps.get_prious_tab})
		}

        if("isLoading" in nextProps){
			this.setState({loadingLists:nextProps.isLoading})
        }
    }

    componentDidMount = () => {
		const {get_prious_tab} = this.props;
		if(get_prious_tab){
			this.setState({tabType:get_prious_tab});
			this.props.fetch_professionals_list({ content_type__category: get_prious_tab, page: this.state.currentPageIndex });
		}else{
            this.props.fetch_professionals_list({ content_type__category: 'doctors', page: this.state.currentPageIndex });
        }
		// window.scrollTo(0, 0)
        // window.addEventListener('scroll', this.handleScroll, { passive: false });
        var options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0,
          };
      
          this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            options
          );
          this.observer.observe(this.loadingRef);
          window.addEventListener('scroll', this.handleScroll, { passive: false });
		// document.title = `Professionals Reviews on WikiReviews - Doctors, Dentists, Attorneys, and More..`;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTillHeight = window.pageYOffset;
        let lastElemHeight = document.getElementById("last-index")?.offsetTop;
        const { currentPageIndex, checkNextPagination, starRatingFilterEnabled, starRatingFilterValue, loadingLists } = this.state;
        if (scrollTillHeight > lastElemHeight && checkNextPagination && !loadingLists) {
            if (this.enableScrollTillEnd) {
                this.enableScrollTillEnd = false;
                const tempCurrentPageIndex = currentPageIndex + 1;
                this.setState({ currentPageIndex: tempCurrentPageIndex }, () => {
                    if (starRatingFilterEnabled) {
                        const queryParams = {
                            content_type: 'professionals',
                            stars: starRatingFilterValue,
                            cat: capitalizeFirstLetter(this.state.tabType),
                            subcat: '',
                            to_api: true,
                            showReviews: true,
                            location: false,
                            page: tempCurrentPageIndex,
                            rating_filter: true
                        }
                        this.props.fetch_filtered_professionals(queryParams);
                    }
                    else {
                        this.props.fetch_professionals_list({
                            content_type__category: this.props.tabType,
                            page: tempCurrentPageIndex
                        });
                    }

                });
            }
        }
    }

    handleChangeTab = (tab) => {
        const { starRatingFilterEnabled, starRatingFilterValue } = this.state;
        const professionalImage = {
            doctors: "https://userdatawikireviews.s3.amazonaws.com/images/doctor-neutral-472x446.jpg",
            dentists: "https://userdatawikireviews.s3.amazonaws.com/images/dentist-neutral-472x446.jpg",
            lawyers: "https://userdatawikireviews.s3.amazonaws.com/images/lawyer-neutral-02-472x446.jpg",
            accountants: "https://userdatawikireviews.s3.amazonaws.com/images/accountants_neutral_turquoise.jpg",
        }

        this.setState({
            professionals_list: [],
            currentPageIndex: 1,
            tabType: tab,
            professionalImage: professionalImage?.[tab],
            
        }, () => {
            if (starRatingFilterEnabled) {
                const queryParams = {
                    content_type: 'professionals',
                    stars: starRatingFilterValue,
                    cat: capitalizeFirstLetter(tab),
                    subcat: '',
                    to_api: true,
                    showReviews: true,
                    location: false,
                    page: 1,
                    rating_filter: true
                }
                this.props.fetch_filtered_professionals(queryParams);
            } else {
                this.props.fetch_professionals_list({ content_type__category: tab, page: 1 });
            }

            this.props.set_previous_tab(tab)
        });
        
    }


    handleStarFilter = (star) => {
        this.setState({
            moviesList: [], 
            professionals_list: [], 
            starRatingFilterEnabled: true,
            starRatingFilterValue: star,
            currentPageIndex: 1
        }, () => {
            this.props.fetch_filtered_professionals({ content_type: 'professionals', stars: star, cat: capitalizeFirstLetter(this.state.tabType), subcat: '', to_api: true, showReviews: true, location: false, page: 1, rating_filter: true });
            window.scrollTo(0, 0);
        })
        
    }

    toggleCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = !expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseEnterCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseLeaveCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    toggleShareCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = !expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }

    onMouseShareEnterCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }

    onMouseShareLeaveCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }


    handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id
        });
    }

    handleOnClickAddToList = (type, status, obj) => {
        if (this.state.professionals_list?.results || this.state.professionals_list) {

            let { professionals_list: data_list } = this.state;
            let message = '',
                url = '',
                nextUrl = '',
                data = { type };

            let itemDetails = obj;
			
            let pinList = itemDetails?.user_recommending?.pin_list_value ? [...itemDetails?.user_recommending?.pin_list_value] : [];

            if (status) {
                message = `${itemDetails.name} has been deleted from your ${type}`;
                url = `/api/professionalpins/${itemDetails.id}/?type=${type}`;
                this.props.remove_from_list(url, message, nextUrl);
                pinList = pinList.filter(item => {
                    return item !== type.split(' ').join('_');
                });
            } else {
                data.professionaluser_entry = itemDetails.id;
                message = `${itemDetails.name} has been added to your ${type}`;
                url = `/api/professionalpins/`;
				
                this.props.add_to_list(url, data, message, nextUrl);
                pinList.push(type.split(' ').join('_'));
            }
            itemDetails.user_recommending = {
                pin_list_value: pinList
            };
            if (data_list?.results) {
                data_list.results = data_list.results.map(item => {
                    //if (item.listing_id === obj.listing_id) {
                    if (item.id === obj.id) {
                        return itemDetails;
                    }
                    return item;
                });
            } else {
                data_list = data_list.map(item => {
                    //if (item.listing_id === obj.listing_id) {
                    if (item.id === obj.id) {
                        return itemDetails;
                    }
                    return item;
                });

            }

            this.setState({
                professionals_list: data_list
            });
        }
        // let message = '',
        //     url = '',
        //     nextUrl = '',
        //     key = '',
        //     data = { type };

        // let professionals_list = this.state.professionals_list;

        // if (type.includes('want')) {
        //     key = 'want_list';
        // } else if (type.includes('favourite')) {
        //     key = 'favourite_list';
        // } else {
        //     key = 'watch_list';
        // }

        // if (status) {
        //     message = `${item.firstname} has been removed from your ${type}!`;
        //     url = `/api/professionalpins/?id=${item.listing_id}&type=${type}`;
        //     this.props.remove_from_list(url, message, nextUrl);
        //     item.pins_status[key] = false;
        // } else {
        //     data.professionaluser_entry = item.listing_id;
        //     message = `${item.firstname} has been added to your ${type}!`;
        //    url = `/api/professionalpins/`;
        //     this.props.add_to_list(url, data, message, nextUrl);
        //     item.pins_status[key] = true;
        // }
        // professionals_list = professionals_list.map(itemValue => {
        //         if (itemValue.listing_id === item.listing_id) {
        //             return item;
        //         }
        //         return itemValue;
        //     });	

        // this.setState({
        //     professionals_list
        // });
    }


    toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
    }

    onPublishReview = () => {
        this.setState({
            toggleItemCreateReview: {},
            toggleItemReadMore: {},
            professionals_list: []
        }, () => {
            this.props.fetch_professionals_list({ content_type__category: this.state.tabType, page: this.state.currentPageIndex });
        })
    }

    closeAfterCancel = () => {
        this.setState({ toggleItemCreateReview: {} })
    }

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet } = this.state;
        let uploadedMediaSetCopy = { ...uploadedMediaSet }
        const key = 'id'
        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
            let mediaSetStored = uploadedMediaSetCopy?.uploadedFiles?.concat(mediaSet)
            const updatedMedia =[...this.props.editExistingMedia,...mediaSetStored]
            const mediaSetUnique = [...new Map(updatedMedia?.map(item =>
                [item[key], item])).values()];            
            this.setState(prevState => ({
                uploadedMediaSet: {
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:  mediaSetUnique
                   }
            }), () => {
                this.props.get_uploaded_media(uploadedMediaSet);                
            })
        }
        if (links && Array.isArray(links) && links.length > 0) {
            let linksStored = uploadedMediaSetCopy?.embedLinks?.concat(links)            
            this.setState(prevState => ({
                uploadedMediaSet: {
                    ...prevState.uploadedMediaSet,
                    embedLinks: linksStored
                }
            }), () => {
                this.props.get_uploaded_media(uploadedMediaSet);
            })
        }
    }

    handleOnClickUploadModalOk = () => {
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }

    getName = (item) =>{
        if(!!item?.name){
            return item?.name
        }else{
            return item?.firstname + item?.lastname
        }
    }

    handleObserver(entities, observer) {
        const y = entities[0].boundingClientRect.y;
        let fetch = false;
        const {get_prious_tab} = this.props;
        if (this.state.prevY === 0 && Math.abs(this.state.prevY - y) > 200) {
          fetch = true;
        } else if (this.state.prevY >= y) {
          fetch = true;
        }
    
        if (fetch) {
            // console.log("Calling again")
        //   if (this.state.dataItems && this.state.dataItems.next) {
        //     // this.getPinDetails(this.state.dataItems.next.split(".com")[1]);
        //   }  

        // enable for pagination
        // if(get_prious_tab){
		// 	this.setState({tabType:get_prious_tab});
		// 	this.props.fetch_professionals_list({ content_type__category: get_prious_tab, page: this.state.currentPageIndex });
		// }else{
        //     this.props.fetch_professionals_list({ content_type__category: 'doctors', page: this.state.currentPageIndex });
        // }  
        // this.setState(prevState=>({currentPageIndex:prevState.currentPageIndex+1}))    
        }
        this.setState({ prevY: y });
      }

      removeUploadedMediaSet = (id)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSet:{
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:this.state.uploadedMediaSet?.uploadedFiles?.filter(item=>item.id!==id)
                }
            }
        })
    }

    renderLatestReview = (review) => {
        const { toggleItemReadMore, starRatingFilterEnabled } = this.state;
        return (
            <>
                <div className="bg-white text-dark p-3 mb-1">
                    <div className="d-flex mx-n2 mb-2">
                        <div className="px-2">
                            <Link to={`/people/${starRatingFilterEnabled ? review?.latest_review?.created_by?.username : review?.latest_review?.username}/`} className="text-decoration-none">
                                <img
                                    className="img-circle _30x30"
                                    src={starRatingFilterEnabled ? review?.latest_review?.created_by?.current_profile_pic : review?.latest_review?.media_dict?.thumbnail}
                                    onError={(err) => err.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`}
                                    alt={review?.latest_review?.created_by?.username}
                                />
                            </Link>
                        </div>
                        <div className="px-2 flex-grow-1">
                            <div className="ff-base text-dark">
                                <Link to={`/people/${starRatingFilterEnabled ? review?.latest_review?.created_by?.username : review?.latest_review?.username}/`} className="text-reset ff-base font-weight-bold mr-2">
                                    {`${starRatingFilterEnabled ? review?.latest_review?.created_by?.name : review?.latest_review?.name}`}
                                </Link>
                                <span className="text-reset ff-base">
                                    {` ${review?.latest_review?.date_time || review?.latest_review?.created_by?.date_time} `}
                                </span>
                                {review?.latest_review?.ratings_set?.avg_rating &&
                                    Array.isArray(review?.latest_review?.ratings_set?.avg_rating) &&
                                    review?.latest_review?.ratings_set?.avg_rating?.length ?
                                    <img
                                        title={review?.latest_review?.ratings_set?.avg_rating[0]}
                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${review?.latest_review?.ratings_set?.avg_rating[0]}`}
                                        alt={review?.latest_review?.ratings_set?.avg_rating[0]}
                                    />
                                    : null}
                                {!!review?.latest_review?.created_by &&
                                    Array.isArray(review?.latest_review?.created_by?.ratings_set?.avg_rating) &&
                                    review?.latest_review?.created_by?.ratings_set?.avg_rating?.length ?
                                    <img
                                        title={review?.latest_review?.created_by?.ratings_set?.avg_rating[0]}
                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${review?.latest_review?.created_by?.ratings_set?.avg_rating[0]}`}
                                        alt={review?.latest_review?.created_by?.ratings_set?.avg_rating[0]}
                                    />
                                    : null}
                                <br />
                            </div>

                            {/* User Reply */}
                            <div className="ff-alt mt-2 text-break">
                                {review?.latest_review?.review && review?.latest_review?.review?.length > 120 ?
                                    ReactHtmlParser((toggleItemReadMore[review?.id]) ?
                                        (review?.latest_review?.review) :
                                        (review?.latest_review?.review).substring(0, 120) + "...")
                                    :
                                    ReactHtmlParser(detectlink(review?.latest_review?.review))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="text-right mt-3">
                        <Link
                            to={{
                                pathname: (`/${review?.content_type_name}/${review?.id}`).toLowerCase(),
                                state: { id: review?.id }
                            }} className="text-primary font-weight-bold cursor-pointer">
                            {this.state.this_user === review?.latest_review?.user?.id ? "Write your follow-up" : "Write your own"}
                        </Link>
                        <span className="mx-2"></span>
                        <span
                            onClick={() => {
                                if (toggleItemReadMore[review?.id] == undefined) {
                                    toggleItemReadMore[review?.id] = true;
                                } else {
                                    toggleItemReadMore[review?.id] = !toggleItemReadMore[review?.id];
                                }
                                this.setState({ toggleItemReadMore });
                            }}
                            className="text-dark font-weight-bold cursor-pointer">
                            {toggleItemReadMore[review?.id] ? 'show less' : 'read more'}
                        </span>

                    </div>
                </div>
            </>
        )
    }

    handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote }) => {
        const key = 'listing_id';
        let url = `/api/professionalShareByemail/`          
        let review_id = this.state.shareId;
        let data;
        if (review_id) {
          data = {
            review_id: review_id,
            mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        } else {
          data = {
            [key]: this.state.shareId,
            mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        }
    
        this.props.share_by_email(url, data);
      }

      

     
    render() {
        const { collage_items, } = this.props;
        let { professionals_list, toggleItemCreateReview, toggleItemReadMore, professionalImage, starRatingFilterValue, starRatingFilterEnabled } = this.state;
        const loadingCSS = {
            height: "100px",
            margin: "30px",
          };
       
        return (
            <div>
                <HelmetMetaData
                    title={getSubCategoryTitle("professionals")}
                    description={getSubCategoryDescription("professionals")}
                />

                {this.state.isEnableFileUpload ?
                    <UploadMedia
                        getUploadedMedia={this.getUploadedMedia}
                        uploadedUrl={this.state.uploadMediaUrl}
                        hideEmbed={this.state.hideEmbed}
                        callbackFunction={this.handleOnClickUploadModalOk}
                        noCategory={this.state.discussionTab}
                        typeUpload={this.state.typeupload}
                    />
                    : null}
                <AppHeader isEnableCover={false} />
                <CollageItems
                    fetchItems={collage_items}
                    hideNavLinkItems={true}
                    content_type="professionals" professionalImage={professionalImage} />

                {/* Top Menu before main body */}
                <Container>
                    <Nav className="featured position-relative bg-white" justified pills style={{ marginTop: '-54px', zIndex: 3 }}>
                        <NavItem className="mw-100 margin-right-2">
                            <NavLink className="text-truncate" href="#"
                                active={this.state.tabType === "doctors"}
                                onClick={() => {
                                    this.handleChangeTab("doctors")
                                }}>
                                <span className="font-weight-bold text-uppercase">
                                    {'DOCTORS'}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="mw-100 margin-right-2">
                            <NavLink className="text-truncate" href="#"
                                active={this.state.tabType === "dentists"}
                                onClick={() => {
                                    this.handleChangeTab("dentists")
                                }}>
                                <span className="font-weight-bold text-uppercase">
                                    {'DENTISTS'}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="mw-100 margin-right-2">
                            <NavLink className="text-truncate" href="#"
                                active={this.state.tabType === "lawyers"}
                                onClick={() => {
                                    this.handleChangeTab("lawyers")
                                }}>
                                <span className="font-weight-bold text-uppercase">
                                    {"LAWYERS"}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="mw-100">
                            <NavLink className="text-truncate" href="#"
                                active={this.state.tabType === "accountants"}
                                onClick={() => {
                                    this.handleChangeTab("accountants")
                                }}>
                                <span className="font-weight-bold text-uppercase">
                                    {'ACCOUNTANTS'}
                                </span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Container>

                {/* Main Content Starts */}
                <section className="py-4 fs-14">
                    <Container>
                        <Row>
                            <Col md={2}>
                                {/* STAR FILTERS */}
                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2 text-dark">Star Rating</div>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' name="filterByStarRating" checked={(starRatingFilterEnabled && starRatingFilterValue == 5) ? true : false} onClick={() => this.handleStarFilter(5)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_6.png" alt="5 Rating" />
                                                <span>only</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' name="filterByStarRating" checked={(starRatingFilterEnabled && starRatingFilterValue == 4) ? true : false} onClick={() => this.handleStarFilter(4)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_5.png" alt="4 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' name="filterByStarRating" checked={(starRatingFilterEnabled && starRatingFilterValue == 3) ? true : false} onClick={() => this.handleStarFilter(3)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_4.png" alt="3 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' name="filterByStarRating" checked={(starRatingFilterEnabled && starRatingFilterValue == 2) ? true : false} onClick={() => this.handleStarFilter(2)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_3.png" alt="2 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio"className='star-filter-input-checkbox' name="filterByStarRating" checked={(starRatingFilterEnabled && starRatingFilterValue == 1) ? true : false} onClick={() => this.handleStarFilter(1)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_2.png" alt="1 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio"className='star-filter-input-checkbox' name="filterByStarRating" checked={(starRatingFilterEnabled && starRatingFilterValue == 0) ? true : false} onClick={() => this.handleStarFilter(0)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_1.png" alt="0 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <hr className="bg-tertiary" />
                                </div>

                                <div>
                                    <div className="bg-white text-center">
                                        <a
                                            rel="noopener noreferrer"
                                            href="http://wikireviews.com/blog" target="_blank" className="text-reset">
                                            <img className="img-fluid blog-img" src={"https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_05.png"} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div>
                                    {/* Repeat this */}
                                    {professionals_list && Array.isArray(professionals_list) && professionals_list?.length ?
                                        professionals_list?.map((item, index) => (
                                            <div
                                                id={`${professionals_list?.length == index + 1 ? 'last-index' : `current-index-${index}`}`}
                                                className="mb-3 fdmkfdkfdkn">
                                                <div className="bg-white text-dark p-3 mb-1">
                                                    <div className="d-flex mx-n3 mb-2">
                                                        <div className="px-3 mb-3 mb-lg-0">
                                                            <Link to={{
                                                                pathname: (`/${item?.content_type_name}/${item?.id}`).toLowerCase(),
                                                                state: { id: item?.id }
                                                            }} className="text-decoration-none">
                                                                {item?.professional_profileimage?.length > 0 && item?.professional_profileimage[0] != 'Not Available' ?
                                                                    <img className="img-circle _90x90" src={item?.professional_profileimage[0]?.location}  alt={this.getName(item)} />
                                                                    :
                                                                    <img className="img-circle _90x90" src={this.state.professionalImage} alt={this.getName(item)} />
                                                                }
                                                            </Link>
                                                        </div>
                                                        <div className="px-3 col">
                                                            <div className="d-flex mx-n2">
                                                                <div className="px-2 col">
                                                                    <div className="ff-base">
                                                                        <Link
                                                                            to={{
                                                                                pathname: (`/${item?.content_type_name}/${item?.id}`).toLowerCase(),
                                                                                state: { id: item?.id }
                                                                            }} className="fs-16 text-royal-blue ff-base font-weight-bold text-decoration-none">
                                                                            {this.getName(item)}
                                                                        </Link>
                                                                        <div className="mt-1">
                                                                            <span className="d-block">
                                                                                <img className="mr-2" title={`${item?.total_rating?.avg_rating[1]}`} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item?.total_rating?.avg_rating[0]}`} alt="no rating" />
                                                                                <span className="ff-alt text-muted align-middle">{item.total_rating?.total_review}  {item.total_rating?.total_review === 1 ? 'review' : 'reviews'}</span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="px-2 col-auto">
                                                                    <div className="d-inline-flex flex-nowrap mx-n1">
                                                                        <div className="px-1">
                                                                            <UncontrolledDropdown onMouseOver={() => this.onMouseShareEnterCategory(true, item?.id)}
                                                                                onMouseLeave={() => this.onMouseShareLeaveCategory(false, item?.id)}
                                                                                isOpen={this.state.homeShareDropdownOpen[item?.id]}
                                                                                toggle={() => this.toggleShareCategory(this.state.homeShareDropdownOpen[item?.id])}>
                                                                                <DropdownToggle className="p-0 text-dark" color="transparent">
                                                                                    <div className="share-it-icon"></div>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className="type-tertiary-alt" right>
                                                                                    <DropdownItem className="fs-14">
                                                                                        <FacebookShareButton
                                                                                            url={`${getShareReviewURL(item?.content_type_name, item?.slug, item?.id)}`}
                                                                                            quote={`Review of ${item?.name || ""}`}
                                                                                            hashtag={"#WikiReviews"}
                                                                                            >
                                                                                            {"Facebook"}
                                                                                        </FacebookShareButton>
                                                                                    </DropdownItem>
                                                                                    {/* <DropdownItem className="fs-14">Google +</DropdownItem> */}
                                                                                    <DropdownItem className="fs-14">
                                                                                        <TwitterShareButton
                                                                                            url={`${getShareReviewURL(item?.content_type_name, item?.slug, item?.id)}`}
                                                                                            title={`Review of ${item?.name || ""}`}
                                                                                            via={"WikiReviews"}
                                                                                            hashtags={['WikiReviews']}
                                                                                            >
                                                                                            {"Twitter"}
                                                                                        </TwitterShareButton>
                                                                                    </DropdownItem>
                                                                                    <DropdownItem className="fs-14" onClick={() => this.handleOnClickShareByEmail(item.latest_review?.id)}>By Email</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
																</div>
                                                                        <div className="px-1">
                                                                            
                                                                            <UncontrolledDropdown
                                                                                onMouseOver={() => this.onMouseEnterCategory(true, item?.id)}
                                                                                onMouseLeave={() => this.onMouseLeaveCategory(false, item?.id)}
                                                                                isOpen={this.state.homeDropdownOpen[item?.id]}
                                                                                toggle={() => this.toggleCategory(this.state.homeDropdownOpen[item?.id])}>
                                                                                <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                                                    <div className="thumbpin-icon"></div>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className="type-tertiary-alt" right>
                                                                                    {/*<DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('want list', item?.pin_status?.want_list, item)}><FontAwesomeIcon icon="heart" className="mr-2" fixedWidth /> {item?.pin_status?.want_list ? 'Added' : 'Add'} to want list</DropdownItem>
                                                                                        <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('favourite list', item?.pin_status?.favourite_list, item)}><FontAwesomeIcon icon="star" className="mr-2" fixedWidth /> {item?.pin_status?.favourite_list ? 'Added' : 'Add'} to favorites</DropdownItem>
                                                                                      <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('watch list', item?.pin_status?.watch_list, item)}><FontAwesomeIcon icon="eye" className="mr-2" fixedWidth /> {item?.pin_status?.watch_list ? 'Added' : 'Add'} to watch list</DropdownItem>*/}
                                                                                    <DropdownItem
                                                                                        className="fs-14"
                                                                                        onClick={() => this.handleOnClickAddToList('want list', item?.user_recommending?.pin_list_value?.includes('want_list'), item)}>
                                                                                        <div className="addto-icon want-list"></div>
                                                                                        {item?.user_recommending?.pin_list_value?.includes('want_list') ? 'Added' : 'Add'} {'to want list'}
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        className="fs-14"
                                                                                        onClick={() => this.handleOnClickAddToList('favourite list', item?.user_recommending?.pin_list_value?.includes('favourite_list'), item)}>
                                                                                        <div className="addto-icon favorite-list"></div>
                                                                                        {item?.user_recommending?.pin_list_value?.includes('favourite_list') ? 'Added' : 'Add'} {'to favorites'}
                                                                                    </DropdownItem>
                                                                                    <DropdownItem
                                                                                        className="fs-14"
                                                                                        onClick={() => this.handleOnClickAddToList('watch list', item?.user_recommending?.pin_list_value?.includes('watch_list'), item)}>
                                                                                        <div className="addto-icon watch-list"></div>
                                                                                        {item?.user_recommending?.pin_list_value?.includes('watch_list') ? 'Added' : 'Add'} {'to watch list'}
                                                                                    </DropdownItem>

                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item?.taxonomy_list && Array.isArray(item?.taxonomy_list) ?
                                                                <div className="mt-3">
                                                                    <span className="font-weight-bold text-dark">
                                                                        {item?.taxonomy_list?.join(", ")}
                                                                    </span>
                                                                </div>
                                                                :
                                                                ''
                                                            }
                                                            

                                                            <div className="text-right mt-5">
                                                                <Link
                                                                    to={{
                                                                        pathname: (`/${item?.content_type_name}/${item?.id}`).toLowerCase(),
                                                                        state: { id: item?.id }
                                                                    }}
                                                                    className="text-reset font-weight-bold">
                                                                    {'View More'}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {item?.latest_review?.review ? 
                                                    <div className="mb-1">
                                                        {/* Repeat this */}
                                                        {this.renderLatestReview(item)}
                                                    </div>
                                                    :
                                                    <div className="mb-1">
                                                        <div className="bg-white text-dark p-3 mb-1">
                                                            <div className="d-flex mx-n2 mb-2">
                                                                <div className="px-2 flex-grow-1">
                                                                    {/* User Reply */}
                                                                    <div className="ff-alt mt-2">
                                                                    Be the First to Write A Review About This Listing! (And Get Your First Reviewer Badge!)
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="text-right mt-3">
                                                                {/* <span
                                                                    onClick={() => {
                                                                        if (toggleItemCreateReview[item?.id] == undefined) {
                                                                            toggleItemCreateReview[item?.id] = true;
                                                                        } else {
                                                                            toggleItemCreateReview[item?.id] = !toggleItemCreateReview[item?.id];
                                                                        }
                                                                        this.setState({ toggleItemCreateReview });
                                                                    }}
                                                                    className="text-primary font-weight-bold cursor-pointer">
                                                                     Write your own
                                                                </span> */}
                                                                <Link
                                                                    to={{
                                                                        pathname: (`/${item?.content_type_name}/${item?.id}`).toLowerCase(),
                                                                        state: { id: item?.id }
                                                                    }} className="text-primary font-weight-bold cursor-pointer">
                                                                    Write your own
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }    

                                                {/* Internal Create Review */}
                                                {toggleItemCreateReview[item?.id] && (
                                                    this.state.this_user === item?.latest_review?.user?.id ?
                                                    <CreateReview
                                                        itemDetail={item}
                                                        closeAfterCancel={this.closeAfterCancel}
                                                        viewEditReviewStep={'writeReview'}
                                                        mainViewType={'reviews'}
                                                        listing_type={this.state.tabType}
                                                        reviews={[]}
                                                        toggleEnableFileUpload={this.toggleEnableFileUpload}
                                                        uploadedMediaSet={this.state.uploadedMediaSet}
                                                        removeUploadedMediaSet={this.removeUploadedMediaSet}
                                                        onPublishReview={this.onPublishReview}
                                                        categoryId={item?.id}
                                                        item={item?.latest_review}
                                                        isWriteFollowUp={true}
                                                        type="followUp"
                                                    />
                                                    :
                                                    <CreateReview
                                                        itemDetail={item}
                                                        closeAfterCancel={this.closeAfterCancel}
                                                        viewEditReviewStep={'writeReview'}
                                                        mainViewType={'reviews'}
                                                        listing_type={this.state.tabType}
                                                        reviews={[]}
                                                        toggleEnableFileUpload={this.toggleEnableFileUpload}
                                                        uploadedMediaSet={this.state.uploadedMediaSet}
                                                        removeUploadedMediaSet={this.removeUploadedMediaSet}
                                                        onPublishReview={this.onPublishReview}
                                                        categoryId={item?.id}
                                                    />
                                                )}
                                            </div>
                                        )) :
                                        !this.state.loadingLists && <div id="top-bar-start" className="bg-white p-3">
                                            <h2 className="text-secondary-dark">No results found!</h2>
                                        </div>
                                    }

                                    <div
                                        ref={(loadingRef) => (this.loadingRef = loadingRef)}
                                        style={loadingCSS}
                                    >
                                        <Alert color="primary" hidden={!this.state.loadingLists}>
                                            Loading...
                                        </Alert>
                                    </div>
                                </div>

                                {/* Write Review */}
                            </Col>
                            <Col md={4}>
                                {/* <MyConnectionsActivity />
                                 */}
                                <UserFeedsPanel category="professionals" mainCategory={this.state.tabType} />
                            </Col>
                        </Row>
                    </Container>
                </section>
                 {/* Share By Email Modal Popup */}
                 <ModalComponent
                    size="sm"
                    isScrollable={true}
                    isOpen={this.state.shareByEmailModalToggle}
                    toggleHandler={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })}
                    header={"Share by Email"}
                    headerClass="text-uppercase px-0"
                    body={<ShareByEmail handleOnSubmitShareByEmail={(value) => this.handleOnSubmitShareByEmail(value)} modalToggler={() => {
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })
                    }} />}
                /> 
            </div>
        )
    }
}

const mapState = (state) => ({
    this_user: state.user.my_profile,
    collage_items: state.products_listing.products_collage_items,
    professionals_list: state.products_listing.professionals_list,    
    isLoading: state.products_listing.isLoading,
    get_prious_tab: state.professionals.get_prious_tab,
    editExistingMedia : state.shared.editExistingMedia
});

const mapProps = (dispatch) => ({
    fetch_professionals_list: (data) => dispatch(fetch_professionals_list(data)),
    set_previous_tab: (data) => dispatch(set_previous_tab(data)),
    fetch_products_newtrends: (data) => dispatch(fetch_products_newtrends(data)),
    fetch_filtered_professionals: (data) => dispatch(fetch_filtered_professionals(data)),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    get_uploaded_media: (data) => dispatch(get_uploaded_media(data)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),

});

export default connect(mapState, mapProps)(Professionals);

// http://wikireviews.com/api/prfessionalstarratingfilter/?content_type=professionals&stars=5&cat=&subcat=&to_api=true&showReview=strue&location=false&page=1&rating_filter=true
