import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { capitalizeFirstLetter, detectlink, responsiveCarousel } from '../../../utils/utilities';
import ReactHtmlParser from 'react-html-parser';
import { callApi, responseCodeValidator } from '../../../utils/apiCaller';
import querystring from "query-string";
import DataTable from './dataTable';
import Carousel from 'react-multi-carousel';

const UserContributions = ({ isModalOpen, user, toggleModal }) => {
    const initialState = {
        contributions: [],
        pageCount: 0,
        loading: true,
        currentPage: 0,
        mediaSet: [],
        isMediaModalOpen: false,
        confirmDeleteContribution: false,
        deleteContribution: null,
    }
    const [state, setState] = useState(initialState);
    const {
        contributions,
        pageCount,
        loading,
        currentPage,
        mediaSet,
        isMediaModalOpen,
        confirmDeleteContribution,
        deleteContribution
    } = state;

    const pageMaxCount = 10;
    const columnFields = ["name", 'date', "contribution", "media", "text", "action"];
    
    const renderName = ({ contribution }) => {
        if (contribution?.verb == "Commented on status") {
            return <Link to={{ pathname: `/people/${contribution?.action?.sender}/` }}>{contribution?.action?.sender}</Link>;
        }
        return <Link to={{ pathname: `/${contribution?.target?.category?.toLowerCase()}/${contribution?.target?.slug || contribution?.target?.id}`, state: { id: contribution?.target?.id } }}>{contribution?.target?.name}</Link>
    }
    const renderDate = ({ contribution }) => {
        return contribution?.time_stamp;
    }
    const renderContribution = ({ contribution }) => {
        return contribution?.verb;
    }
    const renderMedia = ({ contribution }) => {
        const isMediaPresent = contribution?.action && ((contribution?.action?.attachments && contribution?.action?.attachments?.length) || (contribution?.action?.reviewmedia_set && contribution?.action?.reviewmedia_set?.length)) ? true : false;
        if (isMediaPresent) {
            return (<a href="#" onClick={(e) => { e.preventDefault(); toggleMediaModal({ contribution }) }}>View Media</a>)
        }
    }

    const renderText = ({ contribution }) => {
        if (contribution?.verb === "Answered a question on") {

        } else if (contribution?.verb === "Added an employee to") {
            return `${contribution?.action?.first_name ? contribution?.action?.first_name : ''} ${contribution?.action?.last_name ? contribution?.action?.last_name : ''}`
        } else if (contribution?.verb?.toLowerCase() === "asked a question about" || contribution?.verb?.toLowerCase() === "reported a problem") {
            return (
                <span>
                    <b>{contribution?.verb?.toLowerCase() === "reported a problem" ? "Problem" : "Question"}:</b>
                    <br />
                    <span>{contribution?.action?.body_preview ? contribution?.action?.body_preview : contribution?.action?.body}</span>
                </span>
            )
        } else if (contribution?.verb?.toLowerCase() === "wrote a review" || contribution?.verb?.toLowerCase() === "has written an update for their review on") {
            if (contribution?.action?.professionalreview) {
                return (
                    <span className='text-break'>{contribution?.action?.professionalreview ? ReactHtmlParser(detectlink(contribution.action.professionalreview)) : ''}</span>
                    )
            } else {
                return (
                    <span className='text-break'>{contribution?.action?.review ? ReactHtmlParser(detectlink(contribution.action.review)) : ''}</span>
                    )
            }
            
        } else if (contribution?.verb?.toLowerCase() === "has written a follow-up review of") {
            if (contribution?.action?.professionalreview) {
                return (
                    <span className='text-break'>{contribution?.action?.child_review?.professionalreview ? ReactHtmlParser(detectlink(contribution.action.child_review?.professionalreview)) : ''}</span>
                    )
            } else {
            return (
                <span className='text-break'>{contribution?.action?.child_review?.review ? ReactHtmlParser(detectlink(contribution.action.child_review.review)) : ''}</span>
                )
            }
        } else if (contribution?.verb?.toLowerCase() === "created a new discussion") {
            return (
                <span className='text-break'>{contribution?.action?.title ? contribution?.action?.title : ''}</span>
                )
        }
        return '';
    }

    


    const optimizedColumns = columnFields.map((col) => {
        if (col === "name") {
            return {
                Header: `Listing Name`,
                accessor: col,
                Cell: ({ row }) => <span className='listing-name'>{renderName({ contribution: row.original })}</span>,
            };
        }
        if (col === "date") {
            return {
                Header: "Date",
                accessor: col,
                Cell: ({ row }) => <span>{renderDate({ contribution: row.original })}</span>,
            };
        }
        if (col === "contribution") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span>{renderContribution({ contribution: row.original })}</span>,
            };
        }
        if (col === "media") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span>{renderMedia({ contribution: row.original })}</span>,
            };
        }

        if (col === "text") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span className='review-content'>{renderText({ contribution: row.original })}</span>,
            };
        }

        if (col === "action") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span className={`btn btn-sm disable-button`} onClick={() => { setState((prev) => ({ ...prev, confirmDeleteContribution: !confirmDeleteContribution, deleteContribution: row.original })) }} >Delete</span>,
            };
        }
    });

    useEffect(() => {
        fetchUserContributions({ page: 1 });
    }, [user]);

    const fetchUserContributions = ({ page }) => {

        const params = {
            actor: user?.id,
            page
        }

        const queryParams = querystring.stringify(params);
        const url = `/api/userfeeds/?${queryParams}`;
        setState((prevState) => ({ ...prevState, loading: true }));

        callApi(url, 'GET').then((res) => {
            if (res && responseCodeValidator(String(res.code))) {
                
                
                setState((prevState) => ({
                    ...prevState,
                    currentPage: page - 1,
                    contributions: res?.results,
                    pageCount: Math.ceil(res?.count / pageMaxCount),
                    loading: false,
                }));
            }
        });
    }

    const deleteContributions = () => {

        setState((prevState) => ({ ...prevState, loading: true }));
        const url = `/api/deletecontribution/${deleteContribution?.id}/`;
        callApi(url, 'GET').then((res) => {
            if (res && responseCodeValidator(String(res.code))) {

                fetchUserContributions({
                    page: currentPage + 1
                });
                setState((prev) => ({
                    ...prev,
                    confirmDeleteContribution: !confirmDeleteContribution,
                    deleteContribution: null
                }))
            }
        });
    }


    const columns = useMemo(() => optimizedColumns, [optimizedColumns]);
    const Contributions = useMemo(() => contributions, [contributions]);

    const toggleMediaModal = ({ contribution = null }) => {
        if (contribution && !isMediaModalOpen) {
            const mediaSet = contribution?.action?.attachments || contribution?.action?.reviewmedia_set;
            setState((prev) => ({ ...prev, mediaSet: mediaSet?.length ? mediaSet : [], isMediaModalOpen: true }))
        }
        else {
            setState((prev) => ({ ...prev, mediaSet: [], isMediaModalOpen: false }))
        }
    }

    return (
        <>
            <Modal
                className="custom-pop-change"
                centered
                isOpen={isModalOpen}
                toggle={toggleModal}>
                <ModalHeader className="px-0" toggle={toggleModal}>
                    {`${user?.user?.first_name} ${user?.user?.last_name}`}
                </ModalHeader>
                <ModalBody className="wk-compose-new-messagebody p-3 modal-dataTable">
                    <DataTable
                        columns={columns}
                        data={Contributions}
                        pageCount={pageCount}
                        loading={loading}
                        fetchData={fetchUserContributions}
                        currentPage={currentPage}
                    />
                </ModalBody>
                <ModalFooter className="justify-content-start px-0">

                </ModalFooter>
            </Modal >

            {/** View Media Modal*/}
            <Modal
                className="custom-pop-change"
                centered
                isOpen={isMediaModalOpen}
                toggle={toggleMediaModal}>
                <ModalHeader className="px-0" toggle={toggleMediaModal}>
                    {`Media`}
                </ModalHeader>
                <ModalBody className="wk-compose-new-messagebody p-3 review-media-modal">
                    {loading ? `Loading...` :
                        <Carousel
                            responsive={responsiveCarousel.videoModal}
                            infinite={mediaSet?.length > 1 ? true : false}
                            autoPlay={false}
                            showDots={false}
                            containerClass="gallery-carousel"
                            arrows={mediaSet?.length > 1 ? true : false}
                        >
                            {(mediaSet && mediaSet?.length > 0) && mediaSet.map((media, index) => {
                                return (
                                    <div className="item carsousel-media-conatiner" key={media?.id + index}>
                                        <div className={`media-holder ${media.media_type == "video" ? `video-thumbnail` : ``} mb-2`}>
                                            <img src={media?.media_type == "image" ? media?.url : media?.thumbnail} alt="media" />
                                        </div>
                                    </div>)
                            })}
                        </Carousel>
                    }
                </ModalBody>
            </Modal >

            {/** Delete COnfirmation Modal */}
            <Modal size="sm" isOpen={confirmDeleteContribution} toggle={() => setState((prev) => ({ ...prev, confirmDeleteContribution: !confirmDeleteContribution, deleteContribution: null }))} modalClassName="backdrop-opaque top-shifted transition-none">
                <ModalHeader></ModalHeader>
                <ModalBody className="text-center text-dark">
                    <h2 className="mb-3">Confirmation</h2>
                    <p className="small">{`Are you sure you want to delete ${deleteContribution?.verb} ${deleteContribution?.target?.name}`}?</p>

                    <div className="pt-4">
                        <div>
                            <Button onClick={() => setState((prev) => ({ ...prev, confirmDeleteContribution: !confirmDeleteContribution, deleteContribution: null }))} size="md" color="primary btn-confirm">Cancel</Button>
                            <Button size="md" color="primary btn-confirm" onClick={() => deleteContributions()} >Ok</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>

    )
}

export default UserContributions;