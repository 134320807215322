import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Label,
    Progress,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
import { Link } from "react-router-dom";
import { Ratings } from "../../molecules/homepage/ShowRatings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Carousel as ReactRespCarousel } from "react-responsive-carousel";
import {
    get_dispute_discussion,
    check_jury_duty,
    get_review_details,
    dispute_modal_status,
    cast_jury_vote,
  } from "../../../actions/dispute";
import Moment from "react-moment";
import ReactHtmlParser from 'react-html-parser';


class DisputeJuryDuty extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            voteReviewModalToggleTwo: false, 
            get_dispute_data: [],
            dispute_data: false,
            video: 'https://youtu.be/gYfJadhmcQY',
            vote: 0,
            remarks: '',
            count_0: 0,
            count_1: 1,
            count_2: 2,
            per_0: 0,
            per_1: 0,
            per_2: 0,

            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            voting_enabled: true,
            can_vote: false,
            already_voted: false,
            timer_started: false,
            myProfileMessage: null,
            review_data: '',
            reviewAdded: '',
            has_voted: false,
            finalJury: '',
            is_final_juror: false,
            finalJuryVotingToggle: false,
            finalJuryReview: '',
            per_keep: 0,
            per_delete: 0,
            has_voted_final: false,
            already_voted_final: false,
            finalJuryVote: null,
            finalremarks: '',
            reviewmedia_set: [],            
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps?.get_jury_data && nextProps.get_jury_data['status']) {
              this.setState({
                voteReviewModalToggleTwo: true,
                votingId: nextProps.get_jury_data['voting_id'],
                count_0: nextProps.get_jury_data['results'].count_0,
                count_1: nextProps.get_jury_data['results'].count_1,
                count_2: nextProps.get_jury_data['results'].count_2
              })
              if (!this.state.dispute_data) {
                this.props.get_dispute_discussion(this.state.reviewId)
              }
              if (this.state.finalJuryVotingToggle) {
                this.setState({
                  voteReviewModalToggleTwo: false
                })      
              }      
              let val0 = nextProps.get_jury_data['results'].count_0;
              val0 = Math.round((val0 / 15) * 100);
              let val1 = nextProps.get_jury_data['results'].count_1;
              val1 = Math.round((val1 / 15) * 100);
              let val2 = nextProps.get_jury_data['results'].count_2;
              val2 = Math.round((val2 / 15) * 100);
              this.setState({ per_0: val0, per_1: val1, per_2: val2 })
            }
            else {
              toast(nextProps.get_jury_data && nextProps.get_jury_data['Message'], {
                autoClose: 2500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
              });
            }
            if (this.state.has_voted) {
              this.setState({ already_voted: true })
            }      
          
        if (
            nextProps.get_dispute_data &&
            Object.keys(nextProps.get_dispute_data).length > 0
          ) {
            this.setState({
              get_dispute_data: nextProps.get_dispute_data.results,
              dispute_data: true
            });
            if (!this.state.timer_started) {
              this.timerSettings();
              this.setState({ timer_started: true })
            }
            let now = new Date();
            let valid_date = nextProps.get_dispute_data?.results && nextProps.get_dispute_data.results[0] && nextProps.get_dispute_data?.results[0]['voting_open_till'];
            if (now > new Date(valid_date)) {
              this.setState({ voting_enable: true })
            }
          }      
        if (nextProps && nextProps.get_review_data) {
            this.setState({ reviewAdded: nextProps.get_review_data.results && 
                                         nextProps.get_review_data.results[0],
                            reviewmedia_set: nextProps.get_review_data.results && 
                                            nextProps.get_review_data.results[0]?.reviewmedia_set })
            }         
    }

    componentDidMount = () => {
        this.handleJuryDuty(this.props.review)
    }

    handleJuryDuty= (review) => {
    this.setState({reviewId: review })
    Promise.all([
    this.props.check_jury_duty(review),
    this.props.get_review_details(review)
    ])
    }

    // countdown counter
    timeDiffCalc(dateFuture, dateNow) {
        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
        let difference = {};
        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        difference['days'] = days;

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;
        difference['hours'] = hours;

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        difference['minutes'] = minutes;
        difference['seconds'] = Math.floor(diffInMilliSeconds);

        return difference;
    }

    timerSettings = () => {
        this.interval = setInterval(() => {
        if (this.state.voteReviewModalToggleFour === false) {
            this.clearTimerSettings()
        }

        let dbDate = new Date(
            this.props?.get_dispute_data?.results &&
            this.props.get_dispute_data.results[0]?.created_on
        );
        
        let date = new Date(dbDate);
        date = date.setDate(date.getDate() + 14);        
        dbDate = new Date(date)
        
        let now = new Date()
        let today = new Date(now)

        if (today < dbDate) {
            this.setState({ can_vote: true })
            let countdown = this.timeDiffCalc(dbDate, today)
            this.setState({
            days: countdown['days'],
            hours: countdown['hours'],
            minutes: countdown['minutes'],
            seconds: countdown['seconds']
            });
        }
        else {
            this.setState({ can_vote: false })
            this.clearTimerSettings()
        }

        }, 1000);

    }
    //countdown clear counter
    clearTimerSettings() {
        if (this.interval) {
        clearInterval(this.interval);
        this.setState({ timer_started: false })
        }
    }

    handleChangeDis = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'reviewToKeep') {
      this.setState({ vote: e.target.value })
    }
    };

  handleSubmit = () => {
    let vote_details = {
      'vote': this.state.vote,
      'remarks': this.state.remarks,
      'id': this.state.votingId
    }

    this.props.cast_jury_vote(vote_details, this.state.reviewId)
    this.setState({ remarks: '', has_voted: true })
  }



    render() {
        const { } = this.props;
        let { get_dispute_data, remarks, count_0, count_1, count_2,
            per_0, per_1, per_2, days, hours, minutes, seconds, reviewmedia_set } = this.state;

        return (
            <div>

                {/* Jury Voting Modal */}
                <Modal
                size="xl"
          // scrollable
          className="drawer-modal"
          isOpen={this.state.voteReviewModalToggleTwo}
          // isOpen = {true}
          toggle={() =>
            this.setState({
              voteReviewModalToggleTwo: !this.state.voteReviewModalToggleTwo,
            })
                }
                >
                    <form className="d-flex flex-column h-100">

                    {/* Dispute review intro design */}
                    <div className="row mb-5 form flex-grow-1">
                    <div className="col-lg-8 ml-auto">
                        <div className="bg-warning text-dark p-2 fs-14">
                        <div className="d-flex flex-wrap mx-n2">
                            <div className="text-white px-2">
                            <span className="font-weight-normal">
                                {" "}
                                This is the{" "}
                                <span className="font-weight-bold"> review </span> which
                                has been disputed.{" "}
                            </span>
                            </div>
                        </div>
                        </div>
                        <div className="bg-white p-3 fs-14 text-dark">
                        <div className="d-flex mx-n2 mb-2">
                            <div className="px-2">
                            <a href="#" className="text-decoration-none">
                                <img
                                className="img-circle _50x50"
                                src={this.state.reviewAdded && this.state.reviewAdded.created_user_media && this.state.reviewAdded.created_user_media.url}
                                alt=""
                                />
                            </a>
                            </div>
                            <div className="px-2 flex-grow-1">
                            <div className="px-2 flex-grow-1">
                                <div className="ff-alt">
                                <a
                                    // href={`/people/${this.state.reviewAddedUser}`}
                                    className="text-reset ff-base font-weight-bold"
                                >
                                    {this.state.reviewAdded && this.state.reviewAdded?.associated_to && this.state.reviewAdded?.associated_to.owner?.first_name} {' '}
                                    {this.state.reviewAdded && this.state.reviewAdded?.associated_to && this.state.reviewAdded?.associated_to.owner?.last_name}
                                </a>
                                <span> wrote a review for </span>
                                <Link
                                    to={{
                                    pathname: `/${this.state.reviewAdded?.associated_to?.content_type?.category?.toLowerCase()}/${this.state.reviewAdded?.associated_to?.slug
                                        ? this.state.reviewAdded?.associated_to?.slug
                                        : "null"
                                        }`,
                                    state: { id: this.state.reviewAdded?.associated_to?.content_type?.id },
                                    }}
                                    className="text-reset font-weight-bold"
                                >
                                    {this.state.reviewAdded && this.state.reviewAdded.associated_to && this.state.reviewAdded.associated_to.name}
                                </Link>{" "}
                                and gave it
                                <br />
                                <Ratings
                                    ratings={
                                    this.state.reviewAdded && this.state.reviewAdded.ratings_sets
                                    }
                                />
                                <span> {this.state.reviewAdded && this.state.reviewAdded.review_date_time}</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="ff-alt my-4">
                            <i className="icon-comma-left"></i>
                            <span className="comment-body-renderer">
                            {" "}
                            {ReactHtmlParser(this.state.reviewAdded?.review)}
                            </span>
                            <i className="icon-comma-right"></i>
                        </div>

                        <div className="mb-3">
                                    <ReactRespCarousel className="rr-carousel" infiniteLoop={true} showArrows={true} showStatus={false} showIndicators={false}>
                                        {reviewmedia_set && reviewmedia_set.length > 0 ? (
                                            reviewmedia_set.map((data, index1) => {                                            
                                                    return (
                                                        <div key={index1}>
                                                            {data.type === "image" ?
                                                                <img width="100" src={data.url} alt=""/>
                                                            :
                                                                data.type === "video" ?
                                                                <video width="100%" controls>
                                                                    <source src={data.url} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    );
                                                
                                            })
                                        ) :
                                            ''
                                        }
                                    </ReactRespCarousel>                                    
                                    </div>                                
                        </div>
                    </div>
                    </div>


                    <div className="modal-header p-0">

                    <div className="w-100">
                        <div>
                        <Button
                            color="primary"
                            // onClick={() =>
                            // this.setState({
                            //     voteReviewModalToggleTwo: !this.state
                            //     .voteReviewModalToggleTwo,
                            // })
                            // }
                            onClick={() => this.props.dispute_modal_status({modal:false})}
                        >
                            <FontAwesomeIcon className="mr-2" icon="angle-left" />
                                    back to listing
                                </Button>
                        </div>
                        <div className="bg-dark text-white fs-14 px-3">
                        <div className="mb-2 pt-2">
                            <div className="d-flex mx-n2">
                            <div className="px-2 col">
                                <span className="fs-14 text-light font-weight-bold">
                                You’re Voting{" "}
                                </span>
                                <UncontrolledDropdown className="d-inline-block">
                                <DropdownToggle
                                    className="text-white bg-transparent shadow-none font-weight-bold"
                                    color="transparent"
                                    size="sm"
                                    caret
                                >
                                    <span className="pr-2">Disputed Reviews</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className="fs-14">
                                    Disputed Reviews
                                            </DropdownItem>
                                </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                            <div className="px-2 col-auto">
                                near{" "}
                                <span className="text-light font-weight-bold">
                                Brooklyn, NY
                                        </span>
                            </div>
                            </div>
                        </div>
                        <ul className="list-unstyled d-flex flex-wrap mx-n2">
                            <li className="px-2">
                            <div className="d-flex">
                                <FormGroup>
                                <Input
                                    type="select"
                                    className="transparent"
                                    size="sm"
                                >
                                    <option>Businesses</option>
                                </Input>
                                </FormGroup>
                                <div className="ml-2">
                                <span className="text-danger">*</span>
                                </div>
                            </div>
                            </li>
                            <li className="px-2">
                            <FormGroup>
                                <Input type="select" className="transparent" size="sm">
                                <option>Select Category</option>
                                <option>IT Services</option>
                                <option>Apartment Rentals</option>
                                <option>Automotive</option>
                                <option>Education</option>
                                <option>Beauty and Spas</option>
                                </Input>
                            </FormGroup>
                            </li>
                            <li className="px-2">
                            <FormGroup>
                                <Input type="select" className="transparent" size="sm">
                                <option>Select Category</option>
                                <option>Asian</option>
                                <option>European</option>
                                </Input>
                            </FormGroup>
                            </li>
                            <li className="px-2">
                            <FormGroup>
                                <Input type="select" className="transparent" size="sm">
                                <option>Select Category</option>
                                <option>Indian</option>
                                </Input>
                            </FormGroup>
                            </li>
                            <li className="px-2">
                            <FormGroup>
                                <Input type="select" className="transparent" size="sm">
                                <option>Select Category</option>
                                <option>Level 6</option>
                                </Input>
                            </FormGroup>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <ModalBody className="p-0 fs-14 text-dark bg-transparent">
                    <div className="bg-dark text-white">
                        <Row noGutters>
                        <Col xs="auto">
                            <div
                            className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                            style={{ minWidth: "300px" }}
                            >
                            <div>
                                <img
                                src={require("../../../assets/images/icons/star/white/fill.png")}
                                alt=""
                                />
                                <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                                <img
                                src={require("../../../assets/images/icons/star/white/fill.png")}
                                alt=""
                                />
                            </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                            <Row>
                                <Col>
                                <div className="pr-md-5">
                                    {get_dispute_data &&
                                    Array.isArray(get_dispute_data) &&
                                    get_dispute_data?.length &&
                                    get_dispute_data[0] &&
                                    get_dispute_data[0].is_business_owner ?
                                    <h3 className="text-light">
                                        Business Owner Initiated.
                                            </h3>
                                    :
                                    <h3 className="text-light">
                                        Community Initiated.
                                            </h3>
                                    }
                                    <div className="fs-14">
                                    <div className="text-primary fs-16 font-weight-bold">
                                        Step 1. Your vote is needed.
                                            </div>
                                    {/* <p className="mb-0">
                                        Brief description of why you vote your counts
                                        and what you'd be voting on. Lorem ipsum, dolor
                                        sit amet consectetur adipisicing elit.
                                        Dignissimos, non?
                                            </p> */}
                                    </div>
                                </div>
                                </Col>
                                {this.state.can_vote ?
                                <Col md="auto" className="align-self-end">
                                    <div className="text-light font-weight-bold">
                                    Submission time ends in
                                        </div>
                                    <div className="d-flex mx-n2 flex-nowrap">
                                    <div className="px-2">
                                        <div className="text-white">
                                        <div className="fs-32 ff-headings">{days}</div>
                                        <div className="font-weight-bold">days</div>
                                        </div>
                                    </div>
                                    <div className="px-2">
                                        <div className="text-muted">
                                        <div className="fs-32 ff-headings text-right">
                                            {hours}
                                        </div>
                                        <div className="font-weight-normal">hours</div>
                                        </div>
                                    </div>
                                    <div className="px-2">
                                        <div className="text-muted">
                                        <div className="fs-32 ff-headings text-right">
                                            {minutes}
                                        </div>
                                        <div className="font-weight-normal">
                                            {'minutes'}
                                        </div>
                                        </div>
                                    </div>
                                    <div className="px-2">
                                        <div className="text-muted">
                                        <div className="fs-32 ff-headings text-right">
                                            {seconds}
                                        </div>
                                        <div className="font-weight-normal">
                                            seconds
                                                </div>
                                        </div>
                                    </div>
                                    </div>
                                </Col>
                                :
                                <Col md="auto" className="align-self-end">
                                    <div className="text-light font-weight-bold">
                                    Submission time has ENDED!
                                        </div>
                                    <div className="d-flex mx-n2 flex-nowrap">
                                    <div className="px-2">
                                        <div className="text-muted">
                                        <div className="fs-32 ff-headings">{days}</div>
                                        <div className="font-weight-normal">days</div>
                                        </div>
                                    </div>
                                    <div className="px-2">
                                        <div className="text-muted">
                                        <div className="fs-32 ff-headings text-right">
                                            00
                                        </div>
                                        <div className="font-weight-normal">hours</div>
                                        </div>
                                    </div>
                                    <div className="px-2">
                                        <div className="text-muted">
                                        <div className="fs-32 ff-headings text-right">
                                            00
                                        </div>
                                        <div className="font-weight-normal">
                                            minutes
                                                </div>
                                        </div>
                                    </div>
                                    <div className="px-2">
                                        <div className="text-muted">
                                        <div className="fs-32 ff-headings text-right">
                                            00
                                        </div>
                                        <div className="font-weight-normal">
                                            seconds
                                                </div>
                                        </div>
                                    </div>
                                    </div>
                                </Col>
                                }
                            </Row>
                            </div>
                        </Col>
                        </Row>
                    </div>
                    <div className="body-bg px-3 py-3">
                        <Row>
                        <Col>
                            In case you don't know the correct address, try looking for
                            it with our search bar.
                                </Col>
                        <Col xs="auto">
                            <div className="d-flex">
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="search"
                                placeholder="Custom Search"
                                style={{ minWidth: "300px" }}
                            ></Input>
                            <Button color="primary" size="sm" className="ml-3">
                                <FontAwesomeIcon icon="search" />
                            </Button>
                            </div>
                        </Col>
                        </Row>
                    </div>
                    <div className="bg-white px-3 py-4">
                        <Row>
                        <Col md={4}>
                            <div className="font-weight-bold fs-16">
                            Dispute History
                                    </div>
                            <ul className="d-flex flex-column list-unstyled">
                            <li className="py-2 border-bottom">
                                <div className="d-flex mx-n1">
                                <div className="px-1">
                                    <div className="ff-alt">
                                    <Moment format="DD-MM-YYYY">
                                        {get_dispute_data &&
                                        Array.isArray(get_dispute_data) &&
                                        get_dispute_data?.length &&
                                        get_dispute_data[0] &&
                                        get_dispute_data[0].created_on}
                                    </Moment>
                                    &nbsp; at &nbsp;
                                    <Moment format="HH:MM:SS">
                                        {get_dispute_data &&
                                        Array.isArray(get_dispute_data) &&
                                        get_dispute_data?.length &&
                                        get_dispute_data[0] &&
                                        get_dispute_data[0].created_on}
                                    </Moment>
                                    </div>
                                </div>
                                <div className="px-1">
                                    <a
                                    href="#"
                                    className="text-dark ff-base font-weight-bold"
                                    >
                                    {get_dispute_data[0] &&
                                        get_dispute_data[0].user_name}
                                    </a>
                                    &nbsp; disputed as " {get_dispute_data[0] &&
                                    get_dispute_data[0].flag_name} "
                                </div>
                                </div>
                            </li>


                            {get_dispute_data && get_dispute_data.map((discussion) => (

                                <>
                                {discussion.child_key_name === 1 ||
                                    discussion.child_key_name === '1'
                                    ?
                                    <li className="py-2 border-bottom">
                                    <div className="d-flex mx-n1">
                                        <div className="px-1">
                                        <div className="ff-alt">
                                            <Moment format="DD-MM-YYYY">
                                            {discussion &&
                                                discussion.created_on}
                                            </Moment>
                                        &nbsp; at &nbsp;
                                        <Moment format="HH:MM:SS">
                                            {discussion &&
                                                discussion.created_on}
                                            </Moment>
                                        </div>
                                        </div>
                                        <div className="px-1">
                                        <a
                                            href="#"
                                            className="text-dark ff-base font-weight-bold"
                                        >
                                            {discussion &&
                                            discussion.user_name}
                                        </a>
                                    &nbsp; reverted edits by{" "}
                                        "
                                    {discussion &&
                                            discussion.content_flagged}
                                        "
                                </div>
                                    </div>
                                    </li>
                                    :
                                    ''
                                }

                                </>
                            ))}


                            </ul>
                        </Col>
                        <Col md={8}>
                            <div className="mb-3">
                            <div className="text-primary fs-16 font-weight-bold mb-2">
                                Step 2. Some Copy Line here
                            </div>
                            <div>
                                <div className="bg-light p-2 text-dark mb-2">
                                <div className="text-tertiary">
                                    Flagged for
                                    <strong> " {get_dispute_data &&
                                    Array.isArray(get_dispute_data) &&
                                    get_dispute_data?.length &&
                                    get_dispute_data[0] &&
                                    get_dispute_data[0].flag_name} " </strong>
                                </div>
                                <hr className="my-2" />
                                <div>
                                    <div className="d-flex mx-n2">
                                    <div className="px-2">
                                        <img
                                        className="img-circle _50x50"
                                        src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                        alt=""
                                        />
                                    </div>
                                    <div className="px-2">
                                        <div className="mb-2">
                                        by{" "}
                                        <a href="#" className="text-reset">
                                            <strong>{get_dispute_data &&
                                            Array.isArray(get_dispute_data) &&
                                            get_dispute_data?.length &&
                                            get_dispute_data[0] &&
                                            get_dispute_data[0].user_name}</strong>
                                        </a>
                                        &nbsp;|&nbsp;
                                        <span className="fs-12 text-muted">
                                            Created on&nbsp;
                                            <span className="text-reset">
                                            <Moment format="MMM DD,YYYY">
                                                {get_dispute_data &&
                                                Array.isArray(get_dispute_data) &&
                                                get_dispute_data?.length && get_dispute_data[0] &&
                                                get_dispute_data[0].created_on}
                                            </Moment>
                                            &nbsp; , &nbsp;
                                            <Moment format="HH:MM">
                                                {get_dispute_data &&
                                                Array.isArray(get_dispute_data) &&
                                                get_dispute_data?.length &&
                                                get_dispute_data[0] &&
                                                get_dispute_data[0].created_on}
                                            </Moment>
                                            </span>
                                        </span>
                                        </div>
                                        <div>
                                        " {get_dispute_data &&
                                            Array.isArray(get_dispute_data) &&
                                            get_dispute_data?.length &&
                                            get_dispute_data[0] &&
                                            get_dispute_data[0].content_flagged} "
                                    </div>
                                        <div className="bg-light p-3">
                                        <div className="mb-2">

                                            {get_dispute_data &&
                                            Array.isArray(get_dispute_data) &&
                                            get_dispute_data?.length &&
                                            get_dispute_data[0] &&
                                            get_dispute_data[0]?.media &&
                                            Array.isArray(get_dispute_data[0].media) &&
                                            get_dispute_data[0]?.media?.length &&
                                            get_dispute_data[0].media?.map((media) =>
                                                <div>
                                                {media.includes('.jpeg') || media.includes('.jpg') || media.includes('.png')
                                                    ?
                                                    <div className="mb-2">
                                                    <img className="img-fluid" src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} alt="" />
                                                    </div>
                                                    :
                                                    <div className="mb-2 d-inline-block">
                                                    <div className="d-flex mx-n2">
                                                        <div className="px-2">
                                                        <FontAwesomeIcon icon="paperclip" fixedWidth />
                                                        </div>
                                                        <div className="px-2 col">
                                                        <span className="font-weight-bold text-truncate">
                                                            <a className="text-reset font-weight-bold" href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} rel="noopener noreferrer" target="_blank">{media.toString().split('/')[2]}</a>
                                                        </span>
                                                        </div>
                                                        <div className="px-2 ml-auto">
                                                        <span className="mr-2">|</span>
                                                        <a href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} rel="noopener noreferrer" target="_blank" className="text-reset">
                                                            <FontAwesomeIcon icon="external-link-alt" />
                                                        </a>
                                                        </div>
                                                    </div>
                                                    </div>
                                                }
                                                </div>
                                            )}
                                        </div>
                                        </div>

                                    </div>
                                    </div>
                                </div>
                                </div>

                            </div>
                            {get_dispute_data && get_dispute_data.length > 1 ?
                                <div className="text-tertiary fs-16 font-weight-bold mt-4">
                                Discussion between parties
                            </div>
                                :
                                ''
                            }
                            <hr className="mt-2" />

                            <div>
                                {get_dispute_data && get_dispute_data.length > 1 ?
                                <div className="p-2 text-dark">
                                    {get_dispute_data && get_dispute_data.map((discussion) => (
                                    <div className="mb-3">
                                        { discussion.child_key_name === 1 ||
                                        discussion.child_key_name === '1'
                                        ?
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                            <img
                                                className="img-circle _30x30"
                                                src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                                alt=""
                                            />
                                            </div>
                                            <div className="px-2 col">
                                            <div className="mb-3">
                                                <div className="d-flex mx-n2 mb-1">
                                                <div className="px-2 col">
                                                    <a href="#" className="text-reset">
                                                    <strong>{discussion.user_name}</strong>
                                                    </a>
                                            &nbsp;
                                                </div>
                                                <div className="col-auto">
                                                    <div className="text-tertiary">
                                                    <span className="text-reset">
                                                        <Moment format="MMM DD,YYYY">
                                                        {discussion.created_on}
                                                        </Moment>
                                                &nbsp; , &nbsp;
                                                <Moment format="HH:MM">
                                                        {discussion.created_on}
                                                        </Moment>
                                                    </span>
                                                    </div>
                                                </div>
                                                </div>
                                                <div>
                                                {discussion.content_flagged}
                                                </div>
                                            </div>
                                            <div className="bg-light p-3">
                                                <div className="mb-2">

                                                {discussion && discussion?.media != 'No media found' && discussion.media?.map((media) =>
                                                    <div>
                                                    {media.includes('.jpeg') || media.includes('.jpg') || media.includes('.png')
                                                        ?
                                                        <div className="mb-2">
                                                        <img className="img-fluid" src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} alt="" />
                                                        </div>
                                                        :
                                                        <div className="mb-2 d-inline-block">
                                                        <div className="d-flex mx-n2">
                                                            <div className="px-2">
                                                            <FontAwesomeIcon icon="paperclip" fixedWidth />
                                                            </div>
                                                            <div className="px-2 col">
                                                            <span className="font-weight-bold text-truncate">
                                                                <a className="text-reset font-weight-bold" href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} rel="noopener noreferrer" target="_blank">{media.toString().split('/')[2]}</a>
                                                            </span>
                                                            </div>
                                                            <div className="px-2 ml-auto">
                                                            <span className="mr-2">|</span>
                                                            <a href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} rel="noopener noreferrer" target="_blank" className="text-reset">
                                                                <FontAwesomeIcon icon="external-link-alt" />
                                                            </a>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    }
                                                    </div>
                                                )}
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        :
                                        ''
                                        }
                                    </div>
                                    ))}

                                </div>
                                :
                                ''
                                }
                                <hr />
                            </div>
                            </div>
                        </Col>
                        </Row>
                    </div>
                    <div className="bg-light px-3 py-4">
                        <Row>
                        <Col md={4}>
                            <div className="bg-dark text-white text-center p-3 bg-vote-alt">
                            <div className="my-2 ff-headings fs-50">CAST</div>
                            <img
                                className="my-2"
                                src={require("../../../assets/images/icons/star/white/fill.png")}
                                alt=""
                            />
                            <div className="my-2 ff-headings fs-50">YOUR</div>
                            <img
                                className="my-2"
                                src={require("../../../assets/images/icons/star/white/fill.png")}
                                alt=""
                            />
                            <div className="my-2 ff-headings fs-50">VOTE</div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div>
                            <div className="text-primary fs-16 font-weight-bold mb-2">
                                Step 3. Some copy line
                                    </div>
                            <div>
                                <div className="bg-white p-3 mb-1">
                                <div className="d-flex mx-n2 mb-2">
                                    <div className="px-2 col">
                                    <FormGroup className="mb-2" check>
                                        <Label
                                        className="text-reset font-weight-normal fs-14 d-block"
                                        check
                                        >
                                        <Input type="radio" name="reviewToKeep" onChange={this.handleChangeDis} value="0" />{" "}
                                        <div className="mb-1">
                                            Fake, remove the review.
                                                </div>
                                        <div className="d-flex flex-wrap mx-n2 fs-14">
                                            <div className="px-2 mb-2">{per_0}%</div>
                                            <div className="px-2 mb-2 align-self-center">
                                            <Progress
                                                color="tertiary"
                                                value="25"
                                                style={{
                                                height: "6px",
                                                width: "240px",
                                                }}
                                            ></Progress>
                                            </div>
                                            <div className="px-2 mb-2">
                                            {count_0} votes out of 15
                                                    </div>
                                        </div>
                                        </Label>
                                    </FormGroup>
                                    </div>
                                    <div className="px-2 col-auto">
                                    {/* <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Expand"
                                >
                                    <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Collapse"
                                >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                </Button> */}
                                    </div>
                                </div>

                                {/* Collapse content */}
                                {/* <div className="pl-3">Collapse content</div> */}
                                </div>
                                <div className="bg-white p-3 mb-1">
                                <div className="d-flex mx-n2 mb-2">
                                    <div className="px-2 col">
                                    <FormGroup className="mb-2" check>
                                        <Label
                                        className="text-reset font-weight-normal fs-14 d-block"
                                        check
                                        >
                                        <Input type="radio" name="reviewToKeep" onChange={this.handleChangeDis} value="1" />{" "}
                                        <div className="mb-1">
                                            Review is valid, leave it.
                                                </div>
                                        <div className="d-flex flex-wrap mx-n2 fs-14">
                                            <div className="px-2 mb-2">{per_1}%</div>
                                            <div className="px-2 mb-2 align-self-center">
                                            <Progress
                                                color="tertiary"
                                                value="25"
                                                style={{
                                                height: "6px",
                                                width: "240px",
                                                }}
                                            ></Progress>
                                            </div>
                                            <div className="px-2 mb-2">
                                            {count_1} votes out of 15
                                                    </div>
                                        </div>
                                        </Label>
                                    </FormGroup>
                                    </div>
                                    <div className="px-2 col-auto">
                                    {/* <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Expand"
                                >
                                    <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Collapse"
                                >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                </Button> */}
                                    </div>
                                </div>

                                {/* Collapse content */}
                                {/* <div className="pl-3">Collapse content</div> */}
                                </div>
                                <div className="bg-primary text-white p-3 mb-1">
                                <div className="d-flex mx-n2 mb-2">
                                    <div className="px-2 col">
                                    <FormGroup className="mb-2" check >
                                        <Label
                                        className="text-reset font-weight-normal fs-14 d-block"
                                        check
                                        >
                                        <Input type="radio" name="reviewToKeep" onChange={this.handleChangeDis} value="2" />{" "}
                                        <div className="mb-1">
                                            Move to Non-Recommended.
                                                </div>
                                        <div className="d-flex flex-wrap mx-n2 fs-14">
                                            <div className="px-2 mb-2">{per_2}%</div>
                                            <div className="px-2 mb-2 align-self-center">
                                            <Progress
                                                color="tertiary"
                                                value="25"
                                                style={{
                                                height: "6px",
                                                width: "240px",
                                                }}
                                            ></Progress>
                                            </div>
                                            <div className="px-2 mb-2">
                                            {count_2} votes out of 15
                                                    </div>
                                        </div>
                                        </Label>
                                    </FormGroup>
                                    </div>
                                    <div className="px-2 col-auto">
                                    {/* <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Expand"
                                >
                                    <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Collapse"
                                >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                </Button> */}
                                    </div>
                                </div>

                                {/* Collapse content */}
                                {/* <div className="pl-3">Collapse content</div> */}
                                </div>
                            </div>
                            </div>
                            {this.state.already_voted
                            ?
                            <div className="mt-4">
                                <div className="text-primary fs-16 font-weight-bold mb-2">
                                Thanks for submitting your Vote!
                        </div>
                            </div>
                            :
                            <div className="mt-4">
                                <div className="text-primary fs-16 font-weight-bold mb-2">
                                Step 4. Please, tell us why.
                                    </div>
                                <div className="text-dark">
                                It would be really helpful for fellow voters to know the
                                reasoning of your decision. It has to be 140 characters
                                long. Some text like that.
                                    </div>
                                <div className="text-right text-primary fs-14">65</div>
                                <Input
                                type="textarea"
                                className="primary"
                                rows="3"
                                name="remarks"
                                value={remarks}
                                onChange={this.handleChangeDis}
                                defaultValue="The Info is accurate, I've been there today."
                                placeholder="Please provide a written explanation to fully support your vote."
                                />
                            </div>
                            }
                        </Col>
                        </Row>
                    </div>
                    </ModalBody>
                    {this.state.already_voted ?
                    ''
                    :
                    <ModalFooter className="bg-white">
                        {this.state.can_vote ?
                        <Button color="primary" onClick={this.handleSubmit}>Submit</Button>
                        :
                        <Button color="primary" disabled>Submit</Button>
                        }
                        <Button color="link" className="font-weight-bold" onClick={() => this.props.dispute_modal_status({modal:false})}>
                        Skip
                    </Button>
                    </ModalFooter>
                    }
                </form >
                </Modal >

        

               
                 {/* SEE HOW REVIEW DISPUTES WORK */}
                <Modal
                    isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
                    toggle={() =>
                        this.setState({
                        watchHowDisputeReviewWorksModalToggle: !this.state
                            .watchHowDisputeReviewWorksModalToggle,
                        })
                    }
                    >
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() =>
                        this.setState({
                            watchHowDisputeReviewWorksModalToggle: !this.state
                            .watchHowDisputeReviewWorksModalToggle,
                        })
                        }
                    >
                        SEE HOW REVIEW DISPUTES WORK
                    </ModalHeader>
                    <ModalBody className="p-0 bg-transparent">
                        <div className="text-center">
                        <video controls autoPlay width="100%" seekable>
                            <source
                            src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                            type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                        </div>
                    </ModalBody>
                </Modal>
                
            </div>
        )
    }
}

const mapState = (state) => ({
    my_profile: state.user.my_profile,
    get_jury_data: state.dispute.get_jury_data,
    get_dispute_data: state.dispute.get_dispute_data,
    get_review_data: state.dispute.get_review_data,
});

const mapProps = (dispatch) => ({
    check_jury_duty: (params) => dispatch(check_jury_duty(params)),
    get_review_details: (params) => dispatch(get_review_details(params)),
    get_dispute_discussion: (id) => dispatch(get_dispute_discussion(id)),
    dispute_modal_status: (params) => dispatch(dispute_modal_status(params)),
    cast_jury_vote: (params, id) => dispatch(cast_jury_vote(params, id)),
})


export default connect(mapState, mapProps)(DisputeJuryDuty);