
const initialState = {
  add_dispute_status: null,
  get_dispute_data: null,
  get_dispute_modal_status: null,
  all_disputed_reviews: null,
  get_jury_duty_data: null,
  get_jury_data: null,
  get_review_data: null,
  get_dispute_draft_data: null,
  delete_dispute_draft_data: null,
  delete_admin_dispute_data: null,
  jury_duty_history: null,
  jury_result: null,
  get_jury_modal_status: null,
  admin_requested_disputes: null,
  administrative_disputed_review: null,
  profile_dispute_count: null,
};


const dispute = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_INITIAL_AUTH_NEW':
      return initialState;
      
    case "ADD_DISPUTE_STATUS":
      state.add_dispute_status = action.payload;
      return { ...state };
    
    case "GET_DISPUTE_DATA":
        state.get_dispute_data = action.payload;
        return { ...state };

    case "DISPUTE_MODAL_STATUS":
        state.get_dispute_modal_status = action.payload;
        return { ...state };

    case "ALL_DISPUTED_REVIEWS":    
      state.all_disputed_reviews = action?.payload?.results;
      return { ...state };

    case "GET_JURY_DUTY_DATA":
        state.get_jury_duty_data = action.payload;
        return { ...state };

    case "GET_JURY_DATA":
      state.get_jury_data = action.payload;
      return { ...state };

    case "REVIEW_DATA":
      state.get_review_data = action.payload;
      return { ...state };

    case "GET_DISPUTE_DRAFT":
      state.get_dispute_draft_data = action.payload;
      return { ...state };

    case "DELETE_DISPUTE_DRAFT":
      state.delete_dispute_draft_data = action.payload;
      return { ...state };

    case "DELETE_ADMINISTRATIVE_DISPUTE":
      state.delete_admin_dispute_data = action.payload;
      return { ...state };

    case "GET_JURY_DUTY_HISTORY":
      state.jury_duty_history = action.payload;
      return { ...state };

    case "GET_JURY_RESULT":
      state.jury_result = action.payload;
      return { ...state };

    case "JURY_MODAL_STATUS":
      state.get_jury_modal_status = action.payload;
        return { ...state };

    case "ADMINISTRATIVE_REVIEWS":
      state.admin_requested_disputes = action.payload;
        return { ...state };

    case "ADMINISTRATIVE_DISPUTED_REVIEW":
      state.administrative_disputed_review = action.payload;
        return { ...state };

    case "PROFILE_DISPUTE_COUNT":
      state.profile_dispute_count = action.payload;
        return { ...state };

    default:
      return state;
  }
};

export default dispute;
