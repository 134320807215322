import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import AppHeader from '../../oragnisms/dashboard/appHeader';

export default class DmcaTakedown extends Component {

    state = {
        form: {
            name: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
            phone: "",
            email: "",
            originalURL: "",
            allegedURL: "",
            additionalInfo: "",
            signature: ""
        },
        supportingDoc: undefined,
        error: {},
        embedLink: [{ 'embedLink_0': "" }]
    }

    onChangeInput = (e) => {
        const { error } = this.state
        const field = { [e.target.name]: e.target.value }
        delete error.name
        this.setState((state) => { return { form: { ...state.form, ...field }, error } })
    }

    onChangeEmbedLink = (e, index) => {
        const { embedLink } = this.state
        const field = { [e.target.name]: e.target.value }
        embedLink[index] = field
        this.setState((state) => { return { embedLink: embedLink } })
    }

    onChangeFileInput = (e) => {
      
        if (e.target?.files && e.target?.files.length >0)
            this.setState({ supportingDoc: e.target.files[0] })
        else
            this.setState(({ supportingDoc: undefined }))
     
    }

    onChangeCheckBoxInput = (e) => {
        
        const field = { [e.target.name]: e.target.checked }
        this.setState((state) => { return { form: { ...state.form, ...field } } })
    }

    requiredFieldValidation = (form) => {
        const error = {}
        for (let [, [key, value]] of Object.entries(Object.entries(form))) {
            if (value === "" || !value)
                error[key] = "Required"
        }

        this.setState((state) => { return { error: { ...state.error, ...error } } })
        return error
    }

    onSubmit = (e) => {
        e.preventDefault()
        const { form, supportingDoc } = this.state
        const formData = new FormData();
        let errors = this.requiredFieldValidation(form)
        // if (Object.keys(errors).length > 0)
        //     return false

        for (let [, [key, value]] of Object.entries(Object.entries(form))) {
            formData.append(key, value);
        }

        if(supportingDoc){
            formData.append('supportingDoc',supportingDoc , supportingDoc.name)
        }
        for(var pair of formData.entries()) {
            console.log(pair[0]+ ', '+ pair[1] , pair);
         }
        this.postTakedown(formData, e)
    }

    postTakedown = (data, e) => {
        const target = e.target

        // callApi(`/api/projects/`, "POST", data).then((response) => {
        //     if (response && response.code !== 400 && response.code !== 500) {
        //         target.reset()
        //     }
        // });
    }

    onClickAddDeleteEmbedLink(type, index) {
        try {
            const { embedLink } = this.state
          
            if (type === 'PLUS') {
                embedLink.push({ [`embedLink_${index}`]: "" })
                this.setState({ embedLink: embedLink })
            } else if (type === 'MINUS') {
                embedLink.splice(index, 1)
                let updateData = [ ...embedLink]
               
                this.setState({ embedLink: updateData },()=>this.forceUpdate())
            }
        } catch (err) { console.log('err', err) }

    }

    render() {
        const { embedLink } = this.state
        
        return (
            <div>
                <AppHeader isEnableCover={false} />
                <Container className="jumbo-page-header" fluid={true}>
                    <h1>DMCA Takedown Request</h1>
                </Container>
                <section className="pt-5">
                    <Container>
                        <Row>
                            <Col lg={3}>
                                <div className="text-dark ff-alt fs-14">
                                    <h2 className="ff-alt text-tertiary fs-20 font-weight-bold mb-3">DMCA Help</h2>
                                    <div className="mb-4">
                                        <div className="fs-16 font-weight-bold mb-2">
                                            What does DMCA mean?
                                        </div>
                                        <p className="text-tertiary lh-1-3">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur voluptas beatae eos voluptatem illum nostrum magnam, molestias quaerat consequatur, sequi sit! Debitis assumenda quos exercitationem alias corporis repellendus, minima deserunt.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <div className="fs-16 font-weight-bold mb-2">
                                            What does DMCA mean?
                                        </div>
                                        <p className="text-tertiary lh-1-3">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur voluptas beatae eos voluptatem illum nostrum magnam, molestias quaerat consequatur, sequi sit! Debitis assumenda quos exercitationem alias corporis repellendus, minima deserunt.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <div className="fs-16 font-weight-bold mb-2">
                                            What does DMCA mean?
                                        </div>
                                        <p className="text-tertiary lh-1-3">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur voluptas beatae eos voluptatem illum nostrum magnam, molestias quaerat consequatur, sequi sit! Debitis assumenda quos exercitationem alias corporis repellendus, minima deserunt.
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={9}>
                                <div className="ff-alt text-dark fs-20 font-weight-bold mb-2">DMCA Takedown Request Form</div>
                                <Form onSubmit={this.onSubmit}>
                                    <div className="bg-white p-3 fs-14">
                                        <div className="text-tertiary ff-alt mb-4">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cumque, rerum quis quo dignissimos consectetur ex omnis? Omnis atque cum fuga labore corporis aperiam est odio, quos aspernatur ex impedit quis sunt voluptatum explicabo sequi, harum tempore, fugiat rem dolorum temporibus aliquam magnam exercitationem assumenda! Blanditiis nesciunt magnam nam velit ducimus.
                                        </div>
                                        <FormGroup className="form-group-primary form-row">
                                            <Label size="sm" for="fullName" sm={3}>Name</Label>
                                            <Col sm={9} md={6}>
                                                <Input required onChange={this.onChangeInput} type="text" bsSize="sm" name="name" id="fullName" placeholder="Type in your Name" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="form-group-primary form-row">
                                            <Label size="sm" for="address1" sm={3}>Address</Label>
                                            <Col sm={9} md={6}>
                                                <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="address1" id="address1" placeholder="Type in your Address, Line 1" />
                                                <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="address2" id="address2" placeholder="Type in your Address, Line 2" />
                                            </Col>
                                            <Col sm={{ size: 9, offset: 3 }}>
                                                <Row form>
                                                    <Col sm={7}>
                                                        <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="city" id="city" placeholder="City" />
                                                    </Col>
                                                    <Col sm={2}>
                                                        <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="state" id="state" placeholder="State" />
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Input onChange={this.onChangeInput} type="text" bsSize="sm" className="mb-2" name="zipCode" id="zip_code" placeholder="Zip Code" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="form-group-primary form-row">
                                            <Label size="sm" for="phone" sm={3}>Phone Number</Label>
                                            <Col sm={9} md={6}>
                                                <Input required onChange={this.onChangeInput} type="tel" bsSize="sm" name="phone" id="phone" placeholder="Type in your phone Number" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="form-group-primary form-row">
                                            <Label size="sm" for="email" sm={3}>Email</Label>
                                            <Col sm={9} md={6}>
                                                <Input required onChange={this.onChangeInput} type="email" bsSize="sm" name="email" id="email" placeholder="Type in your email Address" />
                                            </Col>
                                        </FormGroup>
                                        <hr />
                                        <FormGroup className="form-group-primary form-row">
                                            <Label size="sm" for="originalURL" sm={3}>Original work(s)</Label>
                                            <Col sm={9} md={6}>
                                                <Input required onChange={this.onChangeInput} type="url" bsSize="sm" name="originalURL" id="original_url" placeholder="URL of original artwork" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="form-group-primary form-row">
                                            <Label size="sm" for="allegedURL" sm={3}>Alleged Infringing Work(s)</Label>
                                            <Col sm={9} md={6}>
                                                <Input required onChange={this.onChangeInput} type="url" bsSize="sm" className="mb-2" name="allegedURL" id="alleged_url" placeholder="URL of alleged infringing work(s)" />
                                            </Col>
                                            <Col sm={{ size: 9, offset: 3 }}>
                                                <Input maxLength={300} onChange={this.onChangeInput} type="textarea" rows="4" bsSize="sm" name="additionalInfo" id="additional_info" placeholder="Additional information. (Optional)" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="form-group-primary form-row">
                                            <Label size="sm" for="documents" sm={3}>Supporting Documents</Label>
                                            <Col sm={9} md={6}>
                                                <Input onChange={this.onChangeFileInput} type="file" bsSize="sm" className="mb-3" name="documents" id="documents" placeholder="Upload supporting files" />
                                            </Col>
                                            <Col sm={{ size: 9, offset: 3 }} md={{ size: 3, offset: 0 }}>
                                                <span className="text-tertiary ff-alt fs-14">or <span role="button" className="font-weight-bold">embed link</span></span>
                                            </Col>
                                            <Col sm={{ size: 9, offset: 3 }}>
                                                {/* Repeat this */}
                                                {embedLink.map((value, index) => (

                                                    <div key={index} className="d-flex flex-nowrap mx-n1 mb-2">
                                                        <div className="px-1 col">
                                                            <Input onChange={(e) => this.onChangeEmbedLink(e, index)} type="text" bsSize="sm" name={`embedLink_${index}`} value={value[`${Object.keys(value)[0]}`]} id={`embed_link_${index}`} placeholder="Inset embed links" />
                                                        </div>
                                                        <div className="px-1">
                                                            <Button onClick={() => this.onClickAddDeleteEmbedLink('PLUS', index + 1)} color="primary" size="sm" title="Add" className="mr-0">
                                                                <FontAwesomeIcon icon="plus" />
                                                            </Button>
                                                            {/* Hide below if one remains */}
                                                            <Button disabled={Object.keys(embedLink).length === 1} onClick={() => this.onClickAddDeleteEmbedLink('MINUS', index)} color="danger" size="sm" title="Remove" className="ml-2">
                                                                <FontAwesomeIcon icon="minus" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Col>
                                        </FormGroup>
                                        <hr />
                                        <FormGroup className="form-group-primary mb-3 form-row" check inline>
                                            <Label size="sm" className="text-tertiary" sm={3}>Disclaimer(s)</Label>
                                            <Col sm={9}>
                                                <p className="text-tertiary fs-14">
                                                    "I have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law."
                                                </p>
                                                <p className="text-tertiary fs-14">
                                                    "The information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed."
                                                </p>
                                                <Label className="text-tertiary ff-alt" check>
                                                    <Input required onChange={this.onChangeCheckBoxInput} type="checkbox" name="agree" /> I Agree
                                                </Label>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="form-group-primary form-row">
                                            <Label size="sm" for="signature" sm={3}>Signature</Label>
                                            <Col sm={9} md={5}>
                                                <Input required onChange={this.onChangeInput} type="text" bsSize="sm" name="signature" id="signature" placeholder="Electronic Signature" />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                    <div className="p-3 text-right">
                                        <Button type="submit" color="primary">Submit</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        )
    }
}
