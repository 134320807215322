import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export default class FirstToReview extends Component {
    render() {
        const { itemDetail } = this.props;
        if (itemDetail?.first_review_info && Array.isArray(itemDetail?.first_review_info) && itemDetail?.first_review_info?.length) {
            return (
                <div className="bg-white-shaded-md p-3 mb-3">
                    <div className="d-flex mx-n2 fs-14">
                        <div className="px-2">
                            <Link className="fs-14 text-dark d-block" to="#" target="_blank">
                                <img
                                    src={itemDetail?.first_review_info[0]?.user_pic}
                                    onError={(e) => {
                                        e.target.src = 'https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png'
                                    }}
                                    className="object-fit-cover rounded-circle"
                                    width={32}
                                    height={32}
                                    title={'title'}
                                    alt="" />
                            </Link>
                        </div>
                        <div className="px-2 col">
                            <Link className="d-inline-block text-dark font-weight-bold ff-base" to="#" target="_blank">
                                {itemDetail?.first_review_info[0]?.user_name}
                            </Link>
                            <div>
                                <span className="text-primary font-weight-bold ff-base">1<sup>st</sup> to Review</span>
                            </div>
                        </div>
                        <div className="px-2">
                            <img width="24" src="https://userdatawikireviews.s3.amazonaws.com/images/first-to-review.png" alt="" />
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }
}

