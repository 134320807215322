/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import queryString from 'query-string';
import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, Alert } from 'reactstrap';
import { clear_search_home, fetch_current_location, get_searchHome, add_tempLocation, get_search_location } from "../../actions/home";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import actorIcon from "../../assets/images/icons/actors-top-search.png";
import allCatIcon from "../../assets/images/icons/all-category-top-search.png";
import businessIcon from "../../assets/images/icons/business-top-search.png";
import crpIcon from "../../assets/images/icons/crp-hover.png";
import moviesIcon from "../../assets/images/icons/movies-top-search.png";
import peopleIcon from "../../assets/images/icons/people-top-search.png";
import productsIcon from "../../assets/images/icons/products-top-search.png";
import professionalIcon from "../../assets/images/icons/professionals-top-search.png";
import brandLogo from '../../assets/images/icons/w-brand.png';
import PlacementAutoComplete from '../atoms/PlacementAutoHomeComplete';
import AppHeader from "../oragnisms/homepage/AppHeader/appHeader";
import Footer from '../oragnisms/homepage/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const responsive = {
    features: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1
        }
    },
    product: {
        desktop: {
            breakpoint: { max: 9999999, min: 1200 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1199, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1
        }
    }
};

class HomepageCity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewTrendingCity: 'la',
            content_type: "All",
            contentImage: allCatIcon,
            searchHomeData: [],
            search_name: "",
            address: '',
            enableSearch: false,
            unableToSet: true,
            isSearching: false,
            searchLocation: null,
            loader: true,
            loadMore: false,
            searchLocationData: []
        }
    }

    componentWillReceiveProps(nextProps) {
        let { unableToSet } = this.state
        if (nextProps.get_search_home &&
            Array.isArray(nextProps.get_search_home.results) &&
            nextProps.get_search_home.results.length > 0 && unableToSet) {
            this.setState({
                searchHomeData: nextProps.get_search_home.results
            });
        }
        if ((nextProps.search_location_data && Object.keys(nextProps.search_location_data).length > 0) && nextProps.search_location_data !== this.props.search_location_data) {
            let newData = [];
            if (this.props.location.state && nextProps.location.state && this.props.location.state.city && nextProps.location.state.city && this.props.location.state.city === nextProps.location.state.city) {
                newData = [...this.state.searchLocationData, ...nextProps.search_location_data.data];
                this.setState({
                    loader: false, loadMore: false, searchLocationData: newData
                });
            }
        }
    }

    async componentDidMount() {
        this.props.fetch_current_location();
        this.props.clear_search_home();
        let { location } = this.props;
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                this.setState({ search_name: query })
            }
        }

        if (location.state && location.state.city) {
            location.state.start = 0;
            location.state.length = 4;
            this.props.get_search_location(location.state);
            let city = '';
            let county = '';
            let state = '';
            let country = '';
            this.setState({ searchLocation: { ...location.state } });
            let address = location.state.stateName + "," + location.state.city + "," + location.state.country;

            const result = await geocodeByAddress(address);
            result && result[0] && result[0].address_components.map((addr) => {
                if (addr.types[0] === "locality") {
                    //city name
                    city = addr.long_name
                } else if (addr.types[0] === "administrative_area_level_2") {
                    //county name
                    county = addr.long_name
                } else if (addr.types[0] === "administrative_area_level_1") {
                    //state name
                    state = addr.short_name
                } else if (addr.types[0] === "country") {
                    //state name
                    country = addr.short_name
                }
            })
            const latlng = await getLatLng(result[0]);
            const data = {
                "city": city,
                "county": county,
                "state": state,
                "country": country,
                "locality2": "",
                "address_text": address,
                "lat": latlng.lat,
                "lng": latlng.lng
            }
            this.props.add_tempLocation(data);
        }
    }

    handleOnClickCategory = (type, ImageType) => {
        const { search_name, } = this.state
        if (type !== "") {
            this.setState({ content_type: type, contentImage: ImageType });
            if (search_name !== "") {
                this.props.get_searchHome(search_name, type);
            }
        }
    }

    // Change State of input field	
    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        // let location = {};
        const { content_type } = this.state;
        if (name === 'search_name') {
            if (value.length) {

                this.setState(
                    {
                        ...this.state,
                        [name]: value,
                        unableToSet: true,
                    }, () => this.props.get_searchHome(value, content_type));

            } else {
                this.setState(
                    {
                        searchHomeData: [],
                        search_name: '',
                        unableToSet: false,
                    }, () => {
                        this.props.clear_search_home();
                    });
            }
        }
    }

    handleEnabelSearch = () => {
        this.setState((prevState) => ({
            enableSearch: !prevState.enableSearch
        }))
    }

    async componentDidUpdate(prevProps) {
        let { location } = this.props;
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                this.setState({ search_name: query })
            }
        }

        if (prevProps.location && prevProps.location.state && location.state && location.state.city && location.state.city !== prevProps.location.state.city) {
            //this.setState({ loader: true});
            location.state.start = 0;
            location.state.length = 4;
            this.setState({ searchLocation: { ...location.state }, loader: true, loadMore: false, searchLocationData: [] }, () => {
                this.props.get_search_location(location.state);
            });
            let city = '';
            let county = '';
            let state = '';
            let country = '';

            let address = location.state.stateName + "," + location.state.city + "," + location.state.country;

            const result = await geocodeByAddress(address);
            result && result[0] && result[0].address_components.map((addr) => {
                if (addr.types[0] === "locality") {
                    //city name
                    city = addr.long_name
                } else if (addr.types[0] === "administrative_area_level_2") {
                    //county name
                    county = addr.long_name
                } else if (addr.types[0] === "administrative_area_level_1") {
                    //state name
                    state = addr.short_name
                } else if (addr.types[0] === "country") {
                    //state name
                    country = addr.short_name
                }
            })
            const latlng = await getLatLng(result[0]);
            const data = {
                "city": city,
                "county": county,
                "state": state,
                "country": country,
                "locality2": "",
                "address_text": address,
                "lat": latlng.lat,
                "lng": latlng.lng
            }
            this.props.add_tempLocation(data);
        }
    }

    getItemDescription = (item) => {
        let html;
        if (item.content_type.toLowerCase() === 'business') {
            html = <div className="bg-white p-2 flex-fill d-flex flex-column">
                <CardImg className="review-img" top width="100%" src={`${item.listing_logo_pic && item.listing_logo_pic[0] && item.listing_logo_pic[0].url}`} onError={(error) =>
                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                } alt="Card image cap" />
                <CardBody className="px-0 py-2 d-flex flex-column flex-fill">
                    <CardTitle className="mb-2 text-tertiary font-weight-bold">{item.name}</CardTitle>
                    <CardText className="text-dark fs-14 ff-base">
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Address:</span>

                            {item.address_set && item.address_set[0] && (
                                <span className="wb-break-word">
                                    {item.address_set[0].address1 ? <span>{`${item.address_set[0].address1},`}<br /></span> : ''}
                                    {/* {", "} */}
                                    {/* <br/> */}
                                    {item.address_set[0].address2 ? <span>{`${item.address_set[0].address2},`}<br /></span> : ''}
                                    {/* {", "} */}
                                    {/* <br/> */}
                                    {item.address_set[0].city ? `${item.address_set[0].city}, ` : ''}
                                    {item.address_set[0].state ? `${item.address_set[0].state} ` : ''} {item.address_set[0].zipcode ? `${item.address_set[0].zipcode},` : ''}
                                    {/* {","} */}
                                    <br />
                                    {item.address_set[0].country ? `${item.address_set[0].country}` : ''}
                                </span>
                            )}
                        </div>
                        {/* <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Reviews:</span>
                            <span className="wb-break-word">
                                <span>
                                    I came in here today to make a deposit. There was a lime about 6 people long and 2 tellers available. It took about 10 minutes to get to the front. I made my deposit and was given my balance after...
                                </span>
                                <span className="d-block">
                                    <a className="text-reset" href="#">
                                        Continue reading
                                    </a>
                                </span>
                            </span>
                        </div> */}
                    </CardText>
                    <div className="mt-auto">
                        <img className="img-fluid" title={item?.total_rating?.avg_rating && item?.total_rating?.avg_rating[1] ? item?.total_rating?.avg_rating[1] : 'No Rating'} src={item?.total_rating?.avg_rating && (item?.total_rating?.avg_rating[1] !== 'No Rating') ? require(`../../assets/images/icons/${Math.floor(item?.total_rating?.avg_rating[1])}rating.png`) : require("../../assets/images/icons/norating.png")} alt="" />
                        <span> {item?.total_rating?.total_review ? item?.total_rating?.total_review : 0} Review{item?.total_rating?.total_review == 1 ? '' : 's'}</span>
                    </div>
                </CardBody>
            </div>
        } else if (item.content_type.toLowerCase() === 'products') {
            html = <div className="bg-white p-2 flex-fill d-flex flex-column">
                <CardImg className="review-img" top width="100%" src={`${item.listing_profileimage && item.listing_profileimage[0] && item.listing_profileimage[0].url}`} onError={(error) =>
                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                } alt="Card image cap" />
                <CardBody className="px-0 py-2 d-flex flex-column flex-fill">
                    <CardTitle className="mb-2 text-tertiary font-weight-bold">{item.name}</CardTitle>
                    <CardText className="text-dark fs-14 ff-base">
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Categories:</span>
                            {item.taxonomy_list && item.taxonomy_list.length > 0 && (
                                <span className="wb-break-word">
                                    {item.taxonomy_list.map((it, i) => {
                                        if (i !== item.taxonomy_list.length - 1) {
                                            return <span>{`${it},`}</span>
                                        } else {
                                            return <span>{it}</span>;
                                        }
                                    })}
                                </span>
                            )}
                        </div>
                        {/* <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Reviews:</span>
                            <span className="wb-break-word">
                                <span>
                                    I came in here today to make a deposit. There was a lime about 6 people long and 2 tellers available. It took about 10 minutes to get to the front. I made my deposit and was given my balance after...
                                </span>
                                <span className="d-block">
                                    <a className="text-reset" href="#">
                                        Continue reading
                                    </a>
                                </span>
                            </span>
                        </div> */}
                    </CardText>
                    <div className="mt-auto">
                        <img className="img-fluid" title={item?.total_rating?.avg_rating && item?.total_rating?.avg_rating[1] ? item?.total_rating?.avg_rating[1] : 'No Rating'} src={item?.total_rating?.avg_rating && (item?.total_rating?.avg_rating[1] !== 'No Rating') ? require(`../../assets/images/icons/${Math.floor(item?.total_rating?.avg_rating[1])}rating.png`) : require("../../assets/images/icons/norating.png")} alt="" />
                        <span> {item?.total_rating?.total_review ? item?.total_rating?.total_review : 0} Review{item?.total_rating?.total_review == 1 ? '' : 's'}</span>
                    </div>
                </CardBody>
            </div>
        } else if (item.content_type.toLowerCase() === 'movies') {
            html = <div className="bg-white p-2 flex-fill d-flex flex-column">
                <CardImg className="review-img" top width="100%" src={`${item.listing_profileimage && item.listing_profileimage[0] && item.listing_profileimage[0].url}`} onError={(error) =>
                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                } alt="Card image cap" />
                <CardBody className="px-0 py-2 d-flex flex-column flex-fill">
                    <CardTitle className="mb-2 text-tertiary font-weight-bold">{item.name}</CardTitle>
                    <CardText className="text-dark fs-14 ff-base">
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Categories:</span>
                            {item.taxonomy_list && item.taxonomy_list.length > 0 && (
                                <span className="wb-break-word">
                                    {item.taxonomy_list.map((it, i) => {
                                        if (i !== item.taxonomy_list.length - 1) {
                                            return <span>{`${it},`}</span>
                                        } else {
                                            return <span>{it}</span>;
                                        }
                                    })}
                                </span>
                            )}
                        </div>
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Storyline:</span>
                            <span>{item.additional_info && item.additional_info.storyline && item.additional_info.storyline[0] && item.additional_info.storyline[0].value}</span>
                        </div>
                        {/* <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Reviews:</span>
                            <span className="wb-break-word">
                                <span>
                                    I came in here today to make a deposit. There was a lime about 6 people long and 2 tellers available. It took about 10 minutes to get to the front. I made my deposit and was given my balance after...
                                </span>
                                <span className="d-block">
                                    <a className="text-reset" href="#">
                                        Continue reading
                                    </a>
                                </span>
                            </span>
                        </div> */}
                    </CardText>
                    <div className="mt-auto">
                        <img className="img-fluid" title={item?.total_rating?.avg_rating && item?.total_rating?.avg_rating[1] ? item?.total_rating?.avg_rating[1] : 'No Rating'} src={item?.total_rating?.avg_rating && (item?.total_rating?.avg_rating[1] !== 'No Rating') ? require(`../../assets/images/icons/${Math.floor(item?.total_rating?.avg_rating[1])}rating.png`) : require("../../assets/images/icons/norating.png")} alt="" />
                        <span> {item?.total_rating?.total_review ? item?.total_rating?.total_review : 0} Review{item?.total_rating?.total_review == 1 ? '' : 's'}</span>
                    </div>
                </CardBody>
            </div>
        } else if (item.content_type.toLowerCase() === 'actors') {
            html = <div className="bg-white p-2 flex-fill d-flex flex-column">
                <CardImg className="review-img" top width="100%" src={`${item.listing_logo_pic && item.listing_logo_pic[0] && item.listing_logo_pic[0].url}`} onError={(error) =>
                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                } alt="Card image cap" />
                <CardBody className="px-0 py-2 d-flex flex-column flex-fill">
                    <CardTitle className="mb-2 text-tertiary font-weight-bold">{item.name}</CardTitle>
                    <CardText className="text-dark fs-14 ff-base">
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Filmography:</span>
                            <span className="wb-break-word">
                                <Link className="text-reset font-weight-bold" to="#">The Private War</Link>
                                {", "}
                                <Link className="text-reset font-weight-bold" to="#">Dark Places</Link>
                                {", "}
                                <Link className="text-reset font-weight-bold" to="#">The Last Face</Link>
                                {" "}
                                and 9 more.
                            </span>
                        </div>
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Appearances:</span>
                            <span className="wb-break-word">226 Movies</span>
                        </div>
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Reviews:</span>
                            <span className="wb-break-word">
                                <span>
                                    I came in here today to make a deposit. There was a lime about 6 people long and 2 tellers available. It took about 10 minutes to get to the front. I made my deposit and was given my balance after...
                                </span>
                                <span className="d-block">
                                    <a className="text-reset" href="#">
                                        Continue reading
                                    </a>
                                </span>
                            </span>
                        </div>
                    </CardText>
                    <div className="mt-auto">
                        <img className="img-fluid" title={item?.total_rating?.avg_rating && item?.total_rating?.avg_rating[1] ? item?.total_rating?.avg_rating[1] : 'No Rating'} src={item?.total_rating?.avg_rating && (item?.total_rating?.avg_rating[1] !== 'No Rating') ? require(`../../assets/images/icons/${Math.floor(item?.total_rating?.avg_rating[1])}rating.png`) : require("../../assets/images/icons/norating.png")} alt="" />
                        <span> {item?.total_rating?.total_review ? item?.total_rating?.total_review : 0} Review{item?.total_rating?.total_review == 1 ? '' : 's'}</span>
                    </div>
                </CardBody>
            </div>
        } else {
            html = <div className="bg-white p-2 flex-fill d-flex flex-column">
                <CardImg className="review-img" top width="100%" src={`${item.listing_logo_pic && item.listing_logo_pic[0] && item.listing_logo_pic[0].url}`} onError={(error) =>
                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                } alt="Card image cap" />
                <CardBody className="px-0 py-2 d-flex flex-column flex-fill">
                    <CardTitle className="mb-2 text-tertiary font-weight-bold">{item.name}</CardTitle>
                    <CardText className="text-dark fs-14 ff-base">
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Categories:</span>
                            <span className="wb-break-word">Electronics, Toys & Games</span>
                        </div>
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Address:</span>
                            <span className="wb-break-word">
                                Shailesh Gandhi MD 1720 Old Spring House
                                {", "}
                                {/* <br/> */}
                                Ln Ste 305 Atlanta
                                {", "}
                                {/* <br/> */}
                                GA 30338
                                {", "}
                                {/* <br/> */}
                                USA
                            </span>
                        </div>
                        <div className="d-flex flex-wrap mb-2">
                            <span className="font-weight-bold mr-2">Reviews:</span>
                            <span className="wb-break-word">
                                <span>
                                    I came in here today to make a deposit. There was a lime about 6 people long and 2 tellers available. It took about 10 minutes to get to the front. I made my deposit and was given my balance after...
                                </span>
                                <span className="d-block">
                                    <a className="text-reset" href="#">
                                        Continue reading
                                    </a>
                                </span>
                            </span>
                        </div>
                    </CardText>
                    <div className="mt-auto">
                        <img className="img-fluid" title={item?.total_rating?.avg_rating && item?.total_rating?.avg_rating[1] ? item?.total_rating?.avg_rating[1] : 'No Rating'} src={item?.total_rating?.avg_rating && (item?.total_rating?.avg_rating[1] !== 'No Rating') ? require(`../../assets/images/icons/${Math.floor(item?.total_rating?.avg_rating[1])}rating.png`) : require("../../assets/images/icons/norating.png")} alt="" />
                        <span> {item?.total_rating?.total_review ? item?.total_rating?.total_review : 0} Review{item?.total_rating?.total_review == 1 ? '' : 's'}</span>
                    </div>
                </CardBody>
            </div>
        }
        return html;
    }

    handleOnClickAddMore = () => {
        const { searchLocation } = this.state;
        searchLocation.start = searchLocation.start + 4;
        this.props.get_search_location(searchLocation);
        this.setState({ searchLocation, loadMore: true });
    }

    render() {
        const { current_location, search_location_data } = this.props;
        const { searchHomeData, search_name, loader, searchLocationData } = this.state;
        return (
            <div>
                <AppHeader />
                <header className="masthead bg-dark text-white">
                    {/* The video */}
                    <video className="masthead-video" nocontrols="true" autoPlay={true} muted={true} loop={true}>
                        <source src={'https://userdatawikireviews.s3.amazonaws.com/videos/mp4/seq_01_single_compressed.mp4'} type="video/mp4" />
                    </video>
                    <Container className="h-100 my-auto masthead-container">
                        <Row className="h-100 align-items-center justify-content-center text-center">
                            <Col lg={10}>
                                <div className="mb-4">
                                    <img className="img-fluid" src={brandLogo} alt="WikiReviews" />
                                </div>
                                <div>
                                    {/* Dynamic heading below */}
                                    <h2>What Happens In Vegas, Doesn't Have To Stay In Vegas!!</h2>
                                </div>
                                <div className="mb-3 mb-lg-5">
                                    <h1 className=" text-white mb-3">
                                        Find &amp; Create Reviews
                                    </h1>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="my-3">
                                    <div className="d-flex flex-column flex-lg-row flex-wrap bg-white text-dark mx-n2 p-3">
                                        <div className="px-2 mb-2 mb-lg-0">
                                            <div className="h2 mb-0">
                                                Find
                                            </div>
                                        </div>
                                        <div className="px-2 mb-2 mb-lg-0 position-relative">
                                            <div className="d-flex flex-column flex-sm-row mx-n2">
                                                <div className="px-2 mb-2 mb-lg-0">
                                                    <div className="d-flex">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle caret color="dark" className="fs-16">
                                                                All
                                                        </DropdownToggle>
                                                            <DropdownMenu className="menu-white no-caret rounded-0 results-dropdown">
                                                                <DropdownItem type="all" key={"all"} onClick={() => {
                                                                    this.handleOnClickCategory("All", allCatIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={allCatIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>all categories</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="business" key={"business"} onClick={() => {
                                                                    this.handleOnClickCategory("business", businessIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={businessIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Business</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="products" key={"products"} onClick={() => {
                                                                    this.handleOnClickCategory("products", productsIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={productsIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Products</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="movies" key={"movies"} onClick={() => {
                                                                    this.handleOnClickCategory("movies", moviesIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={moviesIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Movies</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="professionals" key={"professionals"} onClick={() => {
                                                                    this.handleOnClickCategory("professionals", professionalIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={professionalIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Professionals</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="actors" key={"actors"} onClick={() => {
                                                                    this.handleOnClickCategory("actors", actorIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={actorIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Actors</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="crp" key={"projects"} onClick={() => {
                                                                    this.handleOnClickCategory("projects", crpIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={crpIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>CRP's</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="people" key={"people"} onClick={() => {
                                                                    this.handleOnClickCategory("people", peopleIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={peopleIcon} width="20" alt="icon" /> */}
                                                                    search only <strong>People</strong>
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        <div className="flex-fill">
                                                            <Input className="primary" type="text" placeholder="Restaurant, Movie..." name="search_name" onChange={this.handleChange} value={search_name} onFocus={() => this.setState({ isSearching: true })} />
                                                        </div>
                                                    </div>
                                                    {/* Instant Search Results */}
                                                    {this.state.isSearching ?
                                                        <ul className="text-left list-unstyled text-dark instant-results bg-white mw-100 shadow" style={{ minWidth: '400px', maxHeight: '400px', overflowY: 'auto' }}>
                                                            {/* Suggested results */}
                                                            {Array.isArray(searchHomeData) && searchHomeData && searchHomeData.length > 0 ? (searchHomeData.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {item._type == "taxonomy" && item._source.index == 0 && (
                                                                            <li>
                                                                                <div className="p-3 fs-14">
                                                                                    <div className="text-muted">
                                                                                        Are you looking for:
                                                                                </div>
                                                                                    <ul className="list-unstyled text-primary font-weight-bold" style={{ maxHeight: '105px', overflowY: 'auto' }}>
                                                                                        {Array.isArray(item._source.main_category) &&
                                                                                            item._source.main_category && item._source.main_category[0].length > 0 ?
                                                                                            (item._source.main_category[0][0].split(",").map((item2, index2) => {
                                                                                                return (
                                                                                                    <li key={index2}>
                                                                                                        <a className="text-reset ff-base" href={"/search?q=" + item2.replace(/\#.+/g, "$'")}>
                                                                                                            {item2.replace(/\#.+/g, "$'")}
                                                                                                        </a>
                                                                                                    </li>
                                                                                                )
                                                                                            })) : (null)
                                                                                        }
                                                                                    </ul>
                                                                                </div>

                                                                            </li>
                                                                        )}
                                                                        {/* featured results */}
                                                                        {item._type == "listing" && (
                                                                            <Link
                                                                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
                                                                                to={{
                                                                                    pathname: `/${(item._source.content_type).toLowerCase()}/${item._source.slug}`,
                                                                                    state: { id: item._source.id }
                                                                                }}>
                                                                                <li className="result-item" role="button" >

                                                                                    <div className="result-link fs-14">
                                                                                        <div className="d-flex">
                                                                                            <div className="mr-3">
                                                                                                <img
                                                                                                    src={

                                                                                                        item._source &&
                                                                                                            item._source.listing_profileimage[0] &&
                                                                                                            item._source.listing_profileimage[0].location ?
                                                                                                            item._source.listing_profileimage[0].location :
                                                                                                            "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"} width={40} alt=""
                                                                                                    onError={(error) =>
                                                                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div>

                                                                                                <span className="font-weight-bold">
                                                                                                    {item._source ? item._source.name : ""}
                                                                                                </span>
                                                                                                <div>
                                                                                                    <img src={"https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png"} alt="" />
                                                                                                </div>
                                                                                                <div className="font-weight-normal lh-1-2">

                                                                                                    {item._source && item._source.category != null ? item._source.category : ""}
                                                                                                    {/* <span>{item._source && item._source.category != null ? item._source.category : ""}</span> */}
                                                                                                </div>
                                                                                                <div className="text-tertiary font-weight-normal mt-1 ff-alt fs-12">
                                                                                                    <span>{item._source && item._source.address.length > 0 ? item._source.address[0].address1 : ''}</span><br />
                                                                                                    <span>{item._source && item._source.address.length > 0 ? item._source.address[0].city + ", " + item._source.address[0].state + ", " + item._source.address[0].zipcode : ""}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </li>
                                                                            </Link>
                                                                        )}
                                                                    </div>
                                                                )
                                                            })) : (null)
                                                            }
                                                        </ul>
                                                        : ""}
                                                </div>
                                                <div className="px-2 mb-2 mb-lg-0 d-flex">
                                                    <div>
                                                        <div className="btn bg-dark text-white fs-16 cursor-default">
                                                            Near
                                                        </div>
                                                    </div>
                                                    <div className="flex-fill">
                                                        {this.state.enableSearch === false ?
                                                            <Input className="primary" type="text" placeholder="CITY...STATE.." value={current_location?.city + ', ' + current_location?.state + ', ' + current_location?.country} onClick={this.handleEnabelSearch} />
                                                            : <PlacementAutoComplete
                                                                handleEnabelSearch={this.handleEnabelSearch}
                                                                current_location={current_location?.city + ', ' + current_location?.state + ', ' + current_location?.country}
                                                            />}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            {search_name != "" ?
                                                (
                                                    // <Button color="grey" size="sm">
                                                    <a href={"/searchOut?q=" + search_name} className="btn btn-primary btn-block mw fs-16 font-weight-bold">SEARCH </a>

                                                    // </Button>
                                                )
                                                :
                                                (<Button color="primary" className="mw fs-16 font-weight-bold" block>SEARCH</Button>)
                                            }

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="10">
                                <div className="mt-3">
                                    <ul className="list-unstyled text-white font-weight-bold d-flex flex-wrap mx-2 mx-lg-n4 justify-content-center">
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="javascript:void(0)">Business</a>
                                        </li>
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="javascript:void(0)">Movies</a>
                                        </li>
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="javascript:void(0)">Products</a>
                                        </li>
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="javascript:void(0)">Professionals</a>
                                        </li>
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="javascript:void(0)">Actors</a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </header>

                <section className="page-section">
                    <Container>
                        <div className="text-center">
                            <h2 className="section-title text-secondary">Hot & New Businesses</h2>
                            {/*
                            <ul className="list-unstyled text-dark font-weight-bold fs-18 d-flex flex-wrap mx-2 mx-lg-n4 justify-content-center mb-4">
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link p-0 filterable active">All</button>
                                </li>
                                 <li className="px-2 px-lg-4">
                                    <button className="btn btn-link p-0 filterable">Business</button>
                                </li>
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link p-0 filterable">Movies</button>
                                </li>
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link p-0 filterable">Products</button>
                                </li>
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link p-0 filterable">Professionals</button>
                                </li>
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link p-0 filterable">Actors</button>
                                </li> 
                            </ul>*/}
                            <Alert color="primary" hidden={!loader}>
                                Loading...
                            </Alert>
                        </div>

                        {/* Carousel ends */}
                        {!loader && searchLocationData && searchLocationData.length > 0 && (
                            <>
                                <Row sm={2} md={3} lg={4}>
                                    {searchLocationData.map((it, i) => {
                                        return (
                                            <Col>
                                                <Card className="p-0 bg-transparent h-100 ff-base" key={it.id}>
                                                    {this.getItemDescription(it)}
                                                    <div className="d-flex align-items-center mx-n2 py-3">
                                                        {/* <div className="px-2">
                                                <img className="user-avatar" src={require('../../assets/images/icons/user-avatar.png')} alt="" />
                                            </div>
                                            <div className="px-2">
                                                <div className="text-dark font-weight-bold">
                                                    Todd Toddler
                                                    </div>
                                            </div> */}
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>
                                <div className="text-center" hidden={search_location_data && search_location_data.data && search_location_data.data.length < 4}>
                                    <Button color="outline-primary" onClick={() => this.handleOnClickAddMore()}>
                                        <span hidden={this.state.loadMore}><FontAwesomeIcon icon="arrow-down" /> Load More</span>
                                        <span hidden={!this.state.loadMore}><FontAwesomeIcon icon="circle-notch" className="fa-spin" /> Loading...</span>
                                    </Button>
                                </div>
                            </>
                        )}
                        {/* Carousel ends */}
                        {search_location_data && search_location_data.data && search_location_data.data.length == 0 && (
                            <div>
                                No results found
                            </div>
                        )}

                    </Container>
                </section>

                <Footer />
                {this.state.isSearching ?
                    <div className="search-mask" onClick={() => this.setState({ isSearching: false })}>&nbsp;</div>
                    : ''
                }
            </div>
        )
    }
}

const mapState = (state) => ({
    current_location: state.home.current_location,
    get_search_home: state.home.get_search_home,
    search_location_data: state.home.search_location,
});

const mapProps = (dispatch) => ({
    fetch_current_location: () => dispatch(fetch_current_location()),
    add_tempLocation: (data) => dispatch(add_tempLocation(data)),
    get_searchHome: (query, contentType) => dispatch(get_searchHome(query, contentType)),
    clear_search_home: () => dispatch(clear_search_home()),
    get_search_location: (data) => dispatch(get_search_location(data)),
});
export default connect(mapState, mapProps)(HomepageCity);
