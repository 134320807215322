import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Button, FormGroup, Input, Label, Form } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from "../../atoms/editBtn";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {  get_tips_warning, add_tips_warning, edit_tips_warning } from './../../../actions/buyersGuide';
import { Link } from 'react-router-dom';

class TipsAndWarning extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tipsText: '',
            isEnable: false,
            toggleTips: false,
            toggleWarning: false,
            warningText: '',
            isWarningEnable: false,
            entryId: null,
            editTip: false,
            editWarning: false,
            detailList: null,
            warDetailList:null,
            editForm: false,

        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.tips_war_list && nextProps.tips_war_list.tips_list){
            this.setState({detailList: nextProps.tips_war_list.tips_list})
        }
        if(nextProps.tips_war_list && nextProps.tips_war_list.warning_list){
            this.setState({warDetailList: nextProps.tips_war_list.warning_list})
        }

    }
    componentDidMount(){
        let props = this.props;
     
    }

    deleteEntry = (id, type) => {
        if (id && type) {
            let text = 'Are you sure want to delete?';
            if (type == 'tip') {
                this.props.toggleConfirmModal({ text, uniqueKey: 'delete-product-tips', id });
            }
            if (type == 'warning') {
                this.props.toggleConfirmModal({ text, uniqueKey: 'delete-product-warning', id });
            }
        }
    }

    handleChange = (e) => {
        let text = e.target.value;
        if (text !== '') {
            this.setState({ tipsText: text, isEnable: true })
        } else {
            this.setState({ tipsText: text, isEnable: false })
        }
    }

    handleWarning = (e) => {
        let text = e.target.value;
        if (text !== '') {
            this.setState({ warningText: text, isWarningEnable: true })
        } else {

            this.setState({ warningText: text, isWarningEnable: false })
        }
    }

    submitTips = (type) => {
        let taxonomy_id = this.props.taxonomy_id;
        if(type == "Edit"){
            let editData={ text: this.state.tipsText, type : 'tip', is_masked: true };
            if (editData.text !== '' && editData.type == 'tip') {
                this.props.edit_tips_warning(editData,this.state.entryId, '', taxonomy_id);
                this.setState({ tipsText: '', toggleTips: false, editForm: false, editTip: false, isEnable: false })
            }
        } else{
            let data = { text : this.state.tipsText, type : 'tip', taxonomy: taxonomy_id};
            this.props.add_tips_warning(data, '', taxonomy_id);
            this.setState({ tipsText: '', toggleTips: false, isEnable: false })
        }
    }

    submitWarning = (type) => {
        let taxonomy_id = this.props.taxonomy_id;
        if(type == "Edit"){
            let editData={
                text: this.state.warningText,
                type : 'warning',
                is_masked: true,
            };
            if (editData.text !== '' && editData.type == 'warning') {
                this.props.edit_tips_warning(editData,this.state.entryId, '', taxonomy_id);
                this.setState({ warningText: '', toggleWarning: false, editForm: false, editWarning: false, isWarningEnable: false })
            }
        }else{
            let data={
                text : this.state.warningText,
                type : 'warning',
                taxonomy : taxonomy_id
            };
            let name = '';
            this.props.add_tips_warning(data, name, taxonomy_id);
            this.setState({ warningText: '', toggleWarning: false, isWarningEnable: false })
        }
    };

    toggleTips = () => {
        let toggle = this.state.toggleTips;

        if (!toggle) {
            this.setState({ toggleTips: !this.state.toggleTips, tipsText: '' })
        }
        else {
            this.setState({ toggleTips: false, tipsText: '', editTip: false, isEnable: false, editForm: false })
        }
    }
    toggleWarning = () => {
        let toggle = this.state.toggleWarning;

        if (!toggle) {
            this.setState({ toggleWarning: !this.state.toggleWarning, warningText: '' })
        }
        else {
            this.setState({ toggleWarning: false, warningText: '', editWarning: false, isWarningEnable: false, editForm: false})
        }
    }

    editText = (text, type, id) => {
        if (text !== '' && type == 'tip' && !this.state.is_masked) {
            this.setState({ toggleTips: true, tipsText: text, isEnable: true, is_masked: true, entryId: id, editTip: true })
        }
        else if (text !== '' && type == 'warning' && !this.state.is_masked) {
            this.setState({ toggleWarning: true, warningText: text, isWarningEnable: true, is_masked: true, entryId: id, editWarning: true })
        }
        else {
            this.setState({ toggleWarning: false, toggleTips: false, is_masked: false })
        }
    }



    render() {
        let profileId = this.props.my_profile_info;
        let { toggleTips, tipsText, isEnable, toggleWarning, warningText, isWarningEnable, warDetailList,editForm, editTip, editWarning, detailList } = this.state;

        const pageType = this.props.location.pathname.split("/")[1]; 
        const headerText = (pageType === "business") ? "Do you have any tips to offer? Any warnings concerning this business? List away!" : ((pageType === "product") ? 'Do you have any tips to offer for current or prospective owners? Any warnings concerning purchase or ownership of this product? List away!' : "Do you have any tips to offer? Any warnings concerning this movie? List away!");

        return (
            <div className="bg-white-shaded-md text-secondary p-3 mb-3">
                <h2 className="mr-2">Tips &amp; Warnings</h2>
                <hr className="bg-white" />
                <div className="mb-3">
                    <p className="text-body ff-base lh-1-3">
                        {headerText}
                    </p>
                </div>

                <CollapseBasic title="Tips" noHr={true} containerClass="bg-transparent type-secondary" bodyClass="mt-3 text-secondary" isOpen={true} size="sm">
                    <ul className="list-unstyled">
                        {detailList && Array.isArray(detailList) && detailList.length > 0 ?
                            detailList.map((item, index) => {
                                if (item.type == "tip") {
                                    return (<li className="mb-2" key={index}>
                                            <div className="border border-primary p-2">
                                                <div className="mb-2 position-relative">
                                                    {/* If spoiler, sent data-blurr={true} */}
                                                    <div className="text-secondary-dark font-weight-medium wb-break-word" >
                                                        {index+1}{'. '}{item.text}
                                                    </div>
                                                </div>
                                                <div className="d-flex mx-n1">
                                                    <div className="px-1">
                                                        {item?.profile_pic ?
                                                            <Link
                                                                to={{ pathname: `/people/${item.profile_url}` }}
                                                            >
                                                                <img className="img-circle _30x30" src={item.profile_pic} alt="" onError={(error) =>
                                                                    (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                                } />
                                                            </Link>
                                                            :
                                                            <img className="img-circle _30x30" src={require("./../../../assets/images/icons/user-circle.png")} alt="" />
                                                        }

                                                    </div>
                                                    <div className="px-1 col">
                                                        <span className="text-secondary-dark">by</span> <Link
                                                        to={{ pathname: `/people/${item.profile_url}` }}
                                                        className="font-weight-bold">{item.username}</Link>
                                                        <br />
                                                        {item.created_on}
                                                    </div>
                                                    {profileId && profileId.id
                                                        ?
                                                        <div className="px-1">
                                                            <EditBtn color="light" onClick={() => this.editText(item.text, item.type, item.id)} />
                                                            <DeleteBtn color="light" onClick={() => this.deleteEntry(item.id, item.type)} />
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            })
                            :
                            ""}
                    </ul>

                    <div className="text-right mb-2">
                        <Button color="link" className="p-0 text-secondary-dark font-weight-bold" onClick={this.toggleTips}>
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                            Add another
                        </Button>
                    </div>
                    {toggleTips ?
                        <div className="bg-white p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">{editTip ? "Edit Your Tips" : "Add"}</h5>
                            <Form>
                                <FormGroup>
                                    <Label className="text-secondary font-weight-bold">Enter Content <span className="text-danger">*</span></Label>
                                    <Input value={tipsText} onChange={this.handleChange} className="primary" bsSize="sm" type="textarea" placeholder="Please add your tip..." />
                                </FormGroup>

                                <div className="text-right mt-3">
                                    <Button className="mw" color="primary" size="sm" disabled={!isEnable} onClick={() => {
                                        if (!editTip) {
                                            this.submitTips();
                                        } else {
                                            this.submitTips("Edit");
                                        }
                                    }}>Submit</Button>
                                    <Button className="mw" color="primary" size="sm" onClick={this.toggleTips}>Cancel</Button>
                                </div>
                            </Form>
                        </div>

                        :
                        ""}

                </CollapseBasic>

                <hr className="bg-white" />
                <CollapseBasic title="Warnings" noHr={true} containerClass="bg-transparent type-secondary" bodyClass="mt-3 text-secondary" isOpen={true} size="sm">
                    <ul className="list-unstyled">
                        {warDetailList && Array.isArray(warDetailList) && warDetailList.length > 0 ?
                            warDetailList.map((item, index) => {
                                if (item.type == "warning") {
                                    return (<li className="mb-2" key={index}>
                                            <div className="border border-primary p-2">
                                                <div className="mb-2 position-relative">
                                                    {/* If spoiler, sent data-blurr={true} */}
                                                    <div className="text-secondary-dark font-weight-medium wb-break-word" >
                                                        {index+1}{'. '}{item.text}
                                                    </div>
                                                </div>
                                                <div className="d-flex mx-n1">
                                                    <div className="px-1">
                                                        {item?.profile_pic ?
                                                            <Link
                                                                to={{ pathname: `/people/${item.profile_url}` }}
                                                            >
                                                                <img className="img-circle _30x30" src={item.profile_pic} alt="" onError={(error) =>
                                                                    (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                                } />
                                                            </Link>
                                                            :
                                                            <img className="img-circle _30x30" src={require("./../../../assets/images/icons/user-circle.png")} alt="" />
                                                        }

                                                    </div>
                                                    <div className="px-1 col">
                                                        <span className="text-secondary-dark">by</span> <Link
                                                        to={{ pathname: `/people/${item.profile_url}` }}
                                                        className="font-weight-bold">{item.username}</Link>
                                                        <br />
                                                        {item.created_on}
                                                    </div>
                                                    {profileId && profileId.id
                                                        ?
                                                        <div className="px-1">
                                                            <EditBtn color="light" onClick={() => this.editText(item.text, item.type, item.id)} />
                                                            <DeleteBtn color="light" onClick={() => this.deleteEntry(item.id, item.type)} />
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            })
                            :
                            ""}


                    </ul>

                    <div className="text-right mb-2">
                        <Button color="link" className="p-0 text-secondary-dark font-weight-bold" onClick={this.toggleWarning}>
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                            Add another
                        </Button>
                    </div>
                    {toggleWarning ?

                        <div className="bg-white p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">{editWarning ? "Edit Your Warning" : "Add Another"}</h5>
                            <Form>
                                <FormGroup>
                                    <Input value={warningText} onChange={this.handleWarning} className="primary" bsSize="sm" type="textarea" placeholder="Please add your warning..." />
                                </FormGroup>

                                <div className="text-right mt-3">
                                    <Button className="mw" color="primary" size="sm" disabled={!isWarningEnable}
                                            onClick={() => {
                                                if (!editWarning) {
                                                    this.submitWarning();
                                                } else {
                                                    this.submitWarning("Edit");
                                                }
                                            }} >Submit</Button>
                                    <Button onClick={this.toggleWarning} className="mw" color="primary" size="sm">Cancel</Button>
                                </div>
                            </Form>
                        </div>
                        :
                        ""
                    }
                </CollapseBasic>
            </div>

        )
    }
}
const mapState = (state) => ({
    tips_war_list: state.buyersGuide.fetch_tips_war_list,
    my_profile_info: state.user.my_profile
});

const mapProps = (dispatch) => ({
    get_tips_warning: (type) => dispatch(get_tips_warning(type)),
    add_tips_warning: (data, name, taxonomy_id) => dispatch(add_tips_warning(data, name, taxonomy_id)),
    edit_tips_warning: (data, id, name, taxonomy_id) => dispatch(edit_tips_warning(data, id, name, taxonomy_id)),
});


export default withRouter(connect(mapState, mapProps)(TipsAndWarning));
