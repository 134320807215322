import React, { Component } from "react";
import Reactions from "../../molecules/homepage/Reactions";

export default class WasHelpfulAndReactions extends Component {
    render() {
        const { item, handleFeedReactions, listing_type, post_review_group, } = this.props;
        const profileId = +localStorage.getItem("profileId")

        return (
            <React.Fragment>               

                <div className="text-tertiary mb-3">
                    <div>
                        <i className="d-inline-block mr-2">
                            {'Was this review helpful?'}
                        </i>
						<span
                            className={
                                item.helpfulCount?.profile_ids?.includes(profileId)
                                    ? "font-weight-bold text-dark d-inline-block"
                                    : "font-weight-bold text-tertiary d-inline-block"
                            }
                            role="button"
                            onClick={() => {
                                this.props.post_review_group(
                                    item?.associated_to?.id,
                                    { type: "helpful", review: item.id },
                                    listing_type
                                );
                                this.props.handleReFetchCallAble('new');
                            }}>
                            {"Yes"}
                        </span>
                        <span className="mx-1">-</span>
                        <span
                            href="#"
                            className={
                                !item.helpfulCount?.profile_ids?.includes(profileId)
                                    ? "font-weight-bold text-dark d-inline-block"
                                    : "font-weight-bold text-tertiary d-inline-block"
                            }
                            role="button"
                            onClick={() => {
                                post_review_group(
                                    item?.associated_to?.id,
                                    { type: "not_helpful", review: item.id },
                                    listing_type
                                );
                                this.props.handleReFetchCallAble('new');
                            }}>
                            {"No"}
                        </span>
                    </div>
                    {item?.helpfulCount?.helpful > 0 &&
                        item?.helpfulCount?.total > 0 ? (
                            <div>
                                <span className="font-weight-bold text-dark">
                                    {`${item?.helpfulCount?.helpful} of ${item?.helpfulCount?.total}`}
                                </span>
                                <i className="text-tertiary d-inline-block ml-2">
                                    {"people found this review as helpful"}
                                </i>
                            </div>
                        ) : (
                            <div>
                                <span className="font-weight-bold text-dark">{`${item?.helpfulCount?.helpful} `}</span>
                                <i className="text-tertiary d-inline-block ml-2">
                                    {"people found this review as helpful"}
                                </i>
                            </div>
                        )}
                </div>

            </React.Fragment>
        )
    }
}
