/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { connect } from "react-redux";
// import { AvForm, AvField } from 'availity-reactstrap-validation';
// import moment from 'moment';
// import { websiteRegex } from '../../../utils/validation';
// import { callApi } from '../../../utils/apiCaller';
import {  Container, Row, Col, FormGroup, Button, Input, InputGroup, InputGroupAddon,  } from 'reactstrap';
import { getReviewSuffix } from '../../../utils/utilities';
import { Link } from 'react-router-dom';
// import { update_payment_options, add_menu, get_menu_item, get_section_menu_item, update_business_detail, add_hours_of_operations, get_media_children, add_media_to_gallery } from '../../../actions/business';
// import { update_detail, add_social_website, delete_listing_Info } from '../../../actions/itemDetail';
// import { get_album_types_list, get_album_type_data, delete_selected_gallery_media} from "../../../actions/user";
// import EditBtn from "../../atoms/editBtn";
// import DeleteBtn from "../../atoms/deleteBtn";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            about: null,
            tempIcon: null,
            editName: false,
            phoneSet: [],
            emailSet: [],
            websiteSet: [],
            email: null,
            pageNo: 1,
			readMore:false,
			dentId:0,
			sepicalities:[],
			insurances:[],
			conditions:[],
			languages:[],
		}
    }


    componentWillReceiveProps(nextProps) {
        const { dentistDetail,dentistId} = nextProps;
    
        
        if (dentistDetail) {
			this.setState({
                about: dentistDetail,
                phoneSet: dentistDetail?.professionalphone_set,
                emailSet: dentistDetail?.professionalemail_set,
                websiteSet: dentistDetail?.professionalwebsite_set,
				sepicalities:dentistDetail.additional_info !==null && dentistDetail?.additional_info.Specialties ? dentistDetail.additional_info.Specialties : [],
				insurances: dentistDetail.additional_info !==null && dentistDetail?.additional_info.Insurances ? dentistDetail.additional_info.Insurances : [],
				conditions: dentistDetail.additional_info !==null && dentistDetail?.additional_info.Conditions ? dentistDetail.additional_info.Conditions : [],
				languages:dentistDetail.additional_info !==null && dentistDetail?.additional_info.Languages ? dentistDetail.additional_info.Languages : [],
             });
        }
		if(dentistId){
			this.setState({
                dentId: dentistId,
            });
		}
    }
	
	/**
    * Function to phone number format
    * 
    */

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    }
   
    render() {
        const {
            about,
            phoneSet,
            tempIcon,
            emailSet,
			modelSet,
			sepicalities,
			insurances,
			conditions,
			languages,
			websiteSet
		} = this.state;
		let avg_rating = about && about?.total_rating && about?.total_rating.avg_rating[0] ? about.total_rating.avg_rating[0] : 'norating.png';
        return (
            <div className="text-center">
                <Container className="position-relative">
                    <div className={'text-center position-relative about-view ' + `${!this.state.toggleMoreLessInfo ? '_mini' : ''}`}>
                        <div className="wr-movie-top-about d-flex flex-column">
                            <div className="wr-movie-top-about-body">
                                <Row>
                                    <Col>
                                        <div className="wr-movie-product-info-top d-flex flex-wrap">
                                            <div className="wr-movie-product-info-top-left">
                                                <img
                                                    src={`${about && about?.professional_profileimage && about?.professional_profileimage.length > 0 && about?.professional_profileimage[0] && about?.professional_profileimage[0].url}`}
                                                    className="img-fluid"
                                                    onError={(error) =>
                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/dentist-neutral-472x446.jpg`)
                                                    }
                                                    alt={`${about && about?.professional_profileimage && about?.professional_profileimage.length > 0 && about?.professional_profileimage[0] && about.professional_profileimage[0].caption ? about.professional_profileimage[0].caption : 'no-image'}`}
                                                />
                                            </div>
                                            <div className="wr-movie-product-info-top-right">
                                                <div className="wr-category-heading">
                                                    <h4>{about && about.firstname} {about && about.lastname}</h4>
                                                    <div hidden>
                                                        <InputGroup size="sm">
                                                            <Input type="text" placeholder="Enter Name" />
                                                            <InputGroupAddon addonType="append">
                                                                <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </div>
                                                    <div className="ff-alt fs-14 text-dark">
                                                        Specializes in
                                                        <br />
                                                        {about && about.taxonomy_dict && about.taxonomy_dict.length > 0 ?
                                                            <>{about.taxonomy_dict.map((specialize, index) => {
                                                                if (specialize.category && specialize.id != about.content_type) {
                                                                    return <span className="font-weight-bold" key={index}>
                                                                        {specialize.category},
                                                                    </span>
                                                                }
                                                            })}</> : ""
                                                        }
                                                    </div>
                                                    <p className="fs-14">
                                                        <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                                                            className="img-fluid"
                                                            onError={(error) =>
                                                                (error.target.src = `../../../assets/images/norating.png`)
                                                            }
                                                            alt={`${about && about.total_rating && about.total_rating.avg_rating[1] ? about.total_rating.avg_rating[1] : 'no-rating'}`}

                                                        /> {(about && about.total_rating && about.total_rating.total_review) ? about.total_rating.total_review : 0} {getReviewSuffix(about?.total_rating?.total_review)}</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="wr-movie-product-info-bottom wr-business-product-info-bottom">
                                            <ul className="m-0 p-0">
                                                <li className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <span className="fs-14 business-icon _map"></span>
                                                    </div>
                                                    <div className="px-2 col">
                                                        <span className="fs-14 font-weight-bold">
                                                            {about && about.address && about.address.length > 0 && about.address[0].name ? about.address[0].name : ""} {about && about.address && about.address.length > 0 && about.address[0].address1 ? about.address[0].address1 : ""}
                                                            {about && about.address && about.address.length > 0 && (about.address[0].name || about.address[0].address1) ? <br /> : ''}
                                                            {about && about.address && about.address.length > 0 && about.address[0].city ? about.address[0].city + "," : ""} {about && about.address && about.address.length > 0 && about.address[0].state ? about.address[0].state + "," : ""} {about && about.address && about.address.length > 0 && about.address[0].zipcode ? about.address[0].zipcode + "," : ""}
                                                            {about && about.address && about.address.length > 0 && (about.address[0].city || about.address[0].state || about.address[0].zipcode) ? ' ' : ' '}
                                                            <span className="text-uppercase">
                                                                {about && about.address && about.address.length > 0 && about.address[0].country ? about.address[0].country : ""}
                                                            </span>

                                                        </span>
                                                    </div>
                                                </li>
                                                <li className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <span className="fs-14 business-icon _phone"></span>
                                                    </div>
                                                    <div className="px-2 col">
                                                        {phoneSet && phoneSet.length > 0 ?
                                                            <>{phoneSet.map((phoneData, index) => {
                                                                return (
                                                                    <span className="fs-14" key={index}> <span className="font-weight-bold">
                                                                        {phoneData ? phoneData.label === "mob" && phoneData.label === "tel" ? phoneData : this.formatPhoneNumber(phoneData.phone) : ""}</span>{" "}<span> {phoneData ? <>({phoneData.label})</> : ""}</span></span>
                                                                )
                                                            })}</> : ""
                                                        }
                                                        <div hidden>
                                                            <InputGroup size="sm">
                                                                <Input type="text" placeholder="Enter Valid Phone" />
                                                                <InputGroupAddon addonType="append">
                                                                    <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>
                                                    </div>

                                                </li>
                                                <li className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <span className="fs-14 business-icon _email"></span>
                                                    </div>
                                                    {
                                                        emailSet && emailSet.length > 0 ?
                                                            <>
                                                                {
                                                                    emailSet.map((emailData, index) => {
                                                                        return (
                                                                            <span className="fs-14 font-weight-bold" key={index}>
                                                                                {emailData ? emailData.email : ""} {""} <span>{emailData ? <>({emailData.email_type})</> : ""}</span>

                                                                            </span>
                                                                        )

                                                                    })

                                                                }
                                                            </> : ""

                                                    }
                                                    <div className="px-2 col">
                                                        <span className="fs-14 font-weight-bold" role="button" hidden>Click to add</span>
                                                        <div hidden>
                                                            <FormGroup>
                                                                <Input className="primary" bsSize="sm" type="text" />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className="mt-2 p-0">
                                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                                    <div className="px-2 flex-90">
                                                        <span className="fs-14">Specialities:</span>
                                                    </div>
                                                    <div className="col px-2">
                                                        {sepicalities && sepicalities.length > 0 ?
                                                            <>{sepicalities.map((specialize, index) => {
                                                                return (<span className="fs-14 font-weight-bold" key={index}>
                                                                    {specialize.value},
                                                                </span>)

                                                            })}</> : ""
                                                        }
                                                        <div hidden>
                                                            <InputGroup size="sm">
                                                                <Input type="text" placeholder="Enter model number" />
                                                                <InputGroupAddon addonType="append">
                                                                    <Button color="light" title="Update">
                                                                        <FontAwesomeIcon icon="check" fixedWidth />
                                                                    </Button>
                                                                    <Button color="light" title="Cancel" className="mx-0">
                                                                        <FontAwesomeIcon icon="times" fixedWidth />
                                                                    </Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                                    <div className="px-2 flex-90">
                                                        <span className="fs-14">Gender:</span>
                                                    </div>
                                                    <div className="col px-2">
                                                        <span className="fs-14 font-weight-bold"> {about && about?.gender} </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                    {this.state.toggleMoreLessInfo ?
                                        <Col md={8}>
                                            <Row>
                                                <Col>
                                                    <div className="wr-category-heading">
                                                        {about && about.total_rating && Object.keys(about.total_rating).length > 0 ?

                                                            about && Object.keys(about.total_rating).map(rating => {
                                                                if (rating !== "total_review" && rating !== "avg_rating") {
                                                                    return <p className="fs-14"><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${about.total_rating[rating][0]}`} className="img-fluid" alt={rating || ""} /> {rating}</p>
                                                                }
                                                            }) : ''}
                                                        <div className="wr-movie-product-info-bottom">
                                                            <ul className="list-unstyled row">
                                                                <li className="col-md-6 d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 font-weight-bold">Accepted Insurances:</span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <ul className="fs-14 list-normal">
                                                                            {insurances && insurances.length > 0 ?
                                                                                <>{insurances.map((insurance, index) => {
                                                                                    return (
                                                                                        <li className="mb-1" key={index}>
                                                                                            <span className="mr-1">
                                                                                                {insurance.value},
                                                                                            </span>
                                                                                            <span hidden>
                                                                                                <input type="text" placeholder="Enter information" />
                                                                                            </span>
                                                                                        </li>
                                                                                    )
                                                                                })}</> : ""
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                                <li className="col-md-6 d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 font-weight-bold">Language Spoken:</span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <ul className="fs-14 list-normal">
                                                                            {languages && languages.length > 0 ?
                                                                                <>{languages.map((language, index) => {
                                                                                    return (
                                                                                        <li className="mb-1" key={index}>
                                                                                            <span className="mr-1 editable-column">
                                                                                                {language.value},
                                                                                            </span>
                                                                                            <span hidden>
                                                                                                <input type="text" placeholder="Enter information" />
                                                                                            </span>
                                                                                        </li>
                                                                                    )
                                                                                })}</> : ""
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                                <li className="col-md-6 d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2" >
                                                                        <span className="fs-14 font-weight-bold">Conditions Dr. Treats:</span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <ul className="fs-14 list-normal">
                                                                            {conditions && conditions.length > 0 ?
                                                                                <>{conditions.map((condition, index) => {
                                                                                    return (
                                                                                        <li className="mb-1" key={index}>
                                                                                            <span className="mr-1">
                                                                                                {condition.value},
                                                                                            </span>
                                                                                            <span hidden>
                                                                                                <input type="text" placeholder="Enter information" />
                                                                                            </span>
                                                                                        </li>
                                                                                    )
                                                                                })}</> : ""
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                                <li className="col-md-6 d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 font-weight-bold">Website:</span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <span className="fs-14 font-weight-bold">
                                                                            {websiteSet && websiteSet.length > 0 ?
                                                                                <>{websiteSet.map((site, index) => {
                                                                                    if (site.website_type === 'Main') {
                                                                                        return <Link className="wb-break-word" target="_blank" to={
                                                                                            {
                                                                                                pathname: "/abouttransition/",
                                                                                                search: `?page_url=${site.website}`
                                                                                            }
                                                                                        } key={index}>
                                                                                            {site ? site.website_type === 'Facebook' ? (<span><i class="fa fa-facebook" aria-hidden="true"></i></span>) : ("") ||
                                                                                                site.website_type === 'Google+' ? (<span><i class="fa fa-google-plus" aria-hidden="true"></i></span>) : ("") ||
                                                                                                    site.website_type === 'Twitter' ? (<span><i class="fa fa-twitter" aria-hidden="true"></i></span>) : ("") ||
                                                                                                        site.website_type === 'Instagram' ? (<span><i class="fa fa-instagram" aria-hidden="true"></i></span>) : ("") ||
                                                                                                            site.website_type === 'LinkedIn' ? (<span><i class="fa fa-linkedin" aria-hidden="true"></i></span>) : ("") : ""}||
                                                                                        </Link>
                                                                                    }
                                                                                })}</> : ""
                                                                            }
                                                                        </span>
                                                                        <span className="fs-14 font-weight-bold editable-column" hidden>Click to add</span>

                                                                        <div hidden>
                                                                            <InputGroup size="sm">
                                                                                <Input type="url" placeholder="Enter valid Website" />
                                                                                <InputGroupAddon addonType="append">
                                                                                    <Button color="light" title="Update">
                                                                                        <FontAwesomeIcon icon="check" fixedWidth />
                                                                                    </Button>
                                                                                    <Button color="light" title="Cancel" className="mx-0">
                                                                                        <FontAwesomeIcon icon="times" fixedWidth />
                                                                                    </Button>
                                                                                </InputGroupAddon>
                                                                            </InputGroup>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        :
                                        ''
                                    }
                                </Row>
                            </div>
                            <div className="mt-auto ml-auto wr-movie-less-info-about">
                                <span role="button" onMouseEnter={() => this.setState({ toggleMoreLessInfo: !this.state.toggleMoreLessInfo })}>
                                    {this.state.toggleMoreLessInfo ? <><span className="text">less info</span><span className="icon-arrow-left"></span></> : <><span className="text">more info</span><span className="icon-arrow-right"></span></>}
                                </span>
                            </div>
                        </div>
                        </div>
                        <div className="floated-info-section d-flex align-items-center flex-wrap justify-content-center">
                            <div className="image-area-show">

                                <img src={`${about && about.media_dict && about.media_dict.length > 0 && Array.isArray(about.media_dict) && about?.media_dict[0] && about.media_dict[0].url}`}
                                    className="img-fluid"
                                    onError={(error) =>
                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/dentist-neutral-472x446.jpg`)
                                    }
                                    alt={`${about && about?.media_dict && about?.media_dict.length > 0 && about?.media_dict[0] && about.media_dict[0].caption ? about.media_dict[0].caption : 'no-image'}`}
                                />
                            </div>
                            <div className="image-area-crop fs-14">
                                Help us to make WikiReviews a better place! <br /> Please upload an alternate image. Suggested Size 1600x1050.
                            </div>
                        </div>
                   
                </Container>
                            </div>

        )
    }
}



export default About;