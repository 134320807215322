import React, { useEffect, useRef, useState } from 'react';
import {
    Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane,
    FormGroup, Button, Modal, ModalHeader, ModalBody, UncontrolledDropdown,
    DropdownToggle, DropdownMenu, Input, DropdownItem, Label, 
    Progress, Dropdown
} from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-multi-carousel';
import { callApi } from '../../../../utils/apiCaller';
import { Link } from 'react-router-dom';
import {
    get_media_children, add_media_to_gallery, fetch_sub_categories, fetch_categories_data,
    filter_categories_data, clear_category, clear_subcategory
} from '../../../../actions/business';
import { get_album_types_list, get_album_type_data, delete_selected_gallery_media } from '../../../../actions/user';
import { upload_add_captions } from "../../../../actions/myProfiles";
import EndOfGallery from '../../../atoms/endOfGallery';
import StarsRating from 'stars-rating';
import { get_category_taxonomy_listing, update_caption_and_categories } from '../../../../actions/itemDetail';
import { setUploadPercentage } from '../../../../actions/shared';

const responsive = {
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1
        }
    },
    viewGalleryImages: {
        desktop: {
            breakpoint: { max: 9999999, min: 1200 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1199, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1
        }
    }
};


const Gallery = ({categoryType,...props}) => {
    const images_children=useSelector((state)=>state.business.images_children);
    const videos_children=useSelector((state)=>state.business.videos_children);
    const album_types_list=useSelector((state)=>state.user.album_types_list);
    const album_type_data=useSelector((state)=>state.user.album_type_data);
    const fetch_taxonomy_listData=useSelector((state)=>state.myProfile.fetch_taxonomy_listData);
    const new_sub_categories=useSelector((state)=>state.business.new_sub_categories);
    const fetch_data_categories=useSelector((state)=>state.business.fetch_data_categories);
    const filter_data_category=useSelector((state)=>state.business.filter_data_category);
    const my_profile=useSelector((state)=>state.user.my_profile);
    const cat_taxonomy_list=useSelector((state)=>state.item_detail.cat_taxonomy_list);
    const uploadPercentage = useSelector(state=>state.shared.uploadPercentage)
    const initialState = {
        viewMediaModalToggle: false,
        carouselMedia: null,
        activeTab: 'all',
        uploadMedia: {
            mediaType: "image",
            albumType: "",
            pageNo: 1,
            albumTypesList: [],
            albumTypeData: {},
            selectedMedia: [],
            selectedMediaIds: [],
            embedLinks: {},
            uploadFiles: [],
            uploadedFiles: [],
            progress: 0,
        },
        fetchGalleryAlbum: "",
        fetchGalleryScroll: [],
        fetchGalleryCount: 0,
        uploadMediaPostModal: false,
        selectedUpMediaType: 'upload',
        showGalleryType: 'images',
        albumType: '',
        pageNo: 1,
        //weatherData: {},
        secondCarouselMedia: [],
        secondaryCategoryTypeTab:'',
        mediaRating:{},
        details: null,
        uploadCaptions: {},
        fetchTaxonomyListing: [],
        fetchTaxomomyCategory: "",
        fetchTaxonomyListingImages: [],
        fetchTaxonomyListingVideos: [],
        taxonomycategoryId: "",
        categoryType: categoryType,
        captionData: "",
        uploadedId: {},
        cattaxListing: {},
        mode: "",
        uploadUrl: '/upload/multiuploader/?album=&instance=listing_images&image=undefined&doc=undefined',
        uploadedFiles: [],
        categoryTypeTab: "",
        weatherData: null,
        get_temp: null,
        isDropdownOpen: false,
        videoFinishedWatching : []
    }
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const [videoFinishedWatching, setVideoFinishedWatching] = useState([])
    const refGalleryVideoScroll = useRef();
    const refGalleryImageScroll = useRef();
    let refSecondCarsouel=useRef();
    //componentDidMount
    useEffect(() => {
        setState((prev)=>({...prev,categoryType:categoryType}))
        if (props.mainTaxonomy === "movies") {
            dispatch(get_media_children(categoryType, 'images'));
            dispatch(get_media_children(categoryType, 'videos'));
        } else if (props.mainTaxonomy) {
            dispatch(get_media_children(categoryType, 'images', props.mainTaxonomy));
            dispatch(get_media_children(categoryType, 'videos', props.mainTaxonomy));
        }
        // else {
        //     dispatch(get_media_children(categoryType, 'images'));
        // }
        dispatch(clear_category());
        dispatch(clear_subcategory());
    }, [dispatch, categoryType, props.mainTaxonomy]);
    

    useEffect(() => {
        if (album_types_list && album_types_list.length > 0) {
            setState((prev) => ({
                ...prev,
                uploadMedia: {
                    ...prev.uploadMedia,
                    albumTypesList: album_types_list,
                },
                selectedUpMediaType: "upload",
                showGalleryType: "images",
            }));
        }
    }, [album_types_list]);


    useEffect(() => {
        if (
            album_type_data &&
            Object.keys(album_type_data).length > 0
        ) {
            let item = album_type_data;
            if (item.album) { setState((prev) => ({ ...prev, fetchGalleryScroll: [] })) }
            setState((prev) => ({
                ...prev,
                uploadMedia: {
                    ...prev.uploadMedia,
                    albumTypeData: album_type_data,
                },
                fetchGalleryScroll: prev.fetchGalleryScroll.splice(item.page - 1, 1, item) && prev.fetchGalleryScroll,
                fetchGalleryCount: item.count,
            }));
        }
    }, [album_type_data]);

    useEffect(() => {
        if (fetch_data_categories) {
            let taxCatListing = fetch_data_categories;
            if (taxCatListing && Array.isArray(taxCatListing) && taxCatListing.length > 0) {
                //taxCatListing = taxCat.filter(dt => dt.label !== null);
                if (taxCatListing[0].isTypes == "image") {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        const temp = taxCatListing[0].children ? taxCatListing[0].children : [];
                        setState((prev) => ({
                            ...prev,
                            fetchTaxonomyListingImages: prev.fetchTaxonomyListingImages[idx] = temp
                        }));
                    }

                } else {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        const temp = taxCatListing[0].children ? taxCatListing[0].children : [];
                        setState((prev) => ({
                            ...prev,
                            fetchTaxonomyListingVideos: prev.fetchTaxonomyListingVideos[idx] = temp
                        }));
                    }
                }
            }

        }
    }, [fetch_data_categories]);


    // useEffect(() => {
    //     if (filter_data_category) {
    //         let taxCatList = filter_data_category;

    //         if (taxCatList && Object.keys(taxCatList).length > 0) {
    //             setState((prev) => ({
    //                 ...prev,
    //                 cattaxListing: taxCatList[0] ? taxCatList[0].children : []
    //             }));
    //         }

    //     }
    // }, [filter_data_category]);


    useEffect(() => {
        if (cat_taxonomy_list &&
            Array.isArray(cat_taxonomy_list) &&
            cat_taxonomy_list?.length) {
            let cattaxListing = [];
            cat_taxonomy_list.forEach(item => {
                cattaxListing = cattaxListing.concat(item.children);
            })
            setState((prev) => ({
                ...prev,
                cattaxListing
            }))
        }

    }, [cat_taxonomy_list]);

    //componentWillReceiveProps
    useEffect(() => {

        if (props.details && state.carouselMedia === null) {
            callApi(`/upload/list/all/?category=&media_type=&page=1&listing=${props.details.id}`, 'GET')
                .then(res => {
                    if (res && Object.keys(res).length > 0) {
                        setState((prev) => ({ ...prev, carouselMedia: res, details: props.details }));
                    }
                });
        }

    }, [props.details, state.carouselMedia]);

    useEffect(() => {
        if (props.details) {
            const url = `/upload/list/all/?category=&media_type=&page=1&listing=${props.details.id}`;
            setState((prev) => ({ ...prev, details: props.details ,categoryType:categoryType}));
            callApi(url, 'GET')
                .then(res => {
                    if (res && Object.keys(res).length > 0) {
                        setState((prev) => ({
                            ...prev,
                            carouselMedia: res,
                            activeTab: 'all'
                        }));
                    }
                });
            callApi(`/api/${categoryType}/${props.details.id}/`, 'GET')
                .then(res => {
                    if (res && Object.keys(res).length > 0) {
                        setState((prev) => ({
                            ...prev,
                            details: {
                                ...prev.details,
                                media_info: { ...res.media_info }
                            }
                        }));
                    }
                });
        }

    }, [props.details, categoryType])

    useEffect(() => {
        if (categoryType == "business") {
            if (props.businessWeather) {
                const businessWeather = { ...props.businessWeather };
                let iconPath = businessWeather?.BlueIcon?.iconPath;
                if (iconPath) {
                    let iconPathArray = iconPath.split('/');
                    iconPath = iconPathArray[iconPathArray.length - 1];
                }
                setState((prev) => ({
                    ...prev,
                    weatherData: {
                        temperature_C_Blue: businessWeather.BlueIcon && businessWeather.BlueIcon.temp_C,
                        temperature_F_Blue: businessWeather.BlueIcon && businessWeather.BlueIcon.temp_F,
                        tempIcon: iconPath
                    }

                }))
            }
        }

    }, [props.businessWeather, categoryType]);

    useEffect(() => {
        if (categoryType == "business") {
            if (props.temp_type) {
                if (props.temp_type && props.temp_type.results && Array.isArray(props.temp_type.results) && props.temp_type.results.length > 0) {
                    let value = props.temp_type?.results?.[12]?.value;
                    setState((prev) => ({ ...prev, get_temp: value }))
                }
            }
        }
    }, [props.temp_type, categoryType]);

    const handleOnFilePostUploadChange = (event) => {
        const { categoryType } = state;
        let uploadFiles = event.target.files;
        let showFiles = [];
        for (const key of Object.keys(uploadFiles)) {
            showFiles.push({ id: "", url: URL.createObjectURL(uploadFiles[key]), type: uploadFiles[key].type });
        }
        setState((prev) => ({
            ...prev,
            uploadMedia: {
                ...prev.uploadMedia,
                uploadFiles: showFiles,
                progress: 0,
            },
        }));
        let progressPart = 100 / showFiles.length;
        for (const key of Object.keys(uploadFiles)) {
            const data = new FormData();
            data.append("file", uploadFiles[key]);
            dispatch(setUploadPercentage(0))
            callApi(
                `/upload/multiuploader/?album=&instance=listing_images&image=undefined&doc=undefined`,
                "POST",
                data,
                true,
                "",
                "",
                dispatch
            ).then((response) => {
                handleOnClickSelectGalleryMedia(response);
                let progress = 0;
                if (showFiles.length === 1 || key === showFiles.length - 1) {
                    progress = 100;
                } else {
                    progress = progress + progressPart;
                }
                showFiles[key].id = response.id;
                showFiles[key].media_type = response.media_type;
                if (response.media_type == "image") {
                    setState((prev) => ({ ...prev, isImageType: true }))
                    dispatch(get_category_taxonomy_listing(state.categoryType, 'images', (props.mainTaxonomy === "movies" ? '' : props.mainTaxonomy?.toLowerCase()?.replace(/&/g, "and"))));
                } else {
                    setState((prev) => ({ ...prev, isImageType: false }));
                    dispatch(get_category_taxonomy_listing(state.categoryType, 'videos', (props.mainTaxonomy === "movies" ? '' : props.mainTaxonomy?.toLowerCase()?.replace(/&/g, "and"))));
                }
                // dispatch(filter_categories_data({ category: categoryType, sub_cat: "", level: 1 }));
                setState((prev) => ({
                    ...prev,
                    uploadMedia: {
                        ...prev.uploadMedia,
                        progress: progress,
                        uploadedFiles: [...prev.uploadMedia.uploadedFiles, response],
                        uploadFiles: showFiles,
                    },
                    categoryTypeTab: "View All Media"
                }));
            });
        }
    };

    const toggleMainDropdown = () => {
        setState((prev) => ({
            ...prev,
            isDropdownOpen: !prev.isDropdownOpen
        }))
    }

    const handleOnClickRemoveSelectedMedia = (id) => () => {
        const removeMediaIds = [];
        removeMediaIds.push(id);
        dispatch(delete_selected_gallery_media(removeMediaIds));
        let uploadFiles = state.uploadMedia.uploadFiles.filter(
            (file) => file.id !== id
        );
        let uploadedFiles = state.uploadMedia.uploadedFiles.filter(
            (file) => file.id !== id
        );
        let selectedMediaIds = state.uploadMedia.selectedMediaIds.filter(
            (item) => item !== id
        );
        let selectedMedia = state.uploadMedia.selectedMedia.filter(
            (file) => file.id !== id
        );
        setState((prev) => ({
            ...prev,
            uploadMedia: {
                ...state.uploadMedia,
                selectedMedia: selectedMedia,
                selectedMediaIds: selectedMediaIds,
                uploadedFiles: uploadedFiles,
                uploadFiles: uploadFiles,
            },
        }));
    };
    const handleOnClickRemoveSelectedGalleryMedia = (media) => {
        const removeMediaIds = [];
        removeMediaIds.push(media.id);
        const index = state.uploadMedia.selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        if (index !== -1) {
            newSelectedMediaIds = state.uploadMedia.selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = state.uploadMedia.selectedMedia.filter(
                (item) => item.id !== media.id
            );
            dispatch(delete_selected_gallery_media(removeMediaIds));
            setState((prev) => ({
                ...prev,
                uploadMedia: {
                    ...state.uploadMedia,
                    selectedMedia: newSelectedMedia,
                    selectedMediaIds: newSelectedMediaIds,
                },
            }));
        }
    };
    const handleOnClickSelectGalleryMedia = (media) => {
        const index = state.uploadMedia.selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        if (index !== -1) {
            newSelectedMediaIds = state.uploadMedia.selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = state.uploadMedia.selectedMedia.filter(
                (item) => item.id !== media.id
            );
        } else {
            newSelectedMediaIds = [
                ...state.uploadMedia.selectedMediaIds,
                media.id,
            ];
            newSelectedMedia = [...state.uploadMedia.selectedMedia, media];
        }

        setState((prev) => ({
            ...prev,
            uploadMedia: {
                ...prev.uploadMedia,
                selectedMedia: newSelectedMedia,
                selectedMediaIds: newSelectedMediaIds,
            },
        }));
    }

    const handleOnClickGalleryType = (type) => {
        let mediaType = "";
        if (type === "images") {
            mediaType = "image";
        } else if (type === "videos") {
            mediaType = "video";
        }
        dispatch(get_album_type_data(mediaType, "", 1));
        setState((prev) => ({
            ...prev,
            selectedUpMediaType: "gallery",
            showGalleryType: type,
            uploadMedia: {
                ...state.uploadMedia,
                mediaType: mediaType,
                albumType: "",
                pageNo: 1
            },
        }));
    };
    const handleOnClickAlbumTypeChange = (e) => {
        const { value } = e.target;
        setState((prev) => ({ ...prev, fetchGalleryScroll: [] }))
        dispatch(get_album_type_data("image", value, 1));
        setState((prev) => ({
            ...prev,
            selectedUpMediaType: "gallery",
            showGalleryType: "images",
            uploadMedia: {
                ...prev.uploadMedia,
                mediaType: "image",
                albumType: value,
                pageNo: 1
            },
        }));
    };
    const handleOnClickSelectedUploadMediaType = (type) => {
        if (type === "gallery") {
            dispatch(get_album_type_data("image", "", 1));
            setState((prev) => ({
                ...prev,
                selectedUpMediaType: type,
                showGalleryType: "images",
                uploadMedia: {
                    ...state.uploadMedia,
                    mediaType: "image",
                    albumType: "",
                    pageNo: 1,
                    embedLinks: {},
                    progress: 0,
                },
            }));
        } else if (type === "upload") {
            setState((prev) => ({
                ...prev,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...state.uploadMedia,
                    embedLinks: {},
                    progress: 0,
                },
            }));
        } else if (type === "embed") {
            let embedLinks = {
                0: "",
                1: "",
            };
            setState((prev) => ({
                ...prev,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    embedLinks: embedLinks,
                },
            }));
        }
    };
    const handleOnClickUploadMedia = () => {
        dispatch(get_album_types_list());
        setState((prev) => ({
            ...prev,
            uploadMedia: {
                ...prev.uploadMedia,
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                progress: 0,
                uploadFiles: [],
                uploadedFiles: [],
            },
            uploadMediaPostModal: true,
            selectedUpMediaType: "upload",
            showGalleryType: "images",
        }));
    };
    const truncate = (filenameString) => {
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        const partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    }
    const handleCaptionByIndex = (e, index) => {
        const { value } = e.target;
        const { uploadCaptions, uploadMedia, taxonomycategoryId } = state;
        uploadCaptions[index] = value;
        let data = {};
        let upload_data = uploadMedia.uploadFiles[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = taxonomycategoryId;
        const id = upload_data.id;
        setState((prev) => ({
            ...prev,
            uploadCaptions,
            captionData: caption_data
        }));
        dispatch(update_caption_and_categories({ id, data }));

    }
    const handleChangeCategory = (e, index) => {
        const { isImageType, uploadMedia, uploadedId, categoryType, uploadCaptions } = state;
        const upload_data = uploadMedia.uploadFiles[index];
        const id = upload_data.id;
        uploadedId[index] = id;
        const { value } = e.target;
        const albumType = value !== 'all' ? value : '';
        setState((prev) => ({
            ...prev,
            albumCategoryId: albumType,
            uploadedId,
            taxonomycategoryId: value
        }));
         const categoryId = categoryType;
         const subcategoryId = value;

        dispatch(update_caption_and_categories({ id, data: { "caption": uploadCaptions[index] || "", "category": value } }))
        //  if(categoryId){
        //      if (isImageType) {
        //          dispatch(fetch_categories_data(categoryId, subcategoryId, 'image', index));
        //      } else {
        //          dispatch(fetch_categories_data(categoryId, subcategoryId, 'video', index));
        //      }
        //  }
            
    }

    const handleChangeTaxonomyData = (e, index) => {
        const { value } = e.target;
        const { uploadCaptions, uploadMedia } = state
        const data = {};
        const upload_data = uploadMedia.uploadFiles[index];
        const caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = value;
        const id = upload_data.id;
        setState(prev => ({
            ...prev,
            taxonomycategoryId: value, 
            captionData: caption_data
        }));
        dispatch(upload_add_captions(id, data));

    }
    
    const uploadMediaModalToggle = () => {
        setState(prev => ({
            ...prev,
            uploadMediaPostModal: !state.uploadMediaPostModal,
            uploadMedia: {
                ...state.uploadMedia,
                selectedMedia: [],
                selectedMediaIds: [],
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                uploadedFiles: [],
                uploadFiles: [],
            },
            selectedUpMediaType: "upload",
            showGalleryType: "images",
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            categoryType: "",
            uploadedId: {},
            captionData: ""
        }));
    }

    const handleScrollGalleryImage = (e) => {
        const { fetchGalleryAlbum } = state;

        const clientHeight = refGalleryImageScroll.current.clientHeight;
        const scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    const fetchGalleryOnScroll = ({ type, album, force_update }) => {
        const { fetchGalleryScroll, fetchGalleryCount } = state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            const countPhotos = Object.keys(fetchGalleryScroll).length;
            const nextCount = countPhotos + 1;
            if (nextCount <= roundValue) {
                const page = nextCount;
                dispatch(get_album_type_data(type, "", page));

            }
        }
    }

    const handleScrollGalleryVideo = (e) => {
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    const fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = state;
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });
           //if(fetchGalleryScroll.results?.length) getDataItems = getDataItems.concat(fetchGalleryScroll.results);
            

            if (getDataItems && getDataItems.length) {
                return getDataItems.map((item,index) => {
                    
                    let itemName = (item?.thumbnail).split('.');
                    let itemFile = (item?.file).split('.');
                    let extName = itemName[itemName.length - 1];
                    let extFile = itemFile[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview

                    return (
                        <Col xs="auto" className="mb-3">
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={()=>
                                        handleOnClickSelectGalleryMedia(item)
                                    } />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    const handleAddMediaToGallery = async () => {
        const url = `/upload/list/all/?category=&media_type=&page=1&listing=${state.details.id}`;
        const data = {
            type: "media",
            entries: state.details.id,
            userentries_id: state.details.id,
            taxonomy_id: "",
            multiuploaderfile: state.uploadMedia.selectedMediaIds,
            galleryType: categoryType
        };
        if (state.uploadMedia.selectedMediaIds.length) {

            dispatch(add_media_to_gallery(data));

            setState((prev) => ({
                ...prev,
                uploadMediaPostModal: false,
                uploadMedia: {
                    ...prev.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    uploadFiles: [],
                    uploadedFiles: []
                },
            }));
            setTimeout(() => {
                callApi(url, 'GET')
                    .then(res => {
                        if (res && Object.keys(res).length > 0) {
                            setState((prev) => ({
                                ...prev,
                                carouselMedia: res,
                                activeTab: 'all'
                            }));
                        }
                    })
                callApi(`/api/${categoryType}/${state.details.id}/`, 'GET')
                    .then(res => {
                        if (res && Object.keys(res).length > 0) {
                            setState((prev) => ({
                                ...prev,
                                details: {
                                    ...prev.details,
                                    media_info: { ...res.media_info }
                                }
                            }));
                        }
                    });
            }, 2000);

            setState((prev) => ({
                ...prev,
                viewMediaModalToggle: false
            }));

        }
    }

    const handleOnClickChangeGalleryTab = (tab) => {
        let mediaType = '';
        if (tab === 'image' || tab === 'video') {
            mediaType = tab;
        }
        let url = `/upload/list/${tab}/?category=&media_type=${mediaType}&page=1&listing=${state.details.id}`;

        callApi(url, 'GET')
            .then(res => {
                if (res && Object.keys(res).length > 0) {
                    setState((prev) => ({ ...prev, carouselMedia: res, activeTab: tab, categoryTypeTab: '' }));
                }
            });
    }

    const handleOnClickChangeGalleryCategory = (tab,id = '', tabType = '',carousel='1') => {
        console.log('handleOnClickChangeGalleryCategory',tab,tabType)
        toggleMainDropdown()
        let url = '',
            mediaType = '';
        let categoryTypeTab = '';
        if (tab == "all") {
            categoryTypeTab = "View All Media";
        } else if (tab == "image" && !tabType) {
            categoryTypeTab = "View All Images";
        } else if (tab == "video" && !tabType) {
            categoryTypeTab = "View All Videos";
        } 
        else {
            categoryTypeTab = tabType;
        }
        if (id) {
            url = `/upload/list/${tab}/?category=${id}&media_type=&page=1&listing=${state.details.id}`;

        } else {
            if (tab === 'image' || tab === 'video') {
                mediaType = tab;
            }
            url = `/upload/list/${tab}/?category=&media_type=${mediaType}&page=1&listing=${state.details.id}`;
        }
        
        
        callApi(url, 'GET')
            .then(res => {
                if (res && Object.keys(res).length > 0) {
                    if(carousel==='1'){
                        setState((prev) => ({ ...prev, categoryTypeTab: categoryTypeTab }));
                        setState((prev) => ({ ...prev, carouselMedia: res }));
                    }
                    if(carousel==='2'){
                        refSecondCarsouel.current.goToSlide(0);
                        refSecondCarsouel.current.state.totalItems=res.results.length+1;
                        setState((prev) => ({ ...prev, secondaryCategoryTypeTab: categoryTypeTab }));
                        setState((prev) => ({ ...prev, secondCarouselMedia: res.results }));
                    }                 
                }
            });
    }
    
    
    const openSecondCarousal = (index) => {
        const carouselMedia = { ...state.carouselMedia };
        const array1 = index ? carouselMedia.results.filter((item, i) => i < index) : [];
        const array2 = (index !== carouselMedia.results.length - 1) ? carouselMedia.results.filter((item, i) => i > index) : [];
        const newArray = [carouselMedia.results[index], ...array2, ...array1];

        setState((prev) => ({ ...prev, secondCarouselMedia: newArray, viewMediaModalToggle: true,secondaryCategoryTypeTab:categoryTypeTab }));
    }

    const save_ratings_vote = async (data,rateKey) => {
        let url = '/ratings/vote/';
        // const {avgRateKey} = this.state
        try {
            const response = await callApi(url, 'POST', data);
            if (response?.code == 200) {
                if(response.key==="avg_rating"){
                    this.setState({voteScore:response.vote_score,avgRateKey:rateKey})
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getSecurityHashToken = (keyName, newRating, media, object_pk, content_type) => {
        const url = "/ratings/security_hash/"
        // const { categoryItemQueryParams, avgRateKey } = state;
        let formDataSecurity = new FormData();

        formDataSecurity.append('object_pk', object_pk);
        formDataSecurity.append('content_type', content_type);
        formDataSecurity.append('key', keyName);
        formDataSecurity.append('timestamp', Date.now());
        callApi(url, 'POST', formDataSecurity).then((res) => {
            if (res.code <= 201) {
                // this.setState({ ratingOn: false })
                let formDataVote = new FormData();
                formDataVote.append('csrfmiddlewaretoken', res?.csrfmiddlewaretoken);
                formDataVote.append('score', newRating);
                formDataVote.append('content_type', res?.content_type);
                formDataVote.append('object_pk', res?.object_pk);
                formDataVote.append('key', keyName);
                formDataVote.append('timestamp', res?.timestamp);
                formDataVote.append('security_hash', res?.security_hash);
                formDataVote.append('rate_unq_key', res?.rate_unq_key);
                save_ratings_vote(formDataVote, res?.rate_unq_key);
                // this.setState((prevState) => {
                //     return {
                //         ...prevState,
                //         categoryItemQueryParams: {
                //             ...prevState.categoryItemQueryParams,
                //             csrfmiddlewaretoken: res?.csrfmiddlewaretoken,
                //             rate_unq_key: res?.rate_unq_key,
                //             security_hash: res?.security_hash,
                //             timestamp: res?.timestamp
                //         }
                //     }
                // })
            }
        });
    }

    const submitMediaRating=(id, rating, media)=>{
        let object_pk = ""
        let content_type = ""
        callApi(`/get_image_id/?id=${id}&type=Business`, 'GET').then((res) => {
            object_pk = res.object_pk
            content_type = res.content_type
            getSecurityHashToken('main', rating, media, object_pk, content_type)
            setState((prev)=>({...prev,mediaRating:{...prev.mediaRating,[id]:rating}}))
        })
    }

    const checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        if (my_profile && my_profile.id && authId && (authId == my_profile.id)) {
            return true;
        } else {
            return false;
        }
    }
    const myCallback = (id) => {
        let videoFinishedWatchingCopy = [...videoFinishedWatching]
        videoFinishedWatchingCopy.push(id)
        setVideoFinishedWatching(videoFinishedWatchingCopy)
    };
    
    const { categoryTypeTab, carouselMedia, activeTab, secondCarouselMedia, details, fetchTaxonomyListingImages, fetchTaxonomyListingVideos,
        uploadedId, cattaxListing, weatherData, get_temp } = state;
        let uploadFinish =false;
        if (state.uploadMedia.uploadFiles.length > 0) {
            
            if(state.uploadMedia.uploadFiles.every(item=>item.id)){
                uploadFinish=true
            }

        };
    
    return (
        <div className="h-100 d-flex flex-column">
            <div className="bg-white-shaded text-dark px-2 pt-2 pb-2 bg-business-bar" style={{ marginBottom: '35px' }}>
                <Container>
                    <div className="d-flex align-items-end mx-n2">
                        <div className="px-2">
                            <div className="topbar-main-img-holder shifted">
                                {
                                    (categoryType !== "business" && details?.listing_profileimage?.length) ?
                                        (
                                            <img className="img-fluid" src={details?.listing_profileimage[0]?.url} alt="" onError={(e) => e.target.src = "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"} />
                                        )
                                        : (categoryType === "business" && details?.listing_logo_pic?.length)
                                            ? (
                                                <img className="img-fluid" src={details?.listing_logo_pic[0]?.url} alt="" onError={(e) => e.target.src = "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"} />
                                            )
                                            : (<img className="img-fluid" src={`https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`} alt="" />)

                                }
                            </div>
                        </div>
                        <div className="px-2 lh-1">
                            <div className="d-flex align-items-start">
                                <div className="mr-2">
                                    <h1 className="text-dark text-uppercase fs-20">
                                        {details?.name}
                                    </h1>
                                </div>
                                <div className="d-flex align-items-center">
                                    {details?.total_rating?.avg_rating && (
                                        <img className="mr-2"
                                            title={details?.total_rating?.avg_rating[1]}
                                            src={(details &&
                                                details?.total_rating?.avg_rating[1] !== 'No Rating') ?
                                                require(`../../../../assets/images/icons/star/blue/${Math.floor(details?.total_rating?.avg_rating[1])}rating.png`) :
                                                require('../../../../assets/images/icons/star/blue/norating.png')} alt="" />
                                    )}
                                    <span className="fs-14 ff-alt">{details?.total_rating?.total_review} Review{details?.total_rating?.total_review != 1 ? 's' : ''}</span>
                                </div>
                            </div>
                        </div>
                        {
                            categoryType == "business" && <div className="px-2 ml-auto">
                                <div className="d-flex align-items-center">
                                    <span className="mr-2 fs-13 text-black ff-alt">
                                        {`${details?.address[0]?.address1},
                                ${details?.address[0]?.address2 ? details?.address[0]?.address2 : ''}${details?.address[0]?.address2 ? ',' : ''}${details?.address[0]?.city}, ${details?.address[0]?.state} ${details?.address[0]?.zipcode}, ${details?.address[0]?.country}`}
                                    </span>
                                    {weatherData && get_temp && (weatherData?.temperature_C_Blue || weatherData?.temperature_F_Blue) ?
                                        <span className="fs-16 font-weight-bold text-dark wr-sun-image">
                                            <img
                                                src={weatherData?.tempIcon ?
                                                    `https://userdatawikireviews.s3.amazonaws.com/images/weather-icons/blue_color_icons/png/night/${weatherData?.tempIcon}` :
                                                    ''}
                                                className="img-fluid"
                                                alt=""
                                            />
                                            {get_temp == 'c' ? `${weatherData?.temperature_C_Blue} °C` : `${weatherData?.temperature_F_Blue} °F`}
                                        </span> :
                                        ""
                                    }
                                </div>
                            </div>
                        }
                        
                    </div>
                </Container>
            </div>
            <Container className="my-auto">
                <div className="text-center">
                    {(details?.media_info?.all == 0 && details?.media_info?.image == 0 && details?.media_info?.video == 0) ?
                        // If no image 
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '200px' }}>
                            <div className="px-4 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                <span>This Listing has no image/video uploaded yet. <br /> Be the first to submit one.</span>
                                <div className="mt-2">
                                    <Button color="link" className="text-white font-weight-bold" onClick={() => {
                                        if (!checkIfLoggedIn()) {
                                            props.forceToggleLoginModal();
                                            return false;
                                        }
                                        handleOnClickUploadMedia()
                                    }}><i className="icon-camera-white"></i> Add Image</Button>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <Carousel
                                responsive={responsive.viewGalleryImages}
                                infinite={true}
                                autoPlay={false}
                                swipeable={true}
                                showDots={false}
                                arrows={true}
                                containerClass="gallery-carousel"
                                sliderClass={`${carouselMedia?.results?.length === 0}` ? "mx-auto" : ""}
                            >
                                {carouselMedia?.results?.length > 0 ? carouselMedia?.results?.map((med, i) => {
                                    return (
                                        <div className="item px-4" key={med?.media?.id + i}>
                                            <div className={`${med?.media?.media_type === 'video' ? 'video-holder' : 'img-holder'} text-center`} role="button" onClick={() => openSecondCarousal(i)}>
                                                <img className="fixed-height-img is-200 full-width" src={med?.media?.media_type === 'video' ? med?.media?.thumbnail : med?.media?.url} alt="" style={{ border: '2px solid #8791b5' }} />
                                            </div>
                                            <div className="text-left position-relative">
                                                <Link
                                                    // to={`/people/${med?.media?.user?.user?.username}`}
                                                    to={{
                                                        pathname: `/people/${med?.media?.user?.user?.id ? med.media.user.user.username : med?.media?.user?.user?.id}`,
                                                        id: med?.media?.user?.user?.id
                                                    }}
                                                >
                                                    <img className="img-circle _30x30 mt-n4 ml-3" src={med?.media?.user?.current_profile_pic ? med?.media?.user?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt="" onError={(error) =>
                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                    } />
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    (
                                        <div className="item px-4">
                                            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '200px' }}>
                                                <div className="px-4 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                                    <span>No result found for this category.</span>
                                                    <div className="mt-2">
                                                        <Button color="link" className="text-white font-weight-bold" onClick={() => {
                                                            handleOnClickChangeGalleryTab('all')
                                                        }}>View All Media</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </Carousel>
                        </div>
                    }


                    <div className="d-flex mx-n4 justify-content-center mt-5">
                        <div className="px-4">
                            <div className={`gallery-filterable-list fs-16 ${activeTab === 'all' ? 'active' : ''}`} role="button" onClick={() => handleOnClickChangeGalleryTab('all')}>
                                All ({details?.media_info?.all})
                            </div>
                        </div>
                        <div className="px-4">
                            <div className={`gallery-filterable-list fs-16 ${activeTab === 'image' ? 'active' : ''}`} role="button" onClick={() => handleOnClickChangeGalleryTab('image')}>
                                Photos ({details?.media_info?.image})
                            </div>
                        </div>
                        <div className="px-4">
                            <div className={`gallery-filterable-list fs-16 ${activeTab === 'video' ? 'active' : ''}`} role="button" onClick={() => handleOnClickChangeGalleryTab('video')}>
                                Videos ({details?.media_info?.video})
                            </div>
                        </div>
                        {categoryType == "business" &&
                            <div className="px-4">
                                <div className={`gallery-filterable-list fs-16 ${activeTab === 'business_owner' ? 'active' : ''}`} role="button" onClick={() => handleOnClickChangeGalleryTab('business_owner')}>
                                    Business Owner({details?.media_info?.owner})
                                </div>
                            </div>
                        }
                        <div className="px-4">
                            <Link
                                to={{
                                    pathname: `/view_all/${details?.id}`,
                                    search: `?name=${details?.name?.replace(/\s+/g, '').toLowerCase()}`,
                                    state: { id: details?.id, categoryType: categoryType, mainTaxonomy: props.mainTaxonomy, itemName: props.details?.name }
                                }}><div className="gallery-filterable-list fs-16" role="button">
                                    View All
                                </div></Link>
                        </div>
                        {/* <div className="px-4">
                            <Dropdown isOpen={state.isDropdownOpen} toggle={toggleMainDropdown}>
                                <DropdownToggle className="text-white bg-transparent font-weight-bold p-0" color="transparent" size="sm" caret>
                                    <span className="gallery-filterable-list fs-16" role="button">
                                        {categoryTypeTab && categoryTypeTab != "" ? categoryTypeTab : "Categories"}
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu className="type-tertiary">
                                    <DropdownItem className="fs-14" onClick={() => handleOnClickChangeGalleryCategory('all')}>
                                        View All Media
                                    </DropdownItem>
                                    <DropdownItem className="fs-14">
                                        <UncontrolledDropdown className='text-center'>
                                            <DropdownToggle className="text-white bg-transparent font-weight-bold p-0" color="transparent" size="sm">
                                                <span className="fs-14" role="button">
                                                    View All Images
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu className="type-tertiary">
                                                <DropdownItem className="fs-14" onClick={() => handleOnClickChangeGalleryCategory('image')}>
                                                    All
                                                </DropdownItem>
                                                <div className="pl-2">
                                                    {images_children && images_children?.length > 0 && images_children[0]?.children?.length > 0 && images_children[0]?.children?.map(item => {
                                                        return (
                                                            <DropdownItem className="fs-14" key={item.id} onClick={() => handleOnClickChangeGalleryCategory('image', '1', item.id, item.label)}>
                                                                {item.label}
                                                            </DropdownItem>
                                                        )
                                                    })}
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </DropdownItem> 
                                    <DropdownItem className="fs-14">
                                    <UncontrolledDropdown className='text-center'>
                                            <DropdownToggle className="text-white bg-transparent font-weight-bold p-0" color="transparent" size="sm" caret>
                                                <span className="fs-14" role="button">
                                                    View All Videos
                                                </span>
                                            </DropdownToggle>
                                            <DropdownMenu className="type-tertiary">
                                                <DropdownItem className="fs-14" onClick={() => handleOnClickChangeGalleryCategory('video')}>
                                                    All
                                                </DropdownItem>
                                                <div className="pl-2">
                                                    {videos_children && videos_children?.length > 0 && videos_children[0]?.children?.length > 0 && videos_children[0]?.children?.map(item => {
                                                        return (
                                                            <DropdownItem className="fs-14" key={item.id} onClick={() => handleOnClickChangeGalleryCategory('video', '1', item.id, item.label)}>
                                                                {item.label}
                                                            </DropdownItem>
                                                        )
                                                    })}
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                   </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div> */}
                        <div className="px-4">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="text-white bg-transparent font-weight-bold p-0" color="transparent" size="sm" caret>
                                        <span className="gallery-filterable-list fs-16" role="button">
                                        {categoryTypeTab && categoryTypeTab != "" ? categoryTypeTab : "Categories"}
                                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu className="type-tertiary">
                                        <DropdownItem className="fs-14" onClick={() => handleOnClickChangeGalleryCategory('all')}>
                                            View All Media
                                                        </DropdownItem>
                                        <DropdownItem className="fs-14" onClick={() => handleOnClickChangeGalleryCategory('image')}>
                                            View All Images
                                                        </DropdownItem>
                                        <div className="pl-2">
                                        {images_children?.length > 0 && images_children[0]?.children?.length > 0 && images_children[0]?.children?.map(item => {
                                            return (
                                                <DropdownItem className="fs-14" key={item.id} onClick={() => handleOnClickChangeGalleryCategory('image', item.id, item.label)}>
                                                    {item.label}
                                                </DropdownItem>
                                            )
                                        })}
                                        </div>
                                        <DropdownItem className="fs-14" onClick={() => handleOnClickChangeGalleryCategory('video')}>
                                            View All Videos
                                        </DropdownItem>
                                        <div className="pl-2">
                                        {videos_children?.length > 0 && videos_children[0]?.children?.length > 0 && videos_children[0]?.children?.map(item => {
                                            return (
                                                <DropdownItem className="fs-14" key={item.id} onClick={() => handleOnClickChangeGalleryCategory('video', item.id, item.label)}>
                                                    {item.label}
                                                </DropdownItem>
                                            )
                                        })}
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        <div className="px-4">
                            <Button color="link" className="text-primary fs-16 font-weight-bold p-0" onClick={() => {
                                if (!checkIfLoggedIn()) {
                                    props.forceToggleLoginModal();
                                    return false;
                                }
                                handleOnClickUploadMedia()

                            }}>
                                <FontAwesomeIcon icon="plus" size="sm" />
                                &nbsp;add media
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>

            {/* Upload Media Post Modal */}
            <Modal
                isOpen={state.uploadMediaPostModal}
                toggle={uploadMediaModalToggle}
            >
                <ModalHeader toggle={uploadMediaModalToggle}>
                    UPLOAD MEDIA
                </ModalHeader>
                <ModalBody className="p-3">
                    <Row>
                        <Col xs={"auto"}>
                            <div className="bg-light h-100 p-3">
                                <Nav tabs className="flex-column active-transparent border-0 m-0">
                                    <NavItem className="text-left">
                                        <NavLink
                                            className="pl-0 pr-4 border-0"
                                            href="#"
                                            active={state.selectedUpMediaType === "upload"}
                                            onClick={() => {
                                                handleOnClickSelectedUploadMediaType("upload");
                                            }}
                                        >
                                            Upload
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <hr className="my-3 bg-tertiary" />
                                    </NavItem>
                                    <NavItem className="text-left border-0">
                                        <NavLink
                                            className="pl-0 pr-4 border-0"
                                            href="#"
                                            active={state.selectedUpMediaType === "gallery"}
                                            onClick={() => {
                                                handleOnClickSelectedUploadMediaType("gallery");
                                            }}
                                        >
                                            Gallery
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </Col>
                        <Col>
                            <TabContent activeTab={state.selectedUpMediaType}>
                                <TabPane tabId="upload">
                                    <div
                                        className="mb-3 type-file-block bg-light"
                                        hidden={
                                            state.uploadMedia.uploadFiles &&
                                                state.uploadMedia.uploadFiles.length > 0
                                                ? true
                                                : false
                                        }
                                    >
                                        <Input
                                            type="file"
                                            name="upload_media_file"
                                            id="uploadFilesPostFromSystem"
                                            accept="image/*, video/*"
                                            onChange={handleOnFilePostUploadChange}
                                            multiple
                                        />
                                        <Label for="uploadFilesPostFromSystem">
                                            <div className="type-bg"></div>
                                        </Label>
                                    </div>
                                    {state.uploadMedia.uploadFiles.length > 0 && (
                                        <Row className="mb-3" form>
                                            <Col xs={12}>
                                                <div
                                                    style={{
                                                        maxWidth: "120px",
                                                        margin: "0 1rem 1rem auto",
                                                    }}
                                                >
                                                    <div className="text-center mb-1 small">
                                                        {parseInt(uploadPercentage) === 100 && uploadFinish ? (
                                                            <div className="text-success">
                                                                <FontAwesomeIcon
                                                                    icon="check-circle"
                                                                    className="mr-1"
                                                                />{" "}
                                                                {'Uploaded'}
                                                            </div>
                                                        ) : (
                                                            state.uploadMedia.uploadFiles.length > 0 && (
                                                                <div>

                                                                    {` Uploading `}
                                                                    <span className="text-success font-weight-bold ff-base">
                                                                        {uploadPercentage !== 100 ? `${parseInt(uploadPercentage)} %` : '...'}
                                                                    </span>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    {state.uploadMedia.uploadFiles.length > 0 && (
                                                        <Progress
                                                            value={parseInt(uploadPercentage)}
                                                            style={{ height: "8px" }}
                                                        />
                                                    )}
                                                </div>
                                            </Col>
                                            {state.uploadMedia.uploadFiles.map((file, index) => {
                                                return (
                                                    <Col xs="auto">
                                                        <div className="bg-light p-2">
                                                            <div className="d-flex pr-3">
                                                                <div>
                                                                    <div
                                                                        className="selectable-media"
                                                                        style={{ cursor: "default" }}
                                                                    >
                                                                        <div className="gallery-media">
                                                                            {file.type.includes('image') ? (
                                                                                <img src={file.url} alt="" />
                                                                            ) : (
                                                                                <video>
                                                                                    <source src={file.url} type={file.type} />
                                                                                    Your browser does not support HTML5 video.
                                                                                </video>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mx-n3 mt-2">
                                                                    <Button color="delete-media _alt" size="sm" title="Remove Media"
                                                                        hidden={file?.id === "" ? true : false}
                                                                        onClick={handleOnClickRemoveSelectedMedia(
                                                                            file?.id
                                                                        )}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2">
                                                                {!file?.id ? <span>Uploading...</span> : <>
                                                                <Input bsSize="sm" type="text" name="caption" className="caption-input" placeholder="Caption" onChange={(e) => handleCaptionByIndex(e, index)} style={{ maxWidth: '160px' }} />
                                                                { cattaxListing && Object.keys(cattaxListing).length > 0 ? <Input onChange={(e) => handleChangeCategory(e, index)} bsSize="sm" type="select" name="album_type_category" id="selectCategoryTypeData" style={{ maxWidth: '160px' }}>
                                                                    <option value="">Choose Category</option>
                                                                    { Object.values(cattaxListing).map(
                                                                            (data, i) => (
                                                                                <>
                                                                                    <option value={data?.id}>{data.category}</option>
                                                                                </>
                                                                            ))
                                                                    }
                                                                </Input> : "" }                                                                
                                                                {uploadedId[index] == file?.id && (Object.keys(fetchTaxonomyListingImages).length > 0 || Object.keys(fetchTaxonomyListingVideos).length > 0) ?
                                                                    <Input onChange={(e) => handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_type_select" id="selectAlbumTypeData" style={{ maxWidth: '160px' }}>
                                                                        <option value="">Choose Subcategory</option>
                                                                        {file.media_type === "image" && fetchTaxonomyListingImages && Object.keys(fetchTaxonomyListingImages).length > 0 && fetchTaxonomyListingImages[index] ?
                                                                            fetchTaxonomyListingImages[index].map(item => (
                                                                                <option value={item.id}>{item.label}</option>
                                                                            ))
                                                                            : null}
                                                                        {file.media_type === "video" && fetchTaxonomyListingVideos && Object.keys(fetchTaxonomyListingVideos).length > 0 && fetchTaxonomyListingImages[index] ?
                                                                            fetchTaxonomyListingVideos[index].map(item => (
                                                                                <option value={item.id}>{item.label}</option>
                                                                            ))
                                                                            : null}
                                                                    </Input>
                                                                    : null}</>}
                                                                
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            })}
                                            <Col xs="auto">
                                                <div className="d-flex pt-3">
                                                    <div className="selectable-media" hidden>
                                                        <Label
                                                            for="uploadFilesFromSystemMini"
                                                            className="gallery-media"
                                                            style={{
                                                                borderStyle: "dashed",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <div className="d-flex h-100 align-items-center justify-content-center">
                                                                <span className="fs-14">Upload More</span>
                                                            </div>
                                                        </Label>
                                                        <Input
                                                            type="file"
                                                            name="upload_media_file"
                                                            id="uploadFilesFromSystemMini"
                                                            accept="image/*, video/*"
                                                            onChange={handleOnFilePostUploadChange}
                                                            multiple
                                                            style={{ display: "none" }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    <div className="d-flex mx-n2 justify-content-center">
                                        <div className="px-2">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mw"
                                                disabled={parseInt(uploadPercentage) !== 100}
                                                onClick={handleAddMediaToGallery}
                                            >
                                                {'OK'}
                                            </Button>
                                        </div>
                                        <div className="px-2">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mw"
                                                onClick={uploadMediaModalToggle}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                        
                                    </div>
                                </TabPane>
                                <TabPane tabId="gallery">
                                    <div className="mb-2">
                                        <Nav tabs className="d-inline-flex mb-0">
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    active={state.showGalleryType === "images"}
                                                    onClick={() => {
                                                        handleOnClickGalleryType("images");
                                                    }}
                                                >
                                                    Image Gallery
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    active={state.showGalleryType === "videos"}
                                                    onClick={() => {
                                                        handleOnClickGalleryType("videos");
                                                    }}
                                                >
                                                    Video Gallery
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <TabContent activeTab={state.showGalleryType}>
                                        <TabPane tabId="images">
                                            <div>
                                                <FormGroup className="form-row justify-content-end">
                                                    <Col xs="auto">
                                                        <Label className="small" for="selectAlbumType">
                                                            Album Type:
                                                        </Label>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Input
                                                            bsSize="sm"
                                                            type="select"
                                                            name="album_type_select"
                                                            id="selectAlbumType"
                                                            defaultValue={state.uploadMedia.albumType}
                                                            onChange={(e)=>handleOnClickAlbumTypeChange(e)}
                                                        >
                                                            <option value="">All</option>
                                                            {state.uploadMedia.albumTypesList.length >
                                                                0 &&
                                                                state.uploadMedia.albumTypesList.map(
                                                                    (type, index) => (
                                                                        index < 4 && <option value={type.category}>
                                                                            {type.category}
                                                                        </option>
                                                                    )
                                                                )}
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                            <div
                                                className="px-3 my-3"
                                                style={{ maxHeight: "350px", overflow: "auto" }}
                                                ref={refGalleryImageScroll}
                                                onScroll={handleScrollGalleryImage}
                                            >
                                                {state.uploadMedia.albumTypeData &&
                                                    state.uploadMedia.albumTypeData.count > 0 ? (
                                                    <Row>
                                                        {fetchScrollPhotoMedia()}
                                                    </Row>
                                                ) : (
                                                    <div className="bg-white p-3">
                                                        <h2 className="text-secondary-dark">No images</h2>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="d-flex mx-n2 justify-content-center">
                                                <div className="px-2">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={handleAddMediaToGallery}
                                                    >
                                                        {'OK'}
                                                    </Button>
                                                </div>
                                                <div className="px-2">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={uploadMediaModalToggle}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                                
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="videos">
                                            <div
                                                className="px-3 my-3"
                                                style={{ maxHeight: "350px", overflow: "auto" }}
                                                ref={refGalleryVideoScroll}
                                                onScroll={handleScrollGalleryVideo}
                                            >
                                                {state.uploadMedia.albumTypeData &&
                                                    state.uploadMedia.albumTypeData.count > 0 ? (
                                                    <Row>
                                                        {fetchScrollPhotoMedia(true)}
                                                    </Row>
                                                ) : (
                                                    <div className="bg-white p-3">
                                                        <h2 className="text-secondary-dark">No videos</h2>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="d-flex mx-n2 justify-content-center">
                                             <div className="px-2">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={handleAddMediaToGallery}
                                                    >
                                                        {'OK'}
                                                    </Button>
                                                </div>
                                                <div className="px-2">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={uploadMediaModalToggle}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                                
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

            {/* View Media Modal */}
            <Modal centered isOpen={state.viewMediaModalToggle} toggle={() => setState((prev)=>({...prev,viewMediaModalToggle: !state.viewMediaModalToggle,secondaryCategoryTypeTab:''}))}>
                {/* <ModalHeader toggle={() => setState({ viewMediaModalToggle: !state.viewMediaModalToggle })}></ModalHeader> */}
                <ModalBody className="bg-transparent p-0">
                    <Carousel
                        responsive={responsive.videoModal}
                        infinite={false}
                        autoPlay={false}
                        swipeable={true}
                        showDots={false}
                        arrows={secondCarouselMedia.length > 0}
                        ref={refSecondCarsouel}
                        sliderClass={`${secondCarouselMedia?.results?.length === 0}` ? "mx-auto" : ""}>
                        {secondCarouselMedia.length > 0 ? secondCarouselMedia.map((med, i) => {
                            return (
                                <div className="item fs-14 h-100 d-flex flex-column justify-content-center" key={med?.media?.id+i}>
                                    <div>
                                        <div className="d-flex text-white justify-content-between mt-3">
                                            <div className="mr-2 d-flex align-items-center justify-content-start ff-alt">
                                                <span className='mr-2'>{med?.media?.caption}</span>
                                                <StarsRating
                                                    count={5}
                                                    value={state.mediaRating[med?.media?.id] ? state.mediaRating[med?.media?.id] : 0}
                                                    edit={false}
                                                    size={24}
                                                    color1={'#7a7a7a'}
                                                    color2={'#ffff'}
                                                />
                                            </div>
                                            <div className="ff-base">
                                                {med?.media?.label}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center mediaContainer media-min-height-container">
                                        {(med?.media?.type === 'image' || med?.media?.type === '') && (
                                            <img className="img-fluid shown-media media" src={med?.media?.url} alt="" />
                                        )}
                                        {med?.media?.type === 'video' && (
                                            <video className="img-fluid shown-media media" controls onEnded={() => myCallback(med?.media?.id)}>
                                                <source src={med?.media?.url} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                        {(med?.media?.type === 'video' && videoFinishedWatching.includes(med?.media?.id)) &&  <div className='mediaRating'>
                                            
                                            <StarsRating
                                                count={5}
                                                value={state.mediaRating[med?.media?.id] ? state.mediaRating[med?.media?.id] : 0}
                                                size={32}
                                                color1={'#d7d8e2'}
                                                color2={'#15c3d4'}
                                                onChange={(rating)=>{
                                                    submitMediaRating(med?.media?.id,rating,med);
                                                }}
                                            />
                                            {
                                                state.mediaRating[med?.media?.id] &&
                                                <span className='ratingInfo'>{state.mediaRating[med?.media?.id]} out of 5</span>
                                            }
                                            <span className='rateMore' onClick={()=>refSecondCarsouel.current.next()}>
                                                Do You want to rate more?
                                            </span>
                                            <span className='rateMore' onClick={()=>{
                                                let videoFinishedWatchingCopy = [...videoFinishedWatching]
                                                const index = videoFinishedWatchingCopy.indexOf(med?.media?.id);
                                                if (index > -1) { // only splice array when item is found
                                                    videoFinishedWatchingCopy.splice(index, 1); // 2nd parameter means remove one item only
                                                }
                                                setVideoFinishedWatching(videoFinishedWatchingCopy)
                                            }}>Hide</span>
                                        </div>}
                                        {med?.media?.type !== 'video' && <div className='mediaRating'>
                                            <StarsRating
                                                count={5}
                                                value={state.mediaRating[med?.media?.id] ? state.mediaRating[med?.media?.id] : 0}
                                                size={32}
                                                color1={'#d7d8e2'}
                                                color2={'#15c3d4'}
                                                onChange={(rating)=>{
                                                    submitMediaRating(med?.media?.id,rating,med);
                                                }}
                                            />
                                            {
                                                state.mediaRating[med?.media?.id] &&
                                                <span className='ratingInfo'>{state.mediaRating[med?.media?.id]} out of 5</span>
                                            }
                                            <span className='rateMore' onClick={()=>refSecondCarsouel.current.next()}>
                                                Do You want to rate more?
                                            </span>
                                        </div>}
                                        
                                    </div>
                                    <div className="px-2 mt-3">
                                        <div className="d-flex align-items-center justufy-content-between col mx-n2">
                                            <div className="px-2 col d-flex flex-nowrap">
                                                <div className="mr-2">
                                                    <img className="img-circle _30x30" src={med?.media?.user?.current_profile_pic ? med?.media?.user?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt="" onError={(error) =>
                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                    } />
                                                </div>
                                                <div>
                                                    <div className="text-primary ff-base">
                                                        Uploaded by&nbsp;
                                                        <Link to={{
                                                        pathname: `/people/${med?.media?.user?.user?.id ? med.media.user.user.username : med?.media?.user?.user?.id}`,
                                                        id: med?.media?.user?.user?.id
                                                    }}>
                                                            <span className="font-weight-bold">{med?.media?.user?.user?.first_name}</span> 
                                                        </Link>
                                                    </div>
                                                    <div className="text-tertiary ff-base">
                                                        {med?.media?.upload_date}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-2 col-auto">
                                                <h2 className="fs-38" style={{ color: '#d4dae6' }}>{i + 1} of {secondCarouselMedia.length}</h2>
                                            </div>
                                            <div className="px-2 col d-flex justify-content-end mr-3 carouselCategoryDropdown">
                                                {/* <UncontrolledDropdown direction='up'>
                                                    <DropdownToggle className="text-primary bg-transparent font-weight-bold p-0 " color="transparent" size="sm" caret>
                                                        <span className="gallery-filterable-list fs-16 text-primary" role="button">
                                                            {state.secondaryCategoryTypeTab && state.secondaryCategoryTypeTab != "" ? state.secondaryCategoryTypeTab : "Categories"}
                                                        </span>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary mr-2 carouselCategoryDropdown">
                                                        <DropdownItem className="fs-14" onClick={() => handleOnClickChangeGalleryCategory('all', '2')}>
                                                            View All Media
                                                        </DropdownItem>
                                                        <UncontrolledDropdown className='text-center'>
                                                            <DropdownToggle className="text-white bg-transparent font-weight-bold p-0" color="transparent" size="sm" caret>
                                                                <span className="fs-14" role="button">
                                                                    View All Images
                                                                </span>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="type-tertiary">
                                                                <DropdownItem className="fs-14" onClick={() => handleOnClickChangeGalleryCategory('image', '2')}>
                                                                    All
                                                                </DropdownItem>
                                                                <div className="pl-2">
                                                                    {images_children && images_children?.length > 0 && images_children[0]?.children?.length > 0 && images_children[0]?.children?.map(item => {
                                                                        return (
                                                                            <DropdownItem className="fs-14" key={item.id} onClick={() => handleOnClickChangeGalleryCategory('image', '2', item.id, item.label)}>
                                                                                {item.label}
                                                                            </DropdownItem>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        <UncontrolledDropdown className='text-center'>
                                                            <DropdownToggle className="text-white bg-transparent font-weight-bold p-0" color="transparent" size="sm" caret>
                                                                <span className="fs-14" role="button">
                                                                    View All Videos
                                                                </span>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="type-tertiary">
                                                                <DropdownItem className="fs-14" onClick={() => handleOnClickChangeGalleryCategory('video', '2')}>
                                                                    All
                                                                </DropdownItem>
                                                                <div className="pl-2">
                                                                    {videos_children && videos_children?.length > 0 && videos_children[0]?.children?.length > 0 && videos_children[0]?.children?.map(item => {
                                                                        return (
                                                                            <DropdownItem className="fs-14" key={item.id} onClick={() => handleOnClickChangeGalleryCategory('video', '2', item.id, item.label)}>
                                                                                {item.label}
                                                                            </DropdownItem>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : null}
                        <EndOfGallery businessName={details?.name}  handleOnClickUploadMedia={handleOnClickUploadMedia}/>
                    </Carousel>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Gallery