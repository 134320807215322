const initialState = {
    review_entry: null,
    broadcast_channel: null,
    searchReviews: null,
    reviews_data: null,
};

const reviews = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "REVIEW_ENTRY":
            state.review_entry = action.payload;
            return { ...state };

        case "GET_BROADCAST_CHANNEL":
            state.broadcast_channel = action.payload;
            return { ...state };
			
	    case "GET_SEARCH_LOCAL":
            state.searchReviews = action.payload;
            return { ...state };	
			

        case "GET_REVIEWS":
            state.reviews_data = action.payload;
            return { ...state };    

        default:
            return state;
    }
};

export default reviews;