
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';

import {Button, Col, FormGroup, Input, Row} from "reactstrap";
import { get_buyers_guide_history } from "../../../actions/buyersGuide";

class HistoryTab extends Component{

    constructor(props) {
        super(props);
        this.state = {
            getHistoryData: [],
            setCurrentPage : 1,
            currentPage : 1,
            pages : 0,
            taxonomyId : '',
            previous: '',
            next: ''
        }
        // const [pages] = useState(Math.round(data.length / dataLimit));
        // const [currentPage, setCurrentPage] = useState(1);

    }

    componentWillReceiveProps(nextProps) {
        
     
        if (nextProps.taxonomyId){
            this.setState({ taxonomyId : nextProps.taxonomyId });
        }

        if (nextProps.getHistoryData && nextProps.getHistoryData.count > 0){
            this.setState({
                getHistoryData : nextProps.getHistoryData,
                previous : nextProps.getHistoryData.previous,
                next : nextProps.getHistoryData.next,
                // pages : Math.round(nextProps.getHistoryData.length / 10)
            })
        }

        if (nextProps.get_buyers_History?.results ){
            this.setState({
                getHistoryData : nextProps.get_buyers_History.results,
                previous : nextProps.get_buyers_History.previous,
                next : nextProps.get_buyers_History.next,
            })
        }

    }

    /* Custom Pagination */
    goToNextPage = (page) => {
        let { currentPage, next } = this.state;
        this.setState({ currentPage : currentPage + 1 });
        let url = next ? next.split('com')[1] : '';
        this.getPaginatedData(url);
    }

    goToPreviousPage = (page) => {
        let { currentPage, previous } = this.state;
        this.setState({ currentPage : currentPage + 1 });
        let url = previous ? previous.split('com')[1] : '';
        this.getPaginatedData(url);
    }

    changePage = (e) => {
       
        const pageNumber = Number(e.target.textContent);
        // setCurrentPage(pageNumber);
    }

    getPaginatedData = (url) => {
        this.props.get_buyers_guide_history(this.state.taxonomyId, url);
    }

    getPaginationGroup = () => {
        // let start = Math.floor((currentPage - 1) / 10) * 10;
        // return new Array(10).fill().map((_, idx) => start + idx + 1);
    }
    /* Custom Pagination */

    render() {
        let { getHistoryData } = this.state;
        return(
            <>
                {/* History Listing - Repeat this */}
                <div>
                    {/* Search in History */}
                    <div className="d-flex">
                        <FormGroup className="ml-auto">
                            <Input className="bg-search bg-search-secondary bg-transparent border text-secondary-dark font-weight-bold" bsSize="sm" type="search" placeholder="Search in History" style={{ width: '250px' }} />
                        </FormGroup>
                    </div>

                    { getHistoryData && Array.isArray(getHistoryData) && getHistoryData?.length !== 0 ?
                        getHistoryData.map((history, index) => (
                            <div className="px-4 py-2 bg-white border-bottom">
                                <Row className="align-items-center">
                                    <Col xs="auto" className="flex-lg-160">
                                        <div className="font-weight-bold ff-alt">
                                            { history.is_approved && history.is_reversed ?
                                                <span className="text-danger">[Reversed]</span> :
                                                history.is_approved?
                                                    <span className="text-primary">[Approved]</span> :
                                                    !history.is_approved?
                                                        <span className="text-muted">[Pending]</span> :
                                                        ''}
                                        </div>
                                    </Col>
                                    <Col className="overflow-hidden">
                                        <div className="text-truncate">
                                            <span>Text changed by {" "}
                                                { history.is_approved && history.is_reversed ?
                                                    <a href="#" className="text-reset font-weight-bold">{history.user}</a> :
                                                    history.is_approved?
                                                        <a href="#" className="text-primary font-weight-bold">{history.user}</a> :
                                                        !history.is_approved?
                                                            <a href="#" className="text-reset font-weight-bold">{history.user}</a> :
                                                            <a href="#" className="text-reset font-weight-bold">{history.user}</a>
                                                }

                                            </span>{" "}
                                            <span className="fs-12 text-muted">{history.created_on}</span>
                                        </div>
                                    </Col>
                                    <Col xs="auto">
                                        <div className="d-flex mx-n3">
                                            <div className="px-3">
                                                <input type="radio" name="selectHistoryType1"/>
                                            </div>
                                            <div className="px-3">
                                                <input type="radio" name="selectHistoryType1"/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        ))
                        :
                        <div className="col-md-12 text-center bg-white p-3 mt-4">
                            <h2 className="text-secondary-dark">No Record Found</h2>
                        </div>
                    }
                    {/* Repeat this */}
                    {/*  <div className="px-4 py-2 bg-white border-bottom">
                        <Row className="align-items-center">
                            <Col xs="auto" className="flex-lg-160">
                                <div className="font-weight-bold ff-alt">
                                    <span className="text-primary">[Approved]</span>
                                </div>
                            </Col>
                            <Col className="overflow-hidden">
                                <div className="text-truncate">
                                    <span>Text changed by <a href="#" className="text-reset font-weight-bold">Sau6402</a></span>{" "}
                                    <span className="fs-12 text-muted">
                                                                    23 hours ago
                                                                </span>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="d-flex mx-n3">
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType1" />
                                    </div>
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType2" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="px-4 py-2 bg-white border-bottom">
                        <Row className="align-items-center">
                            <Col xs="auto" className="flex-lg-160">
                                <div className="font-weight-bold ff-alt">
                                    <span className="text-primary">[Approved]</span>
                                </div>
                            </Col>
                            <Col className="overflow-hidden">
                                <div className="text-truncate">
                                    <span>Text changed by <a href="#" className="text-reset font-weight-bold">Sau6402</a></span>{" "}
                                    <span className="fs-12 text-muted">
                                                                    23 hours ago
                                                                </span>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="d-flex mx-n3">
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType1" />
                                    </div>
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType2" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="px-4 py-2 bg-white border-bottom">
                        <Row className="align-items-center">
                            <Col xs="auto" className="flex-lg-160">
                                <div className="font-weight-bold ff-alt">
                                    <span className="text-primary">[Approved]</span>
                                </div>
                            </Col>
                            <Col className="overflow-hidden">
                                <div className="text-truncate">
                                    <span>Text changed by <a href="#" className="text-reset font-weight-bold">Sau6402</a></span>{" "}
                                    <span className="fs-12 text-muted">
                                                                    23 hours ago
                                                                </span>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="d-flex mx-n3">
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType1" />
                                    </div>
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType2" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="px-4 py-2 bg-white border-bottom">
                        <Row className="align-items-center">
                            <Col xs="auto" className="flex-lg-160">
                                <div className="font-weight-bold ff-alt">
                                    <span className="text-danger">[Reversed]</span>
                                </div>
                            </Col>
                            <Col className="overflow-hidden">
                                <div className="text-truncate">
                                    <span>Text changed by <a href="#" className="text-reset font-weight-bold">Sau6402</a></span>{" "}
                                    <span className="fs-12 text-muted">
                                                                    23 hours ago
                                                                </span>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="d-flex mx-n3">
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType1" />
                                    </div>
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType2" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="px-4 py-2 bg-white border-bottom">
                        <Row className="align-items-center">
                            <Col xs="auto" className="flex-lg-160">
                                <div className="font-weight-bold ff-alt">
                                    <span className="text-danger">[Reversed]</span>
                                </div>
                            </Col>
                            <Col className="overflow-hidden">
                                <div className="text-truncate">
                                    <span>Text changed by <a href="#" className="text-reset font-weight-bold">Sau6402</a></span>{" "}
                                    <span className="fs-12 text-muted">
                                                                    23 hours ago
                                                                </span>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="d-flex mx-n3">
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType1" />
                                    </div>
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType2" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="px-4 py-2 bg-white border-bottom">
                        <Row className="align-items-center">
                            <Col xs="auto" className="flex-lg-160">
                                <div className="font-weight-bold ff-alt">
                                    <span className="text-muted">[Pending]</span>
                                </div>
                            </Col>
                            <Col className="overflow-hidden">
                                <div className="text-truncate">
                                    <span>Text changed by <a href="#" className="text-reset font-weight-bold">Sau6402</a></span>{" "}
                                    <span className="fs-12 text-muted">
                                                                    23 hours ago
                                                                </span>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="d-flex mx-n3">
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType1" />
                                    </div>
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType2" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="px-4 py-2 bg-white border-bottom">
                        <Row className="align-items-center">
                            <Col xs="auto" className="flex-lg-160">
                                <div className="font-weight-bold ff-alt">
                                    <span className="text-primary">[Approved]</span>
                                </div>
                            </Col>
                            <Col className="overflow-hidden">
                                <div className="text-truncate">
                                    <span>Text changed by <a href="#" className="text-reset font-weight-bold">Sau6402</a></span>{" "}
                                    <span className="fs-12 text-muted">
                                                                    23 hours ago
                                                                </span>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="d-flex mx-n3">
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType1" />
                                    </div>
                                    <div className="px-3">
                                        <input type="radio" name="selectHistoryType2" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>*/}
                    {/* Add Pagination here*/}
                    {/*<div className="d-flex justify-content-center text-center">*/}
                    {/*    <div className="pagination-wrapper">*/}
                    {/*        <button className="btn btn-pagination" onClick={this.goToPreviousPage} >Prev</button>*/}
                    {/*        <button className="btn btn-pagination active" onClick={(e) => this.changePage(e)} >1</button>*/}
                    {/*        <button className="btn btn-pagination " onClick={(e) =>this.changePage(e)}>2</button>*/}
                    {/*        <button className="btn btn-pagination" onClick={(e) =>this.changePage(e)}>3</button>*/}
                    {/*        <button className="btn btn-pagination" onClick={(e) =>this.changePage(e)}>4</button>*/}
                    {/*        <button className="btn btn-pagination">. . .</button>*/}
                    {/*        <button className="btn btn-pagination" onClick={this.goToNextPage}>Next</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>



                {/* Compare Button*/}
                <div className="text-right mt-2" hidden>
                    <Button color="primary">Compare</Button>
                </div>

                {/* Compare Div */}
                <div hidden>
                    <div className="d-flex">
                        <div>
                            <div className="font-weight-bold hover-underline" role="button">&lt; back to history</div>
                        </div>
                        <FormGroup className="ml-auto">
                            <Input className="bg-search bg-search-secondary bg-transparent border text-secondary-dark font-weight-bold" bsSize="sm" type="search" placeholder="Search in History" style={{ width: '250px' }} />
                        </FormGroup>
                    </div>

                    <div className="bg-white p-3 my-3">
                        <div className="mb-2">
                            <span className="text-highlight _tertiary">Text Removed</span>
                            {"     "}
                            <span className="text-highlight _warning">Text added</span>
                        </div>
                        <Row xs={1} lg={2}>
                            <Col>
                                <div className="p-2 text-tertiary">
                                    <div className="mb-2">
                                        <span className="text-darker font-weight-bold fs-15">Original</span>{" "}
                                        <span className="text-primary font-weight-bold fs-14">[approved]</span>
                                    </div>

                                    <div>
                                        <div className="d-flex mx-n1 mb-2">
                                            <div className="px-1">
                                                <img width="30" src={require("../../../assets/images/icons/user-circle.png")} alt="User" />
                                            </div>
                                            <div className="px-1 text-tertiary">
                                                Revised by <span className="font-weight-bold text-dark">John Username</span>
                                                <br />
                                                <span className="text-tertiary">3 days ago</span>
                                            </div>
                                        </div>
                                        <div className="text-tertiary mb-2">
                                            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa distinctio ducimus asperiores at, minus nesciunt eius in esse dignissimos doloremque?"
                                        </div>
                                        <div>
                                            history     <span className="text-tertiary font-weight-bold">Supporting proofs:</span>{" "}
                                            <a href="#">
                                                <span className="bg-light p-1">
                                                    <FontAwesomeIcon icon="link" />
                                                </span>
                                            </a>
                                        </div>

                                        <hr />
                                        <p className="text-dark text-highlight _warning mb-2">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero sapiente tempora quia facilis officiis dolor!
                                        </p>
                                        <p className="text-dark mb-2">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero sapiente tempora quia facilis officiis dolor!
                                        </p>
                                        <p className="text-dark text-highlight _warning mb-2">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero sapiente tempora quia facilis officiis dolor!
                                        </p>
                                        <div className="text-right">
                                            <Button color="primary" size="sm">Revert Edit</Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="p-2 text-dark border border-primary">
                                    <div className="mb-2">
                                        <span className="text-darker font-weight-bold fs-15">New</span>{" "}
                                        <span className="text-muted font-weight-bold fs-14">[ending]</span>
                                    </div>
                                    <div>
                                        <div className="d-flex mx-n1 mb-2">
                                            <div className="px-1">
                                                <img width="30" src={require("../../../assets/images/icons/user-circle.png")} alt="User" />
                                            </div>
                                            <div className="px-1 text-dark">
                                                Revised by <span className="font-weight-bold text-primary">Your Name</span>
                                                <br />
                                                <span className="text-tertiary">3 days ago</span>
                                            </div>
                                        </div>
                                        <div className="text-darker mb-2">
                                            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa distinctio ducimus asperiores at, minus nesciunt eius in esse dignissimos doloremque?"
                                        </div>
                                        <div>
                                            <span className="text-darker font-weight-bold">Supporting proofs:</span>{" "}
                                            <a href="#" className="mx-1">
                                                <span className="bg-light p-1">
                                                    <FontAwesomeIcon icon="link" />
                                                </span>
                                            </a>
                                            <a href="#" className="mx-1">
                                                <span className="bg-light p-1">
                                                    <FontAwesomeIcon icon="link" />
                                                </span>
                                            </a>
                                        </div>
                                        <hr />
                                        <p className="text-highlight _tertiary mb-2">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero sapiente tempora quia facilis officiis dolor!
                                        </p>
                                        <p className="mb-2">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero sapiente tempora quia facilis officiis dolor!
                                        </p>
                                        <p className="text-highlight _tertiary mb-2">
                                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero sapiente tempora quia facilis officiis dolor!
                                        </p>
                                        <div className="text-right">
                                            <Button color="primary" size="sm">Revert Edit</Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

const mapState = (state) => ({
    get_buyers_History: state.buyersGuide.get_buyersHistory

});

const mapProps = (dispatch) => ({
    get_buyers_guide_history: (taxonomyId, url) => dispatch(get_buyers_guide_history(taxonomyId, url)),
});

export default connect(mapState, mapProps)(HistoryTab);
