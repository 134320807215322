/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component, Fragment } from 'react';
import AppHeader from '../oragnisms/dashboard/appHeader';
import { Container, Row, Col, Nav, NavItem, NavLink, Button, FormGroup, Label, Input, Collapse, Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import CollageItems from "../oragnisms/business/collageItems";

// import StarsRating from 'stars-rating';
// import MyConnectionsActivity from '../oragnisms/business/myConnectionsActivity';
import UserFeedsRightPanel from '../atoms/userFeedsRightPanel';
import {
    fetch_collage_items, fetch_all_categories, clear_sub_categories, add_to_list, remove_from_list, share_by_email, get_additional_attributes, filter_with_additional_attributes, fetch_items_pin,
    fetch_sub_categories,
    fetch_all_sub_categories_items,
    fetch_friends_sub_categories_items,
    fetch_highly_sub_categories_items,
    fetch_sub_child_categories,
    clear_sub_child_categories,
    reset_sub_categories,
    reset_product_list
} from "../../actions/business";
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";
import { Link } from 'react-router-dom';
import CreateReview from '../../component/atoms/createReview';
import UploadMedia from '../atoms/fileUploadMedia';
import Loader from "../atoms/loader";
import ShareByEmail from '../atoms/shared/shareByEmail';
import ModalComponent from '../atoms/modalComponent';
import { get_uploaded_media } from '../../actions/itemDetail';
import { detectlink, getReviewSuffix, pushUniqueObjectsInArray } from '../../utils/utilities';
import ReactHtmlParser from 'react-html-parser';
class Business extends Component {
    constructor(props) {
        super(props)

        this.category = false;
        this.state = {
            viewEditReviewStep: 'writeReview',
            setChildrens: {},
            deleteId: 0,
            activeCategoryFilter: 'all',
            selectedItems: null,
            productsList: null,
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: '',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            shareByEmailModalToggle: false,
            shareId: 0,
            shareError: {},
            features: {},
            prices: {},
            deletedItems: {},
            enableScroll: true,
            setCurrentPage: 1,
            listLoading: true,
            listingPins: {},
            forceUpdateProductList: false,
            subcat: null,
            subCatItem: null,
            allSubCategories: null,
            friendsSubCategories: null,
            highlySubCategories: null,
            enableLoading: true,
            enableStarRatings: {},
            profileInfo: {},
            toggleIcons: {},
            toggleItemCreateReview: {},
            isEnableFileUpload: false,
            uploadedMediaSet: {
                embedLinks: [],
                uploadedFiles: [],
            },
            uploadedMediaSetFollowUp: {
                embedLinks: [],
                uploadedFiles: [],
            },
			currentLat: 37.7749295,
            currentLong: -122.4194155,
            categoryId: 0,
            city: "San Francisco",
            state: "CA",
            firstReviewReadMore: {},
            this_user: null,
            mediaSet: [],
            mediaSetFollowUp: [],
            starRatingApplied: false,
            stars: 0
        }
    }

    fetchSubCategories = () => {
        let { subCatItem, activeCategoryFilter } = this.state;

        this.props.fetch_sub_categories({ sub_cat: subCatItem.label, level: 1 });

        if (activeCategoryFilter == "all") {
            this.props.fetch_all_sub_categories_items({ cat: subCatItem.id, page: 1 });
        }
    }

    getParams = (url) => {
        let params = {};
        let parser = document.createElement('a');
        parser.href = url;
        let query = parser.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };

    componentWillReceiveProps(nextProps) {
        let { setChildrens, productsList, setCurrentPage, listingPins, forceUpdateProductList, allSubCategories, friendsSubCategories, highlySubCategories } = this.state;

        const { products_list, all_sub_categories, friends_sub_categories, highly_sub_categories, } = nextProps;
        let products_params = null;
        let filter_all_sub_categories = null;
        // let filter_friends_sub_categories = null;
        let filter_highly_sub_categories = null;

        if (nextProps.this_user) {
            this.setState({this_user: nextProps.this_user.id})
        }

        if (products_list?.next) {
            products_params = this.getParams(products_list?.next);
            if (products_params?.page !== null) {
                products_params.page = parseInt(products_params?.page);
            }
        }

        // all_sub_categories -- start

        if (all_sub_categories?.next) {
            filter_all_sub_categories = this.getParams(all_sub_categories?.next);
            if (filter_all_sub_categories?.page !== null) {
                filter_all_sub_categories.page = parseInt(filter_all_sub_categories?.page);
            }
        }

        if (allSubCategories == null && all_sub_categories?.results?.length) {
            this.setState({ allSubCategories: all_sub_categories, enableLoading: false }, () => {
                let enableMoreScroll = false;
                if (this.state.allSubCategories?.count > this.state.allSubCategories?.results?.length) {
                    enableMoreScroll = true;
                }
                this.setState({ enableScroll: enableMoreScroll })
            });
        }

        if (allSubCategories?.results?.length && allSubCategories !== null && all_sub_categories?.results?.length) {
            let items = allSubCategories?.results;
            let newItems = all_sub_categories?.results;
            allSubCategories['results'] = items.concat(newItems);

            this.setState({ allSubCategories, enableLoading: false }, () => {
                let enableMoreScroll = false;
                if (this.state.allSubCategories?.count > this.state.allSubCategories?.results?.length) {
                    enableMoreScroll = true;
                }
                this.setState({ enableScroll: enableMoreScroll });
            })
        }


        // friends_sub_categories -- start

        if (friendsSubCategories == null && friends_sub_categories) {
            this.setState({ friendsSubCategories: friends_sub_categories, enableLoading: false });
        }

        // all_sub_categories -- end

        // highly_sub_categories -- start

        if (highly_sub_categories?.next) {
            filter_highly_sub_categories = this.getParams(highly_sub_categories?.next);
            if (filter_highly_sub_categories?.page !== null) {
                filter_highly_sub_categories.page = parseInt(filter_highly_sub_categories?.page);
            }
        }

        if (highlySubCategories == null && highly_sub_categories?.results?.length) {
            this.setState({ highlySubCategories: highly_sub_categories, enableLoading: false }, () => {
                let enableMoreScroll = false;
                if (this.state.highlySubCategories?.count > this.state.highlySubCategories?.results?.length) {
                    enableMoreScroll = true;
                }
                this.setState({ enableScroll: enableMoreScroll })
            });
        }

        if (highlySubCategories?.results?.length && highlySubCategories !== null && highly_sub_categories?.results?.length) {
            let items = highlySubCategories?.results;
            let newItems = highly_sub_categories?.results;
            highlySubCategories['results'] = items.concat(newItems);

            this.setState({ highlySubCategories, enableLoading: false }, () => {
                let enableMoreScroll = false;
                if (this.state.highlySubCategories?.count > this.state.highlySubCategories?.results?.length) {
                    enableMoreScroll = true;
                }
                this.setState({ enableScroll: enableMoreScroll });
            })
        }

        // highly_sub_categories -- end


        if (nextProps?.listing_pins?.id) {
            let id = nextProps?.listing_pins?.id;
            listingPins[id] = nextProps?.listing_pins;
            this.setState({ listingPins });
        }

        // if ((products_list && Object.keys(products_list).length && (productsList == null || forceUpdateProductList) && (products_params?.page == 2 || products_params == null)) || products_list?.count !== productsList?.count) {
        //     productsList = {};
        //     this.setState({
        //         productsList: products_list,
        //         forceUpdateProductList: false,
        //         setCurrentPage: 1,
        //         listLoading: false
        //     }, () => {
        //         let enableMoreScroll = false;
        //         if (this.state.productsList?.count > this.state.productsList?.results?.length) {
        //             enableMoreScroll = true;
        //         }
        //         this.setState({ enableScroll: enableMoreScroll });
        //     });
        // }

        // if ((setCurrentPage > 1 && products_params?.page > 2 && productsList?.results?.length) || (products_list?.next == null && productsList?.results?.length)) {

        //     if ((Object.keys(this.state.features).length > 1 || Object.keys(this.state.prices).length > 1) && Object.keys(this.state.enableStarRatings).length == 0) {
        //         productsList = {};
        //         this.setState({
        //             productsList: products_list,
        //             forceUpdateProductList: false,
        //             setCurrentPage: 1,
        //             listLoading: false,
        //             enableScroll: false
        //         });
        //     } else {
        //         let items = productsList?.results;
        //         let newItems = nextProps?.products_list?.results;

        //         items = items.concat(newItems);

        //         productsList['results'] = items;

        //         let enableMoreScroll = false;
        //         if (productsList?.count > items.length) {
        //             enableMoreScroll = true;
        //         }
        //         this.setState({ productsList, enableScroll: enableMoreScroll, listLoading: false });

        //     }
        // }

        if ((products_list && Object.keys(products_list).length) ){
            const updatedList = pushUniqueObjectsInArray(
                (productsList?.results || []),
                (products_list.results || []),
                'id'
            );

           const tempProductListing = {
                ...products_list,
                results: [
                    ...updatedList ]
            }

            this.setState({
                productsList: tempProductListing,
                forceUpdateProductList: false,
                listLoading: false
            }, () => {
                let enableMoreScroll = false;
                if (this.state.productsList?.count > this.state.productsList?.results?.length) {
                    enableMoreScroll = true;
                }
                this.setState({ enableScroll: enableMoreScroll });
            });
        }

        if (nextProps?.sub_categories) {
            if (nextProps?.sub_categories[0]?.parent) {
                let id = nextProps?.sub_categories[0]?.parent;
                if (setChildrens[id] == undefined) {
                    setChildrens[id] = nextProps.sub_categories;
                    this.setState({ setChildrens });
                }
            }
        }

        if (Object.keys(this.state.profileInfo).length == 0) {
            if (nextProps.my_profile_info) {
                this.setState({
                    profileInfo: nextProps.my_profile_info
                })
            }
        }
		
		
		if (
            nextProps.current_location &&
            Object.keys(nextProps.current_location).length > 0
        ) {
            if (nextProps.current_location?.lat != this.props.current_location?.lat && nextProps.current_location?.lng != this.props.current_location?.lng && this.props.current_location != null) {
                this.setState(
                    {
                        currentLat: nextProps.current_location?.lat,
                        currentLong: nextProps.current_location?.lng,
                        city: nextProps.current_location?.city,
                        state: nextProps.current_location?.state != null ? nextProps.current_location?.state : "",
                    },
                    () => {
                       this.fetchItems();
					}
                );
            }

        }
        
        if (nextProps.products_list !== this.props.products_list && this.state.enableScroll) {
            this.setState({
                productsList: nextProps.products_list,
                forceUpdateProductList: false,
                setCurrentPage: 1,
                listLoading: false
            })
            window.scrollTo(0, 0);
        }
        if ("isEditReviewEnabled" in nextProps) {
            if (this.state.isEditReviewEnabled !== nextProps.isEditReviewEnabled) {

                this.revertBackTheImages();
            }
            this.setState({ isEditReviewEnabled: nextProps.isEditReviewEnabled })
        }
        /*  if (category) {
            this.category = category;
        } else {
            this.category = false;
        }*/
        // this.props.fetch_items_pin(item)
    }
    revertBackTheImages = () =>{
        const tempReviews = [...this.state.reviews];
        const foundIndex = tempReviews.findIndex(r=> r.id ===this.props.isEditedReviewId)
        
        if(foundIndex >-1){
            let reviewmedia_set_copy =  tempReviews[foundIndex].reviewmedia_set
            this.state.newlyUpdatedMedias.forEach((media)=>{
                if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.id===media?.id)){
                    reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.id!==media.id)
                }                
            })
           
            this.state.newlyUpdatedEmbedMedias.forEach((media)=>{
                if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.url===media?.url)){
                    reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.url!==media.url)
                }                
            })
            this.state.deletedMedias?.forEach((media)=>{                
                reviewmedia_set_copy.push(media)                              
            })

            tempReviews[foundIndex].reviewmedia_set = reviewmedia_set_copy;

            this.setState({reviews: tempReviews,deletedMedias:[]},()=>{
            })
        }
    }
    /* componentDidUpdate() {
        if (this.props?.match?.params?.category) {
            if (this.state.subCatItem == null) {
                this.category = false;
                this.props.history.push('/business')
            }
            this.category = true;
        }
    } */

    componentDidUpdate(prevProps) {        
        let { mediaSet,mediaSetFollowUp, uploadedMediaSet,uploadedMediaSetFollowUp } = this.state;
        if (this.props.location !== prevProps.location) {
            this.category = false;
            if (!this.category) {
                let id = this?.props?.history?.location?.state?.id;
                if (id) {
                    this.category = true;
                    this.setState({ subCatItem: { id }, enableLoading: false }, () => {
                        this.props.fetch_all_sub_categories_items({ cat: id, page: 1 });
                    })
                } else {
                    this.category = false;
                }
            }
        }

        if (this?.props?.history?.location?.state?.id) {
            if (!this.category) {
                let id = this?.props?.history?.location?.state?.id;
                if (id) {
                    this.category = true;
                    this.setState({ subCatItem: { id }, enableLoading: false }, () => {
                        this.props.fetch_all_sub_categories_items({ cat: id, page: 1 });
                    })
                }
            }
        } else {
            if (this.props?.match?.params?.category) {
                if (this.state.subCatItem == null) {
                    this.category = false;
                    this.props.history.push('/business');
                }
                this.category = true;
            }
        }

        let uploadedFiles = uploadedMediaSet?.uploadedFiles;

        if (Array.isArray(uploadedFiles) && Array.isArray(mediaSet) && uploadedFiles?.length !== mediaSet?.length) {

            this.setState({ mediaSet: uploadedFiles });
        }

        let uploadedFilesFollowUp = uploadedMediaSetFollowUp?.uploadedFiles;

        if (Array.isArray(uploadedFilesFollowUp) && Array.isArray(mediaSetFollowUp) && uploadedFilesFollowUp?.length !== mediaSetFollowUp?.length) {

            this.setState({ mediaSetFollowUp: uploadedFilesFollowUp });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({setChildrens: {}, deletedItems: {}, starRatingApplied: false, stars: 0, setCurrentPage: 1 })
        this.props.reset_sub_categories();
        this.props.reset_product_list();
        this.props.get_additional_attributes('business');
        this.fetchItems();
		document.title = `Business Reviews on WikiReviews- Local, National, or Global `;
		window.addEventListener("scroll", this.handleScroll, { passive: false });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        let { productsList, enableScroll, setCurrentPage, subcat, subCatItem, allSubCategories, friendsSubCategories, highlySubCategories, starRatingApplied, stars } = this.state;
        let { fetch_collage_items } = this.props;

        if (productsList?.results?.length && starRatingApplied) {
            let itemEnd = document.getElementById("last-key");

            if (itemEnd) {
                let elem = itemEnd;
                let distanceScrolled = document.body.scrollTop - 300;
                let elemRect = elem.getBoundingClientRect();
                let elemViewportOffset = elemRect.top;
                let totalOffset = distanceScrolled + elemViewportOffset - 350;

                if (totalOffset < 0 && enableScroll) {
                    this.setState({ enableScroll: false, setCurrentPage: setCurrentPage + 1, listLoading: true }, () => {
                        let data = { content_type: 'business',
                        stars: stars,
                        cat: '',
                        subcat: '',
                        to_api: true,
                        showReview: true,
                        cPage: true,
                        location: false,
                        page: setCurrentPage+1 };
                        
                        fetch_collage_items(data).then(() => this.setState({ listLoading: false, enableLoading: false }));
                    });
                }
            } 
        } else if (productsList?.results?.length && !this.category) {
            // let windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
            let itemEnd = document.getElementById("last-key");

            if (itemEnd) {
                let elem = itemEnd;
                let distanceScrolled = document.body.scrollTop - 300;
                let elemRect = elem.getBoundingClientRect();
                let elemViewportOffset = elemRect.top;
                let totalOffset = distanceScrolled + elemViewportOffset - 350;

                if (totalOffset < 0 && enableScroll) {
                    this.setState({ enableScroll: false, setCurrentPage: setCurrentPage + 1, listLoading: true }, () => {
                        let data = { content_type: 'business', cat: '', to_api: true, showReview: true, cPagebest: true, location: true, page: this.state.setCurrentPage };
                        if (subcat) {
                            data['subcat'] = subcat;
                        }
                        fetch_collage_items(data).then(() => this.setState({ listLoading: false, enableLoading: false }));
                    });
                }
            } // if condition ends here.
        }

        if (allSubCategories?.results?.length && this.category && subCatItem?.id && this.state.activeCategoryFilter == "all") {
            let itemEnd = document.getElementById("last-key");

            if (itemEnd) {
                let elem = itemEnd;
                let distanceScrolled = document.body.scrollTop - 300;
                let elemRect = elem.getBoundingClientRect();
                let elemViewportOffset = elemRect.top;
                let totalOffset = distanceScrolled + elemViewportOffset - 350;

                if (totalOffset < 0 && enableScroll) {
                    this.setState({ enableScroll: false, setCurrentPage: setCurrentPage + 1, enableLoading: true }, () => {
                        this.props.fetch_all_sub_categories_items({ cat: subCatItem?.id, page: this.state.setCurrentPage });
                    })
                }
            }
        }


        if (friendsSubCategories?.results?.length && this.category && subCatItem?.id && this.state.activeCategoryFilter == "friendsPick") {
            let itemEnd = document.getElementById("last-key");

            if (itemEnd) {
                let elem = itemEnd;
                let distanceScrolled = document.body.scrollTop - 300;
                let elemRect = elem.getBoundingClientRect();
                let elemViewportOffset = elemRect.top;
                let totalOffset = distanceScrolled + elemViewportOffset - 350;

                if (totalOffset < 0 && enableScroll) {
                    this.setState({ enableScroll: false, setCurrentPage: setCurrentPage + 1, enableLoading: true }, () => {
                        this.props.fetch_friends_sub_categories_items({ cat: subCatItem?.id, page: this.state.setCurrentPage });
                    })
                }
            }
        }

        if (highlySubCategories?.results?.length && this.category && subCatItem?.id && this.state.activeCategoryFilter == "highlyRecommended") {
            let itemEnd = document.getElementById("last-key");

            if (itemEnd) {
                let elem = itemEnd;
                let distanceScrolled = document.body.scrollTop - 300;
                let elemRect = elem.getBoundingClientRect();
                let elemViewportOffset = elemRect.top;
                let totalOffset = distanceScrolled + elemViewportOffset - 350;

                if (totalOffset < 0 && enableScroll) {
                    this.setState({ enableScroll: false, setCurrentPage: setCurrentPage + 1, enableLoading: true }, () => {
                        this.props.fetch_highly_sub_categories_items({ cat: subCatItem?.id, page: this.state.setCurrentPage });
                    })
                }
            }
        }
    }

    fetchItems = () => {
        this.category = false;
        this.setState({ selectedItems: null }, () => {
            Promise.all([
                this.props.fetch_collage_items({ content_type: 'business', catTrending: 'True', size: 6, mainPage: 'True' }), // fetch categories
                this.props.fetch_collage_items({ content_type: 'business', cat: '', to_api: true, showReview: true, cPagebest: true, location: true, page: 1 }), // fetch products
                this.props.fetch_all_categories({ category: 'business', depth: 'false', delsel: '', ancestor: '', sub_cat: '', level: 1 }),
            ])
                .then(() => {
                    this.setState({
                        enableLoading: false,
                        listLoading: false
                    })
                });

        });
    }

    getCategories = (item) => {
        let { setChildrens, deletedItems } = this.state;
        let id = item.id;

        if (setChildrens[id] == undefined) {
            deletedItems[id] = false;
            this.props.fetch_all_categories({ category: id, depth: 'true', delsel: '', ancestor: '', sub_cat: '', level: '', enableSubChild: true });
        }

        if (setChildrens[id]) {
            deletedItems[id] = !deletedItems[id];
        }

        this.setState({ deletedItems });
    }

    sortItems = (a, b) => {
        if (a.category > b.category) {
            return 1;
        } else if (a.category < b.category) {
            return -1;
        } else {
            return 0;
        }
    }

    itemsSet = (item, isParent) => {
        if (!isParent) {
            this.setState({ selectedItems: item, setCurrentPage: 1, forceUpdateProductList: true, subcat: item.id, productsList: null, listLoading: true }, () => {
                this.props.fetch_collage_items({ content_type: 'business', subcat: item.id, to_api: true, showReview: true, cPage: true, location: false, cPagebest: true, page: 1 }).then(()=>this.setState({listLoading:false,enableLoading:false}));; // fetch by rating
                let elmnt = document.getElementById("top-bar-start");
                if (elmnt) {
                    elmnt.scrollIntoView(true);
                }
            })
        } else {
            if (item?.seo_label) {
                this.setState({ subCatItem: item }, () => {
                    this.props.history.replace(item?.seo_label);
                    this.fetchSubCategories();
                })
            }
        }
    }


    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
    }

    startsRating = (count) => {
        let enableItem = {}
        enableItem[count] = true;
        this.setState({ 
            enableStarRatings: enableItem,
            productsList: [],
            listLoading: true,
            enableLoading: true,
            starRatingApplied: true,
            stars: count,
            setCurrentPage: 1
        }, () => {
            const data = {
                content_type: 'business',
                cat: '',
                subcat: '',
                to_api: true,
                showReview: true,
                cPage: true,
                location: false,
                page: this.state.setCurrentPage
            };

            if(this.state.stars) data['stars'] = this.state.stars;
            window.scrollTo(0, 0);
            this.props.fetch_collage_items(data)
                .then(() => this.setState({
                    listLoading: false,
                    enableLoading: false
                })); // fetch by rating
                window.scrollTo(0, 0) 
        })
    }

    filterByFeatures = (name) => {
        const { features, productsList, prices } = this.state;
        let attributeKeys = Object.keys(features);
        const priceKeys = Object.keys(prices);
        const targetIds = productsList?.results?.length > 0 ? productsList?.results?.map(i => i.id) : [];

        if (features[name]) {
            delete features[name];
            attributeKeys = attributeKeys.filter(i => i !== name);
        } else {
            features[name] = true;
            attributeKeys.push(name);
        }

        this.setState({
            features: features
        }, () => {
            if (priceKeys.length > 0 || attributeKeys.length > 0) {
                let url = `/api/getadditionalattributes/?attribute=${attributeKeys.length > 0 ? attributeKeys.join(',') : ''}&price=${priceKeys.length > 0 ? priceKeys.join(',') : ''}&target_id=${targetIds.length > 0 ? targetIds.join(',') : ''}`;
                this.props.filter_with_additional_attributes(url, targetIds.length > 0 ? productsList?.results : []);
            } else {
                this.props.fetch_collage_items({ content_type: 'business', cat: '', to_api: true, showReview: true, cPagebest: true, location: true, page: 1 }).then(() => this.setState({ listLoading: false, enableLoading: false }));
            }
        });
    }

    filterByPrices = (name) => {
        const { features, productsList, prices } = this.state;
        const attributeKeys = Object.keys(features);
        let priceKeys = Object.keys(prices);
        const targetIds = productsList?.results?.length > 0 ? productsList?.results?.map(i => i.id) : [];

        if (prices[name]) {
            delete prices[name];
            priceKeys = priceKeys.filter(i => i !== name);
        } else {
            prices[name] = true;
            priceKeys.push(name);
        }

        this.setState({
            prices: prices
        }, () => {
            if (priceKeys.length > 0 || attributeKeys.length > 0) {
                let url = `/api/getadditionalattributes/?attribute=${attributeKeys.length > 0 ? attributeKeys.join(',') : ''}&price=${priceKeys.length > 0 ? priceKeys.join(',') : ''}&target_id=${targetIds.length > 0 ? targetIds.join(',') : ''}`;
                this.props.filter_with_additional_attributes(url, targetIds.length > 0 ? productsList?.results : []);
            } else {
                this.props.fetch_collage_items({ content_type: 'business', cat: '', to_api: true, showReview: true, cPagebest: true, location: true, page: 1 }).then(() => this.setState({ listLoading: false, enableLoading: false }));
            }
        });
    }

    handleOnClickChangeTab = (item) => {
        let { subCatItem } = this.state;

        if (subCatItem?.id) {
            if (item == "friendsPick") {
                this.props.fetch_friends_sub_categories_items({ cat: subCatItem?.id, page: 1 });
                this.setState({ activeCategoryFilter: item, allSubCategories: null, highlySubCategories: null, enableLoading: true });
            }

            if (item == "highlyRecommended") {
                this.props.fetch_highly_sub_categories_items({ cat: subCatItem?.id, page: 1 });
                this.setState({ activeCategoryFilter: item, allSubCategories: null, friendsSubCategories: null, enableLoading: true });
            }

            if (item == "all") {
                this.props.fetch_all_sub_categories_items({ cat: subCatItem?.id, page: 1 });
                this.setState({ activeCategoryFilter: item, friendsSubCategories: null, highlySubCategories: null, enableLoading: true });
            }

        } else {
            this.props.history.push('/business')
        }

    }

    handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote}) => {
        const {shareId} = this.state;
        let key = 'listing_id';

        let url = `/api/shareByemail/`;

        const data = {
            [key]: shareId,
            mix_uid: `${shareEmail.split("@")[0]}_${shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            listing_type: "business",
            optional_note: shareNote
        };

        this.props.share_by_email(url, data);

        this.setState({
            shareByEmailModalToggle: false,
        });
    }

    handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareId: id
        });
    }

    handleOnClickAddToList = (type, status, item) => {
        let message = '',
            url = '',
            nextUrl = '',
            key = '',
            data = { type };
        // let {profileInfo} = this.state;
        let productsList = this.state.productsList;
        if (type.includes('want')) {
            key = 'user_want_status';
        } else if (type.includes('favourite')) {
            key = 'user_favourites_status';
        } else {
            key = 'user_watch_status';
        }

        if (status) {
            message = `${item.name} has been removed from your ${type}!`;
            url = `/api/pins/${item.id}/?type=${type}`;
            this.props.remove_from_list(url, message, nextUrl);
            item.pins[key] = false;
        } else {
            data.user_entry = item.id;
            message = `${item.name} has been added to your ${type}!`;
            url = `/api/pins/?type=${type}&user=`;
            this.props.add_to_list(url, data, message, nextUrl);
            item.pins[key] = true;
        }
        let newResults = productsList.results.map(i => {
            if (i.id === item.id) {
                return item;
            } else {
                return i;
            }
        })

        this.setState({
            productsList: {
                ...this.state.productsList,
                results: newResults
            }
        });

    }

    renderAddress = ({ item }) => {
        return (
            `${item.address1 !== null ? item.address1 + `, ` : ''} 
             ${item.address2 !== null ? item.address2 + `, ` : ''} 
             ${item.city !== null ? item.city + `, ` : ''}  
             ${item.country !== null ? item.country + `, ` : ''} 
              ${item.state !== null ? item.state + `, ` : ''}  
               ${item.zipcode !== null ? item.zipcode : ''}`
        )
    }

    renderItemsBySub = ({ productsList }) => {
        let filteredItems = [];

        if (productsList && productsList?.results && Array.isArray(productsList?.results) && productsList?.results?.length && this.category) {
            filteredItems = productsList?.results;
        }

        if (productsList && Array.isArray(productsList) && productsList?.length && this.category) {
            filteredItems = productsList;
        }

        if (filteredItems && Array.isArray(filteredItems) && filteredItems?.length && this.category) {
            return filteredItems.map((item, index) => {
                let detailedRatingKeys = [];

                if (item?.detailed_rating) {
                    detailedRatingKeys = Object.keys(item?.detailed_rating).filter(k => !k.includes('review') && !k.includes('rating'));
                }

                return (
                    <div id={`${(filteredItems?.length - 1 == index) ? 'last-key' : `index-${index}`}`} className={`bg-white text-dark p-3 mb-1`}>
                        <div className="d-flex mx-n2 mb-2">
                            <div className="px-2">
                                <a href="#" className="text-decoration-none">
                                    <img width="111" src="https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png" alt="" />
                                </a>
                            </div>
                            <div className="px-2 col">
                                <div className="d-flex mx-n2">
                                    <div className="px-2 col">
                                        <div className="ff-base">
                                            <Link
                                                to={{
                                                    pathname: `/${(item?.content_type).toLowerCase()}/${item?.slug || item?.id}`,
                                                    state: { id: item?.id }
                                                }}
                                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none">
                                                {`${index + 1}. ${item.name}`}
                                            </Link>

                                            <div className="mt-2">
                                                <span className="d-inline-block hoverable-rating">
                                                    <img
                                                        title={item.total_rating?.avg_rating[1]}
                                                        src={item.total_rating?.avg_rating[1] !== 'No Rating' ?
                                                            require(`../../assets/images/icons/${Math.floor(item.total_rating?.avg_rating[1])}rating.png`) :
                                                            require('../../assets/images/icons/norating.png')}
                                                        alt="" />
                                                    <div className="detail-rating-onhover">
                                                        <div className="text-dark font-weight-bold mb-2">
                                                            {'Detailed Star Rating'}
                                                        </div>
                                                        <div>
                                                            {detailedRatingKeys.length > 0 && detailedRatingKeys.map(keyRating => {
                                                                return (
                                                                    <div className="d-flex flex-nowrap align-items-center">
                                                                        <img
                                                                            title={item.detailed_rating[keyRating][1]}
                                                                            className="mr-1"
                                                                            src={item.detailed_rating[keyRating][1] !== 'No Rating' ?
                                                                                require(`../../assets/images/icons/${Math.floor(item.detailed_rating[keyRating][1])}rating.png`) :
                                                                                require('../../assets/images/icons/norating.png')}
                                                                            alt="" />
                                                                        <span className="ff-alt">{keyRating}</span>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </span>&nbsp;<span>{item.total_rating?.total_review} {getReviewSuffix(item.total_rating?.total_review)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-2 col-auto">
                                        <div className="d-inline-flex flex-nowrap mx-n1">
                                            <div className="px-1">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                        <div className="share-it-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary-alt" right>
                                                        <DropdownItem className="fs-14">
                                                            <FacebookShareButton
                                                                url={`${this.state.protocol}//${this.state.host}/business/${item.id}/${item.slug ? item.slug : ''}`}
                                                                quote={`Check out ${item.name}, ${item.address[0]?.city}, ${item.state} on `}
                                                                hashtag={this.state.fbHashTag}>
                                                                {'Facebook'}
                                                            </FacebookShareButton>
                                                        </DropdownItem>
                                                        <DropdownItem className="fs-14">
                                                            <TwitterShareButton
                                                                url={`${this.state.protocol}//${this.state.host}/business/${item.id}/${item.slug ? item.slug : ''}`}
                                                                title={`Check out ${item.name}, ${item.address[0]?.city}, ${item.address[0]?.state} on`}
                                                                via={this.state.shareVia}
                                                                hashtags={this.state.twitterHashTags}>
                                                                {'Twitter'}
                                                            </TwitterShareButton>
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            className="fs-14"
                                                            onClick={() => this.handleOnClickShareByEmail(item.id)}>
                                                            {'By Email'}
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            <div className="px-1">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                        <div className="thumbpin-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary-alt" right>
                                                        <DropdownItem
                                                            className="fs-14"
                                                            onClick={() => this.handleOnClickAddToList('want list', item.pins?.user_want_status, item)}>
                                                            <div className="addto-icon want-list"></div>
                                                            {item.pins?.user_want_status ? 'Added' : 'Add'} {'to want list'}
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            className="fs-14"
                                                            onClick={() => this.handleOnClickAddToList('favourite list', item.pins?.user_favourites_status, item)}>
                                                            <div className="addto-icon favorite-list"></div>
                                                            {item.pins?.user_favourites_status ? 'Added' : 'Add'} {'to favorites'}
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            className="fs-14"
                                                            onClick={() => this.handleOnClickAddToList('watch list', item.pins?.user_watch_status, item)}>
                                                            <div className="addto-icon watch-list"></div>
                                                            {item.pins?.user_watch_status ? 'Added' : 'Add'} {'to watch list'}
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ff-alt">
                                    <div className="mt-2">
                                        <span className="font-weight-bold">
                                            {item?.address &&
                                                item?.address[0] &&
                                                item?.address[0].address1 &&
                                                item?.address[0].address1 !== null ? this.renderAddress({ item: item?.address[0] }) : ''}
                                        </span>
                                    </div>
                                </div>
                                <div className="ff-base">
                                    <div className="mt-2">
                                        {item.taxonomy_list.map((item_tax, tax_index) => (
                                            <span>{item_tax?.category}{(item?.taxonomy_list?.length > 1 && item?.taxonomy_list?.length - 1 !== tax_index) ? ` , ` : ''}</span>
                                        ))}
                                    </div>
                                </div>
                                <div className="ff-alt">
                                    <div className="mt-2">
                                        <span>{this.formatPhoneNumber(item?.phone_set[0]?.phone)}</span>
                                    </div>
                                </div>

                                <div className="text-right mt-3">
                                    <Link
                                        to={{
                                            pathname: `/${(item?.content_type).toLowerCase()}/${item?.slug || item?.id}`,
                                            state: { id: item?.id }
                                        }}
                                        className="text-dark font-weight-bold">{'view more'}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        } else {
            if (!this.state.enableLoading) {
                return (
                    <div id="top-bar-start" className="bg-white p-3">
                        <h2 className="text-secondary-dark">No results found!</h2>
                    </div>
                )
            }
        }

        // if (this.state.enableLoading) {
        //     return (
        //         <div className="alert alert-primary fade show" role="alert">Loading...</div>
        //     )
        // }
    }


    hitAndFetchSubCat = ({ id }) => {
        this.setState({
            allSubCategories: null,
            friendsSubCategories: null,
            highlySubCategories: null
        }, () => {
            if (this.state.activeCategoryFilter == "all") {
                this.props.fetch_all_sub_categories_items({ cat: id, page: 1 });
            }

            if (this.state.activeCategoryFilter == "friendsPick") {
                this.props.fetch_friends_sub_categories_items({ cat: id, page: 1 });
            }

            if (this.state.activeCategoryFilter == "highlyRecommended") {
                this.props.fetch_highly_sub_categories_items({ cat: id, page: 1 });
            }

        })
    }


    closeAfterCancel = () => {
        this.setState({ toggleItemCreateReview: {} })
    }

    toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false, reviewOneToggler,followUpStatus) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
        this.setState({
            reviewOneToggler: reviewOneToggler? true: false,
            followUpStatus:followUpStatus
        })
    }

    onPublishReview = () => {
        this.setState({
            toggleItemCreateReview: {},
            toggleItemReadMore: {},
            moviesList: [],
            currentPageIndex: 1
        }, () => {
            this.props.get_additional_attributes('business');
            this.fetchItems();
        })
    }

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet,uploadedMediaSetFollowUp } = this.state;
        let uploadedMediaSetCopy = { ...uploadedMediaSet }
        let uploadedMediaSetFollowUpCopy = {...uploadedMediaSetFollowUp}
        const key = 'id'
        const keyEmbed = 'thumbnail'

        if(this.state.reviewOneToggler){
           if(this.state.followUpStatus){
            if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                let mediaSetStored = uploadedMediaSetFollowUpCopy?.uploadedFiles?.concat(mediaSet)
                const updatedMedia =[...mediaSetStored]
                const mediaSetUnique = [...new Map(updatedMedia?.map(item =>
                    [item[key], item])).values()];            
                this.setState(prevState => ({
                    uploadedMediaSetFollowUp: {
                        ...prevState.uploadedMediaSetFollowUp,
                        uploadedFiles:  mediaSetUnique
                       }
                }), () => {
                    this.props.get_uploaded_media_follow(uploadedMediaSetFollowUp);                
                })
            }

            if (links && Array.isArray(links) && links.length > 0) {
                let linksStored = uploadedMediaSetFollowUpCopy?.embedLinks?.concat(links) 
                const updatedEmbedMedia =[...linksStored]
                const mediaSetEmbedUnique = [...new Map(updatedEmbedMedia?.map(item =>
                    [item[keyEmbed], item])).values()];                         
                this.setState(prevState => ({
                    uploadedMediaSetFollowUp: {
                        ...prevState.uploadedMediaSetFollowUp,
                        embedLinks: mediaSetEmbedUnique
                    }
                }), () => {
                    this.props.get_uploaded_media_follow(uploadedMediaSetFollowUp);
                })
            }

           } else{

            if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                let mediaSetStored = uploadedMediaSetCopy?.uploadedFiles?.concat(mediaSet)
                const updatedMedia =[...this.props.editExistingMedia,...mediaSetStored]
                const mediaSetUnique = [...new Map(updatedMedia?.map(item =>
                    [item[key], item])).values()];            
                this.setState(prevState => ({
                    uploadedMediaSet: {
                        ...prevState.uploadedMediaSet,
                        uploadedFiles:  mediaSetUnique
                       }
                }), () => {
                    this.props.get_uploaded_media(uploadedMediaSet);                
                })
            }
    
            if (links && Array.isArray(links) && links.length > 0) {
                let linksStored = uploadedMediaSetCopy?.embedLinks?.concat(links) 
                const updatedEmbedMedia =[...this.props.editExistingEmbedMedia,...linksStored]
                const mediaSetEmbedUnique = [...new Map(updatedEmbedMedia?.map(item =>
                    [item[keyEmbed], item])).values()];                         
                this.setState(prevState => ({
                    uploadedMediaSet: {
                        ...prevState.uploadedMediaSet,
                        embedLinks: mediaSetEmbedUnique
                    }
                }), () => {
                    this.props.get_uploaded_media(uploadedMediaSet);
                })
            }
           }
        } else {
            if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                const tempReviews = [...this.state.reviews];
                const foundIndex = tempReviews.findIndex(r=> r.id ===this.props.isEditedReviewId)

                if(foundIndex >-1){
                    mediaSet.forEach(media=>{
                        
                        tempReviews[foundIndex].reviewmedia_set.push(media);
                    })
    
                    this.setState({reviews: tempReviews, newlyUpdatedMedias:[...this.state.newlyUpdatedMedias, ...mediaSet] })
                }
            }

            if(links && Array.isArray(links) && links.length > 0){
                const tempReviews = [...this.state.reviews];
                const foundIndex = tempReviews.findIndex(r=> r.id ===this.props.isEditedReviewId)

                if(foundIndex >-1){
                    links.forEach(link=>{
                        tempReviews[foundIndex].reviewmedia_set.push(link);
                    })
    
                    this.setState({reviews: tempReviews , newlyUpdatedEmbedMedias:[...this.state.newlyUpdatedEmbedMedias,...links]})
                }
            }
           
        }
    
    }
    resetUploadedMediaSet = () => {
        const uploadedMediaSet = {
            embedLinks: [],
            uploadedFiles: [],
            mediaSet: [],
            mediaSetFollowUp:[]
        }
        this.setState({ uploadedMediaSet });
    }
    handleOnClickUploadModalOk = () => {
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }

    removeUploadedMediaSet = (media)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSet:{
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:this.state.uploadedMediaSet?.uploadedFiles?.filter(item=>item.id!==media?.id)
                }
            }
        })

        this.setState({deletedMedias:[...this.state.deletedMedias,...[media]]})
    }

    removeUploadedMediaSetFollow = (media)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSetFollowUp:{
                    ...prevState.uploadedMediaSetFollowUp,
                    uploadedFiles:this.state.uploadedMediaSetFollowUp?.uploadedFiles?.filter(item=>item.id!==media?.id)
                }
            }
        })        
    }

    render() {
        const { collage_items, categories, additional_attributes, new_sub_categories, new_sub_child_categories } = this.props;
        let { setChildrens, selectedItems, productsList, deletedItems, allSubCategories, friendsSubCategories, highlySubCategories, subCatItem, toggleIcons, toggleItemCreateReview, firstReviewReadMore,uploadedMediaSet,
            uploadedMediaSetFollowUp,
            followUpStatus } = this.state
        return (
            <div>
                {this.state.enableLoading && <Loader/>}
                {this.state.isEnableFileUpload ?
                    <UploadMedia
                        getUploadedMedia={this.getUploadedMedia}
                        uploadedUrl={this.state.uploadMediaUrl}
                        hideEmbed={this.state.hideEmbed}
                        callbackFunction={this.handleOnClickUploadModalOk}
                        noCategory={this.state.discussionTab}
                        typeUpload={this.state.typeupload}
                        toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                            this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, this.state.reviewOneToggler? true: false);
                           
                        }}
                    />
                    : null}
                <AppHeader isEnableCover={false} />
                <CollageItems
                    fetchItems={this.fetchItems}
                    enableSubCategory={this.category}
                    items={collage_items}
                    selectedItems={selectedItems}
                    subCatItem={subCatItem}
                />

                {/* Main Content Starts */}
                <section className="py-4 fs-14">
                    <Container>
                        <Row>
                            <Col md={3} className="flex-180">
                                <div className="mb-5">
                                    <h2 className="fs-36 text-tertiary"> Categories</h2>
                                    <hr className="bg-tertiary mb-2" />

                                    {this.category ? (
                                        <div>
                                            <ul className="list-unstyled">
                                                {new_sub_categories && new_sub_categories[0] &&
                                                    new_sub_categories[0]?.children &&
                                                    Array.isArray(new_sub_categories[0]?.children) &&
                                                    new_sub_categories[0]?.children?.length ?

                                                    new_sub_categories[0]?.children?.sort((a, b) => {
                                                        var x = a['label'];
                                                        var y = b['label'];
                                                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                                                    }).map((item, item_index) => (
                                                        <li className="py-2 border-bottom" key={item_index}>
                                                            <div className="d-flex align-items-start">
                                                                <Button
                                                                    onClick={() => {
                                                                    
                                                                        this.hitAndFetchSubCat({ id: item?.id })
                                                                    }}
                                                                    color="link"
                                                                    className="p-0 text-darker font-weight-bold ff-base text-left">
                                                                    {item.label}
                                                                </Button>
                                                                {item.children_count > 0 && (
                                                                    <Button
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            if (toggleIcons[`${item?.id}-subchild-${item_index}`] == undefined) {
                                                                                toggleIcons[`${item?.id}-subchild-${item_index}`] = true;
                                                                            } else {
                                                                                toggleIcons[`${item?.id}-subchild-${item_index}`] = !toggleIcons[`${item?.id}-subchild-${item_index}`];
                                                                            }
                                                                            this.setState({ toggleIcons })

                                                                            if (new_sub_child_categories && new_sub_child_categories[0] && new_sub_child_categories[0].parent == item.id) {
                                                                                this.props.clear_sub_child_categories();
                                                                            } else {
                                                                                this.props.fetch_sub_child_categories({ category: item?.id });
                                                                            }
                                                                        }}
                                                                        color="transparent"
                                                                        className="ml-auto text-dark px-2 py-0"
                                                                        id={`item-${item?.id}`}>
                                                                        {
                                                                            toggleIcons[`${item?.id}-subchild-${item_index}`] ?
                                                                                <i className="icon-minus-cat"></i>
                                                                                :
                                                                                <i className="icon-plus-cat"></i>
                                                                        }
                                                                    </Button>
                                                                )}
                                                            </div>
                                                            {new_sub_child_categories && new_sub_child_categories[0] && new_sub_child_categories[0].parent == item.id ? (
                                                                <Collapse isOpen={new_sub_child_categories[0].parent == item.id}>
                                                                    <ul className="list-unstyled pl-2 fs-14">
                                                                        {new_sub_child_categories.sort((a, b) => {
                                                                            var x = a['label'];
                                                                            var y = b['label'];
                                                                            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                                                                        }).map(sub_child_item => (
                                                                            <li>
                                                                                <Button
                                                                                    onClick={() => { this.hitAndFetchSubCat({ id: sub_child_item?.id }) }}
                                                                                    className="text-dark ff-base p-0 text-left font-weight-normal"
                                                                                    color="link" >
                                                                                    {sub_child_item?.label}
                                                                                </Button>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </Collapse>
                                                            ) : null}
                                                        </li>
                                                    ))
                                                    : null}
                                            </ul>
                                        </div>
                                    ) : (
                                            <div>
                                                <ul className="list-unstyled">
                                                    {categories && categories[0] && categories[0]?.children && Array.isArray(categories[0]?.children) && categories[0]?.children?.length && !this.category ?
                                                        categories[0].children.sort(this.sortItems).map((item, item_index) => (
                                                            <li className="py-2 border-bottom">
                                                                <div className="d-flex align-items-start">
                                                                    {/* <Link
                                                                        to={{
                                                                            pathname: `/business-category/${item.seo_label?.split('business/')[1].toLowerCase()}`,
                                                                            state: { id: item?.id }
                                                                        }}
                                                                        // target="_blank"
                                                                        > */}
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/business-category/${item?.seo_label.split(/business-reviews\//)[1].replace(/ /g, "-").toLowerCase()}`,
                                                                            state: {
                                                                                id: item?.id,
                                                                                parent_category: "business",
                                                                                child_category: item?.category
                                                                            }
                                                                        }}
                                                                        // target="_blank"
                                                                        >
                                                                        <Button
                                                                            onClick={() => { this.itemsSet(item, true) }}
                                                                            color="link"
                                                                            className="p-0 text-darker ff-base text-left">
                                                                            {item.category}
                                                                        </Button>
                                                                    </Link>
                                                                    {item.children_count > 0 && (
                                                                        <Button
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                if (toggleIcons[`${item?.id}-child-${item_index}`] == undefined) {
                                                                                    toggleIcons[`${item?.id}-child-${item_index}`] = true;
                                                                                } else {
                                                                                    toggleIcons[`${item?.id}-child-${item_index}`] = !toggleIcons[`${item?.id}-child-${item_index}`];
                                                                                }
                                                                                this.setState({ toggleIcons })
                                                                                this.getCategories(item)
                                                                            }}
                                                                            color="transparent"
                                                                            className="ml-auto text-dark px-2 py-0"
                                                                            id={`item-${item?.id}`}>
                                                                            {
                                                                                toggleIcons[`${item?.id}-child-${item_index}`] ?
                                                                                    <i className="icon-minus-cat"></i>
                                                                                    :
                                                                                    <i className="icon-plus-cat"></i>
                                                                            }
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                                {setChildrens && setChildrens[item?.id] && !deletedItems[item?.id] ? (                                                   
                                                                    <Collapse isOpen={setChildrens[item?.id]}>
                                                                        <ul className="list-unstyled pl-2 fs-14">
                                                                            {setChildrens[item?.id] && setChildrens[item?.id].length ?
                                                                                setChildrens[item?.id].sort((a, b) => {
                                                                                    var x = a['label'];
                                                                                    var y = b['label'];
                                                                                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                                                                                }).map((child_item, child_index_value) => (
                                                                                    <li>
                                                                                        <div className="d-flex align-items-start">
                                                                                            {/* <Button
                                                                                                onClick={() => { 
                                                                                                    this.props.history.push({
                                                                                                        pathname: `${'/business-category/' + child_item.seo_label.split('business/')[1]}`,
                                                                                                        state: { id: child_item.id }
                                                                                                    })
                                                                                                }}
                                                                                                className="text-dark ff-base p-0 text-left font-weight-normal"
                                                                                                color="link">
                                                                                                {selectedItems?.id == child_item?.id ? (<b>{child_item?.label}</b>) : (child_item?.label)}
                                                                                            </Button> */}
                                                                                            <Link
                                                                                                to={{
                                                                                                    pathname: `${'/business-category/' + child_item.seo_label.split(/business-reviews\//)[1].replace(/ /g, '-').toLowerCase()}`,
                                                                                                    state: {
                                                                                                        id: child_item.id,
                                                                                                        parent_category: "business",
                                                                                                        child_category: child_item?.category
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <Button
                                                                                                    className="text-dark ff-base p-0 text-left font-weight-normal"
                                                                                                    color="link">
                                                                                                    {selectedItems?.id == child_item?.id ? (<b>{child_item?.label}</b>) : (child_item?.label)}
                                                                                                </Button>
                                                                                            </Link>
                                                                                           
                                                                                            {child_item?.children_count > 0 && (
                                                                                                <Button
                                                                                                    onClick={() => {
                                                                                                        if (toggleIcons[`${child_item?.id}-parent-${child_index_value}`] == undefined) {
                                                                                                            toggleIcons[`${child_item?.id}-parent-${child_index_value}`] = true;
                                                                                                        } else {
                                                                                                            toggleIcons[`${child_item?.id}-parent-${child_index_value}`] = !toggleIcons[`${child_item?.id}-parent-${child_index_value}`];
                                                                                                        }
                                                                                                        this.setState({ toggleIcons })
                                                                                                        this.getCategories(child_item);
                                                                                                    }}
                                                                                                    size="sm"
                                                                                                    className="ml-auto text-dark px-2 py-0"
                                                                                                    color="transparent" >
                                                                                                    {
                                                                                                        toggleIcons[`${child_item?.id}-parent-${child_index_value}`] ?
                                                                                                            <i className="icon-minus-cat _sm"></i>
                                                                                                            :
                                                                                                            <i className="icon-plus-cat _sm"></i>
                                                                                                    }
                                                                                                </Button>
                                                                                            )}
                                                                                        </div>
                                                                                        {setChildrens && setChildrens[child_item?.id] && !deletedItems[child_item?.id] ? (
                                                                                            <Collapse isOpen={setChildrens[child_item?.id]}>
                                                                                                <ul className="list-unstyled pl-2 fs-14">
                                                                                                    {setChildrens[child_item?.id] && setChildrens[child_item?.id].length ?
                                                                                                        setChildrens[child_item?.id].sort((a, b) => {
                                                                                                            var x = a['label'];
                                                                                                            var y = b['label'];
                                                                                                            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                                                                                                        }).map(sub_child_item => (
                                                                                                            <li>
                                                                                                                {/* <Button
                                                                                                                    onClick={() => { 
                                                                                                                        this.props.history.push({
                                                                                                                            pathname: `${'/business-category/' + sub_child_item.seo_label.split('business/')[1]}`,
                                                                                                                            state: { id: sub_child_item.id }
                                                                                                                        })
                                                                                                                    }}
                                                                                                                    className="text-dark ff-base p-0 text-left font-weight-normal"
                                                                                                                    color="link" >
                                                                                                                    {sub_child_item?.label}
                                                                                                                </Button> */}
                                                                                                                <Link
                                                                                                                    to={{
                                                                                                                        pathname: `${'/business-category/' + sub_child_item?.seo_label.split(/business-reviews\//)[1].replace(/ /g, "-").toLowerCase()}`,
                                                                                                                        state: {
                                                                                                                            id: sub_child_item.id,
                                                                                                                            parent_category: "business",
                                                                                                                            child_category: sub_child_item?.category
                                                                                                                        }
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Button
                                                                                                                        className="text-dark ff-base p-0 text-left font-weight-normal"
                                                                                                                        color="link" >
                                                                                                                        {sub_child_item?.label}
                                                                                                                    </Button>
                                                                                                                </Link>
                                                                                                                
                                                                                                            </li>))
                                                                                                        : null}
                                                                                                </ul>
                                                                                            </Collapse>
                                                                                        ) : null}
                                                                                    </li>
                                                                                ))
                                                                                : null}
                                                                        </ul>
                                                                    </Collapse>
                                                                ) : null}
                                                            </li>
                                                        ))
                                                        : null}
                                                </ul>
                                            </div>
                                        )}
                                </div>

                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2 text-dark">Star Rating</div>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input onClick={() => { this.startsRating(5) }} type="radio" className='star-filter-input-checkbox' name="filterByStarRating" />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_6.png" alt="5 Rating" />
                                                <span>only</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input onClick={() => { this.startsRating(4) }} type="radio" className='star-filter-input-checkbox' name="filterByStarRating" />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_5.png" alt="4 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input onClick={() => { this.startsRating(3) }} type="radio" className='star-filter-input-checkbox' name="filterByStarRating" />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_4.png" alt="3 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input onClick={() => { this.startsRating(2) }} type="radio" className='star-filter-input-checkbox' name="filterByStarRating" />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_3.png" alt="2 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input onClick={() => { this.startsRating(1) }} type="radio"className='star-filter-input-checkbox' name="filterByStarRating" />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_2.png" alt="1 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input onClick={() => { this.startsRating(0) }} type="radio" className='star-filter-input-checkbox' name="filterByStarRating" />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_1.png" alt="0 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <hr className="bg-tertiary" />
                                </div>

                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2 text-dark">Features</div>
                                    {additional_attributes?.length > 0 && additional_attributes.map(attr => {
                                        return (
                                            <FormGroup className="mb-1" check key={attr.id}>
                                                <Label className="text-dark ff-alt font-weight-normal" check>
                                                    <Input type="checkbox" name="filterByFeatures"
                                                        checked={this.state.features[attr.label] ? true : false}
                                                        onClick={() => this.filterByFeatures(attr.label)} />{' '}
                                                    {attr.label}
                                                </Label>
                                            </FormGroup>
                                        )
                                    })}
                                    <hr className="bg-tertiary" />
                                </div>

                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2 text-dark">Price</div>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" checked={this.state.prices['$'] ? true : false} onClick={() => this.filterByPrices('$')} />{' '}
                                            $
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" checked={this.state.prices['$$'] ? true : false} onClick={() => this.filterByPrices('$$')} />{' '}
                                            $$
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" checked={this.state.prices['$$$'] ? true : false} onClick={() => this.filterByPrices('$$$')} />{' '}
                                            $$$
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" checked={this.state.prices['$$$$'] ? true : false} onClick={() => this.filterByPrices('$$$$')} />{' '}
                                            $$$$
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" checked={this.state.prices['$$$$$'] ? true : false} onClick={() => this.filterByPrices('$$$$$')} />{' '}
                                            $$$$$
                                        </Label>
                                    </FormGroup>
                                    <hr className="bg-tertiary" />
                                </div>

                                <div>
                                    <div className="bg-white p-2">
                                        <a rel="noopener noreferrer" href="http://wikireviews.com/blog" target="_blank" className="text-reset">
                                            <img className="w-100 blog-img" src={"https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_05.png"} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </Col>  
                            <Col md={6}>
                                <div id="top-bar-start">
                                    <div className="mb-3">
                                        {productsList && productsList?.results && Array.isArray(productsList?.results) && productsList?.results?.length && !this.category ?
                                            <>
                                            {
                                                productsList?.results?.map((item, index) => {
                                                    let detailedRatingKeys = [];
    
                                                    if (item?.detailed_rating) {
                                                        detailedRatingKeys = Object.keys(item?.detailed_rating).filter(k => !k.includes('review') && !k.includes('rating'));
                                                    }
    
                                                    return (
                                                        <div id={`${(productsList?.results?.length - 1 == index) ? 'last-key' : `index-${index}`}`} className={`mb-1`} key={item?.id}>
                                                            <div className="bg-white text-dark p-3">
                                                                <div className="d-flex mx-n2 mb-2">
                                                                    <div className="px-2">
                                                                        <a href="#" className="text-decoration-none">
                                                                            <img width="110" className='listing-profile-image' src={`${item && item.listing_profileimage && item.listing_profileimage.length > 0 && Array.isArray(item.listing_profileimage) && item.listing_profileimage[0] ? item.listing_profileimage[0].url : 'https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'}`}
                                                                                onError={(error) =>
                                                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                                }
                                                                                alt={item?.name} />
                                                                        </a>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <div className="d-flex mx-n2">
                                                                            <div className="px-2 col">
                                                                                <div className="ff-base">
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: `/${(item?.content_type).toLowerCase()}/${item?.slug || item?.id}`,
                                                                                            state: { id: item?.id }
                                                                                        }}
                                                                                        className="fs-16 text-reset ff-base font-weight-bold text-decoration-none">
                                                                                        {`${index + 1}. ${item.name}`}
                                                                                    </Link>
                                                                                    <div className="mt-2">
                                                                                        <span className="d-inline-block hoverable-rating">
                                                                                            <img
                                                                                                title={item.total_rating?.avg_rating[1]}
                                                                                                src={item.total_rating?.avg_rating[1] !== 'No Rating' ?
                                                                                                    require(`../../assets/images/icons/${Math.floor(item.total_rating?.avg_rating[1])}rating.png`) :
                                                                                                    require('../../assets/images/icons/norating.png')}
                                                                                                alt="" />
                                                                                            <div className="detail-rating-onhover">
                                                                                                <div className="text-dark font-weight-bold mb-2">
                                                                                                    {'Detailed Star Rating'}
                                                                                                </div>
                                                                                                <div>
                                                                                                    {detailedRatingKeys.length > 0 && detailedRatingKeys.map(keyRating => {
                                                                                                        return (
                                                                                                            <div className="d-flex flex-nowrap align-items-center">
                                                                                                                <img
                                                                                                                    title={item.detailed_rating[keyRating][1]}
                                                                                                                    className="mr-1"
                                                                                                                    src={item.detailed_rating[keyRating][1] !== 'No Rating' ?
                                                                                                                        require(`../../assets/images/icons/${Math.floor(item.detailed_rating[keyRating][1])}rating.png`) :
                                                                                                                        require('../../assets/images/icons/norating.png')}
                                                                                                                    alt="" />
                                                                                                                <span className="ff-alt">{keyRating}</span>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                        </span>&nbsp;<span>{item.total_rating?.total_review} {getReviewSuffix(item.total_rating?.total_review)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="px-2 col-auto">
                                                                                <div className="d-inline-flex flex-nowrap mx-n1">
                                                                                    <div className="px-1">
                                                                                        <UncontrolledDropdown>
                                                                                            <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                                                                <div className="share-it-icon"></div>
                                                                                            </DropdownToggle>
                                                                                            <DropdownMenu className="type-tertiary-alt" right>
                                                                                                <DropdownItem className="fs-14">
                                                                                                    <FacebookShareButton
                                                                                                        url={`${this.state.protocol}//${this.state.host}/business/${item.id}/${item.slug ? item.slug : ''}`}
                                                                                                        quote={`Check out ${item.name} in ${item.address[0]?.city}, ${item.state} on`}
                                                                                                        hashtag={this.state.fbHashTag}>
                                                                                                        {'Facebook'}
                                                                                                    </FacebookShareButton>
                                                                                                </DropdownItem>
                                                                                                <DropdownItem className="fs-14">
                                                                                                    <TwitterShareButton
                                                                                                        url={`${this.state.protocol}//${this.state.host}/business/${item.id}/${item.slug ? item.slug : ''}`}
                                                                                                        title={`Check out ${item.name} in ${item.address[0]?.city}, ${item.address[0]?.state} on`}
                                                                                                        via={this.state.shareVia}
                                                                                                        hashtags={this.state.twitterHashTags}>
                                                                                                        {'Twitter'}
                                                                                                    </TwitterShareButton>
                                                                                                </DropdownItem>
                                                                                                <DropdownItem
                                                                                                    className="fs-14"
                                                                                                    onClick={() => this.handleOnClickShareByEmail(item.id)}>
                                                                                                    {'By Email'}
                                                                                                </DropdownItem>
                                                                                            </DropdownMenu>
                                                                                        </UncontrolledDropdown>
                                                                                    </div>
                                                                                    <div className="px-1">
                                                                                        <UncontrolledDropdown>
                                                                                            <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                                                                <div className="thumbpin-icon"></div>
                                                                                            </DropdownToggle>
                                                                                            <DropdownMenu className="type-tertiary-alt" right>
                                                                                                <DropdownItem
                                                                                                    className="fs-14"
                                                                                                    onClick={() => this.handleOnClickAddToList('want list', item.pins?.user_want_status, item)}>
                                                                                                    <div className="addto-icon want-list"></div>
                                                                                                    {item.pins?.user_want_status ? 'Added' : 'Add'} {'to want list'}
                                                                                                </DropdownItem>
                                                                                                <DropdownItem
                                                                                                    className="fs-14"
                                                                                                    onClick={() => this.handleOnClickAddToList('favourite list', item.pins?.user_favourites_status, item)}>
                                                                                                    <div className="addto-icon favorite-list"></div>
                                                                                                    {item.pins?.user_favourites_status ? 'Added' : 'Add'} {'to favorites'}
                                                                                                </DropdownItem>
                                                                                                <DropdownItem
                                                                                                    className="fs-14"
                                                                                                    onClick={() => this.handleOnClickAddToList('watch list', item.pins?.user_watch_status, item)}>
                                                                                                    <div className="addto-icon watch-list"></div>
                                                                                                    {item.pins?.user_watch_status ? 'Added' : 'Add'} {'to watch list'}
                                                                                                </DropdownItem>
                                                                                            </DropdownMenu>
                                                                                        </UncontrolledDropdown>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
    
                                                                        <div className="ff-alt">
                                                                            <div className="mt-2">
                                                                                <span className="font-weight-bold">
                                                                                    {item?.address &&
                                                                                        item?.address[0] &&
                                                                                        item?.address[0].address1 &&
                                                                                        item?.address[0].address1 !== null ? this.renderAddress({ item: item?.address[0] }) : ''}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="ff-base">
                                                                            <div className="mt-2">
                                                                                {item.taxonomy_list.map((item_tax, tax_index) => (
                                                                                    <span>{item_tax?.category}{(item?.taxonomy_list?.length > 1 && item?.taxonomy_list?.length - 1 !== tax_index) ? ` , ` : ''}</span>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className="ff-alt">
                                                                            <div className="mt-2">
                                                                                <span>{this.formatPhoneNumber(item?.phone_set[0]?.phone)}</span>
                                                                            </div>
                                                                        </div>
    
                                                                        <div className="text-right mt-3">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: `/${(item?.content_type).toLowerCase()}/${item?.slug || item?.id}`,
                                                                                    state: { id: item?.id }
                                                                                }}
                                                                                className="text-dark font-weight-bold">
                                                                                {'view more'}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            {/* Show All Reviews */}
                                                            <div className="mb-1 mt-1">
                                                                {/* Repeat this */}
                                                                <div className="bg-white text-dark p-3 mb-1">
                                                                    <div className="d-flex mx-n2 mb-2">
                                                                        <div className="px-2">
                                                                            <a href="#" className="text-decoration-none">
                                                                                <img
                                                                                    className="img-circle _30x30"
                                                                                    src={item?.review?.current_profile_pic_full_path}
                                                                                    alt={item?.review?.created_by?.user?.first_name}
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        <div className="px-2 flex-grow-1">
                                                                            <div className="ff-base text-dark">
                                                                               
                                                                                <Link
                                                                                    to={`/people/${item?.review?.created_by?.user?.username}/`}
                                                                                    className="text-reset ff-base font-weight-bold"
                                                                                >
                                                                                    {`${item?.review?.created_by?.user?.first_name || ''} ${item?.review?.created_by?.user?.last_name || ''}`}
                                                                                </Link>
                                                                                {item?.review?.created_by?.user?.first_name && (
                                                                                    <span className="ml-2 mr-2">gave it</span>
                                                                                )}
                                                                                {item?.review && item?.review?.ratings_sets && item?.review?.ratings_sets[0] ? (
                                                                                    <img
                                                                                        title={item?.review && item?.review?.ratings_sets && item?.review?.ratings_sets[1] ? item?.review?.ratings_sets[1] : ''}
                                                                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item?.review?.ratings_sets[0] || 'norating.png'}`}
                                                                                        alt={item?.review?.ratings_sets[1]}
                                                                                    />
                                                                                ) : null}
                                                                                <div className="fs-13">
                                                                                    {`${item?.review?.review_date_time || ''} ${item?.review?.device_type ? `via ${item?.review?.device_type || ''}` : ''}`}
                                                                                </div>
                                                                            </div>
    
                                                                            {/* User Reply */}
                                                                            <div className="ff-alt mt-3 text-break">
                                                                                {ReactHtmlParser(detectlink(item?.review?.review?.length > 120
                                                                                    ?
                                                                                    firstReviewReadMore[item?.id]
                                                                                        ?
                                                                                        item?.review?.review
                                                                                        :
                                                                                        (item?.review?.review?.slice(0, 120) + "...")
                                                                                    : item?.review?.review || ''
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
    
                                                                    <div className="text-right">
                                                                        {/* <Button color="link" className="text-primary font-weight-bold p-0">Write a follow up</Button> */}
                                                                        {/* <Button
                                                                            onClick={() => {
                                                                                if (toggleItemCreateReview[item?.id] == undefined) {
                                                                                    toggleItemCreateReview[item?.id] = true;
                                                                                } else {
                                                                                    toggleItemCreateReview[item?.id] = !toggleItemCreateReview[item?.id];
                                                                                }
                                                                                this.setState({ toggleItemCreateReview });
                                                                            }}
                                                                            color="link" className="text-primary font-weight-bold p-0">
                                                                                {this.state.this_user === item?.review?.created_by?.id ? "Write your follow-up" : "Create your own review"}
                                                                        </Button> */}
                                                                        <Link
                                                                            to={{
                                                                                pathname: `/${(item?.content_type).toLowerCase()}/${item?.slug || item?.id}`,
                                                                                state: { id: item?.id }
                                                                            }}
                                                                            color="link"
                                                                            className="text-primary font-weight-bold p-0">
                                                                            {this.state.this_user === item?.review?.created_by?.id ? "Write your follow-up" : "Create your own review"}
                                                                        </Link>
                                                                        <Button hidden = {item?.review?.review?.length < 120} color="link" className="text-dark font-weight-bold p-0 pl-3" onClick={() => {
                                                                            if (firstReviewReadMore[item?.id] == undefined) {
                                                                                firstReviewReadMore[item?.id] = true
                                                                            } else {
                                                                                firstReviewReadMore[item?.id] = !firstReviewReadMore[item?.id]
                                                                            }
                                                                            this.setState({ firstReviewReadMore })
                                                                        }}>
                                                                            {/* <Link
                                                                                to={{
                                                                                    pathname: `/business/${item?.slug || item?.id}`,
                                                                                    state: { id: item?.id }
                                                                                }}>
                                                                                {'read more'}
                                                                            </Link> */}
                                                                            {item?.review?.review?.length > 120
                                                                                ?
                                                                                firstReviewReadMore[item?.id] ? "show less" : "read more"
                                                                                :
                                                                                ''
                                                                            }
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            {toggleItemCreateReview[item?.id] && 
                                                            (this.state.this_user === item?.review?.created_by?.id ?
                                                                <CreateReview
                                                                    itemDetail={item}
                                                                    closeAfterCancel={this.closeAfterCancel}
                                                                    viewEditReviewStep={'writeReview'}
                                                                    mainViewType={'reviews'}
                                                                    listing_type={'business'}
                                                                    reviews={[]}
                                                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                                                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true, false);                                       
                                                                    }}
                                                                    uploadedMediaSet={uploadedMediaSet}
                                                                    uploadedMediaSetFollow={uploadedMediaSetFollowUp}
                                                                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                                                                    removeUploadedMediaSetFollow={this.removeUploadedMediaSetFollow}
                                                                    onPublishReview={this.onPublishReview}
                                                                    categoryId={item?.id}
                                                                    reviewType="categoryPage"
                                                                    item={item?.review}
                                                                    isWriteFollowUp={true}
                                                                    type="followUp"
                                                                    resetUploadedMediaSet={this.resetUploadedMediaSet}
                                                                />
                                                                    :
                                                                    <CreateReview
                                                                    itemDetail={item}
                                                                    closeAfterCancel={this.closeAfterCancel}
                                                                    viewEditReviewStep={'writeReview'}
                                                                    mainViewType={'reviews'}
                                                                    listing_type={'business'}
                                                                    reviews={[]}
                                                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                                                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true, false);                                       
                                                                    }}
                                                                    uploadedMediaSet={uploadedMediaSet}
                                                                    uploadedMediaSetFollow={uploadedMediaSetFollowUp}
                                                                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                                                                    removeUploadedMediaSetFollow={this.removeUploadedMediaSetFollow}
                                                                    onPublishReview={this.onPublishReview}
                                                                    categoryId={item?.id}
                                                                    type="followUp"
                                                                    reviewType="categoryPage"
                                                                    resetUploadedMediaSet={this.resetUploadedMediaSet}
                                                                    />)
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                    (productsList?.results &&
                                                        Array.isArray(productsList?.results) &&
                                                        productsList?.results.length && this.state.listLoading) ?
                                                        <div className="mt-5 text-center">
                                                            <h2>{"Loading more business..."}</h2>
                                                        </div> : ''
                                                }
                                                <div style={{ height: "400px" }} />
                                            </>
                                            : this.state.listLoading ? 
                                            <div className="mt-5">
                                                            <h2>{"Loading..."}</h2>
                                                        </div> 
                                            : !this.category ? (
                                                <div id="top-bar-start" className="bg-white p-3">
                                                    <h2 className="text-secondary-dark">No results found!</h2>
                                                </div>
                                            ) : ''}

                                        {/* {this.state.listLoading && !this.category && (
                                            <Loader />
                                            // <div className="alert alert-primary fade show" role="alert">Loading...1572</div>
                                        )} */}

                                        {this.category && (
                                            <div className="mt-4">
                                                <Nav className="nav-fill active-transparent non-active-normal active-bold hover-none" tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className="fs-16 ff-base"
                                                            active={this.state.activeCategoryFilter === "all"}
                                                            onClick={() => {
                                                                this.handleOnClickChangeTab('all');
                                                            }}>
                                                            {'All'}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className="fs-16 ff-base"
                                                            active={this.state.activeCategoryFilter === "friendsPick"}
                                                            onClick={() => {
                                                                this.handleOnClickChangeTab('friendsPick');
                                                            }}>
                                                            {'Friends Pick'}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className="fs-16 ff-base"
                                                            active={this.state.activeCategoryFilter === "highlyRecommended"}
                                                            onClick={() => {
                                                                this.handleOnClickChangeTab('highlyRecommended')
                                                            }}>
                                                            {'Highly Recommended'}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>

                                                {this.state.activeCategoryFilter === "all" && this.category ? (
                                                    <div>
                                                        {allSubCategories && allSubCategories?.results && allSubCategories?.results?.length ? this.renderItemsBySub({ productsList: allSubCategories }) : !this.state.enableLoading ? (
                                                            <div id="top-bar-start" className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">No results found!</h2>
                                                            </div>) : (
                                                            <Loader />
                                                            // <div className="alert alert-primary fade show" role="alert">Loading...1615</div>
                                                            )}
                                                    </div>
                                                ) : null}

                                                {this.state.activeCategoryFilter === "friendsPick" && this.category ? (
                                                    <div>
                                                        {friendsSubCategories && friendsSubCategories?.length ? this.renderItemsBySub({ productsList: friendsSubCategories }) : !this.state.enableLoading ? (
                                                            <div id="top-bar-start" className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">No results found!</h2>
                                                            </div>) : (
                                                            <Loader />
                                                            // <div className="alert alert-primary fade show" role="alert">Loading...1624</div>
                                                            )}
                                                    </div>
                                                ) : null}

                                                {this.state.activeCategoryFilter === "highlyRecommended" && this.category ? (
                                                    <div>
                                                        {highlySubCategories && highlySubCategories?.results && highlySubCategories?.results?.length ? this.renderItemsBySub({ productsList: highlySubCategories }) : !this.state.enableLoading ? (
                                                            <div id="top-bar-start" className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">No results found!</h2>
                                                            </div>) : (
                                                            <Loader />
                                                            // <div className="alert alert-primary fade show" role="alert">Loading...1633</div>
                                                            )}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                {/* <MyConnectionsActivity category="business" /> */}
                                <UserFeedsRightPanel category="business" />
                            </Col>                           
                        </Row>
                    </Container>
                </section>

                {/* Share By Email Modal Popup */}

                <ModalComponent
                    size={"sm"}
                    isScrollable={true}
                    isOpen={this.state.shareByEmailModalToggle}
                    toggleHandler={()=> this.setState({ shareByEmailModalToggle: !this.state.shareByEmailModalToggle })}
                    header={" Share by Email"}
                    bodyClass={""}
                    body={<ShareByEmail handleOnSubmitShareByEmail={(value) => this.handleOnSubmitShareByEmail(value)} />}
        />
            </div>
        )
    }
}

const mapState = (state) => ({
    this_user: state.user.my_profile,
    collage_items: state.business.collage_items,
    categories: state.business.categories,
    sub_categories: state.business.sub_categories,
    products_list: state.business.products_list,
    additional_attributes: state.business.additional_attributes,
    listing_pins: state.business.listing_pins,
    new_sub_categories: state.business.new_sub_categories,
    new_sub_child_categories: state.business.new_sub_child_categories,
    all_sub_categories: state.business.all_sub_categories,
    friends_sub_categories: state.business.friends_sub_categories,
    highly_sub_categories: state.business.highly_sub_categories,
    my_profile_info: state.user.my_profile,
	current_location: state.home.current_location,
    editExistingMedia : state.shared.editExistingMedia
});

const mapProps = (dispatch) => ({
    fetch_collage_items: (data) => dispatch(fetch_collage_items(data)),
    reset_product_list: () => dispatch(reset_product_list()),
    fetch_all_categories: (data) => dispatch(fetch_all_categories(data)),
    reset_sub_categories: () => dispatch(reset_sub_categories()),
    clear_sub_categories: () => dispatch(clear_sub_categories()),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    get_additional_attributes: (type) => dispatch(get_additional_attributes(type)),
    filter_with_additional_attributes: (url, data) => dispatch(filter_with_additional_attributes(url, data)),
    fetch_items_pin: (id) => dispatch(fetch_items_pin(id)),
    fetch_sub_categories: ({ sub_cat, level }) => dispatch(fetch_sub_categories({ sub_cat, level })),
    fetch_sub_child_categories: ({ category }) => dispatch(fetch_sub_child_categories({ category })),
    fetch_all_sub_categories_items: ({ cat, page }) => dispatch(fetch_all_sub_categories_items({ cat, page })),
    fetch_friends_sub_categories_items: ({ cat, page }) => dispatch(fetch_friends_sub_categories_items({ cat, page })),
    fetch_highly_sub_categories_items: ({ cat, page }) => dispatch(fetch_highly_sub_categories_items({ cat, page })),
    clear_sub_child_categories: () => dispatch(clear_sub_child_categories()),
    get_uploaded_media: (data) => dispatch(get_uploaded_media(data))
});

export default connect(mapState, mapProps)(Business);
