/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import CollapseBasic from "../../atoms/collapse";
import { connect } from "react-redux";
import { get_recent_activity, get_my_contri } from "./../../../actions/homeTabs";
import { Link } from 'react-router-dom'

class RecentActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callFirstTime: false,
            recentList: null,
            contriButionList: null
        };
    }


    componentDidMount() {
        this.get_recent();
        this.props.get_my_contri();
    }

    componentDidUpdate() {
        if (this.state.callFirstTime == false && this.props.user_id) {
            this.setState({ callFirstTime: true }, () => { this.get_recent() })
        }
    }

    get_recent = () => {
        if (this.props.user_id?.id) {
            let id = this.props.user_id.id;

            if (id) {
                this.props.get_recent_activity(id);
            }
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.recent_activity?.results) {
            this.setState({ recentList: nextProps.recent_activity.results })

        }
        if (nextProps.get_my_contribution?.results) {
            this.setState({ contriButionList: nextProps.get_my_contribution.results })
        }
    }

    redirectToSlugUrl = (item) => {
        if (item?.redirect_link) {
            let id = (item?.listing_id).toString();
            let splitItems = item.redirect_link.split('/');
            let filteredItems = splitItems.map(item => {
                if (item !== '' && item !== id) {
                    return item;
                }
            }).filter(item => item !== undefined).join('/');
            return ({
                pathname: filteredItems,
                state: { id }
            })
        }
    }

    getVerb(item) {
        let verb = item?.verb
        if (verb === "Followed me") {
            let lists = Object.keys(item?.target?.userpins)
            let addedLists = lists.filter((listName) => item?.target?.userpins?.[`${listName}`])
            addedLists = addedLists.map((type) => {
                if (type === "want_this") {
                    return "Want"
                } else if (type === "watch_this") {
                    return "Watch"
                } else if (type === "favourite") {
                    return "Favorite"
                } else if (type === "recommend") {
                    return "Recommend"
                }
                return type
            })
            return `Added to My ${addedLists.join(", ")} ${item?.target?.category ? item.target.category : ''} List `
        }
        return item.verb        
    }

    render() {
        const { recentList, contriButionList } = this.state;
        return (
            <div className="bg-white-shaded-md px-3 py-3 mb-3">
                <h2 className="text-secondary">Recent Activity</h2>
                <hr className="bg-secondary-light" />

                <CollapseBasic
                    title="My Reviews"
                    noHr={true}
                    containerClass="bg-transparent title-type-sm expanded-dark"
                    bodyClass="mt-2 text-secondary-dark fs-13"
                    isOpen={true}
                    size="sm"
                >
                    <ul className="list-unstyled">
                        {recentList && Array.isArray(recentList) && recentList.length > 0
                            ?
                            recentList.map((item, index) => {
                                return (
                                    <li className="mb-3" key={index}>
                                        <div className="d-flex flex-nowrap mx-n1">
                                            <div className="px-1 mt-1 mb-2">
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Link
                                                            to={this.redirectToSlugUrl(item)}
                                                            className="text-decoration-none">
                                                            <img
                                                                src={item?.listing_profileimage[0]?.location
                                                                    ?
                                                                    item.listing_profileimage[0].location
                                                                    :
                                                                    require("../../../assets/images/icons/placeholder-img.png")
                                                                }

                                                                alt=""
                                                                width={50}
                                                                height={50}
                                                                style={{
                                                                    objectFit: "cover",
                                                                    objectPosition: "center center",
                                                                }}
                                                                onError={(error) =>
                                                                    (error.target.src = require("../../../assets/images/icons/placeholder-img.png"))}
                                                            />
                                                        </Link>
                                                    </div>

                                                    <div className="px-2">
                                                        <div className="font-weight-bold ff-base">
                                                            <Link to={this.redirectToSlugUrl(item)} className="text-reset">
                                                                {item.listing_name}
                                                            </Link>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap mx-n1">
                                                            <div className="px-1">
                                                                {item && item.ratings_sets && item.ratings_sets.avg_rating && Array.isArray(item.ratings_sets.avg_rating) && item.ratings_sets.avg_rating[0]
                                                                    ?
                                                                    <img
                                                                        src={require(`../../../assets/images/icons/star/gold/${item?.ratings_sets?.avg_rating[0]}`)}
                                                                        alt={item?.ratings_sets?.avg_rating?.[1]}
                                                                        title={item?.ratings_sets?.avg_rating?.[1]} />
                                                                    :
                                                                    <img src={require(`../../../assets/images/icons/star/gold/0rating.png`)} alt="no rating" />
                                                                }

                                                            </div>
                                                            <div className="px-1 ff-alt">
                                                                {item.review_date_time}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mb-0 mt-1 border-secondary" />
                                    </li>
                                )
                            })
                            :
                            "No Record Found"
                        }

                    </ul>
                </CollapseBasic>
                <div className="my-3"></div>
                <CollapseBasic
                    title="My Contributions"
                    noHr={true}
                    containerClass="bg-transparent title-type expanded-dark"
                    bodyClass="mt-2 text-secondary fs-13"
                    isOpen={true}
                    size="sm"
                >
                    <ul className="list-unstyled">
                        {contriButionList && Array.isArray(contriButionList) && contriButionList.length > 0
                            ?
                            contriButionList.map((item, index) => {
                                if (index < 4) {
                                    if (item.verb !== "Has written an update for their review on" || item.verb !== "Commented on status" && item.target !== null) {
                                        return (
                                            <li className="mb-3" key={index}>
                                                <div className="d-flex flex-nowrap mx-n1">
                                                    <div className="px-1 mt-1 mb-2">
                                                        <div className="d-flex mx-n2">
                                                            <div className="px-2">
                                                                {/* {item.verb != 'Commented on status' && item.verb != 'Added new project' ? ( */}
                                                                {item?.target?.category ? (
                                                                    <Link
                                                                        to={{
                                                                            pathname: `${item?.target?.category?.toLowerCase()}/${item?.target?.slug}`,
                                                                            state: { id: item?.target?.id }
                                                                        }}
                                                                        className="text-decoration-none">
                                                                        {item?.target?.profile_image &&
                                                                            Array.isArray(item?.target?.profile_image) &&
                                                                            item?.target?.profile_image[0] &&
                                                                            item?.target?.profile_image[0].url
                                                                            ?
                                                                            <img
                                                                                src={item?.target?.profile_image[0].url}
                                                                                alt=""
                                                                                width={50}
                                                                                height={50}
                                                                                style={{
                                                                                    objectFit: "cover",
                                                                                    objectPosition: "center center",
                                                                                }}
                                                                            />
                                                                            :
                                                                            <img
                                                                                src={require("../../../assets/images/icons/placeholder-img.png")}
                                                                                alt=""
                                                                                width={50}
                                                                                height={50}
                                                                                style={{
                                                                                    objectFit: "cover",
                                                                                    objectPosition: "center center",
                                                                                }}
                                                                            />
                                                                        }
                                                                    </Link>
                                                                ) : (
                                                                        <Link
                                                                            to={`/people/${item?.actor?.username}`}
                                                                            className="text-decoration-none">
                                                                            {item?.actor?.current_profile_pic?.url
                                                                                ?
                                                                                <img
                                                                                    src={item?.actor?.current_profile_pic?.url}
                                                                                    alt=""
                                                                                    width={50}
                                                                                    height={50}
                                                                                    style={{
                                                                                        objectFit: "cover",
                                                                                        objectPosition: "center center",
                                                                                    }}
                                                                                />
                                                                                :
                                                                                <img
                                                                                    src={require("../../../assets/images/icons/placeholder-img.png")}
                                                                                    alt=""
                                                                                    width={50}
                                                                                    height={50}
                                                                                    style={{
                                                                                        objectFit: "cover",
                                                                                        objectPosition: "center center",
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </Link>
                                                                    )}
                                                            </div>

                                                            <div className="px-2">
                                                                <div className="ff-base">
                                                                    <span>
                                                                        {this.getVerb(item)}
                                                                        <Link
                                                                            to={{
                                                                                pathname: `${item?.target?.category?.toLowerCase()}/${item?.target?.slug}`,
                                                                                state: { id: item?.target?.id }
                                                                            }}
                                                                            className="text-secondary-dark font-weight-bold">
                                                                            {` `}{` `}{item?.target?.name}
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex align-items-center flex-wrap mx-n1">
                                                                    <div className="px-1">
                                                                        {item?.target?.rating_set?.avg_rating?.[0]
                                                                            ?
                                                                            <img src={require(`../../../assets/images/icons/star/gold/${item?.target.rating_set.avg_rating[0]}`)} alt="" />
                                                                            :
                                                                            ""
                                                                        }

                                                                    </div>
                                                                    <div className="px-1 ff-alt text-secondary-dark">
                                                                        {item.time_stamp}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mb-0 mt-1 border-secondary" />
                                            </li>
                                        )
                                    }
                                    else if (item.verb === 'Commented on status' && item.target === null) {
                                        return (

                                            <li className="mb-3">
                                                <div className="d-flex flex-nowrap mx-n1">
                                                    <div className="px-1 mt-1 mb-2">
                                                        <div className="d-flex mx-n2">
                                                            <div className="px-2">
                                                                <Link
                                                                    to={{
                                                                        pathname: `${(item?.target?.category).toLowerCase()}/${item?.target?.slug}`,
                                                                        state: { id: item?.target?.id }
                                                                    }}
                                                                    className="text-decoration-none">
                                                                    {item?.actor?.current_profile_pic?.url
                                                                        ?
                                                                        <img
                                                                            src={
                                                                                item.actor.current_profile_pic.url
                                                                            }
                                                                            alt=""
                                                                            width={50}
                                                                            height={50}
                                                                            style={{
                                                                                objectFit: "cover",
                                                                                objectPosition: "center center",
                                                                            }}
                                                                        />
                                                                        :
                                                                        <img src={require("../../../assets/images/icons/placeholder-img.png")} alt="" />
                                                                    }

                                                                </Link>
                                                            </div>

                                                            <div className="px-2">
                                                                <div className="ff-base">
                                                                    {item.verb}
                                                                    <Link
                                                                        to={{
                                                                            pathname: `${(item?.target?.category).toLowerCase()}/${item?.target?.slug}`,
                                                                            state: { id: item?.target?.id }
                                                                        }}
                                                                        className="text-secondary-dark font-weight-bold">
                                                                        {item?.actor?.first_name} &nbps; {item?.actor?.last_name}
                                                                    </Link>
                                                                    {"'s post"}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center flex-wrap mx-n1">

                                                                <div className="px-1 ff-alt text-secondary-dark">
                                                                    {item.time_stamp}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mb-0 mt-1 border-secondary" />
                                            </li>
                                        )
                                    }
                                    else {
                                        return (

                                            <li className="mb-3" key={index}>
                                                <div className="d-flex flex-nowrap mx-n1">
                                                    <div className="px-1 mt-1 mb-2">
                                                        <div className="d-flex mx-n2">
                                                            <div className="px-2">
                                                                <Link
                                                                    to={{
                                                                        pathname: `${(item?.target?.category).toLowerCase()}/${item?.target?.slug}`,
                                                                        state: { id: item?.target?.id }
                                                                    }}
                                                                    className="text-decoration-none">
                                                                    {item?.actor?.current_profile_pic?.url
                                                                        ?
                                                                        <img
                                                                            src={
                                                                                item.actor.current_profile_pic.url
                                                                            }
                                                                            alt=""
                                                                            width={50}
                                                                            height={50}
                                                                            style={{
                                                                                objectFit: "cover",
                                                                                objectPosition: "center center",
                                                                            }}
                                                                        />
                                                                        :
                                                                        <img src={require("../../../assets/images/icons/placeholder-img.png")} alt="" />
                                                                    }

                                                                </Link>
                                                            </div>

                                                            <div className="px-2">
                                                                <div className="ff-base">
                                                                    <span>
                                                                        {item.actor.first_name} {item.actor.last_name} {item.verb}
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex align-items-center flex-wrap mx-n1">
                                                                    <div className="px-1 ff-alt text-secondary-dark">
                                                                        {item.time_stamp}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mb-0 mt-1 border-secondary" />
                                            </li>
                                        )
                                    }
                                }
                            })
                            :
                            "No Record Found"}
                    </ul>
                </CollapseBasic>
                <div className="text-right">
                    <Link
                        to="myprofile?active_tab=contributions">
                        <a className="text-secondary font-weight-bold">
                            view all
                        </a>
                    </Link>
               
                </div>
            </div>
        )
    }
}
const mapState = (state) => ({
    recent_activity: state.homeTabs.recent_activity_data,
    user_id: state.user.my_profile,
    get_my_contribution: state.homeTabs.get_my_contribution
});

const mapProps = (dispatch) => ({
    get_recent_activity: (id) => dispatch(get_recent_activity(id)),
    get_my_contri: () => dispatch(get_my_contri())
});

export default connect(mapState, mapProps)(RecentActivity);