export const websiteRegex = "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?";



export const emailRegex = "/^[a-zA-Z0-9.#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/"

export const getQueryString = ({ data, url }) => {
    if (data && data.length > 0) {
        let args = data[0];
        if (Object.keys(args).length > 0) {
            Object.keys(args).forEach((key, index) => {
                if (index == 0) {
                    url += `?${key}=${args[key]}`;
                } else {
                    url += `&${key}=${args[key]}`;
                }
            })
        }
    }
    return url;
}


export const randomStrings = (string_length) => {
       return [...Array(string_length)].map(i=>(~~(Math.random()*36)).toString(36)).join('');
};
export const checkIfHaveParams = ({ data, param }) => {
    let hasParam = false;
    if (Array.isArray(data) && data.length > 0) {
        let items = data[0];
        if (items.hasOwnProperty(param)) {
            hasParam = true
        }
    }
    return hasParam;
}
