/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import PublicPageHeader from '../../oragnisms/homepage/publicPageHeader';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
export default class Terms extends Component {
    render() {
        const isLoggedIn = localStorage.getItem("token")

        return (
            <div>
                {isLoggedIn ? <AppHeader isEnableCover={false} /> : <PublicPageHeader />}    
                <div className="wk-static-wrapper">
                  <Container>
                      <div className="wk-static-sub-head">
                         <h1>Terms & Conditions</h1>
                      </div>
                      <div className="wk-static-content-body">
                         <Row>
                            <Col md={4}>
                               <div className="wk-static-sidebar-left">
                                  <h2>Terms Of Use</h2>
                                  <ul>
                                    <li><a href="#accept">Acceptance of Terms</a> </li>
                                    <li><a href="#right">Right to Modify Terms </a></li>
                                    <li><a href="#advice">Accuracy of Advice </a></li>
                                    <li><a href="#acc_creation">Business Account Creation </a></li>
                                    <li><a href="#site_changes">Changes to Site </a></li>
                                    <li><a href="#license">Limited Use License </a></li>
                                    <li><a href="#intro">Information Collected </a></li>
                                    <li><a href="#info">Use of Information </a></li>
                                    <li><a href="#app">Mobile App </a></li>
                                    <li><a href="#prop_rights">Intellectual Property Rights </a></li>
                                    <li><a href="#restrictions">Restrictions </a></li>
                                    <li><a href="#third_party">Third Party Links </a></li>
                                    <li><a href="#copyright">Digital Millennium Copyright Notice </a></li>
                                    <li><a href="#warranties">Disclaimer of Warranties </a></li>
                                    <li><a href="#reliance">Reliance on Information Posted </a></li>
                                    <li><a href="#indemnification">Indemnification </a></li>
                                    <li><a href="#law">Governing Law </a></li>
                                    <li><a href="#dispute">Dispute Resolution and Binding Arbitration </a></li>
                                    <li><a href="#release">Release </a></li>
                                    <li><a href="#agreement">Entire Agreement </a></li>
                                    </ul>
                               </div>                            
                            </Col>
                            <Col md={8}>
                                <h1 className='mb-3 mt-3'>INTRODUCTION</h1>
                               <div className="wk-static-content-right">
                                  <p className="fs-14">WikiReviews, Inc. (“WikiReviews,” “we,” or “us”) provides the content and services available on our website located at wikireviews.com (including its mobile version of the website, all subdomains and social media, the "Website" or “Site”) and through our Mobile Application ("Mobile App" or “App”) including any content functionality offered on or through the Site or the Mobile App (collectively, the “Services”), subject to the terms and conditions of use set forth below, the Privacy Policy, and any other terms and conditions which may exist otherwise on the Site or in related agreements (the “Terms”). The Website and Mobile App are collectively referred to as the “Site” for all guests and registered users.
                                  </p>
                                  <p className="fs-14"><b><u id="accept">Acceptance of Terms</u></b>
                                  </p>
                                  <p className="fs-14">You acknowledge that you have read the terms and conditions of use and that you accept the terms thereof. You agree to read these terms and conditions of use carefully before using the Site. If you do not agree to these terms and conditions of use, you may not access or otherwise use the Site. By using or accessing WikiReviews, you agree that we can collect and use such content and information in accordance with our Privacy Policy as amended from time to time.
                                  </p>
                                  <p className="fs-14"><b><u id="right">Right to Modify Terms</u></b>
                                  </p>
                                  <p className="fs-14">WikiReviews reserves the right, at its sole discretion, to change, modify, add or remove any portion of the Terms, in whole or in part, at any time. Notification of changes in the Terms will be posted on the Site. Your continued use of and access to the Site constitutes your acceptance of any amendments or modifications to the Terms.
                                  </p>
                                  <p className="fs-14"><b><u id="advice">Accuracy of Advice</u></b>
                                  </p>
                                  <p className="fs-14">WikiReviews does not represent or endorse the accuracy or reliability of any advice, opinion, statement, or other information displayed or distributed through the Site. You acknowledge that any reliance upon any such opinion, advice, statement, comment, or information shall be at your sole risk.
                                  </p>
                                  <p className="fs-14"><b><u id="acc_creation">Business Account Creation</u></b>
                                  </p>
                                  <p className="fs-14">In order to claim your business’ listing on WikiReviews and use the associating account features, you must create an account (your “Merchant Account”) on the Site and accept the terms of the Merchant Terms of Use. In creating such an account, you represent that all information provided is accurate and that you are the owner or authorized agent of the business. You are entirely responsible for maintaining the security of, updates submitted by, and all of the Site activities that occur in connection with, your Merchant Account. Impersonation of businesses or business owners through the submission of false account information is prohibited and may subject such users to civil liability for any economic damages incurred by WikiReviews or other third parties.
                                  </p>
                                  <p className="fs-14">We reserve the right to suspend access to or close your account at any time for any or no reason. You agree to notify us immediately of any unauthorized use of your account by sending an email to <a href="mailto:abuse@wikireviews.com">abuse@wikireviews.com.</a>
                                  </p>
                                  <p className="fs-14"><b><u id="site_changes">Changes to Site</u></b>
                                  </p>
                                  <p className="fs-14">WikiReviews may change, suspend or discontinue any aspect of the Site at any time, including the availability of any Site feature, database, or content. WikiReviews may also impose limits on certain features and services or restrict your access to parts or the entire Site without notice or liability.
                                  </p>
                                  <p className="fs-14"><b><u id="license">Limited Use License</u></b>
                                  </p>
                                  <p className="fs-14">WikiReviews grants you a limited, revocable, and non-exclusive license to access and make personal, non-commercial use of the Site. This limited license does not include the right to:
                                  </p>
                                  <ul>
                                        <li>frame or enclose the Site or any portion thereof; </li>
                                        <li>license the Site or any content thereon; and/or  </li>
                                        <li>create any derivative works based upon either the Site or any content thereon.</li>
                                  </ul>
                                  <p className="fs-14">This limited license may be terminated immediately upon any unauthorized use by you of the Site or any and/or all of WikiReviews’s content.
                                  </p>
                                  <p className="fs-14"><b><u>Content and Communications</u></b>
                                  </p>
                                  <p className="fs-14">WikiReviews contains message boards, chat rooms, personal web pages or profiles, review portals, and other interactive features that allow users to post, submit, publish, display, review, or transmit to other users or other persons (hereafter, “post”) content or materials (collectively, the “User Uploads”) on or through the Site. All User Uploads will be subject to the Wikireviews “Content Guidelines,” found at <mark><a href="https://wikireviews.com/guidelines#content-guidelines">https://wikireviews.com/guidelines#content-guidelines</a></mark>
                                  </p>
                                  <p className="fs-14">We always appreciate your feedback or other suggestions about WikiReviews, but you understand that we may use your feedback or suggestions without any obligation to compensate you for them (just as you have no obligation to offer them). By posting User Uploads to the Site, you hereby grant to WikiReviews a perpetual, worldwide, irrevocable, unrestricted, non-exclusive, royalty free license to use, copy, license, sublicense, adapt, distribute, display, publicly perform, reproduce, transmit, modify, edit and otherwise exploit User Uploads, in all media now known or hereafter developed. You give us permission to use your name, profile picture, content, and information in connection with commercial, sponsored, or related content (such as a brand you like) served or enhanced by us. This means, for example, that you permit a business or other entity to pay us to display your name and/or profile picture with your content or information, without any compensation to you. If you have selected a specific audience for your content or information, we will respect your choice when we use it. You hereby waive all rights to any claim against WikiReviews for any alleged or actual infringements of any proprietary rights, rights of privacy and publicity, moral rights, and rights of attribution in connection with User Uploads.
                                  </p>
                                  <p className="fs-14">You represent and warrant that you own or control all rights to, including intellectual property rights to, the User Uploads and have the right to grant the license granted above to us and our licensees, successors and assigns, that all of your User Uploads will comply with the Content Guidelines (found at <mark><a href="https://wikireviews.com/guidelines#content-guidelines">https://wikireviews.com/guidelines#content-guidelines</a></mark>) and these Terms and Conditions, and that you understand and acknowledge that you have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.
                                  </p>
                                  <p className="fs-14"><b><u id="intro">Information Collected</u></b>
                                  </p>
                                  <p className="fs-14">WikiReviews contains message boards, chat rooms, personal web pages or profiles, review portals, and other interactive features that allow users to post, submit, publish, display, review, or transmit to other users or other persons (hereafter, “post”) content or materials (collectively, the “User Uploads”) on or through the Site. All User Uploads will be subject to the Wikireviews “Content Guidelines,” found at <mark><a href="https://wikireviews.com/guidelines#content-guidelines">https://wikireviews.com/guidelines#content-guidelines</a></mark>
                                  </p>
                                  <p className="fs-14"><b><u id="info">Use of Information</u></b>
                                  </p>
                                  <p className="fs-14">In general, we may use the information you provide to us for whatever purpose we deem appropriate, including geo-targeting marketing campaigns. We may also use this information to deliver information to you about WikiReviews and promotional material from some of our partners, or to conduct trend analysis, pattern detection, and Site administration.
                                  </p>
                                  <p className="fs-14">We may access your information in order to mine data for sharing with third parties namely, advertisers, marketers, and other data aggregators. Your information may also be used to contact you when necessary and may be shared with other companies that may want to contact you with offers consistent with your stated preferences. Users may opt-out of receiving future mailings from WikiReviews and other entities by following the instructions set forth in the opt-out section below.
                                  </p>
                                  <p className="fs-14">We may access your information in order to mine data for sharing with third parties namely, advertisers, marketers, and other data aggregators. Your information may also be used to contact you when necessary and may be shared with other companies that may want to contact you with offers consistent with your stated preferences. Users may opt-out of receiving future mailings from WikiReviews and other entities by following the instructions set forth in the opt-out section below.
                                  </p>
                                  <p className="fs-14">Personal demographic and profile data is used to tailor your experience at our Site, showing you content we think you might be interested in. This information may also be shared with advertisers on an aggregate and personal basis.
                                  </p>
                                  <p className="fs-14">We may investigate and disclose information from or about you if we have a good faith belief that such investigation or disclosure is (a) reasonably necessary to comply with legal process and law enforcement instructions and orders, such as a search warrant, subpoena, statute, judicial proceeding, or other legal process served on us; (b) helpful to prevent, investigate, or identify possible wrongdoing in connection with the Site; (c) in connection with our Terms of Service and other agreements; (d) in connection with a corporate transaction, such a divestiture, merger, consolidation, or asset sale or (e) protect our rights, reputation, property, or that of our users affiliates, or the public.
                                  </p>
                                  <p className="fs-14"><b><u id="app">Mobile App</u></b>
                                  </p>
                                  <p className="fs-14">Download of the Mobile App for mobile platforms and devices (“Devices”) is for personal use only. You may not use the Mobile App on any Device that you do not own. You agree that by downloading the Mobile App you are granted a license to use, but do not otherwise acquire any ownership rights in, the downloaded code or third party content. You will not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Mobile App, other than User Uploads. You will not make any changes to any content that you are permitted to download under these Terms, and in particular you will not delete or alter any proprietary rights or attribution notices in any content, other than User Uploads.
                                  </p>
                                  <p className="fs-14">We currently provide our Mobile App for free, but your carrier's normal rates and fees, such as text messaging and data charges, will still apply. We may automatically check the version of the Mobile App installed on your Device and, if applicable, provide updates which may include but are not limited to, bug fixes, patches, enhanced functionality, plug-ins and new versions of the Mobile App (“Updates”). By installing the Mobile App, you authorize the automatic download and installation of Updates and agree to manually download and install Updates as required. We reserve the right to temporarily disable or permanently discontinue any and all functionality of the Mobile App at any time without notice or liability.
                                  </p>
                                  <p className="fs-14"><b><u id="prop_rights">Intellectual Property Rights</u></b>
                                  </p>
                                  <p className="fs-14">The Site is protected by copyright as a collective work and/or compilation, pursuant to U.S. copyright laws, international conventions, and other copyright laws. All information and content contained on the Site, including but not limited to trademarks, graphics, images, and logos are protected by U.S. and international intellectual property laws, and are owned or controlled by WikiReviews or the party credited as the provider of the content. You will abide by any and all additional copyright notices, information, or restrictions contained in any content on the Site. Copying or storing of any content other than for personal noncommercial use is expressly prohibited without the prior written permission from WikiReviews or the copyright holder identified in the individual content's copyright notice.
                                  </p>
                                  <p className="fs-14">In addition, the software and source code of the Website and Mobile App (collectively referred to as the “Code”) are protected as a copyright work, pursuant to U.S. laws and international conventions and treaties. Use, such as but not limited to, reverse engineering, redistribution, copying, storing, or derivation, of any Code is strictly prohibited.
                                  </p>
                                  <p className="fs-14"><b><u id="restrictions">Restrictions</u></b>
                                  </p>
                                  <p className="fs-14">We are under no obligation to enforce the Terms on your behalf against another user. While we encourage you to let us know if you believe another user has violated the Terms, we reserve the right to investigate and take appropriate action at our sole discretion.
                                  </p>
                                  <p className="fs-14">You agree not to, and will not assist, encourage, or enable others to use the Site to:
                                  </p>
                                  <ul>
                                    <li>
                                        
                                        Violate our <Link to="/guidelines">Content Guidelines</Link>, for example, by writing a fake or defamatory review, trading reviews with other businesses, or compensating someone or being compensated to write or remove a review; or mislead WikiReviews and the WikiCommunity by rating reviews incorrectly and by providing misinformation when validating and verifying a business.  
                                            
                                    </li>
                                        <li>
                                        
                                        Violate any third party's rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;  
                                            
                                        </li>
                                        <li>
                                        
                                        Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not; engage in keyword spamming, or otherwise attempt to manipulate the Site's search results or any third party website;  
                                            
                                        </li> 
                                        <li>
                                        
                                        Solicit personal information from minors, or submit or transmit pornography; or 
                                            
                                        </li> 
                                        <li>
                                        
                                        Transmit any computer viruses, worms, defects, Trojan horses or other items of a destructive nature (collectively, "Viruses");
                                            
                                        </li> 
                                        <li>
                                        
                                        Violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the Site or the Site’s content; or
                                            
                                        </li> 
                                        <li>
                                        
                                        Violate any applicable law.
                                            
                                        </li> 
                                    
                                        </ul>
                                        <p className="fs-14">You also agree not to, and will not assist, encourage, or enable others to:
                                        </p>
                                        <ul>	
                                        <li>
                                            
                                            Violate the Terms;
                                                
                                            </li> 
                                        <li>
                                            
                                            Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit the Site or the Site’s content (other than User Uploads), except as expressly authorized by WikiReviews;
                                                
                                        </li> 
                                        <li>
                                            
                                            Use any robot, spider, site search/retrieval application, or other automated device, process or means to access, retrieve, scrape, or index any portion of the Site or any of the Site’s content;
                                                
                                        </li> 
                                        <li>
                                            
                                        Reverse engineer any portion of the Site or Mobile Application;
                                                
                                        </li> 
                                        <li>
                                            
                                            Remove or modify any copyright, trademark or other proprietary rights notice that appears on any portion of the Site or on any materials printed or copied from the Site;
                                                
                                        </li> 
                                        <li>
                                            
                                            Record, process, or mine information about other users;
                                                
                                        </li> 
                                        <li>
                                            
                                            Access, retrieve or index any portion of the Site for purposes of constructing or populating a searchable database of business reviews;
                                                
                                        </li>
                                        <li>
                                            
                                            Reformat or frame any portion of the Site;
                                                
                                        </li> 
                                        <li>
                                            
                                        Take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on WikiReviews’ technology infrastructure or otherwise make excessive traffic demands of the Site;
                                                
                                        </li> 
                                        <li>
                                            
                                            Attempt to gain unauthorized access to the Site, user accounts, computer systems or networks connected to the Site through hacking, password mining or any other means, or attack the Site or service via a denial-of-service (DOS) attack or a distributed denial-of-service (DDOS) attack;
                                                
                                        </li> 
                                        <li>
                                            
                                            Use any device, software or routine that interferes with the proper working of the Site, or otherwise attempt to interfere with the proper working of the Site;
                                                
                                        </li> 
                                        <li>
                                            
                                            Remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Site, features that prevent or restrict the use or copying of the Site’s content, or features that enforce limitations on the use of the Site.
                                                
                                        </li> 
                                    </ul>
                                    <p className="fs-14">The restrictions above only apply to the extent permissible under applicable law. We may use, post or refuse to post, disable from view, remove or reinstate any content (including User Uploads) in our sole discretion. Without limiting the generality of the foregoing, we may, but are not obligated to return any of User Uploads to you under any circumstances.
                                    </p>
                                    <p className="fs-14"><b><u id="third_party">Third Party Links</u></b>
                                    </p>
                                    <p className="fs-14">The Site may contain links and pointers to the other related World Wide Web Internet sites, resources, and sponsors of the Site. Links to and from the Site to other third party sites, maintained by third parties, do not constitute an endorsement by WikiReviews or any of its subsidiaries or affiliates of any third party resources, or their content. If you decide to access any of the third party sites linked to the Site, you do so entirely at your own risk. All such websites are subject to the policies and procedures of the owner of such websites. Because we have no control over such websites or resources or the individuals who make such contributions, you acknowledge and agree that WikiReviews is not responsible for the availability of such websites or resources or any contributions, neither endorses nor is responsible or liable for any content, advertising, products or other materials on or available from such websites or resources or the content of any contributions and shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such websites or resource.
                                    </p>
                                    <p className="fs-14"><b><u id="copyright">Digital Millennium Copyright Notice</u></b>
                                    </p>
                                    <ul>
                                            <li>
                                                    
                                                    a description of the copyrighted work that you claim has been infringed;  
                                                    
                                            </li>  
                                            <li>
                                                    
                                                    a description of where the allegedly infringing material is located on the Site, including the URLs of the particular web pages;  
                                                    
                                            </li>  
                                            <li>
                                                    
                                                    your address, telephone number, and email address;  
                                                    
                                            </li>  
                                            <li>
                                                    
                                                    a written statement by you that you have a good faith belief that the use on the Site is not authorized by the copyright owner, its agent, or the law;  
                                                    
                                            </li>  
                                            <li>
                                                    
                                                    a statement by you, made under penalty of perjury, that the foregoing information is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf;
                                                    
                                            </li>  
                                            <li>
                                                    
                                                    an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest.
                                                    
                                            </li>  
                                            
                                        </ul>
                                        <p className="fs-14">WikiReviews’ Designated Agent for copyright claims can be contacted as follows:
                                        </p>
                                        <p className="fs-14">WIKIREVIEWS DMCA NOTICE 
                                        <br/>Attn: Sunil S. Wagle
                                        <br/>26 Burlingame
                                        <br/>Irvine, CA 92602
                                        </p>
                                        <p className="fs-14">We may, from time to time, change our Designated Agent for copyright claims and update you by releasing an updated Terms page. At any time, you may verify this information by accessing the website <mark><a href="https://dmca.copyright.gov/osp/">https://dmca.copyright.gov/osp/</a></mark>.  
                                        </p>
                                        <p className="fs-14"><b><u id="warranties">Disclaimer of Warranties</u></b>
                                        </p>
                                        <p className="fs-14">The Site and the App, including all content, software, functions, materials and information made available on or accessed through the Site and the App, are provided "as is." To the fullest extent permissible by law, WikiReviews and its subsidiaries and affiliates make no representation or warranties of any kind whatsoever for the content on the Site, the App, or the materials, information and functions made accessible by the software used on or accessed through the Site, the App, for any products or services or hypertext links to third parties or for any breach of security associated with the transmission of sensitive information through the Site, the App, or any linked site. Further, WikiReviews and its subsidiaries and affiliates disclaim any express or implied warranties, including, without limitation, non-infringement, merchantability or fitness for a particular purpose. WikiReviews does not warrant that the functions contained in the Site or the App, or any materials or content contained therein will be uninterrupted or error free, that defects will be corrected, or that the Site, the App, or WikiReviews’ server(s) that makes it available is free of viruses or other harmful components. WikiReviews and its subsidiaries and affiliates shall not be liable for the use of the Site or the App, including, without limitation, the content and any errors contained therein or thereon.
                                        </p>
                                        <p className="fs-14">WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, MOBILE DEVICE, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
                                        </p>
                                        <p className="fs-14"><b><u id="reliance">Reliance on Information Posted</u></b>
                                        </p>
                                        <p className="fs-14">The information presented on or through the Site or by way of our Services is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site or the Services, or by anyone who may be informed of any of its contents. This Site may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators or reporting services. All statements or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by us, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect our opinion. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
                                        </p>
                                        <p className="fs-14"><b><u id="indemnification">Indemnification</u></b>
                                        </p>
                                        <p className="fs-14">You hereby agree to indemnify, defend and hold WikiReviews, and all its officers, directors, owners, agents, employees, information providers, affiliates, licensors and licensees (collectively, the "Indemnified Parties") harmless from and against any and all liability and costs incurred by the Indemnified Parties in connection with any claim arising out of any breach by you of these Terms or the foregoing representations, warranties and covenants, including, without limitation, attorneys' fees and costs. You shall cooperate as fully as reasonably required in the defense of any claim. WikiReviews reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you and you shall not in any event settle any matter without the written consent of WikiReviews.
                                        </p>
                                        <p className="fs-14"><b><u id="law">Governing Law</u></b>
                                        </p>
                                        <p className="fs-14">These Terms and your use or access of WikiReview shall be governed by and construed in accordance with the laws of the state of California, without regard to conflicts of laws provisions.
                                        </p>
                                        <p className="fs-14"><b><u id="dispute">Dispute Resolution and Binding Arbitration</u></b>
                                        </p>
                                        <p className="fs-14"><b>YOU AND WIKIREVIEWS ARE AGREEING TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A JURY. OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT MAY ALSO BE UNAVAILABLE OR MAY BE LIMITED IN ARBITRATION.</b>
                                        </p>
                                        <p className="fs-14"><b>ANY CLAIM, DISPUTE OR CONTROVERSY (WHETHER IN CONTRACT, TORT OR OTHERWISE, WHETHER PRE-EXISTING, PRESENT OR FUTURE, AND INCLUDING STATUTORY, CONSUMER PROTECTION, COMMON LAW, INTENTIONAL TORT, INJUNCTIVE AND EQUITABLE CLAIMS) BETWEEN YOU AND US ARISING FROM OR RELATING IN ANY WAY TO YOUR PURCHASE OF PRODUCTS OR SERVICES THROUGH THE WEBSITE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION.
                                                             </b>
                                        </p>
                                        <p className="fs-14">The arbitration will be administered by the American Arbitration Association (“<b>AAA</b>”) in accordance with the Consumer Arbitration Rules (the “<b>AAA Rules</b>”) then in effect, except as modified by this Section. (The AAA Rules are available at www.adr.org/arb_med or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will govern the interpretation and enforcement of this Section. The arbitrator will have exclusive authority to resolve any dispute relating to arbitrability and/or enforceability of this arbitration provision, including any unconscionability challenge or any other challenge that the arbitration provision or the agreement is void, voidable or otherwise invalid. The arbitrator will be empowered to grant whatever relief would be available in court under law or in equity. Any award of the arbitrator(s) will be final and binding on each of the parties, and may be entered as a judgment in any court of competent jurisdiction.
                                        </p>
                                        <p className="fs-14">You agree to an arbitration on an individual basis. In any dispute, <b>NEITHER YOU NOR WIKIREVIEWS WILL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS BY OR AGAINST OTHER USERS (COMMERCIAL OR PERSONAL) IN COURT OR IN ARBITRATION OR OTHERWISE PARTICIPATE IN ANY CLAIM AS A CLASS REPRESENTATIVE, CLASS MEMBER OR IN A PRIVATE ATTORNEY GENERAL CAPACITY.</b> The arbitral tribunal may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding. The arbitral tribunal has no power to consider the enforceability of this class arbitration waiver and any challenge to the class arbitration waiver may only be raised in a court of competent jurisdiction.
                                        </p>
                                        <p className="fs-14">If any provision of this arbitration Section is found unenforceable, the unenforceable provision will be severed and the remaining arbitration terms will be enforced.
                                        </p>
                                        <p className="fs-14"><b><u id="release">Release</u></b>
                                        </p>
                                        <p className="fs-14">WIKIREVIEWS IS NOT RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES. PURSUANT TO CALIFORNIA LAW, YOU WAIVE CIVIL CODE §1542, WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR. WE WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR OTHER CONSEQUENTIAL, SPECIAL, INDIRECT, OR INCIDENTAL DAMAGES ARISING OUT OF OR IN CONNECTION YOUR USE OR ACCESS OF WIKIREVIEWS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY ARISING OUT OF YOUR USE OR ACCESS OF WIKIREVIEWS WILL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID US IN THE PAST TWELVE MONTHS. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, WIKIREVIEWS' LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
                                        </p>
                                        <p className="fs-14"><b><u id="agreement">Entire Agreement</u></b>
                                        </p>
                                        <p className="fs-14">These Terms constitutes the entire agreement between WikiReviews and you with respect to your use of the Site. If for any reason a court of competent jurisdiction finds any provision of these Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to affect the intent of these Terms, and the remainder of these Terms shall continue in full force and effect.
                                        </p>
                               </div>
                            </Col>
                         </Row>
                      </div>
                  </Container>
                </div>
            </div>
        )
    }
}
