import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";
export const GET_FAVORITE_DATA = "GET_FAVORITE_DATA";
export const ADD_FAVORITE = "ADD_FAVORITE";
export const SEARCH_FAVORITE = "SEARCH_FAVORITE";
export const GET_FAVORITE_PROFESSION_DATA = "GET_FAVORITE_PROFESSION_DATA";

export const get_favorite_list_ = (data) => {
  return {
    type: GET_FAVORITE_DATA,
    payload: data,
  };
};

export const get_favorite_list = (type, id) => {
  let url = `/api/pins/?&type=${type}&user=`
  if (id)
    url = `/api/pins/?&type=${type}&user=${id}`
  return (dispatch) => {
    callApi(
      url,
      "GET"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_favorite_list_(response));
      }
    });
  };
};

export const get_favorite_professionpins_ = (data) => {
  return {
    type: GET_FAVORITE_PROFESSION_DATA,
    payload: data,
  };
};

export const get_favorite_professionpins = (type) => {
  return (dispatch) => {
	callApi(
      `/api/professionalpins/?&type=${type}&user=&in_page=private`,
      "GET"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_favorite_professionpins_(response));
      }
    });
  };
};


export const add_favorite_ = (data) => {
  return {
    type: ADD_FAVORITE,
    payload: data,
  };
};


export const add_favorite = (data,type) => {
  return (dispatch) => {
    callApi(`/api/pins/?&type=${data.type}&user=`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
		  toast(`${data.user_entry} added to your favorites list.`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_favorite_list(type));
      }
    });
  };
};

export const search_favorite_ = (data) => {
  return {
    type: SEARCH_FAVORITE,
    payload: data,
  };
};

export const search_favorite = (query) => {
  return (dispatch) => {
    callApi(`/api/search/?location=false&page=1&q=${query}&sensor=false&size=5`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(search_favorite_(response));
      }
    });
  };
};

export const clear_search_favorite = () => {
  return (dispatch) => {dispatch(search_favorite_([]));
   
  };
};

export const delete_favorite = (favoriteId, type,favoritetype,name,professional = false) => {
  return (dispatch) => {
    callApi(`/api/${professional ? 'professionalpins' : 'pins'}/${favoriteId}/?type=${type}`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${name} has been removed from your favorites list.`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        if(professional){
          return dispatch(get_favorite_professionpins(favoritetype));
        }else{
          return dispatch(get_favorite_list(favoritetype));
        }
      }
    });
  };
};




