import React, { Component } from 'react';
import { Row, Col, Button, FormGroup, Label, Input, Modal, ModalBody, ModalHeader, ButtonGroup, ModalFooter, Media, Progress, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CollapseBasic from '../../atoms/collapse';
import { connect } from "react-redux";
import { my_profile } from "../../../actions/user";
import { get_community_review_projects, get_project, get_project_responses, publish_project, update_project_status, get_project_taxonomies, get_project_subcategories, get_project_subcategories_level, add_project, get_category_questions, get_friends } from "../../../actions/communityReviewProjects";
import { callApi } from "../../../utils/apiCaller";
import Moment from 'react-moment';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import { get_album_types_list, get_album_type_data, delete_selected_gallery_media } from "../../../actions/user";
import { upload_add_captions } from "../../../actions/myProfiles";
import {
    fetch_sub_categories, fetch_categories_data, filter_categories_data, clear_category, clear_subcategory
} from '../../../actions/business';

const initialAddProject = {
    crpType: 'express',
    chooseOne: 'newproject',
    project_taxonomy: '',
    subCategory1: '',
    subCategory2: '',
    taxonomy: '',
    title: '',
    info: '',
    mediaCount: '',
    media_description: '',
    media_description_second: '',
    mainQuestion: '',
    selectedOwnMain: '',
    selectedOwnMainDisabled: true,
    personality: '',
    errors: null,
    confidenceLevel: '90',
    isGeographic: false,
    isDemographic: false,
    country: 'usa',
    characterstics: {
        "21": [],
        "22": [],
        "23": [],
        "24": [],
        "25": [],
        "26": [],
        "29181": [],
        "28422": [],
        "28423": [],
        "28424": [],
        "28425": [],
        "28421": [],
    },
    taxonomyTree: [],
    additionalQuestions: {},
    additionalQuestionsCount: ['1'],
    openEndedQuestion: '',
    openEndedQuestionDisabled: true
};

const initialUploadMedia = {
    projectImages: {
        showFiles: [],
        selectedMedia: [],
        selectedMediaIds: [],
        uploadedFiles: []
    },
    firstItem: {
        showFiles: [],
        selectedMedia: [],
        selectedMediaIds: [],
        uploadedFiles: []
    },
    secondItem: {
        showFiles: [],
        selectedMedia: [],
        selectedMediaIds: [],
        uploadedFiles: []
    },
    mediaType: "image",
    albumType: "",
    pageNo: 1,
    albumTypesList: [],
    albumTypeData: {},
    embedLinks: {},
    uploadFiles: [],
    progress: 0,
};
class CommunityModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewCRPType: 'all',
            detailedViewId: 0,
            myProfileInfo: null,
            communityReviewProjects: null,
            addProjectVisible: false,
            editCrpProjectModalToggle: false,
            projectInfo: null,
            viewCrpResponsesModalToggle: false,
            selectProjectToCopyModalToggle: false,
            saveDraftPreviewModalToggle: false,
            deleteProjectId: 0,
            confirmDeleteModal: false,
            //host: window.location.host,
            //protocol: window.location.protocol,
            //shareTitle: 'Log in or sign up for WikiReviews to view and rate my Community Review Project!',
            //shareVia: 'WikiReviews',
            //fbHashTag: '#WikiReviews',
            //twitterHashTags: ['WikiReviews'],
            addProject: {
                ..._.cloneDeep(initialAddProject)
            },
            uploadMedia: {
                ..._.cloneDeep(initialUploadMedia)
            },
            showProjectSubCategories: false,
            showProjectSubCategoriesLevel: false,
            editProject: {
                selectedOwnMainDisabled: true,
            },
            projectQuestions: [],
            kickstartReviewModalToggle: false,
            postToFacebook: false,
            postToTwitter: false,
            friendsStatus: 'all',
            selectedFriends: [],
            searchFriend: '',
            showSearchFriendList: false,

            fetchGalleryAlbum: "",
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            uploadMediaPostModal: false,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            albumType: '',
            pageNo: 1,
            uploadCaptions: {},
            fetchTaxonomyListing: [],
            fetchTaxomomyCategory: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            taxonomycategoryId: "",
            categoryType: "business",
            captionData: "",
            uploadedId: {},
            cattaxListing: {},
            mode: "",
            uploadMediaModalType: 'projectImages',
        }
        this.refGalleryVideoScroll = React.createRef();
        this.refGalleryImageScroll = React.createRef();
    }

    handleOnClickCopyFromProject = async (project) => {

        await this.props.get_category_questions(project.taxonomy);

        const newAddProject = { ..._.cloneDeep(initialAddProject) };
        const newUploadMedia = { ..._.cloneDeep(initialUploadMedia) };

        newAddProject.chooseOne = 'existing';
        newAddProject.taxonomy = project.taxonomy;
        newAddProject.taxonomyTree = project.taxonomy_tree;
        newAddProject.title = project.title;
        newAddProject.info = project.info;
        newAddProject.confidenceLevel = project.confidence_level;
        newAddProject.mediaCount = 'single';
        newAddProject.isGeographic = project.geography_flag;
        newAddProject.isDemographic = project.reviewer_demography ? true : false;

        let keys = Object.keys(project.characterstics);
        if (keys.length > 0) {
            newAddProject.characterstics = { ...newAddProject.characterstics, ...project.characterstics };

            if (keys.includes('28421')) {
                newAddProject.country = 'canada';
            }
        }

        if (project.projectquestionanswers_set.length > 0) {
            for (const item of project.projectquestionanswers_set) {
                if (item.question_type === '1') {
                    if (!item.body && item.related_taxonomy_id) {
                        newAddProject.mainQuestion = item.related_taxonomy_id.toString();
                    } else {
                        newAddProject.selectedOwnMainDisabled = false;
                        newAddProject.selectedOwnMain = item.body;
                    }
                } else {
                    if (newAddProject.crpType === 'express') {
                        newAddProject.crpType = 'expanded';
                    }
                    let key = (parseInt(item.question_type) - 1).toString();
                    newAddProject.additionalQuestions[key] = item.related_taxonomy_id.toString();
                    if (newAddProject.additionalQuestionsCount.length <= 4) {
                        let value = (parseInt(newAddProject.additionalQuestionsCount[newAddProject.additionalQuestionsCount.length - 1]) + 1).toString();
                        newAddProject.additionalQuestionsCount.push(value);
                    }
                }
            }
        }

        newAddProject.media_description = project.main_images && project.main_images.length > 0 && project.main_images[0].description ? project.main_images[0].description : '';

        newAddProject.media_description_second = project.main_images && project.main_images.length > 0 && project.main_images[0].description_second ? project.main_images[0].description_second : '';

        if (project.main_images && project.main_images.length > 0) {
            newUploadMedia.projectImages.selectedMedia = project.main_images;
            newUploadMedia.projectImages.selectedMediaIds = project.main_images.map(img => img.id);
        }

        if (project.second_item_images && project.second_item_images.length > 0) {
            newUploadMedia.secondItem.selectedMedia = project.second_item_images;
            newUploadMedia.secondItem.selectedMediaIds = project.second_item_images.map(img => img.id);
            newAddProject.mediaCount = 'multiple';
        }

        if (project.first_item_images && project.first_item_images.length > 0) {
            newUploadMedia.firstItem.selectedMedia = project.first_item_images;
            newUploadMedia.firstItem.selectedMediaIds = project.first_item_images.map(img => img.id);
        }

        this.setState({
            selectProjectToCopyModalToggle: false,
            addProject: newAddProject,
            uploadMedia: newUploadMedia,
        });
    }

    getAddProjectErrors = () => {
        const { addProject, uploadMedia } = this.state;
        addProject.errors = {};
        if (addProject.title === '') {
            addProject.errors.title = "This field is required!";
        } else if (addProject.title.length > 50) {
            addProject.errors.title = "Max. character limit is 50!";
        }

        if (addProject.info === '') {
            addProject.errors.info = "This field is required!";
        } else if (addProject.info.length > 90) {
            addProject.errors.info = "Max. character limit is 90!";
        }

        // if((!addProject.selectedOwnMainDisabled) && addProject.selectedOwnMain.length > 40){
        //     addProject.errors.selectedOwnMain = "Max. character limit is 40!";
        // }else if(addProject.selectedOwnMainDisabled && addProject.errors.selectedOwnMain){
        //     delete addProject.errors.selectedOwnMain;
        // }

        if (!addProject.selectedOwnMainDisabled) {
            if (addProject.selectedOwnMain.length > 40) {
                addProject.errors.selectedOwnMain = "Max. character limit is 40!";
            } else if (addProject.selectedOwnMain.length == 0) {
                addProject.errors.selectedOwnMain = "Please select/write your main question!";
            }
        } else if (addProject.selectedOwnMainDisabled && addProject.errors.selectedOwnMain) {
            delete addProject.errors.selectedOwnMain;
        }

        if (addProject.selectedOwnMainDisabled && !addProject.mainQuestion) {
            addProject.errors.selectedOwnMain = "Please select/write your main question!";
        } else if (addProject.selectedOwnMainDisabled && addProject.mainQuestion && addProject.errors.selectedOwnMain) {
            delete addProject.errors.selectedOwnMain;
        }

        if (uploadMedia.projectImages.selectedMediaIds.length === 0) {
            addProject.errors.projectImages = "Please upload an image!";
        }

        if (addProject.mediaCount === '') {
            addProject.errors.mediaCount = "This field is required!";
        } else {
            if (uploadMedia.firstItem.selectedMediaIds.length === 0) {
                addProject.errors.firstItem = "Please upload an image!";
            }

            if (addProject.media_description === '') {
                addProject.errors.media_description = "This field is required!";
            } else if (addProject.media_description.length > 90) {
                addProject.errors.media_description = "Max. character limit is 90!";
            }

            if (addProject.mediaCount === 'multiple' && uploadMedia.secondItem.selectedMediaIds.length === 0) {
                addProject.errors.secondItem = "Please upload an image!";
            }

            if (addProject.mediaCount === 'multiple' && addProject.media_description_second === '') {
                addProject.errors.media_description_second = "This field is required!";
            } else if (addProject.media_description_second.length > 90) {
                addProject.errors.media_description_second = "Max. character limit is 90!";
            }
        }

        this.setState({
            addProject: { ...addProject }
        });

        if (Object.keys(addProject.errors).length > 0) {
            return true;
        }
    }

    handleOnSubmitAddProject = async (submitType) => {
        if (this.getAddProjectErrors()) {
            return false;
        } else {
            const { addProject, uploadMedia } = this.state;
            let data = {
                title: addProject.title,
                info: addProject.info,
                taxonomy: addProject.taxonomy,
                image: uploadMedia.projectImages.selectedMediaIds[0] ? uploadMedia.projectImages.selectedMediaIds[0] : '',
                is_draft: submitType === 'submit' ? false : true,
                is_express: false,
                characterstics: addProject.characterstics,
                projectmedia_set: {
                    second_image: uploadMedia.secondItem.selectedMediaIds[0] ? uploadMedia.secondItem.selectedMediaIds[0] : '',
                    second_item_media: uploadMedia.secondItem.selectedMediaIds,
                    first_image: uploadMedia.firstItem.selectedMediaIds[0] ? uploadMedia.firstItem.selectedMediaIds[0] : '',
                    first_item_media: uploadMedia.firstItem.selectedMediaIds,
                    description: addProject.media_description,
                    description_second: addProject.media_description_second,
                    main_images: uploadMedia.projectImages.selectedMediaIds
                },
                projectquestionanswers_set: [
                    {
                        related_taxonomy_id: addProject.selectedOwnMainDisabled && addProject.mainQuestion ? addProject.mainQuestion : '',
                        body: !addProject.selectedOwnMainDisabled && addProject.selectedOwnMain ? addProject.selectedOwnMain : '',
                        question_type: "1",
                        type: "projects"
                    }
                ],
                rem_images: [],
                confidence_level: addProject.confidenceLevel
            };

            if (Object.keys(addProject.additionalQuestions).length) {
                for (let k in addProject.additionalQuestions) {
                    let question = {
                        related_taxonomy_id: addProject.additionalQuestions[k],
                        question_type: `${parseInt(k) + 1}`,
                        type: 'projects'
                    };
                    data.projectquestionanswers_set.push(question);
                }
            }

            const tab = this.state.viewCRPType,
                url = this.getUrl(tab);

            if (addProject.crpType === 'express' && addProject.chooseOne === 'newproject') {
                data.is_express = true;
            }

            if (url) {
                await this.props.add_project(data, url, submitType);
                if (submitType === 'draft') {
                    // this.setState({
                    //     saveDraftPreviewModalToggle: true
                    // });
                    await this.handleOnClickCancelAddProject();
                    await this.props.toggleCommunityModal();
                    this.props.history.push('/myprofile?active_tab=crp');

                } else {
                    this.setState({
                        kickstartReviewModalToggle: true
                    });
                    await this.handleOnClickCancelAddProject();
                    await this.props.toggleCommunityModal();
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.community_review_projects_data && Object.keys(nextProps.community_review_projects_data).length > 0) {
            this.setState({
                communityReviewProjects: nextProps.community_review_projects_data
            });
        }
        if (nextProps.project_data && Object.keys(nextProps.project_data).length > 0) {
            this.setState({
                projectInfo: nextProps.project_data
            });
        }

        // if (nextProps.project_questions) {
        //     this.setState({
        //         projectQuestions: nextProps.project_questions
        //     });
        // }

        let { fetchGalleryScroll, fetchGalleryCount } = this.state;
        if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypesList: nextProps.album_types_list,
                },
                //uploadMediaModal: true,
                selectedUpMediaType: "upload",
                showGalleryType: "images",
            });
        }

        if (
            nextProps.album_type_data &&
            Object.keys(nextProps.album_type_data).length > 0
        ) {
            let item = nextProps.album_type_data;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({
                ...this.state,
                //selectedUpMediaType: "gallery",
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypeData: nextProps.album_type_data,
                },
                fetchGalleryScroll, fetchGalleryCount,
            });
        }

        if (nextProps.fetch_data_categories) {
            let { fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state;
            let taxCatListing = nextProps.fetch_data_categories;
            if (taxCatListing && Array.isArray(taxCatListing) && taxCatListing.length > 0) {
                //taxCatListing = taxCat.filter(dt => dt.label !== null);
                if (taxCatListing[0].isTypes == "image") {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingImages[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingImages });
                    }

                } else {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingVideos[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingVideos });
                    }
                }
            }
        }

        if (nextProps.filter_data_category) {
            let taxCatList = nextProps.filter_data_category;

            if (taxCatList && Object.keys(taxCatList).length > 0) {
                this.setState({ cattaxListing: taxCatList[0] ? taxCatList[0].children : [] })

            }
        }
    }

    componentDidMount() {
        // this.props.get_project_taxonomies();
        callApi(`/api/myprofile/?q=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                this.props.get_community_review_projects(`/api/projects/?created_by__id=${response.id}&is_active=True&page=1`);
                this.setState({
                    myProfileInfo: response
                });
            }
        });
        this.props.clear_category();
        this.props.clear_subcategory();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.project_questions !== this.props.project_questions) {
            this.setState({
                projectQuestions: this.props.project_questions
            });
        }
    }

    handleOnClickAddProject = () => {
        //this.props.get_project_taxonomies();
        this.setState({
            addProjectVisible: !this.state.addProjectVisible,
            addProject: {
                ..._.cloneDeep(initialAddProject)
            },
            uploadMedia: {
                ..._.cloneDeep(initialUploadMedia)
            },
            showProjectSubCategories: false,
            showProjectSubCategoriesLevel: false,
            selectProjectToCopyModalToggle: false,
            projectQuestions: [],
            kickstartReviewModalToggle: false,
            saveDraftPreviewModalToggle: false,
            postToFacebook: false,
            postToTwitter: false,
            friendsStatus: 'all',
            selectedFriends: [],
            searchFriend: '',
            showSearchFriendList: false,
            // fetchGalleryAlbum: "",
            // fetchGalleryScroll: {},
            // fetchGalleryCount: 0,
            // uploadMediaPostModal: false,
            // selectedUpMediaType: 'upload',
            // showGalleryType: 'images',
            // albumType: '',
            // pageNo: 1,
            // uploadCaptions: {},
            // fetchTaxonomyListing: [],
            // fetchTaxomomyCategory: "",
            // fetchTaxonomyListingImages: {},
            // fetchTaxonomyListingVideos: {},
            // taxonomycategoryId: "",
            // categoryType: "business",
            // captionData: "",
            // uploadedId: {},
            // cattaxListing: {},
            // mode: "",
            uploadMediaModalType: 'projectImages',
        });
    }

    searchFriendsForReview = async (e) => {
        let query = e.target.value;
        await this.props.get_friends(query);
        this.setState({
            searchFriend: query,
            showSearchFriendList: true
        });
    }

    handleSelectFriends = (item, type) => {
        let { selectedFriends } = this.state;
        if (type === 'add') {
            selectedFriends = selectedFriends.filter(el => el.id !== item.id);
            selectedFriends.push(item);
        } else {
            selectedFriends = selectedFriends.filter(el => el.id !== item.id);
        }
        this.setState({ selectedFriends, showSearchFriendList: false, searchFriend: '' });
    }

    handleOnChangeAddProject = (e, categoryType = '') => {
        if (categoryType && e.target.value) {
            this.handleOnClickCategory(e.target.value, categoryType);
        } else {
            let addProject = this.state.addProject;
            let errors = addProject.errors;

            if (errors && Object.keys(errors).length > 0) {
                if (errors[e.target.name]) {
                    delete errors[e.target.name];
                }
                addProject.errors = errors;
                if (e.target.name === 'mediaCount' && e.target.value === 'single') {
                    if (errors.media_description_second) {
                        delete errors.media_description_second;
                    }

                    if (errors.secondItem) {
                        delete errors.secondItem;
                    }
                }
            }

            if (e.target.name === 'openEndedQuestionDisabled') {
                addProject.openEndedQuestionDisabled = !addProject.openEndedQuestionDisabled;
            } else if (e.target.name.includes('additionalQuestion')) {
                let targetName = e.target.name.split('-')[1].toString();
                if (e.target.value === '') {
                    delete addProject['additionalQuestions'][targetName];
                } else {
                    addProject['additionalQuestions'][targetName] = e.target.value.toString();
                }
            } else {
                addProject[e.target.name] = e.target.value;
            }

            let selectProjectToCopyModalToggle = false;
            let viewCRPType = this.state.viewCRPType;
            if (e.target.name === 'chooseOne' && e.target.value === 'existing') {
                selectProjectToCopyModalToggle = true;
                this.props.get_community_review_projects(`/api/projects/?created_by__id=${this.state.myProfileInfo.id}&is_active=True&page=1`);
                viewCRPType = 'all';
            }

            this.setState({
                addProject: { ...addProject },
                selectProjectToCopyModalToggle: selectProjectToCopyModalToggle,
                viewCRPType: viewCRPType
            });
        }
    }

    handleOnClickAddProjectCheckbox = (groupId = 0, value = 0) => (e) => {
        if (!groupId) {
            let obj = {};
            if (e.target.name !== 'country') {
                if (e.target.name === 'isGeographic') {
                    obj = {
                        "28422": [],
                        "28423": [],
                        "28424": [],
                        "28425": [],
                        "28421": [],  //Canada
                    }
                } else if (e.target.name === 'isDemographic') {
                    obj = {
                        "21": [],
                        "22": [],
                        "23": [],
                        "24": [],
                        "25": [],
                        "26": [],
                        "29181": [],  //Gender
                    }
                }
                this.setState({
                    addProject: {
                        ...this.state.addProject,
                        [e.target.name]: !this.state.addProject[e.target.name],
                        characterstics: {
                            ...this.state.addProject.characterstics,
                            ...obj
                        }
                    }
                });
            } else {
                if (e.target.value === 'usa') {
                    obj = {
                        "28421": [], //Canada
                    }
                } else {
                    obj = {
                        "28422": [],
                        "28423": [],
                        "28424": [],
                        "28425": [],
                    }
                }
                this.setState({
                    addProject: {
                        ...this.state.addProject,
                        [e.target.name]: e.target.value,
                        characterstics: {
                            ...this.state.addProject.characterstics,
                            ...obj
                        }
                    }
                });
            }

        } else {
            let group = this.state.addProject.characterstics[groupId];
            if (group.includes(value)) {
                group = group.filter(item => item !== value);
            } else {
                group.push(value);
            }
            this.setState({
                addProject: {
                    ...this.state.addProject,
                    characterstics: {
                        ...this.state.addProject.characterstics,
                        [groupId]: group
                    }
                }
            });
        }

    }

    handleOnClickCancelAddProject = () => {
        this.setState({
            addProjectVisible: false,
            addProject: {
                ..._.cloneDeep(initialAddProject)
            },
            uploadMedia: {
                ..._.cloneDeep(initialUploadMedia)
            },
            showProjectSubCategories: false,
            showProjectSubCategoriesLevel: false,
            selectProjectToCopyModalToggle: false,
            projectQuestions: [],
            // fetchGalleryAlbum: "",
            // fetchGalleryScroll: {},
            // fetchGalleryCount: 0,
            // uploadMediaPostModal: false,
            // selectedUpMediaType: 'upload',
            // showGalleryType: 'images',
            // albumType: '',
            // pageNo: 1,
            // uploadCaptions: {},
            // fetchTaxonomyListing: [],
            // fetchTaxomomyCategory: "",
            // fetchTaxonomyListingImages: {},
            // fetchTaxonomyListingVideos: {},
            // taxonomycategoryId: "",
            // categoryType: "business",
            // captionData: "",
            // uploadedId: {},
            // cattaxListing: {},
            // mode: "",
            uploadMediaModalType: 'projectImages',
        });
    }

    handleOnClickCategory = (id, type) => {
        let showProjectSubCategories = false,
            showProjectSubCategoriesLevel = false,
            key = 'project_taxonomy';
        if (type === 'main') {
            this.props.get_project_subcategories(id);
            showProjectSubCategories = true;
        } else if (type === 'sub') {
            this.props.get_project_subcategories_level(id);
            showProjectSubCategories = true;
            showProjectSubCategoriesLevel = true;
            key = 'subCategory1';
        } else if (type === 'sublevel') {
            showProjectSubCategories = true;
            showProjectSubCategoriesLevel = true;
            key = 'subCategory2';
        }

        this.props.get_category_questions(id);

        this.setState({
            addProject: {
                ...this.state.addProject,
                taxonomy: id,
                [key]: id
            },
            showProjectSubCategories: showProjectSubCategories,
            showProjectSubCategoriesLevel: showProjectSubCategoriesLevel,
        });
    }

    handleOnFilePostUploadChange = (event) => {
        const { addProject, categoryType, uploadMediaModalType: type } = this.state;

        let errors = addProject.errors;
        if (errors && Object.keys(errors).length > 0) {
            if (errors[type]) {
                delete errors[type];
            }
        }

        let uploadFiles = event.target.files;
        let restrictVideo = false;
        let showFiles = [];
        for (const key of Object.keys(uploadFiles)) {
            showFiles.push({ id: "", url: URL.createObjectURL(uploadFiles[key]) });
            if (type === 'projectImages' && uploadFiles[key].type.includes('video')) {
                restrictVideo = true;
                break;
            }
        }
        if (restrictVideo) {
            errors = {};
            errors.projectImages = 'Please upload images only!';
            this.setState({
                addProject: {
                    ...this.state.addProject,
                    errors: errors
                }
            });
            return true;
        } else {
            this.setState({
                ...this.state,
                addProject: {
                    ...this.state.addProject,
                    errors: errors
                },
                uploadMedia: {
                    ...this.state.uploadMedia,
                    [type]: {
                        showFiles: showFiles,
                        selectedMedia: this.state.uploadMedia[type].selectedMedia,
                        selectedMediaIds: this.state.uploadMedia[type].selectedMediaIds,
                        uploadedFiles: this.state.uploadMedia[type].uploadedFiles
                    },
                    progress: 0,
                },
            });

            let url = '';
            if (type === 'projectImages') {
                url = "/upload/multiuploader/?album=CRP&instance=first&image=undefined&doc=first";
            } else {
                url = "/upload/multiuploader/?album=CRP&instance=second&image=undefined&doc=second";
            }

            let progressPart = 100 / showFiles.length;
            let progress = 0;

            for (const key of Object.keys(uploadFiles)) {
                let data = new FormData();
                data.append("file", uploadFiles[key]);
                callApi(
                    `${url}`,
                    "POST",
                    data,
                    true
                ).then((response) => {
                    if (response.id) {
                        this.handleOnClickSelectGalleryMedia(response, type);
                        if (showFiles.length === 1 || key === showFiles.length - 1) {
                            progress = 100;
                        } else {
                            progress = progress + progressPart;
                        }
                        showFiles[key].id = response.id;
                        showFiles[key].media_type = response.media_type;
                        let isImageType = false;
                        if (response.media_type == "image") {
                            isImageType = true;
                        }
                        this.props.filter_categories_data({ category: categoryType, sub_cat: "", level: 1 });
                        this.setState({
                            ...this.state,
                            uploadMedia: {
                                ...this.state.uploadMedia,
                                [type]: {
                                    ...this.state.uploadMedia[type],
                                    uploadedFiles: [...this.state.uploadMedia[type].uploadedFiles, response],
                                    showFiles: showFiles,
                                },
                                progress
                            },
                            isImageType
                        });
                    }
                });
            }
        }

    }

    handleOnClickSelectGalleryMedia = (media, type) => {
        let index = this.state.uploadMedia[type].selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        if (index !== -1) {
            newSelectedMediaIds = this.state.uploadMedia[type].selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = this.state.uploadMedia[type].selectedMedia.filter(
                (item) => item.id !== media.id
            );
        } else {
            newSelectedMediaIds = [
                ...this.state.uploadMedia[type].selectedMediaIds,
                media.id,
            ];
            newSelectedMedia = [...this.state.uploadMedia[type].selectedMedia, media];
        }
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                [type]: {
                    ...this.state.uploadMedia[type],
                    selectedMedia: newSelectedMedia,
                    selectedMediaIds: newSelectedMediaIds,
                }
            },
        });
    };

    handleOnClickRemoveSelectedMedia = (id) => () => {
        const { uploadMediaModalType, uploadMedia } = this.state;
        let removeMediaIds = [];
        removeMediaIds.push(id);
        this.props.delete_selected_gallery_media(removeMediaIds);
        let showFiles = uploadMedia[uploadMediaModalType].showFiles.filter(
            (file) => file.id !== id
        );
        let uploadedFiles = uploadMedia[uploadMediaModalType].uploadedFiles.filter(
            (file) => file.id !== id
        );
        let selectedMediaIds = uploadMedia[uploadMediaModalType].selectedMediaIds.filter(
            (item) => item !== id
        );
        let selectedMedia = uploadMedia[uploadMediaModalType].selectedMedia.filter(
            (file) => file.id !== id
        );
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                [uploadMediaModalType]: {
                    ...this.state.uploadMedia[uploadMediaModalType],
                    selectedMedia: selectedMedia,
                    selectedMediaIds: selectedMediaIds,
                    showFiles: showFiles,
                    uploadedFiles: uploadedFiles
                }
            },
        });
    };

    handleOnClickRemoveSelectedGalleryMedia = (media, type) => {
        let removeMediaIds = [];
        removeMediaIds.push(media.id);
        let index = this.state.uploadMedia[type].selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        let newShowFiles;
        let newUploadedFiles;
        if (index !== -1) {
            newSelectedMediaIds = this.state.uploadMedia[type].selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = this.state.uploadMedia[type].selectedMedia.filter(
                (item) => item.id !== media.id
            );
            newShowFiles = this.state.uploadMedia[type].showFiles.filter(
                (item) => item !== media.id
            );
            newUploadedFiles = this.state.uploadMedia[type].uploadedFiles.filter(
                (item) => item.id !== media.id
            );
            this.props.delete_selected_gallery_media(removeMediaIds);
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    [type]: {
                        ...this.state.uploadMedia[type],
                        selectedMedia: newSelectedMedia,
                        selectedMediaIds: newSelectedMediaIds,
                        showFiles: newShowFiles,
                        uploadedFiles: newUploadedFiles,
                    }
                },
            });
        }
    };

    handleOnClickGalleryType = (type) => {
        let mediaType = "";
        if (type === "images") {
            mediaType = "image";
        } else if (type === "videos") {
            mediaType = "video";
        }
        this.props.get_album_type_data(mediaType, "", 1);
        this.setState({
            ...this.state,
            selectedUpMediaType: "gallery",
            showGalleryType: type,
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: mediaType,
                albumType: "",
                pageNo: 1
            },
        });
    };

    handleOnClickAlbumTypeChange = (e) => {
        this.props.get_album_type_data("image", e.target.value, 1);
        this.setState({
            ...this.state,
            selectedUpMediaType: "gallery",
            showGalleryType: "images",
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: "image",
                albumType: e.target.value,
                pageNo: 1
            },
        });
    };

    handleOnClickSelectedUploadMediaType = (type) => {
        if (type === "gallery") {
            this.props.get_album_type_data("image", "", 1);
            this.setState({
                ...this.state,
                selectedUpMediaType: type,
                showGalleryType: "images",
                uploadMedia: {
                    ...this.state.uploadMedia,
                    mediaType: "image",
                    albumType: "",
                    pageNo: 1,
                    embedLinks: {},
                    progress: 0,
                },
            });
        } else if (type === "upload") {
            this.setState({
                ...this.state,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    embedLinks: {},
                    progress: 0,
                },
            });
        } else if (type === "embed") {
            let embedLinks = {
                0: "",
                1: "",
            };
            this.setState({
                ...this.state,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    embedLinks: embedLinks,
                },
            });
        }
    };

    handleOnClickUploadMedia = (type) => {
        this.props.get_album_types_list();
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                progress: 0,
                [type]: {
                    ...this.state.uploadMedia[type],
                    showFiles: [],
                    uploadedFiles: [],
                }
            },
            uploadMediaPostModal: true,
            selectedUpMediaType: "upload",
            showGalleryType: "images",
            uploadMediaModalType: type
        });
    };

    truncate = (filenameString) => {
        // let split = filenameString.split(".");
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };

    handleCaptionByIndex = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, uploadMedia, taxonomycategoryId, uploadMediaModalType } = this.state;
        uploadCaptions[index] = value;
        let data = {};
        let upload_data = uploadMedia[uploadMediaModalType].showFiles[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = taxonomycategoryId;
        let id = upload_data.id;
        this.setState({ uploadCaptions, captionData: caption_data }, () => this.props.upload_add_captions(id, data));
    }

    handleChangeCategory = (e, index) => {
        let { isImageType, uploadMedia, uploadedId, categoryType, uploadMediaModalType } = this.state;
        let upload_data = uploadMedia[uploadMediaModalType].showFiles[index];
        let id = upload_data.id;
        uploadedId[index] = id;
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ albumCategoryId: albumType, uploadedId });
        let categoryId = categoryType
        let subcategoryId = value
        if (isImageType) {
            this.props.fetch_categories_data(categoryId, subcategoryId, 'image', index);
        } else {
            this.props.fetch_categories_data(categoryId, subcategoryId, 'video', index);
        }
    }

    handleChangeTaxonomyData = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, uploadMedia, uploadMediaModalType } = this.state
        let data = {};
        let upload_data = uploadMedia[uploadMediaModalType].showFiles[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = value;
        let id = upload_data.id;
        this.setState({ taxonomycategoryId: value, captionData: caption_data }, () => this.props.upload_add_captions(id, data))
    }

    uploadMediaModalToggle = () => {
        const { uploadMediaModalType: type } = this.state;
        this.setState({
            ...this.state,
            uploadMediaPostModal: !this.state.uploadMediaPostModal,
            uploadMedia: {
                ...this.state.uploadMedia,
                [type]: {
                    ...this.state.uploadMedia[type],
                    selectedMedia: [],
                    selectedMediaIds: [],
                    uploadedFiles: [],
                    showFiles: [],
                },
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
            },
            selectedUpMediaType: "upload",
            showGalleryType: "images",
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            categoryType: "",
            uploadedId: {},
            captionData: ""
        });
    };

    handleScrollGalleryImage = (e) => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;
            if (nextCount <= roundValue) {
                let page = nextCount;
                this.props.get_album_type_data(type, "", page);
            }
        }
    }

    handleScrollGalleryVideo = (e) => {
        let { refGalleryVideoScroll } = this;
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll, uploadMediaModalType } = this.state;
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0) {
                return getDataItems.map(item => {

                    let itemName = (item?.thumbnail).split('.');
                    let itemFile = (item?.file).split('.');
                    let extName = itemName[itemName.length - 1];
                    let extFile = itemFile[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview

                    return (
                        <Col xs="auto" className="mb-3">
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={() =>
                                        this.handleOnClickSelectGalleryMedia(
                                            item, uploadMediaModalType
                                        )
                                    } />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    getUrl = (tab) => {
        const { myProfileInfo } = this.state;
        let url = '';
        if (tab === 'all') {
            url = `/api/projects/?created_by__id=${myProfileInfo.id}&is_active=True&page=1`;

        } else if (tab === 'new') {
            url = `/api/projects/?created_by__id=${myProfileInfo.id}&status=In Progress&is_published=False&is_draft=False&is_active=True&page=1`;

        } else if (tab === 'drafts') {
            url = `/api/projects/?created_by__id=${myProfileInfo.id}&is_draft=True&is_active=True&page=1`;

        } else if (tab === 'inProgress') {
            url = `/api/projects/?created_by__id=${myProfileInfo.id}&status=In Progress&is_draft=False&is_active=True&page=1`;

        } else if (tab === 'completed') {
            url = `/api/projects/?created_by__id=${myProfileInfo.id}&status=Completed&is_active=True&page=1`;

        } else if (tab === 'trash') {
            url = `/api/projects/?created_by__id=${myProfileInfo.id}&is_active=False&page=1`;

        } else if (tab === 'onHold') {
            url = `/api/projects/?created_by__id=${myProfileInfo.id}&status=Stopped&is_active=True&page=1`;

        } else if (tab === 'crpReviews') {
            url = `/api/projectreview/?user_id=${myProfileInfo.id}&page=1`;

        }
        return url;
    }

    renderQuestions = (type) => {
        let questions = [...this.state.projectQuestions];
        const addProject = { ..._.cloneDeep(this.state.addProject) };
        let selectedQuestions = addProject.mainQuestion ? [...Object.values(addProject.additionalQuestions), addProject.mainQuestion] : [...Object.values(addProject.additionalQuestions)];

        if (type === 'mq') {
            selectedQuestions = selectedQuestions.filter(item => item !== addProject.mainQuestion);
        } else {
            selectedQuestions = selectedQuestions.filter(item => item !== addProject.additionalQuestions[type]);
        }

        questions = questions.filter(item => !selectedQuestions.includes(item.id.toString()));

        return (
            <>
                <option value=''>Choose Question</option>
                {questions.map(item => (
                    <option value={item.id.toString()}>{item.category}</option>
                ))}
            </>
        );
    }

    handleOnClickAddAnotherQuestion = () => {
        const addProject = { ..._.cloneDeep(this.state.addProject) };
        if (addProject.additionalQuestionsCount.length <= 4) {
            const value = `${addProject.additionalQuestionsCount.length + 1}`
            addProject.additionalQuestionsCount.push(value);
        }
        this.setState({
            addProject: { ...addProject }
        });
    }

    onToggleCommunityModal = () => {
        this.props.toggleCommunityModal();
        this.handleOnClickAddProject();
    }

    render() {
        const { detailedViewId, myProfileInfo, communityReviewProjects, addProjectVisible, projectInfo, addProject, uploadMedia, showProjectSubCategories, showProjectSubCategoriesLevel, editProject, fetchTaxonomyListingImages, fetchTaxonomyListingVideos,
            uploadedId, cattaxListing, uploadMediaModalType } = this.state;
        const { project_responses, project_taxonomies, project_subcategories, project_subcategories_level, openModal, current_location } = this.props;

        return (
            <div>
                {/* Add CRP drawer modal popup */}
                <Modal size="xl" scrollable className="drawer-modal"
                    isOpen={openModal}
                    toggle={this.onToggleCommunityModal}>
                    <div className="modal-header p-0">
                        <div className="w-100">
                            <div>
                                <Button color="primary"
                                    onClick={this.onToggleCommunityModal}>
                                    <FontAwesomeIcon icon="angle-left" /> back to listing</Button>
                            </div>
                            <div>
                                <div className="bg-darker p-2 text-tertiary">
                                    <div className="d-flex mx-n2 text-light font-weight-bold">
                                        <div className="px-2">
                                            You’re Adding CRP For Review
             </div>
                                        <div className="px-2 ml-auto">
                                            {current_location ? `near ${current_location.city} ${current_location.country}` : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalBody className="bg-white text-dark">
                        {/* <div className="bg-white p-3"> */}
                        <CollapseBasic title="Step 1. Create a Project" noHr={true} containerClass="type-primary bg-transparent" isOpen={true} size="sm">
                            <Row className="mb-3">
                                <Col md={4}>
                                    <div className="text-darker font-weight-bold fs-14">
                                        Choose CRP Type
                                                              </div>
                                </Col>
                                <Col md={8}>
                                    <FormGroup check>
                                        <Label className="text-dark fs-14" check>
                                            <Input type="radio" name="crpType" value="express" onChange={this.handleOnChangeAddProject} checked={addProject.crpType === 'express'} />{' '}
                                                  Express CRP
                                              </Label>
                                        <div className="text-darker font-weight-bold fs-14">
                                            Easily upload projects and ask your main question (with our basic 90% confidence level).
                                              </div>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label className="text-dark fs-14" check>
                                            <Input type="radio" name="crpType" value="expanded" onChange={this.handleOnChangeAddProject} checked={addProject.crpType === 'expanded'} />{' '}
                                                  Expanded CRP
                                              </Label>
                                        <div className="text-darker font-weight-bold fs-14">
                                            Upload projects where you can ask more questions with demographic and geographic targeting and choose higher confidence levels.
                                              </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <div className="text-darker font-weight-bold fs-14">
                                        Choose one
                                                              </div>
                                </Col>
                                <Col md={8}>
                                    <FormGroup check>
                                        <Label className="text-dark fs-14" check>
                                            <Input type="radio" name="chooseOne" value="newproject" onChange={this.handleOnChangeAddProject} checked={addProject.chooseOne === 'newproject'} />{' '}
                                                    Create new project
                                                </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label className="text-dark fs-14" check>
                                            <Input type="radio" name="chooseOne" value="existing" onChange={this.handleOnChangeAddProject} checked={addProject.chooseOne === 'existing'} />{' '}
                                                Copy an existing project
                                            </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CollapseBasic>
                        <hr className="bg-light my-2" />

                        <CollapseBasic title="Step 2. Categorize your project" noHr={true} containerClass="type-primary bg-transparent" isOpen={true} size="sm">
                            <Row>
                                <Col md={4}>
                                    <div className="text-tertiary fs-14 ff-alt">
                                        How would you categorize your project? If none of the available options seem to fit, click "Suggest New" and enter your own category.
                                        </div>
                                </Col>
                                <Col md={8}>
                                    {addProject.taxonomyTree && addProject.taxonomyTree.length > 0 && (
                                        <div className="mb-2 ">
                                            <div className="text-dark font-weight-bold fs-14 d-inline-block mr-2">
                                                Copied project category:
                                            </div>
                                            <div className="text-dark fs-14 d-inline-block">
                                                {addProject.taxonomyTree[0]}
                                                {addProject.taxonomyTree[1] && (<span> &lt; {addProject.taxonomyTree[1]}</span>)}
                                                {addProject.taxonomyTree[2] && (<span> &lt; {addProject.taxonomyTree[2]}</span>)}
                                                <br />To change above selected category, please select new category from below drop down.
                                            </div>
                                        </div>
                                    )}
                                    <Label className="text-darker font-weight-bold fs-14">
                                        Project Type
                                        </Label>
                                    <Row form>
                                        <Col lg>
                                            <FormGroup>
                                                <Input className="category-select" type="select"
                                                    name="project_taxonomy" onChange={(e) => this.handleOnChangeAddProject(e, 'main')} bsSize='sm'>
                                                    <option value="">Select Category</option>
                                                    {project_taxonomies && project_taxonomies.length > 0 && project_taxonomies.map(pt => {
                                                        return (
                                                            <option value={pt.id} key={pt.id}>{pt.category}</option>
                                                        )
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        {showProjectSubCategories && project_subcategories && project_subcategories.length > 0 && (
                                            <Col lg>
                                                <FormGroup className="px-1 align-self-end">
                                                    <Input className="primary" type="select" name="subCategory1"
                                                        onChange={(e) => this.handleOnChangeAddProject(e, 'sub')} bsSize='sm'>
                                                        <option value="">Select Sub-Category</option>
                                                        {project_subcategories && project_subcategories.length > 0 && project_subcategories.map(pt => {
                                                            return (
                                                                <option value={pt.id} key={pt.id}>{pt.category}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        )}
                                        {showProjectSubCategoriesLevel && project_subcategories_level && project_subcategories_level.length > 0 && (
                                            <Col lg>
                                                <FormGroup className="px-1 align-self-end">
                                                    <Input className="primary" type="select" name="subCategory2" onChange={(e) => this.handleOnChangeAddProject(e, 'sublevel')} bsSize='sm'>
                                                        <option value="">Select Sub-Category</option>
                                                        {project_subcategories_level && project_subcategories_level.length > 0 && project_subcategories_level.map(pt => {
                                                            return (
                                                                <option value={pt.id} key={pt.id}>{pt.category}</option>
                                                            )
                                                        })}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </CollapseBasic>
                        <hr className="bg-light my-2" />

                        <CollapseBasic title="Step 3. Add Project Details" noHr={true} containerClass="type-primary bg-transparent" isOpen={true} size="sm">
                            <Row>
                                <Col md={4}>
                                    <div className="text-tertiary fs-14 ff-alt">
                                        This is the display name the entire WikiReviews community will see when viewing your project. For best results, make it descriptive, as in "My First Music Video" or "Corporate Commercial Parody."
                                                            </div>
                                </Col>
                                <Col md={8}>
                                    <div className="text-darker font-weight-bold fs-14 mb-3">
                                        <Label>Project Title</Label>
                                        <Input className="primary" bsSize="sm" type="text" name="title" onChange={this.handleOnChangeAddProject} value={addProject.title} />
                                        {addProject.errors && addProject.errors.title && (
                                            <div className="text-danger small mt-1">
                                                {addProject.errors.title}
                                            </div>
                                        )}
                                    </div>
                                    <div className="text-darker font-weight-bold fs-14 mb-3">
                                        <Label>Upload Project Image</Label>
                                        <p className="text-dark font-weight-normal">
                                            Upload an image to represent your project. This could be album artwork, a book cover, artist profile, video-freeze frame, headshot... you get the idea (.jpg, .jpeg, .png).
                                            </p>
                                        {/* <Input
                                            type="file"
                                            name="upload_media_file"
                                            accept="image/*"
                                            id="project_image"
                                            onChange={this.handleOnUploadMedia('projectImages')}
                                            multiple
                                            style={{ display: 'none' }}
                                        /> */}
                                        <Label for="project_image" className="btn btn-royal-blue" role="button" onClick={() => this.handleOnClickUploadMedia('projectImages')}>
                                            Upload Media
                                        </Label>
                                    </div>
                                    <div>
                                        {/* Uploaded media shown here */}
                                        {
                                            uploadMedia.projectImages && uploadMedia.projectImages.selectedMedia && uploadMedia.projectImages.selectedMedia.length > 0
                                                ?

                                                <div className="border mb-3" >
                                                    <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                                        {uploadMedia.projectImages.selectedMedia.map(file => {
                                                            return (
                                                                <Col key={file.id}>
                                                                    <div className="d-flex pr-3 m-2">
                                                                        <div>
                                                                            {(file.media_type === 'image' || file.type === 'image') && (
                                                                                <img className="img-fluid img-thumbnail" src={file.url || file.file} alt={file.name || file.filename} />
                                                                            )}
                                                                            {(file.media_type === 'video' || file.type === 'video') && (
                                                                                <video width="100" height="100">
                                                                                    <source src={file.url || file.file} type="video/mp4" />
                                                                            Your browser does not support the video tag.
                                                                                </video>
                                                                            )}
                                                                        </div>
                                                                        <div className="mx-n3 mt-2">
                                                                            <Button color="delete-media" size="sm" title="Remove Media" onClick={() => this.handleOnClickRemoveSelectedGalleryMedia(file, 'projectImages')}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </div>
                                                : ''
                                        }
                                        {addProject.errors && addProject.errors.projectImages && (
                                            <div className="text-danger small mt-1">
                                                {addProject.errors.projectImages}
                                            </div>
                                        )}
                                    </div>
                                    <div className="text-darker font-weight-bold fs-14">
                                        <Label>Project Information</Label>
                                        <p className="text-dark font-weight-normal">
                                            Tell us more about your project. What is your inspiration for this work? What was the creative process like? What else do you want the community to know? Providing more context will allow community members to submit higher quality reviews.
                                            </p>
                                        <FormGroup className="main-post-formgroup">
                                            <div className="input-label-block">
                                                <Input className="primary" type="textarea" rows="4" name="info" onChange={this.handleOnChangeAddProject} value={addProject.info} />
                                                {addProject.errors && addProject.errors.info && (
                                                    <div className="text-danger small mt-1">
                                                        {addProject.errors.info}
                                                    </div>
                                                )}
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        </CollapseBasic>
                        <hr className="bg-light my-2" />

                        <CollapseBasic title="Step 4. Provide Items to Review" noHr={true} containerClass="type-primary bg-transparent" isOpen={true} size="sm">
                            <Row>
                                <Col md={4}>
                                    <div className="text-tertiary fs-14 ff-alt">
                                        Is this project for review a single item (e.g. a song, book, poem, or piece of artwork), or is it a comparison between two items (e.g. a before and after pictures, two different logos, or two different commercials)?
                                                            </div>
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col xs="auto">
                                            <div className="text-darker font-weight-bold fs-14">
                                                Single or Multiple
                                                                    </div>
                                        </Col>
                                        <Col>
                                            <FormGroup check>
                                                <Label className="text-dark fs-14" check>
                                                    <Input type="radio" name="mediaCount" value="single" onChange={this.handleOnChangeAddProject} checked={addProject.mediaCount === 'single'} />{' '}
                                                    Provide a single item for review
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label className="text-dark fs-14" check>
                                                    <Input type="radio" name="mediaCount" value="multiple" onChange={this.handleOnChangeAddProject} checked={addProject.mediaCount === 'multiple'} />{' '}
                                                    Provide multiple items for review
                                                </Label>
                                                {addProject.errors && addProject.errors.mediaCount && (
                                                    <div className="text-danger small mt-1">
                                                        {addProject.errors.mediaCount}
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr className="bg-light my-2" />
                                    <Label className="text-darker font-weight-bold d-block mb-2 fs-14">
                                        Upload your single item for review
                                        </Label>
                                    <p className="fs-14 text-dark">
                                        You can upload JPEG, PNG, GIF, and PDF files up to 5MB in size; embed a video using a link; or enter up to 10,000 characters of text.
                                        </p>
                                    <div>
                                        {/* Uploaded media shown here */}
                                        {
                                            uploadMedia.firstItem && uploadMedia.firstItem.selectedMedia && uploadMedia.firstItem.selectedMedia.length > 0
                                                ?

                                                <div className="border mb-3" >
                                                    <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                                        {uploadMedia.firstItem.selectedMedia.map(file => {
                                                            return (
                                                                <Col key={file.id}>
                                                                    <div className="d-flex pr-3 m-2">
                                                                        <div>
                                                                            {(file.media_type === 'image' || file.type === 'image') && (
                                                                                <img className="img-fluid img-thumbnail" src={file.url || file.file} alt={file.name || file.filename} />
                                                                            )}
                                                                            {(file.media_type === 'video' || file.type === 'video') && (
                                                                                <video width="100" height="100">
                                                                                    <source src={file.url || file.file} type="video/mp4" />
                                                                                Your browser does not support the video tag.
                                                                                </video>
                                                                            )}
                                                                        </div>
                                                                        <div className="mx-n3 mt-2">
                                                                            <Button color="delete-media" size="sm" title="Remove Media" onClick={() => this.handleOnClickRemoveSelectedGalleryMedia(file, 'firstItem')}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </div>
                                                :
                                                ''
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <Label className="text-darker font-weight-bold d-block mb-2">
                                            First Item:
                                                                </Label>
                                        <div className="mb-2">
                                            {addProject.errors && addProject.errors.firstItem && (
                                                <div className="text-danger small mt-1">
                                                    {addProject.errors.firstItem}
                                                </div>
                                            )}
                                            <div className="mb-2">
                                                {/* <Input
                                                    type="file"
                                                    name="upload_media_file"
                                                    id="first_item_media"
                                                    accept="image/*, video/*"
                                                    onChange={this.handleOnUploadMedia('firstItem')}
                                                    multiple
                                                    style={{ display: 'none' }}
                                                /> */}
                                                <Label for="first_item_media" className="btn btn-royal-blue" role="button" onClick={() => this.handleOnClickUploadMedia('firstItem')}>
                                                    Upload Media
                                                </Label>
                                            </div>
                                        </div>
                                        <Label className="text-darker font-weight-bold d-block mb-2 fs-14">
                                            Description for first item
                                                                </Label>
                                        <p className="text-dark fs-14">
                                            Enter a short description of the above item. This doesn't have to be long, but it should allow reviewers to easily identify the First Item in your project.
                                                                </p>
                                        <Input className="primary" bsSize="sm" type="textarea" rows="4" name="media_description" onChange={this.handleOnChangeAddProject} value={addProject.media_description} />
                                        {addProject.errors && addProject.errors.media_description && (
                                            <div className="text-danger small mt-1">
                                                {addProject.errors.media_description}
                                            </div>
                                        )}
                                    </div>
                                    {addProject.mediaCount === 'multiple' && (
                                        <div>
                                            <Label className="text-darker font-weight-bold d-block mb-2">
                                                Second Item:
                                        </Label>
                                            {/* Uploaded media shown here */}
                                            {
                                                uploadMedia.secondItem && uploadMedia.secondItem.selectedMedia && uploadMedia.secondItem.selectedMedia.length > 0
                                                    ?
                                                    <div className="border mb-3" >
                                                        <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                                            {uploadMedia.secondItem.selectedMedia.map(file => {
                                                                return (
                                                                    <Col key={file.id}>
                                                                        <div className="d-flex pr-3 m-2">
                                                                            <div>
                                                                                {(file.media_type === 'image' || file.type === 'image') && (
                                                                                    <img className="img-fluid img-thumbnail" src={file.url || file.file} alt={file.name || file.filename} />
                                                                                )}
                                                                                {(file.media_type === 'video' || file.type === 'video') && (
                                                                                    <video width="100" height="100">
                                                                                        <source src={file.url || file.file} type="video/mp4" />
                                                                                    Your browser does not support the video tag.
                                                                                    </video>
                                                                                )}
                                                                            </div>
                                                                            <div className="mx-n3 mt-2">
                                                                                <Button color="delete-media" size="sm" title="Remove Media" onClick={() => this.handleOnClickRemoveSelectedGalleryMedia(file, 'secondItem')}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                            <div className="font-weight-bold text-secondary-dark fs-14 mb-2">
                                                To replace the second CRP image, use the Upload Media button below.
                                        </div>
                                            {addProject.errors && addProject.errors.secondItem && (
                                                <div className="text-danger small mt-1">
                                                    {addProject.errors.secondItem}
                                                </div>
                                            )}
                                            <div className="mb-2">
                                                {/* <Input
                                                type="file"
                                                name="upload_media_file"
                                                id="second_item_media"
                                                accept="image/*, video/*"
                                                onChange={this.handleOnUploadMedia('secondItem')}
                                                multiple
                                                style={{ display: 'none' }}
                                            /> */}
                                                <Label for="second_item_media" className="btn btn-royal-blue" role="button" onClick={() => this.handleOnClickUploadMedia('secondItem')}>
                                                    Upload Media
                                            </Label>
                                            </div>
                                            <Label className="text-darker font-weight-bold d-block mb-2 fs-14">
                                                Description for Second item
                                                                </Label>
                                            <p className="text-dark fs-14">
                                                Enter a short description of the above item. This doesn't have to be long, but it should allow reviewers to easily identify the First Item in your project.
                                                                </p>
                                            <Input className="primary" bsSize="sm" type="textarea" rows="4" name="media_description_second" onChange={this.handleOnChangeAddProject} value={addProject.media_description_second} />
                                            {addProject.errors && addProject.errors.media_description_second && (
                                                <div className="text-danger small mt-1">
                                                    {addProject.errors.media_description_second}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CollapseBasic>
                        <hr className="bg-light my-2" />

                        <CollapseBasic title="Step 5. Select Questions for Reviewers" noHr={true} containerClass="type-primary bg-transparent" isOpen={true} size="sm">
                            <Row>
                                <Col md={4}>
                                    <div className="text-tertiary fs-14 ff-alt">
                                        Time for feedback! Select questions about your project you wish to have answered by the WikiReviews community.
                                                            </div>
                                </Col>
                                <Col md={8}>
                                    <div className="mb-3">
                                        <Label className="text-darker font-weight-bold fs-14">Main question<br /><span hidden={((this.state.projectQuestions && this.state.projectQuestions.length > 0) || !addProject.selectedOwnMainDisabled) ? true : false}>Please select category/subcategory from Step 2 above to get pre-defined questions</span></Label>
                                        <Input className="category-select" bsSize="sm" type="select" name="mainQuestion" onChange={this.handleOnChangeAddProject} value={addProject.mainQuestion ? addProject.mainQuestion.toString() : ''} disabled={!addProject.selectedOwnMainDisabled}>
                                            {this.renderQuestions('mq')}
                                        </Input>
                                    </div>
                                    <div className="mb-3">
                                        <Label className="text-dark font-weight-bold fs-14" role="button" onClick={() => this.setState({
                                            addProject: {
                                                ...this.state.addProject,
                                                mainQuestion: '',
                                                selectedOwnMainDisabled: false,
                                                selectedOwnMain: ''
                                            }
                                        })}>Write your own</Label>
                                        <div hidden={addProject.selectedOwnMainDisabled}>
                                            <div className="d-flex" >
                                                <Input bsSize="sm" type="text" className="mr-2 ph-normal border-grey text-darker font-weight-bold" name="selectedOwnMain" placeholder="Ask your own question" onChange={this.handleOnChangeAddProject} value={addProject.selectedOwnMain} />

                                                <Button size="sm" color="tertiary" className="mw" onClick={() => this.setState({
                                                    addProject: {
                                                        ...this.state.addProject,
                                                        mainQuestion: '',
                                                        selectedOwnMainDisabled: true,
                                                        selectedOwnMain: ''
                                                    }
                                                })}>Cancel</Button>
                                            </div>
                                        </div>
                                    </div>
                                    {addProject.errors && addProject.errors.selectedOwnMain && (
                                        <div className="text-danger small mt-1">
                                            {addProject.errors.selectedOwnMain}
                                        </div>
                                    )}
                                    <hr className="bg-light my-2" />

                                    <div className="text-dark fs-14 mb-2">
                                        Ask up to 5 additional questions using the Likert Scale shown below:
                                                            </div>

                                    <div className="text-center d-flex">
                                        <FormGroup check inline>
                                            <Label className="fs-14 text-dark" check>
                                                <Input type="radio" name="personality" value="ds" onChange={this.handleOnChangeAddProject} disabled={true} />
                                                <br />
                                                Disagree Strongly
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label className="fs-14 text-dark" check>
                                                <Input type="radio" name="personality" value="ds" onChange={this.handleOnChangeAddProject} disabled={true} /> <br />
                                                Disagree moderately
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label className="fs-14 text-dark" check>
                                                <Input type="radio" name="personality" value="ds" onChange={this.handleOnChangeAddProject} disabled={true} />
                                                <br />
                                                                        Disagree a little
                                                                    </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label className="fs-14 text-dark" check>
                                                <Input type="radio" name="personality" value="ds" onChange={this.handleOnChangeAddProject} disabled={true} /> <br />
                                                                        Agree a little
                                                                    </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label className="fs-14 text-dark" check>
                                                <Input type="radio" name="personality" value="ds" onChange={this.handleOnChangeAddProject} disabled={true} />
                                                <br />
                                                Agree moderately
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label className="fs-14 text-dark" check>
                                                <Input type="radio" name="personality" value="ds" onChange={this.handleOnChangeAddProject} disabled={true} /> <br />
                                                Agree Strongly
                                            </Label>
                                        </FormGroup>
                                    </div>

                                    {addProject.crpType === 'expanded' && (
                                        <div>
                                            <hr className="bg-light" />
                                            <div>
                                                <Label className="text-dark font-weight-normal fs-14">Additional question #1</Label>
                                                <Input className="primary" type="select" name="additionalQuestion-1" onChange={this.handleOnChangeAddProject} value={addProject.additionalQuestions['1'] ? addProject.additionalQuestions['1'].toString() : ''} bsSize="sm">
                                                    {this.renderQuestions('1')}
                                                </Input>
                                            </div>

                                            {addProject.additionalQuestionsCount.length > 1 && addProject.additionalQuestionsCount.filter(item => item !== '1').map(item => (
                                                <>
                                                    <hr className="bg-light" />
                                                    <div>
                                                        <Label className="text-dark font-weight-normal fs-14">Additional question #{item}</Label>
                                                        <Input className="primary" type="select" name={`additionalQuestion-${item}`}
                                                            value={addProject.additionalQuestions[item] ? addProject.additionalQuestions[item].toString() : ''}
                                                            onChange={this.handleOnChangeAddProject} bsSize="sm">
                                                            {this.renderQuestions(`${item}`)}
                                                        </Input>
                                                    </div>
                                                </>
                                            ))}

                                            <div className="text-right" hidden={addProject.additionalQuestionsCount.length === 5}>
                                                <span className="text-dark font-weight-bold fs-14" role="button" onClick={() => this.handleOnClickAddAnotherQuestion()}>
                                                    <FontAwesomeIcon icon="plus" className="mr-2" />
                                                    add another rit
                                                </span>
                                            </div>
                                            <br />
                                            <FormGroup>
                                                <Label className="text-dark font-weight-bold fs-14">Open-ended Questions</Label>
                                                <FormGroup className="mb-2" check>
                                                    <Label className="font-weight-normal fs-14 text-dark" check>
                                                        <Input type="checkbox" name="openEndedQuestionDisabled" onChange={this.handleOnChangeAddProject} checked={!addProject.openEndedQuestionDisabled} />{' '}
                                                        Ask up to 3 additional questions for open-ended feedback.
                                                    </Label>
                                                </FormGroup>
                                                <Input type="text" bsSize="sm" placeholder="Open-ended Question 1" name="openEndedQuestion" onChange={this.handleOnChangeAddProject} hidden={addProject.openEndedQuestionDisabled} value={addProject.openEndedQuestion} />
                                            </FormGroup>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CollapseBasic>

                        {addProject.crpType === 'expanded' && (
                            <div>
                                <hr className="bg-light my-2" />

                                <CollapseBasic title="Step 6. Choose a confidence level" noHr={true} containerClass="type-primary bg-transparent" isOpen={true} size="sm">
                                    <div className="fs-14 text-darker">
                                        <div className="bg-light p-2 mb-2">
                                            <FormGroup check>
                                                <Label className="font-weight-bold" check>
                                                    <Input type="radio" name="confidenceLevel" checked={addProject.confidenceLevel === '90'} value="90" onChange={this.handleOnChangeAddProject} />
                                            90% Confidence / 10% Margin of Error
                                        </Label>
                                            </FormGroup>
                                            <p className="mb-0 pl-4">
                                                Projects usually done quickly. Needs approx. 100 reviews. Recommended for before and after, Logo, and Website Comparisons.
                                            </p>
                                        </div>
                                        <div className="bg-light p-2 mb-2">
                                            <FormGroup check>
                                                <Label className="font-weight-bold" check>
                                                    <Input type="radio" name="confidenceLevel" value="95" onChange={this.handleOnChangeAddProject} checked={addProject.confidenceLevel === '95'} />
                                                    95% Confidence / 5% Margin of Error
                                                </Label>
                                            </FormGroup>
                                            <p className="mb-0 pl-4">
                                                Projects that take a little longer. Needs approx. 150 reviews. Recommended for Music, Videos, and anything else more general in nature.
                                            </p>
                                        </div>
                                        <div className="bg-light p-2 mb-2">
                                            <FormGroup check>
                                                <Label className="font-weight-bold" check>
                                                    <Input type="radio" name="confidenceLevel" value="99" onChange={this.handleOnChangeAddProject} checked={addProject.confidenceLevel === '99'} />
                                                    99% Confidence / 1% Margin of Error
                                        </Label>
                                            </FormGroup>
                                            <p className="mb-0 pl-4">
                                                Projects time is the longest available. Needs approx. 200 reviews. Recommended for projects that are unsusual or very detailed in nature.
                                    </p>
                                        </div>
                                    </div>
                                </CollapseBasic>
                            </div>
                        )}

                        {addProject.crpType === 'expanded' && (
                            <div>
                                <hr className="bg-light my-2" />

                                <CollapseBasic title="Step 7. Geographic and Demographic Segmentation (Optional)" noHr={true} containerClass="type-primary bg-transparent" isOpen={true} size="sm">
                                    <div className="fs-14 text-dark mt-3">
                                        <FormGroup row>
                                            <Col sm="3">
                                                <Label className="text-darker font-weight-bold">Geographic</Label>
                                            </Col>
                                            <Col sm={{ size: 9 }}>
                                                <FormGroup check>
                                                    <Label className="text-dark font-weight-normal" check>
                                                        <Input type="checkbox" name="isGeographic" checked={addProject.isGeographic} value={addProject.isGeographic} onClick={this.handleOnClickAddProjectCheckbox(0, 0)} />{' '}
                                                Target this project to be reviewed by certain region(s) in the country.
                                            </Label>
                                                </FormGroup>

                                                <div className="mt-2" hidden={!addProject.isGeographic}>
                                                    <FormGroup>
                                                        <Input type="select" name="country" bsSize="sm" defaultValue="usa" onChange={this.handleOnClickAddProjectCheckbox(0, 0)}>
                                                            <option value="">Select Country</option>
                                                            <option value="usa">USA</option>
                                                            <option value="canada">Canada</option>
                                                        </Input>
                                                    </FormGroup>

                                                    <div hidden={addProject.country !== 'usa'}>
                                                        <div className="text-darker font-weight-bold">
                                                            USA:
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="font-weight-bold text-tertiary">
                                                                Northeast
                                                            </div>
                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28422'].includes('28430')} onClick={this.handleOnClickAddProjectCheckbox('28422', '28430')} />{' '}
                                                                    New England (Maine, New Hampshire, Vermont, Massachusetts, Rhode Island, Connecticut)
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28422'].includes('28431')} onClick={this.handleOnClickAddProjectCheckbox('28422', '28431')} />{' '}
                                                            Mid-Atlantic (New York, Pennsylvania, New Jersey)
                                                        </Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="font-weight-bold text-tertiary">
                                                                Midwest
                                                            </div>
                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28423'].includes('28432')} onClick={this.handleOnClickAddProjectCheckbox('28423', '28432')} />{' '}
                                                                    East North Central (Wisconsin, Michigan, Illinois, Indiana, Ohio)
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28423'].includes('28433')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28423', '28433')} />{' '}
                                                                    West North Central (Missouri, North Dakota, South Dakota, Nebraska, Kansas, Minnesota, Iowa)
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="font-weight-bold text-tertiary">
                                                                South
                                                            </div>
                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28424'].includes('28434')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28424', '28434')} />{' '}
                                                                    South Atlantic (Delaware, Maryland, District of Columbia, Virginia, West Virginia, North Carolina, South Carolina, Georgia, Florida)
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28424'].includes('28435')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28424', '28435')} />{' '}
                                                                    East South Central (Kentucky, Tennessee, Mississippi, Alabama)
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28424'].includes('28436')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28424', '28436')} />{' '}
                                                                    West South Central (Oklahoma, Texas, Arkansas, Louisiana)
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="font-weight-bold text-tertiary">
                                                                West
                                                            </div>
                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28425'].includes('28437')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28425', '28437')} />{' '}
                                                                    Mountain (Idaho, Montana, Wyoming, Nevada, Utah, Colorado, Arizona, New Mexico)
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28425'].includes('28438')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28425', '28438')} />{' '}
                                                                    Pacific (Alaska, Washington, Oregon, California,Hawaii)
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                    </div>

                                                    <div hidden={addProject.country !== 'canada'}>
                                                        <div className="text-darker font-weight-bold">
                                                            Canada:
                                                        </div>
                                                        <div className="mb-2">
                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28421'].includes('28426')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28421', '28426')} />{' '}
                                                                    Western Canada (Alberta, British Columbia, Saskatchewan, Manitoba)
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28421'].includes('28427')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28421', '28427')} />{' '}
                                                                    Central Canada (Quebec, Ontario)
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28421'].includes('28428')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28421', '28428')} />{' '}
                                                                    Atlantic Canada (New Brunswick, Prince Edward Island, Nova Scotia, Newfoundland and Labrador)
                                                                </Label>
                                                            </FormGroup>

                                                            <FormGroup check>
                                                                <Label className="font-weight-normal" check>
                                                                    <Input type="checkbox" checked={addProject.characterstics['28421'].includes('28429')}
                                                                        onClick={this.handleOnClickAddProjectCheckbox('28421', '28429')} />{' '}
                                                                    Northern Canada (Northwest Territories, Nunavut, Yukon)
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                        <hr className="bg-light" />
                                        <FormGroup row>
                                            <Col sm="3">
                                                <Label className="text-darker font-weight-bold">Demographic</Label>
                                            </Col>
                                            <Col sm={{ size: 9 }}>
                                                <FormGroup check>
                                                    <Label className="text-dark font-weight-normal" check>
                                                        <Input type="checkbox" name="isDemographic" checked={addProject.isDemographic} value={addProject.isDemographic} onClick={this.handleOnClickAddProjectCheckbox(0, 0)} />{' '}
                                                        Target this project to be reviewed by certain demographics based on factors like age, income, education, ethnicity, emplyment status, or employment category.
                                                    </Label>
                                                </FormGroup>

                                                <div className="mt-2" hidden={!addProject.isDemographic}>
                                                    <div className="mb-3">
                                                        <div className="text-darker font-weight-bold mb-2">
                                                            Age Group
                                                            </div>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['21'].includes('27')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('21', '27')} /> 18-24
                                                                </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['21'].includes('28')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('21', '28')} /> 25-30
                                                    </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['21'].includes('29')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('21', '29')} /> 31-35
                                                                </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['21'].includes('30')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('21', '30')} /> 36-40
                                                                </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['21'].includes('31')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('21', '31')} /> 41-45
                                                                </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['21'].includes('28416')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('21', '28416')} /> 50+
                                                                </Label>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="text-darker font-weight-bold mb-2">
                                                            Education
                                                        </div>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['22'].includes('32')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('22', '32')} />  High School
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['22'].includes('33')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('22', '33')} />  Trade School
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['22'].includes('34')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('22', '34')} /> Associate
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['22'].includes('35')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('22', '35')} /> Bachelors
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['22'].includes('36')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('22', '36')} />  Post-graduate
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="text-darker font-weight-bold mb-2">
                                                            Ethnicity
                                                        </div>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('37')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '37')} />  White
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('38')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '38')} />  Black, African American, Negro
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('39')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '39')} /> Asian Indian
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('40')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '40')} /> Japanese
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('41')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '41')} /> Pacific Islander
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('42')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '42')} /> Chinese
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('43')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '43')} />  Guamanian or Chamorro
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('44')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '44')} /> Filipino
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('45')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '45')} /> Vietnamese
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('46')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '46')} />  Hispanic, Mexican American, Chicano
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['23'].includes('47')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('23', '47')} />  Atlantic Islander (Cubans, Haitians, Puerto Ricans, Barbaians)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="text-darker font-weight-bold mb-2">
                                                            Personal Income
                                                        </div>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['24'].includes('48')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('24', '48')} /> Very Low (&lt;12k)
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['24'].includes('49')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('24', '49')} />  Low
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['24'].includes('50')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('24', '50')} />  Slightly Low
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['24'].includes('51')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('24', '51')} /> Average
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['24'].includes('52')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('24', '52')} /> Slightly High
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['24'].includes('53')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('24', '53')} /> High
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['24'].includes('54')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('24', '54')} /> Very High (&gt;125k)
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="text-darker font-weight-bold mb-2">
                                                            Employment Status
                                                        </div>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['25'].includes('55')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('25', '55')} /> Fully Employed
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['25'].includes('56')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('25', '56')} /> Partially Employed
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['25'].includes('57')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('25', '57')} /> Unemployed
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="text-darker font-weight-bold mb-2">
                                                            Business Category
                                                        </div>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('58')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '58')} />  Arts &amp; Entertainment
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('59')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '59')} />  Home &amp; Garden
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('60')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '60')} /> Industry &amp; Agriculture
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('61')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '61')} />  Business &amp; Professional Services
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('62')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '62')} /> Legal &amp; Financial
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('63')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '63')} />  Clothing &amp; Accessories
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('64')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '64')} />  Community &amp; Government
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('65')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '65')} />  Personal Care &amp; Services
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('66')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '66')} /> Computer &amp; Electronics
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('67')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '67')} /> Real Estate
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('68')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '68')} /> Construction &amp; Contractors
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('69')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '69')} /> Shopping
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('70')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '70')} /> Education
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('71')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '71')} /> Sports &amp; Recreation
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('72')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '72')} /> Food &amp; Dining
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['26'].includes('73')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('26', '73')} /> Travel &amp; Transportation
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="text-darker font-weight-bold mb-2">
                                                            Gender
                                                        </div>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['29181'].includes('29182')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('29181', '29182')} /> Male
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup check inline>
                                                            <Label className="font-weight-normal" check>
                                                                <Input type="checkbox" checked={addProject.characterstics['29181'].includes('29183')}
                                                                    onClick={this.handleOnClickAddProjectCheckbox('29181', '29183')} /> Female
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </Col>
                                        </FormGroup>

                                        <div className="text-darker bg-light p-3 ff-alt">
                                            <span className="font-weight-bold">Note: </span>
                                                We will not sell, pirate, license, or reproduce your uploaded items for any purpose. Uploaded projects are available solely to the WikiReviews community for the purpose of acquiring targeted feedback for you, the uploader.
                                            <br />
                                            <span className="font-weight-bold">
                                                You must be an Authorized Representative of the submitted files and the work or persons presented within those files.
                                            </span>
                                        </div>
                                    </div>
                                </CollapseBasic>
                            </div>
                        )}
                        <hr className="bg-light my-2" />

                        <div className="py-3">
                            <div className="d-flex mx-n2">
                                <div className="px-2">
                                    <Button color="transparent text-tertiary" onClick={() => {
                                        this.handleOnClickCancelAddProject()
                                        this.props.toggleCommunityModal()
                                    }}>
                                        Cancel
                                            </Button>
                                </div>
                                <div className="px-2 ml-auto">
                                    <Button color="tertiary reset" onClick={() => this.handleOnSubmitAddProject('draft')}>
                                        Save Draft &amp; Preview
                                    </Button>
                                    <Button color="primary" onClick={() => this.handleOnSubmitAddProject('submit')}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </ModalBody>
                </Modal>

                {/* Save Draft & Preview Modal */}
                {projectInfo && myProfileInfo && (
                    <Modal size="xl" isOpen={this.state.saveDraftPreviewModalToggle} toggle={() => this.setState({ saveDraftPreviewModalToggle: !this.state.saveDraftPreviewModalToggle })}>
                        <ModalHeader toggle={() => this.setState({ saveDraftPreviewModalToggle: !this.state.saveDraftPreviewModalToggle })}>Project Preview </ModalHeader>
                        <ModalBody className="px-0 pb-0 pt-5">
                            <Row noGutters>
                                <Col md={4}>
                                    <div className="text-center h-100 bg-dark text-white p-4">
                                        <div className="mb-2">
                                            <img className="img-circle _150X150" src={myProfileInfo.current_profile_file ? myProfileInfo.current_profile_file : ''} alt="" />
                                        </div>
                                        <h1>
                                            {projectInfo.created_by.user.first_name} {projectInfo.created_by.user.last_name}
                                        </h1>
                                        <p>
                                            joined <Moment parse="MMMM YYYY">
                                                {projectInfo.created_by.joining_date}
                                            </Moment>
                                        </p>
                                        <div>
                                            <span>
                                                <strong>{projectInfo.project_count} Projects</strong> added
                                        </span>
                                        </div>
                                        <div className="mt-3">
                                            <span>
                                                <strong>{projectInfo.reviewed_count} Projects</strong> reviewed
                                        </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className="h-100 text-white fs-14 p-4" style={{ backgroundColor: 'rgba(0, 22, 43, 0.8)' }}>
                                        <Row>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <span className="font-weight-bold">
                                                        {projectInfo.title}
                                                    </span>
                                                </div>
                                                <div className="mb-3">
                                                    <span>Overall Rating:</span>
                                                    <br />
                                                    <img title={projectInfo.average_rating[1]} src={projectInfo.average_rating[1] !== 'No Rating' ? require(`../../../assets/images/icons/${Math.floor(projectInfo.average_rating[1])}rating.png`) : require('../../../assets/images/icons/norating.png')} alt="" />
                                                </div>
                                                <p className="ff-base">
                                                    Uploaded by
                                                <br />
                                                    <span className="font-weight-bold">{projectInfo.created_by.user.first_name}</span> just now
                                            </p>
                                                <p className="ff-base">
                                                    Categories:
                                                <br />
                                                    {projectInfo.taxonomy_tree && projectInfo.taxonomy_tree.length > 0 && projectInfo.taxonomy_tree.map((item, index) => {
                                                        if (projectInfo.taxonomy_tree.length > 1 && index < (projectInfo.taxonomy_tree.length - 1)) {

                                                            return (
                                                                <span className="font-weight-bold">{item}, </span>
                                                            )
                                                        } else {
                                                            return (
                                                                <span className="font-weight-bold">{item}</span>
                                                            )
                                                        }

                                                    })}

                                                </p>
                                                <div>
                                                    <span>{projectInfo.project_response_count} responses</span>
                                                </div>
                                            </Col>

                                            {projectInfo.main_images && projectInfo.main_images.length > 0 && (
                                                <Col lg>
                                                    <img className="fixed-height-img is-200" src={projectInfo.main_images && projectInfo.main_images[0] && projectInfo.main_images[0].type === 'image' ? projectInfo.main_images[0].type.url : projectInfo.main_images[0].thumbnail}
                                                        alt="" />
                                                </Col>
                                            )}

                                            {projectInfo.first_item_images && projectInfo.first_item_images.length > 0 && (
                                                <Col lg>
                                                    <img className="fixed-height-img is-200" src={projectInfo.first_item_images && projectInfo.first_item_images[0] && projectInfo.first_item_images[0].type === 'image' ? projectInfo.first_item_images[0].type.url : projectInfo.first_item_images[0].thumbnail}
                                                        alt="" />
                                                </Col>
                                            )}

                                            {projectInfo.second_item_images && projectInfo.second_item_images.length > 0 && (
                                                <Col lg>
                                                    <img className="fixed-height-img is-200" src={projectInfo.second_item_images && projectInfo.second_item_images[0] && projectInfo.second_item_images[0].type === 'image' ? projectInfo.second_item_images[0].type.url : projectInfo.second_item_images[0].thumbnail}
                                                        alt="" />
                                                </Col>
                                            )}

                                        </Row>
                                        {/* <div className="text-right">
                                            <Button color="link" className="px-0 text-reset" onClick={() => this.setState({ viewProjectImagesModal: !this.state.viewProjectImagesModal })}>View All</Button>
                                        </div> */}
                                    </div>
                                </Col>
                            </Row>

                            <div className="bg-white p-4">
                                <Row>
                                    <Col md={4}>
                                        <CollapseBasic title="Project Details" noHr={true} bodyClass="text-dark fs-14" containerClass="type-dark bg-transparent" isOpen={true} size="sm">
                                            <div className="mb-3">
                                                <div className="font-weight-bold mb-1">
                                                    Project Information:
                                                </div>
                                                <div>
                                                    {projectInfo.info}
                                                </div>
                                            </div>
                                            {projectInfo.first_item_images && projectInfo.first_item_images.length > 0 && projectInfo.first_item_images[0].description && (
                                                <div className="mb-3">
                                                    <div className="font-weight-bold mb-1">
                                                        First Image Description:
                                                    </div>
                                                    <div>
                                                        {projectInfo.first_item_images[0].description}
                                                    </div>
                                                </div>
                                            )}

                                            {projectInfo.second_item_images && projectInfo.second_item_images.length > 0 && projectInfo.second_item_images[0].description && (
                                                <div className="mb-3">
                                                    <div className="font-weight-bold mb-1">
                                                        Second Image Description:
                                                    </div>
                                                    <div>
                                                        {projectInfo.second_item_images[0].description}
                                                    </div>
                                                </div>
                                            )}

                                        </CollapseBasic>
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                    </Modal>
                )}

                {/* Select Project to copy from Modal */}
                <Modal size="lg" isOpen={this.state.selectProjectToCopyModalToggle} toggle={() => this.setState({
                    selectProjectToCopyModalToggle: !this.state.selectProjectToCopyModalToggle
                })}>
                    <ModalHeader toggle={() => this.setState({
                        selectProjectToCopyModalToggle: !this.state.selectProjectToCopyModalToggle
                    })}>
                        Select Project
                    </ModalHeader>
                    <ModalBody>
                        <Row xs={2} md={3} lg={4} form>
                            {
                                communityReviewProjects && communityReviewProjects.results && communityReviewProjects.results.length > 0 ? communityReviewProjects.results.map(
                                    item => {
                                        return (
                                            <Col key={item.id}>
                                                <div className="p-2 fs-14 text-white mb-2" style={{ backgroundColor: 'rgb(50, 82, 113)' }}>
                                                    <div className="mb-2 text-center">
                                                        <img className="fixed-height-img w-100 object-fit-cover" src={item.main_images && item.main_images[0] && item.main_images[0]?.type === 'image' ? item.main_images[0]?.url : item.main_images[0]?.thumbnail} alt="Project Image"
                                                        />
                                                    </div>
                                                    <div className="mb-2">
                                                        <a href="#" className="text-reset">{item.title}</a>
                                                    </div>
                                                    <div>
                                                        <img title={item.average_rating[1]} src={item.average_rating[1] !== 'No Rating' ? require(`../../../assets/images/icons/${Math.floor(item.average_rating[1])}rating.png`) : require('../../../assets/images/icons/norating.png')} alt="" />
                                                    </div>
                                                    <div>
                                                        {item.project_response_count} Responses
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <Button size="sm" color="primary" onClick={() => this.handleOnClickCopyFromProject(item)}>Select</Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }
                                ) : (
                                        <div className="bg-white p-3">
                                            <h2 className="text-secondary-dark">No projects to display</h2>
                                        </div>
                                    )
                            }
                        </Row>
                    </ModalBody>
                </Modal>

                <Modal size="lg" isOpen={this.state.kickstartReviewModalToggle}
                    toggle={() => this.setState({
                        kickstartReviewModalToggle: !this.state.kickstartReviewModalToggle
                    })}>
                    <div className="d-flex mx-n3">
                        <div className="px-2 flex-grow-1">
                            <ModalHeader className="p-0" toggle={() => this.setState({
                                kickstartReviewModalToggle: !this.state.kickstartReviewModalToggle
                            })}>
                                Congratulations!
                            </ModalHeader>
                            <ModalBody className="text-tertiary fs-14">
                                <p className="ff-base">
                                    You can check on your project any time by clicking the <a href={`/myprofile?active_tab=crp`} className="text-royal-blue font-weight-bold">My Projects</a> link in the My Places menu. There you can see the WikiReviews community's detailed reviews of your project.
                                </p>
                                <hr />

                                <div className="border-top border-tertiary pt-3">
                                    <div className="text-primary font-weight-bold fs-16 mb-3">
                                        Step 8. Kickstart the Review Process <span className="text-tertiary font-weight-normal">(Optional)</span>
                                    </div>
                                    <p className="ff-base">
                                        Kickstart the review process by asking your friends to help and review your project. Choose some or all of the options below:
                                    </p>
                                    <div className="border-top pt-3 mb-3">
                                        <p className="ff-base">
                                            Post to the below websites and ask if my friends would like to help me in reviewing my project.
                                        </p>
                                        <div className="d-flex mx-n3 text-dark">
                                            <div className="px-3 flex-150">
                                                <span className="font-weight-bold">
                                                    Social Networks
                                                </span>
                                            </div>
                                            <div className="px-3 flex-grow-1">
                                                <FormGroup className="mb-2" check>
                                                    <Label check className="text-dark font-weight-normal fs-14">
                                                        <Input type="checkbox" checked={this.state.postToFacebook} value={this.state.postToFacebook} onChange={() => this.setState({
                                                            postToFacebook: !this.state.postToFacebook
                                                        })} />{' '}
                                                        Post to Facebook
                                                    </Label>
                                                </FormGroup>
                                                {/* <FormGroup className="mb-2" check>
                                                    <Label check className="text-dark font-weight-normal fs-14">
                                                        <Input type="checkbox" />{' '}
                                                        Post to Google+
                                                    </Label>
                                                </FormGroup> */}
                                                <FormGroup className="mb-2" check>
                                                    <Label check className="text-dark font-weight-normal fs-14">
                                                        <Input type="checkbox" checked={this.state.postToTwitter} value={this.state.postToTwitter} onChange={() => this.setState({
                                                            postToTwitter: !this.state.postToTwitter
                                                        })} />{' '}
                                                        Post to Twitter
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-top pt-3">
                                        <div className="d-flex mx-n3 text-dark">
                                            <div className="px-3 flex-150">
                                                <span className="font-weight-bold">
                                                    WikiReviews Friends
                                        </span>
                                            </div>
                                            <div className="px-3 flex-grow-1">
                                                <FormGroup className="mb-2" check>
                                                    <Label check className="text-dark font-weight-normal fs-14">
                                                        <Input type="radio" name="friendsStatus" value='all' onChange={() => this.setState({
                                                            friendsStatus: 'all',
                                                            selectedFriends: [],
                                                            searchFriend: '',
                                                            showSearchFriendList: false
                                                        })} checked={this.state.friendsStatus === 'all'} />{' '}
                                                        Ask ALL of my WikiReviews friends to review my project.
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-2" check>
                                                    <Label check className="text-dark font-weight-normal fs-14">
                                                        <Input type="radio" name="friendsStatus" value='specific' onChange={() => this.setState({
                                                            friendsStatus: 'specific'
                                                        })} checked={this.state.friendsStatus === 'specific'} />{' '}
                                                        Ask specific WikiReviews friends to review my project.
                                                    </Label>
                                                </FormGroup>
                                                {/* Share with specific people here */}
                                                <div hidden={this.state.friendsStatus === 'all'}>
                                                    <Input type="text" bsSize="sm" className="text-dark font-weight-bold ph-normal border" placeholder="Start Typing a Friend's Name" value={this.state.searchFriend} style={{ width: '216px' }} onChange={this.searchFriendsForReview} />

                                                    {/* Instant Search Results */}
                                                    {
                                                        this.state.showSearchFriendList && this.state.searchFriend && this.props.friends?.results?.filter(item => item.id !== this.state.myProfileInfo?.id).length > 0 ?
                                                            <ul
                                                                className="list-unstyled text-dark instant-results bg-white w-100 shadow"
                                                                style={{
                                                                    maxHeight: "201px",
                                                                    maxWidth: "300px",
                                                                    overflowY: "auto",
                                                                }}
                                                            >
                                                                {this.props.friends?.results.filter(item => item.id !== this.state.myProfileInfo?.id).map(item => (
                                                                    <li
                                                                        onClick={() => this.handleSelectFriends(item, 'add')}
                                                                        className="result-item"
                                                                        role="button"
                                                                        key={item.id}
                                                                    >
                                                                        <div className="result-link fs-16">
                                                                            <Media>
                                                                                <Media
                                                                                    object
                                                                                    className="img-circle _50x50"
                                                                                    src={item.profile_pic}
                                                                                    alt="User"
                                                                                />
                                                                                <Media className="p-2" body>
                                                                                    {item.first_name} {item.last_name}
                                                                                </Media>
                                                                            </Media>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            :
                                                            ''
                                                    }

                                                    {/* Selected People List */}
                                                    {this.state.selectedFriends.length > 0 && (
                                                        <div className="mt-3">
                                                            {/* Repeat this */}
                                                            {this.state.selectedFriends.map(el => (
                                                                <div className="mb-2" key={el.id}>
                                                                    <div className="d-flex mx-n1">
                                                                        <div className="px-1">
                                                                            <a href="/#">
                                                                                <img className="img-circle _50x50" src={el.profile_pic} alt="User" />
                                                                            </a>
                                                                        </div>
                                                                        <div className="px-1">
                                                                            <div className="fs-13 text-dark mt-2">
                                                                                <a href="/#" className="text-reset text-decoration-none font-weight-bold mr-2">{el.first_name} {el.last_name}</a>
                                                                                <Button
                                                                                    onClick={() => this.handleSelectFriends(el, 'remove')}
                                                                                    color="link p-0 fs-13 text-tertiary">X Remove</Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Link to={`/projects/${this.props.project_data?.id}`} className="btn btn-primary">kickstart</Link>
                            </ModalFooter>
                        </div>
                        <div className="px-2 flex-320">
                            <div className="bg-greyish text-dark fs-14 p-2" style={{ marginTop: '54px' }}>
                                <div className="d-flex mb-2">
                                    <div className="font-weight-bold flex-grow-1">
                                        Tips For a Successful Project
                                    </div>
                                    <button type="button" class="close modal-type-close _dark" aria-label="Close">
                                        {/* <span aria-hidden="true">×</span> */}
                                    </button>
                                </div>
                                <div>
                                    <p className="ff-base"><strong>Tip 1.</strong> Choose the correct and most suited questions related to your CRP. You have the option to ask up to 6 questions and 3 open ended questions for your CRP, so make them count! </p>
                                    <p className="ff-base"><strong>Tip 2.</strong> Include as much relevant information as possible for your CRP, including images, videos, and descriptions! </p>
                                    <p className="ff-base"><strong>Tip 3.</strong> Use the correct confidence level for your project. If it is a more intricate project, choose a higher confidence level for more ratings and a smaller margin of error.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* Upload Media Post Modal */}
                <Modal
                    isOpen={this.state.uploadMediaPostModal}
                    toggle={this.uploadMediaModalToggle}
                >
                    <ModalHeader toggle={this.uploadMediaModalToggle}>
                        UPLOAD MEDIA
          </ModalHeader>
                    <ModalBody className="p-3">
                        <Row>
                            <Col xs={"auto"}>
                                <div className="bg-light h-100 p-3">
                                    <Nav tabs className="flex-column active-transparent border-0 m-0">
                                        <NavItem className="text-left">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "upload"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("upload");
                                                }}
                                            >
                                                Upload
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "gallery"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("gallery");
                                                }}
                                            >
                                                Gallery
                      </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Col>
                            <Col>
                                <TabContent activeTab={this.state.selectedUpMediaType}>
                                    <TabPane tabId="upload">
                                        <div
                                            className="mb-3 type-file-block bg-light"
                                            hidden={
                                                this.state.uploadMedia[uploadMediaModalType].showFiles &&
                                                    this.state.uploadMedia[uploadMediaModalType].showFiles.length > 0
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <Input
                                                type="file"
                                                name="upload_media_file"
                                                id="uploadFilesPostFromSystem"
                                                accept="image/*, video/*"
                                                onChange={this.handleOnFilePostUploadChange}
                                                multiple
                                            />
                                            <Label for="uploadFilesPostFromSystem">
                                                <div className="type-bg"></div>
                                            </Label>
                                        </div>
                                        {this.state.uploadMedia[uploadMediaModalType].showFiles.length > 0 && (
                                            <Row className="mb-3" form>
                                                <Col xs={12}>
                                                    <div
                                                        style={{
                                                            maxWidth: "120px",
                                                            margin: "0 1rem 1rem auto",
                                                        }}
                                                    >
                                                        <div className="text-center mb-1 small">
                                                            {this.state.uploadMedia.progress === 100 ? (
                                                                <div className="text-success">
                                                                    <FontAwesomeIcon
                                                                        icon="check-circle"
                                                                        className="mr-1"
                                                                    />{" "}
                                  Uploaded
                                                                </div>
                                                            ) : (
                                                                    <div>
                                                                        Uploading{" "}
                                                                        <span className="text-success font-weight-bold ff-base">
                                                                            {this.state.uploadMedia.progress.toFixed(0)}
                                    %
                                  </span>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <Progress
                                                            value={this.state.uploadMedia.progress}
                                                            style={{ height: "8px" }}
                                                        ></Progress>
                                                    </div>
                                                </Col>
                                                {this.state.uploadMedia[uploadMediaModalType].showFiles.map((file, index) => {
                                                    return (
                                                        <Col xs="auto">
                                                            <div className="bg-light p-2">
                                                                <div className="d-flex pr-3">
                                                                    <div>
                                                                        <div
                                                                            className="selectable-media"
                                                                            style={{ cursor: "default" }}
                                                                        >
                                                                            <div className="gallery-media">
                                                                                {(file.type && file.type.includes('image') || file.media_type && file.media_type.includes('image')) ? (
                                                                                    <img src={file.url || file.file} alt="" />
                                                                                ) : (
                                                                                        <video>
                                                                                            <source src={file.url || file.file} type={file.type || file.media_type} />
                                                                                Your browser does not support HTML5 video.
                                                                                        </video>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mx-n3 mt-2">
                                                                        <Button color="delete-media _alt" size="sm" title="Remove Media"
                                                                            hidden={file.id === "" ? true : false}
                                                                            onClick={this.handleOnClickRemoveSelectedMedia(
                                                                                file.id
                                                                            )}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input bsSize="sm" type="text" name="caption" className="caption-input" placeholder="Caption" onBlur={(e) => this.handleCaptionByIndex(e, index)} style={{ maxWidth: '160px' }} />
                                                                    <Input onChange={(e) => this.handleChangeCategory(e, index)} bsSize="sm" type="select" name="album_type_category" id="selectCategoryTypeData" style={{ maxWidth: '160px' }}>
                                                                        <option value="">Choose Category</option>
                                                                        {cattaxListing && Object.keys(cattaxListing).length > 0 ?
                                                                            Object.values(cattaxListing).map(
                                                                                (data, i) => (
                                                                                    <>
                                                                                        <option value={data.category}>{data.category}</option>
                                                                                    </>
                                                                                ))
                                                                            : null}
                                                                    </Input>
                                                                    {uploadedId[index] == file.id && (Object.keys(fetchTaxonomyListingImages).length > 0 || Object.keys(fetchTaxonomyListingVideos).length > 0) ?
                                                                        <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_type_select" id="selectAlbumTypeData" style={{ maxWidth: '160px' }}>
                                                                            <option value="">Choose Subcategory</option>
                                                                            {file.media_type === "image" && fetchTaxonomyListingImages && Object.keys(fetchTaxonomyListingImages).length > 0 && fetchTaxonomyListingImages[index] ?
                                                                                fetchTaxonomyListingImages[index].map(item => (
                                                                                    <option value={item.id}>{item.label}</option>
                                                                                ))
                                                                                : null}
                                                                            {file.media_type === "video" && fetchTaxonomyListingVideos && Object.keys(fetchTaxonomyListingVideos).length > 0 && fetchTaxonomyListingImages[index] ?
                                                                                fetchTaxonomyListingVideos[index].map(item => (
                                                                                    <option value={item.id}>{item.label}</option>
                                                                                ))
                                                                                : null}
                                                                        </Input>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                })}
                                                <Col xs="auto">
                                                    <div className="d-flex pt-3">
                                                        <div className="selectable-media" hidden>
                                                            <Label
                                                                for="uploadFilesFromSystemMini"
                                                                className="gallery-media"
                                                                style={{
                                                                    borderStyle: "dashed",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <div className="d-flex h-100 align-items-center justify-content-center">
                                                                    <span className="fs-14">Upload More</span>
                                                                </div>
                                                            </Label>
                                                            <Input
                                                                type="file"
                                                                name="upload_media_file"
                                                                id="uploadFilesFromSystemMini"
                                                                accept="image/*, video/*"
                                                                onChange={this.handleOnFilePostUploadChange}
                                                                multiple
                                                                style={{ display: "none" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={this.uploadMediaModalToggle}
                                                >
                                                    Cancel
                        </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            uploadMediaPostModal: false,
                                                        });
                                                    }}
                                                >
                                                    {'OK'}
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="gallery">
                                        <div className="mb-2">
                                            <Nav tabs className="d-inline-flex mb-0">
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        active={this.state.showGalleryType === "images"}
                                                        onClick={() => {
                                                            this.handleOnClickGalleryType("images");
                                                        }}
                                                    >
                                                        Image Gallery
                          </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        active={this.state.showGalleryType === "videos"}
                                                        onClick={() => {
                                                            this.handleOnClickGalleryType("videos");
                                                        }}
                                                    >
                                                        Video Gallery
                          </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={this.state.showGalleryType}>
                                            <TabPane tabId="images">
                                                <div>
                                                    <FormGroup className="form-row justify-content-end">
                                                        <Col xs="auto">
                                                            <Label className="small" for="selectAlbumType">
                                                                Album Type:
                              </Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Input
                                                                bsSize="sm"
                                                                type="select"
                                                                name="album_type_select"
                                                                id="selectAlbumType"
                                                                defaultValue={this.state.uploadMedia.albumType}
                                                                onChange={this.handleOnClickAlbumTypeChange}
                                                            >
                                                                <option value="">All</option>
                                                                {this.state.uploadMedia.albumTypesList.length >
                                                                    0 &&
                                                                    this.state.uploadMedia.albumTypesList.map(
                                                                        (type) => (
                                                                            <option value={type.category}>
                                                                                {type.category}
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: "350px", overflow: "auto" }}
                                                    ref={this.refGalleryImageScroll}
                                                    onScroll={this.handleScrollGalleryImage}
                                                >
                                                    {this.state.uploadMedia.albumTypeData &&
                                                        this.state.uploadMedia.albumTypeData.count > 0 ? (
                                                            <Row>

                                                                {this.fetchScrollPhotoMedia()}
                                                            </Row>
                                                        ) : (
                                                            <div className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">No images</h2>
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={this.uploadMediaModalToggle}
                                                        >
                                                            Cancel
                            </Button>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    uploadMediaPostModal: false,
                                                                });
                                                            }}
                                                        >
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="videos">
                                                <div
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: "350px", overflow: "auto" }}
                                                    ref={this.refGalleryVideoScroll}
                                                    onScroll={this.handleScrollGalleryVideo}
                                                >
                                                    {this.state.uploadMedia.albumTypeData &&
                                                        this.state.uploadMedia.albumTypeData.count > 0 ? (
                                                            <Row>
                                                                {this.fetchScrollPhotoMedia(true)}
                                                            </Row>
                                                        ) : (
                                                            <div className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">No videos</h2>
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={this.uploadMediaModalToggle}
                                                        >
                                                            Cancel
                            </Button>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    uploadMediaPostModal: false,
                                                                });
                                                            }}
                                                        >
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapState = (state) => ({
    my_profile_info: state.user.my_profile,
    community_review_projects_data: state.communityReviewProjects.community_review_projects_data,
    project_data: state.communityReviewProjects.project_data,
    project_responses: state.communityReviewProjects.project_responses,
    project_taxonomies: state.communityReviewProjects.project_taxonomies,
    project_subcategories: state.communityReviewProjects.project_subcategories,
    project_subcategories_level: state.communityReviewProjects.project_subcategories_level,
    project_questions: state.communityReviewProjects.project_questions,
    friends: state.communityReviewProjects.friends,
    current_location: state.home.current_location,
    album_types_list: state.user.album_types_list,
    album_type_data: state.user.album_type_data,
    fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
    new_sub_categories: state.business.new_sub_categories,
    fetch_data_categories: state.business.fetch_data_categories,
    filter_data_category: state.business.filter_data_category,
});

const mapDispatch = (dispatch) => ({
    my_profile: () => dispatch(my_profile()),
    get_community_review_projects: (url) => dispatch(get_community_review_projects(url)),
    get_project: (id) => dispatch(get_project(id)),
    get_project_responses: (id) => dispatch(get_project_responses(id)),
    publish_project: (id, url) => dispatch(publish_project(id, url)),
    update_project_status: (id, url, data) => dispatch(update_project_status(id, url, data)),
    get_project_taxonomies: () => dispatch(get_project_taxonomies()),
    get_project_subcategories: (id) => dispatch(get_project_subcategories(id)),
    get_project_subcategories_level: (id) => dispatch(get_project_subcategories_level(id)),
    add_project: (data, url, submitType) => dispatch(add_project(data, url, submitType)),
    get_category_questions: (id) => dispatch(get_category_questions(id)),
    get_friends: (query) => dispatch(get_friends(query)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    fetch_sub_categories: ({ sub_cat, level }) => dispatch(fetch_sub_categories({ sub_cat, level })),
    fetch_categories_data: (cat, sub_cat, type, index) => dispatch(fetch_categories_data(cat, sub_cat, type, index)),
    filter_categories_data: ({ category, sub_cat, level }) => dispatch(filter_categories_data({ category, sub_cat, level })),
    clear_category: () => dispatch(clear_category()),
    clear_subcategory: () => dispatch(clear_subcategory()),
    get_album_types_list: () => dispatch(get_album_types_list()),
    get_album_type_data: (type, album, page) => dispatch(get_album_type_data(type, album, page)),
    delete_selected_gallery_media: (dataID) => dispatch(delete_selected_gallery_media(dataID)),
});

export default withRouter(connect(mapState, mapDispatch)(CommunityModal));