import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import moment from "moment";

import {
  upload_media,
  initFetchMovieSubCategories,
  search_moviesMembers
} from "../../../actions/footer";

import {
  add_movie
} from "../../../actions/movies";

import { callApi } from "../../../utils/apiCaller";

class RateMovieReviewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addMovieForm: {
        name: "",
        is_draft: false,
        drawer: true,
        additional_info: {
          rating: "",
          duration: "",
          storyline: "",
        },
        taxonomy: [],
        media: [],
        country: "",
        year: moment().year(),
        rating: "",
        duration: "",
        directors_set: [
          {
            first_name: "",
            profession_id: "",
          },
        ],
        producers_set: [
          {
            first_name: "",
            profession_id: "",
          },
        ],
        writers_set: [
          {
            first_name: "",
            profession_id: "",
          },
        ],
        casts_set: [
          {
            first_name: "",
            profession_id: "",
          },
        ],
      },
      years_list: [],
      name: '',
      category_id: '',
      subcategory_id: '',
      year: moment().year(),
      country: 'United States',
      rating: 'G',
      duration: '',
      storyline: '',

      directors_set: [
        {
          first_name: "",
          profession_id: "",
        },
      ],
      writers_set: [
        {
          first_name: "",
          profession_id: "",
        },
      ],
      producers_set: [
        {
          first_name: "",
          profession_id: "",
        },
      ],
      casts_set: [
        {
          first_name: "",
          profession_id: "",
        },
      ],

      mediaSet: [],
      media: [],
      uploadMediaModal: false,
      fileReviewStatus: false,
      selectedFilesPreview: [],
      latestUploadedData: {},
      showVideoMedia: true,
      fetchTaxonomy: [],
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      uploadCaptions: {},
      nameError: "",
      categoryError: "",
      directorsError: "",
      durationError: "",
      writersError: "",
      storylineError: "",
      producersError: "",
      castsError: "",
      files: [],
      albumType: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      uploadMediaPostModal: false,
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
      search_driector_info: [],
      search_producer_info: [],
      search_writer_info: [],
      search_cast_info: [],
      indexValue: 0,
      indexCastValue: 0,
      indexProducerValue: 0,
      indexWriterValue: 0,
      typeMethod: "",
      rateType: "",
    };
  }
  redirectto = (movieId, slug) => {
    let path = "movies";
    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: movieId }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fetch_save_movie_response) {
      if (nextProps.fetch_save_movie_response && nextProps.fetch_save_movie_response.id && nextProps.fetch_save_movie_response.slug) {
        let slug = null;
        let movieId = "";
        if (nextProps.fetch_save_movie_response.id != "") {
          movieId = nextProps.fetch_save_movie_response.id;
        }
        if (nextProps.fetch_save_movie_response.slug != "") {
          slug = nextProps.fetch_save_movie_response.slug;
        }
        this.redirectto(movieId, slug);

      }
    }

    if (nextProps.ratingType && nextProps.ratingType != "") {
      this.setState({ rateType: nextProps.ratingType })
    }

  }

  componentDidMount = () => {
    let date = new Date()
    let year = date.getFullYear()
    let years_list = [];
    for (let i = 1970; i <= year + 10; i++) {
      years_list.push(i)
    }
    this.setState({ years_list: years_list })
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let { rateType } = this.state;
    this.setState({
      [name]: value
    });
    if (name == "category_id") {
      let type = "category_id";
      this.setState({
        categoryError: "",
        category_id: e.target.value
      });
      this.props.initFetchMovieSubCategories(e.target.value, type);
      if (rateType == "RateMovieImage") {
        this.fetchDataFromHtmlForm(e.target.value, "Images");
      } else {
        this.fetchDataFromHtmlForm(e.target.value, rateType.replace("RateMovie", ""));
      }

    }

  }

  fetchDataFromHtmlForm = async (id, listing_type) => {
    let name_type = `review-step-1`;
    let url = null;
    if (["Images", "Videos", "Reviews", "Questions"].includes(listing_type)) {

      url = `/form/ratingadd?type=${listing_type}&object=Movies&rating_type=&taxonomy_id=${id}`;
    }

    if (url) {
      try {
        const response = await callApi(url, 'GET');

        if (response?.code == 200) {
          let data = response?.data;
          let personOptions = null;
          if (data) {
            const doc = new DOMParser().parseFromString(data, "text/html")

            if (doc) {
             
              /*let uniqrate = doc.getElementById('uniqrate').value;
              let csrfmiddlewaretoken = doc.getElementsByName('csrfmiddlewaretoken')[0].value;
              let timestamp = doc.getElementsByName('timestamp')[0].value;
              let honeypot = doc.getElementsByName('honeypot')[0].value;
              let object_pk = doc.getElementsByName('object_pk')[0].value;
              let content_type = doc.getElementsByName('content_type')[0].value;

              let avg_rating_34 = doc.getElementsByClassName(name_type)[0];

              if (avg_rating_34) {
                  let security_hash = avg_rating_34.querySelector('#id_security_hash')?.value;

                  let reviewDynamicItems = {};
                  reviewDynamicItems.uniqrate = uniqrate;
                  reviewDynamicItems.csrfmiddlewaretoken = csrfmiddlewaretoken;
                  reviewDynamicItems.security_hash = security_hash;
                  reviewDynamicItems.timestamp = timestamp;
                  reviewDynamicItems.honeypot = honeypot;
                  reviewDynamicItems.object_pk = object_pk;
                  reviewDynamicItems.content_type = content_type;

                  if (listing_type == "movies") {
                      if (this.props.isWriteFollowUp) {
                          personOptions = doc.getElementById('follow_profession').innerHTML;
                      } else {
                          personOptions = doc.getElementById('id_professional').innerHTML;
                      }
                  }

                  let filteredReviewCategories = [];
                  let sampleElements = doc.getElementsByClassName('services')[0];
                  let listElements = sampleElements?.getElementsByTagName('li');

                  if (listElements && typeof (listElements) == "object" && listElements?.length) {
                      for (let item of listElements) {
                          filteredReviewCategories.push(this.renderListItems(item));
                      }

                      this.setState({
                          doc,
                          uniqrate,
                          reviewDynamicItems,
                          choosePersonOptions: personOptions,
                          reviewCategoriesList: filteredReviewCategories
                      });
                  }
              }*/
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  }



  handleMovieSubmit = () => {
    let { name, storyline, category_id, duration, directors_set, additional_info, writers_set, producers_set, casts_set } = this.state
    if (name == "" && category_id == "" && directors_set[0].first_name == "" && directors_set[0].profession_id == "" && writers_set[0].first_name == ""
      && writers_set[0].profession_id == "" && producers_set[0].profession_id == "" && producers_set[0].first_name == "" && casts_set[0].first_name == ""
      && casts_set[0].profession_id == "" && storyline == "") {
      this.setState({
        nameError:
          "This field is required",
        categoryError:
          "This field is required",
        directorsError:
          "This field is required",
        durationError:
          "This field is required",
        writersError:
          "This field is required",
        storylineError:
          "This field is required",
        producersError:
          "This field is required",
        castsError:
          "This field is required",
      });
    } else if (name == "") {
      this.setState({
        nameError:
          "This field is required",
      });
    }
    else if (category_id == "") {
      this.setState({
        categoryError:
          "Please select category",
      });

    }
    else if (directors_set[0].first_name == "") {
      this.setState({
        directorsError:
          "Please select Director",
      });
    } else if (directors_set[0].profession_id == "") {
      this.setState({
        directorsError:
          "Please select Director",
      });
    }
    else if (duration == "") {
      this.setState({
        durationError:
          "Please select Duration",
      });
    }
    else if (writers_set[0].first_name == "") {
      this.setState({
        writersError:
          "Please select Writers",
      });
    } else if (writers_set[0].profession_id == "") {
      this.setState({
        writersError:
          "Please select Writers",
      });
    }
    else if (storyline == "") {
      this.setState({
        storylineError:
          "Please select Storyline",
      });
    }
    else if (producers_set[0].first_name == "") {
      this.setState({
        producersError:
          "Please select producers",
      });
    } else if (producers_set[0].profession_id == "") {
      this.setState({
        producersError:
          "Please select producers",
      });
    }
    else if (casts_set[0].first_name == "") {
      this.setState({
        castsError:
          "Please select casts",
      });
    } else if (casts_set[0].profession_id == "") {
      this.setState({
        castsError:
          "Please select casts",
      });
    } else {
      let params = {}
      let media = []
      // this.state.mediaSet?.map(med => {
      //   media.push(med.id)
      // })
      let intItem = parseInt(this.state.category_id)
      params = {
        name: this.state.name,
        is_draft: false,
        additional_info: {
          rating: this.state.rating,
          duration: this.state.duration,
          storyline: this.state.storyline,
        },
        country: this.state.country,
        cast_set: this.state.casts_set,
        directors_set: this.state.directors_set,
        producers_set: this.state.producers_set,
        writers_set: this.state.writers_set,
        media: [],
        taxonomy: [intItem],
        release_date: this.state.year,
      }
      if (this.state.uploadMedia.selectedMediaIds.length > 0) {
        params.media = this.state.uploadMedia.selectedMediaIds
      }

      this.props.add_movie(params);

    }
  }

  render() {
    const {
      openModal,
      toggleRateMovieModal,
      movies,
      ratingType
    } = this.props;
    let rateType = ratingType && ratingType != "" ? ratingType.replace("RateMovie", "") : "";
    const { addMovieForm, category_id, years_list, year, name, rating, duration, directors_set,
      writers_set, casts_set, producers_set, search_driector_info, search_producer_info, search_writer_info, search_cast_info, typeMethod } = this.state;
    return (
      <>
        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={openModal}
          toggle={toggleRateMovieModal}
        >
          <form
            className="d-flex flex-column h-100"
          >
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button color="primary" onClick={toggleRateMovieModal}>
                    <FontAwesomeIcon icon="angle-left" />
                        back to listing
                      </Button>
                </div>
                <div>
                  <div className="bg-darker p-3 text-tertiary">
                    <div className="mb-2 text-primary font-weight-bold">
                      You’re Rating {rateType == "Image" ? "Images" : rateType}
                    </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input
                              type="select"
                              className="transparent"
                              size="sm"
                            >
                              <option>{movies?.category}</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </li>
                      <li className="px-2">
                        <div className="d-flex">
                          <div className="mr-1">
                            <span className="text-danger">*</span>
                          </div>
                          <FormGroup>
                            <Input
                              id="category_id"
                              name="category_id"
                              onChange={this.handleChange}
                              type="select"
                              className="transparent"
                              size="sm"
                              selected={category_id}
                            >
                              <option value="">Select Category</option>
                              {movies?.children?.map((movie) => {
                                return (
                                  <option value={movie.id} key={movie.id}>
                                    {movie.category}
                                  </option>
                                );
                              })}
                            </Input>
                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.categoryError}</span>
                          </FormGroup>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ModalBody className="bg-white text-dark">
              <div className="wk-crpmodal">
                <div className="wk-rating-crp-section">
                  <Row>
                    <Col md="5">
                      <div className="wk-rating-crp-block p-4">
                        <ul className="position-relative m-0 p-0 d-flex justify-content-center">
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                        </ul>
                        <h2 className="text-white text-uppercase mt-2 mb-2 text-center">RATE</h2>
                        <ul className="position-relative m-0 p-0 d-flex justify-content-center">
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                          <li className="mr-2">
                            <FontAwesomeIcon icon="star" />
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="9">
                      <div className="wk-voting-crp-block p-4 bg-dark mb-4">
                        <div class=" font-weight-bold mb-2 text-primary">Step 1. Rate Away!</div>
                        <p className="text-white mb-0">	Be honest; you like sharing your opinion! At WikiReviews, we LOVE hearing
                        what you have to say! Your ratings determine the rise and fall of content on
                        this site. Like it? 5 stars will get the community talking. Hate it? Send it to 1
                        star oblivion with a click of your mouse. This is your site!
                          </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col md="6">
                    <div class=" font-weight-bold mb-2 text-primary mb-3">Step 1. Read the Review</div>
                    <p className="wk-anonmous-review-text text-dark font-weight-bold">Anonymous's Review</p>
                    <div className="bg-grey p-4 mb-4">
                      <Row className="mb-3 align-items-center">
                        <Col md="6">
                          <p className="mb-0">Overall Rating</p>
                        </Col>
                        <Col md="6">
                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/35.png" alt="star" />
                        </Col>
                      </Row>
                      <Row className="mb-3 align-items-center">
                        <Col md="6">
                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/35.png" alt="star" />
                          <p className="mb-0 mt-2 text-dark font-weight-bold">Storyline/Plot</p>
                        </Col>
                        <Col md="6">
                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/5rating.png" alt="star" />
                          <p className="mb-0 mt-2 text-dark font-weight-bold">Attention Span</p>
                        </Col>
                      </Row>
                      <Row className="mb-3 align-items-center">
                        <Col md="6">
                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/5rating.png" alt="star" />
                          <p className="mb-0 mt-2 text-dark font-weight-bold">Cinematography</p>
                        </Col>
                        <Col md="6">
                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/5rating.png" alt="star" />
                          <p className="mb-0 mt-2 text-dark font-weight-bold">Score</p>
                        </Col>
                      </Row>
                      <Row className="mb-3 align-items-center justify-content-end">
                        <Col md="6">
                          <img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/5rating.png" alt="star" />
                          <p className="mb-0 mt-2 text-dark font-weight-bold">Quality of Acting</p>
                        </Col>
                      </Row>
                      <p className="mb-0 text-dark">During WW2, Germany invaded Norway and there were some Norwegians who plotted against the Germans and this is the story of that failure.  It was a riveting performance of survival of the 12th man on that mission.   It is a true and truly crazy story of he survived against the Nazi's.  The only thing that was very disappointing was that everyone kept him alive because he had some very important info to share with the Norwegians about his mission, but at the end, it seemed like that info was not that important.  And they didn't focus on it at all so it seemed like the whole movie of keeping him alive to give nonimportant info was kind of a let down.
                         </p>
                    </div>
                    <p className="mb-2">Submitted by <a href="#">Anonymous</a></p>
                    <p><a href="#" className="font-normal">2 years ago</a></p>
                  </Col>
                  <Col md="6">
                    <div class=" font-weight-bold mb-2 text-primary">Step 2. Rate</div>
                    <p>Rate This Project</p>
                    <div className="mb-2">
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                      <input type="radio" className="mr-2" />
                    </div>
                    <textarea placeholder="" class="form-control wk-crpmodal-quick-note">Quick Note.(optional)</textarea>
                    <div className="text-right mt-3">
                      <Button color="primary" size="sm">
                        Quick Note
                      </Button>
                      <Button className="ml-0" color="primary" size="sm" onClick={this.handleMovieSubmit}>
                        Submit
                      </Button>
                      <a href="#" className="ml-4">Skip ></a>
                    </div>
                  </Col>
                </Row>
              </div>

            </ModalBody>
          </form>
        </Modal>



      </>


    );



  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  directorListing: state.footer.directorListing,
  writerListing: state.footer.writerListing,
  castListing: state.footer.castListing,
  producerListing: state.footer.producerListing,
  fetch_save_movie_response: state.movies.fetch_save_movie_response,
});

const mapProps = (dispatch) => ({
  add_movie: (data) => dispatch(add_movie(data)),
  initFetchMovieSubCategories: (categoryId, type) => dispatch(initFetchMovieSubCategories(categoryId, type)),
  search_moviesMembers: (value, type) => dispatch(search_moviesMembers(value, type)),
});
export default withRouter(connect(mapState, mapProps)(RateMovieReviewModal));