import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import osm from "../../atoms/osm-providers"
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Progress,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { callApi } from "../../../utils/apiCaller";
import { Icon } from "leaflet";
import { Map, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import Control from 'react-leaflet-control';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import {
  upload_media,
  initFetchBusinessSubCategories,
  clear_cat_sub_business
} from "../../../actions/footer";

import {
  add_business
} from "../../../actions/business";


class RateBusinessModal extends React.Component {
  constructor(props) {
    super(props);
    this.myScrollPhotoRef = React.createRef();
    this.myScrollVideoRef = React.createRef();

    this.refGalleryVideoScroll = React.createRef();
    this.refGalleryImageScroll = React.createRef();

    this.refScrollDeleteImages = React.createRef();
    this.refScrollDeleteVideos = React.createRef();
    this.wrapperRef = React.createRef();
    this.state = {
      addBusinessForm: {
        name: "",
        country: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        hour_start_time: "",
        hour_end_time: "",
        hour_day_of_week: "",
        hour_info: "",
        radial_distance: "",
        drawer: true,
        is_draft: false,
		myPostsList: {},
        termsCondtions: false,
        additional_info: {
          is_tos_accepted: false,
        },
        taxonomy: [""],
        media: [""],
        email_set: {
          email: "",
          email_type: ""
        },
        website_set: [{
          website: ""
        }],
        location_radial_set: [
          {
            radial_distance: "",
          }
        ],
        location_set: [
          {
            area_served: ""
          }
        ],
        paymentoptions_set: [
          {
            paymentoptions: ""
          }
        ],
        phone_set: [{
          ph1: "",
          ph2: "",
          ph3: "",
          phone: ""
        }],
        address: [{
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipcode: ""
        }],
        hours_operation_set: [{
          start_time: "",
          end_time: "",
          day_of_week: "",
          info: ""
        }]
      },
      country: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      category_id: '',
      subcategory_id: '',
      name: "",
      email: "",
      email_type: "",
      website: "",
      radial_distance: "",
      email_set: {
        email: "",
        email_type: ""
      },
      location_set: [
        {
          area_served: ""
        }
      ],
      paymentoptions_set: [
        {
          paymentoptions: ""
        }],
      hours_operation_set: [{
        start_time: "",
        end_time: "",
        day_of_week: "",
        info: ""
      }],
      address: [{
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: ""
      }],
      location_latLong: [
        {
          latitude: 0,
          longitude: 0,
          address: ""

        }
      ],
      mediaSet: [],
      uploadMediaModal: false,
      media: [],
      fileReviewStatus: false,
      selectedFilesPreview: [],
      latestUploadedData: {},
      showVideoMedia: true,
      fetchTaxonomy: [],
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      files: [],
      nameError: "",
      cityError: "",
      categoryError: "",
      stateError: "",
      zipcodeError: "",
      termsError: "",
      businessSubcategory: [],
      uploadCaptions: {},
      taxonomycategoryId: "",
      fetchTaxomomyCategory: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      albumType: "",
      uploadMediaPostModal: false,
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
      number_part_1: "",
      number_part_2: "",
      number_part_3: "",
      hour_start_time: "",
      hour_end_time: "",
      hour_day_of_week: "",
      hour_info: "",
      termsCondtions: false,
      currentLat: 0,
      currentLong: 0,
      zoom: 12,
      keyMAP: Math.random(),
      routes: [],
      address: "",
      lat: "",
      lng: "",
      is_draft: false
    };
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.current_location &&
      Object.keys(nextProps.current_location).length > 0
    ) {
      if (nextProps.current_location?.lat != this.props.current_location?.lat && nextProps.current_location?.lng != this.props.current_location?.lng && this.props.current_location != null) {
        this.setState(
          {
            currentLat: nextProps.current_location?.lat,
            currentLong: nextProps.current_location?.lng,
          },
          () => {
            this.mapCordinatesSet();
          }
        );
      } else {

        this.setState(
          {
            currentLat: nextProps.current_location?.lat,
            currentLong: nextProps.current_location?.lng,
          });
      }

    }

    if (nextProps.fetch_save_response) {
      if (nextProps.fetch_save_response && nextProps.fetch_save_response.id && nextProps.fetch_save_response.slug) {
        let slug = null;
        let businessId = "";
        if (nextProps.fetch_save_response.id != "") {
          businessId = nextProps.fetch_save_response.id;
        }
        if (nextProps.fetch_save_response.slug != "") {
          slug = nextProps.fetch_save_response.slug;
        }
        this.redirecttobusiness(businessId, slug);
        
      }

    }

  }
  

  componentWillUnmount() {
    this.props.clear_cat_sub_business();
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (name == "name") {
      this.setState({
        nameError: ""
      });
    } else if (name == "city") {
      this.setState({
        cityError: ""
      });
    } else if (name == "category_id") {
      this.setState({
        categoryError: ""
      });
    } else if (name == "state") {
      this.setState({
        stateError: ""
      });
    } else if (name == "zipcode") {
      this.setState({
        zipcodeError: ""
      });
    } else if (name == "termsCondtions") {
      this.setState({
        termsError: ""
      });
    }


    if (name == "category_id") {
      let type = "category_id"
      this.props.initFetchBusinessSubCategories(e.target.value, type);
    }
    if (name == "subcategory_id") {
      let type = "subcategory_id"
      this.props.initFetchBusinessSubCategories(e.target.value, type);
    }

  }
  redirecttobusiness = (businessId, slug) => {
    let path = "business";

    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: businessId }
    })
  }

  handleChangeBusiness = (e, index) => {
    const { paymentoptions_set, location_set, hours_operation_set } = this.state
    const { name, value } = e.target
    if (name.includes('payment') && paymentoptions_set.length <= index) {
      paymentoptions_set.push({ paymentoptions: "" })
      this.setState({ paymentoptions_set })
    }
    if (name.includes('area') && location_set.length <= index) {
      if (this.state.radial_distance != "") {
        location_set.push({ area_served: "", radial_distance: "" })
      } else {
        location_set.push({ area_served: "" })
      }

      this.setState({ location_set })
    }
    if (name.includes('hours') && hours_operation_set.length <= index) {
      hours_operation_set.push({ start_time: "", end_time: "", day_of_week: "", info: "" })
      this.setState({ hours_operation_set })
    }


    if (name === 'payment_options') {
      paymentoptions_set[index].paymentoptions = value
      this.setState({ paymentoptions_set })
    }
    else if (name === 'area_served') {
      location_set[index].area_served = value;
      if (this.state.radial_distance != "") {
        location_set[index].radial_distance = this.state.radial_distance
      }
      this.setState({ location_set })
    } else if (name === 'hour_start_time') {
      hours_operation_set[index].start_time = value
      this.setState({ hours_operation_set })
    } else if (name === 'hour_end_time') {
      hours_operation_set[index].end_time = value
      this.setState({ hours_operation_set })
    } else if (name === 'hour_day_of_week') {
      hours_operation_set[index].day_of_week = value
      this.setState({ hours_operation_set })
    } else if (name === 'hour_info') {
      hours_operation_set[index].info = value
      this.setState({ hours_operation_set })
    }

  }


  handleCancel = () => {
    this.setState({
      businessSubcategory: []
    });
    this.props.clear_cat_sub_business();
    this.props.toggleRateBusinessModal();
  }

  handleBusinessSubmit = () => {
    let { name, city, state, zipcode, address1, address2
      , email_set, location_set, paymentoptions_set, hours_operation_set, address, email, email_type, website, radial_distance, start_time,
      end_time, day_of_week, info, category_id, termsCondtions } = this.state;
    
    if (name == "" && city == "" && category_id == "" && state == "" && zipcode == "" && termsCondtions == false) {
      this.setState({
        nameError:
          "This field is required",
        cityError:
          "City is required",
        categoryError:
          "This field is required",
        stateError:
          "State name is required",
        zipcodeError:
          "Zipcode/Postal code is required",
        termsError:
          "This field is required",
      });
    } else if (city == "") {
      this.setState({
        cityError:
          "Please required city",
      });
    } else if (name == "") {
      this.setState({
        nameError:
          "Please required business name",
      });
    } else if (category_id == "") {
      this.setState({
        categoryError:
          "Please select category",
      });
    }
    else if (state == "") {
      this.setState({
        stateError:
          "Please required state",
      });
    }
    else if (zipcode == "") {
      this.setState({
        zipcodeError:
          "Please required zipcode",
      });
    }
    else if (termsCondtions == "") {
      this.setState({
        termsError:
          "Please select Terms and Conditions",
      });
    } else {
      let params = {}
      let media = []
      
      params = {
        name: this.state.name,
        is_draft: this.state.is_draft,
        additional_info: {
          is_tos_accepted: this.state.termsCondtions,
        },
        email_set: {
          email: this.state.email,
          email_type: this.state.email_type
        },
        website_set: [{
          website: this.state.website
        }],
        location_radial_set: [{
          radial_distance: this.state.radial_distance
        }],
        phone_set: [{
          ph1: this.state.number_part_1,
          ph2: this.state.number_part_2,
          ph3: this.state.number_part_3,
          phone: this.state.number_part_1 + this.state.number_part_2 + this.state.number_part_3
        }],
        address: [{
          address1: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          zipcode: this.state.zipcode
        }],
        hoursofoperation_set: hours_operation_set && hours_operation_set[0] && hours_operation_set[0].start_time == "" ? {} : hours_operation_set,
        paymentoptions_set: paymentoptions_set && paymentoptions_set[0] && paymentoptions_set[0].paymentoptions == "" ? {} : paymentoptions_set,
        location_set: location_set && location_set[0] && location_set[0].area_served == "" ? {} : location_set,
        taxonomy: [this.state.category_id],
      }
      if (location_set.length > 0) {
        for (var i = 0; i < location_set.length; i++) {
          if (this.state.radial_distance != "") {
            location_set[i].radial_distance = this.state.radial_distance
          }
        }
      }
      if (this.state.uploadMedia.selectedMediaIds.length > 0) {
        params.media = this.state.uploadMedia.selectedMediaIds
      }
      this.props.add_business(params)
    }
  }

  handleOnRestoreMap = () => {
    this.setState({ keyMAP: Math.random() })
  }

  handleChangeAddress = (address, stateName, index) => {

    let { location_set, location_latLong } = this.state;
    //
    if (location_set.length <= index) {
      if (this.state.radial_distance != "") {
        location_set.push({ area_served: "", radial_distance: "" })
      } else {
        location_set.push({ area_served: "" })
      }

      if (location_latLong.length <= index) {
        location_latLong.push({ latitude: 0, longitude: 0, address: "" })
      }


      this.setState({ location_set })
    }
    if (this.state.radial_distance != "") {
      location_set[index].radial_distance = this.state.radial_distance
    }
    location_set[index].area_served = address;
    this.setState({ location_set, [stateName]: address });

  }


  mapCordinatesSet = () => {
    let location_latLong = this.state.location_latLong;
    const skater = new Icon({
      iconUrl: "https://stagingdatawikireviews.s3.amazonaws.com/images/map-W.png",
      iconSize: [30, 40]
    });
    return (
      <Map key={this.state.keyMAP} center={{
        lat: (this.state.currentLat),
        lng: (this.state.currentLong)
      }}
        zoom={this.state.zoom}
        onClick={this.onMapClicked}
        style={{ position: 'absolute', zIndex: '0' }}
        boundsOptions={{
          paddingBottomRight: [250, 0],
        }}
        zoomControl={false}
      >
        <ZoomControl position="bottomright" />

        <TileLayer
			url={osm.maptiler.url}
			attribution={osm.maptiler.attribution}
          maxZoom={18}
        />
        {location_latLong && Array.isArray(location_latLong) && location_latLong.length ?
          location_latLong.map((item, index) => {
            return (<Marker
              position={[
                item.latitude && item.latitude != 0 ? item.latitude : 0,
                item.longitude && item.longitude != 0 ? item.longitude : 0,
              ]}
              icon={skater}
              //onClick={this.onMarkerClick}
              key={index}
            >
              <Popup>{item.address ? item.address : ""}</Popup>
            </Marker>
            )
          })
          : (null)}

        <Control position="topright">
          <button className="leafletbutton"
            onClick={() => { this.handleOnRestoreMap() }}
          >
            Restore Map
				</button>
        </Control>
      </Map>
    )
  }

  handleSelect = async (address, stateName, index) => {
    const result = await geocodeByAddress(address);
    const latlng = await getLatLng(result[0]);

    let { location_set, location_latLong } = this.state;
    //
    if (location_set.length <= index) {
      if (this.state.radial_distance != "") {
        location_set.push({ area_served: "", radial_distance: "" })
      } else {
        location_set.push({ area_served: "" })
      }

      if (location_latLong.length <= index) {
        location_latLong.push({ latitude: 0, longitude: 0, address: "" })
      }

      this.setState({ location_set })
    }
    if (this.state.radial_distance != "") {
      location_set[index].radial_distance = this.state.radial_distance
    }
    location_set[index].area_served = address;
    location_latLong[index].latitude = latlng.lat;
    location_latLong[index].longitude = latlng.lng;
    location_latLong[index].address = address;
    await this.setState({
      location_set, [stateName]: address, location_latLong
    }, () => {
      this.mapCordinatesSet()
    });
  };


  render() {
    const {
      openModal,
      toggleRateBusinessModal,
      businesses,
      videos_children,
    } = this.props;
    const { myPostsList, name, addBusinessForm, category_id, subcategory_id, country, address1, address2,
      city, state, zipcode, email_set, website, radial_distance, location_set, paymentoptions_set, mediaSet, latestUploadedData, selectedFilesPreview,
      showVideoMedia, fetchTaxonomy, embeddedLink, businessSubcategory, fetchTaxomomyCategory,
      fetchTaxonomyListingImages, fetchTaxonomyListingVideos, number_part_1, number_part_2, number_part_3, hours_operation_set, email, email_type } = this.state;

    const searchOptions = {
      componentRestrictions: { country: ['us', 'ca'] },
      // types: ['city']
    };

    return (
        <>
         /* Add Business drawer modal popup */
              <Modal
                size="xl" scrollable className="drawer-modal"
                isOpen={openModal}
                toggle={toggleRateBusinessModal}
              >
                <div className="modal-header p-0">
                  <div className="w-100">
                    <div>
                      <Button color="primary" onClick={toggleRateBusinessModal}><FontAwesomeIcon icon="angle-left" /> back to listing</Button>
                    </div>
                    <div>
                      <div className="bg-darker p-3 text-tertiary">
                        <div className="mb-2 text-primary font-weight-bold">
                          You’re Adding a New Business Listing
                                   </div>
                        <ul className="list-unstyled d-flex flex-wrap mx-n2">
                          <li className="px-2">
                            <div className="d-flex">
                              <FormGroup>
                                <Input type="select" className="transparent" size="sm">
                                  <option>Business</option>
                                </Input>
                              </FormGroup>
                            </div>
                          </li>
                          <li className="px-2">
                            <div className="d-flex">
                              <div className="mr-1">
                                <span className="text-danger">*</span>
                              </div>
                              <FormGroup>
                                <Input
                                  id="category_id"
                                  name="category_id"
                                  onChange={this.handleChange}
                                  type="select"
                                  className="transparent"
                                  size="sm"
                                  selected={category_id}
                                >
                                  <option value="">Select Category</option>
                                  {businesses && businesses.length > 0 ? businesses.map((business) => {
                                    return (
                                      <option value={business.id} key={business.id}>
                                        {business.category}
                                      </option>

                                    );
                                  }) : ("")}
                                </Input>
                                <span className="text-danger fs-14 ff-alt">{this.state.categoryError}</span>
                              </FormGroup>
                            </div>
                          </li>
                          <li className="px-2" hidden={businessSubcategory && businessSubcategory.length > 0 ? false : true}>
                            <FormGroup>
                              <Input
                                id="subcategory_id"
                                name="subcategory_id"
                                onChange={this.handleChange}
                                type="select"
                                className="transparent"
                                size="sm"
                                selected={subcategory_id}
                              >
                                <option value="">Select SubCategory</option>
                                {businessSubcategory && businessSubcategory.length > 0 ? businessSubcategory.map((businesssub) => {
                                  return (

                                    <option value={businesssub.id} key={businesssub.id}>
                                      {businesssub.category}
                                    </option>
                                  );
                                }) : ("")}
                              </Input>
                            </FormGroup>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <ModalBody className="bg-white text-dark">
                  <div className="wk-crpmodal">
                 <Row>
                    <Col md="6">
                       <div class=" font-weight-bold mb-2 text-primary mb-3">Step 1. Rate Image</div>
                       <p>How would you rate this image?</p>
					   <img alt="" src="https://userdatawikireviews.s3.amazonaws.com/media/content/296878r5.jpg" className="wk-rate-image-left mb-3" />
                       <p className="mb-0">Submitted by <a href="#">Anonymous User</a></p>
                       <p><a href="#" className="font-normal">2 months ago</a></p>
                    </Col>
                    <Col md="6">
                       <p>Rate This Project</p>
                       <div className="mb-2">
                           <input type="radio" className="mr-2"/>
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                           <input type="radio" className="mr-2" />
                       </div>
                       <p>How much do you like this picture? Choose a category for this image: Storefront pic</p>
                       <textarea placeholder="" class="form-control wk-crpmodal-quick-note">Quick Note.(optional)</textarea>
                        <div className="text-right mt-3">
                        <Button color="primary" size="sm" onClick={this.handleMovieSubmit}>
                          Submit
                      </Button>
                      <a href="#" className="ml-4">Skip ></a>
                      </div>
                    </Col>
                 </Row>
             </div>
                </ModalBody>
              </Modal>
            
      </>
    )
  }
}

const mapState = (state) => ({
 videos_children:state.business.videos_children && state.business.videos_children[0],
  businessSabcategories: state.footer.businessSabcategories,
  productAnothSabcategories: state.footer.productAnothSabcategories,
  productOtherSabcategories: state.footer.productOtherSabcategories,
  current_location: state.home.current_location,
  fetch_save_response: state.business.fetch_save_response,
});

const mapProps = (dispatch) => ({
  initFetchBusinessSubCategories: (categoryId, type) => dispatch(initFetchBusinessSubCategories(categoryId, type)),
  clear_cat_sub_business: () => dispatch(clear_cat_sub_business()),
  add_business: (data) => dispatch(add_business(data)),
  
});

export default withRouter(connect(mapState, mapProps)(RateBusinessModal));
