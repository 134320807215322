const initialState = {
    user_feeds: null,
};

const feeds_listing = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "FETCH_USER_FEEDS":
            state.user_feeds = action.payload;
            return { ...state };

        default:
            return state;

    }
}

export default feeds_listing;