import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Button, FormGroup, Input, Form } from 'reactstrap';
import CollapseBasic from './collapse';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { get_tips_warning_data, add_tips_warning_data } from '../../actions/user';

class TipsAndWarning extends Component {
    constructor(props) {
        super(props)
        this.callOnce = true;
        this.callArray = true;
        this.state = {
            detailList: null,
            selectedOption: null,
            toggleForm: false,
            submitText: '',
            tipsArray: [],
            warningArray: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.tips_war_list && nextProps.tips_war_list?.results) {
            let tipsArray = nextProps.tips_war_list.results.filter(item => item.type == "tips");
            let warningArray = nextProps.tips_war_list.results.filter(item => item.type == "warning");
            this.setState({
                detailList: nextProps.tips_war_list.results,
                tipsArray,
                warningArray
            });
        }
    }

    componentDidMount() {
        this.fetchItems();
    }

    fetchItems = () => {
        let id = this.props?.id;
        if (id) {
            this.props.get_tips_warning_data(id);
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        if (value == 'tips') {
            this.setState({ selectedOption: value })
        } else if (value == 'warning') {
            this.setState({ selectedOption: value })
        } else {
            this.setState({ selectedOption: null })
        }
    }

    handleText = (e) => {
        e.preventDefault();

        let text = e.target.value;
        if (text !== '') {
            this.setState({ submitText: text })
        } else {
            this.setState({ submitText: text })
        }
    }


    submitTips = (e) => {
        e.preventDefault();
        let text = this.state.submitText;
        let type = this.state.selectedOption;
        let user_entry = this.props.id;
        let professionalName = this.props.name;

        if (professionalName && professionalName.firstname && professionalName.lastname) {
            professionalName = professionalName.firstname + professionalName.lastname;
        }

        if (text !== '' && type === 'tips' && user_entry) {
            let data = { text, type, user_entry };
            this.props.add_tips_warning_data(data, professionalName);
            this.setState({ submitText: '', toggleForm: false, selectedOption: null }, () => {
                this.fetchItems();
            })
            this.callArray = true;
        }

        if (text !== '' && type === 'warning' && user_entry) {
            let data = { text, type, user_entry };
            this.props.add_tips_warning_data(data, professionalName);
            this.setState({ submitText: '', toggleForm: false, selectedOption: null }, () => {
                this.fetchItems();
            })
            this.callArray = true;
        }
    }

    toggleTips = () => {
        let toggle = this.state.toggleForm;

        if (!toggle) {
            this.setState({ toggleForm: !this.state.toggleForm, selectedOption: null, submitText: '' })
        } else {
            this.setState({ toggleForm: false, selectedOption: null, submitText: '' })
        }
    }

    renderItemsWithOut = ({ detailList, toggleForm, submitText, selectedOption, tipsArray, warningArray }) => {
        return (
            <React.Fragment>
                <hr className="bg-white" />
                <div className="mb-3 fs-13">
                    <p className="text-body ff-base lh-1-3">
                        {'Do you have any tips to offer for current or prospective owners? Any warnings concerning purchase or ownership of this product? List away!'}
                    </p>
                </div>

                <CollapseBasic
                    title="Tips"
                    noHr={true}
                    containerClass="bg-transparent type-secondary title-type-sm expanded-dark icon-lg"
                    bodyClass="mt-3 text-secondary"
                    isOpen={true}
                    size="sm">
                    <ul className="list-unstyled">
                        {tipsArray && Array.isArray(tipsArray) && tipsArray.length > 0 ?
                            tipsArray.map((item, index) => {
                                if (item.type == "tips" && index < 3) {
                                    return (
                                        <li className="mb-2" key={index}>
                                            <div className="text-secondary-dark wb-break-word">
                                                <span className="text-secondary font-weight-bold">{index + 1}.</span> {item.text}
                                            </div>
                                        </li>
                                    )
                                }
                            })
                            :
                            ""}
                    </ul>
                </CollapseBasic>
                <hr className="bg-white" />
                <CollapseBasic
                    title="Warnings"
                    noHr={true}
                    containerClass="bg-transparent type-secondary title-type-sm expanded-dark icon-lg"
                    bodyClass="mt-3 text-secondary"
                    isOpen={true}
                    size="sm">
                    <ul className="list-unstyled">
                        {warningArray && Array.isArray(warningArray) && warningArray.length > 0 ?
                            warningArray.map((item, index) => {
                                if (item.type == "warning" && index < 3) {
                                    return (
                                        <li className="mb-2" key={index}>
                                            <div className="text-secondary-dark wb-break-word">
                                                <span className="text-secondary font-weight-bold">{index + 1}.</span> {item.text}
                                            </div>
                                        </li>
                                    )
                                }
                            })
                            :
                            ""}
                    </ul>
                </CollapseBasic>
                <div className="text-right my-2">
                    <Button
                        color="link"
                        className="p-0 text-secondary-dark font-weight-bold"
                        onClick={() => {
                            if(!this.checkIfLoggedIn()){
                                this.props.forceToggleLoginModal();
                                return false;
                            }
                            this.toggleTips()}}>
                        <FontAwesomeIcon
                            className="mr-1"
                            size="sm"
                            icon="plus"
                        />
                        {'Add another'}
                    </Button>
                </div>

                {toggleForm ?
                    <div className="bg-white p-3">
                        <h5 className="text-secondary-dark mb-3 fs-30">{"Add Another"}</h5>
                        <Form onSubmit={this.submitTips}>
                            <FormGroup>
                                <Input
                                    className="border"
                                    bsSize="sm"
                                    type="select"
                                    onChange={this.handleChange}>
                                    <option value="">{'Please Select'}</option>
                                    <option value="tips">{'Tips'}</option>
                                    <option value="warning">{'Warnings'}</option>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    value={submitText}
                                    onChange={this.handleText}
                                    className="primary text-dark font-weight-bold ph-text-form"
                                    bsSize="sm"
                                    type="textarea"
                                    rows="4"
                                    placeholder="add tip or warning..."
                                />
                            </FormGroup>
                            <div className="text-right mt-3">
                                <Button className="mw" color="primary" size="sm" disabled={submitText == '' || selectedOption == null}>Submit</Button>
                                <Button className="mw" color="primary" size="sm" onClick={this.toggleTips}>Cancel</Button>
                            </div>
                        </Form>
                    </div>

                    :
                    ""}
            </React.Fragment>
        )
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        let { detailList, toggleForm, submitText, selectedOption, tipsArray, warningArray } = this.state;
        const { setCollapse } = this.props;
        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                {setCollapse ?
                    (<CollapseBasic
                        title="Tips & Warnings"
                        containerClass="bg-transparent type-secondary"
                        bodyClass="mt-2 text-secondary"
                        isOpen={false}>
                        {this.renderItemsWithOut({ detailList, toggleForm, submitText, selectedOption, tipsArray, warningArray })}
                    </CollapseBasic>) :
                    (<React.Fragment>
                        <h2 className="mr-2">Tips &amp; Warnings</h2>
                        {this.renderItemsWithOut({ detailList, toggleForm, submitText, selectedOption, tipsArray, warningArray })}
                    </React.Fragment>)}
            </div>
        )
    }
}
const mapState = (state) => ({
    my_profile_data: state.user.my_profile,
    tips_war_list: state.user.get_tw_data
});

const mapProps = (dispatch) => ({
    get_tips_warning_data: (id) => dispatch(get_tips_warning_data(id)),
    add_tips_warning_data: (data, name) => dispatch(add_tips_warning_data(data, name))
});


export default withRouter(connect(mapState, mapProps)(TipsAndWarning));