import { callApi } from "../utils/apiCaller";
// import { toast } from "react-toastify";

export const GET_TAB_LIST = "GET_TAB_LIST";
export const GET_TAB_SCROLL = "GET_TAB_SCROLL";
export const GET_RECENT_ACTIVITY = "GET_RECENT_ACTIVITY";
export const GET_MY_CONTRIBUTION = "GET_MY_CONTRIBUTION";

// Import list
export const get_tab_list_ = (data) => {
  return {
    type: GET_TAB_LIST,
    payload: data,
    name: data.type,
  };
};

export const get_tab_list = (type) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      callApi(
        `/api/search/?content_type=${type}&to_api=true&showReview=true&homePage=true&page=1&feed_tab=true`,
        "GET"
      ).then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          response["type"] = type;
          dispatch(get_tab_list_(response));
          return resolve();
        }
        return resolve();
      });
    });
    
  };
};

// Project list on scroll
export const get_tab_list_scroll_ = (data) => {
  return {
    type: GET_TAB_SCROLL,
    payload: data,
  };
};

export const get_tab_list_scroll = (type, pageNo) => {
  return (dispatch) => {
    callApi(
      `/api/search/?content_type=${type}&to_api=true&showReview=true&homePage=true&page=${pageNo}&feed_tab=true`,
      "GET"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        response["number"] = pageNo;
        dispatch(get_tab_list_scroll_(response));
      }
    });
  };
};

// Import Recent Activity
export const get_recent_activity_ = (data) => {
  return {
    type: GET_RECENT_ACTIVITY,
    payload: data,
    name: data.type,
  };
};

export const get_recent_activity = (id) => {
  return (dispatch) => {
    callApi(
      `/api/reviews/?entry=&user=${id}&asset=&home_page=true`,
      "GET"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(get_recent_activity_(response));
      }
    });
  };
};

// Import My contribution
export const get_my_contri_ = (data) => {
  return {
    type: GET_MY_CONTRIBUTION,
    payload: data,
  };
};

export const get_my_contri = () => {
  return (dispatch) => {
    callApi(`/api/userfeeds/?actor=me`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(get_my_contri_(response));
      }
    });
  };
};

//https://wikireviews.com/api/reviews/?entry=&user=1490&asset=&home_page=true
