
/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { uniqBy } from 'lodash';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    CustomInput,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Media,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Progress,
    Row,
    TabContent,
    TabPane,
    Alert
} from 'reactstrap';
import Carousel from 'react-multi-carousel';
import { fetch_connections } from "../../../../actions/connections";
import { biz_msg_create, delete_conversation, embedNewLink, fetch_all_messages, fetch_messages_by_id, remove_force_messages, replyOnMessages,restore_from_trash } from "../../../../actions/messages";
import { clear_media_dispatch, delete_mutiple_media, fetch_delete_media, fetch_media_data, fetch_taxonomy_category, media_upload_scroll, single_delete_from_upload, upload_add_captions, upload_media_files } from "../../../../actions/myProfiles";
import { change_password, my_profile, search_theater, update_email, update_my_profile } from "../../../../actions/user";
import DeleteBtn from '../../../atoms/deleteBtn';
import { callApi } from '../../../../utils/apiCaller';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import { isValidURL } from '../../../../utils/utilities';
import SearchRecipients from '../../../atoms/searchRecipients';
import SearchBar from '../../../atoms/searchBar';
import EditBtn from '../../../atoms/editBtn';


class Messages extends Component {
    constructor(props) {
        super(props)

        this.myScrollPhotoRef = React.createRef();
        this.myScrollVideoRef = React.createRef();

        this.refGalleryVideoScroll = React.createRef();
        this.refGalleryImageScroll = React.createRef();

        this.refScrollDeleteImages = React.createRef();
        this.refScrollDeleteVideos = React.createRef();

        this.messageTextAreaRef = React.createRef();

        this.state = {
            checkifClickOrLoading: true,
            viewProjectImagesModal: false,
            filterSearchText: '',
            viewMessagesType: 'inbox',
            previousMessageViewTab: 'inbox',
            composeNewMessageModal: false,
            loading: false,
            prevY: 0,
            allMessages: {},
            msgCount: {},
            allMessagesById: {},
            filterType: 'inbox',
            conversationKey: null,
            firstRender: true,
            connections: [],
            selectedIds: [],
            filteredData: [],
            searchConnections: '',
            showDropdownList: false,
            filterSelectCompose: [],
            messageText: '',
            composeMessageText: '',
            mediaSet: [],
            filteredMessagesData: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            searchTheater: '',
            fetchPhotos: {},
            fetchPhotosCount: 0,
            fetchVideos: {},
            fetchVideosCount: 0,
            albumType: '',
            fetchTaxonomy: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            uploadFailed: false,
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            dataWantsToDelete: [],
            confirmDeleteModal: false,
            limitedPostsModal: false,
            uploadMediaModal: false,
            uploadMediaPostModal: false,
            isModalTrue: false,
            modalText: null,
            modalType: null,
            modalSelected: null,
            itemsAttachment: [],
            currentIndex: 0,
            isLoadingInviteToWiki: true,
            allCheckedRecipients: false,
            composeMediaSet:[],
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            forceLoadConversation: false,

            isEditDraft: false,
            editDraftMessage: null,

        }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        let { allMessages, allMessagesById, firstRender, fetchPhotos, fetchPhotosCount, fetchVideos, fetchVideosCount, fetchGalleryScroll, fetchGalleryCount, fetchDeletedData, fetchDeleteCount, latestUploadedData, selectedIds, currentRecipient, composeMediaSet, mediaSet } = this.state;
        if('isLoading' in nextProps){
            this.setState({checkifClickOrLoading: nextProps.isLoading})
        }

        if (nextProps?.all_messages?.results?.length) {
            let data = nextProps?.all_messages?.results;

            let type = nextProps?.all_messages?.type;
            let isForceTrue = nextProps?.all_messages?.force;
            const { next, previous } = nextProps?.all_messages;
            allMessages['msg_count'] = nextProps?.all_messages?.msg_count;
            allMessages[type] = { data, next, previous };

            if (isForceTrue) {
                let conversationKey = data[0].msg_id;
                this.setState({ allMessages, conversationKey, checkifClickOrLoading: false, msgCount: nextProps?.all_messages?.msg_count }, () => {
                    if (this.state.viewMessagesType == 'draft') {
                        this.props.fetch_messages_by_id({ id: conversationKey, type: 'draft', force: false });
                    } else if (this.state.viewMessagesType == 'inbox') {
                        this.props.fetch_messages_by_id({id: conversationKey, type: 'inbox', force: false});
                    } else {
                        this.props.fetch_messages_by_id({id: conversationKey, type: 'trash', force: false});
                    }
                    this.props.remove_force_messages();
                });
            } 

            if (firstRender) {
                let conversationKey = data[0].msg_id;
                this.setState({ allMessages, conversationKey, firstRender: false, checkifClickOrLoading: false, msgCount: nextProps?.all_messages?.msg_count }, () => {
                    // if (Object.keys(allMessagesById).length == 0) {
                    if (conversationKey) {
                        if (this.state.viewMessagesType == 'draft') {
                            this.props.fetch_messages_by_id({ id: conversationKey, type: 'draft' });

                        } else if (this.state.viewMessagesType == 'inbox') {

                            this.props.fetch_messages_by_id({ id: conversationKey, type: 'inbox' });

                        } else {
                            this.props.fetch_messages_by_id({ id: conversationKey, type: 'trash' });

                        }
                    }
                    // }
                })
            } else {
                this.setState({ allMessages,  msgCount: nextProps?.all_messages?.msg_count });
            }

            this.handleClickScroll();
        } else {
            this.setState({  allMessages: {}, allMessagesById: {} });
        }


        if (nextProps.fetch_photos) {
            let item = nextProps.fetch_photos;
            if (item.album) { fetchPhotos = [] }
            fetchPhotos[item.page] = item;
            fetchPhotosCount = item.count;
            this.setState({ fetchPhotos, fetchPhotosCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_videos) {
            let item = nextProps.fetch_videos;
            if (item.album) { fetchVideos = [] }
            fetchVideos[item.page] = item;
            fetchVideosCount = item.count;
            this.setState({ fetchVideos, fetchVideosCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_taxonomy) {
            let taxCat = nextProps.fetch_taxonomy;
            if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
                taxCat = taxCat.filter(dt => dt.label !== null);
            }
            this.setState({ fetchTaxonomy: taxCat })
        }

        if (nextProps.fetch_deleted_data) {
            let item = nextProps.fetch_deleted_data;
            if (!item.type) { fetchDeletedData = {} }
            fetchDeletedData[item.page] = item;
            fetchDeleteCount = item.count;
            this.setState({ fetchDeleteCount, fetchDeletedData, activeMyPhotoIndex: 0 });
        }

        if (nextProps.upload_scroll) {
            
            let item = nextProps.upload_scroll;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({ fetchGalleryScroll, fetchGalleryCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.my_profile_info && Object.keys(nextProps.my_profile_info).length > 0) {
            let data = nextProps.my_profile_info;
            if (data?.user) {
                data['name_full'] = `${data?.user?.first_name} ${data?.user?.last_name}`
            }
            this.setState({
                myProfile: data,
            });
        }

        if (nextProps.upload_media_data) {
            let data = nextProps.upload_media_data;
            let { composeNewMessageModal } = this.state;
            let dataArray = [];
            dataArray.push(data);

            let idx = data.index;
            idx !== undefined && (latestUploadedData[idx] = data);

            if ("isUpload" in data && !data.isUpload) {
                this.setState({ uploadFailed: true });
                this.setState({ latestUploadedData });

            } else if (data.index !== undefined) {
                const tempSelectedFilesPreview = JSON.parse(JSON.stringify(this.state.selectedFilesPreview));

                if (tempSelectedFilesPreview.length) {
                    tempSelectedFilesPreview[idx].thumbnail = data.thumbnail;
                    this.setState({ selectedFilesPreview: tempSelectedFilesPreview });
                }

                this.setState({ latestUploadedData }, () => {
                    // let media = { ...this.state.latestUploadedData };
                    // media = Object.values(media);
                    // mediaSet = mediaSet.filter(val => media.findIndex( val1 => val1.id === val.id ));
                    composeNewMessageModal ? this.setState({ composeMediaSet: uniqBy([...this.state.composeMediaSet, ...dataArray]) }) : this.setState({ mediaSet: uniqBy([...this.state.mediaSet, ...dataArray]) })
                });
            }
        }

        if (nextProps?.messages_by_id && Object.keys(allMessages).length) {
            const { forceLoadConversation, messageText, mediaSet } = this.state;
            let data = nextProps?.messages_by_id;
            data['itemType'] = data['item-type'];
            let itemId = data['item-id'];
            allMessagesById[itemId] = data;
            this.setState({ allMessagesById })
            let messages_by_id = nextProps?.messages_by_id;
            const nextType = messages_by_id.itemType;
            const prevType = this.state.previousMessageViewTab;
            let placeholdingMessageText = nextType != prevType ? '' : messageText;
            if (nextType == "draft" && (nextType != prevType)) {
                placeholdingMessageText = messages_by_id.body;
            }

            let placeholdingMediaSet = nextType != prevType ? [] : mediaSet;
            if (nextType == "draft" && (nextType != prevType)) {
                placeholdingMediaSet = messages_by_id.attachments || [];
                this.setState({ mediaSetCompose: false });
            }

            if(nextType != prevType){
                this.setState({mediaSet: []});
            }

            this.setState({
                currentIndex: !this.state.currentIndex ? 0 : this.state.currentIndex,
                currentRecipient: forceLoadConversation ? currentRecipient : this.state.viewMessagesType == 'draft' ? allMessages?.draft?.data?.[0]?.recipients : this.state.viewMessagesType == 'inbox' ? allMessages?.inbox?.data?.[0]?.recipients : allMessages?.trash?.data?.[0]?.recipients,
                conversationKey: messages_by_id.msg_id,
                messageText: placeholdingMessageText,
                mediaSet: placeholdingMediaSet,
                //mediaSet: messages_by_id.itemType == "draft" ? messages_by_id.attachments : [],
            }, () => {this.setState({ forceLoadConversation: false }); this.handleClickScroll()})
        }

        /*if (nextProps?.connections?.results?.length) {
            this.setState({ connections: nextProps.connections.results });
        }*/

        if (nextProps?.connections?.results?.length) {
            let newArr = [];
            if (selectedIds && Array.isArray(selectedIds) && selectedIds.length > 0) {
                nextProps?.connections?.results.map((o1, o1Index) => {
                    selectedIds.forEach((o2, o2Index) => {
                        if (o1.id === o2.id) {
                            o1['isChecked'] = true;
                        }
                    });
                });
                newArr = nextProps?.connections?.results
            } else {
                newArr = nextProps?.connections?.results.map(v => ({ ...v, isChecked: false }))
            }
            this.setState({
                connections: newArr,
                isLoadingConnections: false,
            });
        } else {
            this.setState({ connections: [], isLoadingConnections: false });
        }

    }

    handleCaptionByIndex = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions } = this.state;

        uploadCaptions[index] = value;
        this.setState({ uploadCaptions });
    }

    componentWillUnmount() {
        this.setState({ firstRender: false });
    }

    // componentWillMount() {
    //     this.setState({
    //         checkifClickOrLoading: true
    //     });
    // }

    componentDidMount() {
        localStorage.setItem('loader', true);
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0,
        };
        //this.props.fetch_connections();
        this.props.fetch_all_messages({ type: 'inbox' });
        this.props.fetch_taxonomy_category();

        this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            options
        );
        this.observer.observe(this.loadingRef);
        // this.setState({
        //     checkifClickOrLoading: true
        // });
        
    }

    openAttachedMediaCarousal = (items, index) => {
        const carouselMedia = items;
        const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
        const array2 = (index !== carouselMedia.length - 1) ? carouselMedia.filter((item, i) => i > index) : [];
        const newArray = [carouselMedia[index], ...array2, ...array1];
        this.setState({ itemsAttachment: newArray, viewProjectImagesModal: true });
    }

    projectImageSlides = () => {
        let { itemsAttachment } = this.state;
        if (itemsAttachment && Array.isArray(itemsAttachment) && itemsAttachment.length > 0) {
            return itemsAttachment.map((item, index) => {
                let full_name = `${item?.user?.user?.first_name} ${item?.user?.user?.last_name}`;
                return (
                    <div className="item fs-14 h-100 d-flex flex-column justify-content-center" key={item.id}>
                        <div>
                            <div className="d-flex text-white mt-3">
                                <div className="d-flex align-items-center justify-content-start ff-alt">
                                    <span className='ff-base font-weight-bold'>{item?.caption}</span>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                            {
                                item.media_type == "image" && <img className="img-fluid show-media " src={this.checkAttachment(item)} alt={item.filename} />
                            }
                            {
                                item.media_type == "video" && <video className="img-fluid show-media" controls>
                                    <source src={item?.url} type="video/mp4" />
                                </video>
                            }
                        </div>
                        <div className="px-2 mt-3">
                            <div className="d-flex flex-nowrap mx-n2">
                                <div className="px-2 col d-flex flex-nowrap">
                                    <div className="mr-2">
                                        <img className="img-circle _30x30"
                                            src={item?.user?.current_profile_pic ? item?.user?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                            alt=""
                                            onError={(error) => (error.target.src = require('../../../../assets/images/icons/user-circle.png'))} />
                                    </div>
                                    <div>
                                        <div className="text-primary ff-base">
                                            Uploaded by&nbsp;<span className="font-weight-bold text-reset">{full_name}</span>
                                        </div>
                                        <div className="text-tertiary ff-base">
                                            {item?.upload_date}
                                        </div>
                                    </div>
                                </div>
                                <div className="px-2 col-auto">
                                    <h2 className="fs-38" style={{ color: '#d4dae6' }}>{index + 1}{" / "}{itemsAttachment?.length} </h2>
                                </div>
                                <div className="px-2 col">&nbsp;</div>
                            </div>
                        </div>
                            {/* <div className="col-md-12 mt-2">
                            <div className="col-md-6">
                                <div className="bottom-heading">
                                    <img
                                        src={full_name == this.state.myProfile?.name_full ? this.state.myProfile.current_profile_pic : item?.user?.current_profile_pic}
                                        style={{ width: 50, height: 50, borderRadius: '50%' }}
                                        alt=""
                                        onError={(e) => e.target.src = require('../../../../assets/images/icons/user-circle.png')}
                                    />
                                    {`Uploaded by  ${full_name}`}
                                </div>
                                <div className="days-ago">
                                    {item?.upload_date}
                                    <span className="float-right">
                                        {index + 1}
                                    </span>
                                </div>
                            </div>
                        </div> */}
                        </div>
                        

                        
                        
                    
                )
            });
        }
    }

    loadConversations = (e, item, index) => {

        let { filterType } = this.state;
        e.preventDefault();
        let conv_key = item.msg_id;

        if (item?.attachments.length) {
            this.setState({ mediaSetCompose: false });
        }

        this.setState({
            currentIndex: index,
            currentRecipient: item.recipients,
            conversationKey: conv_key,
            messageText: filterType == "draft" ? item.body : '',
            forceLoadConversation: true,
            isEditDraft: false,
            //mediaSet: filterType == "draft" ? item.attachments : [],
        }, () => {
            this.props.fetch_messages_by_id({ id: conv_key, type: filterType });
        });
    }

    handleToggleTabs = (type) => {
        this.setPreviousTabType();
        this.setState({
            // currentIndex: type == "inbox" ? 0 : -1,
            currentIndex: !type ? -1 : 0,
            viewMessagesType: type,
            filterType: type,
            allMessages: {},
            allMessagesById: {},
            checkifClickOrLoading: true,
            firstRender: true,
            isEditDraft: false,
        }, () => {
            this.props.fetch_all_messages({ type, force: true });
        });
    }

    setPreviousTabType = () =>{
        const {viewMessagesType} = JSON.parse(JSON.stringify(this.state));
        this.setState({previousMessageViewTab: viewMessagesType});
    }

    // handleSetMutipleCompose = ({ e, item }) => {
    //     e.preventDefault();
    //     let { filterSelectCompose } = this.state;

    //     if (filterSelectCompose[item] !== true) {
    //         filterSelectCompose[item] = true;
    //     } else {
    //         filterSelectCompose[item] = !filterSelectCompose[item];
    //         if (!filterSelectCompose[item]) {
    //             delete filterSelectCompose[item];
    //         }
    //     }
    //     this.setState({ filterSelectCompose });
    // }
    handleSelectId = (values) => {
        let ids = [];
        if (values && values.length) {
            ids = values.map(v => v.value);
        }
        this.setState({ filterSelectCompose: ids });
    }
    handleSubmitCompose = ({ e, draft }) => {
        e.preventDefault();
        let { filterSelectCompose, composeMessageText, composeMediaSet, filterType } = this.state;
        let recipients = filterSelectCompose;
        let filterMediaSet = composeMediaSet.map(item => item.id);
        let data = {
            "recipients": recipients,
            "body": composeMessageText,
            "messagemedia_set": filterMediaSet,
            "reply_draft": filterType == "draft" ? true : false
        };

        let compose_type = 'inbox';
        data['is_draft'] = false;
        if (draft) {
            data['is_draft'] = true;
            compose_type = 'draft';
        }

        this.props.biz_msg_create(data);
        this.handleToggleCompose();
        // if (draft) {
        this.handleToggleTabs(compose_type);
        this.setPreviousTabType();
        this.setState({
            viewMessagesType: compose_type,
            currentIndex: 0,
            firstRender: true,
        });
        // }
        if (draft) {
            this.setState({  messageText: composeMessageText });
        }
    }

    handleToggleCompose = () => {
        let { composeNewMessageModal } = this.state;
        if (composeNewMessageModal) {
            composeNewMessageModal = !composeNewMessageModal;
            this.setState({
                composeNewMessageModal,
                allCheckedRecipients: false,
                composeMessageText: '',
                selectedIds: [],
                composeMediaSet: [],
                filterSelectCompose: [],
                selectedFilesPreview: [],
                uploadFailed: false,
                searchConnections:'',
            });
        } else {
            this.setState({ composeNewMessageModal: !composeNewMessageModal });
            
        }
    }

    setMediaSetForItems = (e, item) => {
        // e.preventDefault();
        let { mediaSet, composeMediaSet, composeNewMessageModal } = this.state;
        let tempMediaSet = composeNewMessageModal ? [...composeMediaSet] : [...mediaSet];
        if (item && item.id) {
            let id = item.id;
            let itemFiltered = tempMediaSet.find(item => item.id == id);
            if (itemFiltered) {
                const index = tempMediaSet.indexOf(itemFiltered);
                if (index > -1) {
                    tempMediaSet.splice(index, 1);
                }
            } else {
                tempMediaSet.push(item)
            }
            composeNewMessageModal ? this.setState({ composeMediaSet: tempMediaSet }) : this.setState({ mediaSet: tempMediaSet });
        }
    }

    checkIsAdded = (item) => {
        let { mediaSet, composeMediaSet, composeNewMessageModal } = this.state;
        let id = item.id;
        let tempMediaSet = composeNewMessageModal ? [...composeMediaSet] : [...mediaSet];
        let itemFiltered = tempMediaSet.find(item => item.id == id);
        if (itemFiltered) {
            return true;
        } else {
            return false;
        }
    }

    removeFromMedia = (e, item) => {
        e.preventDefault();
        let { mediaSet,composeNewMessageModal, composeMediaSet } = this.state;
        let id = item.id;
        let tempMediaSet = composeNewMessageModal ? [...composeMediaSet] : [...mediaSet];
        let itemFiltered = tempMediaSet.find(item => item.id == id);
        if (itemFiltered) {
            const index = tempMediaSet.indexOf(itemFiltered);
            if (index > -1) {
                tempMediaSet.splice(index, 1);
            }
            composeNewMessageModal ? this.setState({ composeMediaSet: tempMediaSet }) : this.setState({ mediaSet: tempMediaSet })
        }
    }

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = this.state;
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0) {

                return getDataItems.map(item => {

                    let itemName = (item?.thumbnail).split('.');
                    let itemFile = (item?.file).split('.');
                    let extName = itemName[itemName.length - 1];
                    let extFile = itemFile[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview
                    item['srcFile'] = isVideo ? srcFile : item?.file;
                    return (
                        <Col xs="auto" className="mb-3" >
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={(e) => { this.setMediaSetForItems(e, item) }} checked={this.checkIsAdded(item)} />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    checkIfVideo = (item) => {
        // return false;
        let imagePreview;

        if (item.media_type === 'video') {
            imagePreview = item.thumbnail;
        }
        if (item.media_type === 'image') {
            imagePreview = item.url;
        }

        // const imagePreview = item.media_type === 'video' ? item.thumbnail : `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`
        let srcFile = imagePreview;
        if (item.file && item.file != "") {
            let itemFile = (item.file).split('.');
            let extFile = itemFile[itemFile.length - 1];


            if (['jpg', 'jpeg', 'png', 'gif'].includes(extFile)) {
                srcFile = item?.upload_date ? item.location : item.file;
            }
        }
        return srcFile;
    }

    handleCloseAndFetch = () => {
        this.setPreviousTabType();
        this.setState({ uploadMediaModal: !this.state.uploadMediaModal }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);

        });
    }

    closeComposeMediaModel = () => {
        let { latestUploadedData, mediaSet,composeMediaSet, composeNewMessageModal} = this.state;
        let latestUploadedDataArray = Object.values(latestUploadedData);
        let tempMediaSet = composeNewMessageModal ? [...composeMediaSet] : [...mediaSet];

            // mediaSet = mediaSet.filter(val => latestUploadedDataArray.findIndex(val1 => val1.id === val.id));
            tempMediaSet.forEach((item, index) => {
            let ItemIndex = latestUploadedDataArray.findIndex(b => b.id === item.id);
            tempMediaSet.splice(ItemIndex, 1)
        });

        this.setState({
            uploadMediaModal: !this.state.uploadMediaModal,
            mediaSet: composeNewMessageModal ? mediaSet : tempMediaSet,
            composeMediaSet: composeNewMessageModal ? tempMediaSet : composeMediaSet,
        });
    }

    handleOnFileUploadChange = (e) => {

        let { selectedFilesPreview, files } = this.state;
        const tempSelectedFilesPreview = JSON.parse(JSON.stringify(selectedFilesPreview));
        const filesSetForUpload = Array.from(e.target.files);
        files = files.concat(filesSetForUpload);
        let self = this;
        this.setState({ files }, () => {
            Promise.all(files.map(file => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            })).then(filebase64 => {
                filebase64.forEach(file => {
                    const thumbnail = file.includes("video/mp4") ? "" : file; // The API will give video a thumbnail, for Image we can use base64 path as thumbail.
                    tempSelectedFilesPreview.push({
                        file: file,
                        thumbnail
                    });
                })


                self.setState({ selectedFilesPreview: tempSelectedFilesPreview })
            }, error => {
                console.error(error);
            });
        });

        if (files && Array.isArray(files) && files.length > 0) {
            let indexSelected = selectedFilesPreview.length - 1;
            files.forEach(async (item, index) => {
                if (index > indexSelected) {
                    let formData = new FormData();
                    formData.append('file', item);

                    let name = item.name;
                    let split_items = name.split('.');
                    let item_ext = split_items[split_items.length - 1];
                    let isImageTrue = ['.jpg', '.png', '.jpeg', '.gif', '.tiff'].includes(item_ext);
                    if (isImageTrue) {
                        await this.props.upload_media_files('image', formData, index);
                    } else {
                        await this.props.upload_media_files('video', formData, index);
                    }
                }
            });
        }
    }

    handleChangeTaxonomy = (e) => {
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ fetchGalleryScroll: {}, fetchGalleryAlbum: value, fetchGalleryType: 'image' }, () => this.props.media_upload_scroll({ type: 'image', album: albumType, page: 1, force_update: true }))
    }

    clearMediaDispatch = () => {
        this.setState({
            latestUploadedData: {},
            uploadCaptions: {},
            selectedFilesPreview: [],
            files: [],
            uploadFailed: false
        },
            () => this.props.clear_media_dispatch()
        );
    }


    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    handleScrollGalleryVideo = () => {
        let { refGalleryVideoScroll } = this;
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    handleScrollGalleryImage = () => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    submitOnReply = (e) => {
        e.preventDefault();
        let { mediaSet, conversationKey, messageText, } = this.state;
        let data = {
            "recipients": conversationKey,
            "body": messageText,
            "messagemedia_set": mediaSet,
            "reply_draft": false
        };
        this.props.biz_msg_create(data);
    }

    toggleModal = ({ text, type, item }) => {
        this.setState({
            isModalTrue: !this.state.isModalTrue,
            modalText: text ? text : null,
            modalType: type ? type : null,
            modalSelected: item
        });
    }

    deleteConversation = ({ id, delete_thread }) => {
        let { filterType, modalType } = this.state;
        
        this.setState({ conversationKey: null, allMessagesById: {}, firstRender: true, isEditDraft: false }, () => {
            this.props.delete_conversation({ id, delete_thread, modalType, type: filterType });
            this.handleToggleTabs(filterType);
        });
    }

    handleClickOkay = () => {
        let { modalType, modalSelected, } = this.state;

        let id = modalSelected.id;

        if (modalType == 'delete-conversation') {
            this.deleteConversation({ id, delete_thread: true });
        }

        if (modalType == "delete-message") {
            this.deleteConversation({ id, delete_thread: false });
        }
        this.toggleModal({ text: null, type: null, item: null });
    }

    checkAttachment = (item) => {
        if (item.location) {
            if (item.media_type == "image") {
                let filename = item.filename.split('.');
                let ext = filename[filename.length - 1];
                let imageExts = ['jpg', 'png', 'gif', 'jpeg'];
                if (imageExts.includes(ext)) {
                    return item.location;
                }
            } else if (item.media_type == "video") {
                return item.thumbnail;
            } else {
                return item.url;
            }
        }
    }

    handleReplyMessage = (e, conversationKey, isDraft, type) => {
        e.preventDefault();
        let { mediaSet, messageText,  currentRecipient } = this.state;
        const filterMediaSet = mediaSet.map(item => item.id);
        if (filterMediaSet?.length || messageText?.length) {
            let successMessage="Message sent successfuly!";
            let data_ = "";
            let view_messages = '';

            if (type === 'Send') {
                data_ = { "body": messageText, "messagemedia_set": filterMediaSet, "recipients":currentRecipient, "reply_draft": true };
                view_messages = 'inbox';
            } else if (type === 'Save Draft') {
                successMessage = "Message saved as draft successfuly!";
                data_ = { "body": messageText, "messagemedia_set": filterMediaSet, "recipients": currentRecipient, "is_draft": true, "reply_draft": false };
                view_messages = 'draft';
            } else {
                data_ = { "body": messageText, "messagemedia_set": filterMediaSet, "recipients": currentRecipient };
                view_messages = 'inbox';
            }

            if (isDraft) {
                // data_['reply_draft'] = false;
                // data_['is_draft'] = true;
            }
            
            callApi(`/api/messages/${conversationKey}/?`, 'PUT',data_)
            .then(response => {
                if (response && response.code !== 400 && response.code !== 500) {
                    toast(successMessage, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                    this.props.fetch_messages_by_id({ id: conversationKey, type: view_messages });
                    this.setState({ mediaSet: [], messageText: '', isEditDraft: false, editDraftMessage: null });
                    this.setState({
                        currentIndex: 0,
                        firstRender: true,
                    });
                    this.handleToggleTabs(view_messages);
                }
            });

            // this.props.replyOnMessages({
            //     data: data_,
            //     id: conversationKey,
            //     type: view_messages
            // });
            //this.setState({ mediaSet: [], messageText: '' });
            

        }
    }

    onSearchFilter = (value) => {
        let { allMessages, filterType } = this.state;
        this.setState({ filterSearchText: value }, () => {
            let filteredData = [];
            if (value?.length && allMessages[filterType]?.data) {
                allMessages[filterType]?.data?.forEach(item => {
                    let tos = (item.tos).toLowerCase();
                    if (tos.search(value.toLowerCase()) > -1) {
                        filteredData.push(item);
                        this.setState({
                            filteredMessagesData: filterType =="inbox" ? uniqBy(filteredData, 'tos') : filteredData,
                            searchResultsEnabled: value?.length ? true : false,
                        })
                    }
                })
                // if (!filteredData.length) filteredData = allMessages[filterType];
            } else {
                filteredData = allMessages[filterType]?.data;
            }
            this.setState({ filteredData: filterType =="inbox" ? uniqBy(filteredData, 'tos') : filteredData })

        })
    }

    AddNewEmbedLink = (e) => {
        e.preventDefault();
        let { embeddedLink } = this.state;
        if (!isValidURL(embeddedLink)) {
            toast("Please enter a valid URL", {
                autoClose: 2500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
            });
            return;
        }
        // this.props.embedNewLink({
        //     data: {
        //         "video": `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
        //         "exclude_list": [],
        //         "body_preview": `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
        //         "question_rating_category": [],
        //         "messagemedia_set": []
        //     }
        // });
        this.setState({ embeddedLink: '' })
    }

    checkImages = (item) => {
        let { viewMessagesType } = this.state;

        if (viewMessagesType) {
            return item.current_profile_pic ? item.current_profile_pic : require('../../../../assets/images/icons/user-circle.png');
        }
        return '';
        // let items = (item.tos).split(',');
        // if (items.length > 1) {
        //     return group_icons;
        // } else {
        //     return item.current_profile_pic;
        // }
    }

    removeSelectedImage = (e, index) => {
        let { latestUploadedData, selectedFilesPreview, composeNewMessageModal, mediaSet, mediaSetCompose } = this.state;
        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        this.props.single_delete_from_upload(id);
        const tempMediaSet = composeNewMessageModal ? mediaSetCompose.filter((item) => item.id != id) : mediaSet.filter((item) => item.id != id);
        selectedFilesPreview.splice(index, 1);
        delete latestUploadedData[index];
        composeNewMessageModal ? this.setState({ composeMediaSet: tempMediaSet, selectedFilesPreview, latestUploadedData }) : this.setState({ mediaSet: tempMediaSet, selectedFilesPreview, latestUploadedData });
    }
    
    debounceSearchConnections = debounce(() => {
        this.props.fetch_connections(this.state.searchConnections, true);
    }, 500);
    
    handleChange = (e) => {

        if (e.target.value == "") {
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value,
                allCheckedRecipients: false
                //selectedIds:[]
            }, () => this.debounceSearchConnections());
        } else {
            this.setState({
                ...this.state,
                [e.target.name]: e.target.value
            }, () => this.debounceSearchConnections());
        }

    }
    
    handleSearch = (e) => {
        e.preventDefault();
        // this.props.search_actor({ search_name: this.state.searchConnections })
    }

    handleCheckRecipients = (e) => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        this.setState(prevState => {
            let { connections, allCheckedRecipients } = prevState;
            if (itemName === "selectallRecipients") {
                allCheckedRecipients = checked;
                connections.forEach(item => {
                    item.isChecked = checked
                    if (checked == true) {
                        let el = this.state.selectedIds.find((itm) => itm.id === item.id);
                        if (el)
                            this.state.selectedIds.splice(
                                this.state.selectedIds.indexOf(el),
                                1
                            );
                            this.handleSetMutipleCompose({ e, item: item.connection.id })
                        this.state.selectedIds.push(item)
                    } else {
                        this.state.selectedIds.pop();
                        this.handleSetMutipleCompose({ e, item: item.connection.id })
                    }
                });
            } else {
                connections.forEach(item => {
                    if (item.id == itemName) {
                        item.isChecked = checked
                        if (checked == true) {
                            let el = this.state.selectedIds.find(
                                (itm) => itm.id == itemName
                            );
                            if (!el) {
                                this.state.selectedIds.push(item)
                                this.handleSetMutipleCompose({ e, item: item.connection.id })
                            }
                        } else {
                            let el = this.state.selectedIds.find(
                                (itm) => itm.id == itemName
                            );
                            if (el)
                                this.state.selectedIds.splice(
                                    this.state.selectedIds.indexOf(el),
                                    1
                                );
                                this.handleSetMutipleCompose({ e, item: item.connection.id })
                        }
                    }
                })
                allCheckedRecipients = connections.every(item => item.isChecked);
            }
            this.setState({ connections, allCheckedRecipients })
        });
    };

    removeFromUploaded = (index) => {
        let { mediaSet } = this.state;
        if (index > -1) {
            mediaSet.splice(index, 1);
        }
        this.setState({ mediaSet });
    }
    truncate = (filenameString) => {
        // let split = filenameString.split(".");
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };

    handleSetEditDraftMessage = ({ message }) => {
        if (!this.state?.isEditDraft) {
            this.setState({
                isEditDraft: true,
                editDraftMessage: message,
                mediaSet: message?.attachments,
                messageText: message?.body,
            });
        } else {
            this.setState({
                isEditDraft: false,
                editDraftMessage: null,
                mediaSet: [],
                messageText: null,
            });
        }
    }
    renderChats = (chats, conversationKey) => {
        const { filterType, isEditDraft, messageText, mediaSet, mediaSetCompose } = this.state;
        return chats.map((item,index) => {         
            return (
                <li className="chat-list" id ="chatList">
                    <Media>
                        <Media className="mr-2" left>
                            <Media
                                className="img-circle _40x40"
                                object
                                src={item.sender == this.state.myProfile?.name_full ?
                                    this.state.myProfile.current_profile_pic :
                                    item.current_profile_pic}
                                onError={(error) => {
                                    error.target.src = require('../../../../assets/images/icons/user-circle.png');
                                }}
                                alt={item?.sender} />
                        </Media>
                        <Media body>
                            <div className="d-flex mx-n2">
                                <Media className="chat-title px-2" heading>
                                    <a href={`/people/${item.sender_details}`} className="text-reset">{item.sender}</a>
                                </Media>
                                <div className="text-faded col-auto px-2 ml-auto">
                                    {moment(item.sent_at).format('MMM DD, yyyy hh:mm A')}
                                </div>
                                {/* {!msg.is_deleted && !msg.is_draft && type!='trash'} */}
                                <div className="px-2 mt-n1">
                                    {
                                        filterType == "draft" ? (
                                            <EditBtn
                                                onClick={() => { this.handleSetEditDraftMessage({ message: item }) }}
                                            />
                                        ) : ""
                                    }
                                    {(filterType != "trash" ? !item.is_draft && !item.is_deleted : true) && (
                                        <DeleteBtn
                                            onClick={() => {
                                                this.toggleModal({
                                                    text: filterType === "trash" ? 'Are you sure you want to permanently delete this message?' : 'Are you sure you want to delete message?',
                                                    type: 'delete-message',
                                                    item: item
                                                })
                                            }} />
                                    )}
                                </div>
                            </div>
                            {
                                !isEditDraft ? (
                                    <>
                                        <div className="ff-base fs-14 text-dark">
                                            {item.body}
                                        </div>

                                        {item?.attachments && Array.isArray(item?.attachments) && item?.attachments?.length > 0 ?
                                            <div className="display-block p-2">
                                                {item?.attachments.map((dt_attch, index) => (
                                                    <img
                                                        className="mr-1"
                                                        onClick={() => {
                                                            this.openAttachedMediaCarousal(item?.attachments, index);
                                                        }}
                                                        src={this.checkAttachment(dt_attch)}
                                                        height={100}
                                                        width={100}
                                                        alt=""
                                                    />))}
                                            </div>
                                            : null}
                                    </>

                                ) : <Form >
                                {
                                    <>
                                        <div className="mt-2 p-2 mb-2" style={{ backgroundColor: '#fcfbf8' }}>
                                            <textarea
                                                className="primary add-comment-input not-trash col messageText"
                                                value={messageText}
                                                ref={this.messageTextAreaRef}
                                                onChange={(e) => this.handleChangeMessageText(e)}
                                                type="textarea"
                                                id="write-draft-text"
                                                placeholder="Write Something..." />
                                        </div>
                                        <div>
                                            <Row xs={2} sm={3} md={4} lg={5} form>
                                                {mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0 && !mediaSetCompose ?
                                                    mediaSet.map(item => {
                                                        return (
                                                            <Col className="mb-3">
                                                                <div className="d-flex pr-3 pt-3" role="button" onClick={() => this.setState({ viewMyPhotosModal: true })}>
                                                                    <div>
                                                                        <img className="img-fluid img-thumbnail" src={item?.thumbnail} alt="Uploaded media" />
                                                                    </div>
                                                                    <div className="mx-n3 mt-n3">
                                                                        <Button color="dark" size="sm" title="Remove Media" onClick={(e) => { this.removeFromMedia(e, item) }}>
                                                                            <FontAwesomeIcon icon="minus" />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })
                                                    : null}
                                            </Row>
                                        </div>
                                    </>
                                }
                            <div className="mb-3">
                                <div className="d-flex mx-n2">
                                    <div className="px-2">
                                        <span role="button" title="Upload media" onClick={() => {
                                            this.handleCloseAndFetch();
                                            this.setState({ mediaSetCompose: false });
                                        }}>
                                            <img src={require('../../../../assets/images/icons/feed-cam.png')} alt="" />
                                        </span>
                                    </div>                                                 
                                    <div className="px-2 ml-auto">
                                        <Button
                                            onClick={() => { this.setState({ messageText: '', mediaSet: [] }) }}
                                            color="transparent" type="button" className="text-royal-blue">
                                                {'Clear'}
                                        </Button>
                                        <Button
                                            onClick={(e) => { this.setState({ messageText: '', mediaSet: [], isEditDraft: false, editDraftMessage: null }) }}
                                            color="primary"
                                        >
                                            {'Cancel'}
                                        </Button>
                                        <Button
                                            onClick={(e) => { this.handleReplyMessage(e, conversationKey, true, 'Save Draft') }}
                                            color="primary"
                                        >
                                            {'Save Draft'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                            }
                        </Media>
                    </Media>
                </li>
            )
        })
    }

    handleChangeMessageText(e) {
        e.persist();
        this.messageTextAreaRef.current.style.height = 'auto';
        this.messageTextAreaRef.current.style.height = (e.target.scrollHeight + 5) + 'px';
        this.setState({ messageText: e.target?.value });
    }

    getMessages(next) {
        let { viewMessagesType, allMessages } = this.state;
        this.setState({ loading: true });
        callApi(`${next}`, "GET").then((res) => {
            if (res && Object.keys(res).length > 0) {
                const data = {
                    next: res.next,
                    previous: res.previous,
                    data: [...allMessages[viewMessagesType].data, ...res.results],
                };
                this.setState({ allMessages: { ...allMessages, [viewMessagesType]: data }, loading: false });
            }
        });
    }

    handleObserver(entities, observer) {
        let { viewMessagesType, allMessages, prevY, loading, checkifClickOrLoading } = this.state;
        const y = entities[0].boundingClientRect.y;
        let fetch = false;
        if (prevY === 0 && Math.abs(prevY - y) > 200) {
            fetch = true;
        } else if (prevY >= y) {
            fetch = true;
        }
        if (fetch && !loading && !checkifClickOrLoading ) {
            if (allMessages && allMessages[viewMessagesType]?.next) {
                this.getMessages(allMessages[viewMessagesType].next.split("com")[1]);
            }
        }
        this.setState({ prevY: y });
    }

    handleClickScroll = () => {
        const { viewMessagesType } = this.state;
        const element = viewMessagesType != "trash" ? document.getElementById('write-draft-text') : document.getElementById('chatList');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: "center" });
            if (this.messageTextAreaRef?.current && viewMessagesType != "trash") {
                this.messageTextAreaRef.current.style.height = 'auto';
                this.messageTextAreaRef.current.style.height = element.scrollHeight + 'px';
            }
        }
    };

    debounceSubmitCaptions = debounce((id, data) => {
        this.props.upload_add_captions(id, data);
    }, 500);

    handleSubmitCaptions = (e, index) => {
        const { latestUploadedData } = this.state
        const data = {};
        const upload_data = latestUploadedData[index];
        const caption_data = e.target.value;
        data.caption = caption_data;
        const id = upload_data.id;
        
        this.debounceSubmitCaptions(id,data);
    }

    render() {
        let {
            searchConnections,
            selectedFilesPreview,
            latestUploadedData,
            fetchTaxonomy,
            allMessages,
            filterType,
            allMessagesById,
            conversationKey,
            viewMessagesType,
            filterSelectCompose,
            messageText,
            mediaSet,
            mediaSetCompose,
            filterSearchText,
            embeddedLink,
            msgCount,
            connections,
            selectedIds,
            filteredData,
            composeMessageText,
            composeMediaSet,
            uploadFailed
        } = this.state;

        let totalLength = selectedFilesPreview.length;
        let objectKeys = [];
        let objectLength = 0;
        let calculatedPercentage = 0;
        if (Object.keys(latestUploadedData).length > 0) {
            objectKeys = Object.keys(latestUploadedData);
            objectLength = objectKeys.length;
            calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
        };
        /*let filteredData = [];
        if (filterSearchText?.length && allMessages[filterType]) {
            allMessages[filterType].forEach(item => {
                let tos = (item.tos).toLowerCase();
            
                if (tos.search(filterSearchText.toLowerCase()) > -1) {
                    filteredData.push(item);
                    this.setState({
                        filteredMessagesData: filteredData,
                    })
                }
            })
            // if (!filteredData.length) filteredData = allMessages[filterType];
        } else {
            filteredData = allMessages[filterType];
        }*/

        if (filterSearchText?.length == 0 && allMessages[filterType]?.data) {
            filteredData = allMessages[filterType]?.data;
            if (filterType == 'inbox') filteredData = uniqBy(filteredData, 'tos');
        }
        const loadingCSS = {
            height: "100px",
            margin: "30px",
        };
        const responsive = {
            videoModal: {
                mobile: {
                    breakpoint: { max: 9999999, min: 0 },
                    items: 1
                }
            },
            viewGalleryImages: {
                desktop: {
                    breakpoint: { max: 9999999, min: 1200 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1199, min: 576 },
                    items: 2
                },
                mobile: {
                    breakpoint: { max: 575, min: 0 },
                    items: 1
                }
            }
        };
        return (
            <div className="fs-14 text-dark">
                <div className="d-flex mx-n2 mb-3">
                    <div className="px-2">
                        <Nav tabs className="non-decorated-alt">
                            <NavItem>
                                <NavLink href="#"
                                    active={this.state.viewMessagesType === 'inbox'}
                                    onClick={() => { this.setState({ messageText: '' }, () => { this.handleToggleTabs('inbox') }) }}>
                                    inbox
                                    <span className="count">{msgCount?.inbox ? msgCount?.inbox : 0}</span>

                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                    active={this.state.viewMessagesType === 'draft'}
                                    onClick={() => { this.setState({ messageText: '' }, () => { this.handleToggleTabs('draft') }) }}
                                >
                                    draft
                                    <span className="count">{msgCount?.draft ? msgCount?.draft : 0}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                    active={this.state.viewMessagesType === 'trash'}
                                    onClick={() => { this.handleToggleTabs('trash') }}>
                                    trash
                                    <span className="count">{msgCount?.trash ? msgCount?.trash : 0}</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div className="ml-auto col-auto px-2">
                        <div className="d-flex">
                            <Button color="primary" size="sm" onClick={() => this.setState({ composeNewMessageModal: true, composeMessageText: '', mediaSetCompose: true, composeMediaSet: [] })}>Compose</Button>
                            <div className='ml-2'>
                                <SearchBar
                                    handleSearchSubmit={this.onSearchFilter}
                                    searchResultsEnabled={this.state.searchResultsEnabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.checkifClickOrLoading ? <div style={{ width: "100%" }}><Alert color="primary">
                        Loading...
                    </Alert></div> : (
                        <TabContent activeTab={viewMessagesType}>
                            <TabPane tabId={viewMessagesType}>
                                <Row>
                                    <Col md={4}>
                                        <ul className="list-unstyled">
                                                {filteredData && Array.isArray(filteredData) && filteredData.length ?
                                                    filteredData.map((item, index) => (
                                                    <li
                                                        className={this.state.currentIndex == index ? "chat-room active" : 'chat-room'}
                                                        role="button"
                                                        onClick={(e) => { this.loadConversations(e, item, index) }}>
                                                        <Media>
                                                            <Media className="mr-2" left href="#">
                                                                <Media
                                                                    className="img-circle _50x50"
                                                                    object
                                                                    src={this.checkImages(item)}
                                                                    onError={(error) => {
                                                                        error.target.src = require('../../../../assets/images/icons/user-circle.png')   
                                                                    }}
                                                                    alt="User" />
                                                            </Media>
                                                            <Media body>
                                                                <div className="d-flex mx-n2 mb-2">
                                                                    <Media className="chat-title px-2" heading>
                                                                        {item.tos}
                                                                    </Media>
                                                                    <div className="col-auto px-2 ml-auto">
                                                                        <div className="chat-title">
                                                                            {filterType == 'trash' ? (item.is_draft ? `[Draft]` : `[Inbox]`) : null}
                                                                        </div>
                                                                        <div className="text-faded mb-1">
                                                                            {moment(item.updated_on).format('MMM DD')}
                                                                        </div>
                                                                        {/* {'Outer Trash'} */}
                                                                        <div className="text-center text-tertiary">
                                                                            {filterType == 'trash' ? (
                                                                                !item.is_deleted
                                                                                    ? (<span className="cursor-default">
                                                                                        <FontAwesomeIcon icon="history" />
                                                                                    </span>)
                                                                                    : (<FontAwesomeIcon
                                                                                        icon="history"
                                                                                        title='Restore message'
                                                                                        onClick={() => {
                                                                                            this.props.restore_from_trash({
                                                                                                id: item.id,
                                                                                                data: { "restore_message": true },
                                                                                                type: this.state.filterType
                                                                                            });
                                                                                            this.handleToggleTabs(this.state.filterType);
                                                                                        }} />)
                                                                            ) : (
                                                                                <DeleteBtn
                                                                                    onClick={() => {
                                                                                        this.toggleModal({
                                                                                            text: filterType == "draft" ? 'Are you sure you want to delete message?' : 'Are you sure you want to delete the conversation?',
                                                                                            type: 'delete-conversation',
                                                                                            item: item
                                                                                        })
                                                                                    }} />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Media>
                                                        </Media>
                                                    </li>
                                                )) :
                                                (filterSearchText?.length ?
                                                    <li className="text-center">
                                                        <div className="no-records">
                                                        {'No search records found'}
                                                        </div>
                                                    </li> : null)
                                            }

                                        </ul>
                                    </Col>
                                    {Object.keys(allMessagesById).length && allMessagesById[conversationKey] && allMessagesById[conversationKey]['itemType'] == viewMessagesType ? (
                                        <Col md={8}>
                                            <div className="mb-2 text-dark font-weight-bold">
                                                {'Conversations'}
                                            </div>

                                            {allMessagesById[conversationKey] &&
                                                allMessagesById[conversationKey].conversation &&
                                                Object.keys(allMessagesById[conversationKey].conversation).length ?
                                                Object.keys(allMessagesById[conversationKey].conversation).reverse().map(key => (
                                                    <>
                                                        {viewMessagesType == 'draft' && allMessagesById[conversationKey].conversation[key].filter(i => i.is_draft)?.length > 0 &&
                                                            <div className="bg-white-shaded-md p-3 mb-2">
                                                                <div className="text-center">
                                                                {moment(key).format('MMM DD, yyyy')}
                                                                </div>
                                                                <ul className="list-unstyled">
                                                                    {this.renderChats(allMessagesById[conversationKey].conversation[key].filter(i => i.is_draft), conversationKey)}
                                                                </ul>
                                                            </div>
                                                        }
                                                        {
                                                            viewMessagesType == "inbox" && allMessagesById[conversationKey].conversation[key].filter(i => !i.is_draft)?.length > 0 &&
                                                            <div className="bg-white-shaded-md p-3 mb-2">
                                                                <div className="text-center">
                                                                    {moment(key).format('MMM DD, yyyy')}
                                                                </div>

                                                                <ul className="list-unstyled">
                                                                    {this.renderChats(allMessagesById[conversationKey].conversation[key].filter(i => !i.is_draft))}
                                                                </ul>
                                                            </div>
                                                        }
                                                        {
                                                            viewMessagesType == "trash" && 
                                                            <div className="bg-white-shaded-md p-3 mb-2">
                                                                <div className="text-center">
                                                                    {moment(key).format('MMM DD, yyyy')}
                                                                </div>

                                                                <ul className="list-unstyled">
                                                                    {this.renderChats(allMessagesById[conversationKey].conversation[key])}
                                                                </ul>
                                                            </div>
                                                        }
                                                    </>
                                                ))
                                                : null}

                                            {filterType !== 'trash'  ?
                                                <Form >
                                                        {
                                                            filterType !== 'draft' && (<>

                                                                <div className="mt-4 p-3 mb-3" style={{ backgroundColor: '#fcfbf8' }}>
                                                                    <textarea
                                                                        className="primary add-comment-input not-trash col messageText"
                                                                        value={messageText}
                                                                        ref={this.messageTextAreaRef}
                                                                        onChange={(e) => this.handleChangeMessageText(e)}
                                                                        type="textarea"
                                                                        id="write-draft-text"
                                                                        placeholder="Write Something..." />
                                                                </div>
                                                                <div>
                                                                    <Row xs={2} sm={3} md={4} lg={5} form>

                                                                        {mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0 && !mediaSetCompose ?
                                                                            mediaSet.map(item => {
                                                                                return (
                                                                                    <Col className="mb-3">
                                                                                        <div className="d-flex pr-3 pt-3" role="button" onClick={() => this.setState({ viewMyPhotosModal: true })}>
                                                                                            <div>
                                                                                                <img className="img-fluid img-thumbnail" src={item?.thumbnail} alt="Uploaded media" />
                                                                                            </div>
                                                                                            <div className="mx-n3 mt-n3">
                                                                                                <Button color="dark" size="sm" title="Remove Media" onClick={(e) => { this.removeFromMedia(e, item) }}>
                                                                                                    <FontAwesomeIcon icon="minus" />
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                )
                                                                            })
                                                                            : null}
                                                                    </Row>
                                                                </div>

                                                            </>)
                                                        }
                                                    

                                                    <div className="mb-3">
                                                        <div className="d-flex mx-n2">
                                                            {
                                                                filterType !== "draft" && (<div className="px-2">
                                                                    <span role="button" title="Upload media" onClick={() => {
                                                                        this.handleCloseAndFetch();
                                                                        this.setState({ mediaSetCompose: false });
                                                                    }}>
                                                                        <img src={require('../../../../assets/images/icons/feed-cam.png')} alt="" />
                                                                    </span>
                                                                </div>)
                                                            }                                                           
                                                            <div className="px-2 ml-auto">
                                                                {
                                                                    filterType !== 'draft' && (
                                                                        <Button
                                                                            onClick={() => { this.setState({ messageText: '', mediaSet: [] }) }}
                                                                            color="transparent" type="button" className="text-royal-blue">{'Clear'}</Button>
                                                                    )
                                                                }
                                                                
                                                                {/*{ const is_draft = filterType == "draft" ? true : false }*/}
                                                                <Button
                                                                    onClick={(e) => {
                                                                        this.handleReplyMessage(e, conversationKey, filterType == "draft" ? true : false, filterType == "draft" ? 'Send' : allMessagesById[conversationKey] &&
                                                                            allMessagesById[conversationKey].conversation &&
                                                                            Object.keys(allMessagesById[conversationKey].conversation).length ? 'Reply' : 'Send')
                                                                    }}
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={((messageText && messageText.trim().length) || mediaSet?.length || filterType == "draft") ? false : true}>
                                                                    {filterType == "draft" ? 'Send' : allMessagesById[conversationKey] &&
                                                                        allMessagesById[conversationKey].conversation &&
                                                                        Object.keys(allMessagesById[conversationKey].conversation).length ? 'Reply' : 'Send'}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                                : null}
                                        </Col>
                                    ) : this.state.checkifClickOrLoading == false && Object.keys(allMessages).length == 0 ? (
                                        <div className="col-md-12 text-center bg-white p-3">
                                            <h2 className="text-secondary-dark">No Record Found</h2>
                                        </div>
                                    ) :
                                        null}
                                </Row>
                            </TabPane>
                        </TabContent>
                    )
                }

                {/* Pagination Loader */}
                <div
                    ref={(loadingRef) => (this.loadingRef = loadingRef)}
                    style={loadingCSS}
                >
                    {(!this.state.checkifClickOrLoading && this.state.loading) && <Alert color="primary">
                        Loading...
                    </Alert>}
                </div>

                {/* Compose Message Modal */}
                <Modal
                    className="custom-pop-change"
                    centered
                    isOpen={this.state.composeNewMessageModal}
                    toggle={this.handleToggleCompose}>
                    <ModalHeader className="px-0" toggle={this.handleToggleCompose}>
                        {'COMPOSE NEW MESSAGE'}
                    </ModalHeader>
                    <ModalBody className="wk-compose-new-messagebody">
                        <div style={{ minHeight: '100px' }}>
                            <div className="fs-16 text-dark font-weight-bold mb-2">{'Recipients'}</div>
                            <SearchRecipients
                                myProfile={this.props.my_profile_info}
                                handleSelectId={this.handleSelectId}
                            /> 
                            {/* <div
                            // style={{ maxHeight: '130px', overflowY: 'auto' }}
                            >
                                <Row className="mb-0"
                                    xs={2} lg={3}
                                >
                                    {selectedIds && Array.isArray(selectedIds) && selectedIds.length > 0 ?
                                        selectedIds.map((item) => (
                                            <Col className="mb-2">
                                                <FormGroup check>
                                                    <Label
                                                        onClick={(e) => { this.handleSetMutipleCompose({ e, item: item.connection.id }) }}
                                                        className="fs-14 text-dark font-weight-bold" check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={item?.connection?.id && this.state.filterSelectCompose.hasOwnProperty(item?.connection?.id) ? true : false}
                                                        />{' '}
                                                        <span className="overlap-checkbox">
                                                            {`${item.connection.user.first_name} ${item.connection.user.last_name}`}
                                                        </span>
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        )) :
                                        <div className="bg-white px-3 py-1">
                                            <span className="text-dark font-weight-bold">No recipients selected</span>
                                        </div>}
                                </Row>
                            </div>
                            <div className="border-bottom mt-4 ">
                                <UncontrolledDropdown className="dropdown-sm d-inline-block mb-3">
                                    <DropdownToggle
                                        color="white"
                                        className="mw"
                                        size="sm"
                                        caret
                                    >
                                        Choose Recipients
                                    </DropdownToggle>
                                    <DropdownMenu className="force-down p-2">
                                        <div className="position-relative" style={{ width: '500px' }}>
                                            <Input bsSize="sm"
                                                type="search"
                                                name="searchConnections"
                                                placeholder="Search for Recipients"
                                                value={searchConnections}
                                                onChange={this.handleChange}
                                            />
                                            <div className="px-2">
                                                <Row form>
                                                    <Col xs={{ size: "auto", order: 2 }} lg={{ order: 1 }} className="align-self-center">
                                                        <CustomInput
                                                            type="checkbox"
                                                            id={"selectallRecipients"}
                                                            name="selectallRecipients"
                                                            checked={this.state.allCheckedRecipients}
                                                            onChange={this.handleCheckRecipients}
                                                        >
                                                            <Label
                                                                for={"selectallRecipients"}
                                                                className="text-dark font-weight-normal fs-14"
                                                            >
                                                                {this.state.allCheckedRecipients ? "Deselect All" : "Select All"}
                                                            </Label>
                                                        </CustomInput>
                                                    </Col>
                                                </Row>
                                                <div
                                                    style={{
                                                        maxHeight: "calc(200px)",
                                                        overflowY: "auto"
                                                    }}
                                                >
                                                    {connections && Array.isArray(connections) && connections.length > 0 ?
                                                        connections.map((item, index) => {
                                                            return (
                                                                <CustomInput
                                                                    type="checkbox"
                                                                    id={"Recipients" + index}
                                                                    key={index}
                                                                    name={item?.id || "searchConnections"}
                                                                    checked={selectedIds && Array.isArray(selectedIds) && selectedIds.length > 0 && item?.isChecked}
                                                                    onChange={this.handleCheckRecipients}
                                                                    value={item?.id}
                                                                >
                                                                    <Label
                                                                        for={"Recipients" + index}
                                                                        className="text-dark font-weight-normal fs-14"
                                                                    >{`${item.connection.user.first_name} ${item.connection.user.last_name}`}
                                                                    </Label>
                                                                </CustomInput>
                                                            )

                                                        })
                                                        : (
                                                            <div className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">
                                                                    No Recipients to Display
                                                                </h2>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>

                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                {/* old */}
                                {/* <Form
                                    hidden
                                // onSubmit={this.handleSearch}
                                >
                                    <FormGroup className="position-relative">
                                        <Input bsSize="sm"
                                            type="search"
                                            name="searchConnections"
                                            placeholder="Search for Recipients"
                                            value={searchConnections}
                                            onChange={this.handleChange}
                                        />

                                        {true ?

                                            <div className="bg-white p-2 text-dark recipients-list">
                                                <Row xs={2} lg={3} form>
                                                    {connections && Array.isArray(connections) && connections.length > 0 ?
                                                        connections.map((item) => (
                                                            <Col className="mb-2">
                                                                <FormGroup check>
                                                                    <Label
                                                                        onClick={(e) => { this.handleSetMutipleCompose({ e, item: item.connection.id }) }}
                                                                        className="fs-14 text-dark font-weight-bold" check>
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={item?.connection?.id && this.state.filterSelectCompose.hasOwnProperty(item?.connection?.id) ? true : false}
                                                                        />{' '}
                                                                        <span className="overlap-checkbox">
                                                                            {`${item.connection.user.first_name} ${item.connection.user.last_name}`}
                                                                        </span>
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        )) :
                                                        <div className="bg-white px-3 py-1">
                                                            <span className="text-dark font-weight-bold">No recipients found</span>
                                                        </div>}
                                                </Row>
                                            </div>
                                            :
                                            null
                                        }
                                    </FormGroup>
                                </Form>
                            </div> */} 
                        </div>
                        <FormGroup>

                            <Input
                                className="primary"
                                id="write-message"
                                type="textarea"
                                name="write_message"
                                rows="4"
                                onChange={(e) => { this.setState({ composeMessageText: (e.target.value) }) }}
                                placeholder="Write something..."
                                value={composeMessageText}
                            />
                        </FormGroup>

                        <div className="mb-2">
                            <span className="d-inline-block" role="button" title="Upload media" onClick={this.handleCloseAndFetch}>
                                <img src={require('../../../../assets/images/icons/feed-cam.png')} alt="" />
                            </span>
                        </div>

                        {/* Uploaded media shown here */}
                        <div className="border mb-3 p-2">
                            <Row xs={2} sm={3} md={4} lg={4}>
    
                                {composeMediaSet && Array.isArray(composeMediaSet) && composeMediaSet.length > 0 && mediaSetCompose ?
                                    composeMediaSet.map((item, index) => {

                                        return (
                                            <Col key={item.id} className="mb-3">
                                                <div className="d-flex">
                                                    <div style={{ width: '112px' }}>
                                                        {(item.type === "image" ||
                                                            item.media_type === "image" || item.type === "video" ||
                                                            item.media_type === "video") && (
                                                                <div className="gallery-media">
                                                                    <img
                                                                        className="img-fluid img-thumbnail"
                                                                        src={item.thumbnail}
                                                                        alt={
                                                                            item.filename
                                                                                ? item.filename.length < 20
                                                                                    ? item.filename
                                                                                    : this.truncate(item.filename)
                                                                                : item.name
                                                                                    ? item.name.length < 20
                                                                                        ? item.name
                                                                                        : this.truncate(item.name)
                                                                                    : ""
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                    </div>
                                                    {/* <div>
                                                        <img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
                                                    </div> */}
                                                    <div className="mx-n3 mt-2">
                                                        <Button color="delete-media _alt" size="sm" title="Remove Media" onClick={(e) =>
                                                            this.removeFromMedia(e,item)
                                                        }><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                        // return (
                                        //     <>
                                        //         {item.file && item.file != "" ?
                                        //             <Col>
                                        //                 <div className="d-flex pr-3 m-2" role="button" onClick={() => this.setState({ viewMyPhotosModal: true })}>
                                        //                     <div>
                                        //                         <img className="img-fluid img-thumbnail" src={this.checkIfVideo(item)} alt="Uploaded media" />
                                        //                     </div>
                                        //                     <div className="mx-n3 mt-2">
                                        //                         <Button color="delete-media" size="sm" title="Remove Media" onClick={(e) => { this.removeFromMedia(e, item) }}>
                                        //                             <FontAwesomeIcon icon="trash-alt" />
                                        //                         </Button>
                                        //                     </div>
                                        //                 </div>
                                        //             </Col>
                                        //             :

                                        //             <Col xs={12} sm={12} md={12} lg={12}>
                                        //                 <div className="text-danger">
                                        //                     <span >{item.msg}</span>
                                        //                 </div>
                                        //             </Col>
                                        //         }
                                        //     </>
                                        // )
                                    })
                                    : null}
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-start px-0">
                        <Button
                            onClick={() => {
                                this.setState({
                                    allCheckedRecipients: false,
                                    composeMessageText: '',
                                    selectedIds: [],
                                    composeMediaSet: [],
                                    filterSelectCompose: [],
                                    selectedFilesPreview: [],
                                    uploadFailed: false
                                });
                            }}
                            color="link"
                            className="text-white font-weight-bold text-decoration-none">
                            {'Clear'}
                        </Button>
                        <Button
                            disabled={(composeMessageText && composeMessageText?.trim().length && filterSelectCompose.length) ? false : true}
                            color="primary"
                            className="mw ml-auto"
                            onClick={(e) => { this.handleSubmitCompose({ e, draft: true }); }}>
                            {'Save Draft'}
                        </Button>
                        <Button
                            disabled={(composeMessageText && composeMessageText?.trim().length && filterSelectCompose.length) ? false : true}
                            color="primary"
                            className="mw"
                            onClick={(e) => { this.handleSubmitCompose({ e, draft: false }); }}>
                            {'Send'}
                        </Button>
                    </ModalFooter>
                </Modal >
                {/* {'--------------------------------------------------------------------------------------------------------------------------'} */}
                < Modal
                    isOpen={this.state.uploadMediaModal}
                    toggle={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal }, () => { this.clearMediaDispatch() })
                    }>
                    <ModalHeader toggle={() => { this.closeComposeMediaModel() }}>UPLOAD MEDIA</ModalHeader>
                    <ModalBody className="p-3">
                        <Row>
                            <Col xs={"auto"}>
                                <div className="bg-light h-100 p-3">
                                    <Nav tabs className="flex-column active-transparent border-0 m-0">
                                        <NavItem className="text-left">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "upload"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "upload" })
                                                }}
                                            >
                                                Upload
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "gallery"}
                                                onClick={() => {
                                                    this.setState({ showGalleryType: "images", selectedUpMediaType: "gallery" }, () => { this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true }) })

                                                }}
                                            >
                                                Gallery
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        {/* <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "embed"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "embed" })
                                                }}
                                            >
                                                Embed
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>
                                </div>
                            </Col>
                            <Col>
                                <TabContent activeTab={this.state.selectedUpMediaType}>
                                    <TabPane tabId="upload">
                                        {selectedFilesPreview && selectedFilesPreview.length == 0 ?
                                            <div className="mb-3 type-file-block bg-light">
                                                <Input
                                                    type="file"
                                                    name="upload_media_file"
                                                    id="uploadFilesFromSystem"
                                                    accept="image/*, video/*"
                                                    onChange={this.handleOnFileUploadChange}
                                                    multiple
                                                />
                                                <Label for="uploadFilesFromSystem">
                                                    <div className="type-bg"></div>
                                                </Label>
                                            </div>
                                            : null}
                                        <div
                                            className="text-right"
                                            style={{
                                                maxWidth: "120px",
                                                margin: "0 1rem 1rem auto",
                                            }}>
                                            <div className="text-center mb-1 small">
                                                {parseInt(calculatedPercentage) === 100 ? (
                                                    <div className="text-success">
                                                        <FontAwesomeIcon
                                                            icon="check-circle"
                                                            className="mr-1"
                                                        />{" "}
                                                        {'Uploaded'}
                                                    </div>
                                                ) : (
                                                    selectedFilesPreview.length > 0 && (
                                                        <div>
                                                            {
                                                                !uploadFailed ?
                                                                <>
                                                                  {` Uploading `}
                                                            <span className="text-success font-weight-bold ff-base">
                                                                {calculatedPercentage ? `${parseInt(calculatedPercentage)} %` : ''}
                                                            </span>
                                                                </>
                                                                :
                                                                <>
                                                                {` Upload Failed `}
                                                                </>
                                                            }
                                                          
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {selectedFilesPreview.length > 0 && (
                                                <Progress
                                                    value={parseInt(calculatedPercentage)}
                                                    style={{ height: "8px" }}
                                                />
                                            )}
                                        </div>

                                        <Row>
                                            {selectedFilesPreview && selectedFilesPreview.length > 0 ?
                                                selectedFilesPreview.map((item, index) => (
                                                    <Col className="mb-3" xs="auto" key={index}>
                                                        <div className="text-center bg-light p-2">
                                                            <div className="d-inline-flex pr-3">
                                                                <div>
                                                                    <div
                                                                        className="selectable-media"
                                                                        style={{ cursor: "default" }}
                                                                    >
                                                                        <div className="gallery-media">
                                                                            <img src={item.thumbnail} alt={`Loading...`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mx-n3 mt-2">
                                                                    <Button
                                                                        color="delete-media _alt"
                                                                        size="sm"
                                                                        title="Remove Media"
                                                                        onClick={(e) => this.removeSelectedImage(e, index)}>
                                                                        <FontAwesomeIcon icon="trash-alt" />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {latestUploadedData &&
                                                                latestUploadedData[index] &&
                                                                (latestUploadedData[index]['isUpload'] !== undefined || latestUploadedData[index]['isUpload'] !== null) ?
                                                                latestUploadedData[index]['isUpload'] ? (
                                                                    <div className="mt-2">
                                                                        <InputGroup style={{ maxWidth: '160px' }}>
                                                                            <Input bsSize="sm" type="text" name="caption" className="caption-input" placeholder="Caption" onChange={(e) => this.handleSubmitCaptions(e, index)} />
                                                                            {/* <InputGroupAddon addonType="append">
                                                                                <Button size="sm" color="secondary" title="Upload" onClick={(e) => this.handleSubmitCaptions(e, index)} >
                                                                                    <FontAwesomeIcon icon="arrow-right" />
                                                                                </Button>
                                                                            </InputGroupAddon> */}
                                                                        </InputGroup>
                                                                    </div>
                                                                ) : (
                                                                    <div className="mt-2 text-center">
                                                                        <small className="text-danger">Error while uploading</small>
                                                                    </div>
                                                                ) : (
                                                                    <div className="mt-2 text-center">
                                                                        <small className="text-info">Uploading...</small>
                                                                    </div>
                                                                )}

                                                        </div>
                                                    </Col>
                                                ))
                                                : null}
                                        </Row>
                                        <div className="d-flex justify-content-center mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.handleCloseAndFetch() }}>
                                                    OK
                                                </Button>
                                            </div>
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.closeComposeMediaModel() }}>
                                                    Cancel
                                                </Button>
                                            </div>    
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="gallery">
                                        <div className="mb-2">
                                            <Nav tabs className="d-inline-flex mb-0">
                                                <NavItem>
                                                    <NavLink href="#"
                                                        active={this.state.showGalleryType === "images"}
                                                        onClick={() => {
                                                            this.setState({ showGalleryType: "images", fetchGalleryType: 'image', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                                this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true })
                                                            })
                                                        }}>
                                                        {'Image Gallery'}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink href="#"
                                                        active={this.state.showGalleryType === "videos"}
                                                        onClick={() => {
                                                            this.setState({ showGalleryType: "videos", fetchGalleryType: 'video', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                                this.props.media_upload_scroll({ type: 'video', album: '', page: 1, force_update: true })
                                                            })
                                                        }}>
                                                        {'Video Gallery'}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={this.state.showGalleryType}>
                                            <TabPane tabId="images">
                                                <div>
                                                    <FormGroup className="form-row justify-content-end">
                                                        <Col xs="auto">
                                                            <Label className="small" for="selectAlbumType">Album Type:</Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Input
                                                                onChange={this.handleChangeTaxonomy}
                                                                bsSize="sm"
                                                                type="select"
                                                                name="album_type_select"
                                                                id="selectAlbumType"
                                                                defaultValue="all">
                                                                <option value="all">All</option>
                                                                {fetchTaxonomy &&
                                                                    Array.isArray(fetchTaxonomy) &&
                                                                    fetchTaxonomy.length > 0 ?
                                                                    fetchTaxonomy.map((item, index) => (
                                                                        <option key={index} value={item.category}>{item.label}</option>
                                                                    ))
                                                                    : null}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    ref={this.refGalleryImageScroll}
                                                    onScroll={this.handleScrollGalleryImage}
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                    <Row>
                                                        {this.fetchScrollPhotoMedia()}
                                                    </Row>
                                                </div>
                                                <div className="d-flex justify-content-center mx-n2">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'Ok'}
                                                        </Button>
                                                    </div>
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'Cancel'}
                                                        </Button>
                                                    </div>    
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="videos">
                                                <div
                                                    ref={this.refGalleryVideoScroll}
                                                    onScroll={this.handleScrollGalleryVideo}
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                    <Row>
                                                        {this.fetchScrollPhotoMedia(true)}
                                                    </Row>
                                                </div>
                                                <div className="d-flex justify-content-center mx-n2">
                                                    
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'Ok'}
                                                        </Button>
                                                    </div>
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'Cancel'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </TabPane>
                                    <TabPane tabId="embed">
                                        <div className="mb-2">
                                            {/* Repeat this while adding */}
                                            <div className="d-flex mx-n2 mb-2" key={0}>
                                                <div className="flex-fill px-2">
                                                    <Input
                                                        type="textarea"
                                                        bsSize="sm"
                                                        className="embed-link-form-control"
                                                        value={embeddedLink}
                                                        onChange={(e) => { this.setState({ embeddedLink: e.target.value }) }}
                                                        placeholder="Submit the link for the image or video you want to embed"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.setState({ embeddedLink: '', uploadMediaModal: false }) }} >
                                                    {'Cancel'}
                                                </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={(e) => { this.AddNewEmbedLink(e) }}>
                                                    {'Insert'}
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal >

                <Modal
                    size="sm"
                    isOpen={this.state.isModalTrue}
                    toggle={() => this.toggleModal({ text: null, type: null, item: null })}
                    className="text-center delete-box" modalClassName="backdrop-opaque top-shifted transition-none">
                    <div className="bg-white modal-header">
                        <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
                        <button
                            className="btn"
                            aria-label="Close"
                            onClick={() => {
                                this.toggleModal({ text: null, type: null, item: null })
                            }}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark">
                        {this.state.modalText}
                    </ModalBody>
                    <ModalFooter className="bg-white">
                        <div className="text-center w-100">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={() => this.toggleModal({ text: null, type: null, item: null })}
                            >
                                {'Cancel'}
                            </Button>
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={this.handleClickOkay}
                            >
                                {'Ok'}
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>


                {/* View Project Images Modal Popup */}
                <Modal
                    size="lg"
                    isOpen={this.state.viewProjectImagesModal}
                    toggle={() => this.setState({ viewProjectImagesModal: !this.state.viewProjectImagesModal })}
                >
                    <ModalHeader toggle={() => this.setState({ viewProjectImagesModal: !this.state.viewProjectImagesModal })}></ModalHeader>
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={this.state.itemsAttachment?.length > 1 ? true : false}
                            showDots={false}
                            arrows={this.state.itemsAttachment?.length > 1 ? true : false}
                            sliderClass={this.state.itemsAttachment?.length === 0 ? "mx-auto" : ""}>
                            {this.projectImageSlides()}
                        </Carousel>
                    </ModalBody>
                </Modal>
            </div >
        )
    }
}

const mapState = (state) => ({
    all_messages: state.messages.all_messages,
    isLoading: state.messages.isLoading,
    messages_by_id: state.messages.messages_by_id,
    connections: state.connections.connections,
    fetch_photos: state.myProfile.fetch_photos,
    fetch_videos: state.myProfile.fetch_videos,
    fetch_taxonomy: state.myProfile.fetch_taxonomy,
    upload_scroll: state.myProfile.upload_scroll,
    upload_media_data: state.myProfile.upload_media_data,
    fetch_deleted_data: state.myProfile.fetch_deleted_data,
    my_profile_info: state.user.my_profile,
    search_theater_info: state.user.search_theater,
});

const mapProps = (dispatch) => ({
    biz_msg_create: (data) => dispatch(biz_msg_create(data)),
    fetch_all_messages: ({ type, force }) => dispatch(fetch_all_messages({ type, force })),
    fetch_messages_by_id: ({ id, type, }) => dispatch(fetch_messages_by_id({ id, type })),
    fetch_connections: (query, search) => dispatch(fetch_connections(query, search)),
    my_profile: () => dispatch(my_profile()),
    fetch_media_data: (type, album, page, user_profile) => dispatch(fetch_media_data(type, album, page, user_profile)),
    update_my_profile: (data, entity) => dispatch(update_my_profile(data, entity)),
    update_email: (data) => dispatch(update_email(data)),
    fetch_taxonomy_category: () => dispatch(fetch_taxonomy_category()),
    search_theater: (data) => dispatch(search_theater(data)),
    media_upload_scroll: ({ type, album, page, force_update }) => dispatch(media_upload_scroll({ type, album, page, force_update })),
    fetch_delete_media: ({ type, page }) => dispatch(fetch_delete_media({ type, page })),
    upload_media_files: (type, data, index) => dispatch(upload_media_files(type, data, index)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    clear_media_dispatch: () => dispatch(clear_media_dispatch()),
    delete_mutiple_media: ({ type, data }) => dispatch(delete_mutiple_media({ type, data })),
    single_delete_from_upload: (id) => dispatch(single_delete_from_upload(id)),
    change_password: (data) => dispatch(change_password(data)),
    delete_conversation: ({ id, delete_thread, type }) => dispatch(delete_conversation({ id, delete_thread, type })),
    replyOnMessages: ({ data, id, type }) => dispatch(replyOnMessages({ data, id, type })),
    embedNewLink: ({ data }) => (dispatch(embedNewLink({ data }))),
    remove_force_messages: () => dispatch(remove_force_messages()),
    restore_from_trash: ({ id, data, type }) => dispatch(restore_from_trash({ id, data, type })),
});


export default connect(mapState, mapProps)(Messages);
