/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Container, Row, Col, } from 'reactstrap';
import { fetch_collage_items, clear_collage_sub_categories } from '../../../actions/business'
import { connect } from "react-redux";
import { withRouter } from "react-router";

class CollageItems extends Component {
    constructor(props) {
        super(props)

        this.state = {
            toggleBlueBanner: false
        }
    }


    getByCategory = (item) => {
        this.setState({ setTaxonomyCategory: item.taxonomy_list[0]?.category, toggleBlueBanner: true }, () => {
            let id = item?.taxonomy_list[0]?.id;
            this.props.fetch_collage_items({ content_type: 'business', catTrending: 'True', subcat: id, trendingType: 'national', size: 6 });
        })
    }

    renderOneItem = ({ item, taxonomy }) => {
        let stringReview = '';
        if (item?.latestreview?.review) {
            stringReview = item?.latestreview?.review;
            stringReview = stringReview.substring(3, stringReview.length - 4);
        }
        
        let image_bg = '';
        if(item?.listing_profileimage && Array.isArray(item?.listing_profileimage) && item?.listing_profileimage.length){
            let filteredItem = item?.listing_profileimage.find(image => image?.type == "image");
            if(filteredItem){
                image_bg = filteredItem.location;
            }
        }

      


        return (
            <div className="d-flex flex-column col">
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.history.push({
                            pathname: `/${(item?.content_type).toLowerCase()}/${item?.id ? item?.id : 'null'}`,
                            state: { id: item?.id }
                        });
                    }}
                    className="flex-grow-1 featured-item" style={{ overflow: 'hidden' }}>
                    <img
                        className="feature-project-image _full"
                        src={image_bg}
                        alt={item?.name || item?.title} />
                    {taxonomy ? (
                        <div className="featured-details hoverable">
                            <a href="#" className="text-reset mb-2 d-block text-decoration-none">
                                <h2 className="fs-25 text-uppercase">{item?.name || item?.title}</h2>
                            </a>
                            <div className="mt-2">
                                <img
                                    title={`${(item?.total_rating?.avg_rating[1] || item?.average_rating[1])}`}
                                    src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0] || item?.average_rating[0]}`)} alt="" 
                                    onError={e => e.target.src = require(`../../../assets/images/icons/star/white/norating.png`)}
                                    />
                                    
                            </div>

                            <div className="fs-13 hover-appear mt-2">
                                <div>
                                    <i className="icon-quote-left"></i> 
                                    {stringReview && `${stringReview.substring(0, 55)} ....`}
                                    <i className="icon-quote-right"></i> 
                                </div>

                                <div className="text-right">
                                    <span className="d-inline-block mr-2">{item?.latestreview?.created_by}</span>
                                    <img
                                        title={item?.total_rating?.avg_rating[1]}
                                        src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0] ? item?.total_rating?.avg_rating[0] : 'norating.png'}`)}
                                        onError={e => e.target.src = require(`../../../assets/images/icons/star/white/norating.png`)}
                                        alt={item?.total_rating?.avg_rating[1] || ''}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                            <React.Fragment>
                                <div className="featured-details minh-50">
                                    <h2 className="featured-item-title">{item?.name}</h2>
                                    <img
                                        title={item?.total_rating && item?.total_rating?.avg_rating ? item?.total_rating?.avg_rating[1] : ''}
                                        src={require(`../../../assets/images/icons/star/white/${item?.total_rating && item?.total_rating?.avg_rating ? item?.total_rating?.avg_rating[0] : 'norating.png'}`)}
                                        onError={e => e.target.src = require(`../../../assets/images/icons/star/white/norating.png`)}
                                        alt={item?.total_rating && item?.total_rating?.avg_rating ? item?.total_rating?.avg_rating[1] : ''} />
                                </div>
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.getByCategory(item);
                                    }}
                                    className="floating-item">
                                    <h2 className="item-title">{item?.taxonomy_list && item?.taxonomy_list[0] ? item?.taxonomy_list[0]?.category : ''}</h2>
                                    <div>
                                        <span className="item-category">{item?.content_type}</span>
                                    </div>
                                    <a className="item-link" href="#">
                                        {"See What's Trending Now"}
                                    </a>
                                </div>
                            </React.Fragment>
                        )}
                </div>
            </div>
        )
    }

    renderTwoItems = ({ item, item2, taxonomy }) => {

        let stringReview = '';
        if (item?.latestreview?.review) {
            stringReview = item?.latestreview?.review;
            stringReview = stringReview.substring(3, stringReview.length - 4);
        }

        let stringReview2 = '';
        if (item2?.latestreview?.review) {
            stringReview2 = item2?.latestreview?.review;
            stringReview2 = stringReview2.substring(3, stringReview2.length - 4);
        }

        let image_bg_2 = '';
        if(item2?.listing_profileimage && Array.isArray(item2?.listing_profileimage) && item2?.listing_profileimage.length){
            let filteredItem2 = item2?.listing_profileimage.find(image => image?.type == "image");
            let ext =  item2.listing_profileimage[0].location.split('.').pop();
            if(filteredItem2){
                image_bg_2 = filteredItem2.location;
            }
            if(ext =='jpg'){
                image_bg_2 = item2.listing_profileimage[0].location;
            }
        }

        let image_bg = '';
        if(item?.listing_profileimage && Array.isArray(item?.listing_profileimage) && item?.listing_profileimage.length){
            let filteredItem = item?.listing_profileimage.find(image => image?.type == "image");
            if(filteredItem){
                image_bg = filteredItem.location;
            }
        }

        return (
            <div className="d-flex flex-column col">
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.history.push({
                            pathname: `/${(item?.content_type).toLowerCase()}/${item?.id ? item?.id : 'null'}`,
                            state: { id: item?.id }
                        });
                    }}
                    className="flex-grow-1 featured-item">
                    <img className="feature-project-image _half"
                        src={image_bg}
                        alt={item?.name || item?.title} />
                    {taxonomy ? (
                        <div className="featured-details hoverable">
                            <a href="#" className="text-reset mb-2 d-block text-decoration-none">
                                <h2 className="fs-25 text-uppercase">{item?.name || item?.title}</h2>
                            </a>
                            <div className="mt-2">
                                <img
                                    title={`${(item?.total_rating?.avg_rating[1] || item?.average_rating[1])}`}
                                    src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0] || item?.average_rating[0]}`)} 
                                    onError={e => e.target.src = require(`../../../assets/images/icons/star/white/norating.png`)}
                                    alt="" 
                                    />
                            </div>

                            <div className="fs-13 hover-appear mt-2">
                                <div>
                                    <i className="icon-quote-left"></i> 
                                    {stringReview && `${stringReview.substring(0, 55)} ....`}
                                    <i className="icon-quote-right"></i> 
                                </div>

                                <div className="text-right">
                                    <span className="d-inline-block mr-2">{item?.latestreview?.created_by}</span>
                                    <img
                                        title={item?.total_rating?.avg_rating[1]}
                                        src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0]}`)}
                                        onError={e => e.target.src = require(`../../../assets/images/icons/star/white/norating.png`)}
                                        alt={item?.total_rating?.avg_rating[1] || ''}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                            <React.Fragment>
                                <div className="featured-details">
                                    <h2 className="featured-item-title">{item?.name}</h2>
                                    <img
                                        title={item?.total_rating && item?.total_rating?.avg_rating ? item?.total_rating?.avg_rating[1] : ''}
                                        src={require(`../../../assets/images/icons/star/white/${item?.total_rating && item?.total_rating?.avg_rating ? item?.total_rating?.avg_rating[0] : 'norating.png'}`)}
                                        onError={e => e.target.src = require(`../../../assets/images/icons/star/white/norating.png`)}
                                        alt={item?.total_rating && item?.total_rating?.avg_rating ? item?.total_rating?.avg_rating[1] : ''} />
                                </div>
                                <div onClick={(e) => { e.stopPropagation(); this.getByCategory(item) }} className="floating-item">
                                    <h2 className="item-title">{item?.taxonomy_list && item?.taxonomy_list[0] ? item.taxonomy_list[0]?.category : ''}</h2>
                                    <div><span className="item-category">{item?.content_type}</span></div>
                                    <a className="item-link" href="#">
                                        See What's Trending Now</a>
                                </div>
                            </React.Fragment>
                        )}
                </div>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.history.push({
                            pathname: `/${(item2?.content_type).toLowerCase()}/${item2?.id ? item2?.id : 'null'}`,
                            state: { id: item2?.id }
                        });
                    }}
                    className="flex-grow-1 featured-item">
                    <img
                        className="feature-project-image _half"
                        src={image_bg_2}
                        alt={item2?.name || item2?.title} />
                    {taxonomy ? (
                        <div className="featured-details hoverable">
                            <a href="#" className="text-reset mb-2 d-block text-decoration-none">
                                <h2 className="fs-25 text-uppercase">{item2?.name || item2?.title}</h2>
                            </a>
                            <div className="mt-2">
                                <img
                                    title={`${(item2?.total_rating?.avg_rating[1] || item2?.average_rating[1])}`}
                                    src={require(`../../../assets/images/icons/star/white/${item2?.total_rating?.avg_rating[0] || item2?.average_rating[0]}`)} alt="" />
                            </div>

                            <div className="fs-13 hover-appear mt-2">
                                <div>
                                    <i className="icon-quote-left"></i> 
                                    {stringReview2 && `${stringReview2.substring(0, 55)} ....`}
                                    <i className="icon-quote-right"></i> 
                                </div>

                                <div className="text-right">
                                    <span className="d-inline-block mr-2">{item2?.latestreview?.created_by}</span>
                                    <img
                                        title={item2?.total_rating?.avg_rating[1]}
                                        src={require(`../../../assets/images/icons/star/white/${item2?.total_rating?.avg_rating[0]}`)}
                                        alt={item2?.total_rating?.avg_rating[1] || ''}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                            <React.Fragment>
                                <div className="featured-details">
                                    <h2 className="featured-item-title">{item2?.name}</h2>
                                    {item2?.total_rating?.avg_rating && (
                                        <img
                                            title={item2?.total_rating && item2?.total_rating?.avg_rating ? item2?.total_rating?.avg_rating[1] : ''}
                                            src={require(`../../../assets/images/icons/star/white/${item2?.total_rating && item2?.total_rating?.avg_rating ? item2?.total_rating?.avg_rating[0] : 'norating.png'}`)}
                                            alt={item2?.total_rating && item2?.total_rating?.avg_rating ? item2?.total_rating?.avg_rating[1] : ''} />
                                    )}
                                </div>
                                <div onClick={(e) => { e.stopPropagation(); this.getByCategory(item2) }} className="floating-item">
                                    <h2 className="item-title">{item2?.taxonomy_list && item2?.taxonomy_list[0] ? item2.taxonomy_list[0].category : ''}</h2>
                                    <div><span className="item-category">{item2?.content_type}</span></div>
                                    <a className="item-link" href="#">
                                        See What's Trending Now</a>
                                </div>
                            </React.Fragment>
                        )}
                </div>
            </div>
        )
    }

    renderItems = () => {
        const { business_collage_sub_items, items } = this.props;

        if (business_collage_sub_items?.results && Array.isArray(business_collage_sub_items?.results) && business_collage_sub_items?.results?.length) {
          
            return (
                <Row noGutters xs={1} md={4}>
                    {this.renderOneItem({ item: business_collage_sub_items.results[0], taxonomy: true })}
                    {this.renderTwoItems({ item: business_collage_sub_items.results[1], item2: business_collage_sub_items.results[2], taxonomy: true })}
                    {this.renderOneItem({ item: business_collage_sub_items.results[3], taxonomy: true })}
                    {this.renderTwoItems({ item: business_collage_sub_items.results[4], item2: business_collage_sub_items.results[5], taxonomy: true })}
                </Row>
            )
        } else {
            if (items?.results && Array.isArray(items?.results) && items?.results?.length) {
              
                return (
                    <Row noGutters xs={1} md={4}>
                        {this.renderOneItem({ item: items.results[0] })}
                        {this.renderTwoItems({ item: items.results[1], item2: items.results[2] })}
                        {this.renderOneItem({ item: items.results[3] })}
                        {this.renderTwoItems({ item: items.results[4], item2: items.results[5] })}
                    </Row>
                )
            }
        }
    }

    render() {
        const { business_collage_sub_items, selectedItems } = this.props;
        let { toggleBlueBanner, setTaxonomyCategory } = this.state;
        return (
            <div className="px-0 container-fluid">
                {(business_collage_sub_items !== null && business_collage_sub_items?.results && toggleBlueBanner) ? (
                    <div className="trending-info-box fs-14 font-weight-bold _top-fix">
                        <div className="d-flex mx-n2 align-items-center">
                            <div className="px-2">
                                <span className="ff-alt mr-2">What's Trending Now:</span>
                                <span className="ff-headings fs-30">{setTaxonomyCategory}</span>
                            </div>
                            <div className="px-2 ml-auto">
                                <span
                                    onClick={() => { this.props.clear_collage_sub_categories(); }}
                                    role="button" className="text-reset mr-2 d-inline-block"><i>Back to main category view</i></span>
                                <span onClick={() => { this.setState({ toggleBlueBanner: false }) }} className="d-inline-block" role="button">
                                    <img src={require("../../../assets/images/icons/close-icon.png")} alt="Close"></img>
                                </span>
                            </div>
                        </div>
                    </div>
                ) : ''}
                <div className="tab-content bg-dark" style={{ minHeight: '500px' }}>
                    <div className="tab-pane active">
                        {this.renderItems()}
                    </div>
                </div>
                {this.props.enableSubCategory && (
                    <section className="position-relative" style={{ zIndex: 3, marginTop: '-40px' }}>
                        <Container>
                            <div>
                                <Row className="align-items-end">
                                    <Col xs="auto">
                                        <span
                                            onClick={() => {
                                                this.props.history.replace('/business', null);
                                                this.props.fetchItems();
                                            }}
                                            className="shade-link d-inline-block _black _padded cursor-pointer">Back to Category</span>
                                    </Col>
                                    <Col>
                                        <div className="text-center text-white black-shade mx-auto" style={{ maxWidth: '500px' }}>
                                            <h1 className="p-0 m-0 fs-34">{selectedItems?.label}</h1>
                                        </div>
                                    </Col>
                                    <Col xs="auto">
                                        <span className="btn btn-primary fs-16">See Buyer's Guide</span>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </section>
                )}
            </div>
        )
    }
}

const mapState = state => ({
    connections_activity_data: state.business.connections_activity_data,
    business_collage_sub_items: state.business.business_collage_sub_items,
});

const mapDispatch = dispatch => ({
    fetch_collage_items: (data) => dispatch(fetch_collage_items(data)),
    clear_collage_sub_categories: () => dispatch(clear_collage_sub_categories())
});

export default withRouter(connect(mapState, mapDispatch)(CollageItems));
