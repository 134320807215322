/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom';
import { Row, Col, Button, FormGroup, Form, Input, Alert } from 'reactstrap';
import { get_actors_list, search_actor, add_actor_as_fav, set_actor_loading } from '../../../actions/actors';
import userIcon from "../../../assets/images/icons/user-circle.png";
import Loader from '../../atoms/loader'
import { get_favorite_professionpins } from "../../../actions/favorite";
import { debounce } from 'lodash';



class Actors extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actors_data: [],
            page: 1,
            searchActors: '',
            profileInfo: {},
            loading: false,
            onSearch: false,
        }
    }

    debounceFavActors = debounce(() => {
        this.handleSearchActor();
    }, 500);

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
            page: 1,
        },()=>{
            this.debounceFavActors()
        });
        
    }

   

    handleSearchActor = () => {
        // e.preventDefault();
        let { searchActors } = this.state;
        this.setState({ onSearch: true })
        if (searchActors && searchActors.length > 0) {
            this.props.search_actor({ search_name: searchActors })
        } else if (!this.state.actors_data.length || !searchActors.length) {
            this.props.get_actors_list();
        } else if (!searchActors) {
            this.props.search_actor({ search_name: searchActors })
        }
    }

    componentWillMount = () => {
        this.props.set_actor_loading(true);
        this.props.get_actors_list()
    }

    componentWillReceiveProps(nextProps) {

        if ('isLoading' in nextProps) {
            this.setState({ loading: nextProps.isLoading })
        }

        if (Object.keys(this.state.profileInfo).length == 0) {
            if (nextProps.my_profile_info) {
                this.setState({
                    profileInfo: nextProps.my_profile_info
                })
            }
        }

        if (nextProps?.actors_data?.results && Array.isArray(nextProps?.actors_data?.results) && nextProps?.actors_data?.results.length > 0) {
            this.setState({
                actors_data: nextProps.actors_data
            });
        } else {
            this.setState({
                actors_data: []
            });
        }

    }

    handleMoreSuggestions = () => {
        const { page, actors_data, searchActors } = this.state;
        if (actors_data?.next) {
            let page_no = page + 1
            this.setState({ page: page_no });

            (searchActors && searchActors?.length)
                ? this.props.search_actor({ search_name: searchActors, page: page_no })
                : this.props.get_actors_list({ page: page_no });
        }
        
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.props.search_actor({ search_name: this.state.searchActors })
    }

    handleAddFav = (id) => {
        this.props.set_actor_loading(true);
        const addFav = this.props.add_actor_as_fav({ id: id })
        const getActList = (this.state.searchActors && this.state.searchActors?.length) ? this.props.search_actor({ search_name: this.state.searchActors }) : this.props.get_actors_list()

        Promise.all([addFav, getActList]).then(() => {
            this.props.get_favorite_professionpins("favourite");
            this.props.set_actor_loading(false);
        });
    }


    render() {
        const { actors_data, searchActors, loading } = this.state;
        return (
            <div>
                <h2 className="fs-34" style={{ color: '#d5d6e5' }}>Who are your favorite actors?</h2>
                <hr />
                <div className="border-bottom">
                    <Form onSubmit={this.handleSearch}>
                        <Row form>
                            <Col xs={12} className="col-sm">
                                <FormGroup>
                                    <Input bsSize="sm"
                                        type="search"
                                        name="searchActors"
                                        placeholder="Search for an actor"
                                        value={searchActors}
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        onChange={this.handleChange}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm="auto">
                                <FormGroup>
                                    <Button size="sm" color="primary" className={this.state.onSearch == true ? "actorbutton mw" : "mw"} type="submit" onClick={this.handleSearchActor}>
                                        Search
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {/* Suggestions */}
                <ul className="list-unstyled">

                    {
                        loading ?
                            <Alert color="primary">
                                Loading...
                            </Alert> :
                            <>
                                {actors_data?.results && actors_data?.results?.map((data, index) => (
                                    <li key={index} className="py-2 border-bottom">
                                        <div className="d-flex flex-nowrap mx-n2">
                                            <div className="px-2">
                                                <Link className="fs-14 text-dark d-block"
                                                    to={{
                                                        pathname: `/${data.content_type_name ? data.content_type_name : 'actors'}/${data.slug ? data.slug : null}`,
                                                        state: { id: data?.id }
                                                    }} rel="noopener noreferrer">
                                                    <div className="d-flex">
                                                        <div className="mr-2">
                                                            {
                                                                data.professional_profileimage &&
                                                                    data.professional_profileimage[0] &&
                                                                    data.professional_profileimage[0].thumbnail ?
                                                                    <img
                                                                        src={data.professional_profileimage[0].thumbnail}
                                                                        className="rounded-circle object-fit-cover"
                                                                        width={50}
                                                                        height={50}
                                                                        title={data.name}
                                                                        onError={(error) =>
                                                                            (error.target.src = userIcon)
                                                                        }
                                                                        alt={data.name}
                                                                    />
                                                                    :
                                                                    <img src={userIcon}
                                                                        className="rounded-circle object-fit-cover"
                                                                        width={50} height={50}
                                                                        title={data.name}
                                                                        alt={data.name}
                                                                    />
                                                            }
                                                        </div>
                                                        <div className="mt-1">
                                                            <span className="d-inline-block font-weight-bold ff-base">{data.name} </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button color="link" className="text-decoration-none font-weight-bold"
                                                    size="sm" onClick={() => this.handleAddFav(data.id)}>
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                    </li>

                                ))
                                }
                            </>

                    }


                </ul>
                <div className="text-right py-2 border-bottom mb-3">
                    <Button disabled={actors_data?.next ? false : true} color="link" className="text-tertiary text-decoration-none font-weight-bold px-0" onClick={this.handleMoreSuggestions} >
                        More suggestions
                    </Button>
                </div>

                {/* Actors search list */}
                <ul className="list-unstyled" hidden>
                    <li className="py-2 border-bottom">
                        <div className="d-flex flex-nowrap mx-n2">
                            <div className="px-2">
                                <Link className="fs-14 text-dark d-block" to="#" target="_blank" rel="noopener noreferrer">
                                    <div className="d-flex">
                                        <div className="mr-2">
                                            <img src={`https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/Hugh_Grant_Brussels_'08.7d6840b5e414c0ade5f7b700cb2681f1968235de.jpg`} className="rounded-circle object-fit-cover" width={50} height={50} alt="Actor" />
                                        </div>
                                        <div className="mt-1">
                                            <span className="d-inline-block font-weight-bold ff-base">Hugh Grant</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="px-2 ml-auto">
                                <Button color="link" className="text-decoration-none font-weight-bold" size="sm">
                                    Add
                                </Button>
                            </div>
                        </div>
                    </li>
                    <li className="py-2 border-bottom">
                        <div className="d-flex flex-nowrap mx-n2">
                            <div className="px-2">
                                <Link className="fs-14 text-dark d-block" to="#" target="_blank" rel="noopener noreferrer">
                                    <div className="d-flex">
                                        <div className="mr-2">
                                            <img src={`https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/Andy%20Garcia.70756481f35ed9e5b4648b540f34d43e06cafa61.jpg`} className="rounded-circle object-fit-cover" width={50} height={50} alt="Actor" />
                                        </div>
                                        <div className="mt-1">
                                            <span className="d-inline-block font-weight-bold ff-base">Andy Garcia</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="px-2 ml-auto">
                                <Button color="link" className="text-decoration-none font-weight-bold" size="sm">
                                    Add
                                </Button>
                            </div>
                        </div>
                    </li>
                    <li className="py-2 border-bottom">
                        <div className="d-flex flex-nowrap mx-n2">
                            <div className="px-2">
                                <Link className="fs-14 text-dark d-block" to="#" target="_blank" rel="noopener noreferrer">
                                    <div className="d-flex">
                                        <div className="mr-2">
                                            <img src={`https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/Sophia_Loren_L.A..8482c0304bfc3997b332e7186bc2a39db54a35e3.jpg`} className="rounded-circle object-fit-cover" width={50} height={50} alt="Actor" />
                                        </div>
                                        <div className="mt-1">
                                            <span className="d-inline-block font-weight-bold ff-base">Sophia Loren</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="px-2 ml-auto">
                                <Button color="link" className="text-decoration-none font-weight-bold" size="sm">
                                    Add
                                </Button>
                            </div>
                        </div>
                    </li>
                </ul>


            </div>
        );
    }
}

const mapState = (state) => {
    return {
        actors_data: state.actor.actors_list,
        isLoading: state.actor.isLoading,
        my_profile_info: state.user.my_profile,
    };
};
const mapDispatch = {
    get_actors_list,
    search_actor,
    add_actor_as_fav,
    get_favorite_professionpins,
    set_actor_loading
};


export default withRouter(connect(mapState, mapDispatch)(Actors));
