const initialState = {
    education_data: [],
    work_data: [],
    search_organization_info: [],
    search_university_info: [],
};


const workeducation = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case "GET_EDUCATION":
            state.education_data = action.payload;
            return { ...state };

        case "GET_WORK_HISTORY":
            state.work_data = action.payload;
            return { ...state };

        case "ADD_WORK_HISTORY":
            return { ...state };

        case "ADD_EDUCATION":
            return { ...state };

        case "SEARCH_ORGANIZATION":
            state.search_organization_info = action.payload;
            return { ...state };

        case "SEARCH_UNIVERSITY":
            state.search_university_info = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default workeducation;
