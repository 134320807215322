import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Media, UncontrolledDropdown } from 'reactstrap';
import { add_to_list, delete_wall_comment, post_wall_comments, put_wall_reply, remove_from_list } from '../../../actions/business';
import { updateFeedReactions } from '../../../actions/feeds';
import { get_product_details_wall_data, product_details_wall_data_loading } from '../../../actions/products';
import { get_reviewgroup } from '../../../actions/user';
import DeleteBtn from '../../atoms/deleteBtn';
import EditBtn from '../../atoms/editBtn';
import ModalComponent from '../../atoms/modalComponent';
import ShareByEmail from '../../atoms/shared/shareByEmail';
import { ConfirmationModal } from '../../molecules/homepage/ConfirmationModal';
import Reactions from '../../molecules/homepage/Reactions';

const WallView = {
    All: 'all',
    Posts: 'posts',
    Replies: 'replies',
    Updates: 'updates'
};

const Wall = (props) => {
    const {
        id,
        //  categoryType
    } = props;
    const [wallViewFilter, setWallViewFilter] = useState(WallView.All);
    const [wallReplyText, setWallReplyText] = useState({});
    const [wallReplyVisibility, setWallReplyVisibility] = useState({});
    const [wallCommentEdit, setWallCommentEdit] = useState(false);
    const [shareByEmailModalToggle, setShareByEmailModalToggle] = useState(false);
    const [shareId, setShareId] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmModalTitle, setConfirmModalTitle] = useState('');
    const [deleteCommentId, setDeleteCommentId] = useState();
    const [forceShowLoginModal, setForceShowLoginModal] = useState(true);
    const [wallDataItems, setWallDataItems] = useState();
    const [comment, setComment] = useState({})
    const dispatch = useDispatch()


    const handleWallReplyText = (id, value) => {
        setWallReplyText((prevState => {
            return {
                ...prevState,
                [id]: value
            }
        }));
    }

    useEffect(() => {
        dispatch(product_details_wall_data_loading(true))
        dispatch(get_product_details_wall_data({ id: id, filter_type: WallView.All, page: 1, force: true }))
    }, []);

    useEffect(() => {
        if (props.wallData) setWallDataItems(props.wallData);
    }, [props.wallData])

    const renderAnimatedReaction = (items) => {
        return ( // cheers_forever
            <div className="reaction-anim" hidden>
                {items.map(item => (
                    <img
                        src={require(`../../../assets/images/icons/${item}.gif`)}
                        alt=""
                    />
                ))}
            </div>
        )
    }

    const renderReaction = (items) => {
        return (
            <React.Fragment>
                {items.map(item => (
                    <div className="reaction-btn-group">
                        {item?.value ? (
                            <React.Fragment>
                                <button className={`reaction ${item?.name}`} title={item.labelName}>
                                    <span className="icon">
                                        <img src={require(`../../../assets/images/icons/${item.name}.png`)} alt={item.labelName} />
                                    </span>
                                    <span className="name">
                                        {item.labelName}
                                    </span>
                                </button>
                                <button
                                    className="reaction hoverable"
                                    toggle={() => {
                                        // this.setState({ viewPeopleWhoReactedModalToggle: !this.state.viewPeopleWhoReactedModalToggle }) //TODO
                                    }}>
                                    <span className="count">
                                        {item?.count ? item?.count : 0}
                                    </span>
                                    <div className="on-hover-appear tooltiptext reaction-tooltip">
                                        <p>WikiReview Investor</p>
                                        <span>and 1 more</span>
                                    </div>
                                </button>
                            </React.Fragment>
                        ) : null}
                    </div>
                ))}
            </React.Fragment>
        )
    }

    const forceToggleLoginModal = () => {
        setForceShowLoginModal(!forceShowLoginModal);
    }

    const checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const { my_profile } = props;
        if (my_profile && my_profile.id && authId && (authId == my_profile.id)) {
            return true;
        } else {
            return false;
        }
    }

    const handleOnClickShareByEmail = (id) => {
        setShareId(id);
        setShareByEmailModalToggle(!shareByEmailModalToggle);
    }

    const handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote }) => {
        setShareByEmailModalToggle(false);
        props.handleOnSubmitShareByEmail({ shareEmail, shareName, shareId, shareNote });
    }


    const handleDeleteComment = () => {
        setShowConfirmModal(false);
        props.delete_wall_comment({ action_id: deleteCommentId, business_id: id, filter_type: wallViewFilter });
        dispatch(get_product_details_wall_data({ id: id, filter_type: WallView.All, page: 1, force: true }))
    }

    const handleOnClickAddToList = (type, status, obj) => {
        if (!checkIfLoggedIn()) {
            forceToggleLoginModal();
            return false;
        }
        if (wallDataItems && wallDataItems.length > 0 && obj && obj.target && obj.target.id) {

            let message = '',
                url = '',
                nextUrl = '',
                key = '',
                item = obj,
                data = { type };

            let prof_list = wallDataItems;

            if (type.includes('want')) {
                key = 'want_this';
            } else if (type.includes('favourite')) {
                key = 'favourite';
            } else {
                key = 'watch_this';
            }

            if (status) {
                message = `${item?.target?.name} has been removed from your ${type}`;
                url = `/api/pins/${item?.target?.id}/?type=${type}`;
                props.remove_from_list(url, message, nextUrl);
                item.target.userpins[key] = false;
            } else {
                data.user_entry = item.target.id;
                message = `${item?.target?.name} has been added to your ${type}`;
                url = `/api/pins/`;
                props.add_to_list(url, data, message, nextUrl);
                item.target.userpins[key] = true;
            }
            prof_list = prof_list.map(itemValue => {
                if (itemValue.id === item.id) {
                    return item;
                }
                return itemValue;
            });

            wallDataItems = prof_list;

            setWallDataItems(wallDataItems);
        }
    }


    const handleFeedReactions = (reaction, id, verb) => {
        const params = {
            type: reaction
        };
        if (verb.includes['has written a follow up review of', 'commented on a review on', 'wrote a review', 'has written an update for their review on']) {
            params['review'] = id
        } else {
            params['actstream_action'] = id
        }
        props.updateFeedReactions(params);
    };


    const toggleWallReply = (item, type) => {
        const wallReplyVisibilityCopy = { ...wallReplyVisibility }
        if (type === "cancel") {
            if (wallReplyVisibilityCopy[item]) {
                wallReplyVisibilityCopy[item] = !wallReplyVisibilityCopy[item];
            } else {
                wallReplyVisibilityCopy[item] = false;
            }
        } else {
            if (wallReplyVisibilityCopy[item]) {
                wallReplyVisibilityCopy[item] = !wallReplyVisibilityCopy[item];
            } else {
                wallReplyVisibilityCopy[item] = true;
            }
        }
        setWallReplyVisibility((wallReplyVisibilityCopy));
    }

    const addComment = (item, value, type) => {
        const commentCopy = { ...comment }
        commentCopy[item] = value;
        setComment(commentCopy)

    }

    const checkOwner = ({ conversation }) => {
        const { loggedInUser } = props;
        if (checkIfLoggedIn()) {
            if (conversation?.sender_details == loggedInUser.user.username) {
                return true;
            }
        }
        return false;
      }
    
    
      
    return <>
        <ButtonGroup className="flex-wrap type-filter mb-3" size="sm">
            <div className="item d-flex align-items-center">
                <Button
                    className="text-nowrap"
                    color="transparent"
                    onClick={() => {
                        setWallViewFilter(WallView.All);
                        dispatch(product_details_wall_data_loading(true))
                        dispatch(get_product_details_wall_data({ id: id, filter_type: WallView.All, page: 1, force: true }))
                    }}
                    active={wallViewFilter === WallView.All}>
                    All
                </Button>
            </div>
            <div className="item d-flex align-items-center">
                <Button
                    className="text-nowrap"
                    color="transparent"
                    onClick={() => {
                        setWallViewFilter(WallView.Posts);
                        dispatch(product_details_wall_data_loading(true))
                        dispatch(get_product_details_wall_data({ id: id, filter_type: WallView.Posts, page: 1, force: true }))
                    }}
                    active={wallViewFilter === WallView.Posts}>
                    Posts
                </Button>
            </div>
            <div className="item d-flex align-items-center">
                <Button
                    className="text-nowrap"
                    color="transparent"
                    onClick={() => {
                        setWallViewFilter(WallView.Replies);
                        dispatch(product_details_wall_data_loading(true))
                        dispatch(get_product_details_wall_data({ id: id, filter_type: WallView.Replies, page: 1, force: true }))
                    }}
                    active={wallViewFilter === WallView.Replies}>
                    Replies
                </Button>
            </div>
            <div className="item d-flex align-items-center">
                <Button
                    className="text-nowrap"
                    color="transparent"
                    onClick={() => {
                        setWallViewFilter(WallView.Updates);
                        dispatch(product_details_wall_data_loading(true))
                        dispatch(get_product_details_wall_data({ id: id, filter_type: WallView.Updates, page: 1, force: true }))
                    }}
                    active={wallViewFilter === WallView.Updates}>
                    Updates
                </Button>
            </div>
        </ButtonGroup>


        <>{!props.isLoading ?
            <>
                {
                    wallDataItems &&
                        wallDataItems.length > 0 ? (
                        wallDataItems.map((item, index) => {
                            let url = `${window.location.protocol}//${window.location.host}/business/${item?.target?.slug}`;
                            let title_quote = `Checkout ${item?.target?.name} on`;
                            return (
                                <div id={wallDataItems.length - 1 === index ? `item-last` : `item-${index}`} className="bg-white text-dark p-3 mb-2 last-item-new">
                                    <div className="d-flex mx-n2 mb-2">
                                        <div className="px-2">
                                            <img className="img-circle _50x50" src={item?.actor?.current_profile_pic?.url ? item.actor.current_profile_pic.url : ""} alt=""

                                                onError={(error) => (error.target.src = require("../../../assets/images/icons/user-circle.png"))}
                                            />
                                        </div>
                                        <div className="px-2 flex-grow-1">
                                            <div className="mb-3">
                                                <a href="#" className="text-darker font-weight-bold">{`${item?.actor?.first_name} ${item?.actor?.last_name}`}</a>
                                                {` ${item?.verb} `}
                                                <a href="#" className="text-darker font-weight-bold">{item?.target?.name}</a>
                                                {item?.verb === "recommends" ? (
                                                    <span className="text-nowrap float-right">
                                                        <div className="d-inline-flex flex-nowrap">
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                                    <div className="share-it-icon"></div>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="type-tertiary-alt" right>
                                                                    <DropdownItem className="fs-14">
                                                                        <FacebookShareButton
                                                                            url={url}
                                                                            quote={title_quote}
                                                                            hashtag={'#WikiReviews'}

                                                                        >
                                                                            {'Facebook'}
                                                                        </FacebookShareButton>
                                                                    </DropdownItem>
                                                                    <DropdownItem className="fs-14">
                                                                        <TwitterShareButton
                                                                            url={url}
                                                                            title={title_quote}
                                                                            // via="WikiReviews"
                                                                            hashtags={['WikiReviews']}

                                                                        >
                                                                            {'Twitter'}
                                                                        </TwitterShareButton>
                                                                    </DropdownItem>
                                                                    <DropdownItem className="fs-14" onClick={() => handleOnClickShareByEmail(item?.target?.id)}>By Email</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                                    <div className="thumbpin-icon"></div>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="type-tertiary-alt" right>
                                                                    <DropdownItem className="fs-14" onClick={() => handleOnClickAddToList('want list', item?.target?.userpins?.want_this, item)}><FontAwesomeIcon icon="heart" className="mr-2" fixedWidth /> {item?.target?.userpins?.want_this ? 'Added' : 'Add'} to want list</DropdownItem>
                                                                    <DropdownItem className="fs-14" onClick={() => handleOnClickAddToList('favourite list', item?.target?.userpins?.favourite, item)}><FontAwesomeIcon icon="star" className="mr-2" fixedWidth /> {item?.target?.userpins?.favourite ? 'Added' : 'Add'} to favorites</DropdownItem>
                                                                    <DropdownItem className="fs-14"><FontAwesomeIcon icon="eye" className="mr-2" fixedWidth onClick={() => handleOnClickAddToList('watch list', item?.target?.userpins?.watch_this, item)} /> {item?.target?.userpins?.watch_this ? 'Added' : 'Add'} to watch list</DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </span>
                                                ) : null}
                                                <br />
                                                <span className="text-tertiary">{item?.time_stamp}</span>

                                            </div>
                                            <div className="mt-3">
                                                <div className="quick-reactions d-flex flex-wrap align-items-end mb-2">
                                                    <div className="reaction-holder" style={{ display: 'contents' }}>
                                                        {/* Show on reacting */}
                                                        {item?.review_group_animation && item?.review_group_animation?.review_group_count && item?.review_group_animation?.review_group_status ?
                                                            <React.Fragment>
                                                                <div hidden>
                                                                    {renderAnimatedReaction(['woohoo_forever', 'nailedit_forever', 'insightful_forever', 'lol_forever', 'bummer_forever'])}
                                                                    {renderReaction([
                                                                        { name: 'woohoo', count: item?.review_group_animation?.review_group_count?.woohoo ? item?.review_group_animation?.review_group_count?.woohoo : 0, labelName: 'Woohoo', value: item?.review_group_animation?.review_group_status['woohoo'] === false ? true : false },
                                                                        { name: 'nailed', count: item?.review_group_animation?.review_group_count?.nailed ? item?.review_group_animation?.review_group_count?.nailed : 0, labelName: 'Nailed It', value: item?.review_group_animation?.review_group_status['nailed'] === false ? true : false },
                                                                        { name: 'insightful', count: item?.review_group_animation?.review_group_count?.insightful ? item?.review_group_animation?.review_group_count?.insightful : 0, labelName: 'Insightful', value: item?.review_group_animation?.review_group_status['insightful'] === false ? true : false },
                                                                        { name: 'lol', count: item?.review_group_animation?.review_group_count?.lol ? item?.review_group_animation?.review_group_count?.lol : 0, labelName: 'LOL', value: item?.review_group_animation?.review_group_status['lol'] === false ? true : false },
                                                                        { name: 'bummer', count: item?.review_group_animation?.review_group_count?.bummer ? item?.review_group_animation?.review_group_count?.bummer : 0, labelName: 'Bummer', value: item?.review_group_animation?.review_group_status['bummer'] === false ? true : false }
                                                                    ])}
                                                                </div>
                                                                <Reactions
                                                                    id={item?.id}
                                                                    counts={item?.review_group_animation?.review_group_count}
                                                                    statuses={item?.review_group_animation?.review_group_status}
                                                                    onClick={(reaction) => handleFeedReactions(reaction, item.id, item.verb)}
                                                                    get_reviewgroup={props.get_reviewgroup}
                                                                    get_reveiewData={props.get_reveiewData}
                                                                />
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <div hidden>
                                                                    {renderAnimatedReaction(['cheers_forever'])}
                                                                    {renderReaction([{ name: 'cheers', labelName: 'Thank you', value: true }])}
                                                                </div>
                                                                <Reactions
                                                                    id={item?.id}
                                                                    counts={item?.contribution_group?.contribution_group_count}
                                                                    statuses={item?.contribution_group?.contribution_group_status}
                                                                    onClick={(reaction) => handleFeedReactions(reaction, item.id, item.verb)}
                                                                    get_reviewgroup={props.get_reviewgroup}
                                                                    get_reveiewData={props.get_reveiewData}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* All Comments */}
                                    <div>
                                        {/* Comment by other user */}
                                        {item?.comments && Array.isArray(item?.comments) && item?.comments.length ?
                                            item?.comments.map(cm_item => (
                                                <Media className="media-post mt-3">
                                                    <Media>
                                                        <img
                                                            className="media-object object-1"
                                                            object
                                                            src={cm_item?.current_profile_pic}
                                                            alt="User Profile"
                                                            onError={(error) => (error.target.src = require("../../../assets/images/icons/user-circle.png"))}
                                                        />
                                                    </Media>
                                                    <Media body>
                                                        <Media className="mb-0" heading>
                                                            <div className="d-flex flex-nowrap mx-n2">
                                                                <div className="px-2 flex-grow-1">
                                                                    <span className="text-darker fs-14">
                                                                        {cm_item?.sender}{": "}
                                                                    </span>
                                                                    {/* Comments */}
                                                                    <span className="wb-break-word fs-14 font-weight-normal text-dark ff-alt">
                                                                        {cm_item?.body}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Media>
                                                        <div>
                                                            <div className="d-flex flex-nowrap mx-n2 mt-n1">
                                                                <div className="align-self-end flex-grow-1 px-2">
                                                                    {/* Reply Button */}
                                                                    <button
                                                                        onClick={() => { toggleWallReply(cm_item?.id) }}
                                                                        className="btn btn-link btn-sm px-0 font-weight-bold text-darker">
                                                                        {'Reply'}
                                                                    </button>
                                                                    <span className="fs-14 ff-alt text-tertiary align-middle">
                                                                        <span>{" - "}</span>
                                                                        {cm_item?.sent_at}
                                                                    </span>
                                                                </div>

                                                                {/* Remove Actions if comment by other user  */}
                                                                {
                                                                    checkOwner({ conversation: cm_item }) && <div className="align-self-start col-auto px-2">
                                                                    <EditBtn
                                                                        onClick={() => {
                                                                            const tempWallReplyText = {};
                                                                            const tempWallReplyVisibility = {};
                                                                            tempWallReplyText[cm_item?.id] = cm_item?.body;
                                                                            tempWallReplyVisibility[cm_item?.id] = true;
                                                                            setWallReplyText(tempWallReplyText);
                                                                            setWallReplyVisibility(tempWallReplyVisibility);
                                                                            setWallCommentEdit(true);
                                                                        }} />
                                                                    <DeleteBtn
                                                                        onClick={() => {
                                                                            setShowConfirmModal(true);
                                                                            setConfirmModalTitle('Do you want to delete the record ?')
                                                                            setDeleteCommentId(cm_item?.id)
                                                                        }} />
                                                                </div>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {/* Show When Replying */}
                                                            {wallReplyVisibility && Object.keys(wallReplyVisibility).length && wallReplyVisibility[cm_item?.id] ? (
                                                                <div>
                                                                    <Media className="media-post mt-3">
                                                                        <Media>
                                                                            <Media
                                                                                object
                                                                                src={props.loggedInUser.current_profile_pic ||
                                                                                    require("../../../assets/images/icons/user-circle.png")}
                                                                                alt="User Image"
                                                                                onError={(error) => error.target.src = require("../../../assets/images/icons/user-circle.png")}
                                                                            />
                                                                        </Media>
                                                                        <Media body>
                                                                            <FormGroup className="mb-0">
                                                                                <Input
                                                                                    onChange={(e) => { handleWallReplyText(cm_item?.id, e.target.value) }}
                                                                                    bsSize="sm"
                                                                                    value={wallReplyText[cm_item?.id]}
                                                                                    className="mb-2"
                                                                                    type="textarea"
                                                                                    name="reply"
                                                                                    placeholder="Write a reply"
                                                                                />
                                                                                <div className="text-right">
                                                                                    <div className="text-right">
                                                                                        <Button
                                                                                            disabled={wallReplyText && wallReplyText[cm_item.id] && wallReplyText[cm_item.id].length ? false : true}
                                                                                            onClick={() => {
                                                                                                if (!checkIfLoggedIn()) {
                                                                                                    forceToggleLoginModal();
                                                                                                    return false;
                                                                                                }
                                                                                                props.put_wall_reply({
                                                                                                    discussion_id: cm_item.id,
                                                                                                    body: wallReplyText[cm_item.id],
                                                                                                    business_id: id,
                                                                                                    filter_type: wallViewFilter,
                                                                                                    edit: wallCommentEdit
                                                                                                });
                                                                                                toggleWallReply(cm_item.id, "cancel")
                                                                                            }}
                                                                                            size="sm"
                                                                                            color="primary"
                                                                                            className=" mr-2">
                                                                                            {'Submit'}
                                                                                        </Button>
                                                                                        <Button
                                                                                            onClick={() => {
                                                                                                const tempWallReplyText = {};
                                                                                                // const tempWallReplyVisibility = {};
                                                                                                setWallReplyText(tempWallReplyText);
                                                                                                toggleWallReply(cm_item.id, "cancel")
                                                                                                // setWallReplyVisibility(tempWallReplyVisibility);
                                                                                                setWallCommentEdit(false);
                                                                                            }}
                                                                                            size="sm"
                                                                                            color="primary"
                                                                                            className="ml-0">
                                                                                            {'Cancel'}
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </FormGroup>
                                                                        </Media>
                                                                    </Media>
                                                                    <hr />
                                                                </div>
                                                            ) : null}
                                                        </div>

                                                        {/* Replies to a comment */}
                                                        <div>
                                                            {cm_item?.conversation && Array.isArray(cm_item?.conversation) && cm_item?.conversation?.length ?
                                                                cm_item?.conversation.map(cm_item_comment => (
                                                                    <Media className="media-post">
                                                                        <Media>
                                                                            <Media
                                                                                object
                                                                                src={cm_item_comment?.current_profile_pic}
                                                                                onError={(error) => error.target.src = require("../../../assets/images/icons/user-circle.png")}
                                                                                alt="User Image"
                                                                            />
                                                                        </Media>
                                                                        <Media body>
                                                                            <Media className="mb-0" heading>
                                                                                <div className="d-flex flex-nowrap mx-n2">
                                                                                    <div className="px-2 flex-grow-1">
                                                                                        <span className="text-darker fs-14">
                                                                                            {cm_item_comment?.sender}{": "}
                                                                                        </span>
                                                                                        {/* Comments */}
                                                                                        <span className="wb-break-word fs-14 font-weight-normal text-dark ff-alt">
                                                                                            {cm_item_comment?.body}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </Media>
                                                                            <div>
                                                                                <div className="d-flex flex-nowrap mx-n2 mt-n1">
                                                                                    <div className="align-self-end flex-grow-1 px-2">
                                                                                        <span className="fs-14 ff-alt text-tertiary align-middle">
                                                                                            {cm_item_comment?.sent_at}
                                                                                        </span>
                                                                                    </div>

                                                                                    {/* Remove Actions if comment by other user  */}
                                                                                    {
                                                                                        checkOwner({ conversation: cm_item_comment }) && <div className="align-self-start col-auto px-2">
                                                                                        <EditBtn
                                                                                            onClick={() => {
                                                                                                const tempWallReplyText = {};
                                                                                                const tempWallReplyVisibility = {};
                                                                                                tempWallReplyText[cm_item_comment?.msg_id] = cm_item_comment?.body;
                                                                                                tempWallReplyVisibility[cm_item_comment?.msg_id] = true;
                                                                                                setWallReplyText(tempWallReplyText);
                                                                                                setWallReplyVisibility(tempWallReplyVisibility);
                                                                                                setWallCommentEdit(true);


                                                                                            }} />
                                                                                        <DeleteBtn
                                                                                            onClick={() => {
                                                                                                setShowConfirmModal(true);
                                                                                                setConfirmModalTitle('Do you want to delete the record ?')
                                                                                                setDeleteCommentId(cm_item_comment?.msg_id)
                                                                                            }} />
                                                                                    </div>
                                                                                    }
                                                                                    
                                                                                </div>
                                                                            </div>

                                                                            {wallReplyVisibility && Object.keys(wallReplyVisibility).length && wallReplyVisibility[cm_item_comment?.msg_id] ? (
                                                                                <div>
                                                                                    <Media className="media-post mt-3">
                                                                                        <Media>
                                                                                            <Media
                                                                                                object
                                                                                                src={props.loggedInUser.current_profile_pic ||
                                                                                                    require("../../../assets/images/icons/user-circle.png")}
                                                                                                alt="User Image"
                                                                                                onError={(error) => error.target.src = require("../../../assets/images/icons/user-circle.png")}
                                                                                            />
                                                                                        </Media>
                                                                                        <Media body>
                                                                                            <FormGroup className="mb-0">
                                                                                                <Input
                                                                                                    onChange={(e) => { handleWallReplyText(cm_item_comment?.msg_id, e.target.value) }}
                                                                                                    bsSize="sm"
                                                                                                    value={wallReplyText[cm_item_comment?.msg_id]}
                                                                                                    className="mb-2 primary ph-normal text-primary"
                                                                                                    type="textarea"
                                                                                                    name="reply"
                                                                                                    placeholder="Write a reply"
                                                                                                />
                                                                                                <div className="text-right">
                                                                                                    <div className="text-right">
                                                                                                        <Button
                                                                                                            disabled={wallReplyText[cm_item_comment.msg_id].length ? false : true}
                                                                                                            onClick={() => {
                                                                                                                if (!checkIfLoggedIn()) {
                                                                                                                    forceToggleLoginModal();
                                                                                                                    return false;
                                                                                                                }
                                                                                                                props.put_wall_reply({
                                                                                                                    discussion_id: cm_item_comment?.msg_id,
                                                                                                                    body: wallReplyText[cm_item_comment?.msg_id],
                                                                                                                    business_id: id,
                                                                                                                    filter_type: wallViewFilter,
                                                                                                                    edit: wallCommentEdit
                                                                                                                });
                                                                                                            }}
                                                                                                            size="sm"
                                                                                                            color="primary"
                                                                                                            className=" mr-2">
                                                                                                            {'Update'}
                                                                                                        </Button>
                                                                                                        <Button
                                                                                                            onClick={() => {
                                                                                                                setWallReplyText({});
                                                                                                                setWallReplyVisibility({});
                                                                                                                setWallCommentEdit(false);
                                                                                                            }}
                                                                                                            size="sm"
                                                                                                            color="primary"
                                                                                                            className="ml-0">
                                                                                                            {'Cancel'}
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </FormGroup>
                                                                                        </Media>
                                                                                    </Media>
                                                                                    <hr />
                                                                                </div>
                                                                            ) : null}
                                                                        </Media>
                                                                    </Media>
                                                                ))
                                                                : null}
                                                        </div>
                                                    </Media>
                                                </Media>
                                            ))

                                            : null}
                                    </div>


                                    {/* Comment box */}
                                    < div className="mt-3" >
                                        <Media className="media-post">
                                            <Media>
                                                <Media
                                                    object
                                                    className="img-circle _35x35"
                                                    src={props.loggedInUser.current_profile_pic ||
                                                        require("../../../assets/images/icons/user-circle.png")}
                                                    alt="User Image"
                                                />
                                            </Media>
                                            <Media body>
                                                <FormGroup className="mb-0">
                                                    <Input
                                                        onChange={(e) => { addComment(item.id, e.target.value) }}
                                                        bsSize="sm"
                                                        value={comment[item.id]}
                                                        className="mb-2 comment-form-control"
                                                        type="textarea"
                                                        rows="1"
                                                        placeholder="Add your comments"
                                                    />
                                                    <div className="text-right">
                                                        <div className="text-right">
                                                            <Button
                                                                disabled={!!comment ? false : true}
                                                                onClick={() => {
                                                                    if (!checkIfLoggedIn()) {
                                                                        forceToggleLoginModal();
                                                                        return false;
                                                                    }
                                                                    props.post_wall_comments({
                                                                        business_id: id,
                                                                        filter_type: wallViewFilter,
                                                                        action_id: item.id,
                                                                        body: comment[item.id]
                                                                    });
                                                                    addComment(item.id, "");
                                                                    // this.timeOutWallReplyText = setTimeout(() => {
                                                                    //     wallReplyText[item.id] = '';
                                                                    //     setWallReplyText(wallReplyText);
                                                                    // }, 1000);
                                                                }}
                                                                size="sm"
                                                                color="primary">
                                                                {'Submit'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Media>
                                        </Media>
                                    </div>
                                </div>
                            )
                        })

                    ) : <div className="bg-white p-3">
                        <h2 className="text-secondary-dark">No results found!</h2>
                    </div>

                }</>

            : <div className="bg-white p-3">
                <h2 className="text-secondary-dark">Loading...</h2>
            </div>}</>


        <ModalComponent
            size={"sm"}
            isScrollable={true}
            isOpen={shareByEmailModalToggle}
            toggleHandler={() => setShareByEmailModalToggle(!shareByEmailModalToggle)}
            header={" Share by Email"}
            bodyClass={""}
            body={<ShareByEmail handleOnSubmitShareByEmail={handleOnSubmitShareByEmail} />}
        />


        <ConfirmationModal
            title={confirmModalTitle}
            isOpen={showConfirmModal}
            setIsOpen={(value) => {
                setShowConfirmModal(false);
            }}
            onConfirm={handleDeleteComment}
        />
    </>
};


const mapState = (state) => ({
    loggedInUser: state.user.my_profile,
    wallData: state.shared.wallData,
    get_reveiewData: state.user.get_reveiewData,
    isLoading: state.shared.isProductWallDataLoading
});

const mapProps = (dispatch) => ({
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    updateFeedReactions: (params) => dispatch(updateFeedReactions(params)),
    delete_wall_comment: ({ action_id, business_id, filter_type }) => dispatch(delete_wall_comment({ action_id, business_id, filter_type })),
    post_wall_comments: ({ action_id, body, business_id, filter_type }) => dispatch(post_wall_comments({ action_id, body, business_id, filter_type })),
    get_reviewgroup: (assetType, postId, type) => dispatch(get_reviewgroup(assetType, postId, type)),
    put_wall_reply: ({ discussion_id, body, business_id, filter_type, edit }) => dispatch(put_wall_reply({ discussion_id, body, business_id, filter_type, edit })),
});


export default withRouter(connect(mapState, mapProps)(Wall));
