import React from "react";
import blueIcon from '../../assets/images/icons/edit-hop.png';
import goldIcon from '../../assets/images/icons/edit-left.png';
import lightIcon from '../../assets/images/icons/edit-tip.png';
import filledIcon from '../../assets/images/icons/edit-info-icon.png';

export default class EditBtn extends React.Component  {
    render() {
        const { onClick, color } = this.props;
        let srcIMG = blueIcon;
        let iconWidth = 16;

        if (color === "gold") {
            srcIMG = goldIcon;
        } else if (color === "light") {
            srcIMG = lightIcon;
            iconWidth = 10;
        } else if (color === "filled") {
            srcIMG = filledIcon;
            iconWidth = 15;
        }
        else {
            srcIMG = blueIcon;
            iconWidth = "";
        }
        return (
            <span className="d-inline-block p-1" role="button" title="Edit" onClick={onClick}>
                <img width={iconWidth} src={srcIMG} alt="Edit" />
            </span>
        )
    }
}