import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Button, Col, FormGroup, Input, Label, Row, Media } from 'reactstrap';
import CollapseBasic from '../../../component/atoms/collapse';
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from '../../atoms/editBtn';
import { Link } from 'react-router-dom';
import { callApi, responseCodeValidator } from "../../../utils/apiCaller";
import moment from 'moment';
import Loader from "../../atoms/loader";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { get_uploaded_media } from '../../../actions/itemDetail';

const inputErrorMessages = {
    shippingText: "Offer Details are required",
    termDetailText: "Terms, Details and Exclusions are required!",
    inStoreText: "Offer Details are required",
    startDateText: "Start Date cannot be empty",
    endDateText: "End Date cannot be empty",
}
class OfferDetails extends Component {
    constructor(props) {
        super(props)
        this.isSetMediaItems = true;
        this.state = {
            defaultOfferSelected: 'Other Offer',
            listingItems: {},
            mediaSet: [],
            startError: {},
            inStoreSelectedItem: {},
            inStoreText: {},
            shippingSelectedItem: {},
            shippingText: {},
            storeName: {},
            startDateSelectedItem:{},
            startDateText: {},
            endDateSelectedItem: {},
            endDateText: {},
            webSiteSelectedItem: {},
            webSiteText: {},
            termDetailText: {},
            offerTypeItem: {
                couponCode: false,
                inStoreCoupon: false,
                otherOffers: false
            },
            isEditItem: {
                couponCode: false,
                inStoreCoupon: false,
                otherOffers: false
            },
            enableToggleOfferReply: {},
            enableReplyText: {},
            commentText: '',
            editItem: {},
            removedMedia: [],
        }
    }

    componentDidMount() {
        this.preFetchItems();
    }

    addComments = async ({ isComment, text, type, item, parent }) => {
        const { id, itemDetail } = this.props;
        try {
            let url = `/api/specialoffercomments/`;
            let data = {};
            if (isComment) {
                data = {
                    [item?.id]: text,
                    "reviews_userentries": id,
                    "created_by": itemDetail?.created_by,
                    "reviews_specialoffers": item?.id,
                    "comment": text
                };
            } else {
                data = {
                    [item?.id]: "",
                    "reviews_userentries": id,
                    "created_by": itemDetail?.created_by,
                    "reviews_specialoffers": item?.id,
                    "comment": text,
                    "parent_id": parent.id
                };
            }

            this.setState({enableLoading: true})
            let response = await callApi(url, 'POST', data);
            if (response && response?.code !== 500) {
                this.setState({ commentText: '', enableToggleOfferReply: {}, enableReplyText: {} })
                await this.fetchItems(type);
            }
            this.setState({enableLoading: false})
        } catch (err) {
            console.log(err)
        }
    }

    preFetchItems = () => {
        Promise.all([
            this.fetchItems('Other Offer'),
            this.fetchItems('In Store Coupon'),
            this.fetchItems('Coupon Code')
        ]).then(() => { })
    }

    isHelpFull = async ({ id, type, offerType }) => {
        try {
            let response = await callApi('/api/offergroup/', 'POST', { type, "specialoffer": id });
            if (response && response?.code !== 500) {
                await this.fetchItems(offerType);
            }
        } catch (err) {
            console.log({ err });
        }
    }

    fetchItems = async (type) => {
        const { id } = this.props;
        let { listingItems } = this.state;
        try {
            let url = `/api/specialoffer/?listing_id=${id}&offer_type=${encodeURIComponent(type)}`;
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {
                listingItems[type] = response?.results;
                this.setState({ listingItems });
            }
        } catch (err) {
            console.log(err);
        }
    }

    isValidUrl = urlString => {
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    handleInputValidation(type) {
        let { startError, inStoreText, shippingText, termDetailText, startDateText, endDateText, webSiteSelectedItem, webSiteText, inStoreSelectedItem, mediaSet } = { ...this.state };

        //offer detail
        if (!(type != "inStoreCoupon" ? shippingText[type] : inStoreSelectedItem?.[type] == 'uploadOfferPdf'  ? mediaSet.length : inStoreText.inStoreCoupon )) {
            startError[type][type == "inStoreCoupon" ? 'inStoreText' : 'shippingText'] = inputErrorMessages.shippingText;
        }

        //offer start date 
        if (!startDateText[type]) {
            startError[type]['startDateText'] = inputErrorMessages.startDateText;
        }
        else {
            startError && startError[type] && startError[type]['startDateText'] && delete startError?.[type]?.['startDateText'];
        }

        //offer end date
        if (!endDateText[type]) {
            startError[type]['endDateText'] = inputErrorMessages.endDateText;
        }
        else if (startDateText[type] > endDateText[type]) {
            startError[type]['endDateText'] = "Expiration date should be greater than Start date";

        } else {
            startError[type] && startError[type]['endDateText'] && delete startError?.[type]?.['endDateText'];
        }

        //offer claim link
        if (!webSiteSelectedItem[type]) {
            startError[type]['webSiteSelectedItem'] = "Please choose website claim option";
        }
        else if (webSiteSelectedItem[type] == "Enter Link") {
            if (!webSiteText[type]) {
                startError[type]['webSiteText'] = "please provide website link to claim";
            }
            else if (!this.isValidUrl(webSiteText[type])) {
                startError[type]['webSiteText'] = "please provide correct link";
            }
            else {
                startError[type] && startError[type]['webSiteText'] && delete startError[type]['webSiteText'];
            }
        }
        else {
            startError[type] && startError[type]['webSiteSelectedItem'] && delete startError[type]['webSiteSelectedItem']
        }

        //offer terms, Details, Exclusions
        if (!termDetailText[type]) {
            startError[type]['termDetailText'] = inputErrorMessages.termDetailText;
        }

        this.setState({ startError });
        if (startError[type] && Object.keys(startError[type])?.length > 0) {
            return false;
        }
        else {
            return true;
        }
    }

    handleSubmit = async ({isUpdate, item}) => {
        let { defaultOfferSelected, inStoreSelectedItem, inStoreText, shippingSelectedItem, shippingText, termDetailText, startDateSelectedItem, startDateText, endDateSelectedItem, endDateText, webSiteSelectedItem, webSiteText, storeName, mediaSet, editItem, removedMedia } = { ...this.state };
        const { id } = this.props;
        const isInputValidated = this.handleInputValidation(item);

        if (isInputValidated) {
            let mediaSetFiltered = [];
            let startDateTextData = ""
            let endDateTextData = "";

            if (startDateText?.[item] && endDateText?.[item]) {
                startDateTextData = startDateText?.[item];
                endDateTextData = endDateText?.[item];
            }

            if (mediaSet?.length) {
                mediaSetFiltered = mediaSet.map(item => item.id);
            }

            try {
                let postUrl = `/api/specialoffer/?listing_id=&${encodeURIComponent(defaultOfferSelected)}`;
                let putUrl = `/api/specialoffer/${editItem?.id}/`;
                let data = {
                    "offer_type": defaultOfferSelected,
                    "other_offer": "other_offer",
                    "reviews_userentries": id,
                    "terms_details_exclusions": '',
                    "startDate": '',
                    "endDate": '',
                };


                if (storeName != undefined) {
                    if (storeName?.[item]) {
                        data['store_name'] = storeName?.[item]
                    }
                }

                if (mediaSetFiltered?.length) {
                    data['media'] = mediaSetFiltered
                }
                if (defaultOfferSelected != 'In Store Coupon') {
                    if (shippingText != undefined) {
                        if (shippingText?.[item]) {
                            data['special_offer'] = shippingText?.[item];
                        }
                        //data["special_offer"] = shippingText;
                    }
                }
                else {
                    if (inStoreText != undefined) {
                        if (inStoreText?.[item]) {
                            data['special_offer'] = inStoreText?.[item];
                        }
                        //data["special_offer"] = shippingText;
                    }
                }
                if (shippingSelectedItem != undefined) {
                    if (shippingSelectedItem?.[item]) {
                        data['isFreeShiping'] = shippingSelectedItem?.[item];
                    }
                    //data['isFreeShiping'] = shippingSelectedItem;
                }

                if (item == "inStoreCoupon") {
                    data["instoreCouponOpt"] = inStoreSelectedItem?.[item];
                }

                if (startDateSelectedItem !== '') {
                    if (startDateSelectedItem?.[item]) {
                        data['startDate'] = startDateSelectedItem?.[item];
                    }
                    //data['startDate'] = startDateSelectedItem;
                    //data['from_date'] = moment(startDateText).format('YYYY-MM-DD');
                    if (startDateTextData) data['from_date'] = moment(startDateTextData).format('YYYY-MM-DD');
                }

                if (endDateSelectedItem !== '') {
                    if (endDateSelectedItem?.[item]) {
                        data['endDate'] = endDateSelectedItem?.[item]
                    }
                    // data['endDate'] = endDateSelectedItem;
                    //data['to_date'] = moment(endDateText).format('YYYY-MM-DD');

                    if (endDateTextData) data['to_date'] = moment(endDateTextData).format('YYYY-MM-DD');
                }
                if (termDetailText != undefined) {
                    if (termDetailText?.[item]) {
                        data['terms_details_exclusions'] = termDetailText?.[item];
                    }
                    // data['endDate'] = endDateSelectedItem;
                    //data['to_date'] = moment(endDateText).format('YYYY-MM-DD');;
                }


                if (webSiteSelectedItem != undefined) {
                    let text = '';
                    text = webSiteSelectedItem?.[item];

                    if (text != '') {

                        if (text == "Not Applicable") {
                            data['claimDealOption'] = text;
                        }
                        if (text == "Enter Link") {
                            if (webSiteText?.[item]) {
                                data['claim_deal_link'] = webSiteText?.[item];
                            }
                            //data['claim_deal_link'] = webSiteText;
                        }
                    }
                }
                if (!isUpdate) {
                    let response = await callApi(postUrl, 'POST', data);
                    if (response && response?.code < 400) {
                        toast(`Offer added successfully!`, {
                            autoClose: 2500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        });
                        this.handleReset();
                        this.fetchItems(defaultOfferSelected);
                    }
                } else {
                    data['is_edit'] = true;
                    if (removedMedia && removedMedia?.length) {
                        await this.deleteMedia(removedMedia);
                    }
                    const response = await callApi(putUrl, 'PUT', data);
                    if (response && responseCodeValidator(String(response?.code))) {
                        toast(`Offer updated successfully!`, {
                            autoClose: 2500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        });
                        this.handleReset();
                        this.fetchItems(defaultOfferSelected);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    componentWillReceiveProps(nextProps) {
    
        let startError = { ...this.state.startError };
        
        let uploadedFiles = nextProps?.uploadedMediaSet?.uploadedFiles;
        
        if (uploadedFiles.length && !this.isSetMediaItems) {
            delete startError?.inStoreCoupon?.inStoreText;
            this.setState({ mediaSet: uploadedFiles, startError });
        }

        if (nextProps?.deleteOfferDetails) {
            this.deleteThisOffer(nextProps?.deleteOfferDetails);
        }
    }

    deleteThisOffer = async (id) => {
        try {
            let response = await callApi(`/api/specialoffer/${id}/`, 'DELETE');
            if (response && response?.code !== 500) {
                toast(`Offer deleted successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                this.props.setToggleDeleteOffer();
                this.preFetchItems();
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleSelectItem = (e,item) => {
        let {shippingSelectedItem} = this.state;
        shippingSelectedItem[item] = e.target.value; 
        this.setState({
            shippingSelectedItem
        })
    }

    removeImageFromMediaSet = (index) => {
        let { mediaSet, editItem, removedMedia } = { ...this.state };
        let tempRemovedMedia = mediaSet.splice(index, 1);

        if (editItem && tempRemovedMedia && !removedMedia.includes(tempRemovedMedia[0]?.id)) {
            removedMedia.push(tempRemovedMedia[0]?.id);
        }
        this.setState({ mediaSet, removedMedia });
        this.props.get_uploaded_media({ uploadedFiles: mediaSet });

    }

    handleChange(e, inputName, type) {
        //type = couponCode,inStoreCoupon,otherOffers
        const requiredInputs = ['shippingText', 'inStoreText', 'termDetailText', 'startDateText', 'endDateText', 'webSiteSelectedItem'];
        const { value } = e.target;
        const prevInputState = this.state?.[inputName];
        const startError = { ...this.state.startError }
        this.setState({ [inputName]: { ...prevInputState, [type]: value } });
        
        if (value && requiredInputs.includes(inputName)) {
            startError?.[type]?.[inputName] && delete startError?.[type]?.[inputName];
            this.setState({ startError,value});
        }
        if (!value && requiredInputs.includes(inputName)) {
            startError[type][inputName] = inputErrorMessages[inputName];
            this.setState({startError});
        }
    }

    clickToAddNew = (item) => {
        let { shippingSelectedItem,
            shippingText,
            storeName,
            startDateSelectedItem,
            startDateText,
            endDateSelectedItem,
            endDateText,
            webSiteSelectedItem,
            webSiteText,
            termDetailText,
            inStoreSelectedItem,
            inStoreText,
            mediaSet,
            startError
        } = this.state;
        let shippingTextAreaPlaceHolder = '';

        if (shippingSelectedItem[item] == "Yes") {
            shippingTextAreaPlaceHolder = 'Please type in the actual offer including the free shipping offer details';
        }

        if (shippingSelectedItem[item] == "No") {
            shippingTextAreaPlaceHolder = 'Please type in the actual offer details';
        }


        return (
            <div className="bg-white shadow-sm p-3">
                {Object.keys(this.state?.editItem).length > 0 && (
                    <h2 className="mb-2">{'Edit Offer'}</h2>
                )}
                <div>
                    <div className="ff-alt text-secondary-dark font-weight-bold">
                        {'Offer Details:'}
                    </div>
                    {item !== "inStoreCoupon" ? (
                        <React.Fragment>
                            <span class="mb-1">
                                {'Does offer have free shipping?'}
                            </span>
                            <FormGroup>
                                <Input
                                    value={shippingSelectedItem[item]}
                                    type="select"
                                    bsSize="sm"
                                    onChange={(e) =>this.handleSelectItem(e,item)}>
                                    <option value="">{'Please Choose'}</option>
                                    <option {...shippingSelectedItem[item] == "Yes" && `selected`} value="Yes"> {'Yes'} </option>
                                    <option  {...shippingSelectedItem[item] == "No" && `selected`} value="No"> {'No'} </option>
                                </Input>
                            
                                {shippingSelectedItem[item] != '' && shippingSelectedItem[item] != undefined && (
                                    <>
                                        <Input
                                            type="textarea"
                                            className="mt-2 text-dark ph-bold ph-grey"
                                            bsSize="sm"
                                            rows="4"
                                            value={shippingText[item]}
                                            onChange={(e) => this.handleChange(e, 'shippingText', item)}
                                            placeholder={shippingTextAreaPlaceHolder} />
                                        <Input
                                            className="mt-2"
                                            type="text"
                                            bsSize="sm"
                                            placeholder="Store Name"
                                            value={storeName[item]}
                                            onChange={(e) => this.handleChange(e, 'storeName', item)}
                                        />
                                    </>
                                )}
                                <span className='mt-1 Demo__error-message'>{startError?.[item]?.shippingText}</span>
                            </FormGroup>
                            
                        </React.Fragment>
                    ) :
                        (
                            <React.Fragment>
                                <FormGroup>
                                    <Input
                                        value={inStoreSelectedItem[item]}
                                        type="select"
                                        bsSize="sm"
                                        onChange={(e) => {
                                            inStoreSelectedItem[item] = e.target.value;
                                            this.setState({ inStoreSelectedItem, mediaSet: [] });
                                            this.props.getUploadedMedia({ mediaSet: [] });
                                        }}>
                                        <option value="">{'Please Choose'}</option>
                                        <option selected={inStoreSelectedItem[item] == "actualOffer"} value="actualOffer">{' I will type in the actual offer'}</option>
                                        <option value="uploadOfferPdf">{' I will upload offer image/pdf'}</option>
                                    </Input>
                                    {inStoreSelectedItem[item] == 'actualOffer' && (
                                        <>
                                            <Input
                                                type="textarea"
                                                className="mt-2 text-dark ph-bold ph-grey"
                                                bsSize="sm"
                                                rows="4"
                                                value={inStoreText[item]}
                                                onChange={(e) => this.handleChange(e, 'inStoreText', item)}
                                                placeholder="Please type in the actual offer details" />
                                            <Input
                                                className="mt-2"
                                                type="text"
                                                bsSize="sm"
                                                placeholder="Store Name"
                                                value={storeName[item]}
                                                onChange={(e) => this.handleChange(e, 'storeName', item)}
                                            />
                                        </>
                                    )}
                                    {inStoreSelectedItem[item] == "uploadOfferPdf" && (
                                        <React.Fragment>
                                            <div className="mt-2">
                                                <Button
                                                    onClick={() => {
                                                        this.props.toggleEnableFileUpload(true);
                                                        this.props.getUploadedMedia({ mediaSet });
                                                        this.isSetMediaItems = false;
                                                    }}
                                                    className="mt-2 text-secondary-dark p-0"
                                                    color="link">
                                                    <span className="icon-add-new"></span>
                                                    {'Upload Media'}
                                                </Button>
                                                <Row className="mt-3" xs={2} sm={3} md={2} xl={2} form>
                                                    {mediaSet && Array.isArray(mediaSet) && mediaSet.length ?
                                                        mediaSet.map((item, index) => {
                                                            return (
                                                                <Col className="mb-3 offer-uploaded-media-thumbnail" >
                                                                    <div
                                                                        className="d-flex"
                                                                        role="button">
                                                                        <div>
                                                                            <img
                                                                                className="img-fluid img-thumbnail"
                                                                                src={item?.thumbnail}
                                                                                alt="Uploaded media"
                                                                            />
                                                                        </div>
                                                                        <div className="mx-n3 mt-n3">
                                                                            <Button
                                                                                onClick={() => { this.removeImageFromMediaSet(index) }}
                                                                                color="dark"
                                                                                size="sm"
                                                                                title="Remove Media">
                                                                                <FontAwesomeIcon icon="minus" />
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        })
                                                        : null}
                                                </Row>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <span className='Demo__error-message' >{startError?.[item]?.inStoreText}</span>
                                </FormGroup>
                            </React.Fragment>
                        )
                    }

                </div>
                <div>
                    <div className="ff-alt text-secondary-dark font-weight-bold mb-2">
                        {'Deal Valid:'}
                    </div>
                    <FormGroup>
                        <Label className="ff-alt text-secondary-dark font-weight-normal">
                            {'Choose deal Start date:'}
                        </Label>
                        {/* <Input
                            type="select"
                            bsSize="sm"
                            value={startDateSelectedItem[item]}
                            onChange={(e) => this.handleChange(e,'startDateSelectedItem',item)}>
                            <option value="">None</option>
                            <option selected={startDateSelectedItem[item] == "Enter Start Date"} value="Enter Start Date">Enter Start Date</option>
                        </Input> */}
                        {/* {startDateSelectedItem[item] != '' && startDateSelectedItem[item] != undefined && ()} */}
                            <div className="custom-date-picker">
                                <DatePicker
                                    className="form-control-sm form-control"
                                    selected={startDateText[item]}
                                    placeholderText="Enter Start Date"
                                    minDate={moment().toDate()}
                                    onChange={(date) => this.handleChange({target:{value:date}},'startDateText',item)}
                                />
                            </div>
                        <span className='Demo__error-message'>{startError?.[item]?.startDateText}</span>
                    </FormGroup>

                    <FormGroup>
                        <Label className="ff-alt text-secondary-dark font-weight-normal">
                            {'Choose deal Expiration date:'}
                        </Label>
                        {/* <Input
                            type="select"
                            bsSize="sm"
                            value={endDateSelectedItem[item]}
                            onChange={(e) => this.handleChange(e,'endDateSelectedItem',item)}>
                            <option value="">None</option>
                            <option selected={endDateSelectedItem[item] == "Enter Expiration Date"} value="Enter Expiration Date">Enter Expiration Date</option>
                        </Input> */}
                        {/* {endDateSelectedItem[item] != '' && endDateSelectedItem[item] != undefined && ()} */}
                            <div className="custom-date-picker">
                                <DatePicker
                                    className="form-control-sm form-control"
                                    selected={endDateText[item]}
                                    placeholderText="Enter End Date"
                                    minDate={moment().toDate()}
                                    onChange={(date) => this.handleChange({target:{value:date}},'endDateText',item)}
                                />
                            </div>
						<span className='Demo__error-message'>{startError?.[item]?.endDateText}</span>
                    </FormGroup>
                    <hr className="bg-light" />

                    <FormGroup>
                        <Label className="ff-alt text-secondary-dark font-weight-normal">
                            {'Website to Claim deal:'}
                        </Label>
                        <Input
                            type="select"
                            bsSize="sm"
                            value={webSiteSelectedItem[item]}
                            onChange={(e) => this.handleChange(e,'webSiteSelectedItem',item)}>
                            <option value="">Please Choose</option>
                            <option selected={webSiteSelectedItem[item] == "Not Applicable"} value="Not Applicable">{'Not Applicable'}</option>
                            <option selected={webSiteSelectedItem[item] == "Enter Link"} value="Enter Link">{'Enter Link'}</option>
                        </Input>
                        {webSiteSelectedItem[item] == 'Enter Link' && (
                            <Input
                                className="mt-2"
                                type="text"
                                bsSize="sm"
                                value={webSiteText[item]}
                                onChange={(e) => this.handleChange(e,'webSiteText',item)}
                            />
                        )}
                        <span className='Demo__error-message'>{startError?.[item]?.webSiteSelectedItem}</span>
                        <span className='Demo__error-message'>{startError?.[item]?.webSiteText}</span>
                    </FormGroup>

                    <hr className="bg-light" />
                    <FormGroup>
                        <Label className="ff-alt text-secondary-dark font-weight-normal">
                            {'Terms, Details and Exclusions:'}
                        </Label>
                        <Input
                            className="mt-2"
                            bsSize="sm"
                            type="textarea"
                            rows="2"
                            value={termDetailText[item]}
                            onChange={(e) => this.handleChange(e,'termDetailText',item)} />
                            <span className='Demo__error-message'>{startError?.[item]?.termDetailText}</span>
                    </FormGroup>
                </div>

                <div className="text-right mt-3">
                    <Button
                        className="mw"
                        color="primary"
                        size="sm"
                        onClick={() => {
                            this.handleClose(item);
                        }}>{'Cancel'}</Button>
                    {Object.keys(this.state?.editItem).length > 0 ?
                        (<Button
                            disabled={(this.state.shippingText != '' || this.state?.mediaSet?.length > 0) ? false : true}
                            className="mw"
                            color="primary"
                            size="sm"
                            onClick={() => this.handleSubmit({ isUpdate: true, item })}>
                            {'Update'}
                        </Button>)
                        :
                        (<Button
                            disabled={(this.state.shippingText != '' || this.state?.mediaSet?.length > 0) ? false : true}
                            className="mw"
                            color="primary"
                            size="sm"
                            onClick={() => this.handleSubmit({ isUpdate: false, item })}>
                            {'Submit'}
                        </Button>)
                    }

                </div>
            </div >
        )
    }

    handleClose = (item) => {
        let { offerTypeItem, isEditItem, startError } = this.state;
        offerTypeItem[item] =  !offerTypeItem[item];
        isEditItem[item] = !isEditItem[item];
        this.setState({ offerTypeItem, editItem: {},
            mediaSet: [],
            inStoreSelectedItem: {},
            inStoreText: {},
            shippingSelectedItem: {},
            shippingText: {},
            storeName: {},
            startDateSelectedItem:{},
            startDateText: {},
            endDateSelectedItem: {},
            endDateText: {},
            webSiteSelectedItem: {},
            webSiteText: {},
            termDetailText: {},
            startError: {...startError,[item]:{}},
            removedMedia: [],
        });
        this.isSetMediaItems = true;
        this.props.resetUploadedMediaSet();
    }

    dislayOfferDetails = (type) => {
        let { listingItems, enableToggleOfferReply, enableReplyText } = this.state;
        let { loggedInUser } = this.props;
        let data = []
        if (listingItems[type] && Array.isArray(listingItems[type]) && listingItems[type]?.length) {
            data = listingItems[type];
        }
        return (
            <>
             {this.state.enableLoading && (
                        <Loader />
                      ) }
            <ul className="list-unstyled">
                {data && Array.isArray(data) && data?.length ?
                    data.map((item, index) => (
                        <li className="mb-4">
                       
                            <div>
                                <div className="d-flex mx-n1 mb-2">
                                    {item?.special_offer !== null ? (
                                        <div className="px-1">
                                            <span className="text-white wb-break-word">
                                                <a href="#" className="text-reset">
                                                    {`${index + 1}. ${item?.special_offer}`}
                                                </a>{` `}
                                                <span className="text-secondary-dark">
                                                    {`[added by ${item?.create_by_name}]`}
                                                </span>
                                            </span>
                                        </div>
                                    ) :
                                        <Row className="mt-2" xs={2} sm={3} md={2} xl={3} form>
                                            {item?.offer_media && Array.isArray(item?.offer_media) && item?.offer_media?.length ?
                                                item?.offer_media.map((item, index) => {
                                                    return (
                                                        <Col className="mb-3">
                                                            <div
                                                                className="d-flex pr-0 pt-1"
                                                                role="button">
                                                                <div>
                                                                    <img
                                                                        className="img-fluid img-thumbnail"
                                                                        src={item?.file}
                                                                        alt="Uploaded media"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                                : null}
                                        </Row>
                                    }
                                    <div className="px-1 col-auto ml-auto" hidden={!this.checkIfLoggedIn()}>
                                        {item?.conversation && Array.isArray(item?.conversation) && item?.conversation?.length ? null :
                                            <>
                                                <EditBtn
                                                    color="gold"
                                                    onClick={() => { this.setEditItems(item) }}
                                                />
                                                <DeleteBtn
                                                    color="gold"
                                                    onClick={() => {
                                                        this.props.toggleConfirmModal({
                                                            isTextDelete: true,
                                                            uniqueKey: 'delete-offer-detail',
                                                            id: item?.id,
                                                            text: "Are you sure you want to delete?",
                                                        })
                                                    }} />
                                            </>
                                        }
                                        
                                    </div>
                                </div>
                                {item?.claim_deal_link && (
                                    <div>
                                        {'Website to Claim deal: '}
                                        <span>
                                            <a href={item?.claim_deal_link} className="text-reset text-secondary-darker">
                                                {item?.claim_deal_link}
                                            </a>
                                        </span>
                                    </div>
                                )}

                                <div>
                                    {'Offer starts on:'}
                                    <span className="font-weight-bold text-white">
                                        {item?.formated_date?.formated_from_date || ''}
                                    </span>
                                </div>

                                <div>
                                    {'Offer ends on:'}
                                    <span className="font-weight-bold text-white">
                                        {item?.formated_date?.formated_to_date || ''}
                                    </span>
                                </div>
                                <div>
                                    {'Terms, Details and Exclusions:'}
                                    <br />
                                    <span class="wb-break-word text-secondary-darker">{item?.terms_details_exclusions || ''}</span>
                                </div>
                            </div>

                            <div className="mb-2">
                                <i>{'Was this offer helpful?'}</i>{' '}{' '}
                                <span
                                    onClick={() => { this.isHelpFull({ id: item?.id, type: 'helpful', offerType: type }) }}
                                    className="text-secondary-darker font-weight-bold d-inline-block"
                                    role="button">
                                    {` Yes `}
                                </span>
                                <span className="text-secondary-darker font-weight-bold d-inline-block" role="button">{` `}{` `}</span>
                                <span
                                    onClick={() => { this.isHelpFull({ id: item?.id, type: 'not_helpful', offerType: type }) }}
                                    className="text-secondary-darker font-weight-bold d-inline-block ml-2"
                                    role="button">
                                    {` No `}
                                </span>
                            </div>
                            <div className="mb-2">
                                <strong>
                                    {item?.offer_group_status?.helpful_count == 1 ? 1 : 0}
                                </strong>{' '}{' '}
                                {'people found this offer helpful'}
                            </div>

                            <div>
                                {/* Repeat this */}
                                {item?.conversation && Array.isArray(item?.conversation) && item?.conversation?.length ?
                                    item?.conversation.map(dt => (
                                        <div className="fs-13 mb-2">
                                            <div className="d-flex mx-n2 mb-2">
                                                <div className="px-2">
                                                    <img
                                                        className="img-circle _50x50"
                                                        src={dt?.sender?.current_profile_pic ? dt?.sender?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png"}
                                                        onError={(e) => { e.target.src = require('../../../assets/images/user-circle.png') }}
                                                        alt={`${dt?.sender?.first_name} ${dt?.sender?.last_name}`} />
                                                </div>
                                                <div className="px-2 flex-grow-1">
                                                    <div className="mb-1">
                                                        <div>
                                                            <Link to={`/people/${dt?.sender?.username}`}
                                                                className="text-secondary-dark font-weight-bold ff-base">
                                                                {` ${dt?.sender?.first_name} ${dt?.sender?.last_name} : `}
                                                            </Link>
                                                            {` ${dt?.body} `}
                                                        </div>
                                                        <span
                                                            onClick={() => {
                                                                enableToggleOfferReply[dt?.id] = true;
                                                                this.setState({ enableToggleOfferReply });
                                                            }}
                                                            role="button"
                                                            className="text-secondary-dark ff-base font-weight-bold">Reply</span>
                                                        <span>&nbsp;-&nbsp;</span>
                                                        <span>{dt?.sent_at}</span>
                                                    </div>


                                                    <div>
                                                        <div
                                                            hidden={enableToggleOfferReply &&
                                                                enableToggleOfferReply[dt?.id] ?
                                                                false : true}>
                                                            <Media className="media-post mt-3">
                                                                <Media>
                                                                    <Media
                                                                        className="img-circle _30x30"
                                                                        src={loggedInUser?.current_profile_file ? loggedInUser?.current_profile_file : 'https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png'}
                                                                        onError={(e) => { e.target.src = require('../../../assets/images/user-circle.png') }}
                                                                        alt="User Image"
                                                                    />
                                                                </Media>
                                                                <Media body>
                                                                    <FormGroup className="mb-0">
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                enableReplyText[dt?.id] = e.target.value;
                                                                                this.setState({ enableReplyText });
                                                                            }}
                                                                            bsSize="sm"
                                                                            value={enableReplyText[dt?.id]}
                                                                            className="text-primary mb-2"
                                                                            type="textarea"
                                                                            name="reply"
                                                                            placeholder="Write a reply"
                                                                        />
                                                                        <div className="text-right">
                                                                            <div className="text-right">
                                                                                
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        enableReplyText[dt?.id] = '';
                                                                                        this.setState({ enableReplyText });
                                                                                    }}
                                                                                    size="sm"
                                                                                    color="primary"
                                                                                    className="ml-0">
                                                                                    {'Cancel'}
                                                                                </Button>
                                                                                <Button
                                                                                    disabled={enableReplyText[dt?.id] &&
                                                                                        enableReplyText[dt?.id].length ? false : true}
                                                                                    onClick={() => {
                                                                                        this.addComments({
                                                                                            isComment: false,
                                                                                            text: enableReplyText[dt?.id],
                                                                                            type,
                                                                                            item,
                                                                                            parent: dt
                                                                                        })
                                                                                    }}
                                                                                    size="sm"
                                                                                    color="primary"
                                                                                    className=" mr-2">
                                                                                    {'Submit'}
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Media>
                                                            </Media>
                                                            <hr />
                                                        </div>
                                                    </div>


                                                    {/* All Replies */}
                                                    <div>
                                                        {dt.comment_reply && Array.isArray(dt?.comment_reply) && dt?.comment_reply?.length ?
                                                            dt?.comment_reply.map(reply_data => (
                                                                <div className="fs-13 mb-1">
                                                                    <div className="d-flex mx-n2">
                                                                        <div className="px-2">
                                                                            <img
                                                                                className="img-circle _30x30"
                                                                                src={reply_data?.sender?.current_profile_pic ? reply_data?.sender?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png"}
                                                                                onError={(e) => { e.target.src = require('../../../assets/images/user-circle.png') }}
                                                                                alt="" />
                                                                        </div>
                                                                        <div className="px-2 flex-grow-1">
                                                                            <div className="mb-1">
                                                                                <div>
                                                                                    <Link to={`/people/${reply_data?.sender?.username}`}
                                                                                        className="text-secondary-dark font-weight-bold ff-base">
                                                                                        {` ${reply_data?.sender?.first_name} ${reply_data?.sender?.last_name} : `}
                                                                                    </Link>
                                                                                    {` ${reply_data?.body} `}
                                                                                </div>
                                                                                <span
                                                                                    onClick={() => {
                                                                                        enableToggleOfferReply[reply_data?.id] = true;
                                                                                        this.setState({ enableToggleOfferReply });
                                                                                    }}
                                                                                    role="button"
                                                                                    className="text-secondary-dark ff-base font-weight-bold">
                                                                                    {'Reply'}
                                                                                </span>
                                                                                <span>&nbsp;-&nbsp;</span>
                                                                                <span>{reply_data?.sent_at}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <div
                                                                            hidden={enableToggleOfferReply &&
                                                                                enableToggleOfferReply[reply_data?.id] ?
                                                                                false : true}>
                                                                            <Media className="media-post mt-3">
                                                                                <Media>
                                                                                    <Media
                                                                                        className="img-circle _30x30"
                                                                                        src={loggedInUser?.current_profile_file ? loggedInUser?.current_profile_file : "https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png"}
                                                                                        onError={(e) => { e.target.src = require('../../../assets/images/user-circle.png') }}
                                                                                        alt="User Image"
                                                                                    />
                                                                                </Media>
                                                                                
                                                                                <Media body>
                                                                                    <FormGroup className="mb-0">
                                                                                        <Input
                                                                                            onChange={(e) => {
                                                                                                enableReplyText[reply_data?.id] = e.target.value;
                                                                                                this.setState({ enableReplyText });
                                                                                            }}
                                                                                            bsSize="sm"
                                                                                            value={enableReplyText[reply_data?.id]}
                                                                                            className="mb-2"
                                                                                            type="textarea"
                                                                                            name="reply"
                                                                                            placeholder="Write a reply"
                                                                                        />
                                                                                        <div className="text-right">
                                                                                            <div className="text-right">
                                                                                                
                                                                                                <Button
                                                                                                    onClick={() => {
                                                                                                        enableReplyText[reply_data?.id] = '';
                                                                                                        this.setState({ enableReplyText });
                                                                                                    }}
                                                                                                    size="sm"
                                                                                                    color="primary"
                                                                                                    className="ml-0">
                                                                                                    {'Cancel'}
                                                                                                </Button>
                                                                                                <Button
                                                                                                    disabled={enableReplyText[reply_data?.id] && enableReplyText[reply_data?.id].length ? false : true}
                                                                                                    onClick={() => {
                                                                                                        this.addComments({
                                                                                                            isComment: false,
                                                                                                            text: enableReplyText[reply_data?.id],
                                                                                                            type,
                                                                                                            item,
                                                                                                            parent: dt
                                                                                                        })
                                                                                                    }}
                                                                                                    size="sm"
                                                                                                    color="primary"
                                                                                                    className=" mr-2">
                                                                                                    {'Submit'}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </FormGroup>
                                                                                </Media>
                                                                            </Media>
                                                                            <hr />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    : null}

                                <div className="bg-white text-dark p-2">
                                    <div className="d-flex mx-n2 mb-2">
                                        <div className="px-2">
                                            <img
                                                className="test img-circle _50x50"
                                                src={loggedInUser?.current_profile_file}
                                                onError={(e) => {
                                                    e.target.src = "https://userdatawikireviews.s3.amazonaws.com/images/profile_blue.png"
                                                }}
                                                alt={loggedInUser?.current_profile_file}
                                            />
                                        </div>
                                        <div className="px-2 col">
                                            <Input
                                                bsSize="sm"
                                                type="textarea"
                                                value={this.state.commentText}
                                                onChange={(e) => { this.setState({ commentText: e.target.value }) }}
                                                rows="2" />
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <Button
                                            onClick={() => { this.addComments({ isComment: true, text: this.state.commentText, type, item }) }}
                                            color="primary"
                                            size="sm">
                                            {'Submit'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )) : null}
            </ul>
        </>
        )
    }

    handleSetItem = (item) => {
        let { offerTypeItem } = this.state;
        offerTypeItem[item] = !offerTypeItem[item];
        this.setState({ offerTypeItem });
    }
    setEditItems = (item) => {
        let { startError, defaultOfferSelected, inStoreSelectedItem, inStoreText, shippingSelectedItem, shippingText, termDetailText, startDateSelectedItem, startDateText, endDateSelectedItem, endDateText, webSiteSelectedItem, webSiteText, storeName, mediaSet } = { ...this.state };
        let type = '';
        if (item?.offer_type) {
            defaultOfferSelected = item?.offer_type;
            type = item?.offer_type == "Coupon Code" ? 'couponCode' : item?.offer_type == "In Store Coupon" ? "inStoreCoupon" : "otherOffers"
        }

        if (item?.claim_deal_link && type) {
            webSiteSelectedItem[type] = "Enter Link";
            webSiteText[type] = item?.claim_deal_link;
        }else{
            webSiteSelectedItem[type] = "Not Applicable"; 
        }

        if (item?.store_name && type) {
            storeName[type] = item?.store_name;

        }
        if (item?.terms_details_exclusions && type) {
            termDetailText[type] = item?.terms_details_exclusions
        }
        if (type != 'inStoreCoupon' && item?.special_offer && type) {
            shippingSelectedItem[type] = "Yes";
            shippingText[type] = item?.special_offer;

        } else {
            if (item?.special_offer && type && !item?.offer_media?.length) {
                inStoreSelectedItem[type] = "actualOffer";
                inStoreText[type] = item?.special_offer;
            }else{
                inStoreSelectedItem[type] = "uploadOfferPdf";
                mediaSet = item?.offer_media;
            }
        }
        if (item?.from_date && type) {
            startDateSelectedItem[type] = "Enter Start Date";
            startDateText[type] = new Date(item?.from_date);
        }
        if (item?.to_date && type) {
            endDateSelectedItem[type] = "Enter Expiration Date";
            endDateText[type] = new Date(item?.to_date);
        }
        this.setState({
            offerTypeItem: {
                couponCode: defaultOfferSelected == 'Coupon Code' ? true : false,
                inStoreCoupon: defaultOfferSelected == 'In Store Coupon' ? true : false,
                otherOffers: defaultOfferSelected == 'Other Offer' ? true : false,
            },
            editItem: item,
            startError,
            defaultOfferSelected,
            inStoreText,
            shippingSelectedItem,
            shippingText,
            termDetailText,
            startDateSelectedItem,
            startDateText,
            endDateSelectedItem,
            endDateText,
            webSiteSelectedItem,
            webSiteText,
            storeName,
            mediaSet,
            removedMedia: []
        });


    }
    handleReset = () => {
        
        this.setState({
            offerTypeItem: {
                couponCode: false,
                inStoreCoupon: false,
                otherOffers: false,
            },
            editItem: {},
            mediaSet: [],
            inStoreSelectedItem: {},
            inStoreText: {},
            shippingSelectedItem: {},
            shippingText: {},
            storeName: {},
            startDateSelectedItem: {},
            startDateText: {},
            endDateSelectedItem: {},
            endDateText: {},
            webSiteSelectedItem: {},
            webSiteText: {},
            termDetailText: {},
            removedMedia:[],
        });
        this.isSetMediaItems = true;
        this.props.resetUploadedMediaSet();
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile} = this.props;
        if(my_profile && my_profile.id && authId && (authId == my_profile.id)){
            return true;
        }else{
            return false;
        }
    }

    deleteMedia = (data) => {
        return new Promise((resolve, reject) => {
            callApi('/upload/multiuploader_delete_multiple/?delete_videos=True', 'POST', data).
                then((response) => {
                    if (response && responseCodeValidator(String(response.code))) {
                        return resolve();
                    }
                    else {
                        return reject(new Error(response));
                    }
                });
        });
    }

    render() {
        let { offerTypeItem, listingItems } = this.state;
        let { loggedInUser } = this.props;
  
        return (
            <div className="text-secondary-dark p-3 mb-3" style={{ backgroundColor: '#dac994' }}>
                <h2 className="mr-2">{'Other Offers'}</h2>
                <hr className="bg-dark" />

                <div>
                    <div className="mb-3">
                        <CollapseBasic
                            title="Coupon Code"
                            noHr={true}
                            containerClass="bg-transparent type-secondary-dark"
                            bodyClass="mt-3 text-secondary-dark"
                            isOpen={true}
                            size="sm">
                            {this.dislayOfferDetails('Coupon Code')}
                        </CollapseBasic>
                        <div>
                            <div className="text-right mb-2">
                                <Button
                                    onClick={() => {
                                        if(!this.checkIfLoggedIn()){
                                            this.props.forceToggleLoginModal();
                                            return false;
                                        }
                                        this.setState({ defaultOfferSelected: 'Coupon Code', editItem: {}, startError: { ...this.state.startError, "couponCode": {} } }, () => {
                                            this.handleClose('couponCode')
                                        });
                                    }}
                                    color="link"
                                    className="p-0 text-secondary-dark font-weight-bold">
                                    {'Add'}
                                </Button>
                                <hr className="bg-secondary-dark" />
                            </div>
                            {offerTypeItem['couponCode'] && (this.clickToAddNew('couponCode'))}
                        </div>
                    </div>

                    <div className="mb-3">
                        <CollapseBasic
                            title="In Store Coupon"
                            noHr={true}
                            containerClass="bg-transparent type-secondary-dark"
                            bodyClass="mt-3 text-secondary-dark"
                            isOpen={true}
                            size="sm">
                            {this.dislayOfferDetails('In Store Coupon')}
                        </CollapseBasic>
                        <div>
                            <div className="text-right mb-2">
                                <Button
                                    onClick={() => {
                                        if(!this.checkIfLoggedIn()){
                                            this.props.forceToggleLoginModal();
                                            return false;
                                        }
                                        this.setState({ defaultOfferSelected: 'In Store Coupon', startError: { ...this.state.startError, "inStoreCoupon": {} } }, 
                                        () => {
                                            this.handleClose('inStoreCoupon');
                                        })
                                    }}
                                    color="link"
                                    className="p-0 text-secondary-dark font-weight-bold">
                                    {'Add'}
                                </Button>
                                <hr className="bg-secondary-dark" />
                            </div>

                            {offerTypeItem['inStoreCoupon'] && (this.clickToAddNew('inStoreCoupon'))}
                        </div>
                    </div>

                    <div className="mb-3">
                        <CollapseBasic
                            title="Other Offers"
                            noHr={true}
                            containerClass="bg-transparent type-secondary-dark"
                            bodyClass="mt-3 text-secondary-dark"
                            isOpen={true}
                            size="sm">
                            {this.dislayOfferDetails('Other Offer')}
                        </CollapseBasic>
                        <div>
                            <div className="text-right mb-2">
                                <Button
                                    onClick={() => {
                                        if(!this.checkIfLoggedIn()){
                                            this.props.forceToggleLoginModal();
                                            return false;
                                        }
                                        this.setState({
                                            defaultOfferSelected: 'Other Offer',
                                            startError: { ...this.state.startError, "otherOffers": {} }
                                        }, () => {
                                            this.handleClose('otherOffers')
                                        });
                                    }}
                                    color="link"
                                    className="p-0 text-secondary-dark font-weight-bold">
                                    {'Add'}
                                </Button>
                                <hr className="bg-secondary-dark" />
                            </div>

                            {offerTypeItem['otherOffers'] && (this.clickToAddNew('otherOffers'))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapProps = (dispatch) => ({
    get_uploaded_media: (data) => dispatch(get_uploaded_media(data)),
})
export default connect(null, mapProps)(OfferDetails);