
const initialState = {
    item_details: null,
    back_to_listing: null,
    cat_taxonomy_list: null,
    flags_data: null,
    review_listing: null,
    pins_data: null,
	uploaded_media:null,
    uploaded_media_follow:null,
    item_details_loading:false
};


const itemDetail = (state = initialState, action) => {

    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "GET_TYPE_DETAILS_BY_ID":
            state.item_details = action.payload;
            return { ...state };

        case "GET_BACK_TO_CATEGORIE":
            state.back_to_listing = action.payload;
            return { ...state };

        case "GET_CATEGORY_TAXONOMY_LIST":
            state.cat_taxonomy_list = action.payload;
            return { ...state };

        case 'GET_ITEM_FLAGS':
            state.flags_data = action.payload;
            return { ...state };

        case 'FETCH_REVIEW_LISTING_ITEMS':
            state.review_listing = action.payload;
            return { ...state };

        case 'GET_PROFESSIONAL_PINS':
            state.pins_data = action.payload;
            return { ...state };
			
        case 'GET_UPLOADED_MEDIA':
            state.uploaded_media = action.payload;
            return { ...state };
        case 'GET_UPLOADED_MEDIA_FOLLOW':
            state.uploaded_media_follow = action.payload;
            return { ...state };
		case 'RESET_ITEM_DETAIL':
            return initialState;

        case 'ITEM_DETAIL_LOADING':
            state.item_details_loading = action.payload
            return {...state}
        default:
            return state;
    }
}

export default itemDetail;