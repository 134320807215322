import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { gapi } from "gapi-script";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import FB from 'fb';
import {
  Button, Col, FormGroup, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane
} from "reactstrap";
import {
  already_on_wikireviews, clear_search_data, fetch_invited, fetch_search_friends, add_connection_requests, send_invitation_requests, send_import_request, get_providers_on_wikireviews
} from "../../../actions/connections";
import { callApi, } from "../../../utils/apiCaller";
import { development_connection_keys } from '../../../utils/constants';
import queryString from 'query-string';
import GoogleAuth from "./googleAuth";
import { Link } from "react-router-dom";

const validate = Yup.object().shape({
  email_set: Yup.array()
    .of(
      Yup.object().shape({
        email: Yup.string().min(4, "too short").required("Required"),
      })
    )
    .required("Must have connection address")
    .min(3, "Minimum of 3 connection address"),

});
class FindFriends extends Component {
  constructor(props) {
    super(props)
    this.myScrollSearchRef = React.createRef();
    this.state = {
      findFriendsType: "already",
      alreadyInvited: [],
      yahooCode: null,
      yahooAccessToken: "UXUHVAKc71ixrOisPxiKnjC.EQ24ppHuB_OImHqP7tHaQdeoRkD7bozKFdXeaLQSl_efVkUmGQe14ffV.1Y.DNzq_QXWmOr5p5jxw2TZDcFDDnCmtJd2O9ExFUSugCF0OvVisdyH5Yqlfyqj4Jd.RhKneUgoMP3Xhac92tk9bGpfphLNp8oI76.bI.y4pQMAs10nwUE16CzSO0h4CqdkvKO0MV9PzKwZw398X8vtRMrxf4OYhzcn4UVvIAJhXY5wg1UuD5nQSKcuJYibe3J6JBqEkWBoLdaoLCFyO2u9Ve6vcyqdmoBMqqiVP_75.TCOjX2JuTtsk1aFKnD0L45qos.BmCGIa6eIxq7AsHuhHC9FQToXa0ZY4PbhGbUDtpDebfYaJtHsDnOe.jW_HN_jUE73M5TU6H73w8ZoIyieHV0i8WSBLvmwoV3OAHQDnYnokShCobqsJUhUousPZLr1jwWxgZ64cTcmceJmpjsFq1GEmP3ZN11ttwwBXWppz2jY4.DZVSsIL4fYSC4zEAG.AGd3Yp0nf4v7TmyjxG0EVBglL5UeDuRwBAe.UBdyFgqQdpwAFnO5Vrjse1FBQO5LyqPRNvTCWQcNz9TSqCZF81lZewQB1cC10FvQ7Vo3h35Tb_00u54DsfHKDJiVqCy3pOn415pXCTIW0tD6U4F8Mn1o5ClHISv2UTpu1leiZuWRKcXEPauM9gB6YJx8LMPMGD0luNEzez.yra.LlyehqKxEYeZ0zBvkCAOSdqIkj8Glhf51OdWzXWNG.QAybgDr2Qc.PmLlU138dKjTAplwTqcN8NrN0rQmuSnmzaiDZuZ5481lFwCZ3avNv4hxhJ5TWPXV7LGnT0SnNpxo6aA46OZV8DSIxaeprdlnuLiusUlSXTxJdRQkwVc4fN.jPRA2NRTSD9VAsGvEqYH_w9Glz_wGdtle9MCqAwcNCEsVrV7VDOISAWMX4DubqMqmWNiCRsuiOIPrkI5Xu5JolQ--" || null,
      outlookCode: null,
      outlookAccessToken: null,
      searchResults: '',
      inviteEmails: {},
      searchError: "",
      alreadyOnWiki: null,
      inviteToWiki: null,
      searchOnWiki: null,
      isLoadingAlreadyOnWiki: true,
      isLoadingInviteToWiki: true,
      isLoadingSearchOnWiki: true,
      selectallSearch: false,
      selectedIds: [],
      allChecked: false,
      allCheckedInvite: false,
      allCheckedAlready: false,
      afterSearch: false,
      clickonsearch: false,
      providersConnections: null,
      newInviteArry: [],
      loading: false,
      emailError: "",
      addConnectionForm: {
        note: "",
        email_set: [
          {
            email: ""
          },
        ],

      },
      note: "",
      email_set: [
        {
          email: ""
        },
      ]      
    }
  }

  componentWillReceiveProps(nextProps) {
    let { searchResults, findFriendsType, clickonsearch } = this.state;

    if (nextProps.my_profile_info) {
      this.setState({ myProfileInfo: nextProps.my_profile_info });
    }

    if (nextProps?.already_on_wikireview?.results?.length) {
      const newArr2 = nextProps?.already_on_wikireview?.results.map(v => ({ ...v, isChecked: false }))
      if (findFriendsType == "already") {
        this.setState({
          alreadyOnWiki: newArr2,
          isLoadingAlreadyOnWiki: false,
        });
      } else if (findFriendsType == "search") {
        this.setState({
          searchOnWiki: newArr2,
          isLoadingSearchOnWiki: true,
          afterSearch: false
        });
      }
    } else {
      this.setState({ alreadyOnWiki: [], isLoadingAlreadyOnWiki: false, searchOnWiki: [], afterSearch: false });
    }

    if (nextProps?.fetch_invited_items?.results?.length) {
      const newArr = nextProps?.fetch_invited_items?.results.map(v => ({ ...v, isChecked: false }))
      this.setState({
        inviteToWiki: newArr,
        isLoadingInviteToWiki: false,
        newInviteArry: nextProps?.fetch_invited_items
      });
    } else {
      this.setState({ inviteToWiki: [], isLoadingInviteToWiki: false, afterSearch: false });
    }

    if (nextProps?.get_search_friends?.results?.length) {
      const newArr1 = nextProps?.get_search_friends?.results.map(v => ({ ...v, isChecked: false }))
      if (searchResults != "") {
        if (findFriendsType == "already") {
          this.setState({
            alreadyOnWiki: newArr1,
            isLoadingAlreadyOnWiki: true,
            afterSearch: false
          });
        } else if (findFriendsType == "invite") {
          this.setState({
            inviteToWiki: newArr1,
            isLoadingInviteToWiki: true,
            afterSearch: false
          });

        } else {
          this.setState({
            searchOnWiki: newArr1,
            isLoadingSearchOnWiki: true,
            afterSearch: false
          });
        }
      }
    } else if (nextProps?.get_search_friends && nextProps.get_search_friends.length > 0 && clickonsearch == true) {
      const newArr5 = nextProps?.get_search_friends?.map(v => ({ ...v, isChecked: false }))
      if (findFriendsType == "already") {
        this.setState({
          alreadyOnWiki: newArr5,
          isLoadingAlreadyOnWiki: true,
          afterSearch: false
        });
      } else if (findFriendsType == "invite") {
        this.setState({
          inviteToWiki: newArr5,
          isLoadingInviteToWiki: true,
          afterSearch: false
        });

      } else {
        this.setState({
          searchOnWiki: newArr5,
          isLoadingSearchOnWiki: true,
          afterSearch: false
        });
      }
    } else if (nextProps?.get_search_friends && nextProps.get_search_friends.length == 0 && clickonsearch == true) {
      this.setState({ searchOnWiki: [], alreadyOnWiki: [], inviteToWiki: [], isLoadingAlreadyOnWiki: false, isLoadingSearchOnWiki: false, afterSearch: true });
    }

    if (nextProps.providers_on_wikireviews) {
      this.setState({
        providersConnections: nextProps.providers_on_wikireviews,
      });
    }
  }

  findImportContacts = async () => {
    try {
      let { searchTerm } = this.state;
      let url = `/api/findimportcontacts/?search=${searchTerm}&on_wiki=false`;
      let response = await callApi(url, 'GET');
      if (response?.code == 200) {
        this.setState({ searchResults: response });
      }
    } catch (err) {
      console.log(err)
    }
  }

  inviteFriendsEmail = async () => {
    try {
      let url = `/invitefriendsemail/`;
      let data = {
        "searchconnection": null,
        "email": "",
        "search": "",
        "email_set": [], // [{ "email": "wikireviewstesting@yahoo.com" }],
        "importsearch": "",
        "note": "testing description.",
        "multiple": true
      }
      let response = await callApi(url, 'POST', data);

    } catch (err) {
      console.log(err);
    }
  }

  setLocalItems = ({ yahoo, outlook, twitter, facebook, linkedin, replace }) => {
    localStorage.setItem('yahoo', yahoo);
    localStorage.setItem('outlook', outlook);
    localStorage.setItem('twitter', twitter);
    localStorage.setItem('facebook', facebook);
    localStorage.setItem('linkedin', linkedin);
    if (replace) this.props.history.replace('/myprofile');
  }

  componentDidMount() {
    const { location } = this.props
    let { code, oauth_token, oauth_verifier } = queryString.parse(location?.search);
    //const {oauth_token, oauth_verifier} = queryString.parse(location?.search);
    if (code) {
      let isYahooTrue = localStorage.getItem('yahoo');
      let isOutlookTrue = localStorage.getItem('outlook');
      let isFacebookTrue = localStorage.getItem('facebook');
      let isLinkedinTrue = localStorage.getItem('linkedin');

      if (isYahooTrue == "true") {
        this.getYahooTokenFromCode(code);
      }
      if (isOutlookTrue == "true") {
        this.getOutlookTokenFromCode(code);
      } // M.R3_BAY.d0f6d4d5-1665-2b12-f7a6-e4dd8b424b31
      if (isFacebookTrue == "true") {
        this.getFacebookTokenFromCode(code)
      }

      if (isLinkedinTrue == "true") {
        this.getLinkedinTokenFromCode(code)
      }

    }
    if (oauth_token && oauth_verifier) {
      let isTwitterTrue = localStorage.getItem('twitter');
      if (isTwitterTrue == "true") {
        this.getTwitterTokenFromCode(oauth_token, oauth_verifier);
      }
    }
    this.props.get_providers_on_wikireviews();
  }

  renderImportBtn = ({ onClick, colorClass, title, iconSpan }) => (
    <Button
      className="mb-2"
      onClick={onClick}
      size="lg"
      color={colorClass}
      block>
      <div className="d-flex">
        {iconSpan}
        <span>
          {`Import from `}<span className="text-uppercase">{title}</span>
        </span>
      </div>
    </Button>
  );

  showToastMessage = (message) => {
    toast(message, {
      autoClose: 2500,
      className: "black-background",
      bodyClassName: "red-hunt",
      progressClassName: "cc",
    });
  }

  /* Yahoo Authorization Functions Start Here */
  redirectYahooForLogin = () => {
    this.setLocalItems({ yahoo: true, outlook: false, twitter: false, facebook: false, linkedin: false });
    let { client_id, secret_key, scope, redirect_uri } = development_connection_keys.yahoo;
    let newUrl = `https://api.login.yahoo.com/oauth2/request_auth?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}&scope=${scope}&nonce=${secret_key}`;
    //window.open(newUrl, "_blank"); // '_self';
    window.open(newUrl, "_blank"); // '_self';
  };

  getYahooTokenFromCode = async (code) => {
    try {
      let url = "/api/import/"; //"https://wikireviews.com/api/import/";
      let { client_id, secret_key, redirect_uri } = development_connection_keys.yahoo;
      let data = {
        client_id,
        client_secret: secret_key,
        redirect_uri,
        code,
        grant_type: "authorization_code",
        url: "https://api.login.yahoo.com/oauth2/get_token",
      };

      let yahooTokenInfo = await callApi(url, "POST", data);

      if (yahooTokenInfo?.message?.access_token) {
        this.setState({ yahooAccessToken: yahooTokenInfo?.message?.access_token }, () => { this.fetchContactsYahoo() })
      }
    } catch (err) {
      console.log({ err });
    }
  }

  fetchContactsYahoo = async () => {
    let token = `Bearer ${this.state.yahooAccessToken}`

    try {
      /*let options = {
          url: "https://social.yahooapis.com/v1/user/me/contacts?format=json",
          method: "GET",
          headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` },
          withCredentials: false
      };
  
      let yahooContacts = await axios(options);*/
      let url = `/api/yahoo-contacts/`;
      let data = {
        "token": token
      }
      let yahooContacts = await callApi(url, 'POST', data);

    } catch (err) {
      console.log({ err });
    }
  };


  /* Yahoo Authorization Functions End Here */
  QueryStringToJSON = (data) => {
    var pairs = data.split('&');

    var result = {};
    pairs.forEach(function (pair) {
      pair = pair.split('=');
      result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
  }

  /* Twitter Authorization Functions Start Here */
  redirectTwitterForLogin = async () => {
    this.setLocalItems({ twitter: true, yahoo: false, outlook: false, facebook: false, linkedin: false });
    let { client_id, secret_key, scope, redirect_uri } = development_connection_keys.twitter;
    try {
      let url = "/api/twitter-token/"; //"https://wikireviews.com/api/import/";
      let { consumer_key, consumer_secret_key, redirect_uri } = development_connection_keys.twitter;
      let data = {};
      data.outh_callback = redirect_uri;
      const response = await callApi(url, "POST");
      let respnData = this.QueryStringToJSON(response?.data);
      const { oauth_token } = respnData;
      //window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
      let newUrl = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
      window.open(newUrl, "_self"); // '_self';
    } catch (error) {
      console.error(error);
    }

  };

  getTwitterTokenFromCode = async (oauth_token, oauth_verifier) => {
    let url = "/api/importOutlookContacts/"; //"https://wikireviews.com/api/importOutlookContacts/";
    //let { client_id, secret_key, redirect_uri } = development_connection_keys.outlook;
    if (oauth_token && oauth_verifier) {
      try {
        //Oauth Step 3
        let url = `/api/twitter-access-token/?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`;
        const response = await callApi(url, "POST");
        //let accessToken = await callApi("/api/oauth2/token/", 'POST');
        //let token = `Bearer ${accessToken?.access_token}`
        let respnData = this.QueryStringToJSON(response?.data);
        let user_id = respnData?.user_id;
        let screen_name = respnData?.screen_name;
        let friendapiurl = `/api/friends/list/json/?user_id=${user_id}&cursor=-1&screen_name=${screen_name}&skip_status=true&include_user_entities=false`;
        let responseTwitter = await callApi(friendapiurl, "GET");

        if (responseTwitter?.users) {
          let filteredItems = [];
          if (responseTwitter?.users?.length) {
            responseTwitter?.users.forEach(item => {
              filteredItems.push({
                name: item?.name,
                email: "",
                provider: "twitter"
              })
            });
          }
          if (filteredItems && Array.isArray(filteredItems) && filteredItems.length > 0) {
            let urlNew = "/api/friendsinvite/";
            let dataNew = {
              "contacts": filteredItems
            }
            let response = callApi(urlNew, 'POST', dataNew);
            if (response) {
              toast("Your contacts are imported from outlook", {
                autoClose: 2500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
              });
              //if(response["New imported contacts"] > 0){
              this.props.setTabView("5")
              //}
            }
          }

          this.setLocalItems({ yahoo: false, outlook: false, twitter: false, facebook: false, linkedin: false, replace: true });
        }
      } catch (error) {
        console.error(error);
      }
    }

  };


  /* Twitter Authorization Functions End Here */


  /* Facebook Authorization Functions Start Here */
  redirectFacebookForLogin = () => {
    this.setLocalItems({ yahoo: false, outlook: false, twitter: false, facebook: true, linkedin: false });
    let { app_id, app_secret, redirect_uri, scope } = development_connection_keys.facebook;
    let loginurl = FB.getLoginUrl({
      client_id: app_id,
      client_secret: app_secret,
      scope: 'email,user_friends',
      redirect_uri: redirect_uri
    });
    window.open(loginurl, "_self");
  };

  getFacebookTokenFromCode = async (code) => {
    // let url = "/api/importOutlookContacts/"; //"https://wikireviews.com/api/importOutlookContacts/";
    let { app_id, app_secret, redirect_uri, scope } = development_connection_keys.facebook;

    console.log({redirect_uri});

    FB.api('oauth/access_token', {
      client_id: app_id,
      client_secret: app_secret,
      redirect_uri: redirect_uri,
      code: code
    }, function (res) {
      if (!res || res.error) {
        console.log(!res ? 'error occurred' : res.error);
        return;
      }

      var accessToken = res.access_token;
      var expires = res.expires ? res.expires : 0;

      FB.setAccessToken(accessToken);
      FB.api(
        "/me/friends",
        function (response) {
          if (response && !response.error) {
            if (response?.data?.length) {
              let filteredItems = [];
              if (response?.data?.length) {
                response?.data.forEach(item => {
                  filteredItems.push({
                    name: item?.name,
                    email: item?.email,
                    provider: "facebook"
                  })
                });
              }
              if (filteredItems && Array.isArray(filteredItems) && filteredItems.length > 0) {
                let urlNew = "/api/friendsinvite/";
                let dataNew = {
                  "contacts": filteredItems
                }
                let response = callApi(urlNew, 'POST', dataNew);
                if (response) {
                  toast("Your contacts are imported from outlook", {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                  });
                  //if(response["New imported contacts"] > 0){
                  this.props.setTabView("5")
                  //}
                }
              }
            }
          }
        }
      );
    });
    this.setLocalItems({ yahoo: false, outlook: false, twitter: false, facebook: false, linkedin: false, replace: true });

  };

  /* Facebook Authorization Functions End Here */

  /* Linkedin Authorization Functions Start Here */
  redirectLinkedinForLogin = () => {
    this.setLocalItems({ yahoo: false, outlook: false, twitter: false, facebook: false, linkedin: true });
    let { client_id, client_secret, response_type, redirect_uri, scope } = development_connection_keys.linkedin;
    let newUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=foobar&scope=${scope}`;
    window.open(newUrl, "_self"); // '_self';
  };


  getLinkedinTokenFromCode = async (code) => {

    /*let url = "/api/importOutlookContacts/"; //"https://wikireviews.com/api/importOutlookContacts/";
    let { client_id, secret_key, redirect_uri } = development_connection_keys.outlook;
    let data = {
      client_id,
      client_secret: secret_key,
      redirect_uri,
      code,
      grant_type: "authorization_code",
      url: "https://login.microsoftonline.com/common/oauth2/v2.0/token",
    };
    let responseOutlook = await callApi(url, "POST", data);
  
    if (responseOutlook?.code == 200) {
      let filteredItems = [];
      if (responseOutlook?.message?.value?.length) {
        responseOutlook?.message?.value.forEach(item => {
          filteredItems.push({
            name: item?.DisplayName,
            email: item.EmailAddresses[0].Address,
            provider: "outlook"
          })
        });
      }
      if (filteredItems && Array.isArray(filteredItems) && filteredItems.length > 0) {
        let urlNew = "/api/friendsinvite/";
        let dataNew = {
          "contacts": filteredItems
        }
        let response = callApi(urlNew, 'POST', dataNew);
        if (response && Object.keys(response)?.length > 0) {
          toast("Your contacts are imported from outlook", {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
          //if(response["New imported contacts"] > 0){
          this.props.setTabView("5")
          //}
        }
      }

      this.setLocalItems({ yahoo: false, outlook: false,twitter:false,facebook:false,linkedin:false, replace: true });
    }*/
  };

  /* Linkedin Authorization Functions End Here */

  /* Oulook Authorization Functions Start Here */
  redirectOutlookForLogin = () => {
    this.setLocalItems({ yahoo: false, outlook: true, twitter: false, facebook: false, linkedin: false });
    let { client_id, redirect_uri, scope } = development_connection_keys.linkedin;
    let newUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&scope=${scope}&redirect_uri=${redirect_uri}&response_type=code`;
    window.open(newUrl, "_self"); // '_self';
  };

  getOutlookTokenFromCode = async (code) => {
    let url = "/api/importOutlookContacts/"; //"https://wikireviews.com/api/importOutlookContacts/";
    let { client_id, secret_key, redirect_uri } = development_connection_keys.outlook;
    let data = {
      client_id,
      client_secret: secret_key,
      redirect_uri,
      code,
      grant_type: "authorization_code",
      url: "https://login.microsoftonline.com/common/oauth2/v2.0/token",
    };
    let responseOutlook = await callApi(url, "POST", data);

    if (responseOutlook?.code == 200) {
      let filteredItems = [];
      if (responseOutlook?.message?.value?.length) {
        responseOutlook?.message?.value.forEach(item => {
          filteredItems.push({
            name: item?.DisplayName,
            email: item.EmailAddresses[0].Address,
            provider: "outlook"
          })
        });
      }
      if (filteredItems && Array.isArray(filteredItems) && filteredItems.length > 0) {
        let urlNew = "/api/friendsinvite/";
        let dataNew = {
          "contacts": filteredItems
        }
        let response = callApi(urlNew, 'POST', dataNew);
        if (response) {
          toast("Your contacts are imported from outlook", {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
          //if(response["New imported contacts"] > 0){
          this.props.setTabView("5")
          //}
        }
      }

      this.setLocalItems({ yahoo: false, outlook: false, twitter: false, facebook: false, linkedin: false, replace: true });
    }
  };
  /* Oulook Authorization Functions End Here */

  /* Google Authorization Functions Start Here */
  authorize = () => {    
    let { client_id, scope, redirect_uri } = development_connection_keys.google;
    gapi.auth.authorize({
      client_id: client_id,
      scope: scope,
      // response_type: 'code',
      prompt: 'none',
      immediate: false,
      redirect_uri
    }, (response) => {
      this.handleAuthorization(response)
    });
  };

  loginToGoogle = (e) => {
    e.preventDefault();
    this.setLocalItems({ yahoo: false, outlook: false, twitter: false, facebook: false, linkedin: false });
    let api_key = development_connection_keys.google.api_key;
    gapi.client.setApiKey(api_key);
    window.setTimeout(this.authorize);
  };

  searchfrends = () => {
    //tomorrow implement api
    let { searchResults, findFriendsType } = this.state;
    let url = "";
    if (searchResults == "") {
      this.setState({ searchError: "This field is required" })
    } else {
      if (findFriendsType == "search") {
        url = `/api/search/?user=${searchResults}`
      }
      if (findFriendsType == "already") {
        url = `/api/findimportcontacts/?search=${searchResults}&on_wiki=true`
      }
      if (findFriendsType == "invite") {
        url = `/api/findimportcontacts/?search=${searchResults}&on_wiki=false`
      }
      this.props.fetch_search_friends(url);
      this.setState({ clickonsearch: true })
    }
  }

  handleSelectType = (tab) => {
    if (tab === "already") {
      this.props.already_on_wikireviews({ page: 1 });
    }
    if (tab === "invite") {
      this.props.fetch_invited({ page: 1, invited: true });
    }
  };

  handleCheckChieldInvite = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState(prevState => {
      let { inviteToWiki, allCheckedInvite } = prevState;
      if (itemName === "checkAllInvite") {
        allCheckedInvite = checked;
        inviteToWiki.forEach(item => {
          item.isChecked = checked
          if (checked == true) {
            let el = this.state.selectedIds.find((itm) => itm === item.email);
            if (el)
              this.state.selectedIds.splice(
                this.state.selectedIds.indexOf(el),
                1
              );
            this.state.selectedIds.push(item.email)
          } else {
            this.state.selectedIds.pop()
          }
        });
      } else {
        inviteToWiki.forEach(item => {
          if (item.email === itemName) {
            item.isChecked = checked
            if (checked == true) {
              this.state.selectedIds.push(item.email)
            } else {
              let el = this.state.selectedIds.find(
                (itm) => itm === itemName
              );
              if (el)
                this.state.selectedIds.splice(
                  this.state.selectedIds.indexOf(el),
                  1
                );
            }
          }
        })
        allCheckedInvite = inviteToWiki.every(item => item.isChecked);
      }
      this.setState({ inviteToWiki, allCheckedInvite })
    });
  };

  handleCheckChieldAlready = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState(prevState => {
      let { alreadyOnWiki, allCheckedAlready } = prevState;
      if (itemName === "checkAllAlready") {
        allCheckedAlready = checked;
        alreadyOnWiki.forEach(item => {
          item.isChecked = checked
          if (checked == true) {
            let el = this.state.selectedIds.find((itm) => itm === parseInt(item._id));
            if (el)
              this.state.selectedIds.splice(
                this.state.selectedIds.indexOf(el),
                1
              );
            this.state.selectedIds.push(parseInt(item._id))
          } else {
            this.state.selectedIds.pop()
          }
        });
      } else {
        alreadyOnWiki.forEach(item => {
          if (item?._id === itemName || item?.profile_id == itemName) {
            item.isChecked = checked
            if (checked == true) {
              this.state.selectedIds.push(parseInt(item._id ? item._id : item?.profile_id))
            } else {
              let el = this.state.selectedIds.find(
                (itm) => itm == parseInt(itemName)
              );
              if (el)
                this.state.selectedIds.splice(
                  this.state.selectedIds.indexOf(el),
                  1
                );
            }
          }
        })
        allCheckedAlready = alreadyOnWiki.every(item => item.isChecked);
        
      }
      this.setState({ alreadyOnWiki, allCheckedAlready })
    });
  };

  handleCheckChieldSearch = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState(prevState => {
      let { searchOnWiki, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        searchOnWiki.forEach(item => {
          item.isChecked = checked
          if (checked == true) {
            let el = this.state.selectedIds.find((itm) => itm === parseInt(item._id));
            if (el)
              this.state.selectedIds.splice(
                this.state.selectedIds.indexOf(el),
                1
              );
            this.state.selectedIds.push(parseInt(item._id))
          } else {
            this.state.selectedIds.pop()
          }
        });
      } else {
        searchOnWiki.forEach(item => {
          if (item._id === itemName) {
            item.isChecked = checked
            if (checked == true) {
              this.state.selectedIds.push(parseInt(item._id))
            } else {
              let el = this.state.selectedIds.find(
                (itm) => itm === parseInt(itemName)
              );
              if (el)
                this.state.selectedIds.splice(
                  this.state.selectedIds.indexOf(el),
                  1
                );
            }
          }
        })
        allChecked = searchOnWiki.every(item => item.isChecked);
      }
      this.setState({ searchOnWiki, allChecked })
    });
  };
  handleInvitation = () => {
    let { searchResults, selectedIds } = this.state;
    let data = {};
    data = selectedIds.join();
    let search = false;
    if (searchResults != "") {
      search = true;
    }
    this.setState({
      selectedIds: [],
      allChecked: false,
      allCheckedInvite: false,
      allCheckedAlready: false,
      afterSearch: false,
      clickonsearch: false
    }, () => {
      this.props.send_invitation_requests(data)
    })

  }
  handleAddConnection = () => {
    let { findFriendsType, searchResults, selectedIds } = this.state;
    let data = {};
    let url = "";
    const selfUserId =  this.props.my_profile_info?.id
    data.ids = selectedIds.filter(item=>item!==selfUserId);
    let search = false;
    if (searchResults != "") {
      search = true;
    }
    if (findFriendsType == "search") {
      url = `/api/search/?user=${searchResults}`
    }
    if (findFriendsType == "already") {
      url = `/api/findimportcontacts/?search=${searchResults}&on_wiki=true`
    }
    if (findFriendsType == "invite") {
      url = `/api/findimportcontacts/?search=${searchResults}&on_wiki=false`
    }
    this.setState({
      selectedIds: [],
      allChecked: false,
      allCheckedInvite: false,
      allCheckedAlready: false,
      afterSearch: false,
      clickonsearch: false
    }, () => {
      this.props.add_connection_requests(data, url, search, findFriendsType)
    })

  }
  responseCallback = async (response) => {
    let newArray = [];
    if (response && response.length > 0) {
      response.forEach((item, index) => {
        let data = {}
        data["email"] = item.email;
        data["name"] = item.title;
        data["provider"] = "google";
        newArray.push(data);
      });
      let url = `/api/friendsinvite/`;
      let dataNew = {
        "contacts": newArray
      }
      let response = await callApi(url, 'POST', dataNew);

    }

  }

  handleChange = (e) => {
    let { findFriendsType } = this.state;
    const value = e.target.value;
    this.setState({ searchResults: e.target.value, searchError: "" });
    if (value == "") {
      if (findFriendsType == "search") {
        this.props.already_on_wikireviews({ page: 1 });
      } else if (findFriendsType == "invite") {
        this.props.fetch_invited({ page: 1, invited: true });
      } else if (findFriendsType == "already") {
        this.props.already_on_wikireviews({ page: 1 });
      }
    }
  }

  handleAuthorization = (response) => {
    
    if (response && response.access_token) {
      let responseRef = {};
      let filterdItems = [];      
      let url = `https://people.googleapis.com/v1/people/me/connections?alt=json&access_token=${response.access_token}&personFields=emailAddresses,names,coverPhotos,photos`
      
      axios.get(url)
        .then(response => {
          responseRef = response;         

          if (responseRef?.connections?.length) {
            responseRef?.connections.forEach(item => {
              if (item?.emailAddresses && item?.emailAddresses[0] && item?.emailAddresses[0]?.value) {
                filterdItems.push({
                  email: item?.emailAddresses[0]?.value,
                  name: item?.names[0]?.displayName || '',
                  provider: "google",
                });
              }
            });
            if (filterdItems && Array.isArray(filterdItems) && filterdItems.length > 0) {
              let urlNew = "/api/friendsinvite/";
              let dataNew = {
                "contacts": filterdItems
              }
              let response = callApi(urlNew, 'POST', dataNew);
              if (response) {
                toast("Your contacts are imported from google", {
                  autoClose: 2500,
                  className: "black-background",
                  bodyClassName: "red-hunt",
                  progressClassName: "cc",
                });
                //if(response["New imported contacts"] > 0){
                this.props.setTabView("5")
                //}
              }
            } else if (filterdItems && Array.isArray(filterdItems) && filterdItems.length === 0) {
              toast("No contacts to import! Try another account or adding contacts to your google account with email address.", {
                autoClose: 2500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
              });
            }
          }
        }).catch(error => console.log(error))
        .finally(() => console.log("end"));
    }
  };

  /* Google Authorization Functions End Here */

  handleScrollSearch = (e) => {
    // let { myScrollSearchRef } = this;
    // let clientHeight = myScrollSearchRef.current.clientHeight;
    // let scrollHeight = myScrollSearchRef.current.scrollHeight;
    // const scrollTop = this.myScrollSearchRef.current.scrollTop.toFixed() - 1;
    // let scrollTopCalulated = scrollHeight - clientHeight;
    // let scrollMinimun_8 = scrollTopCalulated - 8;
    // let scrollMinimun_6 = scrollTopCalulated - 6;
    // let scrollMinimun_5 = scrollTopCalulated - 5;
    // let scrollMinimun_3 = scrollTopCalulated - 3;
    // let scrollMinimun_1 = scrollTopCalulated - 1;
    // if (
    //   scrollTopCalulated == scrollTop ||
    //   scrollTop == scrollMinimun_1 ||
    //   scrollTop == scrollMinimun_3 ||
    //   scrollTop == scrollMinimun_5 ||
    //   scrollTop == scrollMinimun_6 ||
    //   scrollTop == scrollMinimun_8
    // ) {
    //   if (this.state.newInviteArry && this.state.newInviteArry.next) {

    //     this.fetchSearchOnScroll(this.state.newInviteArry.next.split('com')[1]);
    //   }
    // }


  }

  fetchSearchOnScroll = (next) => {
    this.setState({ loading: true });
    callApi(`${next}`, 'GET')
      .then(res => {
        if (res && Object.keys(res).length > 0 && res.results) {
          let data = {
            ...res,
            results: [...this.state.newInviteArry.results, ...res.results]
          };
          const newArr = data?.results.map(v => ({ ...v, isChecked: false }))
          this.setState({ inviteToWiki: newArr, loading: false });
        }
      });
  }

  handleChangeEmail = (e, index) => {
    const { email_set } = this.state
    const { name, value } = e.target
    if (name.includes('email') && email_set.length <= index) {
      email_set.push({ email: "" })
      this.setState({ email_set, emailError: "" })
    }

    if (name === 'email') {
      email_set[index].email = value
      this.setState({ email_set, emailError: "" })
    }

  }

  handleremoveEmail = (option) => {
    const { email_set } = this.state
    if (option === 'email_set') {
      email_set.pop()
      this.setState({ email_set })
    }
  }

  handleConnectionSubmit = () => {
    let { note, email_set } = this.state
    if (email_set[0].email == "") {
      this.setState({
        emailError:
          "This field is required",
      });
    } else {
      let inviteFriends = {}
      inviteFriends.searchconnection = null;
      inviteFriends.email = "";
      inviteFriends.search = "";
      inviteFriends.email_set = email_set;
      inviteFriends.note = note;
      inviteFriends.multiple = true;
      this.setState({ note: "" }, () => {
        this.props.send_import_request(inviteFriends);
      })

    }
  }

  checkSelfAccount=(item)=>{
    if(this.props.my_profile_info?.user.username===item._source?.user?.username){
      return false
    }else{
      return true
    }
    
  }

  render() {

    let {
      searchResults,
      alreadyOnWiki,
      inviteToWiki,
      searchOnWiki,
      afterSearch,
      addConnectionForm,
      note,
      providersConnections
    } = this.state;
    
    let filterAlreadyOnWiki = [];
    if (alreadyOnWiki?.length) {
      filterAlreadyOnWiki = alreadyOnWiki;
    }
    let filterInviteToWiki = [];
    if (inviteToWiki?.length) {
      filterInviteToWiki = inviteToWiki;
    }
    let filterSearchToWiki = [];
    if (searchOnWiki?.length) {
      filterSearchToWiki = searchOnWiki;
    }
    return (
      <>
        <Formik
          touched
          errors
          initialValues={addConnectionForm}
          validationSchema={validate}
          onSubmit={(values) => {
            this.props.onSubmit({
              ...values,
              taxonomy: [values.taxonomy],
            });
          }}
          render={(props) => {
            const {
              values,
            } = props;
            return (
              <Row>
                <Col className="mb-3" md={4}>
                  <h2 className="text-secondary">
                    {'Find Connections '}
                  </h2>
                  <hr className="bg-secondary" />

                  <div className="border-top border-secondary-dark mb-3">
                    <div className="position-relative wk-search-profile-friend">
                      <Input
                        className="bg-search ph-brown text-secondary-dark font-weight-bold fs-14 border-0"
                        type="search"
                        bsSize="sm"
                        onChange={this.handleChange}
                        value={searchResults}
                        placeholder="search connections"
                      />
                      <Button className="wk-friendprofile-search-button" onClick={() => {
                        this.setState({ isLoadingAlreadyOnWiki: true, isLoadingInviteToWiki: true, allCheckedAlready: false, allChecked: false, allCheckedInvite: false, })
                        this.searchfrends();
                      }}><FontAwesomeIcon size="lg" icon="search" /></Button>
                    </div>
                    <span className="fs-12" style={{ color: 'red' }}>{this.state.searchError}</span>
                  </div>


                  {/* {!providersConnections?.facebook ?
                    <>
                      {this.renderImportBtn({
                        onClick: this.redirectFacebookForLogin,
                        colorClass: "facebook fs-14",
                        iconSpan: (
                          <span className="mr-2">
                            <FontAwesomeIcon size="lg" icon={["fab", "facebook-f"]} fixedWidth />
                          </span>),
                        title: 'Facebook'
                      })}
                    </>
                    :
                    <div className="d-flex mb-2">
                      <div className="btn fs-14 btn-facebook btn-lg flex-grow-1 pe-none mx-0">
                        <div className="d-flex">
                          <span className="mr-2">
                            <FontAwesomeIcon size="lg" icon={["fab", "facebook-f"]} fixedWidth />
                          </span>
                          <span>
                            {`Import from `}<span className="text-uppercase">Facebook</span>
                          </span>
                        </div>
                      </div>
                      <div title="imported">
                        <div className="btn btn-facebook btn-lg pe-none h-100 d-inline-flex flex-column justify-content-center px-2 mx-0">
                          <i className="imported-contacts"></i>
                        </div>
                      </div>
                      <Button
                        onClick={this.redirectFacebookForLogin}
                        color="facebook" className="d-inline-flex flex-column justify-content-center px-2 mx-0" size="lg" title="sync">
                        <i className="sync-contacts"></i>
                      </Button>
                    </div>
                  } */}

                  {/* {!providersConnections?.twitter ?
                    <>
                      {this.renderImportBtn({
                        onClick: this.redirectTwitterForLogin,
                        colorClass: "twitter fs-14",
                        iconSpan: (
                          <span className="mr-2">
                            <FontAwesomeIcon size="lg" icon={["fab", "twitter"]} fixedWidth />
                          </span>),
                        title: 'Twitter'
                      })}
                    </>
                    :
                    <div className="d-flex mb-2">
                      <div className="btn fs-14 btn-twitter btn-lg flex-grow-1 pe-none mx-0">
                        <div className="d-flex">
                          <span className="mr-2">
                            <FontAwesomeIcon size="lg" icon={["fab", "twitter"]} fixedWidth />
                          </span>
                          <span>
                            {`Import from `}<span className="text-uppercase">Twitter</span>
                          </span>
                        </div>
                      </div>
                      <div title="imported">
                        <div className="btn btn-twitter btn-lg pe-none h-100 d-inline-flex flex-column justify-content-center px-2 mx-0">
                          <i className="imported-contacts"></i>
                        </div>
                      </div>
                      <Button
                        onClick={this.redirectTwitterForLogin}
                        color="twitter" className="d-inline-flex flex-column justify-content-center px-2 mx-0" size="lg" title="sync">
                        <i className="sync-contacts"></i>
                      </Button>
                    </div>
                  } */}

                  
                  <GoogleOAuthProvider clientId={development_connection_keys.google.client_id}>
                    <GoogleAuth handleAuthorization={this.handleAuthorization} alreadyImported={providersConnections?.google} setLocalItems={this.setLocalItems} />
                  </GoogleOAuthProvider>

                  {/* STATIC IMPORT BUTTONS for OTHER PLATFORMS */}
                  <>
                    {this.renderImportBtn({
                      onClick: () => {this.showToastMessage("Coming Soon!")},
                      colorClass: "facebook fs-14",
                      iconSpan: (
                        <span className="mr-2">
                          <FontAwesomeIcon size="lg" icon={["fab", "facebook-f"]} fixedWidth />
                        </span>),
                      title: 'Facebook'
                    })}
                  </>

                  <>
                    {this.renderImportBtn({
                      onClick: () => {this.showToastMessage("Coming Soon!")},
                      colorClass: "twitter fs-14",
                      iconSpan: (
                        <span className="mr-2">
                          <FontAwesomeIcon size="lg" icon={["fab", "twitter"]} fixedWidth />
                        </span>),
                      title: 'Twitter'
                    })}
                  </>

                  <>
                    {this.renderImportBtn({
                      onClick: () => {this.showToastMessage("Coming Soon!")},
                      colorClass: "linkedin fs-14",
                      iconSpan: (
                        <span className="mr-2">
                          <FontAwesomeIcon size="lg" icon={["fab", "linkedin"]} fixedWidth />
                        </span>),
                      title: 'Linkedin'
                    })}
                  </>

                  <>
                    {this.renderImportBtn({
                      onClick: () => {this.showToastMessage("Coming Soon!")},
                      colorClass: "yahoo fs-14",
                      iconSpan: (<span className="mr-2 icon-yahoo"></span>),
                      title: 'Yahoo!'
                    })}
                  </>

                  <>
                    {this.renderImportBtn({
                      onClick: () => {this.showToastMessage("Coming Soon!")},
                      colorClass: "live fs-14",
                      iconSpan: (<span className="mr-2 icon-live"></span>),
                      title: 'Live'
                    })}
                  </>

                  {/* {!providersConnections?.linkedin ?
                    <>
                      {this.renderImportBtn({
                        onClick: this.redirectLinkedinForLogin,
                        colorClass: "linkedin fs-14",
                        iconSpan: (
                          <span className="mr-2">
                            <FontAwesomeIcon size="lg" icon={["fab", "linkedin"]} fixedWidth />
                          </span>),
                        title: 'Linkedin'
                      })}
                    </>
                    :
                    <div className="d-flex mb-2">
                      <div className="btn fs-14 btn-twitter btn-lg flex-grow-1 pe-none mx-0">
                        <div className="d-flex">
                          <span className="mr-2">
                            <FontAwesomeIcon size="lg" icon={["fab", "linkedin"]} fixedWidth />
                          </span>
                          <span>
                            {`Import from `}<span className="text-uppercase">Linkedin</span>
                          </span>
                        </div>
                      </div>
                      <div title="imported">
                        <div className="btn btn-linkedin btn-lg pe-none h-100 d-inline-flex flex-column justify-content-center px-2 mx-0">
                          <i className="imported-contacts"></i>
                        </div>
                      </div>
                      <Button
                        onClick={this.redirectLinkedinForLogin}
                        color="linkedin" className="d-inline-flex flex-column justify-content-center px-2 mx-0" size="lg" title="sync">
                        <i className="sync-contacts"></i>
                      </Button>
                    </div>
                  } */}

                  {/* {!providersConnections?.yahoo ?
                    <>
                      {this.renderImportBtn({
                        onClick: this.redirectYahooForLogin,
                        colorClass: "yahoo fs-14",
                        iconSpan: (<span className="mr-2 icon-yahoo"></span>),
                        title: 'Yahoo!'
                      })}
                    </>
                    :
                    <div className="d-flex mb-2">
                      <div className="btn fs-14 btn-yahoo btn-lg flex-grow-1 pe-none mx-0">
                        <div className="d-flex">
                          <span className="mr-2 icon-yahoo"></span>
                          <span>
                            {`Import from `}<span className="text-uppercase">Yahoo</span>
                          </span>
                        </div>
                      </div>
                      <div title="imported">
                        <div className="btn btn-yahoo btn-lg pe-none h-100 d-inline-flex flex-column justify-content-center px-2 mx-0">
                          <i className="imported-contacts"></i>
                        </div>
                      </div>
                      <Button onClick={this.redirectYahooForLogin} color="yahoo" className="d-inline-flex flex-column justify-content-center px-2 mx-0" size="lg" title="sync">
                        <i className="sync-contacts"></i>
                      </Button>
                    </div>
                  } */}
                  {/* {!providersConnections?.outlook ?
                    <>
                      {this.renderImportBtn({
                        onClick: this.redirectOutlookForLogin,
                        colorClass: "live fs-14",
                        iconSpan: (<span className="mr-2 icon-live"></span>),
                        title: 'Live'
                      })}
                    </>
                    :
                    <div className="d-flex mb-2">
                      <div className="btn fs-14 btn-live btn-lg flex-grow-1 pe-none mx-0">
                        <div className="d-flex">
                          <span className="mr-2 icon-live"></span>
                          <span>
                            {`Import from `}<span className="text-uppercase">Live</span>
                          </span>
                        </div>
                      </div>
                      <div title="imported">
                        <div className="btn btn-live btn-lg pe-none h-100 d-inline-flex flex-column justify-content-center px-2 mx-0">
                          <i className="imported-contacts"></i>
                        </div>
                      </div>
                      <Button onClick={this.redirectOutlookForLogin} color="live" className="d-inline-flex flex-column justify-content-center px-2 mx-0" size="lg" title="sync">
                        <i className="sync-contacts"></i>
                      </Button>
                    </div>
                  } */}
                  {/*<Button onClick={this.fetchContactsYahoo} color="red">{'Fetch Yahoo'}</Button>*/}
                  <Button
                    color="link"
                    className="p-0 mx-0 text-secondary-dark font-weight-normal fs-14 ff-alt"
                    size="lg">
                    {'or via e-mail'}
                  </Button>
                  <div>
                    {/* Repeat this */}
                    <div>
                      <FieldArray
                        name="email_set"
                        render={(arrayHelpers) => (
                          <div>
                            {values.email_set.map(
                              (email, index) => (
                                <div className="mt-2" key={index}>
                                  <FormGroup className="mb-1">
                                    <Input
                                      // name={`producers_set[${index}.first_name]`}
                                      name="email"
                                      type="email"
                                      className="ph-grey fs-14 border text-secondary-dark font-weight-bold"
                                      placeholder="connection's address"
                                      autoComplete="off"
                                      onChange={(e) => this.handleChangeEmail(e, index)}
                                    />
                                    <span style={{ color: 'red', fontSize: "13px" }}>{this.state.emailError}</span>

                                  </FormGroup>
                                  {/* Hide for first */}
                                  {values.email_set.length > 1 &&
                                    (
                                      <div className="d-flex justify-content-end">
                                        <Button color="link" className="font-weight-normal ff-alt text-dark px-0"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            this.handleremoveEmail('email_set')
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      </div>
                                    )
                                  }
                                </div>
                              )
                            )}
                            <div className="text-right">
                              <Button color="link" className="text-secondary px-0 font-weight-bold"
                                onClick={() =>
                                  arrayHelpers.push({
                                    email: ""
                                  })
                                }>
                                <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <FormGroup>
                    <Input
                      type="textarea"
                      rows="4"
                      className="ph-grey fs-14 border border-secondary text-secondary-dark font-weight-bold glowy-form-control-alt"
                      placeholder="add a personal note (optional)"
                      name="note"
                      value={note}
                      onChange={(e) => {
                        this.setState({ note: e.target.value });
                      }}
                    />
                  </FormGroup>
                  <div className="text-right">
                    <Button color="primary" size="lg" onClick={this.handleConnectionSubmit}>
                      {'Invite'}
                    </Button>
                  </div>
                </Col>
                <Col md={8}>
                  <Nav className="mb-3" fill tabs>
                    <NavItem className="text-center">
                      <NavLink
                        href="#"
                        active={this.state.findFriendsType === "already"}
                        onClick={() => {
                          this.setState({ findFriendsType: "already", searchResults: "", selectedIds: [], afterSearch: false, searchOnWiki: [], clickonsearch: false });
                          this.handleSelectType("already");

                        }}>
                        <span className="ff-base font-weight-normal">
                          {'Already on'}
                        </span>{" "}
                        <br />{" "}
                        <span className="font-weigh-bold">{'WikiReviews'}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="text-center">
                      <NavLink
                        href="#"
                        active={this.state.findFriendsType === "invite"}
                        onClick={() => {
                          this.setState({ findFriendsType: "invite", searchResults: "", selectedIds: [], afterSearch: false, searchOnWiki: [], clickonsearch: false });
                          this.handleSelectType("invite");
                          this.state.selectedIds.pop();
                        }}>
                        <span className="ff-base font-weight-normal">
                          {'Invite to'}
                        </span>{" "}
                        <br />{" "}
                        <span className="font-weigh-bold">{'WikiReviews'}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem className="text-center">
                      <NavLink
                        href="#"
                        active={this.state.findFriendsType === "search"}
                        onClick={() => {
                          this.setState({ findFriendsType: "search", searchResults: "", selectedIds: [], afterSearch: true, clickonsearch: false });
                          this.handleSelectType("search");
                          this.state.selectedIds.pop();
                        }}>
                        <span className="ff-base font-weight-normal">
                          {'Search on'}
                        </span>{" "}
                        <br />{" "}
                        <span className="font-weigh-bold">{'WikiReviews'}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.findFriendsType}>
                    <TabPane tabId="already">
                      {
                        filterAlreadyOnWiki && filterAlreadyOnWiki.length > 0 ? 
                        <div className="d-flex">
                        <p className="text-dark fs-14">
                          {'Select which contacts to add from the list below'}
                        </p>
                        <div className="text-tertiary font-weight-normal ff-alt ml-4 fs-14">
                          {'Select All/None'}
                          <input className="ml-2" type="checkbox" name="checkAllAlready" checked={this.state.allCheckedAlready} onChange={this.handleCheckChieldAlready} />{" "}
                        </div>
                      </div> :
                      ''
                      }
                      <Row>
                        {filterAlreadyOnWiki && filterAlreadyOnWiki.length > 0 ? (
                          filterAlreadyOnWiki.map((itemAlready, indexAlready) => (
                            <Col md="6" key={indexAlready}>
                              <div className="wk-friends-search-box p-4 mb-2 d-flex align-items-center justify-content-between">
                                <div className="wk-friends-search-boxleft">
                                  <img
                                    src={`${itemAlready && itemAlready?._source?.current_profile_pic ? itemAlready?._source.current_profile_pic : (itemAlready?.thumbnail ? itemAlready.thumbnail : "https://userdatawikireviews.s3.amazonaws.com/images/circle.png")}`}
                                    className="wk-friends-search-image"
                                    onError={(error) =>
                                      (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/circle.png`)
                                    }
                                    alt={`${itemAlready && itemAlready?._source?.name ? itemAlready?._source.name : 'no-image'}`}
                                  />
                                  {
                                      itemAlready?._source?.user?.username ? 
                                      <Link to={`/people/${itemAlready?._source?.user?.username}`} className="text-dark ml-2 fs-14">{itemAlready?._source?.name ? itemAlready?._source?.name : ""}</Link> : 
                                      <Link to={`/people/${itemAlready?.vanity_url}`} className="text-dark ml-2 fs-14">{itemAlready?.fullname ? itemAlready?.fullname : (itemAlready?.name ? itemAlready.name : "")}</Link>
                                  }
                                  
                                </div>
                                <div className="wk-friends-search-boxright">
                                  <input type="checkbox" name={itemAlready?._id ? itemAlready?._id : itemAlready?.profile_id} checked={itemAlready?.isChecked} onChange={this.handleCheckChieldAlready} />
                                </div>
                              </div>
                            </Col>

                          ))
                        ) : (
                            <Col xs={12}>
                              <div className="text-secondary-dark font-weight-bold">
                                <span hidden={filterAlreadyOnWiki.length == 0 && afterSearch == true ? false : true}>{"Either we do not have this user or request is already sent."}</span>
                                <span hidden={filterAlreadyOnWiki.length == 0 && afterSearch == false ? false : true}>{'No Results to display.'}</span>
                              </div>
                            </Col>
                          )}
                      </Row>
                      {
                        filterAlreadyOnWiki.find((item) => item.isChecked) 
                          ?
                          <div className="mt-2 d-flex justify-content-end">
                            <button className="btn btn-primary" hidden={filterAlreadyOnWiki.length === 0 && afterSearch == true} onClick={() => {
                              this.handleAddConnection();
                            }}>Add Connections</button>
                          </div>
                          :
                          ''
                      }
                    </TabPane>
                    <TabPane tabId="invite">
                      {
                        (filterInviteToWiki && filterInviteToWiki.length > 0) ? 
                        <div className="d-flex">
                        <p className="text-dark fs-14">
                          {'Select which contacts to add from the list below'}
                        </p>
                        <div className="text-tertiary font-weight-normal ff-alt ml-4 fs-14">
                          {'Select All/None'}
                          <input className="ml-2" type="checkbox" name="checkAllInvite" checked={this.state.allCheckedInvite} onChange={this.handleCheckChieldInvite} />{" "}
                        </div>
                      </div>
                        : ''
                      }
                      
                      <Row ref={this.myScrollSearchRef}
                        onScroll={this.handleScrollSearch}
                        style={{ maxHeight: "calc(100vh - 25px)", overflow: "auto" }}>
                        {filterInviteToWiki && filterInviteToWiki.length > 0 ? (
                          filterInviteToWiki.map((itemInvite, indexInvite) => (
                            <Col md="6" key={indexInvite}>

                              <div className="wk-friends-search-box p-4 mb-2 d-flex align-items-center justify-content-between">
                                <div className="wk-friends-search-boxleft">
                                  <img
                                    src={`${itemInvite && itemInvite?.thumbnail ? itemInvite?.thumbnail : "https://userdatawikireviews.s3.amazonaws.com/images/circle.png"}`}
                                    className="wk-friends-search-image"
                                    onError={(error) =>
                                      (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/circle.png`)
                                    }
                                    alt={`${itemInvite && itemInvite.user_name ? itemInvite?.user_name : 'no-image'}`}
                                  />
                                  <a href="#" className="text-dark ml-2 fs-14">{itemInvite?.name}</a>
                                </div>
                                
                                <div className="wk-friends-search-boxright">
                                  {
                                    indexInvite.is_invited ? <FontAwesomeIcon
                                    icon="check"
                                    className="mr-2 text-dark"
                                  /> : <input type="checkbox" name={itemInvite?.email} checked={itemInvite?.isChecked} onChange={this.handleCheckChieldInvite} value={itemInvite?.email} />
                                  }
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : (
                            <Col xs={12}>
                              <div className="text-secondary-dark font-weight-bold">
                                <span hidden={filterInviteToWiki.length == 0 && afterSearch == true ? false : true}>{'Can not find this user in your imports.'}</span>
                                <span hidden={filterInviteToWiki.length == 0 && afterSearch == false ? false : true}>{'No Results to display.'}</span>
                              </div>
                            </Col>
                          )}
                      </Row>
                      <div className="mt-2 d-flex justify-content-end">
                        <button className="btn btn-primary" hidden={filterInviteToWiki.length === 0 ? true : false}
                          onClick={() => {
                            this.handleInvitation();
                          }}>Invite Connections</button>
                      </div>
                    </TabPane>
                    <TabPane tabId="search">
                      {
                        (this.state.searchResults && filterSearchToWiki && filterSearchToWiki.length > 0 && filterSearchToWiki.find((item) => {
                          return (!item?._source?.friend && !item?._source?.friend_request_accepted)
                        })) ? 
                        <div className="d-flex">
                        <p className="text-dark fs-14">
                          {'Select which contacts to add from the list below'}
                        </p>
                        <div className="text-tertiary font-weight-normal ff-alt ml-4 fs-14">
                          {"Select All/None"}
                          <input className="ml-2" type="checkbox" name="checkAll" checked={this.state.allChecked} onChange={this.handleCheckChieldSearch} />{" "}
                        </div>
                      </div> :
                      ''
                      }
                      <Row>
                        {(this.state.searchResults && filterSearchToWiki && filterSearchToWiki.length > 0) ? (
                          filterSearchToWiki.map((itemSearch, indexSearch) => (

                            <Col md="6" key={indexSearch}>
                              <div className="wk-friends-search-box p-4 mb-2 d-flex align-items-center justify-content-between">
                                <div className="wk-friends-search-boxleft">
                                  <img
                                    src={`${itemSearch && itemSearch?._source?.current_profile_pic ? itemSearch?._source?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/images/circle.png"}`}
                                    className="wk-friends-search-image"
                                    onError={(error) =>
                                      (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/circle.png`)
                                    }
                                    alt={`${itemSearch && itemSearch?._source?.name ? itemSearch?._source.name : 'no-image'}`}
                                  />
                                  <a href={`/people/${itemSearch?._source?.user?.username}`} className="text-dark ml-2 fs-14">{itemSearch?._source?.name}</a>
                                </div>
                                <div className="wk-friends-search-boxright" >
                                  {
                                    itemSearch?._source?.friend_request_accepted ? <FontAwesomeIcon
                                      icon="check"
                                      title="Friends on Wiki"
                                      className="mr-2 text-dark"
                                    /> : itemSearch?._source?.friend ? <FontAwesomeIcon
                                    icon="clock"
                                    title="Request Pending"
                                    className="mr-2 text-dark"
                                  /> : (this.checkSelfAccount(itemSearch) && <input type="checkbox" name={itemSearch?._id} checked={itemSearch?.isChecked} onChange={this.handleCheckChieldSearch} value={itemSearch?._id} />)
                                  }
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : (
                            <Col xs={12}>
                              <div className="text-secondary-dark font-weight-bold">
                                <span hidden={filterSearchToWiki.length == 0 && afterSearch == false ? false : true}>{'User not found.'}</span>
                                <span hidden={filterSearchToWiki.length == 0 && afterSearch == true ? false : true}>{'Please provide the name of the person you are searching for in the search box.'}</span>
                              </div>
                            </Col>

                          )}
                      </Row>
                      <div className="mt-2 d-flex justify-content-end">
                        {
                          (filterSearchToWiki.length === this.state.selectedIds.length || this.state.selectedIds.length === 0) ?
                            (this.state.allChecked ? <button className="btn btn-primary" hidden={filterSearchToWiki.length === 0 ? true : false} onClick={() => {
                              this.handleAddConnection();
                            }}>Add Connections</button> : '')
                            :
                            <button className="btn btn-primary" hidden={filterSearchToWiki.length === 0 ? true : false} onClick={() => {
                              this.handleAddConnection();
                            }}>Add Connections</button>
                        }                      
                      </div>
                    </TabPane>
                  </TabContent>                
                </Col>
              </Row>
            );
          }}
        />
      </>
    )
  }
}


const mapState = (state) => ({
  already_on_wikireview: state.connections.already_on_wikireviews,
  fetch_invited_items: state.connections.fetch_invited_items,
  get_search_friends: state.connections.get_search_friends,
  my_profile_info: state.user.my_profile,
  providers_on_wikireviews: state.connections.providers_on_wikireviews,
});

const mapProps = (dispatch) => ({
  fetch_search_friends: (url) => dispatch(fetch_search_friends(url)),
  already_on_wikireviews: ({ page }) => dispatch(already_on_wikireviews({ page })),
  fetch_invited: (data) => dispatch(fetch_invited(data)),
  add_connection_requests: (data, url, search, friendsType) => dispatch(add_connection_requests(data, url, search, friendsType)),
  send_invitation_requests: (emails) => dispatch(send_invitation_requests(emails)),
  send_import_request: (data) => dispatch(send_import_request(data)),
  clear_search_data: () => dispatch(clear_search_data()),
  get_providers_on_wikireviews: () => dispatch(get_providers_on_wikireviews())
})
export default withRouter(connect(mapState, mapProps)(FindFriends));
