/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, Row, FormGroup, Label,Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
class DoctorLocation extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      docId: 0,
      about: null,
      doctorData: null,
      destination_details: {
        latitude: 0,
        longitude: 0,
        travelMode: 'DRIVING',
        addressTo: ''
      },
      routes: [],
      address: "",
      lat: "",
      lng: "",
      routesData: [],
      toData: {},
	  compareModal: false,
	  message : ""
    }
  }

  componentWillReceiveProps(nextProps) {
    const { doctorDetails, doctorId } = nextProps;
    let {about, docId} = this.state;
    if (about !== doctorDetails) {
      this.setState({
        about: doctorDetails,

      });
    }
    if (docId !== doctorId) {
      this.setState({
        docId: doctorId,
      });
    }
  }

  handleChange = address => {
    this.setState({ address, routes: [], message:"",compareModal:false }, () => {
      this.handleSelect(address)
    });
  };

  handleSelect = async (address) => {
    let city = '';
    let county = '';
    let state = '';
    let country = '';

    const result = await geocodeByAddress(address);
    result && result[0] && result[0].address_components.map((addr) => {
      if (addr.types[0] === "locality") {
        //city name
        city = addr.long_name
      } else if (addr.types[0] === "administrative_area_level_2") {
        //county name
        county = addr.long_name
      } else if (addr.types[0] === "administrative_area_level_1") {
        //state name
        state = addr.short_name
      } else if (addr.types[0] === "country") {
        //state name
        country = addr.short_name
      }
    })
    const latlng = await getLatLng(result[0]);
    await this.setState({
      address: address,
      lat: latlng.lat,
      lng: latlng.lng,
      destination_details: {
        ...this.state.destination_details,
        latitude: latlng.lat,
        longitude: latlng.lng,
        addressTo: address
      }
    }, () => {

    });

  };

  handleOnClickGetDirections = (todata) => {
    let fromdetail = { ...this.state.destination_details };
	let {address} = this.state;
	if (address == ""){
	this.setState({
				compareModal: true,
				message: "Please enter start from address."
			});	
	}else{
    this.setState({
      toData: todata
    }, () => {
      this.props.handleDestinationChange(fromdetail, todata);
    });
	}
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({ routesData: [] })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.setState({ routesData: [] })
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      //this.props.handleEnabelSearch();
    }
  }

  handleTravelModeChange = (mode) => {
    let { toData } = this.state;
    this.setState({
      destination_details: {
        ...this.state.destination_details,
        travelMode: mode
      }
    }, () => {
      let fromdetail = { ...this.state.destination_details };
      this.props.handleDestinationChange(fromdetail, toData);
    });
  }


  addressList = () => {
    let doctorData = this.state.doctorData;
    const { doctorDetails } = this.props
    if (doctorDetails && doctorDetails?.address && Array.isArray(doctorDetails?.address) && doctorDetails.address.length > 0) {
      return doctorDetails?.address.map((item, index) => {
        //if (theaterData && theaterData.length > 0) {
        //return theaterData.map((item, index) => {
        return (

          <li className="mb-2" key={index}>
            <div className="d-flex">
              <span className="mr-4 mt-1"><span className="business-icon _map"></span></span>
              <div>
                <span className="text-darker">
                  {item && item.name ? <><span className="font-weight-bold">{item.name}</span><br /></> : ""}
                  {item && item.address1 ? item.address1 : ""}
                  <br />{item && item.city ? item.city : ""} {item && item.state ? item.state : ""} {item && item.zipcode ? item.zipcode : ""}
                  <br />{item && item.country ? item.country : ""}
                </span>
                {item && item.latitude && item.latitude != "" && item.latitude != null && item.longitude && item.longitude != "" && item.longitude != null ? 
				<div className="mt-1">
					<Button color="link" className="text-primary font-weight-bold p-0" onClick={() => this.handleOnClickGetDirections(item)}>get directions</Button>
				</div>
				: "" }
              </div>
            </div>
          </li>
        )
      })
    } else {

      return (
        <div className="bg-white p-3">
          <h2 className="text-secondary-dark">No Address to Display</h2>
        </div>

      )
    }
  }

  render() {
    const {
      about,
      doctorData,
      destination_details
    } = this.state;
    const { doctorDetails } = this.props;
    let avg_rating = doctorDetails && doctorDetails.total_rating && doctorDetails.total_rating.avg_rating[0] ? doctorDetails.total_rating.avg_rating[0] : 'norating.png';
    const searchOptions = {
      componentRestrictions: { country: ['us', 'ca'] },
      // types: ['city']
    };
    return (
      <>
        <Container>
          <Row>
            <Col md={4}>
              <div className="wr-movie-top-about">
                <div className="wr-movie-top-about-body">
                  <div className="wr-movie-product-info-top d-flex flex-wrap">
                    <div className="wr-movie-product-info-top-left">
                      <img
                        src={`${about && about?.professional_profileimage && about?.professional_profileimage.length > 0 && about?.professional_profileimage[0] && about?.professional_profileimage[0].thumbnail}`}
                        className="img-fluid"
                        onError={(error) =>
                          (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/doctor-neutral-472x446.jpg`)
                        }
                        alt={`${about && about?.professional_profileimage && about?.professional_profileimage.length > 0 && about?.professional_profileimage[0] && about.professional_profileimage[0].caption ? about.professional_profileimage[0].caption : 'no-image'}`}
                      />
                    </div>
                    <div className="wr-movie-product-info-top-right">
                      <div className="wr-category-heading">
                        <h4>{about && about.firstname} {about && about.lastname}</h4>
                        <div hidden>
                          <InputGroup size="sm">
                            <Input type="text" placeholder="Enter Name" />
                            <InputGroupAddon addonType="append">
                              <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </div>
                        <p className="fs-14"><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                          className="img-fluid"
                          onError={(error) =>
                            (error.target.src = `../../../assets/images/norating.png`)
                          }
                          alt={`${about && about.total_rating && about.total_rating.avg_rating[1] ? about.total_rating.avg_rating[1] : 'no-rating'}`}

                        /> {(about && about.total_rating && about.total_rating.total_review) ? about.total_rating.total_review : 0} reviews</p>
                      </div>
                    </div>
                  </div>
                  <div className="wr-movie-product-info-bottom">
                    <ul className="list-unstyled fs-13">
                      {this.addressList()}
                    </ul>
                  </div>
                  <ul className="wk-transport">
                    <li>
                      <a href="javascript:void(0)" onClick={() => this.handleTravelModeChange('DRIVING')} className={`icon-car ${destination_details.travelMode === 'DRIVING' ? 'active' : ''}`}></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={() => this.handleTravelModeChange('TRANSIT')} className={`icon-train ${destination_details.travelMode === 'TRANSIT' ? 'active' : ''}`}></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={() => this.handleTravelModeChange('WALKING')} className={`icon-walk ${destination_details.travelMode === 'WALKING' ? 'active' : ''}`}></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" onClick={() => this.handleTravelModeChange('BICYCLING')} className={`icon-bycl ${destination_details.travelMode === 'BICYCLING' ? 'active' : ''}`}></a>
                    </li>
                  </ul>
                  <div className="wr-business-location-form">
                    <FormGroup>
                      <Label>Start From:</Label>
                      <div ref={this.wrapperRef}>
                        <PlacesAutocomplete
                          value={this.state.address}
                          onChange={this.handleChange}
                          onSelect={this.handleSelect}
                          searchOptions={searchOptions}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="position-relative">
                              <input
                                {...getInputProps({
                                  placeholder: 'Enter Address',
                                  className: 'location-search-input comment-form-control form-control form-control-sm',
                                })}
                              />
                              <div className="autocomplete-dropdown-container position-absolute" style={{minWidth: 'unset'}}>
                                {loading && <div className="bg-white fs-14 p-2">Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <FontAwesomeIcon className="mr-2" fixedWidth icon="map-marker-alt" />
                                      {/*suggestion.formattedSuggestion.mainText*/}
                                      {suggestion.description}
                                      {/* <small>
																				{suggestion.formattedSuggestion.secondaryText}
																			</small> */}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                      {/*<a href="#" className="view-all fs-14 font-weight-bold">get directions</a>*/}
                    </FormGroup>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
		<Modal isOpen={this.state.compareModal} toggle={() => this.setState({ compareModal: !this.state.compareModal })}>
					<ModalHeader toggle={() => this.setState({ compareModal: !this.state.compareModal })}>wikireviews.com says</ModalHeader>
					<ModalBody>
						{this.state.message}
					</ModalBody>
					<ModalFooter>
					</ModalFooter>
				</Modal>
      </>

    )
  }
}


export default DoctorLocation;