import React from "react";
import blueIcon from '../../assets/images/icons/delete-mypost.png';
import goldIcon from '../../assets/images/icons/delete-history.png';
import lightIcon from '../../assets/images/icons/delete-tip.png';

export default class DeleteBtn extends React.Component  {
    render() {
        let  { onClick, color, disabled } = this.props;
        if(disabled == null){
            disabled = false
        }
        let srcIMG = blueIcon;
        let iconWidth = 16;

        if (color === "gold") {
            srcIMG = goldIcon;
        } else if (color === "light") {
            srcIMG = lightIcon;
            iconWidth = 10;
        } else {
            srcIMG = blueIcon;
            iconWidth = "";
        }
        return (
            <button type="button" className="d-inline-block p-1 border-0 btn-withoutbg" title="Delete" onClick={onClick} disabled={disabled}>
                <img width={iconWidth} src={srcIMG} alt="Delete" />
            </button>
        )
    }
}