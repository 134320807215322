import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import moment from "moment";

import {
  upload_media,
  initFetchMovieSubCategories,
  search_moviesMembers,
  get_patrol_crp,
  get_patrol_review, submit_patrol_review

} from "../../../actions/footer";


class PatrolMovieReviewsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      loaded: true,
      drawer: true,
      patrolReviews_list: [],
      reasonError: "",
      decision: "",
      reason: '',
      category_id: '',
      subcategory_id: '',
      typeMethod: "",
      rateType: "",
      type: "review",
      listingType: "movies",
      about: null
    };
  }

  redirectto = (movieId, slug) => {
    let path = "movies";
    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: movieId }
    })
  }

  componentWillReceiveProps(nextProps) {
    let { loaded } = this.state;
    if (nextProps.fetch_save_movie_response) {
      if (nextProps.fetch_save_movie_response && nextProps.fetch_save_movie_response.id && nextProps.fetch_save_movie_response.slug) {
        let slug = null;
        let movieId = "";
        if (nextProps.fetch_save_movie_response.id != "") {
          movieId = nextProps.fetch_save_movie_response.id;
        }
        if (nextProps.fetch_save_movie_response.slug != "") {
          slug = nextProps.fetch_save_movie_response.slug;
        }
        this.redirectto(movieId, slug);

      }
    }

    if (nextProps.ratingType && nextProps.ratingType != "") {
      this.setState({ rateType: nextProps.ratingType })
    }

    if (nextProps.patrolReviewListing && nextProps.patrolReviewListing.results && Array.isArray(nextProps.patrolReviewListing.results) && nextProps.patrolReviewListing.results.length > 0) {
      this.setState({ patrolReviews_list: nextProps.patrolReviewListing.results }, () => {
        if (nextProps.patrolReviewListing.results && nextProps.patrolReviewListing.results[0] && loaded == true) {
          this.getReviewDetail(nextProps.patrolReviewListing.results[0])
        }
      });
    }

    if (nextProps?.getPatrolReview && nextProps.getPatrolReview.results && Array.isArray(nextProps.getPatrolReview.results) && nextProps.getPatrolReview.results.length > 0 && nextProps?.getPatrolReview?.results[0]) {
      this.setState({
        about: nextProps?.getPatrolReview?.results[0],
      });
    }

  }

  componentDidMount = () => {
    let date = new Date()
  }

  getReviewDetail = async (data) => {
    this.setState({ loaded: false, about: "" }, () => {
      this.props.get_patrol_review(data.id);
    })
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let { rateType } = this.state;
    this.setState({
      [name]: value
    });
    if (name == "category_id") {
      let type = "category_id";
      this.setState({
        categoryError: "",
        category_id: e.target.value,
        patrolReviews_list: [],
        about: "",
        loaded: true
      }, () => {
        let id = this.state.category_id;
        let typeCore = "review";
        let listingType = "movies";
        this.props.get_patrol_crp(id, typeCore, listingType);
      });
      this.props.initFetchMovieSubCategories(e.target.value, type);
    }
  }

  handleSkip = () => {
    let { patrolReviews_list } = this.state;
    if (patrolReviews_list && Array.isArray(patrolReviews_list) && patrolReviews_list.length > 0) {
      patrolReviews_list.shift();
      this.setState({ patrolReviews_list, about: "" }, () => {
        this.getReviewDetail(patrolReviews_list[0])
      });
    }
  }

  handleReviewSubmit = () => {
    let { reason, decision, patrolReviews_list } = this.state
    if (reason == "") {
      this.setState({
        reasonError:
          "This field is required"
      });
    } else {
      if (patrolReviews_list && Array.isArray(patrolReviews_list) && patrolReviews_list.length > 0) {
        let params = {}
        params.flag_details = {}
        params.flag_details.type = "review";
        params.flag_details.decision = decision;
        params.flag_details.reason = reason;
        params.flag_details.object_id = patrolReviews_list && patrolReviews_list[0] ? patrolReviews_list[0].id : "";
        params.flag_details.is_patroled = true;
        this.props.submit_patrol_review(params);
        this.handleSkip()
      }

    }

  }

  handleChangeReview = (e) => {
    if (e.target.name === 'reason') {
      this.setState({ reason: e.target.value, reasonError: "" })
    }
    if (e.target.name === 'Approved') {
      this.setState({ decision: e.target.value })
    }
    if (e.target.name === 'Rejected') {
      this.setState({ decision: e.target.value })
    }
  };



  render() {
    const {
      openModal,
      togglePatrolMovieReviewsModal,
      movies,
    } = this.props;
    const { category_id, patrolCrpListing, reason, about } = this.state;
    let avg_rating = about && about.ratings_sets && about.ratings_sets.avg_rating[0] ? about.ratings_sets.avg_rating[0] : 'norating.png';
    return (
      <>
        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={openModal}
          toggle={togglePatrolMovieReviewsModal}
        >
          <form
            className="d-flex flex-column h-100"
          >
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button color="primary" onClick={togglePatrolMovieReviewsModal}>
                    <FontAwesomeIcon icon="angle-left" />
                        back to listing
                      </Button>
                </div>
                <div>
                  <div className="bg-darker p-3 text-tertiary">
                    <div className="mb-2 text-primary font-weight-bold">
                      You’re Patrolling Reviews
                        </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input
                              type="select"
                              className="transparent"
                              size="sm"
                            >
                              <option>{movies?.category}</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </li>
                      <li className="px-2">
                        <div className="d-flex">
                          <div className="mr-1">
                            <span className="text-danger">*</span>
                          </div>
                          <FormGroup>
                            <Input
                              id="category_id"
                              name="category_id"
                              onChange={this.handleChange}
                              type="select"
                              className="transparent"
                              size="sm"
                              selected={category_id}
                            >
                              <option value="">Select Category</option>
                              {movies?.children?.map((movie) => {
                                return (
                                  <option value={movie.id} key={movie.id}>
                                    {movie.category}
                                  </option>
                                );
                              })}
                            </Input>
                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.categoryError}</span>
                          </FormGroup>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ModalBody className="bg-white text-dark">
              <div className="wk-crpmodal">
                <Row>
                  <Col md="7">
                    <div className=" font-weight-bold mb-2 text-primary mb-3">Step 1. Reviews</div>
                    <p>Community Reviews are the core of WikiReviews. While free voice is important, protecting our community from legal action is important as well. The purpose of patrolling is to prevent spam and help community members avoid legally actionable missteps.</p>
                    <div className="bg-grey p-4 mb-4">
                      <p>
                        <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                          className="mr-2"
                          onError={(error) =>
                            (error.target.src = `../../../assets/images/norating.png`)
                          }
                          alt={`${about && about.total_rating && about.total_rating.avg_rating[1] ? about.total_rating.avg_rating[1] : 'no-rating'}`}

                        />Overall Rating</p>
                      {about && about.ratings_sets && Object.keys(about.ratings_sets).length > 0 ?
                        about && Object.keys(about.ratings_sets).map(rating => {
                          if (rating !== "total_review" && rating !== "avg_rating") {
                            return <div className="d-flex mx-n2"><div className="px-2"><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${about.ratings_sets[rating][0]}`} className="img-fluid mt-n1" alt={rating || ""} /></div> <div className="px-2"><div className="fs-14 ff-alt">{rating}</div></div> </div>

                          }
                        }) : ''}
                      <p className="wk-crp-projectinfo-text mt-4"><i className="icon-comma"></i> <span>{about?.review_text}</span></p>
                    </div>
                  </Col>
                  <Col md="5">
                    <div className=" font-weight-bold mb-2 text-primary">Step 2. Approve or Reject</div>
                    <div className="wk-approve-petrol-radio">
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" name="Approved" value="Approved" checked={this.state.decision === "Approved"} onChange={this.handleChangeReview} />Approved</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" name="Rejected" value="Rejected" checked={this.state.decision === "Rejected"} onChange={this.handleChangeReview} />Rejected</label>
                    </div>
                    <div className="font-weight-bold mb-2 text-primary">Step 3. Check if review doesn't adheres to our tips</div>
                    <select className="form-control wk-multiselect fs-14" multiple="multiple">
                      <option value="1">Do not post photos or videos of other copyrighted material that you don’t have permission to use. Do not post photos of people without their permission. If they are children, ask the parents before you post.</option>
                      <option value="2">Opinions are almost always protected as free speech except in the case of defamation (a false accusation of an offense or a malicious misrepresentation of someone's words or actions), true threats, solicitations to commit crimes, incitement to imminent lawless action, blackmail, fighting words and obscenity).</option>
                    </select>
                    <div className="font-weight-bold mb-2 text-primary mt-4">Step 4. Check for Flags</div>
                    <div className="wk-approve-petrol-radio">
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />General Issue</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Offensive</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Profanity</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Spam</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Hate/Racist Based</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Illegal Drug Focused</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Notability</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Universally Illegal</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Vanity Page</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Incomplete/Not Useful</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" />Vandalism</label>
                    </div>
                    <textarea className="form-control wk-crpmodal-quick-note wk-crpmodal-quick-note2  w-100" value={reason} name="reason" placeholder="Tell us why?" onChange={this.handleChangeReview}></textarea>
                    <span style={{ color: 'red', fontSize: "13px" }}>{this.state.reasonError}</span>

                    <div className="text-right mt-3">
                      <Button className="ml-0" color="primary" size="sm" onClick={this.handleReviewSubmit}>
                        Submit
                      </Button>
                      <a href="javascript:void(0)" className="ml-4" onClick={() => {
                        this.handleSkip();
                      }}>Skip ></a>
                    </div>
                  </Col>
                </Row>
              </div>

            </ModalBody>
          </form>
        </Modal>



      </>


    );



  }
}

const mapState = (state) => ({
  patrolReviewListing: state.footer.patrolReviewListing,
  getPatrolReview: state.footer.getPatrolReview,
});

const mapProps = (dispatch) => ({
  submit_patrol_review: (data) => dispatch(submit_patrol_review(data)),
  initFetchMovieSubCategories: (categoryId, type) => dispatch(initFetchMovieSubCategories(categoryId, type)),
  search_moviesMembers: (value, type) => dispatch(search_moviesMembers(value, type)),
  get_patrol_crp: (categoryId, type, listType) => dispatch(get_patrol_crp(categoryId, type, listType)),
  get_patrol_review: (reviewId) => dispatch(get_patrol_review(reviewId)),
});
export default withRouter(connect(mapState, mapProps)(PatrolMovieReviewsModal));