const initialState = {
    isMobileTrue: false,
    runSiteTour: false,
    index:0,
    toggleStarRating:{},
};

const utils = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "ENABLE_MOBILE":
            state.isMobileTrue = action.payload;
            return { ...state };

        case "CLICK_TOUR_START":
            state.runSiteTour = action.payload;
            return { ...state };

        case "SET_TOUR_STEP":
            state.step = action.payload;
            return { ...state };
            
        case "CLICK_CHANGE_TOUR_SUBSTEPS":
            state.index = action.payload;
            return { ...state };

        case "TOGGLE_DETAIL_STAR_RATING":
            state.toggleStarRating = action.payload;
            return { ...state };

        case "TOGGLE_CREATE_NEW_PROJECT":
            state.toggleCreateNewProject = action.payload;
            return { ...state };
        default:
            return state;
    }
}

export default utils;