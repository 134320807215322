import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
	Button,
	ButtonGroup,
	Col,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalHeader,
	ModalBody,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Row,
	Progress,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CollapseBasic from '../collapse';
import RichTextEditor from 'react-rte';
import {
	add_buyers_guide, submit_discuss_article, submit_article_order
} from '../../../actions/buyersGuide'
import {
	clear_media_dispatch,
	upload_add_captions,
	fetch_taxonomy_list,
	fetch_taxonomy_category
} from "../../../actions/myProfiles";
import {
	get_album_types_list,
	get_album_type_data,
	delete_selected_gallery_media, add_my_post
} from "../../../actions/user";
import { callApi } from "../../../utils/apiCaller";
import AddSubCategory from "./addSubCategory"
import { find } from 'lodash'
import {create_discussion} from "../../../actions/discussionsTab";
import VideoPlaceholder from "../../../assets/images/videopholder.jpg";

class AddArticle extends Component {

	constructor(props) {
		super(props);
		// this.toolbarSettings = {
		// 	items: ['CreateLink']
		// };
		this.state = {
			selectTitleError: "",
			titleNameError: "",
			createNewDisModalToggle: false,
			qaDropDown: null,
			qaData: null,
			dropdownSortBy: false,
			articlesData: [],
			confirmModalKey: '',
			confirmModalText: '',
			confirmModalItemId: null,
			isEnableConfirmModal: false,
			openArticle: false,
			textData: props.itemArctile && props.itemArctile.description != "" ? RichTextEditor.createValueFromString(props.itemArctile.description, 'html') : RichTextEditor.createEmptyValue(),
			select_main_category: "",
			main_category_name: "",
			uploadUrl: '/upload/multiuploader/?album=&instance=NjUHgskupvRykJG&image=undefined&doc=undefined',
			editImages: props.itemArctile && props.itemArctile.article_media ? props.itemArctile.article_media : [],

			// editImages: props.itemArctile.article_media && props.itemArctile.article_media.location != "" ? props.itemArctile.article_media.location : [],
			keySimilar: "",
			type: "media",
			listingType: "movies",
			about: null,
			mediaSet: [],
			media: [],
			article_title: "",
			article_title_id: "",
			allArticlesData: [],
			uploadMediaModal: false,
			fileReviewStatus: false,
			selectedFilesPreview: [],
			latestUploadedData: {},
			showVideoMedia: true,
			fetchTaxonomy: [],
			selectedUpMediaType: "upload",
			showGalleryType: "images",
			fetchGalleryScroll: {},
			fetchGalleryCount: 0,
			uploadCaptions: {},
			files: [],
			albumType: "",
			pageNo: 1,
			albumTypesList: [],
			albumTypeData: {},
			selectedMedia: [],
			selectedMediaIds: [],
			embedLinks: {},
			uploadFiles: [],
			uploadedFiles: [],
			progress: 0,
			addSubCategoryForm: {
				subcategory_sets: [
					{
						info: "",
						name: "",
					},
				],
			},
			subcategory_sets: [
				{
					info: "",
					name: "",
				},
			],
			fetchTaxonomyListingImages: [],
			fetchTaxonomyListingVideos: [],
			uploadMediaPostModal: false,
			uploadMedia: {
				mediaType: "image",
				albumType: "",
				pageNo: 1,
				albumTypesList: [],
				albumTypeData: {},
				selectedMedia: [],
				selectedMediaIds: [],
				embedLinks: {},
				uploadFiles: [],
				uploadedFiles: [],
				progress: 0,

			},
			formOptions: [],
			editArticle: true,
			discussArticleText: "",
			dataLengthError: "",
			openAddSubCategory: false,
			taxonomy_id: "",
			showReferenceDiv : false,
			showOrderingArrow : true,
			showUpwardOrderingArrow : true,
			showDownwardOrderingArrow : true,
			delete_image_ids: [],
			article_order_id : 0,
			article_id : '',
		};

	}

	componentWillReceiveProps(nextProps) {
		let {
			fetchGalleryScroll,
			fetchGalleryCount,
		} = this.state;

		if (nextProps.get_buyersGuides) {
			this.setState({
				allArticlesData: nextProps.get_buyersGuides,
			});
		}

		this.setState({
			taxonomy_id: nextProps.taxonomy_id
		});
		if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
			this.setState({
				...this.state,
				uploadMedia: {
					...this.state.uploadMedia,
					albumTypesList: nextProps.album_types_list,
				},
				selectedUpMediaType: "upload",
				showGalleryType: "images",
			});
		}
		if (nextProps.fetch_taxonomy_listData) {
			let taxCatListing = nextProps.fetch_taxonomy_listData;
			if (
				taxCatListing &&
				Array.isArray(taxCatListing) &&
				taxCatListing.length > 0
			) {
				if (taxCatListing[0].isTypes == "images") {
					this.setState({
						fetchTaxonomyListingImages: taxCatListing[0].children
							? taxCatListing[0].children
							: [],
					});
				} else {
					this.setState({
						fetchTaxonomyListingVideos: taxCatListing[0].children
							? taxCatListing[0].children
							: [],
					});
				}
			}
		}
		if ( nextProps.album_type_data && Object.keys(nextProps.album_type_data).length > 0) {
			let item = nextProps.album_type_data;
			if (item.album) {
				fetchGalleryScroll = [];
			}
			fetchGalleryScroll[item.page] = item;
			fetchGalleryCount = item.count;
			this.setState({
				...this.state,
				uploadMedia: {
					...this.state.uploadMedia,
					albumTypeData: nextProps.album_type_data,
				},
				fetchGalleryScroll,
				fetchGalleryCount,
			});
		}

		if (nextProps.fetch_taxonomy) {
			let taxCat = nextProps.fetch_taxonomy;
			if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
				taxCat = taxCat.filter((dt) => dt.label !== null);
			}
			this.setState({ fetchTaxonomy: taxCat[0]?.children });
		}
		if (nextProps.upload_scroll) {
			let item = nextProps.upload_scroll;
			if (item.album) {
				fetchGalleryScroll = [];
			}
			fetchGalleryScroll[item.page] = item;
			fetchGalleryCount = item.count;
			this.setState({
				fetchGalleryScroll,
				fetchGalleryCount,
				activeMyPhotoIndex: 0,
			});
		}

		if (nextProps.openEditArticle) {

			this.setState({
				article_title_id: '',
				article_title: '',
				selectTitleError : '',
				textData : RichTextEditor.createEmptyValue(),
				dataLengthError: '',
				titleNameError: '',
				openAddSubCategory: false
			});
			let { allArticlesData, showUpwardOrderingArrow, showDownwardOrderingArrow  } = this.state;
			if (nextProps.itemArctile) {
				let editItemArticle = nextProps.itemArctile;
			
				let showReferenceDiv  = editItemArticle?.article_title === 'References';
				let showOrderingArrow  = !(editItemArticle?.article_title === 'References' || editItemArticle?.article_title === 'Getting Started') ;
				// let showSingleOrderingArrow  = (editItemArticle?.buyers_guide_article_master === 2 || editItemArticle?.buyers_guide_article_master === 6 ) ? editItemArticle.buyers_guide_article_master  : 0 ;

				let filteredArticleIndex = allArticlesData.findIndex((item) => item.buyers_guide_article_master === editItemArticle?.buyers_guide_article_master);


				let data = {};
				let current_article_data = allArticlesData[filteredArticleIndex];

				let previous_article_data = allArticlesData[filteredArticleIndex-1];
				if(previous_article_data && previous_article_data['article_title'] !== 'Getting Started'){
					showUpwardOrderingArrow = false;
				}

				let next_article_data = allArticlesData[filteredArticleIndex+1];
				if(next_article_data && next_article_data['article_title'] !== 'References'){
					showDownwardOrderingArrow = false;
				}


				this.setState({
					article_id : editItemArticle.id,
					article_title_id: editItemArticle?.buyers_guide_article_master,
					article_title: editItemArticle?.article_title,
					textData: editItemArticle.description !== "" ? RichTextEditor.createValueFromString(editItemArticle.description, 'html') : RichTextEditor.createEmptyValue(),
					openAddSubCategory: false,
					delete_image_ids: [],
					editImages: editItemArticle && editItemArticle.article_media ? editItemArticle.article_media : [],
					article_order_id: editItemArticle.order_id !== '' ? editItemArticle.order_id : 0,
					showReferenceDiv,
					showOrderingArrow,
					showUpwardOrderingArrow,
					showDownwardOrderingArrow
				});
			}
		}
		// if (nextProps.openAddArticle) {
		// 	this.setState({
		// 		article_title_id: '',
		// 		article_title: '',
		// 		selectTitleError : '',
		// 		textData : RichTextEditor.createEmptyValue(),
		// 		dataLengthError: '',
		// 		titleNameError: '',
		// 		openAddSubCategory: false
		// 	});
		// }


	}
	handleChangeOptions = (e) => {
		let data = this.state.formOptions[e.target.value - 1];

		let showReferenceDiv  = data.name === 'References';
		let showOrderingArrow  = !(data.name === 'References' || data.name === 'Getting Started') ;
		
		this.setState({
			article_title_id: data.id,
			article_title: data.name,
			showReferenceDiv,
			showOrderingArrow
		})
	};
	handleInputChange = (e) => {
		this.setState({
			article_title: e.target.value,
			showReferenceDiv : true
		})
	};

	handleArrowClickForReorderingArticle = (order_type, article_id, article_title_id, article_order_id) => {
		let { taxonomy_id, allArticlesData } = this.state;

		let filteredArticleIndex = allArticlesData.findIndex((item) => item.buyers_guide_article_master === article_title_id);


		let data = {};
		let current_article_data = allArticlesData[filteredArticleIndex];
		if (order_type === 'up'){
			let previous_article_data = allArticlesData[filteredArticleIndex-1];
			if(previous_article_data['article_title'] !== 'Getting Started'){
				data = {
					"article_order_to_change": [
						{"article_id":current_article_data['id'], "order_id": previous_article_data['oredr_id']},
						{"article_id":previous_article_data['id'],"order_id": current_article_data['order_id']}
					]
				};
			}
		} else {
			let next_article_data = allArticlesData[filteredArticleIndex+1];
			if(next_article_data['article_title'] !== 'References'){
				data = {
					"article_order_to_change": [
						{"article_id":current_article_data['id'], "order_id": next_article_data['order_id']},
						{"article_id":next_article_data['id'],"order_id": current_article_data['order_id']}
					]
				};
			}
		}

		

		this.props.submit_article_order(taxonomy_id, data);
		this.props.closeEditor();
	};

	submitSubcategory = (data1) => {
		this.handleSubmitArticle(false, data1)
	}

	getAutoSelectedId = (articleTitle, type) => {
		let selectedObj = find(this.state.formOptions, { name: articleTitle })
		if (selectedObj) {
			this.setState({ article_title_id: selectedObj.id, article_title: selectedObj.name })
			if (type === 1)
				return selectedObj.id
			else if (type === 2)
				return selectedObj.name
		}
		return ""
	}

	handleCancelClick = ( ) => {
		const { closeAddEditor } = this.props;
		// if (nextProps.openAddArticle) {
		this.setState({
			article_title_id: '',
			article_title: '',
			selectTitleError : '',
			textData : RichTextEditor.createEmptyValue(),
			dataLengthError: '',
			titleNameError: '',
			openAddSubCategory: false
		});
		closeAddEditor();
		// }

	}

	handleSubmitArticle = async (is_draft, data1) => {
		const { openEditArticle, closeEditor, closeAddEditor } = this.props;
		let { uploadMedia, article_title_id, article_title, allArticlesData } = this.state;

		if (!article_title_id) {
			this.setState({
				selectTitleError: "This field is required"
			})
			return;
		} else {
			this.setState({
				selectTitleError: ""
			})
		}
		if (!article_title) {
			this.setState({
				titleNameError: "This field is required"
			})
			return;
		} else {
			this.setState({
				titleNameError: ""
			})
		}

		let data = {}
		if (openEditArticle) {

			let itemFiltered = allArticlesData.find((item) => item.buyers_guide_article_master == article_title_id);
			
			if (itemFiltered && itemFiltered.buyers_guide_article_master != this.props.itemArctile?.buyers_guide_article_master) {
				this.setState({
					selectTitleError: "Article with this option already exist, Please choose another one."
				})
				return;
			} else {
				this.setState({
					selectTitleError: ""
				})
			}

			data = {
				"description": this.state.textData?._cache?.html,
				"media": uploadMedia?.selectedMediaIds,
				"buyers_guide_article_master": this.state.article_title_id || this.getAutoSelectedId(this.props.itemArctile?.article_title, 1),
				"article_title": this.state.article_title || this.getAutoSelectedId(this.props.itemArctile?.article_title, 2),
				"article_taxonomy_list": data1 && data1.article_taxonomy_list ? data1.article_taxonomy_list : {},
				"article_taxonomy": data1 && data1.article_taxonomy ? data1.article_taxonomy : '',
				"catandmediadesc": {},
				"main_images": [],
				"category_images": {},
				"catandmedia": data1 && data1.catandmedia ? data1.catandmedia : '',
				"description_RwFExRRtURryCuP": data1 && data1.description_RwFExRRtURryCuP ? data1.description_RwFExRRtURryCuP : '',
				"RwFExRRtURryCuP": data1 && data1.RwFExRRtURryCuP ? data1.RwFExRRtURryCuP : '',
				"delete_image_ids" : this.state.delete_image_ids ? this.state.delete_image_ids : [],
				// "buyers_guide_article_order_id" : this.state.article_title_id || this.getAutoSelectedId(this.props.itemArctile?.article_title, 1)
			}
			let description = data.description;
			let htmlTest = /<[^>]+>/g.test(description);

			if (htmlTest) {
				description = description.replace(/<[^>]+>/g, '');
			}

			if (description === undefined || description?.length < 100) {
				this.setState({
					dataLengthError: "Ensure this value has at least 100 characters."
				});
				return;
			}
			let id = this.props.itemArctile.id;
			let type = "Edit";
			let taxonomy_id = this.state.taxonomy_id;
			let message = "You have updated an article in the Buyer's Guide..";
			this.props.add_buyers_guide(id, type, taxonomy_id, data, message);
			closeEditor();

		} else {
			let { allArticlesData } = this.state;
			let itemFiltered = allArticlesData.find((item) => item.buyers_guide_article_master == this.state.article_title_id);
			if (itemFiltered) {
				this.setState({
					selectTitleError: "Article with this option already exist, Please choose another one."
				})
				return;
			} else {
				this.setState({
					selectTitleError: ""
				})
			}
			data = {
				"description": this.state.textData?._cache?.html,
				"media": uploadMedia?.selectedMediaIds,
				"is_draft": is_draft ? true : false,
				"buyers_guide_article_master": this.state.article_title_id,
				"article_title": this.state.article_title,
				"article_taxonomy_list": {},
				"taxonomy_id": this.state.taxonomy_id,
				"article_taxonomy": "",
				"catandmediadesc": {},
				"main_images": [],
				"category_images": {},
				"buyers_guide_article_order_id":  this.state.article_title_id,
				// "delete_image_ids" : []
			};

			let description = data.description;
			let htmlTest = /<[^>]+>/g.test(description);

			if (htmlTest) {
				description = description.replace(/<[^>]+>/g, '');
			}

			if (description === undefined || description?.length < 100) {
				this.setState({
					dataLengthError: "Ensure this value has at least 100 characters."
				});
				return;
			}
			let id = "";
			let type = "Add";
			let taxonomy_id = this.state.taxonomy_id;
			let message = "You have added an article in the Buyer's Guide..";
			this.props.add_buyers_guide(id, type, taxonomy_id, data, message);
			closeAddEditor();
		}

	}

	submitDiscussion = async () => {
		let { uploadMedia, discussArticleText } = this.state;
		let taxonomy_id =  this.props.taxonomy_id;
		var data = {
			body: discussArticleText,
			buyers_article: this.props.itemArctile.id,
			// buyers_guide: 1,
			title: this.props.article_title,
			messagemedia_set: uploadMedia?.selectedMediaIds,
			related_taxonomy: this.props.itemArctile.buyers_taxonomy
		}
		let message = "Your message has been added to the Discussion page.";
		this.props.submit_discuss_article(data, message, taxonomy_id);


		// let taxonomy_id =  this.props.taxonomy_id;
		// let url =  `/api/buyers-discussion/?entity=${taxonomy_id}&page=1`;
		//
		// let data1 = {
		// 	title: this.props.article_title,
		// 	body: discussArticleText,
		// 	user_entry: taxonomy_id,
		// 	messagemedia_set: uploadMedia?.selectedMediaIds,
		// 	apiUrl:url
		// };
		// this.props.create_discussion(data1, url);
		this.props.toggle();

		// }
	}

	handleChangeTaxonomyData = (e, index) => {
		let { value } = e.target;
		let { uploadCaptions, uploadMedia } = this.state;
		let data = {};
		let upload_data = uploadMedia.uploadFiles[index];
		let caption_data = uploadCaptions[index];
		data.caption = caption_data;
		data.category = value;
		let id = upload_data.id;
		this.setState({ taxonomycategoryId: value }, () =>
			this.props.upload_add_captions(id, data)
		);
	};

	handleOnClickRemoveSelectedMedia = (id) => () => {
		let removeMediaIds = [];
		removeMediaIds.push(id);
		this.props.delete_selected_gallery_media(removeMediaIds);
		let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
			(file) => file.id !== id
		);
		let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
			(file) => file.id !== id
		);
		let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
			(item) => item !== id
		);
		let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
			(file) => file.id !== id
		);
		this.setState({
			...this.state,
			uploadMedia: {
				...this.state.uploadMedia,
				selectedMedia: selectedMedia,
				selectedMediaIds: selectedMediaIds,
				uploadedFiles: uploadedFiles,
				uploadFiles: uploadFiles,
			},
		});
	};

	handleRemoveGalleryMediaOfEditArticle = (media, index, editPost) => {
		let { delete_image_ids, editImages } = this.state;
		let id = media.id;
		delete_image_ids.push(id);

		editImages = this.state.editImages.filter(
			(file) => file.id !== id
		);

		this.setState({
			...this.state,
			editImages
		});
	}

	handleOnClickRemoveSelectedGalleryMedia = (media, index, editPost) => {
		// remove media from upload media state
		let { uploadMedia, editImages } = this.state;
		let id = media.id;

		//for edit
		let editSelectedMediaFiles = [];
		// let editImages = [];
		if (editPost){
			editImages = editImages?.filter(data => data.id !== id);
		}

		let filterSelectedMediaIds = [];
		let filterSelectedMedia = [];
		if (id) {
			filterSelectedMediaIds = uploadMedia?.selectedMediaIds.filter(data => data !== id);
			filterSelectedMedia = uploadMedia?.selectedMedia.filter(data => data.id !== id);
		}
		uploadMedia['selectedMediaIds'] = filterSelectedMediaIds;
		uploadMedia['selectedMedia'] = filterSelectedMedia;

		// if (editPost) {
		// 	editPost["messagemedia_set"] = filterSelectedMediaIds;
		// }

		this.setState({ uploadMedia, editPost, editImages }, () => { this.props.delete_selected_gallery_media([id]) });
	};


	handleOnClickSelectGalleryMedia = (media) => {
		if (media) {
			let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
			let newSelectedMediaIds;
			let newSelectedMedia;
			if (index !== -1) {
				newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
					(item) => item !== media.id
				);
				newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
					(item) => item.id !== media.id
				);
			} else {
				newSelectedMediaIds = [
					...this.state.uploadMedia.selectedMediaIds,
					media.id,
				];
				newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
			}
			this.setState({
				...this.state,
				uploadMedia: {
					...this.state.uploadMedia,
					selectedMedia: newSelectedMedia,
					selectedMediaIds: newSelectedMediaIds,
				},
			});
		}
	};

	handleOnFilePostUploadChange = (event) => {
		let uploadFiles = event.target.files;
		let showFiles = [];
		for (const key of Object.keys(uploadFiles)) {
			let itemType = uploadFiles[key].type.split("/");
			let extName = itemType[0];
			showFiles.push({ id: "", url: extName === "image" ? URL.createObjectURL(uploadFiles[key]) : require("../../../assets/images/blank.png") });
		}
		this.setState({
			...this.state,
			uploadMedia: {
				...this.state.uploadMedia,
				uploadFiles: showFiles,
				progress: 0,
			},
		});
		let progressPart = 100 / showFiles.length;
		let progress = 0;
		for (const key of Object.keys(uploadFiles)) {
			let data = new FormData();
			data.append("file", uploadFiles[key]);
			callApi(
				`/upload/multiuploader/?album=&instance=NjUHgskupvRykJG&image=undefined&doc=undefined`,
				"POST",
				data,
				true
			).then((response) => {
				this.handleOnClickSelectGalleryMedia(response);
				if (showFiles.length === 1 || key === showFiles.length - 1) {
					progress = 100;
				} else {
					progress = progress + progressPart;
				}
				if (response && response.code !== 400 && response.code !== 500) {
					showFiles[key].id = response.id;
					showFiles[key].media_type = response.media_type;
					showFiles[key].thumbnail = response.thumbnail;
					if (response.media_type == "image") {
						this.props.fetch_taxonomy_list('movies', 'images');
					} else {
						this.props.fetch_taxonomy_list('movies', 'videos');
					}

					this.setState({
						...this.state,
						uploadMedia: {
							...this.state.uploadMedia,
							progress: progress,
							// uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
							uploadedFiles: showFiles,
						},
					});
				}
			});
		}
	};

	clearMediaDispatch = () => {
		this.setState(
			{
				latestUploadedData: {},
				uploadCaptions: {},
				selectedFilesPrevietoggleBottomMenuw: [],
				files: [],
			},
			() => this.props.clear_media_dispatch()
		);
	};

	toggleVideoOption = () => {
		this.setState({ showVideoMedia: !this.state.showVideoMedia });
	};

	AddNewEmbedLink = (e) => {
		e.preventDefault();
		let { embeddedLink } = this.state;
		this.props.embedNewLink({
			data: {
				video: `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
				exclude_list: [],
				body_preview: `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
				question_rating_category: [],
				messagemedia_set: [],
			},
		});
		this.setState({ embeddedLink: "" });
	};

	fetchScrollPhotoMedia = (isVideo) => {
		let { fetchGalleryScroll } = this.state;
		let getDataItems = [];
		const imagePreview = <img src={VideoPlaceholder} alt=""/>;

		if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
			Object.keys(fetchGalleryScroll).forEach((item) => {
				if (
					fetchGalleryScroll[item] &&
					fetchGalleryScroll[item].results !== null
				) {
					let data = fetchGalleryScroll[item].results;
					getDataItems = getDataItems.concat(data);
				}
			});

			if (getDataItems && getDataItems.length > 0) {
				return getDataItems.map((item) => {
					let itemName = (item?.thumbnail).split(".");
					let itemFile = (item?.file).split(".");
					let extName = itemName[itemName.length - 1];
					let extFile = itemFile[itemFile.length - 1];
					let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;

					return (
						<Col xs="auto" className="mb-3">
							<FormGroup
								className="checkable-media"
								check
								style={{ width: "120px" }}
							>
								<Label check>
									<Input
										type="checkbox"
										onClick={() => this.handleOnClickSelectGalleryMedia(item)}
									/>
									<div className="gallery-media">
										<img
											className="img-fluid"
											src={isVideo ? srcFile : item?.file}
											alt={item?.filename ? item.filename : "file"}
											onError={(error) => (error.target.src = require("../../../assets/images/videopholder.jpg"))}
										/>
									</div>
								</Label>
								<div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
									{item.caption ? item.caption : "No Caption"}
								</div>
								<div className="font-weight-normal fs-14 d-block">
									{item.category ? item.category : "No Category"}
								</div>
							</FormGroup>
						</Col>
					);
				});
			}
		} else {
			return getDataItems;
		}
	};

	handleMediaUpload = () => {
		const queryCaptionParams = {
			category: "Album",
			depth: true,
			delsel: "",
			ancestor: "",
			sub_cat: "",
			parent: "",
		};
		let { mediaSetReview } = this.state;
		this.props.getAllCaptionCategories(queryCaptionParams);

		this.setState(
			{
				uploadMediaModal: !this.state.uploadMediaModal,
				fileReviewStatus: true,
				mediaSet: mediaSetReview,
			},
			() => {
				this.clearMediaDispatch();
				this.props.fetch_media_data("image", "", 1, true, true);
				this.props.fetch_media_data("video", "", 1, true, true);
			}
		);
	};

	checkIsAdded = (item) => {
		let { mediaSet } = this.state;
		let id = item.id;
		let itemFiltered = mediaSet.find((item) => item.id === id);
		if (itemFiltered) {
			return true;
		} else {
			return false;
		}
	};

	setMediaSetForItems = (e, item) => {
		// e.preventDefault();
		let { mediaSet, offerCheck } = this.state;
		if (item && item.id) {
			let id = item.id;
			let itemFiltered = mediaSet.find((item) => item.id === id);

			if (itemFiltered) {
				const index = mediaSet.indexOf(itemFiltered);
				if (index > -1) {
					mediaSet.splice(index, 1);
				}
			} else {
				mediaSet.push(item);
			}

			this.setState({ mediaSet, mediaSetReview: mediaSet, offerCheck });
		}
	};

	removeFromMedia = (e, item) => {
		e.preventDefault();
		let { mediaSet } = this.state;
		let id = item.id;
		let itemFiltered = mediaSet.find((item) => item.id === id);
		if (itemFiltered) {
			const index = mediaSet.indexOf(itemFiltered);
			if (index > -1) {
				mediaSet.splice(index, 1);
			}
			this.setState({ mediaSet, mediaSetReview: mediaSet });
		}
	};
	handleCaptionByIndex = (e, index) => {
		let { value } = e.target;
		let { uploadCaptions, uploadMedia } = this.state;
		uploadCaptions[index] = value;
		let data = {};
		let upload_data = uploadMedia.uploadFiles[index];
		let caption_data = uploadCaptions[index];
		data.caption = caption_data;
		let id = upload_data.id;
		this.setState({ uploadCaptions }, () =>
			this.props.upload_add_captions(id, data)

		);

	};
	handleOnClickGalleryType = (type) => {
		let mediaType = "";
		if (type === "images") {
			mediaType = "image";
		} else if (type === "videos") {
			mediaType = "video";
		}
		this.props.get_album_type_data(mediaType, "", 1);
		this.setState({
			...this.state,
			selectedUpMediaType: "gallery",
			showGalleryType: type,
			uploadMedia: {
				...this.state.uploadMedia,
				mediaType: mediaType,
				albumType: "",
				pageNo: 1,
			},
		});
	};
	handleOnClickAlbumTypeChange = (e) => {
		this.props.get_album_type_data("image", e.target.value, 1);
		this.setState({
			...this.state,
			selectedUpMediaType: "gallery",
			showGalleryType: "images",
			uploadMedia: {
				...this.state.uploadMedia,
				mediaType: "image",
				albumType: e.target.value,
				pageNo: 1,
			},
		});
	};
	handleOnClickSelectedUploadMediaType = (type) => {
		if (type === "gallery") {
			this.props.get_album_type_data("image", "", 1);
			this.setState({
				...this.state,
				selectedUpMediaType: type,
				showGalleryType: "images",
				uploadMedia: {
					...this.state.uploadMedia,
					mediaType: "image",
					albumType: "",
					pageNo: 1,
					embedLinks: {},
					progress: 0,
				},
			});
		} else if (type === "upload") {
			this.setState({
				...this.state,
				selectedUpMediaType: type,
				uploadMedia: {
					...this.state.uploadMedia,
					embedLinks: {},
					progress: 0,
				},
			});
		} else if (type === "embed") {
			let embedLinks = {
				0: "",
			};
			this.setState({
				...this.state,
				selectedUpMediaType: type,
				uploadMedia: {
					...this.state.uploadMedia,
					selectedMedia: [],
					selectedMediaIds: [],
					embedLinks: embedLinks,
				},
			});
		}
	};

	embedLinkOnChange =  (id) => async (e) => {
		await this.setState({
			...this.state,
			uploadMedia: {
				...this.state.uploadMedia,
				embedLinks: {
					...this.state.uploadMedia.embedLinks,
					[id]: e.target.value,
				},
			},
		});
		
	};
	mapEmbedLinks = () => {
		let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
		return embedKeys.map((emKey) => {
			return (
				<div className="d-flex mx-n2 mb-2" key={emKey}>
					<div className="flex-fill px-2">
						<Input
							type="url"
							bsSize="sm"
							className="bg-white"
							value={this.state.uploadMedia.embedLinks[emKey]}
							onChange={this.embedLinkOnChange(emKey)}
							placeholder="Embeded link"
						/>
					</div>
					<div className="px-2">
						<Button
							title="Remove"
							color="danger"
							size="sm"
							hidden={emKey <= 1 ? true : false}
							onClick={() => {
								this.deleteEmbedLinkRow(emKey);
							}}
						>
							<FontAwesomeIcon icon="minus" />
						</Button>
					</div>
				</div>
			);
		});
	};
	deleteEmbedLinkRow = (emKey) => {
		let embedLinks = this.state.uploadMedia.embedLinks;
		if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
			delete embedLinks[emKey];
			this.setState({
				...this.state,
				uploadMedia: {
					...this.state.uploadMedia,
					embedLinks: embedLinks,
				},
			});
		}
	};
	addEmbedlinkRow = () => {
		let newEmbedLinks = {};
		for (let i = 2; i < 5; i += 1) {
			if (
				Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
				-1
			) {
				newEmbedLinks[i] = "";
				break;
			}
		}
		if (Object.keys(newEmbedLinks).length > 0) {
			this.setState({
				...this.state,
				uploadMedia: {
					...this.state.uploadMedia,
					embedLinks: {
						...this.state.uploadMedia.embedLinks,
						...newEmbedLinks,
					},
				},
			});
		}
	};
	confirmDeleteModalToggle = () => {
		if (this.state.deletePostId) {
			this.setState({ deletePostId: 0, deletePostType: "" });
		}
		this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
	};
	handleOnClickUploadMedia = () => {
		this.props.get_album_types_list();
		this.setState({
			...this.state,
			uploadMedia: {
				...this.state.uploadMedia,
				mediaType: "image",
				albumType: "",
				pageNo: 1,
				albumTypeData: {},
				embedLinks: {},
				progress: 0,
				uploadFiles: [],
				uploadedFiles: [],
			},
			uploadMediaPostModal: true,
			selectedUpMediaType: "upload",
			showGalleryType: "images",
		});
	};
	truncate = (filenameString) => {
		let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
		let extension = filenameString.substr(
			filenameString.lastIndexOf("."),
			filenameString.length - 1
		);
		let partial = filename.substring(filename.length - 3, filename.length);
		filename = filename.substring(0, 15);
		return filename + "..." + partial + extension;
	};
	limitedPostsModalToggle = () => {
		this.setState({ limitedPostsModal: !this.state.limitedPostsModal });
	};
	uploadMediaModalToggle = () => {
		this.setState({
			...this.state,
			uploadMediaPostModal: !this.state.uploadMediaPostModal,
			uploadMedia: {
				...this.state.uploadMedia,
				selectedMedia: [],
				selectedMediaIds: [],
				mediaType: "image",
				albumType: "",
				pageNo: 1,
				albumTypeData: {},
				embedLinks: {},
				uploadedFiles: [],
				uploadFiles: [],
			},
			selectedUpMediaType: "upload",
			showGalleryType: "images",
			taxonomycategoryId: null,
			fetchTaxonomyListingImages: [],
			fetchTaxonomyListingVideos: [],
		});
	};
	handleOpenModal(data, url) {
		this.setState({
			viewMyPhotosModal: !this.state.viewMyPhotosModa,
			imageAttacment: data,
		});
	}

	handleScrollGalleryVideo = (e) => {
		let { refGalleryVideoScroll } = this;
		// let count = 0;
		let clientHeight = refGalleryVideoScroll.current.clientHeight;
		let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
		const scrollTop =
			this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
		let scrollTopCalulated = scrollHeight - clientHeight;
		let scrollMinimun_8 = scrollTopCalulated - 8;
		let scrollMinimun_6 = scrollTopCalulated - 6;
		let scrollMinimun_5 = scrollTopCalulated - 5;
		let scrollMinimun_3 = scrollTopCalulated - 3;
		let scrollMinimun_1 = scrollTopCalulated - 1;

		if (
			scrollTopCalulated === scrollTop ||
			scrollTop === scrollMinimun_1 ||
			scrollTop === scrollMinimun_3 ||
			scrollTop === scrollMinimun_5 ||
			scrollTop === scrollMinimun_6 ||
			scrollTop === scrollMinimun_8
		) {
			this.fetchGalleryOnScroll({
				type: "video",
				album: "",
				force_update: false,
			});
		}
	};

	fetchGalleryOnScroll = ({ type, album, force_update }) => {
		let { fetchGalleryScroll, fetchGalleryCount } = this.state;
		if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
			let totalCount = fetchGalleryCount / 10;
			let roundValue = parseInt(totalCount.toFixed());
			let countPhotos = Object.keys(fetchGalleryScroll).length;
			let nextCount = countPhotos + 1;
			if (nextCount <= roundValue) {
				let page = nextCount;
				this.props.get_album_type_data(type, "", page);
			}
		}
	};

	handleScrollGalleryImage = (e) => {
		let { refGalleryImageScroll } = this;
		let { fetchGalleryAlbum } = this.state;

		let clientHeight = refGalleryImageScroll.current.clientHeight;
		let scrollHeight = refGalleryImageScroll.current.scrollHeight;
		const scrollTop =
			this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
		let scrollTopCalulated = scrollHeight - clientHeight;
		let scrollMinimun_8 = scrollTopCalulated - 8;
		let scrollMinimun_6 = scrollTopCalulated - 6;
		let scrollMinimun_5 = scrollTopCalulated - 5;
		let scrollMinimun_3 = scrollTopCalulated - 3;
		let scrollMinimun_1 = scrollTopCalulated - 1;

		if (
			scrollTopCalulated === scrollTop ||
			scrollTop === scrollMinimun_1 ||
			scrollTop === scrollMinimun_3 ||
			scrollTop === scrollMinimun_5 ||
			scrollTop === scrollMinimun_6 ||
			scrollTop === scrollMinimun_8
		) {
			this.fetchGalleryOnScroll({
				type: "image",
				album: fetchGalleryAlbum,
				force_update: false,
			});
		}
	};

	insertEmbedLinks = () => {
		//let post = this.state.addPost;

		let embedValues = Object.values(this.state.uploadMedia.embedLinks).filter(
			(item) => item !== ""
		);

		//if (post.body && post.body.replace(/(<([^>]+)>)/gi, "") !== "") {
		// if (embedValues.length > 0) {
		//   embedValues = embedValues.map((item) => "<p>" + item + "</p>");
		//   post.body = post.body + embedValues.join("");
		//   post.body_preview = post.body;
		// }
		// this.setState({
		//   ...this.state,
		//   addPost: post
		// });
		//} else {
		if (embedValues.length > 0) {
			embedValues = embedValues.map((item) => "<a href=" + item + "><p>" + item + "</p></a>");
			let newPost = {
				video: embedValues.join("\n"),
				exclude_list: [],
				question_rating_category: [],
				messagemedia_set: [],
			};
			// if (this.state.myPostsList.count < this.state.limitedPostsCount) {
			this.props.add_my_post(
				newPost,
				this.state.filterType,
				this.state.pageNo,
				'buyers guide'
			);

			this.setState({
				...this.state,
				uploadMedia: {
					...this.state.uploadMedia,
					selectedMedia: [],
					selectedMediaIds: [],
					embedLinks: {},
				},
				uploadMediaPostModal: false,
			});
		}
		//}
	};

	handleChangeEditor = (value) => {

		this.setState({ textData: value, });
		if (value.toString('html').length >= 100) {
			this.setState({ dataLengthError: '' });
		}
		else {
			this.setState({
				dataLengthError: "Ensure this value has at least 100 characters."
			});
		}

	}

	componentDidMount = () => {
		this.fetchDataFromHtmlForm();
	};

	fetchDataFromHtmlForm = async () => {
		let url = `/form/buyersguide`;

		if (url) {
			try {
				const response = await callApi(url, 'GET');

				if (response?.code == 200) {
					let data = response?.data;
					if (data) {
						const doc = new DOMParser().parseFromString(data, "text/html")

						if (doc) {
							let article_guide = doc.getElementById('id_buyers_guide_article_master');

							let article_guide_length = doc.getElementById('id_buyers_guide_article_master').options.length;

							let i;
							for (i = 0; i <= article_guide_length; i++) {
								if (article_guide.getElementsByTagName("option")[i]?.value !== undefined) {
									this.state.formOptions.push({
										id: article_guide.getElementsByTagName("option")[i]?.value,
										name: article_guide.getElementsByTagName("option")[i]?.text
									})
								}
							}
						}
					}
				}
			} catch (e) {
				console.log(e)
			}
		}
	}

	discussArticle = (e) => {
		this.setState({
			discussArticleText: e.target.value
		})
	}
	render() {
		const { openAddSubCategory, showReferenceDiv, showOrderingArrow, showUpwardOrderingArrow, showDownwardOrderingArrow, article_order_id, article_id } = this.state;
		let { openEditArticle } = this.props;
		
		return (
			<div>

				<div className="bg-white p-3 mb-3">
					{ showReferenceDiv &&
					<CollapseBasic title="Reference policy"
								   containerClass="bg-transparent type-secondary heading-lg heading-secondary-dark expanded-dark mb-3"
								   bodyClass="fs-13 ff-alt text-secondary-dark" isOpen={true} noPadding={true}>
						<p>A good reference abides by the following:</p>
						<ul className="list-unstyled">
							<li className="mb-2">
								<strong className="ff-base">1) {" "}</strong>
								It has been published with some editorial oversight, and by a publisher that stands
								behind the words said in the article (so no opinion or fan pieces).
							</li>
							<li className="mb-2">
								<strong className="ff-base">2) {" "}</strong>
								The piece was written in a neutral tone, and was not intended to hype its subject
								manner in any way.
							</li>
							<li className="mb-2">
								<strong className="ff-base">3) {" "}</strong>
								There is no proven conflict of interest or redaction that might affect the integrity
								of the information of the piece.
							</li>
						</ul>
					</CollapseBasic>
					}

					<div className="d-flex flex-nowrap mx-n2">
						<div className="flex-grow-1 px-2">
							<FormGroup>
							
								<Input className="primary select-type-dark" bsSize="sm" type="select" name="select_main_category" onChange={this.handleChangeOptions}>
									{this.state.formOptions && Array.isArray(this.state.formOptions) && this.state.formOptions?.length ?
										this.state.formOptions.map((item, index) => (
											// <option selected={item.id === this.props.itemArctile?.buyers_guide_article_master.toString()} value={index + 1}>{item.name || 'Null'}</option>
											<option selected={item.id === this.state.article_title_id.toString()} value={index + 1}>{item.name || 'Null'}</option>
										))
										: null}
								</Input>
								<span style={{ color: 'red' }}>{this.state.selectTitleError}</span>
							</FormGroup>
						</div>
						{/*Arrows for reordering articles */}
						{ openEditArticle &&
						showOrderingArrow &&
						<div className="px-2">
							<div className="d-inline-flex">
								<Button color="white" size="sm" disabled={showUpwardOrderingArrow} onClick={()=>this.handleArrowClickForReorderingArticle('up', article_id, this.state.article_title_id, article_order_id)}
										className={`mx-0 ${showUpwardOrderingArrow ? 'border-tertiary text-tertiary' : 'border-primary text-primary'}`}
								>
									<FontAwesomeIcon icon="arrow-up"/>
								</Button>
								<Button color="white" size="sm" disabled={showDownwardOrderingArrow} onClick={()=>this.handleArrowClickForReorderingArticle('down', article_id, this.state.article_title_id, article_order_id)}
										className={`mx-0 ${showDownwardOrderingArrow ? 'border-tertiary text-tertiary' : 'border-primary text-primary'}`}
								>
									<FontAwesomeIcon icon="arrow-down"/>
								</Button>
							</div>
						</div>
						}
					</div>
					<FormGroup>
						<Input className="primary ph-bold ph-grey" bsSize="sm" type="text" name="main_category_name" placeholder="Add / Edit Title"
							   style={{ maxWidth: '450px' }} value={this.state.article_title} onChange={this.handleInputChange} />
						<span style={{ color: 'red' }}>{this.state.titleNameError}</span>
					</FormGroup>

					<div className="mb-3">
						<div className="text-center" style={{ wordBreak: 'break-word' }}>
							<div className="fs-24 ff-headings">
								<RichTextEditor
									placeholder="Write your content here..."
									value={this.state.textData}
									onChange={this.handleChangeEditor}
								/>
								
							</div>
						</div>
						<span style={{ color: 'red', fontSize: "14px" }}>{this.state.dataLengthError}</span>
					</div>
					{/*<div className="wk-articles-tab-content pt-2">*/}
					{/*	{this.state.editImages && Array.isArray(this.state.editImages) && this.state.editImages.length > 0 ?*/}
					{/*		this.state.editImages.map((editImages, indexArticle) => (*/}
					{/*			<img*/}
					{/*				src={editImages?.location}*/}
					{/*				className="img-fluid"*/}
					{/*				onError={(error) =>*/}
					{/*					(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)*/}
					{/*				}*/}
					{/*				alt={editImages.caption ? editImages.caption : 'no-image'}*/}
					{/*			/>*/}
					{/*		)) : null}*/}
					{/*</div>*/}
					{/* Uploaded media shown here */}

					<div className="border mb-3">
						<Row xs={2} sm={3} md={4} lg={5} noGutters>
							{/*Edit article media - Already added media*/}
						
							{this.state.editImages && Array.isArray(this.state.editImages) && this.state.editImages.length > 0 ?
								this.state.editImages.map((editImages, indexArticle) => (

									<Col>
										<div className="d-flex pr-3 m-2">
											<div>
												{
													(editImages.type === "image" ||
														editImages.media_type === "image" ||
														editImages.type === "video" ||
														editImages.media_type === "video" || editImages.type === "audio" ||
														editImages.media_type === "audio") && (
														<div className="gallery-media">
															{ editImages.media_type === 'image' ?
																<img
																	className="img-fluid img-thumbnail"
																	src={editImages.url ? editImages.url : editImages.file}
																	alt={
																		editImages.filename
																			? editImages.filename.length < 20
																			? editImages.filename
																			: this.truncate(editImages.filename)
																			: editImages.name
																			? editImages.name.length < 20
																				? editImages.name
																				: this.truncate(editImages.name)
																			: ""
																	} /> :
																<img
																	className="img-fluid img-thumbnail"
																	src={editImages.thumbnail && editImages.thumbnail !== null ? editImages.thumbnail : editImages.url}
																	alt={
																		editImages.filename
																			? editImages.filename.length < 20
																			? editImages.filename
																			: this.truncate(editImages.filename)
																			: editImages.name
																			? editImages.name.length < 20
																				? editImages.name
																				: this.truncate(editImages.name)
																			: ""
																	}
																/>}
														</div>
													)}
											</div>

											<div className="mx-n3 mt-2">
												<Button
													color="delete-media"
													size="sm"
													title="Remove Media"
													onClick={() =>
														this.handleRemoveGalleryMediaOfEditArticle(
															editImages, indexArticle, true
														)
													}
												>
													<FontAwesomeIcon icon="trash-alt" />{" "}
												</Button>
											</div>
										</div>
									</Col>
								)) : ''
							}
							{/* Newly uploaded media*/}
							{this.state.uploadMedia.selectedMedia.length ?
								this.state.uploadMedia.selectedMedia.map((item, indexMedia) => (
									<Col>
										<div className="d-flex pr-3 m-2">
											<div>
												{(item.type === "image" ||
													item.media_type === "image" ||
													item.type === "video" ||
													item.media_type === "video" || item.type === "audio" ||
													item.media_type === "audio") && (
													<div className="gallery-media">
														{ item.media_type === 'image' ?
															<img
																className="img-fluid img-thumbnail"
																src={item.url ? item.url : item.file}
																alt={
																	item.filename
																		? item.filename.length < 20
																		? item.filename
																		: this.truncate(item.filename)
																		: item.name
																		? item.name.length < 20
																			? item.name
																			: this.truncate(item.name)
																		: ""
																} /> :
															<img
																className="img-fluid img-thumbnail"
																src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
																alt={
																	item.filename
																		? item.filename.length < 20
																		? item.filename
																		: this.truncate(item.filename)
																		: item.name
																		? item.name.length < 20
																			? item.name
																			: this.truncate(item.name)
																		: ""
																}
															/>}
													</div>
												)}
											</div>
											<div className="mx-n3 mt-2">
												<Button
													color="delete-media"
													size="sm"
													title="Remove Media"
													onClick={() =>
														this.handleOnClickRemoveSelectedGalleryMedia(
															item, indexMedia, false
														)
													}
												>
													<FontAwesomeIcon icon="trash-alt" />{" "}
												</Button>
											</div>
										</div>
									</Col>
								))
								: ''
							}
						</Row>
					</div>


					<div>
						<Button
							color="transparent"
							size="sm"
							className="mr-2 bg-transparent"
							title="Upload Media"
							onClick={() =>
								this.handleOnClickUploadMedia()
							}
						>
							<img
								src={require("../../../assets/images/icons/feed-cam.png")}
								alt=""
							/>
						</Button>
					</div>

					{/* Sub category demo*/}
					<div className="my-3" hidden>
						<hr className="border-dark" />
						<FormGroup>
							<Label className="text-primary font-weight-bold mb-2">Choose sub-category</Label>
							<Input className="primary ph-bold ph-grey" bsSize="sm" type="text" name="sub_category_name" placeholder="Search category here ..." style={{ maxWidth: '330px' }} />
						</FormGroup>

						<FormGroup>
							<Input className="primary ph-bold ph-grey" bsSize="sm" type="textarea" name="sub_category_details" placeholder="Write your content here..." rows="3" />
						</FormGroup>

						{/* Uploaded media shown here */}
						<div className="border mb-3">
							<Row xs={2} sm={3} md={4} lg={5} noGutters>
								<Col>
									<div className="d-flex pr-3 m-2">
										<div>
											<img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
										</div>
										<div className="mx-n3 mt-2">
											<Button color="delete-media" size="sm" title="Remove Media"><FontAwesomeIcon icon="trash-alt" /> </Button>
										</div>
									</div>
								</Col>
								<Col>
									<div className="d-flex pr-3 m-2">
										<div>
											<img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
										</div>
										<div className="mx-n3 mt-2">
											<Button color="delete-media" size="sm" title="Remove Media"><FontAwesomeIcon icon="trash-alt" /> </Button>
										</div>
									</div>
								</Col>
								<Col>
									<div className="d-flex pr-3 m-2">
										<div>
											<img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
										</div>
										<div className="mx-n3 mt-2">
											<Button color="delete-media" size="sm" title="Remove Media"><FontAwesomeIcon icon="trash-alt" /> </Button>
										</div>
									</div>
								</Col>
							</Row>
						</div>

						<div>
							<Button
								color="transparent"
								size="sm"
								className="mr-2 bg-transparent"
								title="Upload Media"
							>
								<img
									src={require("../../../assets/images/icons/feed-cam.png")}
									alt=""
								/>

								{/* images */}


							</Button>
						</div>
						<div className="text-right">
							<Button color="tertiary reset" size="sm">Cancel</Button>
						</div>

						<hr />
					</div>

					{/* Sub category */}
					<div>
						<div hidden={openAddSubCategory == true ? false : true}>
							{/*<div >*/}
							<AddSubCategory openAddSubCategory={openAddSubCategory} taxonomyId={this.state.taxonomy_id} submitSubcategory={this.submitSubcategory}
											closeAddEditor={() => this.setState({
												openArticle: false
											})} />
						</div>

						{!showReferenceDiv &&   //hide subcategory in case of reference article
						<div hidden={openAddSubCategory == true ? true : false} className="text-right mb-2">
							<Button color="link" size="sm" className="p-0 text-primary hover-blue"
									onClick={() =>
										this.setState({
											openAddSubCategory: true
										})
									}>
								<FontAwesomeIcon icon="plus"/>{" Add another sub-category"}
							</Button>
						</div>
						}

						{!openEditArticle &&
						<div className="text-right">
							<Button color="grey" size="sm" className="mw" onClick={()=>{
								this.handleCancelClick();
							}}>Cancel</Button>
							<Button color="tertiary reset" size="sm" className="mw" onClick={() => {
								this.handleSubmitArticle(true)
							}}>Save Draft</Button>
							<Button color="primary" size="sm" className="mw" onClick={() => {
								this.handleSubmitArticle(false)
							}}>Submit</Button>
						</div>
						}

						{openEditArticle &&
						<div className="text-right">
							<Button color="grey" size="sm" className="mw" onClick={this.props.closeEditor}>Cancel</Button>

							<Button color="primary" size="sm" className="mw" onClick={() => {
								this.handleSubmitArticle(false)
							}}>Update</Button>
						</div>
						}
					</div>

				</div>

				{/* discuss modal */}
				<Modal
					size="lg"
					scrollable
					className="drawer-modal"
					isOpen={this.props.openModal}
					toggle={this.props.toggle}
				>
					<form
						className="d-flex flex-column h-100"
					>
						<div className="modal-header p-0">
							<div className="w-100">

								<div>
									<div className="bg-darker p-3 text-tertiary">
										<div className="mb-2 text-primary font-weight-bold">
											ADD NEW DISCUSSION TO ARTICLE
										</div>
										<button type="button" class="close modal-type-close _dark" aria-label="Close" onClick={this.props.toggle}>
											<span aria-hidden="true">×</span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<ModalBody className="bg-white text-dark">
							<div className="wk-crpmodal">
								<Row>
									<Col md="6">
										<textarea placeholder="" className="form-control wk-crpmodal-quick-note"
												  onChange={this.discussArticle}
										></textarea>
									</Col>
									<Col md="12">
										<div>
											<Button
												color="transparent"
												size="sm"
												className="mr-2 bg-transparent"
												title="Upload Media"
												onClick={() =>
													this.handleOnClickUploadMedia()
												}
											>
												<img
													src={require("../../../assets/images/icons/feed-cam.png")}
													alt=""
												/>
											</Button>
										</div>
										{/* Discussion Uploaded media shown here */}
										{
											this.state.uploadMedia.selectedMedia.length ?
												<div className="border mb-3">
													<Row xs={2} sm={3} md={4} lg={5} noGutters>
														{this.state.uploadMedia.selectedMedia.map((item, indexMedia) => (
															<Col>
																<div className="d-flex pr-3 m-2">
																	<div>
																		{(item.type === "image" ||
																			item.media_type === "image" ||
																			item.type === "video" ||
																			item.media_type === "video" || item.type === "audio" ||
																			item.media_type === "audio") && (
																			<div className="gallery-media">
																				{ item.media_type === 'image' ?
																					<img
																						className="img-fluid img-thumbnail"
																						src={item.url ? item.url : item.file}
																						alt={
																							item.filename
																								? item.filename.length < 20
																								? item.filename
																								: this.truncate(item.filename)
																								: item.name
																								? item.name.length < 20
																									? item.name
																									: this.truncate(item.name)
																								: ""
																						} /> :
																					<img
																						className="img-fluid img-thumbnail"
																						src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
																						alt={
																							item.filename
																								? item.filename.length < 20
																								? item.filename
																								: this.truncate(item.filename)
																								: item.name
																								? item.name.length < 20
																									? item.name
																									: this.truncate(item.name)
																								: ""
																						}
																					/>}
																			</div>
																		)}
																	</div>
																	<div className="mx-n3 mt-2">
																		<Button
																			color="delete-media"
																			size="sm"
																			title="Remove Media"
																			onClick={() =>
																				this.handleOnClickRemoveSelectedGalleryMedia(
																					item, indexMedia, false
																				)
																			}
																		>
																			<FontAwesomeIcon icon="trash-alt" />{" "}
																		</Button>
																	</div>
																</div>
															</Col>
														))}
													</Row>
												</div>
												: ''
										}
									</Col>

								</Row>
								<div className="text-right mt-3">
									<Button color="primary" size="sm" onClick={() => this.submitDiscussion()}>
										Submit
									</Button>
								</div>
							</div>
						</ModalBody>
					</form>
				</Modal>

				{/* Media Modal */}
				<Modal isOpen={this.state.uploadMediaPostModal} toggle={this.uploadMediaModalToggle}>
					<ModalHeader toggle={this.uploadMediaModalToggle}>
						UPLOAD MEDIA
					</ModalHeader>
					<ModalBody className="p-3">
						<Row>
							<Col xs={"auto"}>
								<div className="bg-light h-100 p-3">
									<Nav
										tabs
										className="flex-column active-transparent border-0 m-0"
									>
										<NavItem className="text-left">
											<NavLink
												className="pl-0 pr-4 border-0"
												href="#"
												active={this.state.selectedUpMediaType === "upload"}
												onClick={() => {
													this.handleOnClickSelectedUploadMediaType("upload");
												}}
											>
												Upload
											</NavLink>
										</NavItem>
										<NavItem>
											<hr className="my-3 bg-tertiary" />
										</NavItem>
										<NavItem className="text-left border-0">
											<NavLink
												className="pl-0 pr-4 border-0"
												href="#"
												active={this.state.selectedUpMediaType === "gallery"}
												onClick={() => {
													this.handleOnClickSelectedUploadMediaType("gallery");
												}}
											>
												Gallery
											</NavLink>
										</NavItem>
										<NavItem>
											<hr className="my-3 bg-tertiary" />
										</NavItem>
										<NavItem className="text-left border-0">
											<NavLink
												className="pl-0 pr-4 border-0"
												href="#"
												active={this.state.selectedUpMediaType === "embed"}
												onClick={() => {
													this.handleOnClickSelectedUploadMediaType("embed");
												}}
											>
												Embed
											</NavLink>
										</NavItem>
									</Nav>
								</div>
							</Col>
							<Col>
								<TabContent activeTab={this.state.selectedUpMediaType}>
									<TabPane tabId="upload">
										<div
											className="mb-3 type-file-block bg-light"
											hidden={
												this.state.uploadMedia.uploadFiles &&
												this.state.uploadMedia.uploadFiles.length > 0
													? true
													: false
											}
										>
											<Input
												type="file"
												name="upload_media_file"
												id="uploadFilesPostFromSystem"
												accept="image/*, video/*, audio/*"
												onChange={this.handleOnFilePostUploadChange}
												multiple
											/>
											<Label for="uploadFilesPostFromSystem">
												<div className="type-bg"></div>
											</Label>
										</div>
										{this.state.uploadMedia.uploadFiles.length > 0 && (
											<Row className="mb-3" form>
												<Col xs={12}>
													<div
														style={{
															maxWidth: "120px",
															margin: "0 1rem 1rem auto",
														}}
													>
														<div className="text-center mb-1 small">
															{this.state.uploadMedia.progress === 100 ? (
																<div className="text-success">
																	<FontAwesomeIcon
																		icon="check-circle"
																		className="mr-1"
																	/>{" "}
																	Uploaded
																</div>
															) : (
																<div>
																	Uploading{" "}
																	<span className="text-success font-weight-bold ff-base">
																			{this.state.uploadMedia.progress.toFixed(0)}
																		%</span>
																</div>
															)}
														</div>
														<Progress
															value={this.state.uploadMedia.progress}
															style={{ height: "8px" }}
														></Progress>
													</div>
												</Col>
												{this.state.uploadMedia.uploadFiles.map(
													(file, index) => {
														return (
															<Col xs="auto">
																<div className="bg-light p-2">
																	<div className="d-flex pr-3">
																		<div>
																			<div
																				className="selectable-media 123"
																				style={{ cursor: "default" }}
																			>
																				<div className="gallery-media">
																					{file.media_type === 'image' ?
																						<img src={file.url} alt="" /> :
																						<img src={file.thumbnail && file.thumbnail !== null ? file.thumbnail : file.url} alt="" />
																					}
																				</div>
																			</div>
																		</div>
																		<div className="mx-n3 mt-2">
																			<Button
																				color="delete-media _alt"
																				size="sm"
																				title="Remove Media"
																				hidden={file.id === "" ? true : false}
																				onClick={this.handleOnClickRemoveSelectedMedia(
																					file.id
																				)}
																			>
																				<FontAwesomeIcon icon="trash-alt" />{" "}
																			</Button>
																		</div>
																	</div>
																	<div className="mt-2">
																		<Input
																			bsSize="sm"
																			type="text"
																			className="mw-100 caption-input"
																			name="caption"
																			placeholder="Caption"
																			onBlur={(e) =>
																				this.handleCaptionByIndex(e, index)
																			}
																			style={{ maxWidth: '160px' }}
																		/>
																	</div>
																</div>
															</Col>
														);
													}
												)}
												<Col xs="auto">
													<div className="d-flex pt-3">
														<div className="selectable-media" hidden>
															<Label
																for="uploadFilesFromSystemMini"
																className="gallery-media"
																style={{
																	borderStyle: "dashed",
																	cursor: "pointer",
																}}
															>
																<div className="d-flex h-100 align-items-center justify-content-center">
																	<span className="fs-14">Upload More</span>
																</div>
															</Label>
															<Input
																type="file"
																name="upload_media_file"
																id="uploadFilesFromSystemMini"
																accept="image/*, video/*"
																onChange={this.handleOnFilePostUploadChange}
																multiple
																style={{ display: "none" }}
															/>
														</div>
													</div>
												</Col>
											</Row>
										)}
										<div className="d-flex mx-n2">
											<div className="px-2">
												<Button
													color="primary"
													size="sm"
													className="mw"
													onClick={this.uploadMediaModalToggle}
												>
													Cancel
												</Button>
											</div>
											<div className="px-2 ml-auto">
												<Button
													color="primary"
													size="sm"
													className="mw"
													onClick={() => {
														this.setState({
															...this.state,
															uploadMediaPostModal: false,
														});
													}}
												>
													{"OK"}
												</Button>
											</div>
										</div>
									</TabPane>
									<TabPane tabId="gallery">
										<div className="mb-2">
											<Nav tabs className="d-inline-flex mb-0">
												<NavItem>
													<NavLink
														href="#"
														active={this.state.showGalleryType === "images"}
														onClick={() => {
															this.handleOnClickGalleryType("images");
														}}
													>
														Image Gallery
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														href="#"
														active={this.state.showGalleryType === "videos"}
														onClick={() => {
															this.handleOnClickGalleryType("videos");
														}}
													>
														Video Gallery
													</NavLink>
												</NavItem>
											</Nav>
										</div>
										<TabContent activeTab={this.state.showGalleryType}>
											<TabPane tabId="images">
												<div>
													<FormGroup className="form-row justify-content-end">
														<Col xs="auto">
															<Label className="small" for="selectAlbumType">
																Album Type:
															</Label>
														</Col>
														<Col xs="auto">
															<Input
																bsSize="sm"
																type="select"
																name="album_type_select"
																id="selectAlbumType"
																defaultValue={this.state.uploadMedia.albumType}
																onChange={this.handleOnClickAlbumTypeChange}
															>
																<option value="">All</option>
																{this.state.uploadMedia.albumTypesList.length >
																0 &&
																this.state.uploadMedia.albumTypesList.map(
																	(type) => {

																		if (type.label) {
																			return (
																				<option value={type.category}>
																					{type.category}
																				</option>

																			)
																		}
																	}
																)}
															</Input>
														</Col>
													</FormGroup>
												</div>
												<div
													className="px-3 my-3"
													style={{ maxHeight: "350px", overflow: "auto" }}
													ref={this.refGalleryImageScroll}
													onScroll={this.handleScrollGalleryImage}
												>
													{this.state.uploadMedia.albumTypeData &&
													this.state.uploadMedia.albumTypeData.count > 0 ? (
														<Row>{this.fetchScrollPhotoMedia()}</Row>
													) : (
														<div className="bg-white p-3">
															<h2 className="text-secondary-dark">No images</h2>
														</div>
													)}
												</div>
												<div className="d-flex mx-n2">
													<div className="px-2">
														<Button
															color="primary"
															size="sm"
															className="mw"
															onClick={this.uploadMediaModalToggle}
														>
															Cancel
														</Button>
													</div>
													<div className="px-2 ml-auto">
														<Button
															color="primary"
															size="sm"
															className="mw"
															onClick={() => {
																this.setState({
																	...this.state,
																	uploadMediaPostModal: false,
																});
															}}
														>
															{"OK"}
														</Button>
													</div>
												</div>
											</TabPane>
											<TabPane tabId="videos">
												<div
													className="px-3 my-3"
													style={{ maxHeight: "350px", overflow: "auto" }}
													ref={this.refGalleryVideoScroll}
													onScroll={this.handleScrollGalleryVideo}
												>
													{this.state.uploadMedia.albumTypeData &&
													this.state.uploadMedia.albumTypeData.count > 0 ? (
														<Row>{this.fetchScrollPhotoMedia(true)}</Row>
													) : (
														<div className="bg-white p-3">
															<h2 className="text-secondary-dark">No videos</h2>
														</div>
													)}
												</div>
												<div className="d-flex mx-n2">
													<div className="px-2">
														<Button
															color="primary"
															size="sm"
															className="mw"
															onClick={this.uploadMediaModalToggle}
														>
															Cancel
														</Button>
													</div>
													<div className="px-2 ml-auto">
														<Button
															color="primary"
															size="sm"
															className="mw"
															onClick={() => {
																this.setState({
																	...this.state,
																	uploadMediaPostModal: false,
																});
															}}
														>
															{"OK"}
														</Button>
													</div>
												</div>
											</TabPane>
										</TabContent>
									</TabPane>
									<TabPane tabId="embed">
										<div className="text-muted mb-2">
											Submit the link for the image or video you want to embed{" "}
										</div>
										<div className="mb-2">
											{/* Repeat this while adding */}
											{this.mapEmbedLinks()}
										</div>
										{/*<div className="d-flex mx-n2 mb-2">*/}
										{/*	<div className="px-2 ml-auto">*/}
										{/*		<Button*/}
										{/*			title="Add"*/}
										{/*			color="primary"*/}
										{/*			size="sm"*/}
										{/*			onClick={() => {*/}
										{/*				this.addEmbedlinkRow();*/}
										{/*			}}*/}
										{/*		>*/}
										{/*			<FontAwesomeIcon icon="plus" />*/}
										{/*		</Button>*/}
										{/*	</div>*/}
										{/*</div>*/}
										<div className="d-flex mx-n2">
											<div className="px-2">
												<Button
													color="primary"
													size="sm"
													className="mw"
													onClick={this.uploadMediaModalToggle}
												>
													Cancel
												</Button>
											</div>
											<div className="px-2 ml-auto">
												<Button
													color="primary"
													size="sm"
													className="mw"
													onClick={() => {
														this.insertEmbedLinks();
													}}
												>
													Insert
												</Button>
											</div>
										</div>
									</TabPane>
								</TabContent>
							</Col>
						</Row>
					</ModalBody>
				</Modal>
			</div>


		)
	}
}


const mapState = (state) => ({
	get_buyersGuides: state.buyersGuide.get_buyersGuides,
	upload_scroll: state.myProfile.upload_scroll,
	upload_media_data: state.myProfile.upload_media_data,
	album_types_list: state.user.album_types_list,
	album_type_data: state.user.album_type_data,
	fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
	fetch_taxonomy: state.myProfile.fetch_taxonomy,
});

const mapProps = (dispatch) => ({
	add_buyers_guide: (id, type, taxonomy_id, data, message) => dispatch(add_buyers_guide(id, type, taxonomy_id, data, message)),
	clear_media_dispatch: () => dispatch(clear_media_dispatch()),
	upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
	get_album_types_list: () => dispatch(get_album_types_list()),
	get_album_type_data: (type, album, page) => dispatch(get_album_type_data(type, album, page)),
	delete_selected_gallery_media: (dataID) => dispatch(delete_selected_gallery_media(dataID)),
	fetch_taxonomy_list: (category, type) => dispatch(fetch_taxonomy_list(category, type)),
	fetch_taxonomy_category: (type) => dispatch(fetch_taxonomy_category(type)),
	submit_discuss_article: (data, message, taxonomy_id) => dispatch(submit_discuss_article(data, message, taxonomy_id)),
	create_discussion: (data, url) => dispatch(create_discussion(data, url)),
	add_my_post: (data, type, page, module_type) => dispatch(add_my_post(data, type, page, module_type)),
	submit_article_order: (taxonomy_id, data) => dispatch(submit_article_order(taxonomy_id, data)),
});

export default connect(mapState, mapProps)(AddArticle);
