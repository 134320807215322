// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
// import { useEmbedly } from "react-contentful-embedly";
import { connect } from "react-redux";
import {
  Button, Col, FormGroup,
  Input, Modal,
  ModalBody, Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table
} from "reactstrap";
import { get_jury_result, jury_modal_status } from "../../../actions/dispute";
import Moment from "react-moment";


// const ComponentNew = (props) => {
//   const option = useEmbedly({
//     align: "left",
//     key: "86995e5d79b242c5b34610989d303d90",
//   });
//   const documentIncludesEmbedly = {
//     nodeType: "document",
//     data: {},
//     content: [
//       {
//         nodeType: "paragraph",
//         data: {},
//         content: [
//           {
//             nodeType: "text",
//             value: `Embedded content: ${props.embedData}`,
//             marks: [],
//             data: {},
//           },
//         ],
//       },
//     ],
//   };

//   let result = documentToReactComponents(documentIncludesEmbedly, option);

//   if (result && Array.isArray(result) && result.length) {
//     let mainKey = result[0];
//     if (Object.keys(mainKey).length > 1) {
//       return <div>{result}</div>;
//     } else {
//       return null;
//     }
//   }
// };

class JuryResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      juryResult: [],
      percentage: {},
      gender_results: {},
      geographic_results: {},
      age_results: {},
      history_results: {},
      decision: ''

    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.jury_result &&
      Object.keys(nextProps.jury_result).length > 0) {
      this.setState({
        juryResult: nextProps.jury_result.result,
        percentage: nextProps.jury_result.result?.jury_result?.percentage,
        geographic_results: nextProps.jury_result.result?.jury_result?.geographic_results,
        age_results: nextProps.jury_result.result?.jury_result?.age_results,
        gender_results: nextProps.jury_result.result?.jury_result?.gender_results,
        history_results: nextProps.jury_result.result?.jury_result?.history_results,
        decision: nextProps.jury_result.result?.jury_result?.decision,
      })
    }
  }

  componentDidMount() {
    this.getDetails(this.props.review)
  }

  getDetails = (review) => {
    this.setState({ voteReviewModalToggleFinal: true })
    this.props.get_jury_result(review)
  }

  componentWillUnmount() {
    // document.removeEventListener("mousedown", this.handleClickOutside);
  }

  myRef = React.createRef();

  handleReviewsStorage() {
  }


  //end the post
  render() {
    let {
      juryResult,
      percentage,
      gender_results,
      geographic_results,
      age_results,
      history_results,
      decision
    } = this.state;

    // const responsive = {
    //   videoModal: {
    //     mobile: {
    //       breakpoint: { max: 9999999, min: 0 },
    //       items: 1,
    //     },
    //   },
    //   viewGalleryImages: {
    //     desktop: {
    //       breakpoint: { max: 9999999, min: 1200 },
    //       items: 3,
    //     },
    //     tablet: {
    //       breakpoint: { max: 1199, min: 576 },
    //       items: 2,
    //     },
    //     mobile: {
    //       breakpoint: { max: 575, min: 0 },
    //       items: 1,
    //     },
    //   },
    // };

    return (
      <div>


        {/* Jury Final Decision Modal */}
        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={this.state.voteReviewModalToggleFinal}
          toggle={() =>
            this.setState({
              voteReviewModalToggleFinal: !this.state
                .voteReviewModalToggleFinal,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.setState({
                        voteReviewModalToggleFinal: !this.state.voteReviewModalToggleFinal,
                      })
                      this.props.jury_modal_status({ modal: false })
                    }
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
                <div className="bg-dark text-white fs-14 px-3">
                  <div className="mb-2 pt-2">
                    <div className="d-flex mx-n2">
                      <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            className="text-white bg-transparent shadow-none font-weight-bold"
                            color="transparent"
                            size="sm"
                            caret
                          >
                            <span className="pr-2">Disputed Reviews</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="fs-14">
                              Disputed Reviews
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="px-2 col-auto">
                        near{" "}
                        <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled d-flex flex-wrap mx-n2">
                    <li className="px-2">
                      <div className="d-flex">
                        <FormGroup>
                          <Input
                            type="select"
                            className="transparent"
                            size="sm"
                          >
                            <option>Businesses</option>
                          </Input>
                        </FormGroup>
                        <div className="ml-2">
                          <span className="text-danger">*</span>
                        </div>
                      </div>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>IT Services</option>
                          <option>Apartment Rentals</option>
                          <option>Automotive</option>
                          <option>Education</option>
                          <option>Beauty and Spas</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Asian</option>
                          <option>European</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Indian</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Level 6</option>
                        </Input>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                      style={{ minWidth: "300px" }}
                    >
                      <div>
                        <img
                          src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                        <img
                          src={require("../../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-column justify-content-center bg-primary text-dark h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            <h3 className="text-white">
                              The Community has voted.
                            </h3>
                            <div className="fs-14">
                              <div className="fs-16 font-weight-bold">
                                Their decision is to {decision[0]}.
                              </div>
                              {/* <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p> */}
                            </div>
                          </div>
                        </Col>
                        <Col md="auto" className="align-self-end">
                          <div className="text-light font-weight-bold text-muted">
                            Voting ended
                          </div>
                          <div className="d-flex mx-n2 flex-nowrap">
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings">0</div>
                                <div className="font-weight-bold">days</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  00
                                </div>
                                <div className="font-weight-normal">hours</div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  00
                                </div>
                                <div className="font-weight-normal">
                                  minutes
                                </div>
                              </div>
                            </div>
                            <div className="px-2">
                              <div className="text-muted">
                                <div className="fs-32 ff-headings text-right">
                                  00
                                </div>
                                <div className="font-weight-normal">
                                  seconds
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16 text-primary mb-2">
                      Voting History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      {/* <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Sau6402
                            </a>
                            &nbsp; added a Punctuation in Tips.
                          </div>
                        </div>
                      </li>
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">22/04/2014</div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Danielbauwens
                            </a>
                            &nbsp; reverted edits by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Fart biscuit
                            </a>
                            &nbsp; changed back to last version by{" "}
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              Anna
                            </a>
                          </div>
                        </div>
                      </li>
                       */}
                      {history_results && Object.entries(history_results)?.map(([key, value]) => {
                        return (<li className="py-2 border-bottom">
                          <div className="d-flex mx-n1">
                            {/* <div className="px-1">
                             <div className="ff-alt">
                              <Moment format="DD/MM/YYYY">
                                {history_results[key][0]}
                              </Moment>
                             </div>
                           </div>                            */}
                            <div className="px-1">
                              &nbsp; Anonymous User &nbsp;
                             <a
                                href="#"
                                className="text-dark ff-base font-weight-bold"
                              >
                                {history_results[key][1]}
                              </a>
                              {/* &nbsp; reverted edits by{" "}
                             <a
                               href="#"
                               className="text-dark ff-base font-weight-bold"
                             >
                               Fart biscuit
                             </a> */}
                              {/* &nbsp; changed back to last version by{" "}
                             <a
                               href="#"
                               className="text-dark ff-base font-weight-bold"
                             >
                               Anna
                             </a> */}
                              {history_results[key][2] ?
                                <span> &nbsp; wrote
                                &nbsp;{history_results[key][2]} {" "}
                                </span>
                                :
                                ''
                              }
                            </div>
                          </div>
                        </li>
                        )
                      }

                      )
                      }
                    </ul>
                  </Col>
                  <Col md={8}>
                    <div>
                      <div className="font-weight-bold fs-16 text-primary mb-3">
                        The Decision
                      </div>

                      <div className="bg-light p-2 mb-3 border">
                        <div className="font-weight-bold">
                          Their Decision is to {decision[0]}.
                        </div>
                        <div className="text-tertiary">
                          {decision[1]}
                        </div>
                      </div>

                      <div className="bg-light p-3 mt-5">
                        <div className="font-weight-bold fs-16 text-primary mb-3">
                          The Dispute Discussion
                        </div>
                        <div
                          className="overflow-y-auto"
                          style={{ maxHeight: "300px" }}
                        >
                          {juryResult?.flag_details?.map((flag, index) => {
                            return (
                              <div>
                                <div className="d-flex">
                                  <div className="px-2">
                                    <img
                                      className="img-circle _50x50"
                                      src={`https://stagingdatawikireviews.s3.amazonaws.com/media/content/${flag?.profile_pic}`}
                                      alt=""
                                    />
                                  </div>
                                  <div className="px-2">
                                    <div className="mb-1">
                                      <a href="#" className="text-tertiary">
                                        <strong>{flag?.user_name}</strong>
                                      </a>
                                      &nbsp;
                                      <span className="fs-12 text-muted">
                                        {/* one hour ago via iOS7 */}
                                        <Moment format="DD MMM, YYYY">
                                          {flag?.created_on}
                                        </Moment>
                                      </span>
                                    </div>
                                    <div>
                                      {flag?.content_flagged}
                                    </div>

                                    <div className="mb-2">
                                      {flag?.media === "No media found" ?
                                        ''
                                        :
                                        flag?.media != "No media found" &&
                                        flag.media?.map((media) => (
                                          <div>
                                            {media.includes(".jpeg") ||
                                              media.includes(".jpg") ||
                                              media.includes(".png") ? (
                                              <div className="mb-2">
                                                <img
                                                  className="img-fluid"
                                                  src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                  alt=""
                                                />
                                              </div>
                                            ) : (
                                              <div className="mb-2 d-inline-block">
                                                <div className="d-flex mx-n2">
                                                  <div className="px-2">
                                                    <FontAwesomeIcon
                                                      icon="paperclip"
                                                      fixedWidth
                                                    />
                                                  </div>
                                                  <div className="px-2 col">
                                                    <span className="font-weight-bold text-truncate">
                                                      <a
                                                        className="text-reset font-weight-bold"
                                                        href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                      >
                                                        {
                                                          media
                                                            .toString()
                                                            .split("/")[2]
                                                        }
                                                      </a>
                                                    </span>
                                                  </div>
                                                  <div className="px-2 ml-auto">
                                                    <span className="mr-2">
                                                      |
                                                    </span>
                                                    <a
                                                      href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="text-reset"
                                                    >
                                                      <FontAwesomeIcon icon="external-link-alt" />
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        ))}

                                      <hr />
                                      {flag?.embed_links?.split(',')?.length > 0 ?
                                        <div className="fs-14 text-dark font-weight-bold">Links</div>
                                        :
                                        ''
                                      }

                                      {flag?.embed_links?.split(',')?.map((link) =>
                                        <div>
                                          {link}
                                        </div>
                                      )
                                      }



                                    </div>



                                  </div>
                                </div>
                                <hr />
                              </div>
                            )
                          })
                          }


                          {/* <div>
                            <div className="d-flex">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-1">
                                  <a href="#" className="text-tertiary">
                                    <strong>UserName</strong>
                                  </a>
                                  &nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div>
                            <div className="d-flex">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-1">
                                  <a href="#" className="text-tertiary">
                                    <strong>UserName</strong>
                                  </a>
                                  &nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                          <div>
                            <div className="d-flex">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-1">
                                  <a href="#" className="text-tertiary">
                                    <strong>UserName</strong>
                                  </a>
                                  &nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                         */}
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="font-weight-bold fs-16 text-primary mb-4">
                        Demographic &amp; Geographic Breakdown of Votes
                        <div className="text-tertiary font-weight-normal fs-14">
                          This is a text explaining what the information below
                          is all about.
                        </div>
                      </div>

                      <Table
                        bordered
                        size="sm"
                        className="text-center table-chart _cols-4"
                      >
                        <thead className="text-tertiary">
                          <tr>
                            <th>&nbsp;</th>
                            <th>Voted to Delete</th>
                            <th>Voted to Keep</th>
                            <th>Voted to Keep, as Questionable</th>
                          </tr>
                        </thead>
                        <tbody className="ff-alt text-muted">
                          <tr>
                            <th className="bg-light" scope="row">
                              Results
                            </th>
                            <td className="bg-light">{percentage?.Voted_to_Delete} %</td>
                            <td className="bg-light">{percentage?.Voted_to_Keep} %</td>
                            <td className="bg-light">{percentage?.Voted_to_Keep_as_Questionable} %</td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="mt-5">
                        <div className="text-dark fs-16 font-weight-bold mb-2">
                          Age Group
                        </div>
                        <Table
                          bordered
                          size="sm"
                          className="text-center table-chart _cols-4"
                        >
                          <thead className="text-tertiary">
                            <tr>
                              <th>Age Group</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Keep</th>
                              <th>Voted to Keep, as Questionable</th>
                            </tr>
                          </thead>
                          <tbody className="ff-alt text-muted">
                            <tr>
                              <th className="bg-light" scope="row">
                                18-24
                              </th>
                              {age_results && age_results['18-24']?.map(result => {
                                return (<td className="bg-light">{result}</td>)
                              })}
                              {/* <td className="bg-light">2</td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td> */}
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                25-34
                              </th>
                              {age_results && age_results['25-34']?.map(result => {
                                return (<td className="bg-light">{result}</td>)
                              })}
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                35-44
                              </th>
                              {age_results && age_results['35-44']?.map(result => {
                                return (<td className="bg-light">{result}</td>)
                              })}
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                45-54
                              </th>
                              {age_results && age_results['45-54']?.map(result => {
                                return (<td className="bg-light">{result}</td>)
                              })}
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                55-64
                              </th>
                              {age_results && age_results['55-64']?.map(result => {
                                return (<td className="bg-light">{result}</td>)
                              })}
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                65+
                              </th>
                              {age_results && age_results['65+']?.map(result => {
                                return (<td className="bg-light">{result}</td>)
                              })}
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      <div className="mt-5">
                        <div className="text-dark fs-16 font-weight-bold mb-2">
                          Gender
                        </div>
                        <Table
                          bordered
                          size="sm"
                          className="text-center table-chart _cols-4"
                        >
                          <thead className="text-tertiary">
                            <tr>
                              <th>Gender</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Keep</th>
                              <th>Voted to Keep, as Questionable</th>
                            </tr>
                          </thead>
                          <tbody className="ff-alt text-muted">
                            <tr>
                              <th className="bg-light" scope="row">
                                Male
                              </th>
                              {gender_results && gender_results['male']?.map(result => {
                                return (<td className="bg-light">{result}</td>)
                              })
                              }
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Female
                              </th>
                              {gender_results && gender_results['female']?.map(result => {
                                return (<td className="bg-light">{result}</td>)
                              })
                              }
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      <div className="mt-5">
                        <div className="text-dark fs-16 font-weight-bold mb-2">
                          Geography
                        </div>
                        <Table
                          bordered
                          size="sm"
                          className="text-center table-chart _cols-4"
                        >
                          <thead className="text-tertiary">
                            <tr>
                              <th>Geography</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Keep</th>
                              <th>Voted to Keep, as Questionable</th>
                            </tr>
                          </thead>
                          <tbody className="ff-alt text-muted">
                            {geographic_results && Object.entries(geographic_results)?.map(([key, value]) => {
                              return (<tr>
                                <th className="bg-light" scope="row">
                                  {key}
                                </th>
                                {value.map(val => {
                                  return (
                                    <td className="bg-light">
                                      {val === 1 ?
                                        <FontAwesomeIcon icon="check" />
                                        :
                                        ''
                                      }
                                    </td>
                                  )
                                })}

                              </tr>
                              )
                            })
                            }
                            {/* <tr>
                              <th className="bg-light" scope="row">
                                Brooklyn, NY
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Perris, CA
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Mequon, WI
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                            </tr>
                            <tr> 
                              <th className="bg-light" scope="row">
                                Chicago, IL
                              </th>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>*/}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </form>
        </Modal>




      </div>
    );
  }
}
const mapState = (state) => ({
  jury_result: state.dispute.jury_result,
});

const mapDispatch = (dispatch) => ({
  get_jury_result: (data) => dispatch(get_jury_result(data)),
  jury_modal_status: (params) => dispatch(jury_modal_status(params)),
});

export default connect(mapState, mapDispatch)(JuryResult);
