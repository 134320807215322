
const initialState = {
    actors_list: [],
    get_review_listing: null,
    isLoading: false
};


const actorReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case "GET_ACTORS_LIST":
            state.actors_list = action.payload;
            return { ...state };

        case "SEARCH_ACTOR":
            state.actors_list = action.payload;
            return { ...state };

        case "ADD_ACTOR_AS_FAV":
            return { ...state };

        case "FETCH_ACTORS_LIST":
            state.actors_list = action.payload;
            return { ...state };

        case "GET_REVIEW_LISTING_ACTORS":
            state.get_review_listing = action.payload;
            return { ...state };
        
        case "RESET_REVIEW_LISTING_ACTORS":
            state.get_review_listing = action.payload;
            return { ...state };

        case "SET_ACTOR_LOADER":
            state.isLoading = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default actorReducer;
