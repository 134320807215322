import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import moment from "moment";

import {
  upload_media,
  initFetchMovieSubCategories,
  search_moviesMembers,
  get_improve_listing,
  submit_improve_tech_media
} from "../../../actions/footer";

import {
  add_movie
} from "../../../actions/movies";
import { get_type_detail } from '../../../actions/itemDetail';

import { callApi } from "../../../utils/apiCaller";
const validate = Yup.object().shape({
  additional_sets: Yup.array()
    .of(
      Yup.object().shape({
        info: Yup.string().min(4, "too short").required("Required"), // these constraints take precedence
        name: Yup.number().required("Please select"),
      })
    )
    .required("Must have additional info")
    .min(3, "Minimum of 3 additional"),
});

class ImproveMovieTechincalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      loaded: true,
      drawer: true,
      improveTechnical_list: [],
      name: '',
      category_id: '',
      subcategory_id: '',
      typeMethod: "",
      rateType: "",
      type: "technicaldetails",
      listingType: "movies",
      about: null,
      addTechincalForm: {
        additional_sets: [
          {
            info: "",
            name: "",
            entries: ""
          },
        ],
      },
      additional_sets: [
        {
          info: "",
          name: "",
          entries: ""
        },
      ],
      additionalError: ""
    };
  }
  redirectto = (movieId, slug) => {
    let path = "movies";
    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: movieId }
    })
  }
  componentWillReceiveProps(nextProps) {
    let { loaded } = this.state;
    if (nextProps.fetch_save_movie_response) {
      if (nextProps.fetch_save_movie_response && nextProps.fetch_save_movie_response.id && nextProps.fetch_save_movie_response.slug) {
        let slug = null;
        let movieId = "";
        if (nextProps.fetch_save_movie_response.id != "") {
          movieId = nextProps.fetch_save_movie_response.id;
        }
        if (nextProps.fetch_save_movie_response.slug != "") {
          slug = nextProps.fetch_save_movie_response.slug;
        }
        this.redirectto(movieId, slug);

      }
    }

    if (nextProps.ratingType && nextProps.ratingType != "") {
      this.setState({ rateType: nextProps.ratingType })
    }
    if (nextProps.getImproveTechnical && nextProps.getImproveTechnical.results && Array.isArray(nextProps.getImproveTechnical.results) && nextProps.getImproveTechnical.results.length > 0) {
      this.setState({ improveTechnical_list: nextProps.getImproveTechnical.results }, () => {
        if (nextProps.getImproveTechnical.results && nextProps.getImproveTechnical.results[0] && loaded == true) {
          this.getMovieDetail(nextProps.getImproveTechnical.results[0])
        }

      });
    }
    if (nextProps?.movie_details) {
      this.setState({
        about: nextProps?.movie_details,
      });
    }

  }

  componentDidMount = () => {
    let { type, listingType, improveTechnical_list } = this.state;
    let id = ""
    //this.props.get_patrol_crp(id,type,listingType);
  }
  getMovieDetail = async (data) => {
    this.setState({ loaded: false, about: "" }, () => {
      this.props.get_type_detail({ type: 'movies', id: data.id });
    })
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let { rateType } = this.state;
    this.setState({
      [name]: value
    });
    if (name == "category_id") {
      let type = "category_id";
      this.setState({
        categoryError: "",
        category_id: e.target.value,
        improveTechnical_list: [],
        about: "",
        loaded: true
      }, () => {
        let id = this.state.category_id;
        let typeCore = "listing";
        let listingType = "movies";
        this.props.get_improve_listing(id, typeCore, listingType);
      });
      this.props.initFetchMovieSubCategories(e.target.value, type);
    }
  }

  handleChangeCast = (e, index) => {
    const { additional_sets, improveTechnical_list } = this.state
    const { name, value } = e.target

    if (name.includes('additional') && additional_sets.length <= index) {
      additional_sets.push({ name: "", info: "", entries: "" })
      this.setState({ additional_sets })

    }
    if (name === 'info') {
      additional_sets[index].info = value;
      additional_sets[index].entries = improveTechnical_list && improveTechnical_list[0] ? improveTechnical_list[0].id : "";
      this.setState({ additional_sets, indexValue: index, typeMethod: "additional", additionalError: "" })
    }
    else if (name === 'name') {
      additional_sets[index].name = value
      this.setState({ additional_sets })
    }
  }

  handleremoveCast = (option) => {
    const { additional_sets } = this.state
    if (option === 'additional_sets') {
      additional_sets.pop()
      this.setState({ additional_sets })
    }
  }

  handleSkip = () => {
    let { improveTechnical_list } = this.state;
    if (improveTechnical_list && Array.isArray(improveTechnical_list) && improveTechnical_list.length > 0) {
      improveTechnical_list.shift();
      this.setState({ improveTechnical_list, about: "" }, () => {
        this.getMovieDetail(improveTechnical_list[0])
      });

    }

  }

  handleMovieTechnicalSubmit = () => {
    let { name, additional_sets, improveTechnical_list, type } = this.state
    if (additional_sets[0].info == "" && additional_sets[0].name == "") {
      this.setState({
        additionalError:
          "This field is required"
      });
    } else {
      let params = {}
      params = {
        type: "technicaldetails",
        attribute_set: this.state.additional_sets,
        entries: improveTechnical_list && improveTechnical_list[0] ? improveTechnical_list[0].id : "",
        associated_with: improveTechnical_list && improveTechnical_list[0] ? improveTechnical_list[0].id : "",
        phone_set: [],
        website_set: [],
        professional_set: []

      }
      let id = ""
      this.props.submit_improve_tech_media(id, type, params);
      this.setState({
        addTechincalForm: {
          additional_sets: [
            {
              info: "",
              name: "",
              entries: ""
            },
          ],
        },
        additional_sets: [
          {
            info: "",
            name: "",
            entries: ""
          },
        ]
      }, () => {
        this.handleSkip();
      })
    }

  }

  render() {
    const {
      openModal,
      toggleImproveMovieTechincalModal,
      movies,
    } = this.props;
    const { category_id, addTechincalForm, additional_sets, improveTechnical_list, name, movieDetail, about } = this.state;
    let avg_rating = about && about.total_rating && about.total_rating.avg_rating[0] ? about.total_rating.avg_rating[0] : 'norating.png';
    return (
      <>
        <Formik
          touched
          errors
          initialValues={addTechincalForm}
          validationSchema={validate}
          onSubmit={(values) => {
            this.props.onSubmit({
              ...values,
              taxonomy: [values.taxonomy],
            });
          }}
          render={(props) => {
            const {
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            } = props;
            return (
              <Modal
                size="xl"
                scrollable
                className="drawer-modal"
                isOpen={openModal}
                toggle={toggleImproveMovieTechincalModal}
              >
                <form
                  className="d-flex flex-column h-100"
                >
                  <div className="modal-header p-0">
                    <div className="w-100">
                      <div>
                        <Button color="primary" onClick={toggleImproveMovieTechincalModal}>
                          <FontAwesomeIcon icon="angle-left" />
                        back to listing
                      </Button>
                      </div>
                      <div>
                        <div className="bg-darker p-3 text-tertiary">
                          <div className="mb-2 text-primary font-weight-bold">
                            You’re Improving Technical Details
                        </div>
                          <ul className="list-unstyled d-flex flex-wrap mx-n2">
                            <li className="px-2">
                              <div className="d-flex">
                                <FormGroup>
                                  <Input
                                    type="select"
                                    className="transparent"
                                    size="sm"
                                  >
                                    <option>{movies?.category}</option>
                                  </Input>
                                </FormGroup>
                              </div>
                            </li>
                            <li className="px-2">
                              <div className="d-flex">
                                <div className="mr-1">
                                  <span className="text-danger">*</span>
                                </div>
                                <FormGroup>
                                  <Input
                                    id="category_id"
                                    name="category_id"
                                    onChange={this.handleChange}
                                    type="select"
                                    className="transparent"
                                    size="sm"
                                    selected={category_id}
                                  >
                                    <option value="">Select Category</option>
                                    {movies?.children?.map((movie) => {
                                      return (
                                        <option value={movie.id} key={movie.id}>
                                          {movie.category}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                  <span style={{ color: 'red', fontSize: "13px" }}>{this.state.categoryError}</span>
                                </FormGroup>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ModalBody className="bg-white text-dark">
                    <div className="wk-crpmodal">
                      <Row>
                        <Col md="6">
                          <div className=" font-weight-bold mb-2 text-primary mb-3">Step 1. Look it Up</div>
                          <p className="text-dark font-weight-bold">The Brief.</p>
                          <div className="wk-core-petrol-listingleft d-flex flex-wrap">
                            <div className="wk-core-petrol-listingimage">
                              {about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].url ?
                                <img
                                  src={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].url}`}
                                  className="img-fluid"
                                  onError={(error) =>
                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                  }
                                  alt={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about.listing_profileimage[0].caption ? about.listing_profileimage[0].caption : 'no-image'}`}
                                />
                                :
                                <img alt="" src="https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png" className="img-fluid" />

                              }

                            </div>
                            <div className="wk-core-petrol-listingcontent">
                              <h4 className="fs-18">{about?.name}
							 &nbsp;<span className="fs-14">{about && Array.isArray(about.taxonomy_list) && about.taxonomy_list.length > 0 ?
                                  about.taxonomy_list.map((taxonomy) => {
                                    return <>{taxonomy} </>
                                  })
                                  : ""
                                }</span></h4>
                              {/*<img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png" alt="star"/>
								 <p className="mt-2 mb-0">0 reviews</p>*/}
                              <p className="fs-14">
                                <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                                  className="img-fluid"
                                  onError={(error) =>
                                    (error.target.src = `../../../assets/images/norating.png`)
                                  }
                                  alt={`${about && about.total_rating && about.total_rating.avg_rating[1] ? about.total_rating.avg_rating[1] : 'no-rating'}`}

                                />{" "}{(about && about.total_rating && about.total_rating.total_review) ? about.total_rating.total_review : 0} reviews</p>
                              <p className="mt-2 mb-0">Category: <span className="font-weight-bold text-dark">
                                {about && Array.isArray(about.taxonomy_list) && about.taxonomy_list.length > 0 ?
                                  about.taxonomy_list.map((taxonomy) => {
                                    return <>{taxonomy} </>
                                  })
                                  : ""
                                }</span></p>
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className=" font-weight-bold mb-2 text-primary">Step 2</div>
                          <FieldArray
                            name="additional_sets"
                            render={(arrayHelpers) => (
                              <div>
                                {values.additional_sets.map(
                                  (additional, index) => (
                                    <div key={index}>
                                      <FormGroup>
                                        <Input
                                          id="color"
                                          name="name"
                                          onChange={(e) => this.handleChangeCast(e, index)}
                                          type="select"
                                          className="transparent"
                                        >
                                          <option value="" selected="selected">Please Select</option>
                                          <option value="33">Color Mix</option>
                                          <option value="34">Aspect Ration</option>
                                          <option value="35">Camera</option>
                                          <option value="36">Laboratory</option>
                                          <option value="37">Negative Format</option>
                                          <option value="38">Cinematographic Process</option>
                                          <option value="39">Printed Film Format</option>
                                          <option value="64">Release Date</option>
                                        </Input>
                                      </FormGroup>
                                      <FormGroup>
                                        <label>Info</label>
                                        <Input
                                          className="primary"
                                          type="text"
                                          name="info"
                                          autocomplete="off"
                                          onChange={(e) => this.handleChangeCast(e, index)}
                                          value={additional_sets[index].additional_name}
                                          placeholder="Enter additional information"
                                        />
                                 
                                        <span style={{ color: 'red', fontSize: "13px" }}>{this.state.additionalError}</span>
                                        <Button hidden={index != 0 ? false : true} color="link" className="mt-2 d-inline-block fs-14" onClick={() => {
                                          arrayHelpers.remove(index);
                                          this.handleremoveCast('additional_sets')
                                        }
                                        }>Remove</Button>
                                      </FormGroup>
                                    </div>
                                  )
                                )}
                                <div className="d-flex justify-content-end">
                                  <Button color="link" className="d-inline-block fs-14" onClick={() => {
                                    arrayHelpers.push({
                                      name: "",
                                      info: "",
                                      entries: improveTechnical_list && improveTechnical_list[0] ? improveTechnical_list[0].id : ""
                                    });
                                    additional_sets.push({
                                      name: "",
                                      info: "",
                                      entries: improveTechnical_list && improveTechnical_list[0] ? improveTechnical_list[0].id : ""
                                    })
                                  }
                                  }>++Add More</Button>
                                </div>
                              </div>
                            )}
                          />

                          <div className="text-right mt-3">
                            <Button className="ml-0" color="primary" size="sm" onClick={this.handleMovieTechnicalSubmit}>
                              Submit
                      </Button>
                            <a href="javascript:void(0)" className="ml-4" onClick={() => {
                              this.handleSkip();
                            }}>Skip ></a>
                          </div>
                        </Col>
                      </Row>
                    </div>

                  </ModalBody>
                </form>
              </Modal>

            );
          }}
        />


      </>


    );



  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  fetch_save_movie_response: state.movies.fetch_save_movie_response,
  getImproveTechnical: state.footer.getImproveTechnical,
  movie_details: state.item_detail.item_details,
});

const mapProps = (dispatch) => ({
  add_movie: (data) => dispatch(add_movie(data)),
  initFetchMovieSubCategories: (categoryId, type) => dispatch(initFetchMovieSubCategories(categoryId, type)),
  search_moviesMembers: (value, type) => dispatch(search_moviesMembers(value, type)),
  get_improve_listing: (categoryId, type, listType) => dispatch(get_improve_listing(categoryId, type, listType)),
  get_type_detail: ({ type, id }) => dispatch(get_type_detail({ type, id })),
  submit_improve_tech_media: (id, type, data) => dispatch(submit_improve_tech_media(id, type, data)),
});
export default withRouter(connect(mapState, mapProps)(ImproveMovieTechincalModal));