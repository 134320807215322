import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Button, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, Input, InputGroup, InputGroupAddon, DropdownItem, Media, Label, ModalFooter, CustomInput, Form, UncontrolledCollapse } from 'reactstrap';
import CollapseBasic from '../atoms/collapse';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppHeader from '../oragnisms/dashboard/appHeader';
import StarsRating from 'stars-rating';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import About from '../oragnisms/actorDetails/about';
import Filmography from '../oragnisms/actorDetails/filmography';
import EditBtn from "../atoms/editBtn";
import DeleteBtn from "../atoms/deleteBtn";
import { get_id_from_slug, get_type_detail, get_professional_pins, clear_type_details, remove_back_to_list_values, get_uploaded_media, setItemDetailLoading, get_uploaded_media_follow } from '../../actions/itemDetail';
// import Gallery from '../oragnisms/actorDetails/gallery';
import Gallery from '../oragnisms/doctorDetails/gallery';
import SocialPinFlag from '../oragnisms/actorDetails/socialPinFlag';
import SocialButtonsProfessionals from '../molecules/shared/SocialButtons/socialButtonsProfessionals';
import DiscussionsTab from '../oragnisms/actorDetails/discussionsTab';
import QandATab from '../oragnisms/actorDetails/QandATab';
import { get_reviews_listing, delete_review, reset_reviews_listing_actors } from '../../actions/actors';
import CreateReview from '../atoms/createReview';
import DetailReviews from '../oragnisms/actorDetails/detailReviews';
import dataReview from './reviews';
import ConfirmModal from '../atoms/confirmModal';
import UploadMedia from '../atoms/fileUploadMedia';
import DiscussThisPage from '../oragnisms/movieDetails/discussThisPage';
import Background from '../atoms/background';
import FriendsRecommendsThis from '../atoms/friendsRecommendsThis';
import AdditionalInfo from '../atoms/additionalInfo';
import Associations from '../atoms/associations';
import FirstToReview from '../atoms/firstToReview';
import { callApi } from "../../utils/apiCaller";
import { my_profile } from "../../actions/user";
import PublicPageHeader from '../oragnisms/homepage/publicPageHeader';
import DetailedStarRating from '../molecules/shared/DetailedStarRatings/detailedStarRating';
import { setEditExistingMedia } from '../../actions/shared';
import { finalizedMediaInReviewEmbedLinkSetForFollowReview, finalizedMediaInReviewEmbedLinkSetForNewReview, finalizedMediaInReviewMediaSetForNewReview, uploadMediaInReviewEmbedLinkSetForNewReview, uploadMediaInReviewMediaSetForEditReview, uploadMediaInReviewMediaSetForFollowReview, uploadMediaInReviewMediaSetForNewReview } from '../../actions/reviewMedia';
import { highlightSearchTerm } from '../../utils/utilities';
class ActorDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewProfessionalAs: 'about',
            viewMediaModalToggle: false,
            createNewDisModalToggle: false,
            mainViewType: 'reviews',
            mediaSet: [],
            mediaSetFollowUp: [],
            viewEditReviewStep: 'writeReview',
            viewProfessionalBackgroundType: 'education',
            actorId: 0,
            actorDetail: {},
            aboutDetail: {},
            overallRatingVote: '',
            isEnableConfirmModal: false,
            isEnableFileUpload: false,
            uploadedMediaSet: {
                embedLinks: [],
                uploadedFiles: [],
            },
            uploadedMediaSetFollowUp: {
                embedLinks: [],
                uploadedFiles: [],
            },
            confirmModalText: '',
            confirmModalKey: '',
            confirmModalItemId: null,
            enableStickyHeader: false,
			typeupload:"Actors",
			discusstype:"",
            forceShowLoginModal: false,
            followUpStatus:false,
            newlyUpdatedMedias: [],
            newlyUpdatedEmbedMedias:[],
            deletedMedias:[],
            reviews: [],
            disableCreateReview:true,
            publishReview:false
        }
    }

    resetStates = () => {
        this.setState({
            viewProfessionalAs: 'about',
            viewMediaModalToggle: false,
            createNewDisModalToggle: false,
            mainViewType: 'reviews',
            viewEditReviewStep: 'writeReview',
            viewProfessionalBackgroundType: 'education',
            actorId: 0,
            actorDetail: {},
            aboutDetail: {},
            overallRatingVote: '',
            isEnableConfirmModal: false,
            isEnableFileUpload: false,
            uploadedMediaSet: {
                embedLinks: [],
                uploadedFiles: [],
            },
            uploadedMediaSetFollowUp: {
                embedLinks: [],
                uploadedFiles: [],
            },
            confirmModalText: '',
            confirmModalKey: '',
            confirmModalItemId: null,
            enableStickyHeader: false,
            forceShowLoginModal: false,
            mediaSet: [],
            mediaSetFollowUp: [],
            followUpStatus:false,
            newlyUpdatedMedias: [],
            newlyUpdatedEmbedMedias:[],
            deletedMedias:[],
            reviews: [],
            disableCreateReview:true,
            publishReview:false
        });
    }


    toggleConfirmModal = ({ text, isTextDelete, uniqueKey, id }) => {
        let text_str = '';
        if (isTextDelete && !text) {
            text_str = 'Do you want to delete this ?';
        }

        if (text && !isTextDelete) {
            text_str = text
        }

        this.setState({
            isEnableConfirmModal: !this.state.isEnableConfirmModal,
            confirmModalText: text_str,
            confirmModalKey: uniqueKey || '',
            confirmModalItemId: id || null
        });
    }

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet,uploadedMediaSetFollowUp } = this.state;
        let uploadedMediaSetCopy = { ...uploadedMediaSet }
        let uploadedMediaSetFollowUpCopy = {...uploadedMediaSetFollowUp}
        const key = 'id'
        const keyEmbed = 'thumbnail'

    
        if (this.state.reviewOneToggler) {
            if (this.props.isFollowReviewEnable) {
                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.followReviewMediaSet, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForFollowReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.followReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForFollowReview(embedSetUnique);
                }
            } else {

                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.newReviewMediaSet, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForNewReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
                }
            }
        } else {
            if (this.props.isEditReviewEnabled) {
                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.editReviewMediaSetFinalized, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForEditReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
                }
            } else {
                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) { uploadedMediaSetCopy['uploadedFiles'] = uploadedMediaSetCopy['uploadedFiles'].concat(mediaSet); }
                if (links) { uploadedMediaSetCopy['embedLinks'] = uploadedMediaSetCopy['embedLinks'].concat(links); }
                this.setState({ uploadedMediaSet: uploadedMediaSetCopy }, () => { this.props.get_uploaded_media(uploadedMediaSetCopy); });
            }
        }
    }

    getuploadUrl = (url) => {
        let { uploadMediaUrl } = this.state;
        uploadMediaUrl = url.uploadUrl;
        this.setState({ uploadMediaUrl });
    }

    componentWillMount() {
        this.checksAndRenderById();
    }

    checksAndRenderById = () => {
        let props = this.props;
        let pathname = props.history.location?.pathname;
        const urlId = props?.match?.params?.id
        let id = props?.history?.location?.state?.id || urlId;
        let serviceParams = props?.match?.params;
        if (id === undefined && serviceParams?.service !== '' && serviceParams?.state !== '' && serviceParams?.city !== '' && serviceParams?.name !== '') {
            let items = pathname.split('/');
            let type = items[1] ? items[1] : '';
            let serviceItems = Object.values(serviceParams).join('/');
            // this.props.get_id_from_slug({ type, slug: serviceItems })
        }

        if (id) {
            this.setState({ actorId: id, ActorDetails: null, setWallDataItems: {} }, () => {
                window.scrollTo(0, 0)
                this.fetchByItemId({ actorId: this.state.actorId });
            });
        }

        if (id === undefined && serviceParams?.id) {
            let items = pathname.split('/');
            let type = items[1] ? items[1] : '';
            let slug = items[2] ? items[2] : '';
            // this.props.get_id_from_slug({ type, slug })

        }
    }

    componentWillReceiveProps(nextProps) {
        let { actorId, aboutDetail } = this.state;

        if (nextProps?.actor_details) {
            this.setState({
                actorDetail: nextProps?.actor_details,
                aboutDetail: nextProps?.actor_details,
            });
        }

        if (nextProps?.back_to_listing) {
            if (nextProps?.back_to_listing?.code === 200 && actorId === null) {
                this.setState({ actorId: nextProps?.back_to_listing?.entry_id }, () => {
                    this.fetchByItemId({ actorId: this.state.actorId });
                });
            }

            if ([404, 400, 500].includes(nextProps?.back_to_listing?.code)) {
                this.props.history.push('/professionals/actors');
            }
        }

        if (nextProps.get_review_listing && Array.isArray(nextProps.get_review_listing.results)) {
            if (!this.state.initialReviewListing) this.setState({ initialReviewListing: nextProps.get_review_listing.results })

            if (!this.state.initialReviewListing?.length && nextProps.get_review_listing.results?.length)
                this.setState({ initialReviewListing: nextProps.get_review_listing.results })

            this.setState({
                reviews: nextProps?.get_review_listing?.results
            },()=>{
                highlightSearchTerm();
            });

            if (nextProps.get_review_listing.results.length > 0) {

                if (!nextProps.get_review_listing.results?.some(item => item?.created_by?.id === this.props?.loggedInUser?.id)) {
                    if (this.state.publishReview) {
                        this.setState({ disableCreateReview: true })
                    } else {
                        if (!this.state.initialReviewListing) this.setState({ disableCreateReview: false })
                    }
                }

            } else if (nextProps.get_review_listing.results.length === 0) {
                this.setState({reviews: nextProps.get_review_listing.results},()=>{
                    highlightSearchTerm();
                })
                if (this.state.publishReview) {
                    this.setState({ disableCreateReview: true })
                } else {
                    if (!this.state.initialReviewListing) this.setState({ disableCreateReview: false })
                }
            }
        } else {
            this.setState({
                reviews: []
            },()=>{
                highlightSearchTerm();
            });
        }


        if(nextProps.auth_data && (nextProps.auth_data !== this.props.auth_data)){
            //this.props.my_profile_info();
            window.location.reload();
        }

        if("isEditReviewEnabled" in nextProps){
            if(this.state.isEditReviewEnabled !==nextProps.isEditReviewEnabled){
                // User has closed thepopup withj publishing
                // revert all the images we just updated.
                // this.revertBackTheImages();
            }
            this.setState({isEditReviewEnabled: nextProps.isEditReviewEnabled})
        }
    }

    // revertBackTheImages = () =>{
    //     const tempReviews = [...this.state.reviews];
    //     const foundIndex = tempReviews.findIndex(r=> r.id ===this.props.isEditedReviewId)
        
    //     if(foundIndex >-1){
    //         let reviewmedia_set_copy =  tempReviews[foundIndex].reviewmedia_set
    //         this.state.newlyUpdatedMedias.forEach((media)=>{
    //             if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.id===media?.id)){
    //                 reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.id!==media.id)
    //             }                
    //         })
           
    //         this.state.newlyUpdatedEmbedMedias.forEach((media)=>{
    //             if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.url===media?.url)){
    //                 reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.url!==media.url)
    //             }                
    //         })
    //         this.state.deletedMedias?.forEach((media)=>{                
    //             reviewmedia_set_copy.push(media)                              
    //         })

    //         tempReviews[foundIndex].reviewmedia_set = reviewmedia_set_copy;

    //         this.setState({reviews: tempReviews,deletedMedias:[]},()=>{
    //         })
    //     }
    // }

    componentDidUpdate(prevProps) {
        let nextProps = this.props;
        let { actorId, loaded, mediaSet,mediaSetFollowUp, uploadedMediaSet,uploadedMediaSetFollowUp } = this.state;
        // Typical usage (don't forget to compare props):
        // if (this.props.current_location !== prevProps.current_location) {
        //     this.hitOnce = false;
        //     this.checksAndRenderById();
        // }


        if (nextProps?.history?.location?.state?.id !== actorId && loaded === false) {

            this.hitOnce = false;
            this.setState(prevState => ({
                loaded: true
            }), () => { this.checksAndRenderById(); })

        }

        if (this.props.current_location && prevProps.current_location) {
            if ((this.props.current_location.lat !== prevProps.current_location.lat) && (this.props.current_location.lng !== prevProps.current_location.lng)) {
                let { actorId } = this.state;
                if (actorId) {
                    this.props.setItemDetailLoading(true);
                    this.fetchByItemId({ actorId })
                }
            }
        }

        let uploadedFiles = uploadedMediaSet?.uploadedFiles;

        if (Array.isArray(uploadedFiles) && Array.isArray(mediaSet) && uploadedFiles?.length !== mediaSet?.length) {

            this.setState({ mediaSet: uploadedFiles });
        }

        let uploadedFilesFollowUp = uploadedMediaSetFollowUp?.uploadedFiles;

        if (Array.isArray(uploadedFilesFollowUp) && Array.isArray(mediaSetFollowUp) && uploadedFilesFollowUp?.length !== mediaSetFollowUp?.length) {

            this.setState({ mediaSetFollowUp: uploadedFilesFollowUp });
        }

    }

    

    componentDidMount() {
        this.props.my_profile_info();
        let { actorId } = this.state;

        if (actorId) {
            this.fetchByItemId({ actorId });
        }
        window.addEventListener("scroll", this.handleScroll, { passive: false });
    }

    fetchByItemId = ({ actorId }) => {
        if (actorId) {
            this.props.setItemDetailLoading(true);
            this.props.get_type_detail({ type: 'actors', id: actorId });
            this.props.get_reviews_listing(actorId)
            this.props.get_professional_pins(actorId);
        }
    }


    onPublishReview = () => {
        let { actorId } = this.state;
        this.setState({publishReview:true},()=>{            
            this.fetchByItemId({ actorId });
        })
        // will call here the fetch reviews list function
    }

    handleOnConfirmModal = () => {
        let { confirmModalKey, actorDetail, confirmModalItemId, actorId } = this.state;

        if (confirmModalKey === "delete-actor-review") {
            let name = actorDetail?.name;
            this.props.delete_review(confirmModalItemId, actorId, name, "actors");
        }

        if (confirmModalKey === "delete-actor-review-comment" && confirmModalItemId) {
            this.deleteReviewComments(confirmModalItemId);
        }
    }

    deleteReviewComments = async (id) => {
        let { actorId } = this.state;
        try {
            let url = `/api/profeesionaldeleteuserfeed/?id=${id}&type=comment`;
            let response = await callApi(url, 'DELETE');
            if (response && response.code !== 500) {
                if (actorId) {                    
                    this.fetchByItemId({ actorId })
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleOnClickUploadModalOk = () => {
        
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }
	
	getnocategory = (discusst) => {
		let { discussType } = this.state;
        if (discusst && discusst != "") {
            this.setState({ discussType:discusst});
        }
	 }

     resetUploadedMediaSet = () => {
        const uploadedMediaSet = {
            embedLinks: [],
            uploadedFiles: [],
            mediaSet: [],
            mediaSetFollowUp:[]
        }
        this.setState({ uploadedMediaSet });
    }
    
    toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false, reviewOneToggler,followUpStatus) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
        this.setState({
            reviewOneToggler: reviewOneToggler? true: false,
            followUpStatus:followUpStatus
        })
    }

    handleScroll = () => {
        let enableSticky = document.getElementById('enable-sticky');
        let distanceToTop = enableSticky.getBoundingClientRect().top;
        if (distanceToTop < 0) {
            if (this.state.enableStickyHeader !== true) {
                this.setState({ enableStickyHeader: true });
            }
        } else {
            if (this.state.enableStickyHeader !== false) {
                this.setState({ enableStickyHeader: false });
            }
        }
    }

    componentWillUnmount() {
        this.resetStates();
        this.props.clear_type_details();
        this.props.remove_back_to_list_values();
        this.props.reset_reviews_listing_actors();
        window.removeEventListener("scroll", this.handleScroll);
    }

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    }

    forceToggleLoginModal = () => {
        this.setState(prevState => ({
          ...this.prevState,
          forceShowLoginModal: !prevState.forceShowLoginModal
        }));
    }

    removeUploadedMediaSet = (media)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSet:{
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:this.state.uploadedMediaSet?.uploadedFiles?.filter(item=>item.id!==media?.id)
                }
            }
        })

        // this.setState({deletedMedias:[...this.state.deletedMedias,...[media]]})
    }
    removeUploadedMediaSetFollow = (media)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSetFollowUp:{
                    ...prevState.uploadedMediaSetFollowUp,
                    uploadedFiles:this.state.uploadedMediaSetFollowUp?.uploadedFiles?.filter(item=>item.id!==media?.id)
                }
            }
        })        
    }

    render() {
        const responsive = {
            videoModal: {
                mobile: {
                    breakpoint: { max: 9999999, min: 0 },
                    items: 1
                }
            },
            viewGalleryImages: {
                desktop: {
                    breakpoint: { max: 9999999, min: 1200 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1199, min: 576 },
                    items: 2
                },
                mobile: {
                    breakpoint: { max: 575, min: 0 },
                    items: 1
                }
            }
        }
        let { actorId, aboutDetail, actorDetail, overallRatingVote, viewEditReviewStep, reviews, isEnableConfirmModal, isEnableFileUpload, confirmModalText, confirmModalKey, enableStickyHeader,uploadedMediaSet,
            uploadedMediaSetFollowUp,
            followUpStatus } = this.state;

       

        const { my_profile, auth_data } = this.props;
        const authId = localStorage.getItem('profileId');
        return (
            <div className="wr-movie-top-banner">
                {isEnableConfirmModal ?
                    <ConfirmModal
                        modalText={confirmModalText}
                        modalKey={confirmModalKey}
                        toggleConfirmModal={this.toggleConfirmModal}
                        handleOnConfirm={this.handleOnConfirmModal}
                    />
                    : null}
                {isEnableFileUpload ?
                    <UploadMedia
                        getUploadedMedia={this.getUploadedMedia}
                        uploadedUrl={this.state.uploadMediaUrl}
                        hideEmbed={this.state.hideEmbed}
                        callbackFunction={this.handleOnClickUploadModalOk}
                        toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                            this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, this.state.reviewOneToggler? true: false);                           
                        }}
						typeUpload={this.state.typeupload}
						discusstype={this.state.discussType}
                    />
                    : null}

                {/* Show/hide on scroll */}
                <div hidden={!enableStickyHeader && actorDetail}>
                    <div className="bg-darker-shaded text-white p-2 fixed-top" style={{ top: '45px' }}>
                        <Container>
                            <Row>
                                <Col lg={6}>
                                    <div className="d-flex">
                                        <div className="px-2">
                                            <img className="object-fit-cover" width="80" height="80" src={`${actorDetail && actorDetail?.professional_profileimage && actorDetail?.professional_profileimage.length > 0 && actorDetail?.professional_profileimage[0] && actorDetail?.professional_profileimage[0].url}`} alt="" onError={(error) =>
                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/circle.png`)
                                            } />
                                        </div>
                                        <div className="px-2 col">
                                            <div className="d-flex mx-n2 flex-wrap">
                                                <div className="px-2">
                                                    <h2 className="fs-20 text-uppercase">{`${actorDetail?.firstname || ''} ${actorDetail?.lastname || ''}`}</h2>
                                                </div>
                                                <div className="px-2">
                                                    <img
                                                        className="mr-2"
                                                        title={actorDetail?.total_rating?.avg_rating[1]} src={actorDetail?.total_rating && actorDetail?.total_rating?.avg_rating && actorDetail?.total_rating?.avg_rating[1] !== 'No Rating' ? require(`../../assets/images/icons/star/white/${Math.floor(actorDetail?.total_rating?.avg_rating[1])}rating.png`) : require('../../assets/images/icons/star/white/norating.png')} alt="" />
                                                    <span className="fs-14">{actorDetail?.review_count} review{actorDetail?.review_count == 1 ? '' : 's'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2 flex-100">
                                            <ul className="list-unstyled d-flex flex-wrap mx-n1 mt-1 fs-14 ff-alt">
                                                {actorDetail?.taxonomy_list?.map((tl, i) => (
                                                    <li className="text-uppercase font-weight-bold px-1" key={i}>
                                                        <span>{tl}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="ml-4 mt-2">
                                        <div className="fs-12 d-flex mx-n2">
                                            <div className="px-2 col-auto">
                                                <div className="ff-alt lh-1-2">
                                                    <span className="fs-14 d-block font-weight-bold">
                                                        {aboutDetail && aboutDetail.address && aboutDetail.address.length > 0 && aboutDetail.address[0].name ? aboutDetail.address[0].name : ""} {aboutDetail && aboutDetail.address && aboutDetail.address.length > 0 ? aboutDetail.address[0].address1 : ""}
                                                        {aboutDetail && aboutDetail.address && aboutDetail.address.length > 0 && (aboutDetail.address[0].name || aboutDetail.address[0].address1) ? <br /> : ''}
                                                        {aboutDetail && aboutDetail.address && aboutDetail.address.length > 0 && aboutDetail.address[0].city ? aboutDetail.address[0].city : ""} {aboutDetail && aboutDetail.address && aboutDetail.address.length > 0 && aboutDetail.address[0].state ? aboutDetail.address[0].state : ""} {aboutDetail && aboutDetail.address && aboutDetail.address.length > 0 && aboutDetail.address[0].zipcode ? aboutDetail.address[0].zipcode : ""}
                                                        {aboutDetail && aboutDetail.address && aboutDetail.address.length > 0 && (aboutDetail.address[0].city || aboutDetail.address[0].state || aboutDetail.address[0].zipcode) ? <br /> : ''}
                                                        <span className="d-block text-uppercase">
                                                             {aboutDetail && aboutDetail.address && aboutDetail.address.length > 0 && aboutDetail.address[0].country ? aboutDetail.address[0].country : ""}
                                                         </span>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                {(my_profile && my_profile.id && authId && (authId == my_profile.id)) ? (<AppHeader isEnableCover={false} logoutToHome={true}/>
                ) : (<PublicPageHeader isEnableCover={false} forceShowLoginModal={this.state.forceShowLoginModal} forceToggleLoginModal={this.forceToggleLoginModal}/>)}
                <header className="position-relative">
                    <div className="featured-blurred-bg" style={{ backgroundImage: `url(${actorDetail && actorDetail.media_dict && actorDetail.media_dict.length > 0 && Array.isArray(actorDetail.media_dict) && actorDetail.media_dict[0] ? actorDetail.media_dict[0].url : 'https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'})` }}></div>
                    <TabContent className={(this.state.viewProfessionalAs === 'gallery' || this.state.viewProfessionalAs === 'filmography') ? 'bg-dark-shaded-sm' : ''} activeTab={this.state.viewProfessionalAs} style={{ minHeight: '500px' }}>
                        <TabPane tabId="about">
                            <About aboutDetails={this.state.actorDetail !== null ? this.state.actorDetail : ""} />
                        </TabPane>
                        <TabPane tabId="gallery">
                            <Gallery professionalDetails={this.props.actor_details} toggleEnableFileUpload={this.toggleEnableFileUpload}
                                uploadedMediaSet={this.state.uploadedMediaSet}
                                callGalleryFunction={this.state.callGalleryFunction}
                                resetGalleryFunctionToggle={() => this.setState({ callGalleryFunction: false })}
                                getuploadUrl={this.getuploadUrl} resetUploadedMediaSet={this.resetUploadedMediaSet} forceToggleLoginModal={this.forceToggleLoginModal}
                                mainTaxonomy={"actors"} />
                        </TabPane>
                        <TabPane tabId="filmography">
                            <Filmography aboutDetails={this.state.actorDetail !== null ? this.state.actorDetail : ""} />
                        </TabPane>
                    </TabContent>
                    <Container>
                        <Nav className="featured position-relativev bg-white" justified pills style={{ marginTop: '-54px', zIndex: 3 }}>
                            <NavItem className="mw-100 margin-right-2">
                                <NavLink className="text-truncate" href="#"
                                    active={this.state.viewProfessionalAs === "about"}
                                    onClick={() => {
                                        this.setState({ viewProfessionalAs: "about" })
                                    }}>
                                    <span className="font-weight-bold text-uppercase">
                                        {'About'}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="mw-100 margin-right-2">
                                <NavLink className="text-truncate" href="#"
                                    active={this.state.viewProfessionalAs === "gallery"}
                                    onClick={() => {
                                        window.dispatchEvent(new Event("resize"));
                                        this.setState({ viewProfessionalAs: "gallery" })
                                    }}>
                                    <span className="font-weight-bold text-uppercase">
                                        {'Gallery'}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="mw-100 margin-right-2">
                                <NavLink className="text-truncate" href="#"
                                    active={this.state.viewProfessionalAs === "filmography"}
                                    onClick={() => {
                                        this.setState({ viewProfessionalAs: "filmography" })
                                    }}>
                                    <span className="font-weight-bold text-uppercase">
                                        {'Filmography'}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="mw-100">
                                {/* <SocialPinFlag actorDetails={this.props.actor_details} forceToggleLoginModal={this.forceToggleLoginModal}/> */}
                                <SocialButtonsProfessionals categoryId={actorId} showHistoryTab categoryType="actor" categoryDetails={this.props.actor_details} forceToggleLoginModal={this.forceToggleLoginModal} bgLight={false} hideWant={true} />
                            </NavItem>
                        </Nav>
                    </Container>
                </header>

                {/* Main Content */}
                <section className="py-4 fs-14" id="enable-sticky">
                    <Container>
                        <Row>
                            <Col md={4}>
                                {actorDetail?.content_type_name !== "actors" && (
                                    <div className="text-dark p-3 mb-3" style={{ backgroundColor: '#d5d6e5' }}>
                                        <h3 className="mb-2">
                                            {`Are you ${actorDetail?.firstname !== undefined ?
                                                `${actorDetail?.firstname || ""} ${actorDetail?.lastname || ""} ?`
                                                : actorDetail?.name !== undefined ? `${actorDetail?.name} ?` : ''}`}
                                        </h3>
                                        <div className="mt-4 text-right">
                                            <Button size="sm" color="tertiary">claim this profile</Button>
                                        </div>
                                    </div>
                                )}

                                {/* Detailed Star Rating */}                                
                                <DetailedStarRating
                                    total_rating={actorDetail?.total_rating || null}
                                    id={actorId}
                                    bgLight={true}
                                    charts={{ bar: true, line: true, genderTable: true }} professionals/> 

                                {/* Background */}
                                <Background
                                    id={actorId}
                                    uniqueDeleteKey={'delete-background'}
                                    toggleConfirmModal={this.toggleConfirmModal}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                />

                                {/* Friends Recommendation */}
                                <FriendsRecommendsThis
                                    id={actorId}
                                    listing_type={'actors'}
                                />

                                {/* Additional Information */}
                                <AdditionalInfo
                                    random={Math.random()}
                                    id={actorId}
                                    listing_type={"actors"}
                                    fetchByItemId={this.fetchByItemId}
                                    additional_info={actorDetail?.additional_info}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                />

                                {/* Associations */}
                                <Associations
                                    id={actorId}
                                    listing_type={"actors"}
                                    fetchByItemId={this.fetchByItemId}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                />

                                {/* First to review */}
                                <FirstToReview
                                    itemDetail={actorDetail}
                                />

                                {/* Discuss This Page */}
                                <DiscussThisPage
                                    id={actorId}
                                    random={Math.random()}
                                    profdiscussion={true}
                                    isLight={true}
                                    getUploadedMedia={this.getUploadedMedia}
                                    toggleEnableFileUpload={this.toggleEnableFileUpload}
                                    uploadedMediaSet={this.state.uploadedMediaSet}
									getnocategory={this.getnocategory}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                />
                                <div className="bg-white text-center">
                                    <a href="http://wikireviews.com/blog" rel="noopener noreferrer" target="_blank">
                                        <img className="img-fluid blog-img" src="https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_02.png" alt="Go to WikiReviews Herald" />
                                    </a>
                                </div>
                            </Col>
                            <Col>
                                {/* // Here Details and Reviews */}
                                {!(actorDetail?.is_logged_user_review ?? true) && 
                                <CreateReview
                                    itemDetail={actorDetail}
                                    overallRatingVote={overallRatingVote}
                                    viewEditReviewStep={viewEditReviewStep}
                                    mainViewType={this.state.mainViewType}
                                    listing_type={'actors'}
                                    reviews={reviews}
                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true, false);                                       
                                    }}
                                    uploadedMediaSet={this.state.uploadedMediaSet}
                                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                                    onPublishReview={this.onPublishReview}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                    categoryId={actorDetail?.id}
                                    resetUploadedMediaSet={this.resetUploadedMediaSet}
                                />
                                }
                                <DetailReviews
                                    uniqueDeleteKey={'delete-actor-review'}
                                    uniqueDeleteCommentKey={'delete-actor-review-comment'}
                                    overallRatingVote={overallRatingVote}
                                    viewEditReviewStep={viewEditReviewStep}
                                    mainViewType={this.state.mainViewType}
                                    onPublishReview={this.onPublishReview}
                                    listing_type={'actors'}                                    
                                    reviews={reviews}
                                    item_detail={this.props.actor_details}
                                    uploadedMediaSet={uploadedMediaSet}
                                    uploadedMediaSetFollow={uploadedMediaSetFollowUp}
                                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                                    removeUploadedMediaSetFollow={this.removeUploadedMediaSetFollow}
                                    toggleConfirmModal={this.toggleConfirmModal}
                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload,newReviewStatus, followUpStatus)=>{
                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus,followUpStatus);                                       
                                    }}
                                    getuploadUrl={this.getuploadUrl}
                                    resetUploadedMediaSet={this.resetUploadedMediaSet}
									getnocategory={this.getnocategory}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* View Media Modal */}
                <Modal isOpen={this.state.viewMediaModalToggle} toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}>
                    <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader>
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}>
                            <div className="item h-100 d-flex flex-column justify-content-center">
                                <div className="text-center">
                                    <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/content/AmandaCrewCropped.cc81bd9fa3c6e2652249eff0448292197685965b.jpg" alt="" />
                                </div>
                            </div>
                        </Carousel>
                    </ModalBody>
                </Modal>

                {/* Share By Email Modal Popup */}
                <Modal size="sm" isOpen={this.state.shareByEmailModalToggle} toggle={() => this.setState({
                    shareByEmailModalToggle: !this.state.shareByEmailModalToggle
                })}>
                    <ModalHeader className="text-uppercase px-0" toggle={() => this.setState({
                        shareByEmailModalToggle: !this.state.shareByEmailModalToggle
                    })}>
                        Share by Email
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input className="primary" bsSize="sm" type="text" name="sharer_name" placeholder="Your name" />
                        </FormGroup>
                        <FormGroup>
                            <Input className="primary" bsSize="sm" type="email" name="email" placeholder="Recipient's email" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <div className="text-right">
                            <Button color="primary">Share</Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapState = (state) => ({
    loggedInUser: state.user.my_profile,
    actor_details: state.item_detail.item_details, // from item_details
    back_to_listing: state.item_detail.back_to_listing, // from item_details
    current_location: state.home.current_location,
    get_review_listing: state.actor.get_review_listing,
    my_profile: state.user.my_profile,
    auth_data: state.auth.auth_login,
    editExistingMedia : state.shared.editExistingMedia,
    editExistingEmbedMedia : state.shared.editExistingEmbedMedia,
    isEditedReviewId: state.shared.isEditedReviewId,
    isEditReviewEnabled: state.shared.isEditReviewEnabled,
    newReviewMediaSet: state.reviewMedia.newReviewMediaSet,     
    newReviewEmbedLinkSetFinalized: state.reviewMedia.newReviewEmbedLinkSetFinalized,
    editReviewMediaSetFinalized:state.reviewMedia.editReviewMediaSetFinalized,
    isFollowReviewEnable:state.shared.isFollowReviewEnable,
    followReviewMediaSet: state.reviewMedia.followReviewMediaSet,     
    followReviewEmbedLinkSetFinalized: state.reviewMedia.followReviewEmbedLinkSetFinalized,
});

const mapProps = (dispatch) => ({
    get_id_from_slug: ({ type, slug }) => dispatch(get_id_from_slug({ type, slug })), // from item_details
    get_type_detail: ({ type, id }) => dispatch(get_type_detail({ type, id })), // from item_details
    get_professional_pins: (id) => dispatch(get_professional_pins(id)),
    get_reviews_listing: (id, type) => dispatch(get_reviews_listing(id, type)),
    delete_review: (id, item_id, name, type) => dispatch(delete_review(id, item_id, name, type)),
    clear_type_details: () => dispatch(clear_type_details()),
    my_profile_info: () => dispatch(my_profile()),
    remove_back_to_list_values: () => dispatch(remove_back_to_list_values()),
    get_uploaded_media: (data) => dispatch(get_uploaded_media(data)),
    get_uploaded_media_follow: (data) => dispatch(get_uploaded_media_follow(data)),
    setItemDetailLoading:(data)=>dispatch(setItemDetailLoading(data)),
    setEditExistingMedia:(data)=>dispatch(setEditExistingMedia(data)),
    uploadMediaInReviewMediaSetForNewReview: (data) => dispatch(uploadMediaInReviewMediaSetForNewReview(data)),
    uploadMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(uploadMediaInReviewEmbedLinkSetForNewReview(data)),
    finalizedMediaInReviewMediaSetForNewReview: (data) => dispatch(finalizedMediaInReviewMediaSetForNewReview(data)),
    finalizedMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForNewReview(data)),
    uploadMediaInReviewMediaSetForEditReview: (data) => dispatch(uploadMediaInReviewMediaSetForEditReview(data)),
    uploadMediaInReviewMediaSetForFollowReview: (data) => dispatch(uploadMediaInReviewMediaSetForFollowReview(data)),
    finalizedMediaInReviewEmbedLinkSetForFollowReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForFollowReview(data)),
    reset_reviews_listing_actors: () => dispatch(reset_reviews_listing_actors()),
});


export default withRouter(connect(mapState, mapProps)(ActorDetails));
