import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Button, FormGroup, Input, Label } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import DeleteBtn from "../../atoms/deleteBtn";
import { callApi } from '../../../utils/apiCaller';
import { toast } from "react-toastify";

export default class CoolFacts extends Component {
    constructor(props) {
        super(props)
        this.isUpdateTrue = false;
        this.state = {
            addAnother: {
                isToggleAddAnother: false,
                isSelectedValue: '',
                isMasked: false,
                descriptionText: ''
            },
            isMaskedReveal: {},
            dataItems: {},
            // { "Quotes": [{ "cool_fact_type_id": 1, "reviews_userentries_id": 28523298, "is_active": true, "cool_fact_content": "details, considerations, or pieces of information of little importance or value.", "is_masked": false, "created_on": "2020-11-10T12:00:49", "updated_by_id": null, "is_patroled": false, "updated_on": "2020-11-10T12:00:49", "created_by_id": 1490, "id": 1864769 }], "Trivia": [{ "cool_fact_type_id": 1, "reviews_userentries_id": 28523298, "is_active": true, "cool_fact_content": "details, considerations, or pieces of information of little importance or value.", "is_masked": true, "created_on": "2020-11-10T12:00:49", "updated_by_id": null, "is_patroled": false, "updated_on": "2020-11-10T12:00:49", "created_by_id": 1490, "id": 1864769 }], "Credit Craziness": [{ "cool_fact_type_id": 1, "reviews_userentries_id": 28523298, "is_active": true, "cool_fact_content": "details, considerations, or pieces of information of little importance or value.", "is_masked": false, "created_on": "2020-11-10T12:00:49", "updated_by_id": null, "is_patroled": false, "updated_on": "2020-11-10T12:00:49", "created_by_id": 1490, "id": 1864769 }], "Goofs": [{ "cool_fact_type_id": 1, "reviews_userentries_id": 28523298, "is_active": true, "cool_fact_content": "details, considerations, or pieces of information of little importance or value.", "is_masked": false, "created_on": "2020-11-10T12:00:49", "updated_by_id": null, "is_patroled": false, "updated_on": "2020-11-10T12:00:49", "created_by_id": 1490, "id": 1864769 }] }
        }
    }

    toggleAddAnother = (e) => {
        e.preventDefault();
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        let { addAnother } = this.state;
        addAnother['isToggleAddAnother'] = !addAnother['isToggleAddAnother'];
        this.setState({ addAnother });
    }

    componentDidMount() {
        this.fetchDataItems();
    }

    componentDidUpdate() {
        let nextProps = this.props;
        if (nextProps?.updateCoolFactsOnDelete && !this.isUpdateTrue) {
            this.isUpdateTrue = true;
            this.fetchDataItems();
        }
    }

    fetchDataItems = async () => {
        let { id } = this.props;
        try {
            let response = await callApi(`/api/coolfacts/?listid=${id}`, 'GET');
            
            if (response && response.code !== 500) { // set state here for fetch data;
                this.props.toggleUpateCoolFacts(false);
                this.isUpdateTrue = false;
                this.setState({ dataItems: response });
            }
        } catch (err) {
            console.log(err);
        }
    }

    createDataItem = async () => {
        let { addAnother: { isToggleAddAnother, isSelectedValue, isMasked, descriptionText } } = this.state;
        let { id } = this.props;

        let data = {};
        data.name = "";
        data.cool_fact_type = isSelectedValue;
        data.cool_fact_content = descriptionText;
        data.reviews_userentries = id;
        data.is_masked = isMasked;

        try {
            let response = await callApi(`/api/coolfacts/?listid=${id}`, 'POST', data);
            if (response && response?.code !== 500) {
                this.fetchDataItems();
                this.handleClickCancel();
                toast(`Your content added to cool facts section.`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                  });
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleSelect = (e) => {
        let { addAnother } = this.state;
        addAnother['isSelectedValue'] = e.target.value;
        this.setState({ addAnother });
    }

    toggleIsMasked = () => {
        let { addAnother } = this.state;
        addAnother['isMasked'] = !addAnother['isMasked'];
        this.setState({ addAnother });
        return addAnother['isMasked'];
    }

    handleDescriptionText = (e) => {
        let { addAnother } = this.state;
        addAnother['descriptionText'] = e.target.value;
        this.setState({ addAnother });
    }

    handleClickCancel = () => {
        this.setState({
            addAnother: {
                isToggleAddAnother: false,
                isSelectedValue: '',
                isMasked: false,
                descriptionText: ''
            }
        })
    }

    checkIsMasked = (item) => {
        let { isMaskedReveal } = this.state;
        let id = item?.id;

        if (item?.is_masked === true && isMaskedReveal[id] == undefined) {
            isMaskedReveal[id] = item?.is_masked;
        }

        return isMaskedReveal[id];
    }

    toggleRevealMasked = (item, index) => {
        let { dataItems } = this.state;
        let id = item?.id;
        let getItem = dataItems[index];

        if (getItem) {
            let filteredIndex = getItem.findIndex(dt => dt.id == id);
            if (filteredIndex > -1) {
                let itemObject = getItem[filteredIndex];
                itemObject['is_masked'] = !itemObject?.is_masked;
                dataItems[index][filteredIndex] = itemObject;
                this.setState({ dataItems });
            }
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile} = this.props;
        if(my_profile && my_profile.id && authId && (authId == my_profile.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        let { addAnother: { isToggleAddAnother, isSelectedValue, isMasked, descriptionText }, dataItems, isMaskedReveal } = this.state;
        const { my_profile } = this.props;
        return (
            <div className="bg-movie-dark text-white mb-3">
                <CollapseBasic
                    title="Cool Facts"
                    containerClass="bg-transparent type-white"
                    bodyClass="mt-2 text-white fs-13"
                    isOpen={true}>
                    <ul className="list-unstyled">
                        {Object.keys(dataItems) && Object.keys(dataItems).length ?

                            Object.keys(dataItems).map((item, mainIndex) => {
                                let value = dataItems[item];
                                return item !== "code" && (
                                    <li key={mainIndex}>
                                        <strong>{item}{":"}</strong>
                                        <ul className="list-unstyled">
                                            {value && Array.isArray(value) && value.length ?
                                                value.map((value_item, index) => (
                                                    <li className="mb-1" key={index}>
                                                        <span className="number-count">{index + 1}.</span>
                                                        <div className="d-flex mx-n2">

                                                            <React.Fragment>
                                                                <div className="px-2">
                                                                    {/* If spoiler, sent data-blurr={true} */}
                                                                    <div
                                                                        className="d-inline-block wb-break-word"
                                                                        data-blurr={value_item?.is_masked}>
                                                                        {value_item?.cool_fact_content}
                                                                    </div>
                                                                    {/* If spoiler, Show button */}
                                                                    <div hidden={!value_item?.is_masked}>
                                                                        <Button
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.toggleRevealMasked(value_item, item)
                                                                            }}
                                                                            color="link"
                                                                            className="text-primary fs-12 p-0">
                                                                            {'Click here to reveal'}
                                                                        </Button>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="px-2 ml-auto"
                                                                    hidden={my_profile?.id !== value_item.created_by_id ? true : false}>
                                                                    <DeleteBtn
                                                                        onClick={() => {
                                                                            this.props.toggleConfirmModal({
                                                                                text: 'Are you sure you want to delete?',
                                                                                uniqueKey: 'delete-movies-coolFacts',
                                                                                id: value_item?.id
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        </div>
                                                    </li>
                                                ))
                                                : null}
                                        </ul>

                                        <hr className="bg-light my-2" />
                                    </li>
                                )
                            })


                            : null}

                    </ul>

                    <div className="text-right mb-2">
                        <Button onClick={this.toggleAddAnother} color="link" className="p-0 text-white">
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                            {'Add another'}
                        </Button>
                    </div>

                    {isToggleAddAnother ? (
                        <div className="bg-white p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">Add another</h5>
                            <FormGroup>
                                <Input
                                    onChange={this.handleSelect}
                                    value={isSelectedValue}
                                    className="primary"
                                    bsSize="sm"
                                    type="select">
                                    <option value="" selected="selected">Please Select</option>
                                    <option value="1">Trivia</option>
                                    <option value="2">Quotes</option>
                                    <option value="3">Goofs</option>
                                    <option value="4">Credit Craziness</option>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    value={descriptionText}
                                    onChange={this.handleDescriptionText}
                                    className="primary ph-primary font-weight-bold text-dark"
                                    bsSize="sm"
                                    type="textarea"
                                    rows="4"
                                    placeholder="Please provide something relevant..."
                                />
                            </FormGroup>
                            <FormGroup check>
                                <Label className="text-dark font-weight-normal" check>
                                    <Input onClick={this.toggleIsMasked} type="checkbox" checked={isMasked} />{' '}
                                    {'Mark as spoiler'}
                                </Label>
                            </FormGroup>

                            <div className="text-right mt-3">
                                <Button
                                disabled={isSelectedValue == '' || descriptionText == ''}
                                    onClick={this.createDataItem}
                                    className="mw"
                                    color="primary"
                                    size="sm">Submit</Button>
                                <Button
                                    onClick={this.handleClickCancel}
                                    className="mw"
                                    color="primary"
                                    size="sm">Cancel</Button>
                            </div>
                        </div>

                    ) : null}

                </CollapseBasic>
            </div>
        )
    }
}
