import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";

export const GET_DISCUSSIONS = "GET_DISCUSSIONS";

export const get_discussions_ = (data) => {
    return {
        type: GET_DISCUSSIONS,
        payload: data,
    };
};


export const get_discussions = (url) => {
    return (dispatch) => {
        callApi(`${url}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
            
                return dispatch(get_discussions_(response));
            }
        });
    };
};

export const create_discussion = (data, url) => {
    const  apiUrl = data.apiUrl ? data.apiUrl : `/api/discussion/`; 
    return (dispatch) => {
        callApi(apiUrl, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Discussion added successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                return dispatch(get_discussions(url));
            }
        });
    };
};

export const delete_discussion = (url, message) => {
    return (dispatch) => {
        callApi(`${url}`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`${message}`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                return true;
               //return dispatch(get_discussions(url));
            }
        });
    };
};

export const delete_buyers_discussion = (url, message) => {
    return (dispatch) => {
        callApi(`${url}`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`${message}`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
               // return true;
               return dispatch(get_discussions(url));
            }
        });
    };
};

export const edit_discussion = (data) => {
    const  apiUrl = data.apiUrl ? data.apiUrl : `/api/getdiscussion/?id=${data.id}`; 
    return (dispatch) => {
        callApi(apiUrl, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if(data.toast){
                    toast(`${data.toast}`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    }); 
                }
                return true;
            }
        });
    };
};

export const add_comment = (id, data) => {
    const  apiUrl = data.apiUrl ? data.apiUrl : `/api/discussion/${id}/`; 
    return (dispatch) => {
        callApi(apiUrl, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return true;
            }
        });
    };
};