import React, { useState } from "react";
import { Button, Media } from "reactstrap";
import { ReplyEditComment } from "./ReplyEditComment";
import EditBtn from "../../atoms/editBtn";
import DeleteBtn from "../../atoms/deleteBtn";

const FeedbackComments = ({
  comments,
  onDeleteComment,
  feed,
  onCommentReply,
  onEditComment_Reply,
  myProfile
}) => {
  const [commentIdToEdit, setCommentIdToEdit] = useState(null);
  const [commentIdToReply, setCommentIdToReply] = useState(null);

  const checkIfLoggedIn = () => {
    const authId = localStorage.getItem('profileId');
    if (myProfile && myProfile.id && authId && (authId == myProfile.id)) {
      return true;
    } else {
      return false;
    }
  }

  const checkOwner = ({ conversation, type }) => {
    if (checkIfLoggedIn() && myProfile?.user?.username) {
      if (type == "comment" && conversation?.sender_details == myProfile.user.username) {
        return true;
      }
      if (type == "reply" && conversation?.sender?.username == myProfile.user.username)
        return true;
    }
    return false;
  }

  return (
    <div>
      <div className="mb-3">
        {comments &&
          Array.isArray(comments) &&
          comments.map((comment) => (
            <Media
              key={comment?.msg_id}
              className="media-post body-bg p-2 mb-1"
            >
              <Media>
                <img
                  className="media-object"
                  src={
                    comment?.current_profile_pic
                      ? comment.current_profile_pic
                      : require("../../../assets/images/icons/user-circle.png")
                  }
                  onError={(error) =>
                    (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                  }
                  alt="user"
                />
              </Media>
              <Media body>
                <Media heading>
                  <div className="d-flex mx-n2">
                    <div className="px-2 col">
                      <span className="text-dark">
                        {comment?.sender && comment.sender}
                        {": "}
                      </span>
                      <span className="font-weight-normal user-comment ff-alt fs-14">
                        {comment?.body && comment.body}
                      </span>
                    </div>
                  </div>
                </Media>
                <div className="d-flex flex-nowrap mx-n2 mt-n1">
                  <div className="align-self-end flex-grow-1 px-2">
                    {/* Reply Button */}
                    <Button
                      color="link"
                      className="text-dark fs-14 font-weight-bold p-0"
                      onClick={() => {
                        setCommentIdToEdit(null);
                        setCommentIdToReply(comment.msg_id);
                      }}
                    >
                      Reply
                    </Button>
                    <span className="fs-14 ff-alt text-tertiary align-middle">
                      <span>{" - "}</span>
                      {comment?.sent_at && comment?.sent_at}
                    </span>
                  </div>

                  {/* Remove Actions if comment by other user  */}
                  {(checkOwner({ conversation: comment, type: "comment" }))
                    ?
                    <div className="align-self-start col-auto px-2">
                      <EditBtn
                        onClick={() => {
                          setCommentIdToReply(null);
                          setCommentIdToEdit(comment?.msg_id);
                        }}
                      />
                      <DeleteBtn
                        onClick={() => {
                          onDeleteComment(comment?.msg_id, "question");
                        }}
                      />
                    </div>
                    : ""
                  }
                  
                </div>
                <div>
                  {comment?.msg_id && commentIdToEdit === comment?.msg_id ? (
                    <ReplyEditComment
                      id={comment?.msg_id}
                      body={comment?.body}
                      userImgSource={
                        myProfile?.current_profile_pic
                              ? myProfile?.current_profile_pic
                              : require("../../../assets/images/icons/user-circle.png")
                      }
                      isUpdating={true}
                      onCancel={() => setCommentIdToEdit(null)}
                      onSubmit={(body) => {
                        onEditComment_Reply({
                          body,
                          id: comment?.msg_id,
                          review: feed?.action?.id,
                          method: "PUT",
                        });
                        setCommentIdToEdit(null);
                      }}
                    />
                  ) : null}
                  {comment?.msg_id && commentIdToReply === comment?.msg_id ? (
                    <ReplyEditComment
                      id={comment?.msg_id}
                      body={""}
                      userImgSource={
                        myProfile?.current_profile_pic
                              ? myProfile?.current_profile_pic
                              : require("../../../assets/images/icons/user-circle.png")
                      }
                      isUpdating={false}
                      onCancel={() => setCommentIdToReply(null)}
                      onSubmit={(body) => {
                        onCommentReply({
                          body,
                          id: comment?.msg_id,
                        });
                        setCommentIdToReply(null);
                      }}
                    />
                  ) : null}
                </div>
                {/* comment reply */}
                {comment.replies &&
                  comment.replies.map((reply, index) => (
                    <Media className="media-post mt-2" key={index}>
                      <Media>
                        <img
                          className="media-object"
                          src={
                            reply?.sender?.current_profile_pic
                              ? reply?.sender?.current_profile_pic
                              : require("../../../assets/images/icons/user-circle.png")
                          }
                          onError={(error) =>
                            (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                          }
                          alt="user"
                        />
                      </Media>
                      <Media body>
                        <Media heading>
                          <div className="d-flex mx-n2">
                            <div className="px-2 col">
                              <span className="text-dark">
                                {reply.sender?.full_name &&
                                  reply.sender?.full_name}
                                {": "}
                              </span>
                              <span className="font-weight-normal user-comment ff-alt fs-14">
                                {reply.body}
                              </span>
                            </div>
                            {/* <div className="ml-auto">
                              <EditBtn
                                onClick={() => {
                                  setCommentIdToReply(null);
                                  setCommentIdToEdit(reply?.id);
                                }}
                              />
                              <DeleteBtn
                                onClick={() =>
                                  onDeleteComment(reply.id, "question")
                                }
                              />
                            </div> */}
                          </div>
                        </Media>
                        <div className="d-flex flex-nowrap mx-n2 mt-n1">
                          <div className="align-self-end flex-grow-1 px-2">
                            {/* Reply Button */}
                            <Button
                              color="link"
                              className="text-dark fs-14 font-weight-bold p-0"
                              onClick={() => {
                                setCommentIdToEdit(null);
                                setCommentIdToReply(reply?.id);
                              }}
                            >
                              Reply
                            </Button>
                            <span className="fs-14 ff-alt text-tertiary align-middle">
                              <span>{" - "}</span>
                              {reply.sent_at}
                            </span>
                          </div>

                          {/* Remove Actions if comment by other user  */}
                          {((checkOwner({ conversation: reply, type: "reply" })))
                            ?
                            <div className="align-self-start col-auto px-2">
                              <EditBtn
                                onClick={() => {
                                  setCommentIdToReply(null);
                                  setCommentIdToEdit(reply?.id);
                                }}
                              />
                              <DeleteBtn
                                onClick={() =>
                                  onDeleteComment(reply.id, "question")
                                }
                              />
                            </div>
                            : ""
                          }
                        </div>
                        <div>
                          {reply?.id && commentIdToEdit === reply?.id ? (
                            <ReplyEditComment
                              id={reply?.id}
                              body={reply?.body}
                              userImgSource={
                                myProfile?.current_profile_pic
                                      ? myProfile?.current_profile_pic
                                      : require("../../../assets/images/icons/user-circle.png")
                              }
                              isUpdating={true}
                              onCancel={() => setCommentIdToEdit(null)}
                              onSubmit={(body) => {
                                onEditComment_Reply({
                                  body,
                                  id: reply?.id,
                                  review: feed?.action?.id,
                                  method: "PUT",
                                });
                                setCommentIdToEdit(null);
                              }}
                            />
                          ) : null}
                          {reply?.id && commentIdToReply === reply?.id ? (
                            <ReplyEditComment
                              id={reply?.id}
                              body={""}
                              userImgSource={
                                myProfile?.current_profile_pic
                                      ? myProfile?.current_profile_pic
                                      : require("../../../assets/images/icons/user-circle.png")
                              }
                              isUpdating={false}
                              onCancel={() => setCommentIdToReply(null)}
                              onSubmit={(body) => {
                                onCommentReply({
                                  body,
                                  review: feed?.action?.id,
                                  id: comment?.msg_id,
                                });
                                setCommentIdToReply(null);
                              }}
                            />
                          ) : null}
                        </div>
                      </Media>
                    </Media>
                  ))}
              </Media>
            </Media>
          ))}
      </div>
    </div>
  );
};

export default FeedbackComments;
