import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { get_type_detail, setItemDetailLoading } from "./itemDetail";


export const GET_REVIEW_LISTING_LAWYER = "GET_REVIEW_LISTING_LAWYER";

export const get_reviews_listing_ = (data) => ({ type: GET_REVIEW_LISTING_LAWYER, payload: data });
export const get_reviews_listing = (id, type, searchValue) => {
    if (!type) { type = 'new' }
    let url = `/api/professionalreviews/?associated_to__id=${id}&searchTerm=${type}`;
    if(searchValue) url = `/api/professionalreviews/?associated_to__id=${id}&page=1&search=${searchValue}`;
  
    return (dispatch) => {
        if (searchValue || searchValue === "") dispatch(get_reviews_listing_([]));
     
        callApi(url, "GET").then((response) => {
            if (response && response?.code !== 400 && response?.code !== 500) {
                dispatch(setItemDetailLoading(false));
                dispatch(get_reviews_listing_(response));
            }
        });
    };
};



export const delete_review = (id, item_id, name, type) => {
    return (dispatch) => {
        callApi(`/api/professionalreviews/${id}/`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Your review of ${name} has been deleted.`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                dispatch(get_type_detail({ type, id: item_id }))
                return dispatch(get_reviews_listing(item_id));
            }
        });
    };
};
