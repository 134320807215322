import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { connectivity_tabs_content, fetch_items_pin } from "./../../../actions/project";
import { Form, Nav, NavItem, NavLink, CardBody, Card, Media, TabContent, TabPane, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, ModalHeader, ModalBody, FormGroup, Input, ModalFooter } from 'reactstrap';
import { callApi } from "./../../../utils/apiCaller";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from "react-toastify";
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";

class MyConnectionsActivity extends Component {
    constructor(props) {
        super(props)

        this.refScrollImages = React.createRef();

        this.state = {
            tabContent: {},
            viewConnectActivity: 'all',
            shareByEmailModalToggle: false,
            projectId: null,
            share_name: "",
            share_email: "",
            validShare: false,
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: 'Log in or sign up for WikiReviews to view and rate my Community Review Project!',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            items_pin_data : []
        }
    }

    componentDidMount() {
        this.props.connectivity_tabs_content("all");
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    componentWillReceiveProps(nextProps) {
        let { tabContent } = this.state;

        if (nextProps?.fetch_items_pin_data) {
            this.setState({ items_pin_data : nextProps.fetch_items_pin_data})
        }
       
        if (nextProps?.tab_content) {
            let name = this.state.viewConnectActivity;
            let result = nextProps.tab_content.results;
            tabContent[name] = { name, value: result }
            this.setState({ tabContent })
        }
    }

    getTabLink = (name) => {
        let vaule = name;
        if (name === "all") {
            this.setState({ viewConnectActivity: "all" },
                () => { this.props.connectivity_tabs_content("all") }
            )
        }
        else if (name === "reviews") {
            this.setState({ viewConnectActivity: "reviews" },
                () => { this.props.connectivity_tabs_content("reviews") }
            )
        }
        else if (name === "comments") {
            this.setState({ viewConnectActivity: "comments" },
                () => { this.props.connectivity_tabs_content("comments") }
            )
        }
        else if (name === "other") {
            this.setState({ viewConnectActivity: "other" },
                () => { this.props.connectivity_tabs_content("other") }
            )
        }
    }


    addList = (name, id, project_name, tab_name) => {
        let fetchType = "";

        if (name === "watchlist") {
            fetchType = "watch list";
        } else if (name === "favouritelist") {
            fetchType = "favourite list";
        } else if (name === "wantlist") {
            fetchType = "want list";
        } else {
            return false;
        }

        let data = {
            type: fetchType,
            user_entry: id
        };
        let sendName = window.unescape(fetchType);

        if (data.type !== "" && data.user_entry) {
           
            callApi(`/api/pins/?type=${sendName}&user=`, "POST", data).then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                   
                    let pin_status = response.pinning_status[`${fetchType}`] ? 'added' : 'removed';
                    toast(` ${project_name} has been ${pin_status} to your ${fetchType}.`, {
                        autoClose: 3500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                    this.getTabLink(tab_name);
                }
            });
            // this.props.fetch_items_pin(id);

        }
    }

    getTabContent = () => {
        let name = this.state.viewConnectActivity;
        let content = this.state.tabContent;
        let contentName = content[name];

        if (name === "all") {
            if (content && contentName?.name === "all") {
                let data = contentName.value;

               
                if (data && Array.isArray(data) && data.length > 0) {
                    return data.map((item, index) => {

                        return (

                            <Card className="text-dark bg-white mb-3" key={index}>
                                <CardBody>
                                    <div className="text-right mt-n2 mr-n2">
                                        <div className="d-inline-flex mx-n1">
                                            <div className="px-1">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 text-dark" color="transparent">
                                                        <div className="share-it-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary-alt" right>
                                                        <DropdownItem>
                                                            <FacebookShareButton url={`${this.state.protocol}//${this.state.host}/projects/${item.target.id}`} quote={item.target.name} hashtag={this.state.fbHashTag} className="text-white p-0 text-decoration-none">
                                                                Facebook
                                                            </FacebookShareButton>
                                                        </DropdownItem>
                                                        {/* <DropdownItem>Google +</DropdownItem> */}
                                                        <DropdownItem>
                                                            <TwitterShareButton url={`${this.state.protocol}//${this.state.host}/projects/${item.target.id}`} quote={item.target.name} hashtag={this.state.twitterHashTags} className="text-white p-0 text-decoration-none">
                                                                Twitter
                                                                </TwitterShareButton>
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => { this.setState({ shareByEmailModalToggle: true, projectId: item.target.id }) }}>By Email</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            <div className="px-1">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 text-dark" color="transparent">
                                                        <div className="thumbpin-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary-alt" right>

                                                        <DropdownItem tag="a" >
                                                            <div onClick={() => { this.addList('wantlist', item.target.id, item.target.name, contentName?.name) }}>{item.target?.pining_list?.want_this ? 'Added' : 'Add'} to Want list</div>
                                                        </DropdownItem>
                                                        <DropdownItem tag="a" >
                                                            <div onClick={() => { this.addList('favouritelist', item.target.id, item.target.name, contentName?.name) }}>{item.target?.pining_list?.favourite ? 'Added' : 'Add'} to Favorites</div>
                                                        </DropdownItem>
                                                        <DropdownItem tag="a">

                                                            <div onClick={() => { this.addList('watchlist', item.target.id, item.target.name, contentName?.name) }}>{item.target?.pining_list?.watch_this ? 'Added' : 'Add'} to Watch list</div>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <Media>
                                        <Media className="mr-3" left href={item.actor.redirect_url}>
                                            <Media className="img-circle _50x50" object src={item?.actor?.current_profile_pic?.thumbnail} alt={item.actor.first_name + ' ' + item.actor.last_name} />
                                        </Media>
                                        <Media body>
                                            <Media className="font-weight-normal ff-base fs-14 mb-2" heading>
                                                <a href={item.actor.redirect_url} className="text-reset font-weight-bold">{item.actor.first_name + ' ' + item.actor.last_name}</a> {item.verb} <a href={`projects/${item.target.id}`} class="text-reset font-weight-bold">{item.target.name}</a>
                                            </Media>

                                            <div className="text-tertiary text-left fs-14 mt-2 ff-alt">
                                                {item.time_stamp}
                                            </div>
                                        </Media>
                                    </Media>
                                </CardBody>
                            </Card>

                        )
                    })
                }
                else {
                    return ("No Result Found")
                }
            }
        }

        if (name === "other") {
            if (content && contentName?.name === "other") {
                let data = contentName.value;

                if (data && Array.isArray(data) && data.length > 0) {
                    return data.map((item, index) => {
                        return (

                            <Card className="text-dark bg-white mb-3" key={index}>
                                <CardBody>
                                    <div className="text-right mt-n2 mr-n2">
                                        <div className="d-inline-flex mx-n1">
                                            <div className="px-1">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 text-dark" color="transparent">
                                                        <div className="share-it-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary-alt" right>
                                                        <DropdownItem>
                                                            <FacebookShareButton url={`${this.state.protocol}//${this.state.host}/projects/${item.target.id}`} quote={item.target.name} hashtag={this.state.fbHashTag} className="text-dark text-decoration-none mx-2">
                                                                Facebook
                                                            </FacebookShareButton>
                                                        </DropdownItem>
                                                        {/* <DropdownItem>Google +</DropdownItem> */}
                                                        <DropdownItem>
                                                            <TwitterShareButton url={`${this.state.protocol}//${this.state.host}/projects/${item.target.id}`} quote={item.target.name} hashtag={this.state.twitterHashTags} className="text-dark text-decoration-none mx-2">
                                                                Twitter
                                                                </TwitterShareButton>
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => { this.setState({ shareByEmailModalToggle: true, projectId: item.target.id }) }}>By Email</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            <div className="px-1">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 text-dark" color="transparent">
                                                        <div className="thumbpin-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary-alt" right>

                                                        <DropdownItem tag="a" >
                                                            <div onClick={() => { this.addList('wantlist', item.target.id, item.target.name) }}>Add to Want list</div>
                                                        </DropdownItem>
                                                        <DropdownItem tag="a" >
                                                            <div onClick={() => { this.addList('favouritelist', item.target.id, item.target.name) }}>Add to Favorites</div>
                                                        </DropdownItem>
                                                        <DropdownItem tag="a">

                                                            <div onClick={() => { this.addList('watchlist', item.target.id, item.target.name) }}>Add to Watch list</div>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <Media>
                                        <Media className="mr-3" left href={item.actor.redirect_url}>
                                            <Media className="img-circle _50x50" object src={item?.actor?.current_profile_pic?.thumbnail} alt={item.actor.first_name + ' ' + item.actor.last_name} />
                                        </Media>
                                        <Media body>
                                            <Media className="font-weight-normal ff-base fs-14 mb-2" heading>
                                                <a href={item.actor.redirect_url} className="text-reset font-weight-bold">{item.actor.first_name + ' ' + item.actor.last_name}</a> {item.verb} <a href={`projects/${item.target.id}`} class="text-reset font-weight-bold">{item.target.name}</a>
                                            </Media>

                                            <div className="text-tertiary text-left fs-14 mt-2 ff-alt">
                                                {item.time_stamp}
                                            </div>
                                        </Media>
                                    </Media>
                                </CardBody>
                            </Card>

                        )
                    })
                }
                else {
                    return ("No Result Found")
                }

            }


        }

        if (name === "reviews") {
            if (content && contentName?.name === "reviews") {
                let data = contentName.value;

                if (data && data.length > 0) {
                    return ("REVIEWS")
                }
                else {
                    return ("No Result Found")
                }
            }
        }

        if (name === "comments") {
            if (content && contentName?.name === "comments") {
                let data = contentName.value;

                if (data && data.length > 0) {
                    return ("comments")
                }
                else {
                    return ("No Result Found")
                }
            }
        }
    }

    shareByEmail = (e) => {
        e.preventDefault();
        let email, user_name, id = "";
        let data = {
            email: this.state.share_email,
            user_name: this.state.share_name.replace(/[^a-zA-Z]/g, ""),
            id: this.state.projectId
        }
        if (data.email !== "" && data.email !== null && data.user_name !== "" && data.user_name !== null && data.id !== "" && data.id !== null) {

            callApi(`/api/crpshareByemail/?project_id=${data.id}&mix_uid=${user_name}_${data.id}_1597299301957&email=${data.email}&user_name=${data.user_name}`, "GET").then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    // removed console from here.
                }
            });
        }
        else {
            // removed console from here.
            return false;
        }
    }

    handleChange = (e) => {
        const value = e.target.value;
        this.setState({
            [e.target.name]: value
        }
        )


    }

    render() {
        const { validShare, items_pin_data } = this.state;
       
        return (
            <>
                <div>
                    <h3 className="text-dark fs-36 mb-3 text-center">My Connection's Activity</h3>
                    <Nav className="active-transparent non-active-normal active-bold" tabs>
                        <NavItem>
                            <NavLink className="fs-16 ff-base"
                                active={this.state.viewConnectActivity === "all"}
                                onClick={() => { this.getTabLink("all") }}
                            >All</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="fs-16 ff-base"
                                active={this.state.viewConnectActivity === "reviews"}
                                onClick={() => { this.getTabLink("reviews") }}
                            >
                                Reviews
                                        </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="fs-16 ff-base"
                                active={this.state.viewConnectActivity === "comments"}
                                onClick={() => { this.getTabLink("comments") }}>
                                Comments
                                        </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className="fs-16 ff-base"
                                active={this.state.viewConnectActivity === "other"}
                                onClick={() => { this.getTabLink("other") }}>
                                Other
                                        </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.viewConnectActivity}>
                        <TabPane tabId={this.state.viewConnectActivity}>
                            {this.getTabContent()}
                        </TabPane>
                    </TabContent>
                </div>

                {/* Share By Email */}
                <Modal size="sm" isOpen={this.state.shareByEmailModalToggle} toggle={() => this.setState({
                    shareByEmailModalToggle: !this.state.shareByEmailModalToggle
                })}>
                    <ModalHeader className="text-uppercase px-0" toggle={() => this.setState({
                        shareByEmailModalToggle: !this.state.shareByEmailModalToggle
                    })}>
                        Share by Email
                    </ModalHeader>
                    <Form onSubmit={this.shareByEmail}>
                        <ModalBody>

                            <FormGroup>
                                <Input className="primary" bsSize="sm" type="text" name="share_name" placeholder="Your name" required pattern="[A-Za-z]{1,32}" value={this.state.share_name} onChange={(e) => {
                                    this.handleChange(e);
                                }} />

                            </FormGroup>
                            <FormGroup>
                                <Input className="primary" bsSize="sm" required type="email" name="share_email" placeholder="Recipient's email" onChange={(e) => {
                                    this.handleChange(e);
                                }} value={this.state.share_email} />

                            </FormGroup>
                        </ModalBody>
                        <ModalFooter className="px-0">
                            <div className="text-right">
                                <Button color="primary">Share</Button>
                            </div>
                        </ModalFooter>
                    </Form>
                </Modal>
            </>
        )
    }
}
const mapState = (state) => (
    {
        tab_content: state.projects.tabs_content,
        fetch_items_pin_data: state.projects.listing_pins,
    }
);

const mapProps = (dispatch) => ({
    connectivity_tabs_content: (name) => dispatch(connectivity_tabs_content(name)),
    fetch_items_pin: (name) => dispatch(fetch_items_pin(name)),
});

export default connect(mapState, mapProps)(MyConnectionsActivity);
