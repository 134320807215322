// import { MoreResources, DisplayFormikState } from './helper';
import React from "react";
import { render } from "react-dom";
import { withFormik, Formik } from "formik";
import deepEqual from "lodash.isequal";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Form,
  Row,
  Col,
  FormText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { connect } from "react-redux";
import { get_captcha, signup } from "../../../actions/auth";
import { API_URL } from "../../../utils/constants";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const validationYup = Yup.object().shape({
  first_name: Yup.string()
      .max(10, "Maximum 10 characters!")
      .required("First Name is required!")
      .matches(/^[a-zA-Z]+$/, 'Only alphabets are allowed. No spaces, numbers or special characters.'),
  last_name: Yup.string()
      .max(10, "Maximum 10 characters!")
      .required("Last Name is required!")
      .matches(/^[a-zA-Z]+$/, 'Only alphabets are allowed. No spaces, numbers or special characters.'),
  email1: Yup.string()
      .email("Invalid email address!")
      .required("Email is required!"),
  email2: Yup.string()
      .email("Invalid email address!")
      .required("Email is required!")
      .oneOf([Yup.ref("email1"), null], "Emails must match"),
  password1: Yup.string()
      .min(8, "Minimum 8 characters!")
      .required("Password is required!")
      .matches(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$@^%&? "])[a-zA-Z0-9!#$@^%&?]{8,20}$/, "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character!"),
  password2: Yup.string()
      .min(8, "Minimum 8 characters!")
      .required("Password is required!")
      .oneOf([Yup.ref("password1"), null], "Passwords must match"),
  gender: Yup.string().required("Gender is required!"),
  captcha: Yup.string().required("Verification code is required!"),
  country: Yup.string().required("Country is required!"),
  zipcode: Yup.string()
      .required("Zipcode is required!")
      .when('country', (country, validationYup) => {
        return country === 'usa' ?
            validationYup.min(5, 'Ensure zipcode/postal code has 5 characters') :
            validationYup.min(6, 'Ensure zipcode/postal code has 6 characters');
      })
      .when('country', (country, validationYup) => {
        return country === 'usa' ?
            validationYup.max(5, 'Ensure zipcode/postal code has 5 characters') :
            validationYup.max(7, 'Ensure zipcode/postal code has 6 characters');
      })
      // .matches(/^([0-9]{5}|[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9])$/, "Zipcode is not valid!")
      // .matches(/^[a-z|A-Z|0-9]+(?: [a-z|A-Z|0-9]+)*$/, "Zipcode is not valid!")
      .when('country', (country, validationYup) => {
        return country === 'usa' ?
            validationYup.matches(/^[0-9]+$/, "Please provide 5 digit numeric zipcode/postal code for US and 6 character alphanumeric for Canada") :
            validationYup.matches(/^[a-z|A-Z|0-9]+(?: [a-z|A-Z|0-9]+)*$/, "Zipcode is not valid!");
      })
      .when('country', (country, validationYup) => {
        return country === 'usa' ?
            validationYup.test(
                'test-is-valid-zipcode',
                'Ensure zipcode/postal code has 5 characters',
                (value) => value?.replace(/ /g, "").length === 5,
            ) :
            validationYup.test(
                'test-is-valid-zipcode',
                'Ensure zipcode/postal code has 6 characters',
                (value) => value?.replace(/ /g, "").length === 6,
            );
      })
  ,
  tos: Yup.bool().oneOf([true], "You must agree to the terms to register!"),
});

class EmailSignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        first_name: "",
        last_name: "",
        email1: "",
        email2: "",
        password1: "",
        password2: "",
        gender: "",
        zipcode: "",
        tos: false,
        captcha: "",
        country: "usa",
      },
    };
  }

  handleOnClickRefreshCaptcha = () => {
    this.setState({
      ...this.state,
      formFields: {
        ...this.state.formFields,
        captcha: ''
      }
    }, () => {
      this.props.get_captcha();
    })
  };

  render() {
    let { formFields } = this.state;
    let {
      additionalInfo,
      captcha,
      signup,
      captcha_error,
      email_signup_response,
      openOnBoardingModalOne,
      email_signup_error
    } = this.props;

    return (
        <Formik
            initialValues={formFields}
            validationSchema={validationYup}
            onSubmit={(values, { setSubmitting }) => {
              let data = {
                ...values,
                email: values.email1,
                captcha_hash: captcha.new_cptch_key,
                from_web: true,
                dob_day: additionalInfo.dobDay,
                dob_month: months.indexOf(additionalInfo.dobMonth) + 1,
                dob_year: additionalInfo.dobYear,
              };
              signup(data).then((response) => {
                if (
                    response &&
                    Object.keys(response).length > 0
                ) {
                  this.props.getSignUpResponse(response)
                  openOnBoardingModalOne();
                }
              })

              setSubmitting(false);
            }}
            render={(props) => {
              const {
                values,
                touched,
                errors,
                initialValues,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;

              const hasChanged = !deepEqual(values, initialValues);
              const hasErrors = Object.keys(errors).length > 0;
              return (
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                              type="text"
                              name="first_name"
                              placeholder="First Name"
                              autoComplete={"false"}
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`primary bg-white ${errors.first_name && touched.first_name && "border border-danger"
                              }`}
                          />
                          {errors.first_name && touched.first_name && (
                              <div className="text-danger small mt-1">
                                {errors.first_name}
                              </div>
                          )}
                          {email_signup_error && email_signup_error.data?.first_name && email_signup_error.data?.first_name[0] && (
                              <div className="text-danger small mt-1">
                                {email_signup_error.data?.first_name[0]}
                              </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                              type="text"
                              name="last_name"
                              placeholder="Last Name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.last_name}
                              className={`primary bg-white ${errors.last_name &&
                              touched.last_name &&
                              "border border-danger"
                              }`}
                          />
                          {errors.last_name && touched.last_name && (
                              <div className="text-danger small mt-1">
                                {errors.last_name}
                              </div>
                          )}
                          {email_signup_error && email_signup_error.data?.last_name && email_signup_error.data?.last_name[0] && (
                              <div className="text-danger small mt-1">
                                {email_signup_error.data?.last_name[0]}
                              </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                              type="email"
                              name="email1"
                              placeholder="Your email address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email1}
                              className={`primary bg-white ${errors.email1 &&
                              touched.email1 &&
                              "border border-danger"
                              }`}
                          />
                          {errors.email1 && touched.email1 && (
                              <div className="text-danger small mt-1">
                                {errors.email1}
                              </div>
                          )}
                          {email_signup_error && email_signup_error.data?.email && email_signup_error.data?.email[0] && (
                              <div className="text-danger small mt-1">
                                {email_signup_error.data?.email[0]}
                              </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                              type="email"
                              name="email2"
                              placeholder="Reenter email address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email2}
                              className={`primary bg-white ${errors.email2 &&
                              touched.email2 &&
                              "border border-danger"
                              }`}
                          />
                          {errors.email2 && touched.email2 && (
                              <div className="text-danger small mt-1">
                                {errors.email2}
                              </div>
                          )}
                          {email_signup_error && email_signup_error.data?.email2 && email_signup_error.data?.email2[0] && (
                              <div className="text-danger small mt-1">
                                {email_signup_error.data?.email2[0]}
                              </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                              type="password"
                              name="password1"
                              placeholder="Enter Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password1}
                              className={`primary bg-white ${errors.password1 &&
                              touched.password1 &&
                              "border border-danger"
                              }`}
                          />
                          {
                            (!errors.password1 || !touched.password1) && (
                              <small className="fs-12">
                                Must have at least 8 characters with number and letter.
                              </small>
                            )
                          }
                          {errors.password1 && touched.password1 && (
                              <div className="text-danger small mt-1">
                                {errors.password1}
                              </div>
                          )}
                          {email_signup_error && email_signup_error.data?.password1 && email_signup_error.data?.password1[0] && (
                              <div className="text-danger small mt-1">
                                {email_signup_error.data?.password1[0]}
                              </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                              type="password"
                              name="password2"
                              placeholder="Confirm Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password2}
                              className={`primary bg-white ${errors.password2 &&
                              touched.password2 &&
                              "border border-danger"
                              }`}
                          />
                          {errors.password2 && touched.password2 && (
                              <div className="text-danger small mt-1">
                                {errors.password2}
                              </div>
                          )}
                          {email_signup_error && email_signup_error.data?.password2 && email_signup_error.data?.password2[0] && (
                              <div className="text-danger small mt-1">
                                {email_signup_error.data?.password2[0]}
                              </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                              type="select"
                              name="country"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country}
                              className={`primary bg-white ${errors.country &&
                              touched.country &&
                              "border border-danger"
                              }`}
                          >
                            {/*<option value="">Select Country</option>*/}
                            <option value="usa">United States</option>
                            <option value="can">Canada</option>
                          </Input>
                          {errors.country && touched.country && (
                              <div className="text-danger small mt-1">
                                {errors.country}
                              </div>
                          )}
                          {email_signup_error && email_signup_error.data?.country && email_signup_error.data?.country[0] && (
                              <div className="text-danger small mt-1">
                                {email_signup_error.data?.country[0]}
                              </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Input
                              type="text"
                              name="zipcode"
                              placeholder="Zipcode/Postal code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.zipcode}
                              // maxLength={7}
                              maxLength={values.country === 'usa' ? 5 : 7 }
                              className={`primary bg-white ${errors.zipcode &&
                              touched.zipcode &&
                              "border border-danger"
                              }`}
                          />
                          {errors.zipcode && touched.zipcode && (
                              <div className="text-danger small mt-1">
                                {errors.zipcode}
                              </div>
                          )}
                          {email_signup_error && email_signup_error.data?.zipcode && email_signup_error.data?.zipcode[0] && (
                              <div className="text-danger small mt-1">
                                {email_signup_error.data?.zipcode[0]}
                              </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <FormGroup>
                          <Row form>
                            <Col xs="auto">
                              <span className="font-weight-bold fs-14">Gender</span>
                              {errors.gender && touched.gender && (
                                  <div className="text-danger small mt-1">
                                    {errors.gender}
                                  </div>
                              )}
                            </Col>
                            <Col>
                              <div className="d-flex mx-2">
                                <div className="px-2">
                                  <FormGroup check>
                                    <Label className="font-weight-normal fs-14" check>
                                      <Input
                                          type="radio"
                                          name="gender"
                                          value="m"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                      />{" "}
                                      Male
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div className="px-2">
                                  <FormGroup check>
                                    <Label className="font-weight-normal fs-14" check>
                                      <Input
                                          type="radio"
                                          name="gender"
                                          value="f"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                      />{" "}
                                      Female
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div className="px-2">
                                  <FormGroup check>
                                    <Label className="font-weight-normal fs-14" check>
                                      <Input
                                          type="radio"
                                          name="gender"
                                          value="n"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                      />{" "}
                                      Prefer not to say
                                    </Label>
                                  </FormGroup>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label className="fs-14">Verification Code</Label>
                          <Input
                              type="text"
                              name="captcha"
                              placeholder="Enter the verification code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.captcha}
                              className={`primary bg-white ${errors.captcha &&
                              touched.captcha &&
                              "border border-danger"
                              }`}
                          />
                          {errors.captcha && touched.captcha && (
                              <div className="text-danger small mt-1">
                                {errors.captcha}
                              </div>
                          )}
                          {email_signup_error && email_signup_error.data?.captcha && email_signup_error.data?.captcha[0] && (
                              <div className="text-danger small mt-1">
                                {email_signup_error.data?.captcha[0]}
                              </div>
                          )}
                          <FormText color="dark" className="ff-alt">
                            Can't read the code? Click{" "}
                            <span
                                role="button"
                                onClick={this.handleOnClickRefreshCaptcha}
                            >
                        <FontAwesomeIcon icon="sync" />
                      </span>{" "}
                            to refresh
                          </FormText>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <img
                              height="70"
                              src={`${API_URL}${captcha && captcha.new_cptch_image
                                  ? captcha.new_cptch_image
                                  : "captcha/image/ee4317507282c6eeeeed34543c5607e0e4d1d39a/"
                              }`}
                              alt="captcha"
                          />
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="mb-3 fs-14">
                          <FormGroup check>
                            <Label check>
                              <Input
                                  type="checkbox"
                                  name="tos"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.tos}
                              />
                              {errors.tos && touched.tos && (
                                  <div className="text-danger small mt-1">
                                    {errors.tos}
                                  </div>
                              )}
                              <span className="ff-alt">
                          I accept&nbsp;
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="/terms"
                                    className="font-weight-bold text-dark"
                                >
                            Terms of Service,
                          </a>
                                &nbsp;
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="/policies"
                                    className="font-weight-bold text-dark"
                                >
                            Privacy Policy
                          </a>
                                &nbsp;and&nbsp;
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="/guidelines"
                                    className="font-weight-bold text-dark"
                                >
                            Content Guidelines.
                          </a>
                                &nbsp;
                        </span>
                            </Label>
                          </FormGroup>
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="text-center">
                          <Button
                              className="rounded"
                              color="primary"
                              style={{ minWidth: "300px", maxWidth: "100%" }}
                              type="submit"
                          >
                            Sign Up with Email
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
              );
            }}
        />
    );
  }
}

const mapState = (state) => ({
  captcha: state.auth.captcha,
  captcha_error: state.auth.captcha_error,
  email_signup_response: state.auth.email_signup_response,
  email_signup_error: state.auth.email_signup_error,
});
const mapProps = (dispatch) => ({
  get_captcha: () => dispatch(get_captcha()),
  signup: (data) => dispatch(signup(data)),
});
export default connect(mapState, mapProps)(EmailSignupForm);
