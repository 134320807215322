import { callApi, responseCodeValidator } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { getQueryString, checkIfHaveParams, randomStrings } from '../utils/validation';
import { get_discussions } from './discussionsTab';
import { get_reviews_listing } from './movies';
import { reduce } from "lodash";
import { rating_created_dispatch } from "./shared";
import { get_product_details_wall_data } from "./products";
import { fetch_professionals_data_list } from "./professionals";
export const GET_CONNECTIONS_ACTIVITY = "GET_CONNECTIONS_ACTIVITY";
export const FETCH_COLLAGE_ITEMS = "FETCH_COLLAGE_ITEMS";
export const FETCH_ALL_CATEGORIES = "FETCH_ALL_CATEGORIES";
export const FETCH_ALL_SUB_CATEGORIES = 'FETCH_ALL_SUB_CATEGORIES';
export const CLEAR_SUB_CATEGORIES = 'CLEAR_SUB_CATEGORIES';
export const FETCH_PRODUCT_LIST = 'FETCH_PRODUCT_LIST';
export const FETCH_BUSINESS_COLLAGE_SUB_CATEGORY = 'FETCH_BUSINESS_COLLAGE_SUB_CATEGORY';
export const BUSINESS_LOCATION_WEATHER = 'BUSINESS_LOCATION_WEATHER';
export const FETCH_LISTING_PINS = 'FETCH_LISTING_PINS';
export const FETCH_NEW_SUB_CATEGORIES = 'FETCH_NEW_SUB_CATEGORIES';
export const FETCH_ALL_SUB_CATEGORIE_ITEMS = 'FETCH_ALL_SUB_CATEGORIE_ITEMS';
export const FETCH_FRIENDS_SUB_CATEGORIE_ITEMS = 'FETCH_FRIENDS_SUB_CATEGORIE_ITEMS';
export const FETCH_HIGHLY_SUB_CATEGORIE_ITEMS = 'FETCH_HIGHLY_SUB_CATEGORIE_ITEMS';
export const FETCH_NEW_SUB_CATEGORIE_CHILD = 'FETCH_NEW_SUB_CATEGORIE_CHILD';

// Business Details
export const GET_BUSINESS_DETAILS_BY_ID = 'GET_BUSINESS_DETAILS_BY_ID';
export const GET_MEDIA_CHILDREN = 'GET_MEDIA_CHILDREN';
export const GET_SIMILLAR_BUSINESS = 'GET_SIMILLAR_BUSINESS';
export const GET_WORKING_EMPLOYEE = 'GET_WORKING_EMPLOYEE';
export const GET_CHART_STAR_RATINGS = 'GET_CHART_STAR_RATINGS';
export const GET_TIPS_AND_WARNINGS = 'GET_TIPS_AND_WARNINGS';
export const GET_BACK_TO_BUSINESS = 'GET_BACK_TO_BUSINESS';

// offers
export const GET_OTHER_OFFERS = 'GET_OTHER_OFFERS';
export const GET_CHECK_IN_OFFERS = 'GET_CHECK_IN_OFFERS';
export const GET_IN_STORE_OFFERS = 'GET_IN_STORE_OFFERS';

export const GET_BUSINESS_FLAGS = 'GET_BUSINESS_FLAGS';
export const GET_DISCUSS_THIS_PAGE = 'GET_DISCUSS_THIS_PAGE';
export const GET_MENU_ITEM = 'GET_MENU_ITEM';
export const GET_SECTION_MENU_ITEM = 'GET_SECTION_MENU_ITEM';
export const GET_CAPTCHA = 'GET_CAPTCHA';
export const POST_BUSINESS_SIGN_UP = 'POST_BUSINESS_SIGN_UP';
export const BUSINESS_WALL_DATA = 'BUSINESS_WALL_DATA';
export const GET_BUSINESS_QA_DROPDWON = 'GET_BUSINESS_QA_DROPDWON';
export const GET_BUSINESS_QA_DATA = 'GET_BUSINESS_QA_DATA';
export const GET_BUSINESS_RATING_BY_ID = "GET_BUSINESS_RATING_BY_ID";
export const GET_BUSINESS_REVIEW_DETAIL_BY_ID = "GET_BUSINESS_REVIEW_DETAIL_BY_ID";
export const GET_ADDITIONAL_ATTRIBUTES = "GET_ADDITIONAL_ATTRIBUTES";
export const FETCH_DATA_CATEGORIES = "FETCH_DATA_CATEGORIES";
export const FILTER_DATA_CATEGORIES = "FILTER_DATA_CATEGORIES";
export const GET_MAP_DATA_ITEM = "GET_MAP_DATA_ITEM";

export const GET_PROFESSIONAL_QA_DATA = 'GET_PROFESSIONAL_QA_DATA';
export const GET_SAVE_BUSINESS_RESPONSE = "GET_SAVE_BUSINESS_RESPONSE";

export const GET_HOURS_OF_OPERATION = "GET_HOURS_OF_OPERATION";
export const GET_AMENITIES_DATA = "GET_AMENITIES_DATA";
export const UPDATE_ADDITIONAL_INFO = "UPDATE_ADDITIONAL_INFO";
export const DELETE_ADDITIONAL_INFO = "DELETE_ADDITIONAL_INFO";
const IS_LOADING = "IS_LOADING";
export const PIN_LIST_STATUS_UPDATE = "PIN_LIST_STATUS_UPDATE";

let toastId = '';
// let toastId = React.useRef(null);


const common_fetch = ({ url, method, data, dispatch_function }) => {
    if (data === undefined) {
        data = {};
    }
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            callApi(url, method, data).then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    dispatch(dispatch_function(response));
                    return resolve();
                }
                else {
                    return resolve();
                }
            });
        });
    };
};

export const get_connections_activity_ = (data) => {
    return {
        type: GET_CONNECTIONS_ACTIVITY,
        payload: data,
    };
};


export const get_connections_activity = (url) => {
    return (dispatch) => {
        callApi(`${url}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_connections_activity_(response));
            }
        });
    };
};



export const collage_items_dispatch = (data) => ({ type: FETCH_COLLAGE_ITEMS, payload: data, });
export const collage_sub_category_dispatch = (data) => ({ type: FETCH_BUSINESS_COLLAGE_SUB_CATEGORY, payload: data, });
export const product_list_dispatch = (data) => ({ type: FETCH_PRODUCT_LIST, payload: data, });
export const reset_product_list = () => ((dispatch) => dispatch({ type: FETCH_PRODUCT_LIST, payload: {}, }));
export const fetch_collage_items = (...data) => {
    return common_fetch({
        url: getQueryString({ url: `/api/search/`, data }),
        method: "GET",
        dispatch_function:
            checkIfHaveParams({ data, param: 'showReview' }) ? product_list_dispatch :
                checkIfHaveParams({ data, param: 'trendingType' }) ? collage_sub_category_dispatch :
                    collage_items_dispatch
    });
};



export const share_by_email = (url, data) => {
    return (dispatch) => {
        callApi(url, "POST", data).then((response) => {
            if (response && responseCodeValidator(String(response.code))) {
                toast(`Details shared by email successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                return true;
            }
        });
    };
};

export const remove_from_list = (url, message, nextUrl,newContent) => {
    return (dispatch) => {
        callApi(`${url}`, "DELETE").then((response) => {
            if (response && response.code < 400) {
                if (message) {
                    toast(message, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });  
                }
                dispatch(fetch_professionals_data_list({ content_type__category: newContent, page: 1 }))
                if (nextUrl) {
                    return dispatch(get_connections_activity(nextUrl));
                } else {
                    return true;
                }
            } else {
                let message = response?.msg ? response.msg : "An error occurred";
                toast(message, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });  
            }
        });
    };
};

export const add_remove_to_list_ = (data) => ({ type: PIN_LIST_STATUS_UPDATE, payload: data, });
//New Function to add or remove to pin
export const add_remove_to_list = (url, message, nextUrl, data) => {
    let method = "DELETE";
    if (data && data.type && data.type != "") {
        method = "POST";
    }
    return (dispatch) => {
        callApi(`${url}`, method, data).then((response) => {
            if (response && response.msg) {
                toast(message || response.msg , {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });   
                return dispatch(add_remove_to_list_(response)) 
            }
            if (response && response.code < 400) {
                let messageNew = message;
                if (response.msg && response.msg !== "Saved successfully") {
                    messageNew = response.msg;
                }
                if (messageNew) {
                    toast(messageNew, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });  
                }
                if (nextUrl) {
                    return dispatch(get_connections_activity(nextUrl));
                } else {
                    return true;
                }
            }
        });
    };
};



export const add_to_list = (url, data, message, nextUrl,newContent) => {
    return (dispatch) => {
        callApi(`${url}`, "POST", data).then((response) => {
            if (response && response.msg) {
                toast(response.msg, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });   
                return dispatch(add_remove_to_list_(response)) 
            }
            if (response && response.code < 400) {
                let messageNew = message;
                if (response.msg && response.msg !== "Saved successfully") {
                    messageNew = response.msg;
                }
                if (messageNew) {
                    toast(messageNew, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });  
                }
                if (nextUrl) {
                    return dispatch(get_connections_activity(nextUrl));
                } else {
                    return true;
                }
            }
            dispatch(fetch_professionals_data_list({ content_type__category: newContent, page: 1 }))
        });
    };
};


export const all_categories_dispatch = (data) => ({ type: FETCH_ALL_CATEGORIES, payload: data, });
export const all_sub_categories_dispatch = (data) => ({ type: FETCH_ALL_SUB_CATEGORIES, payload: data, });
export const reset_sub_categories = () => ((dispatch) => (dispatch({ type: FETCH_ALL_SUB_CATEGORIES, payload: [] })));
export const fetch_all_categories = (...data) => {
    return common_fetch({
        url: getQueryString({ url: `/api/taxonomy/`, data }),
        method: "GET",
        dispatch_function: checkIfHaveParams({ data, param: 'enableSubChild' }) ? all_sub_categories_dispatch : all_categories_dispatch
    });
};

export const clear_collage_sub_categories = () => ((dispatch) => (dispatch({ type: FETCH_BUSINESS_COLLAGE_SUB_CATEGORY, payload: null })));
export const clear_sub_categories = () => ((dispatch) => (dispatch({ type: CLEAR_SUB_CATEGORIES, payload: [] })));


export const add_comment = (data, nextUrl) => {
    return (dispatch) => {
        callApi(`/api/reviewdiscussion/?review=${data.review}`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Comment added successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_connections_activity(nextUrl));
            }
        });
    };
};

export const business_detail_dispatch = (data) => ({ type: GET_BUSINESS_DETAILS_BY_ID, payload: data, });
export const business_detail_failiure_dispatch = (data) => ({ type: GET_BACK_TO_BUSINESS, payload: data, });
export const get_business_detail = (id) => {
    return (dispatch) => {
        callApi(`/api/business/${id}/`, "GET").then((response) => {
            if (response?.code == 404) {
                return dispatch(business_detail_failiure_dispatch(response))
            }

            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(business_detail_dispatch(response));
            }
        });
    };
};

// business loation wether
export const get_business_location_weather_dispatch = (data) => ({ type: BUSINESS_LOCATION_WEATHER, payload: data, });
export const get_business_location_weather = (data) => {
    return (dispatch) => {
        callApi(`/api/getweather/?qcity=${data.qcity}&qstate=${data.qstate}&qcountry=${data.qcountry}&qzip=${data.qzip}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_business_location_weather_dispatch(response));
            }
        });
    };
};

export const get_media_children_ = (data, mediaType) => ({ type: GET_MEDIA_CHILDREN, payload: data, mediaType: mediaType });
export const get_media_children = (category, type, subCategory) => {
    return (dispatch) => {
        let url
        if (subCategory) {
            url = `/api/taxonomy/?category=${category}&parent=${type}&sub_category=${subCategory}`
        } else {
            url = `/api/taxonomy/?category=${category}&parent=${type}`
        }
        callApi(url, "GET").then((response) => {
            if (response && response.code !== 400) {
                return dispatch(get_media_children_(response, type));
            }
        });
    };
};


export const simillar_business_dispatch = (data) => ({ type: GET_SIMILLAR_BUSINESS, payload: data, });
// export const get_simillar_business = ({ cat, id, name }) => {
//     return (dispatch) => {
//         callApi(`/api/search/?content_type=business&cat=${cat}&to_api=true&page=1&simmilar=true&listing=${id}&l_name=${name}`, "GET").then((response) => {
//             if (response && response.code !== 400 && response.code !== 500) {
//                 dispatch(get_chart_star_ratings(id));
//                 dispatch(get_tips_and_warnings(id));
//                 return dispatch(simillar_business_dispatch(response));
//             }
//         });
//     };
// };
export const get_simillar_business = ({ cat, id, name }) => {
    return (dispatch) => {
        callApi(`/api/business/?id=${id}&similar=business`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(get_chart_star_ratings(id));
                dispatch(get_tips_and_warnings(id));
                return dispatch(simillar_business_dispatch(response));
            }
        });
    };
};
// /api/business/?id=26049732&similar=business


export const chart_star_ratings_dispatch = (data) => ({ type: GET_CHART_STAR_RATINGS, payload: data, });
export const get_chart_star_ratings = (id) => {
    return (dispatch) => {
        callApi(`/api/detailsstarrating/?id=${id}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(chart_star_ratings_dispatch(response));
            }
        });
    };
};

export const tips_and_warnings_dispatch = (data) => ({ type: GET_TIPS_AND_WARNINGS, payload: data, });
export const get_tips_and_warnings = (id) => {
    return (dispatch) => {
        callApi(`/api/userguide/?entry_type=${id}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(tips_and_warnings_dispatch(response));
            }
        });
    };
};

export const update_business_detail = (id, data, message) => {
    return (dispatch) => {
        callApi(`/api/business/${id}/`, "PATCH", data).then((response) => {
            if (response && responseCodeValidator(`${response.code}`)) {
                if (response?.error) {
                    toast(`${response.error}!`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc"
                    });
                } else {
                    toast(`${message}!`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc"
                    });   
                }
                return dispatch(get_business_detail(id));
            } else {
                toast(`Something went wrong!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });  
            }
        });
    };
};

export const add_social_website = (id, data) => {
    return (dispatch) => {
        callApi(`/api/addsocialwebsite/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (response.error) {
                    toast(`${response.error}!`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc"
                    });
                } else {
                    toast(`Social media link added successfully!`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc"
                    });
                    return dispatch(get_business_detail(id));
                }
            }
        });
    };
};


export const add_hours_of_operations = (id, data) => {
    return (dispatch) => {
        callApi(`/api/hoursofoperation/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (response && response.code === 201) {
                    toast(`${data.businessName ? data.businessName : 'Hours of operations added successfully!'}`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc"
                    });
                }
                return dispatch(get_business_detail(id));
            }
        });
    };
};

export const get_hours_of_operations_dispatch = (data) => ({ type: GET_HOURS_OF_OPERATION, payload: data, });
export const get_hours_of_operations = (id) => {
    return (dispatch) => {
        callApi(`/api/hoursofoperation/?list_id=${id}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_hours_of_operations_dispatch(response));
            }
        });
    };
};

export const delete_listing_Info = (id, listingid) => {
    return (dispatch) => {
        callApi(`/api/deletelistingInfo/${listingid}/`, "DELETE",).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Social Media Link Deleted Successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_business_detail(id));
            }
        });
    };
};


export const get_menu_item_ = (data) => {
    return {
        type: GET_MENU_ITEM,
        payload: data,
    };
};

export const menuItemChangedStatus = (data) => {
    return {
        type: "MENU_ITEM_CHANGED",
        payload: data,
    };
};


export const get_menu_item = (url) => {
    return (dispatch) => {
        callApi(`${url}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_menu_item_(response));
            }
        });
    };
};

export const get_section_menu_item_ = (data) => {
    return {
        type: GET_SECTION_MENU_ITEM,
        payload: data,
    };
};


export const get_section_menu_item = (url) => {
    return (dispatch) => {
        callApi(`${url}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_section_menu_item_(response));
            }
        });
    };
};

export const add_menu = (id, data) => {
    return (dispatch) => {
        callApi(`/api/getmenu/?id=${id}`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                const randomNo = Math.random()
                dispatch(menuItemChangedStatus(randomNo))
                toast(`You added menu item to this listing page.`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_business_detail(id));
            }
        });
    };
};

export const add_media_to_gallery = (data) => {
    return (dispatch) => {
        callApi(`/api/improve/?type=&type=${data.type}`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (data.galleryType) {
                    toast(`Media uploaded successfully.`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc"
                    });
                }

                if (!data.galleryType) {
                    return dispatch(get_business_detail(data.entries));
                }
            }
        });
    };
};

export const post_tips_and_warnings = (data) => {
    let ToastMessage = "";
    if (data.type === 'tips') {
        if (data.editform) {
            ToastMessage = `You have updated a tip to ${data.businessName}`;
        } else {
            ToastMessage = `You added a tip to  ${data.businessName}`;
        }
    } else {
        if (data.editform) {
            ToastMessage = `You have updated a warning to ${data.businessName}`;
        } else {
            ToastMessage = `You added a warning to  ${data.businessName}`;
        }
    }


    return (dispatch) => {
        callApi(`/api/userguide/?entry_type=`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(ToastMessage, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                if (data?.user_entry) dispatch(get_tips_and_warnings(data.user_entry));
            }
        });
    };
}


export const delete_tips_and_warnings = ({ deleteId, id }) => {
    return (dispatch) => {
        callApi(`/api/userguide/${deleteId}/`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Record Deleted Successfully!`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                dispatch(get_tips_and_warnings(id));
            }
        });
    };
};


export const other_offers_dispatch = (data) => ({ type: GET_OTHER_OFFERS, payload: data, });
export const check_in_offers_dispatch = (data) => ({ type: GET_CHECK_IN_OFFERS, payload: data, });
export const in_store_offers_dispatch = (data) => ({ type: GET_IN_STORE_OFFERS, payload: data, });

export const fetch_other_offers = ({ listing_id, offer_type }) => {
    return (dispatch) => {
        callApi(`/api/specialoffer/?listing_id=${listing_id}&offer_type=${offer_type}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (offer_type == "Check In Offer") dispatch(check_in_offers_dispatch(response))
                if (offer_type == "In Store Coupon") dispatch(in_store_offers_dispatch(response))
                if (offer_type == "Other Offer") dispatch(other_offers_dispatch(response))
            }
        });
    };
}

export const fetch_working_employee_ = (data) => ({ type: GET_WORKING_EMPLOYEE, payload: data, });
export const get_working_employee = ({ business_id }) => {
    return (dispatch) => {

        callApi(`/api/professionaluserentries/?reviews_userentries__id=${business_id}&page=1`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {

                dispatch(fetch_working_employee_(response))
            }
        });
    };
}


export const post_other_offers = ({ data, listing_id, offer_type }) => {
    return (dispatch) => {
        callApi(`/api/specialoffer/?listing_id=&offer_type=${offer_type}`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Record Added Successfully!`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                dispatch(fetch_other_offers({ listing_id, offer_type }));
            }
        });
    };
}

export const post_people_working = ({ data, business_id }) => {
    return (dispatch) => {
        let listing_id = data.associated_with;
        callApi(`/api/professional/?listing_id=&type=`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`You added a new employee to this business!`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                dispatch(get_working_employee({ business_id: listing_id }));
            }else{
                if((typeof response.data === 'object')){
                    Object.keys(response.data)?.map(item=>{
                        return toast(`${item?.replace(/_/g, " ")} : ${response.data?.[item]?.[0]}`, {
                            autoClose: 3500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        }); 
                    })
                }
            }
        })
    };
}

/*
// 7799918

Request URL: https://wikireviews.com/api/specialoffer/?listing_id=7799918&offer_type=Other%20Offer
Request Method: GET
Status Code: 200 OK

Request URL: https://wikireviews.com/api/specialoffer/?listing_id=&offer_type=Other%20Offer
Request Method: POST

claimDealOption: "Not Applicable"
endDate: "Enter Expiration date"
from_date: "2020-8-13"
offer_type: "Other Offer"
other_offer: "other_offer"
reviews_userentries: 7799918
special_offer: "Test Offer"
startDate: "Enter Start date"
terms_details_exclusions: "test"
to_date: "2020-8-19"

*/

export const get_business_flags_ = (data) => ({ type: GET_BUSINESS_FLAGS, payload: data, });
export const get_business_flags = () => {
    return (dispatch) => {
        callApi(`/api/flags/?type=flaglist&category=business&flag_id=&object_id=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_business_flags_(response));
            }
        });
    };
};


export const post_discuss_this_page = (data) => {
    let url = `/api/discussion/?entity=${data.user_entry}&page=1`;
    return (dispatch) => {
        callApi(`/api/discussion/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Discussion Created Successfully!`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                dispatch(get_discussions(url));
                dispatch(get_discuss_this_page({ id: data.user_entry, pageId: 1 }));
            }
        });
    };
};


export const post_comment_on_offers = (data) => {
    return (dispatch) => {
        callApi(`/api/specialoffercomments/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Comment Added Successfully!`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
            }

        })
    }
}

export const update_business_flag = (data) => {
    return (dispatch) => {
        callApi(`/api/flags/?type=&category=&flag_id=&object_id=`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`You have flagged content for ${data.message}!`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
            } else if (response && response.code === 400) {
                toast(`You have already flagged this content!`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
            }
        });
    };
}

export const discuss_this_page_dispatch = (data) => ({ type: GET_DISCUSS_THIS_PAGE, payload: data });
export const get_discuss_this_page = ({ id, pageId, type = 'discussion' }) => {
    return (dispatch) => {
        callApi(`/api/${type}/?entity=${id}&page=${pageId}`, "GET").then((response) => {
            dispatch(discuss_this_page_dispatch(response));
        })
    }
}

export const dispatch_captcha = (data) => ({ type: GET_CAPTCHA, payload: data });
export const getCaptcha = () => {
    return (dispatch) => {
        callApi(`/api/getcapcha/`, "GET").then((response) => {
            dispatch(dispatch_captcha(response));
        })
    }
}

export const dispatch_business_items = (data) => ({ type: POST_BUSINESS_SIGN_UP, payload: data });
export const post_business_sign_up = (data) => {
    return (dispatch) => {
        callApi(`/api/signup/?type=biz/`, "POST", data).then((response) => {
            dispatch(dispatch_business_items(response));
        })
    }
}

export const business_wall_dispatch = (data) => ({ type: BUSINESS_WALL_DATA, payload: data })
export const get_business_wall_data = ({ id, filter_type, page, force }) => {
    return (dispatch) => {
        try {
            callApi(`/api/bizownerfeeds/?id=${id}&filter_type=${filter_type}&listing=true&page=${page}`, "GET").then((response) => {
                response['filter_type'] = filter_type ? filter_type : 'all';
                response['page'] = page;
                if (force) {
                    response['force'] = true;
                }
                dispatch(business_wall_dispatch(response));
            })
        }
        catch (e) {
            console.log(e)
        }
    }
}

/*
POST : https://wikireviews.com/api/discussion/
{"body":"Test","title":"Test !!!!","user_entry":"7799918","messagemedia_set":[]}

GET : https://wikireviews.com/api/discussion/?entity=7799918&page=1

*/

// Get QA drop down
export const get_business_QA_dropdown_ = (data) => ({ type: GET_BUSINESS_QA_DROPDWON, payload: data, });
export const get_business_QA_dropdown = (id) => {
    return (dispatch) => {
        callApi(`/api/questions/?entity=${id}&filter=True&options=true`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_business_QA_dropdown_(response));
            }
        });
    };
};

// Get QA data
export const get_business_QA_data_ = (data) => ({ type: GET_BUSINESS_QA_DATA, payload: data, });
export const get_business_QA_data = (id) => {
    return (dispatch) => {
        // callApi(`/api/questions/${id}/?entity=`, "GET").then((response) => {
        callApi(`/api/questions/?entity=${id}&category&page=1&qid=null`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_business_QA_data_(response));
            }
        });
    };
};

export const sort_business_QA_data = (id, sortByID, type = 'business') => {
    let questionType = 'questions';
    if (!['business', 'movies', 'products'].includes(type)) {
        questionType = 'professionalquestions';
    }
    return (dispatch) => {
        callApi(`/api/${questionType}/?rating_tax_id=${sortByID}&type=${type}&entity=${id}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return (questionType === 'questions') ? dispatch(get_business_QA_data_(response)) : dispatch(get_professional_QA_data_(response));
            }
        });
    };
};

export const ask_something_question = (id, data) => {
    const apiUrl = data.qaType ? `/api/professionalquestions/?entity=&sortby=` : `/api/questions/?entity=`;
    return (dispatch) => {
        callApi(apiUrl, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (response?.msg) {
                    toastCall(response?.msg);
                } else {
                    toastCall(`Your post has been submitted successfully.`);
                }
                return data.qaType ? dispatch(get_professional_QA_data(id)) : dispatch(get_business_QA_data(id));
            }
        });
    };
};

// edit question

export const business_edit_quesiton = (id, msgid, data) => {
    const apiUrl = data.qaType ? `/api/professionalquestions/${msgid}/?entity=&sortby=` : `/api/feeds/${msgid}/?entity=`;
    return (dispatch) => {
        callApi(apiUrl, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall(`Q & A  updated successfully!`);
                return data.qaType ? dispatch(get_professional_QA_data(id)) : dispatch(get_business_QA_data(id));
            }
        });
    }
}


export const save_ratings_vote = (data) => {
    const formdata = new FormData();
    formdata.append("csrfmiddlewaretoken", data.csrfmiddlewaretoken);
    formdata.append("score", data.score);
    formdata.append("content_type", data.content_type);
    formdata.append("object_pk", data.object_pk);
    formdata.append("key", data.key);
    formdata.append("timestamp", data.timestamp);
    formdata.append("security_hash", data.security_hash);
    formdata.append("honeypot", data.honeypot);
    formdata.append("rate_unq_key", data.rate_unq_key);
    return (dispatch) => {
        callApi(`/ratings/vote/`, "POST", formdata).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                // toast(`Record Updated Successfully!`, {
                //     autoClose: 3500,
                //     className: "black-background",
                //     bodyClassName: "red-hunt",
                //     progressClassName: "cc",
                // });

            }
        });
    };
};


export const remove_ratings_vote = (data) => {
    return (dispatch) => {
        callApi(`/api/removerating/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                // removed console from here.
            }
        });
    };
};

export const save_ratings_vote_person = ({ name, vote, personId }) => {
    const date = new Date();
    const formdata = new FormData();
    formdata.append("csrfmiddlewaretoken", "6f3vESbpPooPZMSx5PQmVNYITSeAlFgkYyVY7FRssDSN6Mm4dBLhcil59wvKWGlY");
    formdata.append("score", vote);
    formdata.append("content_type", 'reviews.reviewsprofessionaluserentriesmapping');
    formdata.append("object_pk", personId);
    formdata.append("key", name);
    formdata.append("timestamp", date.getTime());
    formdata.append("security_hash", 'fada4d078151de0587e0bc68c73cc5049ee50305');
    formdata.append("honeypot", '');
    formdata.append("rate_unq_key", 'NXLZ3C');
    return (dispatch) => {
        callApi(`/ratings/vote/`, "POST", formdata).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                // removed console from here.
            }
        });
    };
};

export const publish_post = (data, businessID) => {
    return (dispatch) => {
        // if (data.review.length > 50) {
        callApi(`/api/reviewslisting/?entry=&user=&asset=`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                let message = "Review Published Successfully!";
                if (data.is_draft) {
                    message = "Review Saved Successfully!";
                }
                toastCall(`${message}`);
                dispatch(get_business_review_detail(data.associated_to, 'new'));
                const randomNo = Math.random()
                dispatch(rating_created_dispatch(randomNo))
                dispatch(get_business_detail(businessID));
            }
        });
        // } else {
        //     toastCall(`review should be 50 Characters `);
        // }
    };
};



export const MyProfilepublish_post = (data, name) => {
    return (dispatch) => {
        if (data.review.length > 50) {
            callApi(`/api/reviewslisting/?entry=&user=&asset=`, "POST", data).then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    let message = `You wrote a follow up review of ${name}`;
                    if (data.is_draft) {
                        message = `You saved a review of ${name}`;
                    }
                    toastCall(`${message}`);
                    dispatch(get_business_review_detail(data.associated_to, 'new'));
                }
            });
        }
        // else {
        //     toastCall(`review should be 50 Characters `);
        // }
    };
};

//delete user feed
export const business_qa_delete_userfeed = (id, msgid, type) => {
    return (dispatch) => {
        callApi(`/api/deleteuserfeeds/${msgid}/`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                type == "answer" && toastCall(`Your answer has been deleted.`);
                type == "question" && toastCall(`Your question has been deleted.`);
                return dispatch(get_business_QA_data(id));
            }
        });
    }
}

export const business_rating_dispatch = (data) => ({ type: GET_BUSINESS_RATING_BY_ID, payload: data, });
export const fetch_detailedstarrating = (id) => {
    return (dispatch) => {
        callApi(`/api/detailedstarrating/?entity=${id}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(business_rating_dispatch(response));
            }
        });
    };
};

export const business_review_detail_dispatch = (data) => ({ type: GET_BUSINESS_REVIEW_DETAIL_BY_ID, payload: data, });
export const get_business_review_detail = (id, filter, searchValue) => {
    
    if (filter === '') {
        return (dispatch) => {
            let url =`/api/reviews/?entry=&associated_to=${id}`;
            if(searchValue) url = `/api/reviews/?asset=&page=1&review=&review_type=All&search=${searchValue}&entry=${id}`;

            if(searchValue || searchValue==="") dispatch(business_review_detail_dispatch([]));

            dispatch(setIsLoadingBusiness(true))
            callApi(url, "GET").then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    dispatch(setIsLoadingBusiness(false))
                    return dispatch(business_review_detail_dispatch(response));
                }
            });
        };

    }
    else {
        return (dispatch) => {
            callApi(`/api/reviews/?entry=&associated_to=${id}&searchTerm=${filter}`, "GET").then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    return dispatch(business_review_detail_dispatch(response));
                }
            });
        };
    }
};

export const edit_review = (id, data) => {
    return (dispatch) => {
        callApi(`/api/reviews/${id}/`, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Review Updated Sucessfully.`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                const randomNo = Math.random()
                dispatch(rating_created_dispatch(randomNo))
                return dispatch(get_business_review_detail(data.associated_with, 'new'));
            }
        });
    };
};

//reply to questinon
export const reply_to_question = (id, msgId, data) => {
    const apiUrl = data.qaType ? `/api/professionalquestions/${msgId}/?entity=&sortby=` : `/api/questions/${msgId}/?entity=`;
    // const apiUrl = `/api/questions/${msgId}/?entity=`;
    return (dispatch) => {
        callApi(apiUrl, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall(`Answer added successfully!`);
                return data.qaType ? dispatch(get_professional_QA_data(id)) : dispatch(get_business_QA_data(id));
            }
        });
    };
}


export const submit_rating_qa = (data, type) => {
    if (type === 'question') {
        return (dispatch) => {
            toastCall(`question rating added successfully!`);
        }
    }
    if (type === 'answer') {
        return (dispatch) => {
            toastCall(`answer rating added successfully!`);
        }
    }
}

export const submit_qa_reply = ({data,id,type}) => {
    return (dispatch) => {
        callApi(`/api/feeds/${id}/?entity=`, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
              let msg = 'Action Performed';
              
              if (type == 'add_reply') {
                msg = 'You replied to answer.'
              }
              if (type == 'edit_reply') {
                msg = 'Your reply has been updated.'
              }
              toast(`${msg}`, {
                autoClose: 2500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
              });
              return dispatch(get_business_QA_data(data.user_entry));
            }
          });
    }
}

const toastCall = (message) => {
    return (
        toast(message, {
            autoClose: 3500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
        })
    );
}

export const post_wall_comments = ({ action_id, body, business_id, filter_type }) => {
    return (dispatch) => {
        callApi(`/api/otherfeeddiscussion/`, "POST", { action_id, body }).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(get_business_wall_data({ id: business_id, filter_type, page: 1, force: true }));
                dispatch(get_product_details_wall_data({ id: business_id, filter_type: filter_type, page: 1, force: true }))
                toastCall('Comment Added!');
            }
        });
    };
}


export const delete_wall_comment = ({ action_id, business_id, filter_type }) => {
    return (dispatch) => {
        callApi(`/api/deleterecord/?entityid=${action_id}&entity=others_comment`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall('Comment Deleted!');
                dispatch(get_business_wall_data({ id: business_id, filter_type, page: 1, force: true }));
            }
        });
    };
}

export const put_wall_reply = ({ discussion_id, body, business_id, filter_type, edit }) => {
    return (dispatch) => {
        let data = { discussion_id, body };
        if (edit) { data.edit = true; }
        callApi(`/api/otherfeeddiscussion/?`, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall('Reply Added!');
                dispatch(get_business_wall_data({ id: business_id, filter_type, page: 1, force: true }));
                dispatch(get_product_details_wall_data({ id: business_id, filter_type: filter_type, page: 1, force: true }))
            }
        });
    };
}


export const fetch_discuss_this_page_items = ({ action_id, business_id, filter_type }) => {
    return (dispatch) => {
        callApi(`/api/deleterecord/?entityid=${action_id}&entity=others_comment`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall('Comment Deleted!');
                dispatch(get_business_wall_data({ id: business_id, filter_type, page: 1, force: true }));
            }
        });
    };
}

export const post_update_comment = (type, data) => {
    return (dispatch) => {
        callApi(`/api/reviewdiscussion/?review=${data.review}`, type, data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                // get_business_review_detail(id, filter)
                toastCall(type === "POST" ? 'Comment Posted!' : 'Comment Update!');
            }
        });
    };
}

export const delete_review_comment = (action_id) => {

    return (dispatch) => {
        callApi(`/api/deleterecord/?entityid=${action_id}&entity=comment`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall('Comment Deleted!');
            }
        });
    };
}

export const get_reaction_comment = (action_id) => {

    return (dispatch) => {
        callApi(`/get_helpfull_count_of_review/?review_id=${action_id}`, "GET").then((response) => {
            // if (response && response.code !== 400 && response.code !== 500) {
            //     toastCall('Comment Deleted!');
            // }
        });
    };
}

export const delete_post_comment = (action_id, id, isTrue) => {

    return (dispatch) => {
        callApi(`/api/reviews/${action_id}/`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (isTrue) {
                    toastCall('Review Deleted!');
                } else {
                    toastCall('Comment Deleted!');
                }
                dispatch(get_business_review_detail(id, 'new'));
                dispatch(get_business_detail(id));
            }
        });
    };
}

export const send_email = (sharer_name, email, shareByEmailId) => {

    return (dispatch) => {
        callApi(`/shareByemail/?review_id=${shareByEmailId}&email=${email}&user_name=${sharer_name}`, "GET").then((response) => {
            // if (response && response.code !== 400 && response.code !== 500) {
            //     toastCall('Comment Deleted!');
            // }
        });
    };
}

export const post_special_offer_comment = ({ data, listing_id, offer_type }) => {
    return (dispatch) => {
        callApi(`/api/specialoffercomments/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall('Comment Added!');
                dispatch(fetch_other_offers({ listing_id, offer_type }));
            }
        });
    }
}

export const put_special_offer = ({ id, data, listing_id, offer_type }) => {
    return (dispatch) => {
        callApi(`/api/specialoffer/${id}/`, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall('Data Updated!');
                dispatch(fetch_other_offers({ listing_id, offer_type }));
            }
        });
    }
}

export const delete_special_offer = ({ id, listing_id, offer_type }) => {
    return (dispatch) => {
        callApi(`/api/specialoffer/${id}/`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall('Data Deleted Successfully!');
                dispatch(fetch_other_offers({ listing_id, offer_type }));
            }
        });
    }
}


export const get_id_from_slug = ({ type, slug }) => {
    return (dispatch) => {
        callApi(`/api/get_id_from_slug/?type=${type}&slug=${slug}`, "GET").then((response) => {
            if (response) {
                dispatch(business_detail_failiure_dispatch(response))
            }
        });
    }
}

export const get_additional_attributes_ = (data) => {
    return {
        type: GET_ADDITIONAL_ATTRIBUTES,
        payload: data,
    };
};

export const get_additional_attributes = (type) => {
    return (dispatch) => {
        callApi(`/api/additionalattributes/?content_type_id=${type}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_additional_attributes_(response.results));
            }
        });
    };
};

export const filter_with_additional_attributes = (url, data) => {
    return (dispatch) => {
        callApi(`${url}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (response && Array.isArray(response)) {
                    response = response.map(i => parseInt(i));
                    let newData = {
                        count: response.length,
                        next: null,
                        previous: null,
                        results: data.filter(i => response.includes(i.id))
                    };
                    return dispatch(product_list_dispatch(newData));
                }
            }
        });
    };
};

export const post_review_group = (id, data, type) => {
    return (dispatch) => {
        let group_type = 'reviewgroup';
        if (["actors", "accountants", "lawyers", "dentists", "doctors"].includes(type)) {
            group_type = 'professionalreviewgroup';
        }

        callApi(`/api/${group_type}/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (type === "movies") {
                    dispatch(get_reviews_listing(id));
                } else {
                    dispatch(get_business_review_detail(id, 'new'))
                }
            }
        });
    }
}

export const pins_dispatch_data = (data) => ({ type: FETCH_LISTING_PINS, payload: data });
export const fetch_items_pin = (id) => {
    return (dispatch) => {
        callApi(`/api/pins/?type=&user=&listing_id=${id}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                response['id'] = id;
                dispatch(pins_dispatch_data(response))
            }
        });
    }
}

export const sub_categories_dispatch = (data) => ({ type: FETCH_NEW_SUB_CATEGORIES, payload: data });
export const fetch_sub_categories = ({ sub_cat, level }) => {
    return (dispatch) => {
        callApi(`/api/taxonomy/?category=business&depth=false&delsel=&ancestor=&sub_cat=${sub_cat}&level=${level}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(sub_categories_dispatch(response))
            }
        });
    }
}

export const clear_sub_child_categories = () => ((dispatch) => (dispatch({ type: FETCH_NEW_SUB_CATEGORIE_CHILD, payload: [] })));
export const sub_categorie_child_dispatch = (data) => ({ type: FETCH_NEW_SUB_CATEGORIE_CHILD, payload: data });
export const fetch_sub_child_categories = ({ category, level }) => {
    return (dispatch) => {
        callApi(`/api/taxonomy/?category=${category}&depth=true&delsel=&ancestor=&sub_cat=&level=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(sub_categorie_child_dispatch(response))
            }
        });
    }
}

export const all_sub_categories_items_dispatch = (data) => ({ type: FETCH_ALL_SUB_CATEGORIE_ITEMS, payload: data });
export const fetch_all_sub_categories_items = ({ cat, page }) => {
    return (dispatch) => {
        callApi(`/api/search/?content_type=business&cat=${cat}&to_api=true&showReview=true&cPagebest=true&location=true&page=${page}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(all_sub_categories_items_dispatch(response))
            }
        });
    }
}


export const friends_sub_categories_items_dispatch = (data) => ({ type: FETCH_FRIENDS_SUB_CATEGORIE_ITEMS, payload: data });
export const fetch_friends_sub_categories_items = ({ cat, page }) => {
    return (dispatch) => {
        callApi(`/api/friendspick/?content_type=business&cat=${cat}&to_api=true&showReview=true&location=false&page=${page}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(friends_sub_categories_items_dispatch(response))
            }
        });
    }
}


export const highly_sub_categories_items_dispatch = (data) => ({ type: FETCH_HIGHLY_SUB_CATEGORIE_ITEMS, payload: data });
export const fetch_highly_sub_categories_items = ({ cat, page }) => {
    return (dispatch) => {
        callApi(`/api/search/?content_type=business&cat=${cat}&to_api=true&showReview=true&location=true&page=${page}&recommends=true`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(highly_sub_categories_items_dispatch(response))
            }
        });
    }
}

export const categories_dispatch = (data) => ({ type: FETCH_DATA_CATEGORIES, payload: data });
export const fetch_categories_data = (cate, sub_cat, type, index) => {
    return (dispatch) => {
        callApi(`/api/taxonomy/?category=${cate}&sub_cat=${sub_cat}&${type}=true`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                if (response[0]) {
                    response[0]['isTypes'] = type;
                    response[0]['index'] = index;
                }
                dispatch(categories_dispatch(response))
            }
        });
    }
}

export const filter_categories_dispatch = (data) => ({ type: FILTER_DATA_CATEGORIES, payload: data });
export const filter_categories_data = ({ category, sub_cat, level }) => {
    return (dispatch) => {
        callApi(`/api/filter_taxonomy/?category=${category}&depth=false&delsel=&ancestor=&sub_cat=${sub_cat}&level=${level}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(filter_categories_dispatch(response))
            }
        });
    }
}

export const clear_category = () => {
    return (dispatch) => {
        dispatch(filter_categories_dispatch([]));
    };
};

export const clear_subcategory = () => {
    return (dispatch) => {
        dispatch(categories_dispatch([]));
    };
};

export const update_payment_options = (userId, data) => {
    return (dispatch) => {
        callApi(
            `/api/corporatepaymentoptions/?corporatelistingid=${userId}`,
            "POST",
            data
        ).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall('Payment Options updated successfully!!');
                return dispatch(get_business_detail(userId));
            }
        });
    };
};

export const send_message_business = (data) => {
    return (dispatch) => {
        callApi(
            `/api/messagetobizowner/`,
            "POST",
            data
        ).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall('Message Send successfully!!');
            }
        });
    };
};

export const get_map_data_ = (data) => ({ type: GET_MAP_DATA_ITEM, payload: data });
export const get_map_data = (mapData) => {
    return (dispatch) => {
        if (mapData) {
            return dispatch(get_map_data_(mapData));
        }
    };
};

// https://wikireviews.com/api/taxonomy/?category=459&depth=true&delsel=&ancestor=&sub_cat=&level=&enableSubChild=true

// callApi(`/api/taxonomy/?category=business&depth=false&delsel=&ancestor=&sub_cat=${sub_cat}&level=${level}`, "GET").then((response) => {


export const offer_helpful_or_not = (businessId, offerType, data) => {
    return (dispatch) => {
        callApi(`/api/offergroup/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(fetch_other_offers({ listing_id: businessId, offer_type: offerType }));
            }
        });
    }
}

// Get QA data
export const get_professional_QA_data_ = (data) => ({ type: GET_PROFESSIONAL_QA_DATA, payload: data, });
export const get_professional_QA_data = (id) => {
    return (dispatch) => {
        callApi(`/api/professionalquestions/?entity=${id}&page=1`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_professional_QA_data_(response));
            }
        });
    };
};

//delete user feed
export const professional_qa_delete_userfeed = (id, msgid, type) => {
    return (dispatch) => {
        callApi(`/api/profeesionaldeleteuserfeed/?id=${msgid}&type=${type}`, "DELETE").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toastCall(`Your ${type} has been deleted successfully!`);
                return dispatch(get_professional_QA_data(id));
            }
        });
    }
}

export const get_response_business_ = (data) => {
    return {
        type: GET_SAVE_BUSINESS_RESPONSE,
        payload: data,
    };
};

// function Msg({ uid }){
//     return <span style={{color:'red'}}>{uid}</span>;
//   }

export const add_business = (data) => {
    return (dispatch) => {
        callApi(`/api/business/?`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                localStorage.setItem('openModal', true);
                // toast(<Msg uid={"Help Verify this business. Cast your vote: 👎 👍 (Help us remove fraudulent/outdated listings.)"}/>, {
                //     autoClose: 3500,
                //     className: "black-background",
                //     bodyClassName: "red-hunt",
                //     progressClassName: "cc",
                // });
                return dispatch(get_response_business_(response));
            }
        });
    };
};

export const get_amenities_options_action = (data) => {
    return {
        type: GET_AMENITIES_DATA,
        payload: data,
    };

}

export const get_amenities_options = (id) => {
    return (dispatch) => {
        callApi(            
            `/api/addinfocategorymultiple/?id=${id}`,
            "GET"
        ).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_amenities_options_action(response));
            }
        });
    };
}

export const update_additional_info_ = (data) => ({
    type: UPDATE_ADDITIONAL_INFO,
    payload: "updated",
})

export const update_additional_info = (data, entry, type) => {
    return (dispatch) => {
        callApi(`/api/business/${entry}/`, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Info updated successfully!`, {
                    autoClose: 2000,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                dispatch(get_business_detail(entry));
                return dispatch(update_additional_info_("updated"));

            }
        });
    };
};

export const delete_additional_info_ = (data) => ({ type: DELETE_ADDITIONAL_INFO, payload: "updated", })

export const delete_additional_info = (data, entry, type) => {
    return (dispatch) => {
        callApi(`/api/business/${entry}/`, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Info deleted successfully!`, {
                    autoClose: 2000,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });

                dispatch(get_business_detail(entry));
                return dispatch(update_additional_info_("updated"));
            }
        });
    };
};

export const delete_additional_info_new = (data, entry, type) => {
    return (dispatch) => {
        callApi(`/api/business/${entry}/`, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(get_business_detail(entry));
                return dispatch(update_additional_info_("updated"));
            }
        });
    };
};

export const add_cite_source = (data) => {
    return (dispatch) => {
        callApi("/api/cite_source/", "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                let message = "Thanks for giving Reviews!";
                toastId = toast.update(toastId, {
                    render: message,
                    // type: toast.TYPE.INFO,
                    autoClose: 5000,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                //   return dispatch(get_my_privacy_list());
            }
        });
    };
};

export const recommend_people = (data, peopleId, businessId) => {
    return (dispatch) => {
        callApi(`/api/recommendbusinessemployee/?id=${peopleId}`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                let message = "Recommend Successfully!";
                if (data.type == "no") {
                    message = "UnRecommend Successfully!";
                }
                if(response.status!=="Already Exists"){
                    toast(message, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                }
                
                return dispatch(get_working_employee({ business_id: businessId }));
            }
        });
    };
};

export const delete_people = (id, listingid) => {
    return (dispatch) => {
        callApi(`/api/professionaluserentries/${listingid}/?empID=${id}`, "DELETE",).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Deleted Successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_working_employee({ business_id: listingid }));
            }
        });
    };
};


export const setIsLoadingBusiness=(data)=>{
    return {
        type:"IS_LOADING",
        payload:data
    }
}