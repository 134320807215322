import * as actions from "../actions/discussionsTab";
const initialState = {
    discussions_data: null,
};

const discussions = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case actions.GET_DISCUSSIONS:
            state.discussions_data = action.payload
            return { ...state };

        default:
            return state;
    }
}

export default discussions;