/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import { Container, Row, Col } from 'reactstrap';
import PublicPageHeader from '../../oragnisms/homepage/publicPageHeader';

export default class PrivacyPolicy extends Component {
    render() {
        const isLoggedIn = localStorage.getItem("token")

        return (
            <div>
                {isLoggedIn ? <AppHeader isEnableCover={false} /> : <PublicPageHeader />}    
                
                <div className="wk-static-wrapper">
                  <Container>
                      <div className="wk-static-sub-head">
                         <h1>Privacy Policy</h1>
                      </div>
                      <div className="wk-static-content-body">
                         <Row>
                            <Col md={4}>
                               <div className="wk-static-sidebar-left">
                                  <h2>PRIVACY POLICY</h2>
                                  <ul>
                                        <li><a href="#intro">Information Collected</a></li>
                                        <li><a href="#info">Use of Information </a></li>
                                        <li><a href="#ip">Use of IP Addresses </a></li>
                                        <li><a href="#ip1">Use of Cookies</a></li>
                                        <li><a href="#sec">Security </a></li>
                                        <li><a href="#sites">Other Sites; Links </a></li>
                                        <li><a href="#opt">Opt Out</a></li>
                                        <li><a href="#child">Children Under the Age of 13  </a></li>
                                        <li><a href="#privacy">California Privacy Rights  </a></li>
                                   </ul>
                               </div>                            
                            </Col>
                            <Col md={8}>
                                <div className="wk-static-content-right">
                                   <h2>INTRODUCTION</h2>
                                   <p className="fs-14">WikiReviews, Inc. ("WikiReviews") is committed to protecting the privacy of our customers’ personal information. This statement applies with respect to the information that we collect from our website located at wikireviews.com ("Website") and through our Mobile Application ("Mobile App"). The Website and Mobile App are collectively referred to as the “Site.” Amendments to this statement will be posted on the Site and be effective when posted. Your continued use of the Site following the posting of any amendment, modification or change shall constitute your acceptance thereof.
                                   </p>
                                   <p className="fs-14"><b><u id="intro">Information Collected</u></b>
                                  </p>
                                  <p className="fs-14">We collect identifiable information about you in a number of ways, including tracking your activities through your IP address, computer settings or most-recently visited URL. We collect personally identifiable information about you including information you submit to us, by, for example, filling out a survey or registration form. The types of information that may be collected include your name, address, e-mail address, geo-location, telephone number, online usage, relations with other users, and content from your computer or mobile device.
                                   </p>
                                   <p className="fs-14"><b><u id="info">Use of Information</u></b>
                                   </p>
                                  <p className="fs-14">In general, we may use the information you provide to us for whatever purpose we deem appropriate, including geo-targeting marketing campaigns. We may also use this information to deliver information to you about WikiReviews and promotional material from some of our partners, or to conduct trend analysis, pattern detection, and Site administration.
                                   </p>
                                   <p className="fs-14">We may access your information in order to mine data for sharing with third parties namely, advertisers, marketers, and other data aggregators. Your information may also be used to contact you when necessary and may be shared with other companies that may want to contact you with offers consistent with your stated preferences. Users may opt-out of receiving future mailings from WikiReviews and other entities by following the instructions set forth in the opt-out section below.
                                   </p>
                                   <p className="fs-14">Personal demographic and profile data is used to tailor your experience at our Site, showing you content we think you might be interested in. This information may also be shared with advertisers on an aggregate and personal basis.
                                   </p>
                                   <p className="fs-14">We may investigate and disclose information from or about you if we have a good faith belief that such investigation or disclosure is (a) reasonably necessary to comply with legal process and law enforcement instructions and orders, such as a search warrant, subpoena, statute, judicial proceeding, or other legal process served on us; (b) helpful to prevent, investigate, or identify possible wrongdoing in connection with the Site; (c) in connection with our Terms of Service and other agreements; (d) in connection with a corporate transaction, such a divestiture, merger, consolidation, or asset sale or (e) protect our rights, reputation, property, or that of our users affiliates, or the public.
                                   </p>
                                   <p className="fs-14"><b><u id="ip">Use of IP Addresses</u></b>
                                   </p>
                                   <p className="fs-14">We use your IP Address to help diagnose problems with our server, and to administer our Site. We may also use your IP Address and geo-locating data for marketing purposes and may share this information with third parties namely, advertisers, marketers, and other data aggregators.
                                   </p>
                                   <p className="fs-14"><b><u id="ip1">Use of Cookies</u></b>
                                   </p>
                                   <p className="fs-14">When you view our Site, we might store some information on your computer. This information will be in the form of a "cookie" or similar file. Cookies are small pieces of information stored on your hard drive, not on our Site. Cookies do not spy on you or otherwise invade your privacy, and they cannot invade your hard drive and steal information. Rather, they help you navigate a Site as easily as possible. We use cookies to deliver content specific to your interests and to prevent you from reentering all your registration data at each connection.
                                   </p>
                                   <p className="fs-14"><b><u id="sec">Security</u></b>
                                   </p>
                                   <p className="fs-14">Our Site has industry standard security measures in place to protect the loss, misuse and alteration of the information under our control. While there is no such thing as "perfect security" on the Internet, we will take all reasonable steps to insure the safety of your personal information.
                                   </p>
                                   <p className="fs-14"><b><u id="sites">Other Sites; Links</u></b>
                                   </p>
                                   <p className="fs-14">Our Site may contain links to other websites. We are not responsible for the privacy practices or the content of such websites.
                                   </p>
                                   <p className="fs-14"><b><u id="opt">Opt Out</u></b>
                                   </p>
                                   <p className="fs-14">With the exception of administrative and legal notices, you may opt-out of receiving communications from our partners, and from us by sending an email to <mark><a href="mailto:optout@wikireviews.com?subject=notice">optout@wikireviews.com.</a></mark>
                                   </p>
                                   <p className="fs-14"><b><u id="child">Children Under the Age of 13</u></b>
                                   </p>
                                   <p className="fs-14">Our Site is not intended for children under 13 years of age. No one under age 13 may provide any personal information to or on the Site. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this Site or on or through any of its features/register on the Site, use any of the interactive or public comment features of this Site or provide any information about yourself to us, including your name, address, telephone number, e-mail address or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at Our Site is not intended for children under 13 years of age. No one under age 13 may provide any personal information to or on the Site. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this Site or on or through any of its features/register on the Site, use any of the interactive or public comment features of this Site or provide any information about yourself to us, including your name, address, telephone number, e-mail address or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at <mark><a href="mailto:admin@wikireviews.com">admin@wikireviews.com.</a></mark>
                                   </p>
                                   <p className="fs-14"><b><u id="privacy">California Privacy Rights</u></b>
                                   </p>
                                   <p className="fs-14">Pursuant to California Civil Code sections 1798.83-1798.84, California residents are entitled to ask us for a notice describing what categories of personal customer information we share with third parties or corporate affiliates for those third parties' or corporate affiliates' direct marketing purposes. That notice will identify the categories of information shared and will include a list of the third parties and affiliates with which it was shared, along with their names and addresses. If you are a California resident and would like a copy of this notice, please submit a written request to the following address:
                                   </p>
                                   <p className="fs-14"> California Privacy Rights Notice
                                                        <br/>WikiReviews, Inc.
                                                        <br/>26 Burlingame
                                                        <br/>Irvine, CA 92618
                                   </p>
                                   <p className="fs-14">
                                      <i><b>*Please allow 30 days for a response.</b></i>
                                   </p>
                                </div>
                            </Col>
                         </Row>
                      </div>
                  </Container>
                </div>
            </div>
        )
    }
}
