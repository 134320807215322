import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Progress,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { add_professional } from "../../../actions/professionals";
import { Formik, FieldArray } from "formik";
import { callApi } from "../../../utils/apiCaller";
import { withRouter } from "react-router";
import {
  media_upload_scroll,
  fetch_media_data,
  clear_media_dispatch,
  upload_add_captions,
  fetch_taxonomy_list,
} from "../../../actions/myProfiles";

import {
  initFetchProfessionalSubCategories,
  clear_cat_sub_professional
} from "../../../actions/footer";

import {
  add_my_post,
  get_album_types_list,
  get_album_type_data,
  delete_selected_gallery_media
} from "../../../actions/user";


import * as Yup from "yup";
const validate = Yup.object().shape({
  professionalwebsite_set: Yup.array()
      .of(
          Yup.object().shape({
            website: Yup.string().min(4, "too short").required("Required"), // these constraints take precedence
            website_type: Yup.number().required("Please select Website Type"),
          })
      )
      .required("Must have websites")
      .min(3, "Minimum of 3 websites"),
  professionalphone_set: Yup.array()
      .of(
          Yup.object().shape({
            phone: Yup.string().min(4, "too short").required("Required"),
            label: Yup.number().required("Please select Label"),
          })
      )
      .required("Phone is required")
      .min(3, "Minimum of 3 Phone"),
  professionalemail_set: Yup.array()
      .of(
          Yup.object().shape({
            email: Yup.string().min(4, "too short").required("Required"),
            email_type: Yup.number().required("Please select Label"),
          })
      )
      .required("Feature is required")
      .min(3, "Minimum of 3 features"),

});

class ProfessionalModal extends React.Component {
  constructor() {
    super()
    this.refGalleryVideoScroll = React.createRef();
    this.refGalleryImageScroll = React.createRef();
    this.state = {
      addProfessional: {
        firstname: "",
        country: "",
        is_draft: false,
        additional_info: {},
        drawer: true,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        hour_start_time: "",
        hour_end_time: "",
        hour_day_of_week: "",
        hour_info: "",
        professionalphone_set: [
          {
            phone: "",
            label: ""
          }
        ],
        professionalemail_set: [
          {
            email_type: "",
            email: ""
          }
        ],
        professionalwebsite_set: [
          {
            website: "",
            website_type: ""
          }
        ],

        professionalpaymentoptions_set: [
          {
            paymentoptions: ""
          }
        ],
        professionalfeestypes_set: [
          {
            fee_value: "",
            feestypes: ""
          }
        ],

        drawer: true,
        taxonomy: null,
        media: [""],
        caption: "",
        address: [{
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipcode: ""
        }],
        professionalhoursofoperation_set: [
          {
            day_of_week: "",
            start_time: "",
            end_time: "",
            info: ""
          }
        ],
      },

      professionalphone_set: [
        {
          phone: "",
          label: ""
        }
      ],
      professionalemail_set: [
        {
          email_type: "",
          email: ""
        }
      ],
      professionalwebsite_set: [
        {
          website: null,
          website_type: null
        }
      ],
      professionalpaymentoptions_set: [
        {
          paymentoptions: null
        }
      ],
      professionalfeestypes_set: [
        {
          fee_value: null,
          feestypes: null
        }
      ],
      address: [{
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: ""
      }],
      professionalhoursofoperation_set: [
        {
          day_of_week: "1",
          start_time: "09:00",
          end_time: "17:00",
          info: ""
        }
      ],
      firstname: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      hour_start_time: "",
      hour_end_time: "",
      hour_day_of_week: "",
      hour_info: "",
      country: "usa",
      cityError: "",
      emailError: "",
      emailTypeError: "",
      nameError: "",
      showGalleryType: "images",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      categoryError: "",
      subCategoryError: "",
      feestypesyError: "",
      stateError: "",
      zipcodeError: "",
      category_id: "",
      subcategory_id: "",
      professionalSubcategories: [],
      professionalAnothSabcategories: [],
      professionalOtherSabcategories: [],
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      uploadMediaPostModal: false,
      uploadCaptions: {},
      caption: "",
      uploadMediaModal: false,
      is_draft: false,
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
      selectedCategory: null,
      catId: '',
    }
  }

  handleCancel = () => {
    this.setState({
      professionalSubcategory: [],
      professionalAnothSabcategory: [],
      professionalOtherSabcategory: []
    });
    this.props.clear_cat_sub_professional();
    this.props.toggleProductModal();
  }
  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (name == "firstname") {
      this.setState({
        nameError: ""
      });
    } else if (name == "category_id") {
      this.setState({
        categoryError: ""
      });
    }
    else if (name == "subcategory_id") {
      this.setState({
        subCategoryError: ""
      });
    }
    else if (name == "city") {
      this.setState({
        cityError: ""
      });
    }
    else if (name == "professionalemail_set[0].email") {
      this.setState({
        emailError: ""
      });
    }
    else if (name == "professionalemail_set[0].email_type") {
      this.setState({
        emailTypeError: ""
      });
    }
    else if (name == "professionalfeestypes_set[0].feestypes") {
      this.setState({
        feestypesyError: ""
      });
    }
    else if (name == "state") {
      this.setState({
        stateError: ""
      });
    }
    else if (name == "zipcode") {
      this.setState({
        zipcodeError: ""
      });
    }

    if (name == "category_id") {
      let type = "category_id"
      this.setState({
        category_id: e.target.value,
        catId: e.target.value
      });
      this.props.initFetchProfessionalSubCategories(e.target.value, type);
    }
    if (name == "subcategory_id") {
      let type = "subcategory_id"
      this.setState({
        category_id: e.target.value
      });
      this.props.initFetchProfessionalSubCategories(e.target.value, type);
    }

  }


  handleChangeProfessional = (e, index) => {
    const { professionalphone_set, professionalemail_set, professionalwebsite_set, professionalpaymentoptions_set, professionalfeestypes_set, professionalhoursofoperation_set } = this.state
    const { name, value } = e.target
    if (name.includes('phone') && professionalphone_set.length <= index) {
      professionalphone_set.push({ phone: "", label: "" })
      this.setState({ professionalphone_set })
    }
    if (name.includes('email') && professionalemail_set.length <= index) {
      professionalemail_set.push({ email: "", email_type: "" })
      this.setState({ professionalemail_set })
    }
    if (name.includes('website') && professionalwebsite_set.length <= index) {
      professionalwebsite_set.push({ website: "", website_type: "" })
      this.setState({ professionalwebsite_set })
    }
    if (name.includes('payment') && professionalpaymentoptions_set.length <= index) {
      professionalpaymentoptions_set.push({ paymentoptions: "" })
      this.setState({ professionalpaymentoptions_set })
    }
    if (name.includes('feestype') && professionalfeestypes_set.length <= index) {
      professionalfeestypes_set.push({ fee_value: "", feestypes: "" })
      this.setState({ professionalfeestypes_set })
    }
    if ((name.includes('hour_day_of_week') || name.includes('hour_start_time') || name.includes('hour_end_time') || name.includes('hour_info')) && professionalhoursofoperation_set.length <= index) {
      professionalhoursofoperation_set.push({ day_of_week: null, start_time: null, end_time: null, info: null })
      this.setState({ professionalhoursofoperation_set })
    }
    if (name === 'phones') {
      professionalphone_set[index].phone = value
      this.setState({ professionalphone_set })
    }
    else if (name === 'labels') {
      professionalphone_set[index].label = value
      this.setState({ professionalphone_set })
    }
    else if (name === 'emails') {
      professionalemail_set[index].email = value
      this.setState({ professionalemail_set })
    }
    else if (name === 'emailType') {
      professionalemail_set[index].email_type = value
      this.setState({ professionalemail_set })
    }
    else if (name === 'websites') {
      professionalwebsite_set[index].website = value
      this.setState({ professionalwebsite_set })
    }
    else if (name === 'websiteType') {
      professionalwebsite_set[index].website_type = value
      this.setState({ professionalwebsite_set })
    }
    else if (name === 'payments') {
      professionalpaymentoptions_set[index].paymentoptions = value
      this.setState({ professionalpaymentoptions_set })
    }
    else if (name === 'fees') {
      professionalfeestypes_set[index].fee_value = value
      this.setState({ professionalfeestypes_set })
    }
    else if (name === 'feestypes') {
      professionalfeestypes_set[index].feestypes = value
      this.setState({ professionalfeestypes_set })
    }
    else if (name === 'hour_start_time') {
      professionalhoursofoperation_set[index].start_time = value
      this.setState({ professionalhoursofoperation_set })
    } else if (name === 'hour_end_time') {
      professionalhoursofoperation_set[index].end_time = value
      this.setState({ professionalhoursofoperation_set })
    } else if (name === 'hour_day_of_week') {
      professionalhoursofoperation_set[index].day_of_week = value
      this.setState({ professionalhoursofoperation_set })
    } else if (name === 'hour_info') {
      professionalhoursofoperation_set[index].info = value
      this.setState({ professionalhoursofoperation_set })
    }
  }

  handleremoveProfessional = (option) => {
    const { professionalphone_set, professionalemail_set, professionalhoursofoperation_set, professionalwebsite_set, professionalpaymentoptions_set, professionalfeestypes_set } = this.state
    if (option === 'professionalphone_set') {
      professionalphone_set.pop()
      this.setState({ professionalphone_set })

    }
    else if (option === 'professionalemail_set') {
      professionalemail_set.pop()
      this.setState({ professionalemail_set })

    }
    else if (option === 'professionalwebsite_set') {
      professionalwebsite_set.pop()
      this.setState({ professionalwebsite_set })

    }
    else if (option === 'professionalpaymentoptions_set') {
      professionalpaymentoptions_set.pop()
      this.setState({ professionalpaymentoptions_set })

    }
    else if (option === 'professionalfeestypes_set') {
      professionalfeestypes_set.pop()

      this.setState({ professionalfeestypes_set })

    }
    else if (option === 'professionalhoursofoperation_set') {
      professionalhoursofoperation_set.pop()
      this.setState({ professionalhoursofoperation_set })
    }
  }
  componentWillReceiveProps(nextProps) {
    let {
      latestUploadedData,
      fetchGalleryScroll,
      fetchGalleryCount,
    } = this.state;


    if (nextProps.professionalSubcategory && nextProps.professionalSubcategory.length > 0) {
      this.setState({ professionalSubcategories: nextProps.professionalSubcategory });
    } else {
      this.setState({ professionalSubcategories: [] });
    }
    if (nextProps.professionalAnothSabcategory && Array.isArray(nextProps.professionalAnothSabcategory) && nextProps.professionalAnothSabcategory.length > 0) {
      this.setState({ professionalAnothSabcategories: nextProps.professionalAnothSabcategory });
    }
    if (nextProps.professionalOtherSabcategory && Array.isArray(nextProps.professionalOtherSabcategory) && nextProps.professionalOtherSabcategory.length > 0) {
      this.setState({ professionalOtherSabcategories: nextProps.professionalOtherSabcategory });
    }
    if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypesList: nextProps.album_types_list,
        },
        selectedUpMediaType: "upload",
        showGalleryType: "images",
      });
    }
    if (
        nextProps.album_type_data &&
        Object.keys(nextProps.album_type_data).length > 0
    ) {
      let item = nextProps.album_type_data;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypeData: nextProps.album_type_data,
        },
        fetchGalleryScroll,
        fetchGalleryCount,
      });
    }
    if (nextProps.fetch_taxonomy_listData) {
      let taxCatListing = nextProps.fetch_taxonomy_listData;
      if (
          taxCatListing &&
          Array.isArray(taxCatListing) &&
          taxCatListing.length > 0
      ) {
        if (taxCatListing[0].isTypes == "images") {
          this.setState({
            fetchTaxonomyListingImages: taxCatListing[0].children
                ? taxCatListing[0].children
                : [],
          });
        } else {
          this.setState({
            fetchTaxonomyListingVideos: taxCatListing[0].children
                ? taxCatListing[0].children
                : [],
          });
        }
      }
    }

    if (nextProps.fetch_save_response) {
      if (nextProps.fetch_save_response && nextProps.fetch_save_response.id && nextProps.fetch_save_response.slug) {
        let slug = null;
        let professionalId = "";
        if (nextProps.fetch_save_response.id != "") {
          professionalId = nextProps.fetch_save_response.id;
        }
        if (nextProps.fetch_save_response.slug != "") {
          slug = nextProps.fetch_save_response.slug;

        }
        this.props?.professionals?.map((profession) => {

          if (profession?.id?.toString() === this.state?.catId?.toString() ) {
            this.props.toggleProfessionalModal();

            this.props.history.push({
              pathname: `/${profession.category}/${slug}`,
              state: { id: professionalId },
            })
          }
        })

      }
    }
  }

  handleChangeTaxonomyData = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    data.category = value;
    let id = upload_data.id;
    this.setState({ taxonomycategoryId: value }, () =>
        this.props.upload_add_captions(id, data)
    );
  };

  handleOnClickRemoveSelectedMedia = (id) => () => {
    let removeMediaIds = [];
    removeMediaIds.push(id);
    this.props.delete_selected_gallery_media(removeMediaIds);
    let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
        (file) => file.id !== id
    );
    let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
        (file) => file.id !== id
    );
    let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
        (item) => item !== id
    );
    let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
        (file) => file.id !== id
    );
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: selectedMedia,
        selectedMediaIds: selectedMediaIds,
        uploadedFiles: uploadedFiles,
        uploadFiles: uploadFiles,
      },
    });
  };

  handleOnClickRemoveSelectedGalleryMedia = (media, index) => {
    // remove media from upload media state
    let { uploadMedia, editPost } = this.state;
    let id = media.id;
    let filterSelectedMediaIds = [];
    let filterSelectedMedia = [];

    if (id) {
      filterSelectedMediaIds = uploadMedia?.selectedMediaIds.filter(data => data !== id);
      filterSelectedMedia = uploadMedia?.selectedMedia.filter(data => data.id !== id);
    }
    uploadMedia['selectedMediaIds'] = filterSelectedMediaIds;
    uploadMedia['selectedMedia'] = filterSelectedMedia;

    if (editPost) {
      editPost["messagemedia_set"] = filterSelectedMediaIds;
    }

    this.setState({ uploadMedia, editPost }, () => { this.props.delete_selected_gallery_media([id]) });
  };

  handleOnClickSelectGalleryMedia = (media) => {
    if (media) {
      let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
      let newSelectedMediaIds;
      let newSelectedMedia;
      if (index !== -1) {
        newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
            (item) => item !== media.id
        );
        newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
            (item) => item.id !== media.id
        );
      } else {
        newSelectedMediaIds = [
          ...this.state.uploadMedia.selectedMediaIds,
          media.id,
        ];
        newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
      }
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: newSelectedMedia,
          selectedMediaIds: newSelectedMediaIds,
        },
      });
    }
  };
  handleOnFilePostUploadChange = (event) => {
    let uploadFiles = event.target.files;
    let showFiles = [];
    for (const key of Object.keys(uploadFiles)) {
      let itemType = uploadFiles[key].type.split("/");
      let extName = itemType[0];
      showFiles.push({ id: "", url: extName === "image" ? URL.createObjectURL(uploadFiles[key]) : require("../../../assets/images/blank.png") });
    }
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        uploadFiles: showFiles,
        progress: 0,
      },
    });
    let progressPart = 100 / showFiles.length;
    let progress = 0;
    for (const key of Object.keys(uploadFiles)) {
      let data = new FormData();
      data.append("file", uploadFiles[key]);

      callApi(
          `/upload/multiuploader/?album=&instance=professionalsdrawer&image=undefined&doc=undefined`,
          "POST",
          data,
          true
      ).then((response) => {
        this.handleOnClickSelectGalleryMedia(response);
        if (showFiles.length === 1 || key === showFiles.length - 1) {
          progress = 100;
        } else {
          progress = progress + progressPart;
        }
        if (response && response.code !== 400 && response.code !== 500) {
          showFiles[key].id = response.id;
          showFiles[key].media_type = response.media_type;
          showFiles[key].thumbnail = response.thumbnail;
          if (response.media_type == "image") {
            this.props.fetch_taxonomy_list('Professionals', 'images');
          } else {
            this.props.fetch_taxonomy_list('Professionals', 'videos');
          }

          this.setState({
            ...this.state,
            uploadMedia: {
              ...this.state.uploadMedia,
              progress: progress,
              uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
              uploadedFiles: showFiles,
            },
          });
        }
      });
    }
  };

  handleCaptionByIndex = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    uploadCaptions[index] = value;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    let id = upload_data.id;
    this.setState({ uploadCaptions }, () =>
        this.props.upload_add_captions(id, data)
    );
  };

  handleOnClickGalleryType = (type) => {
    let mediaType = "";
    if (type === "images") {
      mediaType = "image";
    } else if (type === "videos") {
      mediaType = "video";
    }
    this.props.get_album_type_data(mediaType, "", 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: type,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: mediaType,
        albumType: "",
        pageNo: 1,
      },
    });
  };

  handleOnClickAlbumTypeChange = (e) => {
    this.props.get_album_type_data("image", e.target.value, 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: "images",
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: e.target.value,
        pageNo: 1,
      },
    });
  };

  handleOnClickSelectedUploadMediaType = (type) => {
    if (type === "gallery") {
      this.props.get_album_type_data("image", "", 1);
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        showGalleryType: "images",
        uploadMedia: {
          ...this.state.uploadMedia,
          mediaType: "image",
          albumType: "",
          pageNo: 1,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "upload") {
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "embed") {
      let embedLinks = {
        0: "",
        1: "",
      };
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: embedLinks,
        },
      });
    }
  };

  embedLinkOnChange = (id) => (e) => {
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        embedLinks: {
          ...this.state.uploadMedia.embedLinks,
          [id]: e.target.value,
        },
      },
    });
  };

  mapEmbedLinks = () => {
    let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
    return embedKeys.map((emKey) => {
      return (
          <div className="d-flex mx-n2 mb-2" key={emKey}>
            <div className="flex-fill px-2">
              <Input
                  type="url"
                  bsSize="sm"
                  className="bg-white"
                  value={this.state.uploadMedia.embedLinks[emKey]}
                  onChange={this.embedLinkOnChange(emKey)}
                  placeholder="Embeded link"
              />
            </div>
            <div className="px-2">
              <Button
                  title="Remove"
                  color="danger"
                  size="sm"
                  hidden={emKey <= 1 ? true : false}
                  onClick={() => {
                    this.deleteEmbedLinkRow(emKey);
                  }}
              >
                <FontAwesomeIcon icon="minus" />
              </Button>
            </div>
          </div>
      );
    });
  };
  deleteEmbedLinkRow = (emKey) => {
    let embedLinks = this.state.uploadMedia.embedLinks;
    if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
      delete embedLinks[emKey];
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: embedLinks,
        },
      });
    }
  };
  addEmbedlinkRow = () => {
    let newEmbedLinks = {};
    for (let i = 2; i < 5; i += 1) {
      if (
          Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
          -1
      ) {
        newEmbedLinks[i] = "";
        break;
      }
    }
    if (Object.keys(newEmbedLinks).length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {
            ...this.state.uploadMedia.embedLinks,
            ...newEmbedLinks,
          },
        },
      });
    }
  };
  confirmDeleteModalToggle = () => {
    if (this.state.deletePostId) {
      this.setState({ deletePostId: 0, deletePostType: "" });
    }
    this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
  };
  handleOnClickUploadMedia = () => {
    this.props.get_album_types_list();
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        progress: 0,
        uploadFiles: [],
        uploadedFiles: [],
      },
      uploadMediaPostModal: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
    });
  };
  truncate = (filenameString) => {
    let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
    let extension = filenameString.substr(
        filenameString.lastIndexOf("."),
        filenameString.length - 1
    );
    let partial = filename.substring(filename.length - 3, filename.length);
    filename = filename.substring(0, 15);
    return filename + "..." + partial + extension;
  };
  limitedPostsModalToggle = () => {
    this.setState({ limitedPostsModal: !this.state.limitedPostsModal });
  };
  uploadMediaModalToggle = () => {
    this.setState({
      ...this.state,
      uploadMediaPostModal: !this.state.uploadMediaPostModal,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: [],
        selectedMediaIds: [],
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        uploadedFiles: [],
        uploadFiles: [],
      },
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      taxonomycategoryId: null,
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
    });
  };
  handleOpenModal(data, url) {
    this.setState({
      viewMyPhotosModal: !this.state.viewMyPhotosModa,
      imageAttacment: data,
    });
  }

  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`;

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach((item) => {
        if (
            fetchGalleryScroll[item] &&
            fetchGalleryScroll[item].results !== null
        ) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map((item) => {
          let itemName = (item?.thumbnail).split(".");
          let itemFile = (item?.file).split(".");
          let extName = itemName[itemName.length - 1];
          let extFile = itemFile[itemFile.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;

          return (
              <Col xs="auto" className="mb-3">
                <FormGroup
                    className="checkable-media"
                    check
                    style={{ width: "120px" }}
                >
                  <Label check>
                    <Input
                        type="checkbox"
                        onClick={() => this.handleOnClickSelectGalleryMedia(item)}
                    />
                    <div className="gallery-media">
                      <img
                          className="img-fluid"
                          src={isVideo ? srcFile : item?.file}
                          alt="logo Pics"
                      />
                    </div>
                  </Label>
                  <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
                    {item.caption ? item.caption : "No Caption"}
                  </div>
                  <div className="font-weight-normal fs-14 d-block">
                    {item.category ? item.category : "No Category"}
                  </div>
                </FormGroup>
              </Col>
          );
        });
      }
    } else {
      return getDataItems;
    }
  };
  handleScrollGalleryImage = (e) => {
    let { refGalleryImageScroll } = this;
    let { fetchGalleryAlbum } = this.state;

    let clientHeight = refGalleryImageScroll.current.clientHeight;
    let scrollHeight = refGalleryImageScroll.current.scrollHeight;
    const scrollTop =
        this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
        scrollTopCalulated === scrollTop ||
        scrollTop === scrollMinimun_1 ||
        scrollTop === scrollMinimun_3 ||
        scrollTop === scrollMinimun_5 ||
        scrollTop === scrollMinimun_6 ||
        scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "image",
        album: fetchGalleryAlbum,
        force_update: false,
      });
    }
  };

  fetchGalleryOnScroll = ({ type, album, force_update }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state;
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;
      if (nextCount <= roundValue) {
        let page = nextCount;
        this.props.get_album_type_data(type, "", page);
      }
    }
  };

  handleScrollGalleryVideo = (e) => {
    let { refGalleryVideoScroll } = this;
    // let count = 0;
    let clientHeight = refGalleryVideoScroll.current.clientHeight;
    let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
    const scrollTop =
        this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
        scrollTopCalulated === scrollTop ||
        scrollTop === scrollMinimun_1 ||
        scrollTop === scrollMinimun_3 ||
        scrollTop === scrollMinimun_5 ||
        scrollTop === scrollMinimun_6 ||
        scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "video",
        album: "",
        force_update: false,
      });
    }
  };
  insertEmbedLinks = () => {

    let embedValues = Object.values(this.state.uploadMedia.embedLinks).filter(
        (item) => item !== ""
    );

    if (embedValues.length > 0) {
      embedValues = embedValues.map(
          (item) => "<a href=" + item + "><p>" + item + "</p></a>"
      );
      let newPost = {
        video: embedValues.join("\n"),
        exclude_list: [],
        question_rating_category: [],
        messagemedia_set: [],
      };

      this.props.add_my_post(newPost, this.state.filterType, this.state.pageNo);

      this.setState({
        ...this.state,
        limitedPostsModal:
            this.state.myPostsList.count < this.state.limitedPostsCount
                ? false
                : true,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: {},
        },
        uploadMediaPostModal: false,
      });
    }
    //}
  };

  handleProfessionalSubmit = () => {
    let { firstname, state,subcategory_id, email, city, zipcode, category_id, professionalphone_set, professionalemail_set, professionalwebsite_set, professionalpaymentoptions_set, professionalfeestypes_set, professionalhoursofoperation_set } = this.state;
   
    if (firstname == "" && city == "" && professionalemail_set[0].email == "" && professionalemail_set[0].email_type == "" && category_id == "" && state == "" && zipcode == "") {
      this.setState({
        nameError:
            "This field is required",
        cityError:
            "City is required",
        emailError:
            "Email is required",
        emailTypeError:
            "Email Type is required",
        categoryError:
            "This field is required",
        subCategoryError:
            "This field is required",
        stateError:
            "State name is required",
        feestypesyError:
            "Fees is required",
        zipcodeError:
            "Zipcode/Postal code is required",
      });
    
    } else if (firstname == "") {
      this.setState({
        nameError:
            "This field is required",
      });
      
    } else if (category_id == "") {
      this.setState({
        categoryError:
            "Please select category",
      });
    
    } else if (subcategory_id == "") {
      this.setState({
        subCategoryError:
            "Please select category",
      });
     
    } else if (city == "") {
      this.setState({
        cityError: "City is required"
      });
      
    } else if (professionalemail_set[0].email == "") {
      this.setState({
        emailError: "email is required"
      });
     
    } else if (professionalemail_set[0].email_type == "") {
      this.setState({
        emailTypeError: "email Type is required"
      });
     
    } else if (professionalfeestypes_set[0].feestypes=="") {
      this.setState({
        feestypesyError: "fees types Type is required"
      });
      
    } else if (state == "") {
      this.setState({
        stateError:
            "State name is required",
      });
      
    } else if (zipcode == "") {
      this.setState({
        zipcodeError:
            "Please required zipcode",
      });
     
    }
    else {
     
      let params = {}
      let media = []

      let intItem = parseInt(this.state.category_id);
      params = {
        firstname: this.state.firstname,
        is_draft: this.state.is_draft,
        country: this.state.country,
        media: [],
        additional_info: this.state.additional_info,
        address: [{
          address1: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          zipcode: this.state.zipcode,
        }],


        professionalphone_set: professionalphone_set && professionalphone_set[0] && professionalphone_set[0].phone == "" ? {} : professionalphone_set,
        professionalemail_set: professionalemail_set && professionalemail_set[0] && professionalemail_set[0].email == "" ? {} : professionalemail_set,
        professionalwebsite_set: professionalwebsite_set && professionalwebsite_set[0] && professionalwebsite_set[0].website == "" ? {} : professionalwebsite_set,
        professionalpaymentoptions_set: professionalpaymentoptions_set && professionalpaymentoptions_set[0] && professionalpaymentoptions_set[0].payment == "" ? {} : professionalpaymentoptions_set,
        professionalfeestypes_set: professionalfeestypes_set && professionalfeestypes_set[0] && professionalfeestypes_set[0].feestype == "" ? {} : professionalfeestypes_set,
        professionalhoursofoperation_set: professionalhoursofoperation_set && professionalhoursofoperation_set[0] && professionalhoursofoperation_set[0].operation == "" ? {} : professionalhoursofoperation_set,
        taxonomy: [intItem],

      }

      if (this.state.uploadMedia.selectedMediaIds.length > 0) {
        params.media = this.state.uploadMedia.selectedMediaIds
      }

   
      // return 
      this.props.add_professional(params)
   
    }
  }
  render() {

    const { firstname, address1, address2, addProfessional, professionalhoursofoperation_set,
      city, category_id, taxonomy, caption, state, zipcode, professionalphone_set, professionalemail_set, professionalpaymentoptions_set, professionalfeestypes_set, professionalwebsite_set,
      fetchTaxonomyListingImages, fetchTaxonomyListingVideos,
      subcategory_id, professionalOtherSabcategories, professionalAnothSabcategories, professionalSubcategories } = this.state
  
    const { openModal, toggleProfessionalModal, professionals } = this.props;
    return (
        <>
          <Formik
              touched
              errors
              initialValues={addProfessional}
              validationSchema={validate}
              onSubmit={(values) => {
                this.props.onSubmit({
                  ...values,
                  taxonomy: [values.taxonomy],
                });
              }}
              render={(props) => {
                const {
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                } = props;
                return (
                    <Modal
                        size="xl"
                        scrollable
                        className="drawer-modal"
                        isOpen={openModal}
                        toggle={toggleProfessionalModal}
                    >
                      <div className="modal-header p-0">
                        <div className="w-100">
                          <div>
                            <Button color="primary" onClick={toggleProfessionalModal}>
                              <FontAwesomeIcon icon="angle-left" /> back to listing
                            </Button>
                          </div>
                          <div>
                            <div className="bg-darker p-3 text-tertiary">
                              <div className="mb-2 text-primary font-weight-bold">
                                You’re Adding a New Professional Listing
                              </div>
                              <ul className="list-unstyled d-flex flex-wrap mx-n2">
                                <li className="px-2">
                                  <div className="d-flex">
                                    <FormGroup>
                                      {/*<Input type="select" className="transparent" va size="sm">*/}
                                      {/*  <option>Professionals</option>*/}
                                      {/*</Input>*/}
                                      <Input
                                          className="primary transparent"
                                          bsSize="sm"
                                          type="text"
                                          maxLength="30"
                                          value='Professionals'
                                          name="address1"
                                          autoComplete="off"
                                      />
                                    </FormGroup>
                                  </div>
                                </li>
                                <li className="px-2">
                                  <div className="d-flex">
                                    <FormGroup>
                                      <Input
                                          id="category_id"
                                          name="category_id"
                                          onChange={this.handleChange}
                                          type="select"
                                          className="transparent"
                                          size="sm"
                                          selected={category_id}
                                      >
                                        <option value="">Select Category</option>
                                        {professionals && professionals.length > 0 ? professionals.map((professional) => {

                                          return (
                                              <option value={professional.id} key={professional.id}>
                                                {professional.category}
                                              </option>

                                          );
                                        }) : ("")}
                                      </Input>
                                      <span style={{ color: 'red', fontSize: "13px" }}>{this.state.categoryError}</span>
                                    </FormGroup>
                                    <div className="ml-1"><span className="text-danger">*</span></div>
                                  </div>
                                </li>
                                <li className="px-2" hidden={professionalSubcategories && professionalSubcategories.length > 0 ? false : true}>
                                  <FormGroup>
                                    <Input
                                        id="subcategory_id"
                                        name="subcategory_id"
                                        onChange={this.handleChange}
                                        type="select"
                                        className="transparent"
                                        size="sm"
                                        selected={subcategory_id}
                                    >
                                      <option value="">Select SubCategory</option>
                                      {professionalSubcategories && professionalSubcategories.length > 0 ? professionalSubcategories.map((professionalsub) => {
                                        return (

                                            <option value={professionalsub.id} key={professionalsub.id}>
                                              {professionalsub.category}
                                            </option>
                                        );
                                      }) : ("")}
                                    </Input>
                                    <span style={{ color: 'red', fontSize: "13px" }}>{this.state.subCategoryError}</span>
                                  </FormGroup>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ModalBody className="bg-white text-dark">
                        {/* Step 1 */}
                        <div>
                          <div className="text-primary font-weight-bold mb-2">
                            Step 1. Enter Basic Information
                          </div>
                          <Row>
                            <Col md={6} lg={4}>
                              <FormGroup>
                                <Label className="text-dark font-weight-bold mb-0" size="sm">
                                  Country
                                </Label>
                                <Input
                                    id="country"
                                    name="country"
                                    onChange={this.handleChange}
                                    className="primary select-type-dark" bsSize="sm" type="select"
                                >
                                  <option value="usa">United States</option>
                                  <option value="can">Canada</option>
                                </Input>
                              </FormGroup>
                              <FormGroup>
                                <Label className="text-dark font-weight-bold mb-0" size="sm">
                                  Professional Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    className="primary"
                                    bsSize="sm"
                                    type="text"
                                    // pattern="[0-9a-fA-F]{4,8}"
                                    placeholder="Professional Name"
                                    value={firstname}
                                    name="firstname"

                                    onChange={this.handleChange}
                                />
                                <span style={{ color: 'red', fontSize: "13px" }}>{this.state.nameError}</span>
                              </FormGroup>
                            </Col>
                            <Col md={6} lg={4}>
                              <FormGroup>
                                <Label className="text-dark font-weight-bold mb-0" size="sm">
                                  Address <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    className="primary"
                                    bsSize="sm"
                                    type="text"
                                    maxLength="30"
                                    value={address1}
                                    name="address1"
                                    autoComplete="off"
                                    onChange={this.handleChange}
                                    placeholder="Address 1"
                                />
                                <Input
                                    className="primary mt-2"
                                    bsSize="sm"
                                    type="text"
                                    maxLength="30"
                                    value={address2}
                                    name="address2"
                                    autoComplete="off"
                                    onChange={this.handleChange}
                                    placeholder="Address 2"
                                />
                                <div className="d-flex mx-n2 mt-2">
                                  <div className="px-2">
                                    <Input
                                        className="primary"
                                        bsSize="sm"
                                        type="text"
                                        value={city}
                                        name="city"
                                        id="city"
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                        placeholder="City"
                                    />
                                    <span style={{ color: 'red', fontSize: "13px" }}>{this.state.cityError}</span>
                                  </div>
                                  <div className="px-2">
                                    <Input
                                        className="primary"
                                        bsSize="sm"
                                        type="text"
                                        value={state}
                                        name="state"
                                        id="state"
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                        placeholder="State"
                                    />
                                    <span style={{ color: 'red', fontSize: "13px" }}>{this.state.stateError}</span>
                                  </div>
                                  <div className="px-2">
                                    <Input
                                        className="primary"
                                        bsSize="sm"
                                        type="text"
                                        value={zipcode}
                                        name="zipcode"
                                        id="zipcode"
                                        autoComplete="off"
                                        onChange={this.handleChange}
                                        placeholder="Zipcode/Postal code"
                                    />
                                    <span style={{ color: 'red', fontSize: "13px" }}>{this.state.zipcodeError}</span>
                                  </div>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md={6} lg={4}>
                              <div>
                                <FieldArray
                                    name="professionalphone_set"
                                    render={(arrayHelpers) => (
                                        <div>
                                          {values.professionalphone_set.map(
                                              (website, index) => (
                                                  <div key={index}>
                                                    <FormGroup>
                                                      <Label
                                                          className="text-dark font-weight-bold mb-0"
                                                          size="sm"
                                                      >
                                                        Phone
                                                      </Label>
                                                      <div className="d-flex mx-n2">
                                                        <div className="col px-2">
                                                          <div className="d-flex mx-n2">
                                                            <div className="col px-2">
                                                              <Input
                                                                  className="primary"
                                                                  bsSize="sm"
                                                                  name="phones"
                                                                  onChange={(e) => this.handleChangeProfessional(e, index)}
                                                                  type="number"
                                                                  placeholder="(555)"
                                                              />
                                                            </div>

                                                          </div>
                                                        </div>
                                                      </div>
                                                      <Input name="rating"
                                                             name="labels"
                                                             onChange={(e) => this.handleChangeProfessional(e, index)}
                                                             className="primary select-type-dark mt-2"
                                                             bsSize="sm" type="select"
                                                      >
                                                        <option value="">Choose phone type</option>
                                                        <option value="Tel">Telephone</option>
                                                        <option value="Mob">Mobile</option>
                                                        <option value="Support">Support</option>
                                                      </Input>
                                                    </FormGroup>
                                                    {/* Hide for first */}
                                                    <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                                            onClick={() => {
                                                              arrayHelpers.remove(index);
                                                              this.handleremoveProfessional('professionalphone_set')
                                                            }
                                                            }>
                                                      Remove
                                                    </Button>
                                                  </div>
                                              )
                                          )}
                                          <div className="text-right">
                                            <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                                    onClick={() =>
                                                        arrayHelpers.push({
                                                          phone: "",
                                                          label: "",
                                                        })
                                                    }>
                                              <FontAwesomeIcon icon="plus" /> Add More
                                            </Button>
                                          </div>
                                        </div>
                                    )}
                                />

                              </div>

                              <div>
                                {/* Repeat this */}
                                <FieldArray
                                    name="professionalemail_set"
                                    render={(arrayHelpers) => (
                                        <div>
                                          {values.professionalemail_set.map(
                                              (Email, index) => (
                                                  <div key={index}>
                                                    <FormGroup>
                                                      <Label
                                                          className="text-dark font-weight-bold mb-0"
                                                          size="sm"
                                                      >
                                                        Email
                                                      </Label>
                                                      <Input
                                                          name="emails"
                                                          onChange={(e) => this.handleChangeProfessional(e, index)}
                                                          className="primary" bsSize="sm" type="email"
                                                      />
                                                      <span style={{ color: 'red', fontSize: "13px" }}>{this.state.emailError}</span>
                                                      <Input
                                                          className="primary select-type-dark mt-2"
                                                          bsSize="sm" type="select"
                                                          name="emailType"
                                                          onChange={(e) => this.handleChangeProfessional(e, index)}
                                                      >
                                                        <option>---------</option>
                                                        <option value="Sales">Sales mail</option>
                                                        <option value="Reserve">Reserve mail</option>
                                                        <option value="General">General mail</option>
                                                      </Input>
                                                      <span style={{ color: 'red', fontSize: "13px" }}>{this.state.emailTypeError}</span>
                                                    </FormGroup>
                                                    <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                                            onClick={() => {
                                                              arrayHelpers.remove(index);
                                                              this.handleremoveProfessional('professionalemail_set')
                                                            }
                                                            }>
                                                      Remove
                                                    </Button>
                                                  </div>
                                              )
                                          )}
                                          <div className="text-right">
                                            <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                                    onClick={() =>
                                                        arrayHelpers.push({
                                                          email: "",
                                                          email_type: "",
                                                        })
                                                    }>
                                              <FontAwesomeIcon icon="plus" /> Add More
                                            </Button>
                                          </div>
                                        </div>
                                    )}
                                />
                              </div>

                              <div>

                                {/* Repeat this */}
                                <div>
                                  <FieldArray
                                      name="professionalwebsite_set"
                                      render={(arrayHelpers) => (
                                          <div>
                                            {values.professionalwebsite_set.map(
                                                (website, index) => (
                                                    <div key={index}>
                                                      <FormGroup>
                                                        <Label
                                                            className="text-dark font-weight-bold mb-0"
                                                            size="sm"
                                                        >
                                                          Website
                                                        </Label>
                                                        <Input
                                                            name="websites"
                                                            onChange={(e) => this.handleChangeProfessional(e, index)}
                                                            className="primary" bsSize="sm" type="url" />
                                                        <Input className="primary select-type-dark mt-2"
                                                               name="websiteType"
                                                               onChange={(e) => this.handleChangeProfessional(e, index)}
                                                               bsSize="sm" type="select">
                                                          <option value="" selected="selected">
                                                            ---------
                                                          </option>
                                                          <option value="">Choose website type</option>
                                                          <option value="Main">Main Website</option>
                                                          <option value="Facebook">Facebook Website</option>
                                                          <option value="Google+">Google Website</option>
                                                          <option value="Twitter">Twitter Website</option>
                                                          <option value="LinkedIn">LinkedIn Website</option>
                                                          <option value="Instagram">Instagram Website</option>
                                                        </Input>
                                                      </FormGroup>
                                                      <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                                              onClick={() => {
                                                                arrayHelpers.remove(index);
                                                                this.handleremoveProfessional('professionalwebsite_set')
                                                              }
                                                              }>
                                                        Remove
                                                      </Button>
                                                    </div>
                                                )
                                            )}
                                            <div className="text-right">
                                              <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                                      onClick={() =>
                                                          arrayHelpers.push({
                                                            website: "",
                                                            website_type: "",
                                                          })
                                                      }>
                                                <FontAwesomeIcon icon="plus" /> Add More
                                              </Button>
                                            </div>
                                          </div>
                                      )}
                                  />


                                </div>

                              </div>
                            </Col>
                          </Row>
                        </div>

                        <hr />
                        {/* Step 2 */}
                        <div>
                          <div className="text-primary font-weight-bold mb-2">Step 2. Add images and/ or videos</div>
                          <Row>
                            <Col lg={5}>
                              <p className="text-tertiary fs-14">
                                Upload an image to represent your project. This could be album artwork, a book cover, artist profile, video-freeze frame, headshot... you get the idea (.jpg, .jpeg, .png).
                              </p>
                            </Col>
                            <Col lg={7}>
                              {/* Uploaded media shown here */}
                              {
                                this.state.uploadMedia.selectedMedia.length ?
                                    <div className="border mb-3">
                                      <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                        {this.state.uploadMedia.selectedMedia.map((item, indexMedia) => (
                                            <Col>
                                              <div className="d-flex pr-3 m-2">
                                                <div>
                                                  {(item.type === "image" ||
                                                      item.media_type === "image" ||
                                                      item.type === "video" ||
                                                      item.media_type === "video" || item.type === "audio" ||
                                                      item.media_type === "audio") && (
                                                      <div className="gallery-media">
                                                        { item.media_type === 'image' ?
                                                            <img
                                                                className="img-fluid img-thumbnail"
                                                                src={item.url ? item.url : item.file}
                                                                alt={
                                                                  item.filename
                                                                      ? item.filename.length < 20
                                                                      ? item.filename
                                                                      : this.truncate(item.filename)
                                                                      : item.name
                                                                      ? item.name.length < 20
                                                                          ? item.name
                                                                          : this.truncate(item.name)
                                                                      : ""
                                                                } /> :
                                                            <img
                                                                className="img-fluid img-thumbnail"
                                                                src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
                                                                alt={
                                                                  item.filename
                                                                      ? item.filename.length < 20
                                                                      ? item.filename
                                                                      : this.truncate(item.filename)
                                                                      : item.name
                                                                      ? item.name.length < 20
                                                                          ? item.name
                                                                          : this.truncate(item.name)
                                                                      : ""
                                                                }
                                                            />}
                                                      </div>
                                                  )}
                                                </div>
                                                <div className="mx-n3 mt-2">
                                                  <Button
                                                      color="delete-media"
                                                      size="sm"
                                                      title="Remove Media"
                                                      onClick={() =>
                                                          this.handleOnClickRemoveSelectedGalleryMedia(
                                                              item, indexMedia
                                                          )
                                                      }
                                                  >
                                                    <FontAwesomeIcon icon="trash-alt" />{" "}
                                                  </Button>
                                                </div>
                                              </div>
                                            </Col>
                                        ))}
                                      </Row>
                                    </div>
                                    :
                                    ''
                              }
                              <Button
                                  color="royal-blue"
                                  className="font-weight-normal"
                                  bsSize="sm"
                                  onClick={() =>
                                      this.handleOnClickUploadMedia()
                                  }
                              >
                                Upload Media
                              </Button>
                            </Col>
                          </Row>
                        </div>
                        <hr />

                        {/* Step 3 */}
                        <div>
                          <div className="text-primary font-weight-bold mb-2">
                            Step 3. Detailed Information
                          </div>
                          <Row>
                            <Col md={6} lg={4}>
                              <div>
                                <FieldArray
                                    name="professionalhoursofoperation_set"
                                    render={(arrayHelpers) => (
                                        <div>
                                          {values.professionalhoursofoperation_set.map(
                                              (hours, index) => (
                                                  <div key={index}>
                                                    <FormGroup>
                                                      <Label
                                                          className="text-dark font-weight-bold mb-0"
                                                          size="sm"
                                                      >
                                                        Hours of operation
                                                      </Label>
                                                      <Input
                                                          className="primary select-type-dark"
                                                          name="hour_day_of_week"
                                                          onChange={(e) => this.handleChangeProfessional(e, index)}
                                                          bsSize="sm" type="select">

                                                        <option value="1" selected="selected">Monday</option>
                                                        <option value="2">Tuesday</option>
                                                        <option value="3">Wednesday</option>
                                                        <option value="4">Thursday</option>
                                                        <option value="5">Friday</option>
                                                        <option value="6">Saturday</option>
                                                        <option value="7">Sunday</option>
                                                      </Input>
                                                      <div className="d-flex mx-n2 mt-2">
                                                        <div className="px-2">
                                                          <Input
                                                              className="primary select-type-dark" bsSize="sm"
                                                              name="hour_start_time"
                                                              onChange={(e) => this.handleChangeProfessional(e, index)}
                                                              type="select">
                                                            <option value="00:00" selected="selected">12:00 am</option>
                                                            <option value="00:30">12:30 am</option>
                                                            <option value="01:00">1:00 am</option>
                                                            <option value="01:30">1:30 am</option>
                                                            <option value="02:00">2:00 am</option>
                                                            <option value="02:30">2:30 am</option>
                                                            <option value="03:00">3:00 am</option>
                                                            <option value="03:30">3:30 am</option>
                                                            <option value="04:00">4:00 am</option>
                                                            <option value="04:30">4:30 am</option>
                                                            <option value="05:00">5:00 am</option>
                                                            <option value="05:30">5:30 am</option>
                                                            <option value="06:00">6:00 am</option>
                                                            <option value="06:30">6:30 am</option>
                                                            <option value="07:00">7:00 am</option>
                                                            <option value="07:30">7:30 am</option>
                                                            <option value="08:00">8:00 am</option>
                                                            <option value="08:30">8:30 am</option>
                                                            <option value="09:00">9:00 am</option>
                                                            <option value="09:30">9:30 am</option>
                                                            <option value="10:00">10:00 am</option>
                                                            <option value="10:30">10:30 am</option>
                                                            <option value="11:00">11:00 am</option>
                                                            <option value="11:30">11:30 am</option>
                                                            <option value="12:00">12:00 pm</option>
                                                            <option value="12:30">12:30 pm</option>
                                                            <option value="13:00">1:00 pm</option>
                                                            <option value="13:30">1:30 pm</option>
                                                            <option value="14:00">2:00 pm</option>
                                                            <option value="14:30">2:30 pm</option>
                                                            <option value="15:00">3:00 pm</option>
                                                            <option value="15:30">3:30 pm</option>
                                                            <option value="16:00">4:00 pm</option>
                                                            <option value="16:30">4:30 pm</option>
                                                            <option value="17:00">5:00 pm</option>
                                                            <option value="17:30">5:30 pm</option>
                                                            <option value="18:00">6:00 pm</option>
                                                            <option value="18:30">6:30 pm</option>
                                                            <option value="19:00">7:00 pm</option>
                                                            <option value="19:30">7:30 pm</option>
                                                            <option value="20:00">8:00 pm</option>
                                                            <option value="20:30">8:30 pm</option>
                                                            <option value="21:00">9:00 pm</option>
                                                            <option value="21:30">9:30 pm</option>
                                                            <option value="22:00">10:00 pm</option>
                                                            <option value="22:30">10:30 pm</option>
                                                            <option value="23:00">11:00 pm</option>
                                                            <option value="23:30">11:30 pm</option>
                                                          </Input>
                                                        </div>
                                                        <div className="px-2">
                                                          <Input

                                                              className="primary select-type-dark"
                                                              bsSize="sm"
                                                              name="hour_end_time"
                                                              onChange={(e) => this.handleChangeProfessional(e, index)}
                                                              type="select">
                                                            {/* <option>Select Closing Hours</option> */}
                                                            <option>End Hours</option>
                                                            <option value="00:30" selected="selected">12:30 am</option>
                                                            <option value="01:00">1:00 am</option>
                                                            <option value="01:30">1:30 am</option>
                                                            <option value="02:00">2:00 am</option>
                                                            <option value="02:30">2:30 am</option>
                                                            <option value="03:00">3:00 am</option>
                                                            <option value="03:30">3:30 am</option>
                                                            <option value="04:00">4:00 am</option>
                                                            <option value="04:30">4:30 am</option>
                                                            <option value="05:00">5:00 am</option>
                                                            <option value="05:30">5:30 am</option>
                                                            <option value="06:00">6:00 am</option>
                                                            <option value="06:30">6:30 am</option>
                                                            <option value="07:00">7:00 am</option>
                                                            <option value="07:30">7:30 am</option>
                                                            <option value="08:00">8:00 am</option>
                                                            <option value="08:30">8:30 am</option>
                                                            <option value="09:00">9:00 am</option>
                                                            <option value="09:30">9:30 am</option>
                                                            <option value="10:00">10:00 am</option>
                                                            <option value="10:30">10:30 am</option>
                                                            <option value="11:00">11:00 am</option>
                                                            <option value="11:30">11:30 am</option>
                                                            <option value="12:00">12:00 pm</option>
                                                            <option value="12:30">12:30 pm</option>
                                                            <option value="13:00">1:00 pm</option>
                                                            <option value="13:30">1:30 pm</option>
                                                            <option value="14:00">2:00 pm</option>
                                                            <option value="14:30">2:30 pm</option>
                                                            <option value="15:00">3:00 pm</option>
                                                            <option value="15:30">3:30 pm</option>
                                                            <option value="16:00">4:00 pm</option>
                                                            <option value="16:30">4:30 pm</option>
                                                            <option value="17:00">5:00 pm</option>
                                                            <option value="17:30">5:30 pm</option>
                                                            <option value="18:00">6:00 pm</option>
                                                            <option value="18:30">6:30 pm</option>
                                                            <option value="19:00">7:00 pm</option>
                                                            <option value="19:30">7:30 pm</option>
                                                            <option value="20:00">8:00 pm</option>
                                                            <option value="20:30">8:30 pm</option>
                                                            <option value="21:00">9:00 pm</option>
                                                            <option value="21:30">9:30 pm</option>
                                                            <option value="22:00">10:00 pm</option>
                                                            <option value="22:30">10:30 pm</option>
                                                            <option value="23:00">11:00 pm</option>
                                                            <option value="23:30">11:30 pm</option>
                                                            <option value="00:00 1">
                                                              12:00 am (midnight next day)
                                                            </option>
                                                            <option value="00:30 1">12:30 am (next day)</option>
                                                            <option value="01:00 1">1:00 am (next day)</option>
                                                            <option value="01:30 1">1:30 am (next day)</option>
                                                            <option value="02:00 1">2:00 am (next day)</option>
                                                            <option value="02:30 1">2:30 am (next day)</option>
                                                            <option value="03:00 1">3:00 am (next day)</option>
                                                            <option value="03:30 1">3:30 am (next day)</option>
                                                            <option value="04:00 1">4:00 am (next day)</option>
                                                            <option value="04:30 1">4:30 am (next day)</option>
                                                            <option value="05:00 1">5:00 am (next day)</option>
                                                            <option value="05:30 1">5:30 am (next day)</option>
                                                            <option value="06:00 1">6:00 am (next day)</option>
                                                          </Input>
                                                        </div>
                                                      </div>
                                                      <Input
                                                          className="primary mt-2"
                                                          bsSize="sm"
                                                          type="text"
                                                          name="hour_info"
                                                          onChange={(e) => this.handleChangeProfessional(e, index)}
                                                          placeholder="i.e. brunch"
                                                      />
                                                    </FormGroup>
                                                    {/* Hide for first */}
                                                    <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                                            onClick={() => {
                                                              arrayHelpers.remove(index);
                                                              this.handleremoveProfessional('professionalhoursofoperation_set')
                                                            }
                                                            }>
                                                      Remove
                                                    </Button>
                                                  </div>
                                              )
                                          )}
                                          <div className="text-right">
                                            <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                                    onClick={() =>
                                                        arrayHelpers.push({
                                                          day_of_week: "",
                                                          start_time: "",
                                                          end_time: "",
                                                          info: "",
                                                        })
                                                    }>
                                              <FontAwesomeIcon icon="plus" /> Add More
                                            </Button>
                                          </div>
                                        </div>
                                    )}
                                />
                              </div>


                            </Col>
                            <Col md={6} lg={4}>
                              <div>
                                {/* Repeat this */}
                                <FieldArray
                                    name="professionalpaymentoptions_set"
                                    render={(arrayHelpers) => (
                                        <div>
                                          {values.professionalpaymentoptions_set.map(
                                              (Payment, index) => (
                                                  <div key={index}>
                                                    <FormGroup>
                                                      <Label
                                                          className="text-dark font-weight-bold mb-0"
                                                          size="sm"
                                                      >
                                                        Payment Options
                                                      </Label>
                                                      <Input className="primary select-type-dark"
                                                             name="payments"
                                                             onChange={(e) => this.handleChangeProfessional(e, index)}
                                                             bsSize="sm"
                                                             type="select">
                                                        <option value="" selected="selected">
                                                          ---------
                                                        </option>
                                                        <option value="3">Check</option>
                                                        <option value="2">Credit Card</option>
                                                        <option value="1">Cash</option>
                                                      </Input>
                                                    </FormGroup>
                                                    <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                                            onClick={() => {
                                                              arrayHelpers.remove(index);
                                                              this.handleremoveProfessional('professionalpaymentoptions_set')
                                                            }
                                                            }>
                                                      Remove
                                                    </Button>
                                                  </div>
                                              )
                                          )}
                                          <div className="text-right">
                                            <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                                    onClick={() =>
                                                        arrayHelpers.push({
                                                          website: "",
                                                          website_type: "",
                                                        })
                                                    }>
                                              <FontAwesomeIcon icon="plus" /> Add More
                                            </Button>
                                          </div>
                                        </div>
                                    )}
                                />
                              </div>
                            </Col>
                            <Col md={6} lg={4}>
                              <div>

                                <FieldArray
                                    name="professionalfeestypes_set"
                                    render={(arrayHelpers) => (
                                        <div>
                                          {values.professionalfeestypes_set.map(
                                              (feestype, index) => (
                                                  <div key={index}>
                                                    <FormGroup>
                                                      <Label
                                                          className="text-dark font-weight-bold mb-0"
                                                          size="sm"
                                                      >
                                                        Fees
                                                      </Label>
                                                      <Input
                                                          name="feestypes"
                                                          onChange={(e) => this.handleChangeProfessional(e, index)}
                                                          className="primary select-type-dark"
                                                          bsSize="sm" type="select">
                                                        <option value="" selected="selected">
                                                          ---------
                                                        </option>
                                                        <option value="6">Pro Bono</option>
                                                        <option value="5">Free Consultation</option>
                                                        <option value="4">Retainer</option>
                                                        <option value="3">Hourly</option>
                                                        <option value="2">Fixed</option>
                                                        <option value="1">Contingent</option>
                                                      </Input>
                                                      <span style={{ color: 'red', fontSize: "13px" }}>{this.state.feestypesyError}</span>
                                                      <br/>
                                                      <Label
                                                          className="text-dark font-weight-bold mb-0"
                                                          size="sm"
                                                      >
                                                        Fees Amount
                                                      </Label>
                                                      <Input className="primary"
                                                             name="fees"
                                                             onChange={(e) => this.handleChangeProfessional(e, index)}
                                                             bsSize="sm" type="text"
                                                      />
                                                    </FormGroup>
                                                    <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                                            onClick={() => {
                                                              arrayHelpers.remove(index);
                                                              this.handleremoveProfessional('professionalfeestypes_set')
                                                            }
                                                            }>
                                                      Remove
                                                    </Button>
                                                  </div>
                                              )
                                          )}
                                          <div className="text-right">
                                            <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                                    onClick={() =>
                                                        arrayHelpers.push({
                                                          website: "",
                                                          website_type: "",
                                                        })
                                                    }>
                                              <FontAwesomeIcon icon="plus" /> Add More
                                            </Button>
                                          </div>
                                        </div>
                                    )}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <hr />

                        {/* Step 4 */}
                        <div className="mt-4">
                          <div className="text-primary font-weight-bold mb-2">
                            Step 4. Write a Review
                          </div>
                          <div className="pl-4">
                            <FormGroup check>
                              <Label className="fs-13 text-darker font-weight-bold" check>
                                <Input type="checkbox" name="is_draft" onChange={this.handleChange} />{' '} Thanks for adding a new listing! As
                                initial uploader, you get the honor of First Review! Write a
                                Review for this product now and immortalize your name in the
                                chronicles of wiki glory!
                              </Label>
                            </FormGroup>
                          </div>

                          <div className="text-right mt-3">
                            <Button color="primary" size="sm" onClick={toggleProfessionalModal}>

                              Cancel
                            </Button>
                            <Button color="primary" onClick={this.handleProfessionalSubmit} size="sm">
                              Add Professional
                            </Button>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                );
              }}
          />
          <Modal
              isOpen={this.state.uploadMediaPostModal}
              toggle={this.uploadMediaModalToggle}
          >
            <ModalHeader toggle={this.uploadMediaModalToggle}>
              UPLOAD MEDIA
            </ModalHeader>
            <ModalBody className="p-3">
              <Row>
                <Col xs={"auto"}>
                  <div className="bg-light h-100 p-3">
                    <Nav
                        tabs
                        className="flex-column active-transparent border-0 m-0"
                    >
                      <NavItem className="text-left">
                        <NavLink
                            className="pl-0 pr-4 border-0"
                            href="#"
                            active={this.state.selectedUpMediaType === "upload"}
                            onClick={() => {
                              this.handleOnClickSelectedUploadMediaType("upload");
                            }}
                        >
                          Upload
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <hr className="my-3 bg-tertiary" />
                      </NavItem>
                      <NavItem className="text-left border-0">
                        <NavLink
                            className="pl-0 pr-4 border-0"
                            href="#"
                            active={this.state.selectedUpMediaType === "gallery"}
                            onClick={() => {
                              this.handleOnClickSelectedUploadMediaType("gallery");
                            }}
                        >
                          Gallery
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <hr className="my-3 bg-tertiary" />
                      </NavItem>
                      <NavItem className="text-left border-0">
                        <NavLink
                            className="pl-0 pr-4 border-0"
                            href="#"
                            active={this.state.selectedUpMediaType === "embed"}
                            onClick={() => {
                              this.handleOnClickSelectedUploadMediaType("embed");
                            }}
                        >
                          Embed
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
                <Col>
                  <TabContent activeTab={this.state.selectedUpMediaType}>
                    <TabPane tabId="upload">
                      <div
                          className="mb-3 type-file-block bg-light"
                          hidden={
                            this.state.uploadMedia.uploadFiles &&
                            this.state.uploadMedia.uploadFiles.length > 0
                                ? true
                                : false
                          }
                      >
                        <Input
                            type="file"
                            name="upload_media_file"
                            id="uploadFilesPostFromSystem"
                            accept="image/*, video/*, audio/*"
                            onChange={this.handleOnFilePostUploadChange}
                            multiple
                        />
                        <Label for="uploadFilesPostFromSystem">
                          <div className="type-bg"></div>
                        </Label>
                      </div>
                      {this.state.uploadMedia.uploadFiles.length > 0 && (
                          <Row className="mb-3" form>
                            <Col xs={12}>
                              <div
                                  style={{
                                    maxWidth: "120px",
                                    margin: "0 1rem 1rem auto",
                                  }}
                              >
                                <div className="text-center mb-1 small">
                                  {this.state.uploadMedia.progress === 100 ? (
                                      <div className="text-success">
                                        <FontAwesomeIcon
                                            icon="check-circle"
                                            className="mr-1"
                                        />{" "}
                                        Uploaded
                                      </div>
                                  ) : (
                                      <div>
                                        Uploading{" "}
                                        <span className="text-success font-weight-bold ff-base">
                                      {this.state.uploadMedia.progress.toFixed(0)}
                                          %
                                  </span>
                                      </div>
                                  )}
                                </div>
                                <Progress
                                    value={this.state.uploadMedia.progress}
                                    style={{ height: "8px" }}
                                ></Progress>
                              </div>
                            </Col>
                            {this.state.uploadMedia.uploadFiles.map(
                                (file, index) => {
                                  return (
                                      <Col xs="auto">
                                        <div className="bg-light p-2">
                                          <div className="d-flex pr-3">
                                            <div>
                                              <div
                                                  className="selectable-media 123"
                                                  style={{ cursor: "default" }}
                                              >
                                                <div className="gallery-media">
                                                  {file.media_type === 'image' ?
                                                      <img src={file.url} alt="" /> :
                                                      <img src={file.thumbnail && file.thumbnail !== null ? file.thumbnail : file.url} alt="" />
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                            <div className="mx-n3 mt-2">
                                              <Button
                                                  color="delete-media _alt"
                                                  size="sm"
                                                  title="Remove Media"
                                                  hidden={file.id === "" ? true : false}
                                                  onClick={this.handleOnClickRemoveSelectedMedia(
                                                      file.id
                                                  )}
                                              >
                                                <FontAwesomeIcon icon="trash-alt" />{" "}
                                              </Button>
                                            </div>
                                          </div>
                                          <div className="mt-2">
                                            <Input
                                                bsSize="sm"
                                                type="text"
                                                className="mw-100 caption-input"
                                                name="caption"
                                                placeholder="Caption"
                                                onBlur={(e) =>
                                                    this.handleCaptionByIndex(e, index)
                                                }
                                                style={{ maxWidth: '160px' }}
                                            />
                                            <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_type_select" id="selectAlbumTypeData" className="mw-100 mt-1">
                                              <option value="">Choose Category</option>
                                              {file.media_type === "image" && fetchTaxonomyListingImages && Array.isArray(fetchTaxonomyListingImages) && fetchTaxonomyListingImages.length > 0 ?
                                                  fetchTaxonomyListingImages.map(item => (
                                                      <option value={item.id}>{item.label}</option>
                                                  ))
                                                  : null}
                                              {file.media_type === "video" && fetchTaxonomyListingVideos && Array.isArray(fetchTaxonomyListingVideos) && fetchTaxonomyListingVideos.length > 0 ?
                                                  fetchTaxonomyListingVideos.map(item => (
                                                      <option value={item.id}>{item.label}</option>
                                                  ))
                                                  : null}
                                            </Input>
                                          </div>
                                        </div>
                                      </Col>
                                  );
                                }
                            )}
                            <Col xs="auto">
                              <div className="d-flex pt-3">
                                <div className="selectable-media" hidden>
                                  <Label
                                      for="uploadFilesFromSystemMini"
                                      className="gallery-media"
                                      style={{
                                        borderStyle: "dashed",
                                        cursor: "pointer",
                                      }}
                                  >
                                    <div className="d-flex h-100 align-items-center justify-content-center">
                                      <span className="fs-14">Upload More</span>
                                    </div>
                                  </Label>
                                  <Input
                                      type="file"
                                      name="upload_media_file"
                                      id="uploadFilesFromSystemMini"
                                      accept="image/*, video/*"
                                      onChange={this.handleOnFilePostUploadChange}
                                      multiple
                                      style={{ display: "none" }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                      )}
                      <div className="d-flex mx-n2">
                        <div className="px-2">
                          <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="px-2 ml-auto">
                          <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                          >
                            {"OK"}
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="gallery">
                      <div className="mb-2">
                        <Nav tabs className="d-inline-flex mb-0">
                          <NavItem>
                            <NavLink
                                href="#"
                                active={this.state.showGalleryType === "images"}
                                onClick={() => {
                                  this.handleOnClickGalleryType("images");
                                }}
                            >
                              Image Gallery
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                                href="#"
                                active={this.state.showGalleryType === "videos"}
                                onClick={() => {
                                  this.handleOnClickGalleryType("videos");
                                }}
                            >
                              Video Gallery
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>
                      <TabContent activeTab={this.state.showGalleryType}>
                        <TabPane tabId="images">
                          <div>
                            <FormGroup className="form-row justify-content-end">
                              <Col xs="auto">
                                <Label className="small" for="selectAlbumType">
                                  Album Type:
                                </Label>
                              </Col>
                              <Col xs="auto">
                                <Input
                                    bsSize="sm"
                                    type="select"
                                    name="album_type_select"
                                    id="selectAlbumType"
                                    defaultValue={this.state.uploadMedia.albumType}
                                    onChange={this.handleOnClickAlbumTypeChange}
                                >
                                  <option value="">All</option>
                                  {this.state.uploadMedia.albumTypesList.length >
                                  0 &&
                                  this.state.uploadMedia.albumTypesList.map(
                                      (type) => {

                                        if (type.label) {
                                          return (
                                              <option value={type.category}>
                                                {type.category}
                                              </option>

                                          )
                                        }
                                      }
                                  )}
                                </Input>
                              </Col>
                            </FormGroup>
                          </div>
                          <div
                              className="px-3 my-3"
                              style={{ maxHeight: "350px", overflow: "auto" }}
                              ref={this.refGalleryImageScroll}
                              onScroll={this.handleScrollGalleryImage}
                          >
                            {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                                <Row>{this.fetchScrollPhotoMedia()}</Row>
                            ) : (
                                <div className="bg-white p-3">
                                  <h2 className="text-secondary-dark">No images</h2>
                                </div>
                            )}
                          </div>
                          <div className="d-flex mx-n2">
                            <div className="px-2">
                              <Button
                                  color="primary"
                                  size="sm"
                                  className="mw"
                                  onClick={this.uploadMediaModalToggle}
                              >
                                Cancel
                              </Button>
                            </div>
                            <div className="px-2 ml-auto">
                              <Button
                                  color="primary"
                                  size="sm"
                                  className="mw"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      uploadMediaPostModal: false,
                                    });
                                  }}
                              >
                                {"OK"}
                              </Button>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="videos">
                          <div
                              className="px-3 my-3"
                              style={{ maxHeight: "350px", overflow: "auto" }}
                              ref={this.refGalleryVideoScroll}
                              onScroll={this.handleScrollGalleryVideo}
                          >
                            {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                                <Row>{this.fetchScrollPhotoMedia(true)}</Row>
                            ) : (
                                <div className="bg-white p-3">
                                  <h2 className="text-secondary-dark">No videos</h2>
                                </div>
                            )}
                          </div>
                          <div className="d-flex mx-n2">
                            <div className="px-2">
                              <Button
                                  color="primary"
                                  size="sm"
                                  className="mw"
                                  onClick={this.uploadMediaModalToggle}
                              >
                                Cancel
                              </Button>
                            </div>
                            <div className="px-2 ml-auto">
                              <Button
                                  color="primary"
                                  size="sm"
                                  className="mw"
                                  onClick={() => {
                                    this.setState({
                                      ...this.state,
                                      uploadMediaPostModal: false,
                                    });
                                  }}
                              >
                                {"OK"}
                              </Button>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </TabPane>
                    <TabPane tabId="embed">
                      <div className="text-muted mb-2">
                        Submit the link for the image or video you want to embed{" "}
                      </div>
                      <div className="mb-2">
                        {/* Repeat this while adding */}
                        {this.mapEmbedLinks()}
                      </div>
                      <div className="d-flex mx-n2 mb-2">
                        <div className="px-2 ml-auto">
                          <Button
                              title="Add"
                              color="primary"
                              size="sm"
                              onClick={() => {
                                this.addEmbedlinkRow();
                              }}
                          >
                            <FontAwesomeIcon icon="plus" />
                          </Button>
                        </div>
                      </div>
                      <div className="d-flex mx-n2">
                        <div className="px-2">
                          <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="px-2 ml-auto">
                          <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.insertEmbedLinks();
                              }}
                          >
                            Insert
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>

    );
  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
      state.business.videos_children && state.business.videos_children[0],
  upload_scroll: state.myProfile.upload_scroll,
  upload_media_data: state.myProfile.upload_media_data,
  professionalSubcategory: state.footer.professionalSubcategory,
  professionalAnothSabcategory: state.footer.professionalAnothSabcategory,
  professionalOtherSabcategory: state.footer.professionalOtherSabcategory,
  fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
  album_types_list: state.user.album_types_list,
  album_type_data: state.user.album_type_data,
  fetch_save_response: state.professionals.fetch_save_response,
});

const mapProps = (dispatch) => ({
  media_upload_scroll: ({ type, album, page, force_update }) =>
      dispatch(media_upload_scroll({ type, album, page, force_update })),
  fetch_media_data: (type, album, page, user_profile) =>
      dispatch(fetch_media_data(type, album, page, user_profile)),
  clear_media_dispatch: () => dispatch(clear_media_dispatch()),
  initFetchProfessionalSubCategories: (categoryId, type) => dispatch(initFetchProfessionalSubCategories(categoryId, type)),
  clear_cat_sub_professional: () => dispatch(clear_cat_sub_professional()),
  add_professional: (data) => dispatch(add_professional(data)),
  upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
  fetch_taxonomy_list: (category, type) =>
      dispatch(fetch_taxonomy_list(category, type)),
  get_album_types_list: () => dispatch(get_album_types_list()),
  get_album_type_data: (type, album, page) =>
      dispatch(get_album_type_data(type, album, page)),
  delete_selected_gallery_media: (dataID) =>
      dispatch(delete_selected_gallery_media(dataID)),
  add_my_post: (data, type, page) => dispatch(add_my_post(data, type, page)),

});


export default withRouter(connect(mapState, mapProps)(ProfessionalModal));
