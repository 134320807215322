import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Markdown from "react-markdown";
import Carousel from "react-multi-carousel";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Embed from 'react-embed';
import _ from 'lodash';
import { debounce } from 'lodash';
import extractUrls from 'extract-urls'
import {
  Alert,
  Badge, Button, Col, FormGroup,
  Input, Label,
  Media, Modal,
  ModalBody,
  ModalFooter, ModalHeader, Nav,
  NavItem,
  NavLink, Progress, Row, TabContent,
  TabPane,
  UncontrolledTooltip
} from "reactstrap";
import {
  fetch_taxonomy_list,
  upload_add_captions
} from "../../../actions/myProfiles";
import {
  add_my_post, add_my_post_comment,
  add_my_post_reply, add_review_group, clear_search_people,
  clear_search_people_edit, delete_my_post, delete_my_post_comment, delete_selected_gallery_media,
  edit_my_post,
  getListOfPeople, get_album_types_list,
  get_album_type_data, get_my_posts_list,
  reset_my_posts_list,
  get_reviewgroup, get_search_people,
  get_search_people_edit,get_tags_list
} from "../../../actions/user";
import { callApi } from "../../../utils/apiCaller";
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from "../../atoms/editBtn";
import MentionInput from "./MentionInput";
import { find } from 'lodash'
import Loader from "../../atoms/loader";
import VideoPlaceholder from "./../../../assets/images/videopholder.jpg";
import { detectlink, mediaThumbnail, pushUniqueObjectsInArray } from "../../../utils/utilities";
import { embedNewLink } from "../../../actions/messages";
import ShowUploadedMedia from "../shared/ShowUploadedMedia/showUploadedMedia";


// const ComponentNew = (props) => {
//   const option = useEmbedly({
//     align: "left",
//   });
//   const documentIncludesEmbedly = {
//     nodeType: "document",
//     data: {},
//     content: [
//       {
//         nodeType: "paragraph",
//         data: {},
//         content: [
//           {
//             nodeType: "text",
//             value: `Embedded content: ${props. }`,
//             marks: [],
//             data: {},
//           },
//         ],
//       },
//     ],
//   };

//   let result = documentToReactComponents(documentIncludesEmbedly, option);

//   if (result && Array.isArray(result) && result.length) {
//     let mainKey = result[0];
//     if (Object.keys(mainKey).length > 1) {
//       return <div>{result}</div>;
//     } else {
//       return null;
//     }
//   }
// };

class Posts extends Component {
  constructor(props) {
    super(props);
    this.myScrollPhotoRef = React.createRef();
    this.myScrollVideoRef = React.createRef();
    this.myDivToFocus = React.createRef();

    this.refGalleryVideoScroll = React.createRef();
    this.refGalleryImageScroll = React.createRef();

    this.refScrollDeleteImages = React.createRef();
    this.refScrollDeleteVideos = React.createRef();

    this.state = {
      matches: null,
      clickedOutside: false,
      toggleShowPlaceolder: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      albumType: "",
      latestUploadedData: {},
      uploadCaptions: {},
      dataWantsToDelete: [],
      review_group_animation: {},
      fetchReviewGroup: [],
      confirmDeleteModal: false,
      limitedPostsModal: false,
      uploadMediaModal: false,
      uploadMediaPostModal: false,
      loading: true,
      viewReactedType: "all",
      viewPeopleWhoReactedModalToggle: false,
      sharePostModal: false,
      dropdownOpen: false,
      hoverwoohoo: false,
      hovernailed: false,
      hoverlol: false,
      hoverinsightful: false,
      inputFieldChange: false,
      hoverbummer: false,
      deletePostId: 0,
      deletePostType: "",
      fetchGalleryAlbum: "",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      myPostsList: {},
      replyFormVisible: {},
      editFormVisible: {},
      excludeListing: [],
      editExcludeListing: [],
      editPost: null,
      editEmbedBody: [],
      editPostType: "",
      deleteEntity: "",
      editPostError: "",
      hoverName: "",
      reviewAll: 0,
      addReply: {
        body: "",
      },
      addPost: {
        body: "",
        islabel: false,
      },
      peopleSearch: "",
      searchPeopleData: [],
      searchPeopleDataEdit: [],
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
      addComment: {},
      pageNo: 1,
      reactedPostId: 0,
      imageAttacment: "",
      hoverPostId: 0,
      fetchTaxomomyCategory: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      taxonomycategoryId: "",
      gifwoohoo: {},
      gifnailed: {},
      gifinsightful: {},
      giflol: {},
      gifbummer: {},
      woohooCount: {},
      nailedCount: {},
      insightfulCount: {},
      lolCount: {},
      bummerCount: {},
      pId: "",
      cmnId: "",
      uploadError: "",
      currentPageIndex: 1,
      checkNextPagination: false,
      loadingMoreData: false,
      embedBody:[],
      isLoadingSearchPeople: false,
      enableScrollTillEnd: true
    };
    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state;
    //get my posts list

    if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypesList: nextProps.album_types_list,
        },
        selectedUpMediaType: "upload",
        showGalleryType: "images",
      });
    }

    if (
      nextProps.album_type_data &&
      Object.keys(nextProps.album_type_data).length > 0
    ) {
      let item = nextProps.album_type_data;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypeData: nextProps.album_type_data,
        },
        fetchGalleryScroll,
        fetchGalleryCount,
      });
    }

    if (
      (nextProps.search_people_data.results &&
      Array.isArray(nextProps.search_people_data.results) && this.state.isLoadingSearchPeople)
    ) {
      this.setState({
        searchPeopleData: nextProps.search_people_data.results,
        isLoadingSearchPeople: false
      });
    }
    if (
      (nextProps.search_people_data_edit.results &&
      Array.isArray(nextProps.search_people_data_edit.results) && this.state.isLoadingSearchPeople)
    ) {
      this.setState({
        searchPeopleDataEdit: nextProps.search_people_data_edit.results,
        isLoadingSearchPeople: false
      });
    }

    if (nextProps.get_reveiewData) {
      this.setState({
        fetchReviewGroup: nextProps.get_reveiewData,
      });
    }

    if (nextProps.fetch_taxonomy_listData) {
      let taxCatListing = nextProps.fetch_taxonomy_listData;
      if (
        taxCatListing &&
        Array.isArray(taxCatListing) &&
        taxCatListing.length > 0
      ) {
        if (taxCatListing[0].isTypes === "images") {
          this.setState({
            fetchTaxonomyListingImages: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        } else {
          this.setState({
            fetchTaxonomyListingVideos: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        }
      }
    }

    if (nextProps?.my_posts_list && nextProps?.my_posts_list?.results && Array.isArray(nextProps?.my_posts_list?.results) && nextProps?.my_posts_list?.results?.length) {
      localStorage.setItem("loader", false);
      this.setState({loadingMoreData: false, loading: false})

      if (this.state.myPostsList?.results?.length > 0) {
        let addComment = this.state?.addComment;
        nextProps.my_posts_list.results.forEach((post) => {
          addComment[post.id] = "";
          if (post['body'] && post['body'] === "[]") post['body'] = post['body_preview'] || "";
          const editExcludeListing = post.exclude_list?.id;
          post.exclude_list = {
            [post.id]: editExcludeListing
          }
        });
        const updatedMyPostsList = pushUniqueObjectsInArray(this.state.myPostsList.results, nextProps.my_posts_list.results, "id")
        this.setState({
          myPostsList: {
            ...nextProps.my_posts_list,
            results: [...updatedMyPostsList],
          },
          addComment,
          enableScrollTillEnd: true,
          checkNextPagination: nextProps?.my_posts_list?.next 
        });
      } else {
          let addComment = {};
          nextProps.my_posts_list.results.forEach((post) => {
            addComment[post.id] = "";
          });
          this.setState({
            myPostsList: nextProps.my_posts_list,
            checkNextPagination: nextProps.my_posts_list?.next, 
            addComment,
          });
      }
    } else if (nextProps?.my_posts_list === null) {
      this.setState({
        myPostsList: {},
        checkNextPagination: false, 
        currentPageIndex: 1,
        loading: false
      });
    }
  }


  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):

    if (this.props.my_posts_list !== prevProps.my_posts_list) {

    //   if (
    //     this.props.my_posts_list &&
    //     Object.keys(this.props.my_posts_list).length > 0
    //   ) {
    //     let addComment = {};
    //     localStorage.setItem("loader", false);
    //     if (
    //       this.props.my_posts_list &&
    //       this.props.my_posts_list.results &&
    //       Array.isArray(this.props.my_posts_list.results) &&
    //       this.props.my_posts_list.results.length > 0
    //     ) {
    //       this.props.my_posts_list.results.forEach((post) => {
    //         addComment[post.id] = "";
    //       });
    //     }
    //     this.setState({
    //       myPostsList: this.props.my_posts_list,
    //       addComment: {
    //         ...addComment,
    //       },
    //       loading: false
    //     });
    //   }

    //   if (this.props.postId !== prevProps.postId && this.props.commentId !== prevProps.commentId) {
    //     this.setState({ pId: this.props.postId, cmnId: this.props.commentId })
    //   }

    //   //   this.fetchData(this.props.userID);
    // }

      if (
        this.state.myPostsList &&
        Object.keys(this.state.myPostsList).length > 0
      ) {
        let addComment = {};
        localStorage.setItem("loader", false);
        if (
          this.state.myPostsList &&
          this.state.myPostsList.results &&
          Array.isArray(this.state.myPostsList.results) &&
          this.state.myPostsList.results.length > 0
        ) {
          this.state.myPostsList.results.forEach((post) => {
            addComment[post.id] = "";
            if (post['body'] && post['body'] === "[]") post['body'] = post['body_preview'] || "";
            const editExcludeListing = post.exclude_list?.id;
            post.exclude_list = {
              [post.id]: editExcludeListing
            }
          });
        }
        this.setState({
          myPostsList: { ...this.state.myPostsList },
          addComment: {
            ...addComment,
          },
          loading: false
        });
      }

      if (this.props.postId !== prevProps.postId && this.props.commentId !== prevProps.commentId) {
        this.setState({ pId: this.props.postId, cmnId: this.props.commentId })
      }

      //   this.fetchData(this.props.userID);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({
      myPostsList: {
        results: []
      },
      currentPageIndex: 1,
      checkNextPagination: false,
      loading: true
    }, () => {
      Promise.all([
        this.props.reset_my_posts_list(),
        this.props.get_my_posts_list(this.state.pageNo),
        this.props.getListOfPeople(),
        this.props.clear_search_people(),
        this.props.clear_search_people_edit()
      ]).then(async () => {
        document.addEventListener("mousedown", this.handleClickOutside);
        if (Object.keys(this.state.myPostsList).length > 0 && this.state.pId != "") {
          this.timeoutff = await setTimeout(() => {

            let elementToScrollTo = document.getElementById("content_" + this.state.pId);
            if (elementToScrollTo != null) {
              elementToScrollTo.scrollIntoView({
                behavior: "smooth",
                block: "start"
              });
            }

          }, 5000);

        }
      })
      // this.setState({
      //   loading: false,
      // })
    });

    //if (this.myDivToFocus.current) {
    /*let fef = 16401;
  	
            let elementToScrollTo = document.getElementById("content_" + fef);
            const childOffset = 1;
            for (let i = 0; i < childOffset; i++) {
                if (!elementToScrollTo.previousElementSibling) {
                    break;
                }
                elementToScrollTo = elementToScrollTo.previousElementSibling;
            }
            elementToScrollTo.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });*/
    // }
    window.addEventListener('scroll', this.handleScroll, false);    
    window.addEventListener('scroll', this.handleScrollForMorePosts, false);
    window.addEventListener('resize', this.handleScroll, false);
    window.addEventListener('fullscreenchange',this.fullScreenChanged,false);
    window.addEventListener('webkitfullscreenchange',this.fullScreenChanged,false);
    window.addEventListener('mozfullscreenchange',this.fullScreenChanged,false);

  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("scroll", this.handleScroll);    
    window.removeEventListener("scroll", this.handleScrollForMorePosts);

  }

  //unmute video on fullscreen
  fullScreenChanged = (event) => {
    if (document.fullscreenElement && document.fullscreenElement instanceof HTMLVideoElement) {
      document.fullscreenElement.muted = false;
      event.target.play();
    }
    else {
      document.activeElement.muted = true;
    }
  }

  handleScroll = () => {
    var videos = document.getElementsByTagName("video");
    for (let i = 0; i < videos.length; i++) {

      let video = videos[i];

      const isInViewPort = (element) => {
        const rect = element.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }

      if (isInViewPort(video)) {
        video.play();
      } else {
        video.pause();
      }

    }
  }

  handleScrollForMorePosts = () => {
    let scrollTillHeight = window.pageYOffset;
    let lastElemHeight = document.getElementById("last-index")?.offsetTop;
    const {currentPageIndex, checkNextPagination, enableScrollTillEnd} = this.state;
    if (scrollTillHeight > lastElemHeight && checkNextPagination) {
        if (enableScrollTillEnd) {
            this.setState({ 
              currentPageIndex: currentPageIndex+1, 
              enableScrollTillEnd: false,
              checkNextPagination: false,
              loadingMoreData: true
              }, () => {
                this.props.get_my_posts_list(this.state.currentPageIndex)
            });
        }
    }
  }


  myRef = React.createRef();

  handleClickOutside = (e) => {
    if (!this.myRef.current?.contains(e.target)) {
      if (this.state.addPost.body === "") {
        if (this.state.toggleShowPlaceolder !== true) {
          this.setState({ toggleShowPlaceolder: true });
        }
      }
    }
  };

  // Post
  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  onMouseEnter() {
    this.setState({ dropdownOpen: true });
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false });
  }

  handleOnFilePostUploadChange = (event) => {
    let uploadFiles = event.target.files;

    //checking file types
    for (const key of Object.keys(uploadFiles)) {
      if (uploadFiles[key].type.includes('image') || uploadFiles[key].type.includes('video') || uploadFiles[key].type.includes('audio')) {

      } else {
        this.setState({ uploadError: 'Requested file format is not supported. Please enter valid format' })
        return false
      }
    }

    let showFiles = [];
    for (const key of Object.keys(uploadFiles)) {
      let itemType = uploadFiles[key].type.split("/");
      let extName = itemType[0];
      showFiles.push({ id: "", url: (extName === "image" || extName === "video") ? URL.createObjectURL(uploadFiles[key]) : require("../../../assets/images/blank.png") });
    }
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        uploadFiles: showFiles,
        progress: 0,
      },
      uploadError: undefined
    });
    let progressPart = 100 / showFiles.length;
    let progress = 0;
    for (const key of Object.keys(uploadFiles)) {
      let data = new FormData();
      data.append("file", uploadFiles[key]);

      callApi(
        `/upload/multiuploader/?album=feeds&instance=post&image=undefined&doc=undefined`,
        "POST",
        data,
        true
      ).then((response) => {
        this.handleOnClickSelectGalleryMedia(response);
        if (showFiles.length === 1 || key === showFiles.length - 1) {
          progress = 100;
        } else {
          progress = progress + progressPart;
        }
        if (response && response.code !== 400 && response.code !== 500) {
          showFiles[key].id = response.id;
          showFiles[key].media_type = response.media_type;
          showFiles[key].thumbnail = response.thumbnail;
          this.setState({
            ...this.state,
            uploadMedia: {
              ...this.state.uploadMedia,
              progress: progress,
              uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
              uploadedFiles: showFiles,
            },
          });
        }
      });
    }
  };

  handleCaptionByIndex = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    uploadCaptions[index] = value;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    let id = upload_data.id;
    this.setState({ uploadCaptions }, () =>
      this.props.upload_add_captions(id, data)
    );
  };

  handleChangeTaxonomyData = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    data.category = value;
    let id = upload_data.id;
    this.setState({ taxonomycategoryId: value }, () =>
      this.props.upload_add_captions(id, data)
    );
  };

  handleOnClickRemoveSelectedMedia = (id) => () => {
    let removeMediaIds = [];
    removeMediaIds.push(id);
    this.props.delete_selected_gallery_media(removeMediaIds);
    let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
      (file) => file.id !== id
    );
    let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
      (file) => file.id !== id
    );
    let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
      (item) => item !== id
    );
    let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
      (file) => file.id !== id
    );
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: selectedMedia,
        selectedMediaIds: selectedMediaIds,
        uploadedFiles: uploadedFiles,
        uploadFiles: uploadFiles,
      },
    });
  };

  handleOnClickRemoveSelectedGalleryMedia = (media, index) => {
    // remove media from upload media state
    let { uploadMedia, editPost } = this.state;
    let id = media.id;
    let filterSelectedMediaIds = [];
    let filterSelectedMedia = [];

    if (id) {
      filterSelectedMediaIds = uploadMedia?.selectedMediaIds.filter(data => data !== id);
      filterSelectedMedia = uploadMedia?.selectedMedia.filter(data => data.id !== id);
    }
    uploadMedia['selectedMediaIds'] = filterSelectedMediaIds;
    uploadMedia['selectedMedia'] = filterSelectedMedia;

    if (editPost) {
      editPost["messagemedia_set"] = filterSelectedMediaIds;
    }

    this.setState({ uploadMedia, editPost }, () => { this.props.delete_selected_gallery_media([id]) });
  };

  handleOnClickSelectGalleryMedia = (media) => {
    if (media) {
      let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
      let newSelectedMediaIds;
      let newSelectedMedia;
      if (index !== -1) {
        newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
          (item) => item !== media.id
        );
        newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
          (item) => item.id !== media.id
        );
      } else {
        newSelectedMediaIds = [
          ...this.state.uploadMedia.selectedMediaIds,
          media.id,
        ];
        newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
      }
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: newSelectedMedia,
          selectedMediaIds: newSelectedMediaIds,
        },
      });
    }
  };

  handleOnClickGalleryType = (type) => {
    let mediaType = "";
    if (type === "images") {
      mediaType = "image";
    } else if (type === "videos") {
      mediaType = "video";
    }
    this.props.get_album_type_data(mediaType, "", 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: type,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: mediaType,
        albumType: "",
        pageNo: 1,
      },
    });
  };

  handleOnClickAlbumTypeChange = (e) => {
    this.props.get_album_type_data("image", e.target.value, 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: "images",
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: e.target.value,
        pageNo: 1,
      },
    });
  };

  handleOnClickSelectedUploadMediaType = (type) => {
    if (type === "gallery") {
      this.props.get_album_type_data("image", "", 1);
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        showGalleryType: "images",
        uploadMedia: {
          ...this.state.uploadMedia,
          mediaType: "image",
          albumType: "",
          pageNo: 1,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "upload") {
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "embed") {
      let embedLinks = {
        0: "",
        // 1: "",
      };
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
        //   selectedMedia: [],
        //   selectedMediaIds: [],
          embedLinks: embedLinks,
        },
      });
    }
  };

  embedLinkOnChange = (id) => (e) => {
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        embedLinks: {
          ...this.state.uploadMedia.embedLinks,
          [id]: e.target.value,
        },
      },
    });
    
  };

  mapEmbedLinks = () => {
    let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
    return embedKeys.map((emKey) => {
      return (
        <div className="d-flex mx-n2 mb-2" key={emKey}>
          <div className="flex-fill px-2">
            <Input
              type="url"
              bsSize="sm"
              className="bg-white"
              value={this.state.uploadMedia.embedLinks[emKey]}
              onChange={this.embedLinkOnChange(emKey)}
              placeholder="Embeded link"
            />
          </div>
          <div className="px-2">
            <Button
              title="Remove"
              color="danger"
              size="sm"
              hidden={emKey <= 1 ? true : false}
              onClick={() => {
                this.deleteEmbedLinkRow(emKey);
              }}
            >
              <FontAwesomeIcon icon="minus" />
            </Button>
          </div>
        </div>
      );
    });
  };

  deleteEmbedLinkRow = (emKey) => {
    let embedLinks = this.state.uploadMedia.embedLinks;
    if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
      delete embedLinks[emKey];
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: embedLinks,
        },
      });
    }
  };
  addEmbedlinkRow = () => {
    let newEmbedLinks = {};
    for (let i = 2; i < 5; i += 1) {
      if (
        Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
        -1
      ) {
        newEmbedLinks[i] = "";
        break;
      }
    }
    if (Object.keys(newEmbedLinks).length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {
            ...this.state.uploadMedia.embedLinks,
            ...newEmbedLinks,
          },
        },
      });
    }
  };

  handleOnClickUploadMedia = () => {
    this.props.get_album_types_list();
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        progress: 0,
        uploadFiles: [],
        uploadedFiles: [],
      },
      uploadMediaPostModal: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      uploadError: ''
    });
  };
  truncate = (filenameString) => {
    let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
    let extension = filenameString.substr(
      filenameString.lastIndexOf("."),
      filenameString.length - 1
    );
    let partial = filename.substring(filename.length - 3, filename.length);
    filename = filename.substring(0, 15);
    return filename + "..." + partial + extension;
  };

  confirmDeleteModalToggle = () => {
    if (this.state.deletePostId) {
      this.setState({ deletePostId: 0, deletePostType: "" });
    }
    this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
  };

  limitedPostsModalToggle = () => {
    this.setState({ limitedPostsModal: !this.state.limitedPostsModal });
  };

  uploadMediaModalToggle = () => {
    this.setState({
      ...this.state,
      uploadMediaPostModal: !this.state.uploadMediaPostModal,
      // uploadMedia: {
      //   ...this.state.uploadMedia,
      //   selectedMedia: [],
      //   selectedMediaIds: [],
      //   mediaType: "image",
      //   albumType: "",
      //   pageNo: 1,
      //   albumTypeData: {},
      //   embedLinks: {},
      //   uploadedFiles: [],
      //   uploadFiles: [],
      // },
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      taxonomycategoryId: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      uploadError: '',
    });
  };

  toggleReplyForm = (id, level) => {
    id = level + id;
    this.setState({
      ...this.state,
      replyFormVisible: {
        [id]: !this.state.replyFormVisible[id],
      },
      editFormVisible: {},
      addReply: {
        body: "",
      },
    });
  };
  toggleEditForm = (id, level, type = "post", body = "") => {
    let post;
    let postType;
    let selectedMedia = [];
    let selectedMediaIds = [];
    let editExcludeListing = [];
    let editEmbedBody = [];
    if (type === "post") {
      if (this.state.editFormVisible[level + id]) {
        post = null;
        postType = "";
        selectedMedia = [];
        selectedMediaIds = [];
      } else {
        post = this.state.myPostsList.results.filter(
          (post) => id === post.id
        )[0];
        postType = "post";
        selectedMedia = post?.attachments;
        selectedMediaIds =
          post.attachments.length > 0
            ? selectedMedia.map((item) => item.id)
            : [];
        editExcludeListing = post?.exclude_list?.[post.id];
        editEmbedBody = (post?.embeded_link && Array.isArray(post?.embeded_link) && post?.embeded_link?.length) ? [...post?.embeded_link] : []
      }
      // this.setState({edit_item:post.body})	

    } else if (type === "comment") {
      if (this.state.editFormVisible[level + id]) {
        post = null;
        postType = "";
      } else {
        postType = "comment";
        post = {
          id: id,
          msg_id: id,
          editpost: true,
          body: body,
          body_text: body,
          attachments: [],
          messagemedia_set: [],
          exclude_list: [],
          editExcludeListing: []
        };
      }
    }
    id = level + id;
    this.setState({
      ...this.state,
      editPost: post,
      editPostType: postType,
      editFormVisible: {
        [id]: !this.state.editFormVisible[id],
      },
      replyFormVisible: {},
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: selectedMedia,
        selectedMediaIds: selectedMediaIds,
      },
      editPostError: "",
      edit_item: post?.body_text,
      toggleShowEditPlaceolder: post?.body_text ? false : true,
      editExcludeListing: editExcludeListing,
      editEmbedBody
    }, () => {
      setTimeout(() => {
        let element = document.getElementById("edit-post")
        if (element) {
          element.selectionStart = element.value.length;
          element.selectionEnd = element.value.length;
        }

      }, 1000)
    });


  };

  insertEmbedLinks = (e) => {

    let embedValues = Object.values(this.state.uploadMedia.embedLinks).filter(
      (item) => item !== ""
    );

    if (embedValues.length > 0) {
      embedValues = embedValues.map(
        // (item) => "<a href=" + item + "><p>" + item + "</p></a>"
        (item) => item
      );
      embedValues.forEach(item=>{
        this.AddNewEmbedLink(e,item)
      })
      
      // let newPost = {
      //   video: embedValues.join("\n"),
      //   exclude_list: [],
      //   question_rating_category: [],
      //   messagemedia_set: [],
      // };
      // this.props.reset_my_posts_list();
      // this.props.add_my_post(newPost, this.state.filterType, this.state.pageNo);
      // resetting

      this.setState({
        ...this.state,
        // limitedPostsModal:
        //   this.state.myPostsList.count < this.state.limitedPostsCount
        //     ? false
        //     : true,
        // uploadMedia: {
        //   ...this.state.uploadMedia,
        //   selectedMedia: [],
        //   selectedMediaIds: [],
        //   embedLinks: {},
        // },
        uploadMediaPostModal: false,
      });
    }
    //}
  };

  AddNewEmbedLink = async (e, embeddedLink) => {
   e.preventDefault();
    let { embedBody, editPost, editEmbedBody } = { ...this.state };
    // this.props.embedNewLink({
    //     data: {
    //         "video": `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
    //         "exclude_list": [],
    //         "body_preview": `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
    //         "question_rating_category": [],
    //         "messagemedia_set": []
    //     }
    // });
    
    let thumbnail = await mediaThumbnail(embeddedLink);
    const new_embeded_link = {
      type: "embedded_link",
      url: embeddedLink,
      thumbnail
    }
    
    if (!editPost) {
      embedBody.push(new_embeded_link);
      this.setState({ embedBody: embedBody })
    } else {
      editEmbedBody.push(new_embeded_link);
      this.setState({ editEmbedBody });
    }
           
    
    
}

  handleCreatePostOnChange = (e) => {

    let expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    
    let itemsGet = e.target.value.match(expression);
    if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
      itemsGet = [...new Set(itemsGet)];
    }
    
    this.setState({
      // ...this.state,
      addPost: {
        ...this.state.addPost,
        isLabel: true,
        body: e.target.value,
        toggleShowPlaceolder: e.target.value.length ? false : true,
      },
      matches: itemsGet,
    });

  
    // if (e.target.value.length) {

    //   var str = e.target.value;
    //   var n = str.lastIndexOf('@');
    //   var result = str.substring(n + 1);

    //   console.log(result,'result');
      
    //   const data = {
    //     //query: e.target.value.replace(/@/g, ''),
    //     query: result,
    //   };
    //   this.props.get_tags_list(data) 
    // }
  };

  handleOnClearCreate = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      addPost: {
        ...this.state.addPost,
        body: "",
      },
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
      },
    });
  };

  handleOnSubmitCreatePost = (e) => {
    e.preventDefault();
    const { excludeListing } = this.state;

    const tag_list = this.getTaggedPeopleList(this.state.addPost?.body)
    const listing_tag_list = this.getTaggedBusinessList(this.state.addPost?.body)

    const otherDetails = {
      exclude_list: excludeListing,
      tag_list: tag_list,
      listing_tag_list: listing_tag_list,
      question_rating_category: [],
      messagemedia_set: this.state.uploadMedia.selectedMediaIds,
      video:this.state.embedBody
    };

    let post = this.state.addPost;
    if ((post.body && post.body.replace(/(<([^>]+)>)/gi, "") !== "") || otherDetails.messagemedia_set.length > 0) {
      post = {
        ...this.state.addPost,
        ...otherDetails,
        body_preview: (post.body && post.body.replace(/(<([^>]+)>)/gi, "") !== "") ? this.state.addPost.body : '',
      };
      this.props.reset_my_posts_list();
      this.props.add_my_post(post, this.state.filterType, this.state.pageNo);
      this.setState({
        ...this.state,
        addPost: {
          body: "",
        },
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
        },
        exclude_list: [],
        excludeListing: [],
        peopleSearch: "",
        peopleSearchEdit: "",
        matches: [],
        embedBody:[], 
        loading: true
      }, () => {
        this.handleOnClearCreate(e);
      });
    }
  };

  debounceSearchPeople = debounce(data => {
    this.setState({
      isLoadingSearchPeople: true
    })
    this.props.get_search_people(data);
  }, 500)

  debounceSearchPeopleEdit = debounce(data => {
    this.setState({
      isLoadingSearchPeople: true
    })
    this.props.get_search_people_edit(data);
  }, 500)

  handlePeopleSearch = (evt) => {
    if (evt.target.value.length) {
      const data = {
        query: evt.target.value,
      };
      this.setState({ peopleSearch: evt.target.value }, () => {
        this.debounceSearchPeople(data);
      });
    } else {
      this.setState({ searchPeopleData: [], peopleSearch: "" });
    }
  };

  handlePeopleSearchEdit = (evt) => {
    if (evt.target.value.length) {
      const data = {
        query: evt.target.value,
      };
      this.setState({ peopleSearchEdit: evt.target.value }, () => {
        this.debounceSearchPeopleEdit(data);
      });
    } else {
      this.setState({ searchPeopleDataEdit: [], peopleSearchEdit: "" });
    }
  };

  handleOnDeleteConfirmation = () => {
    let message = ""
    if (this.state.deletePostId && this.state.deletePostType === "post") {
      message = "Post deleted successfully!";
      this.props.delete_my_post(
        this.state.deletePostId,
        this.state.filterType,
        this.state.pageNo,
        message
      );
    } else if (
      this.state.deletePostId &&
      (this.state.deletePostType === "comment" ||
        this.state.deletePostType === "reply")
    ) {


      message = "Your comment has been deleted.";
      this.props.delete_my_post(
        this.state.deletePostId,
        this.state.filterType,
        this.state.pageNo,
        message
      );
    }
    this.props.reset_my_posts_list();
    // this.props.get_my_posts_list(1);
    this.setState({
      confirmDeleteModal: !this.state.confirmDeleteModal,
      deletePostId: 0,
      deletePostType: "",
    });
  };

  handleOnReplyChange = (e) => {
    this.setState({
      ...this.state,
      addReply: {
        ...this.state.addReply,
        body: e.target.value,
      },
    });
  };

  handleOnReplySubmit = (id, level) => (e) => {
    e.preventDefault();
    const otherDetails = {
      answer_id: id,
      //biz_msg : "feed",
      type: "biz_msg",
    };

    const reply = { ...otherDetails, body: this.state.addReply.body };
    if (reply.body && reply.body.replace(/(<([^>]+)>)/gi, "") !== "") {
      this.props.add_my_post_reply(
        reply,
        this.state.filterType,
        this.state.pageNo
      );
      this.setState({
        ...this.state,
        replyFormVisible: {
          [level + id]: !this.state.replyFormVisible[level + id],
        },
        editFormVisible: {},
        addReply: {
          body: "",
        },
        peopleSearch: "",
        peopleSearchEdit: "",
        myPostsList: {},
        loading: true
      });
    }
  };
  handleOnCommentChange = (id) => (e) => {
    this.setState({
      ...this.state,
      addComment: {
        ...this.state.addComment,
        [id]: e.target.value,
      },
    });
  };
  handleOnCommentSubmit = (id) => (e) => {
    e.preventDefault();
    const { editExcludeListing } = this.state;
    const otherDetails = {
      owner_premium: "False",
      [id]: this.state.addComment[id],
      exclude_list: editExcludeListing,
      editExcludeListing: editExcludeListing,
      tag_list: [],
      listing_tag_list: [],
      question_rating_category: [],
      messagemedia_set: [],
    };

    let comment = this.state.addComment;


    let limitedPostsModalValue = false;
    if (comment[id].trim() !== "") {

      comment = { body: this.state.addComment[id], body_preview: this.state.addComment[id], ...otherDetails };
      this.props.add_my_post_comment(
        id,
        comment,
        this.state.filterType,
        this.state.pageNo
      );

      this.setState({
        ...this.state,
        limitedPostsModal: limitedPostsModalValue,
        addComment: {
          ...this.state.addComment,
          [id]: "",
        },
        peopleSearch: "",
        peopleSearchEdit: "",
        myPostsList: {},
        loading: true
      });
    }
  };

  handleEditPostOnChange = (e) => {
    let expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    let itemsGet = e.target.value.match(expression);

    if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
      itemsGet = [...new Set(itemsGet)];
    }

    this.setState({
      ...this.state,
      editPost: {
        ...this.state.editPost,
        isLabel: true,
        body_text: e.target.value,
        toggleShowEditPlaceolder: e.target.value.length ? false : true,
      },
      matches: itemsGet,
      inputFieldChange: true,
      editPostError: ""
    });
  };

  handleOnClearEdit = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      editPost: {
        ...this.state.editPost,
        body_text: "",
        attachments: [],
        messagemedia_set: []
      },
      editEmbedBody: [],
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: [],
        selectedMediaIds: [],
      },
      editPostError: "",
    });
  };

  handleOnSubmitEditPost = (e) => {
    e.preventDefault();


    const { editExcludeListing, edit_item, editEmbedBody } = this.state;
    let post = this.state.editPost;

    let messagemediaSet = [];

    if (
      this.state.uploadMedia.selectedMediaIds.length > 0 &&
      this.state.editPost
    ) {
      messagemediaSet = this.state.uploadMedia.selectedMediaIds;
    } else {
      messagemediaSet = this.state.editPost.messagemedia_set;
    }

    if (post.body_text === "" && messagemediaSet.length == 0 && !editEmbedBody.length ) {
      this.setState({
        editPostError:
          "Please provide message content or photos/video to send this message",
      });

    } else if (this.compareMediaChanges(post) && post.body_text == edit_item) {
      this.setState({
        editPostError:
          "No Change Was Reported! Please change either media or text.",
      });

    } else if ((post.body_text && post.body_text.replace(/(<([^>]+)>)/gi, "") !== "" && post.body_text !== "") || (messagemediaSet.length > 0)) {
      post = {
        ...this.state.editPost,
        editpost: true,
        body: post.body === "<p></p>" ? '' : this.state.editPost.body_text,
        body_preview: post.body === "<p></p>" ? '' : this.state.editPost.body_text,
        body_text: this.state.editPost.body_text,
        messagemedia_set: messagemediaSet,
        attachments:
          this.state.uploadMedia.selectedMedia.length > 0 && this.state.editPost
            ? this.state.uploadMedia.selectedMedia
            : this.state.editPost.attachments,
        exclude_list: editExcludeListing,
        video:editEmbedBody
      };

      localStorage.setItem("loader", true);

      this.props.edit_my_post(
        post,
        this.state.editPostType,
        this.state.filterType,
        this.state.pageNo
      );

      let level;
      if (this.state.editPostType === "post") {
        level = "L1";
      } else if (this.state.editPostType === "comment") {
        level = "L2";
      }
      this.setState({
        ...this.state,
        editPost: null,
        editPostType: "",
        editFormVisible: {
          [level + post.id]: !this.state.editFormVisible[level + post.id],
        },
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
        },
        editExcludeListing: [],
        peopleSearch: "",
        peopleSearchEdit: "",
        inputFieldChange: false,
        editPostError: '',
        myPostsList: {},
        loading: true,
        editEmbedBody:[]
      });
    }
  };


  compareMediaChanges = (editingPost) => {
    const { editEmbedBody, uploadMedia, myPostsList } = this.state;
    let isMediaNotChange = true;
    if (Array.isArray(myPostsList.results) && myPostsList.results.length) {
      if (uploadMedia?.selectedMedia && Array.isArray(uploadMedia?.selectedMedia) && Array.isArray(editingPost.attachments)) {
        if (uploadMedia?.selectedMedia.length !== editingPost.attachments.length) {
          isMediaNotChange = false;
        }
      }
      if (editEmbedBody && editEmbedBody.length !== editingPost?.embeded_link?.length) {
        isMediaNotChange = false;
      }
    }
    return isMediaNotChange;
  }

  handleMouseIn(event) {
    var name = "";
    if (event.target.getAttribute("data-name") !== null) {
      name = event.target.getAttribute("data-name");
    }
    var id = 0;
    if (event.target.getAttribute("data-id") !== null) {
      id = parseInt(event.target.getAttribute("data-id"));
    }
    if (name === "bummer") {
      this.setState({ hoverbummer: true, hoverPostId: id });
    } else if (name === "lol") {
      this.setState({ hoverlol: true, hoverPostId: id });
    } else if (name === "nailed") {
      this.setState({ hovernailed: true, hoverPostId: id });
    } else if (name === "insightful") {
      this.setState({ hoverinsightful: true, hoverPostId: id });
    } else if (name === "woohoo") {
      this.setState({ hoverwoohoo: true, hoverPostId: id });
    }
    if (name !== "" && id !== 0) {
      let assetType = "post";
      this.props.get_reviewgroup(assetType, id, name);
    }
  }

  handleMouseOut(event) {
    var name = "";
    if (event.target.getAttribute("data-name") !== null) {
      name = event.target.getAttribute("data-name");
    }
    var id = 0;
    if (event.target.getAttribute("data-id") !== null) {
      id = parseInt(event.target.getAttribute("data-id"));
    }
    if (name === "bummer") {
      this.setState({ hoverbummer: false, hoverPostId: id });
    } else if (name === "lol") {
      this.setState({ hoverlol: false, hoverPostId: id });
    } else if (name === "nailed") {
      this.setState({ hovernailed: false, hoverPostId: id });
    } else if (name === "insightful") {
      this.setState({ hoverinsightful: false, hoverPostId: id });
    } else if (name === "woohoo") {
      this.setState({ hoverwoohoo: false, hoverPostId: id });
    }
    if (name !== "" && id !== 0) {
      let assetType = "post";
      this.props.get_reviewgroup(assetType, id, name);
    }
  }
  handleOnClickReviewType = (post, postId, type) => {
    let reviewAl = 0;
    reviewAl =
      post.review_group_count.lol +
      post.review_group_count.woohoo +
      post.review_group_count.insightful +
      post.review_group_count.bummer +
      post.review_group_count.nailed;
    this.setState({
      viewPeopleWhoReactedModalToggle: !this.state
        .viewPeopleWhoReactedModalToggle,
      review_group_animation: post,
      reviewAll: reviewAl,
      viewReactedType: type,
      reactedPostId: postId,
    });
  };

  handleOnClickReacted = (type) => {
    const { reactedPostId } = this.state;
    if (type !== "" && reactedPostId !== 0) {
      let assetType = "post";
      this.props.get_reviewgroup(assetType, reactedPostId, type);
      this.setState({ viewReactedType: type });
    }
  };

  handleScrollGalleryImage = (e) => {
    let { refGalleryImageScroll } = this;
    let { fetchGalleryAlbum } = this.state;

    let clientHeight = refGalleryImageScroll.current.clientHeight;
    let scrollHeight = refGalleryImageScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "image",
        album: fetchGalleryAlbum,
        force_update: false,
      });
    }
  };

  fetchGalleryOnScroll = ({ type, album, force_update }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state;
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;
      if (nextCount <= roundValue) {
        let page = nextCount;
        this.props.get_album_type_data(type, "", page);
      }
    }
  };

  handleScrollGalleryVideo = (e) => {
    let { refGalleryVideoScroll } = this;
    // let count = 0;
    let clientHeight = refGalleryVideoScroll.current.clientHeight;
    let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "video",
        album: "",
        force_update: false,
      });
    }
  };

  handleSelect = (e, item) => {
    e.preventDefault();
    const { excludeListing } = this.state;
    let el = excludeListing.find((itm) => itm.id === item.id);
    if (!el) {
      excludeListing.push(item);
    }
    this.setState({ peopleSearch: "", searchPeopleData: [] });
  };

  removeSelect = (e, id) => {
    e.preventDefault();
    const { excludeListing } = this.state;
    let el = excludeListing.find((itm) => itm.id === id);
    if (el) {
      excludeListing.splice(excludeListing.indexOf(el), 1);
    }
    this.setState({ excludeListing });
  };

  removeSelectEdit = (e,postId, id) => {
    e.preventDefault();
    const { editExcludeListing } = this.state;
    let el = editExcludeListing[postId].find((itm) => itm.id === id);
    if (el) {
      editExcludeListing[postId].splice(editExcludeListing[postId].indexOf(el), 1);
    }
    this.setState({ editExcludeListing });
  };

  handleSelectEdit = (e,postId, item) => {
    e.preventDefault();
    const { editExcludeListing } = this.state;
    let el = editExcludeListing[postId]?.find((itm) => itm.id === item.id);
    if (!el) {
      editExcludeListing[postId].push(item);
    }
    this.setState({ peopleSearchEdit: "", searchPeopleDataEdit: [], inputFieldChange: false });
  };

  addreviewGroup = async (show, id, type) => {
    let {
      gifbummer,
      giflol,
      gifnailed,
      gifinsightful,
      gifwoohoo,
      woohooCount,
      nailedCount,
      insightfulCount,
      lolCount,
      bummerCount,
    } = this.state;
    let data = {};
    data.post_id = id;
    data.type = type;
    if (type == "bummer") {
      let bummerNumber = 0;
      if (bummerCount[id] == 1) {
        gifbummer[id] = !show;
        bummerNumber = 0;
      } else {
        bummerNumber = 1;
        gifbummer[id] = show;
      }
      bummerCount[id] = bummerNumber;
      this.setState({ gifbummer, bummerCount });
    } else if (type == "lol") {
      let lolNumber = 0;
      if (lolCount[id] == 1) {
        giflol[id] = !show;
        lolNumber = 0;
      } else {
        giflol[id] = show;
        lolNumber = 1;
      }
      lolCount[id] = lolNumber;
      this.setState({ giflol, lolCount });
    } else if (type == "nailed") {
      let nailedNumber = 0;
      if (nailedCount[id] == 1) {
        gifnailed[id] = !show;
        nailedNumber = 0;
      } else {
        gifnailed[id] = show;
        nailedNumber = 1;
      }
      nailedCount[id] = nailedNumber;
      this.setState({ gifnailed, nailedCount });
    } else if (type == "insightful") {
      let insightfulNumber = 0;
      if (insightfulCount[id] == 1) {
        gifinsightful[id] = !show;
        insightfulNumber = 0;
      } else {
        gifinsightful[id] = show;
        insightfulNumber = 1;
      }
      insightfulCount[id] = insightfulNumber;
      this.setState({ gifinsightful, insightfulCount });
    } else if (type == "woohoo") {
      let wooNumber = 0;
      if (woohooCount[id] > 0) {
        gifwoohoo[id] = !show;
        wooNumber = 0;
      } else {
        gifwoohoo[id] = show;
        wooNumber = 1;
      }
      woohooCount[id] = wooNumber;
      this.setState({ gifwoohoo, woohooCount });
    }
    this.timeout = await setTimeout(() => {
      gifbummer[id] = !show;
      gifwoohoo[id] = !show;
      gifinsightful[id] = !show;
      gifnailed[id] = !show;
      giflol[id] = !show;
      this.setState({
        gifwoohoo,
        giflol,
        gifnailed,
        gifinsightful,
        gifbummer,
      });
    }, 500);
    this.props.add_review_group(data);

  };

  componentWillMount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutff);
    this.setState({ excludeListing: [], editExcludeListing: {} });
  }

  handleOpenModal(data, url) {
    this.setState({
      viewMyPhotosModal: !this.state.viewMyPhotosModa,
      imageAttacment: data,
    });
  }

  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = <img src={VideoPlaceholder} alt="" />;

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach((item) => {
        if (
          fetchGalleryScroll[item] &&
          fetchGalleryScroll[item].results !== null
        ) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map((item) => {
          let itemName = (item?.thumbnail).split(".");
          let itemFile = (item?.file).split(".");
          let extName = itemName[itemName.length - 1];
          let extFile = itemFile[itemFile.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;

          return (
            <Col xs="auto" className="mb-3">
              <FormGroup
                className="checkable-media"
                check
                style={{ width: "120px" }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={() => this.handleOnClickSelectGalleryMedia(item)}
                  />
                  <div className="gallery-media">
                    <img
                      className="img-fluid"
                      src={isVideo ? srcFile : item?.file}
                      alt={item?.filename ? item.filename : "file"}
                      onError={(error) => (error.target.src = require("../../../assets/images/videopholder.jpg"))}
                    />
                  </div>
                </Label>
                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
                  {item.caption ? item.caption : "No Caption"}
                </div>
                <div className="font-weight-normal fs-14 d-block">
                  {item.category ? item.category : "No Category"}
                </div>
              </FormGroup>
            </Col>
          );
        });
      }
    } else {
      return getDataItems;
    }
  };

  fetchReviewGroupList = (type) => {
    let fetchReviewGroup = this.state.fetchReviewGroup;
    if (fetchReviewGroup && fetchReviewGroup.length > 0) {
      return fetchReviewGroup.map((item, index) => {
        //if(item.group_icon === type){
        return (
          <li className="mb-3" key={index}>
            <div className="d-flex">
              <div className="mr-2 media media-post">
                <img
                  className="media-object"
                  width="50"
                  height="50"
                  src={
                    item.profile_pic
                      ? item.profile_pic
                      : require("../../../assets/images/icons/user-circle.png")
                  }
                  onError={(error) => {
                    (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                  }}
                  alt={item?.name || "UserImage"}
                />
              </div>
              <div>
                <a
                  href={item.redirect_to}
                  className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                >
                  {" "}
                  {item.name}
                </a>
                {type === "all" ? (
                  <img
                    src={require("../../../assets/images/icons/" +
                      item.group_icon +
                      "-active.png")}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </li>
        );
        //}
      });
    } else {
      return (
        <div className="text-secondary-dark font-weight-bold">
          No People Reacted.
        </div>
      );
    }
  };

  // renderLinksWrapper = ({ body }) => {
  //   let expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
  //   let itemsGet = body.match(expression);

  //   if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
  //     itemsGet = [...new Set(itemsGet)];

  //     if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
  //       return itemsGet.map((item, index) => (
  //         <div key={index}>
  //           <Embed url={item} />
  //           {/* <ComponentNew embedData={item} /> */}
  //         </div>
  //       ));
  //     }
  //   }
  // };

  renderLinksWrapper = ({ body, index }) => {
    let expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    let itemsGet = body?.url?.match(expression);    
    if(itemsGet!==null){
        if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
            itemsGet = [...new Set(itemsGet)];          
            if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
              return itemsGet.map((item, index) => (
                <div className="outer-link" key={index}>
                  <Embed url={item} />
                  {/* <ComponentNew embedData={item} /> */}
                </div>
              ));
            }
          }
    }else{
        return <img
        className="img-fluid img-thumbnail h-100"
        src={body.thumbnail || require("../../../assets/images/embedded_link_img.png")}
        id={`embedded_link-${index}`}
        alt={body.url}
        style={{width:"100%"}}
    />
    }        
  };

  getTaggedPeopleList(str) {
    const regexp = /\(([^)]+)\)/g
    const Ids = []
    // regular expression explaination:   
    // \( being opening brace, ( — start of subexpression, [^)]+ — anything but 
    // closing parenthesis one or more times (you may want to 
    //   replace + with *), ) — end of subexpression, \) — closing brace.
    //    The match() returns an array ["(my)","my"] from which the second element is extracted.

    const matchedArray = [...str.matchAll(regexp)];
    if (Array.isArray(matchedArray) && matchedArray.length > 0) {

      for (let matched of matchedArray) {

        if (Array.isArray(matched) && matched[1]) {
          let peopleUserName = matched[1].split('/people/')
          if (Array.isArray(peopleUserName) && peopleUserName[1]) {
            let people = find(this.props.peopleList, { username: peopleUserName[1] })
            if (people)
              Ids.push(people.id)
          }
        }
      }
    }



    return Ids
  }

  getTaggedBusinessList(str) {
    const regexp = /\(([^)]+)\)/g
    const Ids = []

    const matchedArray = [...str.matchAll(regexp)];
    if (Array.isArray(matchedArray) && matchedArray.length > 0) {

      for (let matched of matchedArray) {

        if (Array.isArray(matched) && matched[1]) {
          let peopleUserName = matched[1].split('/business/')
          if (Array.isArray(peopleUserName) && peopleUserName[1]) {
              Ids.push(Number(peopleUserName[1]))
          }
        }
      }

    }
    return Ids
  }


  removeEmbeddedFromUploaded = (index,item) => {              
        
    const embedBodyCopy = _.cloneDeep(this.state.embedBody)
    let { editEmbedBody, editPost } = { ...this.state };

    if (index > -1) {
      if (editPost &&  editEmbedBody?.length) {
        editEmbedBody.splice(index, 1);
      }
      else {
        embedBodyCopy.splice(index, 1);
      }
    }
    this.setState({ embedBody: embedBodyCopy, editEmbedBody });
}

 transform=(node)=> {
  // do not render any <span> tags
  if (node.type === 'tag' && node.name === 'a') {
    return null;
  }
}
  //end the post
  render() {
    const { notPeopleTab = true } = this.props;
    let {
      myPostsList,
      replyFormVisible,
      editFormVisible,
      editPost,
      hoverbummer,
      hoverinsightful,
      hoverlol,
      hovernailed,
      hoverwoohoo,
      fetchReviewGroup,
      review_group_animation,
      reviewAll,
      searchPeopleData,
      excludeListing,
      editExcludeListing,
      searchPeopleDataEdit,
      imageAttacment,
      hoverPostId,
      matches,
      gifwoohoo,
      gifnailed,
      gifinsightful,
      giflol,
      gifbummer,
      woohooCount,
      nailedCount,
      insightfulCount,
      lolCount,
      bummerCount,
      loading,
      embedBody
    } = this.state;

    const tooltipBummer = {
      display: this.state.hoverbummer ? "block" : "none",
    };
    const tooltipLol = {
      display: this.state.hoverlol ? "block" : "none",
    };
    const tooltipNailed = {
      display: this.state.hovernailed ? "block" : "none",
    };
    const tooltipWoohoo = {
      display: this.state.hoverwoohoo ? "block" : "none",
    };
    const tooltipInsightful = {
      display: this.state.hoverinsightful ? "block" : "none",
    };

    const responsive = {
      videoModal: {
        mobile: {
          breakpoint: { max: 9999999, min: 0 },
          items: 1,
        },
      },
      viewGalleryImages: {
        desktop: {
          breakpoint: { max: 9999999, min: 1200 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 1199, min: 576 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1,
        },
      },
    };
    return (
      <>
        {notPeopleTab &&
          <FormGroup className="main-post-formgroup">
            <div className="input-label-block">
              <span
                ref={this.myRef}
                onClick={() => {
                  this.setState({ toggleShowPlaceolder: false }, () => {
                    document.getElementById("post").focus();
                  });
                }}
              >
                <MentionInput

                  value={this.state.addPost.body.replace(/(<([^>]+)>)/gi, "")}
                  onChange={this.handleCreatePostOnChange}
                  peopleList={this.props.peopleList && this.props.peopleList}
                  placeholder={""}
                  my_profile={this.props.profile_data}
                />
                {/* <MentionInput

                  value={this.state.addPost.body.replace(/(<([^>]+)>)/gi, "")}
                  onChange={this.handleCreatePostOnChange}
                   //peopleList={this.props.post_tag_list && this.props.post_tag_list}
                  peopleList={this.props.peopleList &&  this.props?.peopleList}
                  placeholder={""}
                /> */}
              </span>
              {this.state.toggleShowPlaceolder && (
                <div
                  onClick={() => {
                    this.setState({ toggleShowPlaceolder: false }, () => {
                      document.getElementById("post").focus();
                    });
                  }}
                  className="post-placeholder-static wk-profile-post-static"
                >
                  <div className="text-center">{"What's on your mind?"}</div>
                  <div className="text-center">
                    {
                      "Please keep it relevant. Use @ for tagging, i.e. @WikiReviews."
                    }
                  </div>
                </div>
              )}
            </div>
          </FormGroup>
        }

        {/* Uploaded media shown here */}
        {
          !this.state.editPost &&
            this.state.editPostType !== "post" &&
            (this.state.uploadMedia.selectedMedia.length > 0 || embedBody.length > 0)
            ?
            <div className="px-1">
              <Button
                color="transparent"
                size="sm"
                className="mr-2 bg-transparent"
                title="Upload Media"
                onClick={() => this.handleOnClickUploadMedia()}
              >
                <img
                  src={require("../../../assets/images/icons/feed-cam.png")}
                  alt=""
                />
              </Button>
            </div>
            : ""
        }
        <div
          className="mb-3 border"
          hidden={
            !this.state.editPost &&
              this.state.editPostType !== "post" &&
              (this.state.uploadMedia.selectedMedia.length > 0 || embedBody.length > 0)
              ? false
              : true
          }
        >
          <Row noGutters>
            {this.state.uploadMedia.selectedMedia.length > 0 &&
              this.state.uploadMedia.selectedMedia.map((item, itemIndex) => {
                return (
                  <Col xs="auto" className="m-2" key={item.id}>
                    <div className="d-flex pr-3">
                      {(item.type === "audio" ||
                        item.media_type === "audio") && (
                          <div className="gallery-media _type-audio">
                            <img
                              className="img-fluid img-thumbnail"
                              src={require('../../../assets/images/icons/audio-icon.png')}
                              alt={
                                item.filename
                                  ? item.filename.length < 20
                                    ? item.filename
                                    : this.truncate(item.filename)
                                  : item.name
                                    ? item.name.length < 20
                                      ? item.name
                                      : this.truncate(item.name)
                                    : ""
                              } />
                          </div>)}
                      <div>
                        {(item.type === "image" ||
                          item.media_type === "image" ||
                          item.type === "video" ||
                          item.media_type === "video") && (
                            <div className="gallery-media">
                              {item.media_type === 'image' ?
                                <img
                                  className="img-fluid img-thumbnail"
                                  src={item?.thumbnail}
                                  alt={
                                    item.filename
                                      ? item.filename.length < 20
                                        ? item.filename
                                        : this.truncate(item.filename)
                                      : item.name
                                        ? item.name.length < 20
                                          ? item.name
                                          : this.truncate(item.name)
                                        : ""
                                  } /> :
                                <img
                                  className="img-fluid img-thumbnail"
                                  src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
                                  alt={
                                    item.filename
                                      ? item.filename.length < 20
                                        ? item.filename
                                        : this.truncate(item.filename)
                                      : item.name
                                        ? item.name.length < 20
                                          ? item.name
                                          : this.truncate(item.name)
                                        : ""
                                  }
                                />}
                            </div>
                          )}
                      </div>
                      <div className="mx-n3 mt-2">
                        <Button
                          color="delete-media"
                          size="sm"
                          title="Remove Media"
                          onClick={() =>
                            this.handleOnClickRemoveSelectedGalleryMedia(item, itemIndex)
                          }
                        >
                          <FontAwesomeIcon icon="trash-alt" />{" "}
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
            {embedBody && Array.isArray(embedBody) && embedBody.length ?
              embedBody.map((item, uploaded_index) => (
                <Col xs="auto" className="m-2" key={uploaded_index}>
                  <div className="d-flex pr-3">
                   
                      {/* {item.type === "embedded_link" &&  */}
                      <div className="gallery-media d-flex justify-content-center">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                          <img
                            className="img-fluid img-thumbnail h-100"
                            src={item.thumbnail || require("../../../assets/images/embedded_link_img.png")}
                            id={`embedded_link-${uploaded_index}`}
                            alt={item.url}
                          />
                          <UncontrolledTooltip
                            autohide={false}
                            placement="top"
                            target={`embedded_link-${uploaded_index}`}
                          >
                            {item.url}
                          </UncontrolledTooltip>
                        </a>
                      </div>
                      {/* } */}
                   
                    <div className="mx-n3 mt-2">
                      <Button onClick={() => { this.removeEmbeddedFromUploaded(uploaded_index, item) }} color="delete-media" size="sm" title="Remove Media">
                        <FontAwesomeIcon icon="trash-alt" />
                      </Button>
                    </div>
                  </div>
                </Col>
              )) : null}
          </Row>
        </div>

        {matches && Array.isArray(matches) && matches.length

          ? matches.map((item) => (

            <div className="outer-link">
              <Embed url={item} />
              {/* <ComponentNew embedData={item} /> */}
            </div>
          ))
          : null}
          <div
          className="mb-3 border"
          hidden={
            !this.state.editPost &&
              this.state.editPostType !== "post" &&
              this.state.uploadMedia.selectedMedia.length > 0
              ? false
              : true
          }
        >
        
        </div>
        

        {notPeopleTab &&
          <div className="d-flex flex-wrap mx-n1 mb-3">
            <div className="px-1 d-flex mx-n1 mb-2">
              {
                (this.state.uploadMedia.selectedMedia.length == 0 && embedBody.length == 0) &&
                <div className="px-1">
                  <Button
                    color="transparent"
                    size="sm"
                    className="mr-2 bg-transparent"
                    title="Upload Media"
                    onClick={() => this.handleOnClickUploadMedia()}
                  >
                    <img
                      src={require("../../../assets/images/icons/feed-cam.png")}
                      alt=""
                    />
                  </Button>
                </div>
              }
              
              <div className="px-1">
                <Input
                  bsSize="sm"
                  className="primary museo-sans-500"
                  type="text"
                  name="peopleSearch"
                  placeholder="Don't share this post with these people"
                  style={{ minWidth: "270px"  }}
                  onChange={this.handlePeopleSearch}
                  value={this.state.peopleSearch}
                />
                {`  `}

                {/* Instant Search Results */}
                <ul
                  className="list-unstyled text-dark instant-results bg-white w-100 shadow"
                  style={{
                    maxHeight: "201px",
                    maxWidth: "300px",
                    overflowY: "auto",
                  }}
                >
                  {Array.isArray(searchPeopleData) &&
                    searchPeopleData &&
                    searchPeopleData.length > 0
                    ? searchPeopleData.map((item, index) => (
                      <li
                        onClick={(e) => this.handleSelect(e, item)}
                        className="result-item"
                        role="button"
                        key={index}
                      >
                        <div className="result-link fs-16">
                          <Media>
                            <Media
                              object
                              className="img-circle _50x50"
                              src={item.profile_pic}
                              alt={`${item?.first_name}  ${item?.last_name}`}
                            />
                            <Media className="p-2" body>
                              {`${item?.first_name}  ${item?.last_name}`}
                            </Media>
                          </Media>
                        </div>
                      </li>
                    ))
                    : null}
                </ul>

                <div className="mt-1" style={{ maxWidth: "300px" }}>
                  <ul className="d-flex mx-n1 flex-wrap list-unstyled">
                    {Array.isArray(excludeListing) &&
                      excludeListing &&
                      excludeListing.length > 0
                      ? excludeListing.map((item, index) => (
                        <li className="px-1 mb-1" key={index}>
                          <div className="hide-member-card-content">
                            <span className="hide-member-card-text">{`${item?.first_name}  ${item?.last_name}`}</span>
                            <span
                              className="hide-member-card-close"
                              aria-label="Close"
                              onClick={(e) => this.removeSelect(e, item.id)}
                            >
                              X
                            </span>
                          </div>
                        </li>
                      ))
                      : null}
                  </ul>
                </div>
              </div>
            </div>
            <div className="ml-auto px-1">
              <Button
                color="transparent"
                className="mr-2 text-royal-blue font-weight-normal ff-alt text-decoration-none"
                onClick={this.handleOnClearCreate}
              >
                {"Clear"}
              </Button>
              <Button
                color="primary museo-sans-900"
                className="ml-0"
                onClick={this.handleOnSubmitCreatePost}
                disabled={
                  this.state.addPost.body?.replace(/(<([^>]+)>)/gi, "").trim().length > 0 || this.state.uploadMedia?.selectedMediaIds.length > 0
                    ? false
                    : true
                }
              >
                {" "}
                POST{" "}
              </Button>
            </div>
          </div>
        }
        {/* All Posts by me container */}
        {loading ? (
          <>
            <Loader />
            <div className="bg-white p-3">
              <h2 className="text-secondary-dark">Loading...</h2>
            </div>
          </>
        ) : (
          <>
          <div>
            {myPostsList?.results?.length > 0 ? (
              myPostsList.results.map((post, post_index) => {
                return (
                  <div className="bg-white p-3 mb-2" style={{width:'100%'}} key={post.id} id={"content_" + post.id} ref={this.myDivToFocus}>
                    <div id={`${myPostsList?.results?.length == post_index + 1 ? 'last-index' : `current-index-${post_index}`}`}>
                    <div className="media-post d-flex">
                        <div>
                          <img
                            className="media-object object-1"
                            src={
                              post.current_profile_pic
                                ? post.current_profile_pic
                                : require("../../../assets/images/icons/user-circle.png")
                            }
                            alt="user"
                            onError={(error) =>
                              (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                            }
                          />
                        </div>
                        <div className="w-100">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ display: 'flex' }}>
                            <div className="ml-3">
                              <Link to={post.sender_details ? `/people/${post.sender_details}` : "javascript:void(0)"} className="fs-14 text-darker ff-base font-weight-bold text-decoration-none">{post.sender}{" "}</Link>
                              <span className="time-stamp">
                                <span>&middot;</span> {post.sent_at}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="d-flex">
                              {notPeopleTab &&
                                <div className="ml-auto">
                                  <EditBtn
                                    onClick={() => this.toggleEditForm(post.id, "L1")}
                                  />
                                  <DeleteBtn
                                    onClick={() => {
                                      this.setState({
                                        deletePostId: post.id,
                                        deletePostType: "post",
                                      });
                                      this.confirmDeleteModalToggle();
                                    }}
                                  />
                                </div>
                              }

                            </div>
                          </div>
                        </div>
                      <div className="ml-3">
                        

                        {/* Hide when editing Post */}

                        <div>
                          <div>
                            {/* Comments */}
                            <div
                              className="comment-body-renderer"
                            >
                              {post.body != null ? (
                                <React.Fragment>
                                  
                                  {/* {detectlink(post.body)==='image' ? <img
                                      className="img-fluid img-thumbnail h-100"
                                      src={post.body || require("../../../assets/images/embedded_link_img.png")}
                                      // id={`embedded_link}`}
                                      alt={"Image"}
                                      style={{ width: "100%" }}
                                    /> :  <Markdown
                                    escapeHtml={false}
                                    source={post.body}
                                  /> } */}
                                  
                                     
                                  {!!post?.body_preview && <Markdown
                                    escapeHtml={false}
                                    source={post.body_preview}
                                  /> }
                                    {!!post?.body_preview && extractUrls(post?.body_preview) !== undefined && extractUrls(post.body_preview).map((link, index) => {
                                      return link.includes('youtube') && <a href={link} target="_blank" rel="noopener noreferrer"><div className="outer-link mb-2" key={index}>
                                        <Embed url={link} />
                                      </div></a>
                                    })}
                                  {/* {this.renderLinksWrapper({ body: post.body })} */}
                                    
                                 
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </div>
                            {post.attachments.length > 0 ? (
                              <div
                                hidden={
                                  this.state.editPost &&
                                    this.state.editPostType === "post" &&
                                    editFormVisible["L1" + post.id]
                                    ? true
                                    : false
                                }
                              >
                                {post.attachments
                                  .slice(0, 1)
                                  .map((attachment, index) => (
                                    <div key={index}>
                                      {attachment.type == "image" && (
                                        <div
                                          className="my-3 mx-auto"
                                          // style={{ maxWidth: "400px" }}
                                        >
                                          <img
                                            src={attachment.url}
                                            alt={
                                              attachment.filename.length < 20
                                                ? attachment.filename
                                                : this.truncate(
                                                  attachment.filename
                                                )
                                            }
                                            className="img-fluid"
                                          />
                                        </div>
                                      )}
                                      {attachment.type == "video" && (
                                        <video width="100%" preload="auto" id={"video" + post.id} controls="true" muted style={{ maxHeight: '500px' }}>
                                          <source
                                            src={attachment.url}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the video
                                          tag.
                                        </video>
                                      )}
                                      {attachment.type?.includes("audio") && (
                                        <audio width="100%" controls>
                                          <source
                                            src={attachment.url}
                                            type={'audio/mpeg'}
                                          />
                                          Your browser does not support the audio
                                          tag.
                                        </audio>
                                      )}
                                    </div>
                                  ))}

                                {/* showing row of media */}
                                <div>
                                  <Row
                                    className="justify-content-space-between"
                                    form
                                  >
                                    {post.attachments &&
                                      post.attachments.length > 1
                                      ? post.attachments.slice(1).map(
                                        (attachmentss, index1) => {
                                          return (
                                            <Col key={index1} xs="auto" className="mb-2">
                                              <div className="posted-video-img-holder" role="button">

                                                {attachmentss.type.includes('video') && (
                                                  <div
                                                    onClick={() =>
                                                      this.handleOpenModal(
                                                        post.attachments,
                                                        attachmentss.url
                                                      )
                                                    }
                                                    className="video-holder"
                                                  >
                                                    <div className="black-box _100x100">
                                                      <img
                                                        src={attachmentss?.thumbnail}
                                                        alt={
                                                          attachmentss?.filename
                                                            .length < 20
                                                            ? attachmentss?.filename
                                                            : this.truncate(
                                                              attachmentss?.filename
                                                            )
                                                        } />
                                                    </div>
                                                  </div>
                                                )}

                                                {attachmentss.type.includes('image') && (
                                                  <img
                                                    src={attachmentss.url}
                                                    alt={
                                                      attachmentss.filename
                                                        .length < 20
                                                        ? attachmentss.filename
                                                        : this.truncate(
                                                          attachmentss.filename
                                                        )
                                                    }
                                                    className="fixed-height-img"
                                                    onClick={() =>
                                                      this.handleOpenModal(
                                                        post.attachments,
                                                        attachmentss.url
                                                      )
                                                    }
                                                  />
                                                )}

                                                {attachmentss.type.includes('audio') && (
                                                  <div
                                                    onClick={() =>
                                                      this.handleOpenModal(
                                                        post.attachments,
                                                        attachmentss.url
                                                      )
                                                    }
                                                    className="audio-holder"
                                                  >
                                                    <div className="black-box _100x100">
                                                      &nbsp;
                                                    </div>
                                                  </div>
                                                )}


                                              </div>
                                            </Col>
                                          );
                                        }
                                      )
                                      : ""}
                                  </Row>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                             {Array.isArray(post.embeded_link) && !!post?.embeded_link?.length && <div className="mb-2"><span >Embedded Media</span></div>}
                              <div style={{ display: "flex", width: '100%', flexWrap: 'wrap' }}>
                                {Array.isArray(post.embeded_link) && post?.embeded_link.map((embedLink, index) => {
                                  return <div className="gallery-media2 mr-2 mb-2" style={{ width: "48%" }}>
                                    <a href={embedLink} target="_blank" rel="noopener noreferrer">
                                    {this.renderLinksWrapper({ body: embedLink })}
                                    </a>
                                  </div>
                                })}
                              </div>
                          </div>

                          {/* Show When Replying */}
                          <div hidden>
                            <Media className="media-post mt-3">
                              <Media>
                                <Media
                                  object
                                  src={require("../../../assets/images/icons/user-circle.png")}
                                  alt="User"
                                />
                              </Media>
                              <Media body>
                                <FormGroup className="mb-0">
                                  <Input
                                    bsSize="sm"
                                    className="mb-2"
                                    type="textarea"
                                    name="reply"
                                    placeholder="Write a Comment..."
                                  />
                                  <div className="text-right">
                                    <div className="text-right">
                                      <Button
                                        size="sm"
                                        color="primary"
                                        className=" mr-2"
                                      >
                                        {" "}
                                        Submit
                                      </Button>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        className="ml-0"
                                      >
                                        {" "}
                                        Cancel{" "}
                                      </Button>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Media>
                            </Media>
                            <hr />
                          </div>
                        </div>

                        {/* Show when editing post */}
                        {editFormVisible["L1" + post.id] &&
                          (
                            <div>
                              <FormGroup className="main-post-formgroup">

                                <div className="input-label-block">

                                  <span
                                    // ref={this.myRef}
                                    onClick={() => {
                                      this.setState({ toggleShowEditPlaceolder: false }, () => {
                                        document.getElementById("edit-post").focus();
                                      });
                                    }}
                                  >

                                    <MentionInput
                                      value={editPost.body_text?.replace(/(<([^>]+)>)/gi, "")}
                                      onChange={this.handleEditPostOnChange}
                                      peopleList={this.props.peopleList && this.props.peopleList}
                                      placeholder={""}
                                      id="edit-post"
                                    />


                                  </span>

                                  {this.state.toggleShowEditPlaceolder && (
                                    <div
                                      onClick={() => {
                                        this.setState({ toggleShowEditPlaceolder: false }, () => {
                                          document.getElementById("edit-post").focus();
                                        });
                                      }}
                                      className="post-placeholder-static"
                                    >
                                      <div className="text-center">{"What's on your mind?"}</div>
                                      <div className="text-center">
                                        {
                                          "Please keep it relevant. Use @ for tagging, i.e. @WikiReviews."
                                        }
                                      </div>
                                    </div>
                                  )}

                                  {/* <Input
                                bsSize="sm"
                                className="primary"
                                type="textarea"
                                name="main_post"
                                rows="3"
                                placeholder="What's on your mind?"
                                value={editPost.body?.replace(
                                  /(<([^>]+)>)/gi,
                                  ""
                                )}
                                onChange={this.handleEditPostOnChange}
                              />
                              <span style={{color:'red'}}>{this.state.editPostError}</span>
                            */}
                                  <span style={{ color: 'red' }}>{this.state.editPostError}</span>
                                </div>

                                <div
                                  className="mb-3"
                                  hidden={
                                    this.state.editPost &&
                                      this.state.editPostType === "post" &&
                                      (this.state.uploadMedia.selectedMedia.length > 0 || (this.state?.editEmbedBody && this.state?.editEmbedBody.length))
                                      ? false
                                      : true
                                  }
                                >
                                  <Row form>
                                    {this.state.uploadMedia.selectedMedia.length >
                                      0 &&
                                      this.state.uploadMedia.selectedMedia.map((item, indexMedia) => {
                                        return (
                                          <Col
                                            xs="auto"
                                            className="mb-3"
                                            key={item.id}
                                          >
                                            <div className="d-flex pr-3">
                                              <div>
                                                {(item.media_type === "image" ||
                                                  item.media_type ===
                                                  "video") && (
                                                    <div
                                                      className="selectable-media 234"
                                                      style={{
                                                        cursor: "default",
                                                      }}
                                                    >
                                                      <div className="gallery-media">
                                                        <img
                                                          src={
                                                            item?.thumbnail
                                                          }
                                                          alt={
                                                            item.filename
                                                              ? item.filename
                                                                .length < 20
                                                                ? item.filename
                                                                : this.truncate(
                                                                  item.filename
                                                                )
                                                              : item.name
                                                                ? item.name.length <
                                                                  20
                                                                  ? item.name
                                                                  : this.truncate(
                                                                    item.name
                                                                  )
                                                                : ""
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                              <div className="mx-n3 mt-2">
                                                <Button
                                                  color="delete-media"
                                                  size="sm"
                                                  title="Remove Media"
                                                  onClick={() =>
                                                    this.handleOnClickRemoveSelectedGalleryMedia(
                                                      item, indexMedia
                                                    )
                                                  }
                                                >
                                                  <FontAwesomeIcon icon="trash-alt" />{" "}
                                                </Button>
                                              </div>
                                            </div>
                                          </Col>
                                        );
                                      }
                                      )}
                                    {this.state?.editEmbedBody && this.state.editEmbedBody.length ?
                                      this.state.editEmbedBody.map((item, uploaded_index) => (
                                        <Col xs="auto" className="mb-2" key={uploaded_index}>
                                          <div className="d-flex pr-3">
                                            {/* {item.type === "embedded_link" &&  */}
                                            <div className="gallery-media d-flex justify-content-center">
                                              <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                <img
                                                  className="img-fluid img-thumbnail h-100"
                                                  src={item.thumbnail || require("../../../assets/images/embedded_link_img.png")}
                                                  id={`embedded_link-${uploaded_index}`}
                                                  alt={item.url}
                                                />
                                                <UncontrolledTooltip
                                                  autohide={false}
                                                  placement="top"
                                                  target={`embedded_link-${uploaded_index}`}
                                                >
                                                  {item.url}
                                                </UncontrolledTooltip>
                                              </a>
                                            </div>
                                            {/* } */}
                                            <div className="mx-n3 mt-2">
                                              <Button onClick={() => { this.removeEmbeddedFromUploaded(uploaded_index, item) }} color="delete-media" size="sm" title="Remove Media">
                                                <FontAwesomeIcon icon="trash-alt" />
                                              </Button>
                                            </div>
                                          </div>
                                        </Col>
                                      )) : null}
                                  </Row>
                                </div>
                              </FormGroup>
                              <div className="d-flex flex-wrap mx-n1">
                                <div className="px-1 d-flex mx-n1 mb-2">
                                  <div className="px-1">
                                    <Button
                                      color="white"
                                      size="sm"
                                      className="mr-2"
                                      onClick={() =>
                                        this.handleOnClickUploadMedia()
                                      }
                                    >
                                      <FontAwesomeIcon icon="camera" />{" "}
                                    </Button>
                                  </div>
                                  <div className="px-1">
                                    <Input
                                      bsSize="sm"
                                      className="primary museo-sans-500"
                                      type="text"
                                      placeholder="Don't share this post with these people"
                                      style={{ minWidth: "270px",}}
                                      onChange={this.handlePeopleSearchEdit}
                                      value={this.state.peopleSearchEdit}
                                    />
                                    {/* Instant Search Results */}
                                    <ul
                                      className="list-unstyled text-dark instant-results bg-white w-100 shadow"
                                      style={{
                                        maxHeight: "201px",
                                        maxWidth: "300px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      {Array.isArray(searchPeopleDataEdit) &&
                                        searchPeopleDataEdit &&
                                        searchPeopleDataEdit.length > 0
                                        ? searchPeopleDataEdit.map(
                                          (item, index) => (
                                            <li
                                              onClick={(e) =>
                                                this.handleSelectEdit(e,post.id, item)
                                              }
                                              className="result-item"
                                              role="button"
                                              key={index}
                                            >
                                              <div className="result-link fs-16">
                                                <Media>
                                                  <Media
                                                    object
                                                    className="img-circle _50x50"
                                                    src={item.profile_pic}
                                                    alt={`${item?.first_name}  ${item?.last_name}`}
                                                  />
                                                  <Media className="p-2" body>
                                                    {`${item?.first_name}  ${item?.last_name}`}
                                                  </Media>
                                                </Media>
                                              </div>
                                            </li>
                                          )
                                        )
                                        : null}
                                    </ul>
                                    <div
                                      className="mt-1"
                                      style={{ maxWidth: "300px" }}
                                    >
                                      <ul className="d-flex mx-n1 flex-wrap list-unstyled">
                                        {editExcludeListing && editExcludeListing[post.id] && Array.isArray(editExcludeListing[post.id]) &&
                                          editExcludeListing[post.id] &&
                                          editExcludeListing[post.id].length > 0
                                          ? editExcludeListing[post.id].map((item, index) => (
                                            <li className="px-1 mb-1" key={index}>
                                              <Badge
                                                color="dark"
                                                className="d-inline-flex align-items-center"
                                              >
                                                <span className="mr-1 font-weight-normal text-truncate">{`${item?.first_name}  ${item?.last_name}`}</span>
                                                <button
                                                  type="button"
                                                  className="close btn-sm text-white"
                                                  aria-label="Close"
                                                  onClick={(e) =>
                                                    this.removeSelectEdit(e,post.id, item.id)
                                                  }
                                                >
                                                  <span>
                                                    <FontAwesomeIcon icon="times-circle" />{" "}
                                                  </span>
                                                </button>
                                              </Badge>
                                            </li>
                                          ))
                                          : null}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="px-1 ml-auto">
                                  <Button
                                    color="transparent"
                                    size="sm"
                                    className="mr-2 text-royal-blue font-weight-normal ff-alt text-decoration-none btn btn-transparent"
                                    onClick={this.handleOnClearEdit}
                                  >
                                    {" "}
                                    Clear
                                  </Button>
                                  <Button
                                    color="transparent"
                                    size="sm"
                                    className="mr-2 text-royal-blue font-weight-normal ff-alt text-decoration-none btn btn-transparent"
                                    onClick={() =>
                                      this.toggleEditForm(post.id, "L1")
                                    }
                                  >
                                    {" "}
                                    Cancel
                                  </Button>
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="mw"
                                    onClick={this.handleOnSubmitEditPost}
                                    disabled={this.state.editPost.body?.replace(/(<([^>]+)>)/gi, "").length > 0 || this.state.uploadMedia?.selectedMediaIds.length > 0 ? false : true
                                    }
                                  >
                                    {" "}
                                    UPDATE{" "}
                                  </Button>
                                </div>
                              </div>
                              <hr />
                            </div>
                          )}
                      </div>
                      </div>
                    </div>
                    <div className="mt-1">
                      {/* All Replies */}
                      {post.conversation.length > 0 && (
                        <div>
                          {post.conversation.map((comment) => {
                            return (
                              <div key={comment.msg_id}>
                                <Media className="media-post mt-3">
                                  <Media>
                                    <img
                                      className="media-object conversation-reply"
                                      src={comment.current_profile_pic}
                                      alt={comment?.sender}
                                      onError={(e) => e.target.src = require("../../../assets/images/icons/user-circle.png")}
                                    />
                                  </Media>
                                  <Media body>
                                    <Media className="mb-0" heading>
                                      <div className="d-flex">
                                        <div>
                                          <span className="text-darker fs-14">
                                            <Link to={comment.sender_details ? `/people/${comment.sender_details}` : "javascript:void(0)"} className="fs-14 text-darker ff-base font-weight-bold text-decoration-none">{comment.sender}</Link>
                                            {": "}
                                          </span>
                                          <span className="comment-body-renderer font-weight-normal">
                                            {ReactHtmlParser(
                                              comment?.body_preview?.replace(
                                                /\n/g,
                                                "<br />"
                                              )
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </Media>

                                    {/* Hide when editing Post */}
                                    <div>
                                      <div className="d-flex mt-n1">
                                        <div className="mr-2">
                                          {/* Reply Button */}
                                          <button
                                            className="btn btn-link btn-sm px-0 font-weight-bold text-darker"
                                            onClick={() =>
                                              this.toggleReplyForm(
                                                comment.msg_id,
                                                "L2"
                                              )
                                            }
                                          >
                                            Reply
                                          </button>
                                          <span className="ff-alt text-dark align-middle">
                                            <span>{" - "}</span>
                                            {comment.sent_at}
                                          </span>
                                        </div>
                                        <div className="ml-auto">
                                          <EditBtn
                                            onClick={() =>
                                              this.toggleEditForm(
                                                comment.msg_id,
                                                "L2",
                                                "comment",
                                                comment.body
                                              )
                                            }
                                          />
                                          <DeleteBtn
                                            onClick={() => {
                                              this.setState({
                                                deletePostId: comment.msg_id,
                                                deletePostType: "comment",
                                              });
                                              this.confirmDeleteModalToggle();
                                            }}
                                            disabled={this.state.editPost && Object.keys(this.state.editPost).length > 0}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    {/* Show when editing post */}
                                    {editFormVisible["L2" + comment.msg_id] && (
                                      <div>
                                        <FormGroup className="main-post-formgroup">
                                          <div className="input-label-block">
                                            <Input
                                              bsSize="sm"
                                              className="primary"
                                              type="textarea"
                                              name="main_post"
                                              rows="3"
                                              placeholder="What's on your mind?"
                                              value={editPost.body_text?.replace(
                                                /(<([^>]+)>)/gi,
                                                ""
                                              )}
                                              onChange={
                                                this.handleEditPostOnChange
                                              }
                                            />
                                            <span style={{ color: 'red' }}>{this.state.editPostError}</span>
                                          </div>
                                        </FormGroup>
                                        <div className="d-flex mx-n1">
                                          <div className="px-1 ml-auto">
                                            {/*<Button
                                            color="transparent"
                                            size="sm"
                                            className="mr-2"
                                            onClick={this.handleOnClearEdit}
                                          >
                                            {" "}
                                            Clear
                                          </Button>*/}
                                            <Button
                                              color="transparent"
                                              size="sm"
                                              className="ml-0 mr-2"
                                              onClick={() =>
                                                this.toggleEditForm(
                                                  comment.msg_id,
                                                  "L2"
                                                )
                                              }
                                            >
                                              {" "}
                                              Cancel
                                            </Button>
                                            <Button
                                              color="primary"
                                              size="sm"
                                              className="mw ml-0"
                                              onClick={
                                                this.handleOnSubmitEditPost
                                              }
                                            >
                                              {" "}
                                              UPDATE{" "}
                                            </Button>
                                          </div>
                                        </div>
                                        <hr />
                                      </div>
                                    )}

                                    {/* Show when replying to comment */}
                                    {replyFormVisible["L2" + comment.msg_id] && (
                                      <div>
                                        <Media className="media-post mt-3">
                                          <Media>
                                            <img
                                              className="media-object"
                                              src={
                                                this.props.profile_data
                                                  .current_profile_file
                                                  ? this.props.profile_data
                                                    .current_profile_file
                                                  : require("../../../assets/images/icons/user-circle.png")
                                              }
                                              onError={(error) =>
                                                (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                                              }
                                              alt={this.props?.profile_data?.user?.first_name}
                                            />
                                          </Media>
                                          <Media body>
                                            <FormGroup className="mb-0">
                                              <Input
                                                bsSize="sm"
                                                className="mb-2 text-primary"
                                                type="textarea"
                                                name="body"
                                                value={this.state.addReply.body}
                                                onChange={
                                                  this.handleOnReplyChange
                                                }
                                                placeholder="Write a Reply..."
                                              />
                                              <div className="text-right">
                                                <div className="text-right">
                                                  <Button
                                                    size="sm"
                                                    color="primary"
                                                    className="mr-2"
                                                    onClick={() =>
                                                      this.toggleReplyForm(
                                                        comment.msg_id,
                                                        "L2"
                                                      )
                                                    }
                                                  >
                                                    {" "}
                                                    Cancel{" "}
                                                  </Button>
                                                  <Button
                                                    size="sm"
                                                    color="primary"
                                                    className="mr-2 ml-0"
                                                    onClick={this.handleOnReplySubmit(
                                                      comment.msg_id,
                                                      "L2"
                                                    )}
                                                  >
                                                    {" "}
                                                    Submit
                                                  </Button>
                                                </div>
                                              </div>
                                            </FormGroup>
                                          </Media>
                                        </Media>
                                        <hr />
                                      </div>
                                    )}

                                    {/* Show replies */}
                                    {comment.replies &&
                                      comment.replies.length > 0 && (
                                        <div>
                                          {comment.replies.map((reply) => {
                                            return (
                                              <div key={reply.id}>
                                                <Media className="media-post">
                                                  <Media>
                                                    <img
                                                      className="media-object"
                                                      src={
                                                        reply.sender
                                                          .current_profile_pic
                                                          ? reply.sender
                                                            .current_profile_pic
                                                          : require("../../../assets/images/icons/user-circle.png")
                                                      }
                                                      onError={(error) =>
                                                        (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                                                      }
                                                      alt={reply?.sender?.full_name}
                                                    />
                                                  </Media>
                                                  <Media body>
                                                    <Media className="mb-0" heading>
                                                      <div className="d-flex">
                                                        <div>
                                                          <Link to={reply.sender.username ? `/people/${reply.sender.username}` : "javascript:void(0)"} className="text-darker fs-14 text-decoration-none">
                                                            {reply.sender.full_name}
                                                            {": "}
                                                          </Link>

                                                          <span className="comment-body-renderer font-weight-normal">
                                                            {reply.body.replace(
                                                              /(<([^>]+)>)/gi,
                                                              ""
                                                            )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </Media>

                                                    <div className="d-flex">
                                                      <div className="mr-2">
                                                        <span className="ff-alt text-dark align-middle">
                                                          {reply.sent_at}
                                                        </span>
                                                      </div>
                                                      <div className="ml-auto">
                                                        {/* <EditBtn
                                                        onClick={() =>
                                                          this.toggleEditForm(
                                                            comment.msg_id,
                                                            "L2",
                                                            "comment",
                                                            comment.body
                                                          )
                                                        }
                                                      /> */}
                                                        <DeleteBtn
                                                          onClick={() => {
                                                            this.setState({
                                                              deletePostId:
                                                                reply.id,
                                                              deletePostType:
                                                                "reply",
                                                            });
                                                            this.confirmDeleteModalToggle();
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </Media>
                                                </Media>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      )}
                                  </Media>
                                </Media>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {/* Reply Button */}
                      <div hidden>
                        <div className="d-flex">
                          <div className="ml-auto align-self-start">
                            {/* <span className="mx-1">|</span> */}
                            <button
                              className="btn btn-link btn-sm text-muted"
                              onClick={() => this.toggleReplyForm(post.id, "L1")}
                            >
                              REPLY
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Quick Reactions */}
                      <div className="mt-4">
                        <div className="d-flex">
                          <div className="quick-reactions d-flex flex-wrap mb-2">
                            <div className="reaction-holder">
                              {/* Show on reacting */}
                              <div
                                className="reaction-anim"
                                hidden={gifwoohoo[post.id] == true ? false : true}
                              >
                                <img
                                  src={require("../../../assets/images/icons/woohoo_forever.gif")}
                                  alt=""
                                />
                              </div>

                              <div className="reaction-btn-group">
                                <button
                                  className="reaction woohoo"
                                  title="Woohoo"
                                  onClick={() => {
                                    this.addreviewGroup(true, post.id, "woohoo");
                                  }}
                                >
                                  <span className="icon">
                                    {post.review_group_animation &&
                                      post.review_group_animation
                                        .review_group_count &&
                                      post.review_group_animation.review_group_count
                                        .woohoo +
                                      (woohooCount[post.id] &&
                                        woohooCount[post.id] != undefined
                                        ? woohooCount[post.id]
                                        : 0) >
                                      0 ? (
                                      <img
                                        src={require("../../../assets/images/icons/woohoo-active.png")}
                                        alt="woohoo"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../assets/images/icons/woohoo.png")}
                                        alt="woohoo"
                                      />
                                    )}
                                  </span>
                                  <span className="name">Woohoo</span>
                                </button>
                                {post.review_group_animation &&
                                  post.review_group_animation.review_group_count &&
                                  post.review_group_animation.review_group_count
                                    .woohoo +
                                  (woohooCount[post.id] &&
                                    woohooCount[post.id] != undefined
                                    ? woohooCount[post.id]
                                    : 0) >
                                  0 ? (
                                  <button
                                    className="reaction"
                                    data-id={post.id}
                                    data-name="woohoo"
                                    onClick={() => {
                                      this.handleOnClickReviewType(
                                        post.review_group_animation,
                                        post.id,
                                        "woohoo"
                                      );
                                    }}
                                    onMouseOver={this.handleMouseIn.bind(this)}
                                    onMouseOut={this.handleMouseOut.bind(this)}
                                  >
                                    <span className="count">
                                      {post.review_group_animation &&
                                        post.review_group_animation
                                          .review_group_count
                                        ? post.review_group_animation
                                          .review_group_count.woohoo +
                                        (woohooCount[post.id] &&
                                          woohooCount[post.id] != undefined
                                          ? woohooCount[post.id]
                                          : 0)
                                        : "0"}
                                    </span>
                                  </button>
                                ) : (
                                  <button className="reaction">
                                    <span className="count">
                                      {woohooCount[post.id] == undefined
                                        ? 0
                                        : woohooCount[post.id]}
                                    </span>
                                  </button>
                                )}
                                <div
                                  className={
                                    hoverwoohoo && hoverPostId === post.id
                                      ? "tooltiptext"
                                      : ""
                                  }
                                >
                                  <div style={tooltipWoohoo}>
                                    {fetchReviewGroup.length > 0 &&
                                      fetchReviewGroup.map((item, index) => {
                                        if (item.group_icon === "woohoo") {
                                          return <p key={index}>{item.name}</p>;
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reaction-holder">
                              {/* Show on reacting */}
                              <div
                                className="reaction-anim"
                                hidden={gifnailed[post.id] == true ? false : true}
                              >
                                <img
                                  src={require("../../../assets/images/icons/nailedit_forever.gif")}
                                  alt=""
                                />
                              </div>
                              <div className="reaction-btn-group">
                                <button
                                  className="reaction nailed"
                                  title="Nailed"
                                  onClick={() => {
                                    this.addreviewGroup(true, post.id, "nailed");
                                  }}
                                >
                                  <span className="icon">
                                    {post.review_group_animation &&
                                      post.review_group_animation
                                        .review_group_count &&
                                      post.review_group_animation.review_group_count
                                        .nailed +
                                      (nailedCount[post.id] &&
                                        nailedCount[post.id] != undefined
                                        ? nailedCount[post.id]
                                        : 0) >
                                      0 ? (
                                      <img
                                        src={require("../../../assets/images/icons/nailed-active.png")}
                                        alt="nailed"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../assets/images/icons/nailed-it.png")}
                                        alt="nailed"
                                      />
                                    )}
                                  </span>
                                  <span className="name">Nailed It</span>
                                </button>
                                {post.review_group_animation &&
                                  post.review_group_animation.review_group_count &&
                                  post.review_group_animation.review_group_count
                                    .nailed +
                                  (nailedCount[post.id] &&
                                    nailedCount[post.id] != undefined
                                    ? nailedCount[post.id]
                                    : 0) >
                                  0 ? (
                                  <button
                                    className="reaction"
                                    data-id={post.id}
                                    data-name="nailed"
                                    onClick={() => {
                                      this.handleOnClickReviewType(
                                        post.review_group_animation,
                                        post.id,
                                        "nailed"
                                      );
                                    }}
                                    onMouseOver={this.handleMouseIn.bind(this)}
                                    onMouseOut={this.handleMouseOut.bind(this)}
                                  >
                                    <span className="count">
                                      {post.review_group_animation &&
                                        post.review_group_animation
                                          .review_group_count
                                        ? post.review_group_animation
                                          .review_group_count.nailed +
                                        (nailedCount[post.id] &&
                                          nailedCount[post.id] != undefined
                                          ? nailedCount[post.id]
                                          : 0)
                                        : "0"}
                                    </span>
                                  </button>
                                ) : (
                                  <button className="reaction">
                                    <span className="count">
                                      {nailedCount[post.id] == undefined
                                        ? 0
                                        : nailedCount[post.id]}
                                    </span>
                                  </button>
                                )}
                                <div
                                  className={
                                    hovernailed && hoverPostId === post.id
                                      ? "tooltiptext"
                                      : ""
                                  }
                                >
                                  <div style={tooltipNailed}>
                                    {fetchReviewGroup.length > 0 &&
                                      fetchReviewGroup.map((item, index) => {
                                        if (
                                          item.group_icon === "nailed" &&
                                          hoverPostId === post.id
                                        ) {
                                          return <p>{item.name}</p>;
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reaction-holder">
                              {/* Show on reacting */}
                              <div
                                className="reaction-anim"
                                hidden={
                                  gifinsightful[post.id] == true ? false : true
                                }
                              >
                                <img
                                  src={require("../../../assets/images/icons/insightful_forever.gif")}
                                  alt=""
                                />
                              </div>

                              <div className="reaction-btn-group">
                                <button
                                  className="reaction insightful"
                                  title="Insightful"
                                  onClick={() => {
                                    this.addreviewGroup(
                                      true,
                                      post.id,
                                      "insightful"
                                    );
                                  }}
                                >
                                  <span className="icon">
                                    {post.review_group_animation &&
                                      post.review_group_animation
                                        .review_group_count &&
                                      post.review_group_animation.review_group_count
                                        .insightful +
                                      (insightfulCount[post.id] &&
                                        insightfulCount[post.id] != undefined
                                        ? insightfulCount[post.id]
                                        : 0) >
                                      0 ? (
                                      <img
                                        src={require("../../../assets/images/icons/insightful-active.png")}
                                        alt="insightful"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../assets/images/icons/insightful.png")}
                                        alt="insightful"
                                      />
                                    )}
                                  </span>
                                  <span className="name">Insightful</span>
                                </button>
                                {post.review_group_animation &&
                                  post.review_group_animation.review_group_count &&
                                  post.review_group_animation.review_group_count
                                    .insightful +
                                  (insightfulCount[post.id] &&
                                    insightfulCount[post.id] != undefined
                                    ? insightfulCount[post.id]
                                    : 0) >
                                  0 ? (
                                  <button
                                    className="reaction"
                                    data-id={post.id}
                                    data-name="insightful"
                                    onClick={() => {
                                      this.handleOnClickReviewType(
                                        post.review_group_animation,
                                        post.id,
                                        "insightful"
                                      );
                                    }}
                                    onMouseOver={this.handleMouseIn.bind(this)}
                                    onMouseOut={this.handleMouseOut.bind(this)}
                                  >
                                    <span className="count">
                                      {post.review_group_animation &&
                                        post.review_group_animation
                                          .review_group_count
                                        ? post.review_group_animation
                                          .review_group_count.insightful +
                                        (insightfulCount[post.id] &&
                                          insightfulCount[post.id] != undefined
                                          ? insightfulCount[post.id]
                                          : 0)
                                        : "0"}
                                    </span>
                                  </button>
                                ) : (
                                  <button className="reaction">
                                    <span className="count">
                                      {insightfulCount[post.id] == undefined
                                        ? 0
                                        : insightfulCount[post.id]}
                                    </span>
                                  </button>
                                )}
                                <div
                                  className={
                                    hoverinsightful && hoverPostId === post.id
                                      ? "tooltiptext"
                                      : ""
                                  }
                                >
                                  <div style={tooltipInsightful}>
                                    {fetchReviewGroup.length > 0 &&
                                      fetchReviewGroup.map((item, index) => {
                                        if (
                                          item.group_icon === "insightful" &&
                                          hoverPostId === post.id
                                        ) {
                                          return <p>{item.name}</p>;
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reaction-holder">
                              {/* Show on reacting */}
                              <div
                                className="reaction-anim"
                                hidden={giflol[post.id] == true ? false : true}
                              >
                                <img
                                  src={require("../../../assets/images/icons/lol_forever.gif")}
                                  alt=""
                                />
                              </div>

                              <div className="reaction-btn-group">
                                <button
                                  className="reaction lol"
                                  title="LOL"
                                  onClick={() => {
                                    this.addreviewGroup(true, post.id, "lol");
                                  }}
                                >
                                  <span className="icon">
                                    {post.review_group_animation &&
                                      post.review_group_animation
                                        .review_group_count &&
                                      post.review_group_animation.review_group_count
                                        .lol +
                                      (lolCount[post.id] &&
                                        lolCount[post.id] != undefined
                                        ? lolCount[post.id]
                                        : 0) >
                                      0 ? (
                                      <img
                                        src={require("../../../assets/images/icons/lol-active.png")}
                                        alt="lol"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../assets/images/icons/lol.png")}
                                        alt="lol"
                                      />
                                    )}
                                  </span>
                                  <span className="name">LOL</span>
                                </button>
                                {post.review_group_animation &&
                                  post.review_group_animation.review_group_count &&
                                  post.review_group_animation.review_group_count
                                    .lol +
                                  (lolCount[post.id] &&
                                    lolCount[post.id] != undefined
                                    ? lolCount[post.id]
                                    : 0) >
                                  0 ? (
                                  <button
                                    className="reaction"
                                    data-id={post.id}
                                    data-name="lol"
                                    onClick={() => {
                                      this.handleOnClickReviewType(
                                        post.review_group_animation,
                                        post.id,
                                        "lol"
                                      );
                                    }}
                                    onMouseOver={this.handleMouseIn.bind(this)}
                                    onMouseOut={this.handleMouseOut.bind(this)}
                                  >
                                    <span className="count">
                                      {post.review_group_animation &&
                                        post.review_group_animation
                                          .review_group_count
                                        ? post.review_group_animation
                                          .review_group_count.lol +
                                        (lolCount[post.id] &&
                                          lolCount[post.id] != undefined
                                          ? lolCount[post.id]
                                          : 0)
                                        : "0"}
                                    </span>
                                  </button>
                                ) : (
                                  <button className="reaction">
                                    <span className="count">
                                      {lolCount[post.id] == undefined
                                        ? 0
                                        : lolCount[post.id]}
                                    </span>
                                  </button>
                                )}
                                <div
                                  className={
                                    hoverlol && hoverPostId === post.id
                                      ? "tooltiptext"
                                      : ""
                                  }
                                >
                                  <div style={tooltipLol}>
                                    {fetchReviewGroup.length > 0 &&
                                      fetchReviewGroup.map((item, index) => {
                                        if (
                                          item.group_icon === "lol" &&
                                          hoverPostId === post.id
                                        ) {
                                          return <p>{item.name}</p>;
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="reaction-holder">
                              {/* Show on reacting */}
                              <div
                                className="reaction-anim"
                                hidden={gifbummer[post.id] == true ? false : true}
                              >
                                <img
                                  src={require("../../../assets/images/icons/bummer_forever.gif")}
                                  alt=""
                                />
                              </div>
                              <div className="reaction-btn-group">
                                <button
                                  className="reaction bummer"
                                  title="Bummer"
                                  onClick={() => {
                                    this.addreviewGroup(true, post.id, "bummer");
                                  }}
                                >
                                  <span className="icon">
                                    {post.review_group_animation &&
                                      post.review_group_animation
                                        .review_group_count &&
                                      post.review_group_animation.review_group_count.bummer +
                                      (bummerCount[post.id] &&
                                        bummerCount[post.id] != undefined
                                        ? bummerCount[post.id]
                                        : 0) > 0 ? (
                                      <img
                                        src={require("../../../assets/images/icons/bummer-active.png")}
                                        alt="bummer"
                                      />
                                    ) : (
                                      <img
                                        src={require("../../../assets/images/icons/bummer.png")}
                                        alt="bummer"
                                      />
                                    )}
                                  </span>
                                  <span className="name">Bummer</span>
                                </button>
                                {post.review_group_animation &&
                                  post.review_group_animation.review_group_count &&
                                  post.review_group_animation.review_group_count
                                    .bummer +
                                  (bummerCount[post.id] &&
                                    bummerCount[post.id] != undefined
                                    ? bummerCount[post.id]
                                    : 0) >
                                  0 ? (
                                  <button
                                    className="reaction"
                                    data-id={post.id}
                                    data-name="bummer"
                                    onClick={() => {
                                      this.handleOnClickReviewType(
                                        post.review_group_animation,
                                        post.id,
                                        "bummer"
                                      );
                                    }}
                                    onMouseOver={this.handleMouseIn.bind(this)}
                                    onMouseOut={this.handleMouseOut.bind(this)}
                                  >
                                    <span className="count">
                                      {post.review_group_animation &&
                                        post.review_group_animation
                                          .review_group_count
                                        ? post.review_group_animation
                                          .review_group_count.bummer +
                                        (bummerCount[post.id] &&
                                          bummerCount[post.id] != undefined
                                          ? bummerCount[post.id]
                                          : 0)
                                        : "0"}
                                    </span>
                                  </button>
                                ) : (
                                  <button className="reaction">
                                    <span className="count">
                                      {bummerCount[post.id] == undefined
                                        ? 0
                                        : bummerCount[post.id]}
                                    </span>
                                  </button>
                                )}

                                <div
                                  className={
                                    hoverbummer && hoverPostId === post.id
                                      ? "tooltiptext"
                                      : ""
                                  }
                                >
                                  <div style={tooltipBummer}>
                                    {fetchReviewGroup.length > 0 &&
                                      fetchReviewGroup.map((item, index) => {
                                        if (
                                          item.group_icon === "bummer" &&
                                          hoverPostId === post.id
                                        ) {
                                          return <p>{item.name}</p>;
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Show When Replying */}
                      <div>
                        <Media className="media-post">
                          <Media>
                            <img
                              className="media-object"
                              src={
                                this.props.profile_data &&
                                  this.props.profile_data.current_profile_file
                                  ? this.props.profile_data.current_profile_file
                                  : require("../../../assets/images/icons/user-circle.png")
                              }
                              onError={(error) =>
                                (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                              }
                              alt="UserImage"
                            />
                          </Media>
                          <Media body>
                            <FormGroup className="mb-0">
                              <Input
                                bsSize="sm"
                                className="mb-2 text-primary font-weight-bold ph-grey"
                                type="textarea"
                                name="reply"
                                placeholder="Add your comments"
                                value={this.state.addComment[post.id]}
                                onChange={this.handleOnCommentChange(post.id)}
                              />
                              <div className="text-right">
                                <div className="text-right">
                                  <Button
                                    size="sm"
                                    color="primary"
                                    onClick={this.handleOnCommentSubmit(post.id)}
                                  >
                                    {" "}
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            </FormGroup>
                          </Media>
                        </Media>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
                ) :
                  <div className="bg-white p-3">
                    <h2 className="text-secondary-dark">{myPostsList?.results?.length === 0 ?  "No Posts to Display" : "Loading..."}</h2>
                  </div>
                }
              </div>
            {
              this.state.loadingMoreData ?
                <div className="bg-white p-3">
                  <h2 className="text-secondary-dark">Loading more posts...</h2>
                </div>
                :
                ''
            }
            <div style={{ height: "600px" }}></div>
          </>
        )}
        {/* Upload Media Post Modal */}
        <Modal
          isOpen={this.state.uploadMediaPostModal}
          toggle={this.uploadMediaModalToggle}
        >
          <ModalHeader toggle={this.uploadMediaModalToggle}>
            UPLOAD MEDIA
          </ModalHeader>
          <ModalBody className="p-3">
            <Row>
              <Col xs={"auto"}>
                <div className="bg-light h-100 p-3">
                  <Nav
                    tabs
                    className="flex-column active-transparent border-0 m-0"
                  >
                    <NavItem className="text-left">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "upload"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("upload");
                        }}
                      >
                        Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "gallery"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("gallery");
                        }}
                      >
                        Gallery
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "embed"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("embed");
                        }}
                      >
                        Embed
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col>
                <TabContent activeTab={this.state.selectedUpMediaType}>
                  <TabPane tabId="upload">
                    <div
                      className="mb-3 type-file-block bg-light"
                      hidden={
                        this.state.uploadMedia.uploadFiles &&
                          this.state.uploadMedia.uploadFiles.length > 0
                          ? true
                          : false
                      }
                    >
                      <Input
                        type="file"
                        name="upload_media_file"
                        id="uploadFilesPostFromSystem"
                        accept="image/*, video/*, audio/*"
                        onChange={this.handleOnFilePostUploadChange}
                        multiple
                      />
                      <Label for="uploadFilesPostFromSystem">
                        <div className="type-bg"></div>
                      </Label>
                    </div>
                    {this.state.uploadMedia.uploadFiles.length > 0 && (
                      <Row className="mb-3" form>
                        <Col xs={12}>
                          <div
                            style={{
                              maxWidth: "120px",
                              margin: "0 1rem 1rem auto",
                            }}
                          >
                            <div className="text-center mb-1 small">
                              {this.state.uploadMedia.progress === 100 ? (
                                <div className="text-success">
                                  <FontAwesomeIcon
                                    icon="check-circle"
                                    className="mr-1"
                                  />{" "}
                                  Uploaded
                                </div>
                              ) : (
                                <div>
                                  Uploading{" "}
                                  <span className="text-success font-weight-bold ff-base">
                                    {this.state.uploadMedia.progress.toFixed(0)}
                                    %
                                  </span>
                                </div>
                              )}
                            </div>
                            <Progress
                              value={this.state.uploadMedia.progress}
                              style={{ height: "8px" }}
                            ></Progress>
                          </div>
                        </Col>
                        {this.state.uploadMedia.uploadFiles.map(
                          (file, index) => {
                            return (
                              <Col xs="auto">
                                <div className="bg-light p-2">
                                  <div className="d-flex pr-3">
                                    <div>
                                      <div
                                        className="selectable-media 123"
                                        style={{ cursor: "default" }}
                                      >
                                        <div className="gallery-media">
                                          {file.media_type === 'image' &&
                                            <img src={file.url} alt="" />}
                                          {file.media_type === 'video' &&
                                            <video width="100" height="100">
                                              <source src={file.url ? file.url : file.thumbnail} type="video/mp4" />
                                              Your browser does not support the video tag.
                                            </video>
                                          }
                                          {file.media_type === 'audio' &&
                                            <img src={file.url ? file.url : file.thumbnail} alt="" />}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mx-n3 mt-2">
                                      <Button
                                        color="delete-media _alt"
                                        size="sm"
                                        title="Remove Media"
                                        hidden={file.id === "" ? true : false}
                                        onClick={this.handleOnClickRemoveSelectedMedia(
                                          file.id
                                        )}
                                      >
                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <Input
                                      bsSize="sm"
                                      type="text"
                                      className="mw-100 caption-input"
                                      name="caption"
                                      placeholder="Caption"
                                      onBlur={(e) =>
                                        this.handleCaptionByIndex(e, index)
                                      }
                                      style={{ maxWidth: '160px' }}
                                    />
                                    {/*<Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_type_select" id="selectAlbumTypeData" className="mw-100 mt-1">
                                    <option value="">Choose Category</option>
                                    {file.media_type === "image" && fetchTaxonomyListingImages && Array.isArray(fetchTaxonomyListingImages) && fetchTaxonomyListingImages.length > 0 ?
                                      fetchTaxonomyListingImages.map(item => (
                                        <option value={item.id}>{item.label}</option>
                                      ))
                                      : null}
                                    {file.media_type === "video" && fetchTaxonomyListingVideos && Array.isArray(fetchTaxonomyListingVideos) && fetchTaxonomyListingVideos.length > 0 ?
                                      fetchTaxonomyListingVideos.map(item => (
                                        <option value={item.id}>{item.label}</option>
                                      ))
                                      : null}
									  </Input>*/}
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        )}
                        <Col xs="auto">
                          <div className="d-flex pt-3">
                            <div className="selectable-media" hidden>
                              <Label
                                for="uploadFilesFromSystemMini"
                                className="gallery-media"
                                style={{
                                  borderStyle: "dashed",
                                  cursor: "pointer",
                                }}
                              >
                                <div className="d-flex h-100 align-items-center justify-content-center">
                                  <span className="fs-14">Upload More</span>
                                </div>
                              </Label>
                              <Input
                                type="file"
                                name="upload_media_file"
                                id="uploadFilesFromSystemMini"
                                accept="image/*, video/*"
                                onChange={this.handleOnFilePostUploadChange}
                                multiple
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                    {this.state.uploadError &&
                      <div className="alert alert-danger font-weight-bold uploadError">{this.state.uploadError}</div>
                    }

                    <div className="d-flex mx-n2 justify-content-center">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              uploadMediaPostModal: false,
                            });
                            if (this.state.uploadMedia?.selectedMedia.length > 0) {
                              this.setState({ editPostError: '' })
                            }
                          }}
                        >
                          {"OK"}
                        </Button>
                      </div>
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>

                    </div>
                  </TabPane>
                  <TabPane tabId="gallery">
                    <div className="mb-2">
                      <Nav tabs className="d-inline-flex mb-0">
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "images"}
                            onClick={() => {
                              this.handleOnClickGalleryType("images");
                            }}
                          >
                            Image Gallery
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "videos"}
                            onClick={() => {
                              this.handleOnClickGalleryType("videos");
                            }}
                          >
                            Video Gallery
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.showGalleryType}>
                      <TabPane tabId="images">
                        <div>
                          <FormGroup className="form-row justify-content-end">
                            <Col xs="auto">
                              <Label className="small" for="selectAlbumType">
                                Album Type:
                              </Label>
                            </Col>
                            <Col xs="auto">
                              <Input
                                bsSize="sm"
                                type="select"
                                name="album_type_select"
                                id="selectAlbumType"
                                defaultValue={this.state.uploadMedia.albumType}
                                onChange={this.handleOnClickAlbumTypeChange}
                              >
                                <option value="">All</option>
                                {this.state.uploadMedia.albumTypesList.length >
                                  0 &&
                                  this.state.uploadMedia.albumTypesList.map(
                                    (type) => (
                                      <option value={type.category}>
                                        {type.category}
                                      </option>
                                    )
                                  )}
                              </Input>
                            </Col>
                          </FormGroup>
                        </div>
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryImageScroll}
                          onScroll={this.handleScrollGalleryImage}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                            <Row>{this.fetchScrollPhotoMedia()}</Row>
                          ) : (
                            <div className="bg-white p-3">
                              <h2 className="text-secondary-dark">No images</h2>
                            </div>
                          )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                                if (this.state.uploadMedia?.selectedMedia.length > 0) {
                                  this.setState({ editPostError: '' })
                                }
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="videos">
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryVideoScroll}
                          onScroll={this.handleScrollGalleryVideo}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                            <Row>{this.fetchScrollPhotoMedia(true)}</Row>
                          ) : (
                            <div className="bg-white p-3">
                              <h2 className="text-secondary-dark">No videos</h2>
                            </div>
                          )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="embed">
                    <div className="text-muted mb-2">
                      Submit the link for the image or video you want to embed{" "}
                    </div>
                    <div className="mb-2">
                      {/* Repeat this while adding */}
                      {this.mapEmbedLinks()}
                    </div>
                    <div className="d-flex mx-n2 mb-2">
                      <div className="px-2 ml-auto">
                        {/* <Button
                          title="Add"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            this.addEmbedlinkRow();
                          }}
                        >
                          <FontAwesomeIcon icon="plus" />
                        </Button> */}
                      </div>
                    </div>
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={(e) => {
                            this.insertEmbedLinks(e);
                          }}
                        >
                          Insert
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          size="sm"
          isOpen={this.state.confirmDeleteModal}
          toggle={this.confirmDeleteModalToggle}
          className="text-center"
          modalClassName="backdrop-opaque delete-box top-shifted transition-none"
        >
          <div className="bg-white modal-header">
            <h5 className="modal-title mx-auto text-dark fs-38">Confirmation</h5>
            <button
              className="btn btn-sm fs-18"
              aria-label="Close"
              onClick={this.confirmDeleteModalToggle}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <ModalBody className="text-dark fs-14">
            Are you sure you want to delete this {this.state.deletePostType ? this.state.deletePostType : ''}?
          </ModalBody>
          <ModalFooter className="bg-white">
            <div className="text-center w-100">
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={this.confirmDeleteModalToggle}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={this.handleOnDeleteConfirmation}
              >
                Ok
              </Button>
            </div>
          </ModalFooter>
        </Modal>

        {/* View Media Modal */}
        <Modal
          centered
          isOpen={this.state.viewMyPhotosModal}
          toggle={() =>
            this.setState({ viewMyPhotosModal: !this.state.viewMyPhotosModal })
          }
        >
          {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
          <ModalBody className="bg-transparent p-0">
            <Carousel
              responsive={responsive.videoModal}
              infinite={true}
              autoPlay={false}
              swipeable={true}
              showDots={false}
              arrows={true}
              sliderClass={`${imageAttacment?.length === 0}` ? "mx-auto" : ""}
            >
              {imageAttacment.length > 1 ? (
                imageAttacment.map((med, i) => {
                  return (
                    <div
                      className="item fs-14 h-100 d-flex flex-column justify-content-center"
                      key={i}
                    >
                      <div>
                        <div className="d-flex text-white justify-content-between mt-3">
                          <div className="mr-2 ff-alt">{med?.caption}</div>
                          <div className="ff-base">{med?.label}</div>
                        </div>
                      </div>
                      <div
                        className="text-center mt-3 d-flex flex-column justify-content-center align-items-center"
                        style={{ minHeight: "300px" }}
                      >
                        {(med?.type === "image" || med?.type === "") && (
                          <img
                            className="img-fluid"
                            src={med?.url}
                            alt=""
                            style={{
                              maxHeight: "300px",
                              border: "2px solid #8791b5",
                            }}
                          />
                        )}
                        {med?.type === "video" && (
                          <video
                            className="img-fluid"
                            controls
                            style={{ maxHeight: "300px" }}
                          >
                            <source src={med?.url} type="video/mp4" />
                            {"Your browser does not support the video tag."}
                          </video>
                        )}

                        {(med?.type.includes('audio')) && (
                          <audio width="100%" controls
                            style={{
                              maxHeight: "300px",
                              border: "2px solid #8791b5",
                            }}>
                            <source
                              src={med?.url}
                              type={'audio/mpeg'}
                            />
                            Your browser does not support the audio
                            tag.
                          </audio>

                        )}

                      </div>
                      <div className="px-2 mt-3">
                        <div className="d-flex flex-nowrap mx-n2">
                          <div className="px-2 col d-flex flex-nowrap">
                            <div className="mr-2">
                              <img
                                className="img-circle _30x30"
                                src={
                                  med?.user?.current_profile_pic
                                    ? med?.user?.current_profile_pic
                                    : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"
                                }
                                alt=""
                                onError={(error) =>
                                  (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                }
                              />
                            </div>
                            <div>
                              <div className="text-primary ff-base">
                                Uploaded by&nbsp;
                                <a className="text-reset" href="#">
                                  <span className="font-weight-bold">
                                    {med?.user?.user?.first_name}
                                  </span>{" "}
                                </a>
                              </div>
                              <div className="text-tertiary ff-base">
                                {med?.upload_date}
                              </div>
                            </div>
                          </div>
                          <div className="px-2 col-auto">
                            <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                              {i + 1} of {imageAttacment.length}
                            </h2>
                          </div>
                          <div className="px-2 col">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="item px-4">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                      <span>No result found for this category</span>
                    </div>
                  </div>
                </div>
              )}
            </Carousel>
          </ModalBody>
        </Modal>

        {/* See who reacted Modal */}
        <Modal
          size="lg"
          isOpen={this.state.viewPeopleWhoReactedModalToggle}
          toggle={() =>
            this.setState({
              viewPeopleWhoReactedModalToggle: !this.state
                .viewPeopleWhoReactedModalToggle,
            })
          }
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                viewPeopleWhoReactedModalToggle: !this.state
                  .viewPeopleWhoReactedModalToggle,
              })
            }
          ></ModalHeader>
          <ModalBody>
            <Nav className="nav-fill active-transparent" tabs>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "all"}
                  onClick={() => {
                    this.handleOnClickReacted("all");
                  }}
                >
                  <div className="ff-base">
                    All&nbsp;<span>{reviewAll}</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "woohoo"}
                  onClick={() => {
                    this.handleOnClickReacted("woohoo");
                  }}
                >
                  <div className="ff-base">
                    Woohoo&nbsp;
                    <span>
                      {review_group_animation &&
                        Object.keys(review_group_animation).length > 0 &&
                        review_group_animation.review_group_count.woohoo
                        ? review_group_animation.review_group_count.woohoo
                        : 0}
                    </span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "nailed"}
                  onClick={() => {
                    this.handleOnClickReacted("nailed");
                  }}
                >
                  <div className="ff-base">
                    Nailed It&nbsp;
                    <span>
                      {review_group_animation &&
                        Object.keys(review_group_animation).length > 0 &&
                        review_group_animation.review_group_count.nailed
                        ? review_group_animation.review_group_count.nailed
                        : 0}
                    </span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "insightful"}
                  onClick={() => {
                    this.handleOnClickReacted("insightful");
                  }}
                >
                  <div className="ff-base">
                    Insightful&nbsp;
                    <span>
                      {review_group_animation &&
                        Object.keys(review_group_animation).length > 0 &&
                        review_group_animation.review_group_count.insightful
                        ? review_group_animation.review_group_count.insightful
                        : 0}
                    </span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "lol"}
                  onClick={() => {
                    this.handleOnClickReacted("lol");
                  }}
                >
                  <div className="ff-base">
                    LOL&nbsp;
                    <span>
                      {review_group_animation &&
                        Object.keys(review_group_animation).length > 0 &&
                        review_group_animation.review_group_count.lol
                        ? review_group_animation.review_group_count.lol
                        : 0}
                    </span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "bummer"}
                  onClick={() => {
                    this.handleOnClickReacted("bummer");
                  }}
                >
                  <div className="ff-base">
                    Bummer&nbsp;
                    <span>
                      {review_group_animation &&
                        Object.keys(review_group_animation).length > 0 &&
                        review_group_animation.review_group_count.bummer
                        ? review_group_animation.review_group_count.bummer
                        : 0}
                    </span>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.viewReactedType}>
              <TabPane tabId="all">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("all")}
                </ul>
              </TabPane>
              <TabPane tabId="woohoo">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("woohoo")}
                </ul>
              </TabPane>
              <TabPane tabId="nailed">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("nailed")}
                </ul>
              </TabPane>
              <TabPane tabId="insightful">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("insightful")}
                </ul>
              </TabPane>
              <TabPane tabId="lol">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("lol")}
                </ul>
              </TabPane>
              <TabPane tabId="bummer">
                <ul className="list-unstyled d-flex flex-column">
                  {this.fetchReviewGroupList("bummer")}
                </ul>
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
const mapState = (state) => ({
  my_posts_list: state.user.my_posts_list,
  album_types_list: state.user.album_types_list,
  album_type_data: state.user.album_type_data,
  search_people_data: state.user.search_people_data,
  search_people_data_edit: state.user.search_people_data_edit,
  //search_people_data_loading: state.user.search_people_data_loading,
  profile_data: state.user.my_profile,
  get_reveiewData: state.user.get_reveiewData,
  peopleList: state.user.people_list.results && state.user.people_list.results,
  fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
  post_tag_list: state.user.tags_list.results && state.user.tags_list.results,
});

const mapDispatch = (dispatch) => ({
  get_my_posts_list: (pageNo) => dispatch(get_my_posts_list(pageNo)),
  reset_my_posts_list: (pageNo) => dispatch(reset_my_posts_list(pageNo)),
  get_album_types_list: () => dispatch(get_album_types_list()),
  get_album_type_data: (type, album, page) =>
    dispatch(get_album_type_data(type, album, page)),
  delete_selected_gallery_media: (dataID) =>
    dispatch(delete_selected_gallery_media(dataID)),
  add_my_post: (data, type, page) => dispatch(add_my_post(data, type, page)),
  add_my_post_comment: (id, data, type, page) =>
    dispatch(add_my_post_comment(id, data, type, page)),
  add_my_post_reply: (data, type, page) =>
    dispatch(add_my_post_reply(data, type, page)),
  get_search_people: (params, search) =>
    dispatch(get_search_people(params, search)),
  get_search_people_edit: (params, search) =>
    dispatch(get_search_people_edit(params, search)),
  delete_my_post: (postId, filterType, page, message) =>
    dispatch(delete_my_post(postId, filterType, page, message)),
  delete_my_post_comment: (deletePostId, filterType, page) =>
    dispatch(delete_my_post_comment, (deletePostId, filterType, page)),
  edit_my_post: (data, type, filterType, pageNo) =>
    dispatch(edit_my_post(data, type, filterType, pageNo)),
  get_reviewgroup: (assetType, postId, type) =>
    dispatch(get_reviewgroup(assetType, postId, type)),
  clear_search_people: () => dispatch(clear_search_people()),
  clear_search_people_edit: () => dispatch(clear_search_people_edit()),
  add_review_group: (data, page) => dispatch(add_review_group(data, page)),
  getListOfPeople: (data, page) => dispatch(getListOfPeople(data, page)),
  fetch_taxonomy_list: (category, type) =>
    dispatch(fetch_taxonomy_list(category, type)),
  upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
  get_tags_list: (data, page) => dispatch(get_tags_list(data, page)),
  embedNewLink: ({ data }) => (dispatch(embedNewLink({ data }))),
});

export default connect(mapState, mapDispatch)(Posts);
