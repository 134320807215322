import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import moment from "moment";

import {
  upload_media,
  initFetchMovieSubCategories,
  search_moviesMembers,
  get_patrol_crp,
  submit_patrol_review
} from "../../../actions/footer";


import { callApi } from "../../../utils/apiCaller";

class PatrolCRPModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_draft: false,
      rating: "",
      duration: "",
      reason: '',
      category_id: '',
      subcategory_id: '',
      rateType: "",
      patrolCrpListing: [],
      type: "communityreviews",
      listingType: "products",
      taxonomyId: "",
      reasonError: "",
      decision: ""

    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.fetch_save_movie_response) {
      if (nextProps.fetch_save_movie_response && nextProps.fetch_save_movie_response.id && nextProps.fetch_save_movie_response.slug) {
        let slug = null;
        let movieId = "";
        if (nextProps.fetch_save_movie_response.id != "") {
          movieId = nextProps.fetch_save_movie_response.id;
        }
        if (nextProps.fetch_save_movie_response.slug != "") {
          slug = nextProps.fetch_save_movie_response.slug;
        }
        this.redirectto(movieId, slug);

      }
    }

    if (nextProps.ratingType && nextProps.ratingType != "") {
      this.setState({ rateType: nextProps.ratingType })
    }

    if (nextProps.patrolCrp && nextProps.patrolCrp.results && Array.isArray(nextProps.patrolCrp.results) && nextProps.patrolCrp.results.length > 0) {
      this.setState({ patrolCrpListing: nextProps.patrolCrp.results })
    }

  }
  redirectto = (movieId, slug) => {
    let path = "movies";
    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: movieId }
    })
  }

  componentDidMount = () => {
    let { type, listingType } = this.state;
    let id = ""
    //this.props.get_patrol_crp(id,type,listingType);
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let { rateType } = this.state;
    this.setState({
      [name]: value
    });

  }

  handleCRPSubmit = () => {
    let { reason, decision, patrolCrpListing } = this.state
    if (reason == "") {
      this.setState({
        reasonError:
          "This field is required"
      });
    } else {
      if (patrolCrpListing && Array.isArray(patrolCrpListing) && patrolCrpListing.length > 0) {
        let params = {}
        params.flag_details = {}
        params.flag_details.type = "Projects";
        params.flag_details.decision = decision;
        params.flag_details.reason = reason;
        params.flag_details.object_id = patrolCrpListing && patrolCrpListing[0] ? patrolCrpListing[0].id : "";
        params.flag_details.is_patroled = true;
        this.props.submit_patrol_review(params);
        this.setState({ reason: "", decision: "" }, () => {
          this.handleSkip()
        })

      }

    }

  }

  handleChangeCRP = (e) => {
    if (e.target.name === 'reason') {
      this.setState({ reason: e.target.value, reasonError: "" })
    }
    if (e.target.name === 'Approved') {
      this.setState({ decision: e.target.value })
    }
    if (e.target.name === 'Rejected') {
      this.setState({ decision: e.target.value })
    }
  };
  handleSkip = () => {
    let { patrolCrpListing } = this.state;
    if (patrolCrpListing && Array.isArray(patrolCrpListing) && patrolCrpListing.length > 0) {
      patrolCrpListing.shift();
    }
    this.setState({ patrolCrpListing });
  }

  render() {
    const {
      openModal,
      togglePatrolCRPModal,
    } = this.props;
    const { category_id, name, rating, patrolCrpListing, reason } = this.state;
    return (
      <>
        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={openModal}
          toggle={togglePatrolCRPModal}
        >
          <form
            className="d-flex flex-column h-100"
          >
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button color="primary" onClick={togglePatrolCRPModal}>
                    <FontAwesomeIcon icon="angle-left" />
                        back to listing
                      </Button>
                </div>
                <div>
                  <div className="bg-darker p-3 text-tertiary">
                    <div className="mb-2 text-primary font-weight-bold">
                      You’re Patrolling CR Projects Taxonomy Changes
                        </div>
                  </div>
                </div>
              </div>
            </div>
            <ModalBody className="bg-white text-dark">
              <div className="wk-crpmodal">
                <Row>
                  <Col md="7">
                    <div className=" font-weight-bold mb-2 text-primary mb-0">Step 1. CR Projects</div>
                    <p className="text-dark font-weight-bold">The Brief.</p>
                    <p>{patrolCrpListing && Array.isArray(patrolCrpListing) && patrolCrpListing.length > 0 && patrolCrpListing[0] ? patrolCrpListing[0].title : ""}</p>
                  </Col>
                  <Col md="5">
                    <div className="font-weight-bold mb-2 text-primary">Step 2. Approve or Disapprove</div>
                    <div className="wk-approve-petrol-radio">
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" name="Approved" value="Approved" checked={this.state.decision === "Approved"} onChange={this.handleChangeCRP} />Approved</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" name="Rejected" value="Rejected" checked={this.state.decision === "Rejected"} onChange={this.handleChangeCRP} />Rejected</label>
                    </div>
                    <textarea className="form-control wk-crpmodal-quick-note wk-crpmodal-quick-note2  w-100" value={reason} name="reason" placeholder="Tell us why?" onChange={this.handleChangeCRP}></textarea>
                    <span style={{ color: 'red', fontSize: "13px" }}>{this.state.reasonError}</span>
                    <div className="text-right mt-3">
                      <Button className="ml-0" color="primary" size="sm" onClick={this.handleCRPSubmit}>
                        Submit
                      </Button>
                      <a href="javascript:void(0)" className="ml-4" onClick={() => {
                        this.handleSkip();
                      }}
                      >Skip ></a>
                    </div>
                  </Col>
                </Row>
              </div>

            </ModalBody>
          </form>
        </Modal>



      </>


    );



  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  directorListing: state.footer.directorListing,
  writerListing: state.footer.writerListing,
  castListing: state.footer.castListing,
  producerListing: state.footer.producerListing,
  fetch_save_movie_response: state.movies.fetch_save_movie_response,
  patrolCrp: state.footer.patrolCrp
});

const mapProps = (dispatch) => ({
  submit_patrol_review: (data) => dispatch(submit_patrol_review(data)),
  initFetchMovieSubCategories: (categoryId, type) => dispatch(initFetchMovieSubCategories(categoryId, type)),
  search_moviesMembers: (value, type) => dispatch(search_moviesMembers(value, type)),
  get_patrol_crp: (categoryId, type, listType) => dispatch(get_patrol_crp(categoryId, type, listType)),
});
export default withRouter(connect(mapState, mapProps)(PatrolCRPModal));