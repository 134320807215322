import React from "react";
import Loaderimg from "../../assets/images/w-brand-black.jpg";

export default class Loader extends React.Component {
    render() {
        return (
            <div className="loader_div">
                <div className="inner-loader">
                    <img src={Loaderimg} alt="" />
                </div>
            </div>
        )
    }
}