import React, { useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu,DropdownItem } from 'reactstrap';




const SortByDropdown = ({ graphType, totalRating , setGraphType,bgLight}) => {
    const [dropdownSortBy, setDropdownSortBy] = useState(false);

    const toggleCategory = () => {
        setDropdownSortBy((prevState) => !prevState);
    }

    const onMouseEnterCategory = () => {
        setDropdownSortBy(true);
    }

    const onMouseLeaveCategory = () => {
        setDropdownSortBy(false);
    }

    const renderDropDownItem = ({ title }, totalRating) => {
        
        return (
            <DropdownItem
                onClick={() => {
                    setGraphType(title);
                }
                }
                className="fs-14">
                {title}
            </DropdownItem>
        )
    };
   
    return (
        <div className="text-right">
            <UncontrolledDropdown 
                isOpen={dropdownSortBy}
                toggle={toggleCategory}>
                <DropdownToggle onMouseOver={onMouseEnterCategory}                    
                    className={"text-secondary-dark bg-transparent font-weight-bold " + `${bgLight ?"type-secondary" : "text-greyish"}`} color="transparent" size="sm" caret>
                    <span className="pr-2">
                        {/* Sort By */}
                        {graphType}
                    </span>
                </DropdownToggle>
                <DropdownMenu className={"_alt _bold wk-sort-dropdown-new " + `${bgLight ?"type-secondary" : "type-tertiary2"}` }>
                    {totalRating !== null && Object.keys(totalRating).length ?
                        <React.Fragment>
                            {renderDropDownItem({ title: 'Overall Rating' })}
                        </React.Fragment>
                        : null}

                    {totalRating !== null && Object.keys(totalRating).length ?
                        Object.keys(totalRating).map((item, rating_index) => {
                            return (item !== "total_review" && item !== "avg_rating") ?
                                <React.Fragment key={rating_index}>
                                    {renderDropDownItem({ title: item }, totalRating)}
                                </React.Fragment>
                                :
                                null;
                        })
                        : null}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}

export default SortByDropdown;