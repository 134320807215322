import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Media, Row, UncontrolledDropdown, Modal, ModalBody, ModalFooter } from 'reactstrap';
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from "../../atoms/editBtn";
import feedCam from './../../../assets/images/icons/feed-cam.png';
import { connect } from 'react-redux';
import { callApi } from '../../../utils/apiCaller';
import {
    get_business_QA_dropdown,
    add_media_to_gallery,
    get_professional_QA_data,
    ask_something_question,
    business_edit_quesiton,
    reply_to_question,
    professional_qa_delete_userfeed,
    sort_business_QA_data,
} from '../../../actions/business'
import {
    get_album_types_list,
    get_album_type_data,
    delete_selected_gallery_media
} from "../../../actions/user";
import { parseInt } from 'lodash';
import Carousel from 'react-multi-carousel';

const responsive = {
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1,
        },
    }
};

class QandATab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createNewDisModalToggle: false,
            qaDropDown: null,
            qaData: null,
            business_tos: null,
            askSomethingBody: null,
            answerQuestion: "",
            quesAboutID: null,
            editQuestion: false,
            editQuestionID: null,
            editQuestionObj: null,
            editConversation: false,
            editConversationID: null,
            editConversationObj: null,
            confirmDeleteModal: false,
            editPromptModalToggle: false,
            deleteFeedID: null,
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            fetchGalleryAlbum: "",
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            uploadMediaPostModal: false,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            albumType: '',
            pageNo: 1,
            selectedLabel: 'Sort By',
			selectedLabelAll: 'all',
            lawyerId: 0,
            uploadedFiles: [],
            uploadUrl: `/upload/multiuploader/?album=feeds&instance=newpost&image=undefined&doc=undefined`
        }
    }

    componentDidMount(){
        if (this.props.lawyerId && this.state.lawyerId === 0) {
            this.props.get_business_QA_dropdown(this.props.lawyerId);
            this.props.get_professional_QA_data(this.props.lawyerId);
            this.setState({ lawyerId: this.props.lawyerId });
        }
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.lawyerId && this.state.lawyerId === 0) {
            this.props.get_business_QA_dropdown(nextProps.lawyerId);
            this.props.get_professional_QA_data(nextProps.lawyerId);
            this.setState({ lawyerId: nextProps.lawyerId });
        }

        if (nextProps.uploadedMediaSet.length !== this.state.uploadedFiles.length) {
            this.setState({ uploadedFiles: [...nextProps.uploadedMediaSet] });
        }

        const { qa_dropdown, qa_data } = nextProps;
        let { fetchGalleryScroll, fetchGalleryCount } = this.state;

        if (qa_dropdown && qa_dropdown.res.length > 0) {
            this.setState({
                qaDropDown: qa_dropdown.res
            })
        }

        if (qa_data) {
            this.setState({
                qaData: qa_data
            })
        }


        if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypesList: nextProps.album_types_list,
                },
                //uploadMediaModal: true,
                selectedUpMediaType: "upload",
                showGalleryType: "images",
            });
        }

        if (
            nextProps.album_type_data &&
            Object.keys(nextProps.album_type_data).length > 0
        ) {
            let item = nextProps.album_type_data;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypeData: nextProps.album_type_data,
                },
                fetchGalleryScroll, fetchGalleryCount,
            });
        }
    }

    truncate = (filenameString) => {
        // let split = filenameString.split(".");
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };

    handleChange = async (evt) => {
        await this.setState({
            [evt.target.name]: evt.target.value,
        });
    }

    handleRadioButtonChange = async (evt) => {
        await this.setState({
            [evt.target.name]: evt.target.id,
        });
    }

    handleQuesionSubmit = async () => {
        const { ask_something_question, lawyerId } = this.props;
        let messagemedia_set = [...this.state.uploadedFiles].map(item => item.id);
        const data = {
            body: this.state.askSomethingBody ? this.state.askSomethingBody : "",
            tos: this.state.business_tos ? this.state.business_tos : "",
            tag_list: [],
            listing_tag_list: [],
            question_rating_category: this.state.quesAboutID ? [
                parseInt(this.state.quesAboutID)
            ] : [],
            professionalmessagemedia_set: messagemedia_set,
            user_entry: lawyerId,
            qaType: 'professional'
        }
        await ask_something_question(lawyerId, data);
        await this.props.resetUploadedMediaSet();
        await this.setState({
            askSomethingBody: '',
            quesAboutID: '',
            business_tos: '',
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            uploadedFiles: []
        })
    }

    handleEditQuestionChange = async (type, key1, key2, evt, value) => {
        const {
            editQuestionObj, editConversationObj
        } = this.state
        let input = evt.target && evt.target.value;
        if (type === 'question') {
            await editQuestionObj && Object.keys(editQuestionObj).map(element => {
                if (element === key1) {
                    this.setState({
                        editQuestionObj: {
                            ...this.state.editQuestionObj,
                            [key1]: input,
                            [key2]: input,
                            editpost: true

                        },
                    })
                }
            })
        } else if (type === 'conversation') {
            await editConversationObj && Object.keys(editConversationObj).map(element => {
                if (element === key1) {
                    this.setState({
                        editConversationObj: {
                            ...this.state.editConversationObj,
                            [key1]: input,
                            [key2]: input,
                            editpost: true
                        },
                    })
                }
            })
        }
    }

    confirmDeleteModalToggle = () => {
        this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
    };

    handleOnDeleteConfirmation = () => {
        if (this.state.deleteFeedID) {
            this.props.professional_qa_delete_userfeed(
                this.props.lawyerId,
                this.state.deleteFeedID,
                this.state.deleteType
            );
        }
        this.setState({
            confirmDeleteModal: !this.state.confirmDeleteModal,
        });
    };

    handleAnswerSubmit = async (id) => {
        const { reply_to_question, lawyerId } = this.props;
        let msgid = id;
        const data = {
            [msgid]: this.state.answerQuestion,
            "user_entry": lawyerId,
            "reply": this.state.answerQuestion,
            "tag_list": [

            ],
            "professionalmessagemedia_set": [

            ],
            qaType: 'professional'
        }

        await reply_to_question(lawyerId, msgid, data);
        await this.setState({
            answerQuestion: ""
        })
    }

    handleEditQuestionSubmit = async (data, id) => {
        const newData = {
            body: data.body,
            editpost: true,
            professionalmessagemedia_set: [],
            qaType: 'professional'
        }
        await this.props.business_edit_quesiton(
            this.props.lawyerId,
            id,
            newData
        );
    }

    removeFromUploaded = (index) => {
        let { uploadedFiles } = this.state;
        if (index > -1) {
            uploadedFiles.splice(index, 1);
        }
        this.setState({ uploadedFiles });
    }

    openSecondCarousal = (files, index) => {
        const carouselMedia = files;
        const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
        const array2 = (index !== carouselMedia.length - 1) ? carouselMedia.filter((item, i) => i > index) : [];
        const newArray = [carouselMedia[index], ...array2, ...array1];

        this.setState({ secondCarouselMedia: newArray, viewMyPhotosModalnew: true });
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const { qaDropDown, qaData, answerQuestion, uploadUrl, uploadedFiles } = this.state
        const { lawyerId } = this.props;

        return (
            <div>
                <div className="d-flex mx-n2 mb-3">
                    <div className="px-2">
                        <Button
                            size="sm"
                            color="filterable"
							active={this.state.selectedLabelAll === "all"}
                            onClick={() => {
                                this.props.get_professional_QA_data(lawyerId);
								this.setState({ selectedLabelAll: "all" });
								
                            }}
                        >All</Button>
                    </div>
                    <div className="px-2">
                        <UncontrolledDropdown>
                            <DropdownToggle className="text-tertiary bg-transparent font-weight-bold py-0" color="transparent" size="sm" caret>
                                <span className="pr-2">
                                    {this.state.selectedLabel}
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="type-tertiary">
                                {
                                    qaDropDown && qaDropDown.length > 0 ?
                                        qaDropDown.map((item) => {
                                            return <DropdownItem
                                                className="fs-14"
                                                onClick={(e) => {
                                                    let sortByid = e.target.id
                                                    this.props.sort_business_QA_data(lawyerId, sortByid, 'lawyers');
                                                    this.setState({ selectedLabel: item.label,selectedLabelAll: "" });
                                                }}
                                                id={item.id}
                                                name={item.name}
                                            >
                                                {item.label}
                                            </DropdownItem>
                                        }) : ""
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
                <div className="mb-4 text-dark">
                    <h2>
                        Ask Something
                                            </h2>
                    <p>
                        Want to know more about this Professional? Ask the WikiReviews community or your friends a question using the box below!
                                            </p>

                    <div>
                        <FormGroup className="main-post-formgroup">
                            <div className="input-labl">
                                <Input
                                    className="primary text-primary font-weight-bold"
                                    type="textarea"
                                    rows="4"
                                    placeholder="Please post the question you have about this lawyer."
                                    name="askSomethingBody"
                                    value={this.state.askSomethingBody || ''}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </FormGroup>
                    </div>
                </div>
                <div className="mb-4">
                    <FormGroup tag="fieldset">
                        <div className="text-tertiary mb-0 fs-16">What is your question about?</div>
                        <hr className="bg-tertiary mt-1" />
                        <Row md={3} form>
                            {
                                qaDropDown && qaDropDown.length > 0 ?
                                    qaDropDown.map((item) => {
                                        return <Col>
                                            <FormGroup check>
                                                <Label className="ff-base font-weight-normal" check>
                                                    <Input
                                                        type="radio"
                                                        name='quesAboutID'
                                                        value={this.state.quesAboutID || ''}
                                                        onChange={this.handleRadioButtonChange}
                                                        checked={this.state.quesAboutID === `${item.id}` ? true : false}
                                                        id={item.id}
                                                    />{' '}
                                                    {item.label}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    }) : ""
                            }
                        </Row>
                    </FormGroup>
                </div>
                <div className="mb-4">
                    <FormGroup tag="fieldset">
                        <div className="text-tertiary mb-0 fs-16">Who's your question addressed to?</div>
                        <hr className="bg-tertiary mt-1" />
                        <Row md={3} form>
                            <Col>
                                <FormGroup check>
                                    <Label className="ff-base font-weight-normal" check>
                                        <Input type="radio" name="business_tos"
                                            onChange={this.handleRadioButtonChange}
                                            value={this.state.business_tos || ''}
                                            checked={this.state.business_tos === "0" ? true : false}
                                            id={0} />{' '}
                                        {'to professional'}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label className="ff-base font-weight-normal" check>
                                        <Input type="radio" name="business_tos"
                                            onChange={this.handleRadioButtonChange}
                                            value={this.state.business_tos || ''}
                                            checked={this.state.business_tos === "1" ? true : false}
                                            id={1} />{' '}
                                        {'to community'}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label className="ff-base font-weight-normal" check>
                                        <Input type="radio" name="business_tos"
                                            onChange={this.handleRadioButtonChange}
                                            value={this.state.business_tos || ''}
                                            checked={this.state.business_tos === "3" ? true : false}
                                            id={3} />{' '}
                                        {'to friends'}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label className="ff-base font-weight-normal" check>
                                        <Input type="radio" name="business_tos"
                                            onChange={this.handleRadioButtonChange}
                                            value={this.state.business_tos || ''}
                                            checked={this.state.business_tos === "2" ? true : false}
                                            id={2} />{' '}
                                        {'to all'}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                </div>
                {/* Uploaded media shown here */}
                {this.state.uploadedFiles.length > 0
                    ?
                    <div className="border mb-3">
                        <Row noGutters>
                            {this.state.uploadedFiles.length > 0 &&
                                this.state.uploadedFiles.map((item, index) => {
                                    return (
                                        <Col xs="auto" key={item.id}>
                                            <div className="d-flex pr-3 m-2" >
                                                <div className="project-thumbnail">
                                                    {(item.type === "image" ||
                                                        item.media_type === "image") && (
                                                            <div

                                                                style={{ border: "none" }}
                                                            >
                                                                <img className="img-fluid img-thumbnail" src={item.file ? item.file : item.url} alt="Uploaded media" />
                                                            </div>
                                                    )}
                                                    {(item.type === "video" ||
                                                        item.media_type === "video") && (
                                                            <div

                                                                style={{ border: "none" }}
                                                            >
                                                                <video width="100" height="100">
                                                                        <source src={item.file ? item.file : item.url} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                    )}
                                                </div>

                                                <div className="mx-n3 mt-2">
                                                    <Button
                                                        color="delete-media"
                                                        size="sm"
                                                        title="Remove Media"
                                                        onClick={() =>
                                                            this.removeFromUploaded(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                    : ""
                }
                <div className="mb-3">
                    <div className="d-flex mx-n2">
                        <div className="px-2">
                            <Button color="transparent" size="sm" title="Upload Media" onClick={() => {
                                if(!this.checkIfLoggedIn()){
                                    this.props.forceToggleLoginModal();
                                    return false;
                                }
                                this.props.getuploadUrl({ uploadUrl });
                                this.props.toggleEnableFileUpload(true, false,false);
                            }}>< img src={feedCam} alt="" /></Button>
                        </div>
                        <div className="px-2 ml-auto">
                            <Button color="link" size="sm" className="text-primary" onClick={
                                () => {
                                    this.props.resetUploadedMediaSet();
                                    this.setState({
                                        askSomethingBody: "",
                                        quesAboutID: "",
                                        business_tos: "",
                                        uploadedFiles: []
                                    })
                                }
                            }>Clear</Button>
                            <Button color="primary" size="sm" onClick={() => {
                                if(!this.checkIfLoggedIn()){
                                    this.props.forceToggleLoginModal();
                                    return false;
                                }
                                this.handleQuesionSubmit()}}
                                disabled={
                                    this.state.askSomethingBody !== null &&
                                    /[a-zA-Z]/.test(this.state.askSomethingBody) &&
                                        this.state.quesAboutID !== null &&
                                        this.state.business_tos !== null ? false : true
                                }>Ask Something</Button>
                        </div>
                    </div>
                </div>

                <div>
                    {
                        this.state.qaData &&
                            this.state.qaData.results &&
                            this.state.qaData.results.length > 0 ?
                            this.state.qaData.results.map((item, index) => {
                                return <div className="bg-white p-3 fs-14 text-dark mb-3" key={item.id}>
                                    <div className="d-flex mx-n2 mb-4">
                                        <div className="px-2">
                                            <a href="#" className="text-decoration-none">
                                                <img className="img-circle _50x50" src={item.current_profile_pic} onError={(error) =>
                                                    (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                                                } alt="" />
                                            </a>
                                        </div>
                                        <div className="px-2 flex-grow-1">
                                            <div className="d-flex mx-n2">
                                                <div className="px-2 flex-grow-1">
                                                    <div className="ff-alt">
                                                        <a href="javascript:void(0)" className="text-reset ff-base font-weight-bold">{item.sender}</a>
                                                        {' '}
                                                        <span>asked</span>
                                                        {' '}
                                                        <a href="javascript:void(0)" className="text-reset ff-base font-weight-bold">the {item.recipients} </a>
                                                        {' '}
                                                        <span>{item.sent_at} about</span>
                                                        {' '}
                                                        <i className="text-tertiary font-weight-bold">{item.question_about}</i>
                                                    </div>
                                                </div>
                                                {/* <div className="px-2 col-auto">
                                                    <EditBtn />
                                                    <DeleteBtn />
                                                </div> */}
                                            </div>

                                            {/* Question details */}
                                            {this.state.editQuestion && this.state.editQuestionID === item.id ?
                                                //  Edit Question 
                                                <div className="mt-3">
                                                    {/* <FormGroup >
                                                        <Input className="primary" bsSize="sm" type="text" placeholder="Enter title for discussion" />
                                                    </FormGroup> */}
                                                    <FormGroup>
                                                        <Input
                                                            className="primary"
                                                            bsSize="sm"
                                                            type="textarea"
                                                            rows="5"
                                                            name="questionBody"
                                                            id="questionBody"
                                                            value={this.state.editQuestionObj.body || ''}
                                                            onChange={(value) => this.handleEditQuestionChange('question', 'body', 'body_preview', value)}
                                                        />
                                                    </FormGroup>
                                                    <div className="text-right mt-3">
                                                        <Button color="link" size="sm" className="font-weight-normal ff-alt text-royal-blue">Clear</Button>
                                                        <Button color="link" size="sm" className="font-weight-normal ff-alt text-royal-blue"
                                                            onClick={() => {
                                                                this.setState({
                                                                    editQuestion: false,
                                                                    editQuestionID: null,
                                                                    editQuestionObj: null,
                                                                })
                                                                // this.handleEditQuestion
                                                            }}
                                                        >Cancel</Button>
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            disabled={this.state.editQuestionObj.body === "" ? true : false}
                                                            onClick={async () => {

                                                                await this.handleEditQuestionSubmit(
                                                                    this.state.editQuestionObj,
                                                                    this.state.editQuestionID
                                                                );
                                                                await this.setState({
                                                                    editQuestion: false,
                                                                    editQuestionID: null,
                                                                    editQuestionObj: null
                                                                })
                                                            }}
                                                        >Post</Button>
                                                    </div>
                                                </div>
                                                :
                                                /* Question details */
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2 col">
                                                        <div className="ff-alt wb-break-word">
                                                            {item.body}
                                                        </div>
                                                    </div>

                                                    <div className="px-2 col-auto" hidden={!this.checkIfLoggedIn()}>
                                                        <EditBtn
                                                            onClick={() => {
                                                                if (item.conversation.length > 0) {
                                                                    this.setState({
                                                                        editPromptModalToggle: true
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        editQuestion: true,
                                                                        editQuestionID: item.id,
                                                                        editQuestionObj: item
                                                                    })
                                                                }
                                                                // this.handleEditQuestion
                                                            }} />
                                                        <DeleteBtn
                                                            onClick={async () => {
                                                                await this.setState({
                                                                    deleteFeedID: item.id,
                                                                    deleteType: 'question'
                                                                })
                                                                this.confirmDeleteModalToggle()
                                                            }} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* Gallery Images */}
                                    {item.attachments?.length > 0 ? 
                                        <div className="my-5">
                                            <div>
                                                {/* Featured Photo/Video */}
                                                {item.attachments.slice(0,1).map(media => {
                                                return (
                                                <div className="mx-auto text-center mb-3" style={{ maxWidth: "450px" }} key={media.id} onClick={() => this.openSecondCarousal(item.attachments, 0)}>
                                                    {media.media_type === 'image' && (
                                                        <div>
                                                            <img
                                                                src={media.url}
                                                                alt="Featured Photo"
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                    )}
                                                    {media.media_type === 'video' && (
                                                        <video className="img-fluid" controls>
                                                            <source
                                                                src={media.url}
                                                                type="video/mp4"
                                                            />
                                                            {'Your browser does not support the video tag.'}
                                                        </video>
                                                    )}
                                                </div>
                                                )})}
                                                <div className="mb-3">
                                                    {/* List of Photos uploaded */}
                                                    <ul className="list-unstyled d-flex justify-content-center flex-wrap mx-n1 mb-2" style={{ maxHeight: '280px', overflowY: 'auto' }}>
                                                        {/* Repeat this */}
                                                        {item.attachments && item.attachments.length > 1 ? (
                                                                item.attachments.filter((el,i) => i != 0).map((media, index) => {
                                                                    return (
                                                        <li onClick={() => this.openSecondCarousal(item.attachments,index+1)}
                                                            className="p-1"
                                                            role="button" key={media.id}>
                                                            {media.media_type === 'image' ?
                                                                <img
                                                                    className="img-thumbnail uploaded-img-thumbnail"
                                                                    src={media.url}
                                                                    alt="Featured Photo"
                                                                />
                                                                :
                                                                <div className="video-holder">
                                                                    <img
                                                                        className="img-thumbnail uploaded-img-thumbnail"
                                                                        src={media.thumbnail}
                                                                        alt="Featured Video"
                                                                    />
                                                                </div>
                                                            }
                                                        </li>
                                                        );
                                                        })) : ("")}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }
                                    {/* Gallery Images ends */}

                                    {/* All Answers */}
                                    <div>
                                        {/* Answer by other user */}
                                        {item.conversation && item.conversation.length > 0 ?
                                            item.conversation.map((element, index) => {
                                                return (
                                                    <>
                                                        <div className="d-flex mx-n2 mt-2">
                                                            <div className="px-2">
                                                                <a href="#" className="text-decoration-none">
                                                                    <img
                                                                        className="img-circle _50x50"
                                                                        src={`${element.current_profile_pic}`}
                                                                        alt={element.sender}
                                                                        onError={(error) =>
                                                                            (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                                                                        }
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="px-2 flex-grow-1">
                                                                <div className="d-flex mx-n2 mb-1">
                                                                    <div className="px-2 flex-grow-1">
                                                                        <div className="ff-alt">
                                                                            <a href="#" className="text-reset ff-base font-weight-bold">{element.sender}</a>
                                                                                &nbsp;
                                                                                <span>Replied to</span>
                                                                                &nbsp;
                                                                                <a href="#" className="text-reset ff-base font-weight-bold">{element.sender} </a>
                                                                                &nbsp;
                                                                                <span>{element.sent_at}</span>
                                                                                &nbsp;
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {this.state.editConversation && this.state.editConversationID === element.msg_id ?
                                                                    //  Edit Question   
                                                                    <div className="mt-2">
                                                                        <FormGroup>
                                                                            <Input
                                                                                className="primary"
                                                                                bsSize="sm"
                                                                                type="textarea"
                                                                                rows="5"
                                                                                name="questionBody"
                                                                                id="questionBody"
                                                                                value={this.state.editConversationObj.body || ''}
                                                                                onChange={(value) => this.handleEditQuestionChange('conversation', 'body', 'body_preview', value)}
                                                                            />
                                                                        </FormGroup>
                                                                        <div className="text-right mt-3">
                                                                            <Button color="link" size="sm" className="font-weight-normal ff-alt text-royal-blue">Clear</Button>
                                                                            <Button color="link" size="sm" className="font-weight-normal ff-alt text-royal-blue"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        editConversation: false,
                                                                                        editConversationID: null,
                                                                                        editConversationObj: null,
                                                                                    })
                                                                                    // this.handleEditQuestion
                                                                                }}
                                                                            >Cancel</Button>
                                                                            <Button
                                                                                color="primary"
                                                                                size="sm"
                                                                                disabled={this.state.editConversationObj.body === "" ? true : false}
                                                                                onClick={async () => {
                                                                                    await this.handleEditQuestionSubmit(
                                                                                        this.state.editConversationObj,
                                                                                        this.state.editConversationID
                                                                                    );
                                                                                    await this.setState({
                                                                                        editConversation: false,
                                                                                        editConversationID: null,
                                                                                        editConversationObj: null,
                                                                                    })
                                                                                }}
                                                                            >Post</Button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    /* Question details */
                                                                    <div className="d-flex mx-n2">
                                                                        <div className="px-2 col">
                                                                            <div className="ff-alt wb-break-word">
                                                                                {element.body}
                                                                            </div>
                                                                        </div>

                                                                        <div className="px-2 col-auto" hidden={!this.checkIfLoggedIn()}>
                                                                            <EditBtn
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        editConversation: true,
                                                                                        editConversationID: element.msg_id,
                                                                                        editConversationObj: element
                                                                                    });
                                                                                }} />
                                                                            <DeleteBtn
                                                                                onClick={async () => {
                                                                                    await this.setState({
                                                                                        deleteFeedID: element.msg_id,
                                                                                        deleteType: 'answer'
                                                                                    })
                                                                                    this.confirmDeleteModalToggle()
                                                                                }} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            })
                                            :
                                            ""}
                                    </div>

                                    {/* Comment box */}
                                    <div className="bg-light p-2 mt-3">
                                        <Media className="media-post">
                                            <Media>
                                                <Media
                                                    object
                                                    src={this.props.my_profile_data?.current_profile_file ? this.props.my_profile_data?.current_profile_file : require("../../../assets/images/icons/user-circle.png")}
                                                    alt="User Image"
                                                />
                                            </Media>
                                            <Media body>
                                                <FormGroup className="mb-0">
                                                    <Input
                                                        bsSize="sm"
                                                        className="mb-2 ph-primary text-primary font-weight-bold"
                                                        type="textarea"
                                                        name="answerQuestion"
                                                        value={this.state.answerQuestion || ''}
                                                        onChange={this.handleChange}
                                                        placeholder="Do you know the answer?"
                                                    />
                                                    <div className="text-right">
                                                        <div className="text-right">
                                                            <Button
                                                                size="sm"
                                                                color="primary"
                                                                disabled={this.state.answerQuestion === "" ? true : false}
                                                                onClick={() => {
                                                                    if(!this.checkIfLoggedIn()){
                                                                        this.props.forceToggleLoginModal();
                                                                        return false;
                                                                    }
                                                                    this.handleAnswerSubmit(item.id)
                                                                }
                                                                }
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </Media>
                                        </Media>
                                    </div>
                                </div>
                            })

                            : "No Questions to Display"
                    }
                </div>

                {/* Delete Confirmation Modal */}

                <Modal
                    size="sm"
                    isOpen={this.state.confirmDeleteModal}
                    toggle={this.confirmDeleteModalToggle}
                    className="text-center" modalClassName="backdrop-opaque top-shifted transition-none"
                >
                    <div className="bg-white modal-header">
                        <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
                        <button
                            className="btn btn-sm"
                            aria-label="Close"
                            onClick={this.confirmDeleteModalToggle}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark">
                        Are you sure you want to delete this {this.state.deleteType ? this.state.deleteType : ''}?
                    </ModalBody>
                    <ModalFooter className="bg-white">
                        <div className="text-center w-100">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={async () => {
                                    await this.setState({
                                        deleteFeedID: null,
                                    })
                                    this.confirmDeleteModalToggle()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={this.handleOnDeleteConfirmation}
                            >
                                Yes
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Edit Prompt Modal Popup */}
                <Modal
                    size="sm"
                    isOpen={this.state.editPromptModalToggle}
                    toggle={() =>
                        this.setState({
                            editPromptModalToggle: !this.state.editPromptModalToggle,
                        })
                    }
                    className="text-center"
                >
                    <div className="bg-white modal-header">
                        <h5 className="modal-title mx-auto text-dark">Alert</h5>
                        <button
                            className="btn btn-sm"
                            aria-label="Close"
                            onClick={() => this.setState({
                                editPromptModalToggle: false
                            })}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark">
                        You can't edit this question as other users have answered it.
                    </ModalBody>
                    <ModalFooter className="bg-white">
                        <div className="text-center w-100">
                            <Button color="primary" size="sm" className="mw" onClick={() =>
                                this.setState({
                                    editPromptModalToggle: false
                                })
                            }>
                                Ok
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* View Media Modal */}
                <Modal
                    centered
                    isOpen={this.state.viewMyPhotosModalnew}
                    toggle={() => {
                        this.setState({ viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew })
                    }}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            sliderClass={true ? "mx-auto" : ""}>
                            {/* Repeat this */}
                            {this.state.secondCarouselMedia?.length > 0 && this.state.secondCarouselMedia.map((med, i) => {
                                return (
                                    <div className="item fs-14 h-100 d-flex flex-column justify-content-center"
                                        key={med.id}
                                    >
                                        <div>
                                            <div className="d-flex text-white justify-content-between mt-3">
                                                <div className="mr-2 ff-alt">{med.caption}</div>
                                                <div className="ff-base">{med.label}</div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                            {(med.media_type === "image" || med.type === "") && (
                                                <img
                                                    className="img-fluid shown-media"
                                                    src={med.url}
                                                    alt=""
                                                />
                                            )}
                                            {med.media_type === "video" && (
                                                <video className="img-fluid shown-media" controls>
                                                    <source src={med.url} type="video/mp4" />
                                                    {'Your browser does not support the video tag.'}
                                                </video>
                                            )}
                                        </div>
                                        <div className="px-2 mt-3">
                                            <div className="d-flex flex-nowrap mx-n2">
                                                <div className="px-2 col d-flex flex-nowrap">
                                                    <div className="mr-2">
                                                        <img
                                                            className="img-circle _30x30"
                                                            src={med?.user?.user?.current_profile_pic
                                                                ? med?.user?.user?.current_profile_pic
                                                                : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                                            alt=""
                                                        />

                                                    </div>
                                                    <div>
                                                        <div className="text-primary ff-base">
                                                            {` Uploaded by `}
                                                            <a className="text-reset" href="#">
                                                                <span className="font-weight-bold">
                                                                    {med.user?.user.first_name} {med.user?.user.last_name}
                                                                </span>{" "}
                                                            </a>
                                                        </div>
                                                        <div className="text-tertiary ff-base">
                                                            {med.upload_date}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-2 col-auto">
                                                    <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                                                        {i + 1} of {this.state.secondCarouselMedia.length}
                                                    </h2>
                                                </div>
                                                <div className="px-2 col">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapState = (state) => ({
    qa_dropdown: state.business.qa_dropdown,
    qa_data: state.business.qa_data,
    album_types_list: state.user.album_types_list,
    album_type_data: state.user.album_type_data,
    my_profile_data: state.user.my_profile
});

const mapProps = (dispatch) => ({
    get_album_types_list: () => dispatch(get_album_types_list()),
    get_album_type_data: (type, album, page) => dispatch(get_album_type_data(type, album, page)),
    delete_selected_gallery_media: (dataID) => dispatch(delete_selected_gallery_media(dataID)),
    add_media_to_gallery: (data) => dispatch(add_media_to_gallery(data)),
    get_business_QA_dropdown: (id) => dispatch(get_business_QA_dropdown(id)),
    get_professional_QA_data: (id) => dispatch(get_professional_QA_data(id)),
    ask_something_question: (id, data) => dispatch(ask_something_question(id, data)),
    sort_business_QA_data: (id, sortByID, type) => dispatch(sort_business_QA_data(id, sortByID, type)),
    business_edit_quesiton: (id, msgid, data) => dispatch(business_edit_quesiton(id, msgid, data)),
    reply_to_question: (id, msgid, data) => dispatch(reply_to_question(id, msgid, data)),
    professional_qa_delete_userfeed: (id, msgid, type) => dispatch(professional_qa_delete_userfeed(id, msgid, type)),
});

export default connect(mapState, mapProps)(QandATab);