import React, { Component } from 'react'
import {
    FormGroup, Button, Input, Label,
     Form,  Collapse
} from 'reactstrap';
import CollapseBasic from './collapse';
import EditBtn from './editBtn';
import { callApi } from "../../utils/apiCaller";
import DeleteBtn from './deleteBtn';
import moment from 'moment';
import { toast } from "react-toastify";

export default class Associations extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchListing: {},
            businessListing: {},
            isOpenAssociations: false,
            associationItems: {
                selectedOrganisation: '',
                associationText: '',
                fromDate: '',
                toDate: '',
                isPresent: false,
                isEdit: false
            },
            searchText: '',
			assocationWithError:"",
			assocationError:"",
			startError:"",
			endError:""
        }
    }


    resetStates = (isTrue) => {
        const { id, listing_type } = this.props;
        this.setState({
            searchListing: {},
            // businessListing: {},
            isOpenAssosiation: !this.state.isOpenAssosiation,
            associationItems: {
                selectedOrganisation: {},
                associationText: '',
                fromDate: '',
                toDate: '',
                isPresent: false,
                isEdit: false
            },
            searchText: ''
        })

        if (isTrue) {
            let textItem = `${listing_type.slice(0, -1)}Id`;
            this.props.fetchByItemId({ [textItem]: id });
            this.listingDataItems();
        }
    }

    componentDidMount() {
        this.listingDataItems();
    }

    listingDataItems = async () => {
        const { id } = this.props;
        try {
            let url = `/api/professionalworkhistory/?&professionaluser_entry__id=${id}&taxonomy__category=association`;
            let response = await callApi(url, 'GET');

            if (response && response?.code !== 500) {
                this.setState({ businessListing: response?.results });
            }
        } catch (err) {
            console.log({ err });
        }
    }

    textOnSearch = async () => {
        let { searchText } = this.state;
        if (searchText?.length) {
            try {
                let url = `/api/search/?content_type=business&location=false&page=1&q=${searchText}&sensor=false&size=5`;
                let response = await callApi(url, 'GET');
                if (response && response?.code !== 500) {
                    this.setState({ searchListing: response });
                }
            } catch (err) {
                console.log(err);
            }
        }
    }


    handleSubmitEdu = async () => {
        let { associationItems: { selectedOrganisation, associationText, fromDate, toDate, isPresent }, startError, endError } = this.state;
        const { id } = this.props;
        let splitFrom = fromDate.split('-');
        let splitTo = toDate.split('-');
        let userId = null;
        if (id) {
            userId = id.toString();
        }
        let data = {
            "is_present": isPresent,
            "user_entry": selectedOrganisation?._source?.name,
            "title": associationText,
            "from_date": fromDate,
            "to_date": toDate,
            "search_id": selectedOrganisation?._id,
            "from_month": splitFrom[1], // month
            "from_year": splitFrom[0], // year
            "professionaluser_entry_id": id,
            "company": selectedOrganisation?._source?.name,
            "category": "association",
            "to_month": splitTo[1], // month
            "to_year": splitTo[0],  // year
            "user_entry_id": userId
        };
		
		if (associationText == "") {
		  this.setState({
			assocationError:
			  "This field is required",
			});
		}
		if (fromDate == "") {
			startError = "This field is required"
		}
		if (!isPresent && toDate == "") {
			endError = "This field is required"
		}

		if (Object.keys(selectedOrganisation).length == 0) {
		  this.setState({
			assocationWithError:
			  "This field is required",
			});
		}
		

        if (isPresent) {
            data['to_date'] = '';
            // data['user_entry_id'] = '';
            delete data['to_month'];
            delete data['to_year'];
        }

        if (!this.state.associationItems.is_present && this.state.associationItems.toDate === '') {
            endError = 'This field is required';
        } else if (this.state.associationItems.fromDate !== '' && (this.state.associationItems.toDate !== '' || !this.state.associationItems.toDate )) {
            const date = new Date()
            const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit' })
            const [{ value: month }, , { value: year }] = dateTimeFormat.formatToParts(date);
            const newDate = `${year}-${month}`;

            if (this.state.associationItems.fromDate > newDate) {
                startError = 'Start date must be less than your current date';
            } else if (this.state.associationItems.toDate > newDate && !this.state.associationItems.is_present) {
                endError = 'End date must be less than your current date';
            } else if (this.state.associationItems.fromDate >= this.state.associationItems.toDate && !this.state.associationItems.is_present)
                endError = 'End date should be greater than your start date.';
            else {
                endError = "";
                startError = ""
            }
        } else {
            endError = "";
        }

        if(this.state.associationItems.isPresent)
           endError = ""
        this.setState({startError: startError, endError: endError})



        if (id && associationText != "" && fromDate != "" && Object.keys(selectedOrganisation).length > 0 && startError == "" && endError == "") {

            try {
                let response = await callApi(`/api/professionalworkhistory/`, 'POST', data);
                if (response && response?.code !== 500) {

                    let message = response?.message;
                    if (message != "undefined" || message != undefined) {
                        toast(`${message}`, {
                            autoClose: 3500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        });
                    }
                    this.resetStates(true);
                }
            } catch (err) {
                console.log({ err });
            }
        }
    }


    updateEdu = async () => {
        let { associationItems: { selectedOrganisation, associationText, fromDate, toDate, isPresent, isEdit }, userEntryId } = this.state;
        const { id } = this.props;
        let splitFrom = fromDate.split('-');
        let splitTo = toDate.split('-');
        let userId = null;
        if (id) {
            userId = id.toString();
        }
		
        let datanew = {
            "category": "association",
			"company": selectedOrganisation?._source?.name,
            "from_date": fromDate,
            "from_month": splitFrom[1], // month
            "from_year": splitFrom[0], // year
            "is_present": isPresent,
            "professionaluser_entry_id": id,
            "search_id": selectedOrganisation?._id == isEdit?.id ? userEntryId : selectedOrganisation?._id,
            "title": associationText,
            "to_date": toDate,
            "to_month": splitTo[1], // month
            "to_year": splitTo[0],  // year
            "user_entry": selectedOrganisation?._source?.name,
            "user_entry_id": userId
        }

        if (associationText == "") {
            this.setState({
              assocationError:
                "This field is required",
              });
          }
          if (fromDate == "") {
            this.setState({
              startError:
                "This field is required",
              });
          }
          if (toDate == "" && !isPresent) {
            this.setState({
              endError:
                "This field is required",
              });
          }
          if (Object.keys(selectedOrganisation).length == 0) {
            this.setState({
              assocationWithError:
                "This field is required",
              });
          }
		
        if (isPresent) {
            datanew['to_date'] = '';
            // data['user_entry_id'] = '';
            delete datanew['to_month'];
            delete datanew['to_year'];
        }
		
        if (!isPresent) {
            //delete datanew['is_present']
        }

        let url = `/api/professionalworkhistory/${isEdit?.id}/`;
        try {
            let response = await callApi(url, 'PUT', datanew);
            if (response && response?.code !== 500) {
				let message = response?.message;
				if(message != "undefined" || message != undefined){
					toast(`${message}`, {
					  autoClose: 3500,
					  className: "black-background",
					  bodyClassName: "red-hunt",
					  progressClassName: "cc",
					});
				}
                this.resetStates(true)
            }
        } catch (err) {

        }
    }

    deleteBackgroundData = async (id) => {
        try {
            let url = `/api/professionalworkhistory/${id}/`;
            let response = await callApi(url, 'DELETE');
            if (response?.code !== 500 || response?.code !== 400) {
				let message = response?.message;
				if(message != "undefined" || message != undefined){
					toast(`${message}`, {
					  autoClose: 3500,
					  className: "black-background",
					  bodyClassName: "red-hunt",
					  progressClassName: "cc",
					});
				}
                this.listingDataItems();
            }
        } catch (err) {
            console.log({ err });
        }
    }


    fetchEditEduForm = async ({ isEdu, id }) => {
        let lable_type = 'professional_assosiation';

        try {
            let url = `/form/${lable_type}/${id}/`
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {
                const doc = new DOMParser().parseFromString(response?.data, "text/html");
                if (doc) {
                    let elements = doc.querySelectorAll('input[type="hidden"]');
                    
                    if (elements?.length) {
                        elements.forEach(el => {
                            if (el?.type == "hidden") {
                                let elements_items = el.attributes['1'].nodeValue;
                                if (elements_items.includes('user_entry_id')) {
                                    let filteredElements = elements_items.split('=');
                                    if (filteredElements && Array.isArray(filteredElements) && filteredElements?.length) {
                                        this.setState({
                                            userEntryId: parseInt(filteredElements[1].replace(/['"]+/g, ''))
                                        })
                                    }
                                }
                            }
                        })
                    }
                }

            }
        } catch (err) {

        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const {
            searchListing,
            businessListing,
            associationItems,
            isOpenAssosiation,
            associationItems: {
                isEdit
            }
        } = this.state;
        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic title="Associations" containerClass="bg-transparent type-secondary" bodyClass="text-secondary" isOpen={false}>
                    <ul className="list-unstyled">
                        {businessListing &&
                            Array.isArray(businessListing) &&
                            businessListing?.length ?
                            businessListing.map((assosiation_list, assosiation_index) => (
                                <li key={assosiation_index} className="mb-3 py-2 border-bottom border-secondary">
                                    <div className="d-flex flex-nowrap mx-n2">
                                        <div className="px-2 mt-1 mb-2">
                                            <img
                                                src={require('../../assets/images/icons/placeholder-img.png')}
                                                alt=""
                                                width={50}
                                                height={50}
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'center center'
                                                }} />
                                        </div>
                                        <div className="d-flex flex-fill px-2 mx-n1">
                                            <div className="text-secondary-dark px-1">
                                                <div className="font-weight-bold fs-14 ff-base lh-1-2 mb-1">
                                                    <span>{assosiation_list?.title}</span>
                                                    <span>&nbsp;at&nbsp;</span>
                                                    <span>{assosiation_list?.company}</span>
                                                </div>
                                                <div className="fs-13">
                                                    {`${assosiation_list?.from_date_label} - ${assosiation_list?.is_present ? 'Present' : assosiation_list?.to_date_label}`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2 col-auto ml-auto">
                                            <div className="interactive">
                                                <div className="interactive-appear">
                                                    <div className="text-nowrap" hidden={!this.checkIfLoggedIn()}>

                                                        <EditBtn color="gold"
                                                            onClick={() => {
                                                                this.setState({
                                                                    isOpenAssosiation: true,
                                                                    associationItems: {
                                                                        selectedOrganisation: {
                                                                            _id: assosiation_list?.id,
                                                                            _source: {
                                                                                name: assosiation_list?.company
                                                                            }
                                                                        },
                                                                        associationText: assosiation_list?.title,
                                                                        fromDate: moment(`${assosiation_list?.from_year}-${assosiation_list?.from_month}`, 'YYYY-MM').format('YYYY-MM-DD'),
                                                                        toDate: moment(`${assosiation_list?.to_year}-${assosiation_list?.to_month}`, 'YYYY-MM').format('YYYY-MM-DD'),
                                                                        isPresent: assosiation_list?.is_present,
                                                                        isEdit: assosiation_list
                                                                    }
                                                                }, () => {
                                                                    this.fetchEditEduForm({ isEdu: true, id: assosiation_list?.id });
                                                                });
                                                            }}
                                                        />

                                                        <DeleteBtn
                                                            color="gold"
                                                            onClick={() => {
                                                                this.deleteBackgroundData(assosiation_list?.id)
                                                                // this.props.toggleConfirmModal({
                                                                //     isTextDelete: true,
                                                                //     uniqueKey: 'delete-background',
                                                                //     id: assosiation_list?.id
                                                                // })
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                            : null
                        }
                    </ul>

                    <div>
                        <Button
                            color="link"
                            className="text-secondary-dark ff-alt fs-13 font-weight-normal p-0 hover-blue _not-icon"
                            onClick={() => {
                                if(!this.checkIfLoggedIn()){
                                    this.props.forceToggleLoginModal();
                                    return false;
                                }
                                this.resetStates()}}>
                            <span className="icon-add-new"></span>
                            {'add new association'}
                        </Button>

                        {/* Add Association */}
                        <Collapse isOpen={isOpenAssosiation}>
                            <div className="shadow bg-white p-3 mt-2">
                                <Form>
                                    <h5 className="text-secondary-dark fs-30">
                                        {`${isEdit ? 'Edit' : 'Add'} Association`}
                                    </h5>
                                    <div className="text-dark">
                                        <div className="mb-2 position-relative">
                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">Associated with</Label>
                                            <Input
                                                bsSize="sm"
                                                type="text"
                                                name="organization_name"
                                                value={associationItems?.selectedOrganisation?._source?.name ?
                                                    associationItems?.selectedOrganisation?._source?.name :
                                                    this.state.searchText}
                                                onChange={(e) => {
                                                    associationItems['selectedOrganisation'] = {};
                                                    this.setState({ searchText: e.target.value, associationItems, assocationWithError: ""}, () => {
                                                        this.textOnSearch();
                                                    });
                                                }}
                                                placeholder="Type/Search Organization"
                                            />
											<span style={{color:'red'}}>{this.state.assocationWithError}</span>

                                            {/* Instant Search Results */}
                                            <ul
                                                className="list-unstyled text-dark instant-results bg-white w-100 shadow"
                                                hidden={this.state.searchText == '' ? true : false}
                                                style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                {searchListing && Array.isArray(searchListing?.results) && searchListing?.results?.length ?
                                                    searchListing?.results.map((data, listing_index) => (
                                                        <li
                                                            key={listing_index}
                                                            className="result-item"
                                                            role="button"
                                                            onClick={() => {
                                                                associationItems['selectedOrganisation'] = data;
                                                                this.setState({ searchText: '', associationItems });
                                                            }}>
                                                            <div className="result-link fs-14">
                                                                <div className="d-flex">
                                                                    <div className="mr-2">
                                                                        <img
                                                                            src={require('../../assets/images/icons/placeholder-img-alt.jpg')}
                                                                            width={40}
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <span>
                                                                            {data?._source?.name}
                                                                        </span>
                                                                        <div>
                                                                            <img src={require('../../assets/images/icons/norating-alt.png')} alt="" />
                                                                        </div>
                                                                        <div>
                                                                            <span>{data?._source?.category}</span>
                                                                        </div>
                                                                        <div className="text-muted">
                                                                            {Object.values(data?._source?.address).map((add, address_index) => (
                                                                                <p key={address_index}>
                                                                                    {`${add?.address1 ? `${add?.address1},` : ''} ${add?.address2 ? `${add?.address2},` : ''} ${add?.city ? `${add?.city},` : ''} ${add?.state ? `${add?.state},` : ''} ${add?.country ? `${add?.country},` : ''} ${add?.zipcode ? `${add?.zipcode},` : ''}`}
                                                                                </p>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                    :
                                                    null
                                                }
                                            </ul>
                                        </div>

                                        <div className="mb-2">
                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">Association</Label>
                                            <Input
                                                bsSize="sm"
                                                type="text"
                                                name="association"
                                                value={associationItems['associationText']}
                                                onChange={(e) => {
                                                    associationItems['associationText'] = e.target.value;
                                                    this.setState({ associationItems,assocationError:"" });
                                                }}
                                                placeholder="Enter Association"
                                            />
											<span style={{color:'red'}}>{this.state.assocationError}</span>
                                        </div>
                                        <div className="mb-2">
                                            <Label size="sm" className="font-weight-bold mb-0 ff-base">Start Date</Label>
                                            <Input
                                                bsSize="sm"
                                                type="month"
                                                name="courseStartDate"
                                                max={moment(associationItems['toDate'], 'YYYY-MM-DD').format('YYYY-MM')}
                                                value={associationItems['fromDate'] !== '' ? moment(associationItems['fromDate'], 'YYYY-MM-DD').format('YYYY-MM') : ''}
                                                onChange={(e) => {
                                                    associationItems['fromDate'] = moment(e.target.value, 'YYYY-MM').format('YYYY-MM');
                                                    this.setState({ associationItems,startError:"" })
                                                }}
                                                placeholder="Enter start date"
                                            />
											<span style={{color:'red'}}>{this.state.startError}</span>
                                        </div>
                                        {!associationItems['isPresent'] && (
                                            <div className="mb-2">
                                                <Label size="sm" className="font-weight-bold mb-0 ff-base">End Date</Label>
                                                <Input
                                                    bsSize="sm"
                                                    type="month"
                                                    name="courseEndDate"
                                                    min={moment(associationItems['fromDate'], 'YYYY-MM-DD').format('YYYY-MM')}
                                                    max={moment().format('YYYY-MM')}
                                                    value={associationItems['toDate'] !== '' ? moment(associationItems['toDate'], 'YYYY-MM-DD').format('YYYY-MM') : ''}
                                                    onChange={(e) => {
                                                        associationItems['toDate'] = moment(e.target.value, 'YYYY-MM').format('YYYY-MM');
                                                        this.setState({ associationItems, endError :"" })
                                                    }}
                                                    placeholder="Enter end date"
                                                />
												<span style={{color:'red'}}>{this.state.endError}</span>
                                            </div>
                                        )}
                                        <div className="mb-2">
                                            <FormGroup check>
                                                <Label className="font-weight-bold ff-base" check>
                                                    <Input 
                                                        type="checkbox"
                                                        checked={this.state.associationItems.isPresent}
                                                        onClick={() => {
                                                            associationItems['isPresent'] = !associationItems['isPresent'];
                                                            this.setState({ associationItems });
                                                        }}
                                                    />
                                                    {'Currently here'}
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <div className="text-right">
                                            <Button
                                                onClick={() => {
                                                    if (!associationItems?.isEdit) {
                                                        this.handleSubmitEdu();
                                                    } else {
                                                        this.updateEdu();
                                                    }
                                                }}
                                                size="sm" color="primary mr-2">
                                                {'Submit'}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    this.resetStates();
                                                }}
                                                size="sm" color="primary ml-0">
                                                {'Cancel'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                <hr />
                            </div>
                        </Collapse>
                    </div>
                </CollapseBasic>
            </div>
        )
    }
}
