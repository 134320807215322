/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class About extends Component {
    render() {
        return (
            <div>
                <AppHeader isEnableCover={false} />
                <div className="wk-static-wrapper">
                  <Container>
                      <div className="wk-static-sub-head">
                         <h1>About WikiReviews</h1>
                      </div>
                      <div className="wk-static-content-body">
                         <Row>
                            <Col md={4}>
                                    <div className="wk-static-sidebar-left">
                                        <h2>Menu</h2>
                                        <ul>
                                            <li>
                                                <Link to="/terms">Terms & Conditions</Link>
                                            </li>
                                            <li>
                                                <Link to="/policies">Privacy Policy</Link>
                                            </li>
                                            <li>
                                                <Link to="/faq">FAQ</Link>
                                            </li>
                                            <li>
                                                <Link to="/guidelines">Guidelines</Link>
                                            </li>
                                        </ul>
                                    </div>                            
                            </Col>
                            <Col md={8}>
                               <div className="wk-static-content-right">
                                  <h2>About Us</h2>
                                  <p className="fs-14">WikiReviews, Inc is based in Irvine, Calfiornia and is a privately owned company not funded by venture capitalists but investors who believe that the “review space” is broken and needs fixing. Sunil Wagle is the founder and he created WikiReviews after himself experiencing issues with reviews for his e-commerce business. While exploring the reviews industry, it was brought to his attention that the “wiki” model of business in the reviews space could be a good idea. As Sunil did more and more research on this concept, he quickly understood that the power of the community cannot be beat. Moreover, he identified a plethora of big problems in the review space including fake reviews, “paid for” reviews and even the inability of business owners to do anything about fake or not relevant reviews.
                                 </p>
                                 <p className="fs-14">He also spent time researching issues with other “Wiki” sites including that most contributors are younger males who once they get married, they stopped contributing. Also, he learned that contributors had to understand HTML programming, otherwise they could not contribute. Finally, he also learned that whenever there is a lot of something…like things to review, or how to articles or an encyclopedia or even reporting accidents and police on our roadways…the best and truly the only successful way of operating anything to do this is by using a “wiki” model, where the community contributes, edits and patrols the content on the site. Only with the power of the community, can organizations focused on these things succeed.
                                 </p>
                                 <p className="fs-14">Sunil was convinced that he had to make WikiReviews user friendly to appeal to a much broader audience of people who can contribute and edit and patrol the content. This entailed a lot of work, time and money in determining user friendly ways for anyone to contribute to WikiReviews.
                                 </p>
                                <p className="fs-14">Also, he developed solutions to every single issue he found with today’s existing review sites which <b>sets our site apart</b> from all other review sites and these include the following:
                                 </p>
                                 <ul className="list1">
                                    <li>
                                        
                                        Authenticating users to prevent fake accounts.
                                        
                                    </li>
                                    <li>
                                        
                                        Only allowing movie reviews to be written after the date of release.
                                        
                                    </li>
                                    <li>
                                        
                                        Offering Detailed Star Ratings so Users can get much deeper insight.
                                        
                                    </li>
                                    <li>
                                        
                                        Making it easy to create reviews by offering speech to text dictation reviews.
                                        
                                    </li>
                                    <li>
                                        
                                        Allowing reviewers to create 5 minute long video reviews….great for products.
                                        
                                    </li>
                                    <li>
                                        
                                        Allowing reviewers to review individuals at businesses where the person’s review weighs heavily over the actual business review…such as hairdressers, pet groomers, lawyers, salon technicians and even police offers.
                                        
                                    </li>
                                    <li>
                                        
                                        We will be the first review site to allow actor reviews…so you can even see how actors rate in specific genres!
                                        
                                    </li>
                                    <li>
                                        
                                        We allow businesses to dispute fake and not relevant reviews. So when a business gets a bad review because the reviewer got a speeding ticket on the way to the business, those can get removed.
                                        
                                    </li>
                                    <li>
                                        
                                        Our site is social so you can be kept updated on all the things your friends review and what they recommend.
                                        
                                    </li>
                                    <li>
                                        
                                        Use our UPC product barcode scanner to easily find and create reviews.
                                        
                                    </li>
                                    <li>
                                        
                                        Use our Buyers Guide to learn the vocabulary associated with things such as buying a new camera and learning about f-stops, aperture settings, etc and even things like different types of pastas and food. Also, we have a How to Buy Section that anyone can read so they have proper guidance before making a new purchase.
                                        
                                    </li>
                                    <li>
                                        
                                        Finally, we are turning reviews upside down by allowing anyone to upload their own “personal projects” to be uploaded for the community to review.
                                        
                                    </li>
                                    <li>
                                        
                                    The community help to “govern” WikiReviews through adding, patrolling, voting and improving the content on the site.
                                        
                                    </li>
                                    <li>	
                                        Allowing reviewers to mark their reviews if they contain “movie spoilers” so that people who not have seen the movie do not accidentally read those reviews.
                                    </li>
                                    <li>	
                                    We found that most people choose movies to see based upon what is playing at their “favorite” theater. So we allow users to put in their favorite theater in their profile and then we have a specific sections called “User’s Theater” that they can go to and easily find the movies and showtimes and trailers for all the movies playing at their favorite theater.
                                    </li>	
                                    </ul>
                                    <h2>HISTORY, LAUNCH & LONG TERM GOALS</h2>
                                    <p className="fs-14">WikiReviews was founded in 2010 and after many years of perfecting the soon to be largest review site on the world wide web, WikiReviews has finally launched and we couldn’t be more excited for our community to grow.
                                    </p>
                                    <p className="fs-14">WikiReviews strives to provide honest and thorough reviews about businesses, products, and movies. And soon, we hope to expand into every single type of review imaginable…from reviewing college athletic programs to boards of directors to money managers and even school principals….the categories to review are endless and we hope to one day cover as many as possible.
                                    </p>
                                    <p className="fs-14">WikiReviews was created <b>for</b> the community and <b>by</b> the community. Sign up for WikiReviews and become a part of the world’s largest (and most honest) reviews community!
                                    </p>
                               </div>
                            </Col>
                         </Row>
                      </div>
                  </Container>
                </div>
            </div>
        )
    }
}
