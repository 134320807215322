const initialState = {
    project_list: null,
    project_list_pages: null,
    project_categories: null,
    project_sub_categories: null,
    project_sub_categories_filter: null,
    categories_filter_pages: null,
    tabs_content: null,
    listing_pins: null,
};

const projects = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "PROJECT_LIST":
            state.project_list = action.payload;
            return { ...state };
            
        case "PROJECT_LIST_PAGES":
            state.project_list_pages = action.payload;
            return { ...state };
        
        case "PROJECT_CATEGORIES":
            state.project_categories = action.payload;
            return { ...state };

        case "PROJECT_SUB_CATEGORIES":
            state.project_sub_categories = action.payload;
            return { ...state };

        case "PROJECT_SUB_CATEGORIES_FILTER":
            state.project_sub_categories_filter = action.payload;
            return { ...state };
        
        case "FILTERED_PROJECT_LIST_PAGES":
            state.categories_filter_pages = action.payload;
            return { ...state };

        case "CONNECTIVITY_TABS_CONTENT":
            state.tabs_content = action.payload;
            return { ...state };

        case 'FETCH_LISTING_PINS':
            state.listing_pins = action.payload;
            return { ...state };


        default:
            return state;
    }
};

export default projects;
