/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import {  Container, Row, Col, FormGroup, Button,  Input, InputGroup, InputGroupAddon,  } from 'reactstrap';
import { add_hours_of_operations } from '../../../actions/itemDetail';
import { getReviewSuffix } from '../../../utils/utilities';
import { Link } from 'react-router-dom';


class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            about: null,
            tempIcon: null,
            editName: false,
            phoneSet: [],
            emailSet: [],
            websiteSet: [],
            paymentOptionsSet: [],
            paymentFeesSet: [],
            email: null,
            pageNo: 1,
            readMore:false,
			lawyId:0,
			languages:[],
			sepicalities:[],
			isToggleHours: false, // Website fields
            isEditHours: false,
            addHours: { day_of_week: '', end_time: "", start_time: "", entries: "", id: "", info: "", "next_day": 0 },
            isHoursValid: false,
            isToggleDeleteHours: false,
            HoursOfOperation: [],
			infoHoursOfOperation: null,
            infoHoursOfOperationIsValid: false,
			hoursofoperation: [],
            addHoursofOperation: false,
            addHOP: null,
			day_of_week: null,
            day_of_weekIsValid: false,
            start_time: null,
            start_timeIsValid: false,
            end_time: null,
            end_timeIsValid: false,
			addhourError :""
		}
    }


    componentWillReceiveProps(nextProps) {
        const { lawyerDetail,lawyerId} = nextProps;
		let { addHours } = this.state;
        if (lawyerDetail) {
			let HoursOfOperation = lawyerDetail?.professionalhoursofoperation_set;
			this.setState({
                about: lawyerDetail,
                phoneSet: lawyerDetail?.professionalphone_set,
                emailSet: lawyerDetail?.professionalemail_set,
                websiteSet: lawyerDetail?.professionalwebsite_set,
				hoursofoperation: HoursOfOperation,
                paymentOptionsSet: lawyerDetail?.professionalpaymentoptions_set,
                paymentFeesTypes: lawyerDetail?.professionalfeestypes_set,
				languages: lawyerDetail?.additional_info !== null && lawyerDetail?.additional_info?.Languages ? lawyerDetail.additional_info.Languages : [],
                sepicalities:lawyerDetail?.additional_info !== null && lawyerDetail?.additional_info?.Specialties ? lawyerDetail.additional_info.Specialties : [],
                
             }, () => {
                let { addHours } = this.state;
                let HoursOfOperation = this.state.hoursofoperation;

                if (HoursOfOperation && Array.isArray(HoursOfOperation) && HoursOfOperation.length) {

                    let lastKeyItem = HoursOfOperation[HoursOfOperation.length - 1].day_of_week;

                    if (lastKeyItem) {
                        let item = null;
                        if (lastKeyItem < 7) {
                            item = lastKeyItem + 1;
                        } else {
                            item = 1;
                        }

                        addHours['day_of_week'] = item;
                    }
                } else {
                    addHours['day_of_week'] = 1;
                }

                this.setState({ addHours });
            });
        }
		if(lawyerId){
			this.setState({
                lawyId: lawyerId,
            });
        }
    }	
	/**
    * Function to phone number format
    * 
    */

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
        
    }
	
	handleChange = async (evt, data) => {
        if (data) {
            await this.setState({
                // ...this.state,
                [evt.target.name]: evt.target.value,
                [data]: evt.target.validity.valid
            });
        }
        else {
            await this.setState({
                [evt.target.name]: evt.target.value,
            });
        }
    }
	
	handleFieldsChange = (type, data) => {
	 let {about} = this.state;	
	 if (type === 'insertHoursOfOperations') {
			let types = "lawyers";
            this.props.add_hours_of_operations(about.id, data,types);
        }
    }

    HoursOfOperation = () => {
        let { addHours, isEditHours } = this.state;
        let HoursOfOperation = this.state.hoursofoperation;
        let weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        if (HoursOfOperation && Array.isArray(HoursOfOperation) && HoursOfOperation.length > 0) {

            return HoursOfOperation.map((item, index) => {
                return (
                    <p className="fs-14 font-weight-bold">
                        {weekDays[item.day_of_week - 1]} {item.start_time ? moment(item.start_time, 'HH:mm:ss').format('hh:mm A') : `[Closed]`} - {item.end_time ? moment(item.end_time, 'HH:mm:ss').format('hh:mm A') : ''} {item.info ? `[${item.info}]` : ""}
                    </p>
                )
            }
            )
        }
    }
	
	handleChangeHours = (event) => {
        let { name, value } = event.target;
        let { addHours, isEditHours } = this.state;
        if (value[value.length - 1] === "1") {
            addHours["next_day"] = value[value.length - 1]
            this.setState({ addHours })
        }
        else {
            addHours["next_day"] = "0";
            this.setState({ addHours })
        }
        addHours[name] = value;
        this.setState({ addHours, isEditHours, addhourError:"" }, () => this.hoursValidation(addHours));
    }
    hoursValidation = (addHours) => {
        let { isHoursValid } = this.state;
        let start_time = moment(addHours.start_time, "HH:mm:ss").format("HH:mm");
        let end_time = moment(addHours.end_time, "HH:mm:ss").format("HH:mm");

        if (moment(end_time, 'HH:mm').isBefore(moment(start_time, 'HH:mm')) && addHours['next_day'] === '0') {

            this.setState({ isHoursValid: true });
        }
        else {
            if (isHoursValid !== false) {
                this.setState({ isHoursValid: false });
            }
        }


    }

    toggleEditHours = (isTrue) => {
        if (isTrue && isTrue.id) {
            let { addHours } = this.state;
            addHours['day_of_week'] = isTrue.day_of_week
            addHours['end_time'] = isTrue.end_time
            addHours['start_time'] = isTrue.start_time
            addHours['entries'] = isTrue.entries
            addHours['id'] = isTrue.id
            addHours['info'] = isTrue.info
            this.setState({ addHours, isHoursValid: false }, () => this.hoursValidation(addHours));
        }
        this.setState({ isEditHours: isTrue && isTrue.id ? isTrue : false, isToggleHours: !this.state.isToggleHours })
    }

    setInitialHours = (startTime, endTime) => {
        this.toggleEditHours();
        this.setState({ addHours: { day_of_week: 1, end_time: endTime, start_time: startTime, entries: "", id: "", info: "" }, isHoursValid: false });
    }

    handleSubmitHours = async () => {
        let { about, addHours,lawyId, addHours: { day_of_week, end_time, start_time, info } } = this.state;
        if(lawyId && lawyId != 0){
        /*let data = {
            day_of_week,
            end_time: moment(end_time, "HH:mm:ss").format("HH:mm"),
            start_time: moment(start_time, "HH:mm:ss").format("HH:mm"),
            info,
            entries_id: about.id,
            next_day: parseInt(addHours['next_day'])
        }*/
		
		if(start_time == end_time){
			
			this.setState({
            addhourError: "Start Time and End Time can't be same"
			})
			
		}else{
		
		let data = {
            day_of_week,
            end_time: moment(end_time, "HH:mm:ss").format("HH:mm"),
            start_time: moment(start_time, "HH:mm:ss").format("HH:mm"),
            info,
            id: about.id,
            type: "professionalhoursofoperation_form"
        }
        let brunchStart = '09:59';
        let brunchEnd = '11:31';

        let lunchStart = '11:59';
        let lunchEnd = '15:01'; // 3:00 pm

        let dinnerStart = '17:59'; // 6:00 pm
        let dinnerEnd = '22:01';  // 10:00 pm



        if (
            moment(data.start_time, 'HH:mm').isBetween(moment(brunchStart, 'HH:mm'), moment(brunchEnd, 'HH:mm')) && moment(data.end_time, 'HH:mm').isBetween(moment(brunchStart, 'HH:mm'), moment(brunchEnd, 'HH:mm'))
        ) {
            this.setState({ isEditHours: false });

            data.info = "Brunch Time";

            await this.handleFieldsChange('insertHoursOfOperations', data);
            this.toggleEditHours();
            this.setInitialHours(data.start_time, data.end_time);
        }


        else if (moment(data.start_time, 'HH:mm').isBetween(moment(lunchStart, 'HH:mm'), moment(lunchEnd, 'HH:mm')) && moment(data.end_time, 'HH:mm').isBetween(moment(lunchStart, 'HH:mm'), moment(lunchEnd, 'HH:mm'))) {
            this.setState({ isEditHours: false });

            data.info = "Lunch Time";
            await this.handleFieldsChange('insertHoursOfOperations', data);
            this.toggleEditHours();
            this.setInitialHours(data.start_time, data.end_time);
        }

        else if (moment(data.start_time, 'HH:mm').isBetween(moment(dinnerStart, 'HH:mm'), moment(dinnerEnd, 'HH:mm')) && moment(data.end_time, 'HH:mm').isBetween(moment(dinnerStart, 'HH:mm'), moment(dinnerEnd, 'HH:mm'))) {
            this.setState({ isEditHours: false });

            data.info = "Dinner Time";
            await this.handleFieldsChange('insertHoursOfOperations', data);
            this.toggleEditHours();
            this.setInitialHours(data.start_time, data.end_time);
        }

        else {

            // data.info = "";
            await this.handleFieldsChange('insertHoursOfOperations', data);
            this.toggleEditHours();
            this.setInitialHours(data.start_time, data.end_time);
        }

        this.setState({
            addHoursofOperation: false
        })
		
		}
		}
        
    }

   
    render() {
        const {
            about,
            phoneSet,
            tempIcon,
            emailSet,
			websiteSet,
			languages,
			sepicalities,
			paymentOptionsSet,
            paymentFeesTypes,
			isEditHours,
            addHours,
            isHoursValid,
        } = this.state;
        
		let avg_rating = about && about?.total_rating && about?.total_rating.avg_rating[0] ? about.total_rating.avg_rating[0] : 'norating.png';
        return (
            <div className="text-center">
                                <Container className="position-relative">
                                    {/* <div className={'text-center position-relative about-view ' + `${!this.state.toggleMoreLessInfo ? '_mini' : ''}`}> */}
                                    <div className={!this.state.toggleMoreLessInfo ? 'text-center position-relative about-view _mini ' : 'text-center position-relative about-view'}>
                                        <div className="wr-movie-top-about d-flex flex-column">
                                            <div className="wr-movie-top-about-body">
                                                <Row>
                                                    <Col>
                                                        <div className="wr-movie-product-info-top d-flex flex-wrap">
                                                            <div className="wr-movie-product-info-top-left">
                                                                <img
																src={`${about && about?.professional_profileimage && about?.professional_profileimage.length > 0 && about?.professional_profileimage[0] && about?.professional_profileimage[0].url}`}
																className="img-fluid"
																onError={(error) =>
																	(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/lawyer-neutral-02-472x446.jpg`)
																}
																alt={`${about && about?.professional_profileimage && about?.professional_profileimage.length > 0 && about?.professional_profileimage[0] && about.professional_profileimage[0].caption ? about.professional_profileimage[0].caption : 'no-image'}`}
															/>
                                                            
                                                            </div>
                                                            <div className="wr-movie-product-info-top-right">
                                                                <div className="wr-category-heading">
                                                                    <h4>{about && about.firstname} {about && about.lastname}</h4>
                                                                    <div hidden>
                                                                        <InputGroup size="sm">
                                                                            <Input type="text" placeholder="Enter Name" />
                                                                            <InputGroupAddon addonType="append">
                                                                                <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                    </div>
                                                                    <div className="ff-alt fs-14 text-dark">
                                                                        Attorney at <span className="font-weight-bold">{about && about.working_at ? about.working_at.company : "" }</span>
                                                                    </div>
                                                                    <p className="fs-14"><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
																		className="img-fluid"
																		onError={(error) =>
																			(error.target.src = `../../../assets/images/norating.png`)
																		}
																		alt={`${about && about.total_rating && about.total_rating.avg_rating[1] ? about.total_rating.avg_rating[1] : 'no-rating'}`}

																	/> {(about && about.total_rating && about.total_rating.total_review) ? about.total_rating.total_review : 0} {getReviewSuffix(about?.total_rating?.total_review)}</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="wr-movie-product-info-bottom wr-business-product-info-bottom">
                                                            <ul className="m-0 p-0">
                                                                <li className="d-flex mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 business-icon _map"></span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <span className="fs-14 font-weight-bold">
                                                                            {about && about.address && about.address.length > 0 && about.address[0].name ? about.address[0].name : ""} {about && about.address && about.address.length > 0 && about.address[0].address1 ? about.address[0].address1 : ""}
                                                                            {about && about.address && about.address.length > 0 && (about.address[0].name || about.address[0].address1) ? <br /> : ''}
                                                                            {about && about.address && about.address.length > 0 && about.address[0].city ? about.address[0].city + "," : ""} {about && about.address && about.address.length > 0 && about.address[0].state ? about.address[0].state + "," : ""} {about && about.address && about.address.length > 0 && about.address[0].zipcode ? about.address[0].zipcode + "," : ""}
                                                                            {about && about.address && about.address.length > 0 && (about.address[0].city || about.address[0].state || about.address[0].zipcode) ? ' ' : ' '}
                                                                            <span className="text-uppercase">
                                                                                {about && about.address && about.address.length > 0 && about.address[0].country ? about.address[0].country : ""}
                                                                            </span>
                                                                           
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 business-icon _phone"></span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        {phoneSet && phoneSet.length > 0 ?
                                                                            <>{phoneSet.map((phoneData,index) => {
                                                                                return (
                                                                                <span className="fs-14" key={index}> <span className="font-weight-bold">
                                                                                        {phoneData ? phoneData.label === "Mob"? phoneData.phone :  this.formatPhoneNumber(phoneData.phone) : ""}</span>{" "}<span> {phoneData ? <>({phoneData.label})</> : ""}</span></span>
                                                                                )
                                                                            })}</> : ""
                                                                        }
                                                                        <div hidden>
                                                                            <InputGroup size="sm">
                                                                                <Input type="text" placeholder="Enter Valid Phone" />
                                                                                <InputGroupAddon addonType="append">
                                                                                    <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                                </InputGroupAddon>
                                                                            </InputGroup>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 business-icon _email"></span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        {emailSet && emailSet.length > 0 ?
                                                                            <>{emailSet.map((Addemail,index) => {
                                                                                return (<span className="fs-14 font-weight-bold" key={index}>
                                                                                        {Addemail ? Addemail.email:""} {""} <span>{Addemail ? <>({Addemail.email_type})</> : ""}</span>
                                                                                      
                                                                                    </span>)
                                                                                
                                                                            })}</> : ""
                                                                        }
                                                                        <div hidden>
                                                                            <InputGroup size="sm">
                                                                                <Input type="text" placeholder="Enter Valid Phone" />
                                                                                <InputGroupAddon addonType="append">
                                                                                    <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                                </InputGroupAddon>
                                                                            </InputGroup>
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <span className="fs-14 font-weight-bold" role="button" hidden>Click to add</span>
                                                                        <div hidden>
                                                                            <FormGroup>
                                                                                <Input className="primary" bsSize="sm" type="text" />
                                                                            </FormGroup>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <ul className="mt-2 p-0">
                                                                <li className="d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14">Website:</span>
                                                                    </div>
                                                                    <div className="col px-2">
                                                                        <span className="fs-14 font-weight-bold">
																			{websiteSet &&  websiteSet.length > 0 ?
																				<>{websiteSet.map(site => {
																					if (site.website_type === 'Main') {
																					return <Link className="wb-break-word" target="_blank" to={
                                                                                            {
                                                                                                pathname: "/abouttransition/",
                                                                                                search: `?page_url=${site.website}`
                                                                                            }
                                                                                        } >
                                                                                            {site.website}
                                                                                        </Link>
																					}
																				})}</> : ""
																			}
																			 </span>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14">Specialities:</span>
                                                                    </div>
                                                                    <div className="col px-2">
                                                                        {sepicalities && sepicalities.length > 0 ?
                                                                            <>{sepicalities.map((specialize,index) => {
                                                                                return (<span className="fs-14 font-weight-bold" key={index}>
                                                                                        {specialize.value},
                                                                                    </span>)
                                                                                
                                                                            })}</> : ""
                                                                        }
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    {this.state.toggleMoreLessInfo ?
                                                        <Col md={8}>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="wr-category-heading">
                                                                    {about && about.total_rating && Object.keys(about.total_rating).length > 0 ?

																	about && Object.keys(about.total_rating).map(rating => {
																		if (rating !== "total_review" && rating !== "avg_rating") {
																			return <p className="fs-14"><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${about.total_rating[rating][0]}`} className="img-fluid" alt={rating || ""} /> {rating}</p>
																		}
																	}) : ''}
                                                                        <div className="wr-movie-product-info-bottom">
                                                                            <ul className="m-0 p-0">
                                                                            <li className="d-flex flex-wrap mb-2 mx-n2">
                                                                                    <div className="px-2">
                                                                                        <span className="fs-14">Payment Methods:</span>
                                                                                    </div>
                                                                                    <div className="col px-2">
																						{paymentOptionsSet && paymentOptionsSet.length > 0 ?
																						<>{paymentOptionsSet.map((paymentMethod,index) => {
																							return (<span className="fs-14 font-weight-bold wb-break-word" key={index}>
																									{paymentMethod ?paymentMethod.paymentoptions:""},
																								</span>)
																							
																						})}</> : ""
																					   }
                                                                                    </div>
                                                                                </li>
                                                                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                                                                    <div className="px-2">
                                                                                        <span className="fs-14">Fees:</span>
                                                                                    </div>
                                                                                    <div className="col px-2">
																					{paymentFeesTypes && paymentFeesTypes.length > 0 ?
																					<>{paymentFeesTypes.map((FeesTypes,index) => {
																						return (<span className="fs-14 font-weight-bold wb-break-word" key={index}>
																								({FeesTypes?FeesTypes.fee_value: ""})
																							</span>)
																						
																					})}</> : ""
																				   }
                                                                                   </div>
                                                                                </li>
                                                                               
                                                                                <li className="d-flex flex-wrap mb-2 mx-n2">
                                                                                    <div className="px-2">
                                                                                        <span className="fs-14">Language Spoken:</span>
                                                                                    </div>
                                                                                    <div className="col px-2">
																					{languages && languages.length > 0 ?
																							<>{languages.map((language,index) => {
																								return (
																								<span className="fs-14 font-weight-bold wb-break-word" key={index}>
																									{language.value},
																								</span>
																									
																								)
																							})}</> : ""
																						}
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="wr-category-heading">
                                                            <p className="fs-14">Hours of Operation:</p>
                                                            {this.HoursOfOperation()}
                                                            <div className="d-flex justify-content-end wr-business-new-add">
                                                                <span className="fs-14 font-weight-bold text-dark"
                                                                    role="button"
                                                                    onClick={() => {
                                                                        let { addHours } = this.state;
                                                                        let HoursOfOperation = this.state.hoursofoperation;

                                                                        let getItem = HoursOfOperation[HoursOfOperation.length - 1];

                                                                        if (HoursOfOperation && Array.isArray(HoursOfOperation) && HoursOfOperation.length) {

                                                                            let lastKeyItem = HoursOfOperation[HoursOfOperation.length - 1].day_of_week;


                                                                            if (lastKeyItem) {
                                                                                let item = null;
                                                                                if (lastKeyItem < 7) {
                                                                                    item = lastKeyItem + 1;
                                                                                } else {
                                                                                    item = 1;
                                                                                }

                                                                                addHours['day_of_week'] = item;
                                                                                addHours['start_time'] = getItem?.start_time || '';
                                                                                addHours['end_time'] = getItem?.end_time || '';
                                                                            }
                                                                        } else {
                                                                            addHours['day_of_week'] = 1;
                                                                            addHours['start_time'] = getItem?.start_time || '';
                                                                            addHours['end_time'] = getItem?.end_time || '';
                                                                        }

                                                                        this.setState({
                                                                            addHours,
                                                                            addHoursofOperation: true
                                                                        })
                                                                    }}
                                                                ><FontAwesomeIcon icon="plus" /> Add another</span>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.addHoursofOperation ? <div className="text-left mb-2 p-2 shadow m-2" >
                                                                <h5 className="text-secondary-dark fs-30">Add Hours of Operation</h5>
                                                                <hr />
                                                                <div>
                                                                    <AvForm>
                                                                        <Row xs={1} className="form-row">

                                                                            <Col>
                                                                                <FormGroup className="mb-2">
                                                                                    <AvField className="primary" bsSize="sm" type="select"
                                                                                        name="day_of_week"
                                                                                        value={isEditHours ? isEditHours.day_of_week : addHours.day_of_week}
                                                                                        onChange={this.handleChangeHours}>
                                                                                        <option value="">Select Day</option>
                                                                                        <option value="1">Monday</option>
                                                                                        <option value="2" >Tuesday</option>
                                                                                        <option value="3" >Wednesday</option>
                                                                                        <option value="4">Thursday</option>
                                                                                        <option value="5">Friday</option>
                                                                                        <option value="6">Saturday</option>
                                                                                        <option value="7">Sunday</option>
                                                                                    </AvField>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col>
                                                                                <FormGroup className="mb-2">
                                                                                    <AvField className="primary" bsSize="sm" type="select"
                                                                                        onChange={this.handleChangeHours}
                                                                                        name="start_time"
                                                                                        value={
                                                                                            isEditHours ? moment(isEditHours.start_time, 'HH:mm:ss').format('HH:mm') : addHours.start_time !== "Closed" ?
                                                                                                moment(addHours.start_time, 'HH:mm:ss').format('HH:mm') :
                                                                                                addHours.start_time
                                                                                        }
                                                                                    >
                                                                                        <option value="">Select Opening Hours</option>
                                                                                        {/* <option value="Closed">Closed</option> */}
                                                                                        <option value="00:00">12:00 am (midnight)</option>
                                                                                        <option value="00:30">12:30 am</option>
                                                                                        <option value="01:00">1:00 am</option>
                                                                                        <option value="01:30">1:30 am</option>
                                                                                        <option value="02:00">2:00 am</option>
                                                                                        <option value="02:30">2:30 am</option>
                                                                                        <option value="03:00">3:00 am</option>
                                                                                        <option value="03:30">3:30 am</option>
                                                                                        <option value="04:00">4:00 am</option>
                                                                                        <option value="04:30">4:30 am</option>
                                                                                        <option value="05:00">5:00 am</option>
                                                                                        <option value="05:30">5:30 am</option>
                                                                                        <option value="06:00">6:00 am</option>
                                                                                        <option value="06:30">6:30 am</option>
                                                                                        <option value="07:00">7:00 am</option>
                                                                                        <option value="07:30">7:30 am</option>
                                                                                        <option value="08:00">8:00 am</option>
                                                                                        <option value="08:30">8:30 am</option>
                                                                                        <option value="09:00">9:00 am</option>
                                                                                        <option value="09:30">9:30 am</option>
                                                                                        <option value="10:00">10:00 am</option>
                                                                                        <option value="10:30">10:30 am</option>
                                                                                        <option value="11:00">11:00 am</option>
                                                                                        <option value="11:30">11:30 am</option>
                                                                                        <option value="12:00">12:00 pm</option>
                                                                                        <option value="12:30">12:30 pm</option>
                                                                                        <option value="13:00">1:00 pm</option>
                                                                                        <option value="13:30">1:30 pm</option>
                                                                                        <option value="14:00">2:00 pm</option>
                                                                                        <option value="14:30">2:30 pm</option>
                                                                                        <option value="15:00">3:00 pm</option>
                                                                                        <option value="15:30">3:30 pm</option>
                                                                                        <option value="16:00">4:00 pm</option>
                                                                                        <option value="16:30">4:30 pm</option>
                                                                                        <option value="17:00">5:00 pm</option>
                                                                                        <option value="17:30">5:30 pm</option>
                                                                                        <option value="18:00">6:00 pm</option>
                                                                                        <option value="18:30">6:30 pm</option>
                                                                                        <option value="19:00">7:00 pm</option>
                                                                                        <option value="19:30">7:30 pm</option>
                                                                                        <option value="20:00">8:00 pm</option>
                                                                                        <option value="20:30">8:30 pm</option>
                                                                                        <option value="21:00">9:00 pm</option>
                                                                                        <option value="21:30">9:30 pm</option>
                                                                                        <option value="22:00">10:00 pm</option>
                                                                                        <option value="22:30">10:30 pm</option>
                                                                                        <option value="23:00">11:00 pm</option>
                                                                                        <option value="23:30">11:30 pm</option>
                                                                                    </AvField>
																					<span style={{ color: 'red' }}>{this.state.addhourError}</span>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col>
                                                                                <FormGroup className="mb-2">
                                                                                    <AvField className="primary" bsSize="sm" type="select"
                                                                                        onChange={this.handleChangeHours}
                                                                                        name="end_time"
                                                                                        value={isEditHours ?
                                                                                            moment(isEditHours.end_time, 'HH:mm:ss').format('HH:mm') :
                                                                                            moment(addHours.end_time, 'HH:mm:ss').format('HH:mm')}
                                                                                    >
                                                                                        <option value="">Select Closing Hours</option>
                                                                                        <option value="00:30">12:30 am</option>
                                                                                        <option value="01:00">1:00 am</option>
                                                                                        <option value="01:30">1:30 am</option>
                                                                                        <option value="02:00">2:00 am</option>
                                                                                        <option value="02:30">2:30 am</option>
                                                                                        <option value="03:00">3:00 am</option>
                                                                                        <option value="03:30">3:30 am</option>
                                                                                        <option value="04:00">4:00 am</option>
                                                                                        <option value="04:30">4:30 am</option>
                                                                                        <option value="05:00">5:00 am</option>
                                                                                        <option value="05:30">5:30 am</option>
                                                                                        <option value="06:00">6:00 am</option>
                                                                                        <option value="06:30">6:30 am</option>
                                                                                        <option value="07:00">7:00 am</option>
                                                                                        <option value="07:30">7:30 am</option>
                                                                                        <option value="08:00">8:00 am</option>
                                                                                        <option value="08:30">8:30 am</option>
                                                                                        <option value="09:00">9:00 am</option>
                                                                                        <option value="09:30">9:30 am</option>
                                                                                        <option value="10:00">10:00 am</option>
                                                                                        <option value="10:30">10:30 am</option>
                                                                                        <option value="11:00">11:00 am</option>
                                                                                        <option value="11:30">11:30 am</option>
                                                                                        <option value="12:00">12:00 pm (noon)</option>
                                                                                        <option value="12:30">12:30 pm</option>
                                                                                        <option value="13:00">1:00 pm</option>
                                                                                        <option value="13:30">1:30 pm</option>
                                                                                        <option value="14:00">2:00 pm</option>
                                                                                        <option value="14:30">2:30 pm</option>
                                                                                        <option value="15:00">3:00 pm</option>
                                                                                        <option value="15:30">3:30 pm</option>
                                                                                        <option value="16:00">4:00 pm</option>
                                                                                        <option value="16:30">4:30 pm</option>
                                                                                        <option value="17:00">5:00 pm</option>
                                                                                        <option value="17:30">5:30 pm</option>
                                                                                        <option value="18:00">6:00 pm</option>
                                                                                        <option value="18:30">6:30 pm</option>
                                                                                        <option value="19:00">7:00 pm</option>
                                                                                        <option value="19:30">7:30 pm</option>
                                                                                        <option value="20:00">8:00 pm</option>
                                                                                        <option value="20:30">8:30 pm</option>
                                                                                        <option value="21:00">9:00 pm</option>
                                                                                        <option value="21:30">9:30 pm</option>
                                                                                        <option value="22:00">10:00 pm</option>
                                                                                        <option value="22:30">10:30 pm</option>
                                                                                        <option value="23:00">11:00 pm</option>
                                                                                        <option value="23:30">11:30 pm</option>
                                                                                        <option value="00:00">12:00 am (midnight next day)</option>
                                                                                        <option value="00:30">12:30 am (next day)</option>
                                                                                        <option value="01:00">1:00 am (next day)</option>
                                                                                        <option value="01:30">1:30 am (next day)</option>
                                                                                        <option value="02:00">2:00 am (next day)</option>
                                                                                        <option value="02:30">2:30 am (next day)</option>
                                                                                        <option value="03:00">3:00 am (next day)</option>
                                                                                        <option value="03:30">3:30 am (next day)</option>
                                                                                        <option value="04:00">4:00 am (next day)</option>
                                                                                        <option value="04:30">4:30 am (next day)</option>
                                                                                        <option value="05:00">5:00 am (next day)</option>
                                                                                        <option value="05:30">5:30 am (next day)</option>
                                                                                        <option value="06:00">6:00 am (next day)</option>
                                                                                    </AvField>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col>
                                                                                <FormGroup className="mb-2">
                                                                                    {/* <AvField
                                                                                        className="primary"
                                                                                        bsSize="sm"
                                                                                        type="text"
                                                                                        name="infoHoursOfOperation"
                                                                                        placeholder="i.e. Brunch"
                                                                                        value={this.state.infoHoursOfOperation}
                                                                                        onChange={(evt) => this.handleChange(evt, 'infoHoursOfOperationIsValid')}
                                                                                    /> */}
                                                                                    <AvField
                                                                                        onChange={this.handleChangeHours}
                                                                                        value={isEditHours ? isEditHours.info : addHours.info}
                                                                                        bsSize="sm"
                                                                                        type="text"
                                                                                        name="info"
                                                                                        className="c-gradient"
                                                                                        placeholder="i.e. Brunch"
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>

                                                                        <div className="text-right">
                                                                            <Button
                                                                                size="sm"
                                                                                color="primary"
                                                                                className="mw"
                                                                                onClick={this.handleSubmitHours}
                                                                                disabled={isHoursValid || addHours['end_time'] === '' ||
                                                                                    addHours['start_time'] === '' || addHours['day_of_week'] === ''}
                                                                            >
                                                                                Submit
                                                                            </Button>
                                                                            <Button size="sm" color="primary" className="mw"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        addHoursofOperation: false,
                                                                                        addHours: {
                                                                                            day_of_week: 0,
                                                                                            end_time: "",
                                                                                            start_time: "",
                                                                                            entries: "",
                                                                                            id: "",
                                                                                            info: "",
                                                                                            "next_day": 0
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            >
                                                                                Cancel
                                                                            </Button>
                                                                        </div>
                                                                    </AvForm>
                                                                    <hr />
                                                                </div>
                                                            </div> : ""}
                                                                    <div className="wr-category-heading">
                                                                        <p className="fs-14">Other Websites:</p>
                                                                        <p className="fs-14">
                                                            {websiteSet && websiteSet.length > 0 ?
                                                                <>{websiteSet.map((site, index) => {
                                                                    if (site.website_type != 'Main') {
                                                                        return <Link className="wb-break-word" target="_blank" to={
                                                                            {
                                                                                pathname: "/abouttransition/",
                                                                                search: `?page_url=${site.website}`
                                                                            }
                                                                        } >
                                                                            {site ? site.website_type === 'Facebook' ? (<span><i class="fa fa-facebook" aria-hidden="true"></i></span>) : ("") ||
                                                                                site.website_type === 'Google+' ? (<span><i class="fa fa-google-plus" aria-hidden="true"></i></span>) : ("") ||
                                                                                    site.website_type === 'Twitter' ? (<span><i class="fa fa-twitter" aria-hidden="true"></i></span>) : ("") ||
                                                                                        site.website_type === 'Instagram' ? (<span><i class="fa fa-instagram" aria-hidden="true"></i></span>) : ("") ||
                                                                                            site.website_type === 'LinkedIn' ? (<span><i class="fa fa-linkedin" aria-hidden="true"></i></span>) : ("") : ""}||
                                                                        </Link>
                                                                    }
                                                                })}</> : ""
                                                            }
																		</p>
																	</div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        :
                                                        ''
                                                    }
                                                </Row>
                                            </div>
                                            <div className="mt-auto ml-auto wr-movie-less-info-about">
                                                <span role="button" onClick={() => this.setState({ toggleMoreLessInfo: !this.state.toggleMoreLessInfo })}>
                                                    {this.state.toggleMoreLessInfo ? <><span className="text">less info</span><span className="icon-arrow-left"></span></> : <><span className="text">more info</span><span className="icon-arrow-right"></span></>}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="floated-info-section d-flex align-items-center flex-wrap justify-content-center">
                                        <div className="image-area-show">
										<img src={`${about && about.media_dict && about.media_dict.length > 0 && Array.isArray(about.media_dict) && about?.media_dict[0] && about.media_dict[0].url}`}
																className="img-fluid"
																onError={(error) =>
																	(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/lawyer-neutral-02-472x446.jpg`)
																}
																alt={`${about && about?.media_dict && about?.media_dict.length > 0 && about?.media_dict[0] && about.media_dict[0].caption ? about.media_dict[0].caption : 'no-image'}`}
															/>
                                        </div>
                                        <div className="image-area-crop fs-14">
                                            Help us to make WikiReviews a better place! <br /> Please upload an alternate image. Suggested Size 1600x1050.
                                        </div>
                                    </div>
                                    {/* <div className="floated-no-image-section">
                                        <Button color="primary" size="lg">Upload Images</Button>
                                    </div> */}
                                </Container>
                            </div>

        )
    }
}

const mapProps = (dispatch) => ({
    add_hours_of_operations: (id, data,type) => dispatch(add_hours_of_operations(id, data,type)),
})

export default connect(null, mapProps)(About);