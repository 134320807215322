/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import _ from 'lodash'
import { indexOf } from 'lodash'
import moment from 'moment';
import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import Rating from 'react-rating';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Button, ButtonGroup, Badge, Carousel as RBCrousel, CarouselItem, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, FormText, Input, InputGroup, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane, UncontrolledDropdown, CustomInput, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap';
import StarsRating from 'stars-rating';
import { clear_category, clear_subcategory, delete_special_offer, delete_tips_and_warnings, delete_wall_comment, fetch_categories_data, fetch_detailedstarrating, fetch_other_offers, fetch_sub_categories, filter_categories_data, getCaptcha, get_business_detail, get_business_location_weather, get_business_QA_dropdown, get_business_review_detail, get_business_wall_data, get_discuss_this_page, get_id_from_slug, get_media_children, get_simillar_business, get_tips_and_warnings, post_business_sign_up, post_comment_on_offers, post_discuss_this_page, post_other_offers, post_tips_and_warnings, post_wall_comments, publish_post, put_special_offer, put_wall_reply, save_ratings_vote, share_by_email, add_to_list, remove_from_list, get_working_employee, post_people_working, delete_people, menuItemChangedStatus } from '../../actions/business';
import {get_friends_profile} from '../../actions/shared'
import { get_discussions } from '../../actions/discussionsTab';
import { embedNewLink } from '../../actions/messages';
import { clear_media_dispatch, delete_mutiple_media, fetch_delete_media, fetch_media_data, fetch_taxonomy_category, fetch_taxonomy_list, media_upload_scroll, single_delete_from_upload, upload_add_captions, upload_media_files, upload_media_filesData } from "../../actions/myProfiles";
import { callApi } from '../../utils/apiCaller';
import CollapseBasic from '../atoms/collapse';
import DeleteBtn from "../atoms/deleteBtn";
import EditBtn from "../atoms/editBtn";
import AboutBusiness from '../oragnisms/businessDetails/aboutBusiness';
import AdditionalInfo from '../oragnisms/businessDetails/AdditionalInfo';
import ClientReview from '../oragnisms/businessDetails/ClientReview';
import DetailStarRating from '../oragnisms/businessDetails/detailStarRating';
import DiscussionsTab from '../oragnisms/businessDetails/discussionsTab';
import DiscussThisPage from '../oragnisms/businessDetails/discussThisPage';
//import Gallery from '../oragnisms/businessDetails/gallery';
import IsThatYourBusiness from '../oragnisms/businessDetails/IsThatYourBusiness';
import LeafletMap from '../oragnisms/businessDetails/LeafletMap';
import Location from '../oragnisms/businessDetails/location';
import OtherOffers from '../oragnisms/businessDetails/otherOffers';
import QandATab from '../oragnisms/businessDetails/QandATab';
import SimilarBusiness from '../oragnisms/businessDetails/similarBusiness';
import PeopleWorking from '../oragnisms/businessDetails/PeopleWorking';
import TipsAndWarning from '../oragnisms/businessDetails/tipsAndWarning';
import TopBarTabs from '../oragnisms/businessDetails/topBarTabs';
import WebCamRecording from '../oragnisms/businessDetails/webCamRecording';
import AppHeader from '../oragnisms/dashboard/appHeader';
import { my_profile } from "../../actions/user";
import Reactions from "../molecules/homepage/Reactions";
import {
    updateFeedReactions
} from "../../actions/feeds";
import { get_reviewgroup, get_my_privacy_list } from "../../actions/user";
import RatingCollapse from '../atoms/ratingCollapse';
import PublicPageHeader from '../oragnisms/homepage/publicPageHeader';
import {
    add_cite_source
} from "../../actions/business";
import { Form } from 'formik';
import FriendsProfile from '../molecules/shared/FriendsProfile/friendsProfile';
import { toast } from 'react-toastify';
import Gallery from '../molecules/shared/Gallery/gallery';
import { categoryType, getReviewSuffix, highlightSearchTerm, mediaThumbnail } from '../../utils/utilities';
import DetailedStarRating from '../molecules/shared/DetailedStarRatings/detailedStarRating';
import { get_category_taxonomy_listing, update_caption_and_categories } from '../../actions/itemDetail';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';
import { getCategoryDetailDescription, getCategoryDetailTitle } from '../../utils/seo-utilities';

const empty_star_rating = (<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "34px" }}>★</span>);

const full_star_rating = (<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "34px" }}>★</span>);

const empty_star_rating_below = (<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "24px" }}>★</span>);

const full_star_rating_below = (<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "24px" }}>★</span>);

const group_icons = 'https://stagingdatawikireviews.s3.amazonaws.com/images/group-icon.png';
const projectImages = [
    {
        id: 1,
        src: 'https://userdatawikireviews.s3.amazonaws.com/media/content/1576217453093.8fc87b9e96593149a0843e5113e157381f812f9e.jpg',
        altText: 'Uploaded Photo 1'
    },
    {
        id: 2,
        src: 'https://userdatawikireviews.s3.amazonaws.com/media/content/1576206413032.87eefd1076f12330a707133670b9b40f59ff2d7d.jpg',
        altText: 'Uploaded Photo 2'
    },
    {
        id: 3,
        src: 'https://userdatawikireviews.s3.amazonaws.com/media/content/maxresdefault.4d660be44ce3bb284961971326c40fcad6d7f61e.jpg',
        altText: 'Uploaded Photo 3'
    }
];

class BusinessDetails extends Component {
    constructor(props) {
        super(props)

        this.myScrollPhotoRef = React.createRef();
        this.myScrollVideoRef = React.createRef();

        this.refGalleryVideoScroll = React.createRef();
        this.refGalleryImageScroll = React.createRef();

        this.state = {
            showRevTips: false,
            temperature_C_Blue: null,
            temperature_F_Blue: null,
            enableRatingText: null,
            enableHoverBanIcon: false,
            titleRatingText: null,
            tempIcon: null,
            enableStickyHeader: false,
            enableErrorForReview: true,
            embedBody: [],
            stepTwoUploadedVideo: [],
            reviewDynamicItems: {},
            businessId: null,
            viewBusinessAs: 'about',
            createNewDisModalToggle: false,
            exploreMenuItemsModalToggle: false,
            activeMenuType: 'mainMenu',
            mainViewType: 'reviews', //'wall'
            viewEditReviewStep: 'writeReview',
            viewWallUpdatesType: 'all',
            setRatingFrom: 'Overall Rating',
            businessDetails: null,
            simillarBusiness: null,
            tipsAndWarnings: null, //dummyTipsWarning,
            isToggleTips: false,
            isToggleWorkingPeople: false,
            isToggleRecommendPeople: false,
            isToggleWarning: false,
            tipsText: '',
            warningText: null,
            // modalText: '',
            editId: null,
            deleteItem: null,
            deleteOffer: null,
            businessRatingList: [],
            host: window.location.host,
            protocol: window.location.protocol,
            otherOffers: {
                isCheckInOfferToggle: false,
                inStoreCouponToggle: false,
                inOtherOffer: false,
            },
            offerCheck: {
                special_offer: "",
                startDate: "Enter Start Date",
                from_date: new Date(),
                endDate: "Enter Expiration Date",
                to_date: new Date(),
                claimDealOption: "",
                terms_details_exclusions: "",
                offer_type: "",
                other_offer: "",
                reviews_userentries: '',
                validateForSubmit: true,
                is_offer_edit: false
            },
            stateOtherOffers: null,
            discussThisPage: null, // dummyDiscussThisPage,
            createNewDiscussion: {
                tittle: '',
                body: '',
                media: []
            },
            filterSelectCompose: {},
            messageText: '',
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            addressnull: null,
            searchTheater: '',
            fetchPhotos: {},
            fetchPhotosCount: 0,
            fetchVideos: {},
            fetchVideosCount: 0,
            albumType: '',
            fetchTaxonomy: [],
            businessRatingIds: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            dataWantsToDelete: [],
            confirmDeleteModal: false,
            limitedPostsModal: false,
            uploadMediaModal: false,
            uploadMediaPostModal: false,
            isModalTrue: false,
            modalText: null,
            modalType: null,
            modalSelected: null,
            itemsAttachment: [],
            reviewsCategories: [],
            itemsIndex: 1,
            showVideoMedia: true, // video option toggle
            businessValues: {
                firstName: '',
                lastName: '',
                email: '',
                confirmEmail: '',
                password: '',
                confirmPassword: '',
                verificationCaptcha: ''
            },
            destinationDetails: {
                latitude: 0,
                longitude: 0,
                travelMode: 'DRIVING',
                addressTo: ''

            },
            routes: [],
            setWallDataItems: {},
            avg_rating: 0,
            enableRating: false,
            AbilitytoResolveDisputes: 0,
            TurnaroundTime: 0,
            AttentiontoDetail: 0,
            QualityofDryCleaning: 0,
            QualityofEquipment: 0,
            write_new_review: '',
            writeAReviewTextVideo: '',
            QualityofEquipmentCheck: false,
            reviewAnonymous: false,
            shocialNetworkSharing: false,
            ReviewTipsGuidelines: false,
            enableScrollForWall: true,
            setCurrentWallPage: 1,
            wallLoading: false,
            wallReplyVisibility: {},
            wallReplyText: {},
            deleteComment: null,
            wallCommentEdit: false,
            dicussionThisText: '',
            dicussionThisBody: '',
            profileInfo: null,
            isEditOffer: false,
            vidoeCaptionCategory: '',
            vidoeCaption: '',
            isadhere: true,
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            isImageType: false,
            captionData: "",
            uploadedId: {},
            cattaxListing: {},
            albumCategoryId: "",
            friendsProfileRecommend: null,
            friendsProfileFavourite: null,
            friendsProfileWant: null,
            chartStarRatings: null,
            dropdownSortBy: false,
            clickedOnWriteAReview: -1,

            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
            forceShowLoginModal: false,
            openVerifyModal: false,
            opennFormallyNameChangedModal: false,

            cite_source_link: '',
            cite_source_supporting_file: '',
            cite_source_radio: '',
            selectedSiteSourceFilesPreview: [],
            SiteSourcefiles: '',
            submitDisabled: true,
            bussiness_moved: false,
            formally_name_chaged: false,
            business_closed: false,
            get_temp: '',
            working_employee: null,
            peopleWorkingState: {},
            workingEmployee: null,
            categoryItemQueryParams:{
                object_pk: props?.productId,
                content_type: 'reviews.userentries',
                key: 'avg_rating',                
            },
            avgRateKey:null,
            ratingOn:false,
            embeddedLink:[],
            rateMenu:[],
            rateMenuContent:[],
            uploadType:"",
            mediaSetMenu:[],
            menuTitle:"",
            menuSubTitle:"",
            menuMediaList:[],
            menuMediaListMedia:{},
            menuObjectPk:"",
            disableCreateReview:true,
            showCreateReview:false
        }
    }

    setClickedTabOperation = ({ value }) => {
        if (value > 1) {
            this.setState({ clickedOnWriteAReview: value, viewEditReviewStep: "writeReview" });
        } else {
            this.setState({ clickedOnWriteAReview: value });
        }
    }


    fetchDataFromHtmlForm = async ({ businessId }) => {
        let url = `/form/reviewnewlisting/business/${businessId}`;
        try {
            const response = await callApi(url, 'GET');
            if (response?.code == 200) {
                let data = response?.data;
                if (data) {
                    const doc = new DOMParser().parseFromString(data, "text/html")
                    let uniqrate = doc.getElementById('uniqrate').value;
                    let csrfmiddlewaretoken = doc.getElementsByName('csrfmiddlewaretoken')[0].value;
                    //let security_hash = doc.getElementsByName('security_hash')[0].value;
                    //let timestamp = doc.getElementsByName('timestamp')[0].value;
                    //let object_pk = doc.getElementsByName('object_pk')[0].value;
                    //let content_type = doc.getElementsByName('content_type')[0].value;
                    let security_hash = "";
                    let timestamp = Date.now();
                    let honeypot = doc.getElementsByName('honeypot')[0].value;
                    let object_pk = this.state.businessId;
                    let content_type = "reviews.userentries";
                    let reviewDynamicItems = {};
                    reviewDynamicItems.uniqrate = uniqrate;
                    reviewDynamicItems.csrfmiddlewaretoken = csrfmiddlewaretoken;
                    reviewDynamicItems.security_hash = security_hash;
                    reviewDynamicItems.timestamp = timestamp;
                    reviewDynamicItems.honeypot = honeypot;
                    reviewDynamicItems.object_pk = object_pk;
                    reviewDynamicItems.content_type = content_type;

                    this.setState({ reviewDynamicItems });
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    updateSecurityHash = async ({ businessId }) => {
        let { reviewDynamicItems } = this.state;
        let url = `/form/reviewnewlisting/business/${businessId}`;
        try {
            const response = await callApi(url, 'GET');
            if (response?.code == 200) {
                let data = response?.data;
                if (data) {
                    const doc = new DOMParser().parseFromString(data, "text/html")
                    let security_hash = doc.getElementsByName('security_hash')[0].value;
                    reviewDynamicItems.security_hash = security_hash;
                    this.setState({ reviewDynamicItems });
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    addUploadedVideo = (item) => {
        let { stepTwoUploadedVideo } = this.state;
        stepTwoUploadedVideo.push(item);
        this.setState({ stepTwoUploadedVideo });

        if (Array.isArray(stepTwoUploadedVideo) && stepTwoUploadedVideo.length > 0) {
            this.setState({ enableErrorForReview: false })
        }

    }

    removeUploadedVideo = () =>{
        this.setState({ stepTwoUploadedVideo : [],enableErrorForReview: true});
    }

    addSpecialOfferComment = ({ }) => {
        // let { profileInfo, businessId } = this.state;
        // let data = { [id]: text, "reviews_userentries": businessId, "created_by": 1490, "reviews_specialoffers": id, "comment": text };
    }

    handleWallReplyText = (id, value) => {
        let { wallReplyText } = this.state;
        wallReplyText[id] = value;
        this.setState({ wallReplyText })
    }

    toggleWallReply = (item) => {
        let { wallReplyVisibility } = this.state;
        if (wallReplyVisibility[item]) {
            wallReplyVisibility[item] = !wallReplyVisibility[item];
        } else {
            wallReplyVisibility[item] = true;
        }
        this.setState({ wallReplyVisibility });
    }

    handleDestinationChange = (destinationDetails) => {
        this.setState({
            destinationDetails: destinationDetails
        });
    }

    handleOnChangeRoutes = (routes) => {
        this.setState({
            routes: routes
        })
    }

    handleCloseAndFetchReview = () => {

        let { mediaSetReview } = this.state;
        this.setState({ uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: true, mediaSet: mediaSetReview, taxonomycategoryId: "", fetchTaxonomyListingImages: {}, fetchTaxonomyListingVideos: {}, categoryType: "", uploadedId: {},uploadType:'' }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);
        });
    }


    handleCloseAndFetch = () => {
        let { latestUploadedData, fileReviewStatus, mediaSetReview } = this.state;

        if (fileReviewStatus && Object.values(latestUploadedData) && Object.values(latestUploadedData)[0] && Object.values(latestUploadedData)[0].id) {
            Object.values(latestUploadedData).map((list) => {
                mediaSetReview.push({
                    "id": list.id,
                    "file": list.url,
                    "thumbnail": list.thumbnail,
                    "url": list.url,
                })
            })

        }
        this.setState({
            uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: false, mediaSetReview, taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {}, captionData: "", uploadedId: {}
        }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);
        });
    }

    handleOtherOffersToggle = (item, edit) => {
        let { otherOffers } = this.state;

        if (item === "inStoreCouponToggle") {
            otherOffers[item] = !otherOffers[item];
            otherOffers['isCheckInOfferToggle'] = false;
            otherOffers['inOtherOffer'] = false;
            this.setState({ otherOffers });
        }

        if (item === "isCheckInOfferToggle") {
            otherOffers[item] = !otherOffers[item];
            otherOffers['inStoreCouponToggle'] = false;
            otherOffers['inOtherOffer'] = false;
            this.setState({ otherOffers });
        }

        if (item === "inOtherOffer") {
            otherOffers[item] = !otherOffers[item];
            otherOffers['isCheckInOfferToggle'] = false;
            otherOffers['inStoreCouponToggle'] = false;
            this.setState({ otherOffers });
        }

        if (!edit) {
            this.setEditOffer();
            this.setState({ isEditOffer: edit });
        }
    }

    toggleTips = () => {
        this.setState({ isToggleTips: !this.state.isToggleTips });
    }

    toggleWarning = () => {
        this.setState({ isToggleWarning: !this.state.isToggleWarning });
    }
    WorkingPeople = () => {
        this.setState({ isToggleWorkingPeople: !this.state.isToggleWorkingPeople });
    }

    RecommendPeople = () => {
        this.setState({ isToggleRecommendPeople: !this.state.isToggleRecommendPeople });
    }




    componentWillMount() {
        this.checksAndRenderById();
    }

    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        this.setState({
            [name]: value
        });



        if ((name == "cite_source_link" && value != "") || (name == "cite_source_radio" && value != "")) {
            this.setState({
                ["submitDisabled"]: false,
            });
        } else {
            this.setState({
                ["submitDisabled"]: true,
            });
        }

        if (name == "cite_source_link" && value != "") {
            var res = value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

            if (res == null) {
                this.setState({
                    ["submitDisabled"]: true,
                });
            }
            else {
                this.setState({
                    ["submitDisabled"]: false,
                });
            }
        }


    }

    checksAndRenderById = () => {
      
        let props = this.props;
        let pathname = props.history.location?.pathname;
        let id = props?.history?.location?.state?.id;
        let id2 = props?.match?.params?.id;
        let serviceParams = props?.match?.params;
        let mainViewTyp = props?.history?.location?.state?.notif_tab;
        if (id === undefined && serviceParams?.service !== '' && serviceParams?.state !== '' && serviceParams?.city !== '' && serviceParams?.name !== '') {
            let items = pathname.split('/');
            let type = items[1] ? items[1] : '';
            let serviceItems = Object.values(serviceParams).join('/');
            // this.props.get_id_from_slug({ type, slug: serviceItems }) // disable due to failing
        }
        if (id || id2) {

            this.setState({ businessId: id || id2, businessDetails: null, simillarBusiness: null, setWallDataItems: {} }, () => {
                window.scrollTo(0, 0)
                this.fetchByBusinessId({ businessId: this.state.businessId });
            });
        }

        if (id === undefined && serviceParams?.id) {
            let items = pathname.split('/');
            let type = items[1] ? items[1] : '';
            let slug = items[2] ? items[2] : '';
            // this.props.get_id_from_slug({ type, slug }) // disable due to failing

        }

        if (mainViewTyp && mainViewTyp != "") {
            this.setState({ mainViewType: mainViewTyp });
        }
    }

    componentWillReceiveProps = (nextProps) => {

        let { fetchPhotos, fetchPhotosCount, fetchVideos, fetchVideosCount, fetchGalleryScroll, fetchGalleryCount, fetchDeletedData, fetchDeleteCount, activeMyPhotoIndex, latestUploadedData, setWallDataItems, setCurrentWallPage, viewWallUpdatesType } = this.state;

        let { businessDetails, businessId } = this.state;
        const { simillar_business, business_weather, working_employee } = nextProps;

        if (nextProps?.chart_star_ratings) {
            this.setState({ chartStarRatings: nextProps?.chart_star_ratings });
        }

        if (nextProps?.back_to_listing) {
            if (nextProps?.back_to_listing?.code === 200 && businessId === null) {
                this.setState({ businessId: nextProps?.back_to_listing?.entry_id }, () => {
                    this.fetchByBusinessId({ businessId: this.state.businessId });
                });
            }

            if ([404, 400, 500].includes(nextProps?.back_to_listing?.code)) {
                this.props.history.push('/business');
            }
        }

        if (nextProps.friends_profile_recommend) {
            this.setState({
                friendsProfileRecommend: nextProps.friends_profile_recommend
            })
        }
        if (nextProps.friends_profile_favourite) {
            this.setState({
                friendsProfileFavourite: nextProps.friends_profile_favourite
            })
        }
        if (nextProps.friends_profile_want) {
            this.setState({
                friendsProfileWant: nextProps.friends_profile_want
            })
        }

        if (business_weather) {

            this.setState({
                weather: business_weather,
                temperature_C_Blue: business_weather.BlueIcon && business_weather.BlueIcon.temp_C,
                temperature_F_Blue: business_weather.BlueIcon && business_weather.BlueIcon.temp_F,
                tempIcon: business_weather.BlueIcon && business_weather.BlueIcon.iconPath,
            });
        }


        if (nextProps?.business_details && ((nextProps?.business_details?.address && businessDetails === null) || (nextProps?.business_details?.name !== businessDetails?.name))) {
            this.setState({
                businessDetails: nextProps?.business_details,
                destinationDetails: {
                    ...this.state.destinationDetails,
                    latitude: nextProps.business_details.address && nextProps.business_details.address[0] ? nextProps.business_details.address[0].latitude : 0,
                    longitude: nextProps.business_details.address && nextProps.business_details.address[0] ? nextProps.business_details.address[0].longitude : 0
                }
            }, () => {
                let { content_type, id, name } = this.state.businessDetails;
                this.props.get_simillar_business({ 'cat': content_type, id, name });
            });
        }

        if (nextProps?.simillar_business) {
            this.setState({ simillarBusiness: simillar_business });
        }

        if (nextProps?.working_employee) {
            this.setState({ workingEmployee: working_employee });
        }




        if (nextProps.qa_dropdown &&
            Array.isArray(nextProps.qa_dropdown.res) &&
            nextProps.qa_dropdown.res.length > 0) {
            this.setState({ reviewsCategories: nextProps.qa_dropdown });
        }

        if (nextProps?.tips_and_warnings) {
            this.setState({ tipsAndWarnings: nextProps?.tips_and_warnings });
        }


        if (nextProps?.discuss_this_page) {
            this.setState({ discussThisPage: nextProps?.discuss_this_page });
        }

        if (nextProps.fetch_photos) {
            let item = nextProps.fetch_photos;
            if (item.album) { fetchPhotos = [] }
            fetchPhotos[item.page] = item;
            fetchPhotosCount = item.count;
            this.setState({ fetchPhotos, fetchPhotosCount, activeMyPhotoIndex, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_videos) {
            let item = nextProps.fetch_videos;
            if (item.album) { fetchVideos = [] }
            fetchVideos[item.page] = item;
            fetchVideosCount = item.count;
            this.setState({ fetchVideos, fetchVideosCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_taxonomy) {
            let taxCat = nextProps.fetch_taxonomy;
            if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
                taxCat = taxCat.filter(dt => dt.label !== null);
            }
            this.setState({ fetchTaxonomy: taxCat })
        }

        if (nextProps.fetch_deleted_data) {
            let item = nextProps.fetch_deleted_data;
            if (!item.type) { fetchDeletedData = {} }
            fetchDeletedData[item.page] = item;
            fetchDeleteCount = item.count;
            this.setState({ fetchDeleteCount, fetchDeletedData, activeMyPhotoIndex: 0 });
        }

        if (nextProps.upload_scroll) {
            let item = nextProps.upload_scroll;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({ fetchGalleryScroll, fetchGalleryCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.my_profile && Object.keys(nextProps.my_profile).length > 0) {
            let data = nextProps.my_profile;
            if (data?.user) {
                data['name_full'] = `${data?.user?.first_name} ${data?.user?.last_name}`
            }
            this.setState({
                myProfile: data,
            });
        }

        if (nextProps.upload_media_data) {
            let data = nextProps.upload_media_data;
            if (data.index !== undefined) {
                let idx = data.index;
                latestUploadedData[idx] = data;
                this.setState({ latestUploadedData });
            }
        }

        if (nextProps?.wall_data && (Object.keys(setWallDataItems).length === 0 || nextProps?.wall_data?.force)) {

            let items = nextProps?.wall_data;
            if (items?.filter_type) {

                setWallDataItems[items?.filter_type] = items;
                this.setState({ setWallDataItems, wallLoading: false, wallReplyText: {}, wallReplyVisibility: {}, wallCommentEdit: false, wallReplyText: {} });
            }
        }

        if (setCurrentWallPage > 1 && nextProps?.wall_data?.page === setCurrentWallPage && nextProps?.wall_data?.filter_type === viewWallUpdatesType) {
            let items = setWallDataItems[viewWallUpdatesType]?.results;
            let newItems = nextProps?.wall_data?.results;
            items = items.concat(newItems);
            setWallDataItems[viewWallUpdatesType].results = items;
            let enableMoreScroll = false;
            if (setWallDataItems?.count > items.length) {
                enableMoreScroll = true;
            }
            this.setState({ setWallDataItems, enableScrollForWall: enableMoreScroll, wallLoading: false, wallReplyText: {} });
        }

        if (nextProps?.my_profile) {
            this.setState({ profileInfo: nextProps?.my_profile });
        }

        // if (nextProps.filter_data_category) {
        //     let taxCatList = nextProps.filter_data_category;

        //     if (taxCatList && Object.keys(taxCatList).length > 0) {
        //         this.setState({ cattaxListing: taxCatList[0] ? taxCatList[0].children : [] })
        //     }
        // }

        if (nextProps?.cat_taxonomy_list &&
            Array.isArray(nextProps?.cat_taxonomy_list) &&
            nextProps?.cat_taxonomy_list?.length) {
            let cattaxListing = [];
            nextProps.cat_taxonomy_list.forEach(item => {
                cattaxListing = cattaxListing.concat(item.children);
            })
            this.setState({ cattaxListing });
        }

        if (nextProps.fetch_data_categories) {
            let { fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state;
            let taxCatListing = nextProps.fetch_data_categories;
            if (taxCatListing && Array.isArray(taxCatListing) && taxCatListing.length > 0) {
                if (taxCatListing[0].isTypes == "image") {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingImages[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingImages });
                    }

                } else {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingVideos[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingVideos });
                    }
                }
            }

        }

        if (nextProps.auth_data && (nextProps.auth_data !== this.props.auth_data)) {
            //this.props.my_profile_info();
            window.location.reload();
        }

        if (nextProps.my_privacy_setting && nextProps.my_privacy_setting.results && Array.isArray(nextProps.my_privacy_setting.results) && nextProps.my_privacy_setting.results.length > 0) {
            let value = nextProps.my_privacy_setting?.results[12]?.value;
            this.setState({ get_temp: value })
        }

        if (nextProps?.toggleStarRating && Object.keys(nextProps?.toggleStarRating).length) {
            const { enable_rating, disable_create_review } = nextProps?.toggleStarRating;
            this.setState({ enableRating: enable_rating, disableCreateReview: disable_create_review });
        }
        
        if (nextProps.business_reviewDetail && Array.isArray(nextProps.business_reviewDetail.results)) {
            this.setState({ initialReviewListing: nextProps.business_reviewDetail.results },()=>{
                highlightSearchTerm();
            })

            if (!this.state.initialReviewListing?.length && nextProps.business_reviewDetail.results?.length)
                this.setState({ initialReviewListing: nextProps.business_reviewDetail.results },()=>{
                    highlightSearchTerm();
                })

            if (nextProps.business_reviewDetail.results.length > 0) {

                if (!nextProps.business_reviewDetail.results?.some(item => item?.created_by?.id === this.props?.loggedInUser?.id)) {
                    if (!this.state.initialReviewListing) this.setState({ disableCreateReview: false },()=>{
                        highlightSearchTerm();
                    })
                }
            } else if (nextProps.business_reviewDetail.results.length === 0) {
                if (!this.state.initialReviewListing) this.setState({ disableCreateReview: false },()=>{
                    highlightSearchTerm();
                })
            }
        } 

        if(nextProps.menuItemChanged !== this.props.menuItemChanged){
            const {businessId} = this.state
            this.fetchRateMenuItems({businessId});
        }

        if (nextProps?.business_details && "is_logged_user_review" in nextProps.business_details) {
            if (this.state.showCreateReview === nextProps?.business_details?.is_logged_user_review) {
                this.setState({ showCreateReview: !nextProps?.business_details?.is_logged_user_review })
            }
        }
       
        if(this.state.stepTwoUploadedVideo?.length > 0){
            this.setState({enableErrorForReview : false})
        }
        
    }

    nextProjectImage = () => {
        if (this.state.animatingProjectImage) return;
        const nextIndex = this.state.activeProjectImageIndex === projectImages.length - 1 ? 0 : this.state.activeProjectImageIndex + 1;
        this.setState({ activeProjectImageIndex: nextIndex })
    }

    previousProjectImage = () => {
        if (this.state.animatingProjectImage) return;
        const nextIndex = this.state.activeProjectImageIndex === 0 ? projectImages.length - 1 : this.state.activeProjectImageIndex - 1;
        this.setState({ activeProjectImageIndex: nextIndex })
    }

    projectImageSlides = () => {
        let { itemsAttachment } = this.state;
        if (itemsAttachment && Array.isArray(itemsAttachment) && itemsAttachment.length > 0) {
            return itemsAttachment.map((item, index) => {
                let full_name = `${item?.user?.user?.first_name} ${item?.user?.user?.last_name}`;
                return (
                    <CarouselItem
                        onExiting={() => this.setState({ animatingProjectImage: true })}
                        onExited={() => this.setState({ animatingProjectImage: false })}
                        key={item.id}>
                        <div className="text-center">
                            <img className="img-fluid" src={this.checkAttachment(item)} alt={item.filename} />
                        </div>

                        <div className="col-md-12">
                            <div className="col-md-6">
                                <div className="bottom-heading">
                                    <img
                                        src={full_name === this.state.myProfile.name_full ? this.state.myProfile.current_profile_file : item?.user?.current_profile_pic}
                                        style={{ width: 50, height: 50, borderRadius: '50%' }}
                                        alt=""
                                    />
                                    {`Uploaded by  ${full_name}`}
                                </div>
                                <div className="days-ago">
                                    {item?.upload_date}
                                    <span className="float-right">
                                        {index + 1}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </CarouselItem>
                )
            });
        }
    }

    setMediaSetForItems = (e, item) => {
        // e.preventDefault();
        let { mediaSet, mediaSetMenu, otherOffers, offerCheck,uploadType,rateMenuContent,menuTitle,menuSubTitle,menuItemContent,businessId,menuMediaList,menuMediaListMedia} = this.state;
        if (item && item.id && uploadType!=="menuItem") {
            let id = item.id;
            let itemFiltered = mediaSet.find(item => item.id === id);

            if (itemFiltered) {
                const index = mediaSet.indexOf(itemFiltered);
                if (index > -1) {
                    mediaSet.splice(index, 1);
                }
            } else {
                mediaSet.push(item)
            }

            if (otherOffers.inStoreCouponToggle) {
                if (mediaSet.length > 0) { offerCheck['validateForSubmit'] = false; }
                else { offerCheck['validateForSubmit'] = true; }
            }

            this.setState({ mediaSet: [...mediaSet], mediaSetReview: [...mediaSet], offerCheck });
        }else{
            let id = item.id;
            let itemFiltered = mediaSetMenu.find(item => item.id === id);

            if (itemFiltered) {
                const index = mediaSetMenu.indexOf(itemFiltered);
                if (index > -1) {
                    mediaSetMenu.splice(index, 1);
                }
            } else {
                mediaSetMenu.push(item) 
            }
            this.setState({mediaSetMenu:[...mediaSetMenu]})              
            
            if(!!menuMediaListMedia[this.state.menuObjectPk]){
                const collectedMedia = [...menuMediaListMedia[this.state.menuObjectPk], item]
                const uniqueMedia = [...new Map(collectedMedia?.map(item =>
                    [item['id'], item])).values()];
                menuMediaListMedia[this.state.menuObjectPk] = uniqueMedia
            }else{
                menuMediaListMedia[this.state.menuObjectPk] = [item]
            }
            let  rateMenuContentCopy = _.cloneDeep( rateMenuContent ) 
           rateMenuContentCopy.filter(item=>item.menuItem===menuTitle)[0].menuContent.filter(subItem=>subItem.menuItemName===menuSubTitle)[0].menuItemContent[5]=item.thumbnail
            

           this.setState({rateMenuContent:rateMenuContentCopy})

        }
    }

    handleCaptionByIndex = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData, taxonomycategoryId } = this.state;
        uploadCaptions[index] = value;
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = taxonomycategoryId;
        let id = upload_data.id;
        this.setState({ uploadCaptions, captionData: caption_data }, () => {
            // this.props.upload_add_captions(id, data)});
            this.props.update_caption_and_categories({ id, data });
    })}

    checkIsAdded = (item) => {
        let  mediaSetContainer 
        if(this.state.uploadType==="menuItem"){
            mediaSetContainer  = this.state.menuMediaListMedia[this.state.menuObjectPk] || [];
        }else{
            mediaSetContainer  = this.state.mediaSet;
        }        
        let id = item.id;
        let itemFiltered = mediaSetContainer.find(item => item.id === id);
        if (itemFiltered) {
            return true;
        } else {
            return false;
        }
    }

    removeFromMedia = (e, item, type) => {
        e.preventDefault();
        let { mediaSet } = this.state;
        let id = item.id;
        let itemFiltered = mediaSet.find(item => item.id === id);
        if (itemFiltered) {
            const index = mediaSet.indexOf(itemFiltered);
            if (index > -1) {
                mediaSet.splice(index, 1);
            }
            // call delete api here 
            // delete_mutiple_media
            this.setState({ mediaSet: [...mediaSet], mediaSetReview: [...mediaSet] }, () => {
                this.props.single_delete_from_upload(id, type);
            });
        }

    }

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = this.state;
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0) {
                return getDataItems.map(item => {
                    let itemName = (item?.thumbnail)?.split('.');
                    let itemFile = (item?.file)?.split('.');
                    let extName = itemName?.[itemName.length - 1];
                    let extFile = itemFile?.[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview
                    item['srcFile'] = isVideo ? srcFile : item?.file;
                    return (
                        <Col xs="auto" className="mb-3" >
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={(e) => { this.setMediaSetForItems(e, item) }} checked={this.checkIsAdded(item)} />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    checkIfVideo = (item) => {
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        let itemFile = (item && item.file).split('.');
        // let extName = itemName !== "" ? itemName[itemName.length - 1] : "";
        let extFile = itemFile[itemFile.length - 1];
        let srcFile = imagePreview;

        if (['jpg', 'jpeg', 'png', 'gif'].includes(extFile)) {
            srcFile = item.file;
        }
        return srcFile;
    }

    handleOnFileUploadChange = (e) => {
        let { selectedFilesPreview, files } = this.state;
        const filesSetForUpload = Array.from(e.target.files);
        files = files.concat(filesSetForUpload);
        let self = this;
        this.setState({ files }, () => {
            Promise.all(files.map(file => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            })).then(images => {
                let concatWithPrev = selectedFilesPreview.concat(images);
                self.setState({ selectedFilesPreview: concatWithPrev })
            }, error => {
                console.error(error);
            });
        });
        if (files && Array.isArray(files) && files.length > 0) {
            let indexSelected = selectedFilesPreview.length - 1;
            files.forEach(async (item, index) => {
                if (index > indexSelected) {
                    let formData = new FormData();
                    formData.append('file', item);

                    let name = item.name;
                    let split_items = name.split('.');
                    let item_ext = split_items[split_items.length - 1];
                    let isImageTrue = ['jpg', 'png', 'jpeg', 'gif', 'tiff'].includes(item_ext);
                    let url = `/upload/multiuploader/?album=reviews&instance=review_1&image=undefined&doc=undefined`
                    this.setState({ isImageType: isImageTrue })
                    // await this.props.filter_categories_data({ category: 'business', sub_cat: "", level: 1 });
                    if (isImageTrue) {
                        //await this.props.upload_media_files('image', formData, index);
                        await this.props.upload_media_filesData(url, 'image', formData, index);
                        await this.props.get_category_taxonomy_listing('business', 'images', this.state.businessDetails?.taxonomy_list?.[0]?.toLowerCase()?.replace(/&/g, "and"));
                    } else {
                        //await this.props.upload_media_files('video', formData, index);
                        await this.props.upload_media_filesData(url, 'video', formData, index);
                        await this.props.get_category_taxonomy_listing('business', 'videos', this.state.businessDetails?.taxonomy_list?.[0]?.toLowerCase()?.replace(/&/g, "and"));
                    }
                }
            });
        }
    }


    // handleOnSiteSourceFileUpload = (e) => {
    //     let { selectedSiteSourceFilesPreview, SiteSourcefiles } = this.state;
    //     const filesSetForUpload = Array.from(e.target.files);
    //     files = files.concat(filesSetForUpload);
    //     let self = this;
    //     this.setState({ files }, () => {
    //         Promise.all(files.map(file => {
    //             return (new Promise((resolve, reject) => {
    //                 const reader = new FileReader();
    //                 reader.addEventListener('load', (ev) => {
    //                     resolve(ev.target.result);
    //                 });
    //                 reader.addEventListener('error', reject);
    //                 reader.readAsDataURL(file);
    //             }));
    //         })).then(images => {
    //             let concatWithPrev = selectedFilesPreview.concat(images);
    //             self.setState({ selectedFilesPreview: concatWithPrev })
    //         }, error => {
    //             console.error(error);
    //         });
    //     });
    //     if (files && Array.isArray(files) && files.length > 0) {
    //         let indexSelected = selectedFilesPreview.length - 1;
    //         files.forEach(async (item, index) => {
    //             if (index > indexSelected) {
    //                 let formData = new FormData();
    //                 formData.append('file', item);

    //                 let name = item.name;
    //                 let split_items = name.split('.');
    //                 let item_ext = split_items[split_items.length - 1];
    //                 let isImageTrue = ['jpg', 'png', 'jpeg', 'gif', 'tiff'].includes(item_ext);
    //                 let url = `/upload/multiuploader/?album=reviews&instance=review_1&image=undefined&doc=undefined`
    //                 this.setState({ isImageType: isImageTrue })
    //                 await this.props.filter_categories_data({ category: 'business', sub_cat: "", level: 1 });
    //                 if (isImageTrue) {
    //                     //await this.props.upload_media_files('image', formData, index);
    //                     await this.props.upload_media_filesData(url, 'image', formData, index);
    //                 } else {
    //                     //await this.props.upload_media_files('video', formData, index);
    //                     await this.props.upload_media_filesData(url, 'video', formData, index);
    //                 }
    //             }
    //         });
    //     }
    // }

    handleChangeTaxonomy = (e) => {
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ fetchGalleryScroll: {}, fetchGalleryAlbum: value, fetchGalleryType: 'image' }, () => this.props.media_upload_scroll({ type: 'image', album: albumType, page: 1, force_update: true }))
    }

    handleChangeCategory = (e, index) => {
        let { latestUploadedData, uploadedId, uploadCaptions } = this.state;
        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        let isImageType = upload_data.media_type;
        uploadedId[index] = id;
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ albumCategoryId: albumType, uploadedId, taxonomycategoryId: value });

        this.props.update_caption_and_categories({ id, data: { "caption": uploadCaptions[index] || "", "category": value } });


        // let categoryId = "business";
        // let subcategoryId = value;
        // if (isImageType == "image") {
        //     this.props.fetch_categories_data(categoryId, subcategoryId, 'image', index);
        // } else {
        //     this.props.fetch_categories_data(categoryId, subcategoryId, 'video', index);
        // }
    }

    handleChangeTaxonomyData = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData } = this.state
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = value;
        let id = upload_data.id;
        this.setState({ taxonomycategoryId: value, captionData: caption_data }, () => this.props.upload_add_captions(id, data))

    }

    handleChangeVideoTaxonomy = async (e) => {
        let { name, value } = e.target;

        await this.setState({
            [name]: value,
        });


    }

    clearMediaDispatch = () => {
        this.setState({
            latestUploadedData: {}, uploadCaptions: {}, selectedFilesPreview: [], files: [], taxonomycategoryId: "",
            fetchTaxonomyListingImages: {}, fetchTaxonomyListingVideos: {}, captionData: "", uploadedId: {}, cattaxListing: {}, albumCategoryId: ""
        }, () => {
            this.props.clear_media_dispatch(); this.props.clear_category();
            this.props.clear_subcategory();
        });

    }


    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    handleScrollGalleryVideo = () => {
        let { refGalleryVideoScroll } = this;
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    handleScrollGalleryImage = () => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    AddNewEmbedLink = async (e) => {
        e.preventDefault();
        let { embeddedLink, embedBody } = this.state;
        // this.props.embedNewLink({
        //     data: {
        //         "video": `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
        //         "exclude_list": [],
        //         "body_preview": `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
        //         "question_rating_category": [],
        //         "messagemedia_set": []
        //     }
        // });
        let thumbnail = await mediaThumbnail(embeddedLink);
        embedBody.push({
            type: "embedded_link",
            url: embeddedLink,
            thumbnail
        });
        this.setState({ embeddedLink: '', embedBody, uploadMediaModal: false, createNewDisModalToggle: false })
    }

    checkImages = (item) => {
        let items = (item.tos).split(',');
        if (items.length > 1) {
            return group_icons;
        } else {
            return item.current_profile_pic;
        }
    }

    handleChangeRatings = (item) => {
        this.setState({ setRatingFrom: item })        
    }

    componentDidMount() {
        this.props.my_profile_info();
        this.props.get_my_privacy_list();
        let { businessId } = this.state;

        if (businessId) {
            this.fetchByBusinessId({ businessId });
            // this.fetchDataFromHtmlForm({ businessId });
            if(this.props.menuItemChanged===0){
            this.fetchRateMenuItems({businessId})
            }
        }
        this.props.clear_category();
        this.props.clear_subcategory();
        window.addEventListener("scroll", this.handleScroll, { passive: false });
        let openModal = localStorage.getItem('openModal');

        if (openModal) {
            this.setState({ openVerifyModal: openModal })
        }

    }

    fetchRateMenuItems = ({businessId})=>{
        callApi(`/api/getmenu/?entity=${businessId}&menuType=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                this.setState({rateMenu:response?.results || []})
                if(response?.results?.length){
                    this.setState({activeMenuType:response?.results[0]})
                    const menuItem = response?.results[0]
                    this.fetchRateMenuItemContent({businessId,menuItem})
                }                  
            }
        });
    }
    fetchRateMenuItemContent = ({businessId,menuItem})=>{
        callApi(`/api/getmenu/?entity=${businessId}&menuType=${menuItem}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {   
                let menuContainer = []
                Object.keys(response?.results).forEach((item)=>{
                    const tempArray = {
                        menuItem:item,
                        menuContent:this.formatMenuContent(response?.results?.[item])
                    }
                    menuContainer.push(tempArray);                   
                }) 
                this.props.menuItemChangedStatus(0);
                this.setState({rateMenuContent:menuContainer})           
            }
        });
    }

    formatMenuContent = (menuConArray)=>{
        let tempMenuContent = [];
        menuConArray.forEach(item=>{
            for (const [key, value] of Object.entries(item)) {
                const tempArray = {
                    menuItemName:key,
                    menuItemContent:value
                }
                tempMenuContent.push(tempArray)
              }            
        })
        return tempMenuContent
    }



    fetchByBusinessId = ({ businessId }) => {
        const { get_friends_profile, fetch_other_offers, get_business_detail, get_discuss_this_page, fetch_taxonomy_category, fetch_detailedstarrating, get_business_QA_dropdown, get_working_employee
        } = this.props;

        //get_media_children('Business', 'videos');
        get_business_detail(businessId);
        fetch_other_offers({ listing_id: businessId, offer_type: 'Other Offer' });
        fetch_other_offers({ listing_id: businessId, offer_type: 'Mention WikiReviews and Get Coupon Code' });
        fetch_other_offers({ listing_id: businessId, offer_type: 'Check In Offer' });
        fetch_other_offers({ listing_id: businessId, offer_type: 'In Store Coupon' });
        get_business_QA_dropdown(businessId);
        get_discuss_this_page({ id: businessId, pageId: 1 });
        fetch_taxonomy_category();
        fetch_detailedstarrating(businessId);
        get_friends_profile('recommend', businessId);
        get_friends_profile('want', businessId);
        get_friends_profile('favourite', businessId);
        get_working_employee({ business_id: businessId });




    }

    componentWillUnmount() {
        clearTimeout(this.timeOutWallReplyText);
        window.removeEventListener("scroll", this.handleScroll);
        localStorage.removeItem("openModal");
    }

    handleScroll = () => {
        let { setWallDataItems, enableScrollForWall, setCurrentWallPage, businessId } = this.state;
        let { get_business_wall_data } = this.props;

        if (Object.keys(setWallDataItems).length) {
            let itemEnd = document.getElementById("item-last");

            if (itemEnd) {
                var elem = itemEnd;
                var distanceScrolled = document.body.scrollTop;
                var elemRect = elem.getBoundingClientRect();
                var elemViewportOffset = elemRect.top;
                var totalOffset = distanceScrolled + elemViewportOffset - 250;

                if (totalOffset < 0 && enableScrollForWall) {
                    this.setState({ enableScrollForWall: false, setCurrentWallPage: setCurrentWallPage + 1, wallLoading: true }, () => {
                        get_business_wall_data({ id: businessId, filter_type: 'all', page: this.state.setCurrentWallPage });
                    });
                }
            }
        }

        let enableSticky = document.getElementById('enable-sticky');
        let distanceToTop = enableSticky.getBoundingClientRect().top;
        if (distanceToTop < 0) {
            if (this.state.enableStickyHeader !== true) {
                this.setState({ enableStickyHeader: true });
            }
        } else {
            if (this.state.enableStickyHeader !== false) {
                this.setState({ enableStickyHeader: false });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.checksAndRenderById();
        }

        if (this.props.business_details !== prevProps.business_details) {
            // if (this.props.business_details && this.props.business_details.taxonomy_list) {

            //     get_media_children('Business', 'videos', this.props.business_details.taxonomy_list);

            // }
            if (this.state.businessDetails) {
                const { businessDetails } = this.state
                let zipcode = businessDetails.address[0] && businessDetails.address[0].country ? businessDetails.address[0].zipcode : "";
                let city = businessDetails.address[0] && businessDetails.address[0].city ? businessDetails.address[0].city : "";
                let state = businessDetails.address[0] && businessDetails.address[0].state ? businessDetails.address[0].state : "";
                let country = businessDetails.address[0] && businessDetails.address[0].country ? businessDetails.address[0].country : "";

                this.props.get_business_location_weather({
                    qcity: city,
                    qstate: state,
                    qcountry: country,
                    qzip: zipcode,
                })
            }

        }
        if (this.props.business_rating !== prevProps.business_rating) {
            const rating = [];            
            Object.keys(this.props.business_rating).map((iteam) => {
                let RatingLabel = "";
                this.props.qa_dropdown && this.props.qa_dropdown.res && this.props.qa_dropdown.res.map((element) => {
                    if (element.name === iteam) {
                        RatingLabel = element.label
                    }
                })

                if (iteam !== "avg_rating" && iteam !== "code") {
                    rating.push({
                        key: iteam,
                        value: null,
                        id: iteam.id,
                        checked: false,
                        label: RatingLabel,
                        name:RatingLabel,
                        isEnable: false
                    })
                }
            })          
            this.setState({
                businessRatingList: rating,
                businessRatingListDefaut: rating
            })
        }
    }

    clearReveiwRatingState = async () => {
        let { chartStarRatings, businessRatingList } = this.state;
        await this.setState({
            enableRating: false,
            avg_rating: 0,
            businessRatingList: this.state.businessRatingListDefaut,
            write_new_review: "",
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            searchTheater: '',
            fetchPhotos: {},
            fetchPhotosCount: 0,
            fetchVideos: {},
            fetchVideosCount: 0,
            albumType: '',
            fetchTaxonomy: [],
            businessRatingIds: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            businessRatingIds: [],
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            categoryType: "",
            captionData: "",
            uploadedId: {},
            avgRateKey:null,
            disableCreateReview:true
        })
        let itemEditSet = [];
        if (chartStarRatings !== null && Object.keys(chartStarRatings).length && itemEditSet.length == 0) {
            businessRatingList.forEach((item) => {
                businessRatingList[businessRatingList.findIndex(itemss => itemss.key == item.key)].value = null
            });
            this.setState({
                businessRatingList
            })
        }
    }

    handleSubmitTipsWarning = ({ text, type }) => {
        let { editId, businessId, businessDetails } = this.state;
        if (editId) {
            let data = {
                editform: true,
                text: text,
                tips_id: editId,
                type: type,
                businessName: businessDetails?.name
            }
            this.props.post_tips_and_warnings(data);
            setTimeout(() => {

                this.props.get_tips_and_warnings(businessId);
            }, 1500);

            if (type === "warning") this.setState({ isToggleWarning: false });
            if (type === "tips") this.setState({ isToggleTips: false });
        } else {
            this.handleEditTipsWarning({ id: businessId, text, type });
        }
    }

    handleEditTipsWarning = ({ id, text, type }) => {
        let { businessId, businessDetails } = this.state;
        let data = { "text": text, "user_entry": id, "form_type": `${type}form`, "type": type, 'businessName': businessDetails?.name };
        if (type === "warning") this.setState({ isToggleWarning: false, warningText: '' });
        if (type === "tips") this.setState({ isToggleTips: false, warningText: '' });
        this.props.post_tips_and_warnings(data);
        this.props.get_tips_and_warnings(businessId);
    }

    handleDeleteTipsWarning = (deleteId) => {
        let { businessId } = this.state;
        this.setState({ isToggleModalTrue: false, deleteItem: '' }, () => {
            this.props.delete_tips_and_warnings({ deleteId, id: businessId });
            this.props.get_tips_and_warnings(businessId);
        })
    }

    toggleModal = () => {
        this.setState({ isToggleModalTrue: !this.state.isToggleModalTrue });
    }

    handleOnConfirm = () => {
        let { deleteItem, deleteComment, deleteOffer } = this.state;

        if (deleteComment) {
            this.handleDeleteComment(deleteComment);
        }

        if (deleteItem) {
            this.handleDeleteTipsWarning(deleteItem);
        }

        if (deleteOffer) {
            this.handleDeleteOffer(deleteOffer);
        }
    }

    handleDeleteOffer = (deleteOffer) => {
        let { businessId } = this.state;
        let { id, type } = deleteOffer;

        this.props.delete_special_offer({
            id,
            listing_id: businessId,
            offer_type: type
        });
        this.setState({ isToggleModalTrue: !this.state.isToggleModalTrue });
    }

    handleDeleteComment = (id) => {
        let { viewWallUpdatesType, businessId } = this.state;
        this.setState({ isToggleModalTrue: false, modalText: '', deleteComment: null }, () => {
            this.props.delete_wall_comment({ action_id: id, business_id: businessId, filter_type: viewWallUpdatesType });
        });
    }

    setEditOffer = (item) => {
        let { offerCheck } = this.state;

        offerCheck['special_offer'] = item?.special_offer;

        offerCheck['startDate'] = 'Enter Start date';
        offerCheck['from_date'] = item?.from_date ? moment(item.from_date).toDate() : '';

        offerCheck['endDate'] = 'Enter Expiration date';
        offerCheck['to_date'] = item?.to_date ? moment(item.to_date).toDate() : '';

        offerCheck['claimDealOption'] = item?.claim_deal_link ? "Enter Link" : '';
        offerCheck['claim_deal_link'] = item?.claim_deal_link;
        offerCheck['terms_details_exclusions'] = item?.terms_details_exclusions;
        offerCheck['offer_type'] = item?.offer_type;
        offerCheck['validateForSubmit'] = false;

        this.setState({ offerCheck, isEditOffer: item });
    }

    showToastMessage = (message, time=2500) => {
        toast(message, {
            autoClose: time,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
        });  
    }

    handleSubmitOtherOffers = () => {
        let { offerCheck, otherOffers, mediaSet, isEditOffer, businessId } = this.state;
        let offerType = null;


        if (otherOffers.inStoreCouponToggle) {
            offerType = 'In Store Coupon';
        };

        if (otherOffers.isCheckInOfferToggle) {
            offerType = 'Check In Offer'
        };

        if (otherOffers.inOtherOffer) {
            offerType = 'Other Offer';
        }



        if (!isEditOffer) {
            let items = {
                offer_type: "Other Offer",
                other_offer: "other_offer",
                reviews_userentries: businessId,
                special_offer: offerCheck.special_offer,
                terms_details_exclusions: offerCheck.terms_details_exclusions,
            }

            if (!offerCheck?.special_offer && !otherOffers.inStoreCouponToggle) {
                this.showToastMessage("Offer details can not be empty.")
                return
            }

            if (offerCheck?.startDate !== '' && offerCheck?.from_date !== '') {
                items['startDate'] = offerCheck.startDate;
                items['from_date'] = moment(offerCheck.from_date).format('YYYY-MM-DD');
            } else {
                this.showToastMessage("Start Date can not be empty.")
                return
            }

            if (offerCheck?.endDate !== "" && offerCheck?.to_date !== '') {
                items['endDate'] = offerCheck.endDate;
                items['to_date'] = moment(offerCheck.to_date).format('YYYY-MM-DD');;
            } else {
                this.showToastMessage("End Date can not be empty.")
                return
            }

            if (offerCheck.claimDealOption !== '') {
                items['claimDealOption'] = offerCheck.claimDealOption;
                if (offerCheck.claimDealOption === "Enter Link") {
                    items['claim_deal_link'] = offerCheck.claim_deal_link;
                }
            } else {
                this.showToastMessage("Please select a claim deal option.")
                return
            }


            if (otherOffers.inStoreCouponToggle) {
                offerType = 'In Store Coupon';
                items.offer_type = 'In Store Coupon';
                if (items.special_offer === '') {
                    items["couponCode"] = "Upload offer image-pdf";
                    if (mediaSet.length) {
                        items['media'] = mediaSet.map(item => item.id);
                    }
                    delete items['special_offer'];
                }
            };

            if (otherOffers.isCheckInOfferToggle) {
                offerType = 'Check In Offer'
                items.offer_type = 'Check In Offer';
            };

            if (otherOffers.inOtherOffer) {
                offerType = 'Other Offer';
                items.offer_type = 'Other Offer';
            }


            this.props.post_other_offers({ data: items, listing_id: businessId, offer_type: offerType });

            setTimeout(() => {

                otherOffers.isCheckInOfferToggle = false;
                otherOffers.inStoreCouponToggle = false;
                otherOffers.inOtherOffer = false;
                this.setState({
                    otherOffers,
                    offerCheck: {
                        special_offer: "",
                        startDate: "Enter Start Date",
                        from_date: new Date(),
                        endDate: "Enter Expiration Date",
                        to_date: new Date(),
                        claimDealOption: "",
                        terms_details_exclusions: "",
                        offer_type: "",
                        other_offer: "",
                        reviews_userentries: ''
                    }
                });
            }, 3000);
        } else {
            this.putSpecialOffer({ offerType });
        }
    }


    handleChangeOffer = ({ name, value }) => {
        let { offerCheck, mediaSet, otherOffers } = this.state;

        if (name == "from_date" || name == "to_date") {
            offerCheck[name] = value;

            let to_date = null;
            let from_date = null;

            if (name == "from_date") {
                if (offerCheck.hasOwnProperty('to_date')) {
                    to_date = moment(offerCheck['to_date']).format('DD-MM-YYYY');
                    from_date = moment(offerCheck['from_date']).format('DD-MM-YYYY');

                    if (moment(to_date, 'DD-MM-YYYY').diff(moment(from_date, 'DD-MM-YYYY'), 'days') < 0) {
                        offerCheck['to_date'] = offerCheck['from_date'];
                    }
                }
            }

            if (name == "to_date") {
                if (offerCheck.hasOwnProperty('from_date')) {
                    to_date = moment(offerCheck['to_date']).format('DD-MM-YYYY');
                    from_date = moment(offerCheck['from_date']).format('DD-MM-YYYY');
                }

                if (moment(from_date, 'DD-MM-YYYY').diff(moment(to_date, 'DD-MM-YYYY'), 'days') > 0) {
                    offerCheck['from_date'] = offerCheck['to_date'];
                }
            }

        } else {
            offerCheck[name] = value;
        }


        if (!otherOffers.inStoreCouponToggle) {
            if (name === 'special_offer') {
                if (value.length) {
                    offerCheck['validateForSubmit'] = false;
                } else {
                    offerCheck['validateForSubmit'] = true;
                }
            }
        }

        if (otherOffers.inStoreCouponToggle) {

            if ((name === 'special_offer' && value.length > 0) || (mediaSet.length > 0)) {
                offerCheck['validateForSubmit'] = false;
            } else {
                offerCheck['validateForSubmit'] = true;
            }
        }

        this.setState({ offerCheck });
    }


    handleChangePeopleWorking = ({ name, value }) => {
        //    this.props.handleChangePeopleWorking({ name, value });
        let { peopleWorkingState } = this.state;
        if (name == "from_date" || name == "to_date") {
            peopleWorkingState[name] = value;

            let to_date = null;
            let from_date = null;

            if (name == "from_date") {
                if (peopleWorkingState.hasOwnProperty('to_date')) {
                    to_date = moment(peopleWorkingState['to_date']).format('DD-MM-YYYY');
                    from_date = moment(peopleWorkingState['from_date']).format('DD-MM-YYYY');

                    if (moment(to_date, 'DD-MM-YYYY').diff(moment(from_date, 'DD-MM-YYYY'), 'days') < 0) {
                        peopleWorkingState['to_date'] = peopleWorkingState['from_date'];
                    }
                }
            }

            if (name == "to_date") {
                if (peopleWorkingState.hasOwnProperty('from_date')) {
                    to_date = moment(peopleWorkingState['to_date']).format('DD-MM-YYYY');
                    from_date = moment(peopleWorkingState['from_date']).format('DD-MM-YYYY');
                }

                if (moment(from_date, 'DD-MM-YYYY').diff(moment(to_date, 'DD-MM-YYYY'), 'days') > 0) {
                    peopleWorkingState['from_date'] = peopleWorkingState['to_date'];
                }
            }

        } else {
            peopleWorkingState[name] = value;
        }





        this.setState({ peopleWorkingState });



    }


    handleSubmitPeopleWorking = () => {
        let { peopleWorkingState, businessId } = this.state;
       
        if (peopleWorkingState && Object.keys(peopleWorkingState).length > 0) {
            let items = {
                associated_with: businessId,
                character_role: peopleWorkingState.character_role,
                first_name: peopleWorkingState.first_name,
                from_date: peopleWorkingState.from_date,
                is_present: peopleWorkingState.is_present,
                last_name: peopleWorkingState.last_name,
                profession: peopleWorkingState.profession,



            }
            if (peopleWorkingState.startDate !== '') {
                items['from_date_month'] = moment(peopleWorkingState.from_date).format('MM');
                items['from_date_year'] = moment(peopleWorkingState.from_date).format('YYYY');
            }
            this.props.post_people_working({ data: items, listing_id: businessId });
            this.setState({ peopleWorkingState: {},isToggleWorkingPeople:false })
        } else {
            if (peopleWorkingState && Object.keys(peopleWorkingState).length == 0) {
                this.setState({ firstNameError: "This field is required.", lastNameError: "This field is required." })
            }
            if (peopleWorkingState.first_name && !peopleWorkingState.last_name) {
                this.setState({ lastNameError: "This field is required." })
            }
            if (!peopleWorkingState.first_name && peopleWorkingState.last_name) {
                this.setState({ firstNameError: "This field is required." })
            }

        }

    }


    deletePeople = (peopleId) => {
        let { businessId } = this.state;
        this.props.delete_people(peopleId, businessId);
    }

    getCheckOffer = () => {
        return {
            offerCheck: this.state.offerCheck,
            mediaSet: this.state.mediaSet,
            local_states: this.state
        }
    }

    setViewBsuinessAs = (item) => {
        this.setState({ viewBusinessAs: item });
    }

    itemsSetState = (items) => {

        this.setState(items);
    }

    // updateOfferDetails = () => {
    //     let data = {
    //         claim_deal_link: null,
    //         create_by_name: null,
    //         created_by: 1502,
    //         from_date: "2020-08-13",
    //         id: 292,
    //         offer_type: "Other Offer",
    //         reviews_userentries: 7799918,
    //         special_offer: "Test Offer 12345",
    //         store_name: null,
    //         terms_details_exclusions: "test",
    //         to_date: "2020-08-19"
    //     }
    // }

    // addedCommentsForOffers = () => {
    //     let data = {
    //         "292": '', // offer_name
    //         "reviews_userentries": businessId,
    //         "created_by": '', // user_id,
    //         "reviews_specialoffers": '', // offer id,
    //         "comment": '',
    //     }
    // }

    handleSubmitDiscussThisPage = () => {
        // let data = {
        //     "body": "",
        //     "title": "",
        //     "user_entry": businessId,
        //     "messagemedia_set": mediaSet
        // };
        // this.props.post_comment_on_offers(data)
    }

    toggleVideoOption = () => {
        this.setState({ showVideoMedia: !this.state.showVideoMedia });
    }

    toggleYourBusiness = () => {
        this.setState({ manageBusinessModalToggleTwo: !this.state.manageBusinessModalToggleTwo }, () => {
            if (this.state.manageBusinessModalToggleTwo) {
                this.props.getCaptcha()
            }
        })
    }

    handleNewBusinessSubmit = () => {

    }

    renderAnimatedReaction = (items) => {
        return ( // cheers_forever
            <div className="reaction-anim" hidden>
                {items.map(item => (
                    <img
                        src={require(`../../assets/images/icons/${item}.gif`)}
                        alt=""
                    />
                ))}
            </div>
        )
    }

    renderReaction = (items) => {
        return (
            <React.Fragment>
                {items.map(item => (
                    <div className="reaction-btn-group">
                        {item?.value ? (
                            <React.Fragment>
                                <button className={`reaction ${item?.name}`} title={item.labelName}>
                                    <span className="icon">
                                        <img src={require(`../../assets/images/icons/${item.name}.png`)} alt={item.labelName} />
                                    </span>
                                    <span className="name">
                                        {item.labelName}
                                    </span>
                                </button>
                                <button
                                    className="reaction hoverable"
                                    toggle={() => { this.setState({ viewPeopleWhoReactedModalToggle: !this.state.viewPeopleWhoReactedModalToggle }) }}>
                                    <span className="count">
                                        {item?.count ? item?.count : 0}
                                    </span>
                                    <div className="on-hover-appear tooltiptext reaction-tooltip">
                                        <p>WikiReview Investor</p>
                                        <span>and 1 more</span>
                                    </div>
                                </button>
                            </React.Fragment>
                        ) : null}
                    </div>
                ))}
            </React.Fragment>
        )
    }

    ratingChanged = async (newRating, name,key) => {       
        let name_modify = '';
        this.VoteData = true;
        this.setState({ratingOn:true})        
        if(name!=="avg_rating"){
            name_modify = key
            const tempArray = [...this.state.businessRatingList]
            const ratedCategory = tempArray.map(item=>{
                if (item.name === name) {
                    return { key: key, name: name,label:name, value: newRating, isEnable: false }
                } else {
                    return { key: item.key, name: item.label,label:item.label, value: item.value, isEnable: false }
                }               
            })
          
            this.setState({businessRatingList:ratedCategory})
        }else{
            name_modify = "avg_rating"            
            let filteredReviewCategories = [];            
            this.setState({ avg_rating: newRating, enableRating: true, reviewCategoriesList: filteredReviewCategories })
            
        } 
        this.getSecurityHashToken(name_modify,newRating)
    }
    ratingChangedMenuItem = async (newRating,menuId)=>{
        const businessId = this.state.businessId
        const menuItem = this.state.activeMenuType
        callApi(`/api/menurating/?value=${newRating}&menu_id=${menuId}&rating_key=${this.state.avgRateKey}`, "POST", []).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {                
               this.fetchRateMenuItemContent({businessId,menuItem})
            }
        });
    }

    ratingChangedForCheck = (checked, data) => {

        if (checked) {
            this.state.businessRatingIds.push(data.id);
        } else {
            let el = this.state.businessRatingIds.find(
                (itm) => itm === parseInt(data.id)
            );
            if (el)
                this.state.businessRatingIds.splice(
                    this.state.businessRatingIds.indexOf(el),
                    1
                );
        }
        /*let { businessRatingIds,reviewsCategories } = this.state
       reviewsCategories[reviewsCategories.res.findIndex(item => item.label === data.label)].Checked = checked
       this.setState({
           businessRatingList
       })*/
    }

    handelChangeReview = (e) => {
        // 'QualityofEquipmentCheck'
        this.setState({
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        }, () => {

            if(!this.state.isadhere){
                this.setState({ enableErrorForReview: true }) 
            }

            if (this.state.write_new_review?.trim()?.length >= 50) {
                this.setState({ enableErrorForReview: false })
            }

            if (this.state.write_new_review?.trim()?.length < 50) {
                this.setState({ enableErrorForReview: true })
            }

        });
    }

    handelChangeReviewVideo = (e) => {
        // 'QualityofEquipmentCheck'
        this.setState({
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        }, () => {


        });
    }


    publishPost = async (dataList) => {

        let { menuMediaList, menuMediaListMedia, reviewAnonymous, reviewDynamicItems, avg_rating, mediaSetReview, write_new_review, writeAReviewTextVideo, businessId, businessRatingIds, shocialNetworkSharing, isadhere, stepTwoUploadedVideo, embedBody } = this.state
        const { publish_post, twitter_connection, facebook_connection } = this.props;
        let mediaSetDataItems = [];

        if (shocialNetworkSharing === true) {
            if (twitter_connection === false || facebook_connection === false) {
                this.setState({
                    shareSocialMediaModalToggle: true
                })
                return;
            }
        }
        if (stepTwoUploadedVideo?.length == 0) {
            if (write_new_review?.trim()?.length < 50) {
                this.setState({ enableErrorForReview: true });
                return
            }
        }

        if (mediaSetReview?.length) {
            mediaSetDataItems = mediaSetReview.map(i => i["id"]);
        }

        if (stepTwoUploadedVideo?.length) {
            let itemsGet = stepTwoUploadedVideo.map(data => data.id);
            mediaSetDataItems = mediaSetDataItems.concat(itemsGet);
        }

        const data = {
            associated_to: businessId,
            avg_rate_val: avg_rating,
            followup: false,
            followup_avg_rate: false,
            is_anonymous: reviewAnonymous,
            is_draft: dataList === "draft",
            media: mediaSetDataItems,
            // review: write_new_review,
            review: writeAReviewTextVideo && writeAReviewTextVideo != "" ? writeAReviewTextVideo : write_new_review?.trim(),
            review_rating_category: businessRatingIds,
            review_voting_key: this.state.avgRateKey,
            social_broadcast: shocialNetworkSharing == true ? true : false,
            social_broadcast_facebook: shocialNetworkSharing == true ? true : false,
            social_broadcast_twitter: shocialNetworkSharing == true ? true : false,
            tips_guide: isadhere
        }

        if(!!Object.keys(menuMediaListMedia).length){          
        data["menu_media_list"] = Object.keys(menuMediaListMedia)?.map(menuItem=>{
            return {
                "type": "media",
                "entries": this.state.businessId,
                "userentries_id": this.state.businessId,
                "taxonomy_id": "",
                "menu": +menuItem,
                "multiuploaderfile": menuMediaListMedia[menuItem]
            }
        })
        }
       
        if (embedBody?.length) {
            data['embed_body'] = embedBody.map(item=>{
                return {
                    url: item.url,
                    thumbnail:item.thumbnail
                }
            });                
        } 

        await publish_post(data, businessId);       
        await this.clearReveiwRatingState();
      
            const rating = [];          
            Object.keys(this.props.business_rating).map((iteam) => {
                let RatingLabel = "";
                this.props.qa_dropdown && this.props.qa_dropdown.res && this.props.qa_dropdown.res.map((element) => {
                    if (element.name === iteam) {
                        RatingLabel = element.label
                    }
                })

                if (iteam !== "avg_rating" && iteam !== "code") {
                    rating.push({
                        key: iteam,
                        value: null,
                        id: iteam.id,
                        checked: false,
                        label: RatingLabel,
                        isEnable: false
                    })
                }
            })          
            this.setState({
                businessRatingList: rating,
                businessRatingListDefaut: rating
            })
        
        // await this.fetchDataFromHtmlForm({ businessId });
        this.setState({ viewEditReviewStep: 'writeReview', embedBody: [], reviewDynamicItems: {}, enableErrorForReview: true, stepTwoUploadedVideo: [],disableCreateReview:true });
        // await get_business_detail(businessId);
    }

    handleNewDiscussion = async () => {
        let { dicussionThisBody, dicussionThisText, mediaSet, businessId } = this.state;
        let data = {
            body: dicussionThisBody,
            messagemedia_set: mediaSet.length ? mediaSet.map(it => it.id) : [],
            title: dicussionThisText,
            user_entry: businessId
        };
        await this.props.post_discuss_this_page(data);

        let url = `/api/discussion/?entity=${businessId}&page=1`;
        await this.props.get_discussions(url);

        await this.setState({ mainViewType: "discussion", dicussionThisBody: '', dicussionThisText: '', createNewDisModalToggle: false, mediaSet: [], stepTwoUploadedVideo: [] });

        setTimeout(() => {

            window.scrollTo(0, 700)
        }, 1500);

    }

    putSpecialOffer = ({ offerType }) => {
        let { offerCheck, isEditOffer, businessId, otherOffers } = this.state;
        let data = {
            claimDealOption: offerCheck.claimDealOption, // "Not Applicable",
            endDate: offerCheck.endDate, // "Enter Expiration date",
            from_date: moment(offerCheck.from_date).format('MM-DD-YYYY'), //"2020-08-29",
            id: isEditOffer.id,
            is_edit: true,
            offer_type: offerCheck.offer_type,//"Other Offer",
            other_offer: offerType, //offerCheck.other_offer,// "other_offer",
            reviews_userentries: +businessId,
            special_offer: offerCheck.special_offer, //"New Offer",
            startDate: offerCheck.startDate, // "Enter Start date",
            terms_details_exclusions: offerCheck.terms_details_exclusions, //"Terms, Details and Exclusions!!!!",
            to_date: moment(offerCheck.to_date).format('MM-DD-YYYY'), //"2020-08-29",
        }

        if (offerCheck.claimDealOption === "Enter Link") {
            data['claim_deal_link'] = offerCheck.claim_deal_link;
        }

        otherOffers.isCheckInOfferToggle = false;
        otherOffers.inStoreCouponToggle = false;
        otherOffers.inOtherOffer = false;
        this.setState({ otherOffers }, () => {
            this.props.put_special_offer({
                id: isEditOffer.id,
                data,
                listing_id: businessId,
                offer_type: offerCheck.offer_type
            });
        });
    }

    getItems = () => {
        return this.state.otherOffers;
    }

    setToggleForChildComponents = ({ toggle, text, id, type }) => {
        this.setState({
            isToggleModalTrue: toggle,
            modalText: text,
            deleteOffer: { id, type }
        });
    }

    removeSelectedImage = (e, index) => {
        let { latestUploadedData, selectedFilesPreview, files, uploadedId, fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state

        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        this.props.single_delete_from_upload(id);

        // delete items from array
        selectedFilesPreview.splice(index, 1);
        files.splice(index, 1); // deleted this because it concat from the upload function.

        // deleted items from json
        delete latestUploadedData[index];
        delete uploadedId[index];
        delete fetchTaxonomyListingImages[index];
        delete fetchTaxonomyListingVideos[index];

        // deleted items from json
        if (latestUploadedData[index] == undefined) {
            let newItems = [];

            // turn json to array for indexes
            Object.values(latestUploadedData).forEach(it => { newItems.push(it) });

            // turn array to json for indexes
            if (newItems && Array.isArray(newItems) && newItems.length) {
                let newItemsJson = {};
                newItems.forEach((nitem, index) => {
                    newItemsJson[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJson).length) {
                    latestUploadedData = newItemsJson;
                }
            }
        }

        if (uploadedId[index] == undefined) {
            let newItemsf = [];

            // turn json to array for indexes
            Object.values(uploadedId).forEach(it => { newItemsf.push(it) });

            // turn array to json for indexes
            if (newItemsf && Array.isArray(newItemsf) && newItemsf.length) {
                let newItemsJsond = {};
                newItemsf.forEach((nitem, index) => {
                    newItemsJsond[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsond).length) {
                    uploadedId = newItemsJsond;
                }
            }
        }

        if (fetchTaxonomyListingImages[index] == undefined) {
            let newItemsImages = [];

            // turn json to array for indexes
            Object.values(fetchTaxonomyListingImages).forEach(it => { newItemsImages.push(it) });

            // turn array to json for indexes
            if (newItemsImages && Array.isArray(newItemsImages) && newItemsImages.length) {
                let newItemsJsonImages = {};
                newItemsImages.forEach((nitem, index) => {
                    newItemsJsonImages[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsonImages).length) {
                    fetchTaxonomyListingImages = newItemsJsonImages;
                }
            }
        }

        if (fetchTaxonomyListingVideos[index] == undefined) {
            let newItemsVideos = [];

            // turn json to array for indexes
            Object.values(fetchTaxonomyListingVideos).forEach(it => { newItemsVideos.push(it) });

            // turn array to json for indexes
            if (newItemsVideos && Array.isArray(newItemsVideos) && newItemsVideos.length) {
                let newItemsJsonVideo = {};
                newItemsVideos.forEach((nitem, index) => {
                    newItemsJsonVideo[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsonVideo).length) {
                    fetchTaxonomyListingVideos = newItemsJsonVideo;
                }
            }
        }

        this.setState({ selectedFilesPreview, latestUploadedData, files, uploadedId, fetchTaxonomyListingVideos, fetchTaxonomyListingImages });
    }

    renderPhotosAndVideos = (iteamImage, isTrue) => {
        if (iteamImage?.url) {
            let items = iteamImage?.url.split('.');
            let lastItem = items[items.length - 1];
            if (['webm', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov'].includes(lastItem)) {
                return (
                    <video
                        autoPlay
                        muted
                        className={isTrue ? "img-fluid img-thumbnail" : "img-thumbnail uploaded-img-video-thumbnail"}
                        controls>
                        <source src={iteamImage?.url} type={`video/${lastItem}`}></source>
                    </video>
                )
            } else {
                return (
                    <img
                        className={isTrue ? "img-fluid img-thumbnail" : "img-thumbnail uploaded-img-video-thumbnail"}
                        src={iteamImage.url}
                        alt=""
                    />)
            }
        }
    }

    handleHoverChange = (rate) => {
        this.enableRatingText = rate;
    }

    toggleCategory(item) {
        this.setState(prevState => ({
            [item]: !prevState[item]
        }));
    }

    onMouseEnterCategory(item) {
        this.setState({ [item]: true });
    }

    onMouseLeaveCategory(item) {
        this.setState({ [item]: false });
    }

    handleOnChangeShareByEmail = (e) => {
        let errors = { ...this.state.shareError };
        if (e.target.value) {
            delete errors[e.target.name];
        }

        this.setState({
            [e.target.name]: e.target.value,
            shareError: errors
        });
    }

    handleOnSubmitShareByEmail = () => {
        let errors = { ...this.state.shareError };

        if (this.state.shareName === '') {
            errors.shareName = 'This field is required!';
        }

        if (this.state.shareEmail === '') {
            errors.shareEmail = 'This field is required!';
        } else {
            const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!reg.test(String(this.state.shareEmail).toLowerCase())) {
                errors.shareEmail = 'Please enter a valid email!';
            }
        }

        if (errors && Object.keys(errors).length > 0) {
            this.setState({ shareError: errors });
        } else {
            let key = 'listing_id';

            let url = `/api/shareByemail/`;

            const data = {
                [key]: this.state.shareId,
                mix_uid: `${this.state.shareEmail.split("@")[0]}_${this.state.shareId}_1597643911786`,
                email: this.state.shareEmail,
                user_name: this.state.shareName,
                listing_type: "business"
            };

            this.props.share_by_email(url, data);

            this.setState({
                shareByEmailModalToggle: false,
                shareName: '',
                shareEmail: '',
                shareError: {}
            });
        }
    }

    handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id
        });
    }

    handleFeedReactions = (reaction, id) => {
        const params = {
            type: reaction,
            review: id,
        };
        this.props.updateFeedReactions(params);
    };

    handleOnClickAddToList = (type, status, obj) => {
        if (!this.checkIfLoggedIn()) {
            this.forceToggleLoginModal();
            return false;
        }
        const { setWallDataItems, viewWallUpdatesType } = this.state;
        if (setWallDataItems &&
            setWallDataItems[viewWallUpdatesType] &&
            setWallDataItems[viewWallUpdatesType].results && setWallDataItems[viewWallUpdatesType].results.length > 0 && obj && obj.target && obj.target.id) {

            let message = '',
                url = '',
                nextUrl = '',
                key = '',
                item = obj,
                data = { type };

            let prof_list = setWallDataItems[viewWallUpdatesType].results;

            if (type.includes('want')) {
                key = 'want_this';
            } else if (type.includes('favourite')) {
                key = 'favourite';
            } else {
                key = 'watch_this';
            }

            if (status) {
                message = `${item?.target?.name} has been removed from your ${type}`;
                url = `/api/pins/${item?.target?.id}/?type=${type}`;
                this.props.remove_from_list(url, message, nextUrl);
                item.target.userpins[key] = false;
            } else {
                data.user_entry = item.target.id;
                message = `${item?.target?.name} has been added to your ${type}`;
                url = `/api/pins/`;
                this.props.add_to_list(url, data, message, nextUrl);
                item.target.userpins[key] = true;
            }
            prof_list = prof_list.map(itemValue => {
                if (itemValue.id === item.id) {
                    return item;
                }
                return itemValue;
            });

            setWallDataItems[viewWallUpdatesType].results = prof_list;

            this.setState({
                setWallDataItems
            });
        }
    }




    forceToggleLoginModal = () => {
        this.setState(prevState => ({
            ...this.prevState,
            forceShowLoginModal: !prevState.forceShowLoginModal
        }));
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const { my_profile } = this.props;
        if (my_profile && my_profile.id && authId && (authId == my_profile.id)) {
            return true;
        } else {
            return false;
        }
    }



    handleOnSubmitCiteSource = (e) => {
        e.preventDefault();
        let {
            cite_source_link,
            cite_source_supporting_file,
            cite_source_radio,
        } = this.state;

        let data1 = {};


        data1.cite_source_link = cite_source_link
        data1.cite_source_supporting_file = cite_source_supporting_file
        data1.cite_source_radio = cite_source_radio
        this.props.add_cite_source(data1);
    };

    handleOnFileUploadChangeCorporate = (event, uploadType, uploadSubType) => {

        let uploadFile = event.target.files;


        let url = "";
        let id = this.props.bussiness_details?.id

        url = url = `/upload/multiuploader/?img=corporate`;
        // if (uploadType === 'companyLogo') {
        //   url = `/upload/multiuploader/?img=corporate`;
        // } else if (uploadType === 'freshProfilePic') {
        //   url = `/upload/multiuploader/?album=&instance=bizimage&image=undefined&doc=undefined`;
        // } else if (uploadType === 'UploadBackgroundVideoOrImage') {
        //   url = `/upload/multiuploader/?album=profile&img=corporate`
        // } else {
        //   url = `/upload/multiuploader/?album=profile&img=corporate`
        // }

        var i = 0;
        let data = new FormData();
        data.append("file", uploadFile[i]);
        callApi(
            url,
            "POST",
            data,
            true
        ).then((response) => {

            if (response) {
                let media_id = response.id;



                this.setState({
                    ...this.state,
                    cite_source_supporting_file: media_id,
                    // selectBranchModal: true,
                });

                // this.setState({
                //   ...this.state,
                //   mediaId: media_id,
                //   // selectBranchModal: true,
                // }, 

                // () => {
                //   if (uploadType === 'freshProfilePic') {
                //     if (uploadSubType === 'branchLogo') {
                //       this.setState({
                //         freshprofileBranchLogoUrl: response.url,
                //         freshProfileBranchLogoId: response.id
                //       })

                //     } else if (uploadSubType === 'branchBanner') {
                //       this.setState({
                //         freshProfileBranchBannerUrl: response.url,
                //         freshProfileBranchBannerId: response.id
                //       })

                //     } else {
                //       this.setState({
                //         freshprofilePicUrl: response.url,
                //         freshProfilePicId: response.id
                //       })
                //     }
                //   } else {
                //     const data = {
                //       "corporate_listing": [id],
                //       "media_id": `${this.state.mediaId}`
                //     }
                //     if (uploadType === 'companyLogo') {
                //       this.props.upload_corporate_media(data, 'companyLogo', id);
                //     } else if (uploadType === 'UploadBackgroundVideoOrImage') {
                //       this.props.upload_corporate_media(data, 'UploadBackgroundVideoOrImage', id);
                //     } else {
                //       this.props.upload_corporate_media(data);
                //     }
                //   }
                // });
            }
        });
    };


    fetchPeopleWorkingList = (type) => {
        let modal_working_employee = this.state.workingEmployee;
        let is_present;
        if (type == 'present') {
            is_present = true
        } else {
            is_present = false
        }

        if (modal_working_employee && Array.isArray(modal_working_employee.results) && modal_working_employee?.results?.length) {

            return modal_working_employee.results.map(item => {
                if (item.reviews_professional?.is_present == is_present) {

                    return (

                        <li className="d-flex flex-nowrap gap-2 py-2">
                            <div>
                                <img src="https://userdatawikireviews.s3.amazonaws.com/images/friend-photo.png" alt="" />
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex">
                                    <Link to="#" className="text-secondary-dark fs-14 font-weight-bold">
                                        {item?.reviews_professional?.firstname}
                                    </Link>

                                    <div className="text-secondary-dark fs-13">   {item?.reviews_professional?.profession?.title}</div>
                                    <DeleteBtn color="gold"
                                        hidden={item?.is_allow_to_delete_user == false ? true : false}
                                        onClick={() => {
                                            this.deletePeople(item?.reviews_professional?.id);
                                        }}
                                    />

                                </div>
                                <div className="my-2">
                                    <Progress value={item?.ratings_by_employer?.recommend_count?.percentage} className="progress-secondary" style={{ height: '14' }}>{item?.ratings_by_employer?.recommend_count?.percentage}%</Progress>
                                </div>
                                <span className="text-secondary-dark fs-13">
                                    <span>{item?.ratings_by_employer?.recommend_count?.percentage}% approval </span>
                                </span>

                                {/* Recommend this person */}
                                {/*
                                <div className="mt-2">
                                    <div className="fs-15 text-secondary-dark">
                                        <span>
                                            Would you recommend this person ?
                                        </span>
                                    </div>
                                    <div>
                                        <span className="text-dark font-weight-bold fs-14" role="button">
                                            Yes
                                        </span>
                                        <span className="mx-2">|</span>
                                        <span className="text-dark font-weight-bold fs-14" role="button">
                                            No
                                        </span>
                                    </div>
                                </div>
                                  */}

                                <div className="text-right">
                                    <span onClick={() => {
                                        this.itemsSetState({ tipsText: '', isToggleRecommendPeople: true })
                                    }} className="text-secondary fs-14 font-weight-bold" role="button">Recommend {item?.reviews_professional?.firstname}</span>
                                </div>
                            </div>
                        </li>
                        // }
                    )
                }
            })
        }

    }

    checkColorTrue = (item) => {
        const { businessRatingList } = this.state;       
        return businessRatingList.filter(itm => itm.key === item)?.[0]?.isEnable || businessRatingList.filter(itm => itm.key === item)?.[0]?.value === 0
    }

    onHoverEnableDisable = (name, value) => {
        const tempArray = [...this.state.businessRatingList]
        const ratedCategory = tempArray.map(item => {
            return { ...item, isEnable: item.key === name ? value : false }
        })
        this.setState({ businessRatingList: ratedCategory })
    }

    findAndSetItem = (item) => {
        let item_modify = '';
        if (item) {
            item_modify = item.replace(/\s/g, '');
            if (item_modify.includes('/')) {
                item_modify = item_modify.replace(/\//g, '')
            }
        }

        let { businessRatingList } = this.state;
        let data = businessRatingList.findIndex(data => {

            let name_modify = '';
            if (data.key) {
                name_modify = data.key.replace(/\s/g, '');
                if (name_modify.includes('/')) {
                    name_modify = name_modify.replace(/\//g, '')
                }
            }

            return name_modify == item_modify;
        });

        if (data > -1) {
            let returnValue = businessRatingList[data];
            if (returnValue?.value > -1) {
                return returnValue?.value;
            }
        }
    }

    getSecurityHashToken = (keyName, newRating) => {
        const url = "/ratings/security_hash/"
        const { categoryItemQueryParams, avgRateKey } = this.state;
        let formDataSecurity = new FormData();

        formDataSecurity.append('object_pk', this.state.businessId);
        formDataSecurity.append('content_type', categoryItemQueryParams.content_type);
        formDataSecurity.append('key', keyName);
        formDataSecurity.append('timestamp', Date.now());
        callApi(url, 'POST', formDataSecurity).then((res) => {
            this.setState({ ratingOn: false })
            if (res.code <= 201) {
                let formDataVote = new FormData();
                formDataVote.append('csrfmiddlewaretoken', res?.csrfmiddlewaretoken);
                formDataVote.append('score', newRating);
                formDataVote.append('content_type', res?.content_type);
                formDataVote.append('object_pk', res?.object_pk);
                formDataVote.append('key', keyName);
                formDataVote.append('timestamp', res?.timestamp);
                formDataVote.append('security_hash', res?.security_hash);
                formDataVote.append('rate_unq_key', !!!avgRateKey ? res?.rate_unq_key : avgRateKey);
                this.save_ratings_vote(formDataVote, res?.rate_unq_key);
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        categoryItemQueryParams: {
                            ...prevState.categoryItemQueryParams,
                            csrfmiddlewaretoken: res?.csrfmiddlewaretoken,
                            rate_unq_key: res?.rate_unq_key,
                            security_hash: res?.security_hash,
                            timestamp: res?.timestamp
                        }
                    }
                })
            }
        });
    }
    save_ratings_vote = async (data,rateKey) => {
        let url = '/ratings/vote/';
        try {
            const response = await callApi(url, 'POST', data);
            if (response?.code == 200) {
                if(response.key==="avg_rating"){
                    this.setState({voteScore:response.vote_score,avgRateKey:rateKey})
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    removeEmbeddedFromUploaded = (index,item) => {               
        let { embedBody } = this.state;
        if (index > -1) {
            embedBody.splice(index, 1);
        }
        this.setState({ embedBody });
        // this.props.removeUploadedMediaSet(item);
    }

    handleDeleteMenuMedia = (menuId, mediaId)=>{
        const {menuMediaListMedia} = this.state
        let menuMediaListMediaCopy = {...menuMediaListMedia}
        const mutateArray = menuMediaListMediaCopy?.[menuId]
        const updatedArray = mutateArray.filter(item=>item.id!==mediaId)
        menuMediaListMediaCopy[menuId] = updatedArray
        this.setState({menuMediaListMedia:menuMediaListMediaCopy})

    }
  
    render() {

        let {
            enableErrorForReview,
            businessId,
            selectedFilesPreview,
            temperature_C_Blue,
            temperature_F_Blue,
            tempIcon,
            latestUploadedData,
            fetchTaxonomy,
            mediaSet,
            mediaSetReview,
            embeddedLink,
            embedBody,
            showVideoMedia,
            viewWallUpdatesType,
            businessValues,
            write_new_review,
            enableRating,
            shocialNetworkSharing,
            reviewAnonymous,
            wallReplyVisibility,
            businessRatingList,
            chartStarRatings,
            setWallDataItems,
            wallReplyText,
            wallCommentEdit,
            reviewsCategories,
            isadhere,
            fetchTaxonomyListingImages,
            fetchTaxonomyListingVideos,
            uploadedId,
            cattaxListing,
            addressnull,
            myProfile,
            showRevTips,
            get_temp,
            weather,
            working_employee,
            workingEmployee,
            writeAReviewTextVideo,
            ratingOn,rateMenu,
            rateMenuContent,            
            stepTwoUploadedVideo,
            wallLoading,
            showCreateReview
        } = this.state;

        
        const responsive = {
            videoModal: {
                mobile: {
                    breakpoint: { max: 9999999, min: 0 },
                    items: 1
                }
            },
            viewGalleryImages: {
                desktop: {
                    breakpoint: { max: 9999999, min: 1200 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1199, min: 576 },
                    items: 2
                },
                mobile: {
                    breakpoint: { max: 575, min: 0 },
                    items: 1
                }
            }
        }

        const { captcha_items, loggedInUsername, my_profile, auth_data } = this.props;
        let { setRatingFrom, businessDetails, simillarBusiness, tipsAndWarnings, isToggleWarning, isToggleTips, tipsText, warningText, isToggleModalTrue, modalText, offerCheck, enableStickyHeader, peopleWorking, isToggleWorkingPeople, isToggleRecommendPeople, enableHoverBanIcon } = this.state;
        let business_details = businessDetails;
        let total_rating = businessDetails?.total_rating;

        let totalLength = selectedFilesPreview.length;
        let objectKeys = [];
        let objectLength = 0;
        let calculatedPercentage = 0;
        let uploadFinish =false;
        if (Object.keys(latestUploadedData).length > 0) {
            objectKeys = Object.keys(latestUploadedData);
            objectLength = objectKeys.length;
            calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
            if(Object.values(latestUploadedData).every(item=>item.isUpload)){
                uploadFinish=true
            }
        };
        const authId = localStorage.getItem('profileId');
        return (


            <div className="wr-movie-top-banner step-1">
                 <HelmetMetaData
                    title={getCategoryDetailTitle("business", this.state.businessDetails?.name)}
                    description={getCategoryDetailDescription(this.state.businessDetails?.name)}
                />

            {this.state.openVerifyModal ? (
                    <div className="top-popup _fixed-header show">
                        <div className="py-4 px-4 bg-primary text-white fs-16">
                            {/* <div className=""> */}
                            <button
                                className="btn btn-sm fs-16 p-0 float-right"
                                aria-label="Close"
                                onClick={() => {
                                    localStorage.removeItem("openModal", false);
                                    this.setState({ openVerifyModal: false });
                                }}

                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            {/* </div> */}

                            {!this.state.verifyActionTaken ? (
                                <div>
                                    <div className="text-center fs-14 font-weight-bold mb-0 lh-1-3 d-flex justify-content-center align-items-center">
                                        <span>{"Help Verify this business. Cast your vote:"}</span>
                                        <span className="ml-2 mr-3">
                                            <span className="d-inline-block p-1 align-bottom" role="button" title="Down Vote">👎</span><span className="d-inline-block p-1 align-top" role="button" title="Up Vote" onClick={() => this.setState({ verifyActionTaken: true })}>👍</span>
                                        </span>
                                        <span className="text-dark">{'(Help us remove fraudulent/outdated listings.)'}</span>
                                    </div>
                                </div>
                            ) :
                                (
                                    <div className="fs-14 text-center">
                                        <div style={{ maxWidth: 400, marginLeft: 'auto', marginRight: 'auto' }}>
                                            <div className="font-weight-bold mb-4">
                                                You've verified this listing! <span className="ml-2">👍</span>
                                            </div>

                                            <div className="text-dark font-weight-bold mb-3">Please cite sources</div>
                                            <Row form>
                                                <Col>
                                                    <FormGroup>
                                                        <AvForm>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText className="text-grey"><FontAwesomeIcon icon="link" /></InputGroupText>
                                                                </InputGroupAddon>
                                                                <AvField
                                                                    className="primary ph-grey"
                                                                    bsSize="sm"
                                                                    type="text"
                                                                    id="cite_source_link"
                                                                    name="cite_source_link"
                                                                    value={this.state.name}
                                                                    onChange={this.handleChange}
                                                                    placeholder="Link i.e. http: wikireviews.com"
                                                                    validate={{ url: { value: true, errorMessage: "Enter a valid url." }, }}
                                                                />
                                                            </InputGroup>
                                                        </AvForm>
                                                        <span style={{ color: 'red', fontSize: "13px" }}>
                                                            {/* {this.state.nameError} */}
                                                        </span>
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col xs="auto">
                                            <Button color="text-dark" size="sm"><FontAwesomeIcon icon="search" /></Button>
                                        </Col> */}
                                            </Row>
                                            <Row form>
                                                <Col xs={12}>
                                                    <FormGroup>
                                                        <CustomInput className="primary ph-grey" type="file" bsSize="sm" onChange={this.handleOnFileUploadChangeCorporate} accept="image/*" id="uploadSupportingFile" name="uploadSupportingFile"


                                                            label={(
                                                                <div class="text-left text-grey font-weight-normal">
                                                                    <FontAwesomeIcon icon="paperclip" className="mr-3" /> Upload supporting file
                                                                </div>
                                                            )} />
                                                        {/* <span className="text-danger fs-14 ff-alt">{this.state.nameError}</span> */}

                                                        {/* <div className="d-flex flex-nowrap mx-n2 align-items-center mt-2 text-left">
                                                    <div className="px-2">
                                                        <FontAwesomeIcon icon="paperclip" />
                                                    </div>
                                                    <div className="px-2 text-truncate flex-grow-1">
                                                        fkleafjklaefleakf.jpg
                                                    </div>
                                                    <div className="px-2">
                                                        <DeleteBtn color="gold" />
                                                    </div>
                                                </div> */}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup check className="justify-content-start w-100 p-0 text-left">
                                                <Label className="w-100 d-flex justify-content-start text-dark font-weight-bold">Can't cite a source? Please choose</Label>
                                                <Label className="ff-base font-weight-normal w-100 d-flex justify-content-start text-dark pl-4" check>
                                                    <Input type="radio"
                                                        onChange={this.handleChange}
                                                        value="content_from_personal_experience"
                                                        name="cite_source_radio"
                                                        id="cite_source_radio1"
                                                    />{' '}
                                                    {'Content from personal experience'}
                                                </Label>
                                                <Label className="ff-base font-weight-normal w-100 d-flex justify-content-start text-dark pl-4" check>
                                                    <Input type="radio"
                                                        onChange={this.handleChange}
                                                        value="references_do_not_exist"
                                                        name="cite_source_radio"
                                                        id="cite_source_radio2" />{' '}
                                                    {'References do not exist'}
                                                </Label>
                                            </FormGroup>
                                            <div className="d-flex justify-content-center mt-4">
                                                <Button color="text-dark" className="ml-2" disabled={this.state.submitDisabled} onClick={(e) => this.handleOnSubmitCiteSource(e)} >Apply</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                ) : (
                        ""
                    )}
                {/* Show/hide on scroll */}
                <div hidden={!enableStickyHeader}>
                    <div className="bg-darker-shaded text-white ff-alt fs-13 p-2 fixed-top" style={{ top: '45px' }}>
                        <Container>
                            <div className="d-flex mx-n2">
                                <div className="px-2">
                                    <div className="topbar-main-img-holder">
                                        <img
                                            className="img-fluid"
                                            onError={(error) =>
                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                            }
                                            src={businessDetails?.listing_logo_pic && businessDetails?.listing_logo_pic[0] && businessDetails?.listing_logo_pic[0].location ? businessDetails?.listing_logo_pic[0].location : ''}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="px-2 mt-2 d-flex">
                                    <div className="d-flex mr-1">
                                        <div className="d-flex">
                                            <div>
                                                <h2 className="fs-20 text-uppercase">{businessDetails?.name || ''}</h2>
                                            </div>
                                            <div className="ml-3 align-self-end" hidden>
                                                <ul className="list-unstyled d-flex flex-wrap mx-n2">
                                                    <li className="text-uppercase font-weight-bold px-1">
                                                        <span>Food, Yogurt</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="ml-3 align-self-end">
                                            <ul className="list-unstyled d-flex flex-wrap mx-n2">
                                                <li className="text-uppercase font-weight-bold px-1">
                                                    <span>&nbsp;</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={businessDetails?.total_rating && businessDetails?.total_rating?.avg_rating[0] ? `https://userdatawikireviews.s3.amazonaws.com/images/star/white/${businessDetails?.total_rating?.avg_rating[0]}` : ''}
                                                className="img-fluid mr-2"
                                                onError={(error) =>
                                                    (error.target.src = "https://userdatawikireviews.s3.amazonaws.com/images/star/white/norating.png")
                                                }
                                                alt={`${businessDetails && businessDetails.total_rating && businessDetails.total_rating.avg_rating[1] ? businessDetails.total_rating.avg_rating[1] : 'no-rating'}`}
                                            />
                                            <span> {businessDetails?.total_rating?.total_review} {getReviewSuffix(businessDetails?.total_rating?.total_review)}</span>
                                        </div>

                                    </div>
                                </div>


                                <div className="px-2 mx-auto align-self-start">
                                    <div className="d-flex mt-2">
                                        {weather && get_temp ?
                                            <div className="mr-2">
                                                <div>
                                                    <img width="24"
                                                        alt="tempIcon"
                                                        onError={(error) =>
                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/weather-icons/white_color_icons/png/night/113.png`)
                                                        }
                                                        src={`https://userdatawikireviews.s3.amazonaws.com${tempIcon}`}
                                                    />
                                                    <span>{get_temp == 'c' ? `${temperature_C_Blue} °C` : `${temperature_F_Blue} °F`}</span>
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                        <div>
                                            <span>
                                                {businessDetails &&
                                                    businessDetails.address &&
                                                    businessDetails.address[0] ?
                                                    <span className="fs-12 font-weight-normal lh-1-2">
                                                        <span className="d-block">
                                                            {businessDetails?.address[0].address1}
                                                        </span>
                                                        <span className="d-block">
                                                            {businessDetails.address[0].city}, {businessDetails.address[0].state} {businessDetails.address[0].zipcode}
                                                        </span>
                                                        <span className="d-block text-uppercase">
                                                            {businessDetails.address[0].country}
                                                        </span>
                                                    </span>
                                                    :
                                                    ''
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.uploadMediaModal}
                    toggle={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal }, () => { this.clearMediaDispatch() })}>
                    <ModalHeader toggle={() => { this.handleCloseAndFetch() }}>UPLOAD MEDIA</ModalHeader>
                    <ModalBody className="p-3">
                        <Row>
                            <Col xs={"auto"}>
                                <div className="bg-light h-100 p-3">
                                    <Nav tabs className="flex-column active-transparent border-0 m-0">
                                        <NavItem className="text-left">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "upload"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "upload" })
                                                }}
                                            >
                                                {'Upload'}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "gallery"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "gallery" }, () => { this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true }) })
                                                }}
                                            >
                                                {'Gallery'}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0" hidden={this.state.uploadType==="menuItem"}>
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "embed"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "embed" })
                                                }}
                                            >
                                                {'Embed'}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Col>
                            <Col>
                                <TabContent activeTab={this.state.selectedUpMediaType}>
                                    <TabPane tabId="upload">
                                        {selectedFilesPreview && selectedFilesPreview.length === 0 ?
                                            <div className="mb-3 type-file-block bg-light">
                                                <Input
                                                    type="file"
                                                    name="upload_media_file"
                                                    id="uploadFilesFromSystem"
                                                    accept="image/*, video/*"
                                                    onChange={this.handleOnFileUploadChange}
                                                    multiple
                                                />
                                                <Label for="uploadFilesFromSystem">
                                                    <div className="type-bg"></div>
                                                </Label>
                                            </div>
                                            : null}
                                        <div
                                            className="text-right"
                                            style={{
                                                maxWidth: "120px",
                                                margin: "0 1rem 1rem auto",
                                            }}>
                                             <div className="text-center mb-1 small">
                                            {parseInt(this.props.uploadPercentage) === 100 && uploadFinish  ? (
                                                <div className="text-success">
                                                    <FontAwesomeIcon
                                                        icon="check-circle"
                                                        className="mr-1"
                                                    />{" "}
                                                    {'Uploaded'}
                                                </div>
                                            ) : (
                                                    selectedFilesPreview.length > 0 && (
                                                        <div>

                                                            {` Uploading `}
                                                            <span className="text-success font-weight-bold ff-base">
                                                                {this.props.uploadPercentage!== 100 ? `${parseInt(this.props.uploadPercentage)} %` : '...'}
                                                            </span>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                        {selectedFilesPreview.length > 0 && (
                                            <Progress
                                                value={parseInt(this.props.uploadPercentage)}
                                                style={{ height: "8px" }}
                                            />
                                        )}
                                        </div>

                                        <Row>
                                            {selectedFilesPreview && selectedFilesPreview.length > 0 ?
                                                selectedFilesPreview.map((item, index) => (
                                                    <Col className="mb-3" xs="auto" key={index}>
                                                        <div className="text-center bg-light p-2">
                                                            <div className="d-inline-flex pr-3">
                                                                <div>
                                                                    <div
                                                                        className="selectable-media"
                                                                        style={{ cursor: "default" }}
                                                                    >
                                                                        {/* <div className="gallery-media">
                                                                            <img src={item} alt={`selected-${index}`} />
                                                                        </div> */}
                                                                        <div className="gallery-media">
                                                                        {!item.includes('video') && (
                                                                            <img src={item} alt={`selected-${index}`} />
                                                                        )}
                                                                        {item.includes('video') && (
                                                                            <video width="100" height="100">
                                                                                <source src={item} type="video/mp4" />
                                                                            Your browser does not support the video tag.
                                                                            </video>
                                                                        )}
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mx-n3 mt-2">
                                                                    <Button
                                                                        color="delete-media _alt"
                                                                        size="sm"
                                                                        title="Remove Media"
                                                                        onClick={(e) => this.removeSelectedImage(e, index, item)}
                                                                    >
                                                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {latestUploadedData &&
                                                                latestUploadedData[index] &&
                                                                (latestUploadedData[index]['isUpload'] !== undefined || latestUploadedData[index]['isUpload'] !== null) ?
                                                                latestUploadedData[index]['isUpload'] ? (
                                                                    <div className="mt-2">
                                                                        <InputGroup>
                                                                            <Input bsSize="sm" type="text" name="caption" className="caption-input" placeholder="Caption" onBlur={(e) => this.handleCaptionByIndex(e, index)} style={{ maxWidth: '160px' }} />

                                                                        </InputGroup>

                                                                        <Input onChange={(e) => this.handleChangeCategory(e, index)} bsSize="sm" type="select" name="album_type_category" id="selectCategoryTypeData" style={{ maxWidth: '160px' }}>
                                                                            <option value="">Choose Category</option>
                                                                            {cattaxListing && Object.keys(cattaxListing).length > 0 ?
                                                                                Object.values(cattaxListing).map(
                                                                                    (data) => (
                                                                                        <>
                                                                                            <option value={data?.id ? data.id : ""}>{data.category}</option>
                                                                                        </>
                                                                                    ))
                                                                                : null}
                                                                        </Input>

                                                                        {uploadedId[index] == latestUploadedData[index].id && (Object.keys(fetchTaxonomyListingImages).length > 0 || Object.keys(fetchTaxonomyListingVideos).length > 0) ?
                                                                            <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_subcategory_select" id="selectAlbumTypeData" style={{ maxWidth: '160px' }}>
                                                                                <option value="">Choose Subcategory</option>
                                                                                {latestUploadedData[index]['media_type'] === "image" && fetchTaxonomyListingImages && Object.keys(fetchTaxonomyListingImages).length > 0 && fetchTaxonomyListingImages[index] ?
                                                                                    fetchTaxonomyListingImages[index].map(item => (
                                                                                        <option value={item.id}>{item.label}</option>
                                                                                    ))
                                                                                    : null}
                                                                                {latestUploadedData[index]['media_type'] === "video" && fetchTaxonomyListingVideos && Object.keys(fetchTaxonomyListingVideos).length > 0 && fetchTaxonomyListingVideos.length > 0 && fetchTaxonomyListingVideos[index] ?
                                                                                    fetchTaxonomyListingVideos[index].map(item => (
                                                                                        <option value={item.id}>{item.label}</option>
                                                                                    ))
                                                                                    : null}
                                                                            </Input>

                                                                            : null}
                                                                    </div>
                                                                ) : (
                                                                        <div className="mt-2 text-center">
                                                                            <small className="text-danger">Error while uploading</small>
                                                                        </div>
                                                                    ) : (
                                                                    <div className="mt-2 text-center">
                                                                        <small className="text-info">Uploading...</small>
                                                                    </div>
                                                                )}

                                                        </div>
                                                    </Col>
                                                ))
                                                : null}
                                        </Row>
                                        <div className="d-flex justify-content-center">
                                            <div className="px-2 ">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    disabled={parseInt(this.props.uploadPercentage) !== 100}
                                                    onClick={() => { this.handleCloseAndFetch() }}>
                                                    OK
                                                </Button>
                                            </div>
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.handleCloseAndFetch() }}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="gallery">
                                        <div className="mb-2">
                                            <Nav tabs className="d-inline-flex mb-0">
                                                <NavItem>
                                                    <NavLink href="#"
                                                        active={this.state.showGalleryType === "images"}
                                                        onClick={() => {
                                                            this.setState({ showGalleryType: "images", fetchGalleryType: 'image', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                                this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true })
                                                            })
                                                        }}>
                                                        {'Image Gallery'}
                                                    </NavLink>
                                                </NavItem>
                                                {showVideoMedia === true && (
                                                    <NavItem>
                                                        <NavLink href="#"
                                                            active={this.state.showGalleryType === "videos"}
                                                            onClick={() => {
                                                                this.setState({ showGalleryType: "videos", fetchGalleryType: 'video', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                                    this.props.media_upload_scroll({ type: 'video', album: '', page: 1, force_update: true })
                                                                })
                                                            }}>
                                                            {'Video Gallery'}
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={this.state.showGalleryType}>
                                            <TabPane tabId="images">
                                                <div>
                                                    <FormGroup className="form-row justify-content-end">
                                                        <Col xs="auto">
                                                            <Label className="small" for="selectAlbumType">Album Type:</Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Input onChange={this.handleChangeTaxonomy} bsSize="sm" type="select" name="album_type_select" id="selectAlbumType" defaultValue="all">
                                                                <option value="all">All</option>
                                                                {fetchTaxonomy && Array.isArray(fetchTaxonomy) && fetchTaxonomy.length > 0 ?
                                                                    fetchTaxonomy.map(item => (
                                                                        <option value={item.category}>{item.label}</option>
                                                                    ))
                                                                    : null}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    ref={this.refGalleryImageScroll}
                                                    onScroll={this.handleScrollGalleryImage}
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                    <Row>
                                                        {this.fetchScrollPhotoMedia()}
                                                    </Row>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'Cancel'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="videos">
                                                <div
                                                    ref={this.refGalleryVideoScroll}
                                                    onScroll={this.handleScrollGalleryVideo}
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                    <Row>
                                                        {this.fetchScrollPhotoMedia(true)}
                                                    </Row>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="px-2 ">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                    <div className="px-2">
                                                        <Button color="primary" size="sm" className="mw" onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>Cancel</Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </TabPane>
                                    <TabPane tabId="embed">
                                        <div className="text-muted mb-2">
                                            Submit the link for the image or video you want to embed{" "}
                                        </div>
                                        <div className="mb-2">
                                            {/* Repeat this while adding */}
                                            <div className="d-flex mx-n2 mb-2" key={0}>
                                                <div className="flex-fill px-2">
                                                    <Input
                                                        type="url"
                                                        bsSize="sm"
                                                        className="bg-white"
                                                        value={embeddedLink}
                                                        onChange={(e) => { this.setState({ embeddedLink: e.target.value }) }}
                                                        placeholder="Embeded link"
                                                    />
                                                </div>
                                                {/* <div className="px-2">
                                                    <Button
                                                        title="Remove"
                                                        color="danger"
                                                        size="sm"
                                                        hidden={false}
                                                        onClick={() => { }}>
                                                        <FontAwesomeIcon icon="minus" />
                                                    </Button>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* <div className="d-flex mx-n2 mb-2">
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    title="Add"
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => { }}>
                                                    <FontAwesomeIcon icon="plus" />
                                                </Button>
                                            </div>
                                        </div> */}
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.setState({ embeddedLink: '', uploadMediaModal: false }) }} >
                                                    {'Cancel'}
                                                </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={(e) => { this.AddNewEmbedLink(e) }}>
                                                    {'Insert'}
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>


                {/* <Modal size="sm" isOpen={isToggleModalTrue} toggle={this.toggleModal} modalClassName="backdrop-opaque top-shifted transition-none">
                    <ModalHeader toggle={this.toggleModal}></ModalHeader>
                    <ModalBody className="text-center text-dark">
                        <h2 className="mb-3">Confirmation</h2>
                        <p className="small">{modalText}</p>
                        <div className="pt-4">
                            <div>
                                <Button onClick={this.toggleModal} size="md" color="primary btn-confirm">Cancel</Button>
                                <Button size="md" color="primary btn-confirm" onClick={this.handleOnConfirm}>Yes</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal> */}
                <Modal
                    size="sm"
                    isOpen={isToggleModalTrue}
                    toggle={this.toggleModal}
                    className="text-center delete-box" modalClassName="backdrop-opaque top-shifted transition-none">
                    {/* <ModalHeader toggle={this.toggleModal}></ModalHeader> */}
                    <div className="bg-white modal-header">
                        <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
                        <button
                            className="btn"
                            aria-label="Close"
                            onClick={() => {
                                this.toggleModal({ text: null, type: null, item: null })
                            }}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark">
                        {modalText}
                    </ModalBody>
                    <ModalFooter className="bg-white">
                        <div className="text-center w-100">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={() => this.toggleModal({ text: null, type: null, item: null })}
                            >
                                {'Cancel'}
                            </Button>
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={this.handleOnConfirm}
                            >
                                {'Ok'}
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                {(my_profile && my_profile.id && authId && (authId == my_profile.id)) ? (<AppHeader isEnableCover={false} logoutToHome={true} />
                ) : (<PublicPageHeader isEnableCover={false} forceShowLoginModal={this.state.forceShowLoginModal} forceToggleLoginModal={this.forceToggleLoginModal} />)}

                {/* Business moved design */}
                {
                    this.state.bussiness_moved ?
                        <div className="bg-warning text-black text-center py-4">
                            This business has <span className="font-weight-bold">moved</span> to 26 Burligame, Irvine, CA
                        </div>
                        :
                        ''
                }
                {/* Business closed design */}
                {
                    this.state.business_closed ?
                        <div className="bg-danger text-white text-center py-4">
                            This business has <span className="font-weight-bold">closed.</span> Check <span className="font-weight-bold">similar spots.</span>
                        </div>
                        :
                        ''
                }

                {/* Business Name formally design */}
                {
                    this.state.formally_name_chaged ?
                        <div className="bg-light text-dark text-center py-4">
                            This business was formally called <span className="font-weight-bold">La comparsia.</span>
                        </div>
                        :
                        ''
                }
                <section className="wk-business-warning-bar p-4" hidden>
                    <Container>
                        <p className="m-0 fs-16 text-center text-black"><FontAwesomeIcon icon="calendar-alt" className="mr-2" /> This Business has <b>changed name</b> to business name </p>
                    </Container>
                </section>
                <section className="wk-business-warning-bar p-4 bg-primary positon-relative" hidden>
                    <FontAwesomeIcon icon="times" className="position-absolute wk-business-bar-close" />
                    <Container>
                        <p className="m-0 fs-16 text-center text-white">Help verify this business. Cast your vote: <FontAwesomeIcon icon="thumbs-down" className="ml-2 mr-2" /> <FontAwesomeIcon icon="thumbs-up" className="mr-2" /> <span className="text-black">(Help us remove fraudulent/outdated listings.)</span> <FontAwesomeIcon icon="times" className="position-absolute wk-business-bar-close" /></p>
                    </Container>
                </section>
                {/* <section className="wk-business-warning-bar p-4 bg-primary positon-relative" hidden>
                    <FontAwesomeIcon icon="times" className="position-absolute wk-business-bar-close" />
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="6">
                                <p className="fs-16 text-center text-white">You're verified this listing! <FontAwesomeIcon icon="thumbs-up" className="ml-2" /></p>
                                <p className="fs-16 text-center text-dark font-weight-bold">Please cite sources</p>
                                <Row>
                                    <Col md="10">
                                        <FormGroup>
                                            <Input
                                                className="primary"
                                                type="text"
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.handleChange}
                                                placeholder="Link i.e. http: wikireviews.com"
                                            />
                                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.nameError}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <Button color="text-dark"><FontAwesomeIcon icon="search" /></Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="9">
                                        <FormGroup>
                                            <Input
                                                className="primary"
                                                type="text"
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.handleChange}
                                                placeholder="Upload supporting file"
                                            />
                                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.nameError}</span>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <Button color="text-dark" className="ml-2">Browse</Button>
                                    </Col>
                                </Row>
                                <FormGroup check className="justify-content-start w-100 p-0">
                                    <Label className="w-100 d-flex justify-content-start">Can't cite a source? Please choose</Label>
                                    <Label className="ff-base font-weight-normal w-100 d-flex justify-content-start pl-4" check>
                                        <Input type="radio" name="business_tos"
                                            onChange={this.handleRadioButtonChange}
                                            value={this.state.business_tos}
                                            id={3} />{' '}
                                        {'Content from personal experience'}
                                    </Label>
                                    <Label className="ff-base font-weight-normal w-100 d-flex justify-content-start pl-4" check>
                                        <Input type="radio" name="business_tos"
                                            onChange={this.handleRadioButtonChange}
                                            value={this.state.business_tos}
                                            id={4} />{' '}
                                        {'Reference do not exists'}
                                    </Label>
                                </FormGroup>
                                <div className="d-flex justify-content-center mt-4">
                                    <Button color="text-dark" onClick={(e) => this.handleOnSubmitCiteSource(e)}  className="ml-2">Apply</Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section> */}
                <header className="position-relative">
                    {
                        this.props.business_details && this.props.business_details.listing_logo_pic && this.props.business_details.listing_logo_pic[0] && this.props.business_details.listing_logo_pic[0].url ?
                            <div
                                className="featured-blurred-bg"
                                style={{
                                    backgroundImage: `url(${this.props.business_details.listing_logo_pic[0].url})`
                                }}
                            >
                            </div>
                            :
                            <div
                                className="featured-blurred-bg no-layer no-blurr"
                                style={{
                                    backgroundImage: `url('https://userdatawikireviews.s3.amazonaws.com/images/empty-bg.jpg')`
                                }}
                            >
                            </div>
                    }

                    <TabContent className={`featured-tab-content ${(this.props.business_details && this.props.business_details.listing_logo_pic && this.props.business_details.listing_logo_pic[0] && this.props.business_details.listing_logo_pic[0].url && this.state.viewBusinessAs === 'gallery') ? 'bg-dark-shaded-sm' : ''}`} activeTab={this.state.viewBusinessAs}>
                        <TabPane tabId="about">
                            <AboutBusiness
                                businessDetails={this.props.business_details !== null ? this.props.business_details : ""}
                                business_weather={this.props.business_weather ? this.props.business_weather : ''}
                                temp_type={this.props.my_privacy_setting ? this.props.my_privacy_setting : ''}
                                businessId={this.state.businessId} />
                        </TabPane>
                        <TabPane tabId="gallery" >
                            <Gallery
                                details={this.props.business_details}
                                categoryType={categoryType.Business}
                                businessWeather={this.props.business_weather ? this.props.business_weather : ''}
                                forceToggleLoginModal={this.forceToggleLoginModal}
                                temp_type={this.props.my_privacy_setting ? this.props.my_privacy_setting : ''}
                                mainTaxonomy={this.props.business_details?.taxonomy_list?.[0]?.toLowerCase().replace(/&/g, "and")}
                            />
                            {/* // <Gallery businessDetails={this.props.business_details} businessWeather={this.props.business_weather ? this.props.business_weather : ''} forceToggleLoginModal={this.forceToggleLoginModal} temp_type={this.props.my_privacy_setting ? this.props.my_privacy_setting : ''} /> */}
                        </TabPane>
                        <TabPane tabId="location" >
                            {this.state.viewBusinessAs === 'location' && (
                                <div className="featured-map">
                                    <LeafletMap businessDetails={this.props.business_details} destinationDetails={this.state.destinationDetails} handleOnChangeRoutes={this.handleOnChangeRoutes} />
                                    {/*<MapWithADirectionsRenderer businessDetails={this.props.business_details} destinationDetails={this.state.destinationDetails} handleOnChangeRoutes={this.handleOnChangeRoutes} />*/}
                                </div>
                            )}

                            {this.props.business_details && (
                                <Location businessDetails={this.props.business_details} destinationDetails={this.state.destinationDetails} routes={this.state.routes} handleDestinationChange={this.handleDestinationChange} />
                            )}
                        </TabPane>
                    </TabContent>
                    {/* TopBarTabs */}
                    <TopBarTabs
                        business_details={this.props.business_details}
                        viewBusinessAs={this.state.viewBusinessAs}
                        setViewBsuinessAs={this.setViewBsuinessAs}
                        forceToggleLoginModal={this.forceToggleLoginModal}
                        businessId={businessId}
                    />
                </header>

                {/* Main Content */}
                <section id="enable-sticky" className="py-4 fs-14">
                    <Container>
                        <Row>
                            <Col lg={4}>

                                <IsThatYourBusiness
                                    toggleYourBusiness={this.toggleYourBusiness}
                                />                               
                                <DetailedStarRating
                                    total_rating={this.props.business_details?.total_rating || null}
                                    id={businessId}
                                    bgLight={true}
                                    charts={{ bar: true, line: true, genderTable: true }} /> 
                                {/* Friends Recommendation
                                {this.state.friendsProfileRecommend &&
                                    this.state.friendsProfileRecommend.results &&
                                    this.state.friendsProfileRecommend.results.length > 0 ?
                                    <FriendsProfile
                                        profileData={this.state.friendsProfileRecommend}
                                        title="Recommends" />
                                    : ""
                                }

                                {/* Friends want this */}
                                {this.state.friendsProfileWant &&
                                    this.state.friendsProfileWant.results &&
                                    this.state.friendsProfileWant.results.length > 0 ?
                                    <FriendsProfile
                                        profileData={this.state.friendsProfileWant}
                                        title="Want" />
                                    : ""
                                }

                                {/* Friends favorited this */}
                                {this.state.friendsProfileFavourite &&
                                    this.state.friendsProfileFavourite.results &&
                                    this.state.friendsProfileFavourite.results.length > 0 ?
                                    <FriendsProfile
                                        profileData={this.state.friendsProfileFavourite}
                                        title="Favorited" />
                                    : ""
                                }

                                {/* Similar Business */}
                                <PeopleWorking peopleWorking={peopleWorking}
                                    isToggleWorkingPeople={isToggleWorkingPeople}
                                    isToggleRecommendPeople={isToggleRecommendPeople}
                                    stateSet={this.itemsSetState}
                                    handleChangePeopleWorking={this.handleChangePeopleWorking}
                                    peopleWorkingState={this.state.peopleWorkingState}
                                    handleSubmitPeopleWorking={this.handleSubmitPeopleWorking}
                                    working_employee={workingEmployee}
                                    viewPeopleWorkingHereModal={this.state.viewPeopleWorkingHereModal}
                                    businessId={this.state.businessId}
                                    firstNameError={this.state.firstNameError}
                                    lastNameError={this.state.lastNameError}
                                />

                                <AdditionalInfo business_details={this.state.businessDetails} />
                                {/* Similar Business */}
                                <SimilarBusiness simillarBusiness={simillarBusiness} />

                                {/* Tips & Warnings */}
                                <TipsAndWarning
                                    tipsAndWarnings={tipsAndWarnings}
                                    stateSet={this.itemsSetState}
                                    editId={this.state.editId}
                                    isToggleTips={isToggleTips}
                                    isToggleWarning={isToggleWarning}
                                    tipsText={tipsText}
                                    warningText={warningText}
                                    handleSubmitTipsWarning={this.handleSubmitTipsWarning}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile={this.props.my_profile}
                                />

                                {/* First to review */}
                                {business_details?.first_review_info && Array.isArray(business_details?.first_review_info) && business_details?.first_review_info?.length ?
                                    business_details?.first_review_info.map(item => (
                                        <div className="bg-white-shaded-md p-3 mb-3">
                                            <div className="d-flex mx-n2 fs-14">
                                                <div className="px-2">
                                                    <Link className="fs-14 text-dark d-block" to="#" target="_blank">
                                                        <img src={item.user_pic}
                                                            className="object-fit-cover rounded-circle"
                                                            width={32}
                                                            height={32}
                                                            title={'title'}
                                                            alt=""
                                                            onError={(error) =>
                                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                            }
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="px-2 col">
                                                    <Link className="d-inline-block text-dark font-weight-bold ff-base" to="#" target="_blank">{item.user_name}</Link>
                                                    <div>
                                                        <span className="text-primary font-weight-bold ff-base">1<sup>st</sup> to Review</span>
                                                    </div>
                                                </div>
                                                <div className="px-2">
                                                    <img width="24" src="https://userdatawikireviews.s3.amazonaws.com/images/first-to-review.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    )) : null}

                                {/* Other Offers */}
                                <OtherOffers
                                    setEditOffer={this.setEditOffer}
                                    setToggleForChildComponents={this.setToggleForChildComponents}
                                    getCheckOffer={this.getCheckOffer}
                                    getItems={this.getItems}
                                    putSpecialOffer={this.putSpecialOffer}
                                    businessId={businessId}
                                    handleOtherOffersToggle={this.handleOtherOffersToggle}
                                    handleChangeOffer={this.handleChangeOffer}
                                    offerCheck={offerCheck}
                                    handleSubmitOtherOffers={this.handleSubmitOtherOffers}
                                    stateSet={this.itemsSetState}
                                    otherOffers={this.state.otherOffers}
                                    addedCommentsForOffers={this.addedCommentsForOffers}
                                    // stateOtherOffers={stateOtherOffers}
                                    handleCloseAndFetch={this.handleCloseAndFetch}
                                    mediaSet={mediaSet}
                                    checkIfVideo={this.checkIfVideo}
                                    removeFromMedia={this.removeFromMedia}
                                    toggleVideoOption={this.toggleVideoOption}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                />

                                {/* Discuss This Page */}
                                <DiscussThisPage
                                    discussThisPage={this.state.discussThisPage}
                                    handleSubmit={this.handleSubmitDiscussThisPage}
                                    createNewDisModalToggle={this.state.createNewDisModalToggle}
                                    stateSet={this.itemsSetState}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile={this.props.my_profile}
                                />
                                <div className="mb-3 bg-white text-center p-2">
                                    <a href="http://wikireviews.com/blog" target="_blank">
                                        <img className="img-fluid blog-img" src="https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_02.png" alt="" />
                                    </a>
                                </div>

                            </Col>
                            <Col>
                            {(showCreateReview ?? false) && 
                               <div className="mb-3 step-2_Review">
                                    {/* write your own review, Part 1 */}
                                    <div className={enableRating ? "bg-white p-3 hide-review-block" : "bg-white p-3"}>
                                        <div className="d-flex flex-nowrap">
                                            <div className="flex-grow-1 mr-3">
                                                <h2 className="text-primary mb-2 fs-38">Start your review of {businessDetails?.name}</h2>
                                                {/* Only show if posting for the first time */}
                                                {
                                                    businessDetails?.total_rating?.total_review < 1 &&
                                                    <p className="text-dark ff-base fs-16">
                                                        <strong>Please share your experience.</strong>
                                                        <br />
                                                        Be the first to write a review about this listing and you will receive your First to Review badge!
                                                    </p>
                                                }
                                                
                                            </div>
                                            {/* Only show if posting for the first time */}
                                            {
                                                businessDetails?.total_rating?.total_review < 1 && <div className="ml-auto col-auto px-0">
                                                    <img className="img-fluid" src={require("../../assets/images/icons/first-to-review.png")} alt="First review badge" />
                                                </div>

                                            }   
                                        </div>

                                        <div className="mt-3">
                                            <h6 className="ff-base font-weight-bold text-primary">Step 1. Star Ratings </h6>
                                            <hr className="bg-light mt-2" />
                                            <p className="text-muted fs-14">
                                                {'Roll over the stars and click to rate.'}
                                            </p>

                                            {/* Star Input */}
                                            <div className="d-flex align-items-center">
                                                <Label className="text-dark font-weight-bold text-nowrap mr-3 fs-16">
                                                    {'Overall Rating*'}
                                                </Label>
                                                <div>
                                                    <div className="stars-rating-input _lg">
                                                        <span
                                                            onMouseEnter={() => {
                                                                if (!this.checkIfLoggedIn()) {
                                                                    this.forceToggleLoginModal();
                                                                    return false;
                                                                }                                                               
                                                                this.setState({ enableRatingText: 0, enableHoverBanIcon: true })
                                                            }}
                                                            onMouseLeave={() => { this.setState({ enableRatingText: null,enableHoverBanIcon: false }) }}
                                                            className="zero-rating-input" role="button" onClick={() => this.ratingChanged(0, 'avg_rating')}>
                                                            <FontAwesomeIcon
                                                                icon="ban"
                                                                onClick={() => this.ratingChanged(0, 'avg_rating')}
                                                                color={enableHoverBanIcon ? '#15c3d4' : '#d7d8e2'}
                                                                title="0"
                                                            />
                                                        </span>

                                                        <span title={this.state.enableRatingText}>                                                            
                                                            <Rating
                                                                fractions={2}
                                                                emptySymbol={empty_star_rating}
                                                                fullSymbol={full_star_rating}
                                                                onHover={(rate) => {
                                                                    if (enableHoverBanIcon) {
                                                                        this.setState({ enableHoverBanIcon: false });
                                                                    }
                                                                    if (rate !== this.state.enableRatingText) {
                                                                        this.setState({ enableRatingText: rate })
                                                                    }
                                                                }}
                                                                readonly={ratingOn}
                                                                initialRating={this.state.avg_rating}
                                                                onChange={(newRating) => this.ratingChanged(newRating, 'avg_rating')}
                                                            />
                                                        </span>

                                                        <span className="label-dynamic-rating">
                                                            {(this.state.enableRatingText == 0) && 'Oh, Hell No!'}
                                                            {(this.state.enableRatingText == 0.5 || this.state.enableRatingText == 1) && 'Definitely would not recommend!'}
                                                            {(this.state.enableRatingText == 1.5 || this.state.enableRatingText == 2) && 'It could have been a lot better!'}
                                                            {(this.state.enableRatingText == 2.5 || this.state.enableRatingText == 3) && 'Okay!'}
                                                            {(this.state.enableRatingText == 3.5 || this.state.enableRatingText == 4) && 'Very good!'}
                                                            {(this.state.enableRatingText == 4.5 || this.state.enableRatingText == 5) && "Can't get any better than this!"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* write your own review, Part 2 */}
                                    <RatingCollapse isOpen={enableRating}>
                                        <div>
                                            <div className="py-3">
                                                <h2 className="text-dark fs-36">You’re reviewing <span className="text-darker">{businessDetails?.name}</span></h2>
                                            </div>

                                            {/* Step 1 Collapse */}
                                            <div className="bg-white mb-3">
                                                <CollapseBasic title="Step 1. Star Ratings" bodyClass="text-dark" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true} isReviewCollapse={true} >

                                                    <p className="text-tertiary fs-14">
                                                        Roll over the stars and click to rate. <span className="text-dark ff-alt hover-underline" role="button"
                                                            onClick={() => this.setState({ showRevTips: !this.state.showRevTips })}> Review Tips & Guidelines.</span>
                                                    </p>

                                                    {/* Review Tips & Guidelines */}
                                                    {this.state.showRevTips ? <div className="mb-3" id="reviewTipsAndGuideLines">
                                                        <div className="text-right">
                                                            <Button color="tertiary" className="text-white" size="sm" onClick={() => this.setState({ showRevTips: false })}><FontAwesomeIcon icon="times" /> </Button>
                                                        </div>
                                                        <div className="bg-tertiary text-white p-2 fs-14" >
                                                            <ul className="list-normal mb-0">
                                                                <li className="ff-base mb-2">Do not post photos or videos of other copyrighted material that you don’t have permission to use. Do not post photos of people without their permission. If they are children, ask the parents before you post.</li>
                                                                <li className="ff-base mb-2">Opinions are almost always protected as free speech except in the case of defamation (a false accusation of an offense or a malicious misrepresentation of someone's words or actions), true threats, solicitations to commit crimes, incitement to imminent lawless action, blackmail, fighting words and obscenity).</li>
                                                                <li className="ff-base mb-2">Please omit excessive exaggeration and do not lie.</li>
                                                                <li className="ff-base mb-2">Deliberately sabotaging the viability of a business is strongly discouraged.</li>
                                                                <li className="ff-base mb-2">Be honest and truthful in your reviews.</li>
                                                                <li className="ff-base mb-2">Please be accurate, avoid embellishment and broad generalizations, and to relate only to your first hand experiences.</li>
                                                            </ul>
                                                        </div>
                                                    </div> : ""}

                                                    {/* Star Input */}

                                                    <div className="d-flex align-items-center">
                                                        <Label className="text-dark font-weight-bold text-nowrap mr-3 fs-16">
                                                            {'Overall Rating*'}
                                                        </Label>
                                                        <div>
                                                            <div className="stars-rating-input _lg">                                                                
                                                                <span
                                                                    onMouseEnter={() => { this.setState({ enableRatingText: 0, enableHoverBanIcon: true }) }}
                                                                    onMouseLeave={() => { this.setState({ enableRatingText: null, enableHoverBanIcon: false }) }}
                                                                    className="zero-rating-input"
                                                                    role="button">
                                                                    <FontAwesomeIcon
                                                                        icon="ban"
                                                                        onClick={() => this.ratingChanged(0, 'avg_rating')}
                                                                        color={(enableHoverBanIcon || this.state.avg_rating === 0) ? '#15c3d4' : '#d7d8e2'}
                                                                    />
                                                                </span>                                                                
                                                                <span title={this.state.enableRatingText}>
                                                                    <Rating
                                                                        fractions={2}
                                                                        emptySymbol={empty_star_rating}
                                                                        fullSymbol={full_star_rating}
                                                                        onHover={(rate) => {
                                                                            if (enableHoverBanIcon) {
                                                                                this.setState({ enableHoverBanIcon: false });
                                                                            }

                                                                            if (rate !== this.state.enableRatingText) {
                                                                                this.setState({ enableRatingText: rate })
                                                                            }
                                                                        }}
                                                                        readonly={ratingOn}
                                                                        initialRating={this.state.avg_rating}
                                                                        onChange={(newRating) => {
                                                                            this.ratingChanged(newRating, 'avg_rating')
                                                                        }}
                                                                    />
                                                                </span>

                                                                <span className="label-dynamic-rating">
                                                                    {(this.state.enableRatingText == 0) && 'Oh, Hell No!'}
                                                                    {(this.state.enableRatingText == 0.5 || this.state.enableRatingText == 1) && 'Definitely would not recommend!'}
                                                                    {(this.state.enableRatingText == 1.5 || this.state.enableRatingText == 2) && 'It could have been a lot better!'}
                                                                    {(this.state.enableRatingText == 2.5 || this.state.enableRatingText == 3) && 'Okay!'}
                                                                    {(this.state.enableRatingText == 3.5 || this.state.enableRatingText == 4) && 'Very good!'}
                                                                    {(this.state.enableRatingText == 4.5 || this.state.enableRatingText == 5) && "Can't get any better than this!"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-2 step-3_Review">
                                                        <div className="text-tertiary">
                                                            <span className="font-weight-bold">
                                                                {'Detailed Rating'}
                                                            </span> {'(optional)'}
                                                        </div>
                                                        <hr className="bg-light" />                                                       
                                                        <Row className="fs-14 ff-alt">
                                                            {businessRatingList.map((iteam) => (
                                                                <Col className="mb-2" md={6} lg={4}>
                                                                    <div>{iteam.label}</div>
                                                                    {/* <div>{iteam.key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')}</div> */}
                                                                    <div className="stars-rating-input">
                                                                        <span className="zero-rating-input" role="button">                                                                            
                                                                            <FontAwesomeIcon
                                                                                icon="ban"
                                                                                color={this.checkColorTrue(iteam.key) ? '#15c3d4' : '#d7d8e2'}
                                                                                onMouseEnter={() => this.onHoverEnableDisable(iteam.key, true)}
                                                                                onMouseLeave={() => this.onHoverEnableDisable(iteam.key, false)}
                                                                                onClick={() => {
                                                                                    this.ratingChanged(0, iteam.label, iteam.key);
                                                                                }}
                                                                            />
                                                                        </span>

                                                                        <span title={this.state.titleRatingText}>                                                                                                                                                       
                                                                            <Rating
                                                                                fractions={2}
                                                                                emptySymbol={empty_star_rating_below}
                                                                                fullSymbol={full_star_rating_below}
                                                                                initialRating={!businessRatingList.filter(itm => itm.key === iteam.key)?.[0]?.isEnable ? this.findAndSetItem(iteam.name) : 0}
                                                                                onChange={(newRating) => {
                                                                                    this.ratingChanged(newRating, iteam.label, iteam.key)
                                                                                }}
                                                                                readonly={ratingOn}
                                                                            />
                                                                        </span>
                                                                        <span
                                                                            onClick={() => {
                                                                                this[iteam.label] = false;
                                                                                this.ratingChanged(0, iteam.label,iteam.key)
                                                                            }}
                                                                            className="clear-rating"
                                                                            role="button"
                                                                            title="Clear">
                                                                            <span className="icon-cross"></span>
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                    {businessDetails?.taxonomy_list && indexOf(businessDetails.taxonomy_list, 'Restaurants') > -1 && (rateMenu && rateMenu?.length) ?
                                                    <div className="p-3 bg-light">
                                                        <CollapseBasic title="You can rate menu items too!" containerClass="bg-transparent type-tertiary" bodyClass="mt-3 text-tertiary" isOpen={false} size="sm">
                                                            <div className="mb-4">
                                                                <Nav className="active-transparent border-0" tabs>
                                                                    {rateMenu && rateMenu.map((menuItem, index) => {
                                                                        return <NavItem>
                                                                            <NavLink className="text-truncate"
                                                                                href="#"
                                                                                active={this.state.activeMenuType === menuItem}
                                                                                onClick={() => {
                                                                                    this.setState({ activeMenuType: menuItem })
                                                                                    this.fetchRateMenuItemContent({businessId,menuItem})
                                                                                    
                                                                                }}
                                                                                key={index}
                                                                            >
                                                                                <span className="font-weight-bold ff-base fs-16">
                                                                                    {menuItem}
                                                                                </span>
                                                                            </NavLink>
                                                                        </NavItem>
                                                                    })}                                                                    
                                                                </Nav>
                                                            </div>
                                                            <TabContent activeTab={this.state.activeMenuType}>
                                                                <TabPane tabId={this.state.activeMenuType}>
                                                                    {rateMenuContent && rateMenuContent.map((menu ,indexItem) => {
                                                                        return <CollapseBasic title={menu.menuItem} containerClass="bg-transparent type-tertiary" bodyClass="fs-14 mt-3 text-tertiary" size="sm" isOpen={false}>
                                                                            <Row>
                                                                                {/* Repeat this for items */}
                                                                                {menu?.menuContent?.map((menuDetail, index) => {
                                                                                    return <Col className="mb-3" lg={6}>
                                                                                        <div className="d-flex flex-nowrap mx-n2">
                                                                                            <div className="px-2">
                                                                                                <img className="menu_img" width="50" src={this.state.menuMediaListMedia[menuDetail.menuItemContent[4]]?.[0]?.thumbnail || 'https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png'} alt="" />
                                                                                            </div>
                                                                                            <div className="px-2">
                                                                                                <div className="d-flex">
                                                                                                    <div className="mr-2">                                                                                                       
                                                                                                        <span className="text-dark mr-2 font-weight-bold">{menuDetail.menuItemName}</span>
                                                                                                        <div>
                                                                                                            <div className="stars-rating-input _sm">
                                                                                                                <span className="zero-rating-input" role="button">
                                                                                                                    <FontAwesomeIcon icon="ban"
                                                                                                                        onClick={() => this.ratingChangedMenuItem(0, menuDetail.menuItemContent[4])}
                                                                                                                        color={+menuDetail.menuItemContent[1] === 0 ? '#15c3d4' : '#d7d8e2'}
                                                                                                                    />
                                                                                                                </span>
                                                                                                                <StarsRating
                                                                                                                    count={5}
                                                                                                                    value={+menuDetail.menuItemContent[1]}
                                                                                                                    size={18}
                                                                                                                    color1={'#d7d8e2'}
                                                                                                                    color2={'#15c3d4'}
                                                                                                                    onChange={(newRating) => {
                                                                                                                        this.ratingChangedMenuItem(newRating, menuDetail.menuItemContent[4])
                                                                                                                    }}
                                                                                                                />
                                                                                                                {/* <span className="ml-2 d-inline-block times-2" role="button" title="Clear">
                                                                                                                    <span className="icon-cross"></span>
                                                                                                                </span> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="text-dark">
                                                                                                    <p className="fs-14 mb-0">
                                                                                                        {menuDetail.menuItemContent[2]}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="d-flex flex-wrap">
                                                                                                    {this.state.menuMediaListMedia[menuDetail.menuItemContent[4]]?.map(img=>{
                                                                                                        return <div className="px-2 py-2 position-relative" >
                                                                                                        <img className='menu_img' width="50" src={img.thumbnail || 'https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png'} alt="" />
                                                                                                        <FontAwesomeIcon onClick={()=>this.handleDeleteMenuMedia(menuDetail.menuItemContent[4],img.id)} color="red" icon="trash-alt" style={{position:"absolute", top:0, right: "10px", cursor:"pointer"}}/>
                                                                                                    </div>
                                                                                                    })}
                                                                                                </div>
                                                                                                <div className="mt-1">
                                                                                                    <Button color="link" className="text-dark fs-14 font-weight-bold p-0"
                                                                                                        onClick={() => {
                                                                                                            this.handleCloseAndFetch();
                                                                                                            this.setState({
                                                                                                                uploadType: "menuItem",
                                                                                                                menuTitle: menu.menuItem,
                                                                                                                menuSubTitle: menuDetail.menuItemName,
                                                                                                                menuObjectPk: menuDetail.menuItemContent[4]
                                                                                                            })
                                                                                                        }}>
                                                                                                        <FontAwesomeIcon icon="camera" className="mr-2" />
                                                                                                        Add Image
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                })}


                                                                            </Row>
                                                                        </CollapseBasic>
                                                                    })}
                                                                    
                                                                </TabPane>
                                                            </TabContent> 
                                                        </CollapseBasic>
                                                    </div> : ''} 
                                                </CollapseBasic>
                                            </div>

                                            {/* Step 2 Collapse */}
                                            <div className="bg-white mb-3">
                                                <CollapseBasic title="Step 2. Review" bodyClass="text-dark" containerClass="type-tertiary type-review bg-transparent" isOpen={false} size="sm" noPadding={true} isReviewCollapse={true}>
                                                    <div className="text-tertiary fs-14 mb-3">
                                                        {'Please provide as much information as you can in your review to be as meaningful as possible to others.'}
                                                    </div>
                                                    <Nav tabs className="mb-3 active-transparent">
                                                        <NavItem>
                                                            <NavLink href="#"
                                                                className="ff-base font-weight-normal"
                                                                active={this.state.viewEditReviewStep === "writeReview"}
                                                                onClick={() => {

                                                                    if ((this.state.clickedOnWriteAReview == 0 || this.state.clickedOnWriteAReview == 1)) {
                                                                        this.setState({ clickedOnWriteAReview: 1 ,enableErrorForReview:write_new_review?.trim()?.length < 50 });
                                                                    } else {
                                                                        this.setState({ viewEditReviewStep: "writeReview",enableErrorForReview:write_new_review?.trim()?.length < 50 })
                                                                    }
                                                                }}>
                                                                <span className="font-weight-bold">
                                                                    {'Write a Review'}
                                                                </span>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink href="#"
                                                                className="ff-base font-weight-normal step-4_Review"
                                                                active={this.state.viewEditReviewStep === "recordVideo"}
                                                                onClick={() => {
                                                                    this.setState({ viewEditReviewStep: "recordVideo", clickedOnWriteAReview: 0,enableErrorForReview: !stepTwoUploadedVideo.length})
                                                                }}>{'or record a'}
                                                                <span className="font-weight-bold">
                                                                    &nbsp; Video Review
                                                                </span>
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>

                                                    <TabContent className="mb-3" activeTab={this.state.viewEditReviewStep}>
                                                        <TabPane tabId="writeReview">

                                                            <div className="px-2 col">
                                                                <div className="fs-14 font-weight-bold text-dark">
                                                                    Add minimum 50 Characters
                                                                </div>
                                                            </div>

                                                            <FormGroup className="main-post-formgroup">
                                                                <div className="input-label-block">
                                                                    <Input
                                                                        // style={{ border: enableErrorForReview ? '1px solid red' : '1px solid #ddd' }}
                                                                        className="primary"
                                                                        type="textarea"
                                                                        name="write_new_review"
                                                                        rows="4"
                                                                        id="write_new_review"
                                                                        value={write_new_review}
                                                                        onChange={this.handelChangeReview}
                                                                        placeholder="&nbsp;"
                                                                    />
                                                                    {write_new_review?.trim()?.length < 50 && write_new_review?.trim()?.length !== 0 ? <span style={{ color: "red" }}>
                                                                        This field contains a minimum of 50 characters.
                                                                    </span> : ""}
                                                                </div>
                                                                <div className="d-flex">
                                                                    <div className="px-2 col">
                                                                        <div className="characters-length-text">
                                                                            Characters: <span>{write_new_review.trim().length}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>
                                                        </TabPane>
                                                        <TabPane tabId="recordVideo">
                                                            <div>
                                                                {this.state.viewEditReviewStep === "recordVideo" && (
                                                                    <WebCamRecording
                                                                        setClickedTabOperation={this.setClickedTabOperation}
                                                                        clickedOnWriteAReview={this.state.clickedOnWriteAReview}
                                                                        stepTwoUploadedVideo={this.state.stepTwoUploadedVideo}
                                                                        addUploadedVideo={this.addUploadedVideo}
                                                                        removeUploadedVideo={this.removeUploadedVideo}
                                                                    />
                                                                )}
                                                                {this.state.viewEditReviewStep === "recordVideo" && (
                                                                    <FormGroup className="main-post-formgroup">
                                                                        <div className="input-label-block">
                                                                            <Input
                                                                                // style={{ border: enableErrorForReview ? '1px solid red' : '1px solid #ddd' }}
                                                                                className="primary"
                                                                                type="textarea"
                                                                                name="writeAReviewTextVideo"
                                                                                rows="4"
                                                                                id="write_new_review_video"
                                                                                value={writeAReviewTextVideo}
                                                                                onChange={this.handelChangeReviewVideo}

                                                                            />

                                                                        </div>
                                                                        <div className="d-flex">
                                                                            <div className="px-2 col">
                                                                                <div className="characters-length-text">
                                                                                    Characters: <span>{writeAReviewTextVideo.length ? writeAReviewTextVideo.length : '0'}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </FormGroup>
                                                                )}
                                                            </div>

                                                        </TabPane>
                                                    </TabContent>

                                                    <div className="fs-14">
                                                        <div className="text-dark">
                                                            <span className="font-weight-bold">
                                                                Choose Review Categories
                                                            </span>
                                                            <span>
                                                                (Optional)
                                                            </span>
                                                        </div>
                                                        <hr className="bg-light my-2" />
                                                        <p className="text-tertiary fs-14 mb-2">
                                                            If your review above contains information about a particular element or elements that you would like to highlight, please check the corresponding boxes below.
                                                        </p>
                                                        <Row>

                                                            {reviewsCategories.res && reviewsCategories.res.map((iteam, indext) => {
                                                                if (iteam.name !== "avg_rating")
                                                                    return (
                                                                        <Col xs={6} lg={4} key={indext}>
                                                                            <FormGroup check>
                                                                                <Label className="text-primary font-weight-bold" check>
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        id="checkbox2"
                                                                                        name={iteam.label}
                                                                                        onChange={(e) => this.ratingChangedForCheck(e.target.checked, iteam)}
                                                                                    />{' '}
                                                                                    {iteam.label}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    )
                                                            })
                                                            }

                                                            {/* <Col xs={6} lg={4}>
                                                            <FormGroup check>
                                                                <Label className="text-primary font-weight-bold" check>
                                                                    <Input type="checkbox" id="checkbox2" name="TurnaroundTime" onChange={this.handelChangeReview} checked={TurnaroundTime} />{' '}
                                                                             Turnaround Time
                                                                        </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6} lg={4}>
                                                            <FormGroup check>
                                                                <Label className="text-primary font-weight-bold" check>
                                                                    <Input type="checkbox" id="checkbox2" />{' '}
                                                                            Taste of Ice Cream/Yogurt
                                                                        </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6} lg={4}>
                                                            <FormGroup check>
                                                                <Label className="text-primary font-weight-bold" check>
                                                                    <Input type="checkbox" id="checkbox2" />{' '}
                                                                            Atmosphere
                                                                        </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={6} lg={4}>
                                                            <FormGroup check>
                                                                <Label className="text-primary font-weight-bold" check>
                                                                    <Input type="checkbox" id="checkbox2" />{' '}
                                                                            Variety of Flavors
                                                                        </Label>
                                                            </FormGroup>
                                                        </Col> */}
                                                        </Row>
                                                    </div>
                                                </CollapseBasic>
                                            </div>

                                            {/* Step 3 Collapse */}
                                            <div className="bg-white mb-3">
                                                <CollapseBasic title="Step 3. Upload Images and/or Video (optional)" containerClass="type-tertiary type-review bg-transparent" isOpen={false} size="sm" noPadding={true} isReviewCollapse={true}>
                                                    <p className="text-tertiary fs-14 mb-3">
                                                        Upload images or video to accompany your review. Additional images/videos can be added later.
                                                    </p>
                                                    {/* Uploaded media shown here */}
                                                    <div className="border mb-2">
                                                        <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                                            {embedBody && Array.isArray(embedBody) && embedBody.length ?
                                                                embedBody.map((item, uploaded_index) => (
                                                                    <Col key={uploaded_index}>
                                                                        <div className="d-flex pr-3 m-2">
                                                                            <div>
                                                                                {/* {item.type === "embedded_link" &&  */}
                                                                                <div className="gallery-media d-flex justify-content-center">
                                                                                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                                                        <img
                                                                                            className="img-fluid img-thumbnail h-100"
                                                                                            src={item.thumbnail || require("../../assets/images/embedded_link_img.png")}
                                                                                            id={`embedded_link-${uploaded_index}`}
                                                                                            alt={item.url}
                                                                                        />
                                                                                        <UncontrolledTooltip
                                                                                            autohide="false"
                                                                                            placement="top"
                                                                                            target={`embedded_link-${uploaded_index}`}
                                                                                        >
                                                                                            {item.url}
                                                                                        </UncontrolledTooltip>
                                                                                    </a>
                                                                                </div>
                                                                                {/* } */}
                                                                            </div>
                                                                            <div className="mx-n3 mt-2">
                                                                                <Button onClick={() => { this.removeEmbeddedFromUploaded(uploaded_index, item) }} color="delete-media" size="sm" title="Remove Media">
                                                                                    <FontAwesomeIcon icon="trash-alt" />
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )) : null}
                                                            {mediaSetReview.map((iteam) => (
                                                                <Col>
                                                                    <div className="d-flex pr-3 m-2">
                                                                        <div className="project-thumbnail position-relative">
                                                                            <img className="img-fluid img-thumbnail" 
                                                                            // src={this.checkIfVideo(iteam)}
                                                                            src={iteam?.thumbnail}
                                                                             alt="Uploaded media" />
                                                                             {iteam.type==="video" &&
                                                                             <FontAwesomeIcon color="white" size="lg" icon="play-circle" style={{position: "absolute",  top: "50%", left: "50%",  transform: "translate(-50%, -50%)"}}/>}
                                                                        </div>
                                                                        <div className="mx-n3 mt-2">
                                                                            <Button
                                                                                className="position-absolute"
                                                                                color="delete-media"
                                                                                size="sm"
                                                                                title="Remove Media"
                                                                                onClick={(e) => { this.removeFromMedia(e, iteam , iteam.type) }}>
                                                                                <FontAwesomeIcon icon="trash-alt" />{" "}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                    <Button color="tertiary" className="font-weight-bold" onClick={this.handleCloseAndFetchReview}>
                                                        {'Upload Media'}
                                                    </Button>
                                                </CollapseBasic>
                                            </div>
                                            {/* Final Step */}
                                            <div className="bg-white p-3">
                                                <div className="mb-3 ff-alt">
                                                    <FormGroup check>
                                                        <Input type="checkbox" id="checkbox2" checked={shocialNetworkSharing} disabled={reviewAnonymous} name="shocialNetworkSharing" onChange={this.handelChangeReview} />
                                                        <Label className="text-tertiary font-weight-normal fs-14" check>
                                                            {' '}
                                                            {'Also post this review on social networks.'}
                                                        </Label>
                                                    </FormGroup>
                                                    <div className="mt-3 pl-4">
                                                        <FacebookShareButton
                                                            url={`${this.state.protocol}//${this.state.host}/business/${businessId}/${businessDetails?.slug ? businessDetails?.slug : 'null'}`}
                                                            quote={`Review of ${businessDetails?.name} in ${businessDetails?.address ? businessDetails.address[0].city : ""}, ${businessDetails?.address ? businessDetails.address[0].state : ""}: ${this.state.write_new_review}`}
                                                            hashtag={this.state.fbHashTag}
                                                            className="text-dark text-decoration-none mx-2">
                                                            <Button color={!(shocialNetworkSharing || this.props.facebook) ? "tertiary reset" : "primary"} disabled={!(shocialNetworkSharing || this.props.facebook)} >
                                                                {'Post To Facebook'}
                                                            </Button>
                                                        </FacebookShareButton>

                                                        <TwitterShareButton
                                                            url={`${this.state.protocol}//${this.state.host}/business/${businessId}/${businessDetails?.slug ? businessDetails?.slug : 'null'}`}
                                                            title={`Review of ${businessDetails?.name} in ${businessDetails?.address ? businessDetails.address[0].city : ""}, ${businessDetails?.address ? businessDetails.address[0].state : ""}: ${this.state.write_new_review}`} via={this.state.shareVia}
                                                            hashtags={this.state.twitterHashTags}
                                                            className="text-dark text-decoration-none mx-2">
                                                            <Button color={!(shocialNetworkSharing || this.props.twitter) ? "tertiary reset" : "primary"} disabled={!(shocialNetworkSharing || this.props.twitter)}>Post To Twitter</Button>
                                                        </TwitterShareButton>
                                                    </div>
                                                </div>
                                                {my_profile?.review_statistics?.count && my_profile?.review_statistics?.count > 5 ?
                                                    <div className="mb-3">
                                                        <FormGroup check className=' step-5_Review'>
                                                                <Input type="checkbox" id="checkbox2" name="reviewAnonymous" checked={reviewAnonymous} onChange={(e) => {
                                                                    this.setState({
                                                                        reviewAnonymous: e.target.checked,
                                                                        shocialNetworkSharing: false
                                                                    })
                                                                }} />
                                                            <Label className="text-tertiary font-weight-bold fs-14 ff-alt" check>
                                                                
                                                                Make this review anonymous <span className="font-weight-normal">(we will not show your username). <img src={require("../../assets/images/icons/ovel.png")} alt="" /></span>
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                    : null}
                                                <div className="mb-3">
                                                    <FormGroup check>
                                                        <Input type="checkbox" id="checkbox2" checked={isadhere} name="isadhere" onChange={this.handelChangeReview} />
                                                        <Label className="text-tertiary font-weight-bold fs-14 ff-alt" check>
                                                            {' '}
                                                            {'I adhere to the '}
                                                            <span className="text-dark" role="button" onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({ showRevTips: !showRevTips }, () => {
                                                                    if (this.state?.showRevTips) {
                                                                        document.getElementById('reviewTipsAndGuideLines').scrollIntoView({ behavior: "smooth", block: "center" });
                                                                    }
                                                                });
                                                            }} >
                                                                {'Review Tips & Guidelines.'}
                                                            </span>
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                                <div className="text-right">
                                                    
                                                    <Button size="lg" color="grey" className="mw mr-3"
                                                        onClick={() => this.clearReveiwRatingState()}
                                                    >Cancel</Button>
                                                    <span className="text-right step-7_Review" style={{display: "inline-block"}}>
                                                    <Button
                                                        disabled={enableErrorForReview}
                                                        size="lg"
                                                        color="tertiary"
                                                        className={reviewAnonymous ? "mw hide-review-block" : "mw"}
                                                        onClick={() => this.publishPost("draft")}>
                                                        {'Save Draft'}
                                                    </Button>
                                                    <Button
                                                        disabled={enableErrorForReview}
                                                        title={enableErrorForReview ? this.state.viewEditReviewStep === "recordVideo" ? "Video Review Required" : "Text Review field Required" : "" }
                                                        size="lg"
                                                        color="primary"
                                                        className="mw"
                                                        onClick={() => this.publishPost("publish")}
                                                    >Publish</Button>
                                                    </span>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </RatingCollapse>
                                </div>}
                                <Nav tabs className="text-center align-items-end active-transparent" onMouseOver={() => this.onMouseEnterCategory('dropdownSortBy')}
                                    onMouseLeave={() => this.onMouseLeaveCategory('dropdownSortBy')}
                                    isOpen={this.state.dropdownSortBy}
                                    toggle={() => this.toggleCategory('dropdownSortBy')}>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base"
                                            active={this.state.mainViewType === "reviews"}
                                            onClick={() => {
                                                this.setState({ mainViewType: "reviews" }, () => {
                                                    this.props.get_business_review_detail(businessId, 'new')
                                                })
                                            }}>
                                            Reviews
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base"
                                            active={this.state.mainViewType === "wall"}
                                            onClick={() => {
                                                this.setState({ mainViewType: "wall", wallLoading: true }, () => {
                                                    this.props.get_business_wall_data({ id: businessId, filter_type: 'all', page: 1 })
                                                })
                                            }}>
                                            Wall
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base"
                                            active={this.state.mainViewType === "qa"}
                                            onClick={() => { this.setState({ mainViewType: "qa" }) }}>
                                            Q&A
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base"
                                            active={this.state.mainViewType === "ownerInfo"}
                                            onClick={() => { this.setState({ mainViewType: "ownerInfo" }) }}>
                                            Owner's Info
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base"
                                            active={this.state.mainViewType === "discussion"}
                                            onClick={() => {
                                                this.setState({ mainViewType: "discussion" }, () => {
                                                    let url = `/api/discussion/?entity=${businessId}&page=1`;
                                                    this.props.get_discussions(url);
                                                })
                                            }}>
                                            Discussion
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={this.state.mainViewType}>
                                    <TabPane tabId="reviews">
                                        <ClientReview
                                            businessId={businessId}
                                            businessDetails={business_details}
                                            business_rating={this.props.business_rating}
                                            qa_dropdown={this.props.qa_dropdown}
                                            // businessDetails={this.props.business_details}
                                            my_profile={this.props.my_profile}
                                            loggedInUsername={loggedInUsername}
                                            forceToggleLoginModal={this.forceToggleLoginModal}
                                            mainTaxonomy={business_details?.taxonomy_list?.[0]?.toLowerCase().replace(/&/g, "and")}
                                            typeUpload="business"
                                            initialReviewListing={this.state.initialReviewListing}
                                        />
                                    </TabPane>
                                    <TabPane tabId="wall">
                                        <ButtonGroup className="flex-wrap type-filter mb-3" size="sm">
                                            <div className="item d-flex align-items-center">
                                                <Button
                                                    className="text-nowrap"
                                                    color="transparent"
                                                    onClick={() => {
                                                        this.setState({ viewWallUpdatesType: "all", wallLoading: true }, () => {
                                                            this.props.get_business_wall_data({ id: businessId, filter_type: 'all', page: 1, force: true })
                                                        })
                                                    }}
                                                    active={this.state.viewWallUpdatesType === "all"}>
                                                    All
                                                </Button>
                                            </div>
                                            <div className="item d-flex align-items-center">
                                                <Button
                                                    className="text-nowrap"
                                                    color="transparent"
                                                    onClick={() => this.setState({ viewWallUpdatesType: "posts", wallLoading: true }, () => {
                                                        this.setState({ viewWallUpdatesType: "posts", wallLoading: true }, () => {
                                                            this.props.get_business_wall_data({ id: businessId, filter_type: 'posts', page: 1, force: true })
                                                        })
                                                    })}
                                                    active={this.state.viewWallUpdatesType === "posts"}>
                                                    Posts
                                                </Button>
                                            </div>
                                            <div className="item d-flex align-items-center">
                                                <Button
                                                    className="text-nowrap"
                                                    color="transparent"
                                                    onClick={() => this.setState({ viewWallUpdatesType: "replies", wallLoading: true }, () => {
                                                        this.setState({ viewWallUpdatesType: "replies", wallLoading: true }, () => {
                                                            this.props.get_business_wall_data({ id: businessId, filter_type: 'replies', page: 1, force: true })
                                                        })
                                                    })}
                                                    active={this.state.viewWallUpdatesType === "replies"}>
                                                    Replies
                                                </Button>
                                            </div>
                                            <div className="item d-flex align-items-center">
                                                <Button
                                                    className="text-nowrap"
                                                    color="transparent"
                                                    onClick={() => this.setState({ viewWallUpdatesType: "updates", wallLoading: true }, () => {
                                                        this.setState({ viewWallUpdatesType: "updates", wallLoading: true }, () => {
                                                            this.props.get_business_wall_data({ id: businessId, filter_type: 'updates', page: 1, force: true })
                                                        })
                                                    })}
                                                    active={this.state.viewWallUpdatesType === "updates"}>
                                                    Updates
                                                </Button>
                                            </div>
                                        </ButtonGroup>

                                        {setWallDataItems &&
                                            setWallDataItems[viewWallUpdatesType] &&
                                            setWallDataItems[viewWallUpdatesType].results &&
                                            setWallDataItems[viewWallUpdatesType].results.length > 0 ? (
                                                setWallDataItems[viewWallUpdatesType].results.map((item, index) => {
                                                    let url = `${this.state.protocol}//${this.state.host}/business/${item?.target?.slug}`;
                                                    let title_quote = `Checkout ${item?.target?.name} on`;
                                                    return (
                                                        <div id={setWallDataItems[viewWallUpdatesType].results.length - 1 === index ? `item-last` : `item-${index}`} className="bg-white text-dark p-3 mb-2 last-item-new">
                                                            <div className="d-flex mx-n2 mb-2">
                                                                <div className="px-2">
                                                                    <img className="img-circle _50x50" src={item?.actor?.current_profile_pic?.url ? item.actor.current_profile_pic.url : ""} alt=""

                                                                        onError={(error) => (error.target.src = require("../../assets/images/icons/user-circle.png"))}
                                                                    />
                                                                </div>
                                                                <div className="px-2 flex-grow-1">
                                                                    <div className="mb-3">
                                                                        <a href="#" className="text-darker font-weight-bold">{`${item?.actor?.first_name} ${item?.actor?.last_name}`}</a>
                                                                        {` ${item?.verb} `}
                                                                        <a href="#" className="text-darker font-weight-bold">{item?.target?.name}</a>
                                                                        {item?.verb === "recommends" ? (
                                                                            <span className="text-nowrap float-right">
                                                                                <div className="d-inline-flex flex-nowrap">
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                                                            <div className="share-it-icon"></div>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu className="type-tertiary-alt" right>
                                                                                            <DropdownItem className="fs-14">
                                                                                                <FacebookShareButton
                                                                                                    url={url}
                                                                                                    quote={title_quote}
                                                                                                    hashtag={this.state.fbHashTag}>
                                                                                                    {'Facebook'}
                                                                                                </FacebookShareButton>
                                                                                            </DropdownItem>
                                                                                            <DropdownItem className="fs-14">
                                                                                                <TwitterShareButton
                                                                                                    url={url}
                                                                                                    title={title_quote}
                                                                                                    // via={this.state.shareVia}
                                                                                                    hashtags={this.state.twitterHashTags}>
                                                                                                    {'Twitter'}
                                                                                                </TwitterShareButton>
                                                                                            </DropdownItem>
                                                                                            <DropdownItem className="fs-14" onClick={() => this.handleOnClickShareByEmail(item?.target?.id)}>By Email</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                    <UncontrolledDropdown>
                                                                                        <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                                                            <div className="thumbpin-icon"></div>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu className="type-tertiary-alt" right>
                                                                                            <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('want list', item?.target?.userpins?.want_this, item)}><FontAwesomeIcon icon="heart" className="mr-2" fixedWidth /> {item?.target?.userpins?.want_this ? 'Added' : 'Add'} to want list</DropdownItem>
                                                                                            <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('favourite list', item?.target?.userpins?.favourite, item)}><FontAwesomeIcon icon="star" className="mr-2" fixedWidth /> {item?.target?.userpins?.favourite ? 'Added' : 'Add'} to favorites</DropdownItem>
                                                                                            <DropdownItem className="fs-14"><FontAwesomeIcon icon="eye" className="mr-2" fixedWidth onClick={() => this.handleOnClickAddToList('watch list', item?.target?.userpins?.watch_this, item)} /> {item?.target?.userpins?.watch_this ? 'Added' : 'Add'} to watch list</DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </div>
                                                                            </span>
                                                                        ) : null}
                                                                        <br />
                                                                        <span className="text-tertiary">{item?.time_stamp}</span>

                                                                    </div>
                                                                    <div className="mt-3">
                                                                        <div className="quick-reactions d-flex flex-wrap align-items-end mb-2">
                                                                            <div className="reaction-holder" style={{ display: 'contents' }}>
                                                                                {/* Show on reacting */}

                                                                                {item?.review_group_animation && item?.review_group_animation?.review_group_count && item?.review_group_animation?.review_group_status ?
                                                                                    <React.Fragment>
                                                                                        <div hidden>
                                                                                            {this.renderAnimatedReaction(['woohoo_forever', 'nailedit_forever', 'insightful_forever', 'lol_forever', 'bummer_forever'])}
                                                                                            {this.renderReaction([
                                                                                                { name: 'woohoo', count: item?.review_group_animation?.review_group_count?.woohoo ? item?.review_group_animation?.review_group_count?.woohoo : 0, labelName: 'Woohoo', value: item?.review_group_animation?.review_group_status['woohoo'] === false ? true : false },
                                                                                                { name: 'nailed', count: item?.review_group_animation?.review_group_count?.nailed ? item?.review_group_animation?.review_group_count?.nailed : 0, labelName: 'Nailed It', value: item?.review_group_animation?.review_group_status['nailed'] === false ? true : false },
                                                                                                { name: 'insightful', count: item?.review_group_animation?.review_group_count?.insightful ? item?.review_group_animation?.review_group_count?.insightful : 0, labelName: 'Insightful', value: item?.review_group_animation?.review_group_status['insightful'] === false ? true : false },
                                                                                                { name: 'lol', count: item?.review_group_animation?.review_group_count?.lol ? item?.review_group_animation?.review_group_count?.lol : 0, labelName: 'LOL', value: item?.review_group_animation?.review_group_status['lol'] === false ? true : false },
                                                                                                { name: 'bummer', count: item?.review_group_animation?.review_group_count?.bummer ? item?.review_group_animation?.review_group_count?.bummer : 0, labelName: 'Bummer', value: item?.review_group_animation?.review_group_status['bummer'] === false ? true : false }
                                                                                            ])}
                                                                                        </div>
                                                                                        <Reactions
                                                                                            id={item?.id}
                                                                                            counts={item?.review_group_animation?.review_group_count}
                                                                                            statuses={item?.review_group_animation?.review_group_status}
                                                                                            onClick={(reaction) => this.handleFeedReactions(reaction, item.id)}
                                                                                            get_reviewgroup={this.props.get_reviewgroup}
                                                                                            get_reveiewData={this.props.get_reveiewData}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <React.Fragment>
                                                                                        <div hidden>
                                                                                            {this.renderAnimatedReaction(['cheers_forever'])}
                                                                                            {this.renderReaction([{ name: 'cheers', labelName: 'Thank you', value: true }])}
                                                                                        </div>
                                                                                        <Reactions
                                                                                            id={item?.id}
                                                                                            counts={item?.contribution_group?.contribution_group_count}
                                                                                            statuses={item?.contribution_group?.contribution_group_status}
                                                                                            onClick={(reaction) => this.handleFeedReactions(reaction, item.id)}
                                                                                            get_reviewgroup={this.props.get_reviewgroup}
                                                                                            get_reveiewData={this.props.get_reveiewData}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* All Comments */}
                                                            <div>
                                                                {/* Comment by other user */}
                                                                {item?.comments && Array.isArray(item?.comments) && item?.comments.length ?
                                                                    item?.comments.map(cm_item => (
                                                                        <Media className="media-post mt-3">
                                                                            <Media>
                                                                                <img
                                                                                    className="media-object object-1"
                                                                                    object
                                                                                    src={cm_item?.current_profile_pic}
                                                                                    alt="User Image"
                                                                                    onError={(error) => (error.target.src = require("../../assets/images/icons/user-circle.png"))}
                                                                                />
                                                                            </Media>
                                                                            <Media body>
                                                                                <Media className="mb-0" heading>
                                                                                    <div className="d-flex flex-nowrap mx-n2">
                                                                                        <div className="px-2 flex-grow-1">
                                                                                            <span className="text-darker fs-14">
                                                                                                {cm_item?.sender}{": "}
                                                                                            </span>
                                                                                            {/* Comments */}
                                                                                            <span className="wb-break-word fs-14 font-weight-normal text-dark ff-alt">
                                                                                                {cm_item?.body}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </Media>
                                                                                <div>
                                                                                    <div className="d-flex flex-nowrap mx-n2 mt-n1">
                                                                                        <div className="align-self-end flex-grow-1 px-2">
                                                                                            {/* Reply Button */}
                                                                                            <button
                                                                                                onClick={() => { this.toggleWallReply(cm_item?.id) }}
                                                                                                className="btn btn-link btn-sm px-0 font-weight-bold text-darker">
                                                                                                {'Reply'}
                                                                                            </button>
                                                                                            <span className="fs-14 ff-alt text-tertiary align-middle">
                                                                                                <span>{" - "}</span>
                                                                                                {cm_item?.sent_at}
                                                                                            </span>
                                                                                        </div>

                                                                                        {/* Remove Actions if comment by other user  */}
                                                                                        <div className="align-self-start col-auto px-2" hidden={!this.checkIfLoggedIn()}>
                                                                                            <EditBtn
                                                                                                onClick={() => {
                                                                                                    wallReplyText = {};
                                                                                                    wallReplyVisibility = {};
                                                                                                    wallReplyText[cm_item?.id] = cm_item?.body;
                                                                                                    wallReplyVisibility[cm_item?.id] = true;
                                                                                                    this.setState({ wallReplyText, wallReplyVisibility, wallCommentEdit: true })
                                                                                                }} />
                                                                                            <DeleteBtn
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        isToggleModalTrue: true,
                                                                                                        modalText: 'Do you want to delete the record ?',
                                                                                                        deleteComment: cm_item?.id
                                                                                                    });
                                                                                                }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    {/* Show When Replying */}
                                                                                    {wallReplyVisibility && Object.keys(wallReplyVisibility).length && wallReplyVisibility[cm_item?.id] ? (
                                                                                        <div>
                                                                                            <Media className="media-post mt-3">
                                                                                                <Media>
                                                                                                    <Media
                                                                                                        object
                                                                                                        src={require("../../assets/images/icons/user-circle.png")}
                                                                                                        alt="User Image"
                                                                                                    />
                                                                                                </Media>
                                                                                                <Media body>
                                                                                                    <FormGroup className="mb-0">
                                                                                                        <Input
                                                                                                            onChange={(e) => { this.handleWallReplyText(cm_item?.id, e.target.value) }}
                                                                                                            bsSize="sm"
                                                                                                            value={wallReplyText[cm_item?.id]}
                                                                                                            className="mb-2"
                                                                                                            type="textarea"
                                                                                                            name="reply"
                                                                                                            placeholder="Write a reply"
                                                                                                        />
                                                                                                        <div className="text-right">
                                                                                                            <div className="text-right">
                                                                                                                <Button
                                                                                                                    disabled={wallReplyText && wallReplyText[cm_item.id] && wallReplyText[cm_item.id].length ? false : true}
                                                                                                                    onClick={() => {
                                                                                                                        if (!this.checkIfLoggedIn()) {
                                                                                                                            this.forceToggleLoginModal();
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        this.props.put_wall_reply({
                                                                                                                            discussion_id: cm_item.id,
                                                                                                                            body: wallReplyText[cm_item.id],
                                                                                                                            business_id: businessId,
                                                                                                                            filter_type: viewWallUpdatesType,
                                                                                                                            edit: wallCommentEdit
                                                                                                                        });
                                                                                                                    }}
                                                                                                                    size="sm"
                                                                                                                    color="primary"
                                                                                                                    className=" mr-2">
                                                                                                                    {'Update'}
                                                                                                                </Button>
                                                                                                                <Button
                                                                                                                    onClick={() => {
                                                                                                                        wallReplyText = {};
                                                                                                                        wallReplyVisibility = {};
                                                                                                                        this.setState({ wallReplyText, wallReplyVisibility, wallCommentEdit: false })
                                                                                                                    }}
                                                                                                                    size="sm"
                                                                                                                    color="primary"
                                                                                                                    className="ml-0">
                                                                                                                    {'Cancel'}
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </FormGroup>
                                                                                                </Media>
                                                                                            </Media>
                                                                                            <hr />
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>

                                                                                {/* Replies to a comment */}
                                                                                <div>
                                                                                    {cm_item?.conversation && Array.isArray(cm_item?.conversation) && cm_item?.conversation?.length ?
                                                                                        cm_item?.conversation.map(cm_item_comment => (
                                                                                            <Media className="media-post">
                                                                                                <Media>
                                                                                                    <Media
                                                                                                        object
                                                                                                        src={cm_item_comment?.current_profile_pic}
                                                                                                        onError={(error) => error.target.src = require("../../assets/images/icons/user-circle.png")}
                                                                                                        alt="User Image"
                                                                                                    />
                                                                                                </Media>
                                                                                                <Media body>
                                                                                                    <Media className="mb-0" heading>
                                                                                                        <div className="d-flex flex-nowrap mx-n2">
                                                                                                            <div className="px-2 flex-grow-1">
                                                                                                                <span className="text-darker fs-14">
                                                                                                                    {cm_item_comment?.sender}{": "}
                                                                                                                </span>
                                                                                                                {/* Comments */}
                                                                                                                <span className="wb-break-word fs-14 font-weight-normal text-dark ff-alt">
                                                                                                                    {cm_item_comment?.body}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Media>
                                                                                                    <div>
                                                                                                        <div className="d-flex flex-nowrap mx-n2 mt-n1">
                                                                                                            <div className="align-self-end flex-grow-1 px-2">
                                                                                                                <span className="fs-14 ff-alt text-tertiary align-middle">
                                                                                                                    {cm_item_comment?.sent_at}
                                                                                                                </span>
                                                                                                            </div>

                                                                                                            {/* Remove Actions if comment by other user  */}
                                                                                                            <div className="align-self-start col-auto px-2" hidden={!this.checkIfLoggedIn()}>
                                                                                                                <EditBtn
                                                                                                                    onClick={() => {
                                                                                                                        wallReplyText = {};
                                                                                                                        wallReplyVisibility = {};
                                                                                                                        wallReplyText[cm_item_comment?.msg_id] = cm_item_comment?.body;
                                                                                                                        wallReplyVisibility[cm_item_comment?.msg_id] = true;
                                                                                                                        this.setState({ wallReplyText, wallReplyVisibility, wallCommentEdit: true });
                                                                                                                    }} />
                                                                                                                <DeleteBtn
                                                                                                                    onClick={() => {
                                                                                                                        this.setState({
                                                                                                                            isToggleModalTrue: true,
                                                                                                                            modalText: 'Do you want to delete the record ?',
                                                                                                                            deleteComment: cm_item_comment?.msg_id
                                                                                                                        });
                                                                                                                    }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {wallReplyVisibility && Object.keys(wallReplyVisibility).length && wallReplyVisibility[cm_item_comment?.msg_id] ? (
                                                                                                        <div>
                                                                                                            <Media className="media-post mt-3">
                                                                                                                <Media>
                                                                                                                    <Media
                                                                                                                        object
                                                                                                                        src={require("../../assets/images/icons/user-circle.png")}
                                                                                                                        alt="User Image"
                                                                                                                    />
                                                                                                                </Media>
                                                                                                                <Media body>
                                                                                                                    <FormGroup className="mb-0">
                                                                                                                        <Input
                                                                                                                            onChange={(e) => { this.handleWallReplyText(cm_item_comment?.msg_id, e.target.value) }}
                                                                                                                            bsSize="sm"
                                                                                                                            value={wallReplyText[cm_item_comment?.msg_id]}
                                                                                                                            className="mb-2 primary ph-normal text-primary"
                                                                                                                            type="textarea"
                                                                                                                            name="reply"
                                                                                                                            placeholder="Write a reply"
                                                                                                                        />
                                                                                                                        <div className="text-right">
                                                                                                                            <div className="text-right">
                                                                                                                                <Button
                                                                                                                                    disabled={wallReplyText[cm_item_comment.msg_id].length ? false : true}
                                                                                                                                    onClick={() => {
                                                                                                                                        if (!this.checkIfLoggedIn()) {
                                                                                                                                            this.forceToggleLoginModal();
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        this.props.put_wall_reply({
                                                                                                                                            discussion_id: cm_item_comment?.msg_id,
                                                                                                                                            body: wallReplyText[cm_item_comment?.msg_id],
                                                                                                                                            business_id: businessId,
                                                                                                                                            filter_type: viewWallUpdatesType,
                                                                                                                                            edit: wallCommentEdit
                                                                                                                                        });
                                                                                                                                    }}
                                                                                                                                    size="sm"
                                                                                                                                    color="primary"
                                                                                                                                    className=" mr-2">
                                                                                                                                    {'Submit'}
                                                                                                                                </Button>
                                                                                                                                <Button
                                                                                                                                    onClick={() => {
                                                                                                                                        wallReplyText = {};
                                                                                                                                        wallReplyVisibility = {};
                                                                                                                                        this.setState({ wallReplyText, wallReplyVisibility, wallCommentEdit: false });
                                                                                                                                    }}
                                                                                                                                    size="sm"
                                                                                                                                    color="primary"
                                                                                                                                    className="ml-0">
                                                                                                                                    {'Cancel'}
                                                                                                                                </Button>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </FormGroup>
                                                                                                                </Media>
                                                                                                            </Media>
                                                                                                            <hr />
                                                                                                        </div>
                                                                                                    ) : null}
                                                                                                </Media>
                                                                                            </Media>
                                                                                        ))
                                                                                        : null}
                                                                                </div>
                                                                            </Media>
                                                                        </Media>
                                                                    ))

                                                                    : null}
                                                            </div>


                                                            {/* Comment box */}
                                                            < div className="mt-3" >
                                                                <Media className="media-post">
                                                                    <Media>
                                                                        <Media
                                                                            object
                                                                            className="img-circle _35x35"
                                                                            src={require("../../assets/images/icons/user-circle.png")}
                                                                            alt="User Image"
                                                                        />
                                                                    </Media>
                                                                    <Media body>
                                                                        <FormGroup className="mb-0">
                                                                            <Input
                                                                                onChange={(e) => { this.handleWallReplyText(item?.id, e.target.value) }}
                                                                                bsSize="sm"
                                                                                value={wallReplyText[item?.id]}
                                                                                className="mb-2 comment-form-control"
                                                                                type="textarea"
                                                                                rows="1"
                                                                                placeholder="Add your comments"
                                                                            />
                                                                            <div className="text-right">
                                                                                <div className="text-right">
                                                                                    <Button
                                                                                        disabled={this.state.wallReplyText && this.state.wallReplyText[item && item.id] ? false : true}
                                                                                        onClick={() => {
                                                                                            if (!this.checkIfLoggedIn()) {
                                                                                                this.forceToggleLoginModal();
                                                                                                return false;
                                                                                            }
                                                                                            this.props.post_wall_comments({
                                                                                                business_id: businessId,
                                                                                                filter_type: viewWallUpdatesType,
                                                                                                action_id: item.id,
                                                                                                body: this.state.wallReplyText[item.id]
                                                                                            });
                                                                                            this.timeOutWallReplyText = setTimeout(() => {
                                                                                                this.state.wallReplyText[item.id] = '';
                                                                                                this.setState({ wallReplyText });
                                                                                            }, 1000);
                                                                                        }}
                                                                                        size="sm"
                                                                                        color="primary">
                                                                                        {'Submit'}
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Media>
                                                                </Media>
                                                            </div>
                                                        </div>
                                                    )
                                                })

                                            ) : 
                                               ( wallLoading ? 
                                                (<div className="alert alert-primary fade show" role="alert">
                                                    {'Loading...'}
                                                </div>)
                                             : <div className="bg-white p-3">
                                                <h2 className="text-secondary-dark">No results found!</h2>
                                            </div>)
                                            
                                        }

                                        {/* {wallLoading && (
                                            <div className="alert alert-primary fade show" role="alert">
                                                {'Loading...'}
                                            </div>
                                        )} */}

                                    </TabPane>
                                    <TabPane tabId="qa">
                                        <QandATab businessId={businessId} forceToggleLoginModal={this.forceToggleLoginModal} />
                                    </TabPane>
                                    <TabPane tabId="ownerInfo">
                                        <h2 className="text-dark mt-4 mb-3 fs-40">About this Business <span className="text-tertiary ff-base fs-14"> Provided by <strong className="text-dark font-weight-bold ff-alt">Owner</strong></span></h2>
                                        <div className="bg-white text-dark p-3 ff-alt">
                                            <i className="text-tertiary">There's no information uploaded by the owner yet.</i>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="discussion">
                                        <DiscussionsTab businessDetails={this.props.business_details} forceToggleLoginModal={this.forceToggleLoginModal} />
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Container>
                </section >

                {/* View Media Modal */}
                < Modal isOpen={this.state.viewMediaModalToggle} toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })
                }>
                    <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader>
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}>
                            <div className="item h-100 d-flex flex-column justify-content-center">
                                <div className="text-center">
                                    <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/content/imgres.591c09dcddc5409e258b1a1c4f615dc48f190802.4a31461561c2316bc0df6c04346f9426f67a4a93.jpg" alt="" />
                                </div>
                            </div>
                            <div className="item h-100 d-flex flex-column justify-content-center">
                                <div className="text-center">
                                    <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/content/image.86e21d0666615fe54c81bf87a167ca9609dd62ec.jpg" alt="" />
                                </div>
                            </div>
                            <div className="item h-100 d-flex flex-column justify-content-center">
                                <div className="text-center">
                                    <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/content/image.4376fbaea82bd6d002686a1900c479b3414b45e7.jpg" alt="" />
                                </div>
                            </div>
                        </Carousel>
                    </ModalBody>
                </Modal >

                {/* Create a new discussion Modal */}
                < Modal isOpen={this.state.createNewDisModalToggle} toggle={() => this.setState({ createNewDisModalToggle: !this.state.createNewDisModalToggle })}>
                    <ModalHeader className="px-0" toggle={() => this.setState({ createNewDisModalToggle: !this.state.createNewDisModalToggle })}>
                        CREATE NEW DISCUSSION
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                onChange={(e) => { this.setState({ dicussionThisText: e.target.value }) }}
                                className="border text-dark ph-grey"
                                value={this.state.dicussionThisText}
                                bsSize="sm"
                                type="text"
                                placeholder="Enter title for discussion"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                onChange={(e) => { this.setState({ dicussionThisBody: e.target.value }) }}
                                value={this.state.dicussionThisBody}
                                className="border text-dark ph-grey font-weight-bold"
                                bsSize="sm"
                                type="textarea"
                                rows="10"
                            />
                        </FormGroup>
                        <div>
                            <Button
                                onClick={() => { this.handleCloseAndFetch(); }}
                                // onClick={this.handleCloseAndFetch}
                                color="transparent"
                                size="sm"
                                title="Upload Media">
                                <FontAwesomeIcon icon="camera" />
                            </Button>
                        </div>


                        {/* Uploaded media shown here -- for discussion*/}
                        {mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0 &&
                            <div className="border mb-3">
                                <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                    {
                                        mediaSet.map(item => {
                                            return (
                                                <Col>
                                                    <div className="d-flex pr-3 m-2" role="button" onClick={() => this.setState({ viewMyPhotosModal: true })}>
                                                        <div>
                                                            <img className="img-fluid img-thumbnail 12345" src={this.checkIfVideo(item)} alt="Uploaded media" />
                                                        </div>

                                                        <div className="mx-n3 mt-2">
                                                            <Button
                                                                color="delete-media _alt"
                                                                size="sm"
                                                                title="Remove Media"
                                                                onClick={(e) => { this.removeFromMedia(e, item , item?.type) }}
                                                            >
                                                                <FontAwesomeIcon icon="trash-alt" />{" "}
                                                            </Button>
                                                        </div>

                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <Button onClick={this.handleNewDiscussion} color="primary"
                            disabled={this.state.dicussionThisText === "" || this.state.dicussionThisBody === "" ? true : false}
                        >{'Submit'}</Button>
                    </ModalFooter>
                </Modal >

                {/* Share By Email Modal Popup */}
                <Modal
                    size="sm"
                    isOpen={this.state.shareByEmailModalToggle}
                    toggle={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })
                    }
                >
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() =>
                            this.setState({
                                shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                            })
                        }
                    >
                        Share by Email
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="text"
                                name="shareName"
                                placeholder="Your name"
                                value={this.state.shareName}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareName && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareName}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="email"
                                name="shareEmail"
                                placeholder="Recipient's email"
                                value={this.state.shareEmail}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareEmail && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareEmail}
                                </div>
                            )}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <div className="text-right">
                            <Button color="primary" onClick={this.handleOnSubmitShareByEmail}>
                                Share
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Explore Menu Items Modal */}
                < Modal isOpen={this.state.exploreMenuItemsModalToggle} toggle={() => this.setState({ exploreMenuItemsModalToggle: !this.state.exploreMenuItemsModalToggle })} size="lg" >
                    <ModalHeader toggle={() => this.setState({ exploreMenuItemsModalToggle: !this.state.exploreMenuItemsModalToggle })}>Menu Items</ModalHeader>
                    <ModalBody>
                        <div className="mb-5">
                            <Nav className="active-transparent" tabs>
                                <NavItem>
                                    <NavLink className="text-truncate" href="#"
                                        active={this.state.activeMenuType === "mainMenu"}
                                        onClick={() => {
                                            this.setState({ activeMenuType: "mainMenu" })
                                        }}>
                                        <span className="font-weight-bold ff-base fs-16">
                                            Main Menu
                                        </span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <TabContent activeTab={this.state.activeMenuType}>
                            <TabPane tabId="mainMenu">
                                <CollapseBasic title="Cakes" containerClass="type-secondary" bodyClass="fs-14 mt-3 text-secondary" size="sm" isOpen={false}>
                                    <Row>
                                        {/* Repeat this for items */}
                                        {/* Use lg={6} default and lg={12} when viewing images below column*/}
                                        <Col className="mb-3" lg={12}>
                                            <Row noGutters>
                                                <Col xs={12} lg={6}>
                                                    <div className="d-flex flex-nowrap mx-n2">
                                                        <div className="px-2">
                                                            <img width="50" src={'https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png'} alt="" />
                                                        </div>
                                                        <div className="px-2">
                                                            <div className="d-flex">
                                                                <div className="mr-2">
                                                                    <a className="text-secondary-dark mr-2 font-weight-bold" href="#">Dq Sheet Cakes</a>

                                                                    <div className="text-secondary">
                                                                        <img src={'https://userdatawikireviews.s3.amazonaws.com/images/star/gold/norating.png'} alt="3.5 Rating" className="mr-2" />
                                                                        <span>0 reviews</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-secondary-dark">
                                                                <p className="fs-14 mb-0">
                                                                    whatever the occasion-birthday, retirement, anniversary, welcome home- there is a dq cake that's jus right. our irrersistble fudge nad crunch between layers of vanilla and chocolate soft serve are as sure way to make your occasion memorable. dq cakes are available in sizes to serve 8 to 24 guest. cake design may vary by dq restaurant location. servings 8"rounds m= 8-10, 10"rounds = 12-16
                                                                </p>
                                                            </div>
                                                            <div className="mt-1">
                                                                <Button color="link" className="text-secondary-dark fs-14 font-weight-bold p-0">
                                                                    <FontAwesomeIcon icon="camera" className="mr-2" />
                                                                    Add Image
                                                                </Button>
                                                                <Button color="link" className="text-secondary-dark fs-14 font-weight-bold p-0">
                                                                    View Images
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>

                                                {/* Show when clicked on view images */}
                                                <Col xs={12} lg={6}>
                                                    <div className="pl-3 mt-3 mt-lg-0">
                                                        <div className="d-flex flex-nowrap mx-n1">
                                                            <div className="px-1 col">
                                                                <RBCrousel
                                                                    responsive={responsive.videoModal}
                                                                    infinite={true}
                                                                    autoPlay={false}
                                                                    swipeable={true}
                                                                    showDots={false}
                                                                    arrows={true}>
                                                                    <div className="item">
                                                                        <div className="text-center">
                                                                            <img height="200" src="https://userdatawikireviews.s3.amazonaws.com/media/content/imgres.591c09dcddc5409e258b1a1c4f615dc48f190802.4a31461561c2316bc0df6c04346f9426f67a4a93.jpg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="item">
                                                                        <div className="text-center">
                                                                            <img height="200" src="https://userdatawikireviews.s3.amazonaws.com/media/content/image.86e21d0666615fe54c81bf87a167ca9609dd62ec.jpg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="item">
                                                                        <div className="text-center">
                                                                            <img height="200" src="https://userdatawikireviews.s3.amazonaws.com/media/content/image.4376fbaea82bd6d002686a1900c479b3414b45e7.jpg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                </RBCrousel>
                                                            </div>

                                                            <div className="text-right px-1 d-flex flex-column">
                                                                <div>
                                                                    <Button color="transparent" title="Close View">
                                                                        <FontAwesomeIcon icon="times" className="text-secondary" />
                                                                    </Button>
                                                                </div>
                                                                <div className="mt-auto">
                                                                    <DeleteBtn />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CollapseBasic>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                </Modal >

                {/* Add New Menu Item Modal */}


                {/* Manage this Page Part 1 Modal Popup  */}
                <Modal
                    size="lg"
                    isOpen={this.state.manageBusinessModalToggleOne}
                    toggle={() => this.setState({ manageBusinessModalToggleOne: !this.state.manageBusinessModalToggleOne })}
                >
                    <ModalHeader className="px-0" toggle={() => this.setState({ manageBusinessModalToggleOne: !this.state.manageBusinessModalToggleOne })}>
                        SIGN UP FOR WIKIREVIEWS
                    </ModalHeader>
                    <ModalBody>
                        <div className="circle-step">
                            <ul>
                                <li className="active">
                                    <strong>1</strong>
                                    <span>Find Your Business</span>
                                </li>
                                <li>
                                    <strong>2</strong>
                                    <span>Create Your Account</span>
                                </li>
                                <li>
                                    <strong>3</strong>
                                    <span>Verify Your Identity</span>
                                </li>
                            </ul>
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-start px-0">
                        <div className="text-right w-100">
                            <Button color="link" className="mw">
                                Skip this Step
                            </Button>
                            <Button color="primary" className="mw">
                                Save & Continue
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Manage this Page 2 Modal Popup  */}
                <Modal
                    size="lg"
                    isOpen={this.state.manageBusinessModalToggleTwo}
                    toggle={() => this.setState({ manageBusinessModalToggleTwo: !this.state.manageBusinessModalToggleTwo })}
                >
                    <AvForm onValidSubmit={this.handleNewBusinessSubmit} onInvalidSubmit={() => { console.log('Invalid submit') }}>
                        <ModalHeader className="px-0" toggle={() => this.setState({ manageBusinessModalToggleTwo: !this.state.manageBusinessModalToggleTwo })}>
                            {'SIGN UP FOR WIKIREVIEWS'}
                        </ModalHeader>
                        <ModalBody>
                            <div className="circle-step">
                                <ul>
                                    <li>
                                        <strong>1</strong>
                                        <span>Find Your Business</span>
                                    </li>
                                    <li className="active">
                                        <strong>2</strong>
                                        <span>Create Your Account</span>
                                    </li>
                                    <li>
                                        <strong>3</strong>
                                        <span>Verify Your Identity</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <FormGroup row>
                                    <Col sm={4}>
                                        <Label className="text-dark font-weight-normal ff-alt fs-14">
                                            Contact Name
                                            <small className="d-block">The person managing this account</small>
                                        </Label>
                                    </Col>
                                    <Col sm={8}>
                                        <Row form>
                                            <Col sm>
                                                <AvField
                                                    bsSize="sm"
                                                    className="primary glowy-form-control-alt"
                                                    name="first-name"
                                                    type="text"
                                                    value={businessValues.firstName}
                                                    placeholder="First Name"
                                                    validate={{ required: { value: true, errorMessage: "Please enter first name" }, }}
                                                />
                                                {/* <Input size="sm" className="primary" type="text" placeholder="First Name" /> */}
                                            </Col>
                                            <Col sm>
                                                <AvField
                                                    bsSize="sm"
                                                    className="primary glowy-form-control-alt"
                                                    name="last-name"
                                                    type="text"
                                                    value={businessValues.lastName}
                                                    placeholder="Last Name"
                                                    validate={{ required: { value: true, errorMessage: "Please enter last name" }, }}
                                                />
                                                {/* <Input size="sm" className="primary" type="text" placeholder="Last Name" /> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={4}>
                                        <Label className="text-dark font-weight-normal ff-alt fs-14">
                                            E-mail Address
                                        </Label>
                                    </Col>
                                    <Col sm={8}>
                                        <Row form>
                                            <Col sm>
                                                <AvField
                                                    bsSize="sm"
                                                    className="primary glowy-form-control-alt"
                                                    name="email"
                                                    type="email"
                                                    value={businessValues.email}
                                                    placeholder="Your email address"
                                                    validate={{ email: true, required: { value: true, errorMessage: "Please enter an email" }, }}
                                                />
                                                {/* <Input size="sm" className="primary" type="email" placeholder="Your email address" /> */}
                                            </Col>
                                            <Col sm>
                                                <AvField
                                                    bsSize="sm"
                                                    className="primary glowy-form-control-alt"
                                                    name="confirm-email"
                                                    type="email"
                                                    value={businessValues.confirmEmail}
                                                    placeholder="Re-enter email address"
                                                    validate={{ email: true, required: { value: true, errorMessage: "Please confirm the email" }, }}
                                                />
                                                {/* <Input size="sm" className="primary" type="email" placeholder="Reenter email address" /> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={4}>
                                        <Label className="text-dark font-weight-normal ff-alt fs-14">
                                            Password
                                        </Label>
                                    </Col>
                                    <Col sm={8}>
                                        <Row form>
                                            <Col sm>
                                                <AvField
                                                    bsSize="sm"
                                                    className="primary glowy-form-control-alt"
                                                    name="password"
                                                    value={businessValues.password}
                                                    type="password"
                                                    placeholder="Enter Password"
                                                    validate={{ required: { value: true, errorMessage: "Please enter a password" }, }}
                                                />
                                                {/* <Input size="sm" className="primary" type="password" placeholder="Enter Password" /> */}
                                            </Col>
                                            <Col sm>
                                                <AvField
                                                    bsSize="sm"
                                                    className="primary glowy-form-control-alt"
                                                    name="confirm-password"
                                                    value={businessValues.confirmPassword}
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    validate={{ required: { value: true, errorMessage: "Please confirm the password" }, }}
                                                />
                                                {/* <Input size="sm" className="primary" type="password" placeholder="Confirm Password" /> */}
                                            </Col>
                                        </Row>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={4}>&nbsp;</Col>
                                    <Col sm={8}>
                                        <div style={{ maxWidth: '300px' }}>
                                            <div className="mb-2">
                                                {captcha_items && captcha_items?.captcha && captcha_items?.captcha?.new_cptch_image ? (
                                                    <img className="w-100" src={`https://wikireviews.com${captcha_items?.captcha?.new_cptch_image}`} alt="captcha" />
                                                ) : null}
                                            </div>
                                            <AvField
                                                bsSize="sm"
                                                className="primary glowy-form-control-alt"
                                                name="captcha-code"
                                                type="text"
                                                value={businessValues.verificationCaptcha}
                                                placeholder="Enter the above code here"
                                                validate={{ required: { value: true, errorMessage: "Please enter the captcha code" }, }}
                                            />
                                            {/* <Input size="sm" className="primary" type="text" placeholder="Enter the above code here" /> */}
                                            <FormText color="dark" className="mt-2">
                                                Can't read the image? Click{" "}
                                                <span onClick={() => { this.props.getCaptcha() }} role="button">
                                                    <FontAwesomeIcon icon="sync" />
                                                </span>{" "}
                                                to refresh
                                            </FormText>
                                        </div>
                                    </Col>
                                </FormGroup>
                            </div>
                        </ModalBody>
                        <ModalFooter className="justify-content-start px-0">
                            {/* <Button color="link" className="mw" toggle={() => this.setState({ manageBusinessModalToggleTwo: !this.state.manageBusinessModalToggleTwo })}>
                                <FontAwesomeIcon icon="angle-left" /> Back
                        </Button> */}
                            <Button type="submit" color="primary" className="mw ml-auto">
                                Signup
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </Modal>

                {/* Manage this Page Part 3 Modal Popup  */}
                <Modal
                    size="lg"
                    isOpen={this.state.manageBusinessModalToggleThree}
                    toggle={() => this.setState({ manageBusinessModalToggleThree: !this.state.manageBusinessModalToggleThree })}
                >
                    <ModalHeader className="px-0" toggle={() => this.setState({ manageBusinessModalToggleThree: !this.state.manageBusinessModalToggleThree })}>
                        SIGN UP FOR WIKIREVIEWS
                    </ModalHeader>
                    <ModalBody>
                        <div className="circle-step">
                            <ul>
                                <li>
                                    <strong>1</strong>
                                    <span>Find Your Business</span>
                                </li>
                                <li>
                                    <strong>2</strong>
                                    <span>Create Your Account</span>
                                </li>
                                <li className="active">
                                    <strong>3</strong>
                                    <span>Verify Your Identity</span>
                                </li>
                            </ul>
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-start px-0">
                        <Button color="link" className="mw">
                            <FontAwesomeIcon icon="angle-left" /> Back
                        </Button>
                        <Button color="primary" className="mw ml-auto">
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* <SocialConnectionCheck /> */}
                <Modal
                    isOpen={this.state.shareSocialMediaModalToggle}
                // toggle={() => this.setState({ shareSocialMediaModalToggle: !this.state.shareSocialMediaModalToggle })}
                >
                    <div className="modal-header" style={{ textTransform: 'none' }}>
                        <h2 className="modal-title fs-32 lh-1 border-bottom border-white">
                            If you would like to share this review on social media, please connect your Twitter and Facebook accounts to WikiReviews.
                        </h2>
                    </div>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.setState({
                            shareSocialMediaModalToggle: false
                        })}>Okay</Button>
                    </ModalFooter>
                </Modal>

                {/* View People working here Modal */}
                <Modal
                    size="sm"
                    centered
                    scrollable
                    isOpen={this.state.viewPeopleWorkingHereModal}
                    toggle={() => {
                        this.setState({ viewPeopleWorkingHereModal: !this.state.viewPeopleWorkingHereModal })
                    }}>
                    <ModalHeader className="text-uppercase px-0" toggle={() => this.setState({ viewPeopleWorkingHereModal: !this.state.viewPeopleWorkingHereModal })}>People working here</ModalHeader>
                    <ModalBody>
                        <div>
                            <h2 className="text-secondary fs-38 border-bottom border-secondary pb-2 mb-2">Present Employee</h2>
                            <ul className="fs-14 list-unstyled mb-2">
                                {this.fetchPeopleWorkingList("present")}


                            </ul>
                        </div>
                        <div>
                            <h2 className="text-secondary fs-38 border-bottom border-secondary pb-2 mb-2">Former Employee</h2>
                            <ul className="fs-14 list-unstyled mb-2">
                                {this.fetchPeopleWorkingList("former")}
                            </ul>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapState = (state) => {
    return {
        loggedInUser: state.user.my_profile,
        facebook: state.connections.enable_facebook,
        twitter: state.connections.enable_tiwitter,
        loggedInUsername: state.user?.my_profile?.user?.username,
        facebook_connection: state.user?.my_profile?.facebook_connection,
        twitter_connection: state.user?.my_profile?.twitter_connection,
        business_details: state.business.business_details,
        business_weather: state.business.business_weather,
        simillar_business: state.business.simillar_business,
        working_employee: state.business.working_employee,
        chart_star_ratings: state.business.chart_star_ratings,
        tips_and_warnings: state.business.tips_and_warnings,
        other_offers: state.business.other_offers,
        fetch_photos: state.myProfile.fetch_photos,
        fetch_videos: state.myProfile.fetch_videos,
        fetch_taxonomy: state.myProfile.fetch_taxonomy,
        upload_scroll: state.myProfile.upload_scroll,
        upload_media_data: state.myProfile.upload_media_data,
        fetch_deleted_data: state.myProfile.fetch_deleted_data,
        captcha_items: state.business.captcha_items,
        wall_data: state.business.wall_data,
        my_profile: state.user.my_profile,
        business_rating: state.business.business_rating,
        discuss_this_page: state.business.discuss_this_page,
        back_to_listing: state.business.back_to_listing,
        videos_children: state.business.videos_children && state.business.videos_children[0],
        qa_dropdown: state.business.qa_dropdown,
        fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
        new_sub_categories: state.business.new_sub_categories,
        filter_data_category: state.business.filter_data_category,
        fetch_data_categories: state.business.fetch_data_categories,
        friends_profile_recommend: state.shared.friends_profile_recommend,
        friends_profile_want: state.shared.friends_profile_want,
        friends_profile_favourite: state.shared.friends_profile_favourite,
        get_reveiewData: state.user.get_reveiewData,
        auth_data: state.auth.auth_login,
        my_privacy_setting: state.user.my_privacy_setting,
        my_privacy_setting_status: state.user.my_privacy_setting_status,
        toggleStarRating: state.utils.toggleStarRating,
        business_reviewDetail: state.business.business_reviewDetail,
        cat_taxonomy_list: state.item_detail.cat_taxonomy_list,
        menuItemChanged: state.business.menuItemChanged,
        uploadPercentage: state.shared.uploadPercentage
    }
}

const mapProps = (dispatch) => ({
    get_business_detail: (id) => dispatch(get_business_detail(id)),
    get_simillar_business: ({ cat, id, name }) => dispatch(get_simillar_business({ cat, id, name })),
    get_business_location_weather: (data) => dispatch(get_business_location_weather(data)),
    post_tips_and_warnings: (data) => dispatch(post_tips_and_warnings(data)),
    delete_tips_and_warnings: ({ deleteId, id }) => dispatch(delete_tips_and_warnings({ deleteId, id })),
    fetch_other_offers: ({ listing_id, offer_type }) => dispatch(fetch_other_offers({ listing_id, offer_type })),
    get_working_employee: ({ business_id }) => dispatch(get_working_employee({ business_id })),
    post_other_offers: ({ data, listing_id, offer_type }) => dispatch(post_other_offers({ data, listing_id, offer_type })),
    post_people_working: ({ data, business_id }) => dispatch(post_people_working({ data, business_id })),
    get_discuss_this_page: ({ id, pageId }) => dispatch(get_discuss_this_page({ id, pageId })),
    post_comment_on_offers: (data) => dispatch(post_comment_on_offers(data)),

    fetch_media_data: (type, album, page, user_profile) => dispatch(fetch_media_data(type, album, page, user_profile)),
    fetch_taxonomy_category: () => dispatch(fetch_taxonomy_category()),
    media_upload_scroll: ({ type, album, page, force_update }) => dispatch(media_upload_scroll({ type, album, page, force_update })),
    fetch_delete_media: ({ type, page }) => dispatch(fetch_delete_media({ type, page })),
    upload_media_files: (type, data, index) => dispatch(upload_media_files(type, data, index)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    clear_media_dispatch: () => dispatch(clear_media_dispatch()),
    delete_mutiple_media: ({ type, data }) => dispatch(delete_mutiple_media({ type, data })),
    single_delete_from_upload: (id,type) => dispatch(single_delete_from_upload(id,type)),
    embedNewLink: ({ data }) => (dispatch(embedNewLink({ data }))),
    getCaptcha: () => dispatch(getCaptcha()),
    post_business_sign_up: (data) => dispatch(post_business_sign_up(data)),
    get_business_wall_data: ({ id, filter_type, page, force }) => dispatch(get_business_wall_data({ id, filter_type, page, force })),
    post_wall_comments: ({ action_id, body, business_id, filter_type }) => dispatch(post_wall_comments({ action_id, body, business_id, filter_type })),
    delete_wall_comment: ({ action_id, business_id, filter_type }) => dispatch(delete_wall_comment({ action_id, business_id, filter_type })),
    save_ratings_vote: (data) => dispatch(save_ratings_vote(data)),
    publish_post: (data, businessId) => dispatch(publish_post(data, businessId)),
    fetch_detailedstarrating: (data) => dispatch(fetch_detailedstarrating(data)),
    put_wall_reply: ({ discussion_id, body, business_id, filter_type, edit }) => dispatch(put_wall_reply({ discussion_id, body, business_id, filter_type, edit })),
    get_tips_and_warnings: (id) => dispatch(get_tips_and_warnings(id)),
    post_discuss_this_page: (data) => dispatch(post_discuss_this_page(data)),
    get_discussions: (url) => dispatch(get_discussions(url)),
    get_business_QA_dropdown: (id) => dispatch(get_business_QA_dropdown(id)),
    get_business_review_detail: (id, filter) => dispatch(get_business_review_detail(id, filter)),
    delete_special_offer: ({ id, listing_id, offer_type }) => dispatch(delete_special_offer({ id, listing_id, offer_type })),
    get_media_children: (category, type, subCategory) => dispatch(get_media_children(category, type, subCategory)),
    get_id_from_slug: ({ type, slug }) => dispatch(get_id_from_slug({ type, slug })),
    fetch_taxonomy_list: (category, type) => dispatch(fetch_taxonomy_list(category, type)),
    upload_media_filesData: (url, type, data, index) => dispatch(upload_media_filesData(url, type, data, index)),
    fetch_sub_categories: ({ sub_cat, level }) => dispatch(fetch_sub_categories({ sub_cat, level })),
    filter_categories_data: ({ category, sub_cat, level }) => dispatch(filter_categories_data({ category, sub_cat, level })),
    fetch_categories_data: (categoryId, subcategoryId, type, index) => dispatch(fetch_categories_data(categoryId, subcategoryId, type, index)),
    clear_category: () => dispatch(clear_category()),
    clear_subcategory: () => dispatch(clear_subcategory()),
    get_friends_profile: (type, businessID, pageNo) => dispatch(get_friends_profile(type, businessID, pageNo)),
    put_special_offer: ({ id, data, listing_id, offer_type }) => dispatch(put_special_offer({ id, data, listing_id, offer_type })),
    my_profile_info: () => dispatch(my_profile()),
    updateFeedReactions: (params) =>
        dispatch(updateFeedReactions(params)),
    get_reviewgroup: (assetType, postId, type) =>
        dispatch(get_reviewgroup(assetType, postId, type)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    add_cite_source: (data) => dispatch(add_cite_source(data)),
    get_my_privacy_list: () => dispatch(get_my_privacy_list()),
    delete_people: (id, businessId) =>
        dispatch(delete_people(id, businessId)),
    get_category_taxonomy_listing: (categoryType, types, mainTaxonomy) => dispatch(get_category_taxonomy_listing(categoryType,types, mainTaxonomy)),
    update_caption_and_categories: ({ id, data }) => dispatch(update_caption_and_categories({ id, data })),
    menuItemChangedStatus:(data)=>dispatch(menuItemChangedStatus(data))
});


export default connect(mapState, mapProps)(BusinessDetails);
