import React from "react";
import { Media } from "reactstrap";
import { useState } from "react";
import { ReplyEditComment } from "./ReplyEditComment";
import EditBtn from "../../atoms/editBtn";
import DeleteBtn from "../../atoms/deleteBtn";

export const ReviewComments = ({
  feed,
  comments,
  onDeleteComment,
  onEditComment_Reply,
  onCommentReply,
  myProfile,
  isReviewProfessional
}) => {
  const [commentIdToEdit, setCommentIdToEdit] = useState(null);
  const [commentIdToReply, setCommentIdToReply] = useState(null);

  const checkIfLoggedIn = () => {
    const authId = localStorage.getItem('profileId');
    if (myProfile && myProfile.id && authId && (authId == myProfile.id)) {
      return true;
    } else {
      return false;
    }
  }

  const checkOwner = ({ conversation, type }) => {
    if (checkIfLoggedIn() && myProfile?.user?.username) {
      if (type == "comment" && conversation?.sender?.username == myProfile.user.username) {
        return true;
      }
      if (type == "reply" && conversation?.sender?.username == myProfile.user.username)
        return true;
    }
    return false;
  }

  return (
    <>
      {comments.map((comment) => (
        <div key={comment.id}>
          <Media className="media-post mt-3">
            <Media>
              <img
                className="media-object"
                src={
                  comment?.sender?.current_profile_pic
                    ? comment?.sender?.current_profile_pic
                    : require("../../../assets/images/icons/user-circle.png")
                }
                onError={(error) =>
                  (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                }
                alt="user"
              />
            </Media>
            <Media body>
              <Media className="mb-0" heading>
                <div className="d-flex flex-nowrap mx-n2">
                  <div className="px-2 flex-grow-1">
                    <span className="text-darker fs-14">
                      {comment?.sender?.full_name && comment.sender?.full_name}
                      :&nbsp;
                    </span>
                    {/* Comments */}
                    <span className="wb-break-word fs-14 font-weight-normal text-dark ff-alt">
                      {comment?.body && comment?.body}
                    </span>
                  </div>
                </div>
              </Media>
              <div>
                <div className="d-flex flex-nowrap mx-n2 mt-n1">
                  <div className="align-self-end flex-grow-1 px-2">
                    {/* Reply Button */}
                    <button
                      className="btn btn-link btn-sm p-0 font-weight-bold text-darker"
                      onClick={() => {
                        setCommentIdToEdit(null);
                        setCommentIdToReply(comment.id);
                      }}
                    >
                      Reply
                    </button>
                    <span className="fs-14 ff-alt text-tertiary align-middle">
                      <span>{" - "}</span>
                      {comment?.sent_at && comment.sent_at}
                    </span>
                  </div>

                  {/* Remove Actions if comment by other user  */}
                  {(checkOwner({ conversation: comment, type: "comment" }))
                    ?
                    <div className="align-self-start col-auto px-2">
                      {!isReviewProfessional ? <EditBtn
                        onClick={() => {
                          setCommentIdToReply(null);
                          setCommentIdToEdit(comment?.id);
                        }}
                      /> : ""}
                      <DeleteBtn
                        onClick={() => {
                          onDeleteComment(comment?.id, "comment", isReviewProfessional);
                        }}
                      />
                    </div>
                    : ""}
                </div>
                {comment?.id && commentIdToEdit === comment?.id ? (
                  <ReplyEditComment
                    id={comment?.id}
                    body={comment?.body}
                    userImgSource={
                      myProfile?.current_profile_pic
                        ? myProfile?.current_profile_pic
                        : require("../../../assets/images/icons/user-circle.png")
                    }
                    isUpdating={true}
                    onCancel={() => setCommentIdToEdit(null)}
                    onSubmit={(body) => {
                      onEditComment_Reply({
                        body,
                        id: comment?.id,
                        review: feed?.action?.id,
                        method: "PUT",
                        isReviewProfessional
                      });
                      setCommentIdToEdit(null);
                    }}
                  />
                ) : null}
                {comment?.id && commentIdToReply === comment?.id ? (
                  <ReplyEditComment
                    id={comment?.id}
                    body={""}
                    userImgSource={
                      myProfile?.current_profile_pic
                            ? myProfile?.current_profile_pic
                            : require("../../../assets/images/icons/user-circle.png")
                    }
                    isUpdating={false}
                    onCancel={() => setCommentIdToReply(null)}
                    onSubmit={(body) => {
                      onCommentReply({
                        body,
                        id: comment?.id,
                        review: feed?.action?.id,
                        isReviewProfessional
                      });
                      setCommentIdToReply(null);
                    }}
                  />
                ) : null}
              </div>
              {comment.comment_reply &&
                comment.comment_reply.map((reply) => (
                  /* Replies to a comment */
                  <Media className="media-post mt-2 fs-13">
                    <Media>
                      <img
                        className="media-object"
                        src={
                          reply?.sender?.current_profile_pic
                            ? reply?.sender?.current_profile_pic
                            : require("../../../assets/images/icons/user-circle.png")
                        }
                        onError={(error) =>
                          (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                        }
                        alt="user"
                      />
                    </Media>
                    <Media body>
                      <Media className="mb-0" heading>
                        <div className="d-flex flex-nowrap mx-n2">
                          <div className="px-2 flex-grow-1">
                            <span className="text-darker fs-13">
                              {reply.sender?.full_name &&
                                reply.sender?.full_name}
                              :&nbsp;
                            </span>
                            {/* Comments */}
                            <span className="wb-break-word fs-13 font-weight-normal text-dark ff-alt">
                              {reply.body}
                            </span>
                          </div>
                        </div>
                      </Media>
                      <div>
                        <div className="d-flex flex-nowrap mx-n2 mt-n1">
                          <div className="align-self-end flex-grow-1 px-2">
                            {/* Reply Button */}
                            <button
                              className="btn btn-link btn-sm p-0 font-weight-bold text-darker fs-13"
                              onClick={() => {
                                setCommentIdToEdit(null);
                                setCommentIdToReply(reply?.id);
                              }}
                            >
                              Reply
                            </button>
                            <span className="ff-alt text-dark align-middle">
                              <span>{" - "}</span>
                              {reply.sent_at}
                            </span>
                          </div>

                          {/* Remove Actions if comment by other user  */}
                          {(checkOwner({ conversation: reply, type: "reply" }))
                            ?
                            <div className="align-self-start col-auto px-2">
                              {!isReviewProfessional ? <EditBtn
                                onClick={() => setCommentIdToEdit(reply?.id)}
                              /> : ""}
                              <DeleteBtn
                                onClick={() =>
                                  onDeleteComment(reply.id, "comment", isReviewProfessional)
                                }
                              />
                            </div>
                            : ""}
                          
                        </div>
                        {reply?.id && commentIdToEdit === reply?.id ? (
                          <ReplyEditComment
                            id={reply?.id}
                            body={reply?.body}
                            userImgSource={
                              myProfile?.current_profile_pic
                                    ? myProfile?.current_profile_pic
                                    : require("../../../assets/images/icons/user-circle.png")
                            }
                            isUpdating={true}
                            onCancel={() => setCommentIdToEdit(null)}
                            onSubmit={(body) => {
                              onEditComment_Reply({
                                body,
                                id: reply?.id,
                                review: feed?.action?.id,
                                method: "PUT",
                                isReviewProfessional
                              });
                              setCommentIdToEdit(null);
                            }}
                          />
                        ) : null}
                        {reply?.id && commentIdToReply === reply?.id ? (
                          <ReplyEditComment
                            id={reply?.id}
                            body={""}
                            userImgSource={
                              myProfile?.current_profile_pic
                                    ? myProfile?.current_profile_pic
                                    : require("../../../assets/images/icons/user-circle.png")
                            }
                            isUpdating={false}
                            onCancel={() => setCommentIdToReply(null)}
                            onSubmit={(body) => {
                              onCommentReply({
                                body,
                                review: feed?.action?.id,
                                id: comment?.id,
                                isReviewProfessional
                              });
                              setCommentIdToReply(null);
                            }}
                          />
                        ) : null}
                      </div>
                    </Media>
                  </Media>
                ))}
            </Media>
          </Media>
        </div>
      ))}
    </>
  );
};
