/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Alert,
  Label,
  InputGroupAddon,
  Progress,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { updatePhoneNumber } from "../../actions/user";
import { toast } from "react-toastify";
import Moment from "react-moment";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {
  // GOOGLE_CLIENT_ID,
  FACEBOOK_APP_ID, ONESIGNAL_APP_ID,
} from "../../utils/constants";
import TwitterLogin from "react-twitter-login";
import CreateAReview from "../oragnisms/home/CreateAReview";
import MyStatistics from "../oragnisms/home/MyStatistics";
import Feed from "../oragnisms/home/Feed";
// import MyPosts from "../oragnisms/home/MyPosts";
import CollageItems from "../oragnisms/home/CollageItems";
import RecentActivity from "../oragnisms/home/RecentActivity";
import Posts from "../molecules/dashboard/posts";
import Actors from "../molecules/dashboard/actors";
import MovieModal from "../oragnisms/homepage/movieModal";
import AppHeader from "../oragnisms/dashboard/appHeader";
import Connections from "../oragnisms/home/Connections";
import {
  fetch_connections,
  updateFacebook,
  updateTiwitter,
} from "../../actions/connections";
import { connect } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import CommunityModal from "../oragnisms/homepage/communityModal";
import MyFriends from "../oragnisms/home/MyFriends";
import RateCrpModal from "../oragnisms/homepage/rateCrpModal";

import {
  initFetchCategories,
  initFetchProductCategories,
  initFetchProfessionalsCategories,
  initFetchMenuCategories,
  initFetchMenuSubCategories,
  allMovieCategories,
  allMenuCategories,
  allSubMenuCategories,
  allProductCategories,
  allProfessionalsCategories,
  initFetchBusinessCategories,
  allBusinessCategories,
  allCategories,
  addMovieListing,
  addProductListing,
  addBusinessListing,
  addprofessionalsListing,
  fetchData,
  allCaptionCategories,
  upload_media,
  initFetchRateCrpCategories,
  allCrpCategories,
  get_patrol_crp,
  get_improve_listing,
  get_vote_listing,
  get_flag_listing,
  getUserStatus,
  unblock_my_account
} from "../../actions/footer";
import { get_project_taxonomies } from "../../actions/communityReviewProjects";

import ProductModal from "../oragnisms/homepage/productModal";

import RateMovieModal from "../oragnisms/homepage/rateMovieModal";
import RateMovieReviewModal from "../oragnisms/homepage/rateMovieModalReview";
import RateMovieQuestionModal from "../oragnisms/homepage/rateMovieModalQuestion";
import RateBusinessModal from "../oragnisms/homepage/rateBusinessModal";
import RateProductModal from "../oragnisms/homepage/rateProductModal";

import PatrolCRPModal from "../oragnisms/homepage/patrolCRPModal";
import PatrolMovieCoreModal from "../oragnisms/homepage/patrolMovieCoreModal";
import PatrolMovieMediaModal from "../oragnisms/homepage/patrolMovieMediaModal";
import PatrolMovieReviewsModal from "../oragnisms/homepage/patrolMovieReviewsModal";

import ImproveMovieChangeModal from "../oragnisms/homepage/improveMovieChangeModal";
import ImproveMovieMediaModal from "../oragnisms/homepage/improveMovieMediaModal";
import ImproveMovieTechincalModal from "../oragnisms/homepage/improveMovieTechincalModal";

import VoteMovieModal from "../oragnisms/homepage/voteMovieModal";

import ProfessionalModal from "../oragnisms/homepage/professionalModal";
import CRPExpressModal from "../oragnisms/homepage/crpExpressModal";
import BusinessModal from "../oragnisms/homepage/businessModal";
import { get_tab_list, get_tab_list_scroll } from "./../../actions/homeTabs";
import Loaderimg from "../../assets/images/w-brand-black.jpg";
import Loader from "../atoms/loader";
import {
  get_feeds,
  check_jury_duty,
  get_dispute_discussion,
  cast_jury_vote,
  dispute_juror_notification,
} from "../../actions/feeds";

import { get_my_posts_list } from "../../actions/user";
import {
  fetch_media_data,
  fetch_taxonomy_category,
  media_upload_scroll,
  fetch_delete_media,
  clear_media_dispatch,
  upload_add_captions,
  single_delete_from_upload,
  delete_mutiple_media,
} from "../../actions/myProfiles";
import { check_is_juror } from "../../actions/feeds";
import { embedNewLink } from "../../actions/messages";
import { Markup } from "interweave";
import { Ratings } from "../molecules/homepage/ShowRatings";
import DisputeJuryDuty from "../atoms/disputeReview/disputeJuryDuty";
import moment from "moment";
import { pushUniqueObjectsInArray } from "../../utils/utilities";
import OneSignal from 'react-onesignal';
import { callApi } from "../../utils/apiCaller";
import RecommendQuestions from "../oragnisms/home/RecommendQuestions";


class Home extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      currentYear: moment().year(),
      connections: {},
      toggleAuthModal: false,
      showRecommendQuestion: true,
      authPhone: {
        ph1: "",
        ph2: "",
        ph3: "",
      },
      viewTrendingType: "national",
      mainViewType: "feed",
      createReviewModalToggle: false,
      viewReactedType: "all",
      viewPeopleWhoReactedModalToggle: false,
      confirmDeleteModalToggle: false,
      viewEditReviewStep: "writeReview",
      drawerModalPopupToggleAddMovie: false,
      drawerModalPopupToggleAddProduct: false,
      drawerModalPopupToggleAddBusiness: false,
      drawerModalPopupToggleAddProfessional: false,
      drawerModalPopupToggleAddCRP: false,
      drawerModalPopupToggleAddCRPExpress: false,
      drawerModalPopupToggleRateMovieModal: false,
      drawerModalPopupToggleRateMovieReviewModal: false,
      drawerModalPopupToggleRateMovieQuestionModal: false,
      drawerModalPopupToggleRateBusinessModal: false,
      drawerModalPopupToggleRateProductModal: false,
      drawerModalPopupToggleRateCrp: false,
      drawerModalPopupTogglePatrolMovieCRPModal: false,
      drawerModalPopupTogglePatrolMovieCoreModal: false,
      drawerModalPopupTogglePatrolMovieMediaModal: false,
      drawerModalPopupTogglePatrolMovieReviewsModal: false,
      drawerModalPopupToggleImproveMovieChangeModal: false,
      drawerModalPopupToggleImproveMovieMediaModal: false,
      drawerModalPopupToggleImproveMovieTechincalModal: false,
      drawerModalPopupToggleVoteMovieModal: false,
      drawerModalPopupToggleVoteProductsModal: false,
      drawerModalPopupToggleVoteBusinessModal: false,
      activeTag: "",
      activeanotherTag: "",
      listData: {},
      nextPage: null,
      movieList: null,
      movieLast: null,
      moviesPage: 1,
      getNumber: null,
      movieFetchLoader: false,
      businessList: null,
      businessPage: 1,
      businessLast: null,
      productList: null,
      productPage: 1,
      productLast: null,
      isLoader: "false",
      media: [],
      fileReviewStatus: false,
      filterSelectCompose: {},
      messageText: "",
      mediaSet: [],
      mediaSetReview: [],
      mediaSetCompose: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      myProfile: null,
      searchTheater: "",
      fetchPhotos: {},
      fetchPhotosCount: 0,
      fetchVideos: {},
      fetchVideosCount: 0,
      albumType: "",
      fetchTaxonomy: [],
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      fetchGalleryType: "image",
      fetchGalleryAlbum: "",
      selectedFilesPreview: [],
      fetchDeletedData: {},
      fetchDeleteCount: 0,
      latestUploadedData: {},
      uploadCaptions: {},
      dataWantsToDelete: [],
      showVideoMedia: true,
      confirmDeleteModal: false,
      limitedPostsModal: false,
      uploadMediaModal: false,
      uploadMediaPostModal: false,
      isModalTrue: false,
      modalText: null,
      modalType: null,
      modalSelected: null,
      itemsAttachment: [],
      itemsIndex: 1,

      is_juror: false,
      juror_data: "",
      reviewId: "",

      id: "",
      voteReviewModalToggleTwo: false,
      votingId: "",
      get_dispute_data: [],
      dispute_data: false,
      video: "https://youtu.be/gYfJadhmcQY",
      vote: 0,
      remarks: "",
      count_0: 0,
      count_1: 1,
      count_2: 2,
      per_0: 0,
      per_1: 0,
      per_2: 0,

      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      voting_enabled: true,
      can_vote: false,
      already_voted: false,
      timer_started: false,
      myProfileMessage: null,
      review_data: "",
      reviewAdded: "",
      has_voted: false,
      finalJury: "",
      is_final_juror: false,
      finalJuryVotingToggle: false,
      finalJuryReview: "",
      per_keep: 0,
      per_delete: 0,
      has_voted_final: false,
      already_voted_final: false,
      finalJuryVote: null,
      finalremarks: "",
      showJuryModal: false,
      disputeModal: false,
      menuCategoryListToShow: [],
      menuSubCategoryListToShow: [],
      blocked_user_status: {},
      ifAccountBlocked: false,
      unblock: true,
      isSearching: true,
      showUserAuthenticateModalToggle :false
    };
  }

  resetStates = () => {
    this.setState({
      toggleAuthModal: false,
      showRecommendQuestion: true,
      authPhone: {
        ph1: "",
        ph2: "",
        ph3: "",
      },
      viewTrendingType: "national",
      mainViewType: "feed",
      createReviewModalToggle: false,
      viewReactedType: "all",
      viewPeopleWhoReactedModalToggle: false,
      confirmDeleteModalToggle: false,
      viewEditReviewStep: "writeReview",
      drawerModalPopupToggleAddMovie: false,
      drawerModalPopupToggleAddProduct: false,
      drawerModalPopupToggleAddBusiness: false,
      drawerModalPopupToggleAddProfessional: false,
      drawerModalPopupToggleAddCRP: false,
      drawerModalPopupToggleAddCRPExpress: false,
      drawerModalPopupToggleRateMovieModal: false,
      drawerModalPopupToggleRateMovieReviewModal: false,
      drawerModalPopupToggleRateMovieQuestionModal: false,
      drawerModalPopupToggleRateBusinessModal: false,
      drawerModalPopupToggleRateProductModal: false,
      drawerModalPopupToggleRateCrp: false,
      drawerModalPopupTogglePatrolMovieCRPModal: false,
      drawerModalPopupTogglePatrolMovieCoreModal: false,
      drawerModalPopupTogglePatrolMovieMediaModal: false,
      drawerModalPopupTogglePatrolMovieReviewsModal: false,
      drawerModalPopupToggleImproveMovieChangeModal: false,
      drawerModalPopupToggleImproveMovieMediaModal: false,
      drawerModalPopupToggleImproveMovieTechincalModal: false,
      drawerModalPopupToggleVoteMovieModal: false,
      drawerModalPopupToggleVoteProductsModal: false,
      drawerModalPopupToggleVoteBusinessModal: false,
      activeTag: "",
      listData: {},
      nextPage: null,
      movieList: null,
      movieLast: null,
      moviesPage: 1,
      getNumber: null,
      movieFetchLoader: false,
      businessList: null,
      businessPage: 1,
      businessLast: null,
      productList: null,
      productPage: 1,
      productLast: null,
      isLoader: "false",
      media: [],
      fileReviewStatus: false,
      filterSelectCompose: {},
      messageText: "",
      mediaSet: [],
      mediaSetReview: [],
      mediaSetCompose: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      myProfile: null,
      searchTheater: "",
      fetchPhotos: {},
      fetchPhotosCount: 0,
      fetchVideos: {},
      fetchVideosCount: 0,
      albumType: "",
      fetchTaxonomy: [],
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      fetchGalleryType: "image",
      fetchGalleryAlbum: "",
      selectedFilesPreview: [],
      fetchDeletedData: {},
      fetchDeleteCount: 0,
      latestUploadedData: {},
      uploadCaptions: {},
      dataWantsToDelete: [],
      showVideoMedia: true,
      confirmDeleteModal: false,
      limitedPostsModal: false,
      uploadMediaModal: false,
      uploadMediaPostModal: false,
      isModalTrue: false,
      modalText: null,
      modalType: null,
      modalSelected: null,
      itemsAttachment: [],
      itemsIndex: 1,

      is_juror: false,
      juror_data: "",
      reviewId: "",

      id: "",
      voteReviewModalToggleTwo: false,
      votingId: "",
      get_dispute_data: [],
      dispute_data: false,
      video: "https://youtu.be/gYfJadhmcQY",
      vote: 0,
      remarks: "",
      count_0: 0,
      count_1: 1,
      count_2: 2,
      per_0: 0,
      per_1: 0,
      per_2: 0,

      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      voting_enabled: true,
      can_vote: false,
      already_voted: false,
      timer_started: false,
      myProfileMessage: null,
      review_data: "",
      reviewAdded: "",
      has_voted: false,
      finalJury: "",
      is_final_juror: false,
      finalJuryVotingToggle: false,
      finalJuryReview: "",
      per_keep: 0,
      per_delete: 0,
      has_voted_final: false,
      already_voted_final: false,
      finalJuryVote: null,
      finalremarks: "",
      showJuryModal: false,
      disputeModal: false,
      RateMovieType: "",
      ratingType: "",
    });
  };

  getFeedsWithLoading = async (queryParams) => {
    this.setState({
      loading: true,
    });

    this.props
      .get_feeds(queryParams)
      .then(() => this.setState({ loading: false }));
  };

  componentWillReceiveProps(nextProps) {
    let mp = localStorage.getItem("mp");
    let {
      movieList,
      businessList,
      productList,
      latestUploadedData,
      fetchGalleryScroll,
      fetchGalleryCount,
      mainViewType,
      blocked_user_status

    } = this.state;

    if (nextProps?.get_blocked_user_status) {

      this.setState({
        blocked_user_status: nextProps?.get_blocked_user_status,
      });

      if (nextProps?.get_blocked_user_status.status == false) {

        this.setState({
          ifAccountBlocked: true,
        });
      } else {
        this.setState({
          ifAccountBlocked: false,
        });

      }
    }

    if (nextProps?.connections) {
      this.setState({
        connections: nextProps?.connections,
      });
    }
    if (nextProps?.auth_phone_number?.msg) {
      this.setState({
        myProfileMessage: nextProps?.auth_phone_number?.msg,
      });
    }

    if (nextProps?.my_profile?.mobile_no == null || mp) {
      localStorage.setItem("mp", true);
      this.setState({ toggleAuthModal: true,});
    }

    if(nextProps?.my_profile?.mobile_no !== null){
      localStorage.setItem("mp", false);
      this.setState({ toggleAuthModal: false, myProfileMessage: '' });
    }

    if (nextProps.upload_scroll) {
      let item = nextProps.upload_scroll;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        fetchGalleryScroll,
        fetchGalleryCount,
        activeMyPhotoIndex: 0,
      });
    }

    if (nextProps.upload_media_data) {
      let data = nextProps.upload_media_data;
      if (data.index !== undefined) {
        let idx = data.index;
        latestUploadedData[idx] = data;
        this.setState({ latestUploadedData });
      }
    }

    if (
      nextProps.menuCategories &&
      Array.isArray(nextProps.menuCategories) &&
      nextProps.menuCategories.length > 0
    ) {
      this.setState({ menuCategoryListToShow: nextProps.menuCategories });
    }
    if (
      nextProps.subCatMenuListing &&
      Array.isArray(nextProps.subCatMenuListing) &&
      nextProps.subCatMenuListing.length > 0
    ) {
      this.setState({
        menuSubCategoryListToShow: nextProps.subCatMenuListing[0],
      });
    }

    if (
      nextProps.tab_list_data &&
      movieList === null &&
      mainViewType == nextProps?.tab_list_data?.type
    ) {
      let feedData = nextProps.tab_list_data;

      let name = this.state.mainViewType;
      let listData = this.state.listData;

      if (nextProps.fetch_taxonomy) {
        let taxCat = nextProps.fetch_taxonomy;
        if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
          taxCat = taxCat.filter((dt) => dt.label !== null);
        }
        this.setState({ fetchTaxonomy: taxCat });
      }

      if (feedData) {
        listData[name] = { feedData };
        this.setState({ listData }, () => {
          let data = this.state.listData;
          if (
            data &&
            data.movies &&
            data.movies.feedData &&
            data.movies.feedData.results &&
            Array.isArray(data.movies.feedData.results) &&
            data.movies.feedData.results.length > 0
          ) {
            let resultItems = data.movies.feedData.results[0].content_type;
            this.setState({
              resultItems,
              movieList: data.movies.feedData.results,
              movieLast: data.movies.feedData.next,
              getNumber: 1,
              movieFetchLoader: false,
            });
          }
        });
      }
    }

    if (
      nextProps.tab_list_data &&
      businessList === null &&
      mainViewType == nextProps?.tab_list_data?.type
    ) {
      let feedData = nextProps.tab_list_data;

      let name = this.state.mainViewType;
      let listData = this.state.listData;
      if (feedData) {
        listData[name] = { feedData };
        this.setState({ listData }, () => {
          let data = this.state.listData;
          if (
            data &&
            data.business &&
            data.business.feedData &&
            data.business.feedData.results &&
            Array.isArray(data.business.feedData.results) &&
            data.business.feedData.results.length > 0
          ) {
            this.setState({
              businessList: data.business.feedData.results,
              businessLast: data.business.feedData.next,
              getNumber: 1,
              movieFetchLoader: false,
            });
          }
        });
      }
    }

    if (
      nextProps.tab_list_data &&
      productList === null &&
      mainViewType == nextProps?.tab_list_data?.type
    ) {
      let feedData = nextProps.tab_list_data;

      let name = this.state.mainViewType;
      let listData = this.state.listData;
      if (feedData) {
        listData[name] = { feedData };
        this.setState({ listData }, () => {
          let data = this.state.listData;
          if (
            data &&
            data.products &&
            data.products.feedData &&
            data.products.feedData.results &&
            Array.isArray(data.products.feedData.results) &&
            data.products.feedData.results.length > 0
          ) {
            this.setState({
              productList: data.products.feedData.results,
              productLast: data.products.feedData.next,
              getNumber: 1,
              movieFetchLoader: false,
            });
          }
        });
      }
    }

    if (
      nextProps.tab_scroll_data &&
      Array.isArray(movieList) &&
      movieList.length
    ) {
      let name = this.state.mainViewType;
      if (name === "movies") {
        if (nextProps.tab_scroll_data?.results?.length) {
          const movList = pushUniqueObjectsInArray(this.state.movieList, nextProps.tab_scroll_data.results, 'id')
          this.setState({
            movieList: movList,
            movieLast: nextProps.tab_scroll_data.next,
            getNumber: nextProps.tab_scroll_data.number,
            movieFetchLoader: false,
          });
        }
      }
    }

    if (
      nextProps.tab_scroll_data &&
      Array.isArray(businessList) &&
      businessList.length
    ) {
      let name = this.state.mainViewType;
      if (name === "business") {
        if (nextProps.tab_scroll_data?.results?.length) {
          const bizList = this.state.businessList.concat(
            nextProps.tab_scroll_data.results
          );
          this.setState({
            businessList: bizList,
            businessLast: nextProps.tab_scroll_data.next,
            getNumber: nextProps.tab_scroll_data.number,
            movieFetchLoader: false,
          });
        }
      }
    }

    if (
      nextProps.tab_scroll_data &&
      Array.isArray(productList) &&
      productList.length
    ) {
      let name = this.state.mainViewType;
      if (name === "products") {
        if (nextProps.tab_scroll_data?.results?.length) {
          const proList = this.state.productList.concat(
            nextProps.tab_scroll_data.results
          );
          this.setState({
            productList: proList,
            productLast: nextProps.tab_scroll_data.next,
            getNumber: nextProps.tab_scroll_data.number,
            movieFetchLoader: false,
          });
        }
      }
    }
    if (nextProps && nextProps.get_juror_data) {
      if (
        nextProps.get_juror_data &&
        nextProps.get_juror_data.results &&
        nextProps.get_juror_data.results[0] &&
        nextProps.get_juror_data.results[0].review_id
      ) {
        if (
          nextProps.get_juror_data &&
          nextProps.get_juror_data.results &&
          nextProps.get_juror_data.results[0] &&
          nextProps.get_juror_data.results[0].never_ask_again === "no"
        ) {
          this.setState({ is_juror: false });
        } else {
          this.setState({
            is_juror: true,
            reviewId:
              nextProps.get_juror_data &&
              nextProps.get_juror_data.results[0].review_id,
          });
        }

        if (
          nextProps.get_juror_data.results[0].vote === 0 ||
          nextProps.get_juror_data.results[0].vote === 1 ||
          nextProps.get_juror_data.results[0].vote === 2
        ) {
          this.setState({ already_voted: true, is_juror: false });
        }
      }
    }

    if (
      this.state.disputeModal &&
      nextProps?.get_dispute_modal_status?.modal === false
    ) {
      this.setState({ showJuryModal: false });
    }
  }

  handleCheckJuryduty = () => {
    this.setState({ showJuryModal: true, disputeModal: true });
    // this.setState({ dispute_data: false })
    // this.props.check_jury_duty(this.state.reviewId)
    // this.props.get_review_details(this.state.reviewId)
  };


  handleOnSubmitUnblockAccount = (e) => {
    e.preventDefault();
    let {
      unblock
    } = this.state;

    let data1 = {};


    data1.unblock = unblock
    this.props.unblock_my_account(data1)
  };


  handleSubmit = () => {
    let vote_details = {
      vote: this.state.vote,
      remarks: this.state.remarks,
      id: this.state.votingId,
    };

    this.props.cast_jury_vote(vote_details, this.state.reviewId);
    this.setState({ remarks: "", has_voted: true });
    this.props.check_is_juror();
  };

  checkIsAdded = (item) => {
    let { mediaSet } = this.state;
    let id = item.id;
    let itemFiltered = mediaSet.find((item) => item.id === id);
    if (itemFiltered) {
      return true;
    } else {
      return false;
    }
  };

  removeFromMedia = (e, item) => {
    e.preventDefault();
    let { mediaSet } = this.state;
    let id = item.id;
    let itemFiltered = mediaSet.find((item) => item.id === id);
    if (itemFiltered) {
      const index = mediaSet.indexOf(itemFiltered);
      if (index > -1) {
        mediaSet.splice(index, 1);
      }
      this.setState({ mediaSet, mediaSetReview: mediaSet });
    }
  };

  setMediaSetForItems = (e, item) => {
    // e.preventDefault();
    let { mediaSet, offerCheck } = this.state;
    if (item && item.id) {
      let id = item.id;
      let itemFiltered = mediaSet.find((item) => item.id === id);

      if (itemFiltered) {
        const index = mediaSet.indexOf(itemFiltered);
        if (index > -1) {
          mediaSet.splice(index, 1);
        }
      } else {
        mediaSet.push(item);
      }

      // if (otherOffers.inStoreCouponToggle) {
      //     if (mediaSet.length > 0) { offerCheck['validateForSubmit'] = false; }
      //     else { offerCheck['validateForSubmit'] = true; }
      // }

      this.setState({ mediaSet, mediaSetReview: mediaSet, offerCheck });
    }
  };

  handleCaptionByIndex = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions } = this.state;

    uploadCaptions[index] = value;
    this.setState({ uploadCaptions });
  };

  loaderInterval = () => {
    /*
        if (!loaderExludedRoutes.includes(this.props.history.location.pathname)) {
          this.intervalCheck = setInterval(() => {
            let isRedirectTrue = localStorage.getItem('redirect');
    
            if (isRedirectTrue == "true") {
              this.props.history.push('/corporatesettings');
              localStorage.removeItem('redirect');
            }
    
            let { isLoader } = this_keep.state;
            let localValue = localStorage.getItem("loader");
            if (isLoader !== localValue) {
              this_keep.setState({ isLoader: localStorage.getItem("loader") });
            }
          }, 1500);
        }
    
        if (loaderExludedRoutes.includes(this.props.history.location.pathname)) {
          clearInterval(this.intervalCheck);
        }
      */
  };

  async pushNotification() {
    let id;
    const isPushNotificationsEnabled = await OneSignal.isPushNotificationsEnabled()
    await OneSignal.setSubscription(true)
    if (isPushNotificationsEnabled) {
      id = await OneSignal.getUserId()
      const url = `/api/notificationtokenstore/?onesignalID=${id}`
      callApi(url, "POST").then((res) => {
        callApi("/api/myprofile/?q=", "GET").then(myprofile => {
          OneSignal.setExternalUserId(myprofile?.id)
        })
      })
    } 
  }

  enableOneSignalChanges = (pushNotify) => {
    OneSignal.on('subscriptionChange', function (isSubscribed) {
      if (isSubscribed) {
        pushNotify()
      }
    });
  
    OneSignal.on('notificationPermissionChange', function(permissionChange) {
      var currentPermission = permissionChange.to;
      pushNotify()
    });
  }

  componentDidMount = () => {
    this.enableOneSignalChanges(this.pushNotification);
    OneSignal.init({ appId: ONESIGNAL_APP_ID, autoResubscribe: true }).then(() => {
      OneSignal.showSlidedownPrompt().then(() => {
        this.pushNotification()
      });
    })
    
    const queryParams = {
      category: 135,
    };
    const queryMovieParams = {
      category: "Movies",
    };

    const queryProductParams = {
      category: "Products",
    };

    const queryBusinessParams = {
      category: "Business",
    };

    const queryProfessionalsParams = {
      category: "Professionals",
    };

    const queryMenuParams = {
      category: "Menu",
    };
    const queryCrpParams = {
      category: "Projects_Taxonomy",
    };
    const {
      getAllCategories,
      getAllMovieCategories,
      getAllProductCategories,
      getAllProfessionalsCategories,
      getAllBusinessCategories,
      fetchData,
      check_is_juror,
      fetch_connections,
      getAllMenuCategories,
      getAllCrpCategories,
      getUserStatus
    } = this.props;
    // this.props.get_project_taxonomies();
    //this.loaderInterval();
    document.title = `Wikireviews Investor Private Profile on WikiReviews`;
    let getBd = document.getElementsByTagName("BODY")[0];
    getBd.className = "";
    getBd.style.paddingRight = 0;
    Promise.all([
      getAllCategories(queryParams),
      getAllMovieCategories(queryMovieParams),
      getAllProductCategories(queryProductParams),
      getAllProfessionalsCategories(queryProfessionalsParams),
      getAllBusinessCategories(queryBusinessParams),
      fetchData(),
      fetch_connections(),
      getAllCrpCategories(queryCrpParams),
      // check_final_jury_duty(),
      check_is_juror(),
      getAllMenuCategories(queryMenuParams),
      getUserStatus()
    ]).then(() => {
      this.loaderInterval();
      window.addEventListener("scroll", this.handleScroll, { passive: false });
    });


    // this.setState({ welcomeModalToggle: true });
  };

  handleJuryNotification = () => {
    const { get_juror_data } = this.props;
    this.props.dispute_juror_notification({
      id: get_juror_data.results[0].id,
      never_ask_again: "no",
    });
  };

  toggleVideoOption = () => {
    this.setState({ showVideoMedia: !this.state.showVideoMedia });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.resetStates();
  }

  checkIsLoader = (isLoader) => {
    if (isLoader && isLoader == "true") {
      return (
        <div className="loader_div">
          <div className="inner-loader">
            <img src={Loaderimg} alt="" />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`;

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach((item) => {
        if (
          fetchGalleryScroll[item] &&
          fetchGalleryScroll[item].results !== null
        ) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map((item) => {
          let itemName = (item?.thumbnail).split(".");
          let itemFile = (item?.file).split(".");
          let extName = itemName[itemName.length - 1];
          let extFile = itemFile[itemFile.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;
          item["srcFile"] = isVideo ? srcFile : item?.file;
          return (
            <Col xs="auto" className="mb-3">
              <FormGroup
                className="checkable-media"
                check
                style={{ width: "120px" }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={(e) => {
                      this.setMediaSetForItems(e, item);
                    }}
                    checked={this.checkIsAdded(item)}
                  />
                  <div className="gallery-media">
                    <img
                      className="img-fluid"
                      src={isVideo ? srcFile : item?.file}
                      alt="logo Pics"
                    />
                  </div>
                </Label>
                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
                  {item.caption ? item.caption : "No Caption"}
                </div>
                <div className="font-weight-normal fs-14 d-block">
                  {item.category ? item.category : "No Category"}
                </div>
              </FormGroup>
            </Col>
          );
        });
      }
    } else {
      return getDataItems;
    }
  };

  handleCloseAndFetch = () => {
    let { latestUploadedData, fileReviewStatus, mediaSetReview } = this.state;

    if (
      fileReviewStatus &&
      Object.values(latestUploadedData) &&
      Object.values(latestUploadedData)[0] &&
      Object.values(latestUploadedData)[0].id
    ) {
      Object.values(latestUploadedData).map((list) => {
        mediaSetReview.push({
          id: list.id,
          file: list.url,
          thumbnail: list.thumbnail,
          url: list.url,
        });
      });
    }
    this.setState(
      {
        uploadMediaModal: !this.state.uploadMediaModal,
        fileReviewStatus: false,
        mediaSetReview,
      },
      () => {
        this.clearMediaDispatch();
        this.props.fetch_media_data("image", "", 1, true, true);
        this.props.fetch_media_data("video", "", 1, true, true);
      }
    );
  };

  handleScroll = () => {
    let moviesHeight = 0;
    let businessHeight = 0;
    let productHeight = 0;

    if (document.getElementById("business")) {
      businessHeight = document.getElementById("business").clientHeight;
    }

    if (document.getElementById("products")) {
      productHeight = document.getElementById("products").clientHeight;
    }

    if (document.getElementById("movies")) {
      moviesHeight = document.getElementById("movies").clientHeight;
    }

    let { getNumber } = this.state;

    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    // const docHeight = Math.max(
    //   body.scrollHeight,
    //   body.offsetHeight,
    //   html.clientHeight,
    //   html.scrollHeight,
    //   html.offsetHeight
    // );
    const windowBottom = windowHeight + window.pageYOffset - 250;
    const moviePage = this.state.moviesPage;
    const bizPage = this.state.businessPage;
    const proPage = this.state.productPage;

    if (
      moviesHeight > 0 &&
      windowBottom > moviesHeight &&
      this.state.mainViewType === "movies"
    ) {
      let lastNumber = this.state.movieLast;
      let number = this.state.moviesPage;

      if (lastNumber !== null && moviePage === getNumber) {
        this.setState({ moviesPage: number + 1, movieFetchLoader: true });
        let type = "movies";
        this.get_list_scroll(type, this.state.moviesPage);
      }
    }

    if (
      businessHeight > 0 &&
      windowBottom > businessHeight &&
      this.state.mainViewType === "business"
    ) {
      let lastNumber = this.state.businessLast;
      let number = this.state.businessPage;

      if (lastNumber !== null && bizPage === getNumber) {
        this.setState({ businessPage: number + 1, movieFetchLoader: true });
        let type = "business";
        this.get_list_scroll(type, this.state.businessPage);
      }
    }

    if (
      productHeight > 0 &&
      windowBottom > productHeight &&
      this.state.mainViewType === "products"
    ) {
      let lastNumber = this.state.productLast;
      let number = this.state.productPage;

      if (lastNumber !== null && proPage === getNumber) {
        this.setState({ productPage: number + 1, movieFetchLoader: true });
        let type = "products";
        this.get_list_scroll(type, this.state.productPage);
      }
    }
  };
  CRPProjectsUploadChange = (e) => {
    let { selectedFilesPreview, files } = this.state;
    const filesSetForUpload = Array.from(e.target.files);
    files = files.concat(filesSetForUpload);
    let self = this;
    this.setState({ files }, () => {
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          let concatWithPrev = selectedFilesPreview.concat(images);
          self.setState({ selectedFilesPreview: concatWithPrev });
        },
        (error) => {
          console.error(error);
        }
      );
    });

    if (files && Array.isArray(files) && files.length > 0) {
      let indexSelected = selectedFilesPreview.length - 1;
      files.forEach(async (item, index) => {
        if (index > indexSelected) {
          let formData = new FormData();
          formData.append("file", item);

          let name = item.name;
          let split_items = name.split(".");
          let item_ext = split_items[split_items.length - 1];
          let isImageTrue = [".jpg", ".png", ".jpeg", ".gif", ".tiff"].includes(
            item_ext
          );
          if (isImageTrue) {
            await this.props.upload_media_files("image", formData, index);
          } else {
            await this.props.upload_media_files("video", formData, index);
          }
        }
      });
    }
  };

  AddNewEmbedLink = (e) => {
    e.preventDefault();
    let { embeddedLink } = this.state;
    // this.props.embedNewLink({
    //   data: {
    //     video: `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
    //     exclude_list: [],
    //     body_preview: `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
    //     question_rating_category: [],
    //     messagemedia_set: [],
    //   },
    // });
    this.setState({ embeddedLink: "" });
  };

  get_list_scroll = (type, number) => {
    this.props.get_tab_list_scroll(type, number);
  };

  removeSelectedImage = (e, index) => {
    let { latestUploadedData, selectedFilesPreview } = this.state;
    let upload_data = latestUploadedData[index];
    let id = upload_data.id;
    this.props.single_delete_from_upload(id);
    selectedFilesPreview.splice(index, 1);
    delete latestUploadedData[index];
    this.setState({ selectedFilesPreview, latestUploadedData });
  };

  clearMediaDispatch = () => {
    this.setState(
      {
        latestUploadedData: {},
        uploadCaptions: {},
        selectedFilesPrevietoggleBottomMenuw: [],
        files: [],
      },
      () => this.props.clear_media_dispatch()
    );
  };

  toggleBottomMenu = (menu) => {
    return this.setState({
      ...this.state,
      activeTag: this.state.activeTag ? "" : menu,
    });
  };

  handletoggleBottomMenu = (menuId, menu) => {
    return this.setState(
      {
        ...this.state,
        //activeTag: this.state.activeTag ? "" : menu,
        activeTag: menu,
        activeanotherTag: menu,
        menuSubCategoryListToShow: [],
      },
      () => {
        const queryParams = {
          category: menuId,
        };
        const { getAllMenuSubCategories } = this.props;
        getAllMenuSubCategories(queryParams);
      }
    );
  };
  handleMediaUpload = () => {
    const queryCaptionParams = {
      category: "Album",
      depth: true,
      delsel: "",
      ancestor: "",
      sub_cat: "",
      parent: "",
    };
    let { mediaSetReview } = this.state;
    this.props.getAllCaptionCategories(queryCaptionParams);

    this.setState(
      {
        uploadMediaModal: !this.state.uploadMediaModal,
        fileReviewStatus: true,
        mediaSet: mediaSetReview,
      },
      () => {
        this.clearMediaDispatch();
        this.props.fetch_media_data("image", "", 1, true, true);
        this.props.fetch_media_data("video", "", 1, true, true);
      }
    );
  };
  //   ?album=&instance=selfvideo&image=undefined&doc=undefined
  //   album=&instance=moviesdrawer&image=undefined&doc=undefined
  toggleModal = (category) => {

    if (category === "addMovie") {
      return this.setState({
        drawerModalPopupToggleAddMovie: !this.state
          .drawerModalPopupToggleAddMovie,
        activeTag: "",
      });
    }

    if (category === "addProduct") {
      return this.setState({
        drawerModalPopupToggleAddProduct: !this.state
          .drawerModalPopupToggleAddProduct,
        activeTag: "",
      });
    }

    if (category === "addBusiness") {
      return this.setState({
        drawerModalPopupToggleAddBusiness: !this.state
          .drawerModalPopupToggleAddBusiness,
      });
    }

    if (category === "addCommunity Review Project") {
      return this.setState({
        drawerModalPopupToggleAddCRP: !this.state.drawerModalPopupToggleAddCRP,
      });
    }

    if (category === "addCRP Express") {
      return this.setState({
        drawerModalPopupToggleAddCRPExpress: !this.state
          .drawerModalPopupToggleAddCRPExpress,
      });
    }

    if (category === "addProfessional") {
      return this.setState({
        drawerModalPopupToggleAddProfessional: !this.state
          .drawerModalPopupToggleAddProfessional,
      });
    }

    if (category === "rateMovieImage" || category === "rateMovieVideos") {
      return this.setState(
        {
          drawerModalPopupToggleRateMovieModal: !this.state
            .drawerModalPopupToggleRateMovieModal,
          RateMovieType: category,
        },
        () => {
          let id = "";
          let type = "images";
          let listingType = "movies";
          this.props.get_patrol_crp(id, type, listingType);
        }
      );
    }

    if (category === "rateBusiness") {
      return this.setState({
        drawerModalPopupToggleRateBusinessModal: !this.state
          .drawerModalPopupToggleRateBusinessModal,
      });
    }

    if (category === "rateProducts") {
      return this.setState({
        drawerModalPopupToggleRateProductModal: !this.state
          .drawerModalPopupToggleRateProductModal,
      });
    }

    if (category === "rateMovieReviews") {
      return this.setState({
        drawerModalPopupToggleRateMovieReviewModal: !this.state
          .drawerModalPopupToggleRateMovieReviewModal,
        RateMovieType: category,
      });
    }

    if (category === "rateMovieQuestions") {
      return this.setState({
        drawerModalPopupToggleRateMovieQuestionModal: !this.state
          .drawerModalPopupToggleRateMovieQuestionModal,
        RateMovieType: category,
      });
    }

    if (category === "rateCRPProjects") {
      return this.setState({
        drawerModalPopupToggleRateCrp: !this.state
          .drawerModalPopupToggleRateCrp,
      });
    }

    if (category === "patrolCommunity Review Project") {
      return this.setState(
        {
          drawerModalPopupTogglePatrolMovieCRPModal: !this.state
            .drawerModalPopupTogglePatrolMovieCRPModal,
        },
        () => {
          let id = "";
          let type = "communityreviews";
          let listingType = "products";
          this.props.get_patrol_crp(id, type, listingType);
        }
      );
    }

    if (category === "patrolMovieCore listing") {
      return this.setState(
        {
          drawerModalPopupTogglePatrolMovieCoreModal: !this.state
            .drawerModalPopupTogglePatrolMovieCoreModal,
        },
        () => {
          let id = "";
          let type = "corelisting";
          let listingType = "movies";
          this.props.get_patrol_crp(id, type, listingType);
        }
      );
    }

    if (category === "patrolMovieReviews") {
      return this.setState(
        {
          drawerModalPopupTogglePatrolMovieReviewsModal: !this.state
            .drawerModalPopupTogglePatrolMovieReviewsModal,
        },
        () => {
          let id = "";
          let type = "review";
          let listingType = "movies";
          this.props.get_patrol_crp(id, type, listingType);
        }
      );
    }

    if (category === "patrolMovieMedia") {
      return this.setState(
        {
          drawerModalPopupTogglePatrolMovieMediaModal: !this.state
            .drawerModalPopupTogglePatrolMovieMediaModal,
        },
        () => {
          let id = "";
          let type = "media";
          let listingType = "movies";
          this.props.get_patrol_crp(id, type, listingType);
        }
      );
    }

    if (category === "improveMovieChange Listing Name") {
      return this.setState(
        {
          drawerModalPopupToggleImproveMovieChangeModal: !this.state
            .drawerModalPopupToggleImproveMovieChangeModal,
        },
        () => {
          let id = "";
          let type = "listing";
          let listingType = "movies";
          this.props.get_improve_listing(id, type, listingType);
        }
      );
    }

    if (category === "improveMovieMedia") {
      return this.setState(
        {
          drawerModalPopupToggleImproveMovieMediaModal: !this.state
            .drawerModalPopupToggleImproveMovieMediaModal,
        },
        () => {
          let id = "";
          let type = "media";
          let listingType = "movies";
          this.props.get_improve_listing(id, type, listingType);
        }
      );
    }

    if (category === "improveMovieTechnical Details") {
      return this.setState(
        {
          drawerModalPopupToggleImproveMovieTechincalModal: !this.state
            .drawerModalPopupToggleImproveMovieTechincalModal,
        },
        () => {
          let id = "";
          let type = "technicaldetails";
          let listingType = "movies";
          this.props.get_improve_listing(id, type, listingType);
        }
      );
    }

    if (category === "voteMovies") {
      return this.setState(
        {
          drawerModalPopupToggleVoteMovieModal: !this.state
            .drawerModalPopupToggleVoteMovieModal,
        },
        () => {
          let id = "";
          let type = "flaglist";
          let voteType = "votinglist";
          let listingType = "Movies";
          this.props.get_flag_listing(id, type, listingType);
          this.props.get_vote_listing(id, voteType, listingType);
        }
      );
    }

    if (category === "voteProducts") {
      return this.setState(
        {
          drawerModalPopupToggleVoteProductsModal: !this.state
            .drawerModalPopupToggleVoteProductsModal,
        },
        () => {
          let id = "";
          let type = "flaglist";
          let voteType = "votinglist";
          let listingType = "Products";
          this.props.get_flag_listing(id, type, listingType);
          this.props.get_vote_listing(id, voteType, listingType);
        }
      );
    }

    if (category === "voteBusiness") {
      return this.setState(
        {
          drawerModalPopupToggleVoteBusinessModal: !this.state
            .drawerModalPopupToggleVoteBusinessModal,
        },
        () => {
          let id = "";
          let type = "flaglist";
          let voteType = "votinglist";
          let listingType = "Business";
          this.props.get_flag_listing(id, type, listingType);
          this.props.get_vote_listing(id, voteType, listingType);
        }
      );
    }
  };

  getList = (name) => {
    if (name === "myposts") {
      // earlier called here get_my_posts_list
    } else if (name === "feed") {
      const queryParams = {
        page: 1,
        all: true,
      };
      this.getFeedsWithLoading(queryParams);
    } else {
      this.props.get_tab_list(name).then(() => {
        this.setState({
          movieFetchLoader: false,
        });
      });      
              
    }
  };

  getMovieList = () => {
    // let ad_index = 6;
    let movieFetchLoader = this.state.movieFetchLoader;
    let results = this.state.movieList;
    let mainViewType = this.state.mainViewType;
    if (results) {
      return results.map((item, index) => {


        // if (index !== 1 && (index - 1) % ad_index) {

          return (
            <Col key={index} className="mb-4" style={{ minHeight: "320px" }}>
              <div className="bg-white text-dark p-2 h-100 hoverable-review">
                <div className="review-details">
                  <div className="text-center">
                    {item?.listing_profileimage[0]?.url ? (
                      <img
                        className="fixed-height-img is-160 w-100 object-fit-cover object-position-top"
                        src={item.listing_profileimage[0].url}
                        alt=""
                        onError={(error) =>
                          (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)
                        }
                      />
                    ) : (
                        <img
                          className="img-fluid"
                          src={
                            "https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png"
                          }
                          alt=""
                        />
                      )}
                  </div>
                  <div className="mt-2">
                    <div className="font-weight-bold fs-14">
                      <span>
                        <a href="#" className="text-reset">
                          {item.name.length > 120
                            ? item.name.substring(0, 120) + "..."
                            : item.name}
                        </a>
                      </span>
                    </div>
                    <div className="mb-1">
                      <img
                        src={
                          item?.total_rating?.avg_rating?.[0]
                            ? require(`../../assets/images/icons/star/blue/${item.total_rating.avg_rating[0]}`)
                            : ""
                        }
                        alt=""
                      />
                    </div>
                    <p className="fs-13">
                      <a href="#" className="text-reset">
                        {`${item.total_rating.total_review} Review${item.total_rating.total_review != 1 ? "s" : ""
                          }`}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="fs-14 review-mask">
                  <div className="p-3">
                    {/* <div className="d-flex align-items-start mb-2">
                      <img className="mr-2 img-circle _50x50" src={item?.review?.created_by?.current_profile_pic?.thumbnail ? `https://userdatawikireviews.s3.amazonaws.com/media/${item.review.created_by.current_profile_pic.thumbnail}` : "https://stagingdatawikireviews.s3.amazonaws.com/images/circle.png"} alt="" onError={(error) => (error.target.src = require("../../assets/images/icons/user-circle.png"))} />
                      <div>
                        <a href="#" className="text-dark font-weight-bold">{item.review != "" && item.review.created_by ? (item.review.created_by.user.first_name + " " + item.review.created_by.user.last_name) : "Someone"}</a> <span className="text-dark">&nbsp;wrote a review for <a href="#" className="text-dark font-weight-bold">{item.name.length > 120 ? item.name.substring(0, 120) + '...' : item.name}</a> and gave it&nbsp;</span>
                        <img src={item.review.ratings_sets && item.review.ratings_sets.length > 0 ? `../../assets/images/icons/star/blue/${item.review.ratings_sets[0]}` : "../../assets/images/icons/star/blue/0rating.png"} alt="" />
                      </div>
                    </div> */}
                    <div className="text-center">
                      {/* <i className="icon-comma-left _sm"></i> */}
                      <span className="comment-body-renderer ff-base fs-14">
                        {item?.review?.review?.length > 95 ? (
                          <React.Fragment>
                            <Markup
                              content={
                                item?.review?.review?.substring(0, 95) +
                                `   ` +
                                `... </p>`
                              }
                            />
                          </React.Fragment>
                        ) : (
                            <Markup content={item?.review?.review} />
                          )}
                      </span>
                      {/* <i className="icon-comma-right _sm"></i> */}
                    </div>
                    <div className="text-center fs-14 mt-3">
                      <Link
                        to={{
                          pathname: `/${mainViewType}/${item?.slug ? item.slug : item?.id }`,
                          state: { id: item.id },
                        }}
                        className="text-reset font-weight-bold"
                      >
                        {"View More"}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          );
        // } 
        // else {
        //   return (
        //     <Col key={index} className="mb-4" style={{ minHeight: "320px" }}>
        //       <div className="bg-white text-dark p-2 h-100 text-center">
        //         <a
        //           rel="noopener noreferrer"
        //           href="http://wikireviews.com/blog"
        //           target="_blank"
        //           className="text-reset"
        //         >
        //           <img
        //             className="img-fluid blog-img"
        //             src={require("../../assets/images/house_ads_heraldblog_04.png")}
        //             alt=""
        //           />
        //         </a>
        //       </div>
        //     </Col>
        //   );
        // }



      });
    } else {
      return movieFetchLoader
        ? "Loading Movies..."
        : // <Alert color="primary">Loading...</Alert>
        "No Record Found";
    }
  };

  getBusinessList = () => {
    let movieFetchLoader = this.state.movieFetchLoader;
    let results = this.state.businessList;
    let mainViewType = this.state.mainViewType;
    if (results) {
      return results.map((item, index) => {
        return (
          <Col key={index} className="mb-4" style={{ minHeight: "320px" }}>
            <div className="bg-white text-dark p-2 h-100 hoverable-review">
              <div className="review-details">
                <div className="text-center">
                  {item?.listing_profileimage[0]?.url ? (
                    <img
                      className="fixed-height-img is-160 w-100 object-fit-cover object-position-top"
                      src={item.listing_profileimage[0].url}
                      alt=""
                      onError={(error) =>
                        (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)
                      }
                    />
                  ) : (
                      <img
                        className="img-fluid"
                        src={
                          "https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png"
                        }
                        alt=""
                      />
                    )}
                </div>
                <div className="mt-2">
                  <div className="font-weight-bold fs-14">
                    <span>
                      <a href="#" className="text-reset">
                        {item.name.length > 120
                          ? item.name.substring(0, 120) + "..."
                          : item.name}
                      </a>
                    </span>
                  </div>
                  <div className="mb-1 xx-new-90">
                    <img
                      src={
                        item?.total_rating?.avg_rating?.[0]
                          ? require(`../../assets/images/icons/star/blue/${item.total_rating.avg_rating[0]}`)
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <p className="fs-13">
                    <a href="#" className="text-reset">
                      {`${item.total_rating.total_review} Review${item.total_rating.total_review != 1 ? "s" : ""
                        }`}
                    </a>
                  </p>
                </div>
              </div>
              <div className="fs-14 review-mask">
                <div className="p-3">
                  {/* <div className="d-flex align-items-start mb-2">
                      <img className="mr-2 img-circle _50x50" src={item?.review?.created_by?.current_profile_pic?.thumbnail ? `https://userdatawikireviews.s3.amazonaws.com/media/${item.review.created_by.current_profile_pic.thumbnail}` : "https://stagingdatawikireviews.s3.amazonaws.com/images/circle.png"} alt="" onError={(error) => (error.target.src = require("../../assets/images/icons/user-circle.png"))} />
                      <div>
                        <a href="#" className="text-dark font-weight-bold">{item.review != "" && item.review.created_by ? (item.review.created_by.user.first_name + " " + item.review.created_by.user.last_name) : "Someone"}</a> <span className="text-dark">&nbsp;wrote a review for <a href="#" className="text-dark font-weight-bold">{item.name.length > 120 ? item.name.substring(0, 120) + '...' : item.name}</a> and gave it&nbsp;</span>
                        <img src={item.review.ratings_sets && item.review.ratings_sets.length > 0 ? `../../assets/images/icons/star/blue/${item.review.ratings_sets[0]}` : "../../assets/images/icons/star/blue/0rating.png"} alt="" />
                      </div>
                    </div> */}
                  <div className="text-center">
                    {/* <i className="icon-comma-left _sm"></i> */}
                    <span className="comment-body-renderer ff-base fs-14">
                      {item?.review?.review?.length > 95 ? (
                        <React.Fragment>
                          <Markup
                            content={
                              item?.review?.review?.substring(0, 95) +
                              `   ` +
                              `... </p>`
                            }
                          />
                        </React.Fragment>
                      ) : (
                          <Markup content={item?.review?.review} />
                        )}
                    </span>
                    {/* <i className="icon-comma-right _sm"></i> */}
                  </div>
                  <div className="text-center fs-14 mt-3">
                    <Link
                      to={{
                        pathname: `/${mainViewType}/${item.slug}`,
                        state: { id: item.id },
                      }}
                      className="text-reset font-weight-bold"
                    >
                      {"View More"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        );
      });
    } else {
      return movieFetchLoader
        ? "Loading Businesses..."
        : // <Alert color="primary">Loading...</Alert>
        "No Record Found";
    }
  };

  getProductList = () => {
    let ad_index = 6;
    // let skip_items = 1;
    let movieFetchLoader = this.state.movieFetchLoader;
    let results = this.state.productList;
    let mainViewType = this.state.mainViewType;
    if (results) {
      return results.map((item, index) => {
        // if (index !== 1 && (index - 1) % ad_index) {
        //   return (
        //     <Col key={index} className="mb-4" style={{ minHeight: "320px" }}>
        //       <div className="bg-white text-dark p-2 h-100 hoverable-review">
        //         <div className="review-details">
        //           <div className="text-center">
        //             {item?.listing_profileimage[0]?.url ? (
        //               <img
        //                 className="fixed-height-img is-160 w-100 object-fit-cover object-position-top"
        //                 src={item.listing_profileimage[0].url}
        //                 alt=""
        //                 onError={(error) =>
        //                   (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)
        //                 }
        //               />
        //             ) : (
        //                 <img
        //                   className="img-fluid"
        //                   src={
        //                     "https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png"
        //                   }
        //                   alt=""
        //                 />
        //               )}
        //           </div>
        //           <div className="mt-2">
        //             <div className="font-weight-bold fs-14">
        //               <span>
        //                 <a href="#" className="text-reset">
        //                   {item.name.length > 120
        //                     ? item.name.substring(0, 120) + "..."
        //                     : item.name}
        //                 </a>
        //               </span>
        //             </div>
        //             <div className="mb-1 xx-new-90">
        //               <img
        //                 src={
        //                   item?.total_rating?.avg_rating?.[0]
        //                     ? require(`../../assets/images/icons/star/blue/${item.total_rating.avg_rating[0]}`)
        //                     : ""
        //                 }
        //                 alt=""
        //               />
        //             </div>
        //             <p className="fs-13">
        //               <a href="#" className="text-reset">
        //                 {`${item.total_rating.total_review} Review${item.total_rating.total_review != 1 ? "s" : ""
        //                   }`}
        //               </a>
        //             </p>
        //           </div>
        //         </div>
        //         <div className="fs-14 review-mask">
        //           <div className="p-3">
        //             {/* <div className="d-flex align-items-start mb-2">
        //               <img className="mr-2 img-circle _50x50" src={item?.review?.created_by?.current_profile_pic?.thumbnail ? `https://userdatawikireviews.s3.amazonaws.com/media/${item.review.created_by.current_profile_pic.thumbnail}` : "https://stagingdatawikireviews.s3.amazonaws.com/images/circle.png"} alt="" onError={(error) => (error.target.src = require("../../assets/images/icons/user-circle.png"))} />
        //               <div>
        //                 <a href="#" className="text-dark font-weight-bold">{item.review != "" && item.review.created_by ? (item.review.created_by.user.first_name + " " + item.review.created_by.user.last_name) : "Someone"}</a> <span className="text-dark">&nbsp;wrote a review for <a href="#" className="text-dark font-weight-bold">{item.name.length > 120 ? item.name.substring(0, 120) + '...' : item.name}</a> and gave it&nbsp;</span>
        //                 <img src={item.review.ratings_sets && item.review.ratings_sets.length > 0 ? `../../assets/images/icons/star/blue/${item.review.ratings_sets[0]}` : "../../assets/images/icons/star/blue/0rating.png"} alt="" />
        //               </div>
        //             </div> */}
        //             <div className="text-center">
        //               {/* <i className="icon-comma-left _sm"></i> */}
        //               <span className="comment-body-renderer ff-base fs-14">
        //                 {item?.review?.review?.length > 95 ? (
        //                   <React.Fragment>
        //                     <Markup
        //                       content={
        //                         item?.review?.review?.substring(0, 95) +
        //                         `   ` +
        //                         `... </p>`
        //                       }
        //                     />
        //                   </React.Fragment>
        //                 ) : (
        //                     <Markup content={item?.review?.review} />
        //                   )}
        //               </span>
        //               {/* <i className="icon-comma-right _sm"></i> */}
        //             </div>
        //             <div className="text-center fs-14 mt-3">
        //               <Link
        //                 to={{
        //                   pathname: `/${mainViewType}/${item.slug}`,
        //                   state: { id: item.id },
        //                 }}
        //                 className="text-reset font-weight-bold"
        //               >
        //                 {"View More"}
        //               </Link>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </Col>
        //   );
        // } else {
        //   return (
        //     <Col key={index} className="mb-4" style={{ minHeight: "320px" }}>
        //       <div className="bg-white text-dark p-2 h-100 text-center">
        //         <a
        //           rel="noopener noreferrer"
        //           href="http://wikireviews.com/blog"
        //           target="_blank"
        //           className="text-reset"
        //         >
        //           <img
        //             className="img-fluid blog-img"
        //             src={require("../../assets/images/house_ads_heraldblog_04.png")}
        //             alt=""
        //           />
        //         </a>
        //       </div>
        //     </Col>
        //   );
        // }
        return (
          <Col key={index} className="mb-4" style={{ minHeight: "320px" }}>
            <div className="bg-white text-dark p-2 h-100 hoverable-review">
              <div className="review-details">
                <div className="text-center">
                  {item?.listing_profileimage[0]?.url ? (
                    <img
                      className="fixed-height-img is-160 w-100 object-fit-cover object-position-top"
                      src={item.listing_profileimage[0].url}
                      alt=""
                      onError={(error) =>
                        (error.target.src = `https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png`)
                      }
                    />
                  ) : (
                      <img
                        className="img-fluid"
                        src={
                          "https://stagingdatawikireviews.s3.amazonaws.com/images/added-fav.png"
                        }
                        alt=""
                      />
                    )}
                </div>
                <div className="mt-2">
                  <div className="font-weight-bold fs-14">
                    <span>
                      <a href="#" className="text-reset">
                        {item.name.length > 120
                          ? item.name.substring(0, 120) + "..."
                          : item.name}
                      </a>
                    </span>
                  </div>
                  <div className="mb-1 xx-new-90">
                    <img
                      src={
                        item?.total_rating?.avg_rating?.[0]
                          ? require(`../../assets/images/icons/star/blue/${item.total_rating.avg_rating[0]}`)
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <p className="fs-13">
                    <a href="#" className="text-reset">
                      {`${item.total_rating.total_review} Review${item.total_rating.total_review != 1 ? "s" : ""
                        }`}
                    </a>
                  </p>
                </div>
              </div>
              <div className="fs-14 review-mask">
                <div className="p-3">
                  {/* <div className="d-flex align-items-start mb-2">
                    <img className="mr-2 img-circle _50x50" src={item?.review?.created_by?.current_profile_pic?.thumbnail ? `https://userdatawikireviews.s3.amazonaws.com/media/${item.review.created_by.current_profile_pic.thumbnail}` : "https://stagingdatawikireviews.s3.amazonaws.com/images/circle.png"} alt="" onError={(error) => (error.target.src = require("../../assets/images/icons/user-circle.png"))} />
                    <div>
                      <a href="#" className="text-dark font-weight-bold">{item.review != "" && item.review.created_by ? (item.review.created_by.user.first_name + " " + item.review.created_by.user.last_name) : "Someone"}</a> <span className="text-dark">&nbsp;wrote a review for <a href="#" className="text-dark font-weight-bold">{item.name.length > 120 ? item.name.substring(0, 120) + '...' : item.name}</a> and gave it&nbsp;</span>
                      <img src={item.review.ratings_sets && item.review.ratings_sets.length > 0 ? `../../assets/images/icons/star/blue/${item.review.ratings_sets[0]}` : "../../assets/images/icons/star/blue/0rating.png"} alt="" />
                    </div>
                  </div> */}
                  <div className="text-center">
                    {/* <i className="icon-comma-left _sm"></i> */}
                    <span className="comment-body-renderer ff-base fs-14">
                      {item?.review?.review?.length > 95 ? (
                        <React.Fragment>
                          <Markup
                            content={
                              item?.review?.review?.substring(0, 95) +
                              `   ` +
                              `... </p>`
                            }
                          />
                        </React.Fragment>
                      ) : (
                          <Markup content={item?.review?.review} />
                        )}
                    </span>
                    {/* <i className="icon-comma-right _sm"></i> */}
                  </div>
                  <div className="text-center fs-14 mt-3">
                    <Link
                      to={{
                        pathname: `/${mainViewType}/${item.slug}`,
                        state: { id: item.id },
                      }}
                      className="text-reset font-weight-bold"
                    >
                      {"View More"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        );
      });
    } else {
      return movieFetchLoader
        ? "Loading Products..."
        : // <Alert color="primary">Loading...</Alert>
        "No Record Found";
    }
  };

  handleFinalJuryNotification = () => {
    this.props.final_jury_voting({
      id: this.state.finalJury?.id,
      status: "stopnotification",
    });
  };

  // handleFinalCheckJuryduty = () => {
  //   const { finalJury } = this.state
  //   if (finalJury?.review_id) {
  //     this.props.get_review_details(finalJury.review_id)
  //   }

  // }

  handleChangeFinal = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  handleFinalSubmit = () => {
    this.props.final_jury_voting({
      vote: this.state.finalJuryVote,
      remarks: this.state.finalremarks,
      id: this.state.finalJury?.id,
      status: "voting",
    });
  };

  enableLoader = (isLoader) => {
    if (isLoader && isLoader === true) {
      return (
        <div className="loader_div">
          <div className="inner-loader">
            <img src={Loaderimg} alt="" />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    let {
      selectedFilesPreview,
      latestUploadedData,
      fetchTaxonomy,
      embeddedLink,
      drawerModalPopupToggleAddMovie,
      activeTag,
      drawerModalPopupToggleAddCRP,
      drawerModalPopupToggleAddBusiness,
      drawerModalPopupToggleAddProfessional,
      drawerModalPopupToggleAddCRPExpress,
      drawerModalPopupToggleAddProduct,
      drawerModalPopupToggleRateMovieModal,
      drawerModalPopupToggleRateBusinessModal,
      drawerModalPopupToggleRateProductModal,
      drawerModalPopupToggleRateMovieReviewModal,
      drawerModalPopupToggleRateMovieQuestionModal,
      drawerModalPopupToggleRateCrp,
      drawerModalPopupTogglePatrolMovieCRPModal,
      drawerModalPopupTogglePatrolMovieCoreModal,
      drawerModalPopupTogglePatrolMovieMediaModal,
      drawerModalPopupTogglePatrolMovieReviewsModal,
      drawerModalPopupToggleImproveMovieChangeModal,
      drawerModalPopupToggleImproveMovieMediaModal,
      drawerModalPopupToggleImproveMovieTechincalModal,
      drawerModalPopupToggleVoteMovieModal,
      drawerModalPopupToggleVoteProductsModal,
      drawerModalPopupToggleVoteBusinessModal,
      showVideoMedia,
      mediaSet,
      isLoader,
      is_juror,
      get_dispute_data,
      days,
      hours,
      minutes,
      seconds,
      finalJury,
      per_keep,
      per_delete,
      finalremarks,
      showJuryModal,
      reviewId,
      menuCategoryListToShow,
      menuSubCategoryListToShow,
      blocked_user_status
    } = this.state;

    let {
      categories,
      movieCategories,
      videos_children,
      productCategories,
      professionalCategories,
      businessCategories,
      crpCategories,
      project_taxonomies,
    } = this.props;
    let categoryListToShow = categories[0] || [];
    let totalLength = selectedFilesPreview.length;
    let objectKeys = [];
    let objectLength = 0;
    let calculatedPercentage = 0;
    if (Object.keys(latestUploadedData).length > 0) {
      objectKeys = Object.keys(latestUploadedData);
      objectLength = objectKeys.length;
      calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
    }


    return (
      <div>
        {showJuryModal ? <DisputeJuryDuty review={reviewId} /> : ""}
        {/* <div className="col-md-12 mt-5 mb-3 cursor-pointer" style={{ zIndex: 999, background: 'red' }}> */}
        {/* <div  className="col-md-12 mt-5 mb-3 cursor-pointer" style={{ zIndex: 999, background: 'red' }} hidden={process.env.NODE_ENV == "development" ? false : true}>
          <div className="pt-3" onClick={() => { this.props.history.push({ pathname: '/movies/captain-america-civil-war-action-adventure-scifi-fantasy', state: { id: 171477 } }) }}>
            <span>Movies</span>
          </div>

          <div className="pt-3" onClick={() => { this.props.history.push({ pathname: '/products/the-alamo', state: { id: 14687010 } }) }}>
            <span>Products</span>
          </div>

          <div className="pt-3" onClick={() => { this.props.history.push({ pathname: '/actors/null', state: { id: 1873844 } }) }}>
            <span>Actor</span>
          </div>

          <div className="pt-3" onClick={() => { this.props.history.push({ pathname: '/accountants/null', state: { id: 14980995 } }) }}>
            <span>Accountant</span>
          </div>

          <div className="pt-3" onClick={() => { this.props.history.push({ pathname: '/dentists/null', state: { id: 15195537 } }) }}>
            <span>Dentist</span>
          </div>

          <div className="pt-3" onClick={() => { this.props.history.push({ pathname: '/doctors/null', state: { id: 17321856 } }) }}>
            <span>Doctor</span>
          </div>

          <div className="pt-3" onClick={() => { this.props.history.push({ pathname: '/lawyers/null', state: { id: 15029760 } }) }}>
            <span>Lawyers</span>
          </div>

          <div className="pt-3" onClick={() => { this.props.history.push({ pathname: '/brands/the-alamo', state: { id: 14687010 } }) }}>
            <span>Brands</span>
          </div>


        </div> */}
        {!this.state.toggleAuthModal && this.state.showRecommendQuestion ? <RecommendQuestions hideRecommendQuestion={() => {
          this.setState({
            showRecommendQuestion: false
          })
        }} /> : ''}
        {this.state.toggleAuthModal ? (
          <div className="top-popup _fixed-header show">
            <div className="pb-5 pt-4 px-4 bg-primary text-white fs-16">
              {/* <div className=""> */}
              <button
                className="btn btn-sm fs-16 p-0 float-right"
                aria-label="Close"
                onClick={() => {
                  localStorage.setItem("mp", false);
                  this.setState({ toggleAuthModal: false });
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
              {/* </div> */}
              <div>
                <div className="text-center mb-3 lh-1-3">
                  {
                    "Please help us prevent fake reviews and user account by authenticating your account."
                  }
                </div>

                <Row
                  className="justify-content-center align-items-center text-center mx-auto"
                  form
                  style={{ maxWidth: "500px" }}
                >
                  <Col>
                    <div className="text-dark font-weight-bold">{"Phone"}</div>
                  </Col>
                  <Col>
                    <Input
                      className="primary"
                      ref={c => this.nextComponent1 = c}
                      value={this.state.authPhone.ph1}
                      onChange={(e) => {
                        if (e.target.value.length === 3 || e.target.value.length > 3) {

                          ReactDOM.findDOMNode(this.nextComponent).focus();
                        }
                        if (e.target.value.length <= 3) {
                          let { authPhone } = this.state;
                          authPhone["ph1"] = e.target.value;
                          this.setState({ authPhone });
                        }

                      }}
                      bsSize="sm"
                      type="number"
                      name="number_part_1"
                      min="0"
                      max="999"
                      placeholder="(555)"
                    />
                  </Col>
                  <Col>
                    <Input

                      value={this.state.authPhone.ph2}
                      onChange={(e) => {
                        if (e.target.value.length <= 3) {
                          let { authPhone } = this.state;
                          authPhone["ph2"] = e.target.value;
                          this.setState({ authPhone });
                        }
                        if (e.target.value.length === 3 || e.target.value.length > 3) {
                          ReactDOM.findDOMNode(this.nextComponent3).focus();
                        }
                      }}
                      className="primary"
                      bsSize="sm"
                      type="number"
                      name="number_part_2"
                      min="0"
                      max="999"
                      placeholder="555"
                      ref={c => this.nextComponent = c}
                    />
                  </Col>
                  <Col>
                    <Input

                      value={this.state.authPhone.ph3}
                      onChange={(e) => {
                        if (e.target.value.length <= 4) {
                          let { authPhone } = this.state;
                          authPhone["ph3"] = e.target.value;
                          this.setState({ authPhone });
                        }
                        if (e.target.value.length === 4) {
                          ReactDOM.findDOMNode(this.nextComponent3).blur();
                        }
                      }}
                      className="primary"
                      bsSize="sm"
                      type="number"
                      name="number_part_3"
                      min="0"
                      max="9999"
                      placeholder="5555"
                      style={{ width: "120px" }}
                      ref={d => this.nextComponent3 = d}

                    />
                  </Col>
                  <Col xs="auto">
                    <Button

                      onClick={() => {
                        let { authPhone } = this.state;
                        if (
                          authPhone?.ph1?.length == 3 &&
                          authPhone?.ph2?.length == 3 &&
                          authPhone?.ph3?.length == 4
                        ) {
                          let data = {
                            ph1: authPhone?.ph1,
                            ph2: authPhone?.ph2,
                            ph3: authPhone?.ph3,
                          };
                          this.props.updatePhoneNumber(data);
                        }
                      }}
                      disabled={
                        this.state.authPhone?.ph1?.length == 3 &&
                          this.state.authPhone?.ph2?.length == 3 &&
                          this.state.authPhone?.ph3?.length == 4
                          ? false
                          : true
                      }
                      color="text-dark"
                      className="fs-15"
                      size="sm"
                    >
                      Authenticate
                    </Button>
                  </Col>
                  <span className="error-text">
                    {this.state.myProfileMessage}
                  </span>
                </Row>
              </div>
            </div>
          </div>
        ) : (
            ""
          )}
        {/* Selected As Jury PopUp */}
        {this.checkIsLoader(isLoader)}
        {is_juror ? (
          <div className="top-popup _fixed-header show">
            <div className="jury-bg bg-darker text-white text-center p-3 pt-4">
              <Container style={{ maxWidth: "1200px" }}>
                <div className="float-right">
                  <Button
                    color="transparent"
                    className="bg-transparent text-white"
                  >
                    <FontAwesomeIcon
                      icon="times"
                      size="lg"
                      onClick={() => {
                        this.setState({ is_juror: false });
                      }}
                    />
                  </Button>
                </div>
                <div className="fs-30 ff-headings text-primary text-uppercase">
                  Please help us. You have been selected to serve as a juror in
                  resolving a review dispute.
                  <span className="text-white ml-2">Your vote counts!</span>
                </div>

                <div className="py-3">
                  <Row className="justify-content-between align-items-center">
                    <Col xs="4">
                      <div
                        className="video-holder play-icon d-inline-block"
                        role="button"
                        onClick={() =>
                          this.setState({
                            watchHowDisputeReviewWorksModalToggle: true,
                          })
                        }
                      >
                        <img
                          height="80"
                          src={require("../../assets/images/dispute_jury.webp")}
                          alt=""
                        />
                      </div>
                      <div className="mt-2">
                        <Button
                          color="link"
                          className="p-0 text-reset fs-14 font-weight-bold"
                          onClick={() =>
                            this.setState({
                              watchHowDisputeReviewWorksModalToggle: true,
                            })
                          }
                        >
                          learn more
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      <div className="text-center d-flex align-items-center justify-content-center">
                        <img
                          src={require("../../assets/images/icons/star/torquise/fill.png")}
                          alt=""
                        />
                        <Button
                          className="mx-3"
                          color="primary"
                          onClick={this.handleCheckJuryduty}
                        >
                          Click Here
                        </Button>

                        <img
                          src={require("../../assets/images/icons/star/torquise/fill.png")}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col xs="4">
                      <Button
                        color="link"
                        className="text-white"
                        onClick={() => {
                          this.setState({ is_juror: false });
                        }}
                      >
                        next time
                      </Button>
                      <span className="text-white-50">|</span>
                      <Button
                        color="link"
                        className="text-white-50"
                        onClick={() => {
                          this.setState({ is_juror: false });
                          this.handleJuryNotification();
                        }}
                      >
                        never ask me
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </div>
        ) : (
            ""
          )}

        <AppHeader isEnableCover={false} />
        <CollageItems />

        {/* Main Content Starts */}
        <section className="py-4 step-1_home">
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col lg={4}>
                    {/* Actos */}
                    <div className="bg-white px-3 py-3 mb-3">
                      <Actors />
                    </div>

                    {/* Recent Activity */}
                    <RecentActivity />

                    <div className="mb-3 bg-white text-center p-2">
                      <a
                        href="http://wikireviews.com/blog"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          className="img-fluid blog-img"
                          src="https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_02.png"
                          alt=""
                        />
                      </a>
                    </div>
                    {/* My Friends */}
                    {this.state.connections?.results?.length > 0 ? <MyFriends connections={this.state.connections} /> : ''}

                    {/* My Statistics */}
                    <MyStatistics />

                    {/* Connections */}
                    <Connections connections={this.state.connections} />

                    {/* Social Site Accounts */}
                    {/* <div className="bg-white-shaded-md p-3 mb-3">
                      <h2 className="text-secondary">
                        {"Social Site Accounts"}
                      </h2>
                      <hr className="bg-secondary mt-2" />

                      <FacebookLogin
                        // appId={901292833693660}
                        appId={FACEBOOK_APP_ID}
                        autoLoad={this.state.isAllow}
                        callback={(e) => {
                          if (e?.status === "unknown") {
                            this.setState({ isAllow: false }, () => { })
                          }
                        }
                        }
                        render={(renderProps) => renderProps.onClick}
                      />
                      <TwitterLogin
                        ref="child"
                        // authCallback={() => { }}
                        //consumerKey="m7vmrQHqNHl86f8NuARoOQDcF"
                        //consumerSecret="rNl0QWBu94ZnwVWBUPrUIUaZboStaAfVuY5mFcL9k6pyzxsRSt"
						consumerKey="R3v8V8ZGULj2ARY7tOQDaO3Kp"
                        consumerSecret="C8lcrsEyNt2FRIwePiuTGUb6D6ROnsohFoc7tOZOx7OfhRCSCU"
                        //callbackUrl="https://staging.wikireviews.com"
                        //callbackUrl="https://localhost:3000/home"
                        authCallback={(err, res) => {
                          console.log('Error: ', err);
                          console.log('Response: ', res);
                        }}
                        // consumerKey="ZTQJrvKBdJrFVCqcoOVu95QX9"
                        // consumerSecret="43JVhC6fyHHr1n53LLA0vCywzI45tE5M3zxaUzpvw8Hm68cnhZ"
                        //callbackUrl="https://staging.wikireviews.com"
                        children={(props) => props.onClick}
                      />


                      <FormGroup className="d-flex mx-n2">
                        <div className="px-2">
                          <div className="text-secondary-dark">Facebook</div>
                        </div>
                        <div className="px-2 ml-auto">
                          <div className="onoffswitch">
                            <input
                              className="onoffswitch-checkbox"
                              type="checkbox"
                              id="fbonoffswitch"
                              checked={this.state.isAllow || false}
                              onChange={(e) => {
                                this.setState({
                                  isAllow: e.target.checked,
                                });
                                this.props.updateFacebook(e.target.checked);
                                if (e.target.checked === false) {
                                  window.FB.logout();
                                }
                              }}
                            />
                            <label
                              className="onoffswitch-label"
                              htmlFor="fbonoffswitch"
                            >
                              <span className="onoffswitch-inner"></span>
                              <span className="onoffswitch-switch"></span>
                            </label>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup className="d-flex mx-n2 mb-0">
                        <div className="px-2">
                          <div className="text-secondary-dark">Twitter</div>
                        </div>
                        <div className="px-2 ml-auto">
                          <div className="onoffswitch">
                            <input
                              className="onoffswitch-checkbox"
                              type="checkbox"
                              id="twonoffswitch"
                              onChange={(e) => {
                                this.setState({
                                  isTwitterAllow: e.target.checked,
                                });
                                this.props.updateTiwitter(e.target.checked);
                                if (e.target.checked) {
                                  this.refs.child.handleLoginClick();
                                }
                                // if(e.target.checked === false) {
                                //    window.FB.logout()
                                // }
                              }}
                            />
                            <label
                              className="onoffswitch-label"
                              htmlFor="twonoffswitch"
                            >
                              <span className="onoffswitch-inner"></span>
                              <span className="onoffswitch-switch"></span>
                            </label>
                          </div>
                        </div>
                      </FormGroup>
                    </div> */}
                  </Col>
                  <Col lg={6} className="wk-home-new-tabs">
                    <div className="d-flex align-items-start">
                      <Nav tabs className="text-center flex-grow-1">
                        <NavItem className="step-3">
                          <NavLink
                            className="ff-base fs-16"
                            active={this.state.mainViewType === "feed"}
                            onClick={() => {
                              if (this.state.mainViewType === "feed") {
                                return null;
                              }
                              else {
                                this.setState({ mainViewType: "feed" });
                              }
                            }}
                          >
                            Feed
                          </NavLink>
                        </NavItem>
                        <NavItem >
                          <NavLink
                            className="ff-base fs-16 step-4"
                            active={this.state.mainViewType === "myposts"}
                            onClick={() => {
                              if (this.state.mainViewType === "myposts") {
                                return null;
                              }
                              else {
                                this.setState({ mainViewType: "myposts" });
                              }
                            }}
                          >
                            My Posts
                          </NavLink>
                        </NavItem>
                        <span className="d-flex align-items-start ml-auto step-6">
                        <NavItem >
                          <NavLink
                            className="ff-base fs-16"
                            active={this.state.mainViewType === "movies"}
                            onClick={() => {
                              if (this.state.mainViewType === "movies") {
                                return null;
                              }
                              else {
                                this.setState(
                                  {
                                    mainViewType: "movies",
                                    movieList: null,
                                    moviesPage: 1,
                                    getNumber: null,
                                    movieFetchLoader: true,
                                  },
                                  () => {
                                    this.getList(this.state.mainViewType);
                                  }
                                );
                              }
                            }}
                          >
                            Movies
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className="ff-base fs-16"
                            active={this.state.mainViewType === "business"}
                            onClick={() => {
                              if (this.state.mainViewType === "business") {
                                return null;
                              }
                              else {
                                this.setState(
                                  {
                                    mainViewType: "business",
                                    businessList: null,
                                    businessPage: 1,
                                    getNumber: null,
                                    movieFetchLoader: true,
                                  },
                                  () => {
                                    this.getList(this.state.mainViewType);
                                  }
                                );
                              }
                            }}
                          >
                            Business
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className="ff-base fs-16"
                            active={this.state.mainViewType === "products"}
                            onClick={() => {
                              if (this.state.mainViewType === "products") {
                                return null;
                              }
                              else {
                                this.setState(
                                  {
                                    mainViewType: "products",
                                    productList: null,
                                    productPage: 1,
                                    getNumber: null,
                                    movieFetchLoader: true,
                                  },
                                  () => {
                                    this.getList(this.state.mainViewType);
                                  }
                                );
                              }
                            }}
                          >
                            Products
                          </NavLink>
                        </NavItem>
                        </span>
                        
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.mainViewType}>
                      {this.state.mainViewType == "feed" && (
                        <TabPane tabId="feed">
                          <Feed />
                        </TabPane>
                      )}
                      {this.state.mainViewType == "myposts" && (
                        <TabPane tabId="myposts">
                          <Posts enableLoader={this.enableLoader} />
                        </TabPane>
                      )}
                      {this.state.mainViewType == "movies" && (
                        <TabPane tabId="movies">
                          <div className="px-lg-3" id="movies">
                            <Row xs={1} sm={2} md={3} form>
                              {this.getMovieList()}
                            </Row>
                            {(this.state.movieFetchLoader && this.state?.movieList?.length) && <div className="mt-3 text-center">
                              <h2>{"Loading more Movies..."}</h2>
                            </div>}
                          </div>
                        </TabPane>
                      )}
                      {this.state.mainViewType == "business" && (
                        <TabPane tabId="business">
                          <div className="px-lg-3" id="business">
                            <Row xs={1} sm={2} md={3} form>
                              {this.getBusinessList()}
                            </Row>
                            {(this.state.movieFetchLoader && this.state?.businessList?.length) && <div className="mt-3 text-center">
                              <h2>{"Loading more Businesses..."}</h2>
                            </div>}
                          </div>
                        </TabPane>
                      )}
                      {this.state.mainViewType == "products" && (
                        <TabPane tabId="products">
                          <div className="px-lg-3" id="products">
                            <Row xs={1} sm={2} md={3} form>
                              {this.getProductList()}
                            </Row>
                            {(this.state.movieFetchLoader && this.state?.productList?.length) && <div className="mt-3 text-center">
                              <h2>{"Loading more Products..."}</h2>
                            </div>}
                          </div>
                        </TabPane>
                      )}
                    </TabContent>
                  </Col>
                  <Col lg={2}>
                    <div className="mb-4">
                      <Button
                        color="primary a-btn"
                        block
                        onClick={() =>
                          this.setState({
                            createReviewModalToggle: !this.state
                              .createReviewModalToggle,
                          })
                        }
                      >
                        Create a Review
                      </Button>
                    </div>
                    <div className="wk-home-new-adds">
                      <img
                        src="https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_05.png"
                        alt="adds"
                        className="img-fluid mb-2 w-100"
                        useMap="#Map5"
                        width={160}
                        height={600}
                      />
                      <map name="Map5" id="Map5">
                        <area shape="rect" coords="11,500,148,587" href="http://wikireviews.com/blog" target="_blank" alt="" />
                      </map>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Bottom Bar */}
        <section className="fs-14 bg-darker text-tertiary bottom-bar">
          <Container>
            <Row className="justify-content-center justify-content-md-between align-items-center">
              <Col xs="auto">
                <span className="fs-16 mr-2">Participate:</span>
                <ul className="list-unstyled d-inline-flex flex-nonwrap">
                  {menuCategoryListToShow &&
                    menuCategoryListToShow &&
                    Array.isArray(menuCategoryListToShow) &&
                    menuCategoryListToShow.length > 0
                    ? menuCategoryListToShow.map((item, indexMenu) => {
                      return (
                        <OutsideClickHandler
                          key={indexMenu}
                          onOutsideClick={() => {
                            if (
                              !this.state.drawerModalPopupToggleAddMovie &&
                              !drawerModalPopupToggleAddMovie &&
                              !drawerModalPopupToggleAddProduct &&
                              !drawerModalPopupToggleAddBusiness &&
                              !drawerModalPopupToggleAddProfessional &&
                              !drawerModalPopupToggleAddCRP &&
                              !drawerModalPopupToggleAddCRPExpress &&
                              !drawerModalPopupToggleRateMovieModal &&
                              !drawerModalPopupToggleRateMovieReviewModal &&
                              !drawerModalPopupToggleRateMovieQuestionModal &&
                              !drawerModalPopupToggleRateBusinessModal &&
                              !drawerModalPopupToggleRateProductModal &&
                              !drawerModalPopupToggleRateCrp &&
                              activeTag != ""
                            ) {

                              //this.toggleBottomMenu("");
                              //this.handletoggleBottomMenu("","");
                              this.setState({ activeTag: "" });
                            }
                          }}
                        >
                          <li className="drawer-container" key={item.id}>
                            <div
                              className={
                                activeTag === item?.label?.toLowerCase()
                                  ? "drawer-trigger active"
                                  : "drawer-trigger"
                              }
                              onClick={() => {
                                this.handletoggleBottomMenu(
                                  item.id,
                                  item.label.toLowerCase()
                                );
                              }}
                            >
                              {item.label}
                            </div>

                            {menuSubCategoryListToShow ? (
                              <div
                                className={
                                  activeTag === item?.label?.toLowerCase()
                                    ? "drawer-slide"
                                    : "drawer-slide hidden"
                                }
                              >
                                <div className="ff-alt fs-18 mb-2 text-white font-weight-bold">
                                  {menuSubCategoryListToShow?.category}
                                </div>
                                <ul className="list-unstyled d-flex flex-column">
                                  {menuSubCategoryListToShow &&
                                    Array.isArray(
                                      menuSubCategoryListToShow.children
                                    ) &&
                                    menuSubCategoryListToShow.children
                                      .length > 0 &&
                                    menuSubCategoryListToShow.children.map(
                                      (subCategory, SubIndex) => {
                                        return (
                                          <li
                                            className="drawer-slide-item mb-2"
                                            key={subCategory.id}
                                          >
                                            <div
                                              // className="drawer-slide-trigger position-relative"
                                              className={
                                                subCategory.children &&
                                                  Array.isArray(
                                                    subCategory.children
                                                  ) &&
                                                  subCategory.children
                                                    .length > 0
                                                  ? "drawer-slide-trigger position-relative has-dropdown-menu"
                                                  : "drawer-slide-trigger position-relative"
                                              }
                                              onClick={() =>
                                                this.toggleModal(
                                                  `${this.state.activeanotherTag}${subCategory.label}`
                                                )
                                              }
                                            >
                                              {subCategory.label}
                                              {(subCategory.children && subCategory.children.length > 0) &&
                                                <div className="wk-movies-drop-down">
                                                  <ul
                                                    className="list-unstyled d-flex flex-column "
                                                  >
                                                    {subCategory.children &&
                                                      Array.isArray(
                                                        subCategory.children
                                                      ) &&
                                                      subCategory.children
                                                        .length > 0 &&
                                                      subCategory.children?.map(
                                                        (
                                                          subChildren,
                                                          subChildIndex
                                                        ) => {
                                                          return (
                                                            <li
                                                              className="drawer-slide-item mb-2"
                                                              key={subChildren.id}
                                                            >
                                                              <div
                                                                className="drawer-slide-trigger"
                                                                onClick={() =>
                                                                  this.toggleModal(
                                                                    `${this.state.activeTag}Movie${subChildren.label}`
                                                                  )
                                                                }
                                                              >
                                                                {
                                                                  subChildren.label
                                                                }
                                                              </div>
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                  </ul>
                                                </div>
                                              }
                                            </div>
                                          </li>
                                        );
                                      }
                                    )}
                                </ul>
                              </div>
                            ) : null}
                          </li>
                        </OutsideClickHandler>
                      );
                    })
                    : null}
                </ul>
              </Col>
              <Col xs="auto">
                <span className="font-weight-bold">
                  WIKIREVIEWS  © {this.state.currentYear}
                </span>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Drawer overlay */}
        <div className="drawer-overlay"></div>

        {this.state.createReviewModalToggle ? (
          <CreateAReview
            createReviewModalToggle={this.state.createReviewModalToggle}
          />
        ) : (
            ""
          )}

        {/* See who reacted Modal */}
        <Modal
          size="lg"
          isOpen={this.state.viewPeopleWhoReactedModalToggle}
          toggle={() =>
            this.setState({
              viewPeopleWhoReactedModalToggle: !this.state
                .viewPeopleWhoReactedModalToggle,
            })
          }
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                viewPeopleWhoReactedModalToggle: !this.state
                  .viewPeopleWhoReactedModalToggle,
              })
            }
          ></ModalHeader>
          <ModalBody>
            <Nav className="nav-fill active-transparent" tabs>
              <NavItem>
                <NavLink

                  active={this.state.viewReactedType === "all"}
                  onClick={() => {
                    this.setState({ viewReactedType: "all" });
                  }}
                >
                  <div className="ff-base">
                    All&nbsp;<span>2</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "wohoo"}
                  onClick={() => {
                    this.setState({ viewReactedType: "wohoo" });
                  }}
                >
                  <div className="ff-base">
                    Wohoo&nbsp;<span>0</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "nailedIt"}
                  onClick={() => {
                    this.setState({ viewReactedType: "nailedIt" });
                  }}
                >
                  <div className="ff-base">
                    Nailed It&nbsp;<span>1</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "insightful"}
                  onClick={() => {
                    this.setState({ viewReactedType: "insightful" });
                  }}
                >
                  <div className="ff-base">
                    Insightful&nbsp;<span>1</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "lol"}
                  onClick={() => {
                    this.setState({ viewReactedType: "lol" });
                  }}
                >
                  <div className="ff-base">
                    LOL&nbsp;<span>0</span>
                  </div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={this.state.viewReactedType === "bummer"}
                  onClick={() => {
                    this.setState({ viewReactedType: "bummer" });
                  }}
                >
                  <div className="ff-base">
                    Bummer&nbsp;<span>0</span>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.viewReactedType}>
              <TabPane tabId="all">
                <ul className="list-unstyled d-flex flex-column">
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2">
                        <img
                          width="50"
                          height="50"
                          src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/test.4fe86463aab3dbd5fdcc5f02cf8d12ca54aef58e.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href="#"
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          WikiReviews Investor
                        </a>
                        <img
                          src={require("../../assets/images/icons/nailed-it-active.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </li>
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2">
                        <img
                          width="50"
                          height="50"
                          src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/test.4fe86463aab3dbd5fdcc5f02cf8d12ca54aef58e.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href="#"
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          WikiReviews Investor
                        </a>
                        <img
                          src={require("../../assets/images/icons/insightful-active.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </TabPane>
              <TabPane tabId="wohoo">
                <div className="text-secondary-dark font-weight-bold">
                  No People Reacted.
                </div>
              </TabPane>
              <TabPane tabId="nailedIt">
                <ul className="list-unstyled d-flex flex-column">
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2">
                        <img
                          width="50"
                          height="50"
                          src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/test.4fe86463aab3dbd5fdcc5f02cf8d12ca54aef58e.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href="#"
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          WikiReviews Investor
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </TabPane>
              <TabPane tabId="insightful">
                <ul className="list-unstyled d-flex flex-column">
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2">
                        <img
                          width="50"
                          height="50"
                          src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/test.4fe86463aab3dbd5fdcc5f02cf8d12ca54aef58e.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href="#"
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          WikiReviews Investor
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </TabPane>
              <TabPane tabId="lol">
                <div className="text-secondary-dark font-weight-bold">
                  No People Reacted.
                </div>
              </TabPane>
              <TabPane tabId="bummer">
                <div className="text-secondary-dark font-weight-bold">
                  No People Reacted.
                </div>
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          size="sm"
          isOpen={this.state.confirmDeleteModalToggle}
          toggle={() =>
            this.setState({
              confirmDeleteModalToggle: !this.state.confirmDeleteModalToggle,
            })
          }
          className="text-center"
          modalClassName="backdrop-opaque top-shifted transition-none"
        >
          <div className="bg-white modal-header">
            <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
            <button
              className="btn btn-sm"
              aria-label="Close"
              onClick={this.confirmDeleteModalToggle}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <ModalBody className="text-dark">
            Are you sure you want to delete?
          </ModalBody>
          <ModalFooter className="bg-white">
            <div className="text-center w-100">
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={() =>
                  this.setState({
                    confirmDeleteModalToggle: !this.state
                      .confirmDeleteModalToggle,
                  })
                }
              >
                Cancel
              </Button>
              <Button color="primary" size="sm" className="btn-confirm">
                Yes
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        {/* Share By Email Modal Popup */}
        <Modal
          size="sm"
          isOpen={this.state.shareByEmailModalToggle}
          toggle={() =>
            this.setState({
              shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            })
          }
        >
          <ModalHeader
            className="text-uppercase px-0"
            toggle={() =>
              this.setState({
                shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
              })
            }
          >
            Share by Email
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                className="primary"
                bsSize="sm"
                type="text"
                name="sharer_name"
                placeholder="Your name"
              />
            </FormGroup>
            <FormGroup>
              <Input
                className="primary"
                bsSize="sm"
                type="email"
                name="email"
                placeholder="Recipient's email"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="px-0">
            <div className="text-right">
              <Button color="primary">Share</Button>
            </div>
          </ModalFooter>
        </Modal>

        {/* Share By Email Modal Popup */}
        <Modal
          size="sm"
          isOpen={this.state.shareByEmailModalToggle}
          toggle={() =>
            this.setState({
              shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            })
          }
        >
          <ModalHeader
            className="text-uppercase px-0"
            toggle={() =>
              this.setState({
                shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
              })
            }
          >
            Share by Email
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                className="primary"
                bsSize="sm"
                type="text"
                name="sharer_name"
                placeholder="Your name"
              />
            </FormGroup>
            <FormGroup>
              <Input
                className="primary"
                bsSize="sm"
                type="email"
                name="email"
                placeholder="Recipient's email"
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="px-0">
            <div className="text-right">
              <Button color="primary">Share</Button>
            </div>
          </ModalFooter>
        </Modal>
        {/* Media Modal */}
        <Modal
          isOpen={this.state.uploadMediaModal}
          toggle={() =>
            this.setState(
              { uploadMediaModal: !this.state.uploadMediaModal },
              () => {
                this.clearMediaDispatch();
              }
            )
          }
        >
          <ModalHeader
            toggle={() => {
              this.handleCloseAndFetch();
            }}
          >
            UPLOAD MEDIA
          </ModalHeader>
          <ModalBody className="p-3">
            <Row>
              <Col xs={"auto"}>
                <div className="bg-light h-100 p-3">
                  <Nav
                    tabs
                    className="flex-column active-transparent border-0 m-0"
                  >
                    <NavItem className="text-left">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "upload"}
                        onClick={() => {
                          this.setState({ selectedUpMediaType: "upload" });
                        }}
                      >
                        Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "gallery"}
                        onClick={() => {
                          this.setState(
                            { selectedUpMediaType: "gallery" },
                            () => {
                              this.props.media_upload_scroll({
                                type: "image",
                                album: "",
                                page: 1,
                                force_update: true,
                              });
                            }
                          );
                        }}
                      >
                        Gallery
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "embed"}
                        onClick={() => {
                          this.setState({ selectedUpMediaType: "embed" });
                        }}
                      >
                        Embed
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col>
                <TabContent activeTab={this.state.selectedUpMediaType}>
                  <TabPane tabId="upload">
                    {selectedFilesPreview &&
                      selectedFilesPreview.length === 0 ? (
                        <div className="mb-3 type-file-block bg-light">
                          <Input
                            type="file"
                            name="upload_media_file"
                            id="uploadFilesFromSystem"
                            accept="image/*, video/*"
                            onChange={this.handleOnFileUploadChange}
                            multiple
                          />
                          <Label for="uploadFilesFromSystem">
                            <div className="type-bg"></div>
                          </Label>
                        </div>
                      ) : null}
                    <div
                      className="text-right"
                      style={{
                        maxWidth: "120px",
                        margin: "0 1rem 1rem auto",
                      }}
                    >
                      <div className="text-center mb-1 small">
                        {parseInt(calculatedPercentage) === 100 ? (
                          <div className="text-success">
                            <FontAwesomeIcon
                              icon="check-circle"
                              className="mr-1"
                            />{" "}
                            {"Uploaded"}
                          </div>
                        ) : (
                            selectedFilesPreview.length > 0 && (
                              <div>
                                {` Uploading `}
                                <span className="text-success font-weight-bold ff-base">
                                  {calculatedPercentage
                                    ? `${parseInt(calculatedPercentage)} %`
                                    : ""}
                                </span>
                              </div>
                            )
                          )}
                      </div>
                      {selectedFilesPreview.length > 0 && (
                        <Progress
                          value={parseInt(calculatedPercentage)}
                          style={{ height: "8px" }}
                        />
                      )}
                    </div>

                    <Row>
                      {selectedFilesPreview && selectedFilesPreview.length > 0
                        ? selectedFilesPreview.map((item, index) => (
                          <Col className="mb-3" xs="auto" key={index}>
                            <div className="text-center bg-light p-2">
                              <div className="d-inline-flex pr-3 pt-3">
                                <div>
                                  <div
                                    className="selectable-media"
                                    style={{ cursor: "default" }}
                                  >
                                    <div className="gallery-media">
                                      <img
                                        src={item}
                                        alt={`selected-${index}`}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="mx-n3 mt-n3">
                                  <Button
                                    color="delete-media _alt"
                                    size="sm"
                                    title="Remove Media"
                                    onClick={(e) =>
                                      this.removeSelectedImage(e, index)
                                    }
                                  >
                                    <FontAwesomeIcon icon="trash-alt" />{" "}
                                  </Button>
                                </div>
                              </div>
                              {latestUploadedData &&
                                latestUploadedData[index] &&
                                (latestUploadedData[index]["isUpload"] !==
                                  undefined ||
                                  latestUploadedData[index]["isUpload"] !==
                                  null) ? (
                                  latestUploadedData[index]["isUpload"] ? (
                                    <div className="mt-2">
                                      {latestUploadedData[index][
                                        "media_type"
                                      ] === "video" ? (
                                          <>
                                            <InputGroup
                                              style={{ maxWidth: "120px" }}
                                            >
                                              <Input
                                                bsSize="sm"
                                                type="text"
                                                name="vidoeCaption"
                                                className="caption-input"
                                                placeholder="Caption"
                                                onChange={
                                                  this.handleChangeVideoTaxonomy
                                                }
                                              />
                                            </InputGroup>
                                            <InputGroup
                                              style={{ maxWidth: "120px" }}
                                            >
                                              <Input
                                                onChange={
                                                  this.handleChangeVideoTaxonomy
                                                }
                                                bsSize="sm"
                                                type="select"
                                                name="vidoeCaptionCategory"
                                                id="vidoeCaptionCategory"
                                                defaultValue=""
                                              >
                                                <option value="">Select</option>
                                                {videos_children &&
                                                  videos_children.children &&
                                                  Array.isArray(
                                                    videos_children.children
                                                  ) &&
                                                  videos_children.children.length >
                                                  0
                                                  ? videos_children.children.map(
                                                    (item) => (
                                                      <option value={item.id}>
                                                        {item.label}
                                                      </option>
                                                    )
                                                  )
                                                  : null}
                                              </Input>
                                              <InputGroupAddon addonType="append">
                                                <Button
                                                  size="sm"
                                                  color="secondary"
                                                  title="Upload"
                                                  onClick={() => {
                                                    this.props.upload_add_captions(
                                                      latestUploadedData[index][
                                                      "id"
                                                      ],
                                                      {
                                                        caption: this.state
                                                          .vidoeCaption,
                                                        category: this.state
                                                          .vidoeCaptionCategory,
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <FontAwesomeIcon icon="arrow-right" />
                                                </Button>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          </>
                                        ) : (
                                          <InputGroup
                                            style={{ maxWidth: "120px" }}
                                          >
                                            <Input
                                              bsSize="sm"
                                              type="text"
                                              name="caption"
                                              className="caption-input"
                                              placeholder="Caption"
                                              onChange={(e) =>
                                                this.handleCaptionByIndex(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                            <InputGroupAddon addonType="append">
                                              <Button
                                                size="sm"
                                                color="secondary"
                                                title="Upload"
                                                onClick={(e) =>
                                                  this.handleSubmitCaptions(
                                                    e,
                                                    index
                                                  )
                                                }
                                              >
                                                <FontAwesomeIcon icon="arrow-right" />
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        )}
                                    </div>
                                  ) : (
                                      <div className="mt-2 text-center">
                                        <small className="text-danger">
                                          Error while uploading
                                      </small>
                                      </div>
                                    )
                                ) : (
                                  <div className="mt-2 text-center">
                                    <small className="text-info">
                                      Uploading...
                                    </small>
                                  </div>
                                )}
                            </div>
                          </Col>
                        ))
                        : null}
                    </Row>
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.handleCloseAndFetch();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.handleCloseAndFetch();
                          }}
                        >
                          ok
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="gallery">
                    <div className="mb-2">
                      <Nav tabs className="d-inline-flex mb-0">
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "images"}
                            onClick={() => {
                              this.setState(
                                {
                                  showGalleryType: "images",
                                  fetchGalleryType: "image",
                                  fetchGalleryAlbum: "",
                                  fetchGalleryScroll: {},
                                },
                                () => {
                                  this.props.media_upload_scroll({
                                    type: "image",
                                    album: "",
                                    page: 1,
                                    force_update: true,
                                  });
                                }
                              );
                            }}
                          >
                            {"Image Gallery"}
                          </NavLink>
                        </NavItem>
                        {showVideoMedia === true && (
                          <NavItem>
                            <NavLink
                              href="#"
                              active={this.state.showGalleryType === "videos"}
                              onClick={() => {
                                this.setState(
                                  {
                                    showGalleryType: "videos",
                                    fetchGalleryType: "video",
                                    fetchGalleryAlbum: "",
                                    fetchGalleryScroll: {},
                                  },
                                  () => {
                                    this.props.media_upload_scroll({
                                      type: "video",
                                      album: "",
                                      page: 1,
                                      force_update: true,
                                    });
                                  }
                                );
                              }}
                            >
                              {"Video Gallery"}
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.showGalleryType}>
                      <TabPane tabId="images">
                        <div>
                          <FormGroup className="form-row justify-content-end">
                            <Col xs="auto">
                              <Label className="small" for="selectAlbumType">
                                Album Type:
                              </Label>
                            </Col>
                            <Col xs="auto">
                              <Input
                                onChange={this.handleChangeTaxonomy}
                                bsSize="sm"
                                type="select"
                                name="album_type_select"
                                id="selectAlbumType"
                                defaultValue="all"
                              >
                                <option value="all">All</option>
                                {fetchTaxonomy &&
                                  Array.isArray(fetchTaxonomy) &&
                                  fetchTaxonomy.length > 0
                                  ? fetchTaxonomy.map((item) => (
                                    <option value={item.category}>
                                      {item.label}
                                    </option>
                                  ))
                                  : null}
                              </Input>
                            </Col>
                          </FormGroup>
                        </div>
                        <div
                          ref={this.refGalleryImageScroll}
                          onScroll={this.handleScrollGalleryImage}
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                        >
                          <Row>{this.fetchScrollPhotoMedia()}</Row>
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() =>
                                this.setState({
                                  uploadMediaModal: !this.state
                                    .uploadMediaModal,
                                })
                              }
                            >
                              {"Cancel"}
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() =>
                                this.setState({
                                  uploadMediaModal: !this.state
                                    .uploadMediaModal,
                                })
                              }
                            >
                              {"Ok"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="videos">
                        <div
                          ref={this.refGalleryVideoScroll}
                          onScroll={this.handleScrollGalleryVideo}
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                        >
                          <Row>{this.fetchScrollPhotoMedia(true)}</Row>
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() =>
                                this.setState({
                                  uploadMediaModal: !this.state
                                    .uploadMediaModal,
                                })
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() =>
                                this.setState({
                                  uploadMediaModal: !this.state
                                    .uploadMediaModal,
                                })
                              }
                            >
                              {"Ok"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="embed">
                    <div className="text-muted mb-2">
                      Submit the link for the image or video you want to embed{" "}
                    </div>
                    <div className="mb-2">
                      {/* Repeat this while adding */}
                      <div className="d-flex mx-n2 mb-2" key={0}>
                        <div className="flex-fill px-2">
                          <Input
                            type="url"
                            bsSize="sm"
                            className="bg-white"
                            value={embeddedLink}
                            onChange={(e) => {
                              this.setState({ embeddedLink: e.target.value });
                            }}
                            placeholder="Embeded link"
                          />
                        </div>
                        {/* <div className="px-2">
                                                    <Button
                                                        title="Remove"
                                                        color="danger"
                                                        size="sm"
                                                        hidden={false}
                                                        onClick={() => { }}>
                                                        <FontAwesomeIcon icon="minus" />
                                                    </Button>
                                                </div> */}
                      </div>
                    </div>
                    {/* <div className="d-flex mx-n2 mb-2">
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    title="Add"
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => { }}>
                                                    <FontAwesomeIcon icon="plus" />
                                                </Button>
                                            </div>
                                        </div> */}
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.setState({
                              embeddedLink: "",
                              uploadMediaModal: false,
                            });
                          }}
                        >
                          {"Cancel"}
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={(e) => {
                            this.AddNewEmbedLink(e);
                          }}
                        >
                          {"Insert"}
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        {/* SEE HOW REVIEW DISPUTES WORK */}
        <Modal
          isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
          toggle={() =>
            this.setState({
              watchHowDisputeReviewWorksModalToggle: !this.state
                .watchHowDisputeReviewWorksModalToggle,
            })
          }
        >
          <ModalHeader
            className="text-uppercase px-0"
            toggle={() =>
              this.setState({
                watchHowDisputeReviewWorksModalToggle: !this.state
                  .watchHowDisputeReviewWorksModalToggle,
              })
            }
          >
            SEE HOW REVIEW DISPUTES WORK
          </ModalHeader>
          <ModalBody className="p-0 bg-transparent">
            <div className="text-center">
              <video controls autoPlay width="100%">
                <source
                  src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </ModalBody>
        </Modal>

        {/* Jury Final Voting Modal */}
        <Modal
          size="xl"
          // scrollable
          className="drawer-modal"
          isOpen={this.state.finalJuryVotingToggle}
          // isOpen = {true}
          toggle={() =>
            this.setState({
              finalJuryVotingToggle: !this.state.finalJuryVotingToggle,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            {/* Dispute review intro design */}
            <div className="row mb-5 form flex-grow-1">
              <div className="col-lg-8 ml-auto">
                <div className="bg-warning text-dark p-2 fs-14">
                  <div className="d-flex flex-wrap mx-n2">
                    <div className="text-white px-2">
                      <span className="font-weight-normal">
                        {" "}
                        This is the{" "}
                        <span className="font-weight-bold"> review </span> which
                        has been through dispute process.{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-3 fs-14 text-dark">
                  <div className="d-flex mx-n2 mb-2">
                    <div className="px-2">
                      <a href="#" className="text-decoration-none">
                        <img
                          className="img-circle _50x50"
                          src={
                            this.state.reviewAdded &&
                            this.state.reviewAdded.created_user_media &&
                            this.state.reviewAdded.created_user_media.url
                          }
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="px-2 flex-grow-1">
                      <div className="px-2 flex-grow-1">
                        <div className="ff-alt">
                          <a
                            // href={`/people/${this.state.reviewAddedUser}`}
                            className="text-reset ff-base font-weight-bold"
                          >
                            {this.state.reviewAdded &&
                              this.state.reviewAdded?.associated_to &&
                              this.state.reviewAdded?.associated_to.owner
                                ?.first_name}{" "}
                            {this.state.reviewAdded &&
                              this.state.reviewAdded?.associated_to &&
                              this.state.reviewAdded?.associated_to.owner
                                ?.last_name}
                          </a>
                          <span> wrote a review for </span>
                          <Link
                            to={{
                              pathname: `/${this.state.reviewAdded?.associated_to?.content_type?.category?.toLowerCase()}/${this.state.reviewAdded?.associated_to?.slug
                                ? this.state.reviewAdded?.associated_to?.slug
                                : "null"
                                }`,
                              state: {
                                id: this.state.reviewAdded?.associated_to
                                  ?.content_type?.id,
                              },
                            }}
                            className="text-reset font-weight-bold"
                          >
                            {this.state.reviewAdded &&
                              this.state.reviewAdded.associated_to &&
                              this.state.reviewAdded.associated_to.name}
                          </Link>{" "}
                          and gave it
                          <br />
                          <Ratings
                            ratings={
                              this.state.reviewAdded &&
                              this.state.reviewAdded.ratings_sets
                            }
                          />
                          <span>
                            {" "}
                            {this.state.reviewAdded &&
                              this.state.reviewAdded.review_date_time}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ff-alt my-4">
                    <i className="icon-comma-left"></i>
                    <span className="comment-body-renderer">
                      {" "}
                      {this.state.reviewAdded?.review}
                    </span>
                    <i className="icon-comma-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        finalJuryVotingToggle: !this.state
                          .finalJuryVotingToggle,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
                <div className="bg-dark text-white fs-14 px-3">
                  <div className="mb-2 pt-2">
                    <div className="d-flex mx-n2">
                      <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            className="text-white bg-transparent shadow-none font-weight-bold"
                            color="transparent"
                            size="sm"
                            caret
                          >
                            <span className="pr-2">Disputed Reviews</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="fs-14">
                              Disputed Reviews
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="px-2 col-auto">
                        near{" "}
                        <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled d-flex flex-wrap mx-n2">
                    <li className="px-2">
                      <div className="d-flex">
                        <FormGroup>
                          <Input
                            type="select"
                            className="transparent"
                            size="sm"
                          >
                            <option>Businesses</option>
                          </Input>
                        </FormGroup>
                        <div className="ml-2">
                          <span className="text-danger">*</span>
                        </div>
                      </div>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>IT Services</option>
                          <option>Apartment Rentals</option>
                          <option>Automotive</option>
                          <option>Education</option>
                          <option>Beauty and Spas</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Asian</option>
                          <option>European</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Indian</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Level 6</option>
                        </Input>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                      style={{ minWidth: "300px" }}
                    >
                      <div>
                        <img
                          src={require("../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                        <img
                          src={require("../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            {get_dispute_data &&
                              Array.isArray(get_dispute_data) &&
                              get_dispute_data?.length &&
                              get_dispute_data[0] &&
                              get_dispute_data[0].is_business_owner ? (
                                <h3 className="text-light">
                                  Business Owner Initiated.
                                </h3>
                              ) : (
                                <h3 className="text-light">
                                  Community Initiated.
                                </h3>
                              )}
                            <div className="fs-14">
                              <div className="text-primary fs-16 font-weight-bold">
                                Step 1. Your vote is needed.
                              </div>
                              <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p>
                            </div>
                          </div>
                        </Col>
                        {this.state.can_vote ? (
                          <Col md="auto" className="align-self-end">
                            <div className="text-light font-weight-bold">
                              Submission time ends in
                            </div>
                            <div className="d-flex mx-n2 flex-nowrap">
                              <div className="px-2">
                                <div className="text-white">
                                  <div className="fs-32 ff-headings">
                                    {days}
                                  </div>
                                  <div className="font-weight-bold">days</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    {hours}
                                  </div>
                                  <div className="font-weight-normal">
                                    hours
                                  </div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    {minutes}
                                  </div>
                                  <div className="font-weight-normal">
                                    {"minutes"}
                                  </div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    {seconds}
                                  </div>
                                  <div className="font-weight-normal">
                                    seconds
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ) : (
                            <Col md="auto" className="align-self-end">
                              <div className="text-light font-weight-bold">
                                Submission time has ENDED!
                            </div>
                              <div className="d-flex mx-n2 flex-nowrap">
                                <div className="px-2">
                                  <div className="text-muted">
                                    <div className="fs-32 ff-headings">
                                      {days}
                                    </div>
                                    <div className="font-weight-normal">days</div>
                                  </div>
                                </div>
                                <div className="px-2">
                                  <div className="text-muted">
                                    <div className="fs-32 ff-headings text-right">
                                      00
                                  </div>
                                    <div className="font-weight-normal">
                                      hours
                                  </div>
                                  </div>
                                </div>
                                <div className="px-2">
                                  <div className="text-muted">
                                    <div className="fs-32 ff-headings text-right">
                                      00
                                  </div>
                                    <div className="font-weight-normal">
                                      minutes
                                  </div>
                                  </div>
                                </div>
                                <div className="px-2">
                                  <div className="text-muted">
                                    <div className="fs-32 ff-headings text-right">
                                      00
                                  </div>
                                    <div className="font-weight-normal">
                                      seconds
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="body-bg px-3 py-3">
                <Row>
                  <Col>
                    In case you don't know the correct address, try looking for
                    it with our search bar.
                  </Col>
                  <Col xs="auto">
                    <div className="d-flex">
                      <Input
                        className="primary"
                        bsSize="sm"
                        type="search"
                        placeholder="Custom Search"
                        style={{ minWidth: "300px" }}
                      ></Input>
                      <Button color="primary" size="sm" className="ml-3">
                        <FontAwesomeIcon icon="search" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16">
                      Dispute History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">
                              <Moment format="DD-MM-YYYY">
                                {get_dispute_data &&
                                  Array.isArray(get_dispute_data) &&
                                  get_dispute_data?.length &&
                                  get_dispute_data[0] &&
                                  get_dispute_data[0].created_on}
                              </Moment>
                              &nbsp; at &nbsp;
                              <Moment format="HH:MM:SS">
                                {get_dispute_data &&
                                  Array.isArray(get_dispute_data) &&
                                  get_dispute_data?.length &&
                                  get_dispute_data[0] &&
                                  get_dispute_data[0].created_on}
                              </Moment>
                            </div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              {get_dispute_data[0] &&
                                get_dispute_data[0].user_name}
                            </a>
                            &nbsp; disputed as "{" "}
                            {get_dispute_data[0] &&
                              get_dispute_data[0].flag_name}{" "}
                            "
                          </div>
                        </div>
                      </li>

                      {get_dispute_data &&
                        get_dispute_data.map((discussion) => (
                          <>
                            {discussion.child_key_name === 1 ||
                              discussion.child_key_name === "1" ? (
                                <li className="py-2 border-bottom">
                                  <div className="d-flex mx-n1">
                                    <div className="px-1">
                                      <div className="ff-alt">
                                        <Moment format="DD-MM-YYYY">
                                          {discussion && discussion.created_on}
                                        </Moment>
                                      &nbsp; at &nbsp;
                                      <Moment format="HH:MM:SS">
                                          {discussion && discussion.created_on}
                                        </Moment>
                                      </div>
                                    </div>
                                    <div className="px-1">
                                      <a
                                        href="#"
                                        className="text-dark ff-base font-weight-bold"
                                      >
                                        {discussion && discussion.user_name}
                                      </a>
                                    &nbsp; reverted edits by "
                                    {discussion && discussion.content_flagged}"
                                  </div>
                                  </div>
                                </li>
                              ) : (
                                ""
                              )}
                          </>
                        ))}
                    </ul>
                  </Col>
                  <Col md={8}>
                    <div className="mb-3">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 2. Some Copy Line here
                      </div>
                      <div>
                        <div className="bg-light p-2 text-dark mb-2">
                          <div className="text-tertiary">
                            Flagged for
                            <strong>
                              {" "}
                              "{" "}
                              {get_dispute_data &&
                                Array.isArray(get_dispute_data) &&
                                get_dispute_data?.length &&
                                get_dispute_data[0] &&
                                get_dispute_data[0].flag_name}{" "}
                              "{" "}
                            </strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>
                                      {get_dispute_data &&
                                        Array.isArray(get_dispute_data) &&
                                        get_dispute_data?.length &&
                                        get_dispute_data[0] &&
                                        get_dispute_data[0].user_name}
                                    </strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    Created on&nbsp;
                                    <span className="text-reset">
                                      <Moment format="MMM DD,YYYY">
                                        {get_dispute_data &&
                                          Array.isArray(get_dispute_data) &&
                                          get_dispute_data?.length &&
                                          get_dispute_data[0] &&
                                          get_dispute_data[0].created_on}
                                      </Moment>
                                      &nbsp; , &nbsp;
                                      <Moment format="HH:MM">
                                        {get_dispute_data &&
                                          Array.isArray(get_dispute_data) &&
                                          get_dispute_data?.length &&
                                          get_dispute_data[0] &&
                                          get_dispute_data[0].created_on}
                                      </Moment>
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  "{" "}
                                  {get_dispute_data &&
                                    Array.isArray(get_dispute_data) &&
                                    get_dispute_data?.length &&
                                    get_dispute_data[0] &&
                                    get_dispute_data[0].content_flagged}{" "}
                                  "
                                </div>
                                <div className="bg-light p-3">
                                  <div className="mb-2">
                                    {get_dispute_data &&
                                      Array.isArray(get_dispute_data) &&
                                      get_dispute_data?.length &&
                                      get_dispute_data[0] &&
                                      get_dispute_data[0]?.media &&
                                      Array.isArray(
                                        get_dispute_data[0].media
                                      ) &&
                                      get_dispute_data[0]?.media?.length &&
                                      get_dispute_data[0].media?.map(
                                        (media) => (
                                          <div>
                                            {media.includes(".jpeg") ||
                                              media.includes(".jpg") ||
                                              media.includes(".png") ? (
                                                <div className="mb-2">
                                                  <img
                                                    className="img-fluid"
                                                    src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                    alt=""
                                                  />
                                                </div>
                                              ) : (
                                                <div className="mb-2 d-inline-block">
                                                  <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                      <FontAwesomeIcon
                                                        icon="paperclip"
                                                        fixedWidth
                                                      />
                                                    </div>
                                                    <div className="px-2 col">
                                                      <span className="font-weight-bold text-truncate">
                                                        <a
                                                          className="text-reset font-weight-bold"
                                                          href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                          rel="noopener noreferrer"
                                                          target="_blank"
                                                        >
                                                          {
                                                            media
                                                              .toString()
                                                              .split("/")[2]
                                                          }
                                                        </a>
                                                      </span>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                      <span className="mr-2">
                                                        |
                                                    </span>
                                                      <a
                                                        href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        className="text-reset"
                                                      >
                                                        <FontAwesomeIcon icon="external-link-alt" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {get_dispute_data && get_dispute_data.length > 1 ? (
                        <div className="text-tertiary fs-16 font-weight-bold mt-4">
                          Discussion between parties
                        </div>
                      ) : (
                          ""
                        )}
                      <hr className="mt-2" />

                      <div>
                        {get_dispute_data && get_dispute_data.length > 1 ? (
                          <div className="p-2 text-dark">
                            {get_dispute_data &&
                              get_dispute_data.map((discussion) => (
                                <div className="mb-3">
                                  {discussion.child_key_name === 1 ||
                                    discussion.child_key_name === "1" ? (
                                      <div className="d-flex mx-n2">
                                        <div className="px-2">
                                          <img
                                            className="img-circle _30x30"
                                            src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                            alt=""
                                          />
                                        </div>
                                        <div className="px-2 col">
                                          <div className="mb-3">
                                            <div className="d-flex mx-n2 mb-1">
                                              <div className="px-2 col">
                                                <a
                                                  href="#"
                                                  className="text-reset"
                                                >
                                                  <strong>
                                                    {discussion.user_name}
                                                  </strong>
                                                </a>
                                              &nbsp;
                                            </div>
                                              <div className="col-auto">
                                                <div className="text-tertiary">
                                                  <span className="text-reset">
                                                    <Moment format="MMM DD,YYYY">
                                                      {discussion.created_on}
                                                    </Moment>
                                                  &nbsp; , &nbsp;
                                                  <Moment format="HH:MM">
                                                      {discussion.created_on}
                                                    </Moment>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              {discussion.content_flagged}
                                            </div>
                                          </div>
                                          <div className="bg-light p-3">
                                            <div className="mb-2">
                                              {discussion &&
                                                discussion?.media !=
                                                "No media found" &&
                                                discussion.media?.map((media) => (
                                                  <div>
                                                    {media.includes(".jpeg") ||
                                                      media.includes(".jpg") ||
                                                      media.includes(".png") ? (
                                                        <div className="mb-2">
                                                          <img
                                                            className="img-fluid"
                                                            src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                            alt=""
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div className="mb-2 d-inline-block">
                                                          <div className="d-flex mx-n2">
                                                            <div className="px-2">
                                                              <FontAwesomeIcon
                                                                icon="paperclip"
                                                                fixedWidth
                                                              />
                                                            </div>
                                                            <div className="px-2 col">
                                                              <span className="font-weight-bold text-truncate">
                                                                <a
                                                                  className="text-reset font-weight-bold"
                                                                  href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                  rel="noopener noreferrer"
                                                                  target="_blank"
                                                                >
                                                                  {
                                                                    media
                                                                      .toString()
                                                                      .split("/")[2]
                                                                  }
                                                                </a>
                                                              </span>
                                                            </div>
                                                            <div className="px-2 ml-auto">
                                                              <span className="mr-2">
                                                                |
                                                          </span>
                                                              <a
                                                                href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                                className="text-reset"
                                                              >
                                                                <FontAwesomeIcon icon="external-link-alt" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                </div>
                              ))}
                          </div>
                        ) : (
                            ""
                          )}
                        <hr />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-light px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="bg-dark text-white text-center p-3 bg-vote-alt">
                      <div className="my-2 ff-headings fs-50">CAST</div>
                      <img
                        className="my-2"
                        src={require("../../assets/images/icons/star/white/fill.png")}
                        alt=""
                      />
                      <div className="my-2 ff-headings fs-50">YOUR</div>
                      <img
                        className="my-2"
                        src={require("../../assets/images/icons/star/white/fill.png")}
                        alt=""
                      />
                      <div className="my-2 ff-headings fs-50">VOTE</div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div>
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 3. Some copy line
                      </div>
                      <div>
                        <div className="bg-white p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input
                                    type="radio"
                                    name="finalJuryVote"
                                    onChange={this.handleChangeFinal}
                                    value="3"
                                  />{" "}
                                  <div className="mb-1">Keep this review</div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">{per_keep}%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      {finalJury?.votes?.count_keep} votes out
                                      of 16
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                          </div>

                          {/* Collapse content */}
                        </div>
                        <div className="bg-white p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input
                                    type="radio"
                                    name="finalJuryVote"
                                    onChange={this.handleChangeFinal}
                                    value="4"
                                  />{" "}
                                  <div className="mb-1">Delete this review</div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">
                                      {per_delete}%
                                    </div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      {finalJury?.votes?.count_delete} votes out
                                      of 16
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                          </div>

                          {/* Collapse content */}
                        </div>
                      </div>
                    </div>
                    {this.state.finalJury?.status === "voted" ? (
                      <div className="mt-4">
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          <Input
                            type="textarea"
                            className="primary"
                            rows="3"
                            name="finalremarks"
                            value={finalremarks}
                            readOnly
                            defaultValue="The Info is accurate, I've been there today."
                            placeholder="Please elaborate..."
                          />
                          Thanks for submitting your Vote!
                        </div>
                      </div>
                    ) : (
                        <div className="mt-4">
                          <div className="text-primary fs-16 font-weight-bold mb-2">
                            Step 4. Please, tell us why.
                        </div>
                          <div className="text-dark">
                            It would be really helpful for fellow voters to know
                            the reasoning of your decision. It has to be 140
                            characters long. Some text like that.
                        </div>
                          <div className="text-right text-primary fs-14">65</div>
                          <Input
                            type="textarea"
                            className="primary"
                            rows="3"
                            name="finalremarks"
                            value={finalremarks}
                            onChange={this.handleChangeFinal}
                            defaultValue="The Info is accurate, I've been there today."
                            placeholder="Please elaborate..."
                          />
                        </div>
                      )}
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter className="bg-white">
              {this.state.finalJury?.status === "voted" ? (
                ""
              ) : (
                  <Button color="primary" onClick={this.handleFinalSubmit}>
                    Submit
                  </Button>
                )}
              <Button
                color="link"
                className="font-weight-bold"
                onClick={() =>
                  this.setState({
                    finalJuryVotingToggle: !this.state.finalJuryVotingToggle,
                  })
                }
              >
                Skip
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        {/*  Media Modal  */}
        <MovieModal
          openModal={drawerModalPopupToggleAddMovie}
          toggleMovieModal={() => this.toggleModal("addMovie")}
          movies={movieCategories[0] || []}
          onMediaUpload={this.handleMediaUpload}
          mediaSet={mediaSet}
          onSubmit={this.props.addMovieListing}
          removeFromMedia={this.removeFromMedia}
        />

        <CommunityModal
          openModal={drawerModalPopupToggleAddCRP}
          toggleCommunityModal={() =>
            this.toggleModal("addCommunity Review Project")
          }
        />

        <CRPExpressModal
          openModal={drawerModalPopupToggleAddCRPExpress}
          toggleCRPExpressModal={() => this.toggleModal("addCRP Express")}
        />

        <BusinessModal
          openModal={drawerModalPopupToggleAddBusiness}
          toggleBusinessModal={() => this.toggleModal("addBusiness")}
          businesses={businessCategories || []}
          onMediaUpload={this.handleMediaUpload}
          mediaSet={mediaSet}
          onSubmit={this.props.addBusinessListing}
          removeFromMedia={this.removeFromMedia}
        />

        <ProductModal
          openModal={drawerModalPopupToggleAddProduct}
          toggleProductModal={() => this.toggleModal("addProduct")}
          products={productCategories || []}
          onMediaUpload={this.handleMediaUpload}
          mediaSet={mediaSet}
          onSubmit={this.props.addProductListing}
          removeFromMedia={this.removeFromMedia}
        />

        <ProfessionalModal
          openModal={drawerModalPopupToggleAddProfessional}
          toggleProfessionalModal={() => this.toggleModal("addProfessional")}
          professionals={professionalCategories || []}
          onMediaUpload={this.handleMediaUpload}
          mediaSet={mediaSet}
          onSubmit={this.props.addprofessionalsListing}
          removeFromMedia={this.removeFromMedia}
        />

        {/* Rate Modal Popup */}
        <RateBusinessModal
          openModal={drawerModalPopupToggleRateBusinessModal}
          toggleRateBusinessModal={() => this.toggleModal("rateBusiness")}
          businesses={businessCategories || []}
        />

        <RateProductModal
          openModal={drawerModalPopupToggleRateProductModal}
          toggleRateProductModal={() => this.toggleModal("rateProducts")}
          products={productCategories || []}
        />

        <RateMovieModal
          openModal={drawerModalPopupToggleRateMovieModal}
          toggleRateMovieModal={() =>
            this.toggleModal(this.state.RateMovieType)
          }
          movies={movieCategories[0] || []}
          ratingType={this.state.RateMovieType}
        />
        <RateMovieReviewModal
          openModal={drawerModalPopupToggleRateMovieReviewModal}
          toggleRateMovieModal={() =>
            this.toggleModal(this.state.RateMovieType)
          }
          movies={movieCategories[0] || []}
          ratingType={this.state.RateMovieType}
        />
        <RateMovieQuestionModal
          openModal={drawerModalPopupToggleRateMovieQuestionModal}
          toggleRateMovieModal={() =>
            this.toggleModal(this.state.RateMovieType)
          }
          movies={movieCategories[0] || []}
          ratingType={this.state.RateMovieType}
        />
        <RateCrpModal
          openModal={drawerModalPopupToggleRateCrp}
          toggleCrpModal={() => this.toggleModal("rateCRPProjects")}
          project_taxonomies={project_taxonomies || []}
        />
        {/* End */}

        {/* Patrol Modal Popup */}
        <PatrolCRPModal
          openModal={drawerModalPopupTogglePatrolMovieCRPModal}
          togglePatrolCRPModal={() =>
            this.toggleModal("patrolCommunity Review Project")
          }
        />
        <PatrolMovieCoreModal
          openModal={drawerModalPopupTogglePatrolMovieCoreModal}
          togglePatrolMovieCoreModal={() =>
            this.toggleModal("patrolMovieCore listing")
          }
          movies={movieCategories[0] || []}
        />
        <PatrolMovieMediaModal
          openModal={drawerModalPopupTogglePatrolMovieMediaModal}
          togglePatrolMovieMediaModal={() =>
            this.toggleModal("patrolMovieMedia")
          }
          movies={movieCategories[0] || []}
        />
        <PatrolMovieReviewsModal
          openModal={drawerModalPopupTogglePatrolMovieReviewsModal}
          togglePatrolMovieReviewsModal={() =>
            this.toggleModal("patrolMovieReviews")
          }
          movies={movieCategories[0] || []}
        />
        {/* End */}

        {/* Improve Modal Popup */}
        <ImproveMovieChangeModal
          openModal={drawerModalPopupToggleImproveMovieChangeModal}
          toggleImproveMovieChangeModal={() =>
            this.toggleModal("improveMovieChange Listing Name")
          }
          movies={movieCategories[0] || []}
        />
        <ImproveMovieMediaModal
          openModal={drawerModalPopupToggleImproveMovieMediaModal}
          toggleImproveMovieMediaModal={() =>
            this.toggleModal("improveMovieMedia")
          }
          movies={movieCategories[0] || []}
        />
        <ImproveMovieTechincalModal
          openModal={drawerModalPopupToggleImproveMovieTechincalModal}
          toggleImproveMovieTechincalModal={() =>
            this.toggleModal("improveMovieTechnical Details")
          }
          movies={movieCategories[0] || []}
        />
        {/* End */}

        {/* Improve Modal Popup */}
        <VoteMovieModal
          openModal={drawerModalPopupToggleVoteMovieModal}
          toggleVoteMovieModal={() => this.toggleModal("voteMovies")}
          VoteType={"Movies"}
        />

        <VoteMovieModal
          openModal={drawerModalPopupToggleVoteProductsModal}
          toggleVoteMovieModal={() => this.toggleModal("voteProducts")}
          VoteType={"Products"}
        />

        <VoteMovieModal
          openModal={drawerModalPopupToggleVoteBusinessModal}
          toggleVoteMovieModal={() => this.toggleModal("voteBusiness")}
          VoteType={"Business"}
        />

        {/* End */}

        {/* Authenticate User Modal Popup */}
        <Modal
          size="sm"
          isOpen={this.state.showUserAuthenticateModalToggle}
          toggle={() =>
            this.setState({
              showUserAuthenticateModalToggle: !this.state
                .showUserAuthenticateModalToggle,
            })
          }
        >
          <ModalBody className="bg-primary text-white fs-14">
            <div className="float-right">
              <button
                className="btn btn-sm fs-16"
                aria-label="Close"
                onClick={() =>
                  this.setState({
                    showUserAuthenticateModalToggle: false,
                  })
                }
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div>
              <div className="text-center mb-3 lh-1-3">
                {
                  "Please help us prevent fake reviews and user account by authenticating your account --."
                }
              </div>

              <Row
                className="justify-content-center align-items-center text-center"
                form
              >
                <Col>
                  <div className="text-dark font-weight-bold">Phone</div>
                </Col>
                <Col>
                  <Input
                    className="primary"
                    bsSize="sm"
                    type="number"
                    name="number_part_1"
                    placeholder="(555)"
                  />
                </Col>
                <Col>
                  <Input
                    className="primary"
                    bsSize="sm"
                    type="number"
                    name="number_part_2"
                    placeholder="555"
                  />
                </Col>
                <Col>
                  <Input
                    className="primary"
                    bsSize="sm"
                    type="number"
                    name="number_part_3"
                    placeholder="5555"
                    style={{ width: "80px" }}
                  />
                </Col>
              </Row>
              <div className="text-center mt-3">
                <Button color="text-dark rounded" size="sm">
                  Authenticate
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Account Blockec Modal */}
        <Modal
          isOpen={this.state.ifAccountBlocked}
          toggle={() =>
            this.setState({
              ifAccountBlocked: !this.state.ifAccountBlocked
            })
          }
          backdrop={'static'}
        >
          <ModalBody>
            <h3 className="fs-23 ff-base text-dark">Currently your account is blocked, Would you like to unblock your account ?</h3>
          </ModalBody>
          <ModalFooter className="bg-white ">
            <Button onClick={(e) => this.handleOnSubmitUnblockAccount(e)} color="primary">Confirm</Button>
            <Button color="primary">Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal centered isOpen={this.state.welcomeModalToggle} id="tour1">
          <ModalHeader>
            Hi <span className="userName">Test!</span><br /> Welcome to WikiReviews!
          </ModalHeader>
          <ModalBody>
            <p>Get familiar with the interface.<br />Take a <b>short tour</b> with us!</p>
            <Button color="primary" className="tourbtn">OK</Button>
          </ModalBody>
          <ModalFooter>
            <div>
              <a href="javascript:void(0);" className="t2-skip">Skip this part and learn how to create a review</a>
              <br />
              <a href="javascript:void(0);" className="t2-skip" onClick={() => this.setState({ welcomeModalToggle: false })}>Or I'll explore on my own</a>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  facebook: state.connections.enable_facebook,
  twitter: state.connections.enable_tiwitter,

  connections: state.connections.connections,
  categories: state.footer.categories || [],
  movieCategories: state.footer.movieCategories || [],
  menuCategories: state.footer.menuCategories || [],
  subCatMenuListing: state.footer.subCatMenuListing || [],
  productCategories: state.footer.productCategories || [],
  professionalCategories: state.footer.professionalCategories || [],
  businessCategories: state.footer.businessCategories || [],
  crpCategories: state.footer.crpCategories || [],
  tab_list_data: state.homeTabs.tab_list_data,
  tab_scroll_data: state.homeTabs.tab_scroll_data,
  feeds: state.feeds.feeds_data,
  my_posts_list: state.user.my_posts_list,
  my_profile: state.user.my_profile,
  auth_phone_number: state.user.auth_phone_number,
  fetch_photos: state.myProfile.fetch_photos,
  fetch_videos: state.myProfile.fetch_videos,
  fetch_taxonomy: state.myProfile.fetch_taxonomy,
  upload_scroll: state.myProfile.upload_scroll,
  upload_media_data: state.myProfile.upload_media_data,
  fetch_deleted_data: state.myProfile.fetch_deleted_data,
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  get_juror_data: state.feeds.get_juror_data,
  project_taxonomies: state.communityReviewProjects.project_taxonomies,

  get_dispute_modal_status: state.dispute.get_dispute_modal_status,
  get_blocked_user_status: state.footer.get_blocked_user_status
});

const mapProps = (dispatch) => ({
  fetch_connections: () => dispatch(fetch_connections()),
  updateTiwitter: (status) => dispatch(updateTiwitter(status)),
  updateFacebook: (status) => dispatch(updateFacebook(status)),
  getAllCategories: (queryParams) =>
    dispatch(initFetchCategories(queryParams, allCategories)),
  getAllMovieCategories: (queryParams) =>
    dispatch(initFetchCategories(queryParams, allMovieCategories)),
  getAllCaptionCategories: (queryParams) =>
    dispatch(initFetchCategories(queryParams, allCaptionCategories)),
  addMovieListing: (params) => dispatch(addMovieListing(params)),
  fetchData: (queryParams) => dispatch(fetchData(queryParams)),
  get_tab_list: (name) => dispatch(get_tab_list(name)),
  get_tab_list_scroll: (type, pageNo) =>
    dispatch(get_tab_list_scroll(type, pageNo)),
  get_my_posts_list: (pageNo) => dispatch(get_my_posts_list(pageNo)),
  get_feeds: async (params) => get_feeds(params, dispatch),
  fetch_media_data: (type, album, page, user_profile) =>
    dispatch(fetch_media_data(type, album, page, user_profile)),
  fetch_taxonomy_category: () => dispatch(fetch_taxonomy_category()),
  media_upload_scroll: ({ type, album, page, force_update }) =>
    dispatch(media_upload_scroll({ type, album, page, force_update })),
  fetch_delete_media: ({ type, page }) =>
    dispatch(fetch_delete_media({ type, page })),
  upload_media_files: (type, data, index) =>
    dispatch(upload_media(type, data, index)),
  upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
  clear_media_dispatch: () => dispatch(clear_media_dispatch()),
  delete_mutiple_media: ({ type, data }) =>
    dispatch(delete_mutiple_media({ type, data })),
  single_delete_from_upload: (id) => dispatch(single_delete_from_upload(id)),
  embedNewLink: ({ data }) => dispatch(embedNewLink({ data })),
  check_is_juror: () => dispatch(check_is_juror()),
  cast_jury_vote: (params, id) => dispatch(cast_jury_vote(params, id)),
  // check_jury_duty: (params) => dispatch(check_jury_duty(params)),
  // get_review_details: (params) => dispatch(get_review_details(params)),
  // get_dispute_discussion: (id) => dispatch(get_dispute_discussion(id)),
  updatePhoneNumber: (data) => dispatch(updatePhoneNumber(data)),
  dispute_juror_notification: (data) =>
    dispatch(dispute_juror_notification(data)),
  getAllProductCategories: (queryParams) =>
    dispatch(initFetchProductCategories(queryParams, allProductCategories)),
  getAllProfessionalsCategories: (queryParams) =>
    dispatch(
      initFetchProfessionalsCategories(queryParams, allProfessionalsCategories)
    ),
  addProductListing: (params) => dispatch(addProductListing(params)),
  getAllBusinessCategories: (queryParams) =>
    dispatch(initFetchBusinessCategories(queryParams, allBusinessCategories)),
  addBusinessListing: (params) => dispatch(addBusinessListing(params)),
  addprofessionalsListing: (params) =>
    dispatch(addprofessionalsListing(params)),
  getAllMenuCategories: (queryParams) =>
    dispatch(initFetchMenuCategories(queryParams, allMenuCategories)),
  getAllMenuSubCategories: (queryParams) =>
    dispatch(initFetchMenuSubCategories(queryParams, allSubMenuCategories)),
  getAllCrpCategories: (queryParams) =>
    dispatch(initFetchRateCrpCategories(queryParams, allCrpCategories)),
  get_project_taxonomies: () => dispatch(get_project_taxonomies()),
  get_patrol_crp: (categoryId, type, listType) =>
    dispatch(get_patrol_crp(categoryId, type, listType)),
  get_improve_listing: (categoryId, type, listType) =>
    dispatch(get_improve_listing(categoryId, type, listType)),
  get_vote_listing: (categoryId, type, listType) =>
    dispatch(get_vote_listing(categoryId, type, listType)),
  get_flag_listing: (categoryId, type, listType) =>
    dispatch(get_flag_listing(categoryId, type, listType)),
  getUserStatus: () =>
    dispatch(getUserStatus()),
  unblock_my_account: (data) => dispatch(unblock_my_account(data)),
});

export default connect(mapState, mapProps)(Home);
