import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_BY_ID = "GET_MESSAGES_BY_ID";
export const REMOVE_FORCE_MESSAGE = "REMOVE_FORCE_MESSAGE";
export const SET_LOADER = "SET_LOADER";

export const remove_force_messages = () => ({ type: REMOVE_FORCE_MESSAGE, payload: true });

export const set_messages_loader = (data) => ({ type: SET_LOADER, payload: data });
export const fetch_all_messages_ = (data) => ({ type: GET_MESSAGES, payload: data });
export const fetch_all_messages = ({ type, force }) => {
    if (type) {
        return async (dispatch) => {
            dispatch(set_messages_loader(true));
            callApi(`/api/messages/?${type}`, 'GET')
                .then((response) => {
                    if (response && response.code !== 400 && response.code !== 500) {


                        response['type'] = type;
                        if (force) response['force'] = true;
                        //dispatch(fetch_messages_by_id(response.results[0].id,type);
                        dispatch(set_messages_loader(false));
                        return dispatch(fetch_all_messages_(response));

                    }
                })
        }
    }
}

export const fetch_messages_by_id_ = (data) => ({ type: GET_MESSAGES_BY_ID, payload: data });
export const fetch_messages_by_id = ({ id, type }) => {
    return (dispatch) => {
        callApi(`/api/messages/${id}/?${type}`, 'GET')
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    response['item-type'] = type;
                    response['item-id'] = id;
                    return dispatch(fetch_messages_by_id_(response));
                }
            })
    }
}

export const restore_from_trash = ({ id, data, type }) => {
    return (dispatch) => {
        callApi(`/api/messages/${id}/?`, 'PATCH', data)
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    toast(`Message restored successfully.`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                    dispatch(fetch_all_messages({ type, force: true }))
                }
            })
    }
}

export const biz_msg_create = (data) => {
    return (dispatch) => {
        callApi(`/api/messages/?biz_msg`, 'POST', data)
            .then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                
                    if (data['is_draft']){
                        toast(`Message Saved as Draft successfully.`, {
                            autoClose: 2500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        });
                        dispatch(fetch_all_messages({ type: 'draft', force: true }));
                    } else {
                        toast(`Message sent successfully.`, {
                            autoClose: 2500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        });
                        dispatch(fetch_all_messages({ type: 'inbox', force: true }));
                    }
                }
            })
    }
}

export const delete_conversation = ({ id, delete_thread,modalType, type }) => {
    let url = `/api/messages/${id}/?%20&conversation=true&delete_thread=${delete_thread}`;
    if (type === "trash") {
        url = `/api/messages/${id}/?%20&conversation=true&delete_trash=true`;
    }

    if(modalType === 'delete-conversation'){
        return (dispatch) => {
            callApi(url, 'DELETE')
                .then((response) => {
                    if (response && response.code !== 400 && response.code !== 500) {
                        toast(`Message thread deleted successfully.`, {
                            autoClose: 2500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        });
                        dispatch(fetch_all_messages({ type, force: true }));
                    }
                })
        }
    }
    else{
        return (dispatch) => {
            callApi(url, 'DELETE')
                .then((response) => {
                    if (response && response.code !== 400 && response.code !== 500) {
                        toast(`Message deleted successfully.`, {
                            autoClose: 2500,
                            className: "black-background",
                            bodyClassName: "red-hunt",
                            progressClassName: "cc",
                        });
                        dispatch(fetch_all_messages({ type, force: true }));
                    }
                })
        }
    }
}

export const replyOnMessages = ({ data, id, type }) => {
    return async(dispatch) => {
        callApi(`/api/messages/${id}/?`, 'PUT', data)
            .then(response => {
                if (response && response.code !== 400 && response.code !== 500) {
                     dispatch(fetch_messages_by_id({ id, type }));
                }
            });
    }
}


export const embedNewLink = ({ data }) => {
    return (dispatch) => {
        callApi(`/api/feeds/?entity=`, 'POST', data)
            .then(response => {
                if (response && response.code !== 400 && response.code !== 500) {
                    toast(`Link Embedded.`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                }
            })
    }
}

// api/feeds/?entity=


// Request URL: https://wikireviews.com/api/messages/15541/?
// Request Method: PUT
// {"body":"Here","messagemedia_set":["1788c9b4066bcdd5b1e0fb6d687c3573f161fa4d"]}


// 

// /api/messages/15508/?

// Request URL: https://wikireviews.com/api/messages/15080/?%20&conversation=true&delete_thread=true
// Request Method: DELETE

// /messages/15495/?inbox
// /messages/?inbox


// Request URL: https://wikireviews.com/api/messages/?biz_msg
// Request Method: POST
// {"recipients":{"1492":true},"body":"Test","messagemedia_set":[],"reply_draft":false}
// {"recipients":{"1492":true,"1624":true,"2334":true},"body":"Test Message","messagemedia_set":[],"reply_draft":false}


// {"recipients":{"1492":true,"1624":true,"2334":true},"body":"Draft Test!","messagemedia_set":[],"reply_draft":false,"is_draft":true} // draft

// Request URL: https://wikireviews.com/api/messages/15498/?%20&conversation=true&delete_thread=false
// Request Method: DELETE

