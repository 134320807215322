import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ReviewTab from "../../atoms/reviewTab";
import DiscussionsTab from './discussionsTab';
import QandATab from './QandATab';
import ProbAndSol from './probAndSol';
import Wall from './wall';
import { withRouter } from 'react-router';
import { get_product_details_wall_data, product_details_wall_data_loading } from '../../../actions/products';
import { connect } from 'react-redux';
import { callApi } from '../../../utils/apiCaller';

class DetailReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainViewType: 'reviews',
            uploadedFiles: [],
            uploadedFilesFollowUp: [],
            embedMediaFollowUp:[],
            discussions:[],
            discussionLoading:true
        }
    }

    componentDidUpdate() {
        let nextProps = this.props;
        let next_uploaded_files = nextProps.uploadedMediaSet?.uploadedFiles.length;
        let next_uploaded_files_follow = nextProps.uploadedMediaSetFollow?.uploadedFiles.length;
        const { uploadedFiles,uploadedFilesFollowUp } = this.state;

        if (next_uploaded_files !== uploadedFiles.length) {
            const { uploadedFiles } = this.props.uploadedMediaSet;
            this.setState({ uploadedFiles: [...uploadedFiles] });
        }
        if (next_uploaded_files_follow !== uploadedFilesFollowUp.length) {
            const { uploadedFiles } = this.props.uploadedMediaSetFollow;
            this.setState({ uploadedFilesFollowUp: [...uploadedFiles] });
        }
        if (next_uploaded_files_follow !== uploadedFilesFollowUp.length) {
            const { embedLinks } = this.props.uploadedMediaSetFollow;
            this.setState({ embedMediaFollowUp: [...embedLinks] });
        }
		
		/*if(nextProps.notifiTab != this.props.notifiTab){
			this.setState({ mainViewType: this.props.notifiTab });
		}*/
    }
	
	componentWillMount() {
		let props = this.props;
        let mainViewTyp = props?.history?.location?.state?.notif_tab;
		if(mainViewTyp && mainViewTyp != ""){
		this.setState({mainViewType:mainViewTyp});
		}
    }

    navLinkItem = ({ type, title }) => {
        const { mainViewType } = this.state;
        return (
            <NavItem >
                <NavLink
                    className="ff-base"
                    active={mainViewType === type}
                    onClick={() => {
                        this.props.resetUploadedMediaSet();
                        this.setState({ mainViewType: type })
                        if(type==="wall"){
                            this.props.get_wall_data({ id: this.props.item_detail?.id, filter_type: "all", page: 1, force: true })
                            this.props.product_details_wall_data_loading(true)
                        }else if(type==="discussion"){
                            if(!!this.props.item_detail?.id){
                                callApi(`/api/discussion/?entity=${this.props.item_detail?.id}&page=1`, 'GET')
                                .then(res => {
                                    this.setState({ discussions: res, productId: this.props.item_detail?.id,discussionLoading:false });
                                });
                            }
                        }
                        
                    }}
                >
                    {title}
                </NavLink>
            </NavItem>
        )
    }

    render() {
        const { reviews, listing_type, item_detail, toggleConfirmModal, toggleEnableFileUpload, overallRatingVote, viewEditReviewStep, onPublishReview, uniqueDeleteKey, uniqueDeleteCommentKey } = this.props;
        let { mainViewType, uploadedFiles ,uploadedFilesFollowUp,embedMediaFollowUp} = this.state;

        return (
            <div>
                <Nav tabs className="text-center align-items-end active-transparent">
                    {this.navLinkItem({ type: "reviews", title: 'Reviews' })}
                    {this.navLinkItem({ type: "wall", title: 'Wall' })}
                    {this.navLinkItem({ type: "qa", title: 'Q&A' })}
                    {this.navLinkItem({ type: "problems", title: 'Problems & Solutions' })}
                    {this.navLinkItem({ type: "discussion", title: 'Discussion' })}
                </Nav>
                <TabContent activeTab={mainViewType}>
                    <TabPane tabId="reviews">
                        <ReviewTab
                            uniqueDeleteCommentKey={uniqueDeleteCommentKey}
                            uniqueDeleteKey={uniqueDeleteKey}
                            reviews={reviews}
                            item_detail={item_detail}
                            toggleConfirmModal={toggleConfirmModal}                            
                            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload,newReviewStatus,followUpStatus)=>{
                                toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus,followUpStatus);                               
                            }}
                            listing_type={listing_type}
                            overallRatingVote={overallRatingVote}
                            viewEditReviewStep={viewEditReviewStep}
                            mainViewType={mainViewType}
                            onPublishReview={onPublishReview}
                            removeUploadedMediaSet={this.props.removeUploadedMediaSet}
                            removeUploadedMediaSetFollow={this.props.removeUploadedMediaSetFollow}
                            uploadedMediaSet={uploadedFiles}
                            uploadedMediaSetFollow={uploadedFilesFollowUp}
                            embedMediaFollowUp={embedMediaFollowUp}
                            forceToggleLoginModal={this.props.forceToggleLoginModal}
                            categoryId={this.props.item_detail?.id}
                        />
                    </TabPane>
                    <TabPane tabId="qa">
                        <QandATab
                            productId={this.props.item_detail?.id}
                            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload,newReviewStatus,followUpStatus)=>{
                                toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus,followUpStatus);                               
                            }}
                            uploadedMediaSet={uploadedFiles}
                            getuploadUrl={this.props.getuploadUrl}
                            resetUploadedMediaSet={this.props.resetUploadedMediaSet}
                            forceToggleLoginModal={this.props.forceToggleLoginModal}
                            getUploadedMedia={this.props.getUploadedMedia}
                        />
                    </TabPane>
                    <TabPane tabId="problems">
                        <ProbAndSol
                            productId={this.props.item_detail?.id}
                            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload,newReviewStatus,followUpStatus)=>{
                                toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus,followUpStatus);                               
                            }}
                            uploadedMediaSet={uploadedFiles}
                            getuploadUrl={this.props.getuploadUrl}
                            resetUploadedMediaSet={this.props.resetUploadedMediaSet}
                            toggleConfirmModal={toggleConfirmModal}
                            forceToggleLoginModal={this.props.forceToggleLoginModal}
                    />
                    </TabPane>
                    <TabPane tabId="discussion">
                        <DiscussionsTab productDetails={this.props.item_detail}
                            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload, newReviewStatus, followUpStatus) => {
                                toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus, followUpStatus);
                            }}
                            uploadedMediaSet={uploadedFiles}
                            getuploadUrl={this.props.getuploadUrl}
                            resetUploadedMediaSet={this.props.resetUploadedMediaSet}
                            discussTab={this.props.discussTab}
                            getnocategory={this.props.getnocategory}
                            forceToggleLoginModal={this.props.forceToggleLoginModal}
                            discussionsData ={this.state.discussions}
                            discussionLoading={this.state.discussionLoading}
                        />
                    </TabPane>
                    <TabPane tabId="wall">
                        <Wall
                            id={this.props.item_detail?.id}
                            categoryType="products"
                            get_wall_data={this.props.getWallData}
                            handleOnSubmitShareByEmail={this.props.handleOnSubmitShareByEmail}
                            my_profile={this.props.my_profile}
                        />
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

const mapState = (state) => ({
    wallData: state.shared.wallData,
    get_reveiewData: state.user.get_reveiewData,
});

const mapProps = (dispatch) => ({
    get_wall_data: (data) => dispatch(get_product_details_wall_data(data)),
    product_details_wall_data_loading: (data)=>dispatch(product_details_wall_data_loading(data))
});

export default withRouter(connect(mapState, mapProps)(DetailReviews));

