import React, { Component } from 'react';
import { connect } from "react-redux";
import { Nav, NavItem, NavLink,
        TabContent, TabPane, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Button
} from 'reactstrap';
import { fetch_user_feeds } from '../../actions/listingFeeds';
import { share_by_email, add_to_list, remove_from_list } from "../../actions/business";
import {Link} from 'react-router-dom'
import UserFeedCard from './userFeedCard';
import ShareByEmail from './shared/shareByEmail';
import ModalComponent from './modalComponent';

class UserFeedsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            feeds:[],
            myConnectionActivity: 'all',
            category:'',
            subCategory:'',
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: 'Log in or sign up for WikiReviews to view and rate my Community Review Project!',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
            loading: true,
            fetchingUpdates: false            
        }
    }

    componentDidMount() {
        this.setState({category: this.props.category}, () => {
            if ( this.props && this.props.sub_category ) {
                this.setState({subCategory: this.props.sub_category},() => {
                    this.fetchUserFeedsData();
                });
                //this.props.fetch_user_feeds({ category: this.props.category, subCat: this.props.sub_category, searchTerm: 'all', page: 1 }); 
            }
            else {
                //this.props.fetch_user_feeds({ category: this.props.category, searchTerm: 'all', page: 1 });
                this.fetchUserFeedsData(); 
            }
        }); 
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps?.user_feeds) {
            this.setState({feeds: nextProps.user_feeds.results, loading: false, fetchingUpdates: false });
        }

        if (nextProps?.category && this.state.category !== nextProps?.category) {
            this.setState({
                category: nextProps?.category,
                feeds: [],
                loading: true,
                fetchingUpdates: true
            }, () => {
                this.fetchUserFeedsData();
            });

        }

        if (nextProps?.sub_category && nextProps?.sub_category != this.state.subCategory) {
            this.setState({
                subCategory: nextProps?.sub_category,
                feeds: [],
                loading: true,
                fetchingUpdates: true
            }, () => {
                this.fetchUserFeedsData();
            });
        } 
    }

    handleChangeFeedsTab = (tab) => {
        let { category, subCategory } = this.state
        this.setState({feeds:[], loading: true});
        const professionalsArray = ['dentists','doctors','lawyers','accountants','actors'];
        if(professionalsArray.includes(category)){
            subCategory = category;
            category = 'professionals';
        }else if(category === 'professionals'){
            if(this.props.category){
                subCategory = this.props.category;
            }
        }
        if ( tab === 'all' ) {
            if(subCategory==="professionals"){
                this.props.fetch_user_feeds({ category: category, searchTerm: 'all', page: 1 });
            }else{
                this.props.fetch_user_feeds({ category: category, subCat: subCategory, searchTerm: 'all', page: 1 });
            }
           // this.props.fetch_user_feeds({ category: category, searchTerm: 'all', page: 1 });  
        }
        if ( tab === 'reviews' ) {
           // this.props.fetch_user_feeds({ category: category, searchTerm: 'reviews', page: 1 });  
           this.props.fetch_user_feeds({ category: category, subCat: subCategory, searchTerm: 'reviews', page: 1 });  
        }
        if ( tab === 'comments' ) {
            this.props.fetch_user_feeds({ category: category, subCat: subCategory, searchTerm: 'comments', page: 1 });  
            //this.props.fetch_user_feeds({ category: category, searchTerm: 'comments', page: 1 });  
        }
        if ( tab === 'other') {
            this.props.fetch_user_feeds({ category: category, subCat: subCategory, searchTerm: 'other', page: 1 });  
           // this.props.fetch_user_feeds({ category: category, searchTerm: 'other', page: 1 });  
        }
    }

    getShareTitle = (name) => {
        const title = name ? `Checkout ${name} on ` : '';
        return title;
    }

    handleOnChangeShareByEmail = (e) => {
        let errors = { ...this.state.shareError };
        if (e.target.value) {
            delete errors[e.target.name];
        }

        this.setState({
            [e.target.name]: e.target.value,
            shareError: errors
        });
    }

    handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote }) => {
        const key = 'listing_id';
        let url
        const professionalCategories = ['accountants', 'doctors', 'dentists', 'lawyers', 'actors', 'professionals','accountant', 'doctor', 'dentist', 'lawyer', 'actor', 'professional']
        if (professionalCategories.includes(this.props.category?.toLowerCase())) {
            url = `/api/professionalShareByemail/`
          } else {
            url = `/api/shareByemail/`;
          }
        let review_id = this.state.shareId;
        let data;
        if (review_id) {
          data = {
            review_id: review_id,
            mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        } else {
          data = {
            [key]: this.state.shareId,
            mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        }
    
        this.props.share_by_email(url, data);
      }
    handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id
        });
    }

    addToList = async (type, id, name, status) => {
        this.setState({fetchingUpdates: true})
        let feed = this.state.feeds.filter(item => item.target?.userentry == id)[0];
        const mainCategoryArray = ['accountants', 'doctors', 'dentists', 'lawyers', 'actors', 'professionals','accountant', 'doctor', 'dentist', 'lawyer', 'actor', 'professional']

        let urlPin = 'professionalpins';
        if(!mainCategoryArray.includes(feed.target?.category?.toLowerCase())){
            urlPin = 'pins';
        }
        let message = '';
        let url = '';
        let { category, subCategory } = this.getCategoryAndSubcategory();

        let nextUrl = `/api/userfeeds/?category=${category}&subcat=${subCategory}&searchTerm=${this.state.myConnectionActivity}&page=1`;
        if (status) {
            message = `${name} has been removed from your ${type}!`;
            // if (this.state.myConnectionActivity === 'all' || this.state.myConnectionActivity === 'other') {
            //     url = `/api/${urlPin}/${id}/?type=${type}`;
            // } else if (this.state.myConnectionActivity === 'reviews' || this.state.myConnectionActivity === 'comments') {
            //     let tab = this.state.myConnectionActivity;
            //     url = `/api/${urlPin}/${id}/?type=${type}&${tab.slice(0, -1)}=true`;
            // }
            url = `/api/${urlPin}/${id}/?type=${type}`;
            await this.props.remove_from_list(url, message, nextUrl);
            this.fetchUserFeedsData();            
        } else {
            url = `/api/${urlPin}/?type=${type}&user=`;
            let data = { type };
            // if (this.state.myConnectionActivity === 'all' || this.state.myConnectionActivity === 'other') {
            //     if(!mainCategoryArray.includes(feed.target?.category?.toLowerCase())){
            //         data.user_entry = id;
            //     }else{
            //         data.professionaluser_entry = id;
            //     }
            // } else if (this.state.myConnectionActivity === 'reviews' || this.state.myConnectionActivity === 'comments') {
            //     data.review = id;
            // }
            if(!mainCategoryArray.includes(feed.target?.category?.toLowerCase())){
                data.user_entry = id;
            }else{
                data.professionaluser_entry = id;
            }

            message = `${name} has been added to your ${type}`;
            await this.props.add_to_list(url, data, message, nextUrl);
            this.fetchUserFeedsData();
        }
    }

    getCategoryAndSubcategory = () =>{
        let {category, subCategory} = this.state;
        const professionalsArray = ['dentists','doctors','lawyers','accountants','actors'];
        if(professionalsArray.includes(category)){
            subCategory = category;
            category = 'professionals';
        }else if(category === 'professionals'){
            if(this.props.category){
                subCategory = this.props.category;
            }
        }

        return {category, subCategory};
    }

    fetchUserFeedsData = () => {
        let { category, subCategory } = this.getCategoryAndSubcategory();
        if(subCategory==="professionals"){
            this.props.fetch_user_feeds({ category: category, searchTerm: this.state.myConnectionActivity, page: 1 });
        }else{
            this.props.fetch_user_feeds({ category: category, subCat: subCategory, searchTerm: this.state.myConnectionActivity, page: 1 });
        }
        
    }

    render() {
        const { feeds, } = this.state
        return (
            <div>
                <h3 className="con-act-heading fs-36 mb-3 text-center">My Connection's Activity</h3>
                <Nav className="con-act-tabs active-transparent non-active-normal active-bold" tabs>
                    <NavItem>
                        <NavLink
                            className="fs-16 ff-base"
                            active={this.state.myConnectionActivity === "all"}
                            onClick={() => {
                                this.setState({ myConnectionActivity: "all" });
                                this.handleChangeFeedsTab('all')
                            }}
                        >
                            All
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className="fs-16 ff-base"
                            active={this.state.myConnectionActivity === "reviews"}
                            onClick={() => {
                                this.setState({ myConnectionActivity: "reviews" });
                                this.handleChangeFeedsTab('reviews')
                            }}
                        >
                            Reviews
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className="fs-16 ff-base"
                            active={this.state.myConnectionActivity === "comments"}
                            onClick={() => {
                                this.setState({ myConnectionActivity: "comments" });
                                this.handleChangeFeedsTab('comments')
                            }}
                        >
                            Comments
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className="fs-16 ff-base"
                            active={this.state.myConnectionActivity === "other"}
                            onClick={() => {
                                this.setState({ myConnectionActivity: "other" });
                                this.handleChangeFeedsTab('other')
                            }}
                        >
                            Other
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.myConnectionActivity}>
                    <TabPane tabId="all">
                        { feeds?.length > 0 ? 
                            ''
                        : 
                            <div className="font-weight-bold fs-16 text-dark" >
                                {this.state.loading ? "Fetching Data..." : "Result not found."}
                            </div>
                        }
                        <div>
                            {/* Repeat This */}
                            { feeds?.map((feed, index) => (
                                <UserFeedCard
                                    key={index}
                                    feed={feed}
                                    protocol={this.state.protocol}
                                    shareTitle={this.state.shareTitle}
                                    shareVia={this.state.shareVia}
                                    fbHashTag={this.state.fbHashTag}
                                    twitterHashTags={this.state.twitterHashTags}
                                    handleOnClickShareByEmail={this.handleOnClickShareByEmail}
                                    addToList={this.addToList}
                                    fetchingUpdates={this.state.fetchingUpdates}
                                    category={this.props.category}
                                />
                            ))
                            }
                           </div>
                    </TabPane>
                    <TabPane tabId="reviews">
                        { feeds?.length > 0 ? 
                            ''
                        :
                            <div className="font-weight-bold fs-16 text-dark" >
                                {this.state.loading ? "Fetching Data..." : "Result not found."}
                            </div>
                        }
                        <div>
                            {/* Repeat This */}
                            { feeds?.map((feed, index) => (
                                <UserFeedCard
                                    key={index}
                                    feed={feed}
                                    protocol={this.state.protocol}
                                    shareTitle={this.state.shareTitle}
                                    shareVia={this.state.shareVia}
                                    fbHashTag={this.state.fbHashTag}
                                    twitterHashTags={this.state.twitterHashTags}
                                    handleOnClickShareByEmail={this.handleOnClickShareByEmail}
                                    addToList={this.addToList}
                                    fetchingUpdates={this.state.fetchingUpdates}
                                    category={this.props.category}
                                />
                            ))
                            }
                        </div>
                    </TabPane>
                    <TabPane tabId="comments">
                        { feeds?.length > 0 ? 
                            ''
                        :
                            <div className="font-weight-bold fs-16 text-dark" >
                                {this.state.loading ? "Fetching Data..." : "Result not found."}
                            </div>
                        }
                       <div>
                            {/* Repeat This */}
                            { feeds?.map((feed, index) => (
                                <UserFeedCard
                                    key={index}
                                    feed={feed}
                                    protocol={this.state.protocol}
                                    shareTitle={this.state.shareTitle}
                                    shareVia={this.state.shareVia}
                                    fbHashTag={this.state.fbHashTag}
                                    twitterHashTags={this.state.twitterHashTags}
                                    handleOnClickShareByEmail={this.handleOnClickShareByEmail}
                                    addToList={this.addToList}
                                    fetchingUpdates={this.state.fetchingUpdates}
                                    category={this.props.category}
                                />
                            ))
                            }
                        </div>
                    </TabPane>
                    <TabPane tabId="other">
                        { feeds?.length > 0 ? 
                            ''
                        :
                            <div className="font-weight-bold fs-16 text-dark" >
                                {this.state.loading ? "Fetching Data..." : "Result not found."}
                            </div>
                        }
                        <div>
                            {/* Repeat This */}
                            {feeds?.map((feed, index) => (
                                <UserFeedCard
                                    key={index}
                                    feed={feed}
                                    protocol={this.state.protocol}
                                    shareTitle={this.state.shareTitle}
                                    shareVia={this.state.shareVia}
                                    fbHashTag={this.state.fbHashTag}
                                    twitterHashTags={this.state.twitterHashTags}
                                    handleOnClickShareByEmail={this.handleOnClickShareByEmail}
                                    addToList={this.addToList}
                                    fetchingUpdates={this.state.fetchingUpdates}
                                    category={this.props.category}
                                />
                            ))
                            }
                        </div>
                    </TabPane>
                </TabContent>
                <div className="mb-3"></div>
                <div className="bg-white text-center">
                    <a rel="noopener noreferrer"
                      href="http://wikireviews.com/blog"
                      target="_blank"
                      className="text-reset">
                        <img className="img-fluid blog-img" src="https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_02.png" alt="Go to WikiReviews Herald" />
                    </a>
                </div>
                {/* Share By Email Modal Popup */}
               
                <ModalComponent
                    size="sm"
                    isScrollable={true}
                    isOpen={this.state.shareByEmailModalToggle}
                    toggleHandler={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })}
                    header={"Share by Email"}
                    headerClass="text-uppercase px-0"
                    body={<ShareByEmail handleOnSubmitShareByEmail={(value) => this.handleOnSubmitShareByEmail(value)} modalToggler={() => {
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })
                    }} />}
                /> 
            </div>
        )
    }
}

const mapState = (state) => ({    
    user_feeds: state.feeds_listing.user_feeds,
});

const mapProps = (dispatch) => ({
    // fetch_newtrends: (data) => dispatch(fetch_newtrends(data)),
    fetch_user_feeds: (data) => dispatch(fetch_user_feeds(data)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
});

export default connect(mapState, mapProps)(UserFeedsPanel);
