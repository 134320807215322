const initialState = {
    fetch_movies: null,
    fetch_movie_category: null,
    fetch_main_category_subcategory: null,
    get_user_feeds: null,
    fetch_movies_taxonomy: null,
    fetch_movies_boxoffice: null,
    fetch_movies_search: null,
    get_search_movies: null,
    get_movies_listing: null,
    get_movie_detail: null,
    get_review_listing: null,
    get_dispute_data: null,
    movie_details: null,
    back_to_listing: null,
    fetch_theater_data: null,
    advisory_list: null,
    movies_category_collage_list: null,
	fetch_save_movie_response: null,
};

const movies = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case "FETCH_MOVIES":
            state.fetch_movies = action.payload;
            return { ...state };

        case "GET_BUSINESS_DETAILS_BY_ID":
            state.movie_details = action.payload;
            return { ...state };

        case "GET_BACK_TO_BUSINESS":
            state.back_to_listing = action.payload;
            return { ...state };

        case "FETCH_MOVIE_CATEGORY":
            state.fetch_movie_category = action.payload;
            return { ...state };

        case "FETCH_MAIN_CATEGORY_SUBCATEGORY":
            state.fetch_main_category_subcategory = action.payload;
            return { ...state };

        case "FETCH_MOVIES_TAXONOMY":
            state.fetch_movies_taxonomy = action.payload;
            return { ...state };

        case "FETCH_MOVIES_BOXOFFICE":
            state.fetch_movies_boxoffice = action.payload;
            return { ...state };

        case "FETCH_MOVIES_SEARCH":
            state.fetch_movies_search = action.payload;
            return { ...state };

        case "GET_SEARCH_BOXOFFICE":
            state.get_search_movies = action.payload;
            return { ...state };

        case "GET_MOVIES_LISTING":
            state.get_movies_listing = action.payload;
            return { ...state };

        case "GET_MOVIE_DETAIL":
            state.get_movie_detail = action.payload;
            return { ...state };

        case "GET_REVIEW_LISTING":
            state.get_review_listing = action.payload;
            return { ...state };

        case "GET_DISPUTE_DATA":
            state.get_dispute_data = action.payload;
            return { ...state };

        case "GET_THEATER_DATA":
            state.fetch_theater_data = action.payload;
            return { ...state };

        case "GET_ADVISORY_LISTING":
            state.advisory_list = action.payload;
            return { ...state };

        case "MOVIE_CATEGORY_COLLAGE_LIST":
            state.movies_category_collage_list = action.payload;
            return { ...state };
			
	    case "GET_SAVE_MOVIE_RESPONSE":
            state.fetch_save_movie_response = action.payload;
            return { ...state };	

		default:
            return state;
    }
};

export default movies;
