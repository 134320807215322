import { combineReducers } from "redux";
import actor from "./actors";
import auth from "./auth";
import communityReviewProjects from "./communityReviewProjects";
import connections from "./connections";
import contributions from "./contributions";
import favorite from "./favoriteReducer";
import footerReducer from "./footer";
import home from "./home";
import messages from "./messages";
import myProfile from "./myProfile";
import projects from "./projects";
import reviews from "./reviews";
import user from "./user";
import utils from "./utils";
import workeducation from "./workeducationReducer";
import picks from "./yourPicks";
import feeds from "./feeds";
import posts from "./myPosts";
import movies from "./movies";
import products from "./products";
import movies_listing from "./moviesListing";
import business from "./business";
import homeTabs from "./homeTabs";
import discussions from "./discussionsTab";
import professionals from "./professionals";
import item_detail from './itemDetail';
import feeds_listing from './listingFeeds';
import products_listing from "./productsListing";
import buyersGuide from "./buyersGuide";
import accountant from './accountant'
import dentist from './dentist'
import doctor from './doctor'
import lawyer from './lawyer'
import shared from './shared'
import dispute from './dispute'
import reviewMedia from './reviewMedia'

export default combineReducers({
  accountant,
  dentist,
  doctor,
  lawyer,
  auth,
  picks,
  messages,
  actor,
  user,
  utils,
  myProfile,
  workeducation,
  favorite,
  reviews,
  home,
  movies_listing,
  projects,
  connections,
  contributions,
  communityReviewProjects,
  feeds,
  footer: footerReducer,
  movies,
  business,
  homeTabs,
  discussions,
  posts,
  professionals,
  item_detail,
  feeds_listing,
  products_listing,
  products,
  dispute,
  buyersGuide,
  shared,
  reviewMedia
});
