/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Container, Button } from 'reactstrap';
import { withRouter } from "react-router";
import AppHeader from '../oragnisms/dashboard/appHeader';
import { fetch_notification_data } from "../../actions/home";

class AllNotifications extends Component {
	constructor(props) {
        super(props)
        this.state = {
           fetchNotification:[],
           checkNextPagination: null,
           currentPageNumber: 1,
           loading: true
        }
        this.enableScrollTillEnd = true
    }
	
	componentWillReceiveProps(nextProps) {
      if (nextProps.get_notification && nextProps.get_notification.results && Array.isArray(nextProps.get_notification.results) && nextProps.get_notification.results.length > 0) {
        if (this.state.fetchNotification.length === 0) {
            this.setState({ fetchNotification : nextProps.get_notification?.results, checkNextPagination: nextProps.get_notification?.next, loading: false}); 		
        } else {
            this.enableScrollTillEnd = true
            this.setState({ fetchNotification : [...this.state.fetchNotification, ...nextProps.get_notification?.results] , checkNextPagination: nextProps.get_notification?.next, loading: false}); 		
        }
	  }	
    }
	
	componentDidMount() {
		let page = 1;
        this.props.fetch_notification_data(page);
		window.addEventListener('scroll', this.handleScroll, { passive: false });

    }

    handleScroll = () => {
        let scrollTillHeight = window.pageYOffset + 600;
        let lastElemHeight = document.getElementById("last-index")?.offsetTop;
        let currentPageIndex = this.state.currentPageNumber;
        let checkNextPagination = this.state.checkNextPagination;

        if (scrollTillHeight > lastElemHeight && checkNextPagination) {
            if (this.enableScrollTillEnd) {
                this.enableScrollTillEnd = false;
                currentPageIndex++;
                this.setState({ currentPageNumber: currentPageIndex, loading: true }, () => {
                    this.props.fetch_notification_data(currentPageIndex);
                });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    renderAction(itemFeed) {
        if (Object.keys(itemFeed.notification.target).length > 0 && itemFeed.notification.target.category) {
            return (
                <Link to={{ pathname: `/${itemFeed.notification.target.category}/${itemFeed.notification.target.slug && itemFeed.notification.target.slug != "" ? itemFeed.notification.target.slug : null}`, state: { id: itemFeed?.notification?.target?.userentry_id, notif_tab: itemFeed?.notif_tab, cid: itemFeed?.object?.objectid, qid: itemFeed?.object?.parentid } }}>
                    {itemFeed?.notification?.verb}
                </Link>
            )
        } else if (itemFeed?.notification?.description == "commented_on_post") {
            return (
                <Link to={{ pathname: "/myprofile", state: { mainViewType: "posts", pid: itemFeed?.object?.parentid, cmntid: itemFeed?.object?.objectid } }}>
                    commented on your post.
                </Link>
            )
        } else {
            if (itemFeed?.notification?.verb) {
                return (
                    <span className='text-dark'>
                        {itemFeed?.notification?.verb}
                    </span>
                )
            }
        }
    }
	
	
    render() {
		let {fetchNotification} = this.state;
		
        return (
            <div>
                <AppHeader isEnableCover={false} />
                <div className="pb-3" style={{ marginTop: '70px' }}>
                    <Container>
                        <h1 className="notification-heading mb-3">Your Notifications</h1>
                        <div>
						{fetchNotification && Array.isArray(fetchNotification) && fetchNotification.length > 0 ?
                           fetchNotification.map((itemFeed,indexFeed) => (
                            itemFeed?.notification?.verb && 
                                <div key={indexFeed} id={`${fetchNotification.length == indexFeed + 1 ? 'last-index' : `current-index-${indexFeed}`}`} className="bg-white fs-14 text-tertiary p-4 mt-2 position-relative" role="button">
                                    <div className="d-flex mx-n1">
                                        <div className="px-1">
                                            <a href="#">
                                                <img className="img-circle _50x50" 
                                                src={itemFeed?.notification?.actor?.current_profile_pic ? itemFeed?.notification?.actor?.current_profile_pic : require('../../assets/images/icons/user-circle.png')} 
                                                onError={(error) => { error.target.src = require('../../assets/images/icons/user-circle.png')}}
                                                alt={'no-img'} />
                                            </a>
                                        </div>
                                        <div className="px-1 align-self-center flex-fill">
                                            <Link to={{ pathname: `/people/${itemFeed?.notification?.actor.username}` }} target="_blank" className="mr-1">
                                                <span className="font-weight-bold text-dark">
                                                {itemFeed?.notification?.actor?.first_name} {itemFeed?.notification?.actor?.last_name}
                                                </span>
                                            </Link>
                                            <span>
                                                {this.renderAction(itemFeed)}
                                            </span>
                                            <span>{" "}{itemFeed?.notification?.time_stamp}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                                :
                                this.state.loading ? '' : <div className="px-3 mt-2">
                                    <div className='p-3 bg-white text-secondary h2 mt-3'>No New Notifications</div>
                                </div>
                            }

                            <div className="bg-white fs-14 text-tertiary p-3 mt-2 position-relative" role="button" hidden>
                                <div className="d-flex mx-n1">
                                    <div className="px-1">
                                        <a href="#">
                                            <img className="img-circle _50x50" src="https://userdatawikireviews.s3.amazonaws.com/media/content/eec181c6d57700abb2feb82d706759a8--chicago-cubs-logo-chicago-cubs-baseball.f190707821a37c13b9c6fa692f43426866c16dd4.jpg" alt="" />
                                        </a>
                                    </div>
                                    <div className="px-1 align-self-center flex-fill">
                                        <Link to="#">
                                            <span className="font-weight-bold text-dark">
                                                Addison B.
                                            </span>
                                        </Link>
                                        <span>{" "}sent you a friend request</span>
                                        <span>{" "}2 years ago</span>

                                        {/* Action buttons */}
                                        <div className="text-right mt-2" hidden>
                                            <Button color="primary" className="btn" size="sm">Accept</Button>
                                            <Button color="primary" className="btn" size="sm">Reject</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.loading ? <div className='p-3 bg-white text-secondary h2 mt-3'>Loading...</div> : ''
                        }
                        <div style={{ height: "400px" }}></div>
                    </Container>
                </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    get_notification: state.home.get_notification,
});

const mapProps = (dispatch) => ({
    fetch_notification_data: (page) => dispatch(fetch_notification_data(page)),
 
});
export default withRouter(connect(mapState, mapProps)(AllNotifications));
