import React, { useEffect, useState } from 'react'
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import axios from "axios";
import { API_URL } from '../../../../utils/constants';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const FoundConnections = ({ connections, authToken }) => {

    const [findFriendsType, setFindFriendsType] = useState("already")
    // const [connectionDetails, setConnectionDetails] = useState([])
    const [alreadyOnWiki, setAlreadyOnWiki] = useState([])
    const [inviteContacts, setInviteContacts] = useState([])
    const [selectAllWiki, setSelectedAllWiki] = useState(false);
    const [selectAllInvite, setSelectedAllInvite] = useState(false);
    const [requestSent, setRequestSent] = useState([]);
    const [invitationSent, setInvitationSent] = useState([]);

    useEffect(() => {
        let tempVar = []
        tempVar = connections.map(item => {
            return {
                name: item?.name || "",
                email: item?.email || "",
                photo: item?.thumbnail || "https://userdatawikireviews.s3.amazonaws.com/images/circle.png",
                isChecked: false,
                isInvited: item?.is_invited,
                onWiki: item?.on_wiki,
                id: item?.profile_id
            }
        });
        let teampAlreadyOnWiki = tempVar.filter((item) => item?.onWiki)
        let tempInviteContacts = tempVar.filter((item) => !item?.onWiki)
        setAlreadyOnWiki(teampAlreadyOnWiki)
        setInviteContacts(tempInviteContacts)
        // setConnectionDetails(tempVar);
    }, [connections])

    useEffect(() => {
        if (alreadyOnWiki.length) {
            const isSelectedAll = alreadyOnWiki.every(item => item.isChecked)
            if (isSelectedAll) setSelectedAllWiki(true)
            else setSelectedAllWiki(false)
        }
    }, [alreadyOnWiki])

    useEffect(() => {
        if (inviteContacts.length) {
            const isSelectedAll = inviteContacts.every(item => item.isChecked)
            if (isSelectedAll) setSelectedAllInvite(true)
            else setSelectedAllInvite(false)
        }
    }, [inviteContacts])

    const handleCheckboxWiki = (name, value) => {
        const tempVar = [...alreadyOnWiki]
        let mutatedObj = tempVar.filter(item => item.name === name)
        mutatedObj[0].isChecked = value
        const indexValue = tempVar.findIndex(item => item.name === name)
        tempVar.splice(indexValue, 1, mutatedObj[0])
        setAlreadyOnWiki(tempVar)
    }

    const handleCheckboxInvite = (name, value) => {
        const tempVar = [...inviteContacts]
        let mutatedObj = tempVar.filter(item => item.name === name)
        mutatedObj[0].isChecked = value
        const indexValue = tempVar.findIndex(item => item.name === name)
        tempVar.splice(indexValue, 1, mutatedObj[0])
        setInviteContacts(tempVar)
    }

    const handleSelectAllWiki = () => {
        const tempVar = [...alreadyOnWiki].map(item => {
            return {
                ...item,
                isChecked: !selectAllWiki
            }
        })
        setAlreadyOnWiki(tempVar)
        setSelectedAllWiki(!selectAllWiki)
    }

    const handleSelectAllInvite = () => {
        const tempVar = [...inviteContacts].map(item => {
            return {
                ...item,
                isChecked: !selectAllInvite
            }
        })
        setInviteContacts(tempVar)
        setSelectedAllInvite(!selectAllInvite)
    }

    const handleAddConnection = () => {
      let checkedIds = [];
      alreadyOnWiki.forEach((item) => {
        if (item.isChecked && !requestSent.includes(item.id)) {
          checkedIds.push(item.id);
        }
      });
      let data = {
        ids: checkedIds,
      };
      let headers = {};
      headers["Authorization"] = `Token ${authToken}`;
      let url = `${API_URL}/api/connectionrequestfriend/`;

      if (checkedIds.length === 0) {
        toast("Please select users", {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      } else {
        axios({
          method: "POST",
          url: url,
          data: data,
          headers: headers,
        }).then((response) => {
          if (response.results) {
            toast("Friend request sent successfully", {
              autoClose: 2500,
              className: "black-background",
              bodyClassName: "red-hunt",
              progressClassName: "cc",
            });
            setRequestSent((prev) => {
                return [...prev, ...checkedIds]
            });
          }
        });
      }
    };

    const handleInvitation = () => {
      let checkedEmails = [];
      inviteContacts.forEach((item) => {
        if (item.isChecked && !invitationSent.includes(item.email)) {
          checkedEmails.push(item.email);
        }
      });

      if (checkedEmails.length === 0) {
        toast("Please select users.", {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      } else {
        let headers = {};
        headers["Authorization"] = `Token ${authToken}`;

        let query = "";
        if (checkedEmails.length > 1) {
            query = `?email=${checkedEmails.join(",")}&multiple=true`
        } else {
            query = `?email=${checkedEmails[0]}&multiple=false`
        }

        let url = `${API_URL}/api/sendinvitation/${query}`;

        axios({
          method: "GET",
          url: url,
          headers: headers,
        }).then((response) => {
          if (response) {
            toast("Invitation sent successfully", {
              autoClose: 2500,
              className: "black-background",
              bodyClassName: "red-hunt",
              progressClassName: "cc",
            });
            setInvitationSent((prev) => {
                return [...prev, ...checkedEmails]
            });
          }
        });
      }
    };


    return (
      <Row>
        <Col md={12}>
          <Nav className="mb-3" tabs>
            <NavItem className="text-center">
              <NavLink
                href="#"
                active={findFriendsType === "already"}
                onClick={() => {
                  // this.setState({ findFriendsType: "already", searchResults: "", selectedIds: [], afterSearch: false, searchOnWiki: [], clickonsearch: false });
                  // this.handleSelectType("already");
                  setFindFriendsType("already");
                }}
              >
                <span className="ff-base fs-16 font-weight-normal">
                  {"Already on"}
                </span>{" "}
                {/* <br />{" "} */}
                <span className="font-weigh-bold fs-16">{"WikiReviews"}</span>
              </NavLink>
            </NavItem>
            <NavItem className="text-center">
              <NavLink
                href="#"
                active={findFriendsType === "invite"}
                onClick={() => {
                  // this.setState({ findFriendsType: "invite", searchResults: "", selectedIds: [], afterSearch: false, searchOnWiki: [], clickonsearch: false });
                  // this.handleSelectType("invite");
                  // this.state.selectedIds.pop();
                  setFindFriendsType("invite");
                }}
              >
                <span className="ff-base fs-16 font-weight-normal">
                  {"Invite to"}
                </span>{" "}
                {/* <br />{" "} */}
                <span className="font-weigh-bold fs-16">{"WikiReviews"}</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={findFriendsType}>
            <TabPane tabId="already">
              {requestSent.length === alreadyOnWiki.length ? (
                ""
              ) : (
                <div className="d-flex justify-content-between">
                  <p className="text-dark fs-14">
                    {"Select which contacts to add from the list below"}
                  </p>
                  <div className="text-tertiary font-weight-normal ff-alt ml-4 fs-14">
                    {"Select All/None"}
                    <input
                      className="ml-2 cursor-pointer"
                      type="checkbox"
                      name="checkAllAlready"
                      checked={selectAllWiki}
                      onChange={handleSelectAllWiki}
                    />{" "}
                  </div>
                </div>
              )}

              <Row className="connection-container pt-3">
                {alreadyOnWiki?.map((item, index) => {
                  return (
                    <Col md="6" key={index + item.name}>
                      <div className="wk-friends-search-box p-2 mb-2 d-flex align-items-center justify-content-between">
                        <div className="wk-friends-search-boxleft">
                          <img
                            src={
                              item.photo ||
                              "https://userdatawikireviews.s3.amazonaws.com/images/circle.png"
                            }
                            className="wk-friends-search-image"
                            onError={(error) =>
                              (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/circle.png`)
                            }
                            alt={`${item?.name ? item.name : "no-image"}`}
                          />
                          <span
                            // href={`/people/${itemAlready?._source?.user?.username}`}
                            className="text-dark ml-2 fs-14"
                          >
                            {item.name}
                          </span>
                        </div>
                        <div className="wk-friends-search-boxright">
                          {requestSent.includes(item.id) ? (
                            <>
                              <FontAwesomeIcon
                                icon="check"
                                className="mr-2 text-dark"
                              />
                            </>
                          ) : (
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              name={item.name}
                              checked={item.isChecked}
                              onChange={(e) =>
                                handleCheckboxWiki(item.name, e.target.checked)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })}

                {/* ))
                    ) : (
                        <Col xs={12}>
                            <div className="text-secondary-dark font-weight-bold">
                                <span hidden={filterAlreadyOnWiki.length == 0 && afterSearch == true ? false : true}>{'We do not have an account for this user.'}</span>
                                <span hidden={filterAlreadyOnWiki.length == 0 && afterSearch == false ? false : true}>{'No Results to display.'}</span>
                            </div>
                        </Col>
                    )} */}
              </Row>
              <div className="mt-2 d-flex justify-content-end">
                <Button
                  className="add-connection"
                  hidden={
                    alreadyOnWiki.length === 0 ||
                    requestSent.length === alreadyOnWiki.length
                  }
                  onClick={() => {
                    handleAddConnection();
                  }}
                >
                  Add Connections
                </Button>
              </div>
            </TabPane>

            <TabPane tabId="invite">
              {invitationSent.length === inviteContacts.length ? (
                ""
              ) : (
                <div className="d-flex">
                  <p className="text-dark fs-14">
                    {"Select which contacts to add from the list below"}
                  </p>
                  <div className="text-tertiary font-weight-normal ff-alt ml-4 fs-14">
                    {"Select All/None"}
                    <input
                      className="ml-2"
                      type="checkbox"
                      name="checkAllInvite"
                      checked={selectAllInvite}
                      onChange={handleSelectAllInvite}
                      // checked={this.state.allCheckedInvite}
                      //  onChange={this.handleCheckChieldInvite}
                    />{" "}
                  </div>
                </div>
              )}

              <Row className="connection-container pt-3">
                {inviteContacts?.map((item, index) => {
                  return (
                    <Col md="6" key={index + item.name}>
                      <div className="wk-friends-search-box p-2 mb-2 d-flex align-items-center justify-content-between">
                        <div className="wk-friends-search-boxleft">
                          <img
                            src={
                              item.photo ||
                              "https://userdatawikireviews.s3.amazonaws.com/images/circle.png"
                            }
                            className="wk-friends-search-image"
                            onError={(error) =>
                              (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/circle.png`)
                            }
                            alt={`${item?.name ? item.name : "no-image"}`}
                          />
                          <span
                            // href={`/people/${itemAlready?._source?.user?.username}`}
                            className="text-dark ml-2 fs-14"
                          >
                            {item.name}
                          </span>
                        </div>
                        <div className="wk-friends-search-boxright">
                        {invitationSent.includes(item.email) ? (
                            <>
                              <FontAwesomeIcon
                                icon="check"
                                className="mr-2 text-dark"
                              />
                            </>
                          ) : (
                            <input
                            type="checkbox"
                            className="cursor-pointer"
                            name={item.name}
                            checked={item.isChecked}
                            onChange={(e) =>
                              handleCheckboxInvite(item.name, e.target.checked)
                            }
                          />
                          )}
                          
                        </div>
                      </div>
                    </Col>
                  );
                })}

                {/* ))
                    ) : (
                        <Col xs={12}>
                            <div className="text-secondary-dark font-weight-bold">
                                <span hidden={filterAlreadyOnWiki.length == 0 && afterSearch == true ? false : true}>{'We do not have an account for this user.'}</span>
                                <span hidden={filterAlreadyOnWiki.length == 0 && afterSearch == false ? false : true}>{'No Results to display.'}</span>
                            </div>
                        </Col>
                    )} */}
              </Row>
              {/* <Row ref={this.myScrollSearchRef}
                    onScroll={this.handleScrollSearch}
                    style={{ maxHeight: "calc(100vh - 25px)", overflow: "auto" }}>
                    {filterInviteToWiki && filterInviteToWiki.length > 0 ? (
                        filterInviteToWiki.map((itemInvite, indexInvite) => (
                            <Col md="6" key={indexInvite}>

                                <div className="wk-friends-search-box p-4 mb-2 d-flex align-items-center justify-content-between">
                                    <div className="wk-friends-search-boxleft">
                                        <img
                                            src={`${itemInvite && itemInvite?.thumbnail ? itemInvite?.thumbnail : "https://userdatawikireviews.s3.amazonaws.com/images/circle.png"}`}
                                            className="wk-friends-search-image"
                                            onError={(error) =>
                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/circle.png`)
                                            }
                                            alt={`${itemInvite && itemInvite.user_name ? itemInvite?.user_name : 'no-image'}`}
                                        />
                                        <a href="#" className="text-dark ml-2 fs-14">{itemInvite?.name}</a>
                                    </div>
                                    <div className="wk-friends-search-boxright">
                                        <input type="checkbox" name={itemInvite?.email} checked={itemInvite?.isChecked} onChange={this.handleCheckChieldInvite} value={itemInvite?.email} />
                                    </div>
                                </div>
                            </Col>
                        ))
                    ) : (
                        <Col xs={12}>
                            <div className="text-secondary-dark font-weight-bold">
                                <span hidden={filterInviteToWiki.length == 0 && afterSearch == true ? false : true}>{'We do not have an account for this user.'}</span>
                                <span hidden={filterInviteToWiki.length == 0 && afterSearch == false ? false : true}>{'No Results to display.'}</span>
                            </div>
                        </Col>
                    )}
                </Row> */}
                
              <div className="mt-2 d-flex justify-content-end">
                <Button
                  color="primary"
                  hidden={inviteContacts.length === 0 ||
                    invitationSent.length === inviteContacts.length}
                  onClick={() => {
                    handleInvitation();
                  }}
                >
                  Invite Connections
                </Button>
              </div>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    );
}

export default FoundConnections;