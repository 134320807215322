import React, { useState } from 'react'
import { Button, FormGroup, Input, Media, } from 'reactstrap';
import StarsRating from 'stars-rating';
import { useDispatch, useSelector } from 'react-redux';
import { business_edit_quesiton, submit_qa_reply } from '../../../../actions/business';
import EditBtn from '../../../atoms/editBtn';
import DeleteBtn from '../../../atoms/deleteBtn';
import Reply from './reply';
import { Link } from 'react-router-dom';

const Answer = ({ question, answer, index, productId, checkIfLoggedIn, deleteAnswer, confirmDeleteModalToggle,handleSubmitRating }) => {

    const dispatch = useDispatch();

    const my_profile_data = useSelector((state) => state.user?.my_profile);
    const [editConversation, setEditConversation] = useState(false);
    const [editConversationID, setEditConversationId] = useState(null);
    const [editConversationObj, setEditConversationObj] = useState(null);
    const [toggleReply, setToggleReply] = useState({});
    const [replyText, setReplyText] = useState({});

    
    const handleEditAnswerChange = (key1, key2, evt) => {
        let input = evt.target && evt.target.value;
        editConversationObj && Object.keys(editConversationObj).map(element => {
            if (element === key1) {
                setEditConversationObj((prev) => ({
                    ...prev,
                    [key1]: input,
                    [key2]: input,
                    editpost: true
                }))
            }
        })
    }

    const handleEditAnswerSubmit = async (data, id) => {
        const updatedAnswer = { ...data, editpost: true }
        dispatch(business_edit_quesiton(
            productId,
            id,
            updatedAnswer
        ));
    }

    const handleReply = (e, index) => {

        const text = e.target.value;

        if (text !== '') {
            replyText[index] = text;
            setReplyText({ ...replyText });
        }
    }

    const submitReply = (text, type='add_reply') => {

        let body = '';
        const answer_id = answer.msg_id;

        if (text && text != '' && text != undefined && text != null) {
            body = text;
        }

        if (body != '' && productId && answer_id && type =='add_reply' ) {
            const data = { answer_id, user_entry: productId, body }
            dispatch(submit_qa_reply({ data, id: answer_id, type }));
            setToggleReply({});
            setReplyText({});
        }
        if (type == 'edit_reply') {
            const data = { answer_id: body?.id, user_entry: productId, body: body?.text, editpost: true }
            dispatch(submit_qa_reply({ data, id: data?.answer_id, type }));
        }
    }

    const toggleRep = (id, index) => {
        if (toggleReply[index] == undefined) {
            toggleReply[index] = true;
        } else {
            toggleReply[index] = !toggleReply[index];
        }
        setToggleReply({ ...toggleReply });
    }


    return (
        <div key={index}>
            <div className="d-flex bg-light mx-n2 mt-2 p-1" key={index}>
                <div className="px-2">
                    <a href="#" className="text-decoration-none">
                        <img
                            className="img-circle _50x50"
                            src={`${answer.current_profile_pic}`}
                            alt={answer.sender}
                            onError={(error) =>
                                (error.target.src = require("../../../../assets/images/icons/user-circle.png"))
                            }
                        />
                    </a>
                </div>
                <div className="px-2 flex-grow-1">
                    <div className="d-flex mx-n2 mb-1">
                        <div className="px-2 d-flex justify-content-between">
                            <div className="col p-0 ff-alt m-2">
                                <Link
                                    to={{ pathname: `/people/${answer.sender_details}` }}
                                    className="text-dark ff-base font-weight-bold">{answer.sender} </Link>
                                &nbsp;
                                <span>Replied to</span>
                                &nbsp;
                                <Link
                                    to={{ pathname: `/people/${question.sender_details}` }}
                                    className="text-dark ff-base font-weight-bold">{question.sender} </Link>
                                &nbsp;
                                <span>{answer.sent_at}</span>
                                &nbsp;
                            </div>
                            <StarsRating
                                count={5}
                                value={answer.rating?answer.rating.value:0}
                                size={24}
                                color1={'#d7d8e2'}
                                color2={'#122c40'}
                                edit={false}
                            />
                        </div>
                    </div>
                    {editConversation && editConversationID === answer.msg_id ?
                        //  Edit Answer   
                        <div className="mt-2">
                            <FormGroup>
                                <Input
                                    className="primary"
                                    bsSize="sm"
                                    type="textarea"
                                    rows="5"
                                    name="answerBody"
                                    id="answerBody"
                                    value={editConversationObj.body}
                                    onChange={(value) => handleEditAnswerChange('body', 'body_preview', value)}
                                />
                            </FormGroup>
                            <div className="text-right mt-3">
                                <Button color="link" size="sm" className="font-weight-normal ff-alt text-royal-blue"
                                    onClick={() => { setEditConversationObj((prevState) => ({ ...prevState, body: '' })) }} >Clear</Button>
                                <Button color="link" size="sm" className="font-weight-normal ff-alt text-royal-blue"
                                    onClick={() => {
                                        setEditConversation(false);
                                        setEditConversationId(null);
                                        setEditConversationObj(null);
                                        // this.handleEditQuestion
                                    }}
                                >Cancel</Button>
                                <Button
                                    color="primary"
                                    size="sm"
                                    disabled={editConversationObj.body === "" ? true : false}
                                    onClick={async () => {
                                        await handleEditAnswerSubmit(
                                            editConversationObj,
                                            editConversationID
                                        );
                                        setEditConversation(false);
                                        setEditConversationId(null);
                                        setEditConversationObj(null);
                                    }}
                                >Post</Button>
                            </div>
                        </div>
                        :
                        /* Conversation details */
                        <div className="d-flex flex-column mx-n2">
                            <div className="px-2 col m-2">
                                <div className="ff-alt wb-break-word">
                                    {answer.body}
                                </div>
                            </div>
                            <div className='d-flex justify-content-end  align-items-center'>
                                <span className='text-dark font-weight-bold'>Rate this Answer:&nbsp;</span>
                                <StarsRating
                                    count={5}
                                    value={0}
                                    size={24}
                                    color1={'#d7d8e2'}
                                    color2={'#15c3d4'}
                                    onChange={(rating) => {
                                        handleSubmitRating(question.id,question.index,rating,answer.msg_id,index)
                                    }}
                                />
                            </div>
                            <div className="px-2 col d-flex justify-content-end" hidden={!checkIfLoggedIn()}>
                                <EditBtn
                                    onClick={() => {
                                        setEditConversation(true);
                                        setEditConversationId(answer.msg_id);
                                        setEditConversationObj(answer);
                                    }} />
                                <DeleteBtn
                                    onClick={async () => {
                                        await deleteAnswer(answer.msg_id, 'answer');
                                        confirmDeleteModalToggle();
                                    }} />
                                {/* Reply Button */}
                                <button
                                    className="btn btn-link btn-sm px-0 font-weight-bold text-dark"
                                    onClick={() =>
                                        toggleRep(answer.msg_id, index)
                                    }
                                >
                                    Reply to the Answer
                                </button>
                            </div>

                            {toggleReply[index] ?

                                <div className="bg-light p-2 mt-3">
                                    <Media className="media-post">
                                        <Media>
                                            <Media
                                                object
                                                src={my_profile_data?.current_profile_pic ? my_profile_data?.current_profile_pic : require("../../../../assets/images/icons/user-circle.png")}
                                                alt="User Image"
                                                onError={(e) => e.target.src = require("../../../../assets/images/icons/user-circle.png")}
                                            />
                                        </Media>
                                        <Media body>
                                            <FormGroup className="mb-0">
                                                <Input
                                                    bsSize="sm"
                                                    className="text-primary border mb-2"
                                                    type="textarea"
                                                    placeholder="Add Reply"
                                                    value={replyText[index]}
                                                    onChange={(e) => handleReply(e, index)}
                                                />
                                                <div className="text-right">
                                                    <div className="text-right">
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            className="ml-0"
                                                            onClick={
                                                                () => {
                                                                    toggleReply[index] = false;
                                                                    setReplyText({});
                                                                    setToggleReply({ ...toggleReply })
                                                                }
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            className=" mr-2"
                                                            onClick={() => { submitReply(replyText[index]) }}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Media>
                                    </Media>
                                </div>
                                :
                                ""
                            }
                        </div>
                    }
                    {
                        answer?.replies && Array.isArray(answer.replies) && answer?.replies.length > 0 ?
                            answer.replies.map((reply, index) => {
                                return (

                                    <Reply
                                        reply={reply}
                                        index={index}
                                        submitReply={submitReply}
                                        checkIfLoggedIn={checkIfLoggedIn}
                                        answer={answer}
                                        question={question}
                                        productId={productId}
                                        key={index}
                                        myProfile={my_profile_data}
                                        deleteAnswer={deleteAnswer}
                                        confirmDeleteModalToggle={confirmDeleteModalToggle}
                                    />
                                )
                            }
                            )
                            :
                            ""
                    }
                </div>
            </div>
        </div>
    )
}

export default Answer;
