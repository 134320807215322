import { callApi } from "../utils/apiCaller";
import { getQueryString, } from '../utils/validation';
import { toast } from "react-toastify";
export const GET_CONNECTIONS_ACTIVITY = "GET_CONNECTIONS_ACTIVITY";
export const PROFESSIONAL_CATEGORY_COLLAGE_LIST = "PROFESSIONAL_CATEGORY_COLLAGE_LIST";
export const FETCH_PROFESSIONALS_DATA_LIST = "FETCH_PROFESSIONALS_DATA_LIST";
export const GET_SAVE_PROFESSIONAL_RESPONSE = "GET_SAVE_PROFESSIONAL_RESPONSE";
export const GET_PREVIOUS_TAB = "GET_PREVIOUS_TAB";
export const SET__PROFESSIONAL_LOADER = "SET__PROFESSIONAL_LOADER";

export const set_professionals_loader = (data) => ({ type: SET__PROFESSIONAL_LOADER, payload: data });

const common_fetch = ({ url, method, data, dispatch_function }) => {

    if (data === undefined) {
        data = {};
    }
    return (dispatch) => {
        dispatch(set_professionals_loader(true));
        if(url.includes('/api/professionalscategory/') || url.includes('/api/prfessionalstarratingfilter/')) {
            dispatch(fetch_professionals_data_list_dispatch([]));
        }

        callApi(url, method, data).then((response) => {
            dispatch(set_professionals_loader(false));
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(dispatch_function(response));
            }
        });
    };
};

export const get_connections_activity_ = (data) => {
    return {
        type: GET_CONNECTIONS_ACTIVITY,
        payload: data,
    };
};

export const get_connections_activity = (url) => {
    return (dispatch) => {
        callApi(`${url}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_connections_activity_(response));
            }
        });
    };
};

export const fetch_professional_category_collage_items_dispatch = (data) => ({ type: PROFESSIONAL_CATEGORY_COLLAGE_LIST, payload: data, });
export const fetch_professional_category_collage_items = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/professionaltrends/`, data }),
        method: "GET",
        dispatch_function: fetch_professional_category_collage_items_dispatch
    })
);

export const clear_professional = () => ({ type: FETCH_PROFESSIONALS_DATA_LIST, payload: [], });

export const fetch_professionals_data_list_dispatch = (data) => ({ type: FETCH_PROFESSIONALS_DATA_LIST, payload: data, });
export const fetch_professionals_data_list = (...data) => (
    common_fetch({
        // url: getQueryString({ url: `/api/professionaltrends/`, data }),
        url: getQueryString({ url: `/api/professionalscategory/`, data }),
        method: "GET",
        dispatch_function: fetch_professionals_data_list_dispatch
    })
);

export const fetch_rating_filtered_professionals = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/prfessionalstarratingfilter/`, data }),
        method: "GET",
        dispatch_function: fetch_professionals_data_list_dispatch
    })
);

export const get_response_professional_ = (data) => {
    return {
        type: GET_SAVE_PROFESSIONAL_RESPONSE,
        payload: data,
    };
};

export const add_professional = (data) => {
    return (dispatch) => {
        callApi(`/api/professionalsbase/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Hooray! You've created a new listing. Help improve it by adding additional information and reviews.`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                return dispatch(get_response_professional_(response));
            }
        });
    };
};

export const set_previous_tab_ = (data) => {
    return {
        type: GET_PREVIOUS_TAB,
        payload: data,
    };
};

export const set_previous_tab = (previousTab) => {
    return (dispatch) => {
        return dispatch(set_previous_tab_(previousTab));
    };
};

