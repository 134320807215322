import React from 'react';
import { Table } from 'reactstrap';
import { getRatingImg } from '../../../../utils/utilities';

const GenderTable = ({ genderData, loading , bgLight}) => {
    const starColor = bgLight ? "gold" : "white";
    return (
        <>
            <hr className="bg-light mt-3" />
            <div className="text-center font-weight-bold mb-2">
                {'Average Ratings Grouped by Age & Gender'}
            </div>
            {loading ? <span>Loading</span> :
                <Table className={bgLight ? 'text-secondary border-0 ff-alt male-female-table' : 'text-tertiary-darker border-0 ff-alt male-female-table-tertiary'} size="sm" bordered style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th className="border-0 text-center" style={{ width: "15%" }}>&nbsp;</th>
                            <th className="border-0 text-center" style={{ width: "20%" }}>Male</th>
                            <th className="border-0 text-center" style={{ width: "20%" }}>Female</th>
                            <th className="border-0 text-center" style={{ width: "45%" }}>Prefer Not to Say</th>
                        </tr>
                    </thead>
                    <tbody>
                        {genderData && genderData.map((item, index) => {
                            return <tr key={index}>
                                <th className="border-0 text-center" scope="row">{item.name}</th>
                                <td className="text-center"><span title={item.male}><img
                                    src={`https://userdatawikireviews.s3.amazonaws.com/images/star/${starColor}/${getRatingImg(item.male)}`}
                                    alt="" /></span></td>
                                <td className="text-center"><span title={item.female}><img
                                    src={`https://userdatawikireviews.s3.amazonaws.com/images/star/${starColor}/${getRatingImg(item.female)}`}
                                    alt="" /></span></td>
                                <td className="text-center"><span title={item.prefer}><img
                                    src={`https://userdatawikireviews.s3.amazonaws.com/images/star/${starColor}/${getRatingImg(item.prefer)}`}
                                    alt="" /></span></td>
                            </tr>
                        })}

                    </tbody>
                </Table>}
        </>
    )
}

export default GenderTable;