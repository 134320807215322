/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { callApi } from '../../utils/apiCaller';
import AppHeader from '../oragnisms/dashboard/appHeader';
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap';
import {
    get_media_children, get_business_detail, fetch_sub_categories, fetch_categories_data,
    filter_categories_data, clear_category, clear_subcategory
} from '../../actions/business';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
const responsive = {
    mediaModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1
        }
    },
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1
        }
    },
    viewBannerMedia: {
        desktop: {
            breakpoint: { max: 9999999, min: 1200 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1199, min: 576 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1
        }
    }
};
class ViewGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMediaModalToggle: false,
            carouselMedia: null,
            activeTab: 'business_owner',
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            fetchGalleryAlbum: "",
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            uploadMediaPostModal: false,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            albumType: '',
            pageNo: 1,
            weatherData: {},
            secondCarouselMedia: [],
            businessDetails: null,
            uploadCaptions: {},
            fetchTaxonomyListing: [],
            fetchTaxomomyCategory: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            taxonomycategoryId: "",
            categoryType: "business",
            captionData: "",
            uploadedId: {},
            cattaxListing: {},
            mode: "",
            categoryTypeTab : "all",
            listingId:"",
            contenttype:"",
            mainTab:"",
            currentSlide :"",
            previousSlide :"",
            nextSlide :"",
            countSlide:1,
            mainTaxonomy: '',
            itemDetails: null,
            professionalCategory: false
        }
    }

    componentWillReceiveProps(nextProps) {
        let contenttype = this.props?.history?.location?.state?.categoryType;
        let categoryId = this.props?.history?.location?.state?.id;
        const professionalCategory = this.props?.history?.location?.state?.professionals;
        let url = "";
        if (this.state.carouselMedia === null) {
            if(contenttype.toLowerCase() == "business" || contenttype.toLowerCase() == "movies" || contenttype.toLowerCase() == "products"){
                url = `/upload/list/all/?category=&media_type=&page=1&listing=${categoryId}`;
            }else{
                url = `/upload/list/all/?category=&media_type=&page=1&pro_listing=${categoryId}`;
            }
            callApi(url, 'GET')
                .then(res => {
                    if (res && Object.keys(res).length > 0) {
                        if (professionalCategory) {
                            let carouselMedia = { ...res, results: res?.results.map((item) => {
                                return {
                                    media: item
                                }
                            })  };
                            this.setState({ carouselMedia, listingId:categoryId, professionalCategory: true},()=>{
                                this.doSpeicalThing(0,2);
                            });
                        } else {
                            this.setState({ carouselMedia: res,listingId:categoryId},()=>{
                                this.doSpeicalThing(0,2);
                            });
                        }
                    }
                });
        }

    }

    componentDidMount() {
        let contenttype = this.props?.history?.location?.state?.categoryType;
        let categoryId = this.props?.history?.location?.state?.id;
        let mainTaxonomy = this.props?.history?.location?.state?.mainTaxonomy;
        let dentistCategory = this.props?.history?.location?.state?.dentists;
        let categoryTypeMapper = {
            movies: "movies",
            physicians: "doctors",
            lawyers: "lawyers",
            actors: "actors",
            accountants: "accountants",
            business: "business",
            products: "products"
        }
        let itemDetails = {
            itemName: this.props?.history?.location?.state?.itemName,
            itemId: categoryId,
            categoryType: dentistCategory ? 'dentists' : categoryTypeMapper[contenttype]
        }
        this.setState({ listingId: categoryId, contenttype: contenttype, mainTaxonomy, itemDetails }, () => {
            if (categoryTypeMapper[this.state.mainTaxonomy]) {
                this.props.get_media_children(this.state.contenttype, 'images');
                this.props.get_media_children(this.state.contenttype, 'videos');
                this.props.clear_category();
                this.props.clear_subcategory();
            } else if (this.state.mainTaxonomy) {
                this.props.get_media_children(this.state.contenttype, 'images', this.state.mainTaxonomy);
                this.props.get_media_children(this.state.contenttype, 'videos', this.state.mainTaxonomy);
                this.props.clear_category();
                this.props.clear_subcategory();
            }
        })
    }

    handleOnClickChangeGalleryCategory = (tab, id = '', tabType = '') => {
        let {listingId} = this.state;
        let url = '',
            mediaType = '';
        let categoryTypeTab = '';
        let mainTab = ""
        if(tab == "all"){
            categoryTypeTab = "all";
        }else if(tab == "all images"){
            categoryTypeTab = "all images";
        }else if(tab == "all videos"){
            categoryTypeTab = "all videos";
        }else if(tab == "image"){
            mainTab = tab;
            categoryTypeTab = tabType;
        }else if(tab == "video"){
            mainTab = tab;
            categoryTypeTab = tabType;
        }else{
            categoryTypeTab = tabType;
        }

        if (this.state.professionalCategory) {
            if (id) {
                url = `/upload/list/${tab}/?category=${id}&media_type=&page=1&pro_listing=${listingId}`;
    
            } else {
                if (tab === 'image' || tab === 'video') {
                    mediaType = tab;
                }
                if(tab == "all"){
                    url = `/upload/list/${tab}/?category=&media_type=&page=1&pro_listing=${listingId}`
                }else if(tab == "all images"){
                    url = `/upload/list/image/?category=&media_type=image&page=1&pro_listing=${listingId}`
                }else if(tab == "all videos"){
                    url = `/upload/list/video/?category=&media_type=video&page=1&pro_listing=${listingId}`
                }else{
                    url = `/upload/list/${tab}/?category=&media_type=${mediaType}&page=1&pro_listing=${listingId}`;
                }
    
            }
            this.setState({categoryTypeTab:categoryTypeTab, mainTab:mainTab, currentSlide:"", previousSlide:"", nextSlide:""},()=>{
                callApi(url, 'GET')
                    .then(res => {
                        if (res && Object.keys(res).length > 0) {
                            let carouselMedia = { ...res, results: res?.results.map((item) => {
                                return {
                                    media: item
                                }
                            })  };
                            this.setState({ carouselMedia, professionalCategory: true},()=>{
                                this.doSpeicalThing(0,2);
                            });
                        }
                    });
            })
        } else {
            if (id) {
                url = `/upload/list/${tab}/?category=${id}&media_type=&page=1&listing=${listingId}`;
    
            } else {
                if (tab === 'image' || tab === 'video') {
                    mediaType = tab;
                }
                if(tab == "all"){
                    url = `/upload/list/${tab}/?category=&media_type=&page=1&listing=${listingId}`
                }else if(tab == "all images"){
                    url = `/upload/list/image/?category=&media_type=image&page=1&listing=${listingId}`
                }else if(tab == "all videos"){
                    url = `/upload/list/video/?category=&media_type=video&page=1&listing=${listingId}`
                }else{
                    url = `/upload/list/${tab}/?category=&media_type=${mediaType}&page=1&listing=${listingId}`;
                }
    
            }
            this.setState({categoryTypeTab:categoryTypeTab, mainTab:mainTab, currentSlide:"", previousSlide:"", nextSlide:""},()=>{
                callApi(url, 'GET')
                    .then(res => {
                        if (res && Object.keys(res).length > 0) {
                            this.setState({ carouselMedia: res });
                            this.doSpeicalThing(0,2);
                        }
                    });
            })
        }
        }        

    openSecondCarousal = (index) => {
        const carouselMedia = { ...this.state.carouselMedia };

        const array1 = index ? carouselMedia?.results.filter((item, i) => i < index) : [];
        const array2 = (index !== carouselMedia.results.length - 1) ? carouselMedia.results?.filter((item, i) => i > index) : [];
        const newArray = [carouselMedia.results[index], ...array2, ...array1];

        this.setState({ secondCarouselMedia: newArray, viewMyPhotosModalnew: true });
    }
    doSpeicalThing =(previousSlide,currentslide) => {
        let current = currentslide-2;
        let previous = previousSlide == 0 ? current+1 : previousSlide-2;
        let next = current+1;
        const carouselMedia = { ...this.state.carouselMedia };
        let countSlide = currentslide-1;
        const array1 = currentslide ? carouselMedia?.results.filter((item, i) => i == current) : [];
        const previousarray1 = currentslide ? carouselMedia?.results.filter((item, i) => i == previous) : [];
        const nextarray1 = currentslide ? carouselMedia?.results.filter((item, i) => i == next) : [];
        if(array1 && array1?.length > 0){
            this.setState({currentSlide:array1[0],countSlide:countSlide,previousSlide:previousarray1[0],nextSlide:nextarray1[0]})
        }
    }

    render() {
        const { images_children, videos_children } = this.props;
        let { carouselMedia,categoryTypeTab,mainTab,secondCarouselMedia,currentSlide,previousSlide,nextSlide,countSlide } = this.state;
        return (
            <div>
                <AppHeader isEnableCover={false} goBackLinkExists={true} goBackLinkDetails={this.state.itemDetails} />
                <header className="wk-view-gallery-banner-main">
                    <div className="wk-view-gallery-banner-bg" style={{ backgroundImage: `url(${currentSlide && currentSlide.media && currentSlide?.media?.media_type === 'video' ? currentSlide?.media?.thumbnail : currentSlide?.media?.url})` }}></div>
                    <div className="wk-gallery-banner-carousel">
                        {/* Previous image */}
                        <div className="wk-gallery-side-img-wrapper">
                            <div className="img-holder">
                                {previousSlide != "" && previousSlide != undefined ?
                                    <img src={previousSlide && previousSlide.media && previousSlide?.media?.media_type === 'video' ? previousSlide?.media?.thumbnail : previousSlide?.media?.url} alt="" />
                                    : null }
                            </div>
                        </div>

                        <Carousel
                            responsive={responsive.viewBannerMedia}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            //  centerMode={true}
                            sliderClass={`${carouselMedia?.results?.length === 0}` ? "mx-auto" : ""}
                            afterChange={(previousSlide, { currentSlide, onMove }) => {
                                this.doSpeicalThing(previousSlide,currentSlide);
                            }}
                        >
                            {carouselMedia?.results?.length > 0 ? carouselMedia?.results?.map((med, i) => {
                                return (
                                    <div className="item h-100 d-flex flex-column justify-content-center" key={med?.media?.id} onClick={() => this.openSecondCarousal(i)}>
                                        <div className={`${med?.media?.media_type === 'video' ? 'video-holder' : 'img-holder'} text-center`}>
                                            <img className="img-fluid" src={med?.media?.media_type === 'video' ? med?.media?.thumbnail : med?.media?.url} alt="" />
                                        </div>
                                        <div className="wk-gallery-banner-carousel-info">
                                            <span>Posted by {" "}
                                                <strong>
                                                    <Link to={{
                                                        pathname: `/people/${med?.media?.user?.user?.username}`,
                                                        state: {
                                                            id: med?.media?.user?.user?.id
                                                        }
                                                    }}>{med?.media?.user?.user?.first_name} {med?.media?.user?.user?.last_name}
                                                    </Link>
                                                </strong>
                                            </span>
                                            <span>{med?.media?.upload_date}</span>
                                        </div>

                                        <div className="wk-pagination-gallery mb-4 align-self-center mt-4" hidden={carouselMedia?.count == 0 ? true : false}>
                                            [ <span className='font-weight-bold'>{countSlide}</span> 0f <span className='font-weight-bold'>{carouselMedia?.count}</span>]
                                        </div>
                                    </div>

                                    
                                )}) : (
                                <div className="item px-4">
                                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '200px' }}>
                                        <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                            <span>No result found for this category</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Carousel>
                        {/* Next image */}
                        <div className="wk-gallery-side-img-wrapper">
                            <div className="img-holder">
                                {nextSlide != "" && nextSlide != undefined ?
                                    <img src={nextSlide && nextSlide.media && nextSlide?.media?.media_type === 'video' ? nextSlide?.media?.thumbnail : nextSlide?.media?.url} alt="" />
                                    : null }
                            </div>
                        </div>
                    </div>

                    {/* {currentSlide != "" ?
               <div className="wk-gallery-banner-carousel-info">
                  <span>Posted by <strong><a href="#">{currentSlide?.media?.user?.user?.username}</a></strong></span>
                  <span>{currentSlide?.media?.upload_date}</span>
               </div>
			   : "" } */}
               
                </header>
                <section className="wk-view-gallery-content">
                    <Container>
                        <Row>
                            <Col md={3}>
                                <div className="wk-pagination-gallery-filter">
                                    <h2 className='mb-4'>Categories</h2>
                                    <ul>
                                        <li className={categoryTypeTab == "all" ? "active" : ""} onClick={() => this.handleOnClickChangeGalleryCategory('all')}>
                                            <a href="javascript:void(0)">All</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)"><span className={mainTab == "image" ? "active" : ""}  onClick={() => this.handleOnClickChangeGalleryCategory('all images')}>Images</span></a>
                                            <ul className="inner-gallery-nav">
                                                <li className={categoryTypeTab == "all images" ? "active" : ""} onClick={() => this.handleOnClickChangeGalleryCategory('all images')}>
                                                    <a href="javascript:void(0)">All Images</a>
                                                </li>
                                                {images_children?.length > 0 && images_children[0]?.children?.length > 0 && images_children[0]?.children?.map(item => {
                                                    return (
                                                        <li className={(categoryTypeTab == item.label && mainTab == "image") ? "active" : ""} key={item.id} onClick={() => this.handleOnClickChangeGalleryCategory('image', item.id, item.label)}>
                                                            <a href="javascript:void(0)">{item.label}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)"><span className={mainTab == "video" ? "active" : ""}  onClick={() => this.handleOnClickChangeGalleryCategory('all videos')}>Videos</span></a>
                                            <ul className="inner-gallery-nav">
                                                <li className={categoryTypeTab == "all videos" ? "active" : ""} onClick={() => this.handleOnClickChangeGalleryCategory('all videos')}>
                                                    <a href="javascript:void(0)">All Videos</a>
                                                </li>
                                                {videos_children?.length > 0 && videos_children[0]?.children?.length > 0 && videos_children[0]?.children?.map(item => {
                                                    return (
                                                        <li className={(categoryTypeTab == item.label && mainTab == "video") ? "active" : ""} key={item.id} onClick={() => this.handleOnClickChangeGalleryCategory('video', item.id,item.label)}>
                                                            <a href="javascript:void(0)">{item.label}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={9}>
                                <div className="wk-pagination-gallery-box">
                                    <h1 className='mb-4'>Gallery</h1>
                                    <Row>
                                        {carouselMedia?.results?.length > 0 ? carouselMedia?.results?.map((med, i) => {
                                            return (
                                                <Col md={3} key={i}>
                                                    <div className="wk-pagination-gallery-box-content">
                                                        <a className="wk-pagination-gallery-box-image" style={{ height: "150px" }} role="button" onClick={() => this.setState({ viewMediaModalToggle: true }, () => {
                                                            this.openSecondCarousal(i)
                                                        })}>
                                                            <img src={med?.media?.media_type === 'video' ? med?.media?.thumbnail : med?.media?.url} alt="gallery" className='img img-fluid w-100 w-100 h-100' />
                                                        </a>
                                                        <div className="wk-pagination-gallery-box-detail-top d-flex flex-wrap">
                                                            <div className="wk-pagination-gallery-box-detail-small-image">
                                                                <img src={med?.media?.user?.current_profile_pic ? med?.media?.user?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} onError={(error) =>
                                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                                } alt="profile" />
                                                            </div>
                                                            <div className="wk-pagination-gallery-box-detail-right">
                                                                <p className="posted-user">
                                                                    Posted by {" "}
                                                                    <br />
                                                                    <Link to={{
                                                                        pathname: `/people/${med?.media?.user?.user?.username}`,
                                                                        state: {
                                                                            id: med?.media?.user?.user?.id
                                                                        }
                                                                    }}>{med?.media?.user?.user?.first_name} {med?.media?.user?.user?.last_name}
                                                                    </Link>
                                                                    {/* <a href="javascript:void(0)">{med?.media?.user?.user?.username}</a> */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <p className="wk-para-for-image">{med?.media?.caption}</p>
                                                    </div>
                                                </Col>
                                            )
                                        }) : ( null)}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* View Media Modal */}
                <Modal
                    centered
                    isOpen={this.state.viewMyPhotosModalnew}
                    toggle={() => {
                        this.setState({ viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew })
                    }}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            sliderClass={true ? "mx-auto" : ""}>
                            {/* Repeat this */}
                            {secondCarouselMedia?.length > 0 && secondCarouselMedia.map((med, i) => {
                                return (
                                    <div className="item fs-14 h-100 d-flex flex-column justify-content-center"
                                         key={med.id}
                                    >
                                        <div>
                                            <div className="d-flex text-white justify-content-between mt-3">
                                                <div className="mr-2 ff-alt">{med?.media?.caption}</div>
                                                <div className="ff-base">{med?.media?.label}</div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                            {(med?.media?.media_type === "image" || med?.media?.type === "") && (
                                                <img
                                                    className="img-fluid shown-media"
                                                    src={med?.media?.url}
                                                    alt=""
                                                />
                                            )}
                                            {med?.media?.media_type === "video" && (
                                                <video className="img-fluid shown-media" controls>
                                                    <source src={med?.media?.url} type="video/mp4" />
                                                    {'Your browser does not support the video tag.'}
                                                </video>
                                            )}
                                        </div>
                                        <div className="px-2 mt-3">
                                            <div className="d-flex flex-nowrap mx-n2">
                                                <div className="px-2 col d-flex flex-nowrap">
                                                    <div className="mr-2">
                                                        <img
                                                            className="img-circle _30x30"
                                                            src={med?.media?.user?.current_profile_pic
                                                                ? med?.media?.user?.current_profile_pic
                                                                : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                                            alt=""
                                                        />

                                                    </div>
                                                    <div>
                                                        <div className="text-primary ff-base">
                                                            {` Uploaded by `}
                                                            <Link className="text-reset" 
                                                            to={{
                                                                pathname: `/people/${med?.media?.user?.user?.username}`,
                                                                state: {
                                                                    id: med?.media?.user?.user?.id
                                                                }
                                                            }}>
                                                            <span className="font-weight-bold">
                                                                {med?.media?.user?.user.first_name} {med?.media?.user?.user.last_name}
                                                                        </span>{" "}
                                                            </Link>
                                                        </div>
                                                        <div className="text-tertiary ff-base">
                                                            {med?.media?.upload_date}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-2 col-auto">
                                                    <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                                                        {i+1} of {secondCarouselMedia.length}
                                                    </h2>
                                                </div>
                                                <div className="px-2 col">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                )})}
                        </Carousel>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapState = (state) => ({
    images_children: state.business.images_children,
    videos_children: state.business.videos_children,
})

const mapProps = (dispatch) => ({
    get_media_children: (category, type, subCategory) => dispatch(get_media_children(category, type, subCategory)),
    get_business_detail: (id) => dispatch(get_business_detail(id)),
    fetch_sub_categories: ({ sub_cat, level }) => dispatch(fetch_sub_categories({ sub_cat, level })),
    fetch_categories_data: (cat, sub_cat, type, index) => dispatch(fetch_categories_data(cat, sub_cat, type, index)),
    filter_categories_data: ({ category, sub_cat, level }) => dispatch(filter_categories_data({ category, sub_cat, level })),
    clear_category: () => dispatch(clear_category()),
    clear_subcategory: () => dispatch(clear_subcategory()),
});
export default connect(mapState, mapProps)(ViewGallery);
