/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import AppHeader from '../oragnisms/dashboard/appHeader';
import { Row, Col, Container, FormGroup, Input, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown, DropdownToggle, Button, Label, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetch_movies_category_collage_items } from '../../actions/movies';
import { fetch_movies_categories, fetch_movies_list, fetch_cities, fetch_friendspick_movies_list, fetch_recommended_movies_list, fetch_filtered_movies, fetch_current_boxoffice, reset_movies_list } from '../../actions/moviesListing';
import UserFeedsPanel from '../atoms/userFeedsRightPanel';
import Moment from "react-moment";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";
import { add_to_list, remove_from_list, share_by_email } from '../../actions/business';
import MovieCard from './movieCard';
import Loader from '../atoms/loader';
import { detectlink, pushUniqueObjectsInArray } from '../../utils/utilities';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';
import { getSubCategoryDescription, getSubCategoryTitle } from '../../utils/seo-utilities';

class MovieCategory extends Component {
    constructor(props) {
        super(props);
        this.enableScrollTillEnd = true;

        this.state = {
            viewMoviesType: 'all',
            myConnectionActivity: 'all',
            category: '',
            categoryData: props?.location?.state?.child_category,
            collage_items: [],
            movies_list: [],
            readMoreShow: {},
            currentLat: '',
            currentLng: '',
            cities: [],
            all_cities: [],
            subCategory: '',
            selectedTab: 'all',
            selectedCities: [],
            selectedCitiesMain: [],
            didYouKnowList: [],
            tabChanged: false,
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: '',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
			pathName:"",
            enableLoading: false,
            currentPageIndex: 1,
            checkNextPagination: false,
            loadingMoreData: false,
            toggleItemReadMore: {},
            currentUser: null,
            starFilterApplied: false,
            stars: 0
        }
    }

    handleScroll = () => {
        let scrollTillHeight = window.pageYOffset;
        let lastElemHeight = document.getElementById("last-index")?.offsetTop;
        let currentPageIndex = this.state.currentPageIndex;
        let checkNextPagination = this.state.checkNextPagination;
        const tab = this.state.selectedTab;
        const taxonomyId = this.state.subCategory

        if (scrollTillHeight > lastElemHeight && checkNextPagination) {
            if (this.enableScrollTillEnd) {
                this.enableScrollTillEnd = false;
                currentPageIndex++;
                this.setState({ currentPageIndex }, () => {
                    if (this.state.starFilterApplied) {
                        this.setState({ loadingMoreData: true })
                        this.props.fetch_filtered_movies({ content_type: "Movies", stars: this.state.stars, cat: '', subcat: this.state.subCategory, to_api: '', showReview: true, cPage: true, location: false, rating_filter: true, page: currentPageIndex })
                    } else {
                        if (tab === 'all') {
                            this.setState({ loadingMoreData: true })
                            this.props.fetch_movies_list({ category: taxonomyId, page: currentPageIndex })
                        }
                        else if (tab === 'friendsPick') {
                            this.setState({ loadingMoreData: true })
                            this.props.fetch_friendspick_movies_list({ category: taxonomyId, page: currentPageIndex })
                        }
                        else if (tab === 'recommended') {
                            this.setState({ loadingMoreData: true })
                            this.props.fetch_recommended_movies_list({ content_type: 'movies', cat: taxonomyId, to_api: true, showReview: true, location: false, page: currentPageIndex, recommends: true })
                        }
                        else {
                            return null
                        }
                    }                    
                });
            }
        } else if (scrollTillHeight > lastElemHeight && !checkNextPagination) {
            <Alert>
                Yay! You are all caught up.
            </Alert>
        }
    }

    componentDidMount = () => {
        const { match } = this.props
		//let pathname = match.history.location?.pathname;
        this.setState({ category: match?.params?.category, tabChanged: true, pathName:match?.url, movies_list: [], currentPageIndex: 1, collage_items: [], starFilterApplied: false, stars: 0 })
        this.props.reset_movies_list();
        this.props.fetch_movies_categories({ category: 'movies', depth: false, delsel: '', ancestor: '', sub_cat: '', level: 1 });
        this.props.fetch_current_boxoffice({ showdata: 'current' })
		window.scrollTo(0, 0)
		window.addEventListener('scroll', this.handleScroll, { passive: false });

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        const previousContent = prevProps?.match?.params?.category
        const newContent = this.props?.match?.params?.category
        if (previousContent != newContent) {
            this.props.reset_movies_list();
            this.setState({ category: newContent, collage_items: [], tabChanged: true, categoryData: this.props?.location?.state?.child_category, selectedTab: 'all', viewMoviesType: 'all' })
            this.props.fetch_movies_categories({ category: 'movies', depth: false, delsel: '', ancestor: '', sub_cat: '', level: 1 });
            this.props.fetch_current_boxoffice({ showdata: 'current' })

        }
        if (this.props.current_location && this.props.current_location.lat) {
            if (this.props.current_location.lat !== this.state.currentLat) {
                this.setState({ currentLat: this.props.current_location.lat, currentLng: this.props.current_location.lng })
                this.props.fetch_cities({ lat: this.props.current_location.lat, lng: this.props.current_location.lng });
            }
        }
    }

    componentWillReceiveProps = (nextProps) => {
        // this.setState({ loading: false })

        if (nextProps.this_user) {
            this.setState({currentUser: nextProps.this_user.user.id})
        }

        if (nextProps?.movies_categories) {
            let movie_categories = []
            const id = nextProps.location.state.id;
            let taxonomy_id = id;
            this.setState({ subCategory: id })
            movie_categories = nextProps?.movies_categories && nextProps?.movies_categories[0]?.children
            movie_categories.map(cat => {
                if (cat.category.toUpperCase() === this.state.category.toUpperCase()) {
                    taxonomy_id = cat.id
                    this.setState({ subCategory: cat.id })
                }
            })

            // https://wikireviews.com/api/search/?content_type=movies&catTrending=True&subcat=215&trendingType=national&size=6
            // https://wikireviews.com/api/search/?content_type=movies&catTrending=true&subcat=215&trendingType=national&size=6

            if (this.state?.tabChanged) {
                // this.props.fetch_movies_category_collage_items({ trending: 'national', content_type: 'movies', listingtaxonomy: taxonomy_id });
                this.props.fetch_movies_category_collage_items({ content_type: 'movies', catTrending: 'True', subcat: taxonomy_id, trendingType: 'national', size: 6 });
                this.props.fetch_movies_list({ category: taxonomy_id, page: 1 })
                this.setState({ tabChanged: false })
            }
        }

        if (nextProps?.movies_items) {
            this.setState({ loadingMoreData: false })
            if(this.state.selectedTab === "friendsPick") this.setState({ movies_list: nextProps.movies_items?.results ? nextProps.movies_items.results : [] });
            if(this.state.selectedTab === "recommended") this.setState({ movies_list: nextProps.movies_items?.results ? nextProps.movies_items.results : [] });
            else {
                if (this.state.movies_list && this.state.movies_list.length > 0) {
                    if (nextProps?.movies_items.results[0]?.id !== this.state.movies_list[0]?.id) {
                        const moviesList = JSON.parse(JSON.stringify(this.state.movies_list));
                        const updatedMovieList = pushUniqueObjectsInArray(moviesList,nextProps.movies_items.results, 'id' )
                        this.enableScrollTillEnd = true;
                        this.setState({
                            movies_list: [...updatedMovieList],
                            checkNextPagination: nextProps.movies_items?.next
                        });
                    }      
                } else {
                    this.setState({ movies_list: nextProps.movies_items.results, 
                        checkNextPagination: nextProps.movies_items.next });
                }
            }
        } else if (nextProps?.movies_items === null) {
            this.setState({ movies_list: [], 
                checkNextPagination: false,
            currentPageIndex: 1 });
        }

        if (nextProps?.collage_items && nextProps?.collage_items?.results) {
            this.setState({ collage_items: nextProps?.collage_items?.results })
        }

        if (nextProps?.get_cities) {
            this.setState({ cities: nextProps.get_cities.slice(0, 4), all_cities: nextProps.get_cities });
        }
        if (nextProps?.current_boxoffice_data) {
            this.setState({ didYouKnowList: nextProps.current_boxoffice_data });
        }

        if("isLoading" in nextProps){
            this.setState({
                enableLoading: nextProps.isLoading
            });
        }
    }

    handleToggleReadMoreShow = (type, state) => {
        this.setState({ readMoreShow: { [type]: state } })
    }

    handleTabChange = (tab) => {
        const { subCategory } = this.state
        this.setState({ movies_list: [], selectedTab: tab, currentPageIndex: 1, starFilterApplied: false, stars: 0 })
        if (tab === 'all') {
            this.props.reset_movies_list();
            this.props.fetch_movies_list({ category: subCategory, page: 1 })
        }
        else if (tab === 'friendsPick') {
            this.props.reset_movies_list();
            this.props.fetch_friendspick_movies_list({
                content_type: "movies",
                cat: subCategory,
                to_api: true,
                showReview: true,
                location: false,
                page: 1
            })
        }
        else if (tab === 'recommended') {
            this.props.reset_movies_list();
            this.props.fetch_recommended_movies_list({ content_type: 'movies', cat: subCategory, to_api: true, showReview: true, location: false, page: 1, recommends: true })
        }
        else {
            return null
        }
    }

    handleStarFilter = (star) => {
        this.props.reset_movies_list();
        this.setState({ movies_list: [], starFilterApplied: true, checkNextPagination: false, currentPageIndex: 1, stars: star})
        this.props.fetch_filtered_movies({ content_type: "Movies", stars: star, cat: '', subcat: this.state.subCategory, to_api: '', showReview: true, cPage: true, location: false, rating_filter: true, page: 1 })
    }

    handleCityFilter = () => {
        const { selectedCitiesMain } = this.state
        this.setState({ movies_list: [] })
        let city = ''
        city = selectedCitiesMain.toString()
        city = city.replace('[', '').replace(']', '').replace('"', '')
        this.setState({ viewNearbyCitiesModalToggle: false })
        this.props.fetch_filtered_movies({ citysearch: 'movie', page: 1, city: city })
    }

    handleAddCityMain = (city) => {
        const { selectedCitiesMain } = this.state
        let cities = selectedCitiesMain
        let final_cities = []
        if (cities.includes(city[0])) {
            cities.map(ct => {
                if (ct === city[0]) { }
                else { final_cities.push(ct) }
            });
            this.setState({ selectedCitiesMain: final_cities })
        }
        else {
            cities.push(city[0])
            this.setState({ selectedCitiesMain: cities })
        }
        this.handleCityFilter()
    }

    handleCitySelection = () => {
        const { selectedCities } = this.state
        this.setState({ movies_list: [] })
        let city = ''
        city = selectedCities.toString()
        city = city.replace('[', '').replace(']', '').replace('"', '')
        this.setState({ viewNearbyCitiesModalToggle: false })
        this.props.fetch_filtered_movies({ citysearch: 'movie', page: 1, city: city })
    }

    handleAddCity = (city) => {
        const { selectedCities } = this.state
        let cities = selectedCities
        let final_cities = []
        if (cities.includes(city[0])) {
            cities.map(ct => {
                if (ct === city[0]) { }
                else { final_cities.push(ct) }
            });
            this.setState({ selectedCities: final_cities })
        }
        else {
            cities.push(city[0])
            this.setState({ selectedCities: cities })
        }
    }

    handleOnChangeShareByEmail = (e) => {
        let errors = { ...this.state.shareError };
        if (e.target.value) {
            delete errors[e.target.name];
        }

        this.setState({
            [e.target.name]: e.target.value,
            shareError: errors
        });
    }

    handleOnSubmitShareByEmail = () => {
        let errors = { ...this.state.shareError };

        if (this.state.shareName === '') {
            errors.shareName = 'This field is required!';
        }

        if (this.state.shareEmail === '') {
            errors.shareEmail = 'This field is required!';
        } else {
            const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!reg.test(String(this.state.shareEmail).toLowerCase())) {
                errors.shareEmail = 'Please enter a valid email!';
            }
        }

        if (errors && Object.keys(errors).length > 0) {
            this.setState({ shareError: errors });
        } else {
            let key = 'listing_id';

            let url = `/api/shareByemail/`;

            const data = {
                [key]: this.state.shareId,
                mix_uid: `${this.state.shareEmail.split("@")[0]}_${this.state.shareId}_1597643911786`,
                email: this.state.shareEmail,
                user_name: this.state.shareName,
                listing_type: "movies"
            };

            this.props.share_by_email(url, data);

            this.setState({
                shareByEmailModalToggle: false,
                shareName: '',
                shareEmail: '',
                shareError: {}
            });
        }
    }

    handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id
        });
    }

    handleOnClickAddToList = (type, status, item) => {
        let message = '',
            url = '',
            nextUrl = '',
            key = '',
            data = { type };
        // let {profileInfo} = this.state;
        let moviesList = this.state.movies_list;
        let itemDetails = item;
        const pinStatus =  Array.isArray(item?.pins) && item?.pins?.length > 0 && item.pins[0]
        const list_type = type.replace(" ", "_")

        if (status) {
            message = `${itemDetails.name} has been removed from your ${type}`;
            url = `/api/pins/${item.id}/?type=${type}`;
            this.props.remove_from_list(url, message, nextUrl);
            if (Object.keys(pinStatus)?.length) {
                pinStatus[list_type] = []
            }
        } else {
            data.user_entry = itemDetails.id;
            message = `${itemDetails.name} has been added to your ${type}`;
            url = `/api/pins/?type=${type}&user=`;
            this.props.add_to_list(url, data, message, nextUrl);
            if (Object.keys(pinStatus)?.length) {
                pinStatus[list_type] = [1]
            }
        }

        if (pinStatus && pinStatus?.length) {
            itemDetails['pins'] = pinStatus
        }
        moviesList = moviesList.map(obj => {
            if (item.id === obj.id) {
                return itemDetails;
            }
            return obj;
        });

        this.setState({
            movies_list: [...moviesList]
        });
    }

    renderNoResultFoundDiv = (movies_list) => {
        return (
            (!movies_list?.length || this.state.enableLoading) &&
            <div className="no-result-category">
                {this.state.enableLoading ?  "Loading...": 'No Results Found. Please Choose Another Category.'}
            </div>
        )
    }

    render() {
        let { category,categoryData, collage_items, movies_list, subCategory, selectedTab, didYouKnowList, toggleItemReadMore } = this.state
        movies_list = (movies_list && Array.isArray(movies_list)) ? movies_list : [];
        
        return (
            <div>
                <HelmetMetaData
                    title={getSubCategoryTitle("Movies", category)}
                    description={getSubCategoryDescription("Movies", category)}
                />

                {
                    this.state.enableLoading ? <Loader /> : ''
                }
                <AppHeader isEnableCover={false} />
                <header style={{ overflowY: 'hidden' }}>
                    {collage_items ?
                        <Row noGutters xs={1} md={4}>
                            <Col className="d-flex flex-column">
                                <Link
                                    to={{
                                        pathname: `/movies/${collage_items[0]?.slug}`,
                                        state: { id: collage_items[0]?.id }
                                    }}
                                >
                                    <div className="flex-grow-1 featured-item">
									<img className="feature-project-image _full" src={collage_items[0]?.listing_profileimage && collage_items[0]?.listing_profileimage[0]?.type == "image" ? collage_items[0]?.listing_profileimage[0]?.location : collage_items[0]?.listing_profileimage[0]?.thumbnail} alt={collage_items[0]?.name} />
                                        <div className="featured-details hoverable">
                                            <a href="#" className="text-reset mb-2 d-block text-decoration-none">
                                                <h2 className="fs-25 text-uppercase">{collage_items[0]?.name}</h2>
                                            </a>
                                            <div className="featured-item-credit" hidden>
                                                by <span>Director/Producer</span>
                                            </div>
                                            <div className="mt-2">
                                                <img title={collage_items[0]?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[0]?.total_rating?.avg_rating[0]}`} alt={collage_items[0]?.total_rating?.avg_rating[1]} />
                                            </div>

                                            <div className="fs-13 hover-appear mt-2">
                                                <div>
                                                    {collage_items[0]?.latestreview?.review
                                                        ?
                                                        <span>
                                                            <i className="icon-quote-left"></i>
                                                            {collage_items[0]?.latestreview?.review.length < 60 ? 
															 (collage_items[0]?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
                                                             (collage_items[0]?.latestreview?.review.substring(0, 60)+ '...').replace(/<\/?[^>]+(>|$)/g, "") 
															 }
                                                            <i className="icon-quote-right"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            Be the first one to review.
                                                </span>

                                                    }
                                                </div>

                                                <div className="text-right">
                                                   from <span className="d-inline-block mr-2">{collage_items[0]?.latestreview?.created_by}</span>
                                                  <img title={collage_items[0]?.latestreview?.ratings_sets?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[0]?.latestreview?.ratings_sets?.avg_rating[0]}`} alt={collage_items[0]?.latestreview?.ratings_sets?.avg_rating[1]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="d-flex flex-column">
                                {collage_items && collage_items.slice(1, 3).map((item) =>
                                    (
                                        <Link
                                            to={{
                                                pathname: `/movies/${item?.slug}`,
                                                state: { id: item?.id }
                                            }}
                                        >
                                            <div className="flex-grow-1 featured-item">
											
                                            <img className="feature-project-image _half" src={item?.listing_profileimage && item?.listing_profileimage[0].type == "image" ? item?.listing_profileimage[0]?.location : item?.listing_profileimage[0]?.thumbnail} alt={item?.name} />
                                                <div className="featured-details hoverable">
                                                    <a href="#" className="text-reset mb-2 d-block text-decoration-none">
                                                        <h2 className="fs-25 text-uppercase">{item?.name}</h2>
                                                    </a>
                                                    <div className="featured-item-credit" hidden>
                                                        by <span>Director/Producer</span>
                                                    </div>
                                                    <div className="mt-2">
                                                        <img title={item?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.total_rating?.avg_rating[0]}`} alt={item?.total_rating?.avg_rating[1]} />
                                                    </div>

                                                    <div className="fs-13 hover-appear mt-2">
                                                        <div>
                                                            {item?.latestreview?.review
                                                                ?
                                                                <span>
                                                                    <i className="icon-quote-left"></i>
                                                                    {item?.latestreview?.review.length < 60 ? 
																	 (item?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
																	 (item?.latestreview?.review.substring(0, 60)+ '...').replace(/<\/?[^>]+(>|$)/g, "")
																	 }
                                                                    <i className="icon-quote-right"></i>
                                                                </span>
                                                                :
                                                                <span>
                                                                    Be the first one to review.
															  </span>

                                                            }
                                                        </div>

                                                        <div className="text-right">
                                                          from <span className="d-inline-block mr-2">{item?.latestreview?.created_by}</span>
                                                            <img title={item?.latestreview?.ratings_sets?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.latestreview?.ratings_sets?.avg_rating[0]}`} alt={item?.latestreview?.ratings_sets?.avg_rating[1]} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                            </Col>
                            <Col className="d-flex flex-column">
                                <Link
                                    to={{
                                        pathname: `/movies/${collage_items[3]?.slug}`,
                                        state: { id: collage_items[3]?.id }
                                    }}
                                >
                                    <div className="flex-grow-1 featured-item">
                                        <img className="feature-project-image _full" src={collage_items[3]?.listing_profileimage && collage_items[3]?.listing_profileimage[0]?.type == "image" ? collage_items[3]?.listing_profileimage[0]?.location : collage_items[3]?.listing_profileimage[0]?.thumbnail} alt={collage_items[3]?.name} />
                                        <div className="featured-details hoverable">
                                            <a href="#" className="text-reset mb-2 d-block text-decoration-none">
                                                <h2 className="fs-25 text-uppercase">{collage_items[3]?.name}</h2>
                                            </a>
                                            <div className="featured-item-credit" hidden>
                                                by <span>Director/Producer</span>
                                            </div>
                                            <div className="mt-2">
                                                <img title={collage_items[3]?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[3]?.total_rating?.avg_rating[0]}`} alt={collage_items[3]?.total_rating?.avg_rating[1]} />
                                            </div>

                                            <div className="fs-13 hover-appear mt-2">
                                                <div>
                                                    {collage_items[3]?.latestreview?.review
                                                        ?
                                                        <span>
                                                            <i className="icon-quote-left"></i>
                                                            {collage_items[3]?.latestreview?.review.length < 60 ? 
																 (collage_items[3]?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
																 (collage_items[3]?.latestreview?.review.substring(0, 60)+ '...').replace(/<\/?[^>]+(>|$)/g, "")
																 //ReactHtmlParser(collage_items[3]?.latestreview?.review.substring(0, 60)+ '...')
															 }
                                                            <i className="icon-quote-right"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            Be the first one to review.
                                                </span>

                                                    }
                                                </div>

                                                <div className="text-right">
                                                    from <span className="d-inline-block mr-2">{collage_items[3]?.latestreview?.created_by}</span>
                                                    <img title={collage_items[3]?.latestreview?.ratings_sets?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[3]?.latestreview?.ratings_sets?.avg_rating[0]}`} alt={collage_items[3]?.latestreview?.ratings_sets?.avg_rating[1]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="d-flex flex-column">
                                {collage_items && collage_items.slice(4, 6).map((item) =>
                                    (
                                        <Link
                                            to={{
                                                pathname: `/movies/${item?.slug}`,
                                                state: { id: item?.id }
                                            }}
                                        >
                                            <div className="flex-grow-1 featured-item">
                                                <img className="feature-project-image _half" src={item?.listing_profileimage && item?.listing_profileimage?.type == "image" ? item?.listing_profileimage[0]?.location : item?.listing_profileimage[0]?.thumbnail} alt={item?.name} />
                                                <div className="featured-details hoverable">
                                                    <a href="#" className="text-reset mb-2 d-block text-decoration-none">
                                                        <h2 className="fs-25 text-uppercase">{item?.name}</h2>
                                                    </a>
                                                    <div className="featured-item-credit" hidden>
                                                        by <span>Director/Producer</span>
                                                    </div>
                                                    <div className="mt-2">
                                                        <img title={item?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.total_rating?.avg_rating[0]}`} alt={item?.total_rating?.avg_rating[1]} />
                                                    </div>

                                                    <div className="fs-13 hover-appear mt-2">
                                                        <div>
                                                            {item?.latestreview?.review
                                                                ?
                                                                <span>
                                                                    <i className="icon-quote-left"></i>
                                                                    {item?.latestreview?.review.length < 60 ? 
																	 (item?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
																	 (item?.latestreview?.review.substring(0, 60) + '...').replace(/<\/?[^>]+(>|$)/g, "")
																	}
                                                                    <i className="icon-quote-right"></i>
                                                                </span>
                                                                :
                                                                <span>
                                                                    Be the first one to review.
                                                </span>

                                                            }
                                                        </div>

                                                        <div className="text-right">
                                                            from <span className="d-inline-block mr-2">{item?.latestreview?.created_by}</span>
                                                           <img title={item?.latestreview?.ratings_sets?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.latestreview?.ratings_sets?.avg_rating[0]}`} alt={item?.latestreview?.ratings_sets?.avg_rating[1]} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                            </Col>

                        </Row>
                        :
                        <div style={{height: "400px"}}></div>
                    }
                </header>

                <section className="position-relative" style={{ zIndex: 3, marginTop: '-40px' }}>
                    <Container>
                        <div>
                            <Row className="align-items-end">
                                <Col xs="auto">
                                    <Link
                                        to={'/movies/'}
                                        className="shade-link d-inline-block _black _padded">
                                        {'Back to Category'}
                                    </Link>
                                </Col>
                                <Col>
                                    <div className="text-center text-white black-shade mx-auto" style={{ maxWidth: '500px' }}>
                                        <h1 className="p-0 m-0 fs-34" style={{ 'text-transform': 'capitalize' }}>{categoryData ? categoryData : category}</h1>
                                    </div>
                                </Col>
                                <Col xs="auto">
								<Link
                                    to={{
                                        pathname: `/movie/${this.state.category}/buyers-guide`,
                                        state: { taxonomyId: subCategory}
                                    }}
									className="btn btn-primary fs-16"
                                >
                                  See Buyer's Guide</Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>

                {/* Main Content Starts */}
                <section className="py-4 text-white fs-14" style={{ backgroundColor: '#00162b' }}>
                    <Container>
                        <Row>
                            <Col md={2}>
                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2">Star Rating</div>
                                    <FormGroup check>
                                        <Label className="text-white ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(5)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/6rating.png" alt="" />
                                                <span>only</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label className="text-white ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(4)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/4rating.png" alt="" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label className="text-white ff-alt font-weight-normal" check>
                                            <Input  className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(3)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/3rating.png" alt="" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label className="text-white ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(2)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/2rating.png" alt="" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label className="text-white ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(1)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/1rating.png" alt="" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label className="text-white ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(0)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/norating.png" alt="" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <hr className="bg-light" />
                                </div>
                                {/* City Listing */}
                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2">Nearby Cities</div>
                                    {this.state?.cities.map(city => (
                                        <FormGroup className="mb-2" check>
                                            <Label className="text-white ff-alt font-weight-normal" check>
                                                <Input type="checkbox" onClick={() => this.handleAddCityMain(city)} />{' '}
                                                {city}
                                            </Label>
                                        </FormGroup>
                                    ))
                                    }
                                    <div className="text-right">
                                        <span className="ff-base text-white fs-14 font-weight-normal" role="button" onClick={() => this.setState({ viewNearbyCitiesModalToggle: true })}>
                                            <FontAwesomeIcon icon="plus" />{" More"}
                                        </span>
                                    </div>
                                    <hr className="bg-light" />
                                </div>

                                {/* Ad. */}
                                <div className="mb-3">
                                    <h2>Did you see?</h2>
                                    <hr className="bg-light" />

                                    <ul className="list-unstyled">
                                        {didYouKnowList?.map((movie) => (
                                            <li className="text-greyish text-center font-weight-bold fs-14 ff-base pb-2 border-bottom mb-2">
                                                <Link to={{
                                                    pathname: `${`/movies/${movie.listid}`}`,
                                                    state: { id: movie?.listid }
                                                }} className="text-reset text-decoration-none">
                                                    {movie?.listing_image ?
                                                        <img className="img-fluid" src={movie?.listing_image} alt="Movie Name" />
                                                        :
                                                        <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/298188r2.jpg" alt="Movie Name" />
                                                    }
                                                </Link>
                                                <div className="py-2">
                                                    {movie?.movietitle}
                                                </div>
                                                <Link
                                                    to={{
                                                        pathname: `${`/movies/${movie.listid}`}`,
                                                        state: { id: movie?.listid }
                                                    }}
                                                    className="text-dark font-weight-bold ff-base">
                                                    <Button color="link" className="text-primary p-0" size="sm">How was it?</Button>
                                                </Link>
                                            </li>

                                        )
                                        )
                                        }
                                        {/* <li className="text-greyish text-center font-weight-bold fs-14 ff-base pb-2 border-bottom mb-2">
                                            <a href="!#" className="text-reset text-decoration-none">
                                                <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/298188r2.jpg" alt="Movie Name"/>
                                            </a>
                                            <div className="py-2">
                                                The Last Vermeer
                                            </div>
                                            <Button color="link" className="text-primary p-0" size="sm">How was it?</Button>
                                        </li>
                                        <li className="text-greyish text-center font-weight-bold fs-14 ff-base pb-2 border-bottom mb-2">
                                            <a href="!#" className="text-reset text-decoration-none">
                                                <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/images/added-fav.png" alt="Movie Name"/>
                                            </a>
                                            <div className="py-2">
                                                Vanguard
                                            </div>
                                            <Button color="link" className="text-primary p-0" size="sm">How was it?</Button>
                                        </li>
                                     */}
                                    </ul>
                                </div>
                                <div>
                                    <div className="bg-white text-center">
                                        <a rel="noopener noreferrer" href="http://wikireviews.com/blog" target="_blank" className="text-reset">
                                            <img className="img-fluid blog-img" src={"https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_05.png"} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Nav className="dark active-transparent" tabs>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base font-weight-normal h-100"
                                            active={this.state.viewMoviesType === "all"}
                                            onClick={() => {
                                                this.setState({ viewMoviesType: "all", stars: 0 });
                                                this.handleTabChange('all')
                                            }}
                                        >
                                            All
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base font-weight-normal h-100"
                                            active={this.state.viewMoviesType === "friendsPick"}
                                            onClick={() => {
                                                this.setState({ viewMoviesType: "friendsPick", stars: 0 });
                                                this.handleTabChange('friendsPick')
                                            }}
                                        >
                                            Friends Pick
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base font-weight-normal h-100"
                                            active={this.state.viewMoviesType === "recommended"}
                                            onClick={() => {
                                                this.setState({ viewMoviesType: "recommended", stars: 0 });
                                                this.handleTabChange('recommended')
                                            }}
                                        >
                                            Highly Recommended
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.viewMoviesType}>
                                    <TabPane tabId="all">
                                        {this.renderNoResultFoundDiv(movies_list)}
                                        {movies_list?.map((movie, index) => (
                                            <>
                                            <MovieCard
                                                key={index}
                                                movie={movie}
                                                index={index}
                                                handleOnClickAddToList={this.handleOnClickAddToList}
                                                itemID={`${this.state.movies_list.length == index + 1 ? 'last-index' : `current-index-${index}`}`}
                                                storylineReadShowMore={(this.state.readMoreShow && !this.state.readMoreShow[`movie_01${index}`]) ? true : false}
                                                handleToggleReadMoreShow={this.handleToggleReadMoreShow}
                                            >
                                            </MovieCard>

                                            {/* First Category */}
                                            {movie?.latest_review?.review ? 
                                                        <div className="mb-1">
                                                            {/* Repeat this */}
                                                            <div className="bg-white text-dark p-3 mb-1">
                                                                <div className="d-flex mx-n2 mb-2">
                                                                    <div className="px-2">
                                                                        {(movie?.latest_review?.created_by?.user?.username && movie?.latest_review?.created_by?.user?.id && movie?.latest_review?.createdby_current_profile_pic) ?
                                                                            <Link to={{
                                                                                pathname: `${`/people/${movie?.latest_review?.created_by?.user?.username.toLowerCase().replace(/ /g, "-")}`}`,
                                                                                state: { id: movie?.latest_review?.created_by?.user?.id }
                                                                            }}>
                                                                                <img
                                                                                    className="img-circle _40x40"
                                                                                    src={movie?.latest_review?.createdby_current_profile_pic}
                                                                                    onError={(err) => err.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`}
                                                                                    alt="Reviewer-Profile"
                                                                                />
                                                                            </Link> :
                                                                            <img
                                                                                className="img-circle _40x40"
                                                                                src={`https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`}
                                                                                alt="Reviewer-Profile"
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="px-2 flex-grow-1">
                                                                        <div className="ff-base text-dark">
                                                                            {(movie?.latest_review?.created_by?.user?.username && movie?.latest_review?.created_by?.user?.id) ?
                                                                                <Link to={{
                                                                                    pathname: `${`/people/${movie?.latest_review?.created_by?.user?.username.toLowerCase().replace(/ /g, "-")}`}`,
                                                                                    state: { id: movie?.latest_review?.created_by?.user?.id }
                                                                                }} className="text-reset ff-base font-weight-bold mr-1">
                                                                                    {`${movie?.latest_review?.created_by?.user?.first_name} ${movie?.latest_review?.created_by?.user?.last_name}`}
                                                                                </Link> :
                                                                                <span className="text-reset ff-base font-weight-bold mr-1">
                                                                                    {`${movie?.latest_review?.created_by?.user?.first_name} ${movie?.latest_review?.created_by?.user?.last_name}`}
                                                                                </span>
                                                                            }

                                                                            {movie?.latest_review?.ratings_sets?.avg_rating &&
                                                                                Array.isArray(movie?.latest_review?.ratings_sets?.avg_rating) &&
                                                                                movie?.latest_review?.ratings_sets?.avg_rating?.length ?
                                                                                <>
                                                                                    <span className="text-reset ff-base mr-1">
                                                                                        gave it
                                                                                    </span>
                                                                                    <img
                                                                                        title={movie?.latest_review?.ratings_sets?.avg_rating[1]}
                                                                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${movie?.latest_review?.ratings_sets?.avg_rating[0]}`}
                                                                                        alt={movie?.latest_review?.ratings_sets?.avg_rating[1]}
                                                                                    />
                                                                                </>
                                                                                : null}
                                                                            <br />
                                                                        </div>

                                                                        {/* User Reply */}
                                                                        <div className="ff-alt mt-2 text-break">
                                                                        {movie?.latest_review?.review && movie.latest_review.review?.length > 120 ?
                                                                            ReactHtmlParser((toggleItemReadMore[movie?.id])
                                                                                ?
                                                                                (movie?.latest_review?.review)
                                                                                :
                                                                                ((movie?.latest_review?.review).substring(0, 120) + "...")
                                                                            )
                                                                            :
                                                                            movie?.latest_review?.review ? ReactHtmlParser(detectlink(movie?.latest_review?.review))
                                                                                :
                                                                                ''
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="text-right mt-3 d-flex justify-content-end align-items-center">
                                                                    {/* <span
                                                                        onClick={() => {
                                                                            if (toggleItemCreateReview[movie?.id] == undefined) {
                                                                                toggleItemCreateReview[movie?.id] = true;
                                                                            } else {
                                                                                toggleItemCreateReview[movie?.id] = !toggleItemCreateReview[movie?.id];
                                                                            }
                                                                            this.setState({ toggleItemCreateReview });
                                                                        }}
                                                                        className="text-primary font-weight-bold cursor-pointer">Write your own</span> */}
                                                                <Link
                                                                    to={{
                                                                        pathname: `${movie?.slug ? `/movies/${movie.slug}` : `/movies/${movie?.name.toLowerCase().replace(/ /g, '-')}`}`,
                                                                        state: { id: movie?.id }
                                                                    }}
                                                                    className="text-dark font-weight-bold ff-base">
                                                                    <Button color="link" className="text-primary font-weight-bold pl-0 pr-0">{this.state.currentUser === movie?.latest_review?.created_by?.user?.id ? "Write your follow-up" : "Create your own review"}</Button>
                                                                </Link>
                                                                {movie?.latest_review?.review.length > 120 &&
                                                                    <>
                                                                        <span className="mx-2"></span>
                                                                        <span
                                                                            onClick={() => {
                                                                                if (toggleItemReadMore[movie?.id] == undefined) {
                                                                                    toggleItemReadMore[movie?.id] = true;
                                                                                } else {
                                                                                    toggleItemReadMore[movie?.id] = !toggleItemReadMore[movie?.id];
                                                                                }
                                                                                this.setState({ toggleItemReadMore });
                                                                            }}
                                                                            className="text-dark font-weight-bold cursor-pointer">
                                                                            {toggleItemReadMore[movie?.id] ? 'show less' : 'read more'}
                                                                        </span>
                                                                    </>
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="mb-1">
                                                            {/* Repeat this */}
                                                            <div className="bg-white text-dark p-3 mb-1">
                                                                <div className="d-flex mx-n2 mb-2">
                                                                    <div className="px-2 flex-grow-1">
                                                                        {/* User Reply */}
                                                                        <div className="ff-alt mt-2 text-break">
                                                                        Be the First to Write A Review About This Listing! (And Get Your First Reviewer Badge!)
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="text-right mt-3 d-flex justify-content-end align-items-center">
                                                                <Link
                                                                    to={{
                                                                        pathname: `${movie?.slug ? `/movies/${movie.slug}` : `/movies/${movie?.name.toLowerCase().replace(/ /g, '-')}`}`,
                                                                        state: { id: movie?.id }
                                                                    }}
                                                                    className="text-dark font-weight-bold ff-base">
                                                                    <Button color="link" className="text-primary font-weight-bold pl-0 pr-0">{this.state.currentUser === movie?.latest_review?.created_by?.user?.id ? "Write your follow-up" : "Create your own review"}</Button>
                                                                </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                            </>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId="friendsPick">
                                        <div>
                                            {this.renderNoResultFoundDiv(movies_list)}
                                            {movies_list?.map((movie, index) => (
                                                <MovieCard
                                                    key={index}
                                                    movie={movie}
                                                    index={index}
                                                    handleOnClickAddToList={this.handleOnClickAddToList}
                                                    itemID={`${this.state.movies_list.length == index + 1 ? 'last-index' : `current-index-${index}`}`}
                                                    storylineReadShowMore={(this.state.readMoreShow && !this.state.readMoreShow[`movie_01${index}`]) ? true : false}
                                                    handleToggleReadMoreShow={this.handleToggleReadMoreShow}
                                                >
                                                </MovieCard>
                                            ))}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="recommended">
                                        {this.renderNoResultFoundDiv(movies_list)}
                                        {movies_list?.map((movie, index) => (
                                            <MovieCard
                                                key={index}
                                                movie={movie}
                                                index={index}
                                                handleOnClickAddToList={this.handleOnClickAddToList}
                                                itemID={`${this.state.movies_list.length == index + 1 ? 'last-index' : `current-index-${index}`}`}
                                                storylineReadShowMore={(this.state.readMoreShow && !this.state.readMoreShow[`movie_01${index}`]) ? true : false}
                                                handleToggleReadMoreShow={this.handleToggleReadMoreShow}
                                            >
                                            </MovieCard>
                                        ))}
                                    </TabPane>
                                </TabContent>
                                {
                                    this.state.loadingMoreData ?
                                        <div className="no-result-category">
                                            {this.state.enableLoading ? "Fetching more data..." : ''}
                                        </div>
                                        : ''
                                }
                                <div style={{height: "400px"}}></div>
                            </Col>
                            <Col md={4}>
                                {category && subCategory ?
                                    <div className="type-dark">
                                        <UserFeedsPanel category={category} sub_category={subCategory} mainCategory="movies"/>
                                    </div>
                                    :
                                    ''
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Show All Cities */}
                <Modal isOpen={this.state.viewNearbyCitiesModalToggle} toggle={() => this.setState({ viewNearbyCitiesModalToggle: !this.state.viewNearbyCitiesModalToggle })}>
                    <ModalHeader toggle={() => this.setState({ viewNearbyCitiesModalToggle: !this.state.viewNearbyCitiesModalToggle })}>MORE NEARBY CITIES</ModalHeader>
                    <ModalBody>
                        <Row xs={1} sm={2} lg={3}>
                            {this.state?.all_cities.map(city => (
                                <Col>
                                    <FormGroup className="mb-2" check>
                                        <Label className="text-dark font-weight-normal fs-14" check>
                                            <Input type="checkbox" onClick={() => this.handleAddCity(city)} />{' '}
                                            {city}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            ))
                            }
                            {/* <Col>
                                <FormGroup className="mb-2" check>
                                    <Label className="text-dark font-weight-normal fs-14" check>
                                        <Input type="checkbox" />{' '}
                                            Westwood
                                        </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-2" check>
                                    <Label className="text-dark font-weight-normal fs-14" check>
                                        <Input type="checkbox" />{' '}
                                            Franklin Square
                                        </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-2" check>
                                    <Label className="text-dark font-weight-normal fs-14" check>
                                        <Input type="checkbox" />{' '}
                                            Linden
                                        </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-2" check>
                                    <Label className="text-dark font-weight-normal fs-14" check>
                                        <Input type="checkbox" />{' '}
                                            Jamaica
                                        </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-2" check>
                                    <Label className="text-dark font-weight-normal fs-14" check>
                                        <Input type="checkbox" />{' '}
                                            Secaucus
                                        </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-2" check>
                                    <Label className="text-dark font-weight-normal fs-14" check>
                                        <Input type="checkbox" />{' '}
                                            Yonkers
                                        </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-2" check>
                                    <Label className="text-dark font-weight-normal fs-14" check>
                                        <Input type="checkbox" />{' '}
                                            Malverne
                                        </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-2" check>
                                    <Label className="text-dark font-weight-normal fs-14" check>
                                        <Input type="checkbox" />{' '}
                                            Paramus
                                        </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-2" check>
                                    <Label className="text-dark font-weight-normal fs-14" check>
                                        <Input type="checkbox" />{' '}
                                            Staten Island
                                        </Label>
                                </FormGroup>
                            </Col> */}
                        </Row>

                        <div className="mt-2">
                            <Button color="primary" className="mw" size="sm" onClick={() => this.handleCitySelection()} >Search</Button>
                            <Button color="primary" className="mw" size="sm" onClick={() => this.setState({ viewNearbyCitiesModalToggle: false })}>Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>

                {/* Share By Email Modal Popup */}
                <Modal
                    size="sm"
                    isOpen={this.state.shareByEmailModalToggle}
                    toggle={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })
                    }
                >
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() =>
                            this.setState({
                                shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                            })
                        }
                    >
                        Share by Email
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="text"
                                name="shareName"
                                placeholder="Your name"
                                value={this.state.shareName}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareName && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareName}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="email"
                                name="shareEmail"
                                placeholder="Recipient's email"
                                value={this.state.shareEmail}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareEmail && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareEmail}
                                </div>
                            )}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <div className="text-right">
                            <Button color="primary" onClick={this.handleOnSubmitShareByEmail}>
                                Share
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
const mapState = (state) => ({
            this_user: state.user.my_profile,
            collage_items: state.movies.movies_category_collage_list,
            movies_categories: state.movies_listing.movies_categories,
            movies_items: state.movies_listing.movies_items,
            current_location: state.home.current_location,
            get_cities: state.movies_listing.get_cities,
            current_boxoffice_data: state.movies_listing.current_boxoffice_data,
            isLoading: state.movies_listing.isLoading
        });

const mapProps = (dispatch) => ({
    fetch_movies_category_collage_items: (data) => dispatch(fetch_movies_category_collage_items(data)),
    fetch_movies_categories: (data) => dispatch(fetch_movies_categories(data)),
    fetch_movies_list: (data) => dispatch(fetch_movies_list(data)),
    fetch_cities: (data) => dispatch(fetch_cities(data)),
    fetch_friendspick_movies_list: (data) => dispatch(fetch_friendspick_movies_list(data)),
    fetch_recommended_movies_list: (data) => dispatch(fetch_recommended_movies_list(data)),
    fetch_filtered_movies: (data) => dispatch(fetch_filtered_movies(data)),
    fetch_current_boxoffice: (data) => dispatch(fetch_current_boxoffice(data)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    reset_movies_list: () => dispatch(reset_movies_list()),
});

export default connect(mapState, mapProps)(MovieCategory);


// https://wikireviews.com/api/friendspick/?content_type=movies&cat=206&to_api=true&showReview=true&location=false&page=1
// https://wikireviews.com/api/search/?content_type=movies&cat=206&to_api=true&showReview=true&location=false&page=1&recommends=true

