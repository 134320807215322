import { callApi } from "../utils/apiCaller";
// import { get_user_role_and_permissions } from "./user";
import { toast } from "react-toastify";
import { fetch_current_location } from "./home";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_ERROR = "AUTH_ERROR";
export const IS_LOGIN_TRUE = "IS_LOGIN_TRUE";
export const GET_CAPTCHA = "GET_CAPTCHA";
export const CAPTCHA_ERROR = "CAPTCHA_ERROR";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const EMAIL_SIGNUP_RESPONSE = "EMAIL_SIGNUP_RESPONSE";
export const GET_USER_INFORMATION = "GET_USER_INFORMATION";
export const EMAIL_SIGNUP_ERROR = "EMAIL_SIGNUP_ERROR";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESEND_ACTIVATION_EMAIL_ERROR = "RESEND_ACTIVATION_EMAIL_ERROR";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const SET_INITIAL_AUTH_NEW = "SET_INITIAL_AUTH_NEW";

export const setInitialAuth = () => ((dispatch) => (dispatch({ type: SET_INITIAL_AUTH_NEW, payload: null })));

export const auth_login_request_ = (data) => {
  return {
    type: AUTH_LOGIN,
    payload: data,
  };
};

export const auth_login_error_ = (data) => {
  return {
    type: AUTH_ERROR,
    payload: data,
  };
};

export const is_auth_login_true = (data) => {
  return {
    type: IS_LOGIN_TRUE,
    payload: data,
  };
};

// AUTH LOGIN REQUEST
export const auth_login_request = (data) => {
  return (dispatch) => {
    callApi("/api-token/login/auth/", "POST", data).then((response) => {
      if (
        response &&
        response.data?.non_field_errors &&
        response.data?.non_field_errors?.length > 0
      ) {
        return dispatch(auth_login_error_(response.data));
      }

      if (response && response.token) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("profileId", response.profileid);
        dispatch(auth_login_error_(null));
        return dispatch(auth_login_request_(response));
      }
    });
  };
};

export const auth_logout_request = ({ props }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      callApi("/api-token/logout/", "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          localStorage.clear();
          dispatch(setInitialAuth());
          // props.history.push('/')
          if (window.FB) {
            window.FB.logout();
          }
          if(props && props.logoutToHome){
            let redirectUrl = window.location.origin;
            window.location.replace(redirectUrl);
          }else{
            window.location.reload();
          }
          dispatch(fetch_current_location());
          // return dispatch(get_category_list_(response));
        }
      });
      resolve()
    });
  };
};

export const google_login_request = (data, accessToken) => {
  return (dispatch) => {
    callApi("/api-token/login/google-plus/", "POST", data,"","googleLogin", accessToken).then((response) => {
      if (
        response &&
        response.non_field_errors &&
        response.non_field_errors.length > 0
      ) {
        return dispatch(auth_login_error_(response));
      }

      if (response && response.token) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("profileId", response.profileid);
        return dispatch(auth_login_request_(response));
      }
    });
  };
};

export const facebook_login_request = (data) => {
  return (dispatch) => {
    callApi("/api-token/login/facebook/", "POST", data).then((response) => {
      if (
        response &&
        response.non_field_errors &&
        response.non_field_errors.length > 0
      ) {
        return dispatch(auth_login_error_(response));
      }

      if (response && response.token) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("profileId", response.profileid);
        return dispatch(auth_login_request_(response));
      }
    });
  };
};

export const get_captcha_ = (data) => {
  return {
    type: GET_CAPTCHA,
    payload: data,
  };
};

export const get_captcha = () => {
  return (dispatch) => {
    callApi("/api/getcapcha/", "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_captcha_(response.captcha));
      }
    });
  };
};

export const set_captcha_error = (data) => {
  return {
    type: CAPTCHA_ERROR,
    payload: data,
  };
};

export const set_email_signup_response = (data) => {
  return {
    type: EMAIL_SIGNUP_RESPONSE,
    payload: data,
  };
};

export const get_category_list_ = (data) => {
  return {
    type: GET_CATEGORY_LIST,
    payload: data,
  };
};

export const get_category_list = () => {
  return (dispatch) => {
    callApi("/api/getcategorylist/", "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_category_list_(response));
      }
    });
  };
};

export const set_email_signup_error = (data) => {
  return {
    type: EMAIL_SIGNUP_ERROR,
    payload: data,
  };
};

export const signup = (data) => {
  return (dispatch) => {
    return callApi("/api/signup/", "POST", data).then((response) => {
      if (response && !response.profileId) {
        dispatch(set_email_signup_error(response));
        return dispatch(get_captcha());

      } else if (response && response.profileId) {
        let newResponse = { ...response, password: data.password1, email: data.email };
        dispatch(set_email_signup_response(newResponse));
        dispatch(set_email_signup_error(null));
        //toast.success("Sign Up Successful. Please go through our Onboarding process to enjoy the most that WikiReviews can offer you.!");
		toast(`Sign Up Successful. Please go through our Onboarding process to enjoy the most that WikiReviews can offer you.!`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return newResponse;
      }
    });
  };
};

export const bulk_favourite = (data) => {
  return (dispatch) => {
    callApi("/api/bulkfavourite/", "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return true;
      }
    });
  };
};

export const get_user_information_ = (data) => {
  return {
    type: GET_USER_INFORMATION,
    payload: data,
  };
};

export const get_user_information = (username) => {
  return (dispatch) => {
    callApi(`/api/people/${username}/`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_user_information_(response));
      }
    });
  };
};

export const upload_media = (data) => {
  return (dispatch) => {
    callApi("/upload/multiuploader/?album=profile&index=true", "POST", data, true).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return true;
      }
    });
  };
};

export const set_reset_password_error = (data) => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: data,
  };
};

export const reset_password = (data) => {
  return (dispatch) => {
    callApi("/api/password/reset/", "POST", data).then((response) => {
      if (!!response.data?.email?.length) {
        toast(`${response.data?.email?.[0]}`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(set_reset_password_error(response.data));

      } else if (response && response.message) {
        toast(`${response.message}`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(set_reset_password_error(null));
      }
    });
  };
};

export const set_resend_activation_email_error = (data) => {
  return {
    type: RESEND_ACTIVATION_EMAIL_ERROR,
    payload: data,
  };
};

export const resend_activation_email = (data) => {
  return (dispatch) => {
    callApi("/api/email/activation/", "POST", data).then((response) => {
      if (response && response.data?.email && response.data?.email?.length > 0) {
        toast(`${response.data?.email[0]}`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return true;//dispatch(set_resend_activation_email_error(response));

      } else if (response && response.message) {
        toast(`Please check email and click on the activation link, to activate the account.`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(set_resend_activation_email_error(null));
      }
    });
  };
};

export const reset_password_with_email = (data, token) => {
  return (dispatch) => {
    callApi(`/api/password/reset/confirm${token ? token : '/'}`, "POST", data).then((response) => {
      if (response && response.message) {
        toast(`Your password has been changed successfully!`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return true;
      }
    });
  };
};

export const get_locations_ = (data) => {
  return {
    type: GET_LOCATIONS,
    payload: data,
  };
};

export const get_locations = () => {
  return (dispatch) => {
    callApi(`/api/get-states/`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_locations_(response));
      }
    });
  };
};

