import React from "react";
import { Media } from "reactstrap";
import { useState } from "react";
import { ReplyEditComment } from "./ReplyEditComment";
import EditBtn from "../../atoms/editBtn";
import DeleteBtn from "../../atoms/deleteBtn";
import { isString } from "lodash";

export const Comments = ({
  comments,
  onDeleteComment,
  onEditComment_Reply,
  onCommentReply,
  myProfile,
}) => {
  const [commentIdToEdit, setCommentIdToEdit] = useState(null);
  const [commentIdToReply, setCommentIdToReply] = useState(null);
  
  const checkIfLoggedIn = () => {
    const authId = localStorage.getItem('profileId');
    if (myProfile && myProfile.id && authId && (authId == myProfile.id)) {
      return true;
    } else {
      return false;
    }
  }

  const checkOwner = ({ conversation, type }) => {
    if (checkIfLoggedIn() && myProfile?.user?.username) {
      if (type == "comment" && conversation?.sender_details == myProfile.user.username) {
        return true;
      }
      if (type == "reply" && (conversation?.sender_details == myProfile.user.username || conversation?.sender?.username == myProfile.user.username))
        return true;
    }
    return false;
  }

  return (
    <>
      {comments &&
        comments.map((comment) => (
          <div key={comment?.id || comment?.msg_id}>
            <Media className="media-post mt-3">
              <Media>
                <img
                  className="media-object"
                  src={
                    comment?.current_profile_pic
                      ? comment.current_profile_pic
                      : require("../../../assets/images/icons/user-circle.png")
                  }
                  onError={(error) =>
                    (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                  }
                  alt="user"
                />
              </Media>
              <Media body>
                <Media className="mb-0" heading>
                  <div className="d-flex flex-nowrap mx-n2">
                    <div className="px-2 flex-grow-1">
                      <span className="text-darker fs-14">
                        {comment?.sender && comment.sender}:&nbsp;
                      </span>
                      {/* Comments */}
                      <span className="wb-break-word fs-14 font-weight-normal text-dark ff-alt">
                        {comment?.body_preview ? comment?.body_preview : comment?.body}
                      </span>
                    </div>
                  </div>
                </Media>
                <div>
                  <div className="d-flex flex-nowrap mx-n2 mt-n1">
                    <div className="align-self-end flex-grow-1 px-2">
                      {/* Reply Button */}
                      <button
                        className="btn btn-link btn-sm p-0 font-weight-bold text-darker"
                        onClick={() => {
                          setCommentIdToEdit(null);
                          setCommentIdToReply(comment?.id || comment?.msg_id);
                        }}
                      >
                        Reply
                      </button>
                      <span className="fs-14 ff-alt text-tertiary align-middle">
                        <span>{" - "}</span>
                        {comment?.sent_at && comment.sent_at}
                      </span>
                    </div>

                    {/* Remove Actions if comment by other user  */}
                    {(checkOwner({ conversation: comment, type: "comment" })) 
                      ? 
                      <div className="align-self-start col-auto px-2">
                      <EditBtn
                        onClick={() => {
                          setCommentIdToReply(null);
                          setCommentIdToEdit(comment?.id || comment?.msg_id);
                        }}
                      />
                      <DeleteBtn
                        onClick={() =>
                          onDeleteComment((comment?.id || comment?.msg_id), "others_comment", false , comment, "comment")
                        }
                      />
                    </div>
                      :""
                    }
                    
                  </div>
                  {(comment?.id || comment?.msg_id) && commentIdToEdit === (comment?.id || comment?.msg_id) ? (
                    <ReplyEditComment
                      id={comment?.id || comment?.msg_id}
                      body={comment?.body}
                      userImgSource={
                        comment?.current_profile_pic
                            ? comment.current_profile_pic
                            : require("../../../assets/images/icons/user-circle.png")
                      }
                      isUpdating={true}
                      onCancel={() => setCommentIdToEdit(null)}
                      onSubmit={(body) => {
                        onEditComment_Reply({
                          body,
                          id: comment?.id || comment?.msg_id,
                          type: "comment",
                          postConversation: comment,
                        });
                        setCommentIdToEdit(null);
                      }}
                    />
                  ) : null}
                  {(comment?.id || comment?.msg_id) && commentIdToReply === (comment?.id || comment?.msg_id) ? (
                    <ReplyEditComment
                      id={comment?.id || comment?.msg_id}
                      body={""}
                      userImgSource={
                        comment?.current_profile_pic
                            ? comment.current_profile_pic
                            : require("../../../assets/images/icons/user-circle.png")
                      }
                      isUpdating={false}
                      onCancel={() => setCommentIdToReply(null)}
                      onSubmit={(body) => {
                        onCommentReply({
                          body,
                          id: comment?.id || comment?.msg_id,
                          type: "reply",
                        });
                        setCommentIdToReply(null);
                      }}
                    />
                  ) : null}
                </div>
                {comment &&
                  (comment.conversation || comment?.replies)  &&
                  Array.isArray((comment.conversation || comment?.replies)) &&
                  (comment?.replies?.length > 0 || comment?.replies?.length > 0) &&
                  [...(comment.conversation || comment?.replies)].map((reply,key) => (
                    /* Replies to a comment */
                    <Media className="media-post mt-2 fs-13" key={key}>
                      <Media>
                        <img
                          className="media-object"
                          src={
                            (reply.current_profile_pic || reply?.sender?.current_profile_pic )
                              ? (reply.current_profile_pic || reply?.sender?.current_profile_pic )
                              : require("../../../assets/images/icons/user-circle.png")
                          }
                          onError={(error) =>
                            (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                          }
                          alt="user"
                        />
                      </Media>
                      <Media body>
                        <Media className="mb-0" heading>
                          <div className="d-flex flex-nowrap mx-n2">
                            <div className="px-2 flex-grow-1">
                              <span className="text-darker fs-13">
                                { reply?.sender?.full_name ? reply?.sender?.full_name : reply?.sender }:&nbsp;
                              </span>
                              {/* Comments */}
                              <span className="wb-break-word fs-13 font-weight-normal text-dark ff-alt">
                                {reply?.body}
                              </span>
                            </div>
                          </div>
                        </Media>
                        <div>
                          <div className="d-flex flex-nowrap mx-n2 mt-n1">
                            <div className="align-self-end flex-grow-1 px-2">
                              {/* Reply Button */}
                              {
                                isString(reply?.sender) &&
                                <button
                                  className="btn btn-link btn-sm p-0 font-weight-bold text-darker fs-13"
                                  onClick={() => {
                                    setCommentIdToEdit(null);
                                    setCommentIdToReply((reply?.msg_id || reply?.id));
                                  }}
                                >
                                  Reply
                                </button>

                              }
                              
                              <span className="ff-alt text-dark align-middle">
                                <span>{" - "}</span>
                                {reply?.sent_at}
                              </span>
                            </div>

                            {/* Remove Actions if comment by other user  */}
                            {(checkOwner({ conversation: reply, type: "reply" }))
                              ?
                              <div className="align-self-start col-auto px-2">
                                {/** Disable edit functionality for my post replies */}
                                {
                                  isString(reply?.sender) &&
                                  <EditBtn
                                    onClick={() =>
                                      setCommentIdToEdit((reply?.msg_id || reply?.id))
                                    }
                                  />
                                }
                                <DeleteBtn
                                  onClick={() =>
                                    onDeleteComment(
                                      (reply?.msg_id || reply?.id),
                                      "others_comment",
                                      false,
                                      reply,
                                      "reply"
                                    )
                                  }
                                />
                              </div>
                              : ""}
                            
                          </div>
                          {reply.msg_id && commentIdToEdit === reply.msg_id ? (
                            <ReplyEditComment
                              id={(reply?.msg_id || reply?.id)}
                              body={reply?.body}
                              userImgSource={
                                comment?.current_profile_pic
                                    ? comment.current_profile_pic
                                    : require("../../../assets/images/icons/user-circle.png")
                              }
                              isUpdating={true}
                              onCancel={() => setCommentIdToEdit(null)}
                              onSubmit={(body) => {
                                onEditComment_Reply({
                                  body,
                                  id: (reply?.msg_id || reply?.id),
                                  type: "reply",
                                  postConversation: reply
                                });
                                setCommentIdToEdit(null);
                              }}
                            />
                          ) : null}
                          {(reply?.msg_id || reply?.id) && commentIdToReply === (reply?.msg_id || reply?.id) && (
                            <ReplyEditComment
                              id={(reply?.msg_id || reply?.id)}
                              body={""}
                              userImgSource={
                                comment?.current_profile_pic
                                    ? comment.current_profile_pic
                                    : require("../../../assets/images/icons/user-circle.png")
                              }
                              isUpdating={false}
                              onCancel={() => setCommentIdToReply(null)}
                              onSubmit={(body) => {
                                onCommentReply({
                                  body,
                                  id: comment?.id || comment?.msg_id,
                                  type: "reply",
                                });
                                setCommentIdToReply(null);
                              }}
                            />
                          )}
                        </div>
                      </Media>
                    </Media>
                  ))}
              </Media>
            </Media>
          </div>
        ))}
    </>
  );
};
