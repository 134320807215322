import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { development_connection_keys } from "../../../utils/constants";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import googleImg from "../../../assets/images/icons/google.svg"

const GoogleAuth = ({
  alreadyImported,
  handleAuthorization,
  setLocalItems,
}) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleAuthorization(tokenResponse);
    },
    onError: (response) => {
        toast("Oops! Something went wrong.", {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
    },
    scope: development_connection_keys.google.scope,
  });

  return (
    <>
      {!alreadyImported ? (
        <Button
          className="mb-2"
          onClick={() => {
            setLocalItems({
              yahoo: false,
              outlook: false,
              twitter: false,
              facebook: false,
              linkedin: false,
            });
            login();
          }}
          size="lg"
          color="google fs-14"
          block
        >
          <div className="d-flex align-items-center">
            <span className="mr-2">
              {/* <FontAwesomeIcon
                size="lg"
                icon={["fab", "google-plus-g"]}
                fixedWidth
              /> */}
              <img src={googleImg} className="h-100" width="25px" height="25px" alt="google plus icon" />
            </span>
            <span>
              {`Import from `}
              <span className="text-uppercase">Google+</span>
            </span>
          </div>
        </Button>
      ) : (
        <div className="d-flex mb-2">
          <div className="btn fs-14 btn-google btn-lg flex-grow-1 pe-none mx-0">
            <div className="d-flex align-items-center">
              <span className="mr-2">
                {/* <FontAwesomeIcon
                  size="lg"
                  icon={["fab", "google-plus-g"]}
                  fixedWidth
                /> */}
              <img src={googleImg} className="h-100" width="25px" height="25px" alt="google plus icon" />
              </span>
              <span>
                {`Import from `}
                <span className="text-uppercase">Google+</span>
              </span>
            </div>
          </div>
          <div title="imported">
            <div className="btn btn-google btn-lg pe-none h-100 d-inline-flex flex-column justify-content-center px-2 mx-0">
              <i className="imported-contacts"></i>
            </div>
          </div>
          <Button
            onClick={() => {
              setLocalItems({
                yahoo: false,
                outlook: false,
                twitter: false,
                facebook: false,
                linkedin: false,
              });
              login();
            }}
            color="google"
            className="d-inline-flex flex-column justify-content-center px-2 mx-0"
            size="lg"
            title="sync"
          >
            <i className="sync-contacts"></i>
          </Button>
        </div>
      )}
    </>
  );
};

export default GoogleAuth;
