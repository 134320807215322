// import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
// import { useEmbedly } from "react-contentful-embedly";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {Button, ButtonGroup, Col, Row, } from "reactstrap";
import { get_jury_duty, get_jury_duty_history } from "../../../actions/dispute";
import { Ratings } from "../../molecules/homepage/ShowRatings";
import { Carousel as ReactRespCarousel } from "react-responsive-carousel";
import Moment from "react-moment";
import JuryResult from "../../atoms/disputeReview/juryResult";
import DisputeJuryDuty from "../../atoms/disputeReview/disputeJuryDuty";

// const ComponentNew = (props) => {
//   const option = useEmbedly({
//     align: "left",
//     key: "86995e5d79b242c5b34610989d303d90",
//   });
//   const documentIncludesEmbedly = {
//     nodeType: "document",
//     data: {},
//     content: [
//       {
//         nodeType: "paragraph",
//         data: {},
//         content: [
//           {
//             nodeType: "text",
//             value: `Embedded content: ${props.embedData}`,
//             marks: [],
//             data: {},
//           },
//         ],
//       },
//     ],
//   };

//   let result = documentToReactComponents(documentIncludesEmbedly, option);

//   if (result && Array.isArray(result) && result.length) {
//     let mainKey = result[0];
//     if (Object.keys(mainKey).length > 1) {
//       return <div>{result}</div>;
//     } else {
//       return null;
//     }
//   }
// };

class JuryDuty extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      viewJuryDutyType: 'juryDutyRequests',
      jury_duty_items: [],
      showJuryResult: false,
      review:'',
      juryModal: false,
      showJuryModal: false,
      disputeModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.jury_duty_history) {
      this.setState({ jury_duty_items: nextProps?.jury_duty_history?.results })
    }   
    
    if (this.state.juryModal && nextProps?.get_jury_modal_status?.modal === false) {
      this.setState({ juryModal: false, showJuryResult: false, review:'' })
    }
    if ( this.state.disputeModal && nextProps?.get_dispute_modal_status?.modal === false) {
      this.setState({showJuryModal: false, disputeModal: false, review:'' })
    }
  }


  componentDidMount() {
    this.props.get_jury_duty_history({ type: 'juryDutyRequests' })
  }

  handleChangeTab = (tab) => {
    this.props.get_jury_duty_history({ type: tab })
  }

  handleJuryResult = (review) => {
    this.setState({ juryModal: true, showJuryResult: true, review: review })
  }

  handleCheckJuryduty = (review) => {
    this.setState({ showJuryModal : true, disputeModal: true, review: review})
  }
 
  //end the post
  render() {
    let {
      viewJuryDutyType,
      jury_duty_items,
      showJuryResult,
      review,
      showJuryModal
    } = this.state;    
    
    // const responsive = {
    //   videoModal: {
    //     mobile: {
    //       breakpoint: { max: 9999999, min: 0 },
    //       items: 1,
    //     },
    //   },
    //   viewGalleryImages: {
    //     desktop: {
    //       breakpoint: { max: 9999999, min: 1200 },
    //       items: 3,
    //     },
    //     tablet: {
    //       breakpoint: { max: 1199, min: 576 },
    //       items: 2,
    //     },
    //     mobile: {
    //       breakpoint: { max: 575, min: 0 },
    //       items: 1,
    //     },
    //   },
    // };

    return (
      <div>
        { showJuryResult ?     
         <JuryResult review={review}  /> 
         :
         ''
      }
      { showJuryModal ?     
          <DisputeJuryDuty review={review} /> 
          :
          ''
        }
        <ButtonGroup className="flex-wrap type-filter" size="sm">
          <div className="item d-flex align-items-center">
            <Button
              color="transparent"
              className="text-nowrap"
              onClick={() => {this.setState({ viewJuryDutyType: 'juryDutyRequests' }); this.handleChangeTab('juryDutyRequests') }}
              active={this.state.viewJuryDutyType === 'juryDutyRequests'}>
              {`Jury Duty Requests`}
            </Button>
            {/* If count greater than zero */}
            
          </div>
          <div className="item d-flex align-items-center">
            <Button
              color="transparent"
              className="text-nowrap"
              onClick={() => {this.setState({ viewJuryDutyType: 'juryDutyInProgress' }); this.handleChangeTab('juryDutyInProgress') } }
              active={this.state.viewJuryDutyType === 'juryDutyInProgress'}>
              {`Jury Duty In Progress`}
            </Button>
            {/* If count greater than zero */}
            
          </div>
          <div className="item d-flex align-items-center">
            <Button
              color="transparent"
              className="text-nowrap"
              onClick={() => {this.setState({ viewJuryDutyType: 'juryDutyResults' }); this.handleChangeTab('juryDutyResults') } }
              active={this.state.viewJuryDutyType === 'juryDutyResults'}>
              {`Jury Duty Results`}
            </Button>
            {/* If count greater than zero */}
            {/* {3 ? <span className="count">{result_count}</span> : ''} */}
          </div>
        </ButtonGroup>

        <div>
          {/* Repeat this for Jury Duty Requests */}
          { viewJuryDutyType === 'juryDutyRequests' ?
            jury_duty_items?.length > 0 ? 
            jury_duty_items?.map((item) => {
              return ( 
                <div className="mt-3">
                  <div className="bg-warning text-dark p-2 fs-14">
                    <div className="d-flex flex-wrap mx-n2">
                    <div className="text-white px-2">
                      <span>{" Review Flagged as "}</span>
                      <span className="font-weight-bold">
                        " {item?.flag_name} "
                      </span>
                      <span>{" on "}</span>
                      <span className="font-weight-bold"> 
                        <Moment format="DD MMM YYYY">{item?.created_on}</Moment>
                      </span>
                    </div>
                      <div className="fs-14 px-2 ml-auto text-black">
                        {/* <p className="mb-0 d-inline-block">
                        This review is currently being Disputed.
                      </p>{" "} */}
                        <span
                          className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                          role="button"
                          onClick=""
                        >
                          <span>Learn More</span>
                          <div
                            className="on-hover-appear invisble-part"
                            style={{ bottom: "calc(100% + 10px)" }}
                          >
                            <div className="tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                              <div className="fs-14 ff-base">
                                {/* Please watch the video to  */}
                              See how Review Disputes work
                            </div>
                              <div className="p-2">
                                <Row className="justify-content-between align-items-center">
                                  <Col
                                    xs="12"
                                    className="d-flex justify-content-center align-items-center"
                                  >
                                    <div
                                      className="video-holder play-icon d-inline-block mx-2"
                                      role="button"
                                      onClick=""
                                    >
                                      <img
                                        height="80"
                                        src={require("../../../assets/images/dispute_jury.webp")}
                                        alt="dispute"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                          </div>
                        </span>
                        <span>{" "}&middot;{" "}</span>
                        <span
                          className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                          role="button"
                          onClick={()=>this.handleCheckJuryduty(item.review_id)}
                        >
                          <span className="flashing">Help & Cast your vote <FontAwesomeIcon icon="arrow-right" /> </span>
                          <div
                            className="on-hover-appear"
                            style={{ bottom: "calc(100% + 10px)" }}
                          >
                            <div className="tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                              Help the community and vote on this review
                          </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white p-3 fs-14 text-dark">
                      <div className="d-flex mx-n2 mb-2">
                      <div className="px-2">
                          <a href="#" className="text-decoration-none">
                          <img
                              className="img-circle _50x50"
                              src={item.review_details && item?.review_details[0]?.created_user_media?.user?.user?.current_profile_pic ? item.review_details && item?.review_details[0]?.created_user_media?.user?.user?.current_profile_pic  : require("../../../assets/images/icons/user-circle.png")}
                              alt=""
                          />
                          </a>
                      </div>
                      <div className="px-2 flex-grow-1">
                          <div className="px-2 flex-grow-1">
                          <div className="ff-alt">
                              <a
                              href={`/people/${item.review_details && item?.review_details[0]?.created_by?.vanity_url}`}
                              className="text-reset ff-base font-weight-bold"
                              >
                              {item.review_details && item?.review_details[0]?.created_by?.user?.first_name}  {item.review_details && item?.review_details[0]?.created_by?.user?.last_name}
                              </a>
                              <span> wrote a review for </span>
                              <Link
                              to={{
                                  pathname: `/${item.review_details && item?.review_details[0]?.associated_to?.content_type?.category?.toLowerCase()}/${
                                    item.review_details && item?.review_details[0]?.associated_to?.slug
                                      ? item.review_details && item?.review_details[0]?.associated_to?.slug
                                      : "null"
                                  }`,
                                  state: { id: item.review_details && item?.review_details[0]?.associated_to?.id },
                              }}
                              className="text-reset font-weight-bold"
                              >
                              {item.review_details && item?.review_details[0]?.associated_to?.name}
                              </Link>{" "}
                              and gave it
                              <br />
                              <Ratings
                              ratings={item.review_details && item?.review_details[0]?.ratings_sets }
                              />
                              <span> {item.review_details && item?.review_details[0]?.review_date_time}</span>
                          </div>
                          </div>
                      </div>
                      </div>
                      <div className="ff-alt my-4">
                      <i className="icon-comma-left"></i>
                      <span className="comment-body-renderer">
                          {" "}
                          {item.review_details && item?.review_details[0]?.review_text}
                      </span>
                      <i className="icon-comma-right"></i>
                      </div>
                      
                      
                      <div className="mb-3">
                            <ReactRespCarousel className="rr-carousel" infiniteLoop={true} showArrows={true} showStatus={false} showIndicators={false}>
                                {item.review_details && item?.review_details[0]?.reviewmedia_set?.length > 0 ? (
                                    item.review_details && item?.review_details[0]?.reviewmedia_set.map((data, index1) => {                                            
                                            return (
                                                <div key={index1}>
                                                    {data.type === "image" ?
                                                        <img width="100" src={data.url} alt=""/>
                                                    :
                                                        data.type === "video" ?
                                                        <video width="100%" controls>
                                                            <source src={data.url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            );
                                        
                                    })
                                ) :
                                    ''
                                }
                            </ReactRespCarousel>                                   
                            </div>

                  </div>

                </div>
              )
            })
            :
              <div className="bg-white text-dark p-3 fs-14">
                  <div className="ff-headings fs-20">There are no current Jury Duty Requests for you</div>
              </div>  
          :
          ''
          }
          {/* Repeat this for Jury Duty In Progress */}
          { viewJuryDutyType === 'juryDutyInProgress' ?

            jury_duty_items?.length > 0 ? 
            jury_duty_items?.map((item) => {
              return ( 
                <div className="mt-3">
                <div className="bg-warning text-dark p-2 fs-14">
                  <div className="d-flex flex-wrap mx-n2">
                  <div className="text-white px-2">
                      <span>{" Review Flagged as "}</span>
                      <span className="font-weight-bold">
                        " {item?.flag_name} "
                      </span>
                      <span>{" on "}</span>
                      <span className="font-weight-bold"> 
                        <Moment format="DD MMM YYYY">{item?.created_on}</Moment>
                      </span>
                    </div>
                    <div className="fs-14 px-2 ml-auto text-black text-right">
                      <span className="font-weight-bold">{" You Voted for Review to "} " {item.my_vote} " {" on "}{" "} {" 16 Feb 2021 "} </span>
                      <span className="font-weight-bold">{" "}&middot;{" "}</span>
                      <span className="font-weight-bold">{" Status: "}{" "}<span className="flashing">{" Jury is still voting "}</span></span>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-3 fs-14 text-dark">
                  <div className="d-flex mx-n2 mb-2">
                  <div className="px-2">
                      <a href="#" className="text-decoration-none">
                      <img
                          className="img-circle _50x50"
                          src={item.review_details && item?.review_details[0]?.created_user_media?.user?.user?.current_profile_pic ? item.review_details && item?.review_details[0]?.created_user_media?.user?.user?.current_profile_pic  : require("../../../assets/images/icons/user-circle.png")}
                          alt=""
                      />
                      </a>
                  </div>
                  <div className="px-2 flex-grow-1">
                      <div className="px-2 flex-grow-1">
                      <div className="ff-alt">
                          <a
                          href={`/people/${item.review_details && item?.review_details[0]?.created_by?.vanity_url}`}
                          className="text-reset ff-base font-weight-bold"
                          >
                          {item.review_details && item?.review_details[0]?.created_by?.user?.first_name}  {item.review_details && item?.review_details[0]?.created_by?.user?.last_name}
                          </a>
                          <span> wrote a review for </span>
                          <Link
                          to={{
                              pathname: `/${item.review_details && item?.review_details[0]?.associated_to?.content_type?.category?.toLowerCase()}/${
                                item.review_details && item?.review_details[0]?.associated_to?.slug
                                  ? item.review_details && item?.review_details[0]?.associated_to?.slug
                                  : "null"
                              }`,
                              state: { id: item.review_details && item?.review_details[0]?.associated_to?.id },
                          }}
                          className="text-reset font-weight-bold"
                          >
                          {item.review_details && item?.review_details[0]?.associated_to?.name}
                          </Link>{" "}
                          and gave it
                          <br />
                          <Ratings
                          ratings={item.review_details && item?.review_details[0]?.ratings_sets }
                          />
                          <span> {item.review_details && item?.review_details[0]?.review_date_time}</span>
                      </div>
                      </div>
                  </div>
                  </div>
                  <div className="ff-alt my-4">
                  <i className="icon-comma-left"></i>
                  <span className="comment-body-renderer">
                      {" "}
                      {item.review_details && item?.review_details[0]?.review_text}
                  </span>
                  <i className="icon-comma-right"></i>
                  </div>
                  
                  
                  <div className="mb-3">
                            <ReactRespCarousel className="rr-carousel" infiniteLoop={true} showArrows={true} showStatus={false} showIndicators={false}>
                                {item.review_details && item?.review_details[0]?.reviewmedia_set?.length > 0 ? (
                                    item.review_details && item?.review_details[0]?.reviewmedia_set.map((data, index1) => {                                            
                                            return (
                                                <div key={index1}>
                                                    {data.type === "image" ?
                                                        <img width="100" src={data.url} alt=""/>
                                                    :
                                                        data.type === "video" ?
                                                        <video width="100%" controls>
                                                            <source src={data.url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            );
                                        
                                    })
                                ) :
                                    ''
                                }
                            </ReactRespCarousel>                                   
                            </div>

                  </div>
              </div>            
              ) 
            })
            :
              <div className="bg-white text-dark p-3 fs-14">
                <div className="ff-headings fs-20">There are no current Jury Duty Requests for you</div>
              </div>
          :
          ''
          }
          {/* Repeat this for Jury Duty Results */}
          { viewJuryDutyType === 'juryDutyResults' ?
              jury_duty_items?.length > 0 ? 
                jury_duty_items?.map((item) => {
                return ( <div className="mt-3">
                <div className="bg-warning text-dark p-2 fs-14">
                  <div className="d-flex flex-wrap mx-n2">
                    <div className="text-white px-2">
                      <span>{" Review Flagged as "}</span>
                      <span className="font-weight-bold">
                        " {item?.flag_name} "
                      </span>
                      <span>{" on "}</span>
                      <span className="font-weight-bold"> 
                        <Moment format="DD MMM YYYY">{item?.created_on}</Moment>
                      </span>
                    </div>
                    <div className="fs-14 px-2 ml-auto text-black text-right">
                      <span className="font-weight-bold">{" Jury's Final Decision: On "}{" "}
                      <Moment format="DD MMM YYYY">{item?.resulted_on}</Moment> </span>
                      <br/>
                      <span className="font-weight-bold">{" Outcome: "} <span className="font-weight-normal">{" Jury voted to mark review as "}</span> <span className="">{" Not Recommended "}</span></span>
                      <span className="font-weight-bold">{" "}&middot;{" "}</span>
                      <span>{" View Detailed Jury Duty Results "} <span className="font-weight-bold hover-underline" role="button" onClick={() => this.handleJuryResult(item.review_id)}> {"HERE."}</span></span>
                    </div>
                  </div>
                </div>
                {/* <div className="bg-white text-dark p-3 fs-14">
                    <div className="ff-headings fs-20">Disputed Review Content</div> 
                  </div>
                */}          
                  <div className="bg-white p-3 fs-14 text-dark">
                      <div className="d-flex mx-n2 mb-2">
                      <div className="px-2">
                          <a href="#" className="text-decoration-none">
                          <img
                              className="img-circle _50x50"
                              src={item.review_details && item?.review_details[0]?.created_user_media?.user?.user?.current_profile_pic ? item.review_details && item?.review_details[0]?.created_user_media?.user?.user?.current_profile_pic  : require("../../../assets/images/icons/user-circle.png")}
                              alt=""
                          />
                          </a>
                      </div>
                      <div className="px-2 flex-grow-1">
                          <div className="px-2 flex-grow-1">
                          <div className="ff-alt">
                              <a
                              href={`/people/${item.review_details && item?.review_details[0]?.created_by?.vanity_url}`}
                              className="text-reset ff-base font-weight-bold"
                              >
                              {item.review_details && item?.review_details[0]?.created_by?.user?.first_name}  {item.review_details && item?.review_details[0]?.created_by?.user?.last_name}
                              </a>
                              <span> wrote a review for </span>
                              <Link
                              to={{
                                  pathname: `/${item.review_details && item?.review_details[0]?.associated_to?.content_type?.category?.toLowerCase()}/${
                                    item.review_details && item?.review_details[0]?.associated_to?.slug
                                      ? item.review_details && item?.review_details[0]?.associated_to?.slug
                                      : "null"
                                  }`,
                                  state: { id: item.review_details && item?.review_details[0]?.associated_to?.id },
                              }}
                              className="text-reset font-weight-bold"
                              >
                              {item.review_details && item?.review_details[0]?.associated_to?.name}
                              </Link>{" "}
                              and gave it
                              <br />
                              <Ratings
                              ratings={item.review_details && item?.review_details[0]?.ratings_sets }
                              />
                              <span> {item.review_details && item?.review_details[0]?.review_date_time}</span>
                          </div>
                          </div>
                      </div>
                      </div>
                      <div className="ff-alt my-4">
                      <i className="icon-comma-left"></i>
                      <span className="comment-body-renderer">
                          {" "}
                          {item.review_details && item?.review_details[0]?.review_text}
                      </span>
                      <i className="icon-comma-right"></i>
                      </div>
                      
                      
                      <div className="mb-3">
                      <ReactRespCarousel className="rr-carousel" infiniteLoop={true} showArrows={true} showStatus={false} showIndicators={false}>
                          {item.review_details && item?.review_details[0]?.reviewmedia_set?.length > 0 ? (
                              item.review_details && item?.review_details[0]?.reviewmedia_set.map((data, index1) => {                                            
                                      return (
                                          <div key={index1}>
                                              {data.type === "image" ?
                                                  <img width="100" src={data.url} alt=""/>
                                              :
                                                  data.type === "video" ?
                                                  <video width="100%" controls>
                                                      <source src={data.url} type="video/mp4" />
                                                      Your browser does not support the video tag.
                                                  </video>
                                                  :
                                                  null
                                              }
                                          </div>
                                      );
                                  
                              })
                          ) :
                              ''
                          }
                      </ReactRespCarousel>                                   
                      </div>

                  </div>





                </div>
                )
                  })
              :
              <div className="bg-white text-dark p-3 fs-14">
                <div className="ff-headings fs-20">There are no current Jury Duty Requests for you</div>
              </div>         
                  
          :
          ''
          }
        </div>                   
                   
      </div>
    );
  }
}
const mapState = (state) => ({
jury_duty_history: state.dispute.jury_duty_history,
get_jury_modal_status: state.dispute.get_jury_modal_status,
get_dispute_modal_status: state.dispute.get_dispute_modal_status,
});

const mapDispatch = (dispatch) => ({
get_jury_duty: () => dispatch(get_jury_duty()),
get_jury_duty_history: (params) => dispatch(get_jury_duty_history(params)),

});

export default connect(mapState, mapDispatch)(JuryDuty);
