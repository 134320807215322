import axios from "axios";
import { toast } from "react-toastify";
import { setInitialAuth } from '../actions/auth'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setupInterceptors: (store, history) => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {

        if (response.data.code >= 400 && response.data.code <= 500) { // in case response status is 200 and response code is >= 400
          toast.error(response.data.message);
        }

        let dataReturn = {};

        if (typeof (response.data) == "string") {
          dataReturn['data'] = response.data;
        } else {
          dataReturn = response.data;
        }

        if (response?.status) {
          dataReturn['code'] = response?.status;
        }
        return dataReturn;
      },
      function (error) { // in case request error code is >= 400
        //catches if the session ended!
        if (error?.response?.data?.detail == "Invalid token." && error?.response?.status == 401) {
          // toast.error(error?.response?.data?.detail);
          localStorage.clear();
          store.dispatch(setInitialAuth());
          window.location.reload();
          // history.push('/');
          // return 
        }

        if (error?.response?.status) {
          localStorage.setItem('loader', false);
          if (error?.response?.data?.message) { toast.error(error?.response?.data?.message); }
          let dataReturn = {
            data: error?.response?.data,
            code: error?.response?.status
          };
          return Promise.reject(dataReturn);
        }
      }
    );
  }
};
