/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash'
import { indexOf } from 'lodash'
import Interweave from 'interweave';
import React, { Component } from "react";
import { useEmbedly } from "react-contentful-embedly";
import ReactHtmlParser from 'react-html-parser';
import Moment from "react-moment";
import Carousel from 'react-multi-carousel';
import { connect } from "react-redux";
import { Carousel as ReactRespCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import ShareByEmail from '../../atoms/shared/shareByEmail';
import ModalComponent from '../../atoms/modalComponent';
import {
  Button, Col,
  Collapse, DropdownItem, DropdownMenu, DropdownToggle, FormGroup,
  Input,
  InputGroup,
  Label, Media, Modal,
  ModalBody,
  ModalFooter, ModalHeader, Nav,
  NavItem,
  NavLink,
  Progress, Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap";
import StarsRating from "stars-rating";
import {
  add_to_list,
  clear_category,
  clear_subcategory, delete_post_comment, delete_review_comment,
  edit_review,
  fetch_categories_data, fetch_detailedstarrating,
  fetch_sub_categories,
  filter_categories_data, get_business_QA_dropdown, get_business_review_detail,
  get_reaction_comment,
  post_review_group, post_update_comment,
  publish_post, remove_from_list,
  save_ratings_vote, send_email,
  share_by_email
} from "../../../actions/business";
import {
  add_dispute_reply, add_dispute_review,
  delete_dispute,
  delete_dispute_reply, get_dispute_discussion, updateFeedReactions
} from "../../../actions/feeds";
import { embedNewLink } from "../../../actions/messages";
import {
  clear_media_dispatch,
  delete_mutiple_media, fetch_delete_media, fetch_media_data,
  fetch_taxonomy_category,
  fetch_taxonomy_list, media_upload_scroll,
  single_delete_from_upload, upload_add_captions, upload_media_files,
  upload_media_filesData
} from "../../../actions/myProfiles";
import { get_reviewgroup } from "../../../actions/user";
import { callApi } from "../../../utils/apiCaller";
import CollapseBasic from "../../atoms/collapse";
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from "../../atoms/editBtn";
import { ConfirmationModal } from "../../molecules/homepage/ConfirmationModal";
import Reactions from "../../molecules/homepage/Reactions";
import WebCamRecording from '../../oragnisms/businessDetails/webCamRecording';
import { toast } from "react-toastify";
import Loaderimg from "../../../assets/images/w-brand-black.jpg";
import { Ratings } from "../../molecules/homepage/ShowRatings";
import { Link } from "react-router-dom";
import DisputingReview from "../../atoms/disputeReview/disputingReview";
import DisputedReview from "../../atoms/disputeReview/disputedReview";
import RatingCollapse from "../../atoms/ratingCollapse";
import { get } from "lodash";
import Rating from "react-rating";
import { checkTextVisibilty, detectlink, getShareReviewURL, mediaThumbnail } from "../../../utils/utilities";
import Embed from "react-embed";
import { get_category_taxonomy_listing, update_caption_and_categories } from '../../../actions/itemDetail';
import SearchBar from "../../atoms/searchBar";
import UserInfoAvatar from "../../atoms/reviewTabItems/userInfoAvatar";



const group_icons = 'https://stagingdatawikireviews.s3.amazonaws.com/images/group-icon.png';

const empty_star_rating = (<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "34px" }}>★</span>);

const full_star_rating = (<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "34px" }}>★</span>);

const empty_star_rating_below = (<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "24px" }}>★</span>);

const full_star_rating_below = (<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "24px" }}>★</span>);


const ComponentNew = (props) => {
  const option = useEmbedly({
    align: "left",
    key: "86995e5d79b242c5b34610989d303d90",
  });
  const documentIncludesEmbedly = {
    nodeType: "document",
    data: {},
    content: [
      {
        nodeType: "paragraph",
        data: {},
        content: [
          {
            nodeType: "text",
            value: `Embedded content: ${props.embedData}`,
            marks: [],
            data: {},
          },
        ],
      },
    ],
  };

  let result = documentToReactComponents(documentIncludesEmbedly, option);

  if (result && Array.isArray(result) && result.length) {
    let mainKey = result[0];
    if (Object.keys(mainKey).length > 1) {
      return <div className="set-item-embed">{result}</div>;
    } else {
      return null;
    }
  }
};

const responsive = {
  videoModal: {
    mobile: {
      breakpoint: { max: 9999999, min: 0 },
      items: 1,
    },
  },
  viewGalleryImages: {
    desktop: {
      breakpoint: { max: 9999999, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  },
};

class ClientReview extends Component {
  constructor(props) {
    super(props);
    this.myScrollPhotoRef = React.createRef();
    this.myScrollVideoRef = React.createRef();

    this.refGalleryVideoScroll = React.createRef();
    this.refGalleryImageScroll = React.createRef();
    this.state = {
      commentId: "",
      commentMessage: "",
      filter: "new",
      host: window.location.host,
      protocol: window.location.protocol,
      shareTitle:
        "Log in or sign up for WikiReviews to view and rate my Community Review Project!",
      shareVia: "WikiReviews",
      fbHashTag: "#WikiReviews",
      twitterHashTags: ["WikiReviews"],
      shareByEmailModalToggle: false,
      shareByEmailId: "",
      isDeleteCommentModalOpen: false,
      deleteIdComment: "",
      sharer_name: "",
      email: "",
      commentMessageAdd: "",
      commentMessageOuter: "",
      shareError: {},
      shareId: 0,
      writeFollowUp: false,
      followUPReviewID: "",
      followUPAssociatedTO: "",
      followUPReview: "",
      followUPReviewVotingKey: "",
      followUPRating_sets: [],
      avg_rating: 0,
      avg_rating_edit: 0,
      businessId: this.props.businessId,
      deletereviewId: 0,
      reviewId: 0,
      confirmDeleteModal: false,
      write_new_review: "",
      reviewAnonymous: false,
      shocialNetworkSharing: false,
      mediaSet: [],
      mediaSetReview: [],
      mediaSetCompose: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      myProfile: null,
      searchTheater: "",
      fetchPhotos: {},
      fetchPhotosCount: 0,
      fetchVideos: {},
      fetchVideosCount: 0,
      albumType: "",
      fetchTaxonomy: [],
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      fetchGalleryType: "image",
      fetchGalleryAlbum: "",
      selectedFilesPreview: [],
      fetchDeletedData: {},
      fetchDeleteCount: 0,
      latestUploadedData: {},
      uploadCaptions: {},
      dataWantsToDelete: [],
      limitedPostsModal: false,
      uploadMediaModal: false,
      uploadMediaPostModal: false,
      isModalTrue: false,
      modalText: null,
      modalType: null,
      modalSelected: null,
      itemsAttachment: [],
      itemsIndex: 1,
      showVideoMedia: true, // video option toggle
      businessRatingList: [],
      businessRatingListEdit: [],
      reviewsCategories: [],
      reviewName: "",
      social_broadcast: false,
      social_broadcast_facebook: false,
      social_broadcast_twitter: false,
      isadhere: true,
      businessRatingIds: [],
      followAverageError: "",
      followName: "",
      mode: "",
      taxonomycategoryId: "",
      fetchTaxonomyListingImages: {},
      fetchTaxonomyListingVideos: {},
      categoryType: "",
      captionData: "",
      uploadedId: {},
      isImageType: false,
      businseesId: 0,
      cattaxListing: {},
      editReveiw: null,
      albumCategoryId: "",
      imageAttacmentnew: "",

      voteReviewModalToggleOne: false,
      voteReviewModalToggleTwo: false,
      voteReviewModalToggleThree: false,
      voteReviewModalToggleFour: false,
      voteReviewModalToggleFive: false,
      voteReviewModalToggleSix: false,
      voteReviewModalToggleFinal: false,
      limitedDisputesModalToggle: false,
      historyLogModalToggle: false,
      patrolReviewModalToggleOne: false,

      all_disputes_list: [],
      disputed_tab: false,
      user_names_list: [],
      get_dispute_data: [],
      showExplanation: false,
      disputeReviewId: "",
      showReply: false,

      dispute_files: [],
      dispute_imgepreview: [],

      dispute_reply_text: "",
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      dropdownSortBy: false,

      reviewAdded: "",
      reviewAddedUser: "",
      reviewAddedUserImage: "",
      reviewAddedDescription: "",
      reviewAddedFor: "",
      reviewAddedOn: "",
      can_vote: false,
      is_media_dispute: false,
      timer_started: false,
      currentActiveIndex: 0,
      is_administrative_review: false,
      isLoading: false,
      confirmationToggle: false,
      confirmationReason: '',

      showDisputeModal: false,
      reviewDetails: null,
      showDisputedModal: false,
      disputeModal: false,
      reason: '',
      errors: {},
      categoryItemQueryParams: {
        object_pk: props?.productId,
        content_type: 'reviews.userentries',
        key: 'avg_rating',
      },
      avgRateKey: null,
      enableHoverBanIcon: false,
      forceShowLoginModal: false,
      viewEditReviewStep: 'writeReview',
      embedBody: [],
      removedMediaItem: [],
      uploadedCat: {},
      rateMenuContent: [],
      activeMenuType: 'mainMenu',
      menuMediaListMedia: {},
      uploadType: "",
      mediaSetMenu: [],
      clickedOnWriteAReview: -1,
      stepTwoUploadedVideo: [],
      enableErrorForReview: true,
      writeAReviewTextVideo: "",
      showRevTips: false,
    };
  }

  toggleCategory(item) {
    this.setState((prevState) => ({
      [item]: !prevState[item],
    }));
  }

  onMouseEnterCategory(item) {
    this.setState({ [item]: true });
  }

  onMouseLeaveCategory(item) {
    this.setState({ [item]: false });
  }

  fetchDataFromHtmlForm = async (type, formID) => {
    const { businessId } = this.props;
    let url =
      type === "edit"
        ? `/form/reviewedit/business/${businessId}/${formID}`
        : `/form/followup/business/${businessId}?followup=true`;
    try {
      const response = await callApi(url, "GET");
      if (response?.code == 200) {
        let data = response?.data;
        if (data) {
          const doc = new DOMParser().parseFromString(data, "text/html");
          let uniqrate = doc.getElementById("follow_uniqrate").value;
          let csrfmiddlewaretoken = doc.getElementsByName(
            "csrfmiddlewaretoken"
          )[0].value;
          /*let security_hash = doc.getElementsByName("security_hash")[0].value;
          let timestamp = doc.getElementsByName("timestamp")[0].value;
          let honeypot = doc.getElementsByName("honeypot")[0].value;
          let object_pk = doc.getElementsByName("object_pk")[0].value;
          let content_type = doc.getElementsByName("content_type")[0].value;*/

          let security_hash = "";
          let timestamp = Date.now();
          let honeypot = doc.getElementsByName('honeypot')[0].value;
          let object_pk = businessId;
          let content_type = "reviews.userentries";

          let reviewDynamicItems = {};
          reviewDynamicItems.uniqrate = uniqrate;
          reviewDynamicItems.csrfmiddlewaretoken = csrfmiddlewaretoken;
          reviewDynamicItems.security_hash = security_hash;
          reviewDynamicItems.timestamp = timestamp;
          reviewDynamicItems.honeypot = honeypot;
          reviewDynamicItems.object_pk = object_pk;
          reviewDynamicItems.content_type = content_type;

          this.setState({ reviewDynamicItems }, () => {


          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  ratingChanged = async (newRating, name, key, type) => {

    let name_modify = '';
    this.VoteData = true;

    if (name !== "avg_rating") {
      if (name === "avg_rating_edit") {

        if (name == "avg_rating_edit") {
          name_modify = "avg_rating"

          this.setState({ avg_rating_edit: newRating })
        }
      } else {
        name_modify = key
        let tempArray
        if (type === "edit") {
          tempArray = [...this.state.businessRatingListEdit]
        } else {
          tempArray = [...this.state.businessRatingList]
        }
        const ratedCategory = tempArray.map(item => {
          if (item.name === name) {
            return { ...item, key: key, name: name, label: name, value: newRating }
          } else {
            return { ...item, key: item.key, name: item.label, label: item.label, value: item.value, isEnable: false }
          }

        })
        if (type === "edit") {
          this.setState({ businessRatingListEdit: ratedCategory })
        } else {
          this.setState({ businessRatingList: ratedCategory })
        }
      }

    } else {
      name_modify = "avg_rating"
      let filteredReviewCategories = [];

      this.setState({ avg_rating: newRating, enableRating: true, reviewCategoriesList: filteredReviewCategories })

    }


    // testing code

    this.getSecurityHashToken(name_modify, newRating)
  }

  getSecurityHashToken = (keyName, newRating, type) => {
    const url = "/ratings/security_hash/"
    const { categoryItemQueryParams, avgRateKey, mode, followUPReviewVotingKey } = this.state;
    let formDataSecurity = new FormData();

    formDataSecurity.append('object_pk', this.state.businessId);
    formDataSecurity.append('content_type', categoryItemQueryParams.content_type);
    formDataSecurity.append('key', keyName);
    formDataSecurity.append('timestamp', Date.now());
    callApi(url, 'POST', formDataSecurity).then((res) => {
      let formDataVote = new FormData();
      formDataVote.append('csrfmiddlewaretoken', res?.csrfmiddlewaretoken);
      formDataVote.append('score', newRating);
      formDataVote.append('content_type', res?.content_type);
      formDataVote.append('object_pk', res?.object_pk);
      formDataVote.append('key', keyName);
      formDataVote.append('timestamp', res?.timestamp);
      formDataVote.append('security_hash', res?.security_hash);
      if (mode === "followup" || mode === "ownreview") {
        formDataVote.append('rate_unq_key', !!!followUPReviewVotingKey ? res?.rate_unq_key : followUPReviewVotingKey);
      } else {
        formDataVote.append('rate_unq_key', !!!avgRateKey ? res?.rate_unq_key : avgRateKey);
      }

      this.save_ratings_vote(formDataVote, res?.rate_unq_key);


      this.setState((prevState) => {
        return {
          ...prevState,
          categoryItemQueryParams: {
            ...prevState.categoryItemQueryParams,
            csrfmiddlewaretoken: res?.csrfmiddlewaretoken,
            rate_unq_key: res?.rate_unq_key,
            security_hash: res?.security_hash,
            timestamp: res?.timestamp,
          }
        }
      })
      if ((mode === "followup" || mode === "ownreview") && !!!followUPReviewVotingKey) {
        this.setState({ followUPReviewVotingKey: res?.rate_unq_key })
      }
    });
  }
  save_ratings_vote = async (data, rateKey) => {
    const { avgRateKey } = this.state
    let url = '/ratings/vote/';
    try {
      const response = await callApi(url, 'POST', data);
      if (response?.code == 200) {
        if (response.key === "avg_rating") {
          this.setState({ voteScore: response.vote_score, avgRateKey: !!!avgRateKey ? rateKey : avgRateKey })
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleOnClickShareByEmail = (id) => {
    this.setState({
      shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
      sharer_name: "",
      shareByEmailId: "",
      email: "",
      shareError: {},
      shareId: id,
    });
  };

  handleOpenModal(data, url, index) {
    this.setState({
      viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew,
      imageAttacmentnew: data,
      currentActiveIndex: index
    });
  }

  truncate = (filenameString) => {
    // let split = filenameString.split(".");
    if (filenameString) {
      let filename = filenameString?.substr(
        0,
        filenameString?.lastIndexOf(".")
      );
      let extension = filenameString.substr(
        filenameString.lastIndexOf("."),
        filenameString.length - 1
      );
      let partial = filename.substring(filename.length - 3, filename.length);
      filename = filename.substring(0, 15);
      return filename + "..." + partial + extension;
    }

    return null;
  };

  componentDidMount() {
    this.filterReviewFunction('');
    this.props.clear_category();
    this.props.clear_subcategory();
    // const {} = this.state
  }

  componentWillReceiveProps(nextProps) {
    let {
      fetchVideos,
      fetchGalleryScroll,
      fetchDeletedData,
      latestUploadedData,
      fetchPhotos,
      disputeThisReviewModalToggle
    } = this.state;

    if (nextProps.add_dispute_status) {
      if (disputeThisReviewModalToggle) {
        this.UpdateReviewList('new')
        this.setState({ disputeThisReviewModalToggle: false })
      }
    }

    if (nextProps?.cat_taxonomy_list &&
      Array.isArray(nextProps?.cat_taxonomy_list) &&
      nextProps?.cat_taxonomy_list?.length && nextProps.typeUpload) {
      let cattaxListing = [];
      nextProps.cat_taxonomy_list.forEach(item => {
        if (item.category === nextProps.typeUpload || item.category?.toLowerCase() === nextProps?.mainTaxonomy?.toLowerCase()) {
          cattaxListing = cattaxListing.concat(item.children);
        }
      })
      this.setState({ cattaxListing });
    }

    if (
      nextProps.get_dispute_data &&
      Object.keys(nextProps.get_dispute_data).length > 0
    ) {
      this.setState({
        get_dispute_data: nextProps.get_dispute_data.results,
        dispute_reply_text: "",
        dispute_files: [],
        dispute_imgepreview: [],
      });
      if (!this.state.timer_started) {
        this.timerSettings();
        this.setState({ timer_started: true })
      }
      this.setState({ isLoading: false })
    }

    if (nextProps.fetch_photos) {
      let item = nextProps.fetch_photos;
      if (item.album) {
        fetchPhotos = [];
      }
      fetchPhotos[item.page] = item;
      this.setState({
        fetchPhotos,
        fetchPhotosCount: item?.count,
        activeMyPhotoIndex: 0,
      });
    }

    if (nextProps.fetch_videos) {
      let item = nextProps.fetch_videos;
      if (item.album) {
        fetchVideos = [];
      }
      fetchVideos[item.page] = item;
      this.setState({
        fetchVideos,
        fetchVideosCount: item?.count,
        activeMyPhotoIndex: 0,
      });
    }

    if (nextProps.fetch_taxonomy) {
      let taxCat = nextProps.fetch_taxonomy;
      if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
        taxCat = taxCat.filter((dt) => dt.label !== null);
      }
      this.setState({ fetchTaxonomy: taxCat });
    }

    if (nextProps.fetch_deleted_data) {
      let item = nextProps.fetch_deleted_data;
      if (!item.type) {
        fetchDeletedData = {};
      }
      fetchDeletedData[item.page] = item;
      this.setState({
        fetchDeleteCount: item.count,
        fetchDeletedData,
        activeMyPhotoIndex: 0,
      });
    }

    if (nextProps.upload_scroll) {
      let item = nextProps.upload_scroll;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      this.setState({
        fetchGalleryScroll,
        fetchGalleryCount: item.count,
        activeMyPhotoIndex: 0,
      });
    }

    if (nextProps.upload_media_data) {
      let data = nextProps.upload_media_data;
      if (data.index !== undefined) {
        let idx = data.index;
        latestUploadedData[idx] = data;
        this.setState({ latestUploadedData });
      }
    }

    if (
      nextProps.qa_dropdown &&
      nextProps.qa_dropdown.res &&
      Array.isArray(nextProps.qa_dropdown.res) &&
      nextProps.qa_dropdown.res.length > 0
    ) {
      this.setState({ reviewsCategories: nextProps.qa_dropdown });
    }

    if (nextProps.fetch_data_categories) {
      let {
        fetchTaxonomyListingImages,
        fetchTaxonomyListingVideos,
      } = this.state;
      let taxCatListing = nextProps.fetch_data_categories;
      if (
        taxCatListing &&
        Array.isArray(taxCatListing) &&
        taxCatListing.length > 0
      ) {
        if (taxCatListing[0].isTypes == "image") {
          if (taxCatListing[0].index !== undefined) {
            let idx = taxCatListing[0].index;
            fetchTaxonomyListingImages[idx] = taxCatListing[0].children
              ? taxCatListing[0].children
              : [];
            this.setState({ fetchTaxonomyListingImages });
          }
        } else {
          if (taxCatListing[0].index !== undefined) {
            let idx = taxCatListing[0].index;
            fetchTaxonomyListingVideos[idx] = taxCatListing[0].children
              ? taxCatListing[0].children
              : [];
            this.setState({ fetchTaxonomyListingVideos });
          }
        }
      }
    }

    // if (nextProps.filter_data_category) {
    //   let taxCatList = nextProps.filter_data_category;
    //   if (taxCatList && Object.keys(taxCatList).length > 0) {
    //     //taxCatListing = taxCat.filter(dt => dt.label !== null);
    //     this.setState({
    //       cattaxListing: taxCatList[0] ? taxCatList[0].children : [],
    //     });
    //   }
    // }

    if (this.state.disputeModal && nextProps?.get_dispute_modal_status?.modal === false) {
      this.setState({ disputeModal: false, showDisputeModal: false, reviewDetails: '', showDisputedModal: false })
      if (nextProps?.get_dispute_modal_status?.refresh) {
        this.filterReviewFunction('');
      }
    }

    if (this.state.stepTwoUploadedVideo?.length > 0) {
      this.setState({ enableErrorForReview: false })
    }
  

  }

  filterReviewFunction = (filter) => {
    const { businessId, get_business_review_detail } = this.props;
    this.setState({ filter }, async () => {
      await get_business_review_detail(businessId, filter);
    });

  };

  filterReviewBySearchText = (value) => {
    const { businessId, get_business_review_detail } = this.props;
    this.setState({
      searchResultsEnabled: value?.length ? true : false,
      filter: ''
    }, async () => {
      await get_business_review_detail(businessId, this.state.filter, value);
    });

  };

  updateCommentFunction = (data, id) => {
    this.setState({
      commentId: data.id,
      commentMessage: data.body,
      commentReview: id,
    });
  };

  deleteCommentFunction = async (id) => {
    this.setState({
      deleteIdComment: id,
      isDeleteCommentModalOpen: true,
    });
  };

  handleChange = (e) => {
    let errors = { ...this.state.shareError };
    if (e.target.value) {
      delete errors[e.target.name];
    }

    this.setState({
      [e.target.name]: e.target.value,
      shareError: errors,
    });
  };

  updateComments = async (status, id = null) => {
    const {
      post_update_comment,
      businessId,
      get_business_review_detail,
    } = this.props;
    const {
      commentId,
      commentReview,
      commentMessage,
      filter,
      commentMessageAdd,
      commentMessageOuter,
    } = this.state;
    let data = {};
    let type;
    if (status === "add") {
      type = "POST";
      data = {
        [id]: commentMessageAdd.length ? commentMessageAdd : commentMessageOuter,
        body: commentMessageAdd.length ? commentMessageAdd : commentMessageOuter,
        media: [],
        review: id,
      };
    } else {
      type = "PUT";
      data = {
        body: commentMessage,
        media: [],
        review: commentReview,
        parent_id: commentId,
      };
    }

    await post_update_comment(type, data);
    await get_business_review_detail(businessId, filter);
    await this.closeComments();
  };
  closeComments = () => {
    this.setState({
      commentId: "",
      commentMessage: "",
      commentReview: "",
      commentMessageAdd: "",
      commentMessageOuter: ""
    });
  };

  handleFeedReactions = (reaction, id) => {
    const params = {
      type: reaction,
      review: id,
    };
    this.props.updateFeedReactions(params);
  };

  handleCancelDeleteComment = () => {
    this.setState({
      commentIdToDelete: null,
      commentEntityToDelete: null,
      isDeleteCommentModalOpen: false,
      deleteIdComment: "",
    });
  };

  handleDeleteComment = async () => {
    const {
      delete_review_comment,
      businessId,
      get_business_review_detail,
    } = this.props;
    const { filter, deleteIdComment } = this.state;
    await delete_review_comment(deleteIdComment);
    await get_business_review_detail(businessId, filter);
    await this.setState({
      commentIdToDelete: null,
      commentEntityToDelete: null,
      isDeleteCommentModalOpen: false,
      deleteIdComment: "",
    });
  };

  handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote }) => {
    const key = 'listing_id';
    const url = `/api/shareByemail/`;
    let review_id = this.state.shareId;
    let data;
    if (review_id) {
      data = {
        review_id: review_id,
        mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
        email: shareEmail,
        user_name: shareName,
        listing_type: this.props.categoryType,
        optional_note: shareNote
      };
    } else {
      data = {
        [key]: this.state.shareId,
        mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
        email: shareEmail,
        user_name: shareName,
        listing_type: this.props.categoryType,
        optional_note: shareNote
      };
    }

    this.props.share_by_email(url, data);
  }

  // handleSubmit = async () => {
  //   console.log("Came inside this function")
  //   let errors = { ...this.state.shareError };

  //   if (this.state.sharer_name === "") {
  //     errors.sharer_name = "This field is required!";
  //   }

  //   if (this.state.email === "") {
  //     errors.email = "This field is required!";
  //   } else {
  //     const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //     if (!reg.test(String(this.state.email).toLowerCase())) {
  //       errors.email = "Please enter a valid email!";
  //     }
  //   }

  //   if (errors && Object.keys(errors).length > 0) {
  //     this.setState({ shareError: errors });
  //   } else {
  //     // let url = `/api/shareByemail/?review_id=${this.state.shareId}&mix_uid=${this.state.email.split("@")[0]
  //     //   }_${this.state.shareId}_1597643911786&email=${this.state.email
  //     //   }&user_name=${this.state.sharer_name}&listing_type=business`;
  //     let key = 'review_id';
  //     let url = `/api/shareByemail/`;

  //     const data = {
  //       [key]: this.state.shareId,
  //       mix_uid: `${this.state?.email?.split('@')[0]}_${this.state?.shareId}_1597643911786`,
  //       email: this.state.email,
  //       user_name: this.state.shareName,
  //     };

  //     this.props.share_by_email(url, data);

  //     this.setState({
  //       shareByEmailModalToggle: false,
  //       shareName: "",
  //       email: "",
  //       shareError: {},
  //     });
  //   }
  // };

  addToList = async (type, id, status) => {
    let message = "",
      url = "",
      nextUrl = "";
    if (status) {
      message = `${this.props.businessDetails?.name} has been removed from your ${type}!`;
      url = `/api/pins/${id}/?type=${type}&review=true`;

      await this.props.remove_from_list(url, message, nextUrl);
      await this.props.get_business_review_detail(
        this.props.businessId,
        this.state.filter
      );
    } else {
      url = `/api/pins/?type=${type}&user=`;
      let data = { type };

      data.review = id;
      message = `${this.props.businessDetails?.name} has been added to your ${type}!`;
      await this.props.add_to_list(url, data, message, nextUrl);
      await this.props.get_business_review_detail(
        this.props.businessId,
        this.state.filter
      );
    }
  };

  handleChildReviewEdit = (item, revId) => {
    const { mediaSetReview, embedBody } = this.state;

    let reviewtext = item.review.replace(/(<([^>]+)>)/gi, "");
    let inputLength = reviewtext.length;

    item.reviewmedia_set.forEach((evt) => {
      mediaSetReview.push({
        id: evt.id,
        file: evt.url,
        thumbnail: evt.thumbnail,
        url: evt.url,
      });
    });

    if (Array.isArray(item?.embeded_video?.body)) {
      item?.embeded_video?.body.forEach((item) => {
        embedBody.push({
          type: "embedded_link",
          url: item.url,
          thumbnail: item.thumbnail
        });
      })
    }

    this.setState({
      writeReviewModalToggleTwo: true,
      write_new_review: reviewtext,
      reviewId: revId,
      totalCharacter: inputLength,
      mediaSetReview,
      embedBody
    });

  };

  componentDidUpdate(prevProps) {
    if (this.props.business_rating !== prevProps.business_rating) {
      const rating = [];
      Object.keys(this.props.business_rating).forEach((iteam) => {
        let RatingLabel = "";
        this.props.qa_dropdown &&
          this.props.qa_dropdown.res.forEach((element) => {
            if (element.name === iteam) {
              RatingLabel = element.label;
            }
          });
        if (iteam !== "avg_rating" && iteam !== "code") {
          rating.push({
            key: iteam,
            value: null,
            checked: false,
            label: RatingLabel,
          });
        }
      });
      this.setState({
        businessRatingList: rating,
        businessRatingListDefaut: rating,
      });
    }
  }

  ratingChangedForCheck = (checked, data) => {
    if (checked) {
      this.state.businessRatingIds.push(data.id);
    } else {
      let el = this.state.businessRatingIds.find(
        (itm) => itm === parseInt(data.id)
      );
      if (el)
        this.state.businessRatingIds.splice(
          this.state.businessRatingIds.indexOf(el),
          1
        );
    }
  };

  handelChangeReview = (e) => {
    // 'QualityofEquipmentCheck'

    this.setState({
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
      errors: {
        ...this.state.errors,
        write_new_review: ''
      }
    },()=>{    
      
      if (!this.state.isadhere) {
        this.setState({ enableErrorForReview: true })
      }
      if((this.state.write_new_review?.length > 50)){
        this.setState({enableErrorForReview:false})
      }else{
        this.setState({enableErrorForReview:true})
      }
    });
    
  };

  publishPost = async (dataList) => {
    const {
      avg_rating,
      avg_rating_edit,
      mediaSetReview,
      write_new_review,
      businessRatingIds,
      shocialNetworkSharing,
      isadhere,
      reviewId,
      writeFollowUp,
      followUPReviewID,
      followUPAssociatedTO,
      followUPReviewVotingKey,
      mode,
      businseesId,
      removedMediaItem,
      embedBody,
      stepTwoUploadedVideo,
      writeAReviewTextVideo
    } = this.state;
    let errors = { ...this.state.errors };
    let mediaSetDataItems = [];
    if (stepTwoUploadedVideo?.length == 0) {
      if (write_new_review?.length < 50) {
        this.setState({ enableErrorForReview: true });
        return
      }
    }

    if (mediaSetReview?.length) {
      mediaSetDataItems = [...mediaSetDataItems,...mediaSetReview.map(i => i["id"])];
    }

    if (stepTwoUploadedVideo?.length) {
      let itemsGet = stepTwoUploadedVideo.map(data => data.id);
      mediaSetDataItems = mediaSetDataItems.concat(itemsGet);
    }

    // if (!this.state.write_new_review?.length) {
    //   errors.write_new_review = 'This field is required!';
    //   this.setState({ errors }, () => {
    //     console.log({ errors }, '990')
    //   })
    //   return
    // }
    // else {
    //   delete errors.write_new_review;
    // }
    const { edit_review, publish_post } = this.props;
    if (writeFollowUp) {
      if (avg_rating === 0) {
        this.setState({
          followAverageError: "Please provide Overall Rating",
        });
      } else {
        const data = {
          associated_to: followUPAssociatedTO,
          followup: mode == "followup" ? writeFollowUp : false,
          followup_avg_rate: avg_rating,
          is_draft: dataList === "draft",
          // media: mediaSetReview.map((i) => i["id"]),
          media: mediaSetDataItems,
          review: !!writeAReviewTextVideo.length ? writeAReviewTextVideo : write_new_review ,
          review_rating_category: businessRatingIds,
          review_voting_key:
            followUPReviewVotingKey != "" ? followUPReviewVotingKey : "",
          social_broadcast: shocialNetworkSharing == true ? true : false,
          social_broadcast_facebook:
            shocialNetworkSharing == true ? true : false,
          social_broadcast_twitter:
            shocialNetworkSharing == true ? true : false,
          tips_guide: isadhere,
          parent_review_id: followUPReviewID,
        };
        if (embedBody?.length) {
          data['embed_body'] = embedBody.map(item => {
            return {
              url: item.url,
              thumbnail: item.thumbnail
            }
          });
        }
        await publish_post(data, followUPAssociatedTO);
        await this.clearReveiwRatingState();
      }
    } else {
      const ratingset = {};
      ratingset.avg_rating = `${avg_rating_edit}`;

      const data = {
        associated_with: businseesId,
        // ratings_set: ratingset,
        is_draft: dataList === "draft",
        media: mediaSetDataItems,
        review: !!writeAReviewTextVideo.length ? writeAReviewTextVideo : write_new_review ,
        review_rating_category: businessRatingIds,
        //review_voting_key: 'JL5BF6',
        professional: "",
      };
      if (embedBody?.length) {
        data['embed_body'] = embedBody.map(item => {
          return {
            url: item.url,
            thumbnail: item.thumbnail
          }
        });
      }
      if (removedMediaItem.length) {
        await callApi('/upload/multiuploader_delete_multiple/?delete_videos=True', 'POST', removedMediaItem)
      }

      await edit_review(reviewId, data);
      this.clearReveiwRatingState();
    }

    this.setState({ viewEditReviewStep: 'writeReview', embedBody: [], reviewDynamicItems: {}, enableErrorForReview: true, stepTwoUploadedVideo: [] });
  };

  clearReveiwRatingState = () => {
    this.setState({
      writeReviewModalToggleTwo: false,
      enableRating: false,
      avg_rating: 0,
      avg_rating_edit: 0,
      businessRatingList: this.state.businessRatingListDefaut,
      businessRatingListEdit: [],
      write_new_review: "",
      mediaSet: [],
      mediaSetReview: [],
      mediaSetCompose: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      myProfile: null,
      searchTheater: "",
      fetchPhotos: {},
      fetchPhotosCount: 0,
      fetchVideos: {},
      fetchVideosCount: 0,
      albumType: "",
      fetchTaxonomy: [],
      businessRatingIds: [],
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      fetchGalleryType: "image",
      fetchGalleryAlbum: "",
      selectedFilesPreview: [],
      fetchDeletedData: {},
      fetchDeleteCount: 0,
      latestUploadedData: {},
      uploadCaptions: {},
      writeFollowUp: false,
      followUPReviewID: "",
      followUPAssociatedTO: "",
      followUPReview: "",
      followUPReviewVotingKey: "",
      followUPRating_sets: [],
      followAverageError: "",
      followName: "",
      mode: "",
      taxonomycategoryId: "",
      fetchTaxonomyListingImages: {},
      fetchTaxonomyListingVideos: {},
      categoryType: "",
      captionData: "",
      uploadedId: {},
      editReveiw: null,
      avgRateKey: null,
      embedBody: [],
      removedMediaItem: [],
      writeAReviewTextVideo: '',
      showRevTips: false,
    });
  };

  setMediaSetForItems = (e, item) => {

    let { mediaSet, mediaSetMenu, menuMediaListMedia, menuSubTitle, menuTitle, rateMenuContent, uploadType } = this.state;
    if (item && item.id && uploadType !== "menuItem") {
      if (item && item.id) {
        let id = item.id;
        let itemFiltered = mediaSet.find((item) => item.id == id);
        if (itemFiltered) {
          const index = mediaSet.indexOf(itemFiltered);
          if (index > -1) {
            mediaSet.splice(index, 1);
          }
        } else {
          mediaSet.push(item);
        }
        this.setState({ mediaSet, mediaSetReview: mediaSet });
      }
    } else {
      let id = item.id;
      let itemFiltered = mediaSetMenu.find(item => item.id === id);

      if (itemFiltered) {
        const index = mediaSetMenu.indexOf(itemFiltered);
        if (index > -1) {
          mediaSetMenu.splice(index, 1);
        }
      } else {
        mediaSetMenu.push(item)
      }
      this.setState({ mediaSetMenu: [...mediaSetMenu] })

      if (!!menuMediaListMedia[this.state.menuObjectPk]) {
        const collectedMedia = [...menuMediaListMedia[this.state.menuObjectPk], item]
        const uniqueMedia = [...new Map(collectedMedia?.map(item =>
          [item['id'], item])).values()];
        menuMediaListMedia[this.state.menuObjectPk] = uniqueMedia
      } else {
        menuMediaListMedia[this.state.menuObjectPk] = [item]
      }
      let rateMenuContentCopy = _.cloneDeep(rateMenuContent)
      rateMenuContentCopy.filter(item => item.menuItem === menuTitle)[0].menuContent.filter(subItem => subItem.menuItemName === menuSubTitle)[0].menuItemContent[5] = item.thumbnail


      this.setState({ rateMenuContent: rateMenuContentCopy })
    }

  };

  handleCaptionByIndex = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, latestUploadedData, taxonomycategoryId } = this.state;
    uploadCaptions[index] = value;
    let data = {};
    let upload_data = latestUploadedData[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    data.category = taxonomycategoryId;
    let id = upload_data.id;
    this.setState({ uploadCaptions, captionData: caption_data }, () =>
      // this.props.upload_add_captions(id, data)
      this.props.update_caption_and_categories({ id, data })
    );
  };

  handleChangeCategory = (e, index) => {
    let { latestUploadedData, uploadedId, uploadedCat, uploadCaptions } = this.state;
    let upload_data = latestUploadedData[index];
    let id = upload_data.id;
    let isImageType = upload_data.media_type;

    let { value } = e.target;
    uploadedId[index] = id;
    uploadedCat[index] = value;
    let albumType = value !== "all" ? value : "";
    this.setState({ albumCategoryId: albumType, uploadedId, uploadedCat, taxonomycategoryId: value });
    let categoryId = "business";
    let subcategoryId = value;

    this.props.update_caption_and_categories({ id, data: { "caption": uploadCaptions[index] || "", "category": value } });

    // if (isImageType == "image") {
    //   this.props.fetch_categories_data(
    //     categoryId,
    //     subcategoryId,
    //     "image",
    //     index
    //   );
    // } else {
    //   this.props.fetch_categories_data(
    //     categoryId,
    //     subcategoryId,
    //     "video",
    //     index
    //   );
    // }
  };

  handleChangeTaxonomyData = (e, index) => {
    let { value } = e.target;
    let {
      uploadCaptions,
      latestUploadedData
    } = this.state;
    let data = {};
    let upload_data = latestUploadedData[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    data.category = value;
    let id = upload_data.id;
    this.setState(
      { taxonomycategoryId: value, captionData: caption_data },
      () => this.props.upload_add_captions(id, data)
    );
  };

  checkIsAdded = (item) => {
    let { mediaSet } = this.state;
    let id = item.id;
    let itemFiltered = mediaSet.find((item) => item.id == id);
    if (itemFiltered) {
      return true;
    } else {
      return false;
    }
  };

  removeFromMedia = (e, item) => {
    e.preventDefault();
    let { mediaSetReview } = this.state;
    this.removeMediaItemCollector(item)
    let id = item.id;
    let itemFiltered = mediaSetReview.find((item) => item.id == id);
    if (itemFiltered) {
      const index = mediaSetReview.indexOf(itemFiltered);
      if (index > -1) {
        mediaSetReview.splice(index, 1);
      }
      this.setState({ mediaSet: mediaSetReview, mediaSetReview });
    }

    if (document.getElementsByTagName('video') && Array.from(document.getElementsByTagName('video').length)) {
      Array.from(document.getElementsByTagName('video')).forEach(video => {
        video.load();
      })
    }
  };

  removeMediaItemCollector = (item) => {
    let removedMediaItemCopy = [...this.state.removedMediaItem]
    removedMediaItemCopy.push(item?.id)
    const uniqueMediaId = [...new Set(removedMediaItemCopy)]
    this.setState({ removedMediaItem: uniqueMediaId });
  }

  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`;

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach((item) => {
        if (
          fetchGalleryScroll[item] &&
          fetchGalleryScroll[item].results !== null
        ) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map((item) => {
          let itemName = (item?.thumbnail)?.split(".");
          let itemFile = (item?.file)?.split(".");
          let extName = itemName?.[itemName?.length - 1];
          let extFile = itemFile?.[itemFile?.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;
          item["srcFile"] = isVideo ? srcFile : item?.file;
          return (
            <Col xs="auto" className="mb-3">
              <FormGroup
                className="checkable-media"
                check
                style={{ width: "120px" }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={(e) => {
                      this.setMediaSetForItems(e, item);
                    }}
                    checked={this.checkIsAdded(item)}
                  />
                  <div className="gallery-media">
                    <img
                      className="img-fluid"
                      src={isVideo ? srcFile : item?.file}
                      alt="logo Pics"
                    />
                  </div>
                </Label>
                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
                  {item.caption ? item.caption : "No Caption"}
                </div>
                <div className="font-weight-normal fs-14 d-block">
                  {item.category ? item.category : "No Category"}
                </div>
              </FormGroup>
            </Col>
          );
        });
      }
    } else {
      return getDataItems;
    }
  };

  checkIfVideo = (item) => {
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`;

    let itemFile = item.file.split(".");

    let extFile = itemFile[itemFile.length - 1];
    let srcFile = imagePreview;

    if (["jpg", "jpeg", "png", "gif"].includes(extFile)) {
      srcFile = item.file;
    }
    return srcFile;
  };

  handleOnFileUploadChange = (e) => {
    let { selectedFilesPreview, files, } = this.state;
    const filesSetForUpload = Array.from(e.target.files);
    files = files.concat(filesSetForUpload);
    let self = this;
    this.setState({ files }, () => {
      Promise.all(
        files.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (ev) => {
              resolve(ev.target.result);
            });
            reader.addEventListener("error", reject);
            reader.readAsDataURL(file);
          });
        })
      ).then(
        (images) => {
          let concatWithPrev = selectedFilesPreview.concat(images);
          self.setState({ selectedFilesPreview: concatWithPrev });
        },
        (error) => {
          console.error(error);
        }
      );
    });

    if (files && Array.isArray(files) && files.length > 0) {
      let indexSelected = selectedFilesPreview.length - 1;
      files.forEach(async (item, index) => {
        if (index > indexSelected) {
          let formData = new FormData();
          formData.append("file", item);

          let name = item.name;
          let split_items = name.split(".");
          let item_ext = split_items[split_items.length - 1];
          let isImageTrue = ["jpg", "png", "jpeg", "gif", "tiff"].includes(
            item_ext
          );
          let url = `/upload/multiuploader/?album=reviews&instance=review_1&image=undefined&doc=undefined`;
          this.setState({ isImageType: isImageTrue });
          // await this.props.filter_categories_data({
          //   category: "business",
          //   sub_cat: "",
          //   level: 1,
          // });
          if (isImageTrue) {

            await this.props.upload_media_filesData(
              url,
              "image",
              formData,
              index
            );
            await this.props.get_category_taxonomy_listing('business', 'images', this.props.mainTaxonomy);
          } else {

            await this.props.upload_media_filesData(
              url,
              "video",
              formData,
              index
            );
            await this.props.get_category_taxonomy_listing('business', 'images', this.props.mainTaxonomy);
          }
        }
      });
    }
  };

  handleChangeTaxonomy = (e) => {
    let { value } = e.target;
    let albumType = value !== "all" ? value : "";
    this.setState(
      {
        fetchGalleryScroll: {},
        fetchGalleryAlbum: value,
        fetchGalleryType: "image",
      },
      () =>
        this.props.media_upload_scroll({
          type: "image",
          album: albumType,
          page: 1,
          force_update: true,
        })
    );
  };

  clearMediaDispatch = () => {
    this.setState(
      {
        latestUploadedData: {},
        uploadCaptions: {},
        selectedFilesPreview: [],
        files: [],
        taxonomycategoryId: "",
        fetchTaxonomyListingImages: {},
        fetchTaxonomyListingVideos: {},
        captionData: "",
        uploadedId: {},
        cattaxListing: {},
        albumCategoryId: "",
      },
      () => {
        this.props.clear_media_dispatch();
        this.props.clear_category();
        this.props.clear_subcategory();
      }
    );
  };

  fetchGalleryOnScroll = ({ type, album, force_update }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state;
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;

      if (nextCount <= roundValue) {
        this.props.media_upload_scroll({
          type,
          album,
          page: nextCount,
          force_update,
        });
      }
    }
  };

  handleScrollGalleryVideo = (e) => {
    let { refGalleryVideoScroll } = this;
    // let count = 0;
    let clientHeight = refGalleryVideoScroll.current.clientHeight;
    let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "video",
        album: "",
        force_update: false,
      });
    }
  };

  handleSubmitCaptions = (e, index) => {
    let { uploadCaptions, latestUploadedData } = this.state;
    let data = {};
    let upload_data = latestUploadedData[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;

    let id = upload_data.id;
    this.props.upload_add_captions(id, data);
  };

  removeSelectedImage = (e, index) => {
    let {
      latestUploadedData,
      selectedFilesPreview,
      files,
      uploadedId,
      fetchTaxonomyListingImages,
      fetchTaxonomyListingVideos,
    } = this.state;

    let upload_data = latestUploadedData[index];
    let id = upload_data.id;
    this.props.single_delete_from_upload(id);

    // delete items from array
    selectedFilesPreview.splice(index, 1);
    files.splice(index, 1); // deleted this because it concat from the upload function.

    // deleted items from json
    delete latestUploadedData[index];
    delete uploadedId[index];
    delete fetchTaxonomyListingImages[index];
    delete fetchTaxonomyListingVideos[index];

    // deleted items from json
    if (latestUploadedData[index] == undefined) {
      let newItems = [];

      // turn json to array for indexes
      Object.values(latestUploadedData).forEach((it) => {
        newItems.push(it);
      });

      // turn array to json for indexes
      if (newItems && Array.isArray(newItems) && newItems.length) {
        let newItemsJson = {};
        newItems.forEach((nitem, index) => {
          newItemsJson[index] = nitem;
        });

        // turn json to array for indexes
        if (Object.keys(newItemsJson).length) {
          latestUploadedData = newItemsJson;
        }
      }
    }

    if (uploadedId[index] == undefined) {
      let newItemsf = [];

      // turn json to array for indexes
      Object.values(uploadedId).forEach((it) => {
        newItemsf.push(it);
      });

      // turn array to json for indexes
      if (newItemsf && Array.isArray(newItemsf) && newItemsf.length) {
        let newItemsJsond = {};
        newItemsf.forEach((nitem, index) => {
          newItemsJsond[index] = nitem;
        });

        // turn json to array for indexes
        if (Object.keys(newItemsJsond).length) {
          uploadedId = newItemsJsond;
        }
      }
    }

    if (fetchTaxonomyListingImages[index] == undefined) {
      let newItemsImages = [];

      // turn json to array for indexes
      Object.values(fetchTaxonomyListingImages).forEach((it) => {
        newItemsImages.push(it);
      });

      // turn array to json for indexes
      if (
        newItemsImages &&
        Array.isArray(newItemsImages) &&
        newItemsImages.length
      ) {
        let newItemsJsonImages = {};
        newItemsImages.forEach((nitem, index) => {
          newItemsJsonImages[index] = nitem;
        });

        // turn json to array for indexes
        if (Object.keys(newItemsJsonImages).length) {
          fetchTaxonomyListingImages = newItemsJsonImages;
        }
      }
    }

    if (fetchTaxonomyListingVideos[index] == undefined) {
      let newItemsVideos = [];

      // turn json to array for indexes
      Object.values(fetchTaxonomyListingVideos).forEach((it) => {
        newItemsVideos.push(it);
      });

      // turn array to json for indexes
      if (
        newItemsVideos &&
        Array.isArray(newItemsVideos) &&
        newItemsVideos.length
      ) {
        let newItemsJsonVideo = {};
        newItemsVideos.forEach((nitem, index) => {
          newItemsJsonVideo[index] = nitem;
        });

        // turn json to array for indexes
        if (Object.keys(newItemsJsonVideo).length) {
          fetchTaxonomyListingVideos = newItemsJsonVideo;
        }
      }
    }

    this.setState({
      selectedFilesPreview,
      latestUploadedData,
      files,
      uploadedId,
      fetchTaxonomyListingVideos,
      fetchTaxonomyListingImages,
    });
  };

  handleScrollGalleryImage = (e) => {
    let { refGalleryImageScroll } = this;
    let { fetchGalleryAlbum } = this.state;

    let clientHeight = refGalleryImageScroll.current.clientHeight;
    let scrollHeight = refGalleryImageScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "image",
        album: fetchGalleryAlbum,
        force_update: false,
      });
    }
  };

  fetchGalleryOnScroll = ({ type, album, force_update }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state;
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;

      if (nextCount <= roundValue) {
        this.props.media_upload_scroll({
          type,
          album,
          page: nextCount,
          force_update,
        });
      }
    }
  };

  AddNewEmbedLink = async (e) => {
    e.preventDefault();
    let { embeddedLink, embedBody } = this.state;
    // this.props.embedNewLink({
    //   data: {
    //     video: `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
    //     exclude_list: [],
    //     body_preview: `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
    //     question_rating_category: [],
    //     messagemedia_set: [],
    //   },
    // });
    let thumbnail = await mediaThumbnail(embeddedLink);
    embedBody.push({
      type: "embedded_link",
      url: embeddedLink,
      thumbnail
    });
    this.setState({ embeddedLink: '', embedBody, uploadMediaModal: false })
  };

  handleCloseAndFetchReview = () => {
    let { mediaSetReview } = this.state;
    this.setState(
      {
        uploadMediaModal: !this.state.uploadMediaModal,
        fileReviewStatus: true,
        mediaSet: mediaSetReview,
        taxonomycategoryId: "",
        fetchTaxonomyListingImages: {},
        fetchTaxonomyListingVideos: {},
        captionData: "",
        uploadedId: {},
      },
      () => {
        this.clearMediaDispatch();
        this.props.fetch_media_data("image", "", 1, true, true);
        this.props.fetch_media_data("video", "", 1, true, true);
      }
    );
  };

  // Delete Review
  confirmDeleteModalToggle = () => {
    if (this.state.deletereviewId) {
      this.setState({ deletereviewId: 0 });
    }
    this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
  };

  handleOnDeleteConfirmation = () => {
    if (this.state.deletereviewId) {
      this.props.delete_post_comment(
        this.state.deletereviewId,
        this.state.businessId,
        true
      );
    }
    this.setState({
      confirmDeleteModal: !this.state.confirmDeleteModal,
      deletereviewId: 0,
    });
  };


  handleCloseAndFetch = () => {
    let { latestUploadedData, fileReviewStatus, mediaSetReview } = this.state;
    if (
      fileReviewStatus &&
      Object.values(latestUploadedData) &&
      Object.values(latestUploadedData)[0] &&
      Object.values(latestUploadedData)[0].id
    ) {
      Object.values(latestUploadedData).forEach((list) => {
        mediaSetReview.push({
          id: list.id,
          file: list.url,
          thumbnail: list.thumbnail,
          url: list.url,
        });
      });
    }
    this.setState(
      {
        uploadMediaModal: !this.state.uploadMediaModal,
        fileReviewStatus: false,
        mediaSetReview,
        taxonomycategoryId: "",
        fetchTaxonomyListingImages: {},
        fetchTaxonomyListingVideos: {},
        captionData: "",
        uploadedId: {},
      },
      () => {
        this.clearMediaDispatch();
        this.props.fetch_media_data("image", "", 1, true, true);
        this.props.fetch_media_data("video", "", 1, true, true);
      }
    );
  };

  checkImages = (item) => {
    let items = item.tos.split(",");
    if (items.length > 1) {
      return group_icons;
    } else {
      return item.current_profile_pic;
    }
  };

  handleChangeRatings = (item) => {
    this.setState({ setRatingFrom: item });
  };

  renderItemsEmbedded = ({ item }) => {
    if (typeof item === "string") {
      let split = item?.split(",");
      if (split && Array.isArray(split) && split?.length) {
        return split.map((data) => <ComponentNew embedData={data} />);
      }
    } else if (item?.length) {
      const urls = item.map(i => i.url);
      if (urls && Array.isArray(urls) && urls?.length) {
        return urls.map((data) => <ComponentNew embedData={data} />);
      }
    }
  };

  filterItemsForVideos = ({ items }) => {
    if (Array.isArray(items) && items?.length) {
      let filteredVideoItems = items.map((item) => {
        if (item?.url) {
          let split_items = item?.url.split(".");
          let lastItem = split_items[split_items.length - 1];
          if (
            [
              "webm",
              "mpg",
              "mp2",
              "mpeg",
              "mpe",
              "mpv",
              "ogg",
              "mp4",
              "m4p",
              "m4v",
              "avi",
              "wmv",
              "mov",
            ].includes(lastItem)
          ) {
            return item;
          }
        }
        return null;
      }).filter((item) => item !== undefined);

      return filteredVideoItems.map((item, index) => {
        if (item?.url) {
          let split_items = item?.url.split(".");
          let lastItem = split_items[split_items.length - 1];
          return (<>
            <video width={index === 0 ? "320" : "125"} height={index === 0 ? "240" : "105"} autoPlay={index === 0 ? true : false} muted className={index === 0 ? "" : " pr-2 pt-2"} controls>
              <source src={item?.url} type={`video/${lastItem}`}></source>
            </video> {index === 0 && <br/>}
            </> 
          );
    }
        return null;
      });
    }
  };

  renderFilteredImages = ({ items }) => {
    let filterItems = this.filterItemsForImages({ items });
    if (filterItems?.length) {
      return (
        <div>
          {filterItems.slice(0, 1).map((reviewmedia, index) => (
            <div key={index}>
              {reviewmedia?.type === "image" && (
                <div className="mb-3" style={{ maxWidth: "450px" }}>
                  <img
                    src={reviewmedia.url}
                    alt={
                      reviewmedia?.filename?.length < 20
                        ? reviewmedia?.filename
                        : this.truncate(reviewmedia?.filename)
                    }
                    className="img-fluid"
                  />
                </div>
              )}
            </div>
          ))}
          <div>
            <Row
              // className="justify-content-center"
              xs={2}
              md={3}
              lg={3}
              xl={3}
              form
            >
              {filterItems?.length > 1
                ? filterItems.map((reviewmediass, index1) => {
                  return index1 !== 0 && (
                    <Col className="mb-2">
                      <img
                        src={reviewmediass?.url}
                        alt={
                          reviewmediass?.filename?.length < 20
                            ? reviewmediass?.filename
                            : this.truncate(reviewmediass?.filename)
                        }
                        className="fixed-height-img is-160 full-width"
                        role="button"
                        onClick={() =>
                          this.handleOpenModal(
                            filterItems,
                            reviewmediass?.url,
                            index1
                          )
                        }
                      />
                    </Col>
                  );
                })
                : ""}
            </Row>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  filterItemsForImages = ({ items }) => {
    if (Array.isArray(items) && items?.length) {
      let filteredPhotoItems = items
        .map((item) => {
          if (item?.url) {
            let split_items = item?.url.split(".");
            let lastItem = split_items[split_items.length - 1];
            if (
              ![
                "webm",
                "mpg",
                "mp2",
                "mpeg",
                "mpe",
                "mpv",
                "ogg",
                "mp4",
                "m4p",
                "m4v",
                "avi",
                "wmv",
                "mov",
              ].includes(lastItem)
            ) {
              return item;
            }
          }
          return null;
        })
        .filter((item) => item !== undefined);

      return filteredPhotoItems.filter(photoItem=> photoItem); ;
    }
    return [];
  };

  renderPhotosAndVideos = (iteamImage, isTrue) => {
    let itemsValue = iteamImage?.url || iteamImage?.location;
    if (itemsValue) {
      let items = itemsValue.split(".");
      let lastItem = items[items.length - 1];
      if (
        [
          "webm",
          "mpg",
          "mp2",
          "mpeg",
          "mpe",
          "mpv",
          "ogg",
          "mp4",
          "m4p",
          "m4v",
          "avi",
          "wmv",
          "mov",
        ].includes(lastItem)
      ) {
        return (
          <video
            autoPlay
            muted
            className={
              isTrue
                ? "img-fluid img-thumbnail"
                : "img-thumbnail uploaded-img-video-thumbnail"
            }
            controls
          >
            <source src={itemsValue} type={`video/${lastItem}`}></source>
          </video>
        );
      } else {
        return (
          <img
            className={
              isTrue
                ? "img-fluid img-thumbnail"
                : "img-thumbnail uploaded-img-video-thumbnail"
            }
            src={itemsValue}
            alt=""
          />
        );
      }
    }
  };

 

  UpdateReviewList = (filter) => {
    const { businessId, get_business_review_detail } = this.props;
    this.setState({ filter });
    get_business_review_detail(businessId, filter);

  }

  handleDisputeThisReview = (review, reason) => {
    this.setState({ showDisputeModal: true, reviewDetails: review, disputeModal: true, reason: reason })
  }

  handleHeatedDiscussion = (feed) => {
    this.setState({ showDisputedModal: true, reviewDetails: feed, disputeModal: true })
    return
  };

  checkIfLoggedIn = () => {
    const authId = localStorage.getItem('profileId');
    const { my_profile } = this.props;
    if (my_profile && my_profile.id && authId && (authId == my_profile.id)) {
      return true;
    } else {
      return false;
    }
  }
  forceToggleLoginModal = () => {
    this.setState(prevState => ({
      ...this.prevState,
      forceShowLoginModal: !prevState.forceShowLoginModal
    }));
  }

  checkColorTrue = (item) => {
    const { businessRatingList } = this.state;
    return businessRatingList?.filter(itm => itm.key === item)?.[0]?.isEnable || businessRatingList?.filter(itm => itm.key === item)?.[0]?.value === 0
  }

  onHoverEnableDisable = (name, value) => {
    const tempArray = [...this.state.businessRatingList]
    const ratedCategory = tempArray.map(item => {
      return { ...item, isEnable: item.key === name ? value : false }
    })
    this.setState({ businessRatingList: ratedCategory })
  }

  findAndSetItem = (item) => {
    let item_modify = '';
    if (item) {
      item_modify = item.replace(/\s/g, '');
      if (item_modify.includes('/')) {
        item_modify = item_modify.replace(/\//g, '')
      }
    }

    let { businessRatingList } = this.state;
    let data = businessRatingList.findIndex(data => {

      let name_modify = '';
      if (data.key) {
        name_modify = data.key.replace(/\s/g, '');
        if (name_modify.includes('/')) {
          name_modify = name_modify.replace(/\//g, '')
        }
      }

      return name_modify == item_modify;
    });

    if (data > -1) {
      let returnValue = businessRatingList[data];
      if (returnValue?.value > -1) {
        return returnValue?.value;
      }
    }
  }

  loadEditBusinessDetailedList = (editItem, allItems) => {
    const rating = [];

    this.props?.qa_dropdown?.res?.map((iteam) => {
      if (iteam.name !== "avg_rating" && iteam.name !== "code") {
        rating.push({
          key: iteam.name,
          value: +editItem?.ratings_sets?.[iteam.label]?.[1] || null,
          id: iteam.id,
          checked: editItem?.category_ids?.includes(iteam.id),
          label: iteam.label,
          isEnable: false
        })
      }
    })
    this.setState({
      businessRatingListEdit: rating,
    })
    let childAvgRatingKey = allItems?.filter(item => item.id === editItem.id)?.[0]?.review_voting_key
    if (!!childAvgRatingKey) {
      this.setState({
        avgRateKey: childAvgRatingKey
      })
    }
  }

  handleEditChange = (value, eleId) => {
    let businessRatingListEditCopy = this.state.businessRatingListEdit?.map(item => {
      return {
        ...item,
        checked: item.id === eleId ? value : item.checked
      }
    })

    const businessRatingIdsCopy = businessRatingListEditCopy?.filter(item => item.checked)?.map(filItem => filItem.id)
    this.setState({ businessRatingListEdit: businessRatingListEditCopy, businessRatingIds: businessRatingIdsCopy })

  }

  isPublishDisable = () => {
    const { write_new_review } = this.state

    if (write_new_review && write_new_review?.length < 50)
      return true
    else if (write_new_review && write_new_review?.length > 50)
      return false

    return true
  }


  removeEmbeddedEditReview = (index, item) => {
    let { embedBody } = this.state;
    if (index > -1) {
      embedBody.splice(index, 1);
    }
    this.setState({ embedBody });

  }

  renderListImagesItem = ({ title }) => (<li>
    <img src={`https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/${title}.png`} alt={title} />
    {title}</li>);


  renderAvatarListingimages = ({ item, active }) => (
    <li>
      <img
        src={item?.award_statistics?.last_month_leader?.gems
          && item?.award_statistics?.last_month_leader?.gems != null
          && item?.award_statistics?.last_month_leader?.gems[1] == active
          ? `https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img${active}_active.png`
          : `https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img${active}.png`}
        alt="" />
    </li>
  );

  renderListingAwardImages = ({ item, active }) => {
    let src = `https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img${active}.png`
    if (item?.award_statistics?.last_month_leader?.planets?.[1] == active) {
      src = `https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img${active}_active.png`
    }
    return (<li>
      <img src={src} alt="" />
    </li>)
  };

  renderMoreDetailsAward = ({ title, count }) => (
    <li className="px-1 mb-2">
      <img width="15"
        src={`https://userdatawikireviews.s3.amazonaws.com/images/awards/${title}.png`}
        alt=""
        title={`${title} ${count} times`} />
    </li>
  );

  renderLinksWrapper = ({ body, index }) => {
    let expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    let itemsGet = body?.url.match(expression);
    if (itemsGet !== null) {
      if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
        itemsGet = [...new Set(itemsGet)];
        if (itemsGet && Array.isArray(itemsGet) && itemsGet.length) {
          return itemsGet.map((item, index) => (
            <div className="outer-link" key={index}>
              <Embed url={item} />
              {/* <ComponentNew embedData={item} /> */}
            </div>
          ));
        }
      }
    } else {
      return <img
        className="img-fluid img-thumbnail h-100"
        src={body.thumbnail || require("../../../assets/images/embedded_link_img.png")}
        id={`embedded_link-${index}`}
        alt={body.url}
        style={{ width: "100%" }}
      />
    }
  };
  handleMenuItem = (businessId, ratingKey) => {
    this.fetchRateMenuItems({ businessId, ratingKey })
  }
  fetchRateMenuItems = ({ businessId, ratingKey }) => {
    callApi(`/api/getmenu/?entity=${businessId}&menuType=`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        this.setState({ rateMenu: response?.results || [] })
        if (response?.results?.length) {
          this.setState({ activeMenuType: response?.results[0] })
          const menuItem = response?.results[0];
          this.fetchRateMenuItemContent({ businessId, menuItem, ratingKey })
        }
      }
    });
  }
  fetchRateMenuItemContent = ({ businessId, menuItem, ratingKey }) => {
    const rKey = !!ratingKey ? `&rating_key=${ratingKey}` : "&menu=true"
    callApi(`/api/getmenu/?entity=${businessId}&menuType=${menuItem}${rKey}`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let menuContainer = []
        Object.keys(response?.results).forEach((item) => {
          const tempArray = {
            menuItem: item,
            menuContent: this.formatMenuContent(response?.results?.[item])
          }
          menuContainer.push(tempArray);
        })
        let dummyObj = {}

        Object.keys(response.results).forEach(item => {
          response.results?.[item].forEach(menuItem => {
            Object.keys(menuItem).forEach(itm => {
              dummyObj[menuItem?.[itm]?.[4]] = menuItem?.[itm]?.[6]
            })
          })

        })

        this.setState({ rateMenuContent: menuContainer, menuMediaListMedia: dummyObj })
      }
    });
  }

  formatMenuContent = (menuConArray) => {
    let tempMenuContent = [];
    menuConArray.forEach(item => {
      for (const [key, value] of Object.entries(item)) {
        const tempArray = {
          menuItemName: key,
          menuItemContent: value
        }
        tempMenuContent.push(tempArray)
      }
    })
    return tempMenuContent
  }

  ratingChangedMenuItem = async (newRating, menuId, ratingKey) => {
    const businessId = this.state.businessId
    const menuItem = this.state.activeMenuType
    callApi(`/api/menurating/?value=${newRating}&menu_id=${menuId}&rating_key=${ratingKey}`, "POST", []).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        this.fetchRateMenuItemContent({ businessId, menuItem, ratingKey })
      }
    });
  }

  handleDeleteMenuMedia = (menuId, mediaId) => {
    const { menuMediaListMedia } = this.state
    let menuMediaListMediaCopy = { ...menuMediaListMedia }
    const mutateArray = menuMediaListMediaCopy?.[menuId]
    const updatedArray = mutateArray.filter(item => item.id !== mediaId)
    menuMediaListMediaCopy[menuId] = updatedArray
    this.setState({ menuMediaListMedia: menuMediaListMediaCopy })

  }

  setClickedTabOperation = ({ value }) => {
    if (value > 1) {
      this.setState({ clickedOnWriteAReview: value, viewEditReviewStep: "writeReview" });
    } else {
      this.setState({ clickedOnWriteAReview: value });
    }
  }

  addUploadedVideo = (item) => {
    let { stepTwoUploadedVideo } = this.state;
    stepTwoUploadedVideo.push(item);
    this.setState({ stepTwoUploadedVideo });

    if (Array.isArray(stepTwoUploadedVideo) && stepTwoUploadedVideo.length > 0) {
      this.setState({ enableErrorForReview: false })
    }

  }

  addUploadedVideo = (item) => {
    let { stepTwoUploadedVideo } = this.state;
    stepTwoUploadedVideo.push(item);
    this.setState({ stepTwoUploadedVideo });

    if (Array.isArray(stepTwoUploadedVideo) && stepTwoUploadedVideo.length > 0) {
        this.setState({ enableErrorForReview: false })
    }

}

removeUploadedVideo = () =>{
  this.setState({ stepTwoUploadedVideo : [],enableErrorForReview: true});
}

reviewShareTitle = (item) => {
  const title = `Check out ${item?.created_by?.user?.first_name ? item?.created_by?.user?.first_name : "this"} ${item?.created_by?.user?.first_name && item?.created_by?.user?.last_name ? item?.created_by?.user?.last_name + "'s" : ""} review of ${item?.associated_to?.name ? item?.associated_to?.name : 'the listing'} on `
  return title
}

truncate = (filenameString) => {
  // let split = filenameString.split(".");
  let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
  let extension = filenameString.substr(
    filenameString.lastIndexOf("."),
    filenameString.length - 1
  );
  let partial = filename.substring(filename.length - 3, filename.length);
  filename = filename.substring(0, 15);
  return filename + "..." + partial + extension;
};

getFilterLabel = (name) =>{
  
    switch (name) {
      case "relevant":
            return "Most Relevant"
        case "featured":
            return "Featured Reviews"
        case "top":
            return "Top Contributions"
        case "image":
            return "Photo Reviews"
        case "video":
            return "Video Reviews"
        case "newest":
            return "Newest"
        case "oldest":
            return  "Oldest";
        case "highest":
            return "Highest Rating"
        case "lowest":
            return "Lowest Rating"
        case "helpful":
            return "Most Helpful"
        case "longest":
            return "Longest"
        case "shortest":
            return "Shortest"        

        default:
            return "";


    }

}

  render() {
    const {
      commentId,
      commentMessage,
      sharer_name,
      email,
      imageAttacment,
      commentMessageAdd,
      commentMessageOuter,
      avg_rating,
      businessRatingList,
      write_new_review,
      reviewsCategories,
      mediaSetReview,
      reviewAnonymous,
      shocialNetworkSharing,
      businessId,
      selectedFilesPreview,
      latestUploadedData,
      fetchTaxonomy,
      embeddedLink,
      showVideoMedia,
      isadhere,
      followName,
      mode,
      fetchTaxonomyListingImages,
      fetchTaxonomyListingVideos,
      uploadedId,
      cattaxListing,
      imageAttacmentnew,
      isLoading,
      confirmationToggle,
      confirmationReason,
      showDisputeModal,
      reviewDetails,
      showDisputedModal,
      reason,
      errors,
      businessRatingListEdit,
      followUPReviewVotingKey,
      avgRateKey,
      embedBody, rateMenu,
      rateMenuContent,
      enableErrorForReview,
      stepTwoUploadedVideo,
      writeAReviewTextVideo,
      viewEditReviewStep
    } = this.state;

    const {
      dispute_reply_text,
      get_dispute_data, is_media_dispute, enableHoverBanIcon } = this.state;

    
    let totalLength = selectedFilesPreview.length;
    let objectKeys = [];
    let objectLength = 0;
    let calculatedPercentage = 0;
    if (Object.keys(latestUploadedData).length > 0) {
      objectKeys = Object.keys(latestUploadedData);
      objectLength = objectKeys.length;
      calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
    }
    const { business_reviewDetail, loggedInUsername, my_profile, initialReviewListing, businessDetails } = this.props;
    const profileId = +localStorage.getItem("profileId")
    return (
      <>

        {showDisputeModal ?
          <DisputingReview review={reviewDetails} source="NonActioned" reason={reason} />
          :
          ''
        }
        {showDisputedModal ?
          <DisputedReview review={reviewDetails} source="NonActioned" />
          :
          ''
        }

        <Modal
          size="lg"
          isOpen={this.state.viewMyPhotosModal}
          toggle={() =>
            this.setState({ viewMyPhotosModal: !this.state.viewMyPhotosModal })
          }
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                viewMyPhotosModal: !this.state.viewMyPhotosModal,
              })
            }
          ></ModalHeader>
          <ModalBody className="bg-transparent p-0">
            <ReactRespCarousel>
              {imageAttacment && imageAttacment.length > 1
                ? imageAttacment.map((data, index1) => {
                  return (
                    <div>
                      <img alt="" src={data.type == "image" ? data.url : ""} />
                    </div>
                  );
                })
                : ""}
            </ReactRespCarousel>
          </ModalBody>
        </Modal>
        <Modal
                    centered
                    isOpen={this.state.viewMyPhotosModalnew}
                    toggle={() => {
                        this.setState({ viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew }, () => {
                            if (!this.state.viewMyPhotosModalnew) {
                                clearTimeout(this.setTimeOutSlider);
                            }
                        })
                    }}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            ref={(el) => (this.Carousel = el)}
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            sliderClass={`${imageAttacmentnew?.length === 0}` ? "mx-auto" : ""}>
                            {imageAttacmentnew.length > 0 ? (
                                imageAttacmentnew.map((med, i) => {
                                    return (
                                        <div className="item fs-14 h-100 d-flex flex-column justify-content-center"
                                            key={i}>
                                            <div>
                                                <div className="d-flex text-white justify-content-between mt-3">
                                                    <div className="mr-2 ff-alt">{med?.caption}</div>
                                                    <div className="ff-base">{med?.label}</div>
                                                </div>
                                            </div>
                                            <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                                {(med?.type === "image" || med?.type === "") && (
                                                    <img
                                                        className="img-fluid shown-media"
                                                        src={med?.url}
                                                        alt=""
                                                    />
                                                )}
                                                {med?.type === "video" && (
                                                    <video className="img-fluid shown-media" controls>
                                                        <source src={med?.url} type="video/mp4" />
                                                        {'Your browser does not support the video tag.'}
                                                    </video>
                                                )}
                                            </div>
                                            <div className="px-2 mt-3">
                                                <div className="d-flex flex-nowrap mx-n2">
                                                    <div className="px-2 col d-flex flex-nowrap">
                                                        <div className="mr-2">
                                                            <img
                                                                className="img-circle _30x30"
                                                                src={med?.user?.current_profile_file
                                                                    ? med?.user?.current_profile_file
                                                                    : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className="text-primary ff-base">
                                                                {` Uploaded by `}
                                                                <a className="text-reset" href="#">
                                                                    <span className="font-weight-bold">
                                                                        {med?.user?.user?.first_name}
                                                                    </span>{" "}
                                                                </a>
                                                            </div>
                                                            <div className="text-tertiary ff-base">
                                                                {med?.upload_date}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-2 col-auto">
                                                        <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                                                            {i + 1} of {imageAttacmentnew.length}
                                                        </h2>
                                                    </div>
                                                    <div className="px-2 col">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (<div className="item px-4">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                        <span>No result found for this category</span>
                                    </div>
                                </div>
                            </div>
                                )}
                        </Carousel>
                        {/* {this.state.viewMyPhotosModalnew && (
                            this.setTimeOutSlider = setTimeout(() => {
                                if (this?.Carousel?.goToSlide) {
                                    let index = this.state.currentActiveIndex;
                                    this.Carousel.goToSlide(index + 2, true);
                                }
                            }, 100)
                        )} */}
                    </ModalBody>
                </Modal>

        <ConfirmationModal
          isOpen={this.state.isDeleteCommentModalOpen}
          setIsOpen={this.handleCancelDeleteComment}
          onConfirm={this.handleDeleteComment}
        />
        
        <ModalComponent
          size="sm"
          isScrollable={true}
          isOpen={this.state.shareByEmailModalToggle}
          toggleHandler={() =>
            this.setState({
              shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            })}
          header={"Share by Email"}
          headerClass="text-uppercase px-0"
          body={<ShareByEmail handleOnSubmitShareByEmail={(value) => this.handleOnSubmitShareByEmail(value)} modalToggler={() => {
            this.setState({
              shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            })
          }} />}
        />  

        {/* <Modal
          size="sm"
          isOpen={this.state.shareByEmailModalToggle}
          toggle={() =>
            this.setState({
              shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            })
          }
        >
          <ModalHeader
            className="text-uppercase px-0"
            toggle={() =>
              this.setState({
                shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
              })
            }
          >
            Share by Email
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input
                className="primary"
                bsSize="sm"
                type="text"
                name="sharer_name"
                value={sharer_name}
                onChange={this.handleChange}
                placeholder="Your name"
              />
              {this.state.shareError?.sharer_name && (
                <div className="text-danger small mt-1">
                  {this.state.shareError?.sharer_name}
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                className="primary"
                bsSize="sm"
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
                placeholder="Recipient's email"
              />
              {this.state.shareError?.email && (
                <div className="text-danger small mt-1">
                  {this.state.shareError?.email}
                </div>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter className="px-0">
            <div className="text-right">
              <Button
                color="primary"
                className="mw"
                onClick={this.handleSubmit}
              >
                Share
              </Button>
            </div>
          </ModalFooter>
        </Modal> */}


        <div className="d-flex mx-n2 mb-3">
          <div className="px-2">
            <Button
              size="sm"
              color="filterable"
              onClick={() => {
                this.filterReviewFunction('');
              }}
            >
              All
            </Button>
          </div>
          <div className="px-2">
            <UncontrolledDropdown
              onMouseOver={() => this.onMouseEnterCategory("dropdownSortBy")}
              onMouseLeave={() => this.onMouseLeaveCategory("dropdownSortBy")}
              isOpen={this.state.dropdownSortBy}
              toggle={() => this.toggleCategory("dropdownSortBy")}
            >
              <DropdownToggle
                className="text-tertiary bg-transparent font-weight-bold p-0"
                color="transparent"
                size="sm"
                caret
              >
                <span className="pr-2">{this.state.filter === "" ? "Sort By" : this.getFilterLabel(this.state.filter)}</span>
              </DropdownToggle>
              <DropdownMenu className="type-tertiary wk-business-sort-dropdown">
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("relevant");
                  }}
                  className="fs-14"
                >
                  Most Relevant
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("featured");
                  }}
                  className="fs-14"
                >
                  Featured Reviews
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("top");
                  }}
                  className="fs-14"
                >
                  Top Contributions
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("image");
                  }}
                  className="fs-14"
                >
                  Photo Reviews
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("video");
                  }}
                  className="fs-14"
                >
                  Video Reviews
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("newest");
                  }}
                  className="fs-14"
                >
                  Newest
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("oldest");
                  }}
                  className="fs-14"
                >
                  Oldest
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("highest");
                  }}
                  className="fs-14"
                >
                  Highest Rating
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("lowest");
                  }}
                  className="fs-14"
                >
                  Lowest Rating
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("helpful");
                  }}
                  className="fs-14"
                >
                  Most Helpful
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("longest");
                  }}
                  className="fs-14"
                >
                  Longest
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.filterReviewFunction("shortest");
                  }}
                  className="fs-14"
                >
                  Shortest
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div className="px-2">
            <Button size="sm" color="filterable" onClick={() => {
              this.filterReviewFunction("notrecommended");
            }}>
              Not Recommended
            </Button>
          </div>

          <div className="ml-auto">
            <SearchBar
              handleSearchSubmit={this.filterReviewBySearchText}
              searchResultsEnabled={this.state.searchResultsEnabled}
            />
          </div>
        </div>

        <div>
          {/* Type Review */}
          {!['relevant','featured','top'].includes(this.state.filter) ? <>{
            initialReviewListing &&
            initialReviewListing.length > 0 ? (
              initialReviewListing &&
              initialReviewListing.map((iteam, index) => {
              let last_month_leader =
                iteam.award_statistics &&
                  iteam.award_statistics.last_month_leader
                  ? iteam.award_statistics.last_month_leader
                  : "";

              return (
                <div className="mt-4">
                  {iteam && iteam.is_review_flag ? (
                    <>
                      <div className="bg-warning text-dark p-2 fs-14">
                        <div className="d-flex flex-wrap mx-n2">
                          {/* <div className="text-white px-2">
                              <span className="font-weight-bold">
                                Not Recommended
                            </span>{" "}
                              <span>Review</span>
                            </div> */}
                          <div className="fs-14 px-2 ml-auto text-black">
                            <p className="mb-0 d-inline-block">
                              This review is currently being Disputed.
                            </p>{" "}
                            <span
                              className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                              role="button"
                              onClick={() => this.setState({ watchHowDisputeReviewWorksModalToggle: true })}
                            >
                              <span>Learn More
                                <FontAwesomeIcon icon="arrow-right" />
                              </span>
                              <div
                                className="on-hover-appear invisble-part"
                                style={{ bottom: "calc(100% + 10px)" }}
                              >
                                <div className="tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                                  <div className="fs-14 ff-base">
                                    {/* Please watch the video to  */}
                                    See how Review Disputes work
                                  </div>
                                  <div className="p-2">
                                    <Row className="justify-content-between align-items-center">
                                      <Col
                                        xs="12"
                                        className="d-flex justify-content-center align-items-center"
                                      >
                                        <div
                                          className="video-holder play-icon d-inline-block mx-2"
                                          role="button"
                                          onClick={() =>
                                            this.setState({
                                              watchHowDisputeReviewWorksModalToggle: true,
                                            })
                                          }
                                        >
                                          <img
                                            height="80"
                                            src={require("../../../assets/images/dispute_jury.webp")}
                                            alt="dispute"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>

                              </div>
                            </span>
                            <span>{" "} {" "}</span>
                            <span
                              className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                              role="button"
                              onClick={() => this.handleHeatedDiscussion(iteam)}
                            >
                              {iteam?.flag?.user?.email === my_profile?.user?.email ?
                                <span className="flashing">
                                  7days Period for Parties to Upload their Response
                                  {iteam?.flag?.discussion ?
                                    iteam?.flag?.owner_discussion ?
                                      ' ( BusinessOwner Responded )'
                                      : ' ( Community Voting )'
                                    :
                                    ''}
                                </span>
                                :
                                <span className="flashing"> View Dispute
                                  {/* <FontAwesomeIcon icon="arrow-right" />  */}
                                </span>
                              }
                              <div
                                className="on-hover-appear"
                                style={{ bottom: "calc(100% + 10px)" }}
                              >
                                <div className="help-community-tooltip tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                                  Help the community and vote on this review
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {!!!iteam?.parent_review && iteam?.child_review?.review_date_time ? (
                    <div className="bg-white p-3 mt-3 col-12">
                      <Row>
                        <Col xs={3}>
                        {(!!iteam?.child_review?.review || iteam?.child_review?.review==="") && 
                        <UserInfoAvatar
                          item={iteam}
                          renderListImagesItem={this.renderListImagesItem}
                          renderAvatarListingimages={this.renderAvatarListingimages}
                          renderListingAwardImages={this.renderListingAwardImages}
                          renderMoreDetailsAward={this.renderMoreDetailsAward}
                          reviewStats={iteam?.award_rating_statistics?.stats}
                          listing_type={"business"}
                        />
                  }
                      </Col>
                        <Col xs={9}>
                          <div>
                            <div className="fs-14">
                              <span className="text-dark font-weight-bold">
                                {'Updated Review'}
                              </span>
                             
                            </div>
                            
                            <div className="d-flex mb-2 mx-n2">
                              <div className="px-2">
                                <img className="mr-2" src={require(`../../../assets/images/icons/${iteam?.child_review?.ratings_sets && iteam?.child_review?.ratings_sets?.avg_rating[0] ? iteam?.child_review?.ratings_sets?.avg_rating[0] : '0rating.png'}`)}
                                  alt={iteam?.child_review?.ratings_sets && iteam?.child_review?.ratings_sets?.avg_rating[1] ? iteam?.child_review?.ratings_sets?.avg_rating[1] : '0.0'}
                                  title={iteam?.child_review?.ratings_sets && iteam?.child_review?.ratings_sets?.avg_rating[1] ? iteam?.child_review?.ratings_sets?.avg_rating[1] : '0.0'} />
                                <span className="text-dark font-weight-bold fs-14">
                                  {'Overall Rating'}
                                </span>
                              </div>
                            </div>
                            <span className="text-tertiary">
                                {`This review was written `}{iteam.child_review.review_date_time}
                              </span>
                            <div className="mb-3">
                              <Row className="fs-14 ff-alt">
                                {iteam?.child_review?.ratings_sets && Object.keys(iteam?.child_review?.ratings_sets).length ?
                                  Object.keys(iteam?.child_review?.ratings_sets).map(element => (
                                    (element === "avg_rating" || element === "total_review") ? "" : (
                                      <Col className="mb-2" md={6}>
                                        <img
                                          className="mr-2"
                                          src={require(`../../../assets/images/icons/${iteam?.child_review?.ratings_sets[element] && iteam?.child_review?.ratings_sets[element][0] ? iteam?.child_review?.ratings_sets[element][0] : '0rating.png'}`)}
                                          alt={iteam?.child_review?.ratings_sets[element] && iteam?.child_review?.ratings_sets[element][1] ? iteam?.child_review?.ratings_sets[element][1] : '0.0'}
                                          title={iteam?.child_review?.ratings_sets[element] && iteam?.child_review?.ratings_sets[element][1] ? iteam?.child_review?.ratings_sets[element][1] : '0.0'}
                                        />
                                        <span>{element}</span>
                                      </Col>
                                    )
                                  ))
                                  : null}
                              </Row>
                            </div>

                            <div className="my-4">
                              <i className="icon-comma-left _dark"></i>
                              <span className="comment-body-renderer child-review-1" filter-value={iteam?.child_review?.review}>
                                {ReactHtmlParser(detectlink(iteam?.child_review?.review))}
                              </span>
                              <i className="icon-comma-right"></i>
                            </div>

                            {/* <div className="mb-3">
                              {this.filterItemsForVideos({
                                items: iteam?.child_review?.reviewmedia_set,
                              })}

                              <div className="text-center">
                                {iteam?.child_review?.reviewmedia_set.length > 0 ?
                                  this.renderFilteredImages({ items: iteam?.child_review?.reviewmedia_set })
                                  : ""}
                              </div>
                            </div> */}
                            {iteam?.child_review?.reviewmedia_set?.length > 0 ? (
                              <div >
                                {iteam?.child_review?.reviewmedia_set.slice(0, 1).map((reviewmedia, index) => (
                                  <div key={index}>
                                    {reviewmedia.type === "image" && (
                                      <div className="mb-3" style={{ maxWidth: "450px" }}>
                                        <img
                                          src={reviewmedia.url}
                                          alt={reviewmedia.filename?.length < 20 ? reviewmedia.filename : this.truncate(reviewmedia.filename || '')}
                                          className="img-fluid"
                                        />
                                      </div>
                                    )}
                                    {reviewmedia.type === "video" && (
                                      <video width="320" height="240" controls>
                                        <source
                                          src={reviewmedia.url}
                                          type="video/mp4"
                                        />
                                        {'Your browser does not support the video tag.'}
                                      </video>
                                    )}
                                  </div>
                                ))}
                                <div className="mb-3">
                                  {/* List of Photos uploaded */}
                                  <ul className="list-unstyled d-flex flex-wrap mx-n1 mb-2" style={{ maxHeight: '280px', overflowY: 'auto' }}>
                                    {iteam?.child_review?.reviewmedia_set && iteam?.child_review?.reviewmedia_set?.length > 1 ? (
                                      iteam?.child_review?.reviewmedia_set?.slice(1)?.map((reviewmediass, index1) => {
                                        return (
                                          <li key={index1}
                                            // onClick={() => this.setState({
                                            //   viewMyPhotosModal: !this.state.viewMyPhotosModal
                                            // })}
                                            className="p-1"
                                            role="button">
                                            {reviewmediass?.type == "image" ?
                                              <img
                                                className="img-thumbnail uploaded-img-thumbnail"
                                                src={reviewmediass?.location}
                                                alt={reviewmediass?.filename?.length < 20
                                                  ? reviewmediass?.filename
                                                  : this.truncate(reviewmediass?.filename)}
                                                onClick={() => {
                                                  this.handleOpenModal(iteam?.child_review?.reviewmedia_set, index1)
                                                }}
                                              />
                                              :
                                              <div>
                                                {/* <div className="video-holder">
                                                             <img
                                                                className="img-thumbnail uploaded-img-thumbnail"
                                                                src={reviewmediass?.url}
                                                                alt={reviewmediass?.filename?.length < 20
                                                                    ? reviewmediass?.filename
                                                                    : truncate(reviewmediass?.filename || '')}
                                                            /> */}
                                                <video width="125" height="105" controls>
                                                  <source
                                                    src={reviewmediass.url}
                                                    type="video/mp4"
                                                  />
                                                  {'Your browser does not support the video tag.'}
                                                </video>
                                              </div>
                                            }
                                          </li>
                                        );
                                      })) : ("")}
                                  </ul>
                                </div>
                              </div>
                            ) : ("")} 
                            {Array.isArray(iteam?.child_review?.child_embed_data?.body) && !!iteam?.child_review?.child_embed_data?.body?.length && <div>
                              <div className="mb-2"><span >Embedded Media</span></div>
                              <div style={{ display: "flex", width: '100%', flexWrap: 'wrap' }}>
                                {(JSON.parse(JSON.stringify(iteam?.child_review?.child_embed_data?.body)))?.map((link, index) => {
                                  return <div className="gallery-media2 mr-2 mb-2" style={{ width: "48%" }}>
                                    <a href={link.url} target="_blank" rel="noopener noreferrer" download="Image">
                                      {this.renderLinksWrapper({ body: link, index })}
                                    </a>
                                  </div>
                                })}
                              </div>
                            </div>
                            }
                            <Reactions
                              id={iteam?.child_review?.id}
                              counts={iteam?.child_review?.review_group_count}
                              statuses={iteam?.child_review?.review_group_status}
                              onClick={(reaction) =>
                                this.handleFeedReactions(reaction, iteam?.child_review?.id)
                              }
                              get_reviewgroup={this.props.get_reviewgroup}
                              get_reveiewData={this.props.get_reveiewData}
                            />

                            
                          </div>
                          <div className="d-flex justify-content-between">
                          <div>
                              <div className="fs-14">
                                <div> <i className="d-inline-block mr-2">
                                  {'Was this review helpful?'}
                                </i>
                                  <span
                                    className={
                                      iteam?.child_review?.helpfulCount?.profile_ids?.includes(profileId)
                                        ? "font-weight-bold text-dark d-inline-block"
                                        : "font-weight-bold text-tertiary d-inline-block"
                                    }
                                    role="button"
                                    onClick={() =>
                                      this.props.post_review_group(
                                        iteam?.listing_id,
                                        { type: "helpful", review: iteam?.child_review?.id }
                                      )
                                    }
                                  >
                                    {"Yes"}
                                  </span>
                                  <span className="mx-1">-</span>
                                  <span
                                    href="#"
                                    className={
                                      !iteam?.child_review?.helpfulCount?.profile_ids?.includes(profileId)
                                        ? "font-weight-bold text-dark d-inline-block"
                                        : "font-weight-bold text-tertiary d-inline-block"
                                    }
                                    // className="font-weight-bold text-dark d-inline-block"
                                    role="button"
                                    onClick={() =>
                                      this.props.post_review_group(
                                        iteam?.listing_id,
                                        { type: "not_helpful", review: iteam?.child_review?.id }
                                      )
                                    }
                                  >
                                    {"No"}
                                  </span>
                                </div>
                                {iteam?.child_review?.helpfulCount?.helpful > 0 &&
                                  iteam?.child_review?.helpfulCount?.total > 0 ? (
                                  <div>
                                    <span className="font-weight-bold text-dark">{`${iteam?.child_review?.helpfulCount?.helpful} of ${iteam?.child_review?.helpfulCount?.total}`}</span>
                                    <i className="text-tertiary d-inline-block ml-2">
                                      {"people found this review as  helpful"}
                                    </i>
                                  </div>
                                ) : (
                                  <div>
                                    <span className="font-weight-bold text-dark">{`${iteam?.child_review?.helpfulCount?.helpful} `}</span>
                                    <i className="text-tertiary d-inline-block ml-2">
                                      {"people found this review as  helpful"}
                                    </i>
                                  </div>
                                )}
                              </div>
                            </div>
                          <div className="text-right">
                            <Button
                              color="link"
                              className="font-weight-bold mx-0 px-1 text-dark"
                              //   onClick={ () => {                                     
                              //     this.setState({
                              //      editreviewID: iteam?.child_review?.id,
                              //      categoryType: iteam?.child_review?.listing_type,
                              //      businseesId: iteam?.associated_to?.id,
                              //      editReveiw: iteam?.child_review,  
                              //     //  avgRateKey: iteam?.child_review?.review_voting_key                                                                            
                              //    });
                              //    this.loadEditBusinessDetailedList(iteam?.child_review,business_reviewDetail.results)                                        
                              //     this.handleChildReviewEdit(
                              //      iteam?.child_review,
                              //      iteam?.child_review?.id
                              //    );
                              //  }}
                              // onClick={
                              //   () => {
                              //     this.setState({
                              //       reviewValue: this.state.modalData && this.state.modalData.review.replace(/(<([^>]+)>)/gi, ""),
                              //       reviewId: ""
                              //     })
                              //     this.handleChildReviewEdit(iteam.child_review, iteam.child_review.id)
                              //   }
                              // }
                              onClick={() => {
                                this.setState({
                                  editreviewID: iteam?.child_review?.id,
                                  categoryType: iteam?.child_review?.listing_type,
                                  businseesId: iteam?.associated_to.id,
                                  editReveiw: iteam?.child_review,
                                  avgRateKey: iteam?.child_review?.review_voting_key,
                                  avg_rating_edit: +iteam?.child_review?.ratings_sets?.avg_rating?.[1] || 0,
                                });
                                this.loadEditBusinessDetailedList(iteam?.child_review)
                                this.handleChildReviewEdit(
                                  iteam?.child_review,
                                  iteam?.child_review?.id
                                );
                              }}
                            >
                              {'edit'}
                            </Button>
                            <Button color="link" className="font-weight-bold mx-0 px-1" onClick={() => {
                              this.setState({
                                deletereviewId: iteam.child_review.id,
                                deleteEntity: 'review'
                              });
                              this.confirmDeleteModalToggle();
                            }} style={{ color: "#2a6496" }}>
                              {'delete'}
                            </Button>

                          </div>
                          </div>


                        </Col>
                      </Row>
                    </div>
                  ) : null}

                  {/* review-bg  bg-white */}
                  
                  <div className={(!!iteam?.child_review?.review || iteam?.child_review?.review==="") ? "review-bg p-3 fs-14 text-dark mb-3" : "bg-white p-3 fs-14 text-dark mb-3"} style={{ borderLeft: '10px solid white', borderRight: '10px solid white', borderBottom: "10px solid white" }}>
                    <Row>
                      <Col xs={3}>
                        {!!!(iteam?.child_review?.review || iteam?.child_review?.review === "") && <UserInfoAvatar
                          item={iteam}
                          renderListImagesItem={this.renderListImagesItem}
                          renderAvatarListingimages={this.renderAvatarListingimages}
                          renderListingAwardImages={this.renderListingAwardImages}
                          renderMoreDetailsAward={this.renderMoreDetailsAward}
                          reviewStats={iteam?.award_rating_statistics?.stats}
                          listing_type={"business"}
                        />}
                      </Col>

                      <Col xs={9}>
                        {/* Review Detail */}
                        <div
                          hidden={iteam.child_review && iteam.child_review.draft != false && iteam.child_review.draft != true ? false : !!!iteam.parent_review}>
                          <span className="text-dark font-weight-bold fs-14">
                            {'Original Review'}
                          </span></div>
                        <div className="d-flex mx-n2 mb-2">
                          <div className="px-2 flex-grow-1">
                            <img
                              src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${iteam.ratings_sets.avg_rating[0]}`}
                              alt={iteam.ratings_sets.avg_rating[1]}
                              title={(iteam?.ratings_set?.avg_rating[1] && iteam?.ratings_set?.avg_rating[0] !== "norating.png") ? iteam?.ratings_set?.avg_rating[1] : `0 star`}
                            />
                            {
                              (iteam?.ratings_set?.avg_rating[0] === "norating.png" || iteam?.ratings_sets?.avg_rating[0] === "norating.png") ? <span className='zero-star-text'>
                                0 star
                              </span> : ""
                            }
                            <span className="font-weight-bold text-dark">
                              {" Overall Rating"}
                            </span>
                            <div className="text-tertiary mt-2">
                              <i className="font-weight-bold">
                                This review was written
                              </i>{" "}
                              {iteam.review_date_time}{" "}
                              {iteam.device_type
                                ? " via " + iteam.device_type
                                : ""}{" "}
                              in{" "}
                              <i className="font-weight-bold">
                                {iteam &&
                                  iteam.created_by &&
                                  iteam.created_by.location}
                              </i>
                            </div>
                          </div>
                          <div className="px-2 col-auto">
                            <div className="d-inline-flex flex-nowrap mx-n1">
                              {iteam?.is_draft && <div className='draft-text'><span>Draft</span></div>}
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="p-0 text-dark"
                                  size="sm"
                                  color="transparent"
                                >
                                  <div className="thumbpin-icon"></div>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="type-tertiary-alt"
                                  right
                                >
                                  {/* <DropdownItem
                                      className="fs-14"
                                      onClick={() =>
                                        this.addToList(
                                          "want list",
                                          iteam.id,
                                          iteam.pining_list &&
                                          iteam.pining_list[0]?.want_list[0]
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="heart"
                                        className="mr-2"
                                        fixedWidth
                                      />{" "}
                                      {iteam.pining_list &&
                                        iteam.pining_list[0]?.want_list[0]
                                        ? "Added"
                                        : "Add"}{" "}
                                      to want list
                                    </DropdownItem>
                                    <DropdownItem
                                      className="fs-14"
                                      onClick={() =>
                                        this.addToList(
                                          "favourite list",
                                          iteam.id,
                                          iteam.pining_list &&
                                          iteam.pining_list[0]?.favourite_list[0]
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="star"
                                        className="mr-2"
                                        fixedWidth
                                      />{" "}
                                      {iteam.pining_list &&
                                        iteam.pining_list[0]?.favourite_list[0]
                                        ? "Added"
                                        : "Add"}{" "}
                                      to favorite list
                                    </DropdownItem> */}
                                  <DropdownItem
                                    className="fs-14"
                                    onClick={() =>
                                      this.addToList(
                                        "watch list",
                                        iteam.id,
                                        iteam.pining_list &&
                                        iteam.pining_list[0]?.watch_list[0]
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon="eye"
                                      className="mr-2"
                                      fixedWidth
                                    />{" "}
                                    {iteam.pining_list &&
                                      iteam.pining_list[0]?.watch_list[0]
                                      ? "Added"
                                      : "Add"}{" "}
                                    to watch list
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="p-0 text-dark"
                                  size="sm"
                                  color="transparent"
                                >
                                  <div className="share-it-icon"></div>
                                </DropdownToggle>
                                <DropdownMenu
                                  className="type-tertiary-alt"
                                  right
                                >
                                  <DropdownItem className="fs-14">
                                    <FacebookShareButton
                                      url={`${getShareReviewURL(iteam?.listing_type, iteam.associated_to?.slug, iteam.associated_to?.id)}`}                                      
                                      quote={this.reviewShareTitle(iteam)}
                                      hashtag={this.state.fbHashTag}
                                    >
                                      {"Facebook"}
                                    </FacebookShareButton>
                                  </DropdownItem>
                                  <DropdownItem className="fs-14">
                                    <TwitterShareButton
                                      url={`${getShareReviewURL(iteam?.listing_type, iteam.associated_to?.slug, iteam.associated_to?.id)}`}  
                                      title={this.reviewShareTitle(iteam)}
                                      via='WikiReviews'
                                      // hashtags={this.state.twitterHashTags}
                                    >
                                      {"Twitter"}
                                    </TwitterShareButton>
                                  </DropdownItem>
                                  <DropdownItem
                                    className="fs-14"
                                    hidden
                                    onClick={() =>
                                      this.setState({
                                        shareByEmailModalToggle: true,
                                        shareByEmailId: iteam.id,
                                      })
                                    }
                                  >
                                    {"By Email"}
                                  </DropdownItem>
                                  <DropdownItem
                                    className="fs-14"

                                    onClick={() =>
                                      this.handleOnClickShareByEmail(iteam.id)
                                    }
                                  >
                                    {"By Email"}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              {iteam && iteam.is_review_flag ? (
                                ""
                              ) : (
                                (my_profile?.id !== get(iteam, 'created_by.id')) && (
                                  <UncontrolledDropdown>
                                    <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                      <div className="downfeed-icon"></div>
                                    </DropdownToggle>
                                    <DropdownMenu className="type-tertiary-alt" right>
                                      <li className="dropdown-item has-submenu to-left ff-base fs-14">
                                        Dispute this review
                                        <ul className="dropdown-submenu">
                                          <DropdownItem className="fs-14" onClick={() => this.handleDisputeThisReview(iteam, 'This review is fake')} >This review is fake</DropdownItem>
                                          <DropdownItem className="fs-14" onClick={() => this.handleDisputeThisReview(iteam, 'This review is not relevant')} >This review is not relevant</DropdownItem>
                                          <DropdownItem className="fs-14" onClick={() => this.handleDisputeThisReview(iteam, 'This review is inaccurate')}>This review is inaccurate</DropdownItem>
                                        </ul>
                                      </li>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Row>
                            {iteam.ratings_sets &&
                              Object.entries(iteam.ratings_sets).map((element, index) =>
                                element[0] !== "avg_rating" && element[1][0] !== "norating.png" && (
                                  <Col className="mb-2" md={6}>
                                    <div className="d-flex flex-nowrap align-items-start">
                                      <img
                                        className="mr-2"
                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${element[1][1] === "norating.png"
                                          ? 0
                                          : element[1][0]
                                          }`}
                                        alt={
                                          element[1][1] === "No Rating"
                                            ? "No Rating"
                                            : element[1][1]
                                        }
                                        title={
                                          element[1][1] === "No Rating"
                                            ? "0 star"
                                            : element[1][1]
                                        }
                                      />
                                      <span className="ff-alt text-wrap">
                                        {element[0]}
                                      </span>
                                    </div>
                                  </Col>
                                )
                              )}
                          </Row>
                        </div>
                        {/* Dispute review block */}
                        <div className="border border-secondary p-4 mb-3" hidden>
                          <div className="mb-3">
                            <Label
                              for="disputed_review"
                              className="text-primary-dark fs-14 font-weight-bold">
                              {" "}
                              {'Provide evidence and explanation for disputing the review in question'}
                            </Label>
                            <Input
                              type="textarea"
                              className="primary"
                              name="disputed_review"
                              id="disputed_review"
                              placeholder="Provide explanation..."
                            />
                          </div>
                          <Row className="mb-3" form>
                            <Col xs="auto" className="mb-3">
                              <div className="d-flex pr-3 pt-3">
                                <div>
                                  <div className="gallery-media">
                                    <img
                                      src={require("../../../assets/images/cvs-logo.jpg")}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="mx-n3 mt-n3">
                                  <Button
                                    color="dark"
                                    size="sm"
                                    title="Remove Media"
                                  >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                  </Button>
                                </div>
                              </div>
                            </Col>
                            <Col xs="auto" className="mb-3">
                              <div className="d-flex pr-3 pt-3">
                                <div>
                                  <div className="gallery-media">
                                    <img
                                      src={require("../../../assets/images/cvs-logo.jpg")}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="mx-n3 mt-n3">
                                  <Button
                                    color="dark"
                                    size="sm"
                                    title="Remove Media"
                                  >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                  </Button>
                                </div>
                              </div>
                            </Col>
                            <Col xs="auto" className="mb-3">
                              <div className="d-flex pr-3 pt-3">
                                <div>
                                  <div className="gallery-media">
                                    <img
                                      src={require("../../../assets/images/cvs-logo.jpg")}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="mx-n3 mt-n3">
                                  <Button
                                    color="dark"
                                    size="sm"
                                    title="Remove Media"
                                  >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div className="d-flex mx-n2">
                            <div className="px-2">
                              <Button color="white" size="sm">
                                <FontAwesomeIcon icon="camera" />
                              </Button>
                            </div>
                            <div className="px-2 ml-auto">
                              <Button color="primary" size="sm" className="mw">
                                {'Submit'}
                              </Button>
                              <Button color="primary" size="sm" className="mw">
                                {'Cancel'}
                              </Button>
                            </div>
                          </div>
                        </div>

                        <div className="position-relative" hidden={checkTextVisibilty(iteam.review)}>
                          <div className="ff-alt my-4">
                            <i className="icon-comma-left"></i>
                            <span className="comment-body-renderer" filter-value={iteam?.review}>
                              <Interweave content={detectlink(iteam.review)} />
                            </span>
                            <i className="icon-comma-right"></i>
                          </div>
                        </div>

                        {/* <div className="mb-3">
                          {this.filterItemsForVideos({
                            items: iteam.reviewmedia_set,
                          })}
                          <div className="text-center">
                            {iteam.reviewmedia_set.length > 0
                              ? this.renderFilteredImages({
                                items: iteam.reviewmedia_set,
                              })
                              : ""}
                          </div>
                        </div> */}
                        {iteam?.reviewmedia_set?.length > 0 ? (
                          <div >
                            {iteam?.reviewmedia_set.slice(0, 1).map((reviewmedia, index) => (
                              <div key={index}>
                                {reviewmedia.type === "image" && (
                                  <div className="mb-3" style={{ maxWidth: "450px" }}>
                                    <img
                                      src={reviewmedia.url}
                                      alt={reviewmedia.filename?.length < 20 ? reviewmedia.filename : this.truncate(reviewmedia.filename || '')}
                                      className="img-fluid"
                                    />
                                  </div>
                                )}
                                {reviewmedia.type === "video" && (
                                  <video width="320" height="240" controls>
                                    <source
                                      src={reviewmedia.url}
                                      type="video/mp4"
                                    />
                                    {'Your browser does not support the video tag.'}
                                  </video>
                                )}
                              </div>
                            ))}
                            <div className="mb-3">
                              {/* List of Photos uploaded */}
                              <ul className="list-unstyled d-flex flex-wrap mx-n1 mb-2" style={{ maxHeight: '280px', overflowY: 'auto' }}>
                                {iteam.reviewmedia_set && iteam.reviewmedia_set?.length > 1 ? (
                                  iteam.reviewmedia_set?.slice(1)?.map((reviewmediass, index1) => {
                                    return (
                                      <li key={index1}
                                        // onClick={() => this.setState({
                                        //   viewMyPhotosModal: !this.state.viewMyPhotosModal
                                        // })}
                                        className="p-1"
                                        role="button">
                                        {reviewmediass?.type == "image" ?
                                          <img
                                            className="img-thumbnail uploaded-img-thumbnail"
                                            src={reviewmediass?.location}
                                            alt={reviewmediass?.filename?.length < 20
                                              ? reviewmediass?.filename
                                              : this.truncate(reviewmediass?.filename)}
                                            onClick={() => {
                                              this.handleOpenModal(iteam.reviewmedia_set, index1)
                                            }}
                                          />
                                          :
                                          <div>
                                            {/* <div className="video-holder">
                                                             <img
                                                                className="img-thumbnail uploaded-img-thumbnail"
                                                                src={reviewmediass?.url}
                                                                alt={reviewmediass?.filename?.length < 20
                                                                    ? reviewmediass?.filename
                                                                    : truncate(reviewmediass?.filename || '')}
                                                            /> */}
                                            <video width="125" height="105" controls>
                                              <source
                                                src={reviewmediass.url}
                                                type="video/mp4"
                                              />
                                              {'Your browser does not support the video tag.'}
                                            </video>
                                          </div>
                                        }
                                      </li>
                                    );
                                  })) : ("")}
                              </ul>
                            </div>
                          </div>
                        ) : ("")} 
                        {Array.isArray(iteam?.embeded_video?.body) && !!iteam?.embeded_video?.body?.length && <div>
                          <div className="mb-2"><span >Embedded Media</span></div>
                          <div style={{ display: "flex", width: '100%', flexWrap: 'wrap' }}>
                            {(JSON.parse(JSON.stringify(iteam?.embeded_video?.body)))?.map((link, index) => {
                              return <div className="gallery-media2 mr-2 mb-2" style={{ width: "48%" }}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer" download="Image">
                                  {this.renderLinksWrapper({ body: link, index })}
                                </a>
                              </div>
                            })}
                          </div>
                        </div>
                        }
                        <div className="quick-reactions d-flex flex-wrap align-items-end mb-2">
                          {iteam?.review_group_count && iteam?.review_group_status ? (
                            <Reactions
                              id={iteam?.id}
                              counts={iteam?.review_group_count}
                              statuses={iteam?.review_group_status}
                              onClick={(reaction) => this.handleFeedReactions(reaction, iteam.id)}
                              get_reviewgroup={this.props.get_reviewgroup}
                              get_reveiewData={this.props.get_reveiewData}
                            />
                          ) : null}
                        </div>
                        <div className="text-tertiary mb-3 d-flex justify-content-between">
                          <div>
                          <div>
                            <i className="d-inline-block mr-2">
                              Was this review helpful?
                            </i>
                            <span
                              className={
                                iteam?.helpfulCount?.profile_ids?.includes(profileId)
                                  ? "font-weight-bold text-dark d-inline-block"
                                  : "font-weight-bold text-tertiary d-inline-block"
                              }
                              role="button"
                              onClick={() =>
                                this.props.post_review_group(
                                  this.props.businessId,
                                  { type: "helpful", review: iteam.id }
                                )
                              }
                            >
                              {"Yes"}
                            </span>
                            <span className="mx-1">-</span>
                            <span
                              href="#"
                              className={
                                !iteam?.helpfulCount?.profile_ids?.includes(profileId)
                                  ? "font-weight-bold text-dark d-inline-block"
                                  : "font-weight-bold text-tertiary d-inline-block"
                              }
                              // className="font-weight-bold text-dark d-inline-block"
                              role="button"
                              onClick={() =>
                                this.props.post_review_group(
                                  this.props.businessId,
                                  { type: "not_helpful", review: iteam.id }
                                )
                              }
                            >
                              {"No"}
                            </span>
                          </div>

                          {iteam?.helpfulCount?.helpful > 0 &&
                            iteam?.helpfulCount?.total > 0 ? (
                            <div>
                              <span className="font-weight-bold text-dark">
                                {`${iteam?.helpfulCount?.helpful} of ${iteam?.helpfulCount?.total}`}
                              </span>
                              <i className="text-tertiary d-inline-block ml-2">
                                {"people found this review as  helpful"}
                              </i>
                            </div>
                          ) : (
                            <div>
                              <span className="font-weight-bold text-dark">{`${iteam?.helpfulCount?.helpful} `}</span>
                              <i className="text-tertiary d-inline-block ml-2">
                                {"people found this review as  helpful"}
                              </i>
                            </div>
                          )}
                          </div>
                          {/*<div>
                            <span className="font-weight-bold text-dark">
                              {iteam.helpfulCount && iteam.helpfulCount.helpful}
                            </span>
                            <i className="text-tertiary d-inline-block ml-2">people found this review as  helpful</i>
                           </div> */}

                          <div className="text-right">
                            {iteam.created_by?.user?.username ===
                              loggedInUsername ? (
                              <>
                                <Button
                                  color="link"
                                  disabled={
                                    iteam.created_by?.user?.username ===
                                      loggedInUsername
                                      ? false
                                      : true
                                  }
                                  className="font-weight-bold mx-0 px-1 text-dark"
                                  onClick={async () => {
                                    this.setState({
                                      editreviewID: iteam.id,
                                      categoryType: iteam.listing_type,
                                      businseesId: iteam.associated_to.id,
                                      editReveiw: iteam,
                                      avgRateKey: iteam.review_voting_key,
                                      avg_rating_edit: +iteam.ratings_sets?.avg_rating?.[1] || 0,
                                    });
                                    this.loadEditBusinessDetailedList(iteam)
                                    await this.handleChildReviewEdit(
                                      iteam,
                                      iteam.id
                                    );
                                    this.handleMenuItem(iteam.associated_to.id, iteam.review_voting_key);
                                  }}
                                >
                                  {"edit"}
                                  {/* on click edit */}
                                </Button>
                                <Button
                                  color="link"
                                  disabled={
                                    iteam.created_by.user.username ===
                                      loggedInUsername
                                      ? false
                                      : true
                                  }
                                  className="font-weight-bold mx-0 px-1"
                                  onClick={() => {
                                    this.setState({
                                      deletereviewId: iteam.id,
                                    });
                                    this.confirmDeleteModalToggle();
                                  }}
                                  style={{ color: "#2a6496" }}
                                >
                                  {"delete"}
                                </Button>
                                {!!!iteam?.child_review?.review && <Button
                                  hidden={
                                    iteam.created_by.user.username ===
                                      loggedInUsername &&
                                      !iteam.is_draft
                                      ? false
                                      : true
                                  }
                                  color="link"
                                  className="font-weight-bold mx-0 px-1 text-primary"
                                  onClick={async () => {

                                    this.setState({
                                      writeFollowUp: true,
                                      followUPReviewID: iteam.id,
                                      followUPAssociatedTO:
                                        iteam.associated_to.id,
                                      // followUPReviewVotingKey:
                                      //   iteam.review_voting_key,
                                      followUPRating_sets: iteam.ratings_sets,
                                      followName: iteam.associated_to.name,
                                      mode: "followup",
                                      categoryType: iteam.listing_type,
                                    });
                                    this.handleMenuItem(iteam.associated_to.id, "");
                                  }}
                                >
                                  {"write follow up"}
                                </Button>}
                              </>
                            ) : (
                              <Button
                                color="link"
                                className="font-weight-bold mx-0 px-1 text-dark"
                                onClick={() => {
                                  this.setState({
                                    writeFollowUp: true,
                                    followUPReviewID: iteam.id,
                                    followUPAssociatedTO:
                                      iteam.associated_to.id,
                                    // followUPReviewVotingKey:
                                    //   iteam.review_voting_key,
                                    followUPRating_sets: iteam.ratings_sets,
                                    followName: iteam.associated_to.name,
                                    mode: "ownreview",
                                  });
                                }}
                              >
                                {"write your own review"}
                              </Button>
                            )}
                          </div>


                        </div>

                        {/* ---------------- */}
                        {/* review_discussion */}
                        <div className="link-embed-items-here">


                          {iteam.review_discussion.map((postList) => (
                            <Media className={(!!iteam?.child_review?.review || iteam?.child_review?.review==="") ? "media-post mt-1 review-bg p-3" : "media-post mt-1 bg-white p-3"}>
                              <Media>
                                <Media
                                  object
                                  src={postList?.sender?.current_profile_pic ? postList.sender.current_profile_pic : require("../../../assets/images/icons/user-circle.png")}
                                  alt="User Image"
                                />
                              </Media>
                              <Media body>
                                <Media className="mb-0" heading>
                                  <div className="d-flex flex-nowrap mx-n2">
                                    <div className="px-2 flex-grow-1">
                                      <span className="text-darker fs-14">
                                        {/* {replied to code will add here } */}
                                        <Link className="text-darker nav-link-decoration-none" to={`people/${postList?.sender?.username ? postList?.sender?.username : postList?.sender?.id }`}>
                                        {postList.sender && postList.sender.full_name}
                                        </Link>
                                      </span>
                                      {/* Comments */}
                                      <span className="wb-break-word fs-14 font-weight-normal text-tertiary ff-alt">
                                      {` replied to `} 
                                        <Link className={`fs-14 font-weight-bold tertiary-link nav-link-decoration-none ${iteam?.is_anonymous ? 'disable-link' : ''}`} to={`/people/${iteam?.created_by?.user?.username ? iteam?.created_by?.user?.username : iteam?.created_by?.user?.id}`}>{`${iteam?.created_by?.user?.first_name} ${iteam?.created_by?.user?.last_name}`}</Link>
                                      </span>
                                      <span className="ml-2 fs-14 font-weight-normal text-tertiary">{postList.sender && postList.sent_at}</span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-nowrap mx-n2 my-2">
                                    <div className="px-2 flex-grow-1">
                                      <span className="wb-break-word fs-14 d-flex font-weight-normal text-dark ff-alt">
                                        {postList.body}
                                        {/* Remove Actions if comment by other user  */}
                                        <div className="align-self-end ml-auto col-auto px-2">
                                          {iteam.created_by &&
                                            postList.sender &&
                                            postList.sender.id ===
                                            my_profile.id && (
                                              <div className="ml-auto" hidden={!this.checkIfLoggedIn()}>
                                                <EditBtn
                                                  onClick={() =>
                                                    this.updateCommentFunction(
                                                      postList,
                                                      iteam.id
                                                    )
                                                  }
                                                />
                                                <DeleteBtn
                                                  onClick={() =>
                                                    this.deleteCommentFunction(
                                                      postList.id
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </Media>
                                <div>
                                  <div className="d-flex flex-nowrap mx-n2 mt-n1">
                                    <div className="align-self-end flex-grow-1 px-2">
                                      <span className="fs-14 ff-alt text-tertiary align-middle">

                                      </span>
                                    </div>

                                    
                                  </div>
                                </div>
                                {/* Show when editing your comment */}
                                <div hidden={postList.id !== commentId}>
                                  <FormGroup className="mb-0 mt-3">
                                    <Input
                                      bsSize="sm"
                                      className="mb-2"
                                      type="textarea"
                                      name="commentMessage"
                                      value={commentMessage}
                                      onChange={this.handleChange}
                                      placeholder="Editing your comment"
                                    />
                                    <div className="text-right">
                                      <div className="text-right">
                                        <Button
                                          disabled={commentMessage.length ? false : true}
                                          size="sm"
                                          color="primary"
                                          className=" mr-2"
                                          onClick={() => this.updateComments("update")}>
                                          {"Update"}
                                        </Button>
                                        <Button
                                          size="sm"
                                          color="primary"
                                          className="ml-0"
                                          onClick={this.closeComments}>
                                          {"Cancel"}
                                        </Button>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </div>

                                {/* Hide when editing your comment */}

                                <div>{/* Comments */}</div>
                              </Media>
                            </Media>
                          ))}
                          {/* Comment by other user */}
                          {/* My Comment */}
                        </div>
                        <Media className="media-post">
                          <Media>
                            <Media
                              object
                              src={my_profile?.current_profile_pic ? my_profile.current_profile_pic : require("../../../assets/images/icons/user-circle.png")}
                              alt="User Image"
                            />
                          </Media>
                          <Media body>
                            <FormGroup className="mb-0">
                              <Input
                                bsSize="sm"
                                className="mb-2 comment-form-control"
                                type="textarea"
                                name="commentMessageOuter"
                                value={commentMessageOuter}
                                onChange={this.handleChange}
                                placeholder="Add a comment..."
                              />
                              <div className="text-right">
                                <div className="text-right">
                                  <Button
                                    disabled={commentMessageOuter.length ? false : true}
                                    size="sm"
                                    color="primary"
                                    onClick={() => this.updateComments("add", iteam.id)}>
                                    {"Submit"}
                                    {/* {'Here Submit Comment'} */}
                                  </Button>
                                </div>
                              </div>
                            </FormGroup>
                          </Media>
                        </Media>
                      </Col>
                    </Row>
                  </div>
                  {(!!iteam?.child_review?.review || iteam?.child_review?.review==="") && <div className={"bg-white fs-14 text-dark"}>
                    <Row>
                      <Col xs={12} >
                        {/* review_discussion */}
                        <div className="bg-white-shaded-md p-2 link-embed-items-here">



                          {iteam.child_review.review_discussion.map((postList) => (
                            <Media className="media-post mt-3">
                              <Media>
                                <Media
                                  object
                                  src={require("../../../assets/images/icons/user-circle.png")}
                                  alt="User Image"
                                />
                              </Media>
                              <Media body>
                                <Media className="mb-0" heading>
                                  <div className="d-flex flex-nowrap mx-n2">
                                    <div className="px-2 flex-grow-1">
                                      <span className="text-darker fs-14">
                                        {postList.sender && postList.sender.full_name}{": "}
                                      </span>
                                      {/* Comments */}
                                      <span className="wb-break-word d-flex fs-14 font-weight-normal text-dark ff-alt">
                                        {postList.body}

                                        {/* Remove Actions if comment by other user  */}
                                        <div className="align-self-end ml-auto col-auto px-2">
                                          {iteam.created_by &&
                                            postList.sender &&
                                            postList.sender.id ===
                                            my_profile.id && (
                                              <div className="ml-auto" hidden={!this.checkIfLoggedIn()}>
                                                <EditBtn
                                                  onClick={() =>
                                                    this.updateCommentFunction(
                                                      postList,
                                                      iteam.id
                                                    )
                                                  }
                                                />
                                                <DeleteBtn
                                                  onClick={() =>
                                                    this.deleteCommentFunction(
                                                      postList.id
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                </Media>
                                <div>
                                  <div className="d-flex flex-nowrap mx-n2 mt-n1">
                                    <div className="align-self-end flex-grow-1 px-2">
                                      <span className="fs-14 ff-alt text-tertiary align-middle">
                                        {postList.sender && postList.sent_at}
                                      </span>
                                    </div>

                                    
                                  </div>
                                </div>
                                {/* Show when editing your comment */}
                                <div hidden={postList.id !== commentId}>
                                  <FormGroup className="mb-0 mt-3">
                                    <Input
                                      bsSize="sm"
                                      className="mb-2"
                                      type="textarea"
                                      name="commentMessage"
                                      value={commentMessage}
                                      onChange={this.handleChange}
                                      placeholder="Editing your comment"
                                    />
                                    <div className="text-right">
                                      <div className="text-right">
                                        <Button
                                          size="sm"
                                          color="primary"
                                          className=" mr-2"
                                          onClick={() =>
                                            this.updateComments("update")
                                          }
                                        >
                                          {"Update"}
                                        </Button>
                                        <Button
                                          size="sm"
                                          color="primary"
                                          className="ml-0"
                                          onClick={this.closeComments}
                                        >
                                          {"Cancel"}
                                        </Button>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </div>

                                {/* Hide when editing your comment */}

                                <div>{/* Comments */}</div>
                              </Media>
                            </Media>
                          ))}
                          {/* Comment by other user */}
                          {/* My Comment */}
                        </div>
                        {/* Comment box */}
                        <div className="bg-white-shaded-md p-2 mt-2">
                          <Media className="media-post">
                            <Media>
                              <Media
                                object
                                src={iteam?.created_by_profile_pic ? iteam?.created_by_profile_pic : (iteam?.createdby_current_profile_pic ? iteam.createdby_current_profile_pic : require("../../../assets/images/icons/user-circle.png"))}
                                alt="User Image"
                              />
                            </Media>
                            <Media body>
                              <FormGroup className="mb-0">
                                <Input
                                  bsSize="sm"
                                  className="mb-2 comment-form-control"
                                  type="textarea"
                                  name="commentMessageAdd"
                                  value={commentMessageAdd}
                                  onChange={this.handleChange}
                                  placeholder="Add a comment..."
                                />
                                <div className="text-right">
                                  <div className="text-right">
                                    <Button
                                      size="sm"
                                      color="primary"
                                      onClick={() =>
                                        this.updateComments("add", iteam.child_review.id)
                                      }
                                    >
                                      {"Submit"}
                                      {/* {'Here Submit Comment'} */}
                                    </Button>
                                  </div>
                                </div>
                              </FormGroup>
                            </Media>
                          </Media>
                        </div>

                      </Col>
                    </Row>
                  </div>}
                  {/* // write followup */}

                  <RatingCollapse
                    isOpen={
                      this.state.writeFollowUp &&
                      this.state.followUPReviewID === iteam.id
                    }
                  >
                    <div>
                      {/* write your own review, Part 2 */}
                      <div>
                        <div className="py-3">
                          <h2 className="text-dark fs-36">
                            {mode == "followup"
                              ? "You're writing a follow up review for "
                              : "You're writing a review for "}
                            <span className="text-darker">{followName}</span>
                          </h2>
                        </div>

                        {/* Step 1 Collapse */}
                        <div className="bg-white mb-3">
                          <CollapseBasic
                            title="Step 1. Star Ratings"
                            bodyClass="text-dark mb-3"
                            containerClass="type-tertiary type-review bg-transparent"
                            isOpen={true}
                            size="sm"
                            noPadding={true}
                            isReviewCollapse={true}
                          >
                            <p className="text-tertiary fs-14">
                              Roll over the stars and click to rate. <span className="text-dark ff-alt hover-underline" role="button"
                                onClick={() => this.setState({ showRevTips: !this.state.showRevTips })}> Review Tips & Guidelines.</span>
                            </p>

                            {/* Review Tips & Guidelines */}
                            {this.state.showRevTips ? <div className="mb-3" id="reviewTipsAndGuideLinesFollowup">
                              <div className="text-right">
                                <Button color="tertiary" className="text-white" size="sm" onClick={() => this.setState({ showRevTips: false })}><FontAwesomeIcon icon="times" /> </Button>
                              </div>
                              <div className="bg-tertiary text-white p-2 fs-14" >
                                <ul className="list-normal mb-0">
                                  <li className="ff-base mb-2">Do not post photos or videos of other copyrighted material that you don’t have permission to use. Do not post photos of people without their permission. If they are children, ask the parents before you post.</li>
                                  <li className="ff-base mb-2">Opinions are almost always protected as free speech except in the case of defamation (a false accusation of an offense or a malicious misrepresentation of someone's words or actions), true threats, solicitations to commit crimes, incitement to imminent lawless action, blackmail, fighting words and obscenity).</li>
                                  <li className="ff-base mb-2">Please omit excessive exaggeration and do not lie.</li>
                                  <li className="ff-base mb-2">Deliberately sabotaging the viability of a business is strongly discouraged.</li>
                                  <li className="ff-base mb-2">Be honest and truthful in your reviews.</li>
                                  <li className="ff-base mb-2">Please be accurate, avoid embellishment and broad generalizations, and to relate only to your first hand experiences.</li>
                                </ul>
                              </div>
                            </div> : ""}

                            {/* Star Input */}

                            <div className="d-flex align-items-center">
                              <Label className="text-dark font-weight-bold text-nowrap mr-3 fs-16">
                                {"Overall Rating*"}
                              </Label>
                              <div>
                                <div className="stars-rating-input _lg">
                                  <span
                                    onMouseEnter={() => {
                                      if (!this.checkIfLoggedIn()) {
                                        this.forceToggleLoginModal();
                                        return false;
                                      }
                                      this.setState({ enableRatingText: 0, enableHoverBanIcon: true })
                                    }}
                                    onMouseLeave={() => { this.setState({ enableRatingText: null, enableHoverBanIcon: false }) }}
                                    className="zero-rating-input" role="button" onClick={() => this.ratingChanged(0, 'avg_rating')}>
                                    <FontAwesomeIcon
                                      icon="ban"
                                      onClick={() => this.ratingChanged(0, 'avg_rating')}
                                      color={enableHoverBanIcon ? '#15c3d4' : '#d7d8e2'}
                                      title="0"
                                    />
                                  </span>
                                  <span title={this.state.enableRatingText}>
                                    <Rating
                                      fractions={2}
                                      emptySymbol={empty_star_rating}
                                      fullSymbol={full_star_rating}
                                      onHover={(rate) => {
                                        if (enableHoverBanIcon) {
                                          this.setState({ enableHoverBanIcon: false });
                                        }
                                        if (rate !== this.state.enableRatingText) {
                                          this.setState({ enableRatingText: rate })
                                        }
                                      }}
                                      initialRating={this.state.avg_rating}
                                      onChange={(newRating) => this.ratingChanged(newRating, 'avg_rating')}
                                    />
                                  </span>
                                </div>
                                <div style={{ color: "red" }}>
                                  {this.state.followAverageError}
                                </div>
                              </div>
                            </div>

                            <div className="mt-2">
                              <div className="text-tertiary">
                                <span className="font-weight-bold">
                                  {"Detailed Ratings"}
                                </span>{" "}
                                {"(optional)"}{" "}
                              </div>
                              <hr className="bg-light" />
                              <Row className="fs-14 ff-alt">
                                {businessRatingList?.map((iteam) => (
                                  <Col className="mb-2" md={6} lg={4}>
                                    <div>{iteam.label}</div>
                                    {/* <div>{iteam.key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')}</div> */}
                                    <div className="stars-rating-input">
                                      <span className="zero-rating-input" role="button">
                                        <FontAwesomeIcon
                                          icon="ban"
                                          color={this.checkColorTrue(iteam.key) ? '#15c3d4' : '#d7d8e2'}
                                          onMouseEnter={() => this.onHoverEnableDisable(iteam.key, true)}
                                          onMouseLeave={() => this.onHoverEnableDisable(iteam.key, false)}
                                          onClick={() => {
                                            this.ratingChanged(0, iteam.label, iteam.key);
                                          }}
                                        />
                                      </span>

                                      <span title={this.state.titleRatingText}>
                                        <Rating
                                          fractions={2}
                                          emptySymbol={empty_star_rating_below}
                                          fullSymbol={full_star_rating_below}
                                          initialRating={!businessRatingList.filter(itm => itm.key === iteam.key)?.[0]?.isEnable ? this.findAndSetItem(iteam.name) : 0}
                                          onChange={(newRating) => {
                                            this.ratingChanged(newRating, iteam.label, iteam.key)
                                          }}
                                        />
                                      </span>
                                      <span
                                        onClick={() => {
                                          this[iteam.label] = false;
                                          this.ratingChanged(0, iteam.label, iteam.key)
                                        }}
                                        className="clear-rating"
                                        role="button"
                                        title="Clear">
                                        <span className="icon-cross"></span>
                                      </span>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                            {businessDetails?.taxonomy_list && indexOf(businessDetails.taxonomy_list, 'Restaurants') > -1 && (rateMenu && rateMenu?.length) ?
                              <div className="p-3 bg-light">
                                <CollapseBasic
                                  title="You can rate menu items too!"
                                  containerClass="bg-transparent type-tertiary"
                                  bodyClass="mt-3 text-tertiary"
                                  isOpen={false}
                                  size="sm"
                                >
                                  <div className="mb-4">
                                    <Nav className="active-transparent border-0" tabs>
                                      {rateMenu && rateMenu.map((menuItem, index) => {
                                        return <NavItem>
                                          <NavLink className="text-truncate"
                                            href="#"
                                            active={this.state.activeMenuType === menuItem}
                                            onClick={() => {
                                              this.setState({ activeMenuType: menuItem });
                                              const ratingKey = iteam?.review_voting_key
                                              this.fetchRateMenuItemContent({ businessId, menuItem, ratingKey })

                                            }}
                                            key={index}
                                          >
                                            <span className="font-weight-bold ff-base fs-16">
                                              {menuItem}
                                            </span>
                                          </NavLink>
                                        </NavItem>
                                      })}
                                    </Nav>
                                  </div>
                                  <TabContent activeTab={this.state.activeMenuType}>
                                    <TabPane tabId={this.state.activeMenuType}>
                                      {rateMenuContent && rateMenuContent.map((menu, indexItem) => {
                                        return <CollapseBasic title={menu.menuItem} containerClass="bg-transparent type-tertiary" bodyClass="fs-14 mt-3 text-tertiary" size="sm" isOpen={false}>
                                          <Row>
                                            {/* Repeat this for items */}
                                            {menu?.menuContent?.map((menuDetail, index) => {
                                              return <Col className="mb-3" lg={6}>
                                                <div className="d-flex flex-nowrap mx-n2">
                                                  <div className="px-2">
                                                    <img className="menu_img" width="50" src={this.state.menuMediaListMedia?.[menuDetail?.menuItemContent?.[4]]?.[0]?.thumbnail || 'https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png'} alt="" />
                                                  </div>
                                                  <div className="px-2">
                                                    <div className="d-flex">
                                                      <div className="mr-2">
                                                        <span className="text-dark mr-2 font-weight-bold">{menuDetail.menuItemName}</span>
                                                        <div>
                                                          <div className="stars-rating-input _sm">
                                                            <span className="zero-rating-input" role="button">
                                                              <FontAwesomeIcon icon="ban"
                                                                onClick={() => this.ratingChangedMenuItem(0, menuDetail.menuItemContent[4], iteam?.review_voting_key)}
                                                                color={+menuDetail.menuItemContent[1] === 0 ? '#15c3d4' : '#d7d8e2'}
                                                              />
                                                            </span>
                                                            <StarsRating
                                                              count={5}
                                                              value={+menuDetail.menuItemContent[1]}
                                                              size={18}
                                                              color1={'#d7d8e2'}
                                                              color2={'#15c3d4'}
                                                              onChange={(newRating) => {
                                                                this.ratingChangedMenuItem(newRating, menuDetail.menuItemContent[4], iteam?.review_voting_key)
                                                              }}
                                                            />
                                                            {/* <span className="ml-2 d-inline-block times-2" role="button" title="Clear">
                                                                                                                    <span className="icon-cross"></span>
                                                                                                                </span> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="text-dark">
                                                      <p className="fs-14 mb-0">
                                                        {menuDetail.menuItemContent[2]}
                                                      </p>
                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                      {this.state.menuMediaListMedia?.[menuDetail?.menuItemContent?.[4]]?.map(img => {

                                                        return <div className="px-2 py-2 position-relative" >
                                                          <img className='menu_img' width="50" src={img?.thumbnail || 'https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png'} alt="" />
                                                          <FontAwesomeIcon onClick={() => this.handleDeleteMenuMedia(menuDetail?.menuItemContent[4], img?.id)} color="red" icon="trash-alt" style={{ position: "absolute", top: 0, right: "10px", cursor: "pointer" }} />
                                                        </div>
                                                      })}
                                                    </div>
                                                    <div className="mt-1">
                                                      <Button color="link" className="text-dark fs-14 font-weight-bold p-0"
                                                        onClick={() => {
                                                          this.handleCloseAndFetch();
                                                          this.setState({
                                                            uploadType: "menuItem",
                                                            menuTitle: menu.menuItem,
                                                            menuSubTitle: menuDetail.menuItemName,
                                                            menuObjectPk: menuDetail.menuItemContent[4]
                                                          })
                                                        }}>
                                                        <FontAwesomeIcon icon="camera" className="mr-2" />
                                                        Add Image
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Col>
                                            })}


                                          </Row>
                                        </CollapseBasic>
                                      })}

                                    </TabPane>
                                  </TabContent>
                                </CollapseBasic>
                              </div>
                              : ''
                            }
                            
                          </CollapseBasic>
                        </div>

                        {/* Step 2 Collapse */}
                        <div className="bg-white mb-3">
                          <CollapseBasic
                            title="Step 2. Review"
                            bodyClass="text-dark mb-3"
                            containerClass="type-tertiary type-review bg-transparent"
                            isOpen={true}
                            size="sm"
                            noPadding={true}
                            isReviewCollapse={true}
                          >
                            <div className="text-tertiary fs-14 mb-3">
                              {
                                "Please provide as much information as you can in your review to be as meaningful as possible to others."
                              }
                            </div>
                            <Nav tabs className="mb-3 active-transparent">
                              <NavItem>
                                <NavLink
                                  href="#"
                                  className="ff-base font-weight-normal"
                                  active={
                                    this.state.viewEditReviewStep ===
                                    "writeReview"
                                  }
                                  onClick={() => {
                                    this.setState({
                                      viewEditReviewStep: "writeReview",
                                    });

                                    if ((this.state.clickedOnWriteAReview == 0 || this.state.clickedOnWriteAReview == 1)) {
                                      this.setState({ clickedOnWriteAReview: 1 });
                                  } else {
                                      this.setState({ viewEditReviewStep: "writeReview" })
                                  }

                                 
                                  if (this.state.write_new_review?.length > 50) {
                                    this.setState({ enableErrorForReview: false })
                                  }
                                  }}
                                >
                                  <span className="font-weight-bold">
                                    Write a Review
                                  </span>
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  href="#"
                                  className="ff-base font-weight-normal"
                                  active={
                                    this.state.viewEditReviewStep ===
                                    "recordVideo"
                                  }
                                  onClick={() => {
                                    this.setState({
                                      viewEditReviewStep: "recordVideo",
                                      clickedOnWriteAReview: 0,
                                      enableErrorForReview: !stepTwoUploadedVideo.length                                      
                                    });
                                  }}
                                >
                                  {"or record a"}
                                  <span className="font-weight-bold">
                                    &nbsp; Video Review
                                  </span>
                                </NavLink>
                              </NavItem>
                            </Nav>

                            <TabContent
                              className="mb-3"
                              activeTab={this.state.viewEditReviewStep}
                            >
                              <TabPane tabId="writeReview">
                                <FormGroup className="main-post-formgroup">
                                  <div className="input-label-block">
                                    <Input
                                      className="primary wk-client-write-review"
                                      type="textarea"
                                      name="write_new_review"
                                      rows="4"
                                      id="write_new_review"
                                      value={write_new_review}
                                      onChange={this.handelChangeReview}
                                      placeholder="&nbsp;"
                                    />
                                    {errors?.write_new_review?.length > 0 && (
                                      <span style={{ color: "red" }}>
                                        {errors.write_new_review}
                                      </span>
                                    )}
                                  </div>
                                  <div className="d-flex">
                                    <div className="px-2 col">
                                      <div className="characters-length-text">
                                        Characters:{" "}
                                        <span>{write_new_review.length}</span>
                                      </div>
                                    </div>
                                  </div>
                                </FormGroup>
                              </TabPane>
                              <TabPane tabId="recordVideo">
                                <div>
                                  {this.state.viewEditReviewStep ===
                                    "recordVideo" && <WebCamRecording
                                      setClickedTabOperation={this.setClickedTabOperation}
                                      clickedOnWriteAReview={this.state.clickedOnWriteAReview}
                                      stepTwoUploadedVideo={this.state.stepTwoUploadedVideo}
                                      addUploadedVideo={this.addUploadedVideo}
                                      removeUploadedVideo={this.removeUploadedVideo} />}
                                       {viewEditReviewStep === "recordVideo" && (
                                        <FormGroup className="main-post-formgroup">
                                            <div className="input-label-block">
                                                <Input
                                                    className="primary"
                                                    type="textarea"
                                                    name="write_new_review_vedio"
                                                    rows="4"
                                                    value={writeAReviewTextVideo}
                                                    id="write_new_review_video"
                                                    onChange={(event) => { this.setState({ writeAReviewTextVideo: event.target.value }) }}
                                                    placeholder=""
                                                />
                                                {/* {writeAReviewTextVideo.length < 50 && writeAReviewTextVideo.length !== 0 ?
                                                    <span style={{ color: "red" }}>
                                                        {'This field contains a minimum of 50 characters.'}
                                                    </span>
                                                    : ""} */}
                                                 
                                           
                                            {errors?.writeAReviewTextVideo?.length > 0 && (
                                                <span style={{ color: "red" }}>
                                                    {errors.writeAReviewTextVideo}
                                                </span>
                                            )}
                                            </div>
                                            <div className="d-flex">


                                                <div className="px-2 col">
                                                    <div className="characters-length-text">
                                                        {'Characters:'} <span>{writeAReviewTextVideo.length ? writeAReviewTextVideo.length : '0'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>

                                    )}
                                </div>
                              </TabPane>
                            </TabContent>

                            <div className="fs-14">
                              <div className="text-dark">
                                <span className="font-weight-bold">
                                  Choose Review Categories
                                </span>
                                <span>(Optional)</span>
                              </div>
                              <hr className="bg-light my-2" />
                              <p className="text-tertiary fs-14 mb-2">
                                If your review above contains information about
                                a particular element or elements that you would
                                like to highlight, please check the
                                corresponding boxes below.
                              </p>
                              <Row>
                                {reviewsCategories.res &&
                                  reviewsCategories.res.map((iteam, index) => (
                                    <Col xs={6} lg={4}>
                                      <FormGroup check>
                                        <Label
                                          className="text-primary font-weight-bold"
                                          check
                                        >
                                          <Input
                                            type="checkbox"
                                            id="checkbox2"
                                            name={iteam.label}
                                            onChange={(e) =>
                                              this.ratingChangedForCheck(
                                                e.target.checked,
                                                iteam
                                              )
                                            }
                                          />{" "}
                                          {iteam.label}
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                  ))}
                              </Row>
                            </div>
                          </CollapseBasic>
                        </div>

                        {/* Step 3 Collapse */}
                        <div className="bg-white mb-3">
                          <CollapseBasic
                            title="Step 3. Upload Images and/or Video (optional)"
                            bodyClass="mb-3"
                            containerClass="type-tertiary type-review bg-transparent"
                            isOpen={true}
                            size="sm"
                            noPadding={true}
                            isReviewCollapse={true}
                          >
                            <p className="text-tertiary fs-14 mb-3">
                              Upload images or video to accompany your review.
                              Additional images/videos can be added later.
                            </p>
                            {/* Uploaded media shown here */}
                            {
                              mediaSetReview.length || embedBody.length
                                ?
                                <div className="border mb-3">
                                  <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                    {mediaSetReview.map((iteam) => (
                                      <Col className="mr-4">
                                        <div className="d-flex pr-3 m-2">
                                          <div className="gallery-media d-flex justify-content-center">
                                            {this.renderPhotosAndVideos(
                                              iteam,
                                              true
                                            )}
                                          </div>
                                          <div className="mx-n3 mt-2">
                                            <Button color="delete-media" size="sm" title="Remove Media"
                                              onClick={(e) => {
                                                this.removeFromMedia(e, iteam);
                                              }}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                          </div>
                                        </div>
                                      </Col>
                                    ))}
                                    {embedBody && Array.isArray(embedBody) && embedBody.length ?
                                      embedBody.map((item, uploaded_index) => (
                                        <Col key={uploaded_index} className="mr-4">
                                          <div className="d-flex pr-3 m-2">
                                            <div>
                                              {/* {item.type === "embedded_link" &&  */}
                                              <div>
                                                <div className="gallery-media d-flex justify-content-center">
                                                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                    <img
                                                      className="img-fluid img-thumbnail h-100"
                                                      src={item.thumbnail || require("../../../assets/images/embedded_link_img.png")}
                                                      id={`embedded_link-${uploaded_index}`}
                                                      alt={item.url}
                                                    />
                                                    {/* <UncontrolledTooltip
                                                            autohide="false"
                                                            placement="top"
                                                            target={`embedded_link-${uploaded_index}`}
                                                          >
                                                            {item.url}
                                                          </UncontrolledTooltip> */}
                                                  </a>
                                                </div>
                                              </div>

                                              {/* } */}
                                            </div>
                                            <div className="mx-n3 mt-2">
                                              <Button onClick={() => { this.removeEmbeddedEditReview(uploaded_index, item) }} color="delete-media" size="sm" title="Remove Media">
                                                <FontAwesomeIcon icon="trash-alt" />
                                              </Button>
                                            </div>
                                          </div>
                                        </Col>
                                      )) : null}
                                  </Row>
                                </div>
                                :
                                ''
                            }

                            <Button
                              color="tertiary"
                              className="font-weight-bold bg-tertiary border-tertiary"
                              onClick={this.handleCloseAndFetchReview}
                            >
                              {"Upload Media"}
                            </Button>
                          </CollapseBasic>
                        </div>
                        {/* Final Step */}
                        <div className="bg-white p-2 mb-3">
                          <div className="mb-3">
                            <FormGroup check>
                              <Input
                                type="checkbox"
                                id="checkbox2"
                                checked={shocialNetworkSharing}
                                disabled={reviewAnonymous}
                                name="shocialNetworkSharing"
                                onChange={this.handelChangeReview}
                              />
                              <Label
                                className="text-tertiary fs-14 ff-alt"
                                check
                              >
                                {" "}
                                {"Also post this review on social networks."}
                              </Label>
                            </FormGroup>
                            <div className="mt-3 pl-4">
                              {/* <Button color="primary">Post To Facebook</Button> */}
                              <FacebookShareButton
                                url={`${this.state.protocol}//${this.state.host}/business/${businessId}`}
                                quote={this.state.write_new_review}
                                className="text-dark text-decoration-none mx-2"
                              >
                                <Button color={(this.state.shocialNetworkSharing == false) ? "tertiary reset" : "primary"} className={(this.state.shocialNetworkSharing == false) ? "socialpressed" : ""} disabled={(this.state.shocialNetworkSharing == false) ? true : false}>
                                  Post To Facebook
                                </Button>
                              </FacebookShareButton>

                              <TwitterShareButton
                                url={`${this.state.protocol}//${this.state.host}/business/${businessId}`}
                                title={this.state.write_new_review}
                                className="text-dark text-decoration-none mx-2"
                              >
                                <Button color={(this.state.shocialNetworkSharing == false) ? "tertiary reset" : "primary"} className={(this.state.shocialNetworkSharing == false) ? "socialpressed" : ""} disabled={(this.state.shocialNetworkSharing == false) ? true : false}>Post To Twitter</Button>
                              </TwitterShareButton>
                            </div>
                          </div>
                          {/* {my_profile?.review_statistics?.count && my_profile?.review_statistics?.count > 5 ?
                            <div className="mb-3">
                              <FormGroup check>
                                <Label
                                  className="text-tertiary font-weight-bold fs-14 ff-alt"
                                  check
                                >
                                  <Input
                                    type="checkbox"
                                    id="checkbox2"
                                    name="reviewAnonymous"
                                    checked={reviewAnonymous}
                                    onChange={(e) => {
                                      this.setState({
                                        reviewAnonymous: e.target.checked,
                                        shocialNetworkSharing: false,
                                      });
                                    }}
                                  />
                                  Make this review anonymous{" "}
                                  <span className="font-weight-normal">
                                    (we will not show your username).{" "}
                                    <img
                                      src={require("../../../assets/images/icons/ovel.png")}
                                      alt=""
                                    />
                                  </span>
                                </Label>
                              </FormGroup>
                            </div>
                            : null} */}
                          <div className="mb-3">
                            <FormGroup check>
                              <Input
                                type="checkbox"
                                id="checkbox2"
                                checked={isadhere}
                                name="isadhere"
                                onChange={this.handelChangeReview}
                              />
                              <Label
                                className="text-tertiary font-weight-bold fs-14 ff-alt"
                                check
                              >
                                {" "}
                                {"I adhere to the"}
                                <span className="text-dark" role="button"  onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({ showRevTips: !this.state?.showRevTips }, () => {
                                    if (this.state?.showRevTips) {
                                      document.getElementById('reviewTipsAndGuideLinesFollowup').scrollIntoView({ behavior: "smooth", block: "center" });
                                    }
                                  });
                                }} >
                                  {' Review Tips & Guidelines.'}
                                </span>
                              </Label>
                            </FormGroup>
                          </div>
                          <div className="text-right">
                            <Button
                              size="lg"
                              color="grey"
                              className="mw text-white"
                              onClick={() => this.clearReveiwRatingState()}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="lg"
                              color="tertiary"
                              className={
                                reviewAnonymous ? "mw hide-review-block" : "mw"
                              }
                              onClick={() => this.publishPost("draft")}
                              // disabled={this.isPublishDisable()}
                              disabled={enableErrorForReview}
                            >
                              Save Draft
                            </Button>
                            <Button
                              size="lg"
                              color="primary"
                              className="mw"
                              onClick={() => this.publishPost("publish")}
                              // disabled={this.isPublishDisable()}
                              disabled={enableErrorForReview}
                              title={this.isPublishDisable() && "Text Review field Required"}
                            >
                              Publish
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </RatingCollapse>
                  {/* Comment box */}
                  {
                    this.state?.editreviewID == iteam.id ? <div className="bg-white-shaded-md p-2 mt-2">


                    {/* Edit your review modal Two */}
                    {(this.state.editreviewID === iteam.id || this.state.editreviewID === iteam?.child_review?.id) ? (
                      <Modal
                        isOpen={this.state.writeReviewModalToggleTwo}
                        toggle={() => {
                          this.clearReveiwRatingState();
                          this.setState({
                            writeReviewModalToggleTwo: !this.state
                              .writeReviewModalToggleTwo,
                          });
                        }}
                      >
                        <ModalBody className="bg-transparent">
                          <div className="mb-3">
                            {/* write your own review, Part 2 */}
                            <div>
                              <div className="bg-white p-2 mb-3">
                                <h2 className="text-dark fs-36">
                                  {"You're editing your review for"}
                                  {` `}
                                  {` `}
                                  <span className="text-darker">
                                    {this.props.businessDetails?.name}
                                  </span>
                                </h2>
                              </div>

                              {/* Step 1 Collapse */}
                              <div className="bg-white mb-3">
                                <CollapseBasic
                                  title="Step 1. Star Ratings"
                                  bodyClass="text-dark"
                                  containerClass="type-tertiary type-review bg-transparent"
                                  isOpen={true}
                                  noPadding={true}
                                  noHr={true}
                                  size="sm"
                                  isReviewCollapse={true}
                                >
                                  <p className="text-tertiary fs-14">
                                    Roll over the stars and click to rate. <span className="text-dark ff-alt hover-underline" role="button"
                                      onClick={() => this.setState({ showRevTips: !this.state.showRevTips })}> Review Tips & Guidelines.</span>
                                  </p>

                                  {/* Review Tips & Guidelines */}
                                  {this.state.showRevTips ? <div className="mb-3" id="reviewTipsAndGuideLinesEdit">
                                    <div className="text-right">
                                      <Button color="tertiary" className="text-white" size="sm" onClick={() => this.setState({ showRevTips: false })}><FontAwesomeIcon icon="times" /> </Button>
                                    </div>
                                    <div className="bg-tertiary text-white p-2 fs-14" >
                                      <ul className="list-normal mb-0">
                                        <li className="ff-base mb-2">Do not post photos or videos of other copyrighted material that you don’t have permission to use. Do not post photos of people without their permission. If they are children, ask the parents before you post.</li>
                                        <li className="ff-base mb-2">Opinions are almost always protected as free speech except in the case of defamation (a false accusation of an offense or a malicious misrepresentation of someone's words or actions), true threats, solicitations to commit crimes, incitement to imminent lawless action, blackmail, fighting words and obscenity).</li>
                                        <li className="ff-base mb-2">Please omit excessive exaggeration and do not lie.</li>
                                        <li className="ff-base mb-2">Deliberately sabotaging the viability of a business is strongly discouraged.</li>
                                        <li className="ff-base mb-2">Be honest and truthful in your reviews.</li>
                                        <li className="ff-base mb-2">Please be accurate, avoid embellishment and broad generalizations, and to relate only to your first hand experiences.</li>
                                      </ul>
                                    </div>
                                  </div> : ""}

                                  {/* Star Input */}
                                  <div className="d-flex align-items-center">
                                    <Label className="text-dark font-weight-bold text-nowrap mr-3 fs-16">
                                      {"Overall Rating*"}
                                    </Label>
                                    <div>
                                      {/* <div className="stars-rating-input _lg">
                                          <span
                                            onClick={(newRating) =>
                                              this.ratingChanged(
                                                0,
                                                "avg_rating",
                                                true
                                              )
                                            }
                                            className="zero-rating-input"
                                            role="button"
                                          >
                                            <FontAwesomeIcon
                                              icon="ban"
                                              color={
                                                this.state.editReveiw?.ratings_sets?.avg_rating?.[1] == 0
                                                  ? "#15c3d4"
                                                  : "#d7d8e2"
                                              }
                                            />
                                          </span>
                                          <StarsRating
                                            count={5}
                                            value={+this.state.editReveiw?.ratings_sets?.avg_rating?.[1]}
                                            size={34}
                                            color1={"#d7d8e2"}
                                            color2={"#15c3d4"}
                                            onChange={(newRating) =>
                                              this.ratingChanged(
                                                newRating,
                                                "avg_rating",
                                                true
                                              )
                                            }
                                          />
                                        </div> */}
                                      <div className="stars-rating-input _lg">
                                        <span
                                          onMouseEnter={() => {
                                            if (!this.checkIfLoggedIn()) {
                                              this.forceToggleLoginModal();
                                              return false;
                                            }
                                            this.setState({ enableRatingText: 0, enableHoverBanIcon: true })
                                          }}
                                          onMouseLeave={() => { this.setState({ enableRatingText: null, enableHoverBanIcon: false }) }}
                                          className="zero-rating-input" role="button" onClick={() => this.ratingChanged(0, 'avg_rating_edit')}>
                                          <FontAwesomeIcon
                                            icon="ban"
                                            // onClick={() => this.ratingChanged(0, 'avg_rating_edit')}
                                            color={enableHoverBanIcon || !!!this.state.avg_rating_edit ? '#15c3d4' : '#d7d8e2'}
                                            title="0"
                                          />
                                        </span>
                                        <span title={this.state.enableRatingText}>
                                          <Rating
                                            fractions={2}
                                            emptySymbol={empty_star_rating}
                                            fullSymbol={full_star_rating}
                                            onHover={(rate) => {
                                              if (enableHoverBanIcon) {
                                                this.setState({ enableHoverBanIcon: false });
                                              }
                                              if (rate !== this.state.enableRatingText) {
                                                this.setState({ enableRatingText: rate })
                                              }
                                            }}
                                            initialRating={this.state.avg_rating_edit}
                                            onChange={(newRating) => this.ratingChanged(newRating, 'avg_rating_edit')}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mt-2">
                                    <div className="text-tertiary">
                                      <span className="font-weight-bold">
                                        Detailed Ratings
                                      </span>{" "}
                                      {"(optional)"}
                                    </div>

                                    <hr className="bg-light" />
                                    <Row className="fs-14 ff-alt">
                                      {businessRatingListEdit.map((iteam) => (
                                        <Col className="mb-2" md={6} lg={4}>
                                          <div>{iteam.label}</div>
                                          {/* <div>{iteam.key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')}</div> */}
                                          <div className="stars-rating-input">
                                            <span className="zero-rating-input" role="button">
                                              <FontAwesomeIcon
                                                icon="ban"
                                                color={this.checkColorTrue(iteam.key) ? '#15c3d4' : '#d7d8e2'}
                                                onMouseEnter={() => this.onHoverEnableDisable(iteam.key, true)}
                                                onMouseLeave={() => this.onHoverEnableDisable(iteam.key, false)}
                                                onClick={() => {
                                                  this.ratingChanged(0, iteam.label, iteam.key, "edit");
                                                }}
                                              />
                                            </span>

                                            <span title={this.state.titleRatingText}>
                                              <Rating
                                                fractions={2}
                                                emptySymbol={empty_star_rating_below}
                                                fullSymbol={full_star_rating_below}
                                                initialRating={businessRatingListEdit?.filter(itm => itm.key === iteam.key)?.[0]?.value}
                                                onChange={(newRating) => {
                                                  this.ratingChanged(newRating, iteam.label, iteam.key, "edit")
                                                }}
                                              />
                                            </span>
                                            <span
                                              onClick={() => {
                                                this[iteam.label] = false;
                                                this.ratingChanged(0, iteam.label, iteam.key, "edit")
                                              }}
                                              className="clear-rating"
                                              role="button"
                                              title="Clear">
                                              <span className="icon-cross"></span>
                                            </span>
                                          </div>
                                        </Col>
                                      ))}
                                    </Row>
                                  </div>
                                  <div className="p-3 bg-light">
                                    <CollapseBasic
                                      title="You can rate menu items too!"
                                      containerClass="bg-transparent type-tertiary"
                                      bodyClass="mt-3 text-tertiary"
                                      isOpen={false}
                                      size="sm"
                                    >
                                      <div className="mb-4">
                                        <Nav className="active-transparent border-0" tabs>
                                          {rateMenu && rateMenu.map((menuItem, index) => {
                                            return <NavItem>
                                              <NavLink className="text-truncate"
                                                href="#"
                                                active={this.state.activeMenuType === menuItem}
                                                onClick={() => {
                                                  this.setState({ activeMenuType: menuItem });
                                                  const ratingKey = iteam?.review_voting_key
                                                  this.fetchRateMenuItemContent({ businessId, menuItem, ratingKey })

                                                }}
                                                key={index}
                                              >
                                                <span className="font-weight-bold ff-base fs-16">
                                                  {menuItem}
                                                </span>
                                              </NavLink>
                                            </NavItem>
                                          })}
                                        </Nav>
                                      </div>
                                      <TabContent activeTab={this.state.activeMenuType}>
                                        <TabPane tabId={this.state.activeMenuType}>
                                          {rateMenuContent && rateMenuContent.map((menu, indexItem) => {
                                            return <CollapseBasic title={menu.menuItem} containerClass="bg-transparent type-tertiary" bodyClass="fs-14 mt-3 text-tertiary" size="sm" isOpen={false}>
                                              <Row>
                                                {/* Repeat this for items */}
                                                {menu?.menuContent?.map((menuDetail, index) => {
                                                  return <Col className="mb-3" lg={6}>
                                                    <div className="d-flex flex-nowrap mx-n2">
                                                      <div className="px-2">
                                                        <img className="menu_img" width="50" src={this.state.menuMediaListMedia?.[menuDetail?.menuItemContent?.[4]]?.[0]?.thumbnail || 'https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png'} alt="" />
                                                      </div>
                                                      <div className="px-2">
                                                        <div className="d-flex">
                                                          <div className="mr-2">
                                                            <span className="text-dark mr-2 font-weight-bold">{menuDetail.menuItemName}</span>
                                                            <div>
                                                              <div className="stars-rating-input _sm">
                                                                <span className="zero-rating-input" role="button">
                                                                  <FontAwesomeIcon icon="ban"
                                                                    onClick={() => this.ratingChangedMenuItem(0, menuDetail.menuItemContent[4], iteam?.review_voting_key)}
                                                                    color={+menuDetail.menuItemContent[1] === 0 ? '#15c3d4' : '#d7d8e2'}
                                                                  />
                                                                </span>
                                                                <StarsRating
                                                                  count={5}
                                                                  value={+menuDetail.menuItemContent[1]}
                                                                  size={18}
                                                                  color1={'#d7d8e2'}
                                                                  color2={'#15c3d4'}
                                                                  onChange={(newRating) => {
                                                                    this.ratingChangedMenuItem(newRating, menuDetail.menuItemContent[4], iteam?.review_voting_key)
                                                                  }}
                                                                />
                                                                {/* <span className="ml-2 d-inline-block times-2" role="button" title="Clear">
                                                                                                                    <span className="icon-cross"></span>
                                                                                                                </span> */}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="text-dark">
                                                          <p className="fs-14 mb-0">
                                                            {menuDetail.menuItemContent[2]}
                                                          </p>
                                                        </div>
                                                        <div className="d-flex flex-wrap">
                                                          {this.state.menuMediaListMedia?.[menuDetail?.menuItemContent?.[4]]?.map(img => {

                                                            return <div className="px-2 py-2 position-relative" >
                                                              <img className='menu_img' width="50" src={img?.thumbnail || 'https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png'} alt="" />
                                                              <FontAwesomeIcon onClick={() => this.handleDeleteMenuMedia(menuDetail?.menuItemContent[4], img?.id)} color="red" icon="trash-alt" style={{ position: "absolute", top: 0, right: "10px", cursor: "pointer" }} />
                                                            </div>
                                                          })}
                                                        </div>
                                                        <div className="mt-1">
                                                          <Button color="link" className="text-dark fs-14 font-weight-bold p-0"
                                                            onClick={() => {
                                                              this.handleCloseAndFetch();
                                                              this.setState({
                                                                uploadType: "menuItem",
                                                                menuTitle: menu.menuItem,
                                                                menuSubTitle: menuDetail.menuItemName,
                                                                menuObjectPk: menuDetail.menuItemContent[4]
                                                              })
                                                            }}>
                                                            <FontAwesomeIcon icon="camera" className="mr-2" />
                                                            Add Image
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                })}


                                              </Row>
                                            </CollapseBasic>
                                          })}

                                        </TabPane>
                                      </TabContent>
                                    </CollapseBasic>
                                  </div>
                                </CollapseBasic>
                              </div>

                              {/* Step 2 Collapse */}
                              <div className="bg-white mb-3">
                                <CollapseBasic
                                  title="Step 2. Review"
                                  bodyClass="text-dark"
                                  containerClass="type-tertiary type-review bg-transparent"
                                  isOpen={true}
                                  noPadding={true}
                                  noHr={true}
                                  size="sm"
                                  isReviewCollapse={true}
                                >
                                  <div className="text-tertiary fs-14 mb-3">
                                    {
                                      "Please provide as much information as you can in your review to be as meaningful as possible to others."
                                    }
                                  </div>
                                  <Nav tabs className="mb-3 active-transparent">
                                    <NavItem>
                                      <NavLink
                                        href="#"
                                        className="ff-base font-weight-normal"
                                        active={
                                          this.state.viewEditReviewStep ===
                                          "writeReview"
                                        }
                                        onClick={() => {
                                          this.setState({
                                            viewEditReviewStep: "writeReview",
                                          });
                                        }}
                                      >
                                        <span className="font-weight-bold">
                                          Write a Review
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        href="#"
                                        className="ff-base font-weight-normal"
                                        active={
                                          this.state.viewEditReviewStep ===
                                          "recordVideo"
                                        }
                                        onClick={() => {
                                          this.setState({
                                            viewEditReviewStep: "recordVideo",
                                          });
                                        }}
                                      >
                                        {"or record a"}
                                        <span className="font-weight-bold">
                                          &nbsp; {"Video Review"}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>

                                  <TabContent
                                    className="mb-3"
                                    activeTab={this.state.viewEditReviewStep}
                                  >
                                    <TabPane tabId="writeReview">
                                      <FormGroup className="main-post-formgroup">
                                        <div className="input-label-block">
                                          <Input
                                            className="primary"
                                            type="textarea"
                                            name="write_new_review"
                                            rows="4"
                                            id="write_new_review"
                                            value={write_new_review}
                                            onChange={this.handelChangeReview}
                                            placeholder="&nbsp;"
                                          />
                                        </div>
                                        <div className="characters-length-text">
                                          Characters:{" "}
                                          <span>{write_new_review.length}</span>
                                        </div>
                                      </FormGroup>
                                    </TabPane>
                                    <TabPane tabId="recordVideo">
                                      <div>
                                        {this.state.viewEditReviewStep ===
                                          "recordVideo" && <WebCamRecording 
                                          setClickedTabOperation={this.setClickedTabOperation}
                                          clickedOnWriteAReview={this.state.clickedOnWriteAReview}
                                          stepTwoUploadedVideo={this.state.stepTwoUploadedVideo}
                                          addUploadedVideo={this.addUploadedVideo}
                                          removeUploadedVideo={this.removeUploadedVideo} editReview/>}
                                          {viewEditReviewStep === "recordVideo" && (
                                        <FormGroup className="main-post-formgroup">
                                            <div className="input-label-block">
                                                <Input
                                                    className="primary"
                                                    type="textarea"
                                                    name="write_new_review_vedio"
                                                    rows="4"
                                                    value={writeAReviewTextVideo}
                                                    id="write_new_review_video"
                                                    onChange={(event) => { this.setState({ writeAReviewTextVideo: event.target.value }) }}
                                                    placeholder=""
                                                />
                                                {/* {writeAReviewTextVideo.length < 50 && writeAReviewTextVideo.length !== 0 ?
                                                    <span style={{ color: "red" }}>
                                                        {'This field contains a minimum of 50 characters.'}
                                                    </span>
                                                    : ""} */}
                                                 
                                           
                                            {errors?.writeAReviewTextVideo?.length > 0 && (
                                                <span style={{ color: "red" }}>
                                                    {errors.writeAReviewTextVideo}
                                                </span>
                                            )}
                                            </div>
                                            <div className="d-flex">


                                                <div className="px-2 col">
                                                    <div className="characters-length-text">
                                                        {'Characters:'} <span>{writeAReviewTextVideo.length ? writeAReviewTextVideo.length : '0'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>

                                    )}
                                      </div>
                                    </TabPane>
                                  </TabContent>

                                  <div className="fs-14">
                                    <div className="text-dark">
                                      <span className="font-weight-bold">
                                        {"Choose Review Categories"}
                                      </span>
                                      <span>(Optional)</span>
                                    </div>
                                    <hr className="bg-light my-2" />
                                    <p className="text-tertiary fs-14 mb-2">
                                      {
                                        "If your review above contains information about a  particular element or elements that you would like to highlight, please check the corresponding boxes below."
                                      }
                                    </p>
                                    <Row>
                                      {businessRatingListEdit &&
                                        businessRatingListEdit.map(
                                          (itesam, index) => (
                                            <Col xs={6} lg={4}>
                                              <FormGroup check>
                                                <Label
                                                  className="text-primary font-weight-bold"
                                                  check
                                                >
                                                  <Input
                                                    type="checkbox"
                                                    id="checkbox2"
                                                    name={itesam.label}
                                                    checked={itesam.checked}
                                                    onChange={(e) => {
                                                      this.handleEditChange(e.target.checked, itesam.id);
                                                    }}
                                                  />{" "}
                                                  {itesam.label}
                                                </Label>
                                              </FormGroup>
                                            </Col>
                                          )
                                        )}
                                    </Row>
                                  </div>
                                </CollapseBasic>
                              </div>

                              {/* Step 4 Collapse edit */}

                              <div className="bg-white mb-3">
                                <CollapseBasic
                                  title="Step 3. Upload Images and/or Video (optional)"
                                  containerClass="type-tertiary type-review bg-transparent"
                                  isOpen={true}
                                  noPadding={true}
                                  noHr={true}
                                  size="sm"
                                  isReviewCollapse={true}
                                >
                                  <p className="text-tertiary fs-14 mb-3">
                                    {
                                      "Upload images or video to accompany your review. Additional images/videos can be added later."
                                    }
                                  </p>
                                  {/* Uploaded media shown here */}
                                  {
                                    (mediaSetReview?.length || embedBody?.length)
                                      ?
                                      <div className="border mb-3">
                                        <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                          {mediaSetReview.map((iteam1) => (
                                            <Col className="mr-4">
                                              <div className="d-flex pr-3 m-2">
                                                <div>
                                                  <div className="gallery-media d-flex justify-content-center">
                                                    {this.renderPhotosAndVideos(
                                                      iteam1,
                                                      true
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="mx-n3 mt-2">
                                                  <Button color="delete-media" size="sm" title="Remove Media"
                                                    onClick={(e) => {
                                                      this.removeFromMedia(
                                                        e,
                                                        iteam1
                                                      );
                                                    }}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                </div>
                                              </div>
                                            </Col>
                                          ))}
                                          {embedBody && Array.isArray(embedBody) && embedBody.length ?
                                            embedBody.map((item, uploaded_index) => (
                                              <Col key={uploaded_index} className="mr-4">
                                                <div className="d-flex pr-3 m-2">
                                                  <div>
                                                    {/* {item.type === "embedded_link" &&  */}
                                                    <div className="gallery-media d-flex justify-content-center">
                                                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                          className="img-fluid img-thumbnail h-100"
                                                          src={item.thumbnail || require("../../../assets/images/embedded_link_img.png")}
                                                          id={`embedded_link-${uploaded_index}`}
                                                          alt={item.url}
                                                        />
                                                        {/* <UncontrolledTooltip
                                                            autohide="false"
                                                            placement="top"
                                                            target={`embedded_link-${uploaded_index}`}
                                                          >
                                                            {item.url}
                                                          </UncontrolledTooltip> */}
                                                      </a>
                                                    </div>
                                                    {/* } */}
                                                  </div>
                                                  <div className="mx-n3 mt-2">
                                                    <Button onClick={() => { this.removeEmbeddedEditReview(uploaded_index, item) }} color="delete-media" size="sm" title="Remove Media">
                                                      <FontAwesomeIcon icon="trash-alt" />
                                                    </Button>
                                                  </div>
                                                </div>
                                              </Col>
                                            )) : null}
                                        </Row>
                                      </div>
                                      :
                                      ''
                                  }
                                  <Button
                                    color="tertiary"
                                    className="font-weight-bold bg-tertiary border-tertiary"
                                    onClick={this.handleCloseAndFetchReview}
                                  >
                                    {"Upload Media"}
                                  </Button>
                                </CollapseBasic>
                              </div>
                              {/* Final Step */}
                              <div className="bg-white p-3">
                                <div className="mb-3">
                                  <FormGroup check>
                                    <Input
                                      type="checkbox"
                                      id="checkbox2"
                                      checked={
                                        iteam.created_by &&
                                          iteam.created_by
                                            .reviews_guidelines_adhere_flag
                                          ? iteam.created_by
                                            .reviews_guidelines_adhere_flag
                                          : isadhere
                                      }
                                      name="isadhere"
                                      onChange={this.handelChangeReview}
                                    />
                                    <Label
                                      className="text-tertiary font-weight-bold fs-14 ff-alt"
                                      check
                                    >
                                      {" "}
                                      I adhere to the{" "}
                                      <span className="text-dark" onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({ showRevTips: !this.state?.showRevTips }, () => {
                                          if (this.state?.showRevTips) {
                                            document.getElementById('reviewTipsAndGuideLinesEdit').scrollIntoView({ behavior: "smooth", block: "center" });
                                          }
                                        });
                                      }} role="button" >
                                        {'Review Tips & Guidelines.'}
                                      </span>
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div className="text-right">
                                  <Button
                                    size="lg"
                                    color="grey"
                                    className="mw text-white"
                                    onClick={() =>
                                      this.clearReveiwRatingState()
                                    }
                                  >
                                    {"Cancel"}
                                  </Button>
                                  {this.state?.editReveiw?.is_draft && (
                                    <Button
                                      size="lg"
                                      color="tertiary"
                                      className={
                                        reviewAnonymous
                                          ? "mw hide-review-block"
                                          : "mw"
                                      }
                                      onClick={() => this.publishPost("draft")}
                                      disabled={!isadhere}
                                    >
                                      {'Update Draft'}
                                    </Button>
                                  )}
                                  <Button
                                    size="lg"
                                    color="primary"
                                    className="mw"
                                    onClick={() => this.publishPost("publish")}
                                    disabled={!isadhere}
                                  >
                                    {'Publish Review'}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal>
                    ) : null}
                  </div> : ""
                  }
                  
                </div>
              );
            })
          ) : <div className="bg-white p-3">
            {this.props.item_details_loading ? <h2 className="text-secondary-dark">Loading Reviews...</h2> : <h2 className="text-secondary-dark">No reviews to display</h2>}
          </div>}</> : <div className="bg-white p-3">
           <h2 className="text-secondary-dark">Coming Soon...</h2></div>}
          {/* All Comments */}
          <div></div>

          <div hidden>
            <div className="d-flex mx-n2 align-items-center py-3">
              <div className="px-2 col">
                <hr className="bg-tertiary my-1" />
              </div>
              <div className="px-2 col-auto">
                <div className="text-black px-3">
                  Reviews as{" "}
                  <span className="font-weight-bold">La Comparsa</span>
                </div>
              </div>
              <div className="px-2 col">
                <hr className="bg-tertiary my-1" />
              </div>
            </div>
          </div>

          <Modal
            isOpen={this.state.uploadMediaModal}
            toggle={() =>
              this.setState(
                { uploadMediaModal: !this.state.uploadMediaModal },
                () => {
                  this.clearMediaDispatch();
                }
              )
            }
          >
            <ModalHeader
              toggle={() => {
                this.handleCloseAndFetch();
              }}
            >
              UPLOAD MEDIA
            </ModalHeader>
            <ModalBody className="p-3">
              <Row>
                <Col xs={"auto"}>
                  <div className="bg-light h-100 p-3">
                    <Nav
                      tabs
                      className="flex-column active-transparent border-0 m-0"
                    >
                      <NavItem className="text-left">
                        <NavLink
                          className="pl-0 pr-4 border-0"
                          href="#"
                          active={this.state.selectedUpMediaType === "upload"}
                          onClick={() => {
                            this.setState({ selectedUpMediaType: "upload" });
                          }}
                        >
                          Upload
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <hr className="my-3 bg-tertiary" />
                      </NavItem>
                      <NavItem className="text-left border-0">
                        <NavLink
                          className="pl-0 pr-4 border-0"
                          href="#"
                          active={this.state.selectedUpMediaType === "gallery"}
                          onClick={() => {
                            this.setState(
                              { selectedUpMediaType: "gallery" },
                              () => {
                                this.props.media_upload_scroll({
                                  type: "image",
                                  album: "",
                                  page: 1,
                                  force_update: true,
                                });
                              }
                            );
                          }}
                        >
                          Gallery
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <hr className="my-3 bg-tertiary" />
                      </NavItem>
                      <NavItem className="text-left border-0">
                        <NavLink
                          className="pl-0 pr-4 border-0"
                          href="#"
                          active={this.state.selectedUpMediaType === "embed"}
                          onClick={() => {
                            this.setState({ selectedUpMediaType: "embed" });
                          }}
                        >
                          Embed
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
                <Col>
                  <TabContent activeTab={this.state.selectedUpMediaType}>
                    <TabPane tabId="upload">
                      {selectedFilesPreview &&
                        selectedFilesPreview.length == 0 ? (
                        <div className="mb-3 type-file-block bg-light">
                          <Input
                            type="file"
                            name="upload_media_file"
                            id="uploadFilesFromSystem"
                            accept="image/*, video/*"
                            onChange={this.handleOnFileUploadChange}
                            multiple
                          />
                          <Label for="uploadFilesFromSystem">
                            <div className="type-bg"></div>
                          </Label>
                        </div>
                      ) : null}
                      <div
                        className="text-right"
                        style={{
                          maxWidth: "120px",
                          margin: "0 1rem 1rem auto",
                        }}
                      >
                        <div className="text-center mb-1 small">
                          {parseInt(calculatedPercentage) === 100 ? (
                            <div className="text-success">
                              <FontAwesomeIcon
                                icon="check-circle"
                                className="mr-1"
                              />{" "}
                              {"Uploaded"}
                            </div>
                          ) : (
                            selectedFilesPreview.length > 0 && (
                              <div>
                                {` Uploading `}
                                <span className="text-success font-weight-bold ff-base">
                                  {calculatedPercentage
                                    ? `${parseInt(calculatedPercentage)} %`
                                    : ""}
                                </span>
                              </div>
                            )
                          )}
                        </div>
                        {selectedFilesPreview.length > 0 && (
                          <Progress
                            value={parseInt(calculatedPercentage)}
                            style={{ height: "8px" }}
                          />
                        )}
                      </div>

                      <Row>
                        {selectedFilesPreview && selectedFilesPreview.length > 0
                          ? selectedFilesPreview.map((item, index) => (
                            <Col className="mb-3" xs="auto" key={index}>
                              <div className="text-center bg-light p-2">
                                <div className="d-inline-flex pr-3">
                                  <div>
                                    <div
                                      className="selectable-media"
                                      style={{ cursor: "default" }}
                                    >
                                      <div className="gallery-media">
                                        <img
                                          src={item}
                                          alt={`selected-${index}`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-2">
                                    <Button
                                      color="delete-media _alt"
                                      size="sm"
                                      title="Remove Media"
                                      onClick={(e) =>
                                        this.removeSelectedImage(e, index)
                                      }
                                    >
                                      <FontAwesomeIcon icon="trash-alt" />{" "}
                                    </Button>
                                  </div>
                                </div>
                                {latestUploadedData &&
                                  latestUploadedData[index] &&
                                  (latestUploadedData[index]["isUpload"] !==
                                    undefined ||
                                    latestUploadedData[index]["isUpload"] !==
                                    null) ? (
                                  latestUploadedData[index]["isUpload"] ? (
                                    <div className="mt-2">
                                      <InputGroup
                                        style={{ maxWidth: "160px" }}
                                      >
                                        <Input
                                          bsSize="sm"
                                          type="text"
                                          name="caption"
                                          className="caption-input"
                                          placeholder="Caption"
                                          onBlur={(e) =>
                                            this.handleCaptionByIndex(
                                              e,
                                              index
                                            )
                                          }
                                        />
                                      </InputGroup>
                                      <Input
                                        onChange={(e) =>
                                          this.handleChangeCategory(e, index)
                                        }
                                        bsSize="sm"
                                        type="select"
                                        name="album_type_category"
                                        id="selectCategoryTypeData"
                                        style={{ maxWidth: "160px" }}
                                      >
                                        <option value="">
                                          Choose Category
                                        </option>
                                        {cattaxListing &&
                                          Object.keys(cattaxListing).length > 0
                                          ? Object.values(cattaxListing).map(
                                            (data, i) => (
                                              <>
                                                <option
                                                  value={data.id}
                                                >
                                                  {data.category}
                                                </option>
                                              </>
                                            )
                                          )
                                          : null}
                                      </Input>
                                      {uploadedId[index] ==
                                        latestUploadedData[index].id &&
                                        (Object.keys(fetchTaxonomyListingImages)
                                          .length > 0 ||
                                          Object.keys(
                                            fetchTaxonomyListingVideos
                                          ).length > 0) ? (
                                        <Input
                                          onChange={(e) =>
                                            this.handleChangeTaxonomyData(
                                              e,
                                              index
                                            )
                                          }
                                          bsSize="sm"
                                          type="select"
                                          name="album_subcategory_select"
                                          id="selectAlbumTypeData"
                                          style={{ maxWidth: "160px" }}
                                        >
                                          <option value="">
                                            Choose Subcategory
                                          </option>
                                          {latestUploadedData[index][
                                            "media_type"
                                          ] === "image" &&
                                            fetchTaxonomyListingImages &&
                                            Object.keys(
                                              fetchTaxonomyListingImages
                                            ).length > 0 &&
                                            fetchTaxonomyListingImages[index]
                                            ? fetchTaxonomyListingImages[
                                              index
                                            ].map((item) => (
                                              <option value={item.id}>
                                                {item.label}
                                              </option>
                                            ))
                                            : null}
                                          {latestUploadedData[index][
                                            "media_type"
                                          ] === "video" &&
                                            fetchTaxonomyListingVideos &&
                                            Object.keys(
                                              fetchTaxonomyListingVideos
                                            ).length > 0 &&
                                            fetchTaxonomyListingVideos.length >
                                            0 &&
                                            fetchTaxonomyListingVideos[index]
                                            ? fetchTaxonomyListingVideos[
                                              index
                                            ].map((item) => (
                                              <option value={item.id}>
                                                {item.label}
                                              </option>
                                            ))
                                            : null}
                                        </Input>
                                      ) : null}
                                    </div>
                                  ) : (
                                    <div className="mt-2 text-center">
                                      <small className="text-danger">
                                        Error while uploading
                                      </small>
                                    </div>
                                  )
                                ) : (
                                  <div className="mt-2 text-center">
                                    <small className="text-info">
                                      Uploading...
                                    </small>
                                  </div>
                                )}
                              </div>
                            </Col>
                          ))
                          : null}
                      </Row>
                      <div className="d-flex mx-n2 justify-content-center">
                        <div className="px-2">
                          <Button
                            color="primary"
                            size="sm"
                            className="mw"
                            onClick={() => {
                              this.handleCloseAndFetch();
                            }}
                          >
                            Ok
                          </Button>
                        </div>
                        <div className="px-2">
                          <Button
                            color="primary"
                            size="sm"
                            className="mw"
                            onClick={() => {
                              this.handleCloseAndFetch();
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                        
                      </div>
                    </TabPane>
                    <TabPane tabId="gallery">
                      <div className="mb-2">
                        <Nav tabs className="d-inline-flex mb-0">
                          <NavItem>
                            <NavLink
                              href="#"
                              active={this.state.showGalleryType === "images"}
                              onClick={() => {
                                this.setState(
                                  {
                                    showGalleryType: "images",
                                    fetchGalleryType: "image",
                                    fetchGalleryAlbum: "",
                                    fetchGalleryScroll: {},
                                  },
                                  () => {
                                    this.props.media_upload_scroll({
                                      type: "image",
                                      album: "",
                                      page: 1,
                                      force_update: true,
                                    });
                                  }
                                );
                              }}
                            >
                              {"Image Gallery"}
                            </NavLink>
                          </NavItem>
                          {/*showVideoMedia ? 'True' : 'False'*/}
                          {showVideoMedia == true && (
                            <NavItem>
                              <NavLink
                                href="#"
                                active={this.state.showGalleryType === "videos"}
                                onClick={() => {
                                  this.setState(
                                    {
                                      showGalleryType: "videos",
                                      fetchGalleryType: "video",
                                      fetchGalleryAlbum: "",
                                      fetchGalleryScroll: {},
                                    },
                                    () => {
                                      this.props.media_upload_scroll({
                                        type: "video",
                                        album: "",
                                        page: 1,
                                        force_update: true,
                                      });
                                    }
                                  );
                                }}
                              >
                                {"Video Gallery"}
                              </NavLink>
                            </NavItem>
                          )}
                        </Nav>
                      </div>
                      <TabContent activeTab={this.state.showGalleryType}>
                        <TabPane tabId="images">
                          <div>
                            <FormGroup className="form-row justify-content-end">
                              <Col xs="auto">
                                <Label className="small" for="selectAlbumType">
                                  Album Type:
                                </Label>
                              </Col>
                              <Col xs="auto">
                                <Input
                                  onChange={this.handleChangeTaxonomy}
                                  bsSize="sm"
                                  type="select"
                                  name="album_type_select"
                                  id="selectAlbumType"
                                  defaultValue="all"
                                >
                                  <option value="all">All</option>
                                  {fetchTaxonomy &&
                                    Array.isArray(fetchTaxonomy) &&
                                    fetchTaxonomy.length > 0
                                    ? fetchTaxonomy.map((item, index) => (
                                      <option value={item.category} key={index}>
                                        {item.label}
                                      </option>
                                    ))
                                    : null}
                                </Input>
                              </Col>
                            </FormGroup>
                          </div>
                          <div
                            ref={this.refGalleryImageScroll}
                            onScroll={this.handleScrollGalleryImage}
                            className="px-3 my-3"
                            style={{ maxHeight: "350px", overflow: "auto" }}
                          >
                            <Row>{this.fetchScrollPhotoMedia()}</Row>
                          </div>
                          <div className="d-flex mx-n2 justify-content-center">
                          <div className="px-2">
                              <Button
                                color="primary"
                                size="sm"
                                className="mw"
                                onClick={() =>
                                  this.setState({
                                    uploadMediaModal: !this.state
                                      .uploadMediaModal,
                                  })
                                }
                              >
                                {"Ok"}
                              </Button>
                            </div>
                            <div className="px-2">
                              <Button
                                color="primary"
                                size="sm"
                                className="mw"
                                onClick={() =>
                                  this.setState({
                                    uploadMediaModal: !this.state
                                      .uploadMediaModal,
                                  })
                                }
                              >
                                {"Cancel"}
                              </Button>
                            </div>
                            
                          </div>
                        </TabPane>
                        <TabPane tabId="videos">
                          <div
                            ref={this.refGalleryVideoScroll}
                            onScroll={this.handleScrollGalleryVideo}
                            className="px-3 my-3"
                            style={{ maxHeight: "350px", overflow: "auto" }}
                          >
                            <Row>{this.fetchScrollPhotoMedia(true)}</Row>
                          </div>
                          <div className="d-flex mx-n2 justify-content-center">
                          <div className="px-2">
                              <Button
                                color="primary"
                                size="sm"
                                className="mw"
                                onClick={() =>
                                  this.setState({
                                    uploadMediaModal: !this.state
                                      .uploadMediaModal,
                                  })
                                }
                              >
                                {"Ok"}
                              </Button>
                            </div>
                            <div className="px-2">
                              <Button
                                color="primary"
                                size="sm"
                                className="mw"
                                onClick={() =>
                                  this.setState({
                                    uploadMediaModal: !this.state
                                      .uploadMediaModal,
                                  })
                                }
                              >
                                Cancel
                              </Button>
                            </div>
                            
                          </div>
                        </TabPane>
                      </TabContent>
                    </TabPane>
                    <TabPane tabId="embed">
                      <div className="text-muted mb-2">
                        Submit the link for the image or video you want to embed{" "}
                      </div>
                      <div className="mb-2">
                        {/* Repeat this while adding */}
                        <div className="d-flex mx-n2 mb-2" key={0}>
                          <div className="flex-fill px-2">
                            <Input
                              type="url"
                              bsSize="sm"
                              className="bg-white"
                              value={embeddedLink}
                              onChange={(e) => {
                                this.setState({ embeddedLink: e.target.value });
                              }}
                              placeholder="Embeded link"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mx-n2">
                        <div className="px-2">
                          <Button
                            color="primary"
                            size="sm"
                            className="mw"
                            onClick={() => {
                              this.setState({
                                embeddedLink: "",
                                uploadMediaModal: false,
                              });
                            }}
                          >
                            {"Cancel"}
                          </Button>
                        </div>
                        <div className="px-2 ml-auto">
                          <Button
                            color="primary"
                            size="sm"
                            className="mw"
                            onClick={(e) => {
                              this.AddNewEmbedLink(e);
                            }}
                          >
                            {"Insert"}
                          </Button>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* Delete Confirmation Modal */}
          <Modal
            size="sm"
            isOpen={this.state.confirmDeleteModal}
            toggle={this.confirmDeleteModalToggle}
            className="text-center"
            modalClassName="backdrop-opaque top-shifted transition-none"
          >
            <div className="bg-white modal-header">
              <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
              <button
                className="btn btn-sm"
                aria-label="Close"
                onClick={this.confirmDeleteModalToggle}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <ModalBody className="text-dark">
              Are you sure you want to delete?
            </ModalBody>
            <ModalFooter className="bg-white">
              <div className="text-center w-100">
                <Button
                  color="primary"
                  size="sm"
                  className="btn-confirm"
                  onClick={this.confirmDeleteModalToggle}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  className="btn-confirm"
                  onClick={this.handleOnDeleteConfirmation}
                >
                  Yes
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div>


        {/* Limited Disputes Modal */}
        <Modal
          centered
          isOpen={this.state.limitedDisputesModalToggle}
          toggle={() =>
            this.setState({
              limitedDisputesModalToggle: !this.state
                .limitedDisputesModalToggle,
            })
          }
        >
          <ModalBody className="p-4">
            <Row className="mb-3">
              <Col xs={3}>
                <img
                  className="img-fluid"
                  // src={require("../../../assets/images/icons/paywall.png")}
                  alt="Bell"
                />
              </Col>
              <Col xs={9}>
                <h4 className="text-primary mb-2 ff-base fs-16">
                  You have disputed 3 reviews for free.
                </h4>
                <p className="text-primary-dark ff-base fs-14">
                  Upgrade to <strong>Premium Business</strong> account to get
                  unlimited ability to dispute and other more benefits.
                </p>
              </Col>
            </Row>
            <Button color="primary" size="lg" block>
              {" "}
              Upgrade to PREMIUM and get more benefits
            </Button>
            <div className="text-center mt-2">
              <Button
                color="link"
                className="text-dark"
                onClick={() =>
                  this.setState({ limitedDisputesModalToggle: false })
                }
              >
                Stay with FREE account
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={this.state.patrolReviewModalToggleOne}
          toggle={() =>
            this.setState({
              patrolReviewModalToggleOne: !this.state
                .patrolReviewModalToggleOne,
            })
          }
        >
          <form className="d-flex flex-column h-100">
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        patrolReviewModalToggleOne: !this.state
                          .patrolReviewModalToggleOne,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
                <div className="bg-dark text-white fs-14 px-3">
                  <div className="mb-2 pt-2">
                    <div className="d-flex mx-n2">
                      <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Patroling{" "}
                        </span>
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            className="text-white bg-transparent shadow-none font-weight-bold"
                            color="transparent"
                            size="sm"
                            caret
                          >
                            <span className="pr-2">Reviews</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="fs-14">
                              Reviews
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="px-2 col-auto">
                        near{" "}
                        <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled d-flex flex-wrap mx-n2">
                    <li className="px-2">
                      <div className="d-flex">
                        <FormGroup>
                          <Input
                            type="select"
                            className="transparent"
                            size="sm"
                          >
                            <option>Businesses</option>
                          </Input>
                        </FormGroup>
                        <div className="ml-2">
                          <span className="text-danger">*</span>
                        </div>
                      </div>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>IT Services</option>
                          <option>Apartment Rentals</option>
                          <option>Automotive</option>
                          <option>Education</option>
                          <option>Beauty and Spas</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Asian</option>
                          <option>European</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Indian</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Level 6</option>
                        </Input>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ModalBody className="fs-14 text-dark">
              <Row>
                <Col lg={8}>
                  <div className="text-primary fs-16 font-weight-bold mb-2">
                    Step 1. Brief
                  </div>
                  <div>
                    <strong>The Brief.</strong>
                  </div>
                  <p>
                    The Original Listing, currently marked as{" "}
                    <span className="font-weight-bold">Non Recommended</span>,
                    was{" "}
                    <span className="font-weight-bold">
                      Nominated for deletion
                    </span>{" "}
                    due to{" "}
                    <span className="font-weight-bold">Facts are Wrong</span> 3
                    months ago, by{" "}
                    <span className="font-weight-bold">Business Owner</span>{" "}
                    (a.k.a the business owner). It was disputed 1 week ago by
                    User2 (a.k.a the reviewer).
                  </p>
                  <div className="bg-light p-3">
                    <div className="text-black mb-2">
                      New Review on ListingName
                    </div>
                    <div className="font-weight-bold">
                      <div className="mb-1">
                        John Username's Review{" "}
                        <img
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="text-tertiary font-weight-bold">
                      <div className="mb-1">
                        Attention{" "}
                        <img
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png"
                          alt=""
                        />
                      </div>
                      <div className="mb-1">
                        Ambient{" "}
                        <img
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="my-4">
                      <FontAwesomeIcon icon="quote-left" />
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Placeat eius adipisci mollitia eos incidunt. Consequatur
                      deleniti neque, eos suscipit saepe quos velit?
                      Necessitatibus dolorum nulla reprehenderit totam, dolore
                      doloribus ut.
                      <FontAwesomeIcon icon="quote-right" />
                    </div>

                    <div className="text-center mt-3">
                      <img
                        className="img-fluid"
                        src="https://source.unsplash.com/random/600x300"
                        alt=""
                      />
                    </div>

                    <div className="mt-3">
                      <Carousel
                        responsive={responsive.viewGalleryImages}
                        infinite={true}
                        autoPlay={false}
                        swipeable={true}
                        showDots={false}
                        arrows={true}
                        containerClass="mx-n4"
                      >
                        <div className="item px-4">
                          <div className="img-holder text-center">
                            <img
                              className="img-thumbnail fixed-height-img full-width"
                              src="https://source.unsplash.com/random/100x100"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="item px-4">
                          <div className="img-holder text-center">
                            <img
                              className="img-thumbnail fixed-height-img full-width"
                              src="https://source.unsplash.com/random/100x100"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="item px-4">
                          <div className="img-holder text-center">
                            <img
                              className="img-thumbnail fixed-height-img full-width"
                              src="https://source.unsplash.com/random/100x100"
                              alt=""
                            />
                          </div>
                        </div>
                      </Carousel>
                    </div>
                    <hr />
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <img
                          className="img-circle _50x50"
                          src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                          alt=""
                        />
                      </div>
                      <div className="px-2">
                        <div className="mb-2">
                          <span>
                            Submitted by{" "}
                            <a href="#" className="text-reset">
                              <strong>Techdarma</strong>
                            </a>
                          </span>
                          <div>
                            <span className="fs-12 text-muted">
                              one hour ago via iOS7
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mb-4">
                    <div className="text-primary fs-16 font-weight-bold mb-2">
                      Step 2. Check if the review doesn't adheres to our tips
                    </div>
                    <div>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Do not post photos or videos of other copyrighted
                            material that you don't have permission to use. Do
                            not post photos of people without their permission.
                            If they are children, ask the parents before you
                            post.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Opinions are almost always protected as free speech
                            except in the case of defamation (a false accusation
                            of an offense or a malicious misrepresentation of
                            someone's words or actions). true threats,
                            solicitations to commit crimes, incitement to
                            imminent lawless action, blackmail, fighting words
                            and obscenity.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Please ommit excessive exaggeration and do not lie.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Deliberately sabotaging the viability of a business
                            is strongly discouraged.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input className="checked-bold" type="checkbox" />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Please be accurate, avoid embellishment and broad
                            generalizations, and to relate only to your first
                            hand experiences.
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          className="primary"
                          type="textarea"
                          bsSize="sm"
                          placeholder="(optional) Write a message to the user re: the tips"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="mb-">
                    <div className="text-primary fs-16 font-weight-bold mb-2">
                      Step 3. Check for Flags
                    </div>
                    <div>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            General Issue
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Offensive
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Profanity
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">Spam</span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Hate/Racist Based
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Illegal Drug Focused
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Notability
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Universally Illegal
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Vanity Page
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Incomplete/Not Useful
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup className="mb-2" check>
                        <Label check>
                          <Input
                            className="checked-bold"
                            type="radio"
                            name="flagIssueType"
                          />{" "}
                          <span className="text-dark fs-14 ff-alt">
                            Vandalism
                          </span>
                        </Label>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          className="primary"
                          type="textarea"
                          bsSize="sm"
                          placeholder="Please elaborate..."
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="bg-white">
              <Button color="primary">Submit</Button>
              <Button color="link" className="text-tertiary font-weight-bold">
                Skip
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        {/* View Media Modal */}
        

        {/* SEE HOW REVIEW DISPUTES WORK */}
        <Modal
          isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
          toggle={() =>
            this.setState({
              watchHowDisputeReviewWorksModalToggle: !this.state
                .watchHowDisputeReviewWorksModalToggle,
            })
          }
        >
          <ModalHeader
            className="text-uppercase px-0"
            toggle={() =>
              this.setState({
                watchHowDisputeReviewWorksModalToggle: !this.state
                  .watchHowDisputeReviewWorksModalToggle,
              })
            }
          >
            SEE HOW REVIEW DISPUTES WORK
          </ModalHeader>
          <ModalBody className="p-0 bg-transparent">
            <div className="text-center">
              <video controls autoPlay width="100%" seekable>
                <source
                  src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </ModalBody>
        </Modal>

        {/* Confirmation Modal */}
        <Modal size="sm"
          isOpen={confirmationToggle}
          className="text-center"
          modalClassName="backdrop-opaque top-shifted transition-none">
          <div className="bg-white modal-header">
            <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
            <button
              className="btn btn-sm"
              aria-label="Close"

            >
              <span aria-hidden="true" onClick={() => this.setState({ confirmationToggle: false })} >×</span>
            </button>
          </div>
          <ModalBody className="text-dark">
            {confirmationReason === 'delete_dispute'
              ?
              'Do you want to remove your Dispute?'
              :
              confirmationReason === 'delete_dispute_reply' ?
                'Do you want to remove your Reply?'
                :
                ''
            }
          </ModalBody>
          <ModalFooter className="bg-white">
            <div className="text-center w-100">
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={() => this.setState({ confirmationToggle: false })}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={() => this.handleDelete()}

              >
                Yes
              </Button>
            </div>
          </ModalFooter>
        </Modal>

      </>
    );
  }
}
const mapState = (state) => ({
  business_reviewDetail: state.business.business_reviewDetail,
  fetch_photos: state.myProfile.fetch_photos,
  fetch_videos: state.myProfile.fetch_videos,
  fetch_taxonomy: state.myProfile.fetch_taxonomy,
  upload_scroll: state.myProfile.upload_scroll,
  upload_media_data: state.myProfile.upload_media_data,
  fetch_deleted_data: state.myProfile.fetch_deleted_data,
  business_rating: state.business.business_rating,
  qa_dropdown: state.business.qa_dropdown,
  fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
  new_sub_categories: state.business.new_sub_categories,
  fetch_data_categories: state.business.fetch_data_categories,
  filter_data_category: state.business.filter_data_category,
  my_profile: state.user.my_profile,
  get_reveiewData: state.user.get_reveiewData,
  get_dispute_modal_status: state.dispute.get_dispute_modal_status,
  item_details_loading: state.business.isLoading,
  cat_taxonomy_list: state.item_detail.cat_taxonomy_list,
});

const mapProps = (dispatch) => ({
  get_business_review_detail: (id, filter, searchValue) =>
    dispatch(get_business_review_detail(id, filter, searchValue)),
  post_update_comment: (type, data) =>
    dispatch(post_update_comment(type, data)),
  delete_review_comment: (id) => dispatch(delete_review_comment(id)),
  get_reaction_comment: (id) => dispatch(get_reaction_comment(id)),
  send_email: (sharer_name, email, shareByEmailId) =>
    dispatch(send_email(sharer_name, email, shareByEmailId)),
  share_by_email: (url, data) => dispatch(share_by_email(url, data)),
  add_to_list: (url, data, message, nextUrl) =>
    dispatch(add_to_list(url, data, message, nextUrl)),
  remove_from_list: (url, message, nextUrl) =>
    dispatch(remove_from_list(url, message, nextUrl)),
  post_review_group: (id, data) => dispatch(post_review_group(id, data)),
  // save_ratings_vote: ({ name, vote, businessId }) => dispatch(save_ratings_vote({ name, vote, businessId })),
  save_ratings_vote: (data) => dispatch(save_ratings_vote(data)),
  fetch_media_data: (type, album, page, user_profile) =>
    dispatch(fetch_media_data(type, album, page, user_profile)),
  fetch_taxonomy_category: () => dispatch(fetch_taxonomy_category()),
  media_upload_scroll: ({ type, album, page, force_update }) =>
    dispatch(media_upload_scroll({ type, album, page, force_update })),
  fetch_delete_media: ({ type, page }) =>
    dispatch(fetch_delete_media({ type, page })),
  upload_media_files: (type, data, index) =>
    dispatch(upload_media_files(type, data, index)),
  upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
  clear_media_dispatch: () => dispatch(clear_media_dispatch()),
  delete_mutiple_media: ({ type, data }) =>
    dispatch(delete_mutiple_media({ type, data })),
  single_delete_from_upload: (id) => dispatch(single_delete_from_upload(id)),
  embedNewLink: ({ data }) => dispatch(embedNewLink({ data })),
  publish_post: (data) => dispatch(publish_post(data)),
  fetch_detailedstarrating: (data) => dispatch(fetch_detailedstarrating(data)),
  get_business_QA_dropdown: (id) => dispatch(get_business_QA_dropdown(id)),
  edit_review: (id, data) => dispatch(edit_review(id, data)),
  delete_post_comment: (reviewId, id, isTrue) =>
    dispatch(delete_post_comment(reviewId, id, isTrue)),
  fetch_taxonomy_list: (category, type) =>
    dispatch(fetch_taxonomy_list(category, type)),
  upload_media_filesData: (url, type, data, index) =>
    dispatch(upload_media_filesData(url, type, data, index)),
  fetch_sub_categories: ({ sub_cat, level }) =>
    dispatch(fetch_sub_categories({ sub_cat, level })),
  fetch_categories_data: (cat, sub_cat, type, index) =>
    dispatch(fetch_categories_data(cat, sub_cat, type, index)),
  filter_categories_data: ({ category, sub_cat, level }) =>
    dispatch(filter_categories_data({ category, sub_cat, level })),
  clear_category: () => dispatch(clear_category()),
  clear_subcategory: () => dispatch(clear_subcategory()),
  get_reviewgroup: (assetType, postId, type) =>
    dispatch(get_reviewgroup(assetType, postId, type)),
  updateFeedReactions: (params) =>
    dispatch(updateFeedReactions(params)),
  get_category_taxonomy_listing: (categoryType, types, mainTaxonomy) => dispatch(get_category_taxonomy_listing(categoryType, types, mainTaxonomy)),
  update_caption_and_categories: ({ id, data }) => dispatch(update_caption_and_categories({ id, data })),
});

export default connect(mapState, mapProps)(ClientReview);
