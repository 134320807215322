import { callApi } from "../utils/apiCaller";
import querystring from "query-string";

export const GET_POSTS = "GET_POSTS";

export const fetchPostsAction = (data) => {
  return {
    type: GET_POSTS,
    payload: data,
  };
};

export const fetchPosts = async (params, dispatch) => {
  const query = querystring.stringify(params);

  const response = await callApi(
    `/api/feeds/${query ? "?" + query : ""}`,
    "GET"
  );

  if (response && response.code !== 400 && response.code !== 500) {
    dispatch(fetchPostsAction(response));
  }

  return response;
};
