/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from "react-redux";
import CollapseBasic from './../atoms/collapse';
import { withRouter } from 'react-router';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
const responsive = {
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1
        }
    },
    viewGalleryImages: {
        desktop: {
            breakpoint: { max: 9999999, min: 1200 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1199, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1
        }
    }
}
class SimilarDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            viewVideoModalToggle: false,
            videoUrl: null
        };
    }
    openBox = (url, thumb) => {
        if (url !== '' && url !== undefined && url !== null) {
            this.setState({ viewVideoModalToggle: true, videoUrl: url })
        }
        if (thumb !== '' && thumb !== undefined && thumb !== null) {
            this.setState({ videoThumb: thumb })
        }
    }

    render() {
        const { name, data, type } = this.props;

        return (

            <div className="bg-movie-dark text-white mb-3">
                <CollapseBasic title={name} containerClass="bg-transparent type-white" bodyClass="mt-2 text-white" isOpen={true}>
                    <ul className="list-unstyled">
                        {data && data.results && Array.isArray(data.results) && data.results.length > 0 ?
                            data.results.map((item, index) =>

                                <li key={index}>
                                    <div className="d-flex mx-n2">
                                        <div className="px-2">
                                            <Link
                                                to={{ pathname: `/${type}/${item.slug}` }}
                                            >
                                                {item?.trailer?.thumbnail ?
                                                    <img width="90" src={item?.trailer?.thumbnail} alt="" />
                                                    :
                                                    <img width="90" src={require(`./../../assets/images/videopholder.jpg`)} alt="" />
                                                }
                                            </Link>
                                        </div>
                                        <div className="px-2 col">
                                            <Link
                                                to={{ pathname: `/${type}/${item.slug}` }}
                                            >
                                                {item.name}
                                            </Link>
                                            {/* <a href={item.slug} className="text-light ff-base">{item.name}</a> */}
                                            <div className="mt-1">
                                                {item?.total_rating?.avg_rating ?
                                                    <img src={require(`./../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0]}`)} alt="" />
                                                    :
                                                    "No Rating"
                                                }
                                            </div>
                                            <div className="mt-1 text-right">
                                                <div className="text-secondary font-weight-bold">{item?.total_rating?.total_review} Reviews</div>
                                            </div>
                                            <div className="mt-2">
                                                {item?.trailer?.url && item?.trailer?.thumbnail ?
                                                    <Button color="link" className="text-primary p-0" onClick={() => this.openBox(item.trailer.url, item.trailer.thumbnail)}>Watch Trailer</Button>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="bg-light" />
                                </li>
                            )
                            :
                            "No Record Found"
                        }


                    </ul>
                </CollapseBasic>

                {/* Watch Video/Trailer Modal */}
                <Modal isOpen={this.state.viewVideoModalToggle} toggle={() => this.setState({ viewVideoModalToggle: !this.state.viewVideoModalToggle })}>
                    <ModalHeader toggle={() => this.setState({ viewVideoModalToggle: !this.state.viewVideoModalToggle })}></ModalHeader>
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={true}
                            swipeable={true}
                            showDots={false}
                            arrows={false}>
                            <div className="item">
                                <video width="100%" controls poster={this.state.videoThumb}>
                                    <source src={this.state.videoUrl} type="video/mp4" />
                                </video>
                            </div>
                        </Carousel>
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}

const mapState = (state) => ({

});

const mapProps = (dispatch) => ({

});


export default withRouter(connect(mapState, mapProps)(SimilarDetail));