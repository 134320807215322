import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container,  Button, Modal,  ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu,  DropdownItem, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-multi-carousel';
import { callApi } from '../../../utils/apiCaller';
import { Link } from 'react-router-dom';
import {
    get_media_children, add_media_to_gallery, fetch_sub_categories, fetch_categories_data,
    filter_categories_data, clear_category, clear_subcategory
} from '../../../actions/business';
import { get_album_types_list, get_album_type_data, delete_selected_gallery_media } from "../../../actions/user";
import { upload_add_captions } from "../../../actions/myProfiles";
import EndOfGallery from '../../atoms/endOfGallery';

const responsive = {
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1
        }
    },
    viewGalleryImages: {
        desktop: {
            breakpoint: { max: 9999999, min: 1200 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1199, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1
        }
    }
};
class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMediaModalToggle: false,
            carouselMedia: null,
            activeTab: 'all',
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            fetchGalleryAlbum: "",
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            uploadMediaPostModal: false,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            albumType: '',
            pageNo: 1,
            //weatherData: {},
            secondCarouselMedia: [],
            professionalDetails: null,
            uploadCaptions: {},
            fetchTaxonomyListing: [],
            fetchTaxomomyCategory: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            taxonomycategoryId: "",
            categoryType: "business",
            captionData: "",
            uploadedId: {},
            cattaxListing: {},
            mode: "",
            uploadUrl: '/upload/multiuploader/?album=&instance=listing_images&image=undefined&doc=undefined',
            uploadedFiles: [],
            categoryTypeTab: "",
        }
    }

    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state;

        if (nextProps.professionalDetails && this.state.carouselMedia === null) {
            callApi(`/upload/list/all/?category=&media_type=&page=1&pro_listing=${nextProps.professionalDetails.id}`, 'GET')
                .then(res => {
                    if (res && Object.keys(res).length > 0) {
                        this.setState({ carouselMedia: res, professionalDetails: nextProps.professionalDetails });
                    }
                });
        }

        if(nextProps.uploadedMediaSet?.uploadedFiles?.length !== this.state.uploadedFiles.length){
            this.setState({ uploadedFiles: [...nextProps.uploadedMediaSet?.uploadedFiles]});
        }

        if(nextProps.callGalleryFunction !== this.props.callGalleryFunction && nextProps.callGalleryFunction ){
            this.handleAddMediaToGallery()
            this.props.resetGalleryFunctionToggle();
        }
    }

    componentDidMount() {
        this.props.get_media_children(this.props.mainTaxonomy, 'images');
        this.props.get_media_children(this.props.mainTaxonomy, 'videos');
        this.props.clear_category();
        this.props.clear_subcategory();
    }

    truncate = (filenameString) => {
        // let split = filenameString.split(".");
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };

    handleAddMediaToGallery = async () => {
        let multiuploaderfile = [...this.state.uploadedFiles].map(item => item.id);

        let url = `/upload/list/all/?category=&media_type=&page=1&pro_listing=${this.props.professionalDetails.id}`;
        let data = { type: "professionalmedia", entries: this.props.professionalDetails.id, professionaluserentries: this.props.professionalDetails.id, taxonomy_id: "", multiuploaderfile: multiuploaderfile, galleryType: 'doctors' };
        if (this.state.uploadedFiles.length > 0) {
            await this.props.add_media_to_gallery(data);
            this.setState({
                uploadMediaPostModal: false,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    uploadFiles: [],
                    uploadedFiles: []
                },
                uploadedFiles: [],
                viewMediaModalToggle: false
            }, () => {
                setTimeout(() => {
                 
                    callApi(url, 'GET')
                        .then(res => {
                            if (res && Object.keys(res).length > 0) {
                                this.setState({
                                    carouselMedia: res,
                                    activeTab: 'all'
                                });
                            }
                        });

                    callApi(`/api/doctors/${this.state.professionalDetails.id}/`, 'GET')
                        .then(res => {
                            if (res && Object.keys(res).length > 0) {
                                this.setState({
                                    professionalDetails: {
                                        ...this.state.professionalDetails,
                                        media_info: { ...res.media_info }
                                    }
                                })
                            }
                        });

                    this.props.resetUploadedMediaSet();    
                }, 2000);
            });

        }
    }

    handleOnClickChangeGalleryTab = (tab) => {
        let mediaType = '';
        if (tab === 'image' || tab === 'video') {
            mediaType = tab;
        }
        let url = `/upload/list/${tab}/?category=&media_type=${mediaType}&page=1&pro_listing=${this.props.professionalDetails.id}`;

        callApi(url, 'GET')
            .then(res => {
                if (res && Object.keys(res).length > 0) {
                    this.setState({ carouselMedia: res, activeTab: tab, categoryTypeTab: "" });
                }
            });
    }

    handleOnClickChangeGalleryCategory = (tab, id = '', tabType = '') => {
        let url = '',
            mediaType = '';

        let categoryTypeTab = '';
        if (tab == "all") {
            categoryTypeTab = "View All Media";
        } else if (tab == "image" && !tabType) {
            categoryTypeTab = "View All Images";
        } else if (tab == "video" && !tabType) {
            categoryTypeTab = "View All Videos";
        } 
        else {
            categoryTypeTab = tabType;
        }

        if (id) {
            url = `/upload/list/${tab}/?category=${id}&media_type=&page=1&pro_listing=${this.props.professionalDetails.id}`;

        } else {
            if (tab === 'image' || tab === 'video') {
                mediaType = tab;
            }
            url = `/upload/list/${tab}/?category=&media_type=${mediaType}&page=1&pro_listing=${this.props.professionalDetails.id}`;
        }

        callApi(url, 'GET')
            .then(res => {
                if (res && Object.keys(res).length > 0) {
                    this.setState({ carouselMedia: res, categoryTypeTab });
                }
            });
    }

    openSecondCarousal = (index) => {
        const carouselMedia = { ...this.state.carouselMedia };
        const array1 = index ? carouselMedia.results.filter((item, i) => i < index) : [];
        const array2 = (index !== carouselMedia.results.length - 1) ? carouselMedia.results.filter((item, i) => i > index) : [];
        const newArray = [carouselMedia.results[index], ...array2, ...array1];

        this.setState({ secondCarouselMedia: newArray, viewMediaModalToggle: true });
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }

    handleOnClickUploadMedia = () => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        this.props.getuploadUrl({ uploadUrl: this.state.uploadUrl }); 
        this.props.toggleEnableFileUpload(true,true,false);
        this.setState({categoryTypeTab: "View All Media"})
    }

    render() {
        const { images_children, videos_children } = this.props;
        const { carouselMedia, activeTab, secondCarouselMedia, professionalDetails, fetchTaxonomyListingImages, fetchTaxonomyListingVideos,
            uploadedId, cattaxListing, uploadUrl, categoryTypeTab } = this.state;

        return (
            <div className="h-100 d-flex flex-column">
                <div className="bg-white-shaded text-dark px-2 pt-2 pb-2 bg-business-bar" style={{ marginBottom: '35px' }}>
                    <Container>
                        <div className="d-flex align-items-center mx-n2">
                            <div className="px-2">
                                <div className="topbar-main-img-holder shifted">
                                    {professionalDetails?.professional_profileimage?.length ? (
                                        <img className="img-fluid" src={professionalDetails?.professional_profileimage[0]?.url} alt="" />
                                    ) : (
                                            <img className="img-fluid" src={`https://userdatawikireviews.s3.amazonaws.com/images/circle.png`} alt="" />
                                        )}
                                </div>
                            </div>
                            <div className="px-2 col">
                                <div className="d-flex align-items-start">
                                    <div className="mr-2">
                                        <h1 className="text-dark text-uppercase fs-20">{professionalDetails?.firstname} {professionalDetails?.lastname}</h1>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {professionalDetails?.total_rating?.avg_rating && (
                                            <img className="mr-2"
                                                title={professionalDetails?.total_rating?.avg_rating[1]}
                                                src={(professionalDetails &&
                                                    professionalDetails?.total_rating?.avg_rating[1] !== 'No Rating') ?
                                                    require(`../../../assets/images/icons/star/blue/${Math.floor(professionalDetails?.total_rating?.avg_rating[1])}rating.png`) :
                                                    require('../../../assets/images/icons/star/blue/norating.png')} alt="" />
                                        )}
                                        <span className="fs-14 ff-alt">{professionalDetails?.total_rating?.total_review} Review{professionalDetails?.total_rating?.total_review != 1 ? 's' : ''}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="px-2 flex-300">
                                <div className="d-flex align-items-center">
                                    <span className="mr-2 fs-12 lh-1-3 text-dark ff-alt">
                                        {professionalDetails?.address && professionalDetails?.address[0]?.address1}
                                        <br/>
                                        {professionalDetails?.address && professionalDetails?.address.length > 0 && (
                                            <span>
                                            {professionalDetails?.address && professionalDetails?.address[0]?.address2 ? professionalDetails?.address[0]?.address2 : ''}{professionalDetails?.address[0]?.address2 ? ',' : ''}{professionalDetails?.address[0]?.city ? professionalDetails?.address[0]?.city : ''}{professionalDetails?.address[0]?.city ? ',' : ''} {professionalDetails?.address[0]?.state ? professionalDetails?.address[0]?.state : ''} {professionalDetails?.address[0]?.zipcode} <br/> {professionalDetails?.address[0]?.country}      
                                            </span>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                <Container className="my-auto">
                <div className="text-center">
                        {(professionalDetails?.media_info?.all == 0 && professionalDetails?.media_info?.image == 0 && professionalDetails?.media_info?.video == 0) ?
                            // If no image 
                            <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '200px' }}>
                                <div className="px-4 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                    <span>This Listing has no image/video uploaded yet. <br /> Be the first to submit one.</span>
                                    <div className="mt-2">
                                        <Button color="link" className="text-white font-weight-bold" onClick={() => {
                                            if(!this.checkIfLoggedIn()){
                                                this.props.forceToggleLoginModal();
                                                return false;
                                            }
                                            this.props.getuploadUrl({uploadUrl}); 
                                            this.props.toggleEnableFileUpload(true,true,false);
                                        }}><i className="icon-camera-white"></i> Add Image</Button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <Carousel
                                    responsive={responsive.viewGalleryImages}
                                    infinite={true}
                                    autoPlay={false}
                                    swipeable={true}
                                    showDots={false}
                                    arrows={true}
                                    containerClass="gallery-carousel" 
                                    sliderClass={`${carouselMedia?.results?.length === 0}` ? "mx-auto" : ""}
                                    >
                                    {carouselMedia?.results?.length > 0 ? carouselMedia?.results?.map((med, i) => {
                                        return (
                                            <div className="item px-4" key={med?.media?.id}>
                                                <div className={`${med?.media_type === 'video' ? 'video-holder' : 'img-holder'} text-center`} role="button" onClick={() => this.openSecondCarousal(i)}>
                                                    <img className="fixed-height-img is-200 full-width" src={med?.thumbnail} alt="" style={{ border: '2px solid #8791b5' }} />
                                                </div>
                                                <div className="text-left position-relative">
                                                    <Link
                                                        to={`/people/${med?.user?.user?.username}`}
                                                        // to='#'
                                                    >
                                                        <img className="img-circle _30x30 mt-n4 ml-3" src={med?.user?.current_profile_pic ? med?.user?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt="" onError={(error) =>
                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                        } />
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                        :
                                        (
                                            <div className="item px-4">
                                                <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '200px' }}>
                                                    <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                                        <span>No result found for this category</span>
                                                        {/* <div className="mt-2">
                                                        <Button color="link" className="text-tertiary font-weight-bold">Back</Button>
                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </Carousel>
                            </div>
                        }

                        <div className="d-flex mx-n4 justify-content-center mt-5">
                            <div className="px-4">
                                <div className={`gallery-filterable-list fs-16 ${activeTab === 'all' ? 'active' : ''}`} role="button" onClick={() => this.handleOnClickChangeGalleryTab('all')}>
                                    All ({professionalDetails?.media_info?.all})
                                </div>
                            </div>
                            <div className="px-4">
                                <div className={`gallery-filterable-list fs-16 ${activeTab === 'image' ? 'active' : ''}`} role="button" onClick={() => this.handleOnClickChangeGalleryTab('image')}>
                                    Photos ({professionalDetails?.media_info?.image})
                                </div>
                            </div>
                            <div className="px-4">
                                <div className={`gallery-filterable-list fs-16 ${activeTab === 'video' ? 'active' : ''}`} role="button" onClick={() => this.handleOnClickChangeGalleryTab('video')}>
                                    Videos ({professionalDetails?.media_info?.video})
                                </div>
                            </div>
                            <div className="px-4">
                                <Link to={{
                                    pathname: `/view_all/${professionalDetails?.id}`,
                                    search: `?name=${professionalDetails?.firstname?.replace(/\s+/g, '')?.toLowerCase()}`,
                                    state: { id: professionalDetails?.id, categoryType: this.props.mainTaxonomy, professionals: true, mainTaxonomy: this.props.mainTaxonomy, itemName: professionalDetails?.firstname + " " + professionalDetails?.lastname, dentists: professionalDetails?.content_type_name === "dentists" }
                                }}>
                                    <div className="gallery-filterable-list fs-16" role="button">
                                        View All
                                    </div>
                                </Link>
                            </div>
                            <div className="px-4">
                                <UncontrolledDropdown>
                                    <DropdownToggle className="text-white bg-transparent font-weight-bold p-0" color="transparent" size="sm" caret>
                                        <span className="gallery-filterable-list fs-16" role="button">
                                        {categoryTypeTab && categoryTypeTab != "" ? categoryTypeTab : "Categories"}
                                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu className="type-tertiary">
                                        <DropdownItem className="fs-14" onClick={() => this.handleOnClickChangeGalleryCategory('all')}>
                                            View All Media
                                                        </DropdownItem>
                                        <DropdownItem className="fs-14" onClick={() => this.handleOnClickChangeGalleryCategory('image')}>
                                            View All Images
                                                        </DropdownItem>
                                        <div className="pl-2">
                                        {images_children?.length > 0 && images_children[0]?.children?.length > 0 && images_children[0]?.children?.map(item => {
                                            return (
                                                <DropdownItem className="fs-14" key={item.id} onClick={() => this.handleOnClickChangeGalleryCategory('image', item.id, item.label)}>
                                                    {item.label}
                                                </DropdownItem>
                                            )
                                        })}
                                        </div>
                                        <DropdownItem className="fs-14" onClick={() => this.handleOnClickChangeGalleryCategory('video')}>
                                            View All Videos
                                        </DropdownItem>
                                        <div className="pl-2">
                                        {videos_children?.length > 0 && videos_children[0]?.children?.length > 0 && videos_children[0]?.children?.map(item => {
                                            return (
                                                <DropdownItem className="fs-14" key={item.id} onClick={() => this.handleOnClickChangeGalleryCategory('video', item.id, item.label)}>
                                                    {item.label}
                                                </DropdownItem>
                                            )
                                        })}
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                            <div className="px-4">
                                <Button color="link" className="text-primary fs-16 font-weight-bold p-0" onClick={this.handleOnClickUploadMedia}>
                                    <FontAwesomeIcon icon="plus" size="sm" />
                                                    &nbsp;add media
                                </Button>
                            </div>
                        </div>
                    </div>
                </Container>

                {/* View Media Modal */}
                <Modal centered isOpen={this.state.viewMediaModalToggle} toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={false}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            sliderClass={`${carouselMedia?.results?.length === 0}` ? "mx-auto" : ""}>
                            {secondCarouselMedia.length > 0 ? secondCarouselMedia.map((med, i) => {
                                return (
                                    <div className="item fs-14 h-100 d-flex flex-column justify-content-center" key={med?.media?.id}>
                                        <div>
                                            <div className="d-flex text-white justify-content-between mt-3">
                                                <div className="mr-2 ff-alt">
                                                    {med?.caption}
                                                </div>
                                                <div className="ff-base">
                                                    {med?.label}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                            {(med?.type === 'image' || med?.type === '') && (
                                                <img className="img-fluid shown-media" src={med?.location} alt={med?.caption} />
                                            )}
                                            {med?.type === 'video' && (
                                                <video className="img-fluid shown-media" controls>
                                                    <source src={med?.location} type="video/mp4" />
                                                 Your browser does not support the video tag.
                                                </video>
                                            )}
                                            {/* <div>
                                                {i+1} of {secondCarouselMedia.length}
                                            </div> */}
                                        </div>
                                        <div className="px-2 mt-3">
                                            <div className="d-flex flex-nowrap mx-n2">
                                                <div className="px-2 col d-flex flex-nowrap">
                                                    <div className="mr-2">
                                                        <img className="img-circle _30x30" src={med?.user?.current_profile_pic ? med?.user?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt="" onError={(error) =>
                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                        } />
                                                    </div>
                                                    <div>
                                                        <div className="text-primary ff-base">
                                                            Uploaded by&nbsp;
                                                            <Link className="text-reset" to={`/people/${med?.user?.user?.username}`}><span className="font-weight-bold">{med?.user?.user?.first_name}{" "}{med?.user?.user?.last_name}</span>
                                                            </Link>
                                                        </div>
                                                        <div className="text-tertiary ff-base">
                                                            {med?.upload_date}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-2 col-auto">
                                                    <h2 className="fs-38" style={{ color: '#d4dae6' }}>{i + 1} of {secondCarouselMedia.length}</h2>
                                                </div>
                                                <div className="px-2 col">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : null}
                            <EndOfGallery businessName = {`${professionalDetails?.firstname} ${professionalDetails?.lastname}`} handleOnClickUploadMedia={this.handleOnClickUploadMedia} />
                        </Carousel>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapState = (state) => ({
    images_children: state.business.images_children,
    videos_children: state.business.videos_children,
    album_types_list: state.user.album_types_list,
    album_type_data: state.user.album_type_data,
    fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
    new_sub_categories: state.business.new_sub_categories,
    fetch_data_categories: state.business.fetch_data_categories,
    filter_data_category: state.business.filter_data_category,
    my_profile_data: state.user.my_profile
})

const mapProps = (dispatch) => ({
    get_media_children: (category, type) => dispatch(get_media_children(category, type)),
    get_album_types_list: () => dispatch(get_album_types_list()),
    get_album_type_data: (type, album, page) => dispatch(get_album_type_data(type, album, page)),
    delete_selected_gallery_media: (dataID) => dispatch(delete_selected_gallery_media(dataID)),
    add_media_to_gallery: (data) => dispatch(add_media_to_gallery(data)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    fetch_sub_categories: ({ sub_cat, level }) => dispatch(fetch_sub_categories({ sub_cat, level })),
    fetch_categories_data: (cat, sub_cat, type, index) => dispatch(fetch_categories_data(cat, sub_cat, type, index)),
    filter_categories_data: ({ category, sub_cat, level }) => dispatch(filter_categories_data({ category, sub_cat, level })),
    clear_category: () => dispatch(clear_category()),
    clear_subcategory: () => dispatch(clear_subcategory()),
});

export default connect(mapState, mapProps)(Gallery);
