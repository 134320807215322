import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup,  Input, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";
import { add_to_list, remove_from_list, share_by_email, update_business_flag } from '../../../actions/business';
import { get_item_flags, get_professional_pins } from '../../../actions/itemDetail';

class SocialPinFlag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lawyerDetails: {},
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: '',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
        }
    }

    componentWillReceiveProps(nextProps) {
        const { lawyerDetails, pins_data } = nextProps;

        if (lawyerDetails && Object.keys(lawyerDetails).length > 0) {
            this.setState({ lawyerDetails }, () => {
                if(!this.state.lawyerDetails.user_recommending && pins_data && Object.keys(pins_data).length > 0){
                    const details = {...this.state.lawyerDetails};
                    let user_recommending = {
                        pin_list_value: [],
                    }
                    const pins_status = pins_data;//details.pins_status;
                    const keys = Object.keys(pins_status);
                    keys.forEach(item => {
                        if(item !== 'code' && item !== 'recommend_list' && pins_status[item]){
                            user_recommending.pin_list_value.push(item);
                        }
                    })
                    if(pins_status.recommend_list){
                        user_recommending.recommend_list = 1;
                    }
                    details.user_recommending = user_recommending;
                    this.setState({ lawyerDetails: details });
                }
            });
        }
    }

    componentDidMount() {
        this.props.get_item_flags('business');
    }

    handleOnClickAddToList = (type, status) => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        if (this.state.lawyerDetails?.user_recommending) {
            let message = '',
                url = '',
                nextUrl = '',
                data = { type };

            let lawyerDetails = this.state.lawyerDetails;
            let pinList = lawyerDetails?.user_recommending?.pin_list_value ? [...lawyerDetails?.user_recommending?.pin_list_value] : [];

            if (status) {
                message = `${this.state.lawyerDetails.firstname} ${this.state.lawyerDetails.lastname} has been deleted from your ${type}!`;
                url = `/api/professionalpins/?id=${this.state.lawyerDetails.id}&type=${type}`;
                this.props.remove_from_list(url, message, nextUrl);
                pinList = pinList.filter(item => {
                    return item !== type.split(' ').join('_');
                });
            } else {
                data.professionaluser_entry = this.state.lawyerDetails.id;
                message = `${this.state.lawyerDetails.firstname} ${this.state.lawyerDetails.lastname} has been added to your ${type}`;
                url = `/api/professionalpins/`;
                this.props.add_to_list(url, data, message, nextUrl);
                pinList.push(type.split(' ').join('_'));
            }
            lawyerDetails.user_recommending.pin_list_value = pinList;

            this.setState({
                lawyerDetails
            });
        }
    }

    handleOnClickRecommend = (status) => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        if (this.state.lawyerDetails?.user_recommending) {
            let message = '',
                url = '',
                nextUrl = '',
                data = { type: 'recommend list' };

            let lawyerDetails = this.state.lawyerDetails;
            let pinList = lawyerDetails?.user_recommending?.pin_list_value ? [...lawyerDetails?.user_recommending?.pin_list_value] : [];

            if (status) {
                message = `${this.state.lawyerDetails.firstname} ${this.state.lawyerDetails.lastname} has been deleted from your recommended list`;
                url = `/api/professionalpins/${this.state.lawyerDetails.id}/?type=recommend list`;
                this.props.remove_from_list(url, message, nextUrl)
                delete lawyerDetails.user_recommending.recommend_list
            } else {
                data.professionaluser_entry = this.state.lawyerDetails.id;
                message = `You recommend ${this.state.lawyerDetails.firstname} ${this.state.lawyerDetails.lastname}!`;
                url = `/api/professionalpins/`;
                this.props.add_to_list(url, data, message, nextUrl);
                lawyerDetails.user_recommending.recommend_list = 1;
            }

            this.setState({
                lawyerDetails
            });
        }
    }

    handleOnChangeShareByEmail = (e) => {
        let errors = { ...this.state.shareError };
        if (e.target.value) {
            delete errors[e.target.name];
        }

        this.setState({
            [e.target.name]: e.target.value,
            shareError: errors
        });
    }

    handleOnSubmitShareByEmail = () => {
        let errors = { ...this.state.shareError };

        if (this.state.shareName === '') {
            errors.shareName = 'This field is required!';
        }

        if (this.state.shareEmail === '') {
            errors.shareEmail = 'This field is required!';
        } else {
            const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!reg.test(String(this.state.shareEmail).toLowerCase())) {
                errors.shareEmail = 'Please enter a valid email!';
            }
        }

        if (errors && Object.keys(errors).length > 0) {
            this.setState({ shareError: errors });
        } else {
            let key = 'listing_id';

            let url = `/api/professionalShareByemail/`;

            const data = {
                [key]: this.state.shareId,
                mix_uid: `${this.state.shareEmail.split("@")[0]}_${this.state.shareId}_1605789136525`,
                email: this.state.shareEmail,
                user_name: this.state.shareName,
                professional: "professional"
            };

            this.props.share_by_email(url, data);

            this.setState({
                shareByEmailModalToggle: false,
                shareName: '',
                shareEmail: '',
                shareError: {}
            });
        }
    }

    handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id
        });
    }

    handleOnClickFlag = (id, message) => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
		const data = {
            flag_details: {
                flag: id,
                is_flagged: true,
                object_id: this.props.lawyerDetails.id
            },
            message: message
        };
        this.props.update_business_flag(data);
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const { lawyerDetails } = this.state;
        const { flags_data } = this.props;
        let url = `${this.state.protocol}//${this.state.host}/lawyers/${this.props.lawyerDetails?.slug ? this.props.lawyerDetails?.slug : 'null'}`;
        let title_quote = `Checkout ${this.props.lawyerDetails?.firstname} ${this.props.lawyerDetails?.lastname} on`;

        return (
            <div className="wk-mv-share">
                <ul className="justify-content-center">
                    <li className="share">
                        <a href="javascript:void(0)">{''}</a>
                        <ul className="dropdownbar">
                            <li>
                                <a href="javascript:void(0)">
                                    <FacebookShareButton
                                        url={url}
                                        quote={title_quote}
                                        hashtag={this.state.fbHashTag}>
                                        {'Facebook'}
                                    </FacebookShareButton>
                                </a>
                            </li>
                            {/* <li>
                                <a href="#">Google+</a>
                            </li> */}
                            <li>
                                <a href="javascript:void(0)">
                                    <TwitterShareButton
                                        url={url}
                                        title={title_quote}
                                        via={this.state.shareVia}
                                        hashtags={this.state.twitterHashTags}>
                                        {'Twitter'}
                                    </TwitterShareButton>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" onClick={() => this.handleOnClickShareByEmail(this.props.lawyerDetails?.id)}>By email</a>
                            </li>
                        </ul>
                    </li>

                    <li className="like" onClick={() => this.handleOnClickRecommend(lawyerDetails?.user_recommending?.recommend_list)}>
                        <a href="javascript:void(0)" className={`${lawyerDetails?.user_recommending?.recommend_list ? 'active' : ''}`}>&nbsp;</a>
                    </li>
                    <li className="pin">
                        <a href="javascript:void(0)" className={`${(lawyerDetails?.user_recommending?.pin_list_value && lawyerDetails?.user_recommending?.pin_list_value?.length > 0) ? 'active' : ''}`}>{''}</a>
                        <ul className="dropdownbar">
                            <li onClick={() => this.handleOnClickAddToList('want list', lawyerDetails?.user_recommending?.pin_list_value?.includes('want_list'))}>
                                <a href="javascript:void(0)"><img alt="" src={require('../../../assets/images/icons/want-list.png')} /> {lawyerDetails?.user_recommending?.pin_list_value?.includes('want_list') ? 'Added' : 'Add'} to want list</a>
                            </li>
                            <li onClick={() => this.handleOnClickAddToList('favourite list', lawyerDetails?.user_recommending?.pin_list_value?.includes('favourite_list'))}>
                                <a href="javascript:void(0)"><img alt=""  src={require('../../../assets/images/icons/favoritelist.png')} /> {lawyerDetails?.user_recommending?.pin_list_value?.includes('favourite_list') ? 'Added' : 'Add'} to favorites</a>
                            </li>
                            <li onClick={() => this.handleOnClickAddToList('watch list', lawyerDetails?.user_recommending?.pin_list_value?.includes('watch_list'))}>
                                <a href="javascript:void(0)"><img alt=""  src={require('../../../assets/images/icons/watchlist.png')} /> {lawyerDetails?.user_recommending?.pin_list_value?.includes('watch_list') ? 'Added' : 'Add'} to watch list</a>
                            </li>
                        </ul>
                    </li>
                    <li className="flag">
                        <a href="javascript:void(0)">{''}</a>
                        <ul className="dropdownbar dropdownbar-right">
                            {flags_data?.results?.length > 0 && flags_data?.results?.map(flag => {
                                return (
                                    <li key={flag.id} onClick={() => this.handleOnClickFlag(flag.id, flag.label)}>
                                        <a href="javascript:void(0)">{flag.label}</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </li>
                </ul>

                {/* Share By Email Modal Popup */}
                <Modal
                    size="sm"
                    isOpen={this.state.shareByEmailModalToggle}
                    toggle={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })
                    }
                >
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() =>
                            this.setState({
                                shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                            })
                        }
                    >
                        Share by Email
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="text"
                                name="shareName"
                                placeholder="Your name"
                                value={this.state.shareName}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareName && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareName}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="email"
                                name="shareEmail"
                                placeholder="Recipient's email"
                                value={this.state.shareEmail}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareEmail && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareEmail}
                                </div>
                            )}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <div className="text-right">
                            <Button color="primary" onClick={this.handleOnSubmitShareByEmail}>
                                Share
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapState = (state) => ({
    flags_data: state.item_detail.flags_data,
    pins_data: state.item_detail.pins_data,
    my_profile_data: state.user.my_profile
});

const mapProps = (dispatch) => ({
    get_item_flags: (type) => dispatch(get_item_flags(type)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    update_business_flag: (data) => dispatch(update_business_flag(data)),
    get_professional_pins: (id) => dispatch(get_professional_pins(id)),
});

export default connect(mapState, mapProps)(SocialPinFlag);
