import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { share_by_email } from "../../actions/business";
import ModalComponent from "../atoms/modalComponent";
import ShareByEmail from "../atoms/shared/shareByEmail";
function MovieCard({ movie, index, handleOnClickAddToList, itemID, internalReview, toggleCreateReview, storylineReadShowMore, handleToggleReadMoreShow, hideReview }) {

    const dispatch = useDispatch();

    const [movieCardState, setMovieCardState] = useState({
        protocol: window.location.protocol,
        host: window.location.host,
        shareVia: 'WikiReviews',
        fbHashTag: '#WikiReviews',
        twitterHashTags: ['WikiReviews'],
    })

    const [emailState, setEmailState] = useState({
            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: 0
    })

    const getDirector = () => movie.professional.filter(p => p.profession === "Director" || p.category === "Director");

    const getCasts = () => movie.professional.filter(p => p.profession === "Cast" || p.category === "Cast").slice(0, 3);;

    const getCategories = () => {
        if (!movie.taxonomy_list?.length) return;

        if (typeof movie.taxonomy_list[0] === "string") return movie.taxonomy_list.join(", ");

        return movie.taxonomy_list.map(a => a.category).join(", ")
    };

   const handleOnClickShareByEmail = (id) => {
        setEmailState({
            shareByEmailModalToggle: !emailState.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id
        });
    }

    const handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote }) => {
        const key = 'listing_id';
        let url = `/api/shareByemail/`;
        
        let review_id = emailState.shareId;
        let data;
        if (review_id) {
          data = {
            review_id: review_id,
            mix_uid: `${shareEmail.split('@')[0]}_${emailState.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        } else {
          data = {
            [key]: emailState.shareId,
            mix_uid: `${shareEmail.split('@')[0]}_${emailState.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        }
    
        dispatch(share_by_email(url, data));
      }
      const movieId = !!movie?.id ? `/${movie?.id}` : ""

    return (
        <div id={itemID}>
            {/* Repeat this */}
            <div className="mb-1">
                <div className="bg-white text-dark p-3 mb-1">
                    <div className="d-flex mx-n2 mb-2">
                        <div className="px-2 d-flex flex-column">
                            {/*<a href="#" className="text-decoration-none">
                                 <img width="111" src="https://userdatawikireviews.s3.amazonaws.com/images/movie-poster-avatar.jpg" alt="" />
                                <img width="111" src={movie.trailer?.thumbnail} alt="" />
                            </a> */}
                            <Link
                                to={{
                                    pathname: `${movie?.slug ? `/movies/${movie.slug}` : `/movies/${movie?.name?.toLowerCase().replace(/ /g, '-')}`}`,
                                    state: { id: movie?.id }
                                }} className="text-decoration-none d-block mb-3">
                                {movie?.listing_profileimage && Array.isArray(movie?.listing_profileimage) && movie?.listing_profileimage.length ?

                                    <img
                                        width="111"
                                        src={movie?.listing_profileimage && movie?.listing_profileimage[0] ? movie?.listing_profileimage[0].url : 'https://userdatawikireviews.s3.amazonaws.com/images/movie-poster-avatar.jpg'}
                                        onError={(err) => err.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/movie-poster-avatar.jpg`}
                                        alt={movie?.name}
                                    />
                                    : (<img
                                        width="111"
                                        src={'https://userdatawikireviews.s3.amazonaws.com/images/movie-poster-avatar.jpg'}
                                        alt={movie?.name} />
                                    )}
                            </Link>

                            <div className="text-center">
                                {!hideReview ?
                                    (internalReview ?
                                        <span
                                            onClick={() => {
                                                toggleCreateReview(movie)
                                            }}
                                            className="cursor-pointer text-primary font-weight-bold p-0">
                                            {'Write a review'}
                                        </span>
                                        :
                                        <Link
                                            to={{
                                                pathname: `${movie?.slug ? `/movies/${movie.slug}` : `/movies/${movie?.name?.toLowerCase().replace(/ /g, '-')}`}`,
                                                state: { id: movie?.id }
                                            }}
                                            className="text-dark font-weight-bold ff-base">
                                            <Button color="link" className="text-primary font-weight-bold p-0">Write a review</Button>
                                        </Link>)
                                    :
                                    ''
                                }
                            </div>
                        </div>
                        <div className="px-2 col">
                            <div className="d-flex mx-n2">
                                <div className="px-2 col">
                                    <div className="ff-base">
                                        <Link
                                            to={{
                                                pathname: `${movie?.slug ? `/movies/${movie?.slug}` : `/movies/${movie?.name?.toLowerCase().replace(/ /g, '-')}`}`,
                                                state: { id: movie?.id }
                                            }}
                                            className="text-dark font-weight-bold ff-base">
                                            {index + 1}. {movie?.name}
                                        </Link>
                                        <div className="mt-2 d-flex align-items-center">
                                            <span className="hoverable-rating  d-flex align-items-start">
                                                <img title={movie?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${movie?.total_rating['avg_rating'][0]}`} alt="Rating" />
                                                {/* <img title="3.5" src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/35.png" alt="3.5 Rating" /> */}
                                                <div className="detail-rating-onhover">
                                                    <div className="text-dark font-weight-bold mb-2">
                                                        Detailed Star Rating
                                                    </div>

                                                    <div>
                                                        <div className="d-flex flex-nowrap align-items-center">
                                                            <img title={movie && movie?.total_rating && movie.total_rating['Attention Span'] && movie.total_rating['Attention Span'][1]} className="mr-1" src={movie && movie?.total_rating && movie.total_rating['Attention Span'] && movie.total_rating['Attention Span'][0] ? `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${movie?.total_rating['Attention Span'][0]}` : ""} alt="" />
                                                            <span className="ff-alt">Attention Span</span>
                                                        </div>
                                                        <div className="d-flex flex-nowrap align-items-center">
                                                            <img title={movie && movie?.total_rating && movie.total_rating['Cinematography'] && movie.total_rating['Cinematography'][1]} className="mr-1" src={movie && movie?.total_rating && movie.total_rating['Cinematography'] && movie.total_rating['Cinematography'][0] ? `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${movie?.total_rating['Cinematography'][0]}` : ""} alt="" />
                                                            <span className="ff-alt">Cinematography</span>
                                                        </div>
                                                        <div className="d-flex flex-nowrap align-items-center">
                                                            <img title={movie && movie?.total_rating && movie.total_rating['Quality of Acting'] && movie.total_rating['Quality of Acting'][1]} className="mr-1" src={movie && movie?.total_rating && movie.total_rating['Quality of Acting'] && movie.total_rating['Quality of Acting'][0] ? `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${movie?.total_rating['Quality of Acting'][0]}` : ""} alt="" />
                                                            <span className="ff-alt">Quality of Acting</span>
                                                        </div>
                                                        <div className="d-flex flex-nowrap align-items-center">
                                                            <img title={movie && movie?.total_rating && movie.total_rating['Score'] && movie.total_rating['Score'][1]} className="mr-1" src={movie && movie?.total_rating && movie.total_rating['Score'] && movie.total_rating['Score'][0] ? `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${movie?.total_rating['Score'][0]}` : ""} alt="" />
                                                            <span className="ff-alt">Score</span>
                                                        </div>
                                                        <div className="d-flex flex-nowrap align-items-center">
                                                            <img title={movie && movie?.total_rating && movie.total_rating['Storyline/Plot'] && movie.total_rating['Storyline/Plot'][1]} className="mr-1" src={movie && movie?.total_rating && movie.total_rating['Storyline/Plot'] && movie.total_rating['Storyline/Plot'][0] ? `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${movie?.total_rating['Storyline/Plot'][0]}` : ""} alt="" />
                                                            <span className="ff-alt">Storyline/Plot</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </span>&nbsp;&nbsp;
                                            <span>{movie?.total_rating.total_review} reviews</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-2 col-auto">
                                    <div className={`d-inline-flex flex-nowrap mx-n1 ${index == 0 ? `tourStep-4_movie` : ''}`}>
                                        <div className="px-1">
                                            <UncontrolledDropdown>
                                                <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                    <div className="share-it-icon"></div>
                                                </DropdownToggle>
                                                <DropdownMenu className="type-tertiary-alt" right>
                                                    <DropdownItem className="fs-14">
                                                        <FacebookShareButton
                                                            url={`${movieCardState.protocol}//${movieCardState.host}/movies${movieId}/${movie?.slug ? movie?.slug : 'null'}`}
                                                            quote={`${movie?.name} Reviews \n ${movie?.additional_info?.storyline && movie?.additional_info?.storyline[0]?.value ? movie?.additional_info?.storyline[0]?.value : ''}`}
                                                            hashtag={movieCardState.fbHashTag}>
                                                            {'Facebook'}
                                                        </FacebookShareButton>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="fs-14">Google +</DropdownItem> */}
                                                    <DropdownItem className="fs-14">
                                                        <TwitterShareButton
                                                            url={`${movieCardState.protocol}//${movieCardState.host}/movies${movieId}/${movie?.slug ? movie?.slug : 'null'}`}
                                                            title={`${movie?.name} Reviews \n ${movie?.additional_info?.storyline && movie?.additional_info?.storyline[0]?.value ? movie?.additional_info?.storyline[0]?.value : ''}`}
                                                            via={movieCardState.shareVia}
                                                            hashtags={movieCardState.twitterHashTags}>
                                                            {'Twitter'}
                                                        </TwitterShareButton>
                                                    </DropdownItem>
                                                    <DropdownItem className="fs-14" onClick={() => handleOnClickShareByEmail(movie?.latest_review?.id)}>By Email</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                        <div className="px-1">
                                            <UncontrolledDropdown>
                                                <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                    <div className="thumbpin-icon"></div>
                                                </DropdownToggle>
                                                <DropdownMenu className="type-tertiary-alt" right>
                                                    <DropdownItem className="fs-14" onClick={() => handleOnClickAddToList('want list', 
                                                    (Array.isArray(movie?.pins) && movie?.pins?.length > 0 && movie.pins[0]?.want_list && Array.isArray(movie.pins[0]?.want_list) && movie.pins[0]?.want_list.length > 0 && movie.pins[0]?.want_list[0] === 1 ? true : false),
                                                    movie)}>
                                                        <FontAwesomeIcon icon="heart" className="mr-2" fixedWidth /> 
                                                        {Array.isArray(movie?.pins) && movie?.pins?.length > 0 && movie.pins[0]?.want_list && Array.isArray(movie.pins[0]?.want_list) && movie.pins[0]?.want_list.length > 0 && movie.pins[0]?.want_list[0] === 1 ? 'Added' : 'Add'} to want list
                                                    </DropdownItem>

                                                    <DropdownItem className="fs-14" onClick={() => handleOnClickAddToList('favourite list', 
                                                    Array.isArray(movie?.pins) && movie?.pins?.length > 0 && movie.pins[0]?.favourite_list && Array.isArray(movie.pins[0]?.favourite_list) && movie.pins[0]?.favourite_list.length > 0 && movie.pins[0]?.favourite_list[0] === 1 ? true : false, 
                                                    movie)}>
                                                        <FontAwesomeIcon icon="star" className="mr-2" fixedWidth /> 
                                                        {Array.isArray(movie?.pins) && movie?.pins?.length > 0 && movie.pins[0]?.favourite_list && Array.isArray(movie.pins[0]?.favourite_list) && movie.pins[0]?.favourite_list.length > 0 && movie.pins[0]?.favourite_list[0] === 1 ? 'Added' : 'Add'} to favorites
                                                    </DropdownItem>

                                                    <DropdownItem className="fs-14" onClick={() => handleOnClickAddToList('watch list', 
                                                    Array.isArray(movie?.pins) && movie?.pins?.length > 0 && movie.pins[0]?.watch_list && Array.isArray(movie.pins[0]?.watch_list) && movie.pins[0]?.watch_list.length > 0 && movie.pins[0]?.watch_list[0] === 1 ? true : false,
                                                    movie)}>
                                                        <FontAwesomeIcon icon="eye" className="mr-2" fixedWidth /> 
                                                        {Array.isArray(movie?.pins) && movie?.pins?.length > 0 && movie.pins[0]?.watch_list && Array.isArray(movie.pins[0]?.watch_list) && movie.pins[0]?.watch_list.length > 0 && movie.pins[0]?.watch_list[0] === 1 ? 'Added' : 'Add'} to watch list
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ff-alt">
                                <div className="mt-2">
                                    <div className="d-flex">
                                        {
                                            movie.additional_info &&
                                            <>
                                                <div className="wb-break-word">{movie?.additional_info?.duration && movie.additional_info?.duration[0]?.value}</div>
                                                {(movie?.additional_info?.duration && movie.additional_info?.duration[0]?.value) ? <div className="mx-2">|</div> :''}
                                                

                                                <div>
                                                    <div className="hoverable-mv-rating">
                                                        <div className="font-weight-bold text-nowrap">
                                                            {movie.additional_info?.rating && movie.additional_info?.rating[0]?.value}
                                                        </div>
                                                        <div className="mv-rating-onhover">
                                                            <div className="text-secondary-dark bg-white p-3 border border-secondary">
                                                                {/* Dynamic Rating */}
                                                                {movie.additional_info?.rating && movie.additional_info?.rating[0]?.value === 'PG' ?
                                                                    <div className="mb-3">
                                                                        <span>PARENTAL GUIDANCE SUGGESTED</span> Parents urge to give
                                                                        “parental guidance.” May contain some material parents might not like for their young children.
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }
                                                                {movie.additional_info?.rating && movie.additional_info?.rating[0]?.value === 'G' ?
                                                                    <div className="mb-3">
                                                                        <span>GENERAL AUDIENCES</span> Nothing that would offend parents for viewing by children.
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }
                                                                {movie.additional_info?.rating && movie.additional_info?.rating[0]?.value === 'PG-13' ?
                                                                    <div className="mb-3">
                                                                        <span>PARENTS STRONGLY CAUTIONED</span> Parents are urged to be cautious. Some material may be inappropriate for pre-teenagers.
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }
                                                                {movie.additional_info?.rating && movie.additional_info?.rating[0]?.value === 'R' ?
                                                                    <div className="mb-3">
                                                                        <span>RESTRICTED</span> Contains some adult material. Parents are urged to learn more about the film before taking their young children with them.Nothing that would offend parents for viewing by children.
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }
                                                                {movie.additional_info?.rating && movie.additional_info?.rating[0]?.value === 'NC-17' ?
                                                                    <div className="mb-3">
                                                                        <span>NO ONE 17 AND UNDER ADMITTED</span>  Clearly adult. Children are not admitted.
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }
                                                                {/* Static Info */}
                                                                <h2 className="text-secondary">The Film Rating System</h2>
                                                                <hr className="bg-secondary my-2" />
                                                                <div className="d-flex flex-nowrap">
                                                                    <div className="mr-2">
                                                                        <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/gda-1.jpg" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <strong>GENERAL AUDIENCES</strong> Nothing that would offend parents for viewing by children.
                                                                    </div>
                                                                </div>
                                                                <hr className="bg-secondary" />
                                                                <div className="d-flex flex-nowrap">
                                                                    <div className="mr-2">
                                                                        <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/pg-2.jpg" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <strong>PARENTAL GUIDANCE SUGGESTED</strong> Parents urge to give “parental guidance.” May contain some material parents
                                                                        might not like for their young children.
                                                                    </div>
                                                                </div>
                                                                <hr className="bg-secondary" />
                                                                <div className="d-flex flex-nowrap">
                                                                    <div className="mr-2">
                                                                        <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/pg-3.jpg" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <strong>PARENTS STRONGLY CAUTIONED</strong> Parents are urged to be cautious. Some material may be inappropriate for
                                                                        pre-teenagers.
                                                                    </div>
                                                                </div>
                                                                <hr className="bg-secondary" />
                                                                <div className="d-flex flex-nowrap">
                                                                    <div className="mr-2">
                                                                        <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/r-4.jpg" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <strong>RESTRICTED</strong> Contains some adult material. Parents are urged to learn more about the film before
                                                                        taking their young children with them.Nothing that would offend parents for viewing by children.
                                                                    </div>
                                                                </div>
                                                                <hr className="bg-secondary" />
                                                                <div className="d-flex flex-nowrap">
                                                                    <div className="mr-2">
                                                                        <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/nc-5.jpg" alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <strong> NO ONE 17 AND UNDER ADMITTED</strong> Clearly adult. Children are not admitted.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {movie.additional_info?.rating && movie.additional_info?.rating[0]?.value ? <div className="mx-2">|</div> : ''}               
                                            </>
                                        }
                                        {
                                            movie?.movie_release_date &&
                                            <div className="text-nowrap">{moment(movie?.movie_release_date).format('LL')}</div>
                                        }
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <span>Categories: </span>
                                    <span className="font-weight-bold">{getCategories()}</span>

                                </div>
                                <div className="mt-2">
                                    <span>Director: </span>
                                    <span className="font-weight-bold">
                                        {getDirector().map((prof, index) => (
                                                <span>
                                                    <Link
                                                        to={{
                                                            // pathname: `/actors/${prof?.slug || 'null'}`,
                                                            pathname: `/actors/${prof?.slug || prof?.professional_id}`,
                                                            state: { id: prof?.professional_id }
                                                        }} className="text-dark">{prof.first_name} {prof.last_name}</Link>
                                                    {index === getDirector().length - 1 ? '' : <span>,&nbsp;</span>}
                                                </span>
                                            ))
                                        }
                                    </span>
                                </div>
                                <div className="mt-2">
                                    <span>Cast: </span>
                                    <span className="font-weight-bold">
                                        {getCasts().map((prof, index) => (
                                            <span>
                                                <Link
                                                    to={{
                                                        // pathname: `/actors/${prof?.slug || 'null'}`,
                                                        pathname: `/actors/${prof?.slug || prof?.professional_id}`,
                                                        state: { id: prof?.professional_id }
                                                    }} className="text-dark">{prof.first_name} {prof.last_name}</Link>
                                                {index === getCasts().length - 1 ? '' : <span>,&nbsp;</span>}
                                            </span>
                                        ))
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="ff-alt mt-3">
                        {storylineReadShowMore
                            ?
                            /* Trimmed content */
                            (movie.additional_info?.storyline && movie.additional_info?.storyline[0]?.value?.length) > 120 ?
                                <span>
                                    {movie.additional_info?.storyline && movie.additional_info?.storyline[0]?.value.slice(0, 120)}
                                    <Button color="link" className="text-dark p-0 font-weight-bold" onClick={() => {
                                        handleToggleReadMoreShow(`movie_01${index}`, true)
                                    } }>...read more</Button>
                                </span>
                                :
                                <span>
                                    {movie.additional_info?.storyline && movie.additional_info?.storyline[0]?.value}
                                    <Button color="link" className="text-dark p-0 font-weight-bold" onClick={() => {
                                        handleToggleReadMoreShow(`movie_01${index}`, true)
                                    }}></Button>
                                </span>

                            :
                            /* Full content */
                            <span>
                                {movie.additional_info?.storyline && movie.additional_info?.storyline[0]?.value}
                                <Button color="link" className="text-dark p-0 font-weight-bold" onClick={() => {
                                    handleToggleReadMoreShow(`movie_01${index}`, false)
                                }}> show less</Button>
                            </span>
                        }
                    </div>
                        </div>
                    </div>

                    <div className="text-right mt-3">
                        <Link
                            to={{
                                pathname: `${movie?.slug ? `/movies/${movie.slug}` : `/movies/${movie?.name?.toLowerCase().replace(/ /g, '-')}`}`,
                                state: { id: movie?.id }
                            }}
                            className="text-dark font-weight-bold"
                        >
                            view more
                        </Link>
                    </div>
                </div>
            </div>
            <ModalComponent
                    size="sm"
                    isScrollable={true}
                    isOpen={emailState.shareByEmailModalToggle}
                    toggleHandler={() =>
                        setEmailState(prevState=> {
                            return {
                                ...prevState,
                                shareByEmailModalToggle: !emailState.shareByEmailModalToggle,
                            }
                        })}
                    header={"Share by Email"}
                    headerClass="text-uppercase px-0"
                    body={<ShareByEmail handleOnSubmitShareByEmail={(value) => handleOnSubmitShareByEmail(value)} modalToggler={() => {
                        setEmailState(prevState=> {
                            return {
                                ...prevState,
                                shareByEmailModalToggle: !emailState.shareByEmailModalToggle,
                            }
                        })
                    }} />}
                /> 
        </div>
    )
}

export default MovieCard;