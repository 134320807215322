

export const ENABLE_MOBILE = "ENABLE_MOBILE";
export const CLICK_TOUR_START = "CLICK_TOUR_START";
export const SET_TOUR_STEP = "SET_TOUR_STEP";
export const CLICK_CHANGE_TOUR_SUBSTEPS = "CLICK_CHANGE_TOUR_SUBSTEPS";
export const TOGGLE_DETAIL_STAR_RATING = "TOGGLE_DETAIL_STAR_RATING";
export const TOGGLE_CREATE_NEW_PROJECT = "TOGGLE_CREATE_NEW_PROJECT";
export const mobile_dispatch = (data) => ({ type: ENABLE_MOBILE, payload: data });
export const isEnableMobile = (data) => {
    return (dispatch) => { dispatch(mobile_dispatch(data)) };
};

export const click_tour_start = (data) => ({ type: CLICK_TOUR_START, payload: data });
export const set_tour_step_ = (data) => ({ type: SET_TOUR_STEP, payload: data });

export const set_tour_step =(step) =>{
    return (dispatch) => { 
        dispatch(set_tour_step_(step));
     }
}

export const toggle_detail_star_rating_ = (data) => ({ type: TOGGLE_DETAIL_STAR_RATING, payload: data });
export const toggle_detail_star_rating = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(toggle_detail_star_rating_(data));
            return resolve();
        });
    }
}
export const toggle_create_new_project_ = (data) => ({ type: TOGGLE_CREATE_NEW_PROJECT, payload: data });
export const toggle_create_new_project = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(toggle_create_new_project_(data));
            return resolve();
        });
    }
}
export const click_change_tour_substeps = (data) => ({ type: CLICK_CHANGE_TOUR_SUBSTEPS, payload: data });