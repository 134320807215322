import * as actions from "../actions/contributions";
const initialState = {
    contributions_data: null,
};

const contributions = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case actions.GET_CONTRIBUTIONS:
            state.contributions_data = action.payload
            return { ...state };
        
        case actions.GET_ADDED_CONTRIBUTIONS:
            state.contributions_data = action.payload
            return { ...state };

        case actions.LOADING_CONTRIBUTIONS:
            state.loadingContributions = action.payload
            return { ...state };
        default:
            return state;
    }
}

export default contributions;