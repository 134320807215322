import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { Button, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane } from 'reactstrap';
import { clear_category, clear_subcategory, delete_special_offer, delete_tips_and_warnings, delete_wall_comment, fetch_categories_data, fetch_detailedstarrating, fetch_other_offers, fetch_sub_categories, filter_categories_data, getCaptcha, get_business_detail, get_business_location_weather, get_business_QA_dropdown, get_business_review_detail, get_business_wall_data, get_discuss_this_page, get_id_from_slug, get_media_children, get_simillar_business, get_tips_and_warnings, post_business_sign_up, post_comment_on_offers, post_discuss_this_page, post_other_offers, post_tips_and_warnings, post_wall_comments, publish_post, put_special_offer, put_wall_reply, save_ratings_vote } from '../../actions/business';
import {get_friends_profile} from '../../actions/shared';
import { get_discussions } from '../../actions/discussionsTab';
import { embedNewLink } from '../../actions/messages';
import { clear_media_dispatch, delete_mutiple_media, fetch_delete_media, fetch_media_data, fetch_taxonomy_category, fetch_taxonomy_list, media_upload_scroll, single_delete_from_upload, upload_add_captions, upload_media_files, upload_media_filesData } from "../../actions/myProfiles";
import { get_category_taxonomy_listing, update_caption_and_categories } from '../../actions/itemDetail';
import VideoPlaceholder from "../../assets/images/videopholder.jpg";

const group_icons = 'https://stagingdatawikireviews.s3.amazonaws.com/images/group-icon.png';

class UploadMedia extends Component {
    constructor(props) {
        super(props)

        this.myScrollPhotoRef = React.createRef();
        this.myScrollVideoRef = React.createRef();

        this.refGalleryVideoScroll = React.createRef();
        this.refGalleryImageScroll = React.createRef();

        this.state = {
            uploadedCat: {},
            embedBody: [],
            noCategory: "",
            stepTwoUploadedVideo: [],
            reviewDynamicItems: {},
            businessId: null,
            viewBusinessAs: 'about',
            createNewDisModalToggle: false,
            exploreMenuItemsModalToggle: false,
            activeMenuType: 'mainMenu',
            mainViewType: 'reviews', //'wall'
            viewEditReviewStep: 'writeReview',
            viewWallUpdatesType: 'all',
            setRatingFrom: 'avg_rating',
            businessDetails: null,
            simillarBusiness: null,
            tipsAndWarnings: null, //dummyTipsWarning,
            isToggleTips: false,
            isToggleWarning: false,
            tipsText: '',
            warningText: null,
            // modalText: '',
            editId: null,
            deleteItem: null,
            deleteOffer: null,
            businessRatingList: [],
            host: window.location.host,
            protocol: window.location.protocol,
            filterSelectCompose: {},
            messageText: '',
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            searchTheater: '',
            fetchPhotos: {},
            fetchPhotosCount: 0,
            fetchVideos: {},
            fetchVideosCount: 0,
            albumType: '',
            fetchTaxonomy: [],
            businessRatingIds: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            dataWantsToDelete: [],
            confirmDeleteModal: false,
            limitedPostsModal: false,
            uploadMediaModal: false,
            uploadMediaPostModal: false,
            isModalTrue: false,
            modalText: null,
            modalType: null,
            modalSelected: null,
            itemsAttachment: [],
            reviewsCategories: [],
            itemsIndex: 1,
            showVideoMedia: true, // video option toggle
            businessValues: {
                firstName: '',
                lastName: '',
                email: '',
                confirmEmail: '',
                password: '',
                confirmPassword: '',
                verificationCaptcha: ''
            },
            destinationDetails: {
                latitude: 0,
                longitude: 0,
                travelMode: 'DRIVING',
                addressTo: ''

            },
            routes: [],
            setWallDataItems: {},
            avg_rating: 0,
            enableRating: false,
            AbilitytoResolveDisputes: 0,
            TurnaroundTime: 0,
            AttentiontoDetail: 0,
            QualityofDryCleaning: 0,
            QualityofEquipment: 0,
            write_new_review: '',
            QualityofEquipmentCheck: false,
            reviewAnonymous: false,
            shocialNetworkSharing: false,
            ReviewTipsGuidelines: false,
            enableScrollForWall: true,
            setCurrentWallPage: 1,
            wallLoading: false,
            wallReplyVisibility: {},
            wallReplyText: {},
            deleteComment: null,
            wallCommentEdit: false,
            dicussionThisText: '',
            dicussionThisBody: '',
            profileInfo: null,
            isEditOffer: false,
            vidoeCaptionCategory: '',
            vidoeCaption: '',
            isadhere: true,
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            isImageType: false,
            captionData: "",
            uploadedId: {},
            cattaxListing: {},
            albumCategoryId: "",
            friendsProfileRecommend: null,
            friendsProfileFavourite: null,
            friendsProfileWant: null,
            chartStarRatings: null,
            dropdownSortBy: false,
            mediaUrl: "",
			mediaType:""
        }
    }

    handleCloseAndFetchReview = () => {
        let { mediaSetReview } = this.state;
        this.setState({ uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: true, mediaSet: mediaSetReview, taxonomycategoryId: "", fetchTaxonomyListingImages: {}, fetchTaxonomyListingVideos: {}, categoryType: "", uploadedId: {} }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);
        });
    }

    handleCloseAndFetch = () => {
        let { latestUploadedData, fileReviewStatus, mediaSetReview } = this.state;
		
        if (fileReviewStatus && Object.values(latestUploadedData) && Object.values(latestUploadedData)[0] && Object.values(latestUploadedData)[0].id) {
            Object.values(latestUploadedData).map((list) => {
                mediaSetReview.push({
                    "id": list.id,
                    "file": list.url,
                    "thumbnail": list.thumbnail,
                    "url": list.url,
                })
            })

        }
        this.setState({
            uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: false, mediaSetReview, taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {}, captionData: "", uploadedId: {}
        }, () => {
			this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);
        });
    }

    componentWillReceiveProps = (nextProps) => {
        let { fetchPhotos,
            fetchPhotosCount,
            fetchVideos,
            fetchVideosCount,
            fetchGalleryScroll,
            fetchGalleryCount,
            fetchDeletedData,
            fetchDeleteCount,
            latestUploadedData
        } = this.state;

        if (nextProps?.cat_taxonomy_list &&
            Array.isArray(nextProps?.cat_taxonomy_list) &&
            nextProps?.cat_taxonomy_list?.length && nextProps.typeUpload) {
            let cattaxListing = [];
            nextProps.cat_taxonomy_list.forEach(item => {
                if (item.category === nextProps.typeUpload) {
                    cattaxListing = cattaxListing.concat(item.children);
                }
            })
            this.setState({ cattaxListing });
        }

        if (nextProps.fetch_photos) {
            let item = nextProps.fetch_photos;
            if (item.album) { fetchPhotos = [] }
            fetchPhotos[item.page] = item;
            fetchPhotosCount = item.count;
            this.setState({ fetchPhotos, fetchPhotosCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_videos) {
            let item = nextProps.fetch_videos;
            if (item.album) { fetchVideos = [] }
            fetchVideos[item.page] = item;
            fetchVideosCount = item.count;
            this.setState({ fetchVideos, fetchVideosCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_taxonomy) {
            let taxCat = nextProps.fetch_taxonomy;
            if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
                taxCat = taxCat.filter(dt => dt.label !== null);
            }
            this.setState({ fetchTaxonomy: taxCat })
        }

        if (nextProps.fetch_deleted_data) {
            let item = nextProps.fetch_deleted_data;
            if (!item.type) { fetchDeletedData = {} }
            fetchDeletedData[item.page] = item;
            fetchDeleteCount = item.count;
            this.setState({ fetchDeleteCount, fetchDeletedData, activeMyPhotoIndex: 0 });
        }

        if (nextProps.upload_scroll) {
            let item = nextProps.upload_scroll;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({ fetchGalleryScroll, fetchGalleryCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.my_profile_info && Object.keys(nextProps.my_profile_info).length > 0) {
            let data = nextProps.my_profile_info;
            if (data?.user) {
                data['name_full'] = `${data?.user?.first_name} ${data?.user?.last_name}`
            }
            this.setState({
                myProfile: data,
            });
        }

        // if (nextProps.upload_media_data) {
        //     let data = nextProps.upload_media_data;
        //     if (data.index !== undefined) {
        //         let idx = data.index;
        //         latestUploadedData[idx] = data;
        //         this.setState({ latestUploadedData });
        //     }
        // }
		if (nextProps.upload_media_data) {
            let data = nextProps.upload_media_data;
			if (data.index !== undefined) {
                let idx = data.index;
                latestUploadedData[idx] = data;
                this.setState({ latestUploadedData }, () => {
                    let media = { ...this.state.latestUploadedData };
                    this.props.getUploadedMedia({ mediaSet: Object.values(media) });
					//let media = this.state.latestUploadedData;
                    //this.props.getUploadedMedia({ mediaSet: Object.values(media) });
                });
            }
        }

        /*if (nextProps.filter_data_category) {
            if (nextProps.typeUpload && nextProps.typeUpload == "Products") {
				if (nextProps.fetch_taxonomy_listData) {
					  let taxCatListing = nextProps.fetch_taxonomy_listData;
					  if (
						taxCatListing &&
						Array.isArray(taxCatListing) &&
						taxCatListing.length > 0
					  ) {
						if (taxCatListing[0].isTypes == "images") {
						  this.setState({
							cattaxListing: taxCatListing[0].children
							  ? taxCatListing[0].children
							  : [],
						  });
						} else {
						  this.setState({
							cattaxListing: taxCatListing[0].children
							  ? taxCatListing[0].children
							  : [],
						  });
						}
					  }
					}
			}else{
				let taxCatList = nextProps.filter_data_category;
				if (taxCatList && Object.keys(taxCatList).length > 0) {
					this.setState({ cattaxListing: taxCatList[0] ? taxCatList[0].children : [] })
				}
			}
        }*/

        if (nextProps.uploadedUrl) {
			this.setState({ mediaUrl: nextProps.uploadedUrl })
        }
		 if (nextProps.typeUpload) {
			this.setState({ mediaType:nextProps.typeUpload.toLowerCase() })
        }

        if (nextProps.noCategory && nextProps.noCategory.getCategoryDiscus) {
            //this.setState({ noCategory: nextProps.noCategory.getCategoryDiscus })
        }
		if (nextProps.discusstype) {
			
			this.setState({ noCategory:nextProps.discusstype })
        }
    }

    setMediaSetForItems = (e, item) => {
        // e.preventDefault();
        let { mediaSet, offerCheck } = this.state;
        if (item && item.id) {
            let id = item.id;
            let itemFiltered = mediaSet.find(item => item.id === id);

            if (itemFiltered) {
                const index = mediaSet.indexOf(itemFiltered);
                if (index > -1) {
                    mediaSet.splice(index, 1);
                }
            } else {
                mediaSet.push(item)
            }

            this.setState({ mediaSet, mediaSetReview: mediaSet, offerCheck }, () => {
                this.props.getUploadedMedia({ mediaSet })
            });
        }
    }

    handleCaptionByIndex = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData, uploadedCat } = this.state;
        uploadCaptions[index] = value;

        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = uploadedCat[index] || '';
        // data.category = taxonomycategoryId;
        let id = upload_data.id;

        this.setState({ uploadCaptions, captionData: caption_data }, () => {
            this.props.update_caption_and_categories({ id, data });
            //  this.props.upload_add_captions(id, data)
        });
    }

    checkIsAdded = (item) => {
        let { mediaSet } = this.state;
        let id = item.id;
        let itemFiltered = mediaSet.find(item => item.id === id);
        if (itemFiltered) {
            return true;
        } else {
            return false;
        }
    }

    removeFromMedia = (e, item) => {
        e.preventDefault();
        let { mediaSet } = this.state;
        let id = item.id;
        let itemFiltered = mediaSet.find(item => item.id === id);
        if (itemFiltered) {
            const index = mediaSet.indexOf(itemFiltered);
            if (index > -1) {
                mediaSet.splice(index, 1);
            }
            // call delete api here 
            // delete_mutiple_media
            this.setState({ mediaSet, mediaSetReview: mediaSet }, () => {
                this.props.single_delete_from_upload(id);
            });
        }

    }

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = this.state;
        let getDataItems = [];
        const imagePreview = <img src={VideoPlaceholder} alt=""/>;

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });
			
            if (getDataItems && getDataItems.length > 0 && getDataItems[0]) {
                return getDataItems.map(item => {
                    let itemName = (item?.thumbnail).split('.');
                    let itemFile = (item?.file).split('.');
                    let extName = itemName[itemName.length - 1];
                    let extFile = itemFile[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview
                    item['srcFile'] = isVideo ? srcFile : item?.file;
                    return (
                        <Col xs="auto" className="mb-3" >
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={(e) => { this.setMediaSetForItems(e, item) }} checked={this.checkIsAdded(item)} />
                                    <div className="gallery-media" >
                                    <img
                                        className="img-fluid"
                                        src={isVideo ? srcFile : item?.file}
                                        alt={item?.filename ? item.filename : "file"}
                                        onError={(error) => (error.target.src = require("../../assets/images/videopholder.jpg"))}
                                        />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    checkIfVideo = (item) => {
        const imagePreview = <img src={VideoPlaceholder} alt=""/>;

        let itemFile = (item && item.file).split('.');
        // let extName = itemName !== "" ? itemName[itemName.length - 1] : "";
        let extFile = itemFile[itemFile.length - 1];
        let srcFile = imagePreview;

        if (['jpg', 'jpeg', 'png', 'gif'].includes(extFile)) {
            srcFile = item.file;
        }
        return srcFile;
    }

    handleOnFileUploadChange = (e) => {
        let { selectedFilesPreview, files, mediaUrl,mediaType } = this.state;
        const filesSetForUpload = Array.from(e.target.files);
        files = files.concat(filesSetForUpload);
        let self = this;
        this.setState({ files }, () => {
            Promise.all(files.map(file => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            })).then(images => {
                let concatWithPrev = selectedFilesPreview.concat(images);
                self.setState({ selectedFilesPreview: concatWithPrev })
            }, error => {
                console.error(error);
            });
        });
        if (files && Array.isArray(files) && files.length > 0) {
            let indexSelected = selectedFilesPreview.length - 1;
            files.forEach(async (item, index) => {
                if (index > indexSelected) {
                    let formData = new FormData();
                    formData.append('file', item);

                    let name = item.name;
                    let split_items = name.split('.');
                    let item_ext = split_items[split_items.length - 1];
                    let isImageTrue = ['jpg', 'png', 'jpeg', 'gif', 'tiff'].includes(item_ext);
					let url = `/upload/multiuploader/?album=reviews&instance=review_1&image=undefined&doc=undefined`;
                    if (mediaUrl && mediaUrl != "") {
                        url = mediaUrl
                    }
                    this.setState({ isImageType: isImageTrue })
                    // await this.props.filter_categories_data({ category: 'movies', sub_cat: "", level: 1 }); //fetch taxonomy list
                    // await this.props.get_category_taxonomy_listing();
                    if (isImageTrue) {
						
                        //await this.props.upload_media_files('image', formData, index);
                        await this.props.upload_media_filesData(url, 'image', formData, index);
						if(mediaType && mediaType != "" && mediaType != "dentists"){
						 //await this.props.fetch_taxonomy_list('Products', 'images');
						 await this.props.get_category_taxonomy_listing(mediaType,'images');
						}
                    } else {
                        //await this.props.upload_media_files('video', formData, index);
                        await this.props.upload_media_filesData(url, 'video', formData, index);
						if(mediaType && mediaType != "" && mediaType != "dentists"){
						await this.props.get_category_taxonomy_listing(mediaType,'videos');	
						 //await this.props.fetch_taxonomy_list('Products', 'videos');
						}
                    }
					
                }
            });
        }
    }

    handleChangeTaxonomy = (e) => {
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ fetchGalleryScroll: {}, fetchGalleryAlbum: value, fetchGalleryType: 'image' }, () => this.props.media_upload_scroll({ type: 'image', album: albumType, page: 1, force_update: true }))
    }

    handleChangeCategory = (e, index) => {
        let { latestUploadedData, uploadedId, uploadedCat, uploadCaptions } = this.state;
        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        // let isImageType = upload_data.media_type;
        let { value } = e.target;
        uploadedId[index] = id;
        uploadedCat[index] = value;
        let albumType = value !== 'all' ? value : '';
        this.setState({ albumCategoryId: albumType, uploadedId, uploadedCat });
        // let categoryId = "business";
        // let subcategoryId = value;

        this.props.update_caption_and_categories({ id, data: { "caption": uploadCaptions[index] || "", "category": value } });
        // if (isImageType == "image") {
        //     this.props.fetch_categories_data(categoryId, subcategoryId, 'image', index);
        // } else {
        //     this.props.fetch_categories_data(categoryId, subcategoryId, 'video', index);
        // }
    }

    handleChangeTaxonomyData = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData } = this.state
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = value;
        let id = upload_data.id;
        this.setState({ taxonomycategoryId: value, captionData: caption_data }, () => this.props.upload_add_captions(id, data))

    }

    handleChangeVideoTaxonomy = async (e) => {
        let { name, value } = e.target;
        await this.setState({
            [name]: value,
        });
    }

    clearMediaDispatch = () => {
        this.setState({
            latestUploadedData: {}, uploadCaptions: {}, selectedFilesPreview: [], files: [], taxonomycategoryId: "",
            fetchTaxonomyListingImages: {}, fetchTaxonomyListingVideos: {}, captionData: "", uploadedId: {}, cattaxListing: {}, albumCategoryId: ""
        }, () => {
            this.props.clear_media_dispatch(); this.props.clear_category();
            this.props.clear_subcategory();
        });
    }

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    handleScrollGalleryVideo = () => {
        let { refGalleryVideoScroll } = this;
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    handleScrollGalleryImage = () => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    AddNewEmbedLink = (e) => {
        e.preventDefault();
        let { embeddedLink, embedBody } = this.state;
        // this.props.embedNewLink({
        //     data: {
        //         "video": `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
        //         "exclude_list": [],
        //         "body_preview": `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
        //         "question_rating_category": [],
        //         "messagemedia_set": []
        //     }
        // });
        embedBody.push(embeddedLink);
        this.props.getUploadedMedia({ links: embedBody });
        this.setState({ embeddedLink: '', embedBody, uploadMediaModal: false, createNewDisModalToggle: false })
    }

    checkImages = (item) => {
        let items = (item.tos).split(',');
        if (items.length > 1) {
            return group_icons;
        } else {
            return item.current_profile_pic;
        }
    }

    handleChangeRatings = (item) => {
        this.setState({ setRatingFrom: item })

    }

    componentDidMount() {
		this.handleCloseAndFetchReview();
        this.props.fetch_taxonomy_category('movies');
        //this.props.get_category_taxonomy_listing();
        this.props.clear_category();
        this.props.clear_subcategory();
        this.props.getUploadedMedia({ mediaSet: [] });
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutWallReplyText);
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        let { setWallDataItems, enableScrollForWall, setCurrentWallPage, businessId } = this.state;
        let { get_business_wall_data } = this.props;

        if (Object.keys(setWallDataItems).length) {
            let itemEnd = document.getElementById("item-last");

            if (itemEnd) {
                var elem = itemEnd;
                var distanceScrolled = document.body.scrollTop;
                var elemRect = elem.getBoundingClientRect();
                var elemViewportOffset = elemRect.top;
                var totalOffset = distanceScrolled + elemViewportOffset - 250;

                if (totalOffset < 0 && enableScrollForWall) {
                    this.setState({ enableScrollForWall: false, setCurrentWallPage: setCurrentWallPage + 1, wallLoading: true }, () => {
                        get_business_wall_data({ id: businessId, filter_type: 'all', page: this.state.setCurrentWallPage });
                    });
                }
            }
        }

        let enableSticky = document.getElementById('enable-sticky');
        let distanceToTop = enableSticky.getBoundingClientRect().top;
        if (distanceToTop < 0) {
            if (this.state.enableStickyHeader !== true) {
                this.setState({ enableStickyHeader: true });
            }
        } else {
            if (this.state.enableStickyHeader !== false) {
                this.setState({ enableStickyHeader: false });
            }
        }
    }

    clearReveiwRatingState = async () => {
        let { chartStarRatings, businessRatingList } = this.state;
        await this.setState({
            enableRating: false,
            avg_rating: 0,
            businessRatingList: this.state.businessRatingListDefaut,
            write_new_review: "",
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            searchTheater: '',
            fetchPhotos: {},
            fetchPhotosCount: 0,
            fetchVideos: {},
            fetchVideosCount: 0,
            albumType: '',
            fetchTaxonomy: [],
            businessRatingIds: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            categoryType: "",
            captionData: "",
            uploadedId: {}
        })
        let itemEditSet = [];
        if (chartStarRatings !== null && Object.keys(chartStarRatings).length && itemEditSet.length == 0) {
            businessRatingList.forEach((item) => {
                businessRatingList[businessRatingList.findIndex(itemss => itemss.key == item.key)].value = 0
            });
            this.setState({
                businessRatingList
            })
        }
    }

    removeSelectedImage = (e, index) => {
        let { latestUploadedData, selectedFilesPreview, files, uploadedId, fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state

        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        this.props.single_delete_from_upload(id);

        // delete items from array
        selectedFilesPreview.splice(index, 1);
        files.splice(index, 1); // deleted this because it concat from the upload function.

        // deleted items from json
        delete latestUploadedData[index];
        delete uploadedId[index];
        delete fetchTaxonomyListingImages[index];
        delete fetchTaxonomyListingVideos[index];

        // deleted items from json
        if (latestUploadedData[index] == undefined) {
            let newItems = [];

            // turn json to array for indexes
            Object.values(latestUploadedData).forEach(it => { newItems.push(it) });

            // turn array to json for indexes
            if (newItems && Array.isArray(newItems) && newItems.length) {
                let newItemsJson = {};
                newItems.forEach((nitem, index) => {
                    newItemsJson[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJson).length) {
                    latestUploadedData = newItemsJson;
                }
            }
        }

        if (uploadedId[index] == undefined) {
            let newItemsf = [];

            // turn json to array for indexes
            Object.values(uploadedId).forEach(it => { newItemsf.push(it) });

            // turn array to json for indexes
            if (newItemsf && Array.isArray(newItemsf) && newItemsf.length) {
                let newItemsJsond = {};
                newItemsf.forEach((nitem, index) => {
                    newItemsJsond[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsond).length) {
                    uploadedId = newItemsJsond;
                }
            }
        }

        if (fetchTaxonomyListingImages[index] == undefined) {
            let newItemsImages = [];

            // turn json to array for indexes
            Object.values(fetchTaxonomyListingImages).forEach(it => { newItemsImages.push(it) });

            // turn array to json for indexes
            if (newItemsImages && Array.isArray(newItemsImages) && newItemsImages.length) {
                let newItemsJsonImages = {};
                newItemsImages.forEach((nitem, index) => {
                    newItemsJsonImages[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsonImages).length) {
                    fetchTaxonomyListingImages = newItemsJsonImages;
                }
            }
        }

        if (fetchTaxonomyListingVideos[index] == undefined) {
            let newItemsVideos = [];

            // turn json to array for indexes
            Object.values(fetchTaxonomyListingVideos).forEach(it => { newItemsVideos.push(it) });

            // turn array to json for indexes
            if (newItemsVideos && Array.isArray(newItemsVideos) && newItemsVideos.length) {
                let newItemsJsonVideo = {};
                newItemsVideos.forEach((nitem, index) => {
                    newItemsJsonVideo[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsonVideo).length) {
                    fetchTaxonomyListingVideos = newItemsJsonVideo;
                }
            }
        }

        this.setState({ selectedFilesPreview, latestUploadedData, files, uploadedId, fetchTaxonomyListingVideos, fetchTaxonomyListingImages });
    }

    renderPhotosAndVideos = (iteamImage, isTrue) => {
        if (iteamImage?.url) {
            let items = iteamImage?.url.split('.');
            let lastItem = items[items.length - 1];
            if (['webm', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov'].includes(lastItem)) {
                return (
                    <video
                        autoPlay
                        muted
                        className={isTrue ? "img-fluid img-thumbnail" : "img-thumbnail uploaded-img-video-thumbnail"}
                        controls>
                        <source src={iteamImage?.url} type={`video/${lastItem}`}></source>
                    </video>
                )
            } else {
                return (
                    <img
                        className={isTrue ? "img-fluid img-thumbnail" : "img-thumbnail uploaded-img-video-thumbnail"}
                        src={iteamImage.url}
                        alt=""
                    />)
            }
        }
    }

    render() {
        let {
            selectedFilesPreview,
            latestUploadedData,
            fetchTaxonomy,
            embeddedLink,
            showVideoMedia,
            businessRatingList,
            chartStarRatings,
            fetchTaxonomyListingImages,
            fetchTaxonomyListingVideos,
            uploadedId,
            cattaxListing,
            uploadMediaModal,
            selectedUpMediaType
        } = this.state;

        let itemEditSet = [];
        if (chartStarRatings !== null && Object.keys(chartStarRatings).length && itemEditSet.length == 0) {
            Object.keys(chartStarRatings).forEach((item, index) => {
                let textItem = item.substring(0, 4);
                let itemsSet = businessRatingList.find(it => (it.key).substring(0, 4).match(textItem));
                if (itemsSet !== undefined) {
                    itemEditSet[index] = itemsSet;
                    itemEditSet[index]['name'] = item;
                }
            });
        }

        let totalLength = selectedFilesPreview.length;
        let objectKeys = [];
        let objectLength = 0;
        let calculatedPercentage = 0;
        if (Object.keys(latestUploadedData).length > 0) {
            objectKeys = Object.keys(latestUploadedData);
            objectLength = objectKeys.length;
            calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
        };

        return (
            <Modal
                isOpen={uploadMediaModal}
                toggle={() => {
                    if(this.props.toggleEnableFileUpload){
                        this.props.toggleEnableFileUpload()
						this.setState({ uploadMediaModal: !uploadMediaModal }, () => { this.clearMediaDispatch() })}}
                    }
                    >
                <ModalHeader toggle={() => { 
				    if(this.props.toggleEnableFileUpload){
                        this.props.toggleEnableFileUpload()
					}
                    this.handleCloseAndFetch() }}>UPLOAD MEDIA</ModalHeader>
                <ModalBody className="p-3">
                    <Row>
                        <Col xs={"auto"}>
                            <div className="bg-light h-100 p-3">
                                <Nav tabs className="flex-column active-transparent border-0 m-0">
                                    <NavItem className="text-left">
                                        <NavLink
                                            className="pl-0 pr-4 border-0"
                                            href="#"
                                            active={selectedUpMediaType === "upload"}
                                            onClick={() => { this.setState({ selectedUpMediaType: "upload" }) }}>
                                            {'Upload'}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <hr className="my-3 bg-tertiary" />
                                    </NavItem>
                                    <NavItem className="text-left border-0">
                                        <NavLink
                                            className="pl-0 pr-4 border-0"
                                            href="#"
                                            active={selectedUpMediaType === "gallery"}
                                            onClick={() => {
                                                this.setState({ selectedUpMediaType: "gallery" }, () => {
                                                    this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true })
                                                })
                                            }}>
                                            {'Gallery'}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <hr className="my-3 bg-tertiary" />
                                    </NavItem>
                                    <NavItem className="text-left border-0" hidden={this.props.hideEmbed}>
                                        <NavLink
                                            className="pl-0 pr-4 border-0"
                                            href="#"
                                            active={selectedUpMediaType === "embed"}
                                            onClick={() => { this.setState({ selectedUpMediaType: "embed" }) }}>
                                            {'Embed'}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </Col>
                        <Col>
                            <TabContent activeTab={selectedUpMediaType}>
                                <TabPane tabId="upload">
                                    {selectedFilesPreview && selectedFilesPreview.length === 0 ?
                                        <div className="mb-3 type-file-block bg-light">
                                            <Input
                                                type="file"
                                                name="upload_media_file"
                                                id="uploadFilesFromSystem"
                                                accept="image/*, video/*"
                                                onChange={this.handleOnFileUploadChange}
                                                multiple
                                            />
                                            <Label for="uploadFilesFromSystem">
                                                <div className="type-bg"></div>
                                            </Label>
                                        </div>
                                        : null}
                                    <div
                                        className="text-right"
                                        style={{
                                            maxWidth: "120px",
                                            margin: "0 1rem 1rem auto",
                                        }}>
                                        <div className="text-center mb-1 small">
                                            {parseInt(calculatedPercentage) === 100 ? (
                                                <div className="text-success">
                                                    <FontAwesomeIcon
                                                        icon="check-circle"
                                                        className="mr-1"
                                                    />{" "}
                                                    {'Uploaded'}
                                                </div>
                                            ) : (
                                                    selectedFilesPreview.length > 0 && (
                                                        <div>

                                                            {` Uploading `}
                                                            <span className="text-success font-weight-bold ff-base">
                                                                {calculatedPercentage ? `${parseInt(calculatedPercentage)} %` : ''}
                                                            </span>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                        {selectedFilesPreview.length > 0 && (
                                            <Progress
                                                value={parseInt(calculatedPercentage)}
                                                style={{ height: "8px" }}
                                            />
                                        )}
                                    </div>

                                    <Row>
                                        {selectedFilesPreview && selectedFilesPreview.length > 0 ?
                                            selectedFilesPreview.map((item, index) => (
                                                <Col className="mb-3" xs="auto" key={index}>
                                                    <div className="text-center bg-light p-2">
                                                        <div className="d-inline-flex pr-3">
                                                            <div>
                                                                <div
                                                                    className="selectable-media"
                                                                    style={{ cursor: "default" }}
                                                                >
                                                                    <div className="gallery-media">
                                                                        {!item.includes('video') && (
                                                                            <img src={item} alt={`selected-${index}`} />
                                                                        )}
                                                                        {item.includes('video') && (
                                                                            <video width="100" height="100">
                                                                                <source src={item} type="video/mp4" />
                                                                            Your browser does not support the video tag.
                                                                            </video>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mx-n3 mt-2">
                                                                <Button
                                                                    color="delete-media _alt"
                                                                    size="sm"
                                                                    title="Remove Media"
                                                                    onClick={(e) => this.removeSelectedImage(e, index, item)}>
                                                                    <FontAwesomeIcon icon="trash-alt" />{" "}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {latestUploadedData &&
                                                            latestUploadedData[index] &&
                                                            (latestUploadedData[index]['isUpload'] !== undefined || latestUploadedData[index]['isUpload'] !== null) ?
                                                            latestUploadedData[index]['isUpload'] ? (
                                                                <div className="mt-2">
                                                                    <InputGroup>
                                                                        <Input
                                                                            bsSize="sm"
                                                                            type="text"
                                                                            name="caption"
                                                                            className="caption-input"
                                                                            placeholder="Caption"
                                                                            onBlur={(e) => this.handleCaptionByIndex(e, index)}
                                                                            style={{ maxWidth: '160px' }} />
                                                                    </InputGroup>
																	{this.state.mediaType != "dentists" ?
                                                                    <Input
                                                                        hidden={this.state.noCategory != "dicussionTab" ? false : true}
                                                                        onChange={(e) => this.handleChangeCategory(e, index)}
                                                                        bsSize="sm"
                                                                        type="select"
                                                                        name="album_type_category"
                                                                        id="selectCategoryTypeData"
                                                                        style={{ maxWidth: '160px' }}>
                                                                        <option value="">Choose Category</option>
                                                                        {cattaxListing &&
                                                                            Array.isArray(cattaxListing) &&
                                                                            cattaxListing.length > 0 ?
                                                                            cattaxListing.map((data) => (<option value={data.id}>{data.category}</option>))
                                                                            : null}
                                                                    </Input>
																	: "" }

                                                                    {uploadedId[index] == latestUploadedData[index].id &&
                                                                        (Object.keys(fetchTaxonomyListingImages).length > 0 || Object.keys(fetchTaxonomyListingVideos).length > 0) ?
                                                                        <Input
                                                                            onChange={(e) => this.handleChangeTaxonomyData(e, index)}
                                                                            bsSize="sm"
                                                                            type="select"
                                                                            name="album_subcategory_select"
                                                                            id="selectAlbumTypeData"
                                                                            style={{ maxWidth: '160px' }}>
                                                                            <option value="">Choose Subcategory</option>
                                                                            {latestUploadedData[index]['media_type'] === "image" &&
                                                                                fetchTaxonomyListingImages &&
                                                                                Object.keys(fetchTaxonomyListingImages).length > 0 &&
                                                                                fetchTaxonomyListingImages[index] ?
                                                                                fetchTaxonomyListingImages[index].map(item => (
                                                                                    <option value={item.id}>{item.label}</option>
                                                                                ))
                                                                                : null}
                                                                            {latestUploadedData[index]['media_type'] === "video" &&
                                                                                fetchTaxonomyListingVideos && Object.keys(fetchTaxonomyListingVideos).length > 0 &&
                                                                                fetchTaxonomyListingVideos.length > 0 &&
                                                                                fetchTaxonomyListingVideos[index] ?
                                                                                fetchTaxonomyListingVideos[index].map(item => (
                                                                                    <option value={item.id}>{item.label}</option>
                                                                                ))
                                                                                : null}
                                                                        </Input>
                                                                        : null}

                                                                </div>
                                                            ) : (
                                                                    <div className="mt-2 text-center">
                                                                        <small className="text-danger">Error while uploading</small>
                                                                    </div>
                                                                ) : (
                                                                <div className="mt-2 text-center">
                                                                    <small className="text-info">Uploading...</small>
                                                                </div>
                                                            )}

                                                    </div>
                                                </Col>
                                            ))
                                            : null}
                                    </Row>
                                    <div className="d-flex mx-n2 justify-content-center">
                                       <div className="px-2">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mw"
                                                onClick={() => {
                                                    if (this.props.callbackFunction) {
                                                        this.props.callbackFunction()
                                                    }
													if(this.props.toggleEnableFileUpload){
														this.props.toggleEnableFileUpload()
                                                    }
                                                    this.handleCloseAndFetch()
                                                }}>
                                                {'OK'}
                                            </Button>
                                        </div>
										<div className="px-2">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mw"
                                                onClick={() => {
                                                    if(this.props.toggleEnableFileUpload){
														this.props.toggleEnableFileUpload()
                                                    } 
                                                    this.handleCloseAndFetch() 
                                                }}>
                                                {'Cancel'}
                                            </Button>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="gallery">
                                    <div className="mb-2">
                                        <Nav tabs className="d-inline-flex mb-0">
                                            <NavItem>
                                                <NavLink href="#"
                                                    active={this.state.showGalleryType === "images"}
                                                    onClick={() => {
                                                        this.setState({ showGalleryType: "images", fetchGalleryType: 'image', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                            this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true })
                                                        })
                                                    }}>
                                                    {'Image Gallery'}
                                                </NavLink>
                                            </NavItem>
                                            {showVideoMedia === true && (
                                                <NavItem>
                                                    <NavLink href="#"
                                                        active={this.state.showGalleryType === "videos"}
                                                        onClick={() => {
                                                            this.setState({ showGalleryType: "videos", fetchGalleryType: 'video', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                                this.props.media_upload_scroll({ type: 'video', album: '', page: 1, force_update: true })
                                                            })
                                                        }}>
                                                        {'Video Gallery'}
                                                    </NavLink>
                                                </NavItem>
                                            )}
                                        </Nav>
                                    </div>
                                    <TabContent activeTab={this.state.showGalleryType}>
                                        <TabPane tabId="images">
                                            <div>
                                                <FormGroup className="form-row justify-content-end">
                                                    <Col xs="auto">
                                                        <Label className="small" for="selectAlbumType">Album Type:</Label>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Input onChange={this.handleChangeTaxonomy} bsSize="sm" type="select" name="album_type_select" id="selectAlbumType" defaultValue="all">
                                                            <option value="all">All</option>
                                                            {fetchTaxonomy && Array.isArray(fetchTaxonomy) && fetchTaxonomy.length > 0 ?
                                                                fetchTaxonomy.map((item, index_tax) => (
                                                                    <option key={index_tax} value={item.category}>{item.label}</option>
                                                                ))
                                                                : null}
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                            <div
                                                ref={this.refGalleryImageScroll}
                                                onScroll={this.handleScrollGalleryImage}
                                                className="px-3 my-3"
                                                style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                <Row>
                                                    {this.fetchScrollPhotoMedia()}
                                                </Row>
                                            </div>
                                            <div className="d-flex mx-n2">
                                                <div className="px-2">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={() => {
                                                            if(this.props.toggleEnableFileUpload){
                                                                this.props.toggleEnableFileUpload()
                                                            }
                                                            this.setState({ uploadMediaModal: !this.state.uploadMediaModal })
                                                        }}>
                                                        {'Cancel'}
                                                    </Button>
                                                </div>
                                                <div className="px-2 ml-auto">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={() => {
                                                            if (this.props.callbackFunction) {
                                                                this.props.callbackFunction()
                                                            }
															if(this.props.toggleEnableFileUpload){
															this.props.toggleEnableFileUpload()
															}
                                                            this.setState({ uploadMediaModal: !this.state.uploadMediaModal })
                                                        }}>
                                                        {'OK'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="videos">
                                            <div
                                                ref={this.refGalleryVideoScroll}
                                                onScroll={this.handleScrollGalleryVideo}
                                                className="px-3 my-3"
                                                style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                <Row>
                                                    {this.fetchScrollPhotoMedia(true)}
                                                </Row>
                                            </div>
                                            <div className="d-flex mx-n2">
                                                <div className="px-2">
                                                    <Button color="primary" size="sm" className="mw" onClick={() => {
                                                        if(this.props.toggleEnableFileUpload){
                                                            this.props.toggleEnableFileUpload()
                                                        }
                                                        this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}}>Cancel</Button>
                                                </div>
                                                <div className="px-2 ml-auto">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={() => {
                                                            if (this.props.callbackFunction) {
                                                                this.props.callbackFunction()
                                                            }
															if(this.props.toggleEnableFileUpload){
															this.props.toggleEnableFileUpload()
															}
                                                            this.setState({ uploadMediaModal: !this.state.uploadMediaModal })
                                                        }}>
                                                        {'OK'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </TabPane>
                                <TabPane tabId="embed">
                                    <div className="text-muted mb-2">
                                        {'Submit the link for the image or video you want to embed'}{" "}
                                    </div>
                                    <div className="mb-2">
                                        {/* Repeat this while adding */}
                                        <div className="d-flex mx-n2 mb-2" key={0}>
                                            <div className="flex-fill px-2">
                                                <Input
                                                    type="url"
                                                    bsSize="sm"
                                                    className="bg-white"
                                                    value={embeddedLink}
                                                    onChange={(e) => { this.setState({ embeddedLink: e.target.value }) }}
                                                    placeholder="Embeded link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mx-n2">
                                        <div className="px-2">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mw"
                                                onClick={() => { this.setState({ embeddedLink: '', uploadMediaModal: false }) }} >
                                                {'Cancel'}
                                            </Button>
                                        </div>
                                        <div className="px-2 ml-auto">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mw"
                                                onClick={(e) => { this.AddNewEmbedLink(e) }}>
                                                {'Insert'}
                                            </Button>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}

const mapState = (state) => {
    return {
        facebook: state.connections.enable_facebook,
        twitter: state.connections.enable_tiwitter,
        loggedInUsername: state.user?.my_profile?.user?.username,
        facebook_connection: state.user?.my_profile?.facebook_connection,
        twitter_connection: state.user?.my_profile?.twitter_connection,
        business_details: state.business.business_details,
        business_weather: state.business.business_weather,
        simillar_business: state.business.simillar_business,
        chart_star_ratings: state.business.chart_star_ratings,
        tips_and_warnings: state.business.tips_and_warnings,
        other_offers: state.business.other_offers,
        fetch_photos: state.myProfile.fetch_photos,
        fetch_videos: state.myProfile.fetch_videos,
        fetch_taxonomy: state.myProfile.fetch_taxonomy,
        upload_scroll: state.myProfile.upload_scroll,
        upload_media_data: state.myProfile.upload_media_data,
        fetch_deleted_data: state.myProfile.fetch_deleted_data,
        captcha_items: state.business.captcha_items,
        wall_data: state.business.wall_data,
        my_profile_info: state.user.my_profile,
        business_rating: state.business.business_rating,
        discuss_this_page: state.business.discuss_this_page,
        back_to_listing: state.business.back_to_listing,
        videos_children: state.business.videos_children && state.business.videos_children[0],
        qa_dropdown: state.business.qa_dropdown,
        fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
        new_sub_categories: state.business.new_sub_categories,
        filter_data_category: state.business.filter_data_category,
        fetch_data_categories: state.business.fetch_data_categories,
        friends_profile_recommend: state.business.friends_profile_recommend,
        friends_profile_want: state.business.friends_profile_want,
        friends_profile_favourite: state.business.friends_profile_favourite,
        cat_taxonomy_list: state.item_detail.cat_taxonomy_list
    }
}

const mapProps = (dispatch) => ({
    get_business_detail: (id) => dispatch(get_business_detail(id)),
    get_simillar_business: ({ cat, id, name }) => dispatch(get_simillar_business({ cat, id, name })),
    get_business_location_weather: (data) => dispatch(get_business_location_weather(data)),
    post_tips_and_warnings: (data) => dispatch(post_tips_and_warnings(data)),
    delete_tips_and_warnings: ({ deleteId, id }) => dispatch(delete_tips_and_warnings({ deleteId, id })),
    fetch_other_offers: ({ listing_id, offer_type }) => dispatch(fetch_other_offers({ listing_id, offer_type })),
    post_other_offers: ({ data, listing_id, offer_type }) => dispatch(post_other_offers({ data, listing_id, offer_type })),
    get_discuss_this_page: ({ id, pageId }) => dispatch(get_discuss_this_page({ id, pageId })),
    post_comment_on_offers: (data) => dispatch(post_comment_on_offers(data)),
    fetch_media_data: (type, album, page, user_profile) => dispatch(fetch_media_data(type, album, page, user_profile)),
    fetch_taxonomy_category: (type) => dispatch(fetch_taxonomy_category(type)),
    media_upload_scroll: ({ type, album, page, force_update }) => dispatch(media_upload_scroll({ type, album, page, force_update })),
    fetch_delete_media: ({ type, page }) => dispatch(fetch_delete_media({ type, page })),
    upload_media_files: (type, data, index) => dispatch(upload_media_files(type, data, index)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    clear_media_dispatch: () => dispatch(clear_media_dispatch()),
    delete_mutiple_media: ({ type, data }) => dispatch(delete_mutiple_media({ type, data })),
    single_delete_from_upload: (id) => dispatch(single_delete_from_upload(id)),
    embedNewLink: ({ data }) => (dispatch(embedNewLink({ data }))),
    getCaptcha: () => dispatch(getCaptcha()),
    post_business_sign_up: (data) => dispatch(post_business_sign_up(data)),
    get_business_wall_data: ({ id, filter_type, page, force }) => dispatch(get_business_wall_data({ id, filter_type, page, force })),
    post_wall_comments: ({ action_id, body, business_id, filter_type }) => dispatch(post_wall_comments({ action_id, body, business_id, filter_type })),
    delete_wall_comment: ({ action_id, business_id, filter_type }) => dispatch(delete_wall_comment({ action_id, business_id, filter_type })),
    save_ratings_vote: (data) => dispatch(save_ratings_vote(data)),
    publish_post: (data, businessId) => dispatch(publish_post(data, businessId)),
    fetch_detailedstarrating: (data) => dispatch(fetch_detailedstarrating(data)),
    put_wall_reply: ({ discussion_id, body, business_id, filter_type, edit }) => dispatch(put_wall_reply({ discussion_id, body, business_id, filter_type, edit })),
    get_tips_and_warnings: (id) => dispatch(get_tips_and_warnings(id)),
    post_discuss_this_page: (data) => dispatch(post_discuss_this_page(data)),
    get_discussions: (url) => dispatch(get_discussions(url)),
    get_business_QA_dropdown: (id) => dispatch(get_business_QA_dropdown(id)),
    get_business_review_detail: (id, filter) => dispatch(get_business_review_detail(id, filter)),
    delete_special_offer: ({ id, listing_id, offer_type }) => dispatch(delete_special_offer({ id, listing_id, offer_type })),
    get_media_children: (category, type, subCategory) => dispatch(get_media_children(category, type, subCategory)),
    get_id_from_slug: ({ type, slug }) => dispatch(get_id_from_slug({ type, slug })),
    fetch_taxonomy_list: (category, type) => dispatch(fetch_taxonomy_list(category, type)),
    upload_media_filesData: (url, type, data, index) => dispatch(upload_media_filesData(url, type, data, index)),
    fetch_sub_categories: ({ sub_cat, level }) => dispatch(fetch_sub_categories({ sub_cat, level })),
    filter_categories_data: ({ category, sub_cat, level }) => dispatch(filter_categories_data({ category, sub_cat, level })),
    fetch_categories_data: (categoryId, subcategoryId, type, index) => dispatch(fetch_categories_data(categoryId, subcategoryId, type, index)),
    clear_category: () => dispatch(clear_category()),
    clear_subcategory: () => dispatch(clear_subcategory()),
    get_friends_profile: (type, businessID, pageNo) => dispatch(get_friends_profile(type, businessID, pageNo)),
    put_special_offer: ({ id, data, listing_id, offer_type }) => dispatch(put_special_offer({ id, data, listing_id, offer_type })),
    get_category_taxonomy_listing: (categoryType,types) => dispatch(get_category_taxonomy_listing(categoryType,types)),
    update_caption_and_categories: ({ id, data }) => dispatch(update_caption_and_categories({ id, data }))
});


export default connect(mapState, mapProps)(UploadMedia);
