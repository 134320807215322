
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { callApi, responseCodeValidator } from '../../../utils/apiCaller';
import querystring from "query-string";
import { capitalizeFirstLetter, responsiveCarousel } from '../../../utils/utilities';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';


const ViewReviewMedia = ({ media_type, associated_id, review_id, isModalOpen, toggleModal }) => {
    const initialState = {
        mediaSet: [],
        loading: true,
        currentPage: 0,

        isViewMediaSecondaryModal: false,
        secondaryMedia: null,
    }
    const [state, setState] = useState(initialState);
    const {
        mediaSet,
        loading,
        currentPage,
        isViewMediaSecondaryModal,
        secondaryMedia
    } = state;

    useEffect(() => {
        fetchMedia();
    }, [media_type, associated_id, review_id]);

    const fetchMedia = () => {

        const params = {
            media_type,
            id: associated_id,
            reviewMedia: true,
            reviewId: review_id
        }

        const queryParams = querystring.stringify(params);
        const url = `/api/adminmedia/?${queryParams}`;

        setState((prevState) => ({ ...prevState, loading: true }));

        callApi(url, 'GET').then((res) => {
            if (res && responseCodeValidator(String(res.code))) {
                setState((prevState) => ({
                    ...prevState,
                    mediaSet: res?.results.filter((item) => item?.id && item?.id.length),
                    loading: false
                }));
            }
        });
    }

    const toggleMediaStatus = ({ media }) => {

        const ismediaActive = media?.is_active;

        const body = {
            id: media.id,
            is_active: ismediaActive ? `False` : `active`,
        }
        setState((prevState) => ({ ...prevState, loading: true }));
        callApi(`/api/adminmedia/${media.id}/`, 'PUT', body).then((res) => {
            if (res && responseCodeValidator(String(res.code))) {
                fetchMedia({ page: currentPage + 1 });
            }
        });
    }

    return (
        <>

            <Modal
                className="custom-pop-change"
                centered
                isOpen={isModalOpen}
                toggle={toggleModal}>
                <ModalHeader className="px-0" toggle={toggleModal}>
                    {`${capitalizeFirstLetter(media_type)}s`}
                </ModalHeader>
                <ModalBody className="wk-compose-new-messagebody p-3 review-media-modal">
                    {loading ? `Loading...` :
                        <Carousel
                            responsive={responsiveCarousel.videoModal}
                            infinite={mediaSet?.length > 1 ? true : false}
                            autoPlay={false}
                            showDots={false}
                            containerClass="gallery-carousel"
                            arrows={mediaSet?.length > 1 ? true : false}
                        >
                            {(mediaSet && mediaSet?.length > 0) ? mediaSet.map((media, index) => {

                                return (
                                    <div className="item carsousel-media-conatiner" key={media?.id + index}>
                                        <div className={`media-holder ${media.media_type == "video" ? `video-thumbnail` : ``} mb-2`}>
                                            <img src={media.media_type == "image" ? media?.file : media?.thumbnail} alt="media" onClick={() => { setState((prev) => ({ ...prev, isViewMediaSecondaryModal: !isViewMediaSecondaryModal, secondaryMedia: media })) }} />
                                        </div>
                                        <div className={`media-action-container mt-2`}>
                                            <span className={`btn btn-sm ${media?.is_active ? `disable` : `enable`}-button`} onClick={() => { toggleMediaStatus({ media }) }}>{media?.is_active ? `Disable` : `Enable`}</span>
                                        </div>
                                    </div>)
                            }) : (

                                <div className="item px-4">
                                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '200px' }}>
                                        <div className="px-4 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                            <span >{`No ${media_type}s found for this listing.`}</span>
                                        </div>
                                    </div>
                                </div>)}
                        </Carousel>
                    }
                </ModalBody>
                <ModalFooter className="justify-content-start px-0">

                </ModalFooter>
            </Modal >

            {/** Secondary Modal to view Media */}

            <Modal
                centered
                isOpen={isViewMediaSecondaryModal}
                toggle={() =>
                    setState((prev) => ({ ...prev, isViewMediaSecondaryModal: !isViewMediaSecondaryModal, secondaryMedia: null }))
                }
            >
                <ModalBody className="bg-transparent p-0">
                    <Carousel
                        responsive={responsiveCarousel.videoModal}
                        infinite={false}
                        autoPlay={false}
                        swipeable={false}
                        showDots={false}
                        arrows={false}
                    >

                        <div
                            className="item fs-14 h-100 d-flex flex-column justify-content-center"
                        >
                            <div>
                                <div className="d-flex text-white justify-content-between mt-3">
                                    <div className="mr-2 ff-alt">{secondaryMedia?.caption}</div>
                                    <div className="ff-base">{secondaryMedia?.label}</div>
                                </div>
                            </div>
                            <div
                                className="text-center mt-3 d-flex flex-column justify-content-center align-items-center"
                                style={{ minHeight: "300px" }}
                            >
                                {(secondaryMedia?.media_type === "image" || secondaryMedia?.type === "") && (
                                    <img
                                        className="img-fluid"
                                        src={secondaryMedia?.file}
                                        alt=""
                                        style={{
                                            maxHeight: "300px",
                                            border: "2px solid #8791b5",
                                        }}
                                    />
                                )}
                                {secondaryMedia?.media_type === "video" && (
                                    <video
                                        className="img-fluid"
                                        controls
                                        style={{ maxHeight: "300px" }}
                                    >
                                        <source src={secondaryMedia?.file} type="video/mp4" />
                                        {"Your browser does not support the video tag."}
                                    </video>
                                )}
                            </div>
                            <div className="px-2 mt-3">
                                <div className="d-flex flex-nowrap mx-n2">
                                    <div className="px-2 col d-flex flex-nowrap">
                                        <div className="mr-2">
                                            <img
                                                className="img-circle _30x30"
                                                src={
                                                    secondaryMedia?.user?.current_profile_pic
                                                        ? secondaryMedia?.user?.current_profile_pic
                                                        : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"
                                                }
                                                alt=""
                                                onError={(error) =>
                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                }
                                            />
                                        </div>
                                        <div>
                                            <div className="text-primary ff-base">
                                                Uploaded by&nbsp;
                                                <Link to={`/people/${secondaryMedia?.user?.user?.username}`}>
                                                    <span className="font-weight-bold">
                                                        {secondaryMedia?.user?.user?.first_name}
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="text-tertiary ff-base">
                                                {secondaryMedia?.upload_date}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-2 col">&nbsp;</div>
                                </div>
                            </div>
                        </div>

                    </Carousel>
                </ModalBody>
            </Modal>
        </>

    )
}

export default ViewReviewMedia;