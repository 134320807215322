/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Alert,
    Badge, Button, Col,
    Container, DropdownItem, DropdownMenu,
    DropdownToggle, FormGroup, Input, Label,
    Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, ModalFooter
} from 'reactstrap';
import {
    fetch_movies_categories,
    fetch_movies_list,
    fetch_boxoffice_list,
    fetch_newtrends,
    fetch_cities,
    fetch_filtered_movies,
    fetch_current_boxoffice,
    reset_movies_list
} from '../../actions/moviesListing';
import CollapseBasic from '../atoms/collapse';
import AppHeader from '../oragnisms/dashboard/appHeader';
import CollageItems from '../oragnisms/movies/collageItems';
import UserFeedsPanel from '../atoms/userFeedsRightPanel';
import Loaderimg from "../../assets/images/w-brand-black.jpg";
import { loaderExludedRoutes } from "../../router";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import CreateReview from '../../component/atoms/createReview';
import UploadMedia from '../atoms/fileUploadMedia';
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";
import { add_to_list, remove_from_list, share_by_email,fetch_all_sub_categories_items } from '../../actions/business';
import Loader from '../atoms/loader';
import MovieCard from './movieCard';
import { detectlink, pushUniqueObjectsInArray } from '../../utils/utilities';
import { get_uploaded_media } from '../../actions/itemDetail';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';
import { getSubCategoryDescription, getSubCategoryTitle } from '../../utils/seo-utilities';

class Movies extends Component {
    constructor(props) {
        super(props);

        this.category = false;
        this.enableScrollTillEnd = true;

        this.state = {
            currentPageIndex: 1,
            checkNextPaination: false,
            readMoreShow: {},
            viewMoviesAs: 'theaters',
            viewEditReviewStep: 'writeReview',
            upcomingMoviesType: 'thisWeek',
            currentLat: '',
            currentLng: '',
            cities: [],
            all_cities: [],
            viewNearbyCitiesModalToggle: false,
            moviesList: [],
            upcomingMonths: [],
            isLoader: false,
            selectedCities: [],
            selectedCitiesMain: [],
            selectedTab: 'theaters',
            didYouKnowList: [],
            homeDropdownOpen: {},
            homeShareDropdownOpen: {},
            uploadedMediaSet: [],
            toggleItemCreateReview: {},
            toggleItemWriteAReview: {},
            toggleItemReadMore: {},
            uploadedMediaSet: {
                embedLinks: {},
                uploadedFiles: [],
            },
            isEnableFileUpload: false,
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: '',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
            enableLoading: false,
            selectedMonth: '',
            starFilterApplied: false,
            stars: null,
            userInfo: null,
            upcomingMoviesCount: null
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.my_profile) {
            if(!this.state.userInfo){
                this.setState({
                    userInfo: nextProps.my_profile
                }, ()=>{
                    this.props.reset_movies_list();
                    this.props.fetch_newtrends({ trending: 'national', content_type: 'movies', listingtaxonomy: '' });
                    this.props.fetch_movies_list({ category: '', location: false, homeTheater: (this.state.userInfo?.home_theater || false), page: this.state.currentPageIndex });
                    this.props.fetch_movies_categories({ category: 'movies', depth: false, delsel: '', ancestor: '', sub_cat: '', level: 1 });
                    this.props.fetch_current_boxoffice({ showdata: 'current' })
                })
            }
       
        }

        if (nextProps?.movies_items?.count && this.state.selectedTab === "upcoming") {
            this.setState({
                upcomingMoviesCount: nextProps.movies_items.count
            })
        }

        if (nextProps?.get_cities) {
            this.setState({ cities: nextProps.get_cities.slice(0, 4), all_cities: nextProps.get_cities });
        }

        if (nextProps?.movies_items?.results && this.state.moviesList?.length) {
            if (nextProps?.movies_items?.next !== this.state.checkNextPaination){
                const moviesList = JSON.parse(JSON.stringify(this.state.moviesList));
                const updatedMovieList = pushUniqueObjectsInArray(moviesList, nextProps.movies_items.results, 'id' )
                this.enableScrollTillEnd = true;
                this.setState({
                    moviesList: [...updatedMovieList],
                    checkNextPaination: nextProps.movies_items?.next
                });
            }      
        } else if (nextProps?.movies_items && Array.isArray(nextProps.movies_items)) {
            this.setState({
                moviesList: nextProps?.movies_items,
                checkNextPaination: false
            });
        } else {
            this.setState({
                moviesList: nextProps?.movies_items?.results,
                checkNextPaination: nextProps.movies_items?.next
            });
        }

        if (nextProps?.current_boxoffice_data) {
            this.setState({ didYouKnowList: nextProps.current_boxoffice_data });
        }

        if("isLoading" in nextProps){
            this.setState({
                enableLoading: nextProps.isLoading
            });
        }
    }

    handleToggleReadMoreShow = (type, state) => {
        this.setState({ readMoreShow: { [type]: state } })
    }

    componentDidUpdate(prevProps) {
        if (this?.props?.history?.location?.state?.id) {
            if (!this.category) {

                let id = this?.props?.history?.location?.state?.id;
                if (id) {
                    this.category = true;
                    this.setState({ subCatItem: { id }, enableLoading: false }, () => {
                        this.props.fetch_all_sub_categories_items({ cat: id, page: 1 });
                    })
                }
            }
        } else {
            if (this.props?.match?.params?.category) {
                if (this.state.subCatItem == null) {
                    this.category = false;
                    this.props.history.push('/business');
                }
                this.category = true;
            }
        }

        if (this.props.current_location && this.props.current_location.lat) {
            if (this.props.current_location.lat !== this.state.currentLat) {
                this.setState({ currentLat: this.props.current_location.lat, currentLng: this.props.current_location.lng })
                this.props.fetch_cities({ lat: this.props.current_location.lat, lng:  this.props.current_location.lng });
                // this.props.fetch_cities({ lat: 36.7783, lng:  -119.4179 });
            }
        }
    }

    componentDidMount() {
        if(this.state.userInfo !== null){
            this.props.reset_movies_list();
            this.props.fetch_newtrends({ trending: 'national', content_type: 'movies', listingtaxonomy: '' });
            this.props.fetch_movies_list({ category: '', location: false, homeTheater: (this.state.userInfo?.home_theater || false), page: this.state.currentPageIndex });
            this.props.fetch_movies_categories({ category: 'movies', depth: false, delsel: '', ancestor: '', sub_cat: '', level: 1 });
            this.props.fetch_current_boxoffice({ showdata: 'current' })
        }
      
        this.loaderInterval();
		window.addEventListener('scroll', this.handleScroll, { passive: false });
		document.title = `Movies Reviews`;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTillHeight = window.pageYOffset;
        let lastElemHeight = document.getElementById("last-index")?.offsetTop;
        let currentPageIndex = this.state.currentPageIndex;
        let checkNextPaination = this.state.checkNextPaination;

        if (scrollTillHeight > lastElemHeight && checkNextPaination) {
            if (this.enableScrollTillEnd) {
                this.enableScrollTillEnd = false;
                currentPageIndex++;
                this.setState({ currentPageIndex, enableLoading: true }, () => {
                    if (this.state.starFilterApplied) {
                        this.props.fetch_filtered_movies({ content_type: "Movies", cat: '', subcat: '', to_api: true, showReview: true, cPage: true, location: false, page: this.state.currentPageIndex, stars: this.state.stars })
                    } else {
                        this.props.fetch_movies_list({
                            category: '',
                            location: false,
                            homeTheater: (this.state.userInfo?.home_theater || false),
                            page: this.state.currentPageIndex
                        });
                    }                 
                    
                });
            }
        }
    }

    fetchItems = () => {
        this.category = false;
        this.setState({ selectedItems: null }, () => {
            this.props.fetch_newtrends({ trending: 'national', content_type: 'movies', listingtaxonomy: '' });
        });
    }

    itemsSet = (item) => {
        // if (isParent) {
        //     let cat = item?.category
        //     cat = cat.toLowerCase()
        //     cat = cat.replace('/', '')
        //     cat = cat.replace('  ', ' ')
        //     let link = '/movie/' + cat;
        //     this.props.reset_movies_list();
        //     this.props.history.push(link)
        // }  
        
        if (item?.seo_label) {
            this.setState({ subCatItem: item }, () => {
                this.props.reset_movies_list();
            })
        }
    }

    // fetchSubCategories = () => {
    //     let { subCatItem, activeCategoryFilter } = this.state;
    //     this.props.fetch_sub_categories({ sub_cat: subCatItem.label, level: 1 });
    //     if (activeCategoryFilter == "all") {
    //         this.props.fetch_all_sub_categories_items({ cat: subCatItem.id, page: 1 });
    //     }
    // }

    toggleCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = !expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseEnterCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseLeaveCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    toggleShareCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = !expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }

    onMouseShareEnterCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = expand;
        this.setState({ homeShareDropdownOpen });
    }

    onMouseShareLeaveCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = expand;
        this.setState({ homeShareDropdownOpen });
    }

    handleChangeTab = (tab) => {
        this.setState({ moviesList: [], selectedTab: tab, selectedMonth: '', starFilterApplied: false, stars: null, upcomingMoviesCount: null })
        let month_names = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "January", "February"];
        if (tab === 'theaters') {
            this.props.reset_movies_list();
            this.props.fetch_movies_list({ category: '', location: false, homeTheater: (this.state.userInfo?.home_theater || false), page: 1 });
        }
        if (tab === 'boxOffice') {
            this.props.reset_movies_list();
            // this.props.fetch_boxoffice_list({ showdata: 'current' });
        }
        if (tab === 'upcoming') {
            this.props.reset_movies_list();
            this.props.fetch_movies_list({ category: '', upcoming: 'thisweek', homeTheater: (this.state.userInfo?.home_theater || false), page: 1 });
        }
        if (tab === 'userTheaters') {
            this.props.reset_movies_list();
            this.props.fetch_movies_list({ category: '', usertheater: 24128, homeTheater: (this.state.userInfo?.home_theater || false), page: 1 });
        }
        if (tab === "comingSoon") {
            let date = new Date()
            let month = date.getMonth()
            let selected_months = [];
            selected_months.push(month_names[month + 1])
            selected_months.push(month_names[month + 2])
            this.setState({ upcomingMonths: selected_months, selectedMonth: selected_months[0] })
            this.props.reset_movies_list();
            this.props.fetch_movies_list({ category: '', upcoming: 'comingweek', homeTheater: (this.state.userInfo?.home_theater || true), month: month_names[month + 1], page: 1 });
        }
        if (month_names.includes(tab)) {
            this.setState({selectedMonth: tab})
            this.props.reset_movies_list();
            this.props.fetch_movies_list({ category: '', upcoming: 'comingweek', homeTheater: (this.state.userInfo?.home_theater || true), month: tab, page: 1 });
        }
    };

    checkIsLoader = () => {
        const {enableLoading} = this.state;
        if (enableLoading && enableLoading == "true") {
            return (
                <div className="loader_div">
                    <div className="inner-loader">
                        <img src={Loaderimg} alt="" />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    loaderInterval = () => {
        let this_keep = this;

        if (!loaderExludedRoutes.includes(this.props.history.location.pathname)) {
            this.intervalCheck = setInterval(() => {

                let { isLoader } = this_keep.state;
                let localValue = localStorage.getItem("loader");
                if (isLoader !== localValue) {
                    this_keep.setState({ isLoader: localStorage.getItem("loader") });
                }
            }, 1500);
        }

        if (loaderExludedRoutes.includes(this.props.history.location.pathname)) {
            clearInterval(this.intervalCheck);
        }
    };

    // this.props.fetch_filtered_movies({ content_type: "movies", stars: star, cat: '', subcat: '', to_api: true, showReview: true, cPage: true, location: false, page: 1, rating_filter: true })
    handleStarFilter = (star) => {
        this.props.reset_movies_list();
        this.setState({ moviesList: [], starFilterApplied: true, currentPageIndex: 1, stars: star, enableLoading: true })
        this.props.fetch_filtered_movies({ content_type: "Movies", cat: '', subcat: '', to_api: true, showReview: true, cPage: true, location: false, page: 1, stars: star })
    }

    handleCityFilter = (selectedCitiesMain) => {
        // const { selectedCitiesMain } = this.state
        this.setState({ moviesList: [], viewNearbyCitiesModalToggle: false })
        if (selectedCitiesMain.length > 0) {
            let city = ''
            city = selectedCitiesMain.join(",")
            this.props.fetch_filtered_movies({ citysearch: 'movie', page: 1, city: city })
        } else {
            this.props.reset_movies_list();
            this.props.fetch_movies_list({ category: '', location: false, homeTheater: (this.state.userInfo?.home_theater || false), page: 1 });
        }
    }

    handleAddCityMain = (city) => {
        let { selectedCitiesMain, selectedCities } = this.state
        let final_cities_main = [], final_cities = []
        if (selectedCitiesMain.includes(city[0])) {
            selectedCitiesMain.map(ct => {
                if (ct === city[0]) { }
                else { final_cities_main.push(ct) }
            });
        }
        else {
            final_cities_main = [...selectedCitiesMain]
            final_cities_main.push(city[0])
        }
        if (selectedCities.includes(city[0])) {
            selectedCities.map(ct => {
                if (ct === city[0]) { }
                else { final_cities.push(ct) }
            });
        }
        else {
            final_cities = [...selectedCitiesMain]
            final_cities.push(city[0])
        }
        this.setState({ selectedCitiesMain: final_cities_main, selectedCities: final_cities }, () => {
            this.handleCityFilter(this.state.selectedCitiesMain)
        })
    }

    handleCitySelection = () => {
        this.handleCityFilter(this.state.selectedCities)
    }

    handleAddCity = (city) => {
        let { selectedCities, selectedCitiesMain } = this.state
        let final_cities = []
        if (selectedCities.includes(city[0])) {
            selectedCities.map(ct => {
                if (ct === city[0]) { }
                else { final_cities.push(ct) }
            });
            this.setState({ selectedCities: final_cities })
        }
        else {
            selectedCities.push(city[0])
            this.setState({ selectedCities })
        }
        final_cities = []
        if (selectedCitiesMain.includes(city[0])) {
            selectedCitiesMain.map(ct => {
                if (ct === city[0]) { }
                else { final_cities.push(ct) }
            });
            this.setState({ selectedCitiesMain: final_cities })
        }
        else {
            selectedCitiesMain.push(city[0])
            this.setState({ selectedCitiesMain })
        }
    }

    toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
    }

    onPublishReview = () => {
        this.setState({
            toggleItemCreateReview: {},
            toggleItemWriteAReview: {},
            toggleItemReadMore: {},
            // moviesList: []
        }, () => {
            this.props.fetch_movies_list({ category: '', location: false, homeTheater: (this.state.userInfo?.home_theater || false), page: 1 });
        })
    }

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet } = this.state;
        let uploadedMediaSetCopy = { ...uploadedMediaSet }
        const key = 'id'
        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
            let mediaSetStored = uploadedMediaSetCopy?.uploadedFiles?.concat(mediaSet)
            const updatedMedia =[...this.props.editExistingMedia,...mediaSetStored]
            const mediaSetUnique = [...new Map(updatedMedia?.map(item =>
                [item[key], item])).values()];            
            this.setState(prevState => ({
                uploadedMediaSet: {
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:  mediaSetUnique
                   }
            }), () => {
                this.props.get_uploaded_media(uploadedMediaSet);                
            })
        }
        if (links && Array.isArray(links) && links.length > 0) {
            let linksStored = uploadedMediaSetCopy?.embedLinks?.concat(links)            
            this.setState(prevState => ({
                uploadedMediaSet: {
                    ...prevState.uploadedMediaSet,
                    embedLinks: linksStored
                }
            }), () => {
                this.props.get_uploaded_media(uploadedMediaSet);
            })
        }
    }

    handleOnClickUploadModalOk = () => {
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }

    closeAfterCancel = () => {
        this.setState({ toggleItemCreateReview: {}, toggleItemWriteAReview: {} })
    }

    handleOnChangeShareByEmail = (e) => {
        let errors = { ...this.state.shareError };
        if (e.target.value) {
            delete errors[e.target.name];
        }

        this.setState({
            [e.target.name]: e.target.value,
            shareError: errors
        });
    }

    handleOnSubmitShareByEmail = () => {
        let errors = { ...this.state.shareError };

        if (this.state.shareName === '') {
            errors.shareName = 'This field is required!';
        }

        if (this.state.shareEmail === '') {
            errors.shareEmail = 'This field is required!';
        } else {
            const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!reg.test(String(this.state.shareEmail).toLowerCase())) {
                errors.shareEmail = 'Please enter a valid email!';
            }
        }

        if (errors && Object.keys(errors).length > 0) {
            this.setState({ shareError: errors });
        } else {
            let key = 'listing_id';

            let url = `/api/shareByemail/`;

            const data = {
                [key]: this.state.shareId,
                mix_uid: `${this.state.shareEmail.split("@")[0]}_${this.state.shareId}_1597643911786`,
                email: this.state.shareEmail,
                user_name: this.state.shareName,
                listing_type: "movies"
            };

            this.props.share_by_email(url, data);

            this.setState({
                shareByEmailModalToggle: false,
                shareName: '',
                shareEmail: '',
                shareError: {}
            });
        }
    }

    handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id
        });
    }

    handleOnClickAddToList = (type, status, item) => {
        let message = '',
            url = '',
            nextUrl = '',
            data = { type };
        let moviesList = this.state.moviesList;
        let itemDetails = item;
        const pinStatus =  Array.isArray(item?.pins) && item?.pins?.length > 0 && item.pins[0]
        const list_type = type.replace(" ", "_")

        if (status) {
            message = `${itemDetails.name} has been removed from your ${type}`;
            url = `/api/pins/${item.id}/?type=${type}`;
            this.props.remove_from_list(url, message, nextUrl);
            if (Object.keys(pinStatus)?.length) {
                pinStatus[list_type] = []
            }
        } else {
            data.user_entry = itemDetails.id;
            message = `${itemDetails.name} has been added to your ${type}`;
            url = `/api/pins/?type=${type}&user=`;
            this.props.add_to_list(url, data, message, nextUrl);
            if (Object.keys(pinStatus)?.length) {
                pinStatus[list_type] = [1]
            }
        }

        if (pinStatus && pinStatus?.length) {
            itemDetails['pins'] = pinStatus
        }
        moviesList = moviesList.map(obj => {
            if (item.id === obj.id) {
                return itemDetails;
            }
            return obj;
        });

        this.setState({
            moviesList: moviesList
        });
    }

    getDirector = (movie) => movie.professional.filter(p => p.profession === "Director");

    getDirectorNames = (movie)=>  (this.getDirector(movie) || []).map(a=> a.first_name + a.last_name).join(", ")

    getCasts = (movie) => movie.professional.filter(p => p.profession === "Cast");

    toggleCreateReview = (movie) => {
        let toggleItemCreateReview = this.state.toggleItemCreateReview;
        if (toggleItemCreateReview[movie?.id] == undefined) {
            toggleItemCreateReview[movie?.id] = true;
        } else {
            toggleItemCreateReview[movie?.id] = !toggleItemCreateReview[movie?.id];
        }
        this.setState({ toggleItemCreateReview, toggleItemWriteAReview: {} });
    }

    toggleWriteAReview = (movie) => {
        let toggleItemWriteAReview = this.state.toggleItemWriteAReview;
        if (toggleItemWriteAReview[movie?.id] == undefined) {
            toggleItemWriteAReview[movie?.id] = true;
        } else {
            toggleItemWriteAReview[movie?.id] = !toggleItemWriteAReview[movie?.id];
        }
        this.setState({ toggleItemWriteAReview, toggleItemCreateReview: {} });
    }

    removeUploadedMediaSet = (id)=>{     
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSet:{
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:this.state.uploadedMediaSet?.uploadedFiles?.filter(item=>item.id!==id)
                }
            }
        })
    }

    render() {
        let { movies_categories } = this.props;
        let { selectedItems, subCatItem, isLoader, selectedTab, didYouKnowList, toggleItemCreateReview, toggleItemWriteAReview, toggleItemReadMore } = this.state;

        return (
            <div>
                <HelmetMetaData
                    title={getSubCategoryTitle("movies")}
                    description={getSubCategoryDescription("movies")}
                />
                {/* {this.checkIsLoader(isLoader)} */}

                {/* {
                    this.state.enableLoading ? <Loader /> : ''
                } */}
                {this.state.isEnableFileUpload ?
                    <UploadMedia
                        getUploadedMedia={this.getUploadedMedia}
                        uploadedUrl={this.state.uploadMediaUrl}
                        hideEmbed={this.state.hideEmbed}
                        callbackFunction={this.handleOnClickUploadModalOk}
                        noCategory={this.state.discussionTab}
                        typeUpload={this.state.typeupload}
                    />
                    : null}
                <AppHeader isEnableCover={false} />
                <CollageItems
                    fetchItems={this.fetchItems}
                    enableSubCategory={this.category}
                    items={this.props.collage_items}
                    selectedItems={selectedItems}
                    subCatItem={subCatItem}
                />

                {/* Top Menu before main body */}
                <Container>
                    <Nav className="featured position-relative" justified pills style={{ marginTop: '-54px', zIndex: 3 }}>
                        <NavItem className="mw-100">
                            <NavLink className="text-truncate tourStep-2_movie" href="#"
                                active={this.state.viewMoviesAs === "theaters"}
                                onClick={() => {
                                    this.setState({ viewMoviesAs: "theaters" });
                                    this.handleChangeTab("theaters")
                                }}>
                                <span className="font-weight-bold text-uppercase">
                                    {'In Theaters'}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="mw-100">
                            <NavLink className="text-truncate" href="#"
                                active={this.state.viewMoviesAs === "boxOffice"}
                                onClick={() => {
                                    this.setState({ viewMoviesAs: "boxOffice" });
                                    this.handleChangeTab("boxOffice")
                                }}>
                                <span className="font-weight-bold text-uppercase">
                                    {'Popular'}
                                </span>
                            </NavLink>
                        </NavItem>
                        <NavItem className="mw-100">
                            <NavLink className="text-truncate" href="#"
                                active={this.state.viewMoviesAs === "upcoming"}
                                onClick={() => {
                                    this.setState({ viewMoviesAs: "upcoming" }, () => {
                                        this.setState({ upcomingMoviesType: "thisWeek" });
                                    });
                                    this.handleChangeTab("upcoming")
                                }}>
                                <span className="font-weight-bold text-uppercase">
                                    {'Upcoming'}
                                </span>
                            </NavLink>
                        </NavItem>
                        {/* <NavItem className="mw-100">
                            <NavLink className="text-truncate tourStep-3_movie" href="#"
                                active={this.state.viewMoviesAs === "userTheaters"}
                                onClick={() => {
                                    this.setState({ viewMoviesAs: "userTheaters" });
                                    this.handleChangeTab("userTheaters")
                                }}>
                                <span className="font-weight-bold text-uppercase">
                                    {"User's Theaters"}
                                </span>
                            </NavLink>
                        </NavItem> */}
                    </Nav>
                </Container>

                {/* Main Content Starts */}
                <section className="py-4 text-white fs-14 step-1" style={{ backgroundColor: '#00162b' }}>
                    <Container>
                        <Row>
                            <Col md={2}>
                                <div className="mb-5">
                                    <h2 className="fs-36"> Categories</h2>
                                    <hr className="bg-light" />
                                    <div>
                                        <ul className="list-unstyled">
                                            {movies_categories && Array.isArray(movies_categories) && movies_categories.length ?
                                                movies_categories[0] && movies_categories[0]?.children?.length ?
                                                    movies_categories[0]?.children.map(item => (
                                                        <li className="ff-base">
                                                            <Link
                                                                to={{
                                                                    pathname: `/movie/${item.seo_label?.split(/movie-reviews\//)?.[1] || item.id}`,
                                                                    state: { 
                                                                        id: item.id ,
                                                                        parent_category: "movies",
                                                                        child_category: item?.category
                                                                    }
                                                                }}>
                                                                <Button
                                                                    onClick={() => { this.itemsSet(item) }}
                                                                    color="link"
                                                                    className="p-0 text-reset font-weight-bold ff-base text-left">
                                                                    {item.category}
                                                                </Button>
                                                            </Link>
                                                        </li>
                                                    ))
                                                    : null
                                                : null}
                                        </ul>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2">{'Star Rating'}</div>
                                    {[5, 4, 3, 2, 1, 0].map(item => (
                                        <FormGroup check>
                                            <Label className="text-white ff-alt font-weight-normal" check>
                                                <Input type="radio" name="filterByStarRating" className='star-filter-input-checkbox' onClick={() => this.handleStarFilter(item)} checked={this.state.stars === item} />{' '}
                                                <div className="d-flex align-items-center">
                                                    {item === 0 ?
                                                        <img
                                                            className="mr-1"
                                                            src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/norating.png`}
                                                            alt=""
                                                        /> :
                                                        <img
                                                            className="mr-1"
                                                            src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item}rating.png`}
                                                            alt=""
                                                        />
                                                    }
                                                    <span>{item === 5 ? "only" : "& up"}</span>
                                                </div>
                                            </Label>
                                        </FormGroup>
                                    ))}
                                    <hr className="bg-light" />
                                </div>


                                {/* City Listing */}
                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2">Nearby Cities</div>
                                    {this.state?.cities.map(city => (
                                        <FormGroup className="mb-2" check>
                                            <Label className="text-white ff-alt font-weight-normal" check>
                                                <Input type="checkbox" onClick={() => this.handleAddCityMain(city)} checked={this.state.selectedCities.includes(city[0]) || this.state.selectedCitiesMain.includes(city[0])} />{' '}
                                                {city}
                                            </Label>
                                        </FormGroup>
                                    ))
                                    }
                                    <div className="text-right">
                                        <span className="ff-base text-white fs-14 font-weight-normal" role="button" onClick={() => this.setState({ viewNearbyCitiesModalToggle: true })}>
                                            <FontAwesomeIcon icon="plus" />{" More"}
                                        </span>
                                    </div>
                                    <hr className="bg-light" />
                                </div>

                                {/* Ad. */}
                                <div className="mb-3">
                                    <h2>Did you see?</h2>

                                    {didYouKnowList && Array.isArray(didYouKnowList) && didYouKnowList.length > 0 ? 
                                    (<ul className="list-unstyled">
                                        {didYouKnowList?.map((movie) => (
                                            <li className="text-greyish text-center font-weight-bold fs-14 ff-base pb-2 border-bottom mb-2">    
                                                <Link to={{
                                                    pathname: `${`/movies/${movie.listid}`}`,
                                                    state: { id: movie?.listid }
                                                }} className="text-reset text-decoration-none">
                                                    {movie?.listing_image ?
                                                        <img className="img-fluid" src={movie?.listing_image} alt="Movie Name" />
                                                        :
                                                        <img className="img-fluid" src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/298188r2.jpg" alt="Movie Name" />
                                                    }
                                                </Link>
                                                <div className="py-2">
                                                    {movie?.movietitle}
                                                </div>
                                                <Link
                                                    to={{
                                                        pathname: `${`/movies/${movie.listid}`}`,
                                                        state: { id: movie?.listid }
                                                    }}
                                                    className="text-dark font-weight-bold ff-base">
                                                    <Button color="link" className="text-primary p-0" size="sm">How was it?</Button>
                                                </Link>
                                            </li>
                                        )
                                        )
                                        }

                                    </ul>)
                                    :
                                    (<h4 className='mt-3'>No data available for this week</h4>)}

                                    <hr className="bg-light" />
                                </div>
                                <div>
                                    <div className="bg-white text-center">
                                        <a rel="noopener noreferrer" href="http://wikireviews.com/blog" target="_blank" className="text-reset">
                                            <img className="img-fluid blog-img" src={"https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_05.png"} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <TabContent activeTab={this.state.viewMoviesAs}>
                                    <TabPane tabId="theaters">
                                        <div>
                                            {selectedTab === 'theaters' && this.state.moviesList?.map((movie, index) => (
                                                <>
                                                    {/* Show Main Movie Card */}
                                                    <MovieCard
                                                        key={index} 
                                                        movie={movie} 
                                                        index={index} 
                                                        handleOnClickAddToList={this.handleOnClickAddToList} 
                                                        itemID={`${this.state.moviesList.length == index + 1 ? 'last-index' : `current-index-${index}`}`} 
                                                        internalReview={false} 
                                                        toggleCreateReview={this.toggleWriteAReview}
                                                        storylineReadShowMore={(this.state.readMoreShow && !this.state.readMoreShow[`movie_01${index}`]) ? true : false}
                                                        handleToggleReadMoreShow={this.handleToggleReadMoreShow}
                                                    >
                                                    </MovieCard>

                                                    {/* Show first review */}
                                                    {movie?.latest_review?.review ? 
                                                        <div className="mb-1">
                                                            {/* Repeat this */}
                                                            <div className="bg-white text-dark p-3 mb-1">
                                                                <div className="d-flex mx-n2 mb-2">
                                                                    <div className="px-2">
                                                                        {(movie?.latest_review?.created_by?.user?.username && movie?.latest_review?.created_by?.user?.id && movie?.latest_review?.createdby_current_profile_pic) ?
                                                                            <Link to={{
                                                                                pathname: `${`/people/${movie?.latest_review?.created_by?.user?.username.toLowerCase().replace(/ /g, "-")}`}`,
                                                                                state: { id: movie?.latest_review?.created_by?.user?.id }
                                                                            }}>
                                                                                <img
                                                                                    className="img-circle _40x40"
                                                                                    src={movie?.latest_review?.createdby_current_profile_pic}
                                                                                    onError={(err) => err.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`}
                                                                                    alt="Reviewer-Profile"
                                                                                />
                                                                            </Link> :
                                                                            <img
                                                                                className="img-circle _40x40"
                                                                                src={`https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`}
                                                                                alt="Reviewer-Profile"
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="px-2 flex-grow-1">
                                                                        <div className="ff-base text-dark">
                                                                            {(movie?.latest_review?.created_by?.user?.username && movie?.latest_review?.created_by?.user?.id) ?
                                                                                <Link to={{
                                                                                    pathname: `${`/people/${movie?.latest_review?.created_by?.user?.username.toLowerCase().replace(/ /g, "-")}`}`,
                                                                                    state: { id: movie?.latest_review?.created_by?.user?.id }
                                                                                }} className="text-reset ff-base font-weight-bold mr-1">
                                                                                    {`${movie?.latest_review?.created_by?.user?.first_name} ${movie?.latest_review?.created_by?.user?.last_name}`}
                                                                                </Link> :
                                                                                <span className="text-reset ff-base font-weight-bold mr-1">
                                                                                    {`${movie?.latest_review?.created_by?.user?.first_name} ${movie?.latest_review?.created_by?.user?.last_name}`}
                                                                                </span>
                                                                            }

                                                                            {movie?.latest_review?.ratings_sets?.avg_rating &&
                                                                                Array.isArray(movie?.latest_review?.ratings_sets?.avg_rating) &&
                                                                                movie?.latest_review?.ratings_sets?.avg_rating?.length ?
                                                                                <>
                                                                                    <span className="text-reset ff-base mr-1">
                                                                                        gave it
                                                                                    </span>
                                                                                    <img
                                                                                        title={movie?.latest_review?.ratings_sets?.avg_rating[1]}
                                                                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${movie?.latest_review?.ratings_sets?.avg_rating[0]}`}
                                                                                        alt={movie?.latest_review?.ratings_sets?.avg_rating[1]}
                                                                                    />
                                                                                </>
                                                                                : null}
                                                                            <br />
                                                                        </div>

                                                                        {/* User Reply */}
                                                                        <div className="ff-alt mt-2 text-break">
                                                                            {movie?.latest_review?.review &&
                                                                                ReactHtmlParser((toggleItemReadMore[movie?.id]) ?
                                                                                detectlink(movie?.latest_review?.review) :
                                                                                detectlink(movie?.latest_review?.review).substring(0, 120))}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="text-right mt-3">
                                                                    {/* <span
                                                                        onClick={() => {
                                                                            if (toggleItemCreateReview[movie?.id] == undefined) {
                                                                                toggleItemCreateReview[movie?.id] = true;
                                                                            } else {
                                                                                toggleItemCreateReview[movie?.id] = !toggleItemCreateReview[movie?.id];
                                                                            }
                                                                            this.setState({ toggleItemCreateReview });
                                                                        }}
                                                                        className="text-primary font-weight-bold cursor-pointer">
                                                                            {this.state.userInfo.id === movie?.first_review?.created_by?.user?.id ? "Write your follow-up" : "Write your own"}
                                                                        </span> */}
                                                                    <Link
                                                                        to={{
                                                                            pathname: `${movie?.slug ? `/movies/${movie.slug}` : `/movies/${movie?.name.toLowerCase().replace(/ /g, '-')}`}`,
                                                                            state: { id: movie?.id }
                                                                        }}
                                                                        className="text-dark font-weight-bold ff-base">
                                                                        <Button color="link" className="text-primary font-weight-bold p-0">
                                                                            {this.state.userInfo.user.id === movie?.latest_review?.created_by?.user.id ? "Write your follow-up" : "Write your own"}
                                                                        </Button>
                                                                    </Link>
                                                                    <span className="mx-2"></span>
                                                                    <span
                                                                        onClick={() => {
                                                                            if (toggleItemReadMore[movie?.id] == undefined) {
                                                                                toggleItemReadMore[movie?.id] = true;
                                                                            } else {
                                                                                toggleItemReadMore[movie?.id] = !toggleItemReadMore[movie?.id];
                                                                            }
                                                                            this.setState({ toggleItemReadMore });
                                                                        }}
                                                                        className="text-dark font-weight-bold cursor-pointer">
                                                                        {toggleItemReadMore[movie?.id] ? 'show less' : 'read more'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="mb-1">
                                                            {/* Repeat this */}
                                                            <div className="bg-white text-dark p-3 mb-1">
                                                                <div className="d-flex mx-n2 mb-2">
                                                                    <div className="px-2 flex-grow-1">
                                                                        {/* User Reply */}
                                                                        <div className="ff-alt mt-2 text-break">
                                                                            Be the First to Write A Review About This Listing! (And Get Your First Reviewer Badge!)
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="text-right mt-3">
                                                                    {/* <span
                                                                        onClick={() => {
                                                                            if (toggleItemCreateReview[movie?.id] == undefined) {
                                                                                toggleItemCreateReview[movie?.id] = true;
                                                                            } else {
                                                                                toggleItemCreateReview[movie?.id] = !toggleItemCreateReview[movie?.id];
                                                                            }
                                                                            this.setState({ toggleItemCreateReview });
                                                                        }}
                                                                        className="text-primary font-weight-bold cursor-pointer">
                                                                         {this.state.userInfo.id === movie?.first_review?.created_by?.user?.id ? "Write your follow-up" : "Write your own"}
                                                                        </span> */}
                                                                        <Link
                                                                        to={{
                                                                            pathname: `${movie?.slug ? `/movies/${movie.slug}` : `/movies/${movie?.name.toLowerCase().replace(/ /g, '-')}`}`,
                                                                            state: { id: movie?.id }
                                                                        }}
                                                                        className="text-dark font-weight-bold ff-base">
                                                                        <Button color="link" className="text-primary font-weight-bold p-0">
                                                                            Write your own
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {/* Show Theaters */}
                                                    {/* { movie?.theatres?.length > 0 ?
                                                        <div>
                                                            <div className="bg-lightish text-dark p-3 mb-1">
                                                                <div className="text-dark font-weight-bold fs-18">
                                                                    {'Theaters'}
                                                                </div>
                                                                <hr className="bg-tertiary mt-2" />

                                                                <div>
                                                                    {movie?.theatres.map((theater, index) => (
                                                                        <span>
                                                                            <div>
                                                                                <div className="d-flex mx-n2">
                                                                                    <div className="px-2">
                                                                                        <img width="30" src={theater?.image} alt="" />
                                                                                    </div>
                                                                                    <div className="px-2 col">
                                                                                        <CollapseBasic title={theater?.name} noHr={true} containerClass="bg-transparent type-tertiary title-type-sm type-theater" bodyClass="mt-2 ff-alt text-dark" isOpen={false} size="sm">
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>Address: </span>
                                                                                                    <span>{theater?.address} <br /> {theater?.city}, {theater?.state} {theater?.zip}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>No. of screens: </span>
                                                                                                    <span>{theater?.screens}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Phone: </span>
                                                                                                    <span>{theater?.phone}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Attribute: </span>
                                                                                                    <span>{theater?.attributes}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Showtime: </span>
                                                                                                    <span>
                                                                                                        {theater?.showtime.map((time, index) => (
                                                                                                            <span>{time}
                                                                                                                {index === theater?.showtime_list.length - 1 ?
                                                                                                                    ''
                                                                                                                    :
                                                                                                                    ' | '
                                                                                                                }
                                                                                                            </span>
                                                                                                        ))}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </CollapseBasic>
                                                                                    </div>
                                                                                </div>
                                                                                <hr className="bg-tertiary mt-2" />
                                                                            </div>
                                                                        </span>
                                                                    ))}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        : 
                                                        ''
                                                    } */}

                                                    {/* Internal Review Section */}
                                                    {toggleItemCreateReview[movie?.id] && (
                                                        (this.state.userInfo.id === movie?.latest_review?.created_by?.user?.id) ?
                                                            <CreateReview
                                                                itemDetail={movie}
                                                                closeAfterCancel={this.closeAfterCancel}
                                                                viewEditReviewStep={'writeReview'}
                                                                mainViewType={'reviews'}
                                                                listing_type={'movies'}
                                                                reviews={[]}
                                                                toggleEnableFileUpload={this.toggleEnableFileUpload}
                                                                uploadedMediaSet={this.state.uploadedMediaSet}
                                                                removeUploadedMediaSet={this.removeUploadedMediaSet}
                                                                onPublishReview={this.onPublishReview}
                                                                categoryId={movie?.id}
                                                                item={movie?.latest_review}
                                                                isWriteFollowUp={true}
                                                                type="followUp"
                                                            />
                                                        :
                                                        <CreateReview
                                                            itemDetail={movie}
                                                            closeAfterCancel={this.closeAfterCancel}
                                                            viewEditReviewStep={'writeReview'}
                                                            mainViewType={'reviews'}
                                                            listing_type={'movies'}
                                                            reviews={[]}
                                                            toggleEnableFileUpload={this.toggleEnableFileUpload}
                                                            uploadedMediaSet={this.state.uploadedMediaSet}
                                                            removeUploadedMediaSet={this.removeUploadedMediaSet}
                                                            onPublishReview={this.onPublishReview}
                                                            categoryId={movie?.id}
                                                        />
                                                    )}
                                                    {
                                                        toggleItemWriteAReview[movie?.id] && 
                                                        <CreateReview
                                                            itemDetail={movie}
                                                            closeAfterCancel={this.closeAfterCancel}
                                                            viewEditReviewStep={'writeReview'}
                                                            mainViewType={'reviews'}
                                                            listing_type={'movies'}
                                                            reviews={[]}
                                                            toggleEnableFileUpload={this.toggleEnableFileUpload}
                                                            uploadedMediaSet={this.state.uploadedMediaSet}
                                                            removeUploadedMediaSet={this.removeUploadedMediaSet}
                                                            onPublishReview={this.onPublishReview}
                                                            categoryId={movie?.id}
                                                        />
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="boxOffice">
                                        <div>
                                            {/* New Implementation to be Done */}
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="upcoming">
                                        <Nav className="dark active-transparent" tabs>
                                            <NavItem>
                                                <NavLink
                                                    className="fs-16 ff-base font-weight-normal h-100"
                                                    active={this.state.upcomingMoviesType === "thisWeek"}
                                                    onClick={() => {
                                                        this.setState({ upcomingMoviesType: "thisWeek" });
                                                        this.handleChangeTab("upcoming")
                                                    }}
                                                >
                                                    Opening This Week 
                                                    { this.state.upcomingMoviesCount ? <Badge color="primary" className="rounded-circle fixed-width ml-1">{this.state.upcomingMoviesType == 'thisWeek' ? this.state.upcomingMoviesCount : ''}</Badge> : '' }
                                                    
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className="fs-16 ff-base font-weight-normal h-100"
                                                    active={this.state.upcomingMoviesType === "comingSoon"}
                                                    onClick={() => {
                                                        this.setState({ upcomingMoviesType: "comingSoon" });
                                                        this.handleChangeTab("comingSoon")
                                                    }}
                                                >
                                                    Coming Soon
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={this.state.upcomingMoviesType}>
                                            <TabPane tabId="thisWeek">
                                                <div>
                                                    {selectedTab === 'upcoming' && this.state.moviesList?.map((movie, index) => (
                                                        <MovieCard
                                                            key={index}
                                                            movie={movie}
                                                            index={index}
                                                            handleOnClickAddToList={this.handleOnClickAddToList}
                                                            itemID={`${this.state.moviesList.length == index + 1 ? 'last-index' : `current-index-${index}`}`}
                                                            storylineReadShowMore={(this.state.readMoreShow && !this.state.readMoreShow[`movie_01${index}`]) ? true : false}
                                                            handleToggleReadMoreShow={this.handleToggleReadMoreShow}
                                                            hideReview={true}
                                                        >
                                                        </MovieCard>
                                                    ))
                                                    }
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="comingSoon">
                                                <div>
                                                    <div className="d-flex mx-n2 mb-3">
                                                        {this.state.upcomingMonths?.map((month) => (
                                                            <div className="px-2">
                                                                <div className={`${month === this.state.selectedMonth ? `text-white` : 'text-tertiary'}`} role="button" onClick={() => this.handleChangeTab(month)}>{month}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div>
                                                    {this.state.moviesList?.map((movie, index) => (
                                                        <>
                                                            <div className='mt-3 mb-1'>
                                                                {movie?.additional_info?.release_date && movie?.additional_info?.release_date[0].value}
                                                                {movie?.additional_info && movie?.additional_info['Release Dates'] && movie?.additional_info['Release Dates'][0].value}
                                                            </div>

                                                            <MovieCard
                                                                key={index}
                                                                movie={movie}
                                                                index={index}
                                                                handleOnClickAddToList={this.handleOnClickAddToList}
                                                                itemID={`${this.state.moviesList.length == index + 1 ? 'last-index' : `current-index-${index}`}`}
                                                                storylineReadShowMore={(this.state.readMoreShow && !this.state.readMoreShow[`movie_01${index}`]) ? true : false}
                                                                handleToggleReadMoreShow={this.handleToggleReadMoreShow}
                                                                hideReview={true}
                                                            >
                                                            </MovieCard>
                                                        </>
                                                    ))
                                                    }
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </TabPane>

                                    {/* <TabPane tabId="userTheaters">
                                        <div>
                                            <div className="d-flex mx-n2 mb-2">
                                                <div className="px-2">
                                                    <img alt="" width="100" src="https://userdatawikireviews.s3.amazonaws.com/media/content/Edwards.Regal.Logo.lines2182thru2223.jpg" />
                                                </div>
                                                <div className="px-2 col">
                                                    <div className="text-greyish">
                                                        <div className="font-weight-bold mb-2">
                                                            Regal Edwards Market Place
                                                        </div>
                                                        <div className="ff-alt">
                                                            <span className="font-weight-bold">
                                                                Address:
                                                            </span>
                                                            <span>
                                                                13782 Jamboree Road
                                                                <br />
                                                                Irvine, CA 92602
                                                            </span>
                                                        </div>
                                                        <hr className="bg-white" />
                                                        <CollapseBasic title="Additional Information" noHr={true} containerClass="bg-transparent type-greyish type-tertiary" bodyClass="mt-2 text-greyish" isOpen={false} size="sm">
                                                            <ul className="list-unstyled">
                                                                <li className="mb-1">
                                                                    <span className="font-weight-bold mr-2">Phone</span>
                                                                    <span>(844) 462-7342 141</span>
                                                                </li>
                                                                <li className="mb-1">
                                                                    <span className="font-weight-bold mr-2">Attributes</span>
                                                                    <span>handicap, hearing</span>
                                                                </li>
                                                                <li className="mb-1">
                                                                    <span className="font-weight-bold mr-2">Online Tickets</span>
                                                                    <span><a rel="noopener noreferrer" className="text-reset" href="http://www.movietickets.com/theater/hid/5344" target="_blank">Movietickets.com</a></span>
                                                                </li>
                                                                <li className="mb-1">
                                                                    <span className="font-weight-bold mr-2">Screens</span>
                                                                    <span>10</span>
                                                                </li>
                                                                <li className="mb-1">
                                                                    <span className="font-weight-bold mr-2">Seating</span>
                                                                    <span>Stadium</span>
                                                                </li>
                                                                <li className="mb-1">
                                                                    <span className="font-weight-bold mr-2">Sound</span>
                                                                    <span>Digital</span>
                                                                </li>
                                                            </ul>
                                                        </CollapseBasic>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="d-flex mx-n2 mb-3">
                                                    <div className="px-2">
                                                        <div className="text-white font-weight-bold" role="button">Showing</div>
                                                    </div>
                                                    <div className="px-2">
                                                        <div className="text-tertiary" role="button">Coming Soon</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                {this.state.moviesList?.map((movie, index) => (
                                                    <MovieCard
                                                    key={index}
                                                    movie={movie}
                                                    index={index}
                                                    handleOnClickAddToList={this.handleOnClickAddToList}
                                                    itemID={`${this.state.moviesList.length == index + 1 ? 'last-index' : `current-index-${index}`}`}
                                                    storylineReadShowMore={(this.state.readMoreShow && !this.state.readMoreShow[`movie_01${index}`]) ? true : false}
                                                    handleToggleReadMoreShow={this.handleToggleReadMoreShow}
                                                    hideReview={true}
                                                >
                                                </MovieCard>
                                                ))
                                                }</div>
                                        </div>
                                    </TabPane> */}
                                </TabContent>
                                {
                                    this.state.enableLoading && !this.state.moviesList?.length
                                        ?
                                        <div>
                                            <h1>Loading...</h1>
                                        </div>
                                        :
                                        ''
                                }

                                {
                                    this.state.enableLoading && this.state.moviesList?.length
                                        ?
                                        <div className="mt-5 text-center">
                                            <h2>{"Loading more movies..."}</h2>
                                        </div>
                                        :
                                        ''
                                }

                                {
                                    !this.state.moviesList?.length && !this.state.enableLoading ?
                                        <div>
                                            <div className="no-result-category">
                                                {'No Results Found. Please Choose Another Category.'}
                                            </div>
                                        </div> : ''

                                }


                                <div style={{height: "400px"}}></div>
                            </Col>
                            <Col md={4}>
                                <div className="type-dark">
                                    <UserFeedsPanel category="movies" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>


                {/* Show All Cities */}
                <Modal isOpen={this.state.viewNearbyCitiesModalToggle} toggle={() => this.setState({ viewNearbyCitiesModalToggle: !this.state.viewNearbyCitiesModalToggle })}>
                    <ModalHeader toggle={() => this.setState({ viewNearbyCitiesModalToggle: !this.state.viewNearbyCitiesModalToggle })}>MORE NEARBY CITIES</ModalHeader>
                    <ModalBody>
                        <Row xs={1} sm={2} lg={3}>
                            {this.state?.all_cities.map(city => (
                                <Col>
                                    <FormGroup className="mb-2" check>
                                        <Label className="text-dark font-weight-normal fs-14" check>
                                            <Input type="checkbox" onClick={() => this.handleAddCity(city)} checked={this.state.selectedCities.includes(city[0]) || this.state.selectedCitiesMain.includes(city[0])} />{' '}
                                            {city}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            ))
                            }
                        </Row>

                        <div className="mt-2">
                            <Button color="primary" className="mw" size="sm" onClick={() => this.handleCitySelection()}>Search</Button>
                            <Button color="primary" className="mw" size="sm" onClick={() => this.setState({ viewNearbyCitiesModalToggle: false, selectedCities: [], selectedCitiesMain: [] }, () => {
                                this.props.reset_movies_list()
                                this.props.fetch_movies_list({ category: '', location: false, homeTheater: (this.state.userInfo?.home_theater || false), page: 1 });
                            })}>Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>

                {/* Share By Email Modal Popup */}
                <Modal
                    size="sm"
                    isOpen={this.state.shareByEmailModalToggle}
                    toggle={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })
                    }
                >
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() =>
                            this.setState({
                                shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                            })
                        }
                    >
                        Share by Email
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="text"
                                name="shareName"
                                placeholder="Your name"
                                value={this.state.shareName}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareName && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareName}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="email"
                                name="shareEmail"
                                placeholder="Recipient's email"
                                value={this.state.shareEmail}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareEmail && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareEmail}
                                </div>
                            )}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <div className="text-right">
                            <Button color="primary" onClick={this.handleOnSubmitShareByEmail}>
                                Share
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

const mapState = (state) => ({
    my_profile: state.user.my_profile,
    collage_items: state.movies_listing.collage_items,
    movies_categories: state.movies_listing.movies_categories,
    movies_items: state.movies_listing.movies_items,   
    isLoading: state.movies_listing.isLoading,
    current_location: state.home.current_location,
    get_cities: state.movies_listing.get_cities,
    current_boxoffice_data: state.movies_listing.current_boxoffice_data,
    editExistingMedia : state.shared.editExistingMedia
});

const mapProps = (dispatch) => ({
    fetch_newtrends: (data) => dispatch(fetch_newtrends(data)),
    fetch_movies_list: (data) => dispatch(fetch_movies_list(data)),    
    reset_movies_list: () => dispatch(reset_movies_list()),
    fetch_movies_categories: (data) => dispatch(fetch_movies_categories(data)),
    fetch_cities: (data) => dispatch(fetch_cities(data)),
    fetch_boxoffice_list: (data) => dispatch(fetch_boxoffice_list(data)),
    fetch_filtered_movies: (data) => dispatch(fetch_filtered_movies(data)),
    fetch_current_boxoffice: (data) => dispatch(fetch_current_boxoffice(data)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
	fetch_all_sub_categories_items: ({ cat, page }) => dispatch(fetch_all_sub_categories_items({ cat, page })),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    get_uploaded_media: (data) => dispatch(get_uploaded_media(data)),
});

export default connect(mapState, mapProps)(Movies);



// https://wikireviews.com/api/search/?citysearch=movie&page=1&city=Rocklin
// https://wikireviews.com/api/search/?citysearch=movie&page=1&city=Citrus%20Heights,Rocklin
