
/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import CollapseBasic from '../../../atoms/collapse';
import { callApi } from "../../../../utils/apiCaller";

import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


class FriendsProfile extends Component {
    constructor(props) {
        super(props)

        this.myScrollFreindhRef = React.createRef();
        this.state = {
            viewFriendsListModalToggle: false,
            FriendsProfileData: null
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profileData) {
            this.setState({
                FriendsProfileData: nextProps.profileData
            })
        }
    }

    handleScrollSearch = (e) => {
        let { myScrollFreindhRef } = this;

        let clientHeight = myScrollFreindhRef.current.clientHeight;
        let scrollHeight = myScrollFreindhRef.current.scrollHeight;
        const scrollTop = this.myScrollFreindhRef.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;


        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            if (this.state.FriendsProfileData && this.state.FriendsProfileData.next) {

                this.fetchSearchOnScroll(this.state.FriendsProfileData.next.split('com')[1]);
            }
        }



    }

    fetchSearchOnScroll = (next) => {
        this.setState({ loading: true });
        callApi(`${next}`, 'GET')
            .then(res => {
                if (res && Object.keys(res).length > 0 && res.results) {
                    let data = {
                        ...res,
                        results: [...this.state.FriendsProfileData.results, ...res.results]
                    };

                    this.setState({ searchResultData: data, loading: false });
                }
            });
    }

    render() {
        const { title } = this.props;
        const { FriendsProfileData } = this.state;   
        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic
                    titleCount={(FriendsProfileData?.count && FriendsProfileData?.count > 0) ? FriendsProfileData.count : "None"}
                    title={`of your Friends ${title} this`}
                    containerClass="bg-transparent type-secondary"
                    bodyClass="text-secondary"
                    isOpen={true}
                    collapsible={FriendsProfileData && FriendsProfileData.count}
                >
                    {(FriendsProfileData?.count !== 0 && FriendsProfileData?.results.length > 0) ? 
                    (<>
                        <ul className="list-unstyled d-flex flex-wrap mx-n2">
                        {
                            FriendsProfileData &&
                            FriendsProfileData?.results &&
                            Array.isArray(FriendsProfileData?.results) &&
                            FriendsProfileData?.results !== undefined
                            ? FriendsProfileData?.results.map((item, index) =>
                                index < 4 ? (
                                    <li className="px-2 mb-2" key={index}>
                                        <Link
                                            to={`/people/${item?.user?.username}`}
                                            target="_blank"
                                        >
                                            <img
                                                className="img-circle _50x50"
                                                src={item?.current_profile_pic || "../../../../assets/images/icons/user-circle.png"}                                                
                                                alt={`${item?.user?.first_name} ${item?.user?.last_name}`}
                                                title={`${item?.user?.first_name} ${item?.user?.last_name}`}
                                                onError={(error) => (error.target.src = require("../../../../assets/images/icons/user-circle.png"))}
                                            
                                            />
                                        </Link>
                                    </li>
                                ) : null
                            )
                            : null
                        }
                        {
                            FriendsProfileData?.results !== undefined &&
                            FriendsProfileData?.results.length > 4 && (
                                <li className="px-2 mb-2">
                                    <div className="more-count"
                                        role='button'
                                        onClick={() => this.setState({
                                            viewFriendsListModalToggle: true
                                        })}>

                                        {`+${FriendsProfileData?.count - 4}`}

                                    </div>
                                </li>
                            )
                        }
                    </ul>
                    <div className="text-right mt-2">
                        <span className="text-secondary-dark font-weight-bold" role="button"
                            onClick={() => this.setState({
                                viewFriendsListModalToggle: true
                            })}
                        >view all</span>
                    </div>
                </>)
                    : 'No data to display'}
                    
                </CollapseBasic>
                {/* Friends want this Modal */}
                <Modal
                    // ref={this.myScrollFreindhRef}
                    // onScroll={this.handleScrollSearch}
                    size="sm"
                    isOpen={this.state.viewFriendsListModalToggle}
                    scrollable
                    toggle={() => this.setState({
                        viewFriendsListModalToggle: !this.state.viewFriendsListModalToggle
                    })}
                >
                    <ModalHeader className="text-uppercase px-0" toggle={() => this.setState({
                        viewFriendsListModalToggle: !this.state.viewFriendsListModalToggle
                    })}>
                        <div className="fs-32">Friends {title} this</div>
                    </ModalHeader>
                    <ModalBody>
                        <ul className="list-unstyled">
                            {FriendsProfileData &&
                                FriendsProfileData?.results &&
                                Array.isArray(FriendsProfileData?.results) &&
                                FriendsProfileData?.results !== undefined
                                ? FriendsProfileData?.results.map((item, index) =>
                                    (
                                        <li className="mb-2">
                                            <div className="d-flex mx-n2 align-items-center">
                                                <div className="px-2">
                                                    <Link
                                                        to={`/people/${item?.user?.username}`}
                                                    >
                                                        <img
                                                            className="object-fit-cover"
                                                            width="50"
                                                            height="50"
                                                            src={item.current_profile_pic}
                                                            onError={(error) => (error.target.src = require("../../../../assets/images/icons/user-circle.png"))}
                                                            alt={`${item?.user?.first_name} ${item?.user?.last_name}`}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="px-2">
                                                    <Link
                                                        to={`/people/${item?.user?.username}`}
                                                        className="text-royal-blue font-weight-bold ff-base fs-14 text-decoration-none">
                                                        {`${item?.user?.first_name} ${item?.user?.last_name}`}
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>

                                    )
                                )
                                : null}
                            {/* <li>
                                <Alert color="primary">Loading...</Alert>
                            </li> */}
                        </ul>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}


export default FriendsProfile;