import { callApi } from "../utils/apiCaller";
import { getQueryString, } from '../utils/validation';

const FETCH_USER_FEEDS = 'FETCH_USER_FEEDS';

const common_fetch = ({ url, method, data, dispatch_function }) => {
    if (data === undefined) {
        data = {};
    }
    return (dispatch) => {
        callApi(url, method, data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(dispatch_function(response));
            }
        });
    };
};

export const fetch_user_feeds_dispatch = (data) => ({ type: FETCH_USER_FEEDS, payload: data, });
export const fetch_user_feeds = (...data) => {
    return common_fetch({
        url: getQueryString({ url: `/api/userfeeds/`, data }),
        method: "GET",
        dispatch_function: fetch_user_feeds_dispatch
    });
};
