import React, { useEffect, useState } from "react";
import {
  Input,
  InputGroupAddon,
  Form,
  InputGroup,
  Card,
  CardBody,
  CardImg,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";
import locationImg from "../../../assets/images/icons/location.svg";
import { callApi, responseCodeValidator } from "../../../utils/apiCaller";
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import {
  faThumbsUp as faThumbsUpSolid,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import {
  capitalizeFirstLetter,
  pushUniqueObjectsInArray,
} from "../../../utils/utilities";
import { toast } from "react-toastify";

const responsive = {
  viewGalleryImages: {
    desktop: {
      breakpoint: { max: 9999999, min: 1200 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1199, min: 576 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 3,
    },
  },
};

const professionalCategories = ['doctors', 'dentists', 'accountants', 'lawyers', 'actors']


const RecommendQuestions = (props) => {
  const [currentLocation, setCurrentLocation] = useState({});
  const [searchInfo, setSearchInfo] = useState({
    search_name: "",
    search_length: 0,
    unableToSet: false,
    searchHomeData: [],
  });
  const [contentType, setContentType] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [questionInfo, setQuestionInfo] = useState({});
  const [suggestedAnswers, setSuggestedAnswers] = useState({});
  const [loadingSuggestions, setLoadingSuggestions] = useState(true);
  const [nextPage, setNextPage] = useState(false);
  const [isThirdLogin, setIsThirdLogin] = useState(false)

  useEffect(() => {
    callApi("/api/settemplocation/", "GET").then((location) => {
      if (location && responseCodeValidator(`${location?.code}`))
        setCurrentLocation(location);
    });
  }, []);

  useEffect(() => {
    callApi("/api/get_random_questions/", "GET").then(
      (response) => {
        if (response && responseCodeValidator(`${response?.code}`)) {
          if (response?.data === "Questions are available on 3rd login only") {
            setIsThirdLogin(false)
          } else {
            setIsThirdLogin(true)
            let question = response.body ? response.body : response.body_preview;
            let taxonomy_list = response.taxonomy_related?.taxonomy_list;
            let mainCategoryType;
            if (taxonomy_list && taxonomy_list.includes("Business")) {
              mainCategoryType = "business";
            } else if (taxonomy_list.includes("Products")) {
              mainCategoryType = "products";
            } else if (taxonomy_list.includes("Movies")) {
              mainCategoryType = "movies";
            } else if (taxonomy_list.includes("Actors")) {
              mainCategoryType = "actors";
            } else if (taxonomy_list.includes("Dentists")) {
              mainCategoryType = "dentists";
            } else if (taxonomy_list.includes("Doctors")) {
              mainCategoryType = "doctors";
            } else if (taxonomy_list.includes("Lawyers")) {
              mainCategoryType = "lawyers";
            } else if (taxonomy_list.includes("Accountants")) {
              mainCategoryType = "accountants";
            } else {
              mainCategoryType = response.taxonomy_related?.parent_category
                ? response.taxonomy_related?.parent_category
                : response.taxonomy_related?.category;
            }
            let questionId = response.id;
            let categoryId = response.taxonomy_related?.id;
            let category = response.taxonomy_related?.category;
            setQuestionInfo({
              questionId,
              question,
              mainCategoryType,
              category,
              categoryId,
            });
          }
        }
      }
    );
  }, []);

  useEffect(() => {
    let url;
    if (questionInfo?.mainCategoryType === "business") {
      url = `/api/search/?content_type=business&cat=${questionInfo?.categoryId}&to_api=true&showReview=true&cPagebest=true&location=true&page=1`;
    } else if (questionInfo?.mainCategoryType === "movies") {
      url = `/api/movies/?category=${questionInfo?.categoryId}&page=1`;
    } else if (questionInfo?.mainCategoryType === "products") {
      url = `/api/search/?content_type=products&cat=${questionInfo?.categoryId}&to_api=true&showReview=true&cPage=true&location=false&page=1`;
    } else if (professionalCategories.includes(questionInfo?.mainCategoryType)) {
      url = `/api/professionalscategory/?content_type__category=${questionInfo?.mainCategoryType}&page=1&taxonomy__in=${questionInfo?.categoryId}`
    }
    if (url) {
      callApi(url, "GET").then((response) => {
        if (response && responseCodeValidator(`${response.code}`)) {
          setLoadingSuggestions(false);
          setNextPage(response.next);
          let suggestedAnswers = response.results;
          if (
            suggestedAnswers &&
            Array.isArray(suggestedAnswers) &&
            suggestedAnswers.length
          ) {
            if (professionalCategories.includes(questionInfo?.mainCategoryType)) {
              suggestedAnswers = suggestedAnswers.map((item) => {
                return {
                  id: item.id,
                  title: `${item?.firstname ? item?.firstname : ''} ${item?.lastname ? item?.lastname : ''}`,
                  image_url: item?.professional_profileimage?.[0]?.url,
                  pins: professionalPinsMapper(item.pins_status),
                };
              });
            } else {
              suggestedAnswers = suggestedAnswers.map((item) => {
                return {
                  id: item.id,
                  title: item.name,
                  image_url: item?.listing_profileimage?.[0]?.url,
                  pins: questionInfo?.mainCategoryType === "movies" ? moviesPinsMapper(item.pins) : item.pins,
                };
              });
            }
            setSuggestedAnswers(suggestedAnswers);
          }
        }
      });
    }
  }, [questionInfo]);

  const professionalPinsMapper = (pins) => {
    const updatedPins = {
      user_favourites_status: pins?.['favourite_list'],
      user_want_status: pins?.['want_list'],
      user_watch_status:  pins?.['watch_list'],
      user_recommend_status:  pins?.['recommend_list'] 
    }
    return updatedPins;
  }

  const moviesPinsMapper = (pins) => {
    const updatedPins = {
      user_favourites_status: pins?.[0]?.favourite_list?.[0] ? true : false,
      user_want_status: pins?.[0]?.want_list?.[0] ? true : false,
      user_watch_status:  pins?.[0]?.watch_list?.[0] ? true : false,
      user_recommend_status:  pins?.[0]?.recommend_list?.[0] ? true : false 
    }
    return updatedPins;
  }

  const fetchMoreAnswers = () => {
    const url = nextPage?.split("/api/")?.[1];
    if (url) {
      callApi(`/api/${url}`, "GET").then((response) => {
        if (response && responseCodeValidator(`${response.code}`)) {
          setNextPage(response.next);
          let newSuggestedAnswers = response.results;
          if (
            newSuggestedAnswers &&
            Array.isArray(newSuggestedAnswers) &&
            newSuggestedAnswers.length
          ) {
            if (professionalCategories.includes(questionInfo?.mainCategoryType)) {
              newSuggestedAnswers = newSuggestedAnswers.map((item) => {
                return {
                  id: item.id,
                  title: `${item?.firstname ? item?.firstname : ''} ${item?.lastname ? item?.lastname : ''}`,
                  image_url: item?.professional_profileimage?.[0]?.url,
                  pins: professionalPinsMapper(item.pins_status),
                };
              });
            } else {
              newSuggestedAnswers = newSuggestedAnswers.map((item) => {
                return {
                  id: item.id,
                  title: item.name,
                  image_url: item?.listing_profileimage?.[0]?.url,
                  pins: questionInfo?.mainCategoryType === "movies" ? moviesPinsMapper(item.pins) : item.pins,
                };
              });
            }
            
            const updatedAnswers = pushUniqueObjectsInArray(
              suggestedAnswers,
              newSuggestedAnswers,
              "id"
            );
            setSuggestedAnswers([...updatedAnswers]);
          }
        }
      });
    }
  };

  const debounceSearchResults = debounce((value, content_type) => {
    if (value.length) {
      let url;
      if (content_type === "All") {
        url = `/api/search/?q=${value}&page=1&size=3&suggetions=true&sensor=false`;
      } else if (content_type == "people" || content_type == "People") {
        url = `/api/search/?user=${value}&page=1&sensor=false`;
      } else {
        url = `/api/search/?q=${value}&content_type=${content_type}&page=1&radius=&size=5&suggetions=true&sensor=false`;
      }
      callApi(url, "GET").then((response) => {
        if (response && responseCodeValidator(`${response.code}`)) {
          setSearchInfo((prev) => {
            return {
              ...prev,
              searchHomeData: response.results,
            };
          });
        }
      });
    } else {
      setTimeout(() => {
        setSearchInfo({
          searchHomeData: [],
          search_name: "",
          search_length: value.length,
          unableToSet: false,
        });
      }, 2000);
    }
  }, 1000);

  const handleSearchChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    // Add content type here
    const content_type = "business";

    if (name === "search_name") {
      debounceSearchResults(value, content_type);

      if (value.length) {
        setSearchInfo({
          [name]: value,
          unableToSet: true,
          search_length: value.length,
        });
      } else {
        setSearchInfo({
          searchHomeData: [],
          search_name: "",
          search_length: value.length,
          unableToSet: false,
        });
      }
    }
  };

  const renderSuggestLine = () => {
    const mainCategories = ["business", "products", "movies"];
    if (questionInfo?.category) {
      if (mainCategories.includes(questionInfo?.mainCategoryType)) {
        return `Suggested ${questionInfo.category} ${capitalizeFirstLetter(
          questionInfo.mainCategoryType
        )}`;
      } else {
        return `Suggested ${questionInfo.category}`;
      }
    }
  };

  const showToastMessage = (message) => {
    toast(message, {
      autoClose: 2500,
      className: "black-background",
      bodyClassName: "red-hunt",
      progressClassName: "cc",
    });
  };

  const addToList = (listing, listType) => {
    const listTypeMapper = {
      "recommend list": "user_recommend_status",
      "favourite list": "user_favourites_status",
      "watch list": "user_watch_status",
    };
    const isProfessionalType = professionalCategories.includes(questionInfo?.mainCategoryType)

    if (listing.pins?.[listTypeMapper[listType]]) {
      // Remove from Recommend List
      const payload = {
        type: listType,
      };
      callApi(
        `/api/${isProfessionalType ? 'professionalpins' : 'pins'}/${listing.id}/?type=${listType}`,
        "DELETE",
        isProfessionalType ? '' : payload
      ).then((response) => {
        if (response && responseCodeValidator(`${response.code}`)) {
          if (response?.msg && !response?.user_entry) {
            showToastMessage(response.msg);
          } else {
            showToastMessage(
              `${
                listing?.title ? listing.title : "Listing"
              } has been removed from your ${listType}.`
            );
            let newSuggestedAnswers = suggestedAnswers.map((item) => {
              if (item.id === listing.id) {
                return {
                  ...item,
                  pins: {
                    user_recommend_status:
                      listType === "recommend list"
                        ? false
                        : item?.pins?.user_recommend_status,
                    user_favourites_status:
                      listType === "favourite list"
                        ? false
                        : item?.pins?.user_favourites_status,
                    user_watch_status:
                      listType === "watch list"
                        ? false
                        : item?.pins?.user_watch_status,
                    user_want_status:
                      listType === "want list"
                        ? false
                        : item?.pins?.want_status,
                  },
                };
              }
              return item;
            });
            setSuggestedAnswers(newSuggestedAnswers);
          }
        }
      });
    } else {
      // Add to Recommend List
      let payload = {} 
      if (isProfessionalType) {
        payload = {
          type: listType,
          professionaluser_entry: listing.id,
        };
      } else {
        payload = {
          type: listType,
          user_entry: listing.id,
        };
      }
      
      callApi(`/api/${isProfessionalType ? 'professionalpins' : 'pins'}/?type=${listType}&user=`, "POST", payload).then(
        (response) => {
          if (response && responseCodeValidator(`${response.code}`)) {
            if (response?.msg) {
              showToastMessage(response.msg);
            } else if (response?.user_entry || response?.professionaluser_entry) {
              showToastMessage(
                `${
                  listing?.title ? listing.title : "Listing"
                } has been added to your ${listType}.`
              );
              let newSuggestedAnswers = suggestedAnswers.map((item) => {
                if (item.id === listing.id) {
                  return {
                    ...item,
                    pins: {
                      user_recommend_status:
                        listType === "recommend list"
                          ? true
                          : item?.pins?.user_recommend_status,
                      user_favourites_status:
                        listType === "favourite list"
                          ? true
                          : item?.pins?.user_favourites_status,
                      user_watch_status:
                        listType === "watch list"
                          ? true
                          : item?.pins?.user_watch_status,
                      user_want_status:
                        listType === "want list"
                          ? true
                          : item?.pins?.want_status,
                    },
                  };
                }
                return item;
              });
              setSuggestedAnswers(newSuggestedAnswers);
            }
          }
        }
      );
    }
  };

  const answerQuestion = (listing) => {
    addToList(listing, "recommend list");
    if (questionInfo?.questionId) {
      const url = `/api/questions/${questionInfo.questionId}/?entity=`
      const data = {
        [questionInfo.questionId]: "Answered",
        user_entry: 123,
        "reply": "Answered",
        tag_list: [],
        messagemedia_set: []
      }
      callApi(url, "PUT", data).then((response) => {
        if (response && responseCodeValidator(`${response?.code}`)) {
          showToastMessage("Your answer is successfully recorded. You can choose more.")
        }
      })
    }
  };

  const hideRecommendComponent = () => {
    props.hideRecommendQuestion()
  }

  return (
    <div className="top-popup _fixed-header show bg-primary" hidden={isThirdLogin ? false : true}>
      <div className="p-5 fs-16 w-75 mx-auto">
        {/* Close Button */}
        <button
          className="btn btn-sm p-0 float-right mt-n4 mr-n5 close-btn"
          aria-label="Close"
          onClick={hideRecommendComponent}
        >
          <span aria-hidden="true">X</span>
        </button>
        {/* Main Content */}
        <div>
          <div className="d-flex justify-content-between">
            {/* Question */}
            <div className="text-darker mb-3 lh-1-3">
              {questionInfo?.question}
            </div>
            {/* Search Bar */}
            <div className="position-relative" hidden={questionInfo?.question ? false : true}>
              <Form
                className="d-flex"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Link className="btn btn-sm border-right-0" to="">
                      <img
                        src={require("../../../assets/images/icons/search-btn.png")}
                        alt=""
                      />
                    </Link>
                  </InputGroupAddon>
                  <Input
                    bsSize="sm"
                    type="text"
                    placeholder={`Search ${
                      questionInfo?.category
                        ? `for ${questionInfo.category}`
                        : ""
                    }`}
                    name="search_name"
                    autoComplete="off"
                    onChange={handleSearchChange}
                  />
                </InputGroup>
              </Form>
              <div>
                {/* Instant Search Results */}
                {true ? (
                  <ul
                    className="text-left list-unstyled text-dark instant-results bg-white mw-100 shadow"
                    style={{
                      minWidth: "400px",
                      maxHeight: "400px",
                      overflowY: "auto",
                    }}
                  >
                    {/* Suggested results */}
                    {Array.isArray(searchInfo.searchHomeData) &&
                    searchInfo.searchHomeData &&
                    searchInfo.searchHomeData.length > 0 ? (
                      searchInfo.searchHomeData.map((item, index) => {
                        return (
                          <div key={index}>
                            {item._type == "taxonomy" &&
                              item._source.index == 0 && (
                                <li>
                                  <div className="p-3 fs-14">
                                    <div className="text-tertiary">
                                      {"Are you looking for:"}
                                    </div>
                                    <ul
                                      className="list-unstyled text-primary font-weight-bold"
                                      style={{
                                        maxHeight: "105px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      {Array.isArray(
                                        item._source.main_category
                                      ) &&
                                      item._source.main_category &&
                                      item._source.main_category[0].length > 0
                                        ? item._source.main_category[0][0]
                                            .split(",")
                                            .map((item2, index2) => {
                                              return (
                                                <li
                                                  key={index2}
                                                  onClick={() => {
                                                    setSearchInfo((prev) => {
                                                      return {
                                                        ...prev,
                                                        search_name: item2.replace(
                                                          /\#.+/g,
                                                          "$'"
                                                        ),
                                                      };
                                                    });
                                                  }}
                                                >
                                                  <Link
                                                    className="text-reset ff-base"
                                                    to={{
                                                      pathname: `/search`,
                                                      search: `?q=${item2.replace(
                                                        /\#.+/g,
                                                        "$'"
                                                      )}`,
                                                      state: {
                                                        contenttype: contentType.toLowerCase(),
                                                      },
                                                    }}
                                                  >
                                                    {item2.replace(
                                                      /\#.+/g,
                                                      "$'"
                                                    )}{" "}
                                                  </Link>
                                                </li>
                                              );
                                            })
                                        : null}
                                    </ul>
                                  </div>
                                </li>
                              )}
                            {/* featured results */}
                            {item._type == "listing" && (
                              <Link
                                to={{
                                  pathname: item._source?.professional_type
                                    ? `/${item._source.professional_type.toLowerCase()}/${
                                        item._source?.id
                                      }`
                                    : `/${item._source.content_type.toLowerCase()}/${
                                        item._source.slug
                                      }`,
                                  state: { id: item._source.id },
                                }}
                                onClick={() => {}}
                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
                              >
                                <li className="result-item" role="button">
                                  <div className="result-link fs-14">
                                    <div className="d-flex">
                                      <div className="mr-3">
                                        <img
                                          src={
                                            item._source &&
                                            item._source.listing_profileimage &&
                                            item._source
                                              .listing_profileimage[0] &&
                                            item._source.listing_profileimage[0]
                                              .location
                                              ? item._source
                                                  .listing_profileimage[0]
                                                  .location
                                              : "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"
                                          }
                                          width={50}
                                          alt=""
                                          onError={(error) =>
                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                          }
                                        />
                                      </div>
                                      <div>
                                        <span className="font-weight-bold">
                                          {item._source
                                            ? item._source.name
                                            : ""}
                                        </span>
                                        <div>
                                          <img
                                            src={
                                              item._source?.total_rating &&
                                              item._source?.total_rating
                                                ?.avg_rating
                                                ? `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item._source?.total_rating?.avg_rating[0]}`
                                                : `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png`
                                            }
                                            alt=""
                                            title={
                                              item._source?.total_rating &&
                                              item._source?.total_rating
                                                ?.rating_count
                                                ? item._source?.total_rating
                                                    ?.rating_count
                                                : 0
                                            }
                                          />
                                        </div>
                                        <div className="font-weight-normal lh-1-2">
                                          {item._source &&
                                          item._source.category != null
                                            ? item._source.category
                                                .split(",")
                                                .join(", ")
                                            : ""}
                                          {/* <span>{item._source && item._source.category != null ? item._source.category : ""}</span> */}
                                        </div>
                                        <div className="text-tertiary font-weight-normal mt-1 ff-alt fs-12">
                                          {item?._source?.address?.length
                                            ? `${
                                                item?._source?.address[0] &&
                                                item?._source?.address[0]
                                                  .address1
                                                  ? item?._source?.address[0]
                                                      .address1 + ","
                                                  : ""
                                              } ${
                                                item?._source?.address[0] &&
                                                item?._source?.address[0]
                                                  .address2
                                                  ? item?._source?.address[0]
                                                      .address2 + ","
                                                  : ""
                                              }`
                                            : null}
                                          <br />
                                          {item?._source?.address?.length
                                            ? `${
                                                item?._source?.address[0] &&
                                                item?._source?.address[0].city
                                                  ? item?._source?.address[0]
                                                      .city + ","
                                                  : ""
                                              } ${
                                                item?._source?.address[0] &&
                                                item?._source?.address[0].state
                                                  ? item?._source?.address[0]
                                                      .state + ","
                                                  : ""
                                              } ${
                                                item?._source?.address[0] &&
                                                item?._source?.address[0]
                                                  .zipcode
                                                  ? item?._source?.address[0]
                                                      .zipcode
                                                  : ""
                                              }`
                                            : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </Link>
                            )}

                            {/* featured results */}
                            {item._type == "user" && (
                              <Link
                                to={{
                                  pathname: `/people/${item._source.user?.username}`,
                                  state: { id: item._source._id },
                                }}
                                onClick={() => {}}
                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none"
                              >
                                <li className="result-item" role="button">
                                  <div className="result-link fs-14">
                                    <div className="d-flex">
                                      <div className="mr-3">
                                        <img
                                          src={
                                            item._source &&
                                            item._source.current_profile_pic
                                              ? item._source.current_profile_pic
                                              : item._source
                                                  .current_profile_pic &&
                                                item._source.current_profile_pic
                                                  .length > 0
                                              ? item._source.current_profile_pic
                                                  .thumbnail
                                              : require("../../../assets/images/icons/placeholder-img.png")
                                          }
                                          width={50}
                                          onError={(error) =>
                                            (error.target.src = `require('../../../assets/images/icons/placeholder-img.png')`)
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div>
                                        <span className="font-weight-bold">
                                          {item._source
                                            ? item._source.name
                                            : ""}
                                        </span>

                                        <div className="font-weight-normal fs-12 ff-alt text-tertiary">
                                          <br />
                                          <span>
                                            {item._source &&
                                            item._source?.statistics
                                              ? item._source.statistics.reviews
                                              : "0"}{" "}
                                            reviews
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </Link>
                            )}
                          </div>
                        );
                      })
                    ) : searchInfo.search_length &&
                      searchInfo.search_length > 0 ? (
                      <div className="pl-3" style={{ minHeight: "70px" }}>
                        No result found
                      </div>
                    ) : (
                      ""
                    )

                    // </ul>
                    }
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {/* Add Answer Tag Line */}
          {questionInfo?.category && currentLocation?.address_text ? (
            <div className="text-dark mt-2">
              <b>{renderSuggestLine()}</b> in
              <img
                src={locationImg}
                alt="Location Marker"
                width="40px"
                className=""
              />
              <span>
                {currentLocation?.address_text
                  ? currentLocation?.address_text
                  : ""}
              </span>
            </div>
          ) : (
            ""
          )}

          <div className="recommend-carousel">
            <Carousel
              responsive={responsive.viewGalleryImages}
              infinite={false}
              autoPlay={false}
              swipeable={true}
              showDots={false}
              arrows={true}
              sliderClass={""}
              slidesToSlide={1}
              afterChange={(
                previousSlide,
                { currentSlide, slidesToShow, totalItems }
              ) => {
                if (totalItems - slidesToShow === currentSlide) {
                  fetchMoreAnswers();
                }
              }}
            >
              {suggestedAnswers && Array.isArray(suggestedAnswers) ? (
                [...suggestedAnswers, { type: "loader" }].map((item, index) => {
                  return (
                    <div key={index} className="mt-2 mb-3">
                      {item?.type !== "loader" ? (
                        <div>
                          <Card className="bg-transparent">
                            <div className="flip-card-recommend">
                              <div className="flip-card-inner-recommend">
                                <div className="flip-card-front-recommend d-flex justify-content-center">
                                  <div className="my-auto">
                                    <CardImg
                                      className="img img-fluid"
                                      width="80%"
                                      style={{ maxHeight: "160px" }}
                                      src={
                                        item.image_url
                                          ? item.image_url
                                          : require("../../../assets/images/icons/placeholder-img-alt.jpg")
                                      }
                                      alt={item.title}
                                      onError={(error) =>
                                        (error.target.src = require("../../../assets/images/icons/placeholder-img-alt.jpg"))
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="flip-card-back-recommend d-flex flex-column justify-content-center">
                                  <div className="my-auto bg-black">
                                    <CardImg
                                      className="img img-fluid dark-overlay"
                                      width="80%"
                                      height="100%"
                                      style={{ maxHeight: "160px" }}
                                      src={
                                        item.image_url
                                          ? item.image_url
                                          : require("../../../assets/images/icons/placeholder-img-alt.jpg")
                                      }
                                      alt={item.title}
                                      onError={(error) =>
                                        (error.target.src = require("../../../assets/images/icons/placeholder-img-alt.jpg"))
                                      }
                                    />
                                    <div className="d-flex flex-column">
                                      {/* Add Recommended Condition HERE */}
                                      {!item?.pins?.user_recommend_status ? (
                                        <div
                                          role="button"
                                          className="recommend-action"
                                          onClick={() => {
                                            answerQuestion(item);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faThumbsUp}
                                            color="white"
                                            className="fs-28"
                                          />
                                          <div className="fs-16 text-primary mt-2">
                                            <b>Recommend This</b>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          role="button"
                                          className="recommend-action"
                                          onClick={() => {
                                            answerQuestion(item);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faThumbsUpSolid}
                                            color="white"
                                            className="fs-28"
                                          />
                                          <div className="fs-16 text-white mt-2">
                                            <b>Added</b>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <CardBody className="p-0 text-center">
                              {/* Add Recommended Condition HERE */}
                              <CardTitle
                                className={`center fs-14 font-weight-bold mb-2 px-2 ${
                                  item?.pins?.user_recommend_status
                                    ? "text-darker"
                                    : "text-dark"
                                }`}
                              >
                                {item?.title}
                                {/* Add Recommended Condition HERE */}
                                {item?.pins?.user_recommend_status ? (
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    color="white"
                                    className="ml-2"
                                  />
                                ) : (
                                  ""
                                )}
                              </CardTitle>
                              {/* Add Recommended Condition HERE */}
                              {item?.pins?.user_recommend_status ? (
                                <div>
                                  <ul className="px-3 mt-3 fs-14">
                                    {/* Check if already added to favorites */}
                                    {item?.pins?.user_favourites_status ? (
                                      <li
                                        className="list-style-none border-top border-dark py-2 d-flex align-items-center cursor-pointer"
                                        onClick={() => {
                                          addToList(item, "favourite list");
                                        }}
                                      >
                                        <img
                                          src={require("../../../assets/images/icons/want-list.png")}
                                          alt="Favorite List"
                                          width="16px"
                                          className="mr-2"
                                        />
                                        <span>added!</span>
                                      </li>
                                    ) : (
                                      <li
                                        className="list-style-none border-top border-dark py-2 d-flex align-items-center cursor-pointer"
                                        onClick={() => {
                                          addToList(item, "favourite list");
                                        }}
                                      >
                                        <img
                                          src={require("../../../assets/images/icons/want-list-other.png")}
                                          alt="Favorite List"
                                          width="16px"
                                          className="mr-2"
                                        />
                                        <span>add to Favorites</span>
                                      </li>
                                    )}
                                    {/* Check if already added to Watch List */}
                                    {item?.pins?.user_watch_status ? (
                                      <li
                                        className="list-style-none border-top border-dark py-2 d-flex align-items-center cursor-pointer"
                                        onClick={() => {
                                          addToList(item, "watch list");
                                        }}
                                      >
                                        <img
                                          src={require("../../../assets/images/icons/watchlist.png")}
                                          alt="Watch List"
                                          width="16px"
                                          className="mr-2"
                                        />
                                        <span>added!</span>
                                      </li>
                                    ) : (
                                      <li
                                        className="list-style-none border-top border-dark py-2 d-flex align-items-center cursor-pointer"
                                        onClick={() => {
                                          addToList(item, "watch list");
                                        }}
                                      >
                                        <img
                                          src={require("../../../assets/images/icons/watchlist-other.png")}
                                          alt="Watch List"
                                          width="16px"
                                          className="mr-2"
                                        />
                                        <span>add to Watchlist</span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                            </CardBody>
                          </Card>
                        </div>
                      ) : nextPage ? (
                        <Card className="bg-transparent">
                          <div className="flip-card-recommend">
                            <div className="flip-card-inner-normal d-flex justify-content-center align-items-center">
                              <div
                                className="d-flex justify-content-center align-items-center bg-white"
                                style={{ width: "70%", height: "55%" }}
                              >
                                <div className="my-auto">
                                  <CardImg
                                    className="img img-fluid"
                                    width="80%"
                                    src={require("../../../assets/images/icons/wiki-blue.png")}
                                    alt={item.title}
                                    onError={(error) =>
                                      (error.target.src = require("../../../assets/images/icons/wiki-blue.png"))
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <CardBody className="p-0 text-center">
                            <CardTitle className="center fs-14 font-weight-bold mb-2 px-2 text-dark">
                              Loading More
                              <br /> Results...
                            </CardTitle>
                          </CardBody>
                        </Card>
                      ) : !loadingSuggestions ? (
                        <Card className="bg-transparent">
                          <div className="flip-card-recommend">
                            <div className="flip-card-inner-normal d-flex justify-content-center align-items-center">
                              <div className="my-auto">
                                <h2>
                                  Can't find your preference? Try searching...
                                </h2>
                              </div>
                            </div>
                          </div>
                        </Card>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })
              ) : loadingSuggestions ? (
                <div className="mt-4">
                  <h3>Loading...</h3>
                </div>
              ) : (
                <div className="mt-4">
                  <h3>No Nearby Results Found!</h3>
                </div>
              )}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendQuestions;
