/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvField ,AvForm} from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from "react-redux";
import { Button, Col, Container, InputGroup, InputGroupAddon, Popover, PopoverBody, PopoverHeader, Row } from 'reactstrap';
import { add_media_to_gallery, get_media_children } from '../../../actions/business';
import { add_media_to_item_gallery, add_social_website, delete_listing_Info, delete_pdf, get_uploaded_media, update_detail, update_PDF_cut_sheet } from '../../../actions/itemDetail';
import { delete_selected_gallery_media, get_album_types_list, get_album_type_data, get_my_privacy_list } from '../../../actions/user';
import { getReviewSuffix } from '../../../utils/utilities';
import UploadMedia from '../../atoms/fileUploadMedia';


class About extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			about: null,
			tempIcon: null,
			editName: false,
			phoneSet: [],
			editPhoneNumber: false,
			editPhoneNumberLabel: false,
			emailSet: [],
			addEmail: false,
			editEmail: false,
			websiteSet: [],
			addWebsite: false,
			editWebsite: false,
			phoneLabel: 'Tel',
			editProfileName: null,
			editProfileNameIsValid: false,
			phone: null,
			phoneIsValid: false,
			phoneLabelIsValid: false,
			newEmail: null,
			newEmailIsValid: false,
			email: null,
			emailIsValid: false,
			newWebsite: null,
			newWebsiteIsValid: false,
			pageNo: 1,
			readMore: false,
			prodId: 0,
			modelSet: null,
			addModel: false,
			editModel: false,
			newModel: null,
			newModelIsValid: false,
			model: null,
			modelIsValid: false,
			minPriceSet: [],
			addMinPrice: false,
			editMinPrice: false,
			newMinPrice: null,
			newMinPriceIsValid: false,
			minPrice: null,
			minPriceIsValid: false,
			maxPriceSet: [],
			addMaxPrice: false,
			editMaxPrice: false,
			newMaxPrice: null,
			newMaxPriceIsValid: false,
			maxPrice: null,
			maxPriceIsValid: false,
			manufactureCutSheet: [],
			pdfManufactureCutSheet: [],
			addManCutSheet: false,
			editManCutSheet: false,
			newManCutSheet: null,
			newManCutSheetIsValid: false,
			manCutSheetPrice: null,
			manCutSheetIsValid: false,
			packedWidth: "",
			addPackedWidth: false,
			editPackedWidth: false,
			newPackedWidth: null,
			newPackedWidthIsValid: false,
			packedWidthValue: null,
			packedWidthIsValid: false,
			packedHeight: "",
			addPackedHeight: false,
			editPackedHeight: false,
			newPackedHeight: null,
			newPackedHeightIsValid: false,
			packedHeightValue: null,
			packedHeightIsValid: false,
			packedDepth: "",
			addPackedDepth: false,
			editPackedDepth: false,
			newPackedDepth: null,
			newPackedDepthIsValid: false,
			packedDepthValue: null,
			packedDepthIsValid: false,
			assembWidth: "",
			addAssembWidth: false,
			editAssembWidth: false,
			newAssembWidth: null,
			newAssembWidthIsValid: false,
			assembWidthValue: null,
			assembWidthIsValid: false,
			assembHeight: "",
			addAssembHeight: false,
			editAssembHeight: false,
			newAssembHeight: null,
			newAssembHeightIsValid: false,
			assembHeightValue: null,
			assembHeightIsValid: false,
			assembDepth: "",
			addAssembDepth: false,
			editAssembDepth: false,
			newAssembDepth: null,
			newAssembDepthIsValid: false,
			assembDepthValue: null,
			assembDepthIsValid: false,
			assembWeight: "",
			addAssembWeight: false,
			editAssembWeight: false,
			newAssembWeight: null,
			newAssembWeightIsValid: false,
			assembWeightValue: null,
			assembWeightIsValid: false,
			packedWeight: "",
			addPackedWeight: false,
			editPackedWeight: false,
			newPackedWeight: null,
			newPackedWeightIsValid: false,
			packedWeightValue: null,
			packedWeightIsValid: false,
			manuWarranty: "",
			addManuWarranty: false,
			editManuWarranty: false,
			newManuWarranty: null,
			newManuWarrantyIsValid: false,
			manuWarrantyValue: null,
			manuWarrantyIsValid: false,
			featuresSet: "",
			addManufacturer: false,
			editManufacturer: false,
			newManufacturer: null,
			newManufacturerIsValid: false,
			manufacturerValue: null,
			manufacturerIsValid: false,
			manufacturer: "",
			uploadedMediaSet: {
				embedLinks: [],
				uploadedFiles: [],
			},
			uploadUrl: `/upload/multiuploader/?album=&instance=listing_images&image=undefined&doc=undefined`,
			uploadedFiles: [],
			minPriceError: "",
			maxPriceError: "",
			get_temp: null,
			pdfCutSheet: null
		}
	}


	async componentWillReceiveProps(nextProps) {
		const { aboutDetails, productId } = nextProps;
		if (aboutDetails) {
			this.setState({
				about: aboutDetails,
				phoneSet: aboutDetails?.phone_set,
				emailSet: aboutDetails?.email_set,
				websiteSet: aboutDetails?.website_set,
				modelSet: aboutDetails?.model_number && aboutDetails?.model_number != null ? aboutDetails?.model_number : "",
				minPriceSet: aboutDetails?.additional_info?.price_min ? aboutDetails?.additional_info?.price_min : [],
				maxPriceSet: aboutDetails?.additional_info?.price_max ? aboutDetails?.additional_info?.price_max : [],
				manufactureCutSheet: aboutDetails?.additional_info?.manufacturer_cut_sheet ? aboutDetails?.additional_info?.manufacturer_cut_sheet : [],
				packedWidth: aboutDetails?.additional_info?.packed_width ? aboutDetails?.additional_info?.packed_width[0].value : "",
				packedHeight: aboutDetails?.additional_info?.packed_height ? aboutDetails?.additional_info?.packed_height[0].value : "",
				packedDepth: aboutDetails?.additional_info?.packed_depth ? aboutDetails?.additional_info?.packed_depth[0].value : "",
				assembWidth: aboutDetails?.additional_info?.assembled_width ? aboutDetails?.additional_info?.assembled_width[0].value : "",
				assembHeight: aboutDetails?.additional_info?.assembled_height ? aboutDetails?.additional_info?.assembled_height[0].value : "",
				assembDepth: aboutDetails?.additional_info?.assembled_depth ? aboutDetails?.additional_info?.assembled_depth[0].value : "",
				assembWeight: aboutDetails?.additional_info?.assembled_weight ? aboutDetails?.additional_info?.assembled_weight[0].value : "",
				packedWeight: aboutDetails?.additional_info?.packed_weight ? aboutDetails?.additional_info?.packed_weight[0].value : "",
				manuWarranty: aboutDetails?.additional_info?.manufacturer_warranty ? aboutDetails?.additional_info?.manufacturer_warranty[0].value : "",
				featuresSet: aboutDetails?.features_set ? aboutDetails?.features_set : "",
				manufacturer: aboutDetails?.manufacturer ? aboutDetails?.manufacturer : "",
				pdfManufactureCutSheet: aboutDetails?.document_info?.length ? aboutDetails.document_info: [],
			});
		}
		if (productId) {
			this.setState({
				prodId: productId,
			});
		}
		if (nextProps.my_privacy_setting && nextProps.my_privacy_setting.results && Array.isArray(nextProps.my_privacy_setting.results) && nextProps.my_privacy_setting.results.length > 0) {

			let value = nextProps.my_privacy_setting.results?.[13]?.value;
            this.setState({ get_temp: value })
        }
	}
	componentDidMount() {
        this.props.get_my_privacy_list();
	}

	toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
    }

	getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet } = this.state;


        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
            uploadedMediaSet['uploadedFiles'] = uploadedMediaSet['uploadedFiles'].concat(mediaSet);
        }

        if (links) {
            uploadedMediaSet['embedLinks'] = uploadedMediaSet['embedLinks'].concat(links);
        }
        this.setState({ uploadedMediaSet }, () => {
            this.props.get_uploaded_media(uploadedMediaSet);
        });
    }

	deletePDFCutSheet = (data) => {
		const {manufactureCutSheet, prodId} = this.state;
			let dataManuCut = {}
			dataManuCut.additional_info = {}
			dataManuCut.additional_info.key = 'manufacturer_cut_sheet';
			dataManuCut.additional_info.oldVal = manufactureCutSheet && manufactureCutSheet.length > 0 ? manufactureCutSheet[0].value : "";
			let types = "products";
			if (this.state.pdfCutSheet) {
				dataManuCut.additional_info.newVal = this.state.pdfCutSheet;
				const formData = new FormData();
				formData.append('file', this.state.pdfCutSheet);
				if (this.state.pdfManufactureCutSheet.length) {
					const pdfIds = this.state.pdfManufactureCutSheet.map(p => p.id);
					this.props.delete_pdf(pdfIds, true, types, prodId, formData, 'Manufacturer Cut Sheet Updated Successfully')
				}
				this.setState({
					pdfCutSheet: false
				})
			} else {
				dataManuCut.additional_info.newVal = data ? data : 'Not Applicable';
				if (this.state.pdfManufactureCutSheet.length) {
					const pdfIds = this.state.pdfManufactureCutSheet.map(p => p.id);
					this.props.delete_pdf(pdfIds, false, types, prodId, dataManuCut, 'Manufacturer Cut Sheet Updated Successfully')
				}
			}
	}


	handleFieldsChange = (type, data) => {

		const { phoneSet, prodId, minPriceSet, maxPriceSet, manufactureCutSheet,pdfManufactureCutSheet, manuWarranty, packedDepth,
			packedHeight, packedWeight, packedWidth, assembDepth, assembHeight, assembWeight, assembWidth } = this.state
		if (prodId && prodId != 0) {
			if (type === 'phone') {
				if (phoneSet.length > 0) {
					const phoneDetails = phoneSet[0]
					const phoneData = {}
					phoneData.phone_set = {};
					phoneData.phone_set.phone = data;
					phoneData.phone_set.id = phoneDetails.id;
					phoneData.phone_set.oldValue = phoneDetails.phone;

					let types = "products";
					this.props.update_detail(types, prodId, phoneData, 'Mobile number updated successfully')
				} else {

					const phoneData = {}
					phoneData.phone_set = {};
					phoneData.phone_set.phone = data;
					let types = "products";
					this.props.update_detail(types, prodId, phoneData, 'Mobile number added successfully')
				}
			}
			else if (type === 'editProfileName') {
				const name = {
					"name": data,
				}
				let types = "products";
				this.props.update_detail(types, prodId, name, 'Name updated successfully')

			} else if (type === 'addManufacturer' || type === 'editManufacturer') {
				const manufacturer = {
					"manufacturer": data,
				}
				let types = "products";
				this.props.update_detail(types, prodId, manufacturer, 'Manufacturer updated successfully')

			}
			else if (type === 'phoneLabel') {
				const phoneDetails = phoneSet[0]
				/*const phone_type = {
					"label": data,
					"id": phoneDetails.id,
					"oldValue": phoneDetails.label
				}*/
				const phoneData = {}
				phoneData.phone_type = {};
				phoneData.phone_type.label = data;
				phoneData.phone_type.id = phoneDetails.id;
				//phoneData.phone_type.oldValue = phoneDetails.label;

				let types = "products";
				this.props.update_detail(types, prodId, phoneData, 'Mobile Label Updated Successfully')

			} else if (type === 'addWebsite') {
				const website_set = {
					"website": data,
					"website_type": "Main"
				}
				let types = "products";
				this.props.update_detail(types, prodId, { website_set }, 'Website Added Successfully')
			} else if (type === 'addModel') {
				const modelnumber = {
					"model_number": data
				}
				let types = "products";
				this.props.update_detail(types, prodId, modelnumber, 'Model Number Added Successfully')
			} else if (type === 'editModel') {
				const modelnumber = {
					"model_number": data
				}
				let types = "products";
				this.props.update_detail(types, prodId, modelnumber, 'Model Number Updated Successfully')
			} else if (type === 'addMinPrice' || type == 'editMinPrice') {

				if (maxPriceSet.length > 0 && maxPriceSet[0].value != "" && parseInt(data) >= parseInt(maxPriceSet[0].value)) {
					this.setState({ maxPriceError: "Max Price Should not be less than or equal to Min Price" })
				} else {
					let dataPriceMin = {}
					dataPriceMin.additional_info = {}
					dataPriceMin.additional_info.key = 'price_min';
					dataPriceMin.additional_info.oldVal = minPriceSet && minPriceSet.length > 0 ? minPriceSet[0].value : "";
					dataPriceMin.additional_info.newVal = data;
					let types = "products";
					this.props.update_detail(types, prodId, dataPriceMin, 'Min Price Updated Successfully')
				}
			} else if (type === 'addMaxPrice' || type == 'editMaxPrice') {
				if (minPriceSet.length > 0 && minPriceSet[0].value != "" && parseInt(data) <= parseInt(minPriceSet[0].value)) {
					this.setState({ maxPriceError: "Max Price Should not be less than or equal to Min Price" })
				} else {
					let dataPriceMax = {}
					dataPriceMax.additional_info = {}
					dataPriceMax.additional_info.key = 'price_max';
					dataPriceMax.additional_info.oldVal = maxPriceSet && maxPriceSet.length > 0 ? maxPriceSet[0].value : "";
					dataPriceMax.additional_info.newVal = data;
					let types = "products";
					this.props.update_detail(types, prodId, dataPriceMax, 'Max Price Updated Successfully')
				}
			} else if (type === 'addManCutSheet' || type == 'editManCutSheet') {
				let dataManuCut = {}
				dataManuCut.additional_info = {}
				dataManuCut.additional_info.key = 'manufacturer_cut_sheet';
				dataManuCut.additional_info.oldVal = manufactureCutSheet && manufactureCutSheet.length > 0 ? manufactureCutSheet[0].value : "";
				let types = "products";
				if (this.state.pdfCutSheet) {
					dataManuCut.additional_info.newVal = this.state.pdfCutSheet;
					const formData = new FormData();
					formData.append('file', this.state.pdfCutSheet);
					if (this.state.pdfManufactureCutSheet.length) {
						const pdfIds = this.state.pdfManufactureCutSheet.map(p => p.id);
						this.props.delete_pdf(pdfIds, true, types, prodId, formData, 'Manufacturer Cut Sheet Updated Successfully')
					} else {
						this.props.update_PDF_cut_sheet(types, prodId, formData, 'Manufacturer Cut Sheet Updated Successfully')
					}
					this.setState({
						pdfCutSheet: false
					})
				} else {
					dataManuCut.additional_info.newVal = data;
					if (this.state.pdfManufactureCutSheet.length) {
						const pdfIds = this.state.pdfManufactureCutSheet.map(p => p.id);
						this.props.delete_pdf(pdfIds, false, types, prodId, dataManuCut, 'Manufacturer Cut Sheet Updated Successfully')
					} else {
						this.props.update_detail(types, prodId, dataManuCut, 'Manufacturer Cut Sheet Updated Successfully')
					}
				}
			} else if (type === 'addManuWarranty' || type == 'editManuWarranty') {
				let dataManuWarranty = {}
				dataManuWarranty.additional_info = {}
				dataManuWarranty.additional_info.key = 'manufacturer_warranty';
				dataManuWarranty.additional_info.oldVal = manuWarranty && manuWarranty != "" ? manuWarranty : "";
				dataManuWarranty.additional_info.newVal = data;
				let types = "products";
				this.props.update_detail(types, prodId, dataManuWarranty, 'Manufacturer Warranty Updated Successfully')
			} else if (type === 'addPackedWidth' || type == 'editPackedWidth') {

				let dataPackedWidth = {}
				dataPackedWidth.additional_info = {}
				dataPackedWidth.additional_info.key = 'packed_width';
				dataPackedWidth.additional_info.oldVal = packedWidth;
				dataPackedWidth.additional_info.newVal = data;
				let types = "products";
				this.props.update_detail(types, prodId, dataPackedWidth, 'Product Packed Width Updated Successfully')
			} else if (type === 'addPackedHeight' || type == 'editPackedHeight') {
				let dataPackedHeight = {}
				dataPackedHeight.additional_info = {}
				dataPackedHeight.additional_info.key = 'packed_height';
				dataPackedHeight.additional_info.oldVal = packedHeight;
				dataPackedHeight.additional_info.newVal = data;
				let types = "products";
				this.props.update_detail(types, prodId, dataPackedHeight, 'Product Packed Height Updated Successfully')
			} else if (type === 'addPackedDepth' || type == 'editPackedDepth') {
				let dataPackedDepth = {}
				dataPackedDepth.additional_info = {}
				dataPackedDepth.additional_info.key = 'packed_depth';
				dataPackedDepth.additional_info.oldVal = packedDepth;
				dataPackedDepth.additional_info.newVal = data;
				let types = "products";
				this.props.update_detail(types, prodId, dataPackedDepth, 'Product Packed Depth Updated Successfully')
			} else if (type === 'addAssembWidth' || type == 'editAssembWidth') {
				let dataAssembWidth = {}
				dataAssembWidth.additional_info = {}
				dataAssembWidth.additional_info.key = 'assembled_width';
				dataAssembWidth.additional_info.oldVal = assembWidth;
				dataAssembWidth.additional_info.newVal = data;
				let types = "products";
				this.props.update_detail(types, prodId, dataAssembWidth, 'Product Assembled Width Updated Successfully')
			} else if (type === 'addAssembDepth' || type == 'editAssembDepth') {
				let dataAssembDepth = {}
				dataAssembDepth.additional_info = {}
				dataAssembDepth.additional_info.key = 'assembled_depth';
				dataAssembDepth.additional_info.oldVal = assembDepth;
				dataAssembDepth.additional_info.newVal = data;
				let types = "products";
				this.props.update_detail(types, prodId, dataAssembDepth, 'Product Assembled Depth Updated Successfully')
			} else if (type === 'addAssembHeight' || type == 'editAssembHeight') {
				let dataAssembHeight = {}
				dataAssembHeight.additional_info = {}
				dataAssembHeight.additional_info.key = 'assembled_height';
				dataAssembHeight.additional_info.oldVal = assembHeight;
				dataAssembHeight.additional_info.newVal = data;
				let types = "products";
				this.props.update_detail(types, prodId, dataAssembHeight, 'Product Assembled Height Updated Successfully')
			} else if (type === 'addAssembDepth' || type == 'editAssembDepth') {
				let dataAssembWidth = {}
				dataAssembWidth.additional_info = {}
				dataAssembWidth.additional_info.key = 'assembled_width';
				dataAssembWidth.additional_info.oldVal = assembWidth;
				dataAssembWidth.additional_info.newVal = data;
				let types = "products";
				this.props.update_detail(types, prodId, dataAssembWidth, 'Product Assembled Width Updated Successfully')
			} else if (type === 'addPackedWeight' || type == 'editPackedWeight') {
				let dataPackedWeight = {}
				let message = ""
				dataPackedWeight.additional_info = {}
				dataPackedWeight.additional_info.key = 'packed_weight';
				dataPackedWeight.additional_info.oldVal = packedWeight;
				dataPackedWeight.additional_info.newVal = data;
				let types = "products";
				if (type === 'addPackedWeight') {
					message = "Product Weight Added Successfully";
				} else {
					message = "Product Packed Weight Updated Successfully";
				}
				this.props.update_detail(types, prodId, dataPackedWeight, message)
			} else if (type === 'addAssembWeight' || type == 'editAssembWeight') {
				let dataAssembWeight = {}
				let message = ""
				dataAssembWeight.additional_info = {}
				dataAssembWeight.additional_info.key = 'assembled_weight';
				dataAssembWeight.additional_info.oldVal = assembWeight;
				dataAssembWeight.additional_info.newVal = data;
				let types = "products";
				if (type === 'addAssembWeight') {
					message = "Product Assembled Weight Added Successfully";
				} else {
					message = "Product Assembled Weight Updated Successfully";
				}
				this.props.update_detail(types, prodId, dataAssembWeight, message)
			}
		}

	}

	/**
	 * Function to phone number format
	 *
	 */

	formatPhoneNumber = (phoneNumberString) => {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
		var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3]
		}
		return phoneNumberString;
	}

	handleChange = async (evt, data) => {
		if (data) {
			await this.setState({
				...this.state,
				[evt.target.name]: evt.target.value,
				[data]: evt.target.validity.valid,
				maxPriceError: ""
			});
		}
		else {
			await this.setState({
				[evt.target.name]: evt.target.value,
				maxPriceError: ""
			});
		}
	}

	handleAddMediaToGallery = (temp) => {
		const {uploadedFiles}=this.state.uploadedMediaSet;
		
		let multiuploaderfile = [...uploadedFiles].map(item => item.id);
		
		//let url = `/upload/list/all/?category=&media_type=&page=1&listing=${this.state.prodId}`;
		let data = {
			type: "media",
			entries: this.state.prodId,
			userentries_id: this.state.prodId,
			taxonomy_id: "",
			multiuploaderfile: multiuploaderfile,
			galleryType: 'products'
		};
		if (uploadedFiles.length > 0) {
			let types = "products";
			this.props.add_media_to_item_gallery(data, types, this.state.prodId);
			this.setState({
				uploadedMediaSet: {
					embedLinks: [],
					uploadedFiles: []
				}
			})
			// await this.setState({
			// 	uploadMediaPostModal: false,
			// 	uploadMedia: {
			// 		...this.state.uploadMedia,
			// 		selectedMedia: [],
			// 		selectedMediaIds: [],
			// 		uploadFiles: [],
			// 		uploadedFiles: []
			// 	},
			// 	uploadedFiles: []
			// }, () => {

			// });

		}
	}

	getManufactureCutSheetRender() {
		const {
			manufactureCutSheet,
			pdfManufactureCutSheet,
		} = this.state;

		return 	pdfManufactureCutSheet?.length ? 
			<a href={pdfManufactureCutSheet[0].url} target="_blank" rel="noreferrer"
			onClick={(event)=>{
				window.open(pdfManufactureCutSheet[0].url, "_blank")
				event.stopPropagation();
				event.preventDefault();
			}}
			>{pdfManufactureCutSheet[0].filename}</a>
			:
			<span>{manufactureCutSheet?.length && manufactureCutSheet[0]?.value ? manufactureCutSheet[0]?.value.substring(0, 25) : ""}</span>
	}

	render() {
		const {
			about,
			phoneSet,
			modelSet,
			minPriceSet,
			maxPriceSet,
			manufactureCutSheet,
			packedWidth,
			packedHeight,
			packedDepth,
			assembWidth,
			assembHeight,
			assembDepth,
			assembWeight,
			packedWeight,
			manuWarranty,
			featuresSet,
			manufacturer,
			uploadUrl,
			get_temp,
			isEnableFileUpload

		} = this.state;
		let avg_rating = about && about.total_rating && about.total_rating.avg_rating?.[0] ? about.total_rating.avg_rating?.[0] : 'norating.png';
		return (
			<div className="text-center">
				{isEnableFileUpload ?
                    <UploadMedia
                        getUploadedMedia={this.getUploadedMedia}
                        uploadedUrl={uploadUrl}
                        hideEmbed={this.state.hideEmbed}
                        callbackFunction={(temp)=>this.handleAddMediaToGallery(temp)}
                        typeUpload={"Products"}
                        toggleEnableFileUpload={this.toggleEnableFileUpload}

                    />
                    : null}
				<Container className="position-relative">
					<div className={'text-center position-relative about-view ' + `${!this.state.toggleMoreLessInfo ? '_mini' : ''}`}>
						<div className="wr-movie-top-about d-flex flex-column">
							<div className="wr-movie-top-about-body">
								<Row>
									<Col>
										<div className="wr-movie-product-info-top d-flex flex-wrap">

											<div className="wr-movie-product-info-top-left position-relative">
												{about && about.listing_profileimage && about.listing_profileimage[0] && about.listing_profileimage[0].url ? (
													<img
														src={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].url}`}
														className="img-fluid"
														onError={(error) =>
															(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
														}
														alt={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].caption ? about.listing_profileimage[0].caption : 'no-image'}`}
													/>
												) :
													(<img src="https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"
														className="img-fluid"
														alt='no-image' />)

												}
												{
													about && about.listing_profileimage && about.listing_profileimage[0] && about.listing_profileimage[0].url ? "" :
														<div
															className="absolute-100-100 flex-center hover-bg-light"
															role="button"
															onClick={() => {
																this.toggleEnableFileUpload(false, true);
															}}

														>
															<div className="text-center text-dark font-weight-bold fs-14">
																{'Upload an Image'}
															</div>
														</div>
												}
											</div>
											<div className="wr-movie-product-info-top-right">
												<div className="wr-category-heading">
													{
														this.state.editname ?
															<div>
																<AvForm>
																	<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																		<AvField type="text"
																			bsSize="sm"
																			placeholder="Enter Name"
																			name="editProfileName"
																			value={this.state.editProfileName}
																			onChange={(evt) => this.handleChange(evt, 'editProfileNameIsValid')}
																			validate={{
																				required: { value: true, errorMessage: 'Please enter a name' },
																			}}
																		/>
																		<InputGroupAddon addonType="append">
																			<Button color="light" disabled={this.state.editProfileNameIsValid ? false : true}
																				onClick={() => {
																					this.setState({
																						editname: false
																					});
																					this.handleFieldsChange('editProfileName', this.state.editProfileName)
																				}}>
																				<FontAwesomeIcon icon="arrow-right" fixedWidth />
																			</Button>
																			<Button color="light" title="Cancel" className="mx-0"
																				onClick={() => {
																					this.setState({
																						editname: false,
																						editProfileName: about && about.name
																					})
																				}}
																			>
																				<FontAwesomeIcon icon="times" fixedWidth />
																			</Button>
																		</InputGroupAddon>
																	</InputGroup>
																</AvForm>
															</div>
															:
															<h4 className="fs-14 font-weight-bold ff-alt lh-1-5 editable-column"
																onClick={() => {
																	this.setState({
																		editname: true,
																		editProfileName: about && about.name
																	})
																}}
															>{about && about.name}</h4>
													}
													{(this.state.manufacturer == "" || this.state.manufacturer == null) ?
														this.state.addManufacturer === false ?
															<span className="fs-14 font-weight-bold editable-column"
																onClick={() => {
																	this.setState({
																		addManufacturer: true
																	})
																}}
															>Click to add</span>
															:
															<AvForm>
																<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																	<AvField
																		type="text"
																		bsSize="sm"
																		placeholder="Enter manufacturer"
																		name="newManufacturer"
																		value={this.state.newManufacturer}
																		onChange={(evt) => this.handleChange(evt, 'newManufacturerIsValid')}
																		validate={{
																			required: { value: true, errorMessage: 'Please enter Manufacturer' }
																		}}
																	/>
																	<InputGroupAddon addonType="append">
																		<Button color="light" title="Update"
																			disabled={this.state.newManufacturerIsValid ? false : true}
																			onClick={() => {
																				this.setState({
																					addManufacturer: false
																				});
																				this.handleFieldsChange('addManufacturer', this.state.newManufacturer)
																			}}
																		>
																			<FontAwesomeIcon icon="check" fixedWidth />
																		</Button>
																		<Button color="light" title="Cancel" className="mx-0"
																			onClick={() => {
																				this.setState({
																					addManufacturer: false
																				})
																			}}
																		>
																			<FontAwesomeIcon icon="times" fixedWidth />
																		</Button>
																	</InputGroupAddon>
																</InputGroup>
															</AvForm>
														:
														<div >
															{
																this.state.editManufacturer ?
																	<AvForm>
																		<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																			<AvField bsSize="sm" type="text" placeholder="Enter manufacturer" value={this.state.manufacturerValue} name='manufacturerValue'
																				onChange={(evt) => this.handleChange(evt, 'manufacturerIsValid')}
																				validate={{
																					required: { value: true, errorMessage: 'Please enter Manufacturer' }
																				}}
																			/>
																			<InputGroupAddon addonType="append">
																				<Button color="light" title="Update"
																					disabled={this.state.manufacturerIsValid ? false : true}
																					onClick={() => {
																						this.setState({
																							editManufacturer: false
																						})
																						this.handleFieldsChange('editManufacturer', this.state.manufacturerValue)
																					}}
																				>
																					<FontAwesomeIcon icon="check" fixedWidth />
																				</Button>
																				<Button color="light" title="Cancel" className="mx-0"
																					onClick={() => {
																						this.setState({
																							editManufacturer: false
																						})
																					}}
																				>
																					<FontAwesomeIcon icon="times" fixedWidth />
																				</Button>
																			</InputGroupAddon>
																		</InputGroup>
																	</AvForm>
																	:

																	<div className="ff-alt ff-alt fs-14 editable-column" onClick={() => {
																		this.setState({
																			editManufacturer: true,
																			manufacturerValue: manufacturer && manufacturer != "" ? manufacturer : ""
																		})
																	}}>
																		by <h4>{manufacturer && manufacturer != "" ? manufacturer : ""}</h4>
																	</div>
															}
														</div>
													}
													<p className="fs-14"><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
														className="img-fluid"
														onError={(error) =>
															(error.target.src = `../../../assets/images/norating.png`)
														}
														alt={`${about && about?.total_rating && about?.total_rating.avg_rating?.[1] ? about?.total_rating.avg_rating?.[1] : 'no-rating'}`}

													/> {(about && about?.total_rating && about?.total_rating.total_review) ? about?.total_rating.total_review : 0} {getReviewSuffix(about?.total_rating?.total_review)}</p>

												</div>
											</div>
										</div>
										<div className="wr-movie-product-info-bottom wr-business-product-info-bottom">
											<ul className="m-0 p-0">
												<li className="d-flex flex-wrap mb-2 mx-n2">
													<div className="px-2 flex-120">
														<span className="fs-14">Model Number:</span>
													</div>
													<div className="col px-2">

														{(this.state.modelSet == "" || this.state.modelSet == null) ?
															this.state.addModel === false ?
																<span className="fs-14 font-weight-bold editable-column"
																	onClick={() => {
																		this.setState({
																			addModel: true
																		})
																	}}
																>Click to add</span>
																:
																<AvForm>
																	<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																		<AvField
																			type="text"
																			bsSize="sm"
																			placeholder="Enter model number"
																			name="newModel"
																			value={this.state.newModel}
																			onChange={(evt) => this.handleChange(evt, 'newModelIsValid')}
																			validate={{
																				required: { value: true, errorMessage: 'Please enter model number' }
																			}}
																		/>
																		<InputGroupAddon addonType="append">
																			<Button color="light" title="Update"
																				disabled={this.state.newModelIsValid ? false : true}
																				onClick={() => {
																					this.setState({
																						addModel: false
																					});
																					this.handleFieldsChange('addModel', this.state.newModel)
																				}}
																			>
																				<FontAwesomeIcon icon="check" fixedWidth />
																			</Button>
																			<Button color="light" title="Cancel" className="mx-0"
																				onClick={() => {
																					this.setState({
																						addModel: false
																					})
																				}}
																			>
																				<FontAwesomeIcon icon="times" fixedWidth />
																			</Button>
																		</InputGroupAddon>
																	</InputGroup>
																</AvForm>
															:
															<div >
																{
																	this.state.editModel ?
																		<AvForm>
																			<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																				<AvField bsSize="sm" type="text" placeholder="Enter model number" value={this.state.model} name='model'
																					onChange={(evt) => this.handleChange(evt, 'modelIsValid')}
																					validate={{
																						required: { value: true, errorMessage: 'Please enter model number' }
																					}}
																				/>
																				<InputGroupAddon addonType="append">
																					<Button color="light" title="Update"
																						disabled={this.state.modelIsValid ? false : true}
																						onClick={() => {
																							this.setState({
																								editModel: false
																							})
																							this.handleFieldsChange('editModel', this.state.model)
																						}}
																					>
																						<FontAwesomeIcon icon="check" fixedWidth />
																					</Button>
																					<Button color="light" title="Cancel" className="mx-0"
																						onClick={() => {
																							this.setState({
																								editModel: false
																							})
																						}}
																					>
																						<FontAwesomeIcon icon="times" fixedWidth />
																					</Button>
																				</InputGroupAddon>
																			</InputGroup>
																		</AvForm>
																		:
																		<span className="fs-14 font-weight-bold editable-column"
																			onClick={() => {
																				this.setState({
																					editModel: true,
																					model: modelSet && modelSet != "" ? modelSet : ""
																				})
																			}}
																		>{modelSet && modelSet != "" ? modelSet : ""}
																		</span>
																}
															</div>
														}
													</div>
												</li>
												<li className="d-flex flex-wrap mb-2 mx-n2">
													<div className="px-2 flex-120">
														<span className="fs-14">Website:</span>
													</div>
													<div className="px-2 col">
														{this.state.websiteSet && (this.state.websiteSet.length === 0 || Object.keys(this.state.websiteSet).length === 0) ?
															this.state.addWebsite === false ?
																<span className="fs-14 font-weight-bold editable-column"
																	onClick={() => {
																		this.setState({
																			addWebsite: true
																		})
																	}}>
																	{'Click to add'}
																</span>
																:
																<AvForm>
																	<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																		<AvField bsSize="sm" type="text" placeholder="Enter valid Website" value={this.state.newWebsite} name="newWebsite"
																			onChange={(evt) => this.handleChange(evt, 'newWebsiteIsValid')}
																			validate={{
																				required: { value: true, errorMessage: 'Please enter your website' },
																				pattern: {
																					value: "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$",
																					errorMessage: 'Please enter valid website link'
																				},
																			}}
																		/>
																		<InputGroupAddon addonType="append">
																			<Button color="light" title="Update"
																				disabled={this.state.newWebsiteIsValid ? false : true}
																				onClick={() => {
																					this.setState({
																						addWebsite: false
																					});
																					this.handleFieldsChange('addWebsite', this.state.newWebsite)
																				}}
																			>
																				<FontAwesomeIcon icon="check" fixedWidth />
																			</Button>
																			<Button color="light" title="Cancel" className="mx-0" onClick={() => {
																				this.setState({
																					addWebsite: false
																				})
																			}}
																			>
																				<FontAwesomeIcon icon="times" fixedWidth />
																			</Button>
																		</InputGroupAddon>
																	</InputGroup>
																</AvForm>
															:
															<div>
																<span className="fs-14 font-weight-bold editable-column">
																	{about && about.website_set && about.website_set.length > 0 ?
																		<>
																		{/* {about.website_set.map(site => {
																			if (site.website_type === 'Main') {
																				return <a href={`${site.website}`} rel="noopener noreferrer" target="_blank" className="text-reset">
																					{site.website}
																				</a>
																			}
																		})} */}
																		{about?.website_set?.[0]?.website}
																		</> : ""
																	}
																</span>

															</div>
														}
													</div>
												</li>

												<li className="d-flex mx-n2 mb-2">
													<div className="px-2 flex-120">
														<span className="fs-14 mr-2">Phone: </span>
													</div>
													<div className="px-2 col">
														{this.state.editPhoneNumber ?
															<div>
																{/* Telephone Edit */}
																<div className="mb-2">
																	<AvForm>
																		<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																			<AvField
																				type="text"
																				bsSize="sm"
																				value={this.state.phone}
																				name='phone'
																				placeholder="Enter valid phone"
																				onChange={(evt) => this.handleChange(evt, 'phoneIsValid')}
																				validate={{
																					pattern: { value: '^[0-9]+$', errorMessage: 'Please enter valid number' },
																					minLength: { value: 10, errorMessage: 'Please enter valid number' },
																					maxLength: { value: 10, errorMessage: 'Please enter valid number' },
																				}}
																			/>
																			<InputGroupAddon addonType="append">
																				<Button color="light" title="Update"
																					disabled={this.state.phoneIsValid ? false : true}
																					onClick={() => {
																						this.setState({
																							editPhoneNumber: false,
																						});
																						this.handleFieldsChange('phone', this.state.phone)
																					}}><FontAwesomeIcon icon="arrow-right" fixedWidth /></Button>
																				<Button color="light" title="Cancel" className="mx-0"
																					onClick={() => {
																						this.setState({
																							editPhoneNumber: false,
																							phone: phoneSet && phoneSet[0] && phoneSet[0].phone
																						});
																						this.handleFieldsChange('phone', this.state.phone)
																					}}
																				>
																					<FontAwesomeIcon icon="times" fixedWidth />
																				</Button>
																			</InputGroupAddon>
																		</InputGroup>
																	</AvForm>
																</div>
																{/* Telephone type edit */}

															</div>
															:
															<span
																className="fs-14 font-weight-bold editable-column"
																onClick={() => {
																	this.setState({
																		editPhoneNumber: true,
																		phone: phoneSet && phoneSet[0] && phoneSet[0].phone
																	})
																}}
															>{phoneSet && phoneSet[0] && phoneSet[0].id ? phoneSet[0].label == "Mob" ? `${phoneSet[0].phone}` : `${this.formatPhoneNumber(phoneSet[0].phone)}` : "Click to add"}</span>
														}

														{this.state.editPhoneNumberLabel ?
															<div>
																{/* Telephone Label Edit */}
																<div>
																	<AvForm>

																		<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																			<AvField type="select"
																				bsSize="sm"
																				value={this.state.phoneLabel}
																				name='phoneLabel'
																				id="phoneLabel"
																				onChange={this.handleChange}
																			>
																				<option value="Tel">Tel</option>
																				<option value="Mob">Mobile</option>
																				<option value="Support">Support</option>
																			</AvField>
																			<InputGroupAddon addonType="append">
																				<Button color="light" title="Update"
																					disabled={this.state.phoneLabel === null || this.state.phoneLabel === 0 ? true : false}
																					onClick={() => {
																						this.setState({
																							editPhoneNumberLabel: false,
																							//phoneLabel: phoneSet && phoneSet[0] && phoneSet[0].phone
																						});
																						this.handleFieldsChange('phoneLabel', this.state.phoneLabel)

																					}}
																				>
																					<FontAwesomeIcon icon="check" fixedWidth />
																				</Button>
																				<Button color="light" title="Cancel" className="mx-0"
																					onClick={() => {
																						this.setState({
																							editPhoneNumberLabel: false,
																							phoneLabel: phoneSet && phoneSet[0] && phoneSet[0].label
																						})
																					}}
																				>
																					<FontAwesomeIcon icon="times" fixedWidth />
																				</Button>
																			</InputGroupAddon>
																		</InputGroup>
																	</AvForm>
																</div>
																{/* Telephone type edit */}

															</div>
															:
															<span
																className="fs-14 ml-2 editable-column"
																onClick={() => {
																	this.setState({
																		editPhoneNumberLabel: true,
																		phoneLabel: phoneSet && phoneSet[0] && phoneSet[0].label
																	})
																}}
															>{phoneSet && phoneSet[0] && phoneSet[0].id ? `${phoneSet[0].label}` : ""}</span>
														}
													</div>
												</li>

												<li className="d-flex flex-wrap mb-2 mx-n2">
													<div className="px-2 flex-120">
														<span className="fs-14">Price Range:</span>
													</div>
													<div className="col px-2">
														<span className="fs-14 font-weight-bold">
															<div className="d-inline">
																{this.state.minPriceSet && this.state.minPriceSet.length === 0 ?
																	this.state.addMinPrice === false ?
																		<span className="fs-14 font-weight-bold editable-column"
																			onClick={() => {
																				this.setState({
																					addMinPrice: true
																				})
																			}}
																		>$ Click to add</span>
																		:
																		<>
																			<div className="d-flex">
																				<span className="mr-2">$ </span>
																				<AvForm>
																					<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																						<AvField
																							type="number"
																							bsSize="sm"
																							min="0.00"
																							pattern="[0-9]+([,\.][0-9]+)?"
																							step="0.01"
																							placeholder="Enter min price"
																							name="newMinPrice"
																							value={this.state.newMinPrice}
																							onChange={(evt) => this.handleChange(evt, 'newMinPriceIsValid')}
																							validate={{
																								required: { value: true, errorMessage: 'Please enter Min Price' }
																							}}
																							onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) &&  e.preventDefault()}
																						/>
																						<InputGroupAddon addonType="append">
																							<Button color="light" title="Update"
																								disabled={this.state.newMinPriceIsValid ? false : true}
																								onClick={() => {
																									this.setState({
																										addMinPrice: false
																									});
																									this.handleFieldsChange('addMinPrice', this.state.newMinPrice)
																								}}
																							>
																								<FontAwesomeIcon icon="check" fixedWidth />
																							</Button>
																							<Button color="light" title="Cancel" className="mx-0"
																								onClick={() => {
																									this.setState({
																										addMinPrice: false
																									})
																								}}
																							>
																								<FontAwesomeIcon icon="times" fixedWidth />
																							</Button>
																						</InputGroupAddon>
																					</InputGroup>
																				</AvForm>
																			</div>
																		</>
																	:
																	<>
																		{this.state.editMinPrice ?
																			<div className="d-flex">
																				<span className="mr-2">$ </span>
																				<AvForm>
																					<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																						<AvField bsSize="sm" min="0.00" type="number" placeholder="Enter min price" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" value={this.state.minPrice} name='minPrice'
																							onChange={(evt) => this.handleChange(evt, 'minPriceIsValid')}
																							validate={{
																								required: { value: true, errorMessage: 'Please enter Min Price' }
																							}}
																							onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																						/>
																						<InputGroupAddon addonType="append">
																							<Button color="light" title="Update"
																								disabled={this.state.minPriceIsValid == true ? false : true}
																								onClick={() => {
																									this.setState({
																										editMinPrice: false
																									})
																									this.handleFieldsChange('editMinPrice', this.state.minPrice)
																								}}
																							>
																								<FontAwesomeIcon icon="check" fixedWidth />
																							</Button>
																							<Button color="light" title="Cancel" className="mx-0"
																								onClick={() => {
																									this.setState({
																										editMinPrice: false
																									})
																								}}
																							>
																								<FontAwesomeIcon icon="times" fixedWidth />
																							</Button>
																						</InputGroupAddon>
																					</InputGroup>
																				</AvForm>
																			</div>
																			:
																			<span className="editable-column"
																				onClick={() => {
																					this.setState({
																						editMinPrice: true,
																						minPrice: minPriceSet && minPriceSet.length > 0 ? minPriceSet[0].value : ""
																					})
																				}}
																			>
																				<span>$</span>
																				<span>{minPriceSet && minPriceSet.length > 0 && minPriceSet[0].value != null && minPriceSet[0].value != 0 ? minPriceSet[0].value : "Click to add"}</span>
																			</span>
																		}

																	</>

																}
															</div>
															<span>&nbsp;-&nbsp;</span>
															<div className="d-inline">
																{this.state.maxPriceSet && this.state.maxPriceSet.length === 0 ?
																	this.state.addMaxPrice === false ?
																		<span className="fs-14 font-weight-bold editable-column"
																			onClick={() => {
																				this.setState({
																					addMaxPrice: true
																				})
																			}}
																		>$ Click to add</span>
																		:
																		<>
																			<div className="d-flex">
																				<span className="mr-2">$ </span>
																				<AvForm>
																					<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																						<AvField
																							type="number"
																							bsSize="sm"
																							pattern="[0-9]+([,\.][0-9]+)?"
																							placeholder="Enter max price"
																							min="0.00"
																							name="newMaxPrice"
																							value={this.state.newMaxPrice}
																							onChange={(evt) => this.handleChange(evt, 'newMaxPriceIsValid')}
																							validate={{
																								required: { value: true, errorMessage: 'Please enter Max Price' }
																							}}
																							onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																						/>
																						<InputGroupAddon addonType="append">
																							<Button color="light" title="Update"
																								disabled={this.state.newMaxPriceIsValid ? false : true}
																								onClick={() => {
																									this.setState({
																										addMaxPrice: false
																									});
																									this.handleFieldsChange('addMaxPrice', this.state.newMaxPrice)
																								}}
																							>
																								<FontAwesomeIcon icon="check" fixedWidth />
																							</Button>
																							<Button color="light" title="Cancel" className="mx-0"
																								onClick={() => {
																									this.setState({
																										addMaxPrice: false
																									})
																								}}
																							>
																								<FontAwesomeIcon icon="times" fixedWidth />
																							</Button>
																						</InputGroupAddon>
																					</InputGroup>
																				</AvForm>
																			</div>
																		</>
																	:
																	<>
																		{this.state.editMaxPrice ?
																			<div className="d-flex">
																				<span className="mr-2">$ </span>
																				<AvForm>
																					<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																						<AvField bsSize="sm" type="number" min="0.00" placeholder="Enter max price"pattern="[0-9]+([,\.][0-9]+)?" step="0.01" value={this.state.maxPrice} name='maxPrice'
																							onChange={(evt) => this.handleChange(evt, 'maxPriceIsValid')}
																							validate={{
																								required: { value: true, errorMessage: 'Please enter Max Price' }
																							}}
																							onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																						/>
																						<InputGroupAddon addonType="append">
																							<Button color="light" title="Update"
																								disabled={this.state.maxPriceIsValid == true ? false : true}
																								onClick={() => {
																									this.setState({
																										editMaxPrice: false
																									})
																									this.handleFieldsChange('editMaxPrice', this.state.maxPrice)
																								}}
																							>
																								<FontAwesomeIcon icon="check" fixedWidth />
																							</Button>
																							<Button color="light" title="Cancel" className="mx-0"
																								onClick={() => {
																									this.setState({
																										editMaxPrice: false
																									})
																								}}
																							>
																								<FontAwesomeIcon icon="times" fixedWidth />
																							</Button>
																						</InputGroupAddon>
																					</InputGroup>
																				</AvForm>
																			</div>
																			:
																			<span className="editable-column"
																				onClick={() => {
																					this.setState({
																						editMaxPrice: true,
																						maxPrice: maxPriceSet && maxPriceSet.length > 0 ? maxPriceSet[0].value : ""
																					})
																				}}
																			>
																				<span>$</span>
																				<span>{maxPriceSet && maxPriceSet.length > 0 && maxPriceSet[0].value != null && maxPriceSet[0].value != 0 ? maxPriceSet[0].value : "Click to add"}</span>
																			</span>
																		}
																	</>
																}
															</div>
														</span>

													</div>
													<span style={{ color: 'red' }}>{this.state.maxPriceError}</span>
												</li>

												<li className="d-flex flex-wrap mb-2 mx-n2">
													<div className="px-2 flex-120">
														<span className="fs-14">Categories:</span>
													</div>
													<div className="col px-2">
														<span className="fs-14 font-weight-bold">{about && Array.isArray(about.taxonomy_list) && about.taxonomy_list.map((taxonomy, index) => {
															if (index+1 === about.taxonomy_list.length) {
																return <>{taxonomy} </>
															}
															return <>{taxonomy}, </>
														})}</span>
													</div>
												</li>
											</ul>
										</div>
									</Col>
									{this.state.toggleMoreLessInfo ?
										<Col md={8}>
											<Row>
												<Col lg={6}>
													<div className="wr-category-heading">
														{about && about.total_rating && Object.keys(about.total_rating).length > 0 ?

															about && Object.keys(about.total_rating).map(rating => {
																if (rating !== "total_review" && rating !== "avg_rating") {
																	return <p className="fs-14"><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${about.total_rating[rating][0]}`} className="img-fluid" alt={rating || ""} /> {rating}</p>

																}
															}) : ''}
														<div className="wr-movie-product-info-bottom">
															<ul className="m-0 p-0">
																<li className="d-flex flex-wrap mb-2 mx-n2">
																	<div className="px-2 flex-120">
																		<span className="fs-14">Manufacturer Cut Sheet:</span>
																	</div>
																	<div className="col px-2">
																		{(!this.state.manufactureCutSheet?.length && !this.state.pdfManufactureCutSheet?.length) ?
																			this.state.addManCutSheet === false ?
																				<span className="fs-14 font-weight-bold editable-column"
																					onClick={() => {
																						this.setState({
																							addManCutSheet: true
																						})
																					}}
																				>Click to add</span>
																				:
																				<AvForm>
																					<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																					{this.state.pdfCutSheet ? 
																									<span className='fs-14'>{this.state.pdfCutSheet?.name}</span>
																									:
																						<AvField
																							type="text"
																							bsSize="sm"
																							placeholder="Enter Information"
																							name="newManCutSheet"
																							value={this.state.newManCutSheet}
																							onChange={(evt) => this.handleChange(evt, 'newManCutSheetIsValid')}
																							validate={{
																								required: { value: true, errorMessage: 'Please enter information' },
																							}}
																						/>}
																						<InputGroupAddon addonType="append">
																						{this.state.pdfCutSheet ?
																										<Button color="light" title="Remove PDF" className="mx-0"
																											onClick={() => {
																												this.setState({
																													pdfCutSheet: null
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="trash" fixedWidth />
																										</Button>
																										:
																										<label className='upload-pdf-cutsheet btn btn-light mb-0'>
																											<input type={"file"}
																												accept="image/*, .pdf"
																												onChange={(event) => {
																													const pdfCutSheet = event.target.files[0];
																													this.setState({
																														pdfCutSheet: pdfCutSheet
																													});
																												}} />
																											<FontAwesomeIcon icon="upload" fixedWidth />
																										</label>
																									}

																							<Button color="light" title="Update"
																								disabled={this.state.pdfCutSheet? false: (this.state.manCutSheetIsValid ? false : true)}
																								onClick={() => {
																									this.setState({
																										addManCutSheet: false
																									});
																									this.handleFieldsChange('addManCutSheet', this.state.newManCutSheet)
																								}}
																							>
																								<FontAwesomeIcon icon="check" fixedWidth />
																							</Button>
																							<Button color="light" title="Cancel" className="mx-0"
																								onClick={() => {
																									this.setState({
																										addManCutSheet: false
																									})
																								}}
																							>
																								<FontAwesomeIcon icon="times" fixedWidth />
																							</Button>
																						</InputGroupAddon>
																					</InputGroup>
																				</AvForm>
																			:
																			<div >
																				{
																					this.state.editManCutSheet ?
																						<AvForm>
																							<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																								{
																									this.state.pdfCutSheet ? 
																									<span className='fs-14'>{this.state.pdfCutSheet?.name}</span>
																									:
																									<AvField bsSize="sm" type="text" placeholder="Enter Information" value={this.state.manCutSheetPrice} name='manCutSheetPrice'
																									onChange={(evt) => this.handleChange(evt, 'manCutSheetIsValid')}
																									validate={{
																										required: { value: true, errorMessage: 'Please enter information' },
																									}}
																								/>
																								}
																								<InputGroupAddon addonType="append">
																									{this.state.pdfCutSheet ?
																										<Button color="light" title="Remove PDF" className="mx-0"
																											onClick={() => {
																												this.setState({
																													pdfCutSheet: null
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="trash" fixedWidth />
																										</Button>
																										:
																										<label className='upload-pdf-cutsheet btn btn-light mb-0'>
																											<input type={"file"}
																												accept="image/*, .pdf"
																												onChange={(event) => {
																													const pdfCutSheet = event.target.files[0];
																													this.setState({
																														pdfCutSheet: pdfCutSheet
																													});
																												}} />
																											<FontAwesomeIcon icon="upload" fixedWidth />
																										</label>
																									}

																									
																									<Button color="light" title="Update"
																										disabled={this.state.pdfCutSheet? false: (this.state.manCutSheetIsValid ? false : true)}
																										onClick={() => {
																											this.setState({
																												editManCutSheet: false
																											})
																											this.handleFieldsChange('editManCutSheet', this.state.manCutSheetPrice)
																										}}
																									>
																										<FontAwesomeIcon icon="check" fixedWidth />
																									</Button>
																									<Button color="light" title="Cancel" className="mx-0"
																										onClick={() => {
																											this.setState({
																												editManCutSheet: false
																											})
																										}}
																									>
																										<FontAwesomeIcon icon="times" fixedWidth />
																									</Button>
																								</InputGroupAddon>
																							</InputGroup>
																						</AvForm>
																						:
																						<div>
																							<span className="fs-14 font-weight-bold editable-column" onClick={() => {
																								this.setState({
																									editManCutSheet: true,
																									manCutSheetPrice: manufactureCutSheet && manufactureCutSheet.length > 0 ? manufactureCutSheet[0].value : ""
																								})
																							}}>
																								{this.getManufactureCutSheetRender()}
																								<span>&nbsp;&nbsp;</span>
																								<FontAwesomeIcon icon="edit" fixedWidth />
																							</span>
																							{
																								this.state.pdfManufactureCutSheet?.length ? 
																								<span onClick={() => this.deletePDFCutSheet(this.state.manCutSheetPrice)}>
																									<FontAwesomeIcon icon="trash" fixedWidth />
																								</span> 
																								: 
																								''
																							}
																							<br />
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										editManCutSheet: true,
																										manCutSheetPrice: manufactureCutSheet && manufactureCutSheet.length > 0 ? manufactureCutSheet[0].value : ""
																									})
																								}}
																							>
																							</span>

																							{/* <div>
																								<span id="mfcsPopOver" className="text-dark fs-14 text-decoration-none font-weight-bold editable-column">
																									<span>......</span>
																								</span>
																								<Popover className="popover-basic" placement="bottom" isOpen={this.state.mfcsPopOver} target="mfcsPopOver" toggle={() => this.setState({mfcsPopOver: !this.state.mfcsPopOver})}>
																									<PopoverHeader>Manufacturer Cut Sheet</PopoverHeader>
																									<PopoverBody>
																										<span className="editable-item">{this.getManufactureCutSheetRender()}</span>
																									</PopoverBody>
																								</Popover>
																							</div> */}
																						</div>
																				}
																			</div>
																		}
																	</div>
																</li>
																<li className="d-flex flex-wrap mb-2 mx-n2">
																	<div className="px-2 flex-120">
																		<span className="fs-14">Manufacturer Warranty:</span>
																	</div>
																	<div className="col px-2">
																		{(this.state.manuWarranty == "" || this.state.manuWarranty == null) ?
																			this.state.addManuWarranty === false ?
																				<span className="fs-14 font-weight-bold editable-column"
																					onClick={() => {
																						this.setState({
																							addManuWarranty: true
																						})
																					}}
																				>Click to add</span>
																				:
																				<AvForm>
																					<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																						<AvField
																							type="text"
																							bsSize="sm"
																							placeholder="Enter Information"
																							name="newManuWarranty"
																							value={this.state.newManuWarranty}
																							onChange={(evt) => this.handleChange(evt, 'newManuWarrantyIsValid')}
																							validate={{
																								required: { value: true, errorMessage: 'Please enter Information' }
																							}}
																						/>
																						<InputGroupAddon addonType="append">
																							<Button color="light" title="Update"
																								disabled={this.state.newManuWarrantyIsValid ? false : true}
																								onClick={() => {
																									this.setState({
																										addManuWarranty: false
																									});
																									this.handleFieldsChange('addManuWarranty', this.state.newManuWarranty)
																								}}
																							>
																								<FontAwesomeIcon icon="check" fixedWidth />
																							</Button>
																							<Button color="light" title="Cancel" className="mx-0"
																								onClick={() => {
																									this.setState({
																										addManuWarranty: false
																									})
																								}}
																							>
																								<FontAwesomeIcon icon="times" fixedWidth />
																							</Button>
																						</InputGroupAddon>
																					</InputGroup>
																				</AvForm>
																			:
																			<div >
																				{
																					this.state.editManuWarranty ?
																						<AvForm>
																							<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																								<AvField bsSize="sm" type="text" placeholder="Enter Information" value={this.state.manuWarrantyValue} name='manuWarrantyValue'
																									onChange={(evt) => this.handleChange(evt, 'manuWarrantyIsValid')}
																									validate={{
																										required: { value: true, errorMessage: 'Please enter Information' }
																									}}
																								/>
																								<InputGroupAddon addonType="append">
																									<Button color="light" title="Update"
																										disabled={this.state.manuWarrantyIsValid ? false : true}
																										onClick={() => {
																											this.setState({
																												editManuWarranty: false
																											})
																											this.handleFieldsChange('editManuWarranty', this.state.manuWarrantyValue)
																										}}
																									>
																										<FontAwesomeIcon icon="check" fixedWidth />
																									</Button>
																									<Button color="light" title="Cancel" className="mx-0"
																										onClick={() => {
																											this.setState({
																												editManuWarranty: false
																											})
																										}}
																									>
																										<FontAwesomeIcon icon="times" fixedWidth />
																									</Button>
																								</InputGroupAddon>
																							</InputGroup>
																						</AvForm>
																						:
																						<div>
																							<span className="fs-14 font-weight-bold editable-column" onClick={() => {
																								this.setState({
																									editManuWarranty: true,
																									manuWarrantyValue: manuWarranty && manuWarranty != "" ? manuWarranty : ""
																								})
																							}}> {manuWarranty && manuWarranty != "" ? manuWarranty.substring(0, 25) : ""} </span>
																							<br />
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										editManuWarranty: true,
																										manuWarrantyValue: manuWarranty && manuWarranty != "" ? manuWarranty : ""
																									})
																								}}
																							>......
																							</span>
																						</div>
																				}
																			</div>
																		}
																	</div>
																</li>
																<li className="d-flex flex-wrap mb-2 mx-n2">
																	<div className="px-2 flex-120">
																		<span className="fs-14">Product Dimensions:</span>
																	</div>
																	<div className="col px-2">
																		<div className="mb-2">
																			<div className="fs-14 font-weight-bold">
																				Packed
																			</div>
																			<div className="d-flex flex-wrap fs-14 font-weight-bold">
																				<div className="d-flex flex-nowrap">

																					{(this.state.packedWidth == "" || this.state.packedWidth == null) ?
																						this.state.addPackedWidth === false ?
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										addPackedWidth: true
																									})
																								}}
																							>Click to add<span>&nbsp;x&nbsp;</span></span>
																							:
																							<AvForm>
																								<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																									<AvField
																										type="number"
																										bsSize="sm"
																										placeholder="Enter Width"
																										name="newPackedWidth"
																										min="0.00"
																										pattern="[0-9]+([,\.][0-9]+)?" 
																										step="0.01"
																										value={this.state.newPackedWidth}
																										onChange={(evt) => this.handleChange(evt, 'newPackedWidthIsValid')}
																										validate={{
																											required: { value: true, errorMessage: 'Please enter Width' }
																										}}
																										onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																									/>
																									<InputGroupAddon addonType="append">
																										<Button color="light" title="Update"
																											disabled={this.state.newPackedWidthIsValid ? false : true}
																											onClick={() => {
																												this.setState({
																													addPackedWidth: false
																												});
																												this.handleFieldsChange('addPackedWidth', this.state.newPackedWidth)
																											}}
																										>
																											<FontAwesomeIcon icon="check" fixedWidth />
																										</Button>
																										<Button color="light" title="Cancel" className="mx-0"
																											onClick={() => {
																												this.setState({
																													addPackedWidth: false
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="times" fixedWidth />
																										</Button>
																									</InputGroupAddon>
																								</InputGroup>
																							</AvForm>
																						:
																						<div >
																							{
																								this.state.editPackedWidth ?
																									<AvForm>
																										<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																											<AvField min="0.00" bsSize="sm" type="number" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" placeholder="Enter Width" value={this.state.packedWidthValue} name='packedWidthValue'
																												onChange={(evt) => this.handleChange(evt, 'packedWidthIsValid')}
																												validate={{
																													required: { value: true, errorMessage: 'Please enter Width' }
																												}}
																												onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																											/>
																											<InputGroupAddon addonType="append">
																												<Button color="light" title="Update"
																													disabled={this.state.packedWidthIsValid ? false : true}
																													onClick={() => {
																														this.setState({
																															editPackedWidth: false
																														})
																														this.handleFieldsChange('editPackedWidth', this.state.packedWidthValue)
																													}}
																												>
																													<FontAwesomeIcon icon="check" fixedWidth />
																												</Button>
																												<Button color="light" title="Cancel" className="mx-0"
																													onClick={() => {
																														this.setState({
																															editPackedWidth: false
																														})
																													}}
																												>
																													<FontAwesomeIcon icon="times" fixedWidth />
																												</Button>
																											</InputGroupAddon>
																										</InputGroup>
																									</AvForm>
																									:
																									<span className="fs-14 font-weight-bold editable-column"
																										onClick={() => {
																											this.setState({
																												editPackedWidth: true,
																												packedWidthValue: packedWidth && packedWidth != "" ? packedWidth : ""
																											})
																										}}
																									>{packedWidth && packedWidth != "" ? packedWidth : ""}
																										<span>&nbsp;x&nbsp;</span>
																									</span>
																							}
																						</div>
																					}
																				</div>
																				<div className="d-flex flex-nowrap">
																					{(this.state.packedHeight == "" || this.state.packedHeight == null) ?
																						this.state.addPackedHeight === false ?
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										addPackedHeight: true
																									})
																								}}
																							>Click to add<span>&nbsp;x&nbsp;</span></span>
																							:
																							<AvForm>
																								<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																									<AvField
																										type="number"
																										bsSize="sm"
																										min="0.00"
																										pattern="[0-9]+([,\.][0-9]+)?" 
																										step="0.01"
																										placeholder="Enter Height"
																										name="newPackedHeight"
																										value={this.state.newPackedHeight}
																										onChange={(evt) => this.handleChange(evt, 'newPackedHeightIsValid')}
																										validate={{
																											required: { value: true, errorMessage: 'Please enter Height' }
																										}}
																										onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																									/>
																									<InputGroupAddon addonType="append">
																										<Button color="light" title="Update"
																											disabled={this.state.newPackedHeightIsValid ? false : true}
																											onClick={() => {
																												this.setState({
																													addPackedHeight: false
																												});
																												this.handleFieldsChange('addPackedHeight', this.state.newPackedHeight)
																											}}
																										>
																											<FontAwesomeIcon icon="check" fixedWidth />
																										</Button>
																										<Button color="light" title="Cancel" className="mx-0"
																											onClick={() => {
																												this.setState({
																													addPackedHeight: false
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="times" fixedWidth />
																										</Button>
																									</InputGroupAddon>
																								</InputGroup>
																							</AvForm>
																						:
																						<div >
																							{
																								this.state.editPackedHeight ?
																									<AvForm>
																										<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																											<AvField bsSize="sm" min="0.00" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" type="number" placeholder="Enter Height" value={this.state.packedHeightValue} name='packedHeightValue'
																												onChange={(evt) => this.handleChange(evt, 'packedHeightIsValid')}
																												validate={{
																													required: { value: true, errorMessage: 'Please enter Height' }
																												}}
																												onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																											/>
																											<InputGroupAddon addonType="append">
																												<Button color="light" title="Update"
																													disabled={this.state.packedHeightIsValid ? false : true}
																													onClick={() => {
																														this.setState({
																															editPackedHeight: false
																														})
																														this.handleFieldsChange('editPackedHeight', this.state.packedHeightValue)
																													}}
																												>
																													<FontAwesomeIcon icon="check" fixedWidth />
																												</Button>
																												<Button color="light" title="Cancel" className="mx-0"
																													onClick={() => {
																														this.setState({
																															editPackedHeight: false
																														})
																													}}
																												>
																													<FontAwesomeIcon icon="times" fixedWidth />
																												</Button>
																											</InputGroupAddon>
																										</InputGroup>
																									</AvForm>
																									:
																									<span className="fs-14 font-weight-bold editable-column"
																										onClick={() => {
																											this.setState({
																												editPackedHeight: true,
																												packedHeightValue: packedHeight && packedHeight != "" ? packedHeight : ""
																											})
																										}}
																									>{packedHeight && packedHeight != "" ? packedHeight : ""}
																										<span>&nbsp;x&nbsp;</span>
																									</span>
																							}
																						</div>
																					}
																				</div>
																				<div className="d-flex flex-nowrap">
																					{(this.state.packedDepth == "" || this.state.packedDepth == null) ?
																						this.state.addPackedDepth === false ?
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										addPackedDepth: true
																									})
																								}}
																							>Click to add<span>&nbsp;{get_temp ? (get_temp === 'us_measurement_system')? 'inch' : 'mm' : 'inches'}&nbsp;</span></span>
																							:
																							<AvForm>
																								<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																									<AvField
																										type="number"
																										bsSize="sm"
																										min="0.00"
																										pattern="[0-9]+([,\.][0-9]+)?" 
																										step="0.01"
																										placeholder="Enter Depth"
																										name="newPackedDepth"
																										value={this.state.newPackedDepth}
																										onChange={(evt) => this.handleChange(evt, 'newPackedDepthIsValid')}
																										validate={{
																											required: { value: true, errorMessage: 'Please enter Depth' }
																										}}
																										onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																									/>
																									<InputGroupAddon addonType="append">
																										<Button color="light" title="Update"
																											disabled={this.state.newPackedDepthIsValid ? false : true}
																											onClick={() => {
																												this.setState({
																													addPackedDepth: false
																												});
																												this.handleFieldsChange('addPackedDepth', this.state.newPackedDepth)
																											}}
																										>
																											<FontAwesomeIcon icon="check" fixedWidth />
																										</Button>
																										<Button color="light" title="Cancel" className="mx-0"
																											onClick={() => {
																												this.setState({
																													addPackedDepth: false
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="times" fixedWidth />
																										</Button>
																									</InputGroupAddon>
																								</InputGroup>
																							</AvForm>
																						:
																						<div >
																							{
																								this.state.editPackedDepth ?
																									<AvForm>
																										<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																											<AvField bsSize="sm" min="0.00" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" type="number" placeholder="Enter Depth" value={this.state.packedDepthValue} name='packedDepthValue'
																												onChange={(evt) => this.handleChange(evt, 'packedDepthIsValid')}
																												validate={{
																													required: { value: true, errorMessage: 'Please enter Depth' }
																												}}
																												onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																											/>
																											<InputGroupAddon addonType="append">
																												<Button color="light" title="Update"
																													disabled={this.state.packedDepthIsValid ? false : true}
																													onClick={() => {
																														this.setState({
																															editPackedDepth: false
																														})
																														this.handleFieldsChange('editPackedDepth', this.state.packedDepthValue)
																													}}
																												>
																													<FontAwesomeIcon icon="check" fixedWidth />
																												</Button>
																												<Button color="light" title="Cancel" className="mx-0"
																													onClick={() => {
																														this.setState({
																															editPackedDepth: false
																														})
																													}}
																												>
																													<FontAwesomeIcon icon="times" fixedWidth />
																												</Button>
																											</InputGroupAddon>
																										</InputGroup>
																									</AvForm>
																									:
																									<span className="fs-14 font-weight-bold editable-column"
																										onClick={() => {
																											this.setState({
																												editPackedDepth: true,
																												packedDepthValue: packedDepth && packedDepth != "" ? packedDepth : ""
																											})
																										}}
																									>{packedDepth && packedDepth != "" ? packedDepth : ""}
																										<span>&nbsp;{get_temp ? (get_temp === 'us_measurement_system')? 'inch' : 'mm' : 'inches'}&nbsp;</span>
																									</span>
																							}
																						</div>
																					}
																				</div>
																			</div>
																		</div>
																		<div className="mb-2">
																			<div className="fs-14 font-weight-bold">
																				Assembled
																			</div>
																			<div className="d-flex flex-wrap fs-14 font-weight-bold">
																				<div className="d-flex flex-nowrap">
																					{(this.state.assembWidth == "" || this.state.assembWidth == null) ?
																						this.state.addAssembWidth === false ?
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										addAssembWidth: true
																									})
																								}}
																							>Click to add<span>&nbsp;x&nbsp;</span></span>
																							:
																							<AvForm>
																								<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																									<AvField
																										type="number"
																										bsSize="sm"
																										min="0.00"
																										pattern="[0-9]+([,\.][0-9]+)?" 
																										step="0.01"
																										placeholder="Enter Width"
																										name="newAssembWidth"
																										value={this.state.newAssembWidth}
																										onChange={(evt) => this.handleChange(evt, 'newAssembWidthIsValid')}
																										validate={{
																											required: { value: true, errorMessage: 'Please enter Width' }
																										}}
																										onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																									/>
																									<InputGroupAddon addonType="append">
																										<Button color="light" title="Update"
																											disabled={this.state.newAssembWidthIsValid ? false : true}
																											onClick={() => {
																												this.setState({
																													addAssembWidth: false
																												});
																												this.handleFieldsChange('addAssembWidth', this.state.newAssembWidth)
																											}}
																										>
																											<FontAwesomeIcon icon="check" fixedWidth />
																										</Button>
																										<Button color="light" title="Cancel" className="mx-0"
																											onClick={() => {
																												this.setState({
																													addAssembWidth: false
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="times" fixedWidth />
																										</Button>
																									</InputGroupAddon>
																								</InputGroup>
																							</AvForm>
																						:
																						<div >
																							{
																								this.state.editAssembWidth ?
																									<AvForm>
																										<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																											<AvField bsSize="sm" min="0.00" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" type="number" placeholder="Enter Height" value={this.state.assembWidthValue} name='assembWidthValue'
																												onChange={(evt) => this.handleChange(evt, 'assembWidthIsValid')}
																												validate={{
																													required: { value: true, errorMessage: 'Please enter Height' }
																												}}
																												onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																											/>
																											<InputGroupAddon addonType="append">
																												<Button color="light" title="Update"
																													disabled={this.state.assembWidthIsValid ? false : true}
																													onClick={() => {
																														this.setState({
																															editAssembWidth: false
																														})
																														this.handleFieldsChange('editAssembWidth', this.state.assembWidthValue)
																													}}
																												>
																													<FontAwesomeIcon icon="check" fixedWidth />
																												</Button>
																												<Button color="light" title="Cancel" className="mx-0"
																													onClick={() => {
																														this.setState({
																															editAssembWidth: false
																														})
																													}}
																												>
																													<FontAwesomeIcon icon="times" fixedWidth />
																												</Button>
																											</InputGroupAddon>
																										</InputGroup>
																									</AvForm>
																									:
																									<span className="fs-14 font-weight-bold editable-column"
																										onClick={() => {
																											this.setState({
																												editAssembWidth: true,
																												assembWidthValue: assembWidth && assembWidth != "" ? assembWidth : ""
																											})
																										}}
																									>{assembWidth && assembWidth != "" ? assembWidth : ""}
																										<span>&nbsp;x&nbsp;</span>
																									</span>
																							}
																						</div>
																					}
																				</div>
																				<div className="d-flex flex-nowrap">
																					{(this.state.assembHeight == "" || this.state.assembHeight == null) ?
																						this.state.addAssembHeight === false ?
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										addAssembHeight: true
																									})
																								}}
																							>Click to add<span>&nbsp;x&nbsp;</span></span>
																							:
																							<AvForm>
																								<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																									<AvField
																										type="number"
																										bsSize="sm"
																										min="0.00"
																										pattern="[0-9]+([,\.][0-9]+)?" 
																										step="0.01"
																										placeholder="Enter Height"
																										name="newAssembHeight"
																										value={this.state.newAssembHeight}
																										onChange={(evt) => this.handleChange(evt, 'newAssembHeightIsValid')}
																										validate={{
																											required: { value: true, errorMessage: 'Please enter Height' }
																										}}
																										onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																									/>
																									<InputGroupAddon addonType="append">
																										<Button color="light" title="Update"
																											disabled={this.state.newAssembHeightIsValid ? false : true}
																											onClick={() => {
																												this.setState({
																													addAssembHeight: false
																												});
																												this.handleFieldsChange('addAssembHeight', this.state.newAssembHeight)
																											}}
																										>
																											<FontAwesomeIcon icon="check" fixedWidth />
																										</Button>
																										<Button color="light" title="Cancel" className="mx-0"
																											onClick={() => {
																												this.setState({
																													addAssembHeight: false
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="times" fixedWidth />
																										</Button>
																									</InputGroupAddon>
																								</InputGroup>
																							</AvForm>
																						:
																						<div >
																							{
																								this.state.editAssembHeight ?
																									<AvForm>
																										<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																											<AvField bsSize="sm" min="0.00" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" type="number" placeholder="Enter Height" value={this.state.assembHeightValue} name='assembHeightValue'
																												onChange={(evt) => this.handleChange(evt, 'assembHeightIsValid')}
																												validate={{
																													required: { value: true, errorMessage: 'Please enter Height' }
																												}}
																												onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																											/>
																											<InputGroupAddon addonType="append">
																												<Button color="light" title="Update"
																													disabled={this.state.assembHeightIsValid ? false : true}
																													onClick={() => {
																														this.setState({
																															editAssembHeight: false
																														})
																														this.handleFieldsChange('editAssembHeight', this.state.assembHeightValue)
																													}}
																												>
																													<FontAwesomeIcon icon="check" fixedWidth />
																												</Button>
																												<Button color="light" title="Cancel" className="mx-0"
																													onClick={() => {
																														this.setState({
																															editAssembHeight: false
																														})
																													}}
																												>
																													<FontAwesomeIcon icon="times" fixedWidth />
																												</Button>
																											</InputGroupAddon>
																										</InputGroup>
																									</AvForm>
																									:
																									<span className="fs-14 font-weight-bold editable-column"
																										onClick={() => {
																											this.setState({
																												editAssembHeight: true,
																												assembHeightValue: assembHeight && assembHeight != "" ? assembHeight : ""
																											})
																										}}
																									>{assembHeight && assembHeight != "" ? assembHeight : ""}
																										<span>&nbsp;x&nbsp;</span>
																									</span>
																							}
																						</div>
																					}
																				</div>
																				<div className="d-flex flex-nowrap">
																					{(this.state.assembDepth == "" || this.state.assembDepth == null) ?
																						this.state.addAssembDepth === false ?
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										addAssembDepth: true
																									})
																								}}
																							>Click to add<span>&nbsp;{get_temp ? (get_temp === 'us_measurement_system')? 'inch' : 'mm' : 'inches'}&nbsp;</span></span>
																							:
																							<AvForm>
																								<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																									<AvField
																										type="number"
																										bsSize="sm"
																										min="0.00"
																										pattern="[0-9]+([,\.][0-9]+)?" 
																										step="0.01"
																										placeholder="Enter Depth"
																										name="newAssembDepth"
																										value={this.state.newAssembDepth}
																										onChange={(evt) => this.handleChange(evt, 'newAssembDepthIsValid')}
																										validate={{
																											required: { value: true, errorMessage: 'Please enter Depth' }
																										}}
																										onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																									/>
																									<InputGroupAddon addonType="append">
																										<Button color="light" title="Update"
																											disabled={this.state.newAssembDepthIsValid ? false : true}
																											onClick={() => {
																												this.setState({
																													addAssembDepth: false
																												});
																												this.handleFieldsChange('addAssembDepth', this.state.newAssembDepth)
																											}}
																										>
																											<FontAwesomeIcon icon="check" fixedWidth />
																										</Button>
																										<Button color="light" title="Cancel" className="mx-0"
																											onClick={() => {
																												this.setState({
																													addAssembDepth: false
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="times" fixedWidth />
																										</Button>
																									</InputGroupAddon>
																								</InputGroup>
																							</AvForm>
																						:
																						<div >
																							{
																								this.state.editAssembDepth ?
																									<AvForm>
																										<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																											<AvField bsSize="sm" min="0.00" type="number" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" placeholder="Enter Depth" value={this.state.assembDepthValue} name='assembDepthValue'
																												onChange={(evt) => this.handleChange(evt, 'assembDepthIsValid')}
																												validate={{
																													required: { value: true, errorMessage: 'Please enter Depth' }
																												}}
																												onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																											/>
																											<InputGroupAddon addonType="append">
																												<Button color="light" title="Update"
																													disabled={this.state.assembDepthIsValid ? false : true}
																													onClick={() => {
																														this.setState({
																															editAssembDepth: false
																														})
																														this.handleFieldsChange('editAssembDepth', this.state.assembDepthValue)
																													}}
																												>
																													<FontAwesomeIcon icon="check" fixedWidth />
																												</Button>
																												<Button color="light" title="Cancel" className="mx-0"
																													onClick={() => {
																														this.setState({
																															editAssembDepth: false
																														})
																													}}
																												>
																													<FontAwesomeIcon icon="times" fixedWidth />
																												</Button>
																											</InputGroupAddon>
																										</InputGroup>
																									</AvForm>
																									:
																									<span className="fs-14 font-weight-bold editable-column"
																										onClick={() => {
																											this.setState({
																												editAssembDepth: true,
																												assembDepthValue: assembDepth && assembDepth != "" ? assembDepth : ""
																											})
																										}}
																									>{assembDepth && assembDepth != "" ? assembDepth : ""}
																										<span>&nbsp;{get_temp ? (get_temp === 'us_measurement_system')? 'inch' : 'mm' : 'inches'}&nbsp;</span>
																									</span>
																							}
																						</div>
																					}
																				</div>
																			</div>
																		</div>
																	</div>
																</li>
																<li className="d-flex flex-wrap mb-2 mx-n2">
																	<div className="px-2 flex-120">
																		<span className="fs-14">Product Weight:</span>
																	</div>
																	<div className="col px-2">
																		<div className="mb-2">
																			<div className="fs-14 font-weight-bold">
																				Packed
																			</div>
																			<div className="d-flex flex-wrap fs-14 font-weight-bold">
																				<div className="d-flex flex-nowrap">
																					{(this.state.packedWeight == "" || this.state.packedWeight == null) ?
																						this.state.addPackedWeight === false ?
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										addPackedWeight: true
																									})
																								}}
																							>Click to add<span>&nbsp;Pounds&nbsp;</span></span>
																							:
																							<AvForm>
																								<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																									<AvField
																										type="number"
																										bsSize="sm"
																										min="0.00"
																										pattern="[0-9]+([,\.][0-9]+)?" 
																										step="0.01"
																										placeholder="Enter Weight"
																										name="newPackedWeight"
																										value={this.state.newPackedWeight}
																										onChange={(evt) => this.handleChange(evt, 'newPackedWeightIsValid')}
																										validate={{
																											required: { value: true, errorMessage: 'Please enter Weight' }
																										}}
																										onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																									/>
																									<InputGroupAddon addonType="append">
																										<Button color="light" title="Update"
																											disabled={this.state.newPackedWeightIsValid ? false : true}
																											onClick={() => {
																												this.setState({
																													addPackedWeight: false
																												});
																												this.handleFieldsChange('addPackedWeight', this.state.newPackedWeight)
																											}}
																										>
																											<FontAwesomeIcon icon="check" fixedWidth />
																										</Button>
																										<Button color="light" title="Cancel" className="mx-0"
																											onClick={() => {
																												this.setState({
																													addPackedWeight: false
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="times" fixedWidth />
																										</Button>
																									</InputGroupAddon>
																								</InputGroup>
																							</AvForm>
																						:
																						<div>
																							{
																								this.state.editPackedWeight ?
																									<AvForm>
																										<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																											<AvField bsSize="sm" min="0.00" type="number" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" placeholder="Enter weight" value={this.state.packedWeightValue} name='packedWeightValue'
																												onChange={(evt) => this.handleChange(evt, 'packedWeightIsValid')}
																												validate={{
																													required: { value: true, errorMessage: 'Please enter Weight' }
																												}}
																												onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																											/>
																											<InputGroupAddon addonType="append">
																												<Button color="light" title="Update"
																													disabled={this.state.packedWeightIsValid ? false : true}
																													onClick={() => {
																														this.setState({
																															editPackedWeight: false
																														})
																														this.handleFieldsChange('editPackedWeight', this.state.packedWeightValue)
																													}}
																												>
																													<FontAwesomeIcon icon="check" fixedWidth />
																												</Button>
																												<Button color="light" title="Cancel" className="mx-0"
																													onClick={() => {
																														this.setState({
																															editPackedWeight: false
																														})
																													}}
																												>
																													<FontAwesomeIcon icon="times" fixedWidth />
																												</Button>
																											</InputGroupAddon>
																										</InputGroup>
																									</AvForm>
																									:
																									<span className="fs-14 font-weight-bold editable-column"
																										onClick={() => {
																											this.setState({
																												editPackedWeight: true,
																												packedWeightValue: packedWeight && packedWeight != "" ? packedWeight : ""
																											})
																										}}
																									>{packedWeight && packedWeight != "" ? packedWeight : ""}
																										<span>&nbsp;Pounds&nbsp;</span>
																									</span>
																							}
																						</div>
																					}
																				</div>
																			</div>
																		</div>
																		<div className="mb-2">
																			<div className="fs-14 font-weight-bold">
																				Assembled
																			</div>
																			<div className="d-flex flex-wrap fs-14 font-weight-bold">
																				<div className="d-flex flex-nowrap">
																					{(this.state.assembWeight == "" || this.state.assembWeight == null) ?
																						this.state.addAssembWeight === false ?
																							<span className="fs-14 font-weight-bold editable-column"
																								onClick={() => {
																									this.setState({
																										addAssembWeight: true
																									})
																								}}
																							>Click to add<span>&nbsp;Pounds&nbsp;</span></span>
																							:
																							<AvForm>
																								<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																									<AvField
																										type="number"
																										bsSize="sm"
																										min="0.00"
																										pattern="[0-9]+([,\.][0-9]+)?" 
																										step="0.01"
																										placeholder="Enter Weight"
																										name="newAssembWeight"
																										value={this.state.newAssembWeight}
																										onChange={(evt) => this.handleChange(evt, 'newAssembWeightIsValid')}
																										validate={{
																											required: { value: true, errorMessage: 'Please enter Weight' }
																										}}
																										onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																									/>
																									<InputGroupAddon addonType="append">
																										<Button color="light" title="Update"
																											disabled={this.state.newAssembWeightIsValid ? false : true}
																											onClick={() => {
																												this.setState({
																													addAssembWeight: false
																												});
																												this.handleFieldsChange('addAssembWeight', this.state.newAssembWeight)
																											}}
																										>
																											<FontAwesomeIcon icon="check" fixedWidth />
																										</Button>
																										<Button color="light" title="Cancel" className="mx-0"
																											onClick={() => {
																												this.setState({
																													addAssembWeight: false
																												})
																											}}
																										>
																											<FontAwesomeIcon icon="times" fixedWidth />
																										</Button>
																									</InputGroupAddon>
																								</InputGroup>
																							</AvForm>
																						:
																						<div >
																							{
																								this.state.editAssembWeight ?
																									<AvForm>
																										<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																											<AvField bsSize="sm" min="0.00" pattern="[0-9]+([,\.][0-9]+)?" step="0.01" type="number" placeholder="Enter weight" value={this.state.assembWeightValue} name='assembWeightValue'
																												onChange={(evt) => this.handleChange(evt, 'assembWeightIsValid')}
																												validate={{
																													required: { value: true, errorMessage: 'Please enter Weight' }
																												}}
																												onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) && e.preventDefault()}
																											/>
																											<InputGroupAddon addonType="append">
																												<Button color="light" title="Update"
																													disabled={this.state.assembWeightIsValid ? false : true}
																													onClick={() => {
																														this.setState({
																															editAssembWeight: false
																														})
																														this.handleFieldsChange('editAssembWeight', this.state.assembWeightValue)
																													}}
																												>
																													<FontAwesomeIcon icon="check" fixedWidth />
																												</Button>
																												<Button color="light" title="Cancel" className="mx-0"
																													onClick={() => {
																														this.setState({
																															editAssembWeight: false
																														})
																													}}
																												>
																													<FontAwesomeIcon icon="times" fixedWidth />
																												</Button>
																											</InputGroupAddon>
																										</InputGroup>
																									</AvForm>
																									:
																									<span className="fs-14 font-weight-bold editable-column"
																										onClick={() => {
																											this.setState({
																												editAssembWeight: true,
																												assembWeightValue: assembWeight && assembWeight != "" ? assembWeight : ""
																											})
																										}}
																									>{assembWeight && assembWeight != "" ? assembWeight : ""}
																										<span>&nbsp;Pounds&nbsp;</span>
																									</span>
																							}
																						</div>
																					}
																				</div>
																			</div>
																		</div>
																	</div>
																</li>
															</ul>
														</div>
													</div>
												</Col>
												<Col lg={6}>
													<div className="wr-category-heading">
														<ul className="m-0 p-0">
															<li className="d-flex flex-wrap mb-2 mx-n2">
																<div className="px-2 flex-130">
																	<span className="fs-14">Product features:</span>
																</div>
																<div className="col px-2">
																	<span className="fs-14 font-weight-bold editable-column">{featuresSet ? "" : ""}</span>
																	{/*
                                                                                true 
                                                                                        ?
                                                                                        <span className="fs-14 font-weight-bold editable-column">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Possimus temporibus. </span>
                                                                                        :
                                                                                        <span className="fs-14 font-weight-bold editable-column"> Click to add </span>
                                                                            }
                                                                            <div hidden>
                                                                                <FormGroup>
                                                                                    <Input className="primary" bsSize="sm" type="textarea" rows="10" />
                                                                                </FormGroup>
                                                                                <div className="text-right mt-3">
                                                                                    <Button color="primary" size="sm">Submit</Button>
                                                                                    <Button color="primary" size="sm">Cancel</Button>
                                                                                </div>
																			</div>*/}
																</div>
															</li>
															{/*<li className="d-flex flex-wrap mb-2 mx-n2">
                                                                        <div className="px-2 flex-130">
                                                                            <span className="fs-14">Audio features:</span>
                                                                        </div>
                                                                        <div className="col px-2">
                                                                            {
                                                                                false
                                                                                        ?
                                                                                        <span className="fs-14 font-weight-bold editable-column"> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis labore placeat eveniet, earum debitis quisquam. </span>
                                                                                        :
                                                                                        <span className="fs-14 font-weight-bold editable-column"> Click to add </span>
                                                                            }
                                                                            <div hidden>
                                                                                <FormGroup>
                                                                                    <Input className="primary" bsSize="sm" type="textarea" rows="10" />
                                                                                </FormGroup>
                                                                                <div className="text-right mt-3">
                                                                                    <Button color="primary" size="sm">Submit</Button>
                                                                                    <Button color="primary" size="sm">Cancel</Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>*/}
														</ul>
													</div>
												</Col>
											</Row>
										</Col>
										:
										''
									}
								</Row>
							</div>
							<div className="mt-auto ml-auto wr-movie-less-info-about">
								<span role="button" onClick={() => this.setState({ toggleMoreLessInfo: !this.state.toggleMoreLessInfo })}>
									{this.state.toggleMoreLessInfo ? <><span className="text">less info</span><span className="icon-arrow-left"></span></> : <><span className="text">more info</span><span className="icon-arrow-right"></span></>}
								</span>
							</div>
						</div>
					</div>

					{/* <div className="floated-info-section d-flex align-items-center flex-wrap justify-content-center">
						<div className="image-area-show">
							<img src={`${about && about.media_dict && about.media_dict.length > 0 && Array.isArray(about.media_dict) && about?.media_dict[0] && about.media_dict[0].url}`}
								 className="img-fluid"
								 onError={(error) =>
									 (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
								 }
								 alt={`${about && about?.media_dict && about?.media_dict.length > 0 && about?.media_dict[0] && about.media_dict[0].caption ? about.media_dict[0].caption : 'no-image'}`}
							/>
						</div>
						<div className="image-area-crop fs-14">
							Help us to make WikiReviews a better place! <br /> Please upload an alternate image. Suggested Size 1600x1050.
						</div>
					</div> */}

					{!this.state.toggleMoreLessInfo ?
						about && about.media_dict && about.media_dict[0] && about.media_dict[0].url ?
							// If atleast one image uploaded remove hidden attr
							<div>
								<div className="floated-info-section d-flex align-items-center flex-wrap justify-content-center">
									<div className="image-area-show">
										<img src={about.media_dict[0].url}
											className="img-fluid"
											alt={about.media_dict[0].caption}
										/>
									</div>
									<div className="image-area-crop">
										Help us to make WikiReviews a better place! <br /> Please upload an alternate image. Suggested Size 1600x1050.
									</div>
								</div>
							</div>
							:
							// If no image uploaded everthis.props.toggleEnableFileUpload(false, true);
							< div className="floated-no-image-section">
								<Button onClick={() => this.toggleEnableFileUpload(false, true)} color="primary" size="lg" hidden={about && about.media_dict && about.media_dict[0] && about.media_dict[0].url}>Upload Images</Button>
								<div className="image-area-crop">
									Help us to make WikiReviews a better place! <br /> Please upload an alternate image. Suggested Size 1600x1050.
								</div>
							</div>
						:
						''
					}
				</Container>
			</div>

		)
	}
}

const mapState = (state) => ({
	album_types_list: state.user.album_types_list,
	album_type_data: state.user.album_type_data,
	my_privacy_setting: state.user.my_privacy_setting,
})

const mapProps = (dispatch) => ({
	update_detail: (type, id, data, message) => dispatch(update_detail(type, id, data, message)),	
	update_PDF_cut_sheet: (type, id, data, message) => dispatch(update_PDF_cut_sheet(type, id, data, message)),	
	delete_pdf: (pdfIds, isUpdatingPDF, type, id, data, message) => dispatch(delete_pdf(pdfIds, isUpdatingPDF, type, id, data, message)),
	add_social_website: (id, types, data) => dispatch(add_social_website(id, types, data)),
	delete_listing_Info: (id, type, lisitnID) => dispatch(delete_listing_Info(id, type, lisitnID)),
	get_media_children: (category, type) => dispatch(get_media_children(category, type)),
	get_uploaded_media: (data) => dispatch(get_uploaded_media(data)),
	get_album_types_list: () => dispatch(get_album_types_list()),
	get_album_type_data: (type, album, page) => dispatch(get_album_type_data(type, album, page)),
	delete_selected_gallery_media: (dataID) => dispatch(delete_selected_gallery_media(dataID)),
	add_media_to_gallery: (data) => dispatch(add_media_to_gallery(data)),
	add_media_to_item_gallery: (data, type, id) => dispatch(add_media_to_item_gallery(data, type, id)),
	get_my_privacy_list: () => dispatch(get_my_privacy_list())
})

export default connect(mapState, mapProps)(About);