import * as actions from "../actions/my_posts";

const initialState = {
  posts: [],
};

const posts = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_INITIAL_AUTH_NEW':
      return initialState;
      
    case actions.GET_POSTS:
      return { ...state, posts: action.payload };

    default:
      return state;
  }
};

export default posts;
