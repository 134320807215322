import React, { Component } from "react";
import { Map, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import Routing from "./RoutingMachine";
import { get_map_data } from '../../../actions/business';
import { connect } from "react-redux";
import { Icon } from "leaflet";
import osm from "../../atoms/osm-providers"

class LeafletMap extends Component {
  state = {
		zoom: 12,
		isMapInit: false,
		tolat: 0,
		tolon: 0,
		keyMAP: "",
		travelMode: "DRIVING",
		routes: [],
		toaddress : '',
		fromaddress : '',
		currentLat: this.props.currentLocationData ? this.props.currentLocationData.lat : 0 ,
		currentLong: this.props.currentLocationData ? this.props.currentLocationData.lng : 0 ,
		keyMAP: Math.random(),
		accountantId:0,
		about: null,
		accountantData: null,
		city:"",
		state: "",
		lat: 0,
		lng: 0,
		toDestinationId:0
  };
  saveMap = map => {
    this.map = map;
    this.setState({
      isMapInit: true
    });
  };

  componentWillReceiveProps(nextProps) {
	 if (nextProps.accountantDetails) {
			this.setState({
                about: nextProps.accountantDetails,
                //currentLat: nextProps.movieDetails && nextProps.movieDetails.location ? nextProps.movieDetails.location.lat : 0,
                //currentLong: nextProps.movieDetails && nextProps.movieDetails.location ? nextProps.movieDetails.location.lng : 0,
             });
        }
		if(nextProps.accountantId){
			this.setState({
                accountantId: nextProps.accountantId,
            });
		}
		
		if (nextProps.destinationDetails !== this.props.destinationDetails) {
		   this.setState({
				lat: nextProps.destinationDetails.latitude ? nextProps.destinationDetails.latitude : 0,
				lng: nextProps.destinationDetails.longitude ? nextProps.destinationDetails.longitude : 0,
				isMapInit: true,
				keyMAP: Math.random(),
				travelMode: nextProps.destinationDetails.travelMode,
				fromaddress : nextProps.destinationDetails.addressTo ? nextProps.destinationDetails.addressTo : 0,
			});
		}
		
		if (nextProps.todestinationDetails !== this.props.todestinationDetails) {
			this.setState({
				toDestinationId:nextProps.todestinationDetails.id ? nextProps.todestinationDetails.id : 0,
				tolat: nextProps.todestinationDetails.latitude ? nextProps.todestinationDetails.latitude : 0,
				tolon: nextProps.todestinationDetails.longitude ? nextProps.todestinationDetails.longitude : 0,
				toaddress : nextProps.todestinationDetails ? (nextProps.todestinationDetails.address1 + ',' + nextProps.todestinationDetails.city + ',' + nextProps.todestinationDetails.state +' '+ nextProps.todestinationDetails.zipcode + ',' + nextProps.todestinationDetails.country) : ''
			});
		}
		
		if (nextProps.currentLocationData !== this.props.currentLocationData) {
			this.setState({
				currentLat: nextProps.currentLocationData ? nextProps.currentLocationData.lat : 0 ,
				currentLong: nextProps.currentLocationData ? nextProps.currentLocationData.lng : 0 ,
			});
		}
	
  }

  initItinerary = (itinerary) => {
    this.props.get_map_data(itinerary)
    
  }


  render() {
    let { address,toDestinationId } = this.state;
	
	
    const positionCenter = [(this.state.currentLat + 0.0029792), (this.state.currentLong - 0.0171707)];
    const position = [this.state.lat, this.state.lng];
    const topostion = [this.state.tolat, this.state.tolon];
	const skater = new Icon({
            iconUrl: "https://stagingdatawikireviews.s3.amazonaws.com/images/map-W.png",
            iconSize: [30, 40]
        });
	const {accountantDetails} = this.props	
	return (
      <Map key={this.state.keyMAP} zoomControl={false} mode={this.state.travelMode} center={positionCenter} zoom={this.state.zoom} ref={this.saveMap} style={{ position: 'absolute', zIndex: '0' }}>
        <ZoomControl position="bottomright" />
         <TileLayer
                    url={osm.maptiler.url}
                    attribution={osm.maptiler.attribution}
				/>
			{accountantDetails && accountantDetails?.address && Array.isArray(accountantDetails?.address) && accountantDetails?.address.length > 0 ?
                    accountantDetails?.address.map((item, index) => {
						if(toDestinationId != 0 && toDestinationId == item?.id){
                        return (
						<Marker 
						  position={[
                                    item.latitude && item.latitude != null ? item.latitude : 0,
                                    item.longitude && item.longitude != null ? item.longitude : 0,
                          ]}
						key={index}
						icon={skater}
								>
						<Popup>
							  <span>{item?.name}</span>
							</Popup>
						  </Marker>	
							)
						}else{
						return (
					     <Marker 
						  position={[
                                    item.latitude && item.latitude != null ? item.latitude : 0,
                                    item.longitude && item.longitude != null ? item.longitude : 0,
                          ]}
						key={index}	
								>
							<Popup>
							  <span>{item?.name}</span>
							</Popup>
						  </Marker>	
							)	
						}
                    })
                    : (null)}
				{(this.state.lat != 0 && this.state.lat != null) && (this.state.lng != 0 && this.state.lng != null) ?			
				(<Marker position={position} >
					<Popup>
					  <span>{address}</span>
					</Popup>
				</Marker>) : ""}
		  
        {this.state.isMapInit && <Routing itineraryReady={this.initItinerary} from={topostion} to={position} map={this.map} travelType={this.state.travelMode} />}
      </Map>
    );
  }
}
const mapProps = (dispatch) => ({
  get_map_data: (data) => dispatch(get_map_data(data)),
});
export default connect(null, mapProps)(LeafletMap)