import React, { Component } from 'react';
import { Button, Form, Collapse, Input } from 'reactstrap';
import { callApi } from '../../utils/apiCaller';
import CollapseBasic from './collapse';
import { toast } from "react-toastify";
import EditBtn from './editBtn'
import DeleteBtn from './deleteBtn'
export default class CoWorkers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isToggleOpen: false,
            professionOptions: [],
            workingHereOptions: [],
            professionId: '',
            workHereId: '',
            firstName: '',
            lastName: '',
            isEdit: false,
			firstError: "",
			lastError: "",
        }
    }


    resetState = () => {
        this.setState({
            isToggleOpen: false,
            professionId: '',
            workHereId: '',
            firstName: '',
            lastName: '',
            isEdit: false
        })
    }


    componentDidMount() {
        this.fetchFormItems();
    }

    handleSubmit = async () => {
        let { professionId, workHereId, firstName, lastName } = this.state;
        const { id, listing_type } = this.props;
		var reg = /^[a-z]+$/i;
		if (firstName == "") {
		  this.setState({
			firstError:
			  "This field is required",
			});
		}else if(reg.test(firstName) == false) {
		  this.setState({
			firstError:
			  "Only Alphabets are allowed",
		  });
		}
		
		if(lastName == "") {
		  this.setState({
			lastError:
			  "This field is required",	
		  });
		}else if(reg.test(lastName) == false) {
		  this.setState({
			lastError:
			  "Only Alphabets are allowed",	
		  });
		}
		if(reg.test(firstName) && reg.test(lastName)){
		try {
            let url = `/api/professionalupdate/`;
            let data = {
                "employment_status": workHereId,
                "first_name": firstName,
                "last_name": lastName,
                "profession": professionId,
                "type": "professionalsemployee_form",
                "id": id
            };
            
            let response = await callApi(url, 'POST', data);
            
            if (response && response?.code !== 500) {
                let textItem = `${listing_type.slice(0, -1)}Id`;
                this.props.fetchByItemId({ [textItem]: id });
				//let message = response?.message;
				let message = "You added employee successfully";
				if(message != "undefined" || message != undefined){
					toast(`${message}`, {
					  autoClose: 3500,
					  className: "black-background",
					  bodyClassName: "red-hunt",
					  progressClassName: "cc",
					});
				}
                this.resetState(true)
            }

        } catch (err) {
            console.log(err);
        }
		}
		
    }

    handleUpdate = async () => {
        let { professionId, workHereId, firstName, lastName, isEdit } = this.state;
        const { id, listing_type } = this.props;
		var reg = /^[a-z]+$/i;
		if (firstName == "") {
		  this.setState({
			firstError:
			  "This field is required",
			});
		}else if(reg.test(firstName) == false) {
		  this.setState({
			firstError:
			  "Only Alphabets are allowed",
		  });
		}
		
		if(lastName == "") {
		  this.setState({
			lastError:
			  "This field is required",	
		  });
		}else if(reg.test(lastName) == false) {
		  this.setState({
			lastError:
			  "Only Alphabets are allowed",	
		  });
		}
		if(reg.test(firstName) && reg.test(lastName)){
        try {
            let url = `/api/professionalsemployee/${isEdit?.id}/`;
            let data = {
                "first_name": firstName,
                "last_name": lastName,
                "profession": professionId,
                "employment_status": workHereId,
                "id": isEdit?.id
            };

            let response = await callApi(url, 'POST', data);
            if (response && response?.code !== 500) {
				let message = response?.message;
				if(message != "undefined" || message != undefined){
					toast(`${message}`, {
					  autoClose: 3500,
					  className: "black-background",
					  bodyClassName: "red-hunt",
					  progressClassName: "cc",
					});
				}
                this.resetState(true)
                let textItem = `${listing_type.slice(0, -1)}Id`;
                this.props.fetchByItemId({ [textItem]: id });
            }

        } catch (err) {
            console.log(err);
        }
		}
    }


    fetchFormItems = async () => {
        try {
            let url = `/form/professionalsemployee`;
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {
                
                const doc = new DOMParser().parseFromString(response?.data, "text/html");
                if (doc) {
                    let id_profession = doc.getElementById('id_profession')?.options;
                    let id_employment_status = doc.getElementById('id_employment_status')?.options;

                    let professionOptions = [];
                    let workingHereOptions = [];
                    if (id_profession && id_profession?.length) {
                        for (let item of id_profession) {
                            professionOptions.push({ value: item?.value, text: item?.childNodes[0]?.data || '' });
                        }
                    }

                    if (id_employment_status && id_employment_status?.length) {
                        for (let item of id_employment_status) {
                            workingHereOptions.push({ value: item?.value, text: item?.childNodes[0]?.data || '' });
                        }
                    }
                    this.setState({
                        professionOptions,
                        workingHereOptions
                    })
                }
            }
        } catch (err) {
            console.log({ err });
        }
    }

    deleteItems = async (edit_id) => {
        const { id, listing_type } = this.props;

        try {
            let url = `/api/professionalsemployee/${edit_id}/`;
            let response = await callApi(url, 'DELETE');
            if (response && response?.code !== 500) {
                this.resetState()
                
                let textItem = `${listing_type.slice(0, -1)}Id`;
                this.props.fetchByItemId({ [textItem]: id });
                let message = response?.message;
                if(message != "undefined" || message != undefined){
					toast(`${message}`, {
					  autoClose: 3500,
					  className: "black-background",
					  bodyClassName: "red-hunt",
					  progressClassName: "cc",
					});
				}
            }
        } catch (err) {
            console.log(err);
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const { itemDetail, data } = this.props;
        let { professionOptions, workingHereOptions, professionId, workHereId, firstName, lastName, isEdit, isToggleOpen } = this.state;
        
        let listingItems = data;

        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic
                    title={`${itemDetail?.firstname || ''}'s co-workers`}
                    containerClass="bg-transparent type-secondary" bodyClass="text-secondary" isOpen={false}>
                    <ul className="list-unstyled">
                        {listingItems && Array.isArray(listingItems) && listingItems?.length ?

                            listingItems.map((item, item_listing_index) => (
                                <li key={item_listing_index} className="mb-3 py-2 border-bottom border-secondary">
                                    <div className="d-flex flex-nowrap mx-n2">
                                        <div className="px-2 mt-1 mb-2">
                                            <img
                                                src={"https://userdatawikireviews.s3.amazonaws.com/images/friend-photo.png"}
                                                alt=""
                                                width={50}
                                                height={50}
                                                style={{ objectFit: 'cover', objectPosition: 'center center' }}
                                            />
                                        </div>
                                        <div className="d-flex flex-fill px-2 mx-n1">
                                            <div className="text-secondary-dark px-1">
                                                <div className="font-weight-bold fs-14 ff-base lh-1-2 mb-1">
                                                    <span>{`${item?.first_name} ${item?.last_name}`}</span> <br />
                                                    <span>{item?.profession}</span>
                                                </div>
                                                <div className="fs-13">
                                                    {item?.employment_status}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2 col-auto ml-auto">
                                            <div className="interactive">
                                                <div className="interactive-appear">
                                                    <div className="text-nowrap" hidden={!this.checkIfLoggedIn()}>
                                                        <EditBtn
                                                            onClick={() => {
                                                                let text = item?.profession;
                                                                let result = professionOptions.find(dt => dt.text == text);
                                                                this.setState({
                                                                    isToggleOpen: !this.state.isToggleOpen,
                                                                    isEdit: item,
                                                                    professionId: result?.value,
                                                                    workHereId: item?.employment_status,
                                                                    firstName: item?.first_name,
                                                                    lastName: item?.last_name
                                                                })
                                                            }}
                                                            color="gold" />
                                                        <DeleteBtn
                                                            onClick={() => {
                                                                this.deleteItems(item?.id);
                                                            }}
                                                            color="gold" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))

                            :
                            null
                        }
                    </ul>

                    <div>
                        <Button
                            color="link"
                            onClick={() => { 
                                if(!this.checkIfLoggedIn()){
                                    this.props.forceToggleLoginModal();
                                    return false;
                                }
                                this.setState({ isToggleOpen: !this.state.isToggleOpen }) }}
                            className="text-secondary-dark ff-alt fs-13 font-weight-normal p-0 hover-blue _not-icon">
                            <span className="icon-add-new"></span>
                            {`Add Employee`}
                        </Button>

                        <Collapse isOpen={isToggleOpen}>
                            {/* Add/Edit Employee */}
                            <div className="shadow bg-white p-3 mt-2">
                                <Form>
                                    <h5 className="text-secondary-dark fs-30 mb-3">
                                        {`${isEdit ? 'Edit' : 'Add'} Professional`}
                                    </h5>
                                    <div className="text-dark">
                                        <div className="mb-2">
                                            {/* <Label size="sm" className="font-weight-bold mb-0 ff-base">Enter First Name</Label> */}
                                            <Input
                                                bsSize="sm"
                                                type="text"
                                                name="fName"
                                                value={firstName}
                                                onChange={(e) => {
                                                    this.setState({ firstName: e.target.value,firstError:"" })
                                                }}
                                                placeholder="Enter First Name"
                                            />
											<span style={{color:'red'}}>{this.state.firstError}</span>
                                        </div>
                                        <div className="mb-2">
                                            {/* <Label size="sm" className="font-weight-bold mb-0 ff-base">Enter Last Name</Label> */}
                                            <Input
                                                bsSize="sm"
                                                type="text"
                                                name="lName"
                                                value={lastName}
                                                onChange={(e) => {
                                                    this.setState({ lastName: e.target.value,lastError:"" })
                                                }}
                                                placeholder="Enter Last Name"
                                            />
											<span style={{color:'red'}}>{this.state.lastError}</span>
                                        </div>
                                        <div className="mb-2">
                                            {/* <Label size="sm" className="font-weight-bold mb-0 ff-base">Choose Profession</Label> */}
                                            <Input
                                                onChange={(e) => {
                                                    this.setState({
                                                        professionId: e.target.value
                                                    })
                                                }}
                                                value={professionId}
                                                bsSize="sm" type="select" name="chooseProfession">
                                                {professionOptions && Array.isArray(professionOptions) && professionOptions?.length ?
                                                    professionOptions.map((item, item_options_index) => (
                                                        <option key={item_options_index} value={item?.value}>{item?.text}</option>
                                                    ))
                                                    : null}
                                            </Input>
                                        </div>
                                        <div className="mb-2">
                                            {/* <Label size="sm" className="font-weight-bold mb-0 ff-base">Status</Label> */}
                                            <Input
                                                onChange={(e) => {
                                                    this.setState({
                                                        workHereId: e.target.value
                                                    })
                                                }}
                                                value={workHereId}
                                                bsSize="sm" type="select" name="workingHere">
                                                {workingHereOptions && Array.isArray(workingHereOptions) && workingHereOptions?.length ?
                                                    workingHereOptions.map((item, item_index_working) => (
                                                        <option key={item_index_working} value={item?.value}>{item?.text}</option>
                                                    ))
                                                    : null}
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <div className="text-right">
                                            <Button
                                                onClick={() => {
                                                    if (!isEdit) {
                                                        this.handleSubmit();
                                                    } else {
                                                        this.handleUpdate();
                                                    }
                                                }}
                                                size="sm" color="primary mr-2">
                                                {'Submit'}
                                            </Button>
                                            <Button
                                                onClick={this.resetState}
                                                size="sm" color="primary ml-0" id="addEditEmployeeToggler">
                                                {'Cancel'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                                <hr />
                            </div>
                        </Collapse>
                    </div>
                </CollapseBasic>
            </div>
        )
    }
}
