import { MapLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import { withLeaflet } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

class Routing extends MapLayer {
  constructor(props) {

    super(props)
    this.state = {
      embedBody: [],
    }

  }

  createLeafletElement() {
    const { map, from, to, travelType } = this.props;
    let travelMode = ""
    if (travelType && travelType != "") {

      if (travelType == 'DRIVING') {
        travelMode = 'driving'
      } else if (travelType == 'TRANSIT') {
        travelMode = 'driving'
      } else if (travelType == 'WALKING') {
        travelMode = 'walking'
      } else {
        travelMode = 'cycling'
      }
    }
    var bikerouter = L.Routing.mapbox(`pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw`);
    bikerouter.options.profile = `mapbox/${travelMode}`;
    let leafletElement = L.Routing.control({
      position: 'topright',
      router: bikerouter,
	  units: 'imperial',
      waypoints: [L.latLng(from[0], from[1]), L.latLng(to[0], to[1])],
      lineOptions: {
        styles: [{
          color: "#33BEFF",
          opacity: .8,
          weight: 6
        }]
      },
      addWaypoints: false,
      draggableWaypoints: false,
      routeWhileDragging: false,
      fitSelectedRoutes: false,
      showAlternatives: true,
      reverseWaypoints: true,
      show: true,
      altLineOptions: { styles: [{ color: 'blue', opacity: 0.5, weight: 2 }] },
    }).addTo(map.leafletElement);
    let props = this.props;
    leafletElement.on('routesfound', function (e) {
      props.itineraryReady(e.routes)
    });
    return leafletElement.getPlan();
  }

}

export default withLeaflet(Routing);