import { callApi } from "../utils/apiCaller";
import { getQueryString } from '../utils/validation';
import { toast } from "react-toastify";

export const REVIEW_ENTRY = "REVIEW_ENTRY";
export const GET_BROADCAST_CHANNEL = "GET_BROADCAST_CHANNEL";
export const GET_SEARCH_LOCAL = "GET_SEARCH_LOCAL";
export const GET_REVIEWS = "GET_REVIEWS";

export const review_entry_ = (data) => ({ type: REVIEW_ENTRY, payload: data });
export const review_entry = (...data) => {
  return (dispatch) => {
    callApi(getQueryString({ url: `/api/reviews/`, data }), "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        try {
          if (data[0]?.page) {
            response['page'] = data[0].page;
            return dispatch(review_entry_(response));
          }
        } catch (e) {
          console.log({ e })
        }
      }
    });
  };
};

export const get_broadcast_channel_ = (data) => ({ type: GET_BROADCAST_CHANNEL, payload: data });
export const get_broadcast_channel = () => {
  return (dispatch) => {
    callApi(`/api/getbroadcastchannel/`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(get_broadcast_channel_(response));
      }
    });
  };
};

export const post_review_group_myprofile = (id, data,profileID, categoryType,viewReviewsType) => {

  let categorySection
  if (["accountants", "Accountants", "doctors", "Doctors", "dentists", "Dentists", "lawyers", "Lawyers", "actors", "Actors"].includes(categoryType)) {
    categorySection = "professionalreviewgroup"
  } else {
    categorySection = "reviewgroup"
  }


    return (dispatch) => {
        callApi(`/api/${categorySection}/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(review_entry(
				  { entry: '', user: profileID, asset: viewReviewsType, page: 1, review: '', review_type: 'All', on_search_filter: 'undefined', }
				));
            }
        });
    }
}

export const add_vote = (data) => {
  return (dispatch) => {
    callApi(`/ratings/vote/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        //return dispatch(get_broadcast_channel());
      }
    });
  };
};

export const edit_review = (id, data, profileID, type="reviews",item) => {
  return (dispatch) => {
    callApi(`/api/${type}/${id}/`, "PUT", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let successMsg
        if(data.is_draft){
          successMsg = "You saved review as a Draft for"
        }else{
          successMsg = "You Edited a review of "
        }
        toast(`${successMsg} ${item.associated_to?.name}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(review_entry(
          { entry: '', user: profileID, asset: '', page: 1, review: '', review_type: 'All', on_search_filter: 'undefined', }
        ));
      }

      if(response && response.code == 400){
        const errorMessages = response.data?.non_field_errors?.join(', ');
        toast(errorMessages, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    });
  };
};

export const delete_my_review = (reviewId, profileID, type="reviews",viewReviewsType) => {
  return (dispatch) => {
    callApi(
      `/api/${type}/${reviewId}/`, "DELETE").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          toast(`Your Review deleted successfully!`, {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
          return dispatch(review_entry(
            { entry: '', user: profileID, asset: viewReviewsType, page: 1, review: '', review_type: 'All', on_search_filter: 'undefined', }
          ));

        }
      });
  };
};

export const get_searchReviews_ = (data) => ({ type: GET_SEARCH_LOCAL, payload: data });

export const get_searchReviews = (location, query, contentType) => {
  return (dispatch) => {
    if (contentType === "All") {
      callApi(`/api/search/?reviewHomeSearch=true&location_add=${location}&page=1&q=${query}&radius=&sensor=false&size=5`, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          dispatch(get_searchReviews_(response));
        }
      });
    } else {
      callApi(`/api/search/?content_type=${contentType}&searchPage=true&location_add=${location}&page=1&q=${query}&radius=&sensor=false&size=5`, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          dispatch(get_searchReviews_(response));
        }
      });

    }
  };
};

export const clear_search_reviews = () => {
  return (dispatch) => {
    dispatch(get_searchReviews_([]));
  };
};
export const get_reviews_ = (data) => {
  return {
    type: GET_REVIEWS,
    payload: data,
  };
};


export const get_reviews = (url) => {
  return (dispatch) => {
    callApi(`${url}`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_reviews_(response));
      }
    });
  };
};

