const initialState = {
    collage_items: null,
    movies_categories: null,
    movies_items: null,
    movies_collage_sub_items: null,
    products_list: null,
    get_cities: null,
    current_boxoffice_data: null,
};

const movies_listing = (state = initialState, action) => {

    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "FETCH_COLLAGE_ITEMS":
            state.collage_items = action.payload;
            return { ...state };

        case "FETCH_MOVIES_CATEGORIES":
            state.movies_categories = action.payload;
            return { ...state };

        case 'FETCH_MOVIES_LIST':
            state.movies_items = action.payload;
            return { ...state };

        case 'RESET_MOVIES':
            state.movies_items = null;
            return { ...state };

        case 'FETCH_MOVIES_COLLAGE_SUB_CATEGORY':
            state.movies_collage_sub_items = action.payload;
            return { ...state };

        case 'FETCH_PRODUCT_LIST':
            state.products_list = action.payload;
            return { ...state };

        case "FETCH_CITIES":
            state.get_cities = action.payload;
            return { ...state };

        case "FETCH_CURRENT_BOXOFFICE_DATA":
            state.current_boxoffice_data = action.payload;
            return { ...state };

        case "SET_MOVIES_LOADER":
            state.isLoading = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default movies_listing;