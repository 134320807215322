const initialState = {
    favorite_list: null,
    search_favorite_info: [],
    favorite_professional_list: null,
};


const favorite = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
		case "GET_FAVORITE_DATA":
			state.favorite_list = action.payload;
		return { ...state };
		
		case "GET_FAVORITE_PROFESSION_DATA":
			state.favorite_professional_list = action.payload;
		return { ...state };
			
		case "ADD_FAVORITE":
            return { ...state };
			
		case "SEARCH_FAVORITE":
            state.search_favorite_info = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default favorite;
