/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Row, Col, Button, Container, Modal, ModalBody, ModalFooter, FormGroup, Input, Label, Alert, ModalHeader } from 'reactstrap';
import CollapseBasic from '../atoms/collapse';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteBtn from "../atoms/deleteBtn";
import StarsRating from 'stars-rating';
import Moment from "react-moment";
import Carousel from 'react-multi-carousel';
import { get_project, get_community_review_projects, get_similar_projects, update_project_status, get_project_responses, add_project_responses, delete_project_responses, publish_project_responses } from "../../actions/communityReviewProjects";
import { get, find } from 'lodash';
import { my_profile } from "../../actions/user";
import { callApi } from '../../utils/apiCaller';
import RatingCollapse from '../atoms/ratingCollapse';
import AppHeader from "../oragnisms/dashboard/appHeader";
import Rating from 'react-rating';


const empty_star_rating = (<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "34px" }}>★</span>);

const full_star_rating = (<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "34px" }}>★</span>);

const responsive = {
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1,
        },
    }
};

const questionAnswers = {
    '1': 'Disagree strongly',
    '2': 'Disagree moderately',
    '3': 'Disagree a little',
    '4': 'Agree a little',
    '5': 'Agree moderately',
    '6': 'Agree strongly'
}
class ProjectDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            confirmDeleteModalToggle: false,
            secondCarouselMedia: [],
            viewMyPhotosModalnew: false,
            projectStopped: false,
            projectResponsesLoading: false,
            hideForm: false,
            reviewIndexForDelete: undefined,
            starRatingStarted: false,
            starRatingForm: {
                rating: 0,
                after: 0,
                answers: null,
                enableText: false,
                ratingText: 0,
                afterText: 0
            },
            prevY: 0,
            collapsedIds: {}
        }

    }

    componentWillReceiveProps(nextProps) {
        const { project_data, project_responses } = nextProps;
        if (project_data && Object.keys(project_data).length > 0 && !this.props.project_data) {
            let url = `/api/projects/?&created_by__id=${project_data.created_by.id}&is_draft=False`;
            this.props.get_community_review_projects(url);
            url = `/api/projects/?&is_draft=False&taxonomy=${project_data.taxonomy}`;
            this.props.get_similar_projects(url);
        }

        if (project_responses && Object.keys(project_responses).length > 0 && project_responses.results) {
            // this.setState({ projectResponsesLoading: false });
        }

        // if(project_responses && Object.keys(project_responses).length > 0 && project_responses.results && project_responses.results.length >= 0 && this.props.my_info && this.props.my_info.id){
        //     let userId = this.props.my_info.id;
        //     let hideForm = false;
        //     for(let ele of project_responses.results){
        //         if(ele.reviewerpersonality_set && (ele.reviewerpersonality_set.reviewer == userId)){
        //             hideForm = true;
        //             break;
        //         }
        //     };
        //     this.setState({ hideForm });
        // }
    }


    componentDidMount() {
        this.props.my_profile_info();
        const { params } = this.props.match;
        if (params.id) {
            this.props.get_project(params.id);
            this.props.get_project_responses(params.id).then(() => this.setState({ projectResponsesLoading: false }))
            this.setState({ projectResponsesLoading: true });
        }

        this.observer = new IntersectionObserver(
            this.handleObserver.bind(this),
            {
                root: null, rootMargin: "0px", threshold: 1.0
            }
        );
        this.observer.observe(this.refReview);

    }

    openSecondCarousal = (files, index) => {
		let carouselMedia = [];
        if (Array.isArray(files)) {
            carouselMedia = files;
        } else {
            carouselMedia.push(files);
        }
        let newArray = carouselMedia;
        if (carouselMedia.length > 1) {
            const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
            const array2 = (index !== carouselMedia.length - 1) ? carouselMedia.filter((item, i) => i > index) : [];
            newArray = [carouselMedia[index], ...array2, ...array1];
        }
        this.setState({ secondCarouselMedia: newArray, viewMyPhotosModalnew: true });
    }

    stopProject = () => {
        const { project_data } = this.props;
        const url = `/api/projects/?&created_by__id=${project_data.created_by.id}&is_draft=False`;
        const data = {
            project_status: 'Stopped',
            is_active: true
        };
        this.props.update_project_status(project_data.id, url, data);

        this.setState({
            projectStopped: true
        }, () => {
            this.props.get_project(project_data.id);
        });
    }

    ratingChanged = (newRating, type = 'before') => {
        const { starRatingForm } = this.state;
        const { project_data } = this.props;
        let key = '';
        if (type === 'before') {
            starRatingForm.rating = newRating;
            key = 'before_img';
        } else {
            starRatingForm.after = newRating;
            key = 'after_img';
        }
        let formData = new FormData();
        formData.append('score', newRating);
        formData.append('content_type', 'crp.projects');
        formData.append('object_pk', project_data?.id);
        formData.append('key', key);
        formData.append('timestamp', Date.now());
        formData.append('honeypot', '');
        // formData.append('security_hash', '');
        // formData.append('csrfmiddlewaretoken', '');

        this.save_ratings_vote(formData);
        this.setState({
            starRatingForm
        });
    }

    save_ratings_vote = async (data) => {
        let url = '/ratings/vote/';
        try {
            const response = await callApi(url, 'POST', data);
        } catch (e) {
            console.log(e);
        }
    }

    selectAnswers = (id, value) => {
        const { starRatingForm } = this.state;
        if (starRatingForm.answers === null) {
            starRatingForm.answers = {
                [id]: value
            }
        } else {
            starRatingForm.answers[id] = value;
        }
        this.setState({ starRatingForm });
    }

    cancelFormSubmit = () => {
        const { starRatingForm } = this.state;
        starRatingForm.rating = 0;
        starRatingForm.after = 0;
        starRatingForm.ratingText = 0;
        starRatingForm.afterText = 0;
        starRatingForm.answers = null;
        this.setState({ starRatingForm, starRatingStarted: false });
    }

    handleFormSubmit = type => {
        //e.preventDefault();
        const { project_data } = this.props;
        const { starRatingForm } = this.state;
        let projectquestionanswers_set = [];
        Object.keys(starRatingForm.answers).forEach(key => {
            let qData = {
                body: starRatingForm.answers[key],
                question_type: '10',
                type: 'projects',
                parent_id_id: key
            }
            projectquestionanswers_set.push(qData);
        });

        let data = {
            is_draft: type === 'draft' ? true : false,
            is_completed: 'true',
            reviewerpersonality_set: [],
            reviewerdemography_set: {
                taxonomy: []
            },
            projectquestionanswers_set: projectquestionanswers_set,
            project_id: project_data.id,
            project_title: project_data.title
        }
        this.props.add_project_responses(data);
        this.cancelFormSubmit();
    }

    deleteProjectResponse = () => {
        const { reviewIndexForDelete } = this.state
        const { delete_project_responses, project_responses, match } = this.props
        if (reviewIndexForDelete || reviewIndexForDelete === 0)
            delete_project_responses(get(match, 'params.id'), get(project_responses, `results[${reviewIndexForDelete}].is_draft`), get(project_responses, `results[${reviewIndexForDelete}].id`), reviewIndexForDelete)
                .then(() => this.setState({ confirmDeleteModalToggle: false }, () => this.cancelFormSubmit()))
    }

    publishProjectResponses = (reviewId, index) => {
        this.props.publish_project_responses(reviewId, index, this.props.project_data.id)
    }

    checkReviewFormVisibility = (userId) => {
        const { project_responses } = this.props
        if (project_responses && Array.isArray(project_responses.results) && project_responses.results.length > 0) {
            for (let ele of project_responses.results) {
                if (ele.reviewerpersonality_set && (ele.reviewerpersonality_set.reviewer == userId)) {
                    return true
                }
            }
            return false
        }
        else
            return false
    }

    handleObserver = (entities, observer) => {
        const { projectResponsesLoading, prevY } = this.state
        const y = entities[0].boundingClientRect.y;
        let fetch = false
        if (!projectResponsesLoading)
            if (prevY === 0 && Math.abs(prevY - y) > 200) {
                fetch = true;
            } else if (Math.abs(prevY) >= y) {
                fetch = true;
            }

        if (fetch) {
            if (this.props.project_responses?.next) {
                this.setState({ projectResponsesLoading: true })
                this.props.get_project_responses(undefined, this.props.project_responses.next.split('com')[1])
                    .finally(() => { this.setState({ projectResponsesLoading: false }) })
            }
        }
        this.setState({ prevY: y });
    }

    render() {
        const { project_data, projects_by_user, similar_projects, my_info, project_responses, } = this.props;
        const { projectStopped, projectResponsesLoading, starRatingStarted, starRatingForm, } = this.state;
        const excludeImages = [];
        if (project_data && project_data?.main_images && project_data?.main_images[0]) {
            excludeImages.push(project_data?.main_images[0].id)
        }
        if (project_data && project_data?.first_item_images && project_data?.first_item_images[0]) {
            excludeImages.push(project_data?.first_item_images[0].id)
        }
        if (project_data && project_data?.second_item_images && project_data?.second_item_images[0]) {
            excludeImages.push(project_data?.second_item_images[0].id)
        }
        return (
            <div className="grey-body">
                <AppHeader isEnableCover={false} />
                <header className="position-relative pt-5">
                    <div className="featured-blurred-bg featured-blurred-bg-shadow-none" style={{ backgroundImage: "url('https://userdatawikireviews.s3.amazonaws.com/media/content/27275063937_72c5083d6e_o.279b2088b3545aa65cf153c345aab94ab6b1f342.jpg')" }}>&nbsp;</div>
                    <Row noGutters>
                        <Col md={3}>
                            <div className="text-center h-100 bg-darker text-white p-4">
                                <div className="mb-2">
                                    <img className="img-circle _150x150" src={`https://stagingdatawikireviews.s3.amazonaws.com/media/${project_data?.created_by?.current_profile_pic?.file}`} onError={(error) =>
                                        (error.target.src = require("../../assets/images/icons/user-circle.png"))
                                    } alt={project_data?.created_by?.current_profile_pic?.caption ? project_data?.created_by?.current_profile_pic?.caption : ''} />
                                </div>
                                <h1>
                                    {project_data?.created_by?.user?.first_name} {project_data?.created_by?.user?.last_name}
                                </h1>
                                <p>
                                    joined <Moment format="MMMM YYYY">
                                        {project_data?.created_by?.joining_date}
                                    </Moment>
                                </p>
                                <div>
                                    <span>
                                        <strong>{project_data?.project_count} Project{project_data?.project_count == 1 ? '' : 's'}</strong> added
                                        </span>
                                </div>
                                <div className="mt-3">
                                    <span>
                                        <strong>{project_data?.reviewed_count} Project{project_data?.reviewed_count == 1 ? '' : 's'}</strong> reviewed
                                        </span>
                                </div>
                            </div>
                        </Col>
                        <Col md={9}>
                            <div className="h-100 text-white fs-14 p-4" style={{ backgroundColor: 'rgba(0, 22, 43, 0.8)' }}>
                                <Row>
                                    <Col lg={3}>
                                        <div className="mb-3">
                                            <span className="font-weight-bold">
                                                {project_data?.title}
                                            </span>
                                        </div>
                                        <div className="mb-3">
                                            <span>Overall Rating:</span>
                                            <br />
                                            <img title={project_data?.rating_dict?.average && project_data?.rating_dict?.average[1] ? project_data?.rating_dict?.average[1] : 'No Rating'} src={project_data?.rating_dict?.average && (project_data?.rating_dict?.average[1] !== 'No Rating') ? require(`../../assets/images/icons/star/white/${Math.floor(project_data?.rating_dict?.average[1])}rating.png`) : require("../../assets/images/icons/star/white/norating.png")} alt="" />
                                        </div>
                                        <p className="ff-base">
                                            Uploaded by
                                                <br />
                                            <span className="font-weight-bold">{project_data?.user_full_name}</span><br /> {project_data?.sent_at}
                                        </p>
                                        <p className="ff-base">
                                            Categories
                                                <br />
                                            <span className="font-weight-bold">{project_data?.taxonomy_tree?.length > 0 && project_data?.taxonomy_tree.map((item, i) => {
                                                return <span key={i}>{item}{i === project_data?.taxonomy_tree.length - 1 ? '' : <span>,<br /></span>}</span>
                                            })}</span>
                                        </p>
                                        <div>
                                            <span>{project_data?.project_response_count} response{(project_data?.project_response_count == 1 || project_data?.project_response_count == 0) ? '' : 's'}</span>
                                        </div>
                                        {/* {!projectStopped && my_info && project_data?.project_status && project_data?.project_status == 'In Progress' && project_data?.created_by?.id == my_info?.id && ( */}
                                        <div>
                                            <Button color="primary" onClick={this.stopProject}>stop project</Button>
                                        </div>
                                        {/* )} */}
                                    </Col>

                                    <Col lg={9}>
                                        <div className="d-lg-flex mx-n2">
                                            {project_data?.main_images?.length > 0 && (
                                                <div className="flex-lg-400 px-2">
                                                    <figure className="figure" role="button">
                                                        <figcaption className="figure-caption text-light">Main Images</figcaption>
                                                        {project_data?.main_images[0].type === 'image' && (
                                                            <img src={project_data?.main_images[0].url} className="project-main-img mb-2" alt={project_data?.main_images[0].caption} onClick={() => this.openSecondCarousal(project_data?.media_dict.find(media => media.id === project_data?.main_images[0].id), 0)}
                                                            onError={(error) =>
                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                        }
															/>
                                                        )}
                                                        {project_data?.first_item_images?.length > 0 && project_data?.first_item_images[0].type === 'image' && (
                                                            <img src={project_data?.first_item_images[0].url} className="project-main-img mb-2" alt={project_data?.first_item_images[0].caption} onClick={() => this.openSecondCarousal(project_data?.media_dict.find(media => media.id === project_data?.first_item_images[0].id), 0)}
                                                            onError={(error) =>
                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                        }
															/>
                                                        )}
                                                        {project_data?.second_item_images?.length > 0 && project_data?.second_item_images[0].type === 'image' && (
                                                            <img src={project_data?.second_item_images[0].url} className="project-main-img mb-2" alt={project_data?.second_item_images[0].caption} onClick={() => this.openSecondCarousal(project_data?.media_dict.find(media => media.id === project_data?.second_item_images[0].id), 0)}
                                                            onError={(error) =>
                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
															}
															/>
                                                        )}
                                                    </figure>
                                                </div>
                                            )}

                                            {project_data && project_data?.media_dict && project_data?.media_dict.length > 1 && (
                                                <div className="flex-lg-400-rest px-2">
                                                    <figcaption className="figure-caption text-light">Other Images</figcaption>
                                                    <Row className="wk-project-other-images">
                                                        {project_data?.media_dict && project_data?.media_dict.filter(item => !excludeImages.includes(item.id)).map((item, i) => (
                                                            <Col key={item.id}>
                                                                <div role="button" onClick={() => this.openSecondCarousal([item], 0)}>
                                                                    {item.type === 'image' && (
                                                                        <img className="fixed-height-img fixed-height-img-new w-100 object-fit-cover object-position-center is-100" src={item.url} alt={item.caption} />
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-right" hidden={project_data?.media_dict.length <= 1}>
                                    <Button color="link" className="px-0 text-reset" onClick={() => this.openSecondCarousal(project_data?.media_dict, 0)}>View All</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </header>

                <section className="py-4">
                    <Container>
                        <Row>
                            <Col md={4}>
                                <div className="bg-white-shaded-sm p-3 mb-3">
                                    <CollapseBasic title="Project Details" noHr={true} bodyClass="text-dark fs-14" containerClass="type-dark bg-transparent" isOpen={true} size="sm" noPadding={true}>
                                        <div className="mb-3">
                                            <div className="font-weight-bold mb-1">
                                                Project Information:
                                            </div>
                                            <div>
                                                {project_data?.info}
                                            </div>
                                        </div>
                                        {project_data?.first_img_description && (
                                            <div className="mb-3">
                                                <div className="font-weight-bold mb-1">
                                                    First Image Description:
                                                </div>
                                                <div>
                                                    {project_data?.first_img_description}
                                                </div>
                                            </div>
                                        )}
                                        {project_data?.second_img_description && (
                                            <div className="mb-3">
                                                <div className="font-weight-bold mb-1">
                                                    Second Image Description:
                                                </div>
                                                <div>
                                                    {project_data?.second_img_description}
                                                </div>
                                            </div>
                                        )}
                                    </CollapseBasic>
                                </div>

                                <div className="bg-white-shaded-sm p-3 mb-3">
                                    <h2 className="text-darker">Other Projects by User</h2>
                                    <hr className="bg-dark mt-2" />
                                    <ul className="list-unstyled">
                                        {project_data?.id && projects_by_user && Object.keys(projects_by_user).length > 0 && projects_by_user.results.length > 1 && projects_by_user.results.filter((p, i) => p.id !== project_data.id).slice(0, 3).map(pro => {
                                            return (
                                                <li className="py-2 border-bottom fs-14" key={pro.id}>
                                                    <div className="d-flex mx-n2">
                                                        <div className="px-2">
                                                            <Link className="fs-14 text-dark d-block" to={`/projects/${pro.id}`} target="_blank">
                                                                <img src={pro?.main_images[0]?.url ? pro?.main_images[0]?.url : `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`}
                                                                    className="object-fit-cover" width={50} height={50} title={pro?.main_images[0]?.caption} alt={pro?.main_images[0]?.caption} onError={(error) =>
                                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                    } />
                                                            </Link>
                                                        </div>
                                                        <div className="px-2">
                                                            <Link className="d-inline-block text-dark font-weight-bold ff-base" to={`/projects/${pro.id}`} target="_blank">{pro.title}</Link>

                                                            <div>
                                                                <img title={pro?.rating_dict?.average && pro?.rating_dict?.average[1] ? pro?.rating_dict?.average[1] : 'No Rating'} src={pro?.rating_dict?.average && (pro?.rating_dict?.average[1] !== 'No Rating') ? require(`../../assets/images/icons/star/blue/${Math.floor(pro?.rating_dict?.average[1])}rating.png`) : require("../../assets/images/icons/star/blue/norating.png")} alt="" />
                                                            </div>
                                                            <div>
                                                                <span className="text-secondary-dark ff-base"><span>{pro?.project_response_count}</span>&nbsp;Response{(pro?.project_response_count == 1 || pro?.project_response_count == 0) ? '' : 's'}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    {!projects_by_user && (<span className="text-secondary-dark fs-14">No Projects found!</span>)}
                                </div>
                                {project_data?.first_reviewer && project_data?.first_reviewer[0] && (
                                    <div className="bg-white p-3 mb-3">
                                        <div className="d-flex mx-n2 fs-14">
                                            <div className="px-2">
                                                <Link className="fs-14 text-dark d-block" to={`/people/${project_data?.first_reviewer[0].username}`} target="_blank">
                                                    <img src={project_data?.first_reviewer[0].profile_pic ? project_data?.first_reviewer[0].profile_pic : require("../../assets/images/icons/user-circle.png")}
                                                        onError={(error) =>
                                                            (error.target.src = require("../../assets/images/icons/user-circle.png"))
                                                        }
                                                        className="object-fit-cover rounded-circle" width={32} height={32} title={project_data?.first_reviewer[0].first_to_review} alt="" />
                                                </Link>
                                            </div>

                                            <div className="px-2">
                                                <Link className="d-inline-block text-dark font-weight-bold ff-base" to={`/people/${project_data?.first_reviewer[0].username}`} target="_blank">{project_data?.first_reviewer[0].first_to_review}</Link>
                                                <div>
                                                    <span className="text-primary font-weight-bold ff-base">1<sup>st</sup> to Review</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="bg-white-shaded-sm p-3 mb-3">
                                    <h2 className="text-darker">Similar Projects</h2>
                                    <hr className="bg-dark mt-2" />
                                    {(!similar_projects || similar_projects && Object.keys(similar_projects).length == 0 || similar_projects && Object.keys(similar_projects).length > 0 && similar_projects.results.length == 1) && (<span className="text-secondary-dark fs-14">No Projects found!</span>)}
                                    <ul className="list-unstyled">
                                        {project_data?.id && similar_projects && Object.keys(similar_projects).length > 0 && similar_projects.results.length > 1 && similar_projects.results.filter((p, i) => p.id !== project_data.id).slice(0, 3).map(pro => {
                                            return (
                                                <li className="py-2 border-bottom fs-14" key={pro.id}>
                                                    <div className="d-flex mx-n2">
                                                        <div className="px-2">
                                                            <Link className="fs-14 text-dark d-block" to={`/projects/${pro.id}`} target="_blank">
                                                                <img src={pro.main_images[0]?.url ? pro.main_images[0]?.url : `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`} className="object-fit-cover" width={50} height={50} title={pro.main_images[0]?.caption} alt="" onError={(error) =>
                                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                } />
                                                            </Link>
                                                        </div>
                                                        <div className="px-2">
                                                            <Link className="d-inline-block text-dark font-weight-bold ff-base" to={`/projects/${pro.id}`} target="_blank">{pro.title}</Link>

                                                            <div>
                                                                <img title={pro?.rating_dict?.average && pro?.rating_dict?.average[1] ? pro?.rating_dict?.average[1] : 'No Rating'} src={pro?.rating_dict?.average && (pro?.rating_dict?.average[1] !== 'No Rating') ? require(`../../assets/images/icons/star/blue/${Math.floor(pro?.rating_dict?.average[1])}rating.png`) : require("../../assets/images/icons/star/blue/norating.png")} alt="" />
                                                            </div>
                                                            <div>
                                                                <span className="text-secondary-dark ff-base"><span>{pro?.project_response_count}</span>&nbsp;Response{(pro?.project_response_count == 1 || pro?.project_response_count == 0) ? '' : 's'}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <div className="float-right mt-2">
                                        {project_data?.id && similar_projects && Object.keys(similar_projects).length > 0 && similar_projects.results.length > 2 ?
                                            <Button color="link" className="text-dark fs-13 p-0 font-weight-normal ff-alt" onClick={()=> this.setState({viewSimilarProjectModalToggle: true})}>
                                                View all
                                            </Button>
                                            :
                                            ""
                                        }
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </Col>

                            <Col md={8}>

                                {/* Write Test reivew */}
                                {!(this.checkReviewFormVisibility(get(my_info, 'id')) || (project_data && my_info && project_data?.created_by?.id == my_info?.id)) && (
                                    <div hidden={this.checkReviewFormVisibility(get(my_info, 'id')) || (project_data && my_info && project_data?.created_by?.id == my_info?.id)}>
                                        {/* Test Review, Part 1 */}
                                        <div className="bg-white p-3 mb-3" hidden={starRatingStarted}>
                                            <div className="d-flex flex-nowrap">
                                                <div className="flex-grow-1">
                                                    <h2 className="text-primary mb-3">Start your review of {project_data?.title}</h2>
                                                    <p className="text-dark ff-base">
                                                        <strong>Please share your thoughts.</strong>
                                                        <br />
                                            Be the first to review this project and you will receive your First to Review badge!
                                        </p>
                                                </div>
                                                <div className="ml-auto">
                                                    <img className="img-fluid" src={require("../../assets/images/icons/first-to-review.png")} alt="" />
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <h6 className="ff-base font-weight-bold fs-18 text-primary">Step 1. Rate this project! </h6>
                                                <hr className="bg-light mt-2" />
                                                <p className="text-muted fs-14">
                                                    Rate the item(s) in this project out of 5 stars.
                                    </p>

                                                {/* Star Input */}
                                                <div className="stars-rating-input _lg">
                                                    <span className="zero-rating-input fs-18" role="button" onClick={() => this.ratingChanged(0)}>Before
                                                    <FontAwesomeIcon icon="ban" />
                                                    </span>

                                                    <Rating
                                                        fractions={2}
                                                        emptySymbol={empty_star_rating}
                                                        fullSymbol={full_star_rating}
                                                        initialRating={this.state.starRatingForm.rating}
                                                        onHover={(newRating) => {
                                                            if (newRating !== undefined && newRating != starRatingForm.rating) {
                                                                this.setState({
                                                                    starRatingForm: {
                                                                        ...this.state.starRatingForm,
                                                                        ratingText: newRating,
                                                                        enableText: true
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                        onClick={(newRating) => {
                                                            this.setState(prevState => ({
                                                                starRatingStarted: true
                                                            }));
                                                            this.ratingChanged(newRating)
                                                        }}
                                                    />
                                                    <span className="label-dynamic-rating">
                                                        {(starRatingForm.ratingText == 0) && 'Oh, Hell No!'}
                                                        {(starRatingForm.ratingText == 0.5 || starRatingForm.ratingText == 1) && 'Definitely would not recommend!'}
                                                        {(starRatingForm.ratingText == 1.5 || starRatingForm.ratingText == 2) && 'It could have been a lot better!'}
                                                        {(starRatingForm.ratingText == 2.5 || starRatingForm.ratingText == 3) && 'Okay!'}
                                                        {(starRatingForm.ratingText == 3.5 || starRatingForm.ratingText == 4) && 'Very good!'}
                                                        {(starRatingForm.ratingText == 4.5 || starRatingForm.ratingText == 5) && "Can't get any better than this!"}
                                                    </span>
                                                </div>
                                                <div className="stars-rating-input _lg">
                                                    <span className="zero-rating-input fs-18" role="button" onClick={() => this.ratingChanged(0, 'after')}>After
                                                    <FontAwesomeIcon icon="ban" />
                                                    </span>
                                                    <Rating
                                                        fractions={2}
                                                        emptySymbol={empty_star_rating}
                                                        fullSymbol={full_star_rating}
                                                        initialRating={this.state.starRatingForm.after}
                                                        onHover={(newRating) => {
                                                            if (newRating !== undefined && newRating != starRatingForm.after) {
                                                                this.setState({
                                                                    starRatingForm: {
                                                                        ...this.state.starRatingForm,
                                                                        afterText: newRating,
                                                                        enableText: true
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                        onClick={(newRating) => {
                                                            this.setState(prevState => ({
                                                                starRatingStarted: true
                                                            }));
                                                            this.ratingChanged(newRating, 'after')
                                                        }}
                                                    />
                                                    <span className="label-dynamic-rating">
                                                        {(starRatingForm.afterText == 0) && 'Oh, Hell No!'}
                                                        {(starRatingForm.afterText == 0.5 || starRatingForm.afterText == 1) && 'Definitely would not recommend!'}
                                                        {(starRatingForm.afterText == 1.5 || starRatingForm.afterText == 2) && 'It could have been a lot better!'}
                                                        {(starRatingForm.afterText == 2.5 || starRatingForm.afterText == 3) && 'Okay!'}
                                                        {(starRatingForm.afterText == 3.5 || starRatingForm.afterText == 4) && 'Very good!'}
                                                        {(starRatingForm.afterText == 4.5 || starRatingForm.afterText == 5) && "Can't get any better than this!"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <RatingCollapse isOpen={starRatingStarted}>
                                            {/* Test Review, Part 2 */}
                                            <div>
                                                <div className="p-3">
                                                    <h2>You’re reviewing <span className="text-dark"> {project_data?.title}</span></h2>
                                                </div>

                                                <div className="bg-white mb-3">
                                                    <CollapseBasic title="Step 1. Rate this project!" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true}>

                                                        <p className="text-muted fs-14">
                                                            Rate the item(s) in this project out of 5 stars.
                                                </p>

                                                        {/* Star Input */}
                                                        <div className="stars-rating-input _lg">
                                                            <span className="zero-rating-input fs-18" role="button" onClick={() => this.ratingChanged(0)}>Before
                                                        <FontAwesomeIcon icon="ban" />
                                                            </span>
                                                            <Rating
                                                                start={0}
                                                                stop={5}
                                                                readonly={false}
                                                                fractions={2}
                                                                emptySymbol={empty_star_rating}
                                                                fullSymbol={full_star_rating}
                                                                initialRating={this.state.starRatingForm.rating}
                                                                // placeholderRating={this.state.starRatingForm.rating}
                                                                onHover={(newRating) => {
                                                                    if (newRating !== undefined && newRating != starRatingForm.rating) {
                                                                        this.setState({
                                                                            starRatingForm: {
                                                                                ...this.state.starRatingForm,
                                                                                ratingText: newRating
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                onClick={(newRating) => {
                                                                    this.ratingChanged(newRating)
                                                                }}
                                                            />
                                                            <span className="label-dynamic-rating">
                                                                {(starRatingForm.ratingText == 0) && 'Oh, Hell No!'}
                                                                {(starRatingForm.ratingText == 0.5 || starRatingForm.ratingText == 1) && 'Definitely would not recommend!'}
                                                                {(starRatingForm.ratingText == 1.5 || starRatingForm.ratingText == 2) && 'It could have been a lot better!'}
                                                                {(starRatingForm.ratingText == 2.5 || starRatingForm.ratingText == 3) && 'Okay!'}
                                                                {(starRatingForm.ratingText == 3.5 || starRatingForm.ratingText == 4) && 'Very good!'}
                                                                {(starRatingForm.ratingText == 4.5 || starRatingForm.ratingText == 5) && "Can't get any better than this!"}
                                                            </span>
                                                        </div>
                                                        <div className="stars-rating-input _lg">
                                                            <span className="zero-rating-input fs-18" role="button" onClick={() => this.ratingChanged(0, 'after')}>After
                                                        <FontAwesomeIcon icon="ban" />
                                                            </span>
                                                            <Rating
                                                                start={0}
                                                                stop={5}
                                                                readonly={false}
                                                                fractions={2}
                                                                emptySymbol={empty_star_rating}
                                                                fullSymbol={full_star_rating}
                                                                initialRating={this.state.starRatingForm.after}
                                                                onHover={(newRating) => {
                                                                    if (newRating !== undefined && newRating != starRatingForm.after) {
                                                                        this.setState({
                                                                            starRatingForm: {
                                                                                ...this.state.starRatingForm,
                                                                                afterText: newRating
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                onClick={(newRating) => {
                                                                    this.ratingChanged(newRating, 'after')
                                                                }}
                                                            />
                                                            <span className="label-dynamic-rating">
                                                                {(starRatingForm.afterText == 0) && 'Oh, Hell No!'}
                                                                {(starRatingForm.afterText == 0.5 || starRatingForm.afterText == 1) && 'Definitely would not recommend!'}
                                                                {(starRatingForm.afterText == 1.5 || starRatingForm.afterText == 2) && 'It could have been a lot better!'}
                                                                {(starRatingForm.afterText == 2.5 || starRatingForm.afterText == 3) && 'Okay!'}
                                                                {(starRatingForm.afterText == 3.5 || starRatingForm.afterText == 4) && 'Very good!'}
                                                                {(starRatingForm.afterText == 4.5 || starRatingForm.afterText == 5) && "Can't get any better than this!"}
                                                            </span>
                                                        </div>
                                                    </CollapseBasic>
                                                </div>
                                                <div className="bg-white mb-3">
                                                    <CollapseBasic title="Step 2. Answer a few questions" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true}>

                                                        <p className="text-muted fs-14">
                                                            {project_data && project_data.projectquestionanswers_set && project_data.projectquestionanswers_set[0] ? 'How strongly do you agree or disagree with the following questions?' : 'No questions'}
                                                        </p>
                                                        {project_data && project_data.projectquestionanswers_set && project_data.projectquestionanswers_set[0] && project_data.projectquestionanswers_set.map((que, i) => (
                                                            <div key={que.id} className="mb-3">
                                                                <Label className="text-muted">
                                                                    {que.related_taxonomy}
                                                                </Label>
                                                                <div className="text-center d-flex">
                                                                    {Object.keys(questionAnswers).map((k, i) => (
                                                                        <FormGroup check inline>
                                                                            <Label className="fs-14 text-dark" check>
                                                                                <Input className="checked-bold" type="radio" name={`personality-${que.id}`} value={`${k}`} onChange={() => this.selectAnswers(que.id, k)} checked={starRatingForm.answers && (starRatingForm.answers[que.id] == k)} />
                                                                                <br />
                                                                                <span>{questionAnswers[k]}</span>
                                                                            </Label>
                                                                        </FormGroup>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </CollapseBasic>
                                                </div>
                                                <div className="mt-3 text-right mb-3">
                                                    <Button color="primary" className="mw" onClick={this.cancelFormSubmit}>Cancel</Button>
                                                    <Button color="primary" className="mw" disabled={!(starRatingForm.answers && project_data.projectquestionanswers_set && (Object.keys(starRatingForm.answers).length === project_data.projectquestionanswers_set.length))} onClick={() => this.handleFormSubmit('draft')}>Save as draft</Button>
                                                    <Button color="primary" className="mw" disabled={!(starRatingForm.answers && project_data.projectquestionanswers_set && (Object.keys(starRatingForm.answers).length === project_data.projectquestionanswers_set.length))} onClick={() => this.handleFormSubmit('submit')}>Submit</Button>
                                                </div>
                                            </div>
                                        </RatingCollapse>
                                    </div>
                                )}


                                {/* All Responses */}
                                {project_responses && Object.keys(project_responses).length > 0 && project_responses.results && project_responses.results.length > 0 && project_responses.results.map((res, i) => {
                                    return (res.is_draft === false || res.is_draft && (get(my_info, 'id') === get(res, 'reviewerpersonality_set.reviewer'))) &&
                                        <div className="bg-white p-3 mb-4" key={res.id}>
                                            <div className="d-flex mx-n2">
                                                <div className="flex-lg-160 px-2">
                                                    <div className="superuser-block fs-14 clearfix">
                                                        <div className="user-info avatars">
                                                            <Link className={res.award_statistics && res.award_statistics.avatar} to={`/people/${res.reviewerdemography_set && res.reviewerdemography_set[0] && res.reviewerdemography_set[0].reviewer_username}`}>
                                                                <img className={`${res.award_statistics && res.award_statistics.avatar} rounded-circle`}
                                                                    src={get(res, 'reviewerdemography_set[0].reviewer_profile_pic')}
                                                                    alt="" title="" width="50" height="50"
                                                                    onError={(error) => (error.target.src = require("../../assets/images/icons/user-circle.png"))}
                                                                />

                                                                <div className="detail-rating2">
                                                                    <ul className="avatars-listing">
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/indium.png"
                                                                            alt="" />indium</li>
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/silver.png"
                                                                            alt="" />silver</li>
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/gold.png "
                                                                            alt="" />gold</li>
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/osmium.png"
                                                                            alt="" />osmium</li>
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/iridum.png"
                                                                            alt="" />iridum</li>
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/rhenium.png"
                                                                            alt="" />rhenium</li>
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/iridum.png"
                                                                            alt="" />iridum</li>
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/platinum.png"
                                                                            alt="" />platinum</li>
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/rhodium.png"
                                                                            alt="" />rhodium</li>
                                                                        <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/ruthenium.png"
                                                                            alt="" />ruthenium</li>
                                                                    </ul>
                                                                </div>
                                                            </Link>
                                                            <div>
                                                                <a href="javascript:void(0)" className="username text-dark fs-16">
                                                                    <strong className="us ">{get(res, 'reviewerdemography_set[0].reviewer_name', '')}</strong> <span> </span>
                                                                </a>
                                                            </div>

                                                            <div style={{ clear: 'both' }}></div>

                                                            {/* <span className="gems " >
                                                            <a href="#"><img
                                                                src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Black Opal.png" alt=""
                                                                title="Black Opal Jun 2020" /></a>
                                                            <small className="count ">8</small>
                                                            <div className="detail-rating-gems">
                                                                Rarest Gem Awards for Highest Number of Contributions in One Month.
                                                            <ul className="avatars-listing">
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img1.png"
                                                                            alt="" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img2.png"
                                                                            alt="" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img3.png"
                                                                            alt="" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img4.png"
                                                                            alt="" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img5.png"
                                                                            alt="" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img6.png"
                                                                            alt="" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img7.png"
                                                                            alt="" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img8_active.png"
                                                                            alt="" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img9.png"
                                                                            alt="" />
                                                                    </li>
                                                                    <li>
                                                                        <img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img10.png"
                                                                            alt="" />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </span> */}

                                                            {/* <span className="planets-right ">
                                                            <a href="#"><img
                                                                src="https://userdatawikireviews.s3.amazonaws.com/images/awards/Vega.png" alt=""
                                                                title="Vega Jun 2020" /></a>
                                                            <small className="count ">4</small>
                                                            <div className="detail-rating-planets">
                                                                Brightest Star Awards for Highest Number of Reviews Written in One Month.
                                                            <ul className="avatars-listing">
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img1.png"
                                                                        alt="" />
                                                                    </li>
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img2.png"
                                                                        alt="" />
                                                                    </li>
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img3.png"
                                                                        alt="" />
                                                                    </li>
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img4_active.png"
                                                                        alt="" />
                                                                    </li>
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img5.png"
                                                                        alt="" />
                                                                    </li>
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img6.png"
                                                                        alt="" />
                                                                    </li>
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img7.png"
                                                                        alt="" />
                                                                    </li>
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img8.png"
                                                                        alt="" />
                                                                    </li>
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img9.png"
                                                                        alt="" />
                                                                    </li>
                                                                    <li><img src="https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img10.png"
                                                                        alt="" />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </span> */}

                                                        </div>


                                                        {/* More details */}
                                                        <div hidden={this.state.collapsedIds.hasOwnProperty(res.id)}>
                                                            {/* <img className="pin-space" src="https://userdatawikireviews.s3.amazonaws.com/images/down_feed.png"
                                                    href="#;" /> */}
                                                            <div>
                                                                <a href="#" className="review-count text-muted">{get(res, 'reviewerdemography_set[0].reviewed_count', 0)}<span> reviews</span></a>
                                                            </div>
                                                            <div className="mb-2">
                                                                <a href="#" className="username text-muted">
                                                                    <span>{get(res, 'reviewerdemography_set[0].reviewer_address', '')}</span>
                                                                </a>
                                                            </div>
                                                            <div className="review-count mb-2">
                                                                <strong>2</strong> <span> Responses </span>
                                                            </div>
                                                            <div className="review-count mb-2">
                                                                <strong>{get(res, 'reviewerdemography_set[0].project_count', 0)}</strong> <span> Projects added </span>
                                                            </div>

                                                            <div style={{ clear: 'both' }}></div>


                                                            <ul className="by-country d-flex flex-wrap mx-n1 clearfix">
                                                                {
                                                                    get(res, 'award_statistics.all_badges') &&
                                                                    Object.keys(res.award_statistics.all_badges).map((key, index) => (
                                                                        <li key={index} className="px-1 mb-2">
                                                                            <img width="15" src={`https://userdatawikireviews.s3.amazonaws.com/images/awards/${key}.png`} alt=""
                                                                                title={`${key} ${res.award_statistics.all_badges[key][1]} times`} />
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                            {
                                                                Array.isArray(get(project_data, 'reviewer_demography["Age group"]')) && project_data.reviewer_demography["Age group"].length > 0 &&
                                                                <div className="fs-14 mb-2 mt-4">
                                                                    <span className="d-block">Age group</span>
                                                                    <span className="d-block">{project_data.reviewer_demography["Age group"].map((value, index) => (
                                                                        index > 0 ? ", " + value : value
                                                                    ))}</span>
                                                                </div>

                                                            }

                                                            {
                                                                Array.isArray(get(project_data, 'reviewer_demography["Business Category"]')) && project_data.reviewer_demography["Business Category"].length > 0 &&
                                                                <div className="fs-14 mb-2">
                                                                    <span className="d-block">Business Category</span>
                                                                    <span className="d-block">{project_data.reviewer_demography["Business Category"].map((value, index) => (
                                                                        index > 0 ? ", " + value : value
                                                                    ))}</span>
                                                                </div>
                                                            }

                                                            {
                                                                Array.isArray(get(project_data, 'reviewer_demography["Education"]')) && project_data.reviewer_demography["Education"].length > 0 &&
                                                                <div className="fs-14 mb-2">
                                                                    <span className="d-block">Education</span>
                                                                    <span className="d-block">{project_data.reviewer_demography["Education"].map((value, index) => (
                                                                        index > 0 ? ", " + value : value
                                                                    ))}</span>
                                                                </div>
                                                            }

                                                            {
                                                                Array.isArray(get(project_data, 'reviewer_demography["Midwest"]')) && project_data.reviewer_demography["Midwest"].length > 0 &&
                                                                <div className="fs-14 mb-2">
                                                                    <span className="d-block">Midwest</span>
                                                                    <span className="d-block">{project_data.reviewer_demography["Midwest"].map((value, index) => (
                                                                        index > 0 ? ", " + value : value
                                                                    ))}</span>
                                                                </div>
                                                            }

                                                            {
                                                                Array.isArray(get(project_data, 'reviewer_demography["Personal Income"]')) && project_data.reviewer_demography["Personal Income"].length > 0 &&
                                                                <div className="fs-14 mb-2">
                                                                    <span className="d-block">Personal Income</span>
                                                                    <span className="d-block">{project_data.reviewer_demography["Personal Income"].map((value, index) => (
                                                                        index > 0 ? ", " + value : value
                                                                    ))}</span>
                                                                </div>
                                                            }
															
															{	
															res?.reviewerdemography_set && Array.isArray(res?.reviewerdemography_set) && res?.reviewerdemography_set.length > 0 ?			
                                                            <div className="mt-4">
                                                                <a href={`/people/${res?.reviewerdemography_set[0]?.reviewer_username}`} className="view-profile">View User Profile</a>
                                                            </div>
															: 
															<div className="mt-4">
                                                                <a href="javascript:void(0)" className="view-profile">View User Profile</a>
                                                            </div>
															}
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="flex-lg-160-rest px-2">
                                                    <div className="d-flex flex-nowrap">
                                                        <div className="flex-grow-1 mr-2">
                                                            <div className="fs-14">
                                                                <span className="font-weight-bold text-dark">Response #{i + 1}</span>
                                                                <span className="text-muted"><span>&nbsp;</span>Submitted on
                                                            <Moment format=" MMM D,YYYY">
                                                                        {res.reviewed_date}
                                                                    </Moment></span>
                                                                {/* Show only if in  Drafts */}
                                                                {
                                                                    res.is_draft && (get(my_info, 'id') === get(res, 'reviewerpersonality_set.reviewer')) && <span className="text-dark font-weight-bold"><span>&nbsp;</span>[Draft]</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center">
                                                            {get(my_info, 'id') === get(res, 'reviewerpersonality_set.reviewer') && <DeleteBtn onClick={() => this.setState({ confirmDeleteModalToggle: !this.state.confirmDeleteModalToggle, reviewIndexForDelete: i })} />}
                                                            {/* Show hide for more/less details */}
                                                            {
                                                                this.state.collapsedIds.hasOwnProperty(res.id) ?
                                                                    <span onClick={() => {
                                                                        const { collapsedIds } = this.state
                                                                        delete collapsedIds[res.id]
                                                                        this.setState({ collapsedIds: collapsedIds })
                                                                    }}
                                                                        className="icon-plus" title="Expand"></span>
                                                                    :
                                                                    <span onClick={() => this.setState((state) => { return { collapsedIds: { ...state.collapsedIds, [res.id]: true } } })} className="icon-minus" title="Collapse"></span>

                                                            }
                                                        </div>
                                                    </div>


                                                    {/* More details */}
                                                    <div hidden={this.state.collapsedIds.hasOwnProperty(res.id)}>
                                                        <div className="mt-2 text-dark">
                                                            <div className="font-weight-bold mb-2">
                                                                1. Star Rating
                                                    </div>
                                                            <div className="fs-14 mb-2">
                                                                on a scale of 1-5 stars, how much do you like this?
                                                    </div>
                                                            <div className="fs-14 mb-2">
                                                                <Row>
                                                                    <Col md="6">
                                                                        <span className="mr-2">Before</span> <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${get(res, 'before_image_rating[0]')}`} alt="star" />
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <span className="mr-2">After</span> <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${get(res, 'after_image_rating[0]')}`} alt="star" />
                                                                    </Col>
                                                                </Row>
                                                                {/* <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${get(res,'after_image_rating[0]')}`} alt="" /> */}
                                                            </div>
                                                            <hr />
                                                        </div>
                                                        <div className="mt-2 text-dark">
                                                            <div className="font-weight-bold mb-2">
                                                                2. Additional Questions
                                                    </div>
                                                            {
                                                                Array.isArray(res.projectquestionanswers_set) && res.projectquestionanswers_set.length > 0 ?
                                                                    res.projectquestionanswers_set.map((question, index) => (
                                                                        <>
                                                                            <div key={index} className="fs-14 mb-2">
                                                                                {question.parent_taxonomy}
                                                                            </div>
                                                                            <div>
                                                                                <span className="font-weight-bold fs-14">
                                                                                    {questionAnswers[question.body]}
                                                                                </span>
                                                                            </div>
                                                                            <hr />
                                                                        </>
                                                                    ))
                                                                    : <span>No questions</span>
                                                            }

                                                        </div>
                                                    </div>

                                                    {/* Show only if in Drafts */}
                                                    {res.is_draft && (get(my_info, 'id') === get(res, 'reviewerpersonality_set.reviewer')) &&
                                                        <div className="text-right mt-3">
                                                            <Button onClick={() => this.publishProjectResponses(res.id, i)} color="primary">Publish</Button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                })}

                                {/* If loading */}
                                {/* {projectResponsesLoading && ( */}
                                <div ref={refReview => (this.refReview = refReview)}
                                    style={{ height: "100px", margin: "30px" }}>
                                    <Alert color="primary" hidden={!projectResponsesLoading}>
                                        Loading...
                                    </Alert>
                                </div>
                                {/* )} */}

                                {/* If no response */}
                                {!projectResponsesLoading && project_responses && Object.keys(project_responses).length > 0 && project_responses.results && project_responses.results.length == 0 && (
                                    <p className="text-secondary-dark">No Response to display.</p>
                                )}


                            </Col>
                        </Row>
                    </Container>
                </section>


                {/* Delete Confirmation Modal */}
                <Modal
                    size="sm"
                    isOpen={this.state.confirmDeleteModalToggle}
                    toggle={() => this.setState({ confirmDeleteModalToggle: !this.state.confirmDeleteModalToggle })}
                    className="text-center" modalClassName="backdrop-opaque top-shifted transition-none"
                >
                    <div className="bg-white modal-header">
                        <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
                        <button
                            className="btn btn-sm"
                            aria-label="Close"
                            onClick={() => this.setState({ confirmDeleteModalToggle: false })}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark">
                        Are you sure you want to delete the response?
                </ModalBody>
                    <ModalFooter className="bg-white">
                        <div className="text-center w-100">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={() => this.setState({ confirmDeleteModalToggle: false })}
                            >
                                Cancel
                        </Button>
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={this.deleteProjectResponse}
                            >
                                Yes
                        </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* View Media Modal */}
                <Modal
                    centered
                    isOpen={this.state.viewMyPhotosModalnew}
                    toggle={() => {
                        this.setState({ viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew })
                    }}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={this.state.secondCarouselMedia?.length > 1 ? true : false}
                            sliderClass={true ? "mx-auto" : ""}>
                            {/* Repeat this */}
                            {this.state.secondCarouselMedia?.length > 0 && this.state.secondCarouselMedia.map((med, i) => {
                                return (
                                    <div className="item fs-14 h-100 d-flex flex-column justify-content-center"
                                        key={med.id}
                                    >
                                        <div>
                                            <div className="d-flex text-white justify-content-between mt-3">
                                                <div className="mr-2 ff-alt">{med.caption ? med.caption : ''}</div>
                                                <div className="ff-base">{med.image_category ? med.image_category : ''}</div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                            {(med.media_type === "image" || med.type === "") && (
                                                <img
                                                    className="img-fluid shown-media"
                                                    src={med.url}
													onError={(error) =>
                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                        }
                                                    alt=""
                                                />
                                            )}
                                            {med.media_type === "video" && (
                                                <video className="img-fluid shown-media" controls>
                                                    <source src={med.url} type="video/mp4" />
                                                    {'Your browser does not support the video tag.'}
                                                </video>
                                            )}
                                        </div>
                                        <div className="text-center" style={{ color: "#d4dae6" }}>{med.description}</div>
                                        <div className="px-2 mt-3">
                                            <div className="d-flex flex-nowrap mx-n2">
                                                <div className="px-2 col d-flex flex-nowrap">
                                                    <div className="mr-2">
                                                        <img
                                                            className="img-circle _30x30"
                                                            src={med?.user?.user?.current_profile_pic
                                                                ? med?.user?.user?.current_profile_pic
                                                                : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="text-primary ff-base">
                                                            {` Uploaded by `}
                                                            <a className="text-reset" href="#">
                                                                <span className="font-weight-bold">
                                                                    {med.user?.user.first_name} {med.user?.user.last_name}
                                                                </span>{" "}
                                                            </a>
                                                        </div>
                                                        <div className="text-tertiary ff-base">
                                                            {med.upload_date}
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.secondCarouselMedia.length > 1 && (
                                                    <div className="px-2 col-auto">
                                                        <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                                                            {i + 1} of {this.state.secondCarouselMedia.length}
                                                        </h2>
                                                    </div>
                                                )}
                                                <div className="px-2 col">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </ModalBody>
                </Modal>

                {/* View Similar Projects Modal */}
                <Modal
                    centered
                    scrollable
                    isOpen={this.state.viewSimilarProjectModalToggle}
                    toggle={() => {
                        this.setState({ viewSimilarProjectModalToggle: !this.state.viewSimilarProjectModalToggle })
                    }}>
                    <ModalHeader className="text-uppercase" toggle={() => this.setState({ viewSimilarProjectModalToggle: !this.state.viewSimilarProjectModalToggle })}>Similar Projects</ModalHeader>
                    <ModalBody>
                        <ul className="list-unstyled d-flex flex-wrap mx-n2 px-2">
                            {project_data?.id && similar_projects && Object.keys(similar_projects).length > 0 && similar_projects.results.length > 1 && similar_projects.results.filter((p, i) => p.id !== project_data.id).map(pro => {
                                return (
                                    <li className="fs-14 mb-3" key={pro.id} style={{flex: '0 0 200px',width: 200}}>
                                        <div className="d-flex flex-column mx-n2">
                                            <div className="px-2">
                                                <Link className="fs-14 text-dark d-block" to={`/projects/${pro.id}`} target="_blank">
                                                    <img src={pro.main_images[0]?.url ? pro.main_images[0]?.url : `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`} className="object-fit-cover" width={100} height={100} title={pro.main_images[0]?.caption} alt="" onError={(error) =>
                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                    } />
                                                </Link>
                                            </div>
                                            <div className="px-2">
                                                <Link className="d-inline-block text-dark font-weight-bold ff-base" to={`/projects/${pro.id}`} target="_blank">{pro.title}</Link>

                                                <div>
                                                    <img title={pro?.rating_dict?.average && pro?.rating_dict?.average[1] ? pro?.rating_dict?.average[1] : 'No Rating'} src={pro?.rating_dict?.average && (pro?.rating_dict?.average[1] !== 'No Rating') ? require(`../../assets/images/icons/star/blue/${Math.floor(pro?.rating_dict?.average[1])}rating.png`) : require("../../assets/images/icons/star/blue/norating.png")} alt="" />
                                                </div>
                                                <div>
                                                    <span className="text-secondary-dark ff-base"><span>{pro?.project_response_count}</span>&nbsp;Response{(pro?.project_response_count == 1 || pro?.project_response_count == 0) ? '' : 's'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </ModalBody>
                </Modal>
            </div>
        )
    }

}

const mapState = (state) => ({
    project_data: state.communityReviewProjects.project_data,
    projects_by_user: state.communityReviewProjects.community_review_projects_data,
    similar_projects: state.communityReviewProjects.similar_projects,
    my_info: state.user.my_profile,
    project_responses: state.communityReviewProjects.project_responses,
});

const mapProps = (dispatch) => ({
    get_project: (id) => dispatch(get_project(id)),
    get_community_review_projects: (url) => dispatch(get_community_review_projects(url)),
    get_similar_projects: (url) => dispatch(get_similar_projects(url)),
    update_project_status: (id, url, data) => dispatch(update_project_status(id, url, data)),
    my_profile_info: () => dispatch(my_profile()),
    get_project_responses: (id, infiniteUrl) => dispatch(get_project_responses(id, infiniteUrl)),
    delete_project_responses: (id, is_draft, reviewid) => dispatch(delete_project_responses(id, is_draft, reviewid)),
    add_project_responses: (data) => dispatch(add_project_responses(data)),
    publish_project_responses: (reviewId, changeInIndex, id) => dispatch(publish_project_responses(reviewId, changeInIndex, id))
});


export default connect(mapState, mapProps)(ProjectDetail);
