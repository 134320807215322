import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Button, FormGroup, Input, Label, Form } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from "../../atoms/editBtn";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { add_movie_advisory, edit_movie_content } from './../../../actions/movies';
import { Link } from 'react-router-dom';

class TipsAndWarning extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tipsText: '',
            isEnable: false,
            toggleTips: false,
            spoilerCheck: false,
            toggleWarning: false,
            warningText: '',
            isWarningEnable: false,
            editForm: false,
            entryId: null,
            editTip: false,
            editWarning: false,
            detailList: props.data
        };
    }

    componentWillReceiveProps(props) {
        this.setState({detailList: props.data});
    }


    deleteEntry = (id, type) => {
        if (id, type) {
            let text = 'Are you sure you want to delete?';
            if (type == 'tips') {
                
                this.props.toggleConfirmModal({ text, uniqueKey: 'delete-movies-tips', id });
            }
            if (type == 'warning') {
                this.props.toggleConfirmModal({ text, uniqueKey: 'delete-movies-warning', id });
            }
        }
    }

    handleChange = (e) => {
        let text = e.target.value;
        if (text !== '') {
            this.setState({ tipsText: text, isEnable: true })
        }
        else {

            this.setState({ tipsText: text, isEnable: false })
        }
    }

    handleWarning = (e) => {
        let text = e.target.value;
        if (text !== '') {
            this.setState({ warningText: text, isWarningEnable: true })
        }
        else {

            this.setState({ warningText: text, isWarningEnable: false })
        }
    }

    submitTips = (e) => {
        e.preventDefault();
        let form_type = 'tipsform';
        let is_masked = this.state.spoilerCheck;
        let text = this.state.tipsText;
        let type = 'tips';
        let user_entry = this.props.id;
        let editform = false;
        let tips_id = this.state.entryId;
        let editForm = this.state.editForm;
        let movieName = this.props.name;
        
        if (text !== '' && !is_masked && form_type == 'tipsform' && type === 'tips' && user_entry && !editForm) {
            let data = { form_type, text, type, user_entry };
            this.props.add_movie_advisory(data,movieName);
            this.setState({ tipsText: '', toggleTips: false, spoilerCheck: false })
        }
        if (text !== '' && is_masked && form_type == 'tipsform' && type === 'tips' && user_entry && !editForm) {
            let data = { form_type, is_masked, text, type, user_entry };
            this.props.add_movie_advisory(data,movieName);
            this.setState({ tipsText: '', toggleTips: false, spoilerCheck: false, maskedText: true })
        }
        if (text !== '' && type == 'tips' && tips_id && editForm) {
            editform = true;
            let data = { editform,is_masked, text, tips_id, type };
            this.props.edit_movie_content(data,user_entry,movieName);
            this.setState({ tipsText: '', toggleTips: false, editForm: false,editTip: false, spoilerCheck: false })
        }
    }

    submitWarning = (e) => {
        e.preventDefault();
        let form_type = 'warningform';
        let text = this.state.warningText;
        let type = 'warning';
        let user_entry = this.props.id;
        let editform = false;
        let tips_id = this.state.entryId;
        let editForm = this.state.editForm;
        let movieName = this.props.name;
        

        if (text !== '' && form_type == 'warningform' && type === 'warning' && user_entry && !editForm) {
            let data = { form_type, text, type, user_entry };
            this.props.add_movie_advisory(data,movieName);
            this.setState({ warningText: '', toggleWarning: false })
        }
        if (text !== '' && type == 'warning' && tips_id && editForm) {
            editform = true;
            let data = { editform, text, tips_id, type };
            
            this.props.edit_movie_content(data,user_entry,movieName);
            this.setState({ warningText: '', toggleWarning: false, editForm: false, editWarning: false })
        }

    }

    toggleTips = () => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        let toggle = this.state.toggleTips;

        if (!toggle) {
            this.setState({ toggleTips: !this.state.toggleTips, tipsText: '' })
        }
        else {
            this.setState({ toggleTips: false, tipsText: '', editTip: false , spoilerCheck: false})
        }
    }
    toggleWarning = () => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        let toggle = this.state.toggleWarning;

        if (!toggle) {
            this.setState({ toggleWarning: !this.state.toggleWarning, warningText: '' })
        }
        else {
            this.setState({ toggleWarning: false, warningText: '', editWarning: false })
        }
    }

    addMask = () => {
        
        if (!this.state.spoilerCheck) {
            this.setState({ spoilerCheck: true })}
        else {
            this.setState({ spoilerCheck: false })
        }
    }

    maskedText = (id,index) => {
        let {detailList} = this.state;
        let getItem = detailList[index];
        if (getItem) {
            let filteredIndex = getItem.id == id;
            if (filteredIndex) {
                let itemObject = getItem;
                itemObject['is_masked'] = !itemObject?.is_masked;
                detailList[index] = itemObject;
                this.setState({ detailList });
            }
        }
        
    }

    editText = (text, type, id) => {
        if (text !== '' && type == 'tips') {
            this.setState({ toggleTips: true, tipsText: text, isEnable: true, editForm: true, entryId: id,editTip: true })
        }
        else if (text !== '' && type == 'warning') {
            this.setState({ toggleWarning: true, warningText: text, isWarningEnable: true, editForm: true, entryId: id, editWarning: true })
        }
        else {
            this.setState({ toggleWarning: false, toggleTips: false })
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_info} = this.props;
        if(my_profile_info && my_profile_info.id && authId && (authId == my_profile_info.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        let {detailList} = this.state;
        let profileId = this.props.my_profile_info;
        let { toggleTips, tipsText, isEnable, toggleWarning, warningText, isWarningEnable, editForm,editTip,editWarning,maskedText } = this.state;
        return (
            <div className="bg-movie-dark text-white p-3 mb-3">
                <h2 className="mr-2">Tips &amp; Warnings</h2>
                <hr className="bg-white" />
                <div className="mb-3">
                    <p className="ff-base">
                        {'Do you have any tips to offer for current or prospective owners? Any warnings concerning purchase or ownership of this product? List away!'}
                    </p>
                </div>

                <CollapseBasic title="Tips" noHr={true} containerClass="bg-transparent type-movie-white expanded-dark" bodyClass="mt-3 text-white" isOpen={true} size="sm">
                    <ul className="list-unstyled">
                        {detailList && Array.isArray(detailList) && detailList.length > 0 ?
                            detailList.map((item, index) => {
                                if (item.type == "tips") {
                                    return (<><li className="mb-2 ff-base" key={index}>
                                        <div className="border border-primary p-2">
                                            <div className="mb-2 position-relative">
                                                {/* If spoiler, sent data-blurr={true} */}
                                                <div className="wb-break-word" data-blurr={item.is_masked ? true : false}>
                                                    <span className="font-weight-bold">{index + 1}{". "}</span>{item.text}
                                                </div>
                                                {/* If spoiler, Show button */}
                                                <div>
                                                    {item.is_masked ?
                                                        <div className="bg-dark-alt font-weight-bold text-white text-center p-3 w-100 ff-base spoiler-alert" role="button" onClick={()=>this.maskedText(item.id,index)}>
                                                            <span className="text-secondary">Spoiler Alert,</span> read anyway?
                                                      </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex mx-n1">
                                                <div className="px-1">
                                                    {item?.profile_pic ?
                                                        <Link
                                                            to={{ pathname: `/people/${item.profile_url}` }}
                                                        >
                                                            <img className="img-circle _30x30" src={item.profile_pic} alt="" onError={(error) =>
                                                                (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                            } />
                                                        </Link>
                                                        :
                                                        <img className="img-circle _30x30" src={require("./../../../assets/images/icons/user-circle.png")} alt="" />
                                                    }

                                                </div>
                                                <div className="px-1 col">
                                                    by <Link
                                                        className="text-reset font-weight-bold"
                                                            to={{ pathname: `/people/${item.profile_url}` }}
                                                        >{item.username}</Link>
                                                    <br />
                                                    {item.created_on}
                                                </div>
                                                {profileId && profileId.id && profileId.id === item.user
                                                    ?
                                                    <div className="px-1">
                                                        <EditBtn color="light" onClick={() => this.editText(item.text, item.type, item.id)} />
                                                        <DeleteBtn color="light" onClick={() => this.deleteEntry(item.id, item.type)} />
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </li>
                                    {/* if tip by other user */}
                                    <li className="mb-2 ff-base" key={index} hidden>
                                        <div>
                                            <div className="mb-2 position-relative">
                                                {/* If spoiler, sent data-blurr={true} */}
                                                    <div className="wb-break-word" data-blurr={item.is_masked ? true : false}>
                                                    <span className="font-weight-bold">{index + 1}{". "}</span>{item.text}
                                                </div>
                                                {/* If spoiler, Show button */}
                                                <div>
                                                    {item.is_masked ?
                                                        <div className="bg-dark-alt font-weight-bold text-white text-center p-3 w-100 ff-base spoiler-alert" role="button" onClick={() => this.maskedText(item.id, index)}>
                                                            <span className="text-secondary">Spoiler Alert,</span> read anyway?
                                                      </div>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex mx-n1">
                                                <div className="px-1 col">
                                                    by <Link
                                                        className="text-reset font-weight-bold"
                                                        to={{ pathname: `/people/${item.profile_url}` }}
                                                    >{item.username}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    </>
                                    )
                                }
                            })
                            :
                            ""}


                    </ul>

                    <div className="text-right mb-2">
                        <Button color="link" className="p-0 text-white" onClick={this.toggleTips}>
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                            Add another
                                      </Button>
                    </div>
                    {toggleTips ?
                        <div className="bg-white p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">{editTip ? "Edit Your Tips" : "Add Another"}</h5>
                            <Form onSubmit={this.submitTips}>
                                <FormGroup>
                                    <Label className="text-secondary font-weight-bold">Enter Content <span className="text-danger">*</span></Label>
                                    <Input value={tipsText} onChange={this.handleChange} className="primary text-dark font-weight-bold ph-text-form" bsSize="sm" type="textarea" rows="4" placeholder="write something..." />
                                </FormGroup>
                                <FormGroup check>
                                    <Label className="text-dark font-weight-normal" check>
                                        <Input type="checkbox" onChange={this.addMask} checked={this.state.spoilerCheck} />{' '}
                                              My Content Contains Spoilers
                                          </Label>
                                </FormGroup>

                                <div className="text-right mt-3">
                                    <Button className="mw" color="primary" size="sm" disabled={!isEnable}>Submit</Button>
                                    <Button className="mw" color="primary" size="sm" onClick={this.toggleTips}>Cancel</Button>
                                </div>
                            </Form>
                        </div>

                        :
                        ""}

                </CollapseBasic>

                <hr className="bg-white" />

                <CollapseBasic title="Warnings" noHr={true} containerClass="bg-transparent type-movie-white expanded-dark" bodyClass="mt-3 text-white" isOpen={true} size="sm">
                    <ul className="list-unstyled">
                        {detailList && Array.isArray(detailList) && detailList.length > 0 ?
                            detailList.map((item, index) => {
                                if (item.type == "warning") {
                                    return (<li className="mb-2 ff-base" key={index}>
                                        <div className="border border-primary p-2">
                                            <div className="mb-2 position-relative">

                                                <div className="wb-break-word">
                                                    <span className="font-weight-bold">{index + 1}{". "}</span>{item.text}
                                                </div>

                                            </div>
                                            <div className="d-flex mx-n1">
                                                <div className="px-1">
                                                    {item?.profile_pic ?
                                                        <Link
                                                            to={{ pathname: `/people/${item.profile_url}` }}
                                                        >
                                                            <img className="img-circle _30x30" src={item.profile_pic} alt="" onError={(error) =>
                                                                (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                            } />
                                                        </Link>
                                                        :
                                                        <img className="img-circle _30x30" src={require("./../../../assets/images/icons/user-circle.png")} alt="" />
                                                    }

                                                </div>
                                                <div className="px-1 col">
                                                    by <Link
                                                        className="text-reset font-weight-bold"
                                                            to={{ pathname: `/people/${item.profile_url}` }}
                                                        >{item.username}</Link>
                                                    <br />
                                                    {item.created_on}
                                                </div>
                                                {profileId && profileId.id && profileId.id === item.user
                                                    ?
                                                    <div className="px-1">
                                                        <EditBtn color="light" onClick={() => this.editText(item.text, item.type, item.id)} />
                                                        <DeleteBtn color="light" onClick={() => this.deleteEntry(item.id, item.type)} />
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </li>
                                    )
                                }
                            })
                            :
                            ""}
                    </ul>

                    <div className="text-right mb-2">
                        <Button color="link" className="p-0 text-white" onClick={this.toggleWarning}>
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                                          Add another
                                      </Button>
                    </div>
                    {toggleWarning ?

                        <div className="bg-white p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">{editWarning ? "Edit Your Warning" : "Add Another"}</h5>
                            <Form onSubmit={this.submitWarning}>
                                <FormGroup>
                                    <Label className="text-dark ff-alt font-weight-bold">Enter Content <span className="text-danger">*</span></Label>
                                    <Input value={warningText} onChange={this.handleWarning} className="primary text-dark font-weight-bold ph-text-form" bsSize="sm" type="textarea" rows="4" placeholder="write something..." />
                                </FormGroup>

                                <div className="text-right mt-3">
                                    <Button className="mw" color="primary" size="sm" disabled={!isWarningEnable} >Submit</Button>
                                    <Button onClick={this.toggleWarning} className="mw" color="primary" size="sm">Cancel</Button>
                                </div>
                            </Form>
                        </div>
                        :
                        ""
                    }
                </CollapseBasic>
            </div>

        )
    }
}
const mapState = (state) => ({
    my_profile_info: state.user.my_profile
});

const mapProps = (dispatch) => ({
    add_movie_advisory: (data,name) => dispatch(add_movie_advisory(data,name)),
    edit_movie_content: (data, id,name) => dispatch(edit_movie_content(data, id,name)),
});


export default withRouter(connect(mapState, mapProps)(TipsAndWarning));