import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export default class ConfirmModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isToggleModalTrue: true,
            modalText: props.modalText,
        }
    }

    toggleModal = () => {
        this.setState({ toggleModal: !this.state.toggleModal }, () => {
            this.props.toggleConfirmModal({});
        })
    }

    handleOnConfirm = () => {
        let key = this.props.confirmModalKey
        this.props.toggleConfirmModal({});

        if (this.props?.handleOnConfirm) {
            this.props.handleOnConfirm(key);
        }
    }

    render() {
        let { isToggleModalTrue, modalText } = this.state;
        return (
            <>
                {/* <Modal size="sm" isOpen={isToggleModalTrue} toggle={this.toggleModal} modalClassName="backdrop-opaque top-shifted transition-none">
                    <ModalHeader toggle={this.toggleModal}></ModalHeader>
                    <ModalBody className="text-center text-dark">
                        <h2 className="mb-3">{'Confirmation'}</h2>
                        <p className="small">{modalText}</p>
                        <div className="pt-4">
                            <div>
                                <Button onClick={this.toggleModal} size="md" color="primary btn-confirm">Cancel</Button>
                                <Button size="md" color="primary btn-confirm" onClick={this.handleOnConfirm}>Ok</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal> */}

                <Modal
                    size="sm"
                    isOpen={isToggleModalTrue}
                    toggle={this.toggleModal}
                    className="text-center delete-box" modalClassName="backdrop-opaque top-shifted transition-none">
                    {/* <ModalHeader toggle={this.toggleModal}></ModalHeader> */}
                    <div className="bg-white modal-header">
                        <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
                        <button
                            className="btn"
                            aria-label="Close"
                            onClick={() => {
                                this.toggleModal({ text: null, type: null, item: null })
                            }}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark">
                        {modalText}
                    </ModalBody>
                    <ModalFooter className="bg-white">
                        <div className="text-center w-100">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={() => this.toggleModal({ text: null, type: null, item: null })}
                            >
                                {'Cancel'}
                            </Button>
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={this.handleOnConfirm}
                            >
                                {'Ok'}
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="sm" isOpen={this.state.nonVerifiedModalToggle} toggle={() => this.setState({ nonVerifiedModalToggle: !this.state.nonVerifiedModalToggle })} modalClassName="backdrop-opaque top-shifted transition-none">
                    <ModalBody className="text-center text-dark">
                        {/* <h2 className="mb-3">{'Confirmation'}</h2> */}
                        <p className="small mb-3">Uh-oh! It appears you have not verified your email address yet. Please click below to resend the activation link to verify your account</p>
                        <div className="pt-4">
                            <div>
                                <Button size="xs" color="primary">Resend Activation Link</Button>
                                <br/>
                                <Button size="xs" color="primary" onClick={() => this.setState({ nonVerifiedModalToggle: !this.state.nonVerifiedModalToggle })}>Cancel</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>


                
            </>
        )
    }
}
