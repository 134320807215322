import axios from "axios";
import classnames from "classnames";
import { gapi } from "gapi-script";
import qs from "qs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { withRouter } from "react-router";
import {
  Alert,
  Button, Card, CardBody, CardImg, CardTitle, Col, Input, InputGroup, Modal, ModalBody, ModalFooter, Nav, NavItem, NavLink, Row, TabContent, TabPane
} from "reactstrap";
import {
  already_on_wikireviews, clear_fetch_invited, block_user, clear_search_data, enable_fiend_friends_tab, fetch_connections, fetch_find_connection, fetch_following_count, fetch_import_count, fetch_invited, fetch_pending_req_count, fetch_sent_friend_req_count, remove_request, send_again_request, unfriend_user, get_providers_on_wikireviews,send_invitation_requests,send_single_invitation_request, search_find_friend_import_connections
} from "../../../actions/connections";
import { callApiAdvance } from "../../../utils/apiCaller";
import FindFriends from './findFriends'
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { API_URL } from "../../../utils/constants";
import { pushUniqueObjectsInArray } from "../../../utils/utilities";

class Connections extends Component {
  constructor(props) {
    super(props);
    if (props.enableFindFriends) window.scrollTo(0, 0);
    this.state = {
      isLoadingConnectionData: true,
      isLoadingFilterFetch: true,
      isLoadingPendingRequest: true,
      isLoadingSentRequest: true,
      isLoadingFollowers: true,
      activeTab: props.enableFindFriends ? "4" : "1",
      connections: null,
      findConnections: null,
      followingCount: null,
      importCount: null,
      pendingRequestCount: null,
      sentFriendRequestCount: null,
      myProfileInfo: null,
      pendingRequests: null,
      fetchInvited: null,
      localSearch: "",
      importConnectionSearch: "",
      findFriendsType: "already",
      isModalTrue: false,
      modalText: null,
      modalType: null,
      yahooCode: false,
      followingArray: [],
      currentPageIndex: 1,
      checkNextPagination: null,
      loadingMoreConnections: false,
      loadingMoreReceived: false,
      loadingMoreSent: false,
      loadingMoreInvited: false,
      loadingMoreFollowers: false
    };

    this.enableScrollTillEnd = true;
  }

  componentWillReceiveProps(nextProps) {
    let { localSearch, yahooCode , activeTab} = this.state;

    if(nextProps.imported_contacts && activeTab === "5"){
      const importedContacts = nextProps.imported_contacts || [];
      this.setState({ findConnections: importedContacts });
    }

    if (nextProps.my_profile_info) {
      this.setState({ myProfileInfo: nextProps.my_profile_info });
    }

    if (nextProps.connections && this.state.activeTab == 1) {
      this.setState({
        connections: nextProps.connections,
        checkNextPagination: nextProps.connections?.next,
        isLoadingConnectionData: false,
      });
      this.enableScrollTillEnd = true;
    } 
    if (nextProps?.find_connections?.length) {
      let searchElement = nextProps?.find_connections?.search;
      if (searchElement === localSearch) {
        this.setState({ findConnections: nextProps.find_connections });
      }
    } else if(!(nextProps.imported_contacts?.length)){
      this.setState({ findConnections: [] });
    }
	
    if (nextProps.following_count) {
      this.setState({ followingCount: nextProps.following_count });
    }
	
    if (nextProps.import_count) {
      this.setState({ importCount: nextProps.import_count });
    }

    if (nextProps.pending_request_count) {
      this.setState({ pendingRequestCount: nextProps.pending_request_count });
    }

    if (nextProps.sent_friend_request_count) {
      this.setState({
        sentFriendRequestCount: nextProps.sent_friend_request_count,
      });
    }

    if (nextProps?.pending_request?.results?.length && this.state.activeTab == 2) {
      // pendingRequestData
      this.setState({
        pendingRequests: nextProps?.pending_request,
        checkNextPagination: nextProps.pending_request?.next,
        isLoadingPendingRequest: false,
      });
    } else if (nextProps?.pending_request?.results?.length === 0) {
      this.setState({ pendingRequests: {}, isLoadingPendingRequest: false });
    }

    if (nextProps?.sent_friend_request && this.state.activeTab == 3) {
      this.setState({
        sentFriendRequest: nextProps.sent_friend_request,
        checkNextPagination: nextProps.sent_friend_request?.next,
        isLoadingSentRequest: false,
      });
    } 

    if (nextProps?.following && this.state.activeTab == 6) {
      this.setState({
        followingArray: nextProps.following?.results,
        checkNextPagination: nextProps.following?.next,
        isLoadingFollowers: false,
      });
    } 


    if (nextProps?.fetch_invited_items && this.state.activeTab == 5) {
      this.setState({
        fetchInvited: nextProps.fetch_invited_items,
        checkNextPagination: nextProps.fetch_invited_items?.next,
        isLoadingFilterFetch: false,
      });
      
    } 

    if (nextProps?.enable_tab_forcefully?.status) {
      let code = nextProps?.enable_tab_forcefully?.param?.code;
      if (yahooCode === false) {
        this.setState({ activeTab: "4", yahooCode: code });
      }
    }
  }

  fetchContactsYahoo = async () => {
    let options = {
      url: "https://social.yahooapis.com/v1/user/me/contacts?format=json",
      method: "GET",
      headers: {
        Authorization:
          "Bearer r0KdOFWc71jZt9kidjxxGThX0CuP9iLcylT.U0Ib75k9QosGhd8yaqWmRk7H1oEpjEpmVSlOOA277z8FhQb5BweogvbBUa5zZz4es8SwgpxOiRrUV6gb_qD66i56PX0TgPQH3mFvaHyjchia2jMPSUFKVT3.vYWbNc3dlu12_1SqkKb10RbN.tmeS8zLL8J_DHm7iGjn5O0nD4EPxc3zHpB2uW0bHNSxwm0TaFyrg153Nm97vtznrd9p4Uyw3LOwDFYAFdVFm1z5ygzWVSJgH_PxbfIOH1gA9Wy_FfUw8JcAbj58mS1thyV_RSIfQ_PJJRIwLdXDTitZqO4SvxPGqxdBhB0n7Hniy5a4Vo0B1IJzcMCbwd5i.cin5FB0xGdAioLjdkpXzVwsS7Am1GTJjM30Di147a.veQql4_uIqyvEH8y3yQiv6mdRHcCDoHyzj2vMb58I8CeEXvtIm5KnkMhPU4eKqzJkYeucfNKqLAVvfFLzHombGwZPJgnlPPU1IHiNPI55w7.eumuKFsp3OirJgHNUEK7gTO5NkLqoxA2ghOQMOAQHn_lHN4mgcvNIpXLVZ0DwZKQa5UAeVBP0YQdADebzyypE1dFxFFbg61xPyZGDfRlksMmou3gTaqVvZUEZPfUo0zI6qkVhUn4WqFDk5wL7t8LxCAUGVxRjK2BWSf0XlsJGwM6KJIFwqDBxLLawAR067AGMT11QAF.pE0lFpLfkmIFwqMSjjLwltBrrJ103u9fLWm0WHDeYBbbOP6UmH0lxbh7vfTsYJ3aMhaRoWrlUD5W2VJIgV7_x2cvYqXJbU9fEkTuvhOr4JBNbqBgK0HL8SjXHIw.9siVjP3YwMDOGPWBa7J48U9Q9udqS7ptRp.LtkTZVPIgGW65WzT8Za6rEWU.fLQuIjot2LlOcwyDZks.BxCYhggLzRBe__HNPn.4QBldiCZiF6XeFlIwuiVPxZaONmk6hsC4r.IzVmbWIUUeOq08E.g--",
      },
    };
    let yahooContacts = await axios.get(options);
 
  };

  // postYahooRequestForAuthToken = async () => {
  //   let {
  //     yahoo_cred: { client_id, secret_key, scope, redirect_uri },
  //   } = this.state;
  //   let url = "https://wikireviews.com/api/import/";
  //   let data = {
  //     client_id: "dj0yJmk9UDh0aFl1d3ZscnZiJnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PWE1",
  //     client_secret: "447a271a79bfb57d9fff387bb3c1b2379afd8ed1",
  //     redirect_uri: "https://localhost:3000/myprofile",
  //     code: "j7wqbmw",
  //     grant_type: "authorization_code",
  //     url: "https://api.login.yahoo.com/oauth2/get_token",
  //   };
  //   let responseYahoo = await callApi(url, "POST", data);
  //   // removed console from here.
  // };

  // postOutlookRequestForAuthToken = async () => {
  //   let url = "https://wikireviews.com/api/importOutlookContacts/";
  //   let data = {
  //     client_id: "5a35d516-6b05-4976-be85-4078e46e7e25",
  //     client_secret: "~2A3UV5f_4bD.ikxRcNi~C8ZT33ql-yaY5",
  //     redirect_uri: "http://localhost:3000/myprofile",
  //     code: "M.R3_BAY.673e283c-67bf-cd16-578f-ed103b4e68d1",
  //     grant_type: "authorization_code",
  //     url: "https://login.microsoftonline.com/common/oauth2/v2.0/token",
  //   };
  //   let responseOutlook = await callApi(url, "POST", data);
  //   // removed console from here.
  // };

  testClick = async () => {
    let {
      yahooCode,
      yahoo_cred: { client_id, secret_key, redirect_uri },
    } = this.state;

    try {
      let response = await fetch(
        "https://api.login.yahoo.com/oauth2/get_token",
        {
          mode: "no-cors",
          method: "POST",
          // headers: {'Content-Type': 'application/x-www-form-url-encoded'},
          headers: {
            "Content-Type": "application/x-www-form-url-encoded",
            Accept: "application/json",
          },
          body: `client_id=${client_id}&client_secret=${secret_key}&redirect_uri=${redirect_uri}&code=${yahooCode}&grant_type=authorization_code`, // 'name=alex&surname=moran'
        }
      );

      if (response) {
        this.props.enable_fiend_friends_tab({ status: false, param: null });
        this.setState({ yahooCode: false });
        if (this?.props?.history?.replace) {
          this.props.history.replace("/myprofile");
        }
      }
    } catch (e) {
      console.log({ e });
    }
  };

  testYahooFetch = () => {
    let myHeaders = new Headers();
    myHeaders.append(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );

    let urlencoded = new URLSearchParams();
    urlencoded.append(
      "client_id",
      "dj0yJmk9UDh0aFl1d3ZscnZiJnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PWE1"
    );
    urlencoded.append("redirect_uri", "https://localhost:3000/myprofile");
    urlencoded.append("scope", "sdct-r");
    urlencoded.append(
      "client_secret",
      "9d20a243e1e23a8a997f3fdd42cdf9bb01798db7"
    );
    urlencoded.append("code", "8muvpjq");
    urlencoded.append("grant_type", "authorization_code");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      // mode: 'no-cors',
      // redirect: 'follow'
    };
    fetch("https://api.login.yahoo.com/oauth2/get_token", requestOptions)
      .then((response) => {
        // removed console from here.
        return response.text();
      })
      .then((result) => {
        // removed console from here.
      })
      .catch((error) => {
        // removed console from here.
      });
  };

  testYahooGetToken = () => {
    // let { yahooCode } = this.state;
    let data = qs.stringify({
      client_id: "dj0yJmk9UDh0aFl1d3ZscnZiJnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PWE1",
      redirect_uri: "https://localhost:3000/myprofile",
      scope: "sdct-r",
      client_secret: "447a271a79bfb57d9fff387bb3c1b2379afd8ed1",
      code: "amffmt6",
      grant_type: "authorization_code",
    });
    let config = {
      method: "post",
      url: "https://api.login.yahoo.com/oauth2/get_token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // removed console from here.
      })
      .catch((error) => {
        // removed console from here.
      });
  };

  handleAcceptRequest = async ({ id }) => {
    let text = 'You have accepted a friend request';
    try {
      let response = await callApiAdvance({
        endpoint: `/api/connectionrequests/${id}/`,
        method: "PUT",
        body: { id: id, action: "accept" },
      });
      if (response.results) {
        this.props.fetch_pending_req_count({ page: 1 });
        this.props.fetch_pending_req_count({ count: true });
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  handleGetYahooToken = async () => {
    let {
      yahooCode,
      yahoo_cred: { client_id, secret_key, redirect_uri },
    } = this.state;

    if (yahooCode) {
      let postdata = {
        client_id: client_id,
        client_secret: secret_key,
        redirect_uri: redirect_uri,
        code: yahooCode,
        grant_type: "authorization_code",
      };

      // const searchParams = Object.keys(postdata).map((key) => {
      //     return encodeURIComponent(key) + '=' + encodeURIComponent(postdata[key]);
      // }).join('&');

      let response = await axios({
        method: "POST",
        url: "https://api.login.yahoo.com/oauth2/get_token",
        params: qs.stringify(postdata),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      });

      if (response !== null) {
        this.props.enable_fiend_friends_tab({ status: false, param: null });
        this.setState({ yahooCode: false });
        if (this?.props?.history?.replace) {
          this.props.history.replace("/myprofile");
        }
      }
    }
  };

  redirectYahooForLogin = () => {
    let {
      yahoo_cred: { client_id, secret_key, scope, redirect_uri },
    } = this.state;
    let newUrl = `https://api.login.yahoo.com/oauth2/request_auth?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}&scope=${scope}&nonce=${secret_key};`;
    window.open(newUrl, "_blank");
  };

  redirectOutlookForLogin = () => {
    let {
      outlook_cred: { client_id, redirect_url, scopes },
    } = this.state;
    let newUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&scope=${scopes}&redirect_uri=${redirect_url}&response_type=code`;
    window.open(newUrl, "_blank");
  };

  handleToggle = (tab) => {
    let { activeTab } = this.state;
    if (activeTab != 5) {
      this.props.clear_search_data();
    }

    if (activeTab !== tab) {
      this.setState({ activeTab: tab, currentPageIndex: 1, checkNextPagination: null
    }, () => {
      
        this.props.history.replace("/myprofile");
        if (tab === "1") {
          this.props.fetch_connections()
        }

        if (tab === "2") {
          this.props.fetch_pending_req_count({ page: 1 });
        }

        if (tab === "3") {
          this.props.fetch_sent_friend_req_count({ page: 1 });
        }

        if (tab === "5") {
          this.props.fetch_invited({ page: 1 });
        }

        if (tab === "4") {
          this.props.already_on_wikireviews({ page: 1 });
          this.props.get_providers_on_wikireviews();
          this.props.clear_fetch_invited();
        }

        if (tab === "6") {
          this.props.fetch_following_count({ page: 1 });
          // this.props.fetch_invited({ page: 1 });
        }
      });
    }
  };

  renderNavLinks = ({ current, title, count }) => {
    let { activeTab } = this.state;
    return (
      <NavItem>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === current })}
          onClick={() => {
            this.handleToggle(current);
          }}
        >
          {`${title}`}
        </NavLink>
        {title !== "Find Friends" ? <span className="count">{count}</span> : ""}
      </NavItem>
    );
  };

  componentDidMount() {
    let self = this;
      let { activeTab } = this.state;

      if (activeTab === "2") {
        self.props.fetch_pending_req_count({ page: 1 });
      } else if (activeTab === "3") {
        self.props.fetch_sent_friend_req_count({ page: 1 });
      } else if (activeTab === "5") {
        self.props.fetch_invited({ page: 1 });
      } else if (activeTab === "6") {
        self.props.fetch_following_count({ page: 1 });
        self.props.fetch_invited({ page: 1 });
      } 

    this.fetchAllData();

		window.addEventListener('scroll', this.handleScroll, { passive: false });
    
  }

  handleScroll = () => {
    let scrollTillHeight = window.pageYOffset + 500;
    let lastElemHeight = document.getElementById(`last-index-${this.state.activeTab}`)?.offsetTop;
    let currentPageIndex = this.state.currentPageIndex;
    let checkNextPagination = this.state.checkNextPagination;

    if (checkNextPagination && currentPageIndex + 1 != checkNextPagination?.charAt(checkNextPagination?.length - 1)) {
      currentPageIndex = Number(checkNextPagination?.charAt(checkNextPagination?.length - 1)) - 1
      this.setState({currentPageIndex})
      return
    }

    if (scrollTillHeight > lastElemHeight && checkNextPagination) {
        if (this.enableScrollTillEnd) {
            this.enableScrollTillEnd = false;
            currentPageIndex++;
            this.setState({ currentPageIndex }, () => {
              if (this.state.activeTab == 1) {
                const url = `${API_URL}/api/connections/?page=${currentPageIndex}`
                const authToken = localStorage.getItem('token');
                let headers = {};
                headers["Authorization"] = `Token ${authToken}`;
                this.setState({
                  loadingMoreConnections: true
                })
                axios({
                  method: "GET",
                  url: url,
                  headers
                }).then((response) => {
                  checkNextPagination = response?.next
                  if (checkNextPagination) {
                    if (this.state.activeTab != 1) {
                      checkNextPagination = this.state.checkNextPagination
                    }
                  }
                  this.enableScrollTillEnd = true;
                  
                  this.setState((prev) => {
                    return {
                      checkNextPagination,
                      connections: { ...response, results: pushUniqueObjectsInArray(prev.connections.results, response.results, 'id')},
                      loadingMoreConnections: false
                    }
                  })
                }).catch(() => {
                  this.setState({
                    loadingMoreConnections: false
                  })
                })
              }
              else if (this.state.activeTab == 2) {
                const url = `${API_URL}/api/pendingrequests/?page=${currentPageIndex}`
                const authToken = localStorage.getItem('token');
                let headers = {};
                headers["Authorization"] = `Token ${authToken}`;
                this.setState({
                  loadingMoreReceived: true
                })
                axios({
                  method: "GET",
                  url: url,
                  headers
                }).then((response) => {
                  checkNextPagination = response?.next
                  if (checkNextPagination) {
                    if (this.state.activeTab != 2) {
                      checkNextPagination = this.state.checkNextPagination
                    }
                  }
                  this.enableScrollTillEnd = true;
                  
                  this.setState((prev) => {
                    return {
                      checkNextPagination,
                      pending_request: { ...response, results: pushUniqueObjectsInArray(prev.pending_request.results, response.results, 'id')},
                      loadingMoreReceived: false
                    }
                  })
                }).catch(() => {
                  this.setState({
                    loadingMoreReceived: false
                  })
                })
              } 
              else if (this.state.activeTab == 3) {
                const url = `${API_URL}/api/sentfriendrequest/?page=${currentPageIndex}`
                const authToken = localStorage.getItem('token');
                let headers = {};
                headers["Authorization"] = `Token ${authToken}`;
                this.setState({
                  loadingMoreSent: true
                })
                axios({
                  method: "GET",
                  url: url,
                  headers
                }).then((response) => {
                  checkNextPagination = response?.next
                  if (checkNextPagination) {
                    if (this.state.activeTab != 3) {
                      checkNextPagination = this.state.checkNextPagination
                    }
                  }
                  this.enableScrollTillEnd = true;
                  
                  this.setState((prev) => {
                    return {
                      checkNextPagination,
                      sentFriendRequest: { ...response, results: pushUniqueObjectsInArray(prev.sentFriendRequest.results, response.results, 'id')},
                      loadingMoreSent: false
                    }
                  })
                }).catch(() => {
                  this.setState({
                    loadingMoreSent: false
                  })
                })
              } else if (this.state.activeTab == 5) {
                const url = `${API_URL}/api/getinvited/?page=${currentPageIndex}`
                const authToken = localStorage.getItem('token');
                let headers = {};
                headers["Authorization"] = `Token ${authToken}`;
                this.setState({
                  loadingMoreInvited: true
                })
                axios({
                  method: "GET",
                  url: url,
                  headers
                }).then((response) => {
                  checkNextPagination = response?.next
                  if (checkNextPagination) {
                    if (this.state.activeTab != 5) {
                      checkNextPagination = this.state.checkNextPagination
                    }
                  }
                  this.enableScrollTillEnd = true;
                  
                  this.setState((prev) => {
                    return {
                      checkNextPagination,
                      fetchInvited: { ...response, results: pushUniqueObjectsInArray(prev.fetchInvited.results, response.results, 'email')},
                      loadingMoreInvited: false
                    }
                  })
                }).catch(() => {
                  this.setState({
                    loadingMoreInvited: false
                  })
                })
              } else if (this.state.activeTab == 6) {
                const url = `${API_URL}/api/following/?page=${currentPageIndex}`
                const authToken = localStorage.getItem('token');
                let headers = {};
                headers["Authorization"] = `Token ${authToken}`;
                this.setState({
                  loadingMoreFollowers: true
                })
                axios({
                  method: "GET",
                  url: url,
                  headers
                }).then((response) => {
                  checkNextPagination = response?.next
                  if (checkNextPagination) {
                    if (this.state.activeTab != 6) {
                      checkNextPagination = this.state.checkNextPagination
                    }
                  }
                  this.enableScrollTillEnd = true;

                  this.setState((prev) => {
                    return {
                      checkNextPagination,
                      followingArray: { ...response, results: pushUniqueObjectsInArray(prev.followingArray.results, response.results, 'id') },
                      loadingMoreFollowers: false
                    }
                  })
                }).catch(() => {
                  this.setState({
                    loadingMoreFollowers: false
                  })
                })
              }
            });
        }
    }
}

  componentWillUnmount() {
    this.props.enable_fiend_friends_tab({ status: false, param: null });
    window.removeEventListener('scroll', this.handleScroll);
  }

  fetchAllData = () => {
    const { id } = this.props
    Promise.all([
      this.props.fetch_pending_req_count({ count: true }),
      this.props.fetch_sent_friend_req_count({ count: true }),
      this.props.fetch_following_count({ count: true }),
      this.props.fetch_import_count(),
      this.props.fetch_connections(id),
      this.props.fetch_invited({ page: 1 })
    ]).then(() => {
      this.setState({
        isLoadingConnectionData: true,
        isLoadingFilterFetch: true,
        isLoadingPendingRequest: true,
        isLoadingSentRequest: true,
        isLoadingFollowers: true
      });
    });
  };

  logChange = (val) => {
    // removed console from here.
  };

  debounceLiveSearch = debounce((value) => {
    this.props.fetch_find_connection({
      search: value,
      sensor: false,
      type: "search",
    });
  }, 500);

  debounceLiveSearchReset = debounce(() => {
    this.props.clear_search_data();
    this.props.fetch_connections();
  }, 500);

  handleLiveSearch = (event) => {
    let { value } = event.target;
    if (value.length) {
      this.setState({ localSearch: value }, () => {
        this.debounceLiveSearch(value);
      });
    } else {
      this.setState({ findConnections: null, localSearch: "" }, () => {
        this.debounceLiveSearchReset();
      });
    }
  };


  debounceImportedConnectionsSearch = debounce((data) => {
    this.props.search_find_friend_import_connections(data);
  }, 1000);

  handleImportedConnectionsSearch = (event) => {
    let { value } = event.target;
    this.setState({ importConnectionSearch: value }, () => {
      this.debounceImportedConnectionsSearch({
        search: value,
        sensor: false,
        type: "search"
      });

    });
  }

  handleSelect = (e, item) => {
    e.preventDefault();

    if(item.user?.id){
      this.props.fetch_find_connection({
        searchID: item.user.id,
        sensor: false,
        type: "getconnection",
      });
    this.props.clear_search_data();

    } else{
      const fetchInvited = this.state.fetchInvited 
      fetchInvited.results = fetchInvited.results.filter(r=> r.email=== item.email) 
      this.setState({
        fetchInvited,
        checkNextPagination: null,
        currentPageIndex: 1
      })
    }
    
    this.setState({ localSearch: "", importConnectionSearch: "" , findConnections: []});
  };

  handleClickOkay = () => {
    let { modalType, modalSelected } = this.state;
    let item = modalSelected;

    if (modalType === "unfriend") {
      this.props.unfriend_user({ id: item.id, friend: item.connection.id });
    }

    if (modalType === "block") {
      this.props.block_user({ id: item.id });
    }

    if (modalType === "send") {
      this.props.send_again_request({
        email: item?.connection?.user?.email,
        vanity_url: item?.vanity_url,
      });
    }

    if (modalType === "remove") {
      this.props.remove_request({ id: item.id });
    }

    this.toggleModal({ text: null, type: null, item: null });
  };

  toggleModal = ({ text, type, item }) => {
    this.setState({
      isModalTrue: !this.state.isModalTrue,
      modalText: text ? text : null,
      modalType: type ? type : null,
      modalSelected: item,
    });
  };

  fetchAlreadyOnWikireviews = () => {
    this.props.already_on_wikireviews({ page: 1 });
  };

  responseCallback = () => {
    // removed console from here.
  };

  loginToGoogle = (e) => {
    e.preventDefault();
    let {
      google_cred: { apiKey },
    } = this.state;
    gapi.client.setApiKey(apiKey);
    window.setTimeout(this.authorize);
  };

  authorize = () => {
    let {
      google_cred: { clientId, scopes },
    } = this.state;
    gapi.auth.authorize(
      { client_id: clientId, scope: scopes, immediate: false },
      this.handleAuthorization
    );
  };

  handleAuthorization = (authorizationResult) => {
    if (authorizationResult && !authorizationResult.error) {
      axios
        .get(
          `https://www.google.com/m8/feeds/contacts/default/thin?alt=json&access_token=${authorizationResult.access_token}&max-results=500&v=3.0`
        )
        .then(function (response) {
          // handle success
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
          console.log("end");
        });
    }
  };

  setTabView = (TabNo) => {
   
    if (TabNo) {
      this.setState({ activeTab: TabNo }, () => {
        this.props.fetch_import_count();
        this.props.fetch_invited({ page: 1 });

      });
    }

  };
  
  handleInvitation = () => {
    let { fetchInvited } = this.state;
    let data = {};
	let selectedIds = []
	if (fetchInvited?.results?.length) {
      let filterfetchInvited = fetchInvited.results;
       filterfetchInvited.forEach((item) => {
		if(!item.on_wiki){
		selectedIds.push(item.email)
	   }
	   });
    }
	if(selectedIds.length > 0){
     data = selectedIds.join();
	 this.props.send_invitation_requests(data)
	}
  }

  handleSendInvite = (item) => {
    this.props.send_single_invitation_request(item.email)
  }

  resetPagination() {
    if (this.state.checkNextPagination || this.state.currentPageIndex !== 1) {
      this.setState({
        checkNextPagination: null,
        currentPageIndex: 1
      })
    }
  }

  render() {
    const { notPeopleTab = true } = this.props
    let {
      activeTab,
      connections,
      myProfileInfo,
      findConnections,
      followingCount,
      importCount,
      pendingRequestCount,
      sentFriendRequestCount,
      pendingRequests,
      sentFriendRequest,
      fetchInvited,
      localSearch,
      importConnectionSearch,
      isLoadingConnectionData,
      isLoadingFilterFetch,
      isLoadingPendingRequest,
      isLoadingSentRequest,
      isLoadingFollowers
    } = this.state;

    let results = [];
    if (connections?.results?.length) {
      results = connections.results;
    }

    let filterPendingRequest = [];
    if (pendingRequests?.results?.length) {
      filterPendingRequest = pendingRequests.results;
    }

    let filterSendFriendRequest = [];
    if (sentFriendRequest?.results?.length) {
      filterSendFriendRequest = sentFriendRequest.results;
    }

    let filterfetchInvited = [];
	let onwikicount = 0;
    if (fetchInvited?.results?.length) {
      filterfetchInvited = fetchInvited.results;
       filterfetchInvited.forEach((item) => {
		if(item.on_wiki){
		onwikicount = onwikicount+1	
	   }
	   });
    }
	
    if (this.props.search_select_connection !== null) {
      findConnections = [];
      results = this.props.search_select_connection;
      this.resetPagination()
    }

    return (
      <div>
        <Nav className="mb-3 border-0 m-0 with-counts" tabs>
          {this.renderNavLinks({
            current: "1",
            title: "My Friends",
            count: connections?.total_count ? connections?.total_count : 0,
          })}

          {notPeopleTab && this.renderNavLinks({
            current: "2",
            title: "Requests Received",
            count: pendingRequestCount?.request_count
              ? pendingRequestCount?.request_count
              : 0,
          })}
          {notPeopleTab && this.renderNavLinks({
            current: "3",
            title: "Requests Sent",
            count: sentFriendRequestCount?.request_count
              ? sentFriendRequestCount?.request_count
              : 0,
          })}
          {notPeopleTab && this.renderNavLinks({
            current: "4",
            title: "Find Friends",
            count: "",
          })}
          {notPeopleTab && this.renderNavLinks({
            current: "5",
            title: "My Imports",
            count: importCount?.count ? importCount?.count : '',
          })}
          {notPeopleTab && this.renderNavLinks({
            current: "6",
            title: "Followers",
            count: followingCount?.count ? followingCount?.count : 0,
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {" "}
            {/* {My Friends} */}
            <Row>
              <Col sm="4">
                {notPeopleTab &&
                  <InputGroup size="sm">
                    <Input
                      className="border font-weight-bold wk-my-friends-search"
                      onChange={this.handleLiveSearch}
                      placeholder="Enter Connection Name"
                      type="text"
                      value={localSearch}
                    />
                    {`  `}

                    <Button
                      size="sm"
                      onClick={() => {
                        this.setState({ findConnections: [], importConnectionSearch: "", localSearch: "" }, () => {
                          this.props.clear_search_data();
                          this.fetchAllData();
                        });
                      }}
                      color="royal-blue"
                    >
                      {"View All"}
                    </Button>
                  </InputGroup>
                }
              </Col>
            </Row>
            <Row className="mt-1 position-absolute" style={{ zIndex: 999 }}>
              <Col sm="4">
                <ul class="list-group">
                  {findConnections && findConnections.length
                    ? findConnections.map((item) => (
                      <li
                        onClick={(e) => this.handleSelect(e, item)}
                        className="custom-hover-search list-group-item d-flex justify-content-between align-items-center"
                        style={{ width: "280px", height: "60px" }}
                      >
                        {`${item?.user?.first_name}  ${item?.user?.last_name}`}
                        {
                          item.current_profile_file ? <div className="image-parent">
                          <img
                            src={item.current_profile_file}
                            alt="Profile"
                            className="sm-img-search"
                          />
                        </div>: <></>
                        }
                        
                      </li>
                    ))
                    : null}
                </ul>
              </Col>
            </Row>
            <Row xs={1} md={3} lg={5} form>
              {results && results.length > 0 ? (
                results.map((item, index) => (
                  <Col id={`${results.length == index + 1 ? `last-index-1` : `current-index-${index}`}`} className="mt-3">
                    <Card className="flip-card-inner">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <CardImg
                              className="round-display img-fluid"
                              top
                              width="100%"
                              src={
                                item.connection?.id === myProfileInfo?.id
                                  ? item?.profile?.current_profile_file
                                  : item?.connection?.current_profile_file
                              }
                              alt="Card image cap"
                            />
                          </div>
                          <div className="flip-card-back d-flex flex-column justify-content-center">
                            <div>
                              <Button
                                size="sm"
                                onClick={() => {
                                  this.toggleModal({
                                    text: `Do you want unfriend this user ?`,
                                    type: "unfriend",
                                    item,
                                  });
                                }}
                                color="tertiary"
                              >
                                {" "}
                                Unfriend{" "}
                              </Button>
                            </div>
                            <div className="mt-2">
                              <Button
                                size="sm"
                                onClick={() => {
                                  this.toggleModal({
                                    text: `Do you want block this user ?`,
                                    type: "block",
                                    item,
                                  });
                                }}
                                color="tertiary"
                              >
                                {" "}
                                Block{" "}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CardBody>
                        <CardTitle className="center text-dark fs-14 font-weight-bold">
                          {item?.connection?.id === myProfileInfo?.id
                            ? <Link className="text-dark" to={`/people/${item?.profile?.user?.username}`}>{`${item?.profile?.user?.first_name} ${item?.profile?.user?.last_name}`}</Link>
                            : <Link className="text-dark" to={`/people/${item?.connection?.user?.username}`}>{`${item?.connection?.user?.first_name} ${item?.connection?.user?.last_name}`}</Link>}
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : isLoadingConnectionData ? 
              <div className="mt-4 p-3 bg-white text-secondary">
                <h2>Loading...</h2> 
              </div>
              : (
                <div className="col-md-12 text-center bg-white p-3 mt-4">
                  <h4 className="text-secondary-dark">No friends to display </h4>
                </div>
              )}
              {
                this.state.loadingMoreConnections ? 
                <div className="mt-4 p-3 bg-white text-secondary">
                <h2>Loading More...</h2> 
              </div> :
              ''
              }
            </Row>
            <div style={{ height: "200px" }}></div>
          </TabPane>

          <TabPane tabId="2">
            {" "}
            {/* {Requests Received} */}
            <Row xs={1} md={3} lg={5} form>
              {filterPendingRequest && filterPendingRequest.length > 0 ? (
                filterPendingRequest.map((item, index) => (
                  <Col className="mt-3" id={`${filterPendingRequest.length == index + 1 ? `last-index-2` : `current-index-${index}`}`} >
                    <Card>
                      <CardImg
                        className="round-display"
                        top
                        width="100%"
                        src={
                          item?.connection?.id === myProfileInfo.id
                            ? item?.profile?.current_profile_file
                            : item?.connection?.current_profile_file
                        }
                        alt="Card image cap"
                      />
                      <CardBody className="text-center">
                        <CardTitle className="center text-dark fs-14 font-weight-bold">
                          {item.connection.id === myProfileInfo.id
                            ? <Link className="text-dark" to={`/people/${item?.profile?.user?.username}`}>{`${item?.profile?.user?.first_name} ${item?.profile?.user?.last_name}`}</Link>
                            : <Link className="text-dark" to={`/people/${item?.connection?.user?.username}`}>{`${item?.connection?.user?.first_name} ${item?.connection?.user?.last_name}`}</Link>
                          }
                        </CardTitle>
                        <div className="text-center">
                          <Button
                            size="sm"
                            color="tertiary"
                            onClick={() => {
                              let id = item.profile.id;
                              this.handleAcceptRequest({ id });
                            }}
                            className="bg-tertiary border-tertiary"
                          >
                            {"Accept"}
                          </Button>
                          <div className="my-2"></div>
                          <Button
                            size="sm"
                            color="tertiary"
                            className="bg-tertiary border-tertiary ml-0"
                            onClick={() => {
                              this.toggleModal({
                                text: `Do you want remove this request ?`,
                                type: "remove",
                                item,
                              });
                            }}
                          >
                            {"Remove Request"}
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : isLoadingPendingRequest ? 
              <div className="mt-4 p-3 bg-white text-secondary">
              <h2>Loading...</h2> 
            </div> : (
                <div className="col-md-12 text-center bg-white p-3 mt-4">
                  <h4 className="text-secondary-dark">No Pending requests to display</h4>
                </div>
              )}
              {
                this.state.loadingMoreReceived ? 
                <div className="mt-4 p-3 bg-white text-secondary">
                <h2>Loading More...</h2> 
              </div> :
              ''
              }
            </Row>
            <div style={{ height: "200px" }}></div>
          </TabPane>
          <TabPane tabId="3">
            <Row xs={1} md={3} lg={5} form>
              {filterSendFriendRequest && filterSendFriendRequest.length > 0 ? (
                filterSendFriendRequest.map((item, index) => (
                  <Col className="mt-3" id={`${filterSendFriendRequest.length == index + 1 ? `last-index-3` : `current-index-${index}`}`}>
                    <Card>
                      <CardImg
                        className="round-display"
                        top
                        width="100%"
                        src={
                          item?.connection?.id === myProfileInfo?.id
                            ? item?.profile?.current_profile_file
                            : item?.connection?.current_profile_file
                        }
                        alt="Card image cap"
                      />
                      <CardBody className="text-center">
                        <CardTitle className="center text-dark fs-14 font-weight-bold">
                          {
                            item?.connection?.id === myProfileInfo?.id
                              ? <Link className="text-dark" to={`/people/${item?.profile?.user?.username}`}>{`${item?.profile?.user?.first_name} ${item?.profile?.user?.last_name}`}</Link>
                              : <Link className="text-dark" to={`/people/${item?.connection?.user?.username}`}>{`${item?.connection?.user?.first_name} ${item?.connection?.user?.last_name}`}</Link>
                          }
                        </CardTitle>
                        <div className="text-center">
                          <Button
                            size="sm"
                            color="tertiary"
                            onClick={() => {
                              this.toggleModal({
                                text: `Do you want send request to this user ?`,
                                type: "send",
                                item,
                              });
                            }}
                            className="bg-tertiary border-tertiary"
                          >
                            {"Send Again"}
                          </Button>
                          <div className="my-2"></div>
                          <Button
                            size="sm"
                            color="tertiary"
                            className="bg-tertiary border-tertiary ml-0"
                            onClick={() => {
                              this.toggleModal({
                                text: `Do you want remove this user ?`,
                                type: "remove",
                                item,
                              });
                            }}
                          >
                            {"Remove Request"}
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : isLoadingSentRequest ? 
              <div className="mt-4 p-3 bg-white text-secondary">
              <h2>Loading...</h2> 
            </div>  : (
                <div className="col-md-12 text-center bg-white p-3 mt-4">
                  <h2 className="text-secondary-dark">No Record Found</h2>
                </div>
              )}
              {
                this.state.loadingMoreSent ? 
                <div className="mt-4 p-3 bg-white text-secondary">
                <h2>Loading More...</h2> 
              </div> :
              ''
              }
            </Row>
            <div style={{ height: "200px" }}></div>
          </TabPane>

          <TabPane tabId="4">
            <FindFriends setTabView={this.setTabView} />
          </TabPane>

          <TabPane tabId={"5"}>
            <div className="bg-white p-2 mb-3 text-center">
              <span className="text-secondary-dark fs-14 w-100">
                You've {filterfetchInvited?.length} connections on Social Accounts
                <br /> <strong> {onwikicount} of them already on WikiReviews </strong>
              </span>
            </div>
            {/* filterfetchInvited */}
            <div className="row">
              <div className="col-sm-4">
                {notPeopleTab &&
                  <InputGroup size="sm">
                    <Input
                      className="border"
                      onChange={this.handleImportedConnectionsSearch}
                      placeholder="Enter Import Contact Name"
                      type="text"
                      value={importConnectionSearch}
                    />
                    {`  `}

                    <Button
                      size="sm"
                      onClick={() => {
                        this.setState({ findConnections: [],fetchInvited: null, importConnectionSearch: "", localSearch: "" }, () => {
                          this.props.clear_search_data();
                          this.fetchAllData();
                        });
                      }}
                      color="royal-blue"
                    >
                      {"View All"}
                    </Button>
                  </InputGroup>
                }
              </div>
              <div className="col-sm-8 text-right">
                  <div>
                    <Button
                      color="primary"
                      className={filterfetchInvited.count > 0 ? false : true}
                      onClick={() => {
                        this.handleInvitation();
                      }}
                    >Invite All </Button>
                  </div>
              </div>
            </div>
            <Row className="mt-1 position-absolute" style={{ zIndex: 999 }}>
              <Col sm="4">
                <ul class="list-group">
                  {findConnections && findConnections.length
                    ? findConnections.map((item) => (
                      <li
                        onClick={(e) => this.handleSelect(e, item)}
                        className="custom-hover-search list-group-item d-flex justify-content-between align-items-center"
                        style={{ width: "280px", height: "60px" }}
                      >
                        {`${item?.name}`}
                        {
                          item.thumbnail ? <div className="image-parent">
                          <img
                            src={item.thumbnail}
                            alt="Profile"
                            className="sm-img-search"
                          />
                        </div>: <></>
                        }
                      </li>
                    ))
                    : null}
                </ul>
              </Col>
            </Row>


            {/*<Row xs={1} md={3} lg={5} form>
              {filterfetchInvited && filterfetchInvited.length > 0 ? (
                filterfetchInvited.map((item, index) => (
                  <Col className="mt-3">
                    <Card>
                       <CardImg
                        className="round-display"
                        top
                        width="100%"
                        // src={
                        //   item.connection.id === myProfileInfo.id
                        //     ? item?.profile?.current_profile_file
                        //     : item?.connection?.current_profile_file
                        // }
                        src={(item.profile_url) ? item.profile_url : ""}
                        alt="Card image cap"
						onError={(error) =>
						(error.target.src = require("../../../assets/images/icons/user-circle.png"))
						}
                      /> 
                      <CardBody className="text-center">
                        <CardTitle className="center text-dark fs-14 font-weight-bold">
                          {/* {item.connection.id === myProfileInfo.id
                            ? `${item?.email}`
                            : `${item?.email}`}
                          {item.email}
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : ''}
            </Row>*/}

            <Row xs={1} md={3} lg={5} form>
              {filterfetchInvited && filterfetchInvited.length > 0 ? (
                filterfetchInvited.map((item, index) => (
                  <Col id={`${filterfetchInvited.length == index + 1 ? `last-index-5` : `current-index-${index}`}`} className="mt-3">
                    <Card className="flip-card-inner">
                      <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front">
                            <CardImg
                              className="round-display img-fluid"
                              top
                              width="100%"
                              src={(item.thumbnail) ? item.thumbnail : ""}
								alt="Card image cap"
								onError={(error) =>
								(error.target.src = require("../../../assets/images/icons/user-circle.png"))
								}
                              
                            />
                          </div>
                          <div className="flip-card-back pt-4">
                            <div hidden={item.on_wiki == false && item.is_invited == true ? false : true}>
                              <div
                                size="sm"
                                role="button"
                                className="bg-tertiary text-white d-inline p-2 font-weight-bold"
                              >
                                Invited
                              </div>
                            </div>
							<div hidden={item.on_wiki == false && item.is_invited == false ? false : true}>
                              <div
                                size="sm"
                                role="button"
                                className="bg-tertiary text-white d-inline p-2 font-weight-bold"
                                onClick={() => {
                                  this.handleSendInvite(item)
                                }}
                              >
                                Invite
                              </div>
                            </div>
                            <div hidden={(item.on_wiki == true && item.is_invited == false) ? false : true}>
                              <Button
                                size="sm"
                                color="secondary"
                                className="text-white"
                              >
                                WikiReviews Member
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CardBody>
                        <CardTitle className="center text-dark fs-14 font-weight-bold mb-2">
                          {item?.name}
                        </CardTitle>
                        <div className="center text-dark fs-14">
                          {item?.email}</div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : isLoadingFilterFetch ? 
              <div className="mt-4 p-3 bg-white text-secondary">
              <h2>Loading...</h2> 
            </div> : (
                <div className="col-md-12 text-center bg-white p-3 mt-4">
                  <h2 className="text-secondary-dark">No Record Found</h2>
                </div>
              )}
              {
                this.state.loadingMoreInvited ? 
                <div className="mt-4 p-3 bg-white text-secondary">
                <h2>Loading More...</h2> 
              </div> :
              ''
              }
            </Row>
            <div style={{ height: "200px" }}></div>
          </TabPane>

          <TabPane tabId={"6"}>
          <Row xs={1} md={3} lg={5} form>
              {this.state.followingArray && this.state.followingArray.length > 0 ? (
                  this.state.followingArray.map((item, index) => (
                  <Col className="mt-3"  id={`${this.state.followingArray.length == index + 1 ? `last-index-6` : `current-index-${index}`}`}>
                    <Card className="text-center">
                            <CardImg
                              className="round-display img-fluid mb-3"
                              top
                              width="100%"
                              src={(item.current_profile_pic) ? item.current_profile_pic : ""}
								alt="Card image cap"
								onError={(error) =>
								(error.target.src = require("../../../assets/images/icons/user-circle.png"))
								}
                              
                            />
                      <CardBody>
                          <CardTitle className="center text-dark fs-14 font-weight-bold mb-2">
                            {item?.vanity ?
                              <Link className="text-dark" to={`/people/${item?.vanity}`}>{item?.name}</Link>
                              :
                              item?.name
                            }
                          </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : isLoadingFollowers ? <div className="mt-4 p-3 bg-white text-secondary">
              <h2>Loading...</h2> 
            </div> : (
                <div className="col-md-12 text-center bg-white p-3 mt-4">
                  <h2 className="text-secondary-dark">No followers to display.</h2>
                </div>
              )}
              {
                this.state.loadingMoreFollowers ? 
                <div className="mt-4 p-3 bg-white text-secondary">
                <h2>Loading More...</h2> 
              </div> :
              ''
              }
            </Row>
            <div style={{ height: "200px" }}></div>
          </TabPane>
        </TabContent>

        <Modal
          size="sm"
          isOpen={this.state.isModalTrue}
          toggle={() =>
            this.toggleModal({ text: null, type: null, item: null })
          }
          className="text-center"
          modalClassName="backdrop-opaque top-shifted transition-none"
        >
          <div className="bg-white modal-header">
            <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
            <button
              className="btn btn-sm"
              aria-label="Close"
              onClick={() =>
                this.toggleModal({ text: null, type: null, item: null })
              }
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <ModalBody className="text-dark">{this.state.modalText}</ModalBody>
          <ModalFooter className="bg-white">
            <div className="text-center w-100">
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={() =>
                  this.toggleModal({ text: null, type: null, item: null })
                }
              >
                {"Cancel"}
              </Button>
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={this.handleClickOkay}
              >
                {"Yes"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => {
  let connections = state.connections;
  
  let user = state.user;
  return {
    pending_request_count: connections.pending_request_count,
    pending_request: connections.pending_request,
    sent_friend_request_count: connections.sent_friend_request_count,
    sent_friend_request: connections.sent_friend_request,
    following_count: connections.following_count,
    following: connections.following,
    import_count: connections.import_count,
    connections: connections.connections,
    is_loading_connections: connections.is_loading_connections,
    find_connections: connections.find_connections,
    my_profile_info: user.my_profile,
    fetch_invited_items: connections.fetch_invited_items,
    search_select_connection: connections.search_select_connection,
    enable_tab_forcefully: connections.enable_tab_forcefully,
    imported_contacts: connections.imported_contacts
  };
};

const mapProps = (dispatch) => ({
  fetch_pending_req_count: (data) => dispatch(fetch_pending_req_count(data)),
  fetch_sent_friend_req_count: (data) =>
    dispatch(fetch_sent_friend_req_count(data)),
  fetch_following_count: (data) => dispatch(fetch_following_count(data)),
  fetch_import_count: () => dispatch(fetch_import_count()),
  fetch_connections: (id, page) => dispatch(fetch_connections(undefined, undefined, id, page)),
  fetch_find_connection: (data) => dispatch(fetch_find_connection(data)),
  fetch_invited: (data) => dispatch(fetch_invited(data)),
  clear_search_data: () => dispatch(clear_search_data()),
  clear_fetch_invited: () => dispatch(clear_fetch_invited()),
  unfriend_user: ({ id, friend }) => dispatch(unfriend_user({ id, friend })),
  block_user: ({ id }) => dispatch(block_user({ id })),
  send_again_request: (data) => dispatch(send_again_request(data)),
  remove_request: ({ id }) => dispatch(remove_request({ id })),
  already_on_wikireviews: ({ page }) =>
    dispatch(already_on_wikireviews({ page })),
  enable_fiend_friends_tab: (data) => dispatch(enable_fiend_friends_tab(data)),
  get_providers_on_wikireviews: () => dispatch(get_providers_on_wikireviews()),
  send_invitation_requests: (emails) => dispatch(send_invitation_requests(emails)),
  send_single_invitation_request: (email) => dispatch(send_single_invitation_request(email)),
  search_find_friend_import_connections: (data) => dispatch(search_find_friend_import_connections(data)),

});

export default withRouter(connect(mapState, mapProps)(Connections));
