import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";

import {
  initFetchMovieSubCategories,
  search_moviesMembers,
  get_patrol_crp,
  submit_patrol_review
} from "../../../actions/footer";

import { get_type_detail } from '../../../actions/itemDetail';

class PatrolMovieCoreModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      loaded: true,
      drawer: true,
      patrolMovie_list: [],
      reasonError: "",
      category_id: '',
      subcategory_id: '',
      rateType: "",
      type: "corelisting",
      listingType: "movies",
      about: null,
      decision: "",
      reason: ""
    };
  }
  redirectto = (movieId, slug) => {
    let path = "movies";
    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: movieId }
    })
  }
  componentWillReceiveProps(nextProps) {
    let { loaded } = this.state;
    if (nextProps.fetch_save_movie_response) {
      if (nextProps.fetch_save_movie_response && nextProps.fetch_save_movie_response.id && nextProps.fetch_save_movie_response.slug) {
        let slug = null;
        let movieId = "";
        if (nextProps.fetch_save_movie_response.id != "") {
          movieId = nextProps.fetch_save_movie_response.id;
        }
        if (nextProps.fetch_save_movie_response.slug != "") {
          slug = nextProps.fetch_save_movie_response.slug;
        }
        this.redirectto(movieId, slug);

      }
    }

    if (nextProps.ratingType && nextProps.ratingType != "") {
      this.setState({ rateType: nextProps.ratingType })
    }
    if (nextProps.patrolCore && nextProps.patrolCore.results && Array.isArray(nextProps.patrolCore.results) && nextProps.patrolCore.results.length > 0) {
      this.setState({ patrolMovie_list: nextProps.patrolCore.results }, () => {
        if (nextProps.patrolCore.results && nextProps.patrolCore.results[0] && loaded == true) {
          this.getMovieDetail(nextProps.patrolCore.results[0])
        }

      });
    }





    if (nextProps?.movie_details) {
      this.setState({
        about: nextProps?.movie_details,
      });
    }
  }

  componentDidMount = () => {
    let { type, listingType, patrolMovie_list } = this.state;
    let id = ""
    //this.props.get_patrol_crp(id,type,listingType);
  }
  getMovieDetail = async (data) => {
    this.setState({ loaded: false, about: "" }, () => {
      this.props.get_type_detail({ type: 'movies', id: data.id });
    })
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let { rateType } = this.state;
    this.setState({
      [name]: value
    });
    if (name == "category_id") {
      let type = "category_id";
      this.setState({
        categoryError: "",
        category_id: e.target.value,
        patrolMovie_list: [],
        about: "",
        loaded: true
      }, () => {
        let id = this.state.category_id;
        let typeCore = "corelisting";
        let listingType = "movies";
        this.props.get_patrol_crp(id, typeCore, listingType);
      });
      this.props.initFetchMovieSubCategories(e.target.value, type);
    }
  }

  handleSkip = () => {
    let { patrolMovie_list } = this.state;
    if (patrolMovie_list && Array.isArray(patrolMovie_list) && patrolMovie_list.length > 0) {
      patrolMovie_list.shift();
      this.setState({ patrolMovie_list, about: "" }, () => {
        this.getMovieDetail(patrolMovie_list[0])
      });

    }

  }

  handleMovieCoreSubmit = () => {
    let { reason, decision, patrolMovie_list } = this.state
    if (reason == "") {
      this.setState({
        reasonError:
          "This field is required"
      });
    } else {
      if (patrolMovie_list && Array.isArray(patrolMovie_list) && patrolMovie_list.length > 0) {
        let params = {}
        params.flag_details = {}
        params.flag_details.type = "userentries";
        params.flag_details.decision = decision;
        params.flag_details.reason = reason;
        params.flag_details.object_id = patrolMovie_list && patrolMovie_list[0] ? patrolMovie_list[0].id : "";
        params.flag_details.is_patroled = true;
        this.props.submit_patrol_review(params);
        this.handleSkip()
      }

    }

  }

  handleChangeReview = (e) => {
    if (e.target.name === 'reason') {
      this.setState({ reason: e.target.value, reasonError: "" })
    }
    if (e.target.name === 'Approved') {
      this.setState({ decision: e.target.value })
    }
    if (e.target.name === 'Rejected') {
      this.setState({ decision: e.target.value })
    }
  };

  render() {
    const {
      openModal,
      togglePatrolMovieCoreModal,
      movies,
    } = this.props;
    const { category_id, patrolMovie_list, name, movieDetail, about, reason } = this.state;
    let avg_rating = about && about.total_rating && about.total_rating.avg_rating[0] ? about.total_rating.avg_rating[0] : 'norating.png';
    return (
      <>
        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={openModal}
          toggle={togglePatrolMovieCoreModal}
        >
          <form
            className="d-flex flex-column h-100"
          >
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button color="primary" onClick={togglePatrolMovieCoreModal}>
                    <FontAwesomeIcon icon="angle-left" />
                        back to listing
                      </Button>
                </div>
                <div>
                  <div className="bg-darker p-3 text-tertiary">
                    <div className="mb-2 text-primary font-weight-bold">
                      You’re Patrolling Core Listing Information
                        </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input
                              type="select"
                              className="transparent"
                              size="sm"
                            >
                              <option>{movies?.category}</option>
                            </Input>
                          </FormGroup>
                        </div>
                      </li>
                      <li className="px-2">
                        <div className="d-flex">
                          <div className="mr-1">
                            <span className="text-danger">*</span>
                          </div>
                          <FormGroup>
                            <Input
                              id="category_id"
                              name="category_id"
                              onChange={this.handleChange}
                              type="select"
                              className="transparent"
                              size="sm"
                              selected={category_id}
                            >
                              <option value="">Select Category</option>
                              {movies?.children?.map((movie) => {
                                return (
                                  <option value={movie.id} key={movie.id}>
                                    {movie.category}
                                  </option>
                                );
                              })}
                            </Input>
                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.categoryError}</span>
                          </FormGroup>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <ModalBody className="bg-white text-dark">
              <div className="wk-crpmodal">
                <Row>
                  <Col md="7">
                    <div className="font-weight-bold mb-0 text-primary mb-0">Step 1. Get All the facts</div>
                    <p className="text-dark font-weight-bold">The Brief.</p>
                    <div className="wk-core-petrol-listingleft d-flex flex-wrap">
                      <div className="wk-core-petrol-listingimage">
                        {about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].url ?
                          <img
                            src={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].url}`}
                            className="img-fluid"
                            onError={(error) =>
                              (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                            }
                            alt={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about.listing_profileimage[0].caption ? about.listing_profileimage[0].caption : 'no-image'}`}
                          />
                          :
                          <img alt="" src="https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png" className="img-fluid" />

                        }

                      </div>
                      <div className="wk-core-petrol-listingcontent">
                        <h4 className="fs-18">{about?.name}
							 &nbsp;<span className="fs-14">{about && Array.isArray(about.taxonomy_list) && about.taxonomy_list.length > 0 ?
                            about.taxonomy_list.map((taxonomy) => {
                              return <>{taxonomy} </>
                            })
                            : ""
                          }</span></h4>
                        {/*<img src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png" alt="star"/>
								 <p className="mt-2 mb-0">0 reviews</p>*/}
                        <p className="fs-14">
                          <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                            className="img-fluid"
                            onError={(error) =>
                              (error.target.src = `../../../assets/images/norating.png`)
                            }
                            alt={`${about && about.total_rating && about.total_rating.avg_rating[1] ? about.total_rating.avg_rating[1] : 'no-rating'}`}

                          />{" "}{(about && about.total_rating && about.total_rating.total_review) ? about.total_rating.total_review : 0} reviews</p>
                        <p className="mt-2 mb-0">Category: <span className="font-weight-bold text-dark">
                          {about && Array.isArray(about.taxonomy_list) && about.taxonomy_list.length > 0 ?
                            about.taxonomy_list.map((taxonomy) => {
                              return <>{taxonomy} </>
                            })
                            : ""
                          }</span></p>
                      </div>
                    </div>
                  </Col>
                  <Col md="5">
                    <div className=" font-weight-bold mb-2 text-primary">Step 2. Approve or Disapprove and tell us why?</div>
                    <div className="wk-approve-petrol-radio">
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" name="Approved" value="Approved" checked={this.state.decision === "Approved"} onChange={this.handleChangeReview} />Approved</label>
                      <label className="fs-14 mr-4"><input type="radio" className="mr-2" name="Rejected" value="Rejected" checked={this.state.decision === "Rejected"} onChange={this.handleChangeReview} />Rejected</label>
                    </div>
                    <textarea className="form-control wk-crpmodal-quick-note wk-crpmodal-quick-note2  w-100" onChange={this.handleChangeReview}
                      value={reason} name="reason" placeholder="Tell us why?" ></textarea>
                    <span style={{ color: 'red', fontSize: "13px" }}>{this.state.reasonError}</span>

                    <div className="text-right mt-3">
                      <Button className="ml-0" color="primary" size="sm" onClick={this.handleMovieCoreSubmit}>
                        Submit
                      </Button>
                      <a href="javascript:void(0)" className="ml-4" onClick={() => {
                        this.handleSkip();
                      }}>Skip ></a>
                    </div>
                  </Col>
                </Row>
              </div>

            </ModalBody>
          </form>
        </Modal>



      </>


    );



  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  directorListing: state.footer.directorListing,
  writerListing: state.footer.writerListing,
  castListing: state.footer.castListing,
  producerListing: state.footer.producerListing,
  fetch_save_movie_response: state.movies.fetch_save_movie_response,
  patrolCore: state.footer.patrolCore,
  get_movie_detail: state.movies.get_movie_detail,
  movie_details: state.item_detail.item_details,
});

const mapProps = (dispatch) => ({
  submit_patrol_review: (data) => dispatch(submit_patrol_review(data)),
  initFetchMovieSubCategories: (categoryId, type) => dispatch(initFetchMovieSubCategories(categoryId, type)),
  search_moviesMembers: (value, type) => dispatch(search_moviesMembers(value, type)),
  get_patrol_crp: (categoryId, type, listType) => dispatch(get_patrol_crp(categoryId, type, listType)),
  get_type_detail: ({ type, id }) => dispatch(get_type_detail({ type, id }))
});
export default withRouter(connect(mapState, mapProps)(PatrolMovieCoreModal));