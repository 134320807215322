import { callApi, callApiAdvance, responseCodeValidator } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { auth_logout_request } from '../actions/auth';

export const MY_PROFILE = "MY_PROFILE";
export const VERIFY_PHONE_NUMBER = "VERIFY_PHONE_NUMBER";
export const RESET_VERIFY_PHONE_NUMBER = "RESET_VERIFY_PHONE_NUMBER";
export const SEARCH_THEATER = "SEARCH_THEATER";
export const GET_MOVE_SET = "GET_MOVE_SET";
export const GET_MY_POSTS_LIST = "GET_MY_POSTS_LIST";
export const RESET_MY_POSTS_LIST = "RESET_MY_POSTS_LIST";
export const GET_ALBUM_TYPES_LIST = "GET_ALBUM_TYPES_LIST";
export const GET_ALBUM_TYPE_DATA = "GET_ALBUM_TYPE_DATA";
export const GET_SEARCH_PEOPLE = "GET_SEARCH_PEOPLE";
export const GET_SEARCH_PEOPLE_EDIT = "GET_SEARCH_PEOPLE_EDIT";
export const GET_SEARCH_PEOPLE_LOADING = "GET_SEARCH_PEOPLE_LOADING";
export const GET_SEARCH_PEOPLE_ERROR = "GET_SEARCH_PEOPLE_ERROR";
export const GET_REVIEWGROUP = "GET_REVIEWGROUP";
export const GET_PEOPLE_LIST = "GET_PEOPLE_LIST";
export const GET_TIPS_WARNING_DATA = "GET_TIPS_WARNING_DATA";
export const SEARCH_USERNAME = "SEARCH_USERNAME";
export const UPDATE_MY_PROFILE_ERROR = "UPDATE_MY_PROFILE_ERROR";
export const PEOPLE_PROFILE = "PEOPLE_PROFILE";
export const LOGGED_USER_PROFILE = "LOGGED_USER_PROFILE"

export const GET_MY_PRIVACY_SETTING = "GET_MY_PRIVACY_SETTING";
export const GET_TAG_LIST = "GET_TAG_LIST";





export const my_profile_ = (data) => {
  return {
    type: MY_PROFILE,
    payload: data,
  };
};

export const verify_phone_number_ = (data) => {
  return {
    type: VERIFY_PHONE_NUMBER,
    payload: data,
  };
}

export const reset_verify_phone_number_ = () =>{
  return {
    type: RESET_VERIFY_PHONE_NUMBER,
    payload: {}
  }
}

export const reset_verify_phone_number = () => {
  return (dispatch) => {
    return dispatch(reset_verify_phone_number_());
  }
}

export const updatePhoneNumber = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      callApi(`/api/myprofilephone/`, "POST", data).then((response) => {
        if (response && responseCodeValidator(String(response?.code))) {
          dispatch(verify_phone_number_(response));
          return resolve(response);
        }
        return resolve();
      });
    })
  }
}

export const verify_otp = ({ phoneNumber, otp }) => {

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      callApi(`/api/verify/${phoneNumber}/${otp}/`, 'POST').then((res) => {
        if (res && responseCodeValidator(String(res.code))) {
          if (res?.results[0]?.status) {
            dispatch(my_profile());
          }
          return resolve(res);
        }
        return resolve(res);
      });
    });
  }
}
export const my_profile = (msg) => {
  return (dispatch) => {
    callApi(`/api/myprofile/?q=`, "GET")
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          if (msg) {
            response['myprofilephone'] = msg;
          }
          //dispatch(logged_user_profile_(response));
          return dispatch(my_profile_(response));

        }
      });
  };
};

export const logged_user_profile_ = (data) => {
  return {
    type: LOGGED_USER_PROFILE,
    payload: data,
  };
};

export const logged_user_profile = (msg) => {
  return (dispatch) => {
    callApi(`/api/myprofile/?q=`, "GET")
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          if (msg) {
            response['myprofilephone'] = msg;
          }
          return dispatch(my_profile_(response));
          //return dispatch(my_profile_(response));

        }
      });
  };
};
export const people_profile_ = (data) => {
  return {
    type: PEOPLE_PROFILE,
    payload: data,
  };
};

export const people_profile = (id) => {
  return (dispatch) => {
    return callApi(`/api/people/${id}/`, "GET")
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          // dispatch(my_profile_(response));          
          return dispatch(people_profile_(response));
        }
      });
  };
};


export const my_profile_update = (msg) => {
  return (dispatch) => {
    callApiAdvance(`/api/myprofile/?q=`, "GET")
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          if (msg) {
            response['myprofilephone'] = msg;
          }
          return dispatch(my_profile_(response));
        }
      });
  };
};
export const update_my_profile = (data, entity) => {
  return (dispatch) => {
    callApi("/api/myprofile/?q=", "PUT", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        if (entity == 'Username') {
          dispatch(update_my_profile_error({ username: null }));
        }

        if (response.error === true) {
          const errorMessage = (response.message) || (`${entity} update failed!`)
          toast(errorMessage, {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
          dispatch(update_my_profile_error({ entity: null }));
        } else {
          toast(`${entity} updated successfully!`, {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }

        return dispatch(my_profile());
      } else if (response && response.code == 400 && response.data) {
        if (response.data.user && response.data.user.username && entity == 'Username') {
          return dispatch(update_my_profile_error({ username: response.data.user.username[0] }));
        }
      }
    });
  };
};

// "/api/email/change/", "POST", data
export const update_email = (data) => {
  return (dispatch) => {
    callApi("/api/myprofile/?q=", "PUT", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${response.message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(my_profile());
      }
    });
  };
};

export const search_theater_ = (data) => {
  return {
    type: SEARCH_THEATER,
    payload: data,
  };
};

export const getListOfPeopleAction = (data) => {
  return {
    type: GET_PEOPLE_LIST,
    payload: data,
  };
};

export const getListOfTags = (data) => {
  return {
    type: GET_TAG_LIST,
    payload: data,
  };
};

export const search_theater = (query) => {
  return (dispatch) => {
    callApi(`/api/search/reviewstheater/?q=${query}&sensor=false`, "GET").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(search_theater_(response.results));
        }
      }
    );
  };
};

export const change_password = (data) => {
  return (dispatch) => {
    callApi("/api/password/change/", "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${response.message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    });
  };
};

export const upload_cover_media = (data) => {
  return (dispatch) => {
    callApi("/upload/multiuploader/?album=profile", "POST", data, true).then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(
            update_my_profile(
              { current_cover_pic: response.id },
              "Cover Picture"
            )
          );
        }
      }
    );
  };
};

export const upload_delete_profile_media = (data) => {
  return (dispatch) => {
    callApi("/upload/multiuploader_delete_multiple/", "POST", data).then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(my_profile());
        }
      }
    );
  };
};

export const upload_move_set_profile_media_ = (data) => {
  return {
    type: GET_MOVE_SET,
    payload: data,
  };
};

export const upload_move_set_profile_media = (data) => {
  return (dispatch) => {
    callApi(
      `/panning/?left=${data.left}&top=${data.top}&x=${data.x}&y=${data.y}`,
      "GET",
      data
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(upload_move_set_profile_media_());
        return dispatch(my_profile());
      }
    });
  };
};

export const get_my_posts_list_ = (data) => {
  return {
    type: GET_MY_POSTS_LIST,
    payload: data,
  };
};

export const get_my_posts_list = (pageNo = 1) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      callApi(`/api/feeds/?postID=undefined&page=${pageNo}`, "GET").then(
        (response) => {
          if (response && response.code !== 400 && response.code !== 500) {
            dispatch(get_my_posts_list_(response));
            return resolve()
          } else {
            return resolve();
          }
        }
      );
    });
  };
};

export const reset_my_posts_list_ = () => {
  return {
    type: RESET_MY_POSTS_LIST
  };
};

export const reset_my_posts_list = () => {
  return (dispatch) => {
    dispatch(reset_my_posts_list_());
  }
};

export const get_album_types_list_ = (data) => {
  return {
    type: GET_ALBUM_TYPES_LIST,
    payload: data,
  };
};

export const get_album_types_list = () => {
  return (dispatch) => {
    callApi(
      `/api/taxonomy/?category=Album&depth=true&delsel=&ancestor=&sub_cat=&parent=`,
      "GET"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_album_types_list_(response));
      }
    });
  };
};

export const get_album_type_data_ = (data) => {
  return {
    type: GET_ALBUM_TYPE_DATA,
    payload: data,
  };
};

export const get_album_type_data = (mediaType, albumType, pageNo) => {
  return (dispatch) => {
    callApi(
      `/upload/list/${mediaType}/?album=${albumType}&page=${pageNo}`,
      "GET"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        response["type"] = mediaType;
        response["page"] = pageNo;
        response["ablum"] = albumType ? true : false;
        return dispatch(get_album_type_data_(response));
      }
    });
  };
};

export const delete_selected_gallery_media = (data) => {
  return (dispatch) => {
    callApi(`/upload/multiuploader_delete_multiple/`, "POST", data).then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return true;
        }
      }
    );
  };
};

export const add_my_post = (data, filterType, pageNo, module_type) => {
  return (dispatch) => {
    callApi("/api/feeds/?entity=", "POST", data).then((response) => {

      let message = "";
      if (response && response.code !== 400 && response.code !== 500) {
        if (module_type) {
          message = "Link Embedded.";
        } else {
          message = "Your post has been submitted successfully.";
          if (response.message !== "Saved successfully") {
            message = response.msg;
          }
          if (response?.data?.msg === "Your account is still not activated. Please click the activation link in your email to activate your WR account.") {
            message = response.data.msg;
          }
        }
        toast(message, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_my_posts_list(pageNo));
      }
    });
  };
};

export const get_search_people_ = (data) => {
  return {
    type: GET_SEARCH_PEOPLE,
    payload: data,
  };
};

export const get_search_people = (data, search) => {
  return (dispatch) => {
    callApi(`/api/tags/?q=${data.query}&listing=false`, "GET").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(get_search_people_(response));
        }
      }
    );
  };
};

export const get_search_people_edit_ = (data) => {
  return {
    type: GET_SEARCH_PEOPLE_EDIT,
    payload: data,
  };
};

export const get_search_people_edit = (data, search) => {
  return (dispatch) => {
    callApi(`/api/tags/?q=${data.query}&listing=false`, "GET").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(get_search_people_edit_(response));
        }
      }
    );
  };
};

export const getListOfPeople = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let url 
      if(data!== undefined){
        url = `/api/tags/?q=${data}&size=20`
      }else{
        url = `/api/tags/?q=`
      }
      callApi(url, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          dispatch(getListOfPeopleAction(response));
          return resolve();
        } else {
          return resolve();
        }
      })
    });

  };
};


export const get_tags_list = (data, page) => {
  return (dispatch) => {
    let newObject = {}
    callApi(`/api/tags/?q=${data.query}`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let newArray = [];
        let newObject = {}
        response && Array.isArray(response.results) &&
          response.results.length > 0 && response.results.map((customList, index) => {
            if (customList?._source) {

              newObject.username = customList?._source.slug;
              newObject.profile_pic = customList?._source?.listing_profileimage[0]?.thumbnail;
              newObject.first_name = customList?._source.name;
              newObject.last_name = "";
              newObject.id = customList?._source.id;
            }
            newArray.push(newObject);
          })
        response["results"] = newArray;
        // console.log(response, 'response')
        return dispatch(getListOfPeopleAction(response));
      }
    });
  }
};


export const get_reviewgroup_ = (data) => {
  return {
    type: GET_REVIEWGROUP,
    payload: data,
  };
};

export const get_reviewgroup = (assetType, Id, type) => {
  return (dispatch) => {
    callApiAdvance({
      endpoint: type === 'thanku' ? `/api/contributiongroup/?action=${Id}&review_group=${type}` : `/api/reviewgroup/?${assetType}=${Id}&review_group=${type}`,
      method: "GET",
      loader: false,
    }).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_reviewgroup_(response));
      }
    });
  };
};

export const delete_my_post = (postId, filterType, pageNo, message) => {
  return (dispatch) => {
    callApi(`/api/deleteuserfeeds/${postId}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_my_posts_list(pageNo));
      }
    });
  };
};

export const edit_my_post = (data, type, filterType, pageNo) => {
  return (dispatch) => {
    callApi(`/api/feeds/${data.id}/?entity=`, "PUT", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(
          `${type.charAt(0).toUpperCase() + type.slice(1)
          } updated successfully!`,
          {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          }
        );
        return dispatch(get_my_posts_list(pageNo));
      }
    });
  };
};

export const add_my_post_reply = (data, filterType, pageNo) => {
  return (dispatch) => {
    callApi("/api/savereply/", "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Comment added successfully!`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_my_posts_list(pageNo));
      }
    });
  };
};

export const add_my_post_comment = (id, data, filterType, pageNo) => {
  return (dispatch) => {
    callApi(`/api/feeds/${id}/?entity=`, "PUT", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Comment added successfully!`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_my_posts_list(pageNo));
      }
    });
  };
};

export const delete_my_post_comment = (messageId, filterType, pageNo) => {
  return (dispatch) => {
    callApi(`/api/deleteuserfeeds/${messageId}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Comment deleted successfully!`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_my_posts_list(pageNo));
      }
    });
  };
};

export const clear_search_people = () => {
  return (dispatch) => {
    dispatch(get_search_people_([]));
  };
};

export const clear_search_people_edit = () => {
  return (dispatch) => {
    dispatch(get_search_people_edit_([]));
  };
};

export const add_review_group = (data,categorySection) => {
let url  
  if(categorySection==="professsionalReview"){
    url = "professionalreviewgroup"
  }else{
    url = "reviewgroup"
  }

  return (dispatch) => {
    callApiAdvance({
      endpoint: `/api/${url}/`,
      method: "POST",
      body: data,
      loader: false,
    }).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        /*return dispatch(get_my_posts_list(pageNo));*/
      }
    });
  };
};

export const add_phone_number = (data) => {
  return (dispatch) => {
    callApi("/api/myprofilephone/", "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = `Phone Number Added successfully!`;
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        //return dispatch(get_my_posts_list(pageNo));
      }
    });
  };
};

// Tips Warning

export const get_tips_warning_data_ = (data) => {
  return {
    type: GET_TIPS_WARNING_DATA,
    payload: data,
  };
};

export const get_tips_warning_data = (id) => {
  return (dispatch) => {
    callApi(`/api/professionaluserguide/?entry_type=${id}`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_tips_warning_data_(response))
      }
    });
  };
}

export const add_tips_warning_data = (data, name) => {
  return (dispatch) => {
    callApi(`/api/professionaluserguide/?entry_type=`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if (data.type == 'warning') {
          text = `You added a warning to  ${name}.`;
        }
        if (data.type == 'tips') {
          text = `You added a tip to  ${name}.`;
        }
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_tips_warning_data(data.user_entry))
      }
    });
  };
}

export const search_username_ = (data) => {
  return {
    type: SEARCH_USERNAME,
    payload: data,
  };
};

export const search_username = (data) => {
  return (dispatch) => {
    callApi(`/api/getusernames/?username=${data.username}&q=${data.username}&sensor=false`, "GET").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          if (!response.results) {
            response.results = [];
          }
          return dispatch(search_username_(response.results));
        }
      }
    );
  };
};

export const update_my_profile_error = (data) => {
  return {
    type: UPDATE_MY_PROFILE_ERROR,
    payload: data,
  };
};

export const add_privacy_setting = (data) => {
  return (dispatch) => {
    callApi("/api/privacy/", "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let message = "Privacy setting updated successfully!";
        toast(message, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_my_privacy_list(response));
      }
    });
  };
};

export const get_my_privacy_list_ = (data) => {
  return {
    type: GET_MY_PRIVACY_SETTING,
    payload: data,
  };
};

export const set_setting_enable = (data) => {
  return {
    type: "SET_SETTINGS_ENABLE",
    payload: data,
  };
};

export const get_my_privacy_list = () => {
  return (dispatch) => {
    callApi(`/api/settingsjson/`, "GET").then(

      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          // return dispatch(get_my_privacy_list_(response));
        }
      }
    );
  };
};

export const deactivate_my_account = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      callApi("/api/blockordeleteuser/", "POST", data).then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          let message = "Your account have been deactivated!";
          dispatch(auth_logout_request(data));

          resolve()
        }
      });
    });
  };
};

export const delete_my_account = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      callApi("/api/blockordeleteuser/", "POST", data).then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {

          dispatch(auth_logout_request(data));
          resolve()
        }
      });
    });
  };
};

