/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Component } from 'react';
import Moment from 'react-moment';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { add_education, delete_education, edit_education, get_education, search_university } from '../../../actions/workeducation';
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from "../../atoms/editBtn";
import OutsideClickHandler from "react-outside-click-handler";
import { debounce } from 'lodash';
import DatePicker from "react-datepicker";

class MyEducation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedUniversity: null,
            addEducationToggle: false,
            university_name: '',
            course: '',
            courseStartDate: '',
            courseEndDate: '',
            is_present: false,
            errors: {},
            editFormVisible: {},
            editItem: null,
            isSearching: true
        }
    }

    debounceSearchUniversity = debounce(value => {
        this.props.search_university(value);
    }, 500)

    handleChange = (e) => {
        let errors = { ...this.state.errors };
        let value = e.target.value;
        let name = e.target.name;
        if (name === 'is_present') {
            value = !this.state.is_present;
        }
        if (name == 'courseStartDate') {
            let date = moment(value, 'YYYY-MM', true);
            let isValid = date.isValid();
            if (isValid == false) {
                errors.courseStartDate = 'Incorrect Start Date';
            } else {
                delete errors.courseStartDate;
            }

        }
        if (name == 'courseEndDate') {
            let date = moment(value, 'YYYY-MM', true);
            let isValid = date.isValid();
            if (isValid == false) {
                errors.courseEndDate = 'Incorrect End Date';
            } else {
                delete errors.courseEndDate;
            }

        }
        this.setState({
            ...this.state,
            [name]: value,
            errors: errors,
            isSearching: true
        });
        if (name === 'university_name') {
            this.debounceSearchUniversity(value);
        }
    }

    handleEditChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        if (name === 'is_present') {
            value = !this.state.editItem.is_present;
        }
        this.setState({
            editItem: {
                ...this.state.editItem,
                [name]: value,
            }
        });
        if (name === 'university_name') {
            this.props.search_university(value);
        }
    }

    componentDidMount = () => {
        this.props.get_education()
    }

    handleOnClickEditEducation = (item) => {
        const editItem = {
            university_name: item.entry_name,
            course: item.title,
            is_present: item.is_present,
            courseStartDate: new Date(item.from_date),
            courseEndDate: item.is_present ? '' : new Date(item.to_date),
            errors: {},
            search_id: item.id,
            id: item.id
        }
        this.setState({
            editFormVisible: {
                [item.id]: true
            },
            editItem: editItem,
            addEducationToggle: false
        });
    }

    handleOnClickDeleteEducation = (id) => {
        this.props.delete_education(id);
    }

    handleAddToggle = () => {
        this.setState({
            selectedUniversity: null,
            addEducationToggle: true,
            university_name: '',
            course: '',
            courseStartDate: '',
            courseEndDate: '',
            is_present: false,
            errors: {},
            editFormVisible: {}
        });
    }

    hideAddCollapse = () => {
        this.setState({
            selectedUniversity: null,
            addEducationToggle: false,
            university_name: '',
            course: '',
            courseStartDate: '',
            courseEndDate: '',
            is_present: false,
            errors: {},
            editFormVisible: {}
        });
    }

    hideEditCollapse = () => {
        this.setState({
            selectedUniversity: null,
            addEducationToggle: false,
            editItem: null,
            editFormVisible: {}
        });
    }

    handleAddEducation = () => {
        let errors = { ...this.state.errors };
        if (this.state.course === '') {
            errors.course = 'This field is required';
        } else {
            delete errors.course;
        }

        if (this.state.university_name === '') {
            errors.university_name = 'Please select the University from dropdown list';
        } else {
            delete errors.university_name;
        }

        if (this.state.courseStartDate === '') {
            errors.courseStartDate = 'This field is required';
        } else {
            delete errors.courseStartDate;
        }

        if (!this.state.is_present && this.state.courseEndDate === '') {
            errors.courseEndDate = 'This field is required';
        } else if (this.state.courseStartDate !== '' && (this.state.courseEndDate !== '' || !this.state.courseEndDate)) {

            const newDate  = new Date();

            if (this.state.courseStartDate > newDate) {
                errors.courseStartDate = 'Start date must be less than your current date';
            } else if (this.state.courseEndDate > newDate && !this.state.is_present) {
                errors.courseEndDate = 'End date must be less than your current date';
            } else if (this.state.courseStartDate >= this.state.courseEndDate && !this.state.is_present)
                errors.courseEndDate = 'End date should be greater than your start date.';
            else {
                delete errors.courseEndDate;
                delete errors.courseStartDate
            }

            // if (this.state.courseStartDate > this.state.courseEndDate) {
            //     errors.courseEndDate = 'End date must be after the Start date';
            // } else {
            //     const date = new Date()
            //     const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit' })
            //     const [{ value: month }, , { value: year }] = dateTimeFormat.formatToParts(date);
            //     const newDate = `${year}-${month}`;

            //     if (this.state.courseEndDate > newDate) {
            //         errors.courseEndDate = 'End date should not be bigger than current date';
            //     } else {
            //         delete errors.courseEndDate;
            //     }
            // }
        } else {
            delete errors.courseEndDate;
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => ({
                ...prevState,
                errors: errors
            }));
        } else {
            const formatted_courseStartDate = moment(this.state.courseStartDate).format("MM-yyyy");
            const formatted_courseEndDate = this.state.is_present ? '' : moment(this.state.courseEndDate).format("MM-yyyy");
            let data = {
                from_date: formatted_courseStartDate,
                to_date: this.state.is_present ? '' : formatted_courseEndDate,
                user_entry: this.state.university_name,
                title: this.state.course,
                search_id: '',
                from_date_month: formatted_courseStartDate.split('-')[0],
                from_date_year: formatted_courseStartDate.split('-')[1],
                to_date_month: this.state.is_present ? '' : formatted_courseEndDate.split('-')[0],
                to_date_year: this.state.is_present ? '' : formatted_courseEndDate.split('-')[1],
                is_present: this.state.is_present
            };
            if (this.state.selectedUniversity && Object.keys(this.state.selectedUniversity).length > 0) {
                data.search_id = this.state.selectedUniversity._id;
                this.props.add_education(data);
                this.setState({
                    selectedUniversity: null,
                    addEducationToggle: false,
                    university_name: '',
                    course: '',
                    courseStartDate: '',
                    courseEndDate: '',
                    is_present: false,
                    errors: {},
                    editFormVisible: {}
                });
            }

        }
    }

    handleEditEducation = () => {
        let errors = { ...this.state.editItem.errors };
        if (this.state.editItem.course === '') {
            errors.course = 'This field is required';
        } else {
            delete errors.course;
        }

        if (this.state.editItem.university_name === '') {
            errors.university_name = 'Please select the University from dropdown list';
        } else {
            delete errors.university_name;
        }

        if (this.state.editItem.courseStartDate === '') {
            errors.courseStartDate = 'This field is required';
        } else {
            delete errors.courseStartDate;
        }

        if (!this.state.editItem.is_present && this.state.editItem.courseEndDate === '') {
            errors.courseEndDate = 'This field is required';
        } else if (this.state.editItem.courseEndDate !== '' && this.state.editItem.courseStartDate !== '') {
            if (this.state.editItem.courseStartDate > this.state.editItem.courseEndDate) {
                errors.courseEndDate = 'End date should be bigger than start date';
            } else {
                const newDate = new Date()

                if (this.state.editItem.courseEndDate > newDate) {
                    errors.courseEndDate = 'End date should not be bigger than current date';
                } else {
                    delete errors.courseEndDate;
                }
            }
        } else {
            delete errors.courseEndDate;
        }

        if (Object.keys(errors).length > 0) {
            this.setState(prevState => ({
                ...prevState,
                editItem: {
                    ...prevState.editItem,
                    errors: errors
                }
            }));
        } else {
            const formatted_courseStartDate = moment(this.state.editItem.courseStartDate).format("MM-yyyy");
            const formatted_courseEndDate = this.state.editItem.is_present ? '' : moment(this.state.editItem.courseEndDate).format("MM-yyyy");

            let data = {
                from_date: formatted_courseStartDate,
                to_date: this.state.editItem.is_present ? '' : formatted_courseEndDate,
                user_entry: '', //this.state.editItem.university_name,
                title: this.state.editItem.course,
                search_id: '',
                from_date_month: formatted_courseStartDate.split('-')[0],
                from_date_year: formatted_courseStartDate.split('-')[1],
                to_date_month: this.state.editItem.is_present ? '' : formatted_courseEndDate.split('-')[0],
                to_date_year: this.state.editItem.is_present ? '' : formatted_courseEndDate.split('-')[1],
                is_present: this.state.editItem.is_present,
                id: this.state.editItem.id
            };
            if (this.state.editItem.selectedUniversity && Object.keys(this.state.editItem.selectedUniversity).length > 0) {
                data.search_id = this.state.editItem.selectedUniversity._id;
                data.user_entry = this.state.editItem.selectedUniversity._id;
            }
            this.props.edit_education(data);
            this.setState({
                selectedUniversity: null,
                addEducationToggle: false,
                editItem: null,
                editFormVisible: {}
            });
        }
    }

    handleOnClickAddSelected = (item, type = 'add') => {
        this.props.search_university('');
        if (type === 'add') {
            this.setState({
                selectedUniversity: item,
                university_name: item._source.name
            });
        } else {
            this.setState({
                editItem: {
                    selectedUniversity: item,
                    ...this.state.editItem,
                    university_name: item._source.name
                }
            });
        }
    }
    handleChangeDate = ({ name, value, isEdit = false }) => {
        const { errors } = { ...this.state };
        if (value && errors[name]) {
            delete errors[name]
        }
        if (!isEdit) {
            this.setState({
                ...this.state,
                [name]: value,
                errors: errors
            });
        } else {
            this.setState({
                editItem: {
                    ...this.state.editItem,
                    [name]: value,
                }
            });
        }
    }


    render() {
        const {
            addEducationToggle,
            university_name,
            course,
            courseEndDate,
            courseStartDate,
            is_present,
            errors,
            editFormVisible,
            editItem,
            isSearching
        } = this.state

        const { education_data, search_university_info } = this.props;

        return (
            <div id="myEducation">
                <h2 className="text-secondary">My education</h2>
                <hr className="bg-secondary" />

                <ul className="list-unstyled" style={{ maxHeight: '325px', overflowY: 'auto', overflowX: 'hidden' }}>
                    {education_data && education_data.length > 0 ? education_data.map(edu => {
                        let profile_image = require('../../../assets/images/icons/placeholder-img.png');
                        if (edu?.profile_image && Array.isArray(edu?.profile_image) && edu?.profile_image[0] && edu?.profile_image[0].thumbnail) {
                            profile_image = edu?.profile_image[0].thumbnail
                        }

                        return (
                            <li className="mb-3" key={edu.id}>
                                <div className="d-flex flex-nowrap mx-n1">
                                    <div className="px-1 mt-1 mb-2 order-1 order-sm-1">
                                        <img
                                            src={profile_image}
                                            alt=""
                                            width={50}
                                            className="listing-profile-image"
                                        />
                                    </div>

                                    <div className="px-1 col-auto ml-auto order-2 order-sm-3">
                                        <div className="interactive">
                                            <div className="interactive-appear">
                                                <div className="text-nowrap">
                                                    <EditBtn color="gold" onClick={() => this.handleOnClickEditEducation(edu)} />
                                                    <DeleteBtn color="gold" onClick={() => this.handleOnClickDeleteEducation(edu.id)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="d-sm-none d-lg-block order-3 w-100"></div> */}
                                    <div className="d-flex flex-fill px-1 mx-n1 order-4 order-sm-2">
                                        <div className="text-secondary-dark px-1">
                                            <div className="font-weight-bold fs-14 ff-base lh-1-2 mb-1 text-break">
                                                <span>{edu.title}</span>
                                                <span>&nbsp;at&nbsp;</span>
                                                <span>{edu.user_entry}</span>
                                            </div>
                                            <div className="fs-13">
                                                <Moment format="MMMM YYYY">
                                                    {edu.from_date}
                                                </Moment> - {!edu.to_date ? edu.is_present_label : (
                                                    <Moment format="MMMM YYYY">
                                                        {edu.to_date}
                                                    </Moment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Edit Education Details */}
                                {editItem && editFormVisible[edu.id] && (
                                    <div className="shadow p-3 mt-2">
                                        <Form>
                                            <h5 className="text-secondary-dark fs-30">
                                                Edit Education Details
                                            </h5>
                                            <div className="text-dark">
                                                <div className="mb-2 position-relative">
                                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">University/College</Label>
                                                    <Input
                                                        bsSize="sm"
                                                        type="text"
                                                        name="university_name"
                                                        placeholder="Type/Search University/Institute"
                                                        value={editItem.university_name}
                                                        onChange={this.handleEditChange}
                                                    />
                                                    {editItem.errors && editItem.errors.university_name && (
                                                        <div className="text-danger small mt-1">
                                                            {editItem.errors.university_name}
                                                        </div>
                                                    )}
                                                    {/* Instant Search Results */}
                                                    {search_university_info && search_university_info.length > 0 && (
                                                        <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                            {search_university_info.map(item => {
                                                                return (
                                                                    <li className="result-item" role="button" key={item._id}>
                                                                        <div className="result-link fs-14" onClick={() => this.handleOnClickAddSelected(item, 'edit')}>
                                                                            <div className="d-flex">
                                                                                <div className="mr-2">
                                                                                    <img src={(item._source?.listing_profileimage && item._source?.listing_profileimage?.length) ? item._source.listing_profileimage[0]?.thumbnail : require('../../../assets/images/icons/placeholder-img-alt.jpg')} width={40} alt="" onError={(e) => e.target.src = require('../../../assets/images/icons/placeholder-img-alt.jpg')} />
                                                                                </div>
                                                                                <div>
                                                                                    <span>{item._source.name}</span>
                                                                                    <div>
                                                                                        <img title={item._source.total_rating?.avg_rating[1]} src={(!item._source.total_rating || item._source.total_rating?.avg_rating[1] === 'No Rating') ? require('../../../assets/images/icons/norating.png') : require(`../../../assets/images/icons/${Math.floor(item._source.total_rating?.avg_rating[1])}rating.png`)} alt="" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <span>{item._source.category}</span>
                                                                                    </div>
                                                                                    <div className="text-muted">
                                                                                        <span>{item._source.address && item._source.address[0] && (
                                                                                            <span>
                                                                                                {item._source.address[0].address1},{item._source.address[0].city},{item._source.address[0].state},{item._source.address[0].country},
                                                                                                {item._source.address[0].zipcode}
                                                                                            </span>
                                                                                        )}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    )}
                                                </div>
                                                <div className="mb-2">
                                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">Degree/Course</Label>
                                                    <Input
                                                        bsSize="sm"
                                                        type="text"
                                                        name="course"
                                                        placeholder="Enter the degree/course"
                                                        value={editItem.course}
                                                        onChange={this.handleEditChange}
                                                    />
                                                    {editItem.errors && editItem.errors.course && (
                                                        <div className="text-danger small mt-1">
                                                            {editItem.errors.course}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-2">
                                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">Start Date</Label>
                                                    <div className="custom-date-picker">
                                                        <DatePicker
                                                            placeholderText="Enter end date"
                                                            dateFormat="MMMM yyyy"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="scroll"
                                                            maxDate={new Date()}
                                                            className="form-control-sm form-control"
                                                            selected={editItem.courseStartDate}
                                                            onChange={(date) => { this.handleChangeDate({ name: 'courseStartDate', value: date, isEdit: true }) }}
                                                        />
                                                    </div>
                                                    {/* <Input
                                                        bsSize="sm"
                                                        type="month"
                                                        name="courseStartDate"
                                                        placeholder="Enter start date"
                                                        value={editItem.courseStartDate}
                                                        onChange={this.handleEditChange}
                                                    /> */}
                                                    {editItem.errors && editItem.errors.courseStartDate && (
                                                        <div className="text-danger small mt-1">
                                                            {editItem.errors.courseStartDate}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-2" hidden={editItem.is_present}>
                                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">End Date</Label>
                                                    <div className="custom-date-picker">
                                                        <DatePicker
                                                            placeholderText="Enter end date"
                                                            dateFormat="MMMM yyyy"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="scroll"
                                                            maxDate={new Date()}
                                                            className="form-control-sm form-control"
                                                            selected={editItem.courseEndDate}
                                                            onChange={(date) => { this.handleChangeDate({ name: 'courseEndDate', value: date, isEdit: true }) }}
                                                        />
                                                    </div>
                                                    {/* <Input
                                                        bsSize="sm"
                                                        type="month"
                                                        name="courseEndDate"
                                                        placeholder="Enter end date"
                                                        value={editItem.courseEndDate}
                                                        onChange={this.handleEditChange}
                                                    /> */}
                                                    {editItem.errors && editItem.errors.courseEndDate && (
                                                        <div className="text-danger small mt-1">
                                                            {editItem.errors.courseEndDate}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mb-2">
                                                    <FormGroup check>
                                                        <Label className="font-weight-bold ff-base fs-14" check>
                                                            <Input type="checkbox" name="is_present" value={editItem.is_present}
                                                                onChange={this.handleEditChange} checked={editItem.is_present} /> Currently here
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <div className="text-right">
                                                    <Button size="sm" color="primary mr-2" onClick={this.handleEditEducation}>
                                                        Submit
                                                    </Button>
                                                    <Button size="sm" color="primary ml-0" onClick={() => this.hideEditCollapse()}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                        <hr />
                                    </div>
                                )
                                }
                            </li>
                        )
                    }) : ''}

                </ul>


                <div>

                    <Button color="link" className="text-secondary-dark font-weight-bold p-0 hover-blue _not-icon"
                        onClick={this.handleAddToggle}>
                        <span className="icon-add-new"></span> add new education
                    </Button>
                    {/* Add Education Details */}
                    <div className="shadow p-3 mt-2" hidden={!addEducationToggle}>

                        <Form>
                            <h5 className="text-secondary-dark fs-30">
                                Add Education Details
                            </h5>
                            <div className="text-dark">
                                <div className="mb-2 position-relative">
                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">University/College</Label>
                                    <Input
                                        bsSize="sm"
                                        type="text"
                                        name="university_name"
                                        value={university_name}
                                        onChange={this.handleChange}
                                        placeholder="Type/Search University/Institute"
                                    />
                                    {errors && errors.university_name && (
                                        <div className="text-danger small mt-1">
                                            {errors.university_name}
                                        </div>
                                    )}
                                    {/* Instant Search Results */}
                                    <OutsideClickHandler
                                        onOutsideClick={() => {
                                            this.setState({ isSearching: false })
                                        }}>
                                        {search_university_info && search_university_info.length > 0 && (

                                            <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                {search_university_info.map(item => {
                                                    return (
                                                        <>
                                                            {isSearching ?
                                                                <li className="result-item" role="button" key={item._id}>
                                                                    <div className="result-link fs-14" onClick={() => this.handleOnClickAddSelected(item)}>
                                                                        <div className="d-flex">
                                                                            <div className="mr-2">
                                                                                <img src={(item._source?.listing_profileimage && item._source?.listing_profileimage?.length) ? item._source.listing_profileimage[0]?.thumbnail : require('../../../assets/images/icons/placeholder-img-alt.jpg')} width={40} alt="" onError={(e) => e.target.src = require('../../../assets/images/icons/placeholder-img-alt.jpg')} />
                                                                            </div>
                                                                            <div>
                                                                                <span>{item._source.name}</span>
                                                                                <div>
                                                                                    <img title={item._source.total_rating?.avg_rating[1]} src={(!item._source.total_rating || item._source.total_rating?.avg_rating[1] === 'No Rating') ? require('../../../assets/images/icons/norating.png') : require(`../../../assets/images/icons/${Math.floor(item._source.total_rating?.avg_rating[1])}rating.png`)} alt="" />
                                                                                </div>
                                                                                <div>
                                                                                    <span>{item._source.category}</span>
                                                                                </div>
                                                                                <div className="text-muted">
                                                                                    <span>{item._source.address && item._source.address[0] && (
                                                                                        <span>
                                                                                            {item._source.address[0].address1},{item._source.address[0].city},{item._source.address[0].state},{item._source.address[0].country},
                                                                                            {item._source.address[0].zipcode}
                                                                                        </span>
                                                                                    )}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                : ""}
                                                        </>

                                                    )
                                                })}
                                            </ul>

                                        )}
                                    </OutsideClickHandler>
                                </div>
                                <div className="mb-2">
                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">Degree/Course</Label>
                                    <Input
                                        bsSize="sm"
                                        type="text"
                                        name="course"
                                        value={course}
                                        onChange={this.handleChange}
                                        placeholder="Enter the degree/course"
                                    />
                                    {errors && errors.course && (
                                        <div className="text-danger small mt-1">
                                            {errors.course}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">Start Date</Label>
                                    <div className="custom-date-picker">
                                        <DatePicker
                                            placeholderText="Enter start date"
                                            dateFormat="MMMM yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="scroll"
                                            maxDate={new Date()}
                                            className="form-control-sm form-control"
                                            selected={courseStartDate}
                                            onChange={(date) => { this.handleChangeDate({ name: 'courseStartDate', value: date }) }}
                                        />
                                    </div>
                                    {/* <Input
                                        bsSize="sm"
                                        type="month"
                                        name="courseStartDate"
                                        value={courseStartDate}
                                        onChange={this.handleChange}
                                        placeholder="Enter start date"
                                    /> */}
                                    {errors && errors.courseStartDate && (
                                        <div className="text-danger small mt-1">
                                            {errors.courseStartDate}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-2" hidden={is_present}>
                                    <Label size="sm" className="font-weight-bold mb-0 ff-base">End Date</Label>
                                    <div className="custom-date-picker">
                                        <DatePicker
                                            placeholderText="Enter start date"
                                            dateFormat="MMMM yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="scroll"
                                            maxDate={new Date()}
                                            className="form-control-sm form-control"
                                            selected={courseEndDate}
                                            onChange={(date) => { this.handleChangeDate({ name: 'courseEndDate', value: date }) }}
                                        />
                                    </div>
                                    {/* <Input
                                        bsSize="sm"
                                        type="month"
                                        name="courseEndDate"
                                        value={courseEndDate}
                                        onChange={this.handleChange}
                                        placeholder="Enter end date"
                                    /> */}
                                    {errors && errors.courseEndDate && (
                                        <div className="text-danger small mt-1">
                                            {errors.courseEndDate}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-2">
                                    <FormGroup check>
                                        <Label className="font-weight-bold ff-base fs-14" check>
                                            <Input type="checkbox" name="is_present" value={is_present}
                                                onChange={this.handleChange} checked={is_present} /> Currently here
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="text-right">
                                    <Button size="sm" color="primary mr-2" onClick={this.handleAddEducation}>
                                        Submit
                                    </Button>
                                    <Button size="sm" color="primary ml-0" onClick={() => this.hideAddCollapse()}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Form>

                        <hr />
                    </div>
                </div>






            </div>
        );
    }
}

const mapState = (state) => ({
    education_data: state.workeducation.education_data,
    search_university_info: state.workeducation.search_university_info,
});

const mapDispatch = (dispatch) => ({
    get_education: () => dispatch(get_education()),
    add_education: (data) => dispatch(add_education(data)),
    search_university: (query) => dispatch(search_university(query)),
    edit_education: (data) => dispatch(edit_education(data)),
    delete_education: (id) => dispatch(delete_education(id)),
});

export default withRouter(connect(mapState, mapDispatch)(MyEducation));
