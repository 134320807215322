import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { createBrowserHistory } from 'history';
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "react-datepicker/dist/react-datepicker.css";

import { Provider } from "react-redux";
import configureStore from "./store";
import interceptor from "./utils/interceptor";

const history = createBrowserHistory();
const store = configureStore();
interceptor.setupInterceptors(store, history);

serviceWorker.unregister();

const render = (Component) => {
  return ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        {/* <HelmetMetaData /> */}
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
};

render(App);

// if (module.hot) {
//   module.hot.accept("./App", () => {
//     const NextApp = require("./App").default;
//     render(NextApp);
//   });
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
