import React, { Component } from "react";
import { Button, FormGroup, Input, Media } from 'reactstrap';

export default class Commentbox extends Component {
    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        let { item, wallReplyText, handleWallReplyText, handleSubmitComment,current_profile_picture } = this.props;
		let imageSrc = null
		if(current_profile_picture){
			imageSrc = current_profile_picture;
		}
		
        return (
            <div className={`bg-white p-3 mt-2 mb-4 ${this.props.bgColor}`} >
                <Media className="media-post">
                    <Media>
					<Media
                            object
                            className="img-circle _35x35"
                            src={imageSrc != null ? imageSrc : "../../../assets/images/icons/user-circle.png"}
                            onError={(e) => (e.target.src = require("../../../assets/images/icons/user-circle.png"))}
                            alt="User Image"
                        />
                    </Media>
                    <Media body>
                        <FormGroup className="mb-0">
						 <Input
                                onChange={(e) => { handleWallReplyText(item?.id, e.target.value) }}
                                bsSize="sm"
                                value={wallReplyText[item?.id] != undefined ? wallReplyText[item?.id] : ""}
                                className="mb-2 comment-form-control"
                                type="textarea"
                                rows="1"
                                placeholder="Add a Comment..."
                            />
                            <div className="text-right">
                                <div className="text-right">
                                    <Button
                                        disabled={wallReplyText && wallReplyText[item?.id] ? false : true}
                                        onClick={() => {
                                            if(!this.checkIfLoggedIn()){
                                                this.props.forceToggleLoginModal();
                                                return false;
                                            }
                                            handleSubmitComment({ id: item.id, body: wallReplyText[item?.id] });
                                        }}
                                        size="sm"
                                        color="primary">
                                        {'Submit'}
                                    </Button>
                                </div>
                            </div>
                        </FormGroup>
                    </Media>
                </Media>
            </div>
        )
    }
}
