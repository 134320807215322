import { callApi, callApiAdvance } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { getQueryString, checkIfHaveParams } from "../utils/validation";
import {people_profile } from "../actions/user";

const FETCH_PENDING_REQUESTS_COUNT = "FETCH_PENDING_REQUESTS_COUNT";
const FETCH_PENDING_REQUESTS = "FETCH_PENDING_REQUESTS";
const FETCH_SENT_FRIEND_REQUEST_COUNT = "FETCH_SENT_FRIEND_REQUEST_COUNT";
const FETCH_SENT_FRIEND_REQUEST = "FETCH_SENT_FRIEND_REQUEST";
const FETCH_FOLLOWING_COUNT = "FETCH_FOLLOWING_COUNT";
const FETCH_FOLLOWING = "FETCH_FOLLOWING";
const FETCH_IMPORT_COUNT = "FETCH_IMPORT_COUNT";
const FETCH_CONNECTIONS = "FETCH_CONNECTIONS";
const IS_LOADING_CONNECTIONS = "IS_LOADING_CONNECTIONS";
const IS_LOADING_FETCH_INVITED = "IS_LOADING_FETCH_INVITED";


const FETCH_FIND_CONNECTION = "FETCH_FIND_CONNECTION";
const FETCH_INVITED = "FETCH_INVITED";
const FETCH_SELECTED_CONNECTION = "FETCH_SELECTED_CONNECTION";
const CLEAR_FIND_CONNECTIONS = "CLEAR_FIND_CONNECTIONS";
const CLEAR_SEARCH_CONNECTIONS = "CLEAR_SEARCH_CONNECTIONS";
const SEARCH_FIND_IMPORT = "SEARCH_FIND_IMPORT";
const ALREADY_ON_WIKIREVIEWS = "ALREADY_ON_WIKIREVIEWS";
const FORCEFULLY_ENABLE_TAB = "FORCEFULLY_ENABLE_TAB";
const FACEBOOK_STATUS = "FACEBOOK_STATUS";
const TIWITTER_STATUS = "TIWITTER_STATUS";
const SEARCH_FRIENDS = "SEARCH_FRIENDS";
const PROVIDERS_ON_WIKIREVIEWS = "PROVIDERS_ON_WIKIREVIEWS";
const FETCH_FOLLOWER_COUNT = 'FETCH_FOLLOWER_COUNT';
const FETCH_FOLLOWER_REQUEST = "FETCH_FOLLOWER_REQUEST";

const common_fetch = ({ url, method, data, dispatch_function }) => {
  if (data === undefined) {
    data = {};
  }
  return (dispatch) => {
    callApiAdvance({ endpoint: url, method, body: data, loader: false }).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(dispatch_function(response));
      }
    });
  };
};

// FETCH_PENDING_REQUESTS_COUNT
export const pending_dispatch = (data) => ({
  type: FETCH_PENDING_REQUESTS_COUNT,
  payload: data,
});
export const pending_requests_dispatch = (data) => ({
  type: FETCH_PENDING_REQUESTS,
  payload: data,
});
export const fetch_pending_req_count = (...data) => {
  // params
  // count / page
  return common_fetch({
    url: getQueryString({ url: `/api/pendingrequests/`, data }),
    method: "GET",
    dispatch_function: checkIfHaveParams({ data, param: "count" })
      ? pending_dispatch
      : pending_requests_dispatch,
  });
};

// FETCH_SENT_FRIEND_REQUEST_COUNT
export const sent_friend_req_count_dispatch = (data) => ({
  type: FETCH_SENT_FRIEND_REQUEST_COUNT,
  payload: data,
});
export const sent_friend_req_dispatch = (data) => ({
  type: FETCH_SENT_FRIEND_REQUEST,
  payload: data,
});
export const fetch_sent_friend_req_count = (...data) => {
  // params
  //  - sentfriendrequest/?count=true`
  //  - sentfriendrequest/?page=1
  return common_fetch({
    url: getQueryString({ url: `/api/sentfriendrequest/`, data }),
    method: "GET",
    dispatch_function: checkIfHaveParams({ data, param: "count" })
      ? sent_friend_req_count_dispatch
      : sent_friend_req_dispatch,
  });
};

export const follower_count_dispatch = (data) => ({
  type: FETCH_FOLLOWER_COUNT,
  payload: data,
});

export const follower_dispatch = (data) => ({
  type: FETCH_FOLLOWER_REQUEST,
  payload: data,
});
export const fetch_follower_invited = (...data) => {
  return common_fetch({
    url: getQueryString({ url: `/api/getinvited/`, data }),
    method: "GET",
    dispatch_function: checkIfHaveParams({ data, param: "count" })
      ? follower_count_dispatch
      : follower_dispatch,
  });
};

// FETCH_FOLLOWING_COUNT
export const following_count_dispatch = (data) => ({
  type: FETCH_FOLLOWING_COUNT,
  payload: data,
});
export const following_dispatch = (data) => ({
  type: FETCH_FOLLOWING,
  payload: data,
});
export const fetch_following_count = (...data) => {
  // params
  // ?count=true
  return common_fetch({
    url: getQueryString({ url: `/api/following/`, data }),
    method: "GET",
    dispatch_function: checkIfHaveParams({ data, param: "count" })
      ? following_count_dispatch
      : following_dispatch,
  });
};

// FETCH_IMPORT_COUNT
export const import_count_dispatch = (data) => ({
  type: FETCH_IMPORT_COUNT,
  payload: data,
});
export const fetch_import_count = () => {
  return common_fetch({
    url: `/api/getimportcount/`,
    method: "GET",
    dispatch_function: import_count_dispatch,
  });
};

export const is_loading_connections_dispatch = (data) => ({
  type: IS_LOADING_CONNECTIONS,
  payload: data,
});

// FETCH_CONNECTIONS
export const connections_dispatch = (data) => ({
  type: FETCH_CONNECTIONS,
  payload: data,
});
export const fetch_connections = (text, search, userId, page=1) => {
  // https://wikireviews.com/api/connections/?page=1&q=jayne
  let url = `/api/connections/?page=${page}`
  if (search)
    url = `/api/connections/?page=${page}&q=${text}`
  if (userId)
    url = `/api/connections/?page=${page}&user=${userId}`

  let method = "GET";
  return (dispatch, getState) => {
    dispatch(is_loading_connections_dispatch(true));
    callApi(url, method).then((response) => {
    dispatch(is_loading_connections_dispatch(false));
      if (response && response.code !== 400 && response.code !== 500) {
        let state = getState();
        if (state?.user?.my_profile) {
          let my_profile = state?.user?.my_profile;
          if (response?.results?.length) {
            let filteredData = response.results.map((item) => {
              if (item.connection.id === my_profile.id) {
                let profile = item.profile;
                item["connection"] = profile;
              }
              return item;
            });
            response.results = filteredData;
          }
        }
        dispatch(connections_dispatch(response));
      }
    });
  };
};

// FETCH_FIND_CONNECTION
export const find_connection_dispatch = (data) => ({
  type: FETCH_FIND_CONNECTION,
  payload: data,
});
export const find_selected_connection_dispatch = (data) => ({
  type: FETCH_SELECTED_CONNECTION,
  payload: data,
});
export const fetch_find_connection = (...data) => {
  // ?searchID=1504&sensor=false&type=getconnection
  let dataItems = data[0];
  let url = getQueryString({ url: `/api/findconnection/`, data });
  let method = "GET";
  return (dispatch) => {
    callApi(url, method).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        if (checkIfHaveParams({ data, param: "search" })) {
          response["search"] = dataItems.search;
          dispatch(find_connection_dispatch(response));
        }

        if (checkIfHaveParams({ data, param: "searchID" })) {
          dispatch(find_selected_connection_dispatch(response));
        }
      }
    });
  };
};


export const is_loading_fetch_invited_dispatch = (data) => ({
  type: IS_LOADING_FETCH_INVITED,
  payload: data,
});

// GET_INVITED
export const invited_dispatch = (data) => ({
  type: FETCH_INVITED,
  payload: data,
});
export const fetch_invited = (...data) => {
  // params
  // ?page=1
  return common_fetch({
    url: getQueryString({ url: `/api/getinvited/`, data }),
    method: "GET",
    dispatch_function: invited_dispatch,
  });
};

export const clear_fetch_invited = () => {
  return (dispatch) => {
    dispatch(invited_dispatch([]));
  };
};

export const clear_search_data = () => {
  return (dispatch) => {
    dispatch(find_import_dispatch([]))
    // dispatch(fetch_connections());
    dispatch(clear_fetch_invited());
    dispatch({ type: CLEAR_FIND_CONNECTIONS });
    dispatch({ type: CLEAR_SEARCH_CONNECTIONS });
  };
};

export const unfriend_user = ({ id, friend }) => {
  return (dispatch) => {
    callApi(`/api/removefriend/?id=${id}&friend=${friend}`, "DELETE").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500 && response?.result) {
          toast("Connection unfriend successfully!", {
            autoClose: 3500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
          dispatch(fetch_pending_req_count({ count: true }));
          dispatch(fetch_pending_req_count({ page: 1 }));
          dispatch(fetch_sent_friend_req_count({ count: true }));
          dispatch(fetch_sent_friend_req_count({ page: 1 }));
          dispatch(fetch_following_count({ count: true }));
          dispatch(fetch_import_count());
          dispatch(fetch_connections());
        }
        else{
          toast("Something went wrong!", {
            autoClose: 3500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }
      }
    );
  };
};

export const block_user = ({ id }) => {
  return (dispatch) => {
    callApi(`/api/connectionrequests/${id}/`, "PUT").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(fetch_pending_req_count({ count: true }));
        dispatch(fetch_pending_req_count({ page: 1 }));
        dispatch(fetch_sent_friend_req_count({ count: true }));
        dispatch(fetch_sent_friend_req_count({ page: 1 }));
        dispatch(fetch_following_count({ count: true }));
        dispatch(fetch_import_count());
        dispatch(fetch_connections());
        toast("Connection blocked successfully!", {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    });
  };
};

export const send_again_request = (data) => {
  let text = 'Your friend request has been sent';
  return (dispatch) => {
    callApi(`/api/connectionrequest/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(fetch_pending_req_count({ count: true }));
        dispatch(fetch_pending_req_count({ page: 1 }));
        dispatch(fetch_sent_friend_req_count({ count: true }));
        dispatch(fetch_sent_friend_req_count({ page: 1 }));
        dispatch(fetch_following_count({ count: true }));
        dispatch(fetch_import_count());
        dispatch(fetch_connections());
        // toast.success("request sent!");
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    });
  };
};

export const send_import_request = (data) => {
  let text = 'Invitation send successfully';
  return (dispatch) => {
    callApi(`/invitefriendsemail/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(fetch_pending_req_count({ count: true }));
        dispatch(fetch_pending_req_count({ page: 1 }));
        dispatch(fetch_sent_friend_req_count({ count: true }));
        dispatch(fetch_sent_friend_req_count({ page: 1 }));
        dispatch(fetch_following_count({ count: true }));
        dispatch(fetch_import_count());
        dispatch(fetch_connections());
        // toast.success("request sent!");
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    });
  };
};


export const remove_request = ({ id }) => {
  let text = 'Friend Request Removed';
  return (dispatch) => {
    callApi(`/api/connectionrequests/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(fetch_pending_req_count({ count: true }));
        dispatch(fetch_pending_req_count({ page: 1 }));
        dispatch(fetch_sent_friend_req_count({ count: true }));
        dispatch(fetch_sent_friend_req_count({ page: 1 }));
        dispatch(fetch_following_count({ count: true }));
        dispatch(fetch_import_count());
        dispatch(fetch_connections());
        // toast.success("request removed!");
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    });
  };
};

export const find_import_dispatch = (data) => ({
  type: SEARCH_FIND_IMPORT,
  payload: data,
});
export const search_find_friend_import_connections = (...data) => {
  return (dispatch) => {
    callApi(
      getQueryString({ url: `/api/findimportcontacts/`, data }),
      "GET"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        // toast.success('request removed!');
        dispatch(find_import_dispatch(response));
      }
    });
  };
};

export const dispatch_alerady_on_wikireview = (data) => ({
  type: ALREADY_ON_WIKIREVIEWS,
  payload: data,
});
export const already_on_wikireviews = ({ page }) => {
  return (dispatch) => {
    callApi(`/api/getnotconnectedfriends/?page=${page}`, "GET").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          dispatch(dispatch_alerady_on_wikireview(response));
        }
      }
    );
  };
};

export const dispatch_providers_on_wikireview = (data) => ({
  type: PROVIDERS_ON_WIKIREVIEWS,
  payload: data,
});
export const get_providers_on_wikireviews = () => {
  return (dispatch) => {
    callApi(`/api/getnotconnectedfriends/?provider=true&home=false`, "GET").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          dispatch(dispatch_providers_on_wikireview(response));
        }
      }
    );
  };
};



export const fetch_search_friends_ = (data) => ({ type: SEARCH_FRIENDS, payload: data });
export const fetch_search_friends = (url) => {
  if (url != "") {
    return (dispatch) => {
      callApi(`${url}`, 'GET')
        .then((response) => {
          if (response && response.code !== 400 && response.code !== 500) {
            try {
              return dispatch(fetch_search_friends_(response));
            } catch (e) {
              return dispatch(fetch_search_friends_([]));
            }
          }
        })
    }
  }
}

export const add_connection_requests = (data, url, search, friendsType) => {
  return (dispatch) => {
    callApi(`/api/connectionrequestfriend/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = 'Friend request sent';
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      if (search) {
        return dispatch(fetch_search_friends(url));
      } else if (!search && friendsType == "already") {
        return dispatch(already_on_wikireviews({ page: 1 }));
      } else if (!search && friendsType == "invite") {
        return dispatch(fetch_invited({ page: 1, invited: true }));
      }else{
        return dispatch(people_profile(data.username));
      }
    });
  };
};


export const send_connection_requests = (data, url, search, friendsType) => {
  return (dispatch) => {
    callApi(`/api/connectionrequest/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = 'Friend request sent';
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    
        return dispatch(people_profile(data.username));
      
    });
  };
};




export const cancel_request = (id,username, url) => {
  return (dispatch) => {
    callApi(`/api/connectionrequests/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(people_profile(username));
      }
     
    });
  };
};


export const follow = (data, url) => {
  return (dispatch) => {
    callApi(`/api/following/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
  
        return dispatch(people_profile(data.username));
      }
    });
  };
};



export const unfollow = (data, url) => {
  return (dispatch) => {
    callApi(`/api/removefollower/?id=${data.id}&self=true`, "DELETE", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
  
        return dispatch(people_profile(data.username));
      }
    });
  };
};

export const send_invitation_requests = (emails) => {
  return (dispatch) => {
    callApi(`/api/sendinvitation/?email=${emails}&multiple=true`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = 'Invitation Sent';
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(fetch_invited({ page: 1, invited: true }));
    });
  };
};

export const send_single_invitation_request = (email) => {
  return (dispatch) => {
    callApi(`/api/sendinvitation/?email=${email}&multiple=false`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = 'Invitation Sent';
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(fetch_invited({ page: 1 }));
    });
  };
};

// CHANGE FACEBOOK STATUS
export const updateFacebook = (data) => ({
  type: FACEBOOK_STATUS,
  payload: data,
});

// CHANGE TIWITTER STATUS
export const updateTiwitter = (data) => ({
  type: TIWITTER_STATUS,
  payload: data,
});

export const enable_fiend_friends_tab = ({ status, param }) => (dispatch) =>
  dispatch({ type: FORCEFULLY_ENABLE_TAB, payload: { status, param } });

// api/pendingrequests/?count=true
// api/sentfriendrequest/?count=true
// api/following/?count=true
// /api/getimportcount/
// api/connections/?page=1
// api/findconnection/?search=add&sensor=false&type=search
// getinvited/?page=1

// findconnection/

// Request URL: https://wikireviews.com/api/removefriend/?id=1998&friend=84
// Request Method: DELETE

// Request URL: https://wikireviews.com/api/connectionrequests/1999/
// Request Method: PUT

// Request URL: https://wikireviews.com/api/connectionrequest/
// Request Method: POST
// Status Code: 200 OK

// email: "waglen@mchsi.com"
// vanity_url: "NikhilWagle"

// Request URL: https://wikireviews.com/api/connectionrequests/2162/
// Request Method: DELETE

// Request URL: https://wikireviews.com/api/findimportcontacts/?search=a&sensor=false&type=search

// Request URL: https://wikireviews.com/api/findimportcontacts/?search=a&on_wiki=true
// Request Method: GET (find friend)

/* Google-
wikireviewsinc@gmail.com
JayneSWagle123

Facebook-
swagle@greenculture.com
DramaSammy123
New Password- Mehta321

Yahoo:
wikireviewsinc@yahoo.com
Password:- Bentley123

Hotmail:
wikireviews@hotmail.com
Password:- Bentley1234



 */
