/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Col, Container,
  Nav,
  NavItem,
  NavLink, Row,
  TabContent,
  TabPane
} from "reactstrap";
import {
  clear_dispatch_taxonomy, fetch_category_results, fetch_collage_items_city, fetch_collage_items_national,
  fetch_collage_items_projects,
  fetch_trending_crp,
  fetch_actors_collage_items,
} from "../../../actions/home";
import { Link } from 'react-router-dom';
import Loader from "../../atoms/loader";
class CollageItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewTrendingType: "national",
      cityData: null,
      nationalData: null,
      projectsData: null,
      setTaxonomyCategory: null,
      taxonomy_crp: false,
      toggleBlueBanner: false,
      professionalUrl: "https://userdatawikireviews.s3.amazonaws.com/images/",
      loading: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.national?.results) {
      this.setState({ nationalData: nextProps.national });
    }

    if (nextProps?.city?.results) {
      this.setState({ cityData: nextProps.city });
    }

    if (nextProps?.projects?.results) {
      this.setState({ projectsData: nextProps.projects });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props?.content_type === 'products') {
      if (this.props.currentLocaiton !== prevProps.currentLocaiton) {
        if (this.props.temporaryLocaiton === this.props.temporaryLocaiton) {
          this.props.fetch_collage_items_national({
            trending: "national",
            content_type: this.props?.content_type,
            listingtaxonomy: "",
          }).then(() => this.setState({ loading: false }));
        }
      }
    }
    else if (this.props?.content_type === 'actors') {
      if (this.props.currentLocaiton !== prevProps.currentLocaiton) {
        if (this.props.temporaryLocaiton === this.props.temporaryLocaiton) {
          this.props.fetch_actors_collage_items({
            content_type: "actors",
          }).then(() => this.setState({ loading: false }));
        }
      }
    }
    else if (this.props?.content_type === 'professionals') {
      if (this.props.currentLocaiton !== prevProps.currentLocaiton) {
        if (this.props.temporaryLocaiton === this.props.temporaryLocaiton) {
          this.props.fetch_actors_collage_items({
            content_type: '',
          }).then(() => this.setState({ loading: false }));
        }
      }
    }
    else {
      if (this.props.currentLocaiton !== prevProps.currentLocaiton) {
        if (this.props.temporaryLocaiton === this.props.temporaryLocaiton) {
          this.fetchAndSetItems(this.state.viewTrendingType);
        }
      }
    }


  }

  componentDidMount() {
    if (this.state.nationalData == null) {
      this.fetchAndSetItems("national");
    }
    document.querySelectorAll("img").forEach((img) => {
      img.onerror = function () {
        this.style.display = "none";
      };
    });
  }

  fetchAndSetItems = (item) => {
    let { cityData, projectsData, nationalData } = this.state;
    this.setState({ viewTrendingType: item, taxonomy_crp: false }, () => {
      this.props.clear_dispatch_taxonomy();
      if (item == "city") {
        this.props.fetch_collage_items_city({ type: "City" }).then(()=>this.setState({loading:false}));
      }

      if (item == "national") {
        if (this.props?.content_type === 'products') {
          this.props.fetch_collage_items_national({
            trending: "national",
            content_type: this.props?.content_type,
            listingtaxonomy: "",
          }).then(()=>this.setState({loading:false}));
        }
        else if (this.props?.content_type === 'actors') {
          this.props.fetch_actors_collage_items({
            content_type: "actors",
          }).then(()=>this.setState({loading:false}));
        }
        else if (this.props?.content_type === 'professionals') {
          this.props.fetch_actors_collage_items({
            content_type: "",
          }).then(()=>this.setState({loading:false}));
        }
        else {
          this.props.fetch_collage_items_national({
            trending: "national",
            content_type: "",
            listingtaxonomy: "",
          }).then(()=>this.setState({loading:false}));

        }
      }
      
      if (item == "crp"){
        this.props.fetch_collage_items_projects({
          image__isnull: "False",
          is_active: "True",
          is_draft: "False",
        }).then(()=>this.setState({loading:false}));
      }
    });
  };

  renderNavItems = ({ title, active }) => {
    return (
        <NavItem className="mw-100">
          <NavLink
              className="text-truncate h-100 "
              href="#"
              active={this.state.viewTrendingType === active}
              onClick={() => {
                this.fetchAndSetItems(active);
              }}
          >
            <span className="font-weight-bold text-uppercase">{title}</span>
          </NavLink>
        </NavItem>
    );
  };

  renderOneColum = ({ item, firstTime }) => {
    let { taxonomy } = this.props;
    let { taxonomy_crp } = this.state;

    let cat_name = item?.content_type
    if (Number.isInteger(cat_name)) {
      cat_name = item?.content_type_name;
    }
    else {
      cat_name = cat_name
      cat_name = cat_name?.toLowerCase();
    }

    if (cat_name === 'movies') {
      cat_name = 'movie'
    }


    let image_bg = "";
    if (item?.image) {
      image_bg = item?.image;
    }

    if (
        item?.listing_profileimage &&
        item?.listing_profileimage[0] &&
        item?.listing_profileimage[0]?.type == "image"
    ) {
      image_bg = item.listing_profileimage[0].location;
    } else {
      let listingProfile = item.listing_profileimage;
      if (listingProfile && Array.isArray(listingProfile) && listingProfile?.length > 1) {
        let filterItem = listingProfile.find(item => item.type == "image");
        if (filterItem) {
          image_bg = filterItem.location;
        }
      }
    }

    if (
        item?.professional_profileimage &&
        item?.professional_profileimage[0] &&
        item?.professional_profileimage[0]?.type == "image"
    ) {
      image_bg = item.professional_profileimage[0].location;
    } else {
      let listingProfile = item.professional_profileimage;
      if (listingProfile && Array.isArray(listingProfile) && listingProfile?.length > 1) {
        let filterItem = listingProfile.find(item => item.type == "image");
        if (filterItem) {
          image_bg = filterItem.location;
        }
      }
    }

    let stringReview = "";
    if (item?.latestreview?.review) {
      stringReview = item?.latestreview?.review;
      stringReview = stringReview.substring(3, stringReview.length - 4);
    }
    let imageOne = "";
    let imageTwo = "";
    if (item?.content_type_name == "accountants") {
      imageOne = `${this.state.professionalUrl}accountants_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}accountants_neutral_blue.jpg`;;
    } else if (item?.content_type_name == "dentists") {
      imageOne = `${this.state.professionalUrl}dentists_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}dentists_neutral_blue.jpg`;
    } else if (item?.content_type_name == "doctors") {
      imageOne = `${this.state.professionalUrl}doctors_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}doctors_neutral_blue.jpg`;
    } else if (item?.content_type_name == "actors") {
      imageOne = `${this.state.professionalUrl}actors_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}actors_neutral_blue.jpg`;
    } else if (item?.content_type_name == "lawyers") {
      imageOne = `${this.state.professionalUrl}lawyers_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}lawyers_neutral_blue.jpg`;
    }
    return (
        <Col className="d-flex flex-column">
          <div
              onClick={(e) => {
                e.stopPropagation();
                this.navigateToCategoryItem(item);
              }}
              className="flex-grow-1 featured-item"
              style={{ overflow: "hidden" }}>
            <img className="feature-project-image _full" src={image_bg && image_bg != "" ? image_bg : firstTime == "Yes" ? imageTwo : imageOne} alt={item?.name || item?.title} />
            {taxonomy == null || taxonomy_crp == true ? (
                <div className="featured-details minh-50">
                  <h2 className="featured-item-title">
                    {item?.name || item?.title}
                  </h2>
                  {this.state.viewTrendingType == "crp" && (
                      <div className="featured-item-credit">
                        {item.user_full_name && item.user_full_name != "" ? <> by <span>{item.user_full_name}</span> </> : null}
                      </div>
                  )}
                  { item?.total_rating?.avg_rating[0] === undefined && item?.average_rating[0] === undefined ?
                      ''
                      :
                      <img
                          title={`${item?.total_rating?.avg_rating[1] || item?.average_rating[1]
                          }`}

                          src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0] || item?.average_rating[0]
                          }`)}
                          alt=""
                      />
                  }
                </div>
            ) : null}
            {taxonomy && taxonomy_crp == false ? (
                <div className="featured-details hoverable">
                  <a href="/abc" className="text-reset mb-2 d-block text-decoration-none">
                    <h2 className="fs-25 text-uppercase"> {item?.name || item?.title}</h2>
                  </a>
                  <div className="mt-2">
                    <img
                        title={`${item?.total_rating?.avg_rating[1] || item?.average_rating[1]
                        }`}
                        src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0] || item?.average_rating[0]
                        }`)}
                        alt=""
                    />
                  </div>

                  <div className="fs-13 hover-appear mt-2">
                    <div>
                      <i className="icon-quote-left"></i>
                      {stringReview && `${stringReview.substring(0, 55)} ....`}
                      <i className="icon-quote-right"></i>
                    </div>

                    <div className="text-right">
                  <span className="d-inline-block mr-2">
                    {item?.latestreview?.created_by}
                  </span>
                      <img
                          title={item?.total_rating?.avg_rating[1]}
                          src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0]}`)}
                          alt={item?.total_rating?.avg_rating[1]}
                      />
                    </div>
                  </div>
                </div>
            ) : taxonomy_crp ? null : (
            this.props?.content_type === 'professionals' ?
              <div className="floating-item">
                <div>
                  <h2 className="item-title">
                    <span>
                      {item?.name}
                    </span>
                  </h2>
                  <div>
                    <span className="item-category">
                        {item?.taxonomy_list_updated
                          ? item?.taxonomy_list_updated
                          : (item?.taxonomy_list && Array.isArray(item.taxonomy_list) && item.taxonomy_list.length > 0) ?
                            item.taxonomy_list[0]
                            :
                            ''
                        }
                    </span>
                  </div>
                  <a className="item-link">
                    See What's Trending Now
                  </a>
                </div>
              </div>
              :
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.fetchNewResults({
                    item,
                    category:
                      item?.taxonomy_list && item?.taxonomy_list[0]
                        ? item?.taxonomy_list[0]?.category
                        : "Projects",
                  });
                }}
                className="floating-item"
              >
                <Link
                  to={{
                    pathname: `/${cat_name}/${item?.id}`,
                    state: { id: item?.id }
                  }}>
                  <h2 className="item-title">
                    <span>
                      {item?.content_type || item?.taxonomy_name}
                    </span>
                  </h2>
                  <div>
                    <span className="item-category">
                      {item?.taxonomy_list && item?.taxonomy_list[0]
                        ? item?.taxonomy_list[0]?.category
                        : "Projects"}
                    </span>
                  </div>
                  <a className="item-link" href="#">
                    See What's Trending Now
                  </a>
                </Link>
              </div>
          )}
          </div>
        </Col>
    );
  };

  fetchNewResults = ({ item, category }) => {
    // fetch new taxonomy
    let { viewTrendingType } = this.state;
    this.setState({ setTaxonomyCategory: category }, () => {
      if (item.content_type) {
        this.setState({ taxonomy_crp: false, toggleBlueBanner: true }, () => {
          this.props.fetch_category_results({
            content_type: item.content_type.toLowerCase(),
            catTrending: "True",
            subcat: item.taxonomy_list[0].id,
            trendingType: viewTrendingType,
            size: 6,
          }).then(()=>this.setState({loading:false}));
        });
      }
      if (item.taxonomy) {
        this.setState({ taxonomy_crp: true }, () => {
          this.props.fetch_trending_crp({
            id: item.id,
            category: item.taxonomy,
          }).then(()=>this.setState({loading:false}));
        });
      }
    });
  };

  renderTwoColum = ({ item, item2, firstTime }) => {
    let { taxonomy } = this.props;
    let { taxonomy_crp, viewTrendingType } = this.state;
    let image_bg = "";
    let image_bg_2 = "";

    let cat_name1 = item?.content_type;
    if (Number.isInteger(cat_name1)) {
      cat_name1 = item?.content_type_name;
    }
    else {
      cat_name1 = cat_name1
      cat_name1 = cat_name1?.toLowerCase();
    }

    if (cat_name1 === 'movies') {
      cat_name1 = 'movie'
    }

    let cat_name2 = item2?.content_type || item2?.content_type_name;
    if (Number.isInteger(cat_name2)) {
      cat_name2 = item2?.content_type_name;
    }
    else {
      cat_name2 = cat_name2
      cat_name2 = cat_name2?.toLowerCase();
    }

    if (cat_name2 === 'movies') {
      cat_name2 = 'movie'
    }

    let imageOne = "";
    let imageTwo = "";
    if (item2?.content_type_name == "accountants") {
      imageOne = `${this.state.professionalUrl}accountants_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}accountants_neutral_blue.jpg`;;
    } else if (item2?.content_type_name == "dentists") {
      imageOne = `${this.state.professionalUrl}dentists_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}dentists_neutral_blue.jpg`;
    } else if (item2?.content_type_name == "doctors") {
      imageOne = `${this.state.professionalUrl}doctors_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}doctors_neutral_blue.jpg`;
    } else if (item2?.content_type_name == "actors") {
      imageOne = `${this.state.professionalUrl}actors_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}actors_neutral_blue.jpg`;
    } else if (item2?.content_type_name == "lawyers") {
      imageOne = `${this.state.professionalUrl}lawyers_neutral_turquoise.jpg`;
      imageTwo = `${this.state.professionalUrl}lawyers_neutral_blue.jpg`;
    }

    if (item?.image) image_bg = item?.image;
    if (item?.listing_profileimage && Array.isArray(item?.listing_profileimage) && item?.listing_profileimage?.length) {
      let filterItem = item.listing_profileimage.find(item => item.type == "image");
      if (filterItem) {
        image_bg = filterItem.location;
      }
    }

    if (item?.professional_profileimage && Array.isArray(item?.professional_profileimage) && item?.professional_profileimage?.length) {
      let filterItem = item.professional_profileimage.find(item => item.type == "image");
      if (filterItem) {
        image_bg = filterItem.location;
      }
    }

    if (item2?.image) image_bg_2 = item2?.image;
    if (item2?.listing_profileimage && Array.isArray(item2?.listing_profileimage) && item2?.listing_profileimage?.length) {
      let filterItem = item2.listing_profileimage.find(item => item.type == "image");
      if (filterItem) {
        image_bg_2 = filterItem.location;
      }
    }

    if (item2?.professional_profileimage && Array.isArray(item2?.professional_profileimage) && item2?.professional_profileimage?.length) {
      let filterItem = item2.professional_profileimage.find(item => item.type == "image");
      if (filterItem) {
        image_bg_2 = filterItem.location;
      }
    }


    let stringReview = "";
    if (item?.latestreview?.review) {
      stringReview = item?.latestreview?.review;
      stringReview = stringReview.substring(3, stringReview.length - 4);
    }

    let stringReview2 = "";
    if (item2?.latestreview?.review) {
      stringReview2 = item2?.latestreview?.review;
      stringReview2 = stringReview2.substring(3, stringReview2.length - 4);
    }

    return (
        <Col className="d-flex flex-column">
          <div
              onClick={(e) => {
                e.stopPropagation();
                this.navigateToCategoryItem(item);
              }}
              className="flex-grow-1 featured-item"
          >

            <img
                className="feature-project-image _half"
                src={viewTrendingType == "crp" ? item?.image : image_bg && image_bg != "" ? image_bg : firstTime == "Yes" ? imageOne : imageTwo}
                alt={item?.name || item?.title}
            />
            {(taxonomy == null || taxonomy_crp) && (
                <div className="featured-details">
                  <h2 className="featured-item-title">
                    {item?.name || item?.title}
                  </h2>
                  {this.state.viewTrendingType == "crp" && (
                      <div className="featured-item-credit">
                        {item.user_full_name && item.user_full_name != "" ? <> by <span>{item.user_full_name}</span> </> : null}
                      </div>
                  )}
                  <img
                      title={`${item?.total_rating?.avg_rating[1] || item?.average_rating[1]
                      }`}
                      src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0] || item?.average_rating[0]}`)}
                      alt=""
                  />
                </div>
            )}
            {taxonomy && taxonomy_crp == false ? (
                <div className="featured-details hoverable">
                  <a href="#" className="text-reset mb-2 d-block text-decoration-none">
                    <h2 className="fs-25 text-uppercase"> {item.name || item.title}</h2>
                  </a>
                  <div className="mt-2">
                    <img
                        title={`${item?.total_rating?.avg_rating[1] || item?.average_rating[1]
                        }`}
                        src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0] || item?.average_rating[0]
                        }`)}
                        alt=""
                    />
                  </div>

                  <div className="fs-13 hover-appear mt-2">
                    <div>
                      <i className="icon-quote-left"></i>
                      {stringReview && `${stringReview.substring(0, 55)} ....`}
                      <i className="icon-quote-right"></i>
                    </div>

                    <div className="text-right">
                  <span className="d-inline-block mr-2">
                    {item?.latestreview?.created_by}
                  </span>
                      <img
                          title={item?.total_rating.avg_rating[1]}
                          src={require(`../../../assets/images/icons/star/white/${item?.total_rating?.avg_rating[0]}`)}
                          alt={item?.total_rating.avg_rating[1]}
                      />
                    </div>
                  </div>
                </div>
            ) : taxonomy_crp ? null : (
              this.props?.content_type === 'professionals' ?
                <div className="floating-item">
                  <div>
                    <h2 className="item-title">
                      <span>
                        {item?.name}
                      </span>
                    </h2>
                    <div>
                      <span className="item-category">
                        {item?.taxonomy_list_updated
                          ? item?.taxonomy_list_updated
                          : (item?.taxonomy_list && Array.isArray(item.taxonomy_list) && item.taxonomy_list.length > 0) ?
                            item.taxonomy_list[0]
                            :
                            ''
                        }
                      </span>
                    </div>
                    <a className="item-link">
                      See What's Trending Now
                    </a>
                  </div>
                </div>
                :
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    this.fetchNewResults({
                      item,
                      category:
                        item?.taxonomy_list && item?.taxonomy_list[0]
                          ? item?.taxonomy_list[0]?.category
                          : "Projects",
                    });
                  }}
                  className="floating-item"
                >
                  <Link
                    to={{
                      pathname: `/${cat_name1}/${item?.id}`,
                      state: { id: item?.id }
                    }}>
                    <h2 className="item-title">
                      <span>
                        {item?.content_type || item?.taxonomy_name}
                      </span>
                    </h2>
                    <div>
                      <span className="item-category">
                        {item?.taxonomy_list && item?.taxonomy_list[0]
                          ? item?.taxonomy_list[0]?.category
                          : "Projects"}
                      </span>
                    </div>
                    <a className="item-link" href="#">
                      See What's Trending Now
                    </a>
                  </Link>
                </div>
            ) }
          </div>
          <div
              onClick={(e) => {
                e.stopPropagation();
                this.navigateToCategoryItem(item2);
              }}
              className="flex-grow-1 featured-item"
          >

            <img
                className="feature-project-image _half"
                src={viewTrendingType == "crp" ? item2?.image : image_bg_2 && image_bg_2 != "" ? image_bg_2 : firstTime == "Yes" ? imageTwo : imageOne}
                alt={item2?.name || item2?.title}
            />
            {taxonomy == null && (
                <div className="featured-details">
                  <h2 className="featured-item-title">
                    {item2?.name || item2?.title}
                  </h2>
                  {this.state.viewTrendingType == "crp" && (
                      <div className="featured-item-credit">
                        by <span>{item2?.user_full_name}</span>
                      </div>
                  )}
                  <img
                      title={`${(item2?.total_rating?.avg_rating[1] || item2?.average_rating[1])}`}
                      src={require(`../../../assets/images/icons/star/white/${item2?.total_rating?.avg_rating[0] || item2?.average_rating[0]}`)}
                      alt="" />
                </div>
            )}
            {taxonomy && taxonomy_crp == false ? (
                <div className="featured-details hoverable">
                  <a href="#" className="text-reset mb-2 d-block text-decoration-none">

                    <h2 className="fs-25 text-uppercase">{item2?.name || item2?.title}</h2>
                  </a>
                  <div className="mt-2">
                    <img
                        title={`${item2?.total_rating?.avg_rating[1] ||
                        item2?.average_rating[1]
                        }`}
                        src={require(`../../../assets/images/icons/star/white/${item2?.total_rating?.avg_rating[0] ||
                        item2?.average_rating[0]
                        }`)}
                        alt=""
                    />
                  </div>

                  <div className="fs-13 hover-appear mt-2">
                    <div>
                      <i className="icon-quote-left"></i>
                      {stringReview2 && `${stringReview2.substring(0, 55)} ....`}
                      <i className="icon-quote-right"></i>
                    </div>

                    <div className="text-right">
                  <span className="d-inline-block mr-2">
                    {item2?.latestreview?.created_by}
                  </span>
                      <img
                          title={item2?.total_rating.avg_rating[1]}
                          src={require(`../../../assets/images/icons/star/white/${item2?.total_rating?.avg_rating[0]}`)}
                          alt={item2?.total_rating.avg_rating[1]}
                      />
                    </div>
                  </div>

                </div>
          ) : taxonomy_crp ? null : (
            this.props?.content_type === 'professionals' ?
              <div className="floating-item">
                <div>
                  <h2 className="item-title">
                    <span>
                      {item2?.name}
                    </span>
                  </h2>
                  <div>
                    <span className="item-category">
                      {item2?.taxonomy_list_updated
                        ? item2?.taxonomy_list_updated
                        : (item2?.taxonomy_list && Array.isArray(item2.taxonomy_list) && item2.taxonomy_list.length > 0) ?
                          item2.taxonomy_list[0]
                          :
                          ''
                      }
                    </span>
                  </div>
                  <a className="item-link">
                    See What's Trending Now
                  </a>
                </div>
              </div>
              :
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.fetchNewResults({
                    item,
                    category:
                      item2?.taxonomy_list && item2?.taxonomy_list[0]
                        ? item2?.taxonomy_list[0]?.category
                        : "Projects",
                  });
                }}
                className="floating-item"
              >
                <Link
                  to={{
                    pathname: `/${cat_name2}/${item2?.id}`,
                    state: { id: item2?.id }
                  }}>
                  <h2 className="item-title">
                    <span>
                      {item2?.content_type || item2?.taxonomy_name}
                    </span>
                  </h2>
                  <div>
                    <span className="item-category">
                      {item2?.taxonomy_list && item2?.taxonomy_list[0]
                        ? item2?.taxonomy_list[0]?.category
                        : "Projects"}
                    </span>
                  </div>
                  <a className="item-link" href="#">
                    See What's Trending Now
                  </a>
                </Link>
              </div>
          )}
        </div>
        </Col>
    );
  };

  renderCompleteRowItems = (taxonomy) => {
    if (taxonomy) {
      let itemSet = taxonomy?.results;
      if (itemSet && Array.isArray(itemSet) && itemSet.length) {
        return (
            <Row noGutters xs={1} md={4}>              
              {itemSet?.[0] && this.renderOneColum({ item: itemSet[0], firstTime: "Yes" })}
              {itemSet?.[1] && itemSet?.[2] && this.renderTwoColum({ item: itemSet[1], item2: itemSet[2], firstTime: "Yes" })}
              {itemSet?.[3] && this.renderOneColum({ item: itemSet[3], firstTime: "No" })}
              {itemSet?.[4] && itemSet?.[5] && this.renderTwoColum({ item: itemSet[4], item2: itemSet[5], firstTime: "No" })}
            </Row>
        );
      }
    } else {
      let itemSet = null;
      let { cityData, projectsData, nationalData, viewTrendingType } = this.state;

      if (viewTrendingType == "crp") {
        itemSet = projectsData?.results;
      }
      if (viewTrendingType == "city") {
        itemSet = cityData?.results;
      }
      if (viewTrendingType == "national") {
        itemSet = nationalData?.results;
      }
      if (itemSet !== undefined) {
        return (
            <Row noGutters xs={1} md={4}>
              {itemSet?.[0] && this.renderOneColum({ item: itemSet[0], firstTime: "Yes" })}
              {itemSet?.[1] && itemSet?.[2] && this.renderTwoColum({ item: itemSet[1], item2: itemSet[2], firstTime: "Yes" })}
              {itemSet?.[3] && this.renderOneColum({ item: itemSet[3], firstTime: "No" })}
              {itemSet?.[4] && itemSet?.[5] && this.renderTwoColum({ item: itemSet[4], item2: itemSet[5], firstTime: "No" })}
            </Row>
        );
      }
    }
  };

  navigateToCategoryItem = (item) => {
    if (item?.content_type && typeof (item?.content_type) == "string") {
      this.props.history.push({
        pathname: (`/${item?.content_type}/${item?.slug ? item?.slug : item?.id}`).toLowerCase(),
        state: {
          id: item?.id,
          parent_category: item?.content_type
        },
      });
    }


    if (item?.content_type_name && typeof (item?.content_type) == "number") {
      this.props.history.push({
        pathname: (`/${item?.content_type_name}/${item?.slug ? item?.slug : item?.listing_id}`).toLowerCase(),
        state: { id: item?.listing_id },
      });
    }
  }

  render() {
    let {
      viewTrendingType,
      setTaxonomyCategory,
      taxonomy_crp,
      toggleBlueBanner,
      loading
    } = this.state;
    let { taxonomy } = this.props;

    return (
        <React.Fragment>
          {loading && <Loader />}
          {taxonomy !== null && !taxonomy_crp && toggleBlueBanner ? (
              <div className="trending-info-box fs-14 font-weight-bold _top-fix">
                <div className="d-flex mx-n2 align-items-center">
                  <div className="px-2">
                    <span className="ff-alt mr-2">What's Trending Now:</span>
                    <span className="ff-headings fs-30">{setTaxonomyCategory}</span>
                  </div>
                  <div className="px-2 ml-auto">
                <span
                    onClick={() => {
                      this.props.clear_dispatch_taxonomy();
                    }}
                    role="button"
                    className="text-reset mr-2 d-inline-block"
                >
                  <i>Back to main category view</i>
                </span>
                    <span
                        onClick={() => {
                          this.setState({ toggleBlueBanner: false });
                        }}
                        className="d-inline-block"
                        role="button"
                    >
                  <img
                      src={require("../../../assets/images/icons/close-icon.png")}
                      alt="Close"
                  ></img>
                </span>
                  </div>
                </div>
              </div>
          ) : (
              ""
          )}
          <div>
            <section className="feature-grid-section">
              <Container className="px-0" fluid={true}>
                <TabContent
                    activeTab={this.state.viewTrendingType}
                    className="bg-dark"
                    style={{ minHeight: "500px" }}
                >
                  <TabPane tabId={viewTrendingType}>
                    {!loading && this.renderCompleteRowItems(taxonomy)}
                  </TabPane>
                </TabContent>
              </Container>
              <Container>
                {this.props?.content_type === 'products' || this.props?.content_type === 'actors' ?
                    ''
                    :
                    <Nav
                        hidden={this.props?.hideNavLinkItems == true ? true : false}
                        className="featured position-relative navbarItem"
                        justified
                        pills
                        style={{ marginTop: "-54px", zIndex: 3 }}
                    >
                      <span className="nav-item d-flex align-items-center step-2">
                        {this.renderNavItems({
                          title: this.props.currentLocaiton || 'City',
                          active: "city",
                        })}
                        {this.renderNavItems({ title: "National", active: "national" })}
                      </span>
                      <span className="crp_nav_item">
                      {this.renderNavItems({
                        title: "Community Review Projects",
                        active: "crp",
                      })}
                      </span>
                    </Nav>
                }
              </Container>
            </section>
          </div>
        </React.Fragment>
    );
  }
}

const mapState = (state) => {
  return {
    my_profile_info: state.user.my_profile,
    national: state.home.national,
    city: state.home.city,
    projects: state.home.projects,
    taxonomy: state.home.taxonomy,
    currentLocaiton: state.home.current_location && state.home.current_location.city,
    temporaryLocaiton: state.home.add_temp_location && state.home.add_temp_location.city,
  };
};

const mapProps = (dispatch) => ({
  fetch_collage_items_national: (data) => dispatch(fetch_collage_items_national(data)),
  fetch_collage_items_city: (data) => dispatch(fetch_collage_items_city(data)),
  fetch_collage_items_projects: (data) => dispatch(fetch_collage_items_projects(data)),
  fetch_category_results: (data) => dispatch(fetch_category_results(data)),
  fetch_trending_crp: (data) => dispatch(fetch_trending_crp(data)),
  clear_dispatch_taxonomy: (data) => dispatch(clear_dispatch_taxonomy(data)),
  fetch_actors_collage_items: (data) => dispatch(fetch_actors_collage_items(data)),
});

export default withRouter(connect(mapState, mapProps)(CollageItems));
