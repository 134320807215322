/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import queryString from 'query-string';
import React, { Component, Fragment } from 'react';
import Moment from "react-moment";
import Carousel from 'react-multi-carousel';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import FancyVideo from 'react-videojs-fancybox';
import { Button, Col, Container, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { enable_fiend_friends_tab, fetch_sent_friend_req_count } from '../../actions/connections';
import { cast_jury_vote, check_is_juror, check_jury_duty, get_dispute_discussion, get_review_details } from "../../actions/feeds";
import { clear_media_dispatch, delete_mutiple_media, fetch_delete_media, fetch_media_data, fetch_taxonomy_category, fetch_taxonomy_list, get_people_you_know, media_upload_scroll, send_friend_request, single_delete_from_upload, upload_add_captions, upload_media_files } from "../../actions/myProfiles";
import { change_password, people_profile, my_profile_update, search_theater, update_email, update_my_profile, search_username, update_my_profile_error } from "../../actions/user";
import Loaderimg from "../../assets/images/w-brand-black.jpg";
import { loaderExludedRoutes } from "../../router";
import CollapseBasic from '../atoms/collapse';
import CommunityReviewProjects from '../molecules/dashboard/communityReviewProjects';
import Connections from "../molecules/dashboard/connections";
import Contributions from "../molecules/dashboard/contributions";
import Messages from "../molecules/dashboard/messages/index";
import Posts from "../molecules/dashboard/posts";
import Review from "../molecules/dashboard/reviews";
import YourPicks from "../molecules/dashboard/yourPicks";
import JuryDuty from "../atoms/disputeReview/juryDuty";
import AppHeader from '../oragnisms/dashboard/appHeader';
import ProfileTab from "../oragnisms/dashboard/peopleProfileTab";
import CreateAReview from '../oragnisms/home/CreateAReview';
import Footer from '../oragnisms/homepage/footer';
import { Ratings } from "../molecules/homepage/ShowRatings";
import { get } from 'lodash'

class People extends Component {
  constructor(props) {
    super(props)
    this.myScrollPhotoRef = React.createRef();
    this.myScrollVideoRef = React.createRef();

    this.refGalleryVideoScroll = React.createRef();
    this.refGalleryImageScroll = React.createRef();

    this.refScrollDeleteImages = React.createRef();
    this.refScrollDeleteVideos = React.createRef();
    this.checksUploadDisable = false;
    this.docHeight = 0;
    this.windowBottom = 0;

    this.state = {
      mainViewType: 'profile',
      // mainViewType: 'connections',
      isFieldShown: {},
      activeMyPhotoIndex: 0,
      animatingMyPhoto: false,
      selectedUpMediaType: 'upload',
      showGalleryType: 'images',
      myProfile: null,
      searchTheater: '',
      fetchPhotos: {},
      fetchPhotosCount: 0,
      fetchVideos: {},
      fetchVideosCount: 0,
      filterType: '',
      albumType: '',
      fetchTaxonomy: [],
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      fetchGalleryType: 'image',
      fetchGalleryAlbum: '',
      selectedFilesPreview: [],
      fetchDeletedData: {},
      fetchDeleteCount: 0,
      latestUploadedData: {},
      uploadCaptions: {},
      dataWantsToDelete: [],
      confirmDeleteModal: false,
      limitedPostsModal: false,
      uploadMediaModal: false,
      uploadMediaPostModal: false,
      sharePostModal: false,
      dropdownOpen: false,
      editPost: null,
      editPostType: "",
      editPostError: "",
      pageNo: 1,
      deletePostId: 0,
      deletePostType: "",
      changePassword: {
        newPassword: '',
        confirmNewPassword: '',
        error: ''
      },
      files: [],
      viewReviewsType: 'all',
      viewEditReviewStep: 'writeReview',
      viewYourPicksType: 'all',
      viewPicksTypeToggle: 'listing',
      viewCRPType: 'all',
      isLoader: false,
      createReviewModalToggle: false,
      taxonomycategoryId: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      is_juror: false,
      juror_data: '',
      reviewId: '',
      id: '',
      voteReviewModalToggleTwo: false,
      votingId: '',
      get_dispute_data: [],
      dispute_data: false,
      video: 'https://youtu.be/gYfJadhmcQY',
      vote: 0,
      remarks: '',
      count_0: 0,
      count_1: 1,
      count_2: 2,
      per_0: 0,
      per_1: 0,
      per_2: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      voting_enabled: true,
      can_vote: false,
      already_voted: false,
      timer_started: false,
      reviewAdded: '',
      myInfoError: {},
      has_voted: false,
      pId: "",
      cmnId: "",
      selectVideoError: "",
      selectPhotoError: "",
      searchUsername: '',
      subMainViewType: '',
      acceptMimeType: 'image/*,video/*',
      show_profile: false,
      selfProfile:false
    };

  }

  checkIsLoader = (isLoader) => {
    if (isLoader && isLoader == "true") {
      return (
        <div className="loader_div">
          <div className="inner-loader">
            <img src={Loaderimg} alt="" />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  loaderInterval = () => {
    let this_keep = this;

    if (!loaderExludedRoutes.includes(this.props.history.location.pathname)) {
      this.intervalCheck = setInterval(() => {

        let { isLoader } = this_keep.state;
        let localValue = localStorage.getItem("loader");
        if (isLoader !== localValue) {
          this_keep.setState({ isLoader: localStorage.getItem("loader") });
        }
      }, 1500);
    }

    if (loaderExludedRoutes.includes(this.props.history.location.pathname)) {
      clearInterval(this.intervalCheck);
    }
  };

  componentDidMount() {
    let { location } = this.props;
    this.props.check_is_juror()
    if (location?.search) {
      const parsed = queryString.parse(location.search);
      if (parsed?.code) {
        this.setState({ mainViewType: 'connections' }, () => this.props.enable_fiend_friends_tab({ status: true, param: parsed }));
      }
      if (parsed?.active_tab) {
        this.parsed_query = parsed?.active_tab;
        if (parsed?.active_tab == "friends") {
          this.setState({ mainViewType: 'connections' });
        } else {
          this.setState({ mainViewType: parsed.active_tab });
        }
      }
    }
    this.loaderInterval();
    document.title = `Wikireviews Investor Private Profile on WikiReviews`;
    let getBd = document.getElementsByTagName("BODY")[0];
    getBd.className = "";
    getBd.style.paddingRight = 0;
    this.fetchAfterRenderItems();
    window.addEventListener('scroll', this.handleScroll, { passive: false });
  }

  componentDidUpdate(prevProps) {
    let nextProps = this.props;
    let { mainViewType } = this.state;
    if (nextProps?.location?.state?.mainViewType == "messages") {
      if (mainViewType !== "messages") {
        this.setState({ mainViewType: "messages" })
      }
    }
    
  }

  fetchAfterRenderItems = () => {
    const { match } = this.props
    
  
    return Promise.all([
      this.props.my_profile(match?.params?.id),
    ]).then((res) => {
  
      this.props.clear_media_dispatch();
      this.props.get_people_you_know(1);
      this.props.fetch_media_data('image', '', 1, true, undefined, get(res, '[0].payload.user.username'))
      this.props.fetch_media_data('video', '', 1, true, undefined, get(res, '[0].payload.user.username'))
    });
  }

  componentWillMount() {
    let props = this.props;
    let mainViewTyp = props?.history?.location?.state?.mainViewType;
    let pId = props?.history?.location?.state?.pid;
    let cmnId = props?.history?.location?.state?.cmntid;
    if (mainViewTyp && mainViewTyp != "") {
      this.setState({ mainViewType: mainViewTyp, pId: pId, cmnId: cmnId });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.setTimeOutSlider);
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(nextProps) {

    let { fetchPhotos, fetchPhotosCount, fetchVideos, fetchVideosCount, fetchGalleryScroll, fetchGalleryCount, fetchDeletedData, fetchDeleteCount, latestUploadedData, show_profile } = this.state;

    if (nextProps.fetch_photos) {
      let item = nextProps.fetch_photos;
      if (item.album) { fetchPhotos = [] }
      fetchPhotos[item.page] = item;
      fetchPhotosCount = item.count;
      this.setState({ fetchPhotos, fetchPhotosCount, activeMyPhotoIndex: 0 });
    }


    if (nextProps.fetch_videos) {
      let item = nextProps.fetch_videos;
      if (item.album) { fetchVideos = [] }
      fetchVideos[item.page] = item;
      fetchVideosCount = item.count;
      this.setState({ fetchVideos, fetchVideosCount, activeMyPhotoIndex: 0 });
    }

    if (nextProps.fetch_taxonomy) {
      let taxCat = nextProps.fetch_taxonomy;
      if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
        taxCat = taxCat.filter(dt => dt.label !== null);
      }
      this.setState({ fetchTaxonomy: taxCat })
    }

    if (nextProps.fetch_deleted_data) {
      let item = nextProps.fetch_deleted_data;
      if (!item.type && !this.state.deleteMyVideosModal) { fetchDeletedData = {} };
      fetchDeletedData[item.page] = item;
      fetchDeleteCount = item.count;
      this.setState({ fetchDeleteCount, fetchDeletedData, activeMyPhotoIndex: 0 });
    }

    if (nextProps.upload_scroll) {
      let item = nextProps.upload_scroll;
      if (item.album) { fetchGalleryScroll = [] }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({ fetchGalleryScroll, fetchGalleryCount, activeMyPhotoIndex: 0 });
    }

    if (nextProps.my_profile_info && Object.keys(nextProps.my_profile_info).length > 0) {
      this.setState({
        myProfile: nextProps.my_profile_info,
        resetMyProfile: nextProps.my_profile_info
      });
    }

    if (nextProps.upload_media_data) {
      let data = nextProps.upload_media_data;
      if (data.index !== undefined) {
        let idx = data.index;
        latestUploadedData[idx] = data;
        this.setState({ latestUploadedData });
      }
    }

    if (nextProps.fetch_taxonomy_listData) {
      let taxCatListing = nextProps.fetch_taxonomy_listData;
      if (taxCatListing && Array.isArray(taxCatListing) && taxCatListing.length > 0) {
        //taxCatListing = taxCat.filter(dt => dt.label !== null);
        if (taxCatListing[0].isTypes == "images") {
          this.setState({ fetchTaxonomyListingImages: taxCatListing[0].children ? taxCatListing[0].children : [] })
        } else {
          this.setState({ fetchTaxonomyListingVideos: taxCatListing[0].children ? taxCatListing[0].children : [] })
        }
      }

    }

    // if ( nextProps.get_dispute_data && Object.keys(nextProps.get_dispute_data).length > 0 ) {
    //     this.setState({
    //       get_dispute_data: nextProps.get_dispute_data.results,
    //       dispute_data: true
    //     });
    //     if (!this.state.timer_started) {
    //       this.timerSettings();
    //       this.setState({ timer_started: true })
    //     }
    //     let now = new Date();
    //     let valid_date = nextProps.get_dispute_data && nextProps.get_dispute_data.results[0]['voting_open_till'];
    //     if (now > new Date(valid_date)) {
    //       this.setState({ voting_enable: true })
    //     }      
    //   }


    if (nextProps.my_profile_info) {
      let item = nextProps.my_profile_info;

      if (item.is_searchable == "all_wiki") {
        if (nextProps?.my_profiles?.id) {
          this.setState({ show_profile: true });
        } else {
          this.setState({ show_profile: false });
        }
      } else if (item.is_searchable == "just_myself") {
        this.setState({ show_profile: false });
      } else if (item.is_searchable == "everyone") {
        this.setState({ show_profile: true });
      } else if (item.is_searchable == "jst_my_friends") {
        if (item.my_connections.includes(nextProps?.my_profiles?.id)) {
          this.setState({ show_profile: true });
        } else {
          this.setState({ show_profile: false });
        }

      }

    }
    if(!!this.props?.match?.params?.id && this.props?.my_profiles?.user?.username){
      
          if(this.props?.match?.params?.id===this.props.my_profiles?.user?.username){
        this.setState({selfProfile: true})
      }else{
        this.setState({selfProfile: false})
      }
    }

  }

  handleOnChangeSearchTheater = (e) => {
    this.setState({
      searchTheater: e.target.value
    });
    this.props.search_theater(e.target.value);
  }

  handleOnChangeSearchUsername = (e) => {
    this.setState({
      searchUsername: e.target.value
    });
    let data = {
      username: e.target.value
    }
    this.props.update_my_profile_error({ username: null });
    this.props.search_username(data);
  }

  handleClickOutSideTheater = () => {
    this.setState({
      searchTheater: ''
    });
    this.props.search_theater('');
  }

  handleClickOutSideUsername = () => {
    let data = {
      username: ''
    }
    this.props.search_username(data);
  }

  handleOnChangeMyProfile = (entity) => (e) => {

    

    let { myInfoError } = this.state;
    const { name, value } = e.target

    if (entity === 'About Me') {
      this.setState({
        ...this.state,
        myProfile: {
          ...this.state.myProfile,
          about_me: e.target.value
        }
      });
    } else if (entity === 'First Name') {
      if (e.target.value.length < 3) {
        myInfoError.first_name = 'Minimum 3 characters!';
      } else if (!e.target?.value.match(/^[a-zA-Z ]*$/))
        myInfoError.first_name = 'Only alphabets are allowed!';
      else {
        delete myInfoError.first_name;
      }
      this.setState({
        ...this.state,
        myProfile: {
          ...this.state.myProfile,
          user: {
            ...this.state.myProfile.user,
            first_name: e.target.value
          }
        },
        myInfoError: {
          ...this.state.myInfoError,
          ...myInfoError
        }
      });
    } else if (entity === 'Last Name') {
      if (e.target.value.length < 3) {
        myInfoError.last_name = 'Minimum 3 characters!';
      } else if (!e.target?.value.match(/^[a-zA-Z ]*$/))
        myInfoError.last_name = 'Only alphabets are allowed!';
      else {
        delete myInfoError.last_name;
      }
      this.setState({
        ...this.state,
        myProfile: {
          ...this.state.myProfile,
          user: {
            ...this.state.myProfile.user,
            last_name: e.target.value
          }
        },
        myInfoError: {
          ...this.state.myInfoError,
          ...myInfoError
        }
      });
    } else if (entity === 'Date Of Birth') {
      this.setState({
        ...this.state,
        myProfile: {
          ...this.state.myProfile,
          dob: e.target.value
        }
      });
    } else if (entity === 'Email') {
      this.setState({
        ...this.state,
        myProfile: {
          ...this.state.myProfile,
          user: {
            ...this.state.myProfile.user,
            email: e.target.value
          }
        }
      });
    } else if (entity === 'From Address') {
      // if (e.target.name === 'zipCode' && e.target.value && e.target.value <= 0) {
      //   e.target.value = '';
      // }
      if (e.target.name === 'zipcode' && e.target.value.length !== 5) {
        myInfoError.zipCode = 'Ensure zipcode/postal code has 5 characters';
      }

      // else if (e.target.value.length < 6) {
      //   myInfoError.zipcode = 'Ensure zipcode/postal code has 5 characters';
      // }
      else if (e.target.name === 'zipcode' && !e.target?.value.match(/^[0-9]*$/))
        myInfoError.zipCode = 'Only numbers are allowed!';
      else {
        delete myInfoError.zipCode;
      }

      if (name === 'city' && (value === "" || !value))
        myInfoError.City = 'City is required'
      else
        delete myInfoError.City

      this.setState({
        ...this.state,
        myProfile: {
          ...this.state.myProfile,
          permanent_address: {
            ...this.state.myProfile.permanent_address,
            [e.target.name]: e.target.value
          }
        },
        myInfoError: {
          ...this.state.myInfoError,
          ...myInfoError
        }
      });
    } else if (entity === 'Address') {
      if (e.target.name === "zipcode" && e.target.value.length !== 5) {
        myInfoError.zipcode = 'Ensure zipcode/postal code has 5 characters sssss';
      }
      else if (!e.target?.value.match(/^[0-9]*$/))
        myInfoError.zipcode = 'Only numbers are allowed!';
      else {
        delete myInfoError.zipcode;
      }


      this.setState({
        ...this.state,
        myProfile: {
          ...this.state.myProfile,
          present_address: {
            ...this.state.myProfile.present_address,
            [e.target.name]: e.target.value
          }
        },
        myInfoError: {
          ...this.state.myInfoError,
          ...myInfoError
        }
      });
    }


    else if (entity === 'Gender') {
      this.setState({
        ...this.state,
        myProfile: {
          ...this.state.myProfile,
          gender: e.target.value
        }
      });
    } else if (entity === 'Password') {
      this.setState({
        ...this.state,
        changePassword: {
          ...this.state.changePassword,
          [e.target.name]: e.target.value,
          error: ''
        }
      });
    }

  }

  handleOnSaveMyProfile = (entity, id = 0) => {
    let data = {};
    let { myInfoError } = this.state;

    if (this.state.myProfile && this.state.myProfile.user) {
      if (entity === 'About Me') {
        data.about_me = this.state.myProfile.about_me;
        if (data.about_me !== '') {
          this.props.update_my_profile(data, entity);
          this.hideEditCollapse('aboutMe');
        }

      } else if (entity === 'First Name') {
        data.user = {};
        data.user.first_name = this.state.myProfile.user.first_name;
        if (data.user.first_name !== '' && !myInfoError.first_name) {
          this.props.update_my_profile(data, entity);
          this.hideEditCollapse('fName');
        }

      } else if (entity === 'Last Name') {
        data.user = {};
        data.user.last_name = this.state.myProfile.user.last_name;
        if (data.user.last_name !== '' && !myInfoError.last_name) {
          this.props.update_my_profile(data, entity);
          this.hideEditCollapse('lName');
        }

      } else if (entity === 'Home Theater') {
        data.user = {};
        data.user.home_theater = id ? id : this.state.myProfile.home_theater_list.id;
        if (data.user.home_theater) {
          this.props.update_my_profile(data, entity);
        }
        this.setState({
          searchTheater: ''
        }, () => {
          this.hideEditCollapse('theater');
        });

      } else if (entity === 'Date Of Birth') {
        data.user = {};
        data.user.dob = this.state.myProfile.dob;
        if (data.user.dob !== '') {
          this.props.update_my_profile(data, entity);
          this.hideEditCollapse('age');
        }

      } else if (entity === 'Email') {
        data.email = this.state.myProfile.user.email;
        if (data.email !== '') {
          this.props.update_email(data);
          this.hideEditCollapse('email');
        }

      } else if (entity === 'From Address') {
        data.permanent_address = this.state.myProfile.permanent_address;
        if (data.permanent_address && data.permanent_address.city !== '') {
          this.props.update_my_profile(data, "Permanent Address");
          this.hideEditCollapse('fromAddress');
          delete myInfoError.City;
        }
        else {
          myInfoError.City = 'City is required.';
          this.setState({
            myInfoError: {
              ...this.state.myInfoError,
              ...myInfoError
            }
          });
        }

      } else if (entity === 'Address') {
        data.present_address = this.state.myProfile.present_address;
        if (data.present_address && data.present_address.city !== '') {
          this.props.update_my_profile(data, "Present Address");
          this.hideEditCollapse('address');

          delete myInfoError.city;

        }
        else {
          myInfoError.city = 'City is required';
          this.setState({
            myInfoError: {
              ...this.state.myInfoError,
              ...myInfoError
            }
          });
        }
      }


      else if (entity === 'Gender') {
        data.user = {};
        data.user.gender = this.state.myProfile.gender;
        if (data.user.gender !== '') {
          data.user.gender = data.user.gender ? data.user.gender : 'm';
          this.props.update_my_profile(data, "Gender");
          this.hideEditCollapse('gender');
        }
      } else if (entity === 'Password') {
        let error = '';
        if (this.state.changePassword.newPassword !== '' && this.state.changePassword.confirmNewPassword !== '') {
          if (this.state.changePassword.newPassword !== this.state.changePassword.confirmNewPassword) {
            error = 'Passwords do not match!';
          } else {
            if (this.state.changePassword.newPassword.length < 6) {
              error = 'Minimum 6 characters are required!';
            }
          }

          if (error) {
            this.setState({
              ...this.state,
              changePassword: {
                ...this.state.changePassword,
                error: error
              }
            });
          } else {
            data.name = 'password';
            data.pk = 1;
            data.password1 = this.state.changePassword.newPassword;
            data.password2 = this.state.changePassword.newPassword;
            this.props.change_password(data);
            this.setState({
              ...this.state,
              changePassword: {
                newPassword: '',
                confirmNewPassword: '',
                error: ''
              }
            }, () => {
              this.hideEditCollapse('password');
            });
          }
        } else {
          error = 'Both fields are required!';
          this.setState(prevState => ({
            ...prevState,
            changePassword: {
              ...prevState.changePassword,
              error: error
            }
          }));
        }
      } else if (entity === 'Username') {
        const { search_username_info } = this.props;
        data.user = {};
        data.user.username = id ? id : (this.state.searchUsername ? this.state.searchUsername : this.state.myProfile?.user?.username);
        let new_search_username_info = search_username_info.map(item => item.id);

        if (data.user.username !== '' && !myInfoError.username) {
          this.props.update_my_profile(data, entity);
        }
        setTimeout(() => {
          if (!(this.props.my_profile_error && this.props.my_profile_error.username)) {
            this.setState({
              searchUsername: '',
            });
            this.hideEditCollapse('username');
          }
        }, 1200);
      }
    }
  }

  handleOnCancelUpdateProfile = (entity) => {
    let { myInfoError } = this.state;
    if (entity === 'About Me') {
      this.hideEditCollapse('aboutMe');

    } else if (entity === 'First Name') {
      this.hideEditCollapse('fName');
      this.setState({
        myProfile: this.state.resetMyProfile
      })
      delete myInfoError.first_name;

    } else if (entity === 'Last Name') {
      this.hideEditCollapse('lName');
      this.setState({
        myProfile: this.state.resetMyProfile
      })
      delete myInfoError.last_name;

    } else if (entity === 'Home Theater') {
      this.hideEditCollapse('theater');
      this.setState({
        searchTheater: ''
      });

    } else if (entity === 'Email') {
      this.hideEditCollapse('email');

    } else if (entity === 'From Address') {
      this.hideEditCollapse('fromAddress');

    }
    else if (entity === 'City') {
      this.hideEditCollapse('city');

    }

    else if (entity === 'Address') {
      this.hideEditCollapse('address');

    } else if (entity === 'Gender') {
      this.hideEditCollapse('gender');

    } else if (entity === 'Password') {
      this.setState({
        ...this.state,
        changePassword: {
          newPassword: '',
          confirmNewPassword: '',
          error: ''
        }
      }, () => {
        this.hideEditCollapse('password');
      });

    } else if (entity === 'Username') {
      this.hideEditCollapse('username');
      delete myInfoError.username;
      this.props.update_my_profile_error({ username: null });

    } else if (entity === 'Date Of Birth') {
      this.hideEditCollapse('age');
    }
    // this.props.my_profile();
    this.props.my_profile_update();
    this.setState({ myInfoError });
  }

  showEditCollapse = (type) => {
    this.setState({ isFieldShown: { [type]: true } })
  }

  hideEditCollapse = (type) => {
    this.setState({ isFieldShown: { [type]: false } })
  }

  nextMyPhoto = () => {
    let { fetchPhotosCount } = this.state;

    if (this.state.animatingMyPhoto) return;
    const nextIndex = this.state.activeMyPhotoIndex === fetchPhotosCount - 1 ? 0 : this.state.activeMyPhotoIndex + 1;
    this.setState({ activeMyPhotoIndex: nextIndex })
  }

  previousMyPhoto = () => {
    let { fetchPhotosCount } = this.state;
    if (this.state.animatingMyPhoto) return;
    const nextIndex = this.state.activeMyPhotoIndex === 0 ? fetchPhotosCount - 1 : this.state.activeMyPhotoIndex - 1;
    this.setState({ activeMyPhotoIndex: nextIndex })
  }

  myPhotoSlides = () => {

    let { fetchPhotos } = this.state;
    let getDataItems = [];
    if (fetchPhotos && Object.keys(fetchPhotos).length > 0) {
      Object.keys(fetchPhotos).forEach(item => {
        let data = fetchPhotos[item].results;
        getDataItems = getDataItems.concat(data);
      });
      return getDataItems.map((med, i) => (
        <div className="item fs-14 h-100 d-flex flex-column justify-content-center" key={`item-${i}`}>
          <div>
            <div className="d-flex text-white justify-content-between mt-3">
              <div className="mr-2 ff-alt">
                {med?.caption}
              </div>
              <div className="ff-base">
                {med?.label}
              </div>
            </div>
          </div>
          <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
            {(med?.type === 'image' || med?.type === '') && (
              <img className="img-fluid shown-media" src={med?.file} alt="" />
            )}

          </div>
          <div className="px-2 mt-3">
            <div className="d-flex flex-nowrap mx-n2">
              <div className="px-2 col d-flex flex-nowrap">
                <div className="mr-2">
                  <img className="img-circle _30x30"
                    src={med?.user?.current_profile_file ? med?.user?.current_profile_file : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt=""
                    onError={(error) => (error.target.src = require("../../assets/images/icons/user-circle.png"))} />
                </div>
                <div>
                  <div className="text-primary ff-base">
                    Uploaded by&nbsp;<a className="text-reset" href="#"><span className="font-weight-bold">{med?.user?.user?.first_name}</span> </a>
                  </div>
                  <div className="text-tertiary ff-base">
                    {med?.upload_date ? moment(med?.upload_date).format('DD-MM-YYYY HH:mm') : ''}
                  </div>
                </div>
              </div>
              <div className="px-2 col-auto">
                <h2 className="fs-38" style={{ color: '#d4dae6' }}>{i + 1} of {getDataItems.length}</h2>
              </div>
              <div className="px-2 col">&nbsp;</div>
            </div>
          </div>
        </div>

      ))

    }
  };


  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach(item => {
        if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map(item => {

          let itemName = (item?.thumbnail).split('.');
          let itemFile = (item?.file).split('.');
          let extName = itemName[itemName.length - 1];
          let extFile = itemFile[itemFile.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview

          return (
            <Col xs="auto" className="mb-3">
              <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                <Label check>
                  <Input type="checkbox" />
                  <div className="gallery-media media-3" >
                    <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                  </div>
                </Label>
                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
              </FormGroup>
            </Col>
          )
        })
      }
    } else {
      return getDataItems;
    }
  }
  // <li onClick={() => this.setState({ viewMyPhotosModal: !this.state.viewMyPhotosModal })} className="p-1" role="button">
  //     <img className="img-thumbnail uploaded-img-thumbnail" src={item?.file} alt="" />
  // </li>


  renderPhotos = () => {
    let { fetchPhotos } = this.state;
    let getDataItems = [];
    if (fetchPhotos && Object.keys(fetchPhotos).length > 0) {
      Object.keys(fetchPhotos).forEach(item => {
        let data = fetchPhotos[item].results;
        getDataItems = getDataItems.concat(data);
      });

      return getDataItems.map((item, index) => (
        <li key={index} onClick={() => this.setState({ viewMyPhotosModal: !this.state.viewMyPhotosModal, activeMyPhotoIndex: index })} className="p-1 uploaded-img-thumbnail" role="button" >
          <img className="img-thumbnail" src={item?.file} alt="" />
        </li>
      ))

    }
  }

  renderVideos = () => {
    let { fetchVideos } = this.state;
    let getDataItems = [];
    if (fetchVideos && Object.keys(fetchVideos).length > 0) {
      Object.keys(fetchVideos).forEach(item => {
        let data = fetchVideos[item].results;
        getDataItems = getDataItems.concat(data);
      });

      const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`
      return getDataItems.map((item, index) => {
        let itemName = item?.thumbnail ? (item.thumbnail).split('.') : "";
        let itemFile = item?.file ? (item.file).split('.') : "";
        let extName = itemName[itemName.length - 1];
        let extFile = itemFile[itemFile.length - 1];

        let image_going_to_render = (extFile !== extName && extName.length <= 4) ? item?.thumbnail : imagePreview;
        return (
          <Fragment>
            <FancyVideo
              className="video-item"
              style={{ height: '85px', width: '85px', margin: '5px' }}
              source={item?.file}
              poster={image_going_to_render}
              id={`sintel-${index}`}
            />
          </Fragment>
        )
      })
    }
  }

  handleScrollGalleryImage = (e) => {
    let { refGalleryImageScroll } = this;
    let { fetchGalleryAlbum } = this.state;

    let clientHeight = refGalleryImageScroll.current.clientHeight;
    let scrollHeight = refGalleryImageScroll.current.scrollHeight;
    const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
    }
  }

  fetchGalleryOnScroll = ({ type, album, force_update }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;

      if (nextCount <= roundValue) {
        this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
      }
    }
  }

  handleScrollGalleryVideo = (e) => {
    let { refGalleryVideoScroll } = this;
    // let count = 0;
    let clientHeight = refGalleryVideoScroll.current.clientHeight;
    let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
    const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
    }
  }

  handlePhotoScroll = (e) => {
    let { myScrollPhotoRef } = this;
    // let count = 0;
    let clientHeight = myScrollPhotoRef.current.clientHeight;
    let scrollHeight = myScrollPhotoRef.current.scrollHeight;
    const scrollTop = this.myScrollPhotoRef.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      this.fetchRenderPhotoItems();
    }
  }

  handleVideoScroll = (e) => {
    let { myScrollVideoRef } = this;
    // let count = 0;
    let clientHeight = myScrollVideoRef.current.clientHeight;
    let scrollHeight = myScrollVideoRef.current.scrollHeight;
    const scrollTop = this.myScrollVideoRef.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      this.fetchRenderVideoItems();
    }
  }

  fetchRenderVideoItems = () => {
    let { fetchVideos, fetchVideosCount } = this.state;

    if (fetchVideos && Object.keys(fetchVideos).length > 0) {
      let totalCount = fetchVideosCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchVideos).length;
      let nextCount = countPhotos + 1;

      if (nextCount <= roundValue) {
        this.props.fetch_media_data('video', '', nextCount, true);
      }
    }
  }


  fetchRenderPhotoItems = () => {
    let { fetchPhotos, fetchPhotosCount } = this.state;

    if (fetchPhotos && Object.keys(fetchPhotos).length > 0) {
      let totalCount = fetchPhotosCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchPhotos).length;
      let nextCount = countPhotos + 1;

      if (nextCount <= roundValue) {
        this.props.fetch_media_data('image', '', nextCount, true);
      }
    }
  }

  handleCheckAlbumType = ({ filterType, albumType }) => {
    this.setState({ filterType, albumType }, () => {
      if (filterType == 'image' && albumType == '') {
        this.props.fetch_media_data(filterType, albumType, 1, true, true);
      } else {
        this.props.fetch_media_data(filterType, albumType, 1, true, true);

      }
    });
  }

  handleChangeTaxonomy = (e) => {
    let { value } = e.target;
    let albumType = value !== 'all' ? value : '';
    this.setState({ fetchGalleryScroll: {}, fetchGalleryAlbum: value, fetchGalleryType: 'image' }, () => this.props.media_upload_scroll({ type: 'image', album: albumType, page: 1, force_update: true }))
  }

  handleOnFileUploadChange = (e) => {
    let { selectedFilesPreview, files } = this.state;

    //checking file types
    let uploadFiles = e.target.files;
    for (const key of Object.keys(uploadFiles)) {
      if (uploadFiles[key].type.includes('image') || uploadFiles[key].type.includes('video') || uploadFiles[key].type.includes('audio')) {

      } else {
        this.setState({ uploadError: 'Requested file format is not supported. Please enter valid format' })
        return false
      }
    }
    this.setState({ uploadError: undefined })

    const filesSetForUpload = Array.from(e.target.files);
    files = files.concat(filesSetForUpload);
    let filteredItems = [];
    files.forEach(item => {
      let type_splt = item.type.split('/');
      if (type_splt[0] == "image" || type_splt[0] == "video") {
        filteredItems.push(item)
      }
    });

    files = filteredItems;

    let self = this;
    this.setState({ files }, () => {
      Promise.all(files.map(file => {
        return (new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', (ev) => {
            resolve(ev.target.result);
          });
          reader.addEventListener('error', reject);
          reader.readAsDataURL(file);
        }));
      })).then(images => {
        let concatWithPrev = selectedFilesPreview.concat(images);
        self.setState({ selectedFilesPreview: concatWithPrev })
      }, error => {
        console.error(error);
      });
    });

    if (files && Array.isArray(files) && files.length > 0) {
      let indexSelected = selectedFilesPreview.length - 1;
      files.forEach(async (item, index) => {
        if (index > indexSelected) {
          let formData = new FormData();
          formData.append('file', item);

          let name = item.name;
          let split_items = name.split('.');
          let item_ext = split_items[split_items.length - 1];
          let isImageTrue = ['jpg', 'png', 'jpeg', 'gif', 'tiff'].includes(item_ext);
          if (isImageTrue) {
            await this.props.upload_media_files('image', formData, index);
            //await this.props.fetch_taxonomy_list('business', 'images');
          } else {
            await this.props.upload_media_files('video', formData, index);
            //await this.props.fetch_taxonomy_list('business', 'videos');
          }
        }
      });
    }
  }

  removeSelectedImage = (e, index) => {
    let { latestUploadedData, selectedFilesPreview, files } = this.state

    let upload_data = latestUploadedData[index];
    let id = upload_data.id;
    this.props.single_delete_from_upload(id);

    // delete items from array
    selectedFilesPreview.splice(index, 1);
    files.splice(index, 1); // deleted this because it concat from the upload function.

    // deleted items from json
    delete latestUploadedData[index];

    // deleted items from json
    if (latestUploadedData[index] == undefined) {
      let newItems = [];

      // turn json to array for indexes
      Object.values(latestUploadedData).forEach(it => { newItems.push(it) });

      // turn array to json for indexes
      if (newItems && Array.isArray(newItems) && newItems.length) {
        let newItemsJson = {};
        newItems.forEach((nitem, index) => {
          newItemsJson[index] = nitem;
        });

        // turn json to array for indexes
        if (Object.keys(newItemsJson).length) {
          latestUploadedData = newItemsJson;
        }
      }
    }

    this.setState({ selectedFilesPreview, latestUploadedData, files });
  }

  handlePushItems = (e, item) => {
    e.preventDefault();
    let { dataWantsToDelete } = this.state;
    let id = item.id;

    if (dataWantsToDelete.includes(id)) {
      dataWantsToDelete = dataWantsToDelete.filter(dt => dt !== id);
    } else {
      dataWantsToDelete.push(id);
    }
    this.setState({ dataWantsToDelete, selectVideoError: "", selectPhotoError: "" });
  }

  renderDeletePhotos = (isVideo) => {
    let { fetchDeletedData, dataWantsToDelete } = this.state;
    let getDataItems = [];

    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`
    if (fetchDeletedData && Object.keys(fetchDeletedData).length > 0) {
      Object.keys(fetchDeletedData).forEach(item => {
        let data = fetchDeletedData[item].results;
        getDataItems = getDataItems.concat(data);
      });

      if (!getDataItems || !Array.isArray(getDataItems) || getDataItems.length == 0) {
        return null;
      }

      return getDataItems.map((item, index) => {

        let itemName = (item?.thumbnail).split('.');
        let itemFile = (item?.file).split('.');
        let extName = itemName[itemName.length - 1];
        let extFile = itemFile[itemFile.length - 1];

        //let items = (extFile !== extName && extName <= 4) ? item?.thumbnail : imagePreview;
        let items = (extFile !== extName && extName.length <= 4) ? item?.thumbnail : imagePreview;

        return (
          <Col key={index} onClick={(e) => { this.handlePushItems(e, item) }} xs="auto" lg="3" className="mb-3">
            <FormGroup className="checkable-media">
              <Label check>
                <Input type="checkbox" checked={dataWantsToDelete.includes(item.id) ? true : false} />
                <div className="gallery-media media-2" >

                  <img className="img-fluid" src={isVideo ? items : item?.file} alt="" />
                  <div className="mt-2">
                    {item?.caption ? item?.caption : ''}
                    {/* {item?.caption} */}
                  </div>
                </div>
              </Label>
            </FormGroup>
          </Col>
        )
      })
    }
  }

  handleScrollDeleteImages = () => {
    let { refScrollDeleteImages } = this;
    // let count = 0;
    let clientHeight = refScrollDeleteImages.current.clientHeight;
    let scrollHeight = refScrollDeleteImages.current.scrollHeight;
    const scrollTop = this.refScrollDeleteImages.current.scrollTop.toFixed() - 1;

    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;
    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      // this.fetchRenderVideoItems();
      this.fetchDeleteItems({ type: 'image' });
    }
  }

  handleScrollDeleteVideos = () => {
    let { refScrollDeleteVideos } = this;
    // let count = 0;
    let clientHeight = refScrollDeleteVideos.current.clientHeight;
    let scrollHeight = refScrollDeleteVideos.current.scrollHeight;
    const scrollTop = this.refScrollDeleteVideos.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated == scrollTop ||
      scrollTop == scrollMinimun_1 ||
      scrollTop == scrollMinimun_3 ||
      scrollTop == scrollMinimun_5 ||
      scrollTop == scrollMinimun_6 ||
      scrollTop == scrollMinimun_8
    ) {
      // this.fetchRenderVideoItems();
      this.fetchDeleteItems({ type: 'video' });
    }
  }

  fetchDeleteItems = ({ type }) => {
    let { fetchDeletedData, fetchDeleteCount } = this.state;
    if (fetchDeletedData && Object.keys(fetchDeletedData).length > 0) {
      let totalCount = fetchDeleteCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchDeletedData).length;
      let nextCount = countPhotos + 1;

      if (nextCount <= roundValue) {
        this.props.fetch_delete_media({ type, page: nextCount });
      }
    }
  }

  uploadMediaTab = () => {
    let data = { file: '' }
    this.props.upload_media_files(data);
  }

  handleCaptionByIndex = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, latestUploadedData } = this.state;
    uploadCaptions[index] = value;
    let data = {};
    let upload_data = latestUploadedData[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    let id = upload_data.id;
    this.setState({ uploadCaptions }, () => this.props.upload_add_captions(id, data));

  }

  handleChangeTaxonomyData = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, latestUploadedData, } = this.state
    let data = {};
    let upload_data = latestUploadedData[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    data.category = value;
    let id = upload_data.id;
    this.setState({ taxonomycategoryId: value }, () => this.props.upload_add_captions(id, data))

  }

  handleSubmitCaptions = (e, index) => {
    let { uploadCaptions, latestUploadedData } = this.state
    let data = {};
    let upload_data = latestUploadedData[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;

    let id = upload_data.id;
    this.props.upload_add_captions(id, data);
  }

  clearMediaDispatch = () => {

    this.setState({ latestUploadedData: {}, uploadCaptions: {}, selectedFilesPreview: [], files: [] }, () => this.props.clear_media_dispatch());
  }

  handleDeleteAfter = () => {
    this.setState({ fetchDeleteCount: 0, fetchDeletedData: {}, dataWantsToDelete: [] }, () => {
      this.props.fetch_media_data('image', '', 1, true, true);
      this.props.fetch_media_data('video', '', 1, true, true);
    });
    // this.setState({ fetchDeleteCount: 0, fetchDeletedData: {}, dataWantsToDelete: [] });
  }

  renderNavItems = ({ active, title }) => {
    return (
      <NavItem>
        <NavLink
          active={this.state.mainViewType === active}
          onClick={() => { this.setState({ mainViewType: active }) }} style={{ maxWidth: '240px' }}>
          {title}
        </NavLink>
      </NavItem>
    )
  }

  handleCloseAndFetch = () => {

    this.setState({
      uploadMediaModal: false, taxonomycategoryId: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: []
    }, () => {
      this.clearMediaDispatch();
      this.props.fetch_media_data('image', '', 1, true, true);
      this.props.fetch_media_data('video', '', 1, true, true);
    });
  }
  handleClosed = () => {
    this.setState({
      uploadMediaModal: false,
      acceptMimeType: 'image/*,video/*'
    });
  }
  handleScroll = () => {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    this.docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    this.windowBottom = windowHeight + window.pageYOffset;
    this.getWindowScrollResult();
  }

  getWindowScrollResult = () => ({ 'docHeight': this.docHeight, 'windowBottom': this.windowBottom });

  handleClickViewMore = (url) => {
    if (url.includes('page')) {
      const newArray = url.split('=');
      if (newArray[1]) {
        this.props.get_people_you_know(newArray[1]);
      }
    }
  }

  sendFriendRequest = async (email) => {
    await this.props.send_friend_request({ email: email });
    await this.props.fetch_sent_friend_req_count({ page: 1 });
    await this.props.fetch_sent_friend_req_count({ count: true });
  }

  isUploadingTrue = (isTrue) => {
    this.checksUploadDisable = isTrue;
  }

  handleCheckJuryduty = () => {
    this.setState({ dispute_data: false, voteReviewModalToggleTwo: true })
    this.props.check_jury_duty(this.state.reviewId)
    this.props.get_review_details(this.state.reviewId)
  }

  // countdown counter
  timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
    let difference = {};
    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    difference['days'] = days;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    difference['hours'] = hours;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    difference['minutes'] = minutes;
    difference['seconds'] = Math.floor(diffInMilliSeconds);

    return difference;
  }

  timerSettings = () => {
    this.interval = setInterval(() => {
      if (this.state.voteReviewModalToggleFour === false) {
        this.clearTimerSettings()
      }
      let dbDate = new Date(
        this.props.get_juror_data &&
        this.props.get_juror_data.results[0] &&
        this.props.get_juror_data.results[0].created_on
      );
      let date = new Date(dbDate);
      date = date.setDate(date.getDate() + 7);
      let now = new Date()
      let today = new Date(now)
      dbDate = new Date(date)
      if (today < dbDate) {
        this.setState({ can_vote: true })
        let countdown = this.timeDiffCalc(dbDate, today)
        this.setState({
          days: countdown['days'],
          hours: countdown['hours'],
          minutes: countdown['minutes'],
          seconds: countdown['seconds']
        });
      }
      else {
        this.setState({ can_vote: false })
        this.clearTimerSettings()
      }

    }, 1000);

  }
  //countdown clear counter
  clearTimerSettings() {
    if (this.interval) {
      clearInterval(this.interval);
      this.setState({ timer_started: false })
    }
  }
  handleChangeDis = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === 'reviewToKeep') {
      this.setState({ vote: e.target.value })
    }
  };

  handleSubmit = () => {
    let vote_details = {
      'vote': this.state.vote,
      'remarks': this.state.remarks,
      'id': this.state.votingId
    }

    this.props.cast_jury_vote(vote_details, this.state.reviewId)
    this.setState({ remarks: '', has_voted: true })
    this.props.check_is_juror()
  }

  handleDeleteVides = () => {
    let { dataWantsToDelete } = this.state;
    if (dataWantsToDelete.length > 0) {
      this.setState({ confirmDeleteMyVideos: true, selectVideoError: "" })
    } else {
      this.setState({ selectVideoError: "Please select at least one video" })

    }

  }

  handleDeletePhotos = () => {
    let { dataWantsToDelete } = this.state;
    if (dataWantsToDelete.length > 0) {
      this.setState({ confirmDeleteMyPhotos: true, selectPhotoError: "" })

    } else {
      this.setState({ selectPhotoError: "Please select at least one photo" })

    }

  }



  render() {
    let {
      fetchPhotos,
      mainViewType,

      fetchTaxonomy,
      selectedFilesPreview,

      myProfile,
      isFieldShown,
      searchTheater,
      latestUploadedData,
      changePassword,
      isLoader,
      get_dispute_data, video, remarks, count_0, count_1, count_2,
      per_0, per_1, per_2, days, hours, minutes, seconds,
      searchUsername,
      subMainViewType,
      show_profile,
      selfProfile
    } = this.state;
    const { search_theater_info, people_you_know_data, search_username_info } = this.props;
    let totalLength = selectedFilesPreview.length;
    let objectKeys = [];
    let objectLength = 0;
    let calculatedPercentage = 0;
    if (Object.keys(latestUploadedData).length > 0) {
      objectKeys = Object.keys(latestUploadedData);
      objectLength = objectKeys.length;
      calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
    };

    const responsive = {
      videoModal: {
        mobile: {
          breakpoint: { max: 9999999, min: 0 },
          items: 1
        }
      },
      viewGalleryImages: {
        desktop: {
          breakpoint: { max: 9999999, min: 1200 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1199, min: 576 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1
        }
      }
    }

   console.log('selfProfile',selfProfile)
    return (
      <div>
        {this.checkIsLoader(isLoader)}
        <AppHeader
          fetchDashboardData={this.fetchAfterRenderItems}
          isEnableCover={true}  
          notPeopleTab={selfProfile}
          selfProfile={selfProfile || false}
        />

        <section>
          <Container>
            <div className="d-flex flex-row mx-n2 flex-nowrap">
              {this.state.show_profile ?
                <div className="px-2 pt-2 flex-170-rest">
                  <Nav tabs className="text-center align-items-end main">
                    {this.renderNavItems({ active: 'profile', title: 'Profile' })}
                    {/* {this.renderNavItems({ active: 'messages', title: 'Messages' })} */}
                    {this.renderNavItems({ active: 'connections', title: 'Connections' })}
                    {this.renderNavItems({ active: 'reviews', title: 'Reviews' })}
                    {this.renderNavItems({ active: 'crp', title: 'Community Review Projects' })}
                    {this.renderNavItems({ active: 'contributions', title: 'Contributions' })}
                    {this.renderNavItems({ active: 'posts', title: 'Posts' })}
                    {/* {this.renderNavItems({ active: 'picks', title: 'Your Picks' })} */}
                    {/* {this.renderNavItems({ active: 'jury', title: 'Jury Duty' })} */}
                  </Nav>
                  <TabContent activeTab={this.state.mainViewType}>
                    <TabPane tabId="profile">
                      <div className="mx-n2 mb-3 ff-alt fs-14 text-dark font-weight-bold">
                        <span role="button" className={`ff-base text-decoration-none mx-2 ${subMainViewType === 'work history' ? 'text-dark' : 'text-tertiary'}`} onClick={() =>
                          this.setState({
                            subMainViewType: 'work history'
                          })
                        }>Work History</span>
                        <span role="button" className={`ff-base text-decoration-none mx-2 ${subMainViewType === 'favorites' ? 'text-dark' : 'text-tertiary'}`} onClick={() => this.setState({
                          subMainViewType: 'favorites'
                        })}>Favorites</span>
                      </div>
                      <ProfileTab
                        myProfile={myProfile}
                        isFieldShown={isFieldShown}
                        searchTheater={searchTheater}
                        showEditCollapse={this.showEditCollapse}
                        hideEditCollapse={this.hideEditCollapse}
                        search_theater_info={search_theater_info}
                        handleOnChangeMyProfile={this.handleOnChangeMyProfile}
                        handleOnSaveMyProfile={this.handleOnSaveMyProfile}
                        handleOnCancelUpdateProfile={this.handleOnCancelUpdateProfile}
                        handleOnChangeSearchTheater={this.handleOnChangeSearchTheater}
                        handleCheckAlbumType={this.handleCheckAlbumType}
                        myScrollPhotoRef={this.myScrollPhotoRef}
                        handlePhotoScroll={this.handlePhotoScroll}
                        renderPhotos={this.renderPhotos}
                        myScrollVideoRef={this.myScrollVideoRef}
                        handleVideoScroll={this.handleVideoScroll}
                        renderVideos={this.renderVideos}
                        changePassword={changePassword}
                        handleClickOutSideTheater={this.handleClickOutSideTheater}
                        myInfoError={this.state.myInfoError}
                        searchUsername={searchUsername}
                        search_username_info={search_username_info}
                        handleOnChangeSearchUsername={this.handleOnChangeSearchUsername}
                        handleClickOutSideUsername={this.handleClickOutSideUsername}
                        myProfileError={this.props.my_profile_error}
                        subMainViewType={subMainViewType}
                      />
                    </TabPane>

                    {mainViewType === 'messages' && (
                      <TabPane tabId="messages">
                        <Messages />
                      </TabPane>
                    )}

                    {mainViewType === 'connections' && (
                      <TabPane tabId="connections">
                        <Connections
                          id={myProfile?.user?.id}
                          notPeopleTab={false}
                          enableFindFriends={this.parsed_query == "friends" ? true : false}
                        />
                      </TabPane>
                    )}

                    {mainViewType === 'reviews' && (
                      <TabPane tabId="reviews">
                        <Review
                          getWindowScrollResult={this.getWindowScrollResult}
                          notPeopleTab={false}
                          id={myProfile?.user?.id}
                        />
                      </TabPane>
                    )}

                    {mainViewType === 'crp' && (
                      <TabPane tabId="crp">
                        <CommunityReviewProjects notPeopleTab={false} />
                      </TabPane>
                    )}

                    {mainViewType === 'contributions' && (
                      <TabPane tabId="contributions">
                        <Contributions notPeopleTab={false} />
                      </TabPane>
                    )}
                    {mainViewType === 'posts' && (
                      <TabPane tabId="posts">
                        <Posts
                          postId={this.state.pId}
                          commentId={this.state.cmnId}
                          notPeopleTab={false}
                        />
                      </TabPane>
                    )}
                    {mainViewType == "picks" ? (
                      <TabPane tabId="picks">
                        <YourPicks />
                      </TabPane>
                    ) : null}
                    {mainViewType == "jury" ? (
                      <TabPane tabId="jury">
                        <JuryDuty />
                      </TabPane>
                    ) : null}
                  </TabContent>
                </div>

                :
                <div className="w-100 text-center">
                  <span style={{color: '#726647', fontSize: '20px' , fontWeight:600}}>User profile set to private.</span>
                </div>}
              <div className="px-2 flex-170">

                {this.state.is_juror ?
                  <Button className="a-btn" color="primary" block onClick={this.handleCheckJuryduty} >Jury Duty</Button>
                  :
                  ''
                }

                <Button className="a-btn" color="primary" block onClick={() => this.setState({ createReviewModalToggle: !this.state.createReviewModalToggle })}>Create a Review</Button>

                <div className="bg-dark-alt text-white p-3 mt-3">
                  <h3 className="mb-2">Need an Opinion?</h3>
                  <p className="fs-14">
                    Upload an image, embed a video, or write something to be reviewed.
                  </p>

                  <Link color="primary" className="btn btn-primary text-left lh-1-3" to="/projectslist" block>Create New Project</Link>
                </div>

                <div className="p-3 mt-3" style={{ backgroundColor: '#ebebec' }}>
                  <a rel="noopener noreferrer" href="http://wikireviews.com/blog" target="_blank" className="text-reset wk-home-new-adds">
                    <img className="w-100 blog-img" src={require('../../assets/images/house_ads_heraldblog_01.png')} alt="" />
                  </a>
                </div>

                <div className="bg-white p-3 mt-3">
                  <h2 className="text-dark">Do you know?</h2>
                  <hr className="mb-0 bg-dark" />
                  {/* Suggestions */}
                  <ul className="list-unstyled text-dark fs-14">
                    {people_you_know_data && people_you_know_data.results && people_you_know_data.results.length > 0 ? people_you_know_data.results.map(item => {
                      return (
                        <li className="pt-2 border-bottom border-tertiary pb-4" key={item.id}>
                          <div className="mb-3 text-center">
                            <img className="img-circle _120x120" src={item.current_profile_pic} title="" onError={(error) =>
                              (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                            } alt="" />
                          </div>
                          <Link className="text-reset font-weight-bold ff-base" target="_blank" to={`/people/${item.user.username}`}>{item.user.first_name} {item.user.last_name[0]}.</Link>
                          <span className="d-block">Ultra Member</span>
                          <Button className="mt-2 btn-on-focus-none" color="tertiary" block onClick={() => this.sendFriendRequest(item.user.email)}>Add</Button>
                        </li>
                      )
                    }) : (
                      <div className="bg-white p-3">
                        <h2 className="text-secondary-dark">No results found</h2>
                      </div>
                    )}
                  </ul>
                  <div className="text-right mt-2">
                    {people_you_know_data && people_you_know_data.next && (
                      <Button color="link" className="text-dark font-weight-bold ff-base fs-14 p-0" onClick={() => this.handleClickViewMore(people_you_know_data.next)}>view more</Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>


        {/* Footer section right above modal Popup starts */}
        <Footer />

        {/* Upload Media Modal */}
        <Modal
          isOpen={this.state.uploadMediaModal}
          toggle={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal, acceptMimeType: 'image/*,video/*' }, () => { this.clearMediaDispatch() })}
        >
          <ModalHeader toggle={() => { this.handleClosed() }}>UPLOAD MEDIA</ModalHeader>
          <ModalBody className="p-3">
            <Row>
              <Col xs={"auto"}>
                <div className="bg-light h-100 p-3">
                  <Nav tabs className="flex-column active-transparent border-0 m-0">
                    <NavItem className="text-left">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "upload"}
                        onClick={() => {
                          this.setState({ selectedUpMediaType: "upload" })
                        }}
                      >
                        {'Upload'}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "gallery"}
                        onClick={() => {
                          this.setState({ selectedUpMediaType: "gallery" }, () => { this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true }) })
                        }}
                      >
                        {'Gallery'}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col>
                <TabContent activeTab={this.state.selectedUpMediaType}>
                  <TabPane tabId="upload">
                    {selectedFilesPreview && selectedFilesPreview.length == 0 ?
                      <div className="mb-3 type-file-block bg-light">
                        <Input
                          type="file"
                          name="upload_media_file"
                          id="uploadFilesFromSystem"
                          accept={this.state.acceptMimeType}
                          onChange={this.handleOnFileUploadChange}
                          multiple
                        />
                        <Label for="uploadFilesFromSystem">
                          <div className="type-bg"></div>
                        </Label>
                      </div>
                      : null}
                    <div
                      className="text-right"
                      style={{
                        maxWidth: "120px",
                        margin: "0 1rem 1rem auto",
                      }}
                    >
                      <div className="text-center mb-1 small">
                        {parseInt(calculatedPercentage) === 100 ? (
                          <div className="text-success">
                            <FontAwesomeIcon
                              icon="check-circle"
                              className="mr-1"
                            />{" "}
                            {'Uploaded'}
                          </div>
                        ) : (
                          selectedFilesPreview.length > 0 && (
                            <div>
                              {` Uploading `}
                              <span className="text-success font-weight-bold ff-base">
                                {calculatedPercentage ? `${parseInt(calculatedPercentage)} %` : ''}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                      {selectedFilesPreview.length > 0 && (
                        <Progress
                          value={parseInt(calculatedPercentage)}
                          style={{ height: "8px" }}
                        />
                      )}
                    </div>

                    <Row>
                      {selectedFilesPreview && selectedFilesPreview.length > 0 ?
                        selectedFilesPreview.map((item, index) => (
                          <Col className="mb-3" xs="auto" key={index}>
                            <div className="text-center bg-light p-2">
                              <div className="d-inline-flex pr-3">
                                <div>
                                  <div
                                    className="selectable-media"
                                    style={{ cursor: "default" }}>
                                    <div className="gallery-media media-1">
                                      <img
                                        src={item}
                                        onError={(error => {
                                          error.target.src = require('../../assets/images/icons/video-icon.png')
                                        })}
                                        alt={`selected-${index}`}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="mx-n3 mt-2">
                                  <Button color="delete-media _alt" size="sm" title="Remove Media"
                                    onClick={(e) => {
                                      if (latestUploadedData[index] &&
                                        latestUploadedData[index]['isUpload'] &&
                                        latestUploadedData[index]['isUpload'] &&
                                        latestUploadedData[index]['isUpload'] == true) {
                                        this.removeSelectedImage(e, index)
                                      }
                                    }}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                </div>
                              </div>
                              {latestUploadedData &&
                                latestUploadedData[index] &&
                                (latestUploadedData[index]['isUpload'] !== undefined || latestUploadedData[index]['isUpload'] !== null) ?
                                latestUploadedData[index]['isUpload'] ? (
                                  <div className="mt-2">
                                    {this.isUploadingTrue(false)}
                                    <InputGroup style={{ maxWidth: '120px' }}>
                                      <Input bsSize="sm" type="text" name="caption" className="caption-input" placeholder="Caption" onBlur={(e) => this.handleCaptionByIndex(e, index)} />
                                    </InputGroup>
                                  </div>
                                ) : (
                                  <div className="mt-2 text-center">
                                    <small className="text-danger">Error while uploading</small>
                                  </div>
                                ) : (
                                  <div className="mt-2 text-center">
                                    {this.isUploadingTrue(true)}
                                    <small className="text-info">Uploading...</small>
                                  </div>
                                )}

                            </div>
                          </Col>
                        ))
                        : null}
                    </Row>

                    {this.state.uploadError &&
                      <div className="alert alert-danger uploadError">{this.state.uploadError}</div>
                    }
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => { this.handleClosed() }}>
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          disabled={this.checksUploadDisable}
                          onClick={() => { this.handleCloseAndFetch() }}>
                          OK
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="gallery">
                    <div className="mb-2">
                      <Nav tabs className="d-inline-flex mb-0">
                        <NavItem>
                          <NavLink href="#"
                            active={this.state.showGalleryType === "images"}
                            onClick={() => {
                              this.setState({ showGalleryType: "images", fetchGalleryType: 'image', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true })
                              })
                            }}
                          >Image Gallery
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink href="#"
                            active={this.state.showGalleryType === "videos"}
                            onClick={() => {
                              this.setState({ showGalleryType: "videos", fetchGalleryType: 'video', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                this.props.media_upload_scroll({ type: 'video', album: '', page: 1, force_update: true })
                              })
                            }}
                          >Video Gallery
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.showGalleryType}>
                      <TabPane tabId="images">
                        <div>
                          <FormGroup className="form-row justify-content-end">
                            <Col xs="auto">
                              <Label className="small" for="selectAlbumType">Album Type:</Label>
                            </Col>
                            <Col xs="auto">
                              <Input onChange={this.handleChangeTaxonomy} bsSize="sm" type="select" name="album_type_select" id="selectAlbumType" defaultValue="all">
                                <option value="all">All</option>
                                {fetchTaxonomy && Array.isArray(fetchTaxonomy) && fetchTaxonomy.length > 0 ?
                                  fetchTaxonomy.map((item, index) => (
                                    <option key={index} value={item.category}>{item.label}</option>
                                  ))
                                  : null}
                              </Input>
                            </Col>
                          </FormGroup>
                        </div>
                        <div
                          ref={this.refGalleryImageScroll}
                          onScroll={this.handleScrollGalleryImage}
                          className="px-3 my-3"
                          style={{ maxHeight: '350px', overflow: 'auto' }}>
                          <Row>
                            {this.fetchScrollPhotoMedia()}
                          </Row>
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                              {'Cancel'}
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                              {'Ok'}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="videos">
                        <div
                          ref={this.refGalleryVideoScroll}
                          onScroll={this.handleScrollGalleryVideo}
                          className="px-3 my-3"
                          style={{ maxHeight: '350px', overflow: 'auto' }}>
                          <Row>
                            {this.fetchScrollPhotoMedia(true)}
                          </Row>
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button color="primary" size="sm" className="mw" onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>Cancel</Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                              {'Ok'}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        {/* View Media Modal */}
        <Modal
          centered
          isOpen={this.state.viewMyPhotosModal}
          toggle={() => {
            this.setState({
              viewMyPhotosModal: !this.state.viewMyPhotosModal
            }, () => {
              if (!this.state.viewMyPhotosModal) {
                clearTimeout(this.setTimeOutSlider);
              }
            });
          }}>
          {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
          <ModalBody className="bg-transparent p-0">
            <Carousel
              ref={(el) => (this.Carousel = el)}
              responsive={responsive.videoModal}
              infinite={true}
              autoPlay={false}
              swipeable={true}
              showDots={false}
              arrows={true}
              activeIndex={this.state.activeMyPhotoIndex}
              sliderClass={`${fetchPhotos?.results?.length === 0}` ? "mx-auto" : ""}>
              {this.myPhotoSlides()}
            </Carousel>
            {this.state.viewMyPhotosModal && (
              this.setTimeOutSlider = setTimeout(() => {
                if (this?.Carousel?.goToSlide) {
                  let index = this.state.activeMyPhotoIndex;
                  this.Carousel.goToSlide(index + 2, true);
                }
              }, 100)
            )}
          </ModalBody>
        </Modal>


        {/* Delete My Photos Modal Popup */}
        <Modal
          scrollable={true}
          isOpen={this.state.deleteMyPhotosModal}
          toggle={() => this.setState({ deleteMyPhotosModal: !this.state.deleteMyPhotosModal }, () => { this.handleDeleteAfter() })}>
          <ModalHeader toggle={() => this.setState({ deleteMyPhotosModal: !this.state.deleteMyPhotosModal }, () => { this.handleDeleteAfter() })}>
            {'DELETE PHOTOS'}
          </ModalHeader>
          <ModalBody>
            <h4 className="mb-3">Select Photos</h4>
            <span style={{ color: 'red' }}>{this.state.selectPhotoError}</span>
            <div className="render-delete-images"
              ref={this.refScrollDeleteImages}
              onScroll={this.handleScrollDeleteImages}>
              <Row>
                {this.renderDeletePhotos()}
              </Row>
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" className="mw" onClick={() => {
              this.handleDeletePhotos()
            }}>Delete</Button>
            <Button color="primary" className="mw" onClick={() => this.setState({ deleteMyPhotosModal: !this.state.deleteMyPhotosModal }, () => { this.handleDeleteAfter() })}>Cancel</Button>
          </ModalFooter>
        </Modal>

        {/* Delete Photos Confirmation Modal */}
        <Modal size="sm" isOpen={this.state.confirmDeleteMyPhotos} toggle={() => this.setState({ confirmDeleteMyPhotos: !this.state.confirmDeleteMyPhotos })} modalClassName="backdrop-opaque top-shifted transition-none">
          <ModalHeader toggle={() => this.setState({ confirmDeleteMyPhotos: !this.state.confirmDeleteMyPhotos })}></ModalHeader>
          <ModalBody className="text-center text-dark">
            <h2 className="mb-3">Confirmation</h2>
            <p className="small">Are you sure you want to delete the photo(s)?</p>

            <div className="pt-4">
              <div>
                <Button onClick={() => this.setState({ deleteMyPhotosModal: false, confirmDeleteMyPhotos: false }, () => { this.handleDeleteAfter() })} size="md" color="primary btn-confirm">Cancel</Button>
                <Button size="md" color="primary btn-confirm" onClick={() => {
                  this.setState({ deleteMyPhotosModal: false, confirmDeleteMyPhotos: false }, () => {
                    this.props.delete_mutiple_media({ type: 'image', data: this.state.dataWantsToDelete });
                    this.handleDeleteAfter();
                  });
                }}>Yes</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Delete My Videos Modal Popup */}
        <Modal
          scrollable={true}
          isOpen={this.state.deleteMyVideosModal}
          toggle={() => this.setState({ deleteMyVideosModal: !this.state.deleteMyVideosModal }, () => { this.handleDeleteAfter() })}
        >
          <ModalHeader toggle={() => this.setState({ deleteMyVideosModal: !this.state.deleteMyVideosModal }, () => { this.handleDeleteAfter() })}>
            {'DELETE VIDEOS'}
          </ModalHeader>
          <ModalBody>
            <h4 className="mb-3">Select Videos</h4>
            <span style={{ color: 'red' }}>{this.state.selectVideoError}</span>
            <div className="render-delete-videos"
              ref={this.refScrollDeleteVideos}
              onScroll={this.handleScrollDeleteVideos}>
              <Row>
                {this.renderDeletePhotos(true)}
              </Row>

            </div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" className="mw" onClick={() => { this.handleDeleteVides() }}>Delete</Button>
            <Button color="primary" className="mw" onClick={() => this.setState({ deleteMyVideosModal: false }, () => { this.handleDeleteAfter() })}>Cancel</Button>
          </ModalFooter>
        </Modal>

        {/* Delete Videos Confirmation Modal */}
        <Modal size="sm" isOpen={this.state.confirmDeleteMyVideos} toggle={() => this.setState({ confirmDeleteMyVideos: !this.state.confirmDeleteMyVideos })} modalClassName="backdrop-opaque top-shifted transition-none">
          <ModalHeader toggle={() => this.setState({ confirmDeleteMyVideos: !this.state.confirmDeleteMyVideos })}></ModalHeader>
          <ModalBody className="text-center text-dark">
            <h2 className="mb-3">Confirmation</h2>
            <p className="small">Are you sure you want to delete the video(s)?</p>

            <div className="pt-4">
              <div>
                <Button onClick={() => this.setState({ deleteMyVideosModal: false, confirmDeleteMyVideos: false }, () => { this.handleDeleteAfter() })} size="md" color="primary btn-confirm">Cancel</Button>
                <Button size="md" color="primary btn-confirm" onClick={() => this.setState({ deleteMyVideosModal: false, confirmDeleteMyVideos: false }, () => {
                  this.props.delete_mutiple_media({ type: 'video', data: this.state.dataWantsToDelete });
                  this.handleDeleteAfter();
                })}>Yes</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* View My Videos Modal Popup */}
        <Modal
          size="lg"
          isOpen={this.state.viewMyVideosModal}
          toggle={() => this.setState({ viewMyVideosModal: !this.state.viewMyVideosModal, fetchDeleteCount: 0, fetchDeletedData: {} })}
        >
          <ModalHeader toggle={() => this.setState({ viewMyVideosModal: !this.state.viewMyVideosModal })}></ModalHeader>
          <ModalBody className="bg-transparent p-0">
            <div className="text-center">
              <video width="100%" controls>
                <source src={'https://userdatawikireviews.s3.amazonaws.com/media/content/videoplayback.822e840860e245bf92e0c5b22dca831c8a0d7513.mp4'} type="video/mp4" />
                Your browser does not support HTML video.
              </video>
            </div>
            <div className="fs-14 text-white">
              <div>Uploaded by WikiReviews</div>
              <div>24/3/2020 at 1:29PM</div>
            </div>
          </ModalBody>
        </Modal>

        {/* Delete Review Modal */}
        <Modal size="sm" isOpen={this.state.confirmDeleteReview} toggle={() => this.setState({ confirmDeleteReview: !this.state.confirmDeleteReview })} modalClassName="backdrop-opaque top-shifted transition-none">
          <ModalHeader toggle={() => this.setState({ confirmDeleteReview: !this.state.confirmDeleteReview })}></ModalHeader>
          <ModalBody className="text-center text-dark">
            <h2 className="mb-3">Confirmation</h2>
            <p className="small">Are you sure you want to delete this review?</p>

            <div className="pt-4">
              <div>
                <Button onClick={() => this.setState({ confirmDeleteReview: false })} size="md" color="primary btn-confirm">Cancel</Button>
                <Button size="md" color="primary btn-confirm">Yes</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Edit review Modal */}
        <Modal size="lg" isOpen={this.state.editReviewModalToggle} toggle={() => this.setState({ editReviewModalToggle: !this.state.editReviewModalToggle })}>
          <ModalHeader toggle={() => this.setState({ editReviewModalToggle: !this.state.editReviewModalToggle })}></ModalHeader>
          <ModalBody className="bg-transparent">
            <div className="bg-white p-3 mb-3">
              <h2 className="mb-0 text-muted">You're editing your review for <span className="text-dark">Crowne Plaza Danbury</span></h2>
            </div>

            <div className="bg-white mb-3">
              <CollapseBasic title="Step 1. Star Ratings" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true}>
                <div className="text-dark">
                  Rating Input
                  <div className="text-tertiary">
                    <span className="font-weight-bold">
                      Detailed Ratings
                    </span> (optional)
                  </div>
                  <hr className="bg-light" />

                  <Row className="fs-14 ff-alt">
                    <Col className="mb-2" md={6}>
                      <div>Bed Confort</div>
                      Rating Input
                    </Col>
                    <Col className="mb-2" md={6}>
                      <div>Cleanliness</div>
                      Rating Input
                    </Col>
                    <Col className="mb-2" md={6}>
                      <div>Food Quality</div>
                      Rating Input
                    </Col>
                    <Col className="mb-2" md={6}>
                      <div>Management</div>
                      Rating Input
                    </Col>
                    <Col className="mb-2" md={6}>
                      <div>Room Size</div>
                      Rating Input
                    </Col>
                    <Col className="mb-2" md={6}>
                      <div>Services</div>
                      Rating Input
                    </Col>
                  </Row>
                </div>
              </CollapseBasic>
            </div>

            <div className="bg-white mb-3">
              <CollapseBasic title="Step 2. Review" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true}>
                <div className="text-tertiary fs-14 mb-3">
                  Please provide as much information as you can in your review to be as meaningful as possible to others.
                </div>
                <Nav tabs className="mb-3">
                  <NavItem>
                    <NavLink href="#"
                      active={this.state.viewEditReviewStep === "writeReview"}
                      onClick={() => {
                        this.setState({ viewEditReviewStep: "writeReview" })
                      }}
                    ><span className="font-weight-bold">
                        Write a Review
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#"
                      active={this.state.viewEditReviewStep === "recordVideo"}
                      onClick={() => {
                        this.setState({ viewEditReviewStep: "recordVideo" })
                      }}
                    >or record a
                      <span className="font-weight-bold">
                        &nbsp; Video Review
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent className="mb-3" activeTab={this.state.viewEditReviewStep}>
                  <TabPane tabId="writeReview">
                    <FormGroup className="main-post-formgroup">
                      <div className="input-label-block">
                        <Input
                          className="primary"
                          type="textarea"
                          name="post"
                          rows="4"
                          id="post"
                          placeholder="&nbsp;"
                          defaultValue="hi Google how are you how are you doing are you sleeping or not tell you awake ok"
                        />
                      </div>
                      <div className="characters-length-text">
                        Characters: <span>23</span>
                      </div>
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId="recordVideo">
                    <div className="my-3">
                      <Button color="primary" className="font-weight-bold">
                        Start Recording
                      </Button>
                      <Button color="primary" className="font-weight-bold">
                        Stop Recording
                      </Button>
                      <Button color="primary" className="font-weight-bold">
                        Pause
                      </Button>
                      <Button color="primary" className="font-weight-bold">
                        Resume
                      </Button>
                      <Button color="primary" className="font-weight-bold">
                        Download
                      </Button>
                    </div>

                    <div>
                      <video width="320" height="240" controls>
                        <source src="#" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </TabPane>
                </TabContent>

                <div className="fs-14">
                  <div className="text-dark">
                    <span className="font-weight-bold">
                      Choose Review Categories
                    </span>
                    <span>
                      (Optional)
                    </span>
                  </div>
                  <hr className="bg-light my-2" />
                  <p className="text-tertiary fs-14 mb-2">
                    If your review above contains information about a particular element or elements that you would like to highlight, please check the corresponding boxes below.
                  </p>
                  <Row>
                    <Col xs={6}>
                      <FormGroup check>
                        <Label className="text-primary font-weight-bold" check>
                          <Input type="checkbox" id="checkbox2" />{' '}
                          Bed Comfort
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup check>
                        <Label className="text-primary font-weight-bold" check>
                          <Input type="checkbox" id="checkbox2" />{' '}
                          Cleanliness
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup check>
                        <Label className="text-primary font-weight-bold" check>
                          <Input type="checkbox" id="checkbox2" />{' '}
                          Food Quality
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup check>
                        <Label className="text-primary font-weight-bold" check>
                          <Input type="checkbox" id="checkbox2" />{' '}
                          Management
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup check>
                        <Label className="text-primary font-weight-bold" check>
                          <Input type="checkbox" id="checkbox2" />{' '}
                          Room Size
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup check>
                        <Label className="text-primary font-weight-bold" check>
                          <Input type="checkbox" id="checkbox2" />{' '}
                          Services
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </CollapseBasic>
            </div>

            <div className="bg-white mb-3">
              <CollapseBasic title="Step 3. Upload Images and/or Video (optional)" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true}>
                <p className="text-tertiary fs-14 mb-3">
                  Upload images or video to accompany your review. Additional images/videos can be added later.
                </p>
                {/* Uploaded media shown here */}
                <div className="border mb-3">
                  <Row xs={2} sm={3} md={4} lg={5} noGutters>
                    <Col>
                      <div className="d-flex pr-3 m-2">
                        <div className="project-thumbnail">
                          <img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
                        </div>
                        <div className="mx-n3 mt-2">
                          <Button color="delete-media" size="sm" title="Remove Media"><FontAwesomeIcon icon="trash-alt" /> </Button>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex pr-3 m-2">
                        <div>
                          <img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
                        </div>
                        <div className="mx-n3 mt-2">
                          <Button color="delete-media" size="sm" title="Remove Media"><FontAwesomeIcon icon="trash-alt" /> </Button>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex pr-3 m-2">
                        <div>
                          <img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
                        </div>
                        <div className="mx-n3 mt-2">
                          <Button color="delete-media" size="sm" title="Remove Media"><FontAwesomeIcon icon="trash-alt" /> </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Button color="tertiary reset" className="font-weight-bold">
                  Upload Media
                </Button>
              </CollapseBasic>
            </div>
            <div className="bg-white p-3">
              <div className="py-3">
                <FormGroup check>
                  <Label className="text-tertiary font-weight-bold fs-14 ff-alt" check>
                    <Input type="checkbox" id="checkbox2" />{' '}
                    I adhere to the <a href="#" className="text-dark">Review Tips & Guidelines.</a>
                  </Label>
                </FormGroup>
              </div>
              <div className="text-right">
                <Button onClick={() => this.setState({ editReviewModalToggle: false })} size="lg" color="light" className="mw">Cancel</Button>
                <Button size="lg" color="primary" className="mw">Publish Review</Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {this.state.createReviewModalToggle ? (
          <CreateAReview
            createReviewModalToggle={this.state.createReviewModalToggle}
          />
        ) : (
          ""
        )}

        {/* SEE HOW REVIEW DISPUTES WORK */}
        <Modal
          isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
          toggle={() =>
            this.setState({
              watchHowDisputeReviewWorksModalToggle: !this.state.watchHowDisputeReviewWorksModalToggle
            })
          }
        >
          <ModalHeader
            className="text-uppercase px-0"
            toggle={() =>
              this.setState({
                watchHowDisputeReviewWorksModalToggle: !this.state.watchHowDisputeReviewWorksModalToggle,
              })
            }
          >
            SEE HOW REVIEW DISPUTES WORK
          </ModalHeader>
          <ModalBody className="p-0 bg-transparent">
            <div className="text-center">
              <video controls autoPlay width="100%">
                <source src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </ModalBody>
        </Modal>



        {/* Jury Voting Modal */}
        <Modal
          size="xl"

          className="drawer-modal"
          isOpen={this.state.voteReviewModalToggleTwo}
          // isOpen = {true}
          toggle={() =>
            this.setState({
              voteReviewModalToggleTwo: !this.state.voteReviewModalToggleTwo,
            })
          }
        >
          <form className="d-flex flex-column h-100">

            {/* Dispute review intro design */}
            <div className="row mb-5 form flex-grow-1">
              <div className="col-lg-4">
                <div className="bg-dark text-white text-center h-100 p-3">
                  <div className="fs-24 ff-headings text-uppercase">
                    Please watch the video to see how Review Disputes work
                  </div>

                  <div className="mt-3">
                    <Row className="justify-content-between align-items-center">
                      <Col
                        xs="12"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div className="text-center d-flex align-items-center justify-content-center">
                          <div>
                            <img
                              className="img-fluid"
                              src={require("../../assets/images/icons/star/torquise/fill.png")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div
                          className="video-holder play-icon d-inline-block mx-2"
                          role="button"
                          onClick={() =>
                            this.setState({
                              watchHowDisputeReviewWorksModalToggle: true,
                            })
                          }
                        >
                          <img
                            height="80"
                            src={require("../../assets/images/dispute_jury.webp")}
                          />
                        </div>
                        <div className="text-center d-flex align-items-center justify-content-center">
                          <div>
                            <img
                              className="img-fluid"
                              src={require("../../assets/images/icons/star/torquise/fill.png")}
                              alt=""
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 ml-auto">
                <div className="bg-warning text-dark p-2 fs-14">
                  <div className="d-flex flex-wrap mx-n2">
                    <div className="text-white px-2">
                      <span className="font-weight-normal">
                        {" "}
                        This is the{" "}
                        <span className="font-weight-bold"> review </span> which
                        has been disputed.{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bg-white p-3 fs-14 text-dark">
                  <div className="d-flex mx-n2 mb-2">
                    <div className="px-2">
                      <a href="#" className="text-decoration-none">
                        <img
                          className="img-circle _50x50"
                          src={this.state.reviewAdded && this.state.reviewAdded.created_user_media && this.state.reviewAdded.created_user_media.url}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="px-2 flex-grow-1">
                      <div className="px-2 flex-grow-1">
                        <div className="ff-alt">
                          <a
                            // href={`/people/${this.state.reviewAddedUser}`}
                            className="text-reset ff-base font-weight-bold"
                          >
                            {this.state.reviewAdded && this.state.reviewAdded?.associated_to && this.state.reviewAdded?.associated_to.owner?.first_name} {' '}
                            {this.state.reviewAdded && this.state.reviewAdded?.associated_to && this.state.reviewAdded?.associated_to.owner?.last_name}
                          </a>
                          <span> wrote this review for </span>
                          <Link
                            to={{
                              pathname: `/${this.state.reviewAdded?.associated_to?.content_type?.category?.toLowerCase()}/${this.state.reviewAdded?.associated_to?.slug
                                ? this.state.reviewAdded?.associated_to?.slug
                                : "null"
                                }`,
                              state: { id: this.state.reviewAdded?.associated_to?.content_type?.id },
                            }}
                            className="text-reset font-weight-bold"
                          >
                            {this.state.reviewAdded && this.state.reviewAdded.associated_to && this.state.reviewAdded.associated_to.name}
                          </Link>{" "}
                          and gave it
                          <br />
                          <Ratings
                            ratings={
                              this.state.reviewAdded && this.state.reviewAdded.ratings_sets
                            }
                          />
                          <span> {this.state.reviewAdded && this.state.reviewAdded.review_date_time}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ff-alt my-4">
                    <i className="icon-comma-left"></i>
                    <span className="comment-body-renderer">
                      {" "}
                      {this.state.reviewAdded?.review}
                    </span>
                    <i className="icon-comma-right"></i>
                  </div>
                </div>
              </div>
            </div>


            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        voteReviewModalToggleTwo: !this.state
                          .voteReviewModalToggleTwo,
                      })
                    }
                  >
                    <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                </div>
                <div className="bg-dark text-white fs-14 px-3">
                  <div className="mb-2 pt-2">
                    <div className="d-flex mx-n2">
                      <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                        ,                         <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle
                            className="text-white bg-transparent shadow-none font-weight-bold"
                            color="transparent"
                            size="sm"
                            caret
                          >
                            <span className="pr-2">Disputed Reviews</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem className="fs-14">
                              Disputed Reviews
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="px-2 col-auto">
                        near{" "}
                        <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                      </div>
                    </div>
                  </div>
                  <ul className="list-unstyled d-flex flex-wrap mx-n2">
                    <li className="px-2">
                      <div className="d-flex">
                        <FormGroup>
                          <Input
                            type="select"
                            className="transparent"
                            size="sm"
                          >
                            <option>Businesses</option>
                          </Input>
                        </FormGroup>
                        <div className="ml-2">
                          <span className="text-danger">*</span>
                        </div>
                      </div>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>IT Services</option>
                          <option>Apartment Rentals</option>
                          <option>Automotive</option>
                          <option>Education</option>
                          <option>Beauty and Spas</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Asian</option>
                          <option>European</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Indian</option>
                        </Input>
                      </FormGroup>
                    </li>
                    <li className="px-2">
                      <FormGroup>
                        <Input type="select" className="transparent" size="sm">
                          <option>Select Category</option>
                          <option>Level 6</option>
                        </Input>
                      </FormGroup>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <ModalBody className="p-0 fs-14 text-dark bg-transparent">
              <div className="bg-dark text-white">
                <Row noGutters>
                  <Col xs="auto">
                    <div
                      className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                      style={{ minWidth: "300px" }}
                    >
                      <div>
                        <img
                          src={require("../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                        <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                        <img
                          src={require("../../assets/images/icons/star/white/fill.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                      <Row>
                        <Col>
                          <div className="pr-md-5">
                            {get_dispute_data && get_dispute_data[0] &&
                              get_dispute_data[0].is_business_owner ?
                              <h3 className="text-light">
                                Business Owner Initiated.
                              </h3>
                              :
                              <h3 className="text-light">
                                Community Initiated.
                              </h3>
                            }
                            <div className="fs-14">
                              <div className="text-primary fs-16 font-weight-bold">
                                Step 1. Your vote is needed.
                              </div>
                              <p className="mb-0">
                                Brief description of why you vote your counts
                                and what you'd be voting on. Lorem ipsum, dolor
                                sit amet consectetur adipisicing elit.
                                Dignissimos, non?
                              </p>
                            </div>
                          </div>
                        </Col>
                        {this.state.can_vote ?
                          <Col md="auto" className="align-self-end">
                            <div className="text-light font-weight-bold">
                              Submission time ends in
                            </div>
                            <div className="d-flex mx-n2 flex-nowrap">
                              <div className="px-2">
                                <div className="text-white">
                                  <div className="fs-32 ff-headings">{days}</div>
                                  <div className="font-weight-bold">days</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    {hours}
                                  </div>
                                  <div className="font-weight-normal">hours</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    {minutes}
                                  </div>
                                  <div className="font-weight-normal">
                                    {'minutes'}
                                  </div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    {seconds}
                                  </div>
                                  <div className="font-weight-normal">
                                    seconds
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          :
                          <Col md="auto" className="align-self-end">
                            <div className="text-light font-weight-bold">
                              Submission time has ENDED!
                            </div>
                            <div className="d-flex mx-n2 flex-nowrap">
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings">{days}</div>
                                  <div className="font-weight-normal">days</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    00
                                  </div>
                                  <div className="font-weight-normal">hours</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    00
                                  </div>
                                  <div className="font-weight-normal">
                                    minutes
                                  </div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    00
                                  </div>
                                  <div className="font-weight-normal">
                                    seconds
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        }
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="body-bg px-3 py-3">
                <Row>
                  <Col>
                    In case you don't know the correct address, try looking for
                    it with our search bar.
                  </Col>
                  <Col xs="auto">
                    <div className="d-flex">
                      <Input
                        className="primary"
                        bsSize="sm"
                        type="search"
                        placeholder="Custom Search"
                        style={{ minWidth: "300px" }}
                      ></Input>
                      <Button color="primary" size="sm" className="ml-3">
                        <FontAwesomeIcon icon="search" />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-white px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="font-weight-bold fs-16">
                      Dispute History
                    </div>
                    <ul className="d-flex flex-column list-unstyled">
                      <li className="py-2 border-bottom">
                        <div className="d-flex mx-n1">
                          <div className="px-1">
                            <div className="ff-alt">
                              <Moment format="DD-MM-YYYY">
                                {get_dispute_data &&
                                  get_dispute_data[0] &&
                                  get_dispute_data[0].created_on}
                              </Moment>
                              &nbsp; at &nbsp;
                              <Moment format="HH:MM:SS">
                                {get_dispute_data &&
                                  get_dispute_data[0] &&
                                  get_dispute_data[0].created_on}
                              </Moment>
                            </div>
                          </div>
                          <div className="px-1">
                            <a
                              href="#"
                              className="text-dark ff-base font-weight-bold"
                            >
                              {get_dispute_data && get_dispute_data[0] &&
                                get_dispute_data[0].user_name}
                            </a>
                            &nbsp; disputed as " {get_dispute_data && get_dispute_data[0] &&
                              get_dispute_data[0].flag_name} "
                          </div>
                        </div>
                      </li>

                      {/* repeat this for dispute discussion */}
                      {get_dispute_data && get_dispute_data.map((discussion) => (

                        <>
                          {discussion.child_key_name === 1 ||
                            discussion.child_key_name === '1'
                            ?
                            <li className="py-2 border-bottom">
                              <div className="d-flex mx-n1">
                                <div className="px-1">
                                  <div className="ff-alt">
                                    <Moment format="DD-MM-YYYY">
                                      {discussion &&
                                        discussion.created_on}
                                    </Moment>
                                    &nbsp; at &nbsp;
                                    <Moment format="HH:MM:SS">
                                      {discussion &&
                                        discussion.created_on}
                                    </Moment>
                                  </div>
                                </div>
                                <div className="px-1">
                                  <a
                                    href="#"
                                    className="text-dark ff-base font-weight-bold"
                                  >
                                    {discussion &&
                                      discussion.user_name}
                                  </a>
                                  &nbsp; reverted edits by{" "}
                                  "
                                  {discussion &&
                                    discussion.content_flagged}
                                  "
                                </div>
                              </div>
                            </li>
                            :
                            ''
                          }

                        </>
                      ))}


                    </ul>
                  </Col>
                  <Col md={8}>
                    <div className="mb-3">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 2. Some Copy Line here
                      </div>
                      <div>
                        <div className="bg-light p-2 text-dark mb-2">
                          <div className="text-tertiary">
                            Flagged for
                            <strong> " {get_dispute_data &&
                              get_dispute_data[0] &&
                              get_dispute_data[0].flag_name} " </strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>{get_dispute_data &&
                                      get_dispute_data[0] &&
                                      get_dispute_data[0].user_name}</strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    Created on&nbsp;
                                    <span className="text-reset">
                                      {/* {discussion.created_on} */}
                                      <Moment format="MMM DD,YYYY">
                                        {get_dispute_data && get_dispute_data[0] &&
                                          get_dispute_data[0].created_on}
                                      </Moment>
                                      &nbsp; , &nbsp;
                                      <Moment format="HH:MM">
                                        {get_dispute_data && get_dispute_data[0] && get_dispute_data[0].created_on}
                                      </Moment>
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  " {get_dispute_data &&
                                    get_dispute_data[0] &&
                                    get_dispute_data[0].content_flagged} "
                                </div>
                                <div className="bg-light p-3">
                                  <div className="mb-2">

                                    {get_dispute_data &&
                                      get_dispute_data[0] &&
                                      get_dispute_data[0].media.map((media) =>
                                        <div>
                                          {media.includes('.jpeg') || media.includes('.jpg') || media.includes('.png')
                                            ?
                                            <div className="mb-2">
                                              <img className="img-fluid" src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} alt="" />
                                            </div>
                                            :
                                            <div className="mb-2 d-inline-block">
                                              <div className="d-flex mx-n2">
                                                <div className="px-2">
                                                  <FontAwesomeIcon icon="paperclip" fixedWidth />
                                                </div>
                                                <div className="px-2 col">
                                                  <span className="font-weight-bold text-truncate">
                                                    <a className="text-reset font-weight-bold" href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} rel="noopener noreferrer" target="_blank">{media.toString().split('/')[2]}</a>
                                                  </span>
                                                </div>
                                                <div className="px-2 ml-auto">
                                                  <span className="mr-2">|</span>
                                                  <a href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} rel="noopener noreferrer" target="_blank" className="text-reset">
                                                    <FontAwesomeIcon icon="external-link-alt" />
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      )}
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      {get_dispute_data && get_dispute_data.length > 1 ?
                        <div className="text-tertiary fs-16 font-weight-bold mt-4">
                          Discussion between parties
                        </div>
                        :
                        ''
                      }
                      <hr className="mt-2" />

                      <div>
                        {get_dispute_data && get_dispute_data.length > 1 ?
                          <div className="p-2 text-dark">
                            {get_dispute_data && get_dispute_data.map((discussion) => (
                              <div className="mb-3">
                                {discussion.child_key_name === 1 ||
                                  discussion.child_key_name === '1'
                                  ?
                                  <div className="d-flex mx-n2">
                                    <div className="px-2">
                                      <img
                                        className="img-circle _30x30"
                                        src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="px-2 col">
                                      <div className="mb-3">
                                        <div className="d-flex mx-n2 mb-1">
                                          <div className="px-2 col">
                                            <a href="#" className="text-reset">
                                              <strong>{discussion.user_name}</strong>
                                            </a>
                                            &nbsp;
                                          </div>
                                          <div className="col-auto">
                                            <div className="text-tertiary">
                                              <span className="text-reset">
                                                <Moment format="MMM DD,YYYY">
                                                  {discussion.created_on}
                                                </Moment>
                                                &nbsp; , &nbsp;
                                                <Moment format="HH:MM">
                                                  {discussion.created_on}
                                                </Moment>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          {discussion.content_flagged}
                                        </div>
                                      </div>
                                      <div className="bg-light p-3">
                                        <div className="mb-2">

                                          {discussion && discussion.media.map((media) =>
                                            <div>
                                              {media.includes('.jpeg') || media.includes('.jpg') || media.includes('.png')
                                                ?
                                                <div className="mb-2">
                                                  <img className="img-fluid" src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} alt="" />
                                                </div>
                                                :
                                                <div className="mb-2 d-inline-block">
                                                  <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                      <FontAwesomeIcon icon="paperclip" fixedWidth />
                                                    </div>
                                                    <div className="px-2 col">
                                                      <span className="font-weight-bold text-truncate">
                                                        <a className="text-reset font-weight-bold" href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} rel="noopener noreferrer" target="_blank">{media.toString().split('/')[2]}</a>
                                                      </span>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                      <span className="mr-2">|</span>
                                                      <a href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} rel="noopener noreferrer" target="_blank" className="text-reset">
                                                        <FontAwesomeIcon icon="external-link-alt" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  ''
                                }
                              </div>
                            ))}

                          </div>
                          :
                          ''
                        }
                        <hr />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="bg-light px-3 py-4">
                <Row>
                  <Col md={4}>
                    <div className="bg-dark text-white text-center p-3 bg-vote-alt">
                      <div className="my-2 ff-headings fs-50">CAST</div>
                      <img
                        className="my-2"
                        src={require("../../assets/images/icons/star/white/fill.png")}
                        alt=""
                      />
                      <div className="my-2 ff-headings fs-50">YOUR</div>
                      <img
                        className="my-2"
                        src={require("../../assets/images/icons/star/white/fill.png")}
                        alt=""
                      />
                      <div className="my-2 ff-headings fs-50">VOTE</div>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div>
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 3. Some copy line
                      </div>
                      <div>
                        <div className="bg-white p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" onChange={this.handleChangeDis} value="0" />{" "}
                                  <div className="mb-1">
                                    Fake, remove the review.
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">{per_0}%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      {count_0} votes out of 15
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">

                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                        <div className="bg-white p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check>
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" onChange={this.handleChangeDis} value="1" />{" "}
                                  <div className="mb-1">
                                    Review is valid, leave it.
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">{per_1}%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      {count_1} votes out of 15
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">

                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                        <div className="bg-primary text-white p-3 mb-1">
                          <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 col">
                              <FormGroup className="mb-2" check >
                                <Label
                                  className="text-reset font-weight-normal fs-14 d-block"
                                  check
                                >
                                  <Input type="radio" name="reviewToKeep" onChange={this.handleChangeDis} value="2" />{" "}
                                  <div className="mb-1">
                                    Move to Non-Recommended.
                                  </div>
                                  <div className="d-flex flex-wrap mx-n2 fs-14">
                                    <div className="px-2 mb-2">{per_2}%</div>
                                    <div className="px-2 mb-2 align-self-center">
                                      <Progress
                                        color="tertiary"
                                        value="25"
                                        style={{
                                          height: "6px",
                                          width: "240px",
                                        }}
                                      ></Progress>
                                    </div>
                                    <div className="px-2 mb-2">
                                      {count_2} votes out of 15
                                    </div>
                                  </div>
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="px-2 col-auto">

                            </div>
                          </div>

                          {/* Collapse content */}
                          <div className="pl-3">Collapse content</div>
                        </div>
                      </div>
                    </div>
                    {this.state.already_voted
                      ?
                      <div className="mt-4">
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          You have Already submitted your Vote!
                        </div>
                      </div>
                      :
                      <div className="mt-4">
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          Step 4. Please, tell us why.
                        </div>
                        <div className="text-dark">
                          It would be really helpful for fellow voters to know the
                          reasoning of your decision. It has to be 140 characters
                          long. Some text like that.
                        </div>
                        <div className="text-right text-primary fs-14">65</div>
                        <Input
                          type="textarea"
                          className="primary"
                          rows="3"
                          name="remarks"
                          value={remarks}
                          onChange={this.handleChangeDis}
                          defaultValue="The Info is accurate, I've been there today."
                          placeholder="Please elaborate..."
                        />
                      </div>
                    }
                  </Col>
                </Row>
              </div>
            </ModalBody>
            {this.state.already_voted ?
              ''
              :
              <ModalFooter className="bg-white">
                {this.state.can_vote ?
                  <Button color="primary" onClick={this.handleSubmit}>Submit</Button>
                  :
                  <Button color="primary" disabled>Submit</Button>
                }
                <Button color="link" className="font-weight-bold">
                  Skip
                </Button>
              </ModalFooter>
            }
          </form >
        </Modal >



      </div>
    );
  }
}

const mapState = (state) => ({
  fetch_photos: state.myProfile.fetch_photos,
  fetch_videos: state.myProfile.fetch_videos,
  fetch_taxonomy: state.myProfile.fetch_taxonomy,
  upload_scroll: state.myProfile.upload_scroll,
  upload_media_data: state.myProfile.upload_media_data,
  fetch_deleted_data: state.myProfile.fetch_deleted_data,
  my_profile_info: state.user.people_profile,
  search_theater_info: state.user.search_theater,
  people_you_know_data: state.myProfile.people_you_know_data,
  fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,

  get_juror_data: state.feeds.get_juror_data,
  get_jury_data: state.feeds.get_jury_data,
  get_dispute_data: state.feeds.get_dispute_data,
  get_review_data: state.feeds.get_review_data,
  search_username_info: state.user.search_username,
  my_profile_error: state.user.my_profile_error,
  my_profiles: state.user.my_profile,
  logged_user_profiles: state.user.logged_user_profile,
});

const mapProps = (dispatch) => ({
  my_profile: (id) => dispatch(people_profile(id)),
  my_profile_update: () => dispatch(my_profile_update()),
  fetch_media_data: (type, album, page, user_profile, force_true, user_name) => dispatch(fetch_media_data(type, album, page, user_profile, force_true, user_name)),
  update_my_profile: (data, entity) => dispatch(update_my_profile(data, entity)),
  update_email: (data) => dispatch(update_email(data)),
  fetch_taxonomy_category: () => dispatch(fetch_taxonomy_category()),
  search_theater: (data) => dispatch(search_theater(data)),
  media_upload_scroll: ({ type, album, page, force_update }) => dispatch(media_upload_scroll({ type, album, page, force_update })),
  fetch_delete_media: ({ type, page }) => dispatch(fetch_delete_media({ type, page })),
  upload_media_files: (type, data, index) => dispatch(upload_media_files(type, data, index)),
  upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
  clear_media_dispatch: () => dispatch(clear_media_dispatch()),
  delete_mutiple_media: ({ type, data }) => dispatch(delete_mutiple_media({ type, data })),
  single_delete_from_upload: (id) => dispatch(single_delete_from_upload(id)),
  change_password: (data) => dispatch(change_password(data)),
  get_people_you_know: (page) => dispatch(get_people_you_know(page)),
  send_friend_request: (data) => dispatch(send_friend_request(data)),
  enable_fiend_friends_tab: (data) => dispatch(enable_fiend_friends_tab(data)),
  fetch_taxonomy_list: (category, type) => dispatch(fetch_taxonomy_list(category, type)),
  fetch_sent_friend_req_count: (data) => dispatch(fetch_sent_friend_req_count(data)),

  check_is_juror: () => dispatch(check_is_juror()),
  cast_jury_vote: (params, id) => dispatch(cast_jury_vote(params, id)),
  check_jury_duty: (params) => dispatch(check_jury_duty(params)),
  get_dispute_discussion: (id) => dispatch(get_dispute_discussion(id)),
  get_review_details: (params) => dispatch(get_review_details(params)),
  search_username: (data) => dispatch(search_username(data)),
  update_my_profile_error: (data) => dispatch(update_my_profile_error(data)),
});

export default connect(mapState, mapProps)(People);
