import React, { Component } from "react";
import { Map, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import { Icon } from "leaflet";
import Control from 'react-leaflet-control';
import 'leaflet/dist/leaflet.css';
import osm from "../../atoms/osm-providers"
class LeafletMap extends Component {
 constructor(props) {
  super(props);	
	  this.state = {
		zoom: 12,
		isMapInit: false,
		tolat: 0,
		tolon: 0,
		keyMAP: "",
		travelMode: "DRIVING",
		routes: [],
		toaddress : '',
		address : '',
		currentLat: this.props.currentLocationData ? this.props.currentLocationData.lat : 0 ,
		currentLong: this.props.currentLocationData ? this.props.currentLocationData.lng : 0 ,
		keyMAP: Math.random(),
		movId:0,
		about: null,
		theaterData: null,
		city:"",
		state: ""
	  }
  }
  
 componentWillReceiveProps(nextProps) {
	 if (nextProps.movieDetails) {
			this.setState({
                about: nextProps.movieDetails,
                //currentLat: nextProps.movieDetails && nextProps.movieDetails.location ? nextProps.movieDetails.location.lat : 0,
                //currentLong: nextProps.movieDetails && nextProps.movieDetails.location ? nextProps.movieDetails.location.lng : 0,
             });
        }
		if(nextProps.movieId){
			this.setState({
                movId: nextProps.movieId,
            });
		}
	
  }
  
  componentDidMount() {
	   this.mapCordinatesSet();
    }
  


 mapCordinatesSet = () => {
	 let theaterData = this.state.theaterData;
	 const {theaterDetails} = this.props
		const skater = new Icon({
            iconUrl: "https://stagingdatawikireviews.s3.amazonaws.com/images/map-W.png",
            iconSize: [30, 40]
        });
        return (
            <Map key={this.state.keyMAP} center={{
                lat: (this.state.currentLat),
                lng: (this.state.currentLong)
            }}
                zoom={this.state.zoom}
                onClick={this.onMapClicked}
                style={{ position: 'absolute', zIndex: '0' }}
                boundsOptions={{
                    paddingBottomRight: [250, 0],
                }}
                zoomControl={false}
            >
                <ZoomControl position="bottomright" />

                 <TileLayer
                    url={osm.maptiler.url}
                    attribution={osm.maptiler.attribution}
                    maxZoom={18}
                />

                {theaterDetails && theaterDetails?.results?.theater_details && Array.isArray(theaterDetails?.results?.theater_details) && theaterDetails.results.theater_details.length > 0 ?
                    theaterDetails?.results?.theater_details.map((item, index) => {
                        return (
						
						<Marker
                                position={[
                                    item?.lat,
                                    item?.lon
                                ]}
                                icon={skater}
                                //onClick={this.onMarkerClick}
                                onClick={() => {
                                    this.onMarkerClick(item);
                                }}
                                key={index}
                            >
                                <Popup>{item ? item.name : ""}</Popup>
                            </Marker>
                            )
                    })
                    : (null)}
                <Control position="topright">
                    <button className="leafletbutton"
                        onClick={() => { this.handleOnRestoreMap() }}
                    >
                        Restore Map
				</button>
                </Control>
            </Map>
        )
    }

    handleOnRestoreMap = () => {
        this.setState({ keyMAP: Math.random() })
    }
	
	 // on click marker show name and move scrollbar
    onMarkerClick = (data) => {
        this.setState({
            showingInfoWindow: true,
            selectedId: data.theater_id
        });
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };


  render() {
    return (
     <>
	 {this.mapCordinatesSet()}
	 </>
    );
  }
}

export default LeafletMap;