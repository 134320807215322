import React, { Component } from 'react';
import Chart from "react-google-charts";


export default class UserInfoAvatar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            onShowRatingHover: false
        };
    }
    render() {
        let { item, renderListImagesItem, renderAvatarListingimages, renderListingAwardImages, renderMoreDetailsAward, reviewStats } = this.props;

        if (reviewStats != 'null') {

            let newReviewStatsGraph = reviewStats;
            if (newReviewStatsGraph !== undefined && Array.isArray(newReviewStatsGraph) && newReviewStatsGraph?.length === 10) {
                for (let i = 0; i < newReviewStatsGraph.length; i++) {
                    newReviewStatsGraph[i][0] = parseFloat(newReviewStatsGraph[i][0]).toString();
                    newReviewStatsGraph[i][3] = newReviewStatsGraph[i][1]
                    newReviewStatsGraph[i][4] = `${parseFloat(newReviewStatsGraph[i][0]).toString()} \n Rates Scale : ${newReviewStatsGraph[i][3]}`;

                }
                newReviewStatsGraph.splice(0, 0, ["", "Rates Scale", { role: 'style' }, { role: 'annotation' }, { type: 'string', role: 'tooltip' }]);
            }
            // removed console from here.

        }

        return (

            <div className="superuser-block fs-14 clearfix">
                <div className="user-info avatars">
                    {item?.is_anonymous ?
                        <div className="">
                            <img className="rounded-circle"
                                src={require("../../../assets/images/icons/user-circle.png")}
                                alt="" title="" width="50" height="50" />
                        </div>
                        :
                        <a className={item?.award_statistics?.avatar ? item?.award_statistics?.avatar : ''} href={`/people/${item?.created_by?.user?.username}`}>

                            <img className={`indium rounded-circle d-block ${item?.award_statistics?.avatar ? '' : 'ml-2' }`}
                                //src={"http://userdatawikireviews.s3.amazonaws.com/media/" + item?.created_by?.current_profile_pic?.thumbnail}
                                src={item?.created_by_profile_pic ? item?.created_by_profile_pic : (item?.createdby_current_profile_pic ? item.createdby_current_profile_pic : require("../../../assets/images/icons/user-circle.png"))}
                                onError={(e) => (e.target.src = require("../../../assets/images/icons/user-circle.png"))}
                                alt="" title="" width="50" height="50" />

                            <div className="detail-rating2">
                                <ul className="avatars-listing">
                                    {renderListImagesItem({ title: 'indium' })}
                                    {renderListImagesItem({ title: 'silver' })}
                                    {renderListImagesItem({ title: 'rhenium' })}
                                    {renderListImagesItem({ title: 'palladium' })}
                                    {renderListImagesItem({ title: 'osmium' })}
                                    {renderListImagesItem({ title: 'iridum' })}
                                    {renderListImagesItem({ title: 'ruthenium' })}
                                    {renderListImagesItem({ title: 'gold' })}
                                    {renderListImagesItem({ title: 'platinum' })}
                                    {renderListImagesItem({ title: 'rhodium' })}
                                </ul>
                            </div>
                        </a>}
                    {/* <br /> */}
                    {item?.is_anonymous ?
                        <div className="">
                            <strong className="us ">
                                {/* {item?.created_by?.user?.first_name} {item?.created_by?.user?.last_name} */}
                                {'Anonymous User'}
                            </strong>
                        </div>
                        :
                        <a href={`/people/${item?.created_by?.user?.username}`} className="username text-dark fs-16">
                            <strong className="us ">
                                {item?.created_by?.user?.first_name} {item?.created_by?.user?.last_name}
                            </strong>
                        </a>}
                    <br />                    
                    <div className="mb-2">
                        <span className="username text-lightGrey font-weight-normal">
                            <span>
                                {`${item?.created_by?.present_address?.city || ''}${item?.created_by?.present_address?.city ? ',' : ""} ${item?.created_by?.present_address?.state || ''}`}
                            </span>
                        </span> 
                    </div>
                    <strong className="us">
                        {item?.created_by?.user?.is_superuser == true ? "Super User" : ""}
                    </strong>
                    <div style={{ clear: 'both' }}></div>

                    {item.is_anonymous ? "" :
                        <>
                         {item?.award_statistics?.last_month_leader?.gems?.[0] ?
                            <span
                                className="gems"
                                hidden={item?.award_statistics?.last_month_leader?.gems && item?.is_anonymous ? true : false}
                            >
                               
                                <a href="#">
                                    <img
                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/awards/${item?.award_statistics?.last_month_leader?.gems?.[0]}.png`}
                                        alt={`${item?.award_statistics?.last_month_leader?.gems?.[0] ? item?.award_statistics?.last_month_leader?.gems?.[0] : ''} ${item?.award_statistics?.last_month_leader?.gems?.[2] ? item?.award_statistics?.last_month_leader?.gems?.[2] : ''}`}
                                        title={`${item?.award_statistics?.last_month_leader?.gems?.[0] ? item?.award_statistics?.last_month_leader?.gems?.[0] : ''} ${item?.award_statistics?.last_month_leader?.gems?.[2] ? item?.award_statistics?.last_month_leader?.gems?.[2] : ''}`} />
                                </a>
                             
                               <small className="count ">
                               {item?.award_statistics?.last_month_leader?.gems
                                   ? item?.award_statistics?.last_month_leader?.gems[1]
                                   : 0}
                           </small>
                                   
                                <div className="detail-rating-gems" >
                                    {'Rarest Gem Awards for Highest Number of Contributions in One Month.'}
                                    <ul className="avatars-listing">
                                        {[...Array(10).keys()].map((i) => (renderAvatarListingimages({ item, active: (i + 1) })))}
                                    </ul>
                                </div>
                            </span>
                            : ''}
                             {item?.award_statistics?.last_month_leader?.planets?.[0] ?
                            <span
                                className="planets-right "
                                hidden={item?.award_statistics?.last_month_leader?.planets && item?.is_anonymous ? true : false}>
                                
                               
                                <a href="#">
                                    <img
                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/awards/${item?.award_statistics?.last_month_leader?.planets?.[0]}.png`} 
                                        alt={`${item?.award_statistics?.last_month_leader?.planets?.[0] ? item?.award_statistics?.last_month_leader?.planets?.[0] : ''} ${item?.award_statistics?.last_month_leader?.planets?.[2] ? item?.award_statistics?.last_month_leader?.planets?.[2] : ''}`}
                                        title={`${item?.award_statistics?.last_month_leader?.planets?.[0] ? item?.award_statistics?.last_month_leader?.planets?.[0] : ''} ${item?.award_statistics?.last_month_leader?.planets?.[2] ? item?.award_statistics?.last_month_leader?.planets?.[2] : ''}`} />
                                </a>
                                <small className="count ">{item?.award_statistics?.last_month_leader?.planets
                                    ? item?.award_statistics?.last_month_leader?.planets[1]
                                    : 0}</small>

                                    
                                    <div className="detail-rating-planets">
                                        {'Brightest Star Awards for Highest Number of Reviews Written in One Month.'}
                                        <ul className="avatars-listing">
                                            {[...Array(10).keys()].map((i) => (renderListingAwardImages({ item, active: (i + 1) })))}
                                        </ul>
                                    </div>
                            </span>
                            : ''}
                        </>
                    }

                </div>

                {/* More details */}
                <div>
                    <div>
                        <div
                            className="review-count text-dark ff-alt mb-2"
                            hidden={item.is_anonymous ? true : false}>
                            <span className="font-weight-bold text-dark">{item?.created_review_user_count || ''}</span>
                            <span>{` Reviews `}</span>
                        </div>
                    </div>
                    {/* this.props.listing_type == "business"  */}
                    {reviewStats && (

                        <div
                            className="hoverable d-inline-block"
                            onMouseOver={() => this.setState({ onShowRatingHover: true })}
                        // hidden={item.is_anonymous ? true : false}
                        >
                            <div className='mb-2'><span
                                hidden={item.is_anonymous ? true : false}
                                className="font-weight-bold fs-14 ff-alt"
                                role="button">
                                {"Show Rating"}
                            </span></div>                            {
                                this.state.onShowRatingHover &&
                                (<div className="on-hover-appear rating-graph-appear">
                                    <div>
                                        <div className="text-center fs-14 text-secondary-dark">Star Rating Distribution</div>
                                        <Chart
                                            width={'100%'}
                                            height={'300px'}
                                            chartType="ColumnChart"
                                            loader={<div>Loading Chart</div>}
                                            data={reviewStats !== null ? reviewStats : []}
                                            options={{
                                                title: null,
                                                legend: 'none',
                                                titleTextStyle: {
                                                    fontName: 'museo_sans500',
                                                    fontSize: 14,
                                                    color: '#716646',
                                                    bold: false
                                                },
                                                vAxis: { minValue: 0, viewWindow: { min: 0 }, title: 'Reviews', titleTextStyle: { italic: false, color: '#053061', fontName: "Arial", fontSize: "14", bold: true } },
                                                hAxis: { title: 'Distribution', titleTextStyle: { italic: false, color: '#053061', fontName: "Arial", fontSize: "14", bold: true } },
                                                seriesType: 'bars',
                                                annotations: {
                                                    textStyle: {
                                                        fontSize: 12
                                                    }
                                                }
                                            }}
                                            rootProps={{ 'data-testid': '1' }}
                                        />
                                    </div>
                                </div>)
                            }
                        </div>
                    )}
                    

                    <div style={{ clear: 'both' }}></div>

                    <ul
                        className="by-country d-flex flex-wrap mx-n1 clearfix"
                        hidden={item.is_anonymous ? true : false}>
                        {item?.award_statistics?.all_badges &&
                            Object.keys(item?.award_statistics?.all_badges).length ?
                            Object.keys(item?.award_statistics?.all_badges).map(item_award => {
                                let value = item?.award_statistics?.all_badges[item_award];
                                return renderMoreDetailsAward({ title: item_award, count: value[0] })
                            })
                            : null}
                    </ul>
                </div>
            </div>
        )
    }
}
