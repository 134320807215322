/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import YearPicker from 'react-single-year-picker'
// import YearPicker from "react-year-picker";
import { Button, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane,UncontrolledPopover, PopoverBody  } from 'reactstrap';
import { add_hours_of_operations, add_media_to_gallery, add_menu, get_media_children, get_menu_item, get_section_menu_item, update_business_detail, update_payment_options } from '../../../actions/business';
import { add_social_website, delete_listing_Info, update_detail,add_media_to_item_gallery } from '../../../actions/itemDetail';
import { delete_selected_gallery_media, get_album_types_list, get_album_type_data } from "../../../actions/user";
import { callApi } from '../../../utils/apiCaller';
import { websiteRegex } from '../../../utils/validation';
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from "../../atoms/editBtn";
import { getReviewSuffix } from '../../../utils/utilities';
//const aplhaRegex = /^[a-z\d\-_\s]+$/i;
const aplhaRegex = /^[A-Za-z_ ]*[A-Za-z][A-Za-z_ ]*$/;

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            about: null,
			durationError:"",
            tempIcon: null,
            editName: false,
            phoneSet: null,
            editPhoneNumber: false,
            editPhoneNumberLabel: false,
            setSocialMediaLink: false,
            changeSocialLink: false,
            socialSiteID: null,
            emailSet: null,
            addEmail: false,
            editEmail: false,
            websiteSet: [],
            addWebsite: false,
            editWebsite: false,
            socialLinkType: "Facebook",
            phoneLabel: 'Tel',
            editProfileName: null,
            editProfileNameIsValid: false,
            phone: null,
            phoneIsValid: false,
            phoneLabelIsValid: false,
            newEmail: null,
            newEmailIsValid: false,
            email: null,
            emailIsValid: false,
            newWebsite: null,
            newWebsiteIsValid: false,
            editSocialLink: null,
            editSocialLinkIsValid: false,
            socialLink: null,
            socialLinkIsValid: false,
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            fetchGalleryAlbum: "",
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            uploadMediaPostModal: false,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            albumType: '',
            pageNo: 1,
            readMore: false,
            oldstoryline: "",
            storyline: "",
            addstoryData: false,
            editstoryData: false,
            durationData: "",
            duration: "",
            oldduration: "",
            addDuration: false,
            editDuration: false,
            ratingData: "",
            rating: "",
            oldrating: "",
            addRating: false,
            editRating: false,
            movId: 0,
            storyData: "",
            editSoryError: false,
            editYear: false,
            oldYear: "",
            currentYear: "",
            uploadedFiles: [],
            uploadUrl: `/upload/multiuploader/?album=&instance=listing_images&image=undefined&doc=undefined`,
			addYear:false,
			addDirector:false,
			newDirector: null,
            newDirectorIsValid: false,
			addWriter:false,
			newWriter: null,
            newWriterIsValid: false,
			addProducer:false,
			newProducer: null,
            newProducerIsValid: false,
			addCast:false,
			newCast: null,
            newCastIsValid: false,
			directorError : "",
			producerError:"",
			writerError : "",
			castError : "",
			validSocialUrl : "",
			editvalidSocialUrl:""

        }
        this.refGalleryVideoScroll = React.createRef();
        this.refGalleryImageScroll = React.createRef();
    }


    async componentWillReceiveProps(nextProps) {
        const { aboutDetails, movieId } = nextProps;
        let { fetchGalleryScroll, fetchGalleryCount,about } = this.state;
        if (aboutDetails) {
            this.setState({
                about: aboutDetails,
                phoneSet: aboutDetails?.phone_set,
                emailSet: aboutDetails?.email_set,
                websiteSet: aboutDetails?.website_set,
                storyData: aboutDetails?.additional_info.storyline ? aboutDetails.additional_info.storyline : "",
                oldstoryline: aboutDetails?.additional_info.storyline ? aboutDetails.additional_info.storyline[0].value : "",
                storyline: aboutDetails?.additional_info.storyline ? aboutDetails.additional_info.storyline[0].value : "",
                duration: aboutDetails?.additional_info.duration ? aboutDetails.additional_info.duration[0].value : "",
                oldduration: aboutDetails?.additional_info.duration ? aboutDetails.additional_info.duration[0].value : "",
                durationData: aboutDetails?.additional_info.duration ? aboutDetails.additional_info.duration : "",
                ratingData: aboutDetails?.rating ? aboutDetails.rating : "",
                rating: aboutDetails?.rating ? aboutDetails.rating[0].value : "",
                oldrating: aboutDetails?.rating ? aboutDetails.rating[0].value : "",
                oldYear: aboutDetails?.additional_info.year ? aboutDetails.additional_info.year[0].value : "",
                currentYear: aboutDetails?.additional_info.year ? aboutDetails.additional_info.year[0].value : "",
            },()=>{

                const socialArray = ["facebook", "twitter", "instagram"]
                if(!!aboutDetails?.website_set){
                    for (let items of socialArray) {
                        if(!aboutDetails?.website_set.some(item=>item.website_type?.toLowerCase()===items)){
                            this.setState({socialLinkType:items})
                            break;
                        }
                      }
                }
                

                             
                
            });
        }
        if (movieId) {
            this.setState({
                movId: movieId,
            });
        }
        if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
            this.setState({
                // ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypesList: nextProps.album_types_list,
                },
            });
        }

        if (
            nextProps.album_type_data &&
            Object.keys(nextProps.album_type_data).length > 0
        ) {
            let item = nextProps.album_type_data;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypeData: nextProps.album_type_data,
                },
                fetchGalleryScroll, fetchGalleryCount,
            });
        }

        let next_uploaded_files = nextProps?.uploadedMediaSet?.uploadedFiles?.length;
        let uploadedFiles = this.state.uploadedFiles;

        if (next_uploaded_files !== uploadedFiles?.length) {
            this.setState({ uploadedFiles: nextProps?.uploadedMediaSet?.uploadedFiles });
        }
		
		if(nextProps.callGalleryFunction !== this.props.callGalleryFunction && nextProps.callGalleryFunction ){
            this.handleAddMediaToGallery()
            this.props.resetGalleryFunctionToggle();
        }
    }


    handleSocialLinkChange = async (evt, data, type) => {
        const name = evt.target.name
        const URL = evt.target.value
        let urlParts = URL.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
        let wwwUrlParts = URL.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
        let arr = urlParts.split(".");
        let arrWWWIncluded = wwwUrlParts.split(".");
        let wwwInDomain = arrWWWIncluded[0];
        let domainName = arr[0];
        let domainType = type.toLowerCase();
        if (data) {
			await this.setState({
                // ...this.state,
                [name]: evt.target.value,
                //[data]: evt.target.validity.valid && domainName === domainType && wwwInDomain === 'www',
                [data]: evt.target.validity.valid && domainName === domainType,
                validSocialUrl: "",
            });
        }
        else {
            await this.setState({
                [evt.target.name]: evt.target.value,
				validSocialUrl: "",
            });
        }
    }

    handleSocialLinkType = async (evt, data, SocialURL) => {

		const name = evt.target.name
        const URLType = evt.target.value
        const URL = SocialURL ? SocialURL : '';
        let urlParts = URL.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
        let arr = urlParts.split(".");
        let domainName = arr[0];
        let domainType = URLType.toLowerCase();
        if (data) {
            await this.setState({
                // ...this.state,
                [name]: evt.target.value,
                [data]: evt.target.validity.valid && domainName === domainType,
            });
        }
        else {
            await this.setState({
                [evt.target.name]: evt.target.value,
            });
        }
    }


    handleFieldsChange = (type, data) => {

        const { phoneSet, about, emailSet, oldstoryline, movId, oldrating, oldduration } = this.state
        
        if (type === 'phone') {
           if (phoneSet.length > 0) {
					const phoneDetails = phoneSet[0]
					const phoneData = {}
					phoneData.phone_set = {};
					phoneData.phone_set.phone = data;
					phoneData.phone_set.id = phoneDetails.id;
					phoneData.phone_set.oldValue = phoneDetails.phone;
					
					let types = "products";
					this.props.update_detail(types, movId, phoneData, 'Mobile number updated successfully')
				}else{
					
					const phoneData = {}
					phoneData.phone_set = {};
					phoneData.phone_set.phone = data;
					let types = "products";
					this.props.update_detail(types, movId, phoneData, 'Mobile number added successfully')
				}
		}
        else if (type === 'editProfileName') {
            const name = {
                "name": data,
            }
            this.props.update_business_detail(about.id, name, 'Name updated successfully');
            
        }
        else if (type === 'phoneLabel') {
            const phoneDetails = phoneSet[0]
            const phone_type = {
                "label": data,
                "id": phoneDetails.id,
                "oldValue": phoneDetails.label
            }
            this.props.update_business_detail(about.id, { phone_type }, 'Mobile label updated successfully');
        }
        else if (type === 'addEmail') {
            const email_set = {
                "email": data
            }
            this.props.update_business_detail(about.id, { email_set }, 'Email added successfully');
        }else if (type === 'addDirector') {
			/*if(!aplhaRegex.test(data)){
				this.setState({directorError:"Only Alphabets are allowed"})
			}else{*/
            const dataDirector = {
                "directors_set": data,
				"existing" : ""
            }
            let types = "movies";
            this.props.update_detail(types, movId, dataDirector, 'Director added successfully')
			//}
        }else if (type === 'addProducer') {
			/*if(!aplhaRegex.test(data)){
				this.setState({producerError:"Only Alphabets are allowed"})
			}else{*/
            const dataProducer = {
                "producers_set": data,
				"existing" : ""
            }
            let types = "movies";
            this.props.update_detail(types, movId, dataProducer, 'Producer added successfully')
			//}
        }else if (type === 'addWriter') {
			/*if(!aplhaRegex.test(data)){
				this.setState({writerError:"Only Alphabets are allowed"})
			}else{*/
            const dataWriter = {
                "writers_set": data,
				"existing" : ""
            }
            let types = "movies";
            this.props.update_detail(types, movId, dataWriter, 'Writer added successfully')
			//}
        }else if (type === 'addCast') {
			/*if(!aplhaRegex.test(data)){
				this.setState({castError:"Only Alphabets are allowed"})
			}else{*/
			 const dataCast = {
                "cast_set": data,
				"existing" : ""
            }
            let types = "movies";
            this.props.update_detail(types, movId, dataCast, 'Cast added successfully')
			//}
        }
        else if (type === 'addWebsite') {
            const website_set = {
                "website": data,
                "website_type": "Main"
            }
			let types = "movies";
            this.props.update_detail(types,movId, {website_set}, 'Website added successfully');
        }
        else if (type === 'editEmail') {
            const email_set = {
                "email": data,
                "id": emailSet[0].id,
                "oldValue": emailSet[0].email
            }
            this.props.update_business_detail(about.id, { email_set }, 'Email updated successfully');
        }
        else if (type === 'editSocialLink') {
			 let website_setdat = {}
			 website_setdat.website_set = {};
			 website_setdat.website_set.website = data.website;
			 website_setdat.website_set.id = data.id;
			 website_setdat.website_set.oldValue = data.oldValue;
			 website_setdat.website_set.website_type = data.website_type;
            let types = "movies";
			let URL = data.website;
			let urlParts = URL.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
			let wwwUrlParts = URL.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
			let arr = urlParts.split(".");
			let arrWWWIncluded = wwwUrlParts.split(".");
			let wwwInDomain = arrWWWIncluded[0];
			let domainName = arr[0];
			let domainType = data.website_type.toLowerCase();
			if(domainName === domainType && (wwwInDomain === 'www' || domainType==="twitter")){
				this.setState({ changeSocialLink: false, }, () =>
                    this.props.update_detail(types, movId, website_setdat, 'Social Media updated successfully')
                );
			}else{
				this.setState({ editvalidSocialUrl: "Please enter valid social link" });
			}
            
        }
        else if (type === 'socialLink') {
            const socialDetail = {
                "website": data,
                "type": this.state.socialLinkType.charAt(0).toUpperCase() + this.state.socialLinkType.slice(1),
                "listing_id": about.id
            }
            let types = "movies";
			let URL = data;
			let urlParts = URL.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
			let wwwUrlParts = URL.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
			let arr = urlParts.split(".");
			let arrWWWIncluded = wwwUrlParts.split(".");
			let wwwInDomain = arrWWWIncluded[0];
			let domainName = arr[0];
			let domainType = this.state.socialLinkType.toLowerCase();
			if(domainName === domainType && (wwwInDomain === 'www' || domainType==="twitter")){
				this.setState({ socialLink: '',setSocialMediaLink: false, }, () =>
                    this.props.add_social_website(about.id, types, socialDetail)
                );
			}else{
				this.setState({ validSocialUrl: "Please enter valid social link" });
			}
		}
        else if (type === 'addStoryLine') {
            if (data == "") {
                this.setState({ editSoryError: true })
            } else {
                let dataStory = {}
                dataStory.additional_info = {}
                dataStory.additional_info.key = 'storyline';
                dataStory.additional_info.oldVal = oldstoryline;
                dataStory.additional_info.newVal = data;
                let types = "movies";
                this.setState({ editstoryData: false }, () =>
                    this.props.update_detail(types, movId, dataStory, 'Story Line updated successfully')
                );
            }
        } else if (type === 'addDuration') {
			if(data.length > 20){
				this.setState({ durationError: "Data too long" })
			}else{
            let dataDuration = {}
            dataDuration.additional_info = {}
            dataDuration.additional_info.key = 'duration';
            dataDuration.additional_info.oldVal = oldduration;
            dataDuration.additional_info.newVal = data;
            let types = "movies";
            this.props.update_detail(types, movId, dataDuration, 'Duration updated successfully')
			}
            

        } else if (type === 'addRating') {
            let dataRating = {}
            dataRating.additional_info = {}
            dataRating.additional_info.key = 'rating';
            dataRating.additional_info.oldVal = oldrating;
            dataRating.additional_info.newVal = data;
            let types = "movies";
            this.props.update_detail(types, movId, dataRating, 'Rating updated successfully')
            
        }

    }

    handleOnFilePostUploadChange = (event) => {
        let url = this.state.addMenuItemImage ? `/upload/multiuploader/?album=&instance=Add_Menu%20Image` : `/upload/multiuploader/?album=logo&instance=listing_images&image=undefined&doc=undefined`
        let uploadFiles = event.target.files;
        let showFiles = [];
        for (const key of Object.keys(uploadFiles)) {
            showFiles.push({ id: "", url: URL.createObjectURL(uploadFiles[key]) });
        }
        this.setState({
            // ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                uploadFiles: showFiles,
                progress: 0,
            },
        });
        let progressPart = 100 / showFiles.length;
        let progress = 0;
        for (const key of Object.keys(uploadFiles)) {
            let data = new FormData();
            data.append("file", uploadFiles[key]);

            callApi(
                url,
                "POST",
                data,
                true
            ).then((response) => {
                this.handleOnClickSelectGalleryMedia(response);
                if (showFiles.length === 1 || key === showFiles.length - 1) {
                    progress = 100;
                } else {
                    progress = progress + progressPart;
                }
                showFiles[key].id = response.id;
                this.setState({
                    // ...this.state,
                    uploadMedia: {
                        ...this.state.uploadMedia,
                        progress: progress,
                        uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
                        uploadedFiles: showFiles,
                    },
                });
            });
        }
    };

    handleOnClickRemoveSelectedMedia = (id) => () => {
        let removeMediaIds = [];
        removeMediaIds.push(id);
        this.props.delete_selected_gallery_media(removeMediaIds);
        let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
            (file) => file.id !== id
        );
        let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
            (file) => file.id !== id
        );
        let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
            (item) => item !== id
        );
        let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
            (file) => file.id !== id
        );
        this.setState({
            // ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                selectedMedia: selectedMedia,
                selectedMediaIds: selectedMediaIds,
                uploadedFiles: uploadedFiles,
                uploadFiles: uploadFiles,
            },
        });
    };
	
	handleAddMediaToGallery = async () => {
        let multiuploaderfile = [...this.state.uploadedFiles].map(item => item.id);

        let url = `/upload/list/all/?category=&media_type=&page=1&listing=${this.props.productDetails.id}`;
        let data = { type: "media", entries: this.state.movId, userentries_id: this.state.movId, taxonomy_id: "", multiuploaderfile: multiuploaderfile, galleryType: 'movies' };
        if (this.state.uploadedFiles.length > 0) {
		    let types = "movies";	
            await this.props.add_media_to_item_gallery(data,types,this.state.movId);
            await this.setState({
                uploadMediaPostModal: false,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    uploadFiles: [],
                    uploadedFiles: []
                },
                uploadedFiles: []
            }, () => {
                
            });

        }
    }

    handleOnClickRemoveSelectedGalleryMedia = (media) => {
        let removeMediaIds = [];
        removeMediaIds.push(media.id);
        let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        if (index !== -1) {
            newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
                (item) => item.id !== media.id
            );
            this.props.delete_selected_gallery_media(removeMediaIds);
            this.setState({
                // ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: newSelectedMedia,
                    selectedMediaIds: newSelectedMediaIds,
                },
            });
        }
    };

    handleOnClickSelectGalleryMedia = (media) => {
        let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        if (index !== -1) {
            newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
                (item) => item.id !== media.id
            );
        } else {
            newSelectedMediaIds = [
                ...this.state.uploadMedia.selectedMediaIds,
                media.id,
            ];
            newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
        }
        this.setState({
            // ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                selectedMedia: newSelectedMedia,
                selectedMediaIds: newSelectedMediaIds,
            },
        });
    };

    handleOnClickGalleryType = (type) => {
        let mediaType = "";
        if (type === "images") {
            mediaType = "image";
        }

        if (type === "videos") {
            mediaType = "video";
        }

        this.props.get_album_type_data(mediaType, "", 1);
        this.setState({
            // ...this.state,
            selectedUpMediaType: "gallery",
            showGalleryType: type,
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: mediaType,
                albumType: "",
                pageNo: 1,
            },
        });
    };

    handleOnClickAlbumTypeChange = (e) => {
        this.props.get_album_type_data("image", e.target.value, 1);
        this.setState({
            // ...this.state,
            selectedUpMediaType: "gallery",
            showGalleryType: "images",
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: "image",
                albumType: e.target.value,
                pageNo: 1,
            },
        });
    };

    handleOnClickSelectedUploadMediaType = (type) => {
        if (type === "gallery") {
            // 
            this.setState({
                // ...this.state,
                selectedUpMediaType: type,
                showGalleryType: "images",
                uploadMedia: {
                    ...this.state.uploadMedia,
                    mediaType: "image",
                    albumType: "",
                    pageNo: 1,
                    embedLinks: {},
                    progress: 0,
                },
            }, () => {
                this.props.get_album_type_data("image", "", 1);
            });
        }

        if (type === "upload") {
            this.setState({
                // ...this.state,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    embedLinks: {},
                    progress: 0,
                },
            });
        }

        if (type === "embed") {
            let embedLinks = {
                0: "",
                1: "",
            };
            this.setState({
                // ...this.state,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    embedLinks: embedLinks,
                },
            });
        }
    };

    handleOnClickUploadMedia = (type) => {
        if (type === 'addmenuItem') {
            this.setState({
                addMenuItemImage: true
            })
        }
        this.props.get_album_types_list();
        this.setState({
            // ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                progress: 0,
                uploadFiles: [],
                uploadedFiles: [],
            },
            uploadMediaPostModal: true,
            selectedUpMediaType: "upload",
            showGalleryType: "images",
        });
    };
	
	allDirectorEqual = (arr) => {
		if(Array.isArray(arr) && arr.length == 1 && arr[0] && arr[0] != false){
			return false;
		}
		else if(Array.isArray(arr) && arr.length > 1){
			return new Set(arr).size == 1;
		}else{
			return true;
		}
	}
	
	allProducerEqual = (arr) => {
		if(Array.isArray(arr) && arr.length == 1 && arr[0] && arr[0] != false){
			return false;
		}
		else if(Array.isArray(arr) && arr.length > 1){
			return new Set(arr).size == 1;
		}else{
			return true;
		}
	}
	
	allWriterEqual = (arr) => {
		if(Array.isArray(arr) && arr.length == 1 && arr[0] && arr[0] != false){
			return false;
		}
		else if(Array.isArray(arr) && arr.length > 1){
			return new Set(arr).size == 1;
		}else{
			return true;
		}
	}
	
	allCastEqual = (arr) => {
		if(Array.isArray(arr) && arr.length == 1 && arr[0] && arr[0] != false){
			return false;
		}
		else if(Array.isArray(arr) && arr.length > 1){
			return new Set(arr).size == 1;
		}else{
			return true;
		}
	}

    truncate = (filenameString) => {
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };

    uploadMediaModalToggle = () => {
        this.setState({
            // ...this.state,
            addMenuItemImage: false,
            uploadMediaPostModal: !this.state.uploadMediaPostModal,
            uploadMedia: {
                ...this.state.uploadMedia,
                selectedMedia: [],
                selectedMediaIds: [],
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                uploadedFiles: [],
                uploadFiles: [],
            },
            selectedUpMediaType: "upload",
            showGalleryType: "images",
        });
    };

    handleScrollGalleryImage = () => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    fetchGalleryOnScroll = ({ type }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;
            if (nextCount <= roundValue) {
                let page = nextCount;
                this.props.get_album_type_data(type, "", page);

            }
        }
    }

    handleScrollGalleryVideo = () => {
        let { refGalleryVideoScroll } = this;
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = this.state;
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0) {
                return getDataItems.map(item => {

                    let itemName = (item?.thumbnail).split('.');
                    let itemFile = (item?.file).split('.');
                    let extName = itemName[itemName.length - 1];
                    let extFile = itemFile[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview

                    return (
                        <Col xs="auto" className="mb-3">
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={() =>
                                        this.handleOnClickSelectGalleryMedia(
                                            item
                                        )
                                    } />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    handleAddMediaToGallery = async () => {
        let data = {
            type: "media",
            entries: this.state.about && this.state.about.id,
            userentries_id: this.state.about && this.state.about.id,
            taxonomy_id: "",
            multiuploaderfile: this.state.uploadMedia.selectedMediaIds
        };
        if (this.state.uploadMedia.selectedMediaIds.length > 0) {
            await this.props.add_media_to_gallery(data);
            await this.setState({
                uploadMediaPostModal: false,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    uploadFiles: [],
                    uploadedFiles: []
                }
            });
        }
    }

     moviesCastList = (type) => {
        let about = this.state.about;
        if (about && about.professional && about.professional.length > 0) {
		let falseIndex = 0;
        let filteredData = about.professional.map((item, index) => {
				if (item?.category === type) {
					falseIndex++; 
                    return falseIndex < 4 &&(
                        <span key={index}> <Link
                      to={{ pathname: `/actors/${item.slug ? item.slug : null}`, state: { id: item.listing } }}
                      className="font-weight-bold">{item.first_name}</Link></span>
                    )
                } else {
                    return false;
                }
            });
			return filteredData;
        }else{
			return false;
			
		}
    }

    handleOnClickReadMore = () => {
        this.setState({ readMore: !this.state.readMore });
    }

    tConvert = (time) => {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    showTime = (data) => {
        if (data && data.length > 0) {
            return data.map((item) => {
                if (data.length > 0) {
                    return (
                        <span>{this.tConvert(item)} | </span>
                    )
                } else {
                    return null;
                }
            })
        }
    }

    handleChange = async (evt, data) => {
		if(evt.target.name == "newDirector"){
			this.setState({directorError:""})
		}
		if(evt.target.name == "newProducer"){
			this.setState({producerError:""})
		}
		if(evt.target.name == "newWriter"){
			this.setState({writerError:""})
		}
		if(evt.target.name == "newCast"){
			this.setState({castError:""})
		}
		
        if (data) {
            await this.setState({
                // ...this.state,
                [evt.target.name]: evt.target.value,
                [data]: evt.target.validity.valid
            });
        }
        else {
            await this.setState({
                [evt.target.name]: evt.target.value,
            });
        }
    }

    handleChangeMovies = async (evt) => {
        if (evt.target.name == "storyline") {
            await this.setState({
                [evt.target.name]: evt.target.value,
                editSoryError: false,
            });
        }if (evt.target.name == "duration") {
            await this.setState({
                [evt.target.name]: evt.target.value,
                durationError: "",
            });
        } else {
            await this.setState({
                [evt.target.name]: evt.target.value,
            });
        }
    }

    handleChangeDate = async (date) => {
        let { oldYear, movId } = this.state;
        let data = {}
        data.additional_info = {}
        data.additional_info.key = 'year';
        data.additional_info.oldVal = oldYear;
        data.additional_info.newVal = date;
        let types = "movies";
        await this.setState({ editYear: false, currentYear: date }, () =>
            this.props.update_detail(types, movId, data, 'Year updated successfully')
        );

    }
	
	handleChangeAddDate = async (date) => {
        let { oldYear, movId } = this.state;
        let data = {}
        data.additional_info = {}
        data.additional_info.key = 'year';
        data.additional_info.oldVal = oldYear;
        data.additional_info.newVal = date;
        let types = "movies";
        await this.setState({ addYear: false, currentYear: date }, () =>
            this.props.update_detail(types, movId, data, 'Year updated successfully')
        );

    }
	
	handleOnClickCategory = (id) => {
	  this.props.handleCategoryChange(id);
	}
	
	getDateDay = (date) => {
        date = new Date(date);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[date.getDay()];
    }

    checkAllNetwork = () =>{
        const social = ["facebook","twitter", "instagram"];
        let result = false;
        for (let items of social) {
            if(this.state.about?.website_set.some(item=>item.website_type?.toLowerCase()===items)){
                result = true;
            }else{
                result = false
                break;
            }
          }

        return result
    }

    render() {
        const {
            about,
            readMore,
            uploadUrl,
			validSocialUrl,
			editvalidSocialUrl,
			currentYear
		} = this.state;
        let avg_rating = about && about.total_rating && about.total_rating.avg_rating[0] ? about.total_rating.avg_rating[0] : 'norating.png';
        return (
            <div className="text-center">
                <Container className="position-relative">
                    <div className={'text-center position-relative about-view ' + `${!this.state.toggleMoreLessInfo ? '_mini' : ''}`}>
                        <div
                            className="blurred-content-bg">
                            <div className="wr-movie-top-about d-flex flex-column">
                                <div className="wr-movie-top-about-body">
                                    <Row>
                                        <Col xs="auto" className="flex-370">
                                            <div className="wr-movie-product-info-top d-flex flex-wrap">
                                                <div className="wr-movie-product-info-top-left position-relative">
                                                    {about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].url && (
                                                        <img
                                                        src={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about?.listing_profileimage[0].url}`}
                                                        className="img-fluid"
                                                        onError={(error) =>
                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                        }
                                                        alt={`${about && about?.listing_profileimage && about?.listing_profileimage.length > 0 && about?.listing_profileimage[0] && about.listing_profileimage[0].caption ? about.listing_profileimage[0].caption : 'no-image'}`}
                                                        />
                                                    )}
                                                    {
                                                        about && about.listing_profileimage && about.listing_profileimage[0] && about.listing_profileimage[0].url ? "" :
                                                            <div
                                                                className="absolute-100-100 flex-center hover-bg-light"
                                                                role="button"
                                                                onClick={() => {
                                                                    this.props.getuploadUrl({ uploadUrl });
                                                                    this.props.toggleEnableFileUpload(false,true,false);
                                                                }}

                                                            >
															<img
															src="https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"
															className="img-fluid"
															alt='no-image'
															hidden
															/>
                                                                <div className="text-center text-darker font-weight-bold fs-14 lh-1-3">
                                                                    {'Upload an Image'}
                                                                </div>
                                                            </div>


                                                    }
                                                </div>
                                                <div className="wr-movie-product-info-top-right">
                                                    <div className="wr-category-heading">
                                                        <h4 className="ff-alt fs-14 font-weight-bold">{about && about.name}</h4>
                                                        <div hidden>
                                                            <InputGroup size="sm">
                                                                <Input type="text" placeholder="Enter Name" />
                                                                <InputGroupAddon addonType="append">
                                                                    <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>
                                                        <p className="fs-14 font-weight-bold">
                                                            {(this.state.durationData == "") || (this.state.durationData && this.state.durationData.length === 0) ?
                                                                this.state.addDuration === false ?
                                                                    <span className="fs-14 font-weight-bold editable-column wb-break-word"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                addDuration: true
                                                                            })
                                                                        }}
                                                                    >Click to add min</span>
                                                                    :
                                                                    <div className="wr-movie-detal-date-edit">
                                                                        <AvForm>
                                                                            <InputGroup size="sm">
																			
                                                                                <Input type="text" name="duration"
                                                                                    value={this.state.duration}
                                                                                    placeholder="Duration in Mins"
                                                                                    onChange={this.handleChangeMovies} />
                                                                                <InputGroupAddon addonType="append">
                                                                                    <Button color="light" onClick={() => {
                                                                                        this.setState({
                                                                                            addDuration: false
                                                                                        });
                                                                                        this.handleFieldsChange('addDuration', this.state.duration)
                                                                                    }}><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                                    <Button color="light" title="Cancel" className="mx-0"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                addDuration: false
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon="times" fixedWidth />
                                                                                    </Button>
																					
                                                                                </InputGroupAddon>
                                                                            </InputGroup>
                                                                        </AvForm>
                                                                    </div>
                                                                :
                                                                <>
                                                                    {this.state.editDuration ?
                                                                        <div className="wr-movie-detal-date-edit">
                                                                            <InputGroup size="sm">
																			<Input type="text" name="duration"
                                                                                    placeholder="Duration in Mins"
                                                                                    value={this.state.duration}
                                                                                    onChange={this.handleChangeMovies} />
                                                                                <InputGroupAddon addonType="append">
                                                                                    <Button color="light" onClick={() => {
                                                                                        this.setState({
                                                                                            addDuration: false,
                                                                                            editDuration: false
                                                                                        });
                                                                                        this.handleFieldsChange('addDuration', this.state.duration)
                                                                                    }}><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                                    <Button color="light" title="Cancel" className="mx-0"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                editDuration: false
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon="times" fixedWidth />
                                                                                    </Button>
																				</InputGroupAddon>

                                                                            </InputGroup>

                                                                        </div>
                                                                        :
                                                                        <span className="fs-14 font-weight-bold editable-column wb-break-word" onClick={() => { this.setState({ editDuration: true }) }}>{(about && about.additional_info && about.additional_info.duration && about.additional_info.duration.length > 0) ? about.additional_info.duration[0].value : ""}</span>
                                                                    }
                                                                </>
                                                            }

                                                            <span> | </span>
                                                            {(this.state.ratingData == "") || (this.state.ratingData && this.state.ratingData.length === 0) ?
                                                                this.state.addRating === false ?
                                                                    <span className="fs-14 font-weight-bold editable-column wb-break-word"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                addRating: true
                                                                            })
                                                                        }}
                                                                    >Unrated</span>
                                                                    :
                                                                    <div className="wr-movie-detal-date-edit">
                                                                        <AvForm>
																		  <InputGroup size="sm">
                                                                                <Input type="select" name="rating" value={this.state.rating} onChange={this.handleChangeMovies}>
                                                                                    <option value="G">G</option>
                                                                                    <option value="PG">PG</option>
                                                                                    <option value="PG-13">PG-13</option>
                                                                                    <option value="R">R</option>
                                                                                    <option value="NC-17">NC-17</option>
                                                                                </Input>
                                                                                <InputGroupAddon addonType="append">
                                                                                    <Button color="light" onClick={() => {
                                                                                        this.setState({
                                                                                            addRating: false
                                                                                        });
                                                                                        this.handleFieldsChange('addRating', this.state.rating)
                                                                                    }}><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                                    <Button color="light" title="Cancel" className="mx-0"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                addRating: false
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon="times" fixedWidth />
                                                                                    </Button>
                                                                                </InputGroupAddon>

                                                                            </InputGroup>
                                                                        </AvForm>
                                                                    </div>
                                                                :
                                                                <>
                                                                    {this.state.editRating ?
                                                                        <div className="wr-movie-detal-date-edit">
                                                                            <InputGroup size="sm">
                                                                                <Input type="select" name="rating" value={this.state.rating} onChange={this.handleChangeMovies}>
                                                                                    <option value="G">G</option>
                                                                                    <option value="PG">PG</option>
                                                                                    <option value="PG-13">PG-13</option>
                                                                                    <option value="R">R</option>
                                                                                    <option value="NC-17">NC-17</option>
                                                                                </Input>
                                                                                <InputGroupAddon addonType="append">
                                                                                    <Button color="light" onClick={() => {
                                                                                        this.setState({
                                                                                            addRating: false,
                                                                                            editRating: false
                                                                                        });
                                                                                        this.handleFieldsChange('addRating', this.state.rating)
                                                                                    }}><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                                    <Button color="light" title="Cancel" className="mx-0"
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                editRating: false
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon="times" fixedWidth />
                                                                                    </Button>
                                                                                </InputGroupAddon>

                                                                            </InputGroup>

                                                                        </div>
                                                                        :
																		<>
                                                                        
																		<span className="position-relative">
																		<span className="fs-14 font-weight-bold editable-column wb-break-word" id="UncontrolledPopover" onClick={() => { this.setState({ editRating: true }) }}>{(about && about.rating) ? about.rating[0].value : "Unrated"}</span>
                                                                            
                                                                           
                                                                                <UncontrolledPopover trigger="hover" placement="bottom" target="UncontrolledPopover" popperClassName="popover-lg placement-bottom">
																				<PopoverBody>
                                                                                    <div className="text-secondary-dark bg-white p-3 border border-secondary">
                                                                                        
                                                                                        {/* Static Info */}
                                                                                        <h2 className="text-secondary">The Film Rating System</h2>
                                                                                        <hr className="bg-secondary my-2" />
                                                                                        <div className="d-flex flex-nowrap">
                                                                                            <div className="mr-2">
                                                                                                <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/gda-1.jpg" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <strong>GENERAL AUDIENCES</strong> Nothing that would offend parents for viewing by children.
                                                                                                    </div>
                                                                                        </div>
                                                                                        <hr className="bg-secondary" />
                                                                                        <div className="d-flex flex-nowrap">
                                                                                            <div className="mr-2">
                                                                                                <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/pg-2.jpg" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <strong>PARENTAL GUIDANCE SUGGESTED</strong> Parents urge to give “parental guidance.” May contain some material parents
                                                                                                    might not like for their young children.
                                                                                                    </div>
                                                                                        </div>
                                                                                        <hr className="bg-secondary" />
                                                                                        <div className="d-flex flex-nowrap">
                                                                                            <div className="mr-2">
                                                                                                <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/pg-3.jpg" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <strong>PARENTS STRONGLY CAUTIONED</strong> Parents are urged to be cautious. Some material may be inappropriate for
                                                                                                        pre-teenagers.
                                                                                                    </div>
                                                                                        </div>
                                                                                        <hr className="bg-secondary" />
                                                                                        <div className="d-flex flex-nowrap">
                                                                                            <div className="mr-2">
                                                                                                <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/r-4.jpg" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <strong>RESTRICTED</strong> Contains some adult material. Parents are urged to learn more about the film before
                                                                                                    taking their young children with them.Nothing that would offend parents for viewing by children.
                                                                                                    </div>
                                                                                        </div>
                                                                                        <hr className="bg-secondary" />
                                                                                        <div className="d-flex flex-nowrap">
                                                                                            <div className="mr-2">
                                                                                                <img width="200" src="https://userdatawikireviews.s3.amazonaws.com/images/nc-5.jpg" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <strong> NO ONE 17 AND UNDER ADMITTED</strong> Clearly adult. Children are not admitted.
                                                                                                    </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </PopoverBody>
																			</UncontrolledPopover>
                                                                            </span>
																			
																			
																			
																		</>
																   }
                                                                </>
                                                            }

                                                            <span> | </span>
															{this.state.currentYear == "" ?
																<>
																<span className="fs-14 font-weight-bold editable-column wb-break-word" onClick={() => { this.setState({ addYear: true }) }}>Click to add</span>
																 <div className="wr-movie-detal-date-edit" hidden={this.state.addYear == true ? false : true}>
																	{/* <YearPicker onChange={this.handleChangeAddDate} /> */}
																	<YearPicker
																	yearArray={['2020', '2021']}
																	value={currentYear}
																	onSelect={(e) => this.handleChangeAddDate(e)}
																	hideInput={false}
																	minRange={1000}
																	maxRange={2031}
																  />
																</div>
																</>
															
															:
															<>
																<span className="fs-14 font-weight-bold editable-column wb-break-word" onClick={() => { this.setState({ editYear: true }) }}>{(this.state.currentYear && this.state.currentYear != "") ? this.state.currentYear : ""}</span>
																<div className="wr-movie-detal-date-edit" hidden={this.state.editYear == true ? false : true}>
																	{/* <YearPicker onChange={this.handleChangeDate} /> */}
																	<YearPicker
																	yearArray={['2020', '2021']}
																	value={currentYear}
																	onSelect={(e) => this.handleChangeDate(e)}
																	hideInput={false}
																	minRange={1000}
																	maxRange={2031}
																  />
																</div>
															</>	
															}
															</p>
															<span style={{color:'red'}}>{this.state.durationError}</span>
                                                        <p className="fs-14">
                                                            <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                                                                className="img-fluid"
                                                                onError={(error) =>
                                                                    (error.target.src = `../../../assets/images/norating.png`)
                                                                }
                                                                alt={`${about && about.total_rating && about.total_rating.avg_rating[1] ? about.total_rating.avg_rating[1] : 'no-rating'}`}

                                                            />{" "}{(about && about.total_rating && about.total_rating.total_review) ? about.total_rating.total_review : 0} {getReviewSuffix(about?.total_rating?.total_review)}</p>
                                                        <p className="fs-14">Release Date:</p>
                                                        <p className="fs-14 font-weight-bold">{(about && about.additional_info && about.additional_info.release_date && about.additional_info.release_date.length > 0) ? about.additional_info.release_date[0].value : ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wr-movie-product-info-bottom">
                                                <ul className="m-0 p-0">
                                                    <li className="d-flex">
                                                        <span className="fs-14 wr-movie-product-info-bottom-left">Categories:</span>
                                                        <div className="wr-movie-product-info-bottom-right fs-14 font-weight-bold">
                                                        <span>{
                                                            about && Array.isArray(about.taxonomy_list) && about.taxonomy_list.map((taxonomy) => {
                                                                return <>{taxonomy} </>
                                                            })}</span>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex">
                                                        <span className="fs-14 wr-movie-product-info-bottom-left">Director:</span>
                                                        <div className="wr-movie-product-info-bottom-right fs-14 font-weight-bold">
														{this.allDirectorEqual(this.moviesCastList("Directors")) == true ?
                                                                this.state.addDirector === false ?
                                                                    <span className="fs-14 font-weight-bold editable-column"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                addDirector: true
                                                                            })
                                                                        }}
                                                                    >Click to add</span>
                                                                    :
                                                                    <AvForm>
                                                                        <InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
                                                                            <AvField
                                                                                type="text"
                                                                                bsSize="sm"
                                                                                placeholder="Enter names"
                                                                                name="newDirector"
                                                                                value={this.state.newDirector}
                                                                                onChange={(evt) => this.handleChange(evt, 'newDirectorIsValid')}
                                                                                validate={{
                                                                                    required: { value: true, errorMessage: 'Please enter names' }
                                                                                }}
                                                                            />
                                                                            <InputGroupAddon addonType="append">
                                                                                <Button color="light" title="Update"
                                                                                    disabled={this.state.newDirectorIsValid ? false : true}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            addDirector: false
                                                                                        });
                                                                                        this.handleFieldsChange('addDirector', this.state.newDirector)
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon="check" fixedWidth />
                                                                                </Button>
                                                                                <Button color="light" title="Cancel" className="mx-0"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            addDirector: false
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon="times" fixedWidth />
                                                                                </Button>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                    </AvForm>
                                                                :
                                                                this.moviesCastList("Directors")
                                                            }
                                                       </div>
													   <span style={{ color: 'red' }}>{this.state.directorError}</span>

                                                    </li>
                                                    <li className="d-flex">
                                                        <span className="fs-14 wr-movie-product-info-bottom-left">Producer:</span>
                                                        <div className="wr-movie-product-info-bottom-right fs-14 font-weight-bold">
														
                                                       {this.allProducerEqual(this.moviesCastList("Producers")) == true ?
													     this.state.addProducer === false ?
													<span className="fs-14 font-weight-bold editable-column"
																onClick={() => {
																			this.setState({
																				addProducer: true
																			})
																		}}
															>Click to add</span>
															:
															<AvForm>
																<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																	<AvField
																		type="text"
																		bsSize="sm"
																		placeholder="Enter names"
																		name="newProducer"
																		value={this.state.newProducer}
																		onChange={(evt) => this.handleChange(evt, 'newProducerIsValid')}
																		validate={{
																			required: { value: true, errorMessage: 'Please enter names' }
																		}}
																	/>
																	<InputGroupAddon addonType="append">
																		<Button color="light" title="Update"
																			disabled={this.state.newProducerIsValid ? false : true}
																			onClick={() => {
																				this.setState({
																				addProducer: false
																				});
																				this.handleFieldsChange('addProducer', this.state.newProducer)
																			}}
																		>
																			<FontAwesomeIcon icon="check" fixedWidth />
																		</Button>
																		<Button color="light" title="Cancel" className="mx-0"
																			onClick={() => {
																				this.setState({
																				 addProducer: false
																				});
																			}}
																		>
																			<FontAwesomeIcon icon="times" fixedWidth />
																		</Button>
																	</InputGroupAddon>
																</InputGroup>
															</AvForm>
															:
															 this.moviesCastList("Producers")   
															 }

                                                        <div className="movie-detail-textarea" hidden>
                                                            <InputGroup size="sm">
                                                                <textarea type="text" placeholder="Enter Storyline"></textarea>
                                                                <InputGroupAddon addonType="append">
                                                                    <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>
                                                        </div>
														<span style={{ color: 'red' }}>{this.state.producerError}</span>
                                                    </li>
                                                    <li className="d-flex">
                                                        <span className="fs-14 wr-movie-product-info-bottom-left">Writers:</span>
                                                        <div className="wr-movie-product-info-bottom-right fs-14 font-weight-bold">
                                                       {this.allWriterEqual(this.moviesCastList("Writers")) == true ?
													     this.state.addWriter === false ?
													<span className="fs-14 font-weight-bold editable-column"
																onClick={() => {
																			this.setState({
																				addWriter: true
																			})
																		}}
															>Click to add</span>
															:
															<AvForm>
																<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																	<AvField
																		type="text"
																		bsSize="sm"
																		placeholder="Enter names"
																		name="newWriter"
																		value={this.state.newWriter}
																		onChange={(evt) => this.handleChange(evt, 'newWriterIsValid')}
																		validate={{
																			required: { value: true, errorMessage: 'Please enter names' }
																		}}
																	/>
																	<InputGroupAddon addonType="append">
																		<Button color="light" title="Update"
																			disabled={this.state.newWriterIsValid ? false : true}
																			onClick={() => {
																				this.setState({
																				addWriter: false
																				});
																				this.handleFieldsChange('addWriter', this.state.newWriter)
																			}}
																		>
																			<FontAwesomeIcon icon="check" fixedWidth />
																		</Button>
																		<Button color="light" title="Cancel" className="mx-0"
																			onClick={() => {
																				this.setState({
																				 addWriter: false
																				});
																			}}
																		>
																			<FontAwesomeIcon icon="times" fixedWidth />
																		</Button>
																	</InputGroupAddon>
																</InputGroup>
															</AvForm>
															:
															 this.moviesCastList("Writers")   
															 }
                                                        </div>
														<span style={{ color: 'red' }}>{this.state.writerError}</span>
                                                    </li>
                                                    <li className="d-flex">
                                                        <span className="fs-14 wr-movie-product-info-bottom-left">Cast:</span>
                                                        <div className="wr-movie-product-info-bottom-right fs-14 font-weight-bold">
                                                        {this.allCastEqual(this.moviesCastList("Cast")) == true ?
													     this.state.addCast === false ?
													<span className="fs-14 font-weight-bold editable-column"
																onClick={() => {
																			this.setState({
																				addCast: true
																			})
																		}}
															>Click to add</span>
															:
															<AvForm>
																<InputGroup size="sm" className="fg-mb-0 flex-nowrap align-items-start mb-2">
																	<AvField
																		type="text"
																		bsSize="sm"
																		placeholder="Enter names"
																		name="newCast"
																		value={this.state.newCast}
																		onChange={(evt) => this.handleChange(evt, 'newCastIsValid')}
																		validate={{
																			required: { value: true, errorMessage: 'Please enter names' }
																		}}
																	/>
																	<InputGroupAddon addonType="append">
																		<Button color="light" title="Update"
																			disabled={this.state.newCastIsValid ? false : true}
																			onClick={() => {
																				this.setState({
																				addCast: false
																				});
																				this.handleFieldsChange('addCast', this.state.newCast)
																			}}
																		>
																			<FontAwesomeIcon icon="check" fixedWidth />
																		</Button>
																		<Button color="light" title="Cancel" className="mx-0"
																			onClick={() => {
																				this.setState({
																				 addCast: false
																				});
																			}}
																		>
																			<FontAwesomeIcon icon="times" fixedWidth />
																		</Button>
																	</InputGroupAddon>
																</InputGroup>
															</AvForm>
															:
															 this.moviesCastList("Cast")   
															 }
                                                        </div>
														<span style={{ color: 'red' }}>{this.state.castError}</span>
                                                    </li>
                                                    <li className="d-flex">
                                                        <span className="fs-14 wr-movie-product-info-bottom-left">Website:</span>
                                                        {/*<span className="wr-movie-product-info-bottom-right fs-14 font-weight-bold"> <a href="#" className="wr-movie-product-info-bottom-right-black">http://topgunmovie.com/</a></span>*/}
                                                        <div className="wr-movie-product-info-bottom-right fs-14 font-weight-bold">
														{this.state.websiteSet && this.state.websiteSet.length === 0 ?
                                                                this.state.addWebsite === false ?
                                                                    <span className="fs-14 font-weight-bold editable-column wb-break-word"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                addWebsite: true
                                                                            })
                                                                        }}
                                                                    >
                                                                        Click to add
                                                                </span>
                                                                    :
                                                                    <AvForm>
                                                                        <InputGroup size="sm" className="fg-mb-0 align-items-start flex-nowrap">
                                                                            <AvField bsSize="sm" type="text" placeholder="Enter valid Website" value={this.state.newWebsite} name="newWebsite"
                                                                                onChange={(evt) => this.handleChange(evt, 'newWebsiteIsValid')}
                                                                                validate={{
																				required: { value: true, errorMessage: 'Please enter your website' },
																				pattern: {
																					value: "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$",
																					errorMessage: 'Please enter valid website link'
																				},
																			}}
                                                                            />
                                                                            <InputGroupAddon addonType="append">
                                                                                <Button color="light" title="Update"
                                                                                    disabled={this.state.newWebsiteIsValid ? false : true}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            addWebsite: false
                                                                                        });
                                                                                        this.handleFieldsChange('addWebsite', this.state.newWebsite)
                                                                                    }}
                                                                                >
                                                                                    <FontAwesomeIcon icon="check" fixedWidth />
                                                                                </Button>
                                                                                <Button color="light" title="Cancel" className="mx-0" onClick={() => {
                                                                                    this.setState({
                                                                                        addWebsite: false
                                                                                    })
                                                                                }}
                                                                                >
                                                                                    <FontAwesomeIcon icon="times" fixedWidth />
                                                                                </Button>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                    </AvForm>
                                                                :
                                                                <div>
                                                                    <span className="wr-movie-product-info-bottom-right fs-14 font-weight-bold">
                                                                        {about && about.website_set && about.website_set.length > 0 ?
                                                                            <>{about.website_set.map(site => {
                                                                                if (site.website_type === 'Main') {
                                                                                    return <Link className="text-reset wb-break-word wr-movie-product-info-bottom-right-black" target="_blank" to={
                                                                                        {
                                                                                            pathname: "/abouttransition/",
                                                                                            search: `?page_url=${site.website}`
                                                                                        }
                                                                                    } >{site.website}</Link> 
                                                                                }
                                                                            })}</> : ""
                                                                        }
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>

                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                        {this.state.toggleMoreLessInfo ?
                                            <Col>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="wr-category-heading text-dark">
                                                            {about && about.total_rating && Object.keys(about.total_rating).length > 0 ?

                                                                about && Object.keys(about.total_rating).map(rating => {
                                                                    if (rating !== "total_review" && rating !== "avg_rating") {
                                                                        return <div className="d-flex mx-n2"><div className="px-2"><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${about.total_rating[rating][0]}`} className="img-fluid mt-n1" alt={rating || ""} /></div> <div className="px-2"><div className="fs-14 ff-alt">{rating}</div></div> </div>

                                                                    }
                                                                }) : ''}
                                                            <p className="fs-14 mt-2">Storyline:</p>
                                                            {/*{(about && about.additional_info && about.additional_info.storyline && about.additional_info.storyline.length > 0) ?				
										<p className="fs-14 font-weight-bold editable-column wb-break-word">
										{(about.additional_info.storyline[0].value?.length <= 140 || !readMore) && about.additional_info.storyline[0].value?.slice(0, 140)}
										{readMore && about.additional_info.storyline[0].value}  
										<Button color="link" className="text-dark p-0 font-weight-bold" onClick={() => this.handleOnClickReadMore()}>
														{!readMore ? 'read more' : 'show less'}</Button> </p>: ""}*/}

                                                            {(this.state.storyData == "") || (this.state.storyData && this.state.storyData.length === 0) ?
                                                                this.state.addstoryData === false ?
                                                                    <span className="fs-14 font-weight-bold editable-column wb-break-word"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                addstoryData: true
                                                                            })
                                                                        }}
                                                                    >Click to add</span>
                                                                    :
                                                                    <div className="movie-detail-textarea">
                                                                        <Input bsSize="sm" className="w-100 primary" type="textarea" placeholder="Enter Storyline" name="storyline"
                                                                            onChange={this.handleChangeMovies} value={this.state.storyline} />
                                                                        {this.state.editSoryError && (
                                                                            <span className="text-error">Required this field.</span>
                                                                        )}
                                                                        <div className="mt-2 text-right">
                                                                            <Button color="primary" size="sm" onClick={() => { this.handleFieldsChange('addStoryLine', this.state.storyline) }}>
                                                                                Submit</Button>
                                                                            <Button color="primary" size="sm" onClick={() => {
                                                                                this.setState({
                                                                                    addstoryData: false
                                                                                })
                                                                            }}>
                                                                                Cancel</Button>
                                                                        </div>

                                                                    </div>

                                                                :
                                                                <div >
                                                                    {
                                                                        this.state.editstoryData ?
                                                                            <div className="movie-detail-textarea">
                                                                                <Input bsSize="sm" className="w-100 primary" type="textarea" placeholder="Enter Storyline" name="storyline"
                                                                                    onChange={this.handleChangeMovies} value={this.state.storyline} />
                                                                                {this.state.editSoryError && (
                                                                                    <span className="text-error">Required this field.</span>
                                                                                )}
                                                                                <div className="mt-2 text-right">
                                                                                    <Button color="primary" size="sm" onClick={() => { this.handleFieldsChange('addStoryLine', this.state.storyline) }}>
                                                                                        Submit</Button>
                                                                                    <Button color="primary" size="sm" onClick={() => {
                                                                                        this.setState({
                                                                                            editstoryData: false
                                                                                        })
                                                                                    }}>
                                                                                        Cancel</Button>
                                                                                </div>

                                                                            </div>
                                                                            :
                                                                            <p className="fs-14 font-weight-bold editable-column wb-break-word" onClick={() => {
                                                                                this.setState({
                                                                                    editstoryData: true
                                                                                })
                                                                            }}>
                                                                                {(this.state.storyline?.length <= 140 || !readMore) && this.state.storyline?.slice(0, 140)}
                                                                                {readMore && this.state.storyline}</p>
                                                                    }
                                                                    <Button color="link" className="text-royal-blue p-0 font-weight-bold" onClick={() => this.handleOnClickReadMore()}>
                                                                        {!readMore ? 'read more' : 'show less'}</Button>
                                                                </div>
                                                            }

                                                            <p className="fs-14">Social Media Links:</p>
                                                            <div className="fs-14 text-dark font-weight-bold">
                                                                {about && about.website_set && about.website_set.length > 0 ?

                                                                    <ul className="list-unstyled mt-2">
                                                                        {
                                                                            about.website_set.map((site, index) => {
                                                                                if (site.website_type !== 'Main' && site.website_type !== null) {
                                                                                    return (
                                                                                        <li key={index} className="mb-1">
                                                                                            {this.state.changeSocialLink && this.state.socialSiteID === site.id ?
                                                                                                <div className="social-media-edits" >
                                                                                                    <AvForm>
                                                                                                        <Row form>
                                                                                                            <Col xs="auto">
                                                                                                                <FormGroup>
                                                                                                                    <Input bsSize="sm" type="select" className="font-awesome fa p-0" style={{fontSize:"15px"}} value={this.state.editSocialLinkType} name="editSocialLinkType" id="editSocialLinkType" onChange={this.handleChange}>
                                                                                                                    {<option value="Facebook" disabled={about?.website_set.some(item=>item.website_type==="Facebook")} className="fa" style={{fontSize:"20px"}}>&#xf09a;</option>}
                                                                                                                     {<option value="Twitter" disabled={about?.website_set.some(item=>item.website_type==="Twitter")} className="fa" style={{fontSize:"20px"}}>&#xf099;</option>}
                                                                                                                    {<option value="Instagram" disabled={about?.website_set.some(item=>item.website_type==="Instagram")} className="fa" style={{fontSize:"20px"}}>&#xf16d;</option>}
                                                                                                                    </Input>
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                            <Col md={6}>
                                                                                                                <FormGroup>
                                                                                                                    <AvField bsSize="sm" value={this.state.editSocialLink} name='editSocialLink' type="text"
                                                                                                                        onChange={(evt) => { 
																														    this.handleChange(evt, 'editSocialLinkIsValid')
																															this.setState({
                                                                                                                                editvalidSocialUrl: ""

                                                                                                                            });
																														}}
                                                                                                                        validate={{
                                                                                                                            required: { value: true, errorMessage: 'Please enter your Social Link' },
                                                                                                                            pattern: { value: websiteRegex, errorMessage: 'Please enter your Social Link' },
                                                                                                                        }}
                                                                                                                    />
																													<span style={{ color: 'red' }}>{editvalidSocialUrl}</span>
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Row form>
                                                                                                            <Col md={12}>
                                                                                                                <FormGroup>
                                                                                                                    <Button color="primary" size="sm"
                                                                                                                        disabled={this.state.editSocialLinkIsValid ? false : true}
                                                                                                                        onClick={() => {
                                                                                                                            this.handleFieldsChange('editSocialLink',
                                                                                                                                { website: this.state.editSocialLink, id: site.id, oldValue: site.website, website_type: this.state.editSocialLinkType });
                                                                                                                        }}
                                                                                                                    >Submit</Button>
                                                                                                                    <Button color="primary" size="sm"
                                                                                                                        onClick={() => {
                                                                                                                            this.setState({
                                                                                                                                changeSocialLink: false,
                                                                                                                                socialLink: site.website,
                                                                                                                                socialLinkType: site.website_type,

                                                                                                                            });
                                                                                                                        }}
                                                                                                                    >Cancel</Button>
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </AvForm>
                                                                                                </div>
                                                                                                :

                                                                                                <div className="d-flex mx-n1 flex-nowrap align-items-start mb-2">
                                                                                                    <div className="px-1 text-nowrap">
                                                                                                        <FontAwesomeIcon icon={['fab', `${site.website_type.toLowerCase()}`]} size="lg" fixedWidth />:
                                                                                                    </div>
                                                                                                    <div className="px-1 flex-grow-1 text-truncate">
                                                                                                        <Link className="text-reset text-nowrap" target="_blank" to={
                                                                                                            {
                                                                                                                pathname: "/abouttransition/",
                                                                                                                search: `?page_url=${site.website}`
                                                                                                            }
                                                                                                        } >{site.website}</Link>
                                                                                                    </div>

                                                                                                    <div className="px-1 col-auto">
                                                                                                        <EditBtn
                                                                                                            color="filled"
                                                                                                            onClick={() => {
                                                                                                                this.setState({
                                                                                                                    changeSocialLink: true,
                                                                                                                    editSocialLink: site.website,
                                                                                                                    socialSiteID: site.id,
                                                                                                                    editSocialLinkType: site.website_type,

                                                                                                                })
                                                                                                            }} />
                                                                                                        <DeleteBtn
                                                                                                            color="gold"
                                                                                                            onClick={() => {
                                                                                                                this.props.delete_listing_Info(about.id, "movies", site.id)
                                                                                                            }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </ul> :
                                                                    ""
                                                                }

                                                            </div>

                                                            {this.state.setSocialMediaLink ? <div className="social-media-edits" >
                                                                <AvForm>
                                                                    <Row form>
                                                                        <Col xs="auto">
                                                                            <FormGroup>
                                                                                <Input bsSize="sm" type="select" className="font-awesome fa p-0" style={{fontSize:"15px"}} value={this.state.socialLinkType} name="socialLinkType" id="socialLinkType"
                                                                                    // onChange={this.handleChange}
                                                                                    onChange={(evt) => this.handleSocialLinkType(evt, 'socialLinkIsValid', this.state.socialLink)}
                                                                                >
                                                                                    {<option value="Facebook" disabled={about?.website_set.some(item=>item.website_type==="Facebook")} className="fa" style={{fontSize:"20px"}}>&#xf09a;</option>}
                                                                                    {<option value="Twitter" disabled={about?.website_set.some(item=>item.website_type==="Twitter")} className="fa" style={{fontSize:"20px"}}>&#xf099;</option>}
                                                                                    {<option value="Instagram" disabled={about?.website_set.some(item=>item.website_type==="Instagram")} className="fa" style={{fontSize:"20px"}}>&#xf16d;</option>}
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <AvField bsSize="sm" value={this.state.socialLink} name='socialLink' type="text"
                                                                                    onChange={(evt) => this.handleSocialLinkChange(evt, 'socialLinkIsValid', this.state.socialLinkType)}
                                                                                    validate={{
                                                                                        required: { value: true, errorMessage: `Please enter your ${this.state.socialLinkType} Social Link` },
                                                                                        pattern: { value: websiteRegex, errorMessage: `Please enter your proper ${this.state.socialLinkType} Social Link` },
                                                                                    }}
                                                                                />
																			<span style={{ color: 'red' }}>{validSocialUrl}</span>	
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row form>
                                                                        <Col md={12}>
                                                                            <FormGroup>
                                                                                <Button color="primary" size="sm"
                                                                                    disabled={this.state.socialLinkIsValid ? false : true}
                                                                                    onClick={async () => {
                                                                                        await this.handleFieldsChange('socialLink', this.state.socialLink,)
                                                                                        
                                                                                    }}>Submit</Button>
                                                                                <Button color="primary" size="sm" onClick={() => {
                                                                                    this.setState({
                                                                                        setSocialMediaLink: false,
                                                                                        socialLink: '',
                                                                                        socialLinkType: 'Facebook'
                                                                                    })
                                                                                }}>Cancel</Button>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </AvForm>
                                                            </div>
                                                                : <span
                                                                hidden = {this.checkAllNetwork()}
                                                                    className="fs-14 font-weight-bold ff-alt wr-movie-product-info-bottom-right-black"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            setSocialMediaLink: true,
                                                                        })
                                                                    }} role="button">Click to add</span>
                                                            }
                                                            <ul className="m-0 p-0 wr-category-list-center">
                                                                <li>
                                                                    <a className="fs-14 font-weight-bold wr-movie-product-info-bottom-right-black ff-base" href="javascript:void(0)" onClick={() => this.handleOnClickCategory("advisory")}>Advisory</a>
                                                                </li>
                                                                <li>
                                                                    <a className="fs-14 font-weight-bold wr-movie-product-info-bottom-right-black ff-base" href="javascript:void(0)" onClick={() => this.handleOnClickCategory("castCrew")}>See Cast and Crew</a>
                                                                </li>
                                                                <li>
                                                                    <a className="fs-14 font-weight-bold wr-movie-product-info-bottom-right-black ff-base" href="javascript:void(0)" onClick={() => this.handleOnClickCategory("coolFacts")}>See Cool Facts</a>
                                                                </li>
                                                                <li>
                                                                    <a className="fs-14 font-weight-bold wr-movie-product-info-bottom-right-black ff-base" href="javascript:void(0)" onClick={() => this.handleOnClickCategory("seetechincaldetail")}>See Technical Details</a>
                                                                </li>
                                                                <li>
                                                                    <a className="fs-14 font-weight-bold wr-movie-product-info-bottom-right-black ff-base" href="javascript:void(0)" onClick={() => this.handleOnClickCategory("seeDetails")}>See Details</a>
                                                                </li>
                                                                <li>
                                                                    <a className="fs-14 font-weight-bold wr-movie-product-info-bottom-right-black ff-base" href="javascript:void(0)" onClick={() => this.handleOnClickCategory("seeTipsWarning")}>See Tips & Warnings</a>
                                                                </li>
                                                                <li>
                                                                    <a className="fs-14 font-weight-bold wr-movie-product-info-bottom-right-black ff-base" href="javascript:void(0)" onClick={() => this.handleOnClickCategory("viewPlotSummary")}>View Plot Summary</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <p className="fs-14 text-left" hidden={about && about.theatres && about.theatres.length === 0 && about?.movie_release_date}>Theaters</p>
                                                        {about && about.theatres && Array.isArray(about.theatres).length > 0 && !about?.movie_release_date ?
                                                            about && Array.isArray(about.theatres).map((theater, index) => {
                                                                if (index < 3) {
                                                                    return <div className="d-flex wr-movie-theture-details flex-wrap mb-3" key={index}>
                                                                        <div className="wr-movie-theture-details-left">
                                                                            <img src={`${theater && theater.image && theater.image[0] && theater.image[0].url}`}
                                                                                className="img-fluid wr-movie-theture-details-left-image"
                                                                                onError={(error) =>
                                                                                    (error.target.src = `../../../assets/images/capitol2.jpg`)
                                                                                }
                                                                                alt={`${theater && theater.image && theater.image[0] && theater.image[0].caption ? theater.image[0].caption : 'no-image'}`}
                                                                            />

                                                                        </div>
                                                                        <div className="wr-movie-theture-details-right">
                                                                            <div className="wr-category-heading">
                                                                                <p className="fs-14 font-weight-bold mt-0">{theater.name}</p>
                                                                                <p className="fs-14 mb-2">{theater.address} {theater.city} , {theater.state} {theater.zip}</p>
                                                                                <p className="fs-14">Showtime: {this.showTime(theater.showtime_list)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            })
                                                            : <div className="bg-white p-3" hidden={about && about.theatres && about.theatres.length === 0 && about?.movie_release_date}>
                                                                <h2 className="text-secondary-dark">No Theaters to Display</h2>
                                                            </div>}

                                                        <div className="wr-category-heading" hidden={about && about.theatres && about.theatres.length === 0 && about?.movie_release_date}>
                                                            <a className="fs-14 font-weight-bold mt-0 wr-movie-product-info-bottom-right-black" href="#">Not in {about && about?.location?.city}?</a>
                                                        </div>

                                                        {about && about.theatres && about.theatres?.length == 0 && about?.additional_info && about?.additional_info?.release_date && about?.additional_info?.release_date[0] && about?.additional_info?.release_date[0]?.value && (
                                                            <div>
                                                                <p className="fs-14 text-left">Release Date: {this.getDateDay(about.movie_release_date)}, {about?.additional_info?.release_date[0]?.value}</p>
                                                                {about && about.movie_release_date && about.wwm_movie_id ?
                                                                    <p className="fs-14 text-left">
                                                                        <Link className="text-reset" target="_blank" to={
                                                                            {
                                                                                pathname: "/abouttransition/",
                                                                                search: `?page_url=${`http://wap.movietickets.com/mobile/movie_detail.asp?afid=wiki&amp;movie_id=${about.wwm_movie_id}&&searchzip=`}`
                                                                            }
                                                                        } >Buy Tickets</Link>
                                                                    </p>
																: ""}
															</div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            : ''
                                        }
                                    </Row>
                                </div>

                                <div className="mt-auto ml-auto wr-movie-less-info-about">
                                    <span role="button" onClick={() => this.setState({ toggleMoreLessInfo: !this.state.toggleMoreLessInfo })}>
                                        {this.state.toggleMoreLessInfo ? <><span className="text">less info</span> <span className="icon-arrow-left"></span> </> : <><span className="text">more info</span><span className="icon-arrow-right"></span> </>}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
					
                    {!this.state.toggleMoreLessInfo ?
                        about && about.listing_profileimage && about.listing_profileimage[0] && about.listing_profileimage[0].url ?
                            // If atleast one image uploaded remove hidden attr
                            <div>
                                <div className="floated-info-section d-flex align-items-center flex-wrap justify-content-center">
                                    <div className="image-area-show">
                                        <img src={about.listing_profileimage[0].url}
                                            className="img-fluid"
                                            alt={about.listing_profileimage[0].caption}
                                        />
                                    </div>
                                    <div className="image-area-crop">
                                        Help us to make WikiReviews a better place! <br /> Please upload an alternate image. Suggested Size 1600x1050.
                                    </div>
                                </div>
                            </div>
                            :
                            // If no image uploaded ever
                            < div className="floated-no-image-section">
								<Button onClick={() => this.handleOnClickUploadMedia()} color="primary" size="lg" hidden={about && about.listing_profileimage && about.listing_profileimage[0] && about.listing_profileimage[0].url}>Upload Images</Button>
								 <div className="image-area-crop">
                                        Help us to make WikiReviews a better place! <br /> Please upload an alternate image. Suggested Size 1600x1050.
                                    </div>
                            </div>
                        :
                        ''
                    }
                </Container>
                {/* Upload Media Post Modal */}
                <Modal
                    isOpen={this.state.uploadMediaPostModal}
                    toggle={this.uploadMediaModalToggle}
                >
                    <ModalHeader toggle={this.uploadMediaModalToggle}>
                        UPLOAD MEDIA
                </ModalHeader>
                    <ModalBody className="p-3">
                        <Row>
                            <Col xs={"auto"}>
                                <div className="bg-light h-100 p-3">
                                    <Nav tabs className="flex-column active-transparent border-0 m-0">
                                        <NavItem className="text-left">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "upload"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("upload");
                                                }}
                                            >
                                                Upload
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "gallery"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("gallery");
                                                }}
                                            >
                                                Gallery
                                            </NavLink>
                                        </NavItem>

                                    </Nav>
                                </div>
                            </Col>
                            <Col>
                                <TabContent activeTab={this.state.selectedUpMediaType}>
                                    <TabPane tabId="upload">
                                        <div
                                            className="mb-3 type-file-block bg-light"
                                            hidden={
                                                this.state.uploadMedia.uploadFiles &&
                                                    this.state.uploadMedia.uploadFiles.length > 0
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <Input
                                                type="file"
                                                name="upload_media_file"
                                                id="uploadFilesPostFromSystem"
                                                accept="image/*, video/*"
                                                onChange={this.handleOnFilePostUploadChange}
                                                multiple
                                            />
                                            <Label for="uploadFilesPostFromSystem">
                                                <div className="type-bg"></div>
                                            </Label>
                                        </div>
                                        {this.state.uploadMedia.uploadFiles.length > 0 && (
                                            <Row className="mb-3" form>
                                                <Col xs={12}>
                                                    <div
                                                        style={{
                                                            maxWidth: "120px",
                                                            margin: "0 1rem 1rem auto",
                                                        }}
                                                    >
                                                        <div className="text-center mb-1 small">
                                                            {this.state.uploadMedia.progress === 100 ? (
                                                                <div className="text-success">
                                                                    <FontAwesomeIcon
                                                                        icon="check-circle"
                                                                        className="mr-1"
                                                                    />{" "}
                                  Uploaded
                                                                </div>
                                                            ) : (
                                                                    <div>
                                                                        Uploading{" "}
                                                                        <span className="text-success font-weight-bold ff-base">
                                                                            {this.state.uploadMedia.progress.toFixed(0)}
                                    %
                                  </span>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <Progress
                                                            value={this.state.uploadMedia.progress}
                                                            style={{ height: "8px" }}
                                                        ></Progress>
                                                    </div>
                                                </Col>
                                                {this.state.uploadMedia.uploadFiles.map((file) => {
                                                    return (
                                                        <Col xs="auto">
                                                            <div className="d-flex pr-3">
                                                                <div>
                                                                    <div
                                                                        className="selectable-media"
                                                                        style={{ cursor: "default" }}
                                                                    >
                                                                        <div className="gallery-media">
                                                                            <img src={file.url} alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mx-n3 mt-2">
                                                                    <Button color="delete-media" size="sm" title="Remove Media"
                                                                        hidden={file.id === "" ? true : false}
                                                                        onClick={this.handleOnClickRemoveSelectedMedia(
                                                                            file.id
                                                                        )}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                })}
                                                <Col xs="auto">
                                                    <div className="d-flex pt-3">
                                                        <div className="selectable-media" hidden>
                                                            <Label
                                                                for="uploadFilesFromSystemMini"
                                                                className="gallery-media"
                                                                style={{
                                                                    borderStyle: "dashed",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <div className="d-flex h-100 align-items-center justify-content-center">
                                                                    <span className="fs-14">Upload More</span>
                                                                </div>
                                                            </Label>
                                                            <Input
                                                                type="file"
                                                                name="upload_media_file"
                                                                id="uploadFilesFromSystemMini"
                                                                accept="image/*, video/*"
                                                                onChange={this.handleOnFilePostUploadChange}
                                                                multiple
                                                                style={{ display: "none" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={this.uploadMediaModalToggle}
                                                >
                                                    Cancel
                        </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={this.state.addMenuItemImage ? () => {
                                                        this.setState({
                                                            uploadMediaPostModal: false,
                                                        })
                                                        this.handleAddMediaToMenuItem()
                                                    } : () => this.handleAddMediaToGallery()}
                                                >
                                                    Ok
                        </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="gallery">
                                        <div className="mb-2">
                                            <Nav tabs className="d-inline-flex mb-0">
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        active={this.state.showGalleryType === "images"}
                                                        onClick={() => {
                                                            this.handleOnClickGalleryType("images");
                                                        }}
                                                    >
                                                        Image Gallery
                          </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        active={this.state.showGalleryType === "videos"}
                                                        onClick={() => {
                                                            this.handleOnClickGalleryType("videos");
                                                        }}
                                                    >
                                                        Video Gallery
                          </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={this.state.showGalleryType}>
                                            <TabPane tabId="images">
                                                <div>
                                                    <FormGroup className="form-row justify-content-end">
                                                        <Col xs="auto">
                                                            <Label className="small" for="selectAlbumType">
                                                                Album Type:
                              </Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Input
                                                                bsSize="sm"
                                                                type="select"
                                                                name="album_type_select"
                                                                id="selectAlbumType"
                                                                defaultValue={this.state.uploadMedia.albumType}
                                                                onChange={this.handleOnClickAlbumTypeChange}
                                                            >
                                                                <option value="">All</option>
                                                                {this.state.uploadMedia.albumTypesList.length >
                                                                    0 &&
                                                                    this.state.uploadMedia.albumTypesList.map(
                                                                        (type) => (
                                                                            <option value={type.category}>
                                                                                {type.category}
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: "350px", overflow: "auto" }}
                                                    ref={this.refGalleryImageScroll}
                                                    onScroll={this.handleScrollGalleryImage}
                                                >
                                                    {this.state.uploadMedia.albumTypeData &&
                                                        this.state.uploadMedia.albumTypeData.count > 0 ? (
                                                            <Row>

                                                                {this.fetchScrollPhotoMedia()}
                                                            </Row>
                                                        ) : (
                                                            <div className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">No images</h2>
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={this.uploadMediaModalToggle}
                                                        >
                                                            Cancel
                            </Button>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.handleAddMediaToGallery()}
                                                        >
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="videos">
                                                <div
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: "350px", overflow: "auto" }}
                                                    ref={this.refGalleryVideoScroll}
                                                    onScroll={this.handleScrollGalleryVideo}
                                                >
                                                    {this.state.uploadMedia.albumTypeData &&
                                                        this.state.uploadMedia.albumTypeData.count > 0 ? (
                                                            <Row>
                                                                {this.fetchScrollPhotoMedia(true)}
                                                            </Row>
                                                        ) : (
                                                            <div className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">No videos</h2>
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={this.uploadMediaModalToggle}
                                                        >
                                                            Cancel
                            </Button>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.handleAddMediaToGallery()}
                                                        >
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
				
				

            </div>

        )
    }
}

const mapState = (state) => ({
    album_types_list: state.user.album_types_list,
    album_type_data: state.user.album_type_data,

})

const mapProps = (dispatch) => ({
    update_business_detail: (id, data, message) => dispatch(update_business_detail(id, data, message)),
    update_detail: (type, id, data, message) => dispatch(update_detail(type, id, data, message)),
    add_social_website: (id, types, data) => dispatch(add_social_website(id, types, data)),
    delete_listing_Info: (id, type, lisitnID) => dispatch(delete_listing_Info(id, type, lisitnID)),
    get_media_children: (category, type) => dispatch(get_media_children(category, type)),
    get_album_types_list: () => dispatch(get_album_types_list()),
    get_album_type_data: (type, album, page) => dispatch(get_album_type_data(type, album, page)),
    delete_selected_gallery_media: (dataID) => dispatch(delete_selected_gallery_media(dataID)),
    add_media_to_gallery: (data) => dispatch(add_media_to_gallery(data)),
    add_media_to_item_gallery: (data,type,id) => dispatch(add_media_to_item_gallery(data,type,id)),
})

export default connect(mapState, mapProps)(About);
