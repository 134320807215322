
const initialState = {
    fetch_tips_war_list: null,
    get_buyersGuides: null,
    get_buyersTalks: null,
    get_buyersVocablory: null,
    get_buyersTrending: null,
    get_buyersSubcategory: null,
    get_buyersHistory: null
};

const buyersGuide = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TIPS_WARNING":
            state.fetch_tips_war_list = action.payload;
            return { ...state };

        case "GET_BUYERS_GUIDES":
            state.get_buyersGuides = action.payload;
            return { ...state };

        case "GET_BUYERS_TALKS":
            state.get_buyersTalks = action.payload;
            return { ...state };

        case "GET_BUYERS_VOCABLORY":
            state.get_buyersVocablory = action.payload;
            return { ...state };

        case "GET_BUYERS_TRENDING":
            state.get_buyersTrending = action.payload;
            return { ...state };

        case "GET_BUYERS_SUBCATEGORY":
            state.get_buyersSubcategory = action.payload;
            return { ...state };

        case "GET_BUYERS_HISTORY":
            state.get_buyersHistory = action.payload;
            return { ...state };

        default:
            return state;
    }
};

export default buyersGuide;
