/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from "react";
import {
  Alert,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Input,
  DropdownItem,
  Media,
  Label,
  ModalFooter,
  CustomInput,
  Progress,
  Table,
} from "reactstrap";
import StarsRating from "stars-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollapseBasic from "../../atoms/collapse";
import { connect } from "react-redux";
import {
  get_feeds,
  update_reaction,
  updateFeedReactions,
  addUpdateCommentOnFeed,
  deleteComments,
  submitCommentOnFeedReview,
  updateReviewFeedback,
  submitCommentOnFeedbacks,
  setFilter,
  get_dispute_discussion,
  delete_dispute,
  updateFeedbackDiscussion,
  add_dispute_reply,
  delete_dispute_reply,
  getreviewHelpfulCopunt,
  reset_feeds,
  addPostCommentReplyOnFeed,
  addPostCommentOnFeed,
  deletePostCommentReplyOnFeed,
} from "../../../actions/feeds";
import { toast } from "react-toastify";
import { callApi, responseCodeValidator } from "../../../utils/apiCaller";
import _, {get} from "lodash";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Link } from "react-router-dom";
import { Ratings } from "../../molecules/homepage/ShowRatings";
import Reactions from "../../molecules/homepage/Reactions";
import { ReviewComments } from "../../molecules/homepage/ReviewComments";
import { Comments } from "../../molecules/homepage/Comments";
import FeedbackComments from "../../molecules/homepage/FeedbackComments";
import { ConfirmationModal } from "../../molecules/homepage/ConfirmationModal";
import {
  remove_from_list,
  share_by_email,
  add_to_list,
} from "../../../actions/business";
import { get_reviewgroup } from "../../../actions/user";
import querystring from "query-string";
import Carousel from "react-multi-carousel";
import DisputingReview from "../../atoms/disputeReview/disputingReview";
import DisputedReview from "../../atoms/disputeReview/disputedReview";
import ModalComponent from "../../atoms/modalComponent";
import ShareByEmail from "../../atoms/shared/shareByEmail";
import CreateReview from "../../atoms/createReview";
import UploadMedia from '../../atoms/fileUploadMedia';
import { finalizedMediaInReviewEmbedLinkSetForFollowReview, finalizedMediaInReviewEmbedLinkSetForNewReview, finalizedMediaInReviewMediaSetForNewReview, uploadMediaInReviewEmbedLinkSetForNewReview, uploadMediaInReviewMediaSetForEditReview, uploadMediaInReviewMediaSetForFollowReview, uploadMediaInReviewMediaSetForNewReview } from "../../../actions/reviewMedia";
import CreateReviewBusiness from "../../atoms/createReviewBusiness";
import Markdown from "react-markdown";
import extractUrls from 'extract-urls'
import Embed from 'react-embed';
import ReactHtmlParser from 'react-html-parser';
import { detectlink } from "../../../utils/utilities";
const responsive = {
  viewGalleryImages: {
    desktop: {
      breakpoint: { max: 9999999, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  },
  videoModal: {
    mobile: {
      breakpoint: { max: 9999999, min: 0 },
      items: 1
    }
  },
};

class Feed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "All",
      filterType: "all",
      page: 1,
      feeds: null,
      prevY: 0,
      loading: false,
      viewReactedType: "all",
      showReactionGif: false,
      host: window.location.host,
      protocol: window.location.protocol,
      shareTitle:
          "Log in or sign up for WikiReviews to view and rate my Community Review Project!",
      shareVia: "WikiReviews",
      fbHashTag: "#WikiReviews",
      twitterHashTags: ["WikiReviews"],
      shareByEmailModalToggle: false,
      ishover: false,
      comment: "",
      reviewComment: "",
      feedbackComment: "",
      disputeThisReviewModalToggle: false,
      isDeleteCommentModalOpen: false,
      shareId: 0,
      shareError: {},
      voteReviewModalToggleOne: false,
      voteReviewModalToggleTwo: false,
      voteReviewModalToggleThree: false,
      voteReviewModalToggleFour: false,
      voteReviewModalToggleFive: false,
      voteReviewModalToggleSix: false,
      voteReviewModalToggleFinal: false,
      limitedDisputesModalToggle: false,
      historyLogModalToggle: false,
      patrolReviewModalToggleOne: false,
      reviewId: "",
      all_disputes_list: [],
      disputed_tab: false,
      user_names_list: [],
      get_dispute_data: [],
      showExplanation: false,
      disputeReviewId: "",
      is_media_dispute: false,

      dispute_files: [],
      dispute_imgepreview: [],
      get_reveiewhelpCount: [],

      pinStatuses: {},

      dispute_reply_text: "",
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      homeDropdownOpen: {},
      homeShareDropdownOpen: {},
      feedDisputeDropdownOpen:{},


      isLoading: false,
      showDisputeModal: false,
      reviewDetails: null,
      showDisputedModal: false,
      disputeModal: false,
      reason: '',

      
      viewProjectImagesModal: false,
      itemsAttachment: [],

      //create review state properties
      overallRatingVote: null,
      mainViewType: 'reviews',
      viewEditReviewStep: 'writeReview',
      isEnableFileUpload: false,
      followUpStatus: false,
      uploadedMediaSet: {
        embedLinks: [],
        uploadedFiles: [],
      },
      reviewTargetListingDetails: {},
      createReviewToggle: {},
      uploadUrl: '/upload/multiuploader/?album=&instance=listing_images&image=undefined&doc=undefined',

      //post states
      postConverstionDelete: null,
      postConverstionDeleteType: null,
      isPostDeleteModalOpen: false,
    };
    this.onMouseEnterCategory = this.onMouseEnterCategory.bind(this);
    this.onMouseLeaveCategory = this.onMouseLeaveCategory.bind(this);
    this.onMouseShareEnterCategory = this.onMouseShareEnterCategory.bind(this);
    this.onMouseShareLeaveCategory = this.onMouseShareLeaveCategory.bind(this);
  }


  getFeedsWithLoading = async (queryParams) => {
      this.props.get_feeds(queryParams);
  };

  componentWillReceiveProps(nextProps) {
    if (
        nextProps.get_reveiew_helpCount &&
        Object.keys(nextProps.get_reveiew_helpCount).length > 0
    ) {
      this.setState({
        get_reveiewhelpCount: nextProps.get_reveiew_helpCount.helpfulcount,
      });
    }
    if (this.state.disputeModal && nextProps?.get_dispute_modal_status?.modal === false) {
      this.setState({ disputeModal: false, showDisputeModal: false, reviewDetails: '', showDisputedModal: false })
      if (nextProps?.get_dispute_modal_status?.refresh) {
        this.handleOnClickFilterType("Reviews", "reviews")
      }
    }

    if("isLoadingFeeds" in nextProps){
      this.setState({
        loading: nextProps.isLoadingFeeds
      })
    }

    if(nextProps.feeds_data){
     if(nextProps.feeds_data.results?.length){
      this.setState({
        enableScrollTillEnd: true,
        checkNextPagination: nextProps.feeds_data?.next ? true: false,
      }); 
     } else{
      this.setState({
         checkNextPagination: false,
         page:  1
        });
     }
    }
  }

  componentDidMount = () => {
    const { page, filterType } = this.state;
    window.scrollTo(0, 0) 
    const queryParams = {
      page,
      [filterType]: true,
    };

    this.getFeedsWithLoading(queryParams);
    
    // const options = {
    //   root: null,
    //   rootMargin: "0px",
    //   threshold: 1.0,
    // };

    // this.observer = new IntersectionObserver(
    //     this.handleObserver.bind(this),
    //     options
    // );

    // this.observer.observe(this.loadingRef);
    window.addEventListener('scroll', this.handleScroll, false);
    window.addEventListener('resize', this.handleScroll, false);
    window.addEventListener('scroll', this.handleScrollFeeds, { passive: false });

    window.addEventListener('fullscreenchange', this.fullScreenChanged, false);
    window.addEventListener('webkitfullscreenchange', this.fullScreenChanged, false);
    window.addEventListener('mozfullscreenchange', this.fullScreenChanged, false);
  };

  fullScreenChanged = (event) => {
    if (document.fullscreenElement && document.fullscreenElement instanceof HTMLVideoElement) {
      document.fullscreenElement.muted = false;
      event.target.play();
    }
    else {
      document.activeElement.muted = true;
    }
  }
  
   componentWillUnmount() {
    this.props.reset_feeds([]);
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.handleScrollFeeds);
  }


  handleScrollFeeds = () => {
    let scrollTillHeight = window.pageYOffset;
    let lastElemHeight = document.getElementById("last-index")?.offsetTop;
    const {page, checkNextPagination, filterType, enableScrollTillEnd} = this.state;
    if (scrollTillHeight > lastElemHeight && checkNextPagination) {
        if (enableScrollTillEnd) {
            this.setState({ 
              page: page+1, 
              enableScrollTillEnd: false,
              checkNextPagination: false
              }, () => {
              const queryParams = {
                page: page,
                [filterType]: true,
              };
                this.getFeedsWithLoading(queryParams);
            });
        }
    }
}
  
  handleScroll = () => {
    var videos = document.getElementsByTagName("video");

    for (let i = 0; i < videos.length; i++) {

      let video = videos[i];

      const isInViewPort = (element) => {
        const rect = element.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }

      if (isInViewPort(video)) {
        video.play();
      } else {
        video.pause();
      }

    }

  }

  toggleCategory = (expand, id, type = "share") => {
    let { homeDropdownOpen, feedDisputeDropdownOpen } = this.state;
    if (type == "share") {
      homeDropdownOpen[id] = !expand;
    }
    if (type == "dispute") {
      feedDisputeDropdownOpen[id] = !expand;
    }

    this.setState({ homeDropdownOpen, feedDisputeDropdownOpen });
  };

  onMouseEnterCategory = (expand, id, type = "share") => {
    let { homeDropdownOpen, feedDisputeDropdownOpen } = this.state;

    if (type == "share") {
      homeDropdownOpen[id] = expand;
    }
    if (type == "dispute") {
      feedDisputeDropdownOpen[id] = expand;
    }

    this.setState({ homeDropdownOpen, feedDisputeDropdownOpen });
  };

  onMouseLeaveCategory = (expand, id, type = "share") => {
    let { homeDropdownOpen, feedDisputeDropdownOpen } = this.state;

    if (type == "share") {
      homeDropdownOpen[id] = expand;
    }
    if (type == "dispute") {
      feedDisputeDropdownOpen[id] = expand;
    }

    this.setState({ homeDropdownOpen, feedDisputeDropdownOpen });
  };

  toggleShareCategory = (expand, id) => {
    let { homeShareDropdownOpen } = this.state;
    homeShareDropdownOpen[id] = !expand;
    this.setState({ homeShareDropdownOpen });
  };

  onMouseShareEnterCategory = (expand, id) => {
    let { homeShareDropdownOpen } = this.state;
    homeShareDropdownOpen[id] = expand;
    this.setState({ homeShareDropdownOpen });
  };

  onMouseShareLeaveCategory = (expand, id) => {
    let { homeShareDropdownOpen } = this.state;
    homeShareDropdownOpen[id] = expand;
    this.setState({ homeShareDropdownOpen });
  };

  toggleFeedMenu = () => {
    this.setState((prevState) => ({
      feedDropdownOpen: !prevState.feedDropdownOpen,
    }));
  };

  onMouseFeedEnter = () => {
    this.setState({ feedDropdownOpen: true });
  };

  onMouseFeedLeave = () => {
    this.setState({ feedDropdownOpen: false });
  };
  
  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    let fetch = false;
    if (this.state.prevY === 0 && Math.abs(this.state.prevY - y) > 200) {
      fetch = true;
    } else if (this.state.prevY >= y) {
      fetch = true;
    }

    const feeds = this.props.feeds_data;

    let nextPage = this.state.page;
    this.setState({
      checkNextPagination: feeds?.next ? true: false
    });

    if (feeds?.next) {
      nextPage += 1;
   
    }

    const queryParams = {
      page: nextPage,
      [this.state.filterType]: true,
    };

    if (fetch && !this.state.loading) {
      this.setState({ prevY: y, page: nextPage, loading: true }, ()=>{
      this.getFeedsWithLoading(queryParams);
      });
    }
  }

  handleOnChangeShareByEmail = (e) => {
    let errors = { ...this.state.shareError };
    if (e.target.value) {
      delete errors[e.target.name];
    }

    this.setState({
      [e.target.name]: e.target.value,
      shareError: errors,
    });
  };

  handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote }) => {
    let key = "listing_id";
    let url = `/api/shareByemail/`;
    let data = {}
    // if (
    //   this.state.myConnectionActivity === "all" ||
    //   this.state.myConnectionActivity === "other"
    // ) {
    //   key = "listing_id";
    // } else if (
    //   this.state.myConnectionActivity === "reviews" ||
    //   this.state.myConnectionActivity === "comments"
    // ) {
    //   key = "review_id";
    // }

    if (this.state.isProfessional) {
      url = `/api/professionalShareByemail/`;
    }

    if (this.state.shareType === "review") {
      data = {
        review_id: this.state.shareId,
        mix_uid: `${shareEmail.split("@")[0]}_${this.state.shareId}_1597643911786`,
        email: shareEmail,
        user_name: shareName,
        optional_note: shareNote
      };
    } else {
      data = {
        listing_id: this.state.shareId,
        mix_uid: `${shareEmail.split("@")[0]}_${this.state.shareId}_1597643911786`,
        email: shareEmail,
        user_name: shareName,
        optional_note: shareNote,
        listing_type: this.state.shareCategory
      };
    }

    this.props.share_by_email(url, data);

    this.setState({
      shareByEmailModalToggle: false,
    });
  };

  handleOnClickShareByEmail = (id, feed) => {
    const professionalListings= ["professionals", "actors", "dentists", "doctors", "lawyers", "accountants"]
    let isProfessional = false, shareType = "listing"
    if (professionalListings.includes(feed?.target?.category?.toLowerCase())) {
      isProfessional = true;
    }
    if (feed?.verb?.includes("review")) {
      shareType = "review"
    }
    this.setState({
      shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
      shareId: id,
      isProfessional,
      shareType,
      shareCategory: feed?.target?.category?.toLowerCase()
    });
  };

  addToList = (type, id, name, status) => {
    
    let message = "",
        url = "",
        nextUrl = `/api/pins/?category=${this.props.category}&searchTerm=${this.state.myConnectionActivity}&page=1`;
    if (status) {
      message = `${name} has been removed from your ${type} list`;
      if (
          this.state.myConnectionActivity === "all" ||
          this.state.myConnectionActivity === "other"
      ) {
        url = `/api/pins/${id}/?type=${type}`;
      } else if (
          this.state.myConnectionActivity === "reviews" ||
          this.state.myConnectionActivity === "comments"
      ) {
        let tab = this.state.myConnectionActivity;
        url = `/api/pins/${id}/?type=${type}&${tab.slice(0, -1)}=true`;
      }
      this.props.remove_from_list(url, message, nextUrl);
    } else {
      url = `/api/pins/?type=${type}&user=`;
      let data = { type };

      if (
          this.state.myConnectionActivity === "all" ||
          this.state.myConnectionActivity === "other"
      ) {
        data.user_entry = id;
      } else if (
          this.state.myConnectionActivity === "reviews" ||
          this.state.myConnectionActivity === "comments"
      ) {
        data.review = id;
      }

      message = `${name} has been added to your ${type} list`;
      this.props.add_to_list(url, data, message, nextUrl);
    }
  };

  handleOnClickFilterType = (selected, filterType) => {
    const queryParams = {
      page: 1,
      [filterType]: true,
    };
    this.props.setFilter();
    this.getFeedsWithLoading(queryParams);
    this.setState({
      selected,
      filterType,
      page:1,
    });
  };

  handleReviewFeedback = async (id, type) => {
    let reviewId = id;
    const body = {
      review: id,
      type: type,
    };

    const feedParams = {
      page: this.state.page,
      [this.state.filterType]: true,
    };

    await this.props.updateReviewFeedback(body, feedParams);
    await this.props.getreviewHelpfulCopunt(reviewId);

    const { page, filterType } = this.state;
    const queryParams = {
      page,
      [filterType]: true,
    };
    await this.getFeedsWithLoading(queryParams);
  };

  updatePinList = (name, feed, type) => {
    const mainCategoryArray = ['actors','doctors','dentists','lawyers','accountants','professionals'];
    let urlPin = 'professionalpins';
    if(!mainCategoryArray.includes(feed.target?.category?.toLowerCase())){
        urlPin = 'pins';
    }

    let id = feed.target?.id;
    let feedId = feed?.id;
    const targetName = feed.target?.name;

    let url = "",
        fetchType = "",
        method = "",
        message = "",
        deleteUrl = "",
        isFetchActive = false;

    let data = {};
    if (type === "review") {
      id = feed.action?.id;
    } else {
      id = feed.target?.id;
    }

    if (!id) {
      console.error("Invalid feed given to update pins");
    }

    if (name === "watchlist") {
      isFetchActive = this.state.pinStatuses[feedId]?.watch_list;
      fetchType = "watch list";
    } else if (name === "favouritelist") {
      isFetchActive = this.state.pinStatuses[feedId]?.favourite_list;
      fetchType = "favourite list";
    } else if (name === "wantlist") {
      isFetchActive = this.state.pinStatuses[feedId]?.want_list;
      fetchType = "want list";
    } else {
      return false;
    }

    if (type === "review") {
      data = {
        type: fetchType,
        review: id,
      };

      if(urlPin === "professionalpins") data.professionaluser_entry = feed.target?.id;
      deleteUrl = `/api/${urlPin}/${id}/?type=${fetchType}&review=true`;
    } else {
      data = {
        type: fetchType,
        user_entry: id,
      };

      if(urlPin === "professionalpins") data.professionaluser_entry = feed.target?.id;
      deleteUrl = `/api/${urlPin}/${id}/?type=${fetchType}`;
    }

    if (!isFetchActive) {
      if (urlPin === "professionalpins") url = `/api/${urlPin}/?`;
      else url = `/api/${urlPin}/?type=${fetchType}&user=`;
      method = "POST";
      message = `${targetName} has been added to your ${fetchType}.`;
    } else {

      data = {
        type: fetchType,
        review: true,
      };
      url = deleteUrl;
      method = "DELETE";

      message = `${targetName} has been removed from your ${fetchType}.`;
    }

    callApi(url, method, data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(message, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
    });
  };

  fetchPinStatusesForFeed = (feed, type) => {
    let params = {};
    let id = feed?.id;
    if (type === "review") {
      params = {
        type: "",
        user: "",
        listing_id: feed?.action?.id,
        pin_review_type: "reviews",
      };
    } else {
      params = {
        type: "",
        user: "",
        listing_id: feed?.target?.id,
      };
    }
    const query = querystring.stringify(params);

    // const mainCategoryArray = ['actors','doctors','dentists','lawyers','accountants','professionals'];
    // let urlPin = 'professionalpins';
    // if(!mainCategoryArray.includes(feed.target?.category?.toLowerCase())){
        let urlPin = 'pins';
    // }

    callApi(`/api/${urlPin}/${query ? "?" + query : ""}`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let data = response[0];
        let pins = {};
        if (!data) {
          return;
        }
        pins.favourite_list = Boolean(data.favourite_list.length);
        pins.want_list = Boolean(data.want_list.length);
        pins.watch_list = Boolean(data.watch_list.length);

        this.setState({
          pinStatuses: {
            [id]: pins,
          },
        });
      }
    });
  };



  handleChange = (e) => {
    const { disputeReview } = this.state;
    disputeReview[e.target.name] = e.target.value;
    this.setState({ disputeReview });
    this.disputeValidate();
  };



  handleHeatedDiscussion = (feed) => {
    this.setState({ showDisputedModal: true, reviewDetails: feed, disputeModal: true })
    return
  };

  handleJuryVoting = (e) => {
    this.setState({ voteReviewModalToggleOne: true });
  };

  handleOpenDisputeStep1 = (feed) => {
    this.setState({
      reviewId: feed.action.id,
      reviewAddedDescription: feed.action,
    });
  };

  toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false, reviewOneToggler, followUpStatus) => {
    this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
    this.setState({
      reviewOneToggler: reviewOneToggler ? true : false,
      followUpStatus: followUpStatus
    })
  }

  handleOnClickUploadModalOk = () => {
    if (this.state.galleryTypeUpload) {
      this.setState({ callGalleryFunction: true })
    }
  }

  getUploadedMedia = ({ mediaSet, links }) => {
    let { uploadedMediaSet } = this.state;
    let uploadedMediaSetCopy = { ...uploadedMediaSet }

    if (this.state.reviewOneToggler) {

      if (this.props.isFollowReviewEnable) {

        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
          const mediaContainer = [...this.props.followReviewMediaSet, ...mediaSet]
          const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
            [item['id'], item])).values()];
          this.props.uploadMediaInReviewMediaSetForFollowReview(mediaSetUnique);
        }
        if (links && Array.isArray(links) && links.length > 0) {
          const embedContainer = [...this.props.followReviewEmbedLinkSetFinalized, ...links]
          const embedSetUnique = [...new Map(embedContainer?.map(item =>
            [item['thumbnail'], item])).values()];
          this.props.finalizedMediaInReviewEmbedLinkSetForFollowReview(embedSetUnique);
        }
      } else {

        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
          const mediaContainer = [...this.props.newReviewMediaSet, ...mediaSet]
          const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
            [item['id'], item])).values()];
          this.props.uploadMediaInReviewMediaSetForNewReview(mediaSetUnique);
        }
        if (links && Array.isArray(links) && links.length > 0) {
          const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
          const embedSetUnique = [...new Map(embedContainer?.map(item =>
            [item['thumbnail'], item])).values()];
          this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
        }
      }
    } else {
      if (this.props.isEditReviewEnabled) {
        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
          const mediaContainer = [...this.props.editReviewMediaSetFinalized, ...mediaSet]
          const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
            [item['id'], item])).values()];
          this.props.uploadMediaInReviewMediaSetForEditReview(mediaSetUnique);
        }
        if (links && Array.isArray(links) && links.length > 0) {
          const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
          const embedSetUnique = [...new Map(embedContainer?.map(item =>
            [item['thumbnail'], item])).values()];
          this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
        }
      }

    }
  }

  removeUploadedMediaSet = (media) => {
    this.setState(prevState => {
      return {
        ...prevState,
        uploadedMediaSet: {
          ...prevState.uploadedMediaSet,
          uploadedFiles: this.state.uploadedMediaSet?.uploadedFiles?.filter(item => item.id !== media?.id)
        }
      }
    })

    this.setState({ deletedMedias: [...this.state.deletedMedias, ...[media]] })
  }

  onPublishReview = () => {
    this.setState({ publishReview: true })
  }
  forceToggleLoginModal = () => {
    this.setState(prevState => ({
      ...this.prevState,
      forceShowLoginModal: !prevState.forceShowLoginModal
    }));
  }

  handleToggleCreateReview = ({ listing_type, listing_id, feed_id, publishReview}) => {
    const url = `/api/${listing_type.toLowerCase()}/${listing_id}/`;
    const { createReviewToggle } = this.state;
    if (createReviewToggle && !createReviewToggle[feed_id]) {
      callApi(url, 'GET').then((response) => {
        if (response && responseCodeValidator(String(response.code))) {
          this.setState({
            reviewTargetListingDetails: { ...response, listing_type },
            createReviewToggle: { ...createReviewToggle, [feed_id]: true }
          })
        }
      });
    }
    else {
      const { page, filterType } = this.state;
      this.setState({
        reviewTargetListingDetails: {},
        createReviewToggle: { ...createReviewToggle, [feed_id]: false }
      });
      if (publishReview) {
        this.setState({
          page: 1,
          enableScrollTillEnd: false,
          checkNextPagination: false,
        }, () => {
          const queryParams = {
            page: page,
            [filterType]: true,
          };
          this.getFeedsWithLoading(queryParams);
        });
      }
    }
  }
  //wrote a review/Review
  renderTypeReview = (feed) => {
    const { overallRatingVote, viewEditReviewStep, mainViewType, uploadedMediaSet, reviewTargetListingDetails, createReviewToggle } = this.state;

    let reactionStatuses = feed?.action?.review_group_status;
    let reactionCounts = feed?.action?.review_group_count;
    const isWriteOwnReview = feed?.verb == "wrote a review" ? !feed?.target?.is_logged_user_review : false;
    const isReviewProfessional = ['actors', 'doctors', 'dentists', 'lawyers', 'accountants', 'professionals'].includes(feed.target?.category?.toLowerCase())
    const comments = feed.action?.review_discussion
      ? feed?.action?.review_discussion
      : [];

    const { my_profile } = this.props;

    if (reactionStatuses == null || reactionStatuses == undefined) {
      return null;
    }
    const profileId = +localStorage.getItem("profileId")
    return (
        <>
        <div className="mb-3">
          {feed.action && feed.action.is_review_flag ? (
              <>{/*}
                <div className="bg-warning text-dark p-2 fs-14">
                  <div className="d-flex flex-wrap mx-n2">
                    
                    <div className="fs-14 px-2 ml-auto text-black">
                      <p className="mb-0 d-inline-block">
                        This review is currently being Disputed.
                      </p>{" "}

                      <span
                          className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                          role="button"
                          onClick={() => this.setState({ watchHowDisputeReviewWorksModalToggle: true })}
                      >
                    <span>Learn More <FontAwesomeIcon icon="arrow-right" /> </span>
                    <div
                        className="on-hover-appear invisble-part"
                        style={{ bottom: "calc(100% + 10px)" }}
                    >
                      <div className="tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                        <div className="fs-14 ff-base">
                          See how Review Disputes work
                          </div>
                        <div className="p-2">
                          <Row className="justify-content-between align-items-center">
                            <Col
                                xs="12"
                                className="d-flex justify-content-center align-items-center"
                            >
                              <div
                                  className="video-holder play-icon d-inline-block mx-2"
                                  role="button"
                                  onClick={() =>
                                      this.setState({
                                        watchHowDisputeReviewWorksModalToggle: true,
                                      })
                                  }
                              >
                                <img
                                    height="80"
                                    src={require("../../../assets/images/dispute_jury.webp")}
                                    alt="dispute"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    </div>
                  </span>

                      <span>{" "}{" "}</span>
                      <span
                          className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                          role="button"
                          onClick={() => this.handleHeatedDiscussion(feed)}
                      >
                    {feed?.action?.flag?.user?.email === my_profile?.user?.email ?
                        <span className="flashing">
                        7 Day Period for Parties to Upload their Response
                          {feed?.action?.flag?.discussion ?
                              feed?.action?.flag?.owner_discussion ?
                                  ' ( BusinessOwner Responded )'
                                  : ' ( Community Voting )'
                              :
                              ''}
                           </span>
                        :
                        <span className="flashing"> View Dispute
                        
                          
                        </span>
                    }

                        <div
                            className="on-hover-appear"
                            style={{ bottom: "calc(100% + 10px)" }}
                        >
                      <div className="help-community-tooltip tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                        Help the community and vote on this review
                      </div>
                    </div>
                  </span>

                    </div>
                  </div>
                </div>
              */}
              </>
          ) : (
              ""
          )}

          <div className="bg-white p-3 fs-14 text-dark">
            <div className="d-flex mx-n2 mb-2">
              <div className="px-2">
                <a
                    href={`/people/${feed.actor?.username}`}
                    className="text-decoration-none"
                >
                  <img
                      className="img-circle _50x50"
                      src={
                        feed.actor?.current_profile_pic?.url
                            ? feed.actor?.current_profile_pic?.url
                            : require("../../../assets/images/icons/user-circle.png")
                      }
                      onError={(error) =>
                          (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                      }
                      alt={feed.actor?.first_name}
                  />
                </a>
              </div>
              <div className="px-2 flex-grow-1">
                <div className="ff-alt">
                  <Link
                    to={`/people/${feed.actor?.username}`}
                    className="text-reset ff-base font-weight-bold"
                  >
                    {feed.actor?.first_name} {feed.actor?.last_name}
                  </Link>
                  &nbsp;
                  <span>{feed.verb} for</span>
                  &nbsp;
                  <Link
                    to={{
                      pathname: `/${feed.target?.category?.toLowerCase()}/${feed.target?.slug ? feed.target?.slug : feed.target?.id
                        }`,
                      state: { id: feed.target?.id },
                    }}
                    className="text-reset font-weight-bold"
                  >
                    {feed?.target?.name}
                  </Link>
                  &nbsp;and gave it &nbsp;
                  <span>
                    <Ratings ratings={feed.action?.ratings_sets} />
                    <span>&nbsp;{feed.time_stamp}</span>
                  </span>
                </div>
              </div>
              <div className="px-2 col-auto">
                <div className="d-inline-flex flex-nowrap mx-n1">
                  <div className="px-1">
                    <UncontrolledDropdown
                        onMouseOver={() => {
                          this.onMouseEnterCategory(true, feed?.id);
                          this.fetchPinStatusesForFeed(feed, "review");
                        }}
                        onMouseLeave={() =>
                            this.onMouseLeaveCategory(false, feed?.id)
                        }
                        isOpen={this.state.homeDropdownOpen[feed?.id]}
                        toggle={() =>
                            this.toggleCategory(this.state.homeDropdownOpen[feed?.id])
                        }
                    >
                      <DropdownToggle
                          className="p-0 text-dark"
                          size="sm"
                          color="transparent"
                      >
                        <div className="thumbpin-icon"></div>
                      </DropdownToggle>
                      <DropdownMenu className="type-tertiary-alt" right>
                        <DropdownItem
                            className="fs-14"
                            onClick={() => {
                              this.updatePinList("watchlist", feed, "review");
                            }}
                        >
                          <div className="addto-icon watch-list"></div>
                          {this.state.pinStatuses[feed?.id]?.watch_list
                              ? "Added"
                              : "Add"}{" "}
                          to watch list
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div className="px-1">
                    <UncontrolledDropdown
                        onMouseOver={() =>
                            this.onMouseShareEnterCategory(true, feed?.id)
                        }
                        onMouseLeave={() =>
                            this.onMouseShareLeaveCategory(false, feed?.id)
                        }
                        isOpen={this.state.homeShareDropdownOpen[feed?.id]}
                        toggle={() =>
                            this.toggleShareCategory(
                                this.state.homeShareDropdownOpen[feed?.id]
                            )
                        }
                    >
                      <DropdownToggle
                          className="p-0 text-dark"
                          size="sm"
                          color="transparent"
                      >
                        <div className="share-it-icon"></div>
                      </DropdownToggle>

                      <DropdownMenu className="type-tertiary-alt" right>
                        <div className="fs-14 dropdown-item">
                          <FacebookShareButton
                              url={`${this.state.protocol}//${this.state.host}/${feed.target && feed.target?.category ? feed.target?.category.toLowerCase() : ''}/${feed.target?.id}/${feed.target?.slug}`}
                              hashtag={this.state.fbHashTag}
                          >
                            Facebook
                          </FacebookShareButton>
                        </div>
                        <div className="fs-14 dropdown-item">
                          <TwitterShareButton
                              url={`${this.state.protocol}//${this.state.host}/${feed.target && feed.target?.category ? feed.target?.category.toLowerCase() : ''}/${feed.target?.id}/${feed.target?.slug}`}
                              via={this.state.shareVia}
                              hashtags={this.state.twitterHashTags}
                          >
                            Twitter
                          </TwitterShareButton>
                        </div>
                        <DropdownItem
                            className="fs-14"
                            onClick={() => this.handleOnClickShareByEmail(feed?.action?.id ? feed?.action?.id : (feed?.target?.id ? feed?.target.id : feed?.id), feed)}
                        >
                          By Email
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  {feed.action && feed.action.is_review_flag ? (
                      ""
                  ) : (
                    feed?.action?.flag?.user?.email === my_profile?.user?.email
                    ?
                    ''
                    :
                      <div className="px-1">
                        { my_profile?.id !== get(feed, 'actor.profile_id') && (
                        <UncontrolledDropdown 
                        onMouseOver={() => {
                          this.onMouseEnterCategory(true, feed?.id, "dispute");
                        }}
                        onMouseLeave={() =>
                            this.onMouseLeaveCategory(false, feed?.id,"dispute")
                        }
                        isOpen={this.state.feedDisputeDropdownOpen[feed?.id]}
                        toggle={() =>
                            this.toggleCategory(this.state.feedDisputeDropdownOpen[feed?.id])
                        }
                        >
                          <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                            <div className="downfeed-icon"></div>
                          </DropdownToggle>
                          <DropdownMenu className="type-tertiary-alt" right>
                            <li className="dropdown-item has-submenu to-left ff-base fs-14">
                              Dispute this review
                              <ul className="dropdown-submenu">
                                <DropdownItem className="fs-14" onClick={() => this.handleDisputeThisReview(feed, 'This review is fake')} >This review is fake</DropdownItem>
                                <DropdownItem className="fs-14" onClick={() => this.handleDisputeThisReview(feed, 'This review is not relevant')} >This review is not relevant</DropdownItem>
                                <DropdownItem className="fs-14" onClick={() => this.handleDisputeThisReview(feed, 'This review is inaccurate')}>This review is inaccurate</DropdownItem>
                              </ul>
                            </li>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        )}
                      </div>                                       
                  )}
                </div>
              </div>
            </div>

            <div className="ff-alt my-4">
              {
                (feed.action?.review_text || feed.action?.professionalreview)
                  ? <>
                    <i className="icon-comma-left"></i>
                    <span className="comment-body-renderer">
                      {ReactHtmlParser(detectlink(feed.action?.review_text || feed.action?.professionalreview))}
                    </span>
                    <i className="icon-comma-right"></i>
                  </>
                  : ""
              }
            </div>
            {feed?.action?.reviewmedia_set.length ?
                <div className="mb-3">
                  {/* Main image/video/audio */}
                  <div className="mb-2 text-center">
                    {feed?.action?.reviewmedia_set[0].media_type === "video" ?
                        <video className="feeds-video-main" controls muted preload="true">
                          <source
                              src={feed?.action?.reviewmedia_set[0].url}
                              type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        : null
                    }
                    {feed?.action?.reviewmedia_set[0].media_type === "audio" ?
                        <audio className="feeds-audio-main" controls autoPlay loop>
                          <source
                              src={feed?.action?.reviewmedia_set[0].url}
                              type="video/mpeg"
                          />
                          Your browser does not support the audio tag.
                        </audio>
                        : null
                    }
                    {feed?.action?.reviewmedia_set[0].media_type === "image" ?
                        <img className="feeds-img-main" src={feed?.action?.reviewmedia_set[0].url} alt="user" />
                        : null
                    }
                  </div>
                  {/* List of other Images/Videos/audios uploaded */}
                  {feed?.action?.reviewmedia_set.length > 0 ?
                      <ul className="list-unstyled feeds-media-list">
                        {feed?.action?.reviewmedia_set
                            .slice(1, feed?.action?.reviewmedia_set.length)
                            .map((media, index) => {
                              return (
                                <li key={index} onClick={() => {
                                  this.openAttachedMediaCarousal(feed?.action?.reviewmedia_set, index + 1);
                                }}>
                                  <div className={'feeds-media-list-item ' + `${media.media_type === 'video' ? 'video-holder' : (media.media_type === 'audio' ? 'audio-holder' : null)}`}>
                                    <img className="feed-media-thumbnail" src={media.thumbnail} alt="user" />
                                  </div>
                                </li>
                              )
                            })}
                      </ul>
                      : null
                  }
                </div>
                :
                null}

            <ConfirmationModal
                isOpen={this.state.isDeleteCommentModalOpen}
                setIsOpen={this.handleCancelDeleteComment}
                onConfirm={this.handleDeleteComment}
            />
            <div className="mt-4">
              <Reactions
                  id={feed.id}
                  counts={reactionCounts}
                  statuses={reactionStatuses}
                  onClick={(reaction, status) =>
                      this.handleFeedReactions(
                          reaction,
                          feed.action?.id,
                          "review",
                          status
                      )
                  }
                  get_reviewgroup={this.props.get_reviewgroup}
                  get_reveiewData={this.props.get_reveiewData}
                  feed={feed}
              />
              {feed?.action?.is_review_flag ?
                  ''
                  :
                  <div className="text-tertiary mb-3">
                    <div>
                      <i className="d-inline-block mr-2 fst-italic">Was this review helpful?</i>
                      <span
                          className={
                            feed?.action?.helpfulCount?.profile_ids?.includes(profileId)
                                ? "font-weight-bold text-dark d-inline-block btn-link"
                                : "font-weight-bold text-tertiary d-inline-block btn-link"
                          }
                          role="button"
                          onClick={() => {
                            this.handleReviewFeedback(feed?.action?.id, "helpful");
                          }}
                      >
                    {"Yes"}
                  </span>
                      <span className="mx-1">-</span>
                      <span
                          className={
                            !feed?.action?.helpfulCount?.profile_ids?.includes(profileId)
                                ? "font-weight-bold text-dark d-inline-block btn-link"
                                : "font-weight-bold text-tertiary d-inline-block btn-link"
                          }
                          role="button"
                          onClick={() => {
                            this.handleReviewFeedback(feed?.action?.id, "not_helpful");
                          }}
                      >
                    {"No"}
                  </span>
                    </div>
                    {feed?.action?.helpfulCount?.helpful > 0 &&
                    feed?.action?.helpfulCount?.total > 0 ? (
                        <div>

                          <span className="text-dark">
                             <span className="font-weight-bold">{feed?.action?.helpfulCount?.helpful} </span>
                             <i className="text-tertiary">of</i>
                            <span className="font-weight-bold"> {feed?.action?.helpfulCount?.total}</span>
                          </span>
                          <i className="text-tertiary d-inline-block ml-2 fst-italic">
                            {feed?.action?.helpfulCount?.total > 1 ? "people found this review as helpful " : "person found this review as helpful"}

                       

                          </i>
                        </div>
                    ) : (
                        <div>
                          <span className="font-weight-bold text-dark ">{`${feed?.action?.helpfulCount?.helpful} `}</span>
                          <i className="text-tertiary d-inline-block ml-2 fst-italic">
                            {"people found this review as helpful"}
                          </i>
                        </div>
                    )}
                  </div>
              }
              {isWriteOwnReview ?
                <div className="mt-4 mb-4 w-100 write-own-review d-flex align-items-center flex-row-reverse">
                  <span onClick={() => this.handleToggleCreateReview({ listing_type: feed?.target?.category, listing_id: feed?.target?.id, feed_id: feed?.id })}>
                    write your own review
                  </span>
                </div>
                : ""
              }
              <ReviewComments
                  feed={feed}
                  comments={comments}
                  onDeleteComment={this.handleStartDeleteComment}
                  onEditComment_Reply={this.handleReviewCommentReply}
                  onCommentReply={this.handleReviewCommentReply}
                  myProfile={this.props.my_profile_data}
                  isReviewProfessional={isReviewProfessional}
              />
              <ConfirmationModal
                  isOpen={this.state.isDeleteCommentModalOpen}
                  setIsOpen={this.handleCancelDeleteComment}
                  onConfirm={this.handleDeleteComment}
              />
              {/*            <div className="mt-2">
              <Reactions
                id={feed.id}
                counts={reactionCounts}
                statuses={reactionStatuses}
                onClick={(reaction) =>
                  this.handleFeedReactions(reaction, feed.id)
                }
                get_reviewgroup={this.props.get_reviewgroup}
                get_reveiewData={this.props.get_reveiewData}
              />
            </div> */}
              {/* Comment box */}
              <div className="bg-light p-2 mt-3">
                <Media className="media-post">
                  <Media>
                    <img
                        className="media-object"
                        src={
                          my_profile?.current_profile_file
                              ? my_profile?.current_profile_file
                              : require("../../../assets/images/icons/user-circle.png")
                        }
                        onError={(error) =>
                            (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                        }
                        alt="user"
                    />
                  </Media>
                  <Media body>
                    <FormGroup className="mb-0">
                      <Input
                          bsSize="sm"
                          className="mb-2 text-primary"
                          type="textarea"
                          name={`reviewComment${feed?.action?.id}`}
                          placeholder="Add your comments"
                          value={this.state[`reviewComment${feed?.action?.id}`]}
                          onChange={(event) =>
                              this.setCommentMessage(
                                  `reviewComment${feed?.action?.id}`,
                                  event.target.value
                              )
                          }
                      />
                      <div className="text-right">
                        <div className="text-right">
                          <Button
                              size="sm"
                              color="primary"
                              onClick={() =>
                                this.onSubmitReviewComment(feed?.action?.id, isReviewProfessional)
                              }
                              disabled={
                                !this.state[`reviewComment${feed?.action?.id}`]
                              }
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </FormGroup>
                  </Media>
                </Media>
              </div>
            </div>
          </div>
        </div>
        {
          createReviewToggle[feed?.id] ?
            <div className="mb-3">
              {
                feed?.target?.category.toLowerCase() == "business"
                  ?
                  <CreateReviewBusiness
                    businessId={feed?.target?.id}
                    businessDetails={reviewTargetListingDetails}
                    mainViewType={mainViewType}
                    handleCloseFeed={this.handleToggleCreateReview}
                    feed={feed}
                  />
                  : <CreateReview
                    itemDetail={reviewTargetListingDetails}
                    overallRatingVote={overallRatingVote}
                    viewEditReviewStep={viewEditReviewStep}
                    mainViewType={mainViewType}
                    listing_type={feed?.target?.category.toLowerCase()}
                    reviews={[]}
                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload) => {
                      this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true, false);
                    }}
                    uploadedMediaSet={uploadedMediaSet}
                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                    onPublishReview={this.onPublishReview}
                    my_review_count={my_profile?.review_statistics?.count}
                    forceToggleLoginModal={this.forceToggleLoginModal}
                    my_profile_data={my_profile}
                    categoryId={feed?.target?.id}
                    resetUploadedMediaSet={this.resetUploadedMediaSet}
                    handleCloseFeed={this.handleToggleCreateReview}
                    feed={feed}
                  />
              }
            </div>
            : ""
        }
      </>
        
    );
  };

  handleEditComment_Reply = ({ body, id, type }) => {
    const queryParams = {
      body: body,
      discussion_id: id,
      edit: true,
    };

    const feedsParams = {
      page: this.state.page,
      [this.state.filterType]: true,
      type,
      newConversation: false,
    };

    this.props.commentOnFeed(queryParams, "PUT", feedsParams);
  };

  handleCommentReply = ({ body, id, type }) => {
    const queryParams = {
      body: body,
      discussion_id: id,
    };

    const feedsParams = {
      page: this.state.page,
      [this.state.filterType]: true,
      type,
      newConversation: true
    };

    this.props.commentOnFeed(queryParams, "PUT", feedsParams);
  };

  handleReviewComment = (e) => {
    this.setState({
      reviewComment: e.target.value,
    });
  };

  handleReviewCommentReply = ({ body, id, review, method, isReviewProfessional }) => {
    if (body === "") {
      toast(`Please type your comment`, {
        autoClose: 3500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      return;
    }

    const params = {
      body: body,
      review: review,
      media: [],
      parent_id: id,
    };

    const queryParams = {
      review: review,
    };

    const feedsParams = {
      page: this.state.page,
      [this.state.filterType]: true,
      isReviewProfessional
    };

    this.props.commentOnReviews(
        queryParams,
        params,
        method ? "PUT" : "POST",
        feedsParams
    );
  };

  handleFeedbackCommentReply = ({ body, id, method }) => {
    if (body === "") {
      toast(`Please type your answer`, {
        autoClose: 3500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      return;
    }

    const params = {
      answer_id: id,
      body: body,
      type: "biz_msg",
    };

    const feedsParams = {
      page: this.state.page,
      [this.state.filterType]: true,
    };

    this.props.updateFeedbackDiscussion(
        params,
        method ? "PUT" : "POST",
        feedsParams
    );
  };

  renderTypeAnsweredQuestion = (feed) => {
    let reactionStatuses = null;
    let reactionCounts = null;

    if (feed?.review_group_animation) {
      reactionStatuses = feed?.review_group_animation?.review_group_status;
      reactionCounts = feed?.review_group_animation?.review_group_count;
    } else {
      reactionStatuses = feed?.contribution_group?.contribution_group_status;
      reactionCounts = feed?.contribution_group?.contribution_group_count;
    }

    const { my_profile } = this.props;

    return (
        <div className="mb-3">
          <div className="bg-white p-3 fs-14 text-dark">
            <div className="d-flex mx-n2 mb-2">
              <div className="px-2">
                <Link to="/" className="text-decoration-none">
                  <img
                      className="img-circle _50x50"
                      src={
                        feed.actor?.current_profile_pic?.url
                            ? feed.actor?.current_profile_pic?.url
                            : require("../../../assets/images/icons/user-circle.png")
                      }
                      alt={feed.actor?.sender}
                  />
                </Link>
              </div>
              <div className="px-2 flex-grow-1">
                <div className="ff-alt">
                  <a href="#" className="text-reset ff-base font-weight-bold">
                    {feed.action?.sender}
                  </a>
                  &nbsp;
                  <span>
                  {`asked the ${feed?.action?.recipients} a question about 
                  ${feed?.action?.question_about
                      ? `${feed?.action?.question_about} for`
                      : ""
                  }`}
                </span>
                  &nbsp;
                  <Link
                      to={{
                        pathname: `/${feed.target?.category?.toLowerCase()}/${feed.target?.slug ? feed.target?.slug : feed.target?.id
                        }`,
                        state: { id: feed.target?.id },
                      }}
                      className="text-reset font-weight-bold"
                  >
                    {feed?.target?.name}
                  </Link>
                  &nbsp;
                  <span>{feed.action?.sent_at}</span>
                </div>
              </div>
              <div className="px-2 col-auto">
                <div className="d-inline-flex flex-nowrap mx-n1">
                  <div className="px-1">
                    <UncontrolledDropdown
                        onMouseOver={() => {
                          this.onMouseEnterCategory(true, feed?.id);
                          this.fetchPinStatusesForFeed(feed);
                        }}
                        onMouseLeave={() =>
                            this.onMouseLeaveCategory(false, feed?.id)
                        }
                        isOpen={this.state.homeDropdownOpen[feed?.id]}
                        toggle={() =>
                            this.toggleCategory(this.state.homeDropdownOpen[feed?.id])
                        }
                    >
                      <DropdownToggle
                          className="p-0 text-dark"
                          size="sm"
                          color="transparent"
                      >
                        <div className="thumbpin-icon"></div>
                      </DropdownToggle>
                      <DropdownMenu className="type-tertiary-alt" right>
                        <DropdownItem
                            onClick={() => {
                              this.updatePinList("wantlist", feed);
                            }}
                            className="fs-14"
                        >
                          <div className="addto-icon want-list"></div>
                          {this.state.pinStatuses[feed?.id]?.want_list
                              ? "Added"
                              : "Add"}{" "}
                          to want list
                        </DropdownItem>
                        <DropdownItem
                            className="fs-14"
                            onClick={() => {
                              this.updatePinList("favouritelist", feed);
                            }}
                        >
                          <div className="addto-icon favorite-list"></div>
                          {this.state.pinStatuses[feed?.id]?.favourite_list
                              ? "Added"
                              : "Add"}{" "}
                          to favorites
                        </DropdownItem>
                        <DropdownItem
                            className="fs-14"
                            onClick={() => {
                              this.updatePinList("watchlist", feed);
                            }}
                        >
                          <div className="addto-icon watch-list"></div>
                          {this.state.pinStatuses[feed?.id]?.watch_list
                              ? "Added"
                              : "Add"}{" "}
                          to watch list
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div className="px-1">
                    <UncontrolledDropdown
                        onMouseOver={() =>
                            this.onMouseShareEnterCategory(true, feed?.id)
                        }
                        onMouseLeave={() =>
                            this.onMouseShareLeaveCategory(false, feed?.id)
                        }
                        isOpen={this.state.homeShareDropdownOpen[feed?.id]}
                        toggle={() =>
                            this.toggleShareCategory(
                                this.state.homeShareDropdownOpen[feed?.id]
                            )
                        }
                    >
                      <DropdownToggle
                          className="p-0 text-dark"
                          size="sm"
                          color="transparent"
                      >
                        <div className="share-it-icon"></div>
                      </DropdownToggle>
                      <DropdownMenu className="type-tertiary-alt" right>
                        {" "}
                        <DropdownItem className="fs-14">
                          <FacebookShareButton
                              url={`${this.state.protocol}//${this.state.host}/${feed.target && feed.target?.category ? feed.target?.category.toLowerCase() : ''}/${feed.target?.id}/${feed.target?.slug}`}
                              hashtag={this.state.fbHashTag}
                          >
                            Facebook
                          </FacebookShareButton>
                        </DropdownItem>
                        {/* {<DropdownItem className="fs-14">Google +</DropdownItem>} */}
                        <DropdownItem className="fs-14">
                          <TwitterShareButton
                              url={`${this.state.protocol}//${this.state.host}/${feed.target && feed.target?.category ? feed.target?.category.toLowerCase() : ''}/${feed.target?.id}/${feed.target?.slug}`}
                              via={this.state.shareVia}
                              hashtags={this.state.twitterHashTags}
                          >
                            Twitter
                          </TwitterShareButton>
                        </DropdownItem>
                        <DropdownItem
                            className="fs-14"
                            // onClick={() =>
                            //   this.setState({
                            //     shareByEmailModalToggle: true,
                            //   })
                            // }
                            onClick={() => this.handleOnClickShareByEmail(feed?.action?.id ? feed.action.id : feed?.id, feed)}
                        >
                          By Email
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  {/* Dispute dropdown icon */}
                  {/* <div className="px-1">
                  {feed.action && feed.action.is_review_flag ? (
                    ""
                  ) : (
                    <div className="px-1">

                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="p-0 text-dark"
                          size="sm"
                          color="transparent"
                        >
                          <div className="downfeed-icon"></div>
                        </DropdownToggle>
                        <DropdownMenu className="type-tertiary-alt" right>
                          <DropdownItem
                            onClick={() =>
                              this.setState({
                                disputeThisReviewModalToggle: true,
                                reviewId: feed.action.id,
                                reviewAddedDescription: feed.action?.body,
                                reviewAddedUser: feed.actor?.username,
                                reviewAddedFor: feed?.target?.name,
                                reviewAddedOn: feed.time_stamp,
                                reviewAdded: feed,
                                reviewAddedUserImage: feed.actor
                                  ?.current_profile_pic?.url
                                  ? feed.actor?.current_profile_pic?.url
                                  : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png",
                              })
                            }

                          >

                            Dispute this review
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  )}
                </div> */}
                </div>
              </div>
            </div>

          <div className="ff-alt my-4">{feed?.action?.body}</div>
          {feed?.action?.attachments.length ?
            <div className="mb-3">
              {/* Main image/video/audio */}
              <div className="mb-2 text-center">
                {feed?.action?.attachments[0].media_type === "video" ?
                  <video className="feeds-video-main" controls muted preload="true">
                    <source
                      src={feed?.action?.attachments[0].url}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  : null
                }
                {feed?.action?.attachments[0].media_type === "audio" ?
                  <audio className="feeds-audio-main" controls autoPlay loop>
                    <source
                      src={feed?.action?.attachments[0].url}
                      type="video/mpeg"
                    />
                    Your browser does not support the audio tag.
                  </audio>
                  : null
                }
                {feed?.action?.attachments[0].media_type === "image" ?
                  <img className="feeds-img-main" src={feed?.action?.attachments[0].url} alt="user" />
                  : null
                }
              </div>
              {/* List of other Images/Videos/audios uploaded */}
              {feed?.action?.attachments.length > 0 ?
                <ul className="list-unstyled feeds-media-list">
                  {feed?.action?.attachments
                    .slice(1, feed?.action?.attachments.length)
                    .map((media, index) => {
                      return (
                        <li key={index} onClick={() => this.openAttachedMediaCarousal(feed?.action?.attachments, index + 1)}>
                          <div className={`feeds-media-list-item ` + `${media.media_type === 'video' ? 'video-holder' : (media.media_type === 'audio' ? 'audio-holder' : null)}`}>
                            <img className="feed-media-thumbnail" src={media.thumbnail} alt="user" />
                          </div>
                        </li>
                      )
                    })}
                </ul>
                : null
              }
            </div>
            :
            null}
          <div className="mb-3">
            <FeedbackComments
              feed={feed}
              comments={
                feed.action?.conversation ? feed?.action?.conversation : []
              }
              onDeleteComment={this.handleStartDeleteComment}
              onEditComment_Reply={this.handleFeedbackCommentReply}
              onCommentReply={this.handleFeedbackCommentReply}
              onSubmitFeedbackComment={this.onSubmitFeedbackComment}
              myProfile={this.props.my_profile_data}
            />
            <ConfirmationModal
              isOpen={this.state.isDeleteCommentModalOpen}
              setIsOpen={this.handleCancelDeleteComment}
              onConfirm={this.handleDeleteComment}
            />
          </div>
          <Reactions
            id={feed.id}
            counts={reactionCounts}
            statuses={reactionStatuses}
            onClick={(reaction) => this.handleFeedReactions(reaction, feed.id)}
            get_reviewgroup={this.props.get_reviewgroup}
            get_reveiewData={this.props.get_reveiewData}
            feed={feed}
          />
          {/* Comment box */}
          <div className="bg-light p-2 mt-3">
            <Media className="media-post">
              <Media>
                <img
                  className="media-object"
                  src={
                    my_profile?.current_profile_file
                      ? my_profile?.current_profile_file
                      : require("../../../assets/images/icons/user-circle.png")
                  }
                  onError={(error) =>
                    (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                  }
                  alt="Comment User"
                />
              </Media>
              <Media body>
                <FormGroup className="mb-0">
                  <Input
                    bsSize="sm"
                    className="mb-2 primary text-primary custom-feed-input"
                    type="textarea"
                    rows={2}
                    placeholder="Do you know the answer?"
                    name={`answerComment${feed?.action?.id}`}
                    value={this.state[`answerComment${feed?.action?.id}`]}
                    onChange={(event) =>
                      this.setCommentMessage(
                        `answerComment${feed?.action?.id}`,
                        event.target.value
                      )
                    }
                  />
                  <div className="text-right">
                    <div className="text-right">
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() =>
                          this.onSubmitFeedbackComment(
                            feed.target?.userentry
                              ? feed.target?.userentry
                              : "",
                            feed.action?.id ? feed.action?.id : ""
                          )
                        }
                        disabled={
                          !this.state[`answerComment${feed?.action?.id}`]
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </FormGroup>
              </Media>
            </Media>
          </div>
        </div>
      </div>
    );
  };

  handleComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  handleFeedbackComment = (e) => {
    this.setState({
      feedbackComment: e.target.value,
    });
  };

  handleStartDeleteComment = (id, entity, isReviewProfessional) => {
    this.setState({
      commentIdToDelete: id,
      isDeleteCommentModalOpen: true,
      commentEntityToDelete: entity,
      isReviewCommentProfessional: isReviewProfessional,
    });
  };

  handleCancelDeleteComment = () => {
    this.setState({
      commentIdToDelete: null,
      commentEntityToDelete: null,
      isDeleteCommentModalOpen: false,
      isReviewCommentProfessional: null
    });
  };

  handleDeleteComment = () => {
    const { isReviewCommentProfessional, commentIdToDelete, commentEntityToDelete, page, filterType } = this.state;
    const queryParams = {
      entityid: commentIdToDelete,
      entity: commentEntityToDelete,
    };
    const professionalQueryParams ={
      id: commentIdToDelete,
      type: commentEntityToDelete,
    };
    const feedsParams = {
      page: page,
      [filterType]: true,
      isReviewCommentProfessional
    };

    this.props.deleteComments(isReviewCommentProfessional ? professionalQueryParams : queryParams, feedsParams);
    this.setState({
      commentIdToDelete: null,
      commentEntityToDelete: null,
      isDeleteCommentModalOpen: false,
      isReviewCommentProfessional: null
    });
  };

  onSubmitComment = (id) => {
    if ( this.state[`reply${id}`] === undefined || this.state[`reply${id}`] === "" ) {
      this.setState({
        editFeedError: "Please provide message content to send this message",
      });
    } else {
      const params = {
        body: this.state[`reply${id}`] ? this.state[`reply${id}`] : "",
        action_id: id,
      };
      const feedsParams = {
        page: this.state.page,
        [this.state.filterType]: true,
      };
      this.props.commentOnFeed(params, "POST", feedsParams);
      if (this.state[`reply${id}`]) {
        this.setState({
          [`reply${id}`]: "",
        });
      }
    }
  };

  onSubmitReviewComment = (id, isReviewProfessional) => {
    const comment = this.state[`reviewComment${id}`]
        ? this.state[`reviewComment${id}`].trim()
        : "";
    if (!comment) {
      toast(`Please type your comment!`, {
        autoClose: 3500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      return;
    }
    const queryParams = {
      review: id,
    };

    const params = {
      body: this.state[`reviewComment${id}`],
      review: id,
      media: [],
    };

    const feedParams = {
      page: this.state.page,
      [this.state.filterType]: true,
      isReviewProfessional,
    };

    this.props.commentOnReviews(queryParams, params, "POST", feedParams);
    this.setState({
      [`reviewComment${id}`]: "",
    });
  };

  onSubmitFeedbackComment = (user_entry, id) => {
    const comment = this.state[`answerComment${id}`]
        ? this.state[`answerComment${id}`].trim()
        : "";

    if (!comment) {
      toast(`Please type your comment!`, {
        autoClose: 3500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      return;
    }

    if (!id || !user_entry) {
      toast(`Invlid Id!`, {
        autoClose: 3500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      return;
    }

    const params = {
      body: comment,
      messagemedia_set: [],
      tag_list: [],
      user_entry: user_entry,
    };

    const feedParams = {
      page: this.state.page,
      [this.state.filterType]: true,
    };

    this.props.submitCommentOnFeedbacks(id, params, "PUT", feedParams);
    this.setState({
      [`answerComment${id}`]: "",
    });
  };

  setCommentMessage = (name, value) => {
    this.setState({
      [name]: value,
      editFeedError: "",
    });
  };

  feedsCheck = ({ verb_text, feed }) => {
    return (
        <div className="px-2 flex-grow-1">
          <div className="ff-alt">
          <Link
            to={`/people/${feed.actor?.username}`}
            className="text-reset ff-base font-weight-bold"
          >
            {feed?.action?.sender ||
              `${feed?.actor.first_name} ${feed?.actor.last_name}`}
          </Link>
            {` ${verb_text} `}
            <Link
                to={{
                  pathname: `/${feed.target?.category?.toLowerCase()}/${feed.target?.slug ? feed.target?.slug : feed.target?.id
                  }`,
                  state: { id: feed.target?.id },
                }}
                className="text-reset font-weight-bold"
            >
              {feed?.target?.name}
            </Link>
            &nbsp;
          </div>
          <div>{feed?.action?.sent_at || feed?.time_stamp}</div>
        </div>
    );
  };

  //   returnFeedsTypes = (feed) => {
  //     let verb_text = "";
  //     let feed_type = "";

  //     if (feed?.verb == "asked a question about") {
  //       // asked a question about
  //       feed_type = "asked_a_question";
  //       if (feed?.action?.recipients != "friends") {
  //         verb_text += "asked the  community and their friends";
  //       }

  //       if (feed?.action?.question_about) {
  //         verb_text += " a question about Restaurant Cleanliness for ";
  //       }
  //     } else if (feed?.verb == "commented on a discussion") {
  //       // commented on a discussion
  //       feed_type = "commented_a_discussion";
  //       verb_text += "created a discussion for";
  //     } else if (feed?.verb == "answered a question on") {
  //       feed_type = "answered_a_question_on";
  //       if (feed?.action?.recipients != "friends") {
  //         verb_text += " asked the  community ";
  //       }

  //       if (!feed?.action?.question_about) {
  //         verb_text += "a question about";
  //       }
  //     } else if (feed?.verb == "feed-others") {
  //       // thank you + feed-others
  //       feed_type = "feed_others";
  //       if (
  //         feed?.target?.category != "Projects" &&
  //         feed?.verb != "added new product" &&
  //         feed?.verb != "added new movie" &&
  //         feed?.verb != "added new business" &&
  //         feed?.verb != "added people working here for" &&
  //         feed?.verb != "added website link for" &&
  //         feed?.verb != "added writer for"
  //       ) {
  //         verb_text += "added an employee to";
  //       }
  //     } else if (feed.verb == "added media for") {
  //       // thank you + added media for
  //       feed_type = "added_media_for";
  //       verb_text += "added media for";
  //     } else if (feed.verb == "wrote a review") {
  //       // wrote a review
  //       feed_type = "wrote_a_review";
  //       if (feed.action && feed.action.review_type == "Video") {
  //         verb_text += "created a video review for";
  //       }
  //     } else {
  //       feed_type = "recommends";
  //       if (
  //         feed?.target?.category != "Projects" &&
  //         feed?.verb != "added new product" &&
  //         feed?.verb != "added new movie" &&
  //         feed?.verb != "added new business" &&
  //         feed?.verb != "added people working here for" &&
  //         feed?.verb != "added website link for" &&
  //         feed?.verb != "added writer for"
  //       ) {
  //         verb_text = "recommends";
  //       }
  //     }

  //     let reactionSatuses = null;
  //     let reactionCounts = null;
  //     if (feed?.review_group_animation) {
  //       reactionSatuses = feed?.review_group_animation?.review_group_status;
  //       reactionCounts = feed?.review_group_animation?.review_group_count;
  //     } else {
  //       reactionSatuses = feed?.contribution_group?.contribution_group_status;
  //       reactionCounts = feed?.contribution_group?.contribution_group_count;
  //     }

  //     return {
  //       verb_text,
  //       feed_type,
  //       reactionSatuses,
  //       reactionCounts,
  //     };
  //   };

  //   renderSubmitCommentBox = ({ my_profile, feed, type }) => {
  //     let placeholder = "Add your comments";
  //     let glow = false;

  //     if (
  //       (type == "asked_a_question" || type == "answered_a_question_on") &&
  //       (feed.target.category != "Products" || feed.action.type == "question")
  //     ) {
  //       placeholder = "Do you know the answer?";
  //       glow = true;
  //     }

  //     if (type == "commented_a_discussion") {
  //       glow = true;
  //     }

  //     return (
  //       <div className="bg-light p-2 mt-3">
  //         <Media className="media-post">
  //           <Media>
  //             <img
  //               className="media-object"
  //               src={
  //                 my_profile?.current_profile_file
  //                   ? my_profile?.current_profile_file
  //                   : require("../../../assets/images/icons/user.png")
  //               }
  //               onError={(error) =>
  //                 (error.target.src = require("../../../assets/images/icons/user-circle.png"))
  //               }
  //               alt="user"
  //             />
  //           </Media>
  //           <Media body>
  //             <FormGroup className="mb-0">
  //               <Input
  //                 bsSize="sm"
  //                 className={glow ? "mb-2 glow-input" : "mb-2"}
  //                 type="textarea"
  //                 name={`reply${feed.id}`}
  //                 placeholder={placeholder}
  //                 value={this.state[`reply${feed.id}`]}
  //                 onChange={(event) =>
  //                   this.setCommentMessage(`reply${feed.id}`, event.target.value)
  //                 }
  //               />
  //               <span className="text-error">{this.state.editFeedError}</span>
  //               <div className="text-right">
  //                 <div className="text-right">
  //                   <Button
  //                     size="sm"
  //                     color="primary"
  //                     onClick={() => this.onSubmitComment(feed.id)}
  //                   >
  //                     Submit
  //                   </Button>
  //                 </div>
  //               </div>
  //             </FormGroup>
  //           </Media>
  //         </Media>
  //       </div>
  //     );
  //   };

  renderRecommendsAndOtherProject = (feed) => {
    let reactionStatuses = null;
    let reactionCounts = null;

    if (feed?.review_group_animation) {
      reactionStatuses = feed?.review_group_animation?.review_group_status;
      reactionCounts = feed?.review_group_animation?.review_group_count;
    } else {
      reactionStatuses = feed?.contribution_group?.contribution_group_status;
      reactionCounts = feed?.contribution_group?.contribution_group_count;
    }

    const { my_profile } = this.props;
    return (
        <div className="bg-white p-3 fs-14 text-dark mb-3">
          <div className="d-flex mx-n2 mb-2">
            <div className="px-2">
              <a
                  href={`/people/${feed.actor?.username}`}
                  className="text-decoration-none"
              >
                <img
                    className="img-circle _50x50"
                    src={
                      feed.actor?.current_profile_pic?.url
                          ? feed.actor?.current_profile_pic?.url
                          : require("../../../assets/images/icons/user-circle.png")
                    }
                    onError={(error) =>
                        (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                    }
                    alt={feed.actor?.first_name}
                />
              </a>
            </div>
            <div className="px-2 flex-grow-1">
              <div className="ff-alt">
              <Link
                to={`/people/${feed.actor?.username}`}
                className="text-reset ff-base font-weight-bold"
              >
                {feed.actor?.first_name} {feed.actor?.last_name}
              </Link>
                &nbsp;
                <span>{
                feed.verb === "status" ? "updated a status as": feed.verb
                }</span> {/*updated her profile picture*/}
                {feed.verb === "status"? <><span className="text-reset ff-base font-weight-bold">{feed.action.body_preview}</span></> 
                :
                <Link
                    to={{
                      pathname: `/${feed.target?.category?.toLowerCase()}/${feed.target?.slug ? feed.target?.slug : feed.target?.id
                      }`,
                      state: { id: feed.target?.id },
                    }}
                    className="text-reset font-weight-bold"
                >
                  {feed?.target?.name}
                </Link>}
                &nbsp;
              </div>
              <div>{feed.time_stamp}</div>
            </div>
            <div className="px-2 col-auto">
              {!(feed.verb && ((feed.verb.includes('updated') && (feed.verb.includes('profile') || feed.verb.includes('cover'))) || feed.verb.includes('status'))) && (
                  <div className="d-inline-flex flex-nowrap mx-n1">
                    <div className="px-1">
                      <UncontrolledDropdown
                          onMouseOver={() => {
                            this.onMouseEnterCategory(true, feed?.id);
                            this.fetchPinStatusesForFeed(feed);
                          }}
                          onMouseLeave={() =>
                              this.onMouseLeaveCategory(false, feed?.id)
                          }
                          isOpen={this.state.homeDropdownOpen[feed?.id]}
                          toggle={() =>
                              this.toggleCategory(this.state.homeDropdownOpen[feed?.id])
                          }
                      >
                        <DropdownToggle
                            className="p-0 text-dark"
                            size="sm"
                            color="transparent"
                        >
                          <div className="thumbpin-icon"></div>
                        </DropdownToggle>
                        <DropdownMenu className="type-tertiary-alt" right>
                          <DropdownItem
                              onClick={() => {
                                this.updatePinList("wantlist", feed);
                              }}
                              className="fs-14"
                          >
                            <div className="addto-icon want-list"></div>
                            {this.state.pinStatuses[feed?.id]?.want_list
                                ? "Added"
                                : "Add"}{" "}
                            to want list
                          </DropdownItem>
                          <DropdownItem
                              className="fs-14"
                              onClick={() => {
                                this.updatePinList("favouritelist", feed);
                              }}
                          >
                            <div className="addto-icon favorite-list"></div>
                            {this.state.pinStatuses[feed?.id]?.favourite_list
                                ? "Added"
                                : "Add"}{" "}
                            to favorites
                          </DropdownItem>
                          <DropdownItem
                              className="fs-14"
                              onClick={() => {
                                this.updatePinList("watchlist", feed);
                              }}
                          >
                            <div className="addto-icon watch-list"></div>
                            {this.state.pinStatuses[feed?.id]?.watch_list
                                ? "Added"
                                : "Add"}{" "}
                            to watch list
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div className="px-1">
                      <UncontrolledDropdown
                          onMouseOver={() =>
                              this.onMouseShareEnterCategory(true, feed?.id)
                          }
                          onMouseLeave={() =>
                              this.onMouseShareLeaveCategory(false, feed?.id)
                          }
                          isOpen={this.state.homeShareDropdownOpen[feed?.id]}
                          toggle={() =>
                              this.toggleShareCategory(
                                  this.state.homeShareDropdownOpen[feed?.id]
                              )
                          }
                      >
                        <DropdownToggle
                            className="p-0 text-dark"
                            size="sm"
                            color="transparent"
                        >
                          <div className="share-it-icon"></div>
                        </DropdownToggle>
                        <DropdownMenu className="type-tertiary-alt" right>
                          <div className="fs-14 dropdown-item">
                            <FacebookShareButton
                                url={`${this.state.protocol}//${this.state.host}/${feed.target && feed.target?.category ? feed.target?.category.toLowerCase() : ''}/${feed.target?.id}/${feed.target?.slug}`}
                                hashtag={this.state.fbHashTag}
                            >
                              Facebook
                            </FacebookShareButton>
                          </div>
                          {/* {<DropdownItem className="fs-14">Google +</DropdownItem>} */}
                          <div className="fs-14 dropdown-item">
                            <TwitterShareButton
                                url={`${this.state.protocol}//${this.state.host}/${feed.target && feed.target?.category ? feed.target?.category.toLowerCase() : ''}/${feed.target?.id}/${feed.target?.slug}`}
                                via={this.state.shareVia}
                                hashtags={this.state.twitterHashTags}
                            >
                              Twitter
                            </TwitterShareButton>
                          </div>
                          <DropdownItem
                              className="fs-14"
                              // onClick={() =>
                              //   this.setState({
                              //     shareByEmailModalToggle: true,
                              //   })
                              // }
                              onClick={() => this.handleOnClickShareByEmail(feed?.action?.id ? feed.action.id : feed?.id, feed)}
                          >
                            By Email
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
              )}
            </div>
          </div>

          {feed.verb && feed.target && feed.verb.includes('updated') && (feed.verb.includes('profile') || feed.verb.includes('cover')) && (
              <div className="mb-3">
                {feed.verb.includes('profile') && (
                    <img
                        className="img-fluid"
                        src={
                          feed.target.profile_pic
                              ? feed.target.profile_pic
                              : require("../../../assets/images/icons/user-circle.png")
                        }
                        onError={(error) =>
                            (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                        }
                        alt="attachment"
                    />
                )}
                {feed.verb.includes('cover') && (
                    <img
                        className="img-fluid"
                        src={
                          feed.target.cover_pic
                              ? feed.target.cover_pic
                              : require("../../../assets/images/icons/user-circle.png")
                        }
                        onError={(error) =>
                            (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                        }
                        alt="attachment"
                    />
                )}
              </div>
          )}

          <div className="mb-2 text-center">
            <img
                className="img-fluid"
                src={feed.target?.project_image_path}
                onError={(error) =>
                    (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                }
                alt=""
            />
          </div>
          <Comments
              feed={feed}
              comments={feed.comments && feed.comments}
              onDeleteComment={this.handleStartDeleteComment}
              onEditComment_Reply={this.handleEditComment_Reply}
              onCommentReply={this.handleCommentReply}
              myProfile={this.props.my_profile_data}
          />
          <ConfirmationModal
              isOpen={this.state.isDeleteCommentModalOpen}
              setIsOpen={this.handleCancelDeleteComment}
              onConfirm={this.handleDeleteComment}
          />
          <div className="mt-4">
            <Reactions
                id={feed.id}
                counts={reactionCounts}
                statuses={reactionStatuses}
                onClick={(reaction) => this.handleFeedReactions(reaction, feed.id)}
                get_reviewgroup={this.props.get_reviewgroup}
                get_reveiewData={this.props.get_reveiewData}
                feed={feed}
            />
          </div>
          {/* Comment box */}
          <div className="bg-light p-2 mt-3">
            <Media className="media-post">
              <Media>
                <img
                    className="media-object"
                    src={
                      my_profile?.current_profile_file
                          ? my_profile?.current_profile_file
                          : require("../../../assets/images/icons/user.png")
                    }
                    onError={(error) =>
                        (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                    }
                    alt="User"
                />
              </Media>
              <Media body>
                <FormGroup className="mb-0">
                  <Input
                      bsSize="sm"
                      className="mb-2 text-primary"
                      type="textarea"
                      name={`reply${feed.id}`}
                      placeholder="Add your comments"
                      value={this.state[`reply${feed.id}`]}
                      onChange={(event) =>
                          this.setCommentMessage(
                              `reply${feed.id}`,
                              event.target.value
                          )
                      }
                  />
                  <div className="text-right">
                    <div className="text-right">
                      <Button
                          size="sm"
                          color="primary"
                          onClick={() => this.onSubmitComment(feed.id)}
                          disabled={!this.state[`reply${feed.id}`]}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </FormGroup>
              </Media>
            </Media>
          </div>
        </div>
    );
  };
  handleStartDeletePostComment = (id, entity, isReviewProfessional, conversation, type) => {
    this.setState({
      commentIdToDelete: id,
      isPostDeleteModalOpen: true,
      commentEntityToDelete: entity,
      isReviewCommentProfessional: isReviewProfessional,
      postConverstionDelete: conversation,
      postConverstionDeleteType: type
    });
  }
  handleCancelPostDeleteComment = () => {
    this.setState({
      commentIdToDelete: null,
      commentEntityToDelete: null,
      isPostDeleteModalOpen: false,
      isReviewCommentProfessional: null,
      postConverstionDelete: null,
      postConverstionDeleteType: null,
    });
  };

  handleDeletePostComment = () =>{
    const { postConverstionDeleteType, commentIdToDelete, page, filterType } = this.state;
    const feedsParams = {
      page: page,
      [filterType]: true,

    };

    this.props.deletePostCommentReplyOnFeed(commentIdToDelete, postConverstionDeleteType, feedsParams);
    this.setState({
      commentIdToDelete: null,
      commentEntityToDelete: null,
      isPostDeleteModalOpen: false,
      isReviewCommentProfessional: null,
      postConverstionDelete: null,
      postConverstionDeleteType: null,
    });
  }

  handlePostEditComment = ({ body, id, type, postConversation }) => {
    const data = {
      ...postConversation,
      body,
      body_preview: body,
      editpost: true
    }
    const feedParams = {
      page: this.state.page,
      [this.state.filterType]: true,
    };

    this.props.addPostCommentOnFeed({ data, id, method: "PUT", feedParams });
  }
  
  handleOnSubmitPostComment = ({ id }) => {
    const comment = this.state[`reply${id}`]
      ? this.state[`reply${id}`].trim()
      : "";
    if (!comment) {
      toast(`Please type your comment!`, {
        autoClose: 3500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      return;
    }
    const otherDetails = {
      owner_premium: "False",
      [id]: comment,
      exclude_list: [],
      editExcludeListing: [],
      tag_list: [],
      listing_tag_list: [],
      question_rating_category: [],
      messagemedia_set: [],
    };
    const data = { body: comment, body_preview: comment, ...otherDetails }

    const feedParams = {
      page: this.state.page,
      [this.state.filterType]: true,
    };

    this.props.addPostCommentOnFeed({ data, id, method: "PUT", feedParams });

    if (this.state[`reply${id}`]) {
      this.setState({
        [`reply${id}`]: "",
      });
    }

  }

  handleOnPostCommentReply = ({ body, id, type }) => {

    const data = {
      body,
      answer_id: id,
      type: "biz_msg"
    }

    const feedParams = {
      page: this.state.page,
      [this.state.filterType]: true,
    };

    this.props.addPostCommentReplyOnFeed({ data, method: "POST", feedParams });
  }

  renderTypeStatus = (feed) => {
    let reactionStatuses = null;
    let reactionCounts = null;

    if (feed?.review_group_animation) {
      reactionStatuses = feed?.review_group_animation?.review_group_status;
      reactionCounts = feed?.review_group_animation?.review_group_count;
    } else {
      reactionStatuses = feed?.contribution_group?.contribution_group_status;
      reactionCounts = feed?.contribution_group?.contribution_group_count;
    }

    const { my_profile } = this.props;

    return (
      <div className="bg-white p-3 fs-14 text-dark mb-3">
        <div className="d-flex mx-n2 mb-2">
          <div className="px-2">
            <a
              href={`/people/${feed.actor?.username}`}
              className="text-decoration-none"
            >
              <img
                className="img-circle _50x50"
                src={
                  feed.actor?.current_profile_pic?.url
                    ? feed.actor?.current_profile_pic?.url
                    : require("../../../assets/images/icons/user-circle.png")
                }
                onError={(error) =>
                  (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                }
                alt={feed.actor?.first_name}
              />
            </a>
          </div>
          <div className="px-2 flex-grow-1">
            <div className="ff-alt">
              <Link
                to={`/people/${feed.actor?.username}`}
                className="text-reset ff-base font-weight-bold"
              >
                {feed.actor?.first_name} {feed.actor?.last_name}
              </Link>
              &nbsp;
              <span>{
                feed.verb === "status" ? "updated a status as" : feed.verb
              }</span>
              &nbsp;
            </div>
            <div>{feed.time_stamp}</div>
          </div>
        </div>
        <div className="ff-alt my-4">
          {feed?.action?.body_preview != null ? (
            <React.Fragment>
              {!!feed?.action?.body_preview && <Markdown
                escapeHtml={false}
                source={feed?.action?.body_preview}
              />}
              {!!feed?.action?.body_preview && extractUrls(feed?.action?.body_preview) !== undefined && extractUrls(feed?.action?.body_preview).map((link, index) => {
                return link.includes('youtube') && <a href={link} target="_blank" rel="noopener noreferrer"><div className="outer-link mb-2" key={index}>
                  <Embed url={link} />
                </div></a>
              })}
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
        {feed?.action?.attachments.length ?
          <div className="mb-3">
            {/* Main image/video/audio */}
            <div className="mb-2 text-center">
              {feed?.action?.attachments[0].media_type === "video" ?
                <video className="feeds-video-main" controls muted preload="true">
                  <source
                    src={feed?.action?.attachments[0].url}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                : null
              }
              {feed?.action?.attachments[0].media_type === "audio" ?
                <audio className="feeds-audio-main" controls autoPlay loop>
                  <source
                    src={feed?.action?.attachments[0].url}
                    type="video/mpeg"
                  />
                  Your browser does not support the audio tag.
                </audio>
                : null
              }
              {feed?.action?.attachments[0].media_type === "image" ?
                <img className="feeds-img-main" src={feed?.action?.attachments[0].url} alt="user" />
                : null
              }
            </div>
            {/* List of other Images/Videos/audios uploaded */}
            {feed?.action?.attachments.length > 0 ?
              <ul className="list-unstyled feeds-media-list">
                {feed?.action?.attachments
                  .slice(1, feed?.action?.attachments.length)
                  .map((media, index) => {
                    return (
                      <li key={index} onClick={() => this.openAttachedMediaCarousal(feed?.action?.attachments, index + 1)}>
                        <div className={`feeds-media-list-item ` + `${media.media_type === 'video' ? 'video-holder' : (media.media_type === 'audio' ? 'audio-holder' : null)}`}>
                          <img className="feed-media-thumbnail" src={media.thumbnail} alt="user" />
                        </div>
                      </li>
                    )
                  })}
              </ul>
              : null
            }
          </div>
          :
          null}
        <div className="mb-3">
          <Comments
            feed={feed}
            comments={feed.action?.conversation && feed.action.conversation}
            onDeleteComment={this.handleStartDeletePostComment}
            onEditComment_Reply={this.handlePostEditComment}
            onCommentReply={this.handleOnPostCommentReply}
            myProfile={this.props.my_profile_data}
          />
          <ConfirmationModal
            isOpen={this.state.isPostDeleteModalOpen}
            setIsOpen={this.handleCancelPostDeleteComment}
            onConfirm={this.handleDeletePostComment}
            title={this.state.postConverstionDeleteType && `Are you sure you want to delete ${this.state.postConverstionDeleteType}?` }
          />
        </div>

        <div className="mt-4">
          <Reactions
            id={feed.id}
            counts={reactionCounts}
            statuses={reactionStatuses}
            onClick={(reaction) => this.handleFeedReactions(reaction, feed.id)}
            get_reviewgroup={this.props.get_reviewgroup}
            get_reveiewData={this.props.get_reveiewData}
            feed={feed}
          />
        </div>
        {/* Comment box */}
        <div className="bg-light p-2 mt-3">
          <Media className="media-post">
            <Media>
              <img
                className="media-object"
                src={
                  my_profile?.current_profile_file
                    ? my_profile?.current_profile_file
                    : require("../../../assets/images/icons/user.png")
                }
                onError={(error) =>
                  (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                }
                alt="User"
              />
            </Media>
            <Media body>
              <FormGroup className="mb-0">
                <Input
                  bsSize="sm"
                  className="mb-2 text-primary"
                  type="textarea"
                  name={`reply${feed.id}`}
                  placeholder="Add your comments"
                  value={this.state[`reply${feed.action?.id}`]}
                  onChange={(event) =>
                    this.setCommentMessage(
                      `reply${feed.action?.id}`,
                      event.target.value
                    )
                  }
                />
                <div className="text-right">
                  <div className="text-right">
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => this.handleOnSubmitPostComment({ id: feed.action?.id })}
                      disabled={!this.state[`reply${feed.action?.id}`]}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </FormGroup>
            </Media>
          </Media>
        </div>
      </div>
    );
  }

  renderTypeAddedNewProject = (feed) => {
    let reactionStatuses = null;
    let reactionCounts = null;

    if (feed?.review_group_animation) {
      reactionStatuses = feed?.review_group_animation?.review_group_status;
      reactionCounts = feed?.review_group_animation?.review_group_count;
    } else {
      reactionStatuses = feed?.contribution_group?.contribution_group_status;
      reactionCounts = feed?.contribution_group?.contribution_group_count;
    }
	
    const { my_profile } = this.props;
    return (
        <div className="bg-white p-3 fs-14 text-dark mb-3">
          <div className="d-flex mx-n2 mb-2">
            <div className="px-2">
              <a
                  href={`/people/${feed.actor?.username}`}
                  className="text-decoration-none"
              >
                <img
                    className="img-circle _50x50"
                    src={
                      feed.actor?.current_profile_pic?.url
                          ? feed.actor?.current_profile_pic?.url
                          : require("../../../assets/images/icons/user-circle.png")
                    }
                    onError={(error) =>
                        (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                    }
                    alt={feed.actor?.first_name}
                />
              </a>
            </div>
            <div className="px-2 flex-grow-1">
              <div className="ff-alt">
              <Link
                to={`/people/${feed.actor?.username}`}
                className="text-reset ff-base font-weight-bold"
              >
                {feed.actor?.first_name} {feed.actor?.last_name}
              </Link>
                &nbsp;
                <span>{feed.verb}</span>
                &nbsp;
                <Link
                    to={{
                      pathname: `/${feed.target?.category?.toLowerCase()}/${feed.target?.slug ? feed.target?.slug : feed.target?.id
                      }`,
                      state: { id: feed.target?.id },
                    }}
                    className="text-reset font-weight-bold"
                >
                  {feed?.target?.name}
                </Link>
                &nbsp;
              </div>
              <div>{feed.time_stamp}</div>
            </div>
            <div className="px-2 col-auto">
              <div className="d-inline-flex flex-nowrap mx-n1">
                <div className="px-1">
                  <UncontrolledDropdown
                      onMouseOver={() => {
                        this.onMouseEnterCategory(true, feed?.id);
                        this.fetchPinStatusesForFeed(feed);
                      }}
                      onMouseLeave={() =>
                          this.onMouseLeaveCategory(false, feed?.id)
                      }
                      isOpen={this.state.homeDropdownOpen[feed?.id]}
                      toggle={() =>
                          this.toggleCategory(this.state.homeDropdownOpen[feed?.id])
                      }
                  >
                    <DropdownToggle
                        className="p-0 text-dark"
                        size="sm"
                        color="transparent"
                    >
                      <div className="thumbpin-icon"></div>
                    </DropdownToggle>
                    <DropdownMenu className="type-tertiary-alt" right>
                      <DropdownItem
                          onClick={() => {
                            this.updatePinList("wantlist", feed);
                          }}
                          className="fs-14"
                      >
                        <div className="addto-icon want-list"></div>
                        {this.state.pinStatuses?.[feed?.id]?.want_list
                            ? "Added"
                            : "Add"}{" "}
                        to want list
                      </DropdownItem>
                      <DropdownItem
                          className="fs-14"
                          onClick={() => {
                            this.updatePinList("favouritelist", feed);
                          }}
                      >
                        <div className="addto-icon favorite-list"></div>
                        {this.state.pinStatuses[feed?.id]?.favourite_list
                            ? "Added"
                            : "Add"}{" "}
                        to favorites
                      </DropdownItem>
                      <DropdownItem
                          className="fs-14"
                          onClick={() => {
                            this.updatePinList("watchlist", feed);
                          }}
                      >
                        <div className="addto-icon watch-list"></div>
                        {this.state.pinStatuses[feed?.id]?.watch_list
                            ? "Added"
                            : "Add"}{" "}
                        to watch list
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className="px-1">
                  <UncontrolledDropdown
                      onMouseOver={() =>
                          this.onMouseShareEnterCategory(true, feed?.id)
                      }
                      onMouseLeave={() =>
                          this.onMouseShareLeaveCategory(false, feed?.id)
                      }
                      isOpen={this.state.homeShareDropdownOpen[feed?.id]}
                      toggle={() =>
                          this.toggleShareCategory(
                              this.state.homeShareDropdownOpen[feed?.id]
                          )
                      }
                  >
                    <DropdownToggle
                        className="p-0 text-dark"
                        size="sm"
                        color="transparent"
                    >
                      <div className="share-it-icon"></div>
                    </DropdownToggle>
                    <DropdownMenu className="type-tertiary-alt" right>
                      <div className="fs-14 dropdown-item">
                        <FacebookShareButton
                            url={`${this.state.protocol}//${this.state.host}/${feed.target && feed.target?.category ? feed.target?.category.toLowerCase() : ''}/${feed.target?.id}/${feed.target?.slug}`}
                            hashtag={this.state.fbHashTag}
                        >
                          Facebook
                        </FacebookShareButton>
                      </div>
                      {/* {<DropdownItem className="fs-14">Google +</DropdownItem>} */}
                      <div className="fs-14 dropdown-item">
                        <TwitterShareButton
                            url={`${this.state.protocol}//${this.state.host}/${feed.target && feed.target?.category ? feed.target?.category.toLowerCase() : ''}/${feed.target?.id}/${feed.target?.slug}`}
                            via={this.state.shareVia}
                            hashtags={this.state.twitterHashTags}
                        >
                          Twitter
                        </TwitterShareButton>
                      </div>
                      <DropdownItem
                          className="fs-14"
                          // onClick={() =>
                          //   this.setState({
                          //     shareByEmailModalToggle: true,
                          //   })
                          // }
                          onClick={() => this.handleOnClickShareByEmail(feed?.action?.id ? feed.action.id : feed?.id, feed)}
                      >
                        By Email
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-2 text-center">
            <img
                className="img-fluid"
                src={feed.target?.project_image_path}
                onError={(error) =>
                    (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                }
                alt=""
            />
          {
            (feed?.action?.attachments && feed?.action?.attachments.length) ?

              <div className="mb-3">
                {/* Main image/video/audio */}
                <div className="mb-2 text-center">
                  {feed?.action?.attachments[0].media_type === "video" ?
                    <video className="feeds-video-main" controls muted preload="true">
                      <source
                        src={feed?.action?.attachments[0].url}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                    : null
                  }
                  {feed?.action?.attachments[0].media_type === "audio" ?
                    <audio className="feeds-audio-main" controls autoPlay loop>
                      <source
                        src={feed?.action?.attachments[0].url}
                        type="video/mpeg"
                      />
                      Your browser does not support the audio tag.
                    </audio>
                    : null
                  }
                  {feed?.action?.attachments[0].media_type === "image" ?
                    <img className="feeds-img-main" src={feed?.action?.attachments[0].url} alt="user" />
                    : null
                  }
                </div>
                {/* List of other Images/Videos/audios uploaded */}
                {feed?.action?.attachments.length > 0 ?
                  <ul className="list-unstyled feeds-media-list">
                    {feed?.action?.attachments
                      .slice(1, feed?.action?.attachments.length)
                      .map((media, index) => {
                        return (
                          <li key={index} onClick={() => this.openAttachedMediaCarousal(feed?.action?.attachments, index + 1)}>
                            <div className={`feeds-media-list-item ` + `${media.media_type === 'video' ? 'video-holder' : (media.media_type === 'audio' ? 'audio-holder' : null)}`}>
                              <img className="feed-media-thumbnail" src={media.thumbnail} alt="user" />
                            </div>
                          </li>
                        )
                      })}
                  </ul>
                  : null
                }
              </div>
              : ""
          }
          </div>
          <Comments
              feed={feed}
              comments={feed.comments && feed.comments}
              onDeleteComment={this.handleStartDeleteComment}
              onEditComment_Reply={this.handleEditComment_Reply}
              onCommentReply={this.handleCommentReply}
              myProfile={this.props.my_profile_data}
          />
          <ConfirmationModal
              isOpen={this.state.isDeleteCommentModalOpen}
              setIsOpen={this.handleCancelDeleteComment}
              onConfirm={this.handleDeleteComment}
          />
          <div className="mt-4">
            {/* need */}
            {feed.contribution_group &&
            feed.contribution_group?.contribution_group_status ? (
                <Reactions
                    id={feed.id}
                    counts={feed.contribution_group?.contribution_group_count}
                    statuses={feed.contribution_group?.contribution_group_status}
                    onClick={(reaction) =>
                        this.handleFeedReactions(reaction, feed.id)
                    }
                    get_reviewgroup={this.props.get_reviewgroup}
                    get_reveiewData={this.props.get_reveiewData}
                    feed={feed}
                />
            ) : (
                <Reactions
                    id={feed.id}
                    counts={reactionCounts}
                    statuses={reactionStatuses}
                    onClick={(reaction) =>
                        this.handleFeedReactions(reaction, feed.id)
                    }
                    get_reviewgroup={this.props.get_reviewgroup}
                    get_reveiewData={this.props.get_reveiewData}
                    feed={feed}
                />
            )}
          </div>
          {/* Comment box */}
          <div className="bg-light p-2 mt-3">
            <Media className="media-post">
              <Media>
                <img
                    className="media-object"
                    src={
                      my_profile?.current_profile_file
                          ? my_profile?.current_profile_file
                          : require("../../../assets/images/icons/user-circle.png")
                    }
                    onError={(error) =>
                        (error.target.src = require("../../../assets/images/icons/user-circle.png"))
                    }
                    alt="User"
                />
              </Media>
              <Media body>
                <FormGroup className="mb-0">
                  <Input
                      bsSize="sm"
                      className="mb-2 text-primary"
                      type="textarea"
                      name={`reply${feed.id}`}
                      placeholder="Add your comments"
                      value={this.state[`reply${feed.id}`]}
                      onChange={(event) =>
                          this.setCommentMessage(
                              `reply${feed.id}`,
                              event.target.value
                          )
                      }
                  />
                  <div className="text-right">
                    <div className="text-right">
                      <Button
                          size="sm"
                          color="primary"
                          onClick={() => this.onSubmitComment(feed.id)}
                          disabled={!this.state[`reply${feed.id}`]}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </FormGroup>
              </Media>
            </Media>
          </div>
        </div>
    );
  };

  handleOnClickReaction = (type, feed) => {
    const newFeed = { ..._.cloneDeep(feed) },
        results = [...this.state.feeds.results];
    let showReactionGif = false;
    if (type === "thanku") {
      if (newFeed.contribution_group.contribution_group_status.thanku) {
        newFeed.contribution_group.contribution_group_status.thanku = false;
        newFeed.contribution_group.contribution_group_count.thanku -= 1;
      } else {
        newFeed.contribution_group.contribution_group_status.thanku = true;
        newFeed.contribution_group.contribution_group_count.thanku += 1;
        showReactionGif = true;
      }
      this.props.update_reaction(`/api/contributiongroup/`, "POST", {
        type: "Thank you",
        actstream_action: feed.id,
      });
    }
    let data = {
      ...this.state.feeds,
      results: results.map((item) => {
        if (item.id !== newFeed.id) {
          return item;
        } else {
          return newFeed;
        }
      }),
    };
    this.setState(
        {
          feeds: data,
          showReactionGif,
        },
        () => {
          if (showReactionGif) {
            setTimeout(() => {
              this.setState({
                showReactionGif: false,
              });
            }, 4000);
          }
        }
    );
  };

  handleFeedReactions = (reaction, id, reationType, isActive) => {


    let params = {};

    if (reationType === "review") {
      params = { type: reaction, review: id, delete: !isActive };
    } else {
      params = {
        type: reaction,
        actstream_action: id,
      };
    }

    const feedParams = {
      page: this.state.page,
      [this.state.filterType]: true,
    };
    this.props.updateFeedReactions(params, feedParams);
  };

  renderDropDownItem = ({ onClickItem, title }) => {
    return (
        <DropdownItem className="fs-14" onClick={onClickItem}>
          {title}
        </DropdownItem>
    );
  };

  handleDisputeThisReview = (review, reason) => {
    this.setState({ showDisputeModal: true, reviewDetails: review, disputeModal: true, reason: reason })
  }

  checkAttachment = (item) => {
    if (item.location) {
      if (item.media_type == "image") {
        let filename = item.filename.split('.');
        let ext = filename[filename.length - 1];
        let imageExts = ['jpg', 'png', 'gif', 'jpeg'];
        if (imageExts.includes(ext)) {
          return item.location;
        }
      } else if (item.media_type == "video") {
        return item.thumbnail;
      } else {
        return item.url;
      }
    }
  }
  openAttachedMediaCarousal = (items, index) => {
    const carouselMedia = items;
    const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
    const array2 = (index !== carouselMedia.length - 1) ? carouselMedia.filter((item, i) => i > index) : [];
    const newArray = [carouselMedia[index], ...array2, ...array1];
    this.setState({ itemsAttachment: newArray, viewProjectImagesModal: true });
  }

  projectImageSlides = () => {
    let { itemsAttachment } = this.state;
    if (itemsAttachment && Array.isArray(itemsAttachment) && itemsAttachment.length > 0) {
        return itemsAttachment.map((item, index) => {
            let full_name = `${item?.user?.user?.first_name} ${item?.user?.user?.last_name}`;
            return (
                <div className="item fs-14 h-100 d-flex flex-column justify-content-center" key={item.id}>
                    <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                        {
                            item.media_type == "image" && <img className="img-fluid show-media " src={this.checkAttachment(item)} alt={item.filename} />
                        }
                        {
                            item.media_type == "video" && <video className="img-fluid show-media" controls>
                                <source src={item?.url} type="video/mp4" />
                            </video>
                        }
                    </div>
                    <div className="px-2 mt-3">
                        <div className="d-flex flex-nowrap mx-n2">
                            <div className="px-2 col d-flex flex-nowrap">
                                <div className="mr-2">
                                    <img className="img-circle _30x30"
                                        src={item?.user?.current_profile_pic ? item?.user?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                        alt=""
                                        onError={(error) => (error.target.src = require('../../../assets/images/icons/user-circle.png'))} />
                                </div>
                                <div>
                                    <div className="text-primary ff-base">
                                        Uploaded by&nbsp;<span className="font-weight-bold text-reset">{full_name}</span>
                                    </div>
                                    <div className="text-tertiary ff-base">
                                        {item?.upload_date}
                                    </div>
                                </div>
                            </div>
                            <div className="px-2 col-auto">
                                <h2 className="fs-38" style={{ color: '#d4dae6' }}>{index + 1}{" / "}{itemsAttachment?.length} </h2>
                            </div>
                            <div className="px-2 col">&nbsp;</div>
                        </div>
                    </div>
                        {/* <div className="col-md-12 mt-2">
                        <div className="col-md-6">
                            <div className="bottom-heading">
                                <img
                                    src={full_name == this.state.myProfile?.name_full ? this.state.myProfile.current_profile_pic : item?.user?.current_profile_pic}
                                    style={{ width: 50, height: 50, borderRadius: '50%' }}
                                    alt=""
                                    onError={(e) => e.target.src = require('../../../../assets/images/icons/user-circle.png')}
                                />
                                {`Uploaded by  ${full_name}`}
                            </div>
                            <div className="days-ago">
                                {item?.upload_date}
                                <span className="float-right">
                                    {index + 1}
                                </span>
                            </div>
                        </div>
                    </div> */}
                    </div>
                    

                    
                    
                
            )
        });
    }
}

  render() {
    const {
      selected,
      get_dispute_data,
      showDisputeModal,
      reviewDetails,
      showDisputedModal,
      reason,
      isEnableFileUpload,
      reviewTargetListingDetails,
    } = this.state;
    const { feeds_data: feeds } = this.props;


    return (
        <>
          { showDisputeModal ?
              <DisputingReview review={reviewDetails} source="Actioned" reason={reason} />
              :
              ''
          }
          { showDisputedModal ?
              <DisputedReview review={reviewDetails} source="Actioned" />
              :
              ''
          }
        {isEnableFileUpload ?
          <UploadMedia
            getUploadedMedia={this.getUploadedMedia}
            uploadedUrl={this.state.uploadMediaUrl}
            hideEmbed={this.state.hideEmbed}
            callbackFunction={this.handleOnClickUploadModalOk}
            typeUpload={reviewTargetListingDetails?.category}
            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload) => {
              this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, this.state.reviewOneToggler ? true : false);

            }}
            mainTaxonomy={reviewTargetListingDetails?.taxonomy_list?.[0]?.toLowerCase().replace(/&/g, "and")}
          />
          : null}

          <div className="mb-2">
            <UncontrolledDropdown
                className="d-inline-block"
                onMouseOver={this.onMouseFeedEnter}
                onMouseLeave={this.onMouseFeedLeave}
                isOpen={this.state.feedDropdownOpen}
                toggle={this.toggleFeedMenu}
            >
              <DropdownToggle
                  className="text-darker font-weight-bold"
                  color="transparent"
                  size="sm"
                  caret
              >
                <span className="pr-2">{selected}</span>
              </DropdownToggle>
              <DropdownMenu className="type-tertiary-alt">
                <DropdownItem
                    className="fs-14"
                    onClick={() => this.handleOnClickFilterType("All", "all")}
                >
                  All
                </DropdownItem>
                <DropdownItem
                    className="fs-14"
                    onClick={() =>
                        this.handleOnClickFilterType("Reviews", "reviews")
                    }
                >
                  Reviews
                </DropdownItem>
                <DropdownItem
                    className="fs-14"
                    onClick={() =>
                        this.handleOnClickFilterType("Contribution", "contribution")
                    }
                >
                  Contribution
                </DropdownItem>
                <DropdownItem
                    className="fs-14"
                    onClick={() =>
                        this.handleOnClickFilterType("Feedback", "feedback")
                    }
                >
                  Feedback
                </DropdownItem>
                <DropdownItem
                    className="fs-14"
                    onClick={() => this.handleOnClickFilterType("CRP", "projects")}
                >
                  CRP
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

          <div>
          {(feeds?.results &&
            Array.isArray(feeds?.results) &&
            feeds?.results.length) ?
            <>
              {feeds.results.map((feed, index) => {
                if (
                  feed?.verb &&
                  (feed?.verb === "answered a question on" ||
                    feed?.verb === "asked a question about")
                ) {
                  return (
                    <div key={index}
                    id={feeds.results?.length == index + 1 ? `last-index` : `current-index-${index}`}
                    >{this.renderTypeAnsweredQuestion(feed)}</div>
                  );
                }

                if (
                  feed?.verb &&
                  (feed?.verb === "wrote a review" ||
                    feed?.verb?.toLowerCase().indexOf("review") >= 0)
                ) {
                  return <div key={index}
                  id={feeds.results?.length == index + 1 ? `last-index` : `current-index-${index}`}
                  >{this.renderTypeReview(feed)}</div>;
                }

                if (
                  feed?.verb &&
                  (feed?.verb === "recommends" || feed?.verb === "checked in to")
                ) {
                  return (
                    <div key={index}
                    id={feeds.results?.length == index + 1 ? `last-index` : `current-index-${index}`}
                    >
                      {this.renderRecommendsAndOtherProject(feed)}
                    </div>
                  );
                }

                if (
                  feed?.verb &&
                  (feed?.verb === "added new project" ||
                    feed?.verb === "added new business" ||
                    feed?.verb === "added media for" ||
                    feed?.verb === "added an employee to" ||
                    feed?.verb?.toLowerCase().indexOf("added") >= 0)
                ) {
                  return (
                    <div key={index}
                    id={feeds.results?.length == index + 1 ? `last-index` : `current-index-${index}`}
                    >{this.renderTypeAddedNewProject(feed)}</div>
                  );
                }
                if (feed?.verb && (
                  feed?.verb.includes('status'))
                ) {
                  return (
                    <div key={index}
                      id={feeds.results?.length == index + 1 ? `last-index` : `current-index-${index}`}
                    >
                      {this.renderTypeStatus(feed)}
                    </div>
                  );
                }

                if (feed?.verb && (
                  feed?.verb !== "has written an update for their review on" &&
                  feed?.verb !== "commented on a review on" &&
                  feed?.verb !== "has written a follow up review o")
                ) {
                  return (
                    <div key={index}
                    id={feeds.results?.length == index + 1 ? `last-index` : `current-index-${index}`}
                    >
                      {this.renderRecommendsAndOtherProject(feed)}
                    </div>
                  );
                }

                return (
                  <div key={index}
                  id={feeds.results?.length == index + 1 ? `last-index` : `current-index-${index}`}
                  >
                    {this.renderRecommendsAndOtherProject(feed)}
                  </div>
                );
              })}
              {
                this.state.loading ? <div className="mt-5 text-center">
                  <h2>{"Loading more feeds..."}</h2>
                </div> : ''
              }
              <div style={{ height: "400px" }} />
            </>
            : !this.state.loading ? <div>No feeds to display!</div> :  <h2>{"Loading..."}</h2>
          }
          
          <div style={{ height: "400px" }} />

          <div ref={(loadingRef) => (this.loadingRef = loadingRef)}></div>
        </div>


          {/* Share By Email Modal Popup */}
          <ModalComponent
                    size={"sm"}
                    isScrollable={true}
                    isOpen={this.state.shareByEmailModalToggle}
                    toggleHandler={()=> this.setState({ shareByEmailModalToggle: !this.state.shareByEmailModalToggle })}
                    header={" Share by Email"}
                    bodyClass={""}
                    body={<ShareByEmail handleOnSubmitShareByEmail={(value) => this.handleOnSubmitShareByEmail(value)} />}
                    />


          {/* Jury Final Decision Modal */}
          <Modal
              size="xl"
              scrollable
              className="drawer-modal"
              isOpen={this.state.voteReviewModalToggleFinal}
              toggle={() =>
                  this.setState({
                    voteReviewModalToggleFinal: !this.state
                        .voteReviewModalToggleFinal,
                  })
              }
          >
            <form className="d-flex flex-column h-100">
              <div className="modal-header p-0">
                <div className="w-100">
                  <div>
                    <Button
                        color="primary"
                        onClick={() =>
                            this.setState({
                              voteReviewModalToggleFinal: !this.state
                                  .voteReviewModalToggleFinal,
                            })
                        }
                    >
                      <FontAwesomeIcon className="mr-2" icon="angle-left" />
                      back to listing
                    </Button>
                  </div>
                  <div className="bg-dark text-white fs-14 px-3">
                    <div className="mb-2 pt-2">
                      <div className="d-flex mx-n2">
                        <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                          <UncontrolledDropdown className="d-inline-block">
                            <DropdownToggle
                                className="text-white bg-transparent shadow-none font-weight-bold"
                                color="transparent"
                                size="sm"
                                caret
                            >
                              <span className="pr-2">Disputed Reviews</span>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem className="fs-14">
                                Disputed Reviews
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="px-2 col-auto">
                          near{" "}
                          <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                        </div>
                      </div>
                    </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input
                                type="select"
                                className="transparent"
                                size="sm"
                            >
                              <option>Businesses</option>
                            </Input>
                          </FormGroup>
                          <div className="ml-2">
                            <span className="text-danger">*</span>
                          </div>
                        </div>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>IT Services</option>
                            <option>Apartment Rentals</option>
                            <option>Automotive</option>
                            <option>Education</option>
                            <option>Beauty and Spas</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Asian</option>
                            <option>European</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Indian</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Level 6</option>
                          </Input>
                        </FormGroup>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ModalBody className="p-0 fs-14 text-dark bg-transparent">
                <div className="bg-dark text-white">
                  <Row noGutters>
                    <Col xs="auto">
                      <div
                          className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                          style={{ minWidth: "300px" }}
                      >
                        <div>
                          <img
                              src={require("../../../assets/images/icons/star/white/fill.png")}
                              alt=""
                          />
                          <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                          <img
                              src={require("../../../assets/images/icons/star/white/fill.png")}
                              alt=""
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex flex-column justify-content-center bg-primary text-dark h-100 p-3">
                        <Row>
                          <Col>
                            <div className="pr-md-5">
                              <h3 className="text-white">
                                The Community has voted.
                              </h3>
                              <div className="fs-14">
                                <div className="fs-16 font-weight-bold">
                                  Their decision is to delete the review.
                                </div>
                                <p className="mb-0">
                                  Brief description of why you vote your counts
                                  and what you'd be voting on. Lorem ipsum, dolor
                                  sit amet consectetur adipisicing elit.
                                  Dignissimos, non?
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md="auto" className="align-self-end">
                            <div className="text-light font-weight-bold text-muted">
                              Voting ended
                            </div>
                            <div className="d-flex mx-n2 flex-nowrap">
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings">0</div>
                                  <div className="font-weight-bold">days</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    00
                                  </div>
                                  <div className="font-weight-normal">hours</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    00
                                  </div>
                                  <div className="font-weight-normal">
                                    minutes
                                  </div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    00
                                  </div>
                                  <div className="font-weight-normal">
                                    seconds
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="bg-white px-3 py-4">
                  <Row>
                    <Col md={4}>
                      <div className="font-weight-bold fs-16 text-primary mb-2">
                        Voting History
                      </div>
                      <ul className="d-flex flex-column list-unstyled">
                        <li className="py-2 border-bottom">
                          <div className="d-flex mx-n1">
                            <div className="px-1">
                              <div className="ff-alt">22/04/2014</div>
                            </div>
                            <div className="px-1">
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Sau6402
                              </a>
                              &nbsp; added a Punctuation in Tips.
                            </div>
                          </div>
                        </li>
                        <li className="py-2 border-bottom">
                          <div className="d-flex mx-n1">
                            <div className="px-1">
                              <div className="ff-alt">22/04/2014</div>
                            </div>
                            <div className="px-1">
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Danielbauwens
                              </a>
                              &nbsp; reverted edits by{" "}
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Fart biscuit
                              </a>
                              &nbsp; changed back to last version by{" "}
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Anna
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col md={8}>
                      <div>
                        <div className="font-weight-bold fs-16 text-primary mb-3">
                          The Decision
                        </div>

                        <div className="bg-light p-2 mb-3 border">
                          <div className="font-weight-bold">
                            Their Decision is to delete the review.
                          </div>
                          <div className="text-tertiary">
                            The review is inaccurate and will be removed from
                            WikiReviews
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col className="bg-light text-center text-tertiary d-flex flex-column">
                      <div className="my-4">
                        <div className="ff-alt">
                          <FontAwesomeIcon icon="quote-left" size="lg" />
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Aut facilis nesciunt odio illum debitis vitae
                          corporis suscipit molestias porro aperiam.
                          <FontAwesomeIcon icon="quote-right" size="lg" />
                        </div>
                        <div className="mt-4">
                          <a href="#" className="text-reset font-weight-bold">
                            proof-number-one.pdf
                          </a>
                        </div>
                      </div>
                      <div className="mt-auto">
                        <hr className="mt-0" />
                      </div>
                    </Col>
                    <Col className="bg-white text-dark d-flex flex-column">
                      <div className="mb-4">
                        <FormGroup className="main-post-formgroup">
                          <div className="input-label-block">
                            <Input
                                className="primary"
                                type="textarea"
                                rows="2"
                                placeholder="&nbsp;"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className="text-tertiary font-weight-bold mb-2">
                            Submit proofs
                          </div>
                          <CustomInput id="2" bsSize="sm" type="file" />
                        </FormGroup>
                        <div className="text-right">
                          <div className="text-tertiary">
                            or{" "}
                            <span className="font-weight-bold" role="button">
                            embed link
                          </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-auto">
                        <hr className="mt-0" />
                      </div>
                    </Col>
                    <Col className="bg-light text-center text-tertiary d-flex flex-column">
                      <div className="my-4">
                        <div className="ff-alt">
                          <FontAwesomeIcon icon="quote-left" size="lg" />
                          Lorem ipsum dolor, sit amet consectetur adipisicing
                          elit. Aut facilis nesciunt odio illum debitis vitae
                          corporis suscipit molestias porro aperiam.
                          <FontAwesomeIcon icon="quote-right" size="lg" />
                        </div>
                        <div className="mt-4">
                          <a href="#" className="text-reset font-weight-bold">
                            proof-number-two.pdf
                          </a>
                        </div>
                      </div>
                      <div className="mt-auto">
                        <hr className="mt-0" />
                      </div>
                    </Col>
                    <Col className="bg-white text-dark d-flex flex-column">
                      <div className="mb-4">
                        <FormGroup className="main-post-formgroup">
                          <div className="input-label-block">
                            <Input
                                className="primary"
                                type="textarea"
                                rows="2"
                                placeholder="&nbsp;"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <div className="text-tertiary font-weight-bold mb-2">
                            Submit proofs
                          </div>
                          <CustomInput id="1" bsSize="sm" type="file" />
                        </FormGroup>
                        <div className="text-right">
                          <div className="text-tertiary">
                            or{" "}
                            <span className="font-weight-bold" role="button">
                            embed link
                          </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-auto">
                        <hr className="mt-0" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter className="bg-white">
                <Button color="primary">Submit</Button>
                <Button color="link" className="text-tertiary font-weight-bold">
                  Skip
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          <Modal
              size="xl"
              scrollable
              className="drawer-modal"
              isOpen={this.state.voteReviewModalToggleSix}

              toggle={() =>
                  this.setState({
                    voteReviewModalToggleSix: !this.state.voteReviewModalToggleSix,
                  })
              }
          >
            <form className="d-flex flex-column h-100">
              <div className="modal-header p-0">
                <div className="w-100">
                  <div>
                    <Button
                        color="primary"
                        onClick={() =>
                            this.setState({
                              voteReviewModalToggleSix: !this.state
                                  .voteReviewModalToggleSix,
                            })
                        }
                    >
                      <FontAwesomeIcon className="mr-2" icon="angle-left" />
                      back to listing
                    </Button>
                  </div>
                  <div className="bg-dark text-white fs-14 px-3">
                    <div className="mb-2 pt-2">
                      <div className="d-flex mx-n2">
                        <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                          <UncontrolledDropdown className="d-inline-block">
                            <DropdownToggle
                                className="text-white bg-transparent shadow-none font-weight-bold"
                                color="transparent"
                                size="sm"
                                caret
                            >
                              <span className="pr-2">Disputed Reviews</span>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem className="fs-14">
                                Disputed Reviews
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="px-2 col-auto">
                          near{" "}
                          <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                        </div>
                      </div>
                    </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input
                                type="select"
                                className="transparent"
                                size="sm"
                            >
                              <option>Businesses</option>
                            </Input>
                          </FormGroup>
                          <div className="ml-2">
                            <span className="text-danger">*</span>
                          </div>
                        </div>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>IT Services</option>
                            <option>Apartment Rentals</option>
                            <option>Automotive</option>
                            <option>Education</option>
                            <option>Beauty and Spas</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Asian</option>
                            <option>European</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Indian</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Level 6</option>
                          </Input>
                        </FormGroup>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ModalBody className="p-0 fs-14 text-dark bg-transparent">
                <div className="bg-dark text-white">
                  <Row noGutters>
                    <Col xs="auto">
                      <div
                          className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                          style={{ minWidth: "300px" }}
                      >
                        <div>
                          <img
                              src={require("../../../assets/images/icons/star/white/fill.png")}
                              alt=""
                          />
                          <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                          <img
                              src={require("../../../assets/images/icons/star/white/fill.png")}
                              alt=""
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                        <Row>
                          <Col>
                            <div className="pr-md-5">
                              <h3 className="text-light">
                                Business Owner dispute.
                              </h3>
                              <div className="fs-14">
                                <div className="text-primary fs-16 font-weight-bold">
                                  Step 1. Your vote is needed.
                                </div>
                                <p className="mb-0">
                                  Brief description of why you vote your counts
                                  and what you'd be voting on. Lorem ipsum, dolor
                                  sit amet consectetur adipisicing elit.
                                  Dignissimos, non?
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md="auto" className="align-self-end">
                            <div className="text-light font-weight-bold">
                              Submission time ends in
                            </div>
                            <div className="d-flex mx-n2 flex-nowrap">
                              <div className="px-2">
                                <div className="text-white">
                                  <div className="fs-32 ff-headings">7</div>
                                  <div className="font-weight-bold">days</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    15
                                  </div>
                                  <div className="font-weight-normal">hours</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    32
                                  </div>
                                  <div className="font-weight-normal">
                                    minutes
                                  </div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    56
                                  </div>
                                  <div className="font-weight-normal">
                                    seconds
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="body-bg px-3 py-3">
                  <Row>
                    <Col>
                      In case you don't know the correct address, try looking for
                      it with our search bar.
                    </Col>
                    <Col xs="auto">
                      <div className="d-flex">
                        <Input
                            className="primary"
                            bsSize="sm"
                            type="search"
                            placeholder="Custom Search"
                            style={{ minWidth: "300px" }}
                        ></Input>
                        <Button color="primary" size="sm" className="ml-3">
                          <FontAwesomeIcon icon="search" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="bg-white px-3 py-4">
                  <Row>
                    <Col md={4}>
                      <div className="font-weight-bold fs-16 text-primary">
                        Step 2. Voting History
                      </div>
                      <ul className="d-flex flex-column list-unstyled">
                        <li className="py-2 border-bottom">
                          <div className="d-flex mx-n1">
                            <div className="px-1">
                              <div className="ff-alt">22/04/2014</div>
                            </div>
                            <div className="px-1">
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Sau6402
                              </a>
                              &nbsp; added a Punctuation in Tips.
                            </div>
                          </div>
                        </li>
                        <li className="py-2 border-bottom">
                          <div className="d-flex mx-n1">
                            <div className="px-1">
                              <div className="ff-alt">22/04/2014</div>
                            </div>
                            <div className="px-1">
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Danielbauwens
                              </a>
                              &nbsp; reverted edits by{" "}
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Fart biscuit
                              </a>
                              &nbsp; changed back to last version by{" "}
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Anna
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col md={8}>
                      <div className="mb-3">
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          Step 3. Review Lorem Ipsum
                        </div>
                        <div>
                          <Row>
                            <Col lg={6}>
                              <div className="bg-white text-center text-tertiary p-3">
                                <div className="mb-2">
                                  <img
                                      className="img-circle _50x50"
                                      // src={require("../../../assets/images/icons/user-circle.png")}
                                      alt=""
                                  />
                                </div>
                                <div className="font-weight-bold fs-18">
                                  User John
                                </div>
                                <div className="text-tertiary">
                                  a.k.a. The Reviewer
                                </div>
                                <div className="mt-3">
                                  {/* Repeat this */}
                                  <div className="mb-4">
                                    <div className="ff-alt">
                                      <FontAwesomeIcon
                                          icon="quote-left"
                                          size="lg"
                                      />
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Aut facilis nesciunt odio
                                      illum debitis vitae corporis suscipit
                                      molestias porro aperiam.
                                      <FontAwesomeIcon
                                          icon="quote-right"
                                          size="lg"
                                      />
                                    </div>
                                    <div className="mt-4">
                                      <a
                                          href="#"
                                          className="text-reset font-weight-bold"
                                      >
                                        letterpfintent.doc
                                      </a>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <div className="ff-alt">
                                      <FontAwesomeIcon
                                          icon="quote-left"
                                          size="lg"
                                      />
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Aut facilis nesciunt odio
                                      illum debitis vitae corporis suscipit
                                      molestias porro aperiam.
                                      <FontAwesomeIcon
                                          icon="quote-right"
                                          size="lg"
                                      />
                                    </div>
                                    <div className="mt-4">
                                      <a
                                          href="#"
                                          className="text-reset font-weight-bold"
                                      >
                                        supporting.doc
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="bg-light text-center text-tertiary p-3">
                                <div className="mb-2">
                                  <img
                                      className="img-circle _50x50"
                                      // src={require("../../../assets/images/icons/user-circle.png")}
                                      alt=""
                                  />
                                </div>
                                <div className="font-weight-bold fs-18">
                                  Business Owner
                                </div>
                                <div className="text-tertiary">
                                  a.k.a. The Business Owner
                                </div>
                                <div className="mt-3">
                                  {/* Repeat this */}
                                  <div className="mb-4">
                                    <div className="ff-alt">
                                      <FontAwesomeIcon
                                          icon="quote-left"
                                          size="lg"
                                      />
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Aut facilis nesciunt odio
                                      illum debitis vitae corporis suscipit
                                      molestias porro aperiam.
                                      <FontAwesomeIcon
                                          icon="quote-right"
                                          size="lg"
                                      />
                                    </div>
                                    <div className="mt-4">
                                      <span>no proofs submitten</span>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <div className="ff-alt">
                                      <FontAwesomeIcon
                                          icon="quote-left"
                                          size="lg"
                                      />
                                      Lorem ipsum dolor, sit amet consectetur
                                      adipisicing elit. Aut facilis nesciunt odio
                                      illum debitis vitae corporis suscipit
                                      molestias porro aperiam.
                                      <FontAwesomeIcon
                                          icon="quote-right"
                                          size="lg"
                                      />
                                    </div>
                                    <div className="mt-4">
                                      <a
                                          href="#"
                                          className="text-reset font-weight-bold"
                                      >
                                        proof-of-some-type.pdf
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="bg-white px-3 py-4">
                  <Row>
                    <Col md={4}>&nbsp;</Col>
                    <Col md={8}>
                      {/* Step 4 */}
                      <div>
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          Step 4. Does the Business Owner have sufficient evidence
                          to support any of the following:
                          <div className="fs-13 text-dark font-weight-normal">
                            Please put as much info (images, video, documents
                            etc.) as possible to support your statements.
                          </div>
                        </div>
                        <div>
                          <div className="bg-light p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                              <div className="px-2 col">
                                <FormGroup className="mb-2" check>
                                  <Label
                                      className="text-reset font-weight-normal fs-14 d-block"
                                      check
                                  >
                                    <Input type="radio" name="reviewToKeep" />{" "}
                                    <div className="mb-1">
                                      This review is inaccurate
                                    </div>
                                    <div className="fs-12 ff-alt">
                                      Must have evidence
                                    </div>
                                    <div className="d-flex flex-wrap mx-n2 fs-14">
                                      <div className="px-2 mb-2">25%</div>
                                      <div className="px-2 mb-2 align-self-center">
                                        <Progress
                                            color="tertiary"
                                            value="25"
                                            style={{
                                              height: "6px",
                                              width: "240px",
                                            }}
                                        ></Progress>
                                      </div>
                                      <div className="px-2 mb-2">
                                        5 votes out of 12
                                      </div>
                                    </div>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="px-2 col-auto">
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Expand"
                                >
                                  <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Collapse"
                                >
                                  <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                              </div>
                            </div>

                            {/* Collapse content */}
                            <div className="pl-3">Collapse content</div>
                          </div>
                          <div className="bg-light p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                              <div className="px-2 col">
                                <FormGroup className="mb-2" check>
                                  <Label
                                      className="text-reset font-weight-normal fs-14 d-block"
                                      check
                                  >
                                    <Input type="radio" name="reviewToKeep" />{" "}
                                    <div className="mb-1">Review is fake.</div>
                                    <div className="fs-12 ff-alt">
                                      Must explain why or have some type of
                                      evidence.
                                    </div>
                                    <div className="d-flex flex-wrap mx-n2 fs-14">
                                      <div className="px-2 mb-2">25%</div>
                                      <div className="px-2 mb-2 align-self-center">
                                        <Progress
                                            color="tertiary"
                                            value="25"
                                            style={{
                                              height: "6px",
                                              width: "240px",
                                            }}
                                        ></Progress>
                                      </div>
                                      <div className="px-2 mb-2">
                                        5 votes out of 12
                                      </div>
                                    </div>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="px-2 col-auto">
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Expand"
                                >
                                  <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Collapse"
                                >
                                  <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                              </div>
                            </div>

                            {/* Collapse content */}
                            <div className="pl-3">Collapse content</div>
                          </div>
                          <div className="bg-light p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                              <div className="px-2 col">
                                <FormGroup className="mb-2" check>
                                  <Label
                                      className="text-reset font-weight-normal fs-14 d-block"
                                      check
                                  >
                                    <Input type="radio" name="reviewToKeep" />{" "}
                                    <div className="mb-1">
                                      Review is Not Relevant.
                                    </div>
                                    <div className="fs-12 ff-alt">
                                      Must explain why.
                                    </div>
                                    <div className="d-flex flex-wrap mx-n2 fs-14">
                                      <div className="px-2 mb-2">25%</div>
                                      <div className="px-2 mb-2 align-self-center">
                                        <Progress
                                            color="tertiary"
                                            value="25"
                                            style={{
                                              height: "6px",
                                              width: "240px",
                                            }}
                                        ></Progress>
                                      </div>
                                      <div className="px-2 mb-2">
                                        5 votes out of 12
                                      </div>
                                    </div>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="px-2 col-auto">
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Expand"
                                >
                                  <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Collapse"
                                >
                                  <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                              </div>
                            </div>

                            {/* Collapse content */}
                            <div className="pl-3">Collapse content</div>
                          </div>
                          <div className="bg-light p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                              <div className="px-2 col">
                                <FormGroup className="mb-2" check>
                                  <Label
                                      className="text-reset font-weight-normal fs-14 d-block"
                                      check
                                  >
                                    <Input type="radio" name="reviewToKeep" />{" "}
                                    <div className="mb-1">
                                      {"Do not dispute review."}
                                    </div>
                                    <div className="fs-12 ff-alt">
                                      {
                                        "The dispute does not sufficiently meet any of the first three criteria."
                                      }
                                    </div>
                                    <div className="d-flex flex-wrap mx-n2 fs-14">
                                      <div className="px-2 mb-2">25%</div>
                                      <div className="px-2 mb-2 align-self-center">
                                        <Progress
                                            color="tertiary"
                                            value="25"
                                            style={{
                                              height: "6px",
                                              width: "240px",
                                            }}
                                        ></Progress>
                                      </div>
                                      <div className="px-2 mb-2">
                                        {"5 votes out of 12"}
                                      </div>
                                    </div>
                                  </Label>
                                </FormGroup>
                              </div>
                              <div className="px-2 col-auto">
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Expand"
                                >
                                  <FontAwesomeIcon icon="plus" />{" "}
                                </Button>
                                <Button
                                    color="outline-tertiary rounded-circle"
                                    size="sm"
                                    title="Collapse"
                                >
                                  <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                              </div>
                            </div>

                            {/* Collapse content */}
                            <div className="pl-3">Collapse content</div>
                          </div>
                        </div>

                        {/* Step 4.b */}
                        <div className="mt-3">
                          <div className="text-primary fs-16 font-weight-bold mb-2">
                            Step 4.b. Should this review be...
                          </div>
                          <div>
                            <div className="bg-light p-3 mb-1">
                              <div className="d-flex mx-n2 mb-2">
                                <div className="px-2 col">
                                  <FormGroup className="mb-2" check>
                                    <Label
                                        className="text-reset font-weight-normal fs-14 d-block"
                                        check
                                    >
                                      <Input
                                          type="radio"
                                          name="keepOrRemoveReview"
                                      />{" "}
                                      <div className="mb-1">
                                        ...kept on WikiReviews?
                                      </div>
                                      <div className="d-flex flex-wrap mx-n2 fs-14">
                                        <div className="px-2 mb-2">25%</div>
                                        <div className="px-2 mb-2 align-self-center">
                                          <Progress
                                              color="tertiary"
                                              value="25"
                                              style={{
                                                height: "6px",
                                                width: "240px",
                                              }}
                                          ></Progress>
                                        </div>
                                        <div className="px-2 mb-2">
                                          5 votes out of 12
                                        </div>
                                      </div>
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div className="px-2 col-auto">
                                  <Button
                                      color="outline-tertiary rounded-circle"
                                      size="sm"
                                      title="Expand"
                                  >
                                    <FontAwesomeIcon icon="plus" />{" "}
                                  </Button>
                                  <Button
                                      color="outline-tertiary rounded-circle"
                                      size="sm"
                                      title="Collapse"
                                  >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                  </Button>
                                </div>
                              </div>

                              {/* Collapse content */}
                              <div className="pl-3">Collapse content</div>
                            </div>
                            <div className="bg-light p-3 mb-1">
                              <div className="d-flex mx-n2 mb-2">
                                <div className="px-2 col">
                                  <FormGroup className="mb-2" check>
                                    <Label
                                        className="text-reset font-weight-normal fs-14 d-block"
                                        check
                                    >
                                      <Input
                                          type="radio"
                                          name="keepOrRemoveReview"
                                      />{" "}
                                      <div className="mb-1">
                                        ...removed from WikiReviews?
                                      </div>
                                      <div className="d-flex flex-wrap mx-n2 fs-14">
                                        <div className="px-2 mb-2">25%</div>
                                        <div className="px-2 mb-2 align-self-center">
                                          <Progress
                                              color="tertiary"
                                              value="25"
                                              style={{
                                                height: "6px",
                                                width: "240px",
                                              }}
                                          ></Progress>
                                        </div>
                                        <div className="px-2 mb-2">
                                          5 votes out of 12
                                        </div>
                                      </div>
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div className="px-2 col-auto">
                                  <Button
                                      color="outline-tertiary rounded-circle"
                                      size="sm"
                                      title="Expand"
                                  >
                                    <FontAwesomeIcon icon="plus" />{" "}
                                  </Button>
                                  <Button
                                      color="outline-tertiary rounded-circle"
                                      size="sm"
                                      title="Collapse"
                                  >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                  </Button>
                                </div>
                              </div>

                              {/* Collapse content */}
                              <div className="pl-3">Collapse content</div>
                            </div>
                          </div>
                        </div>

                        {/* Step 4.c */}
                        <div className="mt-3">
                          <div className="text-primary fs-16 font-weight-bold mb-2">
                            Step 4.c. Should this kept review be...
                          </div>
                          <div>
                            <div className="bg-light p-3 mb-1">
                              <div className="d-flex mx-n2 mb-2">
                                <div className="px-2 col">
                                  <FormGroup className="mb-2" check>
                                    <Label
                                        className="text-reset font-weight-normal fs-14 d-block"
                                        check
                                    >
                                      <Input
                                          type="radio"
                                          name="reasonToKeepReview"
                                      />{" "}
                                      <div className="mb-1">
                                        ...put in the filtered section?
                                      </div>
                                      <div className="d-flex flex-wrap mx-n2 fs-14">
                                        <div className="px-2 mb-2">25%</div>
                                        <div className="px-2 mb-2 align-self-center">
                                          <Progress
                                              color="tertiary"
                                              value="25"
                                              style={{
                                                height: "6px",
                                                width: "240px",
                                              }}
                                          ></Progress>
                                        </div>
                                        <div className="px-2 mb-2">
                                          5 votes out of 12
                                        </div>
                                      </div>
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div className="px-2 col-auto">
                                  <Button
                                      color="outline-tertiary rounded-circle"
                                      size="sm"
                                      title="Expand"
                                  >
                                    <FontAwesomeIcon icon="plus" />{" "}
                                  </Button>
                                  <Button
                                      color="outline-tertiary rounded-circle"
                                      size="sm"
                                      title="Collapse"
                                  >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                  </Button>
                                </div>
                              </div>

                              {/* Collapse content */}
                              <div className="pl-3">Collapse content</div>
                            </div>
                            <div className="bg-light p-3 mb-1">
                              <div className="d-flex mx-n2 mb-2">
                                <div className="px-2 col">
                                  <FormGroup className="mb-2" check>
                                    <Label
                                        className="text-reset font-weight-normal fs-14 d-block"
                                        check
                                    >
                                      <Input
                                          type="radio"
                                          name="reasonToKeepReview"
                                      />{" "}
                                      <div className="mb-1">
                                        ...kept where it is? (in the unfiltered
                                        section)
                                      </div>
                                      <div className="d-flex flex-wrap mx-n2 fs-14">
                                        <div className="px-2 mb-2">25%</div>
                                        <div className="px-2 mb-2 align-self-center">
                                          <Progress
                                              color="tertiary"
                                              value="25"
                                              style={{
                                                height: "6px",
                                                width: "240px",
                                              }}
                                          ></Progress>
                                        </div>
                                        <div className="px-2 mb-2">
                                          5 votes out of 12
                                        </div>
                                      </div>
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div className="px-2 col-auto">
                                  <Button
                                      color="outline-tertiary rounded-circle"
                                      size="sm"
                                      title="Expand"
                                  >
                                    <FontAwesomeIcon icon="plus" />{" "}
                                  </Button>
                                  <Button
                                      color="outline-tertiary rounded-circle"
                                      size="sm"
                                      title="Collapse"
                                  >
                                    <FontAwesomeIcon icon="minus" />{" "}
                                  </Button>
                                </div>
                              </div>

                              {/* Collapse content */}
                              <div className="pl-3">Collapse content</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Step 5 */}
                      <div className="mt-4">
                        <div className="text-primary fs-16 font-weight-bold mb-2">
                          Step 5. Please, tell us why.
                        </div>
                        <div className="text-dark">
                          It would be really helpful for fellow voters to know the
                          reasoning of your decision. It has to be 140 characters
                          long. Some text like that.
                        </div>
                        <div className="text-right text-primary fs-14">65</div>
                        <Input
                            type="textarea"
                            className="primary"
                            rows="3"
                            defaultValue="The Info is accurate, I've been there today."
                            placeholder="Please elaborate..."
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter className="bg-white">
                <Button color="primary">Submit</Button>
                <Button color="link" className="text-tertiary font-weight-bold">
                  Skip
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/* Jury Final Decision Modal */}
          <Modal
              size="xl"
              scrollable
              className="drawer-modal"
              isOpen={this.state.voteReviewModalToggleFinal}
              toggle={() =>
                  this.setState({
                    voteReviewModalToggleFinal: !this.state
                        .voteReviewModalToggleFinal,
                  })
              }
          >
            <form className="d-flex flex-column h-100">
              <div className="modal-header p-0">
                <div className="w-100">
                  <div>
                    <Button
                        color="primary"
                        onClick={() =>
                            this.setState({
                              voteReviewModalToggleFinal: !this.state
                                  .voteReviewModalToggleFinal,
                            })
                        }
                    >
                      <FontAwesomeIcon className="mr-2" icon="angle-left" />
                      back to listing
                    </Button>
                  </div>
                  <div className="bg-dark text-white fs-14 px-3">
                    <div className="mb-2 pt-2">
                      <div className="d-flex mx-n2">
                        <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Voting{" "}
                        </span>
                          <UncontrolledDropdown className="d-inline-block">
                            <DropdownToggle
                                className="text-white bg-transparent shadow-none font-weight-bold"
                                color="transparent"
                                size="sm"
                                caret
                            >
                              <span className="pr-2">Disputed Reviews</span>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem className="fs-14">
                                Disputed Reviews
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="px-2 col-auto">
                          near{" "}
                          <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                        </div>
                      </div>
                    </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input
                                type="select"
                                className="transparent"
                                size="sm"
                            >
                              <option>Businesses</option>
                            </Input>
                          </FormGroup>
                          <div className="ml-2">
                            <span className="text-danger">*</span>
                          </div>
                        </div>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>IT Services</option>
                            <option>Apartment Rentals</option>
                            <option>Automotive</option>
                            <option>Education</option>
                            <option>Beauty and Spas</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Asian</option>
                            <option>European</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Indian</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Level 6</option>
                          </Input>
                        </FormGroup>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ModalBody className="p-0 fs-14 text-dark bg-transparent">
                <div className="bg-dark text-white">
                  <Row noGutters>
                    <Col xs="auto">
                      <div
                          className="d-flex flex-column justify-content-center bg-dark text-white text-center px-3 py-4 h-100 bg-vote"
                          style={{ minWidth: "300px" }}
                      >
                        <div>
                          <img
                              src={require("../../../assets/images/icons/star/white/fill.png")}
                              alt=""
                          />
                          <h2 className="my-2 fs-50 text-shadow">JURY DUTY</h2>
                          <img
                              src={require("../../../assets/images/icons/star/white/fill.png")}
                              alt=""
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex flex-column justify-content-center bg-primary text-dark h-100 p-3">
                        <Row>
                          <Col>
                            <div className="pr-md-5">
                              <h3 className="text-white">
                                The Community has voted.
                              </h3>
                              <div className="fs-14">
                                <div className="fs-16 font-weight-bold">
                                  Their decision is to delete the review.
                                </div>
                                <p className="mb-0">
                                  Brief description of why you vote your counts
                                  and what you'd be voting on. Lorem ipsum, dolor
                                  sit amet consectetur adipisicing elit.
                                  Dignissimos, non?
                                </p>
                              </div>
                            </div>
                          </Col>
                          <Col md="auto" className="align-self-end">
                            <div className="text-light font-weight-bold text-muted">
                              Voting ended
                            </div>
                            <div className="d-flex mx-n2 flex-nowrap">
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings">0</div>
                                  <div className="font-weight-bold">days</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    00
                                  </div>
                                  <div className="font-weight-normal">hours</div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    00
                                  </div>
                                  <div className="font-weight-normal">
                                    minutes
                                  </div>
                                </div>
                              </div>
                              <div className="px-2">
                                <div className="text-muted">
                                  <div className="fs-32 ff-headings text-right">
                                    00
                                  </div>
                                  <div className="font-weight-normal">
                                    seconds
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="bg-white px-3 py-4">
                  <Row>
                    <Col md={4}>
                      <div className="font-weight-bold fs-16 text-primary mb-2">
                        Voting History
                      </div>
                      <ul className="d-flex flex-column list-unstyled">
                        <li className="py-2 border-bottom">
                          <div className="d-flex mx-n1">
                            <div className="px-1">
                              <div className="ff-alt">22/04/2014</div>
                            </div>
                            <div className="px-1">
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Sau6402
                              </a>
                              &nbsp; added a Punctuation in Tips.
                            </div>
                          </div>
                        </li>
                        <li className="py-2 border-bottom">
                          <div className="d-flex mx-n1">
                            <div className="px-1">
                              <div className="ff-alt">22/04/2014</div>
                            </div>
                            <div className="px-1">
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Danielbauwens
                              </a>
                              &nbsp; reverted edits by{" "}
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Fart biscuit
                              </a>
                              &nbsp; changed back to last version by{" "}
                              <a
                                  href="#"
                                  className="text-dark ff-base font-weight-bold"
                              >
                                Anna
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col md={8}>
                      <div>
                        <div className="font-weight-bold fs-16 text-primary mb-3">
                          The Decision
                        </div>

                        <div className="bg-light p-2 mb-3 border">
                          <div className="font-weight-bold">
                            Their Decision is to delete the review.
                          </div>
                          <div className="text-tertiary">
                            The review is inaccurate and will be removed from
                            WikiReviews
                          </div>
                        </div>

                        <div className="bg-light p-3 mt-5">
                          <div
                              className="overflow-y-auto"
                              style={{ maxHeight: "300px" }}
                          >
                            <div>
                              <div className="d-flex">
                                <div className="px-2">
                                  <img
                                      className="img-circle _50x50"
                                      src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                      alt=""
                                  />
                                </div>
                                <div className="px-2">
                                  <div className="mb-1">
                                    <a href="#" className="text-tertiary">
                                      <strong>UserName</strong>
                                    </a>
                                    &nbsp;
                                    <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                  </div>
                                  <div>
                                    "Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Aut nobis officia odit
                                    aspernatur obcaecati, dolorum quasi
                                    voluptatibus porro ullam! Perferendis libero
                                    atque perspiciatis voluptatibus quia similique
                                    iste recusandae sequi enim cum, nam quis est
                                    minus corporis sit porro aliquid at."
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div>
                              <div className="d-flex">
                                <div className="px-2">
                                  <img
                                      className="img-circle _50x50"
                                      src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                      alt=""
                                  />
                                </div>
                                <div className="px-2">
                                  <div className="mb-1">
                                    <a href="#" className="text-tertiary">
                                      <strong>UserName</strong>
                                    </a>
                                    &nbsp;
                                    <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                  </div>
                                  <div>
                                    "Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Aut nobis officia odit
                                    aspernatur obcaecati, dolorum quasi
                                    voluptatibus porro ullam! Perferendis libero
                                    atque perspiciatis voluptatibus quia similique
                                    iste recusandae sequi enim cum, nam quis est
                                    minus corporis sit porro aliquid at."
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div>
                              <div className="d-flex">
                                <div className="px-2">
                                  <img
                                      className="img-circle _50x50"
                                      src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                      alt=""
                                  />
                                </div>
                                <div className="px-2">
                                  <div className="mb-1">
                                    <a href="#" className="text-tertiary">
                                      <strong>UserName</strong>
                                    </a>
                                    &nbsp;
                                    <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                  </div>
                                  <div>
                                    "Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Aut nobis officia odit
                                    aspernatur obcaecati, dolorum quasi
                                    voluptatibus porro ullam! Perferendis libero
                                    atque perspiciatis voluptatibus quia similique
                                    iste recusandae sequi enim cum, nam quis est
                                    minus corporis sit porro aliquid at."
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div>
                              <div className="d-flex">
                                <div className="px-2">
                                  <img
                                      className="img-circle _50x50"
                                      src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                      alt=""
                                  />
                                </div>
                                <div className="px-2">
                                  <div className="mb-1">
                                    <a href="#" className="text-tertiary">
                                      <strong>UserName</strong>
                                    </a>
                                    &nbsp;
                                    <span className="fs-12 text-muted">
                                    one hour ago via iOS7
                                  </span>
                                  </div>
                                  <div>
                                    "Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Aut nobis officia odit
                                    aspernatur obcaecati, dolorum quasi
                                    voluptatibus porro ullam! Perferendis libero
                                    atque perspiciatis voluptatibus quia similique
                                    iste recusandae sequi enim cum, nam quis est
                                    minus corporis sit porro aliquid at."
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5">
                        <div className="font-weight-bold fs-16 text-primary mb-4">
                          Demographic &amp; Geographic Breakdown of Votes
                          <div className="text-tertiary font-weight-normal fs-14">
                            This is a text explaining what the information below
                            is all about.
                          </div>
                        </div>

                        <Table
                            bordered
                            size="sm"
                            className="text-center table-chart _cols-4"
                        >
                          <thead className="text-tertiary">
                          <tr>
                            <th>&nbsp;</th>
                            <th>Voted to Delete</th>
                            <th>Voted to Delete</th>
                            <th>Voted to Keep, as Questionable</th>
                          </tr>
                          </thead>
                          <tbody className="ff-alt text-muted">
                          <tr>
                            <th className="bg-light" scope="row">
                              Results
                            </th>
                            <td className="bg-light">18.75%</td>
                            <td className="bg-light">43.75%</td>
                            <td className="bg-light">37.5%</td>
                          </tr>
                          </tbody>
                        </Table>

                        <div className="mt-5">
                          <div className="text-dark fs-16 font-weight-bold mb-2">
                            Age Group
                          </div>
                          <Table
                              bordered
                              size="sm"
                              className="text-center table-chart _cols-4"
                          >
                            <thead className="text-tertiary">
                            <tr>
                              <th>Age Group</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Keep, as Questionable</th>
                            </tr>
                            </thead>
                            <tbody className="ff-alt text-muted">
                            <tr>
                              <th className="bg-light" scope="row">
                                18-24
                              </th>
                              <td className="bg-light">2</td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                25-34
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">3</td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                35-44
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">4</td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                45-54
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                              <td className="bg-light">6</td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                55-64
                              </th>
                              <td className="bg-light">1</td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                65+
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                            </tbody>
                          </Table>
                        </div>

                        <div className="mt-5">
                          <div className="text-dark fs-16 font-weight-bold mb-2">
                            Gender
                          </div>
                          <Table
                              bordered
                              size="sm"
                              className="text-center table-chart _cols-4"
                          >
                            <thead className="text-tertiary">
                            <tr>
                              <th>Gender</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Keep, as Questionable</th>
                            </tr>
                            </thead>
                            <tbody className="ff-alt text-muted">
                            <tr>
                              <th className="bg-light" scope="row">
                                Male
                              </th>
                              <td className="bg-light">2</td>
                              <td className="bg-light">2</td>
                              <td className="bg-light">2</td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Female
                              </th>
                              <td className="bg-light">3</td>
                              <td className="bg-light">3</td>
                              <td className="bg-light">3</td>
                            </tr>
                            </tbody>
                          </Table>
                        </div>

                        <div className="mt-5">
                          <div className="text-dark fs-16 font-weight-bold mb-2">
                            Geography
                          </div>
                          <Table
                              bordered
                              size="sm"
                              className="text-center table-chart _cols-4"
                          >
                            <thead className="text-tertiary">
                            <tr>
                              <th>Geography</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Delete</th>
                              <th>Voted to Keep, as Questionable</th>
                            </tr>
                            </thead>
                            <tbody className="ff-alt text-muted">
                            <tr>
                              <th className="bg-light" scope="row">
                                Irvine, CA
                              </th>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Brooklyn, NY
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Perris, CA
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Mequon, WI
                              </th>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                            </tr>
                            <tr>
                              <th className="bg-light" scope="row">
                                Chicago, IL
                              </th>
                              <td className="bg-light">
                                <FontAwesomeIcon icon="check" />
                              </td>
                              <td className="bg-light"></td>
                              <td className="bg-light"></td>
                            </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
            </form>
          </Modal>

          {/* Limited Disputes Modal */}
          <Modal
              centered
              isOpen={this.state.limitedDisputesModalToggle}
              toggle={() =>
                  this.setState({
                    limitedDisputesModalToggle: !this.state
                        .limitedDisputesModalToggle,
                  })
              }
          >
            <ModalBody className="p-4">
              <Row className="mb-3">
                <Col xs={3}>
                  <img
                      className="img-fluid"
                      src={require("../../../assets/images/icons/paywall.png")}
                      alt="Bell"
                  />
                </Col>
                <Col xs={9}>
                  <h4 className="text-primary mb-2 ff-base fs-16">
                    You have disputed 3 reviews for free.
                  </h4>
                  <p className="text-primary-dark ff-base fs-14">
                    Upgrade to <strong>Premium Business</strong> account to get
                    unlimited ability to dispute and other more benefits.
                  </p>
                </Col>
              </Row>
              <Button color="primary" size="lg" block>
                {" "}
                Upgrade to PREMIUM and get more benefits
              </Button>
              <div className="text-center mt-2">
                <Button
                    color="link"
                    className="text-dark"
                    onClick={() =>
                        this.setState({ limitedDisputesModalToggle: false })
                    }
                >
                  Stay with FREE account
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
              size="xl"
              scrollable
              className="drawer-modal"
              isOpen={this.state.patrolReviewModalToggleOne}
              toggle={() =>
                  this.setState({
                    patrolReviewModalToggleOne: !this.state
                        .patrolReviewModalToggleOne,
                  })
              }
          >
            <form className="d-flex flex-column h-100">
              <div className="modal-header p-0">
                <div className="w-100">
                  <div>
                    <Button
                        color="primary"
                        onClick={() =>
                            this.setState({
                              patrolReviewModalToggleOne: !this.state
                                  .patrolReviewModalToggleOne,
                            })
                        }
                    >
                      <FontAwesomeIcon className="mr-2" icon="angle-left" />
                      back to listing
                    </Button>
                  </div>
                  <div className="bg-dark text-white fs-14 px-3">
                    <div className="mb-2 pt-2">
                      <div className="d-flex mx-n2">
                        <div className="px-2 col">
                        <span className="fs-14 text-light font-weight-bold">
                          You’re Patroling{" "}
                        </span>
                          <UncontrolledDropdown className="d-inline-block">
                            <DropdownToggle
                                className="text-white bg-transparent shadow-none font-weight-bold"
                                color="transparent"
                                size="sm"
                                caret
                            >
                              <span className="pr-2">Reviews</span>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem className="fs-14">
                                Reviews
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div className="px-2 col-auto">
                          near{" "}
                          <span className="text-light font-weight-bold">
                          Brooklyn, NY
                        </span>
                        </div>
                      </div>
                    </div>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                      <li className="px-2">
                        <div className="d-flex">
                          <FormGroup>
                            <Input
                                type="select"
                                className="transparent"
                                size="sm"
                            >
                              <option>Businesses</option>
                            </Input>
                          </FormGroup>
                          <div className="ml-2">
                            <span className="text-danger">*</span>
                          </div>
                        </div>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>IT Services</option>
                            <option>Apartment Rentals</option>
                            <option>Automotive</option>
                            <option>Education</option>
                            <option>Beauty and Spas</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Asian</option>
                            <option>European</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Indian</option>
                          </Input>
                        </FormGroup>
                      </li>
                      <li className="px-2">
                        <FormGroup>
                          <Input type="select" className="transparent" size="sm">
                            <option>Select Category</option>
                            <option>Level 6</option>
                          </Input>
                        </FormGroup>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <ModalBody className="fs-14 text-dark">
                <Row>
                  <Col lg={8}>
                    <div className="text-primary fs-16 font-weight-bold mb-2">
                      Step 1. Brief
                    </div>
                    <div>
                      <strong>The Brief.</strong>
                    </div>
                    <p>
                      The Original Listing, currently marked as{" "}
                      <span className="font-weight-bold">Non Recommended</span>,
                      was{" "}
                      <span className="font-weight-bold">
                      Nominated for deletion
                    </span>{" "}
                      due to{" "}
                      <span className="font-weight-bold">Facts are Wrong</span> 3
                      months ago, by{" "}
                      <span className="font-weight-bold">Business Owner</span>{" "}
                      (a.k.a the business owner). It was disputed 1 week ago by
                      User2 (a.k.a the reviewer).
                    </p>
                    <div className="bg-light p-3">
                      <div className="text-black mb-2">
                        New Review on ListingName
                      </div>
                      <div className="font-weight-bold">
                        <div className="mb-1">
                          John Username's Review{" "}
                          <img
                              src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png"
                              alt=""
                          />
                        </div>
                      </div>
                      <div className="text-tertiary font-weight-bold">
                        <div className="mb-1">
                          Attention{" "}
                          <img
                              src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png"
                              alt=""
                          />
                        </div>
                        <div className="mb-1">
                          Ambient{" "}
                          <img
                              src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/3rating.png"
                              alt=""
                          />
                        </div>
                      </div>
                      <div className="my-4">
                        <FontAwesomeIcon icon="quote-left" />
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Placeat eius adipisci mollitia eos incidunt. Consequatur
                        deleniti neque, eos suscipit saepe quos velit?
                        Necessitatibus dolorum nulla reprehenderit totam, dolore
                        doloribus ut.
                        <FontAwesomeIcon icon="quote-right" />
                      </div>

                      <div className="text-center mt-3">
                        <img
                            className="img-fluid"
                            src="https://source.unsplash.com/random/600x300"
                            alt=""
                        />
                      </div>

                      <div className="mt-3">
                        <Carousel
                            responsive={responsive.viewGalleryImages}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            containerClass="mx-n4"
                        >
                          <div className="item px-4">
                            <div className="img-holder text-center">
                              <img
                                  className="img-thumbnail fixed-height-img full-width"
                                  src="https://source.unsplash.com/random/100x100"
                                  alt=""
                              />
                              <label
                                  for="imageUpload"
                                  className="btn btn-tertiary bg-tertiary border-tertiary"
                              >
                                Upload Media
                              </label>
                            </div>
                            <div className="mb-3">
                              <Row xs={2} sm={3} md={4} form>
                                {this.state.dispute_imgepreview.map(
                                    (image, index) => (
                                        <Col className="mb-3">
                                          <div
                                              className="d-flex pr-3"
                                              role="button"
                                              onClick={() =>
                                                  this.setState({
                                                    viewMyPhotosModal: true,
                                                  })
                                              }
                                          >
                                            <div className="flex-grow-1 mw-100">
                                              {image
                                                  .toString()
                                                  .includes("data:image") ? (
                                                  <img
                                                      className="img-fluid img-thumbnail"
                                                      src={image}
                                                      alt="dispute"
                                                      title="Image Name"
                                                  />
                                              ) : image
                                                  .toString()
                                                  .includes("data:video") ? (
                                                  <div
                                                      className="bg-light p-2 text-truncate border fs-13"
                                                      title={
                                                        this.state.dispute_files &&
                                                        this.state.dispute_files[index] &&
                                                        this.state.dispute_files[index]
                                                            .name
                                                      }
                                                  >
                                                    <img
                                                        width={24}
                                                        className="mr-2"
                                                        src={
                                                          "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/videoicon.jpg"
                                                        }
                                                        alt="Icon"
                                                    />{" "}
                                                    {this.state.dispute_files &&
                                                    this.state.dispute_files[index] &&
                                                    this.state.dispute_files[index]
                                                        .name}
                                                  </div>
                                              ) : (
                                                  <div
                                                      className="bg-light p-2 text-truncate border fs-13"
                                                      title={
                                                        this.state.dispute_files &&
                                                        this.state.dispute_files[index] &&
                                                        this.state.dispute_files[index]
                                                            .name
                                                      }
                                                  >
                                                    <img
                                                        width={24}
                                                        className="mr-2"
                                                        src={
                                                          "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/file_icon.png"
                                                        }
                                                        alt="Icon"
                                                    />{" "}
                                                    {this.state.dispute_files &&
                                                    this.state.dispute_files[index] &&
                                                    this.state.dispute_files[index]
                                                        .name}
                                                  </div>
                                              )}
                                            </div>
                                            <div className="mx-n3 mt-2">
                                              <Button
                                                  color="delete-media"
                                                  size="sm"
                                                  title="Remove Media"
                                                  onClick={() =>
                                                      this.deleteImage(index)
                                                  }
                                              >
                                                <FontAwesomeIcon icon="trash-alt" />{" "}
                                              </Button>
                                            </div>
                                          </div>
                                        </Col>
                                    )
                                )}
                              </Row>
                            </div>

                            <div className="text-right">
                              <Button
                                  color="primary"
                                  className="mw"
                                  onClick={() =>
                                      this.setState({ showReply: false })
                                  }
                              >
                                Cancel
                              </Button>
                              <Button
                                  color="primary"
                                  className="mw"
                                  onClick={() =>
                                      this.handleDisputeReply(get_dispute_data)
                                  }
                              >
                                Submit Reply
                              </Button>
                            </div>
                          </div>
                          <div className="item px-4">
                            <div className="img-holder text-center">
                              <img
                                  className="img-thumbnail fixed-height-img full-width"
                                  src="https://source.unsplash.com/random/100x100"
                                  alt=""
                              />
                            </div>
                          </div>
                          <div className="item px-4">
                            <div className="img-holder text-center">
                              <img
                                  className="img-thumbnail fixed-height-img full-width"
                                  src="https://source.unsplash.com/random/100x100"
                                  alt=""
                              />
                            </div>
                          </div>
                        </Carousel>
                      </div>
                      <hr />
                      <div className="d-flex mx-n2">
                        <div className="px-2">
                          <img
                              className="img-circle _50x50"
                              src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                              alt=""
                          />
                        </div>
                        <div className="px-2">
                          <div className="mb-2">
                          <span>
                            Submitted by{" "}
                            <a href="#" className="text-reset">
                              <strong>Techdarma</strong>
                            </a>
                          </span>
                            <div>
                            <span className="fs-12 text-muted">
                              one hour ago via iOS7
                            </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-4">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 2. Check if the review doesn't adheres to our tips
                      </div>
                      <div>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input className="checked-bold" type="checkbox" />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Do not post photos or videos of other copyrighted
                            material that you don't have permission to use. Do
                            not post photos of people without their permission.
                            If they are children, ask the parents before you
                            post.
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input className="checked-bold" type="checkbox" />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Opinions are almost always protected as free speech
                            except in the case of defamation (a false accusation
                            of an offense or a malicious misrepresentation of
                            someone's words or actions). true threats,
                            solicitations to commit crimes, incitement to
                            imminent lawless action, blackmail, fighting words
                            and obscenity.
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input className="checked-bold" type="checkbox" />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Please ommit excessive exaggeration and do not lie.
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input className="checked-bold" type="checkbox" />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Deliberately sabotaging the viability of a business
                            is strongly discouraged.
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input className="checked-bold" type="checkbox" />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Please be accurate, avoid embellishment and broad
                            generalizations, and to relate only to your first
                            hand experiences.
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Input
                              className="primary"
                              type="textarea"
                              bsSize="sm"
                              placeholder="(optional) Write a message to the user re: the tips"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="mb-">
                      <div className="text-primary fs-16 font-weight-bold mb-2">
                        Step 3. Check for Flags
                      </div>
                      <div>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            General Issue
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Offensive
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Profanity
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">Spam</span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Hate/Racist Based
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Illegal Drug Focused
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Notability
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Universally Illegal
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Vanity Page
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Incomplete/Not Useful
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup className="mb-2" check>
                          <Label check>
                            <Input
                                className="checked-bold"
                                type="radio"
                                name="flagIssueType"
                            />{" "}
                            <span className="text-dark fs-14 ff-alt">
                            Vandalism
                          </span>
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Input
                              className="primary"
                              type="textarea"
                              bsSize="sm"
                              placeholder="Please elaborate..."
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="bg-white">
                <Button color="primary">Submit</Button>
                <Button color="link" className="text-tertiary font-weight-bold">
                  Skip
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/* SEE HOW REVIEW DISPUTES WORK */}
          <Modal
              isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
              toggle={() =>
                  this.setState({
                    watchHowDisputeReviewWorksModalToggle: !this.state
                        .watchHowDisputeReviewWorksModalToggle,
                  })
              }
          >
            <ModalHeader
                className="text-uppercase px-0"
                toggle={() =>
                    this.setState({
                      watchHowDisputeReviewWorksModalToggle: !this.state
                          .watchHowDisputeReviewWorksModalToggle,
                    })
                }
            >
              SEE HOW REVIEW DISPUTES WORK
            </ModalHeader>
            <ModalBody className="p-0 bg-transparent">
              <div className="text-center">
              <video controls width="100%" seekable>
                  <source
                      src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                      type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </ModalBody>
          </Modal>
          
          {/* View Project Images Modal Popup */}
        <Modal
          size="lg"
          isOpen={this.state.viewProjectImagesModal}
          toggle={() => this.setState({ viewProjectImagesModal: !this.state.viewProjectImagesModal })}
        >
          <ModalHeader toggle={() => this.setState({ viewProjectImagesModal: !this.state.viewProjectImagesModal })}></ModalHeader>
          <ModalBody className="bg-transparent p-0">
            <Carousel
              responsive={responsive.videoModal}
              infinite={true}
              autoPlay={false}
              swipeable={true}
              showDots={false}
              arrows={true}
              sliderClass={this.state.itemsAttachment?.length === 0 ? "mx-auto" : ""}>
              {this.projectImageSlides()}
            </Carousel>
          </ModalBody>
        </Modal>
        </>
    );
  }
}

const mapState = (state) => ({
  feeds_data: state.feeds.feeds_data,  
  isLoadingFeeds: state.feeds.isLoadingFeeds,
  get_dispute_data: state.feeds.get_dispute_data,
  get_reveiewData: state.user.get_reveiewData,
  my_profile: state.user.my_profile,
  get_reveiew_helpCount: state.feeds.get_reveiew_helpCount,
  get_dispute_modal_status: state.dispute.get_dispute_modal_status,
  my_profile_data: state.user.my_profile,
  newReviewMediaSet: state.reviewMedia.newReviewMediaSet,
  newReviewEmbedLinkSetFinalized: state.reviewMedia.newReviewEmbedLinkSetFinalized,
  followReviewMediaSet: state.reviewMedia.followReviewMediaSet,
  followReviewEmbedLinkSetFinalized: state.reviewMedia.followReviewEmbedLinkSetFinalized,
  editReviewMediaSetFinalized: state.reviewMedia.editReviewMediaSetFinalized,
  isFollowReviewEnable: state.shared.isFollowReviewEnable

});

const mapDispatch = (dispatch) => ({
  get_feeds: async (params) => get_feeds(params, dispatch),
  setFilter: () => dispatch(setFilter()),
  update_reaction: (url, method, data) =>
      dispatch(update_reaction(url, method, data)),
  updateFeedReactions: (params, feedParams) =>
      dispatch(updateFeedReactions(params, feedParams)),
  commentOnFeed: (params, method, feedParams) =>
      dispatch(addUpdateCommentOnFeed(params, method, feedParams)),
  deleteComments: (params, feedParams) =>
      dispatch(deleteComments(params, feedParams)),
  commentOnReviews: (params, body, method, feedParams) =>
      dispatch(submitCommentOnFeedReview(params, body, method, feedParams)),
  updateReviewFeedback: (params, feedParams) =>
      dispatch(updateReviewFeedback(params, feedParams)),
  submitCommentOnFeedbacks: (params, body, method, feedParams) =>
      dispatch(submitCommentOnFeedbacks(params, body, method, feedParams)),
  remove_from_list: (url, message, nextUrl) =>
      dispatch(remove_from_list(url, message, nextUrl)),
  share_by_email: (url, data) => dispatch(share_by_email(url, data)),
  add_to_list: (url, data, message, nextUrl) =>
      dispatch(add_to_list(url, data, message, nextUrl)),

  get_dispute_discussion: (id) => dispatch(get_dispute_discussion(id)),
  delete_dispute: (id) => dispatch(delete_dispute(id)),
  updateFeedbackDiscussion: (params, method, feedParams) =>
      dispatch(updateFeedbackDiscussion(params, method, feedParams)),
  add_dispute_reply: (params) => dispatch(add_dispute_reply(params)),
  get_reviewgroup: (assetType, postId, type) =>
      dispatch(get_reviewgroup(assetType, postId, type)),
  delete_dispute_reply: (id, reviewId) =>
      dispatch(delete_dispute_reply(id, reviewId)),
  getreviewHelpfulCopunt: (reviewId) =>
      dispatch(getreviewHelpfulCopunt(reviewId)),  
  reset_feeds: (data) =>
    dispatch(reset_feeds(data)),
  uploadMediaInReviewMediaSetForNewReview: (data) => dispatch(uploadMediaInReviewMediaSetForNewReview(data)),
  uploadMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(uploadMediaInReviewEmbedLinkSetForNewReview(data)),
  finalizedMediaInReviewMediaSetForNewReview: (data) => dispatch(finalizedMediaInReviewMediaSetForNewReview(data)),
  finalizedMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForNewReview(data)),
  uploadMediaInReviewMediaSetForEditReview: (data) => dispatch(uploadMediaInReviewMediaSetForEditReview(data)),
  uploadMediaInReviewMediaSetForFollowReview: (data) => dispatch(uploadMediaInReviewMediaSetForFollowReview(data)),
  finalizedMediaInReviewEmbedLinkSetForFollowReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForFollowReview(data)),
  addPostCommentOnFeed: (data) => dispatch(addPostCommentOnFeed(data)),
  addPostCommentReplyOnFeed: (data) => dispatch(addPostCommentReplyOnFeed(data)),
  deletePostCommentReplyOnFeed: (id, type, feedParams) => dispatch(deletePostCommentReplyOnFeed(id, type, feedParams))
});

export default connect(mapState, mapDispatch)(Feed);
