/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Input, InputGroup, InputGroupAddon, } from 'reactstrap';
import { Link } from 'react-router-dom';


class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            about: null,
            tempIcon: null,
            editName: false,
            phoneSet: [],
            emailSet: [],
            websiteSet: [],
            pageNo: 1,
            readMore: false,
            actoId: 0,
        }
    }


    componentWillReceiveProps(nextProps) {
        const { aboutDetails, actorId } = nextProps;
        if (aboutDetails) {
            this.setState({
                about: aboutDetails,
                phoneSet: aboutDetails?.professionalphone_set,
                emailSet: aboutDetails?.professionalemail_set,
                websiteSet: aboutDetails?.professionalwebsite_set,
            });
        }
        if (actorId) {
            this.setState({
                actoId: actorId,
            });
        }
    }
    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null

    }

    render() {
        const {
            about,
            phoneSet,
            tempIcon,
            emailSet,
            modelSet,
        } = this.state;
        let avg_rating = about && about?.total_rating && about?.total_rating.avg_rating[0] ? about.total_rating.avg_rating[0] : 'norating.png';
        return (
            <div className="text-center">
                <Container className="position-relative">
                    <div className={'text-center position-relative about-view ' + `${!this.state.toggleMoreLessInfo ? '_mini' : ''}`}>
                        <div className="wr-movie-top-about d-flex flex-column">
                            <div className="wr-movie-top-about-body">
                                <Row>
                                    <Col>
                                        <div className="wr-movie-product-info-top d-flex flex-wrap">
                                            <div className="wr-movie-product-info-top-left">
                                                <img
                                                    src={`${about && about.professional_profileimage && about.professional_profileimage.length > 0 && Array.isArray(about.professional_profileimage) && about?.professional_profileimage[0] && about.professional_profileimage[0].url}`}
                                                    className="img-fluid"
                                                    onError={(error) =>
                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/actor-neutral-02-472x446.jpg`)
                                                    }
                                                    alt={`${about && about?.professional_profileimage && about?.professional_profileimage.length > 0 && about?.professional_profileimage[0] && about.professional_profileimage[0].caption ? about.professional_profileimage[0].caption : 'no-image'}`}
                                                />

                                            </div>
                                            <div className="wr-movie-product-info-top-right">
                                                <div className="wr-category-heading">
                                                    <h4>{about && about.firstname} {about && about.lastname}</h4>
                                                    <div hidden>
                                                        <InputGroup size="sm">
                                                            <Input type="text" placeholder="Enter Name" />
                                                            <InputGroupAddon addonType="append">
                                                                <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </div>
                                                    <div className="ff-alt fs-14 text-dark" hidden>
                                                        Profession in <span className="font-weight-bold">Acting, Writing</span>
                                                    </div>
                                                    <p className="fs-14"><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${avg_rating}`}
                                                        className="img-fluid"
                                                        onError={(error) =>
                                                            (error.target.src = `../../../assets/images/norating.png`)
                                                        }
                                                        alt={`${about && about?.total_rating && about?.total_rating.avg_rating[1] ? about.total_rating.avg_rating[1] : 'no-rating'}`}

                                                    />{" "}{(about && about.total_rating && about.total_rating.total_review) ? about.total_rating.total_review : 0} reviews</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wr-movie-product-info-bottom wr-business-product-info-bottom" hidden>
                                            <ul className="m-0 p-0">
                                                <li className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <span className="fs-14 business-icon _map"></span>
                                                    </div>
                                                    <div className="px-2 col">
                                                        <span className="fs-14 font-weight-bold">
                                                            {about && about.address && about.address.length > 0 && about.address[0].name ? about.address[0].name : ""} {about && about.address && about.address.length > 0 && about.address[0].address1 ? about.address[0].address1 : ""}
                                                            {about && about.address && about.address.length > 0 && (about.address[0].name || about.address[0].address1) ? <br /> : ''}
                                                            {about && about.address && about.address.length > 0 && about.address[0].city ? about.address[0].city + "," : ""} {about && about.address && about.address.length > 0 && about.address[0].state ? about.address[0].state + "," : ""} {about && about.address && about.address.length > 0 && about.address[0].zipcode ? about.address[0].zipcode + "," : ""}
                                                            {about && about.address && about.address.length > 0 && (about.address[0].city || about.address[0].state || about.address[0].zipcode) ? ' ' : ' '}
                                                            <span className="text-uppercase">
                                                                {about && about.address && about.address.length > 0 && about.address[0].country ? about.address[0].country : ""}
                                                            </span>

                                                        </span>
                                                    </div>
                                                </li>
                                                {/* <li className="d-flex mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 business-icon _phone"></span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        {phoneSet && phoneSet.length > 0 ?
                                                                       
                                                                       
                                                                            <>{phoneSet.map((phoneData,index) => {
                                                                             
                                                                                return (
                                                                                <span className="fs-14" key={index}> <span className="font-weight-bold">
                                                                                        {phoneData ? phoneData.label === "Mob"? phoneData.phone :  this.formatPhoneNumber(phoneData.phone) : ""}</span>{" "}<span> {phoneData ? <>({phoneData.label})</> : ""}</span></span>
                                                                                )
                                                                            })}</> : ""
                                                                        }
                                                                    </div>
                                                                </li> */}
                                                <li className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <span className="fs-14 business-icon _email"></span>
                                                    </div>
                                                    <div className="px-2 col">
                                                        {emailSet && emailSet.length > 0 ?
                                                            <>{emailSet.map((Addemail, index) => {
                                                                return (<span className="fs-14 font-weight-bold" key={index}>
                                                                    {Addemail ? Addemail.email : ""} {""} <span>{Addemail ? <>({Addemail.email_type})</> : ""}</span>

                                                                </span>)

                                                            })}</> : ""
                                                        }
                                                        <div hidden>
                                                            <InputGroup size="sm">
                                                                <Input type="text" placeholder="Enter Valid Phone" />
                                                                <InputGroupAddon addonType="append">
                                                                    <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                    {this.state.toggleMoreLessInfo ?
                                        <Col md={8}>
                                            <Row>
                                                <Col>
                                                    <div className="wr-category-heading">
                                                        {about && about.total_rating && Object.keys(about.total_rating).length > 0 ?

                                                            about && Object.keys(about.total_rating).map((rating, index) => {
                                                                if (rating !== "total_review" && rating !== "avg_rating") {
                                                                    return <p className="fs-14" key={index}><img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${about.total_rating[rating][0]}`} className="img-fluid" alt={rating || ""} /> {rating}</p>

                                                                }
                                                            }) : ''}
                                                        <div className="wr-movie-product-info-bottom">
                                                            <ul className="list-unstyled row">
                                                                <li className="col-md-6 d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 font-weight-bold">Born:</span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <span className="fs-14">
                                                                            {about && about.date_of_birth ? about?.date_of_birth.birth_date : ""} in
                                                                                    <br />
                                                                            {about && about.date_of_birth ? about?.date_of_birth.birth_location : ""}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li className="col-md-6 d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 font-weight-bold">Appearances:</span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <span className="fs-14">
                                                                            {about && about.appearances ? about.appearances.movies : "0"} Movies
                                                                                </span>
                                                                    </div>
                                                                </li>
                                                                <li className="col-md-6 d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2" >
                                                                        <span className="fs-14 font-weight-bold">Other:</span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <ul className="fs-14 list-normal">
                                                                            {(about && about?.job_category && about?.job_category?.job_category && about?.job_category?.job_category.length > 0) ? (
                                                                                about?.job_category?.job_category.map((jobCategory, i) => {
                                                                                    return (
                                                                                        <li className="mb-1" key={i}>
                                                                                            <span className="mr-1">
                                                                                                {jobCategory?.value}
                                                                                            </span>
                                                                                        </li>

                                                                                    );
                                                                                })
                                                                            ) : ("")}
                                                                        </ul>
                                                                    </div>
                                                                </li>
                                                                <li className="col-md-6 d-flex flex-column mb-2 mx-n2">
                                                                    <div className="px-2">
                                                                        <span className="fs-14 font-weight-bold">Website:</span>
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                        <span className="fs-14 font-weight-bold">
                                                                            {about && about.professionalwebsite_set && about.professionalwebsite_set.length > 0 ?
                                                                                <>{about.professionalwebsite_set.map((site, index) => {
                                                                                    if (site.website_type != 'Main') {
                                                                                        return <Link className="wb-break-word" target="_blank" to={
                                                                                            {
                                                                                                pathname: "/abouttransition/",
                                                                                                search: `?page_url=${site.website}`
                                                                                            }
                                                                                        } >
                                                                                            {site ? site.website_type === 'Facebook' ? (<span><i class="fa fa-facebook" aria-hidden="true"></i></span>) : ("") ||
                                                                                                site.website_type === 'Google+' ? (<span><i class="fa fa-google-plus" aria-hidden="true"></i></span>) : ("") ||
                                                                                                    site.website_type === 'Twitter' ? (<span><i class="fa fa-twitter" aria-hidden="true"></i></span>) : ("") ||
                                                                                                        site.website_type === 'Instagram' ? (<span><i class="fa fa-instagram" aria-hidden="true"></i></span>) : ("") ||
                                                                                                            site.website_type === 'LinkedIn' ? (<span><i class="fa fa-linkedin" aria-hidden="true"></i></span>) : ("") : ""}||
                                                                                        </Link>
                                                                                    }
                                                                                })}</> : ""
                                                                            }
                                                                        </span>
                                                                        <span className="fs-14 font-weight-bold editable-column" hidden>Click to add</span>

                                                                        <div hidden>
                                                                            <InputGroup size="sm">
                                                                                <Input type="url" placeholder="Enter valid Website" />
                                                                                <InputGroupAddon addonType="append">
                                                                                    <Button color="light" title="Update">
                                                                                        <FontAwesomeIcon icon="check" fixedWidth />
                                                                                    </Button>
                                                                                    <Button color="light" title="Cancel" className="mx-0">
                                                                                        <FontAwesomeIcon icon="times" fixedWidth />
                                                                                    </Button>
                                                                                </InputGroupAddon>
                                                                            </InputGroup>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        :
                                        ''
                                    }
                                </Row>
                            </div>
                            <div className="mt-auto ml-auto wr-movie-less-info-about">
                                <span role="button" onClick={() => this.setState({ toggleMoreLessInfo: !this.state.toggleMoreLessInfo })}>
                                    {this.state.toggleMoreLessInfo ? <><span className="text">less info</span><span className="icon-arrow-left"></span></> : <><span className="text">more info</span><span className="icon-arrow-right"></span></>}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="floated-info-section d-flex align-items-center flex-wrap justify-content-center">
                        <div className="image-area-show">

                            <img src={`${about && about.media_dict && about.media_dict.length > 0 && Array.isArray(about.media_dict) && about?.media_dict[0] && about.media_dict[0].url}`}
                                className="img-fluid"
                                onError={(error) =>
                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                }
                                alt={`${about && about?.media_dict && about?.media_dict.length > 0 && about?.media_dict[0] && about.media_dict[0].caption ? about.media_dict[0].caption : 'no-image'}`}
                            />
                        </div>
                        <div className="image-area-crop fs-14">
                            Help us to make WikiReviews a better place! <br /> Please upload an alternate image. Suggested Size 1600x1050.
                                        </div>
                    </div>
                    {/* <div className="floated-no-image-section">
                                        <Button color="primary" size="lg">Upload Images</Button>
                                    </div> */}
                </Container>
            </div>

        )
    }
}



export default About;