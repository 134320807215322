import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ReviewTab from "../../atoms/reviewTab";
import DiscussionsTab from './discussionsTab';
import QandATab from './QandATab';

class DetailReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainViewType: 'reviews',
            uploadedFiles: [],
            uploadedFilesFollowUp: [],
            embedMediaFollowUp:[]
        }
    }

    componentDidUpdate() {
        let nextProps = this.props;
        let next_uploaded_files = nextProps.uploadedMediaSet?.uploadedFiles.length;
        let next_uploaded_files_follow = nextProps.uploadedMediaSetFollow?.uploadedFiles.length;

        const { uploadedFiles,uploadedFilesFollowUp, } = this.state;

        if (next_uploaded_files !== uploadedFiles.length) {
            const { uploadedFiles } = this.props.uploadedMediaSet;
            this.setState({ uploadedFiles: [...uploadedFiles] });
        }
        if (next_uploaded_files_follow !== uploadedFilesFollowUp.length) {
            const { uploadedFiles } = this.props.uploadedMediaSetFollow;
            this.setState({ uploadedFilesFollowUp: [...uploadedFiles] });
        }
        if (next_uploaded_files_follow !== uploadedFilesFollowUp.length) {
            const { embedLinks } = this.props.uploadedMediaSetFollow;
            this.setState({ embedMediaFollowUp: [...embedLinks] });
        }
    }
	
	componentWillMount() {
		let props = this.props;
        let mainViewTyp = props?.history?.location?.state?.notif_tab;
		if(mainViewTyp && mainViewTyp != ""){
		this.setState({mainViewType:mainViewTyp});
		}
    }

    navLinkItem = ({ type, title }) => {
        const { mainViewType } = this.state;
        return (
            <NavItem >
                <NavLink
                    className="ff-base"
                    active={mainViewType === type}
                    onClick={() => {
                        this.props.resetUploadedMediaSet();
                        this.setState({ mainViewType: type })
                    }}
                >
                    {title}
                </NavLink>
            </NavItem>
        )
    }

    render() {
        const { reviews, listing_type, item_detail, toggleConfirmModal, toggleEnableFileUpload, overallRatingVote, viewEditReviewStep, onPublishReview, uniqueDeleteKey, uniqueDeleteCommentKey } = this.props;
        let { mainViewType, uploadedFiles ,uploadedFilesFollowUp,embedMediaFollowUp} = this.state;

        return (
            <div>
                <Nav tabs className="text-center align-items-end active-transparent">
                    {this.navLinkItem({ type: "reviews", title: 'Reviews' })}
                    {this.navLinkItem({ type: "qa", title: 'Q&A' })}
                    {this.navLinkItem({ type: "discussion", title: 'Discussion' })}
                </Nav>
                <TabContent activeTab={mainViewType}>
                    <TabPane tabId="reviews">
                        <ReviewTab
                            uniqueDeleteCommentKey={uniqueDeleteCommentKey}
                            uniqueDeleteKey={uniqueDeleteKey}
                            reviews={reviews}
                            item_detail={item_detail}
                            toggleConfirmModal={toggleConfirmModal}
                            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload,newReviewStatus,followUpStatus)=>{
                                toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus,followUpStatus);                               
                            }}
                            listing_type={listing_type}
                            overallRatingVote={overallRatingVote}
                            viewEditReviewStep={viewEditReviewStep}
                            mainViewType={mainViewType}
                            onPublishReview={onPublishReview}
                            uploadedMediaSet={uploadedFiles}
                            removeUploadedMediaSet={this.props.removeUploadedMediaSet}
                            removeUploadedMediaSetFollow={this.props.removeUploadedMediaSetFollow}
                            uploadedMediaSetFollow={uploadedFilesFollowUp}
                            embedMediaFollowUp={embedMediaFollowUp}
                            forceToggleLoginModal={this.props.forceToggleLoginModal}
                        />
                    </TabPane>
                    <TabPane tabId="qa">
                        <QandATab
                            doctorId={this.props.item_detail?.id}
                            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload,newReviewStatus,followUpStatus)=>{
                                toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus,followUpStatus);                               
                            }}
                            uploadedMediaSet={uploadedFiles}
                            getuploadUrl={this.props.getuploadUrl}
                            resetUploadedMediaSet={this.props.resetUploadedMediaSet}
                            forceToggleLoginModal={this.props.forceToggleLoginModal}
                        />
                    </TabPane>
                    <TabPane tabId="discussion">
                        <DiscussionsTab doctorDetails={this.props.item_detail}
                            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload, newReviewStatus, followUpStatus) => {
                                toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus, followUpStatus);
                            }}
                            uploadedMediaSet={uploadedFiles}
                            getuploadUrl={this.props.getuploadUrl}
                            resetUploadedMediaSet={this.props.resetUploadedMediaSet}
                            getnocategory={this.props.getnocategory}
                            forceToggleLoginModal={this.props.forceToggleLoginModal}
                        />
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}

export default DetailReviews;