import React, { Component } from "react";
import {
    Navbar,
    Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Button, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, Input, InputGroup, InputGroupAddon, DropdownItem, Label, Progress, Form
} from "reactstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import menuIcon from "../../../assets/images/icons/leftmenu-lines-top.png";
import tagIcon from "../../../assets/images/icons/browse-category.png";
import userIcon from "../../../assets/images/icons/user-circle.png";
import wikiWhite from "../../../assets/images/icons/w-white.png";
import allCatIcon from "../../../assets/images/icons/all-category-top-search.png";
import businessIcon from "../../../assets/images/icons/business-top-search.png";
import productsIcon from "../../../assets/images/icons/products-top-search.png";
import moviesIcon from "../../../assets/images/icons/movies-top-search.png";
import professionalIcon from "../../../assets/images/icons/professionals-top-search.png";
import actorIcon from "../../../assets/images/icons/actors-top-search.png";
import crpIcon from "../../../assets/images/icons/crp-hover.png";
import peopleIcon from "../../../assets/images/icons/people-top-search.png";
import { logged_user_profile, my_profile, upload_cover_media, upload_delete_profile_media, update_my_profile, upload_move_set_profile_media } from "../../../actions/user";
import { fetch_media_data, fetch_taxonomy_category, media_upload_scroll, fetch_delete_media, upload_profile_files, upload_add_captions, clear_media_dispatch, single_delete_from_upload, fetch_taxonomy_list } from "../../../actions/myProfiles";
import { callApi } from "../../../utils/apiCaller";
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import HeaderCover from './headerCover';
import PlacementAutoComplete from '../../atoms/PlacementAutoComplete'
import { fetch_category_list, fetch_current_location, get_searchHome, clear_search_home, my_notification, set_nofiticationCount, add_tempLocation } from "../../../actions/home";
import { auth_logout_request, setInitialAuth } from "../../../actions/auth";
import {
    add_connection_requests,cancel_request,follow,unfollow,send_connection_requests
} from "../../../actions/connections";
import queryString from 'query-string';
import { click_change_toursteps, click_tour_start, set_tour_step } from "../../../actions/utils";
import { debounce } from "lodash";
import { capitalizeFirstLetter } from "../../../utils/utilities";
import PhoneOtpVerify from "../../atoms/phoneOtpVerify";
const group_icons = 'https://stagingdatawikireviews.s3.amazonaws.com/images/group-icon.png';



class AppHeader extends Component {
    constructor(props) {
        super(props)

        // let width = window.innerWidth;
        this.checkForUnAuth = false;
        let isOldUrl = false;
        let current_url = window.location.href;

        if (['http://m.wikireviews.com.s3-website-us-east-1.amazonaws.com', 'http://m.wikireviews.com.s3-website-us-east-1.amazonaws.com/'].includes(current_url)) {
            isOldUrl = true;
        } else {
            isOldUrl = false;
        }

        // let isMobile = /iPhone|Android/i.test(navigator.userAgent);

        // if (isMobile) {
        //     if (isOldUrl) {
        //         window.location.href = process.env.REACT_APP_WEB_URL;
        //     } else {
        //         window.location.href = process.env.REACT_APP_MOBILE_URL;
        //     }
        // } else {
        //     if (isOldUrl) {
        //         window.location.href = process.env.REACT_APP_WEB_URL;
        //     }
        // }
        this.myScrollPhotoRef = React.createRef();
        this.myScrollVideoRef = React.createRef();

        this.refGalleryVideoScroll = React.createRef();
        this.refGalleryImageScroll = React.createRef();

        this.state = {
            handle: null,
            isToggle: false,
            isImage: false,
            viewNotifType: 'notif',
            mediaId: 0,
            mediaUrl: "",
            upload: false,
            relX: 0,
            relY: 0,
            x: -85,
            y: -45,
            left: 0,
            top: 0,
            content_type: "All",
            contentImage: allCatIcon,
            searchHomeData: [],
            search_name: "",
            search_length: 0,
            address: '',
            enableSearch: false,
            unableToSet: true,
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            searchTheater: '',
            fetchPhotos: {},
            fetchPhotosCount: 0,
            fetchVideos: {},
            fetchVideosCount: 0,
            albumType: '',
            fetchTaxonomy: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            dataWantsToDelete: [],
            confirmDeleteModal: false,
            limitedPostsModal: false,
            uploadMediaModal: false,
            uploadMediaPostModal: false,
            isModalTrue: false,
            modalText: null,
            modalType: null,
            modalSelected: null,
            fetchTaxonomyListing: [],
            taxonomycategoryId: "",
            outSideClick: false,
            dropdownOpenCategory: false,
            homeDropdownOpen: false,
            dropdownOpenMenu: false,
            dropdownOpenNotification: false,
            imageError: "",
            fetchNotiMessage: [],
            fetchNotiFeeds: [],
            fetchNotiRequest: [],
            totalNotificationCount: 0,
            msgCount: 0,
            requestCount: 0,
            feedsCount: 0,
            newTopCategories: [],
            my_profile_info: null
        };

        this.handleOnFileUploadChange = this.handleOnFileUploadChange.bind(this);
        this.gridX = props.gridX || 1;
        this.gridY = props.gridY || 1;
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);

        this.toggleCategory = this.toggleCategory.bind(this);
        this.onMouseEnterCategory = this.onMouseEnterCategory.bind(this);
        this.onMouseLeaveCategory = this.onMouseLeaveCategory.bind(this);
    }
    static propTypes = {
        // onMove: PropTypes.func,
        // onStop: PropTypes.func,
        // x: PropTypes.number.isRequired,
        // y: PropTypes.number.isRequired,
        // gridX: PropTypes.number,
        // gridY: PropTypes.number
    };

    toggleCategory(item) {
        this.setState(prevState => ({
            [item]: !prevState[item]
        }));
    }

    onMouseEnterCategory(item) {
        this.setState({ [item]: true });
    }

    onMouseLeaveCategory(item) {
        this.setState({ [item]: false });
    }

    componentWillReceiveProps(nextProps) {
        let { unableToSet, fetchVideos, newTopCategories, fetchGalleryScroll, fetchDeletedData, latestUploadedData, fetchPhotos } = this.state
        if (nextProps.get_search_home &&
            Array.isArray(nextProps.get_search_home.results) &&
            nextProps.get_search_home.results.length > 0 && unableToSet) {
            this.setState({
                searchHomeData: nextProps.get_search_home.results
            });
        } else {
            this.setState({
                searchHomeData: []
            });
        }

        if (nextProps.fetch_photos) {
            let item = nextProps.fetch_photos;
            if (item.album) { fetchPhotos = [] }
            fetchPhotos[item.page] = item;
            this.setState({ fetchPhotos, fetchPhotosCount: item?.count, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_videos) {
            let item = nextProps.fetch_videos;
            if (item.album) { fetchVideos = [] }
            fetchVideos[item.page] = item;
            this.setState({ fetchVideos, fetchVideosCount: item?.count, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_taxonomy) {
            let taxCat = nextProps.fetch_taxonomy;
            if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
                taxCat = taxCat.filter(dt => dt.label !== null);
            }
            this.setState({ fetchTaxonomy: taxCat })
        }

        if (nextProps.fetch_taxonomy_listData) {
            let taxCatListing = nextProps.fetch_taxonomy_listData;
            if (taxCatListing && Array.isArray(taxCatListing) && taxCatListing.length > 0) {
                //taxCatListing = taxCat.filter(dt => dt.label !== null);
                this.setState({ fetchTaxonomyListing: taxCatListing[0].children ? taxCatListing[0].children : [] })
            }

        }
        if (nextProps.top_categories && Array.isArray(nextProps.top_categories) && nextProps.top_categories.length > 0) {
            let newArray = [];
            let newArray2 = [];
            nextProps.top_categories.map((listCatorgies) => {
                if (listCatorgies?.category == "Products") {
                    newArray.push(listCatorgies)
                } else {
                    newArray2.push(listCatorgies)
                }
            })
            this.setState({ newTopCategories: newArray.concat(newArray2) })
        }

        if (nextProps.fetch_deleted_data) {
            let item = nextProps.fetch_deleted_data;
            if (!item.type) { fetchDeletedData = {} }
            fetchDeletedData[item.page] = item;
            this.setState({ fetchDeleteCount: item.count, fetchDeletedData, activeMyPhotoIndex: 0 });
        }

        if (nextProps.upload_scroll) {
            let item = nextProps.upload_scroll;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            this.setState({ fetchGalleryScroll, fetchGalleryCount: item.count, activeMyPhotoIndex: 0 });
        }

        if (nextProps.upload_profile_data) {
            let data = nextProps.upload_profile_data;
            if (data.index !== undefined) {
                let idx = data.index;
                latestUploadedData[idx] = data;
                this.setState({ latestUploadedData });
            }
        }
        if (
            nextProps.fetch_my_notification &&
            Object.keys(nextProps.fetch_my_notification).length > 0
        ) {
            let totalNotificationCount = 0;
            if (nextProps.fetch_my_notification?.unreadmsgs) {
                totalNotificationCount += nextProps.fetch_my_notification?.unreadmsgs?.msgcount ? nextProps.fetch_my_notification?.unreadmsgs?.msgcount : 0;

                this.setState({ fetchNotiMessage: nextProps.fetch_my_notification?.unreadmsgs?.unreadmsgs, msgCount: nextProps.fetch_my_notification?.unreadmsgs?.msgcount });
            }
            if (nextProps.fetch_my_notification?.connections) {
                totalNotificationCount += nextProps.fetch_my_notification?.connections?.friendrequestcount ? nextProps.fetch_my_notification?.connections?.friendrequestcount : 0;
                this.setState({ fetchNotiRequest: nextProps.fetch_my_notification?.connections?.friendrequest, requestCount: nextProps.fetch_my_notification?.connections?.friendrequestcount });
            }
            if (nextProps.fetch_my_notification?.feeds) {
                totalNotificationCount += nextProps.fetch_my_notification?.feeds?.feedscount ? nextProps.fetch_my_notification?.feeds?.feedscount : 0;
                this.setState({ fetchNotiFeeds: nextProps.fetch_my_notification?.feeds?.feeds, feedsCount: nextProps.fetch_my_notification?.feeds?.feedscount });
            }
            this.setState({ totalNotificationCount });
        }

        if (nextProps?.my_profile_info) {
            this.setState({ my_profile_info: nextProps?.my_profile_info });
        }

    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        let nextProps = this.props;

        if (this.props.fetch_my_notification !== prevProps.fetch_my_notification) {
            let totalNotificationCount = 0;
            const fetch_my_notification = nextProps.fetch_my_notification;

            totalNotificationCount += fetch_my_notification?.unreadmsgs?.msgcount ? fetch_my_notification.unreadmsgs?.msgcount : 0;
            totalNotificationCount += fetch_my_notification?.connections?.friendrequestcount ? fetch_my_notification.connections?.friendrequestcount : 0;
            totalNotificationCount += fetch_my_notification?.feeds?.feedscount ? fetch_my_notification.feeds?.feedscount : 0;

            if (this.props.fetch_my_notification?.unreadmsgs !== prevProps.fetch_my_notification?.unreadmsgs) {
                
                this.setState({ fetchNotiMessage: this.props.fetch_my_notification?.unreadmsgs?.unreadmsgs, msgCount: this.props.fetch_my_notification?.unreadmsgs?.msgcount });
            }
            if (this.props.fetch_my_notification?.connections !== prevProps.fetch_my_notification?.connections) {
                
                this.setState({ fetchNotiRequest: this.props.fetch_my_notification?.connections?.friendrequest, requestCount: this.props.fetch_my_notification?.connections?.friendrequestcount });
            }
            if (this.props.fetch_my_notification?.feeds !== prevProps.fetch_my_notification?.feeds) {
                
                this.setState({ fetchNotiFeeds: this.props.fetch_my_notification?.feeds?.feeds, feedsCount: this.props.fetch_my_notification?.feeds?.feedscount });
            }
            
            this.setState({ totalNotificationCount });
        }
        // if (this.props.my_profile_info !== prevProps.my_profile_info) {
        //         this.props.my_profile();
        // }
    }


    componentDidMount() {
        let { isEnableCover } = this.props;
        if (window.location.href.includes('/api/activate')) {
            let tokenArray = window.location.href.split('activate');
            callApi(`/api/activate${tokenArray[1]}`, "GET").then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    toast(response.message, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                    this.props.history.push('/');
                }
            });
        } else if (window.location.href.includes('/api/confirm-email-id')) {
            this.props.history.push('/');
        }
        if (isEnableCover && isEnableCover == true) {
         
            this.props.logged_user_profile();
        } else {
            
            this.props.my_profile();
        }

        this.props.fetch_category_list({ category: 1, depth: false, level: 1, show_children: true });
        this.setLocation();
        this.props.clear_search_home();
        this.props.my_notification();
        let { location } = this.props;
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                this.setState({ search_name: query })
            }
        }
        try {
            this.intervalCheck = setInterval(async () => {
                await this.props.my_notification();
            }, 60000);
        } catch (e) {
            console.log(e);
        }

    }

    componentWillUnmount() {
        this.clearTimerSettings();
    }

    async setLocation() {
        if (localStorage.getItem("lastSetupLocation")) {
            const data = JSON.parse(localStorage.getItem("lastSetupLocation"))
            await this.props.add_tempLocation(data);
        } else {
            await this.props.fetch_current_location();
        }
    }

    //countdown clear counter
    clearTimerSettings() {
        if (this.intervalCheck) {
            clearInterval(this.intervalCheck);
        }
    }

    onChangeUploadCoverImage = (event) => {
        let file = event.target.files[0];
        let data = new FormData();
        data.append("file", file);
        this.props.upload_cover_media(data);
    }

    /*handleOnFileUploadChange = (event) => {
        let uploadFile = event.target.files;
        var i = 0;
        let data = new FormData();
        data.append("file", uploadFile[i]);
        callApi(
            `/upload/multiuploader/?album=profile&instance=myProfile_image&image=undefined&doc=undefined`,
            "POST",
            data,
            true
        ).then((response) => {
            if (response) {
                let media_id = response.id;
                let media_url = response.url;
                let data = [];
                let profileData = {};
                profileData.current_profile_pic = media_id;
                let entity = "Image"
                data.push(media_id);
                this.setState({
                    ...this.state,
                    mediaId: media_id,
                    mediaUrl: media_url,
                    upload: true
                },
                    () => this.props.update_my_profile(profileData, entity));
                this.props.upload_delete_profile_media(data);

            }
        });
    };*/

    reposition = (e) => {
        e.preventDefault();
        this.setState({ isImage: true });
    }

    getImage = () => {
        this.setState({ isImage: false });
        let data = {}
       
        data.left = this.state.left;
        data.top = this.state.top;
        data.x = this.state.x;
        data.y = this.state.y;
        this.props.upload_move_set_profile_media(data);
    }

    onStart() {
        let { handle } = this.state;
        let ref = ReactDOM.findDOMNode(handle);
        const box = ref?.getBoundingClientRect();
        if (box) {
            this.setState({
                relX: box?.left ? box?.left : 0,
                relY: box?.top ? box?.top : 0
            });
        }
    }

    onMove(e) {
        // let left = 0
        // let top = 0
     
        const left = Math.trunc((e.pageX - this.state.relX  ) / this.gridX) * this.gridX;
        const top = Math.trunc((e.pageY -this.state.relY ) / this.gridY) * this.gridY;
    
     
        if (left !== this.state.left || top !== this.state.top) {
            // if(top < -7 && top > - 88 && left < -9 && left > - 178) {
            this.setState({
                left,
                top
            }, () => {
                localStorage.setItem('left', this.state.left);
                localStorage.setItem('top', this.state.top);
            });
            this.props.onMove && this.props.onMove(this.state.left, this.state.top);
        // }
        }
    }

    onMouseDown(e) {
        if (e.button !== 0) return;
        this.onStart();
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);
        e.preventDefault();
    }

    onMouseUp(e) {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
        this.props.onStop && this.props.onStop(this.state.left, this.state.top);
        e.preventDefault();
    }

    onMouseMove(e) {
        this.onMove(e);
        e.preventDefault();
    }

    onTouchStart(e) {
        this.onStart();
        document.addEventListener('touchmove', this.onTouchMove, { passive: false });
        document.addEventListener('touchend', this.onTouchEnd, { passive: false });
        e.preventDefault();
    }

    onTouchMove(e) {
        this.onMove(e.touches[0]);
        e.preventDefault();
    }

    onTouchEnd(e) {
        document.removeEventListener('touchmove', this.onTouchMove);
        document.removeEventListener('touchend', this.onTouchEnd);
        this.props.onStop && this.props.onStop(this.state.left, this.state.top);
        e.preventDefault();
    }

    handleOnClickCategory = (type, ImageType) => {
        const { search_name, } = this.state
        if (type !== "") {
            this.setState({
                content_type: type, contentImage: ImageType,
                isSearching: true
            });
            if (search_name !== "") {
                this.props.get_searchHome(search_name, type);
            }
        }
    }

    debounceSearchResults = debounce((value, content_type) => {
        if (value.length) this.props.get_searchHome(value, content_type);
        else this.props.clear_search_home();
    }, 1000);

    // Change State of input field	
    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        // let location = {};
        const { content_type } = this.state;
        if (name === 'search_name') {
            if (value.length) {
                this.setState(
                    {
                        ...this.state,
                        [name]: value,
                        unableToSet: true,
                        search_length: value.length,
                    }, () => this.debounceSearchResults(value, content_type));
            } else {
                this.setState(
                    {
                        searchHomeData: [],
                        search_name: '',
                        search_length: value.length,
                        unableToSet: false,
                    }, () => {
                        this.debounceSearchResults(value, content_type);
                    });
            }
        }
    }

    handleEnabelSearch = () => {
        this.setState((prevState) => ({
            enableSearch: !prevState.enableSearch
        }))
    }

    //start
    setMediaSetForItems = (e, item) => {
        // e.preventDefault();
        let newmedia = []
        let { mediaSet, latestUploadedData } = this.state;
        if (e.target.checked) {
            if (item && item.id) {
                let id = item.id;
                let itemFiltered = mediaSet.find(item => item.id == id);
                if (itemFiltered) {
                    const index = mediaSet.indexOf(itemFiltered);
                    if (index > -1) {
                        mediaSet.splice(index, 1);
                    }
                } else {
                    newmedia.push(item);
                }

                latestUploadedData[0] = item;
                this.setState({ latestUploadedData });
                this.setState({ mediaSet: newmedia, mediaSetReview: newmedia, latestUploadedData });
            }
        } else {
            if (item && item.id) {
                let id = item.id;
                let itemFiltered = mediaSet.find(item => item.id == id);
                if (itemFiltered) {
                    const index = mediaSet.indexOf(itemFiltered);
                    if (index > -1) {
                        mediaSet.splice(index, 1);
                    }
                } else {
                    newmedia.push(item);
                }
                this.setState({ latestUploadedData: {} });
                this.setState({ mediaSet: newmedia, mediaSetReview: newmedia });
            }
        }
    }

    /*handleCaptionByIndex = (e, index) => {
        let { name, value } = e.target;
        let { uploadCaptions } = this.state;

        uploadCaptions[index] = value;
        this.setState({ uploadCaptions });
    }*/
    handleCaptionByIndex = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData } = this.state;
        uploadCaptions[index] = value;
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        let id = upload_data.id;
        this.setState({ uploadCaptions }, () => this.props.upload_add_captions(id, data));

    }

    checkIsAdded = (item) => {

        let { mediaSet } = this.state;

        let id = item.id;
        let itemFiltered = mediaSet.find(item => item.id == id);

        if (itemFiltered) {
            return true;
        } else {
            return false;
        }
    }

    removeFromMedia = (e, item) => {
        e.preventDefault();
        let { mediaSet } = this.state;
        let id = item.id;
        let itemFiltered = mediaSet.find(item => item.id == id);
        if (itemFiltered) {
            const index = mediaSet.indexOf(itemFiltered);
            if (index > -1) {
                mediaSet.splice(index, 1);
            }
            this.setState({ mediaSet, mediaSetReview: mediaSet });
        }
    }

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = this.state;
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0 && getDataItems[0]) {

                return getDataItems.map(item => {

                    let itemName = (item?.thumbnail)?.split('.');
                    let itemFile = (item?.file)?.split('.');
                    let extName = itemName?.[itemName?.length - 1];
                    let extFile = itemFile?.[itemFile?.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview
                    item['srcFile'] = isVideo ? srcFile : item?.file;
                    return (
                        <Col xs="auto" className="mb-3" >
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={(e) => { this.setMediaSetForItems(e, item) }} checked={this.checkIsAdded(item)} />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    checkIfVideo = (item) => {
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        let itemFile = (item.file).split('.');

        let extFile = itemFile[itemFile.length - 1];
        let srcFile = imagePreview;

        if (['jpg', 'jpeg', 'png', 'gif'].includes(extFile)) {
            srcFile = item.file;
        }
        return srcFile;
    }

    handleOnFileUploadChange = (e) => {
        let { selectedFilesPreview, files } = this.state;
        let uploadFiles = e.target.files;
        let extName = "";
        for (const key of Object.keys(uploadFiles)) {
            let itemType = uploadFiles[key].type.split("/");
            extName = itemType[0];
        }
        if (extName == "image") {
            const filesSetForUpload = Array.from(e.target.files);
            files = files.concat(filesSetForUpload);
            let self = this;
            this.setState({ files, outSideClick: true, imageError: '' }, () => {
                Promise.all(files.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                })).then(images => {
                    let concatWithPrev = selectedFilesPreview.concat(images);
                    self.setState({ selectedFilesPreview: concatWithPrev })
                }, error => {
                    console.error(error);
                });
            });

            if (files && Array.isArray(files) && files.length > 0) {
                let indexSelected = selectedFilesPreview.length - 1;
                files.forEach(async (item, index) => {
                    if (index > indexSelected) {
                        let formData = new FormData();
                        formData.append('file', item);

                        let name = item.name;
                        let split_items = name.split('.');
                        let item_ext = split_items[split_items.length - 1];
                        let isImageTrue = ['jpg', 'png', 'jpeg', 'gif', 'tiff'].includes(item_ext);
                        if (isImageTrue) {
                            await this.props.upload_profile_files('image', formData, index);
                            //await this.props.fetch_taxonomy_list('business', 'images');
                        }
                    }
                });
            }
        } else {
            this.setState({ imageError: 'Image is Invalid.' })

        }
    }

    handleChangeTaxonomy = (e) => {
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ fetchGalleryScroll: {}, fetchGalleryAlbum: value, fetchGalleryType: 'image' }, () => this.props.media_upload_scroll({ type: 'image', album: albumType, page: 1, force_update: true }))
    }

    handleChangeTaxonomyData = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData } = this.state
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = value;
        let id = upload_data.id;
        this.setState({ taxonomycategoryId: value }, () => this.props.upload_add_captions(id, data))

    }

    clearMediaDispatch = () => {
        this.setState({ latestUploadedData: {}, uploadCaptions: {}, selectedFilesPreview: [], files: [] }, () => this.props.clear_media_dispatch());
    }


    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    handleScrollGalleryVideo = () => {
        let { refGalleryVideoScroll } = this;
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    handleSubmitCaptions = (e, index) => {
        let { uploadCaptions, latestUploadedData } = this.state
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        this.setState({ outSideClick: false });
        let id = upload_data.id;
        this.props.upload_add_captions(id, data);
    }

    logoutCustom = (props) => {

        this.props.auth_logout_request({ props: this.props }).then(() => {

            this.props.history.push('/')
            // if (this?.props?.history?.replace) {
            //     this.props.history.replace("/");
            // }
        });

    }

    removeSelectedImage = (e, index) => {
        let { latestUploadedData, selectedFilesPreview, files } = this.state

        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        this.props.single_delete_from_upload(id);

        // delete items from array
        selectedFilesPreview.splice(index, 1);
        files.splice(index, 1); // deleted this because it concat from the upload function.

        // deleted items from json
        delete latestUploadedData[index];

        // deleted items from json
        if (latestUploadedData[index] == undefined) {
            let newItems = [];

            // turn json to array for indexes
            Object.values(latestUploadedData).forEach(it => { newItems.push(it) });

            // turn array to json for indexes
            if (newItems && Array.isArray(newItems) && newItems.length) {
                let newItemsJson = {};
                newItems.forEach((nitem, index) => {
                    newItemsJson[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJson).length) {
                    latestUploadedData = newItemsJson;
                }
            }
        }
        this.setState({ selectedFilesPreview, latestUploadedData, files });
    }

    handleScrollGalleryImage = () => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    AddNewEmbedLink = (e) => {
        e.preventDefault();
        let { embeddedLink } = this.state;
        // this.props.embedNewLink({
        //     data: {
        //         "video": `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
        //         "exclude_list": [],
        //         "body_preview": `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
        //         "question_rating_category": [],
        //         "messagemedia_set": []
        //     }
        // });
        this.setState({ embeddedLink: '' })
    }

    handleCloseAndFetchReview = () => {

        let { mediaSetReview } = this.state;
        this.setState({ uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: true, mediaSet: mediaSetReview }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            //this.props.fetch_media_data('video', '', 1, true, true);
            this.props.fetch_taxonomy_category()
        });
    }

    // Delete Review
    confirmDeleteModalToggle = () => {
        if (this.state.deletereviewId) {
            this.setState({ deletereviewId: 0 });
        }
        this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
    };

    handleOnDeleteConfirmation = () => {
        if (this.state.deletereviewId) {
            this.props.delete_post_comment(
                this.state.deletereviewId, this.state.businessId
            );
        }
        this.setState({
            confirmDeleteModal: !this.state.confirmDeleteModal,
            deletereviewId: 0,
        });
    };


    handleCloseAndFetch = () => {
        let { latestUploadedData, fileReviewStatus, mediaSetReview } = this.state;
        if (fileReviewStatus && Object.values(latestUploadedData) && Object.values(latestUploadedData)[0] && Object.values(latestUploadedData)[0].id) {
            Object.values(latestUploadedData).map((list) => {
                mediaSetReview.push({
                    "id": list.id,
                    "file": list.url,
                    "thumbnail": list.thumbnail,
                    "url": list.url,
                })
            })

        }
        this.setState({ uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: false, mediaSetReview, imageError: '' }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);
        });
    }


    checkImages = (item) => {
        let items = (item.tos).split(',');
        if (items.length > 1) {
            return group_icons;
        } else {
            return item.current_profile_pic;
        }
    }

    handleOnAddFile = () => {
        let { latestUploadedData } = this.state;

        let media_id = latestUploadedData && latestUploadedData[0] ? latestUploadedData[0].id : 0;
        //let media_url = latestUploadedData && latestUploadedData[0] ? latestUploadedData[0].url : "";
        let media_url = "";
        let data = [];
        let profileData = {};
        profileData.current_profile_pic = media_id;
        let entity = "Image"
        data.push(media_id);
        this.setState({
            ...this.state,
            mediaId: media_id,
            mediaUrl: media_url,
            upload: true,
            uploadMediaModal: false,
        }, () => {
            this.props.fetchDashboardData();
            this.props.update_my_profile(profileData, entity);
        });
        // this.props.upload_delete_profile_media(data);
    }
    //end

    toggleMenu = () => {
        this.setState(prevState => ({
            homeDropdownOpen: !prevState.homeDropdownOpen
        }));
    }

    onMouseEnter = () => {
        this.setState({ homeDropdownOpen: true });
    }

    onMouseLeave = () => {
        this.setState({ homeDropdownOpen: false });
    }

    toTitleCase = (str) => {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    setHandle = (self, item) => {
        if (!self.state.handle) {
            self.setState({ handle: item });
        }
    }

    handleAccept = () => {

    }

    handleReject = () => {

    }

    handleNotificationCount = (type) => {
        let { feedsCount, requestCount, msgCount, fetchNotiFeeds, fetchNotiMessage, fetchNotiRequest } = this.state;
        let data = {};
        data.type = type;
        if (feedsCount > 0 && type == "feeds") {
            data.id = fetchNotiFeeds && fetchNotiFeeds[0].id;
            this.props.set_nofiticationCount(data);
        }
        if (requestCount > 0 && type == "friends") {
            data.id = fetchNotiRequest && fetchNotiRequest[0].id;
            this.props.set_nofiticationCount(data);
        }
        if (msgCount > 0 && type == "messages") {
            data.id = fetchNotiMessage && fetchNotiMessage[0].id;
            this.props.set_nofiticationCount(data);
        }
    }

    handleRemoveFollower = () => {

    }

    setFeedCount = (feedId, feedstype) => {
        let data = {};
        data.type = feedstype;
        data.id = feedId;
        this.props.set_nofiticationCount(data);
    }

    handleAddConnection = (email, vanity_url, username) => {
      


        let search = '';
        let findFriendsType = '';
        //  let data = {};
        let url = "";
        let data = { "email": email, "vanity_url": username, 'username': username }

        //data.ids = selectedIds;
        this.props.send_connection_requests(data, url, search, findFriendsType)

    }


    ChangeRequest = (event, id, vanity_url, username,email) => {
        let search = '';
        let findFriendsType = '';
        let url = "";
        if(event =="request_again"){
            let data = { "email": email, "vanity_url": username, 'username': username }
            this.props.send_connection_requests(data, url, search, findFriendsType);
        }

        if(event =="cancel_request"){
             this.props.cancel_request(id,username, url)
        }
        
       

    }

    handleFollowUnfollw = (id,username, type) => {
        let url = "";
        if (type == 'follow') {
            let data = { "id": id, 'username': username }
            this.props.follow(data ,url );
        }
        if (type == 'unfollow') {
            let data = { "id": id, 'username': username }
            this.props.unfollow(data,url)
        }
    }

    renderLocation = (current_location) => {
        if (current_location?.city?.toUpperCase() === current_location?.state?.toUpperCase()) {
            return `${current_location?.state ? current_location?.state : 'State'}, ${current_location?.country ? (current_location?.country?.toUpperCase() === "US" ? "USA" : current_location?.country?.toUpperCase()) : 'Country'}`
        } else {
            return `${current_location?.city ? current_location?.city : 'City'}, ${current_location?.state ? current_location?.state : 'State'}, ${current_location?.country ? (current_location?.country?.toUpperCase() === "US" ? "USA" : current_location?.country?.toUpperCase()) : 'Country'}`
        }
    }

    render() {
        const { my_profile_info, isEnableCover, top_categories, current_location, notPeopleTab = true, logged_user_profiles,people_profile } = this.props;
        
        const { isImage, contentImage, searchHomeData, search_name, selectedFilesPreview,
            latestUploadedData,
            fetchTaxonomy,
            fetchNotiRequest, fetchNotiMessage, newTopCategories, fetchNotiFeeds, totalNotificationCount, feedsCount, requestCount, msgCount, my_profile_info: my_profile, viewNotifType } = this.state;
        let totalLength = selectedFilesPreview.length;
        let objectKeys = [];
        let objectLength = 0;
        let calculatedPercentage = 0;
        if (Object.keys(latestUploadedData).length > 0) {
            objectKeys = Object.keys(latestUploadedData);
            objectLength = objectKeys.length;
            calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
        };

        return (
            <React.Fragment>
                <>
                    <Navbar dark expand="xs" className="fixed-top" id="appNav">
                        <Container fluid>
                            <Row className="flex-grow-1" form>
                                <Col>
                                    <Nav navbar className="align-items-center">
                                        <UncontrolledDropdown nav inNavbar className="d-none d-md-block mr-0" onMouseOver={() => this.onMouseEnterCategory('dropdownOpenMenu')} onMouseLeave={() => this.onMouseLeaveCategory('dropdownOpenMenu')} isOpen={this.state.dropdownOpenMenu} toggle={() => this.toggleCategory('dropdownOpenMenu')}>
                                            <DropdownToggle nav>
                                                <img src={menuIcon} alt="Menu" width="24" />
                                            </DropdownToggle>
                                            <DropdownMenu className="slidable-dropdown">
                                                <DropdownItem>
                                                    <Link to="/about">{'About'}</Link>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Link to="/faq">{'FAQ'}</Link>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Link to="/manifesto">{'Manifesto'}</Link>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Link to="/guidelines">{'Guidelines'}</Link>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Link to="/policies">{'Privacy Policy'}</Link>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <UncontrolledDropdown nav inNavbar className="mr-0" onMouseOver={() => this.onMouseEnterCategory('dropdownOpenCategory')} onMouseLeave={() => this.onMouseLeaveCategory('dropdownOpenCategory')} isOpen={this.state.dropdownOpenCategory} toggle={() => this.toggleCategory('dropdownOpenCategory')}>
                                            <DropdownToggle nav>
                                                <img className="d-none d-md-inline-block" src={tagIcon} alt="Categories" />
                                                <img className="d-md-none" src={wikiWhite} alt="" width="24" data-type="img" />
                                            </DropdownToggle>
                                            <DropdownMenu className="profile-dropdown-menu">
                                                <DropdownItem header>Main Categories</DropdownItem>

                                                <ul className="list-unstyled main-category-list">
                                                    {newTopCategories && Array.isArray(newTopCategories) && newTopCategories.length ?
                                                        newTopCategories.map((item, index) => item?.category && (
                                                            <li className="dropdown-item has-submenu" key={index}>
                                                                <Link
                                                                    to={`/${(item?.category).toLowerCase()}/`}
                                                                    className="dropdown-link">
                                                                    {`${item?.category}`}
                                                                </Link>
                                                                <ul className="dropdown-submenu">
                                                                    {item.children && Array.isArray(item.children) && item.children.length ?
                                                                        item.children.map((child_item, index3) =>
                                                                            child_item?.category !== "Actors" && (
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: (`/${(item.category !== "Business" && item.category !== "Professionals") ?
                                                                                            item.category.slice(0, -1) :
                                                                                            item.category == "Business" ? 'business-category' : item.category}/${child_item?.category && (child_item?.category).replace(/\s/g, '').split('/').join('-')}`).toLowerCase(),
                                                                                        state: {
                                                                                            id: child_item.id,
                                                                                            parent_category: (item.category).toLowerCase(),
                                                                                            child_category: child_item?.category
                                                                                        }
                                                                                        
                                                                                    }}
                                                                                    // onClick={() => {
                                                                                    //     console.log({ child_item })
                                                                                    // }}
                                                                                    className="dropdown-item nav-link-decoration-none" key={index3}>
                                                                                    {`${child_item.category}`}
                                                                                </Link>
                                                                            ))
                                                                        : null}
                                                                    <Link
                                                                        to={`/${(item?.category)?.toLowerCase()}/`}
                                                                        className="dropdown-item text-right nav-link-decoration-none">
                                                                        {'View all'}
                                                                    </Link>
                                                                </ul>
                                                            </li>

                                                        ))
                                                        : null}
                                                </ul>
                                                <Link to="/professionals/actors" className="dropdown-item main-category-list-link">
                                                    Actors
                                                </Link>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <li className="nav-item flex-grow-1 mx-2">
                                            <div className="d-md-none mobile-input">
                                                <Input bsSize="sm" type="search" placeholder="e.g. Taco's, Mel's" />
                                            </div>
                                            <div className="input-rest d-none d-md-block position-relative">
                                                <div className="d-flex flex-column flex-md-row align-items-md-center mx-n1">
                                                    <div className="px-1 d-lg-block step-10" hidden={this.state.enableSearch}>
                                                        <div className="d-flex">
                                                            <UncontrolledDropdown size="sm">
                                                                <DropdownToggle color={`${contentImage === allCatIcon ? 'primary' : 'tertiary'}`} className="text-darker d-flex align-items-center step-8">
                                                                    {contentImage === allCatIcon ? "All" : <img src={contentImage} width="20" alt="icon" />}
                                                                    <span className="text-darker ml-2">
                                                                        <FontAwesomeIcon icon="caret-down" />
                                                                    </span>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="menu-white no-caret rounded-0 results-dropdown">
                                                                    <DropdownItem type="all" key={"all"} onClick={() => {
                                                                        this.handleOnClickCategory("All", allCatIcon);
                                                                    }}>
                                                                        {/* <img className="mr-3" src={allCatIcon} width="20" alt="icon" /> */}
                                                                        results in <strong>all categories</strong>
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                                    <DropdownItem type="business" key={"business"} onClick={() => {
                                                                        this.handleOnClickCategory("business", businessIcon);
                                                                    }}>
                                                                        {/* <img className="mr-3" src={businessIcon} width="20" alt="icon" /> */}
                                                                        results in <strong>Business</strong>
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                                    <DropdownItem type="products" key={"products"} onClick={() => {
                                                                        this.handleOnClickCategory("products", productsIcon);
                                                                    }}>
                                                                        {/* <img className="mr-3" src={productsIcon} width="20" alt="icon" /> */}
                                                                        results in <strong>Products</strong>
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                                    <DropdownItem type="movies" key={"movies"} onClick={() => {
                                                                        this.handleOnClickCategory("movies", moviesIcon);
                                                                    }}>
                                                                        {/* <img className="mr-3" src={moviesIcon} width="20" alt="icon" /> */}
                                                                        results in <strong>Movies</strong>
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                                    <DropdownItem type="professionals" key={"professionals"} onClick={() => {
                                                                        this.handleOnClickCategory("professionals", professionalIcon);
                                                                    }}>
                                                                        {/* <img className="mr-3" src={professionalIcon} width="20" alt="icon" /> */}
                                                                        results in <strong>Professionals</strong>
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                                    <DropdownItem type="actors" key={"actors"} onClick={() => {
                                                                        this.handleOnClickCategory("actors", actorIcon);
                                                                    }}>
                                                                        {/* <img className="mr-3" src={actorIcon} width="20" alt="icon" /> */}
                                                                        results in <strong>Actors</strong>
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                                    <DropdownItem type="crp" key={"projects"} onClick={() => {
                                                                        this.handleOnClickCategory("projects", crpIcon);
                                                                    }}>
                                                                        {/* <img className="mr-3" src={crpIcon} width="20" alt="icon" /> */}
                                                                        results in <strong>CRP's</strong>
                                                                    </DropdownItem>
                                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                                    <DropdownItem type="people" key={"people"} onClick={() => {
                                                                        this.handleOnClickCategory("people", peopleIcon);
                                                                    }}>
                                                                        {/* <img className="mr-3" src={peopleIcon} width="20" alt="icon" /> */}
                                                                        search only <strong>People</strong>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                            <InputGroup className="step-9">
                                                            <Form className="d-flex" onSubmit={(e)=>{
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                this.setState({
                                                                    isSearching: false
                                                                });
                                                                this.props.history.push({
                                                                        pathname: `/search`,
                                                                        search: `?q=${this.state.search_name}`,
                                                                        state: { contenttype: this.state.content_type.toLowerCase() }
                                                                })
                                                            }}>
                                                                    <Input bsSize="sm" type="text"
                                                                        placeholder={this.state.isSearching ? '' : 'FIND'}
                                                                        name="search_name"
                                                                        onChange={this.handleChange}
                                                                        value={search_name}
                                                                        onFocus={() => this.setState({ isSearching: true })}
                                                                        autoComplete="off"
                                                                        // onBlur={() => this.setState({ isSearching: false })}
                                                                    />
                                                                <InputGroupAddon addonType="append">
                                                                    {search_name != "" ?
                                                                        (
                                                                            // <Button color="grey" size="sm">
                                                                            <Link className="btn btn-grey btn-sm"
                                                                                to={{
                                                                                    pathname: `/search`,
                                                                                    search: `?q=${this.state.search_name}`,
                                                                                    state: { contenttype: this.state.content_type.toLowerCase() }
                                                                                }}><img src={require("../../../assets/images/icons/search-btn.png")} alt="" onClick={() => this.setState({ isSearching: false })} /> </Link>
                                                                            // </Button>
                                                                        )
                                                                        :
                                                                        (<Button type="submit" color="grey" size="sm">
                                                                            <img src={require("../../../assets/images/icons/search-btn.png")} alt="Search" />
                                                                        </Button>)
                                                                    }
                                                                </InputGroupAddon>
                                                                </Form>
                                                            </InputGroup>
                                                        </div>
                                                    </div>
                                                    <div className="px-1 flex-grow-1 d-lg-block" hidden={this.state.isSearching}>
                                                        {/* Show city selected, hide when editing */}

                                                        <div className={`main-search-holder ${this.state.enableSearch ? 'mw-100' : ''}`}>
                                                            {this.state.enableSearch === false ?
                                                                <div
                                                                    role="button"
                                                                    className="d-none d-md-block text-nowrap"
                                                                    // style={{ minWidth: '150px' }}
                                                                    onClick={this.handleEnabelSearch} >
                                                                    <div className="location-holder" title={current_location?.address_text}>
                                                                        <span className="step-10 step-7">
                                                                            {
                                                                                current_location?.city?.toUpperCase() === current_location?.state?.toUpperCase() ?
                                                                                    <>
                                                                                        <span>{current_location?.state ? `${current_location?.state[0].toUpperCase()}${current_location?.state.slice(1)}, ` : ''}</span>
                                                                                        <span>{current_location?.country ? (current_location?.country.toUpperCase() === "US" ? "USA" : current_location?.country.toUpperCase()) : ''}</span>
                                                                                    </> :
                                                                                    <>
                                                                                        <span>
                                                                                            {current_location?.city ?
                                                                                                `${this.toTitleCase(current_location?.city)}, `
                                                                                                : ''} </span>
                                                                                        <span>{current_location?.state ? `${current_location?.state[0].toUpperCase()}${current_location?.state.slice(1)}, ` : ''}</span>
                                                                                        <span>{current_location?.country ? (current_location?.country.toUpperCase() === "US" ? "USA" : current_location?.country.toUpperCase()) : ''}</span>
                                                                                    </>
                                                                            }
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                                : <PlacementAutoComplete
                                                                    dashboardPageHeader={true}
                                                                    hideMainSearch={() => {
                                                                        this.setState({
                                                                            isSearching: false
                                                                        })
                                                                    }}
                                                                    handleEnabelSearch={this.handleEnabelSearch}
                                                                    current_location={this.renderLocation(current_location)}
                                                                />
                                                                }
                                                        </div>
                                                        {/* Show input field, show when editing */}
                                                        {/* <div >
                                                        <Input bsSize="sm" type="text" name="location" placeholder={`${current_location?.city} ${current_location?.country}`} />
                                                    </div>
                                                       */}
                                                    </div>
                                                </div>

                                                {/* Show/hide while searching */}
                                                <div>
                                                    {/* Instant Search Results */}
                                                    {this.state.isSearching ?
                                                        <ul className="text-left list-unstyled text-dark instant-results bg-white mw-100 shadow" style={{ minWidth: '400px', maxHeight: '400px', overflowY: 'auto' }}>
                                                            {/* Suggested results */}
                                                            {Array.isArray(searchHomeData) && searchHomeData && searchHomeData.length > 0 ? (searchHomeData.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {item._type == "taxonomy" && item._source.index == 0 && (
                                                                            <li>
                                                                                <div className="p-3 fs-14">
                                                                                    <div className="text-tertiary">
                                                                                        {'Are you looking for:'}
                                                                                    </div>
                                                                                    <ul
                                                                                        className="list-unstyled text-primary font-weight-bold"
                                                                                        style={{ maxHeight: '105px', overflowY: 'auto' }}>
                                                                                        {Array.isArray(item._source.main_category) &&
                                                                                            item._source.main_category && item._source.main_category[0].length > 0 ?
                                                                                            (item._source.main_category[0][0].split(",").map((item2, index2) => {
                                                                                                return (
                                                                                                    <li key={index2} onClick={() => {
                                                                                                        this.setState({
                                                                                                            isSearching: false,
                                                                                                            search_name: item2.replace(/\#.+/g, "$'")
                                                                                                        })
                                                                                                    }}>
                                                                                                        {/*<a className="text-reset ff-base" href={"/search?q=" + item2.replace(/\#.+/g, "$'")}>
                                                                                                            {item2.replace(/\#.+/g, "$'")}
																										</a>*/}
                                                                                                        <Link className="text-reset ff-base"
                                                                                                            to={{
                                                                                                                pathname: `/search`,
                                                                                                                search: `?q=${item2.replace(/\#.+/g, "$'")}`,
                                                                                                                state: { contenttype: this.state.content_type.toLowerCase() }
                                                                                                            }}>{item2.replace(/\#.+/g, "$'")} </Link>
                                                                                                    </li>
                                                                                                )
                                                                                            })) : (null)
                                                                                        }
                                                                                    </ul>
                                                                                </div>

                                                                            </li>
                                                                        )}
                                                                        {/* featured results */}
                                                                        {item._type == "listing" && (
                                                                            <Link
                                                                                to={{
                                                                                    pathname: item._source?.professional_type ?
                                                                                        `/${(item._source.professional_type).toLowerCase()}/${item._source?.id}`
                                                                                        : `/${(item._source.content_type).toLowerCase()}/${item._source.slug}`,
                                                                                    state: { id: item._source.id }
                                                                                }}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        isSearching: false,
                                                                                    })
                                                                                }}
                                                                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none">
                                                                                <li className="result-item" role="button" >

                                                                                    <div className="result-link fs-14">
                                                                                        <div className="d-flex">
                                                                                            <div className="mr-3">
                                                                                                <img
                                                                                                    src={

                                                                                                        item._source && item._source.listing_profileimage &&
                                                                                                            item._source.listing_profileimage[0] &&
                                                                                                            item._source.listing_profileimage[0].location ?
                                                                                                            item._source.listing_profileimage[0].location :
                                                                                                            "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"} width={50} alt=""
                                                                                                    onError={(error) =>
                                                                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div>

                                                                                                <span className="font-weight-bold">
                                                                                                    {item._source ? item._source.name : ""}
                                                                                                </span>
                                                                                                <div>
                                                                                                    <img src={(item._source?.total_rating && item._source?.total_rating?.avg_rating) ? `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item._source?.total_rating?.avg_rating[0]}` : `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png`} alt="" title={(item._source?.total_rating && item._source?.total_rating?.rating_count) ? item._source?.total_rating?.rating_count : 0} />
                                                                                                </div>
                                                                                                <div className="font-weight-normal lh-1-2">
                                                                                                    {item._source && item._source.category != null ? item._source.category.split(',').join(', ') : ""}
                                                                                                    {/* <span>{item._source && item._source.category != null ? item._source.category : ""}</span> */}
                                                                                                </div>
                                                                                                <div className="text-tertiary font-weight-normal mt-1 ff-alt fs-12">
                                                                                                    {item?._source?.address?.length ?
                                                                                                        `${item?._source?.address[0] && item?._source?.address[0].address1 ? item?._source?.address[0].address1 + ',' : ''} ${item?._source?.address[0] && item?._source?.address[0].address2 ? item?._source?.address[0].address2 + ',' : ''}`
                                                                                                        : null}
                                                                                                    <br />
                                                                                                    {item?._source?.address?.length ?
                                                                                                        `${item?._source?.address[0] && item?._source?.address[0].city ? item?._source?.address[0].city + ',' : ''} ${item?._source?.address[0] && item?._source?.address[0].state ? item?._source?.address[0].state + ',' : ''} ${item?._source?.address[0] && item?._source?.address[0].zipcode ? item?._source?.address[0].zipcode : ''}`
                                                                                                        : null}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </li>
                                                                            </Link>
                                                                        )
                                                                        }

                                                                        {/* featured results */}
                                                                        {item._type == "user" && (
                                                                            <Link
                                                                                to={{
                                                                                    pathname: `/people/${item._source.user?.username}`,
                                                                                    state: { id: item._source._id }
                                                                                }}
                                                                                onClick={() => {
                                                                               
                                                                                }}
                                                                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none">
                                                                                <li className="result-item" role="button" >

                                                                                    <div className="result-link fs-14">
                                                                                        <div className="d-flex">
                                                                                            <div className="mr-3">
                                                                                                <img src={item._source && item._source.current_profile_pic ? item._source.current_profile_pic : item._source.current_profile_pic && item._source.current_profile_pic.length > 0 ? item._source.current_profile_pic.thumbnail : require('../../../assets/images/icons/placeholder-img.png')
                                                                                                } width={50}
                                                                                                    onError={(error) =>
                                                                                                        (error.target.src = `require('../../../assets/images/icons/placeholder-img.png')`)
                                                                                                    }
                                                                                                    alt="" />

                                                                                            </div>
                                                                                            <div>

                                                                                                <span className="font-weight-bold">
                                                                                                    {item._source ? item._source.name : ""}
                                                                                                </span>

                                                                                                <div className="font-weight-normal fs-12 ff-alt text-tertiary">
                                                                                                    {/* Use Conditional Adddress starts */}
                                                                                                    {/* <small>
                                                                                                        26 Burlingame 
                                                                                                    </small>
                                                                                                    <small>
                                                                                                        , 
                                                                                                    </small>
                                                                                                    <small>
                                                                                                        Irvine
                                                                                                    </small>
                                                                                                    <small>
                                                                                                        , 
                                                                                                    </small>
                                                                                                    <small>
                                                                                                        California
                                                                                                    </small>
                                                                                                    <small>
                                                                                                        , 
                                                                                                    </small>
                                                                                                    <small>
                                                                                                        92602
                                                                                                    </small> */}
                                                                                                    {/* Use Conditional Adddress ends */}

                                                                                                    <br />
                                                                                                    <span>
                                                                                                        {item._source && item._source?.statistics ? item._source.statistics.reviews : "0"} reviews
                                                                                                    </span>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </li>
                                                                            </Link>
                                                                        )
                                                                        }
                                                                    </div>
                                                                )
                                                            }))
                                                                :
                                                                // <ul className="text-left list-unstyled text-dark instant-results bg-white mw-100 pl-3 shadow"
                                                                //     style={{ minWidth: '400px', minHeight: '70px', maxHeight: '400px', overflowY: 'auto' }}>
                                                                this.state.search_length && this.state.search_length > 0 ?
                                                                    <div className="pl-3" style={{ minHeight: '70px' }}>No result found</div>
                                                                    :
                                                                    ""

                                                                // </ul>
                                                            }
                                                        </ul>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    </Nav>
                                </Col>
                                <Col xs="auto" className="d-none d-md-block">
                                    <Link
                                        to="/"
                                        className="navbar-brand m-0 text-center">
                                        <span className="text-uppercase" data-type="text">
                                            WikiReviews
                                        </span>
                                        <img src={wikiWhite} alt="WikiReviews" data-type="img" />
                                    </Link>
                                </Col>
                                <Col className="d-flex col-auto col-md">
                                    <Nav className="ml-auto pr-3 align-items-center" navbar>
                                        <UncontrolledDropdown nav inNavbar
                                            isOpen={this.state.dropdownTourMenu}
                                            toggle={() => this.toggleCategory('dropdownTourMenu')}
                                            onMouseLeave={() => this.onMouseLeaveCategory('dropdownTourMenu')}
                                        >
                                            <DropdownToggle nav className="text-nowrap pl-0"
                                                onMouseOver={() => this.onMouseEnterCategory('dropdownTourMenu')} >
                                                <div className="d-inline-flex align-items-center" style={{ paddingLeft: 36 }}><img className="mr-2 position-absolute" src={require('../../../assets/images/icons/compass-small.png')} alt="" style={{ left: 0 }} /> Site Tour</div>
                                            </DropdownToggle>
                                            <DropdownMenu className="profile-dropdown-menu" right style={{ width: '200px', minWidth: 'unset' }}>
                                                <DropdownItem>

                                                    <span onClick={() => this.props.set_sitetour_step({ begin: true, No: 1, tour_start_from: this.props?.history?.location })}>
                                                        {"Begin Tour"}
                                                    </span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <span onClick={() => this.props.set_sitetour_step({ begin: false, No: 1, tour_start_from: this.props?.history?.location })}>
                                                        {"Step 1: Homepage "}
                                                    </span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <span onClick={() => this.props.set_sitetour_step({ begin: false, No: 2, tour_start_from: this.props?.history?.location })}>
                                                        {"Step 2:  Create A Review "}
                                                    </span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <span onClick={() => this.props.set_sitetour_step({ begin: false, No: 3, tour_start_from: this.props?.history?.location })}>
                                                        {"Step 3: Listing Page Tutorial  "}
                                                    </span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <span onClick={() => this.props.set_sitetour_step({ begin: false, No: 4, tour_start_from: this.props?.history?.location })}>
                                                        {"Step 4: Profile Page Tutorial  "}
                                                    </span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <span onClick={() => this.props.set_sitetour_step({ begin: false, No: 5, tour_start_from: this.props?.history?.location })}>
                                                        {"Step 5: Movie Category Page Tutorial "}
                                                    </span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <span onClick={() => this.props.set_sitetour_step({ begin: false, No: 6, tour_start_from: this.props?.history?.location })}>
                                                        {"Step 6: Community Review Projects Tutorial  "}
                                                    </span>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        {this.props?.goBackLinkExists && this.props?.goBackLinkDetails?.itemId &&
                                            <NavItem>
                                                <Link to={
                                                    {
                                                        pathname: `/${this.props.goBackLinkDetails.categoryType}/${this.props.goBackLinkDetails.itemName}`,
                                                        state: {
                                                            id: this.props.goBackLinkDetails.itemId
                                                        }
                                                    }
                                                }  title={`${this.props.goBackLinkDetails.itemName}`} className="goback-link"> Go back to <strong>{`${this.props.goBackLinkDetails.itemName}`}</strong></Link>
                                            </NavItem>
                                        }
                                        {/* <NavItem className="d-none d-md-block mr-0 to tourStep-5_movie" onMouseOver={this.onMouseEnter}
                                            onMouseLeave={this.onMouseLeave}
                                            // isOpen={this.state.homeDropdownOpen}
                                            // toggle={this.toggleMenu}
                                            >
                                            <Link to="/home" className="nav-link">
                                                Home
                                            </Link>
                                        </NavItem> */}
                                        <UncontrolledDropdown nav inNavbar
                                            onMouseOver={this.onMouseEnter}
                                            onMouseLeave={this.onMouseLeave}
                                            isOpen={this.state.homeDropdownOpen}
                                            toggle={this.toggleMenu}
                                            className="tourStep-5_movie"
                                        >
                                            <DropdownToggle caret nav className="text-nowrap pl-0">
                                                <Link to="/home" className="nav-item-link-white">
                                                    Home
                                                </Link>
                                                <div className="d-md-none"><FontAwesomeIcon icon="user-circle" size="lg" /> </div>
                                            </DropdownToggle>
                                            <DropdownMenu className="profile-dropdown-menu menu-item-hover-underline" right style={{ width: '120px', minWidth: 'unset' }}>
                                                <Link className="dropdown-item" to="/myprofile">My Profile</Link>
                                                <Link className="dropdown-item  d-md-none" to="#">Feed</Link>
                                                <Link className="dropdown-item  d-md-none" to="#">Recent Activity</Link>
                                                <Link className="dropdown-item  d-md-none" to="#">Account Settings</Link>
                                                <Link className="dropdown-item  d-none d-md-block" to="/projectslist">Community Review Projects</Link>
                    
                                                {my_profile && my_profile?.user?.is_superuser ? <Link className="dropdown-item  d-none d-md-block" to="/admin-panel">Wiki Admin</Link> : ''}
                                                <DropdownItem

                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.clearTimerSettings();
                                                        this.props.auth_logout_request({ props: this.props });
                                                        // this.logoutCustom({props:this.props})
                                                        // window.location.reload();
                                                    }}>
                                                    <span>
                                                        {"Logout"}
                                                    </span>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <NavItem className="d-none d-md-block">
                                            <Link className="nav-link step-6_Listing" to="/myprofile">
                                                <span className="mr-3">{my_profile_info && my_profile_info.user && my_profile_info.user.first_name ? capitalizeFirstLetter(my_profile_info.user.first_name) : ''}</span>
                                                <img className="rounded-circle object-fit-cover" width="24" height="24" src={(my_profile_info && my_profile_info.current_profile_file) ? my_profile_info.current_profile_file : `${userIcon}`}
                                                    onError={(error) => { error.target.src = `${userIcon}` }}
                                                    alt="User" />
                                            </Link>
                                        </NavItem>
                                        <UncontrolledDropdown nav inNavbar
                                            onMouseOver={() => this.onMouseEnterCategory('dropdownOpenNotification')}
                                            onMouseLeave={() => this.onMouseLeaveCategory('dropdownOpenNotification')}
                                            isOpen={this.state.dropdownOpenNotification}
                                            toggle={() => this.toggleCategory('dropdownOpenNotification')}
                                        >
                                            <DropdownToggle nav>
                                                {this.state?.totalNotificationCount > 0 ?
                                                    <div className="">
                                                        <div className="icon-bell" 
                                                            onMouseOver={() => {
                                                                const type = viewNotifType == "notif" ? 'feeds' : viewNotifType == "msg" ? 'messages' : 'friends';
                                                                this.handleNotificationCount(type);
                                                            }}>

                                                    </div>
                                                        {/* Notifications count */}
                                                        <div className="notification-count">{this.state?.totalNotificationCount}</div>
                                                    </div>
                                                    :
                                                    <div className="">
                                                        <div className="icon-bell"></div>
                                                        {/* Notifications count */}
                                                        {""}
                                                    </div>
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-list" right>
                                                <Nav className="nav-fill mx-2" tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className="position-relative"
                                                            active={this.state.viewNotifType === "notif"}
                                                            onClick={() => {
                                                                this.setState({ viewNotifType: "notif" })
                                                                this.handleNotificationCount("feeds")
                                                            }}
                                                        >
                                                            <div className="text-uppercase fs-12">
                                                                Notifications {feedsCount > 0 ?
                                                                    <div className="notification-count _sm">{feedsCount}</div>
                                                                    : ""}
                                                            </div>
                                                            {/* Remove when 0 */}
                                                            
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className="position-relative"
                                                            active={this.state.viewNotifType === "msg"}
                                                            onClick={() => {
                                                                this.setState({ viewNotifType: "msg" })
                                                                this.handleNotificationCount("messages")
                                                            }}
                                                        >
                                                            <div className="text-uppercase fs-12">
                                                                Messages {msgCount > 0 ?
                                                                <div className="notification-count _sm">{msgCount}</div>
                                                                : ""}
                                                            </div>
                                                            {/* Remove when 0 */}
                                                            
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className="position-relative"
                                                            active={this.state.viewNotifType === "friends"}
                                                            onClick={() => {
                                                                this.setState({ viewNotifType: "friends" })
                                                                this.handleNotificationCount("friends");
                                                            }}
                                                        >
                                                            <div className="text-uppercase fs-12">
                                                                Friends {requestCount > 0 ?
                                                                <div className="notification-count _sm">{requestCount}</div>
                                                                : ""}
                                                            </div>
                                                            {/* Remove when 0 */}
                                                            
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent activeTab={this.state.viewNotifType}>
                                                    <TabPane tabId="notif">
                                                        <ul className="list-unstyled">
                                                            {fetchNotiFeeds && Array.isArray(fetchNotiFeeds) && fetchNotiFeeds.length > 0 ?
                                                                fetchNotiFeeds.slice(0, 5).map((itemFeed, indexFeed) => (
                                                                    <li
                                                                        className="d-flex dropdown-item notification-item" data-type="notif" key={indexFeed}
                                                                        role="button"
                                                                    >
                                                                        <div className="mr-2 mt-1">
                                                                            <div className="img-circle">
                                                                                <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">
                                                                                    <img
                                                                                        src={itemFeed?.actor?.current_profile_pic ? itemFeed?.actor?.current_profile_pic : require('../../../assets/images/icons/user-circle.png')}
                                                                                        className="img-fluid"
                                                                                        onError={(error) => { error.target.src = require('../../../assets/images/icons/user-circle.png') }}
                                                                                        alt={''}
                                                                                    />
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            (itemFeed?.description == "following" || itemFeed?.description == "message") && (
                                                                                <span className="font-weight-medium">

                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">
                                                                                        {itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}
                                                                                    </Link>

                                                                                    {itemFeed?.verb}
                                                                                </span>
                                                                            )
                                                                        }
                                                                        {(itemFeed?.description == "commented_on_post") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    {!itemFeed.post_created_by ?
                                                                                        <Link to={{ pathname: "/myprofile", state: { mainViewType: "posts", pid: itemFeed?.object?.parentid, cmntid: itemFeed?.object?.objectid } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to={{ pathname: `/people/${itemFeed.post_created_by}` }}>commented on your post</Link>
                                                                                    }
                                                                                    {/* Live Refrence Link <a ng-if="!obj.post_created_by" href="/myprofile?notif_tab=post&pid={[{obj.object.parentid}]}&cmntid={[{obj.object.objectid}]}">{[{obj.verb}]}</a>
																								<a ng-if="obj.post_created_by" href="/people/{[{obj.post_created_by}]}?notif_tab=post&pid={[{obj.object.parentid}]}&cmntid={[{obj.object.objectid}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                        {(itemFeed?.description == "owner_call_action_response") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    {!itemFeed.post_created_by ?
                                                                                        <Link to={{ pathname: "/myprofile", state: { mainViewType: "messages" } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }

                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                        {(itemFeed?.description == "nailed_post" || itemFeed?.description == "priceless_post" || itemFeed?.description == "lol_post" || itemFeed?.description == "bummer_post" || itemFeed?.description == "woohoo_post" || itemFeed?.description == "insightful_post") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    <Link to={{ pathname: "/myprofile", state: { mainViewType: "posts", pid: itemFeed?.object?.parentid, cmntid: itemFeed?.object?.objectid } }}>{itemFeed?.verb}</Link>
                                                                                    {/* Live Refrence Link <a href="/myprofile?notif_tab=post&pid={[{obj.object.parentid}]}&cmntid={[{obj.object.objectid}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {(itemFeed?.description == "commented_on_review") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    <Link to={{ pathname: "/myprofile", state: { mainViewType: "posts", pid: itemFeed?.object?.parentid, cmntid: itemFeed?.object?.objectid } }}>{itemFeed?.verb}</Link>
                                                                                    {/*Live Refrence Link <a href="/myprofile?notif_tab=post&pid={[{obj.object.parentid}]}&cmntid={[{obj.object.objectid}]}">{[{obj.verb}]}</a> */}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                        {(itemFeed?.description == "nailed" || itemFeed?.description == "priceless" || itemFeed?.description == "lol" || itemFeed?.description == "bummer" || itemFeed?.description == "woohoo" || itemFeed?.description == "insightful" || itemFeed?.description == "helpful" || itemFeed?.description == "watch_new_review" || itemFeed?.description == "pin_new_review") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a  href="/{[{obj.target.category}]}/{[{obj.target.userentry_id}]}/{[{obj.target.slug}]}?rid={[{obj.target.review_id}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {(itemFeed?.description == "watch_answered_question" || itemFeed?.description == "watch_asked_question" || itemFeed?.description == "pin_asked_question" || itemFeed?.description == "owner_response_on_question" || itemFeed?.description == "community_response_on_question") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    {itemFeed?.description == "watch_answered_question" || itemFeed?.description == "watch_asked_question" || itemFeed?.description == "pin_asked_question" ?
                                                                                        <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                        :
                                                                                        ''
                                                                                        // <Link to="#!">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    }
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id, notif_tab: "qa" } }}>{" "} {itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a href="/{[{obj.target.category}]}/{[{obj.target.userentry_id}]}/{[{obj.target.slug}]}?notif_tab=questions&cid={[{obj.object.objectid}]}&qid={[{obj.object.parentid}]}">{[{obj.verb}]}</a> */}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {(itemFeed?.description == "tagged" || itemFeed?.description == "post_tagged" || itemFeed?.description == "feed_tagged") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}`, state: { notif_tab: "posts", pid: itemFeed?.object?.objectid } }}>{itemFeed?.verb}</Link>
                                                                                    {/*Live Refrence Link <a href="/people/{[{ obj.actor.username}]}?notif_tab=post&pid={[{obj.object.objectid}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                        {(itemFeed?.description == "upload_crp") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a href="/response/{[{obj.object.parentid}]}"> {[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {(itemFeed?.description == "crp_new_review" || itemFeed?.description == "friend_review_crp") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    {itemFeed?.description == "friend_review_crp" ?
                                                                                        <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    }
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a href="/response/{[{obj.object.parentid}]}/?notif_tab=crp&prid={[{obj.object.parentid}]}&rid={[{obj.object.objectid}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {(itemFeed?.description == "biz_msg") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a href="/{[{obj.target.category}]}/{[{obj.target.userentry_id}]}/{[{obj.target.slug}]}?{[{obj.notif_tab}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {(itemFeed?.description == "pin_add_special_discount" || itemFeed?.description == "watch_owner_edit_additional_info" || itemFeed?.description == "watch_edit_additional_info" || itemFeed?.description == "watch_add_special_discount" || itemFeed?.description == "biz_add_employee" || itemFeed?.description == "watch_owner_add_special_discount" || itemFeed?.description == "watch_owner_add_coupons" || itemFeed?.description == "watch_add_coupons" || itemFeed?.description == "biz_add_coupon" || itemFeed?.description == "watch_edit_coolfacts" || itemFeed?.description == "watch_added_advisory" || itemFeed?.description == "watch_added_tips" || itemFeed?.description == "watch_added_warning") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id, notif_tab: itemFeed?.notif_tab } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a href="/{[{obj.target.category}]}/{[{obj.target.userentry_id}]}/{[{obj.target.slug}]}?{[{obj.notif_tab}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {(itemFeed?.description == "watch_add_solution" || itemFeed?.description == "watch_add_problem" || itemFeed?.description == "watch_added_discussion" || itemFeed?.description == "watch_respond_discussion" || itemFeed?.description == "pin_added_discussion") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id, notif_tab: itemFeed?.notif_tab, cid: itemFeed?.object?.objectid, qid: itemFeed?.object?.parentid } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a href="/{[{obj.target.category}]}/{[{obj.target.userentry_id}]}/{[{obj.target.slug}]}?notif_tab={[{obj.notif_tab}]}&cid={[{obj.object.objectid}]}&qid={[{obj.object.parentid}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {(itemFeed?.description == "watch_edit_website" || itemFeed?.description == "watch_owner_edit_website" || itemFeed?.description == "watch_edit_additional_info" || itemFeed?.description == "watch_owner_edit_phone" || itemFeed?.description == "watch_edit_phone" || itemFeed?.description == "watch_edit_model_number" || itemFeed?.description == "watch_owner_upload_media" || itemFeed?.description == "watch_upload_media" || itemFeed?.description == "watch_edit_email" || itemFeed?.description == "watch_owner_edit_email" || itemFeed?.description == "watch_owner_edit_name" || itemFeed?.description == "watch_edit_name" || itemFeed?.description == "watch_edit_paymentoptions" || itemFeed?.description == "watch_owner_edit_paymentoptions" || itemFeed?.description == "watch_owner_edit_address" || itemFeed?.description == "watch_edit_address" || itemFeed?.description == "watch_owner_change_hop" || itemFeed?.description == "watch_change_hop" || itemFeed?.description == "watch_upload_media" || itemFeed?.description == "watch_edit_model_number") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    {itemFeed?.description != "watch_upload_media" || itemFeed?.description != "pin_change_hop" || itemFeed?.description != "watch_owner_edit_email" || itemFeed?.description != "watch_owner_edit_phone" || itemFeed?.description != "watch_owner_edit_paymentoptions" || itemFeed?.description != "watch_owner_edit_address" ?
                                                                                        <Link to="#!">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                        :
                                                                                        <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
                                                                                    }
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a href="/{[{obj.target.category}]}/{[{obj.target.userentry_id}]}/{[{obj.target.slug}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {(itemFeed?.description == "pin_change_hop" || itemFeed?.description == "pin_add_coupons") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a href="/{[{obj.target.category}]}/{[{obj.target.userentry_id}]}/{[{obj.target.slug}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                        {(itemFeed?.description == "flag") && (
                                                                            <div>
                                                                                <span className="font-weight-medium">
                                                                                    {Object.keys(itemFeed.target).length > 0 && itemFeed.target.category ?
                                                                                        <Link to={{ pathname: `/${itemFeed.target.category}/${itemFeed.target.slug && itemFeed.target.slug != "" ? itemFeed.target.slug : null}`, state: { id: itemFeed?.target?.userentry_id } }}>{itemFeed?.verb}</Link>
                                                                                        :
                                                                                        <Link to="#!">{itemFeed?.verb}</Link>
                                                                                    }
                                                                                    {/*Live Refrence Link <a href="/{[{obj.target.category}]}/{[{obj.target.userentry_id}]}/{[{obj.target.slug}]}">{[{obj.verb}]}</a>*/}
                                                                                </span>
                                                                            </div>
                                                                        )}

                                                                        {/* {(itemFeed?.description == "following") && (
																			<div>
																				<span className="font-weight-medium">
																				    <Link to={{ pathname: `/people/${itemFeed.actor.username}` }} target="_blank" className="mr-2">{itemFeed?.actor?.first_name} {itemFeed?.actor?.last_name}</Link>
																					{itemFeed?.verb}
																					<div className="text-right">
																					<Button color="primary" className="btn" size="xs" onClick={() => { this.handleRemoveFollower(itemFeed?.actor?.id)}}>Unfollow</Button>
																					</div>
																				</span>
																			</div>
																			)} */}
                                                                    </li>
                                                                ))
                                                                : <div className="px-3 mt-2">
                                                                    <span>See Notifications</span>
                                                                </div>}

                                                        </ul>
                                                        {fetchNotiFeeds && Array.isArray(fetchNotiFeeds) && fetchNotiFeeds.length > 0 ?
                                                            <div className="px-3 mt-2">
                                                                <Link to="/notifications" className="text-white ff-base fs-14">
                                                                    See all notifications
                                                                </Link>
                                                            </div>
                                                            :
                                                            ""
                                                        }

                                                    </TabPane>
                                                    <TabPane tabId="msg">
                                                        <ul className="list-unstyled">

                                                            {fetchNotiMessage && Array.isArray(fetchNotiMessage) && fetchNotiMessage.length > 0 ?
                                                                fetchNotiMessage.slice(0, 5).map((itemMsg, indexMsg) => (
                                                                    <li className="d-flex dropdown-item notification-item" data-type="notif" role="button" key={indexMsg}>
                                                                        <div className="mr-2 mt-1">
                                                                            <div className="img-circle">
                                                                                <img src={itemMsg?.current_profile_pic ? itemMsg?.current_profile_pic : require('../../../assets/images/icons/user-circle.png')}
                                                                                    className="img-fluid"
                                                                                    onError={(error) => {
                                                                                        error.target.src = require('../../../assets/images/icons/user-circle.png')
                                                                                    }}
                                                                                    alt={''}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-fill">
                                                                            <span className="font-weight-medium">
                                                                                {itemMsg.business_owner ?
                                                                                    <Link to={{ pathname: `/${itemMsg.content_type}/${itemMsg.slug && itemMsg.slug != "" ? itemMsg.slug : null}`, state: { id: itemMsg?.business_id } }} target="_blank" className="mr-2">{itemMsg?.first_name} {itemMsg.last_name && itemMsg.last_name != null ? itemMsg.last_name : ""}</Link>
                                                                                    :
                                                                                    <Link to={{ pathname: `/people/${itemMsg.username}` }} target="_blank" className="mr-2">{itemMsg?.first_name} {itemMsg.last_name && itemMsg.last_name != null ? itemMsg.last_name : ""}</Link>

                                                                                }
                                                                            </span>
                                                                            {/* Message below */}
                                                                            <div>
                                                                                {itemMsg?.body?.length > 20 ? itemMsg?.body.replace(/(.{20})..+/, "$1…") : itemMsg?.body}
                                                                            </div>

                                                                            {/* Time stamp */}
                                                                            <small className="text-right d-block">{itemMsg?.sent_at}</small>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                                : <div className="px-3 mt-2">
                                                                    <span>No New Messages</span>
                                                                </div>}
                                                        </ul>
                                                        {fetchNotiMessage && Array.isArray(fetchNotiMessage) && fetchNotiMessage.length > 0 ?
                                                            <div className="px-3 mt-2">
                                                                <Link
                                                                    to={{ pathname: "/myprofile", state: { mainViewType: "messages" } }} className="text-white ff-base fs-14">
                                                                    View Inbox
                                                                </Link>
                                                            </div>
                                                            : ""}
                                                    </TabPane>
                                                    <TabPane tabId="friends">
                                                        {fetchNotiRequest && Array.isArray(fetchNotiRequest) && fetchNotiRequest.length > 0 ?
                                                            fetchNotiRequest.slice(0, 5).map((itemRequest, indexReq) => (
                                                                <Link className="d-flex dropdown-item notification-item" data-type="notif" to="#" key={indexReq}>
                                                                    <div className="mr-2 mt-1">
                                                                        <div className="img-circle">
                                                                            <img src={itemRequest?.current_profile_pic ? itemRequest?.current_profile_pic : require('../../../assets/images/icons/user-circle.png')}
                                                                                className="img-fluid"
                                                                                onError={(error) => {
                                                                                    error.target.src = require('../../../assets/images/icons/user-circle.png')
                                                                                }}
                                                                                alt={itemRequest?.current_profile_pic}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-fill">
                                                                        {/* Message below */}
                                                                        <div>
                                                                            <Link to={{ pathname: `/people/${itemRequest.username}` }} target="_blank" className="mr-2">	{itemRequest?.profile__user__first_name} {itemRequest?.profile__user__last_name} {itemRequest?.verb} </Link>
                                                                        </div>

                                                                        {/* <div className="text-right" hidden={itemRequest.connection_status == "wait" ? false : true}>
                                                                                <Button color="primary" className="btn" size="xs" onClick={() => { this.handleAccept(itemRequest.profile__id,"accept")}}>Accept</Button>
                                                                                <Button color="primary" className="btn" size="xs" onClick={() => { this.handleReject(itemRequest.id,itemRequest.profile__id)}}>Reject</Button>
                                                                            </div> */}
                                                                    </div>
                                                                </Link>
                                                            ))
                                                            : <div className="px-3 mt-2">
                                                                <span>No Friend Request found</span>
                                                            </div>}
                                                    </TabPane>
                                                </TabContent>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Nav>
                                </Col>
                            </Row>

                            <Modal
                                isOpen={this.state.uploadMediaModal}
                                toggle={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal }, () => { this.clearMediaDispatch() })}>
                                <ModalHeader toggle={() => { this.handleCloseAndFetch() }}>UPLOAD MEDIA</ModalHeader>
                                <ModalBody className="p-3">
                                    <Row>
                                        <Col xs={"auto"}>
                                            <div className="bg-light h-100 p-3">
                                                <Nav tabs className="flex-column active-transparent border-0 m-0">
                                                    <NavItem className="text-left">
                                                        <NavLink
                                                            className="pl-0 pr-4 border-0"
                                                            href="#"
                                                            active={this.state.selectedUpMediaType === "upload"}
                                                            onClick={() => {
                                                                this.setState({ selectedUpMediaType: "upload" })
                                                            }}
                                                        >
                                                            {'Upload'}
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <hr className="my-3 bg-tertiary" />
                                                    </NavItem>
                                                    <NavItem className="text-left border-0">
                                                        <NavLink
                                                            className="pl-0 pr-4 border-0"
                                                            href="#"
                                                            active={this.state.selectedUpMediaType === "gallery"}
                                                            onClick={() => {
                                                                this.setState({ selectedUpMediaType: "gallery" }, () => { this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true }) })
                                                            }}
                                                        >
                                                            {'Gallery'}
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                        </Col>
                                        <Col>
                                            <TabContent activeTab={this.state.selectedUpMediaType}>
                                                <TabPane tabId="upload">
                                                    {selectedFilesPreview && selectedFilesPreview.length == 0 ?
                                                        <div className="mb-3 type-file-block bg-light">
                                                            <Input
                                                                type="file"
                                                                name="upload_media_file"
                                                                id="uploadFilesFromSystem"
                                                                accept="image/*"
                                                                onChange={this.handleOnFileUploadChange}

                                                            />
                                                            <Label for="uploadFilesFromSystem">
                                                                <div className="type-bg"></div>
                                                            </Label>
                                                            <div className="text-image-error">{this.state.imageError}</div>
                                                        </div>
                                                        : null}
                                                    <div
                                                        className="text-right"
                                                        style={{
                                                            maxWidth: "120px",
                                                            margin: "0 1rem 1rem auto",
                                                        }}>
                                                        <div className="text-center mb-1 small">
                                                            {parseInt(calculatedPercentage) === 100 ? (
                                                                <div className="text-success">
                                                                    <FontAwesomeIcon
                                                                        icon="check-circle"
                                                                        className="mr-1"
                                                                    />{" "}
                                                                    {'Uploaded'}
                                                                </div>
                                                            ) : (
                                                                selectedFilesPreview.length > 0 && (
                                                                    <div>
                                                                        {` Uploading `}
                                                                        <span className="text-success font-weight-bold ff-base">
                                                                            {calculatedPercentage ? `${parseInt(calculatedPercentage)} %` : ''}
                                                                        </span>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                        {selectedFilesPreview.length > 0 && (
                                                            <Progress
                                                                value={parseInt(calculatedPercentage)}
                                                                style={{ height: "8px" }}
                                                            />
                                                        )}
                                                    </div>

                                                    <Row>
                                                        {selectedFilesPreview && selectedFilesPreview.length > 0 ?
                                                            selectedFilesPreview.map((item, index) => (
                                                                <Col className="mb-3" xs="auto" key={index}>
                                                                    <div className="text-center bg-light p-2">
                                                                        <div className="d-inline-flex pr-3">
                                                                            <div>
                                                                                <div
                                                                                    className="selectable-media"
                                                                                    style={{ cursor: "default" }}
                                                                                >
                                                                                    <div className="gallery-media">
                                                                                        <img src={item} alt={`selected-${index}`} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mx-n3 mt-2">
                                                                                <Button color="delete-media _alt" size="sm" title="Remove Media" onClick={(e) => this.removeSelectedImage(e, index)}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                                            </div>
                                                                        </div>
                                                                        {latestUploadedData &&
                                                                            latestUploadedData[index] &&
                                                                            (latestUploadedData[index]['isUpload'] !== undefined || latestUploadedData[index]['isUpload'] !== null) ?
                                                                            latestUploadedData[index]['isUpload'] ? (
                                                                                <div className="mt-2">
                                                                                    <Input bsSize="sm" type="text" className="mw-100 caption-input" name="caption" placeholder="Caption" onBlur={(e) => this.handleCaptionByIndex(e, index)} style={{ maxWidth: '160px' }} />
                                                                                </div>
                                                                            ) : (
                                                                                <div className="mt-2 text-center">
                                                                                    <small className="text-danger">Error while uploading</small>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="mt-2 text-center">
                                                                                    <small className="text-info">Uploading...</small>
                                                                                </div>
                                                                            )}

                                                                    </div>
                                                                </Col>
                                                            ))
                                                            : null}
                                                    </Row>
                                                    <div className="d-flex mx-n2">
                                                        <div className="px-2">
                                                            <Button
                                                                color="primary"
                                                                size="sm"
                                                                className="mw"
                                                                onClick={() => { this.handleCloseAndFetch() }}>
                                                                {'Cancel'}
                                                            </Button>
                                                        </div>
                                                        <div className="px-2 ml-auto">
                                                            <Button
                                                                disabled={Object.keys(this.state.latestUploadedData).length ? false : true}
                                                                color="primary"
                                                                size="sm"
                                                                className="mw"
                                                                onClick={() => { this.handleOnAddFile() }}>
                                                                {'Add'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="gallery">
                                                    <div className="mb-2">

                                                        <FormGroup className="form-row justify-content-end">
                                                            <Col xs="auto">
                                                                <Label className="small" for="selectAlbumType">Album Type:</Label>
                                                            </Col>
                                                            <Col xs="auto">
                                                                <Input onChange={this.handleChangeTaxonomy} bsSize="sm" type="select" name="album_type_select" id="selectAlbumType" defaultValue="all">
                                                                    <option value="all">All</option>
                                                                    {fetchTaxonomy && Array.isArray(fetchTaxonomy) && fetchTaxonomy.length > 0 ?
                                                                        fetchTaxonomy.map((item, index) => (
                                                                            <option value={item.category} key={index}>{item.label}</option>
                                                                        ))
                                                                        : null}
                                                                </Input>
                                                            </Col>
                                                        </FormGroup>

                                                    </div>
                                                    <TabContent activeTab={this.state.showGalleryType}>
                                                        <TabPane tabId="images">
                                                            <div
                                                                ref={this.refGalleryImageScroll}
                                                                onScroll={this.handleScrollGalleryImage}
                                                                className="px-3 my-3"
                                                                style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                                <Row>
                                                                    {this.fetchScrollPhotoMedia()}
                                                                </Row>
                                                            </div>
                                                            <div className="d-flex mx-n2">
                                                                <div className="px-2">
                                                                    <Button
                                                                        color="primary"
                                                                        size="sm"
                                                                        className="mw"
                                                                        onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                                        {'Cancel'}
                                                                    </Button>
                                                                </div>
 
                                                                <div className="px-2 ml-auto">
                                                                    <Button
                                                                        color="primary"
                                                                        disabled={Object.keys(this.state.latestUploadedData).length ? false : true}
                                                                        size="sm"
                                                                        className="mw"
                                                                        onClick={() => this.handleOnAddFile()}>
                                                                        {'Add'}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </TabPane>
                                                    </TabContent>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </Row>
                                </ModalBody>
                            </Modal>
                        </Container>
                    </Navbar>
                    {this.state.isSearching ?
                        <div className="search-mask" onClick={() => this.setState({ isSearching: false })}>&nbsp;</div>
                        : ''
                    }
                </>
                {
                    isEnableCover ? (
                        <HeaderCover
                            self={this}
                            my_profile_info={(this.props.selfProfile || this.props.selfProfile===undefined ) ? my_profile_info : people_profile}
                            setHandle={this.setHandle}
                            onMouseDown={this.onMouseDown}
                            onTouchStart={this.onTouchStart}
                            isImage={isImage}
                            left={this.state.left}
                            top={this.state.top}
                            getImage={this.getImage}
                            reposition={this.reposition}
                            handleOnFileUploadChange={this.handleOnFileUploadChange}
                            handleCloseAndFetchReview={this.handleCloseAndFetchReview}
                            upload={this.state.upload}
                            onChangeUploadCoverImage={this.onChangeUploadCoverImage}
                            notPeopleTab={notPeopleTab}
                            handleAddConnection={this.handleAddConnection}
                            handleFollowUnfollw={this.handleFollowUnfollw}
                            ChangeRequest={this.ChangeRequest}
                            isEnableCover={isEnableCover}
                        />
                    ) : null
                }
                <PhoneOtpVerify/>


            </React.Fragment >
        );
    }
}

const mapState = (state) => ({
    isMobileTrue: state.utils.isMobileTrue,
    my_profile_info: state.user.my_profile,
    top_categories: state.home.top_categories,
    current_location: state.home.current_location,
    fetch_my_notification: state.home.fetch_my_notification,
    get_search_home: state.home.get_search_home,
    fetch_photos: state.myProfile.fetch_photos,
    fetch_videos: state.myProfile.fetch_videos,
    fetch_taxonomy: state.myProfile.fetch_taxonomy,
    upload_scroll: state.myProfile.upload_scroll,
    upload_profile_data: state.myProfile.upload_profile_data,
    fetch_deleted_data: state.myProfile.fetch_deleted_data,
    fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
    logged_user_profiles: state.user.logged_user_profile,
    people_profile:state.user.people_profile

});

const mapProps = (dispatch) => ({
    my_profile: () => dispatch(my_profile()),
    logged_user_profile: () => dispatch(logged_user_profile()),
    auth_logout_request: ({ props }) => dispatch(auth_logout_request({ props })),
    upload_cover_media: (data) => dispatch(upload_cover_media(data)),
    upload_delete_profile_media: (data) => dispatch(upload_delete_profile_media(data)),
    upload_move_set_profile_media: (data) => dispatch(upload_move_set_profile_media(data)),
    update_my_profile: (profileData, entity) => dispatch(update_my_profile(profileData, entity)),
    fetch_category_list: (data) => dispatch(fetch_category_list(data)),
    fetch_current_location: () => dispatch(fetch_current_location()),
    get_searchHome: (query, contentType) => dispatch(get_searchHome(query, contentType)),
    clear_search_home: () => dispatch(clear_search_home()),
    my_notification: () => dispatch(my_notification()),
    fetch_media_data: (type, album, page, user_profile) => dispatch(fetch_media_data(type, album, page, user_profile)),
    fetch_taxonomy_category: () => dispatch(fetch_taxonomy_category()),
    media_upload_scroll: ({ type, album, page, force_update }) => dispatch(media_upload_scroll({ type, album, page, force_update })),
    fetch_delete_media: ({ type, page }) => dispatch(fetch_delete_media({ type, page })),
    upload_profile_files: (type, data, index) => dispatch(upload_profile_files(type, data, index)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    clear_media_dispatch: () => dispatch(clear_media_dispatch()),
    single_delete_from_upload: (id) => dispatch(single_delete_from_upload(id)),
    fetch_taxonomy_list: (category, type) => dispatch(fetch_taxonomy_list(category, type)),
    set_nofiticationCount: (data) => dispatch(set_nofiticationCount(data)),
    setInitialAuth: () => dispatch(setInitialAuth()),
    add_connection_requests: (data, url, search, friendsType) => dispatch(add_connection_requests(data, url, search, friendsType)),
    send_connection_requests: (data, url, search, friendsType) => dispatch(send_connection_requests(data, url, search, friendsType)),
    cancel_request: (data, url, search, friendsType) => dispatch(cancel_request(data, url, search, friendsType)),
    follow: (data ,url) => dispatch(follow(data, url)),
    unfollow: (data ,url) => dispatch(unfollow(data, url)),
    set_sitetour_step : (step) => dispatch (set_tour_step(step)),
	add_tempLocation: (data) => dispatch(add_tempLocation(data)),
});
export default withRouter(connect(mapState, mapProps)(AppHeader));

