import React, { useState, useRef, useEffect } from 'react'
import {
    Button, Nav, UncontrolledDropdown, Card, CardBody, DropdownItem, DropdownMenu,
    DropdownToggle, Media, FormGroup, Input, Row, Col, Modal, TabPane, TabContent, Label, NavItem, NavLink, ModalBody, Progress, ModalHeader
} from 'reactstrap';
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { add_comment, add_to_list, get_connections_activity, remove_from_list, share_by_email } from '../../actions/business';
import { callApi } from '../../utils/apiCaller';
import { my_profile, get_album_type_data, get_album_types_list, delete_selected_gallery_media } from '../../actions/user';
import { toast } from 'react-toastify';
import { getShareReviewURL } from '../../utils/utilities';

function UserFeedCard({
    feed,
    host,
    protocol,
    shareTitle,
    shareVia,
    fbHashTag,
    twitterHashTags,
    handleOnClickShareByEmail,
    addToList,
    fetchingUpdates,
    my_profile_data,
    add_comment,
    get_album_type_data,
    delete_selected_gallery_media,
    album_type_data,
    album_types_list
}) {
    const [reviewDiscussion, setReviewDiscussion] = useState(feed?.action?.review_discussion ? feed.action.review_discussion : []);
    const [showMore, setShowMore] = useState(false);
    const [showAllComments, setShowAllComments] = useState(false)
    const [replyForm, setReplyForm] = useState({})
    const [reply, setReply] = useState({
        id: '',
        body: ''
    })
    const [uploadMedia, setUploadMedia] = useState({
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
    })
    const [uploadMediaPostModal, setUploadMediaPostModal] = useState(false)
    const [fetchGalleryAlbum, setFetchGalleryAlbum] = useState("")
    const [fetchGalleryScroll, setFetchGalleryScroll] = useState({})
    const [fetchGalleryCount, setFetchGalleryCount] = useState(0)
    const [selectedUpMediaType, setSelectedUpMediaType] = useState('upload')
    const [showGalleryType, setShowGalleryType] = useState('images')

    const refGalleryImageScroll = useRef(null)
    const refGalleryVideoScroll = useRef(null)

    useEffect(() => {
        if (album_type_data && Object.keys(album_type_data).length > 0) {
            let item = album_type_data;
            let tempFetchGalleryScroll = fetchGalleryScroll;
            let tempFetchGalleryCount = fetchGalleryCount;
            if (item.album) { tempFetchGalleryScroll = [] }
            tempFetchGalleryScroll[item.page] = item;
            tempFetchGalleryCount = item.count;
            setUploadMedia((prevValue) => {
                return {
                    ...prevValue,
                    albumTypeData: album_type_data,
                }
            })
            setFetchGalleryCount(tempFetchGalleryCount)
            setFetchGalleryScroll(tempFetchGalleryScroll)
        }
    }, [album_type_data])

    useEffect(() => {
        if (album_types_list && album_types_list?.length > 0) {
            setUploadMedia((prevValue) => {
                return {
                    ...prevValue,
                    albumTypesList: album_types_list,
                }
            })
            setSelectedUpMediaType("upload")
            setShowGalleryType("images")
        }
    }, [album_types_list])

    const getShareTitle = (name) => {
        const title = name ? `Checkout ${name} on ` : '';
        return title;
    }

    const fetchGalleryOnScroll = ({ type, album, force_update }) => {
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;
            if (nextCount <= roundValue) {
                let page = nextCount;
                get_album_type_data(type, "", page);
                //this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    const handleScrollGalleryImage = (e) => {
        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    const handleScrollGalleryVideo = (e) => {
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    const fetchScrollPhotoMedia = (isVideo) => {
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0) {
                return getDataItems.map(item => {

                    let itemName = (item?.thumbnail).split('.');
                    let itemFile = (item?.file).split('.');
                    let extName = itemName[itemName.length - 1];
                    let extFile = itemFile[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview

                    return (
                        <Col xs="auto" className="mb-3">
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={() =>
                                        handleOnClickSelectGalleryMedia(
                                            item
                                        )
                                    } />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    const handleToggleReadMoreShow = (status) => {
        setShowMore(status)
    }

    const handleShowAllComments = (status) => {
        setShowAllComments(true)
    }

    const toggleReplyForm = (id, discussion_id) => {
        let tempReplyForm = { ...replyForm };
        const tempReply = { id: '', body: '' };
        if (tempReplyForm && Object.keys(tempReplyForm).length > 0) {
            tempReplyForm = {};
        } else {
            tempReplyForm[id] = {};
            tempReplyForm[id][discussion_id] = true
            tempReply.id = id;
        }
        setReplyForm(tempReplyForm);
        setReply(tempReply);
    }


    const onClickPostComment = () => {
        if (reply.id) {
            if (reply.body !== '') {
                let review_discussion = reviewDiscussion;
                let data = {
                    body: reply.body,
                    media: uploadMedia.selectedMediaIds,
                    review: reply.id
                }
                let url = `/api/reviewdiscussion/?review=${data.review}`;
                if (feed?.action?.professionalreview) {
                    url = `/api/professionalreviewdiscussion/?review=${data.review}`;
                }
                // let url = `/api/userfeeds/?category=${feed.target.category.toLowerCase()}&searchTerm=comments&page=1`;
                try {
                    callApi(url, "POST", data).then((response) => {
                        if (response && (response.code >= 400 || response.code <= 600)) {
                            toast(`Comment added successfully!`, {
                                autoClose: 2500,
                                className: "black-background",
                                bodyClassName: "red-hunt",
                                progressClassName: "cc"
                            });
                            review_discussion.push(response.review_discussion[0]);
                            setReviewDiscussion([...review_discussion])
                        }
                    });

                } catch (error) {
                }
                toggleReplyForm(reply.id);
                setUploadMedia((uploadMedia) => {
                    return {
                    ...uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    mediaType: "image",
                    albumType: "",
                    pageNo: 1,
                    albumTypeData: {},
                    //albumTypesList: [],
                    embedLinks: {},
                    uploadedFiles: [],
                    uploadFiles: [],
                }})
                // this.setState({
                //     selectedUpMediaType: "upload",
                //     showGalleryType: "images"
                // });
            }
        }
    }

    const uploadMediaModalToggle = () => {
        setUploadMediaPostModal((prevState) => {
            return !prevState
        })
        setUploadMedia((prevValue) => {
            return {
                ...prevValue,
                selectedMedia: [],
                selectedMediaIds: [],
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                //albumTypesList: [],
                embedLinks: {},
                uploadedFiles: [],
                uploadFiles: [],
            }
        })
        setSelectedUpMediaType("upload")
        setShowGalleryType("images")
    };

    const handleOnClickSelectedUploadMediaType = (type) => {
        if (type === "gallery") {
            get_album_type_data("image", "", 1);
            setSelectedUpMediaType(type)
            setShowGalleryType("images")
            setUploadMedia((prevData) => {
                return {
                    ...prevData,
                    mediaType: "image",
                    albumType: "",
                    pageNo: 1,
                    //selectedMedia: [],
                    //selectedMediaIds: [],
                    embedLinks: {},
                    progress: 0,
                }
            })
        } else if (type === "upload") {
            setSelectedUpMediaType(type)
            setUploadMedia((prevData) => {
                return {
                    ...prevData,
                    embedLinks: {},
                    progress: 0,
                }
            })
        } else if (type === "embed") {
            let embedLinks = {
                0: "",
                1: "",
            };
            setSelectedUpMediaType(type)
            setUploadMedia((prevData) => {
                return {
                    ...prevData,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    embedLinks: embedLinks,
                }
            })
        }
    };

    const handleOnClickSelectGalleryMedia = (media) => {
        let index = uploadMedia.selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        if (index !== -1) {
            newSelectedMediaIds = uploadMedia.selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = uploadMedia.selectedMedia.filter(
                (item) => item.id !== media.id
            );
        } else {
            newSelectedMediaIds = [
                ...uploadMedia.selectedMediaIds,
                media.id,
            ];
            newSelectedMedia = [...uploadMedia.selectedMedia, media];
        }
        setUploadMedia((prevData) => {
            return {
                ...prevData,
                selectedMedia: newSelectedMedia,
                selectedMediaIds: newSelectedMediaIds,
            }
        })
    };

    const handleOnFilePostUploadChange = (event) => {
        let uploadFiles = event.target.files;
        let showFiles = [];
        for (const key of Object.keys(uploadFiles)) {
            showFiles.push({ id: "", url: URL.createObjectURL(uploadFiles[key]) });
        }
        setUploadMedia((prevData) => {
            return {
                ...prevData,
                uploadFiles: showFiles,
                progress: 0,
            }
        })
        for (const key of Object.keys(uploadFiles)) {
            let progressPart = 100 / showFiles.length;
            let progress = 0;
            let data = new FormData();
            data.append("file", uploadFiles[key]);

            callApi(
                `/upload/multiuploader/?album=&instance=reviewcomment&image=undefined&doc=undefined`,
                "POST",
                data,
                true
            ).then((response) => {
                handleOnClickSelectGalleryMedia(response);
                if (showFiles.length === 1 || key === showFiles.length - 1) {
                    progress = 100;
                } else {
                    progress = progress + progressPart;
                }
                showFiles[key].id = response.id;
                setUploadMedia((prevData) => {
                    return {
                        ...prevData,
                        progress,
                        // uploadedFiles: [...uploadMedia.uploadedFiles, response],
                        uploadedFiles: showFiles,
                    }
                })
            });
        }
    };

    const handleOnClickRemoveSelectedMedia = (id) => () => {
        let removeMediaIds = [];
        removeMediaIds.push(id);
        delete_selected_gallery_media(removeMediaIds);
        let uploadFiles = uploadMedia.uploadFiles.filter(
            (file) => file.id !== id
        );
        let uploadedFiles = uploadMedia.uploadedFiles.filter(
            (file) => file.id !== id
        );
        let selectedMediaIds = uploadMedia.selectedMediaIds.filter(
            (item) => item !== id
        );
        let selectedMedia = uploadMedia.selectedMedia.filter(
            (file) => file.id !== id
        );
        setUploadMedia((prevData) => {
            return {
                ...prevData,
                selectedMedia: selectedMedia,
                selectedMediaIds: selectedMediaIds,
                uploadedFiles: uploadedFiles,
                uploadFiles: uploadFiles,
            }
        })
    };

    const handleOnClickGalleryType = (type) => {
        let mediaType = "";
        if (type === "images") {
            mediaType = "image";
        } else if (type === "videos") {
            mediaType = "video";
        }
        get_album_type_data(mediaType, "", 1);
        setSelectedUpMediaType("gallery")
        setShowGalleryType(type)
        setUploadMedia((prevData) => {
            return {
                ...prevData,
                mediaType: mediaType,
                albumType: "",
                pageNo: 1,
                //selectedMedia: [],
                //selectedMediaIds: [],
            }
        })
    };

    const handleOnClickAlbumTypeChange = (e) => {
        get_album_type_data("image", e.target.value, 1);
        setSelectedUpMediaType("gallery")
        setShowGalleryType("images")
        setUploadMedia((prevData) => {
            return {
                ...prevData,
                mediaType: "image",
                albumType: e.target.value,
                pageNo: 1,
                //selectedMedia: [],
                //selectedMediaIds: [],
            }
        })
    };

    const handleOnClickUploadMedia = () => {
        get_album_types_list();
        setUploadMedia((prevData) => {
            return {
                ...prevData,
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                progress: 0,
                uploadFiles: [],
                uploadedFiles: [],
            }
        })
        setUploadMediaPostModal(true)
        setSelectedUpMediaType("upload")
        setShowGalleryType("images")
    };

    const handleOnClickRemoveSelectedGalleryMedia = (media) => {
        let removeMediaIds = [];
        removeMediaIds.push(media.id);
        let index = uploadMedia.selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        if (index !== -1) {
            newSelectedMediaIds = uploadMedia.selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = uploadMedia.selectedMedia.filter(
                (item) => item.id !== media.id
            );
            delete_selected_gallery_media(removeMediaIds);
            setUploadMedia((prevValue) => {
                return {
                    ...prevValue,
                    selectedMedia: newSelectedMedia,
                    selectedMediaIds: newSelectedMediaIds,
                }
            })
        }
    };

    const renderCommentCard = (comment_details) => {
        return (
        <>
        <Card key={comment_details.id} className='mb-1'>
            <CardBody className='text-tertiary card-body'>
                <div className='row'>
                    <div className='pl-3 col-md-3'>
                        {
                            comment_details?.sender?.current_profile_pic ?
                                <img 
                                className='img-circle _50x50' 
                                src={comment_details.sender.current_profile_pic} 
                                alt={comment_details.sender.full_name} /> :
                                <img 
                                className='img-circle _50x50' 
                                src='https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png' 
                                alt={comment_details.sender.full_name} />
                        }
                    </div>
                    <div className='col-md-9 pl-0'>
                        <div>
                            <span className='font-weight-bold'>{comment_details?.sender?.full_name}</span>
                            <span> replied to </span>
                            {/* <span className='font-weight-bold'>{comment_details?.sender?.full_name}</span> */}
                        </div>
                        <div className='text-tertiary'>{comment_details?.sent_at ? comment_details?.sent_at : ''}</div>
                        <div className='mt-3'>
                            {comment_details?.body}
                            <div className='row mt-2'>
                                {comment_details?.discussion_media && Array.isArray(comment_details.discussion_media) ?
                                    comment_details.discussion_media.map((media_item, index) => {
                                        return <div key={index} className="col-md-6 p-1">
                                            {
                                                (media_item?.type === "image" ||
                                                    media_item?.media_type === "image") && (
                                                    <div>
                                                        <img className="img-fluid img-thumbnail w-100" src={media_item?.url && media_item.url} alt="Uploaded media" />
                                                    </div>
                                                )
                                            }
                                            {
                                                (media_item?.type === "video" ||
                                                    media_item?.media_type === "video") && (
                                                    <div>
                                                        <video className='w-100' controls>
                                                            <source src={media_item?.url && media_item.url} />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    })
                                    :
                                    ''
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <Button color='link' className='mt-3 d-block text-dark font-weight-bold ml-auto' onClick={() => toggleReplyForm(feed?.action?.id, comment_details?.id)}>Reply</Button>
            </CardBody>
        </Card>
                {
                    <>
                        {/* Reply Box */}
                        <div hidden={!(replyForm && Object.keys(replyForm).length > 0 && replyForm[feed?.action?.id] && replyForm[feed?.action?.id][comment_details?.id])} className="mt-1">
                            <div className="bg-white text-dark p-3 mb-1">
                                <div className="d-flex mx-n2 mb-2">
                                    <div className="px-2">
                                        <a href="#" className="text-decoration-none">
                                            <img className="img-circle _30x30" src={my_profile_data?.current_profile_file ? my_profile_data?.current_profile_file : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt="" />
                                        </a>
                                    </div>
                                    <div className="px-2 col">
                                        <FormGroup>
                                            <Input className="primary font-weight-bold text-primary" type="textarea" placeholder="Add a Comment..." value={reply.body} onChange={(e) =>
                                                setReply({
                                                    ...reply,
                                                    body: e.target.value
                                                })} />
                                        </FormGroup>

                                        {/* Uploaded media shown here */}
                                        <div className="border mb-3">
                                            <Row xs={1} md={2} lg={3} noGutters>
                                                {uploadMedia.selectedMedia.length > 0 &&
                                                    uploadMedia.selectedMedia.map((it) => {
                                                        return (
                                                            <Col>
                                                                <div className="d-flex pr-3 m-2">
                                                                    {(it.type === "image" ||
                                                                        it.media_type === "image") && (
                                                                            <div>
                                                                                <img className="img-fluid img-thumbnail" src={it.file ? it.file : it.url} alt="Uploaded media" />
                                                                            </div>
                                                                        )}
                                                                    {(it.type === "video" ||
                                                                        it.media_type === "video") && (
                                                                            <div>
                                                                                <video width="100" height="100" controls>
                                                                                    <source src={it.file ? it.file : it.url} type="video/mp4" />
                                                                                    Your browser does not support the video tag.
                                                                                </video>
                                                                            </div>
                                                                        )}
                                                                    <div className="mx-n3 mt-2">
                                                                        <Button color="delete-media" size="sm" title="Remove Media" onClick={() =>
                                                                            handleOnClickRemoveSelectedGalleryMedia(it)
                                                                        }><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        );
                                                    })}

                                            </Row>
                                        </div>

                                        <div className="d-flex mx-n2">
                                            <div className="px-2 col">
                                                <Button color="transparent" size="sm" title="Upload Media" onClick={() => handleOnClickUploadMedia()}>
                                                    <FontAwesomeIcon icon="camera" />
                                                </Button>
                                            </div>
                                            <div className="px-2 col">
                                                <div className="text-right">
                                                    <Button color="primary" size="sm" onClick={() => onClickPostComment()}>Post a Comment</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
        </>)
    }

    const quote = `${feed?.target?.name ? `Check out ${feed?.actor?.first_name} ${feed?.actor?.last_name || ""}'s review of ${feed?.target?.name} on ` : ''}`

    const checkSharingEnable = (verb)=>{
        return verb?.includes('review') || false
    }
  
    return (
        <>
            <Card className="text-black bg-white mb-1 mt-2">
                <CardBody className='text-dark'>
                    <div className="d-flex mx-n1">
                        <div className="px-1 col">
                            <Media>
                                {
                                    feed?.actor?.username ?
                                        <Link to={`/people/${feed?.actor?.username}/`}>
                                            <Media className="mr-3" left>
                                                <Media className="img-circle _50x50" src={feed.actor?.current_profile_pic?.url ? feed.actor?.current_profile_pic?.url : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt={feed?.actor?.first_name} />
                                            </Media>
                                        </Link> :
                                        <Media className="mr-3" left>
                                            <Media className="img-circle _50x50" src={feed.actor?.current_profile_pic?.url ? feed.actor?.current_profile_pic?.url : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt={feed?.actor?.first_name} />
                                        </Media>
                                }
                                <Media body>
                                    <Media className="font-weight-normal ff-base fs-14 mb-2" heading>
                                        <Link to={`/people/${feed?.actor?.username}/`} className="text-reset font-weight-bold">{feed?.actor?.first_name} {" "}
                                            {feed?.actor?.last_name}
                                        </Link>
                                        {" "} {feed?.verb} {" "}
                                        <Link
                                            to={
                                                {
                                                    pathname: (feed?.target && feed?.target.slug ? `/${feed?.target?.category?.toLowerCase()}/${feed?.target?.slug}` : `/${feed?.target?.category?.toLowerCase()}/${feed?.target?.name?.replace(/ /g, "-")?.toLowerCase()}`),
                                                    state: { id: feed?.target?.id }
                                                }}
                                            className="text-reset font-weight-bold">
                                            {feed?.target?.name}
                                        </Link>
                                        {
                                            feed?.verb === "wrote a review" && feed.action && feed.action?.associated_rating?.avg_rating ?
                                                <>
                                                    <span> and gave it </span>
                                                    <br />
                                                    <img className='ml-3 mt-1' title={feed.action?.associated_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${feed.action?.associated_rating?.avg_rating[0]}`} alt="Rating" />
                                                </>
                                                :
                                                ''
                                        }
                                    </Media>

                                    <div className="text-tertiary text-left fs-14 mt-2 ff-alt">
                                        {feed?.time_stamp}
                                    </div>
                                </Media>
                            </Media>
                        </div>
                        <div className="px-1 col-auto">
                            <div className="text-right mb-2 mt-n2 mr-n2">
                                <div className="d-inline-flex mx-n1">
                                    <div className="px-1">
                                        {checkSharingEnable(feed?.verb) && 
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="p-0 text-dark" color="transparent">
                                                <div className="share-it-icon"></div>
                                            </DropdownToggle>
                                            <DropdownMenu className="type-tertiary-alt" right>
                                                <DropdownItem className="fs-14">   
                                                    <FacebookShareButton
                                                        url={`${getShareReviewURL(feed?.target?.category, feed?.target?.slug, feed?.target?.id)}`}
                                                        quote={quote}
                                                        hashtag={fbHashTag}>    
                                                        {"Facebook"}
                                                    </FacebookShareButton>
                                                </DropdownItem>
                                                {/* <DropdownItem className="fs-14">Google +</DropdownItem> */}
                                                <DropdownItem className="fs-14">                                                    
                                                    <TwitterShareButton
                                                         url={`${getShareReviewURL(feed?.target?.category, feed?.target?.slug, feed?.target?.id)}`}
                                                        title={quote}
                                                        via={shareVia}
                                                        hashtags={twitterHashTags}>
                                                        {"Twitter"}
                                                    </TwitterShareButton>
                                                </DropdownItem>
                                                <DropdownItem className="fs-14" onClick={() => handleOnClickShareByEmail(feed.action?.id)}>By Email</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        }
                                    </div>
                                    <div className="px-1">
                                        <UncontrolledDropdown>
                                            <DropdownToggle className="p-0 text-dark" color="transparent">
                                                <div className="thumbpin-icon"></div>
                                            </DropdownToggle>
                                            {
                                                fetchingUpdates ?
                                                    <DropdownMenu className="type-tertiary-alt" right>
                                                        <DropdownItem className="fs-14">
                                                            <FontAwesomeIcon icon="sync" className="mr-1" fixedWidth /> Updating pin status
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                    :
                                                    <DropdownMenu className="type-tertiary-alt" right>
                                                        <DropdownItem className="fs-14" onClick={() => addToList('want list', feed.target?.userentry, feed.target?.name, feed.target?.userpins?.want_this)}>
                                                            <FontAwesomeIcon icon="heart" className="mr-2" fixedWidth /> {feed.target?.userpins?.want_this ? 'Added' : 'Add'} to want list
                                                        </DropdownItem>
                                                        <DropdownItem className="fs-14" onClick={() => addToList('favourite list', feed.target?.userentry, feed.target?.name, feed.target?.userpins?.favourite)}>
                                                            <FontAwesomeIcon icon="star" className="mr-2" fixedWidth /> {feed.target?.userpins?.favourite ? 'Added' : 'Add'} to favorites
                                                        </DropdownItem>
                                                        <DropdownItem className="fs-14"
                                                            onClick={() => addToList('watch list', feed.target?.userentry, feed.target?.name, feed.target?.userpins?.watch_this)}>
                                                            <FontAwesomeIcon icon="eye" className="mr-2" fixedWidth /> {feed.target?.userpins?.watch_this ? 'Added' : 'Add'} to watch list
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                            }

                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            (feed.verb === "wrote a review" || feed.verb === "commented on a review on") && feed?.action && feed.action?.review ?
                                feed.action.review?.length > 120 ?
                                    showMore ?
                                        <div className='mt-3 mb-2'>
                                            <span className='text-dark' dangerouslySetInnerHTML={{ __html: feed.action.review }} />
                                        </div>
                                        :
                                        <div className='mt-3 mb-2'>
                                            <span className='text-dark' dangerouslySetInnerHTML={{ __html: feed.action.review?.slice(0, 120)?.concat("...") }} />
                                        </div>
                                    :
                                    <div className='mt-3 mb-2'>
                                        <span className='text-dark' dangerouslySetInnerHTML={{ __html: feed.action.review }} />
                                    </div>
                                :
                                ''
                        }
                        {
                            (feed.verb === "wrote a review" || feed.verb === "commented on a review on") && feed?.action && feed.action?.professionalreview ?
                                feed.action.professionalreview?.length > 120 ?
                                    showMore ?
                                        <div className='mt-3 mb-2'>
                                            <span className='text-dark' dangerouslySetInnerHTML={{ __html: feed.action.professionalreview }} />
                                        </div>
                                        :
                                        <div className='mt-3 mb-2'>
                                            <span className='text-dark' dangerouslySetInnerHTML={{ __html: feed.action.professionalreview?.slice(0, 120)?.concat("...") }} />
                                        </div>
                                    :
                                    <div className='mt-3 mb-2'>
                                        <span className='text-dark' dangerouslySetInnerHTML={{ __html: feed.action.professionalreview }} />
                                    </div>
                                :
                                ''
                        }
                        {
                            (feed.verb === "wrote a review" || feed.verb === "commented on a review on") && feed?.action && feed.action?.review ?
                                feed.action.review?.length > 120 ?
                                    showMore ?
                                        <div className='mt-3 mb-2 text-right'>
                                            <Button color="link" className="text-dark p-0 font-weight-bold" onClick={() => {
                                                handleToggleReadMoreShow(false)
                                            }}>show less</Button>
                                        </div>
                                        :
                                        <div className='mt-3 mb-2 text-right'>
                                            <Button color="link" className="text-dark p-0 font-weight-bold" onClick={() => {
                                                handleToggleReadMoreShow(true)
                                            }}>read more</Button>
                                        </div>
                                    :
                                    ''
                                :
                                ''
                        }
                        {
                            (feed.verb === "wrote a review" || feed.verb === "commented on a review on") && feed?.action && feed.action?.professionalreview ?
                                feed.action.professionalreview?.length > 120 ?
                                    showMore ?
                                        <div className='mt-3 mb-2 text-right'>
                                            <Button color="link" className="text-dark p-0 font-weight-bold" onClick={() => {
                                                handleToggleReadMoreShow(false)
                                            }}>show less</Button>
                                        </div>
                                        :
                                        <div className='mt-3 mb-2 text-right'>
                                            <Button color="link" className="text-dark p-0 font-weight-bold" onClick={() => {
                                                handleToggleReadMoreShow(true)
                                            }}>read more</Button>
                                        </div>
                                    :
                                    ''
                                :
                                ''
                        }
                        {
                            (feed.verb === "wrote a review" || feed.verb === "commented on a review on") && feed?.action && feed.action?.reviewmedia_set && Array.isArray(feed.action.reviewmedia_set) ?
                                feed.action.reviewmedia_set.map((media_item, index) => {
                                    return (<div key={index} className="row">
                                        <div className='col-md-2'></div>
                                        <div className='col-md-10'>
                                            {media_item?.type === "video" ?
                                                <>
                                                    <video className='img img-fluid mb-2' controls>
                                                        <source src={media_item?.url} />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </>
                                                :
                                                <img className='img img-fluid mb-2' src={media_item?.url} alt={media_item?.filename} />
                                            }
                                        </div>
                                    </div>)
                                })
                                :
                                ''
                        }
                    </div>
                </CardBody>
            </Card>
            {
                (feed?.verb === "commented on a review on" && reviewDiscussion && Array.isArray(reviewDiscussion) && reviewDiscussion.length > 0) ?
                reviewDiscussion?.length === 1 ?
                        renderCommentCard(reviewDiscussion[0])
                        :
                        <>
                            {renderCommentCard(reviewDiscussion[0])}
                            {showAllComments ?
                                reviewDiscussion?.slice(1).map((comment_details) => {
                                    return renderCommentCard(comment_details)
                                }) :
                                <Card className='mt-1 mb-3'>
                                    <CardBody className='text-dark p-0 pt-2 pb-2'>
                                        <div className='row align-items-center'>
                                            <div className='col-md-3 text-right'>
                                                <img src='https://userdatawikireviews.s3.amazonaws.com/images/chat-icon.png' alt='chat-icon' />
                                            </div>
                                            <div className='col-md-9 font-weight-bold pl-0'>
                                                <Button color='link' className='text-dark' onClick={() => {
                                                    handleShowAllComments()
                                                }}>View {reviewDiscussion.length - 1} more comments</Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            }
                        </>
                    :
                    ''
            }

            {/* Upload Media Post Modal */}
            <Modal
                isOpen={uploadMediaPostModal}
                toggle={uploadMediaModalToggle}
            >
                <ModalHeader toggle={uploadMediaModalToggle}>
                    UPLOAD MEDIA
                </ModalHeader>
                <ModalBody className="p-3">
                    <Row>
                        <Col xs={"auto"}>
                            <div className="bg-light h-100 p-3">
                                <Nav tabs className="flex-column active-transparent border-0 m-0">
                                    <NavItem className="text-left">
                                        <NavLink
                                            className="pl-0 pr-4 border-0"
                                            href="#"
                                            active={selectedUpMediaType === "upload"}
                                            onClick={() => {
                                                handleOnClickSelectedUploadMediaType("upload");
                                            }}
                                        >
                                            Upload
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <hr className="my-3 bg-tertiary" />
                                    </NavItem>
                                    <NavItem className="text-left border-0">
                                        <NavLink
                                            className="pl-0 pr-4 border-0"
                                            href="#"
                                            active={selectedUpMediaType === "gallery"}
                                            onClick={() => {
                                                handleOnClickSelectedUploadMediaType("gallery");
                                            }}
                                        >
                                            Gallery
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </Col>
                        <Col>
                            <TabContent activeTab={selectedUpMediaType}>
                                <TabPane tabId="upload">
                                    <div
                                        className="mb-3 type-file-block bg-light"
                                        hidden={
                                            uploadMedia.uploadFiles &&
                                                uploadMedia.uploadFiles.length > 0
                                                ? true
                                                : false
                                        }
                                    >
                                        <Input
                                            type="file"
                                            name="upload_media_file"
                                            id="uploadFilesPostFromSystem"
                                            accept="image/*, video/*"
                                            onChange={handleOnFilePostUploadChange}
                                            multiple
                                        />
                                        <Label for="uploadFilesPostFromSystem">
                                            <div className="type-bg"></div>
                                        </Label>
                                    </div>
                                    {uploadMedia.uploadFiles.length > 0 && (
                                        <Row className="mb-3" form>
                                            <Col xs={12}>
                                                <div
                                                    style={{
                                                        maxWidth: "120px",
                                                        margin: "0 1rem 1rem auto",
                                                    }}
                                                >
                                                    <div className="text-center mb-1 small">
                                                        {uploadMedia.progress === 100 ? (
                                                            <div className="text-success">
                                                                <FontAwesomeIcon
                                                                    icon="check-circle"
                                                                    className="mr-1"
                                                                />{" "}
                                                                Uploaded
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                Uploading{" "}
                                                                <span className="text-success font-weight-bold ff-base">
                                                                    {uploadMedia.progress.toFixed(0)}
                                                                    %
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <Progress
                                                        value={uploadMedia.progress}
                                                        style={{ height: "8px" }}
                                                    ></Progress>
                                                </div>
                                            </Col>
                                            {uploadMedia.uploadFiles.map((file) => {
                                                return (
                                                    <Col xs="auto">
                                                        <div className="d-flex pr-3">
                                                            <div>
                                                                <div
                                                                    className="selectable-media"
                                                                    style={{ cursor: "default" }}
                                                                >
                                                                    <div className="gallery-media">
                                                                        <img src={file.url} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mx-n3 mt-2">
                                                                <Button color="delete-media" size="sm" title="Remove Media"
                                                                    hidden={file.id === "" ? true : false}
                                                                    onClick={handleOnClickRemoveSelectedMedia(
                                                                        file.id
                                                                    )}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            })}
                                            <Col xs="auto">
                                                <div className="d-flex pt-3">
                                                    <div className="selectable-media" hidden>
                                                        <Label
                                                            for="uploadFilesFromSystemMini"
                                                            className="gallery-media"
                                                            style={{
                                                                borderStyle: "dashed",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <div className="d-flex h-100 align-items-center justify-content-center">
                                                                <span className="fs-14">Upload More</span>
                                                            </div>
                                                        </Label>
                                                        <Input
                                                            type="file"
                                                            name="upload_media_file"
                                                            id="uploadFilesFromSystemMini"
                                                            accept="image/*, video/*"
                                                            onChange={handleOnFilePostUploadChange}
                                                            multiple
                                                            style={{ display: "none" }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    <div className="d-flex mx-n2">
                                        <div className="px-2">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mw"
                                                onClick={uploadMediaModalToggle}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="px-2 ml-auto">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                className="mw"
                                                onClick={() => {
                                                    setUploadMediaPostModal(false)
                                                }}
                                            >
                                                {'OK'}
                                            </Button>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="gallery">
                                    <div className="mb-2">
                                        <Nav tabs className="d-inline-flex mb-0">
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    active={showGalleryType === "images"}
                                                    onClick={() => {
                                                        handleOnClickGalleryType("images");
                                                    }}
                                                >
                                                    Image Gallery
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    active={showGalleryType === "videos"}
                                                    onClick={() => {
                                                        handleOnClickGalleryType("videos");
                                                    }}
                                                >
                                                    Video Gallery
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <TabContent activeTab={showGalleryType}>
                                        <TabPane tabId="images">
                                            <div>
                                                <FormGroup className="form-row justify-content-end">
                                                    <Col xs="auto">
                                                        <Label className="small" for="selectAlbumType">
                                                            Album Type:
                                                        </Label>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Input
                                                            bsSize="sm"
                                                            type="select"
                                                            name="album_type_select"
                                                            id="selectAlbumType"
                                                            defaultValue={uploadMedia.albumType}
                                                            onChange={handleOnClickAlbumTypeChange}
                                                        >
                                                            <option value="">All</option>
                                                            {uploadMedia.albumTypesList.length >
                                                                0 &&
                                                                uploadMedia.albumTypesList.map(
                                                                    (type) => (
                                                                        <option value={type.category}>
                                                                            {type.category}
                                                                        </option>
                                                                    )
                                                                )}
                                                        </Input>
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                            <div
                                                className="px-3 my-3"
                                                style={{ maxHeight: "350px", overflow: "auto" }}
                                                ref={refGalleryImageScroll}
                                                onScroll={handleScrollGalleryImage}
                                            >
                                                {uploadMedia.albumTypeData &&
                                                    uploadMedia.albumTypeData.count > 0 ? (
                                                    <Row>
                                                        {fetchScrollPhotoMedia()}
                                                    </Row>
                                                ) : (
                                                    <div className="bg-white p-3">
                                                        <h2 className="text-secondary-dark">No images</h2>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="d-flex mx-n2">
                                                <div className="px-2">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={uploadMediaModalToggle}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div className="px-2 ml-auto">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={() => {
                                                            setUploadMediaPostModal(false)
                                                        }}
                                                    >
                                                        {'OK'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="videos">
                                            <div
                                                className="px-3 my-3"
                                                style={{ maxHeight: "350px", overflow: "auto" }}
                                                ref={refGalleryVideoScroll}
                                                onScroll={handleScrollGalleryVideo}
                                            >
                                                {uploadMedia.albumTypeData &&
                                                    uploadMedia.albumTypeData.count > 0 ? (
                                                    <Row>
                                                        {fetchScrollPhotoMedia(true)}
                                                    </Row>
                                                ) : (
                                                    <div className="bg-white p-3">
                                                        <h2 className="text-secondary-dark">No videos</h2>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="d-flex mx-n2">
                                                <div className="px-2">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={uploadMediaModalToggle}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                                <div className="px-2 ml-auto">
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="mw"
                                                        onClick={() => {
                                                            setUploadMediaPostModal(false)
                                                        }}
                                                    >
                                                        {'OK'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

const mapState = state => ({
    connections_activity_data: state.business.connections_activity_data,
    my_profile_data: state.user.my_profile,
    album_types_list: state.user.album_types_list,
    album_type_data: state.user.album_type_data
});

const mapDispatch = dispatch => ({
    get_connections_activity: (url) => dispatch(get_connections_activity(url)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    my_profile: () => dispatch(my_profile()),
    add_comment: (data, url) => dispatch(add_comment(data, url)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    delete_selected_gallery_media: (dataID) => dispatch(delete_selected_gallery_media(dataID)),
    get_album_type_data: (type, album, page) => dispatch(get_album_type_data(type, album, page)),
    get_album_types_list: () => dispatch(get_album_types_list()),
});

export default connect(mapState, mapDispatch)(UserFeedCard);
