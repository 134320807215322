import React from 'react';

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: { message: '', stack: '' },
        info: { componentStack: '' }
      };
    
      static getDerivedStateFromError = error => {
        return { hasError: true };
      };
    
      componentDidCatch = (error, info) => {
        this.setState({ error, info });
      };
    
      render() {
        const { hasError, } = this.state;
        const { children } = this.props;
    
        return hasError ? 
        (
        <div className="container">
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.info?.componentStack}
          </details>
        </div>
      )
        : children;
      }   
}

export default ErrorBoundary;