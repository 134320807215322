import axios from 'axios';
import { setUploadPercentage } from '../actions/shared';
import { API_URL } from "./constants";


export const callApi = (endpoint, method = 'POST', body, fileToUpload = false,type,accessToken,dispatch) => {
    let headers = {};
    let authToken = false;
    let baseUrl = `${API_URL}${endpoint}`

    if(endpoint !== `/api/notification/`) localStorage.setItem('loader', true);
    let isTokenAvailable = localStorage.getItem('token');

    if (isTokenAvailable) {
        authToken = isTokenAvailable;
    }

    if (method) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
    }

    if (fileToUpload) {
        headers['Content-Type'] = 'multipart/form-data';
    } else {
        // headers['Content-Type'] = 'application/json;charset=UTF-8';
        headers['Content-Type'] = 'application/json';
    }

    

    // headers['Accept'] = 'application/json, text/plain, */*';
    // headers['Cookie'] = 'authorization=79d285ce70983b1cb3417280411ebbd88286a356; authenticate=79d285ce70983b1cb3417280411ebbd88286a356; csrftoken=jjxUaDsynURUZ2P8pQR3wKPpQsaun8Yb';
    // headers['Connection'] = 'keep-alive';
    // headers['X-CSRFToken'] = 'jjxUaDsynURUZ2P8pQR3wKPpQsaun8Yb';

    if (authToken) {
        headers['Authorization'] = `Token ${authToken}`;
    }

    if(type==="googleLogin"){        
        headers['Authorization'] = `Token ${accessToken}`
    }

    let axiosObject = {
        method: method,
        url: baseUrl,
        data: body,
        headers: headers,
        onUploadProgress: (progressEvent) => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );         
          dispatch(setUploadPercentage(percentage))
          }
    }
    if(!fileToUpload){
         delete axiosObject.onUploadProgress 
    }

    return axios(axiosObject).then((response) => {
        if(endpoint !== `/api/notification/`)    localStorage.setItem('loader', false);     
        return response;
    }).catch((err) => err);
}


export const callApiAdvance = ({ endpoint, method, body, fileToUpload, loader}) => {
    
    localStorage.setItem('loader', loader);

    let headers = {};
    let authToken = false;
    let baseUrl = `${API_URL}${endpoint}`

    localStorage.setItem('loader', loader);

    let isTokenAvailable = localStorage.getItem('token');

    if (isTokenAvailable) {
        authToken = isTokenAvailable;
    }

    if (method) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
    }

    if (fileToUpload) {
        headers['Content-Type'] = 'multipart/form-data';
    } else {
        headers['Content-Type'] = 'application/json';
        // headers['Content-Type'] = 'application/json;charset=UTF-8';
    }

    // headers['Accept'] = 'application/json, text/plain, */*';
    // headers['Cookie'] = 'authorization=79d285ce70983b1cb3417280411ebbd88286a356; authenticate=79d285ce70983b1cb3417280411ebbd88286a356; csrftoken=jjxUaDsynURUZ2P8pQR3wKPpQsaun8Yb';
    // headers['Connection'] = 'keep-alive';
    // headers['X-CSRFToken'] = 'jjxUaDsynURUZ2P8pQR3wKPpQsaun8Yb';

    if (authToken) {
        headers['Authorization'] = `Token ${authToken}`;
    }

    return axios({
        method: method,
        url: baseUrl,
        data: body,
        headers: headers
    }).then((response) => {
        localStorage.setItem('loader', false);
        return response;
    }).catch((err) => err);
}

export const callApiNotification = (endpoint, method = 'POST', body, fileToUpload = false) => {
    //localStorage.setItem('loader', true);
    let headers = {};
    let authToken = false;
    let baseUrl = `${API_URL}${endpoint}`

    //localStorage.setItem('loader', true);
    let isTokenAvailable = localStorage.getItem('token');

    if (isTokenAvailable) {
        authToken = isTokenAvailable;
    }

    if (method) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
    }

    if (fileToUpload) {
        headers['Content-Type'] = 'multipart/form-data';
    } else {
        // headers['Content-Type'] = 'application/json;charset=UTF-8';
        headers['Content-Type'] = 'application/json';
    }

    // headers['Accept'] = 'application/json, text/plain, */*';
    // headers['Cookie'] = 'authorization=79d285ce70983b1cb3417280411ebbd88286a356; authenticate=79d285ce70983b1cb3417280411ebbd88286a356; csrftoken=jjxUaDsynURUZ2P8pQR3wKPpQsaun8Yb';
    // headers['Connection'] = 'keep-alive';
    // headers['X-CSRFToken'] = 'jjxUaDsynURUZ2P8pQR3wKPpQsaun8Yb';

    if (authToken) {
        headers['Authorization'] = `Token ${authToken}`;
    }

    return axios({
        method: method,
        url: baseUrl,
        data: body,
        headers: headers
    }).then((response) => {
        localStorage.setItem('loader', false);
        return response;
    }).catch((err) => err);
}

export const responseCodeValidator = code => !code.match(/[45]0[0-9]/g); // throw false if response code is 40* or 50*

// https://github.com/axios/axios/issues/943
// https://gist.github.com/nzvtrk/ebf494441e36200312faf82ce89de9f2
// https://www.npmjs.com/package/axios-cookiejar-support