import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux';
import {capitalizeFirstLetter, searchDataMapper} from "../../../utils/utilities";
import { Link } from 'react-router-dom';
import moment from 'moment';
import DataTable from '../admin_atoms/dataTable';
import { callApi, responseCodeValidator } from '../../../utils/apiCaller';
import querystring from "query-string";
import { debounce } from 'lodash';
import UserReviews from '../admin_atoms/userReviews';
import UserContributions from '../admin_atoms/userContributions';


const Users = () => {
    const initialState = {
        users: [],
        pageCount: 0,
        loading: true,
        currentPage: 0,
        searchUserText: '',
        isReviewModalOpen: false,
        reviewModalUser: null,
        isContributionModalOpen: false,
        contributionModalUser: null,
        intialRender: true
    }
    const [state,setState] = useState(initialState);
    
    const {
        users,
        pageCount,
        loading,
        currentPage,
        searchUserText,
        isReviewModalOpen,
        reviewModalUser,
        isContributionModalOpen,
        contributionModalUser,
        intialRender
    } = state;

    const pageMaxCount = 20;
    
    const columnFields = ["s.n", "name", "email", "public_profile", "joining_date", "reviews", "contributions", "action"];
    
    const optimizedColumns = columnFields.map((col) => {
        if (col === "s.n") {
            return {
                Header: "Sr No",
                accessor: col,
                Cell: ({ row }) => <span>{row.index+1+(currentPage*pageMaxCount)}</span>,
            };
        }
        if (col === "name") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span>{row.original.user.first_name + " " + row.original.user.last_name}</span>,
            };
        }
        if (col === "email") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span>{row.original.user.email}</span>,
            };
        }

        if (col === "public_profile") {
            return {
                Header: "Public Profile",
                accessor: col,
                Cell: ({ row }) => <span><Link to={`/people/${row.original.user.username}`}>{row.original.vanity_url}</Link></span>,
            };
        }

        if (col === "joining_date") {
            return {
                Header: "Joining date",
                accessor: col,
                Cell: ({ row }) => <span>{moment(row.original.sign_up_date).format("MMM DD, YYYY")}</span>,
            };
        }

        if (col === "reviews") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span><a href='#' onClick={(e) => { e.preventDefault(); setState((prev) => ({ ...prev, isReviewModalOpen: true, reviewModalUser: row.original })) }} > Get Reviews </a></span>,
            };
        }

        if (col === "contributions") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span><a href='#' onClick={(e) => { e.preventDefault(); setState((prev) => ({ ...prev, isContributionModalOpen: true, contributionModalUser: row.original })) }} > View Contributions </a></span>,
            };
        }

        if (col === "action") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span className={`btn btn-sm ${row.original.user.is_active ? `disable-button` : `enable-button`}`} onClick={() => { toggleUserStatus({ user: row.original }) }}>{row.original.user.is_active ? `Disable` : `Enable`}</span>,
            };
        }
    });

    const columns = useMemo(() => optimizedColumns, [optimizedColumns]);
    const Users = React.useMemo(() => users, [users]);

    useEffect(() => {
        fetchUsers({ page: 1 });
        setState((prev) => ({ ...prev, intialRender: false }))
    }, []);

 

    const fetchUsers = ({ page }) => {
        const params = (searchUserText && searchUserText.length) ? {
            user: searchUserText,
            page: page,
            size: 20,
            admin:true
        } : {
            userType: 'user',
            page: page,
        };

        setState((prevState) => ({ ...prevState, loading: true }));

        const queryParams = querystring.stringify(params);
        const url = (searchUserText && searchUserText.length) ? `/api/search/?${queryParams}` : `/api/adminprofile/?${queryParams}`;

        callApi(url, 'GET').then((res) => {

            if (res && responseCodeValidator(String(res.code))) {

                const users = (searchUserText && searchUserText.length) ? searchDataMapper({dataList:res.results}) : res?.user_list;
                setState((prevState) => ({
                    ...prevState,
                    currentPage: page - 1,
                    users,
                    pageCount: Math.ceil(res?.count / pageMaxCount),
                    loading: false
                }));
            }else{
                setState((prevState) => ({
                    ...prevState,
                    currentPage: 0,
                    users:[],
                    pageCount: 0,
                    loading: false
                }));
            }
        });

    }

    const toggleUserStatus = ({ user }) => {

        const isUserActive = user?.user?.is_active;

        const body = {
            id: user.id,
            is_active: isUserActive ? `false` : `active`,
        }
        setState((prevState) => ({ ...prevState, loading: true }));
        callApi(`/api/adminprofile/${user.id}/`, 'PUT', body).then((res) => {
            if (res && responseCodeValidator(String(res.code))) {
                fetchUsers({ page: currentPage + 1 });
            }
        });
    }

    const debounceSearchUser = debounce(() => {
        fetchUsers({ page: 1 })
    }, 600);

    const searchUser = (searchValue) => {
        setState((prevState) => ({
            ...prevState,
            searchUserText: searchValue
        }));
    }

    useEffect(() => {
        !intialRender && debounceSearchUser();

        return () => {
            debounceSearchUser.cancel();
        };
    }, [searchUserText]);


    const closeReviewModal = () => {
        setState((prev) => ({ ...prev, isReviewModalOpen: false, reviewModalUser: null }))
    }
    
    const closeContributionModal =()=>{
        setState((prev) => ({ ...prev, isContributionModalOpen: false, contributionModalUser: null }))
    }

  return (
      <div className='main-data-container'>
          <div className="table-header">
              <h4 className='table-title'>Users</h4>
          </div>
          <div className="table-container">
              <DataTable
                  columns={columns}
                  data={Users}
                  pageCount={pageCount}
                  loading={loading}
                  fetchData={fetchUsers}
                  currentPage={currentPage}
                  handleSearch={searchUser}
                  searchText={searchUserText}
                  tab={"Users"}
              />
          </div>

          {isReviewModalOpen &&
              <UserReviews
                  isModalOpen={isReviewModalOpen}
                  toggleModal={closeReviewModal}
                  user={reviewModalUser}
              />
          }
          {isContributionModalOpen &&
              <UserContributions
                  isModalOpen={isContributionModalOpen}
                  toggleModal={closeContributionModal}
                  user={contributionModalUser}
              />
          }

      </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Users)