/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import queryString from 'query-string';
import React, { Component } from 'react';
import Geocode from "react-geocode";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import { clear_search_home, fetch_current_location, get_searchHome,add_tempLocation,browse_by_category } from "../../actions/home";
import actorIcon from "../../assets/images/icons/actors-top-search.png";
import allCatIcon from "../../assets/images/icons/all-category-top-search.png";
import businessIcon from "../../assets/images/icons/business-top-search.png";
import crpIcon from "../../assets/images/icons/crp-hover.png";
import moviesIcon from "../../assets/images/icons/movies-top-search.png";
import peopleIcon from "../../assets/images/icons/people-top-search.png";
import productsIcon from "../../assets/images/icons/products-top-search.png";
import professionalIcon from "../../assets/images/icons/professionals-top-search.png";
import brandLogo from '../../assets/images/icons/w-brand.png';
import PlacementAutoComplete from '../atoms/PlacementAutoHomeComplete';
import AppHeader from "../oragnisms/homepage/AppHeader/appHeader";
import Footer from '../oragnisms/homepage/footer';
import { setInitialAuth } from '../../actions/auth';
import axios from "axios";
import { debounce } from 'lodash';
Geocode.setApiKey("AIzaSyCcEM2DwFBE1dzZn52D1w-N_3NI_97aXMI");
Geocode.setLanguage("en");
const google = window.google
const responsive = {
    features: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1
        }
    },
    product: {
        desktop: {
            breakpoint: { max: 9999999, min: 1200 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1199, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1
        }
    }
};

class Homepage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewTrendingCity: 'la',
            content_type: "All",
            contentImage: allCatIcon,
            searchHomeData: [],
            search_name: "",
            address: '',
            enableSearch: false,
            unableToSet: true,
            isSearching: false,
			city: "",
			country : "",
			state: "",
            browseByCategoryMovies : []
        }
		this.getlocation = this.getlocation.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let { unableToSet } = this.state
       
        if (nextProps.get_search_home &&
            Array.isArray(nextProps.get_search_home.results) &&
            nextProps.get_search_home.results.length > 0 && unableToSet) {
            this.setState({
                searchHomeData: nextProps.get_search_home.results
            });
        }
		
		if(nextProps.current_location){
			this.setState({
                city: nextProps.current_location.city && nextProps.current_location.city != "undefined" ? nextProps.current_location.city : "",
                country: nextProps.current_location.country && nextProps.current_location.country != "undefined" ? nextProps.current_location.country : "",
                state: nextProps.current_location.state && nextProps.current_location.state != "undefined" ? nextProps.current_location.state : "",
            });
		}

        if(nextProps.browse_by_category_state){
            if (nextProps.browse_by_category_state?.count > 0) {
                if (nextProps.browse_by_category_state?.results) {
                    this.setState({ browseByCategoryMovies: nextProps.browse_by_category_state?.results })
                }
            }
        }

    }

    componentDidMount() {
        // this.props.fetch_current_location();
        this.props.clear_search_home();
        this.props.browse_by_category('movies');
        let { location,fetch_current_location, browse_by_category } = this.props;
        

        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                this.setState({ search_name: query })
            }
        }
        //  if (browse_by_category?.count > 0) {
        //     if (browse_by_category?.results) {
        //         this.setState({ browseByCategoryMovies: browse_by_category?.results })
        //     }
        // }

		this.getlocation();
		//this.getData();
		
    }
	
	getlocation = () => {
		let { fetch_current_location,add_tempLocation } = this.props;
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(function(position) {
				Geocode.fromLatLng(position?.coords?.latitude, position?.coords?.longitude).then(
				  (response) => {
					const address = response.results[0].formatted_address;
					let city, state, country;
					for (let i = 0; i < response.results[0].address_components.length; i++) {
					  for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
						switch (response.results[0].address_components[i].types[j]) {
						  case "locality":
							city = response.results[0].address_components[i].long_name;
							break;
						  case "administrative_area_level_1":
							state = response.results[0].address_components[i].long_name;
							break;
						  case "country":
							country = response.results[0].address_components[i].long_name;
							break;
						}
					  }
					}
					const data = {
					"city": city,
					"county": city,
					"state": state,
					"country": country,
					"locality2": "",
					"address_text": address,
					"lat": position?.coords?.latitude,
					"lng": position?.coords?.longitude
					}
					add_tempLocation(data);
				  },
				  (error) => {
					console.error(error);
				  }
				);
				// fetch_current_location();
			});
			}else{
				fetch_current_location(); 
		}
	}
	
	/*getData = async () => {
	   let ipinfo_token = "ee7d5b9297d3f3";
       await axios.get(`https://ipinfo.io/json?token=${ipinfo_token}`)
		  .then(response => {
			if(response && Object.keys(response)?.length > 0) { 
			
			let lat_long = response?.loc.split(',');
			const data = {
            "city": response?.city,
            "county": "",
            "state": response?.region,
            "country": response?.country,
            "locality2": "",
            "address_text": response?.city + ',' + response?.region + ',' + response?.country,
            "lat": lat_long[0],
            "lng": lat_long[1]
			}
			this.props.add_tempLocation(data);
			}
			
		  })
		  .catch(e => {
			console.log(e);
		  });
    
	}*/

    handleOnClickCategory = (type, ImageType) => {
        const { search_name, } = this.state
        if (type !== "") {
            this.setState({ content_type: type, contentImage: ImageType });
            if (search_name !== "") {
                this.props.get_searchHome(search_name, type);
            }
        }
        
        this.props.setInitialAuth();
    }
    debounceSearchResults = debounce((value, content_type) => {
        if (value.length) this.props.get_searchHome(value, content_type);
        else this.props.clear_search_home();
    }, 500);
    // Change State of input field	
    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        // let location = {};
        const { content_type } = this.state;
        if (name === 'search_name') {
            if (value.length) {

                this.setState(
                    {
                        ...this.state,
                        [name]: value,
                        unableToSet: true,
                    }, () => this.debounceSearchResults(value, content_type));

            } else {
                this.setState(
                    {
                        searchHomeData: [],
                        search_name: '',
                        unableToSet: false,
                        search_length: value.length,
                    }, () => {
                        this.debounceSearchResults(value, content_type);
                    });
            }
        }
    }

    handleEnabelSearch = () => {
        this.setState((prevState) => ({
            enableSearch: !prevState.enableSearch
        }))
    }



       openFullscreen=() => {
        var elem = document.getElementById("myvideo");
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      }

    render() {
        const { current_location, browse_by_category } = this.props;
        const {  searchHomeData, search_name,city,state,country, browseByCategoryMovies, contentImage,content_type } = this.state;
 
        return (
            <div>
                <AppHeader />
                <header className="masthead bg-dark text-white" onDoubleClick={this.openFullscreen}>
                    {/* The video */}
                    <video id="myvideo" className="masthead-video" nocontrols="true" autoPlay={true} muted={true} loop={true} >
                        <source src={'https://userdatawikireviews.s3.amazonaws.com/videos/mp4/seq_01_single_compressed.mp4'} type="video/mp4" />
                    </video>
                    <Container className="h-100 my-auto masthead-container">
                        <Row className="h-100 align-items-center justify-content-center text-center">
                            <Col lg={10}>
                                <div className="mb-4">
                                    <img className="img-fluid" src={brandLogo} alt="WikiReviews" />
                                </div>
                                <div>
                                    {/* Dynamic heading below */}
                                    <h2>What Happens In Vegas, Doesn't Have To Stay In Vegas!</h2>
                                </div>
                                <div className="mb-3 mb-lg-5">
                                    <h1 className=" text-white mb-3">
                                        Find &amp; Create Reviews
                                    </h1>
                                </div>
                            </Col>
                            <Col xs="auto">
                                <div className="my-3">
                                    <div className="d-flex flex-column flex-lg-row flex-wrap bg-white text-dark mx-n2 p-3">
                                        <div className="px-2 mb-2 mb-lg-0">
                                            <div className="h2 mb-0">
                                                Find
                                            </div>
                                        </div>
                                        <div className="px-2 mb-2 mb-lg-0 position-relative">
                                            <div className="d-flex flex-column flex-sm-row mx-n2">
                                                <div className="px-2 mb-2 mb-lg-0">
                                                    <div className="d-flex">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle caret color={`${contentImage === allCatIcon ? 'primary' : 'tertiary'}`} style={{':hover': { backgroundColor: `${contentImage === allCatIcon ? '#12a3b1;' : '#6f86a6;'}` }}} className="fs-16 searchToggle">
                                                            {contentImage === allCatIcon ? "All" : <img src={contentImage} width="20" alt="icon" />}
                                                        </DropdownToggle>
                                                            <DropdownMenu className="menu-white no-caret rounded-0 results-dropdown">
                                                                <DropdownItem type="all" key={"all"} onClick={() => {
                                                                    this.handleOnClickCategory("All", allCatIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={allCatIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>all categories</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="business" key={"business"} onClick={() => {
                                                                    this.handleOnClickCategory("business", businessIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={businessIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Business</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="products" key={"products"} onClick={() => {
                                                                    this.handleOnClickCategory("products", productsIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={productsIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Products</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="movies" key={"movies"} onClick={() => {
                                                                    this.handleOnClickCategory("movies", moviesIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={moviesIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Movies</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="professionals" key={"professionals"} onClick={() => {
                                                                    this.handleOnClickCategory("professionals", professionalIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={professionalIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Professionals</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="actors" key={"actors"} onClick={() => {
                                                                    this.handleOnClickCategory("actors", actorIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={actorIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>Actors</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="crp" key={"projects"} onClick={() => {
                                                                    this.handleOnClickCategory("projects", crpIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={crpIcon} width="20" alt="icon" /> */}
                                                                    results in <strong>CRP's</strong>
                                                                </DropdownItem>
                                                                {/* <DropdownItem className="my-1" divider /> */}
                                                                <DropdownItem type="people" key={"people"} onClick={() => {
                                                                    this.handleOnClickCategory("people", peopleIcon);
                                                                }}>
                                                                    {/* <img className="mr-3" src={peopleIcon} width="20" alt="icon" /> */}
                                                                    search only <strong>People</strong>
                                                                </DropdownItem>
                                                                </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        <div className="flex-fill">
                                                            <Input className="primary" type="text" placeholder="Restaurant, Movie..." name="search_name" onChange={this.handleChange} value={search_name} onFocus={() => this.setState({ isSearching: true })} />
                                                        </div>
                                                    </div>
                                                    {/* Instant Search Results */}
                                                    {this.state.isSearching ?
                                                        <ul className="text-left list-unstyled text-dark instant-results bg-white mw-100 shadow" style={{ minWidth: '400px', maxHeight: '400px', overflowY: 'auto' }}>
                                                            {/* Suggested results */}
                                                            {Array.isArray(searchHomeData) && searchHomeData && searchHomeData.length > 0 ? (searchHomeData.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {item._type == "taxonomy" && item._source.index == 0 && (
                                                                            <li>
                                                                                <div className="p-3 fs-14">
                                                                                    <div className="text-muted">
                                                                                        Are you looking for:
                                                                                </div>
                                                                                    <ul className="list-unstyled text-primary font-weight-bold" style={{ maxHeight: '105px', overflowY: 'auto' }}>
                                                                                        {Array.isArray(item._source.main_category) &&
                                                                                            item._source.main_category && item._source.main_category[0].length > 0 ?
                                                                                            (item._source.main_category[0][0].split(",").map((item2, index2) => {
                                                                                                return (
                                                                                                    <li key={index2} onClick={() => {
                                                                                                        this.setState({
                                                                                                            isSearching: false,
                                                                                                            search_name: item2.replace(/\#.+/g, "$'")
                                                                                                        })
                                                                                                    }}>
                                                                                                        <Link className="text-reset ff-base"
                                                                                                            to={{
                                                                                                                pathname: `/searchOut`,
                                                                                                                search: `?q=${item2.replace(/\#.+/g, "$'")}`,
                                                                                                                state: { contenttype: this.state.content_type.toLowerCase() }
                                                                                                            }}>{item2.replace(/\#.+/g, "$'")} </Link>
                                                                                                    </li>
                                                                                                )
                                                                                            })) : (null)
                                                                                        }
                                                                                    </ul>
                                                                                </div>

                                                                            </li>
                                                                        )}
                                                                        {/* featured results */}
                                                                        {/* featured results */}
                                                                        {item._type == "listing" && (
                                                                            <Link
                                                                                to={{
                                                                                    pathname: item._source?.professional_type ?
                                                                                        `/${(item._source.professional_type).toLowerCase()}/${item._source?.id}`
                                                                                        : `/${(item._source.content_type).toLowerCase()}/${item._source.slug}`,
                                                                                    state: { id: item._source.id }
                                                                                }}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        isSearching: false,
                                                                                    })
                                                                                }}
                                                                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none">
                                                                                <li className="result-item" role="button" >

                                                                                    <div className="result-link fs-14">
                                                                                        <div className="d-flex">
                                                                                            <div className="mr-3">
                                                                                                <img
                                                                                                    src={

                                                                                                        item._source && item._source.listing_profileimage &&
                                                                                                            item._source.listing_profileimage[0] &&
                                                                                                            item._source.listing_profileimage[0].thumbnail ?
                                                                                                            item._source.listing_profileimage[0].thumbnail :
                                                                                                            "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"} width={50} alt=""
                                                                                                    onError={(error) =>
                                                                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div>

                                                                                                <span className="font-weight-bold">
                                                                                                    {item._source ? item._source.name : ""}
                                                                                                </span>
                                                                                                <div>
                                                                                                    <img src={(item._source?.total_rating && item._source?.total_rating?.avg_rating) ? `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item._source?.total_rating?.avg_rating[0]}` : `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png`} alt="" title={(item._source?.total_rating && item._source?.total_rating?.rating_count) ? item._source?.total_rating?.rating_count : 0} />
                                                                                                </div>
                                                                                                <div className="font-weight-normal lh-1-2">
                                                                                                    {item._source && item._source.category != null ? item._source.category.split(',').join(', ') : ""}
                                                                                                    {/* <span>{item._source && item._source.category != null ? item._source.category : ""}</span> */}
                                                                                                </div>
                                                                                                <div className="text-tertiary font-weight-normal mt-1 ff-alt fs-12">
                                                                                                    {item?._source?.address?.length ?
                                                                                                        `${item?._source?.address[0] && item?._source?.address[0].address1 ? item?._source?.address[0].address1 + ',' : ''} ${item?._source?.address[0] && item?._source?.address[0].address2 ? item?._source?.address[0].address2 + ',' : ''}`
                                                                                                        : null}
                                                                                                    <br />
                                                                                                    {item?._source?.address?.length ?
                                                                                                        `${item?._source?.address[0] && item?._source?.address[0].city ? item?._source?.address[0].city + ',' : ''} ${item?._source?.address[0] && item?._source?.address[0].state ? item?._source?.address[0].state + ',' : ''} ${item?._source?.address[0] && item?._source?.address[0].zipcode ? item?._source?.address[0].zipcode : ''}`
                                                                                                        : null}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </li>
                                                                            </Link>
                                                                        )
                                                                        }

                                                                        {/* featured results */}
                                                                        {item._type == "user" && (
                                                                            <Link
                                                                                to={{
                                                                                    pathname: `/people/${item._source.user?.username}`,
                                                                                    state: { id: item._source._id }
                                                                                }}
                                                                                onClick={() => {
                                                                               
                                                                                }}
                                                                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none">
                                                                                <li className="result-item" role="button" >

                                                                                    <div className="result-link fs-14">
                                                                                        <div className="d-flex">
                                                                                            <div className="mr-3">
                                                                                                <img src={item._source && item._source.current_profile_pic ? item._source.current_profile_pic : item._source.current_profile_pic && item._source.current_profile_pic.length > 0 ? item._source.current_profile_pic.thumbnail : require('../../assets/images/icons/placeholder-img.png')
                                                                                                } width={50}
                                                                                                    onError={(error) =>
                                                                                                        (error.target.src = require('../../assets/images/icons/placeholder-img.png'))
                                                                                                    }
                                                                                                    alt="" />

                                                                                            </div>
                                                                                            <div>

                                                                                                <span className="font-weight-bold">
                                                                                                    {item._source ? item._source.name : ""}
                                                                                                </span>

                                                                                                <div className="font-weight-normal fs-12 ff-alt text-tertiary">
                                                                                                    <br />
                                                                                                    <span>
                                                                                                        {item._source && item._source?.statistics ? item._source.statistics.reviews : "0"} reviews
                                                                                                    </span>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </li>
                                                                            </Link>
                                                                        )
                                                                        }
                                                                    </div>
                                                                )
                                                            })) : (null)
                                                            }
                                                        </ul>
                                                        : ""}
                                                </div>
                                                <div className="px-2 mb-2 mb-lg-0 d-flex">
                                                    <div>
                                                        <div className="btn bg-dark text-white fs-16 cursor-default">
                                                            Near
                                                        </div>
                                                    </div>
                                                    <div className="flex-fill">
                                                        <PlacementAutoComplete
                                                            get_search_home={this.props.get_searchHome}
                                                            content_type={content_type}
                                                            search_name={search_name}
                                                            handleEnabelSearch={this.handleEnabelSearch}
                                                            current_location={city != "" ? city + ', ' + state + ', ' + country : ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            {search_name != "" ?
                                                (
                                                    // <Button color="grey" size="sm">
                                                    <Link to={"/searchOut?q=" + search_name} className="btn btn-primary btn-block mw fs-16 font-weight-bold">SEARCH </Link>

                                                    // </Button>
                                                )
                                                :
                                                (<Button color="primary" className="mw fs-16 font-weight-bold" block>SEARCH</Button>)
                                            }

                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="10">
                                <div className="mt-3">
                                    <ul className="list-unstyled text-white font-weight-bold d-flex flex-wrap mx-2 mx-lg-n4 justify-content-center">
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="#">Business</a>
                                        </li>
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="#">Movies</a>
                                        </li>
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="#">Products</a>
                                        </li>
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="#">Professionals</a>
                                        </li>
                                        <li className="px-2 px-lg-4">
                                            <a className="text-reset text-decoration-none ff-base" href="#">Actors</a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </header>
                <section className="text-center page-section bg-white">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="10">
                                <h2 className="section-title">
                                    Trusted recommendations from your community
                                </h2>
                                <Row className="mb-3" xs={1} md={2} xl={4}>
                                    <Col className="mb-3 text-center">
                                        <div className="caption-block">
                                            <img className="caption-img" src={require('../../assets/images/schools.jpg')} alt="" />
                                            <a href="#" className="stretched-link">
                                                <span className="caption-heading bottom filter">Schools</span>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col className="mb-3 text-center">
                                        <div className="caption-block">
                                            <img className="caption-img" src={require('../../assets/images/movies.jpg')} alt="" />
                                            <a href="#" className="stretched-link">
                                                <span className="caption-heading bottom filter">Movies</span>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col className="mb-3 text-center">
                                        <div className="caption-block">
                                            <img className="caption-img" src={require('../../assets/images/restaurants.jpg')} alt="" />
                                            <a href="#" className="stretched-link">
                                                <span className="caption-heading bottom filter">Restaurants</span>
                                            </a>
                                        </div>
                                    </Col>
                                    <Col className="mb-3 text-center">
                                        <div className="caption-block">
                                            <img className="caption-img" src={require('../../assets/images/gyms.jpg')} alt="" />
                                            <a href="#" className="stretched-link">
                                                <span className="caption-heading bottom filter">Gyms</span>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            <Col xs="12">

                                {/* Carousel area starts*/}
                                <Carousel
                                    responsive={responsive.features}
                                    infinite={true}
                                    autoPlay={false}
                                    swipeable={true}
                                    showDots={true}
                                    arrows={false}
                                    autoPlaySpeed={3000}
                                    dotListClass="type-secondary"
                                    containerClass="main-site-carousel">
                                    <div className="item">
                                        <div className="px-3">
                                            <h3 className="section-subtitle mx-auto" style={{ maxWidth: '500px' }}>
                                                WIKIREVIEWS IS THE NEW STANDARD IN ONLINE REVIEWS. 100% FREE RECOMMENDATIONS FROM A COMMUNITY YOU TRUST.
                                            </h3>
                                            <div className="mx-auto" style={{ maxWidth: '900px' }}>
                                                <Row xs={1} lg={3}>
                                                    <Col>
                                                        <div className="mb-4 mx-auto" style={{ maxWidth: '400px' }}>
                                                            <div className="text-center mb-4">
                                                                <img className="fixed-height-img" src={require('../../assets/images/icons/eye.png')} alt="Reading" />
                                                            </div>
                                                            <h4 className="review-heading">Read Reviews</h4>
                                                            <p className="review-desc">
                                                                Are you tired of fake reviews? Empower yourself by learning from the experiences of your personal network and the WikiReviews community at large. No more anonymous. We're 100% real!
                                                        </p>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-4 mx-auto" style={{ maxWidth: '400px' }}>
                                                            <div className="text-center mb-4">
                                                                <img className="fixed-height-img" src={require('../../assets/images/icons/hand.jpg')} alt="Writing" />
                                                            </div>
                                                            <h4 className="review-heading">Create Reviews</h4>
                                                            <p className="review-desc">
                                                                Have something to say? Share personal experiences with friends, family, and a committed online community. Vote excellence to the top and hold poor quality accountable. The power is in your hands.
                                                        </p>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-4 mx-auto" style={{ maxWidth: '400px' }}>
                                                            <div className="text-center mb-4">
                                                                <img className="fixed-height-img" src={require('../../assets/images/icons/star.jpg')} alt="Read Reviews" />
                                                            </div>
                                                            <h4 className="review-heading">Get your own projects reviewed</h4>
                                                            <p className="review-desc">
                                                                Launching a new project? Get insight, tips, and feedback from a passionate community of critics and connoisseurs. Upload anything and everything, from business plans to songs to screenplays and more!
                                                        </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <video controls={true} autoPlay={false} muted={false} loop={false} poster={require('../../assets/images/find-out-how.jpg')} style={{ width: '100%', maxHeight: 'unset' }}>
                                            <source src={'https://userdatawikireviews.s3.amazonaws.com/videos/mp4/WhatWikiReviewsHD.mp4'} type="video/mp4" />
                                        </video>
                                    </div>
                                    <div className="item">
                                        <div className="px-3">
                                            <h3 className="text-dark display-3 mb-4">
                                                HOW WE ARE DIFFERENT
                                            </h3>

                                            <div className="mx-auto" style={{ maxWidth: '900px' }}>
                                                <Row xs={1} sm={2} lg={4} className="justify-content-center text-center">
                                                    <Col className="mb-4">
                                                        <div className="custom-tooltip-container">
                                                            <div className="text-wrap mb-4">
                                                                <img className="fixed-height-img" src={'https://userdatawikireviews.s3.amazonaws.com/images/video-reviews.png'} alt="" title="" />
                                                            </div>
                                                            <p className="fs-14 text-brown">
                                                                Write or dictate (with speech to text) fantastic reviews. You can even record your own video reviews! Your Reviews. Your Site.
                                                            </p>
                                                            <div className="custom-tooltip">
                                                                <p>
                                                                    Why settle for generic? We give you
                                                                    the tools you need to craft perfectly personalized reviews.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-4">
                                                        <div className="custom-tooltip-container">
                                                            <div className="text-wrap mb-4">
                                                                <img className="fixed-height-img" src={'https://userdatawikireviews.s3.amazonaws.com/images/creative-review.png'} alt="" title="" />
                                                            </div>
                                                            <p className="fs-14 text-brown">
                                                                A business is the sum of its employees. Great experience with a particular employee? Give honor where it's due. Not so much? Sound off.
                                                            </p>
                                                            <div className="custom-tooltip">
                                                                <p>
                                                                    Be a part of the revolution and join the first site
                                                                    where you get the chance to review individual employees.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-4">
                                                        <div className="custom-tooltip-container">
                                                            <div className="text-wrap mb-4">
                                                                <img className="fixed-height-img" src={'https://userdatawikireviews.s3.amazonaws.com/images/wiki-community.png'} alt="" title="" />
                                                            </div>
                                                            <p className="fs-14 text-brown">
                                                                Tired of useless reviews that skew results? Vote with the community to eliminate trash and highlight treasure. The power is in your hands.
                                                            </p>
                                                            <div className="custom-tooltip">
                                                                <p>
                                                                    Tired of fake reviewers who eliminate review credibility?
                                                                    Join the first review site where you decide what should
                                                                    happen to the review!
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-4">
                                                        <div className="custom-tooltip-container">
                                                            <div className="text-wrap mb-4">
                                                                <img className="fixed-height-img" src={'https://userdatawikireviews.s3.amazonaws.com/images/view-overall.png'} alt="" title="" />
                                                            </div>
                                                            <p className="fs-14 text-brown">
                                                                Go deeper than a handful of stars. WikiReviews offers multiple review categories so you can find 5-star quality in a 2-star atmosphere.
                                                            </p>
                                                            <div className="custom-tooltip">
                                                                <p>
                                                                    Summing up a complex experience with a single rating
                                                                    doesn't make sense. We let you peel back the layers and go deep.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-4">
                                                        <div className="custom-tooltip-container">
                                                            <div className="text-wrap mb-4">
                                                                <img className="fixed-height-img" src={'https://userdatawikireviews.s3.amazonaws.com/images/code-review.png'} alt="" title="" />
                                                            </div>
                                                            <p className="fs-14 text-brown">
                                                                Take us with you on the go! Use the WikiReviews mobile app to scan product UPC codes for instant access to the reviews you need.
                                                            </p>
                                                            <div className="custom-tooltip">
                                                                <p>
                                                                    No need to stop, search, and scroll. Simply point and scan.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-4">
                                                        <div className="custom-tooltip-container">
                                                            <div className="text-wrap mb-4">
                                                                <img className="fixed-height-img" src={'https://userdatawikireviews.s3.amazonaws.com/images/binoculars.png'} alt="" title="" />
                                                            </div>
                                                            <p className="fs-14 text-brown">
                                                                Planning a future purchase? Our Watchlist feature sends automatic updates every time your selected items receive a new review.
                                                            </p>
                                                            <div className="custom-tooltip">
                                                                <p>
                                                                    There's no need to constantly check
                                                                    up on the things you care about.
                                                                    Our advanced system sends new reviews and
                                                                    updates straight to you!
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-4">
                                                        <div className="custom-tooltip-container">
                                                            <div className="text-wrap mb-4">
                                                                <img className="fixed-height-img" src={'https://userdatawikireviews.s3.amazonaws.com/images/favorites.png'} alt="" title="" />
                                                            </div>
                                                            <p className="fs-14 text-brown">
                                                                Highlight the best of the best with your personal Favorites list. Share your discoveries with friends, and find hidden gems in your area.
                                                            </p>
                                                            <div className="custom-tooltip">
                                                                <p>
                                                                    Add Favorites to have easy access
                                                                    to the listing and share with your friends what you love.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-4">
                                                        <div className="custom-tooltip-container">
                                                            <div className="text-wrap mb-4">
                                                                <img className="fixed-height-img" src={'https://userdatawikireviews.s3.amazonaws.com/images/mail.png'} alt="" title="" />
                                                            </div>
                                                            <p className="fs-14 text-brown">
                                                                There's no need to constantly check up on your items of interest. Our advanced system sends new reviews straight to your inbox!
                                                            </p>
                                                            <div className="custom-tooltip">
                                                                <p>
                                                                    Don't want to login? No problem. Add listings to
                                                                    your Watchlist and never lose track of what you are following!
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="bg-dark text-white p-3 p-lg-5 wikiFactor">
                                            <div className="mx-auto" style={{ maxWidth: '900px' }}>
                                                <h3 className="text-white text-uppercase display-3 mb-4">
                                                    The Wiki Factor
                                                </h3>
                                                <div className="white-separators">
                                                    <span className="repeat-star"></span>
                                                </div>
                                                <div className="text-shadow ff-base font-weight-bold">
                                                    <p>A "Wiki" allows anyone and everyone to add, modify, or delete content in collaboration with others, creating a site based entirely on YOUR contributions. </p>
                                                    <p>With you in charge, the possibilities are endless.</p>
                                                    <p>What happens when two people disagree?</p>
                                                    <p>In other Wikis, disputes turn into back and forth battles, opinion against opinion, where nobody wins -OR -moderators take over, removing authority from users.</p>
                                                    <p>At WikiReviews, we take content disputes directly into community review. This means authority stays with YOU, the user, 100% of the time!</p>
                                                    <p>What's possible on WikiReviews? ANYTHING!</p>
                                                    <p>Rate movies, vote on products and review local services. Give genuine feedback to owners wanting to offer you a better customer experience.</p>
                                                    <p>Provide tips, offer warnings, suggest new categories or update listings. Flag fraudulent content or users attempting to undermine the review system.</p>
                                                    <p>The Wiki Factor means our site is yours to command. However. Whenever.</p>
                                                    <p>Improve Questions and Answers asked by the community.</p>
                                                    <p>Create and Validate New Listings.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="body-bg p-3 p-lg-5">
                                            <div className="mx-auto" style={{ maxWidth: '900px' }}>

                                                <h3 className="text-brown display-4 mb-4">
                                                    Get your own projects reviewed.
                                                </h3>

                                                <Row xs={1} md={2} className="justify-content-center text-center">
                                                    <Col className="mb-md-3">
                                                        <div className="p-4">
                                                            <div className="mb-2">
                                                                <span className="counter-circle mx-auto ff-base brown">1</span>
                                                            </div>
                                                            <div className="project-review-heading text-brown">
                                                                UPLOAD "BEFORE" AND "AFTER" IMAGES AND RECEIVE SUPPORT FROM YOUR PEERS.
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-md-3">
                                                        <div className="p-4">
                                                            <div className="mb-2">
                                                                <span className="counter-circle mx-auto ff-base blue">2</span>
                                                            </div>
                                                            <div className="project-review-heading text-blue">
                                                                POST PERFORMANCE VIDEOS FOR COMMUNITY FEEDBACK AND REVIEW.
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-md-3">
                                                        <div className="p-4">
                                                            <div className="mb-2">
                                                                <span className="counter-circle mx-auto ff-base blue">3</span>
                                                            </div>
                                                            <div className="project-review-heading text-blue">
                                                                LIST EVERYTHING FROM ARTWORK TO BUSINESS PLAN FOR FEEDBACK AND REVIEW.
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-md-3">
                                                        <div className="p-4">
                                                            <div className="mb-2">
                                                                <span className="counter-circle mx-auto ff-base brown">4</span>
                                                            </div>
                                                            <div className="project-review-heading text-brown">
                                                                CREATE YOUR OWN QUESTIONS FOR COMMUNITY ANSWERS, VOTES AND RATINGS.
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-md-3">
                                                        <div className="p-4">
                                                            <div className="mb-2">
                                                                <span className="counter-circle mx-auto ff-base brown">5</span>
                                                            </div>
                                                            <div className="project-review-heading text-brown">
                                                                USE CUSTOM STATISTICAL CONFIDENCE LEVELS FOR MORE ACCURATE DATA ANALYSIS.
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-md-3">
                                                        <div className="p-4">
                                                            <div className="mb-2">
                                                                <span className="counter-circle mx-auto ff-base blue">6</span>
                                                            </div>
                                                            <div className="project-review-heading text-blue">
                                                                TARGET YOUR PROJECT'S EXPOSURE WITH OUR DEMOGRAPHIC SEGMENTATION TOOLS.
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="mb-md-3">
                                                        <div className="p-4">
                                                            <div className="mb-2">
                                                                <span className="counter-circle mx-auto ff-base blue">7</span>
                                                            </div>
                                                            <div className="project-review-heading text-blue">
                                                                KICKSTART YOUR CAMPAIGN THROUGH SOCIAL MEDIA OUTREACH AND NETWORKING.
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                    </div>
                                </Carousel>
                                {/* Carousel area ends */}
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="10">
                                <div className="bg-light-blue p-3">
                                    <h2 className="section-title mt-2">YOU JUST LOOKED AT THESE LISTINGS</h2>
                                    <Row className="mb-3" xs={1} md={3}>
                                        <Col className="mb-3 text-center">
                                            <div className="caption-block">
                                                <img className="caption-img" src={require('../../assets/images/schools.jpg')} alt="" />
                                                <a href="#" className="stretched-link">
                                                    <span className="caption-heading bottom filter">Listing 1</span>
                                                </a>
                                            </div>
                                        </Col>
                                        <Col className="mb-3 text-center">
                                            <div className="caption-block">
                                                <img className="caption-img" src={require('../../assets/images/movies.jpg')} alt="" />
                                                <a href="#" className="stretched-link">
                                                    <span className="caption-heading bottom filter">Listing 2</span>
                                                </a>
                                            </div>
                                        </Col>
                                        <Col className="mb-3 text-center">
                                            <div className="caption-block">
                                                <img className="caption-img" src={require('../../assets/images/restaurants.jpg')} alt="" />
                                                <a href="#" className="stretched-link">
                                                    <span className="caption-heading bottom filter">Listing 3</span>
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center justify-content-center">
                                        <Col xs="12" md="auto">
                                            <div className="text-center text-sm-left mb-sm-3 mb-md-0 d-flex flex-column flex-sm-row align-items-center mx-n3">
                                                <div className="px-3">
                                                    <img src={require('../../assets/images/icons/hand-rating.png')} alt="" />
                                                </div>
                                                <div className="px-3">
                                                    <h3 className="text-dark fs-40">
                                                        Create Your Next Review
                                                    </h3>
                                                    <p className="ff-base text-black font-weight-bold fs-14">
                                                        Help the Wikireviews community <br /> sharing your experiences
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs="12" md="auto">
                                            <div className="px-lg-4">
                                                <Button color="primary" size="lg" block className="font-weight-bold">START REVIEWING NOW</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="mt-5">
                                    <div className="horizontal-bar">
                                        <div className="text-center bg-white p-4 mx-auto">
                                            <img src={require('../../assets/images/icons/wiki-blue.png')} alt="Wiki" />
                                            <div className="ff-headings fs-40 text-dark">Las Vegas</div>
                                        </div>
                                    </div>

                                    <Nav className="non-decorated justify-content-center" tabs>
                                        <NavItem>
                                            <NavLink
                                                active={this.state.viewTrendingCity === "la"}
                                                onClick={() => {
                                                    this.setState({ viewTrendingCity: "la" })
                                                }}
                                            >
                                                LOS ANGELES
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                active={this.state.viewTrendingCity === "sd"}
                                                onClick={() => {
                                                    this.setState({ viewTrendingCity: "sd" })
                                                }}
                                            >
                                                SAN DIEGO
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                active={this.state.viewTrendingCity === "sf"}
                                                onClick={() => {
                                                    this.setState({ viewTrendingCity: "sf" })
                                                }}
                                            >
                                                SAN FRANCISCO
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                active={this.state.viewTrendingCity === "ny"}
                                                onClick={() => {
                                                    this.setState({ viewTrendingCity: "ny" })
                                                }}
                                            >
                                                NEW YORK
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                active={this.state.viewTrendingCity === "more"}
                                                onClick={() => {
                                                    this.setState({ viewTrendingCity: "more" })
                                                }}
                                            >
                                                Search More Cities
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={this.state.viewTrendingCity}>
                                        <TabPane tabId="la">
                                            <h2 className="section-title">
                                                Trending Now in Los Angeles
                                            </h2>
                                            <Row xs={1} md={3}>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-business.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Business</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-movie.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Movie</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-product.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Product</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="sd">
                                            <h2 className="section-title">
                                                Trending Now in San Diego
                                            </h2>
                                            <Row xs={1} md={3}>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-business.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Business</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-movie.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Movie</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-product.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Product</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="sf">
                                            <h2 className="section-title">
                                                Trending Now in San Francisco
                                            </h2>
                                            <Row xs={1} md={3}>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-business.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Business</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-movie.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Movie</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-product.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Product</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="ny">
                                            <h2 className="section-title">
                                                Trending Now in Ney York
                                            </h2>
                                            <Row xs={1} md={3}>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-business.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Business</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-movie.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Movie</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-product.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Product</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="more">
                                            <h2 className="section-title">
                                                Trending Now in other cities
                                            </h2>
                                            <Row xs={1} md={3}>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-business.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Business</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-movie.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Movie</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                                <Col className="mb-3 text-center">
                                                    <div className="caption-block filter">
                                                        <img className="caption-img" src={require('../../assets/images/hot-product.jpg')} alt="" />
                                                        <a href="#" className="stretched-link">
                                                            <span className="caption-heading text-uppercase center">Product</span>
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="page-section">
                    <Container>
                        <div className="text-center">
                            <h2 className="section-title text-secondary">Latest Reviews</h2>
                            <ul className="list-unstyled text-dark font-weight-bold fs-18 d-flex flex-wrap mx-2 mx-lg-n4 justify-content-center mb-4">
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link text-dark p-0 filterable ">All</button>
                                </li>
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link text-dark p-0 filterable">Business</button>
                                </li>
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link text-dark p-0 filterable">Movies</button>
                                </li>
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link text-dark p-0 filterable">Products</button>
                                </li>
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link text-dark p-0 filterable">Professionals</button>
                                </li>
                                <li className="px-2 px-lg-4">
                                    <button className="btn btn-link text-dark p-0 filterable">Actors</button>
                                </li>
                            </ul>
                        </div>

                        {/* Carousel ends */}
                        <Carousel
                            responsive={responsive.product}
                            infinite={true}
                            autoPlay={true}
                            swipeable={true}
                            showDots={true}
                            arrows={false}
                            autoPlaySpeed={3000}
                            itemClass="px-2"
                            dotListClass="type-secondary"
                            containerClass="pb-5">
                            <Card className="p-0 bg-transparent h-100 ff-base">
                                <div className="bg-white p-2 flex-fill d-flex flex-column">
                                    <CardImg className="review-img" top width="100%" src={require('../../assets/images/restaurants.jpg')} alt="Card image cap" />
                                    <CardBody className="px-0 py-2 d-flex flex-column flex-fill">
                                        <CardTitle className="mb-2 text-tertiary font-weight-bold">Restaurant Name</CardTitle>
                                        <CardText className="text-dark fs-14 ff-base">
                                            I came in here today to make a deposit. There was a lime about 6 people long and 2 tellers available. It took about 10 minutes to get to the front. I made my deposit and was given my balance after...
                                                <span className="d-block">
                                                <a className="text-reset" href="#">
                                                    Continue reading
                                                    </a>
                                            </span>
                                        </CardText>
                                        <div className="mt-auto">
                                            <img title="5 Star" className="img-fluid" src={require('../../assets/images/icons/5rating.png')} alt="5 Star" />
                                        </div>
                                    </CardBody>
                                </div>

                                <div className="d-flex align-items-center mx-n2 py-3">
                                    <div className="px-2">
                                        <img className="user-avatar" src={require('../../assets/images/icons/user-avatar.png')} alt="" />
                                    </div>
                                    <div className="px-2">
                                        <div className="text-dark font-weight-bold">
                                            Todd Toddler
                                            </div>
                                    </div>
                                </div>
                            </Card>
                            <Card className="p-0 bg-transparent h-100 ff-base">
                                <div className="bg-white p-2 flex-fill d-flex flex-column">
                                    <CardImg className="review-img" top width="100%" src={require('../../assets/images/hot-movie.jpg')} alt="Card image cap" />
                                    <CardBody className="px-0 py-2 d-flex flex-column flex-fill">
                                        <CardTitle className="mb-2 text-tertiary font-weight-bold">Movie Name</CardTitle>
                                        <CardText className="text-dark fs-14 ff-base">
                                            I came in here today to make a deposit. There was a lime about 6 people long and 2 tellers available. It took about 10 minutes to get to the front. I made my deposit and was given my balance after...
                                                <span className="d-block">
                                                <a className="text-reset" href="#">
                                                    Continue reading
                                                    </a>
                                            </span>
                                        </CardText>
                                        <div className="mt-auto">
                                            <img title="3 Star" className="img-fluid" src={require('../../assets/images/icons/3rating.png')} alt="3 Star" />
                                        </div>
                                    </CardBody>
                                </div>

                                <div className="d-flex align-items-center mx-n2 py-3">
                                    <div className="px-2">
                                        <img className="user-avatar" src={require('../../assets/images/icons/user-avatar.png')} alt="" />
                                    </div>
                                    <div className="px-2">
                                        <div className="text-dark font-weight-bold">
                                            Dian Johnson
                                            </div>
                                    </div>
                                </div>
                            </Card>
                            <Card className="p-0 bg-transparent h-100 ff-base">
                                <div className="bg-white p-2 flex-fill d-flex flex-column">
                                    <CardImg className="review-img" top width="100%" src={require('../../assets/images/hot-business.jpg')} alt="Card image cap" />
                                    <CardBody className="px-0 py-2 d-flex flex-column flex-fill">
                                        <CardTitle className="mb-2 text-tertiary font-weight-bold">Business Name</CardTitle>
                                        <CardText className="text-dark fs-14 ff-base">
                                            I came in here today to make a deposit. There was a lime about 6 people long and 2 tellers available. It took about 10 minutes to get to the front. I made my deposit and was given my balance after...
                                                <span className="d-block">
                                                <a className="text-reset" href="#">
                                                    Continue reading
                                                    </a>
                                            </span>
                                        </CardText>
                                        <div className="mt-auto">
                                            <img title="5 Star" className="img-fluid" src={require('../../assets/images/icons/5rating.png')} alt="5 Star" />
                                        </div>
                                    </CardBody>
                                </div>

                                <div className="d-flex align-items-center mx-n2 py-3">
                                    <div className="px-2">
                                        <img className="user-avatar" src={require('../../assets/images/icons/user-avatar.png')} alt="" />
                                    </div>
                                    <div className="px-2">
                                        <div className="text-dark font-weight-bold">
                                            Mike Whindam
                                            </div>
                                    </div>
                                </div>
                            </Card>
                            <Card className="p-0 bg-transparent h-100 ff-base">
                                <div className="bg-white p-2 flex-fill d-flex flex-column">
                                    <CardImg className="review-img" top width="100%" src={require('../../assets/images/hot-product.jpg')} alt="Card image cap" />
                                    <CardBody className="px-0 py-2 d-flex flex-column flex-fill">
                                        <CardTitle className="mb-2 text-tertiary font-weight-bold">Product Name</CardTitle>
                                        <CardText className="text-dark fs-14 ff-base">
                                            I came in here today to make a deposit. There was a lime about 6 people long and 2 tellers available. It took about 10 minutes to get to the front. I made my deposit and was given my balance after...
                                                <span className="d-block">
                                                <a className="text-reset" href="#">
                                                    Continue reading
                                                    </a>
                                            </span>
                                        </CardText>
                                        <div className="mt-auto">
                                            <img title="4 Star" className="img-fluid" src={require('../../assets/images/icons/4rating.png')} alt="4 Star" />
                                        </div>
                                    </CardBody>
                                </div>

                                <div className="d-flex align-items-center mx-n2 py-3">
                                    <div className="px-2">
                                        <img className="user-avatar" src={require('../../assets/images/icons/user-avatar.png')} alt="" />
                                    </div>
                                    <div className="px-2">
                                        <div className="text-dark font-weight-bold">
                                            Neal Armstrong
                                            </div>
                                    </div>
                                </div>
                            </Card>
                        </Carousel>
                        {/* Carousel ends */}

                    </Container>
                </section>

                {/*Browse by Category*/}
                <section className="page-section text-center bg-white">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={10}>
                                <h2 className="section-title">
                                    Browse by Category
                                </h2>

                                {/* Business Category */}
                                <div className="mb-5">
                                    <div className="category-heading" data-category="businesses">
                                        Businesses
                                    </div>
                                    <Row xs={2} sm={3} md={4} xl={5} className="mt-4">
                                        {/*{Array.isArray(item._source.main_category) &&*/}
                                        {/*item._source.main_category && item._source.main_category[0].length > 0 ?*/}
                                        {/*    (item._source.main_category[0][0].split(",").map((item2, index2) => {*/}
                                        {/*        return (*/}
                                        {/*            <li key={index2}>*/}
                                        {/*                <a className="text-reset ff-base" href={"/search?q=" + item2.replace(/\#.+/g, "$'")}>*/}
                                        {/*                    {item2.replace(/\#.+/g, "$'")}*/}
                                        {/*                </a>*/}
                                        {/*            </li>*/}
                                        {/*        )*/}
                                        {/*    })) : (null)*/}
                                        {/*}*/}
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/business-sm-01.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/business-sm-02.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/business-sm-03.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/business-sm-04.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/business-sm-05.jpg')} alt="" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Movies Category */}
                                <div className="mb-5">
                                    <div className="category-heading" data-category="movies">
                                        Movies
                                    </div>

                                    <Row xs={2} sm={3} md={4} xl={5} className="mt-4">
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/movie-sm-01.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/movie-sm-02.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/movie-sm-03.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/movie-sm-04.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/movie-sm-05.jpg')} alt="" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Products Category */}
                                <div className="mb-5">
                                    <div className="category-heading" data-category="products">
                                        Products
                                    </div>

                                    <Row xs={2} sm={3} md={4} xl={5} className="mt-4">
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/product-sm-01.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/product-sm-02.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/product-sm-03.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/product-sm-04.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/product-sm-05.jpg')} alt="" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Professionals Category */}
                                <div className="mb-5">
                                    <div className="category-heading" data-category="professionals">
                                        Professionals
                                    </div>

                                    <Row xs={2} sm={3} md={4} xl={5} className="mt-4">
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/professional-sm-01.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/professional-sm-02.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/professional-sm-03.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/professional-sm-04.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/professional-sm-05.jpg')} alt="" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Actors Category */}
                                <div>
                                    <div className="category-heading" data-category="actors">
                                        Actors
                                    </div>
                                    <Row xs={2} sm={3} md={4} xl={5} className="mt-4">
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/actor-sm-01.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/actor-sm-02.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/actor-sm-03.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/actor-sm-04.jpg')} alt="" />
                                            </div>
                                        </Col>
                                        <Col className="mb-4">
                                            <div>
                                                <img className="fixed-height-img is-120 full-width" src={require('../../assets/images/actor-sm-05.jpg')} alt="" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Footer />
                {this.state.isSearching ?
                    <div className="search-mask" onClick={() => this.setState({ isSearching: false })}>&nbsp;</div>
                    : ''
                }
            </div>
        )
    }
}

const mapState = (state) => ({
    current_location: state.home.current_location,
    get_search_home: state.home.get_search_home,
    browse_by_category_state: state.home.browse_by_category?.results,
});

const mapProps = (dispatch) => ({
    fetch_current_location: () => dispatch(fetch_current_location()),
    get_searchHome: (query, contentType) => dispatch(get_searchHome(query, contentType)),
    clear_search_home: () => dispatch(clear_search_home()),
    setInitialAuth: () => dispatch(setInitialAuth()),
	add_tempLocation: (data) => dispatch(add_tempLocation(data)),
    browse_by_category: (data) => dispatch(browse_by_category(data)),
});
export default connect(mapState, mapProps)(Homepage);
