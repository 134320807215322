import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { auth_logout_request } from '../../actions/auth';

export const AdminSidebar = ({activeTab, toggleTab}) => {

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(auth_logout_request({ logoutToHome: true }))
    } 

  return (
      <div className='admin-sidebar'>
          <div className="sidebarBrand">
              <h4 className='navbar-brand'>Wiki Admin</h4>
          </div>
          <hr />
          <div className="sidebarWrapper">
              <div className="sidebarMenu">
                  <ul className="sidebarList">
                      <li className={`sidebarListItem ${activeTab == "users" ? "active" : ""}`} onClick={() => toggleTab("users")}>
                          Users
                      </li>
                  </ul>
              </div>
              <div className="sidebarMenu">
                  <h3 className="sidebarTitle">Reviews</h3>
                  <ul className="sidebarList">
                      <li className={`sidebarListItem ${activeTab =="products" ? "active" :"" }`} onClick={() => toggleTab("products")}>
                          Products
                      </li>
                      <li className={`sidebarListItem ${activeTab =="business" ? "active" :"" }`} onClick={() => toggleTab("business")}>
                          Business
                      </li>
                      <li className={`sidebarListItem ${activeTab =="movies" ? "active" :"" }`} onClick={() => toggleTab("movies")}>
                          Movies
                      </li>
                  </ul>
              </div>
          </div>
          <hr />
          <div className="sidebarWrapper mt-0 pt-0">
          <div className="sidebarMenu">
                  <ul className="sidebarList">
                      <li className={`sidebarListItem ${activeTab == "logout" ? "active" : ""}`} onClick={() => toggleTab("users")}>
                          <span onClick={()=>{handleLogout()}}>
                              Logout
                          </span>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebar)