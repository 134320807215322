import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Progress,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import {
  media_upload_scroll,
  fetch_media_data,
  clear_media_dispatch,
  upload_add_captions,
  fetch_taxonomy_list,
} from "../../../actions/myProfiles";

import {
  upload_media,
  initFetchMovieSubCategories,
  search_moviesMembers
} from "../../../actions/footer";

import {
  add_movie
} from "../../../actions/movies";

import {
  get_album_types_list,
  get_album_type_data,
  delete_selected_gallery_media
} from "../../../actions/user";
import { callApi } from "../../../utils/apiCaller";
import { toast } from "react-toastify";
const validate = Yup.object().shape({
  directors_set: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string().min(4, "too short").required("Required"), // these constraints take precedence
        profession_id: Yup.number().required("Please select Profession"),
      })
    )
    .required("Must have directors")
    .min(3, "Minimum of 3 directors"),
  producers_set: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string().min(4, "too short").required("Required"),
        profession_id: Yup.number().required("Please select Profession"),
      })
    )
    .required("Producer is required")
    .min(3, "Minimum of 3 Producer"),
  writers_set: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string().min(4, "too short").required("Required"),
        profession_id: Yup.number().required("Please select Profession"),
      })
    )
    .required("Writer is required")
    .min(3, "Minimum of 3 writer"),
  cast_set: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string().min(4, "too short").required("Required"),
        profession_id: Yup.number().required("Please select Profession"),
      })
    )
    .required("Casts")
    .min(3, "Minimum of 3 casts"),
});

const DatePickerField = ({ name, value, onChange }) => {
  return (
    <DatePicker
      dateFormat="yyyy"
      selected={(value && moment(value, "yyyy").toDate()) || null}
      onChange={(val) => {
        onChange(name, moment(val).format("yyyy"));
      }}
    />
  );
};

// const Directors = ["D1", "D@", "D#", "xyz"];
// const Directors = ["D1", "D@", "D#", "xyz"];
// const Directors = ["D1", "D@", "D#", "xyz"];
// const Directors = ["D1", "D@", "D#", "xyz"];
// export default class AddMovieModal extends 
class AddMovieModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addMovieForm: {
        name: "",
        is_draft: false,
        drawer: true,
        additional_info: {
          rating: "",
          duration: "",
          storyline: "",
        },
        taxonomy: [],
        media: [],
        country: "",
        year: moment().year(),
        rating: "",
        duration: "",
        directors_set: [
          {
            first_name: "",
            profession_id: "",
          },
        ],
        producers_set: [
          {
            first_name: "",
            profession_id: "",
          },
        ],
        writers_set: [
          {
            first_name: "",
            profession_id: "",
          },
        ],
        casts_set: [
          {
            first_name: "",
            profession_id: "",
          },
        ],
      },
      years_list: [],
      name: '',
      category_id: '',
      subcategory_id: '',
      year: moment().year(),
      country: 'United States',
      rating: 'G',
      duration: '',
      storyline: '',

      directors_set: [
        {
          first_name: "",
          profession_id: "",
        },
      ],
      writers_set: [
        {
          first_name: "",
          profession_id: "",
        },
      ],
      producers_set: [
        {
          first_name: "",
          profession_id: "",
        },
      ],
      casts_set: [
        {
          first_name: "",
          profession_id: "",
        },
      ],

      mediaSet: [],
      media: [],
      uploadMediaModal: false,
      fileReviewStatus: false,
      selectedFilesPreview: [],
      latestUploadedData: {},
      showVideoMedia: true,
      fetchTaxonomy: [],
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      uploadCaptions: {},
      nameError: "",
      categoryError: "",
      directorsError: "",
      durationError: "",
      writersError: "",
      storylineError: "",
      producersError: "",
      castsError: "",
      files: [],
      albumType: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      uploadMediaPostModal: false,
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
      search_driector_info: [],
      search_producer_info: [],
      search_writer_info: [],
      search_cast_info: [],
      indexValue: 0,
      indexCastValue: 0,
      indexProducerValue: 0,
      indexWriterValue: 0,
      typeMethod: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    let {
      latestUploadedData,
      fetchGalleryScroll,
      fetchGalleryCount,
    } = this.state;

    if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypesList: nextProps.album_types_list,
        },
        selectedUpMediaType: "upload",
        showGalleryType: "images",
      });
    }
    if (nextProps.fetch_taxonomy_listData) {
      let taxCatListing = nextProps.fetch_taxonomy_listData;
      if (
        taxCatListing &&
        Array.isArray(taxCatListing) &&
        taxCatListing.length > 0
      ) {
        if (taxCatListing[0].isTypes == "images") {
          this.setState({
            fetchTaxonomyListingImages: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        } else {
          this.setState({
            fetchTaxonomyListingVideos: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        }
      }
    }
    if (
      nextProps.album_type_data &&
      Object.keys(nextProps.album_type_data).length > 0
    ) {
      let item = nextProps.album_type_data;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypeData: nextProps.album_type_data,
        },
        fetchGalleryScroll,
        fetchGalleryCount,
      });
    }

    // if (nextProps.upload_media_data) {
    //   let data = nextProps.upload_media_data;
    //   if (data.index !== undefined) {
    //     let idx = data.index;
    //     latestUploadedData[idx] = data;
    //     this.setState({ latestUploadedData });
    //   }
    // }
    if (nextProps.fetch_taxonomy) {
      let taxCat = nextProps.fetch_taxonomy;
      if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
        taxCat = taxCat.filter((dt) => dt.label !== null);
      }
      this.setState({ fetchTaxonomy: taxCat });
    }
    if (nextProps.upload_scroll) {
      let item = nextProps.upload_scroll;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        fetchGalleryScroll,
        fetchGalleryCount,
        activeMyPhotoIndex: 0,
      });
    }

    if (nextProps.directorListing &&
      Array.isArray(nextProps.directorListing.results) &&
      nextProps.directorListing.results.length > 0) {
      this.setState({
        search_driector_info: nextProps.directorListing.results
      });
    }

    if (nextProps.writerListing &&
      Array.isArray(nextProps.writerListing.results) &&
      nextProps.writerListing.results.length > 0) {
      this.setState({
        search_writer_info: nextProps.writerListing.results
      });
    }

    if (nextProps.castListing &&
      Array.isArray(nextProps.castListing.results) &&
      nextProps.castListing.results.length > 0) {
      this.setState({
        search_cast_info: nextProps.castListing.results
      });
    }

    if (nextProps.producerListing &&
      Array.isArray(nextProps.producerListing.results) &&
      nextProps.producerListing.results.length > 0) {
      this.setState({
        search_producer_info: nextProps.producerListing.results
      });
    }

    if (nextProps.fetch_save_movie_response) {
      if (nextProps.fetch_save_movie_response && nextProps.fetch_save_movie_response.id && nextProps.fetch_save_movie_response.slug) {
        let slug = null;
        let movieId = "";
        if (nextProps.fetch_save_movie_response.id != "") {
          movieId = nextProps.fetch_save_movie_response.id;
        }
        if (nextProps.fetch_save_movie_response.slug != "") {
          slug = nextProps.fetch_save_movie_response.slug;
        }
        this.redirectto(movieId, slug);

      }
    }

  }

  componentDidMount = () => {
    let date = new Date()
    let year = date.getFullYear()
    let years_list = [];
    for (let i = 1970; i <= year + 10; i++) {
      years_list.push(i)
    }
    this.setState({ years_list: years_list })
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (name == "name") {
      this.setState({
        nameError: ""
      });
    } else if (name == "category_id") {
      let type = "category_id";
      this.setState({
        categoryError: "",
        category_id: e.target.value
      });
      this.props.initFetchMovieSubCategories(e.target.value, type);
    }
    else if (name == "directors_set[0].first_name") {
      this.setState({
        directorsError: ""
      });
    }
    else if (name == "duration") {
      this.setState({
        durationError: ""
      });
    }
    else if (name == "writers_set[0].first_name") {
      this.setState({
        writersError: ""
      });
    }
    else if (name == "storyline") {
      this.setState({
        storylineError: ""
      });
    }
    else if (name == "producers_set[0].first_name") {
      this.setState({
        producersError: ""
      });
    }
    else if (name == "casts_set[0].first_name") {
      this.setState({
        castsError: ""
      });
    }
  }

  handleChangeCast = (e, index) => {
    const { directors_set, writers_set, producers_set, casts_set } = this.state
    const { name, value } = e.target

    if (name.includes('directors') && directors_set.length <= index) {
      directors_set.push({ first_name: "", profession_id: "" })
      this.setState({ directors_set })

    }
    if (name.includes('writers') && writers_set.length <= index) {
      writers_set.push({ first_name: "", profession_id: "" })
      this.setState({ writers_set })
    }
    if (name.includes('producers') && producers_set.length <= index) {
      producers_set.push({ first_name: "", profession_id: "" })
      this.setState({ producers_set })
    }
    if (name.includes('casts') && casts_set.length <= index) {
      casts_set.push({ first_name: "", profession_id: "" })
      this.setState({ casts_set })
    }

    if (name === 'directors_name') {
      directors_set[index].first_name = value;
      this.setState({ directors_set, indexValue: index, typeMethod: "director" })
      let type = 'director';
      this.props.search_moviesMembers(value, type);

    }
    else if (name === 'directors_id') {
      directors_set[index].profession_id = value
      this.setState({ directors_set })
    }
    else if (name === 'writers_name') {
      writers_set[index].first_name = value
      this.setState({ writers_set, indexWriterValue: index, typeMethod: "writer" })
      let type = 'writer';
      this.props.search_moviesMembers(value, type);
    }
    else if (name === 'writers_id') {
      writers_set[index].profession_id = value
      this.setState({ writers_set })
    }
    else if (name === 'producers_name') {
      producers_set[index].first_name = value
      this.setState({ producers_set, indexProducerValue: index, typeMethod: "producer" })
      let type = 'producer';
      this.props.search_moviesMembers(value, type);
    }
    else if (name === 'producers_id') {
      producers_set[index].profession_id = value
      this.setState({ producers_set })
    }
    else if (name === 'casts_name') {
      casts_set[index].first_name = value
      this.setState({ casts_set, indexCastValue: index, typeMethod: "cast" });
      let type = 'cast';
      this.props.search_moviesMembers(value, type);
    }
    else if (name === 'casts_id') {
      casts_set[index].profession_id = value
      this.setState({ casts_set })
    }
  }

  handleOnClickAddSelected = (item, type, index) => {
    let { directors_set, producers_set, casts_set, writers_set } = this.state;
    if (type === 'director') {
      //this.props.search_moviesMembers('',type);
      directors_set[index].first_name = item.first_name + " " + item.last_name;
      this.setState({
        directors_set,
        search_driector_info: [],
        typeMethod: ''
      });
    }
    if (type === 'producer') {
      //this.props.search_moviesMembers('',type);
      producers_set[index].first_name = item.first_name + " " + item.last_name;
      this.setState({
        producers_set,
        search_producer_info: [],
        typeMethod: ''
      });
    }
    if (type === 'writer') {
      //this.props.search_moviesMembers('',type);
      writers_set[index].first_name = item.first_name + " " + item.last_name;
      this.setState({
        writers_set,
        search_writer_info: [],
        typeMethod: ''
      });
    }
    if (type === 'cast') {
      //this.props.search_moviesMembers('',type);
      casts_set[index].first_name = item.first_name + " " + item.last_name;
      this.setState({
        casts_set,
        search_cast_info: [],
        typeMethod: ''
      });
    }
  }

  handleremoveCast = (option) => {
    const { directors_set, writers_set, producers_set, casts_set } = this.state
    if (option === 'directors_set') {
      directors_set.pop()
      this.setState({ directors_set })
    }
    else if (option === 'writers_set') {
      writers_set.pop()
      this.setState({ writers_set })
    }
    else if (option === 'producers_set') {
      producers_set.pop()
      this.setState({ producers_set })
    }
    else if (option === 'casts_set') {
      casts_set.pop()
      this.setState({ casts_set })
    }
  }
  handleChangeTaxonomyData = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    data.category = value;
    let id = upload_data.id;
    this.setState({ taxonomycategoryId: value }, () =>
      this.props.upload_add_captions(id, data)
    );
  };

  redirectto = (movieId, slug) => {
    let path = "movies";
    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: movieId }
    })
  }
  handleOnClickRemoveSelectedMedia = (id) => () => {
    let removeMediaIds = [];
    removeMediaIds.push(id);
    this.props.delete_selected_gallery_media(removeMediaIds);
    let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
      (file) => file.id !== id
    );
    let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
      (file) => file.id !== id
    );
    let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
      (item) => item !== id
    );
    let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
      (file) => file.id !== id
    );
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: selectedMedia,
        selectedMediaIds: selectedMediaIds,
        uploadedFiles: uploadedFiles,
        uploadFiles: uploadFiles,
      },
    });
  };
  handleOnClickRemoveSelectedGalleryMedia = (media, index) => {
    // remove media from upload media state
    let { uploadMedia, editPost } = this.state;
    let id = media.id;
    let filterSelectedMediaIds = [];
    let filterSelectedMedia = [];

    if (id) {
      filterSelectedMediaIds = uploadMedia?.selectedMediaIds.filter(data => data !== id);
      filterSelectedMedia = uploadMedia?.selectedMedia.filter(data => data.id !== id);
    }
    uploadMedia['selectedMediaIds'] = filterSelectedMediaIds;
    uploadMedia['selectedMedia'] = filterSelectedMedia;

    if (editPost) {
      editPost["messagemedia_set"] = filterSelectedMediaIds;
    }

    this.setState({ uploadMedia, editPost }, () => { this.props.delete_selected_gallery_media([id]) });
  };
  handleOnClickSelectGalleryMedia = (media) => {
    if (media) {
      let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
      let newSelectedMediaIds;
      let newSelectedMedia;
      if (index !== -1) {
        newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
          (item) => item !== media.id
        );
        newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
          (item) => item.id !== media.id
        );
      } else {
        newSelectedMediaIds = [
          ...this.state.uploadMedia.selectedMediaIds,
          media.id,
        ];
        newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
      }
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: newSelectedMedia,
          selectedMediaIds: newSelectedMediaIds,
        },
      });
    }
  };

  handleOnFilePostUploadChange = (event) => {
    let uploadFiles = event.target.files;
    let showFiles = [];
    for (const key of Object.keys(uploadFiles)) {
      let itemType = uploadFiles[key].type.split("/");
      let extName = itemType[0];
      showFiles.push({ id: "", url: extName === "image" ? URL.createObjectURL(uploadFiles[key]) : require("../../../assets/images/blank.png") });
    }
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        uploadFiles: showFiles,
        progress: 0,
      },
    });
    let progressPart = 100 / showFiles.length;
    let progress = 0;
    for (const key of Object.keys(uploadFiles)) {
      let data = new FormData();
      data.append("file", uploadFiles[key]);

      callApi(
        `/upload/multiuploader/?album=&instance=moviesdrawer&image=undefined&doc=undefined`,
        "POST",
        data,
        true
      ).then((response) => {
        this.handleOnClickSelectGalleryMedia(response);
        if (showFiles.length === 1 || key === showFiles.length - 1) {
          progress = 100;
        } else {
          progress = progress + progressPart;
        }
        if (response && response.code !== 400 && response.code !== 500) {
          showFiles[key].id = response.id;
          showFiles[key].media_type = response.media_type;
          showFiles[key].thumbnail = response.thumbnail;
          if (response.media_type == "image") {
            this.props.fetch_taxonomy_list('movies', 'images');
          } else {
            this.props.fetch_taxonomy_list('movies', 'videos');
          }

          this.setState({
            ...this.state,
            uploadMedia: {
              ...this.state.uploadMedia,
              progress: progress,
              uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
              uploadedFiles: showFiles,
            },
          });
        }
      });
    }
  };

  // Upload media functions
  // handleOnFileUploadChange = (e) => {
  //   let { selectedFilesPreview, files } = this.state;
  //   const filesSetForUpload = Array.from(e.target.files);
  //   files = files?.concat(filesSetForUpload);
  //   let self = this;
  //   this.setState({ files }, () => {
  //     Promise.all(
  //       files.map((file) => {
  //         return new Promise((resolve, reject) => {
  //           const reader = new FileReader();
  //           reader.addEventListener("load", (ev) => {
  //             resolve(ev.target.result);
  //           });
  //           reader.addEventListener("error", reject);
  //           reader.readAsDataURL(file);
  //         });
  //       })
  //     ).then(
  //       (images) => {
  //         let concatWithPrev = selectedFilesPreview.concat(images);
  //         self.setState({ selectedFilesPreview: concatWithPrev });
  //       },
  //       (error) => {
  //         console.error(error);
  //       }
  //     );
  //   });

  //   if (files && Array.isArray(files) && files.length > 0) {
  //     let indexSelected = selectedFilesPreview.length - 1;
  //     files.forEach(async (item, index) => {
  //       if (index > indexSelected) {
  //         let formData = new FormData();
  //         formData.append("file", item);

  //         let name = item.name;
  //         let split_items = name.split(".");
  //         let item_ext = split_items[split_items.length - 1];
  //         let isImageTrue = [".jpg", ".png", ".jpeg", ".gif", ".tiff"].includes(
  //           item_ext
  //         );
  //         if (isImageTrue) {
  //           await this.props.upload_media_files("image", formData, index);
  //         } else {
  //           await this.props.upload_media_files("video", formData, index);
  //         }
  //       }
  //     });
  //   }
  // };

  // removeSelectedImage = (e, index) => {
  //   let { latestUploadedData, selectedFilesPreview } = this.state;
  //   let upload_data = latestUploadedData[index];
  //   let id = upload_data.id;
  //   this.props.single_delete_from_upload(id);
  //   selectedFilesPreview.splice(index, 1);
  //   delete latestUploadedData[index];
  //   this.setState({ selectedFilesPreview, latestUploadedData });
  // };

  // handleCloseAndFetch = () => {
  //   let { latestUploadedData, fileReviewStatus, mediaSetReview } = this.state;

  //   if (
  //     fileReviewStatus &&
  //     Object.values(latestUploadedData) &&
  //     Object.values(latestUploadedData)[0] &&
  //     Object.values(latestUploadedData)[0].id
  //   ) {
  //     Object.values(latestUploadedData).map((list) => {
  //       mediaSetReview.push({
  //         id: list.id,
  //         file: list.url,
  //         thumbnail: list.thumbnail,
  //         url: list.url,
  //       });
  //     });
  //   }
  //   this.setState(
  //     {
  //       uploadMediaModal: !this.state.uploadMediaModal,
  //       fileReviewStatus: false,
  //       mediaSetReview,
  //     },
  //     () => {
  //       this.clearMediaDispatch();
  //       this.props.fetch_media_data("image", "", 1, true, true);
  //       this.props.fetch_media_data("video", "", 1, true, true);
  //     }
  //   );
  // };

  clearMediaDispatch = () => {
    this.setState(
      {
        latestUploadedData: {},
        uploadCaptions: {},
        selectedFilesPrevietoggleBottomMenuw: [],
        files: [],
      },
      () => this.props.clear_media_dispatch()
    );
  };

  toggleVideoOption = () => {
    this.setState({ showVideoMedia: !this.state.showVideoMedia });
  };

  AddNewEmbedLink = (e) => {
    e.preventDefault();
    let { embeddedLink } = this.state;
    // this.props.embedNewLink({
    //   data: {
    //     video: `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
    //     exclude_list: [],
    //     body_preview: `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
    //     question_rating_category: [],
    //     messagemedia_set: [],
    //   },
    // });
    this.setState({ embeddedLink: "" });
  };

  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`;

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach((item) => {
        if (
          fetchGalleryScroll[item] &&
          fetchGalleryScroll[item].results !== null
        ) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map((item) => {
          let itemName = (item?.thumbnail).split(".");
          let itemFile = (item?.file).split(".");
          let extName = itemName[itemName.length - 1];
          let extFile = itemFile[itemFile.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;

          return (
            <Col xs="auto" className="mb-3">
              <FormGroup
                className="checkable-media"
                check
                style={{ width: "120px" }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={() => this.handleOnClickSelectGalleryMedia(item)}
                  />
                  <div className="gallery-media">
                    <img
                      className="img-fluid"
                      src={isVideo ? srcFile : item?.file}
                      alt="logo Pics"
                    />
                  </div>
                </Label>
                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
                  {item.caption ? item.caption : "No Caption"}
                </div>
                <div className="font-weight-normal fs-14 d-block">
                  {item.category ? item.category : "No Category"}
                </div>
              </FormGroup>
            </Col>
          );
        });
      }
    } else {
      return getDataItems;
    }
  };

  handleMediaUpload = () => {
    const queryCaptionParams = {
      category: "Album",
      depth: true,
      delsel: "",
      ancestor: "",
      sub_cat: "",
      parent: "",
    };
    let { mediaSetReview } = this.state;
    this.props.getAllCaptionCategories(queryCaptionParams);

    this.setState(
      {
        uploadMediaModal: !this.state.uploadMediaModal,
        fileReviewStatus: true,
        mediaSet: mediaSetReview,
      },
      () => {
        this.clearMediaDispatch();
        this.props.fetch_media_data("image", "", 1, true, true);
        this.props.fetch_media_data("video", "", 1, true, true);
      }
    );
  };

  checkIsAdded = (item) => {
    let { mediaSet } = this.state;
    let id = item.id;
    let itemFiltered = mediaSet.find((item) => item.id === id);
    if (itemFiltered) {
      return true;
    } else {
      return false;
    }
  };

  setMediaSetForItems = (e, item) => {
    // e.preventDefault();
    let { mediaSet, offerCheck } = this.state;
    if (item && item.id) {
      let id = item.id;
      let itemFiltered = mediaSet.find((item) => item.id === id);

      if (itemFiltered) {
        const index = mediaSet.indexOf(itemFiltered);
        if (index > -1) {
          mediaSet.splice(index, 1);
        }
      } else {
        mediaSet.push(item);
      }

      // if (otherOffers.inStoreCouponToggle) {
      //     if (mediaSet.length > 0) { offerCheck['validateForSubmit'] = false; }
      //     else { offerCheck['validateForSubmit'] = true; }
      // }

      this.setState({ mediaSet, mediaSetReview: mediaSet, offerCheck });
    }
  };

  removeFromMedia = (e, item) => {
    e.preventDefault();
    let { mediaSet } = this.state;
    let id = item.id;
    let itemFiltered = mediaSet.find((item) => item.id === id);
    if (itemFiltered) {
      const index = mediaSet.indexOf(itemFiltered);
      if (index > -1) {
        mediaSet.splice(index, 1);
      }
      this.setState({ mediaSet, mediaSetReview: mediaSet });
    }
  };
  handleCaptionByIndex = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    uploadCaptions[index] = value;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    let id = upload_data.id;
    this.setState({ uploadCaptions }, () =>
      this.props.upload_add_captions(id, data)

    );

  };
  handleOnClickGalleryType = (type) => {
    let mediaType = "";
    if (type === "images") {
      mediaType = "image";
    } else if (type === "videos") {
      mediaType = "video";
    }
    this.props.get_album_type_data(mediaType, "", 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: type,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: mediaType,
        albumType: "",
        pageNo: 1,
      },
    });
  };
  handleOnClickAlbumTypeChange = (e) => {
    this.props.get_album_type_data("image", e.target.value, 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: "images",
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: e.target.value,
        pageNo: 1,
      },
    });
  };
  handleOnClickSelectedUploadMediaType = (type) => {
    if (type === "gallery") {
      this.props.get_album_type_data("image", "", 1);
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        showGalleryType: "images",
        uploadMedia: {
          ...this.state.uploadMedia,
          mediaType: "image",
          albumType: "",
          pageNo: 1,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "upload") {
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "embed") {
      let embedLinks = {
        0: "",
        1: "",
      };
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: embedLinks,
        },
      });
    }
  };
  embedLinkOnChange = (id) => (e) => {
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        embedLinks: {
          ...this.state.uploadMedia.embedLinks,
          [id]: e.target.value,
        },
      },
    });
  };
  mapEmbedLinks = () => {
    let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
    return embedKeys.map((emKey) => {
      return (
        <div className="d-flex mx-n2 mb-2" key={emKey}>
          <div className="flex-fill px-2">
            <Input
              type="url"
              bsSize="sm"
              className="bg-white"
              value={this.state.uploadMedia.embedLinks[emKey]}
              onChange={this.embedLinkOnChange(emKey)}
              placeholder="Embeded link"
            />
          </div>
          <div className="px-2">
            <Button
              title="Remove"
              color="danger"
              size="sm"
              hidden={emKey <= 1 ? true : false}
              onClick={() => {
                this.deleteEmbedLinkRow(emKey);
              }}
            >
              <FontAwesomeIcon icon="minus" />
            </Button>
          </div>
        </div>
      );
    });
  };
  deleteEmbedLinkRow = (emKey) => {
    let embedLinks = this.state.uploadMedia.embedLinks;
    if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
      delete embedLinks[emKey];
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: embedLinks,
        },
      });
    }
  };
  addEmbedlinkRow = () => {
    let newEmbedLinks = {};
    for (let i = 2; i < 5; i += 1) {
      if (
        Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
        -1
      ) {
        newEmbedLinks[i] = "";
        break;
      }
    }
    if (Object.keys(newEmbedLinks).length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {
            ...this.state.uploadMedia.embedLinks,
            ...newEmbedLinks,
          },
        },
      });
    }
  };
  confirmDeleteModalToggle = () => {
    if (this.state.deletePostId) {
      this.setState({ deletePostId: 0, deletePostType: "" });
    }
    this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
  };
  handleOnClickUploadMedia = () => {
    this.props.get_album_types_list();
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        progress: 0,
        uploadFiles: [],
        uploadedFiles: [],
      },
      uploadMediaPostModal: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
    });
  };
  truncate = (filenameString) => {
    let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
    let extension = filenameString.substr(
      filenameString.lastIndexOf("."),
      filenameString.length - 1
    );
    let partial = filename.substring(filename.length - 3, filename.length);
    filename = filename.substring(0, 15);
    return filename + "..." + partial + extension;
  };
  limitedPostsModalToggle = () => {
    this.setState({ limitedPostsModal: !this.state.limitedPostsModal });
  };
  uploadMediaModalToggle = () => {
    this.setState({
      ...this.state,
      uploadMediaPostModal: !this.state.uploadMediaPostModal,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: [],
        selectedMediaIds: [],
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        uploadedFiles: [],
        uploadFiles: [],
      },
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      taxonomycategoryId: null,
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
    });
  };
  handleOpenModal(data, url) {
    this.setState({
      viewMyPhotosModal: !this.state.viewMyPhotosModa,
      imageAttacment: data,
    });
  }

  handleScrollGalleryVideo = (e) => {
    let { refGalleryVideoScroll } = this;
    // let count = 0;
    let clientHeight = refGalleryVideoScroll.current.clientHeight;
    let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "video",
        album: "",
        force_update: false,
      });
    }
  };

  fetchGalleryOnScroll = ({ type, album, force_update }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state;
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;
      if (nextCount <= roundValue) {
        let page = nextCount;
        this.props.get_album_type_data(type, "", page);
      }
    }
  };

  handleScrollGalleryImage = (e) => {
    let { refGalleryImageScroll } = this;
    let { fetchGalleryAlbum } = this.state;

    let clientHeight = refGalleryImageScroll.current.clientHeight;
    let scrollHeight = refGalleryImageScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "image",
        album: fetchGalleryAlbum,
        force_update: false,
      });
    }
  };


  handleMovieSubmit = () => {
    let { name, storyline, category_id, duration, directors_set, additional_info, writers_set, producers_set, casts_set } = this.state
    if (name == "" && category_id == "" && directors_set[0].first_name == "" && directors_set[0].profession_id == "" && writers_set[0].first_name == ""
      && writers_set[0].profession_id == "" && producers_set[0].profession_id == "" && producers_set[0].first_name == "" && casts_set[0].first_name == ""
      && casts_set[0].profession_id == "" && storyline == "") {
      this.setState({
        nameError:
          "This field is required",
        categoryError:
          "This field is required",
        directorsError:
          "This field is required",
        durationError:
          "This field is required",
        writersError:
          "This field is required",
        storylineError:
          "This field is required",
        producersError:
          "This field is required",
        castsError:
          "This field is required",
      });
    } else if (name == "") {
      this.setState({
        nameError:
          "This field is required",
      });
    }
    else if (category_id == "") {
      this.setState({
        categoryError:
          "Please select category",
      });

    }
    else if (directors_set[0].first_name == "") {
      this.setState({
        directorsError:
          "Please select Director",
      });
    } else if (directors_set[0].profession_id == "") {
      this.setState({
        directorsError:
          "Please select Director",
      });
    }
    else if (duration == "") {
      this.setState({
        durationError:
          "Please select Duration",
      });
    }
    else if (writers_set[0].first_name == "") {
      this.setState({
        writersError:
          "Please select Writers",
      });
    } else if (writers_set[0].profession_id == "") {
      this.setState({
        writersError:
          "Please select Writers",
      });
    }
    else if (storyline == "") {
      this.setState({
        storylineError:
          "Please select Storyline",
      });
    }
    else if (producers_set[0].first_name == "") {
      this.setState({
        producersError:
          "Please select producers",
      });
    } else if (producers_set[0].profession_id == "") {
      this.setState({
        producersError:
          "Please select producers",
      });
    }
    else if (casts_set[0].first_name == "") {
      this.setState({
        castsError:
          "Please select casts",
      });
    } else if (casts_set[0].profession_id == "") {
      this.setState({
        castsError:
          "Please select casts",
      });
    } else {
      let params = {}
      let media = []
      // this.state.mediaSet?.map(med => {
      //   media.push(med.id)
      // })
      let intItem = parseInt(this.state.category_id)
      params = {
        name: this.state.name,
        is_draft: false,
        additional_info: {
          rating: this.state.rating,
          duration: this.state.duration,
          storyline: this.state.storyline,
        },
        country: this.state.country,
        cast_set: this.state.casts_set,
        directors_set: this.state.directors_set,
        producers_set: this.state.producers_set,
        writers_set: this.state.writers_set,
        media: [],
        taxonomy: [intItem],
        release_date: this.state.year,
      }
      if (this.state.uploadMedia.selectedMediaIds.length > 0) {
        params.media = this.state.uploadMedia.selectedMediaIds
      } else {
        toast(`Please add media with the listing.`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return 0;
      }
      this.props.add_movie(params);
      this.props.toggleMovieModal()
    }
  }

  render() {
    const {
      openModal,
      toggleMovieModal,
      movies,
      videos_children,
    } = this.props;
    const { addMovieForm, category_id, years_list, year, name, rating, duration, directors_set,
      writers_set, casts_set, producers_set, storyline, mediaSet, latestUploadedData, selectedFilesPreview,
      showVideoMedia, fetchTaxonomy, embeddedLink, fetchTaxonomyListingImages, fetchTaxonomyListingVideos, search_driector_info, search_producer_info, search_writer_info, search_cast_info, typeMethod } = this.state;
    let totalLength = selectedFilesPreview.length;
    let objectKeys = [];
    let objectLength = 0;
    let calculatedPercentage = 0;
    if (Object.keys(latestUploadedData).length > 0) {
      objectKeys = Object.keys(latestUploadedData);
      objectLength = objectKeys.length;
      calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
    }
    return (
      <>
        <Formik
          touched
          errors
          initialValues={addMovieForm}
          validationSchema={validate}
          onSubmit={(values) => {
            this.props.onSubmit({
              ...values,
              taxonomy: [values.taxonomy],
            });
          }}
          render={(props) => {
            const {
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            } = props;
            return (
              /* Add movieuseFormik drawer modal popup */
              <Modal
                size="xl"
                scrollable
                className="drawer-modal"
                isOpen={openModal}
                toggle={toggleMovieModal}
              >
                <form
                  className="d-flex flex-column h-100"
                  onSubmit={handleSubmit}
                >
                  <div className="modal-header p-0">
                    <div className="w-100">
                      <div>
                        <Button color="primary" onClick={toggleMovieModal}>
                          <FontAwesomeIcon icon="angle-left" />
                        back to listing
                      </Button>
                      </div>
                      <div>
                        <div className="bg-darker p-3 text-tertiary">
                          <div className="mb-2 text-primary font-weight-bold">
                            You’re Adding a New Movie Listing
                        </div>
                          <ul className="list-unstyled d-flex flex-wrap mx-n2">
                            <li className="px-2">
                              <div className="d-flex">
                                <FormGroup>
                                  <Input
                                    type="select"
                                    className="transparent"
                                    size="sm"
                                  >
                                    <option>{movies.category}</option>
                                  </Input>
                                </FormGroup>
                              </div>
                            </li>
                            <li className="px-2">
                              <div className="d-flex">
                                <div className="mr-1">
                                  <span className="text-danger">*</span>
                                </div>
                                <FormGroup>
                                  <Input
                                    id="category_id"
                                    name="category_id"
                                    onChange={this.handleChange}
                                    type="select"
                                    className="transparent"
                                    size="sm"
                                    selected={category_id}
                                  >
                                    <option value="">Select Category</option>
                                    {movies.children?.map((movie) => {
                                      return (
                                        <option value={movie.id} key={movie.id}>
                                          {movie.category}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                  <span style={{ color: 'red', fontSize: "13px" }}>{this.state.categoryError}</span>
                                </FormGroup>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ModalBody className="bg-white text-dark">
                    {/* Step 1 */}
                    <div>
                      <div className="text-primary font-weight-bold mb-2">
                        Step 1. Enter Basic Information
                    </div>
                      <Row>
                        <Col md={6} lg={4}>
                          <FormGroup>
                            <Label
                              for="name"
                              className="text-dark font-weight-bold mb-0"
                              size="sm"
                            >
                              Movie Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              bsSize="sm"
                              type="text"
                              placeholder="Type in the name of the listing"
                              value={name}
                              id="name"
                              name="name"
                              autocomplete="off"
                              onChange={this.handleChange}
                              onBlur={handleBlur}
                              className={`primary bg-white ${errors.name &&
                                touched.name &&
                                "border border-danger"
                                }`}
                            />
                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.nameError}</span>
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="form-inline mx-n2">
                        <FormGroup className="px-2 mb-3">
                          <Label
                            for="country"
                            className="text-dark font-weight-bold mb-0 mr-2"
                            size="sm"
                          >
                            Country
                        </Label>
                          <Input
                            id="country"
                            name="country"
                            onChange={this.handleChange}
                            className="primary select-type-dark"
                            bsSize="sm"
                            type="select"
                          >
                            <option key={"United States"} value="United States">
                              United States
                          </option>
                            <option key={"Canada"} value="Canada">
                              Canada
                          </option>
                          </Input>
                        </FormGroup>
                        <FormGroup className="px-2 mb-3">
                          <Label
                            for="year"
                            className="text-dark font-weight-bold mb-0 mr-2"
                            size="sm"
                          >
                            Year
                        </Label>

                          <Input
                            id="year"
                            name="year"
                            onChange={this.handleChange}
                            type="select"
                            className="transparent"
                            size="sm"
                            selected={year}
                            value={year}
                          >
                            {years_list?.map((yr) => {
                              return (
                                <option
                                  //  disabled={yr < moment().year() ? true : false}
                                  value={yr} key={yr}>
                                  {yr}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                        {/* <FormGroup className="px-2 mb-3">
                        <Label
                          for="date"
                          className="text-dark font-weight-bold mb-0 mr-2"
                          size="sm"
                        >
                          Year
                        </Label>
                        <DatePickerField
                          type="date"
                          id="year"
                          name="year"
                          value={year}
                          onChange={this.handleChange}
                        />
                      </FormGroup> */}
                        <FormGroup className="px-2 mb-3">
                          <Label
                            className="text-dark font-weight-bold mb-0 mr-2"
                            size="sm"
                          >
                            Rating
                        </Label>
                          <Input
                            className="primary select-type-dark"
                            bsSize="sm"
                            type="select"
                            name="rating"
                            value={rating}
                            onChange={this.handleChange}
                          >
                            <option value="G">G</option>
                            <option value="PG">PG</option>
                            <option value="PG-13">PG-13</option>
                            <option value="R">R</option>
                            <option value="NC-17">NC-17</option>
                          </Input>
                        </FormGroup>
                        <FormGroup className="px-2 mb-3">
                          <Label
                            className="text-dark font-weight-bold mb-0 mr-2"
                            size="sm"
                          >
                            Duration
                        <span className="text-danger ml-1">*</span>
                        </Label>
                          <Input
                            id="duration"
                            name="duration"
                            onChange={this.handleChange}
                            value={duration}
                            className="primary"
                            bsSize="sm"
                            type="text"
                          />
                        </FormGroup>
                      </div>
                      <span style={{ color: 'red', fontSize: "13px", display: 'flex', justifyContent: 'flex-end', marginRight: "37%" }}>{this.state.durationError}</span>

                      <hr className="bg-dark" />

                      <Row>
                        <Col lg={4}>
                          <div>
                            {/* Repeat this */}
                            <div>
                              <FieldArray
                                name="directors_set"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.directors_set.map(
                                      (director, index) => (
                                        <div key={index}>
                                          <FormGroup>
                                            <Label
                                              className="text-dark font-weight-bold mb-0"
                                              size="sm"
                                            >
                                              Director/s
                                            <span className="text-danger ml-1">*</span>
                                          </Label>
                                            <Input
                                              className="primary"
                                              bsSize="sm"
                                              type="text"
                                              // name={`directors_set[${index}].first_name`}
                                              name="directors_name"
											  maxLength="30"
                                              autocomplete="off"
                                              onChange={(e) => this.handleChangeCast(e, index)}
                                              value={directors_set[index].first_name}
                                            />

                                            {/* Instant Search Results */}
                                            {this.state.indexValue == index && typeMethod == "director" && search_driector_info && search_driector_info.length > 0 && (
                                              <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                {search_driector_info.map(itemData => {
                                                  return (
                                                    <li className="result-item" role="button" key={itemData.id}>
                                                      <div className="result-link fs-14" onClick={() => this.handleOnClickAddSelected(itemData, 'director', index)}>
                                                        <div className="d-flex">
                                                          <div>
                                                            <span>{itemData.first_name} {itemData.last_name}</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )
                                                })}
                                              </ul>
                                            )}

                                            <div className="my-2"></div>
                                            <Input
                                              className="primary select-type-dark"
                                              bsSize="sm"
                                              type="select"
                                              name="directors_id"
                                              onChange={(e) => this.handleChangeCast(e, index)}
                                            >
                                              <option>-----</option>
                                              <option value="10">Director</option>
                                            </Input>
                                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.directorsError}</span>
                                          </FormGroup>
                                          {/* Hide for first */}
                                          <Button color="link" className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                              this.handleremoveCast('directors_set')
                                            }
                                            }>
                                            Remove
                                          </Button>
                                        </div>
                                      )
                                    )}
                                    <div className="text-right">
                                      <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                        onClick={() => {
                                          arrayHelpers.push({
                                            first_name: "",
                                            profession_id: "",
                                          });
                                          directors_set.push({
                                            first_name: "",
                                            profession_id: "",
                                          })
                                        }
                                        }
                                      >
                                        <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="my-2"></div>
                          <div>
                            {/* Repeat this */}
                            <div>
                              <FieldArray
                                name="producers_set"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.producers_set.map(
                                      (producer, index) => (
                                        <div key={index}>
                                          <FormGroup>
                                            <Label
                                              className="text-dark font-weight-bold mb-0"
                                              size="sm"
                                            >
                                              Producer/s
                                             <span className="text-danger ml-1">*</span>
                                          </Label>
                                            <Input
                                              className="primary"
                                              bsSize="sm"
                                              type="text"
                                              autocomplete="off"
                                              value={producers_set[index].first_name}
                                              name="producers_name"
											  maxLength="30"
                                              onChange={(e) => this.handleChangeCast(e, index)}
                                            />

                                            {/* Instant Search Results */}
                                            {this.state.indexProducerValue == index && typeMethod == "producer" && search_producer_info && search_producer_info.length > 0 && (
                                              <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                                {search_producer_info.map(itemData => {
                                                  return (
                                                    <li className="result-item" role="button" key={itemData.id}>
                                                      <div className="result-link fs-14" onClick={() => this.handleOnClickAddSelected(itemData, 'producer', index)}>
                                                        <div className="d-flex">
                                                          <div>
                                                            <span>{itemData.first_name} {itemData.last_name}</span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  )
                                                })}
                                              </ul>
                                            )}
                                            <div className="my-2"></div>
                                            <Input
                                              className="primary select-type-dark"
                                              bsSize="sm"
                                              type="select"
                                              name="producers_id"
                                              onChange={(e) => this.handleChangeCast(e, index)}
                                            >
                                              <option>-----</option>
                                              <option value="3">Line Producer</option>
                                              <option value="5">Producer</option>
                                            </Input>
                                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.producersError}</span>
                                          </FormGroup>
                                          {/* Hide for first */}
                                          <Button color="link" className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                              this.handleremoveCast('producers_set')
                                            }
                                            }>
                                            Remove
                                           </Button>
                                        </div>
                                      )
                                    )}
                                    <div className="text-right">
                                      <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                        onClick={() => {
                                          arrayHelpers.push({
                                            first_name: "",
                                            profession_id: "",
                                          });
                                          producers_set.push({
                                            first_name: "",
                                            profession_id: "",
                                          })
                                        }
                                        }>
                                        <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                            {/* Repeat this */}
                            <div>
                              <FieldArray
                                name="writers_set"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.writers_set.map((writer, index) => (
                                      <div key={index}>
                                        <FormGroup>
                                          <Label
                                            className="text-dark font-weight-bold mb-0"
                                            size="sm"
                                          >
                                            Writer/s
                                            <span className="text-danger ml-1">*</span>
                                        </Label>
                                          <Input
                                            className="primary"
                                            bsSize="sm"
                                            type="text"
                                            autocomplete="off"
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                            name="writers_name"
											maxLength="30"
                                            value={writers_set[index].first_name}
                                          />

                                          {this.state.indexWriterValue == index && typeMethod == "writer" && search_writer_info && search_writer_info.length > 0 && (
                                            <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                              {search_writer_info.map(itemData => {
                                                return (
                                                  <li className="result-item" role="button" key={itemData.id}>
                                                    <div className="result-link fs-14" onClick={() => this.handleOnClickAddSelected(itemData, 'writer', index)}>
                                                      <div className="d-flex">
                                                        <div>
                                                          <span>{itemData.first_name} {itemData.last_name}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                )
                                              })}
                                            </ul>
                                          )}
                                          <div className="my-2"></div>
                                          <Input
                                            className="primary select-type-dark"
                                            bsSize="sm"
                                            type="select"
                                            name="writers_id"
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                          >
                                            <option>-----</option>
                                            <option value="8">Screenplay</option>
                                            <option value="9">Book</option>
                                            <option value="11">Writer</option>
                                          </Input>
                                          <span style={{ color: 'red', fontSize: "13px" }}>{this.state.writersError}</span>
                                        </FormGroup>

                                        <Button color="link" className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            this.handleremoveCast('writers_set')
                                          }
                                          }>
                                          Remove
                                           </Button>
                                      </div>
                                    ))}
                                    <div className="text-right">
                                      <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                        onClick={() => {
                                          arrayHelpers.push({
                                            first_name: "",
                                            profession_id: "",
                                          });
                                          writers_set.push({
                                            first_name: "",
                                            profession_id: "",
                                          })
                                        }
                                        }>
                                        <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                          <div className="my-2"></div>
                          <div>
                            {/* Repeat this */}
                            <div>
                              <FieldArray
                                name="casts_set"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.casts_set.map((cast, index) => (
                                      <div key={index}>
                                        <FormGroup>
                                          <Label
                                            className="text-dark font-weight-bold mb-0"
                                            size="sm"
                                          >
                                            Cast
                                            <span className="text-danger ml-1">*</span>
                                        </Label>
                                          <Input
                                            className="primary"
                                            bsSize="sm"
                                            type="text"
                                            autocomplete="off"
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                            name="casts_name"
											maxLength="30"
                                            value={casts_set[index].first_name}
                                          />

                                          {this.state.indexCastValue == index && typeMethod == "cast" && search_cast_info && search_cast_info.length > 0 && (
                                            <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                              {search_cast_info.map(itemData => {
                                                return (
                                                  <li className="result-item" role="button" key={itemData.id}>
                                                    <div className="result-link fs-14" onClick={() => this.handleOnClickAddSelected(itemData, 'cast', index)}>
                                                      <div className="d-flex">
                                                        <div>
                                                          <span>{itemData.first_name} {itemData.last_name}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                )
                                              })}
                                            </ul>
                                          )}
                                          <div className="my-2"></div>
                                          <Input
                                            className="primary select-type-dark"
                                            bsSize="sm"
                                            type="select"
                                            name="casts_id"
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                          >
                                            <option>-----</option>
                                            <option value="6">Actor</option>
                                            <option value="7">Actresses</option>
                                            <option value="16">Cast</option>
                                          </Input>
                                          <span style={{ color: 'red', fontSize: "13px" }}>{this.state.castsError}</span>
                                        </FormGroup>

                                        <Button color="link" className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            this.handleremoveCast('casts_set')
                                          }
                                          }>
                                          Remove
                                           </Button>
                                      </div>
                                    ))}
                                    <div className="text-right">
                                      <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                        onClick={() => {
                                          arrayHelpers.push({
                                            first_name: "",
                                            profession_id: "",
                                          });
                                          casts_set.push({
                                            first_name: "",
                                            profession_id: "",
                                          })
                                        }
                                        }>
                                        <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <FormGroup className="px-2 mb-3">
                            <Label
                              className="text-dark font-weight-bold mb-0 mr-2"
                              size="sm"
                            >
                              Storyline
                              <span className="text-danger ml-1">*</span>
                          </Label>
                            <Input
                              id="stroyline"
                              name="storyline"
                              onChange={this.handleChange}
                              value={values.storyline}
                              className="primary"
                              bsSize="sm"
                              type="textarea"
                              rows="5"
                            />
                            <span style={{ color: 'red', fontSize: "13px" }}>{this.state.storylineError}</span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="bg-dark" />
                    {/* Step 2 */}
                    <div>
                      <div className="text-primary font-weight-bold mb-2">
                        Step 2. Add Trailer or associated Images and/or Videos
                        <span className="ml-1" style={{ color: 'red', fontSize: "16px" }}>*</span>
                    </div>
                      <Row>
                        <Col lg={5}>
                          <p className="text-tertiary fs-14">
                            Upload images or video to accompany your listing.
                            <br />
                            Additional images/videos can be added later.
                        </p>
                        </Col>
                        <Col lg={7}>
                          {/* Uploaded media shown here */}
                          {
                            this.state.uploadMedia.selectedMedia.length ?
                              <div className="border mb-3">
                                <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                  {this.state.uploadMedia.selectedMedia.map((item, indexMedia) => (
                                    <Col>
                                      <div className="d-flex pr-3 m-2">
                                        <div>
                                          {(item.type === "image" ||
                                            item.media_type === "image" ||
                                            item.type === "video" ||
                                            item.media_type === "video" || item.type === "audio" ||
                                            item.media_type === "audio") && (
                                              <div className="gallery-media">
                                                { item.media_type === 'image' ?
                                                  <img
                                                    className="img-fluid img-thumbnail"
                                                    src={item.url ? item.url : item.file}
                                                    alt={
                                                      item.filename
                                                        ? item.filename.length < 20
                                                          ? item.filename
                                                          : this.truncate(item.filename)
                                                        : item.name
                                                          ? item.name.length < 20
                                                            ? item.name
                                                            : this.truncate(item.name)
                                                          : ""
                                                    } /> :
                                                  <img
                                                    className="img-fluid img-thumbnail"
                                                    src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
                                                    alt={
                                                      item.filename
                                                        ? item.filename.length < 20
                                                          ? item.filename
                                                          : this.truncate(item.filename)
                                                        : item.name
                                                          ? item.name.length < 20
                                                            ? item.name
                                                            : this.truncate(item.name)
                                                          : ""
                                                    }
                                                  />}
                                              </div>
                                            )}
                                        </div>
                                        <div className="mx-n3 mt-2">
                                          <Button
                                            color="delete-media"
                                            size="sm"
                                            title="Remove Media"
                                            onClick={() =>
                                              this.handleOnClickRemoveSelectedGalleryMedia(
                                                item, indexMedia
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon="trash-alt" />{" "}
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                              :
                              ''
                          }
                          <Button
                            color="royal-blue"
                            className="font-weight-normal"
                            bsSize="sm"
                            onClick={() =>
                              this.handleOnClickUploadMedia()
                            }
                          >
                            Upload Media
                        </Button>
                        </Col>
                      </Row>
                    </div>

                    <hr className="bg-dark" />

                    {/* Step 3 */}
                    <div>
                      <div className="text-primary font-weight-bold mb-2">
                        Step 3. Write a review
                    </div>
                      <div className="pl-4">
                        <FormGroup check>
                          <Label className="fs-13 text-darker font-weight-bold" check>
                            <Input type="checkbox" /> Thanks for adding a new
                          listing! As initial uploader, you get the honor of
                          First Review! Write a Review for this movie now and
                          immortalize your name in the chronicles of wiki glory!
                        </Label>
                        </FormGroup>
                      </div>

                      <div className="text-right mt-3">
                        <Button
                          color="primary"
                          size="sm"
                          onClick={toggleMovieModal}
                        >
                          Cancel
                      </Button>
                        <Button color="primary" size="sm" onClick={this.handleMovieSubmit}>
                          Submit
                      </Button>
                      </div>
                    </div>
                  </ModalBody>
                </form>
              </Modal>



            );
          }}
        />


        {/* Media Modal */}

        <Modal
          isOpen={this.state.uploadMediaPostModal}
          toggle={this.uploadMediaModalToggle}
        >
          <ModalHeader toggle={this.uploadMediaModalToggle}>
            UPLOAD MEDIA
          </ModalHeader>
          <ModalBody className="p-3">
            <Row>
              <Col xs={"auto"}>
                <div className="bg-light h-100 p-3">
                  <Nav
                    tabs
                    className="flex-column active-transparent border-0 m-0"
                  >
                    <NavItem className="text-left">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "upload"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("upload");
                        }}
                      >
                        Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "gallery"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("gallery");
                        }}
                      >
                        Gallery
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "embed"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("embed");
                        }}
                      >
                        Embed
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col>
                <TabContent activeTab={this.state.selectedUpMediaType}>
                  <TabPane tabId="upload">
                    <div
                      className="mb-3 type-file-block bg-light"
                      hidden={
                        this.state.uploadMedia.uploadFiles &&
                          this.state.uploadMedia.uploadFiles.length > 0
                          ? true
                          : false
                      }
                    >
                      <Input
                        type="file"
                        name="upload_media_file"
                        id="uploadFilesPostFromSystem"
                        accept="image/*, video/*, audio/*"
                        onChange={this.handleOnFilePostUploadChange}
                        multiple
                      />
                      <Label for="uploadFilesPostFromSystem">
                        <div className="type-bg"></div>
                      </Label>
                    </div>
                    {this.state.uploadMedia.uploadFiles.length > 0 && (
                      <Row className="mb-3" form>
                        <Col xs={12}>
                          <div
                            style={{
                              maxWidth: "120px",
                              margin: "0 1rem 1rem auto",
                            }}
                          >
                            <div className="text-center mb-1 small">
                              {this.state.uploadMedia.progress === 100 ? (
                                <div className="text-success">
                                  <FontAwesomeIcon
                                    icon="check-circle"
                                    className="mr-1"
                                  />{" "}
                                  Uploaded
                                </div>
                              ) : (
                                  <div>
                                    Uploading{" "}
                                    <span className="text-success font-weight-bold ff-base">
                                      {this.state.uploadMedia.progress.toFixed(0)}
                                    %
                                  </span>
                                  </div>
                                )}
                            </div>
                            <Progress
                              value={this.state.uploadMedia.progress}
                              style={{ height: "8px" }}
                            ></Progress>
                          </div>
                        </Col>
                        {this.state.uploadMedia.uploadFiles.map(
                          (file, index) => {
                            return (
                              <Col xs="auto">
                                <div className="bg-light p-2">
                                  <div className="d-flex pr-3">
                                    <div>
                                      <div
                                        className="selectable-media 123"
                                        style={{ cursor: "default" }}
                                      >
                                        <div className="gallery-media">
                                          {file.media_type === 'image' ?
                                            <img src={file.url} alt="" /> :
                                            <img src={file.thumbnail && file.thumbnail !== null ? file.thumbnail : file.url} alt="" />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mx-n3 mt-2">
                                      <Button
                                        color="delete-media _alt"
                                        size="sm"
                                        title="Remove Media"
                                        hidden={file.id === "" ? true : false}
                                        onClick={this.handleOnClickRemoveSelectedMedia(
                                          file.id
                                        )}
                                      >
                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <Input
                                      bsSize="sm"
                                      type="text"
                                      className="mw-100 caption-input"
                                      name="caption"
                                      placeholder="Caption"
                                      onBlur={(e) =>
                                        this.handleCaptionByIndex(e, index)
                                      }
                                      style={{ maxWidth: '160px' }}
                                    />
                                    <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_type_select" id="selectAlbumTypeData" className="mw-100 mt-1">
                                      <option value="">Choose Category</option>
                                      {file.media_type === "image" && fetchTaxonomyListingImages && Array.isArray(fetchTaxonomyListingImages) && fetchTaxonomyListingImages.length > 0 ?
                                        fetchTaxonomyListingImages.map(item => (
                                          <option value={item.id}>{item.label}</option>
                                        ))
                                        : null}
                                      {file.media_type === "video" && fetchTaxonomyListingVideos && Array.isArray(fetchTaxonomyListingVideos) && fetchTaxonomyListingVideos.length > 0 ?
                                        fetchTaxonomyListingVideos.map(item => (
                                          <option value={item.id}>{item.label}</option>
                                        ))
                                        : null}
                                    </Input>
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        )}
                        <Col xs="auto">
                          <div className="d-flex pt-3">
                            <div className="selectable-media" hidden>
                              <Label
                                for="uploadFilesFromSystemMini"
                                className="gallery-media"
                                style={{
                                  borderStyle: "dashed",
                                  cursor: "pointer",
                                }}
                              >
                                <div className="d-flex h-100 align-items-center justify-content-center">
                                  <span className="fs-14">Upload More</span>
                                </div>
                              </Label>
                              <Input
                                type="file"
                                name="upload_media_file"
                                id="uploadFilesFromSystemMini"
                                accept="image/*, video/*"
                                onChange={this.handleOnFilePostUploadChange}
                                multiple
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              uploadMediaPostModal: false,
                            });
                          }}
                        >
                          {"OK"}
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="gallery">
                    <div className="mb-2">
                      <Nav tabs className="d-inline-flex mb-0">
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "images"}
                            onClick={() => {
                              this.handleOnClickGalleryType("images");
                            }}
                          >
                            Image Gallery
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "videos"}
                            onClick={() => {
                              this.handleOnClickGalleryType("videos");
                            }}
                          >
                            Video Gallery
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.showGalleryType}>
                      <TabPane tabId="images">
                        <div>
                          <FormGroup className="form-row justify-content-end">
                            <Col xs="auto">
                              <Label className="small" for="selectAlbumType">
                                Album Type:
                              </Label>
                            </Col>
                            <Col xs="auto">
                              <Input
                                bsSize="sm"
                                type="select"
                                name="album_type_select"
                                id="selectAlbumType"
                                defaultValue={this.state.uploadMedia.albumType}
                                onChange={this.handleOnClickAlbumTypeChange}
                              >
                                <option value="">All</option>
                                {this.state.uploadMedia.albumTypesList.length >
                                  0 &&
                                  this.state.uploadMedia.albumTypesList.map(
                                    (type) => {

                                      if (type.label) {
                                        return (
                                          <option value={type.category}>
                                            {type.category}
                                          </option>

                                        )
                                      }
                                    }
                                  )}
                              </Input>
                            </Col>
                          </FormGroup>
                        </div>
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryImageScroll}
                          onScroll={this.handleScrollGalleryImage}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>{this.fetchScrollPhotoMedia()}</Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No images</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="videos">
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryVideoScroll}
                          onScroll={this.handleScrollGalleryVideo}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>{this.fetchScrollPhotoMedia(true)}</Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No videos</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="embed">
                    <div className="text-muted mb-2">
                      Submit the link for the image or video you want to embed{" "}
                    </div>
                    <div className="mb-2">
                      {/* Repeat this while adding */}
                      {this.mapEmbedLinks()}
                    </div>
                    <div className="d-flex mx-n2 mb-2">
                      <div className="px-2 ml-auto">
                        <Button
                          title="Add"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            this.addEmbedlinkRow();
                          }}
                        >
                          <FontAwesomeIcon icon="plus" />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.insertEmbedLinks();
                          }}
                        >
                          Insert
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

      </>


    );



  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  upload_scroll: state.myProfile.upload_scroll,
  upload_media_data: state.myProfile.upload_media_data,
  album_types_list: state.user.album_types_list,
  album_type_data: state.user.album_type_data,
  fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
  directorListing: state.footer.directorListing,
  writerListing: state.footer.writerListing,
  castListing: state.footer.castListing,
  producerListing: state.footer.producerListing,
  fetch_save_movie_response: state.movies.fetch_save_movie_response,
});

const mapProps = (dispatch) => ({
  media_upload_scroll: ({ type, album, page, force_update }) =>
    dispatch(media_upload_scroll({ type, album, page, force_update })),
  fetch_media_data: (type, album, page, user_profile) =>
    dispatch(fetch_media_data(type, album, page, user_profile)),
  add_movie: (data) => dispatch(add_movie(data)),
  clear_media_dispatch: () => dispatch(clear_media_dispatch()),
  upload_media_files: (type, data, index) =>
    dispatch(upload_media(type, data, index)),
  upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
  get_album_types_list: () => dispatch(get_album_types_list()),
  get_album_type_data: (type, album, page) =>
    dispatch(get_album_type_data(type, album, page)),
  delete_selected_gallery_media: (dataID) =>
    dispatch(delete_selected_gallery_media(dataID)),
  fetch_taxonomy_list: (category, type) =>
    dispatch(fetch_taxonomy_list(category, type)),
  initFetchMovieSubCategories: (categoryId, type) => dispatch(initFetchMovieSubCategories(categoryId, type)),
  search_moviesMembers: (value, type) => dispatch(search_moviesMembers(value, type)),
});
export default withRouter(connect(mapState, mapProps)(AddMovieModal));
