/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Container, Form, Row, Col, Button } from 'reactstrap';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { get_my_privacy_list, add_privacy_setting } from '../../../actions/user';



class PrivacySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // privacySettingsModalToggle: false,
      view_my_profile: '',
      who_can_see_future_post: '',
      who_can_send_friend_request: '',
      who_can_post_on_your_my_posts_page: '',
      who_can_see_posts_your_tagged_in_on_your_posts_page: '',
      who_can_see_what_others_post_on_your_posts_page: '',
      how_can_i_manage_tags_people_add: '',
      follow_me: '',
      follower_comments: '',
      follower_notifications: '',
      who_can_look_you_using_email: '',
      who_can_look_you_using_phone: '',
      link_search_engine_timelines: '',
      temperature_setting: '',
      measurement_setting: '',
      auto_play_videos: '',
      video_default_quality: '',
      unsubscribe_user_notifications: '',
      email_for_wikireviews_herald: '',
      unsubscribe_from_push_notifications: '',
      filterSelectCompose: {},
      getPrivacyData: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    var checkbox_names = ["posts_all_my_reviews_on_facebook", "crp", 'friend_activity', 'post_all_of_my_community_review_projects_on_facebook', "post_all_of_my_community_review_projects_on_facebook", "automatically_add_pages_i_edit_to_my_watchlist", "dont_put_my_own_changes_on_my_watchlist",
      "notify_friend_activity", "notify_tagging", "notify_review_activity", "notify_qa_activity", "notify_draft", "notify_discussion", "notify_awards"
      , "notify_flagging", "notify_admin", "notify_watchlist",
      "notify_crp_friend", "notify_crp_activity", "notify_crp_draft", "notify_dispute_review", "notify_following_friend_activity", "notify_following_business",
      "notify_following_movies", "notify_following_products", "notify_following_listing", "notify_watchlist_general", "notify_watchlist_discussion_activity",
      "notify_watchlist_frnd_activity", "notify_watchlist_business", "notify_watchlist_product", "notify_watchlist_movie", "notify_anb", "notify_participate",
      "wikireviews_experience", "friend_activity", "discussion_threads", "review_disputes", "community_review_project_summary", "social_sharing", "appeals",
      "contributions", "admin", "awards", "push_friend_activity", "push_tags", "push_comments", "push_question_and_answer", "push_messages"];
    let object = {};
    if (nextProps.my_privacy_setting && nextProps.my_privacy_setting.results && Array.isArray(nextProps.my_privacy_setting.results) && nextProps.my_privacy_setting.results.length > 0) {
      nextProps.my_privacy_setting.results.forEach(
        (data, index) => {

          if (checkbox_names.includes(data.name)) {
            let newJson = {};
            Object.keys(data.value).forEach((key) => {
              newJson[key] = true;
              object[data.name] = newJson;
            }
            );
          }
          else {
            this.setState({
              [data.name]: data.value
            });
          }
        }
      );
      this.setState({
        filterSelectCompose: object

      })
    }
  }

  componentDidMount() {

    this.props.get_my_privacy_list();


  }

  // Change State of input field	
  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value
    });
  }

  handleAllChecked = (e) => {
    let { filterSelectCompose } = this.state;
    let value = e.target.value;
    let name = e.target.name;
    let checked
    this.setState({
      [name]: value
    });

    if (value == "subscribe_user_notifications") {
      checked = true;
      var checkboxes_array = ['notify_friend_activity', 'notify_tagging', 'notify_review_activity', 'notify_qa_activity'
        , 'notify_draft', 'notify_discussion', 'notify_awards', 'notify_admin', 'notify_watchlist', 'notify_flagging',
        'notify_crp_friend', 'notify_crp_activity', 'notify_crp_draft', 'notify_dispute_review', 'notify_following_friend_activity',
        'notify_following_business', 'notify_following_movies', 'notify_following_products', 'notify_following_listing'
        , 'notify_watchlist_general', 'notify_watchlist_discussion_activity', 'notify_watchlist_frnd_activity', 'notify_watchlist_business',
        'notify_watchlist_product', 'notify_watchlist_movie', 'notify_anb', 'notify_participate'];
    } else if (value == "unsubscribe_user_notifications") {
      var checkboxes_array = ['notify_friend_activity', 'notify_tagging', 'notify_review_activity', 'notify_qa_activity'
        , 'notify_draft', 'notify_discussion', 'notify_awards', 'notify_admin', 'notify_watchlist', 'notify_flagging',
        'notify_crp_friend', 'notify_crp_activity', 'notify_crp_draft', 'notify_dispute_review', 'notify_following_friend_activity',
        'notify_following_business', 'notify_following_movies', 'notify_following_products', 'notify_following_listing'
        , 'notify_watchlist_general', 'notify_watchlist_discussion_activity', 'notify_watchlist_frnd_activity', 'notify_watchlist_business',
        'notify_watchlist_product', 'notify_watchlist_movie', 'notify_anb', 'notify_participate'];
      checked = false;
    } else if (value == "subscribe_me_to_all_wikireviews_emails") {
      var checkboxes_array = ['wikireviews_experience', 'friend_activity', 'discussion_threads', 'review_disputes'
        , 'community_review_project_summary', 'social_sharing', 'appeals', 'contributions', 'admin', 'awards', 'crp'];
      checked = true
    } else if (value == "unsubscribe_me_to_all_wikireviews_emails") {
      var checkboxes_array = ['wikireviews_experience', 'friend_activity', 'discussion_threads', 'review_disputes'
        , 'community_review_project_summary', 'social_sharing', 'appeals', 'contributions', 'admin', 'awards', 'crp'];
      checked = false
    } else if (value == "subscribe_from_push_notifications") {
      var checkboxes_array = ['push_friend_activity', 'push_tags', 'push_comments', 'push_question_and_answer'
        , 'push_messages'];
      checked = true
    } else if (value == "unsubscribe_from_push_notifications") {
      var checkboxes_array = ['push_friend_activity', 'push_tags', 'push_comments', 'push_question_and_answer'
        , 'push_messages'];
      checked = false
    }

    let object1 = {};
    checkboxes_array.forEach(
      (data, index) => {
        let checkboxes = document.getElementsByName(data);
        let newJson1 = {};
        checkboxes.forEach(
          (data1, index) => {
            newJson1[data1.value] = checked;
          }
        );
        object1[data] = newJson1;
      }
    );


    Object.keys(this.state.filterSelectCompose).forEach(
      (key, index) => {
        Object.keys(object1).forEach(
          (key1, index1) => {
            if (key === key1) {
              filterSelectCompose[key] = object1[key]
            } else {
              filterSelectCompose[key1] = object1[key1]
            }
          })
      })

  };


  privacySettingsModalToggle = async (data) => {


  };

  handleMultipleCheckbox = (e) => {
    const { name, id, checked, value } = e.target;
    
    this.setState({
      filterSelectCompose: {
        ...this.state.filterSelectCompose,
        [name]: { ...this.state.filterSelectCompose?.[name], [value]: checked }
      }
    })

  }

  handleOnSubmitPrivacySetting = (e) => {
    e.preventDefault();
    let { filterSelectCompose, view_my_profile,
      who_can_see_future_post,
      who_can_send_friend_request,
      who_can_post_on_your_my_posts_page,
      who_can_see_posts_your_tagged_in_on_your_posts_page,
      who_can_see_what_others_post_on_your_posts_page,
      how_can_i_manage_tags_people_add,
      follow_me,
      follower_comments,
      follower_notifications,
      who_can_look_you_using_email,
      who_can_look_you_using_phone,
      link_search_engine_timelines,
      temperature_setting,
      measurement_setting,
      auto_play_videos,
      video_default_quality,
      unsubscribe_user_notifications,
      email_for_wikireviews_herald,
      unsubscribe_from_push_notifications } = this.state;

    let data1 = {};
    if (
      filterSelectCompose &&
      Object.keys(filterSelectCompose).length > 0
    ) {
      Object.keys(filterSelectCompose).forEach(
        (key) => {
          data1[key] = filterSelectCompose[key];
        }
      );
    }

    data1.view_my_profile = view_my_profile
    data1.who_can_see_future_post = who_can_see_future_post
    data1.who_can_send_friend_request = who_can_send_friend_request
    data1.who_can_post_on_your_my_posts_page = who_can_post_on_your_my_posts_page
    data1.who_can_see_posts_your_tagged_in_on_your_posts_page = who_can_see_posts_your_tagged_in_on_your_posts_page
    data1.who_can_see_what_others_post_on_your_posts_page = who_can_see_what_others_post_on_your_posts_page
    data1.how_can_i_manage_tags_people_add = how_can_i_manage_tags_people_add
    data1.follow_me = follow_me
    data1.follower_comments = follower_comments
    data1.follower_notifications = follower_notifications
    data1.who_can_look_you_using_email = who_can_look_you_using_email
    data1.who_can_look_you_using_phone = who_can_look_you_using_phone
    data1.temperature_setting = temperature_setting
    data1.measurement_setting = measurement_setting
    data1.auto_play_videos = auto_play_videos
    data1.video_default_quality = video_default_quality
    data1.link_search_engine_timelines = link_search_engine_timelines
    data1.unsubscribe_user_notifications = unsubscribe_user_notifications
    data1.email_for_wikireviews_herald = email_for_wikireviews_herald
    data1.unsubscribe_from_push_notifications = unsubscribe_from_push_notifications
    this.props.add_privacy_setting(data1);
  };

  render() {

    let { getPrivacyData,
      view_my_profile,
      who_can_see_future_post,
      who_can_send_friend_request,
      who_can_post_on_your_my_posts_page,
      who_can_see_posts_your_tagged_in_on_your_posts_page,
      who_can_see_what_others_post_on_your_posts_page,
      how_can_i_manage_tags_people_add,
      follow_me,
      follower_comments,
      follower_notifications,
      who_can_look_you_using_email,
      who_can_look_you_using_phone,
      temperature_setting,
      measurement_setting,
      auto_play_videos,
      video_default_quality,
      link_search_engine_timelines,
      unsubscribe_user_notifications,
      email_for_wikireviews_herald,
      filterSelectCompose,
      unsubscribe_from_push_notifications
    } = this.state;

    return (

      <div>
        <AppHeader isEnableCover={false} />
        <div className="wk-static-wrapper">
          <Container>
            <div className="wk-static-sub-head">
              <h1>MY PRIVACY & SETTINGS</h1>
            </div>
            <Row>
              <Col lg="12">
                <div className="wk-static-content-body">
                  <Form>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Who Can See My Profile?</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Who can see your User's Info and Selections (Wants, Recommends & Favorites) on your profile page?</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" id="id_view_my_profile_1" className="mr-2" value="all_wiki" name="view_my_profile" checked={view_my_profile === 'all_wiki'} onChange={this.handleChange} /> Just WikiReviews Members with Accounts</label>
                          <label className="fs-14 w-100"><input type="radio" id="id_view_my_profile_2" className="mr-2" value="just_myself" name="view_my_profile" checked={view_my_profile === 'just_myself'} onChange={this.handleChange} /> Just Myself (Blocks your profile from showing in search results)</label>
                          <label className="fs-14 w-100"><input type="radio" id="id_view_my_profile_3" className="mr-2" value="everyone" name="view_my_profile" checked={view_my_profile === 'everyone'} onChange={this.handleChange} /> Everyone</label>
                          <label className="fs-14 w-100"><input type="radio" id="id_view_my_profile_4" className="mr-2" value="jst_my_friends" name="view_my_profile" checked={view_my_profile === 'jst_my_friends'} onChange={this.handleChange} /> Just my WikiReviews Friends</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Who Can See My Stuff?</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Who can see your future posts?</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" checked={who_can_see_future_post === 'friends'} className="mr-2" id="id_who_can_see_future_post_1" value="friends" name="who_can_see_future_post" onChange={this.handleChange} /> Friends (Your friends on WikiReviews)</label>
                          <label className="fs-14 w-100"><input type="radio" checked={who_can_see_future_post === 'public'} className="mr-2" id="id_who_can_see_future_post_2" value="public" name="who_can_see_future_post" onChange={this.handleChange} /> Public (Anyone on or off WikiReviews)</label>
                          <label className="fs-14 w-100"><input type="radio" checked={who_can_see_future_post === 'friends_of_friends'} className="mr-2" id="id_who_can_see_future_post_3" value="friends_of_friends" name="who_can_see_future_post" onChange={this.handleChange} /> Friends of Friends (Your friends of friends on WikiReviews)</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Who can send you friend requests ?</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input id="id_who_can_send_friend_request_1" type="radio" checked={who_can_send_friend_request === 'noone'} className="mr-2" value="noone" name="who_can_send_friend_request" onChange={this.handleChange} /> No one</label>
                          <label className="fs-14 w-100"><input id="id_who_can_send_friend_request_2" type="radio" checked={who_can_send_friend_request === 'everyone'} className="mr-2" value="everyone" name="who_can_send_friend_request" onChange={this.handleChange} /> Everyone</label>
                          <label className="fs-14 w-100"><input id="id_who_can_send_friend_request_3" type="radio" checked={who_can_send_friend_request === 'friends_of_friends'} className="mr-2" value="friends_of_friends" name="who_can_send_friend_request" onChange={this.handleChange} /> Friends of Friends</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">My Posts Page & Tagging</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Who can post on your My Posts Page?</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" id="id_who_can_post_on_your_my_posts_page_1" checked={who_can_post_on_your_my_posts_page === 'friends'} value="friends" className="mr-2" name="who_can_post_on_your_my_posts_page" onChange={this.handleChange} /> Friends</label>
                          <label className="fs-14 w-100"><input type="radio" id="id_who_can_post_on_your_my_posts_page_2" checked={who_can_post_on_your_my_posts_page === 'only_me'} value="only_me" className="mr-2" name="who_can_post_on_your_my_posts_page" onChange={this.handleChange} /> Only me</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Who can see posts your tagged in on your Posts Page?</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" id="id_who_can_see_posts_your_tagged_in_on_your_posts_page_1" checked={who_can_see_posts_your_tagged_in_on_your_posts_page === 'friends'} value="friends" name="who_can_see_posts_your_tagged_in_on_your_posts_page" onChange={this.handleChange} /> Friends</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" id="id_who_can_see_posts_your_tagged_in_on_your_posts_page_2" checked={who_can_see_posts_your_tagged_in_on_your_posts_page === 'everyone'} value="everyone" name="who_can_see_posts_your_tagged_in_on_your_posts_page" onChange={this.handleChange} /> Everyone</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" id="id_who_can_see_posts_your_tagged_in_on_your_posts_page_3" checked={who_can_see_posts_your_tagged_in_on_your_posts_page === 'only_me'} value="only_me" name="who_can_see_posts_your_tagged_in_on_your_posts_page" onChange={this.handleChange} /> Only Me</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" id="id_who_can_see_posts_your_tagged_in_on_your_posts_page_4" checked={who_can_see_posts_your_tagged_in_on_your_posts_page === 'friends_of_friends'} value="friends_of_friends" name="who_can_see_posts_your_tagged_in_on_your_posts_page" onChange={this.handleChange} /> Friends of Friends</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Who can see what others post on your Posts Page?</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={who_can_see_what_others_post_on_your_posts_page === 'friends'} value="friends" id="id_who_can_see_what_others_post_on_your_posts_page_1" name="who_can_see_what_others_post_on_your_posts_page" onChange={this.handleChange} /> Friends</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={who_can_see_what_others_post_on_your_posts_page === 'everyone'} value="everyone" id="id_who_can_see_what_others_post_on_your_posts_page_2" name="who_can_see_what_others_post_on_your_posts_page" onChange={this.handleChange} /> Everyone</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={who_can_see_what_others_post_on_your_posts_page === 'only_me'} value="only_me" id="id_who_can_see_what_others_post_on_your_posts_page_3" name="who_can_see_what_others_post_on_your_posts_page" onChange={this.handleChange} /> Only Me</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={who_can_see_what_others_post_on_your_posts_page === 'friends_of_friends'} value="friends_of_friends" id="id_who_can_see_what_others_post_on_your_posts_page_4" name="who_can_see_what_others_post_on_your_posts_page" onChange={this.handleChange} /> Friends of Friends</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">How can I manage tags people add?</label>
                        </Col>
                        <Col md="8">
                          {/* <label className="fs-14 w-100"><input type="checkbox" className="mr-2" id="id_how_can_i_manage_tags_people_add_0" checked={filterSelectCompose.how_can_i_manage_tags_people_add?.review_tag === true} name="how_can_i_manage_tags_people_add" value="review_tag" onClick={(e) => this.handleMultipleCheckbox(e)} /> Review tags people add to your own posts before the tags appear on WikiReviews?</label> */}
                          <label className="fs-14 w-100"><input type="checkbox" className="mr-2" checked={how_can_i_manage_tags_people_add === 'review_tag'} name="how_can_i_manage_tags_people_add" id="id_how_can_i_manage_tags_people_add_0" value="review_tag" onChange={this.handleChange} /> Review tags people add to your own posts before the tags appear on WikiReviews?</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Follower Settings</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Who can follow me?</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={follow_me === 'everybody'} id="id_follow_me_1" value="everybody" name="follow_me" onChange={this.handleChange} /> Everybody</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={follow_me === 'friends'} id="id_follow_me_2" value="friends" name="follow_me" onChange={this.handleChange} /> Friends</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Follower Comments</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={follower_comments === 'everybody'} id="id_follower_comments_1" name="follower_comments" value="everybody" onChange={this.handleChange} /> Everybody</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={follower_comments === 'friends'} id="id_follower_comments_2" name="follower_comments" value="friends" onChange={this.handleChange} /> Friends</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={follower_comments === 'friends_of_friends'} id="id_follower_comments_3" name="follower_comments" value="friends_of_friends" onChange={this.handleChange} /> Friends of Friends</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Follower Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={follower_notifications === 'everybody'} id="id_follower_notifications_1" name="follower_notifications" value="everybody" onChange={this.handleChange} /> Everybody</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={follower_notifications === 'nobody'} id="id_follower_notifications_2" name="follower_notifications" value="nobody" onChange={this.handleChange} /> Nobody</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={follower_notifications === 'friends_of_friends'} id="id_follower_notifications_3" name="follower_notifications" value="friends_of_friends" onChange={this.handleChange} /> Friends of Friends</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Sharing</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Posts all my Reviews on Social Media</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" className="mr-2" id="id_posts_all_my_reviews_on_facebook_0" checked={filterSelectCompose.posts_all_my_reviews_on_facebook?.twitter === true} name="posts_all_my_reviews_on_facebook" value="twitter" onClick={(e) => this.handleMultipleCheckbox(e)} /> Twitter</label>
                          {/* <label className="fs-14 w-100"><input type="checkbox" className="mr-2" name="account11" value="google_plus"  onClick={(e) => { this.handleSetMutipleCompose({ e}) }} /> Google Plus</label> */}
                          <label className="fs-14 w-100"><input type="checkbox" className="mr-2" id="id_posts_all_my_reviews_on_facebook_2" checked={filterSelectCompose.posts_all_my_reviews_on_facebook?.facebook === true} name="posts_all_my_reviews_on_facebook" value="facebook" onClick={(e) => this.handleMultipleCheckbox(e)} /> Facebook</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Post all of my Community Review Projects on Social Media</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.post_all_of_my_community_review_projects_on_facebook?.twitter === true} className="mr-2" value="twitter" name="post_all_of_my_community_review_projects_on_facebook" id="id_post_all_of_my_community_review_projects_on_facebook_0" onClick={(e) => this.handleMultipleCheckbox(e)} /> Twitter</label>
                          {/* <label className="fs-14 w-100"><input type="checkbox" className="mr-2" value="" name="post_all_of_my_community_review_projects_on_facebook" id="id_post_all_of_my_community_review_projects_on_facebook_1" /> Google Plus</label> */}
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.post_all_of_my_community_review_projects_on_facebook?.facebook === true} className="mr-2" value="facebook" name="post_all_of_my_community_review_projects_on_facebook" id="id_post_all_of_my_community_review_projects_on_facebook_2" onClick={(e) => this.handleMultipleCheckbox(e)} /> Facebook</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Who Can Look Me Up?</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Who can look you up using the email address you provided?</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={who_can_look_you_using_email === 'everyone'} id="id_who_can_look_you_using_email_1" name="who_can_look_you_using_email" value="everyone" onChange={this.handleChange} /> Everybody</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" checked={who_can_look_you_using_email === 'friends_of_friends'} id="id_who_can_look_you_using_email_2" name="who_can_look_you_using_email" value="friends_of_friends" onChange={this.handleChange} /> Friends of Friends</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Who can look you up using the phone number you provided?</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" checked={who_can_look_you_using_phone === 'everyone'} className="mr-2" id="id_who_can_look_you_using_phone_1" name="who_can_look_you_using_phone" value="everyone" onChange={this.handleChange} /> Everybody</label>
                          <label className="fs-14 w-100"><input type="radio" checked={who_can_look_you_using_phone === 'friends_of_friends'} className="mr-2" id="id_who_can_look_you_using_phone_2" name="who_can_look_you_using_phone" value="friends_of_friends" onChange={this.handleChange} /> Friends of Friends</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Do you want other search engines to link to your public profile?</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Let other search engines link to your public profile</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" checked={link_search_engine_timelines === 'yes'} className="mr-2" id="id_link_search_engine_timelines_1" value="yes" name="link_search_engine_timelines" onChange={this.handleChange} /> YES</label>
                          <label className="fs-14 w-100"><input type="radio" checked={link_search_engine_timelines === 'no'} className="mr-2" id="id_link_search_engine_timelines_2" value="no" name="link_search_engine_timelines" onChange={this.handleChange} /> NO</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">My Settings</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Temperature</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" checked={temperature_setting === 'c'} className="mr-2" id="id_temperature_setting_1" name="temperature_setting" value="c" onChange={this.handleChange} /> Celsius</label>
                          <label className="fs-14 w-100"><input type="radio" checked={temperature_setting === 'f'} className="mr-2" id="id_temperature_setting_2" name="temperature_setting" value="f" onChange={this.handleChange} />  Fahrenheit</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">System of Measurement</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" checked={measurement_setting === 'metric_measurement_system'} className="mr-2" id="id_measurement_setting_1" name="measurement_setting" value="metric_measurement_system" onChange={this.handleChange} /> Metric/International System of Units (SI) (ie, Kilograms, Millimeters, Centimeters)</label>
                          <label className="fs-14 w-100"><input type="radio" checked={measurement_setting === 'us_measurement_system'} className="mr-2" id="id_measurement_setting_2" name="measurement_setting" value="us_measurement_system" onChange={this.handleChange} />  US Measurement System (Customer Units/Former English System/Imperial Units).(ie, lbs, Inches, Feet)</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Video Settings</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Auto Play Videos</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" checked={auto_play_videos === 'on'} className="mr-2" value="on" name="auto_play_videos" id="id_auto_play_videos_1" onChange={this.handleChange} /> On</label>
                          <label className="fs-14 w-100"><input type="radio" checked={auto_play_videos === 'off'} className="mr-2" value="off" name="auto_play_videos" id="id_auto_play_videos_2" onChange={this.handleChange} />  Off</label>
                          <label className="fs-14 w-100"><input type="radio" checked={auto_play_videos === 'wifi_only'} className="mr-2" value="wifi_only" name="auto_play_videos" id="id_auto_play_videos_3" onChange={this.handleChange} />  Wi-Fi only</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Video Default Quality</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" checked={video_default_quality === 'default'} className="mr-2" id="id_video_default_quality_1" name="video_default_quality" value="default" onChange={this.handleChange} /> Default</label>
                          <label className="fs-14 w-100"><input type="radio" checked={video_default_quality === 'hd_if_available'} className="mr-2" id="id_video_default_quality_2" name="video_default_quality" value="hd_if_available" onChange={this.handleChange} /> HD if available</label>
                          <label className="fs-14 w-100"><input type="radio" checked={video_default_quality === 'ds_only'} className="mr-2" id="id_video_default_quality_3" name="video_default_quality" value="ds_only" onChange={this.handleChange} /> SD Only</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Watchlist Notifications</h2>
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Automatically add pages I edit to my Watchlist</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.automatically_add_pages_i_edit_to_my_watchlist?.automatically_add_pages_i_edit_to_my_watchlist === true} className="mr-2" id="id_automatically_add_pages_i_edit_to_my_watchlist_0" value="automatically_add_pages_i_edit_to_my_watchlist" name="automatically_add_pages_i_edit_to_my_watchlist" onClick={(e) => this.handleMultipleCheckbox(e)} /> Automatically add pages I edit to my Watchlist</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row>
                        <Col md="4">
                          <label className="fs-14 text-dark font-weight-bold">Don't put my own changes on my Watchlist</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.dont_put_my_own_changes_on_my_watchlist?.dont_put_my_own_changes_on_my_watchlist === true} className="mr-2" id="id_dont_put_my_own_changes_on_my_watchlist_0" value="dont_put_my_own_changes_on_my_watchlist" name="dont_put_my_own_changes_on_my_watchlist" onClick={(e) => this.handleMultipleCheckbox(e)} /> Don’t put my own changes on my Watchlist</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Notification Settings</h2>
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-20 font-weight-bold text-dark">User Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" checked={unsubscribe_user_notifications === 'subscribe_user_notifications'} className="mr-2" id="id_unsubscribe_user_notifications_1" name="unsubscribe_user_notifications" value="subscribe_user_notifications" onChange={this.handleAllChecked} />  Subscribe Me To All User Notifications.</label>
                          <label className="fs-14 w-100"><input type="radio" checked={unsubscribe_user_notifications === 'unsubscribe_user_notifications'} className="mr-2" id="id_unsubscribe_user_notifications_2" name="unsubscribe_user_notifications" value="unsubscribe_user_notifications" onChange={this.handleAllChecked} />  Unsubscribe Me From All User Notifications.</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Friend Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.friend_requests === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_0" onClick={(e) => this.handleMultipleCheckbox(e)} value="friend_requests" /> Friend Requests</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.accepted_friend_requests === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_1" onClick={(e) => this.handleMultipleCheckbox(e)} value="accepted_friend_requests" /> Accepted Friend Requests</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.new_followers === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_2" onClick={(e) => this.handleMultipleCheckbox(e)} value="new_followers" /> New Followers</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.friend_comments_on_your_review === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_3" onClick={(e) => this.handleMultipleCheckbox(e)} value="friend_comments_on_your_review" /> Friend Comments On Your Review</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.friend_comments_on_your_post === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_4" onClick={(e) => this.handleMultipleCheckbox(e)} value="friend_comments_on_your_post" /> Friend Comments On Your Post</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.friend_comments_on_your_photo === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_5" onClick={(e) => this.handleMultipleCheckbox(e)} value="friend_comments_on_your_photo" /> Friend Comments On Your Photo</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.friends_answer_your_questions === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_6" onClick={(e) => this.handleMultipleCheckbox(e)} value="friends_answer_your_questions" /> Friends Answer Your Questions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.friends_posting_on_business_page_walls === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_7" onClick={(e) => this.handleMultipleCheckbox(e)} value="friends_posting_on_business_page_walls" /> Friends Posting On Business Page Walls</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.friends_asking_questions === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_8" onClick={(e) => this.handleMultipleCheckbox(e)} value="friends_asking_questions" /> Friends Asking Questions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.friends_adding_problems_to_listing_pages === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_9" onClick={(e) => this.handleMultipleCheckbox(e)} value="friends_adding_problems_to_listing_pages" /> Friends Adding Problems To Listing Pages</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_friend_activity?.friends_adding_solutions_to_listing_pages === true} className="mr-2" name="notify_friend_activity" id="id_notify_friend_activity_10" onClick={(e) => this.handleMultipleCheckbox(e)} value="friends_adding_solutions_to_listing_pages" /> Friends Adding Solutions To Listing Pages</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Tagging</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_tagging?.photo_tags === true} className="mr-2" name="notify_tagging" id="id_notify_tagging_0" value="photo_tags" onClick={(e) => this.handleMultipleCheckbox(e)} /> Photo tags</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_tagging?.post_tags === true} className="mr-2" name="notify_tagging" id="id_notify_tagging_1" value="post_tags" onClick={(e) => this.handleMultipleCheckbox(e)} /> Post tags</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_tagging?.comment_tags === true} className="mr-2" name="notify_tagging" id="id_notify_tagging_2" value="comment_tags" onClick={(e) => this.handleMultipleCheckbox(e)} /> Comment tags</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Review Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_review_activity?.comment_on_review === true} id="id_notify_review_activity_0" value="comment_on_review" className="mr-2" name="notify_review_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> User Comments On Your Review</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_review_activity?.nailed === true} id="id_notify_review_activity_1" value="nailed" className="mr-2" name="notify_review_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Review Marks Of "Nailed It"</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_review_activity?.priceless === true} id="id_notify_review_activity_2" value="priceless" className="mr-2" name="notify_review_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Review Marks Of "Priceless"</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_review_activity?.lol === true} id="id_notify_review_activity_3" value="lol" className="mr-2" name="notify_review_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Review Marks Of "LOL"</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_review_activity?.bummer === true} id="id_notify_review_activity_4" value="bummer" className="mr-2" name="notify_review_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Review Marks Of "Bummer"</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_review_activity?.insightful === true} id="id_notify_review_activity_5" value="insightful" className="mr-2" name="notify_review_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Review Marks Of "Insightful"</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_review_activity?.wohoo === true} id="id_notify_review_activity_6" value="wohoo" className="mr-2" name="notify_review_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Review Marks Of "Wohoo"</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_review_activity?.community_thanks === true} id="id_notify_review_activity_7" value="community_thanks" className="mr-2" name="notify_review_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Review Marks Of "Community Thanks"</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_review_activity?.compliments === true} id="id_notify_review_activity_8" value="compliments" className="mr-2" name="notify_review_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Compliments</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Q & A Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_qa_activity?.community_answer_to_question === true} className="mr-2" id="id_notify_qa_activity_0" value="community_answer_to_question" name="notify_qa_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Community Answers Your Questions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_qa_activity?.business_owners_answers_your_questions === true} className="mr-2" id="id_notify_qa_activity_1" value="business_owners_answers_your_questions" name="notify_qa_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Business Owners Answers Your Questions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_qa_activity?.thumbs_up_to_answers === true} className="mr-2" id="id_notify_qa_activity_2" value="thumbs_up_to_answers" name="notify_qa_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Thumbs Up To Answers You Provide</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_qa_activity?.thumbs_up_to_questions === true} className="mr-2" id="id_notify_qa_activity_3" value="thumbs_up_to_questions" name="notify_qa_activity" onClick={(e) => this.handleMultipleCheckbox(e)} /> Thumbs Up To Questions You Provide</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Draft Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_draft?.review_drafts === true} className="mr-2" id="id_notify_draft_0" value="review_drafts" name="notify_draft" onClick={(e) => this.handleMultipleCheckbox(e)} /> Review Drafts</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Discussion Thread Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_discussion?.discussion_thread_contributions === true} className="mr-2" id="id_notify_discussion_0" value="discussion_thread_contributions" name="notify_discussion" onClick={(e) => this.handleMultipleCheckbox(e)} /> Discussion Thread Contributions</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Awards</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_awards?.brightest_star === true} className="mr-2" value="brightest_star" id="id_notify_awards_0" name="notify_awards" onClick={(e) => this.handleMultipleCheckbox(e)} /> Brightest Star Awards</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_awards?.gem === true} className="mr-2" value="gem" id="id_notify_awards_1" name="notify_awards" onClick={(e) => this.handleMultipleCheckbox(e)} /> Gem Awards</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_awards?.wikileaderboard_awards === true} className="mr-2" value="wikileaderboard_awards" id="id_notify_awards_2" name="notify_awards" onClick={(e) => this.handleMultipleCheckbox(e)} /> Wikileaderboard Awards</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_awards?.admin_awards === true} className="mr-2" value="admin_awards" id="id_notify_awards_3" name="notify_awards" onClick={(e) => this.handleMultipleCheckbox(e)} /> Admin Awards</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_awards?.first_to_awards === true} className="mr-2" value="first_to_awards" id="id_notify_awards_4" name="notify_awards" onClick={(e) => this.handleMultipleCheckbox(e)} /> First To Awards</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_awards?.first_to_mobile_awards === true} className="mr-2" value="first_to_mobile_awards" id="id_notify_awards_5" name="notify_awards" onClick={(e) => this.handleMultipleCheckbox(e)} /> First To Mobile Awards</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_awards?.contribution_avatar_award === true} className="mr-2" value="contribution_avatar_award" id="id_notify_awards_6" name="notify_awards" onClick={(e) => this.handleMultipleCheckbox(e)} /> Contribution Avatar Award</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Flagging Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_flagging?.image_video_flags === true} className="mr-2" value="image_video_flags" id="id_notify_flagging_0" name="notify_flagging" onClick={(e) => this.handleMultipleCheckbox(e)} /> Image and Video Flags</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_flagging?.copyright_violations === true} className="mr-2" value="copyright_violations" id="id_notify_flagging_0" name="notify_flagging" onClick={(e) => this.handleMultipleCheckbox(e)} /> Copyright Violations</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Admin Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_admin?.admin_nominations === true} className="mr-2" value="admin_nominations" id="id_notify_admin_0" name="notify_admin" onClick={(e) => this.handleMultipleCheckbox(e)} /> Admin Nominations</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Watchlist Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist?.flagged_media === true} className="mr-2" value="flagged_media" id="id_notify_watchlist_0" name="notify_watchlist" onClick={(e) => this.handleMultipleCheckbox(e)} /> Flagged Media</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 className="text-dark mb-2">Community Review Projects Notifications</h2>
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Friend Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_crp_friend?.upload_crp === true} className="mr-2" value="upload_crp" id="id_notify_crp_friend_0" name="notify_crp_friend" onClick={(e) => this.handleMultipleCheckbox(e)} /> Friends Uploading Community Review Projects</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_crp_friend?.friend_review_crp === true} className="mr-2" value="friend_review_crp" id="id_notify_crp_friend_0" name="notify_crp_friend" onClick={(e) => this.handleMultipleCheckbox(e)} /> Friends Reviewing Your Community Review Projects</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Community Review Projects Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_crp_activity?.community_review_project_reviews_completed === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" value="community_review_project_reviews_completed" id="id_notify_crp_activity_0" name="notify_crp_activity" /> Community Review Project Has Been Completed</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_crp_activity?.community_review_project_reviews_live === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" value="community_review_project_reviews_live" id="id_notify_crp_activity_1" name="notify_crp_activity" /> Community Review Project Is Now Live</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_crp_activity?.community_review_project_reviews_not_published === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" value="community_review_project_reviews_not_published" id="id_notify_crp_activity_2" name="notify_crp_activity" /> Community Review Project Is Not Published</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_crp_activity?.new_community_review_project_reviews === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" value="new_community_review_project_reviews" id="id_notify_crp_activity_3" name="notify_crp_activity" /> New Community Review Project Reviews</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Draft Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_crp_draft?.crp_draft === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" value="crp_draft" id="id_notify_crp_draft_0" name="notify_crp_draft" /> Community Review Project Drafts</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 class="text-dark mb-2">Dispute Notifications</h2>
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Dispute Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_dispute_review?.reviews_you_dispute === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_dispute_review_0" value="reviews_you_dispute" name="notify_dispute_review" /> Reviews You Are Disputing</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_dispute_review?.your_reviews_disputed === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_dispute_review_1" value="your_reviews_disputed" name="notify_dispute_review" /> Reviews You Have Written That Are Being Disputed</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 class="text-dark mb-2">Following Notifications</h2>
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Friend Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_friend_activity?.friend_ask_question === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" value="friend_ask_question" id="id_notify_following_friend_activity_0" name="notify_following_friend_activity" /> Friends Asking Questions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_friend_activity?.friend_review_listing_you_follow === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" value="friend_review_listing_you_follow" id="id_notify_following_friend_activity_1" name="notify_following_friend_activity" /> Friends Reviewing Listings You’re Following</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_friend_activity?.friend_create_new_discussion === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" value="friend_create_new_discussion" id="id_notify_following_friend_activity_2" name="notify_following_friend_activity" /> Friends Creating New Discussion Threads</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Businesses</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_business?.new_or_change_biz_info === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_following_business_0" value="new_or_change_biz_info" name="notify_following_business" /> New or Changed Business Information</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_business?.coupons === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_following_business_1" value="coupons" name="notify_following_business" /> Coupons</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_business?.special_offer === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_following_business_2" value="special_offer" name="notify_following_business" /> Special Offers</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Movies</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_movies?.movie_info === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_following_movies_0" value="movie_info" name="notify_following_movies" /> Movie Information</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Products</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_products?.problem_n_solution === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_following_products_0" value="problem_n_solution" name="notify_following_products" /> Problems and Solutions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_products?.product_info === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_following_products_1" value="product_info" name="notify_following_products" /> Product Information</label>                            </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Listing Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_listing?.question_n_answer === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_following_listing_0" value="question_n_answer" name="notify_following_listing" /> Questions and Answers</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_listing?.new_review === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_following_listing_1" value="new_review" name="notify_following_listing" /> New Reviews</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_following_listing?.tips_n_warnings === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_following_listing_2" value="tips_n_warnings" name="notify_following_listing" /> Tips and Warnings</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 class="text-dark mb-2">Watchlist Notifications</h2>
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">General Watchlist Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_general?.watch_new_reviews === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_general_0" value="watch_new_reviews" name="notify_watchlist_general" /> New Reviews</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_general?.watch_media_upload === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_general_1" value="watch_media_upload" name="notify_watchlist_general" /> New Media Uploads</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_general?.watch_new_tips === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_general_2" value="watch_new_tips" name="notify_watchlist_general" /> New Tips and Warnings</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_general?.watch_qa === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_general_3" value="watch_qa" name="notify_watchlist_general" /> Questions and Answers</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_general?.watch_page_flags === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_general_4" value="watch_page_flags" name="notify_watchlist_general" /> Page Flags</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_general?.watch_special_offer === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_general_5" value="watch_special_offer" name="notify_watchlist_general" /> Special Offers</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_general?.watch_coupons === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_general_6" value="watch_coupons" name="notify_watchlist_general" /> Coupons</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Discussion Thread Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_discussion_activity?.watch_discussion_contribution === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_discussion_activity_0" value="watch_discussion_contribution" name="notify_watchlist_discussion_activity" /> Discussion Thread Contributions</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Friend Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_frnd_activity?.frnd_add_problem === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_frnd_activity_0" value="frnd_add_problem" name="notify_watchlist_frnd_activity" /> Friends Adding Problems</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_frnd_activity?.frnd_add_solution === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_frnd_activity_1" value="frnd_add_solution" name="notify_watchlist_frnd_activity" /> Friends Adding Solutions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_frnd_activity?.frnd_answer_question === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_frnd_activity_2" value="frnd_answer_question" name="notify_watchlist_frnd_activity" /> Friends Answering Questions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_frnd_activity?.frnd_ask_question === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_frnd_activity_3" value="frnd_ask_question" name="notify_watchlist_frnd_activity" /> Friends Asking Questions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_frnd_activity?.frnd_create_discussion === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_frnd_activity_4" value="frnd_create_discussion" name="notify_watchlist_frnd_activity" /> Friends Creating New Discussion Threads</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_frnd_activity?.frnd_review_listing === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_frnd_activity_6" value="frnd_review_listing" name="notify_watchlist_frnd_activity" /> Friends Reviewing Listings</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Business Watchlist Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_business?.biz_info_change === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_business_0" value="biz_info_change" name="notify_watchlist_business" /> Changes In Business Information</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_business?.biz_new_media === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_business_1" value="biz_new_media" name="notify_watchlist_business" /> New Media Uploads</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_business?.biz_page_flags === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_business_2" value="biz_page_flags" name="notify_watchlist_business" /> Page Flags</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_business?.biz_coupons === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_business_3" value="biz_coupons" name="notify_watchlist_business" /> Coupons</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_business?.biz_special_offer === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_business_4" value="biz_special_offer" name="notify_watchlist_business" /> Special Offers</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Product Watchlist Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_product?.problem_n_solution === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_product_0" value="problem_n_solution" name="notify_watchlist_product" /> Problems & Solutions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_product?.product_info_change === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_product_1" value="product_info_change" name="notify_watchlist_product" /> Changes In Product Information</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Movie Watchlist Notifications</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_watchlist_movie?.movie_info_change === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_watchlist_movie_0" value="movie_info_change" name="notify_watchlist_movie" /> Changes In Movie Information</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 class="text-dark mb-2">Admin Noticeboard Notifications</h2>
                      <Row className="justify-content-end">
                        <Col md="4"></Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.prc === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_0" value="prc" name="notify_anb" /> Patrol Recent Changes</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.urc === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_1" value="urc" name="notify_anb" /> Urgent Action Committee</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.user_issue === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_2" value="user_issue" name="notify_anb" /> User Issues and Help Team</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.monitoring === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_3" value="monitoring" name="notify_anb" /> User Monitoring</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.suspect === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_4" value="suspect" name="notify_anb" /> Suspect Behavior</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.contributor === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_5" value="contributor" name="notify_anb" /> Contributors to Watch</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.page_issue === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_6" value="page_issue" name="notify_anb" /> Page Issues</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.flag_review === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_7" value="flag_review" name="notify_anb" /> Flagged Reviews</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.disp_resolution === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_8" value="disp_resolution" name="notify_anb" /> Dispute Resolutions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.mediation === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_9" value="mediation" name="notify_anb" /> Mediation & Arbitration Committee</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.ban_request === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_10" value="ban_request" name="notify_anb" /> Ban Requests</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.wiki_herald === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_11" value="wiki_herald" name="notify_anb" /> WikiReviews Herald</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.meeting_place === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_12" value="meeting_place" name="notify_anb" /> Admin Meeting Place</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.page_protection === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_13" value="page_protection" name="notify_anb" /> Page Protections</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.policies === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_14" value="policies" name="notify_anb" /> Policies</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.reports === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_15" value="reports" name="notify_anb" /> Reports</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.collab_corner === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_16" value="collab_corner" name="notify_anb" /> Collab Corner</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_anb?.contests === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_anb_17" value="contests" name="notify_anb" /> Contests</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 class="text-dark mb-2">Participate Noticeboard Notifications</h2>
                      <Row className="justify-content-end">
                        <Col md="4"></Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.patrol_product_changes === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_0" value="patrol_product_changes" name="notify_participate" /> Patrol Recent Product Changes</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.patrol_movie_changes === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_1" value="patrol_movie_changes" name="notify_participate" /> Patrol Recent Movie Changes</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.recent_changes === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_2" value="recent_changes" name="notify_participate" /> Patrol Recent Changes</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.patrol_biz_changes === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_3" value="patrol_biz_changes" name="notify_participate" /> Patrol Recent Business Changes</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.improve_changes === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_4" value="improve_changes" name="notify_participate" /> Improve Pages</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.moderate_changes === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_5" value="moderate_changes" name="notify_participate" /> Moderate Pages</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.voting_review_Activity === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_6" value="voting_review_Activity" name="notify_participate" /> Voting on Review Activity</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.vote_recent_changes === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_7" value="vote_recent_changes" name="notify_participate" /> Vote on Recent Changes</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.biz_community_voting === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_8" value="biz_community_voting" name="notify_participate" /> Community Voting on Businesses</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.movie_community_voting === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_9" value="movie_community_voting" name="notify_participate" /> Community Voting on Movies</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.product_community_voting === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_10" value="product_community_voting" name="notify_participate" /> Community Voting on Products</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.qst_n_ans_voting === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_11" value="qst_n_ans_voting" name="notify_participate" /> Question and Answer Voting</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.qst_n_cmnt_voting === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_12" value="qst_n_cmnt_voting" name="notify_participate" /> Question and Comment Voting</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.discussion_voting === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_13" value="discussion_voting" name="notify_participate" /> Discussion Thread Voting</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.pg_flags === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_14" value="pg_flags" name="notify_participate" /> Page Flags</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.crp_voting === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_15" value="crp_voting" name="notify_participate" /> Community Review Projects Voting</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.admin_nominations === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_16" value="admin_nominations" name="notify_participate" /> Admin Nominations</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.request_voting === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_17" value="request_voting" name="notify_participate" /> Requested Voting</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.prob_n_sol === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_18" value="prob_n_sol" name="notify_participate" /> Problems and Solutions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.community_forum === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_19" value="community_forum" name="notify_participate" /> Community Forum</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.village_pump === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_20" value="village_pump" name="notify_participate" /> Village Pump</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.help_section === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_21" value="help_section" name="notify_participate" /> Help Section</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.prompts === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_22" value="prompts" name="notify_participate" /> Prompts</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.notify_participate?.reported_issues === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_notify_participate_23" value="reported_issues" name="notify_participate" /> Reported Issues</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 class="text-dark mb-2">Email Settings</h2>
                      <Row className="justify-content-end">
                        <Col md="4"></Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" checked={email_for_wikireviews_herald === 'subscribe_me_to_all_wikireviews_emails'} className="mr-2" id="id_email_for_wikireviews_herald_1" value="subscribe_me_to_all_wikireviews_emails" name="email_for_wikireviews_herald" onChange={this.handleAllChecked} /> Subscribe Me To All WikiReviews Emails.</label>
                          <label className="fs-14 w-100"><input type="radio" checked={email_for_wikireviews_herald === 'unsubscribe_me_to_all_wikireviews_emails'} className="mr-2" id="id_email_for_wikireviews_herald_2" value="unsubscribe_me_to_all_wikireviews_emails" name="email_for_wikireviews_herald" onChange={this.handleAllChecked} /> Unsubscribe Me From All WikiReviews Emails.</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">WikiReviews Experience</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.wikireviews_experience?.tips_and_tricks === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_wikireviews_experience_0" value="tips_and_tricks" name="wikireviews_experience" /> Tips and Tricks</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.wikireviews_experience?.mobile_tips_and_tricks === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_wikireviews_experience_1" value="mobile_tips_and_tricks" name="wikireviews_experience" /> Mobile Tips and Tricks</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.wikireviews_experience?.inactivity_prompts === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_wikireviews_experience_2" value="inactivity_prompts" name="wikireviews_experience" /> Inactivity Prompts</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.wikireviews_experience?.issues === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_wikireviews_experience_3" value="issues" name="wikireviews_experience" /> Issues</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.wikireviews_experience?.account_breakdown === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_wikireviews_experience_4" value="account_breakdown" name="wikireviews_experience" /> Account Breakdown</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.wikireviews_experience?.special_offers_and_coupons === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_wikireviews_experience_5" value="special_offers_and_coupons" name="wikireviews_experience" /> Special Offers and Coupons</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.wikireviews_experience?.community_review_projects === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_wikireviews_experience_6" value="community_review_projects" name="wikireviews_experience" /> Community Review Projects</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Friend Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.friend_activity?.new_friend_requests === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_friend_activity_0" value="new_friend_requests" name="friend_activity" /> New Friend Requests</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.friend_activity?.new_friends_on_wikireviews === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_friend_activity_1" value="new_friends_on_wikireviews" name="friend_activity" /> New Friends on WikiReviews</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.friend_activity?.community_review_project_requests === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_friend_activity_2" value="community_review_project_requests" name="friend_activity" /> Community Review Projects Review Requests</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Discussion Threads</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.discussion_threads?.new_disucussions === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_discussion_threads_0" value="new_disucussions" name="discussion_threads" /> New Discussions</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.discussion_threads?.requested_input === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_discussion_threads_1" value="requested_input" name="discussion_threads" /> Requested Input</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.discussion_threads?.outcome_of_threads === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_discussion_threads_2" value="outcome_of_threads" name="discussion_threads" /> Outcome of Threads</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Review Disputes</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.review_disputes?.review_dispute_updates === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_review_disputes_0" value="review_dispute_updates" name="review_disputes" /> Review Dispute Updates</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.review_disputes?.new_review_updates === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_review_disputes_1" value="new_review_updates" name="review_disputes" /> New Review Disputes</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Community Review Projects</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.crp?.community_review_project_summary === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_crp_0" value="community_review_project_summary" name="crp" /> Community Review Project Summary</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.crp?.community_review_project_views === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_crp_1" value="community_review_project_views" name="crp" /> Community Review Project Views</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.crp?.new_community_review_project_reviews === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_crp_2" value="new_community_review_project_reviews" name="crp" /> New Community Review Project Reviews</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.crp?.project_updates === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_crp_3" value="project_updates" name="crp" /> Project Updates</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Social Sharing</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.social_sharing?.link_other_accounts === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_social_sharing_0" value="link_other_accounts" name="social_sharing" /> Link Other Accounts</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.social_sharing?.friend_invitations === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_social_sharing_1" value="friend_invitations" name="social_sharing" /> Friend Invitations</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Appeals</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.appeals?.blocked_accounts === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_appeals_0" value="blocked_accounts" name="appeals" /> Blocked Accounts</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Contributions</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.contributions?.contribution_proof === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_contributions_0" value="contribution_proof" name="contributions" /> Contribution Proof</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.contributions?.added_content === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_contributions_1" value="added_content" name="contributions" /> Added Content</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.contributions?.new_listings === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_contributions_2" value="new_listings" name="contributions" /> New Listings</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.contributions?.sandbox === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_contributions_3" value="sandbox" name="contributions" /> Sandbox</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Admin</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.admin?.admin_nominations === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_admin_0" value="admin_nominations" name="admin" /> Admin Nominations</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Awards</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.awards?.gem === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_awards_0" value="gem" name="awards" /> Gem</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.awards?.brightest_star === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_awards_1" value="brightest_star" name="awards" /> Brightest Star</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.awards?.admin === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_awards_2" value="admin" name="awards" /> Admin</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.awards?.contribution === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_awards_3" value="contribution" name="awards" /> Contribution</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.awards?.first_to_mobile === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_awards_4" value="first_to_mobile" name="awards" /> First To Mobile</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.awards?.first_to === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_awards_5" value="first_to" name="awards" /> First To</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.awards?.leaderboard === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_awards_6" value="leaderboard" name="awards" /> Leaderboard</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 class="text-dark mb-2">Desktop and Mobile User Push Notifications</h2>
                      <Row className="justify-content-end">
                        <Col md="4"></Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" value="subscribe_from_push_notifications" id="id_unsubscribe_from_push_notifications_1" checked={unsubscribe_from_push_notifications === 'subscribe_from_push_notifications'} name="unsubscribe_from_push_notifications" onChange={this.handleAllChecked} /> Subscribe Me To All Desktop & Push Notifications.</label>
                          <label className="fs-14 w-100"><input type="radio" className="mr-2" value="unsubscribe_from_push_notifications" id="id_unsubscribe_from_push_notifications_2" checked={unsubscribe_from_push_notifications === 'on'} name="unsubscribe_from_push_notifications" onChange={this.handleAllChecked} /> Unsubscribe Me From All Desktop & Push Notifications.</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Friend Activity</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_friend_activity?.new_friend_requests === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_friend_activity_0" value="new_friend_requests" name="push_friend_activity" /> New Friend Requests</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_friend_activity?.new_followers === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_friend_activity_1" value="new_followers" name="push_friend_activity" /> New Followers</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Tags</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_tags?.photo_tags === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_tags_0" value="photo_tags" name="push_tags" /> Photo Tags</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_tags?.comment_tags === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_tags_1" value="comment_tags" name="push_tags" /> Comment Tags</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Comments</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_comments?.review_comments === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_comments_0" value="review_comments" name="push_comments" /> Review Comments</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_comments?.post_comments === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_comments_1" value="post_comments" name="push_comments" /> Post Comments</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_comments?.photo_comments === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_comments_2" value="photo_comments" name="push_comments" /> Photo Comments</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Question and Answers</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_question_and_answer?.business_owner_answers === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_question_and_answer_0" value="business_owner_answers" name="push_question_and_answer" /> Business Owner Answers</label>
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_question_and_answer?.community_friend_and_answers === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_question_and_answer_1" value="community_friend_and_answers" name="push_question_and_answer" /> Community and Friend Answers</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <Row className="justify-content-end">
                        <Col md="4">
                          <label className="fs-14 font-weight-bold text-dark">Messages</label>
                        </Col>
                        <Col md="8">
                          <label className="fs-14 w-100"><input type="checkbox" checked={filterSelectCompose.push_messages?.new_messages === true} onClick={(e) => this.handleMultipleCheckbox(e)} className="mr-2" id="id_push_messages_0" value="new_messages" name="push_messages" /> New Messages</label>
                        </Col>
                      </Row>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 class="text-dark mb-2">Deactivate my WikiReviews account</h2>
                      <p className="fs-14">Deactivating your account will remove your personal profile from WikiReviews temporarily. You can reactivate your account anytime by logging back into WikiReviews.</p>
                      <Link to="/deactivateaccount">
                      
                      <Button  color="primary">Deactivate My account</Button>
                      </Link>
                    </div>
                    <div className="wk-privacy-setting-content mb-3 pb-3">
                      <h2 class="text-dark mb-2">Delete my WikiReviews account</h2>
                      <p className="fs-14">Deleting your account will remove your personal profile from wikiReviews permanently. Once you delete your account, it can not be restored. Are you sure about you don't want to deactivate your account temporarily?</p>
                      <Link to="/deleteaccount">
                      <Button color="primary">Delete My account</Button>
                      </Link>
                    </div>
                    <div className="text-right">
                      <Button color="primary">Cancel</Button>
                      <Button color="primary" onClick={(e) => this.handleOnSubmitPrivacySetting(e)}>Submit</Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>


          </Container>
        </div>

      </div>
    )
  }
}

const mapState = (state) => ({
  my_privacy_setting: state.user.my_privacy_setting
});

const mapProps = (dispatch) => ({
  add_privacy_setting: (data) => dispatch(add_privacy_setting(data)),
  get_my_privacy_list: () => dispatch(get_my_privacy_list()),


});


export default withRouter(connect(mapState, mapProps)(PrivacySettings));
