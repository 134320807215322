import React from 'react'
import { Chart } from "react-google-charts";


const RatingBarChart = ({graphType,data,barChartStatus,loading,bgLight}) => {

    const chartColor = bgLight ? '#d1bb78' : '#879ab5'
    const axisStyle = {
        fontName: 'Arial',
        fontSize: '10',
        color: bgLight  ? '#69510c' : "white"
    }
    
    const optionsBar = {
        height: 200,
        chartArea: {
            top: 0, width: '70%', height: '90%', backgroundColor: {
                fill: "transparent"
            }
        },
        bar: { groupWidth: "30%" },
        legend: { position: "none" },
        hAxis: {
            viewWindow: {
                min: 0
            },
            gridlines: { color: 'transparent', count: 2 },
            format: 'decimal',
            baselineColor: chartColor,
            textStyle: axisStyle,
            format: '0'
        },
        vAxis: {
            minValue:0,
            viewWindow: {
                min: 0
            },
            ticks: [{ v: 0, f: '0 Star' }, { v: 1, f: '1 Star' }, { v: 2, f: '2 Stars' }, { v: 3, f: '3 Stars' }, { v: 4, f: '4 Stars' }, { v: 5, f: '5 Stars' }],
            gridlines: { color: 'transparent' },
            textStyle: axisStyle
        },
        backgroundColor: 'transparent',
        baselineColor: chartColor,
        colors: [chartColor],
    };
    

    return (
        <>
            <hr className="bg-light mt-3" />
            <div className="font-weight-bold mb-2">
                <span className={bgLight ? "text-secondary-dark" : "text-tertiary-darker"}>{graphType} </span>
                {graphType == 'Overall Rating' ? ' Distribution' : ' Rating Distribution'}
            </div>
            {loading ? <span>Loading</span> : <>{data && barChartStatus ? (
                <Chart
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={data}
                    options={optionsBar}
                    // For tests
                    rootProps={{ 'data-testid': '1' }}
                />
            ):<span>No data</span>}</>}
            
        </>
    )
}

export default RatingBarChart;

