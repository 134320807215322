import { FINALIZED_EMBED_LINK_SET_TO_EDIT_REVIEW, FINALIZED_EMBED_LINK_SET_TO_FOLLOW_REVIEW, FINALIZED_EMBED_LINK_SET_TO_NEW_REVIEW, FINALIZED_MEDIA_SET_TO_EDIT_REVIEW, FINALIZED_MEDIA_SET_TO_FOLLOW_REVIEW, FINALIZED_MEDIA_SET_TO_NEW_REVIEW, UPLOAD_EMBED_LINK_SET_TO_EDIT_REVIEW, UPLOAD_EMBED_LINK_SET_TO_FOLLOW_REVIEW, UPLOAD_EMBED_LINK_SET_TO_NEW_REVIEW, UPLOAD_MEDIA_SET_TO_EDIT_REVIEW, UPLOAD_MEDIA_SET_TO_FOLLOW_REVIEW, UPLOAD_MEDIA_SET_TO_NEW_REVIEW } from "../reducers/reviewMedia";

// [Main] Create a Review
export const uploadMediaInReviewMediaSetForNewReview = (data) => ({
    type: UPLOAD_MEDIA_SET_TO_NEW_REVIEW,
    payload: data
});


export const uploadMediaInReviewEmbedLinkSetForNewReview = (data) => ({
    type: UPLOAD_EMBED_LINK_SET_TO_NEW_REVIEW,
    payload: data
});


export const finalizedMediaInReviewMediaSetForNewReview = (data) => ({
    type: FINALIZED_MEDIA_SET_TO_NEW_REVIEW,
    payload: data
});


export const finalizedMediaInReviewEmbedLinkSetForNewReview = (data) => ({
    type: FINALIZED_EMBED_LINK_SET_TO_NEW_REVIEW,
    payload: data
});


// Editing a review
export const uploadMediaInReviewMediaSetForEditReview = (data) => ({
    type: UPLOAD_MEDIA_SET_TO_EDIT_REVIEW,
    payload: data
});


export const uploadMediaInReviewEmbedLinkSetForEditReview = (data) => ({
    type: UPLOAD_EMBED_LINK_SET_TO_EDIT_REVIEW,
    payload: data
});


export const finalizedMediaInReviewMediaSetForEditReview = (data) => ({
    type: FINALIZED_MEDIA_SET_TO_EDIT_REVIEW,
    payload: data
});


export const finalizedMediaInReviewEmbedLinkSetForEditReview = (data) => ({
    type: FINALIZED_EMBED_LINK_SET_TO_EDIT_REVIEW,
    payload: data
});


// Follow up a review

export const uploadMediaInReviewMediaSetForFollowReview = (data) => ({
    type: UPLOAD_MEDIA_SET_TO_FOLLOW_REVIEW,
    payload: data
});


export const uploadMediaInReviewEmbedLinkSetForFollowReview = (data) => ({
    type: UPLOAD_EMBED_LINK_SET_TO_FOLLOW_REVIEW,
    payload: data
});


export const finalizedMediaInReviewMediaSetForFollowReview = (data) => ({
    type: FINALIZED_MEDIA_SET_TO_FOLLOW_REVIEW,
    payload: data
});


export const finalizedMediaInReviewEmbedLinkSetForFollowReview = (data) => ({
    type: FINALIZED_EMBED_LINK_SET_TO_FOLLOW_REVIEW,
    payload: data
});