import moment from "moment";


// export const API_URL = `https://wikireviews.com`;
// export const API_URL = `http://54.227.164.83:8000`;
export const API_URL = `https://staging.api.wikireviews.com`;
// export const API_URL = 'http://52.90.129.75:8000'

export const GOOGLE_CLIENT_ID = `161323905488-tknv5d132kfauvo56u9po2698cdidcla.apps.googleusercontent.com`;

export const FACEBOOK_APP_ID = "878744626590326";
// `https://wikireviews.com/api-token` // we will get this later from .env file.
//export const FACEBOOK_APP_ID = "2410123159233166";

// export const GOOGLE_MAP_KEY = "AIzaSyCcEM2DwFBE1dzZn52D1w-N_3NI_97aXMI";
export const GOOGLE_MAP_KEY = "AIzaSyCDQkxFp7zKBVP-iR93cXu89An2aiTZWWw";

export const ONESIGNAL_APP_ID = "91313d36-a10c-4f80-a6c4-23a965165855";

export const WEB_URL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://staging.wikireviews.com";

// Video Review Time Limit. (In Seconds) 
export const VIDEO_REVIEW_TIME_LIMIT_SEC = 300;
export const VIDEO_REVIEW_WARNING_DISPLAY = 60;

export const development_connection_keys = {
  // google: {
  //   client_id: '72303859283-ge12el06qi4no77aghd6vp880ih0mn8n.apps.googleusercontent.com',
  //   api_key: 'AIzaSyBjrFhArjUAQvEUySYWLdvL3K6RGpbQ0Ak',
  //   scope: 'https://www.googleapis.com/auth/contacts.readonly',
  //   redirect_uri: `${WEB_URL}/myprofile`
  // },
  google: {
    client_id: '161323905488-p19s0g12knssa5njn1rnecosbrks9kdt.apps.googleusercontent.com',
    api_key: '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',
    scope: 'https://www.googleapis.com/auth/contacts.readonly',
    redirect_uri: `${WEB_URL}/myprofile`
  },
  yahoo: {
    client_id: 'dj0yJmk9UDh0aFl1d3ZscnZiJnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PWE1',
    secret_key: '447a271a79bfb57d9fff387bb3c1b2379afd8ed1',
    scope: 'sdct-r openid',
    redirect_uri: `${WEB_URL}/myprofile`
  },
  outlook: {
    client_id: '5a35d516-6b05-4976-be85-4078e46e7e25',
    secret_key: '~2A3UV5f_4bD.ikxRcNi~C8ZT33ql-yaY5',
    scope: 'openid+https://outlook.office.com/contacts.read+offline_access',
    redirect_uri: `${WEB_URL}/myprofile`,
  },
  twitter: {
    consumer_key: 'UKxNy1xnC2DUQFpmopCM9sHpb',
    consumer_secret_key: 'Kazr9HI82tzHFJJvXEbM7lAymfNNatqVgPbJuihdH8YMc7mYqR',
    scope: '',
    redirect_uri: `${WEB_URL}/myprofile`,
  },
  facebook: {
    app_id: `${FACEBOOK_APP_ID}`,
    // app_secret: 'dbd2269ccc8db399dffe7fb6a6cbfd7c',
    app_secret: 'b39f3d983b26ec9c7c4145c461dbe476',
    scope: '',
    redirect_uri: `${WEB_URL}/myprofile`,
  },
  linkedin: {
    client_id: '86tu9f2im93vz6',
    client_secret: 'TNbiXPNwpDqttabV',
    scope: 'r_emailaddress r_liteprofile w_member_social r_1st_connections',
    response_type: 'code',
    state: '',
    redirect_uri: `${WEB_URL}/myprofile`,
  }
}

export const responsive = {
  videoModal: {
    mobile: {
      breakpoint: { max: 9999999, min: 0 },
      items: 1
    }
  },
  viewGalleryImages: {
    desktop: {
      breakpoint: { max: 9999999, min: 1200 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1199, min: 576 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1
    }
  }
}

// 1464177653822593


/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM");
  return buildDate;
};

export const badgesReference = {
  // Stars
  Sun: "Monthly Reviews Rank- 1",
  Alpha_Centauri: "Monthly Reviews Rank- 2",
  Sirius: "Monthly Reviews Rank- 3",
  Vega: "Monthly Reviews Rank- 4",
  Capella: "Monthly Reviews Rank- 5",
  Arcturus: "Monthly Reviews Rank- 6",
  Aldebaran: "Monthly Reviews Rank- 7",
  Polaris: "Monthly Reviews Rank- 8",
  Canopus: "Monthly Reviews Rank- 9",
  Antares: "Monthly Reviews Rank- 10",
  // Stones
  Pink_Star_Diamond: "1000+ Monthly Contributions",
  Painite: "900+ Monthly Contributions",
  Musgravite: "700+ Monthly Contributions",
  Jadeite: "500+ Monthly Contributions",
  Alexandrite: "300+ Monthly Contributions",
  Red_Beryl: "200+ Monthly Contributions",
  Benitoite: "100+ Monthly Contributions",
  Black_Opal: "50+ Monthly Contributions",
  Taaffeite: "25+ Monthly Contributions",
  Tanzanite: "10+ Monthly Contributions" 
}