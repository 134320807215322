import React, { Component } from 'react'
import recordRtc from 'recordrtc';
import moment from 'moment';
import { callApi } from '../../../utils/apiCaller'
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Webcam from "react-webcam";
import { VIDEO_REVIEW_TIME_LIMIT_SEC, VIDEO_REVIEW_WARNING_DISPLAY } from '../../../utils/constants';
export default class webCamRecording extends Component {
    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef();
        this.counterInterval = null;
        this.state = {
            file: null,
            uploaded: false,
            startUploading: false,
            clickedOnWriteAReview: props.clickedOnWriteAReview,
            webcam: true,
            confirmation: {
                text: '',
                delete: false,
                outer: false,
                upload: false
            },
            showTimer: false,
            recordingCounter: VIDEO_REVIEW_TIME_LIMIT_SEC,
            isPaused: false
        }
    }

    disableStart = (isStatus) => {
        let controlStart = document.getElementById("control-start");
        if (controlStart) { controlStart.disabled = isStatus; }
    }

    disableStop = (isStatus) => {
        let controlStart = document.getElementById("control-stop");
        if (controlStart) { controlStart.disabled = isStatus; }
    }

    disablePause = (isStatus) => {
        let controlStart = document.getElementById("control-pause");
        if (controlStart) { controlStart.disabled = isStatus; }
        this.setState({isPaused: !isStatus})
    }

    disableResume = (isStatus) => {
        let controlStart = document.getElementById("control-resume");
        if (controlStart) { controlStart.disabled = isStatus; }
        this.setState({isPaused: !isStatus})
    }

    async componentDidMount() {
        this.disableStop(true)
        this.disablePause(true)
        this.disableResume(true)
        if (!this.props.stepTwoUploadedVideo) {
            this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: { echoCancellation: false } });
            this.recorder = new recordRtc(this.stream, {
                type: 'video'
            });
        }  
    }

    componentWillUnmount() {
        if (this && this.stream) {
            this.stream.getTracks().forEach(function (track) { track.stop(); })
        }
    }

    captureCamera = (callback) => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(function (camera) {
            callback(camera);
        }).catch(function (error) {
            alert('Unable to capture your camera. Please check console logs.');
            console.error(error);
        });
    }

    handleStop = () => {
        // Hide Timer
        this.setState({showTimer: false})

        // Stop Recording
        this.recorder.stopRecording(() => {
            this.stopRecordingCallback();
            this.setState({
                confirmation: {
                    text: 'Do you want to upload this video ?',
                    delete: false,
                    outer: false,
                    upload: true
                }
            }, () => {
                this.toggleModal();
            })
        });
    }

    handleCounterInterval = () => {
        this.setState({recordingCounter: this.state.recordingCounter - 1});
        if (this.state.recordingCounter === 0) {
            this.handleStop()
        }
        // if (this.state.recordingCounter === VIDEO_REVIEW_WARNING_DISPLAY) {
        //     toast("1 Minute Remaining", {
        //         autoClose: 2500,
        //         className: "black-background",
        //         bodyClassName: "red-hunt",
        //         progressClassName: "cc",
        //       });
        // }
    }

    stopRecordingCallback = () => { 
        //Clear Interval of Video Time Limit Counter
        clearInterval(this.counterInterval)
        // Resetting the Time Limit Counter
        this.setState({recordingCounter: VIDEO_REVIEW_TIME_LIMIT_SEC})

        this.video.src = this.video.srcObject = null;
        this.video.muted = false;
        this.video.volume = 1;
        this.video.src = URL.createObjectURL(this.recorder.getBlob());
        this.recorder.camera.stop();

        this.disableStart(true);
        this.disableStop(true);
        this.disablePause(true);
        this.disableResume(true);

        let lastModified = new Date();
        let filename = `video-${moment().format('YYYY-MM-DD-HH-MM-SS')}.mp4`
        let file = new File([this.recorder.getBlob()], filename, { type: "video/webm;codecs=opus", lastModified });
        this.setState({ file })
        this.destroyRecorder();
        this.stream?.getTracks().forEach(function (track) { track.stop(); })
    }

    handleDeleteUploadedFile = async () => {
        const deleteURL = this.state.uploaded?.delete_url || this.props.stepTwoUploadedVideo[0]?.delete_url;
        if (deleteURL) {
            try {
                const response = await callApi(deleteURL, 'POST');
                if (!!response) {
                    this.props.removeUploadedVideo && this.props.removeUploadedVideo();
                    toast(`Video deleted successfully`, {
                        autoClose: 3500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                    this.setState({ uploaded: false,webcam:true });
                    this.toggleModal();
                    this.closeItem();
                }
            } catch (err) {
                toast(`Something went wrong while deleting the video.`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "danger",
                    progressClassName: "cc",
                });
                // this.setState({ startUploading: false });
                this.toggleModal();
                this.closeItem();
            }
        }
    }

    handleUploadFile = async () => {
        let url = '/upload/multiuploader/?album=reviews&instance=selfvideo&image=undefined&doc=undefined';
        let file = this.state.file;
        let formData = new FormData();
        formData.append('file', file);

        try {
            const response = await callApi(url, 'POST', formData);
            if (response && response.url) {
                this.props.addUploadedVideo && this.props.addUploadedVideo(response);
                toast(`Video uploaded successfully`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                this.setState({ uploaded: response, startUploading: false });
                this.toggleModal();
                this.closeItem();
            } else {
                toast(`Something went wrong while uploading the video.`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "danger",
                    progressClassName: "cc",
                });
                this.setState({ startUploading: false });
                this.toggleModal();
                this.closeItem();
            }
        } catch (e) {
            console.log(e);
            this.toggleModal();
            this.closeItem();
            this.props.setClickedTabOperation && this.props.setClickedTabOperation({ value: 2 })
        }
    }

    closeItem = () => {
        if(this.video){
            this.video.src = null;
        }        
        this.setState({ file: null }, () => {
            this.disableStart(false);
            this.disableStop(true);
            this.disablePause(true);
            this.disableResume(true);
        });
    }

    handlePause = () => {
        this.recorder.pauseRecording();
        document.getElementById('video-player').pause();
        this.video.pause();
        clearInterval(this.counterInterval)
        this.disablePause(true);
        this.disableResume(false);
        if (this.video.paused) {
            // removed console from here.
        }
    }

    handleResume = () => {
        this.recorder.resumeRecording();
        document.getElementById('video-player').play()
        this.video.play();
        this.disablePause(false);
        this.disableResume(true);

        // Resetting the Video Time Limit Interval
        this.counterInterval = setInterval(this.handleCounterInterval, 1000)

        if (!this.video.paused) {
            // removed console from here.
        }
    }

    handleStart = (e) => {
        this.video = document.getElementById('video-player');
        document.getElementById('video-player').play()
        this.recorder = null;

        // Resetting the Time Limit Counter
        this.setState({recordingCounter: VIDEO_REVIEW_TIME_LIMIT_SEC})
        
        let self = this;

        if (!self.video.paused) {
            // removed console from here.
        }

        this.captureCamera((camera) => {
            self.video.muted = true;
            self.video.volume = 0;
            self.video.srcObject = camera;
            self.video.currentTime = 0;
            self.recorder = recordRtc(camera, {
                type: 'video'
            });

            self.recorder.startRecording();

            // Setting Video Time Limit Interval at each Second
            this.counterInterval = setInterval(this.handleCounterInterval, 1000)

            // Display Timer
            this.setState({showTimer: true})

            // release camera on stopRecording
            self.recorder.camera = camera;
            self.setState({ file: null,webcam:this.props.editReview}, () => {
                self.disableStart(true)
                self.disableStop(false)
                self.disablePause(false)
                self.disableResume(true)
            });
        });
    }

    destroyRecorder = () => {
        this.recorder.destroy();
        this.recorder = null;
        // removed console from here.
    }

    toggleModal = () => {
        this.setState({ isModal: !this.state.isModal });
        if (this.state.confirmation.outer) {
            this.props.setClickedTabOperation({ value: 0 });
        }
    }

    componentDidUpdate(prevProps) {
        let nextProps = this.props;
       
        if (nextProps.clickedOnWriteAReview == 1 && nextProps.clickedOnWriteAReview !== prevProps.clickedOnWriteAReview && this.state.file !== null) {
            this.setState({
                confirmation: {
                    text: 'The video you made will be lost unless you upload it first.',
                    delete: false,
                    outer: true,
                    upload: false
                }
            }, () => {
                this.toggleModal();
            })
        }

        if (nextProps.clickedOnWriteAReview == 1 && !this.state.file) {
            this.props.setClickedTabOperation({ value: 2 });
        }
    }

    resetState = () => {
        // this.setState({
        //     confirmation: {
        //         text: '',
        //         delete: false,
        //         outer: false,
        //         upload: false
        //     },
        //     file:null
        // }, () => {
        //     this.toggleModal();
        // })
        this.setState({
            file: null,
            uploaded: false,
            startUploading: false,
            webcam: true,
            confirmation: {
                text: '',
                delete: false,
                outer: false,
                upload: false
            },
            showTimer: false,
            recordingCounter: VIDEO_REVIEW_TIME_LIMIT_SEC,
            isPaused: false
        }, () => {
            this.toggleModal();
            this.disableStart(false)
        })
    }

    displayTimer = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        let displayMinutes = (minutes >= 10) ? `${minutes}` : `0${minutes}`;
        let displaySeconds = (seconds >= 10) ? `${seconds}` : `0${seconds}`;
        let timerClassNames = 'p-2 text-white font-weight-bold';
        timerClassNames += (this.state.isPaused) ? ' bg-secondary' : ((totalSeconds > 60) ? ' bg-primary' : ' bg-danger');
                
        return (
        <span className={timerClassNames}>
            <span>{`Time Remaining- ${displayMinutes}:${displaySeconds}`}</span>
        </span>)
    }

    render() {
        let { isModal, uploaded, startUploading, file } = this.state;
        let url_item = null;

        if (this.props?.stepTwoUploadedVideo?.length) {
            url_item = this.props.stepTwoUploadedVideo[0].url;
        }
        return (
            <div>
                <Modal size="sm" isOpen={isModal} toggle={this.toggleModal} modalClassName="backdrop-opaque top-shifted transition-none">
                    <ModalHeader toggle={this.toggleModal}></ModalHeader>
                    <ModalBody className="text-center text-dark">
                        <h2 className="mb-3">Confirmation</h2>
                        <p className="small">{this.state.confirmation.text}</p>
                        <div className="pt-4">
                            {!startUploading ? (
                                <div>
                                    <Button
                                        onClick={() => {
                                            if (this.state.confirmation.outer) {
                                                this.props.setClickedTabOperation({ value: 2 });
                                                this.resetState();
                                            } else {
                                                this.resetState();
                                            }
                                        }}
                                        size="md"
                                        color="primary btn-confirm">
                                        {this.state.confirmation.outer ? 'Ok' : 'No'}
                                    </Button>
                                    {this.state.confirmation.outer ? "" : (
                                        <Button
                                            size="md"
                                            color="primary btn-confirm"
                                            onClick={() => {
                                                if (this.state.confirmation.delete) {
                                                    if (url_item) {
                                                        this.handleDeleteUploadedFile()
                                                    } else {
                                                        this.closeItem();
                                                        this.toggleModal();
                                                    }                                                    
                                                }

                                                if (this.state.confirmation.outer) {
                                                    this.setState({ startUploading: true });
                                                    this.handleUploadFile();
                                                }

                                                if (this.state.confirmation.upload) {
                                                    this.setState({ startUploading: true });
                                                    this.handleUploadFile();
                                                }
                                            }}>
                                            {'Yes'}
                                        </Button>
                                    )}
                                </div>
                            ) : (
                                    <div style={{ display: 'inline-flex' }}>
                                        {` Uploading `}  <span className="blink_me ml-3 mb-3"><h5>{` . . . `}</h5></span>
                                    </div>
                                )}
                        </div>
                    </ModalBody>
                </Modal>
                <div className="ml-3">
                    <div className="row mb-3 ml-auto" hidden={(uploaded !== false || url_item !== null) ? true : false}>
                        <Button size="md" id="control-start" color="primary" onClick={this.handleStart}> {'Start Recording'}</Button>
                        <Button size="md" id="control-stop" color="primary" onClick={this.handleStop}> {'Stop Recording'} </Button>
                        <Button size="md" id="control-pause" color="primary" onClick={this.handlePause}> {'Pause'}</Button>
                        <Button size="md" id="control-resume" color="primary" onClick={this.handleResume}> {'Resume'}</Button>
                        
                        
                        {this.state.file !== null && (
                            <a className="ml-3"
                                style={{ textDecoration: 'none', color: '#fff' }}
                                download={`${moment().format('DD_MM_YYYY_HH_mm_ss')}.mp4`}
                                href={URL.createObjectURL(this.state.file)}>
                                <Button size="md" id="control-resume" color="primary">
                                    {'Download'}
                                </Button>
                            </a>
                        )}
                    </div>
                    {/* Display Counter */}
                    {(this.state.showTimer) ? this.displayTimer(this.state.recordingCounter) : (!this.state.file && !uploaded && !url_item) &&
                        (<div>
                            Record a video review of <em>Max length 5 Minutes</em>.
                        </div>)
                    }
                    {(this.state.file == null && url_item == null) ? (
                        <div className="mt-3 file-null w-100">
                            <div className="d-flex pr-2 mt-2">
                                {this.state.webcam ? <Webcam width="100%" /> : ""}
                                <video
                                    id="video-player"
                                    // controls
                                    autoPlay
                                    playsInline
                                    width="100%"
                                    onClick={() => {
                                        var video = document.getElementById('video');
                                        // removed console from here.
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                            <div className="mt-3 have-file" style={{ width: '360px' }}>
                                <div className="d-flex pr-3 mt-2">
                                    <video id="video-player" src={file !== null ? URL.createObjectURL(file) : url_item} playsInline controls width="100%" />

                                    <div className="mt-2 ml-n3 position-relative" hidden={(uploaded !== false || url_item !== null) ? true : false}>
                                        <Button
                                            color="delete-media _alt"
                                            size="sm"
                                            title="Remove Media"
                                            onClick={() => {
                                                // this.closeItem();
                                                this.setState({
                                                    confirmation: {
                                                        text: 'Do you want to delete this video ?',
                                                        delete: true,
                                                        outer: false,
                                                        upload: false
                                                    }
                                                }, () => {
                                                    this.toggleModal();
                                                })
                                            }}>
                                            <FontAwesomeIcon icon="trash-alt" />
                                        </Button>
                                    </div>
                                    <div className="mt-2 ml-n3 position-relative" hidden={(uploaded === false && url_item === null) ? true : false}>
                                        <Button
                                            color="delete-media _alt"
                                            size="sm"
                                            title="Remove Media"
                                            onClick={() => {
                                                // this.closeItem();
                                                this.setState({
                                                    confirmation: {
                                                        text: 'Do you want to delete the uploaded video ?',
                                                        delete: true,
                                                        outer: false,
                                                        upload: false
                                                    }
                                                }, () => {
                                                    this.toggleModal();
                                                })
                                            }}>
                                            <FontAwesomeIcon icon="trash-alt" />
                                        </Button>
                                    </div>
                                </div>

                                {/* <div className="mt-2 text-center" hidden={(uploaded !== false || url_item !== null) ? true : false}>
                                    <Button
                                        onClick={() => {
                                            this.setState({
                                                confirmation: {
                                                    text: 'Do you want to upload this video ?',
                                                    delete: false,
                                                    outer: false,
                                                    upload: true
                                                }
                                            }, () => {
                                                this.toggleModal();
                                            })
                                        }}
                                        size="md"
                                        color="primary">
                                        {'Upload'}
                                    </Button>
                                </div> */}
                            </div>
                        )}
                </div>
            </div >
        )
    }
}