import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Button, FormGroup, Input, Label, Form } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from "../../atoms/editBtn";
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {  add_buyers_vocablory,search_vocablory} from './../../../actions/buyersGuide';
import { Link } from 'react-router-dom';

class Vocablory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tipsText: '',
            isEnable: false,
            toggleTips: false,
            toggleWarning: false,
            warningText: '',
            isWarningEnable: false,
            entryId: null,
            editTip: false,
            editWarning: false,
            vocabList: [],
            editForm: false,
			term:"",
			reference:"",
			definition:"",
			searchVocab:"",
			taxonoId:""

        };
    }

    componentWillReceiveProps(nextProps) {
		if(nextProps.get_buyersVocablory && nextProps.get_buyersVocablory.results && Array.isArray(nextProps.get_buyersVocablory.results)){
            this.setState({vocabList: nextProps.get_buyersVocablory.results})
        }
    }
    

    handleChange = (e) => {
        const target = e.target;
		const value = target.value;
		const name = target.name;
		this.setState({
		  [name]: value,
		  isEnable: true
		});
    }
	handleChangeVocab = (e) => {
		let {searchVocab} = this.state;
		let {taxonoId} = this.props;
        this.setState({
            searchVocab: e.target.value,
        }, () => {
            this.props.search_vocablory(taxonoId,this.state.searchVocab);
        });
    }

    showToastMessage = (message) => {
        toast(message, {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
    }

   submitVocablory = () => {
	   let {taxonoId} = this.props;
	   let {term, definition, reference} = this.state;

       // Validators
       if (!term) {
        this.showToastMessage("Term can not be empty!")
        return;
       } else if (!definition) {
        this.showToastMessage("Description can not be empty!")
        return;
       } else if (!reference) {
        this.showToastMessage("Reference can not be empty!")
        return;
       }

	   let data={
         "term": term,
		 "definition": definition,
	     "reference": reference,
	     "taxonomy": taxonoId
        }

       this.props.add_buyers_vocablory(taxonoId, data);
       this.setState({ term: '', reference: '', definition: '', isEnable:false, toggleTips:false,taxonoId:taxonoId })       
    }

    openVocab = () => {
        let toggle = this.state.toggleTips;
		
        if (!toggle) {
            this.setState({ toggleTips: !this.state.toggleTips, term: '',reference: '', definition: '' })
        }
        else {
            this.setState({ toggleTips: false, term: '',reference: '', definition: '', isEnable: false})
        }
    }
   
    render() {
        let profileId = this.props.my_profile_info;
        let { toggleTips, isEnable, isWarningEnable, vocabList, term, reference, definition,searchVocab } = this.state;
        
        return (
           <div className="bg-white-shaded-md text-secondary p-3 mb-3 guide-edit-mode--highlighted">
					<CollapseBasic title="Vocabulary" containerClass="bg-transparent type-secondary" bodyClass="mt-2 text-secondary-dark" isOpen={true} noPadding={true}>
					  
						 <div className="position-relative wk-buyers-guide-serch mb-4">
							<Input className="form-control vocab-search-input text-secondary" type="text" bsSize="sm"  placeholder="Search" name="searchVocab" value={searchVocab} onChange={this.handleChangeVocab} /> <FontAwesomeIcon className="mr-1" size="sm" icon="search" />
						</div>
						<ul className="list-unstyled" style={{ maxHeight: "400px", overflowY: "scroll" }}>
						{vocabList && Array.isArray(vocabList) && vocabList.length > 0 ?
                            vocabList.map((item, index) => {
                                return (<li className="mb-2" key={index}>
                                        {/* <div>
                                            <strong>Lorem Ipsum: </strong>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima voluptates possimus ullam eaque dolore omnis.
                                        </div>
                                        <div className="mt-2">
                                            <i className="fs-12">Ron Frantz, Fandom, Confidential, 2000</i>
                                        </div>
                                        <hr />

                                        <div className="text-dark">
                                            <div className="font-weight-bold border p-2 mb-3 ff-base">
                                                Lorem Ipsum
                                            </div>
                                            <div className="border p-2 mb-3">
                                                <strong>Lorem Ipsum: </strong>
                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima voluptates possimus ullam eaque dolore omnis.
                                            </div>
                                            <div className="border p-2 mb-3">
                                                <i className="fs-12">Ron Frantz, Fandom, Confidential, 2000</i>
                                            </div>
                                            <hr />
                                        </div> */}
                                        <div className="border-primary mr-3">
                                            <div className="mb-2 position-relative">
                                                {/* If spoiler, sent data-blurr={true} */}
                                                <div className="text-secondary-dark font-weight-medium wb-break-word fs-5" >
                                                   <b> {item.term}</b>:{" "}{item.definition}
                                                </div>
                                            </div>
                                            <div className="mb-2 d-flex mx-n1">
                                               <div className="px-1 col text-break font-italic" style={{ fontSize: "12px" }}>
                                                    {item.reference}
                                                </div>
                                             </div>
                                             {/* <div className="mt-2">
                                                <i className="fs-12">{' '}{item?.author_details?.first_name !== undefined ?
                                                `${item?.author_details?.first_name || ''} ${item?.author_details?.last_name || ''}`
                                                : ''}, {item?.author_details?.permanent_address !== undefined ?
                                                `${item?.author_details?.permanent_address || ''} `
                                                : ''}</i>
                                            </div> */}
                                        </div>
										<hr className="bg-secondary mb-1 mt-2 mr-3" />
                                    </li>
                                    )
                             })
                            :
                            ""}


                    </ul>
						<div className="text-right mb-2">
							<Button
								color="link"
								className="p-0 text-secondary-dark font-weight-bold mt-3" onClick={() => this.openVocab()}>
								 <FontAwesomeIcon className="mr-1" size="sm" icon="plus"/> 
								{'Add Another'}
							</Button>
						</div>
						{toggleTips == true ?
                        <div className="bg-white pt-3">
                           <Form>
                                <FormGroup>
                                    {/* <Label className="text-secondary font-weight-bold">Enter Content <span className="text-danger">*</span></Label> */}
                                    <Input value={term} onChange={this.handleChange} name="term" className="primary" bsSize="sm" type="text" placeholder="Term*" />
                                </FormGroup>
                                <FormGroup>
                                    <Input value={definition} onChange={this.handleChange} name="definition" className="primary" bsSize="sm" type="textarea" placeholder="Description*" />
                                </FormGroup>
                                <FormGroup>
                                    <Input value={reference} onChange={this.handleChange} name="reference" className="primary" bsSize="sm" type="text" placeholder="Source or Reference*" />
                                </FormGroup>
                                <div className="text-right mt-3">
                                    <Button className="mw" color="primary" size="sm" disabled={!isEnable} onClick={() => {
                                                   this.submitVocablory();
                                                }}>Submit</Button>
                                    <Button className="mw" color="primary" size="sm" onClick={() => {
                                        this.openVocab()
                                    }}>Cancel</Button>
                                </div>
                            </Form>
                        </div>
						: 
						""}
                       
					</CollapseBasic>
				</div>


        )
    }
}
const mapState = (state) => ({
    get_buyersVocablory: state.buyersGuide.get_buyersVocablory
});

const mapProps = (dispatch) => ({
    add_buyers_vocablory: (taxnomyId,data) => dispatch(add_buyers_vocablory(taxnomyId,data)),
    search_vocablory: (taxnomyId,search) => dispatch(search_vocablory(taxnomyId,search)),
	
});


export default withRouter(connect(mapState, mapProps)(Vocablory));
