import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Button, FormGroup, Input, Label, Form } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import DeleteBtn from "../../atoms/deleteBtn";
import EditBtn from "../../atoms/editBtn";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {  get_tips_warning, add_tips_warning, edit_tips_warning } from './../../../actions/products';
import { Link } from 'react-router-dom';

class TipsAndWarning extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tipsText: '',
            isEnable: false,
            toggleTips: false,
            toggleWarning: false,
            warningText: '',
            isWarningEnable: false,
            editForm: false,
            entryId: null,
            editTip: false,
            editWarning: false,
            detailList: null
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.tips_war_list && nextProps.tips_war_list.results){
            this.setState({detailList: nextProps.tips_war_list.results})
        }
        // this.setState({ detailList: props.data });
    }
    componentDidMount(){
        let id = this.props.id;
        if(id){
            this.props.get_tips_warning(id);
        }
    }

    deleteEntry = (id, type) => {
        if (id && type) {
            let text = 'Are you sure want to delete?';
            if (type == 'tips') {
                this.props.toggleConfirmModal({ text, uniqueKey: 'delete-product-tips', id });
            }
            if (type == 'warning') {
                this.props.toggleConfirmModal({ text, uniqueKey: 'delete-product-warning', id });
            }
        }
    }

    handleChange = (e) => {
        let text = e.target.value;
        if (text !== '') {
            this.setState({ tipsText: text, isEnable: true })
        }
        else {

            this.setState({ tipsText: text, isEnable: false })
        }
    }

    handleWarning = (e) => {
        let text = e.target.value;
        if (text !== '') {
            this.setState({ warningText: text, isWarningEnable: true })
        }
        else {

            this.setState({ warningText: text, isWarningEnable: false })
        }
    }

    submitTips = (e) => {
        e.preventDefault();
        let form_type = 'tipsform';
        let text = this.state.tipsText;
        let type = 'tips';
        let user_entry = this.props.id;
        let editform = false;
        let tips_id = this.state.entryId;
        let editForm = this.state.editForm;
        let productName = this.props.name;
        if(productName && productName.name){
            productName = productName.name;
        }

        if (text !== '' && form_type == 'tipsform' && type === 'tips' && user_entry && !editForm) {
            let data = { form_type, text, type, user_entry };
            this.props.add_tips_warning(data, productName);
            this.setState({ tipsText: '', toggleTips: false, isEnable: false })
        }
        if (text !== '' && type == 'tips' && tips_id && editForm) {
            editform = true;
            let data = { editform, text, tips_id, type };
            this.props.edit_tips_warning(data, user_entry, productName);
            this.setState({ tipsText: '', toggleTips: false, editForm: false, editTip: false, isEnable: false })
        }
    }

    submitWarning = (e) => {
        e.preventDefault();
        let form_type = 'warningform';
        let text = this.state.warningText;
        let type = 'warning';
        let user_entry = this.props.id;
        let editform = false;
        let tips_id = this.state.entryId;
        let editForm = this.state.editForm;
        let productName = this.props.name;
        if(productName && productName.name){
            productName = productName.name;
        }

        if (text !== '' && form_type == 'warningform' && type === 'warning' && user_entry && !editForm) {
            let data = { form_type, text, type, user_entry };
            this.props.add_tips_warning(data, productName);
            this.setState({ warningText: '', toggleWarning: false, isWarningEnable: false })
        }
        if (text !== '' && type == 'warning' && tips_id && editForm) {
            editform = true;
            let data = { editform, text, tips_id, type };

            this.props.edit_tips_warning(data, user_entry, productName);
            this.setState({ warningText: '', toggleWarning: false, editForm: false, editWarning: false, isWarningEnable: false })
        }

    }

    toggleTips = () => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        let toggle = this.state.toggleTips;

        if (!toggle) {
            this.setState({ toggleTips: !this.state.toggleTips, tipsText: '' })
        }
        else {
            this.setState({ toggleTips: false, tipsText: '', editTip: false, isEnable: false, editForm: false })
        }
    }
    toggleWarning = () => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        let toggle = this.state.toggleWarning;

        if (!toggle) {
            this.setState({ toggleWarning: !this.state.toggleWarning, warningText: '' })
        }
        else {
            this.setState({ toggleWarning: false, warningText: '', editWarning: false, isWarningEnable: false, editForm: false })
        }
    }

    editText = (text, type, id) => {
        if (text !== '' && type == 'tips' && !this.state.editForm) {
            this.setState({ toggleTips: true, tipsText: text, isEnable: true, editForm: true, entryId: id, editTip: true })
        }
        else if (text !== '' && type == 'warning' && !this.state.editForm) {
            this.setState({ toggleWarning: true, warningText: text, isWarningEnable: true, editForm: true, entryId: id, editWarning: true })
        }
        else {
            this.setState({ toggleWarning: false, toggleTips: false, editForm: false })
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_info} = this.props;
        if(my_profile_info && my_profile_info.id && authId && (authId == my_profile_info.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        let profileId = this.props.my_profile_info;
        let { toggleTips, tipsText, isEnable, toggleWarning, warningText, isWarningEnable, editForm, editTip, editWarning, detailList } = this.state;
        
        let tipsData
        let warningData
        if ( detailList && Array.isArray(detailList) && detailList.length > 0 ) {
            tipsData = detailList.filter((item) => item.type == "tips")
            warningData = detailList.filter((item) => item.type == "warning")
        }

        return (
            <div className="bg-white-shaded-md text-secondary p-3 mb-3">
                <h2 className="mr-2">Tips &amp; Warnings</h2>
                <hr className="bg-white" />
                <div className="mb-3">
                    <p className="text-body ff-base lh-1-3">
                        {'Do you have any tips to offer for current or prospective owners? Any warnings concerning purchase or ownership of this product? List away!'}
                    </p>
                </div>

                <CollapseBasic title="Tips" noHr={true} containerClass="bg-transparent type-secondary" bodyClass="mt-3 text-secondary" isOpen={true} size="sm">
                    <ul className="list-unstyled">
                        {tipsData ?
                            tipsData.map((item, index) => {
                                    return (<li className="mb-2" key={index}>
                                        <div className="border border-primary p-2">
                                            <div className="mb-2 position-relative">
                                                {/* If spoiler, sent data-blurr={true} */}
                                                <div className="text-secondary-dark font-weight-medium wb-break-word" >
                                                    {`${index+1}. ${item.text}`}
                                                </div>
                                            </div>
                                            <div className="d-flex mx-n1">
                                                <div className="px-1">
                                                    {item?.profile_pic ?
                                                        <Link
                                                            to={{ pathname: `/people/${item.profile_url}` }}
                                                        >
                                                            <img className="img-circle _30x30" src={item.profile_pic} alt="" onError={(error) =>
                                                                (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                            } />
                                                        </Link>
                                                        :
                                                        <img className="img-circle _30x30" src={require("./../../../assets/images/icons/user-circle.png")} alt="" />
                                                    }

                                                </div>
                                                <div className="px-1 col">
                                                    <span className="text-secondary-dark">by</span> <Link
                                                        to={{ pathname: `/people/${item.profile_url}` }}
                                                        className="font-weight-bold">{item.username}</Link>
                                                    <br />
                                                    {item.created_on}
                                                </div>
                                                {profileId && profileId.id && profileId.id === item.user
                                                    ?
                                                    <div className="px-1">
                                                        <EditBtn color="light" onClick={() => this.editText(item.text, item.type, item.id)} />
                                                        <DeleteBtn color="light" onClick={() => this.deleteEntry(item.id, item.type)} />
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </li>
                                    )
                                
                            })
                            :
                            ""}


                    </ul>

                    <div className="text-right mb-2">
                        <Button color="link" className="p-0 text-secondary-dark font-weight-bold" onClick={this.toggleTips}>
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                                          {(tipsData && Array.isArray(tipsData) && tipsData.length > 0) ? "Add Another" : "Add" }
                                      </Button>
                    </div>
                    {toggleTips ?
                        <div className="bg-white p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">{editTip ? "Edit Your Tip" : ((tipsData && Array.isArray(tipsData) && tipsData.length > 0) ? "Add Another" : "Add")}</h5>
                            <Form onSubmit={this.submitTips}>
                                <FormGroup>
                                    <Label className="text-secondary font-weight-bold">Enter Content <span className="text-danger">*</span></Label>
                                    <Input value={tipsText} onChange={this.handleChange} className="primary" bsSize="sm" type="textarea" placeholder="Please add your tip..." />
                                </FormGroup>

                                <div className="text-right mt-3">
                                    <Button className="mw" color="primary" size="sm" disabled={!isEnable}>Submit</Button>
                                    <Button className="mw" color="primary" size="sm" onClick={this.toggleTips}>Cancel</Button>
                                </div>
                            </Form>
                        </div>

                        :
                        ""}

                </CollapseBasic>

                <hr className="bg-white" />

                <CollapseBasic title="Warnings" noHr={true} containerClass="bg-transparent type-secondary" bodyClass="mt-3 text-secondary" isOpen={true} size="sm">
                    <ul className="list-unstyled">
                        {warningData ?
                            warningData.map((item, index) => {
                                    return (<li className="mb-2" key={index}>
                                        <div className="border border-primary p-2">
                                            <div className="mb-2 position-relative">
                                                {/* If spoiler, sent data-blurr={true} */}
                                                <div className="text-secondary-dark font-weight-medium wb-break-word" >
                                                    {`${index+1}. ${item.text}`}
                                                </div>
                                            </div>
                                            <div className="d-flex mx-n1">
                                                <div className="px-1">
                                                    {item?.profile_pic ?
                                                        <Link
                                                            to={{ pathname: `/people/${item.profile_url}` }}
                                                        >
                                                            <img className="img-circle _30x30" src={item.profile_pic} alt="" onError={(error) =>
                                                                (error.target.src = require("./../../../assets/images/icons/user-circle.png"))
                                                            } />
                                                        </Link>
                                                        :
                                                        <img className="img-circle _30x30" src={require("./../../../assets/images/icons/user-circle.png")} alt="" />
                                                    }

                                                </div>
                                                <div className="px-1 col">
                                                    <span className="text-secondary-dark">by</span> <Link
                                                        to={{ pathname: `/people/${item.profile_url}` }}
                                                        className="font-weight-bold">{item.username}</Link>
                                                    <br />
                                                    {item.created_on}
                                                </div>
                                                {profileId && profileId.id && profileId.id === item.user
                                                    ?
                                                    <div className="px-1">
                                                        <EditBtn color="light" onClick={() => this.editText(item.text, item.type, item.id)} />
                                                        <DeleteBtn color="light" onClick={() => this.deleteEntry(item.id, item.type)} />
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </li>
                                    )
                            
                            })
                            :
                            ""}


                    </ul>

                    <div className="text-right mb-2">
                        <Button color="link" className="p-0 text-secondary-dark font-weight-bold" onClick={this.toggleWarning}>
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                            {(warningData && Array.isArray(warningData) && warningData.length > 0) ? "Add Another" : "Add"}
                                      </Button>
                    </div>
                    {toggleWarning ?

                        <div className="bg-white p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">{editWarning ? "Edit Your Warning" : ((warningData && Array.isArray(warningData) && warningData.length > 0) ? "Add Another" : "Add")}</h5>
                            <Form onSubmit={this.submitWarning}>
                                <FormGroup>
                                    <Input value={warningText} onChange={this.handleWarning} className="primary" bsSize="sm" type="textarea" placeholder="Please add your warning..." />
                                </FormGroup>

                                <div className="text-right mt-3">
                                    <Button className="mw" color="primary" size="sm" disabled={!isWarningEnable} >Submit</Button>
                                    <Button onClick={this.toggleWarning} className="mw" color="primary" size="sm">Cancel</Button>
                                </div>
                            </Form>
                        </div>
                        :
                        ""
                    }
                </CollapseBasic>
            </div>

        )
    }
}
const mapState = (state) => ({
    tips_war_list: state.products.fetch_tips_war_list,
    my_profile_info: state.user.my_profile
});

const mapProps = (dispatch) => ({
    get_tips_warning: (type) => dispatch(get_tips_warning(type)),
    add_tips_warning: (data, name) => dispatch(add_tips_warning(data, name)),
    edit_tips_warning: (data, id, name) => dispatch(edit_tips_warning(data, id, name)),
});


export default withRouter(connect(mapState, mapProps)(TipsAndWarning));