import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { getRefinedParams } from "../utils/utilities";

export const GET_CONTRIBUTIONS = "GET_CONTRIBUTIONS";
export const GET_ADDED_CONTRIBUTIONS = "GET_ADDED_CONTRIBUTIONS";
export const LOADING_CONTRIBUTIONS = "LOADING_CONTRIBUTIONS";


export const get_contributions_ = (data) => {
    return {
        type: GET_CONTRIBUTIONS,
        payload: data,
    };
};


export const get_contributions = (actor = 'me', page = 1,contributionType="",search="") => {       
    return (dispatch) => {
        dispatch(loading_contributions(true));
        callApi(`/api/userfeeds/?${getRefinedParams({actor,page,contributionType,search})}`, "GET").then((response) => {
        dispatch(loading_contributions(false));
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_contributions_(response));
            }
        });
    };
};

export const get_added_contributions_ = (data) => {
    return {
        type: GET_ADDED_CONTRIBUTIONS,
        payload: data,
    };
};

export const get_added_contributions = (actor = 'me', page = 1,contributionType="",search="") => {
    return (dispatch) => {
        callApi(`/api/userfeeds/?actor=${actor}&page=${page}&contribution_type=${contributionType}&search_text${search}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_added_contributions_(response));
            }
        });
    };
};

export const delete_contribution = (id) => {
    return (dispatch) => {
        callApi(`/api/deletecontribution/${id}/`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                toast(`Contribution deleted successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                return true;
            }
        });
    };
};

export const delete_media = (data) => {
    return (dispatch) => {
        callApi(`/upload/multiuploader_delete_multiple/`, "POST", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return true;
            }
        });
    };
};

export const loading_contributions = (data) => {
    return {
        type: LOADING_CONTRIBUTIONS,
        payload: data,
    };
};




