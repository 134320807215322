/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'react-moment';
//import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-multi-carousel';
import { connect } from "react-redux";
import RichTextEditor from 'react-rte';
import { Link } from 'react-router-dom';
import {
    FacebookShareButton,
    TwitterShareButton
} from "react-share";
import { Button, ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import StarsRating from 'stars-rating';
import {
    clear_category, clear_subcategory, fetch_categories_data, fetch_sub_categories,
    filter_categories_data,
    MyProfilepublish_post, publish_post, remove_ratings_vote, save_ratings_vote, share_by_email
} from "../../../actions/business";
import { add_dispute_reply, delete_dispute_reply, get_dispute_discussion } from "../../../actions/feeds";
import { embedNewLink } from '../../../actions/messages';
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { fetch_taxonomy_list, upload_add_captions } from "../../../actions/myProfiles";
import {
    add_vote, delete_my_review, edit_review, get_broadcast_channel,
    post_review_group_myprofile, review_entry
} from '../../../actions/review';
import {
    get_all_disputed_reviews, get_profile_dispute_count
} from '../../../actions/dispute';
import { add_my_post, add_review_group, delete_selected_gallery_media, get_album_types_list, get_album_type_data, get_reviewgroup } from "../../../actions/user";
import { callApi } from "../../../utils/apiCaller";
import CollapseBasic from '../../atoms/collapse';
import DeleteBtn from "../../atoms/deleteBtn";
import WebCamRecording from '../../oragnisms/businessDetails/webCamRecording';
import DisputedReview from "../../atoms/disputeReview/disputedReview";
import DisputingReview from "../../atoms/disputeReview/disputingReview";
import Loader from "../../atoms/loader";
import { checkTextVisibilty, detectlink, getFullName, getShareReviewURL, pushUniqueObjectsInArray } from '../../../utils/utilities';
import CreateReviewFollowUp from '../../atoms/createReviewFollowUp';
import UploadMedia from '../../atoms/fileUploadMedia';
import { finalizedMediaInReviewEmbedLinkSetForFollowReview, finalizedMediaInReviewEmbedLinkSetForNewReview, finalizedMediaInReviewMediaSetForNewReview, uploadMediaInReviewEmbedLinkSetForNewReview, uploadMediaInReviewMediaSetForEditReview, uploadMediaInReviewMediaSetForFollowReview, uploadMediaInReviewMediaSetForNewReview } from '../../../actions/reviewMedia';
import SearchBar from '../../atoms/searchBar';
import ShareByEmail from '../../atoms/shared/shareByEmail';
import ModalComponent from '../../atoms/modalComponent';
import { get_category_taxonomy_listing, update_caption_and_categories } from '../../../actions/itemDetail';
const toolbarConfig = {
    display: [],
};
class Reviews extends Component {
    constructor(props) {
        super(props)
        this.myScrollPhotoRef = React.createRef();
        this.myScrollVideoRef = React.createRef();

        this.refGalleryVideoScroll = React.createRef();
        this.refGalleryImageScroll = React.createRef();

        this.refScrollDeleteImages = React.createRef();
        this.refScrollDeleteVideos = React.createRef();
        this.state = {
            viewReviewsType: 'All',
            viewMainView: true,
            reviews: {},
            channels: {},
            profileInfo: {},
            embedBody: [],
            isModal: false,
            scrollToEnd: false,
            carouselMedia: null,
            secondCarouselMedia: [],
            currentPage: 1,
            currentId: null,
            modalData: null,
            fetchGalleryAlbum: "",
            embeddedLink: "",
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            deletereviewId: 0,
            followupListingId: 0,
            reviewId: 0,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            albumType: '',
            uploadCaptions: {},
            viewReactedType: 'all',
            uploadMediaPostModal: false,
            confirmDeleteModal: false,
            writeFollowUp: false,
            loading: true,
            followUPReviewID: "",
            followUPAssociatedTO: "",
            followUPReview: "",
            followUPReviewVotingKey: "",
            followUPRating_sets: [],
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            editreviewData: {},
            reviewValue: "",
            totalCharacter: 0,
            imageAttacment: "",
            viewPeopleWhoReactedModalToggle: false,
            reactedReviewId: 0,
            review_group_count: {},
            reviewAll: 0,
            fetchReviewGroup: [],
            searchReview: "",
            hoverwoohoo: false,
            hovernailed: false,
            hoverlol: false,
            hoverinsightful: false,
            hoverbummer: false,
            hoverName: "",
            hoverReviewId: 0,
            fetchTaxonomyListing: [],
            fetchTaxomomyCategory: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            taxonomycategoryId: "",
            categoryType: "",
            captionData: "",
            uploadedId: {},
            isImageType: false,
            businseesId: 0,
            cattaxListing: {},
            albumCategoryId: "",
            shareByEmailModalToggle: false,
            reviewCategory:"",
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: '',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            gifwoohoo: {},
            gifnailed: {},
            gifinsightful: {},
            giflol: {},
            gifbummer: {},
            woohooCount: {},
            nailedCount: {},
            insightfulCount: {},
            lolCount: {},
            bummerCount: {},
            businessRatingList: [],
            isOpen: {},
            editReviewChecked: true,
            write_new_review: '',
            mode: "",
            businessRatingIds: [],
            shocialNetworkSharing: false,
            writeFollowupChecked: true,
            editReviewError: "",

            voteReviewModalToggleFour: false,
            reviewAdded: '',
            dispute_imgepreview: [],
            dispute_files: [],
            dispute_reply_text: '',
            days: undefined,
            hours: undefined,
            minutes: undefined,
            seconds: undefined,
            disputeReviewId: '',
            // reviewId: '',
            can_vote: false,
            timer_started: false,

            disputed_reviews_list: [],
            showDisputed: false,
            disputeReviewsCount: 0,
            showDisputedModal: false,
            reviewDetails: '',
            showDisputeModal: false,
            disputeModal: false,
            dispute_count:'',
			textData: RichTextEditor.createEmptyValue(),
			newtextReview: RichTextEditor.createEmptyValue(),
            loadingMoreData: false,
            isToggleWriteUp: {},
            followUpStatus:false,
            isEnableFileUpload: false,
            viewEditReviewStep : "writeReview",
            uploadedCat: {},
        }
    }

    toggleModal = () => {
        this.setState({ editReviewModalToggle: !this.state.editReviewModalToggle })
    };

    componentWillReceiveProps(nextProps) {
        let { currentPage, fetchGalleryScroll, fetchGalleryCount } = this.state;
        const { notPeopleTab, id } = this.props

        if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypesList: nextProps.album_types_list,
                },
                selectedUpMediaType: "upload",
                showGalleryType: "images",
            });
        }
        if (
            nextProps.album_type_data &&
            Object.keys(nextProps.album_type_data).length > 0
        ) {
            let item = nextProps.album_type_data;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypeData: nextProps.album_type_data,
                },
                fetchGalleryScroll, fetchGalleryCount,
            });
        }

        if (nextProps.channels) {
            this.setState({ channels: nextProps.channels });
        }

        if (Object.keys(this.state.profileInfo).length == 0) {
            if (nextProps.my_profile_info) {
                this.setState({
                    profileInfo: nextProps.my_profile_info
                }, () => {
                    this.props.review_entry({ entry: '', user: notPeopleTab === false ? id : nextProps.my_profile_info.id, asset: '', page: currentPage, review: '', review_type: 'All', on_search_filter: 'undefined', });
                })
            }
        }

        if (
            nextProps.get_reveiewData
        ) {
            this.setState({
                fetchReviewGroup: nextProps.get_reveiewData,
            });
        }

        if (nextProps.fetch_data_categories) {
            let { fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state;
            let taxCatListing = nextProps.fetch_data_categories;
            if (taxCatListing && Array.isArray(taxCatListing) && taxCatListing.length > 0) {
                if (taxCatListing[0].isTypes == "image") {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingImages[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingImages });
                    }

                } else {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingVideos[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingVideos });
                    }
                }
            }

        }

        // if (nextProps.filter_data_category) {
        //     let taxCatList = nextProps.filter_data_category;

        //     if (taxCatList && Object.keys(taxCatList).length > 0) {
        //         this.setState({ cattaxListing: taxCatList[0] ? taxCatList[0].children : [] })

        //     }

        // }

        if (nextProps?.cat_taxonomy_list &&
            Array.isArray(nextProps?.cat_taxonomy_list) &&
            nextProps?.cat_taxonomy_list?.length) {
            let cattaxListing = [];
            nextProps.cat_taxonomy_list.forEach(item => {
                if (item?.category?.toLowerCase() === this.state.categoryType) {
                    cattaxListing = cattaxListing.concat(item.children);
                }
            })
            this.setState({ cattaxListing });
        }

        let { reviews, } = this.state;
        if (nextProps.all_disputed_reviews &&
            Object.keys(nextProps.all_disputed_reviews).length > 0) {
            this.setState({
                disputed_reviews_list: nextProps?.all_disputed_reviews,
                disputeReviewsCount: nextProps?.all_disputed_reviews.length
            })

            reviews['results'] = nextProps?.all_disputed_reviews
            this.setState({ reviews, showDisputed: true })

        }

        if (this.state.disputeModal && nextProps?.get_dispute_modal_status?.modal === false) {
            this.setState({ disputeModal: false, showDisputeModal: false, showDisputedModal: false, reviewDetails: '' })
            if (nextProps?.get_dispute_modal_status?.refresh) {
                this.props.get_profile_dispute_count();
                if (this.state.viewReviewsType === 'draftReviews') {
                    this.props.get_all_disputed_reviews('drafted');
                }
                if (this.state.viewReviewsType === 'reviewsIAmDisputing') {
                    this.props.get_all_disputed_reviews('disputing');
                }
            }
        }
        
        if (nextProps?.profile_dispute_count) {
            this.setState({ dispute_count : nextProps?.profile_dispute_count })
        }

        if (nextProps?.reviews?.page == currentPage) {
            let data = this.state?.reviews;
            let fetchMore = false;
            let refCurrent = currentPage;
            if (nextProps?.reviews?.results?.length) {
                if (data?.results?.length && currentPage !== 1) {
                    data.results = pushUniqueObjectsInArray(data.results, nextProps.reviews.results, 'id')
                    // data.results = data.results.concat(nextProps?.reviews?.results);
                } else {
                    data = nextProps?.reviews;
                }
            }

            refCurrent = refCurrent * 10;
            let count = data.count / refCurrent;
            if (count > 1) {
                fetchMore = true;
            }
            this.setState({ reviews: data, scrollToEnd: !fetchMore, loadingMoreData: false });
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        let { currentPage } = this.state;
        if (this.props.reviews !== prevProps.reviews) {
            if (this.props.reviews?.page == currentPage) {
                let data = this.state.reviews;
                let fetchMore = false;
                let refCurrent = currentPage;
                if (this.props?.reviews?.results?.length) {
                    if (data?.results?.length && currentPage !== 1) {
                        data.results = pushUniqueObjectsInArray(this.state.reviews.results, this.props?.reviews?.results, 'id')
                        // data.results = data.results.concat(this.props?.reviews?.results);
                    } else {
                        data = this.props?.reviews;
                    }
                }
                refCurrent = refCurrent * 10;
                let count = data.count / refCurrent;
                if (count > 1) {
                    fetchMore = true;
                }
                this.setState({ reviews: data, scrollToEnd: !fetchMore, loading: false });
            }
        }
    }

    componentDidMount() {
        this.props.get_broadcast_channel();
        this.props.clear_category();
        this.props.clear_subcategory();
        this.props.get_profile_dispute_count();
        setTimeout(() => {
         
            window.addEventListener('scroll', this.handleScroll, { passive: false });
        }, 2000);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        let { scrollToEnd, reviews, currentPage, showDisputed } = this.state;
        const { notPeopleTab, id } = this.props
        if (Object.keys(reviews).length > 0 && !showDisputed) {
            let { getWindowScrollResult } = this.props;
            let getScroll = getWindowScrollResult();
            let { docHeight, windowBottom } = getScroll;
            windowBottom = windowBottom + 100;
            if (windowBottom >= docHeight && docHeight !== 0) {
                if (scrollToEnd == false) {
                    currentPage = currentPage + 1;
                    this.setState({ scrollToEnd: true, loadingMoreData: true, currentPage }, () => {
                        this.props.review_entry({
                            entry: '',
                            user: notPeopleTab === false ? id : this.props.my_profile_info.id,
                            asset: this.state.viewReviewsType == 'All' ? '' : this.state.viewReviewsType,
                            page: currentPage,
                            review: '',
                            review_type: 'All',
                            on_search_filter: 'undefined',
                        });
                    });
                }
            }
        }
    }

    nextPage = (asset) => {
        let { profileInfo, } = this.state;
        const { notPeopleTab, id } = this.props
        this.resetReviews();
        this.setState({ showDisputed: false, currentPage: 1, loadingMoreData: true })
        this.setState({
            searchReview: "",
        })
        this.props.review_entry({
            entry: '',
            user: notPeopleTab === false ? id : profileInfo.id,
            asset: asset == 'All' ? '' : asset,
            page: '1',
            review: '',
            review_type: 'All',
            on_search_filter: 'undefined',
        })
    }

    resetReviews = () => {
        this.setState({
            reviews: {
                next: null,
                page: 1,
                results: [],
                previous: null,
                extra: this.state.reviews?.extra
            }
        })
    }

    handleChildReviewEdit = (item, revId) => {
        if (this.state.viewReviewsType === "Actors" || this.state.viewReviewsType === "Professionals") {
            let reviewtext = item?.professionalreview.replace(/(<([^>]+)>)/gi, "");
            let inputLength = reviewtext.length;
            let mediaIds = []
            item.reviewmedia_set.forEach((evt) => {
                mediaIds.push(evt.id)
            })
            this.setState({
                editReviewModalToggle: true,
                isModal: true,
                modalData: item,
                textData: item && item?.professionalreview != "" ? RichTextEditor.createValueFromString(item?.professionalreview, 'html') : RichTextEditor.createEmptyValue(),
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: item.reviewmedia_set,
                    selectedMediaIds: mediaIds
                },
                reviewId: revId,
                reviewValue: item?.professionalreview?.replace(/(<([^>]+)>)/gi, ""),
                totalCharacter: inputLength,
                categoryType: item?.associated_to?.content_type?.category,
                enableEditReviewSocialShare: false
            })
        } else {
            let reviewtext = item.review.replace(/(<([^>]+)>)/gi, "");
            let inputLength = reviewtext.length;
            let mediaIds = []
            item.reviewmedia_set.forEach((evt) => {
                mediaIds.push(evt.id)
            })
            this.setState({
                editReviewModalToggle: true,
                isModal: true,
                modalData: item,
                textData: item && item.review != "" ? RichTextEditor.createValueFromString(item.review, 'html') : RichTextEditor.createEmptyValue(),
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: item.reviewmedia_set,
                    selectedMediaIds: mediaIds
                },
                reviewId: revId,
                reviewValue: item.review.replace(/(<([^>]+)>)/gi, ""),
                totalCharacter: inputLength,
                categoryType: item.listing_type,
                enableEditReviewSocialShare: false
            })
        }
    }

    // Delete Review
    confirmDeleteModalToggle = () => {
        if (this.state.deletereviewId) {
            this.setState({ deletereviewId: 0, deleteEntity: '' });
        }
        this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
    };

    handleOnDeleteConfirmation = () => {
        if (this.state.viewReviewsType === "Actors" || this.state.viewReviewsType === "Professionals") {
            if (this.state.deletereviewId) {
                this.props.delete_my_review(
                    this.state.deletereviewId, this.state.profileInfo && this.state.profileInfo.id, "professionalreviews",this.state.viewReviewsType
                );
            }
        } else {
            if (this.state.deletereviewId) {
                this.props.delete_my_review(
                    this.state.deletereviewId, this.state.profileInfo && this.state.profileInfo.id,"reviews",this.state.viewReviewsType
                );
            }
        }
        this.setState({
            confirmDeleteModal: !this.state.confirmDeleteModal,
            deletereviewId: 0,
            // viewReviewsType: "All", 
            // viewMainView: true
        });
    };

    // upload media code start
    handleOnClickUploadMedia = () => {
        this.props.get_album_types_list();
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                progress: 0,
                uploadFiles: [],
                uploadedFiles: [],
            },
            uploadMediaPostModal: true,
            selectedUpMediaType: "upload",
            showGalleryType: "images",
        });
    };
    uploadMediaModalToggle = () => {
        this.setState({
            ...this.state,
            uploadMediaPostModal: !this.state.uploadMediaPostModal,
            uploadMedia: {
                ...this.state.uploadMedia,
                selectedMedia: [],
                selectedMediaIds: [],
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                uploadedFiles: [],
                uploadFiles: [],
            },
            selectedUpMediaType: "upload",
            showGalleryType: "images",
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            categoryType: "",
            uploadedId: {},
            captionData: ""
        });
    };

    handleOnClickSelectedUploadMediaType = (type) => {
        if (type === "gallery") {
            this.props.get_album_type_data("image", "", 1);
            this.setState({
                ...this.state,
                selectedUpMediaType: type,
                showGalleryType: "images",
                uploadMedia: {
                    ...this.state.uploadMedia,
                    mediaType: "image",
                    albumType: "",
                    pageNo: 1,
                    embedLinks: {},
                    progress: 0,
                },
            });
        } else if (type === "upload") {
            this.setState({
                ...this.state,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    embedLinks: {},
                    progress: 0,
                },
            });
        } else if (type === "embed") {
            let embedLinks = {
                0: "",
                1: "",
            };
            this.setState({
                ...this.state,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    embedLinks: embedLinks,
                },
            });
        }
    };

    handleOnFilePostUploadChange = (event) => {
        let { categoryType } = this.state;
        let uploadFiles = event.target.files;

        let showFiles = [];

        for (const key of Object.keys(uploadFiles)) {
            let itemType = uploadFiles[key].type.split("/");
            let extName = itemType[0];
            showFiles.push({ id: "", url: extName == "image" ? URL.createObjectURL(uploadFiles[key]) : require("../../../assets/images/blank.png") });
        }

        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                uploadFiles: showFiles,
                progress: 0,
            },
        });
        let progressPart = 100 / showFiles.length;
        let progress = 0;
        for (const key of Object.keys(uploadFiles)) {
            let data = new FormData();
            data.append("file", uploadFiles[key]);
            callApi(
                `/upload/multiuploader/?album=reviews&instance=review_2&image=undefined&doc=undefined`,
                "POST",
                data,
                true
            ).then((response) => {
                this.handleOnClickSelectGalleryMedia(response);
                if (showFiles.length === 1 || key === showFiles.length - 1) {
                    progress = 100;
                } else {
                    progress = progress + progressPart;
                }
                showFiles[key].id = response.id;
                showFiles[key].media_type = response.media_type;
                showFiles[key].thumbnail = response.thumbnail;
                if (response.media_type == "image") {
                    this.setState({ isImageType: true })
					this.props.get_category_taxonomy_listing(categoryType, 'images');

                } else {
                    this.setState({ isImageType: false })
				    this.props.get_category_taxonomy_listing(categoryType ,'videos');	

                }
                // this.props.filter_categories_data({ category: categoryType, sub_cat: "", level: 1 });
                this.setState({
                    ...this.state,
                    uploadMedia: {
                        ...this.state.uploadMedia,
                        progress: progress,
                        // uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
                        uploadedFiles: showFiles,
                    },
                });
            });
        }
    };

    handleOnClickSelectGalleryMedia = (media) => {
        let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        if (index !== -1) {
            newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
                (item) => item.id !== media.id
            );
        } else {
            newSelectedMediaIds = [
                ...this.state.uploadMedia.selectedMediaIds,
                media.id,
            ];
            newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
        }
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                selectedMedia: newSelectedMedia,
                selectedMediaIds: newSelectedMediaIds,
            },
        });
    };

    handleOnClickRemoveSelectedMedia = (id) => () => {
        let removeMediaIds = [];
        removeMediaIds.push(id);
        this.props.delete_selected_gallery_media(removeMediaIds);
        let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
            (file) => file.id !== id
        );
        let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
            (file) => file.id !== id
        );
        let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
            (item) => item !== id
        );
        let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
            (file) => file.id !== id
        );
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                selectedMedia: selectedMedia,
                selectedMediaIds: selectedMediaIds,
                uploadedFiles: uploadedFiles,
                uploadFiles: uploadFiles,
            },
        });
    };

    handleOnClickGalleryType = (type) => {
        let mediaType = "";
        if (type === "images") {
            mediaType = "image";
        } else if (type === "videos") {
            mediaType = "video";
        }
        this.props.get_album_type_data(mediaType, "", 1);
        this.setState({
            ...this.state,
            selectedUpMediaType: "gallery",
            showGalleryType: type,
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: mediaType,
                albumType: "",
                pageNo: 1,

            },
        });
    };

    handleOnClickAlbumTypeChange = (e) => {
        if (e.target.value === 'Disputed') {
            return
        }
        this.props.get_album_type_data("image", e.target.value, 1);
        this.setState({
            ...this.state,
            selectedUpMediaType: "gallery",
            showGalleryType: "images",
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: "image",
                albumType: e.target.value,
                pageNo: 1,

            },
        });
    };

    handleScrollGalleryImage = (e) => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;
            if (nextCount <= roundValue) {
                let page = nextCount;
                this.props.get_album_type_data(type, "", page);

            }
        }
    }

    handleScrollGalleryVideo = (e) => {
        let { refGalleryVideoScroll } = this;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = this.state;
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0) {
                return getDataItems.map(item => {

                    let itemName = (item?.thumbnail).split('.');
                    let itemFile = (item?.file).split('.');
                    let extName = itemName[itemName.length - 1];
                    let extFile = itemFile[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview

                    return (
                        <Col xs="auto" className="mb-3">
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={() =>
                                        this.handleOnClickSelectGalleryMedia(
                                            item
                                        )
                                    } />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    embedLinkOnChange = (id) => (e) => {
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                embedLinks: {
                    ...this.state.uploadMedia.embedLinks,
                    [id]: e.target.value,
                },
            },
        });
    };
    mapEmbedLinks = () => {
        let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
        return embedKeys.map((emKey) => {
            return (
                <div className="d-flex mx-n2 mb-2" key={emKey}>
                    <div className="flex-fill px-2">
                        <Input
                            type="url"
                            bsSize="sm"
                            className="bg-white"
                            value={this.state.uploadMedia.embedLinks[emKey]}
                            onChange={this.embedLinkOnChange(emKey)}
                            placeholder="Embeded link"
                        />
                    </div>
                    <div className="px-2">
                        <Button
                            title="Remove"
                            color="danger"
                            size="sm"
                            hidden={emKey <= 1 ? true : false}
                            onClick={() => {
                                this.deleteEmbedLinkRow(emKey);
                            }}
                        >
                            <FontAwesomeIcon icon="minus" />
                        </Button>
                    </div>
                </div>
            );
        });
    };

    deleteEmbedLinkRow = (emKey) => {
        let embedLinks = this.state.uploadMedia.embedLinks;
        if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
            delete embedLinks[emKey];
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    embedLinks: embedLinks,
                },
            });
        }
    };

    addEmbedlinkRow = () => {
        let newEmbedLinks = {};
        for (let i = 2; i < 5; i += 1) {
            if (
                Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
                -1
            ) {
                newEmbedLinks[i] = "";
                break;
            }
        }
        if (Object.keys(newEmbedLinks).length > 0) {
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    embedLinks: {
                        ...this.state.uploadMedia.embedLinks,
                        ...newEmbedLinks,
                    },
                },
            });
        }
    };

    AddNewEmbedLink = (e) => {
        e.preventDefault();
        let { embeddedLink, embedBody } = this.state;
        /*this.props.embedNewLink({
            data: {
                "video": `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
                "exclude_list": [],
                "body_preview": `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
                "question_rating_category": [],
                "messagemedia_set": []
            }
        });*/
        embedBody.push(embeddedLink);
        this.setState({ embeddedLink: '', embedBody, uploadMediaPostModal: false })
    }

    insertEmbedLinks = () => {       

        let embedValues = Object.values(this.state.uploadMedia.embedLinks).filter(
            (item) => item !== ""
        );

        if (embedValues.length > 0) {
            embedValues = embedValues.map((item) => "<a href=" + item + "><p>" + item + "</p></a>");
            let newPost = {
                video: embedValues.join("\n"),
                exclude_list: [],
                question_rating_category: [],
                messagemedia_set: [],
            };

            this.props.add_my_post(
                newPost,
                this.state.filterType,
                this.state.pageNo
            );

            this.setState({
                ...this.state,
                limitedPostsModal:
                    this.state.reviews.count < this.state.limitedPostsCount
                        ? false
                        : true,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    embedLinks: {},
                },
                uploadMediaPostModal: false,
            });
        }

    };

    handleOnClickRemoveSelectedGalleryMedia = (media) => {
        let removeMediaIds = [];
        removeMediaIds.push(media.id);
        let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
        let newSelectedMediaIds;
        let newSelectedMedia;
        if (index !== -1) {
            newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
                (item) => item !== media.id
            );
            newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
                (item) => item.id !== media.id
            );
            this.props.delete_selected_gallery_media(removeMediaIds);
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: newSelectedMedia,
                    selectedMediaIds: newSelectedMediaIds,
                },
            });
        }
    };

    truncate = (filenameString) => {        
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };

    handleCaptionByIndex = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, uploadMedia, uploadedCat } = this.state;
        uploadCaptions[index] = value;

        let data = {};
        let upload_data = uploadMedia.uploadFiles[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = uploadedCat[index] || '';
        let id = upload_data.id;

        this.setState({ uploadCaptions, captionData: caption_data }, () => {
            this.props.update_caption_and_categories({ id, data });
        });
    }

    handleChangeCategory = (e, index) => {
        let { uploadMedia, uploadedId, uploadCaptions, uploadedCat } = this.state;
        let upload_data = uploadMedia.uploadFiles[index];
        let id = upload_data.id;
        let { value } = e.target;
        uploadedId[index] = id;
        uploadedCat[index] = value;
        let albumType = value !== 'all' ? value : '';
        this.setState({ albumCategoryId: albumType, uploadedId, uploadedCat });
        // let categoryId = categoryType
        // let subcategoryId = value

        this.props.update_caption_and_categories({ id, data: { "caption": uploadCaptions[index] || "", "category": value } });

        // if (isImageType) {
        //     this.props.fetch_categories_data(categoryId, subcategoryId, 'image', index);
        // } else {
        //     this.props.fetch_categories_data(categoryId, subcategoryId, 'video', index);
        // }

    }

    handleChangeTaxonomyData = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, uploadMedia } = this.state
        let data = {};
        let upload_data = uploadMedia.uploadFiles[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = value;
        let id = upload_data.id;
        this.setState({ taxonomycategoryId: value, captionData: caption_data }, () => this.props.upload_add_captions(id, data))

    }

    fetchDataFromHtmlForm = async (type, formID, businessId, listingType) => {
        let url = type === 'edit' ? `/form/reviewedit/${listingType}/${businessId}/${formID}` : `/form/followup/business/${businessId}?followup=true`;
        try {
            const response = await callApi(url, 'GET');
            if (response?.code == 200) {
                let data = response?.data;
                if (data) {
                    const doc = new DOMParser().parseFromString(data, "text/html")

                    let uniqrate = doc.getElementById('follow_uniqrate')?.value;
                    let csrfmiddlewaretoken = doc.getElementsByName('csrfmiddlewaretoken')[0]?.value;
                    let security_hash = doc.getElementsByName('security_hash')[0]?.value;
                    let timestamp = doc.getElementsByName('timestamp')[0]?.value;
                    let honeypot = doc.getElementsByName('honeypot')[0]?.value;
                    let object_pk = doc.getElementsByName('object_pk')[0]?.value;
                    let content_type = doc.getElementsByName('content_type')[0]?.value;
                    let reviewDynamicItems = {};
                    reviewDynamicItems.uniqrate = uniqrate;
                    reviewDynamicItems.csrfmiddlewaretoken = csrfmiddlewaretoken;
                    reviewDynamicItems.security_hash = security_hash;
                    reviewDynamicItems.timestamp = timestamp;
                    reviewDynamicItems.honeypot = honeypot;
                    reviewDynamicItems.object_pk = object_pk;
                    reviewDynamicItems.content_type = content_type;

                    this.setState({ reviewDynamicItems });
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    ratingChanged = async (newRating, name, enable = false) => {
        let reviewDynamicItems = this.state.reviewDynamicItems



        const { save_ratings_vote } = this.props
        let { businessRatingList, editReveiw } = this.state;
        if (!enable) {
            businessRatingList[businessRatingList.findIndex(item => item.key === name.replace(/ +/g, ""))].value = newRating
            this.setState({
                businessRatingList,
                followAverageError: ""
            })

        } else {
            await this.setState({
                [name]: newRating,
                followAverageError: ""
            })
        }

        if (this.state.editReveiw) {
            if (editReveiw['ratings_sets'] && Array.isArray(editReveiw['ratings_sets'][name]) && editReveiw['ratings_sets'][name].length) {
                editReveiw['ratings_sets'][name][1] = newRating;
            } else {
                editReveiw['ratings_sets'][name] = ['25.png', newRating];
            }

            this.setState({ editReveiw });
        }

        let data = {
            csrfmiddlewaretoken: reviewDynamicItems?.csrfmiddlewaretoken,
            score: newRating,
            content_type: reviewDynamicItems?.content_type,
            object_pk: reviewDynamicItems?.object_pk,
            key: name.replace(/ /g, ''),
            timestamp: reviewDynamicItems?.timestamp,
            security_hash: reviewDynamicItems?.security_hash,
            honeypot: reviewDynamicItems?.honeypot ? reviewDynamicItems?.honeypot : '',
            rate_unq_key: reviewDynamicItems?.uniqrate
        }
        save_ratings_vote(data);
    }

    removeVote = async (newRating, name, enable = false) => {
        let reviewDynamicItems = this.state.reviewDynamicItems

        const { remove_ratings_vote } = this.props
        let { businessRatingList, editReveiw } = this.state;
        if (!enable) {
            businessRatingList[businessRatingList.findIndex(item => item.key === name.replace(/ +/g, ""))].value = newRating
            this.setState({
                businessRatingList,
                followAverageError: ""
            })

        } else {
            await this.setState({
                [name]: newRating,
                followAverageError: ""
            })
        }

        if (this.state.editReveiw) {
            if (editReveiw['ratings_sets'] && Array.isArray(editReveiw['ratings_sets'][name]) && editReveiw['ratings_sets'][name].length) {
                editReveiw['ratings_sets'][name][1] = newRating;
            } else {
                editReveiw['ratings_sets'][name] = ['25.png', newRating];
            }

            this.setState({ editReveiw });
        }

        await remove_ratings_vote(
            {
                "key": name.replace(/ /g, ''),
                "voting_key": reviewDynamicItems?.uniqrate
            }
        )
    }

    handleModalForm = (key1, key2, value, voting_key, listing_id) => {
        const { save_ratings_vote } = this.props
        const {
            modalData
        } = this.state
        let reviewDynamicItems = this.state.reviewDynamicItems

      

        let data = {
            csrfmiddlewaretoken: reviewDynamicItems?.csrfmiddlewaretoken,
            score: value,
            content_type: reviewDynamicItems?.content_type,
            object_pk: reviewDynamicItems?.object_pk,
            key: key2 !== 'avg_rating' ? key2.replace(/[^A-Z0-9]/ig, "") : key2,
            timestamp: reviewDynamicItems?.timestamp,
            security_hash: reviewDynamicItems?.security_hash,
            honeypot: reviewDynamicItems?.honeypot ? reviewDynamicItems?.honeypot : '',
            rate_unq_key: reviewDynamicItems?.uniqrate
        }
        save_ratings_vote(data);
        if (key2 == "avg_rating") {

            modalData && Object.keys(modalData).map(element => {
                if (element === key1) {
                    let valueArr = [];
                    valueArr[0] = this.state.modalData && this.state.modalData[key1] && this.state.modalData[key1][key2][0];
                    valueArr[1] = value.toString();
                    this.setState({
                        modalData: {
                            ...this.state.modalData,
                            [key1]: { ...this.state.modalData[key1], [key2]: valueArr }
                        }
                    })
                }
            })

        } else {
            modalData && Object.keys(modalData).map(element => {
                if (element === key1) {
                    let valueArr = [];
                    valueArr[0] = this.state.modalData && this.state.modalData[key1] && this.state.modalData[key1][key2][0];
                    valueArr[1] = value.toString();
                    this.setState({
                        modalData: {
                            ...this.state.modalData,
                            ['ratings_sets']: { ...this.state.modalData['ratings_sets'], [key2]: valueArr }
                        }
                    })
                }
            })
        }

    }

    handleModalFormRemove = (key1, key2, value, voting_key, listing_id) => {

        const {
            modalData
        } = this.state

        if (key2 == "avg_rating") {

            modalData && Object.keys(modalData).map(element => {
                if (element === key1) {
                    let valueArr = [];
                    valueArr[0] = this.state.modalData && this.state.modalData[key1] && this.state.modalData[key1][key2][0];
                    valueArr[1] = value.toString();
                    this.setState({
                        modalData: {
                            ...this.state.modalData,
                            [key1]: { ...this.state.modalData[key1], [key2]: valueArr }
                        }
                    })
                }
            })

        } else {
            modalData && Object.keys(modalData).map(element => {
                if (element === key1) {
                    let valueArr = [];
                    valueArr[0] = this.state.modalData && this.state.modalData[key1] && this.state.modalData[key1][key2][0];
                    valueArr[1] = value.toString();
                    this.setState({
                        modalData: {
                            ...this.state.modalData,
                            ['ratings_sets']: { ...this.state.modalData['ratings_sets'], [key2]: valueArr }
                        }
                    })
                }
            })
        }
    }

    handleChangeFollowReview = (value) => {
        let input = value.toString('html');
        this.setState({
            newtextReview: value,
            write_new_review: input
        })
    }

    handleChangeEditor = (value) => {
        const {
            modalData
        } = this.state;

        let input = value.toString('html');
        let key1 = 'review';
        if (["Actors", "Professionals"].includes(this.state.viewReviewsType)) {
            key1 = "professionalreview";
        }
        modalData && Object.keys(modalData).map(element => {
            if (element === key1) {
                this.setState({
                    modalData: {
                        ...this.state.modalData,
                        [key1]: input
                    },
                    textData: value,
                    reviewValue: input,
                    totalCharacter: input.length,
                })
            }
        })
    }

    handleChange = (key1, evt, value) => {
        const {
            modalData
        } = this.state
        var input = evt.target.value;
        modalData && Object.keys(modalData).map(element => {
            if (element === key1) {
                this.setState({
                    modalData: {
                        ...this.state.modalData,
                        [key1]: evt.target.value
                    },
                    reviewValue: "<p>" + evt.target.value + "</p>",
                    totalCharacter: input.length,
                })
            }
        })

    }

    handleSearchReview = (value) => {
        const { viewReviewsType } = this.state;
        const { notPeopleTab, id } = this.props
        this.setState({
            ...this.state,
            searchResultsEnabled: value?.length ? true : false,
        });
        let { profileInfo } = this.state;
        this.props.review_entry({
            entry: '',
            user: notPeopleTab === false ? id : profileInfo.id,
            asset: viewReviewsType == 'All' ? '' : viewReviewsType,
            page: '1',
            review: '',
            review_type: 'All',
            on_search_filter: value.length > 0 ? value : 'undefined',
        })

    }

    reviewsAPI = ()=>{

        const { viewReviewsType } = this.state;
        const { notPeopleTab, id } = this.props
       
        let { profileInfo } = this.state;
        this.props.review_entry({
            entry: '',
            user: notPeopleTab === false ? id : profileInfo.id,
            asset: viewReviewsType == 'All' ? '' : viewReviewsType,
            page: '1',
            review: '',
            review_type: 'All',
            on_search_filter: "",
        })

    }

    handleMouseIn(event) {
        var name = "";
        if (event.target.getAttribute("data-name") != null) {
            name = event.target.getAttribute("data-name");
        }
        var id = 0;
        if (event.target.getAttribute("data-id") != null) {
            id = parseInt(event.target.getAttribute("data-id"));
        }
        if (name == "bummer") {
            this.setState({ hoverbummer: true, hoverReviewId: id })
        } else if (name == "lol") {
            this.setState({ hoverlol: true, hoverReviewId: id })
        } else if (name == "nailed") {
            this.setState({ hovernailed: true, hoverReviewId: id })
        } else if (name == "insightful") {
            this.setState({ hoverinsightful: true, hoverReviewId: id })
        } else if (name == "woohoo") {
            this.setState({ hoverwoohoo: true, hoverReviewId: id })
        }
        if (name != "" && id != 0) {
            let assetType = "review";
            this.props.get_reviewgroup(assetType, id, name)
        }

    }

    handleMouseOut(event) {
        var name = "";
        if (event.target.getAttribute("data-name") != null) {
            name = event.target.getAttribute("data-name");
        }
        var id = 0;
        if (event.target.getAttribute("data-id") != null) {
            id = parseInt(event.target.getAttribute("data-id"));
        }
        if (name == "bummer") {
            this.setState({ hoverbummer: false, hoverReviewId: id })
        } else if (name == "lol") {
            this.setState({ hoverlol: false, hoverReviewId: id })
        } else if (name == "nailed") {
            this.setState({ hovernailed: false, hoverReviewId: id })
        } else if (name == "insightful") {
            this.setState({ hoverinsightful: false, hoverReviewId: id })
        } else if (name == "woohoo") {
            this.setState({ hoverwoohoo: false, hoverReviewId: id })
        }
        if (name != "" && id != 0) {
            let assetType = "review";
            this.props.get_reviewgroup(assetType, id, name)
        }
    }

    handleOnClickReacted = (type) => {
        const { reactedReviewId } = this.state;
        if (type != "" && reactedReviewId != 0) {
            let assetType = "review";
            this.props.get_reviewgroup(assetType, reactedReviewId, type)
            this.setState({ viewReactedType: type });
        }
    }

    handleOnSubmitReview = (e) => {
        e.preventDefault();
        let type = ""
        if (e.target.getAttribute("data-id") != null) {
            type = e.target.getAttribute("data-id");
        }
        const isProfessionalCategory = ["Actors", "Professionals"].includes(this.state.viewReviewsType)
        const { reviewValue, reviewId, embedBody } = this.state;
        let editDetails;
        if (isProfessionalCategory) {
            editDetails = {
                associated_with: reviewId,
                professionalreview: reviewValue,
                is_draft_p: type == "draft" ? true : false,
                review_rating_category: [],
                media: this.state.uploadMedia.selectedMediaIds,
                ratings_set: {
                    avg_rating: this.state.modalData && this.state.modalData['ratings_sets'] ? this.state.modalData['ratings_sets']['avg_rating'][1] : 0
                }
            };
        } else {
            editDetails = {
                professional: "",
                review: reviewValue,
                is_draft: type == "draft" ? true : false,
                review_rating_category: [],
                media: this.state.uploadMedia.selectedMediaIds,
                ratings_set: {
                    avg_rating: this.state.modalData && this.state.modalData['ratings_sets'] ? this.state.modalData['ratings_sets']['avg_rating'][1] : 0
                }
            };
        }
        
        if (embedBody?.length) {
            editDetails['embed_body'] = embedBody.join(',');
        }

        if (reviewValue && reviewValue.replace(/(<([^>]+)>)/gi, "") !== "") {
            this.props.edit_review(reviewId, editDetails, this.state.profileInfo.id, isProfessionalCategory ? "professionalreviews" : "reviews", this.state.modalData);
            this.setState({
                ...this.state,
                reviewValue: "",
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                },
                editReviewModalToggle: false,
                isModal: false,
                editReviewChecked: false,
                editReviewError: "",
                viewReviewsType: "All",
                mainViewType: true
            });

        }

    };

    addreviewGroup = async (show, id, type, item) => {
        let {
            gifbummer,
            giflol,
            gifnailed,
            gifinsightful,
            gifwoohoo,
            woohooCount,
            nailedCount,
            insightfulCount,
            lolCount,
            bummerCount,
        } = this.state;
        let data = {};
        data.review = id;
        data.type = type;
        const categoryType = item?.associated_to?.content_type?.category
        let categorySection

        if(["accountants","Accountants", "doctors","Doctors", "dentists","Dentists", "lawyers","Lawyers", "actors","Actors"].includes(categoryType)){ 
            categorySection = "professsionalReview"
        }else{
            categorySection = "review"
        }

        if (type == "bummer") {
            let bummerNumber = 0;
            if (bummerCount[id] == 1) {
                gifbummer[id] = !show;
                bummerNumber = 0;
            } else {
                bummerNumber = 1;
                gifbummer[id] = show;
            }
            bummerCount[id] = bummerNumber;
            this.setState({ gifbummer, bummerCount });
        } else if (type == "lol") {
            let lolNumber = 0;
            if (lolCount[id] == 1) {
                giflol[id] = !show;
                lolNumber = 0;
            } else {
                giflol[id] = show;
                lolNumber = 1;
            }
            lolCount[id] = lolNumber;
            this.setState({ giflol, lolCount });
        } else if (type == "nailed") {
            let nailedNumber = 0;
            if (nailedCount[id] == 1) {
                gifnailed[id] = !show;
                nailedNumber = 0;
            } else {
                gifnailed[id] = show;
                nailedNumber = 1;
            }
            nailedCount[id] = nailedNumber;
            this.setState({ gifnailed, nailedCount });
        } else if (type == "insightful") {
            let insightfulNumber = 0;
            if (insightfulCount[id] == 1) {
                gifinsightful[id] = !show;
                insightfulNumber = 0;
            } else {
                gifinsightful[id] = show;
                insightfulNumber = 1;
            }
            insightfulCount[id] = insightfulNumber;
            this.setState({ gifinsightful, insightfulCount });
        } else if (type == "woohoo") {
            let wooNumber = 0;
            if (woohooCount[id] > 0) {
                gifwoohoo[id] = !show;
                wooNumber = 0;
            } else {
                gifwoohoo[id] = show;
                wooNumber = 1;
            }
            woohooCount[id] = wooNumber;
            this.setState({ gifwoohoo, woohooCount });
        }
        this.timeout = await setTimeout(() => {
            gifbummer[id] = !show;
            gifwoohoo[id] = !show;
            gifinsightful[id] = !show;
            gifnailed[id] = !show;
            giflol[id] = !show;
            this.setState({
                gifwoohoo,
                giflol,
                gifnailed,
                gifinsightful,
                gifbummer,
            });
        }, 500);
        this.props.add_review_group(data,categorySection);

    };

    handleOpenModal(data, url, index) {
        this.setState({ viewMyPhotosModal: !this.state.viewMyPhotosModa, imageAttacment: data, carouselMedia: data })
    }

    handleOnClickReviewType = (review, reviewId, type) => {
        let reviewAl = 0;
        reviewAl = review.review_group_count.lol + review.review_group_count.woohoo + review.review_group_count.insightful + review.review_group_count.bummer + review.review_group_count.nailed;
        this.setState({ viewPeopleWhoReactedModalToggle: !this.state.viewPeopleWhoReactedModalToggle, review_group_count: review, reviewAll: reviewAl, viewReactedType: type, reactedReviewId: reviewId });
    };

    fetchReviewGroupList = (type) => {
        let fetchReviewGroup = this.state.fetchReviewGroup;
        if (fetchReviewGroup && fetchReviewGroup.length > 0) {
            return fetchReviewGroup.map((item, index) => {
                //if(item.group_icon == type){
                return (
                    <li className="mb-3" key={index}>
                        <div className="d-flex">
                            <div className="mr-2 media media-post">
                                <img
                                    className="media-object"
                                    width="50" height="50"
                                    src={item.profile_pic ? item.profile_pic : require("../../../assets/images/icons/user-circle.png")} alt={item?.name} />
                            </div>
                            <div>
                                <a href={item.redirect_to} className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"> {item.name}</a>
                                {type == "all" ? (<img src={require("../../../assets/images/icons/" + item.group_icon + "-active.png")} alt="" />) : ""}
                            </div>
                        </div>
                    </li>

                )
                //}
            })
        } else {
            return (
                <div className="text-secondary-dark font-weight-bold">No People Reacted.</div>
            )
        }
    }

    handleOnChangeShareByEmail = (e) => {
        let errors = { ...this.state.shareError };
        if (e.target.value) {
            delete errors[e.target.name];
        }

        this.setState({
            [e.target.name]: e.target.value,
            shareError: errors
        });
    }

    handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote }) => {
        const key = 'listing_id';
        let url
        const professionalCategories = ['accountants', 'doctors', 'dentists', 'lawyers', 'actors', 'professionals','accountant', 'doctor', 'dentist', 'lawyer', 'actor', 'professional']
        if (professionalCategories.includes(this.state.reviewCategory?.toLowerCase())) {
            url = `/api/professionalShareByemail/`
          } else {
            url = `/api/shareByemail/`;
          }
        let review_id = this.state.shareId;
        let data;
        if (review_id) {
          data = {
            review_id: review_id,
            mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        } else {
          data = {
            [key]: this.state.shareId,
            mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        }
    
        this.props.share_by_email(url, data);
      }

    handleOnClickShareByEmail = (id,cat) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id,
            reviewCategory:cat
        });
    }

    handleOnExpand = async (expand, id) => {
        let { isOpen } = this.state;
        isOpen[id] = expand;
        await this.setState({ isOpen });
    }

    handelChangeReview = (e) => {
        // 'QualityofEquipmentCheck'

        this.setState({
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        })
    }

    ratingChangedForCheck = (checked, data) => {
        if (checked) {
            this.state.businessRatingIds.push(data.id);
        } else {
            let el = this.state.businessRatingIds.find(
                (itm) => itm === parseInt(data.id)
            );
            if (el)
                this.state.businessRatingIds.splice(
                    this.state.businessRatingIds.indexOf(el),
                    1
                );
        }
    }

    publishPost = async (dataList) => {
        const {
            avg_rating,
            write_new_review,
            businessRatingIds,
            shocialNetworkSharing,
            writeFollowupChecked,
            writeFollowUp,
            followUPReviewID,
            followUPAssociatedTO,
            mode,
            reviewDynamicItems,
            followUPReview,
            embedBody
        } = this.state
        const {
            MyProfilepublish_post
        } = this.props


        if (writeFollowUp) {
            if (avg_rating === 0) {
                this.setState({
                    followAverageError:
                        "Please provide Overall Rating",
                });
            } else {
                const data = {
                    associated_to: followUPAssociatedTO,
                    followup: mode == "followup" ? writeFollowUp : false,
                    followup_avg_rate: avg_rating ? avg_rating : 0,
                    is_draft: dataList === "draft",
                    media: this.state.uploadMedia && this.state.uploadMedia.selectedMediaIds,
                    review: write_new_review,
                    review_rating_category: businessRatingIds,
                    review_voting_key: reviewDynamicItems?.uniqrate == "" ? reviewDynamicItems?.uniqrate : '',
                    social_broadcast: shocialNetworkSharing == true ? true : false,
                    social_broadcast_facebook: shocialNetworkSharing == true ? true : false,
                    social_broadcast_twitter: shocialNetworkSharing == true ? true : false,
                    tips_guide: writeFollowupChecked,
                    parent_review_id: followUPReviewID
                }
                if (embedBody?.length) {
                    data['embed_body'] = embedBody.join(',');
                }

                await MyProfilepublish_post(data, followUPReview.associated_to.name);
                await this.clearReveiwRatingState();
                // removed console from here.
            }
        }

    }

    clearReveiwRatingState = async () => {
        await this.setState({
            avg_rating: 0,
            businessRatingList: this.state.businessRatingListDefaut,
            write_new_review: "",
            newtextReview: RichTextEditor.createEmptyValue(),
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            searchTheater: '',
            albumType: '',
            fetchTaxonomy: [],
            businessRatingIds: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            uploadCaptions: {},
            writeFollowUp: false,
            followUPReviewID: "",
            followUPAssociatedTO: "",
            followUPReview: "",
            followUPReviewVotingKey: "",
            followUPRating_sets: [],
            followAverageError: "",
            followName: "",
            mode: "",
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            categoryType: "",
            captionData: "",
            uploadedId: {},
            editReveiw: null,
            embedBody: [],
        })
    }

    // handleHeatedDiscussion = (feed) => {
    //     this.setState({ voteReviewModalToggleFour: true, disputeReviewId: feed.id });
    //     this.setState({
    //         reviewAdded: feed,
    //         timer_started : false,
    //     })
    //     this.props.get_dispute_discussion(feed.id);
    // }
    handleHeatedDiscussion = (feed) => {
        this.setState({ showDisputedModal: true, reviewDetails: feed, disputeModal: true })
    };

    redirectToSlugUrl = (item) => {
        if (this.state.viewReviewsType === 'Actors' || this.state.viewReviewsType === "Professionals") {
            let id = (item?.associated_to?.id)?.toString();
            if (item?.associated_to?.slug) {
                return ({
                    pathname: `/${item?.associated_to?.content_type?.category?.toLowerCase()}/${item?.associated_to?.slug}`,
                    state: { id }
                })
            } else {
                return ({
                    pathname: `/${item?.associated_to?.content_type?.category?.toLowerCase()}/${id}`,
                    state: { id }
                })
            }
        } else {
            let id = (item?.listing_id)?.toString();
            if (item?.associated_to?.slug) {
                return ({
                    pathname: `/${item?.listing_type}/${item?.associated_to?.slug}`,
                    state: { id }
                })
            } else {
                return ({
                    pathname: `/${item?.listing_type}`,
                    state: { id }
                })
            }
        }
    }

    handleDisputeTabs = (tab) => {
        const { reviews } = this.state
        reviews['results'] = []
        this.setState({ disputed_reviews_list: [], reviews })
        this.props.get_all_disputed_reviews(tab);
    }

    handleDisputeThisReview = (item) => {
        this.setState({ showDisputeModal: true, reviewDetails: item, disputeModal: true })

    }
    toggleWriteFollowUp = ({ isTrue, itemId }) => {
        let { isToggleWriteUp } = this.state;
        if (!isTrue) {
          isToggleWriteUp = {};
          this.setState({ isToggleWriteUp });
        } else {
          const tempObj = {}
          tempObj[itemId] = isTrue;
          this.setState({ isToggleWriteUp:tempObj });
        }    
      }

      toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false, reviewOneToggler,followUpStatus) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
        this.setState({
            reviewOneToggler: reviewOneToggler? true: false,
            followUpStatus:followUpStatus
        })
    }

    getUploadedMedia = ({ mediaSet, links }) => { 
        if (this.state.reviewOneToggler) {

            if (this.props.isFollowReviewEnable) {

                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.followReviewMediaSet, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForFollowReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.followReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForFollowReview(embedSetUnique);
                }
            } else {

                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.newReviewMediaSet, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForNewReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
                }
            }
        } else {
            if (this.props.isEditReviewEnabled) {
                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.editReviewMediaSetFinalized, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForEditReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
                }
            }
            
        }

        //redux
    
    }

    handleOnClickUploadModalOk = () => {
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }
    render() {
        let { reviews, modalData, totalCharacter, imageAttacment,
            review_group_count, reviewAll, searchReview,
            hoverbummer, hoverinsightful, hoverlol,
            hovernailed, hoverwoohoo, fetchReviewGroup,
            hoverReviewId, editReviewModalToggle,
            fetchTaxonomyListingImages, fetchTaxonomyListingVideos,
            uploadedId, cattaxListing, isOpen, write_new_review,
            followUPReview, avg_rating, businessRatingList,
            dispute_reply_text, get_dispute_data, loading, embeddedLink,
            gifwoohoo,
            gifnailed,
            gifinsightful,
            giflol,
            gifbummer,
            woohooCount,
            nailedCount,
            insightfulCount,
            lolCount,
            bummerCount,
            showDisputedModal,
            reviewDetails,
            showDisputeModal,
            dispute_count,
            isEnableFileUpload 
        } = this.state;

        const PROFESSIONALS = ["Actors", "Professionals"];
        const isProfessionalCategory = PROFESSIONALS.includes(this.state.viewReviewsType)

        const { my_profile_info, notPeopleTab = true } = this.props

        const tooltipBummer = {
            display: this.state.hoverbummer ? 'block' : 'none'
        }
        const tooltipLol = {
            display: this.state.hoverlol ? 'block' : 'none'
        }
        const tooltipNailed = {
            display: this.state.hovernailed ? 'block' : 'none'
        }
        const tooltipWoohoo = {
            display: this.state.hoverwoohoo ? 'block' : 'none'
        }
        const tooltipInsightful = {
            display: this.state.hoverinsightful ? 'block' : 'none'
        }

        const responsive = {
            videoModal: {
                mobile: {
                    breakpoint: { max: 9999999, min: 0 },
                    items: 1
                }
            },
            viewGalleryImages: {
                desktop: {
                    breakpoint: { max: 9999999, min: 1200 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1199, min: 576 },
                    items: 2
                },
                mobile: {
                    breakpoint: { max: 575, min: 0 },
                    items: 1
                }
            }
        }
        const profileId = +localStorage.getItem("profileId")

        return (
            <div>
                { showDisputeModal ?
                    <DisputingReview review={reviewDetails} source="NonActioned" />
                    :
                    ''
                }

                { showDisputedModal ?
                    <DisputedReview review={reviewDetails} source="NonActioned" />
                    :
                    ''
                }
               
                <TabPane tabId="reviews">
                    {loading ? (
                        <>
                            <Loader />
                            <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">Loading...</h2>
                            </div>
                        </>
                    ) :
                        <>
                            <div className="d-flex mx-n1 mb-3">
                                <div className="px-1">
                                    <UncontrolledDropdown>
                                        <DropdownToggle className={`px-0 py-1 ${this.state.viewMainView ? 'text-dark' : 'text-tertiary'}`} color="transparent" caret>
                                            {
                                                this.state.viewMainView ?
                                                    <>
                                                        {this.state.viewReviewsType}
                                                        {reviews.extra?.[this.state.viewReviewsType] ? <span className="count mr-1">{reviews.extra?.[this.state.viewReviewsType]}</span> : ''}
                                                    </>
                                                    :
                                                    <>
                                                        {'All'} {reviews?.extra?.['All'] ? <span className="count mr-1">{reviews?.extra?.['All']}</span> : ''}
                                                    </>
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu className="type-tertiary-alt">
                                            {reviews.extra && Object.keys(reviews.extra).length ?
                                                Object.keys(reviews.extra).map(item => (
                                                    <DropdownItem className="fs-14"
                                                        onClick={() => this.setState({ viewReviewsType: item, viewMainView: true }, () => this.nextPage(item))}
                                                    // active={this.state.viewReviewsType === item}
                                                    >
                                                        {`${item}`}
                                                        {reviews.extra?.[item] ? <span className="count">{reviews.extra[item]}</span> : ''}
                                                    </DropdownItem>
                                                ))
                                                : null}
                                            <DropdownItem className="fs-14" onClick={() => this.setState({ viewReviewsType: 'notRecommended', viewMainView: true, showDisputed: false })}>
                                                Not Recommended
                                                <span className="count">4</span>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>
                                <div className="px-1">
                                    <ButtonGroup className="flex-wrap type-filter" size="sm">
                                        <div className="item d-flex align-items-center">
                                            <Button
                                                color="transparent"
                                                className="text-nowrap"
                                                onClick={() => { this.setState({ viewReviewsType: 'draftReviews', viewMainView: false }); this.handleDisputeTabs('drafted') }}
                                                active={this.state.viewReviewsType === 'draftReviews'}>
                                                {`Draft Disputes`}
                                            </Button>
                                            {/* If count greater than zero */}
                                            
                                            {dispute_count?.drafted_count ? 
                                                dispute_count.drafted_new ?
                                                    <span 
                                                    className="count" 
                                                    style={{"backgroundColor": "red !important"}}>
                                                        {dispute_count?.drafted_count}
                                                    </span> 
                                                : 
                                                    <span 
                                                    className="count">
                                                        {dispute_count?.drafted_count}
                                                    </span> 
                                            :
                                                ''
                                            }
                                        </div>
                                        <div className="item d-flex align-items-center">
                                            <Button
                                                color="transparent"
                                                className="text-nowrap"
                                                onClick={() => { this.setState({ viewReviewsType: 'reviewsIAmDisputing', viewMainView: false }); this.handleDisputeTabs('disputing') }}
                                                active={this.state.viewReviewsType === 'reviewsIAmDisputing'}>
                                                {`Reviews I Am Disputing`}
                                            </Button>
                                            {/* {dispute_count?.disputing_count ? <span className="count">{dispute_count?.disputing_count}</span> : ''} */}
                                            {dispute_count?.disputing_count ? 
                                                dispute_count.disputing_new ?
                                                    <span 
                                                    className="count" 
                                                    style={{"backgroundColor": "red !important"}}>
                                                        {dispute_count?.disputing_count}
                                                    </span> 
                                                : 
                                                    <span 
                                                    className="count">
                                                        {dispute_count?.disputing_count}
                                                    </span> 
                                            :
                                                ''
                                            }
                                        
                                        </div>
                                        <div className="item d-flex align-items-center">
                                            <Button
                                                color="transparent"
                                                className="text-nowrap"
                                                onClick={() => { this.setState({ viewReviewsType: 'reviewsBeingDisputed', viewMainView: false }); this.handleDisputeTabs('disputed') }}
                                                active={this.state.viewReviewsType === 'reviewsBeingDisputed'}>
                                                {`My Reviews Being Disputed`}
                                            </Button>
                                            {/* {dispute_count?.disputed_count ? <span className="count">{dispute_count?.disputed_count}</span> : ''} */}
                                            {dispute_count?.disputed_count ? 
                                                dispute_count.disputed_new ?
                                                    <span 
                                                    className="count" 
                                                    style={{"backgroundColor": "red !important"}}>
                                                        {dispute_count?.disputed_count}
                                                    </span> 
                                                : 
                                                    <span 
                                                    className="count">
                                                        {dispute_count?.disputed_count}
                                                    </span> 
                                            :
                                                ''
                                            }
                                        </div>
                                        {/* { disputed_reviews_list?.length > 0 ?
                                            <div className="item d-flex align-items-center">
                                                <Button
                                                    color="transparent"
                                                    className="text-nowrap"
                                                    onClick={() => this.setState({ viewReviewsType: 'Disputed', viewMainView: false }, () => this.nextPage('Disputed'))}
                                                    active={this.state.viewReviewsType === 'Disputed'}>
                                                    {`Disputed`}
                                                </Button>
                                                <span className="count">{disputeReviewsCount}</span>
                                            </div>
                                        :
                                            ''
                                            } */}
                                    </ButtonGroup>
                                </div>

                                <div className="px-1 ml-auto">
                                    <SearchBar
                                        handleSearchSubmit={this.handleSearchReview}
                                        searchResultsEnabled={this.state.searchResultsEnabled}
                                    />
                                </div>
                            </div>

                            {reviews.results && Array.isArray(reviews.results) && reviews.results.length > 0 ?
                                reviews.results.map((item, index) => (
                                    <div className="mb-3">
                                        { item.is_review_flag ?
                                            <>
                                                <div className="bg-warning text-dark p-2 fs-14">
                                                    <div className="d-flex flex-wrap mx-n2">
                                                        <div className="fs-14 px-2 ml-auto text-black">
                                                        <p className="mb-0 d-inline-block">
                                                            You have disputed this review.
                                                        </p>{" "}
                                                        
                                                        <span
                                                            className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                                                            role="button"
                                                            onClick={() => this.setState({ watchHowDisputeReviewWorksModalToggle: true })}
                                                        >
                                                            <span>Learn More &nbsp;
                                                            
                                                            </span>                                                            
                                                            <div
                                                            className="on-hover-appear invisble-part"
                                                            style={{ bottom: "calc(100% + 10px)" }}
                                                            >
                                                                <span>Learn More</span>
                                                                <div
                                                                    className="on-hover-appear invisble-part"
                                                                    style={{ bottom: "calc(100% + 10px)" }}
                                                                >
                                                                    <div className="tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                                                                        <div className="fs-14 ff-base">
                                                                            {/* Please watch the video to  */}
                                                                    See how Review Disputes work
                                                                </div>
                                                                        <div className="p-2">
                                                                            <Row className="justify-content-between align-items-center">
                                                                                <Col
                                                                                    xs="12"
                                                                                    className="d-flex justify-content-center align-items-center"
                                                                                >
                                                                                    <div
                                                                                        className="video-holder play-icon d-inline-block mx-2"
                                                                                        role="button"
                                                                                        onClick={() =>
                                                                                            this.setState({
                                                                                                watchHowDisputeReviewWorksModalToggle: true,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            height="80"
                                                                                            src={require("../../../assets/images/dispute_jury.webp")}
                                                                                            alt="dispute"
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            
                                                            </div>
                                                        </span>
                                                        {/* <span>{" "}&middot;{" "}</span> */}
                                                        <span 
                                                            role="button" 
                                                            onClick={() => this.handleHeatedDiscussion(item)}>                                                                
                                                                <FontAwesomeIcon icon="arrow-right"/> &nbsp;
                                                                View Dispute                                                                
                                                        </span>
                                                        <span
                                                            className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                                                            role="button"                                                            
                                                            onClick={() => this.handleHeatedDiscussion(item)}
                                                        >                                                           
                                                            {/* { item?.flag?.user?.email === my_profile_info?.user?.email ?
                                                                <span className="flashing">
                                                                    7days Period for Parties to Upload their Response 
                                                                    { item?.flag?.discussion ?  
                                                                        item?.flag?.owner_discussion ? 
                                                                        ' ( BusinessOwner Responded )'
                                                                        : ' ( Community Voting )'
                                                                    : 
                                                                    '' }  
                                                                    <FontAwesomeIcon icon="arrow-right" /> </span>
                                                                :

                                                                <span className="flashing">Help & Cast your vote 
                                                                <FontAwesomeIcon icon="arrow-right" /> 
                                                                </span>
                                                            } */}
                                                            <div
                                                            className="on-hover-appear"
                                                            style={{ bottom: "calc(100% + 10px)" }}
                                                            >
                                                                {item?.flag?.user?.email === my_profile_info?.user?.email ?
                                                                    <span className="flashing">
                                                                        7days Period for Parties to Upload their Response
                                                                    {item?.flag?.discussion ?
                                                                            item?.flag?.owner_discussion ?
                                                                                ' ( BusinessOwner Responded )'
                                                                                : ' ( Community Voting )'
                                                                            :
                                                                            ''}
                                                                        <FontAwesomeIcon icon="arrow-right" /> </span>
                                                                    :

                                                                    <span className="flashing"> View Dispute <FontAwesomeIcon icon="arrow-right" /> </span>
                                                                }
                                                                <div
                                                                    className="on-hover-appear"
                                                                    style={{ bottom: "calc(100% + 10px)" }}
                                                                >
                                                                    <div className="help-community-tooltip tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                                                                        Help the community and vote on this review
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                            
                                                    </div>                                         
                                                    
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            ''
                                        }
                                        {this.state.viewReviewsType === 'draftReviews' ?
                                            <div className="bg-warning text-dark p-2 fs-14">
                                                <div className="d-flex flex-wrap mx-n2">
                                                    <div className="fs-14 px-2 ml-auto text-black">
                                                        {item?.dispute_draft ?
                                                            <p className="mb-0 d-inline-block">
                                                                This review is saved in draft for Dispute.
                                                            </p>
                                                            :
                                                            <p className="mb-0 d-inline-block">
                                                                This review is requested for Administrative Review.
                                                            </p>

                                                        }
                                                        {" "}

                                                        <span
                                                            className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                                                            role="button"
                                                            onClick={() => this.setState({ watchHowDisputeReviewWorksModalToggle: true })}
                                                        >
                                                            <span>Learn More</span>
                                                            <div
                                                                className="on-hover-appear invisble-part"
                                                                style={{ bottom: "calc(100% + 10px)" }}
                                                            >
                                                                <div className="tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                                                                    <div className="fs-14 ff-base">
                                                                        {/* Please watch the video to  */}
                                                                See how Review Disputes work
                                                            </div>
                                                                    <div className="p-2">
                                                                        <Row className="justify-content-between align-items-center">
                                                                            <Col
                                                                                xs="12"
                                                                                className="d-flex justify-content-center align-items-center"
                                                                            >
                                                                                <div
                                                                                    className="video-holder play-icon d-inline-block mx-2"
                                                                                    role="button"
                                                                                    onClick={() =>
                                                                                        this.setState({
                                                                                            watchHowDisputeReviewWorksModalToggle: true,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        height="80"
                                                                                        src={require("../../../assets/images/dispute_jury.webp")}
                                                                                        alt="dispute"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </span>
                                                        <span>{" "}&middot;{" "}</span>
                                                        <span
                                                            className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                                                            role="button"
                                                            onClick={() => this.handleDisputeThisReview(item)}
                                                        >
                                                            <span className="flashing">Continue Editing <FontAwesomeIcon icon="arrow-right" /> </span>
                                                            <div
                                                                className="on-hover-appear"
                                                                style={{ bottom: "calc(100% + 10px)" }}
                                                            >
                                                                <div className="tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                                                                    You may Edit, Delete or Post this dispute.
                                                        </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            :
                                            ''
                                        }
                                        <div className="bg-white p-3 mb-3">
                                            {isOpen && isOpen[item.id] ?
                                            item?.child_review?.review_date_time ? <div className="d-flex flex-row flex-nowrap mx-n2">
                                            <div className="flex-160 px-2">
                                                <div className="d-flex text-left mb-2 mx-n2">
                                                    <div className="col-auto px-2">
                                                        {/* <img width="30" className="img-fluid" src={item?.reviewprofileimages_set.length > 0 ? item?.reviewprofileimages_set[0]?.thumbnail : "../../../assets/images/product-img2.png"}
                                                            onError={(error) => (error.target.src = require("../../../assets/images/product-img2.png"))}
                                                            alt="" /> */}
                                                            {/* <img width="30" className="img-fluid" src={item?.listing_profileimage?.url ? item?.listing_profileimage.url : "../../../assets/images/product-img2.png"}
                                                            onError={(error) => (error.target.src = require("../../../assets/images/product-img2.png"))}
                                                            alt="" /> */}
                                                            <img width="30" className="img-fluid mb-3" src={item?.reviewprofileimages_set.length > 0 ? item?.reviewprofileimages_set[0]?.thumbnail : "../../../assets/images/product-img2.png"}
                                                                onError={(error) => (error.target.src = require("../../../assets/images/product-img2.png"))}
                                                                alt="" />
                                                    </div>
                                                    <div className="px-2">
                                                        <div className="text-dark fs-14 ff-alt mb-3">
                                                            {
                                                                isProfessionalCategory ? (item?.associated_to?.firstname + " " + item?.associated_to?.lastname) : item?.associated_to?.name
                                                            }
                                                        </div>
                                                        <div className="text-tertiary fs-14 ff-alt">
                                                            <div>
                                                                {item?.associated_to?.taxonomy?.length ?
                                                                    item.associated_to.taxonomy.map((item_taxo, index) => (
                                                                        <span >{item_taxo?.label}{item?.taxonomy?.length < index ? ', ' : ''}</span>
                                                                    )) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-2">
                                                <div className="d-flex flex-nowrap mx-n2">
                                                    <div className="px-2">
                                                        <img src={require(`../../../assets/images/icons/star/blue/${item?.associated_rating && item?.associated_rating?.avg_rating &&
                                                            item?.associated_rating?.avg_rating[0] ? item?.associated_rating?.avg_rating[0] : '0rating.png'}`)}
                                                            alt={item?.associated_rating && item?.associated_rating?.avg_rating[1] ? item?.associated_rating?.avg_rating[1] : '0.0'}
                                                            title={item?.associated_rating && item?.associated_rating?.avg_rating[1] ? item?.associated_rating?.avg_rating[1] : '0.0'} />
                                                    </div>
                                                    <div className="flex-grow-1 px-2">
                                                        <p className="text-tertiary fs-14 wb-break-word">
                                                            {
                                                                isProfessionalCategory ?
                                                                    (item?.child_review?.professionalreview?.length < 200 ? ReactHtmlParser(item?.child_review?.professionalreview) :
                                                                        <>
                                                                            {ReactHtmlParser(detectlink(item.child_review?.professionalreview?.substring(0, 200)))}
                                                                            <span className="text-dark font-weight-bold" role="button" onClick={async () => {
                                                                                this.handleOnExpand(false, item?.id);
                                                                            }}>...read more</span>
                                                                        </>)
                                                                    :
                                                                    (item?.child_review?.review?.length < 200 ? ReactHtmlParser(detectlink(item?.child_review?.review)) :
                                                                        <>
                                                                            {ReactHtmlParser(detectlink(item.child_review?.review?.substring(0, 200)))}
                                                                            <span className="text-dark font-weight-bold" role="button" onClick={async () => {
                                                                                this.handleOnExpand(false, item?.id);
                                                                            }}>...read more</span>
                                                                        </>)
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="ml-auto col-auto px-2">
                                                        <span className="fs-14 ff-alt text-tertiary">{moment(item?.created_date_time).format('MMM DD, YYYY')}</span>
                                                    </div>
                                                    <div className="px-2">
                                                        <span className="icon-plus" title="Expand"
                                                            onClick={async () => {
                                                                this.handleOnExpand(false, item?.id);
                                                            }}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                                (<div className="d-flex flex-row flex-nowrap mx-n2">
                                                    <div className="flex-160 px-2">
                                                        <div className="d-flex text-left mb-2 mx-n2">
                                                            <div className="col-auto px-2">
                                                                {/* <img width="30" className="img-fluid" src={item?.reviewprofileimages_set.length > 0 ? item?.reviewprofileimages_set[0]?.thumbnail : "../../../assets/images/product-img2.png"}
                                                                    onError={(error) => (error.target.src = require("../../../assets/images/product-img2.png"))}
                                                                    alt="" /> */}
                                                                    {/* <img width="30" className="img-fluid" src={item?.listing_profileimage?.url ? item?.listing_profileimage.url : "../../../assets/images/product-img2.png"}
                                                                    onError={(error) => (error.target.src = require("../../../assets/images/product-img2.png"))}
                                                                    alt="" /> */}
                                                                    <img width="30" className="img-fluid mb-3" src={item?.reviewprofileimages_set.length > 0 ? item?.reviewprofileimages_set[0]?.thumbnail : "../../../assets/images/product-img2.png"}
                                                                        onError={(error) => (error.target.src = require("../../../assets/images/product-img2.png"))}
                                                                        alt="" />
                                                            </div>
                                                            <div className="px-2">
                                                                <div className="text-dark fs-14 ff-alt mb-3">
                                                                    {
                                                                        isProfessionalCategory ? (item?.associated_to?.firstname + " " + item?.associated_to?.lastname) : item?.associated_to?.name
                                                                    }
                                                                </div>
                                                                <div className="text-tertiary fs-14 ff-alt">
                                                                    <div>
                                                                        {item?.associated_to?.taxonomy?.length ?
                                                                            item.associated_to.taxonomy.map((item_taxo, index) => (
                                                                                <span >{item_taxo?.label}{item?.taxonomy?.length < index ? ', ' : ''}</span>
                                                                            )) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col px-2">
                                                        <div className="d-flex flex-nowrap mx-n2">
                                                            <div className="px-2">
                                                                <img src={require(`../../../assets/images/icons/star/blue/${item?.associated_rating && item?.associated_rating?.avg_rating &&
                                                                    item?.associated_rating?.avg_rating[0] ? item?.associated_rating?.avg_rating[0] : '0rating.png'}`)}
                                                                    alt={item?.associated_rating && item?.associated_rating?.avg_rating[1] ? item?.associated_rating?.avg_rating[1] : '0.0'}
                                                                    title={item?.associated_rating && item?.associated_rating?.avg_rating[1] ? item?.associated_rating?.avg_rating[1] : '0.0'} />
                                                            </div>
                                                            <div className="flex-grow-1 px-2">
                                                                <p className="text-tertiary fs-14 wb-break-word">
                                                                    {
                                                                        isProfessionalCategory ?
                                                                            (item?.professionalreview?.length < 200 ? ReactHtmlParser(item?.professionalreview) :
                                                                                <>
                                                                                    {ReactHtmlParser(detectlink(item.professionalreview?.substring(0, 200)))}
                                                                                    <span className="text-dark font-weight-bold" role="button" onClick={async () => {
                                                                                        this.handleOnExpand(false, item?.id);
                                                                                    }}>...read more</span>
                                                                                </>)
                                                                            :
                                                                            (item?.review?.length < 200 ? ReactHtmlParser(detectlink(item?.review)) :
                                                                                <>
                                                                                    {ReactHtmlParser(detectlink(item.review?.substring(0, 200)))}
                                                                                    <span className="text-dark font-weight-bold" role="button" onClick={async () => {
                                                                                        this.handleOnExpand(false, item?.id);
                                                                                    }}>...read more</span>
                                                                                </>)
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="ml-auto col-auto px-2">
                                                                <span className="fs-14 ff-alt text-tertiary">{moment(item?.created_date_time).format('MMM DD, YYYY')}</span>
                                                            </div>
                                                            <div className="px-2">
                                                                <span className="icon-plus" title="Expand"
                                                                    onClick={async () => {
                                                                        this.handleOnExpand(false, item?.id);
                                                                    }}></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                                :
                                                (
                                                item?.child_review?.review_date_time ? 
                                                
                                                <div>
                                                    <div className="d-flex flex-row flex-nowrap mx-n2">
                                                        <div className="flex-160 px-2">
                                                            <div className="text-dark ff-alt fs-14">
                                                                <div className="text-center">
                                                                    <Link
                                                                        to={this.redirectToSlugUrl(item)}
                                                                        className="text-decoration-none">
                                                                        <img className="img-fluid mb-3" src={item?.reviewprofileimages_set.length > 0 ? item?.reviewprofileimages_set[0]?.thumbnail : "../../../assets/images/product-img2.png"}
                                                                            onError={(error) => (error.target.src = require("../../../assets/images/product-img2.png"))}
                                                                            alt="" />
                                                                    </Link>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <div className="font-weight-bold text-dark ff-base fs-14">
                                                                        <Link
                                                                            to={this.redirectToSlugUrl(item)}
                                                                            className="text-decoration-none">
                                                                            {
                                                                                isProfessionalCategory ? (item?.associated_to?.firstname + " " + item?.associated_to?.lastname) : item?.associated_to?.name
                                                                            }
                                                                        </Link>
                                                                    </div>
                                                                    <div className="text-tertiary">
                                                                        {
                                                                            isProfessionalCategory ? item?.associated_to?.content_type?.category : item?.listing_type
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <img className="img-fluid"
                                                                            src={require(`../../../assets/images/icons/${item?.associated_rating && item?.associated_rating?.avg_rating &&
                                                                                item?.associated_rating?.avg_rating[0] ? item?.associated_rating?.avg_rating[0] : '0rating.png'}`)}
                                                                            alt={item?.associated_rating && item?.associated_rating?.avg_rating[1] ? item?.associated_rating?.avg_rating[1] : '0.0'}
                                                                            title={item?.associated_rating && item?.associated_rating?.avg_rating[1] ? item?.associated_rating?.avg_rating[1] : '0.0'} />
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        <span className="font-weight-bold mr-2">{item?.associated_rating?.total_review}</span>
                                                                        <span className="text-tertiary">{`review${item?.associated_rating?.total_review != 1 ? 's' : ''}`}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="fs-14 text-tertiary">
                                                                        {item?.fulladdress}
                                                                    </div>
                                                                </div>
                                                                <div className="fs-14 mb-2">
                                                                    <span>{'Categories:'}</span>
                                                                    <div>
                                                                        {item?.associated_to?.taxonomy?.length ?
                                                                            item.associated_to.taxonomy.map((item_taxo, index) => (
                                                                                <span className="font-weight-bold">{item_taxo?.label}{index < (item.associated_to.taxonomy.length - 1) ? ', ' : ''}</span>
                                                                            )) : "NA"}
                                                                    </div>
                                                                </div>

                                                                <ul className="list-unstyled d-flex mx-n1">
                                                                    <li className="mx-2">
                                                                        <FacebookShareButton
                                                                        url={`${getShareReviewURL(item?.associated_to?.content_type?.category, item?.associated_to?.slug, item?.listing_id || item?.associated_to?.id)}`}                                                                        
                                                                        quote={`Review of ${item?.associated_to?.name || getFullName(item?.associated_to)}: ${ReactHtmlParser(item.review)}`}
                                                                         hashtag={this.state.fbHashTag}
                                                                          className="text-decoration-none">
                                                                            <FontAwesomeIcon size="lg" icon={['fab', 'facebook-f']} />
                                                                        </FacebookShareButton>
                                                                    </li>
                                                                    {/* <li className="mx-1">
                                                    <a href="#" className="text-dark">
                                                        <FontAwesomeIcon icon={['fab', 'google-plus-g']} />
                                                    </a>
                                                </li> */}
                                                                    <li className="mx-2">
                                                                        <TwitterShareButton 
                                                                        url={`${getShareReviewURL(item?.associated_to?.content_type?.category, item?.associated_to?.slug, item?.listing_id || item?.associated_to?.id)}`}
                                                                         title={`Review of ${item?.associated_to?.name || getFullName(item?.associated_to)}: ${ReactHtmlParser(item.review)}`} via={this.state.shareVia} hashtags={this.state.twitterHashTags} className="text-decoration-none">
                                                                            <FontAwesomeIcon size="lg" icon={['fab', 'twitter']} />
                                                                        </TwitterShareButton>
                                                                    </li>
                                                                    <li className="mx-2">
                                                                        <a href="javascript:void(0)" className="text-dark" onClick={() => this.handleOnClickShareByEmail(item.id, item?.associated_to?.content_type?.category)}>
                                                                            <img src={require("../../../assets/images/icons/email.png")} alt="" />
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col px-2">
                                                            <div>
                                                                <div hidden={item.child_review && item.child_review.draft != false && item.child_review.draft != true ? false : true}><span className="text-dark font-weight-bold fs-14">Updated Review</span></div>
                                                                <div className="d-flex mb-3 mx-n2">
                                                                    <div className="px-2">
                                                                        <img
                                                                            className="mr-2"
                                                                            src={require(`../../../assets/images/icons/star/torquise/${item?.child_review?.ratings_sets?.avg_rating[0]}`)}
                                                                            alt={item?.child_review?.ratings_sets?.avg_rating[1]}
                                                                            title={item?.child_review?.ratings_sets?.avg_rating[1]} />
                                                                        <span className="text-dark font-weight-bold fs-14">
                                                                            {'Overall Rating'}{item.is_draft == true ? " [DRAFT]" : ""}
                                                                        </span>
                                                                    </div>
                                                                    <div className="ml-auto px-2">
                                                                        <span className="fs-14 ff-alt text-dark">{moment(item.child_review?.created_date_time).format('MMM DD, YYYY')}</span>
                                                                    </div>
                                                                    <div className="px-2">
                                                                        <span className="icon-minus" title="Collapse"
                                                                            onClick={async () => {
                                                                                this.handleOnExpand(true, item.id);
                                                                            }}></span>
                                                                    </div>
                                                                </div>

                                                                <div className="my-4" hidden={checkTextVisibilty(item?.child_review?.review)}>
                                                                                    <i className="icon-comma-left _dark" ></i>
                                                                                    <span className="comment-body-renderer" >{ReactHtmlParser(detectlink(item?.child_review?.review))}</span>
                                                                                    <i className="icon-comma-right"></i>
                                                                                </div>

                                                                {item?.child_review?.reviewmedia_set.length > 0 ? (
                                                                        <div className="mt-4">
                                                                            {item?.child_review?.reviewmedia_set.slice(0, 1).map((reviewmedia, index) => (
                                                                                <div key={index}>
                                                                                    {reviewmedia.type === "image" && (
                                                                                        <div className={`mb-3 ${item?.child_review?.reviewmedia_set.length > 1 ? 'mx-auto text-center' : ''}`} style={{ maxWidth: "450px" }}>
                                                                                            <img
                                                                                                src={reviewmedia.url}
                                                                                                alt={
                                                                                                    reviewmedia.filename.length < 20
                                                                                                        ? reviewmedia.filename
                                                                                                        : this.truncate(reviewmedia.filename)
                                                                                                }
                                                                                                className="img-fluid"

                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                    {reviewmedia.type === "video" && (
                                                                                        <video width="100%" controls>
                                                                                            <source
                                                                                                src={reviewmedia.url}
                                                                                                type="video/mp4"
                                                                                            />
																		Your browser does not support the video
																		tag.
                                                                                        </video>
                                                                                    )}
                                                                                </div>
                                                                            ))}

                                                                            <div>
                                                                                <Row className="justify-content-center" xs={2} md={3} lg={3} xl={4} form>
                                                                                    {item?.child_review?.reviewmedia_set && item?.child_review?.reviewmedia_set.length > 1 ? (
                                                                                        item?.child_review?.reviewmedia_set.map((reviewmediass, index1) => {
                                                                                            return (
                                                                                                <Col className="mb-2 wk-profile-small-images">
                                                                                                    {reviewmediass.type === "video" ? (
                                                                                                        <div
                                                                                                            onClick={() => this.handleOpenModal(item?.child_review?.reviewmedia_set, reviewmediass.url)}
                                                                                                            className="video-holder">
                                                                                                            <div className="black-box _100x100">&nbsp;</div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                            <img
                                                                                                                src={reviewmediass.url}
                                                                                                                alt={''}
                                                                                                                className="fixed-height-img is-160 full-width"
                                                                                                                role="button"
                                                                                                                onClick={() => this.handleOpenModal(item?.child_review?.reviewmedia_set, reviewmediass.url)} />
                                                                                                        )}
                                                                                                </Col>
                                                                                            );
                                                                                        })) : ("")}
                                                                                </Row>

                                                                            </div>



                                                                        </div>
                                                                    ) : (
                                                                            ""
                                                                        )}

                                                                <div className="quick-reactions d-flex flex-wrap mb-2">
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={gifwoohoo[item.child_review.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/woohoo_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction woohoo"
                                                                                title="Woohoo"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.child_review.id, "woohoo",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.child_review.review_group_count &&
                                                                                        item.child_review.review_group_count
                                                                                            .woohoo +
                                                                                        (woohooCount[item.child_review.id] &&
                                                                                            woohooCount[item.child_review.id] != undefined
                                                                                            ? woohooCount[item.child_review.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/woohoo-active.png")}
                                                                                                alt="woohoo"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/woohoo.png")}
                                                                                                alt="woohoo"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Woohoo</span>
                                                                            </button>
                                                                            {item.child_review.review_group_count && item.child_review.review_group_count
                                                                                .woohoo +
                                                                                (woohooCount[item.child_review.id] &&
                                                                                    woohooCount[item.child_review.id] != undefined
                                                                                    ? woohooCount[item.child_review.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.child_review.id}
                                                                                        data-name="woohoo"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.child_review.review_group_count,
                                                                                                item.child_review.id,
                                                                                                "woohoo"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.child_review.review_group_count
                                                                                                ? item.child_review.review_group_count.woohoo +
                                                                                                (woohooCount[item.child_review.id] &&
                                                                                                    woohooCount[item.child_review.id] != undefined
                                                                                                    ? woohooCount[item.child_review.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {woohooCount[item.child_review.id] == undefined
                                                                                                ? 0
                                                                                                : woohooCount[item.child_review.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hoverwoohoo && hoverReviewId === item.child_review.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipWoohoo}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (element.group_icon === "woohoo") {
                                                                                                return <p key={index}>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={gifnailed[item.child_review.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/nailedit_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction nailed"
                                                                                title="Nailed"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.child_review.id, "nailed",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.child_review.review_group_count &&
                                                                                        item.child_review.review_group_count
                                                                                            .nailed +
                                                                                        (nailedCount[item.child_review.id] &&
                                                                                            nailedCount[item.child_review.id] != undefined
                                                                                            ? nailedCount[item.child_review.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/nailed-active.png")}
                                                                                                alt="nailed"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/nailed-it.png")}
                                                                                                alt="nailed"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Nailed It</span>
                                                                            </button>
                                                                            {item.child_review.review_group_count && item.child_review.review_group_count
                                                                                .nailed +
                                                                                (nailedCount[item.child_review.id] &&
                                                                                    nailedCount[item.child_review.id] != undefined
                                                                                    ? nailedCount[item.child_review.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.child_review.id}
                                                                                        data-name="nailed"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.child_review.review_group_count,
                                                                                                item.child_review.id,
                                                                                                "nailed"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.child_review.review_group_count
                                                                                                ? item.child_review.review_group_count.nailed +
                                                                                                (nailedCount[item.child_review.id] &&
                                                                                                    nailedCount[item.child_review.id] != undefined
                                                                                                    ? nailedCount[item.child_review.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {nailedCount[item.child_review.id] == undefined
                                                                                                ? 0
                                                                                                : nailedCount[item.child_review.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hovernailed && hoverReviewId === item.child_review.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipNailed}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "nailed" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={
                                                                                gifinsightful[item.child_review.id] == true ? false : true
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/insightful_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction insightful"
                                                                                title="Insightful"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(
                                                                                        true,
                                                                                        item.child_review.id,
                                                                                        "insightful",item
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.child_review.review_group_count &&
                                                                                        item.child_review.review_group_count
                                                                                            .insightful +
                                                                                        (insightfulCount[item.child_review.id] &&
                                                                                            insightfulCount[item.child_review.id] != undefined
                                                                                            ? insightfulCount[item.child_review.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/insightful-active.png")}
                                                                                                alt="insightful"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/insightful.png")}
                                                                                                alt="insightful"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Insightful</span>
                                                                            </button>
                                                                            {item.child_review.review_group_count &&
                                                                                item.child_review.review_group_count.insightful +
                                                                                (insightfulCount[item.child_review.id] &&
                                                                                    insightfulCount[item.child_review.id] != undefined
                                                                                    ? insightfulCount[item.child_review.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.child_review.id}
                                                                                        data-name="insightful"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.child_review.review_group_count,
                                                                                                item.child_review.id,
                                                                                                "insightful"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.child_review.review_group_count
                                                                                                ? item.child_review.review_group_count.insightful +
                                                                                                (insightfulCount[item.child_review.id] &&
                                                                                                    insightfulCount[item.child_review.id] != undefined
                                                                                                    ? insightfulCount[item.child_review.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {insightfulCount[item.child_review.id] == undefined
                                                                                                ? 0
                                                                                                : insightfulCount[item.child_review.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hoverinsightful && hoverReviewId === item.child_review.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipInsightful}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "insightful" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={giflol[item.child_review.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/lol_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction lol"
                                                                                title="LOL"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.child_review.id, "lol",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.child_review.review_group_count &&
                                                                                        item.child_review.review_group_count
                                                                                            .lol +
                                                                                        (lolCount[item.child_review.id] &&
                                                                                            lolCount[item.child_review.id] != undefined
                                                                                            ? lolCount[item.child_review.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/lol-active.png")}
                                                                                                alt="lol"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/lol.png")}
                                                                                                alt="lol"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">LOL</span>
                                                                            </button>
                                                                            {item.child_review.review_group_count &&
                                                                                item.child_review.review_group_count
                                                                                    .lol +
                                                                                (lolCount[item.child_review.id] &&
                                                                                    lolCount[item.child_review.id] != undefined
                                                                                    ? lolCount[item.child_review.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.child_review.id}
                                                                                        data-name="lol"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.child_review.review_group_count,
                                                                                                item.child_review.id,
                                                                                                "lol"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.child_review.review_group_count
                                                                                                ? item.child_review.review_group_count.lol +
                                                                                                (lolCount[item.child_review.id] &&
                                                                                                    lolCount[item.child_review.id] != undefined
                                                                                                    ? lolCount[item.child_review.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {lolCount[item.child_review.id] == undefined
                                                                                                ? 0
                                                                                                : lolCount[item.child_review.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hoverlol && hoverReviewId === item.child_review.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipLol}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "lol" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={gifbummer[item.child_review.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/bummer_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction bummer"
                                                                                title="Bummer"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.child_review.id, "bummer",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.child_review.review_group_count &&
                                                                                        item.child_review.review_group_count.bummer +
                                                                                        (bummerCount[item.child_review.id] &&
                                                                                            bummerCount[item.child_review.id] != undefined
                                                                                            ? bummerCount[item.child_review.id]
                                                                                            : 0) > 0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/bummer-active.png")}
                                                                                                alt="bummer"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/bummer.png")}
                                                                                                alt="bummer"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Bummer</span>
                                                                            </button>
                                                                            {item.child_review.review_group_count &&
                                                                                item.child_review.review_group_count
                                                                                    .bummer +
                                                                                (bummerCount[item.child_review.id] &&
                                                                                    bummerCount[item.child_review.id] != undefined
                                                                                    ? bummerCount[item.child_review.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.child_review.id}
                                                                                        data-name="bummer"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.child_review.review_group_count,
                                                                                                item.child_review.id,
                                                                                                "bummer"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.child_review.review_group_count
                                                                                                ? item.child_review.review_group_count.bummer +
                                                                                                (bummerCount[item.child_review.id] &&
                                                                                                    bummerCount[item.child_review.id] != undefined
                                                                                                    ? bummerCount[item.child_review.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {bummerCount[item.child_review.id] == undefined
                                                                                                ? 0
                                                                                                : bummerCount[item.child_review.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}

                                                                            <div
                                                                                className={
                                                                                    hoverbummer && hoverReviewId === item.child_review.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipBummer}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "bummer" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className="fs-14">
                                                                        <div> <i className="d-inline-block mr-2">
                                                                            Was this review helpful?
																					</i>
                                                                            <span
                                                                                className={
                                                                                    // item?.child_review?.helpfulCount.helpful === 1
                                                                                    item?.child_review?.helpfulCount?.profile_ids?.includes(profileId)
                                                                                        ? "font-weight-bold text-dark d-inline-block"
                                                                                        : "font-weight-bold text-tertiary d-inline-block"
                                                                                }
                                                                                role="button"
                                                                                onClick={() =>
                                                                                    this.props.post_review_group_myprofile(
                                                                                        item?.child_review?.listing_id,
                                                                                        { type: "helpful", review: item?.child_review?.id },
                                                                                        this.state.profileInfo.id,
                                                                                        item?.associated_to?.content_type?.category,
                                                                                        this.state.viewReviewsType
                                                                                    )
                                                                                }
                                                                            >
                                                                                {"YES"}
                                                                            </span>
                                                                            <span className="mx-1">-</span>
                                                                            <span
                                                                                href="#"
                                                                                className={
                                                                                    !item?.child_review?.helpfulCount?.profile_ids?.includes(profileId)
                                                                                        ? "font-weight-bold text-dark d-inline-block"
                                                                                        : "font-weight-bold text-tertiary d-inline-block"
                                                                                }
                                                                                // className="font-weight-bold text-dark d-inline-block"
                                                                                role="button"
                                                                                onClick={() =>
                                                                                    this.props.post_review_group_myprofile(
                                                                                        item?.child_review?.listing_id,
                                                                                        { type: "not_helpful", review: item?.child_review?.id },
                                                                                        this.state.profileInfo.id,
                                                                                        item?.associated_to?.content_type?.category,
                                                                                        this.state.viewReviewsType
                                                                                    )
                                                                                }
                                                                            >
                                                                                {"NO"}
                                                                            </span>
                                                                        </div>
                                                                        {item?.child_review?.helpfulCount?.helpful > 0 &&
                                                                            item?.child_review?.helpfulCount?.total > 0 ? (
                                                                                <div>
                                                                                    <span className="font-weight-bold text-dark">{`${item?.child_review?.helpfulCount?.helpful} of ${item?.child_review?.helpfulCount?.total}`}</span>
                                                                                    <i className="text-tertiary d-inline-block ml-2">
                                                                                        {"people found this review as  helpful"}
                                                                                    </i>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <span className="font-weight-bold text-dark">{`${item?.child_review?.helpfulCount?.helpful} `}</span>
                                                                                    <i className="text-tertiary d-inline-block ml-2">
                                                                                        {"people found this review as  helpful"}
                                                                                    </i>
                                                                                </div>
                                                                            )}
                                                                            <div className="text-right">
                                                        <Button color="link" className="font-weight-bold mx-0 px-1 text-primary" onClick={() => {
                                                            this.setState({
                                                                deletereviewId: item.child_review.id,
                                                                deleteEntity: 'review'
                                                            });
                                                            this.confirmDeleteModalToggle();
                                                        }}>
                                                            {'delete'}
                                                        </Button>

                                                        <Button
                                                            color="link"
                                                            className="font-weight-bold mx-0 px-1 text-tertiary"
                                                            onClick={
                                                                () => {
                                                                    this.setState({
                                                                        reviewValue: this.state.modalData && this.state.modalData.review.replace(/(<([^>]+)>)/gi, ""),
                                                                        reviewId: ""
                                                                    })
                                                                    this.handleChildReviewEdit(item.child_review, item.child_review.id)
                                                                }
                                                            }
                                                        >
                                                            {'edit review'}
                                                        </Button>
                                                    </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : 
                                                
                                                <div>
                                                    <div className="d-flex flex-row flex-nowrap mx-n2">
                                                        <div className="flex-160 px-2">
                                                            <div className="text-dark ff-alt fs-14">
                                                                <div className="text-center">
                                                                    <Link
                                                                        to={this.redirectToSlugUrl(item)}
                                                                        className="text-decoration-none">
                                                                        <img className="img-fluid mb-3" src={item?.reviewprofileimages_set.length > 0 ? item?.reviewprofileimages_set[0]?.thumbnail : "../../../assets/images/product-img2.png"}
                                                                            onError={(error) => (error.target.src = require("../../../assets/images/product-img2.png"))}
                                                                            alt="" />
                                                                    </Link>
                                                                </div>

                                                                <div className="mb-3">
                                                                    <div className="font-weight-bold text-dark ff-base fs-14">
                                                                        <Link
                                                                            to={this.redirectToSlugUrl(item)}
                                                                            className="text-decoration-none">
                                                                            {
                                                                                isProfessionalCategory ? (item?.associated_to?.firstname + " " + item?.associated_to?.lastname) : item?.associated_to?.name
                                                                            }
                                                                        </Link>
                                                                    </div>
                                                                    <div className="text-tertiary">
                                                                        {
                                                                            isProfessionalCategory ? item?.associated_to?.content_type?.category : item?.listing_type
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <img className="img-fluid"
                                                                            src={require(`../../../assets/images/icons/${item?.associated_rating && item?.associated_rating?.avg_rating &&
                                                                                item?.associated_rating?.avg_rating[0] ? item?.associated_rating?.avg_rating[0] : '0rating.png'}`)}
                                                                            alt={item?.associated_rating && item?.associated_rating?.avg_rating[1] ? item?.associated_rating?.avg_rating[1] : '0.0'}
                                                                            title={item?.associated_rating && item?.associated_rating?.avg_rating[1] ? item?.associated_rating?.avg_rating[1] : '0.0'} />
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        <span className="font-weight-bold mr-2">{item?.associated_rating?.total_review}</span>
                                                                        <span className="text-tertiary">{`review${item?.associated_rating?.total_review != 1 ? 's' : ''}`}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <div className="fs-14 text-tertiary">
                                                                        {item?.fulladdress}
                                                                    </div>
                                                                </div>
                                                                <div className="fs-14 mb-2">
                                                                    <span>{'Categories:'}</span>
                                                                    <div>
                                                                        {item?.associated_to?.taxonomy?.length ?
                                                                            item.associated_to.taxonomy.map((item_taxo, index) => (
                                                                                <span className="font-weight-bold">{item_taxo?.label}{index < (item.associated_to.taxonomy.length - 1) ? ', ' : ''}</span>
                                                                            )) : "NA"}
                                                                    </div>
                                                                </div>

                                                                <ul className="list-unstyled d-flex mx-n1">
                                                                    <li className="mx-2">
                                                                        <FacebookShareButton
                                                                         url={`${getShareReviewURL(item?.associated_to?.content_type?.category, item?.associated_to?.slug, item?.listing_id || item?.associated_to?.id)}`}
                                                                          quote={`Review of ${item?.associated_to?.name || getFullName(item?.associated_to)}: ${ReactHtmlParser(item.review)}`} hashtag={this.state.fbHashTag} className="text-decoration-none">
                                                                            <FontAwesomeIcon size="lg" icon={['fab', 'facebook-f']} />
                                                                        </FacebookShareButton>
                                                                    </li>
                                                                    {/* <li className="mx-1">
                                                    <a href="#" className="text-dark">
                                                        <FontAwesomeIcon icon={['fab', 'google-plus-g']} />
                                                    </a>
                                                </li> */}
                                                                    <li className="mx-2">
                                                                        <TwitterShareButton
                                                                         url={`${getShareReviewURL(item?.associated_to?.content_type?.category, item?.associated_to?.slug, item?.listing_id || item?.associated_to?.id)}`}
                                                                          title={`Review of ${item?.associated_to?.name || getFullName(item?.associated_to)}: ${ReactHtmlParser(item.review)}`} via={this.state.shareVia} hashtags={this.state.twitterHashTags} className="text-decoration-none">
                                                                            <FontAwesomeIcon size="lg" icon={['fab', 'twitter']} />
                                                                        </TwitterShareButton>
                                                                    </li>
                                                                    <li className="mx-2">
                                                                        <a href="javascript:void(0)" className="text-dark" onClick={() => this.handleOnClickShareByEmail(item.id, item?.associated_to?.content_type?.category)}>
                                                                            <img src={require("../../../assets/images/icons/email.png")} alt="" />
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col px-2">
                                                            <div>
                                                                <div hidden={item.child_review && item.child_review.draft != false && item.child_review.draft != true ? false : true}><span className="text-dark font-weight-bold fs-14">Updated Review</span></div>
                                                                <div className="d-flex mb-3 mx-n2">
                                                                    <div className="px-2">
                                                                        <img
                                                                            className="mr-2"
                                                                            src={require(`../../../assets/images/icons/star/torquise/${item?.ratings_sets?.avg_rating[0]}`)}
                                                                            alt={item?.ratings_sets?.avg_rating[1]}
                                                                            title={item?.ratings_sets?.avg_rating[1]} />
                                                                        <span className="text-dark font-weight-bold fs-14">
                                                                            {'Overall Rating'}{item.is_draft == true ? " [DRAFT]" : ""}
                                                                        </span>
                                                                    </div>
                                                                    <div className="ml-auto px-2">
                                                                        <span className="fs-14 ff-alt text-dark">{moment(item.created_date_time).format('MMM DD, YYYY')}</span>
                                                                    </div>
                                                                    <div className="px-2">
                                                                        <span className="icon-minus" title="Collapse"
                                                                            onClick={async () => {
                                                                                this.handleOnExpand(true, item.id);
                                                                            }}></span>
                                                                        {/* {
                                                                isOpen && isOpen[item?.id] ?
                                                                    <Button
                                                                        color="outline-tertiary btn-circle"
                                                                        size="md"
                                                                        title="Collapse"
                                                                        onClick={async () => {
                                                                            this.handleOnExpand(false, item.id);
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon icon="minus" />
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        color="outline-tertiary btn-circle"
                                                                        size="md"
                                                                        title="Expand"
                                                                        onClick={async () => {
                                                                            this.handleOnExpand(true, item.id);
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon icon="plus" />
                                                                    </Button>
                                                            } */}
                                                                    </div>
                                                                </div>

                                                                {/* {' Hidden Stars'} */}

                                                                <div className="mb-3">
                                                                    <Row className="fs-14 ff-alt text-dark">
                                                                        {item?.ratings_sets && Object.keys(item?.ratings_sets).length > 2 ?
                                                                            Object.keys(item.ratings_sets).map(rt_item => (
                                                                                (
                                                                                    // rt_item == "Bed Comfort" || rt_item == "Cleanliness" || rt_item == "Food Quality" || rt_item == "Management" || rt_item == "Room Size" || rt_item == "Services" ||
                                                                                    rt_item === "avg_rating" || rt_item === "total_review") ? "" : (
                                                                                        <Col className="mb-2" md={6}>
                                                                                            <img
                                                                                                className="mr-2"
                                                                                                src={require(`../../../assets/images/icons/star/torquise/${item.ratings_sets && item.ratings_sets[rt_item] && item.ratings_sets[rt_item][0] ? item.ratings_sets[rt_item][0] : '0rating.png'}`)}
                                                                                                alt={item.ratings_sets && item.ratings_sets[item] && item.ratings_sets[rt_item][1] ? item.ratings_sets[rt_item][1] : '0rating.png'}
                                                                                                title={item.ratings_sets && item.ratings_sets[rt_item] && item.ratings_sets[rt_item][1] ? item.ratings_sets[rt_item][1] : '0rating.png'} />
                                                                                            <span>{rt_item}</span>
                                                                                        </Col>
                                                                                    )
                                                                            ))
                                                                            : null}
                                                                    </Row>
                                                                </div>

                                                                <div className="my-4">
                                                                    {/* <i className="icon-comma-left"></i> */}
                                                                    <span className="comment-body-renderer">
                                                                        {ReactHtmlParser(item?.professionalreview ? detectlink(item.professionalreview) : detectlink(item?.review))}
                                                                    </span>
                                                                    {/* <i className="icon-comma-right"></i> */}
                                                                    {/* {post.body.replace(/(<([^>]+)>)/gi, "")} */}
                                                                    {item.reviewmedia_set.length > 0 ? (
                                                                        <div className="mt-4">
                                                                            {item.reviewmedia_set.slice(0, 1).map((reviewmedia, index) => (
                                                                                <div key={index}>
                                                                                    {reviewmedia.type === "image" && (
                                                                                        <div className={`mb-3 ${item.reviewmedia_set.length > 1 ? 'mx-auto text-center' : ''}`} style={{ maxWidth: "450px" }}>
                                                                                            <img
                                                                                                src={reviewmedia.url}
                                                                                                alt={
                                                                                                    reviewmedia.filename.length < 20
                                                                                                        ? reviewmedia.filename
                                                                                                        : this.truncate(reviewmedia.filename)
                                                                                                }
                                                                                                className="img-fluid"

                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                    {reviewmedia.type === "video" && (
                                                                                        <video width="100%" controls>
                                                                                            <source
                                                                                                src={reviewmedia.url}
                                                                                                type="video/mp4"
                                                                                            />
																		Your browser does not support the video
																		tag.
                                                                                        </video>
                                                                                    )}
                                                                                </div>
                                                                            ))}
                                                                            <div>
                                                                                <Row className="justify-content-center" xs={2} md={3} lg={3} xl={4} form>
                                                                                    {item.reviewmedia_set && item.reviewmedia_set.length > 1 ? (
                                                                                        item.reviewmedia_set.map((reviewmediass, index1) => {
                                                                                            return (
                                                                                                <Col className="mb-2 wk-profile-small-images">
                                                                                                    {reviewmediass.type === "video" ? (
                                                                                                        <div
                                                                                                            onClick={() => this.handleOpenModal(item.reviewmedia_set, reviewmediass.url)}
                                                                                                            className="video-holder">
                                                                                                            <div className="black-box _100x100">&nbsp;</div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                            <img
                                                                                                                src={reviewmediass.url}
                                                                                                                alt={''}
                                                                                                                className="fixed-height-img is-160 full-width"
                                                                                                                role="button"
                                                                                                                onClick={() => this.handleOpenModal(item.reviewmedia_set, reviewmediass.url)} />
                                                                                                        )}
                                                                                                </Col>
                                                                                            );
                                                                                        })) : ("")}
                                                                                </Row>

                                                                            </div>



                                                                        </div>
                                                                    ) : (
                                                                            ""
                                                                        )}
                                                                </div>
                                                                <div className="quick-reactions d-flex flex-wrap mb-2">
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={gifwoohoo[item.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/woohoo_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction woohoo"
                                                                                title="Woohoo"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.id, "woohoo",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count
                                                                                            .woohoo +
                                                                                        (woohooCount[item.id] &&
                                                                                            woohooCount[item.id] != undefined
                                                                                            ? woohooCount[item.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/woohoo-active.png")}
                                                                                                alt="woohoo"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/woohoo.png")}
                                                                                                alt="woohoo"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Woohoo</span>
                                                                            </button>
                                                                            {item.review_group_count &&
                                                                                item.review_group_count
                                                                                    .woohoo +
                                                                                (woohooCount[item.id] &&
                                                                                    woohooCount[item.id] != undefined
                                                                                    ? woohooCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="woohoo"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "woohoo"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.woohoo +
                                                                                                (woohooCount[item.id] &&
                                                                                                    woohooCount[item.id] != undefined
                                                                                                    ? woohooCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {woohooCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : woohooCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hoverwoohoo && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipWoohoo}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (element.group_icon === "woohoo") {
                                                                                                return <p key={index}>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={gifnailed[item.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/nailedit_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction nailed"
                                                                                title="Nailed"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.id, "nailed",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count
                                                                                            .nailed +
                                                                                        (nailedCount[item.id] &&
                                                                                            nailedCount[item.id] != undefined
                                                                                            ? nailedCount[item.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/nailed-active.png")}
                                                                                                alt="nailed"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/nailed-it.png")}
                                                                                                alt="nailed"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Nailed It</span>
                                                                            </button>
                                                                            {item.review_group_count &&
                                                                                item.review_group_count
                                                                                    .nailed +
                                                                                (nailedCount[item.id] &&
                                                                                    nailedCount[item.id] != undefined
                                                                                    ? nailedCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="nailed"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "nailed"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.nailed +
                                                                                                (nailedCount[item.id] &&
                                                                                                    nailedCount[item.id] != undefined
                                                                                                    ? nailedCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {nailedCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : nailedCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hovernailed && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipNailed}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "nailed" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={
                                                                                gifinsightful[item.id] == true ? false : true
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/insightful_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction insightful"
                                                                                title="Insightful"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(
                                                                                        true,
                                                                                        item.id,
                                                                                        "insightful",item
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count
                                                                                            .insightful +
                                                                                        (insightfulCount[item.id] &&
                                                                                            insightfulCount[item.id] != undefined
                                                                                            ? insightfulCount[item.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/insightful-active.png")}
                                                                                                alt="insightful"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/insightful.png")}
                                                                                                alt="insightful"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Insightful</span>
                                                                            </button>
                                                                            {item.review_group_count &&
                                                                                item.review_group_count
                                                                                    .insightful +
                                                                                (insightfulCount[item.id] &&
                                                                                    insightfulCount[item.id] != undefined
                                                                                    ? insightfulCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="insightful"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "insightful"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.insightful +
                                                                                                (insightfulCount[item.id] &&
                                                                                                    insightfulCount[item.id] != undefined
                                                                                                    ? insightfulCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {insightfulCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : insightfulCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hoverinsightful && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipInsightful}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "insightful" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={giflol[item.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/lol_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction lol"
                                                                                title="LOL"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.id, "lol",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count
                                                                                            .lol +
                                                                                        (lolCount[item.id] &&
                                                                                            lolCount[item.id] != undefined
                                                                                            ? lolCount[item.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/lol-active.png")}
                                                                                                alt="lol"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/lol.png")}
                                                                                                alt="lol"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">LOL</span>
                                                                            </button>
                                                                            {item.review_group_count &&
                                                                                item.review_group_count
                                                                                    .lol +
                                                                                (lolCount[item.id] &&
                                                                                    lolCount[item.id] != undefined
                                                                                    ? lolCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="lol"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "lol"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.lol +
                                                                                                (lolCount[item.id] &&
                                                                                                    lolCount[item.id] != undefined
                                                                                                    ? lolCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {lolCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : lolCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hoverlol && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipLol}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "lol" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={gifbummer[item.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/bummer_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction bummer"
                                                                                title="Bummer"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.id, "bummer",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count.bummer +
                                                                                        (bummerCount[item.id] &&
                                                                                            bummerCount[item.id] != undefined
                                                                                            ? bummerCount[item.id]
                                                                                            : 0) > 0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/bummer-active.png")}
                                                                                                alt="bummer"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/bummer.png")}
                                                                                                alt="bummer"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Bummer</span>
                                                                            </button>
                                                                            {item.review_group_count &&
                                                                                item.review_group_count
                                                                                    .bummer +
                                                                                (bummerCount[item.id] &&
                                                                                    bummerCount[item.id] != undefined
                                                                                    ? bummerCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="bummer"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "bummer"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.bummer +
                                                                                                (bummerCount[item.id] &&
                                                                                                    bummerCount[item.id] != undefined
                                                                                                    ? bummerCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {bummerCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : bummerCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}

                                                                            <div
                                                                                className={
                                                                                    hoverbummer && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipBummer}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "bummer" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="fs-14">
                                                                        <div>
                                                                            <i className="d-inline-block mr-2">
                                                                                Was this review helpful?
																					</i>
                                                                            <span
                                                                                className={
                                                                                    // item.helpfulCount.helpful === 1
                                                                                    item?.helpfulCount?.profile_ids?.includes(profileId)
                                                                                        ? "font-weight-bold text-dark d-inline-block"
                                                                                        : "font-weight-bold text-tertiary d-inline-block"
                                                                                }
                                                                                role="button"
                                                                                onClick={() =>
                                                                                    this.props.post_review_group_myprofile(
                                                                                        item.listing_id,
                                                                                        { type: "helpful", review: item.id },
                                                                                        this.state.profileInfo.id,
                                                                                        item?.associated_to?.content_type?.category,
                                                                                        this.state.viewReviewsType
                                                                                    )
                                                                                }
                                                                            >
                                                                                {"Yes"}
                                                                            </span>
                                                                            <span className="mx-1">-</span>
                                                                            <span
                                                                                href="#"
                                                                                className={
                                                                                    // item.helpfulCount.helpful === 0
                                                                                    !item?.helpfulCount?.profile_ids?.includes(profileId)
                                                                                        ? "font-weight-bold text-dark d-inline-block"
                                                                                        : "font-weight-bold text-tertiary d-inline-block"
                                                                                }
                                                                                // className="font-weight-bold text-dark d-inline-block"
                                                                                role="button"
                                                                                onClick={() =>
                                                                                    this.props.post_review_group_myprofile(
                                                                                        item.listing_id,
                                                                                        { type: "not_helpful", review: item.id },
                                                                                        this.state.profileInfo.id,
                                                                                        item?.associated_to?.content_type?.category,
                                                                                        this.state.viewReviewsType
                                                                                    )
                                                                                }
                                                                            >
                                                                                {"No"}
                                                                            </span>
                                                                        </div>
                                                                        {item?.helpfulCount?.helpful > 0 &&
                                                                            item?.helpfulCount?.total > 0 ? (
                                                                                <div>
                                                                                    <span className="font-weight-bold text-dark">{`${item?.helpfulCount?.helpful} of ${item?.helpfulCount?.total}`}</span>
                                                                                    <i className="text-tertiary d-inline-block ml-2">
                                                                                        {"people found this review as helpful"}
                                                                                    </i>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <span className="font-weight-bold text-dark">{`${item?.helpfulCount?.helpful} `}</span>
                                                                                    <i className="text-tertiary d-inline-block ml-2">
                                                                                        {"people found this review as helpful"}
                                                                                    </i>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        notPeopleTab &&
                                                        <div className="text-right">
                                                            <Button color="link" className="font-weight-bold mx-1 px-1 text-primary" onClick={() => {
                                                                this.setState({
                                                                    deletereviewId: item?.id,
                                                                    deleteEntity: 'review'
                                                                });
                                                                this.confirmDeleteModalToggle();
                                                            }}>
                                                                {'delete'}
                                                            </Button>
                                                            <Button
                                                                hidden={!item?.is_draft && item?.child_review?.draft == true ? false : true}
                                                                color="link"
                                                                className="font-weight-bold mx-1 px-1 text-dark"
                                                                onClick={async () => {
                                                                    const rating = [];
                                                                    await this.fetchDataFromHtmlForm('followUP', item?.id, item?.listing_id, item?.listing_type)
                                                                    Object.keys(item?.associated_rating).map((iteam) => {

                                                                        if (iteam !== "avg_rating" && iteam !== "total_review" && iteam !== "code") {
                                                                            rating.push({
                                                                                key: iteam.replace(/ +/g, ""),
                                                                                value: 0,
                                                                                checked: false,
                                                                                label: iteam

                                                                            })
                                                                        }
                                                                    })

                                                                 this.setState({
                                                                        writeFollowUp: true,
                                                                        followUPReviewID: item.id,
                                                                        followUPAssociatedTO: item.associated_to.id,
                                                                        followUPReviewVotingKey: item.review_voting_key,
                                                                        followUPRating_sets: item.ratings_sets,
                                                                        followUPReview: item,
                                                                        categoryType: item.listing_type,
                                                                        mode: "followup",
                                                                        businessRatingList: rating,
                                                                        businessRatingListDefaut: rating,
                                                                        followupListingId: item.listing_id,
                                                                    })
                                                                    this.toggleWriteFollowUp({ isTrue: true, itemId: item.id })
                                                                }}>
                                                                {'write follow up'}
                                                            </Button>
                                                            <Button color="link"
                                                                // className="font-weight-bold mx-1 px-1 text-tertiary"
                                                                className="font-weight-bold mx-1 px-1 text-dark"
                                                                onClick={async () => {
                                                                    await this.fetchDataFromHtmlForm('edit', item.id, item.associated_to.id, item?.listing_type || item?.associated_to?.content_type?.category?.toLowerCase())
                                                                    this.handleChildReviewEdit(item, item.id)
                                                                }
                                                                }>
                                                                {'edit review'}
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                hidden={item.is_draft && item.review ? false : true}
                                                                color="primary"
                                                                data-id="publish"
                                                                className="mx-1"
                                                                onClick={
                                                                    () => {
                                                                        this.props.edit_review(
                                                                            item.id,
                                                                            {
                                                                                "is_draft": false,
                                                                                "review": isProfessionalCategory ? item.professionalreview.replace(/(<([^>]+)>)/gi, "") : item.review.replace(/(<([^>]+)>)/gi, "")
                                                                            },
                                                                            this.state.profileInfo && this.state.profileInfo.id,
                                                                            isProfessionalCategory ? "professionalreviews" : "reviews",
                                                                            item
                                                                        );
                                                                    }
                                                                }

                                                            >publish review</Button>
                                                        </div>
                                                    }
                                                </div> )
                                            }

                                            {/* Original Review, if Follow up review exists */}
                                            {item?.child_review?.review_date_time ? (
                                                <div className="review-bg p-3 mt-3">
                                                    <Row>
                                                        <Col md={2}>{` `} {` `}</Col>
                                                        <Col md={10}>
                                                            <div>
                                                                <div className="fs-14">
                                                                    <span className="text-dark font-weight-bold">
                                                                        {'Original Review'}
                                                                    </span>
                                                                    <span className="text-tertiary">
                                                                        {` `}{item.review_date_time}
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex mb-3 mx-n2">
                                                                    <div className="px-2">
                                                                        <img className="mr-2" src={require(`../../../assets/images/icons/${item?.ratings_sets && item?.ratings_sets?.avg_rating[0] ? item?.ratings_sets?.avg_rating[0] : '0rating.png'}`)}
                                                                            alt={item?.ratings_sets && item?.ratings_sets?.avg_rating[1] ? item?.ratings_sets?.avg_rating[1] : '0.0'}
                                                                            title={item?.ratings_sets && item?.ratings_sets?.avg_rating[1] ? item?.ratings_sets?.avg_rating[1] : '0.0'} />
                                                                        <span className="text-dark font-weight-bold fs-14">
                                                                            {'Overall Rating'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="my-4" hidden={checkTextVisibilty(item?.review)}>
                                                                    <i className="icon-comma-left _dark" ></i>
                                                                    <span className="comment-body-renderer" >{ReactHtmlParser(detectlink(item?.review))}</span>
                                                                    <i className="icon-comma-right"></i>
                                                                </div>
                                                                <div className="my-4" hidden={checkTextVisibilty(item?.professionalreview)}>
                                                                    <i className="icon-comma-left _dark" ></i>
                                                                    <span className="comment-body-renderer" >{ReactHtmlParser(detectlink(item?.professionalreview))}</span>
                                                                    <i className="icon-comma-right"></i>
                                                                </div>

                                                                {item?.reviewmedia_set.length > 0 ? (
                                                                        <div className="mt-4">
                                                                            {item?.reviewmedia_set.slice(0, 1).map((reviewmedia, index) => (
                                                                                <div key={index}>
                                                                                    {reviewmedia.type === "image" && (
                                                                                        <div className={`mb-3 ${item?.reviewmedia_set.length > 1 ? 'mx-auto text-center' : ''}`} style={{ maxWidth: "450px" }}>
                                                                                            <img
                                                                                                src={reviewmedia.url}
                                                                                                alt={
                                                                                                    reviewmedia.filename.length < 20
                                                                                                        ? reviewmedia.filename
                                                                                                        : this.truncate(reviewmedia.filename)
                                                                                                }
                                                                                                className="img-fluid"

                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                    {reviewmedia.type === "video" && (
                                                                                        <video width="100%" controls>
                                                                                            <source
                                                                                                src={reviewmedia.url}
                                                                                                type="video/mp4"
                                                                                            />
																		Your browser does not support the video
																		tag.
                                                                                        </video>
                                                                                    )}
                                                                                </div>
                                                                            ))}
                                                                            <div>
                                                                                <Row className="justify-content-center" xs={2} md={3} lg={3} xl={4} form>
                                                                                    {item?.reviewmedia_set && item?.reviewmedia_set.length > 1 ? (
                                                                                        item?.reviewmedia_set.map((reviewmediass, index1) => {
                                                                                            return (
                                                                                                <Col className="mb-2 wk-profile-small-images">
                                                                                                    {reviewmediass.type === "video" ? (
                                                                                                        <div
                                                                                                            onClick={() => this.handleOpenModal(item?.reviewmedia_set, reviewmediass.url)}
                                                                                                            className="video-holder">
                                                                                                            <div className="black-box _100x100">&nbsp;</div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                            <img
                                                                                                                src={reviewmediass.url}
                                                                                                                alt={''}
                                                                                                                className="fixed-height-img is-160 full-width"
                                                                                                                role="button"
                                                                                                                onClick={() => this.handleOpenModal(item?.reviewmedia_set, reviewmediass.url)} />
                                                                                                        )}
                                                                                                </Col>
                                                                                            );
                                                                                        })) : ("")}
                                                                                </Row>

                                                                            </div>



                                                                        </div>
                                                                    ) : (
                                                                            ""
                                                                        )}

                                                                

                                                                <div className="quick-reactions d-flex flex-wrap mb-2">
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={gifwoohoo[item.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/woohoo_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction woohoo"
                                                                                title="Woohoo"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.id, "woohoo",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count
                                                                                            .woohoo +
                                                                                        (woohooCount[item.id] &&
                                                                                            woohooCount[item.id] != undefined
                                                                                            ? woohooCount[item.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/woohoo-active.png")}
                                                                                                alt="woohoo"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/woohoo.png")}
                                                                                                alt="woohoo"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Woohoo</span>
                                                                            </button>
                                                                            {item.review_group_count && item.review_group_count
                                                                                .woohoo +
                                                                                (woohooCount[item.id] &&
                                                                                    woohooCount[item.id] != undefined
                                                                                    ? woohooCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="woohoo"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "woohoo"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.woohoo +
                                                                                                (woohooCount[item.id] &&
                                                                                                    woohooCount[item.id] != undefined
                                                                                                    ? woohooCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {woohooCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : woohooCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hoverwoohoo && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipWoohoo}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (element.group_icon === "woohoo") {
                                                                                                return <p key={index}>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={gifnailed[item.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/nailedit_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction nailed"
                                                                                title="Nailed"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.id, "nailed",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count
                                                                                            .nailed +
                                                                                        (nailedCount[item.id] &&
                                                                                            nailedCount[item.id] != undefined
                                                                                            ? nailedCount[item.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/nailed-active.png")}
                                                                                                alt="nailed"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/nailed-it.png")}
                                                                                                alt="nailed"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Nailed It</span>
                                                                            </button>
                                                                            {item.review_group_count && item.review_group_count
                                                                                .nailed +
                                                                                (nailedCount[item.id] &&
                                                                                    nailedCount[item.id] != undefined
                                                                                    ? nailedCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="nailed"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "nailed"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.nailed +
                                                                                                (nailedCount[item.id] &&
                                                                                                    nailedCount[item.id] != undefined
                                                                                                    ? nailedCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {nailedCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : nailedCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hovernailed && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipNailed}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "nailed" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={
                                                                                gifinsightful[item.id] == true ? false : true
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/insightful_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction insightful"
                                                                                title="Insightful"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(
                                                                                        true,
                                                                                        item.id,
                                                                                        "insightful",item
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count
                                                                                            .insightful +
                                                                                        (insightfulCount[item.id] &&
                                                                                            insightfulCount[item.id] != undefined
                                                                                            ? insightfulCount[item.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/insightful-active.png")}
                                                                                                alt="insightful"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/insightful.png")}
                                                                                                alt="insightful"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Insightful</span>
                                                                            </button>
                                                                            {item.review_group_count &&
                                                                                item.review_group_count.insightful +
                                                                                (insightfulCount[item.id] &&
                                                                                    insightfulCount[item.id] != undefined
                                                                                    ? insightfulCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="insightful"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "insightful"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.insightful +
                                                                                                (insightfulCount[item.id] &&
                                                                                                    insightfulCount[item.id] != undefined
                                                                                                    ? insightfulCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {insightfulCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : insightfulCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hoverinsightful && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipInsightful}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "insightful" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={giflol[item.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/lol_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction lol"
                                                                                title="LOL"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.id, "lol",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count
                                                                                            .lol +
                                                                                        (lolCount[item.id] &&
                                                                                            lolCount[item.id] != undefined
                                                                                            ? lolCount[item.id]
                                                                                            : 0) >
                                                                                        0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/lol-active.png")}
                                                                                                alt="lol"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/lol.png")}
                                                                                                alt="lol"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">LOL</span>
                                                                            </button>
                                                                            {item.review_group_count &&
                                                                                item.review_group_count
                                                                                    .lol +
                                                                                (lolCount[item.id] &&
                                                                                    lolCount[item.id] != undefined
                                                                                    ? lolCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="lol"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "lol"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.lol +
                                                                                                (lolCount[item.id] &&
                                                                                                    lolCount[item.id] != undefined
                                                                                                    ? lolCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {lolCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : lolCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}
                                                                            <div
                                                                                className={
                                                                                    hoverlol && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipLol}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "lol" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="reaction-holder">
                                                                        {/* Show on reacting */}
                                                                        <div
                                                                            className="reaction-anim"
                                                                            hidden={gifbummer[item.id] == true ? false : true}
                                                                        >
                                                                            <img
                                                                                src={require("../../../assets/images/icons/bummer_forever.gif")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="reaction-btn-group">
                                                                            <button
                                                                                className="reaction bummer"
                                                                                title="Bummer"
                                                                                onClick={() => {
                                                                                    this.addreviewGroup(true, item.id, "bummer",item);
                                                                                }}
                                                                            >
                                                                                <span className="icon">
                                                                                    {item.review_group_count &&
                                                                                        item.review_group_count.bummer +
                                                                                        (bummerCount[item.id] &&
                                                                                            bummerCount[item.id] != undefined
                                                                                            ? bummerCount[item.id]
                                                                                            : 0) > 0 ? (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/bummer-active.png")}
                                                                                                alt="bummer"
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={require("../../../assets/images/icons/bummer.png")}
                                                                                                alt="bummer"
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                                <span className="name">Bummer</span>
                                                                            </button>
                                                                            {item.review_group_count &&
                                                                                item.review_group_count
                                                                                    .bummer +
                                                                                (bummerCount[item.id] &&
                                                                                    bummerCount[item.id] != undefined
                                                                                    ? bummerCount[item.id]
                                                                                    : 0) >
                                                                                0 ? (
                                                                                    <button
                                                                                        className="reaction"
                                                                                        data-id={item.id}
                                                                                        data-name="bummer"
                                                                                        onClick={() => {
                                                                                            this.handleOnClickReviewType(
                                                                                                item.review_group_count,
                                                                                                item.id,
                                                                                                "bummer"
                                                                                            );
                                                                                        }}
                                                                                        onMouseOver={this.handleMouseIn.bind(this)}
                                                                                        onMouseOut={this.handleMouseOut.bind(this)}
                                                                                    >
                                                                                        <span className="count">
                                                                                            {item.review_group_count
                                                                                                ? item.review_group_count.bummer +
                                                                                                (bummerCount[item.id] &&
                                                                                                    bummerCount[item.id] != undefined
                                                                                                    ? bummerCount[item.id]
                                                                                                    : 0)
                                                                                                : "0"}
                                                                                        </span>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button className="reaction">
                                                                                        <span className="count">
                                                                                            {bummerCount[item.id] == undefined
                                                                                                ? 0
                                                                                                : bummerCount[item.id]}
                                                                                        </span>
                                                                                    </button>
                                                                                )}

                                                                            <div
                                                                                className={
                                                                                    hoverbummer && hoverReviewId === item.id
                                                                                        ? "tooltiptext"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <div style={tooltipBummer}>
                                                                                    {fetchReviewGroup.length > 0 &&
                                                                                        fetchReviewGroup.map((element, index) => {
                                                                                            if (
                                                                                                element.group_icon === "bummer" &&
                                                                                                hoverReviewId === element.id
                                                                                            ) {
                                                                                                return <p>{element.name}</p>;
                                                                                            }
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className="fs-14">
                                                                        <div> <i className="d-inline-block mr-2">
                                                                            Was this review helpful?
																					</i>
                                                                            <span
                                                                                className={
                                                                                    // item?.helpfulCount.helpful === 1
                                                                                    item?.helpfulCount?.profile_ids?.includes(profileId)
                                                                                        ? "font-weight-bold text-dark d-inline-block"
                                                                                        : "font-weight-bold text-tertiary d-inline-block"
                                                                                }
                                                                                role="button"
                                                                                onClick={() =>
                                                                                    this.props.post_review_group_myprofile(
                                                                                        item?.listing_id,
                                                                                        { type: "helpful", review: item?.id },
                                                                                        this.state.profileInfo.id,
                                                                                        item?.associated_to?.content_type?.category,
                                                                                        this.state.viewReviewsType
                                                                                    )
                                                                                }
                                                                            >
                                                                                {"YES"}
                                                                            </span>
                                                                            <span className="mx-1">-</span>
                                                                            <span
                                                                                href="#"
                                                                                className={
                                                                                    // item?.helpfulCount.helpful === 0
                                                                                    !item?.helpfulCount?.profile_ids?.includes(profileId)
                                                                                        ? "font-weight-bold text-dark d-inline-block"
                                                                                        : "font-weight-bold text-tertiary d-inline-block"
                                                                                }
                                                                                // className="font-weight-bold text-dark d-inline-block"
                                                                                role="button"
                                                                                onClick={() =>
                                                                                    this.props.post_review_group_myprofile(
                                                                                        item?.listing_id,
                                                                                        { type: "not_helpful", review: item?.id },
                                                                                        this.state.profileInfo.id,
                                                                                        item?.associated_to?.content_type?.category,
                                                                                        this.state.viewReviewsType
                                                                                    )
                                                                                }
                                                                            >
                                                                                {"NO"}
                                                                            </span>
                                                                        </div>
                                                                        {item?.helpfulCount?.helpful > 0 &&
                                                                            item?.helpfulCount?.total > 0 ? (
                                                                                <div>
                                                                                    <span className="font-weight-bold text-dark">{`${item?.helpfulCount?.helpful} of ${item?.helpfulCount?.total}`}</span>
                                                                                    <i className="text-tertiary d-inline-block ml-2">
                                                                                        {"people found this review as  helpful"}
                                                                                    </i>
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    <span className="font-weight-bold text-dark">{`${item?.helpfulCount?.helpful} `}</span>
                                                                                    <i className="text-tertiary d-inline-block ml-2">
                                                                                        {"people found this review as  helpful"}
                                                                                    </i>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className="text-right">
                                                       { notPeopleTab &&
                                                        <div className="text-right">
                                                            <Button color="link" className="font-weight-bold mx-1 px-1 text-primary" onClick={() => {
                                                                this.setState({
                                                                    deletereviewId: item?.id,
                                                                    deleteEntity: 'review'
                                                                });
                                                                this.confirmDeleteModalToggle();
                                                            }}>
                                                                {'delete'}
                                                            </Button>
                                                            <Button
                                                                hidden={!item?.is_draft && item?.child_review?.draft == true ? false : true}
                                                                color="link"
                                                                className="font-weight-bold mx-1 px-1 text-dark"
                                                                onClick={async () => {
                                                                    const rating = [];
                                                                    await this.fetchDataFromHtmlForm('followUP', item?.id, item?.listing_id, item?.listing_type)
                                                                    Object.keys(item?.associated_rating).map((iteam) => {

                                                                        if (iteam !== "avg_rating" && iteam !== "total_review" && iteam !== "code") {
                                                                            rating.push({
                                                                                key: iteam.replace(/ +/g, ""),
                                                                                value: 0,
                                                                                checked: false,
                                                                                label: iteam

                                                                            })
                                                                        }
                                                                    })

                                                                 this.setState({
                                                                        writeFollowUp: true,
                                                                        followUPReviewID: item.id,
                                                                        followUPAssociatedTO: item.associated_to.id,
                                                                        followUPReviewVotingKey: item.review_voting_key,
                                                                        followUPRating_sets: item.ratings_sets,
                                                                        followUPReview: item,
                                                                        categoryType: item.listing_type,
                                                                        mode: "followup",
                                                                        businessRatingList: rating,
                                                                        businessRatingListDefaut: rating,
                                                                        followupListingId: item.listing_id,
                                                                    })
                                                                    this.toggleWriteFollowUp({ isTrue: true, itemId: item.id })
                                                                }}>
                                                                {'write follow up'}
                                                            </Button>
                                                            <Button color="link"
                                                                // className="font-weight-bold mx-1 px-1 text-tertiary"
                                                                className="font-weight-bold mx-1 px-1 text-dark"
                                                                onClick={async () => {
                                                                    await this.fetchDataFromHtmlForm('edit', item.id, item.associated_to.id, item?.listing_type || item?.associated_to?.content_type?.category?.toLowerCase())
                                                                    this.handleChildReviewEdit(item, item.id)
                                                                }
                                                                }>
                                                                {'edit review'}
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                hidden={item.is_draft && item.review ? false : true}
                                                                color="primary"
                                                                data-id="publish"
                                                                className="mx-1"
                                                                onClick={
                                                                    () => {
                                                                        this.props.edit_review(
                                                                            item.id,
                                                                            {
                                                                                "is_draft": false,
                                                                                "review": isProfessionalCategory ? item.professionalreview.replace(/(<([^>]+)>)/gi, "") : item.review.replace(/(<([^>]+)>)/gi, "")
                                                                            },
                                                                            this.state.profileInfo && this.state.profileInfo.id,
                                                                            isProfessionalCategory ? "professionalreviews" : "reviews",
                                                                            item
                                                                        );
                                                                    }
                                                                }

                                                            >publish review</Button>
                                                        </div>}
                                                    </div>
                                                </div>
                                                
                                            ) : null}

                                        </div>
                                        {/* Write Follow up review */}

                                        {this.state.isToggleWriteUp[item?.id] && <>
                                            <CreateReviewFollowUp
                                                avarageTrue={false}
                                                listing_type={item.listing_type || "professionals"}
                                                toggleWriteFollowUp={this.toggleWriteFollowUp}
                                                itemDetail={item}
                                                reviews={reviews}
                                                item={item}
                                                isWriteFollowUp={true}
                                                toggleEnableFileUpload={(hideEmbed, galleryTypeUpload) => {
                                                    this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true, true);
                                                }}
                                                // overallRatingVote={overallRatingVote}
                                                // viewEditReviewStep={viewEditReviewStep}
                                                // uploadedMediaSet={uploadedFilesFollowUp}
                                                // embedMediaFollowUp={embedMediaFollowUp}
                                                // removeUploadedMediaSet={this.props.removeUploadedMediaSet}
                                                // removeUploadedMediaSetFollow={this.props.removeUploadedMediaSetFollow}
                                                // onPublishReview={onPublishReview}
                                                // my_review_count={my_profile?.review_statistics?.count}
                                                categoryId={item?.associated_to?.id}
                                                type="followUp"
                                                followUpType="reviewPage"
                                                reCallApi={this.reviewsAPI}
                                            />
                                            {isEnableFileUpload ?
                                                <UploadMedia
                                                    getUploadedMedia={this.getUploadedMedia}
                                                    callbackFunction={this.handleOnClickUploadModalOk}
                                                    typeUpload={item.listing_type}
                                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload) => {
                                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, this.state.reviewOneToggler ? true : false);

                                                    }}
                                                   
                                                />
                                                : null}
                                        </>}
                                    </div>
                                ))
                                :
                                this.state.loadingMoreData ?
                                    <div className="bg-white p-3">
                                        <h2 className="text-secondary-dark">Loading...</h2>
                                    </div>
                                    :
                                    <div className="bg-white p-3">
                                        <h2 className="text-secondary-dark">No reviews to display</h2>
                                    </div>
                            }

                            
                            {this.state.loadingMoreData && this.state?.reviews?.results?.length > 0
                                ?
                                <div className="bg-white p-3">
                                    <h2 className="text-secondary-dark">Loading...</h2>
                                </div>
                                :
                                ''
                            }

                            <div style={{ height: "200px" }} />
                        </>}

                

                </TabPane>
                {/* Write Edit up review */}
                <Modal isOpen={editReviewModalToggle} toggle={this.toggleModal} >

                    <ModalBody className="p-0 bg-transparent" >
                        <div>
                            <div className="bg-white p-2 mb-3">
                                <h2 className="mb-0 text-dark fs-36">You're editing your review for &nbsp;
								<span className="text-darker">{this.state.modalData?.associated_to?.name}</span></h2>
                            </div>

                            <div className="bg-white mb-3">
                                <CollapseBasic title="Step 1. Star Ratings" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true} isReviewCollapse={true}>
                                    <div className="text-dark">
                                        {/* {'Rating Input'} */}
                                        <div className="text-tertiary">
                                            <div className="stars-rating-input _lg">
                                                <span
                                                    className={this.state.modalData && this.state.modalData.ratings_sets && (this.state.modalData.ratings_sets["avg_rating"][1] === "0" || this.state.modalData.ratings_sets["avg_rating"][1] === "No Rating") ? "zero-rating-input active" : "zero-rating-input"}
                                                    role="button"
                                                    onClick={(value) => {
                                                        this.handleModalForm("ratings_sets", "avg_rating", 0)
                                                    }} >
                                                    <FontAwesomeIcon icon="ban" />
                                                </span>
                                                <StarsRating
                                                    count={5}
                                                    value={this.state.modalData !== null ? this.state.modalData.ratings_sets && this.state.modalData.ratings_sets["avg_rating"][1] : "0"}
                                                    onChange={(value) => {
                                                        if (value > 0) {
                                                            this.setState({ editReviewError: "" })
                                                        }
                                                        this.handleModalForm("ratings_sets", "avg_rating", value)
                                                    }}
                                                    size={34} color1={'#d7d8e2'} color2={'#15c3d4'} />
											&nbsp;&nbsp;&nbsp;<span style={{ color: "red" }}>{this.state.editReviewError}</span>
                                            </div>
                                        </div>
                                        <div className="mt-2 text-tertiary">
                                            <span className="font-weight-bold fs-14">
                                                {'Detailed Ratings'} <span className="ff-alt font-weight-normal">{'(optional)'}</span>
                                            </span>

                                            <hr className="bg-light" />
                                        </div>

                                        <Row className="fs-14 ff-alt">
                                            {modalData && modalData.associated_rating &&
                                                Object.keys(modalData.associated_rating).length > 0
                                                ? Object.keys(modalData.associated_rating).map(
                                                    (data, i) => (
                                                        (data !== "avg_rating" && data !== "total_review") ? (
                                                            <Col className="mb-2" md={6} key={i}>
                                                                <div>{data}</div>
                                                                <div className="stars-rating-input">
                                                                    <span
                                                                        className={modalData !== null && modalData.ratings_sets && modalData.ratings_sets[data] && modalData.ratings_sets[data][1] === "0" ? "zero-rating-input active" : "zero-rating-input"}

                                                                        role="button"
                                                                        onClick={(value) => {
                                                                            this.handleModalForm("associated_rating", data, 0, modalData.review_voting_key, modalData.listing_id)
                                                                        }}
                                                                    >
                                                                        <FontAwesomeIcon icon="ban" />
                                                                    </span>
                                                                    <StarsRating
                                                                        count={5}
                                                                        value={modalData !== null && modalData.ratings_sets && modalData.ratings_sets[data] ? modalData.ratings_sets[data][1] : "0"}
                                                                        onChange={(value) => {
                                                                            this.handleModalForm("associated_rating", data, value, modalData.review_voting_key, modalData.listing_id)
                                                                        }}
                                                                        size={24} color1={'#d7d8e2'} color2={'#15c3d4'} />
                                                                    <span className="clear-rating" role="button" title="Clear"
                                                                        onClick={async () => {
                                                                            await this.handleModalFormRemove("associated_rating", data, '', modalData.review_voting_key, modalData.listing_id);
                                                                            await this.props.remove_ratings_vote(
                                                                                {
                                                                                    "key": data.replace(/[^A-Z0-9]/ig, ""),
                                                                                    "voting_key": modalData.review_voting_key
                                                                                }
                                                                            )
                                                                        }}
                                                                    ><span className="icon-cross"></span>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        ) : ''
                                                    ))
                                                : null}

                                        </Row>
                                    </div>
                                </CollapseBasic>
                            </div>

                            <div className="bg-white mb-3">
                                <CollapseBasic title="Step 2. Review" containerClass="type-tertiary type-review bg-transparent" isOpen={false} size="sm" noPadding={true} isReviewCollapse={true}>
                                    <div className="text-tertiary fs-14 mb-3">
                                        {' Please provide as much information as you can in your review to be as meaningful as possible to others.'}
                                    </div>
                                    <Nav tabs className="mb-3 main active-transparent">
                                        <NavItem>
                                            <NavLink href="#"
                                                active={this.state.viewEditReviewStep === "writeReview"}
                                                onClick={() => { this.setState({ viewEditReviewStep: "writeReview" }) }}>
                                                <span className="font-weight-bold">
                                                    {'Write a Review'}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#"
                                                active={this.state.viewEditReviewStep === "recordVideo"}
                                                onClick={() => {
                                                    this.setState({ viewEditReviewStep: "recordVideo" })
                                                }}
                                            >or record a
                                        <span className="font-weight-bold">
                                                    &nbsp; Video Review
                                        </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent className="mb-3" activeTab={this.state.viewEditReviewStep}>
                                        <TabPane tabId="writeReview">
                                            <FormGroup className="main-post-formgroup">
                                                <div className="input-label-block">
                                                    <RichTextEditor
                                                        toolbarConfig={toolbarConfig}
                                                        value={this.state.textData}
                                                        onChange={this.handleChangeEditor}
                                                        className="primary"
                                                    // name="textEditor"

                                                    />
                                                    {/*<Input
                                                        className="primary"
                                                        type="textarea"
                                                        name="review"
                                                        rows="4"
                                                        id="review"
                                                        onChange={(value) => this.handleChange('review', value)}
                                                        value={this.state.modalData && this.state.modalData.review.replace(/(<([^>]+)>)/gi, "")}
                                                        placeholder="&nbsp;"
                                                        defaultValue="hi Google how are you how are you doing are you sleeping or not tell you awake ok"
                                                    />*/}
                                                </div>
                                                <div className="characters-length-text">
                                                    Characters: <span>{totalCharacter}</span>
                                                </div>
                                            </FormGroup>
                                        </TabPane>
                                        <TabPane tabId="recordVideo">
                                            <div>
                                                {this.state.viewEditReviewStep === "recordVideo" && (
                                                    <WebCamRecording />
                                                )}
                                            </div>
                                        </TabPane>
                                    </TabContent>

                                    <div className="fs-14">
                                        <div className="text-dark">
                                            <span className="font-weight-bold">
                                                Choose Review Categories
                                        </span>
                                            <span>
                                                (Optional)
                                        </span>
                                        </div>
                                        <hr className="bg-light my-2" />
                                        <p className="text-tertiary fs-14 mb-2">
                                            If your review above contains information about a particular element or elements that you would like to highlight, please check the corresponding boxes below.
                                    </p>
                                        <Row>
                                            {modalData && modalData.ratings_sets &&
                                                Object.keys(modalData.ratings_sets).length > 0
                                                ? Object.keys(modalData.ratings_sets).map(
                                                    (data, i) => (
                                                        (data !== "avg_rating" && data !== "total_review") ? (
                                                            <Col xs={6} key={i}>
                                                                <FormGroup check>
                                                                    <Label className="text-primary font-weight-bold" check>
                                                                        <Input type="checkbox" id="checkbox2" />{' '}
                                                                        {data}
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        ) : ''
                                                    ))
                                                : null}
                                            {/* <Col xs={6}>
                                                <FormGroup check>
                                                    <Label className="text-primary font-weight-bold" check>
                                                        <Input type="checkbox" id="checkbox2" />{' '}
                                                    Room Size
                                                </Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={6}>
                                                <FormGroup check>
                                                    <Label className="text-primary font-weight-bold" check>
                                                        <Input type="checkbox" id="checkbox2" />{' '}
                                                    Services
                                                </Label>
                                                </FormGroup>
                                            </Col> */}
                                        </Row>
                                    </div>
                                </CollapseBasic>
                            </div>

                            <div className="bg-white mb-3">
                                <CollapseBasic title="Step 3. Upload Images and/or Video (optional)" containerClass="type-tertiary type-review bg-transparent" isOpen={false} size="sm" noPadding={true} isReviewCollapse={true}>
                                    <p className="text-tertiary fs-14 mb-3">
                                        Upload images or video to accompany your review. Additional images/videos can be added later.
                                </p>
                                    {/* Uploaded media shown here */}
                                    <div className="border mb-3">
                                        <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                            {this.state.uploadMedia.selectedMedia.length > 0 &&
                                                this.state.uploadMedia.selectedMedia.map((item) => {
                                                    return (
                                                        <Col>
                                                            <div className="d-flex pr-3 m-2">
                                                                <div>
                                                                    {(item.type === "image" ||
                                                                        item.media_type === "image" ||
                                                                        item.type === "video" ||
                                                                        item.media_type === "video") && (
                                                                            <div className="project-thumbnail"

                                                                                style={{ border: "none" }}
                                                                            >

                                                                                { item.media_type === 'image' ?
                                                                                    <img
                                                                                        className="img-fluid img-thumbnail"
                                                                                        src={item.url ? item.url : item.file}
                                                                                        alt={
                                                                                            item.filename
                                                                                                ? item.filename.length < 20
                                                                                                    ? item.filename
                                                                                                    : this.truncate(item.filename)
                                                                                                : item.name
                                                                                                    ? item.name.length < 20
                                                                                                        ? item.name
                                                                                                        : this.truncate(item.name)
                                                                                                    : ""
                                                                                        } /> :
                                                                                    <img
                                                                                        className="img-fluid img-thumbnail"
                                                                                        src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
                                                                                        alt={
                                                                                            item.filename
                                                                                                ? item.filename.length < 20
                                                                                                    ? item.filename
                                                                                                    : this.truncate(item.filename)
                                                                                                : item.name
                                                                                                    ? item.name.length < 20
                                                                                                        ? item.name
                                                                                                        : this.truncate(item.name)
                                                                                                    : ""
                                                                                        }
                                                                                    />}


                                                                            </div>
                                                                        )}
                                                                </div>

                                                                <div className="mx-n3 mt-2">
                                                                    <Button
                                                                        color="delete-media"
                                                                        size="sm"
                                                                        title="Remove Media"
                                                                        onClick={() =>
                                                                            this.handleOnClickRemoveSelectedGalleryMedia(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                })}

                                        </Row>
                                    </div>
                                    <Button color="tertiary reset" onClick={() => this.handleOnClickUploadMedia()}>
                                        {'Upload Media'}
                                    </Button>
                                </CollapseBasic>
                            </div>
                            <div className="bg-white p-2">
                                <div className="mb-3">
                                    <FormGroup check>
                                        <Label className="text-tertiary ff-alt fs-14" check>
                                            <Input type="checkbox" id="checkbox2" onChange={() => {
                                                this.setState({ enableEditReviewSocialShare: !this.state.enableEditReviewSocialShare })
                                            }} />{' '}
                                            {'Also post this review on social networks.'}
                                        </Label>
                                    </FormGroup>
                                    <div className="mt-3 pl-4">
                                        <FacebookShareButton
                                            url={`${this.state.protocol}//${this.state.host}/myprofile`}
                                            hashtag={this.state.fbHashTag}
                                            quote={`Review of ${this.state.modalData?.associated_to?.name}: ${this.state.modalData?.review?.replace(/(<([^>]+)>)/gi, "")}`}
                                            className="text-dark text-decoration-none mx-2">
                                            <Button color={!(this.state.enableEditReviewSocialShare) ? "tertiary reset" : "primary"} disabled={!this.state.enableEditReviewSocialShare}>
                                                {'Post To Facebook'}
                                            </Button>
                                        </FacebookShareButton>

                                        <TwitterShareButton
                                            url={`${this.state.protocol}//${this.state.host}/myprofile`}
                                            title={`Review of ${this.state.modalData?.associated_to?.name}: ${this.state.modalData?.review?.replace(/(<([^>]+)>)/gi, "")}`}
                                            via={this.state.shareVia}
                                            hashtags={this.state.twitterHashTags}
                                            className="text-dark text-decoration-none mx-2">
                                            <Button color={!(this.state.enableEditReviewSocialShare) ? "tertiary reset" : "primary"} disabled={!this.state.enableEditReviewSocialShare}>Post To Twitter</Button>
                                        </TwitterShareButton>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <FormGroup check>
                                        <Label className="text-tertiary font-weight-bold ff-alt fs-14" check>
                                            <Input type="checkbox" id="editReviewChecked"
                                                checked={this.state.editReviewChecked === true ? true : false}
                                                onClick={async () => {
                                                    await this.setState({
                                                        editReviewChecked: !this.state.editReviewChecked
                                                    })
                                                }} />{' '}
                                            {'I adhere to the'} <a href="#" className="text-dark">{'Review Tips'} & {'Guidelines.'}</a>
                                        </Label>
                                    </FormGroup>
                                </div>
                                <div className="text-right">
                                    <Button onClick={() => this.setState({ editReviewModalToggle: false })} size="lg" color="grey" className="mw">Cancel</Button>
                                    {this.state.modalData && this.state.modalData.is_anonymous === true ? "" : <Button size="lg" color="tertiary" className="mw" data-id="draft"
                                        disabled={this.state.editReviewChecked ? false : true}
                                        onClick={this.handleOnSubmitReview} >Update Draft</Button>}
                                    <Button
                                        size="lg"
                                        color="primary"
                                        data-id="publish"
                                        className="mw"
                                        title={this.state.editReviewChecked ? "" : "Please accept the Review Tips & Guidelines before publishing."}
                                        disabled={this.state.editReviewChecked ? false : true}
                                        onClick={this.handleOnSubmitReview}>Publish Review</Button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                {/* Upload Media Post Modal */}
                < Modal
                    isOpen={this.state.uploadMediaPostModal}
                    toggle={this.uploadMediaModalToggle}
                >
                    <ModalHeader toggle={this.uploadMediaModalToggle}>
                        UPLOAD MEDIA
          </ModalHeader>
                    <ModalBody className="p-3">
                        <Row>
                            <Col xs={"auto"}>
                                <div className="bg-light h-100 p-3">
                                    <Nav tabs className="flex-column active-transparent border-0 m-0">
                                        <NavItem className="text-left">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "upload"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("upload");
                                                }}
                                            >
                                                Upload
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "gallery"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("gallery");
                                                }}
                                            >
                                                Gallery
                      </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "embed"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("embed");
                                                }}
                                            >
                                                Embed
                      </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Col>
                            <Col>
                                <TabContent activeTab={this.state.selectedUpMediaType}>
                                    <TabPane tabId="upload">
                                        <div
                                            className="mb-3 type-file-block bg-light"
                                            hidden={
                                                this.state.uploadMedia.uploadFiles &&
                                                    this.state.uploadMedia.uploadFiles.length > 0
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <Input
                                                type="file"
                                                name="upload_media_file"
                                                id="uploadFilesPostFromSystem"
                                                accept="image/*, video/*"
                                                onChange={this.handleOnFilePostUploadChange}
                                                multiple
                                            />
                                            <Label for="uploadFilesPostFromSystem">
                                                <div className="type-bg"></div>
                                            </Label>
                                        </div>
                                        {this.state.uploadMedia.uploadFiles.length > 0 && (
                                            <Row className="mb-3" form>
                                                <Col xs={12}>
                                                    <div
                                                        style={{
                                                            maxWidth: "120px",
                                                            margin: "0 1rem 1rem auto",
                                                        }}
                                                    >
                                                        <div className="text-center mb-1 small">
                                                            {this.state.uploadMedia.progress === 100 ? (
                                                                <div className="text-success">
                                                                    <FontAwesomeIcon
                                                                        icon="check-circle"
                                                                        className="mr-1"
                                                                    />{" "}
                                  Uploaded
                                                                </div>
                                                            ) : (
                                                                    <div>
                                                                        Uploading{" "}
                                                                        <span className="text-success font-weight-bold ff-base">
                                                                            {this.state.uploadMedia.progress.toFixed(0)}
                                    %
                                  </span>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <Progress
                                                            value={this.state.uploadMedia.progress}
                                                            style={{ height: "8px" }}
                                                        ></Progress>
                                                    </div>
                                                </Col>
                                                {this.state.uploadMedia.uploadFiles.map((file, index) => {

                                                    return (
                                                        <Col xs="auto">
                                                            <div className="bg-light p-2">
                                                                <div className="d-flex pr-3">
                                                                    <div>
                                                                        <div
                                                                            className="selectable-media"
                                                                            style={{ cursor: "default" }}
                                                                        >
                                                                            <div className="gallery-media">
                                                                                {
                                                                                    file.media_type === 'image' ?
                                                                                        <img src={file.url} alt="" /> :
                                                                                        <img src={file.thumbnail && file.thumbnail !== null ? file.thumbnail : file.url} alt="" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mx-n3 mt-2">
                                                                        <Button
                                                                            color="delete-media _alt"
                                                                            size="sm"
                                                                            title="Remove Media"
                                                                            hidden={file.id === "" ? true : false}
                                                                            onClick={this.handleOnClickRemoveSelectedMedia(
                                                                                file.id
                                                                            )}
                                                                        >
                                                                            <FontAwesomeIcon icon="trash-alt" />{" "}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Input bsSize="sm" type="text" name="caption" className="caption-input" placeholder="Caption" onBlur={(e) => this.handleCaptionByIndex(e, index)} style={{ maxWidth: '160px' }} />
                                                                    <Input onChange={(e) => this.handleChangeCategory(e, index)} bsSize="sm" type="select" name="album_type_category" id="selectCategoryTypeData" style={{ maxWidth: '160px' }}>
                                                                        <option value="">Choose Category</option>
                                                                        {cattaxListing && Object.keys(cattaxListing).length > 0 ?
                                                                            Object.values(cattaxListing).map(
                                                                                (data, i) => (
                                                                                    <>
                                                                                        <option value={data.id}>{data.category}</option>
                                                                                    </>
                                                                                ))
                                                                            : null}
                                                                    </Input>
                                                                    {uploadedId[index] == file.id && (Object.keys(fetchTaxonomyListingImages).length > 0 || Object.keys(fetchTaxonomyListingVideos).length > 0) ?
                                                                        <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_type_select" id="selectAlbumTypeData" style={{ maxWidth: '160px' }}>
                                                                            <option value="">Choose Subcategory</option>
                                                                            {file.media_type === "image" && fetchTaxonomyListingImages && Object.keys(fetchTaxonomyListingImages).length > 0 && fetchTaxonomyListingImages[index] ?
                                                                                fetchTaxonomyListingImages[index].map(item => (
                                                                                    <option value={item.id}>{item.label}</option>
                                                                                ))
                                                                                : null}
                                                                            {file.media_type === "video" && fetchTaxonomyListingVideos && Object.keys(fetchTaxonomyListingVideos).length > 0 && fetchTaxonomyListingImages[index] ?
                                                                                fetchTaxonomyListingVideos[index].map(item => (
                                                                                    <option value={item.id}>{item.label}</option>
                                                                                ))
                                                                                : null}
                                                                        </Input>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                })}
                                                <Col xs="auto">
                                                    <div className="d-flex pt-3">
                                                        <div className="selectable-media" hidden>
                                                            <Label
                                                                for="uploadFilesFromSystemMini"
                                                                className="gallery-media"
                                                                style={{
                                                                    borderStyle: "dashed",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <div className="d-flex h-100 align-items-center justify-content-center">
                                                                    <span className="fs-14">Upload More</span>
                                                                </div>
                                                            </Label>
                                                            <Input
                                                                type="file"
                                                                name="upload_media_file"
                                                                id="uploadFilesFromSystemMini"
                                                                accept="image/*, video/*"
                                                                onChange={this.handleOnFilePostUploadChange}
                                                                multiple
                                                                style={{ display: "none" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <div className="d-flex mx-n2 justify-content-center">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            uploadMediaPostModal: false,
                                                        });
                                                    }}
                                                >
                                                    {'OK'}
                                                </Button>
                                            </div>
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={this.uploadMediaModalToggle}
                                                >
                                                    Cancel
											</Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="gallery">
                                        <div className="mb-2">
                                            <Nav tabs className="d-inline-flex mb-0">
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        active={this.state.showGalleryType === "images"}
                                                        onClick={() => {
                                                            this.handleOnClickGalleryType("images");
                                                        }}
                                                    >
                                                        Image Gallery
                          </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        active={this.state.showGalleryType === "videos"}
                                                        onClick={() => {
                                                            this.handleOnClickGalleryType("videos");
                                                        }}
                                                    >
                                                        Video Gallery
                          </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={this.state.showGalleryType}>
                                            <TabPane tabId="images">
                                                <div>
                                                    <FormGroup className="form-row justify-content-end">
                                                        <Col xs="auto">
                                                            <Label className="small" for="selectAlbumType">
                                                                Album Type:
                              </Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Input
                                                                bsSize="sm"
                                                                type="select"
                                                                name="album_type_select"
                                                                id="selectAlbumType"
                                                                defaultValue={this.state.uploadMedia.albumType}
                                                                onChange={this.handleOnClickAlbumTypeChange}
                                                            >
                                                                <option value="">All</option>
                                                                {this.state.uploadMedia.albumTypesList.length >
                                                                    0 &&
                                                                    this.state.uploadMedia.albumTypesList.map(
                                                                        (type) => (
                                                                            <option value={type.category}>
                                                                                {type.category}
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </Input>

                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: "350px", overflow: "auto" }}
                                                    ref={this.refGalleryImageScroll}
                                                    onScroll={this.handleScrollGalleryImage}
                                                >
                                                    {this.state.uploadMedia.albumTypeData &&
                                                        this.state.uploadMedia.albumTypeData.count > 0 ? (
                                                            <Row>

                                                                {this.fetchScrollPhotoMedia()}
                                                            </Row>
                                                        ) : (
                                                            <div className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">No images</h2>
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="d-flex mx-n2 justify-content-center">

                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    uploadMediaPostModal: false,
                                                                });
                                                            }}
                                                        >
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={this.uploadMediaModalToggle}
                                                        >
                                                            Cancel
                            </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="videos">
                                                <div
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: "350px", overflow: "auto" }}
                                                    ref={this.refGalleryVideoScroll}
                                                    onScroll={this.handleScrollGalleryVideo}
                                                >
                                                    {this.state.uploadMedia.albumTypeData &&
                                                        this.state.uploadMedia.albumTypeData.count > 0 ? (
                                                            <Row>
                                                                {this.fetchScrollPhotoMedia(true)}
                                                            </Row>
                                                        ) : (
                                                            <div className="bg-white p-3">
                                                                <h2 className="text-secondary-dark">No videos</h2>
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={this.uploadMediaModalToggle}
                                                        >
                                                            Cancel
                            </Button>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    uploadMediaPostModal: false,
                                                                });
                                                            }}
                                                        >
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </TabPane>
                                    <TabPane tabId="embed">
                                        <div className="text-muted mb-2">
                                            Submit the link for the image or video you want to embed{" "}
                                        </div>
                                        <div className="mb-2">
                                            {/* Repeat this while adding */}
                                            <div className="d-flex mx-n2 mb-2" key={0}>
                                                <div className="flex-fill px-2">
                                                    <Input
                                                        type="url"
                                                        bsSize="sm"
                                                        className="bg-white"
                                                        value={embeddedLink}
                                                        onChange={(e) => { this.setState({ embeddedLink: e.target.value }) }}
                                                        placeholder="Embeded link"
                                                    />
                                                </div>
                                                {/* <div className="px-2">
                                                    <Button
                                                        title="Remove"
                                                        color="danger"
                                                        size="sm"
                                                        hidden={false}
                                                        onClick={() => { }}>
                                                        <FontAwesomeIcon icon="minus" />
                                                    </Button>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* <div className="d-flex mx-n2 mb-2">
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    title="Add"
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => { }}>
                                                    <FontAwesomeIcon icon="plus" />
                                                </Button>
                                            </div>
                                        </div> */}
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.setState({ embeddedLink: '', uploadMediaPostModal: false }) }} >
                                                    {'Cancel'}
                                                </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={(e) => { this.AddNewEmbedLink(e) }}>
                                                    {'Insert'}
                                                </Button>
                                            </div>
                                        </div>

                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                {/* View Media Modal */}
                <Modal centered isOpen={this.state.viewMyPhotosModal} toggle={() => this.setState({ viewMyPhotosModal: !this.state.viewMyPhotosModal })}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            sliderClass={`${imageAttacment?.length === 0}` ? "mx-auto" : ""}>
                            {imageAttacment.length > 1 ? imageAttacment.map((med, i) => {
                                return (
                                    <div className="item fs-14 h-100 d-flex flex-column justify-content-center" key={i}>
                                        <div>
                                            <div className="d-flex text-white justify-content-between mt-3">
                                                <div className="mr-2 ff-alt">
                                                    {med?.caption}
                                                </div>
                                                <div className="ff-base">
                                                    {med?.label}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                            {(med?.type === 'image' || med?.type === '') && (
                                                <img className="img-fluid shown-media" src={med?.url} alt="" />
                                            )}
                                            {med?.type === 'video' && (
                                                <video className="img-fluid shown-media" controls>
                                                    <source src={med?.url} type="video/mp4" />
                                                 Your browser does not support the video tag.
                                                </video>
                                            )}
                                            {/* <div>
                                                {i+1} of {secondCarouselMedia.length}
                                            </div> */}
                                        </div>
                                        <div className="px-2 mt-3">
                                            <div className="d-flex flex-nowrap mx-n2">
                                                <div className="px-2 col d-flex flex-nowrap">
                                                    <div className="mr-2">
                                                        <img className="img-circle _30x30"
                                                            src={med?.user?.current_profile_pic ? med?.user?.current_profile_pic : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt=""
                                                            onError={(error) => (error.target.src = require("../../../assets/images/icons/user-circle.png"))} />
                                                    </div>
                                                    <div>
                                                        <div className="text-primary ff-base">
                                                            Uploaded by&nbsp;<a className="text-reset" href="#"><span className="font-weight-bold">{med?.user?.user?.first_name}</span> </a>
                                                        </div>
                                                        <div className="text-tertiary ff-base">
                                                            {med?.upload_date}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-2 col-auto">
                                                    <h2 className="fs-38" style={{ color: '#d4dae6' }}>{i + 1} of {imageAttacment.length}</h2>
                                                </div>
                                                <div className="px-2 col">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : (
                                    <div className="item px-4">
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                                <span>No result found for this category</span>
                                                {/* <div className="mt-2">
                                                    <Button color="link" className="text-tertiary font-weight-bold">Back</Button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </Carousel>
                    </ModalBody>
                </Modal>


                {/* See who reacted Modal */}
                <Modal size="lg" isOpen={this.state.viewPeopleWhoReactedModalToggle} toggle={() => this.setState({ viewPeopleWhoReactedModalToggle: !this.state.viewPeopleWhoReactedModalToggle })}>
                    <ModalHeader toggle={() => this.setState({ viewPeopleWhoReactedModalToggle: !this.state.viewPeopleWhoReactedModalToggle })}></ModalHeader>
                    <ModalBody>
                        <Nav className="nav-fill active-transparent" tabs>
                            <NavItem>
                                <NavLink
                                    active={this.state.viewReactedType === "all"}
                                    onClick={() => {
                                        this.handleOnClickReacted("all");
                                    }}
                                >
                                    <div className="ff-base">
                                        All&nbsp;<span>{reviewAll}</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={this.state.viewReactedType === "woohoo"}
                                    onClick={() => {
                                        this.handleOnClickReacted("woohoo");
                                    }}
                                >
                                    <div className="ff-base">
                                        Woohoo&nbsp;<span>{review_group_count &&
                                            Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.woohoo ? review_group_count.review_group_count.woohoo : 0}</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={this.state.viewReactedType === "nailed"}
                                    onClick={() => {
                                        this.handleOnClickReacted("nailed");
                                    }}
                                >
                                    <div className="ff-base">
                                        Nailed It&nbsp;<span>{review_group_count &&
                                            Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.nailed ? review_group_count.review_group_count.nailed : 0}</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={this.state.viewReactedType === "insightful"}
                                    onClick={() => {
                                        this.handleOnClickReacted("insightful");
                                    }}
                                >
                                    <div className="ff-base">
                                        Insightful&nbsp;<span>{review_group_count &&
                                            Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.insightful ? review_group_count.review_group_count.insightful : 0}</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={this.state.viewReactedType === "lol"}
                                    onClick={() => {
                                        this.handleOnClickReacted("lol");
                                    }}
                                >
                                    <div className="ff-base">
                                        LOL&nbsp;<span>{review_group_count &&
                                            Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.lol ? review_group_count.review_group_count.lol : 0}</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    active={this.state.viewReactedType === "bummer"}
                                    onClick={() => {
                                        this.handleOnClickReacted("bummer");
                                    }}
                                >
                                    <div className="ff-base">
                                        Bummer&nbsp;<span>{review_group_count &&
                                            Object.keys(review_group_count).length > 0 && review_group_count.review_group_count.bummer ? review_group_count.review_group_count.bummer : 0}</span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.viewReactedType}>
                            <TabPane tabId="all">
                                <ul className="list-unstyled d-flex flex-column">
                                    {this.fetchReviewGroupList("all")}
                                </ul>
                            </TabPane>
                            <TabPane tabId="woohoo">
                                <ul className="list-unstyled d-flex flex-column">
                                    {this.fetchReviewGroupList("woohoo")}
                                </ul>
                            </TabPane>
                            <TabPane tabId="nailed">
                                <ul className="list-unstyled d-flex flex-column">
                                    {this.fetchReviewGroupList("nailed")}
                                </ul>
                            </TabPane>
                            <TabPane tabId="insightful">
                                <ul className="list-unstyled d-flex flex-column">
                                    {this.fetchReviewGroupList("insightful")}
                                </ul>
                            </TabPane>
                            <TabPane tabId="lol">
                                <ul className="list-unstyled d-flex flex-column">
                                    {this.fetchReviewGroupList("lol")}
                                </ul>
                            </TabPane>
                            <TabPane tabId="bummer">
                                <ul className="list-unstyled d-flex flex-column">
                                    {this.fetchReviewGroupList("bummer")}
                                </ul>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                </Modal>

                {/* Delete Confirmation Modal */}
                <Modal
                    size="sm"
                    isOpen={this.state.confirmDeleteModal}
                    toggle={this.confirmDeleteModalToggle}
                    className="text-center"
                    modalClassName="backdrop-opaque top-shifted transition-none"
                >
                    <div className="bg-white modal-header">
                        <h2 className="modal-title mx-auto text-dark fs-38">Confirmation</h2>
                        <button
                            className="btn btn-sm fs-18"
                            aria-label="Close"
                            onClick={this.confirmDeleteModalToggle}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark fs-14">
                        Are you sure you want to delete this {this.state.deleteEntity ? this.state.deleteEntity : ''}?
          </ModalBody>
                    <ModalFooter className="bg-white">
                        <div className="text-center w-100">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={this.confirmDeleteModalToggle}
                            >
                                Cancel
              </Button>
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={this.handleOnDeleteConfirmation}
                            >
                                Yes
              </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Share By Email Modal Popup */}
                <ModalComponent
                    size="sm"
                    isScrollable={true}
                    isOpen={this.state.shareByEmailModalToggle}
                    toggleHandler={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })}
                    header={"Share by Email"}
                    headerClass="text-uppercase px-0"
                    body={<ShareByEmail handleOnSubmitShareByEmail={(value) => this.handleOnSubmitShareByEmail(value)} modalToggler={() => {
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })
                    }} />}
                /> 

                {/* SEE HOW REVIEW DISPUTES WORK */}
                <Modal
                    isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
                    toggle={() =>
                        this.setState({
                            watchHowDisputeReviewWorksModalToggle: !this.state.watchHowDisputeReviewWorksModalToggle
                        })
                    }
                >
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() =>
                            this.setState({
                                watchHowDisputeReviewWorksModalToggle: !this.state.watchHowDisputeReviewWorksModalToggle,
                            })
                        }
                    >
                        SEE HOW REVIEW DISPUTES WORK
          </ModalHeader>
                    <ModalBody className="p-0 bg-transparent">
                        <div className="text-center">
                            <video controls autoPlay width="100%">
                                <source src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                        </div>
                    </ModalBody>
                </Modal>

                {/* Discussion and reply against dispute by community user */}
                <Modal
                    size="xl"
                    // scrollable
                    className="drawer-modal"
                    isOpen={this.state.voteReviewModalToggleFour}
                    // isOpen = {true}
                    toggle={() =>
                        this.setState({
                            voteReviewModalToggleFour: !this.state.voteReviewModalToggleFour,
                        })
                    }
                >
                    <form className="d-flex flex-column h-100">
                        <div className="modal-header flex-wrap p-0">
                            {/* Dispute review intro design */}
                            <div className="row mb-5 form flex-grow-1">
                                <div className="col-lg-4">
                                    <div className="bg-dark text-white text-center h-100 p-3">
                                        <div className="fs-24 ff-headings text-uppercase">
                                            Please watch the video to see how Review Disputes work
                      </div>

                                        <div className="mt-3">
                                            <Row className="justify-content-between align-items-center">
                                                <Col xs="12" className="d-flex justify-content-center align-items-center">
                                                    <div className="text-center d-flex align-items-center justify-content-center">
                                                        <div>
                                                            <img
                                                                className="img-fluid"
                                                                src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="video-holder play-icon d-inline-block mx-2"
                                                        role="button"
                                                        onClick={() =>
                                                            this.setState({
                                                                watchHowDisputeReviewWorksModalToggle: true
                                                            })
                                                        } >
                                                        <img height="80" src={require("../../../assets/images/dispute_jury.webp")} alt="" />
                                                    </div>
                                                    <div className="text-center d-flex align-items-center justify-content-center">
                                                        <div>
                                                            <img
                                                                className="img-fluid"
                                                                src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* <Col xs="12">
                            <div className="text-center d-flex align-items-center justify-content-center">
                              <div>
                                <img
                                  className="img-fluid"
                                  src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                  alt=""
                                />
                              </div>
                              <Button className="mx-3" color="primary text-nowrap"
                                onClick={() =>
                                  this.setState({
                                    watchHowDisputeReviewWorksModalToggle: true
                                  })
                                }>
                                Click Here
                                  </Button>
                              <div>
                                <img
                                  className="img-fluid"
                                  src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                  alt=""
                                />
                              </div>
                            </div>
                          </Col> */}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 ml-auto">
                                    <div className="bg-warning text-dark p-2 fs-14">
                                        <div className="d-flex flex-wrap mx-n2">
                                            <div className="text-white px-2">
                                                <span className="font-weight-normal">You're voting this
                        <span className="font-weight-bold">Disputed Review</span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white p-3 fs-14 text-dark">
                                        <div className="d-flex mx-n2 mb-2">
                                            <div className="px-2">
                                                <a href="#" className="text-decoration-none">
                                                    <img className="img-circle _50x50"
                                                        src={
                                                            this.state.reviewAdded.created_user_media &&
                                                                this.state.reviewAdded.created_user_media.url
                                                                ? this.state.reviewAdded.created_user_media.url
                                                                : require("../../../assets/images/icons/user-circle.png")
                                                        }
                                                        alt={this.state.reviewAdded.created_user_media &&
                                                            `${this.state.reviewAdded.created_user_media.user.user.first_name} ${this.state.reviewAdded.created_user_media.user.user.last_name}`} />

                                                </a>
                                            </div>
                                            <div className="px-2 flex-grow-1">
                                                <div className="px-2 flex-grow-1">
                                                    <div className="ff-alt">
                                                        <a
                                                            href={`/people/${this.state.reviewAddedUser}`}
                                                            className="text-reset ff-base font-weight-bold"
                                                        >
                                                            {this.state.reviewAdded.created_user_media &&
                                                                `${this.state.reviewAdded.created_user_media.user.user.first_name} ${this.state.reviewAdded.created_user_media.user.user.last_name}`}
                                                        </a>
                                                        <span> wrote a this review </span>
                                                        <br />
                                                        <img className="mr-3" alt="" />
                                                        <span> {this.state.reviewAdded.review_date_time}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                       
                                        <div className="ff-alt my-4" hidden={checkTextVisibilty(this.state.reviewAdded.review)}>
                                            <i className="icon-comma-left"></i>
                                            <span className="comment-body-renderer">{ReactHtmlParser(detectlink(this.state.reviewAdded.review))}</span>
                                            <i className="icon-comma-right" ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100">
                                <div>
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            this.setState({
                                                voteReviewModalToggleFour: !this.state
                                                    .voteReviewModalToggleFour,
                                            })
                                        }
                                    >
                                        <FontAwesomeIcon className="mr-2" icon="angle-left" />
                    back to listing
                  </Button>
                                </div>
                            </div>
                        </div>
                        <ModalBody className="p-0 fs-14 text-dark bg-transparent">
                            <div className="bg-dark text-white">
                                <Row noGutters>
                                    <Col xs={12} lg="auto">
                                        <div
                                            className="d-flex flex-column justify-content-center bg-tertiary text-white text-center px-5 py-4 h-100"
                                        >
                                            <div>
                                                <img
                                                    src={require("../../../assets/images/icons/star/blue/fill.png")}
                                                    alt=""
                                                />
                                                <h2 className="my-2 fs-50 text-shadow">
                                                    REVIEW DISPUTE
                        </h2>
                                                <img
                                                    src={require("../../../assets/images/icons/star/blue/fill.png")}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="col-lg">
                                        <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                                            <Row>
                                                <Col>
                                                    <div className="pr-md-5">
                                                        {get_dispute_data && get_dispute_data[0] &&
                                                            get_dispute_data[0].is_business_owner ?
                                                            <h3 className="text-light">
                                                                Business Owner Initiated.
                                </h3>
                                                            :
                                                            <h3 className="text-light">
                                                                Community Initiated.
                                </h3>
                                                        }
                                                        <div className="fs-14">
                                                            <div className="text-primary fs-16 font-weight-bold">
                                                                Step 1. Lorem Ipsum.
                              </div>
                                                            <p className="mb-0">
                                                                Brief description of why you vote your counts
                                                                and what you'd be voting on. Lorem ipsum, dolor
                                                                sit amet consectetur adipisicing elit.
                                                                Dignissimos, non?
                              </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* { new Date(get_dispute_data[0] && get_dispute_data[0].voting_open_till) < new Date() 
                        ?  */}
                                                {this.state.can_vote ?
                                                    <Col md="auto" className="align-self-end">
                                                        <div className="text-light font-weight-bold">
                                                            Submission time ends in
                          </div>
                                                        <div className="d-flex mx-n2 flex-nowrap">
                                                            <div className="px-2">
                                                                <div className="text-white">
                                                                    <div className="fs-32 ff-headings">{this.state.days}</div>
                                                                    <div className="font-weight-bold">days</div>
                                                                </div>
                                                            </div>
                                                            <div className="px-2">
                                                                <div className="text-muted">
                                                                    <div className="fs-32 ff-headings text-right">
                                                                        {this.state.hours}
                                                                    </div>
                                                                    <div className="font-weight-normal">hours</div>
                                                                </div>
                                                            </div>
                                                            <div className="px-2">
                                                                <div className="text-muted">
                                                                    <div className="fs-32 ff-headings text-right">
                                                                        {this.state.minutes}
                                                                    </div>
                                                                    <div className="font-weight-normal">
                                                                        minutes
                                </div>
                                                                </div>
                                                            </div>
                                                            <div className="px-2">
                                                                <div className="text-muted">
                                                                    <div className="fs-32 ff-headings text-right">
                                                                        {this.state.seconds}
                                                                    </div>
                                                                    <div className="font-weight-normal">
                                                                        seconds
                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    :
                                                    <Col md="auto" className="align-self-end">
                                                        <div className="text-light font-weight-bold">
                                                            Submission time ENDED!
                          </div>
                                                        <div className="d-flex mx-n2 flex-nowrap">
                                                            <div className="px-2">
                                                                <div className="text-muted">
                                                                    <div className="fs-32 ff-headings">00</div>
                                                                    <div className="font-weight-normal">days</div>
                                                                </div>
                                                            </div>
                                                            <div className="px-2">
                                                                <div className="text-muted">
                                                                    <div className="fs-32 ff-headings text-right">
                                                                        00
                                </div>
                                                                    <div className="font-weight-normal">hours</div>
                                                                </div>
                                                            </div>
                                                            <div className="px-2">
                                                                <div className="text-muted">
                                                                    <div className="fs-32 ff-headings text-right">
                                                                        00
                                </div>
                                                                    <div className="font-weight-normal">
                                                                        minutes
                                </div>
                                                                </div>
                                                            </div>
                                                            <div className="px-2">
                                                                <div className="text-muted">
                                                                    <div className="fs-32 ff-headings text-right">
                                                                        00
                                </div>
                                                                    <div className="font-weight-normal">
                                                                        seconds
                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                }


                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="body-bg px-3 py-3">
                                <Row>
                                    <Col>
                                        In case you don't know the correct address, try looking for
                                        it with our search bar.
                  </Col>
                                    <Col xs="auto">
                                        <div className="d-flex">
                                            <Input
                                                className="primary"
                                                bsSize="sm"
                                                type="search"
                                                placeholder="Custom Search"
                                                style={{ minWidth: "300px" }}
                                            ></Input>
                                            <Button color="primary" size="sm" className="ml-3">
                                                <FontAwesomeIcon icon="search" />
                                            </Button>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                            <div className="bg-white px-3 py-4">
                                <Row>
                                    <Col md={4}>
                                        <div className="font-weight-bold fs-16">
                                            Dispute History
                    </div>
                                        <ul className="d-flex flex-column list-unstyled">
                                            <li className="py-2 border-bottom">
                                                <div className="d-flex mx-n1">
                                                    <div className="px-1">
                                                        <div className="ff-alt">
                                                            <Moment format="DD/MM/YYYY">
                                                                {get_dispute_data &&
                                                                    get_dispute_data[0] &&
                                                                    get_dispute_data[0].created_on}
                                                            </Moment>
                                                        </div>
                                                    </div>
                                                    <div className="px-1">
                                                        <a
                                                            href="#"
                                                            className="text-dark ff-base font-weight-bold"
                                                        >
                                                            {get_dispute_data &&
                                                                get_dispute_data[0] &&
                                                                get_dispute_data[0].user_name}
                                                        </a>
                            &nbsp; disputed as " {get_dispute_data && get_dispute_data[0] &&
                                                            get_dispute_data[0].flag_name} "
                          </div>
                                                </div>
                                            </li>

                                            {/* repeat this for dispute discussion */}
                                            {get_dispute_data && get_dispute_data.map((discussion) => (

                                                <li className="py-2 border-bottom">
                                                    { discussion.child_key_name === 1 ||
                                                        discussion.child_key_name === '1'
                                                        ?
                                                        <div className="d-flex mx-n1">
                                                            <div className="px-1">
                                                                <div className="ff-alt">
                                                                    <Moment format="DD/MM/YYYY">
                                                                        {discussion &&
                                                                            discussion.created_on}
                                                                    </Moment>
                                                                </div>
                                                            </div>
                                                            <div className="px-1">
                                                                <a
                                                                    href="#"
                                                                    className="text-dark ff-base font-weight-bold"
                                                                >
                                                                    {discussion &&
                                                                        discussion.user_name}
                                                                </a>
                            &nbsp; reverted edits by{" "}
                                                                {discussion &&
                                                                    discussion.content_flagged}
                                  "
                          </div>
                                                        </div>
                                                        :
                                                        ''
                                                    }

                                                </li>
                                            ))}


                                        </ul>
                                    </Col>
                                    <Col md={8}>
                                        <div className="mb-3">
                                            <div className="text-primary fs-16 font-weight-bold mb-2">
                                                Step 2. Some Copy Line here
                      </div>
                                            <div>
                                                <div className="bg-light p-2 text-dark mb-2">
                                                    <div className="text-tertiary">
                                                        Flagged for
                            <strong> " {get_dispute_data &&
                                                            get_dispute_data[0] &&
                                                            get_dispute_data[0].flag_name} " </strong>
                                                    </div>
                                                    <hr className="my-2" />
                                                    <div>
                                                        <div className="d-flex mx-n2">
                                                            <div className="px-2">
                                                                <img
                                                                    className="img-circle _50x50"
                                                                    src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="px-2">
                                                                <div className="mb-2">
                                                                    by{" "}
                                                                    <a href="#" className="text-reset">
                                                                        <strong>{get_dispute_data &&
                                                                            get_dispute_data[0] &&
                                                                            get_dispute_data[0].user_name}</strong>
                                                                    </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                                                        Created on&nbsp;
                                    <span className="text-reset">
                                                                            {/* {discussion.created_on} */}
                                                                            <Moment format="MMM DD,YYYY">
                                                                                {get_dispute_data && get_dispute_data[0] &&
                                                                                    get_dispute_data[0].created_on}
                                                                            </Moment>
                                      &nbsp; , &nbsp;
                                      <Moment format="HH:MM">
                                                                                {get_dispute_data && get_dispute_data[0] && get_dispute_data[0].created_on}
                                                                            </Moment>
                                                                        </span>

                                                                    </span>
                                                                    {(this.props.my_profile &&
                                                                        this.props.my_profile.user &&
                                                                        this.props.my_profile.user.email) ===
                                                                        (get_dispute_data &&
                                                                            get_dispute_data[0] &&
                                                                            get_dispute_data[0].user_details &&
                                                                            get_dispute_data[0].user_details.email) ? (
                                                                            <DeleteBtn
                                                                                onClick={() => this.handleDeleteDispute()}
                                                                            />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                </div>
                                                                <div>
                                                                    " {get_dispute_data &&
                                                                        get_dispute_data[0] &&
                                                                        get_dispute_data[0].content_flagged} "
                                </div>
                                                                <div className="bg-light p-3">
                                                                    <div className="mb-2">

                                                                        {get_dispute_data &&
                                                                            get_dispute_data[0] &&
                                                                            get_dispute_data[0].media.map((media) =>
                                                                                <div>
                                                                                    {media.includes('.jpeg') || media.includes('.jpg') || media.includes('.png')
                                                                                        ?
                                                                                        <div className="mb-2">
                                                                                            <img className="img-fluid" src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} alt="" />
                                                                                        </div>
                                                                                        :
                                                                                        <div className="mb-2 d-inline-block">
                                                                                            <div className="d-flex mx-n2">
                                                                                                <div className="px-2">
                                                                                                    <FontAwesomeIcon icon="paperclip" fixedWidth />
                                                                                                </div>
                                                                                                <div className="px-2 col">
                                                                                                    <span className="font-weight-bold text-truncate">
                                                                                                        <a
                                                                                                            className="text-reset font-weight-bold"
                                                                                                            href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                                                            rel="noopener noreferrer"
                                                                                                            target="_blank">{media.toString().split('/')[2]}</a>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div className="px-2 ml-auto">
                                                                                                    <span className="mr-2">|</span>
                                                                                                    <a
                                                                                                        href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                                                        rel="noopener noreferrer"
                                                                                                        target="_blank"
                                                                                                        className="text-reset">
                                                                                                        <FontAwesomeIcon icon="external-link-alt" />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="bg-light p-2 text-dark mb-2">
                          <div className="text-tertiary">
                            Flagged for{" "}
                            <strong>Another Reason Something</strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>NameNameName</strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago in Chicago, IL
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-light p-2 text-dark mb-2"> 
                          <div className="text-tertiary">
                            Flagged for{" "}
                            <strong>Yet Another Reason Something</strong>
                          </div>
                          <hr className="my-2" />
                          <div>
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _50x50"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-2">
                                  by{" "}
                                  <a href="#" className="text-reset">
                                    <strong>Messi10</strong>
                                  </a>
                                  &nbsp;|&nbsp;
                                  <span className="fs-12 text-muted">
                                    one hour ago via Android
                                  </span>
                                </div>
                                <div>
                                  "Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Aut nobis officia odit
                                  aspernatur obcaecati, dolorum quasi
                                  voluptatibus porro ullam! Perferendis libero
                                  atque perspiciatis voluptatibus quia similique
                                  iste recusandae sequi enim cum, nam quis est
                                  minus corporis sit porro aliquid at."
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>*/}
                                            </div>
                                            {get_dispute_data && get_dispute_data.length > 1 ?
                                                <div className="text-tertiary fs-16 font-weight-bold mt-4">
                                                    Discussion between parties
                        </div>
                                                :
                                                ''
                                            }
                                            <hr className="mt-2" />
                                            {/* <div className="text-center mb-2">
                        <div className="text-dark">Dec 16, 2013</div>
                      </div> */}
                                            <div>
                                                {get_dispute_data && get_dispute_data.length > 1 ?
                                                    <div className="p-2 text-dark">
                                                        {get_dispute_data && get_dispute_data.map((discussion) => (
                                                            <div className="mb-3">
                                                                { discussion.child_key_name === 1 ||
                                                                    discussion.child_key_name === '1'
                                                                    ?
                                                                    <div className="d-flex mx-n2">
                                                                        <div className="px-2">
                                                                            <img
                                                                                className="img-circle _30x30"
                                                                                src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="px-2 col">
                                                                            <div className="mb-3">
                                                                                <div className="d-flex mx-n2 mb-1">
                                                                                    <div className="px-2 col">
                                                                                        <a href="#" className="text-reset">
                                                                                            <strong>{discussion.user_name}</strong>
                                                                                        </a>
                                      &nbsp;
                                      {/* <span className="fs-12 text-muted">
                                        Business Owner
                                      </span> */}
                                                                                    </div>
                                                                                    <div className="col-auto">
                                                                                        <div className="text-tertiary d-inline-block mr-2">
                                                                                            <span className="text-reset">
                                                                                                <Moment format="MMM DD,YYYY">
                                                                                                    {discussion.created_on}
                                                                                                </Moment>
                                          &nbsp; , &nbsp;
                                          <Moment format="HH:MM">
                                                                                                    {discussion.created_on}
                                                                                                </Moment>
                                                                                            </span>
                                                                                        </div>
                                                                                        {
                                                                                            (this.props.my_profile_info &&
                                                                                                this.props.my_profile_info.user &&
                                                                                                this.props.my_profile_info.user.email) ===
                                                                                                (discussion &&
                                                                                                    discussion.user_details &&
                                                                                                    discussion.user_details.email)
                                                                                                ?
                                                                                                <DeleteBtn onClick={() => this.handleDeleteReply(discussion.pk)} />
                                                                                                :
                                                                                                ''
                                                                                        }
                                                                                        {/* <DeleteBtn onClick={() => this.handleDeleteReply(discussion.pk)} /> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    {discussion.content_flagged}
                                                                                </div>
                                                                            </div>
                                                                            <div className="bg-light p-3">
                                                                                <div className="mb-2">
                                                                                    {/* <div className="mb-2">
                                          <a
                                            href="#"
                                            className="text-dark font-weight-bold"
                                          >
                                            www.website.com
                                          </a>
                                        </div>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. */}
                                                                                    {discussion && discussion.media.map((media) =>
                                                                                        <div>
                                                                                            {media.includes('.jpeg') || media.includes('.jpg') || media.includes('.png')
                                                                                                ?
                                                                                                <div className="mb-2">
                                                                                                    <img className="img-fluid" src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`} alt="" />
                                                                                                </div>
                                                                                                :
                                                                                                <div className="mb-2 d-inline-block">
                                                                                                    <div className="d-flex mx-n2">
                                                                                                        <div className="px-2">
                                                                                                            <FontAwesomeIcon icon="paperclip" fixedWidth />
                                                                                                        </div>
                                                                                                        <div className="px-2 col">
                                                                                                            <span className="font-weight-bold text-truncate">
                                                                                                                <a className="text-reset font-weight-bold" href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                                                                    rel="noopener noreferrer" target="_blank">{media.toString().split('/')[2]}</a>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="px-2 ml-auto">
                                                                                                            <span className="mr-2">|</span>
                                                                                                            <a href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                                                                rel="noopener noreferrer" target="_blank" className="text-reset">
                                                                                                                <FontAwesomeIcon icon="external-link-alt" />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    ''
                                                                }
                                                            </div>
                                                        ))}
                                                        {/* <div className="mb-2">
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _30x30"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-3">
                                  <div className="d-flex mx-n2 mb-1">
                                    <div className="px-2 col">
                                      <a href="#" className="text-reset">
                                        <strong>User Name</strong>
                                      </a>
                                      &nbsp;
                                      <span className="fs-12 text-muted">
                                        Expert Reviewer
                                      </span>
                                    </div>
                                    <div className="col-auto">
                                      <div className="text-tertiary">
                                        Dec 16, 2013, 15:50
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Voluptate, incidunt iste,
                                    obcaecati nostrum reprehenderit veniam et
                                    saepe reiciendis voluptatibus tenetur a.
                                    Porro debitis consectetur iste saepe ipsam
                                    libero reprehenderit sed!
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-2">
                            <div className="d-flex mx-n2">
                              <div className="px-2">
                                <img
                                  className="img-circle _30x30"
                                  src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                  alt=""
                                />
                              </div>
                              <div className="px-2">
                                <div className="mb-3">
                                  <div className="d-flex mx-n2 mb-1">
                                    <div className="px-2 col">
                                      <a href="#" className="text-reset">
                                        <strong>User Name</strong>
                                      </a>
                                      &nbsp;
                                      <span className="fs-12 text-muted">
                                        Business Owner
                                      </span>
                                    </div>
                                    <div className="col-auto">
                                      <div className="text-tertiary">
                                        Dec 16, 2013, 16:01
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Dolorum, esse perferendis?
                                    Ipsam tempore minus fugiat.
                                  </div>
                                  <div className="mt-3">
                                    <img
                                      className="img-fluid"
                                      src="https://source.unsplash.com/random/400x300"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        */}
                                                    </div>
                                                    :
                                                    ''
                                                }
                                                <hr />
                                                {this.state.can_vote ?
                                                    <div>
                                                        <div className="attachable-container">
                                                            <Input
                                                                type="textarea"
                                                                className="primary"
                                                                bsSize="sm"
                                                                rows="3"
                                                                onChange={this.handleReplyChange}
                                                                value={dispute_reply_text}
                                                                placeholder="Provide explanation..."
                                                            />
                                                            <div className="attachable-area">
                                                                <hr className="bg-primary mt-0 mb-2" />
                                                                <div className="d-flex mx-n1">
                                                                    <div className="px-1">
                                                                        {/* <Input
                                    type="file"
                                    id="attach_files_modal"
                                    multiple
                                    style={{ display: "none" }}
                                  />
                                  <Label
                                    for="attach_files_modal"
                                    className="btn btn-sm btn-transparent"
                                    title="Attach Files"
                                  >
                                    <FontAwesomeIcon
                                      icon="paperclip"
                                      fixedWidth
                                    />
                                  </Label> */}
                                                                        <input type="file" name="imageUpload"
                                                                            id="imageUpload"
                                                                            style={{ 'display': 'None' }}
                                                                            onChange={this.handleImageChange}
                                                                            multiple

                                                                        />
                                                                        <label for="imageUpload"
                                                                            title="Upload"
                                                                            className="text-primary"
                                                                            role="button"
                                                                        >
                                                                            <FontAwesomeIcon icon="camera" />
                                                                        </label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Attach file types documents */}
                                                        <div className="mt-3">

                                                            <Row xs={2} sm={3} md={4} form>
                                                                {this.state.dispute_imgepreview.map((image, index) => (
                                                                    <Col className="mb-3">
                                                                        <div className="d-flex pr-3 pt-3" role="button" onClick={() => this.setState({ viewMyPhotosModal: true })}>
                                                                            <div className="flex-grow-1 mw-100">
                                                                                {image.toString().includes('data:image')
                                                                                    ?

                                                                                    <img
                                                                                        className="img-fluid img-thumbnail"
                                                                                        src={image}
                                                                                        alt=""
                                                                                        title="Image Name"
                                                                                    />
                                                                                    :
                                                                                    image.toString().includes("data:video") ? (
                                                                                        <div
                                                                                            className="bg-light p-2 text-truncate border fs-13"
                                                                                            title={
                                                                                                this.state.dispute_files &&
                                                                                                this.state.dispute_files[index] &&
                                                                                                this.state.dispute_files[index].name
                                                                                            }
                                                                                        >
                                                                                            <img
                                                                                                width={24}
                                                                                                className="mr-2"
                                                                                                src={
                                                                                                    "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/videoicon.jpg"
                                                                                                }
                                                                                                alt="Icon"
                                                                                            />{" "}
                                                                                            {this.state.dispute_files &&
                                                                                                this.state.dispute_files[index] &&
                                                                                                this.state.dispute_files[index].name}
                                                                                        </div>
                                                                                    )

                                                                                        :
                                                                                        (<div
                                                                                            className="bg-light p-2 text-truncate border fs-13"
                                                                                            title={
                                                                                                this.state.dispute_files &&
                                                                                                this.state.dispute_files[index] &&
                                                                                                this.state.dispute_files[index].name
                                                                                            }
                                                                                        >
                                                                                            <img
                                                                                                width={24}
                                                                                                className="mr-2"
                                                                                                src={
                                                                                                    "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/file_icon.png"
                                                                                                }
                                                                                                alt="Icon"
                                                                                            />{" "}
                                                                                            {this.state.dispute_files &&
                                                                                                this.state.dispute_files[index] &&
                                                                                                this.state.dispute_files[index].name}
                                                                                        </div>
                                                                                        )}
                                                                            </div>
                                                                            <div className="mx-n3 mt-n3">
                                                                                <Button color="dark"
                                                                                    size="sm"
                                                                                    title="Remove Media"
                                                                                    onClick={() => this.deleteImage(index)}
                                                                                >
                                                                                    <FontAwesomeIcon icon="minus" size="sm" />
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                ))}
                                                            </Row>

                                                        </div>

                                                        {/* Uploaded file type images/videos */}
                                                        {/* <div className="mt-3">
                            <div className="fs-14 font-weight-bold mb-2">
                              Uploaded Files
                            </div>
                            <Row form>
                              <Col xs="auto" className="mb-3">
                                <div className="d-flex pr-3 pt-3">
                                  <div>
                                    <div className="gallery-media">
                                      <img
                                        src={require("../../../assets/images/cvs-logo.jpg")}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-n3">
                                    <Button
                                      color="dark"
                                      size="sm"
                                      title="Remove File"
                                    >
                                      <FontAwesomeIcon icon="minus" />{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                              <Col xs="auto" className="mb-3">
                                <div className="d-flex pr-3 pt-3">
                                  <div>
                                    <div className="gallery-media">
                                      <img
                                        src={require("../../../assets/images/cvs-logo.jpg")}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-n3">
                                    <Button
                                      color="dark"
                                      size="sm"
                                      title="Remove File"
                                    >
                                      <FontAwesomeIcon icon="minus" />{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                              <Col xs="auto" className="mb-3">
                                <div className="d-flex pr-3 pt-3">
                                  <div>
                                    <div className="gallery-media">
                                      <img
                                        src={require("../../../assets/images/cvs-logo.jpg")}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="mx-n3 mt-n3">
                                    <Button
                                      color="dark"
                                      size="sm"
                                      title="Remove File"
                                    >
                                      <FontAwesomeIcon icon="minus" />{" "}
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                         */}
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                        <ModalFooter className="bg-white">
                            <Button color="primary" onClick={() => this.handleDisputeReply(get_dispute_data)}>Post</Button>
                        </ModalFooter>
                    </form>
                </Modal>

            </div>
        )
    }
}

const mapState = (state) => ({
    reviews: state.reviews.review_entry,
    album_types_list: state.user.album_types_list,
    album_type_data: state.user.album_type_data,
    channels: state.reviews.broadcast_channel,
    my_profile_info: state.user.my_profile,
    get_reveiewData: state.user.get_reveiewData,
    fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
    new_sub_categories: state.business.new_sub_categories,
    fetch_data_categories: state.business.fetch_data_categories,
    filter_data_category: state.business.filter_data_category,
    get_dispute_data: state.feeds.get_dispute_data,
    all_disputed_reviews: state.dispute.all_disputed_reviews,
    get_dispute_modal_status: state.dispute.get_dispute_modal_status,
    profile_dispute_count: state.dispute.profile_dispute_count,
    isEditedReviewId: state.shared.isEditedReviewId,
    isEditReviewEnabled: state.shared.isEditReviewEnabled,
    newReviewMediaSet: state.reviewMedia.newReviewMediaSet,     
    newReviewEmbedLinkSetFinalized: state.reviewMedia.newReviewEmbedLinkSetFinalized,
    followReviewMediaSet: state.reviewMedia.followReviewMediaSet,     
    followReviewEmbedLinkSetFinalized: state.reviewMedia.followReviewEmbedLinkSetFinalized,
    editReviewMediaSetFinalized:state.reviewMedia.editReviewMediaSetFinalized,
    isFollowReviewEnable:state.shared.isFollowReviewEnable,
    cat_taxonomy_list: state.item_detail.cat_taxonomy_list,
});

const mapProps = (dispatch) => ({
    get_broadcast_channel: () => dispatch(get_broadcast_channel()),
    get_album_types_list: () => dispatch(get_album_types_list()),
    get_album_type_data: (type, album, page) => dispatch(get_album_type_data(type, album, page)),
    delete_selected_gallery_media: (dataID) => dispatch(delete_selected_gallery_media(dataID)),
    add_vote: (data) => dispatch(add_vote(data)),
    edit_review: (id, data, profileID, type, item) => dispatch(edit_review(id, data, profileID, type,item)),
    delete_my_review: (reviewId, profileID, type,viewReviewsType) => dispatch(delete_my_review(reviewId, profileID, type,viewReviewsType)),
    review_entry: ({ entry, user, asset, page, review, review_type, on_search_filter, }) => dispatch(review_entry({ entry, user, asset, page, review, review_type, on_search_filter, })),
    get_reviewgroup: (assetType, reviewId, type) => dispatch(get_reviewgroup(assetType, reviewId, type)),
    fetch_taxonomy_list: (category, type) => dispatch(fetch_taxonomy_list(category, type)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    fetch_sub_categories: ({ sub_cat, level }) => dispatch(fetch_sub_categories({ sub_cat, level })),
    fetch_categories_data: (cat, sub_cat, type, index) => dispatch(fetch_categories_data(cat, sub_cat, type, index)),
    filter_categories_data: ({ category, sub_cat, level }) => dispatch(filter_categories_data({ category, sub_cat, level })),
    clear_category: () => dispatch(clear_category()),
    clear_subcategory: () => dispatch(clear_subcategory()),
    save_ratings_vote: (data) => dispatch(save_ratings_vote(data)),
    remove_ratings_vote: (data) => dispatch(remove_ratings_vote(data)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    publish_post: (data) => dispatch(publish_post(data)),
    MyProfilepublish_post: (data,name) => dispatch(MyProfilepublish_post(data,name)),
    get_dispute_discussion: (data) => dispatch(get_dispute_discussion(data)),
    add_dispute_reply: (data) => dispatch(add_dispute_reply(data)),
    delete_dispute_reply: (data,id) => dispatch(delete_dispute_reply(data,id)),
	add_my_post: (data, type, page) => dispatch(add_my_post(data, type, page)),
	embedNewLink: ({ data }) => (dispatch(embedNewLink({ data }))),
	post_review_group_myprofile: (id, data,profileID, categoryType,viewReviewsType) => dispatch(post_review_group_myprofile(id, data,profileID,categoryType,viewReviewsType)),
    add_review_group: (data, categorySection) => dispatch(add_review_group(data, categorySection)), 
    get_all_disputed_reviews: (data) => dispatch(get_all_disputed_reviews(data)), 
    get_profile_dispute_count: () => dispatch(get_profile_dispute_count()),
    uploadMediaInReviewMediaSetForNewReview: (data) => dispatch(uploadMediaInReviewMediaSetForNewReview(data)),
    uploadMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(uploadMediaInReviewEmbedLinkSetForNewReview(data)),
    finalizedMediaInReviewMediaSetForNewReview: (data) => dispatch(finalizedMediaInReviewMediaSetForNewReview(data)),
    finalizedMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForNewReview(data)),
    uploadMediaInReviewMediaSetForEditReview: (data) => dispatch(uploadMediaInReviewMediaSetForEditReview(data)),
    uploadMediaInReviewMediaSetForFollowReview: (data) => dispatch(uploadMediaInReviewMediaSetForFollowReview(data)),
    finalizedMediaInReviewEmbedLinkSetForFollowReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForFollowReview(data)),
    get_category_taxonomy_listing: (categoryType, types, mainTaxonomy) => dispatch(get_category_taxonomy_listing(categoryType,types, mainTaxonomy)),
    update_caption_and_categories: ({ id, data }) => dispatch(update_caption_and_categories({ id, data })),
});


export default connect(mapState, mapProps)(Reviews);
