/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import CollapseBasic from '../../../component/atoms/collapse';
import { Link } from 'react-router-dom';

export default class SimilarBusiness extends Component {
    render() {
        const { simillarBusiness } = this.props;
        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic title="Similar Businesses" containerClass="bg-transparent type-secondary" bodyClass="text-secondary" isOpen={true}>
                    <ul className="fs-14 list-unstyled">
                        {simillarBusiness && simillarBusiness?.results && Array.isArray(simillarBusiness?.results) && simillarBusiness?.results?.length ?
                            simillarBusiness?.results.map((item,index) => (
                                <li className="mb-3" key={index}>
                                        <div className="d-flex flex-nowrap mx-n1">
                                            <div className="px-1 mb-2">
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2 mt-1">
                                                        <Link 
                                                            onClick={() => { window.scrollTo(0, 0); }} to={{
                                                            pathname: `/${(item?.content_type).toLowerCase()}/${item?.slug}`,
                                                            state: { id: item?.id }
                                                        }} className="text-decoration-none">
                                                            <img
                                                                className="object-fit-cover object-position-center"
                                                                src={item?.listing_profileimage?.[0]?.url ? item?.listing_profileimage?.[0]?.url : require("../../../assets/images/icons/placeholder-img.png")}
                                                                alt=""
                                                                width={50}
                                                                height={50}
                                                                onError={(error) =>
                                                                    (error.target.src = require("../../../assets/images/icons/placeholder-img.png"))
                                                                }
                                                            />
                                                        </Link>
                                                    </div>

                                                    <div className="px-2">
                                                        <div className="font-weight-bold text-secondary-dark ff-base">
                                                            <Link 
                                                                onClick={() => { window.scrollTo(0, 0); }}
                                                                to={{
                                                                pathname: `/${(item?.content_type).toLowerCase()}/${item?.slug}`,
                                                                state: { id: item?.id }
                                                            }} className="text-reset">
                                                                {item?.name}
                                                            </Link>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap mx-n1">
                                                            <div className="px-1">
                                                                <img
                                                                    title={item?.total_rating?.avg_rating[1]}
                                                                    src={require(`../../../assets/images/icons/star/gold/${item?.total_rating?.avg_rating[0]}`)}
                                                                    alt="4.0 Rating"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div> {item?.total_rating?.total_review} {item?.total_rating?.total_review > 1 ? "Reviews" : "Review"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="bg-secondary mb-0 mt-1" />
                                    
                                </li>
                            ))
                            : 'No Record Found'}
                    </ul>
                </CollapseBasic>
            </div>
        )
    }
}
