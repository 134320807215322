import * as actions from "../actions/lawyer";
const initialState = {
    get_review_listing: null
};


const lawyer = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case actions.GET_REVIEW_LISTING_LAWYER:
            state.get_review_listing = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default lawyer;