import React, { Component } from 'react';
import { Button } from 'reactstrap';

export default class IsThatYourBusiness extends Component {
    render() {
        const {toggleYourBusiness} = this.props;
        return (
            <div className="text-dark p-3 mb-3" style={{ backgroundColor: '#d5d6e5' }}>
                <h2 className="mb-2">Is This Your Business?</h2>
                <div className="mt-4 text-right">
                    <Button size="sm" color="tertiary bg-tertiary border-tertiary ff-alt" onClick={() => { toggleYourBusiness() }}>Manage This Page</Button>
                </div>
            </div>
        )
    }
}
