import { callApi, callApiAdvance, responseCodeValidator } from "../utils/apiCaller";
import querystring from "query-string";
import { toast } from "react-toastify";

export const GET_FEEDS = "GET_FEEDS";
export const UPDATE_FEED_REACTIONS = "UPDATE_FEED_REACTIONS";
export const COMMENT_ON_FEED = "COMMENT_ON_FEED";
export const DELTE_COMMENT = "DELTE_COMMENT";
export const SUBMIT_COMMENT_FEED_REVIEW = "SUBMIT_COMMENT_FEED_REVIEW";
export const UPDATE_REVIEW_FEEDBACK = "UPDATE_REVIEW_FEEDBACK";
export const SET_FILTER = "SET_FILTER";
export const GET_DISPUTE_DATA = "GET_DISPUTE_DATA";
export const GET_JURY_DATA = "GET_JURY_DATA";
export const CHECK_JUROR_DATA = "CHECK_JUROR_DATA";
export const GET_HELPCOUNT = "GET_HELPCOUNT";
export const GET_PINSCOUNT = "GET_PINSCOUNT";
export const REVIEW_DATA = "REVIEW_DATA";
export const REVIEW_STATUS = "REVIEW_STATUS";
export const FINAL_JURY_DATA = "FINAL_JURY_DATA";
export const ADD_DISPUTE_STATUS = "ADD_DISPUTE_STATUS";
export const FEEDS_LOADING = "FEEDS_LOADING";
export const RESET_FEEDS = "RESET_FEEDS";

export const get_feeds_ = (data) => {
  return {
    type: GET_FEEDS,
    payload: data,
  };
};
export const reset_feeds = (data) => {
  return {
    type: RESET_FEEDS,
    payload: data,
  };
};

export const commentOnFeedAction = (data) => ({
  type: COMMENT_ON_FEED,
  payload: data,
});

export const deleteCommentAction = (data) => ({
  type: DELTE_COMMENT,
  payload: data,
});

export const updateFeedReactionsAction = (data) => ({
  type: UPDATE_FEED_REACTIONS,
  payload: data,
});

export const updateReviewFeedbackAction = (data) => ({
  type: UPDATE_REVIEW_FEEDBACK,
  payload: data,
});

export const submitCommentOnFeedReviewAction = (data) => ({
  type: SUBMIT_COMMENT_FEED_REVIEW,
  payload: data,
});


export const feeds_loading = (data) => ({
  type: FEEDS_LOADING,
  payload: data,
});


export const get_feeds = async (params, dispatch) => {
  dispatch(feeds_loading(true));

  const query = querystring.stringify(params);

  const response = await callApiAdvance({
    endpoint: `/api/userfeeds/${query ? "?" + query : ""}`,
    method: "GET",
    loader: false,
  });

  if (response && response.code !== 400 && response.code !== 500) {
    dispatch(get_feeds_(response));
  }

  dispatch(feeds_loading(false));

  return response;
};

export const update_reaction = (url, method, data) => {
  return (dispatch) => {
    callApi(`${url}`, method, data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return true;
      }
    });
  };
};

export const updateFeedReactions = (params, feedParams, type) => {
  return async (dispatch) => {

    let review_type = 'reviewgroup';
    if (type) {
      if (["accountants", "doctors", "dentists", "lawyers", "actors"].includes(type)) {
        review_type = 'professionalreviewgroup'
      }
    }

    let response = await callApiAdvance({
      endpoint:
        params.type === "Thank you"
          ? `/api/contributiongroup/`
          : `/api/${review_type}/`,
      method: "POST",
      body: params,
      loader: false,
    });

    if (response && response.code !== 400 && response.code !== 500) {
      try {
        dispatch(updateFeedReactionsAction(response));
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {
    
      }
    }
  };
};

export const addUpdateCommentOnFeed = (params, method, feedParams) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/otherfeeddiscussion/?`,
      method,
      params
    );

    if (response && responseCodeValidator(String(response.code))) {
      let msg = '';
      if (method == "PUT") {
        const { type, newConversation, ...newFeedParms } = { ...feedParams };
        feedParams = newFeedParms;

        if (type == "reply" && newConversation) msg = "Reply added successfully!";
        else if (type == "reply" && !newConversation) msg = "Reply updated successfully!";
        else msg = 'Comment updated successfully!'
      }

      if (method == "POST") {
        msg = 'Comment added successfully!'
      }
      
      
      toast(msg, {
        autoClose: 6500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      try {
        dispatch(commentOnFeedAction(response));
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {
    
      }
    }
  };
};
export const addPostCommentOnFeed = ({ data, id, method, feedParams }) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/feeds/${id}/?entity=`,
      method,
      data
    );

    if (response && responseCodeValidator(String(response.code))) {
      
      let msg = 'Comment added successfully!';
      if (data?.editpost) {
        msg = 'Comment updated successfully!'
      }

      toast(msg, {
        autoClose: 6500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });

      try {
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {

      }
    }
  };
}
export const addPostCommentReplyOnFeed = ({ data, method, feedParams }) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/savereply/`,
      method,
      data
    );

    if (response && responseCodeValidator(String(response.code))) {
      let msg = '';
      if (method == "POST") {
        msg = 'Reply added successfully!'
      }

      toast(msg, {
        autoClose: 6500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      try {
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {
        console.error({ error: e })
      }
    }
  };
}
export const deletePostCommentReplyOnFeed = (id, type, feedParams) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/deleteuserfeeds/${id}/`,
      "DELETE",
    );

    if (response && responseCodeValidator(String(response.code))) {
      let msg = '';
      if (type == "comment") {
        msg = 'Comment deleted successfully!'
      }
      if (type == "reply") {
        msg = 'Reply deleted successfully!'
      }

      toast(msg, {
        autoClose: 6500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      try {
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {
        console.error({ error: e })
      }
    }
  };
}

export const updateFeedbackDiscussion = (params, method, feedParams) => {
  return async (dispatch) => {
    const response = await callApi(`/api/savereply/`, method, params);
    const message =
      method === "PUT"
        ? "Comment updated successfully"
        : "Comment added successfully";
    if (response && response.code !== 400 && response.code !== 500) {
      toast(message, {
        autoClose: 6500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      try {
        dispatch(commentOnFeedAction(response));
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {
       
      }
    }
  };
};

export const deleteComments = (params, feedParams) => {
  const { isReviewCommentProfessional, ...feed_params } = feedParams;
  feedParams = feed_params;
  return async (dispatch) => {
    const query = querystring.stringify(params);
    const url = isReviewCommentProfessional ? `profeesionaldeleteuserfeed` : `deleterecord`;
    const response = await callApi(
      `/api/${url}/${query ? "?" + query : ""}`,
      "DELETE"
    );

    if (response && response.code !== 400 && response.code !== 500) {
      toast(`Comment deleted successfully!`, {
        autoClose: 6500,
        className: "black-background",
        bodyClassName: "red-hunt",
        progressClassName: "cc",
      });
      try {
        dispatch(deleteCommentAction(response));
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {
      
      }
    }
  };
};

export const submitCommentOnFeedReview = (params, body, method, feedParams) => {
  const query = querystring.stringify(params);
  
  const { isReviewProfessional, ...feed_params } = { ...feedParams };
  feedParams = feed_params;

  return async (dispatch) => {
    const response = await callApi(
      `/api/${isReviewProfessional ? `professionalreviewdiscussion` : `reviewdiscussion`}/${query ? "?" + query : ""}`,
      method,
      body
    );

    if (response && response.code !== 400 && response.code !== 500) {
      if (response.Message) {
        toast(response.Message, {
          autoClose: 6500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      } else {
        toast(`Comment added successfully`, {
          autoClose: 6500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      //   toast(`You have added comment for review`, {
      //     autoClose: 6500,
      //     className: "black-background",
      //     bodyClassName: "red-hunt",
      //     progressClassName: "cc",
      //   });
      try {
        dispatch(submitCommentOnFeedReviewAction(response));
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {
     
      }
    }
  };
};

export const updateReviewFeedback = (params, feedParams, type) => {
  return async (dispatch) => {
  
    let review_type = 'reviewgroup';
    if (type) {
      if (["accountants", "doctors", "dentists", "lawyers", "actors"].includes(type)) {
        review_type = 'professionalreviewgroup'
      }
    }

    const response = await callApi(`/api/${review_type}/`, "POST", params);
    if (response && response.code !== 400 && response.code !== 500) {
      try {
        dispatch(updateReviewFeedbackAction(response));
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const submitCommentOnFeedbacks = (id, body, method, feedParams) => {
  return async (dispatch) => {
    const response = await callApi(
      `/api/questions/${id}/?entity=`,
      method,
      body
    );

    if (response && response.code !== 400 && response.code !== 500) {
      if (response.Message) {
        toast(response.Message, {
          autoClose: 6500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      } else {
        toast(`Comment added successfully`, {
          autoClose: 6500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      try {
        dispatch(submitCommentOnFeedReviewAction(response));
        for (let i = 1; i <= feedParams.page; i++) {
          get_feeds({ ...feedParams, page: i }, dispatch);
        }
      } catch (e) {
        console.log({ e });
      }
    }
  };
};

export const setFilter = () => ({
  type: SET_FILTER,
});

//dispute review

export const checkStatusDispatch = (data) => {
  return {
    type: REVIEW_STATUS,
    payload: data,
  };
};

export const add_dispute_action = (data) => {
  return {
    type: ADD_DISPUTE_STATUS,
    payload: data,
  };
};

export const add_dispute_review = (data, file='home') => {  
  let formData = new FormData();
  if (data?.check_status) {
    formData.append("check_status", data.check_status);
    formData.append("review_id", data.review_id);

    return async (dispatch) => {
      callApi(`/api/disputes/`, "POST", formData, true).then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          if (response?.Status) {
            dispatch(checkStatusDispatch(response.Status));
            toast(response.Status, {
              autoClose: 5000,
              className: "black-background",
              bodyClassName: "red-hunt",
              progressClassName: "cc",
            });
          }
          else {
            dispatch(checkStatusDispatch(response.Status));
          }
        }
      });
    };
  }
  else {
    formData.append("reason", data.reason);
    formData.append("comment", data.comment);
    formData.append("review_id", data.review_id);
    for (let i = 0; i < data.dispute_file.length; i++) {
      formData.append("dispute_file", data.dispute_file[i]);
    }
  
  return async (dispatch) => {
    callApi(`/api/disputes/`, "POST", formData, true).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        if (response.Message) {
          toast(response.Message, {
            autoClose: 3000,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }
        else {
          toast(`Review has been marked as disputed!`, {
            autoClose: 3000,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }
        if (file === 'ClientReview') {
          return dispatch(add_dispute_action(response));
        }
        if ( file === 'reviewRating') {
          return dispatch(add_dispute_action(response));
        }
        else {
            try {
              get_feeds({ page: 1, reviews: true }, dispatch);
            } catch (e) {
              console.log({ e });
            }
          }
        }
    });
  };
  }
};

export const get_dispute_action = (data) => {
  return {
    type: GET_DISPUTE_DATA,
    payload: data,
  };
};

export const get_dispute_discussion = (review_id) => {
  return (dispatch) => {
    callApi(`/api/disputes/?review_id=${review_id}`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_dispute_action(response));
      }
    });
  };
};

export const getreviewHelpfulCopunt_ = (data) => {
  return {
    type: GET_HELPCOUNT,
    payload: data,
  };
};

export const getreviewHelpfulCopunt = (review_id) => {
  return (dispatch) => {
    callApi(
      `/get_helpfull_count_of_review/?review_id=${review_id}`,
      "GET"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(getreviewHelpfulCopunt_(response));
      }
    });
  };
};

export const getpinsCount_ = (data) => {
  return {
    type: GET_PINSCOUNT,
    payload: data,
  };
};

export const getpinsCount = (feed_id) => {
  return (dispatch) => {
    callApi(`/api/pins/?type=&user=&listing_id=${feed_id}`, "GET").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          if (response.length > 0) {
            response[0]["id"] = feed_id;
          }
          return dispatch(getpinsCount_(response));
        }
      }
    );
  };
};

export const delete_dispute = (review_id) => {
  return (dispatch) => {
    callApi(`/api/disputes/?review_id=${review_id}`, "DELETE").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          toast(`Dispute has been removed!`, {
            autoClose: 3000,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }
        try {
          get_feeds({ page: 1, reviews: true }, dispatch);
        } catch (e) {
          console.log({ e });
        }
      }
    );
  };
};

export const add_dispute_reply = (data) => {
  let formData = new FormData();
  formData.append("review_id", data.review_id);
  formData.append("flag_content_id", data.flag_content_id);
  formData.append("flag_id", data.flag_id);
  formData.append("comment", data.comment);

  for (let i = 0; i < data.dispute_reply_file.length; i++) {
    formData.append("dispute_reply_file", data.dispute_reply_file[i]);
  }

  return (dispatch) => {
    callApi(`/api/disputes-history/`, "POST", formData, true).then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          if (response.done) {
            toast("Your reply has been added Successfully!", {
              autoClose: 6500,
              className: "black-background",
              bodyClassName: "red-hunt",
              progressClassName: "cc",
            });
          }
        }
        return dispatch(get_dispute_discussion(data.review_id));
      }
    );
  };
};

export const delete_dispute_reply = (pk, review_id) => {
  return (dispatch) => {
    callApi(`/api/disputes-history/?flag_content_id=${pk}`, "DELETE").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          toast(`Reply has been removed!`, {
            autoClose: 3000,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }
        return dispatch(get_dispute_discussion(review_id));
      }
    );
  };
};

export const jury_duty_action = (data) => {
  return {
    type: GET_JURY_DATA,
    payload: data,
  };
};

export const check_jury_duty = (review_id) => {
  return (dispatch) => {
    callApi(`/api/disputes-check-jury/?review_id=${review_id}`, "GET").then(
      (response) => {
        if (response && response !== 400 && response.code !== 500) {
          return dispatch(jury_duty_action(response));
        }
      }
    );
  };
};

export const cast_jury_vote = (vote_details, review_id) => {
  let data = { vote_details: vote_details };
  return (dispatch) => {
    callApi(`/api/disputes-jury-voting/`, "POST", data).then((response) => {
      if (response && response !== 400 && response.code !== 500) {
        toast(response.status, {
          autoClose: 6500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(check_jury_duty(review_id));
      }
    });
  };
};

export const check_is_juror_action = (data) => {
  return {
    type: CHECK_JUROR_DATA,
    payload: data,
  };
};

export const check_is_juror = () => {
  return (dispatch) => {
    callApi(`/api/disputes-check-jury/`, "GET").then((response) => {
      if (response && response !== 400 && response.code !== 500) {
        return dispatch(check_is_juror_action(response));
      }
    });
  };
};

export const dispute_juror_notification = (params) => {
  return (dispatch) => {
    callApi(`/api/disputes-notification-jury/`, "POST", params).then(
      (response) => {
        if (response && response !== 400 && response.code !== 500) {
          return 0;
        }
      }
    );
  };
};

export const review_details_action = (data) => {
  return {
    type: REVIEW_DATA,
    payload: data,
  };
};

export const get_review_details = (review_id) => {
  return (dispatch) => {
    callApi(`/api/reviews/?id=${review_id}`, "GET").then((response) => {
      if (response && response !== 400 && response.code !== 500) {
        return dispatch(review_details_action(response));
      }
    });
  };
};


export const final_jury_duty_action = (data) => {
  return {
    type: FINAL_JURY_DATA,
    payload: data,
  };
};


export const check_final_jury_duty = () => {
  let check_juror = true
  return (dispatch) => {
    callApi(`/api/disputes-final-jury/?check_juror=${check_juror}`, "GET").then((response) => {
      if (response && response !== 400 && response.code !== 500) {
        return dispatch(final_jury_duty_action(response));
      }
    });
  };
}

export const final_jury_voting = (data) => {
  return (dispatch) => {
    callApi(`/api/disputes-final-jury/`, "POST", data).then((response) => {
      if (response && response !== 400 && response.code !== 500) {
        toast(response.Message, {
          autoClose: 6500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(check_final_jury_duty());
      }
    });
  };

}


