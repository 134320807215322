import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class MyFriends extends Component {
  render() {
    let { connections } = this.props;
    return (
      <div className="bg-white-shaded-md px-3 py-3 mb-3">
        <h2 className="text-secondary">My Friends on WikiReviews</h2>
        <hr className="bg-light" />

        <ul className="list-unstyled d-flex flex-wrap mx-n2">
          {connections &&
          connections?.results &&
          Array.isArray(connections?.results) &&
          connections?.results !== undefined
            ? connections?.results.map((item, index) =>
                index < 4 ? (
                  <li key={index} className="pr-2 mb-2">
                    <Link
                      to={`/people/${item?.connection?.user?.username}`}
                      target="_blank"
                    >
                      <img
                        className="img-circle _50x50"
                        src={item.connection.current_profile_file}
                        alt={`${item?.connection?.user?.first_name} ${item?.connection?.user?.last_name}`}
                        title={`${item?.connection?.user?.first_name} ${item?.connection?.user?.last_name}`}
                      />
                    </Link>
                  </li>
                ) : null
              )
            : null}
          {connections?.results !== undefined &&
            connections?.results.length > 4 && (
              <li className="px-2 mb-2 mt-2 more-link-home">
              <Link to={
                {
                  key: '1',
                  pathname: '/myprofile',
                  search: '?active_tab=friends',
                  state: {
                  }
                }
              }>
                <a rel="noopener noreferrer" className="text-secondary-dark font-weight-bold">
                  <span className="span_circle_50x50-home">
                    {`+${connections?.results.length - 4}`}
                  </span>
                </a>
              </Link>
              </li>
            )}
        </ul>
      </div>
    );
  }
}
