import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ReactDOM from "react-dom";
import { reset_verify_phone_number, updatePhoneNumber, verify_otp } from '../../actions/user';
import { toast } from 'react-toastify';
class PhoneOtpVerify extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isModalOpen: false,
            authPhone: {
                ph1: "",
                ph2: "",
                ph3: "",
            },
            currentNumber: null,
            otp: null,
            errorMessage: null,
            resendMessage: null,
        }
    }
    componentWillReceiveProps(nextProps){
        const { authNumber } = nextProps;
        if (authNumber) {
            this.setState({ isModalOpen: authNumber?.status });

            if(authNumber?.status){
                this.setState({
                    currentNumber: authNumber?.phone, 
                    authPhone: {
                        ph1: "",
                        ph2: "",
                        ph3: "",
                    }
                });
            }
        }
    }
    toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen, 
            authPhone: {
                ph1: "",
                ph2: "",
                ph3: "",
            },
            currentNumber: null,
            otp: null,
            errorMessage: null,
            resendMessage: null,
        },()=>{
            this.props.reset_verify_number();
        })
    }
    handleOnClickConfirm = () => {
        const { currentNumber, otp } = this.state;
        this.props.verify_otp({ phoneNumber: currentNumber, otp }).then((res) => {

            if (res?.results && res?.results[0]) {
                if (!res?.results[0]?.status) {
                    this.setState({ errorMessage: res?.results[0]?.msg, otp: null });
                }
                if (res?.results[0]?.status) {
                    this.toggleModal();
                    toast(`Phone Number Added successfully!`, {
                        autoClose: 2500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                }

            }
        });
    }
    handleOnClickResend = () => {
        const { authPhone } = this.state;
        if (
            authPhone?.ph1?.length == 3 &&
            authPhone?.ph2?.length == 3 &&
            authPhone?.ph3?.length == 4
        ) {
            const data = {
                ph1: authPhone?.ph1,
                ph2: authPhone?.ph2,
                ph3: authPhone?.ph3,
            };
            this.props.updatePhoneNumber(data).then((res)=>{
                if (res) {
                    this.setState({ resendMessage: res });
                }
            });
        }
    }

    render() {
        const { isModalOpen, currentNumber, otp, errorMessage, resendMessage } = this.state;
        return (
            <Modal
                className="custom-pop-change custom-otp-modal d-flex justify-content-center align-items-center"
                centered
                backdrop="static"
                isOpen={isModalOpen}
                toggle={this.toggleModal}>
                <ModalHeader className="px-0 mb-2 phone-otp-verify-title w-100 " toggle={this.toggleModal}>
                </ModalHeader>
                <ModalBody className="wk-compose-new-messagebody phone-otp-verify ">
                    <div className="header  p-3">
                        <h4 className='mb-2 font-weight-normal text-primary fs-24'>Confirm Your Phone Number</h4>
                        <span className='text-wrap'>Be prepared to recieve a text or call at +{currentNumber} from Wikireviews</span>
                    </div>
                    <div className="body p-3">
                        <div className='confirm-code mb-3'>
                            <FormGroup className='d-flex mb-1 align-items-center justify-content-between'>
                                <Input
                                    type='number'
                                    placeholder='Enter Code'
                                    className='primary flex-3'
                                    min="0"
                                    max="99999"
                                    onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) &&  e.preventDefault()}
                                    value={otp}
                                    onChange={(e) => this.setState({ otp: e.target.value })}
                                />
                                <Button className="text-center ml-2 flex-2" color="primary" onClick={() => this.handleOnClickConfirm()}>
                                    Confirm
                                </Button>
                            </FormGroup>
                            {errorMessage && <span className='text-danger fs-14'>{errorMessage}</span>}
                        </div>
                        <div className='resend-code'>
                            <span className='text-center text-wrap mb-3'>
                                Haven't received your code yet? Confirm your number below and click resend.
                            </span>
                            <Row
                                className="justify-content-center align-items-center text-center mx-auto"
                                form
                            >
                                <Col>
                                    <Input
                                        className="primary"
                                        ref={c => this.nextComponent1 = c}
                                        value={this.state.authPhone.ph1}
                                        onChange={(e) => {
                                            if (e.target.value.length === 3 || e.target.value.length > 3) {

                                                ReactDOM.findDOMNode(this.nextComponent).focus();
                                            }
                                            if (e.target.value.length <= 3) {
                                                let { authPhone } = this.state;
                                                authPhone["ph1"] = e.target.value;
                                                this.setState({ authPhone });
                                            }

                                        }}
                                        bsSize="sm"
                                        type="number"
                                        name="number_part_1"
                                        min="0"
                                        max="999"
                                        onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) &&  e.preventDefault()}
                                        placeholder="(555)"
                                    />
                                </Col>
                                <Col>
                                    <Input

                                        value={this.state.authPhone.ph2}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 3) {
                                                let { authPhone } = this.state;
                                                authPhone["ph2"] = e.target.value;
                                                this.setState({ authPhone });
                                            }
                                            if (e.target.value.length === 3 || e.target.value.length > 3) {
                                                ReactDOM.findDOMNode(this.nextComponent3).focus();
                                            }
                                        }}
                                        className="primary"
                                        bsSize="sm"
                                        type="number"
                                        name="number_part_2"
                                        min="0"
                                        max="999"
                                        onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) &&  e.preventDefault()}
                                        placeholder="555"
                                        ref={c => this.nextComponent = c}
                                    />
                                </Col>
                                <Col>
                                    <Input

                                        value={this.state.authPhone.ph3}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 4) {
                                                let { authPhone } = this.state;
                                                authPhone["ph3"] = e.target.value;
                                                this.setState({ authPhone });
                                            }
                                            if (e.target.value.length === 4) {
                                                ReactDOM.findDOMNode(this.nextComponent3).blur();
                                            }
                                        }}
                                        className="primary"
                                        bsSize="sm"
                                        type="number"
                                        name="number_part_3"
                                        min="0"
                                        max="9999"
                                        onKeyDown={e => (/[\+\-\,]$/.test(e.key) || ["e", "E", "+", "-"].includes(e.key)) &&  e.preventDefault()}
                                        placeholder="5555"
                                        style={{ width: "120px" }}
                                        ref={d => this.nextComponent3 = d}

                                    />
                                </Col>
                                <Col xs="auto">
                                    <Button

                                        onClick={() => {
                                            this.handleOnClickResend();
                                        }}
                                        disabled={
                                            this.state.authPhone?.ph1?.length == 3 &&
                                                this.state.authPhone?.ph2?.length == 3 &&
                                                this.state.authPhone?.ph3?.length == 4
                                                ? false
                                                : true
                                        }
                                        color="text-dark"
                                        className="fs-15"
                                        size="sm"
                                    >
                                        Resend
                                    </Button>
                                </Col>
                                {
                                    resendMessage &&
                                    <span className={`mt-1 fs-14 ${resendMessage?.status ? `text-primary` : `text-danger`}`}>
                                        {resendMessage?.status ? `Verification code sent successfully!` : resendMessage?.msg}
                                    </span>
                                }
                            </Row>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-start px-0">
                </ModalFooter>
            </Modal >
        )
    }
}

const mapStateToProps = (state) => ({
    authNumber: state.user.auth_phone_number,
})

const mapDispatchToProps = (dispatch) => ({
    verify_otp: (data) => dispatch(verify_otp(data)),
    reset_verify_number: () => dispatch(reset_verify_phone_number()),
    updatePhoneNumber: (data) => dispatch(updatePhoneNumber(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneOtpVerify)
