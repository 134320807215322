// SUBCATEGORY LISTINGS
export const getSubCategoryTitle = (category, subCategory) => {

    if (!subCategory) return `${capFirstLetter(category)} Listings. Review Your ${capFirstLetter(category)} on WikiReviews.`

    else
        return `Read about ${capFirstLetter(subCategory)} in the ${capFirstLetter(category)} category. Choose a ${capFirstLetter(category)} to Review.`
};

export const getSubCategoryDescription = (category, subCategory) => {

    if (!subCategory)
        return `Browse the ${capFirstLetter(category)} category at WikiReviews. Read reviews, respond, or post your own.`

    else
        return `Read about ${capFirstLetter(subCategory)} in the ${capFirstLetter(category)} category. Choose a ${capFirstLetter(category)} to review, read reviews, or respond.`
};


// Individual Category Details Page
export const getCategoryDetailTitle = (category, productName) => {
    return `Reviews for ${capFirstLetter(productName)} in ${capFirstLetter(category)}`
}

export const getCategoryDetailDescription = (productName) => {
    return `Read and respond to reviews for ${capFirstLetter(productName)}. Sign in to edit, improve, or change the listing.`
}

export const capFirstLetter = (string) => {
    if (!string) return;

    return string.charAt(0).toUpperCase() + string.slice(1);
}
