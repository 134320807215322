import React from "react";
import { Collapse } from 'reactstrap';

const RatingCollapse = (props) => {

    return (
        <Collapse
            className="rating-collapse"
            timeout={{
                appear: 1500,
                enter: 1500,
                exit: 0,
            }} 
            isOpen={props.isOpen}>
            {props.children}
        </Collapse>
    );
};

export default RatingCollapse;
