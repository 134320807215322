import * as actions from "../actions/accountant";
const initialState = {
    get_review_listing: null
};


const accountant = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case actions.GET_REVIEW_LISTING_ACCOUNTANT:
            state.get_review_listing = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default accountant;
