import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Progress,
} from "reactstrap";
import moment from "moment";

import {initFetchRateCrpSubCategories} from "../../../actions/footer";
import { get_project_subcategories, get_project_subcategories_level} from "../../../actions/communityReviewProjects";

import { callApi } from "../../../utils/apiCaller";

class RateCrpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addMovieForm: {
        name: "",
        is_draft: false,
        drawer: true,
        additional_info: {
          rating: "",
          duration: "",
          storyline: "",
        },
        taxonomy: [],
        media: [],
        country: "",
        year: moment().year(),
        rating: "",
        duration: "",
      },
      years_list: [],
      name: '',
      category_id: '',
      subcategory_id: '',
      year: moment().year(),
      country: 'United States',
      rating: 'G',
      duration: '',
      storyline: '',
      mediaSet: [],
      media: [],
      uploadMediaModal: false,
      fileReviewStatus: false,
      selectedFilesPreview: [],
      latestUploadedData: {},
      showVideoMedia: true,
      fetchTaxonomy: [],
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      uploadCaptions: {},
      nameError: "",
      categoryError: "",
      directorsError: "",
      durationError: "",
      writersError: "",
      storylineError: "",
      producersError: "",
      castsError: "",
      files: [],
      albumType: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      uploadMediaPostModal: false,
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
	  search_driector_info:[],
	  search_producer_info:[],
	  search_writer_info:[],
	  search_cast_info:[],
	  indexValue : 0,
	  indexCastValue : 0,
	  indexProducerValue : 0,
	  indexWriterValue : 0,
	  typeMethod : "",
	  showProjectSubCategories: false,
      showProjectSubCategoriesLevel: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    
		
  }
 componentDidMount = () => {
   this.fetchDataFromHtmlForm()
  }

fetchDataFromHtmlForm = async () => {
  console.log('test')
        let name_type = `review-step-1`;
        let  url = `/form/ratingadd?type=CRPProjects`;
		
        if (url) {
            try {
                const response = await callApi(url, 'GET');

                if (response?.code == 200) {
                    let data = response?.data;
                    let personOptions = null;
                    if (data) {
                        const doc = new DOMParser().parseFromString(data, "text/html")

                        if (doc) {
							
                            /*let uniqrate = doc.getElementById('uniqrate').value;
                            let csrfmiddlewaretoken = doc.getElementsByName('csrfmiddlewaretoken')[0].value;
                            let timestamp = doc.getElementsByName('timestamp')[0].value;
                            let honeypot = doc.getElementsByName('honeypot')[0].value;
                            let object_pk = doc.getElementsByName('object_pk')[0].value;
                            let content_type = doc.getElementsByName('content_type')[0].value;

                            let avg_rating_34 = doc.getElementsByClassName(name_type)[0];

                            if (avg_rating_34) {
                                let security_hash = avg_rating_34.querySelector('#id_security_hash')?.value;

                                let reviewDynamicItems = {};
                                reviewDynamicItems.uniqrate = uniqrate;
                                reviewDynamicItems.csrfmiddlewaretoken = csrfmiddlewaretoken;
                                reviewDynamicItems.security_hash = security_hash;
                                reviewDynamicItems.timestamp = timestamp;
                                reviewDynamicItems.honeypot = honeypot;
                                reviewDynamicItems.object_pk = object_pk;
                                reviewDynamicItems.content_type = content_type;

                                if (listing_type == "movies") {
                                    if (this.props.isWriteFollowUp) {
                                        personOptions = doc.getElementById('follow_profession').innerHTML;
                                    } else {
                                        personOptions = doc.getElementById('id_professional').innerHTML;
                                    }
                                }

                                let filteredReviewCategories = [];
                                let sampleElements = doc.getElementsByClassName('services')[0];
                                let listElements = sampleElements?.getElementsByTagName('li');

                                if (listElements && typeof (listElements) == "object" && listElements?.length) {
                                    for (let item of listElements) {
                                        filteredReviewCategories.push(this.renderListItems(item));
                                    }

                                    this.setState({
                                        doc,
                                        uniqrate,
                                        reviewDynamicItems,
                                        choosePersonOptions: personOptions,
                                        reviewCategoriesList: filteredReviewCategories
                                    });
                                }
                            }*/
                        }
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
    }




  handleOnChangeAddProject = (e, categoryType = '') => {
        if (categoryType && e.target.value) {
            this.handleOnClickCategory(e.target.value, categoryType);
            this.fetchDataFromHtmlForm();
        } 
    }

  handleOnClickCategory = (id, type) => {
        let showProjectSubCategories = false,
            showProjectSubCategoriesLevel = false,
            key = 'project_taxonomy';
        if (type === 'main') {
            this.props.get_project_subcategories(id);
            showProjectSubCategories = true;
        } else if (type === 'sub') {
            this.props.get_project_subcategories_level(id);
            showProjectSubCategories = true;
            showProjectSubCategoriesLevel = true;
            key = 'subCategory1';
        } else if (type === 'sublevel') {
            showProjectSubCategories = true;
            showProjectSubCategoriesLevel = true;
            key = 'subCategory2';
        }
		this.setState({
            showProjectSubCategories: showProjectSubCategories,
            showProjectSubCategoriesLevel: showProjectSubCategoriesLevel,
        });
    }
 

  handleMovieSubmit = () => {
    let { name, storyline, category_id, duration, directors_set, additional_info, writers_set, producers_set, casts_set } = this.state
    if (name == "" && category_id == "" && directors_set[0].first_name == "" && directors_set[0].profession_id == "" && writers_set[0].first_name == ""
	&& writers_set[0].profession_id == "" && producers_set[0].profession_id == "" && producers_set[0].first_name == "" && casts_set[0].first_name == ""
	&& casts_set[0].profession_id == "" && storyline == "") {
      this.setState({
        nameError:
          "This field is required",
        categoryError:
          "This field is required",
        directorsError:
          "This field is required",
        durationError:
          "This field is required",
        writersError:
          "This field is required",
        storylineError:
          "This field is required",
        producersError:
          "This field is required",
        castsError:
          "This field is required",
      });
    } else if (name == "") {
      this.setState({
        nameError:
          "This field is required",
      });
    }
    else if (category_id == "") {
      this.setState({
        categoryError:
          "Please select category",
      });

    }

  
    else {
    let params = {}
  
    let intItem = parseInt(this.state.category_id)
    params = {
      name: this.state.name,
      is_draft: false,
      additional_info: {
        rating: this.state.rating,
        duration: this.state.duration,
        storyline: this.state.storyline,
      },
      country: this.state.country,
      cast_set: this.state.casts_set,
      directors_set: this.state.directors_set,
      producers_set: this.state.producers_set,
      writers_set: this.state.writers_set,
      media: [],
      taxonomy: [intItem],
      release_date: this.state.year,
    }
  this.props.add_movie(params);

   }
  }

  render() {
    const {
      openModal,
      toggleCrpModal,
      project_taxonomies, 
	  project_subcategories, 
	  project_subcategories_level
    } = this.props;
	let {category_id,showProjectSubCategories, showProjectSubCategoriesLevel} = this.state;
    return (
      <>
           /* Add movieuseFormik drawer modal popup */
              <Modal
                size="xl"
                scrollable
                className="drawer-modal"
                isOpen={openModal}
                toggle={toggleCrpModal}
              >
                <form
                  className="d-flex flex-column h-100"
                >
                  <div className="modal-header p-0">
                    <div className="w-100">
                      <div>
                        <Button color="primary" onClick={toggleCrpModal}>
                          <FontAwesomeIcon icon="angle-left" />
                        back to listing
                      </Button>
                      </div>
                      <div>
                        <div className="bg-darker p-3 text-tertiary">
                          <div className="mb-2 text-primary font-weight-bold">
                            You’re Rating CRPProjects
                        </div>
                          <ul className="list-unstyled d-flex flex-wrap mx-n2">
                            <li className="px-2">
                            <div className="d-flex">
                              <div className="mr-1">
                                <span className="text-danger">*</span>
                              </div>
                              <FormGroup>
                                <Input
                                  id="category_id"
                                  name="project_taxonomy" 
								  onChange={(e) => this.handleOnChangeAddProject(e, 'main')}
                                  type="select"
                                  className="transparent"
                                  bsSize="sm"
                                 >
                                  <option value="">Select Category</option>
                                  {project_taxonomies && project_taxonomies.length > 0 ? project_taxonomies.map((pt) => {
                                    return (
                                      <option value={pt.id} key={pt.id}>
                                        {pt.category}
                                      </option>

                                    );
                                  }) : ("")}
                                </Input>
                                <span style={{ color: 'red' }}>{this.state.categoryError}</span>
                              </FormGroup>
                            </div>
                          </li>
						  {showProjectSubCategories && project_subcategories && project_subcategories.length > 0 && (
                          <li className="px-2">
                            <FormGroup>
                              <Input
                                id="subcategory_id"
                                name="subCategory1" 
								onChange={(e) => this.handleOnChangeAddProject(e, 'sub')}
                                type="select"
                                className="transparent"
                                bsSize="sm"
                              >
                                <option value="">Select SubCategory</option>
                                {project_subcategories && project_subcategories.length > 0 && project_subcategories.map(pt => {
                                 return (

                                    <option value={pt.id} key={pt.id}>
                                      {pt.category}
                                    </option>
                                 )
                               })}
                              </Input>
                            </FormGroup>
                          </li>
						)}
						{showProjectSubCategoriesLevel && project_subcategories_level && project_subcategories_level.length > 0 && (		
                          <li className="px-2">
                            <FormGroup>
                              <Input
                                id="anothersubcategory_id"
                                name="subCategory2" 
								onChange={(e) => this.handleOnChangeAddProject(e, 'sublevel')}
                                type="select"
                                className="transparent"
                                size="sm"
                              >
                                <option value="">Select SubCategory</option>
                                {project_subcategories_level && project_subcategories_level.length > 0 && project_subcategories_level.map(pt => {
									return (
										<option value={pt.id} key={pt.id}>{pt.category}</option>
									)
								})}
                              </Input>
                            </FormGroup>
                          </li>
						)}	
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ModalBody className="bg-white text-dark">
                    <div className="wk-crpmodal">
                    <Row>
                      <Col md="6">
                        <div className=" font-weight-bold mb-2 wk-crpmodalhead mb-4">
                          Test project
                        </div>
                        <p className="wk-crp-projectinfo-text"><i class="icon-comma"></i> <span>project info</span></p>
                        <p>Created by <a href="#">Amarjeet Singh</a></p>
                      </Col>
                      <Col md="6">
                        <p>Rate This Project</p>
                        <p> 0 | <FontAwesomeIcon icon="star" /> <FontAwesomeIcon icon="star" /> <FontAwesomeIcon icon="star" /> <FontAwesomeIcon icon="star" /> <FontAwesomeIcon icon="star" /> &nbsp; &nbsp; <i>Oh, Hell No!</i></p>
                        <p className="pl-3"><i>Oh, Hell No!</i></p>
                        <p className="pl-3"><i>Definitely would not recommend!</i></p>
                        <p className="pl-3"><i>Definitely would not recommend!</i></p>
                        <p className="pl-3"><i>It could have been a lot better!</i></p>
                        <p className="pl-3"><i>It could have been a lot better! Okay!</i></p>
                        <p className="pl-3"><i>Okay! Very good! Very good!</i></p>
                        <p className="pl-3"><i>Can't get any better than this!</i></p>
                        <p className="pl-3"><i>Can't get any better than this!</i></p>
                        <textarea placeholder="" className="form-control wk-crpmodal-quick-note">Quick Note.(optional)</textarea>
                      </Col>
                    </Row>
                      <div className="text-right mt-3">
                        <Button color="primary" size="sm" onClick={this.handleMovieSubmit}>
                          Submit
                      </Button>
                      <a href="#" className="ml-4">Skip</a>
                      </div>
                    </div>
                  </ModalBody>
                </form>
              </Modal>

		/>

      </>
	);
 }
}

const mapState = (state) => ({
  videos_children:
  state.business.videos_children && state.business.videos_children[0],
  fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
  producerListing: state.footer.producerListing,
  fetch_save_movie_response: state.movies.fetch_save_movie_response,
  project_subcategories: state.communityReviewProjects.project_subcategories,
  project_subcategories_level: state.communityReviewProjects.project_subcategories_level,
});

const mapProps = (dispatch) => ({
  get_project_subcategories: (id) => dispatch(get_project_subcategories(id)),
    get_project_subcategories_level: (id) => dispatch(get_project_subcategories_level(id)),	
  initFetchRateCrpSubCategories: (categoryId, type) => dispatch(initFetchRateCrpSubCategories(categoryId, type)),

});
export default withRouter(connect(mapState, mapProps)(RateCrpModal));