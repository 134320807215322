import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Rating from 'react-rating';
import _ from 'lodash'
import {
    Button, Col, Collapse,
    FormGroup,
    Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip
} from 'reactstrap';
import StarsRating from 'stars-rating';
import { callApi } from '../../utils/apiCaller';
import CollapseBasic from './collapse';
import WebCamRecording from '../oragnisms/businessDetails/webCamRecording';
import ReactHtmlParser from 'react-html-parser';
import { toast } from "react-toastify";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';
import { rating_created_dispatch, setEditExistingEmbedMedia, setEditExistingMedia, setFollowReviewToggler } from '../../actions/shared';
import { finalizedMediaInReviewEmbedLinkSetForNewReview, finalizedMediaInReviewMediaSetForEditReview, finalizedMediaInReviewMediaSetForNewReview, uploadMediaInReviewMediaSetForEditReview, uploadMediaInReviewMediaSetForNewReview } from '../../actions/reviewMedia';
import ShowUploadedMedia from '../molecules/shared/ShowUploadedMedia/showUploadedMedia';


const empty_star_rating = (<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "34px" }}>★</span>);

const full_star_rating = (<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "34px" }}>★</span>);

const empty_star_rating_below = (<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "24px" }}>★</span>);

const full_star_rating_below = (<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "24px" }}>★</span>);
let checkIfEditEnable = false;
let checkIfWriteFollowUp = false;
let checkIfProfessional = false;

class CreateReview extends Component {
    constructor(props) {
        super(props)
        this.VoteData = false;
        this.isRendered = false;
        this.callWriteFormOnce = false;

        let isEditItem = props?.isEditItem;
        let listing_type = props?.listing_type;
        let editBusinessRatingList = [];        
        let editfilteredReviewCategories = []
        let avgRateKeyEdit;
        let itemDetail = props?.itemDetail;
        let chooseAPerson = {
            selectedItem: null,
            firstNameText: '',
            lastNameText: '',
            ratingPerson: {},
            fullNameText: {}
        }
        // let toEditBusinessRatingList = setEditBussinessRatingList()
        if (isEditItem) {
            checkIfEditEnable = props.isEditEnable;
            avgRateKeyEdit = isEditItem?.review_voting_key
        }
        
        if (isEditItem && listing_type == "movies") {

            if (isEditItem?.professional && isEditItem?.professional?.reviews_professional) {
                chooseAPerson['selectedItem'] = isEditItem?.professional?.id;
                const firstNameText = isEditItem?.professional?.reviews_professional?.firstname;
                const lastNameText = isEditItem?.professional?.reviews_professional?.lastname;
                chooseAPerson['fullNameText'][isEditItem?.professional?.id] = firstNameText + ' ' + lastNameText;

                if (isEditItem?.ratings_sets && isEditItem?.ratings_sets?.proessional_rate) {
                    const personName = Object.keys(isEditItem?.ratings_sets?.proessional_rate)[0];
                    const professionalRating = isEditItem?.ratings_sets?.proessional_rate?.[personName][1];
                    chooseAPerson['ratingPerson'][isEditItem?.professional?.id] = professionalRating;
                }
            }
        }
        
        if (props?.isWriteFollowUp) {
            checkIfWriteFollowUp = true;
        }

        if (isEditItem?.ratings_sets && Object.keys(isEditItem?.ratings_sets).length) {
            
            Object.keys(isEditItem?.ratings_sets).map(item => {
                let value = isEditItem?.ratings_sets[item];
                editBusinessRatingList.push({ key: item, value: value[1],isEnable:false });
            });
            
            for (const item of editBusinessRatingList) {
                if(item.key==="avg_rating" || item.key==="total_review") {}
                else editfilteredReviewCategories.push(this.renderListItems(item,isEditItem?.category_ids));
            }   
                     
        }
        let isProfessional
        let reviewName = 'review'
        if(["accountants", "doctors", "dentists", "lawyers", "actors"].includes(this.props?.listing_type)){
            isProfessional = true
            reviewName = 'professionalreview'
            checkIfProfessional=true
        }else if(["products", "business", "movies"].includes(this.props?.listing_type)){
            checkIfProfessional = false;
        }  
        this.state = {
            errors: {},
            shocialNetworkSharing: false,
            host: window.location.host,
            protocol: window.location.protocol,
            is_anonymous: false,
            is_social_share: true,
            doc: null,
            embedBody: isEditItem?.embeded_video?.body || [],
            personFormRatings: null,
            reviewCategoriesList: !!isEditItem?.review_voting_key ? editfilteredReviewCategories : [],
            chooseAPersonOptions: (itemDetail?.professional && listing_type == "movies") ? itemDetail?.professional : null,
            uniqrate: null,
            enableHoverPersonRating: false,
            enableHoverBanIcon: false,
            enableRatingText: null,
            titleRatingText: null,
            avg_rating: isEditItem?.ratings_sets?.avg_rating[1] ? isEditItem?.ratings_sets?.avg_rating[1] == "No Rating" ? 0 : isEditItem?.ratings_sets?.avg_rating[1] : null,
            reviewDynamicItems: null,
            item_id: null,
            listing_id: null,
            businessRatingList: !!isEditItem?.review_voting_key ? editBusinessRatingList : [],
            avarageTrue: props?.avarageTrue ? props?.avarageTrue : false,
            isHiddenReviewTipsAndGuide: true,
            isMaskReview: isEditItem?.is_masked || false,
            writeAReviewText: isEditItem?.[reviewName] ? isEditItem?.[reviewName]?.replace(/<[^>]*>?/gm, '') : isEditItem?.review ? isEditItem?.review?.replace(/<[^>]*>?/gm, '') : '',
            writeAReviewTextVideo: isEditItem?.optional_video_text ? isEditItem?.optional_video_text?.replace(/<[^>]*>?/gm, '') : isEditItem?.review ? isEditItem?.review?.replace(/<[^>]*>?/gm, '') : '',
            chooseAPerson: chooseAPerson,
            reviewCategories: {},
            viewEditReviewStep: "writeReview",
            stepTwoUploadedVideo: [],
            uploadedFiles: isEditItem?.reviewmedia_set || [],
            isAdhere: true,
            clickedOnWriteAReview: -1,
            myProfile: null,
            categoryItemQueryParams:{
                object_pk: props?.categoryId,
                content_type: isProfessional ? 'professionals.professionaluserentries' : 'reviews.userentries',
                key: 'avg_rating',                
            },
            voteScore:!!isEditItem?.review_voting_key ? +isEditItem?.ratings_sets?.avg_rating?.[1] : null,
            avgRateKey:!!isEditItem?.review_voting_key ? avgRateKeyEdit : null,
            ratingOn:false,
            removedMediaItem:[],
            isEditReviewEnable: !!isEditItem?.review_voting_key ? true : false
        }
    }

    componentWillReceiveProps(nextProps) {
        const { listing_type, itemDetail } = nextProps;
        if (nextProps.my_profile_info && Object.keys(nextProps.my_profile_info).length > 0) {
            this.setState({
                myProfile: nextProps.my_profile_info
            });
        }       
        // if (nextProps.categoryId!==this.props.categoryId) {            
            if(["accountants", "doctors", "dentists", "lawyers", "actors"].includes(this.props?.listing_type)){               
                this.setState(prevState=>{
                    return {
                        ...prevState,
                        categoryItemQueryParams : {
                            ...prevState.categoryItemQueryParams,
                            object_pk:nextProps?.categoryId || this.props.categoryId, 
                        }                    
                    }
                });
                checkIfProfessional = true;
            }else if(["products", "business", "movies"].includes(this.props?.listing_type)){
                checkIfProfessional = false;
            } 
        if (itemDetail?.id && listing_type == "movies") {
            this.setState({
                chooseAPersonOptions: itemDetail?.professional
            });
        }           
        // }   
                   
    }

    setEditBussinessRatingList = ()=>{
        let tempArray = []
        const {isEditItem} = this.state
        if (isEditItem?.ratings_sets && Object.keys(isEditItem?.ratings_sets).length) {
            Object.keys(isEditItem?.ratings_sets).map(item => {
                let value = isEditItem?.ratings_sets[item];
                tempArray.push({ key: item, value: value[1] });
            });
        }
        return tempArray
    }

    setClickedTabOperation = ({ value }) => {
        if (value > 1) {
            this.setState({ clickedOnWriteAReview: value, viewEditReviewStep: "writeReview" });
        } else {
            this.setState({ clickedOnWriteAReview: value });
        }
    }

    resetStates = (publishReview) => {
       
        let isProfessionalReset
        
        if(["accountants", "doctors", "dentists", "lawyers", "actors"].includes(this.props?.listing_type)){
            isProfessionalReset = true            
        } 

        this.setState({
            is_anonymous: false,
            is_social_share: false,
            loadingdata: false,
            doc: null,
            embedBody: [],
            personFormRatings: null,
            reviewCategoriesList: [],
            choosePersonOptions: null,
            uniqrate: null,
            enableHoverPersonRating: false,
            enableHoverBanIcon: false,
            enableRatingText: null,
            titleRatingText: null,
            avg_rating: null,
            reviewDynamicItems: null,
            // item_id: null,
            listing_id: null,
            // businessRatingList: [],
            avarageTrue: false,
            isHiddenReviewTipsAndGuide: true,
            isMaskReview: false,
            writeAReviewText: '',
            writeAReviewTextVideo: '',
            chooseAPerson: {
                selectedItem: null,
                firstNameText: '',
                lastNameText: '',
                ratingPerson: {},
                fullNameText: {}
            },
            reviewCategories: {},
            viewEditReviewStep: "writeReview",
            stepTwoUploadedVideo: [],
            uploadedFiles: [],
            isAdhere: true,
            avgRateKey:null,
            removedMediaItem:[],
            categoryItemQueryParams:{
                object_pk: this.props?.categoryId,
                content_type: isProfessionalReset ? 'professionals.professionaluserentries' : 'reviews.userentries',
                key: 'avg_rating',                
            },
            isEditReviewEnable:false
        }, () => {
            // if (!checkIfEditEnable) {
            // }
            checkIfEditEnable = false;
            if (this.props?.closeToggleEditEnable) {
                this.props.closeToggleEditEnable();
            }
            if (this.props?.toggleWriteFollowUp) {
                this.props.toggleWriteFollowUp(false)
            }
        });
        this.props.uploadMediaInReviewMediaSetForNewReview([]);
        this.props.finalizedMediaInReviewMediaSetForNewReview([]);
        this.props.finalizedMediaInReviewEmbedLinkSetForNewReview([]);

        if (this.props?.feed && this.props?.handleCloseFeed) {
            this.props.handleCloseFeed({ listing_type: this.props?.itemDetail?.listing_type, listing_id: this.props?.itemDetail?.id, feed_id: this.props?.feed?.id,publishReview})
        }
    }

    componentDidMount() {
        let { listing_type, itemDetail } = this.props;
        if (this.props.isWriteFollowUp && !this.callWriteFormOnce) {
            this.callWriteFormOnce = true;
        }
        if(!this.props.isEditReviewEnabled){
            this.props.finalizedMediaInReviewMediaSetForNewReview([]);
            this.props.finalizedMediaInReviewEmbedLinkSetForNewReview([]);
            this.props.uploadMediaInReviewMediaSetForNewReview([]);
        }
        
        // this.getSecurityHashToken()
        // window.scrollTo(0, 0)
    }

    componentDidUpdate() {
        let listing_type = this.props.listing_type;
        let itemDetail = this.props.itemDetail;
        let nextProps = this.props;
        let isEditItem = nextProps?.isEditItem;
      

     
        let next_uploaded_files = nextProps?.uploadedMediaSet;

       
        let {uploadedFiles} = this.state;
        if(next_uploaded_files?.embedLinks!==undefined){           
            if (next_uploaded_files?.embedLinks &&
                Array.isArray(next_uploaded_files?.embedLinks) &&
                next_uploaded_files?.embedLinks?.length > 0 &&
                next_uploaded_files?.embedLinks?.length !== this.state?.embedBody?.length) {                   
                this.setState({ embedBody: next_uploaded_files?.embedLinks });
            }
        }        
        if (this.state.isEditReviewEnable &&
            next_uploaded_files !== undefined &&
            isEditItem !== undefined &&
            Array.isArray(next_uploaded_files) &&
            next_uploaded_files?.length > 0 &&
            next_uploaded_files?.length !== uploadedFiles?.length) {
            this.setState({ uploadedFiles: next_uploaded_files });
        } else {
            if (!!this.state.avgRateKey) {                           
                let uploadedMediaSet = nextProps.uploadedMediaSet?.uploadedFiles;
                if (uploadedMediaSet?.length !== uploadedFiles?.length && !checkIfWriteFollowUp) {
                    if (Array.isArray(uploadedMediaSet) && uploadedMediaSet.length > 0 && uploadedFiles !== nextProps.uploadedMediaSet?.uploadedFiles) {
                        let itemSet = this.state.uploadedFiles;
                        // if (itemSet?.length) {
                        //     itemSet = itemSet.concat(uploadedMediaSet);
                        // } else {
                        //     itemSet = uploadedMediaSet;
                        // }
                        this.setState({ uploadedFiles: nextProps.uploadedMediaSet?.uploadedFiles, loadingdata: false });
                    }
                }

                if (next_uploaded_files?.length !== uploadedFiles?.length && checkIfWriteFollowUp) {

                    this.setState({ uploadedFiles: next_uploaded_files });
                }
            }
        }

        if(isEditItem?.reviewmedia_set){
            for (let index = 0; index < isEditItem.reviewmedia_set.length; index++) {
                const element = isEditItem.reviewmedia_set[index];
                if(element.type == "embedded_link"){
                    const embedFiles = (!this.state.embedBody || this.state.embedBody === "nodata")? [] : [...this.state.embedBody]
                  if(!embedFiles.find(u=> u.url ===element.url )                  ){
                    embedFiles.push(element);
                    this.setState({ embedBody: embedFiles });
                  }
                   
                } else {
                    const upFiles = (!this.state.uploadedFiles || this.state.uploadedFiles === "nodata")? [] : [...this.state.uploadedFiles]
                    if(!upFiles.find(u=> u.url ===element.url )                  ){
                        upFiles.push(element);
                        this.setState({ uploadedFiles: upFiles });
                    }
                }
               }


        }
      

        if (itemDetail?.id && !this.isRendered) {
            this.isRendered = true;
            let businessRatingList = [];
            let filteredReviewCategories = []
            if (!isEditItem) {
                if(this.props.listing_type==="business"){
                    if (itemDetail?.detailed_rating && Object.keys(itemDetail?.detailed_rating).length) {
                        Object.keys(itemDetail?.detailed_rating).forEach(item => {                       
                            businessRatingList.push({ key: item, value: null ,isEnable:false});
                        });
                    }
                }else{
                    if (itemDetail?.total_rating && Object.keys(itemDetail?.total_rating).length) {
                        Object.keys(itemDetail?.total_rating).forEach(item => {                       
                            businessRatingList.push({ key: item, value: null ,isEnable:false});
                        });
                    }
                }
                
                this.setState({ item_id: itemDetail?.id, businessRatingList }, () => {   
                    for (let item of businessRatingList) {
                        if(item.key==="avg_rating" || item.key==="total_review") {}
                        else filteredReviewCategories.push(this.renderListItems(item));
                    }    
                    this.setState({                        
                        reviewCategoriesList: filteredReviewCategories
                    });
                });
            } else {
                if (isEditItem?.ratings_sets && Object.keys(isEditItem?.ratings_sets).length) {
                    Object.keys(isEditItem?.ratings_sets).map(item => {
                        let value = isEditItem?.ratings_sets[item];
                        businessRatingList.push({ key: item, value: value[1],isEnable:false });
                    });
                }
            }

          
        }
    }

    getSecurityHashToken = (keyName, newRating) => {
        const url = "/ratings/security_hash/"
        const { categoryItemQueryParams, avgRateKey } = this.state;
        let formDataSecurity = new FormData();

        formDataSecurity.append('object_pk', categoryItemQueryParams.object_pk);
        formDataSecurity.append('content_type', categoryItemQueryParams.content_type);
        formDataSecurity.append('key', keyName);
        formDataSecurity.append('timestamp', Date.now());
        callApi(url, 'POST', formDataSecurity).then((res) => {
            if (res.code <= 201) {
                this.setState({ ratingOn: false })
                let formDataVote = new FormData();
                formDataVote.append('csrfmiddlewaretoken', res?.csrfmiddlewaretoken);
                formDataVote.append('score', newRating);
                formDataVote.append('content_type', res?.content_type);
                formDataVote.append('object_pk', res?.object_pk);
                formDataVote.append('key', keyName);
                formDataVote.append('timestamp', res?.timestamp);
                formDataVote.append('security_hash', res?.security_hash);
                formDataVote.append('rate_unq_key', !!!avgRateKey ? res?.rate_unq_key : avgRateKey);
                this.save_ratings_vote(formDataVote, res?.rate_unq_key);
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        categoryItemQueryParams: {
                            ...prevState.categoryItemQueryParams,
                            csrfmiddlewaretoken: res?.csrfmiddlewaretoken,
                            rate_unq_key: res?.rate_unq_key,
                            security_hash: res?.security_hash,
                            timestamp: res?.timestamp
                        }
                    }
                })
            }
        });
    }
    

    updateSecurityHash = ({ id, name_modify }) => {
        let { reviewDynamicItems, doc } = this.state;
        try {

            let id_type_doc = doc.getElementById(name_modify);
            if (id_type_doc) {
                let security_hash = id_type_doc.querySelector('#id_security_hash')?.value;
                reviewDynamicItems.security_hash = security_hash;
                this.setState({ reviewDynamicItems });
            }
        } catch (err) {
            console.log({ err });
        }
    }

    renderListItems = (item, checkedArray) => {  
        return {
            checked: checkedArray?.includes(this.getVoteItemKeyId(item.key)) ? true: false,
            label: item.key,
            id:this.getVoteItemKeyId(item.key)
        }
    }

    fetchDataFromEditForm = async ({ listing_type, id, review_id }) => {

        try {
            let url = `/form/reviewedit/${listing_type}/${id}/${review_id}`;

            if (["accountants", "doctors", "dentists", "lawyers", "actors"].includes(listing_type)) {
                //url = `/form/professionaleditreview/${listing_type}/${id}/${review_id}`;
                url = `/form/reviewedit/${listing_type}/${id}/${review_id}`;
                if (this.props.isWriteFollowUp) {
                    url = `/form/professionalfollowreview/${listing_type}/${id}`;
                }
            }

            const response = await callApi(url, 'GET');
            let personOptions = '';

            if (response?.code == 200) {
                let data = response?.data;
                if (data) {
                    const docEdit = new DOMParser().parseFromString(data, "text/html");
                    if (docEdit) {
                        let listOfAllPersons = [];
                        if (listing_type == "movies") {
                            personOptions = docEdit.querySelector('[id*="follow_profession"]').innerHTML;
                            let itemOptions = docEdit.querySelector('[id*="follow_profession"]').options;
                            if (itemOptions && itemOptions?.length) {

                                for (const property in itemOptions) {
                                    let item = itemOptions[property];
                                    let value = '';

                                    if (item?.attributes && item?.attributes[0] && item?.attributes[0]?.value) {
                                        value = item?.attributes[0]?.value;
                                    }

                                    if (item?.innerText) {
                                        listOfAllPersons.push({
                                            text: item?.innerText,
                                            value
                                        });
                                    }
                                }

                                let prevPersonItems = this.props.isEditItem?.ratings_sets?.proessional_rate;
                                if (prevPersonItems) {
                                    let matchedItems = [];
                                    let chooseAPerson = {
                                        selectedItem: null,
                                        firstNameText: '',
                                        lastNameText: '',
                                        ratingPerson: {},
                                        fullNameText: {}
                                    };

                                    if (Object.keys(prevPersonItems).length) {
                                        Object.keys(prevPersonItems).forEach(item => {
                                            let filteredItem = listOfAllPersons.filter(data => {
                                                data.text = data.text.replace(/  +/g, ' ')
                                                let new_string = item.replace(/  +/g, ' ')

                                                if (data.text == new_string) {
                                                    data['rating'] = prevPersonItems[item];
                                                    return data;
                                                }
                                            });
                                            if (filteredItem) {
                                                let itemSet = filteredItem[0];
                                                matchedItems.push(itemSet);
                                            }
                                        })
                                    }
                                    if (matchedItems.length) {
                                        matchedItems.forEach(person_item => {
                                            let int_value = parseInt(person_item?.value);
                                            chooseAPerson.fullNameText[int_value] = person_item?.text;
                                            chooseAPerson.ratingPerson[int_value] = person_item?.rating[1];
                                        })
                                        this.setState({ chooseAPerson });
                                    }
                                }
                            }
                        }

                        this.setState({
                            docEdit,
                            choosePersonOptions: personOptions,
                        })
                    }
                }
            }

        } catch (err) {
            console.log({ err });
        }
    }

    updateEditSecurityHash = ({ type }) => {
        let { docEdit, uniqrate, item_id } = this.state;
        let { listing_type, } = this.props;
        let personOptions = null;
        let name_type = `review-step-1`;
        try {

            if (docEdit) {
                let elementsGet = docEdit.querySelectorAll(`[id*="${type}"]`)[0];

                if (elementsGet) {

                    let uniqrate = elementsGet.querySelector('[id="follow_uniqrate"]')?.value;
                    let security_hash = elementsGet.querySelector('#id_security_hash')?.value;
                    let csrfmiddlewaretoken = elementsGet.querySelector('[name="csrfmiddlewaretoken"]')?.value;
                    let timestamp = elementsGet.querySelector('[name="timestamp"]')?.value;
                    let honeypot = elementsGet.querySelector('[name="honeypot"]')?.value;
                    let object_pk = elementsGet.querySelector('[name="object_pk"]')?.value;
                    let content_type = elementsGet.querySelector('[name="content_type"]')?.value;

                    let reviewDynamicItems = {};
                    reviewDynamicItems.uniqrate = uniqrate;
                    reviewDynamicItems.csrfmiddlewaretoken = csrfmiddlewaretoken;
                    reviewDynamicItems.security_hash = security_hash;
                    reviewDynamicItems.timestamp = timestamp;
                    reviewDynamicItems.honeypot = honeypot;
                    reviewDynamicItems.object_pk = object_pk;
                    reviewDynamicItems.content_type = content_type;

                    if (listing_type == "movies") {
                        personOptions = docEdit.querySelector('[id*="follow_profession"]').innerHTML;
                    }

                    this.setState({
                        uniqrate,
                        reviewDynamicItems,
                        choosePersonOptions: personOptions,
                        reviewCategoriesList: []
                    });

                } else {


                    let uniqrate = docEdit.getElementById('follow_uniqrate')?.value || uniqrate;
                    let csrfmiddlewaretoken = docEdit.getElementsByName('csrfmiddlewaretoken')[0]?.value || '';
                    let timestamp = docEdit.getElementsByName('timestamp')[0]?.value || Date.now();
                    let honeypot = docEdit.getElementsByName('honeypot')[0]?.value || '';
                    let object_pk = docEdit.getElementsByName('object_pk')[0]?.value || item_id;
                    let content_type = docEdit.getElementsByName('content_type')[0]?.value || 'reviews.userentries';
                    let security_hash = docEdit.querySelector('[name="security_hash"]')?.value || '';
                    let reviewDynamicItems = {};
                    reviewDynamicItems.uniqrate = uniqrate;
                    reviewDynamicItems.csrfmiddlewaretoken = csrfmiddlewaretoken;
                    reviewDynamicItems.security_hash = security_hash;
                    reviewDynamicItems.timestamp = timestamp;
                    reviewDynamicItems.honeypot = honeypot;
                    reviewDynamicItems.object_pk = object_pk;
                    reviewDynamicItems.content_type = content_type;
                    if (listing_type == "movies") {
                        personOptions = docEdit.querySelector('[id*="follow_profession"]').innerHTML;
                    }

                    this.setState({
                        uniqrate,
                        reviewDynamicItems,
                        choosePersonOptions: personOptions,
                        reviewCategoriesList: []
                    });

                };
            }
        } catch (err) {
            console.log(err, 'errrrr')

        }
    }

  

    getRatingAnother = async ({ id }) => {
        try {
            const response = await callApi(`/api/detailsstarrating/?id=${id}`, 'GET');
            if (response?.code == 200) {
                let chartStarRatings = response;

                let { businessRatingList } = this.state;
                let itemEditSet = [];
                if (chartStarRatings !== null && Object.keys(chartStarRatings).length && itemEditSet.length == 0) {
                    businessRatingList.forEach((item) => {
                        businessRatingList[businessRatingList.findIndex(itemss => itemss.key == item.key)].value = 0
                    });

                    Object.keys(chartStarRatings).forEach((item, index) => {
                        let textItem = item.substring(0, 4);
                        let itemsSet = businessRatingList.find(it => (it.key).substring(0, 4).match(textItem));
                        if (itemsSet !== undefined) {
                            itemEditSet[index] = itemsSet;
                            itemEditSet[index]['name'] = item;
                        }
                    });

                    this.setState({ businessRatingList, itemEditSet })
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    ratingChanged = async (newRating, name, enable = false) => {
       
        let name_modify = '';
        this.VoteData = true;
        this.setState({ratingOn:true})
        
        if(name!=="avg_rating"){
            name_modify = this.getVoteItemKeyName(name)
            const tempArray = [...this.state.businessRatingList]
            const ratedCategory = tempArray.map(item=>{
                if(item.key===name){
                    return {key:name,value:newRating,isEnable:false}
                }else{
                    return {key:item.key,value:item.value,isEnable:false}
                }               
            })
            this.setState({businessRatingList:ratedCategory})
        }else{
            name_modify = name
            const tempArray = [...this.state.businessRatingList]
            let filteredReviewCategories = [];
            const ratedCategory = tempArray.map(item=>{               
                    return {key:item.key,value:false,isEnable:false} 
            })
            for (let item of ratedCategory) {
                if (item.key === "avg_rating" || item.key === "total_review") { }
                else filteredReviewCategories.push(this.renderListItems(item));
            }  
            this.setState({ avg_rating: newRating, avarageTrue: true, businessRatingList: ratedCategory, reviewCategoriesList: filteredReviewCategories })
           if(this.state.avg_rating===null){
            this.props.resetUploadedMediaSet && this.props.resetUploadedMediaSet();
            this.setState({uploadedFiles:[],embedBody:[]})
           }
          
          
        }     
        this.getSecurityHashToken(name_modify,newRating)
    }

    onHoverEnableDisable = (name,value)=>{      
            const tempArray = [...this.state.businessRatingList]
            const ratedCategory = tempArray.map(item=>{                
                    return {...item,isEnable:item.key===name ? value : false}
                 })
            this.setState({businessRatingList:ratedCategory})
    }

    checkColorTrue = (item)=>{
        const {businessRatingList} = this.state;
        return businessRatingList.filter(itm=>itm.key===item)?.[0]?.isEnable || businessRatingList.filter(itm=>itm.key===item)?.[0]?.value===0
         
    }

    getVoteItemKeyName = (name)=>{
        const matchName = Object.keys(this.props.itemDetail?.rating_key).find(key => this.props.itemDetail?.rating_key[key] === name)
        return matchName
    }

    getVoteItemKeyId = (name)=>{       
        const matchId = this.props.itemDetail?.rating_key_ids?.key_id.filter(item=>item.name===name)?.[0]?.id
        return matchId
    }

    save_ratings_vote = async (data,rateKey) => {
        let url = '/ratings/vote/';
        const {avgRateKey} = this.state
        try {
            const response = await callApi(url, 'POST', data);
            if (response?.code == 200) {
                if(response.key==="avg_rating"){
                    this.setState({voteScore:response.vote_score,avgRateKey:!!!avgRateKey ? rateKey : avgRateKey})
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    findAndSetItem = (item) => {
        let item_modify = '';
        if (item) {
            item_modify = item.replace(/\s/g, '');
            if (item_modify.includes('/')) {
                item_modify = item_modify.replace(/\//g, '')
            }
        }

        let { businessRatingList } = this.state;
        let data = businessRatingList.findIndex(data => {

            let name_modify = '';
            if (data.key) {
                name_modify = data.key.replace(/\s/g, '');
                if (name_modify.includes('/')) {
                    name_modify = name_modify.replace(/\//g, '')
                }
            }

            return name_modify == item_modify;
        });

        if (data > -1) {
            let returnValue = businessRatingList[data];
            if (returnValue?.value > -1) {
                return returnValue?.value;
            }
        }
    }

    handleChangePerson = async (e) => {
        let { chooseAPerson } = this.state;
        let { value } = e.target;
        let fullName = e.target.selectedOptions[0].text;

        if (!value || fullName.length == 0) {
            chooseAPerson['selectedItem'] = null;
            chooseAPerson['fullNameText'] = {};
            this.setState({ chooseAPerson, personFormRatings: {} });
        } else {
            chooseAPerson['selectedItem'] = value;
            chooseAPerson['fullNameText'][value] = fullName !== "Choose a Person" ? fullName : `${chooseAPerson?.firstNameText} ${chooseAPerson?.lastNameText}`;
            this.setState({ chooseAPerson });

            let formData = new FormData();

            formData.append('object_pk', value);
            formData.append('timestamp', Date.now());
            const personFormRatings = await this.fetchPersonRateSecurityHash(formData);

            if (personFormRatings) {
                this.setState({ personFormRatings });
            }
        }

    }

    fetchPersonRateSecurityHash = async (formData) => {

        const url = "/ratings/security_hash/";
        const content_type = "reviews.reviewsprofessionaluserentriesmapping";
        const key = "avg_rating";

        formData.append('content_type', content_type);
        formData.append('key', key);
        let data = {};
        try {
            let response = await callApi(url, 'post', formData);
            if (response && response.code !== 500) {

                const { code, ...res } = { ...response };
                data = res;
            }
        } catch (err) {
            console.log(err);
        }
        return data;
    }

    submitPersonRating = (personFormRatings) => {
        let { chooseAPerson, categoryItemQueryParams, avgRateKey } = this.state;
        let { isEditItem,isEditReviewEnabled } = this.props;
        if (personFormRatings) {
            const key = "avg_rating";
            const object_pk = "128434095";

            let formData = new FormData();
            formData.append('score', chooseAPerson['ratingPerson'][chooseAPerson['selectedItem']]);
            formData.append('csrfmiddlewaretoken', personFormRatings?.csrfmiddlewaretoken);
            formData.append('security_hash', personFormRatings?.security_hash);
            formData.append('rate_unq_key', isEditItem && isEditReviewEnabled ? isEditItem?.review_voting_key : avgRateKey);
            formData.append('timestamp', personFormRatings?.timestamp);
            formData.append('key', key);
            formData.append('content_type', personFormRatings?.content_type);
            formData.append('object_pk', personFormRatings?.object_pk);
            this.savePersonRatingVote(formData);
        }
    }

    savePersonRatingVote = async (data) => {
        const url = '/ratings/vote/';
        try {
            const response = await callApi(url, 'POST', data);
            if (response?.code == 200) {
                if (response.key === "avg_rating") {
                    this.setState({ personVoteScore: response.vote_score });
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    // getValuesFromElements = (elements) => {
    //     let { uniqrate, chooseAPerson, item_id } = this.state;
    //     if (elements) {
    //         let csrfmiddlewaretoken = elements.querySelector('[name="csrfmiddlewaretoken"]')?.value || '';
    //         let content_type = elements.querySelector('[name="content_type"]')?.value || 'reviews.userentries';
    //         let object_pk = elements.querySelector('[name="object_pk"]')?.value || item_id;
    //         let timestamp = elements.querySelector('[name="timestamp"]')?.value || Date.now();
    //         let security_hash = elements.querySelector('[name="security_hash"]')?.value || '';
    //         let honeypot = elements.querySelector('[name="honeypot"]')?.value || '';
    //         let rate_unq_key = elements.querySelector('[name="uniqrate"]')?.value || uniqrate;

    //         let formData = new FormData();
    //         formData.append('csrfmiddlewaretoken', csrfmiddlewaretoken);
    //         formData.append('score', chooseAPerson['ratingPerson'][chooseAPerson['selectedItem']]);
    //         formData.append('content_type', content_type);
    //         formData.append('object_pk', object_pk);
    //         formData.append('key', 'avg_rating');
    //         formData.append('timestamp', timestamp);
    //         formData.append('security_hash', security_hash);
    //         formData.append('honeypot', honeypot);
    //         formData.append('rate_unq_key', rate_unq_key);

    //         return formData;
    //     }
    // }

    professionalHandleChangeRating = async () => {
        let { chooseAPerson } = this.state;

        const object_pk = "128434095";
        let formData = new FormData();
        formData.append('object_pk', chooseAPerson['selectedItem'] ? chooseAPerson['selectedItem'] : object_pk);
        formData.append('timestamp', Date.now());

        const personRatingSecurityHash = await this.fetchPersonRateSecurityHash(formData);
        
        if (personRatingSecurityHash) {
            this.submitPersonRating(personRatingSecurityHash);
        }
        
        
    }

    addUploadedVideo = (item) => {
        let { stepTwoUploadedVideo } = this.state;
        stepTwoUploadedVideo.push(item);
        this.setState({ stepTwoUploadedVideo });
    }  
    
    removeUploadedVideo = () =>{
        this.setState({ stepTwoUploadedVideo : []});
    }

    removeFromUploaded = (index,item) => {          
        const newReviewMediaSetFinalizedCopy  = [...this.props.newReviewMediaSetFinalized]
        let removedMediaItemCopy = [...this.state.removedMediaItem]
        removedMediaItemCopy.push(item?.id)
        const uniqueMediaId = [...new Set(removedMediaItemCopy)]
        if (index > -1) {
            newReviewMediaSetFinalizedCopy.splice(index, 1);
        }
        this.setState({removedMediaItem:uniqueMediaId });
        this.props.finalizedMediaInReviewMediaSetForNewReview(newReviewMediaSetFinalizedCopy);
    }
    removeFromUploadedEdit = (index,item) => {  
     
        const editReviewMediaSetFinalizedCopy  = [...this.props.editReviewMediaSetFinalized]
        let removedMediaItemCopy = [...this.state.removedMediaItem]
        removedMediaItemCopy.push(item?.id)
        const uniqueMediaId = [...new Set(removedMediaItemCopy)]
        if (index > -1) {
            editReviewMediaSetFinalizedCopy.splice(index, 1);
        }      
        this.setState({removedMediaItem:uniqueMediaId });     
        this.props.finalizedMediaInReviewMediaSetForEditReview(editReviewMediaSetFinalizedCopy);
    }

    removeEmbeddedFromUploaded = (index,item) => {              
        
        const newReviewEmbedLinkSetFinalizedCopy  = _.cloneDeep( this.props.newReviewEmbedLinkSetFinalized )
         if (index > -1) {
            newReviewEmbedLinkSetFinalizedCopy.splice(index, 1);
        }
        this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(newReviewEmbedLinkSetFinalizedCopy)
    }

    removeEmbeddedFromUploadedEdit = (index,item) => {           
      
        const editReviewEmbedLinkSetFinalizedCopy  = _.cloneDeep( this.props.editReviewEmbedLinkSetFinalized )
         if (index > -1) {
            editReviewEmbedLinkSetFinalizedCopy.splice(index, 1);
        }
        this.props.finalizedMediaInReviewEmbedLinkSetForEditReview(editReviewEmbedLinkSetFinalizedCopy)
    }

  

    handelChangeReview = () => { this.setState({ isAdhere: !this.state.isAdhere }) }

    handleSubmitProfessionalReview = async (is_draft) => {
        let { listing_type, itemDetail, isEditItem, item ,newReviewMediaSetFinalized,newReviewEmbedLinkSetFinalized,editReviewMediaSetFinalized } = this.props;
        let { categoryItemQueryParams,isEditReviewEnable,item_id, uniqrate, chooseAPerson, isMaskReview, writeAReviewText,reviewCategoriesList, writeAReviewTextVideo, uploadedFiles, stepTwoUploadedVideo, reviewCategories, businessRatingList,removedMediaItem } = this.state;
        let mediaSetDataItems = [];
        

        

        if (stepTwoUploadedVideo?.length) {
            let itemsGet = stepTwoUploadedVideo.map(data => data.id);
            mediaSetDataItems = mediaSetDataItems.concat(itemsGet);
        }

        const review_rating_category = reviewCategoriesList.map(item=>{
            if(item.checked){
                return item.id
            }else{
                return null
            }
        })


        if (!isEditReviewEnable) {
            let data = {};
            if (newReviewMediaSetFinalized?.length) {
                mediaSetDataItems = [...mediaSetDataItems, ...newReviewMediaSetFinalized.map(i => i["id"])];
            }
            if (checkIfWriteFollowUp) {
                data = {
                    "professionalreview": !!writeAReviewTextVideo.length ? writeAReviewTextVideo : writeAReviewText,
                    "media": mediaSetDataItems,
                    "is_draft_p": is_draft ? true : false,
                    "associated_to": categoryItemQueryParams.object_pk,
                    "review_rating_category": review_rating_category.filter(Boolean),
                    "review_voting_key": this.state.avgRateKey,
                    "followup": "true",
                    "social_broadcast": true,
                    "social_broadcast_twitter": true,
                    "social_broadcast_facebook": true,
                    "followup_avg_rate": this.state.voteScore,
                    "parent_review_id": item?.id,
                    "tips_guide": true
                }
            } else {
                data = {
                    "professionalreview": !!writeAReviewTextVideo.length ? writeAReviewTextVideo : writeAReviewText,
                    "media": mediaSetDataItems,
                    "is_draft_p": is_draft ? true : false,
                    "associated_to": categoryItemQueryParams.object_pk, // [professional - listing]
                    "review_rating_category": review_rating_category.filter(Boolean),
                    "review_voting_key": this.state.avgRateKey,
                    "followup": "false",
                    "social_broadcast_twitter": false,
                    "social_broadcast_facebook": false,
                    "followup_avg_rate": false,
                    "avg_rate_val": this.state.voteScore,
                    "tips_guide": true
                }
            }
            if(this.props.type==="ownReview"){
                delete data.parent_review_id
            }
            if (newReviewEmbedLinkSetFinalized?.length) {
                data['embed_body'] = newReviewEmbedLinkSetFinalized.map(item=>{
                    return {
                        url: item.url,
                        thumbnail:item.thumbnail
                    }
                });                
            }else{
                data['embed_body']=[]
            }   
            if (listing_type == "movies") {
                data["professional"] = chooseAPerson?.selectedItem | ''; // [Linda Hamilton - id]
                data["first_name"] = chooseAPerson?.firstNameText || "";
                data["last_name"] = chooseAPerson?.lastNameText || "";
                data["is_masked"] = isMaskReview;
                data["recommendPerson"] = chooseAPerson?.selectedItem | '';
            }
            let successMessage = is_draft ? 'You saved review as a draft for' : 'You wrote a review of';
            let response = await callApi(`/api/professionalreviews/?associated_to__id=&is_active=&searchTerm=`, "POST", data);
            if (response) {
                toast(`${successMessage} ${itemDetail?.name || `${itemDetail?.firstname} ${itemDetail?.lastname}`}`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });

                this.resetStates(true);
                if (this.props?.onPublishReview) {
                    this.props.onPublishReview();
                }
                const randomNo = Math.random()
                this.props.rating_created_dispatch(randomNo)
            }
        } else {
            let updatedRatings = {};
            if (businessRatingList && Array.isArray(businessRatingList) && businessRatingList.length) {
                businessRatingList.map(item => {
                    updatedRatings[item.key] = item.value;
                })
            }
            if (editReviewMediaSetFinalized?.length) {
                mediaSetDataItems = [...mediaSetDataItems, ...editReviewMediaSetFinalized.map(i => i["id"])];
            }
            let data = {
                professionalreview: writeAReviewText,
                media: mediaSetDataItems,
                review_rating_category: review_rating_category.filter(Boolean),
                is_draft_p: is_draft ? true : false,
                associated_with: categoryItemQueryParams.object_pk
            }
            if (newReviewEmbedLinkSetFinalized?.length) {
                data['embed_body'] = newReviewEmbedLinkSetFinalized.map(item=>{
                    return {
                        url: item.url,
                        thumbnail:item.thumbnail
                    }
                });                
            } else{
                data['embed_body']=[]
            }  
            if (listing_type == "movies") {
                // if (Object.keys(updatedRatings).length) {
                //     data['ratings_set'] = updatedRatings;
                // }
                data['is_masked'] = isMaskReview;
                data['professional'] = chooseAPerson?.selectedItem || ''; ;
                data['first_name'] = chooseAPerson['firstNameText'];
                data['last_name'] = chooseAPerson['lastNameText'];
            }

            let response = await callApi(`/api/professionalreviews/${isEditItem?.id}/`, "PUT", data);
            if (response) {
                if (this.props?.closeToggleEditEnable) {
                    this.props?.closeToggleEditEnable();
                }
                if(removedMediaItem.length){
                    await callApi('/upload/multiuploader_delete_multiple/?delete_videos=True','POST',removedMediaItem)
                }
                toast(`You wrote a review of ${itemDetail?.name || `${itemDetail?.firstname} ${itemDetail?.lastname}`}`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });               
                this.resetStates(true);
                
                this.props.setEditExistingMedia([])
                this.props.setEditExistingEmbedMedia([])
                if (this.props?.onPublishReview) {
                    this.props.onPublishReview();
                }
                const randomNo = Math.random()
                this.props.rating_created_dispatch(randomNo)
            }
        }
        this.props.finalizedMediaInReviewMediaSetForNewReview([]);
        this.props.finalizedMediaInReviewEmbedLinkSetForNewReview([]);
        this.props.uploadMediaInReviewMediaSetForNewReview([]);
        this.props.setFollowReviewToggler(false);
        // try {
        //     let data = {
        //         "professionalreview": writeAReviewText,
        //         "media": mediaSetDataItems,
        //         "is_draft": is_draft ? true : false,
        //         "associated_to": item_id,
        //         "tips_guide": true,
        //         "review_voting_key": uniqrate,
        //         "review_rating_category": review_rating_category,
        //         "followup": "false",
        //         "avg_rate_val": businessRatingList['avg_rating'] || 0
        //     };

        //     let response = await callApi(`/api/professionalreviews/?associated_to__id=&is_active=&filter=`, "POST", data);
        //     if (response) {
        //         toast(`You wrote a review of ${itemDetail?.name || `${itemDetail?.firstname} ${itemDetail?.lastname}`}`, {
        //             autoClose: 3500,
        //             className: "black-background",
        //             bodyClassName: "red-hunt",
        //             progressClassName: "cc",
        //         });

        //         this.resetStates();
        //         if (this.props?.onPublishReview) {
        //             this.props.onPublishReview();
        //         }
        //     }
        // } catch (err) {
        //     console.log(err);
        // }
    }

    // AddNewEmbedLink = (e) => {
    //     e.preventDefault();
    //     let { embeddedLink, embedBody } = this.state;
    //     this.props.embedNewLink({
    //         data: {
    //             "video": `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
    //             "exclude_list": [],
    //             "body_preview": `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
    //             "question_rating_category": [],
    //             "messagemedia_set": []
    //         }
    //     });
    //     embedBody.push(embeddedLink);
    //     this.setState({ embeddedLink: '', embedBody, uploadMediaModal: false, createNewDisModalToggle: false })
    // }



    handleSubmitReview = async (is_draft) => {
        let errors = { ...this.state.errors };

        let { listing_type, itemDetail, isEditItem, item,newReviewMediaSetFinalized,newReviewEmbedLinkSetFinalized,editReviewMediaSetFinalized } = this.props;
        let { categoryItemQueryParams,isEditReviewEnable,embedBody, item_id, uniqrate, chooseAPerson, isMaskReview, writeAReviewText, writeAReviewTextVideo, uploadedFiles, stepTwoUploadedVideo, reviewCategories, businessRatingList, is_anonymous, shocialNetworkSharing,reviewCategoriesList,removedMediaItem } = this.state;
        let mediaSetDataItems = [];
        

        if (stepTwoUploadedVideo?.length) {
            let itemsGet = stepTwoUploadedVideo.map(data => data.id);
            mediaSetDataItems = mediaSetDataItems.concat(itemsGet);
        } else {
            if (!this.state.writeAReviewText?.length && !isEditReviewEnable) {
                errors.writeAReviewText = 'This field is required!';
                this.setState({ errors }, () => {
                    console.log({ errors }, '990')
                })
                return
            }
            else {
                delete errors.writeAReviewText;
            }

        }

        const review_rating_category = reviewCategoriesList.map(item=>{
            if(item.checked){
                return item.id
            }else{
                return null
            }
        })


        if (!isEditReviewEnable) {
            let data = {};
            var avg_rate;
            if (this.VoteData) {
                avg_rate = false;
            } else {
                avg_rate = "4.5";
            }
            if (newReviewMediaSetFinalized?.length) {
                mediaSetDataItems = [...mediaSetDataItems, ...newReviewMediaSetFinalized.map(i => i["id"])];
            }
            if (checkIfWriteFollowUp) {
                data = {
                    "review": !!writeAReviewTextVideo.length ? writeAReviewTextVideo : writeAReviewText,
                    // "optional_video_text" :writeAReviewTextVideo,
                    "is_masked": true,
                    "media": mediaSetDataItems,
                    "is_draft": is_draft ? true : false,
                    "associated_to": categoryItemQueryParams.object_pk,
                    "is_anonymous": is_anonymous,
                    "review_rating_category": review_rating_category.filter(Boolean),
                    "review_voting_key": this.state.avgRateKey,
                    "followup": "true",
                    "social_broadcast": shocialNetworkSharing == true ? true : false,
                    "social_broadcast_twitter": shocialNetworkSharing == true ? true : false,
                    "social_broadcast_facebook": shocialNetworkSharing == true ? true : false,
                    // "followup_avg_rate": '4.5',
                    "followup_avg_rate": avg_rate,

                    "parent_review_id": item?.id,
                    "tips_guide": true
                }
            } else {

                data = {
                    "review": !!writeAReviewTextVideo.length ? writeAReviewTextVideo : writeAReviewText,
                    //  "optional_video_text" :writeAReviewTextVideo,
                    "media": mediaSetDataItems,
                    "is_draft": is_draft ? true : false,
                    "associated_to": categoryItemQueryParams.object_pk, // [professional - listing]
                    "review_rating_category": review_rating_category.filter(Boolean),
                    "review_voting_key": this.state.avgRateKey,
                    "followup": "false",
                    'is_anonymous': is_anonymous,
                    "social_broadcast_twitter": shocialNetworkSharing == true ? true : false,
                    "social_broadcast_facebook": shocialNetworkSharing == true ? true : false,
                    "followup_avg_rate": shocialNetworkSharing == true ? true : false,
                    "avg_rate_val": this.state.voteScore,
                    "tips_guide": true,                    
                }
            }

            if(this.props.type==="ownReview"){
                delete data.parent_review_id
            }
            
            if (newReviewEmbedLinkSetFinalized?.length) {
                data['embed_body'] = newReviewEmbedLinkSetFinalized.map(item=>{
                    return {
                        url: item.url,
                        thumbnail:item.thumbnail
                    }
                });                
            }else{
                data['embed_body']=[]
            }            
            if (listing_type == "movies") {
                data["professional"] = chooseAPerson?.selectedItem | ''; // [Linda Hamilton - id]
                data["first_name"] = chooseAPerson?.firstNameText || "";
                data["last_name"] = chooseAPerson?.lastNameText || "";
                data["is_masked"] = isMaskReview;
                data["recommendPerson"] = chooseAPerson?.selectedItem | '';
            }
            let successMessage = is_draft ? 'You saved review as a draft for' : 'You wrote a review of';

            let response = await callApi(`/api/reviewslisting/?entry=&user=&asset=`, "POST", data);
           
            if (response.code <= 201) {
                toast(`${successMessage} ${itemDetail?.name || `${itemDetail?.firstname} ${itemDetail?.lastname}`}`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                const randomNo = Math.random()
                this.props.rating_created_dispatch(randomNo)
                this.resetStates(true);
                if (this.props?.onPublishReview) {
                    this.props.onPublishReview();
                }
                checkIfEditEnable=false;
            }

            if (response.code == 400 && listing_type == "movies") {
                const invalidData = response.data;
                this.setState({ invalidData })
            }
        }
         else {
            let updatedRatings = {};
            if (businessRatingList && Array.isArray(businessRatingList) && businessRatingList.length) {
                businessRatingList.map(item => {
                    updatedRatings[item.key] = item.value;
                })
            }
            if (editReviewMediaSetFinalized?.length) {
                mediaSetDataItems = [...mediaSetDataItems, ...editReviewMediaSetFinalized.map(i => i["id"])];
            }
            let data = {
                review: writeAReviewText,
                optional_video_text: writeAReviewTextVideo,
                media: mediaSetDataItems,
                review_rating_category: review_rating_category.filter(Boolean),
                is_draft: is_draft ? true : false,
                associated_with: categoryItemQueryParams.object_pk,
                embeded_video:newReviewEmbedLinkSetFinalized,
            }
            if (newReviewEmbedLinkSetFinalized?.length) {
                data['embed_body'] = newReviewEmbedLinkSetFinalized.map(item=>{
                    return {
                        url: item.url,
                        thumbnail:item.thumbnail
                    }
                });                
            } else{
                data['embed_body']=[]
            }  

            if (listing_type == "movies") {
                // if (Object.keys(updatedRatings).length) {
                //     data['ratings_set'] = updatedRatings;
                // }
                data['is_masked'] = isMaskReview;
                data['professional'] = chooseAPerson?.selectedItem || '';
                data['first_name'] = chooseAPerson['firstNameText'];
                data['last_name'] = chooseAPerson['lastNameText'];
            }

            let response = await callApi(`/api/reviews/${isEditItem?.id}/`, "PUT", data);
            if (response.code <= 201) {
                if (this.props?.closeToggleEditEnable) {
                    this.props?.closeToggleEditEnable();
                }
                if(removedMediaItem.length){
                    await callApi('/upload/multiuploader_delete_multiple/?delete_videos=True','POST',removedMediaItem)
                }
                toast(`You Edited a review of ${itemDetail?.name || `${itemDetail?.firstname} ${itemDetail?.lastname}`}`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                const randomNo = Math.random()
                this.props.rating_created_dispatch(randomNo)
                this.resetStates();
               
                this.props.setEditExistingMedia([])
                this.props.setEditExistingEmbedMedia([])
                // if (!isEditItem) {
                    if (itemDetail?.total_rating && Object.keys(itemDetail?.total_rating).length) {
                        Object.keys(itemDetail?.total_rating).forEach(item => {
                            businessRatingList.push({ key: item, value: null,isEnable:false });
                        });
                    }
                // } else {
                    // if (isEditItem?.ratings_sets && Object.keys(isEditItem?.ratings_sets).length) {
                    //     Object.keys(isEditItem?.ratings_sets).map(item => {
                    //         let value = isEditItem?.ratings_sets[item];
                    //         businessRatingList.push({ key: item, value: value[1] });
                    //     });
                    // }
                // }
                checkIfEditEnable=false;
                this.setState({businessRatingList });
                if (this.props?.onPublishReview) {
                    this.props.onPublishReview();
                }
            }else{
                toast(`Something went wrong, Please try again`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                if (this.props?.closeToggleEditEnable) {
                    this.props?.closeToggleEditEnable();
                }
            }
        }
        this.props.finalizedMediaInReviewMediaSetForNewReview([]);
        this.props.finalizedMediaInReviewEmbedLinkSetForNewReview([]);
        this.props.uploadMediaInReviewMediaSetForNewReview([]);
        this.props.setFollowReviewToggler(false);
    }
    handelChangeSocialNetwork = (e) => {
        this.setState({
            [e.target.name]:
                e.target.type === "checkbox" ? e.target.checked : e.target.value,
            is_anonymous: false
        });
    };

    handleChangeChooseAPerson = (event, type) => {
        let chooseAPerson = { ...this.state.chooseAPerson };
        chooseAPerson[type] = event.target.value;
        if (chooseAPerson[type].length <= 30) {
            this.setState({ chooseAPerson });
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const { my_profile_data } = this.props;
        if (my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)) {
            return true;
        } else {
            return false;
        }
    }

    isPublishDisable = () => {
        const { uploadedFiles, writeAReviewText, stepTwoUploadedVideo,writeAReviewTextVideo ,viewEditReviewStep, isAdhere} = this.state
        
        if(this.props.editReviewMediaSetFinalized?.length > 0) return false
        if (!isAdhere) return true 
        if (writeAReviewText && writeAReviewText?.length < 50 && viewEditReviewStep !== "recordVideo" )
            return true                   
        else if (writeAReviewText && writeAReviewText?.length > 50 && viewEditReviewStep !== "recordVideo") 
            return false           
        else if (Array.isArray(stepTwoUploadedVideo) && stepTwoUploadedVideo.length > 0)
            return false
       
        return true
    }

    isPublishDisableDraft = () => {
        const { uploadedFiles, writeAReviewText, stepTwoUploadedVideo,viewEditReviewStep, isAdhere } = this.state
        if(this.props.editReviewMediaSetFinalized?.length > 0) return false
        if (!isAdhere) return true 
        if (writeAReviewText && writeAReviewText?.length < 50 && viewEditReviewStep !== "recordVideo")
            return true
        else if (writeAReviewText && writeAReviewText?.length > 50 && viewEditReviewStep !== "recordVideo")
            return false        
        else if (Array.isArray(stepTwoUploadedVideo) && stepTwoUploadedVideo.length > 0)
            return false      
        

        return true
    }

    reviewCategoryHandler = (id)=>{
        const tempArr = [...this.state.reviewCategoriesList]
        const currentIndex = tempArr.findIndex(item=>item.id===id)
        tempArr[currentIndex]["checked"] = !tempArr[currentIndex]["checked"];
        this.setState({reviewCategoriesList:tempArr})
    }

    renderChoosePersonOptions = () => {
        const { chooseAPersonOptions, chooseAPerson } = this.state;
        return (
            <>
                <option value="">Choose a person </option>
                {Array.isArray(chooseAPersonOptions) && chooseAPersonOptions.length && chooseAPersonOptions.map((person) => (
                    <option value={person?.sr_no} selected={chooseAPerson['selectedItem'] == person?.sr_no ? true : false}>{person?.first_name + ' ' + person?.last_name}</option>
                ))}
            </>
        )
    }

    handleChangePersonRating = (value) => {
        let { chooseAPerson, personFormRatings } = this.state;
        chooseAPerson['ratingPerson'][chooseAPerson['selectedItem']] = value;

        this.setState({ chooseAPerson, enableHoverPersonRating: false }, async () => {
            if (chooseAPerson['selectedItem'] && !personFormRatings) {
                let formData = new FormData();
                formData.append('object_pk', chooseAPerson['selectedItem']);
                formData.append('timestamp', Date.now());
                const personFormRatings = await this.fetchPersonRateSecurityHash(formData);

                if (personFormRatings) {
                    this.setState({ personFormRatings });
                    this.submitPersonRating(personFormRatings);
                }
            }
            else if (personFormRatings && Object.keys(personFormRatings).length) {
                this.submitPersonRating(personFormRatings);
            } else {
                this.professionalHandleChangeRating();
            }
        });
    }

    render() {
        const { itemDetail, toggleEnableFileUpload, listing_type, reviews, my_review_count,reviewType,newReviewMediaSetFinalized,newReviewEmbedLinkSetFinalized ,editReviewMediaSetFinalized,editReviewEmbedLinkSetFinalized} = this.props;
        const { myProfile,businessRatingList,ratingOn,isEditReviewEnable } = this.state
        const userFirst = myProfile?.user?.first_name
        const userLast = myProfile?.user?.last_name
        const firstPrefix = userFirst ? (userFirst?.charAt(0)?.toUpperCase() + userFirst?.slice(1)) : ""
        const secondPrefix = userLast ? (" " + userLast?.charAt(0)?.toUpperCase() + userLast?.slice(1)) : ""
        const socialSharePrefix = (firstPrefix || secondPrefix) ? (firstPrefix + secondPrefix + "'s review of ") : "Review of "
        const shareText = socialSharePrefix + itemDetail?.name + " on WikiReviews.com "; 
        let {
            avarageTrue, isHiddenReviewTipsAndGuide,
            chooseAPerson, enableHoverPersonRating,
            enableHoverBanIcon, isMaskReview,
            writeAReviewText, writeAReviewTextVideo, reviewCategories,
            viewEditReviewStep, uploadedFiles,
            isAdhere, choosePersonOptions, reviewCategoriesList, errors, embedBody } = this.state;

        return (
            <div className="mb-3">
                {/* <Helmet>
                    <meta property="og:image" content={`${itemDetail ? itemDetail?.media_dict?.[0]?.thumbnail : itemDetail?.media_dict?.[0]?.url}`} />
                    <meta property="og:image:width" content="400" />
                    <meta property="og:image:height" content="350" />
                    <meta property="og:type" content="" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:site_name" content="staging.wikireviews.com" />

                    <meta name="twitter:title" content="Trusted Reviews and Recommendations from Your Community" />
                    <meta name="twitter:description" content="Trusted Reviews and Recommendations from Your Community" />
                    <meta name="twitter:image" content={`${itemDetail ? itemDetail?.media_dict?.[0]?.thumbnail : itemDetail?.media_dict?.[0]?.url}`} />
                    <meta name="twitter:card" content="staging.wikireviews.com" />
                    <meta name="twitter:site" content="https://staging.wikireviews.com/" />
                    <meta name="twitter:creator" content="@WikiReviews" />
                </Helmet> */}
                {/* <HelmetMetaData
                    image={`${itemDetail ? itemDetail?.media_dict?.[0]?.thumbnail : itemDetail?.media_dict?.[0]?.url}`} 
                    title={`${itemDetail?.name || ""}`} description={shareText}/>                                  */}
                {/* write your own review, Part 1 */}
                <div className="bg-white p-3" hidden={!avarageTrue ? false : true}>
                    <div className="d-flex flex-nowrap">
                        {reviews && Array.isArray(reviews) && reviews?.length ? (
                            <React.Fragment>
                                <div className="flex-grow-1">
                                    <h2 className="text-primary mb-3">{'Start your review of'}
                                        {' '}
                                        {itemDetail?.firstname !== undefined ?
                                            `${itemDetail?.firstname} ${itemDetail?.lastname}`
                                            : itemDetail?.name !== undefined ? itemDetail?.name : ''}
                                    </h2>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="flex-grow-1">
                                    <h2 className="text-primary mb-3">{'Start your review of'}
                                        {' '}{itemDetail?.firstname !== undefined ?
                                            `${itemDetail?.firstname || ''} ${itemDetail?.lastname || ''}`
                                            : itemDetail?.name !== undefined ? itemDetail?.name : ''}
                                    </h2>
                                    <div className="text-dark font-weight-bold text-nowrap f-14 mr-3" style={{ fontSize: '16px' }}>
                                        <strong>{'Please share your experience.'}</strong>
                                    </div>
                                    <div className="text-dark mr-3">
                                        {/* <div className="text-dark text-nowrap mr-3"> */}
                                        <p>{'Be the first to write a review about this listing and you will receive your First to Review badge!'}</p>
                                    </div>
                                </div>
                                <div className="text-dark font-weight-bold text-nowrap mr-3">
                                    <div className="ml-auto col-auto px-0">
                                        <img className="img-fluid" src={require("../../assets/images/icons/first-to-review.png")} alt="" />
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    <div className="mt-3">
                        <h6 className="ff-base font-weight-bold text-primary">
                            {'Step 1. Star Ratings'}
                        </h6>
                        <hr className="bg-light mt-2" />
                        <p className="text-muted fs-14">
                            {'Roll over the stars and click to rate.'}
                        </p>

                        {/* Star Input */}
                        <div className="d-flex align-items-center">
                            <div className='mt-2'>
                                <Label className={`text-dark font-weight-bold text-nowrap museo-sans-900 mr-3 ${reviewType === "categoryPage" ? "fs-14" : "fs-16"}`}>
                                    {'Overall Rating*'}
                                </Label>
                            </div>
                            <div>
                                <div className="stars-rating-input _lg">
                                    <span
                                        onMouseEnter={() => { this.setState({ enableRatingText: 0, enableHoverBanIcon: true }) }}
                                        onMouseLeave={() => { this.setState({ enableRatingText: null, enableHoverBanIcon: false }) }}
                                        className="zero-rating-input"
                                        role="button">
                                        <FontAwesomeIcon
                                            icon="ban"
                                            onClick={() => this.ratingChanged(0, 'avg_rating', true)}
                                            color={(enableHoverBanIcon || this.state.avg_rating === 0) ? '#15c3d4' : '#d7d8e2'}
                                        />
                                    </span>
                                    <span title={this.state.enableRatingText}>
                                        <Rating
                                            fractions={2}
                                            emptySymbol={empty_star_rating}
                                            fullSymbol={full_star_rating}
                                            onHover={(rate) => {
                                                if (enableHoverBanIcon) {
                                                    this.setState({ enableHoverBanIcon: false });
                                                }
                                                if (rate !== this.state.enableRatingText) {
                                                    this.setState({ enableRatingText: rate })
                                                }
                                            }}
                                            readonly={ratingOn}
                                            initialRating={this.state.avg_rating}
                                            onChange={(newRating) => this.ratingChanged(newRating, 'avg_rating', true)}
                                        />
                                    </span>

                                    <span className="label-dynamic-rating">
                                        {(this.state.enableRatingText == 0) && 'Oh, Hell No!'}
                                        {(this.state.enableRatingText == 0.5 || this.state.enableRatingText == 1) && 'Definitely would not recommend!'}
                                        {(this.state.enableRatingText == 1.5 || this.state.enableRatingText == 2) && 'It could have been a lot better!'}
                                        {(this.state.enableRatingText == 2.5 || this.state.enableRatingText == 3) && 'Okay!'}
                                        {(this.state.enableRatingText == 3.5 || this.state.enableRatingText == 4) && 'Very good!'}
                                        {(this.state.enableRatingText == 4.5 || this.state.enableRatingText == 5) && "Can't get any better than this!"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* write your own review, Part 2 replace true with avarageTrue*/}
                <Collapse className="_review" isOpen={avarageTrue}>
                    <div className="py-3">

                        {
                            this.props?.feed
                                ?
                                <h2 className="text-dark fs-36">You’re reviewing <span className="text-darker">{itemDetail?.name || `${itemDetail?.firstname || ""} ${itemDetail?.lastname || ""}`}</span></h2>
                                :
                                <>
                                    <h2 className={`text-dark fs-36`}>{'You’re reviewing '}
                                        <span className={listing_type == "movies" ? "text-white" : this.state.isEditReviewEnable ? "text-white" : "text-darker"}>
                                            {itemDetail?.name || `${itemDetail?.firstname || ""} ${itemDetail?.lastname || ""}`}
                                        </span>
                                    </h2>
                                </>


                        }
                    </div>

                    {/* Step 1 Collapse */}
                    <div className="bg-white mb-3">
                        <CollapseBasic
                            title="Step 1. Star Ratings"
                            bodyClass="text-dark"
                            containerClass="type-tertiary type-review bg-transparent"
                            isOpen={true}
                            size="sm"
                            noPadding={true}
                            isReviewCollapse={true}
                            >

                            <p className="text-tertiary fs-14">
                                {'Roll over the stars and click to rate.'}
                                <span
                                    onClick={() => { this.setState({ isHiddenReviewTipsAndGuide: !this.state.isHiddenReviewTipsAndGuide }) }}
                                    className="text-dark ff-alt cursor-pointer ">
                                    {' Review Tips & Guidelines.'}
                                </span>
                            </p>

                            {/* Review Tips & Guidelines */}
                            {!this.state.isHiddenReviewTipsAndGuide && <div id="reviewTipsAndGuideLines" className="mb-3" >
                                <div className="text-right">
                                    <Button
                                        onClick={() => { this.setState({ isHiddenReviewTipsAndGuide: !this.state.isHiddenReviewTipsAndGuide }) }}
                                        color="tertiary"
                                        className="text-white"
                                        size="sm">
                                        <FontAwesomeIcon icon="times" />
                                    </Button>
                                </div>
                                <div className="bg-tertiary text-white p-2 fs-14">
                                    <ul className="list-normal mb-0">
                                        <li className="ff-base mb-2">{'Do not post photos or videos of other copyrighted material that you don’t have permission to use. Do not post photos of people without their permission. If they are children, ask the parents before you post.'}</li>
                                        <li className="ff-base mb-2">{"Opinions are almost always protected as free speech except in the case of defamation (a false accusation of an offense or a malicious misrepresentation of someone's words or actions), true threats, solicitations to commit crimes, incitement to imminent lawless action, blackmail, fighting words and obscenity)."}</li>
                                        <li className="ff-base mb-2">{"Please omit excessive exaggeration and do not lie."}</li>
                                        <li className="ff-base mb-2">{"Deliberately sabotaging the viability of a business is strongly discouraged."}</li>
                                        <li className="ff-base mb-2">{"Be honest and truthful in your reviews."}</li>
                                        <li className="ff-base mb-2">{"Please be accurate, avoid embellishment and broad generalizations, and to relate only to your first hand experiences"}.</li>
                                    </ul>
                                </div>
                            </div> }
                            

                            {/* Star Input */}

                            <div className="d-flex align-items-center">
                            <div className='mt-2'>
                                <Label className={`text-dark font-weight-bold text-nowrap mr-3 ${reviewType === "categoryPage" ? "fs-14" : "fs-16"}`}>
                                    {'Overall Rating*'}
                                </Label>
                            </div>                                
                                <div>
                                    <div className="stars-rating-input _lg">
                                        <span
                                            onMouseEnter={() => { this.setState({ enableRatingText: 0, enableHoverBanIcon: true }) }}
                                            onMouseLeave={() => { this.setState({ enableRatingText: null, enableHoverBanIcon: false }) }}
                                            className="zero-rating-input"
                                            role="button">
                                            <FontAwesomeIcon
                                                icon="ban"
                                                onClick={() => this.ratingChanged(0, 'avg_rating', true)}
                                                color={(enableHoverBanIcon || this.state.avg_rating===0) ? '#15c3d4' : '#d7d8e2'}
                                            />
                                        </span>

                                        <span title={this.state.enableRatingText}>
                                            <Rating
                                                fractions={2}
                                                emptySymbol={empty_star_rating}
                                                fullSymbol={full_star_rating}
                                                onHover={(rate) => {
                                                    if (enableHoverBanIcon) {
                                                        this.setState({ enableHoverBanIcon: false });
                                                    }

                                                    if (rate !== this.state.enableRatingText) {
                                                        this.setState({ enableRatingText: rate })
                                                    }
                                                }}
                                                readonly={ratingOn}
                                                initialRating={this.state.avg_rating}
                                                onChange={(newRating) => {
                                                    this.ratingChanged(newRating, 'avg_rating', true)
                                                }}
                                            />
                                        </span>

                                        <span className="label-dynamic-rating">
                                            {(this.state.enableRatingText == 0) && 'Oh, Hell No!'}
                                            {(this.state.enableRatingText == 0.5 || this.state.enableRatingText == 1) && 'Definitely would not recommend!'}
                                            {(this.state.enableRatingText == 1.5 || this.state.enableRatingText == 2) && 'It could have been a lot better!'}
                                            {(this.state.enableRatingText == 2.5 || this.state.enableRatingText == 3) && 'Okay!'}
                                            {(this.state.enableRatingText == 3.5 || this.state.enableRatingText == 4) && 'Very good!'}
                                            {(this.state.enableRatingText == 4.5 || this.state.enableRatingText == 5) && "Can't get any better than this!"}
                                        </span>
                                    </div>

                                </div>
                            </div>

                            <div className="mt-2">
                                <div className="text-tertiary">
                                    <span className="font-weight-bold">
                                        {'Detailed Ratings'}
                                    </span> {"(optional)"}
                                </div>
                                <hr className="bg-light" />

                                <Row
                                    // hidden={this.state.reviewDynamicItems !== null ? false : true}
                                    className="fs-14 ff-alt">                                   
                                    {/* {itemDetail?.detailed_rating && Object.keys(itemDetail?.detailed_rating).length ?
                                        Object.keys(itemDetail?.detailed_rating).map(item => (
                                            (item !== "total_review" && item !== "avg_rating" && item !== "rating_count") ?
                                                (<Col className="mb-2" md={6} lg={reviewType==="categoryPage" ? 6 : 4}>
                                                    <div>{item}</div>
                                                    <div className="stars-rating-input">
                                                        <span className="zero-rating-input" role="button">
                                                        <FontAwesomeIcon
                                                                icon="ban"
                                                                color={this.checkColorTrue(item) ? '#15c3d4' : '#d7d8e2'}
                                                                onMouseEnter={()=>this.onHoverEnableDisable(item,true)}
                                                                onMouseLeave={()=>this.onHoverEnableDisable(item,false)}
                                                                onClick={() => {                                                                    
                                                                    this.ratingChanged(0, item);
                                                                }}
                                                            />
                                                        </span>
                                                        <Rating
                                                                fractions={2}
                                                                emptySymbol={empty_star_rating_below}
                                                                readonly={ratingOn}
                                                                fullSymbol={full_star_rating_below}                                                                
                                                                initialRating={!businessRatingList.filter(itm=>itm.key===item)?.[0]?.isEnable ? this.findAndSetItem(item) : 0}
                                                                onChange={(newRating) => {
                                                                    this[item] = false;
                                                                    this.ratingChanged(newRating, item) 
                                                                }}
                                                            />
                                                        <span className="clear-rating" role="button" title="Clear"
                                                            onClick={() => {
                                                                this[item.name] = false;
                                                                this.ratingChanged(0, item)
                                                            }}>
                                                            <span className="icon-cross"></span>
                                                        </span>
                                                    </div>
                                                </Col>) : null
                                        )) : null} */}



                                    {itemDetail?.total_rating && Object.keys(itemDetail?.total_rating).length ?
                                        Object.keys(itemDetail?.total_rating).map((item,index) => (
                                            (item !== "total_review" && item !== "avg_rating" && item !== "rating_count") ?
                                                (<Col className="mb-2" md={6} lg={reviewType==="categoryPage" ? 6 : 4} key={index}>
                                                    <div>{item}</div>
                                                    <div className="stars-rating-input">
                                                        <span className="zero-rating-input" role="button">
                                                            <FontAwesomeIcon
                                                                icon="ban"
                                                                color={this.checkColorTrue(item) ? '#15c3d4' : '#d7d8e2'}
                                                                onMouseEnter={()=>this.onHoverEnableDisable(item,true)}
                                                                onMouseLeave={()=>this.onHoverEnableDisable(item,false)}
                                                                onClick={() => {                                                                    
                                                                    this.ratingChanged(0, item);
                                                                }}
                                                            />
                                                        </span>
                                                        <span title={this.state.titleRatingText}>
                                                            {/*<StarsRating
                                                            count={5}
															value={this.findAndSetItem(item)}
                                                            size={24}
                                                            color1={'#d7d8e2'}
                                                            color2={'#15c3d4'}
															onChange={(newRating) => {
                                                                this[item] = false;
                                                                this.ratingChanged(newRating, item)
                                                            }}
															/>*/}                                                            
                                                            <Rating
                                                                fractions={2}
                                                                emptySymbol={empty_star_rating_below}
                                                                readonly={ratingOn}
                                                                fullSymbol={full_star_rating_below}                                                                
                                                                initialRating={!businessRatingList.filter(itm=>itm.key===item)?.[0]?.isEnable ? this.findAndSetItem(item) : 0}
                                                                onChange={(newRating) => {
                                                                    this[item] = false;
                                                                    this.ratingChanged(newRating, item) 
                                                                }}
                                                            />
                                                        </span>
                                                        <span className="clear-rating" role="button" title="Clear"
                                                            onClick={() => {
                                                                this[item.name] = false;
                                                                this.ratingChanged(0, item)
                                                            }}>
                                                            <span className="icon-cross"></span>
                                                        </span>
                                                    </div>
                                                </Col>) : null
                                        ))
                                        : null}
                                </Row>
                            </div>
                        </CollapseBasic>
                    </div>

                    {/* Step 2 Collapse */}
                    {listing_type == "movies" ? (
                        <div className="bg-white mb-3">
                            <CollapseBasic
                                title="Step 2. Choose a Person (optional)"
                                bodyClass="text-dark"
                                containerClass="type-tertiary type-review bg-transparent"
                                isOpen={false}
                                size="sm"
                                noPadding={true}
                                isReviewCollapse={true}
                                >
                                
                                <div className="text-tertiary fs-14 mb-3">
                                    {'If your review above contains information about a particular person at this business that you would like to highlight, please find his or her name below.'}
                                </div>
                                <div className="d-inline-block">
                                    <FormGroup>
                                        <Input
                                            className="border-0 text-primary font-weight-bold"
                                            type="select"
                                            size="sm"
                                            value={chooseAPerson['selectedItem'] || ''}
                                            onChange={this.handleChangePerson}>
                                            {this.renderChoosePersonOptions()}
                                        </Input>
                                    </FormGroup>
                                </div>

                                <hr className="bg-light my-2" />

                                <div className="text-tertiary fs-14 mb-3">
                                    {'Enter the name of a missing person below'}
                                </div>
                                <Row form>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Input
                                                disabled={chooseAPerson?.selectedItem ? true : false}
                                                className="primary"
                                                value={chooseAPerson['firstNameText']}
                                                onChange={(event) => {
                                                    this.handleChangeChooseAPerson(event,'firstNameText');
                                                }}
                                                bsSize="sm"
                                                type="text"
                                                placeholder="Enter First Name" />
                                            {this.state?.invalidData?.first_name  && <span className='Demo__error-message'>{this.state?.invalidData?.first_name }</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Input
                                                disabled={chooseAPerson?.selectedItem ? true : false}
                                                className="primary"
                                                value={chooseAPerson['lastNameText']}
                                                onChange={(event) => {
                                                    this.handleChangeChooseAPerson(event,'lastNameText');
                                                }}
                                                bsSize="sm"
                                                type="text"
                                                placeholder="Enter Last Name" />
                                            {this.state?.invalidChoosePerson?.last_name && <span className='Demo__error-message'>{this.state?.invalidChoosePerson?.last_name }</span>}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <hr className="bg-light my-2" />

                                <div className="row p-3 chooseAPerson-rating">
                                    <Label className="text-primary font-weight-bold fs-16 mr-3 mb-0">
                                        {`How would you rate ${chooseAPerson?.selectedItem ?
                                            `${chooseAPerson?.fullNameText?.[chooseAPerson?.selectedItem]}?` :
                                            chooseAPerson?.firstNameText?.length ?
                                                `${chooseAPerson?.firstNameText} ${chooseAPerson?.lastNameText}?` :
                                                'this person?'}`}
                                    </Label>
                                    <div className="stars-rating-input _lg">
                                        <span
                                            onMouseEnter={() => { if (!enableHoverPersonRating) { this.setState({ enableHoverPersonRating: true }) } }}
                                            onMouseLeave={() => { if (enableHoverPersonRating) { this.setState({ enableHoverPersonRating: false }) } }}
                                            className="zero-rating-input"
                                            role="button">
                                            <FontAwesomeIcon
                                                icon="ban"
                                                color={enableHoverPersonRating ? '#15c3d4' : '#d7d8e2'}
                                                onClick={() => {
                                                    this.handleChangePersonRating(0);
                                                }}
                                            />
                                        </span>
                                        <StarsRating
                                            count={5}
                                            value={chooseAPerson['ratingPerson'][chooseAPerson['selectedItem']] || ""}
                                            size={34}
                                            color1={'#d7d8e2'}
                                            color2={'#15c3d4'}
                                            onChange={(value) => {
                                                this.handleChangePersonRating(value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </CollapseBasic>
                        </div>
                    ) : null}

                    {/* Step 3 Collapse */}
                    <div className="bg-white mb-3">
                        <CollapseBasic
                            title={`Step ${listing_type == "movies" ? '3' : '2'}. Review`}
                            bodyClass="text-dark"
                            containerClass="type-tertiary type-review bg-transparent"
                            isOpen={false}
                            size="sm"
                            noPadding={true}
                            isReviewCollapse={true}
                            >
                            <div className="text-tertiary fs-14 mb-3">
                                {'Please provide as much information as you can in your review to be as meaningful as possible to others.'}
                            </div>
                            <Nav tabs className="mb-3 active-transparent">
                                <NavItem>
                                    <NavLink href="#"
                                        className="ff-base font-weight-normal"
                                        active={viewEditReviewStep === "writeReview"}
                                        onClick={() => {
                                            if (!this.state.stepTwoUploadedVideo.length && (this.state.clickedOnWriteAReview == 0 || this.state.clickedOnWriteAReview == 1)) {
                                                this.setState({ clickedOnWriteAReview: 1 });
                                            } else {
                                                this.setState({ viewEditReviewStep: "writeReview" })
                                            }
                                        }}>
                                        <span className="font-weight-bold">
                                            {'Write a Review'}
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#"
                                        className="ff-base font-weight-normal"
                                        active={viewEditReviewStep === "recordVideo"}
                                        onClick={() => {
                                            this.setState({ viewEditReviewStep: "recordVideo", clickedOnWriteAReview: 0 })
                                        }}>
                                        {'or record a'}
                                        <span className="font-weight-bold">
                                            &nbsp; {'Video Review'}
                                        </span>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent className="mb-3" activeTab={viewEditReviewStep}>
                                <TabPane tabId="writeReview">
                                    <FormGroup className="main-post-formgroup">
                                        <div className="input-label-block">
                                            <Input
                                                className="primary"
                                                type="textarea"
                                                name="writeAReviewText"
                                                rows="4"
                                                value={writeAReviewText}
                                                id="write_new_review"
                                                onChange={(event) => {
                                                    this.setState({
                                                        writeAReviewText: event.target.value,
                                                        errors: {
                                                            ...this.state.errors,
                                                            writeAReviewText: ''
                                                        }
                                                    })
                                                }}
                                                placeholder=""
                                            />
                                            {writeAReviewText.length < 50 && writeAReviewText.length !== 0 ?
                                                <span style={{ color: "red" }}>
                                                    { (this.props.editReviewMediaSetFinalized?.length > 0) ? "" : 'This field contains a minimum of 50 characters.'}
                                                </span>
                                                : ""}
                                           
                                            {errors?.writeAReviewText?.length > 0 && (
                                                <span style={{ color: "red" }}>
                                                    {errors.writeAReviewText}
                                                </span>
                                            )}
                                            {/* {this.state.errors?.writeAReviewText && (
                                                <div className="text-danger small mt-1">
                                                    {this.state.errors?.writeAReviewText}
                                                </div>
                                            )} */}
                                            {/* {writeAReviewText == '' && writeAReviewText.length !== 0 ?
                                                <span style={{ color: "red" }}>
                                                    {'This field required'}
                                                </span>
                                                : ""} */}
                                        </div>
                                        <div className="d-flex">
                                            {listing_type == "movies" && (
                                                <div className="px-2 col">
                                                    <FormGroup check className='d-flex align-items-center'>
                                                        <Input
                                                            type="checkbox"
                                                            checked={isMaskReview}
                                                            onClick={() => { this.setState({ isMaskReview: !this.state.isMaskReview }) }} />
                                                        <Label className="font-weight-normal text-dark cursor-pointer" check>
                                                            {/* {isEditItem ? 'My Review Contains Spoilers' : 'Mask Review'} */}
                                                            {'My Review Contains Spoilers'}
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            )}

                                            <div className="px-2 col">
                                                <div className="characters-length-text">
                                                    {'Characters:'} <span>{writeAReviewText.length ? writeAReviewText.length : '0'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </TabPane>
                                <TabPane tabId="recordVideo">
                                    {viewEditReviewStep === "recordVideo" && (
                                        <WebCamRecording
                                            setClickedTabOperation={this.setClickedTabOperation}
                                            clickedOnWriteAReview={this.state.clickedOnWriteAReview}
                                            stepTwoUploadedVideo={this.state.stepTwoUploadedVideo}
                                            addUploadedVideo={this.addUploadedVideo}
                                            removeUploadedVideo={this.removeUploadedVideo}                                           
                                        />
                                    )}
                                    {viewEditReviewStep === "recordVideo" && (
                                        <FormGroup className="main-post-formgroup">
                                            <div className="input-label-block">
                                                <Input
                                                    className="primary"
                                                    type="textarea"
                                                    name="write_new_review_vedio"
                                                    rows="4"
                                                    value={writeAReviewTextVideo}
                                                    id="write_new_review_video"
                                                    onChange={(event) => { this.setState({ writeAReviewTextVideo: event.target.value }) }}
                                                    placeholder=""
                                                />
                                                {/* {writeAReviewTextVideo.length < 50 && writeAReviewTextVideo.length !== 0 ?
                                                    <span style={{ color: "red" }}>
                                                        {'This field contains a minimum of 50 characters.'}
                                                    </span>
                                                    : ""} */}
                                                 
                                           
                                            {errors?.writeAReviewTextVideo?.length > 0 && (
                                                <span style={{ color: "red" }}>
                                                    {errors.writeAReviewTextVideo}
                                                </span>
                                            )}
                                            </div>
                                            <div className="d-flex">


                                                <div className="px-2 col">
                                                    <div className="characters-length-text">
                                                        {'Characters:'} <span>{writeAReviewTextVideo.length ? writeAReviewTextVideo.length : '0'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </FormGroup>

                                    )}
                                </TabPane>
                            </TabContent>

                            <div className="fs-14">
                                <div className="text-dark">
                                    <span className="font-weight-bold">
                                        {'Choose Review Categories'}
                                    </span>
                                    <span>
                                        {'(Optional)'}
                                    </span>
                                </div>
                                <hr className="bg-light my-2" />
                                <p className="text-tertiary fs-14 mb-2">
                                    {'If your review above contains information about a particular element or elements that you would like to highlight, please check the corresponding boxes below.'}
                                </p>
                                <Row>
                                    {reviewCategoriesList && Array.isArray(reviewCategoriesList) && reviewCategoriesList?.length ?
                                        reviewCategoriesList.map((reviewCategory) => (
                                            <Col xs={6} lg={4} key={reviewCategory.id}>
                                                <FormGroup check>
                                                    <Label className="text-primary font-weight-bold" check>
                                                        <Input
                                                            onClick={() => this.reviewCategoryHandler(reviewCategory.id)}
                                                            checked={reviewCategory.checked}
                                                            type="checkbox"
                                                            id="checkbox2" />{' '}
                                                        {reviewCategory?.label}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        ))
                                        : null}
                                </Row>
                            </div>
                        </CollapseBasic>
                    </div>

                    {/* Step 4 Collapse */}
                    <div className="bg-white mb-3">
                        <CollapseBasic
                            title={`Step ${listing_type == "movies" ? '4' : '3'}. Upload Images and/or Video (optional)`}
                            containerClass="type-tertiary type-review bg-transparent"
                            isOpen={false}
                            size="sm"
                            noPadding={true}
                            isReviewCollapse={true}
                            >
                            <p className="text-tertiary fs-14 mb-3">
                                {'Upload images or video to accompany your review. Additional images/videos can be added later.'}
                            </p>
                            {/* Uploaded media shown here */}
                            {!!this.props.isEditItem ?
                                <ShowUploadedMedia
                                    embedMedia={newReviewEmbedLinkSetFinalized}
                                    galleryMedia={editReviewMediaSetFinalized}
                                    removeEmbeddedFromUploaded={this.removeEmbeddedFromUploaded}
                                    removeFromUploaded={this.removeFromUploadedEdit} isEdit /> :
                                <ShowUploadedMedia
                                    embedMedia={newReviewEmbedLinkSetFinalized}
                                    galleryMedia={newReviewMediaSetFinalized}
                                    removeEmbeddedFromUploaded={this.removeEmbeddedFromUploaded}
                                    removeFromUploaded={this.removeFromUploaded} 
                                    feed={this.props?.feed?.id ? true : false}
                                    />
                            }                    
                            
                            <Button
                                color="tertiary reset"
                                onClick={() => {
                                    toggleEnableFileUpload(false, false)
                                    this.setState({ loadingdata: true })
                                }}>
                                {'Upload Media'}
                            </Button>
                        </CollapseBasic>
                    </div>

                    {/* Final Step */}
                    <div className="bg-white p-3">
                        <div className="mb-3">
                            <FormGroup check className='custom-consent-checkbox'>
                                <Input
                                    checked={this.state.is_social_share}
                                    type="checkbox"
                                    id="checkbox2"
                                    onChange={() => {
                                        this.setState({ is_social_share: !this.state.is_social_share }, () => {
                                            if (this.state.is_social_share) {
                                                this.setState({ is_anonymous: false })
                                            }
                                        })
                                    }}
                                />
                                <Label className="text-tertiary fs-14 ff-alt">
                                    {' '}
                                    {'Also post this review on social networks.'}
                                </Label>
                            </FormGroup>
                            <div className="mt-3 pl-4">
                                {/* <Button color="primary">Post To Facebook</Button> */}
                                <FacebookShareButton                                    
                                    url={`${this.state.protocol}//${this.state.host}/${this.props.listing_type}/${this.props.itemDetail?.id}`}
                                    quote={shareText + this.state.writeAReviewText}
                                    className={(this.state.is_anonymous == true || this.state.is_social_share == false) ? "text-dark text-decoration-none mx-2 socialpressed" : "text-dark text-decoration-none mx-2"}>
                                    <Button color={(this.state.is_anonymous == true || this.state.is_social_share == false) ? "tertiary reset" : "primary"} className={(this.state.is_anonymous == true || this.state.is_social_share == false) ? "socialpressed" : ""} disabled={(this.state.is_anonymous == true || this.state.is_social_share == false) ? true : false}>
                                        {'Post To Facebook'}
                                    </Button>
                                </FacebookShareButton>

                                <TwitterShareButton
                                    url={`${this.state.protocol}//${this.state.host}/${this.props.listing_type}/${this.props.itemDetail?.id}`}
                                    title={shareText + this.state.writeAReviewText}
                                    className={(this.state.is_anonymous == true || this.state.is_social_share == false) ? "text-dark text-decoration-none mx-2 socialpressed" : "text-dark text-decoration-none mx-2"}>
                                    <Button color={(this.state.is_anonymous == true || this.state.is_social_share == false) ? "tertiary reset" : "primary"} className={(this.state.is_anonymous == true || this.state.is_social_share == false) ? "socialpressed" : ""} disabled={(this.state.is_anonymous == true || this.state.is_social_share == false) ? true : false}>{'Post To Twitter'}</Button>
                                </TwitterShareButton>
                            </div>
                            {/* <div className="mt-3 pl-4">
                                <Button color="primary">Post To Facebook</Button>
                                <Button color="tertiary reset">Post To Twitter</Button>
                            </div> */}
                        </div>
                        {my_review_count && my_review_count > 5 && this.props.listing_type &&
                            !["actors", "accountants", "doctors", "dentists", "lawyers"].includes(this.props.listing_type) ?
                            <div className="mb-3">
                                <FormGroup check className='custom-consent-checkbox'>
                                    <Input
                                        checked={this.state.is_anonymous}
                                        type="checkbox"
                                        id="checkbox2"
                                        onChange={() => {
                                            this.setState({ is_anonymous: !this.state.is_anonymous }, () => {
                                                if (this.state.is_anonymous) {
                                                    this.setState({ is_social_share: false })
                                                }
                                            })
                                        }} />
                                    <Label className="text-tertiary font-weight-bold fs-14 ff-alt">
                                        {' '}
                                        {'Make this review anonymous '}
                                        <span className="font-weight-normal">
                                            {'(we will not show your username).'}
                                            <img src={require("../../assets/images/icons/ovel.png")} alt="" />
                                        </span>
                                    </Label>
                                </FormGroup>
                            </div>
                            :
                            null
                        }
                        <div className="mb-3">
                            <FormGroup check className='custom-consent-checkbox'>
                                <Input
                                    type="checkbox"
                                    id="checkbox2"
                                    checked={isAdhere}
                                    onChange={this.handelChangeReview}
                                />
                                <Label className="text-tertiary font-weight-bold fs-14 ff-alt" check>
                                    {' '}{'I adhere to the'}{' '}
                                    <span
                                        className="text-dark"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({ isHiddenReviewTipsAndGuide: !this.state?.isHiddenReviewTipsAndGuide }, () => {
                                                if (!this.state?.isHiddenReviewTipsAndGuide) {
                                                    document.getElementById('reviewTipsAndGuideLines').scrollIntoView({ behavior: "smooth", block: "center" });
                                                }
                                            });
                                        }}>
                                        {'Review Tips & Guidelines.'}
                                    </span> 

                                </Label><br/>
                                {!isAdhere && <span className="text-danger">Required</span>}
                            </FormGroup>
                        </div>
                        <div className="text-right">
                            {!this.state.isEditReviewEnable ? (
                                <React.Fragment>
                                    <Button size="lg" color="grey" style={{ color: "white" }} className="mw" onClick={() => {
                                        this.resetStates(false);
                                        if (this.props.closeAfterCancel) {
                                            this.props.closeAfterCancel();
                                        }
                                        this.props.finalizedMediaInReviewMediaSetForEditReview([])
                                        this.props.uploadMediaInReviewMediaSetForEditReview([])
                                    }}>Cancel</Button>
                                    {!this.state.is_anonymous && 
                                    <Button
                                        //disabled={this.state.writeAReviewText?.length < 50 || this.state.is_anonymous || (Array.isArray(this.state.stepTwoUploadedVideo) && this.state.stepTwoUploadedVideo.length) > 0 ? true : false}
                                        disabled={this.isPublishDisableDraft()}

                                        size="lg" color="tertiary" className="mw"
                                        // disabled={this.state.is_anonymous ? true : false}
                                        onClick={() => {
                                            if (checkIfProfessional) {
                                                this.handleSubmitProfessionalReview(true);
                                            } else {
                                                this.handleSubmitReview(true)
                                            }
                                        }}>
                                        {'Save Draft'}
                                    </Button>}
                                    <Button
                                        disabled={this.isPublishDisable()}
                                        title={this.isPublishDisable() ? viewEditReviewStep === "recordVideo" ? "Video Review Required" : "Text Review field Required" : "" }
                                        size="lg" color="primary" className="mw"
                                        onClick={() => {
                                            if (checkIfProfessional) {
                                                this.handleSubmitProfessionalReview(false);
                                            } else {
                                                this.handleSubmitReview(false)
                                            }
                                        }}>
                                        {'Publish'}
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Button size="lg" color="grey" className="mw" onClick={() => { this.resetStates(false) }}>Cancel</Button>
                                    {/* <Button size="lg" color="tertiary" className="mw" onClick={() => { this.handleSubmitReview(true) }}></Button> */}
                                    <Button
                                        disabled={this.isPublishDisable()}
                                        title={this.isPublishDisable() ? viewEditReviewStep === "recordVideo" ? "Video Review Required" : "Text Review field Required" : "" }
                                        size="lg" color="primary" className="mw" onClick={() => {
                                            if (checkIfProfessional) {
                                                this.handleSubmitProfessionalReview(false);
                                            } else {
                                                this.handleSubmitReview(false)
                                            }
                                        }}>{'Publish Review'}</Button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Collapse>
            </div>

        )
    }
}


const mapState = ((state) => ({
    my_profile_info: state.user.my_profile,
    newReviewMediaSetFinalized:state.reviewMedia.newReviewMediaSetFinalized,
    newReviewMediaSet:state.reviewMedia.newReviewMediaSet,
    newReviewEmbedLinkSetFinalized: state.reviewMedia.newReviewEmbedLinkSetFinalized,
    newReviewEmbedLinkSet:state.reviewMedia.newReviewEmbedLinkSet,
    editReviewMediaSet:state.reviewMedia.editReviewMediaSet,
    editReviewMediaSetFinalized:state.reviewMedia.editReviewMediaSetFinalized,
    editReviewEmbedLinkSetFinalized:state.reviewMedia.editReviewEmbedLinkSetFinalized,
    editReviewMediaSetFinalized:state.reviewMedia.editReviewMediaSetFinalized,
    isEditReviewEnabled:state.shared.isEditReviewEnabled,
}));

const mapProps = (dispatch) => ({
    rating_created_dispatch: (data) => dispatch(rating_created_dispatch(data)),
    setEditExistingMedia:(data)=>dispatch(setEditExistingMedia(data)),
    setEditExistingEmbedMedia:(data)=>dispatch(setEditExistingEmbedMedia(data)),
    uploadMediaInReviewMediaSetForNewReview: (data) => dispatch(uploadMediaInReviewMediaSetForNewReview(data)),
    finalizedMediaInReviewMediaSetForNewReview: (data) => dispatch(finalizedMediaInReviewMediaSetForNewReview(data)),
    finalizedMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForNewReview(data)),
    finalizedMediaInReviewMediaSetForEditReview: (data) => dispatch(finalizedMediaInReviewMediaSetForEditReview(data)),
    uploadMediaInReviewMediaSetForEditReview:(data)=>dispatch(uploadMediaInReviewMediaSetForEditReview(data)),
    setFollowReviewToggler:(data)=>dispatch(setFollowReviewToggler(data))
});

export default connect(mapState,mapProps)(CreateReview);