/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Button, FormGroup, Input, Form, Label } from 'reactstrap';
import { connect } from "react-redux";
import CollapseBasic from './../../atoms/collapse';
import { withRouter } from 'react-router';
import { add_movie_advisory } from './../../../actions/movies';
import DeleteBtn from "./../../atoms/deleteBtn";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Advisory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            advisoryText: '',
            isEnable: false,
            toggleAdvisory: false
        };
    }


    deleteAdvise = (id) => {
        if (id) {
            this.props.toggleConfirmModal({ isTextDelete: true, uniqueKey: 'delete-movies-advisory', id });
        }
    }

    handleChange = (e) => {
        let text = e.target.value;
        if (text !== '') {
            this.setState({ advisoryText: text, isEnable: true })
        }
        else{
            this.setState({ advisoryText: text, isEnable: false })
        }
    }

    submitAdvisory = (e) => {
        e.preventDefault();
        let text = this.state.advisoryText;
        let type = 'advisory';
        let user_entry = this.props.id;
        let data = { text, type, user_entry };
        if (text !== '' && type == 'advisory' && user_entry) {
            this.props.add_movie_advisory(data);
            this.setState({ advisoryText: '', toggleAdvisory: false })
        }
    }
    toggleAdvisory = () => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        let toggle = this.state.toggleAdvisory;

        if (!toggle) {
            this.setState({ toggleAdvisory: !this.state.toggleAdvisory, advisoryText: '' })
        }
        else {
            this.setState({ toggleAdvisory: false, advisoryText: '' })
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_info} = this.props;
        if(my_profile_info && my_profile_info.id && authId && (authId == my_profile_info.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        let { isEnable, toggleAdvisory } = this.state;
        let profileId = this.props.my_profile_info;
        let advisoryList = this.props.data;
        return (

            <div className="bg-movie-dark text-white mb-3">
                <CollapseBasic title="Advisory" containerClass="bg-transparent type-white" bodyClass="mt-2 text-white" isOpen={true}>
                    <ul className="list-unstyled">
                        {advisoryList && Array.isArray(advisoryList) && advisoryList.length > 0 ?
                            advisoryList.map((item, index) => {
                                if (item.type == "advisory") {
                                    return (<li className="mb-1" key={index}>
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <div className="ff-base wb-break-word">
                                                    {item.text}
                                                </div>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                {profileId && profileId.id && profileId.id === item.user && !item.is_masked ?
                                                    <DeleteBtn onClick={() => { this.deleteAdvise(item.id) }} />
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </li>
                                    )
                                }
                            }
                            )
                            :
                            "No Record Found"
                        }
                    </ul>

                    <div className="text-right mb-2">
                        <Button color="link" className="p-0 text-white" onClick={this.toggleAdvisory}>
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                                                Add another
                                            </Button>
                    </div>
                    {toggleAdvisory ?
                        <div className="bg-white p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30" >Add another</h5>
                            <Form onSubmit={this.submitAdvisory}>
                                <FormGroup>
                                    <Label className="text-dark ff-alt font-weight-bold">Add Advisory <span className="text-danger">*</span></Label>
                                    <Input value={this.state.advisoryText} onChange={this.handleChange} className="primary text-dark font-weight-bold ph-text-form" bsSize="sm" type="textarea" rows="4" placeholder="add advisory..." />
                                </FormGroup>

                                <div className="text-right mt-3">

                                    <Button disabled={!isEnable} className="mw" color="primary" size="sm">Submit</Button>
                                    <Button className="mw" color="primary" size="sm" onClick={this.toggleAdvisory}>Cancel</Button>
                                </div>
                            </Form>
                        </div>
                        :
                        ""
                    }
                </CollapseBasic>
            </div>

        )
    }
}

const mapState = (state) => ({
    my_profile_info: state.user.my_profile
});

const mapProps = (dispatch) => ({
    add_movie_advisory: (data) => dispatch(add_movie_advisory(data)),
});


export default withRouter(connect(mapState, mapProps)(Advisory));
