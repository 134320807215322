import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {
    Button, Col,
    FormGroup,
    Input, Modal, ModalBody,
    ModalFooter, ModalHeader,
    Row
} from 'reactstrap';
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import CollapseBasic from '../../atoms/collapse';
import { callApi } from "../../../utils/apiCaller";
import { get_discussions } from '../../../actions/discussionsTab';

class DiscussThisPage extends Component {
    constructor(props) {
        super(props)
        this.enableItems = false;
        this.enableFetch = false;
        this.state = {
            createNewDisModalToggle: false,
            discussionList: {},
            mediaSet: [],
            titleText: '',
            descriptionText: ''
        }
    }

    componentDidMount() {
        this.enableFetch = true;
        this.fetchDataItems();
    }

    componentDidUpdate() {
        let { mediaSet, discussionList } = this.state;
        let nextProps = this.props;
        let discussions_data = nextProps?.discussions_data;
 
        if (discussions_data?.results?.length !== discussionList?.length) {
            this.setState({ discussionList: nextProps?.discussions_data?.results });
        }
		let uploadedFiles = nextProps?.uploadedMediaSet?.uploadedFiles;
        if (uploadedFiles?.length !== mediaSet?.length) {
            this.setState({ mediaSet: uploadedFiles });
        }
 
        
    }

    fetchDataItems = async () => {
        let { id, profdiscussion } = this.props;
        let url = `/api/${profdiscussion ? 'profdiscussion' : 'discussion'}/?entity=${id}&page=1`;
        this.props.get_discussions(url);
    }

    createNewDicussion = async () => {
        let { titleText, descriptionText, mediaSet } = this.state;
        let { id, profdiscussion } = this.props;



        let mediaItems = [];

        if (mediaSet?.length) {
            mediaItems = mediaSet.map(data => data.id);
        }

        let url = `/api/${profdiscussion ? 'profdiscussion' : 'discussion'}/`;
        let data = {
            "title": titleText,
            "body": descriptionText,
            "user_entry": id,
        }

        if (!profdiscussion) {
            data["messagemedia_set"] = mediaItems;
        } else {
            data["professionalmessagemedia_set"] = mediaItems;
        }

        try {
            let response = await callApi(url, 'POST', data);
            if (response && response.code !== 500) {
				toast(`Discussion Created Successfully!`, {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                this.enableFetch = true;
                await this.fetchDataItems();
                this.setState({
                    createNewDisModalToggle: false,
                    mediaSet: [],
                    titleText: '',
                    descriptionText: ''
                });
                let discussionType = "discussion"
                this.props.getDiscussion({ discussionType })
                setTimeout(() => {
                  
                    window.scrollTo(0, 700)
                }, 1500);
            }
        } catch (err) {
            console.log(err);
        }
    }
    truncate = (filenameString) => {
        // let split = filenameString.split(".");
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const { my_profile_data } = this.props;
        if (my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)) {
            return true;
        } else {
            return false;
        }
    }

    render() {
       
        let { discussionList, mediaSet, titleText, descriptionText } = this.state;
    
        const { isLight } = this.props;
        return (
            <React.Fragment>
                <div className={isLight ? "bg-white-shaded-md text-secondary mb-3" : "bg-movie-dark text-white mb-3"}>
                    <CollapseBasic
                        title="Discuss This Page"
                        containerClass={isLight ? "bg-transparent type-secondary" : "bg-transparent type-white"}
                        bodyClass={isLight ? "mt-2 text-secondary-dark" : "mt-2 text-white"}
                        isOpen={true}>
						
                        <ul className="list-unstyled">
                            {discussionList &&
                                Array.isArray(discussionList) &&
                                discussionList?.length ?
                                discussionList.map((item, index_item_discuss) => (
                                    <li
                                        key={index_item_discuss}
                                        className="ff-base">
                                        <div className="font-weight-bold wb-break-word">
                                            <a href="#" className="text-reset font-weight-bold">{item?.title}</a>
                                        </div>
                                        <div>
                                            {`Created ${item?.sent_at} by`} <a href={`/people/${item?.sender_details}`} className="text-reset font-weight-bold">{item?.sender}</a>
                                        </div>
                                        <hr className="bg-light" />
                                    </li>
                                ))
                                : `No Record Found`}
                        </ul>
                        <div className="text-right">
                            <Button
                                color="link"
                                className={isLight ? "p-0 text-secondary-dark font-weight-bold" : "p-0 text-white font-weight-bold"}
                                onClick={() => {
                                    if (!this.checkIfLoggedIn()) {
                                        this.props.forceToggleLoginModal();
                                        return false;
                                    }
                                    this.setState({ createNewDisModalToggle: true, titleText: '', descriptionText: '' });
                                    this.props.getUploadedMedia({ mediaSet: [] });
                                    this.enableItems = false;
                                }}>
                                {'Create a New Discussion'}
                            </Button>
                        </div>
                    </CollapseBasic>
                </div>
                {/* Create a new discussion Modal */}
                < Modal
                    isOpen={this.state.createNewDisModalToggle}
                    toggle={() => this.setState({ createNewDisModalToggle: !this.state.createNewDisModalToggle })}>
                    <ModalHeader
                        className="px-0"
                        onClick={() => {
                            
                            this.props.resetUploadedMediaSet()
                        }}
                        
                        toggle={() => this.setState({ createNewDisModalToggle: !this.state.createNewDisModalToggle })}>
                            
                        {'CREATE NEW DISCUSSION'}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                onChange={(e) => { this.setState({ titleText: e.target.value }) }}
                                value={titleText}
                                className="border text-dark ph-grey"
                                bsSize="sm"
                                type="text"
                                placeholder="Enter title for discussion" />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                onChange={(e) => { this.setState({ descriptionText: e.target.value }) }}
                                value={descriptionText}
                                className="border text-dark ph-grey font-weight-bold"
                                bsSize="sm"
                                type="textarea"
                                rows="10" />
                        </FormGroup>
                        <div>
                            <Button
                                onClick={() => {
                                    this.setState({ mediaSet: [] }, () => {
                                        this.props.getUploadedMedia({ mediaSet: [] });
                                        this.props.toggleEnableFileUpload();
                                        this.enableItems = false;
                                        this.props.getnocategory("dicussionTab");
                                    })
                                }}
                                color="transparent"
                                size="sm"
                                title="Upload Media">
                                <FontAwesomeIcon icon="camera" />
                            </Button>
                        </div>

                        {/* Uploaded media shown here */}
                        
                        {this.state.mediaSet && Array.isArray(this.state.mediaSet) && this.state.mediaSet.length > 0 &&
                            <div className="border mb-3">
                                <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                    {
                                        this.state.mediaSet.map((item, index) => {
                                           
                                            return (
                                                <Col key={item.id}>
                                                    <div className="d-flex pr-3 m-2">
                                                        <div>
                                                            {(item.type === "image" ||
                                                                item.media_type === "image") && (
                                                                    <div>
                                                                        <img
                                                                            className="img-fluid img-thumbnail"
                                                                            src={item.file ? item.file : item.url}
                                                                            alt={
                                                                                item.filename
                                                                                    ? item.filename.length < 20
                                                                                        ? item.filename
                                                                                        : this.truncate(item.filename)
                                                                                    : item.name
                                                                                        ? item.name.length < 20
                                                                                            ? item.name
                                                                                            : this.truncate(item.name)
                                                                                        : ""
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            {(item.type === "video" ||
                                                                item.media_type === "video") && (
                                                                    <div>
                                                                        <video width="100" height="100">
                                                                            <source src={item.file ? item.file : item.url} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                    </video>
                                                                    </div>
                                                                )}
                                                        </div>

                                                        <div className="mx-n3 mt-2">
                                                            <Button
                                                                onClick={() => {
                                                                    let { mediaSet } = this.state;
                                                                    mediaSet.splice(index, 1);
                                                                    this.setState({ mediaSet });
                                                                }}
                                                                color="delete-media _alt"
                                                                size="sm"
                                                                title="Remove Media">
                                                                <FontAwesomeIcon
                                                                    icon="trash-alt" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <Button
                            disabled={(titleText?.length && descriptionText?.length) ? false : true}
                            onClick={() => { this.createNewDicussion() }}
                            color="primary">Submit</Button>
                    </ModalFooter>
                </Modal >

            </React.Fragment>
        )
    }
}

const mapState = (state) => ({
    discussions_data: state.discussions.discussions_data,
    my_profile_data: state.user.my_profile
});

const mapDispatch = (dispatch) => ({
    get_discussions: (url) => dispatch(get_discussions(url)),
})

export default connect(mapState, mapDispatch)(DiscussThisPage);

