import {
  ALL_CATEGORIES,
  ALL_MOVIE_CATEGORIES,
  ALL_PRODUCT_CATEGORIES,
  ADD_MOVIE_LISTING,
  ADD_PRODUCT_LISTING,
  GET_PRODUCT_SUBCATEGORY_LISTING,
  GET_PRODUCT_ANOTHSUBCATEGORY_LISTING,
  GET_PRODUCT_OTHERSUBCATEGORY_LISTING,
  GET_PROFESSIONAL_SUBCATEGORY_LISTING,
  GET_PROFESSIONAL_ANOTHSUBCATEGORY_LISTING,
  GET_PROFESSIONAL_OTHERSUBCATEGORY_LISTING,
  ALL_PROFESSIONAL_CATEGORIES,
  ALL_BUSINESS_CATEGORIES,
  ADD_BUSINESS_LISTING,
  ADD_PROFESSIONALS_LISTING,
  GET_BUSINESS_SUBCATEGORY_LISTING,
  GET_MOVIE_SUBCATEGORY_LISTING,
  GET_DRIECTOR_LISTING,
  GET_WRITER_LISTING,
  GET_PRODUCER_LISTING,
  GET_CAST_LISTING,
  GET_CATEGORY_MENU_LISTING,
  GET_SUBCATEGORY_MENU_LISTING,
  ALL_CRP_CATEGORIES,
  GET_CRP_SUBCATEGORY_LISTING,
  GET_CRP_ANOTHSUBCATEGORY_LISTING,
  GET_PATROL_CRP,
  GET_PATROL_CORE,
  GET_PATROL_MEDIA,
  GET_PATROL_REVIEW_LISTING,
  GET_PATROL_REVIEW,
  GET_IMPROVE_CORE,
  GET_IMPROVE_MEDIA,
  GET_IMPROVE_TECHINCAL,
  GET_VOTE_LISTING,
  GET_FLAG_LISTING,
  GET_USER_STATUS,
  // SUBMIT_PATROL_REVIEW
} from "../actions/footer";

const initialState = {
  categories: [],
  menuCategories: [],
  movieCategories: [],
  productCategories: [],
  productSabcategories: [],
  productAnothSabcategories: [],
  productOtherSabcategories: [],
  professionalCategories: [],
  professionalSubcategory: [],
  professionalAnothSabcategory: [],
  professionalOtherSabcategory: [],
  businessCategories: [],
  businessSabcategories: [],
  movieSabcategories: [],
  data: {},
  directorListing:{},
  writerListing:{},
  producerListing:{},
  castListing:{},
  subCatMenuListing:{},
  crpCategories:[],
  crpSabcategories:[],
  crpAnothSubcategories:[],
  patrolCrp:[],
  patrolCore:[],
  patrolMedia:[],
  patrolReviewListing:[],
  getPatrolReview:[],
  getImproveCore:[],
  getImproveMedia:[],
  getImproveTechnical:[],
  getVoteListing:[],
  getFlagListing:[],
  get_blocked_user_status:{},

};

const footerReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_INITIAL_AUTH_NEW':
      return initialState;
      
    case GET_CATEGORY_MENU_LISTING:
      return { ...state, menuCategories: action.payload };
	  
	case GET_SUBCATEGORY_MENU_LISTING:
      return { ...state, subCatMenuListing: action.payload };
	
	case ALL_CATEGORIES:
      return { ...state, categories: action.payload };
     
    case GET_USER_STATUS:
        return { ...state, get_blocked_user_status: action.payload };	    

    case ALL_MOVIE_CATEGORIES:
      return { ...state, movieCategories: action.payload };

    case ALL_PRODUCT_CATEGORIES:
      return { ...state, productCategories: action.payload };

    case ALL_PROFESSIONAL_CATEGORIES:
      return { ...state, professionalCategories: action.payload };

    case ADD_MOVIE_LISTING:
      return { ...state, data: action.payload };

    case ADD_PRODUCT_LISTING:
      return { ...state, data: action.payload };

    case GET_PRODUCT_SUBCATEGORY_LISTING:
      return { ...state, productSabcategories: action.payload };

    case GET_PRODUCT_ANOTHSUBCATEGORY_LISTING:
      return { ...state, productAnothSabcategories: action.payload };

    case GET_PRODUCT_OTHERSUBCATEGORY_LISTING:
      return { ...state, productOtherSabcategories: action.payload };
    case GET_PROFESSIONAL_SUBCATEGORY_LISTING:
      return { ...state, professionalSubcategory: action.payload };

    case GET_PROFESSIONAL_ANOTHSUBCATEGORY_LISTING:
      return { ...state, professionalAnothSabcategory: action.payload };

    case GET_PROFESSIONAL_OTHERSUBCATEGORY_LISTING:
      return { ...state, professionalOtherSabcategory: action.payload };

    case ALL_BUSINESS_CATEGORIES:
      return { ...state, businessCategories: action.payload };

    case ADD_BUSINESS_LISTING:
      return { ...state, data: action.payload };

    case ADD_PROFESSIONALS_LISTING:
      return { ...state, data: action.payload };

    case GET_BUSINESS_SUBCATEGORY_LISTING:
      return { ...state, businessSabcategories: action.payload };
	  
	case GET_MOVIE_SUBCATEGORY_LISTING:
      return { ...state, movieSabcategories: action.payload }; 

   case GET_DRIECTOR_LISTING:
      return { ...state, directorListing: action.payload };  
   
   case GET_PRODUCER_LISTING:
      return { ...state, producerListing: action.payload };  

   case GET_WRITER_LISTING:
      return { ...state, writerListing: action.payload };  

   case GET_CAST_LISTING:
      return { ...state, castListing: action.payload }; 	  
  
   case ALL_CRP_CATEGORIES:
      return { ...state, crpCategories: action.payload };

    case GET_CRP_SUBCATEGORY_LISTING:
      return { ...state, crpSubcategories: action.payload };
      
    case GET_CRP_ANOTHSUBCATEGORY_LISTING:
      return { ...state, crpAnothSubcategories: action.payload };
	  
	case GET_PATROL_CRP:
      return { ...state, patrolCrp: action.payload };
	  
	case GET_PATROL_CORE:
      return { ...state, patrolCore: action.payload };
	  
    case GET_PATROL_MEDIA:
      return { ...state, patrolMedia: action.payload };
	  
   case GET_PATROL_REVIEW_LISTING:
      return { ...state, patrolReviewListing: action.payload };	  
	
   case GET_PATROL_REVIEW:
      return { ...state, getPatrolReview: action.payload };	
	
   case GET_IMPROVE_CORE:
      return { ...state, getImproveCore: action.payload }; 
   
   case GET_IMPROVE_MEDIA:
      return { ...state, getImproveMedia: action.payload };
   
   case GET_IMPROVE_TECHINCAL:
      return { ...state, getImproveTechnical: action.payload }; 
	  
   case GET_VOTE_LISTING:
      return { ...state, getVoteListing: action.payload };   
	
  case GET_FLAG_LISTING:
      return { ...state, getFlagListing: action.payload };  
	  
   default:
      return state;
  }
};

export default footerReducer;
