/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import { Container, Row, Col } from 'reactstrap';
import PublicPageHeader from '../../oragnisms/homepage/publicPageHeader';
export default class Guidelines extends Component {
    render() {
        const isLoggedIn = localStorage.getItem("token")

        return (
            <div>
                {isLoggedIn ? <AppHeader isEnableCover={false} /> : <PublicPageHeader />}    

                <div className="wk-static-wrapper">
                  <Container>
                      <div className="wk-static-sub-head">
                         <h1>Guidelines</h1>
                      </div>
                      <div className="wk-static-content-body">
                         <Row>
                            <Col md={4}>
                               <div className="wk-static-sidebar-left">
                                  <h2>MENU</h2>
                                  <ul>
                                    <li><a href="#welcome-to-wikiReviews">Welcome to WikiReviews </a> </li>
                                    <li><a href="#wikiReviews-terminology">WikiReviews Terminology </a></li>
                                    <li><a href="#getting-started">Getting Started  </a></li>
                                    <li><a href="#disputing-reviews ">Disputing Reviews </a> </li>
                                    <li><a href="#personal-accounts">Personal Accounts  </a></li>
                                    <li><a href="#content-guidelines">Content Guidelines  </a></li>
                                    <li><a href="#community-guidelines">Community Guidelines  </a></li>
                                    <li><a href="#listing-pages">Listing Pages </a> </li>
                                    <li><a href="#category-pages">Category Pages  </a></li>
                                    <li><a href="#buyers-guide-criteria">Buyer's Guide Criteria  </a></li>
                                    <li><a href="#community-review-projects">Community Review Projects  </a></li>
                                    <li><a href="#flagging-editing">Flagging &amp; Editing Site Content</a></li>
                                    <li><a href="#badges-awards">Badges &amp; Awards  </a></li>
                                    <li><a href="#admin-noticeboard">Admin Noticeboard  </a></li>
                                    <li><a href="#admin-guide">Admin Guide  </a></li>
                                    <li><a href="#business-owner-accounts">Business Owner Accounts  </a></li>
                                    <li><a href="#professional-accounts">Professional Accounts  </a></li>
                                    <li><a href="#style-guide">Style Guide  </a></li>
                                    </ul>
                               </div>                            
                            </Col>
                            <Col md={8}>
                                <div className="wk-static-content-right">
                                   <h2 id="welcome-to-wikiReviews">WELCOME TO WIKIREVIEWS</h2>
                                   <p className="fs-14">Hello and welcome to WikiReviews, the world’s largest 100% free community review website. Our goal is to allow consumers and everyday people to give feedback directly to the source, be it a manufacturer, company, movie studio, artist or physician. Our website provides a platform for anyone, from anywhere in the world, to not only review things that currently exist - like products, businesses, and movies - but also things that could exist in the future - think meticulous business plans or the next great invention!  
                                   </p>
                                   <p className="fs-14">Through our innovative Community Review Projects, users can also upload any personal creations they want reviewed and receive thoughtful (and perhaps humorous) feedback from the WikiReviews community. You can upload artwork, creative ideas, product concepts, music recordings, videos, books, or anything your brilliant mind can conceive. Our site is a platform for constructive feedback, inspiration, and even a dash of humor. 
                                   </p>
                                   <p className="fs-14"><b><u>The Power of Online Reviews</u></b>
                                   </p>
                                   <p className="fs-14">A few decades ago, there were only three ways to identify the quality of a product, business, or movie. Either you received insight from a friend with firsthand experience, read a review in the newspaper, or tried it blind. Even if your friends and local reviewers had exceptional taste (a big "if"), they could only provide insight on a small percentage of your consumer options. 
                                   </p>
                                   <p className="fs-14">All this changed with the emergence of online reviews. We can now evaluate a product, business, or movie based on the collective experiences of complete strangers. Business owners can no longer treat customers poorly without consequence. Warnings about poorly designed products get publicized quickly. Movies must actually be good to enjoy success past opening night. 
                                   </p>
                                   <p className="fs-14">Online reviews place the power squarely in our hands. We can applaud excellence and avoid wasting our hard-earned dollars on below-average options. Unfortunately, this power isn’t guaranteed to stay in our hands. 
                                   </p>
                                   <p className="fs-14"><b><u>Why Do We Need A New Review Website?</u></b>
                                   </p>
                                   <p className="fs-14">The problem with the review industry is that it’s highly lucrative. The value of online reviews has turned several key review hubs into multi-million dollar businesses, and opportunity for profit has begun to sabotage the review experience. Here are a few of the major problems consumers face with today’s review websites:
                                   </p>
                                   <ul>
                                        <li>Reviews sites allow business owners to pay to eliminate negative reviews or boost their rankings in the search results. </li>
                                        <li>Angry, irrational customers can sabotage a quality business with a single negative review. </li>
                                        <li>Anonymous accounts allow owners to fraudulently boost their reputation using fake accounts and paid services. </li>
                                    </ul>
                                    <p className="fs-14">Each of these points comes down to trust. If you can’t trust the legitimacy of the review you’re reading, it is worthless to you. We need to bring control back into the users’ hands, creating a trustworthy community where only legitimate reviews are displayed. 
                                     </p>
                                   <p className="fs-14"><b><u>What Is A WIKI And How Does It Work?</u></b>
                                   </p>
                                   <p className="fs-14">A Wiki is simply a website where users can add, modify and delete content. Ward Cunningham developed the first ever wiki software called WikiWikiWeb.
                                   </p>
                                   <p className="fs-14">The concept behind a wiki is to create a forum where the site is essentially run by its own community through forums and policies that allow the community to police itself. When any change is made to the site, this change is highlighted in another part of our website (the Participate Section), and another member of the community can review and approve it, modify it, or delete the change by restoring the content to its previous form. This system helps ensure that the site can only be changed for the better.
                                   </p>
                                   <p className="fs-14">In addition to this base system, there are a number of guardians for our site referred to as Administrators or “Admins”. Admins are simply avid site participants who are willing to help the community by contributing their time and energy toward making the site better. Admins perform routine maintenance duties, mediate disputes and help guide the site’s evolution through policy suggestions and changes.
                                   </p>
                                   <p className="fs-14"><b><u>So What Exactly Is WikiReviews?</u></b>
                                   </p>
                                   <p className="fs-14">WikiReviews is a free-to-use community website that helps people make better consumer decisions through the aggregation of reviews for all different products, services, movies, businesses, organizations, and more. We also provide a platform for anyone, anywhere to upload virtually anything they wish to have reviewed, from business plans and products to songs and videos.
                                   </p>
                                   <p className="fs-14"><b><u>What WikiReviews is NOT</u></b>
                                   </p>
                                   <ul>
                                       <li>WikiReviews is NOT an entirely safe website. We do not actively control the behavior of our users. Beware of “trolls” – people who claim to be something that they are not. People claiming to be a young girl may be an old man. People may also claim to hold positions or ranks that they do not actually hold, such as a doctor, lawyer, or other professional. Be cautious of any advice that may cause mental, physical, or financial harm. 
                                       <br/>Never reveal any personal information, such as your physical address, email address or phone number. Users under 13 years old are not permitted on WikiReviews. Report users who don’t meet our age requirement via the Help Page. After investigating, we will promptly remove all personally identifiable information from the user account and block the offending profile until the user reaches 13 years of age.
                                       </li>
                                       <li>WikiReviews is NOT a role-playing site. It is a real community of real people helping each other make informed consumer decisions. Do not impersonate another WikiReviews user, a famous individual, or any identifiable third party.
                                       </li>
                                       <li>WikiReviews is NOT a place for vandalism. Inappropriate or mean-spirited content will be deleted. Act in good faith. Testing is permitted and encouraged in the Sandbox. Utilize this feature to learn how to edit. Disrupting the community or encouraging others to do so is discouraged.
                                       </li>
                                       <li>WikiReviews is NOT a place for listings that are only valuable to a small handful of people. Personal content should be saved for the Community Review Projects section. Create listings that are notable and useful to a wide segment of people. For example, listings about products made for your own personal use that the community at large is unable to purchase will not appeal to wider audiences and will probably be deleted.
                                       </li>
                                       <li>WikiReviews is NOT a link farm. Listings should contain useful instructions with a reasonable number of relevant links. Listings that are merely link collections or contain an unreasonably large selection of links may be deleted.
                                       </li>
                                       <li>WikiReviews is NOT a mirror or file hosting site. Please do not gratuitously upload images or files to our servers. All uploaded files must be relevantly used on active WikiReviews pages and conform to our deletion, image, and copyright policies. Abuse of the image and video systems on WikiReviews is not tolerated, nor will any pornography or depiction of lewd acts/behavior be permitted.
                                       </li>
                                       <li>WikiReviews is NOT a place for bullying or hate-based reviews. Any activity violating our harassment policies will result in account suspension.
                                       </li>
                                       <li>WikiReviews product listings are NOT a place for retailers to set up shop. Product pages in the future will only be claimed by manufacturers. Links pointing to retailers selling the product are only permitted in the “Other Offers” section. Listings should be purely informational. Excessive promotion is discouraged.
                                       </li>
                                       <li>Claiming ownership of businesses or any other listing pages implies you are the rightful owner or representative of that listing and have the authority to respond to reviews and questions posted by the community. Furthermore, claiming ownership means you have the authority to speak on behalf of the entity featured in the listing.
                                       </li>
                                  </ul>
                                  <p className="fs-14"><b><u>Who Created WikiReviews?</u></b>
                                  </p>
                                  <p className="fs-14">WikiReviews was founded by Sunil Wagle, an internet entrepreneur who believes everyone should pursue what they are most passionate about in life. His desire is to revolutionize the way we live life and do business by facilitating honest feedback among businesses, consumers, artists, lawyers, doctors and more. His passion for transforming the online review space is surpassed only by his commitment to letting the WikiReviews community choose its own destiny, free of administrative controls.
                                  </p>
                                  <h2  id="wikiReviews-terminology">WIKIREVIEWS TERMINOLOGY</h2>
                                  <p className="fs-14"><b><u>WIKIREVIEWS TERMINOLOGY</u></b>
                                  </p>
                                  <p className="fs-14"><b><i>Nominate For Deletion (NFD) –</i></b> This is the term used when users click on the flag pulldown on listing pages to nominate a listing for deletion. This can be done for a variety of reasons including, abuse, profanity, or vandalism. 
                                  </p>
                                  <p className="fs-14"><b><i>Speedy Deletion –</i></b> This is a term used when a contributor on the site feels that something should be done quickly such as a deletion that needs to be made right away due to various reasons such as pornography or because it is illegal. 
                                  </p>
                                  <p className="fs-14"><b><i>Page Protection -</i></b> Each page on the site can be protected from users making any changes to it. There is Full Protection and Semi- Page protection. Full Protection only allows WikiReviews staff to make changes to the pages such as the Policies page, Terms of Use, etc. Fully protected pages can easily be identified by seeing the Closed Lock icon on the page. Semi-Page protection allows all registered users to make changes to the site. Non-logged in users cannot write reviews or make any contributions to listing pages.  
                                  </p>
                                  <p className="fs-14"><b><i>Page Protection -</i></b> Each page on the site can be protected from users making any changes to it. There is Full Protection and Semi- Page protection. Full Protection only allows WikiReviews staff to make changes to the pages such as the Policies page, Terms of Use, etc. Fully protected pages can easily be identified by seeing the Closed Lock icon on the page. Semi-Page protection allows all registered users to make changes to the site. Non-logged in users cannot write reviews or make any contributions to listing pages.  
                                  </p>
                                  <p className="fs-14"><b><i>Trolling -</i></b> Trolling is one of the top reasons why people get kicked or banned from the site. It's defined as unnecessary, off topic chat with the intention of harassing people or disruption of a normal conversation. The "troll" feeds off the negative comments towards them to gain attention and to grow "stronger". 
                                  </p>
                                  <p className="fs-14"><b><i>Vandalism -</i></b> This is for users who disrupt the site operations by nominating things for deletion for no reason or removing proper content just for purposes of disruption. 
                                  </p>
                                  <p className="fs-14"><b><i>Blanking -</i></b> This is when a user intentionally deletes editable content on the page and leaves the page with little to no information just for jokes or testing or for disruption purposes. 
                                  </p>
                                  <p className="fs-14"><b><i>Disruptive -</i></b> Users who use tactics to prevent the normal functioning of the site for our everyday users. 
                                  </p>
                                  <p className="fs-14"><b><i>Personal Attacks -</i></b> Disagreements are fine, but when users take disagreements to another level by using physical threats or posting racist comments or sexist insults, we regard that as a personal attack and those posts are subject to immediate removal. 
                                  </p>
                                  <p className="fs-14"><b><i>Harassment -</i></b> Users can repeatedly contact a user or admin for selfish purposes or to prove a point and they may do so in such a frequent manner as to be considered harassment. 
                                  </p>
                                  <p className="fs-14"><b><i>Blocking -</i></b> A block is a tool placed on users that prevents them from contributing to the site in any format. A block can be placed on a user for a specified time, with each successive block being longer with each infraction. This happens in the event we have an abusive or disruptive user or a user creates speedy deletions for no valid reason, malicious sockpuppetry, adding spam links, trolling, vandalism, threats of vandalism to user/talk pages, incivility, personal attacks, outing, threats of legal action and/or violence, or even death threats. 
                                  </p>
                                  <p className="fs-14"><b><i>Banning -</i></b> Banning is done through a process voted on or determined by the administrators and bans can take many forms including the following different types: site ban, listing (page) ban, category (topic) ban, and interaction ban. 
                                  </p>
                                  <p className="fs-14"><b><i>Sockpuppetry -</i></b> This is when a user takes on an alternative persona ("sock puppet", or "sock") to influence consensus or to create false identities to advance their own purposes such as making fraudulent reviews or trying to get others to agree with his/her side 
                                  </p>
                                  <p className="fs-14"><b><i>Meatpuppetry -</i></b> This is very similar to sockpuppetry, except this entails getting help from family and friends to create identities on the site and further the purposes of one individual. 
                                  </p>
                                  <p className="fs-14"><b><i>Talk Pages -</i></b> Each listing and every user on our site has a talk page. A talk page is an area on the listing or user page that allows users to discuss issues with that listing/user. This is also a section where users should post any detailed explanations of changes that were made to the listing/user page so that there is full disclosure as to the reason why things were modified on the page. 
                                  </p>
                                  <p className="fs-14"><b><i>Reverts -</i></b> Reverts are changes that patrols make to change the page back to the same content that was on the page just before the most recent person made edits to the page. 
                                  </p>
                                  <p className="fs-14"><b><i>Request for Comments -</i></b> When users or admins have questions about the way something was handled or want to pose an issue to the greater wiki or admin community, they can post a Request for Comment and get a broader consensus on various issues. 
                                  </p>
                                  <p className="fs-14"><b><i>Community Noticeboard (CNB) & Forums -</i></b> This is the place where administrators and the community get together to discuss issues and take corrective action for issues such as copyright infringement, flagged adult images and videos, flagged questions, etc. 
                                  </p>
                                  <p className="fs-14"><b><i>Sandbox -</i></b> Every wiki has what is called a Sandbox. A sandbox is a test area for new users to try and make modifications or deletions to a listing such as adding images and videos, deleting content, etc. to get familiarity with using the site. Anyone can do anything here without any repercussions.
                                  </p>
                                  <p className="fs-14"><b><i>WikiReviews Herald -</i></b> This is our community newspaper that we encourage users to go to and read all updates to the site, community news, results of administration elections, etc. 
                                  </p>
                                  <p className="fs-14"><b><i>Retitle Page -</i></b> Many times, page titles are created by new users and they should be changed to allow for proper formatting so that our listings show in search engine results properly. In addition, many times titles are not made as per our title guidelines in our policy so these must be changed. This task is only performed by admins. 
                                  </p>
                                  <p className="fs-14"><b><i>Retitle and Move Pages -</i></b> Sometimes duplicate listings for the same thing are listed on our site so listings must be consolidated, which will result in moving one page into another page. This task is done by admins. 
                                  </p>
                                  <p className="fs-14"><b><i>Restore Pages -</i></b> There are occasions where a page is deleted on accident and with this functionality, admins can quickly restore the deleted page. 
                                  </p>
                                  <p className="fs-14"><b><i>Protect Pages -</i></b> Many times pages are subject to a lot of vandalism. In cases such as this, users can request admins to protect the page, preventing further edits to the page until the vandalism ceases.
                                  </p>
                                  <h2  id="getting-started">GETTING STARTED</h2>
                                  <p className="fs-14">Before you begin, there are a few things you should know. The WikiReviews experience can be broken down into 4 primary parts.
                                  </p>
                                  <ul>
                                    <li>Review products, movies, or businesses based on your personal experiences.</li>
                                    <li>Research buying decisions through reading reviews and engaging with the community.</li>
                                    <li>Participate in improving WikiReviews by reviewing community uploads, patrolling user edits, mediating disputes, and much more.</li>
                                    <li>Upload your own projects for the community to review.</li>
                                  </ul>
                                  <p className="fs-14"><b><u>Do I Need An Account?</u></b>
                                  </p>
                                  <p className="fs-14">Users without accounts may only read reviews. Users with registered accounts are given complete access to the site and all its functions.
                                  </p>
                                  <p className="fs-14"><b><u>1. Writing Reviews</u></b>
                                  </p>
                                  <p className="fs-14"><b>Start reviewing products, movies, or businesses based on your personal experiences.</b>
                                  </p>
                                  <p className="fs-14"><mark>To begin, click on the Search Box at the top of the page and search for a business, product or movie that you want to review. You will see a list of results and all you have to do is click on the listing name and you will be taken to the main listing page from where you can just click “Create A Review.”</mark>
                                  </p>
                                  <p className="fs-14">Please note that only registered users can create reviews.
                                  </p>
                                  <ul>
                                      <li>Please review our Content Guidelines.</li>
                                      <li>Search for the listing you want to write a review about and be sure to look for duplicate listings. If you spot the same listing twice, please report the duplicate listing on the listing page itself by clicking on the flag at the top of the page.</li>
                                      <ul className="wk-guidelines-inner-list">
                                            <li> a. Duplicates will eventually be merged or deleted.</li>
                                            <li>b. If you see the listing you want to write a review about, be sure not to create a new listing with the same name and location.  </li>
                                     </ul>
                                      <li>When you find the listing you would like to review, click on the listing to open the page.</li>
                                      <li>Click on the Create A Review button at the top of the page</li>
                                      <li>Please be sure to give an overall star as well as detailed star ratings and be sure that the overall star rating factors in the overall ratings you gave to the detailed ratings elements.</li>
                                      <li>Think of your readers. Who would read this? Please use words that target your readers and include as much detail as possible. For video reviews, please be concise and clearly show the things you think viewers of the video would like to see.</li>
                                      <li>If there is a specific person at the location who assisted you, please either add that person’s name or choose from the pulldown to further assist us on reviewing actual people who work at the location.</li>
                                      <li>Decide if you would like to post your review to Facebook or Twitter and check the appropriate box(es) if you would like to share your reviews. You can also choose for your review to be anonymous. Please check the box if you wish to do so.</li>
                                      <li>Please be sure to add videos and images whenever possible.</li>
                                      <li>Click “Submit” when your review is ready to go.</li>
                                   </ul>
                                   <p className="fs-14"><b><u>Anonymous Reviews</u></b>
                                   </p> 
                                   <p className="fs-14">Users have the option to leave anonymous reviews on any given listing. When a user leaves an anonymous review, all ties to the author, both visible and internal, are eliminated. This means a user cannot return and edit the review after leaving it. Users must write a minimum of 5 credited reviews before receiving the option to write reviews anonymously.
                                   </p> 
                                   <p className="fs-14"><b><u>2. Reading Reviews</u></b>
                                   </p>
                                   <p className="fs-14"><b>Research buying decisions through reading reviews and engaging with the community.</b>
                                   </p> 
                                   <p className="fs-14"><mark>Just like when you create a review, you start out by finding the listing.  Similarly, just search for the product, movie or business you are looking to read reviews about and click on the listing in the search results.  Then all you have to do is scroll down and read the reviews.</mark>
                                   </p>
                                   <p className="fs-14"><b><u>3. Participating</u></b>
                                   </p>
                                   <p className="fs-14"><b>Participate in improving WikiReviews by adding new listings, improving current listings, rating content, voting and patrolling.</b>
                                   </p>
                                   <p className="fs-14">Click on the Participate tab at the top of the page and you will be taken to a page where everything we need the wiki community to do is showcased. Here is a brief summary of the various things we need done (The actual list is much greater):
                                   </p>  
                                   <ul>
                                       <li>Add new listings on the site</li>
                                       <li>Improve listings by adding more information, correcting existing info, or deleting vandalism and reverting incorrect changes.</li>
                                       <li>Rate existing recently Added images, videos, reviews, and other content</li>
                                       <li>Vote on a host of issues from reviews to new categories to listing page changes.</li>
                                       <li>Patrol all recent changes</li>
                                   </ul>
                                   <p className="fs-14"><b>How to Find WikiReviews Pages That Need Editing</b>
                                   </p>
                                   <p className="fs-14">Wikis are perpetually incomplete and forever in need of improvement. Here are ways to find WikiReviews Pages that need editing.
                                   </p>
                                   <ul>
                                     <li>Add new listings on the site</li>
                                     <ul className="wk-guidelines-inner-list">
                                        <li>a. Post content with the understanding that it will be expanded, modified and rewritten.</li>
                                        <li>b. Retain pertinent information from a previous version</li>
                                        <li>c. Remove extraneous or redundant concepts.</li>
                                     </ul>
                                     <li>Click on the Participate tab at the top of the page</li>
                                     <ul className="wk-guidelines-inner-list">
                                            <li>a.	Determine if you would like to help Rate, Review or Moderate various elements of pages.</li>
                                            <li>b.	Narrow down your search by either geographic area or type of business, film or products</li>
                                            <li>c.	Help by patrolling recent changes, adding images, adding listing details and adding videos or reviewing flagged reviews amongst many other functions.</li>
                                            <li>d.	Drill down into exactly which task you would like to help with and Start!</li>
                                     </ul>
                                     <li>Evaluate Each Listing</li>
                                     <ul className="wk-guidelines-inner-list">
                                        <li>a.	Is the information correct? Does it need clarification?</li>
                                        <li>b.	Does it need more information for it to be helpful?</li>
                                        <li>c.	Can you add images or videos to help users?</li>
                                    </ul>
                                    <li>Click in the space provided to indicate a minor edit.</li>
                                    <ul className="wk-guidelines-inner-list">
									   <li>a.	Correct minor spelling, punctuation or grammatical errors.</li>
								    </ul>
                                    <li>Continue on patrolling edits and helping us make WikiReviews the best review site on the planet!</li>
                                   </ul>
                                   <p className="fs-14"><b><u>Participate Option #1: Add</u></b>
                                   </p>
                                   <p className="fs-14">The more information available on WikiReviews, the more useful it is to the community. Users can help expand and improve the site at any time by adding new listings. The following are a few different types of listings you can create on WikiReviews.
                                   </p>
                                   <ul>
                                       <li>Products</li>
                                       <ul className="wk-guidelines-inner-list">
                                        <li>Electronics</li>	
                                        <li>Books</li>
                                        <li>Books</li>
                                        <li>Household items</li>
                                        <li>Office supplies</li>
                                        <li>Sporting goods</li>
                                        <li>Pet products</li>
                                        <li>Tools &amp; equipment</li>
                                        <li>Luxury goods</li>	
                                       </ul>
                                       <li>Businesses</li>
                                       <ul className="wk-guidelines-inner-list">
                                        <li>Restaurants</li>	
                                        <li>Professional services</li>
                                        <li>Automotive services</li>
                                        <li>Retail shops</li>
                                        <li>Coffee bars</li>
                                        <li>Hotels</li>
                                      </ul>
                                      <li>Movies</li>
                                   </ul>
                                   <p className="fs-14"><b><u>Participate Option #2: Improve</u></b>
                                   </p>
                                   <p className="fs-14">We ask that listing creators fill out all requested information. This might not always happen, however, for a variety of reasons. As new listings are added, there will inevitably be missing or incorrect info.
                                                        Users can contribute by improving existing listings in a number of ways.
                                   </p>
                                   <ul className="wk-guidelines-inner-list">
                                        <li>Fill in missing info</li>
                                        <li>Correct info that is incorrect</li>
                                        <li>Update info that is outdated</li>
                                        <li>Revert incorrect edits to original info</li>
                                  </ul>
                                  <p className="fs-14">There is always new info to correct, update, and fill in, so feel free to check in on the Improve feed at regular intervals.
                                  </p>
                                  <p className="fs-14"><b><u>Participate Option #3: Rate</u></b>
                                  </p>
                                  <p className="fs-14">While WikiReviews hosts a wide variety of information, we also want to help users sort this information, providing the community with the most relevant, useful, and accurate content available. Rating content is one of our primary tools for making this happen.
                                  </p>
                                  <p className="fs-14">Users can rate many different types of content, helping to bury irrelevant or inaccurate content while sending the best content to the top. Types of content users can rate include:
                                  </p>
                                  <ul>
                                        <li>Tips</li>
                                        <li>Reviews</li>
                                        <li>Related Businesses</li>
                                        <li>Questions &amp; Answers</li>
                                        <li>Problems &amp; Solutions</li>
                                        <li>Owner Responses</li>
                                        <li>Offers</li>
                                        <li>Images</li>
                                        <li>Videos</li>
                                        <li>Community Review Projects</li>
                                        <li>Feedback</li>
                                        <li>Cool Facts</li>
                                    </ul>
                                    <p className="fs-14"><b><u>Participate Option #4: Vote</u></b>
                                    </p>
                                    <p className="fs-14">Voting allows WikiReviews community members to bring together their collective voice in making important decisions on reviews, listings, categories, and other issues. Users can vote on the following items:
                                    </p>
                                    <ul>
                                        <li>The status of Reviews</li>
                                        <li>New Categories/Subcategories</li>
                                        <li>Detailed Star Ratings</li>
                                        <li>Newly created listings (We do ask users to show proof of a new listing, but if we don't get the proof, we ask users to validate and vote on the existence of the listing).</li>
                                        <li>New template Answers in the Participate Section</li>
                                        <li>Business listing closures/moves, product discontinuations, etc.… (but only if there is no proof like a picture or link that validates the flag they are choosing)</li>
                                        <li>Questions and Answers</li>
                                        <li>Admins and expert reviewers</li>
                                    </ul>
                                    <p className="fs-14"><b><u>Participate Option #5: Patrol</u></b>
                                    </p>
                                    <p className="fs-14">As new edits are made by the community; the changes are funneled through the Patrol Recent Changes section. Here, other users can verify that the new information is not obviously inaccurate, in violation of WikiReviews policies, or the result of trolling. Users who wish to patrol can simply visit the Patrol Recent Changes feed and begin verifying changed items. 
                                    </p>
                                    <p className="fs-14"><b><u>Community Review Projects</u></b>
                                    </p>
                                    <p className="fs-14">Community Review Projects are a unique opportunity for our users to obtain direct feedback from our community on their own creative pursuits. Submissions should only be of your own personal work. Follow the tips below for a more successful experience.
                                    </p>
                                    <ul>
                                        <li>The first step to a successful upload is to actually submit a finished project. It can be tempting to skip ahead and ask for opinions, but community feedback won't be as helpful if your project is half-finished.</li>
                                        <li>Fill in all of the project information as thoroughly as possible. Include all relevant pictures, videos, links, data, and text. The better the community understands your project, the more accurately they can review it.</li>
                                        <li>Put some time into your review questions. What do you really want from the community's reviews? If you are just in this for the affirmation, that's fine, but if you are serious about taking your projects to the next level, phrase your questions to elicit answers that will genuinely help you improve.</li> 
                                    </ul>
                                    <p className="fs-14">Click on the Community Review Projects tab at the top of the page. You will then be taken to a page where you can upload the item you want reviewed. Please enter the following when uploading a project to be reviewed:
                                    </p>
                                    <ul>
                                        <li>Title - Be sure to give your listing a title and explain what it is that you are uploading</li>
                                        <li>Upload an image representing your project </li>
                                        <li>Information – Please enter in any additional information that the community would to know about you and your project</li>
                                        <li>Choose if you are entering in a single item to get reviewed or choose multiple items if your project entails comparing two different things (i.e., like the end of story, the end of a movie, a before and after pic, etc.)</li>
                                        <li>Specify the main question you would like reviewers to answer when rating the items. </li>
                                        <li>You can choose up to five additional questions you can ask the community to answer via the Likert Scale. Answers include: disagree strongly, disagree moderately, disagree a little, agree a little, agree moderately, or agree strongly.</li>
                                        <li>Choose up to three additional questions you would like users to answer. These can be any questions you want them to answer as they can provide open ended textual feedback.</li>
                                        <li>Choose the appropriate confidence level you want for your project. We recommend the 90% confidence option for most projects. Selecting a higher option will increase the accuracy of your results, but it could also significantly extend the length of the review process for your project.</li>
                                        <li>Please specify and geographic and demographic preferences you have. When you narrow down the project to specific geographic locations and demographics, then only those people who fit that criteria will in fact be doing the reviews.</li>
                                        <li>Submit the project for review.</li>
                                        <li>Kick start your project by sharing it to all your Facebook and Twitter friends and ask them to review your project on WikiReviews. </li>
                                        <li>Please be sure to read user feedback and feel free to review all user profiles of reviewers so you can see the reviewers experience with your subject matter.</li>
                                        <li>Please note the project will be reviewed to ensure it is properly setup and when approved, we will notify you via email that the project has started.</li>
                                    </ul>
                                    <h2  id="disputing-reviews">Disputing Reviews</h2>
                                    <p className="fs-14">For a business owner, online reviews can be extremely important. And while we would expect that good businesses would receive good reviews and bad businesses would receive bad reviews, this is not always the case. Sometimes, a patron might write a poor review based on a related experience they had that day. For example, a man gets a parking ticket for an expired meter while eating at a restaurant and then leaves an extremely poor review based solely on his emotions after receiving the ticket. Alternatively, a business owner might hire a reviewer to leave a poor review on the competition’s listing. Or a “troll” who has never even seen the business might leave a bad review for no reason at all. 
                                    </p>
                                    <p className="fs-14">With most review websites, when a user or business owner complains about fake reviews, nothing is done about it. At WikiReviews, we value your reputation and have created a method for resolving these types of issues. 
                                    </p>
                                    <p className="fs-14"><b><u>Business Owner Disputes Review</u></b>
                                    </p>
                                    <p className="fs-14">When a review is written on a business that has been claimed on WikiReviews by the owner, the business owner has the option to dispute that review. The owner is required to state why he or she is disputing the review and upload any documentation proving his/her point. The user whose review is being disputed can also post supporting information and verification if he/she so desires to contend against the owner’s dispute. Alternatively, they can simply ignore the dispute and the review will be evaluated based on its own merit. 
                                    </p>
                                    <p className="fs-14">Once the 7-day period (which starts as soon as the dispute is commenced by the business owner) is over, WikiReviews will randomly choose users who are on our site and let them know they have been chosen for our Jury System. These users will receive a notice at the top of their page informing them of the selection and requesting their help in categorizing the review as valid, not recommended, or fake. Voting is evaluated based on statistical analysis. If 70% or more of the community votes wants the review put into one area, that is what we will do. Otherwise, we let the review remain as-is. 
                                    </p>
                                    <p className="fs-14"><b><u>Community Disputed Review</u></b>
                                    </p>
                                    <p className="fs-14">Any member of the community with a registered account can flag a review for being irrelevant, incorrect, fake, etc. Once we get two flags from different users in a specified period (this period will change depending on our traffic levels), we will put up review into community voting just like in section 1 above. In this case, each person who flagged the review, as well as the business owner and reviewer are all notified about the dispute, and everyone has the option to submit comments and supporting evidence within the 7-day window. After the period ends and the dispute goes to the community jury, the same conditions from section 1 are used to evaluate and deliver judgment on the review.
                                    </p>
                                    <h2  id="personal-accounts">Personal Accounts</h2>
                                    <p className="fs-14">After creating an account and logging in, you will see a menu tray on the right hand side of the page. This tray is a helpful tool so that you can quickly and easily navigate to the most trafficked parts of the site.
                                    </p>
                                    <p className="fs-14"><b><u>User Profile</u></b>
                                    </p>
                                    <p className="fs-14">You can create a profile with information about yourself, your pictures, your likes/dislikes/hobbies/ professions, and education so that others who read your reviews can know more about you. This tends to give you much more credibility to others when they review your contributions to the site. In this section, show others what you are all about and most importantly, have fun! 
                                    </p>
                                    <p className="fs-14"><b><u><mark>Username</mark></u></b>
                                    </p>
                                    <p className="fs-14"><mark>Your <b>Username/Nickname</b> is associated with your User ID number. It appears on your profile page. Your Nickname is just to let users know more about you. Understand that changing usernames will confuse other Contributors and friends following you.</mark>
                                    </p>
                                    <p className="fs-14"><b><u>Real Name</u></b>
                                    </p>
                                    <p className="fs-14">Your <b>Real Name</b> is used to credit your contributions and awards. It appears with every edit. If a name is inappropriate, please click on the user’s flag for Inappropriate Name and in the open text box please type in the appropriate username violation: Imply Authority, Easily Confused with (open text to explain), Offensive, Celebrity Names, Too Long/Nonsense, Symbol/Numeric Names, and Email Address as Username. 
                                    </p>
                                    <p className="fs-14"><b><u>Violations consist of:</u></b>
                                    </p>
                                    <ul>
                                        <li>Names that imply a position of authority, such as "admin", "expert", "WikiReviews", etc.</li>
                                        <li>Names that closely resemble or are easily confused with current Contributors.</li>
                                        <li>Names that are offensive, inflammatory, hate-based, harassing, or sexual in nature.</li>
                                        <li>Names of living or <b>recently </b>deceased public figures, such as celebrities, politicians or other notorious individuals. </li>
                                        <li>Extremely lengthy, nonsensical names.</li>
                                        <li>Names that are made up predominantly of numbers and/or symbols.</li>
                                        <li>Usernames that contain e-mail addresses (e.g.<a href="mailto:joe@example.com?subject=contain">johndoe@example.com</a>)</li>
                                        <li>Any other name that detracts or inhibits the ability to productively create a helpful review site.</li>
                                    </ul>
                                    <p className="fs-14"><b><u>Pictures</u></b>
                                    </p>
                                    <p className="fs-14">Upload as many images as you’d like, but please only upload images you have the rights to. Copyrighted images will be deleted.
                                    </p>
                                    <p className="fs-14"><b><u>Settings</u></b>
                                    </p>
                                    <p className="fs-14">Be sure to properly review all of the profile and notification settings when you first set up your account.
                                    </p>
                                    <p className="fs-14"><b><u>Time Options</u></b>
                                    </p>
                                    <p className="fs-14">The time is displayed in local time, according to the set preferences in your user profile.
                                    </p>
                                    <p className="fs-14"><b><u>About Me</u></b>
                                    </p>
                                    <p className="fs-14">Be sure to fill in this section to let others know more about your work and education history.
                                    </p>
                                    <p className="fs-14"><b><u>My Watchlist</u></b>
                                    </p>
                                    <p className="fs-14">You can create a Watchlist for any listing you would like to follow. A Watchlist can be setup to notify you every time there is a new review (Reviews Only) or whenever there is any proposed or real change to the site.
                                    </p>
                                    <p className="fs-14"><b><u>My Favs List</u></b>
                                    </p>
                                    <p className="fs-14">In this section, add listings consistently to it so that you can create a general profile of your likes and recommendations. If a listing you’d like to recommend or favorite is not found on our site, please help us by creating a new listing with as much information as possible.
                                    </p>
                                    <p className="fs-14"><b><u>My Friends</u></b>
                                    </p>
                                    <p className="fs-14">You can find friends, import connects, and manage your friends list in the Connections tab on your profile page.
                                    </p>
                                    <p className="fs-14"><b><u>Inviting Friends</u></b>
                                    </p>
                                    <p className="fs-14">You can easily invite all your friends to join WikiReviews by clicking on the Facebook, Twitter and email accounts in the Connections tab on your profile page. You can also see and add all your friends who are already on WikiReviews in this section as well.
                                    </p>
                                    <p className="fs-14"><b><u>Reviews</u></b>
                                    </p>
                                    <p className="fs-14">All reviews are listed in this section on your profile page. Please know that you can edit, update, add media, and delete any of your reviews. Please note that reviews you have posted anonymously will not show up here and you cannot update, edit, or delete anonymous reviews.
                                    </p>
                                    <p className="fs-14"><b><u>My Drafts</u></b>
                                    </p>
                                    <p className="fs-14">In this area you can see all of the reviews you started to write but did not have time to finish. You can finish the reviews here and publish them! 
                                    </p>
                                    <p className="fs-14"><b><u>My Messages</u></b>
                                    </p>
                                    <p className="fs-14">You can see all your incoming, outgoing, and unread messages in the Messages tab in your profile page. If you have any new messages when you log back into your account, you will see a notification for the message in the notifications tab. If for any reason you get an abusive message, please post it on the Disruptive Users area of the Report Issues section.
                                    </p>
                                    <p className="fs-14"><b><u>After Login Feed</u></b>
                                    </p>
                                    <p className="fs-14">You can see all your incoming, outgoing, and unread messages in the Messages tab in your profile page. If you have any new messages when you log back into your account, you will see a notification for the message in the notifications tab. If for any reason you get an abusive message, please post it on the Disruptive Users area of the Report Issues section.
                                    </p>
                                    <ul>
                                        <li>See what’s trending</li>
                                        <li>View recent friends’ reviews or reviews on listings you follow</li>
                                        <li>Share your favorites</li>
                                        <li>Post a status update</li>
                                        <li>Check your recent activity on WikiReviews</li>
                                        <li>Follow other WikiReviews users</li>
                                    </ul>
                                    <p className="fs-14"><b><u>Protecting Your WikiReviews Account</u></b>
                                    </p>
                                    <ul>
							           <li>
								           Once you login to your account, your account will stay open until you manually log out.   
					                  </li>
						             <li>Choose a Secure Password
								      <ul className="wk-guidelines-inner-list">
                                        <li>Use a password that you can remember and that only you know.</li>
                                        <li>Be sure not to share your passwords with others.</li>
                                        <li>If you are sharing a public computer, please log out to ensure your information remains secure. </li>
                                      </ul>
							         </li>
						          </ul>
                                  <p className="fs-14"><b><u>Authenticate Your Account</u></b>
                                  </p>
                                  <p className="fs-14">We highly recommend authenticating your account via phone number or social media profile. This establishes you as a real person and a more trustworthy community member. 
                                                       Our community depends on real people like you. Let them know you’re the real deal by authenticating your account. 
                                  </p>
                                  <p className="fs-14"><b><u>Quitting WikiReviews</u></b>
                                  </p>
                                  <p className="fs-14">If you’ve decided it’s time to stop using WikiReviews, the simplest option is to simply stop logging in. If you want something more conclusive however, there are two options.
                                  </p>
                                  <p className="fs-14"><b><u>Deactivating Your Account</u></b>
                                  </p>
                                  <p className="fs-14">By deactivating your account, all of your information is hidden from view but can be restored at any time down the road by logging back into the site.
                                  </p>
                                  <p className="fs-14"><b><u>Deleting Your Account</u></b>
                                  </p>
                                  <p className="fs-14">You can delete your account after you have deactivated your account. Unlike deactivation, deletion is permanent. All your information will be permanently deleted from WikiReviews. For most users, we suggest deactivation, as it essentially accomplishes the same things as deletion without the permanent after-effect.
                                  </p>
                                  <h2 id="content-guidelines">CONTENT GUIDELINES</h2>
                                  <p className="fs-14"><b><u>General Guidelines</u></b>
                                  </p>
                                  <p className="fs-14">WikiReviews allows users to contribute different kinds of content, including reviews, photos, videos, events, votes, quick tips, private messages, and more. As we are striving to be the world’s largest site for reviews, we need everyone using the site to work together and adhere to the following guidelines. This will allow our site to thrive and benefit the whole Wiki Community:
                                  </p>
                                  <ul>
                                    <li>Keep it family friendly</li>
                                    <ul className="wk-guidelines-inner-list">
                                        <li>a. No profanity, threats, prejudiced comments, hate speech, sexually explicit language or other non-family-friendly content.</li>
                                        <li>b. No graphic reports of violent criminal activity or participation in drug-related activity.</li>
                                        <li>c. Reports of deaths will be considered on an individual basis.</li>
                                    </ul>
                                    <li>Reviews must be written by actual consumers and users.</li>
                                    <ul className="wk-guidelines-inner-list">
									<li>a. Reviews must be based on the users experience, not second-hand information or hearsay (unverified information, rumors or quotations from other sources or the reported opinions/experience of others).</li>
								    <li>b. Reviews written by ownership or management posing as guests will not be accepted. Current or past employees or anyone associated or related to employees of an establishment listed on our site are not allowed to post reviews.</li>
								   </ul>
                                   <li>Commercial e-mail addresses and contact information are not allowed in reviews, nor is promotional material of any kind. We reserve the right to reject any URL for any reason.</li>
                                   <li>Please follow standard etiquette when writing your review. Do not use ALL CAPS, excessive typographic symbols, HTML tags, or slang.</li>
                                   <li>Inappropriate content is prohibited
                                        <ul className="wk-guidelines-inner-list">
                                            <li>a. Colorful language and imagery is fine.  We just do not want to see threats, harassment, lewdness, hate speech, and other displays of bigotry. We also want to keep the site profanity free.</li>
                                        </ul>
								   </li>
                                   <li> Conflicts of interest
								      <ul className="wk-guidelines-inner-list">
                                        <li>a. Your contributions should be unbiased and objective. For example, businesses and their employees shouldn't write reviews about themselves or their competitors. Users should always act in the best interests of the community and not in their own selfish interests.</li>
                                      </ul>
								  </li>
                                  <li>Promotional content
                                    <ul className="wk-guidelines-inner-list">
                                        <li>a. Unless you're using your business owner’s account to add content to your business’s profile page, we do not want to see any promotional content. Let's keep the site useful for the Wiki community and not overrun with spam and other commercial content. 
                                        </li>
                                    </ul>
								  </li>
                                  <li>Relevance
                                    <ul className="wk-guidelines-inner-list">
                                        <li> a. Please make sure your contributions are relevant and appropriate.  Please be as detailed as possible with your review so that readers really get a good idea as to your experience.  Please refrain from just writing rants about a business's employment practices, extraordinary circumstances, or other matters that don't address the core of the consumer experience.</li>
                                        <li>b. Do not mention another product, business, or film in the review itself (just keep this to the related WikiReviews listing in which you are writing about). </li>   
                                    </ul>
								  </li>
                                  <li>Privacy
                                    <ul className="wk-guidelines-inner-list">
                                        <li>a. Do not publicize other people's private information. While some businesses may be identified by the people behind them (e.g., real estate agents, doctors, lawyers, accountants, etc.), you should generally avoid posting other people's full names, personal phone numbers, and email addresses and avoid uploading close-ups of other people without their permission. </li>	
                                    </ul>
                                  </li>
                                  <li>Intellectual property
                                    <ul className="wk-guidelines-inner-list">
                                        <li>a. Please do not take content from other sites or users as this will only cause problems.  Just keep the reviews you create to your own personal experiences and please create them yourself. Use only pictures and videos that you have taken and only upload Community Review Projects that you have created yourself. </li>
                                    </ul>
                                  </li>
                                  </ul>
                                  <p className="fs-14"><b><u>Additional Guidelines</u></b>
                                  </p>
                                  <p className="fs-14">We encourage reviewers to take their time and create detailed, passionate, and personal reviews. These are the reviews that will really “click” with our Wiki community.
                                                       Here are some additional thoughts for conscientious reviewers.
                                  </p>
                                  <ul>
									<li> Personal experience
                                        <ul  className="wk-guidelines-inner-list">
                                            <li>The Wiki community wants to hear about your firsthand consumer experience, not what you heard from your friends or others.  Tell your own story without resorting to broad generalizations and conclusory allegations. </li>
                                        </ul>
									</li>
									<li> Accuracy
                                        <ul  className="wk-guidelines-inner-list">
                                            <li>Make sure your review is factually correct. Feel free to air your opinions, but don't exaggerate or misrepresent your experience. Please try to just stick to the facts. </li>
                                        </ul>
									</li>
									<li> Review updates
                                        <ul  className="wk-guidelines-inner-list">
                                            <li> Review updates should reflect a new experience or interaction with the business. Don't tell the same story you've already told. If you'd like to add new insight to an old experience, just edit your review instead of creating a new update. </li>
                                        </ul>
									</li>
									<li> Tips
                                        <ul  className="wk-guidelines-inner-list">
                                            <li>Everyone would like to know little tips you can offer them. Add tips and warnings to listing pages to give users even more insight. </li>
                                        </ul>
									</li>
							       </ul>
                                   <p className="fs-14"><b><u>Photo Guidelines</u></b>
                                   </p>
                                   <p className="fs-14">As we all know, pictures say almost everything! You can upload photos and videos to the gallery section of any business.
                                   </p>
                                   <p className="fs-14">The best community photos and videos reflect the typical consumer experience (e.g., what the business looks like, what the business offers, the noise level etc.).
                                   </p>
                                   <p className="fs-14"><b><u>User Profile Guidelines</u></b>
                                   </p>
                                   <p className="fs-14">We are now living in a world where we share more about ourselves than ever before. You have the option of sharing your service providers with your friends and even your favorite movie theater.
                                   </p>
                                   <p className="fs-14">Please do try to fill out all of your profile information and add listings to your recommend, favorite, want, and watch lists to give your friends even more insight into new listings to try.
                                   </p>
                                   <p className="fs-14">The Wiki community enjoys reading reviews from users they know and trust (not those with profiles that are empty or laced with inappropriate content).
                                   </p>
                                   <p className="fs-14"><b><u>Messaging Guidelines</u></b>
                                   </p>
                                   <p className="fs-14">WikiReviews has a handy messaging feature to keep you connected with your fellow WikiReviewers. First off, please don't use this feature to harass other users or send other inappropriate content. Second, user accounts are for personal, non-commercial use only. Business owners should message reviewers using their free business owners account. In order to do so, we ask that they upload a clear photo of themselves to help personalize their message.
                                   </p>
                                   <p className="fs-14"><b><u>Business Owner Guidelines</u></b>
                                   </p>
                                   <p className="fs-14">Business owners now have an opportunity to communicate directly to their customers with no middleman in our feedback section. We suggest the following guidelines for this communication:
                                   </p>
                                   <ul>
								      <li>About Your Business: 
                                        <ul className="wk-guidelines-inner-list">
                                            <li>
                                            You can use the "About This Business" tab to tell people a little something special about your business. Please keep it relevant: don't use this feature to complain about your competitors, reviewers, and don't use it to seed keywords or post special offers or promotions — this is against our guidelines and we will have these removed.   
                                            </li>
                                         </ul>
                                    </li>
							        <li>Public Comments 
                                        <ul className="wk-guidelines-inner-list">
                                        <li>Private messaging is often the best way to resolve a dispute with an unhappy customer, but business owners can also address issues publicly by posting a public comment. As with private messaging, we ask that business owners upload a clear photo of themselves to help personalize their message. Don't use public comments to launch personal attacks, advertise, or offer an incentive to change a review.   
                                        </li>
                                        </ul>
									 </li>
									 <li>Video
                                        <ul className="wk-guidelines-inner-list">
                                            <li>
                                            Videos are a great way for advertisers to promote themselves on WikiReviews. Be as creative as you can, but please keep it clean. We do not approve of imagery showing violence, drug use, nudity, near nudity, or suggestive acts. Also, please don't use your video to disparage other businesses or users, request or solicit reviews from users, or respond to user reviews.   
                                            </li>
                                        </ul>
									 </li>
							       </ul>
                                   <p className="fs-14">WikiReviews reserves the right to remove a review or management response at any time for any reason.
                                   </p>
                                   <p className="fs-14">The reviews posted on WikiReviews are individual and highly subjective opinions. The opinions expressed in reviews are those of WikiReviews members and not of WikiReviews, Inc. We do not endorse any of the opinions expressed by reviewers or in management responses. We are not affiliated with any establishment listed or reviewed on this web site.
                                   </p>
                                   <h2 id="community-guidelines">COMMUNITY GUIDELINES</h2>
                                   <p className="fs-14">WikiReviews policies and guidelines are developed by the community to describe best practices, clarify principles, resolve conflicts, and otherwise further our goal of creating a free and trustworthy review site.
                                   </p>
                                   <p className="fs-14"><b><u>Assume Good Faith</u></b>
                                   </p>
                                   <p className="fs-14">Unless there is strong evidence to the contrary, assume that contributors are trying to help, not hurt the community. 
                                   </p>
                                   <p className="fs-14">The Assume Good Faith guideline does not require that contributors continue to assume good faith in the presence of obvious contrary evidence; however, do not assume any more intentional wrongdoing than the evidence clearly supports, and given equally plausible interpretations of the evidence, choose the most positive one. If criticism is needed, discuss the contributors' actions, but avoid accusing others of harmful motives without clear evidence. 
                                   </p>
                                   <p className="fs-14">When disagreement occurs, try to the best of your ability to explain and resolve the problem, not cause more conflict, and give others the opportunity to reply in kind. Consider whether a dispute stems from different perspectives, and look for ways to reach consensus. 
                                   </p>
                                   <p className="fs-14">When doubt is cast on good faith, continue to assume good faith yourself where you can. Be civil and follow dispute resolution processes rather than attacking contributors. If you wish to express doubts about the conduct of fellow WikiReviews community members, please substantiate those doubts with specific relevant evidence, so that people can understand the basis for your concerns. Although bad conduct may seem to be due to bad faith, it is usually best to address the conduct without mentioning motives, which might exacerbate resentments all around. 
                                   </p>
                                   <p className="fs-14">Be careful about citing this principle too aggressively, because just as one can incorrectly judge that another is acting in bad faith, so too can one mistakenly conclude that bad faith is being assumed, and encouragements to "Assume Good Faith" can themselves reflect negative assumptions about others if a perceived assumption of bad faith was not clear-cut. 
                                   </p>
                                   <p className="fs-14"><b><u>About good faith</u></b>
                                   </p>
                                   <p className="fs-14">Everyone makes mistakes, both behavioral (such as personal attacks) and content-based (such as adding incorrect information). Most of the time, we can correct such mistakes with simple reminders. However, there will be disagreements on WikiReviews for which no policy or guideline has an easy answer. When disagreements happen, ill intent may not be involved. Keep a cool head, and consider a dispute resolution if disagreements seem intractable; many of them are not. 
                                   </p>
                                   <p className="fs-14">Violation of policies, such as engaging in sock-puppetry, violating consensus, and so on, may be perpetrated in either good or bad faith. There are processes for dealing with these, and sanctions for repeated violation of policy will apply regardless of whether bad faith was involved or not. 
                                   </p>
                                   <p className="fs-14"><b><u>Good faith and newcomers</u></b>
                                   </p>
                                   <p className="fs-14"><b>Please do not bite the newcomers.</b> It is important to be patient with newcomers, who will be unfamiliar with WikiReviews culture and rules, but may nonetheless turn out to be valuable contributors. 
                                   </p>
                                   <p className="fs-14">A newcomer's behavior probably seems appropriate to him/her, and a problem in that regard usually indicates unawareness or misunderstanding of WikiReviews culture. It is not uncommon for a newcomer to believe that an unfamiliar policy should be changed to match their notion of how things should function, especially if they notice that there is already some level of disagreement over the policy in question. Similarly, many newcomers want to have their contributions to listings accepted without question, especially those which pertain to subjects on which they have extensive knowledge. Behaviors arising from these perspectives, while possibly misguided, are usually not malicious and should not be treated as such. Many new users who lack an intuitive grasp of WikiReviews customs are gradually brought around, once the logic behind these customs becomes clearer to them. 
                                   </p>
                                   <p className="fs-14"><b><u>Demonstrate good faith</u></b>
                                   </p>
                                   <p className="fs-14">In addition to assuming good faith, encourage others to assume good faith by demonstrating your own good faith. You can do this by articulating your honest motives and by making edits that show your willingness to compromise, interest in improving WikiReviews, adherence to policies and guidelines, belief in the veracity of your edits, avoidance of gaming the system, and other good-faith behavior. Showing good faith is not required, but it aids smooth and successful interactions with contributors. 
                                   </p>
                                   <p className="fs-14"><b><u>Dealing with bad faith</u></b>
                                   </p>
                                   <p className="fs-14">Even if bad faith is evident, do not act uncivilly yourself in return, attack others, or lose your cool over it. It is ultimately much easier for others to resolve a dispute and see who is breaching policies, if one side is clearly acting appropriately throughout. 
                                   </p>
                                   <p className="fs-14">WikiReviews administrators and other experienced Contributors involved in dispute resolution will usually be glad to help, and are very capable of identifying policy-breaching conduct if their attention is drawn to clear and specific evidence. 
                                   </p>
                                   <p className="fs-14"><b><u>Accusing others of bad faith</u></b>
                                   </p>
                                   <p className="fs-14">Although the assumption of good faith is dictated by WikiReviews policy, there is no corresponding policy requiring contributors to act in good faith. Thus, accusations of bad faith serve no purpose. They also can be inflammatory and hence can aggravate a dispute. It can be seen as a personal attack if bad faith motives are alleged without clear evidence that the others' action is actually in bad faith and harassment if done repeatedly. The result is often accusations of bad faith on your part, which tends to create a nasty cycle. 
                                   </p>
                                   <p className="fs-14"><b><u>Civility</u></b>
                                   </p>
                                   <p className="fs-14">Although the assumption of good faith is dictated by WikiReviews policy, there is no corresponding policy requiring contributors to act in good faith. Thus, accusations of bad faith serve no purpose. They also can be inflammatory and hence can aggravate a dispute. It can be seen as a personal attack if bad faith motives are alleged without clear evidence that the others' action is actually in bad faith and harassment if done repeatedly. The result is often accusations of bad faith on your part, which tends to create a nasty cycle. 
                                   </p>
                                   <ul>
                                        <li>
                                            Participate in a respectful and considerate way, and avoid directing offensive language at other users.
                                        </li>
                                        <li>Do not ignore the positions and conclusions of others.</li>
                                        <li>
                                            Try to make coherent and concise arguments rather than simply attacking others, and encourage others to do the same.
                                        </li>
                                    </ul>
                                    <p className="fs-14"><b>Civility</b> is part of WikiReviews code of conduct. The civility policy is a standard of conduct that sets out how WikiReviews contributors should interact. Stated simply, contributors should always treat each other with consideration and respect. To keep the focus on improving the review site and to help maintain a pleasant editing environment, contributors should behave politely, calmly, and reasonably, even during heated debates. 
                                    </p>
                                    <p className="fs-14">This policy describes the standards of behavior expected of users when they interact, and appropriate ways of dealing with problems that may arise. It applies to all Contributors and all interaction on WikiReviews, including on user and listing talk pages, and in any other discussion with or about fellow WikiReviews.
                                    </p>
                                    <p className="fs-14"><b><u>Co-operation and civility</u></b>
                                    </p>
                                    <p className="fs-14">Differences of opinion are inevitable in a collaborative project. When discussing these differences, some contributors can seem unnecessarily harsh, while simply trying to be forthright. Other contributors may seem oversensitive when their views are challenged. Silent and faceless words on talk pages do not transmit fully the nuances of verbal conversation, sometimes leading to misinterpretation of a contributor's comments. An uncivil remark can escalate spirited discussion into a personal argument that no longer focuses objectively on the problem at hand. Such exchanges waste our efforts and undermine a positive, productive working environment. Resolve differences of opinion through civil discussion; disagree without being disagreeable. Discussion of other contributors should be limited to polite discourse about their actions. 
                                    </p>
                                    <p className="fs-14">Contributors are expected to be reasonably cooperative, to refrain from making personal attacks, to work within the scope of policies, and to be responsive to good-faith questions. Try to treat your fellow contributors as respected colleagues with whom you are working on an important project. Be especially welcoming and patient towards new users. Welcome other people to edit the listings, but do discourage non-constructive edits. Remember, you can agree to disagree with other contributors and still be civil at the same time. 
                                    </p>
                                    <p className="fs-14"><b><u>Avoiding incivility</u></b>
                                    </p>
                                    <p className="fs-14">Incivility – or the appearance of incivility – typically arises from heated content disputes.
                                    </p>
                                    <ul>
                                        <li> 
                                            Explain yourself. Not sufficiently explaining edits can be perceived as uncivil, whether that's the contributor's intention or not. All edit explanations will be posted on the talk page.
                                        </li>
                                        <li>
                                            Be careful with user warning templates. Be careful about issuing template messages to contributors you're currently involved in a dispute with, and exercise caution when using template messages for newcomers (Please do not bite the newcomers). Consider using a personal message instead of, or at least in addition to, the template message.
                                        </li>
                                        <li>
                                            Try not to get too intense. Other people can misread your passion as aggression. Take great care to avoid the appearance of being heavy-handed or bossy. Nobody likes to be bossed around by a contributor who appears to believe that they are "superior"; nobody likes a bully.
                                        </li>
                                        <li>
                                            Avoid editing while you're in a bad mood or under the influence, it does spill over.   
                                        </li>
                                        <li>
                                            Take a real-life check; disengage by two steps to assess what you're about to say (or have just said). Asking yourself "How would I feel if someone said that to me?" is often not enough, many people can just brush things off, and its water off a duck's back. So, to get a better perspective, ask yourself: "How would I feel if someone said that someone I love who can't just "brush it off?" If you'd find that unacceptable, then don't say it. And, if you've already said it, strike through it and apologize. 
                                        </li>
                                    </ul>
                                    <p className="fs-14">Just because we're online and unpaid doesn't mean we can behave badly to each other. People working together in a newspaper office aren't supposed to get into punch-ups in the newsroom because they disagree about how something's worded or whose turn it is to make the coffee. Nor are volunteers working at the animal rescue center allowed to start screaming at each other over who left ferrets in the filing cabinet or the corn snake in the cutlery drawer. In fact, there's pretty much nowhere where people working together to do something good can get into fist-fights, shouting matches, hair-pulling or name-calling. Same applies here, too. 
                                    </p>
                                    <p className="fs-14">Incivility consists of one or more of the following behaviors: personal attacks, rudeness, disrespectful comments, and aggressive behaviors---when such behavior disrupts the project and leads to unproductive stressors and conflict. Contributors make mistakes, so a few minor incidents of incivility are not in themselves a major concern. However, an unfolding pattern of incivility is disruptive and unacceptable. If incivility becomes harassment or egregious personal attacks, then it may result in blocks. A single act of severe incivility can also result in blocks: for instance, single episodes of extreme verbal abuse or profanity directed at another contributor, or a threat against another person can each result in blocks. 
                                    </p>
                                    <p className="fs-14">In general, be understanding and non-retaliatory in dealing with incivility. If others are uncivil, be understanding (people do say things when they get upset) rather than judgmental, and do not respond in kind. If necessary, point out gently that you think the comment might be considered uncivil, and make it clear that you want to move on and focus on the content issue. Bear in mind that the contributor may not have considered it uncivil -WikiReviews is edited by people from many different backgrounds, and standards vary. Consider too the option of ignoring isolated examples of incivility, and simply moving forward with the content issue. Only take things to dispute resolution (see below) if there is an ongoing problem you can't resolve. 
                                    </p>
                                    <p className="fs-14">This policy is not a weapon to use against other contributors. To insist that a Contributor be sanctioned for an isolated, minor offense, or to treat constructive criticism as an attack, is itself potentially disruptive, and may result in warnings or even blocks if repeated. 
                                    </p>
                                    <p className="fs-14"><b><u>Identifying incivility</u></b>
                                    </p>
                                    <p className="fs-14">It is sometimes difficult to make a hard-and-fast judgment of what is uncivil and what is not. Such a judgment may need to consider such matters as (i) the intensity of the language/behavior; (ii) whether the behavior has occurred on a single occasion, or is occasional or regular; (iii) whether a request has already been made to stop the behavior, and whether that request is recent; (iv) whether the behavior has been provoked; and (v) the extent to which the behavior of others need to be treated at the same time.
                                    </p>
                                    <p className="fs-14">The following behaviors can all contribute to an uncivil environment:
                                    </p>
                                    <p className="fs-14"><b><i>A. Direct rudeness</i></b>
                                    </p>
                                    <ul>
                                        <li>
                                        rudeness, insults, name-calling, gross profanity or indecent suggestions;
                                        </li>
                                        <li>
                                        personal attacks, including racial, ethnic, sexual, gender-related and religious slurs, and derogatory references to groups such as social classes or nationalities;
                                        </li>
                                        <li>
                                        ill-considered accusations of impropriety; <br/> belittling a fellow contributor, including the use of judgmental talk-page posts (e.g. "that is the stupidest thing I have ever seen”)
                                        </li>
						            </ul>
                                    <p className="fs-14"><b><i>B. Other uncivil behaviors</i></b>
                                    </p>
                                    <ul>
							         <li>
                                       taunting or baiting: deliberately pushing others to the point of breaching civility even if not seeming to commit such a breach themselves;
                                    </li>
							        <li>
								       harassment, including Wikihounding, bullying, personal or legal threats, posting of personal information, repeated email or user space postings;
									</li>
                                    <li>
                                        sexual harassment;
                                    </li>
                                    <li>
                                        lying;
                                    </li>
                                    <li>
                                        quoting another contributor out of context to give the impression they hold views they do not hold, or to malign them
                                    </li>
						          </ul>
                                  <p className="fs-14">In addition, lack of care when applying other policies can lead to conflict and stress. For instance, referring to a user's good-faith edits as vandalism may lead to their feeling of being unfairly attacked. Use your best judgment, and be ready to apologize if you turn out to be wrong. 
                                  </p>
                                  <p className="fs-14"><b><u>Dealing with incivility</u></b>
                                  </p>
                                  <p className="fs-14">First, consider whether you and the other contributor may simply have misunderstood each other. Clarify, and ask for clarification. 
                                  </p>
                                  <p className="fs-14">Consider the possibility that something you said or did wrongly provoked a defensive, irritated or fed-up response. Be prepared to apologize for anything which you could / should have done better!
                                  </p>
                                  <p className="fs-14">Even if you're hurt, be as calm and reasonable as possible in your response. The other contributor probably didn't mean to cause you pain or harm. 
                                  </p>
                                  <p className="fs-14">Explain, clearly but kindly, exactly what you felt was uncivil. Sometimes it helps to let the other contributor know how their edit made you feel. Contributors aren't mind-readers.
                                  </p>
                                  <p className="fs-14">If none of this is working, either walk away (if the other person isn't damaging WikiReviews or being uncivil/unkind to other contributors), or get help. Dispute resolution from uninvolved contributors might resolve something and it is always worth a try.
                                  </p>
                                  <p className="fs-14">No matter how much you're being provoked, resist the temptation to snap back. It never works well; it just makes things worse. Strive to become the contributor who can’t be baited. 
                                  </p>
                                  <p className="fs-14">In "emergency" situations (where the other contributor needs to be stopped in their tracks to avoid causing serious disruption) take it to the Report Issues area for heated discussions. 
                                  </p>
                                  <p className="fs-14">For longer-term, less acute, but persistently unkind/uncivil contributors, please report it in the Help section. 
                                  </p>
                                  <p className="fs-14"><b><u>Removing uncivil comments</u></b>
                                  </p>
                                  <p className="fs-14">Where the uncivil comment is yours, any of these options will help to reduce the impact: 
                                  </p>
                                  <ul>
								    <li>	
							          If someone is taking offense at your comment where none was intended, calmly explain what you meant.
								    </li>
								    <li>	
							           Quietly remove it, or rewrite the comment to be more civil – Usually only a good idea if you think better of it before anyone took offense to it. If someone has taken offense already, you should acknowledge the change.
								    </li>
								    <li>	
							          Simply apologize. This option never hurts, and can be combined well with any of the others. Even if you feel the thrust of your words is true, or that they are misunderstanding what you meant, you can still apologize for the offense caused.
								    </li>
								    <li>	
						              In the event of rudeness or incivility on the part of another contributor, it may be appropriate to discuss the offending words with that contributor, and to request that contributor to change that specific wording. Some care is necessary, however, so as not to further inflame the situation. It is not normally appropriate to edit or remove another contributor's comment. Exceptions include removing obvious trolling or vandalism. Derogatory comments about another contributor may be removed by any contributor.
								    </li>
							     </ul>
                                 <p className="fs-14"><b><i>Different places; different atmospheres</i></b>
                                  </p>
                                  <p className="fs-14">Listing talk pages should be, overall, considered to be professional work-spaces. They're places to talk about how to improve the listing, and to discuss the listing (though it's OK for conversations to wander into related areas, or go more into depth than the listing does, as that helps with research and gives ideas on improvement).
                                  </p>
                                  <p className="fs-14"><b><u>No personal attacks or harassment</u></b>
                                  </p>
                                  <p className="fs-14">Contributors are expected to avoid personal attacks and harassment of other WikiReviewers. This applies equally to all WikiReviewers. It is as unacceptable to attack a user who has a history of foolish or boorish behavior, or even one who has been subject to disciplinary action by the Mediation & Arbitration Committee. WikiReviews encourages a positive online community: people make mistakes, but they are encouraged to learn from them and change their ways. Personal attacks and harassment are contrary to this spirit, damaging to the work of building a great review site, and may result in blocks. 
                                  </p>
                                  <p className="fs-14"><b><u>Apologizing: It's OK to say sorry</u></b>
                                  </p>
                                  <p className="fs-14">Disputes, and even misunderstandings, can lead to situations in which one party feels injured by the other. There's no loss of face in apologizing. We all make mistakes, we all say the odd hurtful thing, and we all have bad days and bad moments. If you have a sneaky feeling you owe someone an apology, offer the apology. Apologizing doesn't hurt you. 
                                  </p>
                                  <p className="fs-14">Remember, though, that you can't demand an apology from anyone else. It will only get their back up and make it either less likely to happen, or to be totally insincere if you do get an apology. Never be too proud to make the first move when it comes to saying sorry. An apology provides the opportunity for a fresh start, and can clear the air when one person's perceived incivility has offended another. 
                                  </p>
                                  <p className="fs-14"><b><u>Guide to Controlling Heated Listings</u></b>
                                  </p>
                                  <ul>
                                    <li>Add appropriate templates to the top of a discussion thread</li>
                                    <ul className="wk-guidelines-inner-list">
                                        <li>Add Inappropriate Discussion.</li>
                                        <li>Add Controversial Topic.</li>
                                        <li>Add a note to discourage an edit war.</li>
                                    </ul>
                                    <li>Contact Admins in the Report Issues section of the site if needed.</li>
                                    <li>Listings can be protected from further edits if needed. Just request listing page protection on the page flag.</li>
                                  </ul>
                                  <p className="fs-14"><b><u>Avoid Angering Others When Possible</u></b>
                                  </p>
                                  <p className="fs-14">Talk pages provide a forum to discuss improvements and other aspects related to a listing. Here are a few suggestions to help remain calm and reasonable in response to adverse comments.
                                  </p>
                                  <ul>
                                    <li>Review the basic principles regarding how to defend a listing on WikiReviews.</li>
                                    <li>Focus on constructive comments. Assume good faith.</li>
                                        <ul className="wk-guidelines-inner-list">
                                            <li>Reread comments to clarify your own misconceptions or misinterpretations.</li>
                                        </ul>
                                    <li>Formulate your own perspective</li>
                                        <ul className="wk-guidelines-inner-list">
                                            <li>Breathe deeply to relax and remain calm.</li>
                                        </ul>
                                   <li>Write a comment</li>
                                        <ul className="wk-guidelines-inner-list">
                                            <li>Support reason with logic and research.</li>
                                            <li>Avoid the overuse of bolding, italics, or capital letters as they may imply an angered tone of voice.</li>
                                        </ul>
                                    <li>Edit and remove statements with potential to anger, such as sarcasm.</li>
                                    <li>Click to post your comment and if you experience strong emotions, you may consider posting later.</li>
                                  </ul>
                                  <p className="fs-14"><b><u>General Etiquette</u></b>
                                  </p>
                                  <p className="fs-14">The following defines the required etiquette of contributing to WikiReviews.
                                  </p>
                                  <ul>
                                        <li>Be polite.</li>
                                        <li>If you feel yourself getting heated, take a break to cool off.</li>
                                        <li>
                                            Each entry belongs to everybody; no matter how long you have worked on the page, others are free to improve it.
                                        </li>
                                        <li>
                                            Do not alter the well-sourced text of someone else unless there is something wrong with the reference or there is a consensus to make the change.
                                        </li>
                                        <li>Do not engage in harassment.</li>
                                        <li>
                                            Be prepared to explain any contribution you have made if asked. If you are not prepared to do so in the case that a question is asked, do not contribute.
                                        </li>
                                 </ul>
                                 <p className="fs-14"><b><u>Sockpuppetry</u></b>
                                  </p>
                                  <p className="fs-14">All users are restricted to a single account. Registering for more than one account is forbidden, and the use of more than one account in contributing to WikiReviews will lead to all accounts being blocked. 
                                  </p>
                                  <p className="fs-14"><b><u>Harassment</u></b>
                                  </p>
                                  <p className="fs-14">It is common for people to become heated or feel competitive when they are working with a team of others, and this can lead to one-time incivilities. While inappropriate, such singular activities can be forgiven if the users recognize their mistake. Harassment goes beyond a one-time event and can lead to account closure. The following constitutes the types of behavior defined as harassment that can lead to such a block:
                                  </p>
                                  <ul>
                                        <li>
                                            Hounding: This is defined as stalking another user on the various entries they are contributing to in order to disagree with their contributions throughout the site. Hounding often occurs after engaging in a dispute with another user on a page, leading one of the two involved in the dispute to start disputing their opponent’s contributions on other pages as well, without any other provocation than the initial unrelated dispute.
                                        </li>
                                        <li>
                                            Threats: This is defined as threatening to do something to another user in the future or giving ultimatums to other users. A threat is usually something that would harm the other user, either online or off, and is not acceptable WikiReviews behavior.
                                         </li>
                                        <li>
                                            Double Jeopardy: Once an incident has been resolved by the community, the community should not continue to remind a user of their transgression or continue to pursue further action against them. WikiReviews administrators can block a user, but if they are not blocked for their behavior, the decision and the user’s contributions should be respected.
                                        </li>
                                        <li>
                                            Offensive Language: The use of offensive language is not acceptable under any circumstances as it can create an unwelcoming environment for some users. This includes using offensive language to describe other editors or their work or using offensive language in general when not directly tied to any other user or contribution. Repeated use of offensive language will be considered harassment and dealt with as such.
                                         </li>
                                        <li>
                                            Hate Based or Racist Speech: The use of hate-based or racist speech is strictly prohibited on WikiReviews. Repeated use of such speech will result in a temporary ban of the offending account.
                                        </li>
                                  </ul>
                                  <p className="fs-14"><b><u>Verifiability</u></b>
                                  </p>
                                  <p className="fs-14">Everything you add to WikiReviews must be independently verifiable by other WikiReviews users. This means you must always include sources that show where you found the information. The following is an overview of how to do this:
                                  </p>
                                  <ul>
                                        <li>
                                            Ensure that the source you are adding can be reasonably accessed by other users. A URL helps with this, however if you are using an offline source, include all relevant publication information so that it can be found by others offline as well (Title, Author, Publisher, Date, Page number).
                                        </li>
                                        <li>
                                            If you cannot find a source to validate the information, do not add it.
                                        </li>
                                        <li>
                                            If multiple sources dispute the same fact, be sure to acknowledge this dispute in your content.
                                        </li>
                                        <li>
                                            A verifiable source must be a legitimate one. Anyone can go online and write what they wish in a blog, but that does not make their writing a source of verifiable facts. Try to use sources that are either written directly by the company involved or by publications with some oversight. Think newspapers, magazines, or websites with editorial oversight.
                                       </li>
                                        <li>
                                            If you see material that is not properly sourced or where the source does not match the content, be bold and remove it. This includes removing completely unsourced pages in their entirety.
                                        </li>
                                        <li>
                                        If you are adding hours of operation for a restaurant, using the menu with the hours on it and showing that the menu has the business name on it will be sufficient.
                                        </li>
                                        <li>
                                        Be sure when adding supporting proofs to make sure you include the name of the business in the picture. If you are adding a website, taking a picture of the website link on a sign will not help to prove it is correct unless you include the name of the business in the same picture. Adding product information that is listed on a box is great, but in that case, make sure the name of the product on the box is visible. If adding something from a product manual, be sure to list that as the reference and what page number in the manual the text is on. 
                                        </li>
							      </ul>
                                  <p className="fs-14"><b><u>Spam</u></b>
                                  </p>
                                  <p className="fs-14">WikiReviews is not a portal for spam, and all spam should be immediately removed. The following describes what constitutes spam on WikiReviews:
                                  </p>
                                  <ul>
                                        <li>
                                           The use of a company name or product name across a span of competitor’s pages, such that the product or company consistently appears to be either equivalent or superior to the subject of the entry itself.
                                        </li>
                                        <li>
                                           The adding of more than a handful of external links to any one entry.
                                        </li>
							            <li>
							                The adding of pages for minor products within a product line, rather than merging these products into a single entry.
							            </li>
							            <li>
							                The adding of pages on national subsidiaries for multinational corporations (such as McDonald’s Canada, McDonald’s Mexico, and dozens of similar potential subjects).
							            </li>
                                        <li>
                                             Adding entries on entities or items for which no verifiable information (according to the WikiReviews verifiability policy) can be found.
                                        </li>
						            </ul>
                                    <p className="fs-14"><b><u>Personal Interest Editing</u></b>
                                    </p>
                                    <p className="fs-14">If you are an employee of the company being portrayed, feel free to contribute to WikiReviews. However, an entry is not the property of or controlled by the company it is describing. Therefore, the content cannot be owned or controlled by the described company or those that produce the described product. If you are personally connected to the subject of an entry, while your contributions are welcome, WikiReviews’ policy is to follow community consensus to drive content, not the directives of any external individual or organization.
                                    </p>
                                    <p className="fs-14"><b><u>Referenced Sources</u></b>
                                    </p>
                                    <p className="fs-14">A good reference abides by the following:
                                    </p>
                                    <ul>
                                        <li>
                                           It has been published with some editorial oversight and by a publisher that stands behind the words said in the article (no opinion or fan pieces).
                                        </li>
                                        <li>
                                           The piece was written in a neutral tone and was not intended to hype its subject matter in any way.
                                        </li>
							           <li>
							               There is no proven conflict of interest or redaction that might affect the integrity of the piece’s information.
							          </li>
						          </ul>
                                  <p className="fs-14"><b><u>Copyright Policy</u></b>
                                  </p>
                                  <p className="fs-14">All material on WikiReviews must comply with US copyright laws in full. That means the following cannot be added and must be removed if found:
                                  </p>
                                  <ul>
							        <li>
								      Plagiarized content from any non-public-domain text.
								   </li>
							      <li>
									  Any unattributed content written by authors where their text is subject to copyright.
							     </li>
							     <li>
                                    Close paraphrasing, even if cited properly, to any copyrighted text.
                                </li>
							     <li>
							       The unattributed copying of lyrics or dialogue from any live-broadcast or recorded media—including music, television, film, books, webcasts, and any other potentially copyrighted material.
					            </li>
							    <li>
							       Images that are not in the public domain, or where no permission of use has been received from the photographer or owner of the image.
							    </li>
							    <li>
							      The use of parody imagery or parody text, even if this excludes the text or image from copyright laws.
							    </li>
							    <li>
							      Copying directly from potentially non-copyrighted materials such as business brochures, corporate documentation, unpublished drafts, government reports, personal communications, and so forth.
							    </li>
						     </ul>
                             <h2  id="listing-pages">LISTING PAGES</h2>
                             <ul>
                               <li>Review our Content Guidelines for proper format.
                               </li>
                               <li>Next to each main area of a listing page you will see flags.  Please use those flags to report any inaccuracies or changes to the page.
						       </li>
                               <li>You may also click on the Edit Tab at the top of the listing or at the right of each desired section
						       </li>
                               <li>Please do the following:
                                <ul className="wk-guidelines-inner-list">
                                    <li>Check for spelling, punctuation and grammatical errors.</li>
                                    <li>
                                        Add additional information and text about the listing as well as images and videos.
                                    </li>
                                </ul>
						       </li>
                               <li>Click on Submit at the bottom of the listing, to save your changes.</li>
                               <li>Please comply with our policies</li>
                               <li>Plagiarism is Illegal.</li>
                               <li>Use correct capitalization.
                                    <ul className="wk-guidelines-inner-list">
                                        <li>Always capitalize the first and last word of a title.</li>
                                        <li>
                                           Capitalize every word except listings, coordinate conjunctions and prepositions. These include<i> a, an, and, at, but, by, else, for, from, if, in, nor, of, off, on, or, out, over, the, then, to, up, with and when.</i> 
                                        </li>
                                        <li>
                                            Capitalize prepositions that are used as part of a verb. These include Make Out, Put Off, Get Over, Prepare For, Go From, Get Along With, Be Without, Act As, etc.
                                        </li>
                                        <li>
                                            Prepositions for the above rule also include by, for, from, in, into, of, off, on, onto, out, over, up, with and without.
                                        </li>
                                    </ul>
                                </li>
                                <li>Limit punctuation:
                                    <ul className="wk-guidelines-inner-list">
                                        <li>Symbols create problems with certain browsers.</li>
                                        <li>
                                            Avoid a period, exclamation mark or question mark at the end of a title.
                                        </li>
                                        <li>Avoid / and &amp;.</li>
                                        <li>Parentheses are acceptable.</li>
                                    </ul>
						       </li>
                               <li>
						        Clarify most abbreviations. Unless the abbreviation is widely used (e.g. TV, CD, DVD) spell out the words and put the abbreviation in parentheses.
						       </li>
                             </ul>
                             <p className="fs-14"><b><u>Requesting User or Listing Page Protection:</u></b>
                             </p>
                             <p className="fs-14">At times, a WikiReviews listing, its Discussion page or your personal user talk page may need temporary or permanent protection from high traffic or frequent vandalism. Here is how to request protection from any further edits.
                             </p>
                             <ul>
                                 <li>We have the following two levels of protection:</li>
                                 <ul className="wk-guidelines-inner-list">
                                        <li>Semi-protection – This allows only registered users and Admins to edit. All pages on our site are at least semi-protected.</li>
                                        <li>Full-Protection allows only Admins to edit.</li>
                                 </ul>
                             </ul>
                             <p className="fs-14">To protect a listing, please review the history page for a pattern of frequent reversions or flags. Contact an Admin or the Help Section of the site to request protection. When requesting protection, please provide an adequate reason, such as “frequent target of vandalism.” 
                             </p>
                             <p className="fs-14">Request page protection, once you believe vandalism will cease. This may be anywhere from a week or up to a month thereafter. The only time a listing should be permanently protected is only if they are severely vandalized on a daily basis; rather than one week out of the year. Certain of our pages will be permanently protected such as our policies and terms of use pages. 
                             </p>
                             <p className="fs-14"><b><u>Help Publicize Your Listing Page on WikiReviews</u></b>
                             </p>
                             <p className="fs-14">While many users come to WikiReviews for fun and to help others by sharing reviews, some come to promote themselves or their businesses. Here are few easy ways to do this:
                             </p>
                             <ul className="list1">
                                <li>Please be sure to first claim your listing.</li>
                                <li>
                                Validate yourself as the responsible person for the listing if you are the owner or   are the authorized person responsible for claiming the listing. Your listing will be “Verified” if you do a phone validation.  Otherwise, your listing will be verified by the WikiCommunity over time.
                                </li>
                                <li>
                                Enhance your listing by adding as much detail to the listing as possible and be sure to add as much additional information as possible. 
                                </li>
                                <li>Upload all images and video best suited to showcase your listing.  </li>
                                <li>
                                Place any links to videos or specific pages to your own website that you feel you would like to use to showcase your listing.
                                </li>
                                <li>Be sure to add your Facebook and Twitter Accounts for Display </li>
                                <li>
                                Respond to every user who creates a review so you can thank them for their valuable feedback and learn how to make their experience better.
                                </li>
                                <li>
                                Settle and try to resolve all issues amicably and nicely. It is always better to use niceties to resolve issues than getting angry or upset.
                                </li>
                                <li>
                                Be sure not to overload the site with irrelevant links and images and videos and extraneous information not related to the listing.
                                </li>
                                <li>Do not spam by being overly commercial in nature. </li>
                            </ul>
                            <p className="fs-14">Know that all of our outbound links are “No Follow” so linking out to help your search rankings will not help. This means that the links do not get indexed by the search engines and posting links will not improve your rankings.
                             </p>
                             <p className="fs-14"><b><u>Information Boxes (IB’s)</u></b>
                             </p>
                             <p className="fs-14">When users mark page listings with flags or flag a user for certain things, Information Boxes appear on the top of the page or the user page. These Information Boxes help to explain to everyone the current status of the page and the reason for the flag. Many Information Boxes ask users to help and fix the issue at hand.
                             </p>
                             <p className="fs-14">UIBs are User Information Boxes that display important information. Any user can create an Information Box after which, the community will review it and once approved, it will be added to the list below.
                             </p>
                             <h2  id="category-pages">CATEGORY PAGES</h2>
                             <p className="fs-14">All listings need to be categorized to keep listings grouped together to help readers locate them. The category list is one of the major search tools at WikiReviews If a listing is not categorized, it will not appear within the category list and the category will not be shown on the actual listing.
                             </p>
                             <ul>
                                  <li>Use the category/subcategory section on the listing page. You can click the flag to modify the categories/subcategories. Please choose the most relevant or specific category that best describes the listing. Always choose a specific category over a broader general category, if it is more appropriate.</li>
                                  <li>If multiple categories/subcategories apply, please be sure to add all that apply.</li>
                                  <li>Suggest a new general category or more specific sub-category in the community forum category.</li>
                                  <ul className="wk-guidelines-inner-list">
                                     <li>The category team makes the final decision after forum discussion.</li>
                                  </ul>
                                  <li>Please note that whenever categorization is done, we will add the category to the recently added categories/subcategories list.</li>
                                  <li>Click on the Save button to save your changes.</li>
                             </ul>
                             <p className="fs-14"><b><u>Guidelines for Suggesting a Category/Subcategory</u></b>
                             </p>
                             <p className="fs-14">At WikiReviews, we are excited to get your help in improving our site by suggesting new categories and subcategories to our index. Our goal is to be the largest review site on anything and everything in the world so our limits on categories go as far as our minds will take us! The following is the method to suggest a new category/subcategory.
                             </p>
                             <ul>
                                    <li>Review the current category and subcategory listing on our sitemap.</li>
                                    <li>Be sure not to list a category or subcategory that exists currently.</li>
                                    <li>
                                    If you have an idea to combine a category or subcategory to make it better, please go to the Participate tab and then Improve Categories/Subcategories.
                                    </li>
                                    <li>
                                    Feel free to suggest something that might even seem small to you, but to certain other users, may be a very helpful topic.
                                    </li>
                                    <li>
                                    Suggest the best category/subcategory that it should go under and provide examples of listings you think could go into that category or subcategory.
                                    </li>
                                    <li>
                                    Try to take initiative and create a project and team to build up the listings in that category or subcategory.
                                    </li>
                                    <li>
                                    Be patient. We have a great team of admins that will get to your suggestion and let you know the outcome as soon as possible.
                                    </li>
						       </ul>
                               <p className="fs-14">The category structure consists of all the categories on WikiReviews. Category structure involves considerations of how child categories support each parent category, how categories sit together as a whole, how consistency across the site is maintained and how we create a smooth means for moving readers through the site seamlessly by means of cross-referencing categories and other tools.
                               </p>
                               <ul>
                                   <li>Parent Categories – aka, a main or general category –are the main categories that you see on the WikiReviews site.</li>
                                   <li>Child Categories – aka, sub-category or specific category –are the varying levels of categories that cascade off the parent categories and off each other to deeper levels.</li>
                               </ul>
                               <p className="fs-14">Constant refinement ensures that the categories are kept in shape, changed if they become outdated, ill-suited, or obsolete and to ensure that there is no avoidable duplication of categories across the site. 
                               </p>
                               <p className="fs-14"><b><u>How We Approach Caring for Category Structure</u></b>
                                </p>
                                <p className="fs-14">Maintenance of the Category Structure is done with a view to ensuring balance, consistency and integrity of the structure across the entire site. Much behind-the-scenes planning continues on a regular basis to ensure that the categories reflect the evident, ongoing needs of contributors, as well as some projected needs, in order to enhance the reader and reviewer enjoyment and use of the site. 
                                </p>
                                <p className="fs-14"><b><u>Who Changes the Category Structure?</u></b>
                                </p>
                                <p className="fs-14"><b>Actual structural changes to the categories system is one of the roles of the Category team.</b> <br/>
                                   The need for maintaining overall category consistency and integrity has resulted in this being an administrator’s role (as well as being reserved for those who demonstrated an amazing passion for organizing things constantly into nice patterns). Admins make the ultimate changes, but most changes and suggestions are a result of the community making suggestions on the Community Noticeboard.  
                                </p>
                                <p className="fs-14">Putting order into the ever-growing WikiReviews site requires regular and ongoing refinement of the category structure, aided by enthusiastic contributors who notice what needs expanding and what needs fixing. 
                                </p>
                                <p className="fs-14">We have many methods in place to keep the category structure in order but much of it depends on the advice and help we receive from you so we welcome all of your suggestions. 
                                </p>
                                <p className="fs-14"><b><u>Cross-Referencing and Suggesting New Categories</u></b>
                                </p>
                                <p className="fs-14">This very useful task can be performed by a contributor on the site experienced with linkages between the categories. 
                                </p>
                                <p className="fs-14">Things to consider when proposing categorization changes include:
                                </p>
                                <ul>
                                    <li>Existing categories</li>
                                    <li>Overloading of existing categories</li>
                                    <li>Obvious need for breakdowns of categories</li>
                                    <li>Creation of an incentive or niche for new listings</li>
                                    <li>
                                       Not spreading categories too thin – not creating them before the need is evident.
                                    </li>
                                    <li>Overall interaction of categories across the site.</li>
						       </ul>
                               <p className="fs-14">Once you submit your request to the Community Noticeboard, it will be submitted to the category/subcategory forums in which existing admins will vote on the suggestions after hearing suggestions and input from the community. Admins will assess the viability of the change with a view to the entire category structure. After 7 days, a decision will be made and we will inform you of the decision and we will post the outcome on the Community Noticeboard and the WikiReviews Herald. We consider all requests and attempt to meet them as much as possible. 
                               </p>
                               <p className="fs-14"><b><u>Guide for Research Resources on Buyer’s Guide Pages</u></b>
                                </p>
                                <p className="fs-14">WikiReviews contributors are strongly encouraged to build out the information on our listing categories and subcategories to properly inform folks who are researching reviews. We believe that providing factual and unbiased information as a foundation for our category/subcategory landing pages is a great start for the community to read first and then move forward to reading reviews and different points of view.
                                </p>
                                <ul>
                                    <li>
                                        As a rule, facts cannot be copyrighted. This means that you can use any facts you find to support the information in the listing.
                                    </li>
                                    <li>
                                        Although facts are not subject to copyright, the words used to express them are, particularly if the wording is original or unique (copyright covers original expression). You are free to use information from other materials in your listings, but use your own words to express it.
                                    </li>
						            <li>
							           Find source materials to cover as many aspects of your topic as possible. 
                                        <ul className="wk-guidelines-inner-list">
                                            <li>
                                            Analyze the credibility of the source. Be sure to use your best judgment in using solid references.</li>
                                            <li>
                                            Avoid using sources that are opinion-based, except when there is significant debate on an issue.
                                            </li>
                                        </ul>
						           </li>
						           <li>
							          Start creating the listing and please use as much detail as possible so that readers can get a much better understanding of the category and subcategory.
                                    <ul className="wk-guidelines-inner-list">
                                    <li>Be sure not to copy word-for-word from your source materials.</li>
                                    <li>Avoid plagiarism.</li>
                                    </ul>
						         </li>
						        <li>
						          Post all citation and source info at the bottom of the page.
						        </li>
						        <li>
						           If all looks good, please publish the listing or edit it to enhance it and publish it.
						      </li>
						    </ul>
                            <h2  id="buyers-guide-criteria">Buyer’s Guide Criteria</h2>
                            <p className="fs-14">Follow the following guidelines for creating and referencing Buyer's Guides.
                            </p>
                            <p className="fs-14"><b><u>Creating a Buyer’s Guide </u></b>
                            </p>
                            <p className="fs-14">Contributors to the Buyer’s Guide section of WikiReviews must abide by the following 5 rules: 
                            </p>
                            <ul>
                              <li>
                                Do not use promotional or overly emotional language; try to keep emotions out of it.
                              </li>
						      <li>
						        Try not to emend the words of others unless it is entirely necessary. Arguing over minor wording changes is generally not defined as “necessary”.
						      </li>
						      <li>
						        Any time you add something, make sure you support it with a source. A URL is fine – just make sure you have something credible that backs up your statements. Referenced sources should abide by our Referenced Source policy below. No blogs or comment threads please. Press releases or company websites are fine, so long as you abide by Rule #1.
						      </li>
						      <li>
						       If you have a conflict of interest, just say so. We don’t mind if you work for the company you are writing about, but let us know so that our community can read your content in context. 
						      </li>
						      <li>
						       If you have an issue with the behavior or contributions of someone else, the correct place for this discussion is by opening a discussion thread on the Admin Noticeboard. Please do not disrupt the page itself.
						      </li>
						  </ul>
                          <p className="fs-14"><b><u>Reference Policy</u></b>
                          </p> 
                          <p className="fs-14">A good reference abides by the following:
                          </p> 
                          <ul>
                            <li>
                            It has been published with some editorial oversight and by a publisher that stands behind the words said in the referenced article (no opinion or fan pieces).
                            </li>
                            <li>
                            The piece was written in a neutral tone and was not intended to hype the subject material in any way.
                            </li>
                            <li>
                            There is no proven conflict of interest or redaction that might affect the integrity of the information in the piece.
                            </li>
						</ul> 
                        <h2 id="flagging-editing">FLAGGING &amp; EDITING SITE CONTENT</h2>
                        <p className="fs-14">WikiReviews users can flag reviews for a wide variety of reasons including, but not limited to: accuracy issues, being fake or lacking meaningful information, facts are wrong, derogatory comments were made, incivility, personal attacks, spam, threatening legal action, threats of violence, and vandalism.
                        </p> 
                        <p className="fs-14">Once a review is flagged, we need the community’s help in reviewing the flagged issue and determining if you agree with the flag. If this is a clear-cut issue, then it will be easy to make a determination, but if for any reason there is doubt about the best course of action, it is best to mark the “Achieve Consensus” button. This will allow the issue to go into the Community Noticeboard where users and admins can vote on what should happen with that review. 
                        </p>
                        <p className="fs-14"><b><u>Flagging Policy</u></b>
                        </p> 
                        <p className="fs-14">The list below lists all the reasons that might qualify users flagging listings. For most of the issues listed below, flagging those issues will have either admins or the community at large review the issue flagged so that appropriate action can be taken. If there are Urgent Action items such as copyright violations, safety issues, etc., Admins will review the information for immediate action. Other issues such as incomplete information or not useful/not relevant information, the general community will review this information and take appropriate action. For many flagged issues listed below, users who originally posted the information that the community deletes/rolls back, will have an opportunity to dispute this decision and have the community vote on keeping it or removing it.
                        </p>
                        <ul>
                            <li>Accuracy issues </li>
                            <li>Abuse</li>
                            <li>Advertising</li>
                            <li>Copyright violations</li>
                            <li>Duplicate listing</li>
                            <li>Recreational drug focused</li>
                            <li>Extremely dangerous and reckless</li>
                            <li>Hate/Race based</li>
                            <li>Universally illegal (rape, murder, assault)</li>
                            <li>Incomplete and not useful</li>
                            <li>Notability</li>
                            <li>Spam</li>
                            <li>Vanity pages – listings only good for one person or select group of insiders (does not include listings that the community uploads for review).
                            </li>
						</ul> 
                        <p className="fs-14"><b><u>Banning policy</u></b>
                        </p> 
                        <p className="fs-14">A WikiReviews ban is a formal revocation of editing privileges on one or more WikiReviews pages. Though a WikiReviews ban may extend to the entire site, it is usually limited to an article ban or a topic ban. A ban may be temporary or permanent. The standard WikiReviews invitation to “edit or flag this page” does not apply to banned editors. 
                        </p>
                        <p className="fs-14">For users who violate our policies, the following automatic sanctions will be followed:
                        </p>
                        <ul>
                            <li>First offense – Written email warning.</li>
                            <li>
                            Second offense—48 hour block of all editing privileges.</li>
                            <li>
                            Third offense—One week block of all editing privileges</li>
                            <li>
                            Fourth offense—Two-week block</li>
                            <li>
                            Fifth offense—One month block</li>
                            <li>
                            Sixth offense—A discussion thread is open to determine if an indefinite block should be placed, or if a second month block will suffice. 72 hours with no postings and no consensus on the thread results in an auto-one month block.
                            </li>
                            <li>
                            Further offenses—Repeat process for sixth offense.</li>
						</ul>
                        <p className="fs-14">This process should be used for all offenses except those deemed illegal in the US. Illegal activities result in an immediate block, unilaterally by administrators. 
                        </p>
                        <p className="fs-14"><b><u>Editing Content on The Site</u></b>
                        </p>
                        <p className="fs-14">If users want to change existent content on the site, navigate to the appropriate section and select edit from the dropdown menu. Once approved via the same process outlined above, your edit will be recorded in the page’s history section. 
                        </p>  
                        <p className="fs-14">The approval standards for editing existing content are a bit higher. Please include authoritative sources and clear content. 
                        </p> 
                        <p className="fs-14">Given this is a wiki site, it is likely other users will edit your contributed content at some point. If you would like to stay updated on changes to any content you’ve written, simply add the listing to your Watchlist, in which case you will be notified of all updates to the page. 
                        </p>
                        <p className="fs-14">If you don’t add the item to your Watchlist, you won’t be notified of future changes. If a revert happens or a change to content happens, only those users with the listing added to their Watchlist will be notified. 
                        </p>
                        <p className="fs-14"><b><u>Trusted Users</u></b>
                        </p>
                        <p className="fs-14">Edits by newly registered users are sent to the Patrol Recent Changes section for verification. Once a user has submitted 10 verified edits and had his/her account for 4+ days, that user is classified as a “trusted user,” and all future edits are immediately published without being sent to Patrol.
                        </p>
                        <p className="fs-14"><b><u>Edit Warring</u></b>
                        </p>
                        <p className="fs-14">Edit Warring is when contributors use edits to fight with other contributors – disagreements should be resolved through discussion. 
                        </p>
                        <p className="fs-14">An edit war occurs when contributors who disagree about the content of a page repeatedly override each other's contributions, rather than trying to resolve the disagreement by discussion. Edit warring is unconstructive and creates animosity between contributors, making it harder to reach a consensus. Users who engage in edit wars risk being blocked or even banned. 
                        </p>
                        <p className="fs-14">Not every revert or controversial edit is regarded as edit warring.
                        </p>
                        <p className="fs-14">WikiReviews encourages contributors to be bold. A potentially controversial change may be made to find out whether it is opposed. Another contributor may revert it. This is known as the bold revert discuss (BRD) cycle. An edit war only arises if the situation develops into a series of back-and-forth reverts.
                        </p>
                        <ul>
                            <li>Reverting vandalism is not edit warring, but note that merely editing from a slanted point of view, general insertion or removal of material, or other good-faith changes, are not considered vandalism.</li>
                            <li>Flagging the same issue repeatedly or flagging it because you may not agree with a point of view is not appropriate. Repeated flagging of issues for non-relevant flagging reasons may lead to a ban or block.</li>
                            <li>Reverting edits by banned users is not edit warring.</li>
                        </ul>
                        <p className="fs-14">When reverting, be sure to indicate your reasons on the talk page.
                        </p>
                        <p className="fs-14"><b><u>How to Respond to Edit Warring </u></b>
                        </p>
                        <p className="fs-14">If an edit war develops, participants should try to discuss the issue on the talk page and work things out.
                        </p>
                        <p className="fs-14">It is better to seek help in addressing the issue than to engage in edit warring over it. When disagreement becomes apparent, one, both, or all participants should cease warring and try to discuss the issue on the talk page, or approach the appropriate venues for help. Other alternative approaches recommended within the community are suggested below. 
                        </p>
                        <p className="fs-14">If, despite trying, one or more users will not cease edit warring, refuse to work collaboratively, or heed the information given to them, or will not move on to appropriate dispute resolution, then a request for administrative involvement in the Community Forum Help section. Consider writing your own note to the user specifically appropriate for the situation, with a view to explicitly cooling things down. 
                        </p>
                        <p className="fs-14"><b><u>How Experienced Contributors Avoid Edit/Flag Wars </u></b>
                        </p>
                        <p className="fs-14">In general, communication is the key to avoiding conflict. Once it is clear that there is a dispute, discuss the matter on the listing's talk page. The primary venue for discussing the dispute should be the listing talk page, which is where a reviewing admin will look for evidence of trying to settle the dispute. When discussion does not produce a conclusion, bringing wider attention to a dispute can lead to compromise. Consider starting a request for comments. Neutral contributors aware of the dispute will help curb egregious edits while also building consensus about the dispute. When these methods fail, seek informal and formal dispute resolution.
                        </p>
                        <p className="fs-14">Some experienced contributors deliberately adopt a policy of only reverting edits covered by the exceptions listed above, or limiting themselves to a single revert or single flag; if there is further dispute, they seek dialog or outside help rather than make the problem worse. They revert/flag again only when necessary. This policy may be particularly appropriate for controversial topics where views are polarized and emotions run high, and thus edit warring/flagging is more frequent.
                        </p>
                        <p className="fs-14">The bottom line: use common sense, and do not participate in edit/flag wars. Rather than reverting or flagging repeatedly, discuss the matter with others; if a revert/flag is necessary, another contributor may conclude the same and do it (without you prompting them), which would then demonstrate consensus for the action. Request page protection rather than becoming part of the dispute by reverting/flagging.
                        </p>
                        <h2  id="badges-awards">Badges and Awards</h2>
                        <p className="fs-14">Badges are for the greatest quantity of contributions (leaderboard winners, from inception to current) and awards are for the monthly leaderboard winners. Long term reviewers and contributors will get metal-based badges. The monthly based trending users with the highest number of reviews will get the Brightest Star awards and contributions will get gemstone and rare gem awards.
                        </p>
                        <p className="fs-14">We will further develop other incentives for users with different levels of awards based upon their Clout score. 
                        </p>
                        <p className="fs-14"><b><u>Wiki Leaderboards (7 Badges) </u></b>
                        </p>
                        <ul>
							<li>
							Leaderboard badges are for users who created reviews with the most Nailed it, LOL and Priceless marks for any single review. 10 users will receive this badge each month and it is a monthly badge only.  
							</li>
							<li>
							
							Top WikiReviewer badges are for users who created the greatest quantity of reviews. This is a monthly and lifetime badge.  
							</li>
							<li>
							
							WikiInfluencer badges are given to users with the highest number of friends and followers. This is a monthly and lifetime badge.  
							</li>
							<li>
							
							Top WikiGuru badges are contribution based badges. This is a monthly and lifetime badge.   
							</li>
							<li>
							
							Top WikiGeoReviewer badges are given to users with the highest location based reviews. This is a monthly and lifetime badge.   
							</li>
							<li>
							
							Top WikiGeoPowerHelper badges are location based badges for users with the most flags, improvements, and contributions to the site. This is a monthly and lifetime badge. 
							</li>
							<li>
							
							WikiScholar badges are given to the users with the highest number of contributions to the Buyer’s Guide section. This is a monthly and lifetime badge.  
							</li>
						</ul>
                        <p className="fs-14"><b><u>Brightest Star Awards  </u></b>
                        </p>
                        <p className="fs-14">The top ten reviewers for each month all get a “Brightest Star” award. Brightest Star awards will always be pinned to the profile of the user who received it and it will have the month and year in which they have achieved it.
                        </p>
                        <ul>
							<li>Sun</li>
							<li>Alpha Centauri</li>
							<li>Sirius</li>
							<li>Vega</li>
							<li>Capella</li>
							<li>Arcturus</li>
							<li>Aldebaran</li>
							<li>Polaris</li>
							<li>Canopus</li>
							<li>Antares</li>
						</ul>
                        <p className="fs-14"><b><u>Gem Awards </u></b>
                        </p>
                        <p className="fs-14">Gem awards are contribution based awards. Once you have reached the number of contributions needed, you will receive the gem award.
                        </p>
                        <ul>
							<li>Pink Star Diamond (1,000 Contributions)</li>
							<li>Painite (900 Contributions)</li>
							<li>Musgravite (700 Contributions) </li>
							<li>Jadeite (500 Contributions)</li>
							<li>Alexandrite (300 Contributions) </li>
							<li>Red Beryl (200 Contributions)</li>
							<li>Benitoite (100 Contributions)</li>
							<li>Black Opal (50 Contributions)</li>
							<li>Taaffeite (25 Contributions)</li>
							<li>Tanzanite (10 Contributions)</li>
						</ul>
                        <p className="fs-14"><b><u>Contribution Avatar Awards </u></b>
                        </p>
                        <ul>
							<li>Rhodium – 10,000 Contributions</li>
							<li>Platinum – 7500 Contributions</li>
							<li>Gold – 5,000 Contributions</li>
							<li>Ruthenium- 3500 Contributions</li>
							<li>Iridium – 2000 Contributions</li>
							<li>Osmium – 1000 Contributionss</li>
							<li>Palladium – 500 Contributions</li>
							<li>Rhenium – 250 Contributions</li>
							<li>Silver – 100 Contributions</li>
							<li>Indium – 50 Contributions</li>
						</ul>
                        <p className="fs-14"><b><u>Administrator Icons </u></b>
                        </p>
                        <ul>
							<li><mark>Analyzers</mark></li>
							<li><mark>WikiReviews Guardian</mark></li>
							<li><mark>Subcategory Supervisor </mark></li>
							<li><mark>Category Supervisor </mark></li>
							<li><mark>Hyperlocal Community Champion</mark></li>
							<li><mark>Expert Reviewers</mark></li>
							<li><mark>Q &amp; A Admin</mark></li>
							<li><mark>Problems &amp; Solutions Supervisor</mark></li>
							<li><mark>Recent Changes Patroller</mark></li>
							<li><mark>WikiReviews Herald Writer</mark></li>
							<li><mark>Professional Admin</mark></li>
							<li><mark>Power Helper</mark></li>
							<li><mark>CRP Admin</mark></li>
							<li><mark>Citation Admin</mark></li>
							<li><mark>Coaching Buddy</mark></li>
						</ul>
                        <p className="fs-14"><b><u>Desktop & Mobile App Awards </u></b>
                        </p>
                        <ul>
							<li>First to Review </li>
							<li>First to add a picture to a listing </li>
							<li>First to add a video to a listing</li>
							<li>First to add a video review (mobile only)</li>
							<li>First to add a listing</li>
							<li>First add a CRP project</li>
							<li>First to review a CRP project</li>
							<li>First to dictate a review (only mobile)</li>
							<li>First to write a tip</li>
							<li>First to add a warning</li>
							<li>First to flag</li>
							<li>First to improve</li>
							<li>First to vote</li>
							<li>First to use UPC barcode scan (mobile only)</li>
						</ul>
                        <h2  id="admin-noticeboard">Admin Noticeboard (ANB)</h2>
                        <p className="fs-14"><mark>The Admin Noticeboard is essentially a collection of discussion threads. In addition to community-generated page-discussion threads, admins can create their own discussions:</mark>
                        </p>
                        <ul>
							<li><mark>Editor behavioral notice</mark></li>
							<li><mark>Admin action review&nbsp;</mark></li>
							<li><mark>Block appeal</mark></li>
						</ul>
                        <p className="fs-14">Permanent block discussions can only be started by administrators, but they constitute a further string. 
                        </p>
                        <p className="fs-14">Once you choose a thread type, enter your username, detail the issue and your personal history with it, and list any other users involved. They will be notified when you save the thread, and the same closure rules apply as with the discussion threads. Only trusted users (those with over 10 edits) can discuss things on the thread, unless they are named as one of the involved users by the poster of the thread. Not-yet trusted users listed by the poster can edit the thread they are invited to, though these edits do not count towards the 10 required for them to become a “trusted user”. 
                        </p>
                        <p className="fs-14">Archived threads can be found with a search window, but only live threads are immediately visible on the noticeboard. 
                        </p>
                        <p className="fs-14">Please leave a detailed message in the ANB about a serious problem that requires prompt attention from an administrator. 
                        </p>
                        <p className="fs-14">If you are reporting forum troll or spam, please add a link to the forum post in question for a faster response. Please do not assume that an admin will be able to find the forum spammer/troll right away if the said troll/spammer has never edited WikiReviews. Admins need to look at contributions before blocking a user. 
                        </p>
                        <p className="fs-14">To use the Admin Noticeboard, click the edit button next to the appropriate section: 
                        </p>
                        <ul>
                            <li>Username Violation</li>
                            <li>Spammers and Vandals&nbsp;</li>
                            <li>Block Disruptive Users</li>
                            <li>Users Under 13</li>
                            <li>Sockpuppets&nbsp;</li>
                            <li>Spam &amp; Sites to be Blacklisted</li>
                            <li>Suggested Words to be Whitelisted&nbsp;</li>
                            <li>Pages Needing Protection</li>
                            <li>Image/Video Uploading Spree</li>
                            <li>NFD Review</li>
                            <li>Speedy Deletions&nbsp;</li>
                            <li>Miscellaneous&nbsp;</li>
						</ul>
                        <p className="fs-14"><mark>To view the ANB,  see here:<a href="http://www.WikiReview.com/WikiReview:Administrator-Notice-Board">&nbsp;http://www.WikiReview.com/WikiReview:Administrator-Notice-Board</a></mark>
                        </p>
                        <p className="fs-14"><b><u>Discussion Threads </u></b>
                        </p>
                        <p className="fs-14">Discussion threads are where users can discuss disagreements they have about content. To open one, there will be a section at the bottom of the page, which begins with a button marked “Discuss This Page”. 
                        </p>
                        <p className="fs-14">This button will lead to a template on our Admin Noticeboard. Enter the name of the page, the user names involved in the dispute and the reason you feel a discussion is necessary. After your new discussion is created, all parties involved will receive a notification. A link to the discussion page will also appear on the page being discussed. 
                        </p>
                        <p className="fs-14">Open discussions are labeled as “Live” and those completed are labeled as “Archived”. Anyone can click on these links and land at the open or closed discussion page to either read or contribute to the conversation. Threads are closed when one of 3 things occur:
                        </p>
                        <ul>
							<li>
							A consensus on a solution is reached by the majority of those involved.
							</li>
							<li> More than 72 hours have passed since the last post.</li>
							
							<li> An administrator takes an action which ends the dispute (such as blocking an offending user or determining that there is a clear policy reason for one side being chosen over the other).
							</li>
						</ul>
                        <p className="fs-14"><b><u>Apply for Admin Nomination</u></b>
                        </p>
                        <p className="fs-14">Apply during the “call for admin nominations”. They will begin approximately every July 1st and will be announced at the Village Pump and the WikiReviews Herald. Ask an existing WikiReviews administrator to nominate you for the position. If the administrator thinks you will be a good candidate for adminship, he or she will nominate you during the nomination cycle. Alternatively, you could nominate yourself by following the instruction posted at the forums during the call for nominations. 
                        </p>
                        <p className="fs-14"><b><u>Expert Nomination</u></b>
                        </p>
                        <p className="fs-14">Expert reviewer and adminship reflects the community's trust in an individual, not an account, so when applying for these roles, it is expected that you will disclose past accounts openly, or to the arbitration committee if the accounts must be kept private. Administrators who fail to disclose past accounts risk being stripped of their administrator status, particularly if knowledge of them would have influenced the outcome of the Request for Adminship (RfA).
                        </p>
                        <p className="fs-14"><b><u>Administrators</u></b>
                        </p>
                        <p className="fs-14">To report something that needs immediate administrator attention, please post it on our Administrator Notice Board.
                        </p>
                        <p className="fs-14">Not sure where to turn? WikiReviews administrators, most of whom are volunteers, are frequently patrolling WikiReviews at all hours of the day, spanning several time zones and different continents. If you have any questions, choose an admin and leave them a note on their talk page. More often than not, you will receive a reply within a day. 
                        </p>
                        <p className="fs-14">WikiReviews administrators (‘admin’ for short) are the community members that willingly take on extra responsibility to make WikiReviews the best “how to” manual possible. At WikiReviews, every user is a contributor and valued member of the community. Admins have extra powers and responsibilities to allow them to ensure that WikiReviews develops into a useful, free, and open resource. Admins can delete and restore pages, rename titles and users, protect and unprotect pages, revert edits, block a user or IP address, and do a few other minor tasks.
                        </p>
                        <p className="fs-14"><b><u>Guidelines on Becoming a WikiReviews Admin</u></b>
                        </p>
                        <p className="fs-14">If you’ve been selected as an admin, congrats! The following is your guide to administration on WikiReviews. 
                        </p>
                        <p className="fs-14">Administrators are WikiReviews community members who willingly take on extra responsibility to make WikiReviews the best review site possible. While every WikiReviews user is a contributor, administrators have three extra powers and responsibilities which allow them to ensure that WikiReviews continues to blossom into a useful free resource:
                        </p>
                        <ul>
							<li>Changing titles</li>
							<li>Deleting Pages</li>
							<li>Blocking Accounts</li>
						</ul>
                        <p className="fs-14">Other than these general housekeeping duties, they participate in the same creative and collaborative activities as any other contributor. Please know that adminship is neither a trophy nor a promotion. Please look at adminship as a responsibility. Being an admin does not make you more popular or respected and it does not mean your opinion matters more than anyone else’s on WikiReviews. It just means you can do a few extra things that require you to know our policies very well. You do not need to be an admin to be respected on WikiReviews. If you are eager to become an admin, you can apply to be one, but please note, you must also be eager to improve WikiReviews as a whole and learn the WikiReviews policies.
                        </p>
                        <p className="fs-14">Admin elections will take place once a year in July. If you would like to participate, keep an eye on the forums.
                        </p>
                        <p className="fs-14"><b><u>Key Tips to Know About Admins</u></b>
                        </p>
                        <ul>
                           <li>Get to know active admins on the site.</li>
                           <ul className="wk-guidelines-inner-list">
                              <li>Active admins are the ones who will be voting in the admin election so it is a good idea to interact with them so when your name pops up, they know who you are. Since admins are not glorified on WikiReviews, it can be hard to know who they are! There are also many admins who are not active at the moment so is best to look at the block log and deletion log to find the most active admins. The best way to make an impression on admins is to ask them questions, especially about situations you come across while following the steps below. 
                              </li>
                           </ul>
                           <li>Know the deletion policy well.</li>
                           <ul className="wk-guidelines-inner-list">
                              <li>Since one of the abilities you gain as an admin is the ability to delete listings, it’s important that you’re familiar with the deletion policy.
                              </li>
                           </ul>
                           <li>Apply nominate for deletion (NFD) tags with care.</li>
                           <ul className="wk-guidelines-inner-list">
                              <li>Nominating listings for deletion carelessly and repeatedly is a red flag and will hurt your chances of getting elected. 
                              </li>
                           </ul>
                           <li>Vote on discussion pages.</li>
                           <ul className="wk-guidelines-inner-list">
                              <li>Most admins review listings that have been nominated for deletion and check the talk pages for consensus. Go to and show your knowledge of the deletion policy by voting on the discussion pages. Explain why you think the listing does or does not violate the deletion policy. Any registered contributors can vote for or against deletion. 
                              </li>
                           </ul>
                           <li>Admins often look for a particular NFD reasons, so vote on a variety of NFD listings so that more admins see your comments.</li>
                           <li>Save listings from deletion.</li>
                           <ul className="wk-guidelines-inner-list">
                              <li>If you see that a listing someone started can be rewritten or edited so that the NFD reason is no longer valid, fix it! Admins and listing boosters are allowed to remove the NFD tag, but let them know that the listing is fixed by posting a comment on the discussion page. Once an admin or a booster sees it, they will remove the tag for you. This will help people remember you as well as demonstrates that you have good knowledge of the deletion policy and a genuine interest in improving listings.
                              </li>
                           </ul>
                           <li>Participate in the forums.</li>
                           <ul className="wk-guidelines-inner-list">
                              <li>Offer feedback, ask questions, and answer questions. The forums are a great place to learn about WikiReviews policies and interact with the community, including active admins. 
                              </li>
                           </ul>
                           <li>You can also spend time in the WikiReviews IRC channel. We encourage admins to log in there when they can.</li>
                           <li>Avoid and mediate conflict.</li>
                           <ul className="wk-guidelines-inner-list">
                              <li>Some people don’t understand how WikiReviews works, and some people just need more help. An important thing that admins look for when voting is patience. It’s great to show that you don’t let people get under your skin, no matter how angry or insulting they get. It’s also good if you can show that you’re immune to trolls, too. If admins see that you get easily irritated by people, whether on talk pages, the forums, or the IRC, admins will question whether your temperament will negatively affect the way you use admin options. 
                              </li>
                           </ul>
                           <li>Patrol Recent Changes – Report any of the following on the Admin Noticeboard.</li>
                           <ul className="wk-guidelines-inner-list">
                              <li>Username policy violations
                              </li>
                              <li>Anyone who writes that they are under 13 years old (that does not mean, however, that it’s acceptable to judge by grade level, or to ask someone how old they are).
                              </li>
                              <li>Vandals
                              </li>
                              <li>Spammers (people who place the same links everywhere) 
                              </li>
                              <li>Pages needing protection (any page that you notice is getting a lot of vandalism in a short period of time; common with controversial featured listings) 
                              </li>
                              <li>Anyone who is uploading a lot of videos in a short period of time, and the videos don’t seem to comply with our video guidelines 
                              </li>
                              <li>Anyone who is directly and repeatedly attacking other contributors on WikiReviews. 
                              </li>
                           </ul>
                           <li>Apply during the “call for admin nominations”, which will begin approximately every July 1st and will be announced at the Village Pump at the Forums. Ask an existing WikiReviews administrator to nominate you for the position. If the administrator thinks you will be a good candidate for adminship, he or she will nominate you during the nomination cycle. Alternatively, you could nominate yourself by following the instruction posted at the Forums during the call for nominations.</li>
                           <li>Recognize that new admins will be will be selected by a consensus-seeking process conducted in confidence among existing admins. Typically, admin candidates need support of 70-80% of the existing admins. Generally, this process can last from a few days to a few weeks, as it can take time for admins to reach a consensus on who should be an admin. When complete, the results will be published on the Village Pump at the Forums.</li>
                           <li>Understand the selection criteria that existing admins will be looking for in each candidate.</li>
                           <ul className="wk-guidelines-inner-list">
                              <li>Trust – This is the most important criterion. Do the existing admins trust you to exercise control responsibly? Trust is earned over time by continually displaying good, level-headed, mature judgment. 
                              </li>
                              <li>Empathy and kindness – Admins exist to serve the broader community of contributors and readers. A demonstrated history of treating others with kindness and mutual respect is a necessity. 
                              </li>
                              <li>Mission focus – Demonstrated ability to put WikiReviews mission before competing concerns. 
                              </li>
                              <li>Civility – Can you remain civil when criticizing the decision you make as an admin? Can you exercise admin powers while still being kind, polite, and welcoming?
                              </li>
                              <li>Track record – While there are no hard and fast rules, generally a contributor should have at least four months of experience and 500-1000 listing contributions on WikiReviews before being considered as a possible administrator candidate. Existing admins have a hard time evaluating candidates that they have yet to observe and interact with personally. 
                              </li>
                           </ul>
                           <li>Don’t be discouraged if you don’t make it through on your first couple of election cycles. About half of the people nominated may not pass on their first few attempts, but are instead encouraged to try on a second, third, or fourth attempt. If you were nominated, but not appointed, reach out to an existing admin to ask for coaching to help you apply in the future. Similarly, if you are never selected for adminship, you can still be a valued member of the community. Many great WikiReviews contributors never become admins.</li>
                        </ul>
                        <p className="fs-14"><b><u>How to Act Responsibly as a WikiReviews Admin </u></b>
                        </p>
                        <p className="fs-14">WikiReviews admins are expected to act responsibly and impartially in their activities and interactions with the community. The following outlines how that can be accomplished:
                        </p>
                        <ul>
                            <li>Delete pages carefully</li>
                            <ul className="wk-guidelines-inner-list">
                               <li>While spam, profanity, and nonsense page listings should be deleted immediately, borderline cases should be nominated for deletion (NFD) by writing on the page and the edit summary and leaving a comment on the discussion page. The community will have a period of a week or two to collect comments from other community members. A specially designated admin will read the votes and make the final decision in accordance with the deletion policy.
                               </li>
                            </ul>
                            <li>Blocking</li>
                            <ul className="wk-guidelines-inner-list">
                               <li>Block users who are repeatedly attempting malicious acts. While a 24 hour block is often sufficient to discourage a person, longer blocks may be necessary in some cases. Feel free to block users for up to one month at your discretion. Even longer blocks are acceptable for repeat offenders and spambots. 
                               </li>
                            </ul>
                            <li>Protection</li>
                            <ul className="wk-guidelines-inner-list">
                               <li>Protect listings that are being vandalized or are naturally vandal-prone.
                               </li>
                            </ul>
                        </ul>
                        <p className="fs-14"><b><u>How Do You Block Disruptive Users on WikiReviews? </u></b>
                        </p>
                        <p className="fs-14">“Assume good faith” is a rule of thumb for all wiki contributors, but when a page is blanked out and replaced with a string of profanity multiple times even after warnings, one must recognize the unconstructive edits have been made. Users who continue to make unconstructed edits, despite repeated coaching and warnings, should be blocked for violating WikiReviews guidelines when they post spam, hate speech, inappropriate content, nonsense, or vandalism, WikiReviews admins can block disruptive users. If you are not an administrator, but you believe a block needs to be considered, please read these instructions then post on the WikiReviews Administrator Notice Board so an admin can apply the block. 
                        </p>
                        <ul>
                             <li>1. Try to leave a friendly warning first. Resist your natural reaction to lash out at troublemakers. Doing so only gives people the attention they crave and may give them incentives to produce more problems for us. 
                            </li>
                            <li>2. Evaluate what kind of edit has been made to decide whether it is a mistake, an experiment, or an unambiguously destructive edit. 2. Evaluate what kind of edit has been made to decide whether it is a mistake, an experiment, or an unambiguously destructive edit. 2. Evaluate what kind of edit has been made to decide whether it is a mistake, an experiment, or an unambiguously destructive edit. 
                            </li>
                            <ul className="wk-guidelines-inner-list">
                               <li>a. Don’t block mistakes, honest errors, and experiments unless they are persistent. Poor edits that might not necessarily be malicious such as blanking the page (which can be accidental), adding bad information, testing our open editing (e.g., “does this work?” or “shfjkdh”), or vanity page creations do not qualify as harmful edits. In most cases, it is best not to warn these contributors and only proceed to blocking if the disruptive contributors persist. 
                               </li>
                               <li>b. Block unconstructive contributors as a last resort when they are repeat offenders after being coached and warned. A destructive edit is defined as one where the vandal is very clearly trying to maliciously destroy the page by adding obscenities, insults, etc. Harmful edits may also include the creation of listings that require speed deletion.
                               </li>
                            </ul>
                            <li>3. If you are not an admin, leave a message on the WikiReviews Admin Noticeboard requesting the user be blocked. If you are an admin, proceed with the block as follows: 
                            </li>
                            <ul className="wk-guidelines-inner-list">
                               <li>a. Click “Block” next to the offending user’s username or IP address in Recent Changes or Page History. Choose the following variables:
                               </li>
                               <ul className="wk-guidelines-inner-list">
                               <li>i. <b>IP Address or Username –</b> This is entered automatically, but you can change it if you need to block someone else.
                               </li>
                               <li>ii. <b>Block length</b> is automatically selected based on the following schedule:
                               </li>
                               <ul className="wk-guidelines-inner-list">
                                  <li>1. First offense: Written Email Warning</li>
                                  <li>2. Second offense: 48 hours of all editing privileges.</li>
                                  <li>3. Third offense: One week block of all editing privileges.</li>
                                  <li>4. Fourth offense: Two week block.</li>
                                  <li>5. Fifth offense: One month block.</li>
                                  <li>6. Sixth offense: A discussion thread is open to determine if an indefinite block should be placed, or if a second month block will suffice. 72 hours with no postings and no consensus on the thread results in an auto-one month block.</li>
                                  <li>7. Further offenses: Repeat process for sixth offense. </li>
                               </ul>
                               <li>iii. Please know the goal of blocking people is to prevent continued disruption to WikiReviews. It is not to punish, shame, or get revenge on a person. Thus, block periods should be the shortest possible duration to minimize disruption to WikiReviews.  In many cases, contributors may share the same IP address as the one blocked user. By blocking that problem user, we may inadvertently block many people in the same company, internet café, school, library, family, etc., which might contribute to the loss of productivity to WikiReviews. Again, assume good faith. Allow users to amend their ways and come back to edit constructively. If possible, please avoid blocking for extended period of time users who have made constructive edits.</li>
                               </ul>
                            </ul>
                            <li>b. Select a reason for the block</li>
                            <ul className="wk-guidelines-inner-list">
                                  <li>Please click on one of the following reasons in the pulldown box and if those are not applicable, please us the text field below it to briefly explain why you are blocking the user:</li>
                                  <ul className="wk-guidelines-inner-list">
                                    <li>1. Abusive comments</li>
                                    <li>2. Adding spam links</li>
                                    <li>3. Creating speedy listings</li>
                                    <li>4. Malicious sockpuppet</li>
                                    <li>5. Spambot</li>
                                    <li>6. Trolling</li>
                                    <li>7. Vandalism</li>
                                    <li>8. Under 13</li>
                                 </ul>
                           </ul>
                           <li><mark>Prevent account creation – this prevents vandals from creating an account to continue their disruption.</mark></li>
                           <li>d. Click “Block this User” to finalize the blocking action.</li>
                           <li>e. If the destructive contributor repeats a destructive edit, then feel free to block again for any time period up to six months.</li>
                           <li>f. Check the contribution history of the offender and ascertain that all problems the offender has caused have been rectified.</li>
                           <li>g. When someone is found to have made a malicious edit (as opposed to a mischievous edit or an edit of error) any administrator becomes entitled, at his or her discretion, to roll back or delete all recent edits made by said vandal, including any new listings created.</li>
                           <ul className="wk-guidelines-inner-list">
                                    <li>i. Note that any blocked user can appeal a block by going to the Appeal Block page. All blocks will be reversed if justified.</li>
                           </ul>
                        </ul>
                        <p className="fs-14"><b><u>How to Unblock a User on WikiReviews</u></b>
                        </p>
                        <p className="fs-14">WikiReviews admins can block and unblock any user in WikiReviews in accordance with our WikiReviews policies.
                        </p>
                        <p className="fs-14">When unblocking a user head to the Username block list, find the username that you wish to unblock, specify the reason why you are choosing to unblock this person, and click the Unblock button.
                        </p>
                        <p className="fs-14"><b><u>How to Request WikiReviews Page Protection</u></b>
                        </p>
                        <p className="fs-14">At times, a WikiReviews listing, its discussion page, or your talk page may need temporary or permanent protection from high traffic or frequent vandalism. Here is how to request protection from any further edits. 
                        </p>
                        <p className="fs-14">We have the following two levels of protection: 
                        </p>
                        <ul>
                            <li>1. Semi-protection allows only registered users and admins to edit.</li>
                            <li>2. Full-Protection allows only admins to edit.</li>
                        </ul>
                        <p className="fs-14">To protect a listing, please review the history page for a pattern of frequent reversions. Contact an admin or the Admin Noticeboard to request protection. When requesting protection, please provide an adequate reason, such as frequent target of vandalism. 
                        </p>
                        <p className="fs-14">You can request to remove protection once you believe vandalism will cease. This may be anywhere from a week or up to a month thereafter. The only time a listing should be permanently protected is only if they are severely vandalized on a daily basis.
                        </p>
                        <p className="fs-14"><b><u>How to Block Spam on WikiReviews</u></b>
                        </p>
                        <p className="fs-14">Like a majority of Wiki sites, WikiReviews receives numerous spam postings, some of which can be quite obscene. In order to prevent spam postings from being published we have enabled the Spam Blacklist Extensions for preventing links that are known to be spam links from being posted on WikiReviews. If you are a WikiReviews administrator and have seen a spam posting, you can help prevent future postings by doing the following: 
                        </p>
                        <ul>
						   <li>
                             1. Find the spam posting and view the links that have been posted by viewing the source. 
                           </li>
							<li>
						     2. Load the listing Spam Blacklist and edit it. 
						    </li>
							<li>		
							 3. Add your new names to the end of the current list and save the page. 	
							</li>
							<li>		
							 4. All newly added domain names will be prevented from being posted to WikiReviews. 	
						    </li>
							<li>	
							 5. Admins can also add good websites which might accidentally be labeled as spam to the spam whitelist.&nbsp; 	
							</li>
							</ul>
                            <p className="fs-14"><b><u>Guidelines for Changing the Title of a Listing</u></b>
                            </p>
                            <p className="fs-14">We do have title guidelines on WikiReviews so that our listings are best described for the wiki community. Please see the following for general guidelines for title names below: 
                            </p>
                            <ul>
                                <li>1. First check the discussion section page first for any comments/consensus regarding the title change. Determine the best title name based upon our guidelines above and the comments/consensus in the discussion section.
                                </li>
                                <li>2. Click on the Retitle & Move button and in the listing pulldown that opens up, please make the change to the title.
                                </li>
                                <li>3. In the reasoning section, please state the reason for the change and then click “Move Page.”
                                </li>
                                <li>4. When this is done, all incoming links to the existing title will be moved to the new page and all references to the old title will be modified to the new title. 
                                </li>
                            </ul>
                            <p className="fs-14"><b><u>Changing a WikiReviews Title</u></b>
                            </p>
                            <p className="fs-14">The more well-established the listing (determined by how old it is and how many page views it has), the more careful you need to be about changing the title. Consider that if the listing has a high view count and thus has been read significantly for a good period of time, the existing title must already have decent merit in attracting readers. 
                            </p>
                            <p className="fs-14">There should be at least a 1-2 week waiting period to allow for discussion regarding the title change. The discussion and waiting period are optional and can be skipped if both of the following apply: 
                            </p>
                            <ul>
                                <li>1. The WikiReviews listing is relatively new</li>
                                <li>2. Your reason for changing the title is to fix spelling, grammar, punctuation, first person usage, or capitalization, all of which are straightforward and usually not debated.</li>
                            </ul>
                            <p className="fs-14"><b><u>How to Delete a Redirect on WikiReviews</u></b>
                            </p>
                            <p className="fs-14">Redirects are created when a page on WikiReviews is either merged or moved so that if someone clicks on a link to the old title on other websites (such as blogs and forum postings) or WikiReviews listings, they will automatically get sent (redirected) to the new title. Sometimes, however, a distinct title gets redirected, which means no one will be able to write a listing for that title because it is populated by redirect code. In this case, the redirect should be deleted, but it’s important to follow these steps to avoid creating broken links.
                            </p>
                            <ul>
								<li>
                                   1. Make sure the redirected title is distinct. 
								</li>
								<li>
                                    2. Go the redirect page which is :<a href="http://www.wikireviews.com/index.php?title=LISTING TITLE&amp;redirect=no">http://www.wikireviews.com/index.php?title=LISTING TITLE&amp;redirect=no</a>  
                                    <ul className="wk-guidelines-inner-list">
                                        <li>
                                            a. Replace LISTING TITLE in the URL with a chosen title.
                                        </li>
                                    </ul>
								</li>
								<li>
								    3. Once you go to the new page, click on “What Links Here”.
								</li>
								<li>
									4. View all linked pages; one of them will be the redirect, and you can click on the link without getting redirected.
								</li>
								<li>
									5. Redirect any inbound links. 
								</li>
								<li>
								    6. Go to each listing in “What Links Here” and change any links pointing to the redirect so that they now point directly to the listing. 
								</li>
								<li>
								   7. For external links, search for the URL of the old (redirected) title in any major search engine. If many pages link to the old title, deleting the redirect may not be a good idea since we don’t want visitors ending up at a page that does not exist. In this case, what you can do is replace the redirect code with content. Even if it is basic (a stub), it is better than nothing, and you can link to the other listing (what the redirect used to point to) as a Related WikiReviews.
								</li>
								<li>	
										8. Delete the redirect page by going to the admin tab (only admins can delete pages) and click delete. 
								</li>
								<li>	
										9.  You must specify your reason for deleting and then submit.
								</li>
							</ul>
                            <p className="fs-14"><b><u>How to Review a NFD List for Final Outcome</u></b>
                            </p>
                            <p className="fs-14">All listings that are nominated for deletion (NFD) need to be reviewed within one or two weeks following the date they are tagged with NFD template. Please note that in steps 3 and 4 below, there should never be discussion. An admin has to review these listings when they are NFD past 14 days. We ask all listings that are NFD to also have a comment added to the discussion page as to whether it meets our criteria for deletion. If the contributor put nothing in the discussion page to justify the NFD, it may be removed for lack of justification. The reasons for this are that there needs to be a consensus on the NFD. Without this it may be deleted, or the NFD may be removed without further action. The comments on the discussion page also gives the community a chance to respond as there may be a compelling reason to keep the listing. The last reason is that there should be a comment left for the admin who reviews the page so that he or she may make an informed decision as to delete or remove the tag. 
                            </p>
                            <ul>
                                <li>1. Click on Special:NFDAdvanced for a list of deletion discussion requiring admin closure. Most deletions are handled automatically by votes cast through Special:NFDGuardian, but some contentious nominations need manual review.
                                </li>
                                <li>2. Review that the NFD tag is at least seven days old and review the reason for the nomination. 
                                </li>
                                <li>Click on the “Discussion Page” to review discussions.
                                </li>
                                <ul className="wk-guidelines-inner-list">
                                        <li>
                                            a. Correlate the dates of comments with the date of the NFD tag.  
                                        </li>
                                        <li>
                                            b. Review comments made by contributors to determine a consensus.   
                                        </li>
                                        <li>
                                            c. Use the deletion policy as a guide in making a inal decision.     
                                        </li>
                                        <li>
                                            d. Determine whether the discussion warrants deletion or saving of the listing. 
                                        </li>
                                        <ul className="wk-guidelines-inner-list">
                                          <li>i. If the decision is to delete.</li>
                                          <ul className="wk-guidelines-inner-list">
                                             <li>1. Go to the actual listing page and click on the delete tab at the upper right of the page.</li>
                                             <li>2. Insert the reason for deletion into the form</li>
                                             <li>3. Click on the delete page button.</li>
                                             <li>4. Add to the discussion page.</li>
                                             <li>5. This outlines the discussion for a future discussion, which may arise if the title is used at a later time for a new listing.</li>
                                          </ul>
                                          <li>ii. If the Decision is to save the listing:</li>
                                          <ul className="wk-guidelines-inner-list">
                                             <li>1. Click on the discussion page input box.</li>
                                             <li>2. Enter a reason for saving the listing.</li>
                                             <li>3. Use if support was strongly in favor.</li>
                                             <li>4. Use if support was mixed.</li>
                                             <li>5. Return to the listing and click on the edit tab at the top of the listing.</li>
                                             <li>6. Remove the NFD tag.</li>
                                             <li>7. Add “NFD tag removed” or “No support for Deletion” to the edit summary.</li>
                                             <li>8. Click on publish to save your changes.</li>
                                             <li>9. Add new tags for stub, format, or copyediting, as needed.</li>
                                          </ul>
                                        </ul>
                                        <li>4. Click on the history tab of a listing if “Discussion Page” is red in the NFD tag.</li>
                                          <ul className="wk-guidelines-inner-list">
                                             <li>a. Delete the page if a reliable contributor added the NFD tag and you support deletion.</li>
                                             <li>b. Add “No support for deletion” to the discussion page if a tag was placed by an unknown or anonymous contributor and there is no support for deletion.</li>
                                             <ul className="wk-guidelines-inner-list">
                                             <li>i. Remove the NFD tag from the listing.</li>
                                            </ul>
                                          </ul>
                                       <li>5. Go back to the Nomination for Deletion page to return to the list of other listings that are being nominated for deletion and start the process again. </li>
                                </ul>
                            </ul>
                            <p className="fs-14"><b><u>Welcome Guide to Admin Roles & Responsibilities</u></b>
                            </p>
                            <p className="fs-14">Congratulations on being made an admin in the WikiReviews community! This is a reflection of your expressing a wish to take on additional responsibilities to help ensure the smooth running of WikiReviews. It is also a vote of confidence in your abilities and recognition of your outstanding contributions to the community as somebody who upholds the mission, vision, and values of WikiReviews.
                            </p>
                            <p className="fs-14">This is the new admin welcome package. It is aimed at helping to guide you as you “learn the admin ropes”. It may help to bookmark this page for an easy and quick reference. 
                            </p>
                            <p className="fs-14">We trust and value our admins on WikiReviews. In return, we believe that being an administrator on WikiReviews requires every admin to reflect wiki values in all that is done and said on the site and in any dealings concerning WikiReviews off the site. To this end, we value the following qualities in our admins: 
                            </p>
                            <ul>
                                <li><b>Trust</b></li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>You are being entrusted to help maintain and care for WikiReviews. This trust has been earned by you after viewing your good, level-headed, and mature judgment. 
                                   </li>
                                </ul>
                                <li><b>Empathy and kindness</b></li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>Admins exist to serve the broader community of contributors and readers. Treating others with kindness and mutual respect is a necessity. 
                                   </li>
                                </ul>
                                <li><b>Fulfill the WikiReviews mission</b></li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>This is a key element of being an admin on WikiReviews. 
                                   </li>
                                </ul>
                                <li><b>Civility</b></li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>As an admin, you are asked to remain civil when criticized for the decisions you make, to keep calm, and to respond with politeness, kindness, and understanding.
                                   </li>
                                </ul>
                                <li><b>Wiki values</b></li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>We expect admins to uphold these values in everything done in relation to WikiReviews. 
                                   </li>
                                </ul>
                            </ul>
                            <p className="fs-14"><b><u>PRACTICAL THINGS</u></b>
                            </p>
                            <p className="fs-14">First, please add yourself to the administrators list and fill out the areas you like on WikiReviews, as well as saying a little about yourself and offer encouraging words to other fellow contributors to reach out to you for any questions or guidance. 
                            </p>
                            <p className="fs-14">Second, do not panic! As an admin you have some important new skills (responsibilities) to exercise. Start out slowly and always feel free to ask for assistance from existing admins or long-time contributors when in doubt. 
                            </p>
                            <p className="fs-14">If you are feeling lost or if something upsets you (such as coping with vandals), you have the entire community and admin group behind you to help you. Ask for help from fellow admins and/or from members of the Help Team. This site is very large and it contains many technical treasures to unearth. Even some long-time contributors and existing admins have not seen everything. You have already demonstrated to the community that you have the aptitude for handling the responsibilities, but we are not expecting you to be an expert in every facet of the site. So please feel free to ask any and all questions. 
                            </p>
                            <p className="fs-14"><b><u>Useful Tips</u></b>
                            </p>
                            <p className="fs-14">There are several areas in which admins have additional responsibilities for ensuring the smooth running of WikiReviews and for ensuring that all WikiReviews contributors have a pleasant, fulfilling and supported experience. 
                            </p>
                            <p className="fs-14">You gain three additional pulldowns on listings:
                            </p>
                            <ul>
                                <li>Delete</li>
                                <li>Protect</li>
                                <li>Move</li>
                            </ul>
                            <p className="fs-14">You can also block vandals and spammers and you can moderate the forums. Each of these is discussed in the next section. Before that, there are some other practical issues of importance to read through. 
                            </p>
                            <ul>
                                <li>Maintain a strong account password</li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>It is vital that admins maintain strong passwords. Make them hard to guess, but make it a password you can remember. Password theft of guessing has happened on other wikis and there is no reason to believe that it might not happen here. Be vigilant and keep secure. Avoid leaving a computer unattended while logged in to your account to keep exchanges between you and contributors with whom you are less familiar in the public forum, as this serves as a record.
                                   </li>
                                </ul>
                                <li>You will need to provide your e-mail to the steward of WikiReviews, Sunil Wagle, for purposes of being contacted as an admin for matters such as admin elections, as this will be organized and conducted off site.</li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>This e-mail is kept confidential and is only distributed within the admin group. You are also under an obligation to keep other admins’ email addresses confidential. 
                                   </li>
                                </ul>
                            </ul>
                            <p className="fs-14"><b><u>The Three New Functionalities</u></b>
                            </p>
                            <p className="fs-14">As said above, you are gaining 3 new functionalities when becoming an admin.
                            </p>
                            <ul>
                                <li>1.<b>Delete</b> – Allow yourself time to settle into using these functionalities. Feeling comfortable with the deletion process takes some getting used to. The following information on deletion is a guide and is background information to assist you in settling into the deletion process.</li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>i. Deletion during patrolling, new listing creation, and speedy responses – There is no absolute right or wrong – we trust the judgment of our admins. The only real guiding document here is the deletion policy. Beyond that, if you believe that something is entitled to speedy deletion, based on the NFD reasons and, on some occasions, wider moral, ethical and illegality reasons, then you are within your rights to delete an item. Some admins are more deletion-oriented than others; however, it does seem to balance out as an overall team, so do not feel that what you are doing is wrong. What is important is to consider whether the community views this as a good decision and to ensure that your deletion choice is for the good of WikiReviews as a whole. It helps to record a reason/description. You should always try to use clear and descriptive reasons for each deletion. 
                                   </li>
                                   <li>ii. Check for speedy deletions – this is often a good thing to check for when you come online, if it is something that interests you. That way, you can clear out any speedy deletions warranting immediate deletion. Please do not hesitate to reduce speedy deletions to NFDs if you feel that this is warranted. Sometimes users get so upset about issues that they only think of a speedy deletion, whereas with the quiet reflection of an objective party, it may seem only worth of an NFD. The value of NFDs over speedy deletion is that community discussion can then ensue. If a speedy deletion contained content that is heinous and deliberatively proactive, it is often a good idea to block the creator before deleting the listing and providing a blocked notice with reason. This should help to discourage a repeat offense. 
                                   </li>
                                   <li><mark>iii. Other areas of deletion which you are welcome to assist with - just contact our Community Manager at cm@wikireviews.com to find out more.</mark>
                                   </li>
                                   <li>iv. Copyvios – Copyright Violations
                                   </li>
                                   <li>v. Media that is unlicensed
                                   </li>
                                   <li>vi. Requests that are outdated, unanswered, or just not good
                                   </li>
                                   <li>vii. NFD – Nominated for deletion as a result of community nomination and consensus
                                   </li>
                                   <li>viii. If a listing is created that is offensive and the title is not something we wish to see on WikiReviews ever again, it can be blocked using the protection tool. This will prevent recreation of the title. Redirect the listing to the deleted listing notice when this has been done. 
                                   </li>
                                </ul>
                                <li>b. Deletions can be undone for sound reasons</li>
                                <ul className="wk-guidelines-inner-list">
                                  <li>i. Deletions can be undone. Sometimes this is needed when something was deleted by accident, deleted, but asked to be resurrected for good reasons, etc. Exercise your good judgment if you consider an item needs resurrection – whether it is because you deleted it or another admin did. It may help to confer with the admin who deleted it originally and it is courteous to inform the admin that you have resurrected a listing if you do.
                                  </li>
                                  <li>ii. Don’t panic! If you delete a listing and then think twice about it and realize that it perhaps should have not been deleted, take a deep breath, open the deletion resurrection log and undelete. 
                                  </li>
                                </ul>
                               <li><b>2. Protect</b></li>
                               <ul className="wk-guidelines-inner-list">
                                  <li>i. “Protect” simply means that an admin can protect a listing from being edited. In general, as wikis are open, we do not encourage much protection except on listings or site management elements that really do warrant it. You will get a sense of the need for protection from the context and circumstances at any given time. 
                                  </li>
                                  <li>ii. What are the reasons why we would want to do this to an open wiki community? The principal answer is to prevent vandalism of a listing, especially in relation to featured listings. Some featured listings are some controversial topics targeted by members of the internet community for one reason or another – boredom, unhappiness with certain listings, strong points of view, etc. To prevent addition of undesirable text, blanking, or other disruptive actions that do not gel with the goal of WikiReviews and to provide high quality listings to the world, protection of a listing may be a temporary necessity. 
                                  </li>
                                  <li>iii. Practical reasons – There are also some very pragmatic reasons for protection of some parts of WikiReviews. Some items such as the Policies and Terms of Use pages are permanently protected pages because they are integral to the overall standardization of smooth functioning of the site.
                                  </li>
                                  <li>iv. User page – You are entitled to protect your own user page. If you are a high profile user, especially if you are dealing with a lot of vandals, you may wish to protect your user page if it becomes a target for whatever reason. You may also assist contributors in protecting their own user pages as well if they are experiencing attacks on their page. In this instance, it is also helpful to follow up with the person who is attacking the user page. 
                                  </li>
                                  <li>b. There are 3 levels of protection:
                                  </li>
                                  <ul className="wk-guidelines-inner-list">
                                     <li>
                                         <mark>1. Default – This is the usual mode for all listings and documents on WikiReviews and simply means that all users can edit the listing. </mark>
                                     </li>
                                     <li>
                                         <mark>2. Registered users only – This is also known as “semi-protect” and means that registered and admins can edit. </mark>
                                     </li>
                                     <li>
                                          3. Administrator Only – This one only permits admins and WikiReviews staff to edit.
                                         <br/> ii. After protecting a listing, when you edit the listing, you will see a message like this one: 
                                         <br/> “WARNING: This page has been locked so that only users with administrator privileges can edit it.” 
                                     </li>
                                  </ul>
                                  <li>c. Making a decision to protect</li>
                                  <ul className="wk-guidelines-inner-list">
                                     <li>i. Again, we trust your judgment. You may be asked by a contributor to protect a page, you may come across the issues requiring a listing to be protected as part of your activities on the site or you may be somebody who assiduously checks the featured listings. In each instance, the decision is one of balancing the openness of a wiki with the potential for degrading, damaging, or repeated vandalism of a listing. 
                                     </li>
                                  </ul>
                                  <li>d. One other reason a listing may require protection is during its creation, to calm concerns of edit conflicts – be guided by considering the purpose that a contributor is seeking and ensure to unprotect when the listing has been completed. 
                                  </li>
                                  <li>e. Duration of protection
                                  </li>
                                  <ul className="wk-guidelines-inner-list">
                                     <li>i. Keep a personal note of listings you have protected and try to return to them to reassess the protection status in order to return the listing to open editing as soon as possible. 
                                     </li>
                                     <li>ii. Apart from user pages and listings protected for the smooth running of the site, it is usual to unprotect a listing as soon as it appears viable to do so. Check the status of a listing – maybe within a few hours of a vandal attack. After 24 hours, the listing should not be receiving as much traffic. Use your judgment on timeline. It does, however, help to keep vigilant about not leaving listings protected for too long on the whole or it prohibits members of the community from editing them, which defeats the wiki spirit. 
                                     </li>
                                     <li>iii. In some instances of controversial topics, you may wish to consider leaving semi-protected status allowing only registered users to edit a listing. This will be evidenced by a concerted spate of bad faith editing to a listing over a period of time. Check the listings history. 
                                     </li>
                                  </ul>
                                </ul>
                              <li>3. Move</li>  
                              <ul className="wk-guidelines-inner-list">
                                     <li>a. The move button is the “change of title” button. There is a previous section in this manual which lists how to change the title of a listing so we will keep this section short. 
                                     </li>
                                     <li>b. Changing titles is a very important part of WikiReviews. Titles should be spelled correctly, contain proper grammar, and follow our title guidelines listed above.
                                     </li>
                                     <li>c. It is always very helpful to have admins prepared to tend to the title change activity. Titles tend to mount up and remain unchanged without the dedication of admins who take the time to focus on this area. 
                                     </li>
                              </ul>
                            </ul>
                            <p className="fs-14"><b><u>Blocking Users</u></b>
                            </p>
                            <p className="fs-14">Blocking is used for vandals (bad faith contributors) and spammers. When and for how long to block will come to you with time and experience. In general, most blocked users are small-time vandals who went awry out of boredom or limits testing. Several hours to 3 days are generally sufficient.
                            </p>
                            <p className="fs-14">We tend to have less tolerance for completely blanking a listing and if there is spamming, the 3 week upwards block can be used. 
                            </p>
                            <p className="fs-14">Some admins have low thresholds for anything degrading or abusive and use longer blocks on such posters. Again, this is something that will come with experience for you, from watching what existing admins choose to do, from reading the forums, and from relying on your own sense of appropriateness, fairness and justice. 
                            </p>
                            <p className="fs-14">Some things to watch for with blocking: 
                            </p>
                            <ul>
                                <li>1. Leave a message</li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>a. This is to inform a user he or she has been blocked. Although if it is an automatically generated spammer, leaving this message is not really necessary. 
                                   </li>
                                </ul>
                                <li>2. Don’t panic</li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>a. Persistent vandals can really upset members in the community and have them leaving copious messages; respond with a level-head and at a pace that does not get you into a tizzy. You need to react calmly and remember that it may well be that another admin is already on the case. In which case, when you go to block, the block log will tell you that the user has already been blocked. Breathe a sigh of relief and move on to whatever else you were doing. 
                                   </li>
                                </ul>
                                <li>3. Please see the block user section above which clearly delineates rules and benchmarks for blocking which include the following: </li>
                                <ul className="wk-guidelines-inner-list">
                                   <li>a. Users under 13 – These users should be blocked until they turn at least fourteen. In these instances, please remove every part of their user pages, not just the part where they said they are under 13 years old. Be sure to remove their about me section, location, and any other personally identifiable information. 
                                   </li>
                                   <li>b. Spammers
                                   </li>
                                   <ul className="wk-guidelines-inner-list">
                                   <li>i. Spamming refers to spambots or persons who place content on the site that links back to advertising, pornography, or other links. Spamming often occurs on listing and user talk pages and forums, with some pages targeted more than others. <br/> <br/>
                                   1. Indicators of spam include: 
                                   </li>
                                   <ul className="wk-guidelines-inner-list">
                                     <li>a. URL’s</li>
                                     <li>b. Comments such as “Hello, nice page” + URL</li>
                                     <li>c. Photos (usually unsavory but sometimes a concerted campaign to play a game).</li>
                                     <li>d. Invisible text or breaks – sometimes spamming, sometimes game-playing by users</li>
                                   </ul>
                                   <li>c. Changing a Username</li>
                                   <ul className="wk-guidelines-inner-list">
                                     <li>i. Occasionally, a user will create an account, edit some pages, and set their real name to something offensive. Admins can change the user’s real name. This page also appears on Special pages under restricted pages. When a user’s real name is changed, it also generates a log entry, so there will be a history of whose name was changed to what by whom it would also make sense to block a user’s account indefinitely if they have created an offensive username, so they just don’t log back in and use their real name again.</li>
                                   </ul>
                                   </ul>
                                </ul>
                                <li>4. View the admin noticeboard</li>
                                <ul className="wk-guidelines-inner-list">
                                     <li>a. The Administrator Noticeboard is the place to check for vandalism, speedy deletions, and other things that patrollers and contributors, who do not have access to the admin tools, have placed there for the attention of an admin. You might be the only the admin online at a particular time so if you can take a moment to check it to see if there is anything warranting attention, that would be great for the community.</li>
                                </ul>
                                <li>5. Forums</li>
                                <ul className="wk-guidelines-inner-list">
                                     <li>a. Admins can moderate the forums and this includes the following:</li>
                                     <ul className="wk-guidelines-inner-list">
                                       <li>i. Move threads</li>
                                       <ul className="wk-guidelines-inner-list">
                                       <li>1. You can move a thread to another forum category if needed.</li>
                                       </ul>
                                       <li>ii. Close threads</li>
                                       <ul className="wk-guidelines-inner-list">
                                       <li>1. To end a discussion that is heading nowhere, or is causing great angst or just is not appropriate for further additions, you can close a thread.</li>
                                       </ul>
                                       <li>iii. Delete threads</li>
                                       <ul className="wk-guidelines-inner-list">
                                       <li>1. This must be done with great care and consideration. It is fine to delete threads that contain anything racist or hate based. For other things, more care must be taken, especially where responses to an issue raised have been left. Ask another admin if in doubt and be clear, open, and honest about deletion of threads that you have taken in good faith and with good reason. For the main part, we are maintaining WikiReviews records in these forum postings and unless there are very pressing reasons, they should remain there.</li>
                                       </ul>
                                       <li>iv. Sink Threads</li>
                                       <ul className="wk-guidelines-inner-list">
                                       <li>1. Keep new posts to a thread from bumping it to the top of the forum.</li>
                                       </ul>
                                     </ul>
                                </ul>
                                <li>6. Edit posts</li>
                                <ul className="wk-guidelines-inner-list">
                                       <li>a. Use this preferably to remove personal information, resize images, and fix broken code.</li>
                                       <ul className="wk-guidelines-inner-list">
                                        <li>i. As a courtesy, please include a note that you edited it at the bottom of the post. </li>
                                        <li>ii. Although forum tending is everyone’s responsibility, if an admin wishes to take the responsibility of moderating the forums, this would be wonderful. It would include checking for vandalism, keeping threads organized, and if wanted, stepping in to moderate discussions if things seem to be getting out of hand.  </li>
                                      </ul>
                                </ul>
                                <li>7. Categories</li>
                                <ul className="wk-guidelines-inner-list">
                                       <li>a. Physical creation of categories is principally an admin task. Occasionally, wiki-knowledgeable persons will add their own, but generally this is discouraged because it often is not in line with existing practices.</li>
                                       <ul className="wk-guidelines-inner-list">
                                        <li>i. Only admins can edit the drop down category box.</li>
                                        <li>ii. Given that categorization is considered across the whole of the site and work is often underway to make changes, we do ask that you raise desired changes on the category forum first, but all help to make changes is appreciated.</li>
                                      </ul>
                                </ul>
                            </ul>
                            <p className="fs-14"><b><u>Role in the Community</u></b>
                            </p>
                            <p className="fs-14">All admins play an active role in the community, whether by way of example, by presence on the forums, discussion pages, talk pages, or IRC messages/interaction.
                            </p>
                            <p className="fs-14">There is no right or wrong way to interact with the community from a practical sense. From an ethical and perception standpoint, however, care must always be taken to put the interests of WikiReviews and its contributors first, to be civil and understanding and to remember that admins do carry a public persona of authority as far as contributors are concerned, even though we always take pains to stress the equality and equal importance of every single user on the site. This perception means that we do have an obligation to uphold WikiReviews in its best light, to be considerate and caring in our interactions and to fulfill the role to the best of our abilities and interests in the time that we have available for WikiReviews. 
                            </p>
                            <p className="fs-14"><b><u>Clarifying Roles of Adminship</u></b>
                            </p>
                            <p className="fs-14">Admins are not expected to be online all the time and admins are not expected to be the janitors of WikiReviews. Where your skills lie is where you will serve WikiReviews best and from where you will derive the most pleasure from this hobby. You are not expected to be typecast and forced into doing anything that you do not enjoy on the site. 
                            </p>
                            <p className="fs-14">Equally, we ask that you do not typecast the admin role in forum discussions, but take care to make it clear to the WikiReviews community that admins are also keen contributors with differing skills, different time availabilities, and different interests. Calling admins “janitors” and assuming the only tasks admins have is to patrol harms the roundedness and fulfillment of being an admin.
                            </p>
                            <p className="fs-14">Adminship is not a trophy and this perception is raised from time to time and is an inaccurate reflection of what admins are. Admins are contributors who have willingly taken on additional responsibilities and who have willingly accepted to help make WikiReviews run smoothly. Anyone who takes on adminship as a “reward” for good work will be sorely disappointed. It doesn’t anoint you with a crown and a position above other contributors. You remain an equal contributor with all other contributors, although you must be prepared for the fact that your public profile is likely to be more scrutinized and higher expectations placed on you (just keep being who you are, there is no call for super humans here).
                            </p>
                            <p className="fs-14">Admins can be considered primarily organizers and facilitators, not controllers. Wikis do not have controllers. The community appreciates your acceptance of the responsibilities and for your initiatives, care for the community and dedication to the site. Thank you for taking on the task of helping to keep WikiReviews run smoothly. 
                            </p>
                            <p className="fs-14"><b><u>Making Changes</u></b>
                            </p>
                            <p className="fs-14">Be bold and don’t feel constrained. Just as with every contributor here, an admin is not restricted in moving things forward and making changes to better WikiReviews. Use your skills to your best advantage on WikiReviews and enjoy your time here. Everything that you contribute is valued and appreciated. As an admin, your commitment to the additional responsibilities is also valued, as is your continued inspiration to moving WikiReviews on into the future as a strong, better, and universally valued site for information. So when you want to innovate and do things that will make WikiReviews a better place, go ahead and be a part of this. We trust you and your initiative.
                            </p>
                            <p className="fs-14"><b><u>Difficult or Stressful Situations</u></b>
                            </p>
                            <p className="fs-14">In times of difficult, stressful, or awkward situations on WikiReviews, please remember that you are never alone and you are not expected to solve problems by yourself. You are always entitled to seek assistance from other admins if you feel out of your depth, personally conflicted or just not willing to become involved in issues that may arise in the community and that have affected you in some way or other. 
                            </p>
                            <p className="fs-14">If you feel unduly pressured or asked to do things that upset or overwhelm you by any user on the site, you are entitled to say no and if necessary, to seek assistance from another admin or a staff member of WikiReviews. You are also entitled to take time off the site and refresh. 
                            </p>
                            <p className="fs-14">In addition, you are welcome to contact Sunil Wagle at swagle@wikireviews.com, if you have any concerns about such situations, although many other admins are also willing to assist if you prefer. 
                            </p>
                            <p className="fs-14">Always remember that the admin team is here to support one another and no question is ever a wrong or stupid question. We want to make sure you are happy as part of the admin team and things are running smoothly for you. We want to ensure that you do not feel any sense of difficulties, lack of clarity, or pressure that prevents this from being a fun hobby.
                            </p>
                            <p className="fs-14"><b><u>You’re Off!</u></b>
                            </p>
                            <p className="fs-14">Now that you have your new functionalities and other responsibilities on WikiReviews, it is now time to settle into using these new responsibilities and see how you feel in making use of them for the good of WikiReviews and the WikiReviews community. Ask questions, try things out, and keep in mind that you have an admin team backing you and ready to help you at any moment.
                            </p>
                            <p className="fs-14">Thank you for taking on these responsibilities and for sharing your skills, time, and interest with WikiReviews. We know you will get a lot out of it and we also know that WikiReviews will grow and flourish all the more for your dedication, tending, and enthusiasm!
                            </p>
                            <p className="fs-14">Enjoy and thank you! 
                            </p>
                            <h2 id="">Business and Professional Accounts </h2>
                            <p className="fs-14"><b><u>Business Owner Accounts</u></b>
                            </p>
                            <p className="fs-14">To claim your business, select “Claim This Business” on the listing page. You will then be taken through the claims process, where you will be required to provide information that validates your ownership. 
                            </p>
                            <p className="fs-14">We have made this process thorough for your protection. We don’t want illegitimate owners claiming your business before you do. 
                            </p>
                            <p className="fs-14"><b><u>How Best to Publicize Your Business</u></b>
                            </p>
                            <p className="fs-14">While many users come to WikiReviews for fun and to help others by sharing reviews, some come to promote themselves or their businesses.
                            </p>
                            <p className="fs-14">Here are few easy ways to do this: 
                            </p>
                            <ul className="list1">
							<li>
								1. Please be sure to first claim your business. 	
							</li>
							<li>
								2. Validate yourself as the responsible person at the business if you are the owner or are the authorized person at the business responsible for claiming the business. Your listing will be “verified” if you do a phone validation. Otherwise, your listing will be verified by the Wiki community over time.
							</li>
							<li>
								3. Enhance your listing by adding as much detail to the listing as possible and be sure to add info in the additional info and about your business sections. 	
							</li>
							<li>
								4. Upload all images and video best suited to showcase your listing.
							</li>
							<li>
								5. Place any links to videos or specific pages to your own website that you feel you would like to use to showcase your listing.
							</li>
							<li>
								6. Respond to each and every user who creates a review so you can thank them for their valuable feedback and learn how to make their experience better.
							</li>
							<li>
								7. Settle and try to resolve any and all issues amicably and nicely. It is always better to use niceties to resolve issues than getting angry or upset.
							</li>
							<li>	
								8. Be sure not to overload the site will irrelevant links, images, videos, and extraneous information not related to the listing.
							</li>
							<li>
								9. Do not spam the site by being overly commercial in nature.
							</li>
							<li>
								10. Know that all of our outbound links are “No Follow” so linking out to increase your search rankings will not help. This means that the links do not get indexed by the search engines and posting links will not improve your rankings.	
							</li>	
						</ul>
                           <p className="fs-14"><b><u>Professional Accounts</u></b>
                            </p>
                            <p className="fs-14">To claim your business, select “Claim This Professional Account” on the listing page. You will then be taken through the claims process, where you will be required to provide information that validates your identity. 
                            </p>
                            <p className="fs-14">We have made this process thorough for your protection. We don’t want illegitimate individuals claiming your professional identity.  
                            </p>
                            <p className="fs-14">While many users come to WikiReviews for fun and to help others by sharing reviews, some come to promote themselves or their businesses.
                            </p>
                           <p className="fs-14"><b><u>How Best to Publicize Your Business</u></b>
                            </p>
                            <p className="fs-14">While many users come to WikiReviews for fun and to help others by sharing reviews, some come to promote themselves or their businesses.
                            </p>
                            <p className="fs-14">Here are few easy ways to do this: 
                            </p>
                            <ul className="list1">
							<li>
								1. Please be sure to first claim your business. 	
							</li>
							<li>
								2. Validate yourself as the responsible person at the business if you are the owner or are the authorized person at the business responsible for claiming the business. Your listing will be “verified” if you do a phone validation. Otherwise, your listing will be verified by the Wiki community over time.
							</li>
							<li>
								3. Enhance your listing by adding as much detail to the listing as possible and be sure to add info in the additional info and about your business sections. 	
							</li>
							<li>
								4. Upload all images and video best suited to showcase your listing.
							</li>
							<li>
								5. Place any links to videos or specific pages to your own website that you feel you would like to use to showcase your listing.
							</li>
							<li>
								6. Respond to each and every user who creates a review so you can thank them for their valuable feedback and learn how to make their experience better.
							</li>
							<li>
								7. Settle and try to resolve any and all issues amicably and nicely. It is always better to use niceties to resolve issues than getting angry or upset.
							</li>
							<li>	
								8. Be sure not to overload the site will irrelevant links, images, videos, and extraneous information not related to the listing.
							</li>
							<li>
								9. Do not spam the site by being overly commercial in nature.
							</li>
							<li>
								10. Know that all of our outbound links are “No Follow” so linking out to increase your search rankings will not help. This means that the links do not get indexed by the search engines and posting links will not improve your rankings.	
							</li>	
						</ul>
                        <h2 className="intro" id="style-guide">STYLE GUIDE</h2>
                        <p className="fs-14">For style guidelines, users should refer to the Chicago Manual of Style.
                        </p>
                        </div>
                        </Col>
                        </Row>
                      </div>
                  </Container>
                </div>
            </div>
        )
    }
}
