import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { GET_WALL_DATA } from "../reducers/shared";
import * as productWallData from './product_wallData.json' 
import { get_type_detail, setItemDetailLoading } from "./itemDetail";

export const FETCH_SIMILAR_PRODUCTS = "FETCH_SIMILAR_PRODUCTS";
export const FETCH_COMPARE_PRODUCTS = "FETCH_COMPARE_PRODUCTS";
export const GET_REVIEW_LISTING_PRODUCT = "GET_REVIEW_LISTING_PRODUCT";
export const GET_TIPS_WARNING = "GET_TIPS_WARNING";
export const GET_PROBLEM_SOLUTION = "GET_PROBLEM_SOLUTION";
export const GET_PROBLEM_RECORD = "GET_PROBLEM_RECORD";
export const GET_SAVE_RESPONSE = "GET_SAVE_RESPONSE";
export const FETCH_WHAT_REVIEWERS_SAY = "FETCH_WHAT_REVIEWERS_SAY";

//Get Similar Products
export const fetch_silimar_products_ = (data) => ({ type: FETCH_SIMILAR_PRODUCTS, payload: data })
export const fetch_silimar_products = (id) => {
  return (dispatch) => {
    callApi(`/api/products/?id=${id}&similar=products`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(fetch_silimar_products_(response));
        }
      })
  }
}
//Get Similar Products
export const fetch_compare_products_ = (data) => ({ type: FETCH_COMPARE_PRODUCTS, payload: data })
export const fetch_compare_products = (compareIds) => {
  return (dispatch) => {
    callApi(`/api/products/?category=${compareIds}&compare=true`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(fetch_compare_products_(response));
        }
      })
  }
}

export const delete_products_review = (id, movie_id, name) => {
  return (dispatch) => {
    callApi(`/api/reviews/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {

        toast(`Your review of ${name} has been deleted.`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        dispatch(get_type_detail({ type: 'products', id: movie_id }))
        return dispatch(get_reviews_listing(movie_id));
      }
    });
  };
};

//Get reviews listing for particular movie
export const get_reviews_listing_ = (data) => ({ type: GET_REVIEW_LISTING_PRODUCT, payload: data });
export const get_reviews_listing = (id, type, searchValue) => {
  if (!type) { type = 'new' }

  let url = `/api/reviews/?entry=&associated_to=${id}&searchTerm=${type}`;
  if(searchValue) url = `/api/reviews/?asset=&page=1&review=&review_type=All&search=${searchValue}&entry=${id}`;

  return (dispatch) => {
    if(searchValue || searchValue==="") dispatch(get_reviews_listing_([]));
    
    callApi(url, "GET").then((response) => {
      if (response && response?.code !== 400 && response?.code !== 500) {
        dispatch(setItemDetailLoading(false));
        dispatch(get_reviews_listing_(response));
      }
    });
  };
};

//What reviewers say
export const fetch_what_reviewers_say_ = (data) => {
  return {
    type: FETCH_WHAT_REVIEWERS_SAY,
    payload: data
  };
};

export const fetch_what_reviewers_say = (productId) => {
  const response = {
    count: Reviews.length,
    reviews: Reviews
  }
  return (dispatch) => {
    return dispatch(fetch_what_reviewers_say_(response));
  }
}


// Tips Warning
export const get_tips_warning_ = (data) => {
  return {
    type: GET_TIPS_WARNING,
    payload: data,
  };
};

export const get_tips_warning = (type) => {
  return (dispatch) => {
    callApi(`/api/userguide/?entry_type=${type}`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_tips_warning_(response));
      }
    });
  };
};

export const delete_tips_warning = (id, movieId, type) => {
  return (dispatch) => {
    callApi(`/api/userguide/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if (type == 'warning') {
          text = `Your warning is deleted.`;
        }
        if (type == 'tips') {
          text = `Your tip is deleted.`;
        }
        if (type == 'advisory') {
          text = `Your warning is deleted.`;
        }
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(get_tips_warning(movieId));
    });
  };
};

export const add_tips_warning = (data, name) => {
  return (dispatch) => {
    callApi(`/api/userguide/?entry_type=`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if (data.type == 'warning') {
          text = `You added a warning to  ${name}.`;
        }
        if (data.type == 'tips') {
          text = `You added a tip to  ${name}.`;
        }
        if (data.type == 'advisory') {
          text = `Your content added to a Advisory section.`;
        }
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(get_tips_warning(data.user_entry));
    });
  };
};

export const edit_tips_warning = (data, id, name) => {
  return (dispatch) => {
    callApi(`/api/userguide/?entry_type=`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if (data.type == 'warning') {
          text = `You have updated a warning to ${name}.`;
        }
        if (data.type == 'tips') {
          text = `You have updated a tip to ${name}.`;
        }
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(get_tips_warning(id));
    });
  };
};

// Problem and Solution
export const get_problem_list_ = (data) => {
  return {
    type: GET_PROBLEM_SOLUTION,
    payload: data,
  };
};

export const get_problem_list = (id) => {
  return (dispatch) => {
    callApi(`/api/problems/?entity=${id}&categoryproblems&page=1&qid=null`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_problem_list_(response));
      }
    });
  };
};

export const submit_problem = (id, data) => {
  return (dispatch) => {
    callApi(`/api/problems/?entity=`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        // toast(`Your post has been submitted successfully.`, {
        toast(`Solution added successfully.`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_problem_list(id));
      }
    });
  };
};

export const delete_problem = (id, productId, message) => {
  return (dispatch) => {
    callApi(`/api/deleteuserfeeds/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`${message}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_problem_list(productId));
      }
    });
  };
};

export const submit_problem_answer = (id, data) => {
  return (dispatch) => {
    callApi(`/api/questions/${id}/?entity=`, "PUT", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Solution added successfully.`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_problem_list(data.user_entry));
      }
    });
  };
};

export const submit_edit_answer = (id, data, type) => {
  return (dispatch) => {
    callApi(`/api/feeds/${id}/?entity=`, "PUT", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let msg = 'Action Performed';
        if (type == 'biz_msg') {
          msg = 'Your Answer has been Updated.'
        }
        if (type == 'problem') {
          msg = 'Your Problem has been Updated.'
        }
        if (type == 'add_reply') {
          msg = 'You replied to answer.'
        }
        if (type == 'edit_reply') {
          msg = 'Your reply has been updated.'
        }
        toast(`${msg}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_problem_list(data.user_entry));
      }
    });
  };
};

export const submit_answer_reply = (data) => {
  return (dispatch) => {
    callApi(`/api/savereply/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Your Reply has been Submit.`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_problem_list(data.user_entry));
      }
    });
  };
};



export const user_feed_prob_ = (data) => {
  return {
    type: GET_PROBLEM_RECORD,
    payload: data,
  };
};

export const user_feed_prob = (id) => {
  return (dispatch) => {
    callApi(`/api/feeds/${id}/`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(user_feed_prob_(response));
      }
    });
  };
};

export const get_response_ = (data) => {
  return {
    type: GET_SAVE_RESPONSE,
    payload: data,
  };
};

export const add_product = (data) => {
  return (dispatch) => {
    callApi(`/api/products/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Hooray! You've created a new listing. Help improve it by adding additional information and reviews.`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(get_response_(response));
      }
    });
  };
};

export const clear_product = () => {
  return (dispatch) => {
    dispatch(get_response_(""));
  };
};

export const get_product_details_wall_data_ = (data) => {
  return {
    type: GET_WALL_DATA,
    payload: data,
  };
};

export const product_details_wall_data_loading = (data) => {
  return {
    type: "WALL_DATA_LOADING",
    payload: data,
  };
};

export const get_product_details_wall_data = ({id,filter_type,page,force}) => {
  return (dispatch) => {
    //DummyData
    // return dispatch(get_product_details_wall_data_(productWallData));

    try {
      callApi(`/api/bizownerfeeds/?id=${id}&filter_type=${filter_type}&listing=true&page=${1}`, "GET").then((response) => {
        // response['filter_type'] = filter_type ? filter_type : 'all';
        // response['filter_type'] = 'all';
        // response['page'] = 1;
        dispatch(get_product_details_wall_data_(response.results));
        dispatch(product_details_wall_data_loading(false));
      })
    }
    catch (e) {
      console.log(e)
    }
  };
};


//temp Static data
const Reviews = [{
  review: "Very Good!",
  votes: 120
},
{
  review: "Amazing!",
  votes: 20
},
{
  review: "Comment 01!",
  votes: 22
},
{
  review: "Comment 02!",
  votes: 24
},
{
  review: "Comment 03!",
  votes: 12
},
{
  review: "Comment 04!",
  votes: 12
},
{
  review: "Comment 05!",
  votes: 12
},
{
  review: "Comment 06!",
  votes: 12
},
{
  review: "Comment 07!",
  votes: 12
},
{
  review: "Comment 08!",
  votes: 12
},
]