/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container, Row, Col, FormGroup, Button, Input, InputGroup, InputGroupAddon, Label, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destination_details: {
        latitude: 0,
        longitude: 0,
        travelMode: 'DRIVING',
		addressTo:''
      },
      routes: [],
      address: "",
      lat: "",
      lng: "",
      routesData: []
    }

    this.wrapperRef = React.createRef();
  }

  handleChange = address => {
    this.setState({ address, routes: [] });
  };

  handleSelect = async (address) => {
    let city = '';
    let county = '';
    let state = '';
    let country = '';
    
    const result = await geocodeByAddress(address);
    result && result[0] && result[0].address_components.map((addr) => {
      if (addr.types[0] === "locality") {
        //city name
        city = addr.long_name
      } else if (addr.types[0] === "administrative_area_level_2") {
        //county name
        county = addr.long_name
      } else if (addr.types[0] === "administrative_area_level_1") {
        //state name
        state = addr.short_name
      } else if (addr.types[0] === "country") {
        //state name
        country = addr.short_name
      }
    })
    const latlng = await getLatLng(result[0]);
	await this.setState({
      address: address,
      lat: latlng.lat,
      lng: latlng.lng,
      destination_details: {
        ...this.state.destination_details,
        latitude: latlng.lat,
        longitude: latlng.lng,
		addressTo : address
      }
    }, () => {
      
    });

  };

  handleOnClickGetDirections = () => {
    let detail = { ...this.state.destination_details };
    this.props.handleDestinationChange(detail);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({ routesData: [] })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    this.setState({ routesData: [] })
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      //this.props.handleEnabelSearch();
    }
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const { destinationDetails, routes } = nextProps;

    if (destinationDetails) {
      this.setState({
        destination_details: destinationDetails,
      });
    }

    if (routes) {
      this.setState({
        routes: routes
      });
    }

    if (nextProps.fetch_map_data &&
      Array.isArray(nextProps.fetch_map_data) &&
      nextProps.fetch_map_data.length > 0) {
      this.setState({
        routesData: nextProps.fetch_map_data
      });
    } else {
      this.setState({
        routesData: []
      });
    }

  }

  handleTravelModeChange = (mode) => {
    this.setState({
      destination_details: {
        ...this.state.destination_details,
        travelMode: mode
      }
    }, () => {
      let detail = { ...this.state.destination_details };
      this.props.handleDestinationChange(detail);
    });
  }
  
  secondsToHms =(d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
    return hDisplay + mDisplay; 
	}
  render() {
    const { businessDetails, } = this.props;
    const { destination_details, routesData } = this.state;

    const searchOptions = {
      componentRestrictions: { country: ['us', 'ca'] },
      // types: ['city']
    };

    return (
      <Container>
        <Row>
          <Col className="flex-300">
            <div className="wr-movie-top-about shadow">
              <div className="wr-movie-top-about-body">
                <div className="wr-movie-product-info-top d-flex flex-wrap">
                  <div className="wr-movie-product-info-top-left">
                    <img src={businessDetails?.listing_logo_pic && businessDetails?.listing_logo_pic[0] && businessDetails?.listing_logo_pic[0]?.thumbnail ? businessDetails?.listing_logo_pic[0]?.thumbnail : 'https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'} className="img-fluid" alt={businessDetails?.name} />
                  </div>
                  <div className="wr-movie-product-info-top-right">
                    <div className="wr-category-heading">
                      <h4>{businessDetails?.name}</h4>
                      <div hidden>
                        <InputGroup size="sm">
                          <Input type="text" placeholder="Enter Name" />
                          <InputGroupAddon addonType="append">
                            <Button color="light"><FontAwesomeIcon icon="arrow-right" /></Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </div>
                      <p className="fs-14"><img title={businessDetails?.total_rating?.avg_rating[1]} src={(businessDetails && businessDetails?.total_rating?.avg_rating[1] !== 'No Rating') ? require(`../../../assets/images/icons/${Math.floor(businessDetails?.total_rating?.avg_rating[1])}rating.png`) : require('../../../assets/images/icons/norating.png')} alt="" className="img-fluid" /> {businessDetails?.total_rating?.total_review} reviews</p>
                    </div>
                  </div>
                </div>
                <div className="wr-movie-product-info-bottom pt-0">
                  <ul className="m-0 p-0">
                    <li className="d-flex">
                      <span className="fs-14 wr-movie-product-info-bottom-left">Address:</span>
                      <span className="wr-movie-product-info-bottom-right fs-14 font-weight-bold lh-1-2">
                        <span className="d-block">
                          {businessDetails?.address[0]?.address1}{businessDetails?.address[0]?.address2 ? ', ' : ''}{businessDetails?.address[0]?.address2}
                        </span>
                        <span className="d-block">
                          {businessDetails?.address[0]?.city}, {businessDetails?.address[0]?.state} {businessDetails?.address[0]?.zipcode},&nbsp;
                        </span>
                        <span className="d-block">
                          {businessDetails?.address[0]?.country}
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <ul className="wk-transport">
                  <li>
                    <a href="javascript:void(0)" onClick={() => this.handleTravelModeChange('DRIVING')} className={`icon-car ${destination_details.travelMode === 'DRIVING' ? 'active' : ''}`}></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={() => this.handleTravelModeChange('TRANSIT')} className={`icon-train ${destination_details.travelMode === 'TRANSIT' ? 'active' : ''}`}></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={() => this.handleTravelModeChange('WALKING')} className={`icon-walk ${destination_details.travelMode === 'WALKING' ? 'active' : ''}`}></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={() => this.handleTravelModeChange('BICYCLING')} className={`icon-bycl ${destination_details.travelMode === 'BICYCLING' ? 'active' : ''}`}></a>
                  </li>
                </ul>
                <div className="wr-business-location-form">
                  <FormGroup>
                    <Label>Start From:</Label>
                    {/* <Input className="primary" type="text" bsSize="sm" name="email" placeholder="Enter Address" /> */}
                    <div ref={this.wrapperRef}>
                      <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={searchOptions}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div className="position-relative">
                            <input
                              {...getInputProps({
                                placeholder: 'Enter Address',
                                className: 'location-search-input comment-form-control form-control form-control-sm',
                              })}
                            />
                            <div className="autocomplete-dropdown-container position-absolute" style={{minWidth: 'unset'}}>
                              {loading && <div className="bg-white fs-14 p-2">Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <FontAwesomeIcon className="mr-2" fixedWidth icon="map-marker-alt" />
                                    {/*suggestion.formattedSuggestion.mainText*/}
                                    {suggestion.description}
                                    {/* <small>
                                        {suggestion.formattedSuggestion.secondaryText}
                                    </small> */}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <a href="javascript:void(0)" className="view-all fs-14 font-weight-bold" onClick={this.handleOnClickGetDirections}>get directions</a>
                  </FormGroup>

                  <div className="clearfix"></div>
                  <div className="adp fs-14">
                    {routesData[0] && (
                      <div className="ff-alt fs-14">
                        <span>{destination_details.travelMode} directions</span>
                        <br />
						<span>{Math.round((routesData[0]?.summary?.totalDistance * 0.621371) / 1000)} Mi &nbsp; About {this.secondsToHms(routesData[0]?.summary?.totalTime)}</span>
                      </div>
                    )}
                    {/*<table className="adp-directions">
                      <tbody>
                        {routesData[0]?.instructions?.length > 0 && routesData[0]?.instructions.map((step, i) => {
                          return (
                            <tr>
                              <div className="adp-stepicon">
                                <div className="adp-turn-right adp-maneuver" >
                                </div>
                              </div>
                              <td className="adp-substep">{i + 1}.</td>
                              <td className="adp-substep">
                                {ReactHtmlParser(step.text)}
                              </td>
                              <td className="adp-substep">
                                <div className="adp-distance">{Math.round((step?.distance / 1000 + Number.EPSILON) * 10) / 10}</div>
                              </td>
                            </tr>
                          )
            })} 
            </tbody>
					  </table>*/}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapState = (state) => ({
  fetch_map_data: state.business.fetch_map_data,

});
export default connect(mapState)(Location);

