/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Button, } from 'reactstrap';
import CollapseBasic from '../../../component/atoms/collapse';


export default class discussThisPage extends Component {

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile} = this.props;
        if(my_profile && my_profile.id && authId && (authId == my_profile.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const { stateSet, discussThisPage } = this.props;
        if(discussThisPage?.results?.length){
            discussThisPage['results'] =  discussThisPage?.results?.splice(0,3);
        }

        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic
                    title="Discuss This Page"
                    containerClass="bg-transparent type-secondary"
                    bodyClass="mt-2 text-secondary-dark"
                    isOpen={true}>
                    <ul className="list-unstyled">
                        {discussThisPage && discussThisPage?.results && Array.isArray(discussThisPage?.results) && discussThisPage?.results?.length ?
                            discussThisPage?.results.map(item => (
                                <li className="ff-base">
                                    <div className="font-weight-bold wb-break-word">
                                        <a href="#" className="text-reset font-weight-bold">{item.title}</a>
                                    </div>
                                    <div>
                                        {`Created ${item.sent_at} by`} <a href="#" className="text-reset font-weight-bold">{item.sender}</a>
                                    </div>
                                    <hr className="bg-light" />
                                </li>
                            ))
                            : 'No Record Found'}
                    </ul>
                    <div className="text-right">
                        <Button
                            color="link"
                            className="p-0 text-secondary-dark font-weight-bold"
                            onClick={() => { 
                                if(!this.checkIfLoggedIn()){
                                    this.props.forceToggleLoginModal();
                                    return false;
                                }
                                stateSet({ createNewDisModalToggle: true }) }}>
                            Create a New Discussion
                    </Button>
                    </div>
                </CollapseBasic>
            </div>
        )
    }
}
