/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { callApi, responseCodeValidator } from "../../utils/apiCaller";
import Loader from "../atoms/loader";
import AppHeader from "../oragnisms/dashboard/appHeader";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Collapse,
} from "reactstrap";
import CollageItems from "../oragnisms/home/CollageItems";
import {
  fetch_products_newtrends,
  fetch_products_categories,
  fetch_products_sub_categories,
  fetch_products_reviews_list,
  fetch_filtered_products,
  setCategoriesLoading,
} from "../../actions/productsListing";
import UserFeedsPanel from "../atoms/userFeedsRightPanel";
import { getQueryString } from "../../utils/validation";
import { detectlink } from "../../utils/utilities";

class Products extends Component {
  constructor(props) {
    super(props);
    this.category = false;
    this.enableScrollTillEnd = true;

    this.state = {
      currentPageIndex: 1,
      checkNextPagination: false,
      productsList: [],
      myConnectionActivity: "all",
      viewEditReviewStep: "writeReview",
      isLoader: false,
      products_sub_categories: null,
      products_sub_categories1: null,
      products_sub_categories2: null,
      childItems: {},
      isLoadingCategories: false,
      isLoadingProductItems: false,
      priceSelected: null
    };
  }

  componentDidMount() {
    this.props.fetch_products_newtrends({
      trending: "national",
      content_type: "products",
      listingtaxonomy: "",
    });
    // this.props.fetch_products_reviews_list({ cPage: true, cat: '', content_type: 'products', location: false, page: this.state.currentPageIndex, showReview: true, to_api: true });

    this.props.setCategoriesLoading(true);
    this.props.fetch_products_categories({
      category: "products",
      depth: false,
      delsel: "",
      ancestor: "",
      sub_cat: "",
      // level: 1,
      level: "",
    });
    this.onHandleClickSubCat({ enable: false, item: false });
    window.addEventListener("scroll", this.handleScroll, { passive: false });
    document.title = `Product Listings, Review your products on Wikireviews`;
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let scrollTillHeight = window.pageYOffset;
    let lastElemHeight = document.getElementById("last-index")?.offsetTop;
    let currentPageIndex = this.state.currentPageIndex;
    let checkNextPagination = this.state.checkNextPagination;

    if (scrollTillHeight > lastElemHeight && checkNextPagination) {
      if (this.enableScrollTillEnd) {
        this.enableScrollTillEnd = false;
        currentPageIndex++;
        this.setState({ currentPageIndex }, () => {

          this.onHandleClickSubCat({ enable: false, item: false });
        });
      }
    }
  };

  componentWillReceiveProps = (nextProps) => {
    let { products_sub_categories } = this.state;

    // if (nextProps?.products_reviews?.results && this.state.productsList?.length) {
    //     let productsList = this.state.productsList;
    //     productsList = productsList.concat(nextProps.products_reviews?.results);
    //     this.enableScrollTillEnd = true;
    //     this.setState({
    //         productsList,
    //         checkNextPaination: nextProps.products_reviews?.next
    //     });
    // } else {
    //     this.setState({
    //         productsList: nextProps?.products_reviews?.results,
    //         checkNextPaination: nextProps.products_reviews?.next
    //     });
    // }

    // if (nextProps?.products_sub_categories && products_sub_categories == null) {
    //     this.setState({ products_sub_categories: nextProps.products_sub_categories });
    // }
    // if (nextProps?.products_sub_categories1) {
    //     this.setState({ products_sub_categories1: nextProps.products_sub_categories1 });
    // }
    // if (nextProps?.products_sub_categories2) {
    //     this.setState({ products_sub_categories2: nextProps.products_sub_categories2 });
    // }

    if (nextProps?.fetch_star_filter_data?.results) {
      let productsList = this.state.productsList;
      productsList = productsList.concat(nextProps.fetch_star_filter_data.results);
      this.enableScrollTillEnd = true;
      this.setState({
        productsList,
        checkNextPagination: nextProps.fetch_star_filter_data?.next,
        isLoadingProductItems: false
      });
    }

    if (
      nextProps?.products_categories &&
      Array.isArray(nextProps?.products_categories) &&
      nextProps?.products_categories?.length
    ) {
      this.setState({
        products_categories: nextProps.products_categories
      });
    }

    if ("isCategoryLoading" in nextProps) {
      this.setState({
        isLoadingCategories: nextProps.isCategoryLoading
      });
    }

    // if (nextProps?.products_reviews) {
    //     this.setState({
    //         products_reviews: nextProps.products_reviews
    //     })
    // }
  };

  sortAsc = (a, b) => {
    if (a.category < b.category) {
      return -1;
    }
    if (a.category > b.category) {
      return 1;
    }
    return 0;
  };

  onHandleClickSubCat = ({ enable, item }) => {
    let { selectedItem } = this.state;
    let category = "products";

    // return
    if (enable) {
      // window.scrollTo(0, 0);
      let element = document.getElementById("scrollIntoViewTop");
      element.scrollIntoView({ behavior: "smooth", inline: "nearest" });

     if(item) item.selectedId = item.id;

      this.setState(
        {
          productsList: [],
          currentPageIndex: 1,
          selectedItem: item,
          isLoadingProductItems: true,
        },
        () => {
          this.fetchMiddleItems({
            name: category,
            id: item?.id,
            page: this.state.currentPageIndex,
          });
        }
      );
    } else {
      // this.fetchCollageItems({ parent_category });
      // this.fetchSubCateories({ parent_category, id });

      this.setState({ isLoadingProductItems: true }, () => {
        this.fetchMiddleItems({
          name: category,
          id: selectedItem?.id ? selectedItem.id : "",
          page: this.state.currentPageIndex,
        });
      });
    }
  };

  fetchMiddleItems = async ({ name, id, page }) => {
    let { productsList, checkNextPagination } = this.state;
    const queryParams = this.getProductsRequestQueryPayload(page);
    const url = getQueryString({ url: `/api/search/`, data: [queryParams] });
    // let url = `/api/search/?content_type=${name}&subcat=${id}&to_api=true&showReview=true&cPage=true&location=false&page=${page}`;

    try {
      this.setState({
        isLoadingProductItems: true,
      });
      let response = await callApi(url, "GET");
      if (response && responseCodeValidator(String(response.code))) {
        let data = response?.results;
        if (data && Array.isArray(data) && data?.length) {
          if (response.next) {
            if (productsList?.length) {
              productsList = productsList.concat(data);
            }
            if (productsList?.length == 0) {
              productsList = data;
            }
            this.enableScrollTillEnd = true;
            checkNextPagination = true;
          } else {

            if (productsList?.length) {
              productsList = productsList.concat(data);
            }
            
            this.enableScrollTillEnd = false;
            checkNextPagination = false;
          }
          this.setState({
            productsList,
            checkNextPagination,
            isLoadingProductItems: false,
          });
        }
      }
    } catch (err) {
      console.log({ err });
    }
  };

  getAllSubCategories = async (item) => {
    let { childItems } = this.state;
    let url = `/api/taxonomy/?category=${item?.id}&depth=true&delsel=&ancestor=&sub_cat=&level=`;
    try {
      let response = await callApi(url, "GET");
      if (response && responseCodeValidator(String(response.code))) {
        if (childItems[item.category]?.isOpen == undefined) {
          childItems[item.category] = {
            isOpen: true,
            values: response,
            parent_id: item.id,
          };
        } else {
          if (this.state[item.category]?.isOpen) {
            childItems[item.category] = { isOpen: true, values: response };
          } else {
            childItems[item.category].isOpen = !childItems[item.category]
              .isOpen;
          }
        }
        this.setState({ childItems });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  getProductsRequestQueryPayload = (page) => {
    const { selectedItem, starFilter,priceSelected } = this.state;
    const queryParams = {
      content_type: "products",
      cat: "",
      subcat: selectedItem?.selectedId || '',
      to_api: true,
      showReview: true,
      cPage: true,
      location: false,
      page: page || 1,    
    };

    if (starFilter > 0) queryParams.stars = starFilter;
    if (priceSelected > 0 ) queryParams.price = priceSelected;

    return queryParams;
  }

  handleStarFilter = (star) => {
    this.setState(
      {
        productsList: [],
        currentPageIndex: 1,
        isLoadingProductItems: true,
        starFilter: star
      },
      () => {
        this.props.fetch_filtered_products(this.getProductsRequestQueryPayload());
      });
  };

  callAbleChildRenders = ({ childItems, item }) => {
    // THIS IS SUB-SUB CATEGORY SECTION
    return (
      <Collapse isOpen={childItems[item.category]?.isOpen}>
        <ul className="list-unstyled pl-2 fs-14">
          {childItems[item.category]?.values &&
            Array.isArray(childItems[item.category]?.values) &&
            childItems[item.category]?.values?.length
            ? childItems[item.category]?.values
              .sort((a, b) => {
                if (a.category < b.category) {
                  return -1;
                }
                if (a.category > b.category) {
                  return 1;
                }
                return 0;
              })
              .map((item_child, item_index) => (
                <li
                  className={`py-2 ${childItems[item.category]?.values?.length ==
                    item_index + 1
                    ? ""
                    : "border-bottom"
                    }`}
                >
                  <div className="category-collapse-holder">
                    {childItems[item_child.category]?.isOpen &&
                      this.callAbleChildRenders({
                        childItems,
                        item: item_child,
                      })}
                    <div className="category-collapse-trigger">
                      <div className="d-flex align-items-start">
                        <button
                          onClick={() => {
                            //this.onHandleClickSubCat({ enable: true, item: item_child });
                            // this.props.history.push({
                            //   pathname: `/product/${item_child.seo_label
                            //     .split("products/")[1]
                            //     .toLowerCase()}`,
                            //   state: {
                            //     id: item_child.id,
                            //     parent_category: "products",
                            //   },
                            // });

                            item.selectedId = item_child.id;

                            this.setState(
                              {
                                productsList: [],
                                currentPageIndex: 1,
                                selectedItem: item,
                                isLoadingProductItems: true,
                              },
                              () => {
                                this.fetchMiddleItems({
                                  name: "products",
                                  id: item_child.id,
                                  page: this.state.currentPageIndex
                                });
                              }
                            );

                          }}
                          className={`text-dark ff-base p-0 text-left font-weight-normal btn btn-link mt-1 nav-link-decoration-none ${this.state.selectedItem?.selectedId == item_child.id ? "btn-active" : ""}`}
                        >
                          {item_child.category}
                        </button>
                        {item_child?.children_count > 0 && (
                          <button
                            type="button"
                            id="cat_toggler1235"
                            onClick={() => {
                              this.getAllSubCategories(item_child);
                            }}
                            className="ml-auto btn btn-transparent btn-sm"
                          >
                            {!childItems[item_child.category]?.isOpen ? (
                              <i className="icon-plus-cat _sm" />
                            ) : (
                              <i className="icon-minus-cat _sm" />
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))
            : null}
        </ul>
      </Collapse>
    );
  };

  handlePriceFilter = (price) => {
    this.setState(
      {
        productsList: [],
        currentPageIndex: 1,
        isLoadingProductItems: true,        
        priceSelected: price,
      },
      () => {
        this.props.fetch_filtered_products(this.getProductsRequestQueryPayload());
      });
}

  render() {
    let {
      products_categories,
      productsList,
      childItems,
      isLoadingCategories,
      priceSelected
    } = this.state;
    let { isLoader } = this.state;
    let categoryListing = [];
    if (
      products_categories &&
      Array.isArray(products_categories) &&
      products_categories?.length
    ) {
      categoryListing = products_categories[0].children;
    }

    return (
      <div ref={(ref) => (this.scrollRef = ref)}>
        {/* {this.checkIsLoader(isLoader)} */}
        <AppHeader isEnableCover={false} />
        <CollageItems content_type="products" />

        {/* Main Content Starts */}
        <section
          id="scrollIntoViewTop"
          className="py-4 fs-14 mx-auto"
          style={{ maxWidth: "1000px" }}
        >
          <Container>
            <Row>
              <Col xs="auto" className="flex-180">
                <div className="mb-5">
                  <h2 className="fs-36 text-tertiary"> {"Categories"}</h2>
                  <hr className="bg-tertiary mb-2" />
                  <div>
                    <ul className="list-unstyled">
                      {
                        this.state.isLoadingCategories &&
                        <div className="text-center py-3 spinner">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </div>
                      }
                      {categoryListing &&
                        Array.isArray(categoryListing) &&
                        categoryListing?.length
                        ?
                        categoryListing
                          .sort((a, b) => {
                            if (a.category < b.category) {
                              return -1;
                            }
                            if (a.category > b.category) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((item, item_index) => (
                            <li
                              className={`py-2 ${categoryListing.length == item_index + 1
                                ? ""
                                : "border-bottom"
                                }`}
                            >
                              <div className="category-collapse-holder">
                                {childItems[item.category]?.isOpen &&
                                  this.callAbleChildRenders({
                                    childItems,
                                    item,
                                  })}
                                <div className="category-collapse-trigger">
                                  <div className="d-flex align-items-start">
                                    <Link
                                      to={{
                                        pathname: item.seo_label ? `/product/${item.seo_label
                                          .split("product-reviews/")[1]
                                          .toLowerCase()}`: `/product/${item.id}`,
                                        state: {
                                          id: item.id,
                                          parent_category: "products",
                                          child_category: item?.category
                                        },
                                      }}
                                      className="p-0 text-darker font-weight-bold ff-base text-left mr-2 mt-1 nav-link-decoration-none"
                                      >
                                      {item.category}
                                    </Link>
                                    <button
                                      type="button"
                                      id="cat_toggler1235"
                                      onClick={() => {
                                        this.getAllSubCategories(item);
                                      }}
                                      className="ml-auto btn btn-transparent btn-sm"
                                    >
                                      {!childItems[item.category]?.isOpen ? (
                                        <i className="icon-plus-cat _sm" />
                                      ) : (
                                        <i className="icon-minus-cat _sm" />
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))
                        : !isLoadingCategories &&
                        <h5>{'No Result Found'}</h5>}
                    </ul>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="font-weight-bold fs-16 mb-2 text-dark">
                    Star Rating
                  </div>
                  <FormGroup className="mb-1" check>
                    <Label
                      className="text-dark ff-alt font-weight-normal"
                      check
                    >
                      <Input
                        type="radio"
                        name="filterByStarRating"
                        className="star-filter-input-checkbox"
                        onClick={() => {
                          this.setState({ productsList: [] }, () =>
                            this.handleStarFilter(5)
                          );
                        }}
                      />{" "}
                      <div className="d-flex align-items-center">
                        <img
                          className="mr-1"
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star_6.png"
                          alt="5 Rating"
                        />
                        <span>only</span>
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-1" check>
                    <Label
                      className="text-dark ff-alt font-weight-normal"
                      check
                    >
                      <Input
                        type="radio"
                        name="filterByStarRating"
                        className="star-filter-input-checkbox"
                        onClick={() => this.handleStarFilter(4)}
                      />{" "}
                      <div className="d-flex align-items-center">
                        <img
                          className="mr-1"
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star_5.png"
                          alt="4 Rating"
                        />
                        <span>& up</span>
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-1" check>
                    <Label
                      className="text-dark ff-alt font-weight-normal"
                      check
                    >
                      <Input
                        type="radio"
                        name="filterByStarRating"
                        className="star-filter-input-checkbox"
                        onClick={() => this.handleStarFilter(3)}
                      />{" "}
                      <div className="d-flex align-items-center">
                        <img
                          className="mr-1"
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star_4.png"
                          alt="3 Rating"
                        />
                        <span>& up</span>
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-1" check>
                    <Label
                      className="text-dark ff-alt font-weight-normal"
                      check
                    >
                      <Input
                        type="radio"
                        name="filterByStarRating"
                        className="star-filter-input-checkbox"
                        onClick={() => this.handleStarFilter(2)}
                      />{" "}
                      <div className="d-flex align-items-center">
                        <img
                          className="mr-1"
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star_3.png"
                          alt="2 Rating"
                        />
                        <span>& up</span>
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-1" check>
                    <Label
                      className="text-dark ff-alt font-weight-normal"
                      check
                    >
                      <Input
                        type="radio"
                        name="filterByStarRating"
                        className="star-filter-input-checkbox"
                        onClick={() => this.handleStarFilter(1)}
                      />{" "}
                      <div className="d-flex align-items-center">
                        <img
                          className="mr-1"
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star_2.png"
                          alt="1 Rating"
                        />
                        <span>& up</span>
                      </div>
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-1" check>
                    <Label
                      className="text-dark ff-alt font-weight-normal"
                      check
                    >
                      <Input
                        type="radio"
                        name="filterByStarRating"
                        className="star-filter-input-checkbox"
                        onClick={() => this.handleStarFilter(0)}
                      />{" "}
                      <div className="d-flex align-items-center">
                        <img
                          className="mr-1"
                          src="https://userdatawikireviews.s3.amazonaws.com/images/star_1.png"
                          alt="0 Rating"
                        />
                        <span>& up</span>
                      </div>
                    </Label>
                  </FormGroup>
                  <hr className="bg-tertiary" />
                </div>
                
                <div className="mb-3">
                  <div className="font-weight-bold fs-16 mb-2 text-dark">Price</div>
                  <FormGroup className="mb-1" check>
                    <Label className="text-dark ff-alt font-weight-normal" check>
                      <Input type="radio" checked={priceSelected === 10} name="filterByPrice" onClick={() => this.handlePriceFilter(10)} />{' '}
                      $
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-1" check>
                    <Label className="text-dark ff-alt font-weight-normal" check>
                      <Input type="radio" checked={priceSelected === 100} name="filterByPrice" onClick={() => this.handlePriceFilter(100)} />{' '}
                      $$
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-1" check>
                    <Label className="text-dark ff-alt font-weight-normal" check>
                      <Input type="radio" checked={priceSelected === 1000} name="filterByPrice" onClick={() => this.handlePriceFilter(1000)} />{' '}
                      $$$
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-1" check>
                    <Label className="text-dark ff-alt font-weight-normal" check>
                      <Input type="radio" checked={priceSelected === 10000} name="filterByPrice" onClick={() => this.handlePriceFilter(10000)} />{' '}
                      $$$$
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-1" check>
                    <Label className="text-dark ff-alt font-weight-normal" check>
                      <Input type="radio" checked={priceSelected === 100000} name="filterByPrice" onClick={() => this.handlePriceFilter(100000)} />{' '}
                      $$$$$
                    </Label>
                  </FormGroup>
                  <hr className="bg-tertiary" />
                </div>

                <div>
                  <div className="bg-white text-center">
                    <a
                      rel="noopener noreferrer"
                      href="http://wikireviews.com/blog"
                      target="_blank"
                      className="text-reset"
                    >
                      <img
                        className="img-fluid blog-img"
                        src={
                          "https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_05.png"
                        }
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <Row xs="auto" sm={2} lg={3} form>
                    {productsList &&
                      Array.isArray(productsList) &&
                      productsList.length ? (
                      productsList.map((review, index) => (
                        <Col
                          id={`${productsList?.length == index + 1
                            ? "last-index"
                            : `current-index-${index}`
                            }`}
                          className="mb-3"
                        >
                          <div
                            className="bg-white text-dark p-2 hoverable-review"
                            style={{ height: '256px' }}
                          >
                            <div className="review-details">
                              <div className="text-center">
                                <img
                                  className="fixed-height-img is-120 w-100 object-fit-contain object-position-top"
                                  // src="https://userdatawikireviews.s3.amazonaws.com/media/inditex/fd070664945be0033eb7c8e1197a1797.jpg"
                                  src={
                                    review?.listing_profileimage &&
                                      review?.listing_profileimage[0] ?
                                      review?.listing_profileimage[0].thumbnail : require('../../assets/images/product-img2 (1).png')
                                  }
                                  onError={(e) => e.target.src = require('../../assets/images/product-img2.png')}
                                  alt={review.name}
                                />
                              </div>
                              <div className="mt-2">
                                <div className="font-weight-bold fs-14 lh-1">
                                  <div className="clamp-product-name">
                                    <a
                                      href={`/products/${review?.slug}/`}
                                      className="text-reset"
                                    >
                                      {/* {review?.name.length < 70
                                        ? review?.name
                                        : review?.name.substring(0, 70) + "..."} */}
                                      {review.name}
                                    </a>
                                  </div>
                                </div>
                                <div className="mb-1">
                                  <img
                                    src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${review
                                      ?.total_rating?.avg_rating &&
                                      review?.total_rating?.avg_rating[0]}`}
                                    alt=""
                                  />
                                </div>
                                <p className="fs-13">
                                  <a href="#" className="text-reset">
                                    {review?.total_rating?.total_review} review
                                    {review?.total_rating?.total_review > 1
                                      ? "s"
                                      : ""}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="fs-14 review-mask">
                              <div className="p-2">
                                <div className="text-center">
                                  <span className="comment-body-renderer clamp-line clamp-line-8">
                                    {/* {ReactHtmlParser(
                                      review?.review?.review.length < 150
                                        ? review?.review?.review
                                        : review?.review?.review.substring(
                                          0,
                                          150
                                        ) + "..."
                                    )} */}
                                    {ReactHtmlParser(detectlink(review?.review?.review))}
                                  </span>
                                </div>

                                <div className="text-center mt-3">
                                  <Link
                                    to={{
                                      pathname: `/${review?.content_type}/${review?.slug}`.toLowerCase(),
                                      state: { id: review?.id },
                                    }}
                                    className="text-reset font-weight-bold"
                                  >
                                    {"View More"}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))
                    ) : this.state.isLoadingProductItems ? (
                      <div className="mt-5 text-center">
                        <h2>{"Loading..."}</h2>
                      </div>
                    ) : (
                      <div className="mt-5 text-center">
                        <h2>{"No Record Found"}</h2>
                      </div>
                    )}
                  </Row>
                  {
                    (productsList &&
                      Array.isArray(productsList) &&
                      productsList.length && this.state.isLoadingProductItems) ?
                      <div className="mt-5 text-center">
                        <h2>{"Loading more products..."}</h2>
                      </div> : ''
                  }
                  <div style={{ height: "400px" }}>

                  </div>
                </div>
              </Col>
              <Col xs="auto" className="flex-350">
                <UserFeedsPanel category="products" />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

const mapState = (state) => ({
  collage_items: state.products_listing.products_collage_items,
  products_categories: state.products_listing.products_categories,
  products_sub_categories: state.products_listing.products_sub_categories,
  products_sub_categories1: state.products_listing.products_sub_categories1,
  products_sub_categories2: state.products_listing.products_sub_categories2,
  products_reviews: state.products_listing.products_reviews,
  isCategoryLoading: state.products_listing.isCategoryLoading,
  fetch_star_filter_data: state.products_listing.fetch_star_filter_data,
});

const mapProps = (dispatch) => ({
  fetch_products_newtrends: (data) => dispatch(fetch_products_newtrends(data)),
  fetch_products_categories: (data) =>
    dispatch(fetch_products_categories(data)),
  setCategoriesLoading: (data) =>
    dispatch(setCategoriesLoading(data)),
  fetch_products_sub_categories: (data) =>
    dispatch(fetch_products_sub_categories(data)),
  fetch_products_reviews_list: (data) =>
    dispatch(fetch_products_reviews_list(data)),
  fetch_filtered_products: (data) => dispatch(fetch_filtered_products(data)),
});

export default connect(mapState, mapProps)(Products);
