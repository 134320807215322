import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Button, FormGroup, Input, Label, Form } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { callApi } from '../../../utils/apiCaller';
import { toast } from "react-toastify";

class PlotSummary extends Component {
    constructor(props) {
        super(props)

        this.setItemData = false;
        this.state = {
            plotText: '',
            isEnable: false,
            togglePlot: false,
            dataItems: {},
            optionValue: 0,
            checkValue: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps?.additional_info && Object.keys(nextProps?.additional_info).length) {
            this.setState({ dataItems: nextProps?.additional_info['plot summary'] || [] });
        }

    }

    componentDidMount() {
        this.setItemData = true;
    }

    componentDidUpdate() {
        let nextProps = this.props;
        let { dataItems } = this.state;
        if (nextProps?.additional_info && Object.keys(dataItems).length !== Object.keys(dataItems).length) {
            this.setItemData = true;
            this.setState({ dataItems: nextProps?.additional_info });
        }
    }


    handleChange = (e) => {
        let text = e.target.value;
        if (text !== '') {
            this.setState({ plotText: text, isEnable: true })
        }
        this.setState({ plotText: text })
    }
    selectValue = (e) => {
        let value = e.target.value;
        if (value !== 0) {
            this.setState({ optionValue: value, isEnable: true })
        }
        this.setState({ optionValue: value })
    }

    submitPlot = async (e) => {
        e.preventDefault();
        let info = this.state.plotText;
        let name = this.state.optionValue;
        let business_owner_can_answer_flag = this.state.checkValue;
        let id = this.props.id;
        let data = {};

        if (info !== '' && name != 0 && id && !business_owner_can_answer_flag) {
            data = { info, name };

        }

        if (info !== '' && name != 0 && id && business_owner_can_answer_flag) {
            data = { business_owner_can_answer_flag, info, name };
        }

        try {
            let response = await callApi(`/api/movies/${id}/`, 'PUT', data);
            if (response?.code !== 500 && response?.code !== 400) {
                this.setState({ plotText: '', togglePlot: false, optionValue: 0, checkValue: false }, () => {
                    this.props.fetchByItemId({ movieId: this.props.id });
                })
            } else {
                if (response?.data?.option_value && Array.isArray(response?.data?.option_value) && response?.data?.option_value?.length) {
                
                    toast(`${response?.data?.option_value[0]}`, {
                        autoClose: 3500,
                        className: "black-background",
                        bodyClassName: "red-hunt",
                        progressClassName: "cc",
                    });
                }
            }


        } catch (err) {
            console.log(err)
        }


    }

    togglePlot = () => {
        if(!this.checkIfLoggedIn()){
            this.props.forceToggleLoginModal();
            return false;
        }
        let toggle = this.state.togglePlot;

        if (!toggle) {
            this.setState({ togglePlot: !this.state.togglePlot, plotText: '' })
        }
        else {
            this.setState({ togglePlot: false, plotText: '' })
        }
    }
    handleInputChange = (e) => {
        let check = this.state.checkValue;
        if (!check) {
            this.setState({ checkValue: true })
        }
        else {
            this.setState({ checkValue: false })
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile} = this.props;
        if(my_profile && my_profile.id && authId && (authId == my_profile.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        let { isEnable, togglePlot, dataItems, optionValue, plotText, checkValue } = this.state;
        let plotList = dataItems;

        // if (Object.keys(this?.state?.dataItems).length) {

        //     plotList = this.state.dataItems.additional_info['plot summary'];

        // }
     

        return (
            <div className="bg-movie-dark text-white mb-3">
                <CollapseBasic title="Plot Summary" containerClass="bg-transparent type-white" bodyClass="mt-2 text-white" isOpen={true}>
                    <ul className="list-unstyled">
                        {plotList && Array.isArray(plotList) && plotList.length > 0 ?
                            plotList.map((item, index) =>
                                <li className="mb-1" key={index}>
                                    <div className="d-flex mx-n2">
                                        <div className="px-2">
                                            <div className="ff-base wb-break-word">
                                                {item.value}
                                            </div>
                                            <div className="ff-base">
                                                {'by'} &nbsp;
                                                <Link className="text-reset font-weight-bold ff-base"
                                                    to={{ pathname: `/people/${item?.userInfo?.username}` }}
                                                >{item?.userInfo?.first_name} {item?.userInfo?.last_name}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                            :
                            "No Record Found"
                        }
                    </ul>

                    <div className="text-right mb-2">
                        <Button color="link" className="p-0 text-white" onClick={this.togglePlot}>
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                            {'Add another'}
                        </Button>
                    </div>
                    {togglePlot ?
                        <div className="bg-white p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">{'Add another'}</h5>
                            <Form onSubmit={this.submitPlot}>
                                <FormGroup>
                                    <Input className="primary" bsSize="sm" type="select" onChange={this.selectValue}>
                                        <option value="0">-----</option>
                                        <option value="17235">plot summary</option>
                                    </Input>

                                </FormGroup>
                                <FormGroup className="mb-3" check>
                                    <Label className="text-dark font-weight-normal" check>
                                        <Input type="checkbox" checked={checkValue} onChange={this.handleInputChange} />{' '}
                                        {'Business owner can answer flag'}
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Input className="primary ph-primary font-weight-bold text-dark" bsSize="sm" type="textarea" rows="4" placeholder="Enter additional information" value={this.state.plotText} onChange={this.handleChange} />
                                </FormGroup>

                                <div className="text-right mt-3">
                                    <Button disabled={plotText === '' || optionValue == 0} className="mw" color="primary" size="sm">Submit</Button>
                                    <Button className="mw" color="primary" size="sm" onClick={this.togglePlot}>Cancel</Button>
                                </div>
                            </Form>
                        </div>
                        :
                        ""
                    }

                </CollapseBasic>
            </div>

        )
    }
}

const mapState = (state) => ({
    my_profile: state.user.my_profile,
});

const mapProps = (dispatch) => ({


});
export default withRouter(connect(mapState, mapProps)(PlotSummary));