import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from "react";
import { Button, Col, Input, Label, Row } from 'reactstrap';

export default class DisputeReview extends Component {
    render() {
        return (
            <div className="border border-secondary p-4 mb-3" hidden>
                <div className="mb-3">
                    <Label for="disputed_review" className="text-primary-dark fs-14 font-weight-bold">
                        {'Provide evidence and explanation for disputing the review in question'}
                    </Label>
                    <Input type="textarea" className="primary" name="disputed_review" id="disputed_review" placeholder="Provide explanation..." />
                </div>
                <Row className="mb-3" form>
                    <Col xs="auto" className="mb-3">
                        <div className="d-flex pr-3 pt-3">
                            <div>
                                <div className="gallery-media">
                                    {/* <img src={require('../../assets/images/cvs-logo.jpg')} alt="" /> */}
                                </div>
                            </div>
                            <div className="mx-n3 mt-n3">
                                <Button
                                    color="dark"
                                    size="sm"
                                    title="Remove Media">
                                    <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs="auto" className="mb-3">
                        <div className="d-flex pr-3 pt-3">
                            <div>
                                <div className="gallery-media">
                                    {/* <img src={require('../../assets/images/cvs-logo.jpg')} alt="" /> */}
                                </div>
                            </div>
                            <div className="mx-n3 mt-n3">
                                <Button
                                    color="dark"
                                    size="sm"
                                    title="Remove Media">
                                    <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs="auto" className="mb-3">
                        <div className="d-flex pr-3 pt-3">
                            <div>
                                <div className="gallery-media">
                                    {/* <img src={require('../../assets/images/cvs-logo.jpg')} alt="" /> */}
                                </div>
                            </div>
                            <div className="mx-n3 mt-n3">
                                <Button
                                    color="dark"
                                    size="sm"
                                    title="Remove Media">
                                    <FontAwesomeIcon icon="minus" />{" "}
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="d-flex mx-n2">
                    <div className="px-2">
                        <Button color="white" size="sm">
                            <FontAwesomeIcon icon="camera" />
                        </Button>
                    </div>
                    <div className="px-2 ml-auto">
                        <Button color="primary" size="sm" className="mw">Submit</Button>
                        <Button color="light" size="sm" className="mw">Cancel</Button>
                    </div>
                </div>
            </div>

        )
    }
}
