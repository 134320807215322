/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Input,
  UncontrolledAlert,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Form
} from "reactstrap";

import classnames from "classnames";
import StarsRating from "stars-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollapseBasic from "../../atoms/collapse";
import { connect } from "react-redux";
import {
  get_contributions,
  get_added_contributions,
  delete_contribution,
  delete_media,
} from "../../../actions/contributions";
import { callApi } from "../../../utils/apiCaller";
import { Link } from "react-router-dom";
import DeleteBtn from "../../atoms/deleteBtn";
import Carousel from "react-multi-carousel";
import { property } from "lodash";
import SearchBar from "../../atoms/searchBar";
import { pushUniqueObjectsInArray } from "../../../utils/utilities";

class Contributions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      actor: "me",
      contributions: null,
      prevY: 0,
      loading: false,
      confirmDeleteModal: false,
      deleteItem: {
        type: "",
        id: 0,
        parent_id: 0,
      },
      secondCarouselMedia: [],
      viewMediaModalToggle: false,
      activeTab: "1",
      contributionType:"",
      notifications: null,
      enableLoading: false,
      searchedValue:""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.contributions_data &&
      Object.keys(nextProps.contributions_data).length > 0
    ) {
      this.setState({
        contributions: nextProps.contributions_data,
        loading: false
      },()=>{
        let displaySet = false;
        const displayArr = [];
        var currval = this.state.searchedValue
        var searchVal = this.state.searchedValue.toLowerCase();
        var filterItems = document.querySelectorAll('[' + 'filter-value' + ']');
        for (var i = 0; i < filterItems.length; i++) {
          if (!displaySet) {
            displayArr.push(this.getDisplayType(filterItems[i]));
          }
          var textcontent = filterItems[i].textContent;
          var replacedval = "<strong style=background-color:yellow>" + currval + "</strong>"
          var finalval = textcontent.replace(currval, replacedval);
          filterItems[i].innerHTML = finalval;
          // filterItems[i].style.display = 'none';
          if (filterItems[i].getAttribute('filter-value').toUpperCase().indexOf(searchVal.toUpperCase()) >= 0) {
            filterItems[i].style.display = displayArr[i];
          }
        }
        displaySet = true;
      });

     
    }

    if("loadingContributions" in nextProps){
      this.setState({
          enableLoading: nextProps.loadingContributions
      });
  }
  }

  handleOnDeleteItem = () => {
    if (
      this.state.deleteItem.id &&
      this.state.deleteItem.type === "contribution"
    ) {
      this.props.delete_contribution(this.state.deleteItem.id);
      let results = [...this.state.contributions.results];
      let data = {
        ...this.state.contributions,
        count: this.state.contributions.count - 1,
        results: results.filter((item) => item.id !== this.state.deleteItem.id),
      };

      this.setState({
        contributions: data,
        confirmDeleteModal: false,
        deleteItem: {
          type: "",
          id: 0,
          parent_id: 0,
        },
      });
    } else if (
      this.state.deleteItem.id &&
      this.state.deleteItem.parent_id &&
      this.state.deleteItem.type === "attachment"
    ) {
      let data = [];
      data.push(this.state.deleteItem.id);
      this.props.delete_media(data);
      let results = [...this.state.contributions.results];
      results = results.filter((item) => {
        if (item.id !== this.state.deleteItem.parent_id) {
          return item;
        } else {
          let attachments = item.target.attachments;
          attachments = attachments.filter(
            (media) => media.id !== this.state.deleteItem.id
          );
          item.target.attachments = [...attachments];
          if (item?.verb?.toLowerCase() === "added media for" && item?.target?.attachments?.length === 0) {
            setTimeout(() => {
              this.setState({
                deleteItem: {
                  type: "contribution",
                  id: item.id,
                }
              }, () => {
                this.handleOnDeleteItem()
                this.setState({
                  deleteItem: {
                    type: "",
                    id: 0,
                    parent_id: 0,
                  },
                });
              })
            }, 500)
          }
          return item;
        }
      });
      let newData = {
        ...this.state.contributions,
        results: [...results],
      };
      this.setState({
        contributions: newData,
        confirmDeleteModal: false,
        deleteItem: {
          type: "",
          id: 0,
          parent_id: 0,
        },
      });
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.props.get_contributions(this.state.actor, this.state.page);
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }

  getContributions(next) {
    this.setState({ loading: true, enableLoading: true });
    callApi(`${next}`, "GET").then((res) => {
      if (res && Object.keys(res).length > 0) {
        let data = {
          ...res,
          results: pushUniqueObjectsInArray(this.state.contributions.results, res.results, 'id'),
        };
        this.setState({ contributions: data, loading: false , enableLoading: false});
      }
    });
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    let fetch = false;
    if (this.state.prevY === 0 && Math.abs(this.state.prevY - y) > 200) {
      fetch = true;
    } else if (this.state.prevY >= y) {
      fetch = true;
    }

    if (fetch) {
      if (this.state.contributions && this.state.contributions.next) {
        this.getContributions(this.state.contributions.next.split("com")[1]);
      }
    }
    this.setState({ prevY: y });
  }

  renderVerb = (item) => {
    let verbData;
    if (item.verb.includes("Commented on status")) {
      verbData = (
        <div className="z-1000">
          {item.verb}{" "}
          <Link
            to={`/people/${item.actor.username}`}
            className="font-weight-bold text-darker"
          >
            {item.target && item.target.name
              ? item.target.name
              : `${item.actor.first_name} ${item.actor.last_name}'s`}
          </Link>{" "}
          Post
        </div>
      );
    } else if (item.verb.includes("Updated his profile")) {
      verbData = <div>Updated his profile picture</div>;
    } else if (item.target && item.target.name) {
      verbData = (
        <div className="z-1000">
          {item.verb}{" "}
          <Link
            to={{
              pathname: `/${item.target.category.toLowerCase()}/${item.target.slug ? item.target.slug : item?.target?.id}`,
              state: { id: item.target.id },
            }}
            className="font-weight-bold text-darker"
            filter-value={item.target.name}
          >
            {item.target.name}
          </Link>
        </div>
      );
    } else {
      verbData = <div>{item.verb}</div>;
    }
    return verbData;
  };

  openSecondCarousal = (item, index, type, subType = "") => {
    let carouselMedia = [],
      newArray = [];

    if (type === "multiple") {
      carouselMedia = [...item.target.attachments];
      const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
      const array2 =
        index !== carouselMedia.length - 1
          ? carouselMedia.filter((item, i) => i > index)
          : [];
      newArray = [carouselMedia[index], ...array2, ...array1];
    } else {
      const media = {
        id: item?.id,
        caption: "",
        label: "",
        type: "image",
        url: item?.target[subType],
        upload_date: item?.time_stamp,
        user: {
          current_profile_pic: item?.actor?.current_profile_pic?.url,
          user: {
            first_name: item?.actor?.first_name,
          },
        },
      };
      carouselMedia.push(media);
      newArray = carouselMedia;
    }

    this.setState({
      secondCarouselMedia: newArray,
      viewMediaModalToggle: true,
    });
  };

  handleToggle = (tab,contributionType) => {
    let { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab,contributionType:contributionType }, () => {
        if (tab === "1") {
    this.setState({ loading: true });
          this.props.get_contributions("me", 1,"");
        }else{
    this.setState({ loading: true });
          this.props.get_contributions(this.state.actor, +tab,contributionType);
        }             
        
      });
    }
  };

  renderNavLinks = ({ current, contributionType, count }) => {
    let { activeTab } = this.state;
      
    return (
      <NavItem>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === current })}
          onClick={() => {
            this.handleToggle(current,contributionType);
            this.setState({
              // searchContribution:''
            })
          }}
        >
          {`${contributionType}`}
        </NavLink>
        {contributionType !== "Find Friends" ? <span className="count">{count}</span> : ""}
      </NavItem>
    );
  };

  reversedStatus = (media) => {
    return (
      <>
        <div id={`Media-${media.id}`} className="color-reversed reversed-status-cursor-pointer">
          {"[Reversed]"}
        </div>
        <UncontrolledPopover
          placement="top"
          trigger="hover"
          target={`Media-${media.id}`}
          className="w-100 px-0"
        >
          <PopoverHeader className="px-0 text-center reversed-status-header">
            Reversed by
          </PopoverHeader>
          <PopoverBody className="attachment-media-star-popover-body px-0">
            <div className="d-flex justify-content-center">
              <img src="https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png" className="rounded-circle w-25 text-center" />
            </div>
            <div className="font-weight-bold">Andrew Symonds</div>
            <div className="font-weight-bold text-secondary mt-3">view full profile</div>
            <div className="font-weight-bold text-primary mb-2">send john a message</div>
          </PopoverBody>
        </UncontrolledPopover>
      </>
    )
  }

 deleteButtonEnable = (type)=>{
    const deletableList = ["added media for","added additional info for","added additional info for","added new project","checked in to","asked a question about","reported a problem","created a new discussion","commented on a discussion","recommends","commented on a review on","added","paymentoptions"]

    if(deletableList.includes(type?.toLowerCase())){
      return true
    }else{
      return false
    }
  }

  renderData = () => {
    const { notPeopleTab = true } = this.props;
    const { contributions } = this.state;

    const adIndex = (contributions && contributions.results && contributions.results?.length > 2) ? ((contributions.results?.length > 7) ? 6 : 3) : 0

    return (
      <div>
        {contributions && contributions.count > 0 ? (
          contributions.results.map((item, index) => {

            // Check whether the contribution contains some data to display- Managing Collapse functionality on this basis
            let haveData = false;
            for (let property in item?.contribution_details) {
              if (property.length > 0) {
                haveData = true;
                break;
              }
            }
            if (
              item.verb.includes("Updated his profile") ||
              item.verb.includes("Updated his cover") ||
              item.verb.includes("Updated her profile") ||
              item.verb.includes("Updated her cover")
            ) {
              haveData = true;
            }
            // Managing verb "Followed me" - No data will be displayed for this verb
            if (item.verb.includes("Followed me")) {
              haveData = false;
            }

            // Display Block
            return (
              <>
                {/* Added space for Google AdSense Banner */}
                {/* {(index !== 0 && index % adIndex === 0) &&
                  <div className="bg-white p-3 position-relative m-3 d-flex justify-content-center align-items-center border border-secondary">
                    <div className="p-3 border border-secondary w-100 text-center"><div className="text-secondary">Google AdSense (Horizontal Banner)</div></div>
                  </div>
                } */}

                {/* Contribution-wise Render Data */}
                <div
                  className="bg-white p-3 position-relative mb-3"
                  key={item.id}
                >
                  {/* Header Line- Display Verb and Time Stamp */}
                  <div className="">
                    {this.renderVerb(item)}
                  </div>
                  <small
                    className="contribution-time-stamp text-lighter fs-14"
                  >
                    {item.time_stamp}
                  </small>

                  {/* Display Collapse Content */}
                  {haveData ? (
                    <div className="mt-n4">
                      <CollapseBasic
                        containerClass="bg-transparent type-tertiary"
                        bodyClass="text-dark fs-14"
                        isOpen={true}
                        size="sm"
                        noHr={true}
                        noPadding={true}
                        fromContribution={true}
                      >
                        {item.target &&
                          item.target.attachments &&
                          item.target.attachments.length > 0 && (
                            <div className="mb-3">
                              <Row xs={2} sm={3} md={4} lg={5} form>
                                {item.target.attachments.map((media, i) => (
                                  <Col className="m-2" key={media.id}>
                                    <div
                                      className="d-flex pr-3"
                                      role="button"
                                    >
                                      <div
                                        onClick={() =>
                                          this.openSecondCarousal(
                                            item,
                                            i,
                                            "multiple"
                                          )
                                        }
                                      >
                                        <img
                                          className="img-fluid img-thumbnail"
                                          src={media.thumbnail}
                                          alt="Uploaded media"
                                        />
                                      </div>
                                      <div className="mx-n3 mt-2">
                                        {/* <Button
                                          color="delete-media"
                                          size="sm"
                                          title="Remove Media"
                                          onClick={() =>
                                            this.setState({
                                              deleteItem: {
                                                type: "attachment",
                                                id: media.id,
                                                parent_id: item.id,
                                              },
                                              confirmDeleteModal: true,
                                            })
                                          }
                                        >
                                          <FontAwesomeIcon icon="trash-alt" />{" "}
                                        </Button> */}
                                      </div>

                                    </div>
                                    {/* Replace true with media.star_rating */}
                                    {false &&
                                      <>
                                        <div id={`contribution-star-hover-${media.id}`}>
                                          <StarsRating
                                            className="attachment-media-star-rating"
                                            count={5}
                                            // Replace value with media.star_rating
                                            value={3}
                                            size={20}
                                            color1={"#999"}
                                            color2={"#333"}
                                            edit={false}
                                          />
                                        </div>
                                        <UncontrolledPopover
                                          placement="top"
                                          trigger="hover"
                                          target={`contribution-star-hover-${media.id}`}
                                        >
                                          <PopoverBody className="attachment-media-star-popover-body pl-4 pr-4">
                                            <StarsRating
                                              count={5}
                                              // Replace value with media.star_rating
                                              value={3}
                                              size={26}
                                              color1={"#D7D5EA"}
                                              color2={"#325271"}
                                              edit={false}
                                            />
                                            <div className="text-center text-dark font-weight-bold">Average <br /> Star Rating</div>
                                             {/* Replace 12 Reviews with media.average_reviews */}
                                            <small className="text-dark">12 Reviews</small>
                                          </PopoverBody>
                                        </UncontrolledPopover>
                                      </>
                                    }

                                    {/* Replace true with media.status */}
                                    {false &&
                                      <>
                                        {/* Replace true with media.status === "approved" */}
                                        {true && <div className="attachment-media-status color-approved">Approved!</div>}
                                        {/* Replace false with media.status === "pending" */}
                                        {false && <div className="attachment-media-status color-pending">{"[Pending]"}</div>}
                                        {/* Replace false with media.status === "rejected" */}
                                        {false && <div className="attachment-media-status">
                                          {this.reversedStatus(media)}
                                        </div>}
                                      </>
                                    }
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          )}

                        {item.target &&
                          item.target.profile_pic &&
                          (item.verb.includes("Updated his profile") ||
                            item.verb.includes("Updated her profile")) && (
                            <div className="mb-3">
                              <Row xs={2} sm={3} md={4} lg={5} form>
                                <Col className="mb-3">
                                  <div className="d-flex" role="button">
                                    <div
                                      onClick={() =>
                                        this.openSecondCarousal(
                                          item,
                                          0,
                                          "single",
                                          "profile_pic"
                                        )
                                      }
                                    >
                                      <img
                                        className="img-fluid img-thumbnail"
                                        src={item.target.profile_pic}
                                        alt="Profile Pic"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}

                        {(!item.target ||
                          !item.target.profile_pic) &&
                          (item.verb.includes("Updated his profile") ||
                            item.verb.includes("Updated her profile")) &&
                          <div className="mb-3 mt-3 ff-alt">
                            Profile picture was removed.
                          </div>
                        }

                        {item.target &&
                          item.target.cover_pic &&
                          (item.verb.includes("Updated his cover") ||
                            item.verb.includes("Updated her cover")) && (
                            <div className="mb-3">
                              <Row xs={2} sm={3} md={4} lg={5} form>
                                <Col className="mb-3">
                                  <div className="d-flex" role="button">
                                    <div
                                      onClick={() =>
                                        this.openSecondCarousal(
                                          item,
                                          0,
                                          "single",
                                          "cover_pic"
                                        )
                                      }
                                    >
                                      <img
                                        className="img-fluid img-thumbnail"
                                        src={item.target.cover_pic}
                                        alt="Profile Pic"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}

                        {item.contribution_details.manufacturer && (
                          <div className="border-bottom mb-2 py-1">
                            <Row>
                              <Col xs={2}>
                                <div className="text-dark font-weight-bold">
                                  Manufacturer:
                                </div>
                              </Col>
                              <Col xs={10}>
                                <div className="text-dark ff-alt" >
                                  <span filter-value={item.contribution_details.manufacturer}>{item.contribution_details.manufacturer}</span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {item.contribution_details.model_number && (
                          <div className="border-bottom mb-2 py-1">
                            <Row>
                              <Col xs={2}>
                                <div className="text-dark font-weight-bold">
                                  Model number:
                                </div>
                              </Col>
                              <Col xs={10}>
                                <div className="text-dark ff-alt">
                                  <span filter-value={item.contribution_details.model_number}>{item.contribution_details.model_number}</span>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {item.contribution_details.website &&
                          item.contribution_details.website[0] &&
                          (item.contribution_details.website[0].Main ||
                            item.contribution_details.website[0].null) && (
                            <div className="border-bottom mb-2 py-1">
                              <Row>
                                <Col xs={2}>
                                  <div className="text-dark font-weight-bold">
                                    Website:
                                  </div>
                                </Col>
                                <Col xs={10}>
                                  <div className="text-dark ff-alt">
                                    <a
                                      href={
                                        item.contribution_details.website[0]
                                          .Main
                                          ? item.contribution_details
                                            .website[0].Main
                                          : item.contribution_details
                                            .website[0].null
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      filter-value={item.contribution_details.website[0]
                                        .Main
                                        ? item.contribution_details
                                          .website[0].Main
                                        : item.contribution_details
                                          .website[0].null}
                                    >
                                      {item.contribution_details.website[0]
                                        .Main
                                        ? item.contribution_details
                                          .website[0].Main
                                        : item.contribution_details
                                          .website[0].null}
                                    </a>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        {item.contribution_details.phone &&
                          item.contribution_details.phone[0] &&
                          item.contribution_details.phone[0].Tel && (
                            <div className="border-bottom mb-2 py-1">
                              <Row>
                                <Col xs={2}>
                                  <div className="text-dark font-weight-bold">
                                    Phone:
                                  </div>
                                </Col>
                                <Col xs={10}>
                                  <div className="text-dark ff-alt">
                                    (
                                    {item.contribution_details.phone[0].Tel.slice(
                                      0,
                                      3
                                    )}
                                    ){" "}
                                    {item.contribution_details.phone[0].Tel.slice(
                                      3,
                                      6
                                    )}
                                    -
                                    {item.contribution_details.phone[0].Tel.slice(
                                      6
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}

                        {item.contribution_details.address &&
                          item.contribution_details.address[0] && (
                            <div className="border-bottom mb-2 py-1">
                              <Row>
                                <Col xs={2}>
                                  <div className="text-dark font-weight-bold">
                                    Address:
                                  </div>
                                </Col>
                                <Col xs={10}>
                                  <div className="text-dark ff-alt">
                                    <span filter-value={item.contribution_details.address[0]}>{item.contribution_details.address[0]}</span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        {item.action &&
                          item.action.type &&
                          item.action.type === "question" &&
                          item.action.body && (
                            <div className="border-bottom mb-2 py-1">
                              <Row>
                                <Col xs={2}>
                                  <div className="text-dark font-weight-bold">
                                    Question Asked:
                                  </div>
                                </Col>
                                <Col xs={10}>
                                  <div className="text-dark ff-alt">
                                    {item.action.body}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        {item.contribution_details.additional_info &&
                          item.contribution_details.additional_info
                            .duration &&
                          item.contribution_details.additional_info
                            .duration[0] && (
                            <div className="border-bottom mb-2 py-1">
                              <Row>
                                <Col xs={2}>
                                  <div className="text-dark font-weight-bold">
                                    Duration:
                                  </div>
                                </Col>
                                <Col xs={10}>
                                  <div className="text-dark ff-alt">
                                    {
                                      item.contribution_details
                                        .additional_info.duration[0].value
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        {item.contribution_details.additional_info &&
                          item.contribution_details.additional_info
                            .storyline &&
                          item.contribution_details.additional_info
                            .storyline[0] && (
                            <div className="border-bottom mb-2 py-1">
                              <Row>
                                <Col xs={2}>
                                  <div className="text-dark font-weight-bold">
                                    Storyline:
                                  </div>
                                </Col>
                                <Col xs={10}>
                                  <div className="text-dark ff-alt">
                                    {
                                      item.contribution_details
                                        .additional_info.storyline[0].value
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        {/* Added a filed to display any tip provided in the contribution */}
                        {/* Alter the item path based on the data received */}
                        {item.contribution_details.additional_info &&
                          item.contribution_details.additional_info
                            .tip && (
                            <div className="border-bottom mb-2 py-1">
                              <Row>
                                <Col xs={2}>
                                  <div className="text-dark font-weight-bold">
                                    Tip:
                                  </div>
                                </Col>
                                <Col xs={10}>
                                  <div className="text-dark ff-alt">
                                    {
                                      item.contribution_details
                                        .additional_info.tip
                                    }
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                      </CollapseBasic>
                    </div>
                  ) : (
                    <div className="">
                      {/* <CollapseBasic
                            containerClass="bg-transparent"
                            bodyClass="text-dark"
                            isOpen={false}
                            size="sm"
                          >
                            <p>No data present</p>
                          </CollapseBasic> */}
                    </div>
                  )}

                  {/* {item.verb != "Added new business" &&
                    item.verb != "Added new product" &&
                    item.verb != "Added new movie" &&
                    item.verb != "Updated his cover photo" &&
                    item.verb != "Updated her cover photo" &&
                    item.verb != "Updated her profile picture" &&
                    item.verb != "Updated his profile picture" ? (
                    <div className="text-right">
                      {notPeopleTab && (
                        <DeleteBtn
                          color="gold"
                          hidden={this.deleteButtonEnable(item.verb)}
                          onClick={() =>
                            this.setState({
                              deleteItem: {
                                type: "contribution",
                                id: item.id,
                              },
                              confirmDeleteModal: true,
                            })
                          }
                        />
                      )}
                    </div>
                  ) : (
                    ""
                  )} */}
                  {this.deleteButtonEnable(item.verb) ? (
                    <div className="text-right">
                      {notPeopleTab && (
                        <DeleteBtn
                          color="gold"                         
                          onClick={() =>
                            this.setState({
                              deleteItem: {
                                type: "contribution",
                                id: item.id,
                              },
                              confirmDeleteModal: true,
                            })
                          }
                        />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            );
          })
        ) : (
            this.state.enableLoading? 
            <div className="h2 bg-white text-secondary-dark p-3" hidden={contributions}>
            Loading...
          </div>:
          <div className="bg-white p-3">
            <h2 className="text-secondary-dark">
              No contributions to display
            </h2>
          </div>
        )}
      </div>
    )
  }

  handleSearchSubmit=(value)=>{
    let contributionTypeTemp
    if(this.state.contributionType==="all") contributionTypeTemp = "";
    else contributionTypeTemp = this.state.contributionType;

    this.setState({ 
      loading: true , 
      searchResultsEnabled: value.length? true: false, 
      contributions: [],
      searchedValue:value
    }, ()=>{
      this.props.get_contributions(this.state.actor, this.state.activeTab,contributionTypeTemp,value);
    });
  }




 
 
  
   getDisplayType=(element)=> {
    var elementStyle = element.currentStyle || window.getComputedStyle(element, "");
    return elementStyle.display;
  }
  

  render() {
    const { activeTab, contributions } = this.state;

    // Static Data for showing Notification Section
    // MUST BE removed when data is fetched via API i.e. when API is ready.
    const notifications = [
      {
        type: "images",
        target: "Harry's Deli",
        future_help: "doing this and that"
      },
      {
        type: "information",
        target: "AbC Reports",
        future_help: "submitting more reports"
      },
      {
        type: "images",
        target: "Harry's Deli",
        future_help: "doing this and that"
      }
    ]

    const loadingCSS = {
      height: "100px",
      margin: "30px",
    };

    const responsive = {
      videoModal: {
        mobile: {
          breakpoint: { max: 9999999, min: 0 },
          items: 1,
        },
      },
      viewGalleryImages: {
        desktop: {
          breakpoint: { max: 9999999, min: 1200 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 1199, min: 576 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 575, min: 0 },
          items: 1,
        },
      },
    };

    return (
      <>
        <div>
          <Nav
            className="mb-3 border-0 m-0 with-counts w-100 d-flex justify-content-between"
            tabs
          >
            <div className="d-flex">
              {/* {this.renderNavLinks({
                current: "1",
                contributionType: "all",
                count: 5,
              })}
              {this.renderNavLinks({
                current: "2",
                contributionType: "added",
                count: 1,
              })}
              {this.renderNavLinks({
                current: "3",
                contributionType: "rated",
                count: 1,
              })}
              {this.renderNavLinks({
                current: "4",
                contributionType: "improved",
                count: 1,
              })}
              {this.renderNavLinks({
                current: "5",
                contributionType: "patrolled",
                count: 1,
              })}
              {this.renderNavLinks({
                current: "6",
                contributionType: "moderated",
                count: 1,
              })}
              {this.renderNavLinks({
                current: "7",
                contributionType: "voted",
                count: 96,
              })} */}
            </div>

            {/* Render Search Field */}
            <NavItem>
              <div className="px-1 ml-auto">
                <SearchBar
                handleSearchSubmit={this.handleSearchSubmit}
                searchResultsEnabled={this.state.searchResultsEnabled}
                getDisplayType={this.getDisplayType}
                />
              </div>
            </NavItem>
          </Nav>

          {/* Approved Notification Alerts */}
          {/* Map correct data when data is fetched. */}
          {/* {notifications && notifications.length > 0 &&
            <UncontrolledAlert className="notification-alert">
              <div className="row align-items-center">
                <div className="notification-alert-title col-3">GOOD JOB, JOHN!</div>
                <div className="notification-alert-desc col-8 col-md-7">
                  {notifications.map((item, index) => {
                    return <div key={index} className="pb-1 pt-2 border-bottom border-light">
                      {index + 1}. The <strong>{item.type}</strong> you've added to <strong>{item.target}</strong> has been approved by the community. Feel free to keep helping us by <strong>{item.future_help}</strong>.
                    </div>
                  })}
                </div>
              </div>
            </UncontrolledAlert>} */}

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {this.renderData()}
            </TabPane>
            <TabPane tabId="2">
              {this.renderData()}
            </TabPane>
            <TabPane tabId="3">
              {this.renderData()}
            </TabPane>
          </TabContent>

          <div
            ref={(loadingRef) => (this.loadingRef = loadingRef)}
            style={loadingCSS}
          >
            <div className="h2 bg-white text-secondary-dark p-3 m-0" hidden={!this.state.enableLoading || !contributions}>
              Loading...
            </div>
          </div>
        </div>

        {/* Delete Review Modal */}
        <Modal
          size="sm"
          isOpen={this.state.confirmDeleteModal}
          toggle={() =>
            this.setState({
              confirmDeleteModal: !this.state.confirmDeleteModal,
              deleteItem: {
                id: 0,
                type: "",
                parent_id: 0,
              },
            })
          }
          modalClassName="backdrop-opaque top-shifted transition-none"
        >
          {/* <ModalHeader
            toggle={() =>
              this.setState({
                confirmDeleteModal: !this.state.confirmDeleteModal,
                deleteItem: {
                  id: 0,
                  type: "",
                  parent_id: 0,
                },
              })
            }
          ></ModalHeader> */}
          <ModalBody className="text-center text-dark">
            <div className="text-right mr-2 cross-icon" onClick={() => {
              this.setState({
                confirmDeleteModal: !this.state.confirmDeleteModal,
                deleteItem: {
                  id: 0,
                  type: "",
                  parent_id: 0,
                },
              })
            }}>x</div>
            <h2 className="mb-3 mt-2">Confirmation</h2>
            <p className="small">Are you sure you want to delete?</p>

            <div className="">
              <div>
                <Button
                  onClick={() =>
                    this.setState({
                      confirmDeleteModal: false,
                      deleteItem: {
                        id: 0,
                        type: "",
                        parent_id: 0,
                      },
                    })
                  }
                  size="md"
                  color="primary btn-confirm"
                  className=" font-weight-bold"
                >
                  Cancel
                </Button>
                <Button
                className="ml-1 font-weight-bold"
                  size="md"
                  color="primary btn-confirm"
                  onClick={() => this.handleOnDeleteItem()}
                >
                  Ok
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* View Media Modal */}
        <Modal
          centered
          isOpen={this.state.viewMediaModalToggle}
          toggle={() =>
            this.setState({
              viewMediaModalToggle: !this.state.viewMediaModalToggle,
            })
          }
        >
          {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
          <ModalBody className="bg-transparent p-0">
            <Carousel
              responsive={responsive.videoModal}
              infinite={true}
              autoPlay={false}
              swipeable={true}
              showDots={false}
              arrows={true}
              sliderClass={
                `${this.state?.secondCarouselMedia?.length === 0}`
                  ? "mx-auto"
                  : ""
              }
            >
              {this.state?.secondCarouselMedia.length > 0 ? (
                this.state?.secondCarouselMedia.map((med, i) => {
                  return (
                    <div
                      className="item fs-14 h-100 d-flex flex-column justify-content-center"
                      key={med?.id}
                    >
                      <div>
                        <div className="d-flex text-white justify-content-between mt-3">
                          <div className="mr-2 ff-alt">{med?.caption}</div>
                          <div className="ff-base">{med?.label}</div>
                        </div>
                      </div>
                      <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                        {(med?.type === "image" || med?.type === "") && (
                          <img
                            className="img-fluid shown-media"
                            src={med?.url}
                            alt=""
                          />
                        )}
                        {med?.type === "video" && (
                          <video className="img-fluid shown-media" controls>
                            <source src={med?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        )}
                        {/* <div>
                                                {i+1} of {secondCarouselMedia.length}
                                            </div> */}
                      </div>
                      <div className="px-2 mt-3">
                        <div className="d-flex flex-nowrap mx-n2">
                          <div className="px-2 col d-flex flex-nowrap">
                            <div className="mr-2">
                              <img
                                className="img-circle _30x30"
                                src={
                                  med?.user?.current_profile_pic
                                    ? med?.user?.current_profile_pic
                                    : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"
                                }
                                alt=""
                                onError={(error) =>
                                  (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                }
                              />
                            </div>
                            <div>
                              <div className="text-primary ff-base">
                                Uploaded by&nbsp;
                                <a className="text-reset" href="#">
                                  <span className="font-weight-bold">
                                    {med?.user?.user?.first_name}
                                  </span>{" "}
                                </a>
                              </div>
                              <div className="text-tertiary ff-base">
                                {med?.upload_date}
                              </div>
                            </div>
                          </div>
                          <div className="px-2 col-auto">
                            <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                              {i + 1} of {this.state.secondCarouselMedia.length}
                            </h2>
                          </div>
                          <div className="px-2 col">&nbsp;</div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="item px-4">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                      <span>No result found for this category</span>
                      {/* <div className="mt-2">
                                                    <Button color="link" className="text-tertiary font-weight-bold">Back</Button>
                                                </div> */}
                    </div>
                  </div>
                </div>
              )}
            </Carousel>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapState = (state) => ({
  contributions_data: state.contributions.contributions_data,  
  loadingContributions: state.contributions.loadingContributions,
});

const mapDispatch = (dispatch) => ({
  get_contributions: (actor, page,contributionType,search) => dispatch(get_contributions(actor, page,contributionType,search)),
  get_added_contributions: (actor, page,contributionType) => dispatch(get_added_contributions(actor, page,contributionType)),
  delete_contribution: (id) => dispatch(delete_contribution(id)),
  delete_media: (data) => dispatch(delete_media(data)),
});

export default connect(mapState, mapDispatch)(Contributions);
