import React from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

const ReactionDetailsModal = ({
  isOpen,
  setIsOpen,
  counts: countsProp,
  statuses,
  onClickViewReactions,
  get_reveiewData,
  feedData,
  currentTab,
  setCurrentTab = () => ({}),
}) => {
  const counts = countsProp || {};
  const allCount = Object.values(counts || []).reduce((a, b) => a + b, 0);
  return (
    <Modal size="lg" isOpen={isOpen} toggle={() => setIsOpen(false)}>
      <ModalHeader toggle={() => setIsOpen(false)}></ModalHeader>
      <ModalBody>
        <Nav className="nav-fill active-transparent" tabs>
          <NavItem>
		  
            <NavLink
              active={currentTab === "all"}
              onClick={() => {
                setCurrentTab("all");
                if (statuses && Object.keys(statuses).includes("thanku")) {
                  onClickViewReactions("thanku",feedData);
                } else {
                  onClickViewReactions("all",feedData);
                }
              }}
            >
              <div className="ff-base">
                All&nbsp;<span>{allCount}</span>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={currentTab === "woohoo"}
              onClick={() => {
                setCurrentTab("woohoo");
                onClickViewReactions("woohoo",feedData);
              }}
              hidden={statuses && Object.keys(statuses).includes("thanku")}
            >
              <div className="ff-base">
                Wohoo&nbsp;<span>{counts.woohoo}</span>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={currentTab === "nailed"}
              onClick={() => {
                setCurrentTab("nailed");
                onClickViewReactions("nailed",feedData);
              }}
              hidden={statuses && Object.keys(statuses).includes("thanku")}
            >
              <div className="ff-base">
                Nailed It&nbsp;<span>{counts.nailed}</span>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={currentTab === "insightful"}
              onClick={() => {
                setCurrentTab("insightful");
                onClickViewReactions("insightful",feedData);
              }}
              hidden={statuses && Object.keys(statuses).includes("thanku")}
            >
              <div className="ff-base">
                Insightful&nbsp;<span>{counts.insightful}</span>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={currentTab === "lol"}
              onClick={() => {
                setCurrentTab("lol");
                onClickViewReactions("lol",feedData);
              }}
              hidden={statuses && Object.keys(statuses).includes("thanku")}
            >
              <div className="ff-base">
                LOL&nbsp;<span>{counts.lol}</span>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={currentTab === "bummer"}
              onClick={() => {
                setCurrentTab("bummer");
                onClickViewReactions("bummer",feedData);
              }}
              hidden={statuses && Object.keys(statuses).includes("thanku")}
            >
              <div className="ff-base">
                Bummer&nbsp;<span>{counts.bummer}</span>
              </div>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentTab}>
          <TabPane tabId="all">
		  
            <ul className="list-unstyled d-flex flex-column">
              {get_reveiewData &&
                get_reveiewData.map((item) => (
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2 media-post">
                        <img
                          className="media-object"
                          width="50"
                          height="50"
                          src={
                            item.profile_pic
                              ? item.profile_pic
                              : "../../../../assets/images/user-circle.png"
                          }
                          // src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/test.4fe86463aab3dbd5fdcc5f02cf8d12ca54aef58e.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href={item.redirect_to}
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          {item.name}
                        </a>
                        <img
                          src={require("../../../../assets/images/icons/nailed-it-active.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </li>
                ))}

              {/* <li className="mb-3">
                <div className="d-flex">
                  <div className="mr-2">
                    <img
                      width="50"
                      height="50"
                      src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/test.4fe86463aab3dbd5fdcc5f02cf8d12ca54aef58e.jpg"
                      alt=""
                    />
                  </div>
                  <div>
                    <a
                      href="#"
                      className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                    >
                      {" "}
                      WikiReviews Investor
                    </a>
                    <img
                      src={require("../../../../assets/images/icons/insightful-active.png")}
                      alt=""
                    />
                  </div>
                </div>
              </li> */}
            </ul>
          </TabPane>
          <TabPane tabId="woohoo">
            {get_reveiewData && (
              <ul className="list-unstyled d-flex flex-column">
                {get_reveiewData.map((item) => (
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2 media-post">
                        <img
                          className="media-object"
                          width="50"
                          height="50"
                          src={
                            item.profile_pic
                              ? item.profile_pic
                              : "../../../../assets/images/user-circle.png"
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href={item.redirect_to}
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          {item.name}
                        </a>
                        <img
                          src={require("../../../../assets/images/icons/nailed-it-active.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {get_reveiewData && get_reveiewData.length === 0 && (
              <div className="text-secondary-dark font-weight-bold">
                No People Reacted.
              </div>
            )}
          </TabPane>
          <TabPane tabId="nailed">
            {get_reveiewData && (
              <ul className="list-unstyled d-flex flex-column">
                {get_reveiewData.map((item) => (
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2 media-post">
                        <img
                          className="media-object"
                          width="50"
                          height="50"
                          src={
                            item.profile_pic
                              ? item.profile_pic
                              : "../../../../assets/images/user-circle.png"
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href={item.redirect_to}
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          {item.name}
                        </a>
                        <img
                          src={require("../../../../assets/images/icons/nailed-it-active.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {get_reveiewData && get_reveiewData.length === 0 && (
              <div className="text-secondary-dark font-weight-bold">
                No People Reacted.
              </div>
            )}
          </TabPane>
          <TabPane tabId="insightful">
            {get_reveiewData && (
              <ul className="list-unstyled d-flex flex-column">
                {get_reveiewData.map((item) => (
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2 media-post">
                        <img
                          className="media-object"
                          width="50"
                          height="50"
                          src={
                            item.profile_pic
                              ? item.profile_pic
                              : "../../../../assets/images/user-circle.png"
                          }
                          // src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/test.4fe86463aab3dbd5fdcc5f02cf8d12ca54aef58e.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href={item.redirect_to}
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          {item.name}
                        </a>
                        <img
                          src={require("../../../../assets/images/icons/nailed-it-active.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {get_reveiewData && get_reveiewData.length === 0 && (
              <div className="text-secondary-dark font-weight-bold">
                No People Reacted.
              </div>
            )}
          </TabPane>
          <TabPane tabId="lol">
            {get_reveiewData && (
              <ul className="list-unstyled d-flex flex-column">
                {get_reveiewData.map((item) => (
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2 media-post">
                        <img
                          className="media-object"
                          width="50"
                          height="50"
                          src={
                            item.profile_pic
                              ? item.profile_pic
                              : "../../../../assets/images/user-circle.png"
                          }
                          // src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/test.4fe86463aab3dbd5fdcc5f02cf8d12ca54aef58e.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href={item.redirect_to}
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          {item.name}
                        </a>
                        <img
                          src={require("../../../../assets/images/icons/nailed-it-active.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {get_reveiewData && get_reveiewData.length === 0 && (
              <div className="text-secondary-dark font-weight-bold">
                No People Reacted.
              </div>
            )}
          </TabPane>
          <TabPane tabId="bummer">
            {get_reveiewData && (
              <ul className="list-unstyled d-flex flex-column">
                {get_reveiewData.map((item) => (
                  <li className="mb-3">
                    <div className="d-flex">
                      <div className="mr-2 media-post">
                        <img
                          className="media-object"
                          width="50"
                          height="50"
                          src={
                            item.profile_pic
                              ? item.profile_pic
                              : "../../../../assets/images/user-circle.png"
                          }
                          // src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/test.4fe86463aab3dbd5fdcc5f02cf8d12ca54aef58e.jpg"
                          alt=""
                        />
                      </div>
                      <div>
                        <a
                          href={item.redirect_to}
                          className="text-secondary-dark mr-2 ff-base fs-14 font-weight-bold"
                        >
                          {" "}
                          {item.name}
                        </a>
                        <img
                          src={require("../../../../assets/images/icons/nailed-it-active.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {get_reveiewData && get_reveiewData.length === 0 && (
              <div className="text-secondary-dark font-weight-bold">
                No People Reacted.
              </div>
            )}
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default ReactionDetailsModal;
