import * as actions from "../actions/professionals";
const initialState = {
    connections_activity_data: null,
    category_collage_items: null,
    professionals_data_list: null,
    fetch_save_response: null,
    get_prious_tab: null,
};

const professionals = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case actions.GET_CONNECTIONS_ACTIVITY:
            state.connections_activity_data = action.payload
            return { ...state };

        case actions.GET_PREVIOUS_TAB:
            state.get_prious_tab = action.payload
            return { ...state };

        case actions.PROFESSIONAL_CATEGORY_COLLAGE_LIST:
            state.category_collage_items = action.payload
            return { ...state };

        case actions.FETCH_PROFESSIONALS_DATA_LIST:
            state.professionals_data_list = action.payload
            return { ...state };
        case actions.GET_SAVE_PROFESSIONAL_RESPONSE:
            state.fetch_save_response = action.payload;
            return { ...state };

        case actions.SET__PROFESSIONAL_LOADER:
            state.isLoading = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default professionals;