import React, { useEffect } from "react";
import PublicPageHeader from "../oragnisms/homepage/publicPageHeader";
import AppHeader from "../oragnisms/dashboard/appHeader";
import { Link, useHistory } from "react-router-dom";

const LinkTransition = () => {
  const isLoggedIn = localStorage.getItem("token");
  const history = useHistory();

  let url = window.location?.search?.split("page_url=")?.[1];
  if (!url) {
    url = window.history?.state?.state?.url;
  }

  const isValidURL = (str) => {
    if(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/g.test(str)) {
         return true;
     } 
     return false;
 }

  const isURLValid = isValidURL(url);

  useEffect(() => {
    let redirectTimeout = setTimeout(() => {
      if (isURLValid) {
        window.location.href = url;
      } else {
        history.push("/home");
      }
    }, 5000);
    return () => {
      clearTimeout(redirectTimeout);
    };
  }, [url, history, isURLValid]);

  return (
    <div>
      {isLoggedIn ? <AppHeader isEnableCover={false} /> : <PublicPageHeader />}
      <div className="link-transition">
        <div className="main-content">
          <img
            src="https://userdatawikireviews.s3.amazonaws.com/images/c-u-later.png"
            alt="See you later"
          />
          {isURLValid ? (
            <div className="mt-4">
              <h1 className="mb-2">See You Later!</h1>
              <p className="fs-18 text-dark">
                You're leaving <b>WikiReviews</b> and you will be <br />
                automatically redirected to <br />
                <a
                  href={url}
                  rel="noopener noreferrer"
                  target="_parent"
                  className="font-weight-bold text-primary"
                >
                  {url}
                </a>
                <br />
                in a moment.
              </p>
              <Link to="/home" className="font-weight-bold fs-18">
                Take me back to WikiReviews!
              </Link>
            </div>
          ) : (
            <div className="mt-4">
              <h1 className="mb-2">Ohh No! URL is not valid.</h1>
              <p className="fs-18 text-dark">
                Automatically redirecting you back to <b>WikiReviews</b> <br />
                in a moment.
              </p>
              <Link to="/home" className="font-weight-bold fs-18">
                Take me back to WikiReviews!
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkTransition;
