import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  Button, Col, DropdownItem,
  DropdownMenu, DropdownToggle, FormGroup,
  Input, Modal, ModalHeader, ModalFooter,
  ModalBody, Row, UncontrolledDropdown
} from "reactstrap";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { callApi } from '../../../utils/apiCaller';
import { toast } from "react-toastify";
import {
  add_dispute_reply, add_dispute_review,
  delete_dispute,
  delete_dispute_reply, get_dispute_discussion,} from "../../../actions/feeds";
import DisputingReview from "../../atoms/disputeReview/disputingReview";
import DisputedReview from "../../atoms/disputeReview/disputedReview";
import {get} from 'lodash'
import ModalComponent from '../modalComponent';
import ShareByEmail from '../shared/shareByEmail';
import { share_by_email } from '../../../actions/business';
import { getShareReviewURL } from '../../../utils/utilities';

// export default class ReviewRatingSet extends Component
class ReviewRatingSet extends Component {
  constructor(props) {
    super(props)

    this.state = {
      
      all_disputes_list: [],
      disputed_tab: false,
      user_names_list: [],
      get_dispute_data: [],
      showExplanation: false,
      disputeReviewId: "",
      showReply: false,

      dispute_files: [],
      dispute_imgepreview: [],

      dispute_reply_text: "",
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      reviewAdded: "",
      reviewAddedUser: "",
      reviewAddedUserImage: "",
      reviewAddedDescription: "",
      reviewAddedFor: "",
      reviewAddedOn: "",
      can_vote: false,
      is_media_dispute: false,
      timer_started: false,
      currentActiveIndex: 0,
      is_administrative_review: false,
      isLoading: false,
      confirmationToggle: false,
      confirmationReason: '',
      homeDropdownOpen: {},
      homeShareDropdownOpen: {},
      disputeDropdownOpen: {},

      showDisputeModal: false,
      reviewDetails: null,
      showDisputedModal: false,
      disputeModal: false,
      reason:'',

      host: window.location.host,
      protocol: window.location.protocol,
    };
  }

  componentWillReceiveProps(nextProps) {
    let {
      disputeThisReviewModalToggle
    } = this.state;

    if (nextProps.add_dispute_status) {
      if (disputeThisReviewModalToggle) {
        this.setState({ disputeThisReviewModalToggle: false })
      }
    }

    // Dispute Review 
    if ( this.state.disputeModal && nextProps?.get_dispute_modal_status?.modal === false) {
      this.setState({ disputeModal: false, showDisputeModal: false, reviewDetails: '', showDisputedModal: false })
      // if( nextProps?.get_dispute_modal_status?.refresh ) {
      // }
    }
  }

  componentDidUpdate() {
    let { get_dispute_data } = this.state;
    let nextProps = this.props;

    if (nextProps?.get_dispute_data?.results &&
      Array.isArray(nextProps?.get_dispute_data?.results) &&
      nextProps?.get_dispute_data?.results?.length &&
      nextProps?.get_dispute_data?.results?.length !== get_dispute_data?.length) {
      this.setState({
        get_dispute_data: nextProps.get_dispute_data.results,
        dispute_reply_text: "",
        dispute_files: [],
        dispute_imgepreview: [],
      });
    }
  }


  clearMediaDispatch = () => {
    this.setState(
      {
        latestUploadedData: {},
        uploadCaptions: {},
        selectedFilesPreview: [],
        files: [],
        taxonomycategoryId: "",
        fetchTaxonomyListingImages: {},
        fetchTaxonomyListingVideos: {},
        captionData: "",
        uploadedId: {},
        cattaxListing: {},
        albumCategoryId: "",
      },
      () => {
        this.props.clear_media_dispatch();
        this.props.clear_category();
        this.props.clear_subcategory();
      }
    );
  };


  handleCloseAndFetchReview = () => {
    let { mediaSetReview } = this.state;
    this.setState(
      {
        uploadMediaModal: !this.state.uploadMediaModal,
        fileReviewStatus: true,
        mediaSet: mediaSetReview,
        taxonomycategoryId: "",
        fetchTaxonomyListingImages: {},
        fetchTaxonomyListingVideos: {},
        captionData: "",
        uploadedId: {},
      },
      () => {
        this.clearMediaDispatch();
        this.props.fetch_media_data("image", "", 1, true, true);
        this.props.fetch_media_data("video", "", 1, true, true);
      }
    );
  };


  onClickWatchList = async (id, type) => {
    let { item_detail } = this.props;
    let data = { "review": id, "type": type };
    let url = `/api/pins/?type=${encodeURIComponent(type)}&user=`;
    if (this.props.listing_type_professional) {
      url = `/api/professionalpins/?`
      data = { "review": id, "type": type, professionaluser_entry: item_detail?.id };
    }

    try {

      let response = await callApi(url, 'POST', data);
      if (response && response?.code !== 500) {

        if (response.pinning_status && response?.pinning_status['watch list'] == true) {
          toast(`${item_detail?.name} has been added to your watch list`, {
            autoClose: 3500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
          this.props.handleReFetchCallAble('new');
        } else if (response.pin_type && response.pin_type != "") {
          toast(`This content has been added to your ${type}`, {
            autoClose: 3500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
          this.props.handleReFetchCallAble('new');

        } else {
          toast(`You have already added this content to your ${type}`, {
            autoClose: 3500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });


        }
      }

    } catch (err) {
      console.log(err);
    }
  }


  onClickRemoveWatch = async (id, type) => {
    let { item_detail } = this.props;
    let url = `/api/pins/${id}/?type=${encodeURIComponent(type)}&review=true`;
    if (this.props.listing_type_professional) {
      // url = `/api/professionalpins/?id=${item_detail?.id}&type=${encodeURIComponent(type)}&review=${id}`
      url = `/api/professionalpins/${item_detail?.id}/?type=${encodeURIComponent(type)}`
    }

    try {
      let response = await callApi(url, 'DELETE');
      if (response && response?.code !== 500) {
        if (response) {
          toast(this.props.listing_type_professional ?
            `${type} has been removed` :
            `${item_detail?.name} has been removed to your watch list`, {
            autoClose: 3500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
          this.props.handleReFetchCallAble('new');
        }
      }

    } catch (err) {
      console.log(err);
    }
  }

  toggleCategory = (expand, id) => {
    let { homeDropdownOpen } = this.state;
    homeDropdownOpen[id] = !expand;
    this.setState({ homeDropdownOpen }, () => {

    });
  }

  onMouseEnterCategory = (expand, id) => {
    let { homeDropdownOpen } = this.state;
    homeDropdownOpen[id] = expand;
    this.setState({ homeDropdownOpen }, () => {

    });
  }

  onMouseLeaveCategory = (expand, id) => {
    let { homeDropdownOpen } = this.state;
    homeDropdownOpen[id] = expand;
    this.setState({ homeDropdownOpen }, () => {

    });
  }

  toggleShareCategory = (expand, id) => {
    let { homeShareDropdownOpen } = this.state;
    homeShareDropdownOpen[id] = !expand;
    this.setState({ homeShareDropdownOpen }, () => {

    });
  }

  onMouseShareEnterCategory = (expand, id) => {
    let { homeShareDropdownOpen } = this.state;
    homeShareDropdownOpen[id] = expand;
    this.setState({ homeShareDropdownOpen }, () => {

    });
  }

  onMouseShareLeaveCategory = (expand, id) => {
    let { homeShareDropdownOpen } = this.state;
    homeShareDropdownOpen[id] = expand;
    this.setState({ homeShareDropdownOpen }, () => {

    });
  }

  toggleDisputeCategory = (expand, id) => {
    let { disputeDropdownOpen } = this.state;
    disputeDropdownOpen[id] = !expand;
    this.setState({ disputeDropdownOpen }, () => {

    });
  }

  onMouseDisputeEnterCategory = (expand, id) => {
    let { disputeDropdownOpen } = this.state;
    disputeDropdownOpen[id] = expand;
    this.setState({ disputeDropdownOpen }, () => {

    });
  }

  onMouseDisputeLeaveCategory = (expand, id) => {
    let { disputeDropdownOpen } = this.state;
    disputeDropdownOpen[id] = expand;
    this.setState({ disputeDropdownOpen }, () => {

    });
  }

  // Dispute Review Methods
  handleDisputeThisReview = (review, reason) => {
    this.setState({ showDisputeModal : true, reviewDetails: review, disputeModal: true, reason: reason })
  }

  handleHeatedDiscussion = (feed) => {
    this.setState({ showDisputedModal: true, reviewDetails: feed, disputeModal: true })
    return    
  };
  
  handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id
        });
    }

  handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote }) => {
    const professionalCategories = ['accountants', 'doctors', 'dentists', 'lawyers', 'actors', 'professionals']
    let url
    if (professionalCategories.includes(this.props.item?.associated_to?.content_type?.category?.toLowerCase()) || professionalCategories.includes(this.props.item?.listing_type?.toLowerCase()) || professionalCategories.includes(this.props.listing_type?.toLowerCase())) {
      url = `/api/professionalShareByemail/`
    } else {
      url = `/api/shareByemail/`;
    }
    let review_id = this.props?.item?.id;
    let data;
    if (review_id) {
      data = {
        review_id: review_id,
        mix_uid: `${shareEmail.split('@')[0]}_${this.props?.item?.id}_1597643911786`,
        email: shareEmail,
        user_name: shareName,
        listing_type: this.props.categoryType,
        optional_note: shareNote
      };
    } 
    this.props.share_by_email(url, data);
  }

  modalToggler = () => {
    this.setState({
      shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
    })
  }

  renderProfessionalRatingSet = (review) => {
    const professionalRating = review?.ratings_sets && Object.keys(review?.ratings_sets).length && review?.ratings_sets['proessional_rate'];
    if (professionalRating) {
      return (<>
        {Object.keys(professionalRating).map((item_professional, index) => (
          <Col key={index} className="mb-2" md={12}>
            <img
              className="mr-2"
              src={require(`../../../assets/images/icons/star/blue/${professionalRating &&
                professionalRating[item_professional] &&
                professionalRating[item_professional][0] ?
                professionalRating[item_professional][0] :
                '0rating.png'}`)}
              alt={professionalRating &&
                professionalRating[item_professional] &&
                professionalRating[item_professional][1] ?
                professionalRating[item_professional][1] :
                '0rating.png'}
              title={professionalRating &&
                professionalRating[item_professional] &&
                professionalRating[item_professional][1] ?
                professionalRating[item_professional][1] :
                '0rating.png'} />
            <span>{item_professional}</span>
          </Col>
        ))}
      </>)
    }
    return null;
  }


  render() {
    const {
      confirmationToggle,
      confirmationReason,
      showDisputeModal,
      showDisputedModal,
      reviewDetails,
      reason,
      shareByEmailModalToggle } = this.state
    const { item, my_profile, listing_type_professional, created_by, item_detail } = this.props;

    let quote = ""

    if (listing_type_professional) {
      quote = `Check out ${created_by?.user?.first_name ? created_by?.user?.first_name : 'the'} ${created_by?.user?.first_name && created_by?.user?.last_name ? created_by?.user?.last_name + "'s" : ''} review of ${item_detail?.firstname ? item_detail?.firstname : 'this listing'} ${item_detail?.lastname ? item_detail?.lastname : ''} on`
    } else {
      quote = `Check out ${created_by?.user?.first_name ? created_by?.user?.first_name : 'the'} ${created_by?.user?.first_name && created_by?.user?.last_name ? created_by?.user?.last_name + "'s" : ''} review of ${item_detail?.name ? item_detail?.name : 'this listing'} on`
    }

    return (
      <React.Fragment>

        { showDisputeModal ?     
          <DisputingReview review={reviewDetails} source="NonActioned" reason={reason} /> 
          :
          ''
        }
        { showDisputedModal ?     
          <DisputedReview review={reviewDetails} source="NonActioned"/> 
          :
          ''
        }

       

        <ModalComponent
          size="sm"
          isScrollable={true}
          isOpen={shareByEmailModalToggle}
          toggleHandler={() =>
            this.setState({
              shareByEmailModalToggle: !shareByEmailModalToggle,
            })}
          header={"Share by Email"}
          headerClass="text-uppercase px-0"
          body={<ShareByEmail handleOnSubmitShareByEmail={(value) => this.handleOnSubmitShareByEmail(value)} modalToggler={this.modalToggler} />}
        />  

        
        <div className="d-flex mx-n2 mb-2">
          <div className="px-2 flex-grow-1">
            {item?.child_review && item?.child_review?.is_draft != false ? (
              <div class="font-weight-bold text-darker" hidden={!!item.child_review?.draft}>
                {'Original Review'}
              </div>
            ) :
              <div class="font-weight-bold text-darker">
                {'Updated Review'}
              </div>}
              <div className="d-flex align-items-center">
              <div><span className="d-inline-block" title={(item?.ratings_sets?.avg_rating[1] && item?.ratings_sets?.avg_rating[0] !=="norating.png") ? item?.ratings_sets?.avg_rating[1] : `0 star`}>
                {item?.ratings_sets && item?.ratings_sets?.avg_rating && item?.ratings_sets?.avg_rating[0] ? (
                  <img
                    src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item?.ratings_sets?.avg_rating[0]}`}
                    alt=""
                    title={(item?.ratings_sets?.avg_rating[1] && item?.ratings_sets?.avg_rating[0] !== "norating.png") ? item?.ratings_sets?.avg_rating[1] : `0 star`} />
                ) : null}
                {item?.rating_set && item?.rating_set?.avg_rating && item?.rating_set?.avg_rating[0] ? (
                  <img
                    src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item?.rating_set?.avg_rating[0]}`}
                    alt=""
                    title={(item?.ratings_set?.avg_rating[1] && item?.ratings_set?.avg_rating[0] !== "norating.png") ? item?.ratings_set?.avg_rating[1] : `0 star`} />
                ) : null}
            </span>
                {
                  (item?.ratings_set?.avg_rating[0] === "norating.png" || item?.ratings_sets?.avg_rating[0] === "norating.png") ? <span className='zero-star-text'>
                    0 star
                  </span> : ""
                }
            </div>
            <div className='mt-1 ml-2'><span className="font-weight-bold text-dark">
              {' Overall Rating'}
            </span></div>
              </div>
              
            
            <div className="text-tertiary">
              <i className="font-weight-bold">{'This review was written'}</i> {item?.review_date_time}
            </div>
          </div>

          {/* {item?.child_review && ( */}
          <div className="px-2 col-auto">
            <div className="d-inline-flex flex-nowrap mx-n1">
              {listing_type_professional && item?.is_draft_p && <div className='draft-text'><span>Draft</span></div>}
              {!listing_type_professional && item?.is_draft && <div className='draft-text'><span>Draft</span></div>}
              <div className="px-1">
                <UncontrolledDropdown onMouseOver={() => this.onMouseShareEnterCategory(true, item?.id)}
                  onMouseLeave={() => this.onMouseShareLeaveCategory(false, item?.id)}
                  isOpen={this.state.homeShareDropdownOpen[item?.id]}
                  toggle={() => this.toggleShareCategory(this.state.homeShareDropdownOpen[item?.id])}>
                  <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                    <div className="thumbpin-icon"></div>
                  </DropdownToggle>

                  <DropdownMenu className="type-tertiary-alt" right>
                    {/* {this.props.listing_type_professional && (
                      <DropdownItem
                        onClick={() => {
                          if (item?.user_recommending?.pin_list_value &&
                            Array.isArray(item?.user_recommending?.pin_list_value) &&
                            item?.user_recommending?.pin_list_value?.length &&
                            item?.user_recommending?.pin_list_value.includes("want_list")) {
                            this.onClickRemoveWatch(item?.id, 'want list')
                          } else {
                            this.onClickWatchList(item?.id, 'want list');
                          }
                        }}
                        className="fs-14">
                        <FontAwesomeIcon
                          icon="heart"
                          className="mr-2"
                          fixedWidth />
                        {`${(item?.user_recommending?.pin_list_value &&
                          Array.isArray(item?.user_recommending?.pin_list_value) &&
                          item?.user_recommending?.pin_list_value?.length &&
                          item?.user_recommending?.pin_list_value.includes("want_list")) ? 'Added' : 'Add'} to want list`}
                      </DropdownItem>
                    )}
					
                    {this.props.listing_type_professional && (
                      <DropdownItem
                        onClick={() => {
                          if (item?.user_recommending?.pin_list_value &&
                            Array.isArray(item?.user_recommending?.pin_list_value) &&
                            item?.user_recommending?.pin_list_value?.length &&
                            item?.user_recommending?.pin_list_value.includes("favourite_list")) {
                            this.onClickRemoveWatch(item?.id, 'favourite list')
                          } else {
                            this.onClickWatchList(item?.id, 'favourite list');
                          }
                        }}
                        className="fs-14">
                        <FontAwesomeIcon
                          icon="star"
                          className="mr-2"
                          fixedWidth />
                        {`${(item?.user_recommending?.pin_list_value &&
                          Array.isArray(item?.user_recommending?.pin_list_value) &&
                          item?.user_recommending?.pin_list_value?.length &&
                          item?.user_recommending?.pin_list_value.includes("favourite_list")) ? 'Added' : 'Add'} to favorites`}
                      </DropdownItem>
                    )} */}
                    
					
                    {!this.props.listing_type_professional ? (
                      <DropdownItem
                        onClick={() => {
                          if (item?.pining_list &&
                            Array.isArray(item?.pining_list) &&
                            item?.pining_list?.length &&
                            item?.pining_list[0]?.watch_list?.length == 0) {
							//this.onClickRemoveWatch(item?.id, 'watch list');
							this.onClickWatchList(item?.id, 'watch list');
                          } else {
							//this.onClickWatchList(item?.id, 'watch list');
							this.onClickRemoveWatch(item?.id, 'watch list');
                          }
                        }}
                        className="fs-14">
                        <FontAwesomeIcon
                          icon="eye"
                          className="mr-2"
                          fixedWidth />
                        {`${item?.pining_list &&
                          Array.isArray(item?.pining_list) &&
                          item?.pining_list?.length && item?.pining_list[0]?.watch_list?.length == 0 ? 'Add' : 'Added'} to watch list`}
                      </DropdownItem>
                    ) : (
                        <DropdownItem
                          onClick={() => {
                            if (item?.user_recommending?.pin_list_value &&
                              Array.isArray(item?.user_recommending?.pin_list_value) &&
                              item?.user_recommending?.pin_list_value?.length &&
                              item?.user_recommending?.pin_list_value.includes("watch_list")) {
							  this.onClickRemoveWatch(item?.id, 'watch list')
                            } else {
							  this.onClickWatchList(item?.id, 'watch list');
                            }
                          }}
                          className="fs-14">
                          <FontAwesomeIcon
                            icon="eye"
                            className="mr-2"
                            fixedWidth />
                          {`${(item?.user_recommending?.pin_list_value &&
                            Array.isArray(item?.user_recommending?.pin_list_value) &&
                            item?.user_recommending?.pin_list_value?.length &&
                            item?.user_recommending?.pin_list_value.includes("watch_list")) ? 'Added' : 'Add'} to watch list`}
                        </DropdownItem>
                      )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="px-1">
                <UncontrolledDropdown
                  onMouseOver={() => this.onMouseEnterCategory(true, item?.id)}
                  onMouseLeave={() => this.onMouseLeaveCategory(false, item?.id)}
                  isOpen={this.state.homeDropdownOpen[item?.id]}
                  toggle={() => this.toggleCategory(this.state.homeDropdownOpen[item?.id])}>
                  <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                    <div className="share-it-icon"></div>
                  </DropdownToggle>

                  <DropdownMenu className="type-tertiary-alt" right>
                    <DropdownItem className="fs-14">
                      <FacebookShareButton
                        url={`${getShareReviewURL(item?.associated_to?.content_type?.category, item.associated_to?.slug, item.associated_to?.id)}`}
                        quote={quote}
                        hashtag={this.state.fbHashTag}>
                        {"Facebook"}
                      </FacebookShareButton>
                    </DropdownItem>
                    <DropdownItem className="fs-14">
                      <TwitterShareButton
                        url={`${getShareReviewURL(item?.associated_to?.content_type?.category, item.associated_to?.slug, item.associated_to?.id)}`}
                        title={quote}
                        via='WikiReviews'
                        // hashtags={this.state.twitterHashTags}
                        >
                        {"Twitter"}
                      </TwitterShareButton>
                    </DropdownItem>
                    {/* <DropdownItem
                                            className="fs-14"
                                            onClick={() => this.setState({ shareByEmailModalToggle: true, shareByEmailId: item.id, })}>
                                            {"By Email"}
                                        </DropdownItem> */}
                    <DropdownItem
                      className="fs-14"
                      onClick={() =>
                        this.modalToggler()
                      }>
                      {"By Email"}
                    </DropdownItem>
                  </DropdownMenu>

                  {/* <DropdownMenu className="type-tertiary-alt" right>
                                        <DropdownItem className="fs-14">Facebook</DropdownItem>
                                        <DropdownItem className="fs-14">Google +</DropdownItem>
                                        <DropdownItem className="fs-14">Twitter</DropdownItem>
                                        <DropdownItem className="fs-14"
                                            onClick={() => this.setState({
                                                shareByEmailModalToggle: true
                                            })}>By Email</DropdownItem>
                                    </DropdownMenu> */}
                </UncontrolledDropdown>
              </div>
              { (my_profile?.id !== get(item, 'created_by.id')) && !this.props.listing_type_professional && (
                <div className="px-1">
                  <UncontrolledDropdown
                    onMouseOver={() => this.onMouseDisputeEnterCategory(true, item?.id)}
                    onMouseLeave={() => this.onMouseDisputeLeaveCategory(false, item?.id)}
                    isOpen={this.state.disputeDropdownOpen[item?.id]}
                    toggle={() => this.toggleDisputeCategory(this.state.disputeDropdownOpen[item?.id])}>
                    <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                      <div className="downfeed-icon"></div>
                    </DropdownToggle>
                    {/* <DropdownMenu className="type-tertiary-alt" right>
                      <DropdownItem
                        onClick={ () => this.handleDisputeThisReview(item) }
                        >
                        Dispute this review
                                        </DropdownItem>
                    </DropdownMenu> */}
                    <DropdownMenu className="type-tertiary-alt" right>
                            <li className="dropdown-item has-submenu to-left ff-base fs-14">
                                Dispute this review
                        <ul className="dropdown-submenu">
                                    <DropdownItem className="fs-14" onClick={ () => this.handleDisputeThisReview(item,'This review is fake') } >This review is fake</DropdownItem>
                                    <DropdownItem className="fs-14" onClick={ () => this.handleDisputeThisReview(item, 'This review is not relevant') } >This review is not relevant</DropdownItem>
                                    <DropdownItem className="fs-14" onClick={ () => this.handleDisputeThisReview(item, 'This review is inaccurate') }>This review is inaccurate</DropdownItem>
                                </ul>
                            </li>
                        </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </div>
          </div>

        </div>
        <div className="mb-3">
          <Row>
            {item?.ratings_sets && Object.keys(item?.ratings_sets).length ?
              Object.keys(item.ratings_sets).map((rt_item, index) => (
                <React.Fragment>
                  {(rt_item === "avg_rating" || rt_item === "total_review" || rt_item == "proessional_rate") ? "" : (
                    <Col key={index} className="mb-2" md={6}>
                      <img
                        className="mr-2"
                        src={require(`../../../assets/images/icons/star/blue/${item.ratings_sets &&
                          item.ratings_sets[rt_item] &&
                          item.ratings_sets[rt_item][0] ?
                          item.ratings_sets[rt_item][0] :
                          '0rating.png'}`)}
                        alt={item.ratings_sets && item.ratings_sets[rt_item] &&
                          item.ratings_sets[rt_item][1] ?
                          item.ratings_sets[rt_item][1] :
                          '0rating.png'}
                        title={item.ratings_sets &&
                          item.ratings_sets[rt_item] &&
                          item.ratings_sets[rt_item][1] ?
                          item.ratings_sets[rt_item][1] :
                          '0rating.png'} />
                      <span>{rt_item}</span>
                    </Col>
                  )}

                </React.Fragment>

              ))
              : null}

            
          </Row>
          {
            item?.ratings_sets && Object.keys(item?.ratings_sets).length && item?.ratings_sets?.proessional_rate
            && <Row className="mt-3">
              {this.renderProfessionalRatingSet(item)}
            </Row>
          }
        </div>

       {/* SEE HOW REVIEW DISPUTES WORK */}
        <Modal
          isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
          toggle={() =>
            this.setState({
              watchHowDisputeReviewWorksModalToggle: !this.state
                .watchHowDisputeReviewWorksModalToggle,
            })
          }
        >
          <ModalHeader
            className="text-uppercase px-0"
            toggle={() =>
              this.setState({
                watchHowDisputeReviewWorksModalToggle: !this.state
                  .watchHowDisputeReviewWorksModalToggle,
              })
            }
          >
            SEE HOW REVIEW DISPUTES WORK
                </ModalHeader>
          <ModalBody className="p-0 bg-transparent">
            <div className="text-center">
              <video controls autoPlay width="100%" seekable>
                <source
                  src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                  type="video/mp4"
                />
                        Your browser does not support the video tag.
                    </video>
            </div>
          </ModalBody>
        </Modal>

        {/* Confirmation Modal */}
        <Modal size="sm"
          isOpen={confirmationToggle}
          className="text-center"
          modalClassName="backdrop-opaque top-shifted transition-none">
          <div className="bg-white modal-header">
            <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
            <button
              className="btn btn-sm"
              aria-label="Close"

            >
              <span aria-hidden="true" onClick={() => this.setState({ confirmationToggle: false })} >×</span>
            </button>
          </div>
          <ModalBody className="text-dark">
            {confirmationReason === 'delete_dispute'
              ?
              'Do you want to remove your Dispute?'
              :
              confirmationReason === 'delete_dispute_reply' ?
                'Do you want to remove your Reply?'
                :
                ''
            }
          </ModalBody>
          <ModalFooter className="bg-white">
            <div className="text-center w-100">
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={() => this.setState({ confirmationToggle: false })}
              >
                Cancel
                </Button>
              <Button
                color="primary"
                size="sm"
                className="btn-confirm"
                onClick={() => this.handleDelete()}

              >
                Yes
                </Button>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapState = (state) => ({
  get_dispute_data: state.feeds.get_dispute_data,
  my_profile: state.user.my_profile,
  add_dispute_status: state.feeds.add_dispute_status,
  get_dispute_modal_status: state.dispute.get_dispute_modal_status,
});

const mapProps = (dispatch) => ({
  get_dispute_discussion: (id) => dispatch(get_dispute_discussion(id)),
  add_dispute_reply: (params) => dispatch(add_dispute_reply(params)),
  delete_dispute_reply: (id, reviewId) =>
    dispatch(delete_dispute_reply(id, reviewId)),
  add_dispute_review: (params, file) => dispatch(add_dispute_review(params, file)),
  delete_dispute: (id) => dispatch(delete_dispute(id)),
  share_by_email: (url, data) => dispatch(share_by_email(url, data)),
})

export default connect(mapState, mapProps)(ReviewRatingSet);

