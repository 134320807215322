import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { getQueryString } from '../utils/validation';
import { setItemDetailLoading } from "./itemDetail";

export const FETCH_MOVIES = "FETCH_MOVIES";
export const FETCH_MOVIE_CATEGORY = "FETCH_MOVIE_CATEGORY";
export const FETCH_MAIN_CATEGORY_SUBCATEGORY = "FETCH_MAIN_CATEGORY_SUBCATEGORY";
export const FETCH_MOVIES_TAXONOMY = "FETCH_MOVIES_TAXONOMY";
export const FETCH_MOVIES_BOXOFFICE = "FETCH_MOVIES_BOXOFFICE";
export const FETCH_MOVIES_SEARCH = "FETCH_MOVIES_SEARCH";
export const GET_SEARCH_BOXOFFICE = "GET_SEARCH_BOXOFFICE";
export const GET_MOVIES_LISTING = "GET_MOVIES_LISTING";
export const GET_MOVIE_DETAIL = "GET_MOVIE_DETAIL";
export const GET_REVIEW_LISTING = "GET_REVIEW_LISTING";
export const GET_USER_FEEDS = "GET_USER_FEEDS";
export const GET_DISPUTE_DATA = "GET_DISPUTE_DATA";
export const GET_THEATER_DATA = "GET_THEATER_DATA";
export const MOVIE_CATEGORY_COLLAGE_LIST = "MOVIE_CATEGORY_COLLAGE_LIST";
export const GET_SAVE_MOVIE_RESPONSE = "GET_SAVE_MOVIE_RESPONSE";

// New Const 

export const GET_MOVIE_DETAILS_BY_ID = 'GET_MOVIE_DETAILS_BY_ID';
export const GET_BACK_TO_MOVIE = 'GET_BACK_TO_MOVIE';

// Advisory
export const GET_ADVISORY_LISTING = "GET_ADVISORY_LISTING";


const common_fetch = ({ url, method, data, dispatch_function }) => {
  if (data === undefined) {
    data = {};
  }
  return (dispatch) => {
    callApi(url, method, data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(dispatch_function(response));
      }
    });
  };
};
//Get Movies Listing while select category movies in center
export const fetch_movies_data_ = (data) => ({ type: FETCH_MOVIES, payload: data });
export const fetch_movies_data = (userId, type, page) => {
  return (dispatch) => {
    if (type == "intheaters") {
      callApi(`/movies/?category=&page=${page}&location=false&homeTheater=false`, 'GET')
        .then((response) => {
          if (response && response.code !== 400 && response.code !== 500) {
            response['page'] = page;
            return dispatch(fetch_movies_data_(response));
          }
        })
    } else if (type == "upcomaxg") {
      callApi(`/movies/?category=&page=${page}&upcomaxg=thisweek&homeTheater=false`, 'GET')
        .then((response) => {
          if (response && response.code !== 400 && response.code !== 500) {
            response['page'] = page;
            return dispatch(fetch_movies_data_(response));
          }
        })

    } else {
      callApi(`/movies/?category=&page=${page}&usertheater=${userId}&homeTheater=false`, 'GET')
        .then((response) => {
          if (response && response.code !== 400 && response.code !== 500) {
            response['page'] = page;
            return dispatch(fetch_movies_data_(response));
          }
        })

    }
  }
}

//Get Movies Sub Categories Listing on left sidebar
export const fetch_movies_category_ = (data) => ({ type: FETCH_MOVIE_CATEGORY, payload: data })
export const fetch_movies_category = () => {
  return (dispatch) => {
    callApi(`/api/taxonomy/?category=movies&depth=false&delsel=&ancestor=&sub_cat=&level=1`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(fetch_movies_category_(response));
        }
      })
  }
}

//Get Main Category with Sub Categories Listing in heading section
export const fetch_main_category_subcategory_ = (data) => ({ type: FETCH_MAIN_CATEGORY_SUBCATEGORY, payload: data })
export const fetch_main_category_subcategory = () => {
  return (dispatch) => {
    callApi(`/api/taxonomy/?category=1&depth=false&show_children=true&level=1`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(fetch_main_category_subcategory_(response));
        }
      })
  }
}

//Get Heading Section Movies Listing with taxonomy Type
export const fetch_movies_taxonomy_ = (data) => ({ type: FETCH_MOVIES_TAXONOMY, payload: data })
export const fetch_movies_taxonomy = () => {
  return (dispatch) => {
    callApi(`/api/newtrends/?trending=national&content_type=movies&listingtaxonomy=`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(fetch_movies_taxonomy_(response));
        }
      })
  }
}

//Get User Feeds on right side bar like all, reviews, comments and other
export const get_user_feeds_ = (data) => ({ type: GET_USER_FEEDS, payload: data });
export const get_user_feeds = (category, type) => {
  return (dispatch) => {
    callApi(`/api/userfeeds/?category=${category}&searchTerm=${type}&page=1`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(get_user_feeds_(response));
      }
    });

  };
};

//Get BoxOffice Data
export const fetch_movies_boxoffice_ = (data) => ({ type: FETCH_MOVIES_BOXOFFICE, payload: data })
export const fetch_movies_boxoffice = () => {
  return (dispatch) => {
    callApi(`/api/boxoffice/?showdata=current`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(fetch_movies_boxoffice_(response));
        }
      })
  }
}

//Get Search Movies by Click on Category Type in Head Section
export const get_searchMovies_ = (data) => ({ type: GET_SEARCH_BOXOFFICE, payload: data });
export const get_searchMovies = (location, subCatId, contentType) => {
  return (dispatch) => {
    callApi(`/api/search/?content_type=${contentType}&catTrending=True&subcat=${subCatId}&trendingType=national&size=6`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(get_searchMovies_(response));
      }
    });
  };
};

//Get Movies on Click movies tab in home page
export const get_moviesListing_ = (data) => ({ type: GET_MOVIES_LISTING, payload: data });
export const get_moviesListing = (contentType, page) => {
  return (dispatch) => {
    callApi(`/api/search/?content_type=${contentType}&to_api=true&showReview=true&homePage=true&page=${page}&feed_tab=true`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(get_moviesListing_(response));
      }
    });
  };
};

//Get reviews listing for particular movie
export const get_reviews_listing_ = (data) => ({ type: GET_REVIEW_LISTING, payload: data });
export const reset_reviews_listings_ = () => ({
  type: GET_REVIEW_LISTING, payload: {
    code: 200,
    count: 0,
    next: null,
    previous: null,
    results: []
  }
});

export const reset_reviews_listings = () => {
  return (dispatch) => {
    dispatch(reset_reviews_listings_());
  }
};

export const get_reviews_listing = (id, type, searchValue) => {
  if (!type) { type = 'new' }
  let url = `/api/reviews/?entry=&associated_to=${id}&searchTerm=${type}`;
  if(searchValue) url = `/api/reviews/?asset=&page=1&review=&review_type=All&search=${searchValue}&entry=${id}`;

  return (dispatch) => {
    if(searchValue || searchValue==="") dispatch(get_reviews_listing_([]));
    
    callApi(url, "GET").then((response) => {
      if (response && response?.code !== 400 && response?.code !== 500) {
        dispatch(setItemDetailLoading(false));
        dispatch(get_reviews_listing_(response));
      }
    });
  };
};

export const add_movie_review_vote = (data) => {
  return (dispatch) => {
    callApi(`/ratings/vote/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        //return dispatch(get_broadcast_channel());
      }
    });
  };
};

//dispute review
export const add_dispute_review = (data) => {
  let formData = new FormData();
  formData.append('reason', data.reason);
  formData.append('comment', data.comment);
  formData.append('review_id', data.review_id);
  for (let i = 0; i < data.dispute_file.length; i++) {
    formData.append('dispute_file', data.dispute_file[i]);
  }
  return (dispatch) => {
    callApi(`/api/disputes/`, "POST", formData, true).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        if (response.Message) {
          toast(response.Message, {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        } else {
          toast(`Review has been marked as disputed!`, {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }
      }
    });
  };
};

export const get_dispute_action = (data) => {
  return {
    type: GET_DISPUTE_DATA,
    payload: data,
  };
};

export const get_dispute_discussion = (review_id) => {
  return (dispatch) => {
    callApi(`/api/disputes/?review_id=${review_id}`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_dispute_action(response));
      }
    });
  };
};

export const delete_dispute = (review_id) => {
  return (dispatch) => {
    callApi(`/api/disputes/?review_id=${review_id}`, "DELETE").then(
      (response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          toast(`Dispute has been removed!`, {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }
      }
    );
  };
};

export const add_dispute_reply = (data) => {
  let formData = new FormData();
  formData.append('review_id', data.review_id);
  formData.append('flag_content_id', data.flag_content_id);
  formData.append('flag_id', data.flag_id);
  formData.append('comment', data.comment);

  for (let i = 0; i < data.dispute_reply_file.length; i++) {
    formData.append('dispute_reply_file', data.dispute_reply_file[i]);
  }

  return (dispatch) => {
    callApi(`/api/disputes-history/`, "POST", formData, true).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        if (response.done) {
          toast("Your reply has been added Successfully!", {
            autoClose: 2500,
            className: "black-background",
            bodyClassName: "red-hunt",
            progressClassName: "cc",
          });
        }
      }
      return dispatch(get_dispute_discussion(data.review_id));
    });
  };
}

export const delete_dispute_reply = (pk, review_id) => {
  return (dispatch) => {
    callApi(
      `/api/disputes-history/?flag_content_id=${pk}`,
      "DELETE"
    ).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Reply has been removed!`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(get_dispute_discussion(review_id));
    });
  };
}

export const get_theater_data_ = (data) => {
  return {
    type: GET_THEATER_DATA,
    payload: data,
  };
};

export const get_theater_data = (movie_id) => {
  return (dispatch) => {
    callApi(`/api/theatres/?movieid=${movie_id}&theatre_id=&city=&notin=true`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_theater_data_(response));
      }
    });
  };
};

export const delete_movies_review = (id, movie_id, name) => {
  return (dispatch) => {
    callApi(`/api/reviews/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {

        toast(`Your review of ${name} has been deleted.`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });

        return dispatch(get_reviews_listing(movie_id));
      }
    });
  };
};


// Advisory
export const get_advisory_listing_ = (data) => {
  return {
    type: GET_ADVISORY_LISTING,
    payload: data,
  };
};

export const get_advisory_listing = (type) => {
  return (dispatch) => {
    callApi(`/api/userguide/?entry_type=${type}`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_advisory_listing_(response));
      }
    });
  };
};

export const delete_movie_advisory = (id,movieId,type) => {
  return (dispatch) => {
    callApi(`/api/userguide/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if(type == 'warning'){
          text = `Your tip is deleted.`;
        }
        if(type == 'tips'){
          text = `Your tip is deleted.`;
        }
        if(type == 'advisory'){
          text = `Your warning is deleted.`;
        }
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(get_advisory_listing(movieId));
    });
  };
};

export const add_movie_advisory = (data,name) => {
  return (dispatch) => {
    callApi(`/api/userguide/?entry_type=`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if(data.type == 'warning'){
          text = `You added a warning to  ${name}.`;
        }
        if(data.type == 'tips'){
          text = `You added a tip to  ${name}.`;
        }
        if(data.type == 'advisory'){
          text = `Your content added to a Advisory section.`;
        }
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(get_advisory_listing(data.user_entry));
    });
  };
};

export const edit_movie_content = (data,id,name) => {
  return (dispatch) => {
	callApi(`/api/userguide/?entry_type=`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let text = '';
        if(data.type == 'warning'){
          text = `You have updated a warning to ${name}.`;
        }
        if(data.type == 'tips'){
          text = `You have updated a tip to ${name}.`;
        }
        toast(`${text}`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
      }
      return dispatch(get_advisory_listing(id));
    });
  };
};

export const fetch_movies_category_collage_items_dispatch = (data) => ({ type: MOVIE_CATEGORY_COLLAGE_LIST, payload: data, });
export const fetch_movies_category_collage_items = (...data) => (
  common_fetch({
    // url: getQueryString({ url: `/api/newtrends/`, data }),
    url: getQueryString({ url: `/api/search/`, data }),
    method: "GET",
    dispatch_function: fetch_movies_category_collage_items_dispatch
  })
);

export const get_response_ = (data) => {
  return {
    type: GET_SAVE_MOVIE_RESPONSE,
    payload: data,
  };
};


export const add_movie = (data) => {
  return (dispatch) => {
    callApi(`/api/movies/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Hooray! You've created a new listing. Help improve it by adding additional information and reviews.`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
		return dispatch(get_response_(response));
      }
    });
  };
};
