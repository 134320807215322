import React, { useState } from "react";
import { FormGroup, Button, Input, Media } from "reactstrap";

export const ReplyEditComment = ({ body, onCancel, userImgSource, onSubmit, isUpdating }) => {
  const [commentBody, setCommentBody] = useState(body);
  const [isExactlySame, setIsExactlySame] = useState(false);
  return (
    /* Show When Replying and update comment */
    <div>
      <Media className="media-post mt-3">
        <Media>
          <img
            className="media-object"
            // src={require("../../../assets/images/icons/user-circle.png")}
            src={userImgSource ? userImgSource:require("../../../assets/images/icons/user-circle.png")}
            alt="User"
            onError={(error) =>
              (error.target.src = require("../../../assets/images/icons/user-circle.png"))
            }
          />
        </Media>
        <Media body>
          <FormGroup className="mb-0">
            <Input
              bsSize="sm"
              className="mb-2 text-primary"
              type="textarea"
              name="reply"
              placeholder="Write a reply"
              value={commentBody}
              onChange={(e) => {
                setCommentBody(e.currentTarget.value);
                setIsExactlySame(false);
              }}
            />
            {isExactlySame && (
              <span className="text-error">No Change Was Reported</span>
            )}
            <div className="d-flex mx-n1 justify-content-end">
              <Button
                size="sm"
                color="primary"
                className="mx-1"
                onClick={onCancel}
              >
                Cancel
                </Button>
              <Button
                size="sm"
                color="primary"
                className="mx-1"
                onClick={() => {
                  if (body !== commentBody) {
                    onSubmit(commentBody);
                    setCommentBody(null);
                  } else {
                    setIsExactlySame(true);
                  }
                }}
                disabled={!commentBody}
              >
                {isUpdating ? "Update" : "Submit"}
              </Button>
            </div>
          </FormGroup>
        </Media>
      </Media>
      <hr />
    </div>
  );
};
