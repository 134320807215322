import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Badge, Button, Collapse, FormGroup, Input, Label } from 'reactstrap';
import { gettextColor } from "../../utils/utilities";

const CollapseBasic = (props) => {
    const [isOpen, setIsOpen] = useState(props.isOpen);
    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (props.detailId) {
            setIsOpen(false)
        }
    }, [props.detailId]);

    return (
        <div className={"d-flex flex-column flex-column-reverse " + (!props.transparent ? 'bg-white ' : ' ') + (props.noPadding ? 'p-0 ' : (props.size === 'sm' ? 'p-1 ' : 'p-3 ')) + (props.containerClass ? props.containerClass : '')} >
            <Collapse isOpen={isOpen}>
                <div className={(props.bodyClass ? props.bodyClass : 'text-secondary-dark')}>
                    {props.children}
                </div>
            </Collapse>

            <div className={"collapse-header" + ' ' + (props.size)} onClick={() => props?.isReviewCollapse && toggle()} >
                <div className="d-flex">
                    {props.titleImg ? (<div className="mr-2">
                        <img src={props.titleImg} height="20" alt="" />
                    </div>) : ''}
                    {props.size === 'sm' ? (
                        <span className={"title mr-2 font-weight-semi-bold fs-16" + " " + (props.titleClass)}>{props.titleCount !== undefined ? <span className="text-secondary-dark">{props.titleCount}{" "}</span> : ''}{props.title}</span>
                    ) : (
                        <h2 className={"mr-2 lh-1" + " " + (props.titleClass)}>{props.titleCount !== undefined ? <span className="text-secondary-dark">{props.titleCount}{" "}</span> : ''}{props.title}</h2>
                    )}
                    {props.collapseType === "history" &&
                        <>
                            {props?.subCollapse ? <div className="d-flex mx-n2">
                                <div className="px-2 mt-1">
                                    <FontAwesomeIcon icon="paperclip" fixedWidth />
                                </div>
                                <div className="px-2 col mt-1">
                                    <span className="font-weight-bold">
                                        filename.jpg <Badge color="dark" className="rounded-0" size="sm">MA</Badge>
                                    </span>
                                </div>
                            </div> :
                                <>
                                    <FormGroup check style={{ minWidth: '190px' }}>
                                        <Label className="text-reset font-weight-normal fs-14 d-block" check>
                                            <Input type="radio" name="selectLogType" />{' '}
                                            <div className="pl-4 font-weight-bold ff-alt">
                                                <span className={gettextColor(props?.headerData?.status)}>[{props?.headerData?.status}]</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <div className="fs-16">
                                        <span>Text changed by <a href="#" className="text-reset font-weight-bold">{props?.headerData?.person}</a></span>&nbsp;
                                        <span className="fs-12 text-muted">
                                            {props?.headerData?.date}
                                        </span>
                                    </div> </>}
                        </>
                    }

                    {(props.collapsible === undefined || props.collapsible === true) ?
                        <div className={`ml-auto ${props.fromContribution ? "mt-n4 mb-4" : ''}`}>
                            <Button color="" className="btn-collapse" onClick={toggle}>
                                <span className="collapse-icon"></span>
                            </Button>
                        </div>
                        : ''

                    }
                </div>
                {props.noHr ? '' : <hr className={"separator " + (props.size === 'sm' ? 'my-2' : '')} />}
            </div>
        </div>
    );


};


export default CollapseBasic;
