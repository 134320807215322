import React, { Component } from 'react'
import {
    FormGroup, Button, Input, Collapse
} from 'reactstrap';
import CollapseBasic from './collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { callApi } from "../../utils/apiCaller";

export default class AdditionalInfo extends Component {
    constructor(props) {
        super(props)
        this.isEnableUpdate = false;
        this.state = {
            listingItems: {},
            readCharactersLimit: {},
            OptionElements: null,
            selectId: '',
            infoText: '',
            isToggleEdit: {},
            editItem: null
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps?.additional_info && Object.keys(nextProps?.additional_info).length) {
          this.setState({ listingItems: nextProps?.additional_info });
        }
    
      }
    componentDidUpdate() {
        if (this.props.additional_info) {
            if (Object.keys(this.props.additional_info)?.length > 0 && this.isEnableUpdate) {
                this.isEnableUpdate = false;
                this.setState({
                    listingItems: this.props.additional_info
                });
            }
        }
    }


    fetchFormItems = async () => {
        const { id } = this.props;
        if (id) {
            try {
                let url = `/form/professionaladditionalinfo/${id}`;
                let response = await callApi(url, 'GET');

                if (response && response?.code !== 500) {
                    if (response?.data) {
                        const doc = new DOMParser().parseFromString(response?.data, "text/html");

                        if (doc) {
                            let elements = doc.getElementById('id_name')?.options;
                            let OptionItems = [];
                            if (elements && elements?.length) {
                                for (let item of elements) {
                                    OptionItems.push({ value: item?.value, text: item?.childNodes[0].data });
                                }
                            }

                            this.setState({ OptionElements: OptionItems });
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    onHandleSubmit = async () => {
        const { id, listing_type } = this.props;
        let { selectId, infoText } = this.state;
        try {
            let url = `/api/${listing_type}/${id}/`;
            let data = { "name": selectId, "info": infoText };
            let response = await callApi(url, 'PUT', data);
            if (response && response?.code !== 500) {
                this.fetchItems();
                this.setState({ isToggleOpen: false,infoText:"",selectId:"" })
            }
            
        } catch (err) {
            console.log(err);
        }
    }

    componentDidMount() {
        this.isEnableUpdate = true;
        this.fetchFormItems();
     
    }

    updateItem = async () => {
        let { editItem } = this.state;
        const { id, listing_type } = this.props;

        try {
            let url = `/api/${listing_type}/${id}/`;
            let data = { "additional_info": { "key": editItem?.key, "oldVal": editItem?.value, "newVal": editItem?.newValue } }
            let response = await callApi(url, 'PATCH', data);
            if (response && response?.code !== 500) {
                this.fetchItems();
                this.isEnableUpdate = true;
                this.setState({ isToggleEdit: {} });
            }
        } catch (err) {
            console.log({ err });
        }
    }

    fetchItems = () => {
        let { fetchByItemId, id, listing_type } = this.props;
        let alterText = `${listing_type.slice(0, -1)}Id`;
        let data = { [alterText]: id };
        
        fetchByItemId(data);
        this.isEnableUpdate = true;
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        let { listingItems, readCharactersLimit, OptionElements, selectId, infoText, isToggleEdit, editItem } = this.state;
        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic title="Additional Information" containerClass="bg-transparent type-secondary" bodyClass="text-secondary-dark" isOpen={true}>

                    {/* Show If data */}
                    <div>
                        <ul className="list-unstyled mb-2 fs-14">
                            {Object.keys(listingItems).length ?
                                Object.keys(listingItems).sort().map((item, index) => (
                                    <li key={index}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="font-weight-bold mb-1 mr-1 col-5 px-0">
                                                {`${item} :`}
                                            </span>

                                            <span className="col px-0">
                                                {/* Hide when editing */}
                                                {listingItems[item] && Array.isArray(listingItems[item]) && listingItems[item]?.length ?
                                                    listingItems[item].map((item_listing, index_item) => (
                                                        <div key={index_item}>
                                                            <div
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    let editValue = {
                                                                        key: item,
                                                                        newValue: ''
                                                                    };
                                                                    if (isToggleEdit[item] == undefined) {
                                                                        isToggleEdit[item] = true;

                                                                    } else {
                                                                        isToggleEdit[item] = !isToggleEdit[item];
                                                                    }

                                                                    if (item_listing?.value?.length) {
                                                                        editValue = item_listing;
                                                                        editValue['key'] = item;
                                                                        editValue['newValue'] = item_listing?.value;
                                                                    }

                                                                    this.setState({ isToggleEdit, editItem: editValue })
                                                                }}
                                                                className="mb-2">
                                                                <div className="mr-1 fs-14">
                                                                    <span className="mr-1 font-weight-normal text-secondary-dark editable-column wb-break-word">
                                                                        {item_listing?.value?.length >= (readCharactersLimit[item] ? item_listing?.value?.length : 100) ?
                                                                            item_listing?.value.substring(0, (readCharactersLimit[item] ? item_listing?.value?.length : 100)) :
                                                                            item_listing?.value}
                                                                    </span>
                                                                    {item_listing?.value !== '' &&
                                                                        item_listing?.value?.length >= (readCharactersLimit[item] ? item_listing?.value?.length : 100) ? (
                                                                            <div>
                                                                                <span
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        if (readCharactersLimit[item] == undefined) {
                                                                                            readCharactersLimit[item] = true;
                                                                                        } else {
                                                                                            readCharactersLimit[item] = !readCharactersLimit[item];
                                                                                        }
                                                                                        this.setState({ readCharactersLimit })
                                                                                    }}
                                                                                    className="text-secondary-dark font-weight-bold cursor-pointer">
                                                                                    {readCharactersLimit[item] ? 'show less' : 'read more'}
                                                                                </span>
                                                                            </div>
                                                                        ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : null}

                                                {isToggleEdit[item] == true ? (
                                                    <div>
                                                        <FormGroup>
                                                            <Input
                                                                onChange={(e) => {
                                                                    editItem.newValue = e.target.value;
                                                                    this.setState({ editItem });
                                                                }}
                                                                value={editItem?.newValue}
                                                                bsSize="sm"
                                                                type="textarea"
                                                                rows="4"
                                                            />
                                                            <div className="text-right mt-2">
                                                                <Button
                                                                    disabled={editItem?.newValue?.length ? false : true}
                                                                    onClick={() => {
                                                                        this.updateItem();
                                                                    }}
                                                                    color="primary" size="sm" title="Save">
                                                                    <FontAwesomeIcon icon="check" fixedWidth />
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        this.setState({ editItem: {}, isToggleEdit: {} })
                                                                    }}
                                                                    color="grey" size="sm" title="Cancel">
                                                                    <FontAwesomeIcon icon="times" fixedWidth />
                                                                </Button>
                                                            </div>
                                                        </FormGroup>
                                                    </div>
                                                ) : null}
                                            </span>
                                        </div>
                                    </li>
                                ))
                                : (
                                    <div>
                                        <span className="small"><i>{'No Info added so far.'}</i></span>
                                    </div>
                                )}
                        </ul>
                    </div>

                    <div className="text-right mb-2">
                        <Button
                            onClick={() => { 
                                if(!this.checkIfLoggedIn()){
                                    this.props.forceToggleLoginModal();
                                    return false;
                                }
                                this.setState({ isToggleOpen: true }) }}
                            color="link"
                            className="p-0 text-secondary-dark font-weight-bold"
                            id="addAdditionalInfoToggler">
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                            Add another
                        </Button>
                    </div>

                    <Collapse
                        isOpen={this.state.isToggleOpen}>
                        {/* Show when Adding */}
                        <div className="mb-4 py-2 shadow-sm bg-white px-2">
                            <h5 className="text-secondary-dark mb-3 fs-30">Add another</h5>
                            <div className="mb-2">
                                <Input
                                    bsSize="sm"
                                    type="select"
                                    name="selectedCategory"
									value={this.state.selectId}
                                    onChange={(e) => { this.setState({ selectId: e.target.value }) }}>
                                    {OptionElements && Array.isArray(OptionElements) && OptionElements?.length ?
                                        OptionElements.map((item, option_index) => (
                                            <option key={option_index} value={item?.value}>{item?.text}</option>
                                        ))
                                        : null}
                                </Input>

                                <Input
                                    onChange={(e) => { this.setState({ infoText: e.target.value }) }}
                                    className="mt-2"
                                    bsSize="sm"
                                    type="text"
									value={this.state.infoText}
                                    placeholder="Enter additional information" />
                            </div>
                            <div className="text-right">
                                <Button
                                    disabled={(infoText !== '' && selectId !== '') ? false : true}
                                    type="reset"
									onClick={() => {
                                        this.onHandleSubmit();
                                    }}
                                    size="sm" color="primary mr-2">
                                    {'Submit'}
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.setState({ isToggleOpen: false })
                                    }}
                                    size="sm" color="primary ml-0" id="addAdditionalInfoToggler">
                                    {'Cancel'}
                                </Button>
                            </div>
                        </div>
                    </Collapse>
                </CollapseBasic>
            </div>
        )
    }
}
