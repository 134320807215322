import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactHtmlParser from 'react-html-parser';
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Button, Col, DropdownItem, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { post_review_group } from '../../actions/business';
import { get_reviews_listing } from '../../actions/movies';
import { get_reviews_listing as get_product_reviews_listing } from '../../actions/products';
import { get_reviews_listing as get_actor_reviews_listing } from '../../actions/actors';
import { get_reviews_listing as get_accountant_reviews_listing } from '../../actions/accountant';
import { get_reviews_listing as get_dentist_reviews_listing } from '../../actions/dentist';
import { get_reviews_listing as get_doctor_reviews_listing } from '../../actions/doctor';
import { get_reviews_listing as get_lawyer_reviews_listing } from '../../actions/lawyer';
import { get_reviewgroup } from "../../actions/user";
import AllComments from './reviewTabItems/allComments';
import Commentbox from './reviewTabItems/commentBox';
import DisputeReview from './reviewTabItems/disputeReview';
import FilterLinks from './reviewTabItems/filterLinks';
import ReviewMediaSet from './reviewTabItems/reviewMediaSet';
import ReviewRatingSet from './reviewTabItems/reviewRatingSet';
import UserInfoAvatar from './reviewTabItems/userInfoAvatar';
import WasHelpfulAndReactions from './reviewTabItems/wasHelpfulAndReactions';
import CreateReview from './createReview';
import { callApi } from "../../utils/apiCaller";
import { updateFeedReactions } from "../../actions/feeds";

import {
  clear_media_dispatch,
} from "../../actions/myProfiles";

import {
  clear_category,
  clear_subcategory,
  share_by_email
} from "../../actions/business";

import DisputingReview from "./disputeReview/disputingReview";
import DisputedReview from "./disputeReview/disputedReview";
import { setItemDetailLoading } from "../../actions/itemDetail";
import { isEditedReviewId, setEditExistingEmbedMedia, setEditExistingMedia, setEditReviewToggler, setFollowReviewToggler } from "../../actions/shared";
import CreateReviewFollowUp from "./createReviewFollowUp";
import { finalizedMediaInReviewEmbedLinkSetForNewReview, finalizedMediaInReviewMediaSetForEditReview, uploadMediaInReviewMediaSetForEditReview } from "../../actions/reviewMedia";
import { checkTextVisibilty, detectlink } from "../../utils/utilities";
import Reactions from "../molecules/homepage/Reactions";

class ReviewTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      wallReplyText: {},
      wallReplyVisibility: {},
      viewWallUpdatesType: 'all',
      isMasked: {},
      isToggleWriteUp: {},
      isEditEnable: {},
      uploadedFiles: [],
      uploadedFilesFollowUp:[],
      disputeReview: {
        review_id: "",
        reason: "This review is fake",
        comment: "",
        dispute_file: [],
      },
      all_disputes_list: [],
      disputed_tab: false,
      user_names_list: [],
      get_dispute_data: [],
      showExplanation: false,
      disputeReviewId: "",
      showReply: false,

      dispute_files: [],
      dispute_imgepreview: [],

      dispute_reply_text: "",
      days: undefined,
      hours: undefined,
      minutes: undefined,
      seconds: undefined,
      reviewAdded: "",
      reviewAddedUser: "",
      reviewAddedUserImage: "",
      reviewAddedDescription: "",
      reviewAddedFor: "",
      reviewAddedOn: "",
      can_vote: false,
      is_media_dispute: false,
      timer_started: false,
      currentActiveIndex: 0,
      is_administrative_review: false,
      isLoading: false,
      confirmationToggle: false,
      confirmationReason: '',

      showDisputeModal: false,
      reviewDetails: null,
      showDisputedModal: false,
      disputeModal: false,
      currentSortByOption:"",
      isEdit:false,
      reviewType:""
    }
  }

  componentWillReceiveProps(nextProps) {
    // Dispute Review 
    if (this.state.disputeModal && nextProps?.get_dispute_modal_status?.modal === false) {
      this.setState({ disputeModal: false, showDisputeModal: false, reviewDetails: '', showDisputedModal: false })
      // if( nextProps?.get_dispute_modal_status?.refresh ) {
      // }
    }
  }

  setDisputeReviewItem = (item) => {
    this.setState(item);
  }

  renderListingAwardImages = ({ item, active }) => {
    let src = `https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img${active}.png`
    if (item?.award_statistics?.last_month_leader?.planets?.[1] == active) {
      src = `https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Brightest Star Awards/img${active}_active.png`
    }
    return (<li>
      <img src={src} alt="" />
    </li>)
  };


  renderDropdownItem = ({ title, item }) => (<DropdownItem onClick={() => {
    this.filterByItems(item);
    
    this.setState({ currentSortByOption: title });
  }} className="fs-14">{title}</DropdownItem>);
  renderListImagesItem = ({ title }) => (<li>
    <img src={`https://userdatawikireviews.s3.amazonaws.com/images/avatars-hover/${title}.png`} alt={title} />
    {title}</li>);

  renderMoreDetailsAward = ({ title, count }) => (
    <li className="px-1 mb-2">
      <img width="15"
        src={`https://userdatawikireviews.s3.amazonaws.com/images/awards/${title}.png`}
        alt=""
        title={`${title} ${count} times`} />
    </li>
  );

  renderAvatarListingimages = ({ item, active }) => (
    <li>
      <img
        src={item?.award_statistics?.last_month_leader?.gems
          && item?.award_statistics?.last_month_leader?.gems != null
          && item?.award_statistics?.last_month_leader?.gems[1] == active
          ? `https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img${active}_active.png`
          : `https://userdatawikireviews.s3.amazonaws.com/images/listing-awards/Gems/img${active}.png`}
        alt="" />
    </li>
  );

  truncate = (filenameString) => {
    // let split = filenameString.split(".");
    let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
    let extension = filenameString.substr(
      filenameString.lastIndexOf("."),
      filenameString.length - 1
    );
    let partial = filename.substring(filename.length - 3, filename.length);
    filename = filename.substring(0, 15);
    return filename + "..." + partial + extension;
  };

  handleFeedReactions = (reaction, id) => {
    const { listing_type } = this.props;
    const params = {
      type: reaction,
      review: id,
    };
    this.props.updateFeedReactions(params, false, listing_type);
  };

  filterByItems = (type) => {
    this.handleReFetchCallAble(type);
  }

  filterBySearch = (value) => {
    this.setState({
      searchResultsEnabled: value.length ? true: false, 
    })
    this.handleReFetchCallAble(null, value);
  }

  handleWallReplyText = (id, value) => {
    let { wallReplyText } = this.state;
    wallReplyText[id] = value;
    this.setState({ wallReplyText });
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutWallReplyText);
  }

  handleOpenModal = () => {

  }

  checkAndSetIsMasked = (item) => {
    let { isMasked } = this.state;

    if (isMasked[item.id] !== undefined) {
      return isMasked[item.id];
    } else {
      return item.is_masked;
    }
  }

  handleShowSoiler = (e, item) => {
    let { isMasked } = this.state;
    isMasked[item.id] = false;
    this.setState({ isMasked });
  }

  handleSubmitComment = async ({ id, body, parent, edit, remove }) => {
    let url = `/api/reviewdiscussion/?review=${id}`;
    if (["actors", "accountants", "doctors", "dentists", "lawyers"].includes(this.props.listing_type)) {
      url = `/api/professionalreviewdiscussion/?review=${id}`
    }

    let add = true;
    let method = 'POST';
    let messageType = "added";

    if (edit || remove) {
      add = false;
    }

    let data = {};
    data['body'] = body;
    data['media'] = [];
    data['review'] = id;
    if (add) {
      data[id] = body;
    }

    if (edit) {
      method = 'PUT';
      data['parent_id'] = parent;
      messageType = "updated";
    }

    try {
      let response = await callApi(url, method, data);
      if (response && response.code !== 500) {
        toast(`Comment ${messageType} successfully!`, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc"
        });
        this.setState({ wallReplyText: {} }, () => {
          this.handleReFetchCallAble('new');
          return "Hello!"
        });
      }
    } catch (err) {
      console.log(err)
    } finally {
      this.setState({ wallReplyText: {} });
      return true;
    }
  }

  handleReFetchCallAble = (type, value = null) => {
    const { item_detail, listing_type } = this.props;

    let id = item_detail?.id;

    this.props.setItemDetailLoading(true);

    if (listing_type === "movies") {
      this.props.get_reviews_listing(id, type, value);
    }

    if (listing_type === "products") {
      this.props.get_product_reviews_listing(id, type, value);
    }

    if (listing_type === "actors") {
      this.props.get_actor_reviews_listing(id, type, value);
    }

    if (listing_type === "dentists") {
      this.props.get_dentist_reviews_listing(id, type, value);
    }

    if (listing_type === "doctors") {
      this.props.get_doctor_reviews_listing(id, type, value);
    }

    if (listing_type === "accountants") {
      this.props.get_accountant_reviews_listing(id, type, value);
    }

    if (listing_type === "lawyers") {
      this.props.get_lawyer_reviews_listing(id, type, value);
    }
  }

  toggleWriteFollowUp = ({ isTrue, itemId }) => {
    let { isToggleWriteUp } = this.state;
    if (!isTrue) {
      isToggleWriteUp = {};
      this.setState({ isToggleWriteUp });
    } else {
      const tempObj = {}
      tempObj[itemId] = isTrue;
      this.setState({ isToggleWriteUp:tempObj });
    }    
  }

  toggleEditEnable = (item) => {
    let { isEditEnable } = this.state;
    let tempObj = {}
    this.setState(prevState => ({
      isEdit: !prevState.isEdit
    }));
    if (isEditEnable[item.id]) {
      tempObj[item.id] = !tempObj[item.id];
      this.props.setEditReviewToggler(false);
      this.props.isEditedReviewId(null);
      this.props.setEditExistingMedia([])
      this.props.setEditExistingEmbedMedia([])      
    } else {
      tempObj[item.id] = true;
      this.props.setEditReviewToggler(true);
      this.props.isEditedReviewId(item.id);

      this.props.uploadMediaInReviewMediaSetForEditReview(item?.reviewmedia_set || []);
      this.props.finalizedMediaInReviewMediaSetForEditReview(item?.reviewmedia_set || [])
      if(["accountants", "doctors", "dentists", "lawyers", "actors"].includes(this.props?.listing_type)){        
        if(Array.isArray(item?.prof_embeded_video?.body)){
          this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(item?.prof_embeded_video?.body || [])
        }        
      }else{
        if(Array.isArray(item?.embeded_video?.body)){
        this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(item?.embeded_video?.body || [])
        }
      }
      
      
      this.props.setEditExistingMedia(item.reviewmedia_set)
      // if(!!item.embeded_video?.body) this.props.setEditExistingEmbedMedia(item.embeded_video?.body)      
    }
    this.setState({ isEditEnable:tempObj });
  }

  closeToggleEditEnable = () => {
    this.setState({ isEditEnable: {} });
    this.props.setEditReviewToggler(false);
  }

  componentDidUpdate() {
    let nextProps = this.props;
    let next_uploaded_files = nextProps?.uploadedMediaSet;
    let next_uploaded_files_follow = nextProps?.uploadedMediaSetFollow;
    let uploadedFiles = this.state.uploadedFiles;
    let uploadedFilesFollowUp = this.state.uploadedFilesFollowUp

    if (next_uploaded_files?.length !== uploadedFiles?.length) {
      this.setState({ uploadedFiles: next_uploaded_files });
    }
    if (next_uploaded_files_follow?.length !== uploadedFilesFollowUp?.length) {
      this.setState({ uploadedFilesFollowUp: next_uploaded_files_follow });
    }
  }

  resetStates = () => {
    this.setState({
      disputeReview: {
        review_id: "",
        reason: "This review is fake",
        comment: "",
        dispute_file: [],
      },
      is_media_dispute: false,
      reviewAdded: "",
      reviewAddedUser: "",
      reviewAddedUserImage: "",
      reviewAddedDescription: "",
      reviewAddedFor: "",
      reviewAddedOn: "",
      disputeThisReviewModalToggle: false,
      reviewId: '',
      dispute_files: [],
      dispute_imgepreview: [],
      imagePreview_Url: '',
    });
  }

  clearMediaDispatch = () => {
    this.setState(
      {
        latestUploadedData: {},
        uploadCaptions: {},
        selectedFilesPreview: [],
        files: [],
        taxonomycategoryId: "",
        fetchTaxonomyListingImages: {},
        fetchTaxonomyListingVideos: {},
        captionData: "",
        uploadedId: {},
        cattaxListing: {},
        albumCategoryId: "",
      },
      () => {
        this.props.clear_media_dispatch();
        this.props.clear_category();
        this.props.clear_subcategory();
      }
    );
  };

  handleCloseAndFetchReview = () => {
    let { mediaSetReview } = this.state;
    this.setState(
      {
        uploadMediaModal: !this.state.uploadMediaModal,
        fileReviewStatus: true,
        mediaSet: mediaSetReview,
        taxonomycategoryId: "",
        fetchTaxonomyListingImages: {},
        fetchTaxonomyListingVideos: {},
        captionData: "",
        uploadedId: {},
      },
      () => {
        this.clearMediaDispatch();
        this.props.fetch_media_data("image", "", 1, true, true);
        this.props.fetch_media_data("video", "", 1, true, true);
      }
    );
  };


  handleHeatedDiscussion = (feed) => {
    this.setState({ showDisputedModal: true, reviewDetails: feed, disputeModal: true })
    return
  };

  checkIfLoggedIn = () => {
    const authId = localStorage.getItem('profileId');
    const { my_profile } = this.props;
    if (my_profile && my_profile.id && authId && (authId == my_profile.id)) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { reviews,embedMediaFollowUp, item_detail, listing_type, my_profile, toggleConfirmModal, toggleEnableFileUpload, overallRatingVote, viewEditReviewStep, onPublishReview, uniqueDeleteKey, uniqueDeleteCommentKey } = this.props;
    let { wallReplyText,
      wallReplyVisibility,
      isEditEnable,
      uploadedFiles,
      uploadedFilesFollowUp,
      showDisputeModal,
      reviewDetails,
      showDisputedModal
    } = this.state;
    const categoryId = this.props?.categoryId

    let checkDraft
    
    if(["actors", "accountants", "doctors", "dentists", "lawyers"].includes(listing_type)){
      checkDraft = "is_draft_p"
    }else{
      checkDraft = "is_draft"
    }
    return (
      <div>

        { showDisputeModal ?
          <DisputingReview review={reviewDetails} source="NonActioned" />
          :
          ''
        }
        { showDisputedModal ?
          <DisputedReview review={reviewDetails} source="NonActioned" />
          :
          ''
        }

        <FilterLinks
          filterByItems={this.filterByItems}
          filterBySearch={this.filterBySearch}
          searchResultsEnabled={this.state.searchResultsEnabled}
          renderDropdownItem={this.renderDropdownItem}
          currentSortByOption={this.state.currentSortByOption}
          listing_type_professional={["actors", "accountants", "doctors", "dentists", "lawyers"].includes(listing_type)}
        />
        {!['Most Relevant','Featured Reviews','Top Contributions'].includes(this.state.currentSortByOption) ? <>  {reviews && Array.isArray(reviews) && reviews?.length ?
          reviews.map((item, index) => (
            <div>
              {/* dispute review code --start*/}
               {item?.is_review_flag && (
                <div className="bg-warning position-relative d-flex justify-content-between text-dark p-2 fs-12">
                  <div><span className="text-white fs-14"><strong>Not Recommended</strong>{" "}Review</span></div>
                  <div className="d-flex container-down-arrow flex-wrap mx-n2">

                    <div className="fs-12 px-2 ml-auto text-black">
                      
                      <p className="mb-0 d-inline-block">
                        This review is currently being Disputed.
                                 </p>{" "}
                      <span
                        className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                        role="button"
                        onClick={() => this.setState({ watchHowDisputeReviewWorksModalToggle: true })}
                      >
                        <span>Learn More &nbsp;
                        <FontAwesomeIcon icon="arrow-right" />

                        </span>
                        <div
                          className="on-hover-appear invisble-part"
                          style={{ bottom: "calc(100% + 10px)" }}
                        >
                          <div className="tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                            <div className="fs-14 ff-base">
                              {/* Please watch the video to  */}
                                           See how Review Disputes work
                                         </div>
                            <div className="p-2">
                              <Row className="justify-content-between align-items-center">
                                <Col
                                  xs="12"
                                  className="d-flex justify-content-center align-items-center"
                                >
                                  <div
                                    className="video-holder play-icon d-inline-block mx-2"
                                    role="button"
                                    onClick={() =>
                                      this.setState({
                                        watchHowDisputeReviewWorksModalToggle: true,
                                      })
                                    }
                                  >
                                    <img
                                      height="80"
                                      src={require("../../assets/images/dispute_jury.webp")}
                                      alt="dispute"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>

                        </div>
                      </span>
                      <span>{" "} {" "}</span>
                      <span
                        className="text-reset font-weight-bold hoverable d-inline-block hover-underline"
                        role="button"
                        onClick={() => this.handleHeatedDiscussion(item)}
                      >
                        {(item?.flag?.user?.email === my_profile?.user?.email) ?
                          <span className="flashing">
                            7days Period for Parties to Upload their Response
                            {item?.flag?.discussion ?
                              item?.flag?.owner_discussion ?
                                ' ( BusinessOwner Responded )'
                                : ' ( Community Voting )'
                              :
                              ''}
                          </span>
                          :
                          <span className="flashing">Help & Cast your vote 
                           {/* <FontAwesomeIcon icon="arrow-right" />   */}
                          {/* <span className="flashing">View Dispute */}
                          </span>
                        }
                        <div
                          className="on-hover-appear"
                          style={{ bottom: "calc(100% + 10px)" }}
                        >
                          <div className="help-community-tooltip tooltiptext auto-width px-3 py-2 shadow text-secondary-dark ff-alt font-weight-normal">
                            Help the community and vote on this review
                                     </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* dispute review code --end*/}
              
              {/* original review for follow up review - start */}
                {(!!!item?.parent_review) && (!!item?.child_review?.review || item?.child_review?.review==="") && (
                   <div  style={{ paddingTop: '25px' }} className={"bg-white p-3 fs-14 text-dark"}> 
                  <Row >
                    <Col xs={3}>
                    {/* user-info avatars */}
                    <UserInfoAvatar
                      item={item}
                      renderListImagesItem={this.renderListImagesItem}
                      renderAvatarListingimages={this.renderAvatarListingimages}
                      renderListingAwardImages={this.renderListingAwardImages}
                      renderMoreDetailsAward={this.renderMoreDetailsAward}
                      reviewStats={this.props.my_profile?.award_rating_statistics?.stats}
                      listing_type={listing_type}
                    />
                  </Col>
                    <Col xs={9}>
                      <React.Fragment>
                        {/* Review Detail */}
                        <ReviewRatingSet
                          share_by_email={this.props.share_by_email}
                          item={{
                            ...item?.child_review,
                            associated_to: item.associated_to,
                            listing_type: item.listing_type || item.associated_to?.content_type?.label
                          }}
                          created_by={item?.created_by}
                          item_detail={item_detail}
                          listing_type_professional={["actors", "accountants", "doctors", "dentists", "lawyers"].includes(listing_type)}
                          handleReFetchCallAble={this.handleReFetchCallAble}
                          listing_type={listing_type}
                        />

                        <div className="position-relative">
                          <div className="ff-alt my-4" data-blurr={this.checkAndSetIsMasked(item?.child_review)}>
                            {
                              item?.child_review?.review ?
                                <>
                                  <i className="icon-comma-left"></i>
                                  <span className="comment-body-renderer" filter-value={item?.child_review?.review}>
                                    {ReactHtmlParser(detectlink(item?.child_review?.review))}
                                  </span>
                                  <i className="icon-comma-right"></i>
                                </>
                                : ""
                            }
                          </div>

                          {/* Spoiler Alert */}
                          {this.checkAndSetIsMasked(item?.child_review) && (
                            <div>
                              <div className="bg-white font-weight-bold text-darker text-center p-3 spoiler-alert _type-review">
                                {'This review contains spoilers.'}
                                <div>
                                  <span
                                    className="text-primary cursor-pointer"
                                    onClick={(e) => this.handleShowSoiler(e, item?.child_review)}>
                                    {'Click here to reveal.'}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <ReviewMediaSet
                          item={item?.child_review}
                          truncate={this.truncate}
                          handleOpenModal={this.handleOpenModal}
                        />
                        <div className="quick-reactions d-flex flex-wrap align-items-end mb-2">
                          {item?.child_review?.review_group_count && item?.child_review?.review_group_status ? (
                            <Reactions
                              id={item?.child_review?.id}
                              counts={item?.child_review?.review_group_count}
                              statuses={item?.child_review?.review_group_status}
                              listing_type={listing_type}
                              onClick={(reaction) => this.handleFeedReactions(reaction, item?.child_review.id)}
                              get_reveiewData={this.props.get_reveiewData}
                            />
                          ) : null}
                        </div>
                        <div className="d-flex justify-content-between">
                        <div>
                          <WasHelpfulAndReactions
                            item={item?.child_review}
                            my_profile={my_profile}
                            handleFeedReactions={this.handleFeedReactions}
                            listing_type={listing_type}
                            post_review_group={this.props.post_review_group}
                            get_reviewgroup={this.props.get_reviewgroup}
                            handleReFetchCallAble={this.handleReFetchCallAble}
                          />
                          </div>
                          {item?.created_by?.user?.username === my_profile?.user?.username && (
                          <div>
                            <Button
                              color="link"
                              onClick={() => {
                                if (!this.checkIfLoggedIn()) {
                                  this.props.forceToggleLoginModal();
                                  return false;
                                }
                                this.toggleEditEnable(item?.child_review)
                              }}
                              className="font-weight-bold mx-0 px-1 text-dark btn btn-link">
                              {'edit'}
                            </Button>



                            <Button
                              color="link"
                              onClick={() => {
                                if (!this.checkIfLoggedIn()) {
                                  this.props.forceToggleLoginModal();
                                  return false;
                                }
                                toggleConfirmModal({
                                  text: 'Are you sure you want to delete this review?',
                                  uniqueKey: uniqueDeleteKey,
                                  id: item?.child_review.id
                                })
                              }}
                              className="font-weight-bold mx-0 px-1 btn btn-link"
                              style={{ color: "rgb(42, 100, 150)" }}
                            >
                              {'delete'}
                            </Button>
                          </div>
                          )}
                        </div>
                       
                        <div className="text-right">
                      
                        
                        {isEditEnable[item?.child_review?.id] && (
                      <Modal
                        isOpen={isEditEnable[item?.child_review?.id]}
                        toggle={() => { this.toggleEditEnable(item?.child_review) }}>
                        <ModalBody className="bg-transparent">
                          <CreateReview
                            avarageTrue={isEditEnable[item?.child_review?.id]}
                            listing_type={listing_type}
                            toggleWriteFollowUp={this.toggleWriteFollowUp}
                            itemDetail={item_detail}
                            isEditItem={item?.child_review}
                            reviews={reviews}                           
                            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                              toggleEnableFileUpload(hideEmbed, galleryTypeUpload, false,false);                               
                          }}
                            overallRatingVote={overallRatingVote}
                            viewEditReviewStep={viewEditReviewStep}
                            uploadedMediaSet={uploadedFiles}
                            removeUploadedMediaSet={this.props.removeUploadedMediaSet}
                            removeUploadedMediaSetFollow={this.props.removeUploadedMediaSetFollow}
                            onPublishReview={onPublishReview}
                            closeToggleEditEnable={this.closeToggleEditEnable}
                            my_review_count={my_profile?.review_statistics?.count}
                            categoryId={item_detail?.id}
                            isEditEnable={this.state.isEdit}
                          />
                        </ModalBody>
                      </Modal>
                    )}
                      
                          
                   

                    </div>

                       
                      </React.Fragment>
                    </Col>
                  </Row>
                  </div>
                )}
              {/* original review for follow up review - end */}

             
              {/* Type Review */}
              <div className={(!!item?.child_review?.review || item?.child_review?.review==="")? "review-bg p-3 fs-14 text-dark mb-3" : "bg-white p-3 fs-14 text-dark mb-3"} style={{borderLeft:'10px solid white', borderRight:'10px solid white', borderBottom:"10px solid white" }}>            
                <Row>
                  <Col xs={3}>
                    {/* user-info avatars */}
                   {!!!(item?.child_review?.review || item?.child_review?.review==="") &&  <UserInfoAvatar
                      item={item}
                      renderListImagesItem={this.renderListImagesItem}
                      renderAvatarListingimages={this.renderAvatarListingimages}
                      renderListingAwardImages={this.renderListingAwardImages}
                      renderMoreDetailsAward={this.renderMoreDetailsAward}
                      reviewStats={item?.award_rating_statistics?.stats}
                      listing_type={listing_type}
                    />}
                  </Col>
                  <Col xs={9}>
                    <React.Fragment>
                      {/* Review Detail */}
                      <ReviewRatingSet
                        share_by_email={this.props.share_by_email}
                        item={item}
                        get_dispute_data={this.props.get_dispute_data}
                        add_dispute_review={this.props.add_dispute_review}
                        clear_media_dispatch={this.props.clear_media_dispatch}
                        clear_category={this.props.clear_category}
                        clear_subcategory={this.props.clear_subcategory}
                        setDisputeReviewItem={this.setDisputeReviewItem}
                        item_detail={item_detail}
                        listing_type_professional={["actors", "accountants", "doctors", "dentists", "lawyers"].includes(listing_type)}
                        handleReFetchCallAble={this.handleReFetchCallAble}
                        listing_type={listing_type}
                        created_by={item?.created_by}
                      />
                      {/* Dispute review block */}
                      <DisputeReview />

                      <div className="position-relative">
                        <div className="ff-alt my-4" data-blurr={this.checkAndSetIsMasked(item)} hidden={checkTextVisibilty(item?.review || item?.professionalreview)}>
                          {
                            (item?.professionalreview || item.review)
                              ?
                              <div>
                                <i className="icon-comma-left" ></i>
                                <span className="comment-body-renderer" filter-value={item.professionalreview || item.review}>
                                  {ReactHtmlParser(detectlink(item.professionalreview || item.review))}
                                </span>
                                <i className="icon-comma-right"></i>
                              </div>
                              : ""
                          }
                        </div>
 
                        {/* Spoiler Alert */}
                        {this.checkAndSetIsMasked(item) && (
                          <div>
                            <div className="bg-white font-weight-bold text-darker text-center p-3 spoiler-alert _type-review">
                              {'This review contains spoilers.'}
                              <div>
                                <span className="text-primary cursor-pointer" onClick={(e) => this.handleShowSoiler(e, item)}>
                                  {'Click here to reveal.'}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <ReviewMediaSet
                        item={item}
                        truncate={this.truncate}
                        handleOpenModal={this.handleOpenModal}                       
                      />
                      <div className="quick-reactions d-flex flex-wrap align-items-end mb-2">
                        {item?.review_group_count && item?.review_group_status ? (
                          <Reactions
                            id={item?.id}
                            counts={item?.review_group_count}
                            statuses={item?.review_group_status}
                            listing_type={listing_type}
                            onClick={(reaction) => this.handleFeedReactions(reaction, item.id)}
                            get_reveiewData={this.props.get_reveiewData}
                          />
                        ) : null}
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <WasHelpfulAndReactions
                            item={item}
                            my_profile={my_profile}
                            handleFeedReactions={this.handleFeedReactions}
                            listing_type={listing_type}
                            post_review_group={this.props.post_review_group}
                            handleReFetchCallAble={this.handleReFetchCallAble}
                          />
                        </div>
                        <div >
                          {item?.created_by?.user?.username === my_profile?.user?.username && (
                            <Button
                              color="link"
                              onClick={() => {
                                if (!this.checkIfLoggedIn()) {
                                  this.props.forceToggleLoginModal();
                                  return false;
                                }
                                this.toggleEditEnable(item)
                              }}
                              className="font-weight-bold mx-0 px-1 text-dark btn btn-link">
                              {'edit'}
                            </Button>
                          )}

                          {item?.created_by?.user?.username === my_profile?.user?.username && (
                            <Button
                              color="link"
                              onClick={() => {
                                if (!this.checkIfLoggedIn()) {
                                  this.props.forceToggleLoginModal();
                                  return false;
                                }
                                toggleConfirmModal({
                                  text: 'Are you sure you want to delete this review?',
                                  uniqueKey: uniqueDeleteKey,
                                  id: item.id
                                })
                              }}
                              className="font-weight-bold mx-0 px-1 btn btn-link"
                              style={{ color: "rgb(42, 100, 150)" }}
                            >
                              {'delete'}
                            </Button>
                          )}
                          {item?.created_by?.user?.username === my_profile?.user?.username ? <>
                            {!item?.[checkDraft] && <Button
                              onClick={() => {
                                if (!this.checkIfLoggedIn()) {
                                  this.props.forceToggleLoginModal();
                                  return false;
                                }
                                this.toggleWriteFollowUp({ isTrue: true, itemId: item.id })
                                this.setState({ reviewType: "followUp" })
                                this.props.setFollowReviewToggler(true);
                              }}
                              hidden={(!!item?.child_review?.review || item?.child_review?.review === "")}
                              color="link" className="text-primary font-weight-bold px-0" style={{ marginLeft: '5px' }}>
                              {'write follow up'}
                            </Button>}
                          </>
                            : <>{!item?.[checkDraft] &&
                              <Button
                                onClick={() => {
                                  if (!this.checkIfLoggedIn()) {
                                    this.props.forceToggleLoginModal();
                                    return false;
                                  }
                                  this.toggleWriteFollowUp({ isTrue: true, itemId: item.id })
                                  this.setState({ reviewType: "ownReview" })
                                  this.props.setFollowReviewToggler(false);
                                }}
                                hidden={item_detail?.is_logged_user_review}
                                color="link" className="text-primary font-weight-bold px-0" style={{ marginLeft: '5px' }}>
                                {'write your own review'}
                              </Button>
                            }</>


                          }

                        </div>
                      </div>
                      
                    </React.Fragment>

                    {/* {'enable after click on edit'} */}
                   
                    {isEditEnable[item.id] && (
                      <Modal
                        isOpen={isEditEnable[item.id]}
                        toggle={() => { this.toggleEditEnable(item) }}>
                        <ModalBody className="bg-transparent">
                          <CreateReview
                            avarageTrue={isEditEnable[item.id]}
                            listing_type={listing_type}
                            toggleWriteFollowUp={this.toggleWriteFollowUp}
                            itemDetail={item_detail}
                            isEditItem={item}
                            reviews={reviews}                           
                            toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                              toggleEnableFileUpload(hideEmbed, galleryTypeUpload, false,false);                               
                          }}
                            overallRatingVote={overallRatingVote}
                            viewEditReviewStep={viewEditReviewStep}
                            uploadedMediaSet={uploadedFiles}
                            removeUploadedMediaSet={this.props.removeUploadedMediaSet}
                            removeUploadedMediaSetFollow={this.props.removeUploadedMediaSetFollow}
                            onPublishReview={onPublishReview}
                            closeToggleEditEnable={this.closeToggleEditEnable}
                            my_review_count={my_profile?.review_statistics?.count}
                            categoryId={item_detail?.id}
                            isEditEnable={this.state.isEdit}
                          />
                        </ModalBody>
                      </Modal>
                    )}

                    
                    <AllComments
                  uniqueDeleteCommentKey={uniqueDeleteCommentKey}
                  item={item}
                  toggleWallReply={this.toggleWallReply}
                  wallReplyText={wallReplyText}
                  listing_type={listing_type}
                  wallReplyVisibility={wallReplyVisibility}
                  handleWallReplyText={this.handleWallReplyText}
                  toggleConfirmModal={this.props.toggleConfirmModal}
                  handleSubmitComment={this.handleSubmitComment}
                  handleReFetchCallAble={this.handleReFetchCallAble}
                  my_profile = {this.props.my_profile}
                />
                <Commentbox
                  item={item}
                  listing_type={listing_type}
                  current_profile_picture={my_profile?.current_profile_file}
                  wallReplyText={wallReplyText}
                  handleWallReplyText={this.handleWallReplyText}
                  handleSubmitComment={this.handleSubmitComment}
                  current_profile_file={my_profile?.current_profile_file}
                  forceToggleLoginModal={this.props.forceToggleLoginModal}
                  my_profile_data={this.props.my_profile}
                  bgColor={(!!item?.child_review?.review || item?.child_review?.review==="") ? "review-bg" : ""}
                />
                  </Col>
                  
                </Row>   
              </div>
              {(!!item?.child_review?.review || item?.child_review?.review==="") && <div className={"bg-white fs-14 text-dark mb-3"}>
                <Row>
                  <Col xs={12} >
                    {/* All Comments */}
                    <div className="pl-3 pr-3">
                    <AllComments
                      uniqueDeleteCommentKey={uniqueDeleteCommentKey}
                      item={item?.child_review}
                      toggleWallReply={this.toggleWallReply}
                      wallReplyText={wallReplyText}
                      listing_type={listing_type}
                      wallReplyVisibility={wallReplyVisibility}
                      handleWallReplyText={this.handleWallReplyText}
                      toggleConfirmModal={this.props.toggleConfirmModal}
                      handleSubmitComment={this.handleSubmitComment}
                      handleReFetchCallAble={this.handleReFetchCallAble}
                      my_profile = {this.props.my_profile}
                    />
                    </div>
                    

                    {/* Comment box */}
                    <Commentbox
                      item={item?.child_review}
                      wallReplyText={wallReplyText}
                      handleWallReplyText={this.handleWallReplyText}
                      handleSubmitComment={this.handleSubmitComment}
                      forceToggleLoginModal={this.props.forceToggleLoginModal}
                      my_profile_data={this.props.my_profile}
                      current_profile_picture={my_profile?.current_profile_file}
                      current_profile_file={my_profile?.current_profile_file}
                    // bgColor={"review-bg"}
                    />                    
                  </Col>
                </Row>
              </div>}

              {/* {'enable after click on write-follow-up'} */}   
              {this.state.isToggleWriteUp[item?.id] && this.state.reviewType==="followUp" && (
                <CreateReviewFollowUp
                  avarageTrue={false}
                  listing_type={listing_type}
                  toggleWriteFollowUp={this.toggleWriteFollowUp}
                  itemDetail={item_detail}
                  reviews={reviews}
                  item={item}
                  isWriteFollowUp={true}                  
                  toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                    toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true,true);                               
                }}
                  overallRatingVote={overallRatingVote}
                  viewEditReviewStep={viewEditReviewStep}
                  uploadedMediaSet={uploadedFilesFollowUp}
                  embedMediaFollowUp={embedMediaFollowUp}
                  removeUploadedMediaSet={this.props.removeUploadedMediaSet}
                  removeUploadedMediaSetFollow={this.props.removeUploadedMediaSetFollow}
                  onPublishReview={onPublishReview}
                  my_review_count={my_profile?.review_statistics?.count}
                  categoryId={item_detail?.id}
                  type="followUp"
                />
              )}  
              {this.state.isToggleWriteUp[item?.id] && this.state.reviewType==="ownReview" && (
                <CreateReview
                  avarageTrue={false}
                  listing_type={listing_type}
                  toggleWriteFollowUp={this.toggleWriteFollowUp}
                  itemDetail={item_detail}
                  reviews={reviews}
                  item={item}
                  isWriteFollowUp={true}                  
                  toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                    toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true,false);                               
                }}
                  overallRatingVote={overallRatingVote}
                  viewEditReviewStep={viewEditReviewStep}
                  uploadedMediaSet={uploadedFilesFollowUp}
                  embedMediaFollowUp={embedMediaFollowUp}
                  removeUploadedMediaSet={this.props.removeUploadedMediaSet}
                  removeUploadedMediaSetFollow={this.props.removeUploadedMediaSetFollow}
                  onPublishReview={onPublishReview}
                  my_review_count={my_profile?.review_statistics?.count}
                  categoryId={item_detail?.id}
                  type="ownReview"
                />
              )} 
            </div>
          ))
          : <div className="bg-white p-3">
            {
              this.props.item_details_loading ?
                <h2 className="text-secondary-dark">Loading Reviews...</h2>
                :
                <h2 className="text-secondary-dark">No reviews to display</h2>
            }
          </div>}</>: <div className="bg-white p-3">           
                <h2 className="text-secondary-dark">Coming soon...</h2>                
          </div>}
      
          

        {/* SEE HOW REVIEW DISPUTES WORK */}
        <Modal
          isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
          toggle={() =>
            this.setState({
              watchHowDisputeReviewWorksModalToggle: !this.state
                .watchHowDisputeReviewWorksModalToggle,
            })
          }
        >
          <ModalHeader
            className="text-uppercase px-0"
            toggle={() =>
              this.setState({
                watchHowDisputeReviewWorksModalToggle: !this.state
                  .watchHowDisputeReviewWorksModalToggle,
              })
            }
          >
            SEE HOW REVIEW DISPUTES WORK
                </ModalHeader>
          <ModalBody className="p-0 bg-transparent">
            <div className="text-center">
              <video controls autoPlay width="100%" seekable>
                <source
                  src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                  type="video/mp4"
                />
                        Your browser does not support the video tag.
                    </video>
            </div>
          </ModalBody>
        </Modal>

      </div>
    )
  }
}

const mapState = (state) => ({
  my_profile: state.user.my_profile,
  get_dispute_modal_status: state.dispute.get_dispute_modal_status,
  item_details_loading:state.item_detail.item_details_loading
});

const mapProps = (dispatch) => ({
  get_reviewgroup: (assetType, postId, type) => dispatch(get_reviewgroup(assetType, postId, type)),
  updateFeedReactions: (params, feedparams, type) => dispatch(updateFeedReactions(params, feedparams, type)),
  post_review_group: (id, data, type) => dispatch(post_review_group(id, data, type)),
  get_reviews_listing: (id, type, searchValue) => dispatch(get_reviews_listing(id, type, searchValue)),
  get_product_reviews_listing: (id, type, searchValue) => dispatch(get_product_reviews_listing(id, type, searchValue)),
  get_actor_reviews_listing: (id, type, searchValue) => dispatch(get_actor_reviews_listing(id, type, searchValue)),
  get_dentist_reviews_listing: (id, type, searchValue) => dispatch(get_dentist_reviews_listing(id, type, searchValue)),
  get_doctor_reviews_listing: (id, type, searchValue) => dispatch(get_doctor_reviews_listing(id, type, searchValue)),
  get_lawyer_reviews_listing: (id, type, searchValue) => dispatch(get_lawyer_reviews_listing(id, type, searchValue)),
  get_accountant_reviews_listing: (id, type, searchValue) => dispatch(get_accountant_reviews_listing(id, type, searchValue)),
  clear_media_dispatch: () => dispatch(clear_media_dispatch()),
  clear_category: () => dispatch(clear_category()),
  clear_subcategory: () => dispatch(clear_subcategory()),
  share_by_email: (url, data) => dispatch(share_by_email(url, data)),
  setItemDetailLoading:(data)=>dispatch(setItemDetailLoading(data)),
  setEditExistingMedia:(data)=>dispatch(setEditExistingMedia(data)),
  setEditExistingEmbedMedia:(data)=>dispatch(setEditExistingEmbedMedia(data)),
  setEditReviewToggler:(data)=>dispatch(setEditReviewToggler(data)),
  isEditedReviewId:(data)=>dispatch(isEditedReviewId(data)),
  uploadMediaInReviewMediaSetForEditReview:(data)=>dispatch(uploadMediaInReviewMediaSetForEditReview(data)),
  finalizedMediaInReviewMediaSetForEditReview:(data)=>dispatch(finalizedMediaInReviewMediaSetForEditReview(data)),
  finalizedMediaInReviewEmbedLinkSetForNewReview:(data)=>dispatch(finalizedMediaInReviewEmbedLinkSetForNewReview(data)),
  setFollowReviewToggler:(data)=>dispatch(setFollowReviewToggler(data)),

  
})


export default connect(mapState, mapProps)(ReviewTab);