import { callApi } from "../utils/apiCaller";
import { sortAsc } from "../utils/utilities";
import { getQueryString, checkIfHaveParams } from '../utils/validation';

const FETCH_COLLAGE_ITEMS = 'FETCH_COLLAGE_ITEMS';
const FETCH_MOVIES_CATEGORIES = 'FETCH_MOVIES_CATEGORIES';
const FETCH_MOVIES_LIST = 'FETCH_MOVIES_LIST';
const FETCH_MOVIES_COLLAGE_SUB_CATEGORY = 'FETCH_MOVIES_COLLAGE_SUB_CATEGORY';
const FETCH_PRODUCT_LIST = 'FETCH_PRODUCT_LIST';
const FETCH_CITIES = 'FETCH_CITIES';
const FETCH_CURRENT_BOXOFFICE_DATA = 'FETCH_CURRENT_BOXOFFICE_DATA';
const SET_MOVIES_LOADER = 'SET_MOVIES_LOADER';
const RESET_MOVIES = 'RESET_MOVIES';

export const set_movies_loader = (data) => ({ type: SET_MOVIES_LOADER, payload: data });

export const reset_movies_list = () => ({ type: RESET_MOVIES });

const common_fetch = ({ url, method, data, dispatch_function }) => {
    if (data === undefined) {
        data = {};
    }
    return (dispatch) => {
        const urlThatAllowedLoaders = ['/api/movies/', `/api/friendspick/`, '/api/search/']
        const allowLoader = urlThatAllowedLoaders.some(item => url.includes(item));
        allowLoader && dispatch(set_movies_loader(true));

        callApi(url, method, data).then((response) => {
            allowLoader && dispatch(set_movies_loader(false));
            if (response && response.code !== 400 && response.code !== 500) {
                dispatch(dispatch_function(response));
            }
        });
    };
};

export const fetch_newtrends_dispatch = (data) => ({ type: FETCH_COLLAGE_ITEMS, payload: data, });
export const fetch_newtrends = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/newtrends/`, data }),
        method: "GET",
        dispatch_function: fetch_newtrends_dispatch
    })
);

export const fetch_movies_list_dispatch = (data) => ({ type: FETCH_MOVIES_LIST, payload: data, });
export const fetch_movies_list = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/movies/`, data }),
        method: "GET",
        dispatch_function: fetch_movies_list_dispatch
    })
);

export const fetch_boxoffice_list = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/boxoffice/`, data }),
        method: "GET",
        dispatch_function: fetch_movies_list_dispatch
    })
);


export const fetch_movies_categories_dispatch = (data) => {
    if (data?.length && data[0].children) {
        data[0].children.sort(sortAsc);
    }
    return ({ type: FETCH_MOVIES_CATEGORIES, payload: data, })
};

export const fetch_movies_categories = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/taxonomy/`, data }),
        method: "GET",
        dispatch_function: fetch_movies_categories_dispatch
    })
);

export const collage_items_dispatch = (data) => ({ type: FETCH_COLLAGE_ITEMS, payload: data, });
export const collage_sub_category_dispatch = (data) => ({ type: FETCH_MOVIES_COLLAGE_SUB_CATEGORY, payload: data, });
export const product_list_dispatch = (data) => ({ type: FETCH_PRODUCT_LIST, payload: data, });
export const fetch_movie_collage_items = (...data) => {
    return common_fetch({
        url: getQueryString({ url: `/api/search/`, data }),
        method: "GET",
        dispatch_function:
            checkIfHaveParams({ data, param: 'showReview' }) ? product_list_dispatch :
                checkIfHaveParams({ data, param: 'trendingType' }) ? collage_sub_category_dispatch :
                    collage_items_dispatch
    });
};

export const clear_collage_sub_categories = () => ((dispatch) => (dispatch({ type: FETCH_MOVIES_COLLAGE_SUB_CATEGORY, payload: null })));

export const fetch_cities_dispatch = (data) => ({ type: FETCH_CITIES, payload: data, });
export const fetch_cities = (data) => (
    common_fetch({
        url: getQueryString({ url: `/api/searchcities/?lat=${data.lat}&lng=${data.lng}&type=undefined` }),
        method: "GET",
        dispatch_function: fetch_cities_dispatch
    })
);

export const fetch_friendspick_movies_list = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/friendspick/`, data }),
        method: "GET",
        dispatch_function: fetch_movies_list_dispatch
    })
);

export const fetch_recommended_movies_list = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/search/`, data }),
        method: "GET",
        dispatch_function: fetch_movies_list_dispatch
    })
);

export const fetch_filtered_movies = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/search/`, data }),
        method: "GET",
        dispatch_function: fetch_movies_list_dispatch
    })
);

export const fetch_current_boxoffice_dispatch = (data) => ({ type: FETCH_CURRENT_BOXOFFICE_DATA, payload: data, });

export const fetch_current_boxoffice = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/boxoffice/`, data }),
        method: "GET",
        dispatch_function: fetch_current_boxoffice_dispatch
    })
);

