import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import moment from "moment";

import {
  upload_media,
  initFetchMovieSubCategories,
  search_moviesMembers,
  get_patrol_crp,
  clearvoteList
} from "../../../actions/footer";

import {
  add_movie
} from "../../../actions/movies";
import {get_type_detail} from '../../../actions/itemDetail';

import { callApi } from "../../../utils/apiCaller";

class VoteMovieModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name: "",
        loaded: true,
        drawer: true,
        voteListing: [],
        flagListing: [],
        name: '',
        category_id: '',
        subcategory_id: '',
        typeMethod: "",
	    rateType:"",
		type:"flaglist",
		listingType:"Movies",
		about:null
    };
  }

  componentWillReceiveProps(nextProps) {
	  let {loaded} = this.state;
    if (nextProps.fetch_save_movie_response) {
      if (nextProps.fetch_save_movie_response && nextProps.fetch_save_movie_response.id && nextProps.fetch_save_movie_response.slug) {
        let slug = null;
        let movieId = "";
        if (nextProps.fetch_save_movie_response.id != "") {
          movieId = nextProps.fetch_save_movie_response.id;
        }
        if (nextProps.fetch_save_movie_response.slug != "") {
          slug = nextProps.fetch_save_movie_response.slug;
        }
        this.redirectto(movieId, slug);

      }
    }
	
	if(nextProps.ratingType && nextProps.ratingType != ""){
		this.setState({rateType:nextProps.ratingType})
	}
	if(nextProps.getVoteListing && nextProps.getVoteListing.results && Array.isArray(nextProps.getVoteListing.results) && nextProps.getVoteListing.results.length > 0){
		this.setState({voteListing:nextProps.getVoteListing.results},() =>{
		});
	}
	if(nextProps.getFlagListing && nextProps.getFlagListing.results && Array.isArray(nextProps.getFlagListing.results) && nextProps.getFlagListing.results.length > 0){
		this.setState({flagListing:nextProps.getFlagListing.results},() =>{
		});
	}
	
  }

  componentDidMount = () => {
    let {type,listingType,patrolMovie_list} = this.state;
	let id = ""
	//this.props.get_patrol_crp(id,type,listingType);
  }
  
  componentWillUnmount() {
	  this.props.clearvoteList();
	  this.setState({flagListing:[],voteListing:[]})
  }
  
  handleSkip = () => {
	  let {flagListing} = this.state;
	  if(flagListing && Array.isArray(flagListing) && flagListing.length > 0){
		  flagListing.shift();
		  this.setState({flagListing,about:""},()=> {
			});
	  }
	  
  }
  
  handleVoteSubmit = () => {
    let { name} = this.state
    
      this.setState({
        nameError:
          "This field is required",
        categoryError:
          "This field is required",
      });
    
  }

  render() {
    const {
      openModal,
      toggleVoteMovieModal,
	  VoteType
     } = this.props;
	const {category_id, flagListing,voteListing, name} = this.state;
	return (
      <>
        <Modal
          size="xl"
          scrollable
          className="drawer-modal"
          isOpen={openModal}
          toggle={toggleVoteMovieModal}
        >
          <form
            className="d-flex flex-column h-100"
          >
            <div className="modal-header p-0">
              <div className="w-100">
                <div>
                  <div className="wk-voting-movie-header">
                        <Row className="m-0">
                           <Col md="6" className="wk-voting-movie-headerleft p-4 text-center">
                              <div>
                                <p className="text-white fs-14">Participate</p>
                                <h2 className="text-white text-uppercase">Community Voting</h2>
                                <p className="text-white fs-14 mb-0">in WikiReviews</p>
                              </div>
                           </Col>
                           <Col md="6" className="wk-voting-movie-headerright p-4 bg-dark">
                              <p className="text-primary m-0 fs-16 mb-2">Community Voting</p>
                              <p className="text-white mb-0 fs-14">When information is disputed, our Community Voting system ensures that your voice will be heard, giving you direct access to create a website you love to enjoy. Browse disputes below to weigh in and let your voice be heard.
                              </p>
                           </Col>
                        </Row>
                      </div>
                </div>
              </div>
            </div>
            <ModalBody className="bg-white text-dark p-4">
               <h2 className="text-dark fs-39 mb-3">{VoteType}</h2>
			   {flagListing && Array.isArray(flagListing) && flagListing.length > 0 && (
               <div className="wk-vote-movie-block pt-3">
                 <ul className="m-0 p-0">
				 {flagListing && Array.isArray(flagListing) &&
					  flagListing.length > 0 
					  && flagListing.map((flag,flagIndex) => {
					  return (
						  <li className="mb-2" key={flag?.id}>
							 <a href="#" className="fs-16 text-dark font-weight-bold">{flag?.label}</a>
							 <ul className="m-0 p-0 ml-4 mt-2 wk-vote-movie-sublist">
							   {flag.children && Array.isArray(flag.children) && flag.children.length > 0 
								  && flag.children?.map((flagChildren,flagChildIndex) => {
									  return (
									 <li className="mb-2" key={flagChildren.id}>
									   <a href="#" className="fs-14">{flagChildren.label}</a>
								   </li> 
								  );
							 })}
							</ul>
					   </li>
					 );
					})}
                   </ul>
               </div>
			   )}
			  {voteListing && Array.isArray(voteListing) && voteListing.length > 0 && (
			   <div className="wk-vote-movie-block pt-3">
                 <ul className="m-0 p-0">
				 {voteListing && Array.isArray(voteListing) &&
					  voteListing.length > 0 
					  && voteListing.map((vote,voteIndex) => {
					  return (
						  <li className="mb-2" key={vote?.id}>
							 <a href="#" className="fs-16 text-dark font-weight-bold">{vote?.label}</a>
							 <ul className="m-0 p-0 ml-4 mt-2 wk-vote-movie-sublist">
							   {vote.children && Array.isArray(vote.children) && vote.children.length > 0 
								  && vote.children?.map((voteChildren,voteChildIndex) => {
									  return (
									 <li className="mb-2" key={voteChildren.id}>
									   <a href="#" className="fs-14">{voteChildren.label}</a>
								   </li> 
								  );
							 })}
							</ul>
					   </li>
					 );
					})}
                   </ul>
			  </div>)}
            </ModalBody>
          </form>
        </Modal>



      </>


    );



  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
  state.business.videos_children && state.business.videos_children[0],
  getVoteListing: state.footer.getVoteListing,
  getFlagListing: state.footer.getFlagListing,
});

const mapProps = (dispatch) => ({
  add_movie: (data) => dispatch(add_movie(data)),
  initFetchMovieSubCategories: (categoryId, type) => dispatch(initFetchMovieSubCategories(categoryId, type)),
  search_moviesMembers: (value, type) => dispatch(search_moviesMembers(value, type)),
  clearvoteList: () => dispatch(clearvoteList()),
});
export default withRouter(connect(mapState, mapProps)(VoteMovieModal));