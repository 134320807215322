import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { callApi } from '../../utils/apiCaller';
import { debounce } from "lodash";

const Menu = (props) => (
    <components.Menu {...props}>
        <div>
            {(!props.isLoading && props.options.length) ? (
                <div>{props.children}</div>
            ) : props.isLoading ? (
                <span className='fetching'>Fetching users...</span>
            ) : <span className='fetching'>No users found...</span>}
        </div>
    </components.Menu>
);

const Option = (props) => {
    return (
        <components.Option {...props}>{props.children}</components.Option>
    )
};

const SearchRecipients = ({ handleSelectId, myProfile }) => {
    const [data, setData] = useState({
        users: [], isLoading: false, searchValue: ""
    });

    const mapUsers = (users) =>
        users.map((user) => ({
            value: user._source?.id,
            label: user._source?.name.toString(),
            profile_pic: user._source?.current_profile_pic,
            key: user._source?.id,
            isFriend: user._source?.friend,
        }));

    const formatOptionLabel = (option) => {
        return (
            <div className='label customOption'>
                <img
                    width={30}
                    height={30}
                    className='rounded-circle'
                    src={option.profile_pic}
                    onError={(e) => e.target.src = "https://userdatawikireviews.s3.amazonaws.com/images/circle.png"}
                    alt="profile"
                />
                <span>{option.label}</span>
                {option?.isFriend && <span className='friendTag'>friends</span> }
            </div>
        );
    };
    
    const handleInputChange = (inputValue) => {
        setData((prevState) => ({ ...prevState, isLoading: inputValue ? true : false, searchValue: inputValue }));
        inputValue && debouncedChangeHandler(inputValue);
    };

    const debouncedChangeHandler = useMemo(
        (inputValue) => debounce((inputValue => {
            callApi(`/api/search/?user=${inputValue}`, 'GET').then((res) => {
                localStorage.setItem('loader', false);
                if (res.code < 400) {
                    if (res.results && res.results.length) {
                        res.results = res.results.filter((i) => i._source.id != myProfile?.id);
                        setData((prevState) => ({ ...prevState, users: mapUsers(res.results), isLoading: false }));
                    }
                    else {
                        setData((prevState) => ({ ...prevState, users: [], isLoading: false }));
                    }
                }
            });
        }), 500)
        , []);

    const customStyles = {
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),
    };

    return (
        <Select
            options={data.users}
            components={{ Menu, Option }}
            placeholder='Search users'
            isMulti
            isSearchable
            autoFocus
            styles={customStyles}
            className="react-select-container mb-2"
            classNamePrefix="react-select"
            formatOptionLabel={formatOptionLabel}
            isLoading={data.isLoading}
            onChange={(value) => handleSelectId(value)}
            onInputChange={handleInputChange}
            menuIsOpen={data.searchValue && data.searchValue.length}
        />
    );
};

export default SearchRecipients;
