/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';

export default class Footer extends Component {
  state = {
    selectedCountry: 'United States',
    currentYear: moment().year(),
  };

  handleOnClickCountry = (country) => {
    this.setState({
      selectedCountry: country
    });
  }

  render() {
    // .match.params.category
    return (
      <React.Fragment>
        <footer className="position-relative footer text-center bg-dark text-white py-4 fs-14">
          <Container fluid={true} className="container-xl">
            <Row className="justify-content-center align-items-center justify-content-lg-between mb-3">
              <Col xs={12} sm="auto">
                <UncontrolledDropdown className="lh-1-5">
                  <DropdownToggle className="type-transparent font-weight-normal px-0" caret>
                    <span>{this.state.selectedCountry}</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link className="font-weight-bold dropdown-item text-reset text-decoration-none" to={{
                      pathname: `/new-york`,
                      state: { city: 'New York', country: 'usa' }
                    }} onClick={() => this.handleOnClickCountry('United States')}>United States</Link>
                    <Link className="font-weight-bold dropdown-item text-reset text-decoration-none" to={{
                      pathname: `/toronto`,
                      state: { city: 'Toronto', country: 'can' }
                    }} onClick={() => this.handleOnClickCountry('Canada')}>Canada</Link>
                    <Link className="font-weight-bold dropdown-item text-reset text-decoration-none" to={`/locations`} onClick={() => window.scrollTo(0, 0)}>More Locations</Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col xs={12} sm="auto">
                <div className="text-center text-lg-right">
                  <a className="d-inline-block" href={'https://play.google.com/store/apps/details?id=com.leo.wikireviews&hl=en_IN'} target="_blank">
                    <img width="162" src={require('../../../assets/images/icons/play-store.png')} alt="" />
                  </a>
                  <a className="d-inline-block" href={'https://itunes.apple.com/us/app/wikireviews/id1019136386?mt=8'} target="_blank">
                    <img width="162" src={require('../../../assets/images/icons/app-store.png')} alt="" />
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col xs={{ order: 1 }} className="col-lg text-lg-left">WIKIREVIEWS © {this.state.currentYear}</Col>
              <Col lg={{ size: 'auto', order: 2 }} xs={{ order: 3 }} className=" text-center">
                <div className="d-md-inline-flex">
                  <div className="mb-2 mb-md-0">
                    <Link to="/about" target="_blank" className="mx-2 font-weight-medium text-decoration-none text-light">About</Link> |
                                        <Link to="/faq" target="_blank" className="mx-2 font-weight-medium text-decoration-none text-light">FAQ</Link> |
                                        <Link to="/guidelines" target="_blank" className="mx-2 font-weight-medium text-decoration-none text-light">Guidelines</Link>
                  </div>
                  <div className="d-none d-md-inline-block">|</div>
                  <div>
                    <Link to="/policies" target="_blank" className="mx-2 font-weight-medium text-decoration-none text-light">Privacy Policy</Link> |
                    <Link to="/terms" target="_blank" className="mx-2 font-weight-medium text-decoration-none text-light">Terms &amp; Conditions</Link>
                    |
                        <a rel={'external'} target="_blank" href={"https://wikireviews.com/blog/"} className="mx-2 font-weight-medium text-decoration-none text-light" >Blog</a>
                    {/* <Link to={"https://wikireviews.com/blog/"} target="_blank" className="mx-2 font-weight-medium text-decoration-none text-light">Blog</Link> */}
                  </div>
                </div>
              </Col>
              <Col lg={{ order: 3 }} xs={{ order: 2 }} className="col-lg text-lg-right my-3 my-lg-0">
                <a className="btn btn-outline-white btn-circle mx-2" href="http://www.facebook.com/wikireviews" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                </a>
                <a className="btn btn-outline-white btn-circle mx-2" href="http://www.twitter.com/wikireviews" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={["fab", "twitter"]} />
                </a>
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    )
  }
}
