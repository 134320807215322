import React, { Component } from 'react'
import CollapseBasic from './collapse';
import { Link } from 'react-router-dom';
import { callApi } from "../../utils/apiCaller";
export default class FriendsRecommendsThis extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listingData: []
        }
    }


    fetchFriendsList = async () => {
        const { id } = this.props;
        if (id) {
            try {
                let url = `api/professionalpins/?&friend_recomend=true&pin_type__category=recommend+list&professionaluser_entry_id=${id}`
                let response = await callApi(url, 'GET');
                if (response && response?.code !== 500) {
                    this.setState({
                        listingData: response?.results
                    })
                }
            } catch (err) {

            }
        }
    }

    render() {
        let { listingData } = this.state;
      

        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic
                    titleCount={listingData?.length}
                    title={'of your friends recommends this'}
                    containerClass="bg-transparent type-secondary"
                    bodyClass="text-secondary"
                    isOpen={false}
                    collapsible={Boolean(listingData?.length)}>
                    <ul className="list-unstyled d-flex flex-wrap mx-n2">
                        {listingData && Array.isArray(listingData) && listingData?.length ?
                            listingData.map(item => (
                                <li className="px-2 mb-2">
                                    <Link
                                        to={{
                                            pathname: `/actors/${item?.profile?.user?.username}`,
                                            state: { id: item?.profile?.user?.id }
                                        }}
                                        target="_blank"
                                        title={`${item?.profile?.user?.first_name} ${item?.profile?.user?.last_name}`}>
                                        <img
                                            className="img-circle _50x50"
                                            src={item?.profile?.current_profile_pic}
                                            alt={`${item?.profile?.user?.first_name} ${item?.profile?.user?.last_name}`}
                                        />
                                    </Link>
                                </li>
                            ))
                            : null}
                    </ul>
                </CollapseBasic>
            </div>
        )
    }
}
