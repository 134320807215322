/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from "leaflet";
import moment from 'moment';
import queryString from 'query-string';
import React, { Component } from 'react';
import { FeatureGroup, Map, Marker, Popup, Rectangle, TileLayer, ZoomControl } from "react-leaflet";
import Control from 'react-leaflet-control';
import Rating from 'react-rating';
import { connect } from "react-redux";
import { Carousel as ReactRespCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Alert, Button, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import StarsRating from 'stars-rating';
import { add_remove_to_list, add_to_list, clear_category, clear_subcategory, fetch_categories_data, fetch_detailedstarrating, fetch_sub_categories, filter_categories_data, get_business_QA_dropdown, get_chart_star_ratings, publish_post, remove_from_list, save_ratings_vote, save_ratings_vote_person, share_by_email } from '../../actions/business';
import { add_search_list, clear_features, clear_search_result, clear_subcategories, delete_pin_list, fetch_additonal_attributes, fetch_filter_result, fetch_price_sort, fetch_search_cities, fetch_subcategories, serach_home_result, send_again_request, follow_request, unfollow_request, set_search_results_loading, serach_home_result_, fetch_current_location } from '../../actions/home';
import { embedNewLink } from '../../actions/messages';
import { clear_media_dispatch, delete_mutiple_media, fetch_delete_media, fetch_media_data, fetch_taxonomy_category, fetch_taxonomy_list, media_upload_scroll, single_delete_from_upload, upload_add_captions, upload_media_files, upload_media_filesData } from "../../actions/myProfiles";
import filterIconDual from "../../assets/images/icons/filter-search.png";
import listIconDual from "../../assets/images/icons/list-search.png";
import mapIconDual from "../../assets/images/icons/map-filter.png";
import { callApi } from "../../utils/apiCaller";
import CollapseBasic from '../atoms/collapse';
import WebCamRecording from '../oragnisms/businessDetails/webCamRecording';
import AppHeader from '../oragnisms/dashboard/appHeader';
import osm from "../atoms/osm-providers"
import PublicPageHeader from '../oragnisms/homepage/publicPageHeader';
import ModalComponent from '../atoms/modalComponent';
import ShareByEmail from '../atoms/shared/shareByEmail';
import { setLoginPrompt, setLoginPromptLogin, setLoginPromptSignUp } from '../../actions/shared';

const group_icons = 'https://stagingdatawikireviews.s3.amazonaws.com/images/group-icon.png';
class SearchResults extends Component {
    constructor(props) {

        super(props)
        this.myScrollSearchRef = React.createRef();
        this.myDivToFocus = React.createRef();
        this.refMap = React.createRef();
        this.groupRef = React.createRef();

        this.myScrollPhotoRef = React.createRef();
        this.myScrollVideoRef = React.createRef();

        this.refGalleryVideoScroll = React.createRef();
        this.refGalleryImageScroll = React.createRef();

        this.state = {
            chartStarRatings: null,
            enableRatingText: null,
            enableErrorForReview: true,
            embedBody: [],
            stepTwoUploadedVideo: [],
            reviewDynamicItems: {},
            searchResultsType: 'all',
            viewEditReviewStep: 'writeReview',
            filterType: 'all',
            showMapView: false,
            sortBy: 'best',
            querySearch: "",
            pageNo: 1,
            searchResultData: [],
            searchFilterData: [],
            loading: false,
            isOpen: {},
            searchResultId: 0,
            stars: 5,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            currentLat: 37.7749295,
            currentLong: -122.4194155,
            categoryId: 0,
            city: "San Francisco",
            state: "CA",
            selectedId: 0,
            addSearchList: {},
            listType: "",
            favorite: false,
            watch: false,
            want: false,
            nearByCitiesModalToggle: false,
            SubCategoryModalToggle: false,
            FeatureModalToggle: false,
            allType: "all",
            indexRange: 3,
            indexSubRange: 5,
            indexFeatureRange: 5,
            brandType: "",
            searchCitiesData: [],
            CityType: "",
            searchSubcategoryData: [],
            searchFeaturesData: [],
            subcategoryId: 0,
            featureName: "",
            priceValue: [],
            featureValue: [],
            targetId: [],
            searchFetchLoader: true,
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle:
                "Log in or sign up for WikiReviews to view and rate my Community Review Project!",
            shareVia: "WikiReviews",
            fbHashTag: "#WikiReviews",
            twitterHashTags: ["WikiReviews"],
            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareCategory: null,
            shareError: {},
            reviewName: "",
            avg_rating: 0,
            enableRating: false,
            businessId: 0,
            write_new_review: '',
            reviewAnonymous: false,
            shocialNetworkSharing: false,
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            searchTheater: '',
            albumType: '',
            fetchPhotos: {},
            fetchPhotosCount: 0,
            fetchVideos: {},
            fetchVideosCount: 0,
            fetchTaxonomy: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            dataWantsToDelete: [],
            confirmDeleteModal: false,
            limitedPostsModal: false,
            uploadMediaModal: false,
            uploadMediaPostModal: false,
            isModalTrue: false,
            modalText: null,
            modalType: null,
            modalSelected: null,
            itemsAttachment: [],
            itemsIndex: 1,
            showVideoMedia: true, // video option toggle
            businessRatingList: [],
            ratePerson: "",
            first_name: "",
            last_name: "",
            writePerson: "",
            sharecontentType: "",
            castPerson: [],
            avg_rating_person: 0,
            personId: 0,
            businessRatingIds: [],
            reviewsCategories: [],
            isadhere: true,
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            categoryType: "",
            cattaxListing: {},
            uploadedId: {},
            albumCategoryId: "",
            captionData: "",
            homeDropdownOpen: {},
            homeShareDropdownOpen: {},
            zoom: 12,
            keyMAP: Math.random()

        }
        this.handleOnClickPerson = this.handleOnClickPerson.bind(this);
        this.onMouseEnterCategory = this.onMouseEnterCategory.bind(this);
        this.onMouseLeaveCategory = this.onMouseLeaveCategory.bind(this);
        this.onMouseShareEnterCategory = this.onMouseShareEnterCategory.bind(this);
        this.onMouseShareLeaveCategory = this.onMouseShareLeaveCategory.bind(this);

    }

    getLocationParams() {
        const { currentLat, currentLong } = this.state;
        return encodeURI(JSON.stringify({ "lat": currentLat, "lng": currentLong }));
    }

    getSearchResultURL(){
        let contenttype = this.props?.history?.location?.state?.contenttype;
        let { location } = this.props;
        const parsed = queryString.parse(location.search);
        let query = parsed?.q;
        const { pageNo } = this.state;
        let page = pageNo;
        
        let url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&size=5&page=${page}&stars=&manufacturer=`;
        if (contenttype && contenttype != "" && contenttype != "undefined") {
            if (contenttype == "people") {
                url = `/api/search/?location_add=${this.getLocationParams()}&user=${query}&page=1`;
            } else if(contenttype == "projects"){
                url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${contenttype}&page=1&stars=&cat=&subcat=&searchPage=true&size=5&radius=&citysearch=true`;
            }else if(contenttype == "business" || contenttype == "products" || contenttype == "movies" || contenttype == "actors" || contenttype == "professionals") {
                url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${contenttype}&page=1&stars=&cat=&subcat=&searchPage=true&size=5&radius=&manufacturer=`;
            }
        }

        return url;
    }

    /**
    * Function to get data after render page
    *
    */
    componentDidMount() {
        let { location } = this.props;
        let contenttype = this.props?.history?.location?.state?.contenttype;
        
        if (location?.search) {
            const parsed = queryString.parse(location.search);
            if (parsed?.q) {

                this.setState({ querySearch: parsed?.q, allType: "all", searchResultsType: contenttype && contenttype != 'undefined' ? contenttype : "all" }, () => {
                    this.props.fetch_current_location().then(() => {
                        const url = this.getSearchResultURL();
                        this.props.serach_home_result_([]);
                        this.props.serach_home_result(url);
                    })
                });
            }
        }
        /*setTimeout(() => {
          this.setState({ searchFetchLoader: false })
        }, 7000);*/
        this.mapCordinatesSet();
        this.props.clear_category();
        this.props.clear_subcategory();
    }

    /**
    * Function to get data after any changes
    *
    */

    componentWillReceiveProps(nextProps) {
        const { searchResultsType, allType, fetchGalleryScroll, fetchDeletedData, latestUploadedData, fetchPhotos, fetchVideos } = this.state;
        if ((nextProps.fetch_search_result &&
            Array.isArray(nextProps.fetch_search_result.results)) && nextProps.searchingResultsLoading) {
            this.props.set_search_results_loading(false);
            let contentType = "all"
            if (nextProps.fetch_search_result?.results && nextProps.fetch_search_result?.results[0]?._index) {
                /*if (nextProps.fetch_search_result.results[0]._index == "wr_product_listing") {
                    contentType = "products";
                } else if (nextProps.fetch_search_result.results[0]._index == "wr_business_listing" && allType == "all") {
                    contentType = "all";
                } else if (nextProps.fetch_search_result.results[0]._index == "wr_professional") {
                    contentType = "professionals";
                } else if (nextProps.fetch_search_result.results[0]._index == "wr_crp") {
                    contentType = "projects";
                } else if (nextProps.fetch_search_result.results[0]._index == "wr_business_listing" && allType == "") {
                    contentType = "business";
                } else if (nextProps.fetch_search_result.results[0]._index == "wr_actor") {
                    contentType = "actors";
                } else if (nextProps.fetch_search_result.results[0]._index == "wr_movie_listing" && allType == "") {
                    contentType = "movies";
                }*/
                this.setState({
                    searchResultData: nextProps.fetch_search_result,
                    //searchResultsType: contentType,
                    searchFetchLoader: false,
                    keyMAP: Math.random() 
                },
                    () => {
                        this.mapCordinatesSet();
                    }
                    );
                
            } else {

                //contentType = "movies";
            }

            // this.setState({
            //     searchResultData: nextProps.fetch_search_result,
            //     //searchResultsType: contentType,
            //     searchFetchLoader: false
            // },
            //     () => {

            //         this.mapCordinatesSet();
            //     }
            // )

        } else {
            this.setState({
                // searchResultData: [],
                // searchFetchLoader: false
            });
        }

   


        if (nextProps?.chart_star_ratings) {
            this.setState({ chartStarRatings: nextProps?.chart_star_ratings });
        }

        if (nextProps.fetch_filter_list &&
            Array.isArray(nextProps.fetch_filter_list) &&
            nextProps.fetch_filter_list.length > 0) {
            this.setState({
                searchFilterData: nextProps.fetch_filter_list
            });
        } else {
            this.setState({
                searchFilterData: []
            });
        }

        if (nextProps.fetch_search_cities_list &&
            Array.isArray(nextProps.fetch_search_cities_list) &&
            nextProps.fetch_search_cities_list.length > 0) {
            this.setState({
                searchCitiesData: nextProps.fetch_search_cities_list
            });
        } else {
            this.setState({
                searchCitiesData: []
            });
        }

        if (nextProps.fetch_subcategories_list &&
            Array.isArray(nextProps.fetch_subcategories_list) &&
            nextProps.fetch_subcategories_list.length > 0) {
            this.setState({
                searchSubcategoryData: nextProps.fetch_subcategories_list
            });
        } else {
            this.setState({
                searchSubcategoryData: []
            });
        }

        if (nextProps.fetch_additonal_attributes_list &&
            Array.isArray(nextProps.fetch_additonal_attributes_list.results) &&
            nextProps.fetch_additonal_attributes_list.results.length > 0) {
            this.setState({
                searchFeaturesData: nextProps.fetch_additonal_attributes_list.results
            });
        } else {
            this.setState({
                searchFeaturesData: []
            });
        }

        if (
            nextProps.fetch_add_search_list &&
            Object.keys(nextProps.fetch_add_search_list).length > 0
        ) {
            this.setState(
                {
                    addSearchList: nextProps.fetch_add_search_list,
                    favorite: nextProps.fetch_add_search_list.pinning_status["favourite list"],
                    watch: nextProps.fetch_add_search_list.pinning_status["watch list"],
                    want: nextProps.fetch_add_search_list.pinning_status["want list"],
                });
        }

        if (
            nextProps.current_location &&
            Object.keys(nextProps.current_location).length > 0
        ) {
            if (nextProps.current_location?.lat != this.props.current_location?.lat && nextProps.current_location?.lng != this.props.current_location?.lng && this.props.current_location != null) {
                this.setState(
                    {
                        currentLat: nextProps.current_location?.lat,
                        currentLong: nextProps.current_location?.lng,
                        city: nextProps.current_location?.city,
                        state: nextProps.current_location?.state != null ? nextProps.current_location?.state : "",
                    },
                    () => {
                        this.mapCordinatesSet();
                        this.handleOnClickCategory(searchResultsType);

                    }
                );
            } else {

                this.setState(
                    {
                        currentLat: nextProps.current_location?.lat,
                        currentLong: nextProps.current_location?.lng,
                        city: nextProps.current_location?.city,
                        state: nextProps.current_location?.state != null ? nextProps.current_location?.state : "",
                    });
            }

        }

        if (nextProps.fetch_photos) {
            let item = nextProps.fetch_photos;
            if (item.album) { let fetchPhotos = [] }
            fetchPhotos[item.page] = item;
            this.setState({ fetchPhotos, fetchPhotosCount: item?.count, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_videos) {
            let item = nextProps.fetch_videos;
            if (item.album) { let fetchVideos = [] }
            fetchVideos[item.page] = item;
            this.setState({ fetchVideos, fetchVideosCount: item?.count, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_taxonomy) {
            let taxCat = nextProps.fetch_taxonomy;
            if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
                taxCat = taxCat.filter(dt => dt.label !== null);
            }
            this.setState({ fetchTaxonomy: taxCat })
        }

        if (nextProps.fetch_deleted_data) {
            let item = nextProps.fetch_deleted_data;
            if (!item.type) { let fetchDeletedData = {} }
            fetchDeletedData[item.page] = item;
            this.setState({ fetchDeleteCount: item.count, fetchDeletedData, activeMyPhotoIndex: 0 });
        }

        if (nextProps.upload_scroll) {
            let item = nextProps.upload_scroll;
            if (item.album) { let fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            this.setState({ fetchGalleryScroll, fetchGalleryCount: item.count, activeMyPhotoIndex: 0 });
        }

        if (nextProps.upload_media_data) {
            let data = nextProps.upload_media_data;
            if (data.index !== undefined) {
                let idx = data.index;
                latestUploadedData[idx] = data;
                this.setState({ latestUploadedData });
            }
        }

        if (nextProps.qa_dropdown &&
            Array.isArray(nextProps.qa_dropdown.res) &&
            nextProps.qa_dropdown.res.length > 0) {
            this.setState({ reviewsCategories: nextProps.qa_dropdown });
        }

        if (nextProps.fetch_data_categories) {
            let { fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state;
            let taxCatListing = nextProps.fetch_data_categories;
            if (taxCatListing && Array.isArray(taxCatListing) && taxCatListing.length > 0) {
                if (taxCatListing[0].isTypes == "image") {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingImages[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingImages });
                    }

                } else {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingVideos[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingVideos });
                    }
                }
            }

        }

        if (nextProps.filter_data_category) {
            let taxCatList = nextProps.filter_data_category;

            if (taxCatList && Object.keys(taxCatList).length > 0) {
                //taxCatListing = taxCat.filter(dt => dt.label !== null);
                this.setState({ cattaxListing: taxCatList[0] ? taxCatList[0].children : [] })

            }

        }

    }

    /**
    * Function to get html hidden value from form
    *
    */

    fetchDataFromHtmlForm = async ({ businessId }) => {
        let url = `/form/reviewnewlisting/business/${businessId}`;
        try {
            const response = await callApi(url, 'GET');
            if (response?.code == 200) {
                let data = response?.data;
                if (data) {
                    const doc = new DOMParser().parseFromString(data, "text/html")
                    let uniqrate = doc.getElementById('uniqrate').value;
                    let csrfmiddlewaretoken = doc.getElementsByName('csrfmiddlewaretoken')[0].value;
                    let security_hash = doc.getElementsByName('security_hash')[0].value;
                    let timestamp = doc.getElementsByName('timestamp')[0].value;
                    let honeypot = doc.getElementsByName('honeypot')[0].value;
                    let object_pk = doc.getElementsByName('object_pk')[0].value;
                    let content_type = doc.getElementsByName('content_type')[0].value;
                    let reviewDynamicItems = {};
                    reviewDynamicItems.uniqrate = uniqrate;
                    reviewDynamicItems.csrfmiddlewaretoken = csrfmiddlewaretoken;
                    reviewDynamicItems.security_hash = security_hash;
                    reviewDynamicItems.timestamp = timestamp;
                    reviewDynamicItems.honeypot = honeypot;
                    reviewDynamicItems.object_pk = object_pk;
                    reviewDynamicItems.content_type = content_type;

                    this.setState({ reviewDynamicItems });
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    /**
   * Function to update security hash value
   *
   */


    updateSecurityHash = async ({ businessId }) => {
        let { reviewDynamicItems } = this.state;
        let url = `/form/reviewnewlisting/business/${businessId}`;
        try {
            const response = await callApi(url, 'GET');
            if (response?.code == 200) {
                let data = response?.data;
                if (data) {
                    const doc = new DOMParser().parseFromString(data, "text/html")
                    let uniqrate = doc.getElementById('uniqrate').value;
                    let csrfmiddlewaretoken = doc.getElementsByName('csrfmiddlewaretoken')[0].value;
                    let security_hash = doc.getElementsByName('security_hash')[0].value;
                    let timestamp = doc.getElementsByName('timestamp')[0].value;
                    let honeypot = doc.getElementsByName('honeypot')[0].value;
                    let object_pk = doc.getElementsByName('object_pk')[0].value;
                    let content_type = doc.getElementsByName('content_type')[0].value;

                    // let reviewDynamicItems = {};
                    reviewDynamicItems.security_hash = security_hash;
                    this.setState({ reviewDynamicItems });
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    /**
    * Function to get category by click on category
    *
    */

    handleOnClickCategory = (type) => {
        let { location } = this.props;
        const { pageNo, currentLat, currentLong } = this.state
        const parsed = queryString.parse(location.search);

        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                let page = pageNo;
                let url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&size=5&page=${page}&stars=&manufacturer=`;
                if (type != "") {
                    if (type == "people") {
                        url = `/api/search/?location_add=${this.getLocationParams()}&user=${query}&page=${page}`;
                        this.setState({ searchFetchLoader: true });

                    } else if (type == "all") {
                        url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&size=5&page=${page}&stars=&manufacturer=`;
                        this.setState({ allType: "all", searchFetchLoader: true });
                    } else {
                        url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${type}&page=${page}&stars=&cat=&subcat=&searchPage=true&size=5&radius=&manufacturer=`
                        this.setState({ allType: "", searchFetchLoader: true });

                    }
                    if (query != "") {
                        this.setState({ searchResultsType: type, filtersVisible: false, querySearch: parsed?.q, stars: "", sortBy: "", brandType: "", distanceBetween: "", searchCitiesData: [], CityType: "", searchFilterData: [], searchSubcategoryData: [], searchFeaturesData: [], indexRange: 3, subcategoryId: 0, categoryId: 0, searchResultData: [], selectedPlace: {}, activeMarker: {}, showingInfoWindow: false, selectedId: 0, isOpen: {} }, () => {
                            this.props.serach_home_result_([]);
                            this.props.serach_home_result(url)
                        });
                        this.props.clear_subcategories();
                        this.props.clear_features();
                        this.props.clear_search_result();
                        if (type == "business") {
                            let urlCity = `/api/searchcities/?lat=${currentLat}&lng=${currentLong}&type=${type}`;
                            this.props.fetch_search_cities(urlCity);
                        }
                    }
                }
            }
        }
    }

    /**
    * Function to get filter data
    *
    */

    handleOnClickFilter = () => {
        const { searchResultsType, searchResultData } = this.state
        let url = "";
        if (searchResultsType != "" && searchResultsType != "all" && searchResultsType != "actors") {
            url = `/api/taxonomy/?category=${searchResultsType}&depth=false&delsel=&ancestor=&level=0`;
            this.setState({ filterType: searchResultsType, searchFilterData: [], searchSubcategoryData: [], searchFeaturesData: [] }, () =>
                this.props.fetch_filter_result(url)
            );
            if (searchResultsType != "movies") {
                searchResultData.results.forEach((searchItem, index) => {
                    this.state.targetId.push(searchItem._id);
                });
            } else {
                searchResultData.results.forEach((searchItem, index) => {
                    this.state.targetId.push(searchItem.id);
                });
            }
        }
    }

    /**
   * Function to get and set person data
   *
   */

    handleOnClickPerson = (event) => {
        let name = ""
        name = event.target.options[event.target.selectedIndex].dataset.name;
        //url = `/api/taxonomy/?category=${searchResultsType}&depth=false&delsel=&ancestor=&level=0`;
        this.setState({ ratePerson: name });
        /*this.setState({ ratePerson: name }, () =>
            this.props.fetch_filter_result(url)
        );*/
    }

    /**
    * Function to phone number format
    * 
    */

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    }

    handleOnChangePerson = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleOnMoreCategory = (categId) => {
        let { location } = this.props;
        const { searchResultsType } = this.state
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                //let url = "";
                if (searchResultsType != "" && searchResultsType != "all") {
                    //url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=1&stars=&cat=${categId}&subcat=&searchPage=true&size=5&radius=&manufacturer=`;
                    this.setState({ categoryId: categId });
                    this.props.fetch_subcategories(categId);
                    this.props.fetch_additonal_attributes(categId);
                }
            }
        }
    }

    handleOnClickSubcategory = (subcategId, range) => {
        let { location } = this.props;
        const { searchResultsType, categoryId } = this.state
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                let url = "";
                if (searchResultsType != "" && searchResultsType != "all" && range == 5) {
                    url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=1&stars=&cat=${categoryId}&subcat=${subcategId}&searchPage=true&size=5&radius=&manufacturer=`;
                    this.setState({ subcategoryId: subcategId, SubCategoryModalToggle: !this.state.SubCategoryModalToggle });
                    this.props.serach_home_result_([]);
                    this.props.serach_home_result(url);

                } else {
                    this.setState({ subcategoryId: subcategId });

                }
            }
        }
    }

    handleOnClickAll = () => {
        let { location } = this.props;
        const { searchResultsType, pageNo } = this.state
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                let page = pageNo;
                let url = "";
                if (searchResultsType != "" && searchResultsType != "all") {
                    url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=${page}&stars=&cat=&subcat=&searchPage=true&size=5&radius=&manufacturer=`
                    this.props.serach_home_result_([]);
                    this.props.serach_home_result(url);
                }
            }
        }
    }



    handleOnClickFeatures = (event) => {
        let { location } = this.props;
        const { searchResultsType, categoryId } = this.state
        const parsed = queryString.parse(location.search);
        var id = 0;
        if (event.target.getAttribute("data-id") != null) {
            id = parseInt(event.target.getAttribute("data-id"));
        }
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                if (event.target.name == "filterByFeature_" + event.target.value) {
                    if (event.target.checked == true) {
                        this.state.featureValue.push(event.target.value)
                    } else {
                        this.state.featureValue.pop(event.target.value)
                    }

                }

                let priceData = ""
                let featureData = this.state.featureValue.join(',');
                let tragetData = this.state.targetId.join();
                if (this.state.priceValue.length > 0) {
                    priceData = this.state.priceValue.join(',');
                }
                if (id == 0) {
                    if (this.state.featureValue.length > 0) {
                        let urlPrice = `/api/GetPriceFilteredResult/?attribute=${featureData}&content_type=${searchResultsType}&price=${priceData}&target_id=${tragetData}`
                        this.props.fetch_price_sort(urlPrice);
                    } else {
                        let urlSeach = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=1&stars=&cat=${categoryId}&subcat=&searchPage=true&size=5&radius=&manufacturer=`;
                        this.props.serach_home_result(urlSeach);
                    }
                }

            }
        }
    }

    handleOnFeatureSubmit = () => {
        let { location } = this.props;
        const { searchResultsType, categoryId } = this.state
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                let priceData = ""
                let featureData = "";
                let tragetData = this.state.targetId.join();
                if (this.state.priceValue.length > 0) {
                    priceData = this.state.priceValue.join(',');
                }
                if (this.state.featureValue.length > 0) {
                    featureData = this.state.featureValue.join(',');
                }
                let urlPrice = `/api/GetPriceFilteredResult/?attribute=${featureData}&content_type=${searchResultsType}&price=${priceData}&target_id=${tragetData}`
                this.props.fetch_price_sort(urlPrice);
                let urlSeach = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=1&stars=&cat=${categoryId}&subcat=&searchPage=true&size=5&radius=&manufacturer=`;
                this.props.serach_home_result(urlSeach);
            }
        }
        this.setState({ FeatureModalToggle: !this.state.FeatureModalToggle });
    }


    handleOnClickMore = () => {
        const { searchFilterData, indexRange } = this.state
        if (searchFilterData && searchFilterData.length > 3) {
            if (indexRange == 3) {
                this.setState({ indexRange: searchFilterData.length });
            } else {
                this.setState({ indexRange: 3 });
            }
        }
    }

    handleOnCategoryMore = () => {
        this.setState({ SubCategoryModalToggle: !this.state.SubCategoryModalToggle });
    }

    handleOnFeatureMore = () => {
        this.setState({ FeatureModalToggle: !this.state.FeatureModalToggle });
    }

    handleOnExpand = (expand, id) => {
        let { isOpen } = this.state;
        isOpen[id] = expand;
        this.setState({ isOpen });
    }

    handleClickOkay = (data, type) => {
        let { querySearch } = this.state;
        let item = data;
        if (type === "send") {
            this.props.send_again_request({
                email: item?._source?.user?.email,
                vanity_url: item?._source?.user?.username,
            }, querySearch);
        }
        if (type == "Follow") {
            this.props.follow_request({
                id: item?._id
            }, querySearch);
        }
        if (type == "cancelFollow") {
            this.props.unfollow_request(item?._id, querySearch);
        }
    };

    handleScrollSearch = (e) => {
        let { myScrollSearchRef } = this;
        let { location } = this.props;
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let clientHeight = myScrollSearchRef.current.clientHeight;
                let scrollHeight = myScrollSearchRef.current.scrollHeight;
                const scrollTop = this.myScrollSearchRef.current.scrollTop.toFixed() - 1;
                let scrollTopCalulated = scrollHeight - clientHeight;
                let scrollMinimun_8 = scrollTopCalulated - 8;
                let scrollMinimun_6 = scrollTopCalulated - 6;
                let scrollMinimun_5 = scrollTopCalulated - 5;
                let scrollMinimun_3 = scrollTopCalulated - 3;
                let scrollMinimun_1 = scrollTopCalulated - 1;
                if (
                    scrollTopCalulated == scrollTop ||
                    scrollTop == scrollMinimun_1 ||
                    scrollTop == scrollMinimun_3 ||
                    scrollTop == scrollMinimun_5 ||
                    scrollTop == scrollMinimun_6 ||
                    scrollTop == scrollMinimun_8
                ) {
                    if (this.state.searchResultData && this.state.searchResultData.next) {

                        this.fetchSearchOnScroll(this.state.searchResultData.next.split('com')[1]);
                    }
                }

            }
        }
    }

    fetchSearchOnScroll = (next) => {
        this.setState({ loading: true });
        callApi(`${next}`, 'GET')
            .then(res => {
                if (res && Object.keys(res).length > 0 && res.results) {
                    let data = {
                        ...res,
                        results: [...this.state.searchResultData.results, ...res.results]
                    };

                    this.setState({ searchResultData: data, loading: false });
                }
            });
    }

    toggleCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = !expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseEnterCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseLeaveCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    toggleShareCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = !expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }

    onMouseShareEnterCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }

    onMouseShareLeaveCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }

    renderMoreDetailsAward = ({ title, count }) => (
        <li className="px-1">
            <img width="20"
                src={`https://userdatawikireviews.s3.amazonaws.com/images/awards/${title}.png`}
                alt=""
                title={`${title} ${count} times`} />
        </li>
    );

    fetchSerchResultList = (type) => {
        let searchResultData = this.state.searchResultData;
        let searchFetchLoader = this.state.searchFetchLoader;
        const { my_profile_info } = this.props;
        let { isOpen, searchResultId, searchResultsType } = this.state
        if (searchResultData.results && searchResultData.results.length > 0) {
            return searchResultData.results.map((item, index) => {
                //if(item.group_icon == type){
                return (
                    <div className="bg-white-shaded shadow-search-list p-3 mb-2" key={index} id={"content_" + item._id} ref={this.myDivToFocus}>
                        <div className="d-flex flex-wrap mx-n2">
                            {isOpen && isOpen[item._id] && type != "people" && item._type != "user" ?
                                <div className="px-2 col-12 col-md-4 col-lg-5 order-2 order-md-1">
                                    <div className="text-center">

                                        <ReactRespCarousel className="rr-carousel" infiniteLoop={true} showArrows={true} showStatus={false} showIndicators={false} showThumbs={item._source && item._source.listing_profileimage && item._source.listing_profileimage.length > 1}>
                                            {item._source && item._source.listing_profileimage && item._source.listing_profileimage.length > 0 ? (
                                                item._source.listing_profileimage.map((data, index1) => {
                                                    return (
                                                        <div key={index1}>
                                                            <img width="100" src={data.thumbnail} alt="" />
                                                        </div>

                                                    );
                                                })
                                            ) :
                                                (<div>
                                                    <img width="100" src="https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png" alt="" />
                                                </div>)}
                                        </ReactRespCarousel>

                                    </div>
                                </div>
                                :
                                <div className="px-2 col-auto">
                                    <a href="#">
                                        {item._type == "user" ?

                                            <img width="100" src={item._source && item._source?.current_profile_pic && item._source?.current_profile_pic?.length > 0 ? item._source.current_profile_pic : require('../../assets/images/icons/user-circle.png')} alt="" onError={(error) => { error.target.src = require('../../assets/images/icons/user-circle.png') }} />
                                            :
                                            <img width="100" src={item._source && item._source.listing_profileimage && item._source.listing_profileimage.length > 0 ? item._source.listing_profileimage[0].thumbnail : "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"} alt="" onError={(error) => { error.target.src = 'https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png' }} />

                                        }
                                    </a>
                                </div>
                            }
                            <div className="px-2 col order-1 order-md-2">
                                <div className="d-flex mx-n2 mb-2">
                                    <div className="px-2 col">
                                        <div className="mb-1">
                                            <Link
                                                to={{
                                                    pathname: `/${item._source?.content_type ? ((item._source?.content_type).toLowerCase() === "professionals" ? item._source?.professional_type : item._source?.content_type?.toLowerCase()) : searchResultsType}/${item._source?.slug ? item._source?.slug : item._source?.id}`,
                                                    // pathname: `/${searchResultsType == "all" ? "business" : searchResultsType}/${item._source?.slug ? item._source?.slug : ''}`,
                                                    state: { id: item?._id }
                                                }}
                                                className={`text-dark text-decoration-none ${isOpen && isOpen[item?._id] && type != "people" && item._type != "user" ? 'fs-30 ff-headings' : 'fs-16 ff-base font-weight-bold'}`}
                                            >{index + 1}. {item._source ? item._source.name : ""}</Link>
                                        </div>
                                        <div>
                                            {type != "people" && item._type != "user" ?
                                                <>
                                                    <img className="mr-2" hidden={type == "people" && item._type == "user" ? true : false} src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png" alt="" />
                                                    <strong className="text-dark ff-alt align-middle">{item._source && item._source.total_rating ? item._source.total_rating.total_review : "0"}
                                                        <span className="font-weight-normal">{' reviews'}</span>
                                                    </strong>
                                                </>
                                                :
                                                <>
                                                    <strong className="text-dark ff-alt align-middle">{item._source && item._source?.statistics ? item._source.statistics.reviews : "0"}
                                                        <span className="font-weight-normal">{' reviews'}</span>
                                                    </strong>
                                                    <br />
                                                    <span className="text-tertiary align-middle">{item._source && item._source?.statistics ? item._source.statistics.recommend : "0"}
                                                        {' recommendations'}
                                                    </span>

                                                    <br />
                                                    <br />
                                                    {isOpen && isOpen[item._id] && type == "people" && item._type == "user" ?
                                                        <ul className="d-flex flex-wrap mx-n1 mt-2">
                                                            {item._source && item?._source?.award_statistics?.all_badges &&
                                                                Object.keys(item?._source?.award_statistics?.all_badges).length ?
                                                                Object.keys(item?._source?.award_statistics?.all_badges).map(item_award => {
                                                                    let value = item?._source?.award_statistics?.all_badges[item_award];
                                                                    return this.renderMoreDetailsAward({ title: item_award, count: value[0] })
                                                                })
                                                                : null}
                                                        </ul>
                                                        :
                                                        ""
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="px-2 col-auto">
                                        <div className="d-inline-flex flex-nowrap align-items-center mx-n1">
                                            <div className="px-1">

                                                <UncontrolledDropdown onMouseOver={() => this.onMouseShareEnterCategory(true, item._id)}
                                                    onMouseLeave={() => this.onMouseShareLeaveCategory(false, item._id)}
                                                    isOpen={this.state.homeShareDropdownOpen[item._id]}
                                                    toggle={() => this.toggleShareCategory(this.state.homeShareDropdownOpen[item._id])}>

                                                    <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                        <div className="share-it-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary-alt" right>
                                                        <DropdownItem className="fs-14">
                                                            <FacebookShareButton url={`${this.state.protocol}//${this.state.host}/${searchResultsType == "all" ? "business" : searchResultsType}/${item?._id}/${item._source?.slug && item._source?.slug != null ? item._source?.slug : ''}&name=${item._source?.name}&picture=${item._source && item._source.listing_profileimage && item._source.listing_profileimage.length > 0 ? item._source.listing_profileimage[0].thumbnail : "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"}`} hashtag={this.state.fbHashTag}>Facebook</FacebookShareButton>
                                                        </DropdownItem>
                                                        {/*<DropdownItem className="fs-14">Google +</DropdownItem>*/}
                                                        <DropdownItem className="fs-14">
                                                            <TwitterShareButton url={`${this.state.protocol}//${this.state.host}/${searchResultsType == "all" ? "business" : searchResultsType}/${item?._id}/${item._source?.slug && item._source?.slug != null ? item._source?.slug : ''}&name=${item._source?.name}&picture=${item._source && item._source.listing_profileimage && item._source.listing_profileimage.length > 0 ? item._source.listing_profileimage[0].thumbnail : "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"}`} via={this.state.shareVia} hashtags={this.state.twitterHashTags}>Twitter</TwitterShareButton>
                                                        </DropdownItem>
                                                        <DropdownItem className="fs-14" onClick={() => this.handleOnClickShareByEmail(item)}>By Email</DropdownItem>
                                                    </DropdownMenu>

                                                </UncontrolledDropdown>
                                            </div>
                                            <div className="px-1" hidden={searchResultsType == "people"
                                                ? true
                                                : false
                                            }>
                                                <UncontrolledDropdown onMouseOver={() => this.onMouseEnterCategory(true, item._id)}
                                                    onMouseLeave={() => this.onMouseLeaveCategory(false, item._id)}
                                                    isOpen={this.state.homeDropdownOpen[item._id]}
                                                    toggle={() => this.toggleCategory(this.state.homeDropdownOpen[item._id])}>
                                                    <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                        <div className="thumbpin-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary-alt" right>
                                                        {item?._source?.content_type === "actors" ? '' :
                                                            <DropdownItem
                                                                className="fs-14"
                                                                onClick={() => this.handleOnClickAddToList('want list', item._source.pins?.user_want_status, item)}>
                                                                {item._source.pins?.user_want_status ?
                                                                    <div className="addto-icon other-want-list"></div>
                                                                    : <div className="addto-icon want-list"></div>
                                                                }
                                                                {item._source.pins?.user_want_status ? 'Added' : 'Add'} {'to want list'}
                                                            </DropdownItem>
                                                        }
                                                        <DropdownItem
                                                            className="fs-14"
                                                            onClick={() => this.handleOnClickAddToList('favourite list', item._source.pins?.user_favourites_status, item)}>
                                                            {item._source.pins?.user_favourites_status ?
                                                                <div className="addto-icon other-favorite-list"></div>
                                                                : <div className="addto-icon favorite-list"></div>
                                                            }
                                                            {item._source.pins?.user_favourites_status ? 'Added' : 'Add'} {'to favorites'}
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            className="fs-14"
                                                            onClick={() => this.handleOnClickAddToList('watch list', item._source.pins?.user_watch_status, item)}>
                                                            {item._source.pins?.user_watch_status ?
                                                                <div className="addto-icon other-watch-list"></div>
                                                                : <div className="addto-icon watch-list"></div>
                                                            }

                                                            {item._source.pins?.user_watch_status ? 'Added' : 'Add'} {'to watch list'}
                                                        </DropdownItem>



                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            <div className="px-1">
                                                {isOpen && isOpen[item?._id] ?
                                                    <span className="icon-minus" title="Collapse"
                                                        onClick={async () => {
                                                            await this.setState({ searchResultId: item._id })
                                                            this.handleOnExpand(false, item._id);
                                                        }}></span>
                                                    :
                                                    <span className="icon-plus" title="Expand"
                                                        onClick={async () => {
                                                            await this.setState({ searchResultId: item._id })
                                                            this.handleOnExpand(true, item._id);
                                                        }}></span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {type != "people" && item._type != "user" ?
                                    <div className="ff-alt">
                                        <div className="mt-2">
                                            <span>Categories: </span>
                                            <span className="font-weight-bold">{item._source && item._source.category != null ? item._source.category.replace(/,/g, ', ') : ""}</span>
                                        </div>
                                    </div>
                                    : ""}

                                {/* More details */}
                                <div className={searchResultId == item._id && isOpen[item?._id] !== true ? "hideShow" : ""}>
                                    <div className="ff-alt" hidden={
                                        (type == "all" || type == "business" || type == "professionals") && item._source && item._source.address &&
                                            item._source.address.length > 0
                                            ? false
                                            : true
                                    }>
                                        <div className="mt-2">
                                            <span>Address: </span>
                                            <span className="font-weight-bold">{item._source && item._source.address &&
                                                item._source.address.length > 0 ? `${`${item._source.address[0].address1 ? item._source.address[0].address1 : ''}` + ", " + `${item._source.address[0].city ? item._source.address[0].city : ''}` + ", " + `${item._source.address[0].state ? item._source.address[0].state : ''}` + " " + item._source.address[0].zipcode + " " + item._source.address[0].country}` : "NA"}</span>
                                        </div>
                                    </div>
                                    <div className="ff-alt" hidden={
                                        (type == "all" || type == "business" || type == "professionals") && item._source && item._source.phone_set &&
                                            item._source.phone_set.length > 0 && item._source.phone_set[0].phone
                                            ? false
                                            : true
                                    }>
                                        <div className="mt-2">
                                            <span>Phone: </span>
                                            <span className="font-weight-bold">
                                                {(item._source && item._source.phone_set && item._source.phone_set.length > 0) ?
                                                    <span>
                                                        <span className="text-darker">{this.formatPhoneNumber(item._source.phone_set[0].phone)}</span> {item._source.phone_set[0].label}
                                                    </span>
                                                    : "NA"
                                                }</span>
                                        </div>
                                    </div>
                                    <div className="ff-alt" hidden={type == "movies" && item && item.movie_release_date
                                        ? false
                                        : true
                                    }>
                                        <div className="mt-2" >
                                            <span>Release Date: </span>
                                            <span className="font-weight-bold">{item._source && item._source.movie_release_date ? moment(item._source.movie_release_date).format('MMMM DD YYYY') : "NA"}</span>
                                        </div>
                                    </div>
                                    <div className="ff-alt" hidden={
                                        (type == "all" || type == "business" || type == "professionals") && item._source && item._source.website_set &&
                                            item._source.website_set.length > 0
                                            ? false
                                            : true
                                    }>
                                        <div className="mt-2" >
                                            <span>Website: </span>
                                            <span className="font-weight-bold text-dark"><a className="text-reset text-decoration-none" rel="noopener noreferrer" href={item._source && item._source.website_set && item._source.website_set.length > 0 ? item._source.website_set[0].website : "#"} target="_blank">{item._source && item._source.website_set && item._source.website_set.length > 0 ? item._source.website_set[0].website : "NA"}</a></span>
                                        </div>
                                    </div>

                                    <div className="ff-alt" hidden={
                                        type == "products" && item._source && item._source.manufacturer
                                            ? false
                                            : true
                                    }>
                                        <div className="mt-2">
                                            <span>Manufacturer: </span>
                                            <span className="font-weight-bold">{item._source && item._source.manufacturer != null ? item._source.manufacturer : "NA"}</span>
                                        </div>
                                    </div>

                                    <div className="ff-alt" hidden={
                                        type == "projects" && item._source && item._source.created_date != null
                                            ? false
                                            : true
                                    }>
                                        <div className="mt-2">
                                            <span>Created On: </span>
                                            <span className="font-weight-bold">{item._source && item._source.created_date != null ? moment(item._source.created_date).format('MMMM DD YYYY') : "NA"}</span>
                                        </div>
                                    </div>

                                    <div className="ff-alt" hidden={
                                        type == "projects" && item._source && item._source.created_by != null
                                            ? false
                                            : true
                                    }>
                                        <div className="mt-2">
                                            <span>Created By: </span>
                                            <span className="font-weight-bold">{item._source && item._source.created_by != null ? item._source.created_by : "NA"}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-right mt-2" hidden={searchResultsType == "projects" && item._source.content_type == 'projects'
                                    ? false
                                    : true
                                }>

                                    <Link
                                        to={{
                                            pathname: `/${searchResultsType == "all" && item._source?.content_type ? (item._source?.content_type).toLowerCase() : searchResultsType}/${item._source?.slug ? item._source?.slug : ''}`,
                                            // pathname: `/${searchResultsType == "all" ? "business" : searchResultsType}/${item._source?.slug ? item._source?.slug : ''}`,
                                            state: { id: item?._id }
                                        }} >
                                        <Button color="tertiary" size="sm">Go to CRP listing</Button></Link>&nbsp;&nbsp;
                                    <Button color="primary" size="sm" hidden={item._source.created_by_id != my_profile_info?.id ? false : true}>Review this CRP</Button>
                                </div>
                                <div className="text-right mt-2" hidden={searchResultsType != 'people' && item._type != 'user' && searchResultsType != "projects" && item._source.content_type != 'projects'
                                    ? false
                                    : true
                                }>
                                    <Link
                                        to={{
                                            pathname: `/${item._source?.content_type ? ((item._source?.content_type).toLowerCase() === "professionals" ? item._source?.professional_type : item._source?.content_type?.toLowerCase()) : searchResultsType}/${item._source?.slug ? item._source?.slug : item._source?.id}`,
                                            // pathname: `/${searchResultsType == "all" ? "business" : searchResultsType}/${item._source?.slug ? item._source?.slug : ''}`,
                                            state: { id: item?._id }
                                        }} >
                                        <Button color="tertiary" size="sm">Go to listing</Button></Link>&nbsp;&nbsp;
                                    <Button color="primary" size="sm" onClick={() => { this.handleOnWriteReview(item, "others"); }}>write a review</Button>
                                </div>

                                <div className="text-right mt-2" hidden={searchResultsType == "people" || (searchResultsType == "all" && item._type == "user")
                                    ? false
                                    : true
                                }>
                                    {item?._source?.user?.id != my_profile_info?.user?.id && item._source?.friend_request_accepted == false && item?._source?.friend == true ?
                                        <Button color="tertiary" size="sm">Friend Requested</Button>
                                        :
                                        <Button color="tertiary" size="sm" onClick={() => { this.handleClickOkay(item, "send"); }}>Add Friend</Button>
                                    }
                                    {item?._source?.user?.id != my_profile_info?.user?.id && !item._source?.following_flag ?
                                        <Button color="tertiary" size="sm" onClick={() => { this.handleClickOkay(item, "Follow"); }}>Follow</Button>
                                        :
                                        <Button color="tertiary" size="sm" onClick={() => { this.handleClickOkay(item, "cancelFollow"); }}>Cancel Following</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        {isOpen && isOpen[item?._id] && item._source?.review != "" && type != "people" && item._type != "user" ?
                            // Most Recent Review 
                            <div>
                                <div className="py-4 px-3 mt-3" style={{ backgroundColor: '#edeef3' }}>
                                    <div className="d-flex mx-n2">
                                        <div className="px-2">
                                            {/*<img className="img-circle _30x30" src={item._source?.review ? item._source?.review.thumbnail : require("../../assets/images/icons/user-circle.png")} alt="" />*/}
                                            <img className="img-circle _30x30" src={item._source?.review ? require("../../assets/images/icons/user-circle.png") : item._source?.review?.thumbnail} alt="" />
                                        </div>
                                        <div className="px-2">
                                            <a href="#" className="text-dark font-weight-bold text-decoration-none">{item._source?.review ? item._source?.review.reviewer_name : ""}</a>
                                            <span className="text-tertiary ml-2">
                                                {item._source?.review ? item._source?.review.review_date_time : ""}
                                            </span>
                                            <div className="mt-2">
                                                {item._source?.review ? item._source?.review.review.replace(/(<([^>]+)>)/gi, "") : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }
                    </div>

                )
                //}
            })
        } else {
            return searchFetchLoader ? (
                <Alert color="primary">Loading...</Alert>
            ) : (
                    <div className="bg-white px-3 py-1">
                        <span className="text-dark font-weight-bold">No matching records found at your location</span>
                    </div>
                );
        }
    }

    autoAdjustZoom() {
        const map = this.refMap.leafletElement;  //get native Map instance
        const group = this.groupRef.current.leafletElement; //get native featureGroup instance
        map.fitBounds(group.getBounds().pad(0.5));
    }

    //Sort By Rating and Best
    handleOnSortBy = (type, rating) => {
        let { location } = this.props;
        const { searchResultsType, pageNo } = this.state
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                let page = pageNo;
                let url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&size=5&page=${page}&stars=${rating}&sortby=${type}`;
                if (type != "" || rating != "") {
                    if (searchResultsType == "all") {
                        url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&size=5&page=${page}&stars=${rating}&sortby=${type}`;
                        this.setState({ sortBy: type, stars: rating ,  searchResultData: {},
                            searchFetchLoader: true,}, () => {
                                this.props.serach_home_result_([]);
                                this.props.serach_home_result(url);
                            });
                    } else {
                        url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=1&stars=${rating}&cat=&subcat=&searchPage=true&size=5&radius=&manufacturer=`;
                        this.setState({ sortBy: type, stars: rating, searchResultData: {},
                            searchFetchLoader: true, }, () => {
                                this.props.serach_home_result_([]);
                                this.props.serach_home_result(url);
                            });
                        let priceData = this.state.priceValue.join(',');
                        let tragetData = this.state.targetId.join();
                        if (this.state.priceValue.length > 0) {
                            let urlPrice = `/api/GetPriceFilteredResult/?attribute=&content_type=${searchResultsType}&price=${priceData}&target_id=${tragetData}`
                            this.props.fetch_price_sort(urlPrice);
                        }
                    }

                }
            }
        }
    }
    //Sort By Price
    handleOnPrice = (event) => {
        let { location } = this.props;
        const { searchResultsType, categoryId } = this.state
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                if (event.target.name == "filterByPriceOne") {
                    if (event.target.checked == true) {
                        this.state.priceValue.push(event.target.value)
                    } else {
                        this.state.priceValue.pop(event.target.value)
                    }

                } else if (event.target.name == "filterByPriceTwo") {
                    if (event.target.checked == true) {
                        this.state.priceValue.push(event.target.value)
                    } else {
                        this.state.priceValue.pop(event.target.value)
                    }

                } else if (event.target.name == "filterByPriceThree") {

                    if (event.target.checked == true) {
                        this.state.priceValue.push(event.target.value)
                    } else {
                        this.state.priceValue.pop(event.target.value)
                    }

                } else {
                    if (event.target.checked == true) {
                        this.state.priceValue.push(event.target.value)
                    } else {
                        this.state.priceValue.pop(event.target.value)
                    }
                }
                let priceData = this.state.priceValue.join(',');
                let featureData = "";
                let tragetData = this.state.targetId.join();
                if (this.state.featureValue.length > 0) {
                    featureData = this.state.featureValue.join(',');
                }
                if (this.state.priceValue.length > 0) {
                    let urlPrice = `/api/GetPriceFilteredResult/?attribute=${featureData}&content_type=${searchResultsType}&price=${priceData}&target_id=${tragetData}`
                    this.props.fetch_price_sort(urlPrice);
                } else {
                    let urlSeach = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=1&stars=&cat=${categoryId}&subcat=&searchPage=true&size=5&radius=&manufacturer=`;
                    this.props.serach_home_result(urlSeach);
                }

            }
        }
    }

    handleOnClickAddToList = (type, status, item) => {
        
        let message = '',
            url = '',
            nextUrl = '',
            key = '',
            data = { type };

        let searchResultData = this.state.searchResultData;

        if (type.includes('want')) {
            key = 'user_want_status';
        } else if (type.includes('favourite')) {
            key = 'user_favourites_status';
        } else {
            key = 'user_watch_status';
        }

        if (status) {
            message = `${item._source.name} has been removed from your ${type}ing`;
            if (item._source.content_type === "professionals") {
                url = `/api/professionalpins/${item._id}/?type=${type}`;
            } else {
                url = `/api/pins/${item._id}/?type=${type}`;
            }
            //this.props.remove_from_list(url, message, nextUrl);
			this.props.add_remove_to_list(url, message, nextUrl);
            item._source.pins[key] = false;
        } else {
            message = `${item._source.name} has been added to your ${type}ing`;
            if (item._source.content_type === "professionals") {
                url = `/api/professionalpins/?type=${type}&user=`;
			    data.professionaluser_entry = item._id;
            } else {
                url = `/api/pins/?type=${type}&user=`;
			    data.user_entry = item._id;
            }
            //this.props.add_to_list(url, data, message, next   Url);
            this.props.add_remove_to_list(url, message, nextUrl,data);
            item._source.pins[key] = true;
        }
        let newResults = searchResultData.results.map(i => {
            if (i._id === item._id) {
                return item;
            } else {
                return i;
            }
        })

        this.setState({
            searchResultData: {
                ...this.state.searchResultData,
                results: newResults
            }
        });
    }

    handleOnWriteReview = async (data, type) => {
        if (type == "others") {
            this.setState({
                writeReviewModalToggleOne: true,
                businessId: data._id,
                reviewName: data._source?.name ? data._source?.name : '',
                writePerson: (data._source?.content_type).toLowerCase(),
                castPerson: data._source?.cast ? data._source?.cast.split(',') : [],
                categoryType: (data._source?.content_type).toLowerCase()
            }, () => {
                this.props.get_chart_star_ratings(data._id);
                this.props.fetch_detailedstarrating(data._id);
                this.props.get_business_QA_dropdown(data._id);
            });
        } else {
            this.setState({
                writeReviewModalToggleOne: true,
                businessId: data.id,
                reviewName: data?.name ? data?.name : '',
                categoryType: (data._source?.content_type).toLowerCase()
            }, () => {
                this.props.get_chart_star_ratings(data._id);
                this.props.fetch_detailedstarrating(data.id);
                this.props.get_business_QA_dropdown(data._id);
            });
        }

       await this.fetchDataFromHtmlForm({ businessId: data._id });
    }

    componentDidUpdate(prevProps) {
        if (this.props.business_rating !== prevProps.business_rating) {

            const rating = []
            Object.keys(this.props.business_rating).map((iteam) => {
                if (iteam !== "avg_rating" && iteam !== "code") {
                    rating.push({
                        key: iteam,
                        value: 0,
                        checked: false
                    })
                }
            })
            this.setState({
                businessRatingList: rating,
                businessRatingListDefaut: rating
            })
        }

        let { location, history } = this.props;
        const { pageNo } = this.state;
        let pathname = this.props.history.location?.pathname;
        let contenttype = this.props?.history?.location?.state?.contenttype;
        let searchText = new URLSearchParams(this.props.location.search).get("q");
        if ((contenttype && contenttype != this.state.searchResultsType) || (this.state.querySearch !== searchText)) {
            if (location?.search) {
                const parsed = queryString.parse(location.search);
                if (parsed?.q) {
                    // let url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&size=5&page=${page}&stars=&manufacturer=`;
                    // if (contenttype && contenttype != "" && contenttype != "undefined") {
                    //     if (contenttype == "people") {
                    //         url = `/api/search/?location_add=${this.getLocationParams()}&user=${query}&page=1`;
                    //     } else {
                    //         url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${contenttype}&page=1&stars=&cat=&subcat=&searchPage=true&size=5&radius=&citysearch=true`;
                    //     }

                    // }
                    let url = this.getSearchResultURL();

                    this.props.serach_home_result_([]);

                    this.setState({ 
                        querySearch: parsed?.q, 
                        allType: "all", 
                        searchResultsType: contenttype, 
                        searchResultData: []
                     }, () => this.props.serach_home_result(url));
                }
            }
            /*setTimeout(() => {
             
                this.setState({ searchFetchLoader: false })
            }, 7000);*/
            this.mapCordinatesSet();
            this.props.clear_category();
            this.props.clear_subcategory();
            history.replace({
                ...this.props.location,
                state: undefined
            })
        }
        if(this.props.isLoggedIn !== prevProps.isLoggedIn){
            window.location.reload();
        }
    }

    ratingChanged = async (newRating, name, enable = false) => {
        let reviewDynamicItems = this.state.reviewDynamicItems;
        if (name !== "avg_rating") {
            await this.updateSecurityHash({ businessId: this.state.businessId });
        }

        if (reviewDynamicItems?.csrfmiddlewaretoken == null || reviewDynamicItems?.csrfmiddlewaretoken == undefined) {
            await this.fetchDataFromHtmlForm({ businessId: this.state.businessId });
            reviewDynamicItems = this.state.reviewDynamicItems
        }

        const { save_ratings_vote } = this.props
        let { businessRatingList } = this.state
        if (!enable) {
            businessRatingList[businessRatingList.findIndex(item => item.key === name)].value = newRating
            this.setState({
                businessRatingList,
                writeReviewModalToggleTwo: true
            })

        } else {
            this.setState({
                [name]: newRating,
                enableRating: true,
                writeReviewModalToggleTwo: true
            })
        }


        let data = {
            csrfmiddlewaretoken: reviewDynamicItems?.csrfmiddlewaretoken,
            score: newRating,
            content_type: reviewDynamicItems?.content_type,
            object_pk: reviewDynamicItems?.object_pk,
            key: name,
            timestamp: reviewDynamicItems?.timestamp,
            security_hash: reviewDynamicItems?.security_hash,
            honeypot: reviewDynamicItems?.honeypot ? reviewDynamicItems?.honeypot : '',
            rate_unq_key: reviewDynamicItems?.uniqrate
        }

        save_ratings_vote(data)
    }

    ratingChangedPerson = (newRating, name) => {
        const { save_ratings_vote_person } = this.props
        let { personId } = this.state
        this.setState({
            [name]: newRating,
            enableRating: true,
            writeReviewModalToggleTwo: true
        })

        save_ratings_vote_person({ name, vote: newRating, personId })
    }

    /*ratingChangedForCheck = (checked, name,) => {
        let { businessRatingList } = this.state
        businessRatingList[businessRatingList.findIndex(item => item.key === name)].Checked = checked
        this.setState({
            businessRatingList
        })
    }*/

    ratingChangedForCheck = (checked, data) => {
        if (checked) {
            this.state.businessRatingIds.push(data.id);
        } else {
            let el = this.state.businessRatingIds.find(
                (itm) => itm === parseInt(data.id)
            );
            if (el)
                this.state.businessRatingIds.splice(
                    this.state.businessRatingIds.indexOf(el),
                    1
                );
        }
    }

    handelChangeReview = (e) => {
        // 'QualityofEquipmentCheck'

        this.setState({
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        }, () => {
            if (this.state.write_new_review?.length >= 50) {
                this.setState({ enableErrorForReview: false })
            }

            if (this.state.write_new_review?.length < 50) {
                this.setState({ enableErrorForReview: true })
            }
        });
    }


    publishPost = async (dataList) => {

        let { reviewDynamicItems, avg_rating, mediaSetReview, write_new_review, businessId, businessRatingIds, shocialNetworkSharing, isadhere, stepTwoUploadedVideo, embedBody } = this.state
        const { publish_post } = this.props;
        let mediaSetDataItems = [];


        if (write_new_review?.length < 50) {
            this.setState({ enableErrorForReview: true });
            return
        }

        if (mediaSetReview?.length) {
            mediaSetDataItems = mediaSetReview.map(i => i["id"]);
        }

        if (stepTwoUploadedVideo?.length) {
            let itemsGet = stepTwoUploadedVideo.map(data => data.id);
            mediaSetDataItems = mediaSetDataItems.concat(itemsGet);
        }

        const data = {
            associated_to: businessId,
            avg_rate_val: avg_rating,
            followup: false,
            followup_avg_rate: false,
            is_draft: dataList === "draft",
            media: mediaSetDataItems,
            review: write_new_review,
            review_rating_category: businessRatingIds,
            review_voting_key: reviewDynamicItems.uniqrate,
            social_broadcast: shocialNetworkSharing == true ? true : false,
            social_broadcast_facebook: shocialNetworkSharing == true ? true : false,
            social_broadcast_twitter: shocialNetworkSharing == true ? true : false,
            tips_guide: isadhere
        }

        if (embedBody?.length) {
            data['embed_body'] = embedBody.join(',');
        }

        await publish_post(data, businessId);
        await this.clearReveiwRatingState();
        await this.fetchDataFromHtmlForm({ businessId });
        await this.props.history.push({
            pathname: "/myprofile",
            state: {
                mainViewType: "reviews"
            }
        });
        this.setState({ viewEditReviewStep: 'writeReview', embedBody: [], reviewDynamicItems: {}, enableErrorForReview: true });
        // await get_business_detail(businessId);
    }

    setMediaSetForItems = (e, item) => {
        // e.preventDefault();
        let { mediaSet } = this.state;
        if (item && item.id) {
            let id = item.id;
            let itemFiltered = mediaSet.find(item => item.id == id);
            if (itemFiltered) {
                const index = mediaSet.indexOf(itemFiltered);
                if (index > -1) {
                    mediaSet.splice(index, 1);
                }
            } else {
                mediaSet.push(item)
            }
            this.setState({ mediaSet, mediaSetReview: mediaSet });
        }
    }

    handleCaptionByIndex = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData, taxonomycategoryId } = this.state;
        uploadCaptions[index] = value;
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = taxonomycategoryId;
        let id = upload_data.id;
        this.setState({ uploadCaptions, captionData: caption_data }, () => this.props.upload_add_captions(id, data));

    }

    handleChangeCategory = (e, index) => {
        let { latestUploadedData, uploadedId } = this.state;
        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        let isImageType = upload_data.media_type;
        uploadedId[index] = id;
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ albumCategoryId: albumType, uploadedId });
        let categoryId = "business";
        let subcategoryId = value;
        if (isImageType == "image") {
            this.props.fetch_categories_data(categoryId, subcategoryId, 'image', index);
        } else {
            this.props.fetch_categories_data(categoryId, subcategoryId, 'video', index);
        }
    }

    handleChangeTaxonomyData = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData } = this.state
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = value;
        let id = upload_data.id;
        this.setState({ taxonomycategoryId: value, captionData: caption_data }, () => this.props.upload_add_captions(id, data))

    }

    clearReveiwRatingState = async () => {

        await this.setState({
            writeReviewModalToggleTwo: false, writeReviewModalToggleOne: false, enableRating: false,
            avg_rating: 0,
            businessRatingList: this.state.businessRatingListDefaut,
            write_new_review: "",
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            searchTheater: '',
            albumType: '',
            fetchTaxonomy: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            businessRatingIds: [],
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            categoryType: "",
            uploadedId: {},
            albumCategoryId: "",
            captionData: ""
        })
    }

    checkIsAdded = (item) => {
        let { mediaSet } = this.state;
        let id = item.id;
        let itemFiltered = mediaSet.find(item => item.id == id);
        if (itemFiltered) {
            return true;
        } else {
            return false;
        }
    }

    removeFromMedia = (e, item) => {
        e.preventDefault();
        let { mediaSet } = this.state;
        let id = item.id;
        let itemFiltered = mediaSet.find(item => item.id == id);
        if (itemFiltered) {
            const index = mediaSet.indexOf(itemFiltered);
            if (index > -1) {
                mediaSet.splice(index, 1);
            }
            this.setState({ mediaSet, mediaSetReview: mediaSet });
        }
    }

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = this.state;
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0) {

                return getDataItems.map(item => {

                    let itemName = (item?.thumbnail).split('.');
                    let itemFile = (item?.file).split('.');
                    let extName = itemName[itemName.length - 1];
                    let extFile = itemFile[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview
                    item['srcFile'] = isVideo ? srcFile : item?.file;
                    return (
                        <Col xs="auto" className="mb-3" >
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={(e) => { this.setMediaSetForItems(e, item) }} checked={this.checkIsAdded(item)} />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    checkIfVideo = (item) => {
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        let itemName = (item.thumbnail).split('.');
        let itemFile = (item.file).split('.');
        let extFile = itemFile[itemFile.length - 1];
        let srcFile = imagePreview;

        if (['jpg', 'jpeg', 'png', 'gif'].includes(extFile)) {
            srcFile = item.file;
        }
        return srcFile;
    }

    handleOnFileUploadChange = (e) => {
        let { selectedFilesPreview, files, categoryType } = this.state;
        const filesSetForUpload = Array.from(e.target.files);
        files = files.concat(filesSetForUpload);
        let self = this;
        this.setState({ files }, () => {
            Promise.all(files.map(file => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            })).then(images => {
                let concatWithPrev = selectedFilesPreview.concat(images);
                self.setState({ selectedFilesPreview: concatWithPrev })
            }, error => {
                console.error(error);
            });
        });

        if (files && Array.isArray(files) && files.length > 0) {
            let indexSelected = selectedFilesPreview.length - 1;
            files.forEach(async (item, index) => {
                if (index > indexSelected) {
                    let formData = new FormData();
                    formData.append('file', item);

                    let name = item.name;
                    let split_items = name.split('.');
                    let item_ext = split_items[split_items.length - 1];
                    let isImageTrue = ['jpg', 'png', 'jpeg', 'gif', 'tiff'].includes(item_ext);
                    let url = `/upload/multiuploader/?album=reviews&instance=review_1&image=undefined&doc=undefined`
                    this.setState({ isImageType: isImageTrue })
                    if (isImageTrue) {
                        await this.props.upload_media_filesData(url, 'image', formData, index);
                    } else {
                        await this.props.upload_media_filesData(url, 'video', formData, index);
                    }
                    await this.props.filter_categories_data({ category: categoryType, sub_cat: "", level: 1 });
                }
            });
        }
    }

    handleChangeTaxonomy = (e) => {
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ fetchGalleryScroll: {}, fetchGalleryAlbum: value, fetchGalleryType: 'image' }, () => this.props.media_upload_scroll({ type: 'image', album: albumType, page: 1, force_update: true }))
    }

    clearMediaDispatch = () => {
        this.setState({
            latestUploadedData: {}, uploadCaptions: {}, selectedFilesPreview: [], files: [], taxonomycategoryId: "",
            fetchTaxonomyListingImages: {}, fetchTaxonomyListingVideos: {}, captionData: "", uploadedId: {}, cattaxListing: {}, albumCategoryId: ""
        }, () => {
            this.props.clear_media_dispatch(); this.props.clear_category();
            this.props.clear_subcategory();
        });
    }


    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    handleScrollGalleryVideo = (e) => {
        let { refGalleryVideoScroll } = this;
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    handleSubmitCaptions = (e, index) => {
        let { uploadCaptions, latestUploadedData } = this.state
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;

        let id = upload_data.id;
        this.props.upload_add_captions(id, data);
    }

    removeSelectedImage = (e, index) => {
        let { latestUploadedData, selectedFilesPreview, files, uploadedId, fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state

        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        this.props.single_delete_from_upload(id);

        // delete items from array
        selectedFilesPreview.splice(index, 1);
        files.splice(index, 1); // deleted this because it concat from the upload function.

        // deleted items from json
        delete latestUploadedData[index];
        delete uploadedId[index];
        delete fetchTaxonomyListingImages[index];
        delete fetchTaxonomyListingVideos[index];

        // deleted items from json
        if (latestUploadedData[index] == undefined) {
            let newItems = [];

            // turn json to array for indexes
            Object.values(latestUploadedData).forEach(it => { newItems.push(it) });

            // turn array to json for indexes
            if (newItems && Array.isArray(newItems) && newItems.length) {
                let newItemsJson = {};
                newItems.forEach((nitem, index) => {
                    newItemsJson[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJson).length) {
                    latestUploadedData = newItemsJson;
                }
            }
        }

        if (uploadedId[index] == undefined) {
            let newItemsf = [];

            // turn json to array for indexes
            Object.values(uploadedId).forEach(it => { newItemsf.push(it) });

            // turn array to json for indexes
            if (newItemsf && Array.isArray(newItemsf) && newItemsf.length) {
                let newItemsJsond = {};
                newItemsf.forEach((nitem, index) => {
                    newItemsJsond[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsond).length) {
                    uploadedId = newItemsJsond;
                }
            }
        }

        if (fetchTaxonomyListingImages[index] == undefined) {
            let newItemsImages = [];

            // turn json to array for indexes
            Object.values(fetchTaxonomyListingImages).forEach(it => { newItemsImages.push(it) });

            // turn array to json for indexes
            if (newItemsImages && Array.isArray(newItemsImages) && newItemsImages.length) {
                let newItemsJsonImages = {};
                newItemsImages.forEach((nitem, index) => {
                    newItemsJsonImages[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsonImages).length) {
                    fetchTaxonomyListingImages = newItemsJsonImages;
                }
            }
        }

        if (fetchTaxonomyListingVideos[index] == undefined) {
            let newItemsVideos = [];

            // turn json to array for indexes
            Object.values(fetchTaxonomyListingVideos).forEach(it => { newItemsVideos.push(it) });

            // turn array to json for indexes
            if (newItemsVideos && Array.isArray(newItemsVideos) && newItemsVideos.length) {
                let newItemsJsonVideo = {};
                newItemsVideos.forEach((nitem, index) => {
                    newItemsJsonVideo[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsonVideo).length) {
                    fetchTaxonomyListingVideos = newItemsJsonVideo;
                }
            }
        }

        this.setState({ selectedFilesPreview, latestUploadedData, files, uploadedId, fetchTaxonomyListingVideos, fetchTaxonomyListingImages });
    }

    handleScrollGalleryImage = (e) => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated == scrollTop ||
            scrollTop == scrollMinimun_1 ||
            scrollTop == scrollMinimun_3 ||
            scrollTop == scrollMinimun_5 ||
            scrollTop == scrollMinimun_6 ||
            scrollTop == scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    AddNewEmbedLink = (e) => {
        e.preventDefault();
        let { embeddedLink, embedBody } = this.state;
        // this.props.embedNewLink({
        //     data: {
        //         "video": `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
        //         "exclude_list": [],
        //         "body_preview": `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
        //         "question_rating_category": [],
        //         "messagemedia_set": []
        //     }
        // });
        embedBody.push(embeddedLink);
        this.setState({ embeddedLink: '', embedBody, uploadMediaModal: false })
    }

    handleCloseAndFetchReview = () => {

        let { mediaSetReview } = this.state;
        this.setState({ uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: true, mediaSet: mediaSetReview }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);
        });
    }


    handleCloseAndFetch = () => {
        let { latestUploadedData, fileReviewStatus, mediaSetReview } = this.state;
        if (fileReviewStatus && Object.values(latestUploadedData) && Object.values(latestUploadedData)[0] && Object.values(latestUploadedData)[0].id) {
            Object.values(latestUploadedData).map((list) => {
                mediaSetReview.push({
                    "id": list.id,
                    "file": list.url,
                    "thumbnail": list.thumbnail,
                    "url": list.url,
                })
            })

        }
        this.setState({ uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: false, mediaSetReview }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);
        });
    }

    checkImages = (item) => {
        let items = (item.tos).split(',');
        if (items.length > 1) {
            return group_icons;
        } else {
            return item.current_profile_pic;
        }
    }

    handleChangeRatings = (item) => {

        this.setState({ setRatingFrom: item })
    }

    // Sort by distance
    handleOnDistanceBetween = (distancetype) => {
        let { location } = this.props;
        const { searchResultsType, stars } = this.state
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                if (distancetype != "") {
                    let url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=1&stars=${stars}&cat=&subcat=&searchPage=true&size=5&radius=${distancetype}&manufacturer=`;
                    this.setState({ distanceBetween: distancetype }, () =>{
                        this.props.serach_home_result_([]);
                        this.props.serach_home_result(url)
                        });
                    let priceData = this.state.priceValue.join(',');
                    let tragetData = this.state.targetId.join();
                    if (this.state.priceValue.length > 0) {
                        let urlPrice = `/api/GetPriceFilteredResult/?attribute=&content_type=${searchResultsType}&price=${priceData}&target_id=${tragetData}`
                        this.props.fetch_price_sort(urlPrice);
                    }
                }
            }
        }
    }

    // Sort By Brand
    handleOnProduct = (type) => {
        let { location } = this.props;
        const { searchResultsType, stars } = this.state
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                if (type != "") {
                    let url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=1&stars=${stars}&cat=&subcat=&searchPage=true&size=5&radius=&manufacturer=${type}`;
                    this.setState({ brandType: type }, () => {
                        this.props.serach_home_result_([]);
                        this.props.serach_home_result(url)
                    });
                    let priceData = this.state.priceValue.join(',');
                    let tragetData = this.state.targetId.join();
                    if (this.state.priceValue.length > 0) {
                        let urlPrice = `/api/GetPriceFilteredResult/?attribute=&content_type=${searchResultsType}&price=${priceData}&target_id=${tragetData}`
                        this.props.fetch_price_sort(urlPrice);
                    }
                }
            }
        }
    }

    // Sort By City
    handleOnClickCity = (citType, range) => {
        let { location } = this.props;
        const { searchResultsType, stars, distanceBetween } = this.state
        const parsed = queryString.parse(location.search);
        if (location?.search) {
            if (parsed?.q) {
                let query = parsed?.q;
                if (citType != "" && searchResultsType == "business" && range == 5) {
                    let url = `/api/search/?location_add=${this.getLocationParams()}&q=${query}&content_type=${searchResultsType}&page=1&stars=${stars}&cat=&subcat=&searchPage=true&size=5&radius=${distanceBetween}&city=${citType}`;
                    this.setState({ CityType: citType, nearByCitiesModalToggle: !this.state.nearByCitiesModalToggle }, () => {
                        this.props.serach_home_result_([]);
                        this.props.serach_home_result(url)
                    });
                    let priceData = this.state.priceValue.join(',');
                    let tragetData = this.state.targetId.join();
                    if (this.state.priceValue.length > 0) {
                        let urlPrice = `/api/GetPriceFilteredResult/?attribute=&content_type=${searchResultsType}&price=${priceData}&target_id=${tragetData}`
                        this.props.fetch_price_sort(urlPrice);
                    }
                } else {
                    this.setState({ CityType: citType });
                }
            }
        }

    }

    // on click marker show name and move scrollbar
    onMarkerClick = (data) => {
        this.setState({
            showingInfoWindow: true,
            selectedId: data._id
        });
        if (this.myDivToFocus.current) {
            let elementToScrollTo = document.getElementById("content_" + data._id);
            const childOffset = 1;
            for (let i = 0; i < childOffset; i++) {
                if (!elementToScrollTo.previousElementSibling) {
                    break;
                }
                elementToScrollTo = elementToScrollTo.previousElementSibling;
            }
            elementToScrollTo.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    handleOnChangeShareByEmail = (e) => {
        let errors = { ...this.state.shareError };
        if (e.target.value) {
            delete errors[e.target.name];
        }

        this.setState({
            [e.target.name]: e.target.value,
            shareError: errors
        });
    }

    // handleOnSubmitShareByEmail = () => {
    //     let errors = { ...this.state.shareError };
    //     let { sharecontentType } = this.state
    //     if (this.state.shareName === '') {
    //         errors.shareName = 'This field is required!';
    //     }

    //     if (this.state.shareEmail === '') {
    //         errors.shareEmail = 'This field is required!';
    //     } else {
    //         const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //         if (!reg.test(String(this.state.shareEmail).toLowerCase())) {
    //             errors.shareEmail = 'Please enter a valid email!';
    //         }
    //     }
    //     if (errors && Object.keys(errors).length > 0) {
    //         this.setState({ shareError: errors });
    //     } else {
    //         let key = 'listing_id';
    //         let url = `/api/shareByemail/`;

    //         const data = {
    //             [key]: this.state.shareId,
    //             mix_uid: `${this.state.shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
    //             email: this.state.shareEmail,
    //             user_name: this.state.shareName,
    //             listing_type: sharecontentType
    //         };

    //         this.props.share_by_email(url, data);

    //         this.setState({
    //             shareByEmailModalToggle: false,
    //             shareName: '',
    //             shareEmail: '',
    //             shareError: {}
    //         });
    //     }
    // }

    handleOnSubmitShareByEmail = ({shareEmail, shareName, shareNote, category}) => {     
        let key = 'listing_id';
        const categoryType = this.state.shareCategory.toLowerCase();
        let url, data
        if (categoryType === "professionals" || categoryType === "actors") {
            url = `/api/professionalShareByemail/`;
            data = {
                [key]:this.state.shareId,
                mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
                email: shareEmail,
                user_name: shareName,
                listing_type: categoryType,
                optional_note: shareNote,
                professional: true
            };
        } else {
            url = `/api/shareByemail/`;
            data = {
                [key]:this.state.shareId,
                mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
                email: shareEmail,
                user_name: shareName,
                listing_type: categoryType,
                optional_note: shareNote
            };
        }
        this.props.share_by_email(url, data);
    }

    handleOnClickShareByEmail = (data) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: data?._source?.id,
            shareCategory: data._source.content_type,
            sharecontentType: data._source?.content_type.toLowerCase()
        });
    }

    mapCordinatesSet = () => {
        let searchResultData = this.state.searchResultData;
        const { searchResultsType } = this.state
        const skater = new Icon({
            iconUrl: "https://stagingdatawikireviews.s3.amazonaws.com/images/map-W.png",
            iconSize: [30, 40]
        });

        let hasRectangleCordinates = false;
        let latArr = [];
        let lonArr = [];
        let maxLat;
        let minLat;
        let maxLon;
        let minLon;
        let xCenter = (this.state.currentLat + 0.005735416);
        let yCenter = (this.state.currentLong - 0.097787153);
        if (searchResultData.results) {
            const searchResultsSource = searchResultData.results.map(i => i._source);
            if (searchResultsSource?.length) {
                const locationArray = searchResultsSource.map(f => f.location).filter(location => location);
                if (locationArray?.length) {
                    let locations = [];
                    locationArray.forEach(l => {
                        if (Array.isArray(l)) {
                            l.forEach(e => {
                                locations.push(e);
                            })
                        } else {
                            locations.push(l)
                        }
                    });

                    latArr = [...(locations.map(e => e?.lat) || [])];
                    lonArr = [...(locations.map(e => e?.lon) || [])];

                    if (latArr?.length >= 1 && lonArr?.length >= 1) {
                        hasRectangleCordinates = true;
                        maxLat = Math.max(...latArr) ? Math.max(...latArr) + 0.002 : null;
                        minLat = Math.min(...latArr) ? Math.min(...latArr) - 0.002 : null;
                        maxLon = Math.max(...lonArr) ? Math.max(...lonArr) + 0.002 : null;
                        minLon = Math.min(...lonArr) ? Math.min(...lonArr) - 0.002 : null;

                        xCenter = (minLat + maxLat) / 2
                        yCenter = (minLon + maxLon) / 2;

                        setTimeout(() => {
                            this.autoAdjustZoom();
                        }, 1000);

                    }
                }
            }

        }

        return (
            <div id="map">
                <Map key={this.state.keyMAP} center={{
                    lat: xCenter,
                    lng: yCenter
                }}
                    ref={(ref) => { this.refMap = ref; }}
                    zoom={this.state.zoom}
                    onClick={this.onMapClicked}
                    style={{ position: 'absolute', zIndex: '0' }}
                    boundsOptions={{
                        paddingBottomRight: [250, 0],
                    }}
                    zoomControl={false}
                >
                    <ZoomControl position="bottomright" />

                    <TileLayer
                        url={osm.maptiler.url}
                        attribution={osm.maptiler.attribution}
                        maxZoom={18}
                    />

                    <FeatureGroup ref={this.groupRef}>
                        {searchResultData.results && Array.isArray(searchResultData.results) && searchResultData.results.length ?
                            searchResultData.results.map((item, index) => {
                                if ((searchResultsType != "movies" || searchResultsType != "professionals" || searchResultsType != "actors" || searchResultsType != "projects" || searchResultsType != "products") && item?._source?.location != null) {
                                    return (<Marker
                                        position={[
                                            item?._source?.location.length > 0 ? item?._source?.location[0]?.lat : item?._source?.location?.lat,
                                            item?._source?.location.length > 0 ? item?._source?.location[0]?.lon : item?._source?.location?.lon
                                        ]}
                                        icon={skater}
                                        //onClick={this.onMarkerClick}
                                        onClick={() => {
                                            this.onMarkerClick(item);
                                        }}
                                        key={index}
                                    >
                                        <Popup><Link
                                            to={{
                                                pathname:  `/${item._source?.content_type ? ((item._source?.content_type).toLowerCase() === "professionals" ? item._source?.professional_type : item._source?.content_type?.toLowerCase()) : searchResultsType}/${item._source?.slug ? item._source?.slug : item._source?.id}`,
                                                // pathname: `/${searchResultsType == "all" ? "business" : searchResultsType}/${item._source?.slug ? item._source?.slug : ''}`,
                                                state: { id: item?._id }
                                            }}

                                        >{item._source ? item._source.name : ""}</Link></Popup>
                                    </Marker>
                                    )
                                }
                            })
                            : (null)}
                    </FeatureGroup>

                    {hasRectangleCordinates ? <Rectangle bounds={[
                        [minLat, minLon], [maxLat, maxLon]
                    ]} pathOptions={{ color: 'black' }} /> : <></>}

                    <Control position="topright">
                        <button className="leafletbutton"
                            onClick={() => { this.handleOnRestoreMap() }}
                        >
                            Restore Map
				</button>
                    </Control>
                </Map>
            </div>
        )
    }

    handleOnRestoreMap = () => {
        this.setState({ keyMAP: Math.random() })
    }

    addUploadedVideo = (item) => {
        let { stepTwoUploadedVideo } = this.state;
        stepTwoUploadedVideo.push(item);
        this.setState({ stepTwoUploadedVideo });
    }

    toggleLoginModal = () =>{
        this.props.setLoginPrompt(false);
        this.setState({writeReviewModalToggleOne:false})
    }

    render() {
        let { enableErrorForReview, querySearch, city, state, searchResultsType, searchFilterData, searchCitiesData, searchSubcategoryData, searchFeaturesData, write_new_review, shocialNetworkSharing, businessId, reviewAnonymous,
            selectedFilesPreview,
            latestUploadedData,
            fetchTaxonomy,
            mediaSetReview,
            embeddedLink,
            showVideoMedia,
            businessRatingList,
            chartStarRatings,
            ratePerson,
            first_name,
            last_name,
            avg_rating_person,
            reviewsCategories,
            isadhere,
            fetchTaxonomyListingImages,
            fetchTaxonomyListingVideos,
            uploadedId,
            cattaxListing
        } = this.state;
        let totalLength = selectedFilesPreview.length;
        let objectKeys = [];
        let objectLength = 0;
        let calculatedPercentage = 0;
        if (Object.keys(latestUploadedData).length > 0) {
            objectKeys = Object.keys(latestUploadedData);
            objectLength = objectKeys.length;
            calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
        };

        let itemEditSet = [];
        if (chartStarRatings !== null && Object.keys(chartStarRatings).length && itemEditSet.length == 0) {
            Object.keys(chartStarRatings).forEach((item, index) => {
                let textItem = item.substring(0, 4);
                let itemsSet = businessRatingList.find(it => (it.key).substring(0, 4).match(textItem));
                if (itemsSet !== undefined) {
                    itemEditSet[index] = itemsSet;
                    itemEditSet[index]['name'] = item;
                }
            });
        }
     
        let isSearchFromOut = this.props.match.path;

        const authId = localStorage.getItem('profileId');
        const { my_profile_info } = this.props;
        // .match.params.category
        return (
            <div
                style={{ maxHeight: "calc(100vh - 25px)", overflow: "auto" }}
                ref={this.myScrollSearchRef}
                onScroll={this.handleScrollSearch}>
                {(my_profile_info && my_profile_info?.id && authId && (authId == my_profile_info.id)) ? (<AppHeader isEnableCover={false} logoutToHome={true} />
                ) : (<PublicPageHeader isEnableCover={false} forceShowLoginModal={this.state.forceShowLoginModal} forceToggleLoginModal={this.forceToggleLoginModal} />)}
                {/* <AppHeader isEnableCover={false} /> */}
                <div className="fixed-map">
                    {this.mapCordinatesSet()}
                </div>

                <section style={{ marginTop: '70px' }}>
                    <div className="position-relative" style={{ marginLeft: 100, width: '100%', maxWidth: 660 }}>
                        <div className="bg-white-shaded shadow-search-list p-3 mb-3">
                            <h2 className="text-tertiary mb-2 fs-38">
                                Search Results:
                                &nbsp; 
                                <span className="text-darker">{querySearch}</span>
                                &nbsp;
                                <span className="text-dark">near</span>
                                &nbsp;
                                {
                                    city?.toUpperCase() === state?.toUpperCase() ?
                                        <span className="text-darker">{city}</span>
                                        :
                                        <>
                                            <span className="text-darker">{city},</span>
                                            &nbsp;
                                            <span className="text-darker">{state}</span>
                                        </>
                                }
                            </h2>
                            <p className="text-tertiary ff-base font-weight-bold fs-14">Select what you want from the list below.</p>
                            <div className="d-flex flex-wrap">
                                <Nav className="search-results-nav active-transparent non-active-normal active-bold flex-grow-1" tabs>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base"
                                            active={this.state.searchResultsType === "all"}
                                            onClick={() => {
                                                this.handleOnClickCategory("all");
                                            }}
                                            title="All"
                                        >
                                            All
                                            </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base"
                                            active={this.state.searchResultsType === "products"}
                                            onClick={() => {
                                                this.handleOnClickCategory("products");
                                            }}
                                            title="Search Products"
                                        >
                                            <span className="products"></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base"
                                            active={this.state.searchResultsType === "business"}
                                            onClick={() => {
                                                this.handleOnClickCategory("business");
                                            }}
                                            title="Search Business"
                                        >
                                            <span className="business"></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base"
                                            active={this.state.searchResultsType === "movies"}
                                            onClick={() => {
                                                this.handleOnClickCategory("movies");
                                            }}
                                            title="Search Movies"
                                        >
                                            <span className="movies"></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base"
                                            active={this.state.searchResultsType === "professionals"}
                                            onClick={() => {
                                                this.handleOnClickCategory("professionals");
                                            }}
                                            title="Search Professionals"
                                        >
                                            <span className="professionals"></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base"
                                            active={this.state.searchResultsType === "actors"}
                                            onClick={() => {
                                                this.handleOnClickCategory("actors");
                                            }}
                                            title="Search Actors"
                                        >
                                            <span className="actors"></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base"
                                            active={this.state.searchResultsType === "projects"}
                                            onClick={() => {
                                                this.handleOnClickCategory("projects");
                                            }}
                                            title="Search Community Review Projects"
                                        >
                                            <span className="crp-search"></span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="fs-16 ff-base"
                                            active={this.state.searchResultsType === "people"}
                                            onClick={() => {
                                                this.handleOnClickCategory("people");
                                            }}
                                            title="Search People"
                                        >
                                            <span className="people"></span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <Nav className="search-results-nav active-transparent non-active-normal active-bold ml-auto border-0 active-border-primary" tabs>
                                    <NavItem className="border-bottom border-tertiary mx-2 px-0">
                                        <NavLink
                                            className="fs-16 ff-base border-0 text-center mx-0"
                                            onClick={() => {
                                                this.setState({ showMapView: !this.state.showMapView })
                                            }}
                                            title={this.state.showMapView ? 'Show Listing' : 'Show Map'}
                                        >
                                            <div className="dual-icon-holder">
                                                {this.state.showMapView ?
                                                    <img src={listIconDual} alt="List" />
                                                    : <img src={mapIconDual} alt="Map" />}
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="border-bottom border-tertiary mx-2 px-0" hidden={this.state.searchResultsType === "people"}>
                                        <NavLink
                                            className="fs-16 ff-base text-center mx-0"
                                            active={this.state.filtersVisible}
                                            onClick={() => {
                                                this.setState({ filtersVisible: !this.state.filtersVisible });
                                                this.handleOnClickFilter();
                                            }}
                                            title={this.state.filtersVisible ? 'Filter Search' : 'Filter Search'}
                                        >
                                            <div className="dual-icon-holder">
                                                <img src={filterIconDual} alt="Filters" />
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <div className="fs-14">
                                <TabContent activeTab={this.state.filtersVisible}>
                                    <TabPane tabId={true}>
                                        <Row xs={1} md={2} lg={3}>
                                            <Col xs={12} md={12} lg={12} hidden={
                                                (searchResultsType == "products" || searchResultsType == "business" || searchResultsType == "movies" || searchResultsType == "professionals" || searchResultsType == "projects")
                                                    ? false
                                                    : true
                                            }>
                                                <ul className="bg-white p-2 d-flex mx-n2 flex-wrap list-unstyled mb-3">
                                                    <li className="px-2" onClick={() => {
                                                        this.handleOnClickAll();
                                                    }}>
                                                        <span className="filterable-item">
                                                            all
                                                                </span>
                                                    </li>
                                                    {Array.isArray(searchFilterData) && searchFilterData && searchFilterData.length > 0 ? (searchFilterData.map((item, index) => {
                                                        if (index < this.state.indexRange) {
                                                            return (
                                                                <li className="px-2" key={index} onClick={() => {
                                                                    this.handleOnMoreCategory(item.id);
                                                                }}>
                                                                    <span className="filterable-item">
                                                                        {item?.category}
                                                                    </span>
                                                                </li>
                                                            )
                                                        }
                                                    })) : (null)
                                                    }
                                                    <li className="px-2" hidden={
                                                        searchFilterData.length > 4
                                                            ? false
                                                            : true
                                                    }
                                                        onClick={() => {
                                                            this.handleOnClickMore();
                                                        }}
                                                    >
                                                        <span className="filterable-item">
                                                            <FontAwesomeIcon icon="ellipsis-h" />
                                                        </span>
                                                    </li>
                                                </ul>
                                            </Col>
                                            {/* Sort By column filter */}
                                            <Col className="mb-3" hidden={
                                                (searchResultsType == "all" || searchResultsType == "products" || searchResultsType == "business" || searchResultsType == "movies" || searchResultsType == "professionals" || searchResultsType == "actors" || searchResultsType == "projects")
                                                    ? false
                                                    : true
                                            }>
                                                <div className="font-weight-bold mb-2 text-dark">Sort By</div>
                                                <div>
                                                    <div className="text-dark mb-2">
                                                        <div className={this.state.sortBy === "best" ? "font-weight-bold" : ""} role="button" onClick={() => {
                                                            this.handleOnSortBy("best", this.state.stars);
                                                        }}>Best Match</div>
                                                    </div>
                                                    <div className="text-dark mb-2">
                                                        <div className={this.state.sortBy === "rating" ? "font-weight-bold" : ""} role="button" onClick={() => {
                                                            this.handleOnSortBy("rating", this.state.stars);
                                                        }}>Highest Overall Rating</div>
                                                    </div>
                                                    <div className="text-dark mb-2">
                                                        <div className={this.state.sortBy === "review" ? "font-weight-bold" : ""} role="button" onClick={() => {
                                                            this.handleOnSortBy("review", this.state.stars);
                                                        }}>Most Reviewed</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            {/* Distance Column Filter */}
                                            <Col className="mb-3" hidden={
                                                (searchResultsType == "business" || searchResultsType == "movies" || searchResultsType == "professionals")
                                                    ? false
                                                    : true
                                            }>
                                                <div className="font-weight-bold mb-2 text-dark">Distance</div>
                                                <div>
                                                    <div className="text-dark mb-2">
                                                        <div className={this.state.distanceBetween === "20" ? "font-weight-bold" : ""} role="button" onClick={() => {
                                                            this.handleOnDistanceBetween("20");
                                                        }}>Aerial View (5-20 Miles)</div>
                                                    </div>
                                                    <div className="text-dark mb-2">
                                                        <div className={this.state.distanceBetween === "5" ? "font-weight-bold" : ""} role="button" onClick={() => {
                                                            this.handleOnDistanceBetween("5");
                                                        }}>Driving (5 Miles)</div>
                                                    </div>
                                                    <div className="text-dark mb-2">
                                                        <div className={this.state.distanceBetween === "2" ? "font-weight-bold" : ""} role="button" onClick={() => {
                                                            this.handleOnDistanceBetween("2");
                                                        }}>Biking (2 Miles)</div>
                                                    </div>
                                                    <div className="text-dark mb-2">
                                                        <div className={this.state.distanceBetween === "1" ? "font-weight-bold" : ""} role="button" onClick={() => {
                                                            this.handleOnDistanceBetween("1");
                                                        }}>Walking (1 Mile)</div>
                                                    </div>
                                                    <div className="text-dark mb-2">
                                                        <div className={this.state.distanceBetween === "0.5" ? "font-weight-bold" : ""} role="button" onClick={() => {
                                                            this.handleOnDistanceBetween("0.5");
                                                        }}>A Few Blocks</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            {/* Price Column Filter */}
                                            <Col className="mb-3" hidden={
                                                (searchResultsType == "products" || searchResultsType == "business")
                                                    ? false
                                                    : true
                                            }>
                                                <div className="font-weight-bold mb-2 text-dark">Price</div>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark font-weight-normal" check>
                                                        <Input type="checkbox" value="1" name="filterByPriceOne" onChange={this.handleOnPrice} />{' '}
                                                                $
                                                            </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark font-weight-normal" check>
                                                        <Input type="checkbox" value="2" name="filterByPriceTwo" onChange={this.handleOnPrice} />{' '}
                                                                $$
                                                            </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark font-weight-normal" check>
                                                        <Input type="checkbox" value="3" name="filterByPriceThree" onChange={this.handleOnPrice} />{' '}
                                                                $$$
                                                            </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark font-weight-normal" check>
                                                        <Input type="checkbox" value="4" name="filterByPriceFour" onChange={this.handleOnPrice} />{' '}
                                                                $$$$
                                                            </Label>
                                                </FormGroup>
                                            </Col>
                                            {/* Brand Column Filter */}
                                            <Col className="mb-3" hidden={
                                                (searchResultsType == "products")
                                                    ? false
                                                    : true
                                            }>
                                                <div className="font-weight-bold mb-2 text-dark">Brand</div>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark font-weight-normal" check>
                                                        <Input type="checkbox" name="filterByPrice" onClick={() => {
                                                            this.handleOnProduct("APC");
                                                        }} />{' '}
                                                                APC
                                                            </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark font-weight-normal" check>
                                                        <Input type="checkbox" name="filterByPrice" onClick={() => {
                                                            this.handleOnProduct("Beiter DC Power");
                                                        }} />{' '}
                                                                Beiter DC Power
                                                            </Label>
                                                </FormGroup>

                                            </Col>
                                            {/* Star Rating Column Filter */}
                                            <Col className="mb-3" hidden={
                                                (searchResultsType == "all" || searchResultsType == "products" || searchResultsType == "business" || searchResultsType == "movies" || searchResultsType == "professionals" || searchResultsType == "actors" || searchResultsType == "projects")
                                                    ? false
                                                    : true
                                            }>
                                                <div className="font-weight-bold mb-2 text-dark">Star Rating</div>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark ff-alt font-weight-normal" check>
                                                        <Input type="radio" name="filterByStarRating" onClick={() => {
                                                            this.handleOnSortBy(this.state.sortBy, 5);
                                                        }} />{' '}
                                                        <div className="d-flex align-items-center">
                                                            <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_6.png" alt="5 Rating" />
                                                            <span>only</span>
                                                        </div>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark ff-alt font-weight-normal" check>
                                                        <Input type="radio" name="filterByStarRating" onClick={() => {
                                                            this.handleOnSortBy(this.state.sortBy, 4);
                                                        }} />{' '}
                                                        <div className="d-flex align-items-center">
                                                            <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_5.png" alt="4 Rating" />
                                                            <span>& up</span>
                                                        </div>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark ff-alt font-weight-normal" check>
                                                        <Input type="radio" name="filterByStarRating" onClick={() => {
                                                            this.handleOnSortBy(this.state.sortBy, 3);
                                                        }} />{' '}
                                                        <div className="d-flex align-items-center">
                                                            <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_4.png" alt="3 Rating" />
                                                            <span>& up</span>
                                                        </div>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark ff-alt font-weight-normal" check>
                                                        <Input type="radio" name="filterByStarRating" onClick={() => {
                                                            this.handleOnSortBy(this.state.sortBy, 2);
                                                        }} />{' '}
                                                        <div className="d-flex align-items-center">
                                                            <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_3.png" alt="2 Rating" />
                                                            <span>& up</span>
                                                        </div>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark ff-alt font-weight-normal" check>
                                                        <Input type="radio" name="filterByStarRating" onClick={() => {
                                                            this.handleOnSortBy(this.state.sortBy, 1);
                                                        }} />{' '}
                                                        <div className="d-flex align-items-center">
                                                            <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_2.png" alt="1 Rating" />
                                                            <span>& up</span>
                                                        </div>
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-1" check>
                                                    <Label className="text-dark ff-alt font-weight-normal" check>
                                                        <Input type="radio" name="filterByStarRating" onClick={() => {
                                                            this.handleOnSortBy(this.state.sortBy, 0);
                                                        }} />{' '}
                                                        <div className="d-flex align-items-center">
                                                            <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_1.png" alt="0 Rating" />
                                                            <span>& up</span>
                                                        </div>
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            {/* Cities Column Filter */}
                                            <Col className="mb-3" hidden={
                                                (searchResultsType == "business")
                                                    ? false
                                                    : true
                                            }>
                                                <div className="font-weight-bold mb-2 text-dark">Cities</div>
                                                {Array.isArray(searchCitiesData) && searchCitiesData && searchCitiesData.length > 0 ? (searchCitiesData.map((item, index) => {
                                                    if (index < 5) {
                                                        return (
                                                            <FormGroup className="mb-1" check key={index}>
                                                                <Label className="text-dark font-weight-normal" check>
                                                                    <Input type="checkbox" name="filterByPrice" onClick={() => {
                                                                        this.handleOnClickCity(item[0], 5);
                                                                    }} />{' '}
                                                                    {item[0] != "" ? item[0] : ""}
                                                                </Label>
                                                            </FormGroup>
                                                        )
                                                    }
                                                })) : (null)
                                                }
                                                <div className="text-right mr-3">
                                                    <div className="d-inline-block" role="button" onClick={() => this.setState({ nearByCitiesModalToggle: true })}>
                                                        <FontAwesomeIcon className="mr-1" icon="plus" size="sm" />
                                                                More
                                                            </div>
                                                </div>
                                            </Col>

                                            {/* Subcategories Column Filter */}
                                            <Col className="mb-3" hidden={
                                                ((searchResultsType == "all" || searchResultsType == "products" || searchResultsType == "business" || searchResultsType == "movies" || searchResultsType == "professionals" || searchResultsType == "actors" || searchResultsType == "projects") && searchSubcategoryData.length > 0)
                                                    ? false
                                                    : true
                                            }>
                                                <div className="font-weight-bold mb-2 text-dark">Subcategories</div>
                                                {Array.isArray(searchSubcategoryData) && searchSubcategoryData && searchSubcategoryData.length > 0 ? (searchSubcategoryData.map((item, index) => {
                                                    if (index < this.state.indexSubRange) {
                                                        return (
                                                            <FormGroup className="mb-1" check key={index}>
                                                                <Label className="text-dark font-weight-normal" check>
                                                                    <Input type="checkbox" name="filterByPrice" onClick={() => {
                                                                        this.handleOnClickSubcategory(item?.id, this.state.indexSubRange);
                                                                    }} />{' '}
                                                                    {item?.category}
                                                                </Label>
                                                            </FormGroup>
                                                        )
                                                    }
                                                })) : (null)
                                                }
                                                <div className="text-right mr-3" hidden={
                                                    searchSubcategoryData.length > 5
                                                        ? false
                                                        : true}>
                                                    <div className="d-inline-block" role="button"
                                                        onClick={() => {
                                                            this.handleOnCategoryMore();
                                                        }}
                                                    >
                                                        <FontAwesomeIcon className="mr-1" icon="plus" size="sm" />
                                                                More
                                                            </div>
                                                </div>
                                            </Col>

                                            {/* Features Column Filter */}
                                            <Col className="mb-3" hidden={
                                                ((searchResultsType == "all" || searchResultsType == "products" || searchResultsType == "business" || searchResultsType == "movies" || searchResultsType == "professionals" || searchResultsType == "actors" || searchResultsType == "projects") && searchFeaturesData.length > 0)
                                                    ? false
                                                    : true
                                            }>
                                                <div className="font-weight-bold mb-2 text-dark">Features</div>
                                                {Array.isArray(searchFeaturesData) && searchFeaturesData && searchFeaturesData.length > 0 ? (searchFeaturesData.map((item, index) => {
                                                    if (index < this.state.indexFeatureRange) {
                                                        return (
                                                            <FormGroup className="mb-1" check key={index}>
                                                                <Label className="text-dark font-weight-normal" check>
                                                                    <Input type="checkbox" value={item?.name} data-id={0} name={"filterByFeature_" + item?.name} onChange={this.handleOnClickFeatures}
                                                                    />{' '}
                                                                    {item?.name}
                                                                </Label>
                                                            </FormGroup>
                                                        )
                                                    }
                                                })) : (null)
                                                }
                                                <div className="text-right mr-3" hidden={
                                                    searchFeaturesData.length > 5
                                                        ? false
                                                        : true}>
                                                    <div className="d-inline-block" role="button"
                                                        onClick={() => {
                                                            this.handleOnFeatureMore();
                                                        }}
                                                    >
                                                        <FontAwesomeIcon className="mr-1" icon="plus" size="sm" />
                                                                More
                                                            </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>

                        <div className="fs-14 text-dark">
                            <TabContent activeTab={this.state.searchResultsType} hidden={this.state.showMapView}>
                                <TabPane tabId="all">
                                    {/* Repeat this */}
                                    {this.fetchSerchResultList("all")}
                                    <div>
                                        <Alert color="primary" hidden={!this.state.loading}>
                                            Loading...
										</Alert>
                                    </div>
                                </TabPane>
                                <TabPane tabId="products">
                                    {this.fetchSerchResultList("products")}
                                    <div>
                                        <Alert color="primary" hidden={!this.state.loading}>
                                            Loading...
										</Alert>
                                    </div>
                                </TabPane>
                                <TabPane tabId="business">
                                    {this.fetchSerchResultList("business")}
                                    <div>
                                        <Alert color="primary" hidden={!this.state.loading}>
                                            Loading...
										</Alert>
                                    </div>
                                </TabPane>
                                <TabPane tabId="movies">
                                    {this.fetchSerchResultList("movies")}
                                    <div>
                                        <Alert color="primary" hidden={!this.state.loading}>
                                            Loading...
										</Alert>
                                    </div>
                                </TabPane>
                                <TabPane tabId="professionals">
                                    {this.fetchSerchResultList("professionals")}
                                    <div>
                                        <Alert color="primary" hidden={!this.state.loading}>
                                            Loading...
										</Alert>
                                    </div>
                                </TabPane>
                                <TabPane tabId="actors">
                                    {this.fetchSerchResultList("actors")}
                                    <div>
                                        <Alert color="primary" hidden={!this.state.loading}>
                                            Loading...
										</Alert>
                                    </div>
                                </TabPane>
                                <TabPane tabId="projects">
                                    {this.fetchSerchResultList("projects")}
                                    <div>
                                        <Alert color="primary" hidden={!this.state.loading}>
                                            Loading...
										</Alert>
                                    </div>
                                </TabPane>
                                <TabPane tabId="people">
                                    {this.fetchSerchResultList("people")}
                                    <div>
                                        <Alert color="primary" hidden={!this.state.loading}>
                                            Loading...
										</Alert>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </section>

                <Modal isOpen={this.state.nearByCitiesModalToggle} toggle={() => this.setState({ nearByCitiesModalToggle: !this.state.nearByCitiesModalToggle })}>
                    <ModalHeader className="text-uppercase" toggle={() => this.setState({ nearByCitiesModalToggle: !this.state.nearByCitiesModalToggle })}>
                        MORE CITIES
                    </ModalHeader>
                    <ModalBody>
                        <ul className="list-unstyled row pt-4">

                            {Array.isArray(searchCitiesData) && searchCitiesData && searchCitiesData.length > 0 ? (searchCitiesData.map((item, index) => {
                                return (
                                    <li className="mb-2 col-md-4" key={index}>
                                        <FormGroup check>
                                            <Label className="font-weight-normal text-reset ff-base fs-16" check>
                                                <Input type="checkbox" onClick={() => {
                                                    this.handleOnClickCity(item[0], 0);
                                                }} />{' '}
                                                {item[0] != "" ? item[0] : ""}
                                            </Label>
                                        </FormGroup>
                                    </li>
                                )
                            })) : (null)
                            }

                        </ul>

                        <div className="text-left mt-2">
                            <Button className="mw" color="primary" size="sm" onClick={() => {
                                this.handleOnClickCity(this.state.CityType, 5);
                            }}>Search</Button>
                            <Button className="mw" color="primary" size="sm" onClick={() => this.setState({ nearByCitiesModalToggle: !this.state.nearByCitiesModalToggle })}>Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.SubCategoryModalToggle} toggle={() => this.setState({ SubCategoryModalToggle: !this.state.SubCategoryModalToggle })}>
                    <ModalHeader className="text-uppercase" toggle={() => this.setState({ SubCategoryModalToggle: !this.state.SubCategoryModalToggle })}>
                        MORE Sub Categories
                    </ModalHeader>
                    <ModalBody>
                        <ul className="list-unstyled row pt-4">
                            {Array.isArray(searchSubcategoryData) && searchSubcategoryData && searchSubcategoryData.length > 0 ? (searchSubcategoryData.map((item, index) => {
                                return (
                                    <li className="mb-2 col-md-4" key={index} onClick={() => {
                                        this.handleOnClickSubcategory(item?.id, 0);
                                    }}>
                                        <FormGroup check>
                                            <Label className="font-weight-normal text-reset ff-base fs-16" check>
                                                <Input type="checkbox" />{' '}
                                                {item?.category}
                                            </Label>
                                        </FormGroup>
                                    </li>

                                )

                            })) : (null)
                            }

                        </ul>

                        <div className="text-left mt-2">
                            <Button className="mw" color="primary" size="sm" onClick={() => {
                                this.handleOnClickSubcategory(this.state.subcategoryId, 5);
                            }}>Search</Button>
                            <Button className="mw" color="primary" size="sm" onClick={() => this.setState({ SubCategoryModalToggle: !this.state.SubCategoryModalToggle })}>Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.FeatureModalToggle} toggle={() => this.setState({ FeatureModalToggle: !this.state.FeatureModalToggle })}>
                    <ModalHeader className="text-uppercase" toggle={() => this.setState({ FeatureModalToggle: !this.state.FeatureModalToggle })}>
                        MORE Features
                    </ModalHeader>
                    <ModalBody>
                        <ul className="list-unstyled row pt-4">
                            {Array.isArray(searchFeaturesData) && searchFeaturesData && searchFeaturesData.length > 0 ? (searchFeaturesData.map((item, index) => {
                                return (
                                    <li className="mb-2 col-md-4" key={index}>
                                        <FormGroup check>
                                            <Label className="font-weight-normal text-reset ff-base fs-16" check>
                                                <Input type="checkbox" value={item?.name} data-id={5} name={"filterByFeature_" + item?.name} onChange={this.handleOnClickFeatures} />{' '}
                                                {item?.name}
                                            </Label>
                                        </FormGroup>
                                    </li>

                                )

                            })) : (null)
                            }


                        </ul>

                        <div className="text-left mt-2">
                            <Button className="mw" color="primary" size="sm" onClick={() => {
                                this.handleOnFeatureSubmit();
                            }}>Search</Button>
                            <Button className="mw" color="primary" size="sm" onClick={() => this.setState({ FeatureModalToggle: !this.state.FeatureModalToggle })}>Cancel</Button>
                        </div>
                    </ModalBody>
                </Modal>

                {/* Share By Email Modal Popup */}
                {/* <Modal
                    size="sm"
                    isOpen={this.state.shareByEmailModalToggle}
                    toggle={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })
                    }
                >
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() =>
                            this.setState({
                                shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                            })
                        }
                    >
                        Share by Email
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="text"
                                name="shareName"
                                placeholder="Your name"
                                value={this.state.shareName}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareName && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareName}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Input
                                className="primary"
                                bsSize="sm"
                                type="email"
                                name="shareEmail"
                                placeholder="Recipient's email"
                                value={this.state.shareEmail}
                                onChange={this.handleOnChangeShareByEmail}
                            />
                            {this.state.shareError?.shareEmail && (
                                <div className="text-danger small mt-1">
                                    {this.state.shareError?.shareEmail}
                                </div>
                            )}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <div className="text-right">
                            <Button color="primary" onClick={this.handleOnSubmitShareByEmail}>
                                Share
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal> */}

                <ModalComponent
                    size="sm"
                    isScrollable={true}
                    isOpen={this.state.shareByEmailModalToggle}
                    toggleHandler={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })}
                    header={"Share by Email"}
                    headerClass="text-uppercase px-0"
                    body={<ShareByEmail handleOnSubmitShareByEmail={(value) => this.handleOnSubmitShareByEmail(value)} modalToggler={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })} />}
                />  

                {/* Write your review modal One */}
                <Modal
                    isOpen={this.state.writeReviewModalToggleOne}
                    toggle={() =>
                        this.setState({
                            writeReviewModalToggleOne: !this.state.writeReviewModalToggleOne,
                        })
                    }>
                    <ModalBody>
                        {/* write your own review, Part 1 */}
                        <div>
                            <div className="d-flex flex-nowrap">
                                <div className="flex-grow-1">
                                    <h2 className="text-primary mb-3 fs-36">Start your review of {this.state.reviewName}</h2>
                                    <p className="text-dark ff-base">
                                        <strong>Please share your experience.</strong>
                                        <br />
									Be the first to write a review about this listing and you will receive your First to Review badge!
								</p>
                                </div>
                                <div className="ml-auto">
                                    <img className="img-fluid" src={require("../../assets/images/icons/first-to-review.png")} alt="" />
                                </div>
                            </div>

                            <div className="mt-3">
                                <h6 className="ff-base font-weight-bold text-primary">
                                    Step 1. Star Ratings{" "}
                                </h6>
                                <hr className="bg-light mt-2" />
                                <p className="text-tertiary fs-14">
                                    Roll over the stars and click to rate.
                            </p>

                                {/* Star Input */}
                                <div className="d-flex align-items-center">
                                    <Label className="text-dark font-weight-bold text-nowrap mr-3 fs-16">
                                        {'Overall Rating*'}
                                    </Label>
                                    <div>
                                        <div className="stars-rating-input _lg">
                                            <span className="zero-rating-input" role="button"
                                                onMouseEnter={() => { this.setState({ enableRatingText: 0 }) }}
                                                onMouseLeave={() => { this.setState({ enableRatingText: null }) }}
                                                onClick={
                                                    () =>
                                                        this.setState({
                                                            writeReviewModalToggleOne: false,
                                                            writeReviewModalToggleTwo: true
                                                        })
                                                }>
                                                <FontAwesomeIcon icon="ban" />
                                            </span>

                                            <Rating
                                                fractions={2}
                                                emptySymbol={<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "34px" }}>★</span>}
                                                fullSymbol={<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "34px" }}>★</span>}
                                                onHover={(rate) => {
                                                    if (rate !== this.state.enableRatingText) {
                                                        this.setState({ enableRatingText: rate })
                                                    }
                                                }}
                                                onChange={(newRating) => this.ratingChanged(newRating, 'avg_rating', true)}
                                            />

                                            <span className="label-dynamic-rating">
                                                {(this.state.enableRatingText == 0) && 'Oh, Hell No!'}
                                                {(this.state.enableRatingText == 0.5 || this.state.enableRatingText == 1) && 'Definitely would not recommend!'}
                                                {(this.state.enableRatingText == 1.5 || this.state.enableRatingText == 2) && 'It could have been a lot better!'}
                                                {(this.state.enableRatingText == 2.5 || this.state.enableRatingText == 3) && 'Okay!'}
                                                {(this.state.enableRatingText == 3.5 || this.state.enableRatingText == 4) && 'Very good!'}
                                                {(this.state.enableRatingText == 4.5 || this.state.enableRatingText == 5) && "Can't get any better than this!"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                {/* Write your review modal Two */}
                <Modal
                    isOpen={this.state.writeReviewModalToggleTwo}
                    toggle={() =>
                        this.setState({
                            writeReviewModalToggleTwo: !this.state.writeReviewModalToggleTwo,
                        })
                    }>
                    <ModalBody className="bg-transparent p-0">
                        <div className="mb-3">
                            {/* write your own review, Part 2 */}
                            <div>
                                <div className="bg-white p-2 mb-3">
                                    <h2 className="text-dark fs-36">
                                        You’re reviewing <span className="text-darker">{this.state.reviewName}</span>
                                    </h2>
                                </div>

                                {/* Step 1 Collapse */}
                                <div className="bg-white mb-3">
                                    <CollapseBasic
                                        title="Step 1. Star Ratings"
                                        bodyClass="text-dark"
                                        containerClass="type-tertiary type-review bg-transparent"
                                        isOpen={true}
                                        size="sm"
                                        noPadding={true}
                                    >
                                        <p className="text-tertiary fs-14">
                                            {'Roll over the stars and click to rate.'}{" "}
                                            <span className="text-dark ff-alt hover-underline"
                                                role="button"
                                                onClick={() => this.setState({ showRevTips: true })}
                                            >
                                                {" "}
                                                {'Review Tips & Guidelines.'}
                                            </span>
                                        </p>

                                        {/* Review Tips & Guidelines */}
                                        {this.state.showRevTips ? <div className="mb-3">
                                            <div className="text-right">
                                                <Button color="tertiary" className="text-white" size="sm"
                                                    onClick={() => this.setState({ showRevTips: false })}
                                                >
                                                    <FontAwesomeIcon icon="times" />{" "}
                                                </Button>
                                            </div>
                                            <div className="bg-tertiary text-white p-2 fs-14">
                                                <ul className="list-normal mb-0">
                                                    <li className="ff-base mb-2">
                                                        Do not post photos or videos of other copyrighted
                                                        material that you don’t have permission to use. Do not
                                                        post photos of people without their permission. If
                                                        they are children, ask the parents before you post.
                                    </li>
                                                    <li className="ff-base mb-2">
                                                        Opinions are almost always protected as free speech
                                                        except in the case of defamation (a false accusation
                                                        of an offense or a malicious misrepresentation of
                                                        someone's words or actions), true threats,
                                                        solicitations to commit crimes, incitement to imminent
                                                        lawless action, blackmail, fighting words and
                                                        obscenity).
                                    </li>
                                                    <li className="ff-base mb-2">
                                                        Please omit excessive exaggeration and do not lie.
                                    </li>
                                                    <li className="ff-base mb-2">
                                                        Deliberately sabotaging the viability of a business is
                                                        strongly discouraged.
                                    </li>
                                                    <li className="ff-base mb-2">
                                                        Be honest and truthful in your reviews.
                                    </li>
                                                    <li className="ff-base mb-2">
                                                        Please be accurate, avoid embellishment and broad
                                                        generalizations, and to relate only to your first hand
                                                        experiences.
                                    </li>
                                                </ul>
                                            </div>
                                        </div> : ""}

                                        {/* Star Input */}
                                        <div className="d-flex align-items-center">
                                            <Label className="text-dark font-weight-bold text-nowrap mr-3 fs-16">
                                                {'Overall Rating*'}
                                            </Label>
                                            <div>
                                                <div className="stars-rating-input _lg">
                                                    <span
                                                        onMouseEnter={() => { this.setState({ enableRatingText: 0, avg_rating: 0 }) }}
                                                        onMouseLeave={() => { this.setState({ enableRatingText: null }) }}
                                                        className="zero-rating-input" role="button" onClick={(newRating) => this.ratingChanged(0, 'avg_rating', true)}>
                                                        <FontAwesomeIcon icon="ban" color={this.state.avg_rating == 0 ? '#15c3d4' : '#d7d8e2'} />
                                                    </span>

                                                    <Rating
                                                        fractions={2}
                                                        emptySymbol={<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "34px" }}>★</span>}
                                                        fullSymbol={<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "34px" }}>★</span>}
                                                        onHover={(rate) => {
                                                            if (rate !== this.state.enableRatingText) {
                                                                this.setState({ enableRatingText: rate })
                                                            }
                                                        }}
                                                        initialRating={this.state.avg_rating}
                                                        onChange={(newRating) => this.ratingChanged(newRating, 'avg_rating', true)}
                                                    />

                                                    <span className="label-dynamic-rating">
                                                        {(this.state.enableRatingText == 0) && 'Oh, Hell No!'}
                                                        {(this.state.enableRatingText == 0.5 || this.state.enableRatingText == 1) && 'Definitely would not recommend!'}
                                                        {(this.state.enableRatingText == 1.5 || this.state.enableRatingText == 2) && 'It could have been a lot better!'}
                                                        {(this.state.enableRatingText == 2.5 || this.state.enableRatingText == 3) && 'Okay!'}
                                                        {(this.state.enableRatingText == 3.5 || this.state.enableRatingText == 4) && 'Very good!'}
                                                        {(this.state.enableRatingText == 4.5 || this.state.enableRatingText == 5) && "Can't get any better than this!"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <div className="text-tertiary">
                                                <span className="font-weight-bold">Detailed Ratings</span>{" "}
                                                {'(optional)'}
                                            </div>
                                            <hr className="bg-light" />

                                            <Row className="fs-14 ff-alt">
                                                {itemEditSet.map((iteam, index) => (
                                                    <Col className="mb-2" md={6} lg={4}>
                                                        <div>{iteam.name}</div>
                                                        {/* <div>{iteam.key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')}</div> */}
                                                        <div className="stars-rating-input">
                                                            <span className="zero-rating-input" role="button">
                                                                <FontAwesomeIcon
                                                                    icon="ban"
                                                                    color={this[iteam.name] ? '#15c3d4' : '#d7d8e2'}
                                                                    onMouseEnter={() => {
                                                                        this[iteam.name] = true;
                                                                        this.ratingChanged(0, iteam.key);
                                                                    }} />
                                                            </span>
                                                            <StarsRating
                                                                count={5}
                                                                value={iteam.value}
                                                                size={24}
                                                                color1={'#d7d8e2'}
                                                                color2={'#15c3d4'}
                                                                onMouseEnter={(e) => {
                                                                    // removed console from here.
                                                                }}
                                                                onChange={(newRating) => {
                                                                    this[iteam.name] = false;
                                                                    this.ratingChanged(newRating, iteam.key)
                                                                }}
                                                            />
                                                            <span
                                                                onClick={() => {
                                                                    this[iteam.name] = false;
                                                                    this.ratingChanged(0, iteam.key)
                                                                }}
                                                                className="clear-rating"
                                                                role="button"
                                                                title="Clear">
                                                                <span className="icon-cross"></span>
                                                            </span>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </CollapseBasic>
                                </div>

                                {/* Step 2 Collapse */}
                                <div className="bg-white mb-3" hidden={this.state.writePerson == "movies"
                                    ? false
                                    : true
                                }>
                                    <CollapseBasic title="Step 2. Choose a Person (optional)" bodyClass="text-dark" containerClass="type-tertiary type-review bg-transparent" isOpen={false} size="sm" noPadding={true}>
                                        <div className="text-tertiary fs-14 mb-3">
                                            If your review above contains information about a particular person at this business that you would like to highlight, please find his or her name below.
                                                        </div>
                                        <div className="d-inline-block">
                                            <FormGroup>
                                                <Input className="border-0 text-primary font-weight-bold" type="select" value={this.state.ratePerson} onChange={this.handleOnClickPerson}>
                                                    <option value="0" data-name="" >Choose a Person</option>
                                                    {this.state.castPerson && Array.isArray(this.state.castPerson) && this.state.castPerson.length > 0 ?
                                                        this.state.castPerson.map((item, index) => (
                                                            <option value={item} key={index} data-name={item}>{item}</option>
                                                        ))
                                                        : null}
                                                </Input>
                                            </FormGroup>
                                        </div>

                                        <hr className="bg-light my-2" />

                                        <div className="text-tertiary fs-14 mb-3">
                                            Enter the name of a missing person below
                                                        </div>
                                        <Row form>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Input className="primary" bsSize="sm" type="text" placeholder="Enter First Name" name="first_name" value={first_name} onChange={this.handleOnChangePerson} disabled={ratePerson != ""
                                                        ? true
                                                        : false
                                                    } />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Input className="primary" bsSize="sm" type="text" placeholder="Enter Last Name" name="last_name" value={last_name} onChange={this.handleOnChangePerson} disabled={ratePerson != ""
                                                        ? true
                                                        : false
                                                    } />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <hr className="bg-light my-2" />

                                        <div className="d-flex align-items-center">
                                            <Label className="text-primary font-weight-bold text-nowrap mr-3" hidden={ratePerson != ""
                                                ? false
                                                : true
                                            }>
                                                How would you rate {ratePerson != "" ? ratePerson : "this person ?"}
                                            </Label>

                                            <Label className="text-primary font-weight-bold text-nowrap mr-3" hidden={ratePerson != ""
                                                ? true
                                                : false
                                            }>
                                                How would you rate {first_name != "" ? first_name + " " + last_name : "this person ?"}
                                            </Label>
                                            <div>
                                                <div className="stars-rating-input _lg">
                                                    <span className="zero-rating-input" role="button">
                                                        <FontAwesomeIcon icon="ban" />
                                                    </span>
                                                    <StarsRating
                                                        count={5}
                                                        value={avg_rating_person}
                                                        size={34} color1={'#d7d8e2'} color2={'#15c3d4'}
                                                        onChange={(newRating) => this.ratingChangedPerson(newRating, 'avg_rating_person', true)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </CollapseBasic>
                                </div>

                                {/* Step 3 Collapse */}
                                <div className="bg-white mb-3">
                                    <CollapseBasic
                                        title={this.state.writePerson == "movies" ? "Step 3. Review" : "Step 2. Review"}
                                        bodyClass="text-dark"
                                        containerClass="type-tertiary type-review bg-transparent"
                                        isOpen={false}
                                        size="sm"
                                        noPadding={true}
                                    >
                                        <div className="text-tertiary fs-14 mb-3">
                                            Please provide as much information as you can in your review
                                            to be as meaningful as possible to others.
                              </div>
                                        <Nav tabs className="mb-3 active-transparent">
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className="ff-base font-weight-normal"
                                                    active={this.state.viewEditReviewStep === "writeReview"}
                                                    onClick={() => {
                                                        this.setState({ viewEditReviewStep: "writeReview" });
                                                    }}
                                                >
                                                    <span className="font-weight-bold">Write a Review</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className="ff-base font-weight-normal"
                                                    active={this.state.viewEditReviewStep === "recordVideo"}
                                                    onClick={() => {
                                                        this.setState({ viewEditReviewStep: "recordVideo" });
                                                    }}
                                                >
                                                    or record a
                                    <span className="font-weight-bold">
                                                        &nbsp; Video Review
                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>

                                        <TabContent
                                            className="mb-3"
                                            activeTab={this.state.viewEditReviewStep}>
                                            <TabPane tabId="writeReview">

                                                <div className="px-2 col">
                                                    <div className="fs-14 font-weight-bold text-dark">
                                                        {'Add minimum 50 Characters'}
                                                    </div>
                                                </div>

                                                <FormGroup className="main-post-formgroup">
                                                    <div className="input-label-block">
                                                        <Input
                                                            //style={{ border: enableErrorForReview ? '1px solid red' : '1px solid #ddd' }}
                                                            className="primary"
                                                            type="textarea"
                                                            name="write_new_review"
                                                            rows="4"
                                                            id="write_new_review"
                                                            value={write_new_review}
                                                            onChange={this.handelChangeReview}
                                                            placeholder="&nbsp;"
                                                        />
                                                        {write_new_review.length < 50 && write_new_review.length !== 0 ? <span style={{ color: "red" }}>
                                                            This field contains a minimum of 50 characters.
                                                            </span> : ""}
                                                    </div>
                                                    <div className="characters-length-text">
                                                        Characters: <span>{write_new_review.length}</span>
                                                    </div>
                                                </FormGroup>
                                            </TabPane>
                                            <TabPane tabId="recordVideo">

                                                <div>
                                                    {this.state.viewEditReviewStep === "recordVideo" && (
                                                        <WebCamRecording
                                                            stepTwoUploadedVideo={this.state.stepTwoUploadedVideo}
                                                            addUploadedVideo={this.addUploadedVideo}
                                                        />
                                                    )}
                                                </div>
                                            </TabPane>
                                        </TabContent>

                                        <div className="fs-14" hidden={reviewsCategories?.res?.length ? false : true}>
                                            <div className="text-dark">
                                                <span className="font-weight-bold">
                                                    {'Choose Review Categories'}
                                                </span>
                                                <span>(Optional)</span>
                                            </div>
                                            <hr className="bg-light my-2" />
                                            <p className="text-tertiary fs-14 mb-2">
                                                If your review above contains information about a
                                                particular element or elements that you would like to
                                                highlight, please check the corresponding boxes below.
                                </p>
                                            <Row>
                                                {reviewsCategories.res && reviewsCategories.res.map((iteam, index) => (
                                                    <Col xs={6} lg={4} key={index}>
                                                        <FormGroup check>
                                                            <Label className="text-primary font-weight-bold" check>
                                                                <Input type="checkbox" id="checkbox2" name={iteam.label}
                                                                    onChange={
                                                                        (e) =>
                                                                            this.ratingChangedForCheck(e.target.checked, iteam)}
                                                                />{' '}
                                                                {iteam.label}
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </CollapseBasic>
                                </div>

                                {/* Step 4 Collapse */}
                                <div className="bg-white mb-3">
                                    <CollapseBasic
                                        title={this.state.writePerson == "movies" ? "Step 4. Upload Images and/or Video (optional)" : "Step 3. Upload Images and/or Video (optional)"}
                                        containerClass="type-tertiary type-review bg-transparent"
                                        isOpen={true}
                                        size="sm"
                                        noPadding={true}
                                    >
                                        <p className="text-tertiary fs-14 mb-3">
                                            Upload images or video to accompany your review. Additional
                                            images/videos can be added later.
                              </p>
                                        {/* Uploaded media shown here */}
                                        <div className="border mb-3">
                                            <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                                {mediaSetReview.map((iteam) => (
                                                    <Col>
                                                        <div className="d-flex pr-3 m-2">
                                                            <div className="project-thumbnail">
                                                                <img className="img-fluid img-thumbnail" src={iteam.file} alt="Uploaded media" />
                                                            </div>
                                                            <div className="mx-n3 mt-2">
                                                                <Button color="delete-media" size="sm" title="Remove Media" onClick={(e) => { this.removeFromMedia(e, iteam) }}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                        <Button color="tertiary" className="font-weight-bold" onClick={this.handleCloseAndFetchReview}>
                                            Upload Media
                              </Button>
                                    </CollapseBasic>
                                </div>
                                {/* Final Step */}
                                <div className="bg-white p-3">
                                    <div className="mb-3">
                                        <FormGroup check>
                                            <Label
                                                className="text-tertiary fs-14 ff-alt"
                                                check
                                            >
                                                <Input type="checkbox" id="checkbox2" checked={shocialNetworkSharing} disabled={reviewAnonymous} name="shocialNetworkSharing" onChange={this.handelChangeReview} /> Also post this
                                  review on social networks.
                                </Label>
                                        </FormGroup>
                                        <div className="mt-3 pl-4">
                                            <FacebookShareButton url={`${this.state.protocol}//${this.state.host}/${searchResultsType == "all" ? "business" : searchResultsType}/${businessId}`} className="text-dark text-decoration-none mx-2">
                                                <Button color="primary">Post To Facebook</Button>
                                            </FacebookShareButton>

                                            <TwitterShareButton url={`${this.state.protocol}//${this.state.host}/${searchResultsType == "all" ? "business" : searchResultsType}/${businessId}`} className="text-dark text-decoration-none mx-2">
                                                <Button color="tertiary reset">Post To Twitter</Button>
                                            </TwitterShareButton>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <FormGroup check>
                                            <Label className="text-tertiary font-weight-bold fs-14 ff-alt" check>
                                                <Input type="checkbox" id="checkbox2" name="reviewAnonymous" checked={reviewAnonymous} onChange={(e) => {
                                                    this.setState({
                                                        reviewAnonymous: e.target.checked,
                                                        shocialNetworkSharing: false
                                                    })
                                                }} />{' '}
														Make this review anonymous <span className="font-weight-normal">(we will not show your username). <img src={require("../../assets/images/icons/ovel.png")} alt="" /></span>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    <div className="mb-3">
                                        <FormGroup check>
                                            <Label
                                                className="text-tertiary font-weight-bold fs-14 ff-alt"
                                                check
                                            >
                                                <Input type="checkbox" id="checkbox2" checked={isadhere} name="isadhere" onChange={this.handelChangeReview} /> I adhere to the{" "}
                                                <a href="#" className="text-dark">
                                                    Review Tips & Guidelines.
                                  </a>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    <div className="text-right">
                                        <Button size="lg" color="grey" className="mw" onClick={() => this.clearReveiwRatingState()} >
                                            Cancel
                              </Button>
                                        <Button
                                            disabled={enableErrorForReview}
                                            size="lg"
                                            color="tertiary"
                                            className={reviewAnonymous ? "mw hide-review-block" : "mw"}
                                            onClick={() => this.publishPost("draft")}>
                                            {'Save Draft'}
                                        </Button>
                                        <Button
                                            disabled={enableErrorForReview}
                                            size="lg"
                                            color="primary"
                                            className="mw"
                                            onClick={() => this.publishPost("publish")}>
                                            {'Publish'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={this.state.uploadMediaModal}
                    toggle={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal }, () => { this.clearMediaDispatch() })}>
                    <ModalHeader toggle={() => { this.handleCloseAndFetch() }}>UPLOAD MEDIA</ModalHeader>
                    <ModalBody className="p-3">
                        <Row>
                            <Col xs={"auto"}>
                                <div className="bg-light h-100 p-3">
                                    <Nav tabs className="flex-column active-transparent border-0 m-0">
                                        <NavItem className="text-left">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "upload"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "upload" })
                                                }}
                                            >
                                                Upload
                </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "gallery"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "gallery" }, () => { this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true }) })
                                                }}
                                            >
                                                Gallery
                </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "embed"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "embed" })
                                                }}
                                            >
                                                Embed
                </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Col>
                            <Col>
                                <TabContent activeTab={this.state.selectedUpMediaType}>
                                    <TabPane tabId="upload">
                                        {selectedFilesPreview && selectedFilesPreview.length == 0 ?
                                            <div className="mb-3 type-file-block bg-light">
                                                <Input
                                                    type="file"
                                                    name="upload_media_file"
                                                    id="uploadFilesFromSystem"
                                                    accept="image/*, video/*"
                                                    onChange={this.handleOnFileUploadChange}
                                                    multiple
                                                />
                                                <Label for="uploadFilesFromSystem">
                                                    <div className="type-bg"></div>
                                                </Label>
                                            </div>
                                            : null}
                                        <div
                                            className="text-right"
                                            style={{
                                                maxWidth: "120px",
                                                margin: "0 1rem 1rem auto",
                                            }}>
                                            <div className="text-center mb-1 small">
                                                {parseInt(calculatedPercentage) === 100 ? (
                                                    <div className="text-success">
                                                        <FontAwesomeIcon
                                                            icon="check-circle"
                                                            className="mr-1"
                                                        />{" "}
                                                        {'Uploaded'}
                                                    </div>
                                                ) : (
                                                        selectedFilesPreview.length > 0 && (
                                                            <div>
                                                                {` Uploading `}
                                                                <span className="text-success font-weight-bold ff-base">
                                                                    {calculatedPercentage ? `${parseInt(calculatedPercentage)} %` : ''}
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            {selectedFilesPreview.length > 0 && (
                                                <Progress
                                                    value={parseInt(calculatedPercentage)}
                                                    style={{ height: "8px" }}
                                                />
                                            )}
                                        </div>

                                        <Row>
                                            {selectedFilesPreview && selectedFilesPreview.length > 0 ?
                                                selectedFilesPreview.map((item, index) => (
                                                    <Col className="mb-3" xs="auto" key={index}>
                                                        <div className="text-center bg-light p-2">
                                                            <div className="d-inline-flex pr-3">
                                                                <div>
                                                                    <div
                                                                        className="selectable-media"
                                                                        style={{ cursor: "default" }}
                                                                    >
                                                                        <div className="gallery-media">
                                                                            <img src={item} alt={`selected-${index}`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mx-n3 mt-2">
                                                                    <Button color="delete-media _alt" size="sm" title="Remove Media"
                                                                        onClick={(e) => this.removeSelectedImage(e, index)}><FontAwesomeIcon icon="trash-alt" /> </Button>
                                                                </div>
                                                            </div>
                                                            {latestUploadedData &&
                                                                latestUploadedData[index] &&
                                                                (latestUploadedData[index]['isUpload'] !== undefined || latestUploadedData[index]['isUpload'] !== null) ?
                                                                latestUploadedData[index]['isUpload'] ? (
                                                                    <div className="mt-2">
                                                                        <InputGroup style={{ maxWidth: '160px' }}>
                                                                            <Input bsSize="sm" type="text" name="caption" className="caption-input" placeholder="Caption" onBlur={(e) => this.handleCaptionByIndex(e, index)} />
                                                                        </InputGroup>
                                                                        <Input onChange={(e) => this.handleChangeCategory(e, index)} bsSize="sm" type="select" name="album_type_category" id="selectCategoryTypeData" style={{ maxWidth: '160px' }}>
                                                                            <option value="">Choose Category</option>
                                                                            {cattaxListing && Object.keys(cattaxListing).length > 0 ?
                                                                                Object.values(cattaxListing).map(
                                                                                    (data, i) => (
                                                                                        <>
                                                                                            <option value={data.category}>{data.category}</option>
                                                                                        </>
                                                                                    ))
                                                                                : null}
                                                                        </Input>
                                                                        {uploadedId[index] == latestUploadedData[index].id && (Object.keys(fetchTaxonomyListingImages).length > 0 || Object.keys(fetchTaxonomyListingVideos).length > 0) ?
                                                                            <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_subcategory_select" id="selectAlbumTypeData" style={{ maxWidth: '160px' }}>
                                                                                <option value="">Choose Subcategory</option>
                                                                                {latestUploadedData[index]['media_type'] === "image" && fetchTaxonomyListingImages && Object.keys(fetchTaxonomyListingImages).length > 0 && fetchTaxonomyListingImages[index] ?
                                                                                    fetchTaxonomyListingImages[index].map(item => (
                                                                                        <option value={item.id}>{item.label}</option>
                                                                                    ))
                                                                                    : null}
                                                                                {latestUploadedData[index]['media_type'] === "video" && fetchTaxonomyListingVideos && Object.keys(fetchTaxonomyListingVideos).length > 0 && fetchTaxonomyListingVideos.length > 0 && fetchTaxonomyListingVideos[index] ?
                                                                                    fetchTaxonomyListingVideos[index].map(item => (
                                                                                        <option value={item.id}>{item.label}</option>
                                                                                    ))
                                                                                    : null}
                                                                            </Input>

                                                                            : null}
                                                                    </div>
                                                                ) : (
                                                                        <div className="mt-2 text-center">
                                                                            <small className="text-danger">Error while uploading</small>
                                                                        </div>
                                                                    ) : (
                                                                    <div className="mt-2 text-center">
                                                                        <small className="text-info">Uploading...</small>
                                                                    </div>
                                                                )}

                                                        </div>
                                                    </Col>
                                                ))
                                                : null}
                                        </Row>
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.handleCloseAndFetch() }}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.handleCloseAndFetch() }}>
                                                    ok
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="gallery">
                                        <div className="mb-2">
                                            <Nav tabs className="d-inline-flex mb-0">
                                                <NavItem>
                                                    <NavLink href="#"
                                                        active={this.state.showGalleryType === "images"}
                                                        onClick={() => {
                                                            this.setState({ showGalleryType: "images", fetchGalleryType: 'image', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                                this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true })
                                                            })
                                                        }}>
                                                        {'Image Gallery'}
                                                    </NavLink>
                                                </NavItem>
                                                {/*showVideoMedia ? 'True' : 'False'*/}
                                                {showVideoMedia == true && (
                                                    <NavItem>
                                                        <NavLink href="#"
                                                            active={this.state.showGalleryType === "videos"}
                                                            onClick={() => {
                                                                this.setState({ showGalleryType: "videos", fetchGalleryType: 'video', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                                    this.props.media_upload_scroll({ type: 'video', album: '', page: 1, force_update: true })
                                                                })
                                                            }}>
                                                            {'Video Gallery'}
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={this.state.showGalleryType}>
                                            <TabPane tabId="images">
                                                <div>
                                                    <FormGroup className="form-row justify-content-end">
                                                        <Col xs="auto">
                                                            <Label className="small" for="selectAlbumType">Album Type:</Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Input onChange={this.handleChangeTaxonomy} bsSize="sm" type="select" name="album_type_select" id="selectAlbumType" defaultValue="all">
                                                                <option value="all">All</option>
                                                                {fetchTaxonomy && Array.isArray(fetchTaxonomy) && fetchTaxonomy.length > 0 ?
                                                                    fetchTaxonomy.map(item => (
                                                                        <option value={item.category}>{item.label}</option>
                                                                    ))
                                                                    : null}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    ref={this.refGalleryImageScroll}
                                                    onScroll={this.handleScrollGalleryImage}
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                    <Row>
                                                        {this.fetchScrollPhotoMedia()}
                                                    </Row>
                                                </div>
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'Cancel'}
                                                        </Button>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'Ok'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="videos">
                                                <div
                                                    ref={this.refGalleryVideoScroll}
                                                    onScroll={this.handleScrollGalleryVideo}
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                    <Row>
                                                        {this.fetchScrollPhotoMedia(true)}
                                                    </Row>
                                                </div>
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Button color="primary" size="sm" className="mw" onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>Cancel</Button>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'Ok'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </TabPane>
                                    <TabPane tabId="embed">
                                        <div className="text-tertiary mb-2">
                                            Submit the link for the image or video you want to embed{" "}
                                        </div>
                                        <div className="mb-2">
                                            {/* Repeat this while adding */}
                                            <div className="d-flex mx-n2 mb-2" key={0}>
                                                <div className="flex-fill px-2">
                                                    <Input
                                                        type="url"
                                                        bsSize="sm"
                                                        className="bg-white"
                                                        value={embeddedLink}
                                                        onChange={(e) => { this.setState({ embeddedLink: e.target.value }) }}
                                                        placeholder="Embeded link"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.setState({ embeddedLink: '', uploadMediaModal: false }) }} >
                                                    {'Cancel'}
                                                </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={(e) => { this.AddNewEmbedLink(e) }}>
                                                    {'Insert'}
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal
                    size="md"
                    isOpen={this.props.loginPrompt}
                    toggle={this.toggleLoginModal}
                    className="text-center delete-box login-prompt-modal" modalClassName="backdrop-opaque top-shifted transition-none">
                    {/* <ModalHeader toggle={this.toggleModal}></ModalHeader> */}
                    <div className="bg-white modal-header">
                        <h2 className="modal-title-login-prompt mx-auto text-tertiary">Hello! Please <span className='text-dark cursor-pointer' onClick={() => this.props.setLoginPromptSignUp(true)}>Sign up</span> or <span className='text-dark cursor-pointer' onClick={() => this.props.setLoginPromptLogin(true)}>Log in</span> to make a WikiReviews contribution</h2>
                        <button
                            className="btn"
                            aria-label="Close"
                            onClick={() => {
                                this.toggleLoginModal()
                            }}
                            >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    {/* <ModalBody className="text-dark">
                        {"modalText"}
                    </ModalBody> */}
                    <ModalFooter className="bg-white">
                        <div className="text-center w-100">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={() => this.props.setLoginPromptSignUp(true)}
                            >
                                {'Sign Up'}
                            </Button>
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={() => this.props.setLoginPromptLogin(true)}
                            >
                                {'Log In'}
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div >
        )
    }
}

const mapState = (state) => ({
    fetch_search_result: state.home.fetch_search_result,
    chart_star_ratings: state.business.chart_star_ratings,
    add_temp_location: state.home.add_temp_location,
    current_location: state.home.current_location,
    fetch_add_search_list: state.home.fetch_add_search_list,
    fetch_filter_list: state.home.fetch_filter_list,
    searchingResultsLoading: state.home.searchingResultsLoading,
    fetch_search_cities_list: state.home.fetch_search_cities_list,
    fetch_subcategories_list: state.home.fetch_subcategories_list,
    fetch_additonal_attributes_list: state.home.fetch_additonal_attributes_list,
    fetch_photos: state.myProfile.fetch_photos,
    fetch_videos: state.myProfile.fetch_videos,
    fetch_taxonomy: state.myProfile.fetch_taxonomy,
    upload_scroll: state.myProfile.upload_scroll,
    upload_media_data: state.myProfile.upload_media_data,
    fetch_deleted_data: state.myProfile.fetch_deleted_data,
    business_rating: state.business.business_rating,
    qa_dropdown: state.business.qa_dropdown,
    fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
    new_sub_categories: state.business.new_sub_categories,
    fetch_data_categories: state.business.fetch_data_categories,
    filter_data_category: state.business.filter_data_category,
    my_profile_info: state.user.my_profile,
    isLoggedIn: state.auth.isLoggedIn,
    loginPrompt:state.shared.loginPrompt
});

const mapProps = (dispatch) => ({
    serach_home_result: (url) => dispatch(serach_home_result(url)),    
    serach_home_result_: (data) => dispatch(serach_home_result_(data)),
    fetch_filter_result: (url) => dispatch(fetch_filter_result(url)),
    fetch_search_cities: (url) => dispatch(fetch_search_cities(url)),
    add_search_list: (url, data, type, name, message) => dispatch(add_search_list(url, data, type, name, message)),
    delete_pin_list: (id, type) => dispatch(delete_pin_list(id, type)),
    fetch_subcategories: (categoryId) => dispatch(fetch_subcategories(categoryId)),
    fetch_additonal_attributes: (categoryId) => dispatch(fetch_additonal_attributes(categoryId)),
    clear_subcategories: () => dispatch(clear_subcategories()),    
    set_search_results_loading: (data) => dispatch(set_search_results_loading(data)),
    clear_features: () => dispatch(clear_features()),
    clear_search_result: () => dispatch(clear_search_result()),
    fetch_price_sort: (url) => dispatch(fetch_price_sort(url)),
    save_ratings_vote: (data) => dispatch(save_ratings_vote(data)),
    save_ratings_vote_person: ({ name, vote, personId }) => dispatch(save_ratings_vote_person({ name, vote, personId })),
    fetch_media_data: (type, album, page, user_profile) => dispatch(fetch_media_data(type, album, page, user_profile)),
    fetch_taxonomy_category: () => dispatch(fetch_taxonomy_category()),
    media_upload_scroll: ({ type, album, page, force_update }) => dispatch(media_upload_scroll({ type, album, page, force_update })),
    fetch_delete_media: ({ type, page }) => dispatch(fetch_delete_media({ type, page })),
    upload_media_files: (type, data, index) => dispatch(upload_media_files(type, data, index)),
    upload_media_filesData: (url, type, data, index) => dispatch(upload_media_filesData(url, type, data, index)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    clear_media_dispatch: () => dispatch(clear_media_dispatch()),
    delete_mutiple_media: ({ type, data }) => dispatch(delete_mutiple_media({ type, data })),
    single_delete_from_upload: (id) => dispatch(single_delete_from_upload(id)),
    embedNewLink: ({ data }) => (dispatch(embedNewLink({ data }))),
    publish_post: (data) => dispatch(publish_post(data)),
    fetch_detailedstarrating: (data) => dispatch(fetch_detailedstarrating(data)),
    get_business_QA_dropdown: (id) => dispatch(get_business_QA_dropdown(id)),
    fetch_taxonomy_list: (category, type) => dispatch(fetch_taxonomy_list(category, type)),
    fetch_sub_categories: ({ sub_cat, level }) => dispatch(fetch_sub_categories({ sub_cat, level })),
    fetch_categories_data: (cat, sub_cat, type, index) => dispatch(fetch_categories_data(cat, sub_cat, type, index)),
    filter_categories_data: ({ category, sub_cat, level }) => dispatch(filter_categories_data({ category, sub_cat, level })),
    clear_category: () => dispatch(clear_category()),
    get_chart_star_ratings: (id) => dispatch(get_chart_star_ratings(id)),
    clear_subcategory: () => dispatch(clear_subcategory()),
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    add_remove_to_list: (url, message, nextUrl,data) => dispatch(add_remove_to_list(url, message, nextUrl,data)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    send_again_request: (data, query) => dispatch(send_again_request(data, query)),
    follow_request: (data, query) => dispatch(follow_request(data, query)),
    unfollow_request: (id, query) => dispatch(unfollow_request(id, query)),
    fetch_current_location: () => dispatch(fetch_current_location()),
    setLoginPrompt:(data)=>dispatch(setLoginPrompt(data)),
    setLoginPromptSignUp:(data)=>dispatch(setLoginPromptSignUp(data)),
    setLoginPromptLogin:(data)=>dispatch(setLoginPromptLogin(data))
});
/*const WrappedContainer = GoogleApiWrapper({
    apiKey: "AIzaSyCcEM2DwFBE1dzZn52D1w-N_3NI_97aXMI"
})(SearchResults);

export default withRouter(connect(mapState, mapProps)(WrappedContainer));*/
export default withRouter(connect(mapState, mapProps)(SearchResults));
