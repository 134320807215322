


export const categoryType = {
    Products: 'products',
    Business: 'business',
    Movies: 'movies',
    Actors: 'actors',
    Professionals: 'professionals'
};

export const gettextColor = (value) => {
    switch (value) {
        case "Pending":
            return "text-muted";
        case "Approved":
            return "text-primary";
        case "Reversed":
            return "text-red";
        default:
            return "text-primary";
    }
};

export const getCategoryTitle = (key)=>{
    switch (key) {
        case "modelNo":
            return "Product Model Number";
        case "website":
            return "Website";
        case "phone":
            return "Phone";
        default:
            return "";
    }
}


export const getCategoryValue = (key,value)=>{
    switch (key) {
        case "modelNo":
            return value;
        case "website":
            return value;
        case "phone":
            return value;
        default:
            return "";
    }
}

export const getInputLabel =(fieldName)=>{
    const profileNameArray = {
        profileName:"Product Name",
        manufacturer:"Manufacturer",
        modelNo:"Product Model No",
        website:"Website",
        phone:"Phone",
        phoneType:"Phone Type",
        minPrice:"Minimun Price",
        maxPrice:"Maximum Price",
        cutSheet:"Manufacturer Cut Sheet",
        warranty:"Manufacturer Warranty"

    }
    return profileNameArray[fieldName] || ""
}

export const getKeyName = (fieldName)=> {
    const profileNameArray = {
        profileName:"name",
        manufacturer:"manufacturer",
        modelNo:"model_number"
    }
    return profileNameArray[fieldName] || ""
}

export const getFieldValidation = (fieldName) => {
    if (fieldName === "website") {
        return {
            required: { value: true, errorMessage: 'Please enter a name' },
            pattern: {
                value: "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$",
                errorMessage: 'Please enter valid website link'
            },
        }
    }else if(fieldName==="phone"){
        return {
            pattern: { value: '^[0-9]+$', errorMessage: 'Please enter valid number' },
            minLength: { value: 10, errorMessage: 'Please enter valid number' },
            maxLength: { value: 10, errorMessage: 'Please enter valid number' },
        }
    } else {
        return {
            required: { value: true, errorMessage: 'Please enter a name' },
        }
    }

}


export const getRefinedParams = (obj) => {
    let params = new URLSearchParams(obj);
    let keysForDel = [];
   
    params.forEach((value, key) => {        
        if (value == '' || value === "null" || value === null || value === undefined) {            
            keysForDel.push(key);
        }
    });    
    keysForDel.forEach(key => {
        params.delete(key);
    });
    return params.toString();
}

export const getRatingImg = (rating) => { 
    switch (Math.round(rating / 0.5) * 0.5) {
        case 0:
            return "0rating.png";
        case 0.5:
            return "05rating.png"
        case 1:
            return "1rating.png";
        case 1.5:
            return "15.png"
        case 2:
            return "2rating.png";
        case 2.5:
            return "25.png"
        case 3:
            return "3rating.png";
        case 3.5:
            return "35.png"
        case 4:
            return "4rating.png";
        case 4.5:
            return "45.png"
        case 5:
            return "5rating.png";
        default:
            return "norating.png"
    }
}

export const padZeroes = (num) => (num <= 9) ? "0" + num : num;

/**
 * Parse the Youtube Video Link
 * @param  {[string]} url URL of Youtube Video
 * @return {[string]}     Youtube Video ID
 */
export const youtubeURLParser = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
};



// export const checkUrlIsImage = (url) => (url.match(/\.(jpeg|jpg|gif|png)$/) != null);

/**
 * [Returns if Media URL is Image]
 * @param  {[string]} url [URL of Media]
 * @return {[Promise]}     Returns if Media URL is Image
 */
export const checkUrlIsImage = (url) => {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
        img.onerror = () => resolve(false);
        img.onload = () => resolve(true);
    });
}

export const isMediaYoutubeURL = (url) => {
    if (url != undefined || url != '') {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match && match[2].length == 11) ? true : false;
    } else {
        return false;
    }
}

export const getYoutubeThumbnail = (videoID, thumbnailNumber = 2) => {
    return `http://img.youtube.com/vi/${videoID}/${thumbnailNumber}.jpg`
}

/**
 * Return the thumbnail for embeded media url
 * @param  {[string]} url [URL of Media]
 * @return {[string]}     Returns thumbnail image path
 */
export const mediaThumbnail = async (mediaLink) => {
    let thumbnail;
    if (await checkUrlIsImage(mediaLink)) {
        thumbnail = mediaLink;
    } else if (isMediaYoutubeURL(mediaLink)) {
        const youtubeVideoID = youtubeURLParser(mediaLink);
        thumbnail = getYoutubeThumbnail(youtubeVideoID);
    } else {
        thumbnail = ""; // Currently not supportinng thumbnail for any other media player except Youtube
    }

    return new Promise(resolve=> resolve(thumbnail))
}

export const pushUniqueObjectsInArray = (originalArr, newArrOfObjects, distinctId) => {
    newArrOfObjects.forEach(obj => {
        if (!(originalArr.find(o=> o[distinctId] === obj[distinctId]))) {
            originalArr.push(obj);
        }
    })

    return originalArr;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const sortAsc = (a, b) => {
    if (a.category < b.category) { return -1; }
    if (a.category > b.category) { return 1; }
    return 0;
}

export const isValidURL = (str) => {
    if(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(str)) {
         return true;
     } 
     return false;
 }

export const preDefinedSiteTourSteps = [
    [{
        target: ".step-1_home",
        // title: `Hello ${this.props.profileData && this.props.profileData.user && this.props.profileData.user.first_name !== null ? this.props.profileData.user.first_name : 'xyz'} and welcome to WikiReviews!`,
        content:
            "",
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        placement: 'center',
        customStyle: `stepStart`,
        styles: {
            options: {
                zIndex: 100000000,
            },
        },
    },
    {
        target: ".step-2",
        content:
            "See what's trending locally or nationally",
        placement: "top",
        disableBeacon: true,
        nextActionText: "OK",
        customStyle:'ls-1-2',
        isFixed:true,
        icon: require('../assets/images/website-tour/HomeTour/step-2.jpg'),
    },
    {
        target: ".step-3",
        content: `<b>Keep up to date</b> on all your friend's reviews, recommendations, and activity on the site. Be sure to add all of your friends on WikiReviews to make the most out of your news feed.`,
        placement: "top",
        disableBeacon: true,
        isFixed:true,
        icon: require('../assets/images/website-tour/HomeTour/step-3.jpg'),
    }, {
        target: ".step-4",
        content: `<b>Have you ever wanted to ask your friends for a recommendation?</b> This is the place where you can post questions and get advice from your friends on any topic.`,
        placement: "left",
        disableBeacon: true,
        customStyle:'ls-1-4',
        isFixed:true,
        icon: require('../assets/images/website-tour/HomeTour/step-4.jpg'),
    },
    {
        target: ".step-5",
        content: `<b>Connect your social media networks</b> so it's super easy to share your reviews across all of your accounts.`,
        placement: "top",
        disableBeacon: true,
        isFixed:true,
        customStyle:'ls-1-5',
        icon: require('../assets/images/website-tour/HomeTour/step-5.jpg'),
    },
    {
        target: ".step-6",
        content: `<b> Browse reviews of local businesses and popular movies and products.</b> </br> Click on any of the categories below and explore the popular listings for which people are creating reviews.`,
        placement: "top",
        disableBeacon: true,
        isFixed:true,
        icon: require('../assets/images/website-tour/HomeTour/step-6.jpg'),
        customStyle: 'browserReview'
    },
    {
        target: ".step-7",
        content: `<b> You can change your location </b><br/> at any time when searching by clicking on the text and entering any city or zip code.`,
        placement: "auto",
        disableBeacon: true,
        nextActionText: "OK",
        customStyle:'ls-1-7',
        isFixed:true,
        icon: require('../assets/images/website-tour/HomeTour/step-7.jpg'),
        skipText: "Skip this step",
        secDesc: "renderHomeStepLocationDesc"
    },
    {
        target: ".step-8",
        content: `<b>Use the dropdown menu to narrow your search results</b><br/> by category, such as business or movie`,
        placement: "left",
        disableBeacon: true,
        nextActionText: "OK",
        customStyle:'ls-1-8',
        isFixed:true,
        icon: require('../assets/images/website-tour/HomeTour/step-7_home_payment.png'),
        skipText: "Skip this step",
        secDesc: "renderHomeStepCategoryDesc"
    },
    {
        target: ".step-9",
        content: `<b> Now, Explore!</b><br/> Here you can type in your search term and click enter or click on the magnifying glass.`,
        placement: "left",
        customStyle: `nowExplore`,
        disableBeacon: true,
        nextActionText: "OK",
        isFixed:true,
        icon: require('../assets/images/website-tour/HomeTour/step-8_home_information.png'),
        skipText: "or skip this step",
        secDesc: "renderHomeStepSearchDesc"
    },
    {
        target: ".step-10",
        content: `<b>Search for your favorite restaurant</b> <br/> after the tour to explore our listing page features and create your first review!`,
        placement: "left",
        nextActionText: "OK",
        disableBeacon: true,
        isFixed:true,
        icon: require('../assets/images/website-tour/HomeTour/step-10.jpg'),
        skipText: "Or skip this step",
        customStyle: `searchRestaurant`,
        secDesc: "renderHomeStepSearchDesc"
    }
    ],
    [{
        target: ".step-1",
        content: "Want to learn about our cool <br/> features for creating a review?",
        icon: require('../assets/images/website-tour/CreateReviewTour/step-1_Icon.png'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        placement: 'center',
        customStyle: `stepStart stepStart-2`,
        nextStepContext: 'Skip to learn how to find information on listing pages, view images, and get directions.',
        styles: {
            options: {
                zIndex: 100000000,
            },
        },
    },
    {
        target: ".step-2_Review",
        content: `<b>Here is an example of writing a review for an app that allows you to keep track of your family and friends called Serendipity.</b><br/> The community would love to hear
        your opinion. Please give an overall star rating and note that we also allow for zero star ratings, should you want your rating to make a statement about your experience.`,
        placement: "top",
        disableBeacon: true,
        isFixed:true,
        nextActionText: "OK",
        skipText: "Skip this step",
        icon: require('../assets/images/website-tour/CreateReviewTour/step-2.jpg'),
        customStyle: `ls-2-1`,
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
    {
        target: ".step-3_Review",
        content: `<p><b>Detailed stars matter!</b></p>
        Tell the community about what is good and what isn't. A simple five star rating is not enough to give a clear picture to readers so please share your insights by using our detailed star ratings.`,
        placement: "top",
        reverse: true,
        iconBottom: true,
        disableBeacon: true,
        isFixed:true,
        skipText: "Skip this step",
        nextActionText: "OK",
        icon: require('../assets/images/website-tour/CreateReviewTour/step-3.png'),
        customStyle: `ls-2-2`,
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
    {
        target: ".step-4_Review",
        content: `<b>Video reviews</b> allows users to create a more in-depth review, which is great for the community. Try creating a video review to show off all the features of a product and how to use it!`,
        placement: "top",
        disableBeacon: true,
        isFixed:true,
        skipText: "Skip this step",
        nextActionText: "OK",
        icon: require('../assets/images/website-tour/CreateReviewTour/step-4.jpg'),
        customStyle: `ls-2-3`,
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
    {
        target: ".step-5_Review",
        content: `<b>Mark your review as anonymous</b> and feel safe knowing that the review can never be traced back to your account. We understand that there are times when you want to create a review and not have it known who created it. We allow for one anonymous review for every five reviews created. Please know that we remove any and all affiliation to your account so the review cannot be edited, updated, or deleted after it has been published.`,
        placement: "top",
        disableBeacon: true,
        reverse: true,
        iconBottom: true,
        isFixed:true,
        skipText: "Skip this step",
        nextActionText: "OK",
        icon: require('../assets/images/website-tour/CreateReviewTour/step-4_review_anonymous.png'),
        customStyle: `ls-2-4`,
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
    // {
    //     target: ".step-6_Review",
    //     content: `<b>Rate and review employees</b><br/> to give the readers the most accurate review. Many times, a five star rating scale is not enough. We allow users to rate individual employees, such as lawyers and doctors, to create the most well-rounded review to allow readers to make better decisions.`,
    //     placement: "top",
    //     disableBeacon: true,
    //     skipText: "Skip this step",
    //     nextActionText: "OK",
    //     icon: require('../assets/images/website-tour/CreateReviewTour/step-6.jpg'),
    //     customStyle: `ls-2-5`,
    //     styles: {
    //         options: {
    //             zIndex: 100000000,
    //             innerWidth: 125,
    //         },
    //     },
    // },
    {
        target: ".step-7_Review",
        content: `<b>You are now ready to publish your review</b><br/> or you can save it as a draft if you want to edit it later`,
        placement: "top",
        disableBeacon: true,
        skipText: "Skip this step",
        isFixed:true,
        nextActionText: "OK",
        icon: require('../assets/images/website-tour/CreateReviewTour/step-7.jpg'),
        customStyle: `ls-2-6`,
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
    ],
    [{
        target: ".step-1",
        content: "Do you want to learn <br/> more about our tools?",
        subContent: `Get the most out of WikiReviews by getting to know our features!`,
        icon: require('../assets/images/website-tour/ListingPage/start-listing-spark.png'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        placement: 'center',
        customStyle: `stepStart stepStart-2 stepStart-3 ls-3-0`,
        nextStepContext: 'Skip to learn about setting up your profile page to enhance your experience on the site',
        styles: {
            options: {
                zIndex: 100000000,
            },
        },
    },
    {
        target: ".step-2_Listing",
        content: `Mouseover the arrow and get more basic information`,
        placement: "top",
        isFixed:true,
        disableBeacon: true,
        nextActionText: "OK",
        skipText: "Skip this step",
        descImg: require('../assets/images/website-tour/ListingPage/step-2_sec.jpg'),
        icon: require('../assets/images/website-tour/ListingPage/step-2.jpg'),
        customStyle: `ls-3-1`,
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
    {
        target: ".step-3_Listing",
        content: `<b>Mouseover the Gallery</b> <br/> to view images and videos`,
        placement: "top",
        isFixed:true,
        disableBeacon: true,
        skipText: "Skip this step",
        nextActionText: "OK",
        icon: require('../assets/images/website-tour/ListingPage/step-3.jpg'),
        customStyle: `ls-3-2`,
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
    {
        target: ".step-4_Listing",
        content: `Here you can get <b>directions</b> and see the exact location of the business or movie theater.`,
        placement: "bottom",
        isFixed:true,
        disableBeacon: true,
        skipText: "Skip this step",
        nextActionText: "OK",
        icon: require('../assets/images/website-tour/ListingPage/step-4.jpg'),
        customStyle: `ls-3-3`,
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
        // {
        //     target: ".step-5_Listing",
        //     content: `We would love to show you how to configure your <br/> profile page and privacy settings.<br/> Do you want to take the tour?`,
        //     icon: require('../assets/images/website-tour/ListingPage/step-5.png'),
        //     disableBeacon: true,
        //     disableOverlayClose: true,
        //     hideCloseButton: true,
        //     placement: 'center',
        //     spotlightClicks: true,
        //     customStyle: `stepStart max-height-500`,
        //     nextStepContext: 'Skip to learn more about the amazing features we have for movie buffs.',
        //     styles: {
        //         options: {
        //             zIndex: 100000000,
        //         },
        //     },
        // },
        // {
        //     target: ".step-6_Listing",
        //     content: `You can always click here to navigate back to your profile page.`,
        //     placement: "bottom",
        //     disableBeacon: true,
        //     skipText: "Skip this step",
        //     nextActionText: "OK",
        //     icon: require('../assets/images/website-tour/ListingPage/step-6.jpg'),
        //     styles: {
        //         options: {
        //             zIndex: 100000000,
        //             innerWidth: 125,
        //         },
        //     },
        // },

    ],
    [
        {
            target: ".step-1",
            content: "We would love to show you how to configure your profile page and privacy settings. Do you want to take the tour?",
            icon: require('../assets/images/website-tour/ListingPage/step-5.png'),
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            placement: 'center',
            customStyle: `stepStart stepStart-2 stepStart-3`,
            nextStepContext: 'Skip to learn about the amazing features we have for movie buffs',
            styles: {
                options: {
                    zIndex: 100000000,
                },
            },
        },
        {
            target: ".step-1_myProfile",
            content: `You can click here at any time to upload or change your profile picture. Upload a profile picture so your friends can easily recognize you and put a face to all those wonderful reviews you'll be creating!`,
            placement: "bottom",
            isFixed:true,
            disableBeacon: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-1.jpg'),
            customStyle: `ls-4-1`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        }, {
            target: ".step-2_myProfile",
            content: `<b>Upload a cover photo</b><br/>to add a personal touch to your profile.`,
            placement: "top",
            isFixed:true,
            disableBeacon: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-2_myprofile.png'),
            customStyle: `ls-4-2`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-3_myProfile",
            content: `<b>Modify your privacy settings</b><p>here to determine who can see your posts and profile.</p>`,
            placement: "top",
            isFixed: true,
            disableBeacon: true,
            skipText: "Skip this step",
            descImg: require('../assets/images/website-tour/MyProfile/step-3_myprofile_profile-settings.jpg'),
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-3_myprofile_privacy-setting.png'),
            customStyle: `ls-4-3 step-3_myProfile`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-4_myProfile",
            content: `Click on any of the text to easily <b>change</b> your profile information.</br>`,
            input: true,
            inputIcon: require('../assets/images/website-tour/MyProfile/stp-4_input_icon.png'),
            sec_content: `<b>TIP:</b> Choose a username that is easy to remember!`,
            placement: "top",
            isFixed:true,
            placementBeacon: "top",
            primary_desc: 'renderMyProfileStepUsernameDesc',
            disableBeacon: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-4.jpg'),
            customStyle: `ls-4-4`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-5_myProfile",
            content: `<b>Collect awards</b> by contributing on WikiReviews! The more reviews and contributions you create, the more awards you will receive. Try to create reviews on both desktop and mobile to maximize the amount of awards you can receive.`,
            placement: "bottom",
            isFixed:true,
            disableBeacon: true,
            reverse: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-5.jpg'),
            customStyle: `ls-4-5`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-6_myProfile",
            content: `Converse with your WikiReviews friends by using our <b>messages</b> feature.`,
            placement: "bottom",
            isFixed:true,
            reverse: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-6.jpg'),
            customStyle: `ls-4-6`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-7_myProfile",
            content: `Be sure to import all your contacts and add all of your <b>friends</b> on WikiReviews to maximize your experience on the site.`,
            placement: "bottom",
            isFixed:true,
            reverse: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-7.jpg'),
            customStyle: `ls-4-7`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-8_myProfile",
            content: `Want to edit, update, or delete a <b>review</b> you created? This is the place to do that.`,
            placement: "bottom",
            isFixed:true,
            disableBeacon: true,
            reverse: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-8.jpg'),
            customStyle: `ls-4-8`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-9_myProfile",
            content: `Here is where you can manage all of your <b>Community Review Projects</b> that you have drafted or published.`,
            placement: "bottom",
            isFixed:true,
            disableBeacon: true,
            reverse: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-9.jpg'),
            customStyle: `ls-4-9`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-10_myProfile",
            content: `Here is where you can see all of the <b>contributions</b> that you have made to WikiReviews.`,
            placement: "bottom",
            isFixed:true,
            disableBeacon: true,
            reverse: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-10.jpg'),
            customStyle: `ls-4-9`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-11_myProfile",
            content: `Here is where you can manage all of your <b>recommendations, wants,</b> and <b>favorites.</b> You will also be able to see all of the updates and reviews made to listings on your watchlist`,
            placement: "bottom",
            isFixed:true,
            disableBeacon: true,
            reverse: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-11.jpg'),
            customStyle: `ls-4-11`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        {
            target: ".step-12_myProfile",
            content: `<b>View your statistics</b> and see the distribution of all your star ratings,reviews, CRPs, the amount of contributions you have made, and the contribution demographics.`,
            placement: "top",
            placementBeacon: "top",
            isFixed:true,
            offset: 5,
            disableBeacon: true,
            reverse: true,
            skipText: "Skip this step",
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/MyProfile/step-12.jpg'),
            customStyle: `ls-4-12`,
            styles: {
                options: {
                    zIndex: 100000000,
                    innerWidth: 125,
                },
            },
        },
        // {
        //     target: ".step-13_myProfile",
        //     content: `<b>Want to go to the movies?</b> After the tour, set your home theater by searching by name, city, or zip code. Once you add your home theater, all of the showtimes and trailers for the films playing at your home theater will be shown on the main movie category page for you.`,
        //     // placement: "top",
        //     // placementBeacon:"top",
        //     placement: "left",
        //     offset:5,
        //     disableBeacon: true,
        //     reverse: true,
        //     skipText: "Skip this step",
        //     nextActionText: "OK",
        //     icon: require('../assets/images/website-tour/MyProfile/set-hometheater-profile.png'),
        //     customStyle: `ls-4-12`,
        //     secImg: require('../assets/images/website-tour/MyProfile/pin-for-theater.jpg'),
        //     styles: {
        //         options: {
        //             zIndex: 100000000,
        //             innerWidth: 125,
        //         },
        //     },
        // },
    ],
    [{
        target: ".step-1",
        content: "let's check out the movies page!",
        icon: require('../assets/images/website-tour/MovieMainCategoryTour/movieStart.png'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        placement: 'center',
        customStyle: `stepStart stepStart-2 stepStart-3`,
        nextStepContext: 'Skip this to learn about uploading personal projects I can have the community review',
        styles: {
            options: {
                zIndex: 100000000,
            },
        },
    },
    {
        target: ".tourStep-2_movie",
        content: `Here we show all the new movies, trailers, and theater locations playing released films. You can buy your movie tickets here, too!`,
        placement: "bottom-end",
        disableBeacon: true,
        skipText: "Skip this step",
        nextActionText: "OK",
        customStyle: 'ls-5-1',
        reverse: true,
        icon: require('../assets/images/website-tour/MovieMainCategoryTour/step-1.png'),
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
    {
        target: ".tourStep-4_movie",
        content: `Share a link of the listing, recommend pages you like to help your friends when they are searching, pin a listing to add it your favorites or watchlist and flag the listing to notify us about updates.`,
        placement: "bottom",
        disableBeacon: true,
        skipText: "Skip this step",
        nextActionText: "OK",
        customStyle: 'ls-5-4',
        icon: require('../assets/images/website-tour/MovieMainCategoryTour/step-3.png'),
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    },
    {
        target: ".tourStep-5_movie",
        content: `<b>Community Review Projects (CRP)<br/><br/>
        To view the CRP page, click on the arrow next to the Home button and click on "Community Review Projects" to navigate to the main CRP page.</b>`,
        placement: "bottom-end",
        disableBeacon: true,
        reverse: true,
        offset: 0,
        customStyle: 'ls-5-5',
        skipText: "Skip this step",
        nextActionText: "OK",
        icon: require('../assets/images/website-tour/MovieMainCategoryTour/step-4.png'),
        styles: {
            options: {
                zIndex: 100000000,
                innerWidth: 125,
            },
        },
    }
    ],
    [
        {
            target: ".step-1_crp",
            // title: `Hello ${this.props.profileData && this.props.profileData.user && this.props.profileData.user.first_name !== null ? this.props.profileData.user.first_name : 'xyz'} and welcome to WikiReviews!`,
            content:
                "",
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            placement: 'center',
            customStyle: `stepStart`,
            styles: {
                options: {
                    zIndex: 100000000,
                },
            },
        },
        {
            target: ".step-2_crp",
            content:
                "Ask the main question you want  the community to answer about your project using either our pre-set questions or create your own",
            placement: "top",
            disableBeacon: true,
            customStyle: 'ls-6-1',
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/HomeTour/step-2.jpg'),
        },
        {
            target: ".step-3_crp",
            content: `Upload the files you want the community to review`,
            placement: "left",
            disableBeacon: true,
            customStyle: 'ls-6-2',
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/CRPTour/step-2.png'),
        }, {
            target: ".step-4_crp",
            content: `Ask the main question you want the community to answer about your project using either our pre-set question or create your own`,
            placement: "left",
            customStyle: 'ls-6-3',
            disableBeacon: true,
            nextActionText: "OK",
            icon: require('../assets/images/website-tour/CRPTour/step-3.png'),
        },
        {
            target: ".last_step_crp",
            // title: `Hello ${this.props.profileData && this.props.profileData.user && this.props.profileData.user.first_name !== null ? this.props.profileData.user.first_name : 'xyz'} and welcome to WikiReviews!`,
            content:
                "",
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            placement: 'center',
            customStyle: `stepStart`,
            styles: {
                options: {
                    zIndex: 100000000,
                },
            }
        }
        // {
        //     target: ".step-5_crp",
        //     content: `And choose your confidence level to determine the accuracy of your results.`,
        //     placement: "top",
        //     disableBeacon: true,
        //     icon: require('../assets/images/website-tour/HomeTour/step-5.jpg'),
        // },
        // {
        //     target: ".step-6_crp",
        //     content: `Upload the files you want the community to review`,
        //     placement: "top",
        //     disableBeacon: true,
        //     icon: require('../assets/images/website-tour/HomeTour/step-6.jpg'),
        // },
        // {
        //     target: ".step-7_crp",
        //     content: `Ask any additional open ended questions you want here.`,
        //     placement: "left",
        //     disableBeacon: true,
        //     nextActionText: "OK",
        //     spotlightClicks: true,
        //     icon: require('../assets/images/website-tour/HomeTour/step-7.jpg'),
        //     skipText: "Skip this step",
        //     secImg: require('../assets/images/website-tour/HomeTour/step-7_Sec.jpg')
        // }
    ],
]

export const checkTextVisibilty =(string)=>{
    if (string?.length===0 || string?.length===undefined) return true
    else return false
}

export const detectlink = (text)=>{
    // var urlRegex = /(https?:\/\/[^\s]+)/g;
    var urlRegex = /(((https?:\/\/)|(www\.)|(data?:image))[^\s]+)/g;
    return text?.replace(urlRegex, function(url,b,c) {
        var url2 = (c == 'www.') ?  'http://' +url : (c=='data:image') ? 'image' : url;
        if(url2==='image'){
            return 'image'
        }else{
            return '<a href="' +url2+ '" target="_blank">' + url + '</a>';
        }
    }) 
}

export const removeHTMLtags = (value)=>{
    var removedTagText = value.replace(/<(?:.|\n)*?>/gm, '');
    return detectlink(removedTagText)
}

export const getDOBDate = (dobDay, dobMonth, dobYear) => {
    const month = new Date(Date.parse(dobMonth +" 1, 2012")).getMonth() + 1;

    return `${dobYear}-${month <= 9 ? `0${month}` : `${month}`}-${dobDay <= 9 ? `0${dobDay}` : `${dobDay}`}`;
}

export const getShareReviewURL = (category, slug, id) => {
    const host = window.location.host;
    const protocol = window.location.protocol;
    const itemId = !!id ? `/${id}` : "";
    const itemSlug = !!slug ? `/${slug}` : "";
    let urlCat
    switch (category?.toLowerCase()) {
        case "products":
            urlCat = "products"
            break;
        case "product":
            urlCat = "products"
            break;
        case "business":
            urlCat = "business"
            break;
        case "actors":
            urlCat = "actors"
            break;
        case "actor":
            urlCat = "actors"
            break;
        case "doctors":
            urlCat = "doctors"
            break;
        case "doctor":
            urlCat = "doctors"
            break;
        case "accountants":
            urlCat = "accountants"
            break;
        case "accountant":
            urlCat = "accountants"
            break;
        case "dentists":
            urlCat = "dentists"
            break;
        case "dentist":
            urlCat = "dentists"
            break;
        case "lawyers":
            urlCat = "lawyers"
            break;
        case "lawyer":
            urlCat = "lawyers"
            break;
        case "movies":
            urlCat = "movies"
            break;
        case "movie":
            urlCat = "movies"
            break;

        default:
            urlCat = category?.toLowerCase();
            break;
    }

    return `${protocol}//${host}/${urlCat || ""}${itemId}${itemSlug}`;
}

export const getCategoryName = (category) => {
    switch (category?.toLowerCase()) {
        case "products":
            return "products"

        case "product":
            return "products"

        case "business":
            return "business"

        case "actors":
            return "actors"

        case "actor":
            return "actors"

        case "doctors":
            return "doctors"

        case "doctor":
            return "doctors"

        case "accountants":
            return "accountants"

        case "accountant":
            return "accountants"

        case "dentists":
            return "dentists"

        case "dentist":
            return "dentists"

        case "lawyers":
            return "lawyers"

        case "lawyer":
            return "lawyers"

        case "movies":
            return "movies"

        case "movie":
            return "movies"

        default:
            return category?.toLowerCase();


    }
}


export const getFullName = (objName) => {
    let first = !!objName?.firstname ? objName?.firstname : "";
    let last = !!objName?.lastname ? objName?.lastname : "";

    return first + " " + last
}



export const checkSettingFieldType = (field)=>{
    switch (field) {
        case "who_can_view_my_profile":
            return "radio"

        case "reviews":
            return "checkbox"

        case "who_can_send_me_private_messages":
            return "radio"

        case "profile_settings":
            return "radio"

        case "reviews_settings":
            return "checkbox"

        case "Professionals":
            return  "professionals";

        case "sharing":
            return "checkbox"

        case "update_my_feed_when_someone":
            return "checkbox"

        case "email_me_when_someone_settings":
            return "checkbox"

        case "email_me_when_someone":
            return "checkbox"

        case "wikireviews_herald":
            return "checkbox"        

        default:
            return "";


    }
}


export const getFieldname = (field)=>{
    switch (field) {
        case "who_can_view_my_profile":
            return "Who can view My Profile?"

        case "reviews":
            return "Reviews"

        case "who_can_send_me_private_messages":
            return "Who can send me Private Messages?"

        case "profile_settings":
            return "Profile Settings"

        case "reviews_settings":
            return "Reviews"

        case "sharing":
            return "Sharing"

        case "update_my_feed_when_someone":
            return "Update My Feed when someone ..."

        case "email_me_when_someone_settings":
            return "Email me when someone ..."

        case "email_me_when_someone":
            return "Email me when someone ..."

        case "wikireviews_herald":
            return "WikiReviews Herald"        

        default:
            return "";


    }
}

export const getFieldSubText = (field) => {
    switch (field) {


        case "reviews":
            return "Your Reviews will always be public, but you can block users outside your friends list from following (subscribing to) them."

        case "reviews_settings":
            return "(Available after first 5 reviews)"

        case "automatically_add_pages_i_edit_to_my_watchlist":
            return "Your watchlist keeps an eye on changes, so don't have to. You can view your watchlist or use your email settings to get real-time notifications about new and updated reviews"

        case "dont_put_my_own_changes_on_my_watchlist":
            return "This prevents your own changes from appearing on your watchlist. If you contribute a lot, this can make the watchlist more useful because it will be easier to see other people's changes."

        default:
            return null;


    }
}

export const searchDataMapper = ({dataList}) =>{
    let formatedDataList = [];
        if (dataList.length == 0) {
            return formatedDataList;
        }
        formatedDataList = dataList.map((data) => ({ ...data?._source }));
        return formatedDataList;
}

export const responsiveCarousel = {
    videoModal: {
        mobile: {
            breakpoint: { max: 9999999, min: 0 },
            items: 1
        }
    },
    viewGalleryImages: {
        desktop: {
            breakpoint: { max: 9999999, min: 1200 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1199, min: 576 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 575, min: 0 },
            items: 1
        }
    }
};


export const getUniqueArrayOfObject = (array,key)=>{
    if(array.length){
        const arrayUniqueByKey = [...new Map(array?.map(item =>
            [item[key], item])).values()];
            return arrayUniqueByKey

    }else{
        return []
    }
}


export const getReviewSuffix = (count)=>{
    if (count && count > 1) return "reviews"
    else return "review"
}


// highlight searched term

const getDisplayType=(element)=> {
    var elementStyle = element.currentStyle || window.getComputedStyle(element, "");
    return elementStyle.display;
  }

export const highlightSearchTerm = () =>{
    let displaySet = false;
    // const displayArr = [];
    // var currval = document.getElementById("filter-search")?.value
    // var searchVal = document.getElementById("filter-search")?.value?.toLowerCase();
    // var filterItems = document.querySelectorAll('[' + 'filter-value' + ']');
    // for (var i = 0; i < filterItems.length; i++) {
    //   if (!displaySet) {
    //     displayArr.push(getDisplayType(filterItems[i]));
    //   }
    //   var textcontent = filterItems[i].textContent;
    //   var replacedval = "<strong style=background-color:yellow>" + currval + "</strong>"
    //   var finalval = textcontent?.replace(currval, replacedval);
    //   filterItems[i].innerHTML = finalval;
    //   // filterItems[i].style.display = 'none';
    //   if (filterItems[i].getAttribute('filter-value').toUpperCase().indexOf(searchVal.toUpperCase()) >= 0) {
    //     filterItems[i].style.display = displayArr[i];
    //   }
    // }
    displaySet = true;
}