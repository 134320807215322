import * as actions from "../actions/doctor";
const initialState = {
    get_review_listing: null
};


const doctor = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case actions.GET_REVIEW_LISTING_DOCTOR:
            state.get_review_listing = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default doctor;