import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
  } from "reactstrap";
  import Loaderimg from "../../../assets/images/w-brand-black.jpg";
  import { Link } from "react-router-dom";
import { Ratings } from "../../molecules/homepage/ShowRatings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel as ReactRespCarousel } from "react-responsive-carousel";
import {
    get_admin_dispute_discussion,
    get_review_details,
    dispute_modal_status
  } from "../../../actions/dispute";
import Moment from "react-moment";
import DeleteBtn from "../../atoms/deleteBtn";
import ReactHtmlParser from "react-html-parser";


class AdminRequestedDispute extends Component {
    constructor(props) {
        super(props)
        this.state = {  
            showReviewModalToggle: false,
            dispute_data: '',
            review_id: '',
            review_user_name: '',
            review_profile_image: '',
            review_target_name: '',
            review_rating: '',
            review_time_stamp: '',
            review_description: '',
            reviewmedia_set: '',
            review_user_link: '',
            review_pathname: '',
            review_slug: '',
            review_state: '',
            
        }
    }

    componentWillReceiveProps(nextProps) {
        if ( nextProps?.administrative_disputed_review ) {
         
            this.setState({ dispute_data: nextProps?.administrative_disputed_review && nextProps?.administrative_disputed_review['results'][0]  })    
          } 
        
        if ( nextProps?.get_review_data ) {
            let review = nextProps?.get_review_data['results'][0]
            this.setState({
                review_id: review?.id,
                review_user_name: (review?.created_by?.user?.first_name + review?.created_by?.user?.last_name),
                review_profile_image: review?.created_user_media?.user?.user?.current_profile_pic,
                review_target_name: review?.associated_to?.name,
                review_rating: review?.ratings_sets,
                review_time_stamp: review?.review_date_time,
                review_description: review?.review_text,
                reviewmedia_set: review?.reviewmedia_set,
                review_user_link: review?.created_by?.vanity_url,
                review_pathname: review?.associated_to?.content_type?.category?.toLowerCase(),
                review_slug: review?.associated_to?.slug,
                review_state: review?.associated_to?.id,
                showReviewModalToggle : true
                })    
          } 
               
    }

    componentDidMount = () => {
        this.handleGetDisputedData()        
    }

    handleGetDisputedData = () => {
        if( this.props.review ) {
            this.setState({ review_id: this.props.review})
            this.props.get_admin_dispute_discussion(this.props.review);
            this.props.get_review_details(this.props.review)
        }

    }


    


    render() {
        const { } = this.props;
        let { dispute_data,
            confirmationToggle,
            confirmationReason,
            isLoading,
            review_user_name,
            review_target_name,
            review_rating,
            review_time_stamp,
            review_description,
            reviewmedia_set,
            review_profile_image,
            review_user_link,
            review_pathname,
            review_slug,
            review_state,
             } = this.state;

        return (
            <div>

                {/* Discussion and reply against dispute by community user */}
                <Modal
                    size="xl"
                    // scrollable
                    className="drawer-modal"
                    isOpen={this.state.showReviewModalToggle}
                    // isOpen = {true}
                    toggle={() =>
                        this.setState({
                        showReviewModalToggle: !this.state.showReviewModalToggle,
                        })
                    }
                    >
                    { isLoading ?
                        <div className="loader_div">
                        <div className="inner-loader">
                            <img src={Loaderimg} alt="" />
                        </div>
                    </div>
                    :
                        ''
                    }
                    <form className="d-flex flex-column h-100">
                        <div className="modal-header flex-wrap p-0">
                        {/* Dispute review intro design */}
                        <div className="row mb-5 form flex-grow-1">                            
                            <div className="col-lg-8 ml-auto">
                            <div className="bg-warning text-dark p-2 fs-14">
                                <div className="d-flex flex-wrap mx-n2">
                                <div className="text-white px-2">
                                    <span className="font-weight-normal">
                                    You're voting this 
                                    <span className="font-weight-bold">
                                        Disputed Review 
                                    </span>
                                    </span>
                                </div>
                                </div>
                            </div>
                            <div className="bg-white p-3 fs-14 text-dark">
                                    <div className="d-flex mx-n2 mb-2">
                                    <div className="px-2">
                                        <a href="#" className="text-decoration-none">
                                        <img
                                            className="img-circle _50x50"
                                            src={review_profile_image ? review_profile_image : require("../../../assets/images/icons/user-circle.png")}
                                            alt= {review_target_name}
                                        />
                                        </a>
                                    </div>
                                    <div className="px-2 flex-grow-1">
                                        <div className="px-2 flex-grow-1">
                                        <div className="ff-alt">
                                            <a
                                            href={`/people/${review_user_link}`}
                                            className="text-reset ff-base font-weight-bold"
                                            >
                                            {review_user_name} 
                                            </a>
                                            <span> wrote a review for </span>
                                            <Link
                                            to={{
                                                pathname: `/${review_pathname}/${
                                                    review_slug
                                                    ? review_slug
                                                    : "null"
                                                }`,
                                                state: { id: review_state },
                                            }}
                                            className="text-reset font-weight-bold"
                                            >
                                            {review_target_name}
                                            </Link>{" "}
                                            and gave it
                                            <br />
                                            <Ratings
                                            ratings={review_rating }
                                            />
                                            <span> {review_time_stamp}</span>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="ff-alt my-4">
                                    <i className="icon-comma-left"></i>
                                    <span className="comment-body-renderer">
                                        {" "}
                                        {ReactHtmlParser(review_description)}
                                    </span>
                                    <i className="icon-comma-right"></i>
                                    </div>
                                    
                                    
                                    <div className="mb-3">
                                    <ReactRespCarousel className="rr-carousel" infiniteLoop={true} showArrows={true} showStatus={false} showIndicators={false}>
                                        {reviewmedia_set && reviewmedia_set.length > 0 ? (
                                            reviewmedia_set.map((data, index1) => {                                            
                                                    return (
                                                        <div key={index1}>
                                                            {data.type === "image" ?
                                                                <img width="100" src={data.url} alt=""/>
                                                            :
                                                                data.type === "video" ?
                                                                <video width="100%" controls>
                                                                    <source src={data.url} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    );
                                                
                                            })
                                        ) :
                                            ''
                                        }
                                    </ReactRespCarousel>                                    
                                    </div>

                                </div>
                                </div>
                        </div>
                        <div className="w-100">
                            <div>
                            <Button
                                color="primary"
                                // onClick={() =>
                                // this.setState({
                                //     showReviewModalToggle: !this.state
                                //     .showReviewModalToggle,
                                // })
                                // }
                                onClick={() => this.props.dispute_modal_status({modal:false})
                                }
                            >
                                <FontAwesomeIcon className="mr-2" icon="angle-left"  />
                                back to listing
                            </Button>
                            </div>
                        </div>
                        </div>
                        <ModalBody className="p-0 fs-14 text-dark bg-transparent">
                        <div className="bg-dark text-white">
                        <Row className="mb-3" noGutters>
                            <Col xs={12} lg="auto">
                            <div
                                className="d-flex flex-column justify-content-center bg-tertiary text-white text-center px-5 py-4 h-100"
                            >
                                <div>
                                <img
                                    src={require("../../../assets/images/icons/star/blue/fill.png")}
                                    alt=""
                                />
                                <h2 className="my-2 fs-50 text-shadow">REVIEW DISPUTE</h2>
                                <img
                                    src={require("../../../assets/images/icons/star/blue/fill.png")}
                                    alt=""
                                />
                                </div>
                            </div>
                            </Col>
                            <Col xs={12} className="col-lg">
                            <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                                <Row>
                                <Col className="text-center">
                                    
                                    <div className="fs-24 ff-headings text-uppercase">
                                    Please watch the video to see how Review Disputes work
                                    </div>
                                    <div className="mt-3">
                                    <Row className="justify-content-between align-items-center">
                                        <Col
                                        xs="12"
                                        className="d-flex justify-content-center align-items-center"
                                        >
                                        <div className="text-center d-flex align-items-center justify-content-center">
                                            <div>
                                            <img
                                                className="img-fluid"
                                                src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                                alt=""
                                            />
                                            </div>
                                        </div>
                                        <div
                                            className="video-holder play-icon d-inline-block mx-2"
                                            role="button"
                                            onClick={() =>
                                            this.setState({
                                                watchHowDisputeReviewWorksModalToggle: true,
                                            })
                                            }
                                        >
                                            <img
                                            height="80"
                                            src={require("../../../assets/images/dispute_jury.webp")}
                                            alt="dispute"
                                            />
                                        </div>
                                        <div className="text-center d-flex align-items-center justify-content-center">
                                            <div>
                                            <img
                                                className="img-fluid"
                                                src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                                alt=""
                                            />
                                            </div>
                                        </div>
                                        </Col>
                                    </Row>
                                    </div>
                                </Col>
                                </Row>
                            </div>
                            </Col>
                        </Row>                            
                        </div>

                        <div className="bg-white px-3 py-4">
                            <Row>
                            <Col md={2}>
                            </Col>
                            <Col md={8}>
                                <div className="mb-3">
                                <div className="text-primary fs-16 font-weight-bold mb-2">
                                    Dispute Review Reason, Explanation & Evidence.
                                </div>
                                <div>
                                    <div className="bg-light p-2 text-dark mb-2">
                                    <div className="text-tertiary">
                                        Flagged for
                                        <strong>
                                        {" "}
                                        "{" "}
                                        {dispute_data?.flag_name}{" "}
                                        "{" "}
                                        </strong>
                                    </div>
                                    <hr className="my-2" />
                                    <div>
                                        <div className="d-flex mx-n2">
                                        <div className="px-2">
                                            <img
                                            className="img-circle _50x50"
                                            src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                            alt={dispute_data?.user_name}
                                            />
                                        </div>
                                        <div className="px-2">
                                            <div className="mb-2">
                                            by{" "}
                                            <a href="#" className="text-reset">
                                                <strong>
                                                {dispute_data?.user_name}
                                                </strong>
                                            </a>
                                            &nbsp;|&nbsp;
                                            <span className="fs-12 text-muted">
                                                Created on&nbsp;
                                                <span className="text-reset">
                                                {/* {discussion.created_on} */}
                                                <Moment format="MMM DD,YYYY">
                                                    {dispute_data?.created_on}
                                                </Moment>
                                                &nbsp; , &nbsp;
                                                <Moment format="HH:MM">
                                                    {dispute_data?.created_on}
                                                </Moment>
                                                </span>
                                            </span>
                                            {( this.props?.my_profile?.user?.email) ===
                                            (dispute_data?.user_details?.email) &&
                                             this.state.can_vote ? (
                                                <DeleteBtn
                                                onClick={() => this.handleDeleteDispute()}
                                                />
                                            ) : (
                                                ""
                                            )}
                                            </div>

                                            <div className="wb-break-word">
                                            "{" "}
                                            {dispute_data?.content_flagged}{" "}
                                            "
                                            </div>
                                            <div className="bg-light p-3">
                                            <div className="mb-2">
                                                {dispute_data?.media === "No media found" ?
                                                ''
                                                :
                                                dispute_data?.media != "No media found" &&
                                                dispute_data?.media?.map((media) => (
                                                    <div>
                                                    {media.includes(".jpeg") ||
                                                    media.includes(".jpg") ||
                                                    media.includes(".png") ? (
                                                        <div className="mb-2">
                                                        <img
                                                            className="img-fluid"
                                                            src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                            alt=""
                                                        />
                                                        </div>
                                                    ) : (
                                                        <div className="mb-2 d-inline-block">
                                                        <div className="d-flex mx-n2">
                                                            <div className="px-2">
                                                            <FontAwesomeIcon
                                                                icon="paperclip"
                                                                fixedWidth
                                                            />
                                                            </div>
                                                            <div className="px-2 col">
                                                            <span className="font-weight-bold text-truncate">
                                                                <a
                                                                className="text-reset font-weight-bold"
                                                                href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                                >
                                                                {
                                                                    media
                                                                    .toString()
                                                                    .split("/")[2]
                                                                }
                                                                </a>
                                                            </span>
                                                            </div>
                                                            <div className="px-2 ml-auto">
                                                            <span className="mr-2">
                                                                |
                                                            </span>
                                                            <a
                                                                href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-reset"
                                                            >
                                                                <FontAwesomeIcon icon="external-link-alt" />
                                                            </a>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )}
                                                    </div>
                                                ))}

                                                <hr/>
                                                { dispute_data?.embed_links?.split(',')?.length > 0 ?
                                                <div className="fs-14 text-dark font-weight-bold">Links</div>
                                                :
                                                ''
                                                }
                                                
                                                { dispute_data?.embed_links?.split(',')?.map((link) =>   
                                                    <div>                                                       
                                                        {link}
                                                    </div>
                                                )
                                                }                                            


                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>
                            </Col>
                            <Col md={2}>
                            </Col>
                            </Row>
                        </div>
                        <div className="bg-white px-3 py-4 text-right">
                            <Button color="primary" className="mw" onClick={() => this.handleDisputeRequest('accept')}> Accept </Button>
                            <Button color="primary" className="mw" onClick={() => this.handleDisputeRequest('reject')}> Reject </Button>
                        </div>
                        </ModalBody>
                        <ModalFooter className="bg-white"> 
                                         
                        </ModalFooter>
                    </form>
                    </Modal>

                {/* SEE HOW REVIEW DISPUTES WORK */}
                <Modal
                        isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
                        toggle={() =>
                            this.setState({
                            watchHowDisputeReviewWorksModalToggle: !this.state
                                .watchHowDisputeReviewWorksModalToggle,
                            })
                        }
                        >
                        <ModalHeader
                            className="text-uppercase px-0"
                            toggle={() =>
                            this.setState({
                                watchHowDisputeReviewWorksModalToggle: !this.state
                                .watchHowDisputeReviewWorksModalToggle,
                            })
                            }
                        >
                            SEE HOW REVIEW DISPUTES WORK
                        </ModalHeader>
                        <ModalBody className="p-0 bg-transparent">
                            <div className="text-center">
                            <video controls autoPlay width="100%">
                                <source
                                src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                                type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                            </div>
                        </ModalBody>
                    </Modal>

                 {/* SEE HOW REVIEW DISPUTES WORK */}
                <Modal
                    isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
                    toggle={() =>
                        this.setState({
                        watchHowDisputeReviewWorksModalToggle: !this.state
                            .watchHowDisputeReviewWorksModalToggle,
                        })
                    }
                    >
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() =>
                        this.setState({
                            watchHowDisputeReviewWorksModalToggle: !this.state
                            .watchHowDisputeReviewWorksModalToggle,
                        })
                        }
                    >
                        SEE HOW REVIEW DISPUTES WORK
                    </ModalHeader>
                    <ModalBody className="p-0 bg-transparent">
                        <div className="text-center">
                        <video controls autoPlay width="100%" seekable>
                            <source
                            src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                            type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                        </div>
                    </ModalBody>
                </Modal>
                
                {/* Confirmation Modal */}
                <Modal size="sm" 
                    isOpen={confirmationToggle} 
                    className="text-center" 
                    modalClassName="backdrop-opaque top-shifted transition-none">
                    <div className="bg-white modal-header">
                        <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
                        <button
                        className="btn btn-sm"
                        aria-label="Close"
                        
                        >
                        <span aria-hidden="true" onClick={()=> this.setState({confirmationToggle: false})} >×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark">
                        {confirmationReason === 'delete_dispute'
                        ?
                        'Do you want to remove your Dispute?'
                        :
                        confirmationReason === 'delete_dispute_reply' ?
                            'Do you want to remove your Reply?'
                        :
                            ''
                        }
                    </ModalBody>
                    <ModalFooter className="bg-white">
                    <div className="text-center w-100">
                    <Button
                        color="primary"
                        size="sm"
                        className="btn-confirm"
                        onClick={()=> this.setState({confirmationToggle: false})}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        size="sm"
                        className="btn-confirm"
                        onClick={()=>this.handleDelete()}
                        
                    >
                        Yes
                    </Button>
                    </div>
                </ModalFooter>
            </Modal>
                  
            </div>
        )
    }
}

const mapState = (state) => ({
    my_profile: state.user.my_profile,
    administrative_disputed_review: state.dispute.administrative_disputed_review,
    get_review_data: state.dispute.get_review_data,
    get_dispute_modal_status: state.dispute.get_dispute_modal_status,
});

const mapProps = (dispatch) => ({
    get_admin_dispute_discussion: (params) => dispatch(get_admin_dispute_discussion(params)),
    get_review_details: (params) => dispatch(get_review_details(params)),
    dispute_modal_status: (params) => dispatch(dispute_modal_status(params)), 
})


export default connect(mapState, mapProps)(AdminRequestedDispute);