import { callApi } from "../utils/apiCaller";
import { getQueryString } from "../utils/validation";
import { toast } from "react-toastify";

export const FETCH_PIN_DETAILS = "FETCH_PIN_DETAILS";
export const FETCH_LATEST_TWO_REVIEWS = "FETCH_LATEST_TWO_REVIEWS";
export const PIN_STATUS = "PIN_STATUS";

export const fetch_pin_details_ = (data) => ({ type: FETCH_PIN_DETAILS, payload: data });
export const fetch_pin_details = (...data) => {
    return (dispatch) => {
        callApi(getQueryString({ url: `/api/pindetails/`, data }), "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(fetch_pin_details_(response));
            }
        });
    };
};

export const pin_status_ = (data) => ({ type: PIN_STATUS, payload: data });
export const set_pin_details = ({ type, pinid, entry_type, selectedTab,message }) => {
    return (dispatch) => {
        callApi(`/api/pinselection/?type=${type}&pinid=${pinid}&entry_type=${entry_type}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
				toast(message, {
					autoClose: 6500,
					className: "black-background",
					bodyClassName: "red-hunt",
					progressClassName: "cc",
				  });
                  dispatch(pin_status_(response));
                if (selectedTab === "reviewTab") {
                    return dispatch(fetch_pin_details({ type: 'review' }))
                } else {
                    if (selectedTab !== "all") {
                        return dispatch(fetch_pin_details({ pin_type: `${selectedTab}%20list` }))
                    } else {
                        return dispatch(fetch_pin_details({ page: 1 }));
                    }
                } 
            }
        });
    };
};

export const fetch_latest_two_reviews_ = (data) => ({ type: FETCH_LATEST_TWO_REVIEWS, payload: data });
export const fetch_latest_two_reviews = (...data) => {
    return (dispatch) => {
        callApi(getQueryString({ url: `/api/getlatesttworeview/`, data }), "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(fetch_latest_two_reviews_(response));
            }
        });
    };
}


//  https://wikireviews.com/api/pinselection/?type=delete&pinid=6277&entry_type=Listing

// /getlatesttworeview/?userentryid=21196067&entry_type=Listing

// api/pinselection/?type=top&pinid=6278&entry_type=Listing
// api/pinselection/?type=bottom&pinid=6278&entry_type=Listing


// pin_type=watch%20list
// pin_type=want%20list
// pin_type=recommend%20list


// Request URL: https://wikireviews.com/api/getlatesttworeview/?userentryid=24180742&entry_type=Listing


// pin_type=favourite%20list

// api/pindetails/?type=review

// Request URL: https://wikireviews.com/api/getlatesttworeview/?userentryid=8335936&entry_type=Listing
