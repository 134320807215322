const initialState = {
    tab_list_data: null,
    tab_scroll_data: null,
    recent_activity_data: null,
    get_my_contribution: null
};

const homeTabs = (state = initialState, action) => {

    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case "GET_TAB_LIST":
            state.tab_list_data = action.payload;
            return {
                ...state
            };

        case "GET_TAB_SCROLL":
            state.tab_scroll_data = action.payload;
            return {
                ...state
            };
        case "GET_RECENT_ACTIVITY":
            state.recent_activity_data = action.payload;
            return {
                ...state
            };

        case "GET_MY_CONTRIBUTION":
            state.get_my_contribution = action.payload;
            return {
                ...state
            };

        default:
            return state;
    }
}

export default homeTabs;