import React, { useEffect } from 'react'
import { Table, Pagination, PaginationItem, PaginationLink, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { useTable, usePagination, useGlobalFilter, HeaderGroup  } from 'react-table';
import { useState } from 'react';

const DataTable = ({ columns, data, fetchData, pageCount: controlledPageCount, loading, currentPage, handleSearch, tab}) => {

    
    const [pageCount, setPageCount] = useState(0);
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { pageIndex, pageSize },
        setGlobalFilter
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 },
        manualPagination: true,
        pageCount: controlledPageCount,
        manualGlobalFilter: true,
    },
        useGlobalFilter,
        usePagination);

    const pageRange = 6;
    const startPageIndex = Math.max(0, currentPage - Math.floor(pageRange / 2));
    const endPageIndex = Math.min(pageCount - 1, startPageIndex + pageRange - 1);

    useEffect(()=>{
        setPageCount(controlledPageCount);
    },[controlledPageCount])

    const handlePageChange = (page) => {
        fetchData({ page });
    };

    const handleSearchChange = (e) => {
        
        handleSearch(e.target.value);
    };


    const Loader = () => (
        <tr>
          <td colSpan={columns.length} className="loader">
            Loading...
          </td>
        </tr>
      );
    const NoRecord =()=>(
        <tr>
          <td colSpan={columns.length} className="loader">
            0 Records found!
          </td>
        </tr>
    )
    return (
        <>
            {
                handleSearch && <div className="search-container">
                    <Input disabled={loading} placeholder={`Search ${tab}`} onChange={(e) => handleSearchChange(e)} />
                    {/* <Button>Search</Button> */}
                </div>
            }
            <Table striped bordered responsive hover size="sm" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                if (column.columns) {
                                    return (
                                        <th
                                            colSpan={column.columns.length}
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    );
                                }
                                return (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                );
                            }

                            )}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {loading ? <Loader />
                        : !data.length ? <NoRecord/> :rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Table>
            <Pagination>
                <PaginationItem disabled={currentPage === 0 || loading}>
                    <PaginationLink onClick={() => handlePageChange(1)}>First</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={currentPage === 0 || loading}>
                    <PaginationLink onClick={() => handlePageChange(currentPage)}>Previous</PaginationLink>
                </PaginationItem>
                {Array.from({ length: endPageIndex - startPageIndex + 1 }, (_, i) => startPageIndex + i).map(i => (
                    <PaginationItem active={i === currentPage} key={i} disabled={loading}>
                        <PaginationLink onClick={() => handlePageChange(i + 1)}>{i + 1}</PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === pageCount - 1 || !data.length || loading}>
                    <PaginationLink onClick={() => handlePageChange(currentPage + 2)}>Next</PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={currentPage === pageCount - 1 || !data.length || loading}>
                    <PaginationLink onClick={() => handlePageChange(pageCount)}>Last</PaginationLink>
                </PaginationItem>
            </Pagination>
        </>
        
    )
}

export default DataTable;