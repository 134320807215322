import React from 'react'
import { Chart } from "react-google-charts";


const RatingLineChart = ({graphType,data,loading,bgLight}) => {

    const chartColor = bgLight ? '#d1bb78' : '#879ab5'
    const axisStyle = {
        fontName: 'Arial',
        fontSize: '10',
        color: bgLight ? '#69510c' : "white"
    }
    const optionsLine = {
        axesDefaults:
        {
            useSeriesColor: true,
            min: 1,
            tickInterval: 1,
            tickOptions: {
                formatString: '%d'
            }
        },
        'width': '100%',
        'height': 150,
        chartArea: {
            top: 10, width: '70%', height: '80%', backgroundColor: {
                fill: "transparent"
            },
        },
        hAxis: {
            gridlines: { color: 'transparent' },
            textStyle: axisStyle,
            viewWindow: {
                min: 0
            },
        },
        vAxis: {
            titleTextStyle: { color: chartColor },
            ticks: [{ v: 0, f: '0 Star' }, { v: 1, f: '1 Star' }, { v: 2, f: '2 Stars' }, { v: 3, f: '3 Stars' }, { v: 4, f: '4 Stars' }, { v: 5, f: '5 Stars' }],
            gridlines: { color: 'transparent' },
            baselineColor: chartColor,
            textStyle: axisStyle
        },
        legend: { position: "none" },
        isStacked: true,
        annotation: {
            fontsize: 2
        },
        colors: [chartColor],
        backgroundColor: 'transparent',
    
    };

    


    return (
        <>
            <hr className="bg-light mt-3" />
                    <div className="font-weight-bold mb-2">
                        <span className={bgLight ? "text-secondary-dark" : "text-tertiary-darker"}>{graphType} </span>
                        {'Trend'}
                    </div>                                
                    {loading ? <span>Loading</span> : <>{data[graphType] ? (
                        <Chart
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={data[graphType]}
                            options={optionsLine}
                            graphID="LineChart"
                            chartEvents={[
                                {
                                    eventName: "select",
                                    callback({ chartWrapper }) {
                                    }
                                }
                            ]}
                        />
                    ) : <span>No data</span>}</>}
        </>
    )
}

export default RatingLineChart;




