import { callApi, responseCodeValidator } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { getQueryString, } from '../utils/validation';
import { get_type_detail, setItemDetailLoading } from "./itemDetail";

export const GET_ACTORS_LIST = "GET_ACTORS_LIST";
export const SEARCH_ACTOR = "SEARCH_ACTOR";
export const ADD_ACTOR_AS_FAV = "ADD_ACTOR_AS_FAV";
export const FETCH_ACTORS_LIST = "FETCH_ACTORS_LIST";
export const GET_REVIEW_LISTING_ACTORS = "GET_REVIEW_LISTING_ACTORS";
export const RESET_REVIEW_LISTING_ACTORS = "RESET_REVIEW_LISTING_ACTORS";

export const SET_ACTOR_LOADER = "SET_ACTOR_LOADER";

let toastId = undefined

const common_fetch = ({ url, method, data, dispatch_function }) => {
  if (data === undefined) {
    data = {};
  }
  return (dispatch) => {
    dispatch(set_actor_loading(true));
    callApi(url, method, data).then((response) => {
      dispatch(set_actor_loading(false));
      if (response && response.code !== 400 && response.code !== 500) {
        dispatch(dispatch_function(response));
      }
    });
  };
};

export const get_actors_list_ = (data) => {
  return {
    type: GET_ACTORS_LIST,
    payload: data,
  };
};


export const get_actors_list = (payload = {}) => {
  if (payload && payload.page) {
    return async (dispatch) => {
      dispatch(set_actor_loading(true));
      return callApi(`/api/getFavoriteActor/?page=${payload.page}`, "GET").then((response) => {
        dispatch(set_actor_loading(false));
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(get_actors_list_(response));
        }
      });
    };
  }
  else {
    return async (dispatch) => {
      dispatch(set_actor_loading(true));
      return callApi(`/api/getFavoriteActor/`, "GET").then((response) => {
        dispatch(set_actor_loading(false));
        if (response && response.code !== 400 && response.code !== 500) {
          return dispatch(get_actors_list_(response));
        }
      });
    };
  }
};

export const search_actor_ = (data) => {
  return {
    type: SEARCH_ACTOR,
    payload: data,
  };
};


export const search_actor = (payload) => {
  return (dispatch) => {
    dispatch(set_actor_loading(true));
    const url = payload?.page ? `/api/getFavoriteActor/?actor_name=${payload.search_name}&page=${payload.page}` : `/api/getFavoriteActor/?actor_name=${payload.search_name}`;
    callApi(url, "GET").then((response) => {
      dispatch(set_actor_loading(false));

      if (response && responseCodeValidator(String(response.code))) {
        return dispatch(search_actor_(response));
      }
    });
  };
};

export const add_actor_as_fav_ = (data) => {
  return {
    type: ADD_ACTOR_AS_FAV,
    payload: data,
  };
};


export const add_actor_as_fav = (payload) => {
  let data = { id: payload.id }
  if (toastId)
    toast.dismiss(toastId);
  return async (dispatch) => {
    return callApi(`/api/getFavoriteActor/`, "POST", data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        let message = `You added ${response.name} to your favorites.`;
        if (response.msg && response.msg !== "Saved successfully") {
          message = response.msg;
        }
        toastId = toast(message, {
          autoClose: 2500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(add_actor_as_fav_(response.results));
      }
    });
  };
};

export const fetch_actors_list_dispatch = (data) => ({ type: FETCH_ACTORS_LIST, payload: data, });
export const fetch_actors_list = (...data) => (
  common_fetch({
    url: getQueryString({ url: `/api/professionaltrends/`, data }),
    method: "GET",
    dispatch_function: fetch_actors_list_dispatch
  })
);

export const get_reviews_listing_ = (data) => ({ type: GET_REVIEW_LISTING_ACTORS, payload: data });
export const get_reviews_listing = (id, type, searchValue) => {
  if (!type) { type = 'new' }

  let url = `/api/professionalreviews/?associated_to__id=${id}&searchTerm=${type}`;
  if(searchValue) url = `/api/professionalreviews/?associated_to__id=${id}&page=1&search=${searchValue}`;

  return (dispatch) => {
    if(searchValue || searchValue==="") dispatch(get_reviews_listing_([]));
    
    callApi(url, "GET").then((response) => {
      if (response && response?.code !== 400 && response?.code !== 500) {
        dispatch(setItemDetailLoading(false));
        dispatch(get_reviews_listing_(response));
      }
    });
  };
};


export const reset_reviews_listing_actors = () => {
  return (dispatch) => {
    dispatch({ type: RESET_REVIEW_LISTING_ACTORS, payload: {} });
  }
}

export const delete_review = (id, item_id, name, type) => {
  return (dispatch) => {
    callApi(`/api/professionalreviews/${id}/`, "DELETE").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        toast(`Your review of ${name} has been deleted.`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        dispatch(get_type_detail({ type, id: item_id }))
        return dispatch(get_reviews_listing(item_id));
      }
    });
  };
};

export const set_actor_loading = (data) => {
  return {
    type: SET_ACTOR_LOADER,
    payload: data,
  };
};
