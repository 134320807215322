import React, { Component } from 'react'
import {Container, Input} from 'reactstrap';
  
 class Hometheater extends Component {
    render() {
      const {Hometheater} = this.props
        return (
            <div>
                <Container>
                <div className="wr-home-theature-left">
                  <h2>Regal Edwards Market Place</h2>
                  <p className="fs-14">13782 Jamboree Road Irvine, CA 92602</p>
                  <div className="text-right">
                    <Input className="primary" bsSize="sm" type="select">
                      <option value="7">Movies Screening Today</option>
                      <option value="0">Monday</option>
                      <option value="1">Tuesday</option>
                      <option value="2">Wednesday</option>
                      <option value="3">Thursday</option>
                      <option value="4">Friday</option>
                      <option value="5">Saturday</option>
                      <option value="6">Sunday</option>
                    </Input>
                  </div>
                  <h2>NO DATA AVAILABLE</h2>
                </div>
              </Container>
            </div>
        )
    }
}
export default Hometheater;