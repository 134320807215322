/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Form, Input, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CollapseBasic from '../../atoms/collapse';
import { get_favorite_list, search_favorite, add_favorite, clear_search_favorite, delete_favorite, get_favorite_professionpins } from "../../../actions/favorite";
import data from './favorite';
import DeleteBtn from "../../atoms/deleteBtn";
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

class MyFavorites extends Component {
    constructor(props) {
        super(props)
        this.state = {
            favoriteList: [],
            getFavoriteActor: [],
            pageCount: 0,
            addFavoriteToggle: false,
            favorite_name: "",
            errors: {},
            deleteFavoriteId: 0,
            confirmDeleteModal: false,
            searchFavoriteData: [],
            deleteFavoriteName: "",
            selectedFavoriteId: 0,
            favType: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleOnDeleteConfirmation = this.handleOnDeleteConfirmation.bind(this);
        this.favoritesRef = React.createRef();
    }
    componentWillReceiveProps(nextProps) {
        // get_favorite_list
        if (
            nextProps.favorite_list &&
            Array.isArray(nextProps.favorite_list.results) &&
            nextProps.favorite_list.results.length > 0
        ) {

            this.setState({
                favoriteList: nextProps.favorite_list.results,
                pageCount: nextProps.favorite_list.count,
            });

        }
        else {
            if (
                data.results &&
                Array.isArray(data.results) &&
                data.results.length > 0
            ) {
                this.setState({
                    favoriteList: data.results,
                    pageCount: data.count,
                });
            }
        }

        if (nextProps.search_favorite_info &&
            Array.isArray(nextProps.search_favorite_info.results) && nextProps.search_favorite_info.results.length > 0) {
            this.setState({ searchFavoriteData: nextProps.search_favorite_info.results });
        }

        if (nextProps.favorite_professional_list &&
            Array.isArray(nextProps.favorite_professional_list.results)) {
            this.setState({ getFavoriteActor: nextProps.favorite_professional_list.results });
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id)
            this.props.get_favorite_list("favourite", this.props.id)

        if ((this.props.subMainViewType !== prevProps.subMainViewType) && this.props.subMainViewType === 'favorites') {
            this.favoritesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    componentDidMount() {
        const { id } = this.props
        let type = "favourite";
        this.props.get_favorite_list(type, id);
        this.props.get_favorite_professionpins(type);
    }

    // Open add favorite field
    handleAddToggle = () => {
        this.setState({
            addFavoriteToggle: true,
            favorite_name: "",
            errors: {},
        });
    }

    // Close add favorite field	
    hideAddCollapse = () => {
        this.setState({
            addFavoriteToggle: false,
            favorite_name: "",
            errors: {},
        });
    }

    debounceSearchFavorite =  debounce(value=> {
        this.props.search_favorite(value);
    }, 500)

    debounceResetFavorite =  debounce(type=> {
        this.props.clear_search_favorite();
        this.props.get_favorite_list(type);
    }, 500)

    // Change State of input field	
    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let type = "favourite";

        this.setState({
            ...this.state,
            [name]: value,
        });
        if (name === 'favorite_name') {
            if (value.length) {
                this.debounceSearchFavorite(value);
            } else {
                this.setState({ searchFavoriteData: [], favorite_name: '' }, () => {
                    this.debounceResetFavorite(type);
                });
            }
        }
    };

    // add Favorite
    handleAddFavorite = () => {
        let errors = { ...this.state.errors };

        if (this.state.favorite_name === '') {
            errors.favorite_name = 'This field is required.';
        } else {
            delete errors.favorite_name;
        }


        if (Object.keys(errors).length > 0) {
            this.setState(prevState => ({
                ...prevState,
                errors: errors
            }));
        } else {
            let data = {
                user_entry: this.state.favorite_name,
                type: "favourite list",
                user_entry_digit: this.state.selectedFavoriteId
            };
            let type = "favourite";
            this.props.add_favorite(data, type);
            this.setState({ searchFavoriteData: [], favorite_name: '' }, () => {
                this.props.clear_search_favorite();
                this.props.get_favorite_list(type);
            });
        }
    }

    confirmDeleteModalToggle = (type) => {
        if (this.state.deleteFavoriteId) {
            this.setState({ deleteFavoriteId: 0, deleteFavoriteName: "" });
        }
        this.setState({ favType: type, confirmDeleteModal: !this.state.confirmDeleteModal });

    };

    /**
    * Function to Delete Favorite
    * @param event
    */
    handleOnDeleteConfirmation = () => {
        if (this.state.deleteFavoriteId) {
            let type = "favourite list";
            let favoritType = "favourite";
            this.props.delete_favorite(this.state.deleteFavoriteId, type, favoritType, this.state.deleteFavoriteName, this.state.favType === 'Actors' ? true : false);
            this.setState({
                confirmDeleteModal: !this.state.confirmDeleteModal,
                deleteFavoriteId: 0,
                deleteFavoriteName: "",
            });
        }
    };

    handleSelect = (e, id, name) => {
        e.preventDefault();
        this.setState({ favorite_name: name, selectedFavoriteId: id, searchFavoriteData: [] });

    }

    favoriteList = (type) => {
        let favoriteList = this.state.favoriteList;
        if (favoriteList && favoriteList.length > 0) {
            return favoriteList.map((item, index) => {

                if (item?.user_entry?.content_type?.category === type) {
                    return (
                        <li className="mb-3" key={index}>
                            <div className="d-flex flex-nowrap mx-n1">
                                <div className="px-1 mt-1 mb-2 order-1 order-sm-1 order-lg-1">
                                    <div className="d-flex mx-n1">
                                        <div className="px-1">
                                            <Link
                                                to={{
                                                    pathname: `/${(item?.user_entry?.content_type?.category).toLowerCase()}/${item.user_entry?.slug ? item.user_entry.slug : item?.user_entry?.id ? item.user_entry.id : '' }`,
                                                    state: { id: item?.user_entry?.id }
                                                }}
                                                className='text-dark text-decoration-none fs-16 ff-base font-weight-bold'
                                            >
                                                <img src={item.profile_image && item.profile_image.length > 0
                                                    ? item.profile_image[0].location
                                                    : require('../../../assets/images/icons/placeholder-img.png')
                                                } alt="" width={50} height={50} style={{ objectFit: 'cover', objectPosition: 'center center' }} />
                                            </Link>
                                        </div>

                                        <div className="px-1">
                                            <div className="font-weight-bold fs-14 ff-base">
                                                <Link
                                                    to={{
                                                        pathname: `/${(item?.user_entry?.content_type?.category).toLowerCase()}/${item.user_entry?.slug ? item.user_entry.slug : item?.user_entry?.id ? item.user_entry.id : '' }`,
                                                        state: { id: item?.user_entry?.id }
                                                    }}
                                                    className='text-dark text-decoration-none fs-16 ff-base font-weight-bold'
                                                >
                                                    <span>{item.user_entry.name}</span></Link>
                                            </div>
                                            <div>
                                                <img className="mr-2" src={require(`../../../assets/images/icons/star/gold/${item?.ratings && item?.ratings?.avg_rating[0] ? item?.ratings?.avg_rating[0] : '0rating.png'}`)}
                                                    alt={item?.ratings && item?.ratings?.avg_rating[1] ? item?.ratings?.avg_rating[1] : '0.0'}
                                                    title={item?.ratings && item?.ratings?.avg_rating[1] ? item?.ratings?.avg_rating[1] : '0.0'} />

                                            </div>
                                            <div className="fs-14 ff-base">
                                                {item.taxonomy_list != null ? item.taxonomy_list.join(", ") : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-1 col-auto ml-auto order-2 order-sm-3 order-lg-2">
                                    <DeleteBtn
                                        color="gold"
                                        onClick={() => {
                                            this.setState({
                                                deleteFavoriteId: item.user_entry.id,
                                                deleteFavoriteName: item.user_entry.name,
                                            });
                                            this.confirmDeleteModalToggle(type);
                                        }}
                                    />
                                </div>
                            </div>
                            <hr className="mb-0 mt-1" />
                        </li>



                    )
                }
            })
        }
    }

    favoriteActorList = (type) => {
        let getFavoriteActor = this.state.getFavoriteActor;
        if (getFavoriteActor && getFavoriteActor.length > 0) {
            return getFavoriteActor.map((item, index) => {

                if (item?.target?.category === type) {
                    return (
                        <li className="mb-3" key={index}>
                            <div className="d-flex flex-nowrap mx-n1">
                                <div className="px-1 mt-1 mb-2 order-1 order-sm-1 order-lg-1">
                                    <div className="d-flex mx-n1">
                                        <div className="px-1">
                                            <Link
                                                to={{
                                                    pathname: `/${(item?.target?.category).toLowerCase()}/${item.target.slug ? item.target.slug : item?.target?.id ? item.target.id : ''}`,
                                                    state: { id: item?.target?.id }
                                                }}
                                                className='text-dark text-decoration-none fs-16 ff-base font-weight-bold'
                                            >
                                                <img
                                                    src={item.target.profile_image && item.target.profile_image.length > 0
                                                        ? item.target.profile_image[0].location
                                                        : require('../../../assets/images/icons/placeholder-img.png')
                                                    }
                                                    alt=""
                                                    width={50}
                                                    height={50}
                                                    style={{ objectFit: 'cover', objectPosition: 'center center' }} />
                                            </Link>
                                        </div>

                                        <div className="px-1">
                                            <div className="font-weight-bold fs-14 ff-base">
                                                <Link
                                                    to={{
                                                        pathname: `/${(item?.target?.category).toLowerCase()}/${item.target.slug ? item.target.slug : item?.target?.id ? item.target.id : ''}`,
                                                        state: { id: item?.target?.id }
                                                    }}
                                                    className='text-dark text-decoration-none fs-16 ff-base font-weight-bold'
                                                > <span>{item.target.name}</span></Link>
                                            </div>
                                            <div>
                                                <img className="mr-2" src={require(`../../../assets/images/icons/star/gold/${item?.rating_set && item?.rating_set?.avg_rating[0] ? item?.rating_set?.avg_rating[0] : '0rating.png'}`)}
                                                    alt={item?.rating_set && item?.rating_set?.avg_rating[1] ? item?.rating_set?.avg_rating[1] : '0.0'}
                                                    title={item?.rating_set && item?.rating_set?.avg_rating[1] ? item?.rating_set?.avg_rating[1] : '0.0'} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-1 col-auto ml-auto order-2 order-sm-3 order-lg-2">
                                    <DeleteBtn
                                        color="gold"
                                        onClick={() => {
                                            this.setState({
                                                deleteFavoriteId: item.target.id,
                                                deleteFavoriteName: item.target.name,
                                            });
                                            this.confirmDeleteModalToggle(type);
                                        }}
                                    />
                                </div>
                            </div>
                            <hr className="mb-0 mt-1" />
                        </li>



                    )
                }
            })
        }
    }

    getDelMessage = () => {
        let type = this.state.favType;
        if (type === 'Business') {
            return "Are you sure want to remove this Business from your favorites?"
        }
        else if (type === 'Movies') {
            return "Are you sure want to remove this Movie from your favorites?"
        }
        else if (type === 'Products') {
            return "Are you sure want to remove this Product from your favorites?"
        }
        else if (type === 'Actors') {
            return "Are you sure want to remove this Actor from your favorites?"
        }
        else {
            return "Are you sure want to remove this from your favorites?"
        }
    }



    render() {
        const {
            addFavoriteToggle,
            favorite_name,
            errors,
            searchFavoriteData,
        } = this.state

        const { heading, showAddNewfavourite = true, } = this.props

        return (
            <div id="favorites" className="type-target" ref={this.favoritesRef}>
                <h2 className="text-secondary">{heading ? heading + ' favorites' : 'My favorites'}</h2>
                <hr className="bg-secondary" />
                <CollapseBasic title="Movies" containerClass="bg-transparent title-type-sm expanded-dark" isOpen={false} size="sm">
                    <ul className="list-unstyled">
                        {this.favoriteList("Movies")}
                    </ul>
                </CollapseBasic>
                <CollapseBasic title="Products" containerClass="bg-transparent title-type-sm expanded-dark" isOpen={false} size="sm">
                    <ul className="list-unstyled">
                        {this.favoriteList("Products")}

                    </ul>
                </CollapseBasic>
                <CollapseBasic title="Businesses" containerClass="bg-transparent title-type-sm expanded-dark" isOpen={false} size="sm">
                    <ul className="list-unstyled">
                        {this.favoriteList("Business")}
                    </ul>
                </CollapseBasic>
                <CollapseBasic title="Actors" containerClass="bg-transparent title-type-sm expanded-dark" isOpen={false} size="sm">
                    <ul className="list-unstyled">
                        {this.favoriteActorList("Actors")}
                    </ul>
                </CollapseBasic>

                {
                    showAddNewfavourite &&
                    <Button color="link" className="text-secondary-dark font-weight-bold p-0 hover-blue _not-icon" onClick={this.handleAddToggle}>
                        <FontAwesomeIcon icon={['far', 'heart']} className="mr-2" />
                    add new favorite</Button>
                }


                {/* Add new favorite box */}
                <div className="shadow p-3 mt-2" hidden={!addFavoriteToggle}>
                    <Form>
                        <h5 className="text-secondary-dark fs-30 mb-3">
                            Add New Favorite
                        </h5>
                        <div className="mb-2 position-relative" >
                            <Input
                                bsSize="sm"
                                type="text"
                                name="favorite_name"
                                placeholder="Add Movies/Business/Products"
                                value={favorite_name}
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                onChange={this.handleChange}
                            />
                            {errors && errors.favorite_name && (
                                <div className="text-danger small mt-1">
                                    {errors.favorite_name}
                                </div>
                            )}
                            {/* Instant Search Results */}
                            <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                {Array.isArray(searchFavoriteData) && searchFavoriteData && searchFavoriteData.length > 0 ? (searchFavoriteData.map((item, index) => (
                                    <li className="result-item" role="button" onClick={(e) => this.handleSelect(e, item._id, item._source.name)}>
                                        <div className="result-link fs-14">
                                            <div className="d-flex">
                                                <div className="mr-2">
                                                    <img src={item._source.listing_profileimage && item._source.listing_profileimage.length > 0 ? item._source.listing_profileimage[0].location : require('../../../assets/images/icons/placeholder-img.png')
                                                    } width={40} alt="" />
                                                </div>
                                                <div>
                                                    <span>{item._source ? item._source.name : ""}</span>
                                                    <div>
                                                        <img src={require('../../../assets/images/icons/norating-alt.png')} alt="" />
                                                    </div>
                                                    <div>
                                                        <span>{item._source.category != null ? item._source.category : ""}</span>
                                                    </div>
                                                    <div className="text-muted">
                                                        <span>{item._source.address && item._source.address.length > 0 ? item._source.address[0].address1 + ',' + item._source.address[0].state + ',' + item._source.address[0].zipcode
                                                            : ""
                                                        }</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                                ) : null}
                            </ul>
                        </div>

                        <div className="mb-2">
                            <div className="text-right">
                                <Button color="primary" size="sm" className="mw mr-2" onClick={this.handleAddFavorite}>
                                    <span className="text">Submit</span>
                                </Button>
                                <Button size="sm" color="primary" className="mw ml-0" type="button" onClick={() => this.hideAddCollapse()}>
                                    <span className="text">Cancel</span>
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
                {/* Delete Confirmation Modal */}
                <Modal
                    size="sm"
                    isOpen={this.state.confirmDeleteModal}
                    toggle={this.confirmDeleteModalToggle}
                    className="text-center" modalClassName="backdrop-opaque top-shifted transition-none"
                >
                    <div className="bg-white modal-header">
                        <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
                        <button
                            className="btn btn-sm"
                            aria-label="Close"
                            onClick={this.confirmDeleteModalToggle}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark">
                        {this.getDelMessage()}

                    </ModalBody>
                    <ModalFooter className="bg-white">
                        <div className="text-center w-100">
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={this.confirmDeleteModalToggle}
                            >
                                Cancel
              </Button>
                            <Button
                                color="primary"
                                size="sm"
                                className="btn-confirm"
                                onClick={this.handleOnDeleteConfirmation}
                            >
                                Yes
              </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapState = (state) => ({
    favorite_list: state.favorite.favorite_list,
    search_favorite_info: state.favorite.search_favorite_info,
    favorite_professional_list: state.favorite.favorite_professional_list,
});

const mapProps = (dispatch) => ({
    get_favorite_list: (type, id) => dispatch(get_favorite_list(type, id)),
    get_favorite_professionpins: (type) => dispatch(get_favorite_professionpins(type)),
    search_favorite: (query) => dispatch(search_favorite(query)),
    add_favorite: (data, favoritType) => dispatch(add_favorite(data, favoritType)),
    delete_favorite: (favoriteId, type, favoritType, name, professional) => dispatch(delete_favorite(favoriteId, type, favoritType, name, professional)),
    clear_search_favorite: () => dispatch(clear_search_favorite()),

});
export default connect(mapState, mapProps)(MyFavorites);
//export default withRouter(MyFavorites);
