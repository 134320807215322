/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { FormGroup, Button, Input, Label, } from 'reactstrap';
import CollapseBasic from '../../../component/atoms/collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditBtn from '../../atoms/editBtn';
import DeleteBtn from '../../atoms/deleteBtn';

export default class TipsAndWarning extends Component {
    
    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile} = this.props;
        if(my_profile && my_profile.id && authId && (authId == my_profile.id)){
            return true;
        }else{
            return false;
        }
    }

    render() {
        const { tipsAndWarnings, stateSet, handleSubmitTipsWarning, isToggleTips, isToggleWarning, tipsText, warningText, editId } = this.props;

        return (
            <div className="bg-white-shaded-md text-secondary p-3 mb-3">
                <h2 className="mr-2">Tips & Warnings</h2>
                <hr className="bg-white" />
                <div className="mb-3">
                    <p className="ff-base text-body lh-1-3">{'Do you have any tips to offer for current or prospective owners? Any warnings concerning purchase or ownership of this product? List away!'}</p>
                </div>

                <CollapseBasic title="Tips" noHr={true} containerClass="bg-transparent type-secondary title-type-sm expanded-dark" bodyClass="mt-3 text-secondary" isOpen={true} size="sm" noPadding={true}>
                    <ul className="list-unstyled">
                        {tipsAndWarnings && Array.isArray(tipsAndWarnings.results) && tipsAndWarnings?.results?.length ?
                            tipsAndWarnings?.results.filter(it => it.type == 'tips').map((item, index) => (
                                <li className="mb-2">
                                    <div className="border border-primary p-2">
                                        <div className="mb-2">
                                            <div className="text-secondary-dark wb-break-word">
                                                <span className="text-secondary">{index + 1}.</span> {item?.text}
                                            </div>
                                        </div>
                                        <div className="d-flex mx-n1">
                                            <div className="px-1">
                                                <img className="img-circle _30x30" src={item?.profile_pic} alt="" onError={(error) =>
                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                } />
                                            </div>
                                            <div className="px-1 col">
                                                <span className="text-secondary-dark">by</span> <a href="#" className="font-weight-bold">{item?.username}</a>
                                                <br /> {item?.created_on}
                                            </div>
                                            <div className="px-1" hidden={!this.checkIfLoggedIn()}>
                                                <EditBtn color="light" onClick={() => { stateSet({ tipsText: item?.text, editId: item.id, isToggleTips: true }) }} />
                                                <DeleteBtn color="light" onClick={() => { stateSet({ isToggleModalTrue: true, modalText: 'Do you want to delete the tip ?', deleteItem: item.id }) }} />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                            : null}

                        <li className="mb-2" hidden>
                            <div>
                                <div className="mb-2">
                                    <div className="text-secondary-dark font-weight-medium wb-break-word">
                                        <span className="text-brown">2.</span> Test Tip for business by another user
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div className="text-right mb-2">
                        <Button
                            onClick={() => { 
                                if(!this.checkIfLoggedIn()){
                                    this.props.forceToggleLoginModal();
                                    return false;
                                }
                                stateSet({ tipsText: '', isToggleTips: true, editId: null }) }}
                            color="link"
                            className="p-0 text-secondary-dark font-weight-bold">
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                            {' Add another'}
                        </Button>
                    </div>

                    {isToggleTips && (
                        <div className="bg-white shadow-sm p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">{editId ? 'Edit Your Tips' : 'Add another'}</h5>
                            <FormGroup>
                                <Label className="text-secondary font-weight-bold">Enter Content <span className="text-danger">*</span></Label>
                                <Input onChange={(e) => { stateSet({ tipsText: e.target.value }) }} value={tipsText} className="primary glowy-form-control" bsSize="sm" type="textarea" placeholder="Please add your tip..." />
                            </FormGroup>

                            <div className="text-right mt-3">
                                <Button onClick={() => { handleSubmitTipsWarning({ text: tipsText, type: 'tips' }) }} disabled={tipsText.length ? false : true} className="mw" color="primary" size="sm">Submit</Button>
                                <Button onClick={() => { stateSet({ tipsText: '', isToggleTips: false, editId: null }) }} className="mw" color="primary" size="sm">Cancel</Button>
                            </div>
                        </div>)}
                </CollapseBasic>

                <hr className="bg-light" />

                <CollapseBasic title="Warnings" noHr={true} containerClass="bg-transparent type-secondary title-type-sm expanded-dark" bodyClass="mt-3 text-secondary" isOpen={true} size="sm" noPadding={true}>
                    <ul className="list-unstyled">
                        {tipsAndWarnings && Array.isArray(tipsAndWarnings.results) && tipsAndWarnings?.results?.length ?
                            tipsAndWarnings?.results.filter(it => it.type == 'warning').map((item, index) => (
                                <li className="mb-2">
                                    <div className="border border-primary p-2">
                                        <div className="mb-2">
                                            <div className="text-secondary-dark wb-break-word">
                                                <span className="text-secondary">{index + 1}.</span> {item?.text}
                                            </div>
                                        </div>
                                        <div className="d-flex mx-n1">
                                            <div className="px-1">
                                                <img className="img-circle _30x30" src={item?.profile_pic} alt="" onError={(error) =>
                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
                                                } />
                                            </div>
                                            <div className="px-1 col">
                                                <span className="text-secondary-dark">by</span> <a href="#" className="font-weight-bold">{item?.username}</a>
                                                <br /> {item?.created_on}
                                            </div>
                                            <div className="px-1">
                                                <EditBtn color="light" onClick={() => { stateSet({ warningText: item?.text, editId: item.id, isToggleWarning: true }) }} />
                                                <DeleteBtn color="light" onClick={() => { stateSet({ isToggleModalTrue: true, modalText: 'Do you want to delete the warning ?', deleteItem: item.id }) }} />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                            : null}

                        <li className="mb-2" hidden>
                            <div>
                                <div className="mb-2">
                                    <div className="text-secondary-dark font-weight-medium wb-break-word">
                                        <span className="text-brown">2.</span> Test warning for business by another user
                                        </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div className="text-right mb-2">
                        <Button
                            onClick={() => { 
                                if(!this.checkIfLoggedIn()){
                                    this.props.forceToggleLoginModal();
                                    return false;
                                }
                                stateSet({ warningText: '', isToggleWarning: true, editId: null }) }}
                            color="link"
                            className="p-0 text-secondary-dark font-weight-bold">
                            <FontAwesomeIcon className="mr-1" size="sm" icon="plus" />
                            {'Add another'}
                        </Button>
                    </div>

                    {isToggleWarning && (
                        <div className="bg-white shadow-sm p-3">
                            <h5 className="text-secondary-dark mb-3 fs-30">{editId ? 'Edit Your Warning' : 'Add another'}</h5>
                            <FormGroup>
                                <Input onChange={(e) => { stateSet({ warningText: e.target.value }) }} value={warningText} className="primary glowy-form-control" bsSize="sm" type="textarea" placeholder="Please add your warning..." />
                            </FormGroup>

                            <div className="text-right mt-3">
                                <Button onClick={() => { handleSubmitTipsWarning({ text: warningText, type: 'warning' }) }} disabled={warningText.length ? false : true} className="mw" color="primary" size="sm">Submit</Button>
                                <Button onClick={() => { stateSet({ warningText: '', isToggleWarning: false, editId: null }) }} className="mw" color="primary" size="sm">Cancel</Button>
                            </div>
                        </div>
                    )}
                </CollapseBasic>
            </div>
        )
    }
}
