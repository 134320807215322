/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import AppHeader from '../oragnisms/homepage/AppHeader/appHeader';
import Footer from '../oragnisms/homepage/footer';
import { connect } from "react-redux";
import { get_locations } from "../../actions/auth";
import {Link} from 'react-router-dom';
class Locations extends Component {

    constructor(props){
        super(props);
        this.state = {
            loader: true
        }
    }

    componentDidMount() {
        this.props.get_locations();
    }

    getUrl = (city) => {
        return city.name.toLowerCase().replace(' ', '-');
    }

    componentWillReceiveProps(nextProps) {
        const { locations } = nextProps;
		
		if((locations && Object.keys(locations).length > 0 && !this.props.locations) || this.props.locations && Object.keys(this.props.locations).length > 0){
            this.setState({
                loader: false
            });
        }
    }

    render() {
        const {locations} = this.props;
        const {loader} = this.state;
		return (
            <div>
                <AppHeader className="colored" />
                <div className="wk-static-wrapper">
                    <Container>
                        <div className="wk-static-sub-head">
                            <h1>More Locations</h1>
                        </div>
                        <Alert color="primary" hidden={!loader}>
                            Loading...
                        </Alert>
                        <div className="wk-static-content-body" hidden={loader}>
                            <Row>
                                <Col lg={6}>
                                    <div className="text-center">
                                        <h3 className="fs-24 text-dark pb-2 mb-3 border-bottom">Unites States</h3>
                                    </div>
                                    <div className="d-flex mx-n2">
                                        <div className="px-2 col">
                                            <ul class="locations-list">
                                                {locations && locations.usa && locations.usa.length > 0 && locations.usa.filter((it, i) => i <= Math.ceil(locations.usa.length / 2)).filter((st, i) => st.city && st.city.length > 0).map((st, i) => {
                                                    return(
                                                        <li class="state" key={i}>
                                                            <div>{st.state}</div>
															<ul class="cities">
                                                                {st.city && st.city.length > 0 && st.city.map((ci, ind) => {
                                                                    return(
                                                                        <li key={ind}><Link to={{
                                                                            pathname: `/${this.getUrl(ci)}`,
                                                                            state: { city: ci.name, country: 'usa', stateName:st.state}
                                                                        }}>{ci.name}</Link></li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="px-2 col">
                                            <ul class="locations-list">
                                            {locations && locations.usa && locations.usa.length > 0 && locations.usa.filter((it, i) => i > Math.ceil(locations.usa.length / 2)).filter((st, i) => st.city && st.city.length > 0).map((st, i) => {
                                                    return(
                                                        <li class="state" key={i}>
                                                            <div>{st.state}</div>
                                                            <ul class="cities">
                                                                {st.city && st.city.length > 0 && st.city.map((ci, ind) => {
                                                                    return(
                                                                        <li key={ind}><Link to={{
                                                                            pathname: `/${this.getUrl(ci)}`,
                                                                            state: { city: ci.name, country: 'usa',stateName:st.state }
                                                                        }}>{ci.name}</Link></li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="text-center">
                                        <h3 className="fs-24 text-dark pb-2 mb-3 border-bottom">Canada</h3>
                                    </div>
                                    <div className="d-flex mx-n2">
                                        <div className="px-2 col">
                                            <ul class="locations-list">
                                            {locations && locations.can && locations.can.length > 0 && locations.can.filter((it, i) => i <= Math.ceil(locations.can.length / 2)).filter((st, i) => st.city && st.city.length > 0).map((st, i) => {
                                                    return(
                                                        <li class="state" key={i}>
                                                            <div>{st.state}</div>
                                                            <ul class="cities">
                                                                {st.city && st.city.length > 0 && st.city.map((ci, ind) => {
                                                                    return(
                                                                        <li key={ind}><Link to={{
                                                                            pathname: `/${this.getUrl(ci)}`,
                                                                            state: { city: ci.name, country: 'can', stateName:st.state }
                                                                        }}>{ci.name}</Link></li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="px-2 col">
                                            <ul class="locations-list">
                                            {locations && locations.can && locations.can.length > 0 && locations.can.filter((it, i) => i > Math.ceil(locations.can.length / 2)).filter((st, i) => st.city && st.city.length > 0).map((st, i) => {
                                                    return(
                                                        <li class="state" key={i}>
                                                            <div>{st.state}</div>
                                                            <ul class="cities">
                                                                {st.city && st.city.length > 0 && st.city.map((ci, ind) => {
                                                                    return(
                                                                        <li key={ind}><Link to={{
                                                                            pathname: `/${this.getUrl(ci)}`,
                                                                            state: { city: ci.name, country: 'can', stateName:st.state }
                                                                        }}>{ci.name}</Link></li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapState = (state) => ({
    locations: state.auth.locations
});

const mapDispatch = (dispatch) => ({
    get_locations: () => dispatch(get_locations()),
});

export default connect(mapState, mapDispatch)(Locations);