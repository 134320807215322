import React, { Component } from "react";
import { Button, FormGroup, Input, Media } from 'reactstrap';
import DeleteBtn from "../deleteBtn";
import EditBtn from "../editBtn";
import { useEmbedly } from "react-contentful-embedly";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { callApi } from "../../../utils/apiCaller";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';


const ComponentNew = (props) => {
    const option = useEmbedly({
        align: "left",
        key: "86995e5d79b242c5b34610989d303d90",
    });
    const documentIncludesEmbedly = {
        nodeType: "document",
        data: {},
        content: [
            {
                nodeType: "paragraph",
                data: {},
                content: [
                    {
                        nodeType: "text",
                        value: `Embedded content: ${props.embedData}`,
                        marks: [],
                        data: {},
                    },
                ],
            },
        ],
    };

    let result = documentToReactComponents(documentIncludesEmbedly, option);

    if (result && Array.isArray(result) && result.length) {
        let mainKey = result[0];
        if (Object.keys(mainKey).length > 1) {
            return <div className="set-item-embed">{result}</div>;
        } else {
            return null;
        }
    }
};

export default class AllComments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            commentId: "",
            commentMessage: "",
            commentReview: "",
            commentMessageAdd: "",
            replyBox: {},
            replyText: {}
        }
    }

    closeComments = () => {
        this.setState({
            commentId: "",
            commentMessage: "",
            commentReview: "",
            commentMessageAdd: "",
            replyBox: {},
            replyText: {}
        });
    };

    updateComments = async () => {
        let { commentId,
            commentMessage,
            commentReview,
        } = this.state;

        let response = await this.props.handleSubmitComment({
            id: commentReview,
            body: commentMessage,
            parent: commentId,
            edit: true
        });

        if (response) {
            this.closeComments();
        }
    };

    updateCommentFunction = (data, id) => {
        this.setState({
            commentId: data.id,
            commentMessage: data.body,
            commentReview: id,
        });
    };

    deleteCommentFunction = (id) => {
        const { uniqueDeleteCommentKey } = this.props;
        this.setState({
            deleteIdComment: id,
            isDeleteCommentModalOpen: true,
        }, () => {
            this.props.toggleConfirmModal({
                text: 'Are you sure you want to delete comment?',
                uniqueKey: uniqueDeleteCommentKey,
                id
            });
        });
    };

    renderItemsEmbedded = ({ item }) => {
        if (item) {
            let split = item.split(",");
            if (split && Array.isArray(split) && split?.length) {
                return split.map((data) => <ComponentNew embedData={data} />);
            }
        }
    };

    handleChange = (e) => {
        let errors = { ...this.state.shareError };
        if (e.target.value) {
            delete errors[e.target.name];
        }

        this.setState({
            [e.target.name]: e.target.value,
            shareError: errors,
        });
    };

    handleReplay = ({ id, value }) => {
        let { replyBox } = this.state;
        replyBox[id] = value;
        this.setState({ replyBox });
    }

    handleChangeReply = ({ id, value }) => {
        let { replyText } = this.state;
        replyText[id] = value;
        this.setState({ replyText });
    }

    handleSubmitReply = async ({ id, item, parent }) => {
        let { replyText } = this.state;
        let val = replyText[id]
        let url = `/api/reviewdiscussion/?review=${parent?.id}`;

        if (["actors", "accountants", "doctors", "dentists", "lawyers"].includes(this.props.listing_type)) {
            url = `/api/professionalreviewdiscussion/?review=${parent?.id}`;
        }

        let data = { "body": val, "review": parent?.id, "media": [], "parent_id": id }

        let response = await callApi(url, 'POST', data);
        if (response && response?.code !== 500) {
			toast(`Reply Added!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
            this.closeComments();
            this.props.handleReFetchCallAble('new');
        }
    }

    render() {
        let { item, my_profile } = this.props;
        let { commentMessage, commentId, replyBox, replyText } = this.state;
		
        return (
            <div className="link-embed-items-here mb-4"> 
                {item?.review_discussion?.map((postList, postListIndex) => (
                    <React.Fragment key={postListIndex}>
                        <Media className="media-post mt-3">
                            <Media>
                                <Media
                                    object
                                    src={postList?.sender?.current_profile_pic || "../../../assets/images/icons/user-circle.png"}
                                    onError={(e) => (e.target.src = require("../../../assets/images/icons/user-circle.png"))}
                                    alt="User Image"
                                />
                            </Media>
                            <Media body>
                                <Media className="mb-0" heading>
                                    <div className="d-flex flex-nowrap mx-n2">
                                        <div className="px-2 flex-grow-1">
                                            <span className="text-darker fs-14">
                                                {/* {replied to code will add here } */}
												<Link to={postList?.sender.full_name ? `/people/${postList?.sender?.username}`: `/people/${item?.created_by?.user?.username}`} className={`text-darker font-weight-bold nav-link-decoration-none`}>
												{postList?.sender.full_name ?
                                                        postList?.sender.full_name :
                                                        `${item?.created_by?.user?.first_name} ${item?.created_by?.user?.last_name}`}
														</Link>
                                               
                                            </span>
                                            {/* Comments */}
                                            {item?.child_review && (
                                                <span className="wb-break-word fs-14 font-weight-normal text-tertiary ff-alt">
                                                    {` replied to `} <Link to={`/people/${item?.created_by?.user?.username}`} className={`font-weight-bold nav-link-decoration-none tertiary-link ${item?.is_anonymous ? 'disable-link' : ''}`}> {item?.created_by?.user?.first_name && item?.created_by?.user?.first_name} {item?.created_by?.user?.last_name && item?.created_by?.user?.last_name}
                                                    </Link>
                                                    <span className="ml-2 fs-14 font-weight-normal">{postList.sender && postList.sent_at}</span>
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="d-flex flex-nowrap mx-n2 my-2">
                                        <div className="px-2 flex-grow-1">
                                            <span className="wb-break-word d-flex fs-14 font-weight-normal text-dark ff-alt">
                                                {postList.body}
                                                {!item?.child_review && ["movies", 'products'].includes(this.props.listing_type) ? (
                                                    <div className="align-self-end ml-auto col-auto px-2">
                                                        <DeleteBtn
                                                            onClick={() =>
                                                                this.deleteCommentFunction(
                                                                    postList.id
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                ) : null}

                                                {/* Remove Actions if comment by other user  */}
                                                <div className="align-self-end ml-auto col-auto px-2 pb-0 mb-0">
                                                    {item.created_by &&
                                                        postList.sender &&
                                                        postList.sender.id ===
                                                        my_profile.id && (
                                                            <div className="ml-auto">
                                                                {["movies", "products"].includes(this.props.listing_type) && (
                                                                    <EditBtn
                                                                        onClick={() =>
                                                                            this.updateCommentFunction(
                                                                                postList,
                                                                                item.id
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                                <DeleteBtn
                                                                    onClick={() =>
                                                                        this.deleteCommentFunction(
                                                                            postList.id
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </span>
                                        </div>
                                    </div>

                                    {!item?.child_review && (
                                        <div className="d-flex flex-nowrap mx-n2 my-2">
                                            <div className="px-2 flex-grow-1">
                                                <span
                                                    className="text-darker fs-14 cursor-pointer"
                                                    onClick={() => {
                                                        this.handleReplay({ id: postList?.id, value: true })
                                                    }}>
                                                    {`Reply`}
                                                </span>
                                                <span className="wb-break-word fs-14 font-weight-normal text-dark ff-alt">
                                                    {` - `}{postList.sender && postList.sent_at}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </Media>

                                <div>
                                    <div className="d-flex flex-nowrap mx-n0 mt-n3">
                                        <div className="align-self-end flex-grow-1 px-2">
                                            <span className="fs-14 ff-alt text-tertiary align-middle">

                                            </span>
                                        </div>

                                        
                                    </div>
                                </div>

                                {!item?.child_review && replyBox[postList?.id] ? (
                                    <div>
                                        <FormGroup className="mb-0 mt-4">
                                            <Input
                                                bsSize="sm"
                                                className="mb-2"
                                                type="textarea"
                                                name="commentMessage"
                                                value={replyText[postList?.id]}
                                                onChange={(e) => {
                                                    this.handleChangeReply({
                                                        id: postList?.id,
                                                        value: e.target.value
                                                    })
                                                }}
                                                placeholder="Write a reply ..."
                                            />
                                            <div className="text-right">
                                                <div className="text-right">
                                                    <Button
                                                        disabled={replyText[postList?.id]?.length ? false : true}
                                                        size="sm"
                                                        color="primary"
                                                        className=" mr-2"
                                                        onClick={() => {
                                                            this.handleSubmitReply({ id: postList?.id, item: postList, parent: item })
                                                        }}>
                                                        {"Submit"}
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        color="primary"
                                                        className="ml-0"
                                                        onClick={() => {
                                                            this.setState({
                                                                replyBox: {},
                                                                replyText: {}
                                                            })
                                                        }}>
                                                        {"Cancel"}
                                                    </Button>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </div>
                                ) : null}

                                {/* Show when editing your comment */}
                                <div hidden={postList.id !== commentId}>
                                    <Media className="media-post mt-3">
                                        <Media>
                                            <Media
                                                object
                                                src={postList?.sender?.current_profile_pic}
                                                onError={(e) => (e.target.src = require("../../../assets/images/icons/user-circle.png"))}
                                                alt="User Image"
                                            />
                                        </Media>
                                        <Media body>
                                            <FormGroup className="mb-0">
                                                <Input
                                                    bsSize="sm"
                                                    className="mb-2"
                                                    type="textarea"
                                                    name="commentMessage"
                                                    value={commentMessage}
                                                    onChange={this.handleChange}
                                                    placeholder="Editing your comment"
                                                />
                                                <div className="text-right">
                                                    <div className="text-right">
                                                        <Button
                                                            disabled={commentMessage.length ? false : true}
                                                            size="sm"
                                                            color="primary"
                                                            className=" mr-2"
                                                            onClick={() => this.updateComments("update")}>
                                                            {"Update"}
                                                        </Button>
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            className="ml-0"
                                                            onClick={this.closeComments}>
                                                            {"Cancel"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Media>
                                    </Media>
                                </div>

                                {/* Hide when editing your comment */}
                                <div>{/* Comments */}</div>
                            </Media>
                        </Media>

                        {this.props?.handleReFetchCallAble && postList.comment_reply && Array.isArray(postList?.comment_reply) && postList?.comment_reply?.length ?
                            postList?.comment_reply.map((item_reply, item_index) => (
                                <Media key={item_index} className="media-post mt-3 ml-5">
                                    <Media>
                                        <Media
                                            object
                                            src={item_reply?.sender?.current_profile_pic}
                                            onError={(e) => (e.target.src = require("../../../assets/images/icons/user-circle.png"))}
                                            alt="User Image"
                                        />
                                    </Media>
                                    <Media body>
                                        <Media className="mb-0" heading>
                                            <div className="d-flex flex-nowrap mx-n2">
                                                <div className="px-2 flex-grow-1">
                                                    <span className="text-darker fs-14">
                                                        {/* {replied to code will add here } */}
                                                        {item_reply?.sender.full_name ?
                                                            item_reply?.sender.full_name :
                                                            `${item?.created_by?.user?.first_name} ${item?.created_by?.user?.last_name}`}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-nowrap mx-n2 my-2">
                                                <div className="px-2 flex-grow-1">
                                                    <span className="wb-break-word fs-14 font-weight-normal text-dark ff-alt">
                                                        {item_reply.body}
                                                    </span>
                                                </div>
                                            </div>

                                            {!item_reply?.child_review && (
                                                <div className="d-flex flex-nowrap mx-n2 my-2">
                                                    <div className="px-2 flex-grow-1">
                                                        <span
                                                            className="text-darker fs-14 cursor-pointer"
                                                            onClick={() => {
                                                                this.handleReplay({ id: item_reply?.id, value: true })
                                                            }}>
                                                            {`Reply`}
                                                        </span>
                                                        <span className="wb-break-word fs-14 font-weight-normal text-dark ff-alt">
                                                            {` - `}{item_reply.sender && item_reply.sent_at}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </Media>

                                        <div>
                                            <div className="d-flex flex-nowrap mx-n0 mt-n4">
                                                <div className="align-self-end flex-grow-1 px-2">
                                                    <span className="fs-14 ff-alt text-tertiary align-middle">

                                                    </span>
                                                </div>

                                                {!item?.comment_reply && (
                                                    <div className="align-self-start col-auto px-2">
                                                        <DeleteBtn
                                                            onClick={() =>
                                                                this.deleteCommentFunction(
                                                                    item_reply.id
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {replyBox[item_reply?.id] ? (
                                            <div>
                                                <FormGroup className="mb-0 mt-4">
                                                    <Input
                                                        bsSize="sm"
                                                        className="mb-2"
                                                        type="textarea"
                                                        name="commentMessage"
                                                        value={replyText[item_reply?.id]}
                                                        onChange={(e) => {
                                                            this.handleChangeReply({
                                                                id: item_reply?.id,
                                                                value: e.target.value
                                                            })
                                                        }}
                                                        placeholder="Write a reply ..."
                                                    />
                                                    <div className="text-right">
                                                        <div className="text-right">
                                                            <Button
                                                                disabled={replyText[item_reply?.id]?.length ? false : true}
                                                                size="sm"
                                                                color="primary"
                                                                className=" mr-2"
                                                                onClick={() => {
                                                                    this.handleSubmitReply({ id: postList?.id, item: postList, parent: item })
                                                                }}>
                                                                {"Submit"}
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                color="primary"
                                                                className="ml-0"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        replyBox: {},
                                                                        replyText: {}
                                                                    })
                                                                }}>
                                                                {"Cancel"}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        ) : null}

                                    </Media>
                                </Media>
                            )) : null}
                    </React.Fragment>
                ))}
                {/* Comment by other user */}
                {/* My Comment */}
            </div>
        )
    }
}
