import React, { Component } from 'react';
import { Button, Col, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap';
import AppHeader from '../oragnisms/dashboard/appHeader';
import { connect } from "react-redux";
import { withRouter,Link } from 'react-router-dom';
import { delete_my_account } from '../../actions/user';
import { toast } from "react-toastify";

class DeleteAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            delete_account: true,
          
        };
    }

   


    handleOnSubmitDeleteAccount = (e) => {
        e.preventDefault();
        let {
            delete_account
        } = this.state;

        let data1 = {};
        let message = "Your account have been deleted!";

        data1.delete = delete_account
        this.props.delete_my_account(data1).then(() => {
            toast(message, {
                autoClose: 2500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
            });
            this.props.history.push('/')
        });
    };


    render() {
        return (
            <div>
                <AppHeader isEnableCover={false} />
                <div className="wk-static-wrapper">
                    <div className="wk-static-sub-head bg-black">
                        <h1>DELETE ACCOUNT</h1>
                    </div>
                    <div className="wk-static-content-body bg-transparent mx-auto lh-1-3" style={{maxWidth: 625}}>
                        <div className="text-tertiary text-center">
                            <h5 className="fs-36 text-dark mb-4">Are you sure you want to delete your account?</h5>
                            <p className="fs-16 ff-base">If you do not think you will use WikiReviews again and would like your account deleted, we can take care of this for you. Keep in mind that you will not able to reactivate your account or retrieve any of the content or information you have added. </p>
                            <p className="fs-14 ff-base">If you would still like your account deleted, click 'Delete My Account'.</p>
                        </div>                           	
                        <hr className="border-tertiary my-4" />
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div>
                                <Link to="#" className="fs-14 text-dark font-weight-bold">Learn more about Account Deletion</Link>
                            </div>
                            <div>
                                <Button color="tertiary" onClick={(e) => this.handleOnSubmitDeleteAccount(e)} size="lg">Delete My Account</Button>
                                <Link to="/home"><Button color="primary" size="lg">Cancel</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    my_privacy_setting: state.user.my_privacy_setting
});

const mapProps = (dispatch) => ({
    delete_my_account: (data) => dispatch(delete_my_account(data)),
    // get_my_privacy_list: () => dispatch(get_my_privacy_list()),


});


export default withRouter(connect(mapState, mapProps)(DeleteAccount));