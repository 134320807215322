/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
export default class FAQ extends Component {
    render() {
        return (
            <div>
            <AppHeader isEnableCover={false} />
            <div className="wk-static-wrapper">
              <Container>
                  <div className="wk-static-sub-head">
                     <h1>FREQUENTLY ASKED QUESTIONS</h1>
                  </div>
                  <div className="wk-static-content-body">
                     <Row>
                        <Col md={4}>
                           <div className="wk-static-sidebar-left">
                              <h2>FAQS</h2>
                              <ul>
                                    <li><a href="#General-Questions">General Questions</a> </li>
                                    <li><a href="#User-Reviews">User Reviews </a></li>
                                    <li><a href="#Review-Filter">Review Filter </a></li>
                                    <li><a href="#Listing-Information">Business, Product &amp; Movies Listing Information </a></li>
                                    <li><a href="#Search">Search </a></li>
                                    <li><a href="#Profile">Profile </a></li>
                                    <li><a href="#Other-Fav">Fans, Friends and Other Favorites </a></li>
                                    <li><a href="#Privacy">Privacy </a></li>
                                    <li><a href="#Legal">Legal </a></li> 
                            </ul>
                           </div>                            
                        </Col>
                        <Col md={8}>
                            <div className="wk-static-content-right">
                               <h2 id="General-Questions">GENERAL QUESTIONS</h2>
                               <h4 className="fs-14">What is WikiReviews?</h4>
                               <p className="fs-14">WikiReviews is an online, community-driven social review website that helps people find, write and read reviews of local and nationwide (USA & Canada) businesses, products services and films. WikiReviews is also a social site where users are encouraged to recommend things they like so that this can easily be shared with their friends and followers on the site. WikiReviews also allows the community to post their own personal projects that they want the community to review. WikiReviews goal is to be the largest online social site for reviews about just about anything and everything in the world. We can only accomplish this with the help of WikiCommunity volunteers all over the world.
                               </p>
                               <h4 className="fs-14">What is a Wiki?</h4>
                               <p className="fs-14">A Wiki is a website where users can add, modify or delete content via their web browser.
                               </p>
                               <h4 className="fs-14">What can the community do to help?</h4>
                               <p className="fs-14">First, we would love for you to contribute to the reviews of products, services, businesses and movies that we have on our site. If you don’t see a particular item on our site, please add it to our database so that others can add their reviews. More info is always better, so be sure to include everything you know, whether that’s a business address, phone number, movie title or product name. If you really enjoy helping, you can also add images and videos or rate other users’ reviews. This can all be done from the Participate Section at the bottom menu bar of our site.
                               </p>
                               <h4 className="fs-14">Does it cost anything to use WikiReviews?</h4>
                               <p className="fs-14">No! WikiReviews is completely free to use for all consumer users and business owners.
                               </p>
                               <h4 className="fs-14">How does WikiReviews make money?</h4>
                               <p className="fs-14">We make money through users clicking on the advertisements shown on the various pages of our site. Also, unlike other review sites, we do not solicit businesses listed on our site for advertising money nor can businesses pay us money to show up higher on our search results page. And most importantly, businesses cannot pay us to modify their star ratings. Star ratings are controlled by users and the community. This money will help us cover our web hosting costs as well as other administrative costs required to keep the site operating. The bulk of our workforce is made up of volunteers working hard to make our site the most comprehensive review site on the web.
                               </p>
                               <h4 className="fs-14">Who uses WikiReviews?</h4>
                               <p className="fs-14">You will see that we have a very diverse user base of folks who use our site. You'll find a wide range of people on WikiReviews, including locals who are "in the know" about what's cool and happening in their city, visitors who want to get an insider's local perspective, and anyone trying to find a great local business. In our Community Review Projects section, you will find all different types of users from business owners who want feedback on potential logos to musicians wanting to know about their music to even artists.
                               </p>
                               <h4 className="fs-14">What kind of Reviews Are Posted on WikiReviews?</h4>
                               <p className="fs-14">Studies show that people enjoy reviewing things they like and things they do not like. People like to give kudos to those things they really enjoyed and similarly, people like to caution others about their experiences. WikiReviews is not just a site full of complaints but rather a balanced site with reviews of all types.
                               </p>
                               <h2  id="User-Reviews">User Reviews</h2>
                               <h4 className="fs-14">What should I review on WikiReviews? </h4>
                               <p className="fs-14">You can review any business, product, service or movie. Just think of something you really liked or didn’t like – something you feel strongly about. Those are the things you should review.  You can also review service providers, such as your physician, lawyer, hair stylist, dog walker, pet groomer or drycleaner.  You can even review how well your favorite actor performed in a movie.  We would love to see reviews of the people you hire.  Please be sure read our <Link to="/guidelines">Content Guidelines</Link> before you begin writing reviews.
                               </p>
                               <h4 className="fs-14">What should I review on WikiReviews?</h4>
                               <p className="fs-14">You can review any business, product, service or movie. Just think of something you really liked or didn’t like – something you feel strongly about. Those are the things you should review.  You can also review service providers, such as your physician, lawyer, hair stylist, dog walker, pet groomer or drycleaner.  You can even review how well your favorite actor performed in a movie.  We would love to see reviews of the people you hire.  Please be sure read our <Link to="/guidelines">Content Guidelines</Link> before you begin writing reviews.
                               </p>
                               <h4 className="fs-14">What if I had a bad experience? Can I say something negative?</h4>
                               <p className="fs-14">Absolutely! The WikiReviews community wants to hear the good AND the bad about your experiences. Please be sure to include as much detail as possible so users can get the best picture of what happened. Try to be as unbiased as possible, and keep in mind there are legal consequences to posting false information.
                               </p>
                               <h4 className="fs-14">Can I leave an anonymous review?</h4>
                               <p className="fs-14">Yes, you can. We realize that there are times when you want to leave feedback but feel like you cannot because you do not wish to be recognized publicly. We allow one anonymous review for every 5 reviews a user creates. We do require all users to be logged into the site so we know you are a real person. Once you’ve created an account, you can opt to make any given review anonymous. Please note that after creating an anonymous review, it can no longer be changed or deleted and there will not be any tie to your personal account. As many anonymous reviews may be treated with skepticism, please provide as much detail as possible in your anonymous review.
                               </p>
                               <h4 className="fs-14">What if I’m offered free things in exchange for my review?</h4>
                               <p className="fs-14">We would like to offer unbiased reviews to all of our users. Being offered anything free in exchange for a review tends to make people feel obligated to do a positive review. Accordingly, we discourage businesses from offering compensation for reviews, and it is against our terms of service for users to accept compensation of any nature in exchange for reviews.
                               </p>
                               <h4 className="fs-14">Can business owners publicly comment on my reviews?</h4>
                               <p className="fs-14">Yes. Business owners can claim their businesses and they can respond publicly to the review, which you have written. You are also then free to communicate with them regarding their response. The community can even rate the business owner’s responses to any review. We hope this feedback will bring positive change or encourage business owners who are communicating positively with the community.
                               </p>
                               <h4 className="fs-14">Do you want me to Rate Reviews?</h4>
                               <p className="fs-14">Yes. We do need your help in rating the quality of reviews that our users provide. This will us weed out poorly written reviews, while simultaneously identifying the best reviews for the community to engage with.
                               </p>
                               <h4 className="fs-14">How do I publish my reviews to Facebook or Twitter?</h4>
                               <p className="fs-14">This is very simple. Just create a review as you normally would on our site, and just before publishing, be sure to click the Facebook and/or Twitter Icons and your review will post to those other sites. If you have not already connected those accounts on WikiReviews, we will ask you to connect the first time only. You can also connect to your social account directly on your main feed page. On the left hand column of the feed page, you will see Connections, which is where you can connect to your social networks.
                               </p>
                               <h4 className="fs-14">How are reviews displayed on the page?</h4>
                               <p className="fs-14">WikiReviews uses its own algorithms to try and show the most useful and beneficial reviews to our users. When you write a review, it will immediately show up on the site, but our algorithm will determine its position on the page, which may change over time.
                               </p>
                               <h4 className="fs-14">What does it mean when a review is flagged?</h4>
                               <p className="fs-14">When community members spot a review they feel is fraudulent or against our Content Guidelines, they can flag the review for an investigation. In these cases, based upon the flag reason, we will investigate the issue and resolve it or in the case of review flagged for being fraud, we ask all parties involved in the review to provide their explanation as well as proof for their position regarding the review and what they think should happen, and then we will let the community vote on whether the review should be filtered, deleted or unfiltered section.
                               </p>
                               <h2  id="Review-Filter">Review Filter</h2>
                               <h4 className="fs-14">What are filtered reviews?</h4>
                               <p className="fs-14">Filtered Reviews are reviews that the WikiCommunity has found to be suspicious in one way or another. The community suspects that those reviews may not be above-board, but there is not enough evidence to warrant deletion. Filtered reviews don't factor into a business's overall star rating, but users can still read them by choosing the Filtered Reviews pulldown on the actual review page.
                               </p>
                               <h4 className="fs-14">Can Filtered Reviews Ever Be Put Back into the Review Page?</h4>
                               <p className="fs-14">Yes! The community can flag those filtered reviews and provide proof as to why the review should not be in the filtered section, and then the community can vote on whether the review should be placed back into the unfiltered section.
                               </p>
                               <h4 className="fs-14">Are filtered reviews only positive reviews?</h4>
                               <p className="fs-14">No. Any review, whether positive, negative or indifferent, can be flagged and subsequently, be voted on, to be placed into the filtered reviews section.
                               </p>
                               <h4 className="fs-14">Why filter reviews?</h4>
                               <p className="fs-14">We understand that some reviews are going to be very meaningful and helpful to our users while others may not be helpful at all. And let’s face it, there are just some unscrupulous people out there who want to either make their competition look bad or simply have a vendetta against a particular business. The reviews these individuals write are not beneficial to our users. Similarly, there are many people who want to give their own products or businesses great reviews to increase sales. However, these reviews are sometimes difficult to distinguish against legitimate customer reviews. By having an independent group of community members from outside of the local area, look at all the evidence and vote on what should happen to the review, we let the community decide what should happen to the review. Who better than the community to decide these very important issues!
                               </p>
                               <h4 className="fs-14">Who can dispute and vote on reviews?</h4>
                               <p className="fs-14">The community at large can dispute a review or a business owner who has claimed his/her business listing page, can dispute a review. When disputing reviews, we want to hear all the reasons why the review was disputed and encourage those disputing to upload as much proof (photo or documents) to support their case. All parties in a dispute are given the opportunity to provide their side of the story and upload any evidence to support their case. Once this happens, an independent group of community members outside of the local jurisdiction is asked to participate in Jury Duty to look independently at all the facts of the case and vote on an outcome. Once this happens, the review is kept or moved based upon community voting.
                               </p>
                               <h2 id="Listing-Information">Business, Product &amp; Movie Listing Information</h2>
                               <h4 className="fs-14">How did my business and other information end up on WikiReviews?</h4>
                               <p className="fs-14">We license information from third party data providers who gather this type of information from public records and other sources. We also get business information from other databases as well as our users, who regularly add new businesses, products and movies to our database each and every day. Moreover, everyday users can keep editing the information to ensure that our information remains accurate.
                               </p>
                               <h4 className="fs-14">What is a Verified Business Listing?</h4>
                               <p className="fs-14">When users add new business, product or movie listings to our site, we try to auto verify the listings. When that cannot happen, we turn to the WikiCommunity for help. We ask users to help us by letting us know if they have been to the business, seen the movie or used the product listed on our site. In addition, we ask users to upload third party proof that the movie, product or business exists with either a picture or a link. Once we get solid proof or enough participation from signed-in users, we will either Verify the Listing it will be Nominated for Deletion. This ensures that any computer-generated or falsely-provided data is not shown on the site for very long.
                               </p>
                               <h4 className="fs-14">From where does WikiReviews acquire subjective business details?</h4>
                               <p className="fs-14">Subjective attributes like expensiveness ratings or child friendliness are voted on by users who have reviewed the business. They can change over time as more people review the business and cast more votes. The more objective attributes that we show in the business listing (whether the business accepts credit cards or is wheelchair accessible) can be manually set by the business owner if he/she has signed up for a free WikiReviews <a rel="noopener noreferrer" href="#" target="_blank">Business Owner’s Account.</a>
                               </p>
                               <h2 id="Search">Search</h2>
                               <h4 className="fs-14">How are search results ranked?</h4>
                               <p className="fs-14">WikiReviews’ search results are based on a mathematical formula that is designed to provide the best results based on a number of different factors, including review text, ratings, and number of reviews. We are constantly working on improving our search results so that we can deliver the most relevant local results to our users. WikiReviews does not allow any entity to pay to achieve higher search listings.
                               </p>
                               <h2 className="intro" id="Profile">Profile</h2>
                               <h4 className="fs-14">How do I verify my WikiReviews account?</h4>
                               <p className="fs-14">When you've completed the registration process, you will receive an email with a special link. Click it to verify your account. When the verification message arrives in your email in-box, click the verification link, or copy and paste it into your browser. Your account will be verified at this point. If it doesn't work <a href="contact_us">please let us know.</a>.
                               </p>
                               <h4 className="fs-14">How do I edit my profile and change my privacy and settings?</h4>
                               <p className="fs-14">To edit your profile or to change your privacy preferences, log in and click your username that appears at the top of any page (next to "Friends' Activity"). Once you get the user profile page, you can scroll down any just click inside anything to edit your information. To modify your privacy settings, click on the round privacy icon on the top right of the cover photo (just next to change image icon). One you click on that icon, you will see a popup where can change your privacy and settings.
                               </p>
                               <h4 className="fs-14">How do I change my email address?</h4>
                               <p className="fs-14">Your email addresses and password are found under the My Info section of the user profile page. Just click on the email and you can instantly edit your email address.
                               </p>
                               <h4 className="fs-14">Who can find me and my reviews on WikiReviews?</h4>
                               <p className="fs-14">Everyone can read your reviews that you have not labeled as anonymous. They can also look at your account profile, photos, bookmarks, and other content that you've contributed to the site. Your public profile will be tied to your first name and last initial (e.g., "Sunil W."). Other than just stumbling upon you and your great reviews by browsing the site, other people can find you using the "Friend Search" feature in the Friends section of the user profile page.
                               </p>
                               <h4 className="fs-14">How do I close my account on WikiReviews?</h4>
                               <p className="fs-14">To close your user account, please click here. If you don't mind telling us, we'd love to hear why you want to leave. Your feedback makes a big difference. If there's something you found unsatisfying about WikiReviews, tell us about it and we'll do our best to improve.
                               </p>
                               <h2 id="Other-Fav">Fans, Friends, and Other Favorites</h2>
                               <h4 className="fs-14">What's a “friend” on WikiReviews?</h4>
                               <p className="fs-14">Well, a friend on WikiReviews is pretty much the same thing as a friend anywhere else. It's someone you enjoy interacting with on the site and want to hold out to the world as your pal. Of course, you don't need to be friends with someone to send them messages or read their reviews, so keep in mind that just like life itself, the WikiReviews experience extends beyond your close-knit group of friends. We even allow you to automatically import or invite all of your friends and contacts from other social media sites and your email account.
                               </p>
                               <h4 className="fs-14">How do I invite friends?</h4>
                               <p className="fs-14">WikiReviews is so much better when you can hear from people you trust. Don’t hesitate to invite all your friends, family and work colleagues to join you on the site. Go to the user profile section, then click on the "Friends" tab on the header of the page, then click on “Find Friends.” From here you can invite your friends from your social media accounts. To invite your email contacts, just look for the Import From on the top right of this same page and click all of th appropriate email account providers you use. This will allow you to connect to each account you have and import those contacts. We have already created an invitation message for you that will get sent after you connect. If they accept your invitation, they will automatically appear in your network.
                               </p>
                               <h4 className="fs-14">How do I remove a friend?</h4>
                               <p className="fs-14">On your User Profile page, click on the "Friends" tab, then below that, click on My Friends. To Unfollow those friends, just mouseover the user picture of the friend you want to Unfollow and click on the Unfollow button. It’s that simple.
                               </p>
                               <h4 className="fs-14">What is a "fan"?</h4>
                               <p className="fs-14">Users can "follow" you (see the "Follow This Reviewer" links on user profiles) and in doing so they become your fan. You won't be able to see their identity, but they are showing their appreciation for your passionate reviews and they'll also see your reviews first when browsing any listings you have reviewed.
                               </p>
                               <h4 className="fs-14">What does it mean to “follow” another reviewer?</h4>
                               <p className="fs-14">By clicking the link on a user's profile to "Follow This Reviewer", you'll be showing your appreciation for their reviews by becoming their fan. You will also see their reviews first in our default sort order whenever you browse a business they've reviewed.
                               </p>
                               <h4 className="fs-14">What is the Watchlist?</h4>
                               <p className="fs-14">Similar to bookmarks in your web browser, your Watchlist is a way for you to mark a business, product or movie that you are interested in. This will make it super simple for you to keep track of the activity of that listing by going straight to your Watchlist rather than having to search for the listing. From this section, you will get updates on new reviews and activity since you last visited the page. Watchlist buttons are conveniently located at the top of each listing page. Clicking “Add to Watchlist” adds that listing to your Watchlist. Your Watchlist is only available for you to see. Your friends are not able to see your Watchlist.
                               </p>
                               <h2 id="Privacy">Privacy</h2>
                               <h4 className="fs-14">What does WikiReviews do with my personal information?</h4>
                               <p className="fs-14">We take privacy very seriously and hate spam even more than you do. Please take the time to review our&nbsp;<Link to="/policies">privacy policy</Link>&nbsp;so you can see exactly what we do with your personal information. If you receive spammy messages from another WikiReviews member, or if you receive a message that is harassing, abusive, or contains a violation of our Terms of Service, please report it here.
                               </p>
                               <h4 className="fs-14">Who can contact me on WikiReviews?</h4>
                               <p className="fs-14">Other users can contact you on WikiReviews, including the businesses you review. If you'd prefer not to be contacted by business owners, you may change your settings here. You can also block future messages from specific users by selecting "Block User" under their account name when viewing a message from them.
                               </p>
                               <h4 className="fs-14">Who can contact me on WikiReviews?</h4>
                               <p className="fs-14">Other users can contact you on WikiReviews, including the businesses you review. If you'd prefer not to be contacted by business owners, you may change your settings on the privacy and setting page which you can find by clicking the gear icon on your user profile page. You can also block future messages from specific users by selecting "Block User" under their account name when viewing a message from them.
                               </p>
                               <h2 id="Legal">LEGAL</h2>
                               <h4 className="fs-14">I'm not happy with what consumers are saying about me on WikiReviews - should I get my lawyer involved?</h4>
                               <p className="fs-14">The first thing we encourage you to do is to reach out to the reviewer to explain your side of the story in a nice and cordial manner.  Then, the reviewer has their own option to either delete the review or create a review update.  The business owner is also able to dispute the review and provide proof to support the reasoning.  If these strategies do not work and you would like to hire a lawyer to contact us, we encourage you to please read Section 230 of the Communications Decency Act <a href="https://en.wikipedia.org/wiki/Section_230_of_the_Communications_Decency_Act"> (https://en.wikipedia.org/wiki/Section_230_of_the_Communications_Decency_Act).</a>
                               </p>
                            </div>
                        </Col>
                     </Row>
                  </div>
              </Container>
            </div>
        </div>
        )
    }
}
