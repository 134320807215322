/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from '../oragnisms/dashboard/appHeader';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, FormGroup, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactHtmlParser from 'react-html-parser';
import { callApi, responseCodeValidator } from '../../utils/apiCaller';
import { Link } from 'react-router-dom';
import {
    FacebookShareButton,
    TwitterShareButton
} from "react-share";
import CreateReview from '../../component/atoms/createReview';
import UploadMedia from '../atoms/fileUploadMedia';
// import MyConnectionsActivity from '../oragnisms/business/myConnectionsActivity';
import UserFeedsRightPanel from '../atoms/userFeedsRightPanel';
import { connect } from 'react-redux';
import { add_to_list, remove_from_list } from '../../actions/business';
import { getQueryString } from '../../utils/validation';
import { get_uploaded_media } from '../../actions/itemDetail';
import { detectlink, pushUniqueObjectsInArray, removeHTMLtags } from '../../utils/utilities';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';
import { getSubCategoryDescription, getSubCategoryTitle } from '../../utils/seo-utilities';

class BusinessCategory extends Component {
    constructor(props) {
        super(props);
        this.enableScrollTillEnd = true;
        this.state = {
            categoryName: props?.location?.state?.child_category || null,
            categoryId: props?.location?.state?.id || null,
            subCategorySlug: props?.match?.url || '',
            myConnectionActivity: 'all',
            viewEditReviewStep: 'writeReview',
            viewBusinessType: 'all',
            loading: true,
            isLoadingCategories: false,
            currentPageIndex: 1,
            checkNextPagination: false,
            collageItems: null,
            listingItems: null,
            childItems: {},
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: '',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
            toggleItemCreateReview: {},
            isEnableFileUpload: false,
            uploadedMediaSet: {
                embedLinks: {},
                uploadedFiles: [],
            },
            pathName: "",
            isOpen: {},
            expandID: '',
            starRatingApplied: false,
            stars: 0,
            toggleShowSubCategories: {},
            this_user: null,
            current_location: null
        }
    }


    componentDidMount() {
        this.setState({ starRatingApplied: false, stars: 0 })
        window.scrollTo(0, 0)
        window.addEventListener('scroll', this.handleScroll, { passive: false });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.this_user) {
            this.setState({this_user: nextProps.this_user.id})
        }

        if (nextProps.current_location) {
            if (this.state.current_location?.lat !== nextProps.current_location.lat) {
                this.setState({ current_location: nextProps.current_location }, () => {
                    const { categoryId } = this.state;
                    if (categoryId)
                        this.preFetchItems()

                    else if (this.state.subCategorySlug)
                        this.fetchCategoryIdBySlug(this.state.subCategorySlug)
                })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTillHeight = window.pageYOffset;
        let lastElemHeight = document.getElementById("last-index")?.offsetTop;
        const { currentPageIndex, checkNextPagination, starRatingApplied, loading } = this.state;
    
        if (scrollTillHeight > lastElemHeight && checkNextPagination && !loading) {

            if (this.enableScrollTillEnd) {
                
                this.enableScrollTillEnd = false;
                const tempCurrentPageIndex = currentPageIndex + 1;
                this.setState({ currentPageIndex: tempCurrentPageIndex }, () => {
                    if (starRatingApplied) {
                        this.fetchStarRatingItems()
                    } else {
                        this.fetchListingItem();
                    }
                });
            }
        }
    }


    componentDidUpdate(prevProps) {
        let nextProps = this.props;

        if ((nextProps?.match?.params?.category !== prevProps?.match?.params?.category) || (nextProps?.match?.params?.category2 !== prevProps?.match?.params?.category2)) {
            this.resetListingsData();
            this.setState({
                categoryName: nextProps?.match?.params?.category2 || nextProps?.location?.state?.child_category || null,
                categoryId: nextProps?.location?.state?.id || null,
            }, () => { this.preFetchItems();  window.scrollTo(0, 0); });
        }
    }

    resetListingsData() {
        this.setState({
            listingItems: {},
            checkNextPagination: false,
            currentPageIndex: 1
        })
    }


    fetchListingItem = async () => {
        try {
         
            let { categoryId, listingItems, viewBusinessType , currentPageIndex} = this.state;
            this.setState({ loading: true });
            const uriPath = viewBusinessType === "friendsPick" ? `/api/friendspick/`:  `/api/search/`;
            const queryParams = {
                content_type: "business",
                cat: categoryId,
                to_api: true,
                showReview: true,
                cPagebest: true,
                location: true,
                page:  currentPageIndex
            }

            if (viewBusinessType === "recommended") { queryParams['recommends'] = true; delete queryParams.cPagebest }
            
           const url = getQueryString({ url: uriPath, data: [queryParams] });
            // let response = await callApi(`/api/search/?content_type=business&cat=${this.state.categoryId}&to_api=true&showReview=true&location=true&page=1&recommends=true`, 'GET');
            let response =  await callApi(url, "GET");

            this.enableScrollTillEnd = true;
            if (listingItems?.results?.length) {
                let newItems = response.results;
                let oldItems = listingItems?.results;
                // oldItems = oldItems.concat(newItems);
                oldItems = pushUniqueObjectsInArray(oldItems, newItems, 'id');
                response.results = oldItems;
                this.setState({ listingItems: response, checkNextPagination: response.next,loading:false });
            } else {
               
                this.setState({ listingItems: response, checkNextPagination: response.next,loading:false });
            }

        } catch (err) {
            console.log(err)
        }
    }


    preFetchItems = async () => {
        let { categoryId } = this.state;
        try {
            if (categoryId) {
                this.fetchCategories(categoryId)
                this.fetchListingItem()
                this.fetchCollageItems(categoryId)
                this.enableScrollTillEnd = true;
            } else {
                this.props.history.push('/business/');
            }
        } catch (err) {
            console.log(err);
        }
    }

    fetchCategories = async (categoryId) => {
      
        this.setState({ isLoadingCategories: true })
        let categories = await callApi(`/api/taxonomy/?category=${categoryId}&depth=true&delsel=&ancestor=&sub_cat=&level=&enableSubChild=true`, 'GET');
        this.setState({ categories, isLoadingCategories: false })
    }

    // fetchListingItem = async (categoryId) => {
    //     this.setState({ loading: true })
    //     let listingItems = await callApi(`/api/search/?content_type=business&cat=${categoryId}&to_api=true&showReview=true&cPagebest=true&location=true&page=${this.state.currentPageIndex}`, 'GET');
    //     this.setState({ listingItems, checkNextPaination: listingItems?.next, loading: false })
    // }

    fetchCollageItems = async (categoryId) => {
        let collageItems = await callApi(`/api/search/?content_type=business&catTrending=True&subcat=${categoryId}&size=6&bizCatPage=True`, 'GET');
        this.setState({ collageItems })
    }

    getSubCategories = async ({ item }) => {
        try {
            let id = item?.id;
            let { childItems } = this.state;
            let response = await callApi(`/api/taxonomy/?category=${id}&depth=true&delsel=&ancestor=&sub_cat=&level=`, 'GET');
            if (response && response?.code !== 500) {
                childItems[id] = response;
                this.setState({
                    childItems
                });
            }
        } catch (err) {
            console.log(err);
        }
    }


    renderChildItems = ({ id }) => {
        let { childItems } = this.state;
        if (childItems[id] && Array.isArray(childItems[id]) && childItems[id].length) {
            return (
                <Collapse isOpen={true}>
                    <ul className="list-unstyled pl-2 fs-14">
                        {childItems[id].map(item => (
                            <li>
                                <div className="d-flex align-items-start py-1">
                                    <Link
                                        to={{
                                            pathname: `${'/business-category/' + item.seo_label?.split(/business-reviews\//)?.[1] || item.id}`,
                                            state: {
                                                id: item.id,
                                                parent_category: "business",
                                                child_category: item?.category
                                            }
                                        }}
                                        className="text-dark ff-base p-0 text-left"
                                    >
                                        {item?.category}
                                    </Link>
                                    {item?.children_count > 0 && (
                                        <Button
                                            onClick={() => { this.getSubCategories({ item }) }}
                                            size="sm" color="transparent" className="ml-auto text-dark px-2 py-0">
                                            <FontAwesomeIcon icon="angle-down" size="sm" />
                                        </Button>
                                    )}
                                </div>
                                {this.renderChildItems({ id: item?.id })}
                            </li>
                        ))}
                    </ul>
                </Collapse>
            )
        }
    }

    fetchStarRatingItems = async () => {
        const { listingItems, stars, categoryId, currentPageIndex, viewBusinessType } = this.state;
        const urlBasePath = `/api/search/`;
        
        try {
            const queryParams = {
                content_type: 'business',
                cat: categoryId,
                to_api: true,
                showReview: true,
                location: true,
                cPagebest:true,
                stars: stars,
                page: currentPageIndex,
                
            }

            if (viewBusinessType === "recommended") { queryParams['recommends'] = true; delete queryParams.cPagebest }

            const url = getQueryString({ url: urlBasePath, data: [queryParams] });
            this.setState({ loading: true });
            let response = await callApi(url, 'GET');
            if (response && responseCodeValidator(`${response.code}`)) {
                const prevListingItems = listingItems?.results && listingItems?.results?.length ? [...listingItems?.results] : [];

                const newListingItems = { ...response, results: [...prevListingItems, ...response?.results] }

                this.enableScrollTillEnd = true;
                this.setState({
                    listingItems: newListingItems,
                    checkNextPagination: response?.next,
                    loading: false,
                });

            }
        } catch (err) {
            console.log(err);
        }
    }

    /**
    * Function to phone number format
    * 
    */

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    }


    fetchCategoryIdBySlug = async (tempSlug) => {

        const slug = tempSlug ? tempSlug.split('/')[2] :'';

        if (slug) {
            const url = `/api/get-id-by-category/?slug=${`/business-reviews/` + slug + `/`}`;
            try {
                this.setState({
                    loading: true
                })
                const response = await callApi(url, 'GET');
                this.setState({
                    loading: false
                })

                if (response && response?.code !== 500 && response?.code !== 400 && response?.code !== 404) {

                    if (response?.id) {
                        this.setState({
                            categoryId: response.id
                        }, () => this.preFetchItems())
                        return response;
                    }
                }
            } catch (err) {
                return undefined
            }
        } else {
            return undefined;
        }
    }

    goToPreviousCategory = async (e) => {
        e.preventDefault();
        const response = await this.fetchCategoryIdBySlug(this.props?.match?.url); //geting parent id and slug 
        if (response && response?.parent && response?.parent?.id && response?.parent?.seo_label && response?.parent?.label !== 'Business') {
            const slug = response?.parent?.seo_label.split(/\/business-reviews/);
            if (slug && slug[1]) {
                this.props.history.push({
                    pathname: `${`/business-category` + slug[1]}`,
                    state: {
                        id: response?.parent?.id,
                        parent_category: "business",
                        child_category: response?.parent?.category
                    }
                })
            } else {
                this.props.history.push('/business')
            }
        } else
            this.props.history.push('/business')
    }

    handleOnExpand = async (expand, id) => {
        let { isOpen } = this.state;
        isOpen[id] = expand;
        await this.setState({ isOpen });
    }

    handleChnageTab = (tabType) => {
        const { starRatingApplied, stars } = this.state;
        this.setState({ viewBusinessType: tabType, listingItems: [], currentPageIndex: 1, stars: stars, }, () => {
            if (starRatingApplied) {
                this.fetchStarRatingItems();
            }
            else {
                this.fetchListingItem();
            }
        })
    }

    handleOnClickAddToList = (type, status, item) => {
        let message = '',
            url = '',
            nextUrl = '',
            key = '',
            data = { type };
        if (type.includes('want')) {
            key = 'user_want_status';
        } else if (type.includes('favourite')) {
            key = 'user_favourites_status';
        } else {
            key = 'user_watch_status';
        }

        if (status) {
            message = `${item.name} has been removed from your ${type}!`;
            url = `/api/pins/${item.id}/?type=${type}`;
            this.props.remove_from_list(url, message, nextUrl);
            item.pins[key] = false;
        } else {
            data.user_entry = item.id;
            message = `${item.name} has been added to your ${type}!`;
            url = `/api/pins/?type=${type}&user=`;
            this.props.add_to_list(url, data, message, nextUrl);
            item.pins[key] = true;
        }

        let newResults = this.state.listingItems.results.map(i => {
            if (i.id === item.id) {
                return item;
            } else {
                return i;
            }
        })

        this.setState({
            listingItems: {
                ...this.state.listingItems,
                results: newResults
            }
        });

    }

    renderItems = () => {
        let { listingItems, toggleItemCreateReview } = this.state;
        let filteredLisingItems = listingItems?.results;
        if (filteredLisingItems && Array.isArray(filteredLisingItems) && filteredLisingItems?.length) {
            return (
                <>
                {filteredLisingItems.map((item, item_index) => (
                <div
                    id={`${filteredLisingItems?.length == item_index + 1 ? 'last-index' : `current-index-${item_index}`}`}
                    className="mb-3" >
                    <div className="bg-white text-dark p-3 mb-1">
                        <div className="d-flex mx-n2 mb-2">
                            <div className="px-2 d-flex flex-column">
                                <Link
                                    to={{
                                        pathname: `/business/${item?.id}`,
                                        state: { id: item?.id }
                                    }}
                                    className="text-decoration-none d-block mb-3">
                                        <img
                                            className="listing-profile-image"
                                            width="110"
                                            src={item?.listing_profileimage && item?.listing_profileimage[0] ? item?.listing_profileimage[0].url : `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`}
                                            onError={(err) => { err.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png` }}
                                            alt={item?.name} />
                                </Link>

                                <div hidden className="text-center">
                                    <Button
                                        onClick={() => {
                                            if (toggleItemCreateReview[item?.id] == undefined) {
                                                toggleItemCreateReview[item?.id] = true;
                                            } else {
                                                toggleItemCreateReview[item?.id] = !toggleItemCreateReview[item?.id];
                                            }
                                            this.setState({ toggleItemCreateReview });
                                        }}
                                        color="link" className="text-primary font-weight-bold p-0">Write a review</Button>
                                </div>
                            </div>
                            <div className="px-2 col">
                                <div className="d-flex mx-n2">
                                    <div className="px-2 col">
                                        <div className="ff-base">
                                            <Link
                                                to={{
                                                    pathname: `/business/${item?.id}`,
                                                    state: { id: item?.id }
                                                }}
                                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none">
                                                {`${item_index + 1}. ${item?.name || ''}`}
                                            </Link>
                                            <div className="mt-2">
                                                <span className="d-inline-block hoverable-rating">
                                                    {item?.total_rating?.avg_rating && item?.total_rating?.avg_rating[0] ?
                                                        <img
                                                            title={item?.total_rating?.avg_rating[1]}
                                                            src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item?.total_rating?.avg_rating?.[0] || "norating.png"}`}
                                                            onError={(error) =>
                                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png`)
                                                            }
                                                            alt={`${item?.total_rating?.avg_rating[1]} Rating`}
                                                        />
                                                        : null}
                                                    <div className="detail-rating-onhover">
                                                        <div className="text-dark font-weight-bold mb-2">
                                                            {'Detailed Star Rating'}
                                                        </div>
                                                        <div>
                                                            {item?.detailed_rating && Object.keys(item?.detailed_rating).map(rating_item => !["avg_rating", 'rating_count', 'total_review'].includes(rating_item) && (
                                                                <div className="d-flex flex-nowrap align-items-center">
                                                                    <img
                                                                        title={item.detailed_rating[rating_item][1] || ''}
                                                                        className="mr-1"
                                                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item?.detailed_rating[rating_item][0] || 'norating.png'}`}
                                                                        onError={(error) =>
                                                                            (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png`)
                                                                        }
                                                                        alt={`${item.detailed_rating[rating_item][1] || ''}`}
                                                                    />
                                                                    <span className="ff-alt">{rating_item}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </span>{' '}{' '}
                                                <span>
                                                    {`${(item?.detailed_rating?.total_review || item?.total_rating?.total_review)} review`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-2 col-auto">
                                        <div className="d-inline-flex flex-nowrap mx-n1">
                                            <div className="px-1">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                        <div className="share-it-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu right className="type-tertiary-alt">
                                                        <DropdownItem className="fs-14">
                                                            <FacebookShareButton
                                                                url={`${this.state.protocol}//${this.state.host}/movies/${item?.id ? item?.id : 'null'}`}
                                                                quote={`${item?.name} Reviews \n ${item?.additional_info?.storyline && item?.additional_info?.storyline[0]?.value ? item?.additional_info?.storyline[0]?.value : ''}`}
                                                                hashtag={this.state.fbHashTag}>
                                                                {'Facebook'}
                                                            </FacebookShareButton>
                                                        </DropdownItem>
                                                        {/* <DropdownItem className="fs-14">Google +</DropdownItem> */}
                                                        <DropdownItem className="fs-14">
                                                            <TwitterShareButton
                                                                url={`${this.state.protocol}//${this.state.host}/movies/${item?.id ? item?.id : 'null'}`}
                                                                title={`${item?.name} Reviews \n ${item?.additional_info?.storyline && item?.additional_info?.storyline[0]?.value ? item?.additional_info?.storyline[0]?.value : ''}`}
                                                                via={this.state.shareVia}
                                                                hashtags={this.state.twitterHashTags}>
                                                                {'Twitter'}
                                                            </TwitterShareButton>
                                                        </DropdownItem>
                                                        <DropdownItem className="fs-14" onClick={() => this.setState({
                                                            shareByEmailModalToggle: true
                                                        })}>By Email</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                            <div className="px-1">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                        <div className="thumbpin-icon"></div>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="type-tertiary-alt" right>
                                                        <DropdownItem
                                                            className="fs-14"
                                                            onClick={() => this.handleOnClickAddToList('want list', item.pins?.user_want_status, item)}>
                                                            <div className="addto-icon want-list"></div>
                                                            {item.pins?.user_want_status ? 'Added' : 'Add'} {'to want list'}
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            className="fs-14"
                                                            onClick={() => this.handleOnClickAddToList('favourite list', item.pins?.user_favourites_status, item)}>
                                                            <div className="addto-icon favorite-list"></div>
                                                            {item.pins?.user_favourites_status ? 'Added' : 'Add'} {'to favorites'}
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            className="fs-14"
                                                            onClick={() => this.handleOnClickAddToList('watch list', item.pins?.user_watch_status, item)}>
                                                            <div className="addto-icon watch-list"></div>
                                                            {item.pins?.user_watch_status ? 'Added' : 'Add'} {'to watch list'}
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ff-alt">
                                    <div className="mt-2">
                                        <span className="font-weight-bold">
                                            {item?.address[0].address1}, {item.address[0].city}, {item.address[0].state} {item.address[0].zipcode}, {item.address[0].country}
                                            {/* {item?.address && item?.address[0] && Object.values(item?.address[0]).length ?
                                                Object.values(item?.address[0]).filter(item => item !== null).join(', ')
                                                : null} */}
                                        </span>
                                    </div>
                                </div>
                                <div className="ff-base">
                                    <div className="mt-2">
                                        <span>
                                            {item?.category?.replace(/,/g, ', ') || ''}
                                        </span>
                                    </div>
                                </div>
                                <div className="ff-alt">

                                    <div className="mt-2">
                                        <span>{item?.phone_set ? item?.phone_set[0]?.label === "mob" ? item?.phone_set[0]?.phone : this.formatPhoneNumber(item?.phone_set[0]?.phone) : ""}</span>
                                    </div>
                                </div>

                                <div className="text-right mt-3">
                                    <Link
                                        to={{
                                            pathname: `/business/${item?.id}`,
                                            state: { id: item?.id }
                                        }}
                                        className="text-dark font-weight-bold">view more</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Show All Reviews */}
                    {item?.review?.id ? 
                    <div className="mb-1">
                        {/* Repeat this */}
                        <div className="bg-white text-dark p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                                <div className="px-2">
                                    <a href="#" className="text-decoration-none">
                                        <img
                                            className="img-circle _30x30"
                                            src={item?.review?.current_profile_pic_full_path}
                                            alt={item?.review?.created_by?.user?.first_name}
                                        />
                                    </a>
                                </div>
                                <div className="px-2 flex-grow-1">
                                        <div className="ff-base text-dark">
                                            <Link
                                                to={`/people/${item?.review?.created_by?.user?.username}/`}
                                                className="text-reset ff-base font-weight-bold"
                                            >
                                                {`${item?.review?.created_by?.user?.first_name || ''} ${item?.review?.created_by?.user?.last_name || ''}`}
                                            </Link>
                                            {' '}{' '}
                                            <span>gave it</span>

                                        <div className="fs-13">
                                            <img
                                                title={item?.review?.ratings_sets && item.review.ratings_sets[1]}
                                                src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${item?.review?.ratings_sets && item.review.ratings_sets[0] || 'norating.png'}`}
                                                onError={(error) =>
                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png`)
                                                }
                                                alt={`${(item?.review?.ratings_sets && item.review.ratings_sets[0]) || 'No-rating'}`}
                                            />{' '}{' '}
                                            {item?.review?.review_date_time || ''}
                                            {/* {'2 years ago via iOS in Georgia, USA'} */}
                                        </div>
                                    </div>
                                    {/* User Reply */}
                                    <div className="ff-alt mt-3">
                                        {/* {item?.review?.review && ReactHtmlParser(item?.review?.review)} */}
                                        {this.state.expandID === item.review?.id ?
                                            item?.review?.review && removeHTMLtags(item?.review?.review)
                                            :
                                            item?.review?.review?.length < 90 ? removeHTMLtags(item?.review?.review) :
                                                <>
                                                    {removeHTMLtags(item?.review?.review?.substring(0, 90)) + "..."}
                                                </>

                                        }
                                        {/* {item?.review?.review || ''} */}
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end align-items-center">
                                {/* <Button color="link" className="text-primary font-weight-bold p-0">Write a follow up</Button> */}
                                {/* <Button
                                    onClick={() => {
                                        if (toggleItemCreateReview[item?.id] == undefined) {
                                            toggleItemCreateReview[item?.id] = true;
                                        } else {
                                            toggleItemCreateReview[item?.id] = !toggleItemCreateReview[item?.id];
                                        }
                                        this.setState({ toggleItemCreateReview });
                                    }}
                                    color="link" className="text-primary font-weight-bold p-0">
                                        {this.state.this_user === item?.review?.created_by?.id ? "Write your follow-up" : "Create your own review"}
                                </Button> */}

                                        <Link
                                            to={{
                                                pathname: `/business/${item?.slug ? item.slug : item.id}`,
                                                state: { id: item?.id }
                                            }}
                                            color="link" className="text-primary font-weight-bold p-0 pr-3">
                                            {this.state.this_user === item?.review?.created_by?.id ? "Write your follow-up" : "Create your own review"}
                                        </Link>

                                <Button color="link" className="text-dark font-weight-bold p-0">
                                    {/* <Link
                                        to={{
                                            pathname: `/business/${item?.slug}`,
                                            state: { id: item?.id }
                                        }}>
                                        read more
                                    </Link> */}
                                    {this.state.expandID === item?.review?.id ?
                                        <span className="text-dark font-weight-bold" role="button"
                                            onClick={async () => {
                                                this.setState({
                                                    expandID: ''
                                                })
                                            }}>show less</span>
                                        :
                                        <span className="text-dark font-weight-bold" role="button"
                                            onClick={async () => {
                                                this.setState({
                                                    expandID: item?.review?.id
                                                })
                                            }}>read more</span>}


                                </Button>
                            </div>
                        </div>
                    </div> 
                    : 
                    <div className="mb-1">
                        <div className="bg-white text-dark p-3 mb-1">
                            <div className="d-flex mx-n2 mb-2">
                                <div className="px-2 flex-grow-1">
                                    <div className="ff-alt mt-3">
                                    Be the First to Write A Review About This Listing! (And Get Your First Reviewer Badge!)
                                    </div>
                                </div>
                            </div>

                            <div className="text-right">
                                {/* <Button
                                    onClick={() => {
                                        if (toggleItemCreateReview[item?.id] == undefined) {
                                            toggleItemCreateReview[item?.id] = true;
                                        } else {
                                            toggleItemCreateReview[item?.id] = !toggleItemCreateReview[item?.id];
                                        }
                                        this.setState({ toggleItemCreateReview });
                                    }}
                                    color="link" className="text-primary font-weight-bold p-0">Create your own review</Button> */}
                                        <Link
                                            to={{
                                                pathname: `/business/${item?.slug ? item.slug : item.id}`,
                                                state: { id: item?.id }
                                            }}
                                            color="link" className="text-primary font-weight-bold p-0 pr-3"> Create your own review
                                        </Link>
                            </div>
                        </div>
                    </div> 
                    }


                    {toggleItemCreateReview[item?.id] && 
                        (this.state.this_user === item?.review?.created_by?.id ?
                        <CreateReview
                            itemDetail={item}
                            closeAfterCancel={this.closeAfterCancel}
                            viewEditReviewStep={'writeReview'}
                            mainViewType={'reviews'}
                            listing_type={'business'}
                            reviews={[]}
                            toggleEnableFileUpload={this.toggleEnableFileUpload}
                            uploadedMediaSet={this.state.uploadedMediaSet}
                            removeUploadedMediaSet={this.removeUploadedMediaSet}
                            onPublishReview={this.onPublishReview}
                            categoryId={item?.id}
                            type="followUp"
                            reviewType="categoryPage"
                            item={item?.review}
                            isWriteFollowUp={true}
                        />
                        :
                        <CreateReview
                            itemDetail={item}
                            closeAfterCancel={this.closeAfterCancel}
                            viewEditReviewStep={'writeReview'}
                            mainViewType={'reviews'}
                            listing_type={'business'}
                            reviews={[]}
                            toggleEnableFileUpload={this.toggleEnableFileUpload}
                            uploadedMediaSet={this.state.uploadedMediaSet}
                            removeUploadedMediaSet={this.removeUploadedMediaSet}
                            onPublishReview={this.onPublishReview}
                            categoryId={item?.id}
                            type="followUp"
                            reviewType="categoryPage"
                        />
                        )
                    }
                </div>
            ))}
                    {
                        (filteredLisingItems &&
                            Array.isArray(filteredLisingItems) &&
                            filteredLisingItems.length && this.state.loading) ?
                            <div className="mt-5 text-center">
                                <h2>{"Loading more business..."}</h2>
                            </div> : ''
                    }
                    <div style={{ height: "400px" }} />
                </>
                
            )
        } else {
            return this.state.loading ? (
                <div>
                    <h2>{"Loading..."}</h2>
                </div>
            ) : (
                <div>
                    <div className="no-result-category">
                        {'No Results Found. Please Choose Another Category.'}
                    </div>
                </div>
            )
        }
    }


    closeAfterCancel = () => {
        this.setState({ toggleItemCreateReview: {} })
    }

    toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
    }

    onPublishReview = () => {
        this.setState({
            toggleItemCreateReview: {},
            toggleItemReadMore: {},
            moviesList: [],
            currentPageIndex: 1
        }, () => {
            this.fetchListingItem();
        })
    }

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet } = this.state;
        let uploadedMediaSetCopy = { ...uploadedMediaSet }
        const key = 'id'
        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
            let mediaSetStored = uploadedMediaSetCopy?.uploadedFiles?.concat(mediaSet)
            const updatedMedia =[...this.props.editExistingMedia,...mediaSetStored]
            const mediaSetUnique = [...new Map(updatedMedia?.map(item =>
                [item[key], item])).values()];            
            this.setState(prevState => ({
                uploadedMediaSet: {
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:  mediaSetUnique
                   }
            }), () => {
                this.props.get_uploaded_media(uploadedMediaSet);                
            })
        }
        if (links && Array.isArray(links) && links.length > 0) {
            let linksStored = uploadedMediaSetCopy?.embedLinks?.concat(links)            
            this.setState(prevState => ({
                uploadedMediaSet: {
                    ...prevState.uploadedMediaSet,
                    embedLinks: linksStored
                }
            }), () => {
                this.props.get_uploaded_media(uploadedMediaSet);
            })
        }
    }

    handleOnClickUploadModalOk = () => {
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }

    removeUploadedMediaSet = (id)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSet:{
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:this.state.uploadedMediaSet?.uploadedFiles?.filter(item=>item.id!==id)
                }
            }
        })
    }

    render() {
        let {
            categories,
            listingItems,
            collageItems,
            isEnableFileUpload,
            isLoadingCategories,
            categoryName,
            categoryId,
            toggleShowSubCategories
        } = this.state;
        let collage_items = [];
        if (collageItems?.results && Array.isArray(collageItems?.results) && collageItems?.results?.length) {
            collage_items = collageItems?.results
        }

        let categoryListingItems = categories;
        return (
            <div>
                <HelmetMetaData
                    title={getSubCategoryTitle("Business", categoryName)}
                    description={getSubCategoryDescription("Business", categoryName)}
                />

                {this.state.isEnableFileUpload ?
                    <UploadMedia
                        getUploadedMedia={this.getUploadedMedia}
                        uploadedUrl={this.state.uploadMediaUrl}
                        hideEmbed={this.state.hideEmbed}
                        callbackFunction={this.handleOnClickUploadModalOk}
                        noCategory={this.state.discussionTab}
                        typeUpload={this.state.typeupload}
                    />
                    : null}
                <AppHeader isEnableCover={false} />
                <header className="nw-header-min-height" style={{ overflowY: 'hidden' }}>
                    {collage_items && collage_items?.length >= 6 ?
                        <Row noGutters xs={1} md={4}>
                            <Col className="d-flex flex-column">
                                <Link
                                    to={{
                                        pathname: `/business/${collage_items[0]?.id}`,
                                        state: { id: collage_items[0]?.id }
                                    }}>
                                    <div className="flex-grow-1 featured-item">

                                        <img className="feature-project-image _full" src={collage_items[0]?.listing_profileimage && collage_items[0]?.listing_profileimage[0]?.location} alt={collage_items[0]?.name} />
                                        <div className="featured-details hoverable">
										<h2 className="fs-25 text-uppercase">{collage_items[0]?.name}</h2>
											
                                            <div className="featured-item-credit" hidden>
                                                by <span>{collage_items[0]?.manufacturer}</span>
                                            </div>
                                            <div className="mt-2">
                                                <img title={collage_items[0]?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[0]?.total_rating?.avg_rating[0]}`} alt={collage_items[0]?.total_rating?.avg_rating[1]} />
                                            </div>

                                            <div className="fs-13 hover-appear mt-2">
                                                <div>
                                                    {collage_items[0]?.latestreview?.review
                                                        ?
                                                        <span>
                                                            <i className="icon-quote-left"></i>
                                                            {collage_items[0]?.latestreview?.review.length < 60 ?
                                                                (collage_items[0]?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
                                                                (collage_items[0]?.latestreview?.review.substring(0, 60) + '...').replace(/<\/?[^>]+(>|$)/g, "")
                                                            }
                                                            <i className="icon-quote-right"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <Link
                                                                to={{
                                                                    pathname: `/${collage_items[0] && (collage_items[0]?.content_type).toLowerCase()}/${collage_items[0] && collage_items[0].id ? collage_items[0].id : null}`,
                                                                    state: { id: collage_items[0]?.id }
                                                                }} >Be the first one to review.</Link>
                                                        </span>

                                                    }
                                                </div>

                                                <div className="text-right" hidden>
                                                    <span className="d-inline-block mr-2">{collage_items[0]?.latest_review?.created_by}</span>
                                                    <img title="4.9" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/5rating.png" alt="4.9 Rating" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="d-flex flex-column">
                                {collage_items && collage_items.slice(1, 3).map((item,index) =>
                                    (
                                        <Link
                                            to={{
                                                pathname: `/business/${item?.id}`,
                                                state: { id: item?.id }
                                            }}
											key={index}
                                        >
                                            <div className="flex-grow-1 featured-item">
                                                <img className="feature-project-image _half" src={item?.listing_profileimage[0]?.location} alt={item?.name} />
                                                <div className="featured-details hoverable">
                                                    <Link
                                                        to={{
                                                            pathname: `/business/${item?.id}`,
                                                            state: { id: item?.id }
                                                        }}
                                                        className="text-reset mb-2 d-block text-decoration-none">
                                                        <h2 className="fs-25 text-uppercase">{item?.name}</h2>
                                                    </Link>
                                                    <div className="featured-item-credit" hidden>
                                                        by <span>{item?.manufacturer}</span>
                                                    </div>
                                                    <div className="mt-2">
                                                        <img title={item?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.total_rating?.avg_rating[0] || "norating.png"}`}
                                                            onError={(error) =>
                                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/star/white/norating.png`)
                                                            }
                                                            alt={item?.total_rating?.avg_rating[1]} />
                                                    </div>

                                                    <div className="fs-13 hover-appear mt-2">
                                                        <div>
                                                            {item?.latestreview?.review
                                                                ?
                                                                <span>
                                                                    <i className="icon-quote-left"></i>
                                                                    {item?.latestreview?.review.length < 60 ?
                                                                        (item?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
                                                                        (item?.latestreview?.review.substring(0, 60) + '...').replace(/<\/?[^>]+(>|$)/g, "")
                                                                    }
                                                                    <i className="icon-quote-right"></i>
                                                                </span>
                                                                :
                                                                <span>
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/${item && (item?.content_type).toLowerCase()}/${item && item.id ? item.id : null}`,
                                                                            state: { id: item?.id }
                                                                        }} >Be the first one to review.</Link>
                                                                </span>

                                                            }
                                                        </div>

                                                        <div className="text-right" hidden>
                                                            <span className="d-inline-block mr-2">{item?.latest_review?.created_by}</span>
                                                            <img title="4.9" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/5rating.png" alt="4.9 Rating" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Link>
                                    ))}
                            </Col>
                            <Col className="d-flex flex-column">
                                <Link
                                    to={{
                                        pathname: `/business/${collage_items[3]?.id}`,
                                        state: { id: collage_items[3]?.id }
                                    }}
                                >
                                    <div className="flex-grow-1 featured-item">
                                        <img className="feature-project-image _full" src={collage_items[3]?.listing_profileimage && collage_items[3]?.listing_profileimage[0]?.location} alt={collage_items[3]?.name} />
                                        <div className="featured-details hoverable">
                                            <Link
                                                to={{
                                                    pathname: `/business/${collage_items[3]?.id}`,
                                                    state: { id: collage_items[3]?.id }
                                                }}
                                                className="text-reset mb-2 d-block text-decoration-none">
                                                <h2 className="fs-25 text-uppercase">{collage_items[3]?.name}</h2>
                                            </Link>
                                            <div className="featured-item-credit" hidden>
                                                by <span>{collage_items[3]?.manufacturer}</span>
                                            </div>
                                            <div className="mt-2">
                                                <img title={collage_items[3]?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[3]?.total_rating?.avg_rating[0] || "norating.png"}`}
                                                    onError={(error) =>
                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/star/white/norating.png`)
                                                    } alt={collage_items[3]?.total_rating?.avg_rating[1]} />
                                            </div>

                                            <div className="fs-13 hover-appear mt-2">
                                                <div>
                                                    {collage_items[3]?.latestreview?.review
                                                        ?
                                                        <span>
                                                            <i className="icon-quote-left"></i>
                                                            {collage_items[3]?.latestreview?.review.length < 60 ?
                                                                (collage_items[3]?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
                                                                (collage_items[3]?.latestreview?.review.substring(0, 60) + '...').replace(/<\/?[^>]+(>|$)/g, "")
                                                                //ReactHtmlParser(collage_items[3]?.latestreview?.review.substring(0, 60)+ '...')
                                                            }
                                                            <i className="icon-quote-right"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <Link
                                                                to={{
                                                                    pathname: `/${collage_items[3] && (collage_items[3]?.content_type).toLowerCase()}/${collage_items[3] && collage_items[3].id ? collage_items[3].id : null}`,
                                                                    state: { id: collage_items[3]?.id }
                                                                }} >Be the first one to review.</Link>
                                                        </span>

                                                    }
                                                </div>

                                                <div className="text-right" hidden>
                                                    <span className="d-inline-block mr-2">{collage_items[0]?.latest_review?.created_by}</span>
                                                    <img title="4.9" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/5rating.png" alt="4.9 Rating" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="d-flex flex-column">
                                {collage_items && collage_items.slice(4, 6).map((item,index) =>
                                    (
                                        <Link
                                            to={{
                                                pathname: `/business/${item?.id}`,
                                                state: { id: item?.id }
                                            }}
											key={index}
                                        >
                                            <div className="flex-grow-1 featured-item">
                                                <img className="feature-project-image _half" src={item?.listing_profileimage[0]?.location} alt={item?.name} />
                                                <div className="featured-details hoverable">
                                                    <Link
                                                        to={{
                                                            pathname: `/business/${item?.id}`,
                                                            state: { id: item?.id }
                                                        }}
                                                        className="text-reset mb-2 d-block text-decoration-none">
                                                        <h2 className="fs-25 text-uppercase">{item?.name}</h2>
                                                    </Link>
                                                    <div className="featured-item-credit" hidden>
                                                        by <span>{item?.manufacturer}</span>
                                                    </div>
                                                    <div className="mt-2">
                                                        <img title={item?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.total_rating?.avg_rating[0] || "norating.png"}`}
                                                            onError={(error) =>
                                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/star/white/norating.png`)
                                                            } alt={item?.total_rating?.avg_rating[1]} />
                                                    </div>

                                                    <div className="fs-13 hover-appear mt-2">
                                                        <div>
                                                            {item?.latestreview?.review
                                                                ?
                                                                <span>
                                                                    <i className="icon-quote-left"></i>
                                                                    {item?.latestreview?.review.length < 60 ?
                                                                        (item?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
                                                                        (item?.latestreview?.review.substring(0, 60) + '...').replace(/<\/?[^>]+(>|$)/g, "")
                                                                    }
                                                                    <i className="icon-quote-right"></i>
                                                                </span>
                                                                :
                                                                <span>
                                                                    <Link
                                                                        to={{
                                                                            pathname: `/${item && (item?.content_type).toLowerCase()}/${item && item.id ? item.id : null}`,
                                                                            state: { id: item?.id }
                                                                        }} >Be the first one to review.</Link>
                                                                </span>

                                                            }
                                                        </div>

                                                        <div className="text-right" hidden>
                                                            <span className="d-inline-block mr-2">{item?.latest_review?.created_by}</span>
                                                            <img title="4.9" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/5rating.png" alt="4.9 Rating" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Link>
                                    ))}
                            </Col>

                        </Row>
                        :
                        ''
                    }
                </header>

                <section className="position-relative" style={{ zIndex: 3, marginTop: '-40px' }}>
                    <Container>
                        <div>
                            <Row className="align-items-end">
                                <Col xs="auto">
                                    <Link
                                        onClick={(e) => this.goToPreviousCategory(e)}
										to="/business/category"
                                        className="shade-link d-inline-block _black _padded">
                                        {'Back to Category'}
                                    </Link>
                                </Col>
                                <Col>
                                    <div className="text-center text-white black-shade mx-auto" style={{ maxWidth: '500px' }}>
                                        <h1 className="p-0 m-0 fs-34">{this.state.categoryName}</h1>
                                    </div>
                                </Col>
                                <Col xs="auto">
                                    <Link
                                        to={{
                                            pathname: `/business/${this.state.categoryName ? this.state.categoryName : this.props.history?.location?.pathname.split("/")[2]}/buyers-guide`,
                                            state: { taxonomyId: categoryId }
                                        }}
                                        className="btn btn-primary fs-16"
                                    >See Buyer's Guide</Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>

                {/* Main Content Starts */}
                <section className="py-4 fs-14">
                    <Container>
                        <Row>
                            <Col md={2}>
                                <div className="mb-5">
                                    <h2 className="fs-36 text-tertiary"> Categories</h2>
                                    <hr className="bg-tertiary mb-2" />

                                    <div>
                                        <ul className="list-unstyled wk-loader-new-spinner position-relative">
                                            {
                                                isLoadingCategories &&
                                                <div className="text-center py-3 spinner">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            }
                                            {categoryListingItems && Array.isArray(categoryListingItems) && categoryListingItems.length ?
                                                categoryListingItems.map((item,index)=> (
                                                    <li className="py-2 border-bottom" key={index}>
                                                        <div className="d-flex align-items-start">
                                                            <Link
                                                                to={{
                                                                    pathname: `${'/business-category/' + item.seo_label?.split(/business-reviews\//)?.[1] || item.id}`,
                                                                    state: {
                                                                        id: item.id,
                                                                        parent_category: "business",
                                                                        child_category: item?.category
                                                                    }
                                                                }}
                                                               className="p-0 text-dark font-weight-bold ff-base text-left mr-2"
                                                                >
                                                            {item?.category}
                                                            </Link>
                                                            {item?.children_count > 0 && (
                                                                <Button
                                                                    onClick={() => {
                                                                        if (toggleShowSubCategories[item.id]) {
                                                                            toggleShowSubCategories[item.id] = false
                                                                            let { childItems } = this.state;
                                                                            delete childItems[item?.id];
                                                                            this.setState({
                                                                                childItems 
                                                                            })
                                                                        } else {
                                                                            toggleShowSubCategories[item.id] = true
                                                                            this.getSubCategories({ item })
                                                                        }
                                                                        this.setState({ toggleShowSubCategories })
                                                                    }}
                                                                    size="sm" color="transparent" className="ml-auto text-dark px-2 py-0">
                                                                    {toggleShowSubCategories[item.id] ? <FontAwesomeIcon icon="angle-up" /> : <FontAwesomeIcon icon="angle-down" />}
                                                                </Button>
                                                            )}
                                                        </div>
                                                        {this.renderChildItems({ id: item?.id })}
                                                    </li>
                                                ))
                                                : !isLoadingCategories &&
                                                <h5>{'No Subcategory to display'}</h5>
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2 text-dark">Star Rating</div>
                                    {[5, 4, 3, 2, 1, 0].map((item,index) => (
                                        <FormGroup key={index} className="mb-1" check>
                                            <Label className="text-dark ff-alt font-weight-normal" check>
                                                <Input
                                                    onChange={() => {
                                                        this.setState({ currentPageIndex: 1, stars: item, starRatingApplied: true, listingItems:[] }, () => {
                                                            this.fetchStarRatingItems();
                                                            window.scrollTo(0, 0);
                                                        })
                                                    }}
                                                    type="radio" name="filterByStarRating" className='star-filter-input-checkbox' />{' '}
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        className="mr-1"
                                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star_${item+1}.png`}
                                                        alt={`${item} Rating`}
                                                    />                                                   
                                                    <span>{item === 5 ? " only" : " & up"}</span>
                                                </div>
                                            </Label>
                                        </FormGroup>
                                    ))}
                                    <hr className="bg-tertiary" />
                                </div>

                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2 text-dark">Price</div>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" />{' '}
                                            $
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" />{' '}
                                            $$
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" />{' '}
                                            $$$
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" />{' '}
                                            $$$$
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="checkbox" name="filterByPrice" />{' '}
                                            $$$$$
                                        </Label>
                                    </FormGroup>
                                    <hr className="bg-tertiary" />
                                </div>

                                <div>
                                    <div className="bg-white p-2">
                                        <a rel="noopener noreferrer" href="http://wikireviews.com/blog" target="_blank" className="text-reset">
                                            <img className="w-100 blog-img" src={"https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_05.png"} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Nav className="active-transparent non-active-normal active-bold hover-none" tabs>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base h-100"
                                            active={this.state.viewBusinessType === "all"}
                                            onClick={() => {
                                                this.handleChnageTab("all");
                                            }}>
                                            All
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base h-100"
                                            active={this.state.viewBusinessType === "friendsPick"}
                                            onClick={() => {
                                                this.setState({ viewBusinessType: "friendsPick", listingItems: null, currentPageIndex: 1, stars: null, starRatingApplied: false }, () => {
                                                    this.fetchListingItem();
                                                });
                                            }}>
                                            Friends Pick
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base h-100"
                                            active={this.state.viewBusinessType === "recommended"}
                                            onClick={() => {
                                                this.handleChnageTab('recommended');
                                            }}>
                                            Highly Recommended
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.viewBusinessType}>
                                    <TabPane tabId="all">
                                        <div>
                                            {this.renderItems()}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="friendsPick">
                                        <div>
                                            {this.renderItems()}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="recommended">
                                        <div>
                                            {this.renderItems()}
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                            <Col md={4}>
                                {/* <MyConnectionsActivity category="business" subCategoryId={this.state.categoryId} /> */}
                                <UserFeedsRightPanel category="business"  sub_category={this.state.categoryId} mainCategory="business" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        )
    }
}

const mapState = (state) => ({
    this_user: state.user.my_profile,
    editExistingMedia : state.shared.editExistingMedia,
    current_location: state.home.current_location,
});

const mapProps = (dispatch) => ({
    add_to_list: (url, data, message, nextUrl) => dispatch(add_to_list(url, data, message, nextUrl)),
    remove_from_list: (url, message, nextUrl) => dispatch(remove_from_list(url, message, nextUrl)),
    get_uploaded_media: (data) => dispatch(get_uploaded_media(data))
});

export default connect(mapState, mapProps)(BusinessCategory);
