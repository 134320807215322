/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { add_tempLocation, fetch_collage_items_city } from '../../actions/home';

class PlacementAutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: "",
            lat: "",
            lng: "",
        }
        this.wrapperRef = React.createRef();
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = async (address) => {
        let city = '';
        let county = '';
        let state = '';
        let country = '';
        // let locality2 = '';

        const result = await geocodeByAddress(address);
        result && result[0] && result[0].address_components.map((addr) => {
            if (addr.types[0] === "locality") {
                //city name
                city = addr.long_name
            } else if (addr.types[0] === "administrative_area_level_2") {
                //county name
                county = addr.long_name
            } else if (addr.types[0] === "administrative_area_level_1") {
                //state name
                state = addr.short_name
            } else if (addr.types[0] === "country") {
                //state name
                country = addr.short_name
            }
        })
        const latlng = await getLatLng(result[0]);
        await this.setState({
            address: address,
            lat: latlng.lat,
            lng: latlng.lng,
        })

        const data = {
            "city": city,
            "county": county,
            "state": state,
            "country": country,
            "locality2": "",
            "address_text": address,
            "lat": this.state.lat,
            "lng": this.state.lng
        }
        await this.props.add_tempLocation(data);
        this.props.handleEnabelSearch();
        const { search_name, content_type} = this.props;
        if (search_name && address) {
            this.props.get_search_home(search_name, content_type);
        } 
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.handleEnabelSearch();
            this.props.fetch_collage_items_city({ type: 'City' });
        }
    }

    render() {
        const searchOptions = {
            componentRestrictions: { country: ['us', 'ca'] },
            types: ['(regions)']
        };
        return (
            <div style={{ backgroundColor: 'red' }} ref={this.wrapperRef}>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    searchOptions={searchOptions}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className="position-relative" style={{zIndex: 1}}>
                            <input
                                {...getInputProps({
                                    placeholder: `${this.props.current_location ? this.props.current_location : `CITY,STATE,COUNTRY` }`,
                                    className: 'location-search-input fs-16 primary form-control',
                                })}
                            />
                            <div className="autocomplete-dropdown-container position-absolute text-left">
                                {loading && <div className="bg-white fs-14 p-2">Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <FontAwesomeIcon className="mr-2" fixedWidth icon="map-marker-alt" />
                                            <span>{!isNaN(parseInt(suggestion?.formattedSuggestion?.mainText)) ? 
                                             `${suggestion?.formattedSuggestion?.mainText}, ${suggestion?.formattedSuggestion?.secondaryText}`
                                              :`${suggestion?.description}`}
                                              </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
        )
    }
}

const mapProps = (dispatch) => ({
    add_tempLocation: (data) => dispatch(add_tempLocation(data)),
    fetch_collage_items_city: (data) => dispatch(fetch_collage_items_city(data)),
})

export default connect(null, mapProps)(PlacementAutoComplete);