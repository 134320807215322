import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { connect } from 'react-redux';
import CollapseBasic from '../../atoms/collapse';
import { get_buyers_talks} from '../../../actions/buyersGuide';
import { get_discuss_this_page } from '../../../actions/business';
import { callApi } from "../../../utils/apiCaller";
import { toast } from "react-toastify";
import {
    Button, Col,
    FormGroup,
    Input, Modal, ModalBody,
    ModalFooter, ModalHeader,
    Row
} from 'reactstrap';

class DiscussThisPage extends Component {
    constructor(props) {
        super(props)
        this.enableItems = false;
        this.enableFetch = false;
        this.state = {
            createNewDisModalToggle: false,
            discussionList: {},
            mediaSet: [],
            titleText: '',
            descriptionText: ''
        }
    }

    componentDidMount() {
		this.props.get_buyers_talks();
    }

    componentDidUpdate() {

      
        let { mediaSet, discussionList } = this.state;
        let nextProps = this.props;
        let get_buyersTalks = nextProps?.get_buyersTalks;
        if (get_buyersTalks?.results?.length !== discussionList?.length) {
            this.setState({ discussionList: nextProps?.get_buyersTalks?.results });
        }
        let uploadedFiles = nextProps?.uploadedMediaSet?.uploadedFiles;
        if (uploadedFiles?.length !== mediaSet?.length) {
            this.setState({ mediaSet: uploadedFiles });
        }

        // if(uploadedMediaSet.length !== uploadedFiles.length){
        //     this.setState({ uploadedFiles: [...uploadedMediaSet]});
        // }


    }

    createNewDicussion = async () => {
        let { titleText, descriptionText, mediaSet } = this.state;
       
        let { id, profdiscussion } = this.props;
        let mediaItems = [];

        if (mediaSet?.length) {
            mediaItems = mediaSet.map(data => data.id);
        }

       // let url = `/api/${profdiscussion ? 'profdiscussion' : 'buyers-discussion'}/`;
        let url = `/api/${profdiscussion ? 'profdiscussion' : 'buyerstalk'}/`;
    
       // let discussion_url =  `/api/buyers-discussion/?entity=${id}&page=1`;
        let discussion_url =  `/api/buyerstalk/?category=${id}&page=1`;
        let data = {
            "title": titleText,
            "body": descriptionText,
            //"user_entry": id,
            "related_taxonomy": id,
        }

        if (!profdiscussion) {
            data["messagemedia_set"] = mediaItems;
        } else {
            data["professionalmessagemedia_set"] = mediaItems;
        }

        try {
            let response = await callApi(url, 'POST', data);
            if (response && response.code !== 500) {
                toast(`Discussion added successfully!`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                });
                //await this.props.get_discuss_this_page({ id: data.user_entry, pageId: 1 });
                await this.props.get_buyers_talks(discussion_url);
                await this.props.resetUploadedMediaSet();
                await this.setState({
                    uploadMedia: {
                        ...this.state.uploadMedia,
                        selectedMedia: [],
                        selectedMediaIds: [],
                        uploadFiles: [],
                        uploadedFiles: []
                    },
                    newDiscussion: {
                        title: '',
                        body: '',
                        errors: {}
                    },
                    createNewDisModalToggle: false,
                    uploadedFiles: []
                });

                await this.props.setStateOfDiscussion( 'discussion');
                
               

            }
        } catch (err) {
            console.log(err);
        }
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const {my_profile_data} = this.props;
        if(my_profile_data && my_profile_data.id && authId && (authId == my_profile_data.id)){
            
            return true;
        }else{
            return false;
        }
    }

    truncate = (filenameString) => {
        // let split = filenameString.split(".");
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };


    

    render() {
     
        let { discussionList, mediaSet, titleText, descriptionText } = this.state;
        const { isLight } = this.props;
        return (
            <React.Fragment>
                <div className={isLight ? "bg-white-shaded-md text-secondary mb-3" : "bg-movie-dark text-white mb-3"}>
                    <CollapseBasic
                        title="Discuss This Page"
                        containerClass={isLight ? "bg-transparent type-secondary" : "bg-transparent type-white"}
                        bodyClass={isLight ? "mt-2 text-secondary-dark" : "mt-2 text-white"}
                        isOpen={true}>
                        <ul className="list-unstyled">
                            {discussionList &&
                                Array.isArray(discussionList) &&
                                discussionList?.length ?
                                discussionList.map((item, index_item_discuss) => (
                                    <li
                                        key={index_item_discuss}
                                        className="ff-base">
                                        <div className="font-weight-bold wb-break-word">
                                            <a href="#" className="text-reset font-weight-bold">{item?.sender}</a>
                                        </div>
                                        <div>
                                            {`Created new discussion`} <a href="#" className="text-reset font-weight-bold"></a>
                                            for article <span className="article-name">{item?.article}</span> {item?.sent_at}
                                            
                                        </div>
                                        <hr className="bg-light" />
                                    </li>
                                ))
                                : `No Record Found`}
                        </ul>
                        <div className="text-right">
                            <Button
                                color="link"
                                className={isLight ? "p-0 text-secondary-dark font-weight-bold" : "p-0 text-white font-weight-bold"}
                                onClick={() => {
                                    if(!this.checkIfLoggedIn()){
                                        this.props.forceToggleLoginModal();
                                        return false;
                                    }
                                    this.setState({ createNewDisModalToggle: true, titleText: '', descriptionText: '' });
                                    this.props.getUploadedMedia({ mediaSet: [] });
                                    this.enableItems = false;
                                    this.props.resetUploadedMediaSet()
                                }}>
                                {'Create a New Discussion'}
                            </Button>
                        </div>
                    </CollapseBasic>
                </div>
                {/* Create a new discussion Modal */}

                < Modal
                    isOpen={this.state.createNewDisModalToggle}
                    toggle={() => this.setState({ createNewDisModalToggle: !this.state.createNewDisModalToggle })}>
                    <ModalHeader
                        className="px-0"
                        toggle={() => this.setState({ createNewDisModalToggle: !this.state.createNewDisModalToggle })}>
                        {'CREATE NEW DISCUSSION'}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input
                                onChange={(e) => { this.setState({ titleText: e.target.value }) }}
                                value={titleText}
                                className="border text-dark ph-grey"
                                bsSize="sm"
                                type="text"
                                placeholder="Enter title for discussion" />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                onChange={(e) => { this.setState({ descriptionText: e.target.value }) }}
                                value={descriptionText}
                                className="border text-dark ph-grey font-weight-bold"
                                bsSize="sm"
                                type="textarea"
                                rows="10" />
                        </FormGroup>
                        <div>
                            <Button
                                onClick={() => {
                                    this.setState({ mediaSet: [] }, () => {
                                        this.props.getUploadedMedia({ mediaSet: [] });
                                        this.props.toggleEnableFileUpload();
                                        this.enableItems = false;
										this.props.getnocategory("dicussionTab");
                                    })
                                }}
                                color="transparent"
                                size="sm"
                                title="Upload Media">
                                <FontAwesomeIcon icon="camera" />
                            </Button>
                        </div>

                        {/* Uploaded media shown here */}
                        {this.state.mediaSet && Array.isArray(this.state.mediaSet) && this.state.mediaSet.length > 0 &&
                        
                        <div className="border mb-3">
                            <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                {
                                        this.state.mediaSet.map((item, index) => {
                                      
                                        return (
                                            <Col key={item.id}>
                                                <div className="d-flex pr-3 m-2">
                                                    <div>
                                                        {(item.type === "image" ||
                                                            item.media_type === "image") && (
                                                                <div>
                                                                    <img
                                                                        className="img-fluid img-thumbnail"
                                                                        src={item.file ? item.file : item.url}
                                                                        alt={
                                                                            item.filename
                                                                                ? item.filename.length < 20
                                                                                    ? item.filename
                                                                                    : this.truncate(item.filename)
                                                                                : item.name
                                                                                    ? item.name.length < 20
                                                                                        ? item.name
                                                                                        : this.truncate(item.name)
                                                                                    : ""
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        {(item.type === "video" ||
                                                            item.media_type === "video") && (
                                                                <div>
                                                                    <video width="100" height="100">
                                                                        <source src={item.file ? item.file : item.url} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                    </video>
                                                                </div>
                                                            )}
                                                    </div>
            
                                                    <div className="mx-n3 mt-2">
                                                    <Button
                                                            onClick={() => {
                                                                let { mediaSet } = this.state;
                                                                mediaSet.splice(index, 1);
                                                                this.setState({ mediaSet });
                                                            }}
                                                            color="delete-media _alt"
                                                            size="sm"
                                                            title="Remove Media">
                                                            <FontAwesomeIcon
                                                                icon="trash-alt" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                            </Row>
                        </div>
                        }
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <Button
                            disabled={(titleText?.length && descriptionText?.length) ? false : true}
                            onClick={() => { this.createNewDicussion() }}
                            color="primary">Submit</Button>
                    </ModalFooter>
                </Modal >
           
            </React.Fragment>
        )
    }
}

const mapState = (state) => ({   
        get_buyersTalks: state.buyersGuide.get_buyersTalks,
        my_profile_data: state.user.my_profile,

});

const mapDispatch = (dispatch) => ({
    get_buyers_talks: (url) => dispatch(get_buyers_talks(url)),
    get_discuss_this_page: (data) => dispatch(get_discuss_this_page(data)),
})

export default connect(mapState, mapDispatch)(DiscussThisPage);

