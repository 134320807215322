import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledDropdown } from 'reactstrap';
import CollapseBasic from '../../atoms/collapse';
import { callApi } from '../../../utils/apiCaller';
import { Chart } from "react-google-charts";
import { API_URL } from '../../../utils/constants';



export default class DetailedStarRating extends Component {
    constructor(props) {
        super(props)

        this.state = {
            genderAgeRating: {},
            detailStarRating: {},
            sortByData: {},
            graphType: 'Overall Rating',
            totalRating: props?.total_rating,
            lineChartResponse: {},
            barChartResponse: {},
            dropdownSortBy: false,
        }
    }

    componentDidUpdate() {
        let nextProps = this.props;
        let { totalRating } = this.state;
        if (nextProps?.total_rating && !totalRating) {
            this.setState({ totalRating: nextProps?.total_rating });
        }
    }

    componentDidMount() {
        let props = this.props;
        this.getTrendingStarRating();
        this.getTrendingGraph();
        this.getBarChartData();
        this.getLineChartData();
        if (props?.listing_type !== "products") {
            this.getGenderAgeRating();
        }
    }

    getGenderAgeRating = () => {
        let { id } = this.props;
        try {
            fetch(`${API_URL}/get_gender_age_rating/?listing_id=${id}`).then((response) => response.json()).then((response) => {
                if (response && response.code !== 500) {
                    this.setState({ genderAgeRating: response })
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    getTrendingStarRating = async () => {
        let { id } = this.props;
        try {
            let response = await callApi(`/api/detailedstarrating/?entity=${id}`, 'GET');

            if (response && response.code !== 500) {
                this.setState({ detailStarRating: response })
            }
        } catch (err) {
            console.log(err);
        }
    }

    getTrendingGraph = async () => {
        let { id } = this.props;
        let { graphType } = this.state;
        try {
            let response = await callApi(`/get_trending_graph/?key=${encodeURIComponent(graphType)}&listing_id=${id}`, 'GET');;
            if (response && response.code !== 500) {
                this.setState({ sortByData: response });
            }
        } catch (err) {
            console.log(err);
        }
    }

    renderDropDownItem = ({ title }) => {
        return (
            <DropdownItem
                onClick={() => this.setState({ graphType: title }, () => { this.getTrendingGraph(); })}
                className="fs-14">
                {title}
            </DropdownItem>
        )
    };

    renderItemsStoryLine = ({ mainTitle, title }) => (
        <div className="font-weight-bold mb-2">
            <span
                className={this.props?.bgLight ?
                    "text-secondary-dark  mr-1" :
                    "text-tertiary mr-1"}>
                {mainTitle}
            </span>
            {title}
        </div>
    )

    renderTableItems = ({ title, first, second }) => (
        <tr>
            <th className="border-0 text-center" scope="row">{title}</th>
            <td className="text-center">{first}</td>
            <td className="text-center">{second}</td>
        </tr>
    )

    getBarChartData = async () => {
        const { id } = this.props;
        try {
            let url = `/api/professionaldetailsstarrating/?id=${id}`;
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {
                this.setState({
                    barChartResponse: response
                })
            }
        } catch (err) {
            console.log({ err });
        }
    }


    getLineChartData = async () => {
        const { id } = this.props;
        try {
            let url = `/get_trending_graph/?key=avg_rating&listing_id=${id}&professional=true`;
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {
                this.setState({
                    lineChartResponse: response
                })
            }
        } catch (err) {
            console.log({ err });
        }
    }

    toggleCategory(item) {
        this.setState(prevState => ({
            [item]: !prevState[item]
        }));
    }

    onMouseEnterCategory(item) {
        this.setState({ [item]: true });
    }

    onMouseLeaveCategory(item) {
        this.setState({ [item]: false });
    }



    render() {
        let { genderAgeRating, graphType, detailStarRating, totalRating, lineChartResponse, barChartResponse } = this.state;
        const { bgLight, listing_type } = this.props;
        let selected_key = '';
        let sortedKeys = Object.keys(genderAgeRating).sort();
        let filteredItems = [];
        sortedKeys.forEach(item => {
            let value = genderAgeRating[item];
            if (value && Array.isArray(value) && value.length) {
                let filteredValues = value.filter((dt, index) => {
                    if (index > 0) {
                        if (dt !== 'null') {
                            return dt;
                        }
                    }
                })
                filteredItems.push({ key: value[0], value: filteredValues })
            }
        });

        if (graphType == 'Overall Rating') {
            selected_key = 'avg_rating'
        } else {
            selected_key = graphType;
        }

        return (
            <div
                className={bgLight ?
                    "bg-white-shaded-md text-secondary mb-3" :
                    "bg-movie-dark text-white mb-3"}>
                <CollapseBasic
                    title="Detailed Star Rating"
                    containerClass={bgLight ? "bg-transparent type-secondary" : "bg-transparent type-white"}
                    bodyClass={bgLight ? "text-secondary" : "mt-2 text-white"}
                    isOpen={false}>
                    <div className="text-right">
                        <UncontrolledDropdown onMouseOver={() => this.onMouseEnterCategory('dropdownSortBy')}
                            onMouseLeave={() => this.onMouseLeaveCategory('dropdownSortBy')}
                            isOpen={this.state.dropdownSortBy}
                            toggle={() => this.toggleCategory('dropdownSortBy')}>
                            <DropdownToggle
                                className={bgLight ?
                                    "text-secondary-dark bg-transparent font-weight-bold" :
                                    "text-white bg-transparent font-weight-bold"}
                                color="transparent"
                                size="sm" caret>
                                <span className="pr-2">
                                    {'Sort By'}
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="type-secondary _alt _bold wk-sort-dropdown-new">
                                {totalRating !== null && Object.keys(totalRating).length ?
                                    <React.Fragment>
                                        {this.renderDropDownItem({ title: 'Overall Rating' })}
                                    </React.Fragment>
                                    : null}

                                {totalRating !== null && Object.keys(totalRating).length ?
                                    Object.keys(totalRating).map((item, rating_index) => {
                                        return (item !== "total_review" && item !== "avg_rating") ?
                                            <React.Fragment key={rating_index}>
                                                {this.renderDropDownItem({ title: item })}
                                            </React.Fragment>
                                            :
                                            null;
                                    })
                                    : null}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>

                    <div>
                        {this.renderItemsStoryLine({ mainTitle: graphType, title: 'Average Rating' })}
                        {totalRating !== null && totalRating[selected_key] ? (
                            <img
                                src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${totalRating[selected_key][0]}`}
                                alt={totalRating[selected_key][1]}
                            />
                        ) : null}
                        <hr className="mt-1" style={{ borderColor: '#a4a4a3' }} />
                    </div>
                    <div>
                        {this.renderItemsStoryLine({ mainTitle: graphType, title: 'Rating Distribution' })}
                        <hr className="mt-1" style={{ borderColor: '#a4a4a3' }} />
                        {/* Chart Area */}
                        {/* <div className="p-4 display-4 text-center">Chart Area</div>
                        <hr className="bg-light" /> */}
                    </div>

                    {listing_type == "movies" && Object.keys(filteredItems).length ?
                        <div>
                            <div className="text-center font-weight-bold mb-3">
                                {'Average Ratings Grouped by Age & Gender'}
                                <br />
                                {'(Number of Reviews)'}
                            </div>
                            <Table
                                className={bgLight ?
                                    "text-secondary border-0 ff-alt" :
                                    "text-white border-0 ff-alt"}
                                size="sm"
                                bordered
                                style={{ width: '240px' }}>
                                <thead>
                                    <tr>
                                        <th className="border-0 text-center">{' '}</th>
                                        <th className="border-0 text-center">{'Male'}</th>
                                        <th className="border-0 text-center">{'Female'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredItems.map(item => {
                                        let first_value = '';
                                        let second_value = '';
                                        let total_value = '';
                                        if (item?.key && Array.isArray(item?.value) && item?.key?.length) {
                                            let array_value = item?.value;
                                            let array_total = item?.key
                                            total_value = array_total || array_total;
                                            first_value = array_value[0] || '';
                                            second_value = array_value[1] || '';
                                        }
                                        return this.renderTableItems({ title: total_value, first: first_value, second: second_value })
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        : <hr className="mt-1 bg-light" />}

                    {barChartResponse[graphType] && (

                        <Chart
                            chartType="BarChart"
                            loader={<div>Loading Chart</div>}
                            data={barChartResponse[graphType]}
                            options={{
                                axesDefaults:
                                {
                                    useSeriesColor: true,
                                    min: 1,
                                    tickInterval: 1,
                                    tickOptions: {
                                        formatString: '%d'
                                    }
                                },
                                'width': 300,
                                'height': 250,
                                chartArea: { width: '70%' },
                                hAxis: {
                                    viewWindow: {
                                        min: 0, max: 20
                                    },
                                    title: 'Number of Reviews',

                                    gridlines: { color: '#FFFFFF', count: 4 },
                                    format: 'decimal'
                                },
                                vAxis: {
                                    viewWindow: {
                                        min: 0
                                    },
                                    ticks: [
                                        { v: 0, f: '0 Star' },
                                        { v: 1, f: '1 Star' },
                                        { v: 2, f: '2 Stars' },
                                        { v: 3, f: '3 Stars' },
                                        { v: 4, f: '4 Stars' },
                                        { v: 5, f: '5 Stars' }],
                                    title: 'Rating',

                                    gridlines: { color: '#FFFFFF' }
                                },
                                legend: { position: "none" },
                                isStacked: true,
                                annotation: {
                                    fontsize: 2
                                },
                                colors: ['#d1bb78', '#726647']
                            }}
                            // For tests
                            rootProps={{ 'data-testid': '1' }}
                        />
                    )}

                    {lineChartResponse[graphType] && (
                        <Chart
                            chartType="LineChart"
                            data={lineChartResponse[graphType]}
                            options={{
                                axesDefaults:
                                {
                                    useSeriesColor: true,
                                    min: 4,
                                    tickInterval: 2,
                                    tickOptions: {
                                        formatString: '%d'
                                    }
                                },
                                'width': 300,
                                'height': 250,
                                chartArea: { width: '80%' },
                                legend: { position: "none" },
                                isStacked: true,
                                annotation: {
                                    fontsize: 2
                                },
                                vAxis: {
                                    baseline: 0,
                                    baselineColor: '#726647',
                                    gridlines: { color: '#FFFFFF', count: 6 },
                                    title: 'Average Star Ratings'
                                },
                                hAxis: {
                                    baseline: 0,
                                    baselineColor: '#726647',
                                    gridlines: { color: '#FFFFFF', count: 6 },
                                    title: 'Rating Over Time'
                                },
                                'tooltip': {
                                    trigger: 'none'
                                },
                                colors: ['#d1bb78', '#726647']
                            }}
                            graphID="LineChart"
                            chartEvents={[
                                {
                                    eventName: "select",
                                    callback({ chartWrapper }) {
                                        
                                    }
                                }
                            ]}
                        />
                    )}
                </CollapseBasic>
            </div>
        )
    }
}
