import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
    Progress,
} from "reactstrap";
import moment from "moment";
import UploadMedia from '../uploadMedia';
import {add_buyers_guide,} from '../../../actions/buyersGuide'
import {media_upload_scroll, fetch_media_data, clear_media_dispatch, upload_add_captions, fetch_taxonomy_list,} from "../../../actions/myProfiles";
import {upload_media, initFetchMovieSubCategories,} from "../../../actions/footer";
import {search_article_subcategory} from "../../../actions/buyersGuide"
import { add_movie} from "../../../actions/movies";

import {get_album_types_list, get_album_type_data, delete_selected_gallery_media} from "../../../actions/user";
import { callApi } from "../../../utils/apiCaller";
import VideoPlaceholder from "../../../assets/images/videopholder.jpg";

const validate = Yup.object().shape({
    directors_set: Yup.array()
        .of(
            Yup.object().shape({
                first_name: Yup.string().min(4, "too short").required("Required"), // these constraints take precedence
                profession_id: Yup.number().required("Please select Profession"),
            })
        )
        .required("Must have directors")
        .min(3, "Minimum of 3 directors"),
});


class AddSubCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addMovieForm: {
                name: "",
                is_draft: false,
                drawer: true,
                additional_info: {
                    rating: "",
                    duration: "",
                    storyline: "",
                },
                taxonomy: [],
                media: [],
                country: "",
                year: moment().year(),
                rating: "",
                duration: "",
                directors_set: [
                    {
                        first_name: "",
                        profession_id: "",
                    },
                ],
            },
            years_list: [],
            name: '',
            category_id: '',
            subcategory_id: '',
            year: moment().year(),
            country: 'United States',
            rating: 'G',
            duration: '',
            storyline: '',
            directors_set: [
                {
                    first_name: "",
                    profession_id: "",
                },
            ],
            mediaSet: [],
            media: [],
            uploadMediaModal: false,
            fileReviewStatus: false,
            selectedFilesPreview: [],
            latestUploadedData: {},
            showVideoMedia: true,
            fetchTaxonomy: [],
            selectedUpMediaType: "upload",
            showGalleryType: "images",
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            uploadCaptions: {},
            nameError: "",
            categoryError: "",
            directorsError: "",
            durationError: "",
            writersError: "",
            storylineError: "",
            producersError: "",
            castsError: "",
            files: [],
            albumType: "",
            fetchTaxonomyListingImages: [],
            fetchTaxonomyListingVideos: [],
            uploadMediaPostModal: false,
            uploadMedia: {
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypesList: [],
                albumTypeData: {},
                selectedMedia: [],
                selectedMediaIds: [],
                embedLinks: {},
                uploadFiles: [],
                uploadedFiles: [],
                progress: 0,
            },
            search_subcat_info: [],
            search_producer_info: [],
            search_writer_info: [],
            search_cast_info: [],
            indexValue: 0,
            indexCastValue: 0,
            indexProducerValue: 0,
            indexWriterValue: 0,
            typeMethod: "",
            isEnableFileUpload: false,
            uploadMediaUrl: "",
            catnode: props?.history?.location?.state?.taxonomyId === undefined ? props.taxonomy_id : props?.history?.location?.state?.taxonomyId,
            subcatText: "",
            subcatTitle: "",
            subcatId: ""

        };
    }
    getuploadUrl = (url) => {
        let { uploadMediaUrl } = this.state;
        uploadMediaUrl = url.uploadUrl;
        this.setState({ uploadMediaUrl });
    }

    componentWillReceiveProps(nextProps) {
        let {
            fetchGalleryScroll,
            fetchGalleryCount,
        } = this.state;

        if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypesList: nextProps.album_types_list,
                },
                selectedUpMediaType: "upload",
                showGalleryType: "images",
            });
        }
        if (nextProps.fetch_taxonomy_listData) {
            let taxCatListing = nextProps.fetch_taxonomy_listData;
            if (
                taxCatListing &&
                Array.isArray(taxCatListing) &&
                taxCatListing.length > 0
            ) {
                if (taxCatListing[0].isTypes == "images") {
                    this.setState({
                        fetchTaxonomyListingImages: taxCatListing[0].children
                            ? taxCatListing[0].children
                            : [],
                    });
                } else {
                    this.setState({
                        fetchTaxonomyListingVideos: taxCatListing[0].children
                            ? taxCatListing[0].children
                            : [],
                    });
                }
            }
        }
        if (
            nextProps.album_type_data &&
            Object.keys(nextProps.album_type_data).length > 0
        ) {
            let item = nextProps.album_type_data;
            if (item.album) {
                fetchGalleryScroll = [];
            }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    albumTypeData: nextProps.album_type_data,
                },
                fetchGalleryScroll,
                fetchGalleryCount,
            });
        }

        // if (nextProps.upload_media_data) {
        //   let data = nextProps.upload_media_data;
        //   if (data.index !== undefined) {
        //     let idx = data.index;
        //     latestUploadedData[idx] = data;
        //     this.setState({ latestUploadedData });
        //   }
        // }
        if (nextProps.fetch_taxonomy) {
            let taxCat = nextProps.fetch_taxonomy;
            if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
                taxCat = taxCat.filter((dt) => dt.label !== null);
            }
            this.setState({ fetchTaxonomy: taxCat });
        }
        if (nextProps.upload_scroll) {
            let item = nextProps.upload_scroll;
            if (item.album) {
                fetchGalleryScroll = [];
            }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({
                fetchGalleryScroll,
                fetchGalleryCount,
                activeMyPhotoIndex: 0,
            });
        }
        

        // if (nextProps.openSubCategory) {
            if (nextProps.get_buyersSubcategory &&
                Array.isArray(nextProps.get_buyersSubcategory.results) &&
                nextProps.get_buyersSubcategory.results.length > 0) {
                this.setState({
                    search_subcat_info: nextProps.get_buyersSubcategory.results
                });
            } else {
                this.setState({
                    search_subcat_info: []
                });
            }
        // }

    }

    componentDidMount = () => {
        let date = new Date()
        let year = date.getFullYear()
        let years_list = [];
        for (let i = 1970; i <= year + 10; i++) {
            years_list.push(i)
        }
        this.setState({ years_list: years_list })
    }

    handleChangeCast = (e, index) => {
        const { directors_set, } = this.state;
        const { name, value } = e.target;
       
        if (name.includes('directors') && directors_set.length <= index) {
            directors_set.push({ first_name: "", profession_id: "" })
            this.setState({ directors_set })
        }
        if (name === 'directors_name') {
            directors_set[index].first_name = value;
            this.setState({ directors_set, indexValue: index, })
            let catnodeId = this.state.catnode;
			if(value.length > 0){
            this.props.search_article_subcategory(catnodeId, value);
			}else{
				this.setState({search_subcat_info: []})
			}
        } else if (name === 'directors_id') {
            directors_set[index].profession_id = value
            this.setState({ directors_set })
        }
        directors_set[index].category = value;
    };

    handleOnClickAddSelected = (item, type, index) => {
        let { directors_set } = this.state;
        directors_set[index].category = item.category;
        this.setState({
            directors_set,
            search_subcat_info: [],
            subcatTitle: directors_set[index].category,
            subcatId: item.id
        })
    }

    handleremoveCast = (option) => {
        const { directors_set } = this.state
        if (option === 'directors_set') {
            directors_set.pop()
            this.setState({ directors_set })
        }
    }
    handleChangeTaxonomyData = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, uploadMedia } = this.state;
        let data = {};
        let upload_data = uploadMedia.uploadFiles[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = value;
        let id = upload_data.id;
        this.setState({ taxonomycategoryId: value }, () =>
            this.props.upload_add_captions(id, data)
        );
    };

    handleOnClickRemoveSelectedMedia = (id) => () => {
        let removeMediaIds = [];
        removeMediaIds.push(id);
        this.props.delete_selected_gallery_media(removeMediaIds);
        let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
            (file) => file.id !== id
        );
        let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
            (file) => file.id !== id
        );
        let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
            (item) => item !== id
        );
        let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
            (file) => file.id !== id
        );
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                selectedMedia: selectedMedia,
                selectedMediaIds: selectedMediaIds,
                uploadedFiles: uploadedFiles,
                uploadFiles: uploadFiles,
            },
        });
    };
    handleOnClickRemoveSelectedGalleryMedia = (media, index) => {
        // remove media from upload media state
        let { uploadMedia, editPost } = this.state;
        let id = media.id;
        let filterSelectedMediaIds = [];
        let filterSelectedMedia = [];

        if (id) {
            filterSelectedMediaIds = uploadMedia?.selectedMediaIds.filter(data => data !== id);
            filterSelectedMedia = uploadMedia?.selectedMedia.filter(data => data.id !== id);
        }
        uploadMedia['selectedMediaIds'] = filterSelectedMediaIds;
        uploadMedia['selectedMedia'] = filterSelectedMedia;

        if (editPost) {
            editPost["messagemedia_set"] = filterSelectedMediaIds;
        }

        this.setState({ uploadMedia, editPost }, () => { this.props.delete_selected_gallery_media([id]) });
    };
    handleOnClickSelectGalleryMedia = (media) => {
        if (media) {
            let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
            let newSelectedMediaIds;
            let newSelectedMedia;
            if (index !== -1) {
                newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
                    (item) => item !== media.id
                );
                newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
                    (item) => item.id !== media.id
                );
            } else {
                newSelectedMediaIds = [
                    ...this.state.uploadMedia.selectedMediaIds,
                    media.id,
                ];
                newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
            }
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: newSelectedMedia,
                    selectedMediaIds: newSelectedMediaIds,
                },
            });
        }
    };

    handleOnFilePostUploadChange = (event) => {
        let uploadFiles = event.target.files;
        let showFiles = [];
        for (const key of Object.keys(uploadFiles)) {
            let itemType = uploadFiles[key].type.split("/");
            let extName = itemType[0];
            showFiles.push({ id: "", url: extName === "image" ? URL.createObjectURL(uploadFiles[key]) : require("../../../assets/images/blank.png") });
        }
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                uploadFiles: showFiles,
                progress: 0,
            },
        });
        let progressPart = 100 / showFiles.length;
        let progress = 0;
        for (const key of Object.keys(uploadFiles)) {
            let data = new FormData();
            data.append("file", uploadFiles[key]);

            callApi(
                `/upload/multiuploader/?album=&instance=moviesdrawer&image=undefined&doc=undefined`,
                "POST",
                data,
                true
            ).then((response) => {
                this.handleOnClickSelectGalleryMedia(response);
                if (showFiles.length === 1 || key === showFiles.length - 1) {
                    progress = 100;
                } else {
                    progress = progress + progressPart;
                }
                if (response && response.code !== 400 && response.code !== 500) {
                    showFiles[key].id = response.id;
                    showFiles[key].media_type = response.media_type;
                    showFiles[key].thumbnail = response.thumbnail;
                    if (response.media_type == "image") {
                        this.props.fetch_taxonomy_list('movies', 'images');
                    } else {
                        this.props.fetch_taxonomy_list('movies', 'videos');
                    }

                    this.setState({
                        ...this.state,
                        uploadMedia: {
                            ...this.state.uploadMedia,
                            progress: progress,
                            // uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
                            uploadedFiles: showFiles,
                        },
                    });
                }
            });
        }
    };

    clearMediaDispatch = () => {
        this.setState(
            {
                latestUploadedData: {},
                uploadCaptions: {},
                selectedFilesPrevietoggleBottomMenuw: [],
                files: [],
            },
            () => this.props.clear_media_dispatch()
        );
    };

    toggleVideoOption = () => {
        this.setState({ showVideoMedia: !this.state.showVideoMedia });
    };

    AddNewEmbedLink = (e) => {
        e.preventDefault();
        let { embeddedLink } = this.state;
        // this.props.embedNewLink({
        //     data: {
        //         video: `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
        //         exclude_list: [],
        //         body_preview: `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
        //         question_rating_category: [],
        //         messagemedia_set: [],
        //     },
        // });
        this.setState({ embeddedLink: "" });
    };

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = this.state;
        let getDataItems = [];
        const imagePreview = <img src={VideoPlaceholder} alt=""/>;

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach((item) => {
                if (
                    fetchGalleryScroll[item] &&
                    fetchGalleryScroll[item].results !== null
                ) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0) {
                return getDataItems.map((item) => {
                    let itemName = (item?.thumbnail).split(".");
                    let itemFile = (item?.file).split(".");
                    let extName = itemName[itemName.length - 1];
                    let extFile = itemFile[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;

                    return (
                        <Col xs="auto" className="mb-3">
                            <FormGroup
                                className="checkable-media"
                                check
                                style={{ width: "120px" }}
                            >
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        onClick={() => this.handleOnClickSelectGalleryMedia(item)}
                                    />
                                    <div className="gallery-media">
                                    <img
										className="img-fluid"
										src={isVideo ? srcFile : item?.file}
										alt={item?.filename ? item.filename : "file"}
										onError={(error) => (error.target.src = require("../../../assets/images/videopholder.jpg"))}
									/>
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
                                    {item.caption ? item.caption : "No Caption"}
                                </div>
                                <div className="font-weight-normal fs-14 d-block">
                                    {item.category ? item.category : "No Category"}
                                </div>
                            </FormGroup>
                        </Col>
                    );
                });
            }
        } else {
            return getDataItems;
        }
    };

    checkIsAdded = (item) => {
        let { mediaSet } = this.state;
        let id = item.id;
        let itemFiltered = mediaSet.find((item) => item.id === id);
        if (itemFiltered) {
            return true;
        } else {
            return false;
        }
    };

    setMediaSetForItems = (e, item) => {
        // e.preventDefault();
        let { mediaSet, offerCheck } = this.state;
        if (item && item.id) {
            let id = item.id;
            let itemFiltered = mediaSet.find((item) => item.id === id);

            if (itemFiltered) {
                const index = mediaSet.indexOf(itemFiltered);
                if (index > -1) {
                    mediaSet.splice(index, 1);
                }
            } else {
                mediaSet.push(item);
            }
            this.setState({ mediaSet, mediaSetReview: mediaSet, offerCheck });
        }
    };

    removeFromMedia = (e, item) => {
        e.preventDefault();
        let { mediaSet } = this.state;
        let id = item.id;
        let itemFiltered = mediaSet.find((item) => item.id === id);
        if (itemFiltered) {
            const index = mediaSet.indexOf(itemFiltered);
            if (index > -1) {
                mediaSet.splice(index, 1);
            }
            this.setState({ mediaSet, mediaSetReview: mediaSet });
        }
    };
    handleCaptionByIndex = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, uploadMedia } = this.state;
        uploadCaptions[index] = value;
        let data = {};
        let upload_data = uploadMedia.uploadFiles[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        let id = upload_data.id;
        this.setState({ uploadCaptions }, () =>
            this.props.upload_add_captions(id, data)

        );

    };
    handleOnClickGalleryType = (type) => {
        let mediaType = "";
        if (type === "images") {
            mediaType = "image";
        } else if (type === "videos") {
            mediaType = "video";
        }
        this.props.get_album_type_data(mediaType, "", 1);
        this.setState({
            ...this.state,
            selectedUpMediaType: "gallery",
            showGalleryType: type,
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: mediaType,
                albumType: "",
                pageNo: 1,
            },
        });
    };
    handleOnClickAlbumTypeChange = (e) => {
        this.props.get_album_type_data("image", e.target.value, 1);
        this.setState({
            ...this.state,
            selectedUpMediaType: "gallery",
            showGalleryType: "images",
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: "image",
                albumType: e.target.value,
                pageNo: 1,
            },
        });
    };
    handleOnClickSelectedUploadMediaType = (type) => {
        if (type === "gallery") {
            this.props.get_album_type_data("image", "", 1);
            this.setState({
                ...this.state,
                selectedUpMediaType: type,
                showGalleryType: "images",
                uploadMedia: {
                    ...this.state.uploadMedia,
                    mediaType: "image",
                    albumType: "",
                    pageNo: 1,
                    embedLinks: {},
                    progress: 0,
                },
            });
        } else if (type === "upload") {
            this.setState({
                ...this.state,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    embedLinks: {},
                    progress: 0,
                },
            });
        } else if (type === "embed") {
            let embedLinks = {
                0: "",
                1: "",
            };
            this.setState({
                ...this.state,
                selectedUpMediaType: type,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    selectedMedia: [],
                    selectedMediaIds: [],
                    embedLinks: embedLinks,
                },
            });
        }
    };
    embedLinkOnChange = (id) => (e) => {
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                embedLinks: {
                    ...this.state.uploadMedia.embedLinks,
                    [id]: e.target.value,
                },
            },
        });
    };
    handleOnClickUploadModalOk = () => {
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }
    mapEmbedLinks = () => {
        let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
        return embedKeys.map((emKey) => {
            return (
                <div className="d-flex mx-n2 mb-2" key={emKey}>
                    <div className="flex-fill px-2">
                        <Input
                            type="url"
                            bsSize="sm"
                            className="bg-white"
                            value={this.state.uploadMedia.embedLinks[emKey]}
                            onChange={this.embedLinkOnChange(emKey)}
                            placeholder="Embeded link"
                        />
                    </div>
                    <div className="px-2">
                        <Button
                            title="Remove"
                            color="danger"
                            size="sm"
                            hidden={emKey <= 1 ? true : false}
                            onClick={() => {
                                this.deleteEmbedLinkRow(emKey);
                            }}
                        >
                            <FontAwesomeIcon icon="minus" />
                        </Button>
                    </div>
                </div>
            );
        });
    };
    deleteEmbedLinkRow = (emKey) => {
        let embedLinks = this.state.uploadMedia.embedLinks;
        if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
            delete embedLinks[emKey];
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    embedLinks: embedLinks,
                },
            });
        }
    };
    addEmbedlinkRow = () => {
        let newEmbedLinks = {};
        for (let i = 2; i < 5; i += 1) {
            if (
                Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
                -1
            ) {
                newEmbedLinks[i] = "";
                break;
            }
        }
        if (Object.keys(newEmbedLinks).length > 0) {
            this.setState({
                ...this.state,
                uploadMedia: {
                    ...this.state.uploadMedia,
                    embedLinks: {
                        ...this.state.uploadMedia.embedLinks,
                        ...newEmbedLinks,
                    },
                },
            });
        }
    };
    confirmDeleteModalToggle = () => {
        if (this.state.deletePostId) {
            this.setState({ deletePostId: 0, deletePostType: "" });
        }
        this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
    };
    handleOnClickUploadMedia = () => {
        this.props.get_album_types_list();
        this.setState({
            ...this.state,
            uploadMedia: {
                ...this.state.uploadMedia,
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                progress: 0,
                uploadFiles: [],
                uploadedFiles: [],
            },
            uploadMediaPostModal: true,
            selectedUpMediaType: "upload",
            showGalleryType: "images",
        });
    };
    truncate = (filenameString) => {
        let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
        let extension = filenameString.substr(
            filenameString.lastIndexOf("."),
            filenameString.length - 1
        );
        let partial = filename.substring(filename.length - 3, filename.length);
        filename = filename.substring(0, 15);
        return filename + "..." + partial + extension;
    };
    limitedPostsModalToggle = () => {
        this.setState({ limitedPostsModal: !this.state.limitedPostsModal });
    };
    uploadMediaModalToggle = () => {
        this.setState({
            ...this.state,
            uploadMediaPostModal: !this.state.uploadMediaPostModal,
            uploadMedia: {
                ...this.state.uploadMedia,
                selectedMedia: [],
                selectedMediaIds: [],
                mediaType: "image",
                albumType: "",
                pageNo: 1,
                albumTypeData: {},
                embedLinks: {},
                uploadedFiles: [],
                uploadFiles: [],
            },
            selectedUpMediaType: "upload",
            showGalleryType: "images",
            taxonomycategoryId: null,
            fetchTaxonomyListingImages: [],
            fetchTaxonomyListingVideos: [],
        });
    };
    handleOpenModal(data, url) {
        this.setState({
            viewMyPhotosModal: !this.state.viewMyPhotosModa,
            imageAttacment: data,
        });
    }

    handleScrollGalleryVideo = (e) => {
        let { refGalleryVideoScroll } = this;
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop =
            this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({
                type: "video",
                album: "",
                force_update: false,
            });
        }
    };

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state;
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;
            if (nextCount <= roundValue) {
                let page = nextCount;
                this.props.get_album_type_data(type, "", page);
            }
        }
    };

    handleScrollGalleryImage = (e) => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop =
            this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({
                type: "image",
                album: fetchGalleryAlbum,
                force_update: false,
            });
        }
    };

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet } = this.state;

        if (mediaSet) {
            uploadedMediaSet['uploadedFiles'] = mediaSet;
        }

        if (links) {
            uploadedMediaSet['embedLinks'] = links;
        }
        this.setState({ uploadedMediaSet });
    }

    handleSubCategoryContent = (e) => {
        this.setState({
            subcatText: e.target.value
        })
    }
    render() {
        const { toggleMovieModal } = this.props;
        const { addMovieForm, directors_set, search_subcat_info, isEnableFileUpload } = this.state;

        var data = {
            description_RwFExRRtURryCuP: this.state.subcatText,
            RwFExRRtURryCuP: this.state.subcatTitle,
            "article_taxonomy": this.state.subcatId,
            "article_taxonomy_list": {
                "1": this.state.subcatId
            },
            "catandmedia": {
                "1": this.state.subcatText
            }
        };

        return (
            <>
                <Formik
                    touched
                    errors
                    initialValues={addMovieForm}
                    validationSchema={validate}
                    onSubmit={(values) => {
                        this.props.onSubmit({
                            ...values,
                            taxonomy: [values.taxonomy],
                        });
                    }}
                    render={(props) => {
                        const {
                            values,
                            handleSubmit,
                        } = props;
                        return (

                            <form
                                className="d-flex flex-column h-100"
                                onSubmit={handleSubmit}
                            >
                                <div>
                                    <hr className="bg-dark" />
                                    <Row>
                                        <Col lg={12}>
                                            <div>
                                                {/* Repeat this */}
                                                <div>
                                                    <FieldArray
                                                        name="directors_set"
                                                        render={(arrayHelpers) => (
                                                            <div>
                                                                {values.directors_set.map(
                                                                    (director, index) => (
                                                                        <div key={index}>
                                                                            <FormGroup>
                                                                                <Label
                                                                                    className="text-primary font-weight-bold mb-2"
                                                                                    size="sm"
                                                                                >
                                                                                    Choose sub-category
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder={"Search sub-category here..."}
                                                                                    className="primary ph-bold ph-grey"
                                                                                    bsSize="sm"
                                                                                    type="text"
                                                                                    // name={`directors_set[${index}].first_name`}
                                                                                    name="directors_name"
                                                                                    autocomplete="off"
                                                                                    style={{ maxWidth: '450px' }}
                                                                                    onChange={(e) => this.handleChangeCast(e, index)}
                                                                                    value={directors_set[index].category}
                                                                                />
                                                                                {/* Instant Search Results */}
                                                                                {this.state.indexValue == index && search_subcat_info && search_subcat_info.length > 0 && (
                                                                                    <ul className="list-unstyled text-dark instant-results bg-white w-100 shadow"
                                                                                        style={{ maxHeight: '400px', overflowY: 'auto', maxWidth: '450px' }}>
                                                                                        {search_subcat_info.map(itemData => {
                                                                                            return (
                                                                                                <li className="result-item" role="button" key={itemData.id}>
                                                                                                    <div className="result-link fs-14" onClick={() => this.handleOnClickAddSelected(itemData, 'director', index)}>
                                                                                                        <div className="d-flex">
                                                                                                            <div>
                                                                                                                <span>{itemData.category}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                )}
                                                                                <div className="my-2"></div>
                                                                                <Input className="primary ph-bold ph-grey" bsSize="sm" type="textarea" name="sub_category_details"
                                                                                       placeholder="Write your content here..." rows="3"
                                                                                       onChange={this.handleSubCategoryContent} />

                                                                                <span style={{ color: 'red', fontSize: "13px" }}>{this.state.directorsError}</span>
                                                                            </FormGroup>
                                                                            {/* Hide for first */}
                                                                            <Button color="link" className="p-0 text-primary hover-blue btn btn-link btn-sm"
                                                                                    onClick={() => {
                                                                                        arrayHelpers.remove(index);
                                                                                        this.handleremoveCast('directors_set')
                                                                                    }}>
                                                                                Remove
                                                                            </Button>
                                                                            <Button
                                                                                color="transparent"
                                                                                size="sm"
                                                                                className="mr-2 bg-transparent"
                                                                                title="Upload Media"
                                                                                onClick={() =>
                                                                                    this.handleOnClickUploadMedia()
                                                                                }>
                                                                                <img
                                                                                    src={require("../../../assets/images/icons/feed-cam.png")}
                                                                                    alt=""
                                                                                />
                                                                            </Button>
                                                                        </div>
                                                                    )
                                                                )}
                                                                <div className="text-right">
                                                                    <Button color="link" className="p-0 text-primary hover-blue btn btn-link btn-sm"
                                                                            onClick={() => {
                                                                                arrayHelpers.push({
                                                                                    first_name: "",
                                                                                    profession_id: "",
                                                                                });
                                                                                directors_set.push({
                                                                                    first_name: "",
                                                                                    profession_id: "",
                                                                                })
                                                                            }}>
                                                                        <FontAwesomeIcon icon="plus" />
                                                                         {" Add another sub-category"}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <hr className="bg-dark" />
                                {/* Step 2 */}
                                <div>
                                    <Row>
                                        <Col lg={7}>
                                            {/* Uploaded media shown here */}
                                            {
                                                this.state.uploadMedia.selectedMedia.length ?
                                                    <div className="border mb-3">
                                                        <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                                            {this.state.uploadMedia.selectedMedia.map((item, indexMedia) => (
                                                                <Col>
                                                                    <div className="d-flex pr-3 m-2">
                                                                        <div>
                                                                            {(item.type === "image" ||
                                                                                item.media_type === "image" ||
                                                                                item.type === "video" ||
                                                                                item.media_type === "video" || item.type === "audio" ||
                                                                                item.media_type === "audio") && (
                                                                                <div className="gallery-media">
                                                                                    { item.media_type === 'image' ?
                                                                                        <img
                                                                                            className="img-fluid img-thumbnail"
                                                                                            src={item.url ? item.url : item.file}
                                                                                            alt={
                                                                                                item.filename
                                                                                                    ? item.filename.length < 20
                                                                                                    ? item.filename
                                                                                                    : this.truncate(item.filename)
                                                                                                    : item.name
                                                                                                    ? item.name.length < 20
                                                                                                        ? item.name
                                                                                                        : this.truncate(item.name)
                                                                                                    : ""
                                                                                            } /> :
                                                                                        <img
                                                                                            className="img-fluid img-thumbnail"
                                                                                            src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
                                                                                            alt={
                                                                                                item.filename
                                                                                                    ? item.filename.length < 20
                                                                                                    ? item.filename
                                                                                                    : this.truncate(item.filename)
                                                                                                    : item.name
                                                                                                    ? item.name.length < 20
                                                                                                        ? item.name
                                                                                                        : this.truncate(item.name)
                                                                                                    : ""
                                                                                            }
                                                                                        />}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="mx-n3 mt-2">
                                                                            <Button
                                                                                color="delete-media"
                                                                                size="sm"
                                                                                title="Remove Media"
                                                                                onClick={() =>
                                                                                    this.handleOnClickRemoveSelectedGalleryMedia(
                                                                                        item, indexMedia
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FontAwesomeIcon icon="trash-alt" />{" "}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                    :
                                                    ''
                                            }
                                            {/* <Button
                                                color="royal-blue"
                                                className="font-weight-normal"
                                                bsSize="sm"
                                                onClick={() =>
                                                    this.handleOnClickUploadMedia()
                                                }
                                            >
                                                Upload Media
                        </Button> */}


                                            {isEnableFileUpload ?
                                                <UploadMedia
                                                    getUploadedMedia={this.getUploadedMedia}
                                                    uploadedUrl={this.state.uploadMediaUrl}
                                                    hideEmbed={this.state.hideEmbed}
                                                    callbackFunction={this.handleOnClickUploadModalOk}
                                                    noCategory={this.state.discussionTab}
                                                    typeUpload={this.state.typeupload}
                                                    toggleEnableFileUpload={this.toggleEnableFileUpload}
                                                    discusstype={this.state.discussType}
                                                />
                                                : null}
                                        </Col>
                                    </Row>
                                </div>
                                <hr className="bg-dark" />
                                <div>

                                    {/*<div className="text-right mt-3">*/}
                                    {/*    <Button*/}
                                    {/*        color="primary"*/}
                                    {/*        size="sm"*/}
                                    {/*        onClick={toggleMovieModal}*/}
                                    {/*    >*/}
                                    {/*        Cancel*/}
                                    {/*    </Button>*/}
                                    {/*    <Button color="primary" size="sm" onClick={() => this.props.submitSubcategory(data)}>*/}
                                    {/*        Submit*/}
                                    {/*    </Button>*/}
                                    {/*</div>*/}
                                </div>
                            </form>
                        );
                    }}
                />

                {/* Media Modal */}
                <Modal
                    isOpen={this.state.uploadMediaPostModal}
                    toggle={this.uploadMediaModalToggle}
                >
                    <ModalHeader toggle={this.uploadMediaModalToggle}>
                        UPLOAD MEDIA
                    </ModalHeader>
                    <ModalBody className="p-3">
                        <Row>
                            <Col xs={"auto"}>
                                <div className="bg-light h-100 p-3">
                                    <Nav
                                        tabs
                                        className="flex-column active-transparent border-0 m-0"
                                    >
                                        <NavItem className="text-left">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "upload"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("upload");
                                                }}
                                            >
                                                Upload
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "gallery"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("gallery");
                                                }}
                                            >
                                                Gallery
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "embed"}
                                                onClick={() => {
                                                    this.handleOnClickSelectedUploadMediaType("embed");
                                                }}
                                            >
                                                Embed
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Col>
                            <Col>
                                <TabContent activeTab={this.state.selectedUpMediaType}>
                                    <TabPane tabId="upload">
                                        <div
                                            className="mb-3 type-file-block bg-light"
                                            hidden={
                                                this.state.uploadMedia.uploadFiles &&
                                                this.state.uploadMedia.uploadFiles.length > 0
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <Input
                                                type="file"
                                                name="upload_media_file"
                                                id="uploadFilesPostFromSystem"
                                                accept="image/*, video/*, audio/*"
                                                onChange={this.handleOnFilePostUploadChange}
                                                multiple
                                            />
                                            <Label for="uploadFilesPostFromSystem">
                                                <div className="type-bg"></div>
                                            </Label>
                                        </div>
                                        {this.state.uploadMedia.uploadFiles.length > 0 && (
                                            <Row className="mb-3" form>
                                                <Col xs={12}>
                                                    <div
                                                        style={{
                                                            maxWidth: "120px",
                                                            margin: "0 1rem 1rem auto",
                                                        }}
                                                    >
                                                        <div className="text-center mb-1 small">
                                                            {this.state.uploadMedia.progress === 100 ? (
                                                                <div className="text-success">
                                                                    <FontAwesomeIcon
                                                                        icon="check-circle"
                                                                        className="mr-1"
                                                                    />{" "}
                                                                    Uploaded
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    Uploading{" "}
                                                                    <span className="text-success font-weight-bold ff-base">
                                                                            {this.state.uploadMedia.progress.toFixed(0)}
                                                                        %
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <Progress
                                                            value={this.state.uploadMedia.progress}
                                                            style={{ height: "8px" }}
                                                        ></Progress>
                                                    </div>
                                                </Col>
                                                {this.state.uploadMedia.uploadFiles.map(
                                                    (file, index) => {
                                                        return (
                                                            <Col xs="auto">
                                                                <div className="bg-light p-2">
                                                                    <div className="d-flex pr-3">
                                                                        <div>
                                                                            <div
                                                                                className="selectable-media 123"
                                                                                style={{ cursor: "default" }}
                                                                            >
                                                                                <div className="gallery-media">
                                                                                    {file.media_type === 'image' ?
                                                                                        <img src={file.url} alt="" /> :
                                                                                        <img src={file.thumbnail && file.thumbnail !== null ? file.thumbnail : file.url} alt="" />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mx-n3 mt-2">
                                                                            <Button
                                                                                color="delete-media _alt"
                                                                                size="sm"
                                                                                title="Remove Media"
                                                                                hidden={file.id === "" ? true : false}
                                                                                onClick={this.handleOnClickRemoveSelectedMedia(
                                                                                    file.id
                                                                                )}
                                                                            >
                                                                                <FontAwesomeIcon icon="trash-alt" />{" "}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        <Input
                                                                            bsSize="sm"
                                                                            type="text"
                                                                            className="mw-100 caption-input"
                                                                            name="caption"
                                                                            placeholder="Caption"
                                                                            onBlur={(e) =>
                                                                                this.handleCaptionByIndex(e, index)
                                                                            }
                                                                            style={{ maxWidth: '160px' }}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        );
                                                    }
                                                )}
                                                <Col xs="auto">
                                                    <div className="d-flex pt-3">
                                                        <div className="selectable-media" hidden>
                                                            <Label
                                                                for="uploadFilesFromSystemMini"
                                                                className="gallery-media"
                                                                style={{
                                                                    borderStyle: "dashed",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <div className="d-flex h-100 align-items-center justify-content-center">
                                                                    <span className="fs-14">Upload More</span>
                                                                </div>
                                                            </Label>
                                                            <Input
                                                                type="file"
                                                                name="upload_media_file"
                                                                id="uploadFilesFromSystemMini"
                                                                accept="image/*, video/*"
                                                                onChange={this.handleOnFilePostUploadChange}
                                                                multiple
                                                                style={{ display: "none" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={this.uploadMediaModalToggle}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => {
                                                        this.setState({
                                                            ...this.state,
                                                            uploadMediaPostModal: false,
                                                        });
                                                    }}
                                                >
                                                    {"OK"}
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="gallery">
                                        <div className="mb-2">
                                            <Nav tabs className="d-inline-flex mb-0">
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        active={this.state.showGalleryType === "images"}
                                                        onClick={() => {
                                                            this.handleOnClickGalleryType("images");
                                                        }}
                                                    >
                                                        Image Gallery
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href="#"
                                                        active={this.state.showGalleryType === "videos"}
                                                        onClick={() => {
                                                            this.handleOnClickGalleryType("videos");
                                                        }}
                                                    >
                                                        Video Gallery
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={this.state.showGalleryType}>
                                            <TabPane tabId="images">
                                                <div>
                                                    <FormGroup className="form-row justify-content-end">
                                                        <Col xs="auto">
                                                            <Label className="small" for="selectAlbumType">
                                                                Album Type:
                                                            </Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Input
                                                                bsSize="sm"
                                                                type="select"
                                                                name="album_type_select"
                                                                id="selectAlbumType"
                                                                defaultValue={this.state.uploadMedia.albumType}
                                                                onChange={this.handleOnClickAlbumTypeChange}
                                                            >
                                                                <option value="">All</option>
                                                                {this.state.uploadMedia.albumTypesList.length >
                                                                0 &&
                                                                this.state.uploadMedia.albumTypesList.map(
                                                                    (type) => {

                                                                        if (type.label) {
                                                                            return (
                                                                                <option value={type.category}>
                                                                                    {type.category}
                                                                                </option>

                                                                            )
                                                                        }
                                                                    }
                                                                )}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: "350px", overflow: "auto" }}
                                                    ref={this.refGalleryImageScroll}
                                                    onScroll={this.handleScrollGalleryImage}
                                                >
                                                    {this.state.uploadMedia.albumTypeData &&
                                                    this.state.uploadMedia.albumTypeData.count > 0 ? (
                                                        <Row>{this.fetchScrollPhotoMedia()}</Row>
                                                    ) : (
                                                        <div className="bg-white p-3">
                                                            <h2 className="text-secondary-dark">No images</h2>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={this.uploadMediaModalToggle}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    uploadMediaPostModal: false,
                                                                });
                                                            }}
                                                        >
                                                            {"OK"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="videos">
                                                <div
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: "350px", overflow: "auto" }}
                                                    ref={this.refGalleryVideoScroll}
                                                    onScroll={this.handleScrollGalleryVideo}
                                                >
                                                    {this.state.uploadMedia.albumTypeData &&
                                                    this.state.uploadMedia.albumTypeData.count > 0 ? (
                                                        <Row>{this.fetchScrollPhotoMedia(true)}</Row>
                                                    ) : (
                                                        <div className="bg-white p-3">
                                                            <h2 className="text-secondary-dark">No videos</h2>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="d-flex mx-n2">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={this.uploadMediaModalToggle}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                    <div className="px-2 ml-auto">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    uploadMediaPostModal: false,
                                                                });
                                                            }}
                                                        >
                                                            {"OK"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </TabPane>
                                    <TabPane tabId="embed">
                                        <div className="text-muted mb-2">
                                            Submit the link for the image or video you want to embed{" "}
                                        </div>
                                        <div className="mb-2">
                                            {/* Repeat this while adding */}
                                            {this.mapEmbedLinks()}
                                        </div>
                                        <div className="d-flex mx-n2 mb-2">
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    title="Add"
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => {
                                                        this.addEmbedlinkRow();
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="plus" />
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={this.uploadMediaModalToggle}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => {
                                                        this.insertEmbedLinks();
                                                    }}
                                                >
                                                    Insert
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

            </>


        );



    }
}

const mapState = (state) => ({
    // collage_items: state.products_listing.products_collage_items,
    videos_children:
        state.business.videos_children && state.business.videos_children[0],
    upload_scroll: state.myProfile.upload_scroll,
    upload_media_data: state.myProfile.upload_media_data,
    album_types_list: state.user.album_types_list,
    album_type_data: state.user.album_type_data,
    fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
    get_buyersSubcategory: state.buyersGuide.get_buyersSubcategory,
    writerListing: state.footer.writerListing,
    castListing: state.footer.castListing,
    producerListing: state.footer.producerListing,
    fetch_save_movie_response: state.movies.fetch_save_movie_response,
});

const mapProps = (dispatch) => ({
    media_upload_scroll: ({ type, album, page, force_update }) =>
        dispatch(media_upload_scroll({ type, album, page, force_update })),
    fetch_media_data: (type, album, page, user_profile) =>
        dispatch(fetch_media_data(type, album, page, user_profile)),
    add_movie: (data) => dispatch(add_movie(data)),
    clear_media_dispatch: () => dispatch(clear_media_dispatch()),
    upload_media_files: (type, data, index) =>
        dispatch(upload_media(type, data, index)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    get_album_types_list: () => dispatch(get_album_types_list()),
    get_album_type_data: (type, album, page) =>
        dispatch(get_album_type_data(type, album, page)),
    delete_selected_gallery_media: (dataID) =>
        dispatch(delete_selected_gallery_media(dataID)),
    fetch_taxonomy_list: (category, type) =>
        dispatch(fetch_taxonomy_list(category, type)),
    add_buyers_guide: (id, type, data, message) => dispatch(add_buyers_guide(id, type, data, message)),
    search_article_subcategory: (taxonomyId, search) => dispatch(search_article_subcategory(taxonomyId, search))
});
export default withRouter(connect(mapState, mapProps)(AddSubCategory));
