/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Col, Container, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Collapse, Spinner } from 'reactstrap';
import AppHeader from '../oragnisms/dashboard/appHeader';
import {
    fetch_products_category_collage_items, 
    setIsLoading,
} from '../../actions/productsListing';
import UserFeedsPanel from '../atoms/userFeedsRightPanel';
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { callApi } from "../../utils/apiCaller";
import { detectlink, getRefinedParams } from '../../utils/utilities';
import { getSubCategoryDescription, getSubCategoryTitle } from '../../utils/seo-utilities';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';

class ProductsCategory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            myConnectionActivity: 'all',
            viewEditReviewStep: 'writeReview',
            viewProductsType: 'all',
            category: props?.location?.state?.child_category || '',
            subCategory: props?.history?.location?.state?.id || '',
            subCategorySlug: props?.match?.url || '',
            collage_items: [],
            products_list: [],
            selectedTab: 'all',
            products_sub_categories: null,
            products_sub_categories1: null,
            products_sub_categories2: null,
            categorisListing: [],
            childItems: {},
            currentPageIndex: 1,
            checkNextPagination: false,
            parentCategory: props?.location?.state?.parent_category || 'products',          //For product api content type need be products
            isLoadingCategories: false,
            pathName: "",
            enableScrollTillEnd: false,
            scrollType: "cat",
            currentCategoryId: null,
            starRatingSelected: null,
            priceSelected: null
        }
    }

    fetchCollageItems = async ({ parent_category }) => {
        let { subCategory } = this.state;
        let sub_cat = '';
        if (subCategory) {
            sub_cat = subCategory
        }
        let url = `/api/search/?content_type=${parent_category}&catTrending=True&subcat=${sub_cat}&trendingType=national&size=6`;
        try {
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {

                this.setState({ collage_items: response.results });
            }
        } catch (err) {
            console.log({ err });
        }
    }

    fetchSubCateories = async ({ parent_category, id }) => {
        let { subCategory } = this.state;
        let sub_cat = '';
        if (subCategory) {
            sub_cat = subCategory
        }
        let url = `/api/taxonomy/?category=${id}&depth=true&delsel=&ancestor=&sub_cat=${sub_cat}&level=`
        try {
            this.setState({ isLoadingCategories: true })
            let response = await callApi(url, 'GET');
            this.setState({ isLoadingCategories: false })
            if (response && response?.code !== 500) {
                this.setState({ categorisListing: response });
            }
        } catch (err) {
            console.log({ err });
        }
    }

    getAllSubCategories = async (item) => {
        let { childItems } = this.state;
        let url = `/api/taxonomy/?category=${item?.id}&depth=true&delsel=&ancestor=&sub_cat=&level=`
        try {
            this.setState({ isLoadingCategories: true })
            let response = await callApi(url, 'GET');
            this.setState({ isLoadingCategories: false })

            if (response && response?.code !== 500) {

                if (childItems?.[item.category]?.isOpen == undefined) {
                    childItems[item.category] = { isOpen: true, values: response, parent_level: item.level }
                } else {
                    if (this.state[item.category]?.isOpen) {
                        childItems[item.category] = { isOpen: true, values: response }
                    } else {
                        childItems[item.category].isOpen = !childItems[item.category].isOpen;
                    }
                }
                this.setState({ childItems });
            }
        } catch (err) {
            console.log({ err });
        }
    }


    fetchMiddleItems = async ({ name, id, page, stars, price }) => {
        let { products_list, checkNextPagination, selectedItem, viewProductsType, scrollType } = this.state;
        const optionalParams = getRefinedParams({ page, stars, price })
        let url
        if (viewProductsType === "all") {
            url = `/api/search/?content_type=${name}&${scrollType}=${id}&to_api=true&showReview=true&cPage=true&location=false&${optionalParams}`;
        } else if (viewProductsType === "recommended") {
            url = `/api/search/?content_type=${name}&${scrollType}=${id}&to_api=true&showReview=true&location=false&${optionalParams}&recommends=true`
        } else {
            url = `/api/friendspick/?content_type=${name}&${"cat"}=${id}&to_api=true&showReview=true&location=false&&${optionalParams}`
        }

        try {
            // this.setState({ isLoadingCategories: true })
            let response = await callApi(url, 'GET');
            // this.setState({ isLoadingCategories: false })

            if (response && response?.code !== 500) {
                let data = response?.results;
                if (data && Array.isArray(data) && data?.length) {
                    if (response.next) {
                        if (products_list.length) {
                           
                            products_list = products_list.concat(data);
                        }
                        if (products_list.length == 0) {
                            products_list = data;
                        }
                        this.setState({ products_list }, () => {
                            this.setState({ enableScrollTillEnd: true, checkNextPagination: true });
                        });

                    } else {
                        if(!!!response.previous && page===1){
                            this.setState({ products_list: data })
                        }

                        if (products_list.length) {
                            products_list = products_list.concat(data);
                            this.setState({ products_list })
                        }
                        
                        this.setState({enableScrollTillEnd: false, checkNextPagination: false });
                    }
                }
            }
            this.props.setIsLoading(false)
        } catch (err) {
            console.log({ err });
        }
    }

    fetchCategoryIdBySlug = async (slug) => {
        let url
        if (slug) {
            slug = slug?.split(/\/product/)
            if (slug && slug[1])
                url = `/api/get-id-by-category/?slug=${'products' + slug[1]}`
        }
        try {
            this.props.setIsLoading(true)
            let response = await callApi(url, 'GET');

            if (response && response?.code !== 500 && response?.code !== 400 && response?.code !== 404) {

                if (response?.category_id) {
                    this.setState({ subCategory: response.category_id })
                    this.onHandleClickSubCat({ enable: false, item: false, subCatId: response.category_id });
                    return response
                }
            }
            this.props.setIsLoading(false)
        } catch (err) {
            console.log({ err });
            return undefined
        }
    }

    callAbleChildRenders = ({ childItems, item }) => {
        return (
            <Collapse isOpen={childItems[item.category]?.isOpen}>
                <ul className="list-unstyled pl-2 fs-14">
                    {childItems[item.category]?.values &&
                        Array.isArray(childItems[item.category]?.values) &&
                        childItems[item.category]?.values?.length ?
                        childItems[item.category]?.values.sort((a, b) => {
                            if (a.category < b.category) { return -1; }
                            if (a.category > b.category) { return 1; }
                            return 0;
                        }).map((item_child, item_index) =>
                            <li className={`py-2 ${childItems[item.category]?.values?.length == item_index + 1 ? '' : 'border-bottom'}`}>
                                <div className="category-collapse-holder">
                                    {childItems[item_child.category]?.isOpen &&
                                        this.callAbleChildRenders({ childItems, item: item_child })}
                                    <div className="category-collapse-trigger">
                                        <div className="d-flex align-items-start">
                                            <button
                                                onClick={() => this.onClickCategory(item, item_child)}
                                                className={`text-dark nav-link-decoration-none ff-base p-0 text-left font-weight-normal btn btn-link ${this.state.currentCategoryId === item_child?.id && " option-active"} ${!!item_child.children_count ? " cursor-default" : " cursor-pointer"}`}>
                                                {item_child.category}
                                            </button>
                                            {item_child.children_count > 0 && (
                                                <button
                                                    type="button"
                                                    id="cat_toggler1235"
                                                    onClick={() => { this.getAllSubCategories(item_child) }}
                                                    className="ml-auto btn btn-transparent btn-sm">
                                                    {!childItems[item_child.category]?.isOpen ?
                                                        (<i className="icon-plus-cat _sm" />) :
                                                        (<i className="icon-minus-cat _sm" />)
                                                    }
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ) : null}
                </ul>
            </Collapse>
        )
    }

    componentDidMount = () => {
        if (this.props?.location?.state?.parent_category && this.props?.location?.state?.id)
            this.onHandleClickSubCat({ enable: false, item: false, initialCall: true });
        else if (this.state.subCategorySlug)
            this.fetchCategoryIdBySlug(this.state.subCategorySlug)
        window.scrollTo(0, 0)
        window.addEventListener('scroll', this.handleScroll, { passive: false });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    onHandleClickSubCat = ({ enable, item, reset, subCatId, initialCall }) => {
        let { selectedItem, parentCategory, subCategory, currentCategoryId, starRatingSelected, priceSelected } = this.state;
        const { match, location } = this.props
        let category = match?.params?.category;
        // let { id, parent_category } = location?.state;
        let id = subCatId || this.props?.location?.state?.id || subCategory         //prioritize to function param
        let parent_category = this.props?.location?.state?.parent_category || parentCategory //prioritize to prop param

        if (enable) {
            window.scrollTo(0, 0);
            this.setState({ products_list: [], currentPageIndex: 1, selectedItem: item, currentCategoryId: null }, () => {
                this.props.setIsLoading(true);
                this.fetchMiddleItems({
                    name: parent_category,
                    id: item?.id || id,
                    page: this.state.currentPageIndex,
                    stars: starRatingSelected,
                    price: priceSelected
                });
                if (reset) {
                    this.fetchCollageItems({ parent_category });
                    this.fetchSubCateories({ parent_category, id });
                }

                //changing url route
                this.changeUrlSlug(item.seo_label)
            });
        } else {
            this.props.setIsLoading(true);
            this.fetchMiddleItems({
                name: parent_category,
                id: selectedItem?.id ? selectedItem.id : currentCategoryId || id,
                stars: starRatingSelected,
                price: priceSelected,
                page: this.state.currentPageIndex
            });
            if (initialCall) this.fetchCollageItems({ parent_category });
            initialCall && this.fetchSubCateories({ parent_category, id });
            initialCall && this.setState({ priceSelected: null, starRatingSelected: null })
        }
    }

    changeUrlSlug = (url) => {

        if (url) {
            let urlToPush = url.split(/products\//)
            if (urlToPush?.[1])
                this.props.history.replace('/product/' + urlToPush[1])
        }
    }

    handleScroll = () => {
        let scrollTillHeight = window.pageYOffset;
        let lastElemHeight = document.getElementById("last-index")?.offsetTop;
        let currentPageIndex = this.state.currentPageIndex;
        if (scrollTillHeight > lastElemHeight && this.state.checkNextPagination) {
            if (this.state.enableScrollTillEnd) {
                this.setState({ enableScrollTillEnd: false })
                currentPageIndex++;
                this.setState({ currentPageIndex }, () => {
                    this.onHandleClickSubCat({ enable: false, item: false });
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        let nextProps = this.props;

        if ((nextProps?.match?.params?.category !== prevProps?.match?.params?.category) || (nextProps?.match?.params?.category2 !== prevProps?.match?.params?.category2)) {
            this.setState({ category: nextProps?.match?.params?.category2 || nextProps?.location?.state?.child_category, subCategory: nextProps?.history?.location?.state?.id }, () => {
                this.onHandleClickSubCat({ enable: true, item: false, reset: true, initialCall: true });
            })
        }

        // const previousContent = prevProps?.match?.params?.category;
        // const newContent = this.props?.match?.params?.category;
        // if (previousContent != newContent) {
        //     this.setState({ category: newContent, collage_items: [] })
        //     this.props.fetch_products_categories({ category: 'products', depth: false, delsel: '', ancestor: '', sub_cat: '', level: 1 });

        // }
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps?.products_categories) {
            let product_categories = []
            product_categories = nextProps?.products_categories && nextProps?.products_categories[0]?.children
            product_categories.map(cat => {
                if (cat.category.toUpperCase() === this.state.category.toUpperCase()) {
                    this.setState({ subCategory: cat.id })
                }
            })
            // if (this.state.collage_items.length === 0) {
            //     this.props.fetch_products_category_collage_items({ trending: 'national', content_type: 'products', listingtaxonomy: taxonomy_id });
            //     this.props.fetch_products_list({ content_type: 'products', cat: taxonomy_id, showReview: true, cPage: true, page: 1 })
            // }
        }

        // if (!!nextProps?.products_items) {
        //     this.setState({ products_list: nextProps.products_items.results });
        //     // this.setState({ enableScrollTillEnd: true, checkNextPagination: nextProps.products_items.next });

        //     let new_product_list = [];
        //     if (nextProps?.products_items?.results && nextProps?.products_items?.results[0]?._source) {
        //         nextProps.products_items?.results?.map(product => {
        //             new_product_list.push(product?._source)
        //         })
        //         console.log("WHY MANNNN??????", new_product_list)
        //         this.setState({ products_list: new_product_list });
        //     }
        // }
    }

    handleTabChange = (tab) => {
        const { subCategory, currentCategoryId, starRatingSelected, priceSelected } = this.state
        // this.props.fetch_products_list_dispatch(null);
        this.setState({
            products_list: [],
            selectedTab: tab,
            currentPageIndex: 1,
            enableScrollTillEnd: true,
            scrollType: "cat"
        }, () => {
            this.props.setIsLoading(true)
            this.fetchMiddleItems({
                name: 'products',
                id: currentCategoryId || subCategory,
                page: 1,
                stars: starRatingSelected,
                price: priceSelected
            });

            // if (tab === 'all') {
            //     this.props.fetch_products_list(this.removeNullEntries({
            //         content_type: 'products',
            //         stars: starRatingSelected,
            //         price: priceSelected,
            //         cat: currentCategoryId || subCategory,
            //         showReview: true,
            //         cPage: true,
            //         page: 1
            //     }))
            // }
            // else if (tab === 'friendsPick') {
            //     this.props.fetch_friendspick_products_list(this.removeNullEntries({
            //         content_type: 'products',
            //         stars: starRatingSelected,
            //         price: priceSelected,
            //         cat: currentCategoryId || subCategory,
            //         to_api: true,
            //         showReview: true,
            //         location: false,
            //         page: 1
            //     }))
            // }
            // else if (tab === 'recommended') {
            //     this.props.fetch_recommended_products_list(this.removeNullEntries({
            //         content_type: 'products',
            //         stars: starRatingSelected,
            //         price: priceSelected,
            //         cat: currentCategoryId || subCategory,
            //         to_api: true,
            //         showReview: true,
            //         location: false,
            //         page: 1,
            //         recommends: true
            //     }))
            // }
            // else {
            //     return null
            // }
        });

    }

    handleStarFilter = (star) => {
        this.props.setIsLoading(true)
        this.setState({
            starRatingSelected: star,
            currentPageIndex: 1,
            enableScrollTillEnd: true,
            products_list: []
        }, () => {
            // this.props.fetch_filtered_products_category(this.removeNullEntries({ content_type: "products", stars: star, price: this.state.priceSelected, cat: '', subcat: this.state.currentCategoryId || this.state.subCategory, to_api: true, showReview: true, cPage: true, location: false, page: 1 }))
            this.fetchMiddleItems({
                name: 'products',
                id: this.state.currentCategoryId || this.state.subCategory,
                page: 1,
                stars: star || null,
                price: this.state.priceSelected
            });

        })
    }
    handlePriceFilter = (price) => {
        this.props.setIsLoading(true);
        this.setState({
            priceSelected: price,
            currentPageIndex: 1,
            enableScrollTillEnd: true,
            products_list: []
        }, () => {
            // this.props.fetch_filtered_products_category(this.removeNullEntries({ content_type: "products", stars: this.state.starRatingSelected, price: price, cat: '', subcat: this.state.currentCategoryId || this.state.subCategory, to_api: true, showReview: true, cPage: true, location: false, page: 1 }))
            this.fetchMiddleItems({
                name: 'products',
                id: this.state.currentCategoryId || this.state.subCategory,
                page: 1,
                stars: this.state.starRatingSelected,
                price: price
            });

        })
    }

    onClickCategory = (itemParent, itemChild) => {
        let item = itemParent
        let item_child

        if (!!itemChild) {
            item_child = itemChild
        } else {
            item_child = item
        }

        let slug = item?.seo_label?.split(/products\//)
        if (slug && slug[1]) {
            this.props.history.push({
                pathname: `${'/product/' + slug[1]}`,
                state: { id: item.id, parent_category: "products" }
            })
        } else {
            if (!!item_child) {
                this.setState({
                    scrollType: "subcat",
                    currentPageIndex: 1,
                    enableScrollTillEnd: true,
                    currentCategoryId: item_child?.id,
                    starRatingSelected: null,
                    priceSelected: null,
                    products_list: []
                }, () => {
                    this.props.setIsLoading(true)
                    const { starRatingSelected, priceSelected } = this.state;

                    this.fetchMiddleItems({
                        name: 'products',
                        id: item_child.id,
                        page: 1,
                        stars: starRatingSelected,
                        price: priceSelected
                    });

                    // if (this.state.selectedTab === 'all') {
                    //     this.setState({ products_list: [] });
                    //     this.props.fetch_products_list({
                    //         content_type: 'products',
                    //         subcat: item_child.id,
                    //         showReview: true,
                    //         cPage: true,
                    //         page: 1
                    //     })
                    // }
                    // else if (this.state.selectedTab === 'friendsPick') {
                    //     this.setState({ products_list: [] });
                    //     this.props.fetch_friendspick_products_list({
                    //         content_type: 'products',
                    //         subcat: item_child.id,
                    //         to_api: true,
                    //         showReview: true,
                    //         cPage: true,
                    //         location: false,
                    //         page: 1
                    //     })
                    // }
                    // else if (this.state.selectedTab === 'recommended') {
                    //     this.setState({ products_list: [] });
                    //     this.props.fetch_recommended_products_list({
                    //         content_type: 'products',
                    //         subcat: item_child.id,
                    //         to_api: true,
                    //         showReview: true,
                    //         cPage: true,
                    //         location: false,
                    //         page: 1,
                    //         recommends: true
                    //     })
                    // }
                })
            }

        }

    }

    removeNullEntries = (obj) => {
        let nullFreeObj = Object.fromEntries(Object.entries(obj).filter(([_, item]) => item != null));
        return nullFreeObj
    }

    goToPreviousCategory = async (e, item) => {

        e.preventDefault();

        if (item) {
            if (item.grand_parent_seo_label === 'products' || item?.grand_parent_seo_label === null)
                this.props.history.push('/products')
            else {
                let slug = item?.grand_parent_seo_label?.split(/products\//)
                if (slug && slug[1]) {
                    this.props.history.push({
                        pathname: `${'/product/' + slug[1]}`,
                        state: { id: item.grand_parent_id, parent_category: "products" }
                    })
                } else {
                    this.props.history.push('/products')
                }

            }
        } else {                 //runs category listing array got empty at final stage

            let response = await this.fetchCategoryIdBySlug(this.props?.match?.url)              //geting parent id and slug 
            if (response?.grand_parent_slug && response?.grand_parent_id && response?.grand_parent_slug !== 'products') {
                let slug = response?.grand_parent_slug?.split(/products\//)
                if (slug && slug[1]) {
                    this.props.history.push({
                        pathname: `${'/product/' + slug[1]}`,
                        state: { id: response.grand_parent_id, parent_category: "products" }
                    })
                }
            } else {
                this.props.history.push('/products')
            }

        }
    }

    onClickAllCategory = () => {

        this.setState({ currentCategoryId: this.state.subCategory }, () => {
            this.handleTabChange(this.state.selectedTab)
        })
    }

    render() {
        const { category, collage_items, subCategory, products_list, selectedTab,
            isLoadingCategories, categorisListing, childItems, currentCategoryId, starRatingSelected, priceSelected, parentCategory } = this.state;
        return (
            <div>
                <HelmetMetaData
                    title={getSubCategoryTitle(parentCategory, category)}
                    description={getSubCategoryDescription(parentCategory, category)}
                />
                <AppHeader isEnableCover={false} />
                <header className="nw-header-min-height" tyle={{ overflowY: 'hidden' }}>
                    {collage_items && collage_items?.length >= 6 ?
                        <Row noGutters xs={1} md={4}>
                            <Col className="d-flex flex-column">
                                <Link
                                    to={{
                                        pathname: `/products/${collage_items[0]?.slug}`,
                                        state: { id: collage_items[0]?.id }
                                    }}>
                                    <div className="flex-grow-1 featured-item">

                                        <img className="feature-project-image _full" src={collage_items[0]?.listing_profileimage && collage_items[0]?.listing_profileimage[0]?.location} alt={collage_items[0]?.name} />
                                        <div className="featured-details hoverable">
                                            <Link
                                                to={{
                                                    pathname: `/products/${collage_items[0]?.slug}`,
                                                    state: { id: collage_items[0]?.id }
                                                }}
                                                className="text-reset mb-2 d-block text-decoration-none">
                                                <h2 className="fs-25 text-uppercase">{collage_items[0]?.name}</h2>
                                            </Link>
                                            <div className="featured-item-credit">
                                                by <span>{collage_items[0]?.manufacturer}</span>
                                            </div>
                                            <div className="mt-2">
                                                <img title={collage_items[0]?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[0]?.total_rating?.avg_rating[0]}`} alt={collage_items[0]?.total_rating?.avg_rating[1]} />
                                            </div>

                                            <div className="fs-13 hover-appear mt-2">
                                                <div>
                                                    {collage_items[0]?.latestreview?.review
                                                        ?
                                                        <span>
                                                            <i className="icon-quote-left"></i>
                                                            {collage_items[0]?.latestreview?.review.length < 60 ?
                                                                (collage_items[0]?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
                                                                (collage_items[0]?.latestreview?.review.substring(0, 60) + '...').replace(/<\/?[^>]+(>|$)/g, "")
                                                            }
                                                            <i className="icon-quote-right"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <Link
                                                                to={{
                                                                    pathname: `/${collage_items[0] && (collage_items[0]?.content_type).toLowerCase()}/${collage_items[0] && collage_items[0].slug ? collage_items[0].slug : null}`,
                                                                    state: { id: collage_items[0]?.id }
                                                                }} >Be the first one to review.</Link>
                                                        </span>

                                                    }
                                                </div>

                                                <div className="text-right">
                                                    from <span className="d-inline-block mr-2">{collage_items[0]?.latestreview?.created_by}</span>
                                                    <img title={collage_items[0]?.latestreview?.ratings_sets?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[0]?.latestreview?.ratings_sets?.avg_rating[0]}`} alt={collage_items[0]?.latestreview?.ratings_sets?.avg_rating[1]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="d-flex flex-column">
                                {collage_items && collage_items.slice(1, 3).map((item) =>
                                (
                                    <Link
                                        to={{
                                            pathname: `/products/${item?.slug}`,
                                            state: { id: item?.id }
                                        }}
                                    >
                                        <div className="flex-grow-1 featured-item">
                                            <img className="feature-project-image _half" src={item?.listing_profileimage[0]?.location} alt={item?.name} />
                                            <div className="featured-details hoverable">
                                                <Link
                                                    to={{
                                                        pathname: `/products/${item?.slug}`,
                                                        state: { id: item?.id }
                                                    }}
                                                    className="text-reset mb-2 d-block text-decoration-none">
                                                    <h2 className="fs-25 text-uppercase">{item?.name}</h2>
                                                </Link>
                                                <div className="featured-item-credit">
                                                    by <span>{item?.manufacturer}</span>
                                                </div>
                                                <div className="mt-2">
                                                    <img title={item?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.total_rating?.avg_rating[0]}`} alt={item?.total_rating?.avg_rating[1]} />
                                                </div>

                                                <div className="fs-13 hover-appear mt-2">
                                                    <div>
                                                        {item?.latestreview?.review
                                                            ?
                                                            <span>
                                                                <i className="icon-quote-left"></i>
                                                                {item?.latestreview?.review.length < 60 ?
                                                                    (item?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
                                                                    (item?.latestreview?.review.substring(0, 60) + '...').replace(/<\/?[^>]+(>|$)/g, "")
                                                                }
                                                                <i className="icon-quote-right"></i>
                                                            </span>
                                                            :
                                                            <span>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/${item && (item?.content_type).toLowerCase()}/${item && item.slug ? item.slug : null}`,
                                                                        state: { id: item?.id }
                                                                    }} >Be the first one to review.</Link>
                                                            </span>

                                                        }
                                                    </div>

                                                    <div className="text-right">
                                                        from <span className="d-inline-block mr-2">{item?.latestreview?.created_by}</span>
                                                        <img title={item?.latestreview?.ratings_sets?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.latestreview?.ratings_sets?.avg_rating[0]}`} alt={item?.latestreview?.ratings_sets?.avg_rating[1]} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Link>
                                ))}
                            </Col>
                            <Col className="d-flex flex-column">
                                <Link
                                    to={{
                                        pathname: `/products/${collage_items[3]?.slug}`,
                                        state: { id: collage_items[3]?.id }
                                    }}
                                >
                                    <div className="flex-grow-1 featured-item">
                                        <img className="feature-project-image _full" src={collage_items[3]?.listing_profileimage && collage_items[3]?.listing_profileimage[0]?.location} alt={collage_items[3]?.name} />
                                        <div className="featured-details hoverable">
                                            <Link
                                                to={{
                                                    pathname: `/products/${collage_items[3]?.slug}`,
                                                    state: { id: collage_items[3]?.id }
                                                }}
                                                className="text-reset mb-2 d-block text-decoration-none">
                                                <h2 className="fs-25 text-uppercase">{collage_items[3]?.name}</h2>
                                            </Link>
                                            <div className="featured-item-credit">
                                                by <span>{collage_items[3]?.manufacturer}</span>
                                            </div>
                                            <div className="mt-2">
                                                <img title={collage_items[3]?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[3]?.total_rating?.avg_rating[0]}`} alt={collage_items[3]?.total_rating?.avg_rating[1]} />
                                            </div>

                                            <div className="fs-13 hover-appear mt-2">
                                                <div>
                                                    {collage_items[3]?.latestreview?.review
                                                        ?
                                                        <span>
                                                            <i className="icon-quote-left"></i>
                                                            {collage_items[3]?.latestreview?.review.length < 60 ?
                                                                (collage_items[3]?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
                                                                (collage_items[3]?.latestreview?.review.substring(0, 60) + '...').replace(/<\/?[^>]+(>|$)/g, "")
                                                            }
                                                            <i className="icon-quote-right"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <Link
                                                                to={{
                                                                    pathname: `/${collage_items[3] && (collage_items[3]?.content_type).toLowerCase()}/${collage_items[3] && collage_items[3].slug ? collage_items[3].slug : null}`,
                                                                    state: { id: collage_items[3]?.id }
                                                                }} >Be the first one to review.</Link>
                                                        </span>

                                                    }
                                                </div>

                                                <div className="text-right">
                                                    from <span className="d-inline-block mr-2">{collage_items[3]?.latestreview?.created_by}</span>
                                                    <img title={collage_items[3]?.latestreview?.ratings_sets?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[3]?.latestreview?.ratings_sets?.avg_rating[0]}`} alt={collage_items[3]?.latestreview?.ratings_sets?.avg_rating[1]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col className="d-flex flex-column">
                                {collage_items && collage_items.slice(4, 6).map((item) =>
                                (
                                    <Link
                                        to={{
                                            pathname: `/products/${item?.slug}`,
                                            state: { id: item?.id }
                                        }}
                                    >
                                        <div className="flex-grow-1 featured-item">
                                            <img className="feature-project-image _half" src={item?.listing_profileimage[0]?.location} alt={item?.name} />
                                            <div className="featured-details hoverable">
                                                <Link
                                                    to={{
                                                        pathname: `/products/${item?.slug}`,
                                                        state: { id: item?.id }
                                                    }}
                                                    className="text-reset mb-2 d-block text-decoration-none">
                                                    <h2 className="fs-25 text-uppercase">{item?.name}</h2>
                                                </Link>
                                                <div className="featured-item-credit">
                                                    by <span>{item?.manufacturer}</span>
                                                </div>
                                                <div className="mt-2">
                                                    <img title={item?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.total_rating?.avg_rating[0]}`} alt={item?.total_rating?.avg_rating[1]} />
                                                </div>

                                                <div className="fs-13 hover-appear mt-2">
                                                    <div>
                                                        {item?.latestreview?.review
                                                            ?
                                                            <span>
                                                                <i className="icon-quote-left"></i>
                                                                {item?.latestreview?.review.length < 60 ?
                                                                    (item?.latestreview?.review).replace(/<\/?[^>]+(>|$)/g, "") :
                                                                    (item?.latestreview?.review.substring(0, 60) + '...').replace(/<\/?[^>]+(>|$)/g, "")
                                                                }
                                                                <i className="icon-quote-right"></i>
                                                            </span>
                                                            :
                                                            <span>
                                                                <Link
                                                                    to={{
                                                                        pathname: `/${item && (item?.content_type).toLowerCase()}/${item && item.slug ? item.slug : null}`,
                                                                        state: { id: item?.id }
                                                                    }} >Be the first one to review.</Link>
                                                            </span>

                                                        }
                                                    </div>

                                                    <div className="text-right">
                                                        from <span className="d-inline-block mr-2">{item?.latestreview?.created_by}</span>
                                                        <img title={item?.latestreview?.ratings_sets?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.latestreview?.ratings_sets?.avg_rating[0]}`} alt={item?.latestreview?.ratings_sets?.avg_rating[1]} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Link>
                                ))}
                            </Col>

                        </Row>
                        :
                        ''
                    }
                </header>

                <section className="position-relative" style={{ zIndex: 3, marginTop: '-40px' }}>
                    <Container>
                        <div>
                            <Row className="align-items-end">
                                <Col xs="auto">
                                    <a
                                        onClick={(e) => this.goToPreviousCategory(e, categorisListing?.[0])}
                                        href="/products/category"
                                        className="shade-link d-inline-block _black _padded">
                                        {'Back to Category'}
                                    </a>
                                </Col>
                                <Col>
                                    <div className="text-center text-white black-shade mx-auto" style={{ maxWidth: '500px' }}>
                                        <h1 className="p-0 m-0 fs-34" style={{ 'text-transform': 'capitalize' }}>{category}</h1>
                                    </div>
                                </Col>
                                <Col xs="auto">
                                    <Link
                                        to={{
                                            pathname: `/product/${this.state.category}/buyers-guide`,
                                            state: { taxonomyId: subCategory }
                                        }}
                                        className="btn btn-primary fs-16"
                                    >See Buyer's Guide</Link>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>

                {/* Main Content Starts */}
                <section className="py-4 fs-14 mx-auto" style={{ maxWidth: '1000px' }}>
                    <Container>
                        <Row>
                            <Col xs="auto" className="flex-180">
                                <div className="mb-5">
                                    <h2 className="fs-36 text-tertiary">{'Categories'}</h2>
                                    <hr className="bg-tertiary mb-2" />

                                    <div>
                                        <ul className="list-unstyled wk-loader-new-spinner position-relative">
                                            {
                                                isLoadingCategories &&
                                                <div className="text-center py-3 spinner">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            }
                                            {!isLoadingCategories && <li className={`py-2 border-bottom`}>
                                                <div className="category-collapse-holder">
                                                    <div className="d-flex align-items-start">
                                                        <Button
                                                            onClick={() => this.onClickAllCategory()}
                                                            color="link"
                                                            className={`p-0 text-darker font-weight-bold nav-link-decoration-none ff-base text-left mr-2`}>
                                                            All Categories
                                                        </Button>
                                                    </div>
                                                </div>
                                            </li>}
                                            {categorisListing && Array.isArray(categorisListing) && categorisListing.length ?
                                                categorisListing.map((item, item_index) => (
                                                    <li className={`py-2 ${categorisListing?.length == item_index + 1 ? '' : 'border-bottom'}`}>
                                                        <div className="category-collapse-holder">
                                                            {childItems[item.category]?.isOpen && this.callAbleChildRenders({ childItems, item })}
                                                            <div className="category-collapse-trigger">
                                                                <div className="d-flex align-items-start">
                                                                    <Button
                                                                        onClick={() => this.onClickCategory(item)}
                                                                        color="link"
                                                                        className={`p-0 text-darker font-weight-bold ff-base nav-link-decoration-none text-left mr-2 `}>
                                                                        {item.category}
                                                                    </Button>
                                                                    {
                                                                        item.children_count > 0 &&
                                                                        <button
                                                                            type="button"
                                                                            id="cat_toggler1235"
                                                                            onClick={() => { this.getAllSubCategories(item) }}
                                                                            className="ml-auto btn btn-transparent btn-sm">
                                                                            {!childItems[item.category]?.isOpen ?
                                                                                (<i className="icon-plus-cat _sm" />) :
                                                                                (<i className="icon-minus-cat _sm" />)
                                                                            }
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                                : !isLoadingCategories &&
                                                <h5>{'No Result Found'}</h5>

                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2 text-dark">Star Rating</div>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' checked={starRatingSelected === 5} name="filterByStarRating" onClick={() => this.handleStarFilter(5)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_6.png" alt="5 Rating" />
                                                <span>only</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' checked={starRatingSelected === 4} name="filterByStarRating" onClick={() => this.handleStarFilter(4)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_5.png" alt="4 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' checked={starRatingSelected === 3} name="filterByStarRating" onClick={() => this.handleStarFilter(3)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_4.png" alt="3 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' checked={starRatingSelected === 2} name="filterByStarRating" onClick={() => this.handleStarFilter(2)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_3.png" alt="2 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' checked={starRatingSelected === 1} name="filterByStarRating" onClick={() => this.handleStarFilter(1)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_2.png" alt="1 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" className='star-filter-input-checkbox' checked={starRatingSelected === 0} name="filterByStarRating" onClick={() => this.handleStarFilter(0)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src="https://userdatawikireviews.s3.amazonaws.com/images/star_1.png" alt="0 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <hr className="bg-tertiary" />
                                </div>

                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2 text-dark">Price</div>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" checked={priceSelected === 10} name="filterByPrice" onClick={() => this.handlePriceFilter(10)} />{' '}
                                            $
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" checked={priceSelected === 100} name="filterByPrice" onClick={() => this.handlePriceFilter(100)} />{' '}
                                            $$
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" checked={priceSelected === 1000} name="filterByPrice" onClick={() => this.handlePriceFilter(1000)} />{' '}
                                            $$$
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" checked={priceSelected === 10000} name="filterByPrice" onClick={() => this.handlePriceFilter(10000)} />{' '}
                                            $$$$
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio" checked={priceSelected === 100000} name="filterByPrice" onClick={() => this.handlePriceFilter(100000)} />{' '}
                                            $$$$$
                                        </Label>
                                    </FormGroup>
                                    <hr className="bg-tertiary" />
                                </div>

                                <div>
                                    <div className="bg-white text-center">
                                        <a rel="noopener noreferrer" href="http://wikireviews.com/blog" target="_blank" className="text-reset">
                                            <img className="img-fluid blog-img" src={"https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_05.png"} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <Nav className="active-transparent non-active-normal active-bold hover-none" tabs>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base h-100"
                                            active={this.state.viewProductsType === "all"}
                                            onClick={() => {
                                                this.setState({ viewProductsType: "all" });
                                                this.handleTabChange('all')
                                            }}
                                        >
                                            All
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base h-100"
                                            active={this.state.viewProductsType === "friendsPick"}
                                            onClick={() => {
                                                this.setState({ viewProductsType: "friendsPick" });
                                                this.handleTabChange('friendsPick')
                                            }}
                                        >
                                            Friends Pick
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="ff-base h-100"
                                            active={this.state.viewProductsType === "recommended"}
                                            onClick={() => {
                                                this.setState({ viewProductsType: "recommended" });
                                                this.handleTabChange('recommended')
                                            }}
                                        >
                                            Highly Recommended
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.viewProductsType}>
                                    <TabPane tabId="all">
                                        <div>
                                            <Row xs="auto" sm={2} lg={3} form>
                                                {this.props.is_loading && !products_list?.length ?
                                                    <div>
                                                        <h2>{"Loading..."}</h2>
                                                    </div>
                                                    : <>
                                                        {selectedTab === 'all' && products_list?.length ?
                                                            products_list.map((review, index) => (
                                                                <Col
                                                                    className="mb-3"
                                                                    id={products_list?.length == index + 1 ? `last-index` : `current-index-${index}`}
                                                                    key={index}>
                                                                    <div className="bg-white text-dark p-2 hoverable-review" style={{ height: '256px' }}>
                                                                        <div className="review-details">
                                                                            <div className="text-center">
                                                                                <img
                                                                                    className="fixed-height-img is-120 w-100 object-fit-contain object-position-top"
                                                                                    // src="https://userdatawikireviews.s3.amazonaws.com/media/inditex/fd070664945be0033eb7c8e1197a1797.jpg"
                                                                                    src={review?.listing_profileimage && review?.listing_profileimage[0] && review?.listing_profileimage[0].location ? review?.listing_profileimage[0].location : "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"}
                                                                                    onError={(error) =>
                                                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                                    }
                                                                                    alt={review.name}
                                                                                />
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <div className="font-weight-bold fs-14 lh-1">
                                                                                    <div className="clamp-product-name">
                                                                                        <Link to={{
                                                                                            pathname: (`/products/${review?.slug}/`).toLowerCase(),
                                                                                            state: { id: review?.id }
                                                                                        }} className="text-reset">
                                                                                            {/* {review?.name?.length < 70 ? review?.name : review?.name.substring(0, 70) + '...'} */}
                                                                                            {review.name}
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mb-1">
                                                                                    <img
                                                                                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${review?.total_rating?.avg_rating && review?.total_rating?.avg_rating[0]}`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <p className="fs-13">
                                                                                    <a href="#" className="text-reset">
                                                                                        {review?.total_rating?.total_review} review{review?.total_rating?.total_review > 1 ? 's' : ''}
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="fs-14 review-mask">
                                                                            <div className="p-2">
                                                                                <div className="text-center">
                                                                                    {review?.review === '' ?
                                                                                        ''
                                                                                        :
                                                                                        <span className="comment-body-renderer clamp-line clamp-line-8">
                                                                                            {/* {ReactHtmlParser(
                                                                                    review?.review?.review.length < 150
                                                                                        ? review?.review?.review
                                                                                        : review?.review?.review.substring(
                                                                                        0,
                                                                                        150
                                                                                        ) + "..."
                                                                                    )} */}
                                                                                            {ReactHtmlParser(detectlink(review?.review?.review))}
                                                                                        </span>
                                                                                    }

                                                                                </div>

                                                                                <div className="text-center mt-3">
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: (`/products/${review?.slug}/`).toLowerCase(),
                                                                                            state: { id: review?.id }
                                                                                        }}
                                                                                        className="text-reset font-weight-bold">
                                                                                        {'View More'}
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )) : (
                                                                <span className='text-dark'>
                                                                    No Results Found. Please Choose Another Category
                                                                </span>
                                                            )
                                                        }</>}

                                            </Row>
                                            {
                                                (products_list &&
                                                    Array.isArray(products_list) &&
                                                    products_list.length && this.props.is_loading) ?
                                                    <div className="mt-5 text-center">
                                                        <h2>{"Loading more products..."}</h2>
                                                    </div> : ''
                                            }
                                            <div style={{ height: "400px" }} />
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="friendsPick">
                                        <div>
                                            <Row xs="auto" sm={2} lg={3} form>
                                                {this.props.is_loading && !products_list?.length ?
                                                    <div>
                                                        <h2>{"Loading..."}</h2>
                                                    </div>
                                                    : <>
                                                        {selectedTab === 'friendsPick' && products_list?.length ? products_list.map((review, index) => {
                                                            return <Col className="mb-3" id={products_list?.length == index + 1 ? `last-index` : `current-index-${index}`}>
                                                                <div className="bg-white text-dark p-2 hoverable-review" style={{ height: '256px' }}>
                                                                    <div className="review-details">
                                                                        <div className="text-center">
                                                                            <img
                                                                                className="fixed-height-img is-120 w-100 object-fit-cover object-position-top"
                                                                                // src="https://userdatawikireviews.s3.amazonaws.com/media/inditex/fd070664945be0033eb7c8e1197a1797.jpg"
                                                                                //src={review?.listing_profileimage && review?.listing_profileimage[0] && review?.listing_profileimage[0].thumbnail}
                                                                                src={review?.listing_profileimage && review?.listing_profileimage[0] && review?.listing_profileimage[0].location ? review?.listing_profileimage[0].location : "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"}
                                                                                onError={(error) =>
                                                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                                }
                                                                                alt={review.name}
                                                                            />
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            <div className="font-weight-bold fs-14 lh-1">
                                                                                <div className="clamp-product-name">
                                                                                    <Link to={{
                                                                                        pathname: (`/products/${review?.slug}/`).toLowerCase(),
                                                                                        state: { id: review?.id }
                                                                                    }} className="text-reset">
                                                                                        {/* {review?.name?.length < 70 ? review?.name : review?.name?.substring(0, 70) + '...'} */}
                                                                                        {review.name}
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-1">
                                                                                <img
                                                                                    src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${review?.total_rating?.avg_rating && review?.total_rating?.avg_rating[0]}`}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <p className="fs-13">
                                                                                <a href="#" className="text-reset">
                                                                                    {review?.total_rating?.total_review} review{review?.total_rating?.total_review > 1 ? 's' : ''}
                                                                                </a>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="fs-14 review-mask">
                                                                        <div className="p-2">
                                                                            <div className="text-center">
                                                                                {review?.review === '' ?
                                                                                    ''
                                                                                    :
                                                                                    <span className="comment-body-renderer clamp-line clamp-line-8">
                                                                                        {/* {ReactHtmlParser(
                                                                                    review?.review?.review.length < 150
                                                                                        ? review?.review?.review
                                                                                        : review?.review?.review.substring(
                                                                                        0,
                                                                                        150
                                                                                        ) + "..."
                                                                                    )} */}
                                                                                        {ReactHtmlParser(detectlink(review?.review?.review))}
                                                                                    </span>
                                                                                }
                                                                            </div>

                                                                            <div className="text-center mt-3">
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: (`/products/${review?.slug}`).toLowerCase(),
                                                                                        state: { id: review?.id }
                                                                                    }}
                                                                                    className="text-reset font-weight-bold">
                                                                                    {'View More'}
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        }) : (
                                                            <span className='text-dark'>
                                                                No Results Found. Please Choose Another Category
                                                            </span>
                                                        )}</>}
                                            </Row>
                                            {
                                                (products_list &&
                                                    Array.isArray(products_list) &&
                                                    products_list.length && this.props.is_loading) ?
                                                    <div className="mt-5 text-center">
                                                        <h2>{"Loading more products..."}</h2>
                                                    </div> : ''
                                            }
                                            <div style={{ height: "400px" }} />
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="recommended">
                                        <div>
                                            <Row xs="auto" sm={2} lg={3} form>
                                                {/* Repeat this */}
                                                {this.props.is_loading && !products_list?.length ?
                                                    <div>
                                                        <h2>{"Loading..."}</h2>
                                                    </div>
                                                    : <>
                                                        {selectedTab === 'recommended' && products_list?.length ? products_list.map((review, index) => {
                                                            return <Col className="mb-3" id={products_list?.length == index + 1 ? `last-index` : `current-index-${index}`}>
                                                                <div className="bg-white text-dark p-2 hoverable-review" style={{ height: '256px' }}>
                                                                    <div className="review-details">
                                                                        <div className="text-center">
                                                                            <img
                                                                                className="fixed-height-img is-120 w-100 object-fit-cover object-position-top"
                                                                                // src="https://userdatawikireviews.s3.amazonaws.com/media/inditex/fd070664945be0033eb7c8e1197a1797.jpg"
                                                                                //src={review?.listing_profileimage && review?.listing_profileimage[0] && review?.listing_profileimage[0].thumbnail}
                                                                                src={review?.listing_profileimage && review?.listing_profileimage[0] && review?.listing_profileimage[0].location ? review?.listing_profileimage[0].location : "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"}
                                                                                onError={(error) =>
                                                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                                }
                                                                                alt={review.name}
                                                                            />
                                                                        </div>
                                                                        <div className="mt-2">
                                                                            <div className="font-weight-bold fs-14 lh-1">
                                                                                <div className="clamp-product-name">
                                                                                    <Link to={{
                                                                                        pathname: (`/products/${review?.slug}/`).toLowerCase(),
                                                                                        state: { id: review?.id }
                                                                                    }} className="text-reset">
                                                                                        {/* {review?.name?.length < 70 ? review?.name : review?.name?.substring(0, 70) + '...'} */}
                                                                                        {review.name}
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mb-1">
                                                                                <img
                                                                                    src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${review?.total_rating?.avg_rating && review?.total_rating?.avg_rating[0]}`}
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <p className="fs-13">
                                                                                <a href="#" className="text-reset">
                                                                                    {review?.total_rating?.total_review} review{review?.total_rating?.total_review > 1 ? 's' : ''}
                                                                                </a>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="fs-14 review-mask">
                                                                        <div className="p-2">
                                                                            <div className="text-center">
                                                                                {review?.review === '' ?
                                                                                    ''
                                                                                    :
                                                                                    <span className="comment-body-renderer clamp-line clamp-line-8">
                                                                                        {/* {ReactHtmlParser(
                                                                                    review?.review?.review.length < 150
                                                                                        ? review?.review?.review
                                                                                        : review?.review?.review.substring(
                                                                                        0,
                                                                                        150
                                                                                        ) + "..."
                                                                                    )} */}
                                                                                        {ReactHtmlParser(detectlink(review?.review?.review))}
                                                                                    </span>
                                                                                }
                                                                            </div>

                                                                            <div className="text-center mt-3">
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: (`/products/${review?.slug}`).toLowerCase(),
                                                                                        state: { id: review?.id }
                                                                                    }}
                                                                                    className="text-reset font-weight-bold">
                                                                                    {'View More'}
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        }) : (
                                                            <span className='text-dark'>
                                                                No Results Found. Please Choose Another Category
                                                            </span>
                                                        )}</>}
                                            </Row>
                                            {
                                                (products_list &&
                                                    Array.isArray(products_list) &&
                                                    products_list.length && this.props.is_loading) ?
                                                    <div className="mt-5 text-center">
                                                        <h2>{"Loading more products..."}</h2>
                                                    </div> : ''
                                            }
                                            <div style={{ height: "400px" }} />
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                            <Col xs="auto" className="flex-350">
                                {/* {category && subCategory ?
                                    <UserFeedsPanel category={category} sub_category={subCategory} mainCategory="products" />
                                    : ''} */}
                                <UserFeedsPanel category={category} sub_category={subCategory} mainCategory="products" />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        )
    }
}

const mapState = (state) => ({
    collage_items: state.products_listing.products_category_collage_list,
    products_categories: state.products_listing.products_categories,
    products_sub_categories: state.products_listing.products_sub_categories,
    products_sub_categories1: state.products_listing.products_sub_categories1,
    products_sub_categories2: state.products_listing.products_sub_categories2,
    products_items: state.products_listing.products_items,
    is_loading: state.products_listing.isLoading

});

const mapProps = (dispatch) => ({
    fetch_products_category_collage_items: (data) => dispatch(fetch_products_category_collage_items(data)),
    setIsLoading: (data) => dispatch(setIsLoading(data)),
});

export default connect(mapState, mapProps)(ProductsCategory);
