import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Input,
    Button,
    Label,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CollapseBasic from "../../atoms/collapse";
import Moment from 'react-moment';
import ModalComponent from "../../atoms/modalComponent";
import ShareByEmail from "../../atoms/shared/shareByEmail";
import SettingsAndPrivacy from "./settingsAndPrivacy";
import { set_setting_enable } from "../../../actions/user";
import { connect } from "react-redux";
class HeaderCover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_value:"",
            settingsModalToggle: false,
        };
      }
    
    render() {
        const { self, my_profile_info, onMouseDown, onTouchStart, isImage, left, top, getImage, reposition, onChangeUploadCoverImage, handleCloseAndFetchReview, notPeopleTab = true } = this.props;
  

        let current_profile = require('../../../assets/images/icons/user-circle.png');
        if (my_profile_info && my_profile_info.current_profile_file !== null) {
            current_profile = my_profile_info.current_profile_file;
        }

        return (
            <div className="step-1">
                <header className="app-header" style={my_profile_info && my_profile_info.current_cover_pic ? { backgroundImage: `url(${my_profile_info.current_cover_pic})` } :
                    { backgroundImage: 'url("https://userdatawikireviews.s3.amazonaws.com/media/content/bg-masthead.cfe60a0dbe0110b8994629b13ba692b8d420a973.jpg")' }}>
                    <Container fluid={true} className="container-lg px-0 px-lg-3">
                        <div className="app-header-block d-flex flex-column justify-content-center">
                            <div className="position-relative d-flex flex-column flex-grow-1">
                                <Row className="flex-grow-1 justify-content-md-center" noGutters>
                                    <Col md="3" className="d-none d-md-block">
                                        <div>
                                            <div className="stat">
                                                <div className="count">
                                                    {my_profile_info && my_profile_info.statistics && my_profile_info.statistics.crp ? my_profile_info.statistics.crp : 0}
                                                </div>
                                                <div className="type">
                                                    Community Review Projects
                                                </div>
                                            </div>
                                            <div className="stat">
                                                <div className="count">
                                                    {my_profile_info && my_profile_info.statistics && my_profile_info.statistics.reviews ? my_profile_info.statistics.reviews : 0}
                                                </div>
                                                <div className="type">
                                                    Reviews
                                                </div>
                                            </div>
                                            <div className="stat">
                                                <div className="count">
                                                    {my_profile_info && my_profile_info.statistics && my_profile_info.statistics.contri ? my_profile_info.statistics.contri : 0}
                                                </div>
                                                <div className="type">
                                                    Contributions
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="text-center p-4">
                                        
                                            <div className={isImage ? "avatars" : "d-none"}>
                                                <div
                                                    className={isImage ? "avatar-img-holder pancontainer dragaa" : "d-none"}
                                                    id="pan_id"
                                                    data-orient="center"
                                                    data-canzoom="no"
                                                    onMouseDown={onMouseDown}
                                                    onTouchStart={onTouchStart}
                                                    ref={(rf) => { this.props.setHandle(self, rf); }} >
                                                    <img
                                                        src={current_profile}
                                                        alt="User"
                                                        style={{
                                                            "left": left,
                                                            "top": top
                                                        }} />

                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" className={isImage ? "text-royal-blue font-weight-bold" : "d-none"} onClick={getImage}>Save</a>

                                            {isImage && <div className={`avatars ${my_profile_info && my_profile_info.award_statistics && my_profile_info.award_statistics.avatar ? my_profile_info.award_statistics.avatar : ''} mt-2`}></div>}

                                            <div className={!isImage ? "header-profile" : "d-none"}>

                                                <div className={`avatars ${my_profile_info && my_profile_info.award_statistics && my_profile_info.award_statistics.avatar ? my_profile_info.award_statistics.avatar : ''}`}>

                                                    <div className="avatar-img-holder">
                                                        <img
                                                            style={{
                                                                "left": left,
                                                                "top": top
                                                            }} 
                                                            src={current_profile}
                                                            alt="User"
                                                        />
                                                    </div>

                                                </div>

                                                <div className="position-relative text-center" style={{ zIndex: 2, marginTop: -55, marginBottom: 20 }}>
                                                    <div className={`${notPeopleTab === false ? 'py-3 mx-auto' : 'mx-auto'}`} style={{ maxWidth: '110px' }}>

                                                        <div >
                                                            {notPeopleTab &&
                                                                <div className="d-flex justify-content-between mx-n1">
                                                                    <div className="px-1" onClick={reposition}>
                                                                        <div role="button" title="Move & set">
                                                                            <img width={20} src={require("../../../assets/images/icons/panning_icon.png")} alt="Move & set" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-1 step-1_myProfile">
                                                                        {/*<Input
                                                                        type="file"
                                                                        name="banner_image"
                                                                        id="branch_banner_image"
                                                                        accept="image/*"
                                                                        style={{ display: "none" }}
                                                                        onChange={handleOnFileUploadChange}
                                                                    />
                                                                    <Label
                                                                        for="branch_banner_image"
                                                                        title="Update Profile Image"
                                                                        role="button"
                                                                    >
                                                                        <FontAwesomeIcon icon="camera" />
																</Label>*/}
                                                                        <Label
                                                                            for="branch_banner_image"
                                                                            title="Update Profile Image"
                                                                            role="button"
                                                                            onClick={() => {
                                                                                handleCloseAndFetchReview()
                                                                            }}
                                                                        >
                                                                            <img width={24} src={require("../../../assets/images/icons/camera_icon.png")} alt="Upload" />
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        {/*<div className={upload ? "mt-3" : " mt-3 d-none"}>
                                                            <small className="font-weight-bold">Updating....</small>
                                                        </div>*/}

                                                        <div className="mt-3" hidden>
                                                            <div className="d-flex justify-content-between">
                                                                <Button color="dark" size="sm" className="mw px-1 mx-0">Cancel</Button>
                                                                <Button color="primary" size="sm" className="mw px-1 mx-0">Save</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h1 className="text-shadow mb-2 text-capitalize">{my_profile_info && my_profile_info.user && my_profile_info.user.first_name ? `${my_profile_info.user.first_name} ${my_profile_info.user.last_name}` : ''} </h1>
                                            <p className="ff-base text-shadow">Joined <Moment format="MMMM YYYY">
                                                {my_profile_info && my_profile_info.joining_date && my_profile_info.joining_date ? my_profile_info.joining_date : new Date()}
                                            </Moment></p>
                                            {my_profile_info && !notPeopleTab ? <>
                                                {!my_profile_info.connection_id ?
                                                    <Button className="mw" size="sm" color="primary" onClick={() => this.props.handleAddConnection(my_profile_info?.user?.email, my_profile_info?.vanity_url, my_profile_info?.user?.username)}>ADD ME AS FRIEND</Button>

                                                    : <select id="lang" value= {this.state.selected_value} name="change_request" 
                                                    
                                                      onChange={(e) => this.props.ChangeRequest(e.target.value,my_profile_info?.connection_id, my_profile_info?.vanity_url, my_profile_info?.user?.username,my_profile_info?.user?.email)} 
                                                      className="mb-0 form-control-sm form-control friends-select" style={{
                                                        width: 'fit-content',
                                                        margin: '0 auto'}}><option value="requested">Friend Request Sent</option>
                                                    <option value="request_again">Sent Request Again</option>
                                                    <option value="cancel_request">Cancel Request</option>
                                                    </select>}
                                                    <div className="mb-2"></div>
                                                    {!my_profile_info.following_flag ?
                                                <Button className="mw" size="sm" color="primary" onClick={() => this.props.handleFollowUnfollw(my_profile_info?.user?.id, my_profile_info?.user?.username, 'follow')}>FOLLOW ME</Button>
                                                : ''}
                                                <br/>

                                                {my_profile_info.following_flag ? 
                                                <Button className="mw" size="sm" color="primary" onClick={() => this.props.handleFollowUnfollw(my_profile_info?.user?.id,  my_profile_info?.user?.username,'unfollow')}>UNFOLLOW</Button>
                                                : ''}
                                            </>
                                                : ''}
                                        </div>
                                    </Col>
                                    <Col md="3 d-none d-md-block">
                                        <div className="d-flex h-100 flex-column text-right">
                                            <div className="stat">
                                                <div className="count">
                                                    {my_profile_info && my_profile_info.award_statistics && my_profile_info.award_statistics.count ? my_profile_info.award_statistics.count : 0}
                                                </div>
                                                <div className="type">
                                                    Awards
                                                </div>
                                            </div>
                                            <div>
                                                <ul className="list-unstyled d-flex flex-wrap justify-content-end mx-n2" style={{ maxHeight: '292px', overflowY: 'auto' }}>
                                                    {my_profile_info &&
                                                        my_profile_info.award_statistics &&
                                                        my_profile_info.award_statistics.other &&
                                                        my_profile_info.award_statistics.other.badges &&
                                                        my_profile_info.award_statistics.other.badges.length > 0 &&
                                                        my_profile_info.award_statistics.other.badges.map((badge, index) => {
                                                            let img = 'taaffeite';
                                                            if (badge[0] === 'Taaffeite') {
                                                                img = 'taaffeite';
                                                            } else if (badge[0] === 'Aldebaran') {
                                                                img = 'aldebaran';
                                                            } else if (badge[0] === 'Tanzanite') {
                                                                img = 'tanzanite';
                                                            } else if (badge[0] === 'Antares') {
                                                                img = 'antares';
                                                            } else if (badge[0] === 'Black Opal') {
                                                                img = 'black_opal';
                                                            } else if (badge[0] === 'Alpha Centauri') {
                                                                img = 'alpha_centauri';
                                                            } else if (badge[0] === 'Arcturus') {
                                                                img = 'arcturus';
                                                            } else if (badge[0] === 'Polaris') {
                                                                img = 'polaris';
                                                            }

                                                            // Added this less than 6 check as according to the staging site refrence.
                                                            return index < 6 && (
                                                                <li className={`px-2 mb-3 ${index==0 && `step-5_myProfile`} key-${index}`} key={index}>
                                                                    <a href="/#" className="user-badge-link" title={`${badge[0]} ${badge[1]}`}>
                                                                        <span className="user-badge">
                                                                            <span className="badge-date">{badge[1]}</span>
                                                                            <span className="badge-img">
                                                                                <img src={require(`../../../assets/images/icons/${img}.png`)} alt={badge[0]} />
                                                                            </span>
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}

                                                </ul>
                                            </div>
                                            <div className="mt-auto">
                                                {notPeopleTab &&
                                                    <div className="d-flex flex-nowrap justify-content-end">
                                                        <div style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}>
                                                            <Input
                                                                type="file"
                                                                name="profile_banner"
                                                                id="profileBanner"
                                                                accept="image/*"
                                                                style={{ display: "none" }}
                                                                onChange={onChangeUploadCoverImage}
                                                            />
                                                            <Label className="btn btn-hollow light mb-0 pl-4 pr-3 py-3" for="profileBanner" title="Update Banner">
                                                                <span className="icon-camera step-2_myProfile"></span>
                                                            </Label>
                                                        </div>
                                                        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
                                                            <div className="btn btn-hollow light mb-0 pl-4 pr-3 py-3" title="Privacy Settings" onClick={() => this.props.set_setting_enable(!this.props.settingEnable)}>
                                                                <span className="icon-settings step-3_myProfile"></span>
                                                            </div>
                                                            {/* <Button color="hollow" className="pl-3 pr-4 py-3 light" title="Privacy Settings">
                                                            <span className="icon-settings"></span>
                                                        </Button> */}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="d-md-none" style={{ position: 'absolute', right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.4)' }}>
                                    <Input
                                        type="file"
                                        name="profile_banner"
                                        id="profileBanner"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <Label className="btn btn-hollow light mb-0 px-4 py-3" for="profileBanner" title="Update Banner"><FontAwesomeIcon icon="camera" size="lg" /></Label>
                                </div>
                            </div>

                        </div>
                    </Container>
                </header>
                <ModalComponent
                    size="md"
                    isScrollable={true}
                    isOpen={this.state.settingsModalToggle}
                    toggleHandler={() =>
                        this.setState({
                            settingsModalToggle: !this.state.settingsModalToggle,
                        })}
                    header={"my privacy & settings"}
                    headerClass="text-uppercase px-0"
                    body={<SettingsAndPrivacy/>}
                    bodyClass="bg-transparent p-0 invisible-scrollbar"
                /> 
            </div>
        )
    }
}


const mapState = (state) => ({
    settingEnable: state.user.settingEnable
  });

const mapProps = (dispatch) => ({
    set_setting_enable: (data) => dispatch(set_setting_enable(data)),   
  });
  
  export default connect(mapState, mapProps)(HeaderCover);