import { callApi } from "../utils/apiCaller";
import { toast } from "react-toastify";
import { get } from 'lodash'

export const GET_COMMUNITY_REVIEW_PROJECTS = "GET_COMMUNITY_REVIEW_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_RESPONSES = "GET_PROJECT_RESPONSES";
export const GET_PROJECT_TAXONOMIES = "GET_PROJECT_TAXONOMIES";
export const GET_PROJECT_SUBCATEGORIES = "GET_PROJECT_SUBCATEGORIES";
export const GET_PROJECT_SUBCATEGORIES_LEVEL = "GET_PROJECT_SUBCATEGORIES_LEVEL";
export const GET_CATEGORY_QUESTIONS = "GET_CATEGORY_QUESTIONS";
export const GET_FRIENDS = "GET_FRIENDS";
export const GET_SIMILAR_PROJECTS = "GET_SIMILAR_PROJECTS";
export const DELETE_PROJECT_RESPONSES = "DELETE_PROJECT_RESPONSES";
export const PUBLISH_PROJECT_RESPONSES = "PUBLISH_PROJECT_RESPONSES";

export const get_community_review_projects_ = (data) => {
    return {
        type: GET_COMMUNITY_REVIEW_PROJECTS,
        payload: data,
    };
};


export const get_community_review_projects = (url) => {
    return (dispatch) => {
        callApi(`${url}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_community_review_projects_(response));
            }
        });
    };
};

export const get_project_ = (data) => {
    return {
        type: GET_PROJECT,
        payload: data,
    };
};


export const get_project = (id) => {
    return (dispatch) => {
        callApi(`/api/projects/${id}/`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_project_(response));
            }
        });
    };
};

export const get_project_responses_ = (data) => {
    return {
        type: GET_PROJECT_RESPONSES,
        payload: data,
    };
};

export const get_project_responses = (id, infiniteUrl) => {
    let url
    if (infiniteUrl)
        url = infiniteUrl
    else
        url = `/api/projectreview/?project_id=${id}&is_draft=False&page=1`

    return async (dispatch, getState) => {
        return callApi(url, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                const store = getState()
                const results = get(store, 'communityReviewProjects.project_responses.results', [])
                const projectResponses = { ...response, results: [...results, ...response.results] }
                if (infiniteUrl)
                    return dispatch(get_project_responses_(projectResponses));
                else
                    return dispatch(get_project_responses_(response));
            }
        });
    };
};

export const publish_project = (id, url) => {
    return (dispatch) => {
        callApi(`/api/publishcrp/?=&id=${id}&status=false`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_community_review_projects(url));
            }
        });
    };
};

export const update_project_status = (id, url, data = {}) => {
    return (dispatch) => {
        callApi(`/api/projects/${id}/?type=crp&key=${id}`, "PUT", data).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_community_review_projects(url));
            }
        });
    };
};

export const get_project_taxonomies_ = (data) => {
    return {
        type: GET_PROJECT_TAXONOMIES,
        payload: data,
    };
};

export const get_project_taxonomies = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            callApi(`/api/taxonomy/?category=Projects_Taxonomy&depth=true&delsel=&ancestor=&sub_cat=&parent=Projects`, "GET").then((response) => {
                if (response && response.code !== 400 && response.code !== 500) {
                    dispatch(get_project_taxonomies_(response));
                    return resolve();
                }
                return resolve();
            });

        });
        
    };
};

export const get_project_subcategories_ = (data) => {
    return {
        type: GET_PROJECT_SUBCATEGORIES,
        payload: data,
    };
};

export const get_project_subcategories = (id) => {
    return (dispatch) => {
        callApi(`/api/taxonomy/?category=${id}&depth=true&delsel=&ancestor=&sub_cat=&parent=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_project_subcategories_(response));
            }
        });
    };
};

export const get_project_subcategories_level_ = (data) => {
    return {
        type: GET_PROJECT_SUBCATEGORIES_LEVEL,
        payload: data,
    };
};

export const get_project_subcategories_level = (id) => {
    return (dispatch) => {
        callApi(`/api/taxonomy/?category=${id}&depth=true&delsel=&ancestor=&sub_cat=&parent=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_project_subcategories_level_(response));
            }
        });
    };
};

export const add_project = (data, url, submitType) => {
    return (dispatch) => {
        callApi(`/api/projects/`, "POST", data).then((response) => {
			if (response && response.code !== 400 && response.code !== 500) {
				if(response && response.id){
                    dispatch(get_project(response.id));
				}
				
                return dispatch(get_community_review_projects(url));
            }
        });
    };
};

export const get_category_questions_ = (data) => {
    return {
        type: GET_CATEGORY_QUESTIONS,
        payload: data,
    };
};

export const get_category_questions = (id) => {
    return (dispatch) => {
        callApi(`/api/project-questions/?type=questions&subcategory=${id}&questiontype=2`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_category_questions_(response.results));
            }
        });
    };
};

export const get_friends_ = (data) => {
    return {
        type: GET_FRIENDS,
        payload: data,
    };
}

export const get_friends = (query) => {
    return (dispatch) => {
        callApi(`/api/tags/?q=${query}&listing=False`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_friends_(response));
            }
        });
    };
}

export const get_similar_projects_ = (data) => {
    return {
        type: GET_SIMILAR_PROJECTS,
        payload: data,
    };
};

export const get_similar_projects = (url) => {
    return (dispatch) => {
        callApi(url, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                return dispatch(get_similar_projects_(response));
            }
        });
    };
};


export const add_project_responses = (data) => {
    let msg = `Your response for ${data.project_title} has been published.`;
    if(data.is_draft){
        msg = `Your response for ${data.project_title} has been saved as draft.`;
    }
    return (dispatch) => {
        callApi(`/api/projectreview/?&project_id=${data.project_id}`, "POST", data).then((response) => {
			if (response && response.code !== 400 && response.code !== 500) {
                toast(msg, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                });
                return dispatch(get_project_responses(data.project_id));
            }
        });
    };
};

export const delete_project_responses_ = (data) => {
    return {
        type: DELETE_PROJECT_RESPONSES,
        payload: data,
    };
};

export const delete_project_responses = (id, is_draft, reviewid, deletedIndex) => {
    return async (dispatch ,getState) => {
       return callApi(`/api/projectreview/?project_id=${id}&is_draft=${is_draft}&page=1&reviewid=${reviewid}`, "DELETE").then((response) => {
           
          if (response && response.status === true  && response.code !== 400 && response.code !== 500) {
              const store = getState()
              const results = get(store, 'communityReviewProjects.project_responses.results',[])
                    results.splice(deletedIndex,1)
                    
                    toast(`Review deleted successfully`, {
                      autoClose: 2500,
                      className: "black-background",
                      bodyClassName: "red-hunt",
                      progressClassName: "cc"
                    });
                return  dispatch(delete_project_responses_(results));
                
            }
        })
    };
};

export const publish_project_responses_ = (data) => {
  return {
      type: PUBLISH_PROJECT_RESPONSES,
      payload: data,
  };
};

export const publish_project_responses = (reviewid, changeInIndex, id) => {
  return async (dispatch ,getState) => {
     return callApi(`/api/projectreview/?reviewid=${reviewid}`, "PUT").then((response) => {
         
        if (response && response.status === true  && response.code !== 400 && response.code !== 500) {
            // const store = getState()
            // const results = get(store, 'communityReviewProjects.project_responses.results',[])
            // let data = {...results[changeInIndex], is_draft: false}
            // let x  = [...results]          
            // x[changeInIndex] = data
                  toast(`Review published successfully`, {
                    autoClose: 2500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc"
                  });
              // return  dispatch(publish_project_responses_(x));
              return dispatch(get_project_responses(id));
          }
      })
  };
};