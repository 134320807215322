/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import deepEqual from "lodash.isequal";
import moment from "moment";
import React, { Component } from "react";
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleContacts from "react-google-contacts";
import { GoogleLogin } from "react-google-login";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Carousel,
  CarouselControl,
  CarouselItem,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Navbar,
  NavbarBrand,
  Row,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup, 
  InputGroupAddon,
} from "reactstrap";
import * as Yup from "yup";
import {
  auth_login_error_,
  auth_login_request,
  bulk_favourite,
  facebook_login_request,
  get_captcha,
  get_category_list,
  get_user_information,
  google_login_request,
  is_auth_login_true,
  resend_activation_email,
  reset_password,
  reset_password_with_email,
  set_email_signup_error,
  set_email_signup_response,
  set_resend_activation_email_error,
  set_reset_password_error,
  upload_media,
  setInitialAuth,
} from "../../../actions/auth";
import gmailIcon from "../../../assets/images/icons/gmail.png";
import hotmailIcon from "../../../assets/images/icons/hotmail.png";
import userIcon from "../../../assets/images/icons/user-circle.png";
import yahooIcon from "../../../assets/images/icons/yahoo.png";
import { callApi } from "../../../utils/apiCaller";
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from "../../../utils/constants";
import EmailSignupForm from "./emailSignupForm";
import TermsOfService from "../../pages/static/terms";
import PrivacyPolicy from "../../pages/static/privacyPolicy";
import Guidelines from "../../pages/static/guidelines";

import tagIcon from "../../../assets/images/icons/browse-category.png";
import wikiWhite from "../../../assets/images/icons/w-white.png";
import menuIcon from "../../../assets/images/icons/leftmenu-lines-top.png";
import allCatIcon from "../../../assets/images/icons/all-category-top-search.png";
import businessIcon from "../../../assets/images/icons/business-top-search.png";
import productsIcon from "../../../assets/images/icons/products-top-search.png";
import moviesIcon from "../../../assets/images/icons/movies-top-search.png";
import professionalIcon from "../../../assets/images/icons/professionals-top-search.png";
import actorIcon from "../../../assets/images/icons/actors-top-search.png";
import crpIcon from "../../../assets/images/icons/crp-hover.png";
import peopleIcon from "../../../assets/images/icons/people-top-search.png";
import PlacementAutoComplete from '../../atoms/PlacementAutoComplete';
import { my_profile } from "../../../actions/user";
import { fetch_category_list, fetch_current_location, get_searchHome, clear_search_home, add_tempLocation } from "../../../actions/home";
import queryString from 'query-string';
import { getDOBDate } from "../../../utils/utilities";
import { setLoginPromptLogin, setLoginPromptSignUp } from "../../../actions/shared";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const validationYup = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required!"),
  // password: Yup.string().min(8, "Password  minimum 8 characters!").required("Password is required!").matches(
  //   /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$@^%&? "])[a-zA-Z0-9!#$@^%&?]{8,20}$/,
  //   "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character"
  // ),
});

class PublicPageHeader extends Component {
  constructor(props) {
    super(props);

    let isOldUrl = false;
    let current_url = window.location.href;

    if (
      [
        "http://m.wikireviews.com.s3-website-us-east-1.amazonaws.com",
        "http://m.wikireviews.com.s3-website-us-east-1.amazonaws.com/",
      ].includes(current_url)
    ) {
      isOldUrl = true;
    } else {
      isOldUrl = false;
    }

    // let isMobile = /iPhone|Android/i.test(navigator.userAgent);

    // if (isMobile) {
    //   if (isOldUrl) {
    //     window.location.href = process.env.REACT_APP_WEB_URL;
    //   } else {
    //     window.location.href = process.env.REACT_APP_MOBILE_URL;
    //   }
    // } else {
    //   if (isOldUrl) {
    //     window.location.href = process.env.REACT_APP_WEB_URL;
    //   }
    // }

    this.state = {
      isOpen: false,
      formFields: { email: "", password: "" },
      signup: {
        dobDay: "",
        dobMonth: "",
        dobYear: "",
        tos: false,
        errors: {},
      },
      onBoardingModalOne: false,
      onBoardingModalTwo: false,
      onBoardingModalThree: false,
      activeMovieIndex: 0,
      animatingMovie: false,
      activeBusinessIndex: 0,
      animatingBusiness: false,
      activeProductIndex: 0,
      animatingProduct: false,
      categoryList: null,
      selectedInModalOne: {
        productsCategory: [],
        businessCategory: [],
        moviesCategory: [],
        saveContinueBlink: false
      },
      selectedInModalThree: {
        image: "",
        imageError: "",
      },
      resetPassModal: false,
      resetPassword: {
        email: "",
        error: "",
      },
      selectedInModalTwo: {
        gmail: false,
        yahoo: false,
        hotmail: false,
      },
      resendActEmailModal: false,
      signUpModal: false,
      emailSignUpModal: false,
      loginModal: false,
      resendActivationEmail: {
        email: "",
        error: "",
      },
      activeClass: "normal",
      resetPassModalWithEmail: false,
      resetPasswordWithEmail: {
        newPassword: "",
        confirmPassword: "",
        error: "",
        redirect: false,
      },
      resetPasswordWithEmailToken: "",

      termsOfService: false,
      privacyPolicy: false,
      contentGuidelines: false,

      newTopCategories:[], //merge headers start
      contentImage: allCatIcon,
      search_name: "",
      enableSearch: false,
      contentImage: allCatIcon,
      searchHomeData: [],
      content_type: "All",
      unableToSet: true
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.saveContinueRef = React.createRef();
    this.favBusinessCategoriesRef = React.createRef();
    this.favProductCategoriesRef = React.createRef();

    //merge headers start
    this.toggleCategory = this.toggleCategory.bind(this);
    this.onMouseEnterCategory = this.onMouseEnterCategory.bind(this);
    this.onMouseLeaveCategory = this.onMouseLeaveCategory.bind(this);
  }

  getOS = () => {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth_response && nextProps.auth_response.token) {
      this.setState({ loginModal: false }, () => {
        this.props.is_auth_login_true();
        //this.setSubmitting(true);
      });
    }

    if (nextProps.category_list && nextProps.category_list.length > 0) {
      this.mapCategoryList(nextProps.category_list);
    }

    if (
      nextProps.reset_password_error &&
      nextProps.reset_password_error.email
    ) {
      this.setState({
        ...this.state,
        resetPassword: {
          ...this.state.resetPassword,
          error: nextProps.reset_password_error.email[0],
        },
      });
    } else {
      this.setState({
        resetPassModal: false,
        resetPassword: {
          email: "",
          error: "",
        },
      });
    }

    if (
      nextProps.resend_activation_email_error &&
      nextProps.resend_activation_email_error.email
    ) {
      this.setState({
        ...this.state,
        resendActivationEmail: {
          ...this.state.resendActivationEmail,
          error: nextProps.resend_activation_email_error.email[0],
        },
      });
    } else {
      this.setState({
        resendActEmailModal: false,
        resendActivationEmail: {
          email: "",
          error: "",
        },
      });
    }

    //merge headers start
    if (nextProps.top_categories && Array.isArray(nextProps.top_categories) && nextProps.top_categories.length > 0) {
      let newArray = [];
      let newArray2 = [];
      nextProps.top_categories.map((listCatorgies) => {
        if(listCatorgies?.category == "Products"){
          newArray.push(listCatorgies)
        }else{
          newArray2.push(listCatorgies)
        }					
      })
      this.setState({ newTopCategories: newArray.concat(newArray2)})
    }

    if (nextProps.get_search_home &&
      Array.isArray(nextProps.get_search_home.results) &&
      nextProps.get_search_home.results.length > 0 && this.state.unableToSet) {
      this.setState({
          searchHomeData: nextProps.get_search_home.results
      });
    }

    if(nextProps.forceShowLoginModal && !this.props.forceShowLoginModal){
      this.loginModalToggle();
    }

    if(nextProps.forceShowLoginModal && !this.state.loginModal){
      this.props.forceToggleLoginModal();
    }

    if( nextProps.loginPromptSignUp !== this.props.loginPromptSignUp){
      if(nextProps.loginPromptSignUp){
        this.signUpModalToggle()
      }
     
    }
    if( nextProps.loginPromptLogin !== this.props.loginPromptLogin){
      if(nextProps.loginPromptLogin){
        this.loginModalToggle()
      }
     
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: false });
    if (window.location.href.includes("/form/passwordresetconfirm")) {
      let tokenArray = window.location.href.split("passwordresetconfirm");
      if (tokenArray.length > 1) {
        this.setState({
          resetPassModalWithEmail: true,
          resetPasswordWithEmail: {
            newPassword: "",
            confirmPassword: "",
            error: "",
          },
          resetPasswordWithEmailToken: tokenArray[1],
        });
      }
    } else if (window.location.href.includes("/api/activate")) {
      /* let tokenArray = window.location.href.split('activate');
      // callApi(`/api/activate${tokenArray[1]}`, "GET").then((response) => {
      //   if (response && response.code !== 400 && response.code !== 500) {
      //     toast(response.message, {
      //       autoClose: 2500,
      //       className: "black-background",
      //       bodyClassName: "red-hunt",
      //       progressClassName: "cc",
      //     });
      //     this.props.history.push('/');
      //   }
      // }); */
      this.props.history.push("/");
    } else if (window.location.href.includes("/api/confirm-email-id")) {
      this.props.history.push("/");
    }

    this.props.my_profile();
    this.props.fetch_category_list({ category: 1, depth: false, level: 1, show_children: true });
    this.setLocation();
    this.props.clear_search_home();
    let { location } = this.props;
    const parsed = queryString.parse(location.search);
    if (location?.search) {
        if (parsed?.q) {
            let query = parsed?.q;
            this.setState({ search_name: query })
        }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  async setLocation() {
    if (localStorage.getItem("lastSetupLocation")) {
        const data = JSON.parse(localStorage.getItem("lastSetupLocation"))
        await this.props.add_tempLocation(data);
    } else {
        await this.props.fetch_current_location();
    }
}

  resetPassModalWithEmailToggle = () => {
    this.setState({
      resetPassModalWithEmail: !this.state.resetPassModalWithEmail,
      resetPasswordWithEmail: {
        newPassword: "",
        confirmPassword: "",
        error: "",
        redirect: true,
      },
      resetPasswordWithEmailToken: "",
    });
  };

  renderRedirect = () => {
    if (this.state.resetPasswordWithEmail.redirect) {
      return <Redirect to="/" />;
    }
  };

  handleOnChangeResetPasswordWithEmail = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      resetPasswordWithEmail: {
        ...prevState.resetPasswordWithEmail,
        [name]: value,
        error: "",
      },
    }));
  };

  handleOnSubmitResetPasswordWithEmail = () => {
    let error = "";
    let data = {};
    if (
      this.state.resetPasswordWithEmail.newPassword !== "" &&
      this.state.resetPasswordWithEmail.confirmPassword !== ""
    ) {
      if (
        this.state.resetPasswordWithEmail.newPassword !==
        this.state.resetPasswordWithEmail.confirmPassword
      ) {
        error = "Passwords do not match!";
      } else {
        if (this.state.resetPasswordWithEmail.newPassword.length < 8) {
          error = "Minimum 8 characters are required!";
        } else {
          if (
            !/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$@^%&? "])[a-zA-Z0-9!#$@^%&?]{8,20}$/.test(
              this.state.resetPasswordWithEmail.newPassword
            )
          ) {
            error =
              "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character";
          }
        }
      }

      if (error) {
        this.setState((prevState) => ({
          ...prevState,
          resetPasswordWithEmail: {
            ...prevState.resetPasswordWithEmail,
            error: error,
          },
        }));
      } else {
        data.password1 = this.state.resetPasswordWithEmail.newPassword;
        data.password2 = this.state.resetPasswordWithEmail.newPassword;
        this.props.reset_password_with_email(
          data,
          this.state.resetPasswordWithEmailToken
        );
        this.setState((prevState) => ({
          ...prevState,
          resetPassModalWithEmail: false,
          resetPasswordWithEmail: {
            newPassword: "",
            confirmPassword: "",
            error: "",
            redirect: true,
          },
          resetPasswordWithEmailToken: "",
        }));
      }
    } else {
      error = "Both fields are required!";
      this.setState((prevState) => ({
        ...prevState,
        resetPasswordWithEmail: {
          ...prevState.resetPasswordWithEmail,
          error: error,
        },
      }));
    }
  };

  handleScroll(event) {
    let { activeClass } = this.state;
    let actClass = "";
    let scrollTop = event.target.scrollingElement.scrollTop;

    if (scrollTop > 500) {
      actClass = "navbar-scrolled";
      if (activeClass !== actClass) {
        this.setState({ activeClass: actClass });
      }
    } else {
      actClass = "normal";
      if (activeClass !== "normal") {
        this.setState({ activeClass: actClass });
      }
    }

    // this.setState({
    //   transform: itemTranslate
    // });
  }

  mapCategoryList = (data) => {
    let mappedList = { business: [], products: [], movies: [] };

    data.map((outerArr) => {
      outerArr.map((innerArr) => {
        innerArr.map((obj) => {
          if (obj.content_type === "Business") {
            mappedList.business.push(obj);
          }
          if (obj.content_type === "Movies") {
            mappedList.movies.push(obj);
          }
          if (obj.content_type === "Products") {
            mappedList.products.push(obj);
          }
        });
      });
    });

    const n = 4;
    Object.keys(mappedList).map((key) => {
      let items = mappedList[key];
      mappedList[key] = new Array(Math.ceil(items.length / n))
        .fill()
        .map((_) => items.splice(0, n));
    });
    this.setState({
      ...this.state,
      categoryList: mappedList,
    });
  };

  toggleNavbar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  signUpModalToggle = () => {
    this.setState({
      signUpModal: !this.state.signUpModal,
      signup: {
        dobDay: "",
        dobMonth: "",
        dobYear: "",
        tos: false,
        errors: {},
      },
      selectedInModalOne: {
        productsCategory: [],
        businessCategory: [],
        moviesCategory: [],
        saveContinueBlink: false
      },
      selectedInModalThree: {
        image: "",
        imageError: "",
      },
      selectedInModalTwo: {
        gmail: false,
        yahoo: false,
        hotmail: false,
      },
    });
    this.props.setLoginPromptSignUp(!this.props.loginPromptSignUp)
    this.props.set_email_signup_response(null);
    this.props.set_email_signup_error(null);
  };
  emailSignUpModalToggle = () => {
    this.setState({
      signUpModal: false,
      emailSignUpModal: !this.state.emailSignUpModal,
    });
  };

  toggleNavbar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openOnBoardingModalOne = () => {
    this.setState({
      onBoardingModalOne: true,
      emailSignUpModal: false,
    });
  };

  onBoardingModalOneToggle = () => {
    if (this.state.onBoardingModalOne) {
      this.requestForLogin();
    }
    this.setState({
      onBoardingModalOne: !this.state.onBoardingModalOne,
    });
  };

  onBoardingModalTwoToggle = () => {
    if (this.state.onBoardingModalTwo) {
      this.requestForLogin();
    }
    this.setState({
      onBoardingModalTwo: !this.state.onBoardingModalTwo,
    });
  };

  onBoardingModalThreeToggle = () => {
    if (this.state.onBoardingModalThree) {
      this.requestForLogin();
    }
    this.setState({
      onBoardingModalThree: !this.state.onBoardingModalThree,
    });
  };

  resetPassModalToggle = () => {
    this.setState({
      loginModal: false,
      resetPassModal: !this.state.resetPassModal,
    });
    this.props.set_reset_password_error(null);
  };

  resendActEmailModalToggleFromSignup = () => {
    this.resendActEmailModalToggle();
    this.emailSignUpModalToggle();
  };

  businessOwnerDetail = () => {
    // removed console from here.
  };

  resendActEmailModalToggle = () => {
    this.setState({
      loginModal: false,
      resendActEmailModal: !this.state.resendActEmailModal,
    });
    this.props.set_resend_activation_email_error(null);
  };

  loginModalToggle = () => {
    this.setState(
      {
        loginModal: !this.state.loginModal,
      },
      () => {
        if (this.state.loginModal) {
          this.props.setInitialAuth();
        }
      }
    );
    this.props.setLoginPromptLogin(!this.props.loginPromptLogin)
    this.props.auth_login_error_(null);
  };

  componentClicked = (data) => { };

  importGoogleContacts = (response) => {
    this.setState((prevState) => ({
      ...prevState,
      selectedInModalTwo: {
        ...prevState.selectedInModalTwo,
        gmail: true,
      },
    }));
  };

  responseFacebook = (data) => {
    if (data && data.id && data.name) {
      let newData = { id: data.id, name: data.name };
      localStorage.setItem("token", data.accessToken);
      this.props.facebook_login_request(newData);
    }
  };

  responseGoogle = (data) => {
    const {dobDay, dobMonth, dobYear} = this.state.signup;
    const dob = !this.state.loginModal ? getDOBDate(dobDay, dobMonth, dobYear) : null;

    if (data && data.profileObj && Object.keys(data.profileObj).length > 0) {
      let newData = {
        email: data.profileObj.email,
        first_name: data.profileObj.givenName,
        last_name: data.profileObj.familyName
      };

      if (dob) newData.dob = dob;

      this.props.google_login_request(newData, data.accessToken);
    }
  };

  nextMovie = () => {
    if (this.state.animatingMovie) return;
    if (this.state.categoryList) {
      const nextIndex =
        this.state.activeMovieIndex ===
          this.state.categoryList.movies.length - 1
          ? 0
          : this.state.activeMovieIndex + 1;
      this.setState({ activeMovieIndex: nextIndex });
    }
  };

  previousMovie = () => {
    if (this.state.animatingMovie) return;
    if (this.state.categoryList) {
      const nextIndex =
        this.state.activeMovieIndex === 0
          ? this.state.categoryList.movies.length - 1
          : this.state.activeMovieIndex - 1;
      this.setState({ activeMovieIndex: nextIndex });
    }
  };

  nextBusiness = () => {
    if (this.state.animatingBusiness) return;
    if (this.state.categoryList) {
      const nextIndex =
        this.state.activeBusinessIndex ===
          this.state.categoryList.business.length - 1
          ? 0
          : this.state.activeBusinessIndex + 1;
      this.setState({ activeBusinessIndex: nextIndex });
    }
  };

  previousBusiness = () => {
    if (this.state.animatingBusiness) return;
    if (this.state.categoryList) {
      const nextIndex =
        this.state.activeBusinessIndex === 0
          ? this.state.categoryList.business.length - 1
          : this.state.activeBusinessIndex - 1;
      this.setState({ activeBusinessIndex: nextIndex });
    }
  };

  nextProduct = () => {
    if (this.state.animatingProduct) return;
    if (this.state.categoryList) {
      const nextIndex =
        this.state.activeProductIndex ===
          this.state.categoryList.products.length - 1
          ? 0
          : this.state.activeProductIndex + 1;
      this.setState({ activeProductIndex: nextIndex });
    }
  };

  previousProduct = () => {
    if (this.state.animatingProduct) return;
    if (this.state.categoryList) {
      const nextIndex =
        this.state.activeProductIndex === 0
          ? this.state.categoryList.products.length - 1
          : this.state.activeProductIndex - 1;
      this.setState({ activeProductIndex: nextIndex });
    }
  };

  modalOneToggleSelection = (type, id) => {
    const { selectedInModalOne } = this.state;
    let objArray = [],
      key = "",
      total = 0;
    if (type === "movies") {
      objArray = [...selectedInModalOne.moviesCategory];
      key = "moviesCategory";
    } else if (type === "products") {
      objArray = [...selectedInModalOne.productsCategory];
      key = "productsCategory";
    } else if (type === "business") {
      objArray = [...selectedInModalOne.businessCategory];
      key = "businessCategory";
    }

    if (objArray.includes(id)) {
      objArray = objArray.filter((item) => item !== id);
    } else {
      objArray.push(id);
    }

    this.setState({
      ...this.state,
      selectedInModalOne: {
        ...this.state.selectedInModalOne,
        [key]: objArray,
      },
    }, () => {
      let saveContinueBlink = false;
      let { selectedInModalOne } = this.state;
      //total = selectedInModalOne.moviesCategory.length + selectedInModalOne.productsCategory.length + selectedInModalOne.businessCategory.length;
      if(selectedInModalOne.moviesCategory.length == 3){
        if(this.favBusinessCategoriesRef && this.favBusinessCategoriesRef.current){
          this.favBusinessCategoriesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
      if(selectedInModalOne.businessCategory.length == 3){
        if(this.favProductCategoriesRef && this.favProductCategoriesRef.current){
          this.favProductCategoriesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
      if (selectedInModalOne.moviesCategory.length >= 3 && selectedInModalOne.productsCategory.length >= 3 && selectedInModalOne.businessCategory.length >= 3) {
        saveContinueBlink = true;
      }
      if (selectedInModalOne.saveContinueBlink !== saveContinueBlink) {
        this.setState({
          ...this.state,
          selectedInModalOne: {
            ...this.state.selectedInModalOne,
            saveContinueBlink
          }
        });
      }
    });
  };

  movieSlides = () => {
    const { selectedInModalOne } = this.state;
    let result = !this.state.categoryList
      ? []
      : this.state.categoryList.movies.map((item, index) => {
        return (
          <CarouselItem
            onExiting={() => this.setState({ animatingMovie: true })}
            onExited={() => this.setState({ animatingMovie: false })}
            key={index}
          >
            <div className="p-3">
              <Row className="justify-content-center">
                {item.map((obj) => (
                  <Col md={3} className="mb-3" key={obj.taxonomy_id}>
                    <div className="item-selectable d-inline-block mb-2">
                      <div
                        role="button"
                        className="overlay font-weight-bold"
                        hidden={
                          selectedInModalOne.moviesCategory.includes(
                            obj.taxonomy_id
                          )
                            ? true
                            : false
                        }
                        onClick={() =>
                          this.modalOneToggleSelection(
                            "movies",
                            obj.taxonomy_id
                          )
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon="plus" className="mr-2" /> Add
                          </div>
                      </div>
                      <div
                        role="button"
                        className="overlay font-weight-bold selected"
                        hidden={
                          selectedInModalOne.moviesCategory.includes(
                            obj.taxonomy_id
                          )
                            ? false
                            : true
                        }
                        onClick={() =>
                          this.modalOneToggleSelection(
                            "movies",
                            obj.taxonomy_id
                          )
                        }
                      >
                        <div> Added </div>
                      </div>
                      <img
                        src={obj.thumbnail}
                        alt={obj.filename}
                        width={100}
                        height={150}
                        style={{
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    </div>
                    <div className="text-center font-weight-bold fs-14 text-dark">
                      {obj.category}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </CarouselItem>
        );
      });

    return result;
  };

  businessSlides = () => {
    const { selectedInModalOne } = this.state;
    let result = !this.state.categoryList
      ? []
      : this.state.categoryList.business.map((item, index) => {
        return (
          <CarouselItem
            onExiting={() => this.setState({ animatingMovie: true })}
            onExited={() => this.setState({ animatingMovie: false })}
            key={index}
          >
            <div className="p-3">
              <Row className="justify-content-center">
                {item.map((obj) => (
                  <Col md={3} className="mb-3" key={obj.taxonomy_id}>
                    <div className="item-selectable d-inline-block mb-2">
                      <div
                        role="button"
                        className="overlay font-weight-bold"
                        hidden={
                          selectedInModalOne.businessCategory.includes(
                            obj.taxonomy_id
                          )
                            ? true
                            : false
                        }
                        onClick={() =>
                          this.modalOneToggleSelection(
                            "business",
                            obj.taxonomy_id
                          )
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon="plus" className="mr-2" /> Add
                          </div>
                      </div>
                      <div
                        role="button"
                        className="overlay font-weight-bold selected"
                        hidden={
                          selectedInModalOne.businessCategory.includes(
                            obj.taxonomy_id
                          )
                            ? false
                            : true
                        }
                        onClick={() =>
                          this.modalOneToggleSelection(
                            "business",
                            obj.taxonomy_id
                          )
                        }
                      >
                        <div> Added </div>
                      </div>
                      <img
                        src={obj.thumbnail}
                        alt={obj.filename}
                        width={120}
                        height={120}
                        style={{
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    </div>
                    <div className="text-center font-weight-bold fs-14 text-dark">
                      {obj.category}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </CarouselItem>
        );
      });

    return result;
  };

  productSlides = () => {
    const { selectedInModalOne } = this.state;
    let result = !this.state.categoryList
      ? []
      : this.state.categoryList.products.map((item, index) => {
        return (
          <CarouselItem
            onExiting={() => this.setState({ animatingMovie: true })}
            onExited={() => this.setState({ animatingMovie: false })}
            key={index}
          >
            <div className="p-3">
              <Row className="justify-content-center">
                {item.map((obj) => (
                  <Col md={3} className="mb-3" key={obj.taxonomy_id}>
                    <div className="item-selectable d-inline-block mb-2">
                      <div
                        role="button"
                        className="overlay font-weight-bold"
                        hidden={
                          selectedInModalOne.productsCategory.includes(
                            obj.taxonomy_id
                          )
                            ? true
                            : false
                        }
                        onClick={() =>
                          this.modalOneToggleSelection(
                            "products",
                            obj.taxonomy_id
                          )
                        }
                      >
                        <div>
                          <FontAwesomeIcon icon="plus" className="mr-2" /> Add
                          </div>
                      </div>
                      <div
                        role="button"
                        className="overlay font-weight-bold selected"
                        hidden={
                          selectedInModalOne.productsCategory.includes(
                            obj.taxonomy_id
                          )
                            ? false
                            : true
                        }
                        onClick={() =>
                          this.modalOneToggleSelection(
                            "products",
                            obj.taxonomy_id
                          )
                        }
                      >
                        <div> Added </div>
                      </div>
                      <img
                        src={obj.thumbnail}
                        alt={obj.filename}
                        width={120}
                        height={120}
                        style={{
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    </div>
                    <div className="text-center font-weight-bold fs-14 text-dark">
                      {obj.category}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </CarouselItem>
        );
      });

    return result;
  };

  restrictSocialSignup = (renderProps) => {
    if (this.state.signUpModal) {
      const signup = { ...this.state.signup };
      let errors = {};
      if (signup.dobDay === "") {
        errors.dobDay = "This field is required";
      }
      if (signup.dobMonth === "") {
        errors.dobMonth = "This field is required";
      }
      if (signup.dobYear === "") {
        errors.dobYear = "This field is required";
      }
      if (!signup.tos) {
        errors.tos = "You must agree to the terms to register";
      }
      if (Object.keys(errors).length > 0) {
        this.setState({
          ...this.state,
          signup: {
            ...this.state.signup,
            errors: errors,
          },
        });
      } else {
        let error = "";
        errors = { ...this.state.errors };
        if (!this.checkAge()) {
          error = "Sorry, you are ineligible to sign up for WikiReviews.";
          errors.age = error;
          this.setState({
            ...this.state,
            signup: {
              ...this.state.signup,
              errors: errors,
            },
          });
        } else {
          delete errors.age;
          this.setState({
            ...this.state,
            signup: {
              ...this.state.signup,
              //tos: false,
              errors: {},
            },
          });
          renderProps.onClick();
        }
      }
    } else {
      renderProps.onClick();
    }
  };

  renderFacebookButton = () => (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      autoLoad={false}
      callback={this.responseFacebook}
      render={(renderProps) => (
        <Button
          onClick={() => this.restrictSocialSignup(renderProps)}
          color="facebook"
          className="font-weight-bold"
          block
        >
          <div className="d-flex">
            <span className="mr-2">
              <FontAwesomeIcon
                size="lg"
                fixedWidth
                icon={["fab", "facebook-f"]}
              />
            </span>
            <span className={this.state.signUpModal ? "ml-5" : "ml-auto"}>
              {this.state.signUpModal ? "Sign Up" : "Login"} with{" "}
              <span className="text-uppercase">Facebook</span>
            </span>
          </div>
        </Button>
      )}
    />
  );

  renderGoogleButton = () => (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <Button
          onClick={() => this.restrictSocialSignup(renderProps)}
          color="google"
          block
          className="mt-3 font-weight-bold"
          disabled={renderProps.disabled}
        >
          <div className="d-flex">
            <span className="mr-2">
              <FontAwesomeIcon
                size="lg"
                fixedWidth
                icon={["fab", "google-plus-g"]}
              />
            </span>
            <span className={this.state.signUpModal ? "ml-5" : "ml-auto"}>
              {this.state.signUpModal ? "Sign Up" : "Login"} with{" "}
              <span className="text-uppercase">Google+</span>
            </span>
          </div>
        </Button>
      )}
      buttonText="Login"
      onSuccess={this.responseGoogle}
      onFailure={this.responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );

  handleDobChange = (e) => {
    let errors = { ...this.state.signup.errors };
    if (e.target.value !== "") {
      delete errors[e.target.name];
    }
    this.setState({
      ...this.state,
      signup: {
        ...this.state.signup,
        [e.target.name]: e.target.value,
        tos:
          e.target.name === "tos"
            ? !this.state.signup.tos
            : this.state.signup.tos,

        errors: errors,
      },
    });
  };

  checkAge = () => {
    let signup = this.state.signup;
    let currentDate = new Date();
    let difference = currentDate.getFullYear() - signup.dobYear;
    if (difference < 13) {
      return false;
    } else {
      return true;
    }
  };

  handleOnClickEmailSignUp = (type = "") => {
    const signup = { ...this.state.signup };
    let errors = {};
    if (signup.dobDay === "") {
      errors.dobDay = "This field is required";
    }
    if (signup.dobMonth === "") {
      errors.dobMonth = "This field is required";
    }
    if (signup.dobYear === "") {
      errors.dobYear = "This field is required";
    }
    if (!signup.tos) {
      errors.tos = "You must agree to the terms to register";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({
        ...this.state,
        signup: {
          ...this.state.signup,
          errors: errors,
        },
      });
    } else {
      let error = "";
      errors = { ...this.state.errors };
      if (!this.checkAge()) {
        error = "Sorry, you are ineligible to sign up for WikiReviews.";
        errors.age = error;
        this.setState({
          ...this.state,
          signup: {
            ...this.state.signup,
            errors: errors,
          },
        });
      } else {
        if (type === "email") {
          this.props.get_captcha();
          this.props.get_category_list();
          delete errors.age;
          this.setState({
            ...this.state,
            signUpModal: false,
            emailSignUpModal: !this.state.emailSignUpModal,
            signup: {
              ...this.state.signup,
              tos: false,
              errors: errors,
            },
          });
        }
      }
    }
  };

  onClickSkipModalOne = () => {
    if (
      this.props.email_signup_response &&
      this.props.email_signup_response.profileId
    ) {
      let data = {
        products_category: [],
        business_category: [],
        movies_category: [],
        profile: this.props.email_signup_response.profileId,
        pin_type: "favourite list",
        user_entry: [],
        skip: true,
      };
      this.props.bulk_favourite(data);
      this.setState({
        ...this.state,
        onBoardingModalOne: false,
        onBoardingModalTwo: true,
        selectedInModalOne: {
          productsCategory: [],
          businessCategory: [],
          moviesCategory: [],
          saveContinueBlink: false
        },
      });
    }
  };

  onClickSaveModalOneData = () => {
    const { selectedInModalOne } = this.state;
    if (
      this.props.email_signup_response &&
      this.props.email_signup_response.profileId
    ) {
      let data = {
        products_category: selectedInModalOne.productsCategory,
        business_category: selectedInModalOne.businessCategory,
        movies_category: selectedInModalOne.moviesCategory,
        profile: this.props.email_signup_response.profileId,
        pin_type: "favourite list",
        user_entry: [],
        skip: false,
      };
      this.props.bulk_favourite(data);
      this.setState({
        ...this.state,
        onBoardingModalOne: false,
        onBoardingModalTwo: true,
      });
    }
  };

  onClickSaveModalTwoData = () => {
    if (
      this.props.email_signup_response &&
      this.props.email_signup_response.username
    ) {
      this.props.get_user_information(
        this.props.email_signup_response.username
      );
      this.setState({
        ...this.state,
        onBoardingModalTwo: false,
        onBoardingModalThree: true,
      });
    }
  };

  onClickSaveModalThreeData = () => {
    this.requestForLogin();
    this.onBoardingModalThreeToggle();
  };

  requestForLogin = () => {
    if (
      this.props.email_signup_response &&
      this.props.email_signup_response.profileId
    ) {
      let data = {
        identification: this.props.email_signup_response.email,
        password: this.props.email_signup_response.password,
        subdomain: "wikireviews",
      };
      this.props.login_request(data);
    }
    return true;
  };

  onChangeUploadProfileImage = (event) => {
    if (
      this.props.email_signup_response &&
      this.props.email_signup_response.profileId
    ) {
      let file = event.target.files[0];
      if (file.type.includes("video")) {
        this.setState({
          ...this.state,
          selectedInModalThree: {
            ...this.state.selectedInModalThree,
            imageError: "Please select image only!",
          },
        });
      } else {
        let fileUrl = URL.createObjectURL(file);
        let data = new FormData();
        data.append("file", file);
        data.append("profileId", this.props.email_signup_response.profileId);
        this.props.upload_media(data);
        this.setState({
          ...this.state,
          selectedInModalThree: {
            ...this.state.selectedInModalThree,
            image: fileUrl,
            imageError: "",
          },
        });
      }
    }
  };

  handleOnChangeResetPassword = (e) => {
    this.setState({
      ...this.state,
      resetPassword: {
        email: e.target.value,
        error: "",
      },
    });
  };

  handleOnChangeResendActivationEmail = (e) => {
    let value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      resendActivationEmail: {
        email: value,
        error: "",
      },
    }));
  };

  handleOnSubmitResendActivationEmail = () => {
    let error = "";
    if (this.state.resendActivationEmail.email === "") {
      error = "Please enter your email";
    } else if (this.state.resendActivationEmail.email !== "") {
      let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (reg.test(this.state.resendActivationEmail.email) === false) {
        error = "Invalid email address";
      }
    }

    if (error) {
      this.setState((prevState) => ({
        ...prevState,
        resendActivationEmail: {
          ...prevState.resendActivationEmail,
          error: error,
        },
      }));
    } else {
      let data = { email: this.state.resendActivationEmail.email };
      this.props.resend_activation_email(data);
      if (!this.props.resend_activation_email_error) {
        this.setState((prevState) => ({
          ...prevState,
          //resendActEmailModal: false,
          resendActivationEmail: {
            email: "",
            error: "",
          },
        }));
      }
    }
  };

  handleOnSubmitResetPassword = () => {
    let error = "";
    if (this.state.resetPassword.email === "") {
      error = "Please enter your email";
    } else if (this.state.resetPassword.email !== "") {
      let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

      if (reg.test(this.state.resetPassword.email) === false) {
        error = "Invalid email address";
      }
    }

    if (error) {
      this.setState({
        ...this.state,
        resetPassword: {
          ...this.state.resetPassword,
          error: error,
        },
      });
    } else {
      let data = { email: this.state.resetPassword.email };
      this.props.reset_password(data);
      if (!this.props.reset_password_error) {
        this.setState({
          ...this.state,
          //resetPassModal: false,
          resetPassword: {
            email: "",
            error: "",
          },
        });
      }
    }
  };

  checkIsMobile = () => {
    let width = false;
    let isMobileTrue = false;

    // function resize() {
    //   width = window.innerWidth;
    //   if (width <= 768) {
    //     isMobileTrue = true;
    //   } else {
    //     isMobileTrue = false;
    //   }
    // }

    if (window.matchMedia("(orientation: portrait)").matches) {
   
      // alert('In portrait');
      // you're in PORTRAIT mode
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
    
      // alert('In landscape');
      // you're in LANDSCAPE mode
    }

    if (!width) {
      width = window.innerWidth;
      if (width <= 768) {
        isMobileTrue = true;
        window.location.href = "http://192.168.43.72:3006";
      } else {
        isMobileTrue = false;
      }
    }

    // window.onresize = resize;
  };

  //merge headers start
  toggleCategory(item) {
    this.setState(prevState => ({
      [item]: !prevState[item]
    }));
  }

  onMouseEnterCategory(item) {
    this.setState({ [item]: true });
  }

  onMouseLeaveCategory(item) {
    this.setState({ [item]: false });
  }

  handleOnClickCategory = (type, ImageType) => {
    const { search_name, } = this.state
    if (type !== "") {
        this.setState({ content_type: type, contentImage: ImageType,
    isSearching:true });
        if (search_name !== "") {
            this.props.get_searchHome(search_name, type);
        }
    }
  }

  // Change State of input field	
  handleChange = (e) => {
      let value = e.target.value;
      let name = e.target.name;
      // let location = {};
      const { content_type } = this.state;
      if (name === 'search_name') {
          if (value.length) {

              this.setState(
                  {
                      ...this.state,
                      [name]: value,
                      unableToSet: true,
                  }, () => this.props.get_searchHome(value, content_type));

          } else {
              this.setState(
                  {
                      searchHomeData: [],
                      search_name: '',
                      unableToSet: false,
                  }, () => {
                      this.props.clear_search_home();
                  });
          }
      }
  }

  handleEnabelSearch = () => {
      this.setState((prevState) => ({
          enableSearch: !prevState.enableSearch
      }))
  }

  toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  render() {
    let currentYear = moment().year();
    let listOfYears = [];
    for (let cY = currentYear; cY >= 1904; cY--) {
      listOfYears.push(cY);
    }
    const { className, current_location, top_categories, my_profile_info, isEnableCover } = this.props;
    let { formFields, newTopCategories, contentImage, search_name, searchHomeData, content_type } = this.state;
    const { signup, activeClass } = this.state;
    return (
      <React.Fragment>
        {this.renderRedirect()}
        <Navbar
          ddark expand="xs" className="fixed-top" id="appNav"
        >
          <Container fluid>
            <Row className="flex-grow-1" form>
                <Col>
                    <Nav navbar className="align-items-center">
                        <UncontrolledDropdown nav inNavbar className="d-none d-md-block mr-0" onMouseOver={() => this.onMouseEnterCategory('dropdownOpenMenu')} onMouseLeave={() => this.onMouseLeaveCategory('dropdownOpenMenu')} isOpen={this.state.dropdownOpenMenu} toggle={() => this.toggleCategory('dropdownOpenMenu')}>
                            <DropdownToggle nav>
                                <img src={menuIcon} alt="Menu" width="24" />
                            </DropdownToggle>
                            <DropdownMenu className="slidable-dropdown">
                                <DropdownItem href="/about" target="_blank">
                                    {'About'}
                                </DropdownItem>
                                <DropdownItem href="/faq" target="_blank">
                                    {'FAQ'}
                                </DropdownItem>
                                <DropdownItem href="/manifesto" target="_blank">
                                    {'Manifesto'}
                                </DropdownItem>
                                <DropdownItem href="/guidelines" target="_blank">
                                    {'Guidelines'}
                                </DropdownItem>
                                <DropdownItem href="/policies" target="_blank">
                                    {'Privacy Policy'}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar className="mr-0" onMouseOver={() => this.onMouseEnterCategory('dropdownOpenCategory')} onMouseLeave={() => this.onMouseLeaveCategory('dropdownOpenCategory')} isOpen={this.state.dropdownOpenCategory} toggle={() => this.toggleCategory('dropdownOpenCategory')}>
                            <DropdownToggle nav>
                                <img className="d-none d-md-inline-block" src={tagIcon} alt="Categories" />
                                <img className="d-md-none" src={wikiWhite} alt="" width="24" data-type="img" />
                            </DropdownToggle>
                            <DropdownMenu className="profile-dropdown-menu">
                                <DropdownItem header>Main Categories</DropdownItem>
                                
                                <ul className="list-unstyled main-category-list">
                                    {newTopCategories && Array.isArray(newTopCategories) && newTopCategories.length ?
                                        newTopCategories.map((item, index) => item?.category && (
                                            <li className="dropdown-item has-submenu" key={index}>
                                                <Link
                                                    to={`/${(item?.category).toLowerCase()}/`}
                                                    className="dropdown-link">
                                                    {`${item?.category}`}
                                                </Link>
                                                <ul className="dropdown-submenu">
                                                    {item.children && Array.isArray(item.children) && item.children.length ?
                                                        item.children.map((child_item, index3) =>
                                                            child_item?.category !== "Actors" && (
                                                                <Link
                                                                    to={{
                                                                        pathname: (`/${(item.category !== "Business" && item.category !== "Professionals") ?
                                                                            item.category.slice(0, -1) :
                                                                            item.category == "Business" ? 'business-category' : item.category}/${child_item?.category && (child_item?.category).replace(/\s/g, '').split('/').join('-')}`).toLowerCase(),
                                                                        state: {
                                                                            id: child_item.id,
                                                                            parent_category: (item.category).toLowerCase(),
                                                                            child_category: child_item?.category
                                                                        }
                                                                    }}
                                                                    // onClick={() => {
                                                                    //     console.log({ child_item })
                                                                    // }}
                                                                    className="dropdown-item" key={index3}>
                                                                    {`${child_item.category}`}
                                                                </Link>
                                                            ))
                                                        : null}
                                                    <Link
                                                        to={`/${(item?.category)?.toLowerCase()}/`}
                                                        className="dropdown-item text-right">
                                                        {'View all'}
                                                    </Link>
                                                </ul>
                                            </li>

                                        ))
                                        : null}
                                </ul>
                                <Link to="/professionals/actors" className="dropdown-item main-category-list-link">
                                    Actors
                            </Link>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <li className="nav-item flex-grow-1 mx-2">
                            <div className="d-md-none mobile-input">
                                <Input bsSize="sm" type="search" placeholder="e.g. Taco's, Mel's" />
                            </div>
                            <div className="input-rest d-none d-md-block position-relative">
                                <div className="d-flex flex-column flex-md-row align-items-md-center mx-n1">
                                    <div className="px-1 d-lg-block" hidden={this.state.enableSearch}>
                                        <div className="d-flex">
                                            <UncontrolledDropdown size="sm">
                                                <DropdownToggle color={`${contentImage === allCatIcon ? 'primary' : 'tertiary'}`} className="text-darker d-flex align-items-center">
                                                    {contentImage === allCatIcon ? "All" : <img src={contentImage} width="20" alt="icon" />}
                                                    <span className="text-darker ml-2">
                                                        <FontAwesomeIcon icon="caret-down" />
                                                    </span>
                                                </DropdownToggle>
                                                <DropdownMenu className="menu-white no-caret rounded-0 results-dropdown">
                                                    <DropdownItem type="all" key={"all"} onClick={() => {
                                                        this.handleOnClickCategory("All", allCatIcon);
                                                    }}>
                                                        {/* <img className="mr-3" src={allCatIcon} width="20" alt="icon" /> */}
                                        results in <strong>all categories</strong>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                    <DropdownItem type="business" key={"business"} onClick={() => {
                                                        this.handleOnClickCategory("business", businessIcon);
                                                    }}>
                                                        {/* <img className="mr-3" src={businessIcon} width="20" alt="icon" /> */}
                                        results in <strong>Business</strong>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                    <DropdownItem type="products" key={"products"} onClick={() => {
                                                        this.handleOnClickCategory("products", productsIcon);
                                                    }}>
                                                        {/* <img className="mr-3" src={productsIcon} width="20" alt="icon" /> */}
                                        results in <strong>Products</strong>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                    <DropdownItem type="movies" key={"movies"} onClick={() => {
                                                        this.handleOnClickCategory("movies", moviesIcon);
                                                    }}>
                                                        {/* <img className="mr-3" src={moviesIcon} width="20" alt="icon" /> */}
                                        results in <strong>Movies</strong>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                    <DropdownItem type="professionals" key={"professionals"} onClick={() => {
                                                        this.handleOnClickCategory("professionals", professionalIcon);
                                                    }}>
                                                        {/* <img className="mr-3" src={professionalIcon} width="20" alt="icon" /> */}
                                        results in <strong>Professionals</strong>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                    <DropdownItem type="actors" key={"actors"} onClick={() => {
                                                        this.handleOnClickCategory("actors", actorIcon);
                                                    }}>
                                                        {/* <img className="mr-3" src={actorIcon} width="20" alt="icon" /> */}
                                            results in <strong>Actors</strong>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                    <DropdownItem type="crp" key={"projects"} onClick={() => {
                                                        this.handleOnClickCategory("projects", crpIcon);
                                                    }}>
                                                        {/* <img className="mr-3" src={crpIcon} width="20" alt="icon" /> */}
                                        results in <strong>CRP's</strong>
                                                    </DropdownItem>
                                                    {/* <DropdownItem className="my-1" divider /> */}
                                                    <DropdownItem type="people" key={"people"} onClick={() => {
                                                        this.handleOnClickCategory("people", peopleIcon);
                                                    }}>
                                                        {/* <img className="mr-3" src={peopleIcon} width="20" alt="icon" /> */}
                                        search only <strong>People</strong>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <InputGroup>
                                                <Input bsSize="sm" type="text" placeholder={this.state.isSearching ? '' : 'FIND'} name="search_name" onChange={this.handleChange} value={search_name} onFocus={() => this.setState({ isSearching: true })} autoComplete="off" />
                                                <InputGroupAddon addonType="append">
                                                    {search_name != "" ?
                                                        (
                                                            // <Button color="grey" size="sm">
                                                            <Link className="btn btn-grey btn-sm" 
                                                            to={{
                                                                pathname: `/search`,
                                                                search: `?q=${this.state.search_name}`,
                                                                state: { contenttype: this.state.content_type.toLowerCase() }
                                                                }}><img src={require("../../../assets/images/icons/search-btn.png")} alt="" onClick={() => this.setState({ isSearching: false })}  /> </Link>
                                                            // </Button>
                                                        )
                                                        :
                                                        (<Button color="grey" size="sm">
                                                            <img src={require("../../../assets/images/icons/search-btn.png")} alt="" />
                                                        </Button>)
                                                    }
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </div>
                                    <div className="px-1 flex-grow-1 d-lg-block" hidden={this.state.isSearching}>
                                        {/* Show city selected, hide when editing */}

                                        <div className={`main-search-holder ${this.state.enableSearch ? 'mw-100' : ''}`}>
                                            {this.state.enableSearch === false ?
                                                <div
                                                    role="button"
                                                    className="d-none d-md-block text-nowrap"
                                                    // style={{ minWidth: '150px' }}
                                                    onClick={this.handleEnabelSearch} >
                                                  <div className="location-holder" title={current_location?.address_text}>
                                                    <span>
                                                      {current_location?.city ?
                                                        `${this.toTitleCase(current_location?.city)}, `
                                                        : ''} </span>
                                                    <span>{current_location?.state ? `${current_location?.state[0].toUpperCase()}${current_location?.state.slice(1)}, ` : ''}</span>
                                                    <span>{current_location?.country ? current_location?.country.toUpperCase() : ''}</span>
                                                  </div>
                                                </div>
                                                : <PlacementAutoComplete
                                                    handleEnabelSearch={this.handleEnabelSearch}
                                                    current_location={`${current_location?.city ? current_location?.city : 'City'}, ${current_location?.state ? current_location?.state : 'State'}, ${current_location?.country ? current_location?.country : 'Country'}`}
                                                />}
                                        </div>
                                        {/* Show input field, show when editing */}
                                        {/* <div >
                                        <Input bsSize="sm" type="text" name="location" placeholder={`${current_location?.city} ${current_location?.country}`} />
                                    </div>
                                        */}
                                    </div>
                                </div>

                                {/* Show/hide while searching */}
                                <div>
                                    {/* Instant Search Results */}
                                    {this.state.isSearching ?
                                        <ul className="text-left list-unstyled text-dark instant-results bg-white mw-100 shadow" style={{ minWidth: '400px', maxHeight: '400px', overflowY: 'auto' }}>
                                            {/* Suggested results */}
                                            {Array.isArray(searchHomeData) && searchHomeData && searchHomeData.length > 0 ? (searchHomeData.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        {item._type == "taxonomy" && item._source.index == 0 && (
                                                            <li>
                                                                <div className="p-3 fs-14">
                                                                    <div className="text-tertiary">
                                                                        {'Are you looking for:'}
                                                                    </div>
                                                                    <ul
                                                                        className="list-unstyled text-primary font-weight-bold"
                                                                        style={{ maxHeight: '105px', overflowY: 'auto' }}>
                                                                        {Array.isArray(item._source.main_category) &&
                                                                            item._source.main_category && item._source.main_category[0].length > 0 ?
                                                                            (item._source.main_category[0][0].split(",").map((item2, index2) => {
                                                                                return (
                                                                                    <li key={index2}>
                                                                                        {/*<a className="text-reset ff-base" href={"/search?q=" + item2.replace(/\#.+/g, "$'")}>
                                                                                            {item2.replace(/\#.+/g, "$'")}
                                                                                        </a>*/}
                                                                                        <Link className="text-reset ff-base" 
                                                                                            to={{
                                                                                                pathname: `/search`,
                                                                                                search: `?q=${item2.replace(/\#.+/g, "$'")}`,
                                                                                                state: { contenttype: this.state.content_type.toLowerCase() }
                                                                                                }}>{item2.replace(/\#.+/g, "$'")} </Link>
                                                                                    </li>
                                                                                )
                                                                            })) : (null)
                                                                        }
                                                                    </ul>
                                                                </div>

                                                            </li>
                                                        )}
                                                        {/* featured results */}
                                                        {item._type == "listing" && (
                                                            <Link
                                                                to={{
                                                                    pathname: item._source?.professional_type ?
                                                                        `/${(item._source.content_type).toLowerCase()}/${item._source.professional_type}`
                                                                        : `/${(item._source.content_type).toLowerCase()}/${item._source.slug}`,
                                                                    state: { id: item._source.id }
                                                                }}
                                                                onClick={() => {
                                                                 
                                                                }}
                                                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none">
                                                                <li className="result-item" role="button" >

                                                                    <div className="result-link fs-14">
                                                                        <div className="d-flex">
                                                                            <div className="mr-3">
                                                                                <img
                                                                                    src={

                                                                                        item._source && item._source.listing_profileimage &&
                                                                                            item._source.listing_profileimage[0] &&
                                                                                            item._source.listing_profileimage[0].location ?
                                                                                            item._source.listing_profileimage[0].location :
                                                                                            "https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png"} width={50} alt=""
                                                                                    onError={(error) =>
                                                                                        (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div>

                                                                                <span className="font-weight-bold">
                                                                                    {item._source ? item._source.name : ""}
                                                                                </span>
                                                                                <div>
                                                                                    <img src={"https://userdatawikireviews.s3.amazonaws.com/images/star/blue/norating.png"} alt="" />
                                                                                </div>
                                                                                <div className="font-weight-normal lh-1-2">
                                                                                    {item._source && item._source.category != null ? item._source.category.split(',').join(', ') : ""}
                                                                                    {/* <span>{item._source && item._source.category != null ? item._source.category : ""}</span> */}
                                                                                </div>
                                                                                <div className="text-tertiary font-weight-normal mt-1 ff-alt fs-12">
                                                                                    {item?._source?.address?.length ?
                                                                                        `${item?._source?.address[0] && item?._source?.address[0].address1 ? item?._source?.address[0].address1 + ',' : ''} ${item?._source?.address[0] && item?._source?.address[0].address2 ? item?._source?.address[0].address2 + ',' : ''}`
                                                                                        : null}
                                                                                    <br />
                                                                                    {item?._source?.address?.length ?
                                                                                        `${item?._source?.address[0] && item?._source?.address[0].city ? item?._source?.address[0].city + ',' : ''} ${item?._source?.address[0] && item?._source?.address[0].state ? item?._source?.address[0].state + ',' : ''} ${item?._source?.address[0] && item?._source?.address[0].zipcode ? item?._source?.address[0].zipcode : ''}`
                                                                                        : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </li>
                                                            </Link>
                                                        )
                                                        }
                                                        
                                                        {/* featured results */}
                                                        {item._type == "user" && (
                                                            <Link
                                                                to={{
                                                                    pathname: `/people/${item._source.user?.username}`,
                                                                    state: { id: item._source._id }
                                                                }}
                                                                onClick={() => {
                                                               
                                                                }}
                                                                className="fs-16 text-reset ff-base font-weight-bold text-decoration-none">
                                                                <li className="result-item" role="button" >

                                                                    <div className="result-link fs-14">
                                                                        <div className="d-flex">
                                                                            <div className="mr-3">
                                                                                <img src={item._source && item._source.current_profile_pic ? item._source.current_profile_pic : item._source.current_profile_pic && item._source.current_profile_pic.length > 0 ? item._source.current_profile_pic.thumbnail : require('../../../assets/images/icons/placeholder-img.png')
                                                                                        } width={50} 
                                                                                        onError={(error) =>
                                                                                                (error.target.src = `require('../../../assets/images/icons/placeholder-img.png')`)
                                                                                            }
                                                                                            alt="" />
                                                                                
                                                                            </div>
                                                                            <div>

                                                                                <span className="font-weight-bold">
                                                                                    {item._source ? item._source.name : ""} 
                                                                                </span>
                                                                                <div>
                                                                                {item._source && item._source?.statistics ? item._source.statistics.reviews : "0"} reviews
                                                                                </div>
                                                                                    
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </li>
                                                            </Link>
                                                        )
                                                        }
                                                    </div>
                                                )
                                            })) : (null)
                                            }
                                        </ul>
                                        : ""}
                                </div>
                            </div>
                        </li>
                    </Nav>
                </Col>
                <Col xs="auto" className="d-none d-md-block">
                    <Link
                        to="/"
                        className="navbar-brand m-0 text-center">
                        <span className="text-uppercase font-weight-bold" data-type="text">
                            WikiReviews
                        </span>
                        <img src={wikiWhite} alt="WikiReviews" data-type="img" />
                    </Link>
                </Col>
                <Col className="d-flex col-auto col-md">
                    <Nav className="ml-auto" navbar>
                        <div className="ml-auto text-center">
                            <Button
                                color="link"
                                className="nav-link-header"
                                onClick={this.signUpModalToggle}
                            >
                                {"Sign up"}
                            </Button>
                            <span className="text-white">or</span>
                            <Button
                                color="link"
                                className="nav-link-header"
                                onClick={this.loginModalToggle}
                            >
                                {"Login"}
                            </Button>
                        </div>                    
                    </Nav>
                </Col>
            </Row>  
          </Container>
        </Navbar>

        {/* Sign Up Modal Popup */}
        <Modal isOpen={this.state.signUpModal} toggle={this.signUpModalToggle}>
          <ModalHeader className="px-0" toggle={this.signUpModalToggle}>
            SIGNUP FOR WIKIREVIEWS
          </ModalHeader>
          <ModalBody className="p-5">
            <FormGroup className="d-md-flex flex-wrap justify-content-center mx-n2">
              <Label className="font-weight-bold px-2 text-dark mb-0 fs-14 mt-2">
                Date of Birth <span className="required-mark"></span>
              </Label>
              <div className="px-2 mb-2">
                <Input
                  bsSize="sm"
                  className="primary bg-white"
                  type="select"
                  name="dobMonth"
                  //defaultValue="Month"
                  value={signup.dobMonth}
                  onChange={this.handleDobChange}
                >
                  <option value="">Month</option>
                  {months.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Input>
                {signup.errors.dobMonth ? (
                  <div className="small text-danger">
                    {signup.errors.dobMonth}
                  </div>
                ) : null}
              </div>
              <div className="px-2 mb-2">
                <Input
                  bsSize="sm"
                  className="primary bg-white"
                  type="select"
                  name="dobDay"
                  //defaultValue="Day"
                  value={signup.dobDay}
                  onChange={this.handleDobChange}
                >
                  <option value="">Day</option>
                  {[...Array(31).keys()].map((item) => (
                    <option value={item + 1}>{item + 1}</option>
                  ))}
                </Input>
                {signup.errors.dobDay ? (
                  <div className="small text-danger">
                    {signup.errors.dobDay}
                  </div>
                ) : null}
              </div>
              <div className="px-2 mb-2">
                <Input
                  bsSize="sm"
                  className="primary bg-white"
                  type="select"
                  name="dobYear"
                  //defaultValue="Year"
                  value={this.state.signup.dobYear}
                  onChange={this.handleDobChange}
                >
                  <option value="">Year</option>
                  {listOfYears.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </Input>
                {signup.errors.dobYear ? (
                  <div className="small text-danger">
                    {signup.errors.dobYear}
                  </div>
                ) : null}
              </div>
              {signup.errors.age ? (
                <div className="small text-danger">{signup.errors.age}</div>
              ) : null}
            </FormGroup>
            <div className="text-center mb-3 fs-14">
              <FormGroup check>
                <Label className="ff-alt" check>
                  <Input
                    type="checkbox"
                    name="tos"
                    value={this.state.signup.tos}
                    onChange={this.handleDobChange}
                  />{" "}
                  <span>
                    <span className="required-mark"></span> I accept&nbsp;
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ termsOfService: true });
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/terms"
                      className="font-weight-bold text-dark"
                    >
                      Terms of Service,
                    </a>
                    &nbsp;
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ privacyPolicy: true });
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/policies"
                      className="font-weight-bold text-dark"
                    >
                      Privacy Policy
                    </a>
                    &nbsp;and&nbsp;
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ contentGuidelines: true });
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/guidelines"
                      className="font-weight-bold text-dark"
                    >
                      Content Guidelines.
                    </a>
                    &nbsp;
                  </span>
                  {signup.errors.tos ? (
                    <div className="small text-danger">{signup.errors.tos}</div>
                  ) : null}
                </Label>
              </FormGroup>
            </div>
            <div className="mx-auto" style={{ maxWidth: "300px" }}>
              {this.renderFacebookButton()}
              {this.renderGoogleButton()}

              <Button
                color="primary"
                block
                className="mt-3 fs-14"
                onClick={() => this.handleOnClickEmailSignUp("email")}
                type="button"
              >
                <div className="d-flex">
                  <span className="mr-2">
                    <FontAwesomeIcon size="lg" fixedWidth icon="envelope" />
                  </span>
                  <span className="ml-5">
                    Sign Up with <span className="text-uppercase">Email</span>
                  </span>
                </div>
              </Button>
            </div>

            <div className="ml-auto" hidden>
              {!this.state.selectedInModalTwo.gmail ? (
                <GoogleContacts
                  clientId={GOOGLE_CLIENT_ID}
                  render={(renderProps) => (
                    <Button
                      color="dark"
                      className="mw text-tertiary"
                      onClick={renderProps.onClick}
                    >
                      Find Connections
                    </Button>
                  )}
                  buttonText="Import"
                  onSuccess={this.importGoogleContacts}
                  onFailure={this.importGoogleContacts}
                />
              ) : (
                  <span>
                    Connections Imported <FontAwesomeIcon icon="check-circle" />
                  </span>
                )}
            </div>
          </ModalBody>
        </Modal>

        {/* EMAIL SIGNUP FOR WIKIREVIEWS Modal Popup */}
        <Modal
          isOpen={this.state.emailSignUpModal}
          toggle={this.emailSignUpModalToggle}
        >
          <ModalHeader className="px-0" toggle={this.emailSignUpModalToggle}>
            EMAIL SIGNUP FOR WIKIREVIEWS
          </ModalHeader>
          <ModalBody className="login-wrap text-dark">
            <EmailSignupForm
              additionalInfo={this.state.signup}
              openOnBoardingModalOne={this.openOnBoardingModalOne}
            />
          </ModalBody>

          <ModalFooter className="justify-content-start">
            <div className="text-white font-weight-bold fs-14">
              Business Owner?
              <span
                className="text-primary"
                role="button"
                onClick={this.businessOwnerDetail}
              >
                {" "}
                Click here
              </span>
            </div>
            <div className="text-white font-weight-bold fs-14">
              Resend Activation Email
              <span
                className="text-primary"
                role="button"
                onClick={this.resendActEmailModalToggleFromSignup}
              >
                {" "}
                Click here
              </span>
            </div>
          </ModalFooter>
        </Modal>

        {/* Login Modal Popup */}
        <Modal
          size="sm"
          isOpen={this.state.loginModal}
          toggle={this.loginModalToggle}
        >
          <ModalHeader className="px-0" toggle={this.loginModalToggle}>
            LOG IN TO WIKIREVIEWS
          </ModalHeader>
          <ModalBody className="bg-transparent p-0">
            <Formik
              initialValues={formFields}
              validationSchema={validationYup}
              onSubmit={(values, { setSubmitting }) => {
                let { email, password } = values;
                let data = {
                  identification: email,
                  password: password,
                  subdomain: "wikireviews",
                };
                this.props.login_request(data);
                setSubmitting(false);
                this.setSubmitting = setSubmitting;
              }}
              render={(props) => {
                const {
                  values,
                  touched,
                  errors,
                  initialValues,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;

                const hasChanged = !deepEqual(values, initialValues);
                const hasErrors = Object.keys(errors).length > 0;
                return (
                  <form>
                    <div className="bg-white p-3 mb-3">
                      {this.renderFacebookButton()}
                      {this.renderGoogleButton()}
                    </div>

                    <div className="bg-white p-3 mb-3">
                      <FormGroup className="login-form-group">
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          autoComplete={"false"}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email &&
                            touched.email &&
                            "border border-danger"
                          }
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger small mt-1">
                            {errors.email}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup className="login-form-group">
                        <Input
                          type="password"
                          name="password"
                          placeholder="Enter Password"
                          autoComplete={"false"}
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.password &&
                            touched.password &&
                            "border border-danger"
                          }
                        />
                        {errors.password && touched.password && (
                          <div className="text-danger small mt-1">
                            {errors.password}
                          </div>
                        )}

                        {this.props.auth_error &&
                          this.props.auth_error.non_field_errors &&
                          this.props.auth_error.non_field_errors.length > 0 && (
                            <div className="text-danger small mt-1">
                              {this.props.auth_error.non_field_errors[0]}
                            </div>
                          )}
                      </FormGroup>

                      <div className="mb-2">
                        <Button
                          color="link"
                          className="text-decoration-none font-weight-bold p-0 ff-alt text-tertiary"
                          onClick={this.resetPassModalToggle}
                        >
                          {"forgot your password?"}
                        </Button>
                      </div>
                      <div>
                        <Button
                          color="link"
                          className="text-decoration-none font-weight-bold p-0 ff-alt text-tertiary"
                          onClick={this.resendActEmailModalToggle}
                        >
                          {"resend activation email"}
                        </Button>
                      </div>
                    </div>
                    <div className="text-right">
                      <Button
                        onClick={handleSubmit}
                        type="submit"
                        color="primary"
                        size="lg"
                        className="mw"
                        disabled={!hasChanged || hasErrors || isSubmitting}
                      >
                        {"Log In"}
                      </Button>
                    </div>
                  </form>
                );
              }}
            />
          </ModalBody>
        </Modal>

        {/* Reset Password Modal */}
        <Modal
          size="sm"
          isOpen={this.state.resetPassModal}
          toggle={this.resetPassModalToggle}
        >
          <ModalHeader className="px-0" toggle={this.resetPassModalToggle}>
            RESET YOUR PASSWORD
          </ModalHeader>
          <ModalBody className="bg-transparent p-0">
            <div className="bg-white p-3 mb-3">
              {this.renderFacebookButton()}
              {this.renderGoogleButton()}
            </div>

            <div className="bg-white p-3 mb-3">
              <FormGroup className="login-form-group">
                <Input
                  className="primary"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  value={this.state.resetPassword.email}
                  onChange={this.handleOnChangeResetPassword}
                />
                {this.state.resetPassword.error && (
                  <div className="text-danger small mt-1">
                    {this.state.resetPassword.error}
                  </div>
                )}
              </FormGroup>
            </div>

            {this.state.resetPassword.error ? (
              <div className="text-right">
                <Button
                  color="primary"
                  size="lg"
                  className="mw button-color-reset"
                  onClick={() => {
                    this.setState({
                      resetPassModal: !this.state.resetPassModal,
                    });
                    this.signUpModalToggle();
                  }}
                >
                  Register
                </Button>
                <Button
                  color="primary"
                  size="lg"
                  className="mw"
                  onClick={() => this.handleOnSubmitResetPassword()}
                >
                  Submit
                </Button>
              </div>
            ) : (
                <div className="text-right">
                  <Button
                    color="primary"
                    size="lg"
                    className="mw"
                    onClick={() => this.handleOnSubmitResetPassword()}
                  >
                    Submit
                </Button>
                </div>
              )}
          </ModalBody>
        </Modal>

        {/* Reset Password From Email Modal */}
        <Modal
          size="sm"
          isOpen={this.state.resetPassModalWithEmail}
          toggle={this.resetPassModalWithEmailToggle}
        >
          <ModalHeader
            className="px-0"
            toggle={this.resetPassModalWithEmailToggle}
          >
            YOU CAN SET YOUR PASSWORD
          </ModalHeader>
          <ModalBody className="bg-transparent p-0">
            <div className="bg-white p-3 mb-3">
              {this.renderFacebookButton()}
              {this.renderGoogleButton()}
            </div>

            <div className="bg-white p-3 mb-3">
              <FormGroup className="login-form-group">
                <Input
                  className="primary"
                  type="password"
                  name="newPassword"
                  placeholder="New Password"
                  value={this.state.resetPasswordWithEmail.newPassword}
                  onChange={this.handleOnChangeResetPasswordWithEmail}
                />
                <Input
                  className="primary"
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={this.state.resetPasswordWithEmail.confirmPassword}
                  onChange={this.handleOnChangeResetPasswordWithEmail}
                />
                {this.state.resetPasswordWithEmail.error && (
                  <div className="text-danger small mt-1">
                    {this.state.resetPasswordWithEmail.error}
                  </div>
                )}
              </FormGroup>
            </div>
            <div className="text-right">
              <Button
                color="primary"
                size="lg"
                className="mw"
                onClick={() => this.handleOnSubmitResetPasswordWithEmail()}
              >
                Submit
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {/* Resend Activation Email Modal */}
        <Modal
          size="sm"
          isOpen={this.state.resendActEmailModal}
          toggle={this.resendActEmailModalToggle}
        >
          <ModalHeader className="px-0" toggle={this.resendActEmailModalToggle}>
            <div className="fs-34">RESEND ACTIVATION EMAIL</div>
          </ModalHeader>
          <ModalBody className="bg-transparent p-0">
            <div className="bg-white p-3 mb-3">
              {this.renderFacebookButton()}
              {this.renderGoogleButton()}
            </div>

            <div className="bg-white p-3 mb-3">
              <FormGroup className="login-form-group">
                <Input
                  className="primary"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  value={this.state.resendActivationEmail.email}
                  onChange={this.handleOnChangeResendActivationEmail}
                />
                {this.state.resendActivationEmail.error && (
                  <div className="text-danger small mt-1">
                    {this.state.resendActivationEmail.error}
                  </div>
                )}
              </FormGroup>
            </div>
            <div className="text-right">
              <Button
                type="button"
                color="primary"
                size="lg"
                className="mw"
                onClick={() => this.handleOnSubmitResendActivationEmail()}
              >
                Submit
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {/* Onboarding Profile Information Part 1 Modal Popup  */}
        <Modal
          isOpen={this.state.onBoardingModalOne}
          toggle={this.onBoardingModalOneToggle}
        >
          <ModalHeader className="px-0" toggle={this.onBoardingModalOneToggle}>
            FILL OUT YOUR PROFILE INFORMATION
          </ModalHeader>
          <ModalBody>
            <div className="circle-step">
              <ul>
                <li className="active">
                  <strong>1</strong>
                  <span>Profile Information</span>
                </li>
                <li>
                  <strong>2</strong>
                  <span>Find Your Connections</span>
                </li>
                <li>
                  <strong>3</strong>
                  <span>Profile Picture</span>
                </li>
              </ul>
            </div>
            <div className="text-center mb-3">
              <strong className="ff-base text-dark fs-14">
                Fill out your Profile Information
              </strong>
            </div>
            <p className="fs-14 text-dark">
              A shared love for experiencing movies, businesses, and products is
              what makes the WikiReviews community strong. Highlight your
              personal style and customize your experience by selecting{" "}
              <i className="font-weight-bold">at least 3 subcategories</i> in
              each category below!
            </p>
            <div className="mb-3">
              <div className="font-weight-bold mb-2 fs-14 text-dark">
                Favorite Movies Categories
              </div>
              <div className="text-center">
                <Carousel
                  className="category-carousel"
                  activeIndex={this.state.activeMovieIndex}
                  next={this.nextMovie}
                  previous={this.previousMovie}
                  interval={this.state.selectedInModalOne.moviesCategory.length > 2 ? false : 5000}
                >
                  {this.movieSlides()}
                  <CarouselControl
                    className="theme-carousel-control"
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={this.previousMovie}
                  />
                  <CarouselControl
                    className="theme-carousel-control"
                    direction="next"
                    directionText="Next"
                    onClickHandler={this.nextMovie}
                  />
                </Carousel>
              </div>
            </div>
            <div className="mb-3" ref={this.favBusinessCategoriesRef}>
              <div className="font-weight-bold mb-2 fs-14 text-dark">
                Favorite Business Categories
              </div>
              <div className="text-center">
                <Carousel
                  className="category-carousel"
                  activeIndex={this.state.activeBusinessIndex}
                  next={this.nextBusiness}
                  previous={this.previousBusiness}
                  interval={this.state.selectedInModalOne.businessCategory.length > 2 ? false : 5000}
                >
                  {this.businessSlides()}
                  <CarouselControl
                    className="theme-carousel-control"
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={this.previousBusiness}
                  />
                  <CarouselControl
                    className="theme-carousel-control"
                    direction="next"
                    directionText="Next"
                    onClickHandler={this.nextBusiness}
                  />
                </Carousel>
              </div>
            </div>
            <div ref={this.favProductCategoriesRef}>
              <div className="font-weight-bold mb-2 fs-14 text-dark">
                Favorite Product Categories
              </div>
              <div className="text-center">
                <Carousel
                  className="category-carousel"
                  activeIndex={this.state.activeProductIndex}
                  next={this.nextProduct}
                  previous={this.previousProduct}
                  interval={this.state.selectedInModalOne.productsCategory.length > 2 ? false : 5000}
                >
                  {this.productSlides()}
                  <CarouselControl
                    className="theme-carousel-control"
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={this.previousProduct}
                  />
                  <CarouselControl
                    className="theme-carousel-control"
                    direction="next"
                    directionText="Next"
                    onClickHandler={this.nextProduct}
                  />
                </Carousel>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-start px-0">
            <div className="text-right w-100" ref={this.saveContinueRef}>
              <Button
                color="link"
                className="text-decoration-none text-white hover-blue"
                onClick={() => this.onClickSkipModalOne()}
              >
                Skip this Step
              </Button>
              <Button
                color="primary"
                className={`mw ${this.state.selectedInModalOne.saveContinueBlink ? 'wk-new-button-glow' : ''}`}
                onClick={() => this.onClickSaveModalOneData()}
              >
                Save & Continue
              </Button>
            </div>
            <div
              className="text-primary p-2 text-left w-100 mt-3 fs-14"
              style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
            >
              Skipping this step will hinder you from experiencing everything
              WikiRieviews has to offer. Be sure to include a few of your
              favorites in each category for best results.
            </div>
          </ModalFooter>
        </Modal>

        {/* Onboarding Connections Part 2 Modal Popup  */}
        <Modal
          isOpen={this.state.onBoardingModalTwo}
          toggle={this.onBoardingModalTwoToggle}
        >
          <ModalHeader className="px-0" toggle={this.onBoardingModalTwoToggle}>
            START BY FINDING YOUR CONNECTIONS
          </ModalHeader>
          <ModalBody>
            <div className="circle-step">
              <ul>
                <li>
                  <strong>1</strong>
                  <span>Profile Information</span>
                </li>
                <li className="active">
                  <strong>2</strong>
                  <span>Find Your Connections</span>
                </li>
                <li>
                  <strong>3</strong>
                  <span>Profile Picture</span>
                </li>
              </ul>
            </div>
            <div className="text-center mb-3">
              <strong className="ff-base text-dark fs-14">
                Are your connections already on WikiReviews?
              </strong>
            </div>
            <p className="fs-14 text-dark">
              How many of your friends and business connections are already
              using WikiReviews? Find out and connect by filling in your email
              address(s) below.
            </p>

            <div>
              <ul className="list-styled">
                <li className="ff-base border-bottom mb-3">
                  <div className="d-flex flex-wrap mb-2">
                    <div className="mr-2">
                      <img
                        width={20}
                        src={gmailIcon}
                        alt="Gmail"
                        className="mr-2"
                      />
                      <span className="font-weight-bold fs-14 text-dark">
                        Gmail
                      </span>
                    </div>
                    <div className="ml-auto">
                      {/* {!this.state.selectedInModalTwo.gmail ? ( */}
                      {true ? (

                        <GoogleContacts
                          clientId={GOOGLE_CLIENT_ID}
                          render={(renderProps) => (
                            <Button
                              color="dark"
                              className="mw text-tertiary"
                              onClick={renderProps.onClick}
                            >
                              Find Connections
                            </Button>
                          )}
                          buttonText="Import"
                          onSuccess={this.importGoogleContacts}
                          onFailure={this.importGoogleContacts}
                        />
                      ) : (
                          <span>
                            Connections Imported{" "}
                            <FontAwesomeIcon icon="check-circle" />
                          </span>
                        )}
                    </div>
                  </div>
                </li>
                <li className="ff-base border-bottom mb-3">
                  <div className="d-flex flex-wrap mb-2">
                    <div className="mr-2">
                      <img
                        width={20}
                        src={hotmailIcon}
                        alt="Hotmail"
                        className="mr-2"
                      />
                      <span className="font-weight-bold fs-14 text-dark">
                        Hotmail
                      </span>
                    </div>
                    <div className="ml-auto">
                      <Button color="dark" className="mw text-tertiary">
                        Find Connections
                      </Button>
                    </div>
                  </div>
                </li>
                <li className="ff-base border-bottom mb-3">
                  <div className="d-flex flex-wrap mb-2">
                    <div className="mr-2">
                      <img
                        width={20}
                        src={yahooIcon}
                        alt="Yahoo"
                        className="mr-2"
                      />
                      <span className="font-weight-bold fs-14 text-dark">
                        Yahoo
                      </span>
                    </div>
                    <div className="ml-auto">
                      <Button color="dark" className="mw text-tertiary">
                        Find Connections
                      </Button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-start px-0">
            <Button
              color="link"
              className="text-decoration-none text-white"
              onClick={() =>
                this.setState({
                  onBoardingModalTwo: false,
                  onBoardingModalOne: true,
                })
              }
            >
              <FontAwesomeIcon icon="angle-left" /> Back
            </Button>
            <Button
              color="primary"
              className="mw ml-auto"
              onClick={() => this.onClickSaveModalTwoData()}
            >
              Continue
            </Button>
          </ModalFooter>
        </Modal>

        {/* Onboarding Profile Picture Part 3 Modal Popup  */}
        <Modal
          isOpen={this.state.onBoardingModalThree}
          toggle={this.onBoardingModalThreeToggle}
        >
          <ModalHeader
            className="px-0"
            toggle={this.onBoardingModalThreeToggle}
          >
            SET YOUR PROFILE PICTURE
          </ModalHeader>
          <ModalBody>
            <div className="circle-step">
              <ul>
                <li>
                  <strong>1</strong>
                  <span>Profile Information</span>
                </li>
                <li>
                  <strong>2</strong>
                  <span>Find Your Connections</span>
                </li>
                <li className="active">
                  <strong>3</strong>
                  <span>Profile Picture</span>
                </li>
              </ul>
            </div>
            <div className="text-center mb-3">
              <strong className="ff-base text-dark fs-14">
                Set your profile picture
              </strong>
            </div>
            <p className="fs-14 text-dark">
              You know all about profile pictures. Make it personal. Make it
              distinctive. This is the image the entire WikiReviews community
              will know you by.
            </p>
            <div className="text-center p-3">
              <div className="profile-pic-holder">
                <img
                  className="profile-pic"
                  src={
                    this.state.selectedInModalThree.image === ""
                      ? userIcon
                      : this.state.selectedInModalThree.image
                  }
                  alt="Profile"
                />
                <Label for="newProfilePhoto" className="upload-file-block">
                  <div className="text-center">
                    <div className="mb-2">
                      <FontAwesomeIcon icon="camera" size="2x" />
                    </div>
                    <div className="text-uppercase">
                      Upload <br /> Profile Photo
                    </div>
                  </div>
                </Label>
                <Input
                  type="file"
                  name="profile_pic"
                  id="newProfilePhoto"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={this.onChangeUploadProfileImage}
                />
              </div>
            </div>
            {this.state.selectedInModalThree?.imageError && (
              <div className="text-danger small mt-1 text-center">
                {this.state.selectedInModalThree.imageError}
              </div>
            )}
            <div className="text-center fs-14 text-dark ff-alt">
              Be sure to include a high-quality profile picture for best
              results.
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-start px-0">
            <Button
              color="link"
              className="text-decoration-none text-white"
              onClick={() =>
                this.setState({
                  onBoardingModalThree: false,
                  onBoardingModalTwo: true,
                })
              }
            >
              <FontAwesomeIcon icon="angle-left" /> Back
            </Button>
            <Button
              color="primary"
              className="mw ml-auto"
              onClick={() => this.onClickSaveModalThreeData()}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>

        {/* Terms of service popup */}
        <Modal
          className="mt-5"
          isOpen={this.state.termsOfService}
          toggle={() => this.setState({ termsOfService: false })}
        >
          <ModalHeader
            className="px-0"
            toggle={() => this.setState({ termsOfService: false })}
          >
            Terms of Service
          </ModalHeader>
          <ModalBody className="p-5">
            <TermsOfService />
          </ModalBody>
        </Modal>

        {/* Terms of service popup */}
        <Modal
          className="mt-5"
          isOpen={this.state.privacyPolicy}
          toggle={() => this.setState({ privacyPolicy: false })}
        >
          <ModalHeader
            className="px-0"
            toggle={() => this.setState({ privacyPolicy: false })}
          >
            Privacy Policy
          </ModalHeader>
          <ModalBody className="p-5">
            <PrivacyPolicy />
          </ModalBody>
        </Modal>

        {/* Terms of service popup */}
        <Modal
          className="mt-5"
          isOpen={this.state.contentGuidelines}
          toggle={() => this.setState({ contentGuidelines: false })}
        >
          <ModalHeader
            className="px-0"
            toggle={() => this.setState({ contentGuidelines: false })}
          >
            Content Guidelines
          </ModalHeader>
          <ModalBody className="p-5">
            <Guidelines />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapState = (state) => ({
  auth_response: state.auth.auth_login,
  auth_error: state.auth.auth_error,
  category_list: state.auth.category_list,
  email_signup_response: state.auth.email_signup_response,
  user_information: state.auth.user_information,
  reset_password_error: state.auth.reset_password_error,
  resend_activation_email_error: state.auth.resend_activation_email_error,
  top_categories: state.home.top_categories, //merge headers start
  my_profile_info: state.user.my_profile,
  current_location: state.home.current_location,
  fetch_my_notification: state.home.fetch_my_notification,
  get_search_home: state.home.get_search_home,
  loginPromptSignUp:state.shared.loginPromptSignUp,
  loginPromptLogin:state.shared.loginPromptLogin
});

const mapProps = (dispatch) => ({
  is_auth_login_true: () => dispatch(is_auth_login_true()),
  login_request: (data) => dispatch(auth_login_request(data)),
  get_captcha: () => dispatch(get_captcha()),
  get_category_list: () => dispatch(get_category_list()),
  bulk_favourite: () => dispatch(bulk_favourite()),
  get_user_information: (username) => dispatch(get_user_information(username)),
  upload_media: (data) => dispatch(upload_media(data)),
  set_email_signup_response: (data) =>
    dispatch(set_email_signup_response(data)),
  set_email_signup_error: (data) => dispatch(set_email_signup_error(data)),
  reset_password: (data) => dispatch(reset_password(data)),
  google_login_request: (data,accessToken) => dispatch(google_login_request(data, accessToken)),
  facebook_login_request: (data) => dispatch(facebook_login_request(data)),
  auth_login_error_: (data) => dispatch(auth_login_error_(data)),
  resend_activation_email: (data) => dispatch(resend_activation_email(data)),
  set_resend_activation_email_error: (data) =>
    dispatch(set_resend_activation_email_error(data)),
  set_reset_password_error: (data) => dispatch(set_reset_password_error(data)),
  reset_password_with_email: (data, token) =>
    dispatch(reset_password_with_email(data, token)),
  setInitialAuth: () => dispatch(setInitialAuth()),
  fetch_category_list: (data) => dispatch(fetch_category_list(data)),//merge headers start
  fetch_current_location: () => dispatch(fetch_current_location()),
  get_searchHome: (query, contentType) => dispatch(get_searchHome(query, contentType)),
  clear_search_home: () => dispatch(clear_search_home()),
  my_profile: () => dispatch(my_profile()),
	add_tempLocation: (data) => dispatch(add_tempLocation(data)),
  setLoginPromptSignUp:(data)=>dispatch(setLoginPromptSignUp(data)),
  setLoginPromptLogin:(data)=>dispatch(setLoginPromptLogin(data)),
});

export default withRouter(connect(mapState, mapProps)(PublicPageHeader));
