import React, { useEffect, useMemo, useState } from 'react'
import { capitalizeFirstLetter, searchDataMapper } from "../../../../utils/utilities";
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { detectlink } from '../../../../utils/utilities';
import DataTable from '../../admin_atoms/dataTable';
import querystring from "query-string";
import { callApi, responseCodeValidator } from '../../../../utils/apiCaller';
import ViewReviewMedia from '../../admin_atoms/viewMedia';
import { debounce } from 'lodash';


const Reviews = ({ tabType, currentTab }) => {
    const initialState ={
        reviews: [],
        pageCount: 0,
        loading: true,
        currentPage: 0,
        searchReviewText: '',

        isMediaModalOpen:false,
        mediaModalType:null,
        mediaAssociated:null,
        mediaReviewId:null,
    }

    const [state,setState] = useState(initialState);
    const pageMaxCount = 10;
    const {
        reviews,
        pageCount,
        loading,
        currentPage,
        searchReviewText,
        isMediaModalOpen,
        mediaModalType,
        mediaAssociated,
        mediaReviewId
    } = state;
    
    const columnFields = ["s.n", "created_by", "review", "name", "created_date", "media", "action"];

    const optimizedColumns = columnFields.map((col) => {
        if (col === "s.n") {
            return {
                Header: "Sr No",
                accessor: col,
                Cell: ({ row }) => <span>{row.index + 1 + (currentPage * pageMaxCount)}</span>,
            };
        }
        if (col === "created_by") {
            return {
                Header: "Created By",
                accessor: col,
                Cell: ({ row }) => <span><Link to={`/people/${row.original.created_by?.user?.username}`}>{row.original.created_by?.user?.first_name + " " + row.original.created_by?.user?.last_name}</Link></span>,
            };
        }
        if (col === "review") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span className='review-content'>{ReactHtmlParser(detectlink(row.original?.review))}</span>,
            };
        }

        if (col === "name") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span className='listing-name'><Link to={{ pathname: `/${tabType}/${row.original.associated_to.id || row.original.associated_to?.slug}`, state: { id: row.original.associated_to.id } }}>{row.original.associated_to.name}</Link></span>,
            };
        }

        if (col === "created_date") {
            return {
                Header: "Created Date",
                accessor: col,
                Cell: ({ row }) => <span>{moment(row.original.created_date_time).format("MMM DD, YYYY")}</span>,
            };
        }

        if (col === "media") {
            return {
                Header: "Media",
                Cell: ({ row }) => <>
                    <td>
                        <span>
                            <a href='#' onClick={(e) => { e.preventDefault(); toggleMediaModal({ mediaModalType: 'image', mediaAssociated: row.original.associated_to.id, mediaReviewId: row.original.id }); }} >Images</a>
                        </span>
                    </td>
                    <td>
                        <span>
                            <a a href='#' onClick={(e) => { e.preventDefault(); toggleMediaModal({ mediaModalType: 'video', mediaAssociated: row.original.associated_to.id, mediaReviewId: row.original.id }); }}>Videos</a>
                        </span>
                    </td>
                </>,
            };
        }

        if (col === "action") {
            return {
                Header: capitalizeFirstLetter(col),
                accessor: col,
                Cell: ({ row }) => <span className={`btn btn-sm ${row.original.is_active ? `disable-button` : `enable-button`}`} onClick={() => { toggleReviewStatus({ review: row.original }) }} >{row.original.is_active ? `Disable` : `Enable`}</span>,
            };
        }
    });

    const columns = useMemo(() => optimizedColumns, [optimizedColumns]);
    const Reviews = React.useMemo(() => reviews, [reviews]);

    useEffect(() => {
        currentTab == tabType && fetchReviews({
            page: 1
        });
    }, [currentTab, tabType]);

    const fetchReviews = ({ page }) => {

        const params = {
            entry: searchReviewText,
            user: '',
            asset: capitalizeFirstLetter(tabType),
            page: page,
        } 

        const queryParams = querystring.stringify(params);

        const url = `/api/adminreviews/?${queryParams}`;
        setState((prevState) => ({ ...prevState, loading: true }));

        callApi(url, 'GET').then((res) => {
            if (res && responseCodeValidator(String(res.code))) {
                setState((prevState) => ({
                    ...prevState,
                    currentPage: page - 1,
                    reviews: res.results,
                    pageCount: Math.ceil(res?.count / pageMaxCount),
                    loading: false
                }));
            }
            else{
                setState((prevState) => ({
                    ...prevState,
                    currentPage: 0,
                    reviews: [],
                    pageCount: 0,
                    loading: false
                }));
            }
        });
    }

    const toggleReviewStatus = ({ review }) => {

        const isReviewActive = review?.is_active;

        const body = {
            id: review.id,
            is_active: isReviewActive ? `Disable` : `Enable`,
        }
        setState((prevState) => ({ ...prevState, loading: true }));
        callApi(`/api/adminreviews/${review.id}/`, 'PUT', body).then((res) => {
            if (res && responseCodeValidator(String(res.code))) {
                fetchReviews({ page: currentPage + 1 });
            }
        });
    }

    const toggleMediaModal = ({ 
        mediaModalType,
        mediaAssociated,
        mediaReviewId, }) => {
        if (!isMediaModalOpen) {
            setState((prev) => ({ ...prev, isMediaModalOpen: true, mediaModalType, mediaAssociated, mediaReviewId }));
        } else {
            setState((prev) => ({ ...prev, isMediaModalOpen: false, mediaModalType: null, mediaAssociated: null, mediaReviewId: null }));
        }
    }

    const debounceSearchReview = debounce(() => {
        fetchReviews({ page: 1 })
    }, 600);

    const searchReview = (searchValue) => {
        setState((prevState) => ({
            ...prevState,
            searchReviewText: searchValue
        }));
    }

    useEffect(() => {
        debounceSearchReview();

        return () => {
            debounceSearchReview.cancel();
        };
    }, [searchReviewText])


    return (
        <div className='main-data-container'>
            <div className="table-header">
                <h4 className='table-title'>{capitalizeFirstLetter(tabType)}</h4>
            </div>
            <div className="table-container">
                <DataTable
                    columns={columns}
                    data={Reviews}
                    pageCount={pageCount}
                    loading={loading}
                    fetchData={fetchReviews}
                    currentPage={currentPage}
                    handleSearch={searchReview}
                    searchText={searchReviewText}
                    tab={capitalizeFirstLetter(tabType)}
                />
            </div>
            {
                isMediaModalOpen && <ViewReviewMedia
                    media_type={mediaModalType}
                    associated_id={mediaAssociated}
                    review_id={mediaReviewId}
                    isModalOpen={isMediaModalOpen}
                    toggleModal={toggleMediaModal}
                />
            }
        </div>
    )
}

export default Reviews;