import { Formik, FieldArray } from "formik";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import React from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Progress,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { callApi } from "../../../utils/apiCaller";
import { Link } from 'react-router-dom';

import {
  media_upload_scroll,
  fetch_media_data,
  clear_media_dispatch,
  upload_add_captions,
  fetch_taxonomy_list,
} from "../../../actions/myProfiles";

import {
  upload_media,
  initFetchProductSubCategories,
  clear_cat_sub_product
} from "../../../actions/footer";

import {
  add_my_post,
  get_album_types_list,
  get_album_type_data,
  delete_selected_gallery_media
} from "../../../actions/user";

import {
  add_product,
  clear_product
} from "../../../actions/products";
import { toast } from "react-toastify";


const validate = Yup.object().shape({
  websites_set: Yup.array()
    .of(
      Yup.object().shape({
        website: Yup.string().min(4, "too short").required("Required"), // these constraints take precedence
        website_type: Yup.number().required("Please select Website Type"),
      })
    )
    .required("Must have websites")
    .min(3, "Minimum of 3 websites"),
  phones_set: Yup.array()
    .of(
      Yup.object().shape({
        phone: Yup.string().min(4, "too short").required("Required"),
        label: Yup.number().required("Please select Label"),
      })
    )
    .required("Phone is required")
    .min(3, "Minimum of 3 Phone"),
  features_set: Yup.array()
    .of(
      Yup.object().shape({
        features: Yup.string().min(4, "too short").required("Required"),
        label: Yup.number().required("Please select Label"),
      })
    )
    .required("Feature is required")
    .min(3, "Minimum of 3 features"),

});

class AddProductModal extends React.Component {
  constructor(props) {
    super(props);
    this.myScrollPhotoRef = React.createRef();
    this.myScrollVideoRef = React.createRef();

    this.refGalleryVideoScroll = React.createRef();
    this.refGalleryImageScroll = React.createRef();

    this.refScrollDeleteImages = React.createRef();
    this.refScrollDeleteVideos = React.createRef();
    this.state = {
      addProductForm: {
        name: '',
        manufacturer: "",
        modelNumber: null,
        is_draft: false,
        additional_info: {
          price_min: "",
          price_max: "",
          packedWeight: "",
          packed_height: "",
          packed_depth: "",
          packed_width: "",
          packed_weight: "",
          assembled_weight: "",
          assembled_width: "",
          assembled_depth: "",
          assembled_height: "",
          assembled_weight: "",
          manufacturer_warranty: "",
          manufacturer_cut_sheet: "",
        },
        taxonomy: [""],
        media: [""],
        websites_set: [
          {
            website: "",
            website_type: ""
          },
        ],
        phones_set: [
          {
            phone: "",
            label: "",
          },
        ],
        features_set: [
          {
            features: "",
            label: "",
          },
        ],
      },
      name: '',
      phoneEerror: {},
      manufacturer: "",
      modelNumber: null,
      maxPrice: "",
      minPrice: "",
      manufactureWarranty: "",
      manufactureCutSheet: "",
      assembledWeight: "",
      assembledHeight: "",
      assembledDepth: "",
      assembledWidth: "",
      packedWeight: "",
      packedHeight: "",
      packedDepth: "",
      packedWidth: "",
      category_id: '',
      subcategory_id: '',
      myPostsList: {},
      anothersubcategory_id: '',
      othersubcategory_id: '',
      websites_set: [
        {
          website: "",
          website_type: ""
        },
      ],
      phones_set: [
        {
          phone: "",
          label: "",
        },
      ],
      features_set: [
        {
          features: "",
          label: "",
        },
      ],
      mediaSet: [],
      uploadMediaModal: false,
      media: [],
      fileReviewStatus: false,
      selectedFilesPreview: [],
      latestUploadedData: {},
      showVideoMedia: true,
      fetchTaxonomy: [],
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      fetchGalleryScroll: {},
      fetchGalleryCount: 0,
      files: [],
      nameError: "",
      manufactureError: "",
      categoryError: "",
      productSubcategory: [],
      productAnothSabcategory: [],
      productOtherSabcategory: [],
      uploadCaptions: {},
      taxonomycategoryId: "",
      fetchTaxomomyCategory: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
      albumType: "",
      priceError: "",
      uploadMediaPostModal: false,
      uploadMedia: {
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypesList: [],
        albumTypeData: {},
        selectedMedia: [],
        selectedMediaIds: [],
        embedLinks: {},
        uploadFiles: [],
        uploadedFiles: [],
        progress: 0,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    let {
      latestUploadedData,
      fetchGalleryScroll,
      fetchGalleryCount,
    } = this.state;
    if (nextProps.album_types_list && nextProps.album_types_list.length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypesList: nextProps.album_types_list,
        },
        selectedUpMediaType: "upload",
        showGalleryType: "images",
      });
    }

    if (
      nextProps.album_type_data &&
      Object.keys(nextProps.album_type_data).length > 0
    ) {
      let item = nextProps.album_type_data;
      if (item.album) {
        fetchGalleryScroll = [];
      }
      fetchGalleryScroll[item.page] = item;
      fetchGalleryCount = item.count;
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          albumTypeData: nextProps.album_type_data,
        },
        fetchGalleryScroll,
        fetchGalleryCount,
      });
    }

    if (nextProps.productSabcategories && Array.isArray(nextProps.productSabcategories) && nextProps.productSabcategories.length > 0) {
      this.setState({ productSubcategory: nextProps.productSabcategories });
    }
    if (nextProps.productAnothSabcategories && Array.isArray(nextProps.productAnothSabcategories) && nextProps.productAnothSabcategories.length > 0) {
      this.setState({ productAnothSabcategory: nextProps.productAnothSabcategories });
    }
    if (nextProps.productOtherSabcategories && Array.isArray(nextProps.productOtherSabcategories) && nextProps.productOtherSabcategories.length > 0) {
      this.setState({ productOtherSabcategory: nextProps.productOtherSabcategories });
    }

    if (nextProps.fetch_taxonomy_listData) {
      let taxCatListing = nextProps.fetch_taxonomy_listData;

      if (
        taxCatListing &&
        Array.isArray(taxCatListing) &&
        taxCatListing.length > 0
      ) {
        if (taxCatListing[0].isTypes == "images") {
          this.setState({
            fetchTaxonomyListingImages: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        } else {
          this.setState({
            fetchTaxonomyListingVideos: taxCatListing[0].children
              ? taxCatListing[0].children
              : [],
          });
        }
      }
    }

    if (nextProps.fetch_save_response) {
      if (nextProps.fetch_save_response && nextProps.fetch_save_response.id && nextProps.fetch_save_response.slug) {
        let slug = null;
        let productId = "";
        if (nextProps.fetch_save_response.id != "") {
          productId = nextProps.fetch_save_response.id;
        }
        if (nextProps.fetch_save_response.slug != "") {
          slug = nextProps.fetch_save_response.slug;
        }
        this.redirectto(productId, slug);

      }
    }



  }

  componentWillUnmount() {
    this.props.clear_cat_sub_product();
    this.props.clear_product();
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    if (name == "name") {
      this.setState({
        nameError: ""
      });
    } else if (name == "manufacturer") {
      this.setState({
        manufactureError: ""
      });
    } else if (name == "category_id") {
      this.setState({
        categoryError: ""
      });
    } else if (name == "minPrice") {
      this.setState({
        priceError: ""
      });
    } else if (name == "maxPrice") {
      this.setState({
        priceError: ""
      });
    }

    if (name == "category_id") {
      let type = "category_id"
      this.setState({
        category_id: e.target.value
      });
      this.props.initFetchProductSubCategories(e.target.value, type);
    }
    if (name == "subcategory_id") {
      let type = "subcategory_id"
      this.setState({
        category_id: e.target.value
      });
      this.props.initFetchProductSubCategories(e.target.value, type);
    }

    if (name == "anothersubcategory_id") {
      let type = "anothersubcategory_id"
      this.setState({
        category_id: e.target.value
      });
      this.props.initFetchProductSubCategories(e.target.value, type);
    }

  }

  redirectto = (productId, slug) => {
    let path = "products";

    this.props.history.push({
      pathname: `/${path}/${slug}`,
      state: { id: productId }
    })
  }

  handleCancel = () => {
    this.setState({
      productSubcategory: [],
      productAnothSabcategory: [],
      productOtherSabcategory: []
    });
    this.props.clear_cat_sub_product();
    this.props.toggleProductModal();
  }



  handleChangeCast = (e, index) => {
    const { websites_set, phones_set, features_set, phoneEerror } = this.state
    const { name, value } = e.target
    if (name.includes('websites') && websites_set.length <= index) {
      websites_set.push({ website: "", website_type: "" })
      this.setState({ websites_set })
    }
    if (name.includes('phones') && phones_set.length <= index) {
      phones_set.push({ phone: "", label: "" })
      this.setState({ phones_set })
    }
    if (name.includes('features') && features_set.length <= index) {
      features_set.push({ features: "", label: "" })
      this.setState({ features_set })
    }

    if (name === 'websites_name') {
      websites_set[index].website = value
      this.setState({ websites_set })
    }
    else if (name === 'websites_type') {
      websites_set[index].website_type = value
      this.setState({ websites_set })
    }
    else if (name === 'phones_name') {
      phones_set[index].phone = value
      if (value.length < 10 && value.length > 1) {
        let phoneError = "Min 10 digits/numbers"
        phoneEerror[index] = phoneError;
        this.setState({ phones_set, phoneEerror })
        e.preventDefault();
      } else {
        let phoneError = ""
        phoneEerror[index] = phoneError;
        this.setState({ phones_set, phoneEerror })
      }
    }
    else if (name === 'phones_label') {
      phones_set[index].label = value

      this.setState({ phones_set })
    }
    else if (name === 'features_name') {
      features_set[index].features = value
      this.setState({ features_set })
    }
    else if (name === 'features_label') {
      features_set[index].label = value
      this.setState({ features_set })
    }

  }

  handleremoveCast = (option) => {
    const { websites_set, phones_set, features_set } = this.state
    if (option === 'websites_set') {
      websites_set.pop()
      this.setState({ websites_set })
    }
    else if (option === 'phones_set') {
      phones_set.pop()
      this.setState({ phones_set })
    }
    else if (option === 'features_set') {
      features_set.pop()
      this.setState({ features_set })
    }

  }

  handleProductSubmit = () => {
    let { name, manufacturer, websites_set, phones_set, features_set, category_id, minPrice, maxPrice } = this.state;

    if (name == "" && manufacturer == "" && category_id == "") {
      this.setState({
        nameError:
          "Required",
        manufactureError:
          "Required",
        categoryError:
          "Required",
      });
    }
    if (manufacturer == "") {
      this.setState({
        manufactureError:
          "Required",
      });
    }
    if (name == "") {
      this.setState({
        nameError:
          "Required",
      });
    }
    if (category_id == "") {
      this.setState({
        categoryError:
          "This field is required",
      });
    }

    if (parseInt(maxPrice) < parseInt(minPrice)) {
      this.setState({
        priceError:
          "Max Price should not be less than Min Price",
      });
      return false;
    }
    if (name != "" && manufacturer != "" && category_id != "") {
      let params = {}
      let media = []
      /*this.state.uploadMedia.selectedMediaIds?.map(med => {
          media.push(med.id)
        })*/
      let intItem = parseInt(this.state.category_id);
      let minPrice = parseInt(this.state.minPrice);
      let maxPrice = parseInt(this.state.maxPrice);
      let packedHeight = parseInt(this.state.packedHeight);
      let packedWidth = parseInt(this.state.packedWidth);
      let packedWeight = parseInt(this.state.packedWeight);
      let packedDepth = parseInt(this.state.packedDepth);
      let assembledWeight = parseInt(this.state.assembledWeight);
      let assembledDepth = parseInt(this.state.assembledDepth);
      let assembledHeight = parseInt(this.state.assembledHeight);
      let assembledWidth = parseInt(this.state.assembledWidth);
      params = {
        name: this.state.name,
        is_draft: this.state.is_draft,
        additional_info: {
          price_min: minPrice,
          price_max: maxPrice,
          packed_height: packedHeight,
          packed_depth: packedDepth,
          packed_width: packedWidth,
          packed_weight: packedWeight,
          assembled_weight: assembledWeight,
          assembled_width: assembledWidth,
          assembled_depth: assembledDepth,
          assembled_height: assembledHeight,
          manufacturer_warranty: this.state.manufactureWarranty,
          manufacturer_cut_sheet: this.state.manufactureCutSheet,
        },
        manufacturer: this.state.manufacturer,
        website_set: websites_set && websites_set[0] && websites_set[0].website == "" ? {} : websites_set,
        phone_set: phones_set && phones_set[0] && phones_set[0].phone == "" ? {} : phones_set,
        feature_set: features_set && features_set[0] && features_set[0].features == "" ? {} : features_set,
        taxonomy: [intItem],
      }
      if (this.state.modelNumber != "" || this.state.modelNumber != null) {
        params.model_number = this.state.modelNumber
      }
      if (this.state.uploadMedia.selectedMediaIds.length > 0) {
        params.media = this.state.uploadMedia.selectedMediaIds
      } else {
        toast(`Please add media with the listing.`, {
          autoClose: 3500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return 0;
      }
      this.props.add_product(params)
    }
  }

  handleOnFilePostUploadChange = (event) => {
    let uploadFiles = event.target.files;
    let showFiles = [];
    for (const key of Object.keys(uploadFiles)) {
      let itemType = uploadFiles[key].type.split("/");
      let extName = itemType[0];
      showFiles.push({ id: "", url: extName === "image" ? URL.createObjectURL(uploadFiles[key]) : require("../../../assets/images/blank.png") });
    }
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        uploadFiles: showFiles,
        progress: 0,
      },
    });
    let progressPart = 100 / showFiles.length;
    let progress = 0;
    for (const key of Object.keys(uploadFiles)) {
      let data = new FormData();
      data.append("file", uploadFiles[key]);

      callApi(
        `/upload/multiuploader/?album=&instance=productsdrawer&image=undefined&doc=undefined`,
        "POST",
        data,
        true
      ).then((response) => {
        this.handleOnClickSelectGalleryMedia(response);
        if (showFiles.length === 1 || key === showFiles.length - 1) {
          progress = 100;
        } else {
          progress = progress + progressPart;
        }
        if (response && response.code !== 400 && response.code !== 500) {
          showFiles[key].id = response.id;
          showFiles[key].media_type = response.media_type;
          showFiles[key].thumbnail = response.thumbnail;
          if (response.media_type == "image") {
            this.props.fetch_taxonomy_list('Products', 'images');
          } else {
            this.props.fetch_taxonomy_list('Products', 'videos');
          }

          this.setState({
            ...this.state,
            uploadMedia: {
              ...this.state.uploadMedia,
              progress: progress,
              uploadedFiles: [...this.state.uploadMedia.uploadedFiles, response],
              uploadedFiles: showFiles,
            },
          });
        }
      });
    }
  };

  handleCaptionByIndex = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    uploadCaptions[index] = value;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    let id = upload_data.id;
    this.setState({ uploadCaptions }, () =>
      this.props.upload_add_captions(id, data)
    );
  };

  handleChangeTaxonomyData = (e, index) => {
    let { value } = e.target;
    let { uploadCaptions, uploadMedia } = this.state;
    let data = {};
    let upload_data = uploadMedia.uploadFiles[index];
    let caption_data = uploadCaptions[index];
    data.caption = caption_data;
    data.category = value;
    let id = upload_data.id;
    this.setState({ taxonomycategoryId: value }, () =>
      this.props.upload_add_captions(id, data)
    );
  };

  handleOnClickRemoveSelectedMedia = (id) => () => {
    let removeMediaIds = [];
    removeMediaIds.push(id);
    this.props.delete_selected_gallery_media(removeMediaIds);
    let uploadFiles = this.state.uploadMedia.uploadFiles.filter(
      (file) => file.id !== id
    );
    let uploadedFiles = this.state.uploadMedia.uploadedFiles.filter(
      (file) => file.id !== id
    );
    let selectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
      (item) => item !== id
    );
    let selectedMedia = this.state.uploadMedia.selectedMedia.filter(
      (file) => file.id !== id
    );
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: selectedMedia,
        selectedMediaIds: selectedMediaIds,
        uploadedFiles: uploadedFiles,
        uploadFiles: uploadFiles,
      },
    });
  };

  handleOnClickRemoveSelectedGalleryMedia = (media, index) => {
    // remove media from upload media state
    let { uploadMedia, editPost } = this.state;
    let id = media.id;
    let filterSelectedMediaIds = [];
    let filterSelectedMedia = [];

    if (id) {
      filterSelectedMediaIds = uploadMedia?.selectedMediaIds.filter(data => data !== id);
      filterSelectedMedia = uploadMedia?.selectedMedia.filter(data => data.id !== id);
    }
    uploadMedia['selectedMediaIds'] = filterSelectedMediaIds;
    uploadMedia['selectedMedia'] = filterSelectedMedia;

    if (editPost) {
      editPost["messagemedia_set"] = filterSelectedMediaIds;
    }

    this.setState({ uploadMedia, editPost }, () => { this.props.delete_selected_gallery_media([id]) });
  };

  handleOnClickSelectGalleryMedia = (media) => {
    if (media) {
      let index = this.state.uploadMedia.selectedMediaIds.indexOf(media.id);
      let newSelectedMediaIds;
      let newSelectedMedia;
      if (index !== -1) {
        newSelectedMediaIds = this.state.uploadMedia.selectedMediaIds.filter(
          (item) => item !== media.id
        );
        newSelectedMedia = this.state.uploadMedia.selectedMedia.filter(
          (item) => item.id !== media.id
        );
      } else {
        newSelectedMediaIds = [
          ...this.state.uploadMedia.selectedMediaIds,
          media.id,
        ];
        newSelectedMedia = [...this.state.uploadMedia.selectedMedia, media];
      }
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: newSelectedMedia,
          selectedMediaIds: newSelectedMediaIds,
        },
      });
    }
  };

  handleOnClickGalleryType = (type) => {
    let mediaType = "";
    if (type === "images") {
      mediaType = "image";
    } else if (type === "videos") {
      mediaType = "video";
    }
    this.props.get_album_type_data(mediaType, "", 1);
    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: type,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: mediaType,
        albumType: "",
        pageNo: 1,
      },
    });
  };

  handleOnClickAlbumTypeChange = (e) => {
    this.props.get_album_type_data("image", e.target.value, 1);

    this.setState({
      ...this.state,
      selectedUpMediaType: "gallery",
      showGalleryType: "images",
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: e.target.value,
        pageNo: 1,
      },
    });
  };

  handleOnClickSelectedUploadMediaType = (type) => {
    if (type === "gallery") {
      this.props.get_album_type_data("image", "", 1);
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        showGalleryType: "images",
        uploadMedia: {
          ...this.state.uploadMedia,
          mediaType: "image",
          albumType: "",
          pageNo: 1,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "upload") {
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {},
          progress: 0,
        },
      });
    } else if (type === "embed") {
      let embedLinks = {
        0: "",
        1: "",
      };
      this.setState({
        ...this.state,
        selectedUpMediaType: type,
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: embedLinks,
        },
      });
    }
  };

  embedLinkOnChange = (id) => (e) => {
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        embedLinks: {
          ...this.state.uploadMedia.embedLinks,
          [id]: e.target.value,
        },
      },
    });
  };

  firstMethod = (e) => {
    const re = /^[0-9 ]+$/
    var startPos = e.currentTarget.selectionStart;
    if (startPos == 0 && e.which == 32) {
      e.preventDefault();
    }
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  mapEmbedLinks = () => {
    let embedKeys = Object.keys(this.state.uploadMedia.embedLinks);
    return embedKeys.map((emKey) => {
      return (
        <div className="d-flex mx-n2 mb-2" key={emKey}>
          <div className="flex-fill px-2">
            <Input
              type="url"
              bsSize="sm"
              className="bg-white"
              value={this.state.uploadMedia.embedLinks[emKey]}
              onChange={this.embedLinkOnChange(emKey)}
              placeholder="Embeded link"
            />
          </div>
          <div className="px-2">
            <Button
              title="Remove"
              color="danger"
              size="sm"
              hidden={emKey <= 1 ? true : false}
              onClick={() => {
                this.deleteEmbedLinkRow(emKey);
              }}
            >
              <FontAwesomeIcon icon="minus" />
            </Button>
          </div>
        </div>
      );
    });
  };

  deleteEmbedLinkRow = (emKey) => {
    let embedLinks = this.state.uploadMedia.embedLinks;
    if (Object.keys(embedLinks).indexOf(emKey) !== -1) {
      delete embedLinks[emKey];
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: embedLinks,
        },
      });
    }
  };
  addEmbedlinkRow = () => {
    let newEmbedLinks = {};
    for (let i = 2; i < 5; i += 1) {
      if (
        Object.keys(this.state.uploadMedia.embedLinks).indexOf(i.toString()) ===
        -1
      ) {
        newEmbedLinks[i] = "";
        break;
      }
    }
    if (Object.keys(newEmbedLinks).length > 0) {
      this.setState({
        ...this.state,
        uploadMedia: {
          ...this.state.uploadMedia,
          embedLinks: {
            ...this.state.uploadMedia.embedLinks,
            ...newEmbedLinks,
          },
        },
      });
    }
  };

  handleOnClickUploadMedia = () => {
    this.props.get_album_types_list();
    this.setState({
      ...this.state,
      uploadMedia: {
        ...this.state.uploadMedia,
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        progress: 0,
        uploadFiles: [],
        uploadedFiles: [],
      },
      uploadMediaPostModal: true,
      selectedUpMediaType: "upload",
      showGalleryType: "images",
    });
  };
  truncate = (filenameString) => {
    let filename = filenameString.substr(0, filenameString.lastIndexOf("."));
    let extension = filenameString.substr(
      filenameString.lastIndexOf("."),
      filenameString.length - 1
    );
    let partial = filename.substring(filename.length - 3, filename.length);
    filename = filename.substring(0, 15);
    return filename + "..." + partial + extension;
  };

  confirmDeleteModalToggle = () => {
    if (this.state.deletePostId) {
      this.setState({ deletePostId: 0, deletePostType: "" });
    }
    this.setState({ confirmDeleteModal: !this.state.confirmDeleteModal });
  };

  limitedPostsModalToggle = () => {
    this.setState({ limitedPostsModal: !this.state.limitedPostsModal });
  };

  uploadMediaModalToggle = () => {
    this.setState({
      ...this.state,
      uploadMediaPostModal: !this.state.uploadMediaPostModal,
      uploadMedia: {
        ...this.state.uploadMedia,
        selectedMedia: [],
        selectedMediaIds: [],
        mediaType: "image",
        albumType: "",
        pageNo: 1,
        albumTypeData: {},
        embedLinks: {},
        uploadedFiles: [],
        uploadFiles: [],
      },
      selectedUpMediaType: "upload",
      showGalleryType: "images",
      taxonomycategoryId: "",
      fetchTaxonomyListingImages: [],
      fetchTaxonomyListingVideos: [],
    });
  };

  insertEmbedLinks = () => {

    let embedValues = Object.values(this.state.uploadMedia.embedLinks).filter(
      (item) => item !== ""
    );

    if (embedValues.length > 0) {
      embedValues = embedValues.map(
        (item) => "<a href=" + item + "><p>" + item + "</p></a>"
      );
      let newPost = {
        video: embedValues.join("\n"),
        exclude_list: [],
        question_rating_category: [],
        messagemedia_set: [],
      };
      this.props.add_my_post(newPost, this.state.filterType, this.state.pageNo);
      this.setState({
        ...this.state,
        /*limitedPostsModal:
         this.state.myPostsList.count < this.state.limitedPostsCount ? false : true,*/
        uploadMedia: {
          ...this.state.uploadMedia,
          selectedMedia: [],
          selectedMediaIds: [],
          embedLinks: {},
        },
        uploadMediaPostModal: false,
      });
    }
    //}
  };

  handleScrollGalleryImage = (e) => {
    let { refGalleryImageScroll } = this;
    let { fetchGalleryAlbum } = this.state;

    let clientHeight = refGalleryImageScroll.current.clientHeight;
    let scrollHeight = refGalleryImageScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "image",
        album: fetchGalleryAlbum,
        force_update: false,
      });
    }
  };

  fetchGalleryOnScroll = ({ type, album, force_update }) => {
    let { fetchGalleryScroll, fetchGalleryCount } = this.state;
    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      let totalCount = fetchGalleryCount / 10;
      let roundValue = parseInt(totalCount.toFixed());
      let countPhotos = Object.keys(fetchGalleryScroll).length;
      let nextCount = countPhotos + 1;
      if (nextCount <= roundValue) {
        let page = nextCount;
        this.props.get_album_type_data(type, "", page);
      }
    }
  };

  handleScrollGalleryVideo = (e) => {
    let { refGalleryVideoScroll } = this;
    // let count = 0;
    let clientHeight = refGalleryVideoScroll.current.clientHeight;
    let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
    const scrollTop =
      this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
    let scrollTopCalulated = scrollHeight - clientHeight;
    let scrollMinimun_8 = scrollTopCalulated - 8;
    let scrollMinimun_6 = scrollTopCalulated - 6;
    let scrollMinimun_5 = scrollTopCalulated - 5;
    let scrollMinimun_3 = scrollTopCalulated - 3;
    let scrollMinimun_1 = scrollTopCalulated - 1;

    if (
      scrollTopCalulated === scrollTop ||
      scrollTop === scrollMinimun_1 ||
      scrollTop === scrollMinimun_3 ||
      scrollTop === scrollMinimun_5 ||
      scrollTop === scrollMinimun_6 ||
      scrollTop === scrollMinimun_8
    ) {
      this.fetchGalleryOnScroll({
        type: "video",
        album: "",
        force_update: false,
      });
    }
  };

  handleOpenModal(data, url) {
    this.setState({
      viewMyPhotosModal: !this.state.viewMyPhotosModa,
      imageAttacment: data,
    });
  }

  fetchScrollPhotoMedia = (isVideo) => {
    let { fetchGalleryScroll } = this.state;
    let getDataItems = [];
    const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`;

    if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
      Object.keys(fetchGalleryScroll).forEach((item) => {
        if (
          fetchGalleryScroll[item] &&
          fetchGalleryScroll[item].results !== null
        ) {
          let data = fetchGalleryScroll[item].results;
          getDataItems = getDataItems.concat(data);
        }
      });

      if (getDataItems && getDataItems.length > 0) {
        return getDataItems.map((item) => {
          let itemName = (item?.thumbnail).split(".");
          let itemFile = (item?.file).split(".");
          let extName = itemName[itemName.length - 1];
          let extFile = itemFile[itemFile.length - 1];
          let srcFile = extFile !== extName ? item?.thumbnail : imagePreview;

          return (
            <Col xs="auto" className="mb-3">
              <FormGroup
                className="checkable-media"
                check
                style={{ width: "120px" }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    onClick={() => this.handleOnClickSelectGalleryMedia(item)}
                  />
                  <div className="gallery-media">
                    <img
                      className="img-fluid"
                      src={isVideo ? srcFile : item?.file}
                      alt="logo Pics"
                    />
                  </div>
                </Label>
                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">
                  {item.caption ? item.caption : "No Caption"}
                </div>
                <div className="font-weight-normal fs-14 d-block">
                  {item.category ? item.category : "No Category"}
                </div>
              </FormGroup>
            </Col>
          );
        });
      }
    } else {
      return getDataItems;
    }
  };

  render() {
    const {
      openModal,
      toggleProductModal,
      products,
      videos_children,
    } = this.props;
    const { addProductForm, category_id, subcategory_id, anothersubcategory_id, name, manufacturer, maxPrice, minPrice, modelNumber, websites_set,
      phones_set, features_set, manufactureCutSheet, manufactureWarranty, mediaSet, latestUploadedData, selectedFilesPreview,
      showVideoMedia, fetchTaxonomy, embeddedLink, assembledWeight, assembledHeight, assembledDepth, assembledWidth,
      packedDepth, packedHeight, packedWeight, packedWidth, productSubcategory, productAnothSabcategory, productOtherSabcategory, othersubcategory_id, fetchTaxomomyCategory,
      fetchTaxonomyListingImages, fetchTaxonomyListingVideos, myPostsList } = this.state;

    let totalLength = selectedFilesPreview.length;
    let objectKeys = [];
    let objectLength = 0;
    let calculatedPercentage = 0;
    if (Object.keys(latestUploadedData).length > 0) {
      objectKeys = Object.keys(latestUploadedData);
      objectLength = objectKeys.length;
      calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
    }

    return (
      <>
        <Formik
          touched
          errors
          initialValues={addProductForm}
          validationSchema={validate}
          onSubmit={(values) => {
            this.props.onSubmit({
              ...values,
              taxonomy: [values.taxonomy],
            });
          }}
          render={(props) => {
            const {
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            } = props;
            return (
              /* Add Product drawer modal popup */
              <Modal
                size="xl" scrollable className="drawer-modal"
                isOpen={openModal}
                toggle={toggleProductModal}
              >
                <div className="modal-header p-0">
                  <div className="w-100">
                    <div>
                      <Button color="primary" onClick={toggleProductModal}>
                        <FontAwesomeIcon icon="angle-left" /> back to listing</Button>
                    </div>
                    <div>
                      <div className="bg-darker p-3 text-tertiary">
                        <div className="mb-2 text-primary font-weight-bold">
                          You’re Adding a New Product Listing
                  </div>
                        <ul className="list-unstyled d-flex flex-wrap mx-n2">
                          <li className="px-2">
                            <div className="d-flex">
                              <FormGroup>
                                <Input type="select" className="transparent" size="sm">
                                  <option>Products</option>
                                </Input>
                              </FormGroup>
                            </div>
                          </li>
                          <li className="px-2">
                            <div className="d-flex">
                              <div className="mr-1">
                                <span className="text-danger">*</span>
                              </div>
                              <FormGroup>
                                <Input
                                  id="category_id"
                                  name="category_id"
                                  onChange={this.handleChange}
                                  type="select"
                                  className="transparent"
                                  bsSize="sm"
                                  selected={category_id}
                                >
                                  <option value="">Select Category</option>
                                  {products && products.length > 0 ? products.map((product) => {
                                    return (
                                      <option value={product.id} key={product.id}>
                                        {product.category}
                                      </option>

                                    );
                                  }) : ("")}
                                </Input>
                                <span style={{ color: 'red' }}>{this.state.categoryError}</span>
                              </FormGroup>
                            </div>
                          </li>
                          <li className="px-2" hidden={productSubcategory && productSubcategory.length > 0 ? false : true}>
                            <FormGroup>
                              <Input
                                id="subcategory_id"
                                name="subcategory_id"
                                onChange={this.handleChange}
                                type="select"
                                className="transparent"
                                bsSize="sm"
                                selected={subcategory_id}
                              >
                                <option value="">Select SubCategory</option>
                                {productSubcategory && productSubcategory.length > 0 ? productSubcategory.map((productsub) => {
                                  return (

                                    <option value={productsub.id} key={productsub.id}>
                                      {productsub.category}
                                    </option>
                                  );
                                }) : ("")}
                              </Input>
                            </FormGroup>
                          </li>

                          <li className="px-2" hidden={productAnothSabcategory && productAnothSabcategory.length > 0 ? false : true}>
                            <FormGroup>
                              <Input
                                id="anothersubcategory_id"
                                name="anothersubcategory_id"
                                onChange={this.handleChange}
                                type="select"
                                className="transparent"
                                size="sm"
                                selected={anothersubcategory_id}
                              >
                                <option value="">Select SubCategory</option>
                                {productAnothSabcategory && productAnothSabcategory.length > 0 ? productAnothSabcategory.map((productanothsub) => {
                                  return (
                                    <option value={productanothsub.id} key={productanothsub.id}>
                                      {productanothsub.category}
                                    </option>

                                  );
                                }) : ("")}
                              </Input>
                            </FormGroup>
                          </li>

                          <li className="px-2" hidden={productOtherSabcategory && productOtherSabcategory.length > 0 ? false : true}>
                            <FormGroup>
                              <Input
                                id="othersubcategory_id"
                                name="othersubcategory_id"
                                onChange={this.handleChange}
                                type="select"
                                className="transparent"
                                size="sm"
                                selected={othersubcategory_id}
                              >
                                <option value="">Select SubCategory</option>
                                {productOtherSabcategory && productOtherSabcategory.length > 0 ? productOtherSabcategory.map((productothsub) => {
                                  return (
                                    <option value={productothsub.id} key={productothsub.id}>
                                      {productothsub.category}
                                    </option>
                                  );
                                }) : ("")}
                              </Input>
                            </FormGroup>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <ModalBody className="bg-white text-dark">
                  {/* Step 1 */}
                  <div>
                    <div className="text-primary font-weight-bold mb-2">Step 1. Enter Core Information</div>
                    <Row>
                      <Col md={6} lg={4}>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0 ff-alt" size="sm">Product Name <span className="text-danger">*</span></Label>
                          <Input
                            className="primary" bsSize="sm" type="text"
                            value={name}
                            id="name"
                            name="name"
                            placeholder="Type in the name of the listing"
                            onChange={this.handleChange}
                          />
                          <span style={{ color: 'red' }}>{this.state.nameError}</span>
                        </FormGroup>
                      </Col>
                      <Col md={6} lg={4}>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0 ff-alt" size="sm">Manufacturer <span className="text-danger">*</span></Label>
                          <Input className="primary"
                            bsSize="sm" type="text"
                            id="manufacturer"
                            name="manufacturer"
                            value={manufacturer}
                            placeholder="Type in the manufacturer name"
                            onChange={this.handleChange}
                          />
                          <span style={{ color: 'red' }}>{this.state.manufactureError}</span>
                        </FormGroup>
                      </Col>
                      <Col md={6} lg={4}>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0 ff-alt" size="sm">Model Number </Label>
                          <Input className="primary"
                            bsSize="sm"
                            type="text"
                            id="modelNumber"
                            name="modelNumber"
                            value={modelNumber}
                            placeholder="Type in the model number"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr />

                  {/* Step 2 */}
                  <div>
                    <div className="text-primary font-weight-bold mb-2">Step 2. Add features and additional information <span className="text-tertiary font-weight-normal">(optional)</span></div>
                    <Row>
                      <Col md={6} lg={4}>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0 ff-alt" size="sm">Price Range ($)</Label>
                          <div className="d-flex align-items-center">
                            <Input className="primary"
                              bsSize="sm"
                              type="number"
                              placeholder="Enter Min."
                              id="minPrice"
                              name="minPrice"
                              min='0'
                              value={minPrice}
                              onChange={this.handleChange}
                              onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                            />
                            <span className="mx-2">to</span>
                            <Input className="primary"
                              bsSize="sm"
                              type="number"
                              placeholder="Enter Max."
                              id="maxPrice"
                              name="maxPrice"
                              min='0'
                              value={maxPrice}
                              onChange={this.handleChange}
                              onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                            />
                          </div>
                          <span style={{ color: 'red' }}>{this.state.priceError}</span>
                        </FormGroup>
                        <div>
                          {/* Repeat this */}
                          <div>
                            <FieldArray
                              name="websites_set"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.websites_set.map(
                                    (website, index) => (
                                      <div key={index}>
                                        <FormGroup>
                                          <Label
                                            className="text-dark font-weight-bold mb-0 ff-alt"
                                            size="sm"
                                          >
                                            Website
                                          </Label>
                                          <Input
                                            className="primary"
                                            bsSize="sm"
                                            type="text"
                                            // name={`producers_set[${index}.first_name]`}
                                            name="websites_name"
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                          />
                                          <div className="my-2"></div>
                                          <Label
                                            className="text-dark font-weight-bold mb-0 ff-alt"
                                            size="sm"
                                          >
                                            Website type
                                          </Label>
                                          <Input
                                            className="primary select-type-dark"
                                            bsSize="sm"
                                            type="select"
                                            // name={`producers_set[${index}.profession_id]`}
                                            name="websites_type"
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                          >
                                            <option value="">Choose website type</option>
                                            <option value="Main">Main Website</option>
                                            <option value="Facebook">Facebook Website</option>
                                            <option value="Google+">Google Website</option>
                                            <option value="Twitter">Twitter Website</option>
                                            <option value="LinkedIn">LinkedIn Website</option>
                                            <option value="Instagram">Instagram Website</option>
                                          </Input>
                                        </FormGroup>
                                        {/* Hide for first */}
                                        <Button hidden={index != 0 ? false : true} color="link" className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            this.handleremoveCast('websites_set')
                                          }
                                          }>
                                          Remove
                                           </Button>
                                      </div>
                                    )
                                  )}
                                  <div className="text-right">
                                    <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          website: "",
                                          website_type: "",
                                        })
                                      }>
                                      <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div>
                          {/* Repeat this */}
                          <div>
                            <FieldArray
                              name="phones_set"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.phones_set.map(
                                    (phone, index) => (
                                      <div key={index}>
                                        <FormGroup>
                                          <Label
                                            className="text-dark font-weight-bold mb-0 ff-alt"
                                            size="sm"
                                          >
                                            Phone Number
                                          </Label>
                                          <Input
                                            className="primary"
                                            bsSize="sm"
                                            type="text"
                                            // name={`producers_set[${index}.first_name]`}
                                            name="phones_name"
                                            maxLength="12"
                                            onKeyPress={(e) => this.firstMethod(e)}
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                          />
                                          <span style={{ color: 'red' }}>{this.state.phoneEerror[index]}</span>
                                          <div className="my-2"></div>
                                          <Input
                                            className="primary select-type-dark"
                                            bsSize="sm"
                                            type="select"
                                            // name={`producers_set[${index}.profession_id]`}
                                            name="phones_label"
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                          >
                                            <option value="">Choose phone type</option>
                                            <option value="Tel">Telephone</option>
                                            <option value="Mob">Mobile</option>
                                            <option value="Support">Support</option>
                                          </Input>
                                        </FormGroup>
                                        {/* Hide for first */}
                                        <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            this.handleremoveCast('phones_set')
                                          }
                                          }>
                                          Remove
                                           </Button>
                                      </div>
                                    )
                                  )}
                                  <div className="text-right">
                                    <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          phone: "",
                                          label: "",
                                        })
                                      }>
                                      <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6} lg={4}>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0 ff-alt" size="sm">Manufacturer's Cut Sheet</Label>
                          <Input className="primary"
                            bsSize="sm" type="text"
                            placeholder="Type in the Manufacturers cut sheet"
                            id="manufactureCutSheet"
                            name="manufactureCutSheet"
                            value={manufactureCutSheet}
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label className="text-dark font-weight-bold mb-0 ff-alt" size="sm">Manufacturer's Warranty</Label>
                          <Input className="primary"
                            bsSize="sm"
                            type="text"
                            placeholder="Warranty"
                            id="manufactureWarranty"
                            name="manufactureWarranty"
                            value={manufactureWarranty}
                            onChange={this.handleChange}
                          />
                        </FormGroup>

                        <div>
                          {/* Repeat this */}
                          <div>
                            <FieldArray
                              name="features_set"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.features_set.map(
                                    (feature, index) => (
                                      <div key={index}>
                                        <FormGroup>
                                          <Label
                                            className="text-dark font-weight-bold mb-0 ff-alt"
                                            size="sm"
                                          >
                                            Features
                                          </Label>
                                          <Input
                                            className="primary"
                                            bsSize="sm"
                                            type="text"
                                            // name={`producers_set[${index}.first_name]`}
                                            name="features_name"
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                          />
                                          <div className="my-2"></div>
                                          <Input
                                            className="primary select-type-dark"
                                            bsSize="sm"
                                            type="select"
                                            // name={`producers_set[${index}.profession_id]`}
                                            name="features_label"
                                            onChange={(e) => this.handleChangeCast(e, index)}
                                          >
                                            <option value="">Choose features type</option>
                                            <option>Audio Feature</option>
                                            <option>Video Feature</option>
                                            <option>Additional Feature</option>
                                          </Input>
                                        </FormGroup>
                                        {/* Hide for first */}
                                        <Button color="link" hidden={index != 0 ? false : true} className="text-royal-blue p-0 font-weight-normal ff-alt fs-13"
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                            this.handleremoveCast('features_set')
                                          }
                                          }>
                                          Remove
                                           </Button>
                                      </div>
                                    )
                                  )}
                                  <div className="text-right">
                                    <Button color="link" className="text-primary p-0 font-weight-normal ff-alt fs-13"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          features: "",
                                          label: "",
                                        })
                                      }>
                                      <FontAwesomeIcon icon="plus" /> Add More
                                    </Button>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6} lg={4}>
                        <div>
                          <p className="mb-1 font-weight-bold fs-14">Product Dimensions (mm)</p>
                          <FormGroup>
                            <Label className="text-dark font-weight-normal mb-0 ff-alt" size="sm">Packed </Label>
                            <div className="d-flex align-items-start mx-n1">
                              <Input className="primary mx-1"
                                bsSize="sm"
                                type="number"
                                placeholder="width"
                                id="packedWidth"
                                name="packedWidth"
                                min='0'
                                value={packedWidth}
                                onChange={this.handleChange}
                                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                              />
                              <Input className="primary mx-1"
                                bsSize="sm"
                                type="number"
                                placeholder="height"
                                id="packedHeight"
                                name="packedHeight"
                                min='0'
                                value={packedHeight}
                                onChange={this.handleChange}
                                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                              />
                              <Input className="primary mx-1"
                                bsSize="sm"
                                type="number"
                                placeholder="depth"
                                id="packedDepth"
                                name="packedDepth"
                                min='0'
                                value={packedDepth}
                                onChange={this.handleChange}
                                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                              />
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Label className="text-dark font-weight-normal mb-0 ff-alt" size="sm">Assembled </Label>
                            <div className="d-flex align-items-start mx-n1">
                              <Input className="primary mx-1"
                                bsSize="sm"
                                type="number"
                                placeholder="width"
                                id="assembledWidth"
                                name="assembledWidth"
                                min='0'
                                value={assembledWidth}
                                onChange={this.handleChange}
                                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                              />
                              <Input className="primary mx-1"
                                bsSize="sm"
                                type="number"
                                placeholder="height"
                                id="assembledHeight"
                                name="assembledHeight"
                                min='0'
                                value={assembledHeight}
                                onChange={this.handleChange}
                                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                              />
                              <Input className="primary mx-1"
                                bsSize="sm"
                                type="number"
                                placeholder="depth"
                                id="assembledDepth"
                                name="assembledDepth"
                                min='0'
                                value={assembledDepth}
                                onChange={this.handleChange}
                                onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                              />
                            </div>
                          </FormGroup>
                        </div>
                        <div>
                          <p className="mb-1 font-weight-bold fs-14">Product Weight (kg)</p>
                          <div className="d-flex mx-n1">
                            <FormGroup className="px-1">
                              <Label className="text-dark font-weight-normal mb-0 ff-alt" size="sm">Packed </Label>
                              <div className="d-flex align-items-start mx-n1">
                                <Input className="primary mx-1"
                                  bsSize="sm"
                                  type="number"
                                  placeholder="weight"
                                  id="packedWeight"
                                  name="packedWeight"
                                  min='0'
                                  value={packedWeight}
                                  onChange={this.handleChange}
                                  onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                                />
                              </div>
                            </FormGroup>
                            <FormGroup className="px-1">
                              <Label className="text-dark font-weight-normal mb-0 ff-alt" size="sm">Assembled </Label>
                              <div className="d-flex align-items-start mx-n1">
                                <Input className="primary mx-1"
                                  bsSize="sm"
                                  type="number"
                                  placeholder="weight"
                                  id="assembledWeight"
                                  name="assembledWeight"
                                  min='0'
                                  value={assembledWeight}
                                  onChange={this.handleChange}
                                  onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <hr />
                  {/* Step 3 */}
                  <div>
                    <div className="text-primary font-weight-bold mb-2">
                      Step 3. Add images and/ or videos
                      <span className="ml-1" style={{ color: 'red', fontSize: "16px" }}>*</span>
                    </div>
                    <Row>
                      <Col lg={5}>
                        <p className="text-tertiary fs-14">
                          Upload images or video to accompany your listing. Additional images/videos can be added later.
                                   </p>
                      </Col>
                      <Col lg={7}>
                        {/* Uploaded media shown here */}
                        {
                          this.state.uploadMedia.selectedMedia.length ?
                            <div className="border mb-3">
                              <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                {this.state.uploadMedia.selectedMedia.map((item, indexMedia) => (
                                  <Col>
                                    <div className="d-flex pr-3 m-2">
                                      <div>
                                      
                                        {(item.type === "image" ||
                                          item.media_type === "image" ||
                                          item.type === "video" ||
                                          item.media_type === "video" || item.type === "audio" ||
                                          item.media_type === "audio") && (
                                            <div className="gallery-media">
                                              { item.media_type === 'image' ?
                                                <img
                                                  className="img-fluid img-thumbnail"
                                                  src={item.url ? item.url : item.file}
                                                  alt={
                                                    item.filename
                                                      ? item.filename.length < 20
                                                        ? item.filename
                                                        : this.truncate(item.filename)
                                                      : item.name
                                                        ? item.name.length < 20
                                                          ? item.name
                                                          : this.truncate(item.name)
                                                        : ""
                                                  } /> :
                                                <img
                                                  className="img-fluid img-thumbnail"
                                                  src={item.thumbnail && item.thumbnail !== null ? item.thumbnail : item.url}
                                                  alt={
                                                    item.filename
                                                      ? item.filename.length < 20
                                                        ? item.filename
                                                        : this.truncate(item.filename)
                                                      : item.name
                                                        ? item.name.length < 20
                                                          ? item.name
                                                          : this.truncate(item.name)
                                                        : ""
                                                  }
                                                />}
                                            </div>
                                          )}
                                      </div>
                                      <div className="mx-n3 mt-2">
                                        <Button
                                          color="delete-media"
                                          size="sm"
                                          title="Remove Media"
                                          onClick={() =>
                                            this.handleOnClickRemoveSelectedGalleryMedia(
                                              item, indexMedia
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon icon="trash-alt" />{" "}
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                            :
                            ''
                        }
                        <Button
                          color="royal-blue"
                          className="font-weight-normal"
                          bsSize="sm"
                          onClick={() =>
                            this.handleOnClickUploadMedia()
                          }
                        >
                          Upload Media
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <hr />

                  {/* Step 4 */}
                  <div>
                    <div className="text-primary font-weight-bold mb-2">Step 4. Write a review</div>
                    <div className="pl-4">
                      <FormGroup check>
                        <Label className="fs-13 text-darker font-weight-bold" check>
                          <Input type="checkbox" name="is_draft" onChange={this.handleChange} />{' '}
                                       Thanks for adding a new listing! As initial uploader,you get the honor of First Review! Write a Review for this product now and immortalize your name in the chronicles of wiki glory!
                                   </Label>
                      </FormGroup>
                    </div>

                    <div className="text-right mt-3">
                      <Button color="primary" size="sm" onClick={async () => { await this.handleCancel(); }} >Cancel</Button>
                      <Button color="primary" size="sm" onClick={this.handleProductSubmit}>Submit</Button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            );
          }}
        />
        {/* Upload Media Post Modal */}
        <Modal
          isOpen={this.state.uploadMediaPostModal}
          toggle={this.uploadMediaModalToggle}
        >
          <ModalHeader toggle={this.uploadMediaModalToggle}>
            UPLOAD MEDIA
          </ModalHeader>
          <ModalBody className="p-3">
            <Row>
              <Col xs={"auto"}>
                <div className="bg-light h-100 p-3">
                  <Nav
                    tabs
                    className="flex-column active-transparent border-0 m-0"
                  >
                    <NavItem className="text-left">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "upload"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("upload");
                        }}
                      >
                        Upload
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "gallery"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("gallery");
                        }}
                      >
                        Gallery
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <hr className="my-3 bg-tertiary" />
                    </NavItem>
                    <NavItem className="text-left border-0">
                      <NavLink
                        className="pl-0 pr-4 border-0"
                        href="#"
                        active={this.state.selectedUpMediaType === "embed"}
                        onClick={() => {
                          this.handleOnClickSelectedUploadMediaType("embed");
                        }}
                      >
                        Embed
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col>
                <TabContent activeTab={this.state.selectedUpMediaType}>
                  <TabPane tabId="upload">
                    <div
                      className="mb-3 type-file-block bg-light"
                      hidden={
                        this.state.uploadMedia.uploadFiles &&
                          this.state.uploadMedia.uploadFiles.length > 0
                          ? true
                          : false
                      }
                    >
                      <Input
                        type="file"
                        name="upload_media_file"
                        id="uploadFilesPostFromSystem"
                        accept="image/*, video/*, audio/*"
                        onChange={this.handleOnFilePostUploadChange}
                        multiple
                      />
                      <Label for="uploadFilesPostFromSystem">
                        <div className="type-bg"></div>
                      </Label>
                    </div>
                    {this.state.uploadMedia.uploadFiles.length > 0 && (
                      <Row className="mb-3" form>
                        <Col xs={12}>
                          <div
                            style={{
                              maxWidth: "120px",
                              margin: "0 1rem 1rem auto",
                            }}
                          >
                            <div className="text-center mb-1 small">
                              {this.state.uploadMedia.progress === 100 ? (
                                <div className="text-success">
                                  <FontAwesomeIcon
                                    icon="check-circle"
                                    className="mr-1"
                                  />{" "}
                                  Uploaded
                                </div>
                              ) : (
                                  <div>
                                    Uploading{" "}
                                    <span className="text-success font-weight-bold ff-base">
                                      {this.state.uploadMedia.progress.toFixed(0)}
                                    %
                                  </span>
                                  </div>
                                )}
                            </div>
                            <Progress
                              value={this.state.uploadMedia.progress}
                              style={{ height: "8px" }}
                            ></Progress>
                          </div>
                        </Col>
                        {this.state.uploadMedia.uploadFiles.map(
                          (file, index) => {
                            return (
                              <Col xs="auto">
                                <div className="bg-light p-2">
                                  <div className="d-flex pr-3">
                                    <div>
                                      <div
                                        className="selectable-media 123"
                                        style={{ cursor: "default" }}
                                      >
                                        <div className="gallery-media">
                                          {file.media_type === 'image' ?
                                            <img src={file.url} alt="" /> :
                                            <img src={file.thumbnail && file.thumbnail !== null ? file.thumbnail : file.url} alt="" />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mx-n3 mt-2">
                                      <Button
                                        color="delete-media _alt"
                                        size="sm"
                                        title="Remove Media"
                                        hidden={file.id === "" ? true : false}
                                        onClick={this.handleOnClickRemoveSelectedMedia(
                                          file.id
                                        )}
                                      >
                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <Input
                                      bsSize="sm"
                                      type="text"
                                      className="mw-100 caption-input"
                                      name="caption"
                                      placeholder="Caption"
                                      onBlur={(e) =>
                                        this.handleCaptionByIndex(e, index)
                                      }
                                      style={{ maxWidth: '160px' }}
                                    />
                                    <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_type_select" id="selectAlbumTypeData" className="mw-100 mt-1">
                                      <option value="">Choose Category</option>
                                      {file.media_type === "image" && fetchTaxonomyListingImages && Array.isArray(fetchTaxonomyListingImages) && fetchTaxonomyListingImages.length > 0 ?
                                        fetchTaxonomyListingImages.map(item => (
                                          <option value={item.id}>{item.label}</option>
                                        ))
                                        : null}
                                      {file.media_type === "video" && fetchTaxonomyListingVideos && Array.isArray(fetchTaxonomyListingVideos) && fetchTaxonomyListingVideos.length > 0 ?
                                        fetchTaxonomyListingVideos.map(item => (
                                          <option value={item.id}>{item.label}</option>
                                        ))
                                        : null}
                                    </Input>
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        )}
                        <Col xs="auto">
                          <div className="d-flex pt-3">
                            <div className="selectable-media" hidden>
                              <Label
                                for="uploadFilesFromSystemMini"
                                className="gallery-media"
                                style={{
                                  borderStyle: "dashed",
                                  cursor: "pointer",
                                }}
                              >
                                <div className="d-flex h-100 align-items-center justify-content-center">
                                  <span className="fs-14">Upload More</span>
                                </div>
                              </Label>
                              <Input
                                type="file"
                                name="upload_media_file"
                                id="uploadFilesFromSystemMini"
                                accept="image/*, video/*"
                                onChange={this.handleOnFilePostUploadChange}
                                multiple
                                style={{ display: "none" }}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              uploadMediaPostModal: false,
                            });
                          }}
                        >
                          {"OK"}
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tabId="gallery">
                    <div className="mb-2">
                      <Nav tabs className="d-inline-flex mb-0">
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "images"}
                            onClick={() => {
                              this.handleOnClickGalleryType("images");
                            }}
                          >
                            Image Gallery
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            active={this.state.showGalleryType === "videos"}
                            onClick={() => {
                              this.handleOnClickGalleryType("videos");
                            }}
                          >
                            Video Gallery
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <TabContent activeTab={this.state.showGalleryType}>
                      <TabPane tabId="images">
                        <div>
                          <FormGroup className="form-row justify-content-end">
                            <Col xs="auto">
                              <Label className="small" for="selectAlbumType">
                                Album Type:
                              </Label>
                            </Col>
                            <Col xs="auto">
                              <Input
                                bsSize="sm"
                                type="select"
                                name="album_type_select"
                                id="selectAlbumType"
                                defaultValue={this.state.uploadMedia.albumType}
                                onChange={this.handleOnClickAlbumTypeChange}
                              >
                                <option value="">All</option>
                                {this.state.uploadMedia.albumTypesList.length >
                                  0 &&
                                  this.state.uploadMedia.albumTypesList.map(
                                    (type) => {
                                      if (type.label) {
                                        return (
                                          <option value={type.category}>
                                            {type.category}
                                          </option>

                                        )
                                      }
                                    }
                                  )}
                              </Input>
                            </Col>
                          </FormGroup>
                        </div>
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryImageScroll}
                          onScroll={this.handleScrollGalleryImage}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>{this.fetchScrollPhotoMedia()}</Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No images</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="videos">
                        <div
                          className="px-3 my-3"
                          style={{ maxHeight: "350px", overflow: "auto" }}
                          ref={this.refGalleryVideoScroll}
                          onScroll={this.handleScrollGalleryVideo}
                        >
                          {this.state.uploadMedia.albumTypeData &&
                            this.state.uploadMedia.albumTypeData.count > 0 ? (
                              <Row>{this.fetchScrollPhotoMedia(true)}</Row>
                            ) : (
                              <div className="bg-white p-3">
                                <h2 className="text-secondary-dark">No videos</h2>
                              </div>
                            )}
                        </div>
                        <div className="d-flex mx-n2">
                          <div className="px-2">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={this.uploadMediaModalToggle}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="px-2 ml-auto">
                            <Button
                              color="primary"
                              size="sm"
                              className="mw"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  uploadMediaPostModal: false,
                                });
                              }}
                            >
                              {"OK"}
                            </Button>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </TabPane>
                  <TabPane tabId="embed">
                    <div className="text-muted mb-2">
                      Submit the link for the image or video you want to embed{" "}
                    </div>
                    <div className="mb-2">
                      {/* Repeat this while adding */}
                      {this.mapEmbedLinks()}
                    </div>
                    <div className="d-flex mx-n2 mb-2">
                      <div className="px-2 ml-auto">
                        <Button
                          title="Add"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            this.addEmbedlinkRow();
                          }}
                        >
                          <FontAwesomeIcon icon="plus" />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex mx-n2">
                      <div className="px-2">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={this.uploadMediaModalToggle}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="px-2 ml-auto">
                        <Button
                          color="primary"
                          size="sm"
                          className="mw"
                          onClick={() => {
                            this.insertEmbedLinks();
                          }}
                        >
                          Insert
                        </Button>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

const mapState = (state) => ({
  // collage_items: state.products_listing.products_collage_items,
  videos_children:
    state.business.videos_children && state.business.videos_children[0],
  upload_scroll: state.myProfile.upload_scroll,
  upload_media_data: state.myProfile.upload_media_data,
  productSabcategories: state.footer.productSabcategories,
  productAnothSabcategories: state.footer.productAnothSabcategories,
  productOtherSabcategories: state.footer.productOtherSabcategories,
  fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
  album_types_list: state.user.album_types_list,
  album_type_data: state.user.album_type_data,
  fetch_save_response: state.products.fetch_save_response,
});

const mapProps = (dispatch) => ({
  // media_upload_scroll: ({ type, album, page, force_update }) =>
  //   dispatch(media_upload_scroll({ type, album, page, force_update })),
  // fetch_media_data: (type, album, page, user_profile) =>
  //   dispatch(fetch_media_data(type, album, page, user_profile)),
  clear_media_dispatch: () => dispatch(clear_media_dispatch()),
  clear_product: () => dispatch(clear_product()),
  initFetchProductSubCategories: (categoryId, type) => dispatch(initFetchProductSubCategories(categoryId, type)),
  clear_cat_sub_product: () => dispatch(clear_cat_sub_product()),
  add_product: (data) => dispatch(add_product(data)),
  upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
  fetch_taxonomy_list: (category, type) =>
    dispatch(fetch_taxonomy_list(category, type)),
  get_album_types_list: () => dispatch(get_album_types_list()),
  get_album_type_data: (type, album, page) =>
    dispatch(get_album_type_data(type, album, page)),
  delete_selected_gallery_media: (dataID) =>
    dispatch(delete_selected_gallery_media(dataID)),
  add_my_post: (data, type, page) => dispatch(add_my_post(data, type, page)),
});

export default withRouter(connect(mapState, mapProps)(AddProductModal));
