
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { connect } from 'react-redux';
import RichTextEditor from 'react-rte';
import { Button, Modal, ModalBody, Row } from 'reactstrap';
import {
	delete_buyers_article, get_buyers_guides
} from '../../../actions/buyersGuide';
import { padZeroes } from '../../../utils/utilities';
import AddArticle from "../../atoms/buyersGuide/addArticle";
import EditAllArticles from "../../atoms/buyersGuide/editAllArticles";
import ConfirmModal from '../../atoms/confirmModal';

const responsive = {
	videoModal: {
		mobile: {
			breakpoint: { max: 9999999, min: 0 },
			items: 1
		}
	},
	viewProductsInfoImages: {
		desktop: {
			breakpoint: { max: 9999999, min: 1200 },
			items: 3
		},
		tablet: {
			breakpoint: { max: 1199, min: 768 },
			items: 3
		},
		mobile: {
			breakpoint: { max: 767, min: 0 },
			items: 1
		}
	}
}

class ArticleTab extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editArticle : {},
			createNewDisModalToggle: false,
			qaDropDown: null,
			qaData: null,
			dropdownSortBy: false,
			articlesData: [],
			confirmModalKey: '',
			confirmModalText: '',
			confirmModalItemId: null,
			isEnableConfirmModal: false,
			openArticle: false,
			openEditArticle: false,
			openEditAllArticlesPage: false,
			uploadedFiles: [],
			embedBody: [],
			textData: RichTextEditor.createEmptyValue(),
			select_main_category: "",
			main_category_name: "",
			uploadUrl: '/upload/multiuploader/?album=&instance=NjUHgskupvRykJG&image=undefined&doc=undefined',
			discussModal: false,
			discussionArticleTitle:"",
			keyCompare: "",
			taxonomy_id : "",
			activeDiscussionTab: "",
			carouselMedia: [],
			viewMediaModalToggle: false
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.get_buyersGuides && Array.isArray(nextProps.get_buyersGuides) && nextProps.get_buyersGuides.length > 0) {
			this.setState({ articlesData: nextProps.get_buyersGuides, taxonomy_id: nextProps.taxonomy_id });
		}
		this.setState({
			openEditAllArticlesPage : nextProps?.openEditAllArticlesPage
		})
	}
	handleOnConfirmModal = () => {
		let { confirmModalKey, confirmModalItemId } = this.state;
		if (confirmModalKey === "delete-buyers-article" && confirmModalItemId) {
			this.props.delete_buyers_article(confirmModalItemId, this.props.taxonomy_id);
		}
	}

	deleteEntry = (id) => {
		let text = 'Are you sure want to delete?';
		this.toggleConfirmModal({ text, uniqueKey: 'delete-buyers-article', id });
	}

	toggleConfirmModal = ({ text, isTextDelete, uniqueKey, id, message }) => {
		let text_str = '';
		let messagetstr = '';
		if (isTextDelete && !text) {
			text_str = 'Do you want to delete this ?';
		}

		if (text && !isTextDelete) {
			text_str = text;
			messagetstr = message;
		}

		if (text && isTextDelete) {
			text_str = text;
			messagetstr = message;
		}

		this.setState({
			isEnableConfirmModal: !this.state.isEnableConfirmModal,
			confirmModalText: text_str,
			confirmMessateText: messagetstr,
			confirmModalKey: uniqueKey || '',
			confirmModalItemId: id || null
		});
	}

	DiscussModal = (articleData) => {
		this.setState({
			discussModal: true,
			discussionArticleTitle: articleData.article_title
		})
	};

	closeModal = () => {
		this.setState({ discussModal: false });
	};

	editArticle = (expand,id) => {
		let {editArticle} = this.state;
		/*Close previous edit article, if any opened*/
		this.state.articlesData.map((itemArctile, index) => (
			this.state.editArticle[itemArctile.id] = false
		));
		editArticle[id] = expand;
		this.setState({
			openEditArticle: true,
			openArticle: false,
			editArticle
		})
	}
	renderArticleImages = (item, index = 0, articleId, first) => {
		if (first) {
			return (
				<img
					src={item?.location}
					className="img-fluid"
					onClick={() => this.openCarousal(index, articleId)}
					onError={(error) =>
						(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
					}
					alt={item.caption ? item.caption : 'no-image'}
				/>
			)
		}
		else {
			return (
				<div className="d-flex pr-3 m-2">
					<img
						src={item?.location}
						className="img-fluid img-thumbnail"
						onClick={() => this.openCarousal(index, articleId)}
						onError={(error) =>
							(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
						}
						alt={item.caption ? item.caption : 'no-image'}
					/>
				</div>
			);
		}
	}
	openCarousal = (index, articleId) => {
		console.log({articleId});
		const carouselMedia = this.state.articlesData.find(i => i.id == articleId)?.article_media;
		console.log({carouselMedia})
		if (carouselMedia) {
			const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
			const array2 = (index !== carouselMedia.length - 1) ? carouselMedia.filter((item, i) => i > index) : [];
			const newArray = [carouselMedia[index], ...array2, ...array1];
			this.setState({ carouselMedia: newArray, viewMediaModalToggle: true });
		}

	}

	render() {
		let { toggleEnableFileUpload, uploadedMediaData, getuploadUrl,callBuyersTrendingApi, closeEditPage } = this.props;
		const { articlesData, openArticle, uploadedFiles, uploadUrl, isEnableConfirmModal,
			openEditArticle, confirmModalText, confirmModalKey, openEditAllArticlesPage, carouselMedia } = this.state;

		return (
			<>
				{/*Add article button*/}
				{articlesData && Array.isArray(articlesData) && articlesData?.length < 7 ?
					<div className="text-right bg-white p-3 mb-3">
						<Button color="link" size="sm" className="p-0 text-primary hover-blue"
								onClick={() => {
									articlesData.map((itemArctile, index) => (
										this.state.editArticle[itemArctile.id] = false
									));
									this.setState({
										openArticle: true,
										openEditArticle: false,
										// editArticle[itemArctile.id]:false
									})
								}}>
							<FontAwesomeIcon className="mr-1" size="sm" icon="plus"/>
							{"Add another"}
						</Button>
					</div>
				: "" }

				{/* add article div */}
				<div hidden={openArticle == true ? false : true}>
					<AddArticle taxonomy_id={this.state.taxonomy_id} openAddArticle={true} closeAddEditor={() => {
						this.setState({
							openArticle: false,
						});
						callBuyersTrendingApi()
					}} />
				</div>

				<div>
					{isEnableConfirmModal ?
						<ConfirmModal
							modalText={confirmModalText}
							modalKey={confirmModalKey}
							toggleConfirmModal={this.toggleConfirmModal}
							handleOnConfirm={this.handleOnConfirmModal}
						/>
						: null}

					{articlesData && Array.isArray(articlesData) && articlesData?.length > 0 ?
						articlesData.map((itemArctile, index) => (
							<div className="bg-white p-3 mb-3">
								{/* List all article starts */}
								<div hidden={openEditAllArticlesPage === false ? false : true}>
									<div className="d-flex mx-n2 mb-3">
										<div className="px-2 align-self-center">
											<div className="fs-16 text-darker font-weight-bold">
												<span>{padZeroes(index + 1)}.</span>{itemArctile?.article_title}
											</div>
										</div>
										<div className="px-2 col-auto ml-auto">
											<Button color="link" title={'Discuss'} size="sm" className="text-primary text-decoration-none pr-0"
													onClick={() => this.DiscussModal(itemArctile)}>Discuss
												<img className="align-middle" src={require("../../../assets/images/icons/talk.png")} alt="" />
											</Button>
											<Button color="link" title={'Edit'} size="sm" className="text-primary text-decoration-none ml-0 pr-0"
													onClick={() => this.editArticle(true,itemArctile.id)}>
												<img className="align-middle" src={require("../../../assets/images/icons/edit-article.png")} alt="" />
											</Button>
											<Button color="link" title={'Delete'} size="sm" className="text-primary text-decoration-none ml-0 pr-0"
													onClick={() => this.deleteEntry(itemArctile.id)}>
												<img className="align-middle" src={require("../../../assets/images/icons/delete-article.png")} alt="" />
											</Button>
										</div>
									</div>
									<div className="read-only-buyers-para-container">
										<p>{ReactHtmlParser(itemArctile?.description)}</p>
									</div>
									<div className="wk-articles-tab-content pt-2">
									
										{itemArctile && itemArctile.article_media && Array.isArray(itemArctile.article_media) && itemArctile.article_media.length > 0 ?
											<>
												{this.renderArticleImages(itemArctile.article_media[0], 0, itemArctile.id, true)}
												<Row xs={2} sm={3} md={4} lg={5} noGutters>
													{itemArctile.article_media.map((articleMeda, indexArticleMedia) =>
														(indexArticleMedia != 0 && this.renderArticleImages(articleMeda, indexArticleMedia, itemArctile.id, false))
													)}
												</Row>
											</>
											: null}
									</div>
								</div>
								{/* List all article ends */}

								{/** Artical Media Carsouel */}
								<Modal centered isOpen={this.state.viewMediaModalToggle} toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}>
									<ModalBody className="bg-transparent p-0">
										<Carousel
											responsive={responsive.videoModal}
											infinite={true}
											autoPlay={false}
											swipeable={true}
											showDots={false}
											arrows={true}
											sliderClass={`${carouselMedia?.length === 0}` ? "mx-auto" : ""}>

											{carouselMedia.length > 0 ? carouselMedia.map((med, i) => {
												return (
													<div className="item fs-14 h-100 d-flex flex-column justify-content-center" key={med?.id}>
														<div>
															<div className="d-flex text-white justify-content-between mt-3">
																<div className="mr-2 ff-alt">
																	{med?.caption}
																</div>
																<div className="ff-base">
																	{med?.label}
																</div>
															</div>
														</div>
														<div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
															{(med?.type === 'image' || med?.type === '') && (
																<img className="img-fluid shown-media" src={med?.url} alt="" />
															)}
															{med?.type === 'video' && (
																<video className="img-fluid shown-media" controls autoPlay>
																	<source src={med?.url} type="video/mp4" />
																	Your browser does not support the video tag.
																</video>
															)}
														</div>
														<div className="px-2 mt-3">
															<div className="d-flex flex-nowrap mx-n2">
																<div className="px-2 col d-flex flex-nowrap">
																	<div className="mr-2">
																		<img className="img-circle _30x30" src={med?.user?.current_profile_file ? med?.user?.current_profile_file : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"} alt="" onError={(error) =>
																			(error.target.src = `https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png`)
																		} />
																	</div>
																	<div>
																		<div className="text-primary ff-base">
																			Uploaded by&nbsp;<a className="text-reset" href="#"><span className="font-weight-bold">{med?.user?.user?.first_name}</span> </a>
																		</div>
																		<div className="text-tertiary ff-base">
																			{med?.upload_date}
																		</div>
																	</div>
																</div>
																<div className="px-2 col-auto">
																	<h2 className="fs-38" style={{ color: '#d4dae6' }}>{i + 1} of {carouselMedia.length}</h2>
																</div>
																<div className="px-2 col">&nbsp;</div>
															</div>
														</div>
													</div>
												)
											}) : (
												<div className="item px-4">
													<div className="d-flex flex-column align-items-center justify-content-center">
														<div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
															<span>No result found for this category</span>
														</div>
													</div>
												</div>
											)}
										</Carousel>
									</ModalBody>
								</Modal>
								{/* Edit all articles start*/}
								<div hidden={openEditAllArticlesPage === true ? false : true}>
									<div className="bg-white p-3 mb-3">
										{index === 0?<h2 className="mb-3">Edit Page</h2> : ""}
										<EditAllArticles
											taxonomy_id={this.state.taxonomy_id}
											itemArctile={itemArctile}
											openEditArticle={true}
											showUpdateButtons={articlesData?.length - 1 == index}
											closeEditAllArticles={() => {
												this.state.openEditAllArticlesPage = false;
												closeEditPage();
											}}
										/>
									</div>
								</div>
								{/* edit all articles ends */}

								{/* edit single article */}
								<div hidden={this.state.editArticle[itemArctile.id] === true ? false : true}>
									<AddArticle taxonomy_id={this.state.taxonomy_id} itemArctile={itemArctile} openEditArticle={openEditArticle}
												closeEditor={() => {
													callBuyersTrendingApi()
													this.state.editArticle[itemArctile.id] = false;
													this.setState({
														openEditArticle: false,
													})}}

									/>
								</div>

								{/* discuss modal */}
								{this.state.discussModal && (
									<AddArticle
										openModal={this.state.discussModal}
										toggle={this.closeModal}
										article_title={this.state.discussionArticleTitle}
										itemArctile={itemArctile}
										taxonomy_id={this.state.taxonomy_id}
										// activeDiscussionTab = {this.state.activeDiscussionTab}
									/>
								)}
							</div>
						))
						:
						<div>
							<span className="small"><i>{'No articles.'}</i></span>
						</div>}
				</div>
			</>
		)
	}
}

const mapState = (state) => ({
	get_buyersGuides: state.buyersGuide.get_buyersGuides
});

const mapProps = (dispatch) => ({
	get_buyers_guides: () => dispatch(get_buyers_guides()),
	delete_buyers_article: (id, taxonomy_id) => dispatch(delete_buyers_article(id, taxonomy_id))

});

export default connect(mapState, mapProps)(ArticleTab);
