/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import AppHeader from '../../oragnisms/dashboard/appHeader';
import { Container, Row, Col } from 'reactstrap';
export default class Manifesto extends Component {
    render() {
        return (
            <div>
                <AppHeader isEnableCover={false} />
                <div className="wk-static-wrapper">
                  <Container>
                      <div className="wk-static-sub-head">
                         <h1>MANIFESTO</h1>
                      </div>
                      <div className="wk-static-content-body">
                         <Row>
                            <Col md={12}>
                                <div className="wk-static-content-right">
                                  <h2 className="text-center">WIKIREVIEWS MANIFESTO</h2>
                                  <p className="fs-14">We believe that reviews are vital to our society and that soon, reviews will be the first thing people turn to before consuming anything.  In fact, we believe there should be reviews for everything, they should be free to read, all in one place and users should be able to see what their friends use, review and recommend.  Existing Review sites, however, do not do this.  In fact, they have business models that are broken by hounding businesses for money, charging users to access reviews, allowing any service provider to pay money to show up higher in the search results, not authenticating their user base and providing no recourse for fake reviews.  This is unfair for both readers and businesses.
                                  </p>
                                  <p className="fs-14">At WikiReviews, we fully understand the issues plaguing the reviews industry as well as the concerns of those who read reviews.  We have developed solutions to overcome these issues, and our primary solution centers around the community being in charge of what happens to reviews and the content on our site. 
                                  </p>
                                  <p className="fs-14">We believe that businesses should have recourse with reviews and the ability to share their side of the story. Then we let the community decide what happens to those reviews. We also believe in authenticating users to make sure that each account has a verified and unique real user.
                                  </p>
                                  <p className="fs-14">We also believe that reviews should be social.  We know that friends trust the recommendations of their friends more than experts.  We designed our site to focus on letting our users know what their friends recommend and want. 
                                  </p>
                                  <p className="fs-14">We are here to solve these problems by creating a place where users can constantly share what they love and what they don’t, with their circle of friends.   Our solution puts the community in charge of all the content including which reviews stay and which one’s go.
                                  </p>
                                  <p className="fs-14">Finally, we want to turn the concept of reviews upside down by creating a truly unique platform that allows users to upload their own personal projects for the community at large to review.
                                  </p>
                                  <p className="fs-14">We hope we are the solution you have all been waiting for!
                                  </p>
                                  <p className="fs-14">Sunil Wagle <br/> CEO & Founder
                                  </p>
                                </div>
                            </Col>
                         </Row>
                      </div>
                  </Container>
                </div>
            </div>
        )
    }
}
