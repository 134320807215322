import * as actions from "../actions/business";
const initialState = {
    connections_activity_data: null,
    collage_items: null,
    categories: null,
    sub_categories: null,
    products_list: null,
    business_collage_sub_items: null,
    business_details: null,
    simillar_business: null,
    chart_star_ratings: null,
    tips_and_warnings: null,
    images_children: [],
    videos_children: [],
    other_offers: null, // offers 
    check_in_offers: null,
    in_store_offers: null,
    flags_data: null,
    discuss_this_page: null,
    section_menu_item: null,
    main_menu_item: null,
    captcha_items: null,
    business_signup_data: null,
    wall_data: null,
    qa_dropdown: null,
    qa_data: null,
    business_rating: [],
    business_reviewDetail: null,
    back_to_listing: null,
    additional_attributes: [],
    listing_pins: null,
    new_sub_categories: null,
    new_sub_child_categories: null,
    all_sub_categories: null,
    friends_sub_categories: null,
    highly_sub_categories: null,
    fetch_data_categories: null,
    filter_data_category: null,    
    fetch_map_data: null,
    fetch_save_response: null,
    get_hours_operation: null,
    amenities_data: null,
    working_employee: null,
    pin_list_status: null,
    isLoading:true,
    menuItemChanged:0
};

const business = (state = initialState, action) => {

    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case actions.GET_CONNECTIONS_ACTIVITY:
            state.connections_activity_data = action.payload
            return { ...state };

        case "FETCH_COLLAGE_ITEMS":
            state.collage_items = action.payload
            return { ...state };

        case "FETCH_ALL_CATEGORIES":
            state.categories = action.payload
            return { ...state };

        case "FETCH_ALL_SUB_CATEGORIES":
            state.sub_categories = action.payload
            return { ...state };

        case 'CLEAR_SUB_CATEGORIES':
            state.sub_categories = null
            return { ...state };

        case 'FETCH_PRODUCT_LIST':
            state.products_list = action.payload;
            return { ...state };

        case 'FETCH_BUSINESS_COLLAGE_SUB_CATEGORY':
            state.business_collage_sub_items = action.payload;
            return { ...state };

        case "GET_BUSINESS_DETAILS_BY_ID":
            state.business_details = action.payload;
            return { ...state };

        case "GET_BUSINESS_RATING_BY_ID":
            state.business_rating = action.payload;
            return { ...state };

        case "GET_BUSINESS_REVIEW_DETAIL_BY_ID":
            state.business_reviewDetail = action.payload;
            return { ...state };

        case "FETCH_DATA_CATEGORIES":
            state.fetch_data_categories = action.payload;
            return { ...state };

        case "FILTER_DATA_CATEGORIES":
            state.filter_data_category = action.payload;
            return { ...state };

        case "GET_MEDIA_CHILDREN":
            if (action.mediaType === 'images') {
                state.images_children = action.payload;
            } else if (action.mediaType === 'videos') {
                state.videos_children = action.payload;
            }
            return { ...state };

        case "GET_BACK_TO_BUSINESS":
            state.back_to_listing = action.payload;
            return { ...state };

        case "BUSINESS_LOCATION_WEATHER":
            state.business_weather = action.payload;
            return { ...state };

        case 'GET_SIMILLAR_BUSINESS':
            state.simillar_business = action.payload;
            return { ...state };

        case 'GET_CHART_STAR_RATINGS':
            state.chart_star_ratings = action.payload;
            return { ...state };

        case 'GET_TIPS_AND_WARNINGS':
            state.tips_and_warnings = action.payload;
            return { ...state };

        case 'GET_OTHER_OFFERS':
            state.other_offers = action.payload;
            return { ...state };

        case 'GET_CHECK_IN_OFFERS':
            state.check_in_offers = action.payload;
            return { ...state };

        case 'GET_IN_STORE_OFFERS':
            state.in_store_offers = action.payload;
            return { ...state };

        case 'GET_BUSINESS_FLAGS':
            state.flags_data = action.payload;
            return { ...state };

        case 'GET_DISCUSS_THIS_PAGE':
            state.discuss_this_page = action.payload;
            return { ...state };

        case 'GET_MENU_ITEM':
            state.main_menu_item = action.payload;
            return { ...state };

        case 'MENU_ITEM_CHANGED':
            state.menuItemChanged = action.payload;
            return { ...state };

        case 'GET_SECTION_MENU_ITEM':
            state.section_menu_item = action.payload;
            return { ...state };

        case 'GET_CAPTCHA':
            state.captcha_items = action.payload;
            return { ...state };

        case 'POST_BUSINESS_SIGN_UP':
            state.business_signup_data = action.payload;
            return { ...state };

        case 'BUSINESS_WALL_DATA':
            state.wall_data = action.payload;
            return { ...state };

        case 'GET_BUSINESS_QA_DROPDWON':
            state.qa_dropdown = action.payload;
            return { ...state };

        case 'GET_BUSINESS_QA_DATA':
            state.qa_data = action.payload;
            return { ...state };

        case 'GET_ADDITIONAL_ATTRIBUTES':
            state.additional_attributes = action.payload;
            return { ...state };

        case 'FETCH_LISTING_PINS':
            state.listing_pins = action.payload;
            return { ...state };


        case 'FETCH_NEW_SUB_CATEGORIES':
            state.new_sub_categories = action.payload;
            return { ...state };

        case 'FETCH_NEW_SUB_CATEGORIE_CHILD':
            state.new_sub_child_categories = action.payload;
            return { ...state };

        case 'FETCH_ALL_SUB_CATEGORIE_ITEMS':
            state.all_sub_categories = action.payload;
            return { ...state };

        case 'FETCH_FRIENDS_SUB_CATEGORIE_ITEMS':
            state.friends_sub_categories = action.payload;
            return { ...state };

        case 'FETCH_HIGHLY_SUB_CATEGORIE_ITEMS':
            state.highly_sub_categories = action.payload;
            return { ...state };

        case 'GET_MAP_DATA_ITEM':
            state.fetch_map_data = action.payload;
            return { ...state };    

        case 'GET_PROFESSIONAL_QA_DATA':
            state.qa_data = action.payload;
            return { ...state };

        case 'GET_HOURS_OF_OPERATION':
            state.get_hours_operation = action.payload;
            return { ...state };

        case "GET_SAVE_BUSINESS_RESPONSE":
            state.fetch_save_response = action.payload;
            return { ...state };

        case "GET_AMENITIES_DATA":
            state.amenities_data = action.payload;
            return { ...state };
        
        case "GET_WORKING_EMPLOYEE":
            state.working_employee = action.payload;
            return { ...state };

        case "PIN_LIST_STATUS_UPDATE":
            state.pin_list_status = action.payload;
            return { ...state }
        case "IS_LOADING":
            state.isLoading = action.payload
            return { ...state };
        default:
            return state;
    }
}

export default business;
